"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextoUtil = void 0;
const angular = require("angular");
class TextoUtil {
    static lowerCamelCase(text) {
        return text.charAt(0).toLowerCase() + text.slice(1);
    }
    static ehEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    static extrairNomeModulo(modulo) {
        return modulo.name.substring(0, modulo.name.indexOf('Modulo')).toLowerCase();
    }
    static transformarEmHash32bits(texto) {
        let hash = 0;
        let chr = 0;
        for (let i = 0; i < texto.length; i++) {
            chr = texto.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0;
        }
        return hash;
    }
    static Uint8ToString(u8a) {
        if ((typeof u8a === 'undefined') || (u8a === null)) {
            return null;
        }
        const CHUNK_SZ = 0x8000;
        let arr = [];
        for (let i = 0; i < u8a.length; i += CHUNK_SZ) {
            arr.push(String.fromCharCode.apply(null, u8a.subarray(i, i + CHUNK_SZ)));
        }
        return arr.join('');
    }
    static converteTextoParaReferencia(texto) {
        if ((typeof texto !== 'undefined') && (texto !== null) && (texto.length > 0)) {
            if ((typeof globalThis.cacheConverteTextoParaReferencia === 'undefined') || (globalThis.cacheConverteTextoParaReferencia === null)) {
                globalThis.cacheConverteTextoParaReferencia = [];
            }
            let textoCache = globalThis.cacheConverteTextoParaReferencia[texto];
            if ((typeof textoCache !== 'undefined') && textoCache) {
                return textoCache;
            }
            let s = texto.trim().toLowerCase();
            s = s.split(' ').join('_');
            s = s.split(' ').join('_');
            s = s.split('\r\n').join('_');
            s = s.split('\n').join('_');
            s = s.split('\r').join('_');
            s = s.split('-').join('_');
            s = s.split('+').join('_');
            s = s.split('*').join('_');
            s = s.split('\\').join('_');
            s = s.split('/').join('_');
            s = s.split('|').join('_');
            s = s.split(',').join('_');
            s = s.split('.').join('_');
            s = s.split(';').join('_');
            s = s.split(':').join('_');
            s = s.split('$').join('_');
            s = s.split('%').join('_');
            s = s.split('&').join('_');
            s = s.split('(').join('_');
            s = s.split(')').join('_');
            s = s.split('{').join('_');
            s = s.split('}').join('_');
            s = s.split('[').join('_');
            s = s.split(']').join('_');
            s = s.split('<').join('_');
            s = s.split('>').join('_');
            s = s.split('¨').join('_');
            s = s.split('^').join('_');
            s = s.split('~').join('_');
            s = s.split('´').join('_');
            s = s.split('`').join('_');
            s = s.split('@').join('_');
            s = s.split('°').join('_');
            s = s.split('º').join('_');
            s = s.split('ª').join('_');
            s = s.split('«').join('_');
            s = s.split('»').join('_');
            s = s.split('!').join('_');
            s = s.split('?').join('_');
            s = s.split('á').join('a');
            s = s.split('Á').join('a');
            s = s.split('à').join('a');
            s = s.split('À').join('a');
            s = s.split('ã').join('a');
            s = s.split('Ã').join('a');
            s = s.split('â').join('a');
            s = s.split('Â').join('a');
            s = s.split('ä').join('a');
            s = s.split('Ä').join('a');
            s = s.split('é').join('e');
            s = s.split('É').join('e');
            s = s.split('è').join('e');
            s = s.split('È').join('e');
            s = s.split('ê').join('e');
            s = s.split('Ê').join('e');
            s = s.split('ë').join('e');
            s = s.split('Ë').join('e');
            s = s.split('í').join('i');
            s = s.split('Í').join('i');
            s = s.split('ì').join('i');
            s = s.split('Ì').join('i');
            s = s.split('î').join('i');
            s = s.split('Î').join('i');
            s = s.split('ï').join('i');
            s = s.split('Ï').join('i');
            s = s.split('ó').join('o');
            s = s.split('Ó').join('o');
            s = s.split('ò').join('o');
            s = s.split('Ò').join('o');
            s = s.split('õ').join('o');
            s = s.split('Õ').join('o');
            s = s.split('ô').join('o');
            s = s.split('Ô').join('o');
            s = s.split('ö').join('o');
            s = s.split('Ö').join('o');
            s = s.split('o').join('o');
            s = s.split('ú').join('u');
            s = s.split('Ú').join('u');
            s = s.split('ù').join('u');
            s = s.split('Ù').join('u');
            s = s.split('û').join('u');
            s = s.split('Û').join('u');
            s = s.split('ü').join('u');
            s = s.split('Ü').join('u');
            s = s.split('ç').join('c');
            s = s.split('Ç').join('c');
            s = s.split('ñ').join('n');
            s = s.split('Ñ').join('n');
            while ((s.length > 0) && (s.indexOf('__') !== -1)) {
                s = s.replace('__', '_');
            }
            while ((s.length > 0) && (s.charAt(0) === '_')) {
                s = s.substring(1);
            }
            while ((s.length > 0) && (s.charAt(s.length - 1) === '_')) {
                s = s.substring(0, s.length - 1);
            }
            globalThis.cacheConverteTextoParaReferencia[texto] = s;
            return s;
        }
        return '';
    }
    static converteTextoParaReferenciaSemNumeroRepeticao(texto) {
        if ((typeof texto !== 'undefined') && (texto !== null) && (texto.length > 0)) {
            if ((typeof globalThis.cacheConverteTextoParaReferenciaSN === 'undefined') || (globalThis.cacheConverteTextoParaReferenciaSN === null)) {
                globalThis.cacheConverteTextoParaReferenciaSN = [];
            }
            let textoCache = globalThis.cacheConverteTextoParaReferenciaSN[texto];
            if ((typeof textoCache !== 'undefined') && textoCache) {
                return textoCache;
            }
            let s = TextoUtil.converteTextoParaReferencia(texto);
            s = s.replace(/ /g, '').replace(/\./g, '').replace(/#/g, '')
                .replace(/0/g, '').replace(/1/g, '').replace(/2/g, '').replace(/3/g, '').replace(/4/g, '')
                .replace(/5/g, '').replace(/6/g, '').replace(/7/g, '').replace(/8/g, '').replace(/9/g, '');
            while ((s.length > 0) && (s.charAt(0) === '_')) {
                s = s.substring(1);
            }
            while ((s.length > 0) && (s.charAt(s.length - 1) === '_')) {
                s = s.substring(0, s.length - 1);
            }
            globalThis.cacheConverteTextoParaReferenciaSN[texto] = s;
            return s.trim();
        }
        return '';
    }
    static extrairNumeros(texto) {
        if ((texto !== null) && (texto.trim().length > 0)) {
            let s = '';
            for (let i = 0; i < texto.length; i++) {
                let caractere = texto.charAt(i);
                if ('0123456789'.indexOf(caractere) !== -1) {
                    s += caractere;
                }
            }
            return s;
        }
        return '';
    }
    static extrairListaNumeros(texto) {
        let lista = new Array();
        if ((texto !== null) && (texto.trim().length > 0)) {
            let s = '';
            let negativo = false;
            for (let i = 0; i < texto.length; i++) {
                let caractere = texto.charAt(i);
                if ('0123456789'.indexOf(caractere) !== -1) {
                    s += caractere;
                }
                else if ((caractere === '-') && (s.trim().length === 0)) {
                    negativo = true;
                }
                else {
                    if (s.trim().length > 0) {
                        lista.push(parseInt((negativo) ? '-' + s.trim() : s.trim(), 10));
                    }
                    s = '';
                    negativo = false;
                }
            }
            if (s.trim().length > 0) {
                lista.push(parseInt((negativo) ? '-' + s.trim() : s.trim(), 10));
            }
        }
        return lista;
    }
    static compararValorCampoIndicador(valor1, valor2) {
        const ehValor1EmBranco = (typeof valor1 === 'undefined') || (valor1 === null) ||
            ((typeof valor1 === 'string') && (valor1.trim().length === 0)) ||
            (Array.isArray(valor1) && (valor1.length === 0)) ||
            ((typeof valor1 === 'object') && (typeof valor1.length !== 'undefined') && (valor1.length === 0));
        const ehValor2EmBranco = (typeof valor2 === 'undefined') || (valor2 === null) || (valor2 === '') ||
            ((typeof valor2 === 'string') && (valor2.trim().length === 0)) ||
            (Array.isArray(valor2) && (valor2.length === 0)) ||
            ((typeof valor2 === 'object') && (typeof valor2.length !== 'undefined') && (valor2.length === 0));
        if (ehValor1EmBranco && ehValor2EmBranco) {
            return true;
        }
        if (!ehValor1EmBranco && !ehValor2EmBranco) {
            if (valor1 === valor2) {
                return true;
            }
            let listaValores1 = new Array();
            if (Array.isArray(valor1)) {
                listaValores1.push(...valor1);
            }
            else if (typeof valor1 === 'object') {
                Object.keys(valor1).forEach((prop) => {
                    if ((typeof valor1[prop] !== 'undefined') && (valor1[prop] !== null)) {
                        if (typeof prop === 'string') {
                            if ((prop === 'id') || (prop === 'uuid') || (prop === 'nome') || (prop === 'descricao')) {
                                listaValores1.push(valor1[prop]);
                            }
                        }
                        else {
                            listaValores1.push(valor1[prop]);
                        }
                    }
                });
            }
            else {
                listaValores1.push(valor1);
            }
            let listaValores2 = new Array();
            if (Array.isArray(valor2)) {
                listaValores2.push(...valor2);
            }
            else if (typeof valor2 === 'object') {
                Object.keys(valor2).forEach((prop) => {
                    if ((typeof valor2[prop] !== 'undefined') && (valor2[prop] !== null)) {
                        if (typeof prop === 'string') {
                            if ((prop === 'id') || (prop === 'uuid') || (prop === 'nome') || (prop === 'descricao')) {
                                listaValores2.push(valor2[prop]);
                            }
                        }
                        else {
                            listaValores2.push(valor2[prop]);
                        }
                    }
                });
            }
            else {
                listaValores2.push(valor2);
            }
            if (listaValores1 && (listaValores1.length > 0) && listaValores2 && (listaValores2.length > 0)) {
                const retornoBusca1 = listaValores1.find((v1) => {
                    const v1Tratado = (typeof v1 === 'string') ? TextoUtil.converteTextoParaReferencia(v1) : v1;
                    const retornoBusca2 = listaValores2.find((v2) => {
                        const v2Tratado = (typeof v2 === 'string') ? TextoUtil.converteTextoParaReferencia(v2) : v2;
                        return (v1Tratado === v2Tratado);
                    });
                    return ((typeof retornoBusca2 !== 'undefined') && retornoBusca2 ? true : false);
                });
                return ((typeof retornoBusca1 !== 'undefined') && retornoBusca1 ? true : false);
            }
        }
        return false;
    }
    static descricaoEntidade(entidade, etapa = null) {
        if ((typeof entidade !== 'undefined') && (entidade !== null)) {
            if ((typeof entidade === 'bigint') || (typeof entidade === 'number')) {
                return String(entidade).trim();
            }
            else if (typeof entidade === 'string') {
                return entidade.trim();
            }
            else if ((typeof entidade.descricao !== 'undefined') && entidade.descricao && (entidade.descricao.trim().length > 0)) {
                return entidade.descricao.trim();
            }
            else if ((typeof entidade.nomeCompleto !== 'undefined') && entidade.nomeCompleto && (entidade.nomeCompleto.trim().length > 0)) {
                return entidade.nomeCompleto.trim();
            }
            else if ((typeof entidade.nome !== 'undefined') && entidade.nome && (entidade.nome.trim().length > 0)) {
                return entidade.nome.trim();
            }
            else if ((typeof entidade.nomeRegional !== 'undefined') && entidade.nomeRegional && (entidade.nomeRegional.trim().length > 0)) {
                return entidade.nomeRegional.trim();
            }
            else if ((typeof entidade.nomeCientifico !== 'undefined') && entidade.nomeCientifico && (entidade.nomeCientifico.trim().length > 0)) {
                return entidade.nomeCientifico.trim();
            }
            else if ((typeof entidade.descricaoDetalhada !== 'undefined') && entidade.descricaoDetalhada && (entidade.descricaoDetalhada.trim().length > 0)) {
                return entidade.descricaoDetalhada.trim();
            }
            else if ((typeof entidade.descricaoResumida !== 'undefined') && entidade.descricaoResumida && (entidade.descricaoResumida.trim().length > 0)) {
                return entidade.descricaoResumida.trim();
            }
            else if ((typeof entidade.titulo !== 'undefined') && entidade.titulo && (entidade.titulo.trim().length > 0)) {
                return entidade.titulo.trim();
            }
            else if ((typeof entidade.sigla !== 'undefined') && entidade.sigla && (entidade.sigla.trim().length > 0)) {
                return entidade.sigla.trim();
            }
            else if (angular.isArray(entidade)) {
                if ((typeof etapa !== 'undefined') && etapa && (etapa > 10)) {
                    return null;
                }
                let primeiroItem = null;
                angular.forEach(entidade, (item) => {
                    if (!primeiroItem && (typeof item !== 'undefined') && item) {
                        primeiroItem = item;
                    }
                });
                if (primeiroItem) {
                    const novaEtapa = (((typeof etapa !== 'undefined') && etapa) ? etapa : 0) + 1;
                    return TextoUtil.descricaoEntidade(primeiroItem, novaEtapa);
                }
            }
        }
        return null;
    }
}
exports.TextoUtil = TextoUtil;
