"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MapaController = void 0;
const DispositivoUtil_1 = require("../../../../../util/DispositivoUtil");
const DialogoFactory_1 = require("../../../../fabricas/DialogoFactory");
const NotificacaoFactory_1 = require("../../../../fabricas/NotificacaoFactory");
class MapaController {
    constructor($scope, notificacao) {
        this.$scope = null;
        this.notificacao = null;
        this.id = null;
        this.coordenada = null;
        this.geojson = null;
        this.timerCarregamentoMapa = null;
        this.$scope = $scope;
        this.notificacao = notificacao;
    }
    $postLink() {
        this.timerCarregamentoMapa = setTimeout(MapaController.carregarMapa, 1000, this);
        const onChange = () => {
            try {
                if (this.timerCarregamentoMapa) {
                    clearTimeout(this.timerCarregamentoMapa);
                }
            }
            catch (ex) {
                console.log(ex);
            }
            this.timerCarregamentoMapa = setTimeout(MapaController.carregarMapa, 1000, this);
        };
        this.$scope.$watch('$ctrl.coordenada', onChange);
        this.$scope.$watch('$ctrl.geojson', onChange);
    }
    static carregarMapa(ctrl) {
        try {
            let idUnico = (ctrl.id) ? '#' + ctrl.id + '-mapa' : '#mapa';
            DispositivoUtil_1.DispositivoUtil.mostrarMapa(ctrl.coordenada, idUnico, ctrl.geojson).then(() => {
                console.log('Mapa carregado!');
            });
        }
        catch (ex) {
            console.log(ex);
        }
    }
}
exports.MapaController = MapaController;
MapaController.$inject = ['$scope', NotificacaoFactory_1.NotificacaoFactory.id, DialogoFactory_1.DialogoFactory.id];
