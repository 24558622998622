"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HistoricoEstoqueProdutoDefensivo = void 0;
const Base_1 = require("./Base");
class HistoricoEstoqueProdutoDefensivo extends Base_1.Base {
    constructor() {
        super();
        this.id = null;
        this.codigo = null;
        this.produtoDefensivo = null;
        this.contaDepositoSap = null;
        this.centroCustoSap = null;
        this.quantidadeAnterior = null;
        this.quantidadeMovimento = null;
        this.quantidadeAtual = null;
        this.movimento = null;
        this.usuario = null;
        this.status = true;
    }
}
exports.HistoricoEstoqueProdutoDefensivo = HistoricoEstoqueProdutoDefensivo;
HistoricoEstoqueProdutoDefensivo.MOVIMENTO_ENTRADA = 'Entrada';
HistoricoEstoqueProdutoDefensivo.MOVIMENTO_SAIDA = 'Saída';
