"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabGerminacaoSementesExterno = void 0;
const Base_1 = require("./Base");
class LabGerminacaoSementesExterno extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.germinacaoSemente = null;
        this.dataGerminacao = null;
        this.genero = null;
        this.especie = null;
        this.pomar = null;
        this.safra = null;
        this.lote = null;
        this.materialGenetico = null;
        this.representatividade = null;
        this.reteste = null;
        this.germinacaoAnterior = null;
        this.laboratorios = [];
        this.anexos = [];
        this.germinacaoSementeId = null;
        this.generoId = null;
        this.generoDescricao = null;
        this.especieId = null;
        this.especieDescricao = null;
        this.pomarId = null;
        this.pomarDescricao = null;
        this.safraId = null;
        this.safraDescricao = null;
        this.loteId = null;
        this.loteDescricao = null;
        this.materialGeneticoId = null;
        this.materialGeneticoDescricao = null;
    }
}
exports.LabGerminacaoSementesExterno = LabGerminacaoSementesExterno;
