"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImportaTalhao = void 0;
const Base_1 = require("./Base");
class ImportaTalhao extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.id = null;
        this.sgif_id = null;
        this.operacao = null;
        this.numero = null;
        this.regiao = null;
        this.regiaoId = null;
        this.regiaoSgifId = null;
        this.regiaoNum = null;
        this.regiaoNome = null;
        this.areaTalhao = null;
        this.dataPlantio = null;
        this.tipoFloresta = null;
        this.arvores = null;
        this.inativo = false;
        this.talhaoAnterior1 = null;
        this.talhaoAnterior2 = null;
        this.talhaoAnterior3 = null;
        this.alterado = false;
    }
}
exports.ImportaTalhao = ImportaTalhao;
