"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BeneficiamentoPolinizacaoPomar = void 0;
const Base_1 = require("./Base");
const uuid_1 = require("uuid");
class BeneficiamentoPolinizacaoPomar extends Base_1.Base {
    constructor() {
        super();
        this.id = null;
        this.guid = null;
        this.ehPinus = null;
        this.ehEucalyptus = null;
        this.data = null;
        this.numSementesTotal = null;
        this.numSementesBoas = null;
        this.numSementesCone = null;
        this.quantidade = null;
        this.guid = uuid_1.v4();
        this.status = true;
    }
}
exports.BeneficiamentoPolinizacaoPomar = BeneficiamentoPolinizacaoPomar;
