"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabGerminacaoPolenController = void 0;
const angular = require("angular");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const UiGridConstrutor_1 = require("../../../app/construtores/UiGridConstrutor");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const AbstratoController_1 = require("../../../app/AbstratoController");
const ICRUDOperacao_1 = require("../../../app/interfaces/ICRUDOperacao");
const config_app_1 = require("../../../../../config/config.app");
const sweetalert2_1 = require("sweetalert2");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const LabGerminacaoPolen_1 = require("../../../../../modelos/LabGerminacaoPolen");
const AmostraGerminacao_1 = require("../../../../../modelos/AmostraGerminacao");
class LabGerminacaoPolenController extends AbstratoController_1.AbstratoController {
    constructor($scope, $injector) {
        super($scope, $injector, new LabGerminacaoPolen_1.LabGerminacaoPolen());
        this.path = '';
        this.caminho = '';
        this.tipoOperacao = null;
        this.modalAmostrasLista = false;
        this.posicaoTopoAntesModal = null;
        this.amostrasGerminacaoPolen = [];
        this.pomares = [];
        this.generos = [];
        this.especies = [];
        this.materiaisGeneticos = [];
        this.estoqueOperacional = LabGerminacaoPolen_1.LabGerminacaoPolen.ESTOQUE_OPERACIONAL;
        this.estoquePesquisa = LabGerminacaoPolen_1.LabGerminacaoPolen.ESTOQUE_PESQUISA;
        this.tipoOperacao = ICRUDOperacao_1.CRUDOperacao;
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.path = config_app_1.config.api.caminho;
            this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.LAB_GERMINACAO_POLEN;
            this.modalAmostrasLista = false;
            if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR ||
                this.operacao === ICRUDOperacao_1.CRUDOperacao.EDITAR ||
                this.operacao === ICRUDOperacao_1.CRUDOperacao.DUPLICAR) {
                this.pomares = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POMAR)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.pomares = resposta;
                    }
                });
                this.generos = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.GENERO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.GENERO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.generos = resposta;
                    }
                });
                this.especies = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESPECIE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESPECIE)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.especies = resposta;
                    }
                });
                this.materiaisGeneticos = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MATERIAL_GENETICO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.materiaisGeneticos = resposta;
                    }
                });
            }
            this.$scope.$apply();
        });
    }
    salvar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.modalAmostrasLista = false;
                if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_GERMINACAO_POLEN)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_GERMINACAO_POLEN)
                        .criar(this.modelo);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_GERMINACAO_POLEN)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_GERMINACAO_POLEN)
                        .atualizar(this.modelo);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir uma nova Germinação de Polen ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir uma nova Germinação de Polen ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    visualizar(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const labGerminacaoPolen = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_GERMINACAO_POLEN)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_GERMINACAO_POLEN)
                    .id(id)
                    .ver();
                labGerminacaoPolen.criacao = new Date(labGerminacaoPolen.criacao);
                labGerminacaoPolen.data = new Date(labGerminacaoPolen.data);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return labGerminacaoPolen;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    remover(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const labGerminacaoPolen = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_GERMINACAO_POLEN)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_GERMINACAO_POLEN)
                    .id(id)
                    .remover();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (labGerminacaoPolen) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const labGerminacaoPolen = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_GERMINACAO_POLEN)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_GERMINACAO_POLEN)
                    .id(this.modelo.id)
                    .restaurar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (labGerminacaoPolen) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosLista(filtro = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                this.path = config_app_1.config.api.caminho;
                this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.LAB_GERMINACAO_POLEN;
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                var auxColunas = Array();
                auxColunas.push({
                    name: 'codigo',
                    displayName: 'Código',
                    width: 90,
                });
                auxColunas.push({
                    name: 'genero.descricao',
                    displayName: 'Gênero',
                    width: 140,
                });
                auxColunas.push({
                    name: 'especie.descricao',
                    displayName: 'Espécie',
                    width: 140,
                });
                auxColunas.push({
                    name: 'germinacaoFinal',
                    displayName: 'Germinação Final',
                    width: 140,
                });
                auxColunas.push({
                    name: 'criacao',
                    displayName: 'Data/Hora Cadastro',
                    cellFilter: 'date:"dd/MM/yyyy HH:mm:ss"',
                    width: 190,
                });
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
                const lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_GERMINACAO_POLEN)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_GERMINACAO_POLEN)
                    .filtro(filtro)
                    .listar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    filtrar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    limparFiltro() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    exibirModalAmostrasLista() {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.modelo === 'undefined' || !this.modelo) {
                return;
            }
            if (typeof this.modelo.amostras === 'undefined' ||
                this.modelo.amostras === null) {
                this.modelo.amostras = new Array();
            }
            try {
                try {
                    this.posicaoTopoAntesModal = angular.element(window).scrollTop();
                }
                catch (ex) {
                    this.posicaoTopoAntesModal = null;
                    console.log(ex);
                }
                this.modalAmostrasLista = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalAmostrasLista() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalAmostrasLista = false;
            angular.element('body').css('overflow-y', '');
            if (typeof this.modelo !== 'undefined' &&
                this.modelo &&
                typeof this.modelo.amostras !== 'undefined' &&
                this.modelo.amostras) {
                this.modelo.amostras = this.modelo.amostras.filter((o) => typeof o.germinacao !== 'undefined' && o.germinacao);
            }
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    moverAmostraParaCima(key) {
        if (typeof this.modelo === 'undefined' ||
            !this.modelo ||
            typeof this.modelo.amostras === 'undefined' ||
            !this.modelo.amostras) {
            return;
        }
        if (key != null && key >= 0 && key < this.modelo.amostras.length - 1) {
            let oldKey = key;
            let newKey = key + 1;
            this.modelo.amostras.splice(newKey, 0, this.modelo.amostras.splice(oldKey, 1)[0]);
            this.scopeApply();
        }
    }
    moverAmostraParaBaixo(key) {
        if (typeof this.modelo === 'undefined' ||
            !this.modelo ||
            typeof this.modelo.amostras === 'undefined' ||
            !this.modelo.amostras) {
            return;
        }
        if (key != null && key >= 0 && key < this.modelo.amostras.length - 1) {
            let oldKey = key;
            let newKey = key + 1;
            this.modelo.amostras.splice(newKey, 0, this.modelo.amostras.splice(oldKey, 1)[0]);
            this.scopeApply();
        }
    }
    adicionarAmostra() {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.modelo === 'undefined' || !this.modelo) {
                return;
            }
            if (typeof this.modelo.amostras === 'undefined' ||
                this.modelo.amostras === null) {
                this.modelo.amostras = new Array();
            }
            this.modelo.amostras.push(new AmostraGerminacao_1.AmostraGerminacao());
            this.scopeApply();
        });
    }
    removerAmostra(key) {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.modelo === 'undefined' || !this.modelo) {
                return;
            }
            if (typeof this.modelo.amostras === 'undefined' ||
                this.modelo.amostras === null) {
                this.modelo.amostras = new Array();
            }
            if (typeof key !== 'undefined' &&
                key !== null &&
                this.modelo.amostras.length > 0 &&
                key <= this.modelo.amostras.length - 1) {
                this.modelo.amostras.splice(key, 1);
                this.calcularGerminacaoFinal();
                this.scopeApply();
            }
        });
    }
    calcularGerminacaoFinal() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.modelo === 'undefined' ||
                !this.modelo ||
                typeof this.modelo.amostras === 'undefined' ||
                !this.modelo.amostras) {
                return;
            }
            let varSoma = 0;
            let totArr = (_a = this.modelo.amostras.length) !== null && _a !== void 0 ? _a : 1;
            this.modelo.germinacaoFinal = 0;
            for (let i = 0; i < totArr; i++) {
                varSoma += (_b = this.modelo.amostras[i].germinacao) !== null && _b !== void 0 ? _b : 0;
            }
            this.modelo.germinacaoFinal = parseFloat((varSoma / totArr).toFixed(2));
        });
    }
}
exports.LabGerminacaoPolenController = LabGerminacaoPolenController;
LabGerminacaoPolenController.$inject = ['$scope', '$injector'];
