"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CRUDOperacao = void 0;
var CRUDOperacao;
(function (CRUDOperacao) {
    CRUDOperacao["LISTAR"] = "listar";
    CRUDOperacao["FORMULARIO"] = "formulario";
    CRUDOperacao["VISUALIZAR"] = "ver";
    CRUDOperacao["IMPRIMIR"] = "imprimir";
    CRUDOperacao["CRIAR"] = "criar";
    CRUDOperacao["EDITAR"] = "editar";
    CRUDOperacao["REMOVER"] = "remover";
    CRUDOperacao["SELECIONAR"] = "selecionar";
    CRUDOperacao["DUPLICAR"] = "duplicar";
    CRUDOperacao["RECUPERAR"] = "recuperar";
    CRUDOperacao["IMPORTAR"] = "importar";
})(CRUDOperacao = exports.CRUDOperacao || (exports.CRUDOperacao = {}));
