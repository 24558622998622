"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pais = void 0;
const Base_1 = require("./Base");
class Pais extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.descricao = null;
    }
}
exports.Pais = Pais;
Pais.BRASIL = '651424a3a556a000e2462153';
Pais.EUA = '63b2f9934d70e1266b6fc345';
