"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabExperimentosController = void 0;
const angular = require("angular");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const UiGridConstrutor_1 = require("../../../app/construtores/UiGridConstrutor");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const AbstratoController_1 = require("../../../app/AbstratoController");
const ICRUDOperacao_1 = require("../../../app/interfaces/ICRUDOperacao");
const config_app_1 = require("../../../../../config/config.app");
const sweetalert2_1 = require("sweetalert2");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const LabExperimentos_1 = require("../../../../../modelos/LabExperimentos");
const DetalheExperimento_1 = require("../../../../../modelos/DetalheExperimento");
const CampoIndicadorFotos_1 = require("../../../app/componentes/campo-indicador/CampoIndicadorFotos");
const DispositivoUtil_1 = require("../../../../../util/DispositivoUtil");
class LabExperimentosController extends AbstratoController_1.AbstratoController {
    constructor($scope, $injector) {
        super($scope, $injector, new LabExperimentos_1.LabExperimentos());
        this.path = '';
        this.caminho = '';
        this.tipoOperacao = null;
        this.listasFotos = null;
        this.modalDetalhesLista = false;
        this.posicaoTopoAntesModal = null;
        this.detalhesExperimento = [];
        this.tiposDelineamento = [];
        this.tipoOperacao = ICRUDOperacao_1.CRUDOperacao;
        this.listasFotos = new CampoIndicadorFotos_1.CampoIndicadorFotos($scope);
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.path = config_app_1.config.api.caminho;
            this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.LAB_EXPERIMENTOS;
            this.modalDetalhesLista = false;
            if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR ||
                this.operacao === ICRUDOperacao_1.CRUDOperacao.EDITAR ||
                this.operacao === ICRUDOperacao_1.CRUDOperacao.DUPLICAR) {
                this.tiposDelineamento = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TIPO_DELINEAMENTO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TIPO_DELINEAMENTO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.tiposDelineamento = resposta;
                    }
                });
            }
            this.$scope.$apply();
        });
    }
    salvar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.modalDetalhesLista = false;
                if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_EXPERIMENTOS)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_EXPERIMENTOS)
                        .criar(this.modelo);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_EXPERIMENTOS)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_EXPERIMENTOS)
                        .atualizar(this.modelo);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir um novo Experimento ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir um novo Experimento ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    visualizar(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const labExperimentos = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_EXPERIMENTOS)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_EXPERIMENTOS)
                    .id(id)
                    .ver();
                labExperimentos.criacao = new Date(labExperimentos.criacao);
                labExperimentos.data = new Date(labExperimentos.data);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return labExperimentos;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    remover(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const labExperimentos = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_EXPERIMENTOS)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_EXPERIMENTOS)
                    .id(id)
                    .remover();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (labExperimentos) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const labExperimentos = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_EXPERIMENTOS)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_EXPERIMENTOS)
                    .id(this.modelo.id)
                    .restaurar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (labExperimentos) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosLista(filtro = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                this.path = config_app_1.config.api.caminho;
                this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.LAB_EXPERIMENTOS;
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                var auxColunas = Array();
                auxColunas.push({
                    name: 'codigo',
                    displayName: 'Código',
                    width: 90,
                });
                auxColunas.push({
                    name: 'nomeExperimento',
                    displayName: 'Nome do experimento',
                    width: 140,
                });
                auxColunas.push({
                    name: 'material',
                    displayName: 'Material',
                    width: 140,
                });
                auxColunas.push({
                    name: 'tipoDelineamento.descricao',
                    displayName: 'Delineamento',
                    width: 140,
                });
                auxColunas.push({
                    name: 'criacao',
                    displayName: 'Data/Hora Cadastro',
                    cellFilter: 'date:"dd/MM/yyyy HH:mm:ss"',
                    width: 190,
                });
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
                const lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_EXPERIMENTOS)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_EXPERIMENTOS)
                    .filtro(filtro)
                    .listar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    filtrar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    limparFiltro() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    exibirModalDetalhesLista() {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.modelo === 'undefined' || !this.modelo) {
                return;
            }
            if (typeof this.modelo.detalhes === 'undefined' ||
                this.modelo.detalhes === null) {
                this.modelo.detalhes = new Array();
            }
            try {
                try {
                    this.posicaoTopoAntesModal = angular.element(window).scrollTop();
                }
                catch (ex) {
                    this.posicaoTopoAntesModal = null;
                    console.log(ex);
                }
                this.modalDetalhesLista = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalDetalhesLista() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalDetalhesLista = false;
            angular.element('body').css('overflow-y', '');
            if (typeof this.modelo !== 'undefined' &&
                this.modelo &&
                typeof this.modelo.detalhes !== 'undefined' &&
                this.modelo.detalhes) {
                this.modelo.detalhes = this.modelo.detalhes.filter((o) => (typeof o.tratamento !== 'undefined' && o.tratamento) || (typeof o.testemunha !== 'undefined' && o.testemunha) || (typeof o.variavel !== 'undefined' && o.variavel));
            }
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    moverDetalheParaCima(key) {
        if (typeof this.modelo === 'undefined' ||
            !this.modelo ||
            typeof this.modelo.detalhes === 'undefined' ||
            !this.modelo.detalhes) {
            return;
        }
        if (key != null && key >= 0 && key < this.modelo.detalhes.length - 1) {
            let oldKey = key;
            let newKey = key + 1;
            this.modelo.detalhes.splice(newKey, 0, this.modelo.detalhes.splice(oldKey, 1)[0]);
            this.scopeApply();
        }
    }
    moverDetalheParaBaixo(key) {
        if (typeof this.modelo === 'undefined' ||
            !this.modelo ||
            typeof this.modelo.detalhes === 'undefined' ||
            !this.modelo.detalhes) {
            return;
        }
        if (key != null && key >= 0 && key < this.modelo.detalhes.length - 1) {
            let oldKey = key;
            let newKey = key + 1;
            this.modelo.detalhes.splice(newKey, 0, this.modelo.detalhes.splice(oldKey, 1)[0]);
            this.scopeApply();
        }
    }
    adicionarDetalhe() {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.modelo === 'undefined' || !this.modelo) {
                return;
            }
            if (typeof this.modelo.detalhes === 'undefined' ||
                this.modelo.detalhes === null) {
                this.modelo.detalhes = new Array();
            }
            this.modelo.detalhes.push(new DetalheExperimento_1.DetalheExperimento());
            this.scopeApply();
        });
    }
    removerDetalhe(key) {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.modelo === 'undefined' || !this.modelo) {
                return;
            }
            if (typeof this.modelo.detalhes === 'undefined' ||
                this.modelo.detalhes === null) {
                this.modelo.detalhes = new Array();
            }
            if (typeof key !== 'undefined' &&
                key !== null &&
                this.modelo.detalhes.length > 0 &&
                key <= this.modelo.detalhes.length - 1) {
                this.modelo.detalhes.splice(key, 1);
                this.scopeApply();
            }
        });
    }
    verFoto(foto) {
        return __awaiter(this, void 0, void 0, function* () {
            DispositivoUtil_1.DispositivoUtil.verFoto(foto);
        });
    }
}
exports.LabExperimentosController = LabExperimentosController;
LabExperimentosController.$inject = ['$scope', '$injector'];
