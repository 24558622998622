"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelatorioPomar = void 0;
const Base_1 = require("./Base");
class RelatorioPomar extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.id = null;
        this.pomar = null;
        this.tipoRelatorio = null;
        this.dataRelatorio = null;
        this.filtroStatus = null;
        this.filtroGenero = null;
        this.filtroEspecie = null;
        this.filtroAno = null;
        this.filtroMaterialGenetico = null;
    }
}
exports.RelatorioPomar = RelatorioPomar;
