"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProducaoMudaClonalMinijardimAvaliacaoSobrevivencia = void 0;
const Base_1 = require("./Base");
class ProducaoMudaClonalMinijardimAvaliacaoSobrevivencia extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.data = null;
        this.materialGenetico = null;
        this.numCanaletao = null;
        this.numOriginalCepas = null;
        this.numCepasMortas = null;
        this.numCepasSobreviventes = null;
        this.observacao = null;
        this.minijardimEucalyptus = null;
        this.minijardimPinus = null;
    }
}
exports.ProducaoMudaClonalMinijardimAvaliacaoSobrevivencia = ProducaoMudaClonalMinijardimAvaliacaoSobrevivencia;
