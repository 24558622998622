"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProducaoMudasSeminal = void 0;
const Base_1 = require("./Base");
class ProducaoMudasSeminal extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.materialGenetico = null;
        this.especie = null;
        this.pomarOrigem = null;
        this.polinizacao = null;
        this.hibridacao = null;
        this.genitoraMatriz = null;
        this.genitor = null;
        this.hibrido = null;
        this.estoqueSementes = null;
        this.cruzamento = null;
        this.materialGeneticoId = null;
        this.materialGeneticoDescricao = null;
        this.especieDescricao = null;
        this.pomarOrigemDescricao = null;
        this.hibridacaoDescricao = null;
        this.genitoraMatrizDescricao = null;
        this.genitorDescricao = null;
    }
}
exports.ProducaoMudasSeminal = ProducaoMudasSeminal;
ProducaoMudasSeminal.POLINIZACAO_PA = 'PA';
ProducaoMudasSeminal.POLINIZACAO_PC = 'PC';
