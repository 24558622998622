"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdentificacaoController = void 0;
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const Generico_1 = require("../../../../../modelos/Generico");
const SincroniaFactory_1 = require("../../../../fabricas/SincroniaFactory");
const GenericoController_1 = require("./GenericoController");
class IdentificacaoController extends GenericoController_1.GenericoController {
    constructor($scope, $injector) {
        super($scope, $injector);
        this.importar = true;
        const filtro = new Generico_1.Generico();
        filtro.referencia = SincroniaConstantes_1.SincroniaConstantes.IDENTIFICACAO;
        this.filtrarGenerico(filtro)
            .exibirColunas(['nome'])
            .exibirCamposFormulario({ nome: true });
    }
    abrirPlanilhaModal($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.planilha.abrir($event, 'identificacao', true);
        });
    }
}
exports.IdentificacaoController = IdentificacaoController;
IdentificacaoController.$inject = ['$scope', '$injector', SincroniaFactory_1.SincroniaFactory.id];
