"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LuckysheetUtil = void 0;
const angular = require("angular");
const pako = require("pako");
const config_app_1 = require("../config/config.app");
const RotaAPIConstantes_1 = require("../constantes/RotaAPIConstantes");
const LuckysheetExportXLSX_1 = require("./LuckysheetExportXLSX");
const TextoUtil_1 = require("./TextoUtil");
const NotificacaoUtil_1 = require("./NotificacaoUtil");
const DataUtil_1 = require("./DataUtil");
class LuckysheetUtil {
    static exportXLSX(luckysheet) {
        let luckysheetExportXLSX = new LuckysheetExportXLSX_1.LuckysheetExportXLSX();
        if ((typeof luckysheet !== 'undefined') && luckysheet) {
            luckysheetExportXLSX.luckysheet = luckysheet;
        }
        else if ((typeof globalThis.luckysheet !== 'undefined') && globalThis.luckysheet) {
            luckysheetExportXLSX.luckysheet = globalThis.luckysheet;
        }
        luckysheetExportXLSX.execute();
    }
    static getCellValue(row, column, options = {}) {
        if ((typeof globalThis.luckysheet !== 'undefined') && globalThis.luckysheet &&
            (typeof globalThis.luckysheet.getCellValue !== 'undefined')) {
            try {
                return globalThis.luckysheet.getCellValue(row, column, options);
            }
            catch (ex) {
                let ignoraMensagemErro = false;
                if (ex && ex.message && (ex.message.indexOf('read properties of undefined') !== -1)) {
                    ignoraMensagemErro = true;
                }
                if (!ignoraMensagemErro) {
                    console.log('LuckysheetUtil.getCellValue() -> ' +
                        'Erro ao obter o valor da célula (' + row + ', ' + column + ').');
                    console.log(ex);
                }
            }
        }
        return null;
    }
    static buscaPosicaoMaterialGeneticoCroqui(fila, coluna) {
        if ((typeof globalThis.luckysheet === 'undefined') || !globalThis.luckysheet ||
            (typeof globalThis.luckysheet.getCellValue === 'undefined') ||
            (typeof fila === 'undefined') || (fila === null) || (typeof coluna === 'undefined') || (coluna === null)) {
            return null;
        }
        let posicao = { row: null, col: null, materialGenetico: null };
        let posicaoPrimeiraColuna = { achou: false, row: null, col: null, valor: null };
        let posicaoPrimeiraLinha = { achou: false, row: null, col: null, valor: null };
        for (let linhaIdx = 0; linhaIdx <= 50; linhaIdx++) {
            for (let colunaIdx = 0; colunaIdx <= 50; colunaIdx++) {
                let valor = globalThis.luckysheet.getCellValue(linhaIdx, colunaIdx);
                if ((typeof valor !== 'undefined') && valor) {
                    if ((typeof valor === 'number') || ((typeof valor === 'object') && (valor !== null)) || ((typeof valor === 'string') && (valor.trim().length > 0) && !isNaN(parseFloat(valor.trim())))) {
                        if (!posicaoPrimeiraColuna || !posicaoPrimeiraColuna.achou) {
                            posicaoPrimeiraColuna.achou = true;
                            posicaoPrimeiraColuna.row = linhaIdx;
                            posicaoPrimeiraColuna.col = colunaIdx;
                            posicaoPrimeiraColuna.valor = valor;
                            break;
                        }
                        else if (!posicaoPrimeiraLinha || !posicaoPrimeiraLinha.achou) {
                            posicaoPrimeiraLinha.achou = true;
                            posicaoPrimeiraLinha.row = linhaIdx;
                            posicaoPrimeiraLinha.col = colunaIdx;
                            posicaoPrimeiraLinha.valor = valor;
                            break;
                        }
                    }
                }
            }
            if (posicaoPrimeiraColuna && posicaoPrimeiraColuna.achou &&
                posicaoPrimeiraLinha && posicaoPrimeiraLinha.achou) {
                break;
            }
        }
        if (posicaoPrimeiraColuna && posicaoPrimeiraColuna.achou) {
            let colunaVal = coluna;
            let colunaNum = (typeof colunaVal === 'number') ? colunaVal : ((typeof colunaVal === 'string') ? parseFloat(colunaVal.trim()) : parseFloat(colunaVal));
            for (let colunaIdx = posicaoPrimeiraColuna.col; colunaIdx <= posicaoPrimeiraColuna.col + 250; colunaIdx++) {
                let valor = globalThis.luckysheet.getCellValue(posicaoPrimeiraColuna.row, colunaIdx);
                if ((typeof valor === 'number') || ((typeof valor === 'object') && (valor !== null)) || ((typeof valor === 'string') && (valor.trim().length > 0) && !isNaN(parseFloat(valor.trim())))) {
                    let valorNum = (typeof valor === 'number') ? valor : ((typeof valor === 'string') ? parseFloat(valor.trim()) : parseFloat(valor));
                    if (valorNum === colunaNum) {
                        posicao.col = colunaIdx;
                        break;
                    }
                }
            }
        }
        if (posicaoPrimeiraLinha && posicaoPrimeiraLinha.achou) {
            let linhaVal = fila;
            let linhaNum = (typeof linhaVal === 'number') ? linhaVal : ((typeof linhaVal === 'string') ? parseFloat(linhaVal.trim()) : parseFloat(linhaVal));
            for (let linhaIdx = posicaoPrimeiraLinha.row; linhaIdx <= posicaoPrimeiraLinha.row + 125; linhaIdx++) {
                let valor = globalThis.luckysheet.getCellValue(linhaIdx, posicaoPrimeiraLinha.col);
                if ((typeof valor === 'number') || ((typeof valor === 'object') && (valor !== null)) || ((typeof valor === 'string') && (valor.trim().length > 0) && !isNaN(parseFloat(valor.trim())))) {
                    let valorNum = (typeof valor === 'number') ? valor : ((typeof valor === 'string') ? parseFloat(valor.trim()) : parseFloat(valor));
                    if (valorNum === linhaNum) {
                        posicao.row = linhaIdx;
                        break;
                    }
                }
            }
        }
        if ((typeof posicao.row !== 'undefined') && (posicao.row !== null) &&
            (typeof posicao.col !== 'undefined') && (posicao.col !== null)) {
            posicao.materialGenetico = globalThis.luckysheet.getCellValue(posicao.row, posicao.col);
        }
        return posicao;
    }
    static materialGeneticoSelecionadoCroqui() {
        if ((typeof globalThis.luckysheet === 'undefined') || !globalThis.luckysheet ||
            (typeof globalThis.luckysheet.getCellValue === 'undefined') ||
            (typeof globalThis.luckysheet.getRange === 'undefined')) {
            return null;
        }
        let posicao = { row: null, col: null, fila: null, coluna: null, materialGenetico: null };
        let posicaoPrimeiraColuna = { achou: false, row: null, col: null, valor: null };
        let posicaoPrimeiraLinha = { achou: false, row: null, col: null, valor: null };
        let listaPosicoesSelecionadas = globalThis.luckysheet.getRange();
        if ((typeof listaPosicoesSelecionadas === 'undefined') || !listaPosicoesSelecionadas || (listaPosicoesSelecionadas.length === 0)) {
            return null;
        }
        let posicaoSelecionada = { achouRow: false, row: null, achouCol: false, col: null };
        angular.forEach(listaPosicoesSelecionadas, (itemRowCol) => {
            if (itemRowCol && (typeof itemRowCol.row !== 'undefined') && itemRowCol.row) {
                if (!posicaoSelecionada || !posicaoSelecionada.achouRow) {
                    angular.forEach(itemRowCol.row, (itemRow) => {
                        if ((typeof itemRow !== 'undefined') && (itemRow !== null) && (!posicaoSelecionada || !posicaoSelecionada.achouRow)) {
                            posicaoSelecionada.achouRow = true;
                            posicaoSelecionada.row = itemRow;
                        }
                    });
                }
            }
            if (itemRowCol && (typeof itemRowCol.column !== 'undefined') && itemRowCol.column) {
                if (!posicaoSelecionada || !posicaoSelecionada.achouCol) {
                    angular.forEach(itemRowCol.column, (itemCol) => {
                        if ((typeof itemCol !== 'undefined') && (itemCol !== null) && (!posicaoSelecionada || !posicaoSelecionada.achouCol)) {
                            posicaoSelecionada.achouCol = true;
                            posicaoSelecionada.col = itemCol;
                        }
                    });
                }
            }
        });
        if (!posicaoSelecionada || !posicaoSelecionada.achouRow || !posicaoSelecionada.achouCol) {
            return null;
        }
        for (let linhaIdx = 0; linhaIdx <= 50; linhaIdx++) {
            for (let colunaIdx = 0; colunaIdx <= 50; colunaIdx++) {
                let valor = globalThis.luckysheet.getCellValue(linhaIdx, colunaIdx);
                if ((typeof valor !== 'undefined') && valor) {
                    if ((typeof valor === 'number') || ((typeof valor === 'object') && (valor !== null)) || ((typeof valor === 'string') && (valor.trim().length > 0) && !isNaN(parseFloat(valor.trim())))) {
                        if (!posicaoPrimeiraColuna || !posicaoPrimeiraColuna.achou) {
                            posicaoPrimeiraColuna.achou = true;
                            posicaoPrimeiraColuna.row = linhaIdx;
                            posicaoPrimeiraColuna.col = colunaIdx;
                            posicaoPrimeiraColuna.valor = valor;
                            break;
                        }
                        else if (!posicaoPrimeiraLinha || !posicaoPrimeiraLinha.achou) {
                            posicaoPrimeiraLinha.achou = true;
                            posicaoPrimeiraLinha.row = linhaIdx;
                            posicaoPrimeiraLinha.col = colunaIdx;
                            posicaoPrimeiraLinha.valor = valor;
                            break;
                        }
                    }
                }
            }
            if (posicaoPrimeiraColuna && posicaoPrimeiraColuna.achou &&
                posicaoPrimeiraLinha && posicaoPrimeiraLinha.achou) {
                break;
            }
        }
        posicao.row = (typeof posicaoSelecionada.row !== 'undefined') ? posicaoSelecionada.row : null;
        posicao.col = (typeof posicaoSelecionada.col !== 'undefined') ? posicaoSelecionada.col : null;
        if (posicaoPrimeiraColuna && posicaoPrimeiraColuna.achou) {
            if ((typeof posicaoPrimeiraColuna.col === 'undefined') || (posicaoPrimeiraColuna.col === null)) {
                posicaoPrimeiraColuna.col = 0;
            }
            if ((typeof posicaoSelecionada.col === 'undefined') || (posicaoSelecionada.col === null)) {
                posicaoSelecionada.col = 0;
            }
            if (posicaoSelecionada.col >= posicaoPrimeiraColuna.col) {
                let valor = globalThis.luckysheet.getCellValue(posicaoPrimeiraColuna.row, posicaoSelecionada.col);
                if ((typeof valor === 'number') || ((typeof valor === 'object') && (valor !== null)) || ((typeof valor === 'string') && (valor.trim().length > 0) && !isNaN(parseFloat(valor.trim())))) {
                    let valorNum = (typeof valor === 'number') ? valor : ((typeof valor === 'string') ? parseFloat(valor.trim()) : parseFloat(valor));
                    posicao.col = posicaoSelecionada.col;
                    posicao.coluna = valorNum;
                }
                else if ((posicaoSelecionada.col - 1) >= posicaoPrimeiraColuna.col) {
                    valor = globalThis.luckysheet.getCellValue(posicaoPrimeiraColuna.row, posicaoSelecionada.col - 1);
                    if ((typeof valor === 'number') || ((typeof valor === 'object') && (valor !== null)) || ((typeof valor === 'string') && (valor.trim().length > 0) && !isNaN(parseFloat(valor.trim())))) {
                        let valorNum2 = (typeof valor === 'number') ? valor : ((typeof valor === 'string') ? parseFloat(valor.trim()) : parseFloat(valor));
                        posicao.col = posicaoSelecionada.col - 1;
                        posicao.coluna = valorNum2;
                    }
                    else if ((posicaoSelecionada.col - 2) >= posicaoPrimeiraColuna.col) {
                        valor = globalThis.luckysheet.getCellValue(posicaoPrimeiraColuna.row, posicaoSelecionada.col - 2);
                        if ((typeof valor === 'number') || ((typeof valor === 'object') && (valor !== null)) || ((typeof valor === 'string') && (valor.trim().length > 0) && !isNaN(parseFloat(valor.trim())))) {
                            let valorNum2 = (typeof valor === 'number') ? valor : ((typeof valor === 'string') ? parseFloat(valor.trim()) : parseFloat(valor));
                            posicao.col = posicaoSelecionada.col - 2;
                            posicao.coluna = valorNum2;
                        }
                    }
                }
            }
        }
        if (posicaoPrimeiraLinha && posicaoPrimeiraLinha.achou) {
            if ((typeof posicaoPrimeiraLinha.row === 'undefined') || (posicaoPrimeiraLinha.row === null)) {
                posicaoPrimeiraLinha.row = 0;
            }
            if ((typeof posicaoSelecionada.row === 'undefined') || (posicaoSelecionada.row === null)) {
                posicaoSelecionada.row = 0;
            }
            if (posicaoSelecionada.row >= posicaoPrimeiraLinha.row) {
                let valor = globalThis.luckysheet.getCellValue(posicaoSelecionada.row, posicaoPrimeiraLinha.col);
                if ((typeof valor === 'number') || ((typeof valor === 'object') && (valor !== null)) || ((typeof valor === 'string') && (valor.trim().length > 0) && !isNaN(parseFloat(valor.trim())))) {
                    let valorNum = (typeof valor === 'number') ? valor : ((typeof valor === 'string') ? parseFloat(valor.trim()) : parseFloat(valor));
                    posicao.row = posicaoSelecionada.row;
                    posicao.fila = valorNum;
                }
            }
        }
        if ((typeof posicao.row !== 'undefined') && (posicao.row !== null) &&
            (typeof posicao.col !== 'undefined') && (posicao.col !== null)) {
            posicao.materialGenetico = globalThis.luckysheet.getCellValue(posicao.row, posicao.col);
        }
        return posicao;
    }
    static carregarPlanilha(container, dados = null, anexo = null, tentativa = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const maxTentativas = 5;
            let luckysheetOptions = {
                container: container,
                title: null,
                data: [],
                showinfobar: false,
                userInfo: false,
                userMenuItem: [],
                myFolderUrl: '',
                plugins: [{ name: 'chart' }, { name: 'print' }],
                curentsheetView: 'viewNormal',
                sheetViewZoom: {
                    viewNormalZoomScale: 1,
                    viewLayoutZoomScale: 1,
                    viewPageZoomScale: 0.6,
                },
                printoptions: {
                    unit: 'mm',
                    PrintArea: '$A$1:$S$31',
                    PrintTitles: {
                        row: 'Sheet1!$1:$1',
                        column: 'Sheet1!$C:$C'
                    },
                    printOptions: {
                        horizontalCentered: 0,
                        verticalCentered: 0,
                        headings: 0,
                        gridLines: 0,
                    },
                    pageMargins: {
                        left: 0.7,
                        right: 0.7,
                        top: 0.75,
                        bottom: 0.75,
                        header: 0.3,
                        footer: 0.3,
                    },
                    pageSetup: {
                        copies: 1,
                        draft: 0,
                        paperSize: 9,
                        paperHeight: null,
                        paperWidth: null,
                        fitToWidth: 0,
                        fitToHeight: 0,
                        scale: 100,
                        orientation: 0,
                        blackAndWhite: 0,
                        cellComments: 0,
                        errors: 0,
                        horizontalDpi: null,
                        verticalDpi: null,
                        pageOrder: 0,
                        firstPageNumber: null,
                        useFirstPageNumber: 0,
                        usePrinterDefaults: 1,
                    },
                    headerFooter: {
                        firstFooter: {
                            left: [
                                {
                                    ff: 'Arial',
                                    fc: '#fff000',
                                    fs: 12,
                                    cl: 0,
                                    un: 0,
                                    bl: 0,
                                    it: 0,
                                    ss: 0,
                                    v: 'ANI Sistemas'
                                }
                            ],
                            center: [],
                            right: [],
                        },
                        firstHeader: null,
                        oddFooter: '&C Página &P 页&R&G',
                        oddHeader: '&L&G&C&A&F',
                        evenFooter: null,
                        evenHeader: null,
                        drawingHF: {
                            LF: {
                                type: '#_x0000_t75',
                                imagedata: '',
                                style: ''
                            },
                            RF: {},
                            CF: {},
                            LH: {},
                            RH: {},
                            CH: {},
                        },
                    },
                }
            };
            if (dados && angular.isArray(dados) && (dados.length > 0)) {
                try {
                    if ((typeof globalThis.luckysheet !== 'undefined') && globalThis.luckysheet) {
                        luckysheetOptions.data = dados;
                        if ((typeof luckysheetOptions.data === 'undefined') || (luckysheetOptions.data === null) || (angular.isArray(luckysheetOptions.data) && (luckysheetOptions.data.length === 0))) {
                            luckysheetOptions.data = [{
                                    name: 'Planilha 1',
                                    status: 1,
                                    total: 0,
                                    order: 0,
                                    data: []
                                }];
                        }
                        globalThis.luckysheet.create(luckysheetOptions);
                    }
                    else {
                        throw new Error('Plugin "luckysheet" não carregado.');
                    }
                }
                catch (ex) {
                    const tentativaAtual = (((typeof tentativa !== 'undefined') && tentativa) ? tentativa : 1);
                    console.log('LuckysheetUtil.carregarPlanilha() #1 > ERROR (tentativa ' + tentativaAtual + '):');
                    console.log(ex);
                    if (tentativa <= maxTentativas) {
                        setTimeout(() => __awaiter(this, void 0, void 0, function* () {
                            LuckysheetUtil.carregarPlanilha(container, dados, anexo, tentativaAtual + 1);
                        }), 1000);
                        return;
                    }
                    NotificacaoUtil_1.NotificacaoUtil.excecaoObj(ex);
                    return;
                }
            }
            else if (anexo && anexo.id) {
                let urlAnexo = config_app_1.config.api.caminho + RotaAPIConstantes_1.RotaAPIConstantes.LOAD + `/${anexo.id}`;
                let nomeAnexo = ((typeof anexo.nome !== 'undefined') && anexo.nome) ? anexo.nome
                    : ((typeof anexo.valor !== 'undefined') && anexo.valor ? anexo.valor : '0');
                try {
                    if ((typeof globalThis.LuckyExcel !== 'undefined') && globalThis.LuckyExcel) {
                        globalThis.LuckyExcel.transformExcelToLuckyByUrl(urlAnexo, nomeAnexo, function (exportJson, _luckysheetfile) {
                            if (exportJson.sheets === null || exportJson.sheets.length === 0) {
                                const msgErro = 'Falha ao ler o conteúdo do arquivo Excel, somente arquivos \'xlsx\' são suportados!';
                                NotificacaoUtil_1.NotificacaoUtil.erro('Arquivo não suportado!', msgErro);
                                throw new Error(msgErro);
                            }
                            try {
                                if ((typeof globalThis.luckysheet !== 'undefined') && globalThis.luckysheet) {
                                    luckysheetOptions.data = exportJson.sheets ? exportJson.sheets : [];
                                    if ((typeof luckysheetOptions.data === 'undefined') || (luckysheetOptions.data === null) || (angular.isArray(luckysheetOptions.data) && (luckysheetOptions.data.length === 0))) {
                                        luckysheetOptions.data = [{
                                                name: 'Planilha 1',
                                                status: 1,
                                                total: 0,
                                                order: 0,
                                                data: []
                                            }];
                                    }
                                    if (exportJson.info && exportJson.info.name) {
                                        luckysheetOptions.title = exportJson.info.name;
                                    }
                                    if (exportJson.info && exportJson.info.name && exportJson.info.name.creator) {
                                        luckysheetOptions.userInfo = exportJson.info.name.creator;
                                    }
                                    globalThis.luckysheet.create(luckysheetOptions);
                                }
                                else {
                                    throw new Error('Plugin "luckysheet" não carregado.');
                                }
                            }
                            catch (ex) {
                                const tentativaAtual = (((typeof tentativa !== 'undefined') && tentativa) ? tentativa : 1);
                                console.log('LuckysheetUtil.carregarPlanilha() #2 > ERROR (tentativa ' + tentativaAtual + '):');
                                console.log(ex);
                                if (tentativa <= maxTentativas) {
                                    setTimeout(() => __awaiter(this, void 0, void 0, function* () {
                                        LuckysheetUtil.carregarPlanilha(container, dados, anexo, tentativaAtual + 1);
                                    }), 1000);
                                    return;
                                }
                                NotificacaoUtil_1.NotificacaoUtil.excecaoObj(ex);
                            }
                        });
                    }
                    else {
                        throw new Error('Plugin "LuckyExcel" não carregado.');
                    }
                }
                catch (ex) {
                    const tentativaAtual = (((typeof tentativa !== 'undefined') && tentativa) ? tentativa : 1);
                    console.log('LuckysheetUtil.carregarPlanilha() #3 > ERROR (tentativa ' + tentativaAtual + '):');
                    console.log(ex);
                    if (tentativa <= maxTentativas) {
                        setTimeout(() => __awaiter(this, void 0, void 0, function* () {
                            LuckysheetUtil.carregarPlanilha(container, dados, anexo, tentativaAtual + 1);
                        }), 1000);
                        return;
                    }
                    NotificacaoUtil_1.NotificacaoUtil.excecaoObj(ex);
                    return;
                }
            }
            else {
                try {
                    if ((typeof globalThis.luckysheet !== 'undefined') && globalThis.luckysheet) {
                        luckysheetOptions.data = [{
                                name: 'Planilha 1',
                                status: 1,
                                total: 0,
                                order: 0,
                                data: []
                            }];
                        globalThis.luckysheet.create(luckysheetOptions);
                    }
                    else {
                        throw new Error('Plugin "luckysheet" não carregado.');
                    }
                }
                catch (ex) {
                    const tentativaAtual = (((typeof tentativa !== 'undefined') && tentativa) ? tentativa : 1);
                    console.log('LuckysheetUtil.carregarPlanilha() #4 > ERROR (tentativa ' + tentativaAtual + '):');
                    console.log(ex);
                    if (tentativa <= maxTentativas) {
                        setTimeout(() => __awaiter(this, void 0, void 0, function* () {
                            LuckysheetUtil.carregarPlanilha(container, dados, anexo, tentativaAtual + 1);
                        }), 1000);
                        return;
                    }
                    NotificacaoUtil_1.NotificacaoUtil.excecaoObj(ex);
                    return;
                }
            }
        });
    }
    static redimensionarPlanilha() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof globalThis.timeoutResizeLuckysheet !== 'undefined') && (globalThis.timeoutResizeLuckysheet !== null)) {
                clearTimeout(globalThis.timeoutResizeLuckysheet);
                globalThis.timeoutResizeLuckysheet = null;
            }
            globalThis.timeoutResizeLuckysheet = setTimeout(() => {
                if ((typeof globalThis.luckysheet !== 'undefined') && globalThis.luckysheet) {
                    try {
                        globalThis.luckysheet.resize();
                    }
                    catch (ex) {
                        console.log(ex);
                        setTimeout(() => {
                            LuckysheetUtil.redimensionarPlanilha();
                        }, 1000);
                    }
                    setTimeout(() => {
                        if (angular.element('.luckysheet-wa-editor.toolbar').length > 0) {
                            angular.element('.luckysheet-wa-editor.toolbar')
                                .css('white-space', 'normal')
                                .css('overflow-y', 'auto');
                        }
                        else {
                            setTimeout(() => {
                                angular.element('.luckysheet-wa-editor.toolbar')
                                    .css('white-space', 'normal')
                                    .css('overflow-y', 'auto');
                            }, 500);
                        }
                    }, 200);
                }
            }, 300);
        });
    }
    static nomePlanilha(luckysheet) {
        let nome = null;
        if ((typeof luckysheet !== 'undefined') && luckysheet &&
            (typeof luckysheet.getWorkbookName !== 'undefined')) {
            nome = luckysheet.getWorkbookName();
            if (nome && (nome.toLowerCase().indexOf('luckysheet') !== -1)) {
                nome = null;
            }
        }
        if (((nome && (nome.length === 0)) || !nome) &&
            (typeof globalThis.luckysheet !== 'undefined') && globalThis.luckysheet) {
            nome = globalThis.luckysheet.getWorkbookName();
            if (nome && (nome.toLowerCase().indexOf('luckysheet') !== -1)) {
                nome = null;
            }
        }
        if ((nome && (nome.length === 0)) || !nome) {
            const dataHoraAtual = DataUtil_1.DataUtil.dataHoraAtual();
            nome = 'Planilha ' + DataUtil_1.DataUtil.converterDataParaFormatoDDMMYYYY(dataHoraAtual) + ' ' +
                DataUtil_1.DataUtil.converterDataParaFormatoHHMMSS(dataHoraAtual);
        }
        return nome;
    }
    static compactarDadosPlanilha(luckysheet) {
        let dadosJson = null;
        if ((typeof luckysheet !== 'undefined') && luckysheet &&
            (typeof luckysheet.getluckysheetfile !== 'undefined')) {
            dadosJson = JSON.stringify(luckysheet.getluckysheetfile());
        }
        else if ((typeof globalThis.luckysheet !== 'undefined') && globalThis.luckysheet) {
            dadosJson = JSON.stringify(globalThis.luckysheet.getluckysheetfile());
        }
        if ((typeof dadosJson !== 'undefined') && dadosJson) {
            const dadosGz = pako.deflateRaw(dadosJson, { level: 9 });
            const dadosStr = TextoUtil_1.TextoUtil.Uint8ToString(dadosGz);
            return Buffer.from(dadosStr, 'binary').toString('base64');
        }
        return null;
    }
    static descompactarDadosPlanilha(dados) {
        if ((typeof dados !== 'undefined') && (dados !== null)) {
            const obj = JSON.parse(pako.inflateRaw(Buffer.from(dados, 'base64'), { to: 'string' }));
            return (typeof obj === 'string') ? JSON.parse(obj) : obj;
        }
        return null;
    }
}
exports.LuckysheetUtil = LuckysheetUtil;
