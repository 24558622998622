"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProducaoMudaClonalEucalyptusResgateMacroestacasAdubacao = void 0;
const Base_1 = require("./Base");
class ProducaoMudaClonalEucalyptusResgateMacroestacasAdubacao extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.data = null;
        this.quantidade = null;
        this.produtoFertilizante = null;
        this.dosagem = null;
        this.contaDepositoSap = null;
        this.centroCustoSap = null;
        this.clonalEucalyptus = null;
        this.cloneMuda = null;
    }
}
exports.ProducaoMudaClonalEucalyptusResgateMacroestacasAdubacao = ProducaoMudaClonalEucalyptusResgateMacroestacasAdubacao;
