"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuUtil = void 0;
const angular = require("angular");
const config_app_1 = require("../config/config.app");
const GrupoPermissoesUsuario_1 = require("../io/GrupoPermissoesUsuario");
const GrupoPermissoesConstantes_1 = require("../constantes/GrupoPermissoesConstantes");
const DispositivoUtil_1 = require("./DispositivoUtil");
class MenuUtil {
    static ajustaMenus(usuarioLogado) {
        const ulMenu = angular.element('ul#main-menu-navigation');
        if (!ulMenu || (ulMenu.length === 0)) {
            return;
        }
        if (!usuarioLogado) {
            ulMenu.find('#itemMenuBusca').remove();
            ulMenu.find('li.nav-item[data-permissao]').remove();
            return;
        }
        let contadorItensPermitidosMenu = 0;
        let gruposPermissoes = new Array();
        gruposPermissoes.push(...GrupoPermissoesConstantes_1.GrupoPermissoesConstantes.TODOS_GRUPOS_PERMISSOES);
        const gruposPermissoesInverso = [...gruposPermissoes].reverse();
        angular.forEach(gruposPermissoesInverso, (grupo) => {
            let grupoVisivel = true;
            if ((typeof grupo.permissaoGrupo !== 'undefined') && grupo.permissaoGrupo) {
                if (grupo.permissaoGrupo.toLowerCase().indexOf('sincronizacao') !== -1) {
                    if (!DispositivoUtil_1.DispositivoUtil.ehCelular()) {
                        grupoVisivel = false;
                    }
                }
            }
            if (grupoVisivel) {
                let liGrupo = ulMenu.find(`li.nav-item[data-permissao='${grupo.permissaoGrupo}']`);
                let contemSubGrupos = false;
                let rotaGrupo = '';
                let permissaoGrupo = null;
                let ehGrupoPermitido = true;
                if ((typeof grupo.subGruposPermissoes !== 'undefined') && grupo.subGruposPermissoes && (grupo.subGruposPermissoes.length > 0)) {
                    contemSubGrupos = true;
                    if (grupo.subGruposPermissoes.length === 1) {
                        if ((typeof grupo.subGruposPermissoes[0].padrao !== 'undefined') && grupo.subGruposPermissoes[0].padrao) {
                            let urlGrupo = '';
                            if ((typeof globalThis.currentState !== 'undefined') && globalThis.currentState) {
                                urlGrupo = 'href="' + globalThis.currentState.href(grupo.subGruposPermissoes[0].rota, {}, { absolute: true }) + '"';
                            }
                            if (grupo.subGruposPermissoes[0].rota && (grupo.subGruposPermissoes[0].rota.indexOf('https://app.powerbi.com') !== -1)) {
                                rotaGrupo = `href="${grupo.subGruposPermissoes[0].rota}" target="_blank"`;
                            }
                            else {
                                rotaGrupo = `ui-sref="${grupo.subGruposPermissoes[0].rota}" ${urlGrupo} onclick="if(window.currentState){ if(event){event.preventDefault();} window.currentState.go('${grupo.subGruposPermissoes[0].rota}'); if(angular.element(document.body).hasClass('vertical-overlay-menu')){ $.app.menu.hide(); angular.element(document.body).removeClass('menu-open').removeClass('menu-show').addClass('menu-hide').css('overflow','auto'); angular.element('.nav-link.nav-menu-main.menu-toggle').removeClass('is-active'); window.mainMenuNavigationCalcHeight(500); }}"`;
                            }
                            contemSubGrupos = false;
                            if ((typeof grupo.subGruposPermissoes[0].rota !== 'undefined') && grupo.subGruposPermissoes[0].rota &&
                                (typeof grupo.subGruposPermissoes[0].permissoesHabilitadas !== 'undefined') && grupo.subGruposPermissoes[0].permissoesHabilitadas &&
                                (typeof grupo.subGruposPermissoes[0].permissoesHabilitadas.acessar !== 'undefined') && grupo.subGruposPermissoes[0].permissoesHabilitadas.acessar) {
                                permissaoGrupo = GrupoPermissoesUsuario_1.GrupoPermissoesUsuario.extrairPermissaoModuloRota(grupo.subGruposPermissoes[0].rota);
                            }
                            if (permissaoGrupo && (permissaoGrupo.toLowerCase().indexOf('sincronizacao') !== -1)) {
                                ehGrupoPermitido = true;
                            }
                            else if (permissaoGrupo && (typeof usuarioLogado !== 'undefined') && (usuarioLogado !== null)) {
                                ehGrupoPermitido = ((typeof usuarioLogado[permissaoGrupo] !== 'undefined') && (usuarioLogado[permissaoGrupo] !== null) && (usuarioLogado[permissaoGrupo]));
                            }
                            else {
                                ehGrupoPermitido = false;
                            }
                        }
                    }
                }
                ehGrupoPermitido = true;
                if (ehGrupoPermitido) {
                    if (!liGrupo || (liGrupo.length === 0)) {
                        if (!rotaGrupo || (rotaGrupo.length === 0)) {
                            rotaGrupo = `onclick="window.mainMenuNavigationCalcHeight(500);"`;
                        }
                        ulMenu.prepend(`
                            <li class="nav-item ${contemSubGrupos ? 'has-sub' : ''}" data-permissao="${grupo.permissaoGrupo}">
                                <a ${rotaGrupo}>
                                    <i class="${grupo.iconeGrupo}"></i>
                                    <span class="menu-title" data-i18n="${grupo.tituloGrupo.replace(/<br>/g, '')}">${grupo.tituloGrupo}</span>
                                </a>
                            </li>`);
                        liGrupo = ulMenu.find(`li.nav-item[data-permissao='${grupo.permissaoGrupo}']`);
                    }
                    if (!contemSubGrupos) {
                        contadorItensPermitidosMenu++;
                    }
                    if ((typeof grupo.subGruposPermissoes !== 'undefined') && grupo.subGruposPermissoes && contemSubGrupos) {
                        let ulConteudoGrupo = liGrupo.find('ul.menu-content');
                        if (!ulConteudoGrupo || (ulConteudoGrupo.length === 0)) {
                            liGrupo.append('<ul class="menu-content"></ul>');
                            ulConteudoGrupo = liGrupo.find('ul.menu-content');
                        }
                        let contadorTotalSubGrupos = 0;
                        let contadorPermissaoSubGrupos = 0;
                        const subGruposPermissoesInverso = [...grupo.subGruposPermissoes].reverse();
                        angular.forEach(subGruposPermissoesInverso, (subGrupo) => {
                            contadorTotalSubGrupos++;
                            contadorItensPermitidosMenu++;
                            let permissaoSubGrupo = null;
                            if ((typeof subGrupo.rota !== 'undefined') && subGrupo.rota &&
                                (typeof subGrupo.permissoesHabilitadas !== 'undefined') && subGrupo.permissoesHabilitadas &&
                                (typeof subGrupo.permissoesHabilitadas.acessar !== 'undefined') && subGrupo.permissoesHabilitadas.acessar) {
                                permissaoSubGrupo = GrupoPermissoesUsuario_1.GrupoPermissoesUsuario.extrairPermissaoModuloRota(subGrupo.rota);
                            }
                            if (permissaoSubGrupo) {
                                const possuiPermissaoSubGrupo = true;
                                if (possuiPermissaoSubGrupo) {
                                    contadorPermissaoSubGrupos++;
                                    if ((typeof subGrupo.parametrosRota === 'undefined') || !subGrupo.parametrosRota) {
                                        subGrupo.parametrosRota = {};
                                    }
                                    let liSubGrupo = ulConteudoGrupo.find(`li[data-rota="${subGrupo.rota}"]`);
                                    if (!liSubGrupo || (liSubGrupo.length === 0)) {
                                        let urlSubGrupo = '';
                                        if ((typeof globalThis.currentState !== 'undefined') && globalThis.currentState) {
                                            urlSubGrupo = globalThis.currentState.href(subGrupo.rota, subGrupo.parametrosRota, { absolute: true });
                                            if ((urlSubGrupo !== null) && (urlSubGrupo.trim().length > 0)) {
                                                urlSubGrupo = 'href="' + urlSubGrupo + '"';
                                            }
                                            else {
                                                urlSubGrupo = '';
                                            }
                                        }
                                        ulConteudoGrupo.prepend(`
                                            <li data-rota="${subGrupo.rota}" ` + (permissaoSubGrupo ? (' data-permissao="' + permissaoSubGrupo + '"') : '') + `>
                                                <a ui-sref="${subGrupo.rota}" ${urlSubGrupo} onclick="if(window.currentState){if(event){event.preventDefault();}window.currentState.go('${subGrupo.rota}',${JSON.stringify(subGrupo.parametrosRota).replace(/"/g, '\'')}); if(angular.element(document.body).hasClass('vertical-overlay-menu')){ $.app.menu.hide(); angular.element(document.body).removeClass('menu-open').removeClass('menu-show').addClass('menu-hide').css('overflow','auto'); angular.element('.nav-link.nav-menu-main.menu-toggle').removeClass('is-active'); window.mainMenuNavigationCalcHeight(500); }}">
                                                    <i class="bx bx-right-arrow-alt"></i>
                                                    <span class="menu-item" data-i18n="${subGrupo.titulo.replace(/<br>/g, '')}">
                                                        ${subGrupo.titulo}
                                                    </span>
                                                </a>
                                            </li>`);
                                        liSubGrupo = ulConteudoGrupo.find(`li[data-rota='${subGrupo.rota}']`);
                                    }
                                }
                            }
                            if ((typeof subGrupo.permissaoGrupo !== 'undefined') && subGrupo.permissaoGrupo &&
                                (typeof subGrupo.subGruposPermissoes !== 'undefined') && subGrupo.subGruposPermissoes && (subGrupo.subGruposPermissoes.length > 0)) {
                                let liGrupoN1 = ulConteudoGrupo.find(`li.nav-item[data-permissao='${subGrupo.permissaoGrupo}']`);
                                if (!liGrupoN1 || (liGrupoN1.length === 0)) {
                                    ulConteudoGrupo.prepend(`
                                        <li class="nav-item has-sub" data-permissao="${subGrupo.permissaoGrupo}">
                                            <a onclick="window.mainMenuNavigationCalcHeight(500);">
                                                <i class="bx bx-right-arrow-alt"></i>
                                                <span class="menu-title" data-i18n="${subGrupo.titulo.replace(/<br>/g, '')}">${subGrupo.titulo}</span>
                                            </a>
                                        </li>`);
                                    liGrupoN1 = ulConteudoGrupo.find(`li.nav-item[data-permissao='${subGrupo.permissaoGrupo}']`);
                                }
                                let ulConteudoGrupoN1 = liGrupoN1.find('ul.menu-content');
                                if (!ulConteudoGrupoN1 || (ulConteudoGrupoN1.length === 0)) {
                                    liGrupoN1.append('<ul class="menu-content"></ul>');
                                    ulConteudoGrupoN1 = liGrupoN1.find('ul.menu-content');
                                }
                                const subGrupoN2sPermissoesInversoN2 = [...subGrupo.subGruposPermissoes].reverse();
                                angular.forEach(subGrupoN2sPermissoesInversoN2, (subGrupoN2) => {
                                    contadorTotalSubGrupos++;
                                    contadorItensPermitidosMenu++;
                                    let permissaoSubGrupoN2 = null;
                                    if ((typeof subGrupoN2.rota !== 'undefined') && subGrupoN2.rota &&
                                        (typeof subGrupoN2.permissoesHabilitadas !== 'undefined') && subGrupoN2.permissoesHabilitadas &&
                                        (typeof subGrupoN2.permissoesHabilitadas.acessar !== 'undefined') && subGrupoN2.permissoesHabilitadas.acessar) {
                                        permissaoSubGrupoN2 = GrupoPermissoesUsuario_1.GrupoPermissoesUsuario.extrairPermissaoModuloRota(subGrupoN2.rota);
                                    }
                                    if (permissaoSubGrupoN2) {
                                        const possuiPermissaoSubGrupoN2 = true;
                                        if (possuiPermissaoSubGrupoN2) {
                                            contadorPermissaoSubGrupos++;
                                            if ((typeof subGrupoN2.parametrosRota === 'undefined') || !subGrupoN2.parametrosRota) {
                                                subGrupoN2.parametrosRota = {};
                                            }
                                            let liSubGrupo = ulConteudoGrupoN1.find(`li[data-rota="${subGrupoN2.rota}"]`);
                                            if (!liSubGrupo || (liSubGrupo.length === 0)) {
                                                let urlSubGrupo = '';
                                                if ((typeof globalThis.currentState !== 'undefined') && globalThis.currentState) {
                                                    urlSubGrupo = globalThis.currentState.href(subGrupoN2.rota, subGrupoN2.parametrosRota, { absolute: true });
                                                    if ((urlSubGrupo !== null) && (urlSubGrupo.trim().length > 0)) {
                                                        urlSubGrupo = 'href="' + urlSubGrupo + '"';
                                                    }
                                                    else {
                                                        urlSubGrupo = '';
                                                    }
                                                }
                                                ulConteudoGrupoN1.prepend(`
                                                    <li data-rota="${subGrupoN2.rota}" ` + (permissaoSubGrupoN2 ? (' data-permissao="' + permissaoSubGrupoN2 + '"') : '') + `>
                                                        <a ui-sref="${subGrupoN2.rota}" ${urlSubGrupo} onclick="if(window.currentState){if(event){event.preventDefault();}window.currentState.go('${subGrupoN2.rota}',${JSON.stringify(subGrupoN2.parametrosRota).replace(/"/g, '\'')}); if(angular.element(document.body).hasClass('vertical-overlay-menu')){ $.app.menu.hide(); angular.element(document.body).removeClass('menu-open').removeClass('menu-show').addClass('menu-hide').css('overflow','auto'); angular.element('.nav-link.nav-menu-main.menu-toggle').removeClass('is-active'); window.mainMenuNavigationCalcHeight(500); }}">
                                                            <i class="bx bx-right-arrow-alt"></i>
                                                            <span class="menu-item" data-i18n="${subGrupoN2.titulo.replace(/<br>/g, '')}">
                                                                ${subGrupoN2.titulo}
                                                            </span>
                                                        </a>
                                                    </li>`);
                                                liSubGrupo = ulConteudoGrupoN1.find(`li[data-rota='${subGrupoN2.rota}']`);
                                            }
                                        }
                                    }
                                    if ((typeof subGrupoN2.permissaoGrupo !== 'undefined') && subGrupoN2.permissaoGrupo &&
                                        (typeof subGrupoN2.subGruposPermissoes !== 'undefined') && subGrupoN2.subGruposPermissoes && (subGrupoN2.subGruposPermissoes.length > 0)) {
                                        let liGrupoN2 = ulConteudoGrupoN1.find(`li.nav-item[data-permissao='${subGrupoN2.permissaoGrupo}']`);
                                        if (!liGrupoN2 || (liGrupoN2.length === 0)) {
                                            ulConteudoGrupoN1.prepend(`
                                                <li class="nav-item has-sub" data-permissao="${subGrupoN2.permissaoGrupo}">
                                                    <a onclick="window.mainMenuNavigationCalcHeight(500);">
                                                        <i class="bx bx-right-arrow-alt"></i>
                                                        <span class="menu-title" data-i18n="${subGrupoN2.titulo.replace(/<br>/g, '')}">${subGrupoN2.titulo}</span>
                                                    </a>
                                                </li>`);
                                            liGrupoN2 = ulConteudoGrupoN1.find(`li.nav-item[data-permissao='${subGrupoN2.permissaoGrupo}']`);
                                        }
                                        let ulConteudoGrupoN2 = liGrupoN2.find('ul.menu-content');
                                        if (!ulConteudoGrupoN2 || (ulConteudoGrupoN2.length === 0)) {
                                            liGrupoN2.append('<ul class="menu-content"></ul>');
                                            ulConteudoGrupoN2 = liGrupoN2.find('ul.menu-content');
                                        }
                                        const subGruposPermissoesInversoN3 = [...subGrupoN2.subGruposPermissoes].reverse();
                                        angular.forEach(subGruposPermissoesInversoN3, (subGrupoN3) => {
                                            contadorTotalSubGrupos++;
                                            contadorItensPermitidosMenu++;
                                            let permissaoSubGrupoN3 = null;
                                            if ((typeof subGrupoN3.rota !== 'undefined') && subGrupoN3.rota &&
                                                (typeof subGrupoN3.permissoesHabilitadas !== 'undefined') && subGrupoN3.permissoesHabilitadas &&
                                                (typeof subGrupoN3.permissoesHabilitadas.acessar !== 'undefined') && subGrupoN3.permissoesHabilitadas.acessar) {
                                                permissaoSubGrupoN3 = GrupoPermissoesUsuario_1.GrupoPermissoesUsuario.extrairPermissaoModuloRota(subGrupoN3.rota);
                                            }
                                            if (permissaoSubGrupoN3) {
                                                const possuiPermissaoSubGrupoN3 = true;
                                                if (possuiPermissaoSubGrupoN3) {
                                                    contadorPermissaoSubGrupos++;
                                                    if ((typeof subGrupoN3.parametrosRota === 'undefined') || !subGrupoN3.parametrosRota) {
                                                        subGrupoN3.parametrosRota = {};
                                                    }
                                                    let liSubGrupo = ulConteudoGrupoN2.find(`li[data-rota="${subGrupoN3.rota}"]`);
                                                    if (!liSubGrupo || (liSubGrupo.length === 0)) {
                                                        let urlSubGrupo = '';
                                                        if ((typeof globalThis.currentState !== 'undefined') && globalThis.currentState) {
                                                            urlSubGrupo = globalThis.currentState.href(subGrupoN3.rota, subGrupoN3.parametrosRota, { absolute: true });
                                                            if ((urlSubGrupo !== null) && (urlSubGrupo.trim().length > 0)) {
                                                                urlSubGrupo = 'href="' + urlSubGrupo + '"';
                                                            }
                                                            else {
                                                                urlSubGrupo = '';
                                                            }
                                                        }
                                                        ulConteudoGrupoN2.prepend(`
                                                            <li data-rota="${subGrupoN3.rota}" ` + (permissaoSubGrupoN3 ? (' data-permissao="' + permissaoSubGrupoN3 + '"') : '') + `>
                                                                <a ui-sref="${subGrupoN3.rota}" ${urlSubGrupo} style="padding-left: 40px !important;"
                                                                    onclick="if(window.currentState){if(event){event.preventDefault();}window.currentState.go('${subGrupoN3.rota}',${JSON.stringify(subGrupoN3.parametrosRota).replace(/"/g, '\'')}); if(angular.element(document.body).hasClass('vertical-overlay-menu')){ $.app.menu.hide(); angular.element(document.body).removeClass('menu-open').removeClass('menu-show').addClass('menu-hide').css('overflow','auto'); angular.element('.nav-link.nav-menu-main.menu-toggle').removeClass('is-active'); window.mainMenuNavigationCalcHeight(500); }}">
                                                                    <i class="bx bx-right-arrow-alt"></i>
                                                                    <span class="menu-item" data-i18n="${subGrupoN3.titulo.replace(/<br>/g, '')}">
                                                                        ${subGrupoN3.titulo}
                                                                    </span>
                                                                </a>
                                                            </li>`);
                                                        liSubGrupo = ulConteudoGrupoN2.find(`li[data-rota='${subGrupoN3.rota}']`);
                                                    }
                                                }
                                            }
                                        });
                                    }
                                });
                            }
                        });
                        let liSeparadorGrupo = ulConteudoGrupo.find(`li[data-rota='separadorFim']`);
                        if (!liSeparadorGrupo || (liSeparadorGrupo.length === 0)) {
                            ulConteudoGrupo.append(`<li style="min-height: 8px !important;" class="is-shown" data-rota="separadorFim"></li>`);
                        }
                        if ((contadorTotalSubGrupos > 0) && (contadorPermissaoSubGrupos > 0)) {
                            liGrupo.show();
                        }
                        else if ((contadorTotalSubGrupos > 0) && (contadorPermissaoSubGrupos === 0)) {
                            liGrupo.hide();
                        }
                    }
                }
            }
        });
        if (contadorItensPermitidosMenu > 0) {
            const liItemMenuBusca = ulMenu.find('#itemMenuBusca');
            if (!liItemMenuBusca || (liItemMenuBusca.length === 0)) {
                ulMenu.prepend(`
                    <li class="nav-item" id="itemMenuBusca">
                        <input type="text" class="form-control search-input container-fluid border-0 tt-input"
                            placeholder="Buscar..." aria-label="Buscar..." autocomplete="off" spellcheck="false" dir="auto"
                            onchange="window.buscarItensMenu(this)" oninput="window.buscarItensMenu(this)">
                        <i class="bx bx-search-alt bx-sm"></i>
                        <a class="btn" onclick="window.limparBuscaItensMenu()" style="display: none;">
                            <i class="fa fa-times-circle"></i>
                        </a>
                    </li>`);
            }
        }
        ulMenu.find('#itemMenuVersao').show().find('span').text('- versão ' + config_app_1.config.versao + ' -');
        ulMenu.find('#itemMenuEmpresa').show().find('span').text('');
        if ((typeof config_app_1.config.empresa !== 'undefined') && config_app_1.config.empresa) {
            if ((typeof config_app_1.config.empresa.nomeResumido !== 'undefined') && config_app_1.config.empresa.nomeResumido &&
                (config_app_1.config.empresa.nomeResumido.trim().length > 0)) {
                ulMenu.find('#itemMenuEmpresa').show().find('span').text(config_app_1.config.empresa.nomeResumido.trim().substring(0, 30));
            }
            else if ((typeof config_app_1.config.empresa.nomeCompleto !== 'undefined') && config_app_1.config.empresa.nomeCompleto &&
                (config_app_1.config.empresa.nomeCompleto.trim().length > 0)) {
                ulMenu.find('#itemMenuEmpresa').show().find('span').text(config_app_1.config.empresa.nomeCompleto.trim().substring(0, 30));
            }
        }
    }
    static ajustaZIndexMenusModalPlanilha(habilitaTimeout = true) {
        const elHeaderNavbar = angular.element('.header-navbar');
        if ((typeof elHeaderNavbar !== 'undefined') && elHeaderNavbar && (elHeaderNavbar.length > 0)) {
            if ((typeof globalThis.old_zindex_header_navbar === 'undefined') || !globalThis.old_zindex_header_navbar) {
                globalThis.old_zindex_header_navbar = elHeaderNavbar.css('z-index');
            }
            elHeaderNavbar.css('z-index', '80');
        }
        const elMainMenu = angular.element('#menu.main-menu');
        if ((typeof elMainMenu !== 'undefined') && elMainMenu && (elMainMenu.length > 0)) {
            if ((typeof globalThis.old_zindex_menu === 'undefined') || !globalThis.old_zindex_menu) {
                globalThis.old_zindex_menu = elMainMenu.css('z-index');
            }
            elMainMenu.css('z-index', '85');
        }
        if ((typeof habilitaTimeout !== 'undefined') && habilitaTimeout) {
            setTimeout(() => {
                MenuUtil.ajustaZIndexMenusModalPlanilha(false);
            }, 1000);
        }
    }
    static restauraZIndexMenus(habilitaTimeout = true) {
        const elHeaderNavbar = angular.element('.header-navbar');
        if ((typeof elHeaderNavbar !== 'undefined') && elHeaderNavbar && (elHeaderNavbar.length > 0) &&
            (typeof globalThis.old_zindex_header_navbar === 'undefined') && globalThis.old_zindex_header_navbar) {
            elHeaderNavbar.css('z-index', globalThis.old_zindex_header_navbar);
        }
        const elMainMenu = angular.element('#menu.main-menu');
        if ((typeof elMainMenu !== 'undefined') && elMainMenu && (elMainMenu.length > 0) &&
            (typeof globalThis.old_zindex_menu === 'undefined') && globalThis.old_zindex_menu) {
            elMainMenu.css('z-index', globalThis.old_zindex_menu);
        }
        if ((typeof habilitaTimeout !== 'undefined') && habilitaTimeout) {
            setTimeout(() => {
                MenuUtil.restauraZIndexMenus(false);
            }, 1000);
        }
    }
}
exports.MenuUtil = MenuUtil;
