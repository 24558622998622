"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiltroFenologiaPomarTratado = exports.FiltroFenologiaPomar = void 0;
class FiltroFenologiaPomar {
    constructor() {
        this.dataIni = null;
        this.dataFim = null;
    }
}
exports.FiltroFenologiaPomar = FiltroFenologiaPomar;
class FiltroFenologiaPomarTratado {
    constructor() {
        this.pomar = null;
    }
}
exports.FiltroFenologiaPomarTratado = FiltroFenologiaPomarTratado;
