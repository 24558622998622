"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TabelaController = void 0;
const angular = require("angular");
const PosicaoColunaTabela_1 = require("../../../../../modelos/PosicaoColunaTabela");
const VisibilidadeColunaTabela_1 = require("../../../../../modelos/VisibilidadeColunaTabela");
const UiGridUtil_1 = require("../../../../../util/UiGridUtil");
const TextoUtil_1 = require("../../../../../util/TextoUtil");
const DispositivoUtil_1 = require("../../../../../util/DispositivoUtil");
const AutenticacaoUtil_1 = require("../../../../../util/AutenticacaoUtil");
const SincroniaFactory_1 = require("../../../../fabricas/SincroniaFactory");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
require("angular-ui-grid/ui-grid.min.css");
class TabelaController {
    constructor($scope, i18nService, sincronia) {
        this.gridId = null;
        this.gridSelection = null;
        this.gridMultiSelect = null;
        this.gridAutoFitColumns = null;
        this.gridEdit = null;
        this.gridMinHeight = null;
        this.gridRowHeight = null;
        this.gridRowSelectionChanged = null;
        this.gridRowSelectionChangedBatch = null;
        this.gridStatesRestored = false;
        this.gridStatesRestoring = false;
        this.opcoes = null;
        this.padrao = null;
        this.rowHeight = 48;
        this.headerHeight = 60;
        this.lang = 'pt';
        this.sincronia = null;
        this.$scope = null;
        this.timeoutRowsRendered = null;
        this.$scope = $scope;
        this.sincronia = sincronia;
        i18nService.setCurrentLang('pt-BR');
        if (!DispositivoUtil_1.DispositivoUtil.ehCelular() && (window.screen.width >= 992)) {
            this.rowHeight = 35;
        }
    }
    $postLink() {
        this.padrao = {
            appScopeProvider: this,
            enableFiltering: true,
            enableSorting: true,
            rowHeight: this.rowHeight,
            saveFocus: false,
            saveScroll: true,
            saveFilter: true,
            saveOrder: false,
            saveSort: true,
            saveGrouping: false,
            saveGroupingExpandedStates: false,
            saveSelection: false,
            saveTreeView: false,
            saveVisible: false,
            saveWidths: false,
            enableGridMenu: true,
            enableSelectAll: true,
            exporterMenuExcel: true,
            exporterMenuPdf: true,
            exporterCsvFilename: 'sre.csv',
            exporterPdfTableLayout: 'lightHorizontalLines',
            exporterPdfDefaultStyle: { fontSize: 9 },
            exporterPdfTableStyle: { margin: [30, 30, 30, 30] },
            exporterPdfTableHeaderStyle: { fontSize: 10, bold: true, italics: true, color: 'black' },
            exporterPdfHeader: { text: 'Sistema de Gestão de Pesquisa - Material Genético', style: 'headerStyle' },
            exporterPdfFooter: function (currentPage, pageCount) {
                return { text: currentPage.toString() + ' of ' + pageCount.toString(), style: 'footerStyle' };
            },
            exporterPdfCustomFormatter: function (docDefinition) {
                docDefinition.styles.headerStyle = { fontSize: 22, bold: true };
                docDefinition.styles.footerStyle = { fontSize: 10, bold: true };
                return docDefinition;
            },
            exporterPdfOrientation: 'portrait',
            exporterPdfPageSize: 'LETTER',
            exporterPdfMaxGridWidth: 500,
        };
        if ((typeof this.gridMultiSelect !== 'undefined') && (this.gridMultiSelect !== null)) {
            if (this.gridMultiSelect === false) {
                this.padrao.enableRowSelection = true;
                this.padrao.enableSelectAll = false;
                this.padrao.multiSelect = false;
                this.padrao.noUnselect = false;
            }
        }
        if (!DispositivoUtil_1.DispositivoUtil.ehCelular() && (typeof this.gridRowHeight !== 'undefined') && (this.gridRowHeight !== null)) {
            this.padrao.rowHeight = this.gridRowHeight;
        }
        this.padrao.onRegisterApi = this.registrarEventos.bind(this);
        if ((typeof this.opcoes === 'undefined') || (this.opcoes === null)) {
            this.opcoes = {};
        }
        this.opcoes = Object.assign(this.padrao, this.opcoes);
        if ((this.gridMinHeight === 'undefined') || !this.gridMinHeight) {
            this.gridMinHeight = '75vh';
        }
    }
    getParentController() {
        if ((typeof this.opcoes.controller !== 'undefined') && (this.opcoes.controller !== null)) {
            return this.opcoes.controller;
        }
        else {
            return this;
        }
    }
    registrarEventos(gridApi) {
        const ctrl = this;
        this.$scope.$parent.gridApi = gridApi;
        if ((typeof this.$scope.$parent.listaGridApi === 'undefined') || !this.$scope.$parent.listaGridApi) {
            this.$scope.$parent.listaGridApi = new Array();
        }
        if (!this.$scope.$parent.listaGridApi.includes(gridApi)) {
            this.$scope.$parent.listaGridApi.push(gridApi);
        }
        if ((typeof this.gridRowSelectionChanged !== 'undefined') && this.gridRowSelectionChanged) {
            gridApi.selection.on.rowSelectionChanged(this.$scope, (handler) => {
                this.gridRowSelectionChanged(handler);
            });
        }
        if ((typeof this.gridRowSelectionChangedBatch !== 'undefined') && this.gridRowSelectionChangedBatch) {
            gridApi.selection.on.rowSelectionChangedBatch(this.$scope, (handler) => {
                ctrl.gridRowSelectionChangedBatch(handler);
            });
        }
        gridApi.core.on.scrollBegin(this.$scope, () => {
            ctrl.removerMenuDropDown();
        });
        gridApi.core.on.sortChanged(this.$scope, () => {
            UiGridUtil_1.UiGridUtil.saveStates(gridApi);
        });
        gridApi.core.on.filterChanged(this.$scope, () => {
            UiGridUtil_1.UiGridUtil.saveStates(gridApi);
        });
        gridApi.core.on.columnVisibilityChanged(ctrl.$scope, (changedColumn) => __awaiter(this, void 0, void 0, function* () {
            if ((typeof changedColumn !== 'undefined') && changedColumn && ctrl.$scope && ctrl.sincronia) {
                let chaveTabela = '';
                let escopoAtual = ctrl.$scope;
                let nivelAtual = 10;
                while (escopoAtual && (nivelAtual > 0)) {
                    let chaveAtual = '';
                    if ((typeof escopoAtual.$ctrl !== 'undefined') && escopoAtual.$ctrl &&
                        (typeof escopoAtual.$ctrl.$stateParams !== 'undefined') && escopoAtual.$ctrl.$stateParams) {
                        const parametros = escopoAtual.$ctrl.$stateParams;
                        chaveAtual += '[' +
                            'M=' + ((typeof parametros.modulo !== 'undefined') && parametros.modulo ? TextoUtil_1.TextoUtil.converteTextoParaReferencia(parametros.modulo) : '0') + ';' +
                            'T=' + ((typeof parametros.titulo !== 'undefined') && parametros.titulo ? TextoUtil_1.TextoUtil.converteTextoParaReferencia(parametros.titulo) : '0') + ';' +
                            'O=' + ((typeof parametros.operacao !== 'undefined') && parametros.operacao ? TextoUtil_1.TextoUtil.converteTextoParaReferencia(parametros.operacao) : '0') +
                            ']';
                    }
                    if (chaveAtual && (chaveAtual.length > 0) && (chaveAtual !== '[]')) {
                        if (chaveTabela && (chaveTabela.indexOf(chaveAtual) === -1)) {
                            chaveTabela = chaveAtual + chaveTabela;
                        }
                        else if (!chaveTabela) {
                            chaveTabela = chaveAtual;
                        }
                    }
                    if ((typeof escopoAtual.$parent !== 'undefined') && escopoAtual.$parent) {
                        escopoAtual = escopoAtual.$parent;
                    }
                    nivelAtual--;
                }
                if (!chaveTabela || (chaveTabela.length === 0) || (chaveTabela === '[]')) {
                    let parametroRota = (location.href && (location.href.indexOf('#!/') !== -1) ? location.href : null);
                    if (parametroRota && (parametroRota.length > 0)) {
                        chaveTabela = '[L=' + parametroRota.substring(parametroRota.indexOf('#!/') + 3) + ']';
                    }
                }
                const usuarioLogado = yield AutenticacaoUtil_1.AutenticacaoUtil.usuario();
                let visibilidadeColunaTabela = new VisibilidadeColunaTabela_1.VisibilidadeColunaTabela();
                visibilidadeColunaTabela.chaveTabela = chaveTabela;
                visibilidadeColunaTabela.nomeCampo = (typeof changedColumn.field !== 'undefined') ? changedColumn.field : ((typeof changedColumn.name !== 'undefined') ? changedColumn.name : null);
                visibilidadeColunaTabela.tituloCampo = (typeof changedColumn.displayName !== 'undefined') ? changedColumn.displayName : null;
                visibilidadeColunaTabela.campoVisivel = (typeof changedColumn.visible !== 'undefined') ? changedColumn.visible : null;
                visibilidadeColunaTabela.usuario = (typeof usuarioLogado.id !== 'undefined') ? usuarioLogado.id : null;
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.VISIBILIDADE_COLUNA_TABELA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.VISIBILIDADE_COLUNA_TABELA)
                    .criar(visibilidadeColunaTabela);
            }
        }));
        gridApi.colMovable.on.columnPositionChanged(ctrl.$scope, (movedColumn, originalPosition, finalPosition) => __awaiter(this, void 0, void 0, function* () {
            if ((typeof movedColumn !== 'undefined') && movedColumn && ctrl.$scope && ctrl.sincronia) {
                let chaveTabela = '';
                let escopoAtual = ctrl.$scope;
                let nivelAtual = 10;
                while (escopoAtual && (nivelAtual > 0)) {
                    let chaveAtual = '';
                    if ((typeof escopoAtual.$ctrl !== 'undefined') && escopoAtual.$ctrl &&
                        (typeof escopoAtual.$ctrl.$stateParams !== 'undefined') && escopoAtual.$ctrl.$stateParams) {
                        const parametros = escopoAtual.$ctrl.$stateParams;
                        chaveAtual += '[' +
                            'M=' + ((typeof parametros.modulo !== 'undefined') && parametros.modulo ? TextoUtil_1.TextoUtil.converteTextoParaReferencia(parametros.modulo) : '0') + ';' +
                            'T=' + ((typeof parametros.titulo !== 'undefined') && parametros.titulo ? TextoUtil_1.TextoUtil.converteTextoParaReferencia(parametros.titulo) : '0') + ';' +
                            'O=' + ((typeof parametros.operacao !== 'undefined') && parametros.operacao ? TextoUtil_1.TextoUtil.converteTextoParaReferencia(parametros.operacao) : '0') +
                            ']';
                    }
                    if (chaveAtual && (chaveAtual.length > 0) && (chaveAtual !== '[]')) {
                        if (chaveTabela && (chaveTabela.indexOf(chaveAtual) === -1)) {
                            chaveTabela = chaveAtual + chaveTabela;
                        }
                        else if (!chaveTabela) {
                            chaveTabela = chaveAtual;
                        }
                    }
                    if ((typeof escopoAtual.$parent !== 'undefined') && escopoAtual.$parent) {
                        escopoAtual = escopoAtual.$parent;
                    }
                    nivelAtual--;
                }
                if (!chaveTabela || (chaveTabela.length === 0) || (chaveTabela === '[]')) {
                    let parametroRota = (location.href && (location.href.indexOf('#!/') !== -1) ? location.href : null);
                    if (parametroRota && (parametroRota.length > 0)) {
                        chaveTabela = '[L=' + parametroRota.substring(parametroRota.indexOf('#!/') + 3) + ']';
                    }
                }
                const usuarioLogado = yield AutenticacaoUtil_1.AutenticacaoUtil.usuario();
                let posicaoColunaTabela = new PosicaoColunaTabela_1.PosicaoColunaTabela();
                posicaoColunaTabela.chaveTabela = chaveTabela;
                posicaoColunaTabela.nomeCampo = (typeof movedColumn.field !== 'undefined') && movedColumn.field ? movedColumn.field : ((typeof movedColumn.name !== 'undefined') && movedColumn.name ? movedColumn.name : null);
                posicaoColunaTabela.tituloCampo = (typeof movedColumn.displayName !== 'undefined') ? movedColumn.displayName : null;
                posicaoColunaTabela.posicaoAnterior = (typeof originalPosition !== 'undefined') ? originalPosition : null;
                posicaoColunaTabela.posicaoFinal = (typeof finalPosition !== 'undefined') ? finalPosition : null;
                posicaoColunaTabela.usuario = (typeof usuarioLogado.id !== 'undefined') ? usuarioLogado.id : null;
                posicaoColunaTabela.camposTabela = [];
                if ((typeof gridApi.grid.columns !== 'undefined') && gridApi.grid.columns) {
                    let posicaoAtual = 0;
                    angular.forEach(gridApi.grid.columns, (coluna) => {
                        posicaoColunaTabela.camposTabela.push({
                            nomeCampo: (typeof coluna.field !== 'undefined') && coluna.field ? coluna.field : ((typeof coluna.name !== 'undefined') && coluna.name ? coluna.name : null),
                            tituloCampo: (typeof coluna.displayName !== 'undefined') ? coluna.displayName : null,
                            posicao: posicaoAtual
                        });
                        posicaoAtual++;
                    });
                }
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POSICAO_COLUNA_TABELA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POSICAO_COLUNA_TABELA)
                    .criar(posicaoColunaTabela);
            }
        }));
        gridApi.core.on.rowsRendered(ctrl.$scope, () => __awaiter(this, void 0, void 0, function* () {
            ctrl.gridStatesRestored = false;
            ctrl.onRowsRendered(gridApi);
        }));
    }
    onRowsRendered(gridApi) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.gridStatesRestoring !== 'undefined') && this.gridStatesRestoring) {
                return;
            }
            if ((typeof this.timeoutRowsRendered !== 'undefined') && (this.timeoutRowsRendered !== null)) {
                clearTimeout(this.timeoutRowsRendered);
                this.timeoutRowsRendered = null;
            }
            if ((typeof this.gridStatesRestored !== 'undefined') && this.gridStatesRestored) {
                return;
            }
            this.timeoutRowsRendered = setTimeout((ctrl, gridApi) => __awaiter(this, void 0, void 0, function* () {
                try {
                    if ((typeof gridApi !== 'undefined') && gridApi) {
                        ctrl.gridStatesRestoring = true;
                        yield UiGridUtil_1.UiGridUtil.restoreStates(gridApi, ctrl.$scope.$parent || ctrl.$scope);
                        ctrl.gridStatesRestored = true;
                        setTimeout((ctrl) => {
                            ctrl.gridStatesRestoring = false;
                        }, 500, ctrl);
                    }
                }
                catch (ex) {
                    console.log('ERRO: TabelaController.onRowsRendered()');
                    console.log(ex);
                }
            }), 250, this, gridApi);
        });
    }
    removerMenuDropDown() {
        $('.dropdown-menu').remove();
    }
}
exports.TabelaController = TabelaController;
TabelaController.$inject = ['$scope', 'i18nService', SincroniaFactory_1.SincroniaFactory.id];
