"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiltroPolinizacaoPomarTratado = exports.FiltroPolinizacaoPomar = void 0;
class FiltroPolinizacaoPomar {
    constructor() {
        this.dataIni = null;
        this.dataFim = null;
    }
}
exports.FiltroPolinizacaoPomar = FiltroPolinizacaoPomar;
class FiltroPolinizacaoPomarTratado {
    constructor() {
        this.pomar = null;
    }
}
exports.FiltroPolinizacaoPomarTratado = FiltroPolinizacaoPomarTratado;
