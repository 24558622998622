"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbstratoController = void 0;
const angular = require("angular");
const config_app_1 = require("../../../config/config.app");
const Usuario_1 = require("../../../modelos/Usuario");
const AutenticacaoUtil_1 = require("../../../util/AutenticacaoUtil");
const DialogoFactory_1 = require("../../fabricas/DialogoFactory");
const ManipulaErroFactory_1 = require("../../fabricas/ManipulaErroFactory");
const NotificacaoFactory_1 = require("../../fabricas/NotificacaoFactory");
const RestFactory_1 = require("../../fabricas/RestFactory");
const SincroniaFactory_1 = require("../../fabricas/SincroniaFactory");
const RotaUtil_1 = require("../../util/RotaUtil");
const ICRUDOperacao_1 = require("./interfaces/ICRUDOperacao");
const UiGridUtil_1 = require("../../../util/UiGridUtil");
const DispositivoUtil_1 = require("../../../util/DispositivoUtil");
const RevTabelaUtil_1 = require("../../../util/RevTabelaUtil");
const TextoUtil_1 = require("../../../util/TextoUtil");
const MenuUtil_1 = require("../../../util/MenuUtil");
const Generico_1 = require("../../../modelos/Generico");
const RotaAPIConstantes_1 = require("../../../constantes/RotaAPIConstantes");
const SincroniaConstantes_1 = require("../../../constantes/SincroniaConstantes");
class AbstratoController {
    constructor($scope, $injector, modelo = null, ehLogin = false) {
        this.modelo = modelo;
        this.operacao = null;
        this.titulo = null;
        this.descricao = null;
        this.modulo = null;
        this.listaOpcoes = null;
        this.links = null;
        this.versao = null;
        this.versaoApi = null;
        this.ehLogin = null;
        this.usuario = null;
        this.uiGridConstantes = null;
        this.operacoesDesabilitadas = [];
        this.importar = false;
        this.vincularFormularioIndicadores = false;
        this.tipoOperacao = null;
        this.tipoPerfilUsuario = null;
        this.tipoGenerico = null;
        this.ehCelular = false;
        this.usuarioLogado = null;
        this.perfilLogado = null;
        this.permissoes = null;
        this.voltar = false;
        this.sincronia = null;
        this.notificacoes = [];
        this.usuarios = [];
        this.pageWidth = null;
        this.pageWidthSmall = null;
        this.pageWidthSmall2 = null;
        this.pageWidthMedium = null;
        this.pageWidthMedium2 = null;
        this.pageWidthLarge = null;
        this.pageWidthLarge2 = null;
        this.pageWidthExtraLarge = null;
        this.pageWidthExtraLarge2 = null;
        this.pageWidthExtraLarge3 = null;
        this.manipulaErro = null;
        this.notificacao = null;
        this.$scope = null;
        this.$state = null;
        this.$stateParams = null;
        this.$timeout = null;
        this.$window = null;
        this.$location = null;
        this.rest = null;
        this.dataHoraAtual = null;
        this.timeoutScopeApply = null;
        this.modeloInicial = null;
        this.timeoutScopeApplyTabela = null;
        this.timeoutRefreshPageWidth = null;
        this.timeoutRefreshPagePrint = null;
        globalThis.currentController = this;
        this.ehLogin = ehLogin;
        this.$state = $injector.get('$state');
        globalThis.currentState = this.$state;
        this.$stateParams = $injector.get('$stateParams');
        globalThis.currentStateParams = this.$stateParams;
        this.sincronia = $injector.get(SincroniaFactory_1.SincroniaFactory.id);
        this.$scope = $scope;
        this.$window = $injector.get('$window');
        this.$timeout = $injector.get('$timeout');
        this.$location = $injector.get('$location');
        this.notificacao = $injector.get(NotificacaoFactory_1.NotificacaoFactory.name);
        this.manipulaErro = $injector.get(ManipulaErroFactory_1.ManipulaErroFactory.name);
        this.rest = $injector.get(RestFactory_1.RestFactory.name);
        this.uiGridConstantes = $injector.get('uiGridConstants');
        this.dialogo = $injector.get(DialogoFactory_1.DialogoFactory.name);
        this.titulo = this.$state.params.titulo;
        this.descricao = this.$state.params.descricao;
        this.versao = config_app_1.config.versao;
        this.versaoApi = config_app_1.config.versao;
        if ((typeof config_app_1.config.empresa === 'undefined') || !config_app_1.config.empresa) {
            config_app_1.config.carregarEmpresaLogada();
        }
        this.operacao = this.$stateParams.operacao;
        this.permissoes = { rota: null, acessar: false, incluir: false, alterar: false, excluir: false };
        this.tipoOperacao = ICRUDOperacao_1.CRUDOperacao;
        this.tipoPerfilUsuario = Usuario_1.PerfilUsuarioValor;
        this.tipoGenerico = Generico_1.Generico;
        this.ehCelular = DispositivoUtil_1.DispositivoUtil.ehCelular();
        this.refreshPageWidth();
    }
    $onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.ehCelular = DispositivoUtil_1.DispositivoUtil.ehCelular();
            this.ehLogin = (typeof this.ehLogin !== 'undefined') && this.ehLogin ? true : false;
            yield RevTabelaUtil_1.RevTabelaUtil.desabilitarMonitoramentoAtualizacoesTabelas();
            yield RevTabelaUtil_1.RevTabelaUtil.limparEventosMonitoramentoAtualizacoesTabelas();
            if ((typeof config_app_1.config.empresa === 'undefined') || !config_app_1.config.empresa) {
                config_app_1.config.carregarEmpresaLogada();
                if ((typeof config_app_1.config.empresa === 'undefined') || !config_app_1.config.empresa) {
                    if (!this.ehLogin) {
                        return this.$state.go('login');
                    }
                }
            }
            yield this.ajusteLogoEmpresa();
            setTimeout((ctrl) => {
                ctrl.ajusteLogoEmpresa();
            }, 1500, this);
            DispositivoUtil_1.DispositivoUtil.habilitarCapturaCoordenada();
            yield this.carregarNotificacoes();
            yield this.ajusteUsuario();
            const ehAutorizado = yield RotaUtil_1.RotaUtil.ehRotaAutorizada(this.$state.current.url, this.perfilLogado);
            if (!ehAutorizado && !this.ehLogin) {
                return this.$state.go('login');
            }
            this.modeloInicial = angular.copy(this.modelo);
            this.links = RotaUtil_1.RotaUtil.gerarLinks(this.$state);
            this.detectaOperacoes();
            if ([Usuario_1.PerfilUsuarioValor.OPERACIONAL, Usuario_1.PerfilUsuarioValor.SUPERVISAO].includes(this.perfilLogado)) {
                this.operacoesDesabilitadas.push(this.tipoOperacao.REMOVER);
            }
            try {
                if ((typeof globalThis.luckysheet !== 'undefined') && globalThis.luckysheet) {
                    globalThis.luckysheet.destroy();
                }
            }
            catch (e) {
                console.log(e);
            }
            yield RevTabelaUtil_1.RevTabelaUtil.limparEventosMonitoramentoAtualizacoesTabelas();
            yield RevTabelaUtil_1.RevTabelaUtil.habilitarMonitoramentoAtualizacoesTabelas(this.rest);
            this.refreshPageWidth();
            this.onInit();
        });
    }
    sincronizaPermissoes() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.usuarioLogado && this.usuario) {
                try {
                    this.usuarioLogado = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.USUARIO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.USUARIO)
                        .id(this.usuario.id)
                        .filtro({ permiteDadosOffline: true })
                        .ver();
                }
                catch (e) {
                    this.usuarioLogado = null;
                }
                if ((typeof this.usuarioLogado === 'undefined') || (this.usuarioLogado === null)) {
                    this.usuarioLogado = new Usuario_1.Usuario();
                    this.usuarioLogado.id = this.usuario.id;
                    this.usuarioLogado.nome = this.usuario.nome;
                    this.usuarioLogado.perfil = ((typeof this.usuario.perfil !== 'undefined') ? this.usuario.perfil : null);
                    if ((typeof this.usuario.permissoes !== 'undefined') && (this.usuario.permissoes !== null)) {
                        for (let key of Object.keys(this.usuario.permissoes)) {
                            this.usuarioLogado[key] = this.usuario.permissoes[key];
                        }
                    }
                    this.perfilLogado = 1;
                }
                this.perfilLogado = (this.usuarioLogado && this.usuarioLogado.perfil) ? this.usuarioLogado.perfil : 1;
                if ((typeof this.permissoes === 'undefined') || !this.permissoes) {
                    this.permissoes = { rota: null, acessar: false, incluir: false, alterar: false, excluir: false };
                }
                else {
                    this.permissoes.rota = null;
                    this.permissoes.acessar = false;
                    this.permissoes.incluir = false;
                    this.permissoes.alterar = false;
                    this.permissoes.excluir = false;
                }
                if (this.usuarioLogado) {
                    const modeloUsuario = Object.assign(new Usuario_1.Usuario(), this.usuarioLogado);
                    const url = this.$location ? String(this.$location.url()).toLowerCase().trim() : String(window.location.hash).toLowerCase().trim();
                    for (let prop of Object.keys(modeloUsuario)) {
                        const ehPropAcessa = prop && (prop.indexOf('Acessa') !== -1);
                        const ehPropInclui = prop && (prop.indexOf('Inclui') !== -1);
                        const ehPropAltera = prop && (prop.indexOf('Altera') !== -1);
                        const ehPropExclui = prop && (prop.indexOf('Exclui') !== -1);
                        if (prop && (ehPropAcessa || ehPropInclui || ehPropAltera || ehPropExclui)) {
                            const rota = prop.replace('Acessar', '').replace('Acessa', '')
                                .replace('Incluir', '').replace('Inclui', '')
                                .replace('Alterar', '').replace('Altera', '')
                                .replace('Excluir', '').replace('Exclui', '').trim();
                            const rotaMinuscula = rota.toLowerCase();
                            if ((url === rotaMinuscula) || (url === '/' + rotaMinuscula) || (url.indexOf('/' + rotaMinuscula + '/') !== -1)) {
                                this.permissoes.rota = rota;
                                this.permissoes.acessar = ehPropAcessa ? ((typeof this.usuarioLogado[prop] !== 'undefined') ? this.usuarioLogado[prop] : false) : this.permissoes.acessar;
                                this.permissoes.incluir = ehPropInclui ? ((typeof this.usuarioLogado[prop] !== 'undefined') ? this.usuarioLogado[prop] : false) : this.permissoes.incluir;
                                this.permissoes.alterar = ehPropAltera ? ((typeof this.usuarioLogado[prop] !== 'undefined') ? this.usuarioLogado[prop] : false) : this.permissoes.alterar;
                                this.permissoes.excluir = ehPropExclui ? ((typeof this.usuarioLogado[prop] !== 'undefined') ? this.usuarioLogado[prop] : false) : this.permissoes.excluir;
                            }
                        }
                    }
                }
                this.permissoes.acessar = true;
                this.permissoes.incluir = true;
                this.permissoes.alterar = true;
                this.permissoes.excluir = true;
                globalThis.permissoes = this.permissoes;
            }
        });
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
        });
    }
    restaurar() {
        this.notificacao.erro('Função não implementada', 'Consulte o suporte para uma possível implementação.');
    }
    resetar() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                this.modelo = angular.copy(this.modeloInicial);
            }
            else {
                const id = this.modelo['id'] || this.modelo['uuid'];
                this.modelo = yield this.visualizar(id);
            }
            this.$scope.$applyAsync();
        });
    }
    voltarPagina() {
        return __awaiter(this, void 0, void 0, function* () {
            console.log('AbstratoController.voltarPagina()');
        });
    }
    scopeApply(callbackAfterApply = null) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.timeoutScopeApply !== 'undefined') && (this.timeoutScopeApply !== null)) {
                clearTimeout(this.timeoutScopeApply);
                this.timeoutScopeApply = null;
            }
            this.timeoutScopeApply = setTimeout((controller, callbackAfterApply) => {
                controller.timeoutScopeApply = null;
                try {
                    controller.dataHoraAtual = new Date();
                    controller.$scope.$apply();
                }
                catch (ex) {
                    controller.$scope.$applyAsync();
                    console.log(ex);
                }
                finally {
                    if (typeof callbackAfterApply === 'function') {
                        callbackAfterApply();
                    }
                }
            }, 250, this, callbackAfterApply);
        });
    }
    carregaPosicaoColunasTabela(listaOpcoes) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof listaOpcoes === 'undefined') || !listaOpcoes ||
                (typeof listaOpcoes.columnDefs === 'undefined') || !listaOpcoes.columnDefs) {
                return;
            }
            let chaveTabela = '';
            let escopoAtual = this.$scope;
            let nivelAtual = 10;
            while (escopoAtual && (nivelAtual > 0)) {
                let chaveAtual = '';
                if ((typeof escopoAtual.$ctrl !== 'undefined') && escopoAtual.$ctrl &&
                    (typeof escopoAtual.$ctrl.$stateParams !== 'undefined') && escopoAtual.$ctrl.$stateParams) {
                    const parametros = escopoAtual.$ctrl.$stateParams;
                    chaveAtual += '[' +
                        'M=' + ((typeof parametros.modulo !== 'undefined') && parametros.modulo ? TextoUtil_1.TextoUtil.converteTextoParaReferencia(parametros.modulo) : '0') + ';' +
                        'T=' + ((typeof parametros.titulo !== 'undefined') && parametros.titulo ? TextoUtil_1.TextoUtil.converteTextoParaReferencia(parametros.titulo) : '0') + ';' +
                        'O=' + ((typeof parametros.operacao !== 'undefined') && parametros.operacao ? TextoUtil_1.TextoUtil.converteTextoParaReferencia(parametros.operacao) : '0') +
                        ']';
                }
                if (chaveAtual && (chaveAtual.length > 0) && (chaveAtual !== '[]')) {
                    if (chaveTabela && (chaveTabela.indexOf(chaveAtual) === -1)) {
                        chaveTabela = chaveAtual + chaveTabela;
                    }
                    else if (!chaveTabela) {
                        chaveTabela = chaveAtual;
                    }
                }
                if ((typeof escopoAtual.$parent !== 'undefined') && escopoAtual.$parent) {
                    escopoAtual = escopoAtual.$parent;
                }
                nivelAtual--;
            }
            if (!chaveTabela || (chaveTabela.length === 0) || (chaveTabela === '[]')) {
                let parametroRota = (location.href && (location.href.indexOf('#!/') !== -1) ? location.href : null);
                if (parametroRota && (parametroRota.length > 0)) {
                    chaveTabela = '[L=' + parametroRota.substring(parametroRota.indexOf('#!/') + 3) + ']';
                }
            }
            const filtro = {
                permiteDadosOffline: false,
                listaSimplificadaOnline: true,
                status: true,
                chaveTabela: chaveTabela,
                usuario: (this.usuario && this.usuario.id ? this.usuario.id :
                    (this.usuarioLogado && this.usuarioLogado.id ? this.usuarioLogado.id : null))
            };
            let lista = yield this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.POSICAO_COLUNA_TABELA)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POSICAO_COLUNA_TABELA)
                .filtro(filtro)
                .listar();
            if ((typeof lista !== 'undefined') && lista) {
                let alterouPosicaoColunas = false;
                let listaDefinicaoColunas = {};
                angular.forEach(listaOpcoes.columnDefs, (_coluna, keyColuna) => {
                    listaDefinicaoColunas['p' + keyColuna] = null;
                });
                let listaColunasRestantes = [...listaOpcoes.columnDefs];
                angular.forEach(lista, (posicaoColunaTabela) => {
                    let colunaGridEncontrada = null;
                    if ((typeof posicaoColunaTabela.tituloCampo !== 'undefined') && posicaoColunaTabela.tituloCampo) {
                        angular.forEach(listaColunasRestantes, (coluna) => {
                            if (!colunaGridEncontrada && (typeof coluna.displayName !== 'undefined') && coluna.displayName) {
                                if (coluna.displayName === posicaoColunaTabela.tituloCampo) {
                                    colunaGridEncontrada = coluna;
                                }
                            }
                        });
                    }
                    if (!colunaGridEncontrada) {
                        if ((typeof posicaoColunaTabela.nomeCampo !== 'undefined') && posicaoColunaTabela.nomeCampo) {
                            angular.forEach(listaColunasRestantes, (coluna) => {
                                if (!colunaGridEncontrada && (typeof coluna !== 'undefined') && coluna &&
                                    (typeof coluna.field !== 'undefined') && coluna.field &&
                                    (coluna.field === posicaoColunaTabela.nomeCampo)) {
                                    colunaGridEncontrada = coluna;
                                }
                            });
                        }
                    }
                    if (!colunaGridEncontrada) {
                        if ((typeof posicaoColunaTabela.nomeCampo !== 'undefined') && posicaoColunaTabela.nomeCampo) {
                            angular.forEach(listaColunasRestantes, (coluna) => {
                                if (!colunaGridEncontrada && (typeof coluna !== 'undefined') && coluna &&
                                    (typeof coluna.name !== 'undefined') && coluna.name &&
                                    (coluna.name === posicaoColunaTabela.nomeCampo)) {
                                    colunaGridEncontrada = coluna;
                                }
                            });
                        }
                    }
                    if (colunaGridEncontrada) {
                        if (typeof posicaoColunaTabela.posicaoFinal !== 'undefined') {
                            if ((posicaoColunaTabela.posicaoFinal === null) || !posicaoColunaTabela.posicaoFinal) {
                                posicaoColunaTabela.posicaoFinal = 0;
                            }
                            listaDefinicaoColunas['p' + (posicaoColunaTabela.posicaoFinal - 1)] = colunaGridEncontrada;
                            listaColunasRestantes = listaColunasRestantes.filter(o => o !== colunaGridEncontrada);
                            alterouPosicaoColunas = true;
                        }
                    }
                });
                if (alterouPosicaoColunas) {
                    let minKeyColuna = 0;
                    let maxKeyColuna = 0;
                    angular.forEach(Object.keys(listaDefinicaoColunas), (prop) => {
                        const keyColuna = parseInt(prop.replace('p', ''), 10);
                        const coluna = listaDefinicaoColunas[prop];
                        if (((typeof coluna === 'undefined') || (coluna === null)) && (listaColunasRestantes.length > 0)) {
                            listaDefinicaoColunas[prop] = listaColunasRestantes[0];
                            listaColunasRestantes.splice(0, 1);
                        }
                        if (keyColuna < minKeyColuna) {
                            minKeyColuna = keyColuna;
                        }
                        if (keyColuna > maxKeyColuna) {
                            maxKeyColuna = keyColuna;
                        }
                    });
                    let posicaoColuna = 1;
                    let listaDefinicaoColunas2 = new Array();
                    for (let keyColuna = minKeyColuna; keyColuna <= maxKeyColuna; keyColuna++) {
                        if ((typeof listaDefinicaoColunas['p' + keyColuna] !== 'undefined') && (listaDefinicaoColunas['p' + keyColuna] !== null)) {
                            listaDefinicaoColunas['p' + keyColuna].posicao = posicaoColuna;
                            listaDefinicaoColunas2.push(listaDefinicaoColunas['p' + keyColuna]);
                            posicaoColuna++;
                        }
                    }
                    angular.forEach(listaOpcoes.columnDefs, (coluna) => {
                        if (listaDefinicaoColunas2.indexOf(coluna) === -1) {
                            listaDefinicaoColunas2.push(coluna);
                        }
                    });
                    listaOpcoes.columnDefs = listaDefinicaoColunas2;
                    this.scopeApply();
                }
            }
        });
    }
    carregaVisibilidadeColunasTabela(listaOpcoes) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof listaOpcoes === 'undefined') || !listaOpcoes ||
                (typeof listaOpcoes.columnDefs === 'undefined') || !listaOpcoes.columnDefs) {
                return;
            }
            let chaveTabela = '';
            let escopoAtual = this.$scope;
            let nivelAtual = 10;
            while (escopoAtual && (nivelAtual > 0)) {
                let chaveAtual = '';
                if ((typeof escopoAtual.$ctrl !== 'undefined') && escopoAtual.$ctrl &&
                    (typeof escopoAtual.$ctrl.$stateParams !== 'undefined') && escopoAtual.$ctrl.$stateParams) {
                    const parametros = escopoAtual.$ctrl.$stateParams;
                    chaveAtual += '[' +
                        'M=' + ((typeof parametros.modulo !== 'undefined') && parametros.modulo ? TextoUtil_1.TextoUtil.converteTextoParaReferencia(parametros.modulo) : '0') + ';' +
                        'T=' + ((typeof parametros.titulo !== 'undefined') && parametros.titulo ? TextoUtil_1.TextoUtil.converteTextoParaReferencia(parametros.titulo) : '0') + ';' +
                        'O=' + ((typeof parametros.operacao !== 'undefined') && parametros.operacao ? TextoUtil_1.TextoUtil.converteTextoParaReferencia(parametros.operacao) : '0') +
                        ']';
                }
                if (chaveAtual && (chaveAtual.length > 0) && (chaveAtual !== '[]')) {
                    if (chaveTabela && (chaveTabela.indexOf(chaveAtual) === -1)) {
                        chaveTabela = chaveAtual + chaveTabela;
                    }
                    else if (!chaveTabela) {
                        chaveTabela = chaveAtual;
                    }
                }
                if ((typeof escopoAtual.$parent !== 'undefined') && escopoAtual.$parent) {
                    escopoAtual = escopoAtual.$parent;
                }
                nivelAtual--;
            }
            if (!chaveTabela || (chaveTabela.length === 0) || (chaveTabela === '[]')) {
                let parametroRota = (location.href && (location.href.indexOf('#!/') !== -1) ? location.href : null);
                if (parametroRota && (parametroRota.length > 0)) {
                    chaveTabela = '[L=' + parametroRota.substring(parametroRota.indexOf('#!/') + 3) + ']';
                }
            }
            const filtro = {
                permiteDadosOffline: false,
                listaSimplificadaOnline: true,
                status: true,
                chaveTabela: chaveTabela,
                usuario: (this.usuario && this.usuario.id ? this.usuario.id :
                    (this.usuarioLogado && this.usuarioLogado.id ? this.usuarioLogado.id : null))
            };
            let lista = yield this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.VISIBILIDADE_COLUNA_TABELA)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.VISIBILIDADE_COLUNA_TABELA)
                .filtro(filtro)
                .listar();
            if ((typeof lista !== 'undefined') && lista) {
                let alterouVisibilidadeColunas = false;
                angular.forEach(lista, (visibilidadeColunaTabela) => {
                    let colunaGridEncontrada = null;
                    if ((typeof visibilidadeColunaTabela.tituloCampo !== 'undefined') && visibilidadeColunaTabela.tituloCampo) {
                        angular.forEach(listaOpcoes.columnDefs, (coluna) => {
                            if (!colunaGridEncontrada && (typeof coluna.displayName !== 'undefined') && coluna.displayName) {
                                if (coluna.displayName === visibilidadeColunaTabela.tituloCampo) {
                                    colunaGridEncontrada = coluna;
                                }
                            }
                        });
                    }
                    if (!colunaGridEncontrada) {
                        if ((typeof visibilidadeColunaTabela.nomeCampo !== 'undefined') && visibilidadeColunaTabela.nomeCampo) {
                            angular.forEach(listaOpcoes.columnDefs, (coluna) => {
                                if (!colunaGridEncontrada && (typeof coluna !== 'undefined') && coluna &&
                                    (typeof coluna.field !== 'undefined') && coluna.field &&
                                    (coluna.field === visibilidadeColunaTabela.nomeCampo)) {
                                    colunaGridEncontrada = coluna;
                                }
                            });
                        }
                    }
                    if (!colunaGridEncontrada) {
                        if ((typeof visibilidadeColunaTabela.nomeCampo !== 'undefined') && visibilidadeColunaTabela.nomeCampo) {
                            angular.forEach(listaOpcoes.columnDefs, (coluna) => {
                                if (!colunaGridEncontrada && (typeof coluna !== 'undefined') && coluna.name &&
                                    (typeof coluna.name !== 'undefined') && coluna.name &&
                                    (coluna.name === visibilidadeColunaTabela.nomeCampo)) {
                                    colunaGridEncontrada = coluna;
                                }
                            });
                        }
                    }
                    if (colunaGridEncontrada) {
                        if (typeof visibilidadeColunaTabela.campoVisivel !== 'undefined') {
                            if ((typeof colunaGridEncontrada.visible === 'undefined') || (colunaGridEncontrada.visible !== visibilidadeColunaTabela.campoVisivel)) {
                                colunaGridEncontrada.visible = visibilidadeColunaTabela.campoVisivel;
                                alterouVisibilidadeColunas = true;
                            }
                        }
                    }
                });
                if (alterouVisibilidadeColunas) {
                    this.scopeApplyTabela();
                }
            }
        });
    }
    scopeApplyTabela() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.timeoutScopeApplyTabela !== 'undefined') && (this.timeoutScopeApplyTabela !== null)) {
                clearTimeout(this.timeoutScopeApplyTabela);
                this.timeoutScopeApplyTabela = null;
            }
            this.timeoutScopeApplyTabela = setTimeout((controller) => __awaiter(this, void 0, void 0, function* () {
                controller.timeoutScopeApplyTabela = null;
                try {
                    controller.$scope.$apply();
                }
                catch (ex) {
                    controller.$scope.$applyAsync();
                    console.log(ex);
                }
            }), 250, this);
        });
    }
    usuarioSimplificado() {
        let obj = { id: null, uuid: null, status: null, email: null };
        const temUsuario = (typeof this.usuario !== 'undefined') && this.usuario ? true : false;
        const temUsuarioLogado = (typeof this.usuarioLogado !== 'undefined') && this.usuarioLogado ? true : false;
        obj.id = ((temUsuario && (typeof this.usuario.id !== 'undefined') && this.usuario.id) ? this.usuario.id :
            ((temUsuarioLogado && (typeof this.usuarioLogado.id !== 'undefined') && this.usuarioLogado.id) ? this.usuarioLogado.id : null));
        obj.uuid = ((temUsuario && (typeof this.usuario.uuid !== 'undefined') && this.usuario.uuid) ? this.usuario.uuid :
            ((temUsuarioLogado && (typeof this.usuarioLogado.uuid !== 'undefined') && this.usuarioLogado.uuid) ? this.usuarioLogado.uuid : null));
        obj.email = ((temUsuario && (typeof this.usuario.email !== 'undefined') && this.usuario.email) ? this.usuario.email :
            ((temUsuarioLogado && (typeof this.usuarioLogado.email !== 'undefined') && this.usuarioLogado.email) ? this.usuarioLogado.email : null));
        obj.nomeCompleto = ((temUsuario && (typeof this.usuario.nomeCompleto !== 'undefined') && this.usuario.nomeCompleto) ? this.usuario.nomeCompleto :
            ((temUsuarioLogado && (typeof this.usuarioLogado.nomeCompleto !== 'undefined') && this.usuarioLogado.nomeCompleto) ? this.usuarioLogado.nomeCompleto :
                ((temUsuario && (typeof this.usuario.nome !== 'undefined') && this.usuario.nome) ? this.usuario.nome :
                    ((temUsuarioLogado && (typeof this.usuarioLogado.nome !== 'undefined') && this.usuarioLogado.nome) ? this.usuarioLogado.nome : null))));
        return (obj.id || obj.uuid) ? obj : null;
    }
    carregarNotificacoes() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.notificacoes = new Array();
            this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.NOTIFICACAO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.NOTIFICACAO)
                .filtro({ status: true })
                .listar()
                .then((resposta) => {
                if ((typeof resposta !== 'undefined') && resposta) {
                    ctrl.notificacoes = resposta;
                }
            });
            this.usuarios = new Array();
            this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.USUARIO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.USUARIO)
                .filtro({ status: true })
                .listar()
                .then((resposta) => {
                if ((typeof resposta !== 'undefined') && resposta) {
                    ctrl.usuarios = resposta;
                }
            });
        });
    }
    ajustaFuncaoNotificacoes() {
        return __awaiter(this, void 0, void 0, function* () {
            let listaNotificacoes = ((typeof this.notificacoes !== 'undefined') && this.notificacoes ? this.notificacoes : []);
            const menuNotificacoes = document.getElementById('menuNotificacoesContainer');
            if ((typeof menuNotificacoes !== 'undefined') && menuNotificacoes) {
                menuNotificacoes.addEventListener('click', () => __awaiter(this, void 0, void 0, function* () {
                    yield this.atualizarNotificacoes();
                }));
            }
            angular.forEach(listaNotificacoes, (notificacao) => __awaiter(this, void 0, void 0, function* () {
                if ((typeof notificacao !== 'undefined') && notificacao &&
                    (typeof notificacao.id !== 'undefined') && notificacao.id &&
                    (typeof notificacao.listaUsuarios !== 'undefined') && notificacao.listaUsuarios) {
                    let itemVerifica = notificacao.listaUsuarios.find(o => ((String(o.usuario) === this.usuario.id)));
                    if ((typeof itemVerifica !== 'undefined') && itemVerifica &&
                        (typeof itemVerifica.visivel !== 'undefined') && itemVerifica.visivel) {
                        const itemNotificacoes = document.getElementById(`notificacao${notificacao.id}`);
                        if (itemNotificacoes) {
                            itemNotificacoes.addEventListener('click', () => __awaiter(this, void 0, void 0, function* () {
                                yield this.deletarNotificacao(notificacao.id);
                            }));
                        }
                    }
                }
            }));
        });
    }
    ajustarNotificacoes(atualizarLido = false) {
        return __awaiter(this, void 0, void 0, function* () {
            let notificacoesHtml = '';
            let listaNotificacoes = ((typeof this.notificacoes !== 'undefined') && this.notificacoes ? this.notificacoes : []);
            let totalNotificacoesNovas = 0;
            angular.forEach(listaNotificacoes, (notificacao) => {
                if ((typeof notificacao !== 'undefined') && notificacao &&
                    (typeof notificacao.listaUsuarios !== 'undefined') && notificacao.listaUsuarios) {
                    let formatoHtml = '';
                    let itemVerifica = notificacao.listaUsuarios.find(o => (String(o.usuario) === this.usuario.id));
                    if ((typeof itemVerifica !== 'undefined') && itemVerifica &&
                        (typeof itemVerifica.visivel !== 'undefined') && itemVerifica.visivel) {
                        if ((typeof itemVerifica.lido !== 'undefined') && !itemVerifica.lido &&
                            (typeof notificacao.dataInicio !== 'undefined') && notificacao.dataInicio && this.passouUmAno(notificacao.dataInicio)) {
                            totalNotificacoesNovas++;
                        }
                        else if ((typeof itemVerifica.lido !== 'undefined') && !itemVerifica.lido &&
                            (typeof notificacao.dataInicio === 'undefined') || !notificacao.dataInicio) {
                            totalNotificacoesNovas++;
                        }
                        formatoHtml = `
                        <li class='list-group-item list-group-item-action dropdown-notifications-item ${((typeof itemVerifica.lido !== 'undefined') && itemVerifica.lido) ? 'marked-as-read' : ''}'>
                            <div class='d-flex'>
                                <div class='flex-grow-1'>
                                    <h6 class='mb-1'>${((typeof notificacao.titulo !== 'undefined') && notificacao.titulo) ? notificacao.titulo : 'Notificação sem título definido'}</h6>
                                    <p class='mb-0'>${((typeof notificacao.descricao !== 'undefined') && notificacao.descricao) ? notificacao.descricao : 'Notificação sem descrição definida.'}</p>
                                    <small class='text-muted'>${((typeof notificacao.criacao !== 'undefined') && notificacao.criacao) ? this.calcularTempoPassado(notificacao.criacao) : 'Sem data definida.'}</small>
                                </div>
                                <div class='flex-shrink-0 dropdown-notifications-actions'>
                                    <a href='javascript:void(0)' class='dropdown-notifications-read'>
                                        <span class='badge badge-dot'></span>
                                    </a>
                                    <a href='javascript:void(0)' id='${((typeof notificacao.id !== 'undefined') && notificacao.id) ? `notificacao${notificacao.id}` : ''}' class='dropdown-notifications-archive'>
                                        <span class='bx bx-x'></span>
                                    </a>
                                </div>
                            </div>
                        </li>
                    `;
                        if ((typeof notificacao.dataInicio !== 'undefined') && notificacao.dataInicio && !this.passouUmAno(notificacao.dataInicio)) {
                            formatoHtml = '';
                        }
                    }
                    notificacoesHtml += formatoHtml;
                }
            });
            if (!atualizarLido) {
                angular.element('#conteudoListaNotificacoes').html(notificacoesHtml);
            }
            angular.element('#totalNotificacoesSistema')
                .removeClass('bg-danger')
                .removeClass('bg-secondary')
                .addClass((totalNotificacoesNovas > 0) ? 'bg-danger' : 'bg-secondary')
                .html(`${totalNotificacoesNovas}`);
        });
    }
    calcularTempoPassado(data) {
        let dataInicial;
        if (data instanceof Date) {
            dataInicial = data;
        }
        else {
            dataInicial = new Date(data);
        }
        if (isNaN(dataInicial.getTime())) {
            return 'Data de criação inválida.';
        }
        const agora = new Date();
        const diferencaEmMs = agora.getTime() - dataInicial.getTime();
        const minutos = Math.floor(diferencaEmMs / (1000 * 60));
        const horas = Math.floor(diferencaEmMs / (1000 * 60 * 60));
        const dias = Math.floor(diferencaEmMs / (1000 * 60 * 60 * 24));
        const anos = Math.floor(diferencaEmMs / (1000 * 60 * 60 * 24 * 365));
        if (anos >= 1) {
            return anos === 1 ? '1 ano atrás.' : `${anos} anos atrás.`;
        }
        else if (dias >= 1) {
            return dias === 1 ? '1 dia atrás.' : `${dias} dias atrás.`;
        }
        else if (horas >= 1) {
            return horas === 1 ? '1 hora atrás.' : `${horas} horas atrás.`;
        }
        else {
            return minutos === 1 ? '1 minuto atrás.' : `${minutos} minutos atrás.`;
        }
    }
    passouUmAno(data) {
        let dataInicial;
        if (data instanceof Date) {
            dataInicial = data;
        }
        else {
            dataInicial = new Date(data);
        }
        if (isNaN(dataInicial.getTime())) {
            return false;
        }
        const dataAtual = new Date();
        const diferencaAnos = dataAtual.getFullYear() - dataInicial.getFullYear();
        if (diferencaAnos > 1) {
            return true;
        }
        else if (diferencaAnos === 1) {
            if (dataAtual.getMonth() > dataInicial.getMonth() || (dataAtual.getMonth() === dataInicial.getMonth() && dataAtual.getDate() >= dataInicial.getDate())) {
                return true;
            }
        }
        return false;
    }
    atualizarNotificacoes() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                let listaNotificacoes = this.notificacoes;
                angular.forEach(listaNotificacoes, (notificacao) => __awaiter(this, void 0, void 0, function* () {
                    if ((typeof notificacao !== 'undefined') && notificacao &&
                        (typeof notificacao.listaUsuarios !== 'undefined') && notificacao.listaUsuarios) {
                        let listaUsuarios = notificacao.listaUsuarios;
                        let itemVerifica = notificacao.listaUsuarios.find(o => ((String(o.usuario) === this.usuario.id)));
                        if ((typeof itemVerifica !== 'undefined') && itemVerifica && (typeof itemVerifica.lido !== 'undefined') && !itemVerifica.lido &&
                            ((typeof notificacao.dataInicio !== 'undefined') && notificacao.dataInicio && this.passouUmAno(notificacao.dataInicio)) ||
                            (typeof notificacao.dataInicio === 'undefined') || !notificacao.dataInicio) {
                            notificacao.listaUsuarios = [];
                            angular.forEach(this.usuarios, (usuario) => {
                                if ((typeof usuario !== 'undefined') && usuario) {
                                    let obj = { id: null, uuid: null, status: null, email: null };
                                    const temUsuario = (typeof usuario !== 'undefined') && usuario ? true : false;
                                    obj.id = ((temUsuario && (typeof usuario.id !== 'undefined') && usuario.id) ? usuario.id : null);
                                    obj.uuid = ((temUsuario && (typeof usuario.uuid !== 'undefined') && usuario.uuid) ? usuario.uuid : null);
                                    obj.email = ((temUsuario && (typeof usuario.email !== 'undefined') && usuario.email) ? usuario.email : null);
                                    obj.nomeCompleto = ((temUsuario && (typeof usuario.nomeCompleto !== 'undefined') && usuario.nomeCompleto) ? usuario.nomeCompleto :
                                        ((temUsuario && (typeof usuario.nome !== 'undefined') && usuario.nome) ? usuario.nome : null));
                                    let itemVerifica = listaUsuarios.find(o => ((String(o.usuario) === obj.id)));
                                    if ((typeof itemVerifica !== 'undefined') && itemVerifica) {
                                        let itemUsuario = {
                                            id: null,
                                            uuid: null,
                                            usuario: obj,
                                            lido: itemVerifica.lido,
                                            status: itemVerifica.status,
                                            visivel: itemVerifica.visivel
                                        };
                                        if (obj.id === this.usuario.id) {
                                            itemUsuario.lido = true;
                                        }
                                        let verificaLista = notificacao.listaUsuarios.find(o => ((o.usuario.id === itemUsuario.usuario.id)));
                                        if ((typeof verificaLista === 'undefined') || !verificaLista) {
                                            notificacao.listaUsuarios.push(itemUsuario);
                                        }
                                    }
                                }
                            });
                            yield this.sincronia
                                .rota(RotaAPIConstantes_1.RotaAPIConstantes.NOTIFICACAO)
                                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.NOTIFICACAO)
                                .atualizar(notificacao);
                            yield this.carregarNotificacoes();
                            yield this.ajustarNotificacoes(true);
                        }
                    }
                }));
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
        });
    }
    deletarNotificacao(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                let notificacao = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.NOTIFICACAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.NOTIFICACAO)
                    .id(id)
                    .ver();
                if ((typeof notificacao !== 'undefined') && notificacao &&
                    (typeof notificacao.listaUsuarios !== 'undefined') && notificacao.listaUsuarios) {
                    let listaUsuarios = notificacao.listaUsuarios;
                    let itemVerifica = notificacao.listaUsuarios.find(o => ((String(o.usuario.id) === this.usuario.id)));
                    if ((typeof itemVerifica !== 'undefined') && itemVerifica &&
                        (typeof itemVerifica.visivel !== 'undefined') && itemVerifica.visivel) {
                        notificacao.listaUsuarios = [];
                        angular.forEach(this.usuarios, (usuario) => {
                            if ((typeof usuario !== 'undefined') && usuario) {
                                let obj = { id: null, uuid: null, status: null, email: null };
                                const temUsuario = (typeof usuario !== 'undefined') && usuario ? true : false;
                                obj.id = ((temUsuario && (typeof usuario.id !== 'undefined') && usuario.id) ? usuario.id : null);
                                obj.uuid = ((temUsuario && (typeof usuario.uuid !== 'undefined') && usuario.uuid) ? usuario.uuid : null);
                                obj.email = ((temUsuario && (typeof usuario.email !== 'undefined') && usuario.email) ? usuario.email : null);
                                obj.nomeCompleto = ((temUsuario && (typeof usuario.nomeCompleto !== 'undefined') && usuario.nomeCompleto) ? usuario.nomeCompleto :
                                    ((temUsuario && (typeof usuario.nome !== 'undefined') && usuario.nome) ? usuario.nome : null));
                                let itemNotificacao = listaUsuarios.find(o => ((String(o.usuario.id) === obj.id)));
                                if ((typeof itemNotificacao !== 'undefined') && itemNotificacao) {
                                    let itemUsuario = {
                                        id: null,
                                        uuid: null,
                                        usuario: obj,
                                        lido: itemNotificacao.lido,
                                        status: itemNotificacao.status,
                                        visivel: itemNotificacao.visivel
                                    };
                                    if (itemUsuario.usuario.id === this.usuario.id) {
                                        itemUsuario.visivel = false;
                                    }
                                    let verificaLista = notificacao.listaUsuarios.find(o => ((o.usuario.id === itemUsuario.usuario.id)));
                                    if ((typeof verificaLista === 'undefined') || !verificaLista) {
                                        notificacao.listaUsuarios.push(itemUsuario);
                                    }
                                }
                            }
                        });
                        yield this.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.NOTIFICACAO)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.NOTIFICACAO)
                            .atualizar(notificacao);
                        yield this.carregarNotificacoes();
                        yield this.ajustarNotificacoes();
                    }
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
        });
    }
    desabilitarOperacoes(desabilitar) {
        this.operacoesDesabilitadas = this.operacoesDesabilitadas.concat(desabilitar);
    }
    imprimir(id) {
        console.log('imprimir(' + ((typeof id !== 'undefined') && id ? id : '') + ')');
        if (this.operacao === ICRUDOperacao_1.CRUDOperacao.IMPRIMIR) {
            this.$window.print();
            return;
        }
        if ((typeof this.links !== 'undefined') && this.links &&
            (typeof this.links.IMPRIMIR !== 'undefined') && this.links.IMPRIMIR &&
            (typeof this.links.IMPRIMIR.rota !== 'undefined') && this.links.IMPRIMIR.rota) {
            const idImpressao = (typeof this.$state.params !== 'undefined') && this.$state.params &&
                (typeof this.$state.params.id !== 'undefined') && this.$state.params.id ? this.$state.params.id : null;
            const urlImpressao = this.$state.href(this.links.IMPRIMIR.rota, { id: idImpressao });
            if ((typeof urlImpressao !== 'undefined') && urlImpressao) {
                this.$window.open(urlImpressao, '_blank');
                return;
            }
        }
        this.$window.print();
    }
    ajusteLogoEmpresa() {
        return __awaiter(this, void 0, void 0, function* () {
            let logoEl = angular.element('img#itemMenuLogoEmpresa');
            if ((typeof logoEl === 'undefined') || !logoEl || (logoEl.length === 0)) {
                return;
            }
            let logoSrc = 'assets/img/logo_transparente_200x60.png';
            if ((typeof config_app_1.config.empresa !== 'undefined') && config_app_1.config.empresa &&
                (typeof config_app_1.config.empresa.logoMenu !== 'undefined') && config_app_1.config.empresa.logoMenu &&
                (typeof config_app_1.config.empresa.logoMenu.src !== 'undefined') && config_app_1.config.empresa.logoMenu.src &&
                (config_app_1.config.empresa.logoMenu.src.length > 0)) {
                logoSrc = config_app_1.config.empresa.logoMenu.src;
            }
            logoEl.attr('src', logoSrc);
        });
    }
    ajusteUsuario() {
        return __awaiter(this, void 0, void 0, function* () {
            this.usuario = yield AutenticacaoUtil_1.AutenticacaoUtil.usuario();
            const ehAutenticado = yield AutenticacaoUtil_1.AutenticacaoUtil.ehAutenticado();
            yield this.sincronizaPermissoes();
            if (ehAutenticado) {
                $('body').removeClass('nao-autorizado');
                $('body').addClass('autorizado');
                $('body').removeClass('bg-full-screen-image');
                $('body').removeClass('blank-page blank-page');
                if (this.usuario.nome) {
                    angular.element('.user-name').html(this.usuario.nome);
                }
                yield this.ajustarNotificacoes();
                yield this.ajustaFuncaoNotificacoes();
            }
            else {
                $('body').removeClass('autorizado');
                $('body').addClass('nao-autorizado');
                $('body').addClass('bg-full-screen-image');
                $('body').addClass('blank-page blank-page');
            }
            MenuUtil_1.MenuUtil.ajustaMenus(this.usuarioLogado);
        });
    }
    detectaOperacoes() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((this.$state.params.operacao === ICRUDOperacao_1.CRUDOperacao.LISTAR) || (this.$state.params.operacao === ICRUDOperacao_1.CRUDOperacao.FORMULARIO)) {
                let dadosTabela = yield this.dadosLista();
                yield this.carregaPosicaoColunasTabela(dadosTabela);
                yield this.carregaVisibilidadeColunasTabela(dadosTabela);
                this.listaOpcoes = dadosTabela;
                this.scopeApplyTabela();
                UiGridUtil_1.UiGridUtil.addLupa();
            }
            else if ((this.$state.params.operacao === ICRUDOperacao_1.CRUDOperacao.VISUALIZAR) ||
                (this.$state.params.operacao === ICRUDOperacao_1.CRUDOperacao.EDITAR) ||
                (this.$state.params.operacao === ICRUDOperacao_1.CRUDOperacao.DUPLICAR) ||
                (this.$state.params.operacao === ICRUDOperacao_1.CRUDOperacao.IMPRIMIR)) {
                this.modelo = yield this.visualizar(this.$state.params.id);
                $(document).on('keydown', (e) => {
                    if (e.ctrlKey && ((e.key === 'p') || (e.key === 'P'))) {
                        e.preventDefault();
                        this.imprimir(this.$state.params.id);
                    }
                });
                this.$scope.$apply();
            }
            this.atualizarFormulario();
        });
    }
    atualizarFormulario() {
        const form = angular.element('form');
        if ((this.operacao === ICRUDOperacao_1.CRUDOperacao.VISUALIZAR) ||
            (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) ||
            (this.operacao === ICRUDOperacao_1.CRUDOperacao.DUPLICAR) ||
            (this.operacao === ICRUDOperacao_1.CRUDOperacao.EDITAR)) {
            if (this.operacao === ICRUDOperacao_1.CRUDOperacao.VISUALIZAR) {
                form.find('input').prop('disabled', true);
                form.find('textarea').prop('disabled', true);
                form.find('checkbox').prop('disabled', true);
                $(document).on('keydown', (e) => {
                    if (e.key === 'e' && e.ctrlKey) {
                        e.preventDefault();
                        this.$timeout(() => {
                            $(document).find('botoes-operacao button#editar').trigger('click');
                        });
                    }
                });
            }
            else {
                $(form).on('keydown', (e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                    }
                });
            }
            $(document).on('keydown', (e) => {
                if (e.key === 'Backspace' && e.ctrlKey) {
                    e.preventDefault();
                    this.$timeout(() => {
                        angular.element(document).find('botoes-operacao button#voltar').triggerHandler('click');
                    });
                }
                else if (e.key === 'Delete' && e.ctrlKey) {
                    e.preventDefault();
                    this.$timeout(() => {
                        if (this.operacao === ICRUDOperacao_1.CRUDOperacao.VISUALIZAR || this.operacao === ICRUDOperacao_1.CRUDOperacao.EDITAR) {
                            const status = 'status';
                            if (this.modelo[status]) {
                                angular.element(document).find('botoes-operacao button#remover').triggerHandler('click');
                            }
                        }
                    });
                }
                else if (e.key === 'r' && e.ctrlKey && e.shiftKey) {
                    e.preventDefault();
                    this.$timeout(() => {
                        if (this.operacao === ICRUDOperacao_1.CRUDOperacao.VISUALIZAR || this.operacao === ICRUDOperacao_1.CRUDOperacao.EDITAR) {
                            angular.element(document).find('botoes-operacao button#recuperar').triggerHandler('click');
                        }
                    });
                }
            });
        }
        if ((this.operacao === ICRUDOperacao_1.CRUDOperacao.LISTAR) || (this.operacao === ICRUDOperacao_1.CRUDOperacao.FORMULARIO)) {
            $(document).off('keydown');
            $(document).on('keypress', (e) => {
                this.$timeout(() => {
                    if (e.key === '+') {
                        angular.element(document).find('botoes-operacao button#adicionar').triggerHandler('click');
                    }
                });
            });
        }
        if (this.operacao === ICRUDOperacao_1.CRUDOperacao.IMPRIMIR) {
            this.refreshPagePrint();
        }
    }
    refreshPageWidth() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof this.timeoutRefreshPageWidth !== 'undefined') && (this.timeoutRefreshPageWidth !== null)) {
                    clearTimeout(this.timeoutRefreshPageWidth);
                    this.timeoutRefreshPageWidth = null;
                }
                if ((typeof globalThis.timeoutRefreshPageWidth !== 'undefined') && (globalThis.timeoutRefreshPageWidth !== null)) {
                    clearTimeout(globalThis.timeoutRefreshPageWidth);
                    globalThis.timeoutRefreshPageWidth = null;
                }
                const tmpPageWidth = $(window).width();
                if (tmpPageWidth && (tmpPageWidth > 0)) {
                    let callScopeApply = false;
                    if ((typeof this.pageWidth === 'undefined') || !this.pageWidth || (this.pageWidth !== tmpPageWidth)) {
                        this.pageWidth = tmpPageWidth;
                    }
                    if ((typeof this.pageWidthSmall === 'undefined') || (this.pageWidthSmall !== (tmpPageWidth >= 576))) {
                        this.pageWidthSmall = (tmpPageWidth >= 576);
                        callScopeApply = true;
                    }
                    if ((typeof this.pageWidthMedium === 'undefined') || (this.pageWidthMedium !== (tmpPageWidth >= 768))) {
                        this.pageWidthMedium = (tmpPageWidth >= 768);
                        callScopeApply = true;
                    }
                    if ((typeof this.pageWidthLarge === 'undefined') || (this.pageWidthLarge !== (tmpPageWidth >= 992))) {
                        this.pageWidthLarge = (tmpPageWidth >= 992);
                        callScopeApply = true;
                    }
                    if ((typeof this.pageWidthExtraLarge === 'undefined') || (this.pageWidthExtraLarge !== (tmpPageWidth >= 1200))) {
                        this.pageWidthExtraLarge = (tmpPageWidth >= 1200);
                        callScopeApply = true;
                    }
                    if (callScopeApply) {
                        setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                            try {
                                ctrl.$scope.$apply();
                            }
                            catch (ex) {
                                console.log('AbstratoController.refreshPageWidth().scopeApply() > Error:');
                                console.log(ex);
                            }
                        }), 500, this);
                    }
                }
                this.timeoutRefreshPageWidth = setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                    ctrl.timeoutRefreshPageWidth = null;
                    ctrl.refreshPageWidth();
                }), 5000, this);
                globalThis.timeoutRefreshPageWidth = this.timeoutRefreshPageWidth;
            }
            catch (ex) {
                if ((typeof this.timeoutRefreshPageWidth !== 'undefined') && (this.timeoutRefreshPageWidth !== null)) {
                    clearTimeout(this.timeoutRefreshPageWidth);
                    this.timeoutRefreshPageWidth = null;
                }
                if ((typeof globalThis.timeoutRefreshPageWidth !== 'undefined') && (globalThis.timeoutRefreshPageWidth !== null)) {
                    clearTimeout(globalThis.timeoutRefreshPageWidth);
                    globalThis.timeoutRefreshPageWidth = null;
                }
                console.log('AbstratoController.refreshPageWidth() > Error:');
                console.log(ex);
                this.timeoutRefreshPageWidth = setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                    ctrl.timeoutRefreshPageWidth = null;
                    ctrl.refreshPageWidth();
                }), 3000, this);
                globalThis.timeoutRefreshPageWidth = this.timeoutRefreshPageWidth;
            }
        });
    }
    refreshPagePrint() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof this.timeoutRefreshPagePrint !== 'undefined') && (this.timeoutRefreshPagePrint !== null)) {
                    clearTimeout(this.timeoutRefreshPagePrint);
                    this.timeoutRefreshPagePrint = null;
                }
                if ((typeof globalThis.timeoutRefreshPagePrint !== 'undefined') && (globalThis.timeoutRefreshPagePrint !== null)) {
                    clearTimeout(globalThis.timeoutRefreshPagePrint);
                    globalThis.timeoutRefreshPagePrint = null;
                }
                let elMenus = angular.element('div#menu, .main-menu, .main-header-navbar, .header-navbar-shadow');
                if (elMenus && (elMenus.length > 0)) {
                    elMenus.remove();
                }
                let elBody = angular.element(document.body);
                if (elBody && (elBody.length > 0)) {
                    const styleBody = 'background: transparent !important;';
                    if (elBody.attr('style') !== styleBody) {
                        elBody.attr('style', styleBody);
                    }
                }
                let elAppContent = angular.element('.app-content');
                if (elAppContent && (elAppContent.length > 0)) {
                    const styleAppContent = 'left: 0px !important; width: 100% !important;';
                    if (elAppContent.attr('style') !== styleAppContent) {
                        elAppContent.attr('style', styleAppContent);
                    }
                    let elContentWrapper = elAppContent.find('.content-wrapper');
                    if (elContentWrapper && (elContentWrapper.length > 0)) {
                        const styleContentWrapper = 'margin-top: 0px !important;';
                        if (elContentWrapper.attr('style') !== styleContentWrapper) {
                            elContentWrapper.attr('style', styleContentWrapper);
                        }
                        let arrBtnsLinkedin = elContentWrapper.find('.btn-label-linkedin');
                        if (arrBtnsLinkedin && (arrBtnsLinkedin.length > 0)) {
                            arrBtnsLinkedin.remove();
                        }
                    }
                }
                let arrSelectizeInputs = angular.element('div.selectize-input.disabled');
                if (arrSelectizeInputs && (arrSelectizeInputs.length > 0)) {
                    arrSelectizeInputs.each((_key, el) => {
                        let elSelectizeInput = angular.element(el);
                        if (elSelectizeInput && (elSelectizeInput.length > 0)) {
                            const styleSelectizeInput = 'background: rgba(0,0,0,0) !important;';
                            if (elSelectizeInput.attr('style') !== styleSelectizeInput) {
                                elSelectizeInput.attr('style', styleSelectizeInput);
                            }
                            let elLabelSelectizeInput = elSelectizeInput.find('div > span');
                            if (elLabelSelectizeInput && (elLabelSelectizeInput.length > 0)) {
                                const styleLabelSelectizeInput = 'color: #354150 !important;';
                                if (elLabelSelectizeInput.attr('style') !== styleLabelSelectizeInput) {
                                    elLabelSelectizeInput.attr('style', styleLabelSelectizeInput);
                                }
                            }
                            let elSearchSelectizeInput = elSelectizeInput.find('input[type=search]');
                            if (elSearchSelectizeInput && (elSearchSelectizeInput.length > 0)) {
                                if (elSearchSelectizeInput.val() !== '-') {
                                    elSearchSelectizeInput.val('-');
                                }
                            }
                        }
                    });
                }
                this.timeoutRefreshPagePrint = setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                    ctrl.timeoutRefreshPagePrint = null;
                    ctrl.refreshPagePrint();
                }), 2000, this);
            }
            catch (ex) {
                if ((typeof this.timeoutRefreshPagePrint !== 'undefined') && (this.timeoutRefreshPagePrint !== null)) {
                    clearTimeout(this.timeoutRefreshPagePrint);
                    this.timeoutRefreshPagePrint = null;
                }
                if ((typeof globalThis.timeoutRefreshPagePrint !== 'undefined') && (globalThis.timeoutRefreshPagePrint !== null)) {
                    clearTimeout(globalThis.timeoutRefreshPagePrint);
                    globalThis.timeoutRefreshPagePrint = null;
                }
                console.log('AbstratoController.refreshPagePrint() > Error:');
                console.log(ex);
                this.timeoutRefreshPagePrint = setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                    ctrl.timeoutRefreshPagePrint = null;
                    ctrl.refreshPagePrint();
                }), 2000, this);
            }
        });
    }
}
exports.AbstratoController = AbstratoController;
