"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TipoEnxertoPomar = void 0;
var TipoEnxertoPomar;
(function (TipoEnxertoPomar) {
    TipoEnxertoPomar["PORTA_ENXERTO"] = "porta_enxerto";
    TipoEnxertoPomar["ENXERTO_ANO_1"] = "enxerto_ano_1";
    TipoEnxertoPomar["ENXERTO_ANO_2"] = "enxerto_ano_2";
    TipoEnxertoPomar["ENXERTO_ANO_3"] = "enxerto_ano_3";
})(TipoEnxertoPomar = exports.TipoEnxertoPomar || (exports.TipoEnxertoPomar = {}));
