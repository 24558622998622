"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReceitaSolucaoController = void 0;
const angular = require("angular");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const UiGridConstrutor_1 = require("../../../app/construtores/UiGridConstrutor");
const AbstratoController_1 = require("../../../app/AbstratoController");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const ICRUDOperacao_1 = require("../../../app/interfaces/ICRUDOperacao");
const ReceitaSolucao_1 = require("../../../../../modelos/ReceitaSolucao");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const config_app_1 = require("../../../../../config/config.app");
const ProdutoReceitaSolucao_1 = require("../../../../../modelos/ProdutoReceitaSolucao");
const HistoricoEstoqueProdutoFertilizante_1 = require("../../../../../modelos/HistoricoEstoqueProdutoFertilizante");
const sweetalert2_1 = require("sweetalert2");
class ReceitaSolucaoController extends AbstratoController_1.AbstratoController {
    constructor($scope, $injector) {
        super($scope, $injector, new ReceitaSolucao_1.ReceitaSolucao());
        this.path = '';
        this.caminho = '';
        this.tipoOperacao = null;
        this.produtosFertilizantes = [];
        this.contasDepositoSap = [];
        this.centrosCustoSap = [];
        this.modalProdutosReceita = false;
        this.posicaoTopoAntesModal = null;
        this.tipoOperacao = ICRUDOperacao_1.CRUDOperacao;
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.path = config_app_1.config.api.caminho;
            this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.RECEITA_SOLUCAO;
            this.modalProdutosReceita = false;
            if (this.operacao !== this.tipoOperacao.LISTAR) {
                this.carregarProdutosFertilizantes();
            }
            this.$scope.$apply();
        });
    }
    carregarProdutosFertilizantes() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.produtosFertilizantes = new Array();
            const promiseProdutosFertilizantes = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                .filtro({})
                .listar();
            promiseProdutosFertilizantes.then((resposta) => {
                if ((typeof resposta !== 'undefined') && resposta) {
                    ctrl.produtosFertilizantes = [...resposta];
                }
                console.log('Produtos Fertilizantes carregados: ' + this.produtosFertilizantes.length);
                ctrl.scopeApply();
            });
            ctrl.contasDepositoSap = new Array();
            const promiseContaDepositoSap = ctrl.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.CONTA_DEPOSITO_SAP)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CONTA_DEPOSITO_SAP)
                .filtro({})
                .listar();
            promiseContaDepositoSap.then((resposta) => {
                ctrl.contasDepositoSap.splice(0, ctrl.contasDepositoSap.length);
                ctrl.contasDepositoSap.push(...resposta);
                console.log('Contas Deposito SAP carregados: ' + ctrl.contasDepositoSap.length);
                ctrl.scopeApply();
            });
            ctrl.centrosCustoSap = new Array();
            const promiseCentroCustoSap = ctrl.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.CENTRO_CUSTO_SAP)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CENTRO_CUSTO_SAP)
                .filtro({})
                .listar();
            promiseCentroCustoSap.then((resposta) => {
                ctrl.centrosCustoSap.splice(0, ctrl.centrosCustoSap.length);
                ctrl.centrosCustoSap.push(...resposta);
                console.log('Centros Custos SAP carregados: ' + ctrl.centrosCustoSap.length);
                ctrl.scopeApply();
            });
        });
    }
    salvar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.modalProdutosReceita = false;
                if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.RECEITA_SOLUCAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.RECEITA_SOLUCAO)
                        .criar(this.modelo);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.RECEITA_SOLUCAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.RECEITA_SOLUCAO)
                        .atualizar(this.modelo);
                }
                console.log('teste');
                yield this.atualizarHistoricoProduto();
                console.log('teste 2');
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir uma nova Receita Solução ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir uma nova Receita Solução ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não'
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    visualizar(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const receitaSolucao = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.RECEITA_SOLUCAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.RECEITA_SOLUCAO)
                    .id(id)
                    .ver();
                receitaSolucao.criacao = new Date(receitaSolucao.criacao);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return receitaSolucao;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    remover(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const receitaSolucao = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.RECEITA_SOLUCAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.RECEITA_SOLUCAO)
                    .id(id)
                    .remover();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (receitaSolucao) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const receitaSolucao = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.RECEITA_SOLUCAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.RECEITA_SOLUCAO)
                    .id(this.modelo.id)
                    .restaurar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (receitaSolucao) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosLista(filtro = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                this.path = config_app_1.config.api.caminho;
                this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.RECEITA_SOLUCAO;
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                var auxColunas = Array();
                auxColunas.push({
                    name: 'codigo',
                    displayName: 'Código',
                    width: 90
                });
                auxColunas.push({
                    name: 'descricao',
                    displayName: 'Descrição - Receita Solução',
                    width: 320
                });
                auxColunas.push({
                    name: 'criacao',
                    displayName: 'Data/Hora Cadastro',
                    cellFilter: 'date:"dd/MM/yyyy HH:mm:ss"',
                    width: 190
                });
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
                const lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.RECEITA_SOLUCAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.RECEITA_SOLUCAO)
                    .filtro(filtro)
                    .listar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach(element => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    filtrar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    limparFiltro() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    exibirModalProdutosReceita() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.modelo === 'undefined') || !this.modelo) {
                return;
            }
            if ((typeof this.modelo.produtos === 'undefined') || (this.modelo.produtos === null)) {
                this.modelo.produtos = new Array();
            }
            try {
                try {
                    this.posicaoTopoAntesModal = angular.element(window).scrollTop();
                }
                catch (ex) {
                    this.posicaoTopoAntesModal = null;
                    console.log(ex);
                }
                this.carregarProdutosFertilizantes();
                this.modalProdutosReceita = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalProdutosReceita() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalProdutosReceita = false;
            angular.element('body').css('overflow-y', '');
            if ((typeof this.modelo !== 'undefined') && this.modelo &&
                (typeof this.modelo.produtos !== 'undefined') && this.modelo.produtos) {
                this.modelo.produtos = this.modelo.produtos.filter(o => (typeof o.produto !== 'undefined') && o.produto);
            }
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                        (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    moverProdutoReceitaParaCima(key) {
        if ((typeof this.modelo === 'undefined') || !this.modelo ||
            (typeof this.modelo.produtos === 'undefined') || !this.modelo.produtos) {
            return;
        }
        if ((key !== null) && (key > 0) && (key <= this.modelo.produtos.length - 1)) {
            let oldKey = key;
            let newKey = key - 1;
            this.modelo.produtos.splice(newKey, 0, this.modelo.produtos.splice(oldKey, 1)[0]);
            this.scopeApply();
        }
    }
    moverProdutoReceitaParaBaixo(key) {
        if ((typeof this.modelo === 'undefined') || !this.modelo ||
            (typeof this.modelo.produtos === 'undefined') || !this.modelo.produtos) {
            return;
        }
        if ((key != null) && (key >= 0) && (key < this.modelo.produtos.length - 1)) {
            let oldKey = key;
            let newKey = key + 1;
            this.modelo.produtos.splice(newKey, 0, this.modelo.produtos.splice(oldKey, 1)[0]);
            this.scopeApply();
        }
    }
    adicionarProdutoReceita() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.modelo === 'undefined') || !this.modelo) {
                return;
            }
            if ((typeof this.modelo.produtos === 'undefined') || (this.modelo.produtos === null)) {
                this.modelo.produtos = new Array();
            }
            this.modelo.produtos.push(new ProdutoReceitaSolucao_1.ProdutoReceitaSolucao());
            this.scopeApply();
        });
    }
    removerProdutoReceita(key) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.modelo === 'undefined') || !this.modelo) {
                return;
            }
            if ((typeof this.modelo.produtos === 'undefined') || (this.modelo.produtos === null)) {
                this.modelo.produtos = new Array();
            }
            if ((typeof key !== 'undefined') && (key !== null) &&
                (this.modelo.produtos.length > 0) &&
                (key <= this.modelo.produtos.length - 1)) {
                this.modelo.produtos.splice(key, 1);
                this.scopeApply();
            }
        });
    }
    atualizarHistoricoProduto() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.modelo.produtos !== 'undefined') && this.modelo.produtos && this.modelo.produtos.length > 0) {
                let listaProdutos = [];
                angular.forEach(this.modelo.produtos, (produto) => {
                    var _a;
                    let itemProduto = {
                        produto: produto.produto,
                        contaDepositoSap: produto.contaDepositoSap,
                        centroCustoSap: produto.centroCustoSap,
                        dosagemTotal: (_a = produto.dosagem) !== null && _a !== void 0 ? _a : 0
                    };
                    let findProduto = listaProdutos.find((o) => (o.produto.id === itemProduto.produto.id) &&
                        (o.centroCustoSap.id === itemProduto.centroCustoSap.id) && (o.contaDepositoSap.id === itemProduto.contaDepositoSap.id));
                    if ((typeof findProduto !== 'undefined') && findProduto) {
                        findProduto.dosagemTotal += itemProduto.dosagemTotal;
                    }
                    else {
                        listaProdutos.push(itemProduto);
                    }
                });
                for (const produtoFinal of listaProdutos) {
                    let produtoFertilizante = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                        .id(produtoFinal.produto.id)
                        .ver();
                    let historicoEstoqueProdutoFertilizante = new HistoricoEstoqueProdutoFertilizante_1.HistoricoEstoqueProdutoFertilizante();
                    if ((typeof produtoFertilizante !== 'undefined') && produtoFertilizante) {
                        historicoEstoqueProdutoFertilizante.produtoFertilizante = produtoFinal.produto;
                        historicoEstoqueProdutoFertilizante.quantidadeAnterior = (_a = produtoFertilizante.quantidadeAtual) !== null && _a !== void 0 ? _a : 0;
                        historicoEstoqueProdutoFertilizante.quantidadeMovimento = (_b = produtoFinal.dosagemTotal) !== null && _b !== void 0 ? _b : 0;
                        historicoEstoqueProdutoFertilizante.quantidadeAtual = historicoEstoqueProdutoFertilizante.quantidadeAnterior - historicoEstoqueProdutoFertilizante.quantidadeMovimento;
                        historicoEstoqueProdutoFertilizante.movimento = HistoricoEstoqueProdutoFertilizante_1.HistoricoEstoqueProdutoFertilizante.MOVIMENTO_SAIDA;
                        historicoEstoqueProdutoFertilizante.usuario = this.usuarioLogado;
                        historicoEstoqueProdutoFertilizante.centroCustoSap = produtoFinal.centroCustoSap;
                        historicoEstoqueProdutoFertilizante.contaDepositoSap = produtoFinal.contaDepositoSap;
                        produtoFertilizante.quantidadeAtual = historicoEstoqueProdutoFertilizante.quantidadeAtual;
                        yield this.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.HISTORICO_ESTOQUE_PRODUTO_FERTILIZANTE)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.HISTORICO_ESTOQUE_PRODUTO_FERTILIZANTE)
                            .criar(historicoEstoqueProdutoFertilizante);
                        yield this.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                            .atualizar(produtoFertilizante);
                    }
                }
            }
        });
    }
}
exports.ReceitaSolucaoController = ReceitaSolucaoController;
ReceitaSolucaoController.$inject = ['$scope', '$injector'];
