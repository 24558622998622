"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginController = void 0;
const angular = require("angular");
const jose = require("node-jose");
const config_app_1 = require("../../../../../config/config.app");
const Autenticacao_1 = require("../../../../../modelos/Autenticacao");
const JWT_1 = require("../../../../../modelos/JWT");
const AutenticacaoUtil_1 = require("../../../../../util/AutenticacaoUtil");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const AbstratoController_1 = require("../../AbstratoController");
const LoginTeste_1 = require("./LoginTeste");
const MenuUtil_1 = require("../../../../../util/MenuUtil");
const TextoUtil_1 = require("../../../../../util/TextoUtil");
const DispositivoUtil_1 = require("../../../../../util/DispositivoUtil");
class LoginController extends AbstratoController_1.AbstratoController {
    constructor($scope, $injector) {
        super($scope, $injector, new Autenticacao_1.Autenticacao(), true);
        this.ehLogin = true;
        this.empresas = new Array();
        this.empresaLogada = null;
        this.empresaTravada = null;
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            config_app_1.config.carregarEmpresaLogada();
            this.empresas = config_app_1.config.empresasFiltradas;
            this.empresaLogada = null;
            if ((typeof config_app_1.config.empresa !== 'undefined') && config_app_1.config.empresa) {
                this.empresaLogada = config_app_1.config.empresa;
                this.modelo.empresa = config_app_1.config.empresa.nomeCompleto ? config_app_1.config.empresa.nomeCompleto : config_app_1.config.empresa.nomeResumido;
            }
            this.empresaTravada = false;
            let travaEmpresaLogada = window.localStorage.getItem('travaEmpresaLogada');
            if ((typeof travaEmpresaLogada !== 'undefined') && travaEmpresaLogada && (travaEmpresaLogada === 'S')) {
                this.empresaTravada = true;
            }
            yield AutenticacaoUtil_1.AutenticacaoUtil.revogar();
            MenuUtil_1.MenuUtil.ajustaMenus(null);
        });
    }
    salvar() {
        return __awaiter(this, void 0, void 0, function* () {
        });
    }
    visualizar() {
        return __awaiter(this, void 0, void 0, function* () {
            return null;
        });
    }
    remover() {
        return __awaiter(this, void 0, void 0, function* () {
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
        });
    }
    dadosLista() {
        return __awaiter(this, void 0, void 0, function* () {
            return null;
        });
    }
    alterarEmpresa() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.empresas === 'undefined') || !this.empresas) {
                this.empresas = config_app_1.config.empresasFiltradas;
            }
            if ((typeof this.empresas !== 'undefined') && this.empresas &&
                (typeof this.modelo !== 'undefined') && this.modelo &&
                (typeof this.modelo.empresa !== 'undefined') && this.modelo.empresa && (this.modelo.empresa.trim().length > 0)) {
                this.empresaLogada = null;
                const refEmpresaLogin = TextoUtil_1.TextoUtil.converteTextoParaReferencia(this.modelo.empresa);
                let empresasFiltradas = new Array();
                angular.forEach(this.empresas, (empresa) => {
                    let adicionouEmpresa = false;
                    if (empresa && empresa.nomeCompleto && (TextoUtil_1.TextoUtil.converteTextoParaReferencia(empresa.nomeCompleto) === refEmpresaLogin)) {
                        if (!adicionouEmpresa) {
                            empresasFiltradas.push(empresa);
                            adicionouEmpresa = true;
                        }
                    }
                    else if (empresa && empresa.palavrasChaves) {
                        angular.forEach(empresa.palavrasChaves, (palavraChave) => {
                            if (!adicionouEmpresa && palavraChave && (palavraChave.length > 0)) {
                                const refPalavraChave = TextoUtil_1.TextoUtil.converteTextoParaReferencia(palavraChave);
                                if (refPalavraChave && (refPalavraChave.length > 0) && (refPalavraChave.substring(0, refEmpresaLogin.length) === refEmpresaLogin)) {
                                    empresasFiltradas.push(empresa);
                                    adicionouEmpresa = true;
                                }
                            }
                        });
                    }
                });
                if (empresasFiltradas.length === 1) {
                    this.empresaLogada = empresasFiltradas[0];
                    this.modelo.empresa = this.empresaLogada.nomeCompleto ? this.empresaLogada.nomeCompleto : this.empresaLogada.nomeResumido;
                    DispositivoUtil_1.DispositivoUtil.focalizarCampo('input#email');
                }
            }
        });
    }
    autenticar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof this.modelo === 'undefined') || !this.modelo) {
                    this.modelo = new Autenticacao_1.Autenticacao();
                }
                const empresaValida = (typeof this.modelo.empresa !== 'undefined') && this.modelo.empresa && (this.modelo.empresa.trim().length > 0) &&
                    (typeof this.empresaLogada !== 'undefined') && this.empresaLogada && (typeof this.empresaLogada.id !== 'undefined') && this.empresaLogada.id ? true : false;
                if (!empresaValida) {
                    this.notificacao.alerta('Empresa / Organização não informada.', 'Digite as primeiras letras da sua empresa que será completado automaticamente.');
                    DispositivoUtil_1.DispositivoUtil.focalizarCampo('input#empresa');
                    return;
                }
                window.localStorage.setItem('idEmpresaLogada', this.empresaLogada.id);
                config_app_1.config.carregarEmpresaLogada();
                const emailValido = (typeof this.modelo.email !== 'undefined') && this.modelo.email && (this.modelo.email.trim().length > 0) ? true : false;
                const senhaValida = (typeof this.modelo.senha !== 'undefined') && this.modelo.senha && (this.modelo.senha.trim().length > 0) ? true : false;
                if (!emailValido || !senhaValida) {
                    this.notificacao.alerta('Campos em branco', 'Senha ou email não informados.');
                    DispositivoUtil_1.DispositivoUtil.focalizarCampo(emailValido ? 'input#senha' : 'input#email');
                    return;
                }
                const key = yield jose.JWK.asKey(config_app_1.config.chavePublica, 'pem');
                const crypto = yield jose.JWE.createEncrypt({ format: 'compact' }, key).update(JSON.stringify(this.modelo)).final();
                const token = btoa(crypto);
                const jwe = new JWT_1.JWT();
                jwe.token = token;
                jwe.painel = true;
                jwe.login = this.modelo.email;
                jwe.pass = this.modelo.senha;
                let resposta = null;
                if ((this.modelo.email === LoginTeste_1.LoginTeste.emailPadrao) && (this.modelo.senha === LoginTeste_1.LoginTeste.senhaPadrao)) {
                    LoginTeste_1.LoginTeste.alerta();
                    resposta = LoginTeste_1.LoginTeste.respostaLogin();
                }
                else {
                    CarregamentoUtil_1.CarregamentoUtil.exibirLoad('form');
                    resposta = yield this.rest.post('credencial/email', jwe);
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                }
                if ((typeof resposta !== 'undefined') && resposta) {
                    if (!resposta.dados) {
                        this.notificacao.servidor(resposta);
                        return;
                    }
                    yield AutenticacaoUtil_1.AutenticacaoUtil.autorizar(resposta.dados);
                    let ehAutenticado = yield AutenticacaoUtil_1.AutenticacaoUtil.ehAutenticado();
                    if (ehAutenticado) {
                        this.$state.go('app.inicio');
                    }
                    else {
                        this.notificacao.alerta('Login e senha inválidos!', 'Autenticação recusada.');
                        return;
                    }
                }
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
}
exports.LoginController = LoginController;
LoginController.$inject = ['$scope', '$injector'];
