"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabMaturacaoConesController = void 0;
const angular = require("angular");
const LabMaturacaoCones_1 = require("../../../../../modelos/LabMaturacaoCones");
const AbstratoController_1 = require("../../../app/AbstratoController");
const ICRUDOperacao_1 = require("../../../app/interfaces/ICRUDOperacao");
const config_app_1 = require("../../../../../config/config.app");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const sweetalert2_1 = require("sweetalert2");
const UiGridConstrutor_1 = require("../../../app/construtores/UiGridConstrutor");
const DataUtil_1 = require("../../../../../util/DataUtil");
class LabMaturacaoConesController extends AbstratoController_1.AbstratoController {
    constructor($scope, $injector) {
        super($scope, $injector, new LabMaturacaoCones_1.LabMaturacaoCones());
        this.path = '';
        this.caminho = '';
        this.tipoOperacao = null;
        this.listaMaturacoesCones = [];
        this.listaMaturacoesConesCarregada = false;
        this.especiesMaturacoesCones = [];
        this.anosMaturacoesCones = [];
        this.pomares = [];
        this.especies = [];
        this.materiaisGeneticos = [];
        this.listaOpcoesRelatorio = null;
        this.listaOpcoesRelatorioResumo = null;
        this.listaDatas = [];
        this.filtroRelatorio = { especie: null, ano: null };
        this.tipoOperacao = ICRUDOperacao_1.CRUDOperacao;
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.path = config_app_1.config.api.caminho;
            this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.LAB_MATURACAO_CONES;
            if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR ||
                this.operacao === ICRUDOperacao_1.CRUDOperacao.EDITAR ||
                this.operacao === ICRUDOperacao_1.CRUDOperacao.DUPLICAR) {
                this.pomares = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POMAR)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.pomares = resposta;
                    }
                });
                this.especies = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESPECIE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESPECIE)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.especies = resposta;
                    }
                });
                this.materiaisGeneticos = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MATERIAL_GENETICO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.materiaisGeneticos = resposta;
                    }
                });
            }
            if (this.operacao === ICRUDOperacao_1.CRUDOperacao.LISTAR) {
                this.listaOpcoesRelatorio = {
                    data: [],
                    columnDefs: []
                };
                this.listaOpcoesRelatorioResumo = {
                    data: [],
                    columnDefs: []
                };
            }
            this.$scope.$apply();
        });
    }
    carregarListaMaturacoesCones(filtro = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
            this.especiesMaturacoesCones = [];
            this.anosMaturacoesCones = [];
            this.listaMaturacoesCones = yield this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_MATURACAO_CONES)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_MATURACAO_CONES)
                .filtro(filtro)
                .listar();
            angular.forEach(this.listaMaturacoesCones, (maturacaoCone) => {
                maturacaoCone.dataStr = maturacaoCone.data;
                if (typeof maturacaoCone.data === 'string') {
                    maturacaoCone.data = new Date(maturacaoCone.data);
                }
                if (typeof maturacaoCone.pomarData === 'string') {
                    maturacaoCone.pomarData = new Date(maturacaoCone.pomarData);
                }
                maturacaoCone.pomarDescricaoCompleta = ((typeof maturacaoCone.pomarDescricao !== 'undefined') && maturacaoCone.pomarDescricao ? maturacaoCone.pomarDescricao : '') +
                    ((typeof maturacaoCone.pomarData !== 'undefined') ? ' - ' + maturacaoCone.pomarData.getFullYear() : '');
                if ((typeof maturacaoCone.especieId !== 'undefined') && maturacaoCone.especieId &&
                    (typeof maturacaoCone.especieDescricao !== 'undefined') && maturacaoCone.especieDescricao) {
                    if (!ctrl.especiesMaturacoesCones.find(o => o.id === maturacaoCone.especieId)) {
                        ctrl.especiesMaturacoesCones.push({
                            id: maturacaoCone.especieId,
                            uuid: maturacaoCone.especieId,
                            codigo: null,
                            descricao: maturacaoCone.especieDescricao
                        });
                    }
                }
                if ((typeof maturacaoCone.data !== 'undefined') && maturacaoCone.data) {
                    if (!ctrl.anosMaturacoesCones.find(o => o === maturacaoCone.data.getFullYear())) {
                        ctrl.anosMaturacoesCones.push(maturacaoCone.data.getFullYear());
                    }
                }
            });
            this.listaMaturacoesConesCarregada = true;
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    salvar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_MATURACAO_CONES)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_MATURACAO_CONES)
                        .criar(this.modelo);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_MATURACAO_CONES)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_MATURACAO_CONES)
                        .atualizar(this.modelo);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir uma nova Maturação de Cones ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir uma nova Maturação de Cones ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    visualizar(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const labMaturacaoCones = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_MATURACAO_CONES)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_MATURACAO_CONES)
                    .id(id)
                    .ver();
                labMaturacaoCones.criacao = new Date(labMaturacaoCones.criacao);
                labMaturacaoCones.data = new Date(labMaturacaoCones.data);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return labMaturacaoCones;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    remover(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const labMaturacaoCones = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_MATURACAO_CONES)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_MATURACAO_CONES)
                    .id(id)
                    .remover();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (labMaturacaoCones) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const labMaturacaoCones = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_MATURACAO_CONES)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_MATURACAO_CONES)
                    .id(this.modelo.id)
                    .restaurar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (labMaturacaoCones) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    cellClicked(row) {
        if (row.entity && typeof row.entity.id !== 'undefined' && row.entity.id) {
            const nomeTabelaMaturacaoCones = 'tabelaMaturacaoCones';
            let gridGerminacoesApi = null;
            let gridMaturacaoConesScope = angular.element('#' + nomeTabelaMaturacaoCones).scope();
            if ((typeof gridMaturacaoConesScope.listaGridApi !== 'undefined') && gridMaturacaoConesScope.listaGridApi) {
                angular.forEach(gridMaturacaoConesScope.listaGridApi, (gridApi) => {
                    const grid = (typeof gridApi.grid !== 'undefined') && gridApi.grid ? gridApi.grid : null;
                    if (grid && (typeof grid.element !== 'undefined') && grid.element) {
                        const tabelasEl = angular.element(grid.element).parents('tabela');
                        if (tabelasEl && (tabelasEl.length > 0) && (tabelasEl.first().attr('id') === nomeTabelaMaturacaoCones)) {
                            gridGerminacoesApi = gridApi;
                        }
                    }
                });
            }
            if (gridGerminacoesApi && (typeof gridGerminacoesApi.selection !== 'undefined') && gridGerminacoesApi.selection) {
                gridGerminacoesApi.selection.selectRow(row.entity);
            }
        }
    }
    dadosRelatorio() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.path = config_app_1.config.api.caminho;
                this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.LAB_MATURACAO_CONES;
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                var auxColunas = Array();
                let listaRelatorio = [];
                this.listaDatas = [];
                this.listaMaturacoesCones.sort((a, b) => (a.data > b.data) ? 1 : ((b.data > a.data) ? -1 : 0));
                angular.forEach(this.listaMaturacoesCones, (maturacaoCone) => {
                    if ((typeof maturacaoCone.especieId !== 'undefined') && maturacaoCone.especieId &&
                        (typeof ctrl.filtroRelatorio.especie !== 'undefined') && ctrl.filtroRelatorio.especie &&
                        (maturacaoCone.especieId === ctrl.filtroRelatorio.especie.id) &&
                        (typeof maturacaoCone.data !== 'undefined') && maturacaoCone.data &&
                        (typeof ctrl.filtroRelatorio.ano !== 'undefined') && ctrl.filtroRelatorio.ano &&
                        (maturacaoCone.data.getFullYear() === ctrl.filtroRelatorio.ano)) {
                        let itemRelatorio = listaRelatorio.find(o => ((o.pomarId === maturacaoCone.pomarId) &&
                            (o.materialGeneticoId === maturacaoCone.materialGeneticoId)));
                        if ((typeof itemRelatorio === 'undefined') || !itemRelatorio) {
                            itemRelatorio = {
                                pomarId: maturacaoCone.pomarId,
                                pomarDescricao: maturacaoCone.pomarDescricao,
                                pomarDescricaoCompleta: maturacaoCone.pomarDescricaoCompleta,
                                materialGeneticoId: maturacaoCone.materialGeneticoId,
                                materialGeneticoDescricao: maturacaoCone.materialGeneticoDescricao,
                                datas: {}
                            };
                            listaRelatorio.push(itemRelatorio);
                        }
                        const dataFormatada = DataUtil_1.DataUtil.converterDataParaFormatoDDMM(maturacaoCone.data);
                        if (ctrl.listaDatas.indexOf(dataFormatada) === -1) {
                            ctrl.listaDatas.push(dataFormatada);
                        }
                        const dataAtributo = dataFormatada.replace('/', '_');
                        if ((typeof itemRelatorio.datas[dataAtributo] !== 'undefined') && itemRelatorio.datas[dataAtributo]) {
                            itemRelatorio.datas[dataAtributo].densidades.push(maturacaoCone.densidadeFinal);
                            let somaDensidades = 0;
                            angular.forEach(itemRelatorio.datas[dataAtributo].densidades, (densidade) => {
                                somaDensidades += (densidade ? densidade : 0);
                            });
                            itemRelatorio.datas[dataAtributo].media = Math.round((somaDensidades / itemRelatorio.datas[dataAtributo].densidades.length) * 100) / 100;
                        }
                        else {
                            itemRelatorio.datas[dataAtributo] = {
                                media: maturacaoCone.densidadeFinal,
                                densidades: [maturacaoCone.densidadeFinal]
                            };
                        }
                    }
                    else if (((typeof maturacaoCone.especieId !== 'undefined') && maturacaoCone.especieId) &&
                        ((typeof ctrl.filtroRelatorio.especie === 'undefined') || !ctrl.filtroRelatorio.especie) &&
                        ((typeof maturacaoCone.data !== 'undefined') && maturacaoCone.data) &&
                        ((typeof ctrl.filtroRelatorio.ano === 'undefined') || !ctrl.filtroRelatorio.ano)) {
                        let itemRelatorio = listaRelatorio.find(o => ((o.pomarId === maturacaoCone.pomarId) &&
                            (o.materialGeneticoId === maturacaoCone.materialGeneticoId)));
                        if ((typeof itemRelatorio === 'undefined') || !itemRelatorio) {
                            itemRelatorio = {
                                pomarId: maturacaoCone.pomarId,
                                pomarDescricao: maturacaoCone.pomarDescricao,
                                pomarDescricaoCompleta: maturacaoCone.pomarDescricaoCompleta,
                                materialGeneticoId: maturacaoCone.materialGeneticoId,
                                materialGeneticoDescricao: maturacaoCone.materialGeneticoDescricao,
                                datas: {}
                            };
                            listaRelatorio.push(itemRelatorio);
                        }
                        const dataFormatada = DataUtil_1.DataUtil.converterDataParaFormatoDDMM(maturacaoCone.data);
                        if (ctrl.listaDatas.indexOf(dataFormatada) === -1) {
                            ctrl.listaDatas.push(dataFormatada);
                        }
                        const dataAtributo = dataFormatada.replace('/', '_');
                        if ((typeof itemRelatorio.datas[dataAtributo] !== 'undefined') && itemRelatorio.datas[dataAtributo]) {
                            itemRelatorio.datas[dataAtributo].densidades.push(maturacaoCone.densidadeFinal);
                            let somaDensidades = 0;
                            angular.forEach(itemRelatorio.datas[dataAtributo].densidades, (densidade) => {
                                somaDensidades += (densidade ? densidade : 0);
                            });
                            itemRelatorio.datas[dataAtributo].media = Math.round((somaDensidades / itemRelatorio.datas[dataAtributo].densidades.length) * 100) / 100;
                        }
                        else {
                            itemRelatorio.datas[dataAtributo] = {
                                media: maturacaoCone.densidadeFinal,
                                densidades: [maturacaoCone.densidadeFinal]
                            };
                        }
                    }
                });
                auxColunas.push({
                    name: 'materialGeneticoDescricao',
                    displayName: 'Família',
                    width: 140,
                });
                auxColunas.push({
                    name: 'pomarDescricao',
                    displayName: 'Pomar',
                    width: 140,
                });
                angular.forEach(ctrl.listaDatas, (data) => {
                    const dataAtributo = data.replace('/', '_');
                    auxColunas.push({
                        name: 'datas.' + dataAtributo + '.media',
                        displayName: data,
                        visible: true,
                        width: 80,
                        cellClass: function (grid, row, col) {
                            if (grid.getCellValue(row, col) <= 0.88) {
                                return 'densidade-ideal';
                            }
                            else {
                                return '';
                            }
                        }
                    });
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: listaRelatorio,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            return null;
        });
    }
    dadosRelatorioResumo() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.path = config_app_1.config.api.caminho;
                this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.LAB_MATURACAO_CONES;
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                var auxColunas = Array();
                let listaRelatorio = [];
                this.listaMaturacoesCones.sort((a, b) => (a.data > b.data) ? 1 : ((b.data > a.data) ? -1 : 0));
                if ((typeof ctrl.filtroRelatorio.especie !== 'undefined') && ctrl.filtroRelatorio.especie &&
                    (typeof ctrl.filtroRelatorio.ano !== 'undefined') && ctrl.filtroRelatorio.ano) {
                    listaRelatorio = this.listaMaturacoesCones.filter(maturacaoCone => {
                        return ((typeof maturacaoCone.especieId !== 'undefined') && maturacaoCone.especieId &&
                            (typeof ctrl.filtroRelatorio.especie !== 'undefined') && ctrl.filtroRelatorio.especie &&
                            (maturacaoCone.especieId === ctrl.filtroRelatorio.especie.id) &&
                            (typeof maturacaoCone.data !== 'undefined') && maturacaoCone.data &&
                            (typeof ctrl.filtroRelatorio.ano !== 'undefined') && ctrl.filtroRelatorio.ano &&
                            (maturacaoCone.data.getFullYear() === ctrl.filtroRelatorio.ano) &&
                            (maturacaoCone.densidadeFinal <= 0.88));
                    });
                }
                else {
                    listaRelatorio = this.listaMaturacoesCones.filter(maturacaoCone => {
                        return ((typeof maturacaoCone.especieId !== 'undefined') && maturacaoCone.especieId &&
                            (typeof maturacaoCone.data !== 'undefined') && maturacaoCone.data &&
                            (maturacaoCone.densidadeFinal <= 0.88));
                    });
                }
                auxColunas.push({
                    name: 'dataStr',
                    displayName: 'Data',
                    width: 120,
                    cellFilter: 'date:"dd/MM/yyyy"',
                });
                auxColunas.push({
                    name: 'materialGeneticoDescricao',
                    displayName: 'Família',
                    width: 140,
                });
                auxColunas.push({
                    name: 'pomarDescricao',
                    displayName: 'Pomar',
                    width: 140,
                });
                auxColunas.push({
                    name: 'densidadeFinal',
                    displayName: 'Densidade Final',
                    width: 140,
                    cellClass: 'densidade-ideal-result'
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: listaRelatorio,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            return null;
        });
    }
    dadosLista(filtro = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                this.path = config_app_1.config.api.caminho;
                this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.LAB_MATURACAO_CONES;
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                var auxColunas = Array();
                auxColunas.push({
                    name: 'pomarDescricaoCompleta',
                    displayName: 'Pomar',
                    width: 160,
                });
                auxColunas.push({
                    name: 'materialGeneticoDescricao',
                    displayName: 'Material Genético',
                    width: 140,
                });
                auxColunas.push({
                    name: 'especieDescricao',
                    displayName: 'Espécie',
                    width: 140,
                });
                auxColunas.push({
                    name: 'dataStr',
                    displayName: 'Data',
                    width: 120,
                    cellFilter: 'date:"dd/MM/yyyy"',
                });
                auxColunas.push({
                    name: 'densidadeFinal',
                    displayName: 'Densidade Final',
                    width: 140,
                });
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                if (!this.listaMaturacoesConesCarregada) {
                    yield this.carregarListaMaturacoesCones(filtro);
                }
                const gridOption = {
                    data: this.listaMaturacoesCones,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    filtrar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    limparFiltro() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    calcularDensidadeN1() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let mN1 = (_a = this.modelo.pesoN1) !== null && _a !== void 0 ? _a : 0;
            let vN1 = this.modelo.volumeN1 && this.modelo.volumeN1 !== 0
                ? this.modelo.volumeN1
                : 1;
            this.modelo.densidadeN1 = parseFloat((mN1 / vN1).toFixed(2));
            this.calcularDensidadeFinal();
        });
    }
    calcularDensidadeN2() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let mN2 = (_a = this.modelo.pesoN2) !== null && _a !== void 0 ? _a : 0;
            let vN2 = this.modelo.volumeN2 && this.modelo.volumeN2 !== 0
                ? this.modelo.volumeN2
                : 1;
            this.modelo.densidadeN2 = parseFloat((mN2 / vN2).toFixed(2));
            this.calcularDensidadeFinal();
        });
    }
    calcularDensidadeS1() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let mS1 = (_a = this.modelo.pesoS1) !== null && _a !== void 0 ? _a : 0;
            let vS1 = this.modelo.volumeS1 && this.modelo.volumeS1 !== 0
                ? this.modelo.volumeS1
                : 1;
            this.modelo.densidadeS1 = parseFloat((mS1 / vS1).toFixed(2));
            this.calcularDensidadeFinal();
        });
    }
    calcularDensidadeS2() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let mS2 = (_a = this.modelo.pesoS2) !== null && _a !== void 0 ? _a : 0;
            let vS2 = this.modelo.volumeS2 && this.modelo.volumeS2 !== 0
                ? this.modelo.volumeS2
                : 1;
            this.modelo.densidadeS2 = parseFloat((mS2 / vS2).toFixed(2));
            this.calcularDensidadeFinal();
        });
    }
    calcularDensidadeFinal() {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            let dN1 = (_a = this.modelo.densidadeN1) !== null && _a !== void 0 ? _a : 0;
            let dN2 = (_b = this.modelo.densidadeN2) !== null && _b !== void 0 ? _b : 0;
            let dS1 = (_c = this.modelo.densidadeS1) !== null && _c !== void 0 ? _c : 0;
            let dS2 = (_d = this.modelo.densidadeS2) !== null && _d !== void 0 ? _d : 0;
            this.modelo.densidadeFinal = parseFloat(((dN1 + dN2 + dS1 + dS2) / 4).toFixed(2));
        });
    }
    filtrarRelatorio() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoesRelatorio = yield this.dadosRelatorio();
            this.listaOpcoesRelatorioResumo = yield this.dadosRelatorioResumo();
            this.scopeApply();
        });
    }
}
exports.LabMaturacaoConesController = LabMaturacaoConesController;
LabMaturacaoConesController.$inject = ['$scope', '$injector'];
