"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiltroControleEnxertiaPomarTratado = exports.FiltroControleEnxertiaPomar = void 0;
class FiltroControleEnxertiaPomar {
    constructor() {
        this.dataIni = null;
        this.dataFim = null;
    }
}
exports.FiltroControleEnxertiaPomar = FiltroControleEnxertiaPomar;
class FiltroControleEnxertiaPomarTratado {
    constructor() {
        this.pomar = null;
    }
}
exports.FiltroControleEnxertiaPomarTratado = FiltroControleEnxertiaPomarTratado;
