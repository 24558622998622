"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProducaoMudaClonalMiniestaquiaAvaliacaoSobrevivenciaCasaSombra = void 0;
const Base_1 = require("./Base");
class ProducaoMudaClonalMiniestaquiaAvaliacaoSobrevivenciaCasaSombra extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.data = null;
        this.clone = null;
        this.numRestanteMudas = null;
    }
}
exports.ProducaoMudaClonalMiniestaquiaAvaliacaoSobrevivenciaCasaSombra = ProducaoMudaClonalMiniestaquiaAvaliacaoSobrevivenciaCasaSombra;
