"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OutraOperacaoPomar = void 0;
const Base_1 = require("./Base");
const uuid_1 = require("uuid");
class OutraOperacaoPomar extends Base_1.Base {
    constructor() {
        super();
        this.id = null;
        this.guid = null;
        this.guid = uuid_1.v4();
        this.status = true;
    }
}
exports.OutraOperacaoPomar = OutraOperacaoPomar;
