"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TipoManejo = void 0;
const Base_1 = require("./Base");
class TipoManejo extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.descricao = null;
        this.corMontagemCroqui = null;
    }
}
exports.TipoManejo = TipoManejo;
