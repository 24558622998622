"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TalhaoController = void 0;
const angular = require("angular");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const UiGridConstrutor_1 = require("../../../app/construtores/UiGridConstrutor");
const AbstratoController_1 = require("../../../app/AbstratoController");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const ICRUDOperacao_1 = require("../../../app/interfaces/ICRUDOperacao");
const Talhao_1 = require("../../../../../modelos/Talhao");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const PlanilhaFactory_1 = require("../../../ferramenta/fabricas/PlanilhaFactory");
const config_app_1 = require("../../../../../config/config.app");
const DataUtil_1 = require("../../../../../util/DataUtil");
const ConfiguracaoGeralController_1 = require("../../../ferramenta/paginas/configuracaoGeral/ConfiguracaoGeralController");
const TextoUtil_1 = require("../../../../../util/TextoUtil");
const sweetalert2_1 = require("sweetalert2");
class TalhaoController extends AbstratoController_1.AbstratoController {
    constructor($scope, $injector, planilha) {
        super($scope, $injector, new Talhao_1.Talhao());
        this.path = '';
        this.caminho = '';
        this.tipoOperacao = null;
        this.importarArcGIS = false;
        this.planilha = null;
        this.regioes = [];
        this.modalSincronizarDadosAPI = null;
        this.tituloSincronizacaoAPI = null;
        this.itensSincronizacaoAPI = null;
        this.tipoOperacao = ICRUDOperacao_1.CRUDOperacao;
        this.planilha = planilha;
        this.importarArcGIS = true;
        this.modalSincronizarDadosAPI = false;
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.importar = true;
            this.importarArcGIS = true;
            this.path = config_app_1.config.api.caminho;
            this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.TALHAO;
            this.modalSincronizarDadosAPI = false;
            if (this.operacao !== ICRUDOperacao_1.CRUDOperacao.LISTAR) {
                this.regioes = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.REGIAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.REGIAO)
                    .listar();
            }
            this.$scope.$apply();
        });
    }
    salvar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.TALHAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TALHAO)
                        .criar(this.modelo);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.TALHAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TALHAO)
                        .atualizar(this.modelo);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir um novo Talhão ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir um novo Talhão ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não'
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    visualizar(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const talhao = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TALHAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TALHAO)
                    .id(id)
                    .ver();
                talhao.criacao = (typeof talhao.criacao !== 'undefined') && talhao.criacao ? DataUtil_1.DataUtil.converterValorParaDataHora(talhao.criacao) : new Date();
                talhao.dataPlantio = (typeof talhao.dataPlantio !== 'undefined') && talhao.dataPlantio ? DataUtil_1.DataUtil.converterValorParaDataHora(talhao.dataPlantio) : null;
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return talhao;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    remover(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const talhao = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TALHAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TALHAO)
                    .id(id)
                    .remover();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (talhao) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const talhao = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TALHAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TALHAO)
                    .id(this.modelo.id)
                    .restaurar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (talhao) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosLista(filtro = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                this.path = config_app_1.config.api.caminho;
                this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.TALHAO;
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                var auxColunas = Array();
                auxColunas.push({
                    name: 'regiaoNome',
                    displayName: 'Região',
                    width: 280
                });
                auxColunas.push({
                    name: 'numero',
                    displayName: 'Nº Talhão',
                    width: 140
                });
                auxColunas.push({
                    name: 'areaTalhao',
                    displayName: 'Área Talhão',
                    width: 140
                });
                auxColunas.push({
                    name: 'dataPlantio',
                    displayName: 'Data Plantio',
                    width: 150,
                    cellFilter: 'date:"dd/MM/yyyy"'
                });
                auxColunas.push({
                    name: 'tipoFloresta',
                    displayName: 'Tipo Floresta',
                    width: 230
                });
                auxColunas.push({
                    name: 'arvores',
                    displayName: 'Árvores',
                    width: 140
                });
                auxColunas.push({
                    name: 'sgif_id',
                    displayName: 'SGIF Id.',
                    width: 160
                });
                auxColunas.push({
                    name: 'criacao',
                    displayName: 'Data/Hora Cadastro',
                    cellFilter: 'date:"dd/MM/yyyy HH:mm:ss"',
                    width: 190
                });
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
                const lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TALHAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TALHAO)
                    .filtro(filtro)
                    .listar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach(element => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    filtrar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    limparFiltro() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    abrirPlanilhaModal($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.planilha.abrir($event, SincroniaConstantes_1.SincroniaConstantes.TALHAO, false);
        });
    }
    exibirModalSincronizarDadosAPI(nomeTarefa) {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalSincronizarDadosAPI = true;
            angular.element('body').css('overflow-y', 'hidden');
            this.scopeApply();
            setTimeout(this.iniciarSincronizacaoDadosAPI, 500, this, nomeTarefa);
        });
    }
    ocultarModalSincronizarDadosAPI() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalSincronizarDadosAPI = false;
            this.tituloSincronizacaoAPI = null;
            angular.element('body').css('overflow-y', '');
            this.scopeApply();
        });
    }
    iniciarSincronizacaoDadosAPI($ctrl, nomeTarefa) {
        console.log('iniciarSincronizacaoDadosAPI: Begin');
        let urlBaseSincronia = null;
        let strSincronizando = 'Sincronizando';
        let strSincronizado = 'Sincronizado';
        const refNomeTarefa = TextoUtil_1.TextoUtil.converteTextoParaReferencia(((typeof nomeTarefa !== 'undefined') && nomeTarefa) ? nomeTarefa : '');
        if (refNomeTarefa === TextoUtil_1.TextoUtil.converteTextoParaReferencia('sincronizarDadosSGIF')) {
            urlBaseSincronia = config_app_1.config.api.caminho + RotaAPIConstantes_1.RotaAPIConstantes.SINCRONIZAR_DADOS_SGIF + '?q={q}&log=true';
        }
        else if (refNomeTarefa === TextoUtil_1.TextoUtil.converteTextoParaReferencia('sincronizarDadosArcGIS')) {
            urlBaseSincronia = config_app_1.config.api.caminho + RotaAPIConstantes_1.RotaAPIConstantes.SINCRONIZAR_DADOS_ARCGIS + '?q={q}&log=true';
        }
        if (!urlBaseSincronia) {
            this.ocultarModalSincronizarDadosAPI();
            alert('Não foi possível determinar o caminho da sincronização com a API. (urlBaseSincronia está vazia)');
            return;
        }
        let listaParametrossincronizacaoAPI = new Array();
        angular.forEach($ctrl.itensSincronizacaoAPI, (item, key) => {
            let url = urlBaseSincronia.replace(/{q}/gi, item.nome);
            if ((typeof item.filtro !== 'undefined') && (item.filtro !== null)) {
                url = url.replace(/{f}/gi, item.filtro);
            }
            else {
                url = url.replace(/&f={f}/gi, '');
            }
            listaParametrossincronizacaoAPI.push(new ConfiguracaoGeralController_1.ParametrosincronizacaoAPI(url, angular.element('#logStatusSincronia_' + item.nome + '_' + key)));
        });
        const sincronizaAPI = function () {
            if (angular.element('#modalSincronizarDadosAPI').length === 0) {
                return;
            }
            for (let i = 0; i <= listaParametrossincronizacaoAPI.length - 1; i++) {
                if (angular.element('#modalSincronizarDadosAPI').length === 0) {
                    return;
                }
                if ((!listaParametrossincronizacaoAPI[i].sincronizacaoInciada) && (!listaParametrossincronizacaoAPI[i].sincronizacaoConcluida)) {
                    listaParametrossincronizacaoAPI[i].sincronizacaoInciada = true;
                    listaParametrossincronizacaoAPI[i].sincronizacaoConcluida = false;
                    listaParametrossincronizacaoAPI[i].logStatusEl.find('span.float-right').first().text(strSincronizando + '...').css('color', '#26789a');
                    if (listaParametrossincronizacaoAPI[i].logStatusEl.find('p').length === 0) {
                        listaParametrossincronizacaoAPI[i].logStatusEl.append('<p><pre></pre></p>');
                    }
                    if (listaParametrossincronizacaoAPI[i].logStatusEl.find('p').first().find('pre').length === 0) {
                        listaParametrossincronizacaoAPI[i].logStatusEl.find('p').first().append('<pre></pre>');
                    }
                    listaParametrossincronizacaoAPI[i].ajax = new XMLHttpRequest();
                    listaParametrossincronizacaoAPI[i].ajax.onreadystatechange = function () {
                        if (this.readyState >= 4) {
                            listaParametrossincronizacaoAPI[i].sincronizacaoConcluida = true;
                            if (this.responseText.indexOf('> Fim') !== -1) {
                                listaParametrossincronizacaoAPI[i].logStatusEl.find('span.float-right').first().text(strSincronizado + '!').css('color', '#2ea225');
                            }
                            else if ((this.responseText.indexOf('Concluído') !== -1) || (this.responseText.indexOf('Concluido') !== -1)) {
                                listaParametrossincronizacaoAPI[i].logStatusEl.find('span.float-right').first().text('Nenhum registro processado!').css('color', '#3e597e');
                            }
                            else {
                                listaParametrossincronizacaoAPI[i].logStatusEl.find('span.float-right').first().text('Falha! Tente novamente.').css('color', '#b92828');
                            }
                            setTimeout(() => {
                                sincronizaAPI();
                            }, 250);
                        }
                        if (this.readyState >= 3) {
                            if ((this.responseText !== null) && (this.responseText.length > 0)) {
                                let logArr = this.responseText.split('\r\n');
                                for (let i = logArr.length - 1; i >= 0; i--) {
                                    let s1 = logArr[i].toLowerCase();
                                    if ((s1.indexOf('registro processado') !== -1) || (s1.indexOf('registros processados') !== -1)) {
                                        for (let k = i - 1; k >= 0; k--) {
                                            let s2 = logArr[k].toLowerCase();
                                            if ((s2.indexOf('registro processado') !== -1) || (s2.indexOf('registros processados') !== -1)) {
                                                logArr.splice(k, 1);
                                            }
                                        }
                                        break;
                                    }
                                }
                                let logText = '';
                                logArr.forEach((log) => {
                                    if ((log !== null) && (log.trim().length > 0)) {
                                        logText += log + '\r\n';
                                    }
                                });
                                listaParametrossincronizacaoAPI[i].logStatusEl.find('p').first().find('pre').first().html(logText);
                            }
                        }
                    };
                    console.log('URL Sincronização ' + listaParametrossincronizacaoAPI[i].logStatusEl.find('span').first().text() + ' -> ' + listaParametrossincronizacaoAPI[i].url);
                    listaParametrossincronizacaoAPI[i].ajax.open('GET', listaParametrossincronizacaoAPI[i].url, true);
                    listaParametrossincronizacaoAPI[i].ajax.send();
                    break;
                }
            }
        };
        sincronizaAPI();
        console.log('iniciarSincronizacaoDadosAPI: End');
    }
    sincronizarDadosArcGIS() {
        return __awaiter(this, void 0, void 0, function* () {
            this.itensSincronizacaoAPI = new Array();
            this.itensSincronizacaoAPI.push(...[
                new ConfiguracaoGeralController_1.ItemSincronizacaoAPI('tokenArcGIS', 'ArcGIS - Token de Autenticação'),
                new ConfiguracaoGeralController_1.ItemSincronizacaoAPI('fazendasArcGIS', 'ArcGIS - Consulta Fazendas'),
                new ConfiguracaoGeralController_1.ItemSincronizacaoAPI('regioesArcGIS', 'ArcGIS - Consulta Regiões'),
                new ConfiguracaoGeralController_1.ItemSincronizacaoAPI('talhoesArcGIS', 'ArcGIS - Consulta Talhões'),
                new ConfiguracaoGeralController_1.ItemSincronizacaoAPI('regiao', 'Importa Regiões'),
                new ConfiguracaoGeralController_1.ItemSincronizacaoAPI('talhao', 'Importa Talhões'),
            ]);
            this.tituloSincronizacaoAPI = 'Status da Sincronização com o ArcGIS';
            this.exibirModalSincronizarDadosAPI('sincronizarDadosArcGIS');
            this.scopeApply();
        });
    }
}
exports.TalhaoController = TalhaoController;
TalhaoController.$inject = ['$scope', '$injector', PlanilhaFactory_1.PlanilhaFactory.id];
