"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProtecaoRegistroCultivar = void 0;
const Base_1 = require("./Base");
class ProtecaoRegistroCultivar extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.clone = null;
        this.nomeCultivar = null;
        this.registro = null;
        this.dataRegistro = null;
        this.numRegistro = null;
        this.protecao = null;
        this.dataProtecao = null;
        this.numProtecao = null;
        this.testeDheVcu = null;
        this.a4Meses = null;
        this.a6Meses = null;
        this.a1Ano = null;
        this.a3Anos = null;
        this.a5Anos = null;
        this.densidade = null;
        this.adultoFloracao = null;
        this.dna = null;
        this.inscricaoA = null;
        this.inscricaoB = null;
        this.certificadoA = null;
        this.certificadoB = null;
        this.anuidades = null;
        this.listaAnuidadesProgramadas = [];
        this.listaAnuidadesProgramadasFormatadas = [];
        this.listaAnuidadesProtetores = [];
    }
}
exports.ProtecaoRegistroCultivar = ProtecaoRegistroCultivar;
