"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HistoricoEstoquePolenPolinizacaoPomar = void 0;
const Base_1 = require("./Base");
class HistoricoEstoquePolenPolinizacaoPomar extends Base_1.Base {
    constructor() {
        super();
        this.id = null;
        this.codigo = null;
        this.pomar = null;
        this.estoquePolenPolinizacaoPomar = null;
        this.quantidadeColetadaAnterior = null;
        this.quantidadeColetadaMovimento = null;
        this.quantidadeColetadaAtual = null;
        this.quantidadeAnterior = null;
        this.quantidadeMovimento = null;
        this.quantidadeAtual = null;
        this.movimento = null;
        this.usuario = null;
        this.status = true;
    }
}
exports.HistoricoEstoquePolenPolinizacaoPomar = HistoricoEstoquePolenPolinizacaoPomar;
