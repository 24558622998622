"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OperacaoAdubacaoFertilizacaoPomar = void 0;
const Base_1 = require("./Base");
const uuid_1 = require("uuid");
class OperacaoAdubacaoFertilizacaoPomar extends Base_1.Base {
    constructor() {
        super();
        this.id = null;
        this.guid = null;
        this.tipoAplicacao = null;
        this.produto = null;
        this.dosagemProduto = null;
        this.contaDepositoSap = null;
        this.centroCustoSap = null;
        this.guid = uuid_1.v4();
        this.status = true;
    }
}
exports.OperacaoAdubacaoFertilizacaoPomar = OperacaoAdubacaoFertilizacaoPomar;
