"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UploadComponente = void 0;
const UploadController_1 = require("./UploadController");
class UploadComponente {
    constructor() {
        this.controller = UploadController_1.UploadController;
        this.templateUrl = require('./upload.componente.html').default;
        this.bindings = {
            id: '@',
            lista: '=',
            planilhas: '@',
            imagens: '@',
            mapas: '@'
        };
    }
}
exports.UploadComponente = UploadComponente;
UploadComponente.id = 'uploadComponente';
