"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RevTabelaUtil = exports.RevTabelaInfo = void 0;
const angular = require("angular");
const RotaAPIConstantes_1 = require("../constantes/RotaAPIConstantes");
class RevTabelaInfo {
    constructor() {
        this.ativo = false;
        this.executando = false;
        this.timeout = null;
        this.eventos = [];
        this.dados = [];
        this.contador = null;
    }
}
exports.RevTabelaInfo = RevTabelaInfo;
class RevTabelaUtil {
    static habilitarMonitoramentoAtualizacoesTabelas(restFactory) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof globalThis.revTabela === 'undefined') || !globalThis.revTabela) {
                    globalThis.revTabela = new RevTabelaInfo();
                }
                globalThis.revTabela.ativo = true;
                yield RevTabelaUtil.executar(restFactory);
            }
            catch (ex) {
                console.log('RevTabelaUtil.habilitarMonitoramentoAtualizacoesTabelas() > Error:');
                console.log(ex);
                return null;
            }
        });
    }
    static desabilitarMonitoramentoAtualizacoesTabelas() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof globalThis.revTabela === 'undefined') || !globalThis.revTabela) {
                    globalThis.revTabela = new RevTabelaInfo();
                }
                if ((typeof globalThis.revTabela.timeout !== 'undefined') && (globalThis.revTabela.timeout !== null)) {
                    clearTimeout(globalThis.revTabela.timeout);
                    globalThis.revTabela.timeout = null;
                }
                globalThis.revTabela.ativo = false;
                globalThis.revTabela.contador = 0;
            }
            catch (ex) {
                console.log('RevTabelaUtil.desabilitarMonitoramentoAtualizacoesTabelas() > Error:');
                console.log(ex);
                return null;
            }
        });
    }
    static limparEventosMonitoramentoAtualizacoesTabelas() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof globalThis.revTabela === 'undefined') || !globalThis.revTabela) {
                    globalThis.revTabela = new RevTabelaInfo();
                }
                globalThis.revTabela.eventos = [];
            }
            catch (ex) {
                console.log('RevTabelaUtil.limparEventosMonitoramentoAtualizacoesTabelas() > Error:');
                console.log(ex);
                return null;
            }
        });
    }
    static adicionarEventoMonitoramentoAtualizacoesTabelas(ctrl, func) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof globalThis.revTabela === 'undefined') || !globalThis.revTabela) {
                    globalThis.revTabela = new RevTabelaInfo();
                }
                if ((typeof globalThis.revTabela.eventos === 'undefined') || !globalThis.revTabela.eventos) {
                    globalThis.revTabela.eventos = [];
                }
                globalThis.revTabela.eventos.push({ ctrl, func });
            }
            catch (ex) {
                console.log('RevTabelaUtil.limparEventosMonitoramentoAtualizacoesTabelas() > Error:');
                console.log(ex);
                return null;
            }
        });
    }
    static executar(restFactory) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof globalThis.revTabela === 'undefined') || !globalThis.revTabela) {
                    globalThis.revTabela = new RevTabelaInfo();
                }
                globalThis.revTabela.executando = true;
                if ((typeof globalThis.revTabela.timeout !== 'undefined') && (globalThis.revTabela.timeout !== null)) {
                    clearTimeout(globalThis.revTabela.timeout);
                    globalThis.revTabela.timeout = null;
                }
                if ((typeof globalThis.revTabela.ativo !== 'undefined') && globalThis.revTabela.ativo) {
                    globalThis.revTabela.contador = (((typeof globalThis.revTabela.contador !== 'undefined') && globalThis.revTabela.contador) ? globalThis.revTabela.contador : 0) + 1;
                    if ((typeof restFactory !== 'undefined') && restFactory) {
                        if ((typeof globalThis.revTabela.dados === 'undefined') || !globalThis.revTabela.dados) {
                            globalThis.revTabela.dados = new Array();
                        }
                        let ultimaRevisao = 0;
                        angular.forEach(globalThis.revTabela.dados, (revTabela) => {
                            if ((typeof revTabela.revisao !== 'undefined') && revTabela.revisao && (revTabela.revisao > ultimaRevisao)) {
                                ultimaRevisao = revTabela.revisao;
                            }
                        });
                        let resposta = yield restFactory.get(RotaAPIConstantes_1.RotaAPIConstantes.REV_TABELA, { revisaoMin: ultimaRevisao + 1 }, false, 30000);
                        if ((typeof resposta !== 'undefined') && resposta &&
                            (typeof resposta.dados !== 'undefined') && resposta.dados) {
                            angular.forEach(resposta.dados, (revTabela) => {
                                if ((typeof revTabela.nome !== 'undefined') && revTabela.nome) {
                                    if ((typeof globalThis.revTabela.dados === 'undefined') || !globalThis.revTabela.dados) {
                                        globalThis.revTabela.dados = new Array();
                                    }
                                    if ((typeof globalThis.revTabela.contador !== 'undefined') && globalThis.revTabela.contador && (globalThis.revTabela.contador === 1)) {
                                        globalThis.revTabela.dados.push(revTabela);
                                    }
                                    else {
                                        let alterouRevTabela = false;
                                        let buscaRevTabela = globalThis.revTabela.dados.find((x) => x.nome === revTabela.nome);
                                        if ((typeof buscaRevTabela !== 'undefined') && buscaRevTabela) {
                                            let numRevisao1 = Math.round(((typeof buscaRevTabela.revisao !== 'undefined') && buscaRevTabela.revisao) ? buscaRevTabela.revisao : 0);
                                            let numRevisao2 = Math.round(((typeof revTabela.revisao !== 'undefined') && revTabela.revisao) ? revTabela.revisao : 0);
                                            if (numRevisao1 !== numRevisao2) {
                                                globalThis.revTabela.dados.push(revTabela);
                                                alterouRevTabela = true;
                                            }
                                        }
                                        else {
                                            globalThis.revTabela.dados.push(revTabela);
                                            alterouRevTabela = true;
                                        }
                                        if (alterouRevTabela) {
                                            if ((typeof globalThis.revTabela.eventos !== 'undefined') && globalThis.revTabela.eventos) {
                                                angular.forEach(globalThis.revTabela.eventos, (evento) => __awaiter(this, void 0, void 0, function* () {
                                                    yield evento.func.call(evento.ctrl, revTabela.nome);
                                                }));
                                            }
                                        }
                                    }
                                }
                            });
                        }
                    }
                    globalThis.revTabela.executando = false;
                    globalThis.revTabela.timeout = setTimeout((restFactory) => __awaiter(this, void 0, void 0, function* () {
                        if ((typeof globalThis.revTabela !== 'undefined') && globalThis.revTabela &&
                            (typeof globalThis.revTabela.ativo !== 'undefined') && globalThis.revTabela.ativo) {
                            RevTabelaUtil.executar(restFactory);
                        }
                    }), (RevTabelaUtil.TEMPO_INTERVALO_CONSULTAS * 1000), restFactory);
                }
            }
            catch (ex) {
                console.log('RevTabelaUtil.executar() > Error:');
                console.log(ex);
                globalThis.revTabela.executando = false;
                globalThis.revTabela.timeout = setTimeout((restFactory) => __awaiter(this, void 0, void 0, function* () {
                    RevTabelaUtil.executar(restFactory);
                }), Math.round((RevTabelaUtil.TEMPO_INTERVALO_CONSULTAS * 1000) / 2), restFactory);
            }
        });
    }
}
exports.RevTabelaUtil = RevTabelaUtil;
RevTabelaUtil.TEMPO_INTERVALO_CONSULTAS = 5;
