"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppModulo = void 0;
const angular_1 = require("angular");
require("@uirouter/angularjs");
require("angular-animate");
require("angular-sanitize");
require("angular-ui-grid");
require("ui-grid-auto-fit-columns");
require("ui-select");
require("ui-select/dist/select.css");
require("microplugin");
require("sifter");
require("selectize");
require("angular-selectize2/dist/selectize");
require("angular-aria");
require("angular-messages");
require("angular-ui-bootstrap");
require("angular-ui-bootstrap/dist/ui-bootstrap-csp.css");
require("angular-material");
require("angular-material/angular-material.min.css");
require("angular-bootstrap-colorpicker");
require("angular-bootstrap-colorpicker/css/colorpicker.min.css");
require("angular-strap");
const ng_currency_1 = require("ng-currency");
const AppRota_1 = require("./AppRota");
const RotaFactory_1 = require("../../fabricas/RotaFactory");
const ManipulaErroFactory_1 = require("../../fabricas/ManipulaErroFactory");
const NotificacaoFactory_1 = require("../../fabricas/NotificacaoFactory");
const RestFactory_1 = require("../../fabricas/RestFactory");
const FerramentaModulo_1 = require("../ferramenta/FerramentaModulo");
const TabelaComponente_1 = require("./componentes/tabela/TabelaComponente");
const BotoesOperacaoComponente_1 = require("./componentes/botoes-operacoes/BotoesOperacaoComponente");
const BotoesFormularioComponente_1 = require("./componentes/botoes-formulario/BotoesFormularioComponente");
const SincroniaFactory_1 = require("../../fabricas/SincroniaFactory");
const CadastroModulo_1 = require("../cadastro/CadastroModulo");
const LancamentoModulo_1 = require("../lancamento/LancamentoModulo");
const DialogoFactory_1 = require("../../fabricas/DialogoFactory");
const PlanilhaFactory_1 = require("../ferramenta/fabricas/PlanilhaFactory");
const UploadComponente_1 = require("./componentes/upload/UploadComponente");
const MapaComponente_1 = require("./componentes/mapa/MapaComponente");
class AppModulo {
    static iniciar() {
        CadastroModulo_1.CadastroModulo.iniciar();
        FerramentaModulo_1.FerramentaModulo.iniciar();
        LancamentoModulo_1.LancamentoModulo.iniciar();
        angular_1.module(AppModulo.id, [
            'ngSanitize',
            'ui.router',
            'ui.bootstrap',
            'ui.grid',
            'ui.grid.moveColumns',
            'ui.grid.autoFitColumns',
            'ui.grid.resizeColumns',
            'ui.grid.autoResize',
            'ui.grid.selection',
            'ui.grid.edit',
            'ui.grid.exporter',
            'ui.grid.saveState',
            'ui.select',
            'ui.utils.masks',
            'ngAria',
            'ngMessages',
            'ngAnimate',
            'ngMaterial',
            'colorpicker.module',
            'mgcrea.ngStrap',
            'selectize',
            'chart.js',
            ng_currency_1.default,
            CadastroModulo_1.CadastroModulo.id,
            FerramentaModulo_1.FerramentaModulo.id,
            LancamentoModulo_1.LancamentoModulo.id,
        ])
            .factory(ManipulaErroFactory_1.ManipulaErroFactory.id, ManipulaErroFactory_1.ManipulaErroFactory)
            .factory(NotificacaoFactory_1.NotificacaoFactory.id, NotificacaoFactory_1.NotificacaoFactory)
            .factory(SincroniaFactory_1.SincroniaFactory.id, SincroniaFactory_1.SincroniaFactory)
            .factory(DialogoFactory_1.DialogoFactory.id, DialogoFactory_1.DialogoFactory)
            .factory(RotaFactory_1.RotaFactory.id, RotaFactory_1.RotaFactory)
            .factory(RestFactory_1.RestFactory.id, RestFactory_1.RestFactory)
            .factory(PlanilhaFactory_1.PlanilhaFactory.id, PlanilhaFactory_1.PlanilhaFactory)
            .component(TabelaComponente_1.TabelaComponente.id, new TabelaComponente_1.TabelaComponente())
            .component(BotoesOperacaoComponente_1.BotoesOperacaoComponente.id, new BotoesOperacaoComponente_1.BotoesOperacaoComponente())
            .component(BotoesFormularioComponente_1.BotoesFormularioComponente.id, new BotoesFormularioComponente_1.BotoesFormularioComponente())
            .component(UploadComponente_1.UploadComponente.id, new UploadComponente_1.UploadComponente())
            .component(MapaComponente_1.MapaComponente.id, new MapaComponente_1.MapaComponente())
            .config(AppRota_1.AppRota);
        globalThis.MicroPluguin = require('microplugin');
        globalThis.Sifter = require('sifter');
        globalThis.Selectize = require('selectize');
    }
    static carregar() {
        return angular_1.module(AppModulo.id);
    }
}
exports.AppModulo = AppModulo;
AppModulo.id = 'app';
