"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonitoramentoFloracaoPolinizacaoPomar = void 0;
const Base_1 = require("./Base");
const uuid_1 = require("uuid");
class MonitoramentoFloracaoPolinizacaoPomar extends Base_1.Base {
    constructor() {
        super();
        this.id = null;
        this.guid = null;
        this.estrobilo = null;
        this.dataColeta = null;
        this.pomarOrigem = null;
        this.pomarOrigemCodigo = null;
        this.pomarOrigemDescricao = null;
        this.materialGeneticoMasculino = null;
        this.quantidadePolenColetado = null;
        this.dataIsolamento = null;
        this.fila = null;
        this.coluna = null;
        this.numPacotes = null;
        this.numEstrobilosFemininosCones = null;
        this.guid = uuid_1.v4();
        this.status = true;
    }
}
exports.MonitoramentoFloracaoPolinizacaoPomar = MonitoramentoFloracaoPolinizacaoPomar;
