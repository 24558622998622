"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TipoProtecaoCultivar = void 0;
var TipoProtecaoCultivar;
(function (TipoProtecaoCultivar) {
    TipoProtecaoCultivar["FORMULARIO_INSCRICAO"] = "formulario_inscricao";
    TipoProtecaoCultivar["RELATORIO_TECNICO"] = "relatorio_tecnico";
    TipoProtecaoCultivar["ENSAIO_DHE"] = "ensaio_dhe";
    TipoProtecaoCultivar["FORMULARIO_ONLINE"] = "formulario_online";
    TipoProtecaoCultivar["COMPROVANTE_INSCRICAO"] = "comprovante_inscricao";
    TipoProtecaoCultivar["DILIGENCIAS_RESPOSTAS"] = "diligencias_respostas";
    TipoProtecaoCultivar["CERTIFICADO_PROVISORIO"] = "certificado_provisorio";
    TipoProtecaoCultivar["TAXA_CERTIFICADO_PROVISORIO"] = "taxa_certificado_provisorio";
    TipoProtecaoCultivar["CERTIFICADO_ORAL"] = "certificado_oral";
    TipoProtecaoCultivar["NUM_PROTECAO"] = "num_protecao";
    TipoProtecaoCultivar["TAXAS_ANUIDADES"] = "taxas_anuidades";
})(TipoProtecaoCultivar = exports.TipoProtecaoCultivar || (exports.TipoProtecaoCultivar = {}));
