"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CadastroModulo = void 0;
const angular_1 = require("angular");
const CadastroRota_1 = require("./CadastroRota");
class CadastroModulo {
    static iniciar() {
        angular_1.module(CadastroModulo.id, []).config(CadastroRota_1.CadastroRota);
    }
    static carregar() {
        return angular_1.module(CadastroModulo.id);
    }
}
exports.CadastroModulo = CadastroModulo;
CadastroModulo.id = 'cadastro';
