"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DispositivoUtil = void 0;
const angular = require("angular");
const moment = require("moment");
const Coordenada_1 = require("../modelos/Coordenada");
class DispositivoUtil {
    static tirarFoto(ehGaleria = false) {
        const uriToBase64 = (imgUri) => {
            return `data:image/jpg;base64,${imgUri}`;
        };
        const opcoes = {
            quality: 80,
            destinationType: Camera.DestinationType.DATA_URL,
            sourceType: ((typeof ehGaleria !== 'undefined') && ehGaleria ? Camera.PictureSourceType.PHOTOLIBRARY : Camera.PictureSourceType.CAMERA),
            encodingType: Camera.EncodingType.JPEG,
            mediaType: Camera.MediaType.PICTURE,
            saveToPhotoAlbum: true,
            allowEdit: false,
            targetWidth: 1920,
            targetHeight: 1200
        };
        return new Promise((resolve, reject) => {
            navigator.camera.getPicture((imgUri) => {
                resolve(uriToBase64(imgUri));
            }, (error) => {
                alert(error);
                return reject(error);
            }, opcoes);
        });
    }
    static ehCelular() {
        if (DispositivoUtil.ehAndroid() || DispositivoUtil.ehIphone()) {
            return true;
        }
        return /webOS|BlackBerry|IEMobile|Opera Mini/gi.test(navigator.userAgent);
    }
    static ehWindows() {
        return false;
    }
    static ehAndroid() {
        return /Android/gi.test(navigator.userAgent);
    }
    static ehIphone() {
        if (typeof navigator.platform !== 'undefined') {
            const lista = [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod'
            ];
            if (lista.includes(navigator.platform)) {
                return true;
            }
        }
        return /iPhone|iPad|iPod/gi.test(navigator.userAgent);
    }
    static taOnline() {
        if (typeof device !== 'undefined') {
            if (device.platform !== 'browser') {
                return navigator.connection.type !== Connection.NONE;
            }
        }
        return navigator.onLine;
    }
    static habilitarCapturaCoordenada() {
        return __awaiter(this, void 0, void 0, function* () {
            globalThis.capturaCoordenadaAtivo = true;
            if ((typeof globalThis.capturaCoordenadaTimeout !== 'undefined') && (globalThis.capturaCoordenadaTimeout !== null)) {
                window.clearTimeout(globalThis.capturaCoordenadaTimeout);
            }
            globalThis.capturaCoordenadaTimeout = window.setTimeout(() => __awaiter(this, void 0, void 0, function* () {
                DispositivoUtil.onTimeoutCapturaCoordenada();
            }), 2500);
        });
    }
    static desabilitarCapturaCoordenada() {
        return __awaiter(this, void 0, void 0, function* () {
            globalThis.capturaCoordenadaAtivo = false;
            if ((typeof globalThis.capturaCoordenadaTimeout !== 'undefined') && (globalThis.capturaCoordenadaTimeout !== null)) {
                window.clearTimeout(globalThis.capturaCoordenadaTimeout);
            }
            globalThis.capturaCoordenadaTimeout = null;
        });
    }
    static onTimeoutCapturaCoordenada() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                globalThis.capturaCoordenadaTimeout = null;
                if ((typeof globalThis.capturaCoordenadaAtivo === 'undefined') || !globalThis.capturaCoordenadaAtivo) {
                    return;
                }
                navigator.geolocation.getCurrentPosition((position) => {
                    try {
                        if ((typeof position !== 'undefined') && (position !== null) &&
                            (typeof position.coords !== 'undefined') && (position.coords !== null) &&
                            (typeof position.coords.latitude !== 'undefined') && (position.coords.latitude !== null) && (position.coords.latitude !== 0) &&
                            (typeof position.coords.longitude !== 'undefined') && (position.coords.longitude !== null) && (position.coords.longitude !== 0)) {
                            const coordenada = {
                                accuracy: ((typeof position.coords.accuracy !== 'undefined') && (position.coords.accuracy !== null)) ? position.coords.accuracy : null,
                                altitude: ((typeof position.coords.altitude !== 'undefined') && (position.coords.altitude !== null)) ? position.coords.altitude : null,
                                altitudeAccuracy: ((typeof position.coords.altitudeAccuracy !== 'undefined') && (position.coords.altitudeAccuracy !== null)) ? position.coords.altitudeAccuracy : null,
                                heading: ((typeof position.coords.heading !== 'undefined') && (position.coords.heading !== null)) ? position.coords.heading : null,
                                latitude: position.coords.latitude,
                                longitude: position.coords.longitude,
                                speed: ((typeof position.coords.speed !== 'undefined') && (position.coords.speed !== null)) ? position.coords.speed : null,
                                timestamp: ((typeof position.timestamp !== 'undefined') && (position.timestamp !== null)) ? position.timestamp : null,
                                dataHora: moment(new Date()).format()
                            };
                            window.localStorage.setItem('coordenada', JSON.stringify(coordenada));
                            console.log('CapturaCoordenada() = { latitude: ' + coordenada.latitude + ', longitude: ' + coordenada.longitude + ' }');
                        }
                    }
                    catch (ex) {
                        console.log('DispositivoUtil.onTimeoutCapturaCoordenada() > Error (navigator.geolocation.getCurrentPosition$success):');
                        console.log(ex);
                    }
                }, (positionError) => {
                    console.log('DispositivoUtil.onTimeoutCapturaCoordenada() > Error (navigator.geolocation.getCurrentPosition$error):');
                    console.log(positionError);
                }, { enableHighAccuracy: true });
            }
            catch (ex) {
                console.log('DispositivoUtil.onTimeoutCapturaCoordenada() > Error:');
                console.log(ex);
            }
            finally {
                if ((typeof globalThis.capturaCoordenadaAtivo !== 'undefined') && globalThis.capturaCoordenadaAtivo) {
                    globalThis.capturaCoordenadaTimeout = window.setTimeout(() => __awaiter(this, void 0, void 0, function* () {
                        DispositivoUtil.onTimeoutCapturaCoordenada();
                    }), 10000);
                }
            }
        });
    }
    static lerCoordenada() {
        let accuracy = 0;
        let altitude = 0;
        let altitudeAccuracy = 0;
        let heading = 0;
        let latitude = 0;
        let longitude = 0;
        let speed = 0;
        const coordenada = JSON.parse(window.localStorage.getItem('coordenada'));
        if ((typeof coordenada !== 'undefined') && coordenada &&
            (typeof coordenada.dataHora !== 'undefined') && coordenada.dataHora) {
            const dataHoraAtual = new Date();
            const dataHoraCoordenada = new Date(coordenada.dataHora);
            const diferencaHoras = Math.round(Math.abs(dataHoraAtual.getTime() - dataHoraCoordenada.getTime()) / 36e5);
            if (diferencaHoras > 8) {
                console.log('DispositivoUtil.lerCoordenada() > Coordenada expirada, excedeu 8 horas. \n' +
                    'Última atualização das coordenadas (geolocalização): ' + moment(dataHoraCoordenada).format() + ' \n' +
                    'Tempo decorrido: ' + diferencaHoras + ' hrs.');
            }
            else {
                accuracy = ((typeof coordenada.accuracy !== 'undefined') && coordenada.accuracy) ? coordenada.accuracy : 0;
                altitude = ((typeof coordenada.altitude !== 'undefined') && coordenada.altitude) ? coordenada.altitude : 0;
                altitudeAccuracy = ((typeof coordenada.altitudeAccuracy !== 'undefined') && coordenada.altitudeAccuracy) ? coordenada.altitudeAccuracy : 0;
                heading = ((typeof coordenada.heading !== 'undefined') && coordenada.heading) ? coordenada.heading : 0;
                latitude = ((typeof coordenada.latitude !== 'undefined') && coordenada.latitude) ? coordenada.latitude : 0;
                longitude = ((typeof coordenada.longitude !== 'undefined') && coordenada.longitude) ? coordenada.longitude : 0;
                speed = ((typeof coordenada.speed !== 'undefined') && coordenada.speed) ? coordenada.accuracy : 0;
            }
        }
        return {
            accuracy, altitude, altitudeAccuracy, heading, latitude, longitude, speed,
            toJSON: () => JSON.stringify({ accuracy, altitude, altitudeAccuracy, heading, latitude, longitude, speed })
        };
    }
    static mostrarMapa(coordenada, divId, geoJson) {
        return __awaiter(this, void 0, void 0, function* () {
            setTimeout(() => {
                var mapOptions = {
                    center: new google.maps.LatLng(0, 0),
                    zoom: 1,
                    mapTypeId: google.maps.MapTypeId.ROADMAP
                };
                if ((typeof coordenada === 'undefined') || (coordenada === null)) {
                    coordenada = new Coordenada_1.Coordenada();
                    coordenada.latitude = 0;
                    coordenada.longitude = 0;
                }
                else {
                    if ((!coordenada.latitude) || (coordenada.latitude === null)) {
                        coordenada.latitude = 0;
                    }
                    if ((!coordenada.longitude) || (coordenada.longitude === null)) {
                        coordenada.longitude = 0;
                    }
                }
                let el = null;
                if ((typeof divId !== 'undefined') && (divId !== null)) {
                    if ((divId.length > 0) && (divId.substring(0, 1) !== '#')) {
                        if (angular.element('#' + divId).length > 0) {
                            el = angular.element('#' + divId).get(0);
                        }
                    }
                    else {
                        if (angular.element(divId).length > 0) {
                            el = angular.element(divId).get(0);
                        }
                    }
                }
                if (!el) {
                    el = document.getElementById('map');
                }
                var map = null;
                var latLong = null;
                var marker = null;
                if ((angular.element(el).length > 0) &&
                    (typeof angular.element(el).data('_map') !== 'undefined') && (angular.element(el).data('_map') !== null) &&
                    (typeof angular.element(el).data('_marker') !== 'undefined') && (angular.element(el).data('_marker') !== null)) {
                    latLong = new google.maps.LatLng(coordenada.latitude, coordenada.longitude);
                    map = angular.element(el).data('_map');
                    marker = angular.element(el).data('_marker');
                    marker.setPosition(latLong);
                    marker.setMap(map);
                    map.setZoom(14);
                    map.setCenter(marker.getPosition());
                    if ((typeof geoJson !== 'undefined') && geoJson) {
                        map.data.addGeoJson(geoJson);
                    }
                }
                else {
                    map = new google.maps.Map(el, mapOptions);
                    latLong = new google.maps.LatLng(coordenada.latitude, coordenada.longitude);
                    marker = new google.maps.Marker({ position: latLong });
                    marker.setMap(map);
                    map.setZoom(14);
                    map.setCenter(marker.getPosition());
                    if ((typeof geoJson !== 'undefined') && geoJson) {
                        map.data.addGeoJson(geoJson);
                    }
                    if (angular.element(el).length > 0) {
                        angular.element(el).data('_map', map);
                        angular.element(el).data('_marker', marker);
                    }
                }
            }, 1000);
        });
    }
    static setaDistancia(posicaoUnidade) {
        return __awaiter(this, void 0, void 0, function* () {
            const coordenada = yield DispositivoUtil.lerCoordenada();
            return this.distancia(coordenada, posicaoUnidade);
        });
    }
    static distancia(position1, position2) {
        let g2rad = (graus) => {
            var radianos = (graus * Math.PI) / 180;
            return radianos;
        };
        let lat1 = position1.latitude;
        let lat2 = position2.latitude;
        let lon1 = position1.longitude;
        let lon2 = position2.longitude;
        let diffLat = lat2 - lat1;
        let diffLon = lon2 - lon1;
        let R = 6371000;
        let φ1 = g2rad(lat1);
        let φ2 = g2rad(lat2);
        let Δφ = g2rad(diffLat);
        let Δλ = g2rad(diffLon);
        let a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
            Math.cos(φ1) * Math.cos(φ2) *
                Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        let d = R * c;
        return d;
    }
    static verFoto(foto) {
        const img = new Image();
        img.src = foto;
        img.style.width = '100%';
        const w = window.open('');
        w.document.title = 'Foto Visualização';
        w.document.write(img.outerHTML);
    }
    static gravarLog(nomeController, nomeFuncao, identificacao, mensagem) {
        const dataHora = new Date();
        const nAno = dataHora.getFullYear();
        const nMes = (dataHora.getMonth() + 1);
        const nDia = dataHora.getDate();
        const nHora = dataHora.getHours();
        const nMinuto = dataHora.getMinutes();
        const nSegundo = dataHora.getSeconds();
        const nMilissegundo = dataHora.getMilliseconds();
        let sDia = '00' + nDia.toString();
        if (sDia.length > 2) {
            sDia = sDia.substring(sDia.length - 2, sDia.length);
        }
        let sMes = '00' + nMes.toString();
        if (sMes.length > 2) {
            sMes = sMes.substring(sMes.length - 2, sMes.length);
        }
        let sAno = '0000' + nAno.toString();
        if (sAno.length > 4) {
            sAno = sAno.substring(sAno.length - 4, sAno.length);
        }
        let sHora = '00' + nHora;
        if (sHora.length > 2) {
            sHora = sHora.substring(sHora.length - 2, sHora.length);
        }
        let sMinuto = '00' + nMinuto.toString();
        if (sMinuto.length > 2) {
            sMinuto = sMinuto.substring(sMinuto.length - 2, sMinuto.length);
        }
        let sSegundo = '00' + nSegundo.toString();
        if (sSegundo.length > 2) {
            sSegundo = sSegundo.substring(sSegundo.length - 2, sSegundo.length);
        }
        let sMilissegundo = '000' + nMilissegundo.toString();
        if (sMilissegundo.length > 3) {
            sMilissegundo = sMilissegundo.substring(sMilissegundo.length - 3, sMilissegundo.length);
        }
        let s = ((typeof nomeController !== 'undefined') && nomeController) ? nomeController : 'Controller não informado';
        if ((typeof nomeFuncao !== 'undefined') && nomeFuncao) {
            s += '->' + nomeFuncao;
        }
        if ((typeof identificacao !== 'undefined') && identificacao) {
            s += ' ( ' + identificacao + ' ) ';
        }
        else {
            s += '()';
        }
        if ((typeof mensagem !== 'undefined') && mensagem) {
            s += ' => ' + mensagem;
        }
        console.log(sDia + '/' + sMes + '/' + sAno + ' ' + sHora + ':' + sMinuto + ':' + sSegundo + '.' + sMilissegundo + ' | Log > ' + s);
    }
    static focalizarCampo(id) {
        if (!id) {
            return;
        }
        const el = angular.element(id);
        if (el && (el.length > 0)) {
            if ((typeof globalThis.focalizarCampoTimeout !== 'undefined') && (globalThis.focalizarCampoTimeout !== null)) {
                clearTimeout(globalThis.focalizarCampoTimeout);
                globalThis.focalizarCampoTimeout = null;
            }
            globalThis.focalizarCampoTimeout = setTimeout((el) => {
                globalThis.focalizarCampoTimeout = null;
                DispositivoUtil.focalizarElemento(el);
            }, 250, el);
        }
    }
    static focalizarElemento(el) {
        if (el && (el.length > 0)) {
            if ((typeof globalThis.focalizarElementoTimeout !== 'undefined') && (globalThis.focalizarElementoTimeout !== null)) {
                clearTimeout(globalThis.focalizarElementoTimeout);
                globalThis.focalizarElementoTimeout = null;
            }
            let carregamentoTravado = false;
            if ((typeof globalThis.carregamentoUtil !== 'undefined') && (globalThis.carregamentoUtil !== null)) {
                if ((typeof globalThis.carregamentoUtil.loadTravado !== 'undefined') && (globalThis.carregamentoUtil.loadTravado === true)) {
                    carregamentoTravado = true;
                }
            }
            if (carregamentoTravado || (angular.element('body.swal2-shown').length > 0)) {
                globalThis.focalizarElementoTimeout = setTimeout((el) => {
                    globalThis.focalizarElementoTimeout = null;
                    DispositivoUtil.focalizarElemento(el);
                }, 250, el);
            }
            else {
                el.first().trigger('focus');
                const el_top = el.first().offset().top;
                if ((typeof el_top !== 'undefined') && (el_top !== null)) {
                    window.scrollTo({ top: ((el_top > 100) ? (el_top - 100) : 0) });
                }
                let el_child = el.find('input');
                if (el_child.length === 0) {
                    el_child = el.find('select');
                }
                if (el_child.length === 0) {
                    el_child = el.find('textarea');
                }
                if (el_child.length === 0) {
                    el_child = el.find('option');
                }
                if (el_child.length === 0) {
                    el_child = el.find('select');
                }
                if (el_child && el_child.length > 0) {
                    el_child.first().trigger('focus');
                }
            }
        }
    }
}
exports.DispositivoUtil = DispositivoUtil;
