"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MapaComponente = void 0;
const MapaController_1 = require("./MapaController");
class MapaComponente {
    constructor() {
        this.controller = MapaController_1.MapaController;
        this.templateUrl = require('./mapa.componente.html').default;
        this.bindings = {
            id: '@',
            coordenada: '=',
            geojson: '='
        };
    }
}
exports.MapaComponente = MapaComponente;
MapaComponente.id = 'mapaComponente';
