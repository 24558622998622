"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenericoController = void 0;
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const UiGridConstrutor_1 = require("../../../app/construtores/UiGridConstrutor");
const Generico_1 = require("../../../../../modelos/Generico");
const AbstratoController_1 = require("../../../app/AbstratoController");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const ICRUDOperacao_1 = require("../../../app/interfaces/ICRUDOperacao");
const PlanilhaFactory_1 = require("../../fabricas/PlanilhaFactory");
class GenericoController extends AbstratoController_1.AbstratoController {
    constructor($scope, $injector) {
        super($scope, $injector, new Generico_1.Generico());
        this.campo = null;
        this.removeAcao = false;
        this.planilha = null;
        this.colunasRemovidas = [];
        this.filtroGenerico = null;
        this.planilha = $injector.get(PlanilhaFactory_1.PlanilhaFactory.id);
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modelo.referencia = this.filtroGenerico.referencia;
        });
    }
    salvar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let resultado = null;
                if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    resultado = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.GENERICO)
                        .criar(this.modelo);
                }
                else {
                    resultado = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.GENERICO)
                        .atualizar(this.modelo);
                }
                if (resultado) {
                    this.$state.go(this.links.LISTAR.rota);
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    visualizar(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const generico = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.GENERICO)
                    .id(id)
                    .ver();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (!generico) {
                    this.$state.go(this.links.LISTAR.rota);
                }
                return generico;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    remover(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const generico = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.GENERICO)
                    .id(id)
                    .remover();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (generico) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const generico = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.GENERICO)
                    .id(this.modelo.id || this.modelo.uuid)
                    .restaurar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (generico) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    exibirColunas(colunas) {
        this.colunasRemovidas = Object.keys(new Generico_1.Generico()).filter(x => !colunas.some(y => y === x));
        return this;
    }
    removerColunas(colunas) {
        this.colunasRemovidas = colunas;
        return this;
    }
    exibirCamposFormulario(campo) {
        this.campo = campo;
        return this;
    }
    filtrarGenerico(generico) {
        this.filtroGenerico = generico;
        return this;
    }
    dadosLista() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(this.colunasRemovidas);
                if (this.removeAcao) {
                    gridConstrutor.removerAcao();
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
                const lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.GENERICO)
                    .filtro(this.filtroGenerico)
                    .listar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
}
exports.GenericoController = GenericoController;
GenericoController.$inject = ['$scope', '$injector'];
