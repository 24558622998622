"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DetalhamentoEC = void 0;
const Base_1 = require("./Base");
class DetalhamentoEC extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.numCanaletao = null;
        this.ec = null;
        this.observacao = null;
    }
}
exports.DetalhamentoEC = DetalhamentoEC;
