"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TipoAnaliseSelegenTesteGenetico = void 0;
class TipoAnaliseSelegenTesteGenetico {
    constructor() {
        this.id = null;
        this.modelo = null;
        this.descricao = null;
    }
    static MODELO_1() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo1 === 'undefined') || (globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo1 === null)) {
            let tipoAnaliseSelegenTesteGenetico_Modelo1 = new TipoAnaliseSelegenTesteGenetico();
            tipoAnaliseSelegenTesteGenetico_Modelo1.id = 'MODELO_1';
            tipoAnaliseSelegenTesteGenetico_Modelo1.modelo = 1;
            tipoAnaliseSelegenTesteGenetico_Modelo1.descricao = 'Modelo 1: Blocos ao acaso, progênies de meios irmãos, várias plantas por parcela';
            globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo1 = tipoAnaliseSelegenTesteGenetico_Modelo1;
        }
        return globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo1;
    }
    static MODELO_2() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo2 === 'undefined') || (globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo2 === null)) {
            let tipoAnaliseSelegenTesteGenetico_Modelo2 = new TipoAnaliseSelegenTesteGenetico();
            tipoAnaliseSelegenTesteGenetico_Modelo2.id = 'MODELO_2';
            tipoAnaliseSelegenTesteGenetico_Modelo2.modelo = 2;
            tipoAnaliseSelegenTesteGenetico_Modelo2.descricao = 'Modelo 2: Blocos ao acaso, teste de clones não aparentados, várias plantas por parcela';
            globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo2 = tipoAnaliseSelegenTesteGenetico_Modelo2;
        }
        return globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo2;
    }
    static MODELO_4() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo4 === 'undefined') || (globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo4 === null)) {
            let tipoAnaliseSelegenTesteGenetico_Modelo4 = new TipoAnaliseSelegenTesteGenetico();
            tipoAnaliseSelegenTesteGenetico_Modelo4.id = 'MODELO_4';
            tipoAnaliseSelegenTesteGenetico_Modelo4.modelo = 4;
            tipoAnaliseSelegenTesteGenetico_Modelo4.descricao = 'Modelo 4: Blocos ao acaso, progênies de meios-irmãos, várias plantas por parcela, vários locais';
            globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo4 = tipoAnaliseSelegenTesteGenetico_Modelo4;
        }
        return globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo4;
    }
    static MODELO_24() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo24 === 'undefined') || (globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo24 === null)) {
            let tipoAnaliseSelegenTesteGenetico_Modelo24 = new TipoAnaliseSelegenTesteGenetico();
            tipoAnaliseSelegenTesteGenetico_Modelo24.id = 'MODELO_24';
            tipoAnaliseSelegenTesteGenetico_Modelo24.modelo = 24;
            tipoAnaliseSelegenTesteGenetico_Modelo24.descricao = 'Modelo 24: Blocos ao acaso, teste de populações ou procedências, várias plantas por parcela';
            globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo24 = tipoAnaliseSelegenTesteGenetico_Modelo24;
        }
        return globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo24;
    }
    static MODELO_33() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo33 === 'undefined') || (globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo33 === null)) {
            let tipoAnaliseSelegenTesteGenetico_Modelo33 = new TipoAnaliseSelegenTesteGenetico();
            tipoAnaliseSelegenTesteGenetico_Modelo33.id = 'MODELO_33';
            tipoAnaliseSelegenTesteGenetico_Modelo33.modelo = 33;
            tipoAnaliseSelegenTesteGenetico_Modelo33.descricao = 'Modelo 33: Blocos ao acaso, teste de progênies de polinização controlada (genitores não aparentados), várias plantas por parcela';
            globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo33 = tipoAnaliseSelegenTesteGenetico_Modelo33;
        }
        return globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo33;
    }
    static MODELO_51() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo51 === 'undefined') || (globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo51 === null)) {
            let tipoAnaliseSelegenTesteGenetico_Modelo51 = new TipoAnaliseSelegenTesteGenetico();
            tipoAnaliseSelegenTesteGenetico_Modelo51.id = 'MODELO_51';
            tipoAnaliseSelegenTesteGenetico_Modelo51.modelo = 51;
            tipoAnaliseSelegenTesteGenetico_Modelo51.descricao = 'Modelo 51: Estabilidade, Adaptabilidade e Produtividade : Blocos, genótipos, várias plantas por parcela, vários locais';
            globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo51 = tipoAnaliseSelegenTesteGenetico_Modelo51;
        }
        return globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo51;
    }
    static MODELO_105() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo105 === 'undefined') || (globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo105 === null)) {
            let tipoAnaliseSelegenTesteGenetico_Modelo105 = new TipoAnaliseSelegenTesteGenetico();
            tipoAnaliseSelegenTesteGenetico_Modelo105.id = 'MODELO_105';
            tipoAnaliseSelegenTesteGenetico_Modelo105.modelo = 105;
            tipoAnaliseSelegenTesteGenetico_Modelo105.descricao = 'Modelo 105: Estatística Geral';
            globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo105 = tipoAnaliseSelegenTesteGenetico_Modelo105;
        }
        return globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo105;
    }
    static MODELO_106() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo106 === 'undefined') || (globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo106 === null)) {
            let tipoAnaliseSelegenTesteGenetico_Modelo106 = new TipoAnaliseSelegenTesteGenetico();
            tipoAnaliseSelegenTesteGenetico_Modelo106.id = 'MODELO_106';
            tipoAnaliseSelegenTesteGenetico_Modelo106.modelo = 106;
            tipoAnaliseSelegenTesteGenetico_Modelo106.descricao = 'Modelo 106: Otimização da Seleção : Ne e Endogamia, Seleção dentro de Progênies';
            globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo106 = tipoAnaliseSelegenTesteGenetico_Modelo106;
        }
        return globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo106;
    }
    static MODELO_44_1() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo44_1 === 'undefined') || (globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo44_1 === null)) {
            let tipoAnaliseSelegenTesteGenetico_Modelo44_1 = new TipoAnaliseSelegenTesteGenetico();
            tipoAnaliseSelegenTesteGenetico_Modelo44_1.id = 'MODELO_44_1';
            tipoAnaliseSelegenTesteGenetico_Modelo44_1.modelo = 441;
            tipoAnaliseSelegenTesteGenetico_Modelo44_1.descricao = 'Modelo 44: Blocos Completos, Várias Planta por Parcela, Vários Locais, Genitores Aparentados';
            globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo44_1 = tipoAnaliseSelegenTesteGenetico_Modelo44_1;
        }
        return globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo44_1;
    }
    static MODELO_44_2() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo44_2 === 'undefined') || (globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo44_2 === null)) {
            let tipoAnaliseSelegenTesteGenetico_Modelo44_2 = new TipoAnaliseSelegenTesteGenetico();
            tipoAnaliseSelegenTesteGenetico_Modelo44_2.id = 'MODELO_44_2';
            tipoAnaliseSelegenTesteGenetico_Modelo44_2.modelo = 442;
            tipoAnaliseSelegenTesteGenetico_Modelo44_2.descricao = 'Modelo 44. Pedigree';
            globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo44_2 = tipoAnaliseSelegenTesteGenetico_Modelo44_2;
        }
        return globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo44_2;
    }
    static MODELO_37() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo37 === 'undefined') || (globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo37 === null)) {
            let tipoAnaliseSelegenTesteGenetico_Modelo37 = new TipoAnaliseSelegenTesteGenetico();
            tipoAnaliseSelegenTesteGenetico_Modelo37.id = 'MODELO_37';
            tipoAnaliseSelegenTesteGenetico_Modelo37.modelo = 37;
            tipoAnaliseSelegenTesteGenetico_Modelo37.descricao = 'Modelo 37. Blocos Completos, Várias Planta por Parcela, Vários Locais, Genitores não Aparentados';
            globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo37 = tipoAnaliseSelegenTesteGenetico_Modelo37;
        }
        return globalThis.globalConstants.tipoAnaliseSelegenTesteGenetico_Modelo37;
    }
    static LISTA() {
        return [
            TipoAnaliseSelegenTesteGenetico.MODELO_1(),
            TipoAnaliseSelegenTesteGenetico.MODELO_2(),
            TipoAnaliseSelegenTesteGenetico.MODELO_4(),
            TipoAnaliseSelegenTesteGenetico.MODELO_24(),
            TipoAnaliseSelegenTesteGenetico.MODELO_33(),
            TipoAnaliseSelegenTesteGenetico.MODELO_51(),
            TipoAnaliseSelegenTesteGenetico.MODELO_105(),
            TipoAnaliseSelegenTesteGenetico.MODELO_106(),
            TipoAnaliseSelegenTesteGenetico.MODELO_44_1(),
            TipoAnaliseSelegenTesteGenetico.MODELO_44_2(),
            TipoAnaliseSelegenTesteGenetico.MODELO_37()
        ];
    }
}
exports.TipoAnaliseSelegenTesteGenetico = TipoAnaliseSelegenTesteGenetico;
