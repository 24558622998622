"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaterialGeneticoSemeio = void 0;
const Base_1 = require("./Base");
class MaterialGeneticoSemeio extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.producaoMudaTeste = null;
        this.unidadeMedida = null;
        this.quantidade = null;
    }
}
exports.MaterialGeneticoSemeio = MaterialGeneticoSemeio;
