"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CasaVegetacao = void 0;
const Base_1 = require("./Base");
class CasaVegetacao extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.descricao = null;
    }
}
exports.CasaVegetacao = CasaVegetacao;
