"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelatorioEnxertiaPomar = void 0;
const Base_1 = require("./Base");
class RelatorioEnxertiaPomar extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.id = null;
        this.numTotalPosicoes = 0;
        this.numTotalVasos = 0;
        this.numTotalMudas = 0;
        this.numTotalReplantio = 0;
        this.numTotalPegamento = 0;
        this.porcentagemPegamento = 0;
        this.numReenxertiaProximoAno = 0;
    }
}
exports.RelatorioEnxertiaPomar = RelatorioEnxertiaPomar;
