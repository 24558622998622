"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataUtil = void 0;
class DataUtil {
    static dataHoraAtual() {
        return new Date();
    }
    static converterValorParaDataHora(value) {
        if ((typeof value === 'undefined') || (value === null)) {
            return null;
        }
        if (typeof value === 'string') {
            if (value.trim().length > 10) {
                return new Date(value.trim());
            }
            else if (value.trim().length > 6) {
                return new Date(value.trim() + 'T00:00:00');
            }
            else {
                return null;
            }
        }
        if (typeof value === 'number') {
            return new Date(value);
        }
        if ((typeof value === 'object') && !isNaN(value)) {
            if ((typeof value.getMonth === 'function') && (typeof value.getDate === 'function')) {
                return value;
            }
            if (value instanceof Date) {
                return value;
            }
        }
        return null;
    }
    static converterDataParaFormatoPHP(data) {
        if ((typeof data === 'undefined') || (data === null)) {
            return null;
        }
        const nDataHora = DataUtil.converterValorParaDataHora(data);
        if (!nDataHora) {
            return null;
        }
        const nAno = nDataHora.getFullYear();
        const nMes = (nDataHora.getMonth() + 1);
        const nDia = nDataHora.getDate();
        let sAno = '0000' + nAno.toString();
        if (sAno.length > 4) {
            sAno = sAno.substring(sAno.length - 4, sAno.length);
        }
        let sMes = '00' + nMes.toString();
        if (sMes.length > 2) {
            sMes = sMes.substring(sMes.length - 2, sMes.length);
        }
        let sDia = '00' + nDia.toString();
        if (sDia.length > 2) {
            sDia = sDia.substring(sDia.length - 2, sDia.length);
        }
        return sAno + '-' + sMes + '-' + sDia;
    }
    static converterHoraParaFormatoPHP(data) {
        if ((typeof data === 'undefined') || (data === null)) {
            return null;
        }
        const nDataHora = DataUtil.converterValorParaDataHora(data);
        if (!nDataHora) {
            return null;
        }
        const nHora = nDataHora.getHours();
        const nMinuto = nDataHora.getMinutes();
        const nSegundo = nDataHora.getSeconds();
        let sHora = '00' + nHora;
        if (sHora.length > 2) {
            sHora = sHora.substring(sHora.length - 2, sHora.length);
        }
        let sMinuto = '00' + nMinuto.toString();
        if (sMinuto.length > 2) {
            sMinuto = sMinuto.substring(sMinuto.length - 2, sMinuto.length);
        }
        let sSegundo = '00' + nSegundo.toString();
        if (sSegundo.length > 2) {
            sSegundo = sSegundo.substring(sSegundo.length - 2, sSegundo.length);
        }
        return sHora + ':' + sMinuto + ':' + sSegundo;
    }
    static converterDataHoraParaFormatoPHP(data) {
        if ((typeof data === 'undefined') || (data === null)) {
            return null;
        }
        return DataUtil.converterDataParaFormatoPHP(data) + ' ' +
            DataUtil.converterHoraParaFormatoPHP(data);
    }
    static converterDataParaFormatoYYMM(data) {
        if ((typeof data === 'undefined') || (data === null)) {
            return null;
        }
        const nDataHora = DataUtil.converterValorParaDataHora(data);
        if (!nDataHora) {
            return null;
        }
        const nAno = nDataHora.getFullYear();
        const nMes = (nDataHora.getMonth() + 1);
        let sAno = '00' + nAno.toString();
        if (sAno.length > 2) {
            sAno = sAno.substring(sAno.length - 2, sAno.length);
        }
        let sMes = '00' + nMes.toString();
        if (sMes.length > 2) {
            sMes = sMes.substring(sMes.length - 2, sMes.length);
        }
        return sAno + '' + sMes;
    }
    static converterDataParaFormatoYYMMDD(data) {
        if ((typeof data === 'undefined') || (data === null)) {
            return null;
        }
        const nDataHora = DataUtil.converterValorParaDataHora(data);
        if (!nDataHora) {
            return null;
        }
        const nAno = nDataHora.getFullYear();
        const nMes = (nDataHora.getMonth() + 1);
        const nDia = nDataHora.getDate();
        let sAno = '00' + nAno.toString();
        if (sAno.length > 2) {
            sAno = sAno.substring(sAno.length - 2, sAno.length);
        }
        let sMes = '00' + nMes.toString();
        if (sMes.length > 2) {
            sMes = sMes.substring(sMes.length - 2, sMes.length);
        }
        let sDia = '00' + nDia.toString();
        if (sDia.length > 2) {
            sDia = sDia.substring(sDia.length - 2, sDia.length);
        }
        return sAno + '' + sMes + '' + sDia;
    }
    static converterDataParaFormatoDDMMYYYY(data) {
        if ((typeof data === 'undefined') || (data === null)) {
            return null;
        }
        const nDataHora = DataUtil.converterValorParaDataHora(data);
        if (!nDataHora) {
            return null;
        }
        const nAno = nDataHora.getFullYear();
        const nMes = (nDataHora.getMonth() + 1);
        const nDia = nDataHora.getDate();
        let sDia = '00' + nDia.toString();
        if (sDia.length > 2) {
            sDia = sDia.substring(sDia.length - 2, sDia.length);
        }
        let sMes = '00' + nMes.toString();
        if (sMes.length > 2) {
            sMes = sMes.substring(sMes.length - 2, sMes.length);
        }
        let sAno = '0000' + nAno.toString();
        if (sAno.length > 4) {
            sAno = sAno.substring(sAno.length - 4, sAno.length);
        }
        return sDia + '/' + sMes + '/' + sAno;
    }
    static converterDataParaFormatoDDMMYY(data) {
        if ((typeof data === 'undefined') || (data === null)) {
            return null;
        }
        const nDataHora = DataUtil.converterValorParaDataHora(data);
        if (!nDataHora) {
            return null;
        }
        const nAno = nDataHora.getFullYear();
        const nMes = (nDataHora.getMonth() + 1);
        const nDia = nDataHora.getDate();
        let sDia = '00' + nDia.toString();
        if (sDia.length > 2) {
            sDia = sDia.substring(sDia.length - 2, sDia.length);
        }
        let sMes = '00' + nMes.toString();
        if (sMes.length > 2) {
            sMes = sMes.substring(sMes.length - 2, sMes.length);
        }
        let sAno = '00' + nAno.toString();
        if (sAno.length > 2) {
            sAno = sAno.substring(sAno.length - 2, sAno.length);
        }
        return sDia + '/' + sMes + '/' + sAno;
    }
    static converterDataParaFormatoDDMM(data) {
        if ((typeof data === 'undefined') || (data === null)) {
            return null;
        }
        const nDataHora = DataUtil.converterValorParaDataHora(data);
        if (!nDataHora) {
            return null;
        }
        const nMes = (nDataHora.getMonth() + 1);
        const nDia = nDataHora.getDate();
        let sDia = '00' + nDia.toString();
        if (sDia.length > 2) {
            sDia = sDia.substring(sDia.length - 2, sDia.length);
        }
        let sMes = '00' + nMes.toString();
        if (sMes.length > 2) {
            sMes = sMes.substring(sMes.length - 2, sMes.length);
        }
        return sDia + '/' + sMes;
    }
    static converterDataParaFormatoMMYYYY(data) {
        if ((typeof data === 'undefined') || (data === null)) {
            return null;
        }
        const nDataHora = DataUtil.converterValorParaDataHora(data);
        if (!nDataHora) {
            return null;
        }
        const nAno = nDataHora.getFullYear();
        const nMes = (nDataHora.getMonth() + 1);
        let sMes = '00' + nMes.toString();
        if (sMes.length > 2) {
            sMes = sMes.substring(sMes.length - 2, sMes.length);
        }
        let sAno = '0000' + nAno.toString();
        if (sAno.length > 4) {
            sAno = sAno.substring(sAno.length - 4, sAno.length);
        }
        return sMes + '/' + sAno;
    }
    static converterDataParaFormatoMMMYYYY(data) {
        if ((typeof data === 'undefined') || (data === null)) {
            return null;
        }
        const nDataHora = DataUtil.converterValorParaDataHora(data);
        if (!nDataHora) {
            return null;
        }
        const nAno = nDataHora.getFullYear();
        const nMes = (nDataHora.getMonth() + 1);
        let sMes = ((nMes === 1 ? 'Jan' :
            (nMes === 2 ? 'Fev' :
                (nMes === 3 ? 'Mar' :
                    (nMes === 4 ? 'Abr' :
                        (nMes === 5 ? 'Mai' :
                            (nMes === 6 ? 'Jun' :
                                (nMes === 7 ? 'Jul' :
                                    (nMes === 8 ? 'Ago' :
                                        (nMes === 9 ? 'Set' :
                                            (nMes === 10 ? 'Out' :
                                                (nMes === 11 ? 'Nov' :
                                                    (nMes === 12 ? 'Dez' :
                                                        nMes.toString())))))))))))));
        let sAno = '0000' + nAno.toString();
        if (sAno.length > 4) {
            sAno = sAno.substring(sAno.length - 4, sAno.length);
        }
        return sMes + '/' + sAno;
    }
    static converterDataParaFormatoMMMMYYYY(data) {
        if ((typeof data === 'undefined') || (data === null)) {
            return null;
        }
        const nDataHora = DataUtil.converterValorParaDataHora(data);
        if (!nDataHora) {
            return null;
        }
        const nAno = nDataHora.getFullYear();
        const nMes = (nDataHora.getMonth() + 1);
        let sMes = ((nMes === 1 ? 'Janeiro' :
            (nMes === 2 ? 'Fevereiro' :
                (nMes === 3 ? 'Março' :
                    (nMes === 4 ? 'Abril' :
                        (nMes === 5 ? 'Maio' :
                            (nMes === 6 ? 'Junho' :
                                (nMes === 7 ? 'Julho' :
                                    (nMes === 8 ? 'Agosto' :
                                        (nMes === 9 ? 'Setembro' :
                                            (nMes === 10 ? 'Outubro' :
                                                (nMes === 11 ? 'Novembro' :
                                                    (nMes === 12 ? 'Dezembro' :
                                                        nMes.toString())))))))))))));
        let sAno = '0000' + nAno.toString();
        if (sAno.length > 4) {
            sAno = sAno.substring(sAno.length - 4, sAno.length);
        }
        return sMes + '/' + sAno;
    }
    static converterDataParaFormatoHHMMSS(data) {
        if ((typeof data === 'undefined') || (data === null)) {
            return null;
        }
        const nDataHora = DataUtil.converterValorParaDataHora(data);
        if (!nDataHora) {
            return null;
        }
        const nHora = nDataHora.getHours();
        const nMinuto = nDataHora.getMinutes();
        const nSegundo = nDataHora.getSeconds();
        let sHora = '00' + nHora.toString();
        if (sHora.length > 2) {
            sHora = sHora.substring(sHora.length - 2, sHora.length);
        }
        let sMinuto = '00' + nMinuto.toString();
        if (sMinuto.length > 2) {
            sMinuto = sMinuto.substring(sMinuto.length - 2, sMinuto.length);
        }
        let sSegundo = '00' + nSegundo.toString();
        if (sSegundo.length > 2) {
            sSegundo = sSegundo.substring(sSegundo.length - 2, sSegundo.length);
        }
        return sHora + ':' + sMinuto + ':' + sSegundo;
    }
    static diasEntreDatas(dataA, dataB) {
        if ((typeof dataA === 'undefined') || (dataA === null) ||
            (typeof dataB === 'undefined') || (dataB === null)) {
            return null;
        }
        const valorDataA = DataUtil.converterValorParaDataHora(dataA);
        const valorDataB = DataUtil.converterValorParaDataHora(dataB);
        const diferencaEmMilissegundos = Math.abs(valorDataA.getTime() - valorDataB.getTime());
        return Math.round(diferencaEmMilissegundos / (1000 * 3600 * 24));
    }
}
exports.DataUtil = DataUtil;
