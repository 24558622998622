"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TipoTesteTratamentoTestemunha = void 0;
class TipoTesteTratamentoTestemunha {
    constructor() {
        this.id = null;
        this.descricao = null;
    }
    static TRATAMENTO() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.tipoTesteTratamento === 'undefined') ||
            (globalThis.globalConstants.tipoTesteTratamento === null)) {
            let tipoTesteTratamento = new TipoTesteTratamentoTestemunha();
            tipoTesteTratamento.id = 'TRATAMENTO';
            tipoTesteTratamento.descricao = 'Tratamento';
            globalThis.globalConstants.tipoTesteTratamento = tipoTesteTratamento;
        }
        return globalThis.globalConstants.tipoTesteTratamento;
    }
    static TESTEMUNHA() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.tipoTesteTestemunha === 'undefined') ||
            (globalThis.globalConstants.tipoTesteTestemunha === null)) {
            let tipoTesteTestemunha = new TipoTesteTratamentoTestemunha();
            tipoTesteTestemunha.id = 'TESTEMUNHA';
            tipoTesteTestemunha.descricao = 'Testemunha';
            globalThis.globalConstants.tipoTesteTestemunha = tipoTesteTestemunha;
        }
        return globalThis.globalConstants.tipoTesteTestemunha;
    }
    static LISTA() {
        return [
            TipoTesteTratamentoTestemunha.TRATAMENTO(),
            TipoTesteTratamentoTestemunha.TESTEMUNHA()
        ];
    }
}
exports.TipoTesteTratamentoTestemunha = TipoTesteTratamentoTestemunha;
