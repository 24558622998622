"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AmostraGerminacao = void 0;
const Base_1 = require("./Base");
class AmostraGerminacao extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.primeiraQuadrante = null;
        this.segundaQuadrante = null;
        this.terceiraQuadrante = null;
        this.quartaQuadrante = null;
        this.germinacao = null;
    }
}
exports.AmostraGerminacao = AmostraGerminacao;
