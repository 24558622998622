"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppRota = void 0;
const RotaFactory_1 = require("../../fabricas/RotaFactory");
const AppModulo_1 = require("./AppModulo");
const InicioController_1 = require("./paginas/inicio/InicioController");
const LoginController_1 = require("./paginas/login/LoginController");
const SincronizacaoController_1 = require("./paginas/sincronizacao/SincronizacaoController");
class AppRota {
    constructor($urlRouterProvider, $stateProvider) {
        this.$urlRouterProvider = $urlRouterProvider;
        this.$stateProvider = $stateProvider;
        this.rota = null;
        this.rota = new RotaFactory_1.RotaFactory(this.$stateProvider);
        this.iniciar();
    }
    iniciar() {
        this.registrar();
    }
    registrar() {
        this.rota.modulo(AppModulo_1.AppModulo);
        const login = {
            controladora: LoginController_1.LoginController,
            nome: 'login',
            titulo: 'Sistema de Gestão de Pesquisa'
        };
        this.rota.addRota(login, '/');
        const inicio = {
            controladora: InicioController_1.InicioController,
            nome: 'inicio',
            titulo: 'Sistema de Gestão de Pesquisa',
            autenticar: true
        };
        this.rota.addRota(inicio);
        const sincronizacao = {
            controladora: SincronizacaoController_1.SincronizacaoController,
            nome: 'sincronizacao',
            titulo: 'Sincronizar Dados',
            autenticar: true,
            template: require('../../modulos/app/paginas/sincronizacao/lista.html')
        };
        this.rota.addListar(sincronizacao);
        this.$urlRouterProvider.otherwise('/inicio');
    }
}
exports.AppRota = AppRota;
AppRota.$inject = ['$urlRouterProvider', '$stateProvider'];
