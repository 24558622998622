"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiltroProdutividadePomarTratado = exports.FiltroProdutividadePomar = void 0;
class FiltroProdutividadePomar {
    constructor() {
        this.dataIni = null;
        this.dataFim = null;
        this.funcionario = null;
    }
}
exports.FiltroProdutividadePomar = FiltroProdutividadePomar;
class FiltroProdutividadePomarTratado {
    constructor() {
        this.pomar = null;
        this.funcionario = null;
    }
}
exports.FiltroProdutividadePomarTratado = FiltroProdutividadePomarTratado;
