"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SincroniaCustomizada = exports.ItemSincroniaCustomizada = void 0;
const RotaAPIConstantes_1 = require("../constantes/RotaAPIConstantes");
const SincroniaConstantes_1 = require("../constantes/SincroniaConstantes");
const ConfiguracaoGeral_1 = require("../modelos/ConfiguracaoGeral");
const Usuario_1 = require("../modelos/Usuario");
const Ged_1 = require("../modelos/Ged");
const Lote_1 = require("../modelos/Lote");
const MaterialGenetico_1 = require("../modelos/MaterialGenetico");
const Genero_1 = require("../modelos/Genero");
const Especie_1 = require("../modelos/Especie");
const Geracao_1 = require("../modelos/Geracao");
const Conjunto_1 = require("../modelos/Conjunto");
const Dialelo_1 = require("../modelos/Dialelo");
const Pomar_1 = require("../modelos/Pomar");
const TipoPomar_1 = require("../modelos/TipoPomar");
const ClassificacaoSolo_1 = require("../modelos/ClassificacaoSolo");
const UnidadeManejo_1 = require("../modelos/UnidadeManejo");
const Regiao_1 = require("../modelos/Regiao");
const Talhao_1 = require("../modelos/Talhao");
const ImportaTalhao_1 = require("../modelos/ImportaTalhao");
const ImportaShapefile_1 = require("../modelos/ImportaShapefile");
const Fonte_1 = require("../modelos/Fonte");
const TipoManejo_1 = require("../modelos/TipoManejo");
const TipoDesbasteManejoPomar_1 = require("../modelos/TipoDesbasteManejoPomar");
const TipoLimpezaManejoPomar_1 = require("../modelos/TipoLimpezaManejoPomar");
const TipoAmostraManejoPomar_1 = require("../modelos/TipoAmostraManejoPomar");
const FinalidadeAmostraManejoPomar_1 = require("../modelos/FinalidadeAmostraManejoPomar");
const PragaDoencaManejoPomar_1 = require("../modelos/PragaDoencaManejoPomar");
const Funcionario_1 = require("../modelos/Funcionario");
const Cliente_1 = require("../modelos/Cliente");
const Pais_1 = require("../modelos/Pais");
const ProvinciaUf_1 = require("../modelos/ProvinciaUf");
const Localidade_1 = require("../modelos/Localidade");
const Hibridacao_1 = require("../modelos/Hibridacao");
const Polinizacao_1 = require("../modelos/Polinizacao");
const TipoTestePopulacao_1 = require("../modelos/TipoTestePopulacao");
const Situacao_1 = require("../modelos/Situacao");
const CloneMaterialGenetico_1 = require("../modelos/CloneMaterialGenetico");
const TipoImplantacao_1 = require("../modelos/TipoImplantacao");
const Procedencia_1 = require("../modelos/Procedencia");
const Reproducao_1 = require("../modelos/Reproducao");
const TipoPomarPopulacao_1 = require("../modelos/TipoPomarPopulacao");
const TipoDelineamento_1 = require("../modelos/TipoDelineamento");
const DisposicaoIndividuos_1 = require("../modelos/DisposicaoIndividuos");
const ClassificacaoClimatica_1 = require("../modelos/ClassificacaoClimatica");
const OperacaoManejoPomar_1 = require("../modelos/OperacaoManejoPomar");
const ProdutoFertilizante_1 = require("../modelos/ProdutoFertilizante");
const HistoricoEstoqueProdutoFertilizante_1 = require("../modelos/HistoricoEstoqueProdutoFertilizante");
const TipoAplicacao_1 = require("../modelos/TipoAplicacao");
const TipoIntervencao_1 = require("../modelos/TipoIntervencao");
const TesteGenetico_1 = require("../modelos/TesteGenetico");
const AnoEnxertia_1 = require("../modelos/AnoEnxertia");
const Estrobilo_1 = require("../modelos/Estrobilo");
const EstagioFloralFeminino_1 = require("../modelos/EstagioFloralFeminino");
const EstagioFloralMasculino_1 = require("../modelos/EstagioFloralMasculino");
const FaseAvaliada_1 = require("../modelos/FaseAvaliada");
const EstagioFloralEucalipto_1 = require("../modelos/EstagioFloralEucalipto");
const MaturacaoFruto_1 = require("../modelos/MaturacaoFruto");
const Cultivar_1 = require("../modelos/Cultivar");
const CategoriaPomar_1 = require("../modelos/CategoriaPomar");
const FinalidadeSemeio_1 = require("../modelos/FinalidadeSemeio");
const UnidadeMedida_1 = require("../modelos/UnidadeMedida");
const TratamentoFungicida_1 = require("../modelos/TratamentoFungicida");
const StatusMinijardim_1 = require("../modelos/StatusMinijardim");
const FinalidadeMultiplicacao_1 = require("../modelos/FinalidadeMultiplicacao");
const AcaoMinijardim_1 = require("../modelos/AcaoMinijardim");
const Canaletoes_1 = require("../modelos/Canaletoes");
const Minijardim_1 = require("../modelos/Minijardim");
const TipoMinijardim_1 = require("../modelos/TipoMinijardim");
const CaixaDAgua_1 = require("../modelos/CaixaDAgua");
const ReceitaSolucao_1 = require("../modelos/ReceitaSolucao");
const NivelContaminacao_1 = require("../modelos/NivelContaminacao");
const CasaVegetacao_1 = require("../modelos/CasaVegetacao");
const CargoFuncao_1 = require("../modelos/CargoFuncao");
const Laboratorio_1 = require("../modelos/Laboratorio");
const AreaSolicitante_1 = require("../modelos/AreaSolicitante");
const TipoAmostra_1 = require("../modelos/TipoAmostra");
const TipoAnalise_1 = require("../modelos/TipoAnalise");
const StatusAnalise_1 = require("../modelos/StatusAnalise");
const Safra_1 = require("../modelos/Safra");
const Propagulo_1 = require("../modelos/Propagulo");
const ColetaMacroestaca_1 = require("../modelos/ColetaMacroestaca");
const ClassificacaoBrotacao_1 = require("../modelos/ClassificacaoBrotacao");
const ProdutoDefensivo_1 = require("../modelos/ProdutoDefensivo");
const HistoricoEstoqueProdutoDefensivo_1 = require("../modelos/HistoricoEstoqueProdutoDefensivo");
const EstagioObservacaoPolinizacaoPomar_1 = require("../modelos/EstagioObservacaoPolinizacaoPomar");
const LabSolicitacaoAnalises_1 = require("../modelos/LabSolicitacaoAnalises");
const LabMaturacaoCones_1 = require("../modelos/LabMaturacaoCones");
const LabUmidadeSementes_1 = require("../modelos/LabUmidadeSementes");
const LabGerminacaoSementes_1 = require("../modelos/LabGerminacaoSementes");
const LabUmidadePolen_1 = require("../modelos/LabUmidadePolen");
const LabGerminacaoPolen_1 = require("../modelos/LabGerminacaoPolen");
const LabEstudoCones_1 = require("../modelos/LabEstudoCones");
const LabExperimentos_1 = require("../modelos/LabExperimentos");
const LabManuaisProcedimentos_1 = require("../modelos/LabManuaisProcedimentos");
const AnaliseInternaGerminacao_1 = require("../modelos/AnaliseInternaGerminacao");
const LabGerminacaoSementesExterno_1 = require("../modelos/LabGerminacaoSementesExterno");
const ProducaoMudasExperimentos_1 = require("../modelos/ProducaoMudasExperimentos");
const ProducaoMudasRecomendacoes_1 = require("../modelos/ProducaoMudasRecomendacoes");
const ProducaoMudasSeminal_1 = require("../modelos/ProducaoMudasSeminal");
const ProducaoMudasPlanejamento_1 = require("../modelos/ProducaoMudasPlanejamento");
const ProducaoMudasSemeioTestes_1 = require("../modelos/ProducaoMudasSemeioTestes");
const ProducaoMudasSemeioEnxertia_1 = require("../modelos/ProducaoMudasSemeioEnxertia");
const ProducaoMudasMetaProducao_1 = require("../modelos/ProducaoMudasMetaProducao");
const ProducaoMudaClonal_1 = require("../modelos/ProducaoMudaClonal");
const ProducaoMudaClonalEucalyptus_1 = require("../modelos/ProducaoMudaClonalEucalyptus");
const ProducaoMudaClonalEucalyptusResgateMacroestacas_1 = require("../modelos/ProducaoMudaClonalEucalyptusResgateMacroestacas");
const ProducaoMudaClonalEucalyptusResgateMacroestacasCorteRaso_1 = require("../modelos/ProducaoMudaClonalEucalyptusResgateMacroestacasCorteRaso");
const ProducaoMudaClonalEucalyptusResgateMacroestacasBrotacoes_1 = require("../modelos/ProducaoMudaClonalEucalyptusResgateMacroestacasBrotacoes");
const ProducaoMudaClonalEucalyptusResgateMacroestacasAdubacao_1 = require("../modelos/ProducaoMudaClonalEucalyptusResgateMacroestacasAdubacao");
const ProducaoMudaClonalEucalyptusResgateMacroestacasControlePragas_1 = require("../modelos/ProducaoMudaClonalEucalyptusResgateMacroestacasControlePragas");
const ProducaoMudaClonalEucalyptusResgateMacroestacasConfeccao_1 = require("../modelos/ProducaoMudaClonalEucalyptusResgateMacroestacasConfeccao");
const ProducaoMudaClonalEucalyptusResgateMacroestacasAvaliacao_1 = require("../modelos/ProducaoMudaClonalEucalyptusResgateMacroestacasAvaliacao");
const ProducaoMudaClonalEucalyptusMinijardim_1 = require("../modelos/ProducaoMudaClonalEucalyptusMinijardim");
const ProducaoMudaClonalMinijardimCroqui_1 = require("../modelos/ProducaoMudaClonalMinijardimCroqui");
const ProducaoMudaClonalMinijardimCroquiAnexo_1 = require("../modelos/ProducaoMudaClonalMinijardimCroquiAnexo");
const ProducaoMudaClonalMinijardimPlantioMacroestacas_1 = require("../modelos/ProducaoMudaClonalMinijardimPlantioMacroestacas");
const ProducaoMudaClonalMinijardimPlantioMiniestacas_1 = require("../modelos/ProducaoMudaClonalMinijardimPlantioMiniestacas");
const ProducaoMudaClonalMinijardimAvaliacaoSobrevivencia_1 = require("../modelos/ProducaoMudaClonalMinijardimAvaliacaoSobrevivencia");
const ProducaoMudaClonalMinijardimEliminarMiniMacroEstacas_1 = require("../modelos/ProducaoMudaClonalMinijardimEliminarMiniMacroEstacas");
const ProducaoMudaClonalMinijardimManejo_1 = require("../modelos/ProducaoMudaClonalMinijardimManejo");
const ProducaoMudaClonalEucalyptusMiniestaquia_1 = require("../modelos/ProducaoMudaClonalEucalyptusMiniestaquia");
const ProducaoMudaClonalMiniestaquiaAvaliacaoEnraizamento_1 = require("../modelos/ProducaoMudaClonalMiniestaquiaAvaliacaoEnraizamento");
const ProducaoMudaClonalMiniestaquiaAvaliacaoSobrevivenciaCasaSombra_1 = require("../modelos/ProducaoMudaClonalMiniestaquiaAvaliacaoSobrevivenciaCasaSombra");
const ProducaoMudaClonalMiniestaquiaAvaliacaoSobrevivenciaAclimatacao_1 = require("../modelos/ProducaoMudaClonalMiniestaquiaAvaliacaoSobrevivenciaAclimatacao");
const ProducaoMudaClonalPinus_1 = require("../modelos/ProducaoMudaClonalPinus");
const ProducaoMudaClonalPinusMinijardim_1 = require("../modelos/ProducaoMudaClonalPinusMinijardim");
const ProducaoMudaClonalPinusMiniestaquia_1 = require("../modelos/ProducaoMudaClonalPinusMiniestaquia");
const ProducaoMudaControlePragaDoenca_1 = require("../modelos/ProducaoMudaControlePragaDoenca");
const ProtecaoRegistroCultivar_1 = require("../modelos/ProtecaoRegistroCultivar");
const ProtecaoRegistroCultivarDescritorMorfologico_1 = require("../modelos/ProtecaoRegistroCultivarDescritorMorfologico");
const ProtecaoRegistroCultivarLaudoDna_1 = require("../modelos/ProtecaoRegistroCultivarLaudoDna");
const ProtecaoRegistroCultivarOrientacoesProcedimentos_1 = require("../modelos/ProtecaoRegistroCultivarOrientacoesProcedimentos");
const ProtecaoRegistroCultivarProteccao_1 = require("../modelos/ProtecaoRegistroCultivarProteccao");
const ProtecaoRegistroCultivarRegistro_1 = require("../modelos/ProtecaoRegistroCultivarRegistro");
const CaracteristicaDescritorMorfologico_1 = require("../modelos/CaracteristicaDescritorMorfologico");
const CentroCustoSap_1 = require("../modelos/CentroCustoSap");
const ContaDepositoSap_1 = require("../modelos/ContaDepositoSap");
const PomarBancoConservacao_1 = require("../modelos/PomarBancoConservacao");
const TratamentoPomar_1 = require("../modelos/TratamentoPomar");
const CroquiPomar_1 = require("../modelos/CroquiPomar");
const MapaPomar_1 = require("../modelos/MapaPomar");
const PlanejamentoPomar_1 = require("../modelos/PlanejamentoPomar");
const ControleManejoPomar_1 = require("../modelos/ControleManejoPomar");
const ControleEnxertiaPomar_1 = require("../modelos/ControleEnxertiaPomar");
const ProdutividadePomar_1 = require("../modelos/ProdutividadePomar");
const FenologiaPomar_1 = require("../modelos/FenologiaPomar");
const GraficoFenologiaPomar_1 = require("../modelos/GraficoFenologiaPomar");
const PolinizacaoPomar_1 = require("../modelos/PolinizacaoPomar");
const EstoquePolenPolinizacaoPomar_1 = require("../modelos/EstoquePolenPolinizacaoPomar");
const HistoricoEstoquePolenPolinizacaoPomar_1 = require("../modelos/HistoricoEstoquePolenPolinizacaoPomar");
const EstoqueBeneficiamentoPinusPolinizacaoPomar_1 = require("../modelos/EstoqueBeneficiamentoPinusPolinizacaoPomar");
const HistoricoEstoqueBeneficiamentoPinusPolinizacaoPomar_1 = require("../modelos/HistoricoEstoqueBeneficiamentoPinusPolinizacaoPomar");
const EstoqueBeneficiamentoEucalyptusPolinizacaoPomar_1 = require("../modelos/EstoqueBeneficiamentoEucalyptusPolinizacaoPomar");
const HistoricoEstoqueBeneficiamentoEucalyptusPolinizacaoPomar_1 = require("../modelos/HistoricoEstoqueBeneficiamentoEucalyptusPolinizacaoPomar");
const AnexoPomar_1 = require("../modelos/AnexoPomar");
const TesteCampo_1 = require("../modelos/TesteCampo");
const TratamentoTesteGenetico_1 = require("../modelos/TratamentoTesteGenetico");
const CroquiTesteGenetico_1 = require("../modelos/CroquiTesteGenetico");
const MapaTesteGenetico_1 = require("../modelos/MapaTesteGenetico");
const ControleManejoTesteGenetico_1 = require("../modelos/ControleManejoTesteGenetico");
const MedicaoDendrometricaTesteGenetico_1 = require("../modelos/MedicaoDendrometricaTesteGenetico");
const LancamentoMedicaoDendrometricaTesteGenetico_1 = require("../modelos/LancamentoMedicaoDendrometricaTesteGenetico");
const PlanejamentoMedicaoDendrometricaTesteGenetico_1 = require("../modelos/PlanejamentoMedicaoDendrometricaTesteGenetico");
const PlanilhaMedicaoDendrometricaTesteGenetico_1 = require("../modelos/PlanilhaMedicaoDendrometricaTesteGenetico");
const QualidadeMadeiraTesteGenetico_1 = require("../modelos/QualidadeMadeiraTesteGenetico");
const AnaliseSelecaoTesteGenetico_1 = require("../modelos/AnaliseSelecaoTesteGenetico");
const SumarioAnaliseSelecaoTesteGenetico_1 = require("../modelos/SumarioAnaliseSelecaoTesteGenetico");
const PlanilhaSelegenAnaliseSelecaoTesteGenetico_1 = require("../modelos/PlanilhaSelegenAnaliseSelecaoTesteGenetico");
const AnaliseSelegenAnaliseSelecaoTesteGenetico_1 = require("../modelos/AnaliseSelegenAnaliseSelecaoTesteGenetico");
const ResultadoSelegenAnaliseSelecaoTesteGenetico_1 = require("../modelos/ResultadoSelegenAnaliseSelecaoTesteGenetico");
const AnaliseAnoAnaliseSelecaoTesteGenetico_1 = require("../modelos/AnaliseAnoAnaliseSelecaoTesteGenetico");
const AnaliseBlupAnaliseSelecaoTesteGenetico_1 = require("../modelos/AnaliseBlupAnaliseSelecaoTesteGenetico");
const SelecaoGeneticaAnaliseSelecaoTesteGenetico_1 = require("../modelos/SelecaoGeneticaAnaliseSelecaoTesteGenetico");
const SelecaoCampoAnaliseSelecaoTesteGenetico_1 = require("../modelos/SelecaoCampoAnaliseSelecaoTesteGenetico");
const AnexoAnaliseSelecaoTesteGenetico_1 = require("../modelos/AnexoAnaliseSelecaoTesteGenetico");
const AnexoTesteGenetico_1 = require("../modelos/AnexoTesteGenetico");
const ProtocoloTesteGenetico_1 = require("../modelos/ProtocoloTesteGenetico");
const PosicaoColunaTabela_1 = require("../modelos/PosicaoColunaTabela");
const VisibilidadeColunaTabela_1 = require("../modelos/VisibilidadeColunaTabela");
class ItemSincroniaCustomizada {
    constructor(rota, etiqueta, modelo) {
        this.rota = rota;
        this.etiqueta = etiqueta;
        this.modelo = modelo;
    }
}
exports.ItemSincroniaCustomizada = ItemSincroniaCustomizada;
class SincroniaCustomizada {
    constructor(factory) {
        this.factory = factory;
        this.itensParaBaixar = [{
                rota: RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.MATERIAL_GENETICO,
                modelo: MaterialGenetico_1.MaterialGenetico
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.GENERO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.GENERO,
                modelo: Genero_1.Genero
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.ESPECIE,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.ESPECIE,
                modelo: Especie_1.Especie
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.GERACAO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.GERACAO,
                modelo: Geracao_1.Geracao
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.CONJUNTO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.CONJUNTO,
                modelo: Conjunto_1.Conjunto
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.DIALELO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.DIALELO,
                modelo: Dialelo_1.Dialelo
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.POMAR,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.POMAR,
                modelo: Pomar_1.Pomar
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.TIPO_POMAR,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.TIPO_POMAR,
                modelo: TipoPomar_1.TipoPomar
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.CLASSIFICACAO_SOLO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.CLASSIFICACAO_SOLO,
                modelo: ClassificacaoSolo_1.ClassificacaoSolo
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.UNIDADE_MANEJO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.UNIDADE_MANEJO,
                modelo: UnidadeManejo_1.UnidadeManejo
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.REGIAO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.REGIAO,
                modelo: Regiao_1.Regiao
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.TALHAO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.TALHAO,
                modelo: Talhao_1.Talhao
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.IMPORTA_TALHAO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.IMPORTA_TALHAO,
                modelo: ImportaTalhao_1.ImportaTalhao
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.IMPORTA_SHAPEFILE,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.IMPORTA_SHAPEFILE,
                modelo: ImportaShapefile_1.ImportaShapefile
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.FONTE,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.FONTE,
                modelo: Fonte_1.Fonte
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.TIPO_MANEJO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.TIPO_MANEJO,
                modelo: TipoManejo_1.TipoManejo
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.TIPO_DESBASTE_MANEJO_POMAR,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.TIPO_DESBASTE_MANEJO_POMAR,
                modelo: TipoDesbasteManejoPomar_1.TipoDesbasteManejoPomar
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.TIPO_LIMPEZA_MANEJO_POMAR,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.TIPO_LIMPEZA_MANEJO_POMAR,
                modelo: TipoLimpezaManejoPomar_1.TipoLimpezaManejoPomar
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.TIPO_AMOSTRA_MANEJO_POMAR,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.TIPO_AMOSTRA_MANEJO_POMAR,
                modelo: TipoAmostraManejoPomar_1.TipoAmostraManejoPomar
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.FINALIDADE_AMOSTRA_MANEJO_POMAR,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.FINALIDADE_AMOSTRA_MANEJO_POMAR,
                modelo: FinalidadeAmostraManejoPomar_1.FinalidadeAmostraManejoPomar
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRAGA_DOENCA_MANEJO_POMAR,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRAGA_DOENCA_MANEJO_POMAR,
                modelo: PragaDoencaManejoPomar_1.PragaDoencaManejoPomar
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.FUNCIONARIO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.FUNCIONARIO,
                modelo: Funcionario_1.Funcionario
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.CLIENTE,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.CLIENTE,
                modelo: Cliente_1.Cliente
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PAIS,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PAIS,
                modelo: Pais_1.Pais
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PROVINCIA_UF,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PROVINCIA_UF,
                modelo: ProvinciaUf_1.ProvinciaUf
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.LOCALIDADE,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.LOCALIDADE,
                modelo: Localidade_1.Localidade
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.HIBRIDACAO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.HIBRIDACAO,
                modelo: Hibridacao_1.Hibridacao
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.POLINIZACAO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.POLINIZACAO,
                modelo: Polinizacao_1.Polinizacao
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.TIPO_TESTE_POPULACAO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.TIPO_TESTE_POPULACAO,
                modelo: TipoTestePopulacao_1.TipoTestePopulacao
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.SITUACAO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.SITUACAO,
                modelo: Situacao_1.Situacao
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.CLONE_MATERIAL_GENETICO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.CLONE_MATERIAL_GENETICO,
                modelo: CloneMaterialGenetico_1.CloneMaterialGenetico
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.TIPO_IMPLANTACAO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.TIPO_IMPLANTACAO,
                modelo: TipoImplantacao_1.TipoImplantacao
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PROCEDENCIA,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PROCEDENCIA,
                modelo: Procedencia_1.Procedencia
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.REPRODUCAO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.REPRODUCAO,
                modelo: Reproducao_1.Reproducao
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.TIPO_POMAR_POPULACAO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.TIPO_POMAR_POPULACAO,
                modelo: TipoPomarPopulacao_1.TipoPomarPopulacao
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.TIPO_DELINEAMENTO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.TIPO_DELINEAMENTO,
                modelo: TipoDelineamento_1.TipoDelineamento
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.DISPOSICAO_INDIVIDUOS,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.DISPOSICAO_INDIVIDUOS,
                modelo: DisposicaoIndividuos_1.DisposicaoIndividuos
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.CLASSIFICACAO_CLIMATICA,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.CLASSIFICACAO_CLIMATICA,
                modelo: ClassificacaoClimatica_1.ClassificacaoClimatica
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.OPERACAO_MANEJO_POMAR,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.OPERACAO_MANEJO_POMAR,
                modelo: OperacaoManejoPomar_1.OperacaoManejoPomar
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE,
                modelo: ProdutoFertilizante_1.ProdutoFertilizante
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.HISTORICO_ESTOQUE_PRODUTO_FERTILIZANTE,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.HISTORICO_ESTOQUE_PRODUTO_FERTILIZANTE,
                modelo: HistoricoEstoqueProdutoFertilizante_1.HistoricoEstoqueProdutoFertilizante
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.TIPO_APLICACAO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.TIPO_APLICACAO,
                modelo: TipoAplicacao_1.TipoAplicacao
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.TIPO_INTERVENCAO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.TIPO_INTERVENCAO,
                modelo: TipoIntervencao_1.TipoIntervencao
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.TESTE_GENETICO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.TESTE_GENETICO,
                modelo: TesteGenetico_1.TesteGenetico
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.ANO_ENXERTIA,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.ANO_ENXERTIA,
                modelo: AnoEnxertia_1.AnoEnxertia
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.ESTROBILO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.ESTROBILO,
                modelo: Estrobilo_1.Estrobilo
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.ESTAGIO_FLORAL_FEMININO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.ESTAGIO_FLORAL_FEMININO,
                modelo: EstagioFloralFeminino_1.EstagioFloralFeminino
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.ESTAGIO_FLORAL_MASCULINO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.ESTAGIO_FLORAL_MASCULINO,
                modelo: EstagioFloralMasculino_1.EstagioFloralMasculino
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.FASE_AVALIADA,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.FASE_AVALIADA,
                modelo: FaseAvaliada_1.FaseAvaliada
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.ESTAGIO_FLORAL_EUCALIPTO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.ESTAGIO_FLORAL_EUCALIPTO,
                modelo: EstagioFloralEucalipto_1.EstagioFloralEucalipto
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.MATURACAO_FRUTO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.MATURACAO_FRUTO,
                modelo: MaturacaoFruto_1.MaturacaoFruto
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.CULTIVAR,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.CULTIVAR,
                modelo: Cultivar_1.Cultivar
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.CATEGORIA_POMAR,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.CATEGORIA_POMAR,
                modelo: CategoriaPomar_1.CategoriaPomar
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.FINALIDADE_SEMEIO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.FINALIDADE_SEMEIO,
                modelo: FinalidadeSemeio_1.FinalidadeSemeio
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.UNIDADE_MEDIDA,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.UNIDADE_MEDIDA,
                modelo: UnidadeMedida_1.UnidadeMedida
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.TRATAMENTO_FUNGICIDA,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.TRATAMENTO_FUNGICIDA,
                modelo: TratamentoFungicida_1.TratamentoFungicida
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.STATUS_MINIJARDIM,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.STATUS_MINIJARDIM,
                modelo: StatusMinijardim_1.StatusMinijardim
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.FINALIDADE_MULTIPLICACAO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.FINALIDADE_MULTIPLICACAO,
                modelo: FinalidadeMultiplicacao_1.FinalidadeMultiplicacao
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.ACAO_MINIJARDIM,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.ACAO_MINIJARDIM,
                modelo: AcaoMinijardim_1.AcaoMinijardim
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.CANALETOES,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.CANALETOES,
                modelo: Canaletoes_1.Canaletoes
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.MINIJARDIM,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.MINIJARDIM,
                modelo: Minijardim_1.Minijardim
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.TIPO_MINIJARDIM,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.TIPO_MINIJARDIM,
                modelo: TipoMinijardim_1.TipoMinijardim
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.CAIXA_DAGUA,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.CAIXA_DAGUA,
                modelo: CaixaDAgua_1.CaixaDAgua
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.RECEITA_SOLUCAO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.RECEITA_SOLUCAO,
                modelo: ReceitaSolucao_1.ReceitaSolucao
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.NIVEL_CONTAMINACAO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.NIVEL_CONTAMINACAO,
                modelo: NivelContaminacao_1.NivelContaminacao
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.CASA_VEGETACAO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.CASA_VEGETACAO,
                modelo: CasaVegetacao_1.CasaVegetacao
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.CARGO_FUNCAO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.CARGO_FUNCAO,
                modelo: CargoFuncao_1.CargoFuncao
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.LABORATORIO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.LABORATORIO,
                modelo: Laboratorio_1.Laboratorio
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.AREA_SOLICITANTE,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.AREA_SOLICITANTE,
                modelo: AreaSolicitante_1.AreaSolicitante
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.TIPO_AMOSTRA,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.TIPO_AMOSTRA,
                modelo: TipoAmostra_1.TipoAmostra
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.TIPO_ANALISE,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.TIPO_ANALISE,
                modelo: TipoAnalise_1.TipoAnalise
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.STATUS_ANALISE,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.STATUS_ANALISE,
                modelo: StatusAnalise_1.StatusAnalise
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.SAFRA,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.SAFRA,
                modelo: Safra_1.Safra
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.LOTE,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.LOTE,
                modelo: Lote_1.Lote
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PROPAGULO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PROPAGULO,
                modelo: Propagulo_1.Propagulo
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.COLETA_MACROESTACA,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.COLETA_MACROESTACA,
                modelo: ColetaMacroestaca_1.ColetaMacroestaca
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.CLASSIFICACAO_BROTACAO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.CLASSIFICACAO_BROTACAO,
                modelo: ClassificacaoBrotacao_1.ClassificacaoBrotacao
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_DEFENSIVO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUTO_DEFENSIVO,
                modelo: ProdutoDefensivo_1.ProdutoDefensivo
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.HISTORICO_ESTOQUE_PRODUTO_DEFENSIVO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.HISTORICO_ESTOQUE_PRODUTO_DEFENSIVO,
                modelo: HistoricoEstoqueProdutoDefensivo_1.HistoricoEstoqueProdutoDefensivo
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.ESTAGIO_OBSERVACAO_POLINIZACAO_POMAR,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.ESTAGIO_OBSERVACAO_POLINIZACAO_POMAR,
                modelo: EstagioObservacaoPolinizacaoPomar_1.EstagioObservacaoPolinizacaoPomar
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.LAB_SOLICITACAO_ANALISES,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.LAB_SOLICITACAO_ANALISES,
                modelo: LabSolicitacaoAnalises_1.LabSolicitacaoAnalises
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.LAB_MATURACAO_CONES,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.LAB_MATURACAO_CONES,
                modelo: LabMaturacaoCones_1.LabMaturacaoCones
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.LAB_UMIDADE_SEMENTES,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.LAB_UMIDADE_SEMENTES,
                modelo: LabUmidadeSementes_1.LabUmidadeSementes
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.LAB_GERMINACAO_SEMENTES,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.LAB_GERMINACAO_SEMENTES,
                modelo: LabGerminacaoSementes_1.LabGerminacaoSementes
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.LAB_UMIDADE_POLEN,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.LAB_UMIDADE_POLEN,
                modelo: LabUmidadePolen_1.LabUmidadePolen
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.LAB_GERMINACAO_POLEN,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.LAB_GERMINACAO_POLEN,
                modelo: LabGerminacaoPolen_1.LabGerminacaoPolen
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.LAB_ESTUDO_CONES,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.LAB_ESTUDO_CONES,
                modelo: LabEstudoCones_1.LabEstudoCones
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.LAB_EXPERIMENTOS,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.LAB_EXPERIMENTOS,
                modelo: LabExperimentos_1.LabExperimentos
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.LAB_MANUAIS_PROCEDIMENTOS,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.LAB_MANUAIS_PROCEDIMENTOS,
                modelo: LabManuaisProcedimentos_1.LabManuaisProcedimentos
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_INTERNA_AVALIACAO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.ANALISE_INTERNA_AVALIACAO,
                modelo: AnaliseInternaGerminacao_1.AnaliseInternaGerminacao
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.LAB_GERMINACAO_SEMENTES_EXTERNO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.LAB_GERMINACAO_SEMENTES_EXTERNO,
                modelo: LabGerminacaoSementesExterno_1.LabGerminacaoSementesExterno
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_EXPERIMENTOS,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_EXPERIMENTOS,
                modelo: ProducaoMudasExperimentos_1.ProducaoMudasExperimentos
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_RECOMENDACOES,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_RECOMENDACOES,
                modelo: ProducaoMudasRecomendacoes_1.ProducaoMudasRecomendacoes
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_SEMINAL,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_SEMINAL,
                modelo: ProducaoMudasSeminal_1.ProducaoMudasSeminal
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_PLANEJAMENTO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_PLANEJAMENTO,
                modelo: ProducaoMudasPlanejamento_1.ProducaoMudasPlanejamento
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_TESTE,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_TESTE,
                modelo: ProducaoMudasSemeioTestes_1.ProducaoMudasSemeioTestes
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_ENXERTIA,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_ENXERTIA,
                modelo: ProducaoMudasSemeioEnxertia_1.ProducaoMudasSemeioEnxertia
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_META_PRODUCAO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_META_PRODUCAO,
                modelo: ProducaoMudasMetaProducao_1.ProducaoMudasMetaProducao
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL,
                modelo: ProducaoMudaClonal_1.ProducaoMudaClonal
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS,
                modelo: ProducaoMudaClonalEucalyptus_1.ProducaoMudaClonalEucalyptus
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS,
                modelo: ProducaoMudaClonalEucalyptusResgateMacroestacas_1.ProducaoMudaClonalEucalyptusResgateMacroestacas
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CORTE_RASO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CORTE_RASO,
                modelo: ProducaoMudaClonalEucalyptusResgateMacroestacasCorteRaso_1.ProducaoMudaClonalEucalyptusResgateMacroestacasCorteRaso
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_BROTACAO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_BROTACAO,
                modelo: ProducaoMudaClonalEucalyptusResgateMacroestacasBrotacoes_1.ProducaoMudaClonalEucalyptusResgateMacroestacasBrotacoes
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_ADUBACAO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_ADUBACAO,
                modelo: ProducaoMudaClonalEucalyptusResgateMacroestacasAdubacao_1.ProducaoMudaClonalEucalyptusResgateMacroestacasAdubacao
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CONTROLE_PRAGAS,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CONTROLE_PRAGAS,
                modelo: ProducaoMudaClonalEucalyptusResgateMacroestacasControlePragas_1.ProducaoMudaClonalEucalyptusResgateMacroestacasControlePragas
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CONFECCAO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CONFECCAO,
                modelo: ProducaoMudaClonalEucalyptusResgateMacroestacasConfeccao_1.ProducaoMudaClonalEucalyptusResgateMacroestacasConfeccao
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_AVALIACAO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_AVALIACAO,
                modelo: ProducaoMudaClonalEucalyptusResgateMacroestacasAvaliacao_1.ProducaoMudaClonalEucalyptusResgateMacroestacasAvaliacao
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM,
                modelo: ProducaoMudaClonalEucalyptusMinijardim_1.ProducaoMudaClonalEucalyptusMinijardim
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_CROQUI,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_CROQUI,
                modelo: ProducaoMudaClonalMinijardimCroqui_1.ProducaoMudaClonalMinijardimCroqui
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_CROQUI_ANEXO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_CROQUI_ANEXO,
                modelo: ProducaoMudaClonalMinijardimCroquiAnexo_1.ProducaoMudaClonalMinijardimCroquiAnexo
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_PLANTIO_MACROESTACAS,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_PLANTIO_MACROESTACAS,
                modelo: ProducaoMudaClonalMinijardimPlantioMacroestacas_1.ProducaoMudaClonalMinijardimPlantioMacroestacas
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_PLANTIO_MINIESTACAS,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_PLANTIO_MINIESTACAS,
                modelo: ProducaoMudaClonalMinijardimPlantioMiniestacas_1.ProducaoMudaClonalMinijardimPlantioMiniestacas
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_AVALIACAO_SOBREVIVENCIA,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_AVALIACAO_SOBREVIVENCIA,
                modelo: ProducaoMudaClonalMinijardimAvaliacaoSobrevivencia_1.ProducaoMudaClonalMinijardimAvaliacaoSobrevivencia
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_ELIMINAR_MINIMACRO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_ELIMINAR_MINIMACRO,
                modelo: ProducaoMudaClonalMinijardimEliminarMiniMacroEstacas_1.ProducaoMudaClonalMinijardimEliminarMiniMacroEstacas
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_MANEJO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_MANEJO,
                modelo: ProducaoMudaClonalMinijardimManejo_1.ProducaoMudaClonalMinijardimManejo
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA,
                modelo: ProducaoMudaClonalEucalyptusMiniestaquia_1.ProducaoMudaClonalEucalyptusMiniestaquia
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_ENRAIZAMENTO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_ENRAIZAMENTO,
                modelo: ProducaoMudaClonalMiniestaquiaAvaliacaoEnraizamento_1.ProducaoMudaClonalMiniestaquiaAvaliacaoEnraizamento
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_CASA_SOMBRA,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_CASA_SOMBRA,
                modelo: ProducaoMudaClonalMiniestaquiaAvaliacaoSobrevivenciaCasaSombra_1.ProducaoMudaClonalMiniestaquiaAvaliacaoSobrevivenciaCasaSombra
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_ACLIMATACAO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_ACLIMATACAO,
                modelo: ProducaoMudaClonalMiniestaquiaAvaliacaoSobrevivenciaAclimatacao_1.ProducaoMudaClonalMiniestaquiaAvaliacaoSobrevivenciaAclimatacao
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS,
                modelo: ProducaoMudaClonalPinus_1.ProducaoMudaClonalPinus
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIJARDIM,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIJARDIM,
                modelo: ProducaoMudaClonalPinusMinijardim_1.ProducaoMudaClonalPinusMinijardim
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIJARDIM_CROQUI,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIJARDIM_CROQUI,
                modelo: ProducaoMudaClonalMinijardimCroqui_1.ProducaoMudaClonalMinijardimCroqui
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIJARDIM_CROQUI_ANEXO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIJARDIM_CROQUI_ANEXO,
                modelo: ProducaoMudaClonalMinijardimCroquiAnexo_1.ProducaoMudaClonalMinijardimCroquiAnexo
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIJARDIM_PLANTIO_MINIESTACAS,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIJARDIM_PLANTIO_MINIESTACAS,
                modelo: ProducaoMudaClonalMinijardimPlantioMiniestacas_1.ProducaoMudaClonalMinijardimPlantioMiniestacas
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIJARDIM_AVALIACAO_SOBREVIVENCIA,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIJARDIM_AVALIACAO_SOBREVIVENCIA,
                modelo: ProducaoMudaClonalMinijardimAvaliacaoSobrevivencia_1.ProducaoMudaClonalMinijardimAvaliacaoSobrevivencia
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIJARDIM_ELIMINAR_MINIMACRO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIJARDIM_ELIMINAR_MINIMACRO,
                modelo: ProducaoMudaClonalMinijardimEliminarMiniMacroEstacas_1.ProducaoMudaClonalMinijardimEliminarMiniMacroEstacas
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIJARDIM_MANEJO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIJARDIM_MANEJO,
                modelo: ProducaoMudaClonalMinijardimManejo_1.ProducaoMudaClonalMinijardimManejo
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA,
                modelo: ProducaoMudaClonalPinusMiniestaquia_1.ProducaoMudaClonalPinusMiniestaquia
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_ENRAIZAMENTO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_ENRAIZAMENTO,
                modelo: ProducaoMudaClonalMiniestaquiaAvaliacaoEnraizamento_1.ProducaoMudaClonalMiniestaquiaAvaliacaoEnraizamento
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_CASA_SOMBRA,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_CASA_SOMBRA,
                modelo: ProducaoMudaClonalMiniestaquiaAvaliacaoSobrevivenciaCasaSombra_1.ProducaoMudaClonalMiniestaquiaAvaliacaoSobrevivenciaCasaSombra
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_ACLIMATACAO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_ACLIMATACAO,
                modelo: ProducaoMudaClonalMiniestaquiaAvaliacaoSobrevivenciaAclimatacao_1.ProducaoMudaClonalMiniestaquiaAvaliacaoSobrevivenciaAclimatacao
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CONTROLE_PRAGA_DOENCA,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CONTROLE_PRAGA_DOENCA,
                modelo: ProducaoMudaControlePragaDoenca_1.ProducaoMudaControlePragaDoenca
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR,
                modelo: ProtecaoRegistroCultivar_1.ProtecaoRegistroCultivar
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_DESCRITOR_MORFOLOGICO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_DESCRITOR_MORFOLOGICO,
                modelo: ProtecaoRegistroCultivarDescritorMorfologico_1.ProtecaoRegistroCultivarDescritorMorfologico
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_LAUDO_DNA,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_LAUDO_DNA,
                modelo: ProtecaoRegistroCultivarLaudoDna_1.ProtecaoRegistroCultivarLaudoDna
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_ORIENTACOES_PROCEDIMENTOS,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_ORIENTACOES_PROCEDIMENTOS,
                modelo: ProtecaoRegistroCultivarOrientacoesProcedimentos_1.ProtecaoRegistroCultivarOrientacoesProcedimentos
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_PROTECCAO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_PROTECCAO,
                modelo: ProtecaoRegistroCultivarProteccao_1.ProtecaoRegistroCultivarProteccao
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_REGISTRO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_REGISTRO,
                modelo: ProtecaoRegistroCultivarRegistro_1.ProtecaoRegistroCultivarRegistro
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.CARACTERISTICA_DESCRITOR_MORFOLOGICO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.CARACTERISTICA_DESCRITOR_MORFOLOGICO,
                modelo: CaracteristicaDescritorMorfologico_1.CaracteristicaDescritorMorfologico
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.CENTRO_CUSTO_SAP,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.CENTRO_CUSTO_SAP,
                modelo: CentroCustoSap_1.CentroCustoSap
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.CONTA_DEPOSITO_SAP,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.CONTA_DEPOSITO_SAP,
                modelo: ContaDepositoSap_1.ContaDepositoSap
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.BANCO_MELHORAMENTO_POMAR,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.BANCO_MELHORAMENTO_POMAR,
                modelo: PomarBancoConservacao_1.PomarBancoConservacao
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.TRATAMENTO_POMAR,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.TRATAMENTO_POMAR,
                modelo: TratamentoPomar_1.TratamentoPomar
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.CROQUI_POMAR,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.CROQUI_POMAR,
                modelo: CroquiPomar_1.CroquiPomar
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.MAPA_POMAR,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.MAPA_POMAR,
                modelo: MapaPomar_1.MapaPomar
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PLANEJAMENTO_POMAR,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PLANEJAMENTO_POMAR,
                modelo: PlanejamentoPomar_1.PlanejamentoPomar
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.CONTROLE_MANEJO_POMAR,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.CONTROLE_MANEJO_POMAR,
                modelo: ControleManejoPomar_1.ControleManejoPomar
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.CONTROLE_ENXERTIA_POMAR,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.CONTROLE_ENXERTIA_POMAR,
                modelo: ControleEnxertiaPomar_1.ControleEnxertiaPomar
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PRODUTIVIDADE_POMAR,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PRODUTIVIDADE_POMAR,
                modelo: ProdutividadePomar_1.ProdutividadePomar
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.FENOLOGIA_POMAR,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.FENOLOGIA_POMAR,
                modelo: FenologiaPomar_1.FenologiaPomar
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.GRAFICO_FENOLOGIA_POMAR,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.GRAFICO_FENOLOGIA_POMAR,
                modelo: GraficoFenologiaPomar_1.GraficoFenologiaPomar
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.POLINIZACAO_POMAR,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.POLINIZACAO_POMAR,
                modelo: PolinizacaoPomar_1.PolinizacaoPomar
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.ESTOQUE_POLEN_POLINIZACAO_POMAR,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.ESTOQUE_POLEN_POLINIZACAO_POMAR,
                modelo: EstoquePolenPolinizacaoPomar_1.EstoquePolenPolinizacaoPomar
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.HISTORICO_ESTOQUE_POLEN_POLINIZACAO_POMAR,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.HISTORICO_ESTOQUE_POLEN_POLINIZACAO_POMAR,
                modelo: HistoricoEstoquePolenPolinizacaoPomar_1.HistoricoEstoquePolenPolinizacaoPomar
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.ESTOQUE_BENEFICIAMENTO_PINUS_POLINIZACAO_POMAR,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.ESTOQUE_BENEFICIAMENTO_PINUS_POLINIZACAO_POMAR,
                modelo: EstoqueBeneficiamentoPinusPolinizacaoPomar_1.EstoqueBeneficiamentoPinusPolinizacaoPomar
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.HISTORICO_ESTOQUE_BENEFICIAMENTO_PINUS_POLINIZACAO_POMAR,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.HISTORICO_ESTOQUE_BENEFICIAMENTO_PINUS_POLINIZACAO_POMAR,
                modelo: HistoricoEstoqueBeneficiamentoPinusPolinizacaoPomar_1.HistoricoEstoqueBeneficiamentoPinusPolinizacaoPomar
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.ESTOQUE_BENEFICIAMENTO_EUCALYPTUS_POLINIZACAO_POMAR,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.ESTOQUE_BENEFICIAMENTO_EUCALYPTUS_POLINIZACAO_POMAR,
                modelo: EstoqueBeneficiamentoEucalyptusPolinizacaoPomar_1.EstoqueBeneficiamentoEucalyptusPolinizacaoPomar
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.HISTORICO_ESTOQUE_BENEFICIAMENTO_EUCALYPTUS_POLINIZACAO_POMAR,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.HISTORICO_ESTOQUE_BENEFICIAMENTO_EUCALYPTUS_POLINIZACAO_POMAR,
                modelo: HistoricoEstoqueBeneficiamentoEucalyptusPolinizacaoPomar_1.HistoricoEstoqueBeneficiamentoEucalyptusPolinizacaoPomar
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.ANEXO_POMAR,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.ANEXO_POMAR,
                modelo: AnexoPomar_1.AnexoPomar
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.TESTE_CAMPO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.TESTE_CAMPO,
                modelo: TesteCampo_1.TesteCampo
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.TRATAMENTO_TESTE_GENETICO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.TRATAMENTO_TESTE_GENETICO,
                modelo: TratamentoTesteGenetico_1.TratamentoTesteGenetico
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.CROQUI_TESTE_GENETICO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.CROQUI_TESTE_GENETICO,
                modelo: CroquiTesteGenetico_1.CroquiTesteGenetico
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.MAPA_TESTE_GENETICO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.MAPA_TESTE_GENETICO,
                modelo: MapaTesteGenetico_1.MapaTesteGenetico
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.CONTROLE_MANEJO_TESTE_GENETICO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.CONTROLE_MANEJO_TESTE_GENETICO,
                modelo: ControleManejoTesteGenetico_1.ControleManejoTesteGenetico
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.MEDICAO_DENDROMETRICA_TESTE_GENETICO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.MEDICAO_DENDROMETRICA_TESTE_GENETICO,
                modelo: MedicaoDendrometricaTesteGenetico_1.MedicaoDendrometricaTesteGenetico
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.LANCAMENTO_MEDICAO_DENDROMETRICA_TESTE_GENETICO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.LANCAMENTO_MEDICAO_DENDROMETRICA_TESTE_GENETICO,
                modelo: LancamentoMedicaoDendrometricaTesteGenetico_1.LancamentoMedicaoDendrometricaTesteGenetico
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PLANEJAMENTO_MEDICAO_DENDROMETRICA_TESTE_GENETICO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PLANEJAMENTO_MEDICAO_DENDROMETRICA_TESTE_GENETICO,
                modelo: PlanejamentoMedicaoDendrometricaTesteGenetico_1.PlanejamentoMedicaoDendrometricaTesteGenetico
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA_MEDICAO_DENDROMETRICA_TESTE_GENETICO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PLANILHA_MEDICAO_DENDROMETRICA_TESTE_GENETICO,
                modelo: PlanilhaMedicaoDendrometricaTesteGenetico_1.PlanilhaMedicaoDendrometricaTesteGenetico
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.QUALIDADE_MADEIRA_TESTE_GENETICO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.QUALIDADE_MADEIRA_TESTE_GENETICO,
                modelo: QualidadeMadeiraTesteGenetico_1.QualidadeMadeiraTesteGenetico
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_SELECAO_TESTE_GENETICO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.ANALISE_SELECAO_TESTE_GENETICO,
                modelo: AnaliseSelecaoTesteGenetico_1.AnaliseSelecaoTesteGenetico
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.SUMARIO_ANALISE_SELECAO_TESTE_GENETICO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.SUMARIO_ANALISE_SELECAO_TESTE_GENETICO,
                modelo: SumarioAnaliseSelecaoTesteGenetico_1.SumarioAnaliseSelecaoTesteGenetico
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PLANILHA_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO,
                modelo: PlanilhaSelegenAnaliseSelecaoTesteGenetico_1.PlanilhaSelegenAnaliseSelecaoTesteGenetico
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.ANALISE_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO,
                modelo: AnaliseSelegenAnaliseSelecaoTesteGenetico_1.AnaliseSelegenAnaliseSelecaoTesteGenetico
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.RESULTADO_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.RESULTADO_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO,
                modelo: ResultadoSelegenAnaliseSelecaoTesteGenetico_1.ResultadoSelegenAnaliseSelecaoTesteGenetico
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_ANO_ANALISE_SELECAO_TESTE_GENETICO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.ANALISE_ANO_ANALISE_SELECAO_TESTE_GENETICO,
                modelo: AnaliseAnoAnaliseSelecaoTesteGenetico_1.AnaliseAnoAnaliseSelecaoTesteGenetico
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_BLUP_ANALISE_SELECAO_TESTE_GENETICO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.ANALISE_BLUP_ANALISE_SELECAO_TESTE_GENETICO,
                modelo: AnaliseBlupAnaliseSelecaoTesteGenetico_1.AnaliseBlupAnaliseSelecaoTesteGenetico
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.SELECAO_GENETICA_ANALISE_SELECAO_TESTE_GENETICO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.SELECAO_GENETICA_ANALISE_SELECAO_TESTE_GENETICO,
                modelo: SelecaoGeneticaAnaliseSelecaoTesteGenetico_1.SelecaoGeneticaAnaliseSelecaoTesteGenetico
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.SELECAO_CAMPO_ANALISE_SELECAO_TESTE_GENETICO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.SELECAO_CAMPO_ANALISE_SELECAO_TESTE_GENETICO,
                modelo: SelecaoCampoAnaliseSelecaoTesteGenetico_1.SelecaoCampoAnaliseSelecaoTesteGenetico
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.ANEXO_ANALISE_SELECAO_TESTE_GENETICO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.ANEXO_ANALISE_SELECAO_TESTE_GENETICO,
                modelo: AnexoAnaliseSelecaoTesteGenetico_1.AnexoAnaliseSelecaoTesteGenetico
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.ANEXO_TESTE_GENETICO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.ANEXO_TESTE_GENETICO,
                modelo: AnexoTesteGenetico_1.AnexoTesteGenetico
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PROTOCOLO_TESTE_GENETICO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PROTOCOLO_TESTE_GENETICO,
                modelo: ProtocoloTesteGenetico_1.ProtocoloTesteGenetico
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PROTOCOLO_TESTE_GENETICO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PROTOCOLO_TESTE_GENETICO,
                modelo: ProtocoloTesteGenetico_1.ProtocoloTesteGenetico
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PROTOCOLO_TESTE_GENETICO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PROTOCOLO_TESTE_GENETICO,
                modelo: ProtocoloTesteGenetico_1.ProtocoloTesteGenetico
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.USUARIO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.USUARIO,
                modelo: Usuario_1.Usuario
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.PARAMETRIZACAO,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.PARAMETRIZACAO,
                modelo: ConfiguracaoGeral_1.ConfiguracaoGeral
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.FILE,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.FILE,
                modelo: Ged_1.Ged
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.POSICAO_COLUNA_TABELA,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.POSICAO_COLUNA_TABELA,
                modelo: PosicaoColunaTabela_1.PosicaoColunaTabela
            }, {
                rota: RotaAPIConstantes_1.RotaAPIConstantes.VISIBILIDADE_COLUNA_TABELA,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.VISIBILIDADE_COLUNA_TABELA,
                modelo: VisibilidadeColunaTabela_1.VisibilidadeColunaTabela
            }];
        this.itensParaEnviarPrimeiro = [{
                rota: RotaAPIConstantes_1.RotaAPIConstantes.LOTE,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.LOTE,
                modelo: Lote_1.Lote
            }];
        this.itensParaEnviarPorUltimo = [{
                rota: RotaAPIConstantes_1.RotaAPIConstantes.FILE,
                etiqueta: SincroniaConstantes_1.SincroniaConstantes.FILE,
                modelo: Ged_1.Ged
            }];
    }
}
exports.SincroniaCustomizada = SincroniaCustomizada;
