"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProducaoMudasExperimentos = void 0;
const Base_1 = require("./Base");
class ProducaoMudasExperimentos extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.data = null;
        this.nomeExperimento = null;
        this.material = null;
        this.tipoDelineamento = null;
        this.detalhes = [];
        this.observacoes = null;
        this.anexos = [];
    }
}
exports.ProducaoMudasExperimentos = ProducaoMudasExperimentos;
