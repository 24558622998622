"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OperacaoColetaAmostraPomar = void 0;
const Base_1 = require("./Base");
const uuid_1 = require("uuid");
class OperacaoColetaAmostraPomar extends Base_1.Base {
    constructor() {
        super();
        this.id = null;
        this.guid = null;
        this.materialGenetico = null;
        this.tipoColetaAmostra = null;
        this.outroTipoColetaAmostra = null;
        this.finalidadeColetaAmostra = null;
        this.outraFinalidadeColetaAmostra = null;
        this.numClones = null;
        this.quantidadeAmostras = null;
        this.guid = uuid_1.v4();
        this.status = true;
    }
}
exports.OperacaoColetaAmostraPomar = OperacaoColetaAmostraPomar;
