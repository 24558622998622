"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BotoesOperacaoComponente = void 0;
const BotoesOperacaoController_1 = require("./BotoesOperacaoController");
class BotoesOperacaoComponente {
    constructor() {
        this.controller = BotoesOperacaoController_1.BotoesOperacaoController;
        this.templateUrl = require('./botoes-operacao.componente.html').default;
        this.transclude = true;
        this.bindings = {
            modelo: '=',
            desabilitar: '=',
            importar: '=',
            links: '=? '
        };
    }
}
exports.BotoesOperacaoComponente = BotoesOperacaoComponente;
BotoesOperacaoComponente.id = 'botoesOperacao';
