"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Cultivar = void 0;
const Base_1 = require("./Base");
class Cultivar extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.especie = null;
        this.cultivar = null;
        this.numRegistro = null;
    }
}
exports.Cultivar = Cultivar;
