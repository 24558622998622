"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GrupoPermissoesUsuario = void 0;
const angular = require("angular");
const UsuarioPermissao_1 = require("../modelos/UsuarioPermissao");
class GrupoPermissoesUsuario {
    constructor() {
        this.tituloGrupo = null;
        this.tituloGrupoFormatado = null;
        this.iconeGrupo = null;
        this.permissaoGrupo = null;
        this.subGruposPermissoes = null;
        this.todosSubGruposPermissoes = null;
    }
    static extrairPermissaoModuloRota(rota) {
        if ((typeof rota !== 'undefined') && rota) {
            let modulo = rota;
            while ((modulo.length > 0) && (modulo.indexOf('.') !== -1)) {
                modulo = modulo.substring(modulo.indexOf('.') + 1);
            }
            if ((modulo.length > 0) && (modulo.indexOf('-') !== -1)) {
                modulo = modulo.substring(0, modulo.indexOf('-'));
            }
            modulo = String(modulo.substring(0, 1).toUpperCase() + modulo.substring(1)).trim();
            const lcModulo = modulo.toLowerCase();
            const ehRotaCadastro = (rota.indexOf('cadastro') !== -1) || (rota.indexOf('Cadastro') !== -1);
            const ehModuloProducaoMuda = (lcModulo.indexOf('producaomuda') === 0);
            const ehModuloProtecaoRegistroCultivar = (lcModulo.indexOf('protecaoregistrocultivar') === 0);
            const ehModuloLaboratorio = (lcModulo.indexOf('lab') === 0) && (lcModulo !== 'laboratorio');
            if (ehRotaCadastro && !ehModuloProducaoMuda && !ehModuloProtecaoRegistroCultivar && !ehModuloLaboratorio) {
                modulo = `Cadastro${modulo}`;
            }
            if (ehRotaCadastro && ehModuloLaboratorio && (lcModulo.indexOf('laboratorio') === -1)) {
                modulo = modulo.replace('lab', 'laboratorio').replace('Lab', 'Laboratorio');
            }
            return `permissao${modulo}`;
        }
        return '';
    }
    static marcarTodos(grupoPermissoesUsuario) {
        if ((typeof grupoPermissoesUsuario === 'undefined') || !grupoPermissoesUsuario) {
            return;
        }
        if ((typeof grupoPermissoesUsuario.subGruposPermissoes === 'undefined') || !grupoPermissoesUsuario.subGruposPermissoes) {
            return;
        }
        angular.forEach(grupoPermissoesUsuario.subGruposPermissoes, (subGrupoPermissoesN2) => {
            if ((typeof subGrupoPermissoesN2.rota !== 'undefined') && subGrupoPermissoesN2.rota &&
                ((typeof subGrupoPermissoesN2.permissoesHabilitadas !== 'undefined') && subGrupoPermissoesN2.permissoesHabilitadas)) {
                if ((typeof subGrupoPermissoesN2.permissoesUsuario === 'undefined') || !subGrupoPermissoesN2.permissoesUsuario) {
                    subGrupoPermissoesN2.permissoesUsuario = new UsuarioPermissao_1.UsuarioPermissao();
                }
                if ((typeof subGrupoPermissoesN2.permissoesHabilitadas !== 'undefined') && subGrupoPermissoesN2.permissoesHabilitadas) {
                    angular.forEach(Object.keys(subGrupoPermissoesN2.permissoesHabilitadas), (permissao) => {
                        subGrupoPermissoesN2.permissoesUsuario[permissao] = true;
                    });
                }
            }
            if ((typeof subGrupoPermissoesN2.subGruposPermissoes !== 'undefined') && subGrupoPermissoesN2.subGruposPermissoes) {
                angular.forEach(subGrupoPermissoesN2.subGruposPermissoes, (subGrupoPermissoesN3) => {
                    if ((typeof subGrupoPermissoesN3.rota !== 'undefined') && subGrupoPermissoesN3.rota &&
                        ((typeof subGrupoPermissoesN3.permissoesHabilitadas !== 'undefined') && subGrupoPermissoesN3.permissoesHabilitadas)) {
                        if ((typeof subGrupoPermissoesN3.permissoesUsuario === 'undefined') || !subGrupoPermissoesN3.permissoesUsuario) {
                            subGrupoPermissoesN3.permissoesUsuario = new UsuarioPermissao_1.UsuarioPermissao();
                        }
                        if ((typeof subGrupoPermissoesN3.permissoesHabilitadas !== 'undefined') && subGrupoPermissoesN3.permissoesHabilitadas) {
                            angular.forEach(Object.keys(subGrupoPermissoesN3.permissoesHabilitadas), (permissao) => {
                                subGrupoPermissoesN3.permissoesUsuario[permissao] = true;
                            });
                        }
                    }
                    if ((typeof subGrupoPermissoesN3.subGruposPermissoes !== 'undefined') && subGrupoPermissoesN3.subGruposPermissoes) {
                        angular.forEach(subGrupoPermissoesN3.subGruposPermissoes, (subGrupoPermissoesN4) => {
                            if ((typeof subGrupoPermissoesN4.rota !== 'undefined') && subGrupoPermissoesN4.rota &&
                                ((typeof subGrupoPermissoesN4.permissoesHabilitadas !== 'undefined') && subGrupoPermissoesN4.permissoesHabilitadas)) {
                                if ((typeof subGrupoPermissoesN4.permissoesUsuario === 'undefined') || !subGrupoPermissoesN4.permissoesUsuario) {
                                    subGrupoPermissoesN4.permissoesUsuario = new UsuarioPermissao_1.UsuarioPermissao();
                                }
                                if ((typeof subGrupoPermissoesN4.permissoesHabilitadas !== 'undefined') && subGrupoPermissoesN4.permissoesHabilitadas) {
                                    angular.forEach(Object.keys(subGrupoPermissoesN4.permissoesHabilitadas), (permissao) => {
                                        subGrupoPermissoesN4.permissoesUsuario[permissao] = true;
                                    });
                                }
                            }
                            if ((typeof subGrupoPermissoesN4.subGruposPermissoes !== 'undefined') && subGrupoPermissoesN4.subGruposPermissoes) {
                                angular.forEach(subGrupoPermissoesN4.subGruposPermissoes, (subGrupoPermissoesN5) => {
                                    if ((typeof subGrupoPermissoesN5.rota !== 'undefined') && subGrupoPermissoesN5.rota &&
                                        ((typeof subGrupoPermissoesN5.permissoesHabilitadas !== 'undefined') && subGrupoPermissoesN5.permissoesHabilitadas)) {
                                        if ((typeof subGrupoPermissoesN5.permissoesUsuario === 'undefined') || !subGrupoPermissoesN5.permissoesUsuario) {
                                            subGrupoPermissoesN5.permissoesUsuario = new UsuarioPermissao_1.UsuarioPermissao();
                                        }
                                        if ((typeof subGrupoPermissoesN5.permissoesHabilitadas !== 'undefined') && subGrupoPermissoesN5.permissoesHabilitadas) {
                                            angular.forEach(Object.keys(subGrupoPermissoesN5.permissoesHabilitadas), (permissao) => {
                                                subGrupoPermissoesN5.permissoesUsuario[permissao] = true;
                                            });
                                        }
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });
    }
    static desmarcarTodos(grupoPermissoesUsuario) {
        if ((typeof grupoPermissoesUsuario === 'undefined') || !grupoPermissoesUsuario) {
            return;
        }
        if ((typeof grupoPermissoesUsuario.subGruposPermissoes === 'undefined') || !grupoPermissoesUsuario.subGruposPermissoes) {
            return;
        }
        angular.forEach(grupoPermissoesUsuario.subGruposPermissoes, (subGrupoPermissoesN2) => {
            if ((typeof subGrupoPermissoesN2.rota !== 'undefined') && subGrupoPermissoesN2.rota &&
                ((typeof subGrupoPermissoesN2.permissoesHabilitadas !== 'undefined') && subGrupoPermissoesN2.permissoesHabilitadas)) {
                if ((typeof subGrupoPermissoesN2.permissoesUsuario === 'undefined') || !subGrupoPermissoesN2.permissoesUsuario) {
                    subGrupoPermissoesN2.permissoesUsuario = new UsuarioPermissao_1.UsuarioPermissao();
                }
                if ((typeof subGrupoPermissoesN2.permissoesHabilitadas !== 'undefined') && subGrupoPermissoesN2.permissoesHabilitadas) {
                    angular.forEach(Object.keys(subGrupoPermissoesN2.permissoesHabilitadas), (permissao) => {
                        subGrupoPermissoesN2.permissoesUsuario[permissao] = false;
                    });
                }
            }
            if ((typeof subGrupoPermissoesN2.subGruposPermissoes !== 'undefined') && subGrupoPermissoesN2.subGruposPermissoes) {
                angular.forEach(subGrupoPermissoesN2.subGruposPermissoes, (subGrupoPermissoesN3) => {
                    if ((typeof subGrupoPermissoesN3.rota !== 'undefined') && subGrupoPermissoesN3.rota &&
                        ((typeof subGrupoPermissoesN3.permissoesHabilitadas !== 'undefined') && subGrupoPermissoesN3.permissoesHabilitadas)) {
                        if ((typeof subGrupoPermissoesN3.permissoesUsuario === 'undefined') || !subGrupoPermissoesN3.permissoesUsuario) {
                            subGrupoPermissoesN3.permissoesUsuario = new UsuarioPermissao_1.UsuarioPermissao();
                        }
                        if ((typeof subGrupoPermissoesN3.permissoesHabilitadas !== 'undefined') && subGrupoPermissoesN3.permissoesHabilitadas) {
                            angular.forEach(Object.keys(subGrupoPermissoesN3.permissoesHabilitadas), (permissao) => {
                                subGrupoPermissoesN3.permissoesUsuario[permissao] = false;
                            });
                        }
                    }
                    if ((typeof subGrupoPermissoesN3.subGruposPermissoes !== 'undefined') && subGrupoPermissoesN3.subGruposPermissoes) {
                        angular.forEach(subGrupoPermissoesN3.subGruposPermissoes, (subGrupoPermissoesN4) => {
                            if ((typeof subGrupoPermissoesN4.rota !== 'undefined') && subGrupoPermissoesN4.rota &&
                                ((typeof subGrupoPermissoesN4.permissoesHabilitadas !== 'undefined') && subGrupoPermissoesN4.permissoesHabilitadas)) {
                                if ((typeof subGrupoPermissoesN4.permissoesUsuario === 'undefined') || !subGrupoPermissoesN4.permissoesUsuario) {
                                    subGrupoPermissoesN4.permissoesUsuario = new UsuarioPermissao_1.UsuarioPermissao();
                                }
                                if ((typeof subGrupoPermissoesN4.permissoesHabilitadas !== 'undefined') && subGrupoPermissoesN4.permissoesHabilitadas) {
                                    angular.forEach(Object.keys(subGrupoPermissoesN4.permissoesHabilitadas), (permissao) => {
                                        subGrupoPermissoesN4.permissoesUsuario[permissao] = false;
                                    });
                                }
                            }
                            if ((typeof subGrupoPermissoesN4.subGruposPermissoes !== 'undefined') && subGrupoPermissoesN4.subGruposPermissoes) {
                                angular.forEach(subGrupoPermissoesN4.subGruposPermissoes, (subGrupoPermissoesN5) => {
                                    if ((typeof subGrupoPermissoesN5.rota !== 'undefined') && subGrupoPermissoesN5.rota &&
                                        ((typeof subGrupoPermissoesN5.permissoesHabilitadas !== 'undefined') && subGrupoPermissoesN5.permissoesHabilitadas)) {
                                        if ((typeof subGrupoPermissoesN5.permissoesUsuario === 'undefined') || !subGrupoPermissoesN5.permissoesUsuario) {
                                            subGrupoPermissoesN5.permissoesUsuario = new UsuarioPermissao_1.UsuarioPermissao();
                                        }
                                        if ((typeof subGrupoPermissoesN5.permissoesHabilitadas !== 'undefined') && subGrupoPermissoesN5.permissoesHabilitadas) {
                                            angular.forEach(Object.keys(subGrupoPermissoesN5.permissoesHabilitadas), (permissao) => {
                                                subGrupoPermissoesN5.permissoesUsuario[permissao] = false;
                                            });
                                        }
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });
    }
}
exports.GrupoPermissoesUsuario = GrupoPermissoesUsuario;
