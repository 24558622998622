"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pomar = void 0;
const Base_1 = require("./Base");
class Pomar extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.id = null;
        this.codigo = null;
        this.descricao = null;
        this.regiao = null;
        this.regiaoId = null;
        this.regiaoSgifId = null;
        this.regiaoNum = null;
        this.regiaoNome = null;
        this.talhao = null;
        this.talhaoId = null;
        this.talhaoSgifId = null;
        this.talhaoNumero = null;
        this.especie = null;
        this.especieId = null;
        this.especieCodigo = null;
        this.especieDescricao = null;
        this.genero = null;
        this.generoId = null;
        this.generoCodigo = null;
        this.generoDescricao = null;
        this.tipo = null;
        this.tipoId = null;
        this.tipoCodigo = null;
        this.tipoSigla = null;
        this.tipoTitulo = null;
        this.tipoDescricao = null;
        this.geracao = null;
        this.geracaoId = null;
        this.geracaoCodigo = null;
        this.geracaoDescricao = null;
        this.pais = null;
        this.paisId = null;
        this.paisCodigo = null;
        this.paisDescricao = null;
        this.provinciaUf = null;
        this.provinciaUfId = null;
        this.provinciaUfCodigo = null;
        this.provinciaUfSigla = null;
        this.provinciaUfDescricao = null;
        this.tipoImplantacao = null;
        this.tipoImplantacaoId = null;
        this.tipoImplantacaoCodigo = null;
        this.tipoImplantacaoDescricao = null;
        this.fonte = null;
        this.fonteId = null;
        this.fonteCodigo = null;
        this.fonteDescricao = null;
        this.situacao = null;
        this.situacaoId = null;
        this.situacaoCodigo = null;
        this.situacaoDescricao = null;
        this.data = null;
        this.idade = null;
        this.ano = null;
        this.area = null;
        this.tratamentos = null;
        this.individuos = null;
        this.municipio = null;
        this.tipoPomarPopulacao = null;
        this.tipoPomarPopulacaoCodigo = null;
        this.tipoPomarPopulacaoDescricao = null;
        this.cruzamento = null;
        this.conjunto = null;
        this.inativo = false;
        this.materialGenetico = null;
        this.materialGeneticoId = null;
        this.materialGeneticoDescricao = null;
        this.descricaoDetalhada = null;
        this.origemNivel1 = null;
        this.origemNivel2 = null;
        this.local = null;
        this.procedencia = null;
        this.instituicao = null;
        this.nomeCientifico = null;
        this.nomeComum = null;
        this.nomeCultivar = null;
        this.tipoNaturalPlantada = null;
        this.numCultivar = null;
        this.criteriosSelecao = null;
        this.intensidadeSelecao = null;
        this.tipoIsolamento = null;
        this.numMesesColeta = null;
        this.categoria = null;
        this.categoriaId = null;
        this.categoriaCodigo = null;
        this.categoriaDescricao = null;
        this.perfilSoloTexturaHorizonteA = null;
        this.perfilSoloTexturaHorizonteB = null;
        this.perfilSoloProfundidadeHorizonteA = null;
        this.perfilSoloProfundidadeHorizonteB = null;
        this.perfilSoloProfundidadeEfetivaHorizonteA = null;
        this.perfilSoloProfundidadeEfetivaHorizonteB = null;
        this.topografiaDrenagem = null;
        this.topografiaDeclividade = null;
        this.topografiaFace = null;
        this.classificacaoSolo = null;
        this.classificacaoSoloId = null;
        this.classificacaoSoloCodigo = null;
        this.classificacaoSoloSigla = null;
        this.classificacaoSoloDescricao = null;
        this.unidadeManejo = null;
        this.unidadeManejoId = null;
        this.unidadeManejoCodigo = null;
        this.unidadeManejoSigla = null;
        this.unidadeManejoDescricao = null;
        this.classificacaoIndiceSitio = null;
        this.preparoSoloEquipamento = null;
        this.preparoSoloLimpezaResiduos = null;
        this.preparoSoloPreparoLinhas = null;
        this.preparoSoloProfundidadeCorte = null;
        this.preparoSoloHerbicida = null;
        this.preparoSoloCulturaAnterior = null;
        this.adubacaoBaseFertilizante = null;
        this.adubacaoBaseContaDepositoSap = null;
        this.adubacaoBaseCentroCustoSap = null;
        this.adubacaoBaseFertilizanteId = null;
        this.adubacaoBaseFertilizanteCodigo = null;
        this.adubacaoBaseFertilizanteDescricao = null;
        this.adubacaoBaseFertilizanteUnidadeMedidaId = null;
        this.adubacaoBaseFertilizanteUnidadeMedidaCodigo = null;
        this.adubacaoBaseFertilizanteUnidadeMedidaDescricao = null;
        this.adubacaoBaseQuantidadeFertilizante = null;
        this.adubacaoCoberturaFertilizante = null;
        this.adubacaoCoberturaContaDepositoSap = null;
        this.adubacaoCoberturaCentroCustoSap = null;
        this.adubacaoCoberturaFertilizanteId = null;
        this.adubacaoCoberturaFertilizanteCodigo = null;
        this.adubacaoCoberturaFertilizanteDescricao = null;
        this.adubacaoCoberturaFertilizanteUnidadeMedidaId = null;
        this.adubacaoCoberturaFertilizanteUnidadeMedidaCodigo = null;
        this.adubacaoCoberturaFertilizanteUnidadeMedidaDescricao = null;
        this.adubacaoCoberturaQuantidadeFertilizante = null;
        this.producaoPortaEnxertosPlantio = null;
        this.producaoPortaEnxertosTipoMudas = null;
        this.producaoPortaEnxertosIdadeMudas = null;
        this.producaoPortaEnxertosFertilizante = null;
        this.producaoPortaEnxertosQuantFertilizante = null;
        this.implantacaoPomarEnxertia = null;
        this.implantacaoPomarEspacamento = null;
        this.implantacaoPomarDensidadeArea = null;
        this.implantacaoPomarFertilizante = null;
        this.controleCompeticaoErvasDaninhas = null;
        this.controleCompeticaoFormiga = null;
        this.controleCompeticaoPulgao = null;
        this.controleCompeticaoVespaMadeira = null;
        this.controleCompeticaoOutros = null;
        this.tipoDelineamento = null;
        this.tipoDelineamentoId = null;
        this.tipoDelineamentoCodigo = null;
        this.tipoDelineamentoDescricao = null;
        this.disposicaoIndividuos = null;
        this.disposicaoIndividuosId = null;
        this.disposicaoIndividuosCodigo = null;
        this.disposicaoIndividuosDescricao = null;
        this.delineamentoTratamentos = null;
        this.delineamentoArvoresPorTratamento = null;
        this.delineamentoTotalArvores = null;
        this.delineamentoTratamentosAtual = null;
        this.delineamentoTotalArvoresAtual = null;
        this.delineamentoTotalArea = null;
        this.delineamentoDensidade = null;
        this.distanciaSede = null;
        this.coordenadasGeograficas = null;
        this.coordenadasGeograficas2 = null;
        this.coordenadasUTM = null;
        this.coordenadasUTM2 = null;
        this.classificacaoClimatica = null;
        this.classificacaoClimaticaId = null;
        this.classificacaoClimaticaCodigo = null;
        this.classificacaoClimaticaDescricao = null;
        this.latitude = null;
        this.longitude = null;
        this.altitude = null;
        this.perfilClimaticoAnual = null;
        this.observacoes = null;
    }
}
exports.Pomar = Pomar;
