"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PomarController = void 0;
const angular = require("angular");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const UiGridConstrutor_1 = require("../../../app/construtores/UiGridConstrutor");
const AbstratoController_1 = require("../../../app/AbstratoController");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const ICRUDOperacao_1 = require("../../../app/interfaces/ICRUDOperacao");
const Pomar_1 = require("../../../../../modelos/Pomar");
const Regiao_1 = require("../../../../../modelos/Regiao");
const config_app_1 = require("../../../../../config/config.app");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const PerfilClimaticoPomar_1 = require("../../../../../modelos/PerfilClimaticoPomar");
const TipoRelatorioPomar_1 = require("../../../../../modelos/TipoRelatorioPomar");
const TextoUtil_1 = require("../../../../../util/TextoUtil");
const NumeroUtil_1 = require("../../../../../util/NumeroUtil");
const RevTabelaUtil_1 = require("../../../../../util/RevTabelaUtil");
const RelatorioPomar_1 = require("../../../../../modelos/RelatorioPomar");
const RelatorioPomaresSementesBancosConservacao_1 = require("../../../../../modelos/RelatorioPomaresSementesBancosConservacao");
const Pais_1 = require("../../../../../modelos/Pais");
const DataUtil_1 = require("../../../../../util/DataUtil");
const sweetalert2_1 = require("sweetalert2");
class PomarController extends AbstratoController_1.AbstratoController {
    constructor($scope, $injector) {
        super($scope, $injector, new Pomar_1.Pomar());
        this.path = '';
        this.caminho = '';
        this.tipoOperacao = null;
        this.pomarSelecionado = null;
        this.pomarCarregado = false;
        this.generos = [];
        this.especies = [];
        this.tipos = [];
        this.regioes = [];
        this.talhoes = [];
        this.talhoesFiltrados = [];
        this.situacoes = [];
        this.geracoes = [];
        this.provinciasUf = [];
        this.fontes = [];
        this.paises = [];
        this.tiposImplantacoes = [];
        this.tiposPomaresPopulacoes = [];
        this.modalTratamentos = false;
        this.modalRelatorioPomar = false;
        this.modalRelatorioBancoConservacao = false;
        this.posicaoTopoAntesModal = null;
        this.listaOpcoesTratamentos = null;
        this.relatorioPomarSelecionado = null;
        this.listaPomares = [];
        this.timeoutFiltroTalhoes = null;
        this.tipoOperacao = ICRUDOperacao_1.CRUDOperacao;
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.path = config_app_1.config.api.caminho;
            this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.POMAR;
            this.generos = new Array();
            const promiseGeneros = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.GENERO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.GENERO)
                .filtro({})
                .listar();
            promiseGeneros.then((resposta) => {
                ctrl.generos.splice(0, ctrl.generos.length);
                ctrl.generos.push(...resposta);
                console.log('Gêneros carregados: ' + ctrl.generos.length);
                ctrl.scopeApply();
            });
            this.especies = new Array();
            const promiseEspecies = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESPECIE)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESPECIE)
                .filtro({})
                .listar();
            promiseEspecies.then((resposta) => {
                ctrl.especies.splice(0, ctrl.especies.length);
                ctrl.especies.push(...resposta);
                console.log('Espécies carregadas: ' + ctrl.especies.length);
                ctrl.scopeApply();
            });
            this.tipos = new Array();
            const promiseTipos = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.TIPO_POMAR)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TIPO_POMAR)
                .filtro({})
                .listar();
            promiseTipos.then((resposta) => {
                ctrl.tipos.splice(0, ctrl.tipos.length);
                ctrl.tipos.push(...resposta);
                console.log('Tipos Pomares carregados: ' + ctrl.tipos.length);
                ctrl.scopeApply();
            });
            this.situacoes = new Array();
            const promiseSituacoesPomares = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.SITUACAO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.SITUACAO)
                .filtro({})
                .listar();
            promiseSituacoesPomares.then((resposta) => {
                ctrl.situacoes.splice(0, ctrl.situacoes.length);
                ctrl.situacoes.push(...resposta);
                console.log('Situações Pomares carregados: ' + ctrl.situacoes.length);
                ctrl.scopeApply();
            });
            this.geracoes = new Array();
            const promiseGeracoes = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.GERACAO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.GERACAO)
                .filtro({})
                .listar();
            promiseGeracoes.then((resposta) => {
                ctrl.geracoes.splice(0, ctrl.geracoes.length);
                if ((typeof resposta !== 'undefined') && resposta) {
                    angular.forEach(resposta, (geracao) => {
                        if ((typeof geracao.codigo !== 'undefined') && (geracao.codigo !== null)) {
                            let valor = '' + geracao.codigo;
                            geracao.codigo = valor.replace('.', ',');
                        }
                    });
                    ctrl.geracoes.push(...resposta);
                }
                console.log('Gerações carregadas: ' + ctrl.geracoes.length);
                ctrl.scopeApply();
            });
            if (this.operacao !== ICRUDOperacao_1.CRUDOperacao.LISTAR) {
                this.provinciasUf = new Array();
                const promiseProvinciasUf = this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROVINCIA_UF)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROVINCIA_UF)
                    .filtro({})
                    .listar();
                promiseProvinciasUf.then((resposta) => {
                    ctrl.provinciasUf.splice(0, ctrl.provinciasUf.length);
                    ctrl.provinciasUf.push(...resposta);
                    console.log('Províncias (UF) carregadas: ' + ctrl.provinciasUf.length);
                    ctrl.scopeApply();
                });
                this.regioes = new Array();
                const promiseRegioes = this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.REGIAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.REGIAO)
                    .filtro({})
                    .listar();
                promiseRegioes.then((resposta) => {
                    ctrl.regioes.splice(0, this.regioes.length);
                    ctrl.regioes.push(...resposta);
                    console.log('Regiões carregadas: ' + ctrl.regioes.length);
                    ctrl.scopeApply();
                });
                this.talhoes = new Array();
                this.talhoesFiltrados = new Array();
                const promiseTalhoes = this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TALHAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TALHAO)
                    .filtro({})
                    .listar();
                promiseTalhoes.then((resposta) => {
                    ctrl.talhoes.splice(0, ctrl.talhoes.length);
                    ctrl.talhoes.push(...resposta);
                    if ((typeof ctrl.talhoes !== 'undefined') && (ctrl.talhoes !== null) && (ctrl.talhoes.length > 0)) {
                        for (let i = 0; i <= ctrl.talhoes.length - 1; i++) {
                            if ((typeof ctrl.talhoes[i].regiao === 'undefined') || (ctrl.talhoes[i].regiao === null)) {
                                ctrl.talhoes[i].regiao = new Regiao_1.Regiao();
                                ctrl.talhoes[i].regiao.sgif_id = ctrl.talhoes[i].regiaoSgifId;
                                ctrl.talhoes[i].regiao.nome = ctrl.talhoes[i].regiaoNome;
                            }
                            else if ((typeof ctrl.talhoes[i].regiao.nome === 'undefined') || (ctrl.talhoes[i].regiao.nome === null) || (ctrl.talhoes[i].regiao.nome.trim().length <= 0)) {
                                ctrl.talhoes[i].regiao.sgif_id = ctrl.talhoes[i].regiaoSgifId;
                                ctrl.talhoes[i].regiao.nome = ctrl.talhoes[i].regiaoNome;
                            }
                        }
                    }
                    console.log('Talhões carregados: ' + ctrl.talhoes.length);
                    ctrl.scopeApply();
                });
                this.fontes = new Array();
                const promiseFontes = this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.FONTE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.FONTE)
                    .filtro({})
                    .listar();
                promiseFontes.then((resposta) => {
                    this.fontes.splice(0, this.fontes.length);
                    this.fontes.push(...resposta);
                    console.log('Fontes carregadas: ' + this.fontes.length);
                    this.scopeApply();
                });
                this.paises = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PAIS)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PAIS)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.paises = resposta;
                    }
                });
                this.tiposImplantacoes = new Array();
                const promiseTiposImplantacoes = this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TIPO_IMPLANTACAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TIPO_IMPLANTACAO)
                    .filtro({})
                    .listar();
                promiseTiposImplantacoes.then((resposta) => {
                    this.tiposImplantacoes.splice(0, this.tiposImplantacoes.length);
                    this.tiposImplantacoes.push(...resposta);
                    console.log('Tipos de Implantação carregados: ' + this.tiposImplantacoes.length);
                    this.scopeApply();
                });
                this.tiposPomaresPopulacoes = new Array();
                const promiseTiposPomaresPopulacoes = this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TIPO_POMAR_POPULACAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TIPO_POMAR_POPULACAO)
                    .filtro({})
                    .listar();
                promiseTiposPomaresPopulacoes.then((resposta) => {
                    this.tiposPomaresPopulacoes.splice(0, this.tiposPomaresPopulacoes.length);
                    this.tiposPomaresPopulacoes.push(...resposta);
                    console.log('Tipos de Pomares/Populações carregados: ' + this.tiposPomaresPopulacoes.length);
                    this.scopeApply();
                });
            }
            RevTabelaUtil_1.RevTabelaUtil.adicionarEventoMonitoramentoAtualizacoesTabelas(this, this.atualizarDadosTabela);
            this.$scope.$apply();
        });
    }
    atualizarDadosTabela(nomeTabela) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof nomeTabela !== 'undefined') && nomeTabela) {
                const refNomeTabela = TextoUtil_1.TextoUtil.converteTextoParaReferencia(nomeTabela);
                const ctrl = this;
                if (refNomeTabela === TextoUtil_1.TextoUtil.converteTextoParaReferencia('Genero')) {
                    this.generos = new Array();
                    const promiseGeneros = this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.GENERO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.GENERO)
                        .filtro({})
                        .listar();
                    promiseGeneros.then((resposta) => {
                        ctrl.generos.splice(0, ctrl.generos.length);
                        ctrl.generos.push(...resposta);
                        console.log('Gêneros carregados: ' + ctrl.generos.length);
                        ctrl.scopeApply();
                    });
                }
                if (refNomeTabela === TextoUtil_1.TextoUtil.converteTextoParaReferencia('Especie')) {
                    this.especies = new Array();
                    const promiseEspecies = this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESPECIE)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESPECIE)
                        .filtro({})
                        .listar();
                    promiseEspecies.then((resposta) => {
                        ctrl.especies.splice(0, ctrl.especies.length);
                        ctrl.especies.push(...resposta);
                        console.log('Espécies carregadas: ' + ctrl.especies.length);
                        ctrl.scopeApply();
                    });
                }
                if (refNomeTabela === TextoUtil_1.TextoUtil.converteTextoParaReferencia('TipoPomar')) {
                    this.tipos = new Array();
                    const promiseTipos = this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.TIPO_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TIPO_POMAR)
                        .filtro({})
                        .listar();
                    promiseTipos.then((resposta) => {
                        ctrl.tipos.splice(0, ctrl.tipos.length);
                        ctrl.tipos.push(...resposta);
                        console.log('Tipos Pomares carregados: ' + ctrl.tipos.length);
                        ctrl.scopeApply();
                    });
                }
                if (refNomeTabela === TextoUtil_1.TextoUtil.converteTextoParaReferencia('Situacao')) {
                    this.situacoes = new Array();
                    const promiseSituacoesPomares = this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.SITUACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.SITUACAO)
                        .filtro({})
                        .listar();
                    promiseSituacoesPomares.then((resposta) => {
                        ctrl.situacoes.splice(0, ctrl.situacoes.length);
                        ctrl.situacoes.push(...resposta);
                        console.log('Situações Pomares carregados: ' + ctrl.situacoes.length);
                        ctrl.scopeApply();
                    });
                }
                if (refNomeTabela === TextoUtil_1.TextoUtil.converteTextoParaReferencia('Geracao')) {
                    this.geracoes = new Array();
                    const promiseGeracoes = this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.GERACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.GERACAO)
                        .filtro({})
                        .listar();
                    promiseGeracoes.then((resposta) => {
                        ctrl.geracoes.splice(0, ctrl.geracoes.length);
                        if ((typeof resposta !== 'undefined') && resposta) {
                            angular.forEach(resposta, (geracao) => {
                                if ((typeof geracao.codigo !== 'undefined') && (geracao.codigo !== null)) {
                                    let valor = '' + geracao.codigo;
                                    geracao.codigo = valor.replace('.', ',');
                                }
                            });
                            ctrl.geracoes.push(...resposta);
                        }
                        console.log('Gerações carregadas: ' + ctrl.geracoes.length);
                        ctrl.scopeApply();
                    });
                }
                if (this.operacao !== ICRUDOperacao_1.CRUDOperacao.LISTAR) {
                    if (refNomeTabela === TextoUtil_1.TextoUtil.converteTextoParaReferencia('ProvinciaUf')) {
                        this.provinciasUf = new Array();
                        const promiseProvinciasUf = this.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROVINCIA_UF)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROVINCIA_UF)
                            .filtro({})
                            .listar();
                        promiseProvinciasUf.then((resposta) => {
                            ctrl.provinciasUf.splice(0, ctrl.provinciasUf.length);
                            ctrl.provinciasUf.push(...resposta);
                            console.log('Províncias (UF) carregadas: ' + ctrl.provinciasUf.length);
                            ctrl.scopeApply();
                        });
                    }
                    if (refNomeTabela === TextoUtil_1.TextoUtil.converteTextoParaReferencia('Regiao')) {
                        this.regioes = new Array();
                        const promiseRegioes = this.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.REGIAO)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.REGIAO)
                            .filtro({})
                            .listar();
                        promiseRegioes.then((resposta) => {
                            ctrl.regioes.splice(0, this.regioes.length);
                            ctrl.regioes.push(...resposta);
                            console.log('Regiões carregadas: ' + ctrl.regioes.length);
                            ctrl.scopeApply();
                        });
                    }
                    if (refNomeTabela === TextoUtil_1.TextoUtil.converteTextoParaReferencia('Talhao')) {
                        this.talhoes = new Array();
                        this.talhoesFiltrados = new Array();
                        const promiseTalhoes = this.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.TALHAO)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TALHAO)
                            .filtro({})
                            .listar();
                        promiseTalhoes.then((resposta) => {
                            ctrl.talhoes.splice(0, ctrl.talhoes.length);
                            ctrl.talhoes.push(...resposta);
                            if ((typeof ctrl.talhoes !== 'undefined') && (ctrl.talhoes !== null) && (ctrl.talhoes.length > 0)) {
                                for (let i = 0; i <= ctrl.talhoes.length - 1; i++) {
                                    if ((typeof ctrl.talhoes[i].regiao === 'undefined') || (ctrl.talhoes[i].regiao === null)) {
                                        ctrl.talhoes[i].regiao = new Regiao_1.Regiao();
                                        ctrl.talhoes[i].regiao.sgif_id = ctrl.talhoes[i].regiaoSgifId;
                                        ctrl.talhoes[i].regiao.nome = ctrl.talhoes[i].regiaoNome;
                                    }
                                    else if ((typeof ctrl.talhoes[i].regiao.nome === 'undefined') || (ctrl.talhoes[i].regiao.nome === null) || (ctrl.talhoes[i].regiao.nome.trim().length <= 0)) {
                                        ctrl.talhoes[i].regiao.sgif_id = ctrl.talhoes[i].regiaoSgifId;
                                        ctrl.talhoes[i].regiao.nome = ctrl.talhoes[i].regiaoNome;
                                    }
                                }
                            }
                            console.log('Talhões carregados: ' + ctrl.talhoes.length);
                            ctrl.scopeApply();
                        });
                    }
                    if (refNomeTabela === TextoUtil_1.TextoUtil.converteTextoParaReferencia('Fonte')) {
                        this.fontes = new Array();
                        const promiseFontes = this.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.FONTE)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.FONTE)
                            .filtro({})
                            .listar();
                        promiseFontes.then((resposta) => {
                            this.fontes.splice(0, this.fontes.length);
                            this.fontes.push(...resposta);
                            console.log('Fontes carregadas: ' + this.fontes.length);
                            this.scopeApply();
                        });
                    }
                    if (refNomeTabela === TextoUtil_1.TextoUtil.converteTextoParaReferencia('Pais')) {
                        this.paises = new Array();
                        this.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.PAIS)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PAIS)
                            .listar()
                            .then((resposta) => {
                            if ((typeof resposta !== 'undefined') && resposta) {
                                ctrl.paises = resposta;
                            }
                        });
                    }
                    if (refNomeTabela === TextoUtil_1.TextoUtil.converteTextoParaReferencia('TipoImplantacao')) {
                        this.tiposImplantacoes = new Array();
                        const promiseTiposImplantacoes = this.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.TIPO_IMPLANTACAO)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TIPO_IMPLANTACAO)
                            .filtro({})
                            .listar();
                        promiseTiposImplantacoes.then((resposta) => {
                            this.tiposImplantacoes.splice(0, this.tiposImplantacoes.length);
                            this.tiposImplantacoes.push(...resposta);
                            console.log('Tipos de Implantação carregados: ' + this.tiposImplantacoes.length);
                            this.scopeApply();
                        });
                    }
                    if (refNomeTabela === TextoUtil_1.TextoUtil.converteTextoParaReferencia('TipoPomarPopulacao')) {
                        this.tiposPomaresPopulacoes = new Array();
                        const promiseTiposPomaresPopulacoes = this.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.TIPO_POMAR_POPULACAO)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TIPO_POMAR_POPULACAO)
                            .filtro({})
                            .listar();
                        promiseTiposPomaresPopulacoes.then((resposta) => {
                            this.tiposPomaresPopulacoes.splice(0, this.tiposPomaresPopulacoes.length);
                            this.tiposPomaresPopulacoes.push(...resposta);
                            console.log('Tipos de Pomares/Populações carregados: ' + this.tiposPomaresPopulacoes.length);
                            this.scopeApply();
                        });
                    }
                }
            }
        });
    }
    salvar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POMAR)
                        .criar(this.modelo);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POMAR)
                        .atualizar(this.modelo);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir um novo Pomar ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir um novo Pomar ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não'
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    visualizar(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const pomar = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POMAR)
                    .id(id)
                    .ver();
                pomar.criacao = new Date(pomar.criacao);
                if ((typeof pomar.data !== 'undefined') && pomar.data) {
                    pomar.data = DataUtil_1.DataUtil.converterValorParaDataHora(pomar.data);
                    if (pomar.data) {
                        let dataHoraAtual = new Date();
                        let idadeMs = dataHoraAtual.getTime() - pomar.data.getTime();
                        let idadeData = new Date(idadeMs);
                        pomar.idade = Math.abs(idadeData.getUTCFullYear() - 1970);
                    }
                }
                this.filtraTalhoes(pomar);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return pomar;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    remover(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const pomar = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POMAR)
                    .id(id)
                    .remover();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (pomar) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const pomar = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POMAR)
                    .id(this.modelo.id)
                    .restaurar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (pomar) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosLista(filtro = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                this.path = config_app_1.config.api.caminho;
                this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.POMAR;
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                var auxColunas = Array();
                auxColunas.push({
                    name: 'generoDescricao',
                    displayName: 'Gênero',
                    width: 100
                });
                auxColunas.push({
                    name: 'especieDescricao',
                    displayName: 'Espécie',
                    width: 140
                });
                auxColunas.push({
                    name: 'tipoDescricao',
                    displayName: 'População (Tipo Pomar)',
                    width: 220
                });
                auxColunas.push({
                    name: 'geracaoDescricao',
                    displayName: 'Geração',
                    width: 80,
                    cellTemplate: `
                    <div class="ui-grid-cell-contents">
                        {{row.entity.geracaoCodigo ? row.entity.geracaoCodigo : row.entity.geracaoDescricao}}
                    </div>
                `
                });
                auxColunas.push({
                    name: 'descricao',
                    displayName: 'Pomar - Descrição',
                    width: 180
                });
                auxColunas.push({
                    name: 'data',
                    displayName: 'Data',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 100
                });
                auxColunas.push({
                    name: 'regiaoNome',
                    displayName: 'Fazenda',
                    width: 130
                });
                auxColunas.push({
                    name: 'regiaoNum',
                    displayName: 'Região',
                    width: 80
                });
                auxColunas.push({
                    name: 'talhaoNumero',
                    displayName: 'Talhão',
                    width: 90
                });
                auxColunas.push({
                    name: 'area',
                    displayName: 'Área (Ha)',
                    width: 90
                });
                auxColunas.push({
                    name: 'idade',
                    displayName: 'Idade',
                    width: 90
                });
                auxColunas.push({
                    name: 'situacaoDescricao',
                    displayName: 'Situação',
                    width: 96
                });
                auxColunas.push({
                    name: 'tratamentos',
                    displayName: 'Tratamentos',
                    width: 106
                });
                auxColunas.push({
                    name: 'individuos',
                    displayName: 'Indivíduos',
                    width: 100
                });
                auxColunas.push({
                    name: 'municipio',
                    displayName: 'municipio',
                    width: 160
                });
                auxColunas.push({
                    name: 'provinciaUfDescricao',
                    displayName: 'ProvinciaUf (UF)',
                    width: 130
                });
                auxColunas.push({
                    name: 'criacao',
                    displayName: 'Data/Hora Cadastro',
                    cellFilter: 'date:"dd/MM/yyyy HH:mm:ss"',
                    width: 190
                });
                auxColunas.push({
                    name: 'codigo',
                    displayName: 'Código',
                    width: 95
                });
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
                const lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POMAR)
                    .filtro(filtro)
                    .listar();
                if ((typeof lista !== 'undefined') && lista) {
                    angular.forEach(lista, (pomar) => {
                        if ((typeof pomar.data !== 'undefined') && pomar.data) {
                            pomar.data = new Date(pomar.data);
                            if (pomar.data) {
                                let dataHoraAtual = new Date();
                                let idadeMs = dataHoraAtual.getTime() - pomar.data.getTime();
                                let idadeData = new Date(idadeMs);
                                pomar.idade = Math.abs(idadeData.getUTCFullYear() - 1970);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach(element => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosListaTratamentos() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const ctrl = this;
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                this.path = config_app_1.config.api.caminho;
                this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO;
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                var auxColunas = Array();
                auxColunas.push({
                    name: 'tratamentos_materialGenetico',
                    field: 'materialGenetico.descricao',
                    displayName: 'Material Genético',
                    width: 150
                });
                auxColunas.push({
                    name: 'tratamentos_genero',
                    field: 'generoDescricao',
                    displayName: 'Gênero',
                    width: 120
                });
                auxColunas.push({
                    name: 'tratamentos_especie',
                    field: 'especieDescricao',
                    displayName: 'Espécie',
                    width: 130
                });
                auxColunas.push({
                    name: 'tratamentos_geracao',
                    field: 'geracaoDescricao',
                    displayName: 'Geração',
                    width: 120
                });
                auxColunas.push({
                    name: 'tratamentos_tipoPomar',
                    field: 'pomar.tipoDescricao',
                    displayName: 'Tipo de Pomar',
                    width: 160
                });
                auxColunas.push({
                    name: 'tratamentos_descricao',
                    field: 'pomar.descricao',
                    displayName: 'Nome do Pomar',
                    width: 180
                });
                auxColunas.push({
                    name: 'tratamentos_regiao',
                    field: 'pomar.regiaoNum',
                    displayName: 'Região',
                    width: 100
                });
                auxColunas.push({
                    name: 'tratamentos_localidade',
                    field: 'pomar.regiaoNome',
                    displayName: 'Localidade/Fazenda',
                    width: 160
                });
                auxColunas.push({
                    name: 'tratamentos_talhao',
                    field: 'pomar.talhaoNumero',
                    displayName: 'Talhão',
                    width: 100
                });
                auxColunas.push({
                    name: 'tratamentos_dataEstabelecimento',
                    field: 'pomar.data',
                    displayName: 'Data Estabelecimento',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 190
                });
                auxColunas.push({
                    name: 'tratamentos_anoEstabelecimento',
                    field: 'pomar.ano',
                    displayName: 'Ano Estabelecimento',
                    width: 160
                });
                auxColunas.push({
                    name: 'tratamentos_idade',
                    field: 'pomar.idade',
                    displayName: 'Idade',
                    width: 100
                });
                auxColunas.push({
                    name: 'tratamentos_area',
                    field: 'pomar.area',
                    displayName: 'Área (HA)',
                    width: 110
                });
                auxColunas.push({
                    name: 'tratamentos_situacao',
                    field: 'pomar.situacao',
                    displayName: 'Situação',
                    width: 120
                });
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
                const lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TRATAMENTO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TRATAMENTO_POMAR)
                    .filtro({
                    pomarId: ((typeof ctrl.pomarSelecionado !== 'undefined') && ctrl.pomarSelecionado &&
                        (typeof ctrl.pomarSelecionado.id !== 'undefined') && ctrl.pomarSelecionado.id
                        ? ctrl.pomarSelecionado.id : null)
                })
                    .listar();
                const listaPomares = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POMAR)
                    .filtro({})
                    .listar();
                if ((typeof listaPomares !== 'undefined') && listaPomares) {
                    angular.forEach(listaPomares, (pomar) => {
                        pomar.descricaoDetalhada = ((typeof pomar.descricao !== 'undefined') && pomar.descricao ? pomar.descricao : '');
                        if ((typeof pomar.data !== 'undefined') && pomar.data) {
                            pomar.data = (typeof pomar.data === 'string') ? new Date(pomar.data) : pomar.data;
                            if (pomar.data) {
                                let dataHoraAtual = new Date();
                                let idadeMs = dataHoraAtual.getTime() - pomar.data.getTime();
                                let idadeData = new Date(idadeMs);
                                pomar.idade = Math.abs(idadeData.getUTCFullYear() - 1970);
                                pomar.ano = pomar.data.getFullYear();
                                pomar.descricaoDetalhada += ' - ' + pomar.data.getFullYear();
                            }
                        }
                        if ((typeof pomar.instituicao === 'undefined') || !pomar.instituicao || (pomar.instituicao.length === 0)) {
                            pomar.instituicao = 'WestRock';
                        }
                        if ((typeof pomar.nomeCientifico === 'undefined') || !pomar.nomeCientifico || (pomar.nomeCientifico.length === 0)) {
                            pomar.nomeCientifico = pomar.descricao;
                        }
                        if ((typeof pomar.distanciaSede === 'undefined') || !pomar.distanciaSede) {
                            pomar.distanciaSede = (pomar.descricaoDetalhada.toLowerCase().indexOf('sede') !== -1) ? '1 km' : '35 km';
                        }
                        pomar.local = ((typeof pomar.municipio !== 'undefined') && pomar.municipio ? pomar.municipio : '') + ' - ' +
                            ((typeof pomar.provinciaUfSigla !== 'undefined') && pomar.provinciaUfSigla ? pomar.provinciaUfSigla : '');
                        if ((typeof pomar.perfilClimaticoAnual !== 'undefined') && pomar.perfilClimaticoAnual) {
                            if ((typeof pomar.perfilClimaticoAnual.atualizacao === 'undefined') || !pomar.perfilClimaticoAnual.atualizacao) {
                                pomar.perfilClimaticoAnual.atualizacao = new Date();
                            }
                            else if (typeof pomar.perfilClimaticoAnual.atualizacao === 'string') {
                                pomar.perfilClimaticoAnual.atualizacao = new Date(pomar.perfilClimaticoAnual.atualizacao);
                            }
                        }
                        else {
                            pomar.perfilClimaticoAnual = new PerfilClimaticoPomar_1.PerfilClimaticoPomar();
                            pomar.perfilClimaticoAnual.atualizacao = new Date();
                        }
                        if ((typeof pomar.producaoPortaEnxertosPlantio !== 'undefined') && pomar.producaoPortaEnxertosPlantio) {
                            pomar.producaoPortaEnxertosPlantio = (typeof pomar.producaoPortaEnxertosPlantio === 'string') ? new Date(pomar.producaoPortaEnxertosPlantio) : pomar.producaoPortaEnxertosPlantio;
                        }
                        if ((typeof pomar.implantacaoPomarEnxertia !== 'undefined') && pomar.implantacaoPomarEnxertia) {
                            pomar.implantacaoPomarEnxertia = (typeof pomar.implantacaoPomarEnxertia === 'string') ? new Date(pomar.implantacaoPomarEnxertia) : pomar.implantacaoPomarEnxertia;
                        }
                    });
                }
                if ((typeof lista !== 'undefined') && lista) {
                    angular.forEach(lista, (tratamentoPomar) => {
                        if ((typeof tratamentoPomar.pomar !== 'undefined') && tratamentoPomar.pomar && tratamentoPomar.pomar.id) {
                            const buscaPomar = listaPomares.find(x => x.id === tratamentoPomar.pomar.id);
                            if ((typeof buscaPomar !== 'undefined') && buscaPomar) {
                                tratamentoPomar.pomar = buscaPomar;
                            }
                        }
                        if ((typeof tratamentoPomar.pomar !== 'undefined') && tratamentoPomar.pomar &&
                            (typeof tratamentoPomar.pomar.generoDescricao !== 'undefined') && tratamentoPomar.pomar.generoDescricao) {
                            tratamentoPomar.generoDescricao = tratamentoPomar.pomar.generoDescricao;
                        }
                        else if ((typeof tratamentoPomar.pomar !== 'undefined') && tratamentoPomar.pomar &&
                            (typeof tratamentoPomar.pomar.genero !== 'undefined') && tratamentoPomar.pomar.genero &&
                            (typeof tratamentoPomar.pomar.genero.descricao !== 'undefined') && tratamentoPomar.pomar.genero.descricao) {
                            tratamentoPomar.generoDescricao = tratamentoPomar.pomar.genero.descricao;
                        }
                        else if ((typeof tratamentoPomar.materialGenetico !== 'undefined') && tratamentoPomar.materialGenetico &&
                            (typeof tratamentoPomar.materialGenetico.genero !== 'undefined') && tratamentoPomar.materialGenetico.genero &&
                            (typeof tratamentoPomar.materialGenetico.genero.descricao !== 'undefined') && tratamentoPomar.materialGenetico.genero.descricao) {
                            tratamentoPomar.generoDescricao = tratamentoPomar.materialGenetico.genero.descricao;
                        }
                        if ((typeof tratamentoPomar.pomar !== 'undefined') && tratamentoPomar.pomar &&
                            (typeof tratamentoPomar.pomar.especieDescricao !== 'undefined') && tratamentoPomar.pomar.especieDescricao) {
                            tratamentoPomar.especieDescricao = tratamentoPomar.pomar.especieDescricao;
                        }
                        else if ((typeof tratamentoPomar.pomar !== 'undefined') && tratamentoPomar.pomar &&
                            (typeof tratamentoPomar.pomar.especie !== 'undefined') && tratamentoPomar.pomar.especie &&
                            (typeof tratamentoPomar.pomar.especie.descricao !== 'undefined') && tratamentoPomar.pomar.especie.descricao) {
                            tratamentoPomar.especieDescricao = tratamentoPomar.pomar.especie.descricao;
                        }
                        else if ((typeof tratamentoPomar.materialGenetico !== 'undefined') && tratamentoPomar.materialGenetico &&
                            (typeof tratamentoPomar.materialGenetico.especie !== 'undefined') && tratamentoPomar.materialGenetico.especie &&
                            (typeof tratamentoPomar.materialGenetico.especie.descricao !== 'undefined') && tratamentoPomar.materialGenetico.especie.descricao) {
                            tratamentoPomar.especieDescricao = tratamentoPomar.materialGenetico.especie.descricao;
                        }
                        if ((typeof tratamentoPomar.pomar !== 'undefined') && tratamentoPomar.pomar &&
                            (typeof tratamentoPomar.pomar.geracaoDescricao !== 'undefined') && tratamentoPomar.pomar.geracaoDescricao) {
                            tratamentoPomar.geracaoDescricao = tratamentoPomar.pomar.geracaoDescricao;
                        }
                        else if ((typeof tratamentoPomar.pomar !== 'undefined') && tratamentoPomar.pomar &&
                            (typeof tratamentoPomar.pomar.geracao !== 'undefined') && tratamentoPomar.pomar.geracao &&
                            (typeof tratamentoPomar.pomar.geracao.descricao !== 'undefined') && tratamentoPomar.pomar.geracao.descricao) {
                            tratamentoPomar.geracaoDescricao = tratamentoPomar.pomar.geracao.descricao;
                        }
                        else if ((typeof tratamentoPomar.materialGenetico !== 'undefined') && tratamentoPomar.materialGenetico &&
                            (typeof tratamentoPomar.materialGenetico.geracao !== 'undefined') && tratamentoPomar.materialGenetico.geracao &&
                            (typeof tratamentoPomar.materialGenetico.geracao.descricao !== 'undefined') && tratamentoPomar.materialGenetico.geracao.descricao) {
                            tratamentoPomar.geracaoDescricao = tratamentoPomar.materialGenetico.geracao.descricao;
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach(element => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    filtrar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    limparFiltro() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    filtraTalhoes(pomar) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.timeoutFiltroTalhoes !== 'undefined') && (this.timeoutFiltroTalhoes !== null)) {
                clearTimeout(this.timeoutFiltroTalhoes);
                this.timeoutFiltroTalhoes = null;
            }
            const tmpPomar = ((typeof pomar !== 'undefined') && (pomar !== null)) ? pomar :
                ((typeof this.modelo !== 'undefined') && (this.modelo !== null)) ? this.modelo : null;
            if (!tmpPomar) {
                return;
            }
            this.timeoutFiltroTalhoes = setTimeout((controller, tmpPomar) => {
                if ((typeof controller.talhoes !== 'undefined') && (controller.talhoes !== null) && (controller.talhoes.length > 0)) {
                    if ((typeof controller.talhoesFiltrados !== 'undefined') && (controller.talhoesFiltrados !== null)) {
                        controller.talhoesFiltrados.splice(0, controller.talhoesFiltrados.length);
                    }
                    else {
                        controller.talhoesFiltrados = new Array();
                    }
                    if ((typeof tmpPomar.regiao !== 'undefined') && (tmpPomar.regiao !== null)) {
                        angular.forEach(controller.talhoes, (talhao) => {
                            if (talhao.regiaoNome === tmpPomar.regiao.nome) {
                                controller.talhoesFiltrados.push(talhao);
                            }
                        });
                    }
                    else if ((typeof tmpPomar.regiaoNome !== 'undefined') && (tmpPomar.regiaoNome !== null)) {
                        angular.forEach(controller.talhoes, (talhao) => {
                            if (talhao.regiaoNome === tmpPomar.regiaoNome) {
                                controller.talhoesFiltrados.push(talhao);
                            }
                        });
                    }
                    console.log('Talhões filtrados: ' + controller.talhoesFiltrados.length);
                    try {
                        controller.$scope.$apply();
                    }
                    catch (err) {
                        console.log(err);
                    }
                }
                else {
                    setTimeout((controller, tmpPomar) => {
                        controller.filtraTalhoes(tmpPomar);
                    }, 1000, controller, tmpPomar);
                }
            }, 500, this, tmpPomar);
        });
    }
    selecionarPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            let ctrl = this;
            if (ctrl && ctrl.constructor && ctrl.constructor.name) {
                if (ctrl.constructor.name === 'TabelaController') {
                    ctrl = ctrl.$scope.$parent.$ctrl;
                }
            }
            ctrl.filtrarPomarSelecionado();
        });
    }
    filtrarPomarSelecionado() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                let pomar = null;
                const nomeTabelaPomares = 'tabelaPomar';
                let gridPomaresApi = null;
                let gridPomaresScope = angular.element('#' + nomeTabelaPomares).scope();
                if ((typeof gridPomaresScope.listaGridApi !== 'undefined') && gridPomaresScope.listaGridApi) {
                    angular.forEach(gridPomaresScope.listaGridApi, (gridApi) => {
                        const grid = ((typeof gridApi.grid !== 'undefined') && gridApi.grid) ? gridApi.grid : null;
                        if (grid && (typeof grid.element !== 'undefined') && grid.element) {
                            const tabelasEl = angular.element(grid.element).parents('tabela');
                            if (tabelasEl && (tabelasEl.length > 0) && (tabelasEl.first().attr('id') === nomeTabelaPomares)) {
                                gridPomaresApi = gridApi;
                            }
                        }
                    });
                }
                if (gridPomaresApi) {
                    const pomaresSelecionados = gridPomaresApi.selection.getSelectedRows();
                    if ((typeof pomaresSelecionados !== 'undefined') && pomaresSelecionados) {
                        angular.forEach(pomaresSelecionados, (obj) => {
                            if (!pomar) {
                                pomar = obj;
                            }
                        });
                    }
                }
                if (pomar && (typeof pomar.id !== 'undefined') && pomar.id) {
                    if ((typeof ctrl.pomarSelecionado !== 'undefined') && ctrl.pomarSelecionado &&
                        (typeof ctrl.pomarSelecionado.id !== 'undefined') && ctrl.pomarSelecionado.id) {
                        if (ctrl.pomarSelecionado.id !== pomar.id) {
                            yield ctrl.limparFiltroPomar();
                            ctrl.pomarSelecionado = pomar;
                            ctrl.pomarCarregado = false;
                        }
                    }
                    else {
                        yield ctrl.limparFiltroPomar();
                        ctrl.pomarSelecionado = pomar;
                        ctrl.pomarCarregado = false;
                    }
                }
                else {
                    if (ctrl.pomarSelecionado || ctrl.pomarCarregado) {
                        yield ctrl.limparFiltroPomar();
                        ctrl.pomarCarregado = false;
                    }
                }
                ctrl.scopeApply();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
        });
    }
    limparFiltroPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.pomarSelecionado = null;
            this.pomarCarregado = false;
        });
    }
    cellClicked(row) {
        if (row.entity && (typeof row.entity.id !== 'undefined') && row.entity.id) {
            const nomeTabelaPomares = 'tabelaPomar';
            let ehTabelaPomares = false;
            if ((typeof row.gridApi !== 'undefined') && row.gridApi &&
                (typeof row.gridApi.element !== 'undefined') && row.gridApi.element &&
                (angular.element(row.gridApi.element).length > 0)) {
                ehTabelaPomares = (angular.element(row.gridApi.element).parents('#' + nomeTabelaPomares).length > 0) ? true : false;
            }
            else {
                ehTabelaPomares = true;
            }
            if (ehTabelaPomares) {
                let gridPomaresApi = null;
                let gridPomaresScope = angular.element('#' + nomeTabelaPomares).scope();
                if ((typeof gridPomaresScope.listaGridApi !== 'undefined') && gridPomaresScope.listaGridApi) {
                    angular.forEach(gridPomaresScope.listaGridApi, (gridApi) => {
                        const grid = ((typeof gridApi.grid !== 'undefined') && gridApi.grid) ? gridApi.grid : null;
                        if (grid && (typeof grid.element !== 'undefined') && grid.element) {
                            const tabelasEl = angular.element(grid.element).parents('tabela');
                            if (tabelasEl && (tabelasEl.length > 0) && (tabelasEl.first().attr('id') === nomeTabelaPomares)) {
                                gridPomaresApi = gridApi;
                            }
                        }
                    });
                }
                if (gridPomaresApi && (typeof gridPomaresApi.selection !== 'undefined') && gridPomaresApi.selection) {
                    gridPomaresApi.selection.selectRow(row.entity);
                }
            }
        }
    }
    abrirTratamentos() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.operacao !== ICRUDOperacao_1.CRUDOperacao.LISTAR) {
                this.pomarSelecionado = this.modelo;
            }
            if ((typeof this.pomarSelecionado === 'undefined') || !this.pomarSelecionado) {
                this.notificacao.alerta('Selecione um Pomar na tabela abaixo para acessar os Tratamentos.');
                return;
            }
            this.modalTratamentos = false;
            this.modalRelatorioPomar = false;
            this.modalRelatorioBancoConservacao = false;
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.listaOpcoesTratamentos = yield this.dadosListaTratamentos();
                this.modalTratamentos = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarTratamentos() {
        this.modalTratamentos = false;
        this.listaOpcoesTratamentos = null;
        angular.element('body').css('overflow-y', '');
        globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
        this.scopeApply(() => {
            setTimeout(() => {
                angular.element('body').css('overflow-y', '');
                if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                    (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                    angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                }
            }, 10);
        });
    }
    acessarTratamentos() {
        return __awaiter(this, void 0, void 0, function* () {
            let pomarId = null;
            let pomarUuid = null;
            let pomar = null;
            if ((this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) || (this.operacao === ICRUDOperacao_1.CRUDOperacao.DUPLICAR)) {
                pomar = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POMAR)
                    .criar(this.modelo);
            }
            else if (this.operacao === ICRUDOperacao_1.CRUDOperacao.EDITAR) {
                pomar = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POMAR)
                    .atualizar(this.modelo);
            }
            if ((typeof pomar !== 'undefined') && pomar) {
                if ((typeof pomar.id !== 'undefined') && pomar.id && (pomar.id.length > 0)) {
                    pomarId = pomar.id;
                }
                if ((typeof pomar.uuid !== 'undefined') && pomar.uuid && (pomar.uuid.length > 0)) {
                    pomarUuid = pomar.uuid;
                }
            }
            if (!pomarId && !pomarUuid && (typeof this.modelo !== 'undefined') && this.modelo) {
                if ((typeof this.modelo.id !== 'undefined') && this.modelo.id) {
                    pomarId = this.modelo.id;
                }
                if ((typeof this.modelo.uuid !== 'undefined') && this.modelo.uuid) {
                    pomarUuid = this.modelo.uuid;
                }
            }
            globalThis.filtroApontamentoPomar = { id: pomarId, uuid: pomarUuid };
            this.$state.go('apontamento.apontamento-listar');
        });
    }
    abrirRelatorioPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.operacao !== ICRUDOperacao_1.CRUDOperacao.LISTAR) {
                this.pomarSelecionado = this.modelo;
            }
            const ctrl = this;
            this.modalTratamentos = false;
            this.modalRelatorioPomar = false;
            this.modalRelatorioBancoConservacao = false;
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.listaPomares = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POMAR)
                    .filtro({})
                    .listar();
                if ((typeof this.listaPomares !== 'undefined') && this.listaPomares) {
                    angular.forEach(this.listaPomares, (pomar) => {
                        pomar.descricaoDetalhada = ((typeof pomar.descricao !== 'undefined') && pomar.descricao ? pomar.descricao : '');
                        if ((typeof pomar.data !== 'undefined') && pomar.data) {
                            pomar.data = (typeof pomar.data === 'string') ? new Date(pomar.data) : pomar.data;
                            if (pomar.data) {
                                let dataHoraAtual = new Date();
                                let idadeMs = dataHoraAtual.getTime() - pomar.data.getTime();
                                let idadeData = new Date(idadeMs);
                                pomar.idade = Math.abs(idadeData.getUTCFullYear() - 1970);
                                pomar.ano = pomar.data.getFullYear();
                                pomar.descricaoDetalhada += ' - ' + pomar.data.getFullYear();
                            }
                        }
                        if ((typeof pomar.paisDescricao === 'undefined') && !pomar.paisDescricao) {
                            if ((typeof ctrl.paises !== 'undefined') && ctrl.paises && (ctrl.paises.length > 0)) {
                                let paisPadrao = null;
                                const paisPadraoReferencia = TextoUtil_1.TextoUtil.converteTextoParaReferencia('Brasil');
                                angular.forEach(ctrl.paises, (pais) => {
                                    if (!paisPadrao && (typeof pais.descricao !== 'undefined') && pais.descricao && (pais.descricao.length > 0)) {
                                        if (TextoUtil_1.TextoUtil.converteTextoParaReferencia(pais.descricao) === paisPadraoReferencia) {
                                            paisPadrao = pais;
                                        }
                                    }
                                });
                                if (paisPadrao) {
                                    pomar.pais = paisPadrao;
                                    pomar.paisId = paisPadrao.id;
                                    pomar.paisCodigo = paisPadrao.codigo;
                                    pomar.paisDescricao = paisPadrao.descricao;
                                }
                            }
                            else {
                                let paisPadrao = {
                                    id: Pais_1.Pais.BRASIL,
                                    uuid: Pais_1.Pais.BRASIL,
                                    status: true,
                                    criacao: new Date(),
                                    codigo: 1,
                                    descricao: 'Brasil'
                                };
                                pomar.pais = paisPadrao;
                                pomar.paisId = paisPadrao.id;
                                pomar.paisCodigo = paisPadrao.codigo;
                                pomar.paisDescricao = paisPadrao.descricao;
                            }
                        }
                        if ((typeof pomar.instituicao === 'undefined') || !pomar.instituicao || (pomar.instituicao.length === 0)) {
                            pomar.instituicao = 'WestRock';
                        }
                        if ((typeof pomar.nomeCientifico === 'undefined') || !pomar.nomeCientifico || (pomar.nomeCientifico.length === 0)) {
                            pomar.nomeCientifico = pomar.descricao;
                        }
                        if ((typeof pomar.distanciaSede === 'undefined') || !pomar.distanciaSede) {
                            pomar.distanciaSede = (pomar.descricaoDetalhada.toLowerCase().indexOf('sede') !== -1) ? '1 km' : '35 km';
                        }
                        pomar.local = ((typeof pomar.municipio !== 'undefined') && pomar.municipio ? pomar.municipio : '') + ' - ' +
                            ((typeof pomar.provinciaUfSigla !== 'undefined') && pomar.provinciaUfSigla ? pomar.provinciaUfSigla : '');
                        if ((typeof pomar.perfilClimaticoAnual !== 'undefined') && pomar.perfilClimaticoAnual) {
                            if ((typeof pomar.perfilClimaticoAnual.atualizacao === 'undefined') || !pomar.perfilClimaticoAnual.atualizacao) {
                                pomar.perfilClimaticoAnual.atualizacao = new Date();
                            }
                            else if (typeof pomar.perfilClimaticoAnual.atualizacao === 'string') {
                                pomar.perfilClimaticoAnual.atualizacao = new Date(pomar.perfilClimaticoAnual.atualizacao);
                            }
                        }
                        else {
                            pomar.perfilClimaticoAnual = new PerfilClimaticoPomar_1.PerfilClimaticoPomar();
                            pomar.perfilClimaticoAnual.atualizacao = new Date();
                        }
                        if ((typeof pomar.producaoPortaEnxertosPlantio !== 'undefined') && pomar.producaoPortaEnxertosPlantio) {
                            pomar.producaoPortaEnxertosPlantio = (typeof pomar.producaoPortaEnxertosPlantio === 'string') ? new Date(pomar.producaoPortaEnxertosPlantio) : pomar.producaoPortaEnxertosPlantio;
                        }
                        if ((typeof pomar.implantacaoPomarEnxertia !== 'undefined') && pomar.implantacaoPomarEnxertia) {
                            pomar.implantacaoPomarEnxertia = (typeof pomar.implantacaoPomarEnxertia === 'string') ? new Date(pomar.implantacaoPomarEnxertia) : pomar.implantacaoPomarEnxertia;
                        }
                    });
                }
                this.relatorioPomarSelecionado = new RelatorioPomar_1.RelatorioPomar();
                this.relatorioPomarSelecionado.pomar = this.pomarSelecionado;
                this.relatorioPomarSelecionado.tipoRelatorio = TipoRelatorioPomar_1.TipoRelatorioPomar.POMARES_SEMENTES;
                this.relatorioPomarSelecionado.dataRelatorio = new Date();
                this.alterarFiltroRelatorioPomar();
                this.modalRelatorioPomar = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarRelatorioPomar() {
        this.modalRelatorioPomar = false;
        angular.element('body').css('overflow-y', '');
        globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
        this.scopeApply(() => {
            setTimeout(() => {
                angular.element('body').css('overflow-y', '');
                if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                    (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                    angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                }
            }, 10);
        });
    }
    abrirRelatorioBancoConservacao() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.operacao !== ICRUDOperacao_1.CRUDOperacao.LISTAR) {
                this.pomarSelecionado = this.modelo;
            }
            const ctrl = this;
            this.modalTratamentos = false;
            this.modalRelatorioPomar = false;
            this.modalRelatorioBancoConservacao = false;
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.listaPomares = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POMAR)
                    .filtro({})
                    .listar();
                if ((typeof this.listaPomares !== 'undefined') && this.listaPomares) {
                    angular.forEach(this.listaPomares, (pomar) => {
                        pomar.descricaoDetalhada = ((typeof pomar.descricao !== 'undefined') && pomar.descricao ? pomar.descricao : '');
                        if ((typeof pomar.data !== 'undefined') && pomar.data) {
                            pomar.data = (typeof pomar.data === 'string') ? new Date(pomar.data) : pomar.data;
                            if (pomar.data) {
                                let dataHoraAtual = new Date();
                                let idadeMs = dataHoraAtual.getTime() - pomar.data.getTime();
                                let idadeData = new Date(idadeMs);
                                pomar.idade = Math.abs(idadeData.getUTCFullYear() - 1970);
                                pomar.ano = pomar.data.getFullYear();
                                pomar.descricaoDetalhada += ' - ' + pomar.data.getFullYear();
                            }
                        }
                        if ((typeof pomar.paisDescricao === 'undefined') && !pomar.paisDescricao) {
                            if ((typeof ctrl.paises !== 'undefined') && ctrl.paises && (ctrl.paises.length > 0)) {
                                let paisPadrao = null;
                                const paisPadraoReferencia = TextoUtil_1.TextoUtil.converteTextoParaReferencia('Brasil');
                                angular.forEach(ctrl.paises, (pais) => {
                                    if (!paisPadrao && (typeof pais.descricao !== 'undefined') && pais.descricao && (pais.descricao.length > 0)) {
                                        if (TextoUtil_1.TextoUtil.converteTextoParaReferencia(pais.descricao) === paisPadraoReferencia) {
                                            paisPadrao = pais;
                                        }
                                    }
                                });
                                if (paisPadrao) {
                                    pomar.pais = paisPadrao;
                                    pomar.paisId = paisPadrao.id;
                                    pomar.paisCodigo = paisPadrao.codigo;
                                    pomar.paisDescricao = paisPadrao.descricao;
                                }
                            }
                            else {
                                let paisPadrao = {
                                    id: Pais_1.Pais.BRASIL,
                                    uuid: Pais_1.Pais.BRASIL,
                                    status: true,
                                    criacao: new Date(),
                                    codigo: 1,
                                    descricao: 'Brasil'
                                };
                                pomar.pais = paisPadrao;
                                pomar.paisId = paisPadrao.id;
                                pomar.paisCodigo = paisPadrao.codigo;
                                pomar.paisDescricao = paisPadrao.descricao;
                            }
                        }
                        if ((typeof pomar.instituicao === 'undefined') || !pomar.instituicao || (pomar.instituicao.length === 0)) {
                            pomar.instituicao = 'WestRock';
                        }
                        if ((typeof pomar.nomeCientifico === 'undefined') || !pomar.nomeCientifico || (pomar.nomeCientifico.length === 0)) {
                            pomar.nomeCientifico = pomar.descricao;
                        }
                        if ((typeof pomar.distanciaSede === 'undefined') || !pomar.distanciaSede) {
                            pomar.distanciaSede = (pomar.descricaoDetalhada.toLowerCase().indexOf('sede') !== -1) ? '1 km' : '35 km';
                        }
                        pomar.local = ((typeof pomar.municipio !== 'undefined') && pomar.municipio ? pomar.municipio : '') + ' - ' +
                            ((typeof pomar.provinciaUfSigla !== 'undefined') && pomar.provinciaUfSigla ? pomar.provinciaUfSigla : '');
                        if ((typeof pomar.perfilClimaticoAnual !== 'undefined') && pomar.perfilClimaticoAnual) {
                            if ((typeof pomar.perfilClimaticoAnual.atualizacao === 'undefined') || !pomar.perfilClimaticoAnual.atualizacao) {
                                pomar.perfilClimaticoAnual.atualizacao = new Date();
                            }
                            else if (typeof pomar.perfilClimaticoAnual.atualizacao === 'string') {
                                pomar.perfilClimaticoAnual.atualizacao = new Date(pomar.perfilClimaticoAnual.atualizacao);
                            }
                        }
                        else {
                            pomar.perfilClimaticoAnual = new PerfilClimaticoPomar_1.PerfilClimaticoPomar();
                            pomar.perfilClimaticoAnual.atualizacao = new Date();
                        }
                        if ((typeof pomar.producaoPortaEnxertosPlantio !== 'undefined') && pomar.producaoPortaEnxertosPlantio) {
                            pomar.producaoPortaEnxertosPlantio = (typeof pomar.producaoPortaEnxertosPlantio === 'string') ? new Date(pomar.producaoPortaEnxertosPlantio) : pomar.producaoPortaEnxertosPlantio;
                        }
                        if ((typeof pomar.implantacaoPomarEnxertia !== 'undefined') && pomar.implantacaoPomarEnxertia) {
                            pomar.implantacaoPomarEnxertia = (typeof pomar.implantacaoPomarEnxertia === 'string') ? new Date(pomar.implantacaoPomarEnxertia) : pomar.implantacaoPomarEnxertia;
                        }
                    });
                }
                this.relatorioPomarSelecionado = new RelatorioPomar_1.RelatorioPomar();
                this.relatorioPomarSelecionado.pomar = this.pomarSelecionado;
                this.relatorioPomarSelecionado.tipoRelatorio = TipoRelatorioPomar_1.TipoRelatorioPomar.BANCOS_CONSERVACAO;
                this.relatorioPomarSelecionado.dataRelatorio = new Date();
                this.alterarFiltroRelatorioPomar();
                this.modalRelatorioBancoConservacao = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarRelatorioBancoConservacao() {
        this.modalRelatorioBancoConservacao = false;
        angular.element('body').css('overflow-y', '');
        globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
        this.scopeApply(() => {
            setTimeout(() => {
                angular.element('body').css('overflow-y', '');
                if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                    (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                    angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                }
            }, 10);
        });
    }
    alterarFiltroRelatorioPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.relatorioPomarSelecionado.pomaresSementes = new RelatorioPomaresSementesBancosConservacao_1.RelatorioPomaresSementesBancosConservacao();
            this.relatorioPomarSelecionado.bancosConservacao = new RelatorioPomaresSementesBancosConservacao_1.RelatorioPomaresSementesBancosConservacao();
            if ((typeof this.listaPomares !== 'undefined') && this.listaPomares) {
                angular.forEach(this.listaPomares, (pomar) => {
                    let ehBancoConservacao = false;
                    let valido = false;
                    if (pomar) {
                        valido = true;
                        if ((typeof pomar.tipoSigla !== 'undefined') && pomar.tipoSigla) {
                            if (pomar.tipoSigla.trim().substring(0, 1).toUpperCase() === 'B') {
                                ehBancoConservacao = true;
                            }
                        }
                        else if ((typeof pomar.tipoDescricao !== 'undefined') && pomar.tipoDescricao) {
                            if (pomar.tipoDescricao.trim().substring(0, 1).toUpperCase() === 'B') {
                                ehBancoConservacao = true;
                            }
                        }
                        if ((typeof ctrl.relatorioPomarSelecionado.tipoRelatorio !== 'undefined') &&
                            (ctrl.relatorioPomarSelecionado.tipoRelatorio === TipoRelatorioPomar_1.TipoRelatorioPomar.BANCOS_CONSERVACAO)) {
                            valido = valido && ehBancoConservacao ? true : false;
                        }
                        else {
                            valido = valido && !ehBancoConservacao ? true : false;
                        }
                    }
                    if (valido) {
                        ctrl.relatorioPomarSelecionado.pomaresSementes.numTotalPomares++;
                        ctrl.relatorioPomarSelecionado.bancosConservacao.numTotalBancos++;
                        if (pomar.area && (pomar.area > 0)) {
                            ctrl.relatorioPomarSelecionado.pomaresSementes.areaTotalPomares += pomar.area;
                            ctrl.relatorioPomarSelecionado.bancosConservacao.areaTotalBancos += pomar.area;
                        }
                        else if (pomar.talhao && pomar.talhao.areaTalhao && (pomar.talhao.areaTalhao > 0)) {
                            ctrl.relatorioPomarSelecionado.pomaresSementes.areaTotalPomares += pomar.talhao.areaTalhao;
                            ctrl.relatorioPomarSelecionado.bancosConservacao.areaTotalBancos += pomar.talhao.areaTalhao;
                        }
                        ctrl.relatorioPomarSelecionado.pomaresSementes.areaTotalPomares = NumeroUtil_1.NumeroUtil.arredondarNumero(ctrl.relatorioPomarSelecionado.pomaresSementes.areaTotalPomares, 2);
                        ctrl.relatorioPomarSelecionado.bancosConservacao.areaTotalBancos = NumeroUtil_1.NumeroUtil.arredondarNumero(ctrl.relatorioPomarSelecionado.bancosConservacao.areaTotalBancos, 2);
                        if ((typeof pomar.geracaoDescricao !== 'undefined') && pomar.geracaoDescricao) {
                            if (pomar.geracaoDescricao.indexOf('1,5') !== -1) {
                                ctrl.relatorioPomarSelecionado.pomaresSementes.numTotalPomaresGeracao15++;
                                ctrl.relatorioPomarSelecionado.bancosConservacao.numTotalBancosGeracao15++;
                            }
                            else if (pomar.geracaoDescricao.indexOf('2,5') !== -1) {
                                ctrl.relatorioPomarSelecionado.pomaresSementes.numTotalPomaresGeracao25++;
                                ctrl.relatorioPomarSelecionado.bancosConservacao.numTotalBancosGeracao25++;
                            }
                            else if (pomar.geracaoDescricao.indexOf('1') !== -1) {
                                ctrl.relatorioPomarSelecionado.pomaresSementes.numTotalPomaresGeracao1++;
                                ctrl.relatorioPomarSelecionado.bancosConservacao.numTotalBancosGeracao1++;
                            }
                            else if (pomar.geracaoDescricao.indexOf('2') !== -1) {
                                ctrl.relatorioPomarSelecionado.pomaresSementes.numTotalPomaresGeracao2++;
                                ctrl.relatorioPomarSelecionado.bancosConservacao.numTotalBancosGeracao2++;
                            }
                            else if (pomar.geracaoDescricao.indexOf('3') !== -1) {
                                ctrl.relatorioPomarSelecionado.pomaresSementes.numTotalPomaresGeracao3++;
                                ctrl.relatorioPomarSelecionado.bancosConservacao.numTotalBancosGeracao3++;
                            }
                        }
                        if (ehBancoConservacao) {
                            ctrl.relatorioPomarSelecionado.bancosConservacao.numTotalFamilias = 148;
                            ctrl.relatorioPomarSelecionado.bancosConservacao.numTotalFamiliasGeracao1 = 27;
                            ctrl.relatorioPomarSelecionado.bancosConservacao.numTotalFamiliasGeracao15 = 5;
                            ctrl.relatorioPomarSelecionado.bancosConservacao.numTotalFamiliasGeracao2 = 16;
                            ctrl.relatorioPomarSelecionado.bancosConservacao.numTotalFamiliasGeracao25 = 0;
                            ctrl.relatorioPomarSelecionado.bancosConservacao.numTotalFamiliasGeracao3 = 0;
                            if (ctrl.relatorioPomarSelecionado.bancosConservacao.numTotalBancos > 0) {
                                angular.forEach(['EucalyptusDunnii', 'EucalyptusBenthamii', 'EucalyptusOutros',
                                    'PinusTaeda', 'PinusElliottii', 'PinusOutros'
                                ], (especie) => {
                                    ctrl.relatorioPomarSelecionado.bancosConservacao['areaBancos' + especie] = NumeroUtil_1.NumeroUtil.arredondarNumero(ctrl.relatorioPomarSelecionado.bancosConservacao.areaTotalBancos
                                        * ctrl.relatorioPomarSelecionado.bancosConservacao['numBancos' + especie]
                                        / ctrl.relatorioPomarSelecionado.bancosConservacao.numTotalBancos, 2);
                                });
                            }
                        }
                        else {
                            if (ctrl.relatorioPomarSelecionado.pomaresSementes.numTotalPomares > 0) {
                                angular.forEach(['EucalyptusDunnii', 'EucalyptusBenthamii', 'EucalyptusOutros',
                                    'PinusTaeda', 'PinusElliottii', 'PinusOutros'
                                ], (especie) => {
                                    ctrl.relatorioPomarSelecionado.pomaresSementes['areaPomares' + especie] = NumeroUtil_1.NumeroUtil.arredondarNumero(ctrl.relatorioPomarSelecionado.pomaresSementes.areaTotalPomares
                                        * ctrl.relatorioPomarSelecionado.pomaresSementes['numPomares' + especie]
                                        / ctrl.relatorioPomarSelecionado.pomaresSementes.numTotalPomares, 2);
                                });
                            }
                        }
                    }
                });
            }
        });
    }
}
exports.PomarController = PomarController;
PomarController.$inject = ['$scope', '$injector'];
