"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CloneMaterialGeneticoController = void 0;
const angular = require("angular");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const UiGridConstrutor_1 = require("../../../app/construtores/UiGridConstrutor");
const AbstratoController_1 = require("../../../app/AbstratoController");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const ICRUDOperacao_1 = require("../../../app/interfaces/ICRUDOperacao");
const CloneMaterialGenetico_1 = require("../../../../../modelos/CloneMaterialGenetico");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const config_app_1 = require("../../../../../config/config.app");
const chart_js_1 = require("chart.js");
const sweetalert2_1 = require("sweetalert2");
class CloneMaterialGeneticoController extends AbstratoController_1.AbstratoController {
    constructor($scope, $injector) {
        super($scope, $injector, new CloneMaterialGenetico_1.CloneMaterialGenetico());
        this.path = '';
        this.caminho = '';
        this.tipoOperacao = null;
        this.cloneMaterialGeneticoSelecionado = null;
        this.cloneMaterialGeneticoCarregado = false;
        this.posicaoTopoAntesModal = null;
        this.modalDossieClone = false;
        this.modalRelatorioClonal = false;
        this.generos = [];
        this.especies = [];
        this.anos = [];
        this.procedencias = [];
        this.regioes = [];
        this.bancos = [];
        this.materiaisGeneticos = [];
        this.minijardins = [];
        this.listaProducao = [];
        this.listaEnraizamento = [];
        this.graficoRelatorioDossie = null;
        this.graficoRelatorioRecomendacao = null;
        this.totalNumMiniestacasCepa = 0;
        this.totalNumMudas = 0;
        this.medEnraizamento = 0;
        this.filtroDossieClone = {
            clone: null,
            listaMinijardins: []
        };
        this.tipoOperacao = ICRUDOperacao_1.CRUDOperacao;
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.path = config_app_1.config.api.caminho;
            this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.CLONE_MATERIAL_GENETICO;
            this.filtroDossieClone = { clone: null, listaMinijardins: [] };
            if ((this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) || (this.operacao === ICRUDOperacao_1.CRUDOperacao.EDITAR) || (this.operacao === ICRUDOperacao_1.CRUDOperacao.DUPLICAR)) {
                this.generos = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.GENERO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.GENERO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.generos = resposta;
                    }
                });
                this.anos = [];
                for (let i = 2000; i <= 2023; i++) {
                    this.anos.push(i);
                }
                this.especies = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESPECIE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESPECIE)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.especies = resposta;
                    }
                });
                this.procedencias = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROCEDENCIA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROCEDENCIA)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.procedencias = resposta;
                    }
                });
                this.regioes = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.REGIAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.REGIAO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.regioes = resposta;
                    }
                });
                this.bancos = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POMAR)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.bancos = resposta;
                    }
                });
                this.materiaisGeneticos = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MATERIAL_GENETICO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.materiaisGeneticos = resposta;
                    }
                });
            }
            if (this.operacao === ICRUDOperacao_1.CRUDOperacao.LISTAR) {
                this.minijardins = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.minijardins = resposta;
                    }
                });
                this.listaProducao = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.listaProducao = resposta;
                    }
                });
                this.listaEnraizamento = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_ENRAIZAMENTO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_ENRAIZAMENTO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.listaEnraizamento = resposta;
                    }
                });
            }
            this.$scope.$apply();
        });
    }
    salvar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.CLONE_MATERIAL_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CLONE_MATERIAL_GENETICO)
                        .criar(this.modelo);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.CLONE_MATERIAL_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CLONE_MATERIAL_GENETICO)
                        .atualizar(this.modelo);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir um novo Clone ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir um novo Clone ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não'
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    visualizar(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const cloneMaterialGenetico = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.CLONE_MATERIAL_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CLONE_MATERIAL_GENETICO)
                    .id(id)
                    .ver();
                cloneMaterialGenetico.criacao = new Date(cloneMaterialGenetico.criacao);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return cloneMaterialGenetico;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    remover(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const cloneMaterialGenetico = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.CLONE_MATERIAL_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CLONE_MATERIAL_GENETICO)
                    .id(id)
                    .remover();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (cloneMaterialGenetico) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const cloneMaterialGenetico = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.CLONE_MATERIAL_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CLONE_MATERIAL_GENETICO)
                    .id(this.modelo.id)
                    .restaurar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (cloneMaterialGenetico) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosLista(filtro = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                this.path = config_app_1.config.api.caminho;
                this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.CLONE_MATERIAL_GENETICO;
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                var auxColunas = Array();
                auxColunas.push({
                    name: 'clone',
                    displayName: 'Clone',
                    width: 120
                });
                auxColunas.push({
                    name: 'genero.descricao',
                    displayName: 'Gênero',
                    width: 160
                });
                auxColunas.push({
                    name: 'especie.descricao',
                    displayName: 'Espécie',
                    width: 200
                });
                auxColunas.push({
                    name: 'anoResgate',
                    displayName: 'Ano Resgate',
                    width: 110
                });
                auxColunas.push({
                    name: 'procedencia.descricao',
                    displayName: 'Procedência',
                    width: 200
                });
                auxColunas.push({
                    name: 'criacao',
                    displayName: 'Data/Hora Cadastro',
                    cellFilter: 'date:"dd/MM/yyyy HH:mm:ss"',
                    width: 190
                });
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
                const lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.CLONE_MATERIAL_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CLONE_MATERIAL_GENETICO)
                    .filtro(filtro)
                    .listar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach(element => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    filtrar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    limparFiltro() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    abrirModalDossieClone() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (typeof this.cloneMaterialGeneticoSelecionado !== 'undefined' && this.cloneMaterialGeneticoSelecionado) {
                    yield this.filtrarMinijardimDossieClone();
                    yield this.filtrarDossieClone();
                    this.modalDossieClone = true;
                    angular.element('body').css('overflow-y', 'hidden');
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Sem informações!',
                        text: 'Você precisa escolher um clone',
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    });
                    this.modalDossieClone = false;
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalDossieClone() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalDossieClone = false;
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    filtrarDossieClone() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            if ((typeof this.cloneMaterialGeneticoSelecionado !== 'undefined') && this.cloneMaterialGeneticoSelecionado) {
                let listaProducao = this.listaProducao;
                let listaEnraizamento = this.listaEnraizamento;
                let listaDataRelatorio = [];
                let listaDataProducaoRelatorio = [];
                let listaDataEnraizamentoRelatorio = [];
                let listaLabelsRelatorio = [];
                listaProducao.sort((a, b) => (a.data > b.data) ? 1 : ((b.data > a.data) ? -1 : 0));
                listaEnraizamento.sort((a, b) => (a.data > b.data) ? 1 : ((b.data > a.data) ? -1 : 0));
                const listaProducaoRelatorio = listaProducao.filter(producao => {
                    return ((typeof producao.clone.id !== 'undefined') && producao.clone.id &&
                        (typeof ctrl.cloneMaterialGeneticoSelecionado.materialGenetico !== 'undefined') && ctrl.cloneMaterialGeneticoSelecionado.materialGenetico &&
                        (producao.clone.id === ctrl.cloneMaterialGeneticoSelecionado.materialGenetico.id));
                });
                const listaEnraizamentoRelatorio = listaEnraizamento.filter(enraizamento => {
                    return ((typeof enraizamento.clone.clone !== 'undefined') && enraizamento.clone.clone &&
                        (typeof ctrl.cloneMaterialGeneticoSelecionado.materialGenetico !== 'undefined') && ctrl.cloneMaterialGeneticoSelecionado.materialGenetico &&
                        (String(enraizamento.clone.clone) === ctrl.cloneMaterialGeneticoSelecionado.materialGenetico.id));
                });
                angular.forEach(listaProducaoRelatorio, (producao) => {
                    var _a, _b;
                    producao.dataStr = producao.data;
                    let itemDataRelatorio = listaDataRelatorio.find(o => o.data === producao.data);
                    if ((typeof itemDataRelatorio === 'undefined') && !itemDataRelatorio) {
                        listaDataRelatorio.push({
                            data: producao.data,
                            enraizamento: 0,
                            producao: (_a = producao.numMiniestacasCepa) !== null && _a !== void 0 ? _a : 0,
                            totalProducao: 1,
                            totalEnraizamento: 0
                        });
                    }
                    else {
                        itemDataRelatorio.enraizamento += 0;
                        itemDataRelatorio.producao += (_b = producao.numMiniestacasCepa) !== null && _b !== void 0 ? _b : 0;
                        itemDataRelatorio.totalProducao += 1;
                        itemDataRelatorio.totalEnraizamento += 0;
                    }
                });
                angular.forEach(listaEnraizamentoRelatorio, (enraizamento) => {
                    var _a;
                    enraizamento.dataStr = enraizamento.data;
                    let itemDataRelatorio = listaDataRelatorio.find(o => o.data === enraizamento.data);
                    if ((typeof itemDataRelatorio === 'undefined') || !itemDataRelatorio) {
                        listaDataRelatorio.push({
                            data: enraizamento.data,
                            enraizamento: (_a = enraizamento.porcentagemEnraizamento) !== null && _a !== void 0 ? _a : 0,
                            producao: 0,
                            totalProducao: 0,
                            totalEnraizamento: 1
                        });
                    }
                    else {
                        itemDataRelatorio.enraizamento += enraizamento.porcentagemEnraizamento;
                        itemDataRelatorio.producao += 0;
                        itemDataRelatorio.totalEnraizamento += 1;
                        itemDataRelatorio.totalProducao += 0;
                    }
                });
                angular.forEach(listaDataRelatorio, (dataRelatorio) => {
                    if ((typeof dataRelatorio.data !== 'undefined') && dataRelatorio.data) {
                        if (dataRelatorio.totalProducao > 0) {
                            listaDataProducaoRelatorio.push(Number((dataRelatorio.producao / dataRelatorio.totalProducao).toFixed(2)));
                        }
                        else {
                            listaDataProducaoRelatorio.push(Number((dataRelatorio.producao).toFixed(2)));
                        }
                        if (dataRelatorio.totalEnraizamento > 0) {
                            listaDataEnraizamentoRelatorio.push(Number((dataRelatorio.enraizamento / dataRelatorio.totalEnraizamento).toFixed(2)));
                        }
                        else {
                            listaDataEnraizamentoRelatorio.push(Number((dataRelatorio.enraizamento).toFixed(2)));
                        }
                        listaLabelsRelatorio.push(new Date(dataRelatorio.data).toLocaleDateString('pt-BR'));
                    }
                });
                yield this.dadosDossieClone(listaDataProducaoRelatorio, listaDataEnraizamentoRelatorio, listaLabelsRelatorio);
            }
            this.scopeApply();
        });
    }
    dadosDossieClone(listaDataProducaoRelatorio, listaDataEnraizamentoRelatorio, listaLabelsRelatorio) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.graficoRelatorioDossie !== 'undefined') && this.graficoRelatorioDossie) {
                this.graficoRelatorioDossie.destroy();
                this.graficoRelatorioDossie = null;
            }
            for (let cargaGrafico = 1; cargaGrafico <= 10; cargaGrafico++) {
                setTimeout((ctrl) => {
                    if (ctrl.graficoRelatorioDossie) {
                        return;
                    }
                    const graficoRelatorioDossieEl = document.getElementById('graficoRelatorioDossie');
                    if ((typeof graficoRelatorioDossieEl === 'undefined') || !graficoRelatorioDossieEl) {
                        return;
                    }
                    const graficoRelatorioDossieCtx = graficoRelatorioDossieEl.getContext('2d');
                    const graficoRelatorioDossieDataSets = [{
                            type: 'bar',
                            label: 'Porcentagem de Enraizamento',
                            data: listaDataEnraizamentoRelatorio,
                            backgroundColor: 'rgba(54, 162, 235, 0.2)',
                            borderColor: 'rgb(54, 162, 235)',
                            borderWidth: 1,
                            yAxisID: 'A',
                        }, {
                            type: 'line',
                            label: 'Nº Miniestacas por Minicepa',
                            data: listaDataProducaoRelatorio,
                            backgroundColor: 'rgb(255, 159, 64)',
                            borderColor: 'rgb(255, 159, 64)',
                            borderWidth: 1,
                            fill: false,
                            yAxisID: 'B',
                        }];
                    ctrl.graficoRelatorioDossie = new chart_js_1.Chart(graficoRelatorioDossieCtx, {
                        type: 'bar',
                        data: {
                            labels: listaLabelsRelatorio,
                            datasets: graficoRelatorioDossieDataSets
                        },
                        plugins: [globalThis.ChartDataLabels],
                        options: {
                            responsive: true,
                            legend: {
                                display: false
                            },
                            layout: {
                                padding: {
                                    right: 30
                                }
                            },
                            scales: {
                                gridLines: { offsetGridLines: true },
                                xAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    }],
                                yAxes: [{
                                        id: 'A',
                                        type: 'linear',
                                        position: 'left',
                                        ticks: {
                                            min: 0
                                        }
                                    }, {
                                        id: 'B',
                                        type: 'linear',
                                        position: 'right',
                                        ticks: {
                                            min: 0
                                        }
                                    }]
                            }
                        }
                    });
                }, cargaGrafico * 1000, this);
            }
            this.scopeApply();
        });
    }
    filtrarMinijardimDossieClone() {
        return __awaiter(this, void 0, void 0, function* () {
            let listaDossie = [];
            this.totalNumMiniestacasCepa = 0;
            this.totalNumMudas = 0;
            this.medEnraizamento = 0;
            if ((typeof this.cloneMaterialGeneticoSelecionado !== 'undefined') && this.cloneMaterialGeneticoSelecionado) {
                listaDossie = this.minijardins.filter((o) => {
                    return (o.producaoClone.clone.id === this.cloneMaterialGeneticoSelecionado.id);
                });
                angular.forEach(listaDossie, (itemDossie) => {
                    var _a, _b, _c;
                    this.filtroDossieClone.listaMinijardins.push(itemDossie);
                    this.filtroDossieClone.clone = itemDossie;
                    this.totalNumMiniestacasCepa += (_a = itemDossie.numMiniestacasCepa) !== null && _a !== void 0 ? _a : 0;
                    this.totalNumMudas += (_b = itemDossie.numMudas) !== null && _b !== void 0 ? _b : 0;
                    this.medEnraizamento += (_c = itemDossie.enraizamento) !== null && _c !== void 0 ? _c : 0;
                });
                if (listaDossie.length > 0) {
                    this.medEnraizamento = this.medEnraizamento / listaDossie.length;
                }
            }
        });
    }
    abrirModalRelatorioClonal() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (typeof this.cloneMaterialGeneticoSelecionado !== 'undefined' && this.cloneMaterialGeneticoSelecionado) {
                    this.modalRelatorioClonal = true;
                    yield this.filtrarMinijardimDossieClone();
                    yield this.filtrarRelatorioClonal();
                    angular.element('body').css('overflow-y', 'hidden');
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Sem informações!',
                        text: 'Você precisa escolher um clone',
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    });
                    this.modalRelatorioClonal = false;
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalRelatorioClonal() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalRelatorioClonal = false;
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    filtrarRelatorioClonal() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            if ((typeof this.cloneMaterialGeneticoSelecionado !== 'undefined') && this.cloneMaterialGeneticoSelecionado) {
                let listaProducao = this.listaProducao;
                let listaEnraizamento = this.listaEnraizamento;
                let listaDataRelatorio = [];
                let listaDataProducaoRelatorio = [];
                let listaDataEnraizamentoRelatorio = [];
                let listaLabelsRelatorio = [];
                listaProducao.sort((a, b) => (a.data > b.data) ? 1 : ((b.data > a.data) ? -1 : 0));
                listaEnraizamento.sort((a, b) => (a.data > b.data) ? 1 : ((b.data > a.data) ? -1 : 0));
                const listaProducaoRelatorio = listaProducao.filter(producao => {
                    return ((typeof producao.clone.id !== 'undefined') && producao.clone.id &&
                        (typeof ctrl.cloneMaterialGeneticoSelecionado.materialGenetico !== 'undefined') && ctrl.cloneMaterialGeneticoSelecionado.materialGenetico &&
                        (producao.clone.id === ctrl.cloneMaterialGeneticoSelecionado.materialGenetico.id));
                });
                const listaEnraizamentoRelatorio = listaEnraizamento.filter(enraizamento => {
                    return ((typeof enraizamento.clone.clone !== 'undefined') && enraizamento.clone.clone &&
                        (typeof ctrl.cloneMaterialGeneticoSelecionado.materialGenetico !== 'undefined') && ctrl.cloneMaterialGeneticoSelecionado.materialGenetico &&
                        (String(enraizamento.clone.clone) === ctrl.cloneMaterialGeneticoSelecionado.materialGenetico.id));
                });
                angular.forEach(listaProducaoRelatorio, (producao) => {
                    var _a, _b;
                    producao.dataStr = producao.data;
                    let itemDataRelatorio = listaDataRelatorio.find(o => o.data === producao.data);
                    if ((typeof itemDataRelatorio === 'undefined') && !itemDataRelatorio) {
                        listaDataRelatorio.push({
                            data: producao.data,
                            enraizamento: 0,
                            producao: (_a = producao.numMiniestacasCepa) !== null && _a !== void 0 ? _a : 0,
                            totalProducao: 1,
                            totalEnraizamento: 0
                        });
                    }
                    else {
                        itemDataRelatorio.enraizamento += 0;
                        itemDataRelatorio.producao += (_b = producao.numMiniestacasCepa) !== null && _b !== void 0 ? _b : 0;
                        itemDataRelatorio.totalProducao += 1;
                        itemDataRelatorio.totalEnraizamento += 0;
                    }
                });
                angular.forEach(listaEnraizamentoRelatorio, (enraizamento) => {
                    var _a;
                    enraizamento.dataStr = enraizamento.data;
                    let itemDataRelatorio = listaDataRelatorio.find(o => o.data === enraizamento.data);
                    if ((typeof itemDataRelatorio === 'undefined') || !itemDataRelatorio) {
                        listaDataRelatorio.push({
                            data: enraizamento.data,
                            enraizamento: (_a = enraizamento.porcentagemEnraizamento) !== null && _a !== void 0 ? _a : 0,
                            producao: 0,
                            totalProducao: 0,
                            totalEnraizamento: 1
                        });
                    }
                    else {
                        itemDataRelatorio.enraizamento += enraizamento.porcentagemEnraizamento;
                        itemDataRelatorio.producao += 0;
                        itemDataRelatorio.totalEnraizamento += 1;
                        itemDataRelatorio.totalProducao += 0;
                    }
                });
                angular.forEach(listaDataRelatorio, (dataRelatorio) => {
                    if ((typeof dataRelatorio.data !== 'undefined') && dataRelatorio.data) {
                        if (dataRelatorio.totalProducao > 0) {
                            listaDataProducaoRelatorio.push(Number((dataRelatorio.producao / dataRelatorio.totalProducao).toFixed(2)));
                        }
                        else {
                            listaDataProducaoRelatorio.push(Number((dataRelatorio.producao).toFixed(2)));
                        }
                        if (dataRelatorio.totalEnraizamento > 0) {
                            listaDataEnraizamentoRelatorio.push(Number((dataRelatorio.enraizamento / dataRelatorio.totalEnraizamento).toFixed(2)));
                        }
                        else {
                            listaDataEnraizamentoRelatorio.push(Number((dataRelatorio.enraizamento).toFixed(2)));
                        }
                        listaLabelsRelatorio.push(new Date(dataRelatorio.data).toLocaleDateString('pt-BR'));
                    }
                });
                yield this.dadosRelatorioClonal(listaDataProducaoRelatorio, listaDataEnraizamentoRelatorio, listaLabelsRelatorio);
            }
            this.scopeApply();
        });
    }
    dadosRelatorioClonal(listaDataProducaoRelatorio, listaDataEnraizamentoRelatorio, listaLabelsRelatorio) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.graficoRelatorioRecomendacao !== 'undefined') && this.graficoRelatorioRecomendacao) {
                this.graficoRelatorioRecomendacao.destroy();
                this.graficoRelatorioRecomendacao = null;
            }
            for (let cargaGrafico = 1; cargaGrafico <= 10; cargaGrafico++) {
                setTimeout((ctrl) => {
                    if (ctrl.graficoRelatorioRecomendacao) {
                        return;
                    }
                    const graficoRelatorioRecomendacaoEl = document.getElementById('graficoRelatorioRecomendacao');
                    if ((typeof graficoRelatorioRecomendacaoEl === 'undefined') || !graficoRelatorioRecomendacaoEl) {
                        return;
                    }
                    const graficoRelatorioRecomendacaoCtx = graficoRelatorioRecomendacaoEl.getContext('2d');
                    const graficoRelatorioRecomendacaoDataSets = [{
                            type: 'bar',
                            label: 'Porcentagem de Enraizamento',
                            data: listaDataEnraizamentoRelatorio,
                            backgroundColor: 'rgba(54, 162, 235, 0.2)',
                            borderColor: 'rgb(54, 162, 235)',
                            borderWidth: 1,
                            yAxisID: 'A',
                        }, {
                            type: 'line',
                            label: 'Nº Miniestacas por Minicepa',
                            data: listaDataProducaoRelatorio,
                            backgroundColor: 'rgb(255, 159, 64)',
                            borderColor: 'rgb(255, 159, 64)',
                            borderWidth: 1,
                            fill: false,
                            yAxisID: 'B',
                        }];
                    ctrl.graficoRelatorioRecomendacao = new chart_js_1.Chart(graficoRelatorioRecomendacaoCtx, {
                        type: 'bar',
                        data: {
                            labels: listaLabelsRelatorio,
                            datasets: graficoRelatorioRecomendacaoDataSets
                        },
                        plugins: [globalThis.ChartDataLabels],
                        options: {
                            responsive: true,
                            legend: {
                                display: false
                            },
                            layout: {
                                padding: {
                                    right: 30
                                }
                            },
                            scales: {
                                gridLines: { offsetGridLines: true },
                                xAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    }],
                                yAxes: [{
                                        id: 'A',
                                        type: 'linear',
                                        position: 'left',
                                        ticks: {
                                            min: 0
                                        }
                                    }, {
                                        id: 'B',
                                        type: 'linear',
                                        position: 'right',
                                        ticks: {
                                            min: 0
                                        }
                                    }]
                            }
                        }
                    });
                }, cargaGrafico * 1000, this);
            }
            this.scopeApply();
        });
    }
    cellClicked(row) {
        if (row.entity && typeof row.entity.id !== 'undefined' && row.entity.id) {
            const nomeCloneMaterialGenetico = 'tabelaCloneMaterialGenetico';
            let gridCloneMaterialGeneticoApi = null;
            let gridCloneMaterialGeneticoScope = angular.element('#' + nomeCloneMaterialGenetico).scope();
            if ((typeof gridCloneMaterialGeneticoScope.listaGridApi !== 'undefined') && gridCloneMaterialGeneticoScope.listaGridApi) {
                angular.forEach(gridCloneMaterialGeneticoScope.listaGridApi, (gridApi) => {
                    const grid = (typeof gridApi.grid !== 'undefined') && gridApi.grid ? gridApi.grid : null;
                    if (grid && (typeof grid.element !== 'undefined') && grid.element) {
                        const tabelasEl = angular.element(grid.element).parents('tabela');
                        if (tabelasEl && (tabelasEl.length > 0) && (tabelasEl.first().attr('id') === nomeCloneMaterialGenetico)) {
                            gridCloneMaterialGeneticoApi = gridApi;
                        }
                    }
                });
            }
            if (gridCloneMaterialGeneticoApi && (typeof gridCloneMaterialGeneticoApi.selection !== 'undefined') && gridCloneMaterialGeneticoApi.selection) {
                gridCloneMaterialGeneticoApi.selection.selectRow(row.entity);
            }
        }
    }
    limparFiltroCloneMaterialGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            this.cloneMaterialGeneticoSelecionado = null;
            this.cloneMaterialGeneticoCarregado = false;
        });
    }
    selecionarCloneMaterialGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            let ctrl = this;
            if (ctrl && ctrl.constructor && ctrl.constructor.name) {
                if (ctrl.constructor.name === 'TabelaController') {
                    ctrl = ctrl.$scope.$parent.$ctrl;
                }
            }
            ctrl.filtrarCloneMaterialGenetico();
        });
    }
    filtrarCloneMaterialGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                let cloneMaterialGenetico = null;
                const nomeTabelaCloneMaterialGenetico = 'tabelaCloneMaterialGenetico';
                let gridCloneMaterialGeneticoApi = null;
                let gridCloneMaterialGeneticoScope = angular.element('#' + nomeTabelaCloneMaterialGenetico).scope();
                if ((typeof gridCloneMaterialGeneticoScope.listaGridApi !== 'undefined') && gridCloneMaterialGeneticoScope.listaGridApi) {
                    angular.forEach(gridCloneMaterialGeneticoScope.listaGridApi, (gridApi) => {
                        const grid = (typeof gridApi.grid !== 'undefined') && gridApi.grid ? gridApi.grid : null;
                        if (grid && typeof (grid.element !== 'undefined') && grid.element) {
                            const tabelasEl = angular.element(grid.element).parents('tabela');
                            if (tabelasEl && (tabelasEl.length > 0) && (tabelasEl.first().attr('id') === nomeTabelaCloneMaterialGenetico)) {
                                gridCloneMaterialGeneticoApi = gridApi;
                            }
                        }
                    });
                }
                if (gridCloneMaterialGeneticoApi) {
                    const clonesMaterialGeneticoSelecionados = gridCloneMaterialGeneticoApi.selection.getSelectedRows();
                    if ((typeof clonesMaterialGeneticoSelecionados !== 'undefined') && clonesMaterialGeneticoSelecionados) {
                        angular.forEach(clonesMaterialGeneticoSelecionados, (obj) => {
                            if (!cloneMaterialGenetico) {
                                cloneMaterialGenetico = obj;
                            }
                        });
                    }
                }
                if (cloneMaterialGenetico && (typeof cloneMaterialGenetico.id !== 'undefined') && cloneMaterialGenetico.id) {
                    if ((typeof ctrl.cloneMaterialGeneticoSelecionado !== 'undefined') && ctrl.cloneMaterialGeneticoSelecionado &&
                        (typeof ctrl.cloneMaterialGeneticoSelecionado.id !== 'undefined') && ctrl.cloneMaterialGeneticoSelecionado.id) {
                        if (ctrl.cloneMaterialGeneticoSelecionado.id !== cloneMaterialGenetico.id) {
                            yield ctrl.limparFiltroCloneMaterialGenetico();
                            ctrl.cloneMaterialGeneticoSelecionado = cloneMaterialGenetico;
                            ctrl.cloneMaterialGeneticoCarregado = false;
                        }
                    }
                    else {
                        yield ctrl.limparFiltroCloneMaterialGenetico();
                        ctrl.cloneMaterialGeneticoSelecionado = cloneMaterialGenetico;
                        ctrl.cloneMaterialGeneticoCarregado = false;
                    }
                }
                else {
                    if (ctrl.cloneMaterialGeneticoSelecionado || ctrl.cloneMaterialGeneticoCarregado) {
                        yield ctrl.limparFiltroCloneMaterialGenetico();
                        ctrl.cloneMaterialGeneticoCarregado = false;
                    }
                }
                ctrl.scopeApply();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
        });
    }
}
exports.CloneMaterialGeneticoController = CloneMaterialGeneticoController;
CloneMaterialGeneticoController.$inject = ['$scope', '$injector'];
