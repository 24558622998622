"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnexoTesteGenetico = void 0;
const Base_1 = require("./Base");
class AnexoTesteGenetico extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.id = null;
        this.codigo = null;
        this.testeGenetico = null;
        this.descricao = null;
        this.anexos = [];
        this.observacoes = null;
        this.usuarioInclusao = null;
        this.dataHoraInclusao = null;
        this.usuarioAlteracao = null;
        this.dataHoraAlteracao = null;
    }
}
exports.AnexoTesteGenetico = AnexoTesteGenetico;
