"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FinalidadeMultiplicacao = void 0;
const Base_1 = require("./Base");
class FinalidadeMultiplicacao extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.descricao = null;
    }
}
exports.FinalidadeMultiplicacao = FinalidadeMultiplicacao;
