"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LancamentoRota = void 0;
const RotaFactory_1 = require("../../fabricas/RotaFactory");
const LancamentoModulo_1 = require("./LancamentoModulo");
const PomarBancoConservacaoController_1 = require("./paginas/pomarBancoConservacao/PomarBancoConservacaoController");
const MovimentacaoEstoqueController_1 = require("./paginas/movimentacaoEstoque/MovimentacaoEstoqueController");
const ControleManejoPomarController_1 = require("./paginas/controleManejoPomar/ControleManejoPomarController");
const FenologiaPomarController_1 = require("./paginas/fenologiaPomar/FenologiaPomarController");
const ControleDialelosEstoqueSementesController_1 = require("./paginas/controleDialelosEstoqueSementes/ControleDialelosEstoqueSementesController");
const TesteCampoController_1 = require("./paginas/testeCampo/TesteCampoController");
class LancamentoRota {
    constructor($stateProvider) {
        this.$stateProvider = $stateProvider;
        this.rota = null;
        this.rota = new RotaFactory_1.RotaFactory(this.$stateProvider);
        this.iniciar();
    }
    iniciar() {
        this.registrar();
    }
    registrar() {
        this.rota.modulo(LancamentoModulo_1.LancamentoModulo);
        const pomarBancoConservacao = {
            controladora: PomarBancoConservacaoController_1.PomarBancoConservacaoController,
            nome: 'pomarBancoConservacao',
            titulo: 'Pomares e bancos de conservação',
            autenticar: true,
            template: require('../../modulos/lancamento/paginas/pomarBancoConservacao/lista.html')
        };
        this.rota.addListar(pomarBancoConservacao);
        pomarBancoConservacao.titulo = 'Visualizar Pomar e banco de conservação';
        pomarBancoConservacao.template = null;
        this.rota.addVisualizar(pomarBancoConservacao);
        pomarBancoConservacao.titulo = 'Adicionar novo Pomar e banco de conservação';
        pomarBancoConservacao.template = null;
        this.rota.addCriar(pomarBancoConservacao);
        pomarBancoConservacao.titulo = 'Atualizar Pomar e banco de conservação';
        pomarBancoConservacao.template = null;
        this.rota.addEditar(pomarBancoConservacao);
        const movimentacaoEstoque = {
            controladora: MovimentacaoEstoqueController_1.MovimentacaoEstoqueController,
            nome: 'movimentacaoEstoque',
            titulo: 'Movimentação de Estoque',
            autenticar: true,
            template: require('../../modulos/lancamento/paginas/movimentacaoEstoque/lista.html')
        };
        this.rota.addListar(movimentacaoEstoque);
        movimentacaoEstoque.titulo = 'Visualizar Movimentação de Estoque';
        movimentacaoEstoque.template = null;
        this.rota.addVisualizar(movimentacaoEstoque);
        movimentacaoEstoque.titulo = 'Adicionar nova Movimentação de Estoque';
        movimentacaoEstoque.template = null;
        this.rota.addCriar(movimentacaoEstoque);
        movimentacaoEstoque.titulo = 'Atualizar Movimentação de Estoque';
        movimentacaoEstoque.template = null;
        this.rota.addEditar(movimentacaoEstoque);
        const controleManejoPomar = {
            controladora: ControleManejoPomarController_1.ControleManejoPomarController,
            nome: 'controleManejoPomar',
            titulo: 'Controle Manejo Pomar',
            autenticar: true,
            template: require('../../modulos/lancamento/paginas/controleManejoPomar/lista.html')
        };
        this.rota.addListar(controleManejoPomar);
        controleManejoPomar.titulo = 'Visualizar Controle Manejo Pomar';
        controleManejoPomar.template = null;
        this.rota.addVisualizar(controleManejoPomar);
        controleManejoPomar.titulo = 'Adicionar novo Controle Manejo Pomar';
        controleManejoPomar.template = null;
        this.rota.addCriar(controleManejoPomar);
        controleManejoPomar.titulo = 'Atualizar Controle Manejo Pomar';
        controleManejoPomar.template = null;
        this.rota.addEditar(controleManejoPomar);
        const fenologiaPomar = {
            controladora: FenologiaPomarController_1.FenologiaPomarController,
            nome: 'fenologiaPomar',
            titulo: 'Fenologia Pomar',
            autenticar: true,
            template: require('../../modulos/lancamento/paginas/fenologiaPomar/lista.html')
        };
        this.rota.addListar(fenologiaPomar);
        fenologiaPomar.titulo = 'Visualizar Fenologia Pomar';
        fenologiaPomar.template = null;
        this.rota.addVisualizar(fenologiaPomar);
        fenologiaPomar.titulo = 'Adicionar nova Fenologia Pomar';
        fenologiaPomar.template = null;
        this.rota.addCriar(fenologiaPomar);
        fenologiaPomar.titulo = 'Atualizar Fenologia Pomar';
        fenologiaPomar.template = null;
        this.rota.addEditar(fenologiaPomar);
        const controleDialelosEstoqueSementes = {
            controladora: ControleDialelosEstoqueSementesController_1.ControleDialelosEstoqueSementesController,
            nome: 'controleDialelosEstoqueSementes',
            titulo: 'Controle Dialelos e Estoque de Sementes',
            autenticar: true,
            template: require('../../modulos/lancamento/paginas/controleDialelosEstoqueSementes/lista.html')
        };
        this.rota.addListar(controleDialelosEstoqueSementes);
        controleDialelosEstoqueSementes.titulo = 'Visualizar Controle de Dialelos';
        controleDialelosEstoqueSementes.template = null;
        this.rota.addVisualizar(controleDialelosEstoqueSementes);
        controleDialelosEstoqueSementes.titulo = 'Adicionar novo Controle de Dialelos';
        controleDialelosEstoqueSementes.template = null;
        this.rota.addCriar(controleDialelosEstoqueSementes);
        controleDialelosEstoqueSementes.titulo = 'Atualizar Controle de Dialelos';
        controleDialelosEstoqueSementes.template = null;
        this.rota.addEditar(controleDialelosEstoqueSementes);
        const testeCampo = {
            controladora: TesteCampoController_1.TesteCampoController,
            nome: 'testeCampo',
            titulo: 'Testes de campo',
            autenticar: true,
            template: require('../../modulos/lancamento/paginas/testeCampo/lista.html')
        };
        this.rota.addListar(testeCampo);
        testeCampo.titulo = 'Visualizar Teste de campo';
        testeCampo.template = null;
        this.rota.addVisualizar(testeCampo);
        testeCampo.titulo = 'Adicionar novo Teste de campo';
        testeCampo.template = null;
        this.rota.addCriar(testeCampo);
        testeCampo.titulo = 'Atualizar Teste de campo';
        testeCampo.template = null;
        this.rota.addEditar(testeCampo);
    }
}
exports.LancamentoRota = LancamentoRota;
LancamentoRota.$inject = ['$stateProvider'];
