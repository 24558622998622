"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LancamentoModulo = void 0;
const angular_1 = require("angular");
const LancamentoRota_1 = require("./LancamentoRota");
class LancamentoModulo {
    static iniciar() {
        angular_1.module(LancamentoModulo.id, []).config(LancamentoRota_1.LancamentoRota);
    }
    static carregar() {
        return angular_1.module(LancamentoModulo.id);
    }
}
exports.LancamentoModulo = LancamentoModulo;
LancamentoModulo.id = 'lancamento';
