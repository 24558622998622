"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabSolicitacaoAnalisesController = void 0;
const angular = require("angular");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const UiGridConstrutor_1 = require("../../../app/construtores/UiGridConstrutor");
const AbstratoController_1 = require("../../../app/AbstratoController");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const ICRUDOperacao_1 = require("../../../app/interfaces/ICRUDOperacao");
const config_app_1 = require("../../../../../config/config.app");
const sweetalert2_1 = require("sweetalert2");
const LabSolicitacaoAnalises_1 = require("../../../../../modelos/LabSolicitacaoAnalises");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const Pomar_1 = require("../../../../../modelos/Pomar");
const CampoIndicadorFotos_1 = require("../../../app/componentes/campo-indicador/CampoIndicadorFotos");
const DispositivoUtil_1 = require("../../../../../util/DispositivoUtil");
class LabSolicitacaoAnalisesController extends AbstratoController_1.AbstratoController {
    constructor($scope, $injector) {
        super($scope, $injector, new LabSolicitacaoAnalises_1.LabSolicitacaoAnalises());
        this.path = '';
        this.caminho = '';
        this.tipoOperacao = null;
        this.listasFotos = null;
        this.modalPomaresLista = false;
        this.posicaoTopoAntesModal = null;
        this.especies = [];
        this.materiaisGeneticos = [];
        this.listaPomares = [];
        this.geracoes = [];
        this.safras = [];
        this.lotes = [];
        this.tiposAmostra = [];
        this.laboratorios = [];
        this.listaStatusAnalise = [];
        this.areaSolicitanteComercial = LabSolicitacaoAnalises_1.LabSolicitacaoAnalises.AREA_SOLICITANTE_COMERCIAL;
        this.areaSolicitanteSilvicultura = LabSolicitacaoAnalises_1.LabSolicitacaoAnalises.AREA_SOLICITANTE_SILVICULTURA;
        this.tipoAnaliseGerminacao = LabSolicitacaoAnalises_1.LabSolicitacaoAnalises.TIPO_ANALISE_GERMINACAO;
        this.tipoAnaliseUmidade = LabSolicitacaoAnalises_1.LabSolicitacaoAnalises.TIPO_ANALISE_UMIDADE;
        this.tipoOperacao = ICRUDOperacao_1.CRUDOperacao;
        this.listasFotos = new CampoIndicadorFotos_1.CampoIndicadorFotos($scope);
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.path = config_app_1.config.api.caminho;
            this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.LAB_SOLICITACAO_ANALISES;
            this.modalPomaresLista = false;
            if (this.operacao !== this.tipoOperacao.LISTAR) {
                this.carregarListaPomares();
            }
            if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR ||
                this.operacao === ICRUDOperacao_1.CRUDOperacao.EDITAR ||
                this.operacao === ICRUDOperacao_1.CRUDOperacao.DUPLICAR) {
                this.especies = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESPECIE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESPECIE)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.especies = resposta;
                    }
                });
                const promiseEspecies = this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESPECIE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESPECIE)
                    .filtro({})
                    .listar();
                promiseEspecies.then((resposta) => {
                    this.especies.splice(0, this.especies.length);
                    this.especies.push(...resposta);
                    console.log('Espécies carregadas: ' + this.especies.length);
                    this.scopeApply();
                });
                this.materiaisGeneticos = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MATERIAL_GENETICO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.materiaisGeneticos = resposta;
                    }
                });
                this.listaPomares = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POMAR)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.listaPomares = resposta;
                    }
                });
                this.geracoes = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.GERACAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.GERACAO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.geracoes = resposta;
                    }
                });
                this.safras = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.SAFRA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.SAFRA)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.safras = resposta;
                    }
                });
                this.lotes = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.LOTE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LOTE)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.lotes = resposta;
                    }
                });
                this.tiposAmostra = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TIPO_AMOSTRA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TIPO_AMOSTRA)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.tiposAmostra = resposta;
                    }
                });
                this.laboratorios = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.LABORATORIO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LABORATORIO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.laboratorios = resposta;
                    }
                });
                this.listaStatusAnalise = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.STATUS_ANALISE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.STATUS_ANALISE)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.listaStatusAnalise = resposta;
                    }
                });
            }
            this.$scope.$apply();
        });
    }
    carregarListaPomares() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.listaPomares = new Array();
            const promiseListaPomares = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.POMAR)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POMAR)
                .filtro({})
                .listar();
            promiseListaPomares.then((resposta) => {
                if (typeof resposta !== 'undefined' && resposta) {
                    ctrl.listaPomares = [...resposta];
                }
                console.log('Pomares carregados: ' + this.listaPomares.length);
                ctrl.scopeApply();
            });
        });
    }
    salvar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.modalPomaresLista = false;
                if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_SOLICITACAO_ANALISES)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_SOLICITACAO_ANALISES)
                        .criar(this.modelo);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_SOLICITACAO_ANALISES)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_SOLICITACAO_ANALISES)
                        .atualizar(this.modelo);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir uma novoa solicitação de análise ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir uma nova solicitação de análise ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    visualizar(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const labSolicitacaoAnalise = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_SOLICITACAO_ANALISES)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_SOLICITACAO_ANALISES)
                    .id(id)
                    .ver();
                labSolicitacaoAnalise.criacao = new Date(labSolicitacaoAnalise.criacao);
                labSolicitacaoAnalise.data = new Date(labSolicitacaoAnalise.data);
                labSolicitacaoAnalise.dataRecebimento = new Date(labSolicitacaoAnalise.dataRecebimento);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return labSolicitacaoAnalise;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    remover(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const labSolicitacaoAnalise = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_SOLICITACAO_ANALISES)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_SOLICITACAO_ANALISES)
                    .id(id)
                    .remover();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (labSolicitacaoAnalise) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const labSolicitacaoAnalise = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_SOLICITACAO_ANALISES)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_SOLICITACAO_ANALISES)
                    .id(this.modelo.id)
                    .restaurar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (labSolicitacaoAnalise) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosLista(filtro = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                this.path = config_app_1.config.api.caminho;
                this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.LAB_SOLICITACAO_ANALISES;
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                var auxColunas = Array();
                auxColunas.push({
                    name: 'codigo',
                    displayName: 'Código',
                    width: 90,
                });
                auxColunas.push({
                    name: 'areaSolicitante',
                    displayName: 'Área de Solicitante',
                    width: 200
                });
                auxColunas.push({
                    name: 'nomeSolicitante',
                    displayName: 'Nome de Solicitante',
                    width: 200
                });
                auxColunas.push({
                    name: 'data',
                    displayName: 'Data',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 190,
                });
                auxColunas.push({
                    name: 'especie.descricao',
                    displayName: 'Espécie',
                    width: 140,
                });
                auxColunas.push({
                    name: 'pomar.descricao',
                    displayName: 'Pomar',
                    width: 140,
                });
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
                const lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_SOLICITACAO_ANALISES)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_SOLICITACAO_ANALISES)
                    .filtro(filtro)
                    .listar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    filtrar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    limparFiltro() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    exibirModalPomaresLista() {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.modelo === 'undefined' || !this.modelo) {
                return;
            }
            if (typeof this.modelo.listaPomares === 'undefined' ||
                this.modelo.listaPomares === null) {
                this.modelo.listaPomares = new Array();
            }
            try {
                try {
                    this.posicaoTopoAntesModal = angular.element(window).scrollTop();
                }
                catch (ex) {
                    this.posicaoTopoAntesModal = null;
                    console.log(ex);
                }
                this.carregarListaPomares();
                this.modalPomaresLista = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalPomaresLista() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalPomaresLista = false;
            angular.element('body').css('overflow-y', '');
            if (typeof this.modelo !== 'undefined' &&
                this.modelo &&
                typeof this.modelo.listaPomares !== 'undefined' &&
                this.modelo.listaPomares) {
                this.modelo.listaPomares = this.modelo.listaPomares;
            }
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    moverPomarListaParaCima(key) {
        if (typeof this.modelo === 'undefined' ||
            !this.modelo ||
            typeof this.modelo.listaPomares === 'undefined' ||
            !this.modelo.listaPomares) {
            return;
        }
        if (key !== null && key > 0 && key <= this.modelo.listaPomares.length - 1) {
            let oldKey = key;
            let newKey = key - 1;
            this.modelo.listaPomares.splice(newKey, 0, this.modelo.listaPomares.splice(oldKey, 1)[0]);
            this.scopeApply();
        }
    }
    moverPomarListaParaBaixo(key) {
        if (typeof this.modelo === 'undefined' ||
            !this.modelo ||
            typeof this.modelo.listaPomares === 'undefined' ||
            !this.modelo.listaPomares) {
            return;
        }
        if (key != null && key >= 0 && key < this.modelo.listaPomares.length - 1) {
            let oldKey = key;
            let newKey = key + 1;
            this.modelo.listaPomares.splice(newKey, 0, this.modelo.listaPomares.splice(oldKey, 1)[0]);
            this.scopeApply();
        }
    }
    adicionarPomarLista() {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.modelo === 'undefined' || !this.modelo) {
                return;
            }
            if (typeof this.modelo.listaPomares === 'undefined' ||
                this.modelo.listaPomares === null) {
                this.modelo.listaPomares = new Array();
            }
            this.modelo.listaPomares.push(new Pomar_1.Pomar());
            this.scopeApply();
        });
    }
    removerPomarLista(key) {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.modelo === 'undefined' || !this.modelo) {
                return;
            }
            if (typeof this.modelo.listaPomares === 'undefined' ||
                this.modelo.listaPomares === null) {
                this.modelo.listaPomares = new Array();
            }
            if (typeof key !== 'undefined' &&
                key !== null &&
                this.modelo.listaPomares.length > 0 &&
                key <= this.modelo.listaPomares.length - 1) {
                this.modelo.listaPomares.splice(key, 1);
                this.scopeApply();
            }
        });
    }
    verFoto(foto) {
        return __awaiter(this, void 0, void 0, function* () {
            DispositivoUtil_1.DispositivoUtil.verFoto(foto);
        });
    }
}
exports.LabSolicitacaoAnalisesController = LabSolicitacaoAnalisesController;
LabSolicitacaoAnalisesController.$inject = ['$scope', '$injector'];
