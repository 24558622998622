"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MapaPomar = void 0;
const Base_1 = require("./Base");
class MapaPomar extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.id = null;
        this.codigo = null;
        this.pomar = null;
        this.pomarCodigo = null;
        this.titulo = null;
        this.latitude = null;
        this.longitude = null;
        this.mapa = [];
        this.observacoes = null;
    }
}
exports.MapaPomar = MapaPomar;
