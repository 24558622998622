"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProducaoMudaClonal = void 0;
const Base_1 = require("./Base");
class ProducaoMudaClonal extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.testeLocal = null;
        this.anoTeste = null;
        this.materialGeneticoTratamento = null;
        this.especie = null;
        this.bloco = null;
        this.parcela = null;
        this.numArvore = null;
        this.codSelecao = null;
        this.codClone = null;
    }
}
exports.ProducaoMudaClonal = ProducaoMudaClonal;
