"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReceitaSolucao = void 0;
const Base_1 = require("./Base");
class ReceitaSolucao extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.descricao = null;
        this.produtos = [];
    }
}
exports.ReceitaSolucao = ReceitaSolucao;
