"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProducaoMudaClonalEucalyptusResgateMacroestacasController = void 0;
const angular = require("angular");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const UiGridConstrutor_1 = require("../../../app/construtores/UiGridConstrutor");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const AbstratoController_1 = require("../../../app/AbstratoController");
const ICRUDOperacao_1 = require("../../../app/interfaces/ICRUDOperacao");
const config_app_1 = require("../../../../../config/config.app");
const sweetalert2_1 = require("sweetalert2");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const ProducaoMudaClonalEucalyptusResgateMacroestacas_1 = require("../../../../../modelos/ProducaoMudaClonalEucalyptusResgateMacroestacas");
const ProducaoMudaClonalEucalyptusResgateMacroestacasCorteRaso_1 = require("../../../../../modelos/ProducaoMudaClonalEucalyptusResgateMacroestacasCorteRaso");
const DispositivoUtil_1 = require("../../../../../util/DispositivoUtil");
const ProducaoMudaClonalEucalyptusResgateMacroestacasBrotacoes_1 = require("../../../../../modelos/ProducaoMudaClonalEucalyptusResgateMacroestacasBrotacoes");
const ProducaoMudaClonalEucalyptusResgateMacroestacasAdubacao_1 = require("../../../../../modelos/ProducaoMudaClonalEucalyptusResgateMacroestacasAdubacao");
const ProducaoMudaClonalEucalyptusResgateMacroestacasControlePragas_1 = require("../../../../../modelos/ProducaoMudaClonalEucalyptusResgateMacroestacasControlePragas");
const ProducaoMudaClonalEucalyptusResgateMacroestacasConfeccao_1 = require("../../../../../modelos/ProducaoMudaClonalEucalyptusResgateMacroestacasConfeccao");
const ProducaoMudaClonalEucalyptusResgateMacroestacasAvaliacao_1 = require("../../../../../modelos/ProducaoMudaClonalEucalyptusResgateMacroestacasAvaliacao");
const chart_js_1 = require("chart.js");
const HistoricoEstoqueProdutoFertilizante_1 = require("../../../../../modelos/HistoricoEstoqueProdutoFertilizante");
const HistoricoEstoqueProdutoDefensivo_1 = require("../../../../../modelos/HistoricoEstoqueProdutoDefensivo");
class ProducaoMudaClonalEucalyptusResgateMacroestacasController extends AbstratoController_1.AbstratoController {
    constructor($scope, $injector) {
        super($scope, $injector, new ProducaoMudaClonalEucalyptusResgateMacroestacas_1.ProducaoMudaClonalEucalyptusResgateMacroestacas());
        this.path = '';
        this.caminho = '';
        this.tipoOperacao = null;
        this.analisesFiltradas = false;
        this.dadosCorteRaso = null;
        this.dadosBrotacoes = null;
        this.dadosAdubacao = null;
        this.dadosControlePragas = null;
        this.dadosConfeccao = null;
        this.dadosAvaliacao = null;
        this.posicaoTopoAntesModal = null;
        this.modalCorteRaso = false;
        this.modalBrotacoes = false;
        this.modalAdubacao = false;
        this.modalControlePragas = false;
        this.modalConfeccao = false;
        this.modalAvaliacao = false;
        this.listaOpcoesCorteRaso = null;
        this.listaOpcoesBrotacoes = null;
        this.listaOpcoesAdubacao = null;
        this.listaOpcoesControlePragas = null;
        this.listaOpcoesConfeccao = null;
        this.listaOpcoesAvaliacao = null;
        this.listaOpcoesRelatorio = null;
        this.brotacoes = [];
        this.fertilizantes = [];
        this.defensivos = [];
        this.coletas = [];
        this.contasDepositoSap = [];
        this.centrosCustoSap = [];
        this.mudasEucalyptus = [];
        this.minijardimEucalyptus = [];
        this.graficoResumoGeral = null;
        this.listaAvaliacaoMacroestacas = [];
        this.listaConfeccaoMacroestacas = [];
        this.numTestes = 0;
        this.numArvores = 0;
        this.numResgatadas = 0;
        this.numEnraizamento = 0;
        this.numMinijardim = 0;
        this.perResgate = 0;
        this.perEnraizamento = 0;
        this.filtroRelatorio = {
            anos: [],
            ano: null,
            testes: [],
            teste: null
        };
        this.tipoOperacao = ICRUDOperacao_1.CRUDOperacao;
        this.analisesFiltradas = false;
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.analisesFiltradas = false;
            this.modalCorteRaso = false;
            this.dadosCorteRaso = null;
            this.modalBrotacoes = false;
            this.dadosBrotacoes = null;
            this.modalAdubacao = false;
            this.dadosAdubacao = null;
            this.modalControlePragas = false;
            this.dadosControlePragas = null;
            this.modalConfeccao = false;
            this.dadosConfeccao = null;
            this.modalAvaliacao = false;
            this.dadosAvaliacao = null;
            this.numTestes = 0;
            this.numArvores = 0;
            this.numResgatadas = 0;
            this.numEnraizamento = 0;
            this.numMinijardim = 0;
            this.perResgate = 0;
            this.perEnraizamento = 0;
            this.path = config_app_1.config.api.caminho;
            this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS;
            if (this.operacao === ICRUDOperacao_1.CRUDOperacao.LISTAR) {
                this.listaOpcoesCorteRaso = {
                    data: [],
                    columnDefs: []
                };
                this.listaOpcoesBrotacoes = {
                    data: [],
                    columnDefs: []
                };
                this.listaOpcoesAdubacao = {
                    data: [],
                    columnDefs: []
                };
                this.listaOpcoesControlePragas = {
                    data: [],
                    columnDefs: []
                };
                this.listaOpcoesConfeccao = {
                    data: [],
                    columnDefs: []
                };
                this.listaOpcoesAvaliacao = {
                    data: [],
                    columnDefs: []
                };
                this.listaOpcoesRelatorio = {
                    data: [],
                    columnDefs: []
                };
                this.brotacoes = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.CLASSIFICACAO_BROTACAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CLASSIFICACAO_BROTACAO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.brotacoes = resposta;
                    }
                });
                this.fertilizantes = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.fertilizantes = resposta;
                    }
                });
                this.defensivos = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_DEFENSIVO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_DEFENSIVO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.defensivos = resposta;
                    }
                });
                this.coletas = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.COLETA_MACROESTACA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.COLETA_MACROESTACA)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.coletas = resposta;
                    }
                });
                this.contasDepositoSap = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.CONTA_DEPOSITO_SAP)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CONTA_DEPOSITO_SAP)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.contasDepositoSap = resposta;
                    }
                });
                this.centrosCustoSap = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.CENTRO_CUSTO_SAP)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CENTRO_CUSTO_SAP)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.centrosCustoSap = resposta;
                    }
                });
                this.mudasEucalyptus = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.mudasEucalyptus = resposta;
                    }
                });
                this.minijardimEucalyptus = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.minijardimEucalyptus = resposta;
                    }
                });
            }
            yield this.filtrarAnalises(true);
            this.$scope.$apply();
        });
    }
    salvar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.modalCorteRaso = false;
                this.dadosCorteRaso = null;
                this.modalBrotacoes = false;
                this.dadosBrotacoes = null;
                this.modalAdubacao = false;
                this.dadosAdubacao = null;
                this.modalControlePragas = false;
                this.dadosControlePragas = null;
                this.modalConfeccao = false;
                this.dadosConfeccao = null;
                this.modalAvaliacao = false;
                this.dadosAvaliacao = null;
                if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS)
                        .criar(this.modelo);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS)
                        .atualizar(this.modelo);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir um novo Resgate de Macroestacas ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir um novo Resgate de Macroestacas ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    visualizar(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const producaoMudaClonalEucalyptusResgateMacroestacas = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS)
                    .id(id)
                    .ver();
                producaoMudaClonalEucalyptusResgateMacroestacas.criacao = new Date(producaoMudaClonalEucalyptusResgateMacroestacas.criacao);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return producaoMudaClonalEucalyptusResgateMacroestacas;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    remover(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const producaoMudaClonalEucalyptusResgateMacroestacas = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS)
                    .id(id)
                    .remover();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (producaoMudaClonalEucalyptusResgateMacroestacas) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const producaoMudaClonalEucalyptusResgateMacroestacas = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS)
                    .id(this.modelo.id)
                    .restaurar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (producaoMudaClonalEucalyptusResgateMacroestacas) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosLista(filtro = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                this.path = config_app_1.config.api.caminho;
                this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS;
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                var auxColunas = Array();
                auxColunas.push({
                    name: 'codigo',
                    displayName: 'Código',
                    width: 90,
                });
                auxColunas.push({
                    name: 'testeLocal',
                    displayName: 'Teste/Local',
                    width: 190,
                });
                auxColunas.push({
                    name: 'anoTeste',
                    displayName: 'Ano teste',
                    width: 100,
                });
                auxColunas.push({
                    name: 'materialGeneticoTratamento',
                    displayName: 'Material Genético/Tratamento',
                    width: 140,
                });
                auxColunas.push({
                    name: 'especie',
                    displayName: 'Espécie',
                    width: 140,
                });
                auxColunas.push({
                    name: 'bloco',
                    displayName: 'Bloco',
                    width: 100,
                });
                auxColunas.push({
                    name: 'parcela',
                    displayName: 'Parcela',
                    width: 100,
                });
                auxColunas.push({
                    name: 'numArvore',
                    displayName: 'Nº Árvore',
                    width: 100,
                });
                auxColunas.push({
                    name: 'codSelecao',
                    displayName: 'Cód. Seleção',
                    width: 100,
                });
                auxColunas.push({
                    name: 'codClone',
                    displayName: 'Cód. Clone',
                    width: 100,
                });
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
                const lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS)
                    .filtro(filtro)
                    .listar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosListaCorteRaso() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let filtro = {
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    limiteRegistros: 100000
                };
                let lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CORTE_RASO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CORTE_RASO)
                    .filtro(filtro)
                    .listar();
                let auxColunas = new Array();
                var corteRasoTemplate = '<div ng-if="row.entity.corteRaso" style="margin-top: 5px; margin-left: 5px;">Sim</div>' +
                    '<div ng-if="!row.entity.corteRaso" style="margin-top: 5px; margin-left: 5px;">Não</div>';
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    posicao: 1,
                    cellClass: 'text-center',
                    cellTemplate: `
                  <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                      <button type="button" class="btn btn-icon bx bxs-edit" style="padding-right: .4rem"
                          onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                          ng-click="grid.appScope.getParentController().abrirModalCorteRaso(row.entity.id)">
                      </button>
                      <button type="button" class="btn btn-icon bx bx-trash" style="padding-left: .4rem"
                          onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                          ng-click="grid.appScope.getParentController().excluirCorteRaso(row.entity.id)">
                      </button>
                  </div> `
                });
                auxColunas.push({
                    name: 'data',
                    displayName: 'Data',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 120,
                });
                auxColunas.push({
                    name: 'corteRaso',
                    displayName: 'Corte raso/anelamento',
                    width: 90,
                    cellTemplate: corteRasoTemplate,
                });
                auxColunas.push({
                    name: 'clonalEucalyptus.clone.clone',
                    displayName: 'Muda Eucalyptus',
                    width: 120,
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosListaBrotacoes() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let filtro = {
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    limiteRegistros: 100000
                };
                let lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_BROTACAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_BROTACAO)
                    .filtro(filtro)
                    .listar();
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    posicao: 1,
                    cellClass: 'text-center',
                    cellTemplate: `
                  <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                      <button type="button" class="btn btn-icon bx bxs-edit" style="padding-right: .4rem"
                          onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                          ng-click="grid.appScope.getParentController().abrirModalBrotacoes(row.entity.id)">
                      </button>
                      <button type="button" class="btn btn-icon bx bx-trash" style="padding-left: .4rem"
                          onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                          ng-click="grid.appScope.getParentController().excluirBrotacoes(row.entity.id)">
                      </button>
                  </div> `
                });
                auxColunas.push({
                    name: 'data',
                    displayName: 'Data',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 120,
                });
                auxColunas.push({
                    name: 'classificacao.descricao',
                    displayName: 'Classificação',
                    width: 120,
                });
                auxColunas.push({
                    name: 'clonalEucalyptus.clone.clone',
                    displayName: 'Muda Eucalyptus',
                    width: 120,
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosListaAdubacao() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let filtro = {
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    limiteRegistros: 100000
                };
                let lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_ADUBACAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_ADUBACAO)
                    .filtro(filtro)
                    .listar();
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    posicao: 1,
                    cellClass: 'text-center',
                    cellTemplate: `
                  <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                      <button type="button" class="btn btn-icon bx bxs-edit" style="padding-right: .4rem"
                          onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                          ng-click="grid.appScope.getParentController().abrirModalAdubacao(row.entity.id)">
                      </button>
                      <button type="button" class="btn btn-icon bx bx-trash" style="padding-left: .4rem"
                          onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                          ng-click="grid.appScope.getParentController().excluirAdubacao(row.entity.id)">
                      </button>
                  </div> `
                });
                auxColunas.push({
                    name: 'data',
                    displayName: 'Data',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 120,
                });
                auxColunas.push({
                    name: 'produtoFertilizante.descricao',
                    displayName: 'Produto',
                    width: 120,
                });
                auxColunas.push({
                    name: 'quantidade',
                    displayName: 'Quantidade',
                    width: 100,
                });
                auxColunas.push({
                    name: 'dosagem',
                    displayName: 'Dosagem',
                    width: 100,
                });
                auxColunas.push({
                    name: 'contaDepositoSap.descricao',
                    displayName: 'Conta/Depósito SAP',
                    width: 120,
                });
                auxColunas.push({
                    name: 'centroCustoSap.descricao',
                    displayName: 'Centro de Custo SAP',
                    width: 120,
                });
                auxColunas.push({
                    name: 'clonalEucalyptus.clone.clone',
                    displayName: 'Muda Eucalyptus',
                    width: 120,
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosListaControlePragas() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let filtro = {
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    limiteRegistros: 100000
                };
                let lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CONTROLE_PRAGAS)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CONTROLE_PRAGAS)
                    .filtro(filtro)
                    .listar();
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    posicao: 1,
                    cellClass: 'text-center',
                    cellTemplate: `
                  <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                      <button type="button" class="btn btn-icon bx bxs-edit" style="padding-right: .4rem"
                          onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                          ng-click="grid.appScope.getParentController().abrirModalControlePragas(row.entity.id)">
                      </button>
                      <button type="button" class="btn btn-icon bx bx-trash" style="padding-left: .4rem"
                          onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                          ng-click="grid.appScope.getParentController().excluirControlePragas(row.entity.id)">
                      </button>
                  </div> `
                });
                auxColunas.push({
                    name: 'data',
                    displayName: 'Data',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 120,
                });
                auxColunas.push({
                    name: 'produtoDefensivo.descricao',
                    displayName: 'Produto',
                    width: 120,
                });
                auxColunas.push({
                    name: 'quantidade',
                    displayName: 'Quantidade',
                    width: 100,
                });
                auxColunas.push({
                    name: 'contaDepositoSap.descricao',
                    displayName: 'Conta/Depósito SAP',
                    width: 120,
                });
                auxColunas.push({
                    name: 'centroCustoSap.descricao',
                    displayName: 'Centro de Custo SAP',
                    width: 120,
                });
                auxColunas.push({
                    name: 'clonalEucalyptus.clone.clone',
                    displayName: 'Muda Eucalyptus',
                    width: 120,
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosListaConfeccao() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.listaConfeccaoMacroestacas = [];
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let filtro = {
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    limiteRegistros: 100000
                };
                let lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CONFECCAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CONFECCAO)
                    .filtro(filtro)
                    .listar();
                this.listaConfeccaoMacroestacas = lista;
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    posicao: 1,
                    cellClass: 'text-center',
                    cellTemplate: `
                  <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                      <button type="button" class="btn btn-icon bx bxs-edit" style="padding-right: .4rem"
                          onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                          ng-click="grid.appScope.getParentController().abrirModalConfeccao(row.entity.id)">
                      </button>
                      <button type="button" class="btn btn-icon bx bx-trash" style="padding-left: .4rem"
                          onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                          ng-click="grid.appScope.getParentController().excluirConfeccao(row.entity.id)">
                      </button>
                  </div> `
                });
                auxColunas.push({
                    name: 'data',
                    displayName: 'Data',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 120,
                });
                auxColunas.push({
                    name: 'coleta.descricao',
                    displayName: 'Coleta',
                    width: 120,
                });
                auxColunas.push({
                    name: 'numEstacas',
                    displayName: 'Nº Estacas',
                    width: 120,
                });
                auxColunas.push({
                    name: 'tratamento.descricao',
                    displayName: 'Produto',
                    width: 120,
                });
                auxColunas.push({
                    name: 'quantidade',
                    displayName: 'Quantidade',
                    width: 100,
                });
                auxColunas.push({
                    name: 'contaDepositoSap.descricao',
                    displayName: 'Conta/Depósito SAP',
                    width: 120,
                });
                auxColunas.push({
                    name: 'centroCustoSap.descricao',
                    displayName: 'Centro de Custo SAP',
                    width: 120,
                });
                auxColunas.push({
                    name: 'clonalEucalyptus.clone.clone',
                    displayName: 'Muda Eucalyptus',
                    width: 120,
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosListaAvaliacao() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.listaAvaliacaoMacroestacas = [];
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let filtro = {
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    limiteRegistros: 100000
                };
                let lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_AVALIACAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_AVALIACAO)
                    .filtro(filtro)
                    .listar();
                this.listaAvaliacaoMacroestacas = lista;
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    posicao: 1,
                    cellClass: 'text-center',
                    cellTemplate: `
                  <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                      <button type="button" class="btn btn-icon bx bxs-edit" style="padding-right: .4rem"
                          onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                          ng-click="grid.appScope.getParentController().abrirModalAvaliacao(row.entity.id)">
                      </button>
                      <button type="button" class="btn btn-icon bx bx-trash" style="padding-left: .4rem"
                          onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                          ng-click="grid.appScope.getParentController().excluirAvaliacao(row.entity.id)">
                      </button>
                  </div> `
                });
                auxColunas.push({
                    name: 'data',
                    displayName: 'Data',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 120,
                });
                auxColunas.push({
                    name: 'qntEnraizadas',
                    displayName: 'Enraizadas (Qnt.)',
                    width: 120,
                });
                auxColunas.push({
                    name: 'clonalEucalyptus.clone.clone',
                    displayName: 'Muda Eucalyptus',
                    width: 120,
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosListaRelatorio() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                let listaRelatio = [];
                let listaFiltro = [];
                let listaFinal = [];
                let listaAvaliacoes = this.listaAvaliacaoMacroestacas;
                let listaMinijardim = this.minijardimEucalyptus;
                let listaConfeccao = this.listaConfeccaoMacroestacas;
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.numTestes = 0;
                this.numArvores = 0;
                this.numResgatadas = 0;
                this.numEnraizamento = 0;
                this.numMinijardim = 0;
                this.perResgate = 0;
                this.perEnraizamento = 0;
                angular.forEach(this.mudasEucalyptus, (muda) => {
                    if ((typeof muda.clone !== 'undefined') && muda.clone) {
                        let listaAvaliacoesMuda = listaAvaliacoes.filter((avaliacao) => {
                            return (avaliacao.clonalEucalyptus.clone.id === muda.clone.id);
                        });
                        let listaMinijardimMuda = listaMinijardim.filter((minijardim) => {
                            return (minijardim.producaoClone.clone.id === muda.clone.id);
                        });
                        let listaConfeccaoMuda = listaConfeccao.filter((confeccao) => {
                            return (confeccao.clonalEucalyptus.clone.id === muda.clone.id);
                        });
                        let itemRelatiorio = {
                            mudas: muda,
                            avaliacoes: listaAvaliacoesMuda,
                            minijardins: listaMinijardimMuda,
                            confeccoes: listaConfeccaoMuda
                        };
                        listaRelatio.push(itemRelatiorio);
                    }
                });
                listaFiltro = listaRelatio;
                if (((typeof this.filtroRelatorio.ano !== 'undefined') && this.filtroRelatorio.ano) &&
                    ((typeof this.filtroRelatorio.teste !== 'undefined') && this.filtroRelatorio.teste)) {
                    listaFiltro = listaRelatio.filter((itemRelatorio) => {
                        return (((typeof itemRelatorio.mudas.anoResgate !== 'undefined') && itemRelatorio.mudas.anoResgate &&
                            itemRelatorio.mudas.anoResgate === this.filtroRelatorio.ano) &&
                            ((typeof itemRelatorio.mudas.codTeste !== 'undefined') && itemRelatorio.mudas.codTeste &&
                                itemRelatorio.mudas.codTeste === this.filtroRelatorio.teste));
                    });
                }
                else if ((typeof this.filtroRelatorio.ano !== 'undefined') && this.filtroRelatorio.ano) {
                    listaFiltro = listaRelatio.filter((itemRelatorio) => {
                        return ((typeof itemRelatorio.mudas.anoResgate !== 'undefined') && itemRelatorio.mudas.anoResgate &&
                            itemRelatorio.mudas.anoResgate === this.filtroRelatorio.ano);
                    });
                }
                else if ((typeof this.filtroRelatorio.teste !== 'undefined') && this.filtroRelatorio.teste) {
                    listaFiltro = listaRelatio.filter((itemRelatorio) => {
                        return ((typeof itemRelatorio.mudas.codTeste !== 'undefined') && itemRelatorio.mudas.codTeste &&
                            itemRelatorio.mudas.codTeste === this.filtroRelatorio.teste);
                    });
                }
                angular.forEach(listaFiltro, (itemRelatorio) => {
                    var _a, _b, _c, _d, _e, _f, _g;
                    let populacao = null;
                    let origem = null;
                    if ((typeof itemRelatorio.mudas.pomar.descricao !== 'undefined') && itemRelatorio.mudas.pomar.descricao) {
                        populacao = itemRelatorio.mudas.pomar.descricao;
                    }
                    else {
                        populacao = '';
                    }
                    if ((typeof itemRelatorio.mudas.pomar.tipo[0].descricao !== 'undefined') && itemRelatorio.mudas.pomar.tipo[0].descricao) {
                        origem = itemRelatorio.mudas.pomar.tipo[0].descricao;
                    }
                    else {
                        origem = '';
                    }
                    let itemFinal = {
                        id: (_a = itemRelatorio.mudas.id) !== null && _a !== void 0 ? _a : '',
                        testeFazenda: (_b = itemRelatorio.mudas.regiaoTeste.nome) !== null && _b !== void 0 ? _b : '',
                        origem: origem,
                        populacao: populacao,
                        numArvores: (_c = itemRelatorio.mudas.numArvores) !== null && _c !== void 0 ? _c : 0,
                        totResgatadas: 0,
                        totEnraizadas: 0,
                        totMinijardins: 0,
                        resgatadas: 0,
                        enraizadas: 0,
                        minijardim: 0,
                        perResgatado: 0,
                        perEnraizado: 0,
                        perMinijardim: 0,
                        totAvaliacoes: 0,
                        totMinijardim: 0,
                        totConfeccoes: 0
                    };
                    if ((typeof itemRelatorio.avaliacoes !== 'undefined') && itemRelatorio.avaliacoes && itemRelatorio.avaliacoes.length > 0) {
                        angular.forEach(itemRelatorio.avaliacoes, (avaliacao) => {
                            var _a;
                            itemFinal.totEnraizadas += (_a = avaliacao.qntEnraizadas) !== null && _a !== void 0 ? _a : 0;
                            itemFinal.totAvaliacoes += 1;
                        });
                        itemFinal.enraizadas = itemFinal.totEnraizadas / itemFinal.totAvaliacoes;
                    }
                    if ((typeof itemRelatorio.minijardins !== 'undefined') && itemRelatorio.minijardins && itemRelatorio.minijardins.length > 0) {
                        angular.forEach(itemRelatorio.minijardins, (minijardim) => {
                            var _a;
                            itemFinal.totMinijardins += (_a = minijardim.numMudas) !== null && _a !== void 0 ? _a : 0;
                            itemFinal.totMinijardim += 1;
                        });
                        itemFinal.minijardim = itemFinal.totMinijardins / itemFinal.totMinijardim;
                    }
                    if ((typeof itemRelatorio.confeccoes !== 'undefined') && itemRelatorio.confeccoes && itemRelatorio.confeccoes.length > 0) {
                        angular.forEach(itemRelatorio.confeccoes, (confeccao) => {
                            var _a;
                            itemFinal.totResgatadas += (_a = confeccao.numEstacas) !== null && _a !== void 0 ? _a : 0;
                            itemFinal.totConfeccoes += 1;
                        });
                        itemFinal.resgatadas = itemFinal.totResgatadas / itemFinal.totConfeccoes;
                    }
                    itemFinal.perResgatado = (itemFinal.resgatadas / itemFinal.numArvores) * 100;
                    itemFinal.perEnraizado = (itemFinal.enraizadas / itemFinal.numArvores) * 100;
                    itemFinal.perMinijardim = (itemFinal.minijardim / itemFinal.numArvores) * 100;
                    this.numArvores += (_d = itemFinal.numArvores) !== null && _d !== void 0 ? _d : 0;
                    this.numResgatadas += (_e = itemFinal.resgatadas) !== null && _e !== void 0 ? _e : 0;
                    this.numEnraizamento += (_f = itemFinal.enraizadas) !== null && _f !== void 0 ? _f : 0;
                    this.numMinijardim += (_g = itemFinal.minijardim) !== null && _g !== void 0 ? _g : 0;
                    listaFinal.push(itemFinal);
                });
                this.numTestes = listaFinal.length;
                if (this.numArvores > 0) {
                    this.perEnraizamento = (this.numEnraizamento / this.numArvores) * 100;
                    this.perResgate = (this.numResgatadas / this.numArvores) * 100;
                }
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'testeFazenda',
                    displayName: 'Teste/Fazenda',
                    width: 120,
                });
                auxColunas.push({
                    name: 'origem',
                    displayName: 'Origem',
                    width: 120,
                });
                auxColunas.push({
                    name: 'populacao',
                    displayName: 'População',
                    width: 120,
                });
                auxColunas.push({
                    name: 'numArvores',
                    displayName: 'Nº Árvores',
                    width: 120,
                });
                auxColunas.push({
                    name: 'resgatadas',
                    displayName: 'Resgatadas',
                    width: 120,
                });
                auxColunas.push({
                    name: 'enraizadas',
                    displayName: 'Enraizadas',
                    width: 120,
                });
                auxColunas.push({
                    name: 'minijardim',
                    displayName: 'Minijardim',
                    width: 120,
                });
                auxColunas.push({
                    name: 'perResgatado',
                    displayName: '% Resgatado',
                    width: 120,
                });
                auxColunas.push({
                    name: 'perEnraizado',
                    displayName: '% Enraizamento',
                    width: 120,
                });
                auxColunas.push({
                    name: 'perMinijardim',
                    displayName: '% Minijardim',
                    width: 120,
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: listaFinal,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                yield this.dadosRelatorio();
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    filtrar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    limparFiltro() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    salvarCorteRaso() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                this.modalBrotacoes = false;
                this.modalAdubacao = false;
                this.modalControlePragas = false;
                this.modalConfeccao = false;
                this.modalAvaliacao = false;
                if (typeof this.dadosCorteRaso.id === 'undefined' || !this.dadosCorteRaso.id) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CORTE_RASO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CORTE_RASO)
                        .criar(this.dadosCorteRaso);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CORTE_RASO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CORTE_RASO)
                        .atualizar(this.dadosCorteRaso);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir um novo Corte Raso ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir um novo Corte Raso ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.ocultarModalCorteRaso();
                this.filtrarAnalises(true);
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
                this.ocultarModalCorteRaso();
            }
            finally {
                this.ocultarModalCorteRaso();
            }
        });
    }
    abrirModalCorteRaso(id = null) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (typeof id !== 'undefined' && id) {
                    this.dadosCorteRaso = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CORTE_RASO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CORTE_RASO)
                        .id(id)
                        .ver();
                    if (this.dadosCorteRaso) {
                        if (typeof this.dadosCorteRaso.data === 'string') {
                            this.dadosCorteRaso.data = new Date(this.dadosCorteRaso.data);
                        }
                    }
                }
                this.modalCorteRaso = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalCorteRaso() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalCorteRaso = false;
            this.dadosCorteRaso = new ProducaoMudaClonalEucalyptusResgateMacroestacasCorteRaso_1.ProducaoMudaClonalEucalyptusResgateMacroestacasCorteRaso();
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    excluirCorteRaso(id) {
        return __awaiter(this, void 0, void 0, function* () {
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            const ctrl = this;
            this.notificacao
                .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                .then((result) => __awaiter(this, void 0, void 0, function* () {
                if (result.value) {
                    CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CORTE_RASO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CORTE_RASO)
                        .id(id)
                        .remover();
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.listaOpcoesCorteRaso = yield ctrl.dadosListaCorteRaso();
                    ctrl.scopeApply();
                }
            }));
        });
    }
    salvarBrotacoes() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                this.modalCorteRaso = false;
                this.modalAdubacao = false;
                this.modalControlePragas = false;
                this.modalConfeccao = false;
                this.modalAvaliacao = false;
                if (typeof this.dadosBrotacoes.id === 'undefined' || !this.dadosBrotacoes.id) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_BROTACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_BROTACAO)
                        .criar(this.dadosBrotacoes);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_BROTACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_BROTACAO)
                        .atualizar(this.dadosBrotacoes);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir uma nova Brotação ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir uma nova Brotação ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.ocultarModalBrotacoes();
                this.filtrarAnalises(true);
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
                this.ocultarModalBrotacoes();
            }
            finally {
                this.ocultarModalBrotacoes();
            }
        });
    }
    abrirModalBrotacoes(id = null) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (typeof id !== 'undefined' && id) {
                    this.dadosBrotacoes = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_BROTACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_BROTACAO)
                        .id(id)
                        .ver();
                    if (this.dadosBrotacoes) {
                        if (typeof this.dadosBrotacoes.data === 'string') {
                            this.dadosBrotacoes.data = new Date(this.dadosBrotacoes.data);
                        }
                    }
                }
                this.modalBrotacoes = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalBrotacoes() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalBrotacoes = false;
            this.dadosBrotacoes = new ProducaoMudaClonalEucalyptusResgateMacroestacasBrotacoes_1.ProducaoMudaClonalEucalyptusResgateMacroestacasBrotacoes();
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    excluirBrotacoes(id) {
        return __awaiter(this, void 0, void 0, function* () {
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            const ctrl = this;
            this.notificacao
                .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                .then((result) => __awaiter(this, void 0, void 0, function* () {
                if (result.value) {
                    CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_BROTACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_BROTACAO)
                        .id(id)
                        .remover();
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.listaOpcoesBrotacoes = yield ctrl.dadosListaBrotacoes();
                    ctrl.scopeApply();
                }
            }));
        });
    }
    salvarAdubacao() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                this.modalCorteRaso = false;
                this.modalBrotacoes = false;
                this.modalControlePragas = false;
                this.modalConfeccao = false;
                this.modalAvaliacao = false;
                if (typeof this.dadosAdubacao.id === 'undefined' || !this.dadosAdubacao.id) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_ADUBACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_ADUBACAO)
                        .criar(this.dadosAdubacao);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_ADUBACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_ADUBACAO)
                        .atualizar(this.dadosAdubacao);
                }
                if ((typeof this.dadosAdubacao.produtoFertilizante !== 'undefined') && this.dadosAdubacao.produtoFertilizante) {
                    let produtoFertilizante = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                        .id(this.dadosAdubacao.produtoFertilizante.id)
                        .ver();
                    let historicoEstoqueProdutoFertilizante = new HistoricoEstoqueProdutoFertilizante_1.HistoricoEstoqueProdutoFertilizante();
                    if ((typeof produtoFertilizante !== 'undefined') && produtoFertilizante) {
                        historicoEstoqueProdutoFertilizante.produtoFertilizante = this.dadosAdubacao.produtoFertilizante;
                        historicoEstoqueProdutoFertilizante.quantidadeAnterior = (_a = produtoFertilizante.quantidadeAtual) !== null && _a !== void 0 ? _a : 0;
                        historicoEstoqueProdutoFertilizante.quantidadeMovimento = (_b = this.dadosAdubacao.dosagem) !== null && _b !== void 0 ? _b : 0;
                        historicoEstoqueProdutoFertilizante.quantidadeAtual = historicoEstoqueProdutoFertilizante.quantidadeAnterior - historicoEstoqueProdutoFertilizante.quantidadeMovimento;
                        historicoEstoqueProdutoFertilizante.movimento = HistoricoEstoqueProdutoFertilizante_1.HistoricoEstoqueProdutoFertilizante.MOVIMENTO_SAIDA;
                        historicoEstoqueProdutoFertilizante.usuario = this.usuarioLogado;
                        historicoEstoqueProdutoFertilizante.centroCustoSap = this.dadosAdubacao.centroCustoSap;
                        historicoEstoqueProdutoFertilizante.contaDepositoSap = this.dadosAdubacao.contaDepositoSap;
                        produtoFertilizante.quantidadeAtual = historicoEstoqueProdutoFertilizante.quantidadeAtual;
                        yield this.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.HISTORICO_ESTOQUE_PRODUTO_FERTILIZANTE)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.HISTORICO_ESTOQUE_PRODUTO_FERTILIZANTE)
                            .criar(historicoEstoqueProdutoFertilizante);
                        yield this.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                            .atualizar(produtoFertilizante);
                    }
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir uma nova Adubação ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir uma nova Adubação ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.ocultarModalAdubacao();
                this.filtrarAnalises(true);
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
                this.ocultarModalAdubacao();
            }
            finally {
                this.ocultarModalAdubacao();
            }
        });
    }
    abrirModalAdubacao(id = null) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (typeof id !== 'undefined' && id) {
                    this.dadosAdubacao = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_ADUBACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_ADUBACAO)
                        .id(id)
                        .ver();
                    if (this.dadosAdubacao) {
                        if (typeof this.dadosAdubacao.data === 'string') {
                            this.dadosAdubacao.data = new Date(this.dadosAdubacao.data);
                        }
                    }
                }
                this.modalAdubacao = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalAdubacao() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalAdubacao = false;
            this.dadosAdubacao = new ProducaoMudaClonalEucalyptusResgateMacroestacasAdubacao_1.ProducaoMudaClonalEucalyptusResgateMacroestacasAdubacao();
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    excluirAdubacao(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            this.notificacao
                .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                .then((result) => __awaiter(this, void 0, void 0, function* () {
                if (result.value) {
                    CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_ADUBACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_ADUBACAO)
                        .id(id)
                        .remover();
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.listaOpcoesAdubacao = yield ctrl.dadosListaAdubacao();
                    ctrl.scopeApply();
                }
            }));
        });
    }
    salvarControlePragas() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                this.modalCorteRaso = false;
                this.modalBrotacoes = false;
                this.modalAdubacao = false;
                this.modalConfeccao = false;
                this.modalAvaliacao = false;
                if (typeof this.dadosControlePragas.id === 'undefined' || !this.dadosControlePragas.id) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CONTROLE_PRAGAS)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CONTROLE_PRAGAS)
                        .criar(this.dadosControlePragas);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CONTROLE_PRAGAS)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CONTROLE_PRAGAS)
                        .atualizar(this.dadosControlePragas);
                }
                if ((typeof this.dadosControlePragas.produtoDefensivo !== 'undefined') && this.dadosControlePragas.produtoDefensivo) {
                    let produtoDefensivo = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_DEFENSIVO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_DEFENSIVO)
                        .id(this.dadosControlePragas.produtoDefensivo.id)
                        .ver();
                    let historicoEstoqueProdutoDefensivo = new HistoricoEstoqueProdutoDefensivo_1.HistoricoEstoqueProdutoDefensivo();
                    if ((typeof produtoDefensivo !== 'undefined') && produtoDefensivo) {
                        historicoEstoqueProdutoDefensivo.produtoDefensivo = this.dadosControlePragas.produtoDefensivo;
                        historicoEstoqueProdutoDefensivo.quantidadeAnterior = (_a = produtoDefensivo.quantidadeAtual) !== null && _a !== void 0 ? _a : 0;
                        historicoEstoqueProdutoDefensivo.quantidadeMovimento = (_b = this.dadosControlePragas.quantidade) !== null && _b !== void 0 ? _b : 0;
                        historicoEstoqueProdutoDefensivo.quantidadeAtual = historicoEstoqueProdutoDefensivo.quantidadeAnterior - historicoEstoqueProdutoDefensivo.quantidadeMovimento;
                        historicoEstoqueProdutoDefensivo.movimento = HistoricoEstoqueProdutoDefensivo_1.HistoricoEstoqueProdutoDefensivo.MOVIMENTO_SAIDA;
                        historicoEstoqueProdutoDefensivo.usuario = this.usuarioLogado;
                        historicoEstoqueProdutoDefensivo.centroCustoSap = this.dadosControlePragas.centroCustoSap;
                        historicoEstoqueProdutoDefensivo.contaDepositoSap = this.dadosControlePragas.contaDepositoSap;
                        produtoDefensivo.quantidadeAtual = historicoEstoqueProdutoDefensivo.quantidadeAtual;
                        yield this.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.HISTORICO_ESTOQUE_PRODUTO_DEFENSIVO)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.HISTORICO_ESTOQUE_PRODUTO_DEFENSIVO)
                            .criar(historicoEstoqueProdutoDefensivo);
                        yield this.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_DEFENSIVO)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_DEFENSIVO)
                            .atualizar(produtoDefensivo);
                    }
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir um novo Controle de Pragas ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir um novo Controle de Pragas ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.ocultarModalControlePragas();
                this.filtrarAnalises(true);
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
                this.ocultarModalControlePragas();
            }
            finally {
                this.ocultarModalControlePragas();
            }
        });
    }
    abrirModalControlePragas(id = null) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (typeof id !== 'undefined' && id) {
                    this.dadosControlePragas = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CONTROLE_PRAGAS)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CONTROLE_PRAGAS)
                        .id(id)
                        .ver();
                    if (this.dadosControlePragas) {
                        if (typeof this.dadosControlePragas.data === 'string') {
                            this.dadosControlePragas.data = new Date(this.dadosControlePragas.data);
                        }
                    }
                }
                this.modalControlePragas = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalControlePragas() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalControlePragas = false;
            this.dadosControlePragas = new ProducaoMudaClonalEucalyptusResgateMacroestacasControlePragas_1.ProducaoMudaClonalEucalyptusResgateMacroestacasControlePragas();
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    excluirControlePragas(id) {
        return __awaiter(this, void 0, void 0, function* () {
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            const ctrl = this;
            this.notificacao
                .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                .then((result) => __awaiter(this, void 0, void 0, function* () {
                if (result.value) {
                    CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CONTROLE_PRAGAS)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CONTROLE_PRAGAS)
                        .id(id)
                        .remover();
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.listaOpcoesControlePragas = yield ctrl.dadosListaControlePragas();
                    ctrl.scopeApply();
                }
            }));
        });
    }
    salvarConfeccao() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                this.modalCorteRaso = false;
                this.modalBrotacoes = false;
                this.modalAdubacao = false;
                this.modalControlePragas = false;
                this.modalAvaliacao = false;
                if (typeof this.dadosConfeccao.id === 'undefined' || !this.dadosConfeccao.id) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CONFECCAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CONFECCAO)
                        .criar(this.dadosConfeccao);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CONFECCAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CONFECCAO)
                        .atualizar(this.dadosConfeccao);
                }
                if ((typeof this.dadosConfeccao.tratamento !== 'undefined') && this.dadosConfeccao.tratamento) {
                    let produtoDefensivo = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_DEFENSIVO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_DEFENSIVO)
                        .id(this.dadosConfeccao.tratamento.id)
                        .ver();
                    let historicoEstoqueProdutoDefensivo = new HistoricoEstoqueProdutoDefensivo_1.HistoricoEstoqueProdutoDefensivo();
                    if ((typeof produtoDefensivo !== 'undefined') && produtoDefensivo) {
                        historicoEstoqueProdutoDefensivo.produtoDefensivo = this.dadosConfeccao.tratamento;
                        historicoEstoqueProdutoDefensivo.quantidadeAnterior = (_a = produtoDefensivo.quantidadeAtual) !== null && _a !== void 0 ? _a : 0;
                        historicoEstoqueProdutoDefensivo.quantidadeMovimento = (_b = this.dadosConfeccao.quantidade) !== null && _b !== void 0 ? _b : 0;
                        historicoEstoqueProdutoDefensivo.quantidadeAtual = historicoEstoqueProdutoDefensivo.quantidadeAnterior - historicoEstoqueProdutoDefensivo.quantidadeMovimento;
                        historicoEstoqueProdutoDefensivo.movimento = HistoricoEstoqueProdutoDefensivo_1.HistoricoEstoqueProdutoDefensivo.MOVIMENTO_SAIDA;
                        historicoEstoqueProdutoDefensivo.usuario = this.usuarioLogado;
                        historicoEstoqueProdutoDefensivo.centroCustoSap = this.dadosConfeccao.centroCustoSap;
                        historicoEstoqueProdutoDefensivo.contaDepositoSap = this.dadosConfeccao.contaDepositoSap;
                        produtoDefensivo.quantidadeAtual = historicoEstoqueProdutoDefensivo.quantidadeAtual;
                        yield this.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.HISTORICO_ESTOQUE_PRODUTO_DEFENSIVO)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.HISTORICO_ESTOQUE_PRODUTO_DEFENSIVO)
                            .criar(historicoEstoqueProdutoDefensivo);
                        yield this.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_DEFENSIVO)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_DEFENSIVO)
                            .atualizar(produtoDefensivo);
                    }
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir uma nova Confecção ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir um nova nova Confecção ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.ocultarModalConfeccao();
                this.filtrarAnalises(true);
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
                this.ocultarModalConfeccao();
            }
            finally {
                this.ocultarModalConfeccao();
            }
        });
    }
    abrirModalConfeccao(id = null) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (typeof id !== 'undefined' && id) {
                    this.dadosConfeccao = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CONFECCAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CONFECCAO)
                        .id(id)
                        .ver();
                    if (this.dadosConfeccao) {
                        if (typeof this.dadosConfeccao.data === 'string') {
                            this.dadosConfeccao.data = new Date(this.dadosConfeccao.data);
                        }
                    }
                }
                this.modalConfeccao = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalConfeccao() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalConfeccao = false;
            this.dadosConfeccao = new ProducaoMudaClonalEucalyptusResgateMacroestacasConfeccao_1.ProducaoMudaClonalEucalyptusResgateMacroestacasConfeccao();
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    excluirConfeccao(id) {
        return __awaiter(this, void 0, void 0, function* () {
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            const ctrl = this;
            this.notificacao
                .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                .then((result) => __awaiter(this, void 0, void 0, function* () {
                if (result.value) {
                    CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CONFECCAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CONFECCAO)
                        .id(id)
                        .remover();
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.listaOpcoesConfeccao = yield ctrl.dadosListaConfeccao();
                    ctrl.scopeApply();
                }
            }));
        });
    }
    salvarAvaliacao() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                this.modalCorteRaso = false;
                this.modalBrotacoes = false;
                this.modalAdubacao = false;
                this.modalControlePragas = false;
                this.modalConfeccao = false;
                if (typeof this.dadosAvaliacao.id === 'undefined' || !this.dadosAvaliacao.id) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_AVALIACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_AVALIACAO)
                        .criar(this.dadosAvaliacao);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_AVALIACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_AVALIACAO)
                        .atualizar(this.dadosAvaliacao);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir uma nova Avaliação ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir um nova nova Avaliação ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.ocultarModalAvaliacao();
                this.filtrarAnalises(true);
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
                this.ocultarModalAvaliacao();
            }
            finally {
                this.ocultarModalAvaliacao();
            }
        });
    }
    abrirModalAvaliacao(id = null) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (typeof id !== 'undefined' && id) {
                    this.dadosAvaliacao = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_AVALIACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_AVALIACAO)
                        .id(id)
                        .ver();
                    if (this.dadosAvaliacao) {
                        if (typeof this.dadosAvaliacao.data === 'string') {
                            this.dadosAvaliacao.data = new Date(this.dadosAvaliacao.data);
                        }
                    }
                }
                this.modalAvaliacao = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalAvaliacao() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalAvaliacao = false;
            this.dadosAvaliacao = new ProducaoMudaClonalEucalyptusResgateMacroestacasAvaliacao_1.ProducaoMudaClonalEucalyptusResgateMacroestacasAvaliacao();
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    excluirAvaliacao(id) {
        return __awaiter(this, void 0, void 0, function* () {
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            const ctrl = this;
            this.notificacao
                .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                .then((result) => __awaiter(this, void 0, void 0, function* () {
                if (result.value) {
                    CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_AVALIACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_AVALIACAO)
                        .id(id)
                        .remover();
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.listaOpcoesAvaliacao = yield ctrl.dadosListaAvaliacao();
                    ctrl.scopeApply();
                }
            }));
        });
    }
    configDadosFiltro() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            ctrl.filtroRelatorio.anos = [];
            ctrl.filtroRelatorio.testes = [];
            angular.forEach(ctrl.mudasEucalyptus, (muda) => {
                if ((typeof muda.anoResgate !== 'undefined') && muda.anoResgate) {
                    if (!ctrl.filtroRelatorio.anos.find((o) => o === muda.anoResgate)) {
                        ctrl.filtroRelatorio.anos.push(muda.anoResgate);
                    }
                }
                if ((typeof muda.codTeste !== 'undefined') && muda.codTeste) {
                    if (!ctrl.filtroRelatorio.testes.find((o) => o === muda.codTeste)) {
                        ctrl.filtroRelatorio.testes.push(muda.codTeste);
                    }
                }
            });
        });
    }
    dadosRelatorio() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.graficoResumoGeral !== 'undefined') && this.graficoResumoGeral) {
                this.graficoResumoGeral.destroy();
                this.graficoResumoGeral = null;
            }
            for (let cargaGrafico = 1; cargaGrafico <= 10; cargaGrafico++) {
                setTimeout((ctrl) => {
                    if (ctrl.graficoResumoGeral) {
                        return;
                    }
                    const graficoResumoGeralEl = document.getElementById('graficoResumoGeral');
                    if ((typeof graficoResumoGeralEl === 'undefined') || !graficoResumoGeralEl) {
                        return;
                    }
                    let numTotArvores = this.numArvores;
                    let numTotResgatadas = this.numResgatadas;
                    let numTotEnraizadas = this.numEnraizamento;
                    let numTotMinijardim = this.numMinijardim;
                    const graficoResumoGeralCtx = graficoResumoGeralEl.getContext('2d');
                    const graficoResumoGeralDataSets = [{
                            label: '',
                            data: [
                                numTotArvores,
                                numTotResgatadas,
                                numTotEnraizadas,
                                numTotMinijardim
                            ],
                            backgroundColor: [
                                'rgb(98,163,78)',
                                'rgb(98,163,78)',
                                'rgb(98,163,78)',
                                'rgb(98,163,78)',
                            ],
                            borderWidth: 1,
                            datalabels: {
                                display: true,
                                font: { size: 11 },
                                align: 'right',
                                padding: { left: 3, right: 3 },
                                backgroundColor: 'rgba(255,255,255,.3)',
                                textShadowBlur: 2,
                                textShadowColor: '#fff'
                            }
                        }];
                    ctrl.graficoResumoGeral = new chart_js_1.Chart(graficoResumoGeralCtx, {
                        type: 'horizontalBar',
                        data: {
                            labels: [
                                'Total de árvores',
                                'Resgatadas',
                                'Enraizadas',
                                'Minijardim'
                            ],
                            datasets: graficoResumoGeralDataSets
                        },
                        plugins: [globalThis.ChartDataLabels],
                        options: {
                            responsive: true,
                            legend: {
                                display: false
                            },
                            layout: {
                                padding: {
                                    right: 30
                                }
                            },
                            scales: {
                                gridLines: { offsetGridLines: true },
                                xAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    }]
                            }
                        }
                    });
                }, cargaGrafico * 1000, this);
            }
            this.scopeApply();
        });
    }
    alterarFiltroRelatorio() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoesRelatorio = yield this.dadosListaRelatorio();
            yield this.dadosRelatorio();
            this.scopeApply();
        });
    }
    filtrarAnalises(alterouDados = false) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.analisesFiltradas === 'undefined') || !this.analisesFiltradas || ((typeof alterouDados !== 'undefined') && alterouDados)) {
                this.listaOpcoesCorteRaso = yield this.dadosListaCorteRaso();
                this.listaOpcoesBrotacoes = yield this.dadosListaBrotacoes();
                this.listaOpcoesAdubacao = yield this.dadosListaAdubacao();
                this.listaOpcoesControlePragas = yield this.dadosListaControlePragas();
                this.listaOpcoesConfeccao = yield this.dadosListaConfeccao();
                this.listaOpcoesAvaliacao = yield this.dadosListaAvaliacao();
                this.listaOpcoesRelatorio = yield this.dadosListaRelatorio();
                yield this.configDadosFiltro();
                this.analisesFiltradas = true;
                this.scopeApply();
            }
        });
    }
}
exports.ProducaoMudaClonalEucalyptusResgateMacroestacasController = ProducaoMudaClonalEucalyptusResgateMacroestacasController;
ProducaoMudaClonalEucalyptusResgateMacroestacasController.$inject = ['$scope', '$injector'];
