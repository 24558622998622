"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProducaoMudaClonalEucalyptusMinijardimController = void 0;
const angular = require("angular");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const UiGridConstrutor_1 = require("../../../app/construtores/UiGridConstrutor");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const AbstratoController_1 = require("../../../app/AbstratoController");
const ICRUDOperacao_1 = require("../../../app/interfaces/ICRUDOperacao");
const config_app_1 = require("../../../../../config/config.app");
const sweetalert2_1 = require("sweetalert2");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const ProducaoMudaClonalEucalyptusMinijardim_1 = require("../../../../../modelos/ProducaoMudaClonalEucalyptusMinijardim");
const ProducaoMudaClonalMinijardimCroqui_1 = require("../../../../../modelos/ProducaoMudaClonalMinijardimCroqui");
const ProducaoMudaClonalMinijardimCroquiAnexo_1 = require("../../../../../modelos/ProducaoMudaClonalMinijardimCroquiAnexo");
const ProducaoMudaClonalMinijardimPlantioMacroestacas_1 = require("../../../../../modelos/ProducaoMudaClonalMinijardimPlantioMacroestacas");
const ProducaoMudaClonalMinijardimPlantioMiniestacas_1 = require("../../../../../modelos/ProducaoMudaClonalMinijardimPlantioMiniestacas");
const ProducaoMudaClonalMinijardimAvaliacaoSobrevivencia_1 = require("../../../../../modelos/ProducaoMudaClonalMinijardimAvaliacaoSobrevivencia");
const ProducaoMudaClonalMinijardimEliminarMiniMacroEstacas_1 = require("../../../../../modelos/ProducaoMudaClonalMinijardimEliminarMiniMacroEstacas");
const ProducaoMudaClonalMinijardimManejo_1 = require("../../../../../modelos/ProducaoMudaClonalMinijardimManejo");
const DispositivoUtil_1 = require("../../../../../util/DispositivoUtil");
const LuckysheetUtil_1 = require("../../../../../util/LuckysheetUtil");
const MaterialPlantado_1 = require("../../../../../modelos/MaterialPlantado");
const DetalhamentoEC_1 = require("../../../../../modelos/DetalhamentoEC");
const ArmazenamentoUtil_1 = require("../../../../../util/ArmazenamentoUtil");
const TextoUtil_1 = require("../../../../../util/TextoUtil");
const chart_js_1 = require("chart.js");
class ProducaoMudaClonalEucalyptusMinijardimController extends AbstratoController_1.AbstratoController {
    constructor($scope, $injector) {
        super($scope, $injector, new ProducaoMudaClonalEucalyptusMinijardim_1.ProducaoMudaClonalEucalyptusMinijardim());
        this.path = '';
        this.caminho = '';
        this.tipoOperacao = null;
        this.analisesFiltradas = false;
        this.producaoMudaClonalEucalyptusSelecionado = null;
        this.croquiProducaoMudaClonalEucalyptusSelecionado = null;
        this.dadosCroqui = null;
        this.dadosPlantioMacroestacas = null;
        this.dadosPlantioMiniestacas = null;
        this.dadosAvaliacaoSobrevivencia = null;
        this.dadosEliminarMiniMacroEstacas = null;
        this.dadosManejo = null;
        this.posicaoTopoAntesModal = null;
        this.modalCroqui = false;
        this.modalPlantioMacroestacas = false;
        this.modalPlantioMiniestacas = false;
        this.modalAvaliacaoSobrevivencia = false;
        this.modalEliminarMiniMacroEstacas = false;
        this.modalManejo = false;
        this.listaOpcoesCroqui = null;
        this.listaOpcoesPlantioMacroestacas = null;
        this.listaOpcoesPlantioMiniestacas = null;
        this.listaOpcoesAvaliacaoSobrevivencia = null;
        this.listaOpcoesEliminarMiniMacroEstacas = null;
        this.listaOpcoesManejo = null;
        this.listaOpcoesDossieClone = null;
        this.listaOpcoesRelatorio = null;
        this.minijardins = [];
        this.minijardinsFiltrados = [];
        this.clonesuEucalyptus = [];
        this.clonesMaterialGenetico = [];
        this.statusMinijardins = [];
        this.finalidadesMultiplicacao = [];
        this.acoesMinijardim = [];
        this.canaletoes = [];
        this.materiaisGeneticos = [];
        this.casasVegetacao = [];
        this.generos = [];
        this.especies = [];
        this.tiposMinijardim = [];
        this.caixasDAgua = [];
        this.receitasSolucao = [];
        this.funcionarios = [];
        this.listaProducao = [];
        this.listaEnraizamento = [];
        this.graficoRelatorioDossie = null;
        this.graficoRelatorioFinalidade = null;
        this.graficoRelatorioEspecie = null;
        this.graficoRelatorioResgate = null;
        this.filtroRelatorioClone = { clone: null, acao: null };
        this.showRelatorioClone = false;
        this.filtroDossieClone = {
            clone: null,
            listaMinijardins: []
        };
        this.showDossieClone = false;
        this.totalClonesMinijardim = 0;
        this.totalClonesMultiplicacao = 0;
        this.totalNumMiniestacasCepa = 0;
        this.totalNumMudas = 0;
        this.medEnraizamento = 0;
        this.totalSemAlertas = 0;
        this.totalPadrao = 0;
        this.totalCuidado = 0;
        this.totalAlerta = 0;
        this.controleManejoFertilizacao = ProducaoMudaClonalMinijardimManejo_1.ProducaoMudaClonalMinijardimManejo.CONTROLE_MANEJO_FERTILIZACAO;
        this.controleManejoControleEc = ProducaoMudaClonalMinijardimManejo_1.ProducaoMudaClonalMinijardimManejo.CONTROLE_MANEJO_CONTROLE_EC;
        this.controleManejoLavagem = ProducaoMudaClonalMinijardimManejo_1.ProducaoMudaClonalMinijardimManejo.CONTROLE_MANEJO_LAVAGEM;
        this.controleManejoPodas = ProducaoMudaClonalMinijardimManejo_1.ProducaoMudaClonalMinijardimManejo.CONTROLE_MANEJO_PODAS;
        this.tipoOperacao = ICRUDOperacao_1.CRUDOperacao;
        this.analisesFiltradas = false;
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.analisesFiltradas = false;
            this.modalCroqui = false;
            this.dadosCroqui = null;
            this.modalPlantioMacroestacas = false;
            this.dadosPlantioMacroestacas = null;
            this.modalPlantioMiniestacas = false;
            this.dadosPlantioMiniestacas = null;
            this.modalAvaliacaoSobrevivencia = false;
            this.dadosAvaliacaoSobrevivencia = null;
            this.modalEliminarMiniMacroEstacas = false;
            this.dadosEliminarMiniMacroEstacas = null;
            this.modalManejo = false;
            this.dadosManejo = null;
            this.minijardinsFiltrados = [];
            this.filtroRelatorioClone = { clone: null, acao: null };
            this.filtroDossieClone = { clone: null, listaMinijardins: [] };
            this.showRelatorioClone = false;
            this.showDossieClone = false;
            this.totalClonesMinijardim = 0;
            this.totalClonesMultiplicacao = 0;
            this.totalNumMiniestacasCepa = 0;
            this.totalNumMudas = 0;
            this.medEnraizamento = 0;
            this.totalSemAlertas = 0;
            this.totalPadrao = 0;
            this.totalCuidado = 0;
            this.totalAlerta = 0;
            this.path = config_app_1.config.api.caminho;
            this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM;
            if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR ||
                this.operacao === ICRUDOperacao_1.CRUDOperacao.EDITAR ||
                this.operacao === ICRUDOperacao_1.CRUDOperacao.DUPLICAR) {
                this.clonesuEucalyptus = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.clonesuEucalyptus = resposta;
                    }
                });
                this.clonesMaterialGenetico = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.CLONE_MATERIAL_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CLONE_MATERIAL_GENETICO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.clonesMaterialGenetico = resposta;
                    }
                });
                this.especies = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESPECIE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESPECIE)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.especies = resposta;
                    }
                });
                this.statusMinijardins = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.STATUS_MINIJARDIM)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.STATUS_MINIJARDIM)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.statusMinijardins = resposta;
                    }
                });
                this.finalidadesMultiplicacao = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.FINALIDADE_MULTIPLICACAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.FINALIDADE_MULTIPLICACAO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.finalidadesMultiplicacao = resposta;
                    }
                });
                this.acoesMinijardim = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ACAO_MINIJARDIM)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ACAO_MINIJARDIM)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.acoesMinijardim = resposta;
                    }
                });
                this.canaletoes = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.CANALETOES)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CANALETOES)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.canaletoes = resposta;
                    }
                });
            }
            if (this.operacao === ICRUDOperacao_1.CRUDOperacao.LISTAR) {
                this.listaOpcoesCroqui = {
                    data: [],
                    columnDefs: []
                };
                this.listaOpcoesPlantioMacroestacas = {
                    data: [],
                    columnDefs: []
                };
                this.listaOpcoesPlantioMiniestacas = {
                    data: [],
                    columnDefs: []
                };
                this.listaOpcoesAvaliacaoSobrevivencia = {
                    data: [],
                    columnDefs: []
                };
                this.listaOpcoesEliminarMiniMacroEstacas = {
                    data: [],
                    columnDefs: []
                };
                this.listaOpcoesManejo = {
                    data: [],
                    columnDefs: []
                };
                this.listaOpcoesDossieClone = {
                    data: [],
                    columnDefs: []
                };
                this.listaOpcoesRelatorio = {
                    data: [],
                    columnDefs: []
                };
                this.canaletoes = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.CANALETOES)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CANALETOES)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.canaletoes = resposta;
                    }
                });
                this.materiaisGeneticos = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MATERIAL_GENETICO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.materiaisGeneticos = resposta;
                    }
                });
                this.casasVegetacao = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.CASA_VEGETACAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CASA_VEGETACAO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.casasVegetacao = resposta;
                    }
                });
                this.generos = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.GENERO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.GENERO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.generos = resposta;
                    }
                });
                this.especies = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESPECIE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESPECIE)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.especies = resposta;
                    }
                });
                this.tiposMinijardim = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TIPO_MINIJARDIM)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TIPO_MINIJARDIM)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.tiposMinijardim = resposta;
                    }
                });
                this.caixasDAgua = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.CAIXA_DAGUA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CAIXA_DAGUA)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.caixasDAgua = resposta;
                    }
                });
                this.receitasSolucao = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.RECEITA_SOLUCAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.RECEITA_SOLUCAO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.receitasSolucao = resposta;
                    }
                });
                this.funcionarios = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.FUNCIONARIO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.FUNCIONARIO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.funcionarios = resposta;
                    }
                });
                this.acoesMinijardim = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ACAO_MINIJARDIM)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ACAO_MINIJARDIM)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.acoesMinijardim = resposta;
                    }
                });
                this.listaProducao = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.listaProducao = resposta;
                    }
                });
                this.listaEnraizamento = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_ENRAIZAMENTO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_ENRAIZAMENTO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.listaEnraizamento = resposta;
                    }
                });
            }
            this.$scope.$apply();
        });
    }
    salvar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.modalCroqui = false;
                this.dadosCroqui = null;
                this.modalPlantioMacroestacas = false;
                this.dadosPlantioMacroestacas = null;
                this.modalPlantioMiniestacas = false;
                this.dadosPlantioMiniestacas = null;
                this.modalAvaliacaoSobrevivencia = false;
                this.dadosAvaliacaoSobrevivencia = null;
                this.modalEliminarMiniMacroEstacas = false;
                this.dadosEliminarMiniMacroEstacas = null;
                this.modalManejo = false;
                this.dadosManejo = null;
                if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM)
                        .criar(this.modelo);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM)
                        .atualizar(this.modelo);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir um novo Minijardim ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir um novo Minijardim ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    visualizar(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const producaoMudaClonalEucalyptusMinijardim = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM)
                    .id(id)
                    .ver();
                producaoMudaClonalEucalyptusMinijardim.criacao = new Date(producaoMudaClonalEucalyptusMinijardim.criacao);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return producaoMudaClonalEucalyptusMinijardim;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    remover(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const producaoMudaClonalEucalyptusMinijardim = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM)
                    .id(id)
                    .remover();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (producaoMudaClonalEucalyptusMinijardim) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const producaoMudaClonalEucalyptusMinijardim = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM)
                    .id(this.modelo.id)
                    .restaurar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (producaoMudaClonalEucalyptusMinijardim) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosLista(filtro = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.minijardins = [];
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                this.path = config_app_1.config.api.caminho;
                this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM;
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                var auxColunas = Array();
                auxColunas.push({
                    name: 'codigo',
                    displayName: 'Código',
                    width: 90,
                });
                auxColunas.push({
                    name: 'producaoClone.clone.clone',
                    displayName: 'Clone',
                    width: 190,
                });
                auxColunas.push({
                    name: 'especie.descricao',
                    displayName: 'Espécie',
                    width: 100,
                });
                auxColunas.push({
                    name: 'anoResgate',
                    displayName: 'Ano Resgate',
                    width: 140,
                });
                auxColunas.push({
                    name: 'statusMinijardim.descricao',
                    displayName: 'Status Minijardim',
                    width: 140,
                });
                auxColunas.push({
                    name: 'finalidade.descricao',
                    displayName: 'Finalidade',
                    width: 100,
                });
                auxColunas.push({
                    name: 'acao.descricao',
                    displayName: 'Ação',
                    width: 100,
                });
                auxColunas.push({
                    name: 'numCanaletao.descricao',
                    displayName: 'Nº Canaletão',
                    width: 100,
                });
                auxColunas.push({
                    name: 'numCepas',
                    displayName: 'Nº Cepas',
                    width: 100,
                });
                auxColunas.push({
                    name: 'numMiniestacasCepa',
                    displayName: 'Nº de Miniestacas/Cepa',
                    width: 140,
                });
                auxColunas.push({
                    name: 'enraizamento',
                    displayName: 'Enraizamento (%)',
                    width: 120,
                });
                auxColunas.push({
                    name: 'numMudas',
                    displayName: 'Nº Mudas',
                    width: 120,
                });
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
                const lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM)
                    .filtro(filtro)
                    .listar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.minijardins = lista;
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosListaCroqui() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let filtro = {
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    limiteRegistros: 100000
                };
                let lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_CROQUI)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_CROQUI)
                    .filtro(filtro)
                    .listar();
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    posicao: 1,
                    cellClass: 'text-center',
                    cellTemplate: `
                    <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                        <button type="button" class="btn btn-icon bx bxs-edit" style="padding-right: .4rem"
                            onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                            ng-click="grid.appScope.getParentController().abrirModalCroqui(row.entity.id)">
                        </button>
                        <button type="button" class="btn btn-icon bx bx-trash" style="padding-left: .4rem"
                            onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                            ng-click="grid.appScope.getParentController().excluirCroqui(row.entity.id)">
                        </button>
                    </div> `
                });
                auxColunas.push({
                    name: 'numCanaletao.descricao',
                    displayName: 'Nº Canaletão',
                    width: 140,
                });
                auxColunas.push({
                    name: 'numLinhas',
                    displayName: 'Nº Linhas',
                    width: 120,
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosListaPlantioMacroestacas() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let filtro = {
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    limiteRegistros: 100000
                };
                let lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_PLANTIO_MACROESTACAS)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_PLANTIO_MACROESTACAS)
                    .filtro(filtro)
                    .listar();
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    posicao: 1,
                    cellClass: 'text-center',
                    cellTemplate: `
                    <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                        <button type="button" class="btn btn-icon bx bxs-edit" style="padding-right: .4rem"
                            onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                            ng-click="grid.appScope.getParentController().abrirModalPlantioMacroestacas(row.entity.id)">
                        </button>
                        <button type="button" class="btn btn-icon bx bx-trash" style="padding-left: .4rem"
                            onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                            ng-click="grid.appScope.getParentController().excluirPlantioMacroestacas(row.entity.id)">
                        </button>
                    </div> `
                });
                auxColunas.push({
                    name: 'dataPlantio',
                    displayName: 'Data',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 120,
                });
                auxColunas.push({
                    name: 'numCanaletao.descricao',
                    displayName: 'Nº Canaletão',
                    width: 120,
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosListaPlantioMiniestacas() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let filtro = {
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    limiteRegistros: 100000
                };
                let lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_PLANTIO_MINIESTACAS)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_PLANTIO_MINIESTACAS)
                    .filtro(filtro)
                    .listar();
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    posicao: 1,
                    cellClass: 'text-center',
                    cellTemplate: `
                    <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                        <button type="button" class="btn btn-icon bx bxs-edit" style="padding-right: .4rem"
                            onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                            ng-click="grid.appScope.getParentController().abrirModalPlantioMiniestacas(row.entity.id)">
                        </button>
                        <button type="button" class="btn btn-icon bx bx-trash" style="padding-left: .4rem"
                            onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                            ng-click="grid.appScope.getParentController().excluirPlantioMiniestacas(row.entity.id)">
                        </button>
                    </div> `
                });
                auxColunas.push({
                    name: 'dataPlantio',
                    displayName: 'Data',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 120,
                });
                auxColunas.push({
                    name: 'numCanaletao.descricao',
                    displayName: 'Nº Canaletão',
                    width: 120,
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosListaAvaliacaoSobrevivencia() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let filtro = {
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    limiteRegistros: 100000
                };
                let lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_AVALIACAO_SOBREVIVENCIA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_AVALIACAO_SOBREVIVENCIA)
                    .filtro(filtro)
                    .listar();
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    posicao: 1,
                    cellClass: 'text-center',
                    cellTemplate: `
                    <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                        <button type="button" class="btn btn-icon bx bxs-edit" style="padding-right: .4rem"
                            onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                            ng-click="grid.appScope.getParentController().abrirModalAvaliacaoSobrevivencia(row.entity.id)">
                        </button>
                        <button type="button" class="btn btn-icon bx bx-trash" style="padding-left: .4rem"
                            onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                            ng-click="grid.appScope.getParentController().excluirAvaliacaoSobrevivencia(row.entity.id)">
                        </button>
                    </div> `
                });
                auxColunas.push({
                    name: 'data',
                    displayName: 'Data',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 120,
                });
                auxColunas.push({
                    name: 'materialGenetico.descricao',
                    displayName: 'Material Genético',
                    width: 140,
                });
                auxColunas.push({
                    name: 'numCanaletao.descricao',
                    displayName: 'Nº Canaletão',
                    width: 120,
                });
                auxColunas.push({
                    name: 'numOriginalCepas',
                    displayName: 'Nº Original Cepas',
                    width: 120,
                });
                auxColunas.push({
                    name: 'numCepasMortas',
                    displayName: 'Nº Cepas Mortas',
                    width: 120,
                });
                auxColunas.push({
                    name: 'numCepasSobreviventes',
                    displayName: 'Nº Cepas Sobreviventes',
                    width: 120,
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosListaEliminarMiniMacroEstacas() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let filtro = {
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    limiteRegistros: 100000
                };
                let lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_ELIMINAR_MINIMACRO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_ELIMINAR_MINIMACRO)
                    .filtro(filtro)
                    .listar();
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    posicao: 1,
                    cellClass: 'text-center',
                    cellTemplate: `
                    <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                        <button type="button" class="btn btn-icon bx bxs-edit" style="padding-right: .4rem"
                            onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                            ng-click="grid.appScope.getParentController().abrirModalEliminarMiniMacroEstacas(row.entity.id)">
                        </button>
                        <button type="button" class="btn btn-icon bx bx-trash" style="padding-left: .4rem"
                            onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                            ng-click="grid.appScope.getParentController().excluirEliminarMiniMacroEstacas(row.entity.id)">
                        </button>
                    </div> `
                });
                auxColunas.push({
                    name: 'data',
                    displayName: 'Data',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 120,
                });
                auxColunas.push({
                    name: 'numCanaletao.descricao',
                    displayName: 'Nº Canaletão',
                    width: 120,
                });
                auxColunas.push({
                    name: 'materialGenetico.descricao',
                    displayName: 'Material Genético',
                    width: 140,
                });
                auxColunas.push({
                    name: 'linhaCanaletao',
                    displayName: 'Linha Canaletão',
                    width: 120,
                });
                auxColunas.push({
                    name: 'numMudas',
                    displayName: 'Nº Mudas',
                    width: 120,
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosListaManejo() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let filtro = {
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    limiteRegistros: 100000
                };
                let lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_MANEJO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_MANEJO)
                    .filtro(filtro)
                    .listar();
                let listaManejo = [];
                this.totalSemAlertas = 0;
                this.totalPadrao = 0;
                this.totalCuidado = 0;
                this.totalAlerta = 0;
                let auxColunas = new Array();
                angular.forEach(lista, (itemManejo) => {
                    if ((typeof itemManejo.tipoControleManejo !== 'undefined') && itemManejo.tipoControleManejo) {
                        if (itemManejo.tipoControleManejo === 'Controle de EC') {
                            if ((typeof itemManejo.listaDetalhamentoEC !== 'undefined') && itemManejo.listaDetalhamentoEC) {
                                let itemSave = {};
                                angular.forEach(itemManejo.listaDetalhamentoEC, (detalhamento) => {
                                    var _a;
                                    itemSave = {
                                        id: itemManejo.id,
                                        uuid: itemManejo.uuid,
                                        codigo: itemManejo.codigo,
                                        criacao: itemManejo.criacao,
                                        data: itemManejo.data,
                                        casaVegetacao: itemManejo.casaVegetacao,
                                        funcionario: itemManejo.funcionario,
                                        listaDetalhamentoEC: itemManejo.listaDetalhamentoEC,
                                        minijardimEucalyptus: itemManejo.minijardimEucalyptus,
                                        observacao: itemManejo.observacao,
                                        status: itemManejo.status,
                                        tipoControleManejo: itemManejo.tipoControleManejo,
                                        ec: (_a = detalhamento.ec) !== null && _a !== void 0 ? _a : 0
                                    };
                                    if (itemSave.ec < 0.3) {
                                        this.totalSemAlertas++;
                                    }
                                    else if (itemSave.ec >= 0.3 && itemSave.ec <= 0.8) {
                                        this.totalPadrao++;
                                    }
                                    else if (itemSave.ec > 0.8 && itemSave.ec < 2) {
                                        this.totalCuidado++;
                                    }
                                    else if (itemSave.ec >= 2) {
                                        this.totalAlerta++;
                                    }
                                    listaManejo.push(itemSave);
                                    itemSave = {};
                                });
                            }
                        }
                        else {
                            listaManejo.push(itemManejo);
                        }
                    }
                });
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    posicao: 1,
                    cellClass: 'text-center',
                    cellTemplate: `
                    <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                        <button type="button" class="btn btn-icon bx bxs-edit" style="padding-right: .4rem"
                            onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                            ng-click="grid.appScope.getParentController().abrirModalManejo(row.entity.id)">
                        </button>
                        <button type="button" class="btn btn-icon bx bx-trash" style="padding-left: .4rem"
                            onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                            ng-click="grid.appScope.getParentController().excluirManejo(row.entity.id)">
                        </button>
                    </div> `
                });
                auxColunas.push({
                    name: 'data',
                    displayName: 'Data',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 180,
                });
                auxColunas.push({
                    name: 'tipoControleManejo',
                    displayName: 'Tipo de Manejo',
                    width: 120,
                });
                auxColunas.push({
                    name: 'ec',
                    displayName: 'EC',
                    width: 120,
                    cellClass: function (grid, row, col) {
                        if (grid.getCellValue(row, col) >= 0.3 && grid.getCellValue(row, col) <= 0.8) {
                            this.totalPadrao++;
                            return 'fundoVerde';
                        }
                        else if (grid.getCellValue(row, col) > 0.8 && grid.getCellValue(row, col) < 2) {
                            this.totalCuidado++;
                            return 'fundoAmarelo';
                        }
                        else if (grid.getCellValue(row, col) >= 2) {
                            this.totalAlerta++;
                            return 'fundoVermelho';
                        }
                        else {
                            return 'text-center';
                        }
                    },
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                console.log(lista);
                const gridOption = {
                    data: listaManejo,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    filtrar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    limparFiltro() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    carregarPlanilhaCroqui() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
            let filtro = {
                listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                producaoMudaClonalId: ((this.producaoMudaClonalEucalyptusSelecionado && this.producaoMudaClonalEucalyptusSelecionado.id) ? this.producaoMudaClonalEucalyptusSelecionado.id : null)
            };
            let listaCroquiPomar = yield this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_CROQUI_ANEXO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_CROQUI_ANEXO)
                .filtro(filtro)
                .listar();
            if ((typeof listaCroquiPomar !== 'undefined') && listaCroquiPomar) {
                angular.forEach(listaCroquiPomar, (item) => {
                    if ((typeof item !== 'undefined') && item) {
                        if ((typeof item.anexos !== 'undefined') && item.anexos) {
                            let anexosAgrupados = new Array();
                            angular.forEach(item.anexos, (ged) => {
                                if (!anexosAgrupados.some((o) => o && o.id && (o.id === ged.id))) {
                                    anexosAgrupados.push(ged);
                                }
                            });
                            item.anexos = anexosAgrupados;
                        }
                        ctrl.croquiProducaoMudaClonalEucalyptusSelecionado = item;
                    }
                });
            }
            if (!this.croquiProducaoMudaClonalEucalyptusSelecionado) {
                this.croquiProducaoMudaClonalEucalyptusSelecionado = new ProducaoMudaClonalMinijardimCroquiAnexo_1.ProducaoMudaClonalMinijardimCroquiAnexo();
                this.croquiProducaoMudaClonalEucalyptusSelecionado.uuid = ArmazenamentoUtil_1.ArmazenamentoUtil.uuid();
                this.croquiProducaoMudaClonalEucalyptusSelecionado.criacao = new Date();
                this.croquiProducaoMudaClonalEucalyptusSelecionado.dados = [];
            }
            if ((typeof this.croquiProducaoMudaClonalEucalyptusSelecionado.anexos !== 'undefined') && this.croquiProducaoMudaClonalEucalyptusSelecionado.anexos) {
                let anexosAgrupados = new Array();
                angular.forEach(this.croquiProducaoMudaClonalEucalyptusSelecionado.anexos, (ged) => {
                    if (!anexosAgrupados.some((o) => o && o.id && (o.id === ged.id))) {
                        anexosAgrupados.push(ged);
                    }
                });
                this.croquiProducaoMudaClonalEucalyptusSelecionado.anexos = anexosAgrupados;
            }
            if (angular.element('#planilha_croqui_producao_muda_eucalyptus').length > 0) {
                if ((typeof globalThis.luckysheet !== 'undefined') && globalThis.luckysheet) {
                    globalThis.luckysheet.destroy();
                }
                angular.element('#planilha_croqui_producao_muda_eucalyptus').empty();
            }
            setTimeout((ctrl) => {
                let dadosPlanilhaCroquiSelecionado = null;
                let dadosAnexoCroquiSelecionado = null;
                if ((typeof ctrl.croquiProducaoMudaClonalEucalyptusSelecionado !== 'undefined') && ctrl.croquiProducaoMudaClonalEucalyptusSelecionado) {
                    if ((typeof ctrl.croquiProducaoMudaClonalEucalyptusSelecionado.dados !== 'undefined') && ctrl.croquiProducaoMudaClonalEucalyptusSelecionado.dados) {
                        if (typeof ctrl.croquiProducaoMudaClonalEucalyptusSelecionado.dados === 'string') {
                            dadosPlanilhaCroquiSelecionado = JSON.parse(ctrl.croquiProducaoMudaClonalEucalyptusSelecionado.dados);
                        }
                        else {
                            dadosPlanilhaCroquiSelecionado = ctrl.croquiProducaoMudaClonalEucalyptusSelecionado.dados;
                        }
                    }
                    if ((typeof ctrl.croquiProducaoMudaClonalEucalyptusSelecionado.anexos !== 'undefined') && ctrl.croquiProducaoMudaClonalEucalyptusSelecionado.anexos && (ctrl.croquiProducaoMudaClonalEucalyptusSelecionado.anexos.length > 0)) {
                        angular.forEach(ctrl.croquiProducaoMudaClonalEucalyptusSelecionado.anexos, (dadosAnexo) => {
                            dadosAnexoCroquiSelecionado = dadosAnexo;
                        });
                    }
                }
                let luckysheetOptions = {
                    container: 'planilha_croqui_producao_muda_eucalyptus',
                    title: null,
                    data: [],
                    showinfobar: false,
                    userInfo: false,
                    userMenuItem: [],
                    myFolderUrl: '',
                    plugins: [{ name: 'chart' }, { name: 'print' }],
                    curentsheetView: 'viewNormal',
                    sheetViewZoom: {
                        viewNormalZoomScale: 1,
                        viewLayoutZoomScale: 1,
                        viewPageZoomScale: 0.6,
                    },
                    printoptions: {
                        unit: 'mm',
                        PrintArea: '$A$1:$S$31',
                        PrintTitles: {
                            row: 'Sheet1!$1:$1',
                            column: 'Sheet1!$C:$C'
                        },
                        printOptions: {
                            horizontalCentered: 0,
                            verticalCentered: 0,
                            headings: 0,
                            gridLines: 0,
                        },
                        pageMargins: {
                            left: 0.7,
                            right: 0.7,
                            top: 0.75,
                            bottom: 0.75,
                            header: 0.3,
                            footer: 0.3,
                        },
                        pageSetup: {
                            copies: 1,
                            draft: 0,
                            paperSize: 9,
                            paperHeight: null,
                            paperWidth: null,
                            fitToWidth: 0,
                            fitToHeight: 0,
                            scale: 100,
                            orientation: 0,
                            blackAndWhite: 0,
                            cellComments: 0,
                            errors: 0,
                            horizontalDpi: null,
                            verticalDpi: null,
                            pageOrder: 0,
                            firstPageNumber: null,
                            useFirstPageNumber: 0,
                            usePrinterDefaults: 1,
                        },
                        headerFooter: {
                            firstFooter: {
                                left: [
                                    {
                                        ff: 'Arial',
                                        fc: '#fff000',
                                        fs: 12,
                                        cl: 0,
                                        un: 0,
                                        bl: 0,
                                        it: 0,
                                        ss: 0,
                                        v: 'ANI Sistemas'
                                    }
                                ],
                                center: [],
                                right: [],
                            },
                            firstHeader: null,
                            oddFooter: '&C Página &P 页&R&G',
                            oddHeader: '&L&G&C&A&F',
                            evenFooter: null,
                            evenHeader: null,
                            drawingHF: {
                                LF: {
                                    type: '#_x0000_t75',
                                    imagedata: '',
                                    style: ''
                                },
                                RF: {},
                                CF: {},
                                LH: {},
                                RH: {},
                                CH: {},
                            },
                        },
                    }
                };
                if (dadosPlanilhaCroquiSelecionado && angular.isArray(dadosPlanilhaCroquiSelecionado) && (dadosPlanilhaCroquiSelecionado.length > 0)) {
                    try {
                        if ((typeof globalThis.luckysheet !== 'undefined') && globalThis.luckysheet) {
                            luckysheetOptions.data = dadosPlanilhaCroquiSelecionado;
                            if ((typeof luckysheetOptions.data === 'undefined') || (luckysheetOptions.data === null) || (angular.isArray(luckysheetOptions.data) && (luckysheetOptions.data.length === 0))) {
                                luckysheetOptions.data = [{
                                        name: 'Planilha 1',
                                        status: 1,
                                        total: 0,
                                        order: 0,
                                        data: []
                                    }];
                            }
                            globalThis.luckysheet.create(luckysheetOptions);
                        }
                        else {
                            throw new Error('Plugin "luckysheet" não carregado.');
                        }
                    }
                    catch (ex) {
                        console.log(ex);
                        ctrl.manipulaErro.exception(ex);
                    }
                }
                else if (dadosAnexoCroquiSelecionado && dadosAnexoCroquiSelecionado.id) {
                    let urlAnexo = config_app_1.config.api.caminho + RotaAPIConstantes_1.RotaAPIConstantes.LOAD + `/${dadosAnexoCroquiSelecionado.id}`;
                    let nomeAnexo = ((typeof dadosAnexoCroquiSelecionado.nome !== 'undefined') && dadosAnexoCroquiSelecionado.nome) ? dadosAnexoCroquiSelecionado.nome
                        : ((typeof dadosAnexoCroquiSelecionado.valor !== 'undefined') && dadosAnexoCroquiSelecionado.valor ? dadosAnexoCroquiSelecionado.valor : '0');
                    try {
                        if ((typeof globalThis.LuckyExcel !== 'undefined') && globalThis.LuckyExcel) {
                            globalThis.LuckyExcel.transformExcelToLuckyByUrl(urlAnexo, nomeAnexo, function (exportJson, _luckysheetfile) {
                                if (exportJson.sheets === null || exportJson.sheets.length === 0) {
                                    throw new Error('Falha ao ler o conteúdo do arquivo Excel, somente arquivos \'xlsx\' são suportados!');
                                }
                                try {
                                    if ((typeof globalThis.luckysheet !== 'undefined') && globalThis.luckysheet) {
                                        luckysheetOptions.data = exportJson.sheets ? exportJson.sheets : [];
                                        if ((typeof luckysheetOptions.data === 'undefined') || (luckysheetOptions.data === null) || (angular.isArray(luckysheetOptions.data) && (luckysheetOptions.data.length === 0))) {
                                            luckysheetOptions.data = [{
                                                    name: 'Planilha 1',
                                                    status: 1,
                                                    total: 0,
                                                    order: 0,
                                                    data: []
                                                }];
                                        }
                                        if (exportJson.info && exportJson.info.name) {
                                            luckysheetOptions.title = exportJson.info.name;
                                        }
                                        if (exportJson.info && exportJson.info.name && exportJson.info.name.creator) {
                                            luckysheetOptions.userInfo = exportJson.info.name.creator;
                                        }
                                        globalThis.luckysheet.create(luckysheetOptions);
                                    }
                                    else {
                                        throw new Error('Plugin "luckysheet" não carregado.');
                                    }
                                }
                                catch (ex) {
                                    console.log(ex);
                                    ctrl.manipulaErro.exception(ex);
                                }
                            });
                        }
                        else {
                            throw new Error('Plugin "LuckyExcel" não carregado.');
                        }
                    }
                    catch (ex) {
                        console.log(ex);
                        ctrl.manipulaErro.exception(ex);
                    }
                }
                else {
                    try {
                        if ((typeof globalThis.luckysheet !== 'undefined') && globalThis.luckysheet) {
                            luckysheetOptions.data = [{
                                    name: 'Planilha 1',
                                    status: 1,
                                    total: 0,
                                    order: 0,
                                    data: []
                                }];
                            globalThis.luckysheet.create(luckysheetOptions);
                        }
                        else {
                            throw new Error('Plugin "luckysheet" não carregado.');
                        }
                    }
                    catch (ex) {
                        console.log(ex);
                        ctrl.manipulaErro.exception(ex);
                    }
                }
            }, 500, this);
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    salvarPlanilhaCroqui() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!this.croquiProducaoMudaClonalEucalyptusSelecionado) {
                    return;
                }
                this.modalCroqui = false;
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if ((typeof globalThis.luckysheet !== 'undefined') && globalThis.luckysheet) {
                    this.croquiProducaoMudaClonalEucalyptusSelecionado.dados = JSON.stringify(globalThis.luckysheet.getluckysheetfile());
                }
                if (!this.croquiProducaoMudaClonalEucalyptusSelecionado.id) {
                    this.croquiProducaoMudaClonalEucalyptusSelecionado = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_CROQUI_ANEXO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_CROQUI_ANEXO)
                        .criar(this.croquiProducaoMudaClonalEucalyptusSelecionado);
                }
                else {
                    this.croquiProducaoMudaClonalEucalyptusSelecionado = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_CROQUI_ANEXO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_CROQUI_ANEXO)
                        .atualizar(this.croquiProducaoMudaClonalEucalyptusSelecionado);
                }
                if ((typeof this.croquiProducaoMudaClonalEucalyptusSelecionado.anexos !== 'undefined') && this.croquiProducaoMudaClonalEucalyptusSelecionado.anexos) {
                    let anexosAgrupados = new Array();
                    angular.forEach(this.croquiProducaoMudaClonalEucalyptusSelecionado.anexos, (ged) => {
                        if (!anexosAgrupados.some((o) => o && o.id && (o.id === ged.id))) {
                            anexosAgrupados.push(ged);
                        }
                    });
                    this.croquiProducaoMudaClonalEucalyptusSelecionado.anexos = anexosAgrupados;
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.ocultarModalCroqui();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    exportarPlanilhaCroqui() {
        return __awaiter(this, void 0, void 0, function* () {
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
            try {
                if ((typeof globalThis.luckysheet === 'undefined') || !globalThis.luckysheet) {
                    throw new Error('Plugin "luckysheet" não carregado.');
                }
                LuckysheetUtil_1.LuckysheetUtil.exportXLSX(globalThis.luckysheet);
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    salvarCroqui() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                this.modalPlantioMacroestacas = false;
                this.modalPlantioMiniestacas = false;
                this.modalAvaliacaoSobrevivencia = false;
                this.modalEliminarMiniMacroEstacas = false;
                this.modalManejo = false;
                if (typeof this.dadosCroqui.id === 'undefined' || !this.dadosCroqui.id) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_CROQUI)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_CROQUI)
                        .criar(this.dadosCroqui);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_CROQUI)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_CROQUI)
                        .atualizar(this.dadosCroqui);
                }
                if ((typeof this.dadosCroqui.numCanaletao !== 'undefined') && this.dadosCroqui.numCanaletao &&
                    (typeof this.dadosCroqui.numLinhas !== 'undefined') && this.dadosCroqui.numLinhas &&
                    (typeof globalThis.luckysheet !== 'undefined') && globalThis.luckysheet &&
                    (typeof globalThis.luckysheet.getCellValue !== 'undefined')) {
                    let ultimaColunaPreenchidaIdx = 0;
                    for (let linhaIdx = 0; linhaIdx <= 10; linhaIdx++) {
                        for (let colunaIdx = 0; colunaIdx <= 50; colunaIdx++) {
                            let valor = globalThis.luckysheet.getCellValue(linhaIdx, colunaIdx);
                            if ((typeof valor !== 'undefined') && valor) {
                                if (colunaIdx > ultimaColunaPreenchidaIdx) {
                                    ultimaColunaPreenchidaIdx = colunaIdx;
                                }
                            }
                        }
                    }
                    const colunaBaseIdx = (ultimaColunaPreenchidaIdx === 0) ? 1 : (ultimaColunaPreenchidaIdx + 2);
                    let largurasColunasPlanilhaCroqui = {};
                    globalThis.luckysheet.setCellValue(1, colunaBaseIdx + 1, this.dadosCroqui.numCanaletao.descricao);
                    globalThis.luckysheet.setCellValue(1, colunaBaseIdx + 3, this.dadosCroqui.minijardimEucalyptus.especie.descricao);
                    globalThis.luckysheet.setCellValue(2, colunaBaseIdx, 'LINHA');
                    globalThis.luckysheet.setCellValue(2, colunaBaseIdx + 1, 'CANALETÃO');
                    globalThis.luckysheet.setCellValue(2, colunaBaseIdx + 2, 'CLONE');
                    globalThis.luckysheet.setCellValue(2, colunaBaseIdx + 3, 'ESPÉCIE');
                    globalThis.luckysheet.setCellValue(2, colunaBaseIdx + 4, 'Nº CEPAS');
                    largurasColunasPlanilhaCroqui[colunaBaseIdx + 1] = 110;
                    for (let linha = 1; linha <= this.dadosCroqui.numLinhas; linha++) {
                        const linhaIdx = linha + 2;
                        globalThis.luckysheet.setCellValue(linhaIdx, colunaBaseIdx, linha);
                        globalThis.luckysheet.setCellValue(linhaIdx, colunaBaseIdx + 1, TextoUtil_1.TextoUtil.extrairNumeros(this.dadosCroqui.numCanaletao.descricao));
                        globalThis.luckysheet.setCellValue(linhaIdx, colunaBaseIdx + 3, this.dadosCroqui.minijardimEucalyptus.especie.descricao);
                    }
                    for (let linha = 1; linha <= this.dadosCroqui.numLinhas + 1; linha++) {
                        const linhaIdx = linha + 1;
                        for (let coluna = 0; coluna <= 4; coluna++) {
                            globalThis.luckysheet.setCellFormat(linhaIdx, colunaBaseIdx + coluna, 'bd', { borderType: 'border-all', style: '1', color: '#000' });
                        }
                    }
                    globalThis.luckysheet.setColumnWidth(largurasColunasPlanilhaCroqui);
                    yield this.salvarPlanilhaCroqui();
                }
                if (this.ehCelular) {
                    alert(`Salvo com sucesso!`);
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        icon: 'info',
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.ocultarModalCroqui();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
                this.ocultarModalCroqui();
            }
            finally {
                this.ocultarModalCroqui();
            }
        });
    }
    abrirModalCroqui(id = null) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.dadosCroqui = new ProducaoMudaClonalMinijardimCroqui_1.ProducaoMudaClonalMinijardimCroqui();
                if (typeof id !== 'undefined' && id) {
                    this.dadosCroqui = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_CROQUI)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_CROQUI)
                        .id(id)
                        .ver();
                }
                this.modalCroqui = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalCroqui() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalCroqui = false;
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    excluirCroqui(id) {
        return __awaiter(this, void 0, void 0, function* () {
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            const ctrl = this;
            this.notificacao
                .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                .then((result) => __awaiter(this, void 0, void 0, function* () {
                if (result.value) {
                    CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_CROQUI)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_CROQUI)
                        .id(id)
                        .remover();
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.listaOpcoesCroqui = yield ctrl.dadosListaCroqui();
                    ctrl.scopeApply();
                }
            }));
        });
    }
    salvarPlantioMacroestacas() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                this.modalCroqui = false;
                this.modalPlantioMiniestacas = false;
                this.modalAvaliacaoSobrevivencia = false;
                this.modalEliminarMiniMacroEstacas = false;
                this.modalManejo = false;
                if (typeof this.dadosPlantioMacroestacas.id === 'undefined' || !this.dadosPlantioMacroestacas.id) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_PLANTIO_MACROESTACAS)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_PLANTIO_MACROESTACAS)
                        .criar(this.dadosPlantioMacroestacas);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_PLANTIO_MACROESTACAS)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_PLANTIO_MACROESTACAS)
                        .atualizar(this.dadosPlantioMacroestacas);
                }
                if ((typeof this.dadosPlantioMacroestacas.numCanaletao !== 'undefined') && this.dadosPlantioMacroestacas.numCanaletao &&
                    (typeof this.dadosPlantioMacroestacas.listaMaterialPlantado !== 'undefined') && this.dadosPlantioMacroestacas.listaMaterialPlantado && this.dadosPlantioMacroestacas.listaMaterialPlantado.length > 0 &&
                    (typeof globalThis.luckysheet !== 'undefined') && globalThis.luckysheet &&
                    (typeof globalThis.luckysheet.getCellValue !== 'undefined')) {
                    let searchCanaletao = globalThis.luckysheet.find(this.dadosPlantioMacroestacas.numCanaletao.descricao || '');
                    if ((typeof searchCanaletao !== 'undefined') && searchCanaletao && searchCanaletao.length > 0) {
                        searchCanaletao.sort((a, b) => (a.row > b.row) ? 1 : ((b.row > a.row) ? -1 : 0));
                        let ultimaColunaPreenchidaIdx = (_a = searchCanaletao[0].column) !== null && _a !== void 0 ? _a : 0;
                        const colunaBaseIdx = (ultimaColunaPreenchidaIdx === 0) ? 1 : (ultimaColunaPreenchidaIdx - 1);
                        let largurasColunasPlanilhaCroqui = {};
                        largurasColunasPlanilhaCroqui[colunaBaseIdx + 1] = 110;
                        for (let linha = 1; linha <= this.dadosPlantioMacroestacas.listaMaterialPlantado.length; linha++) {
                            let itemMaterialPlantado = this.dadosPlantioMacroestacas.listaMaterialPlantado[linha - 1];
                            const linhaIdx = itemMaterialPlantado.linha + 2;
                            globalThis.luckysheet.setCellValue(linhaIdx, colunaBaseIdx + 2, itemMaterialPlantado.materialGenetico.descricao);
                            globalThis.luckysheet.setCellValue(linhaIdx, colunaBaseIdx + 3, this.dadosPlantioMacroestacas.minijardimEucalyptus.especie.descricao);
                            globalThis.luckysheet.setCellValue(linhaIdx, colunaBaseIdx + 4, itemMaterialPlantado.numMudas);
                            for (let coluna = 0; coluna <= 4; coluna++) {
                                globalThis.luckysheet.setCellFormat(linhaIdx, colunaBaseIdx + coluna, 'bd', { borderType: 'border-all', style: '1', color: '#000' });
                            }
                        }
                        globalThis.luckysheet.setColumnWidth(largurasColunasPlanilhaCroqui);
                        yield this.salvarPlanilhaCroqui();
                    }
                    else {
                        let ultimaColunaPreenchidaIdx = 0;
                        for (let linhaIdx = 0; linhaIdx <= 10; linhaIdx++) {
                            for (let colunaIdx = 0; colunaIdx <= 50; colunaIdx++) {
                                let valor = globalThis.luckysheet.getCellValue(linhaIdx, colunaIdx);
                                if ((typeof valor !== 'undefined') && valor) {
                                    if (colunaIdx > ultimaColunaPreenchidaIdx) {
                                        ultimaColunaPreenchidaIdx = colunaIdx;
                                    }
                                }
                            }
                        }
                        const colunaBaseIdx = (ultimaColunaPreenchidaIdx === 0) ? 1 : (ultimaColunaPreenchidaIdx + 2);
                        let largurasColunasPlanilhaCroqui = {};
                        globalThis.luckysheet.setCellValue(1, colunaBaseIdx + 1, this.dadosPlantioMacroestacas.numCanaletao.descricao);
                        globalThis.luckysheet.setCellValue(2, colunaBaseIdx, 'LINHA');
                        globalThis.luckysheet.setCellValue(2, colunaBaseIdx + 1, 'CANALETÃO');
                        globalThis.luckysheet.setCellValue(2, colunaBaseIdx + 2, 'CLONE');
                        globalThis.luckysheet.setCellValue(2, colunaBaseIdx + 3, 'ESPÉCIE');
                        globalThis.luckysheet.setCellValue(2, colunaBaseIdx + 4, 'Nº CEPAS');
                        largurasColunasPlanilhaCroqui[colunaBaseIdx + 1] = 110;
                        let maiorLinha = 0;
                        for (let linha = 1; linha <= this.dadosPlantioMacroestacas.listaMaterialPlantado.length; linha++) {
                            let itemMaterialPlantado = this.dadosPlantioMacroestacas.listaMaterialPlantado[linha - 1];
                            const linhaIdx = itemMaterialPlantado.linha + 2;
                            if (linhaIdx > maiorLinha) {
                                maiorLinha = linhaIdx;
                            }
                            globalThis.luckysheet.setCellValue(linhaIdx, colunaBaseIdx + 2, itemMaterialPlantado.materialGenetico.descricao);
                            globalThis.luckysheet.setCellValue(linhaIdx, colunaBaseIdx + 3, this.dadosPlantioMacroestacas.minijardimEucalyptus.especie.descricao);
                            globalThis.luckysheet.setCellValue(linhaIdx, colunaBaseIdx + 4, itemMaterialPlantado.numMudas);
                            for (let coluna = 0; coluna <= 4; coluna++) {
                                globalThis.luckysheet.setCellFormat(linhaIdx, colunaBaseIdx + coluna, 'bd', { borderType: 'border-all', style: '1', color: '#000' });
                            }
                        }
                        for (let linha = 1; linha <= maiorLinha - 1; linha++) {
                            const linhaIdx = linha + 2;
                            globalThis.luckysheet.setCellValue(linhaIdx, colunaBaseIdx, linha);
                            globalThis.luckysheet.setCellValue(linhaIdx, colunaBaseIdx + 1, TextoUtil_1.TextoUtil.extrairNumeros(this.dadosPlantioMacroestacas.numCanaletao.descricao));
                        }
                        for (let linha = 1; linha <= maiorLinha; linha++) {
                            const linhaIdx = linha + 1;
                            for (let coluna = 0; coluna <= 4; coluna++) {
                                globalThis.luckysheet.setCellFormat(linhaIdx, colunaBaseIdx + coluna, 'bd', { borderType: 'border-all', style: '1', color: '#000' });
                            }
                        }
                        globalThis.luckysheet.setColumnWidth(largurasColunasPlanilhaCroqui);
                        yield this.salvarPlanilhaCroqui();
                    }
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir um novo Plantio de Macroestacas ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir um novo Plantio de Macroestacas ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.ocultarModalPlantioMacroestacas();
                this.filtrarAnalises(true);
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
                this.ocultarModalPlantioMacroestacas();
            }
            finally {
                this.ocultarModalPlantioMacroestacas();
            }
        });
    }
    abrirModalPlantioMacroestacas(id = null) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (typeof id !== 'undefined' && id) {
                    this.dadosPlantioMacroestacas = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_PLANTIO_MACROESTACAS)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_PLANTIO_MACROESTACAS)
                        .id(id)
                        .ver();
                    if (this.dadosPlantioMacroestacas) {
                        if (typeof this.dadosPlantioMacroestacas.dataPlantio === 'string') {
                            this.dadosPlantioMacroestacas.dataPlantio = new Date(this.dadosPlantioMacroestacas.dataPlantio);
                        }
                    }
                }
                this.modalPlantioMacroestacas = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalPlantioMacroestacas() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalPlantioMacroestacas = false;
            this.dadosPlantioMacroestacas = new ProducaoMudaClonalMinijardimPlantioMacroestacas_1.ProducaoMudaClonalMinijardimPlantioMacroestacas();
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    excluirPlantioMacroestacas(id) {
        return __awaiter(this, void 0, void 0, function* () {
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            const ctrl = this;
            this.notificacao
                .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                .then((result) => __awaiter(this, void 0, void 0, function* () {
                if (result.value) {
                    CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_PLANTIO_MACROESTACAS)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_PLANTIO_MACROESTACAS)
                        .id(id)
                        .remover();
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.listaOpcoesPlantioMacroestacas = yield ctrl.dadosListaPlantioMacroestacas();
                    ctrl.scopeApply();
                }
            }));
        });
    }
    adicionarRegistroListaMateriaisPlantadosMacroestacas() {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.dadosPlantioMacroestacas === 'undefined' ||
                !this.dadosPlantioMacroestacas) {
                this.dadosPlantioMacroestacas = new ProducaoMudaClonalMinijardimPlantioMacroestacas_1.ProducaoMudaClonalMinijardimPlantioMacroestacas();
            }
            if (typeof this.dadosPlantioMacroestacas.listaMaterialPlantado === 'undefined' ||
                this.dadosPlantioMacroestacas.listaMaterialPlantado === null ||
                !this.dadosPlantioMacroestacas.listaMaterialPlantado) {
                this.dadosPlantioMacroestacas.listaMaterialPlantado = new Array();
            }
            this.dadosPlantioMacroestacas.listaMaterialPlantado.push(new MaterialPlantado_1.MaterialPlantado());
            this.scopeApply();
        });
    }
    removerRegistroListaMateriaisPlantadosMacroestacas(key) {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.dadosPlantioMacroestacas.listaMaterialPlantado === 'undefined' ||
                this.dadosPlantioMacroestacas.listaMaterialPlantado === null) {
                this.dadosPlantioMacroestacas.listaMaterialPlantado = new Array();
            }
            if (typeof key !== 'undefined' &&
                key !== null &&
                this.dadosPlantioMacroestacas.listaMaterialPlantado.length > 0 &&
                key <= this.dadosPlantioMacroestacas.listaMaterialPlantado.length - 1) {
                this.dadosPlantioMacroestacas.listaMaterialPlantado.splice(key, 1);
                this.scopeApply();
            }
        });
    }
    moverRegistroListaMateriaisPlantadosMacroestacasParaCima(key) {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.dadosPlantioMacroestacas.listaMaterialPlantado === 'undefined' ||
                !this.dadosPlantioMacroestacas.listaMaterialPlantado) {
                return;
            }
            if (key != null && key >= 0 && key < this.dadosPlantioMacroestacas.listaMaterialPlantado.length - 1) {
                let oldKey = key;
                let newKey = key - 1;
                this.dadosPlantioMacroestacas.listaMaterialPlantado.splice(newKey, 0, this.dadosPlantioMacroestacas.listaMaterialPlantado.splice(oldKey, 1)[0]);
                this.scopeApply();
            }
        });
    }
    moverRegistroListaMateriaisPlantadosMacroestacasParaBaixo(key) {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.dadosPlantioMacroestacas.listaMaterialPlantado === 'undefined' ||
                !this.dadosPlantioMacroestacas.listaMaterialPlantado) {
                return;
            }
            if (key != null && key >= 0 && key < this.dadosPlantioMacroestacas.listaMaterialPlantado.length - 1) {
                let oldKey = key;
                let newKey = key + 1;
                this.dadosPlantioMacroestacas.listaMaterialPlantado.splice(newKey, 0, this.dadosPlantioMacroestacas.listaMaterialPlantado.splice(oldKey, 1)[0]);
                this.scopeApply();
            }
        });
    }
    salvarPlantioMiniestacas() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                this.modalCroqui = false;
                this.modalPlantioMacroestacas = false;
                this.modalAvaliacaoSobrevivencia = false;
                this.modalEliminarMiniMacroEstacas = false;
                this.modalManejo = false;
                if (typeof this.dadosPlantioMiniestacas.id === 'undefined' || !this.dadosPlantioMiniestacas.id) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_PLANTIO_MINIESTACAS)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_PLANTIO_MINIESTACAS)
                        .criar(this.dadosPlantioMiniestacas);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_PLANTIO_MINIESTACAS)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_PLANTIO_MINIESTACAS)
                        .atualizar(this.dadosPlantioMiniestacas);
                }
                if ((typeof this.dadosPlantioMiniestacas.numCanaletao !== 'undefined') && this.dadosPlantioMiniestacas.numCanaletao &&
                    (typeof this.dadosPlantioMiniestacas.listaMaterialPlantado !== 'undefined') && this.dadosPlantioMiniestacas.listaMaterialPlantado && this.dadosPlantioMiniestacas.listaMaterialPlantado.length > 0 &&
                    (typeof globalThis.luckysheet !== 'undefined') && globalThis.luckysheet &&
                    (typeof globalThis.luckysheet.getCellValue !== 'undefined')) {
                    let searchCanaletao = globalThis.luckysheet.find(this.dadosPlantioMiniestacas.numCanaletao.descricao || '');
                    if ((typeof searchCanaletao !== 'undefined') && searchCanaletao && searchCanaletao.length > 0) {
                        searchCanaletao.sort((a, b) => (a.row > b.row) ? 1 : ((b.row > a.row) ? -1 : 0));
                        let ultimaColunaPreenchidaIdx = (_a = searchCanaletao[0].column) !== null && _a !== void 0 ? _a : 0;
                        const colunaBaseIdx = (ultimaColunaPreenchidaIdx === 0) ? 1 : (ultimaColunaPreenchidaIdx - 1);
                        let largurasColunasPlanilhaCroqui = {};
                        largurasColunasPlanilhaCroqui[colunaBaseIdx + 1] = 110;
                        for (let linha = 1; linha <= this.dadosPlantioMiniestacas.listaMaterialPlantado.length; linha++) {
                            let itemMaterialPlantado = this.dadosPlantioMiniestacas.listaMaterialPlantado[linha - 1];
                            const linhaIdx = itemMaterialPlantado.linha + 2;
                            globalThis.luckysheet.setCellValue(linhaIdx, colunaBaseIdx + 2, itemMaterialPlantado.materialGenetico.descricao);
                            globalThis.luckysheet.setCellValue(linhaIdx, colunaBaseIdx + 3, this.dadosPlantioMiniestacas.minijardimEucalyptus.especie.descricao);
                            globalThis.luckysheet.setCellValue(linhaIdx, colunaBaseIdx + 4, itemMaterialPlantado.numMudas);
                            for (let coluna = 0; coluna <= 4; coluna++) {
                                globalThis.luckysheet.setCellFormat(linhaIdx, colunaBaseIdx + coluna, 'bd', { borderType: 'border-all', style: '1', color: '#000' });
                            }
                        }
                        globalThis.luckysheet.setColumnWidth(largurasColunasPlanilhaCroqui);
                        yield this.salvarPlanilhaCroqui();
                    }
                    else {
                        let ultimaColunaPreenchidaIdx = 0;
                        for (let linhaIdx = 0; linhaIdx <= 10; linhaIdx++) {
                            for (let colunaIdx = 0; colunaIdx <= 50; colunaIdx++) {
                                let valor = globalThis.luckysheet.getCellValue(linhaIdx, colunaIdx);
                                if ((typeof valor !== 'undefined') && valor) {
                                    if (colunaIdx > ultimaColunaPreenchidaIdx) {
                                        ultimaColunaPreenchidaIdx = colunaIdx;
                                    }
                                }
                            }
                        }
                        const colunaBaseIdx = (ultimaColunaPreenchidaIdx === 0) ? 1 : (ultimaColunaPreenchidaIdx + 2);
                        let largurasColunasPlanilhaCroqui = {};
                        globalThis.luckysheet.setCellValue(1, colunaBaseIdx + 1, this.dadosPlantioMiniestacas.numCanaletao.descricao);
                        globalThis.luckysheet.setCellValue(2, colunaBaseIdx, 'LINHA');
                        globalThis.luckysheet.setCellValue(2, colunaBaseIdx + 1, 'CANALETÃO');
                        globalThis.luckysheet.setCellValue(2, colunaBaseIdx + 2, 'CLONE');
                        globalThis.luckysheet.setCellValue(2, colunaBaseIdx + 3, 'ESPÉCIE');
                        globalThis.luckysheet.setCellValue(2, colunaBaseIdx + 4, 'Nº CEPAS');
                        largurasColunasPlanilhaCroqui[colunaBaseIdx + 1] = 110;
                        let maiorLinha = 0;
                        for (let linha = 1; linha <= this.dadosPlantioMiniestacas.listaMaterialPlantado.length; linha++) {
                            let itemMaterialPlantado = this.dadosPlantioMiniestacas.listaMaterialPlantado[linha - 1];
                            const linhaIdx = itemMaterialPlantado.linha + 2;
                            if (linhaIdx > maiorLinha) {
                                maiorLinha = linhaIdx;
                            }
                            globalThis.luckysheet.setCellValue(linhaIdx, colunaBaseIdx + 2, itemMaterialPlantado.materialGenetico.descricao);
                            globalThis.luckysheet.setCellValue(linhaIdx, colunaBaseIdx + 3, this.dadosPlantioMiniestacas.minijardimEucalyptus.especie.descricao);
                            globalThis.luckysheet.setCellValue(linhaIdx, colunaBaseIdx + 4, itemMaterialPlantado.numMudas);
                            for (let coluna = 0; coluna <= 4; coluna++) {
                                globalThis.luckysheet.setCellFormat(linhaIdx, colunaBaseIdx + coluna, 'bd', { borderType: 'border-all', style: '1', color: '#000' });
                            }
                        }
                        for (let linha = 1; linha <= maiorLinha - 1; linha++) {
                            const linhaIdx = linha + 2;
                            globalThis.luckysheet.setCellValue(linhaIdx, colunaBaseIdx, linha);
                            globalThis.luckysheet.setCellValue(linhaIdx, colunaBaseIdx + 1, TextoUtil_1.TextoUtil.extrairNumeros(this.dadosPlantioMiniestacas.numCanaletao.descricao));
                        }
                        for (let linha = 1; linha <= maiorLinha; linha++) {
                            const linhaIdx = linha + 1;
                            for (let coluna = 0; coluna <= 4; coluna++) {
                                globalThis.luckysheet.setCellFormat(linhaIdx, colunaBaseIdx + coluna, 'bd', { borderType: 'border-all', style: '1', color: '#000' });
                            }
                        }
                        globalThis.luckysheet.setColumnWidth(largurasColunasPlanilhaCroqui);
                        yield this.salvarPlanilhaCroqui();
                    }
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir um novo Plantio de Miniestaca ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir um novo Plantio de Miniestaca ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.ocultarModalPlantioMiniestacas();
                this.filtrarAnalises(true);
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
                this.ocultarModalPlantioMiniestacas();
            }
            finally {
                this.ocultarModalPlantioMiniestacas();
            }
        });
    }
    abrirModalPlantioMiniestacas(id = null) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (typeof id !== 'undefined' && id) {
                    this.dadosPlantioMiniestacas = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_PLANTIO_MINIESTACAS)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_PLANTIO_MINIESTACAS)
                        .id(id)
                        .ver();
                    if (this.dadosPlantioMiniestacas) {
                        if (typeof this.dadosPlantioMiniestacas.dataPlantio === 'string') {
                            this.dadosPlantioMiniestacas.dataPlantio = new Date(this.dadosPlantioMiniestacas.dataPlantio);
                        }
                    }
                }
                this.modalPlantioMiniestacas = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalPlantioMiniestacas() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalPlantioMiniestacas = false;
            this.dadosPlantioMiniestacas = new ProducaoMudaClonalMinijardimPlantioMiniestacas_1.ProducaoMudaClonalMinijardimPlantioMiniestacas();
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    excluirPlantioMiniestacas(id) {
        return __awaiter(this, void 0, void 0, function* () {
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            const ctrl = this;
            this.notificacao
                .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                .then((result) => __awaiter(this, void 0, void 0, function* () {
                if (result.value) {
                    CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_PLANTIO_MINIESTACAS)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_PLANTIO_MINIESTACAS)
                        .id(id)
                        .remover();
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.listaOpcoesPlantioMiniestacas = yield ctrl.dadosListaPlantioMiniestacas();
                    ctrl.scopeApply();
                }
            }));
        });
    }
    adicionarRegistroListaMateriaisPlantadosMiniestacas() {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.dadosPlantioMiniestacas === 'undefined' ||
                !this.dadosPlantioMiniestacas) {
                this.dadosPlantioMiniestacas = new ProducaoMudaClonalMinijardimPlantioMiniestacas_1.ProducaoMudaClonalMinijardimPlantioMiniestacas();
            }
            if (typeof this.dadosPlantioMiniestacas.listaMaterialPlantado === 'undefined' ||
                this.dadosPlantioMiniestacas.listaMaterialPlantado === null ||
                !this.dadosPlantioMiniestacas.listaMaterialPlantado) {
                this.dadosPlantioMiniestacas.listaMaterialPlantado = new Array();
            }
            this.dadosPlantioMiniestacas.listaMaterialPlantado.push(new MaterialPlantado_1.MaterialPlantado());
            this.scopeApply();
        });
    }
    removerRegistroListaMateriaisPlantadosMiniestacas(key) {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.dadosPlantioMiniestacas.listaMaterialPlantado === 'undefined' ||
                this.dadosPlantioMiniestacas.listaMaterialPlantado === null) {
                this.dadosPlantioMiniestacas.listaMaterialPlantado = new Array();
            }
            if (typeof key !== 'undefined' &&
                key !== null &&
                this.dadosPlantioMiniestacas.listaMaterialPlantado.length > 0 &&
                key <= this.dadosPlantioMiniestacas.listaMaterialPlantado.length - 1) {
                this.dadosPlantioMiniestacas.listaMaterialPlantado.splice(key, 1);
                this.scopeApply();
            }
        });
    }
    moverRegistroListaMateriaisPlantadosMiniestacasParaCima(key) {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.dadosPlantioMiniestacas.listaMaterialPlantado === 'undefined' ||
                !this.dadosPlantioMiniestacas.listaMaterialPlantado) {
                return;
            }
            if (key != null && key >= 0 && key < this.dadosPlantioMiniestacas.listaMaterialPlantado.length - 1) {
                let oldKey = key;
                let newKey = key - 1;
                this.dadosPlantioMiniestacas.listaMaterialPlantado.splice(newKey, 0, this.dadosPlantioMiniestacas.listaMaterialPlantado.splice(oldKey, 1)[0]);
                this.scopeApply();
            }
        });
    }
    moverRegistroListaMateriaisPlantadosMiniestacasParaBaixo(key) {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.dadosPlantioMiniestacas.listaMaterialPlantado === 'undefined' ||
                !this.dadosPlantioMiniestacas.listaMaterialPlantado) {
                return;
            }
            if (key != null && key >= 0 && key < this.dadosPlantioMiniestacas.listaMaterialPlantado.length - 1) {
                let oldKey = key;
                let newKey = key + 1;
                this.dadosPlantioMiniestacas.listaMaterialPlantado.splice(newKey, 0, this.dadosPlantioMiniestacas.listaMaterialPlantado.splice(oldKey, 1)[0]);
                this.scopeApply();
            }
        });
    }
    salvarAvaliacaoSobrevivencia() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                this.modalCroqui = false;
                this.modalPlantioMacroestacas = false;
                this.modalPlantioMiniestacas = false;
                this.modalEliminarMiniMacroEstacas = false;
                this.modalManejo = false;
                if (typeof this.dadosAvaliacaoSobrevivencia.id === 'undefined' || !this.dadosAvaliacaoSobrevivencia.id) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_AVALIACAO_SOBREVIVENCIA)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_AVALIACAO_SOBREVIVENCIA)
                        .criar(this.dadosAvaliacaoSobrevivencia);
                    this.dadosAvaliacaoSobrevivencia.minijardimEucalyptus.numMudas -= (_a = this.dadosAvaliacaoSobrevivencia.numCepasMortas) !== null && _a !== void 0 ? _a : 0;
                    this.modelo = this.dadosAvaliacaoSobrevivencia.minijardimEucalyptus;
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM)
                        .atualizar(this.modelo);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_AVALIACAO_SOBREVIVENCIA)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_AVALIACAO_SOBREVIVENCIA)
                        .atualizar(this.dadosAvaliacaoSobrevivencia);
                    this.dadosAvaliacaoSobrevivencia.minijardimEucalyptus.numMudas -= (_b = this.dadosAvaliacaoSobrevivencia.numCepasMortas) !== null && _b !== void 0 ? _b : 0;
                    this.modelo = this.dadosAvaliacaoSobrevivencia.minijardimEucalyptus;
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM)
                        .atualizar(this.modelo);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir uma nova Avaliação de Sobrevivência ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir uma nova Avaliação de Sobrevivência ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.ocultarAvaliacaoSobrevivencia();
                this.filtrarAnalises(true);
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
                this.ocultarAvaliacaoSobrevivencia();
            }
            finally {
                this.ocultarAvaliacaoSobrevivencia();
            }
        });
    }
    abrirModalAvaliacaoSobrevivencia(id = null) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (typeof id !== 'undefined' && id) {
                    this.dadosAvaliacaoSobrevivencia = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_AVALIACAO_SOBREVIVENCIA)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_AVALIACAO_SOBREVIVENCIA)
                        .id(id)
                        .ver();
                    if (this.dadosAvaliacaoSobrevivencia) {
                        if (typeof this.dadosAvaliacaoSobrevivencia.data === 'string') {
                            this.dadosAvaliacaoSobrevivencia.data = new Date(this.dadosAvaliacaoSobrevivencia.data);
                        }
                    }
                }
                this.modalAvaliacaoSobrevivencia = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarAvaliacaoSobrevivencia() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalAvaliacaoSobrevivencia = false;
            this.dadosAvaliacaoSobrevivencia = new ProducaoMudaClonalMinijardimAvaliacaoSobrevivencia_1.ProducaoMudaClonalMinijardimAvaliacaoSobrevivencia();
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    excluirAvaliacaoSobrevivencia(id) {
        return __awaiter(this, void 0, void 0, function* () {
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            const ctrl = this;
            this.notificacao
                .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                .then((result) => __awaiter(this, void 0, void 0, function* () {
                if (result.value) {
                    CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_AVALIACAO_SOBREVIVENCIA)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_AVALIACAO_SOBREVIVENCIA)
                        .id(id)
                        .remover();
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.listaOpcoesAvaliacaoSobrevivencia = yield ctrl.dadosListaAvaliacaoSobrevivencia();
                    ctrl.scopeApply();
                }
            }));
        });
    }
    salvarEliminarMiniMacroEstacas() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                this.modalCroqui = false;
                this.modalPlantioMacroestacas = false;
                this.modalPlantioMiniestacas = false;
                this.modalAvaliacaoSobrevivencia = false;
                this.modalManejo = false;
                if (typeof this.dadosEliminarMiniMacroEstacas.id === 'undefined' || !this.dadosEliminarMiniMacroEstacas.id) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_ELIMINAR_MINIMACRO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_ELIMINAR_MINIMACRO)
                        .criar(this.dadosEliminarMiniMacroEstacas);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_ELIMINAR_MINIMACRO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_ELIMINAR_MINIMACRO)
                        .atualizar(this.dadosEliminarMiniMacroEstacas);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir um novo Eliminar Mini/Macroestacas ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir um novo Eliminar Mini/Macroestacas ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.ocultarModalEliminarMiniMacroEstacas();
                this.filtrarAnalises(true);
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
                this.ocultarModalEliminarMiniMacroEstacas();
            }
            finally {
                this.ocultarModalEliminarMiniMacroEstacas();
            }
        });
    }
    abrirModalEliminarMiniMacroEstacas(id = null) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (typeof id !== 'undefined' && id) {
                    this.dadosEliminarMiniMacroEstacas = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_ELIMINAR_MINIMACRO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_ELIMINAR_MINIMACRO)
                        .id(id)
                        .ver();
                    if (this.dadosEliminarMiniMacroEstacas) {
                        if (typeof this.dadosEliminarMiniMacroEstacas.data === 'string') {
                            this.dadosEliminarMiniMacroEstacas.data = new Date(this.dadosEliminarMiniMacroEstacas.data);
                        }
                    }
                }
                this.modalEliminarMiniMacroEstacas = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalEliminarMiniMacroEstacas() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalEliminarMiniMacroEstacas = false;
            this.dadosEliminarMiniMacroEstacas = new ProducaoMudaClonalMinijardimEliminarMiniMacroEstacas_1.ProducaoMudaClonalMinijardimEliminarMiniMacroEstacas();
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    excluirEliminarMiniMacroEstacas(id) {
        return __awaiter(this, void 0, void 0, function* () {
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            const ctrl = this;
            this.notificacao
                .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                .then((result) => __awaiter(this, void 0, void 0, function* () {
                if (result.value) {
                    CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_ELIMINAR_MINIMACRO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_ELIMINAR_MINIMACRO)
                        .id(id)
                        .remover();
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.listaOpcoesEliminarMiniMacroEstacas = yield ctrl.dadosListaEliminarMiniMacroEstacas();
                    ctrl.scopeApply();
                }
            }));
        });
    }
    salvarManejo() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                this.modalCroqui = false;
                this.modalPlantioMacroestacas = false;
                this.modalPlantioMiniestacas = false;
                this.modalAvaliacaoSobrevivencia = false;
                this.modalEliminarMiniMacroEstacas = false;
                if (typeof this.dadosManejo.id === 'undefined' || !this.dadosManejo.id) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_MANEJO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_MANEJO)
                        .criar(this.dadosManejo);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_MANEJO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_MANEJO)
                        .atualizar(this.dadosManejo);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir um novo Manejo ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir um novo Manejo ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.ocultarModalManejo();
                this.filtrarAnalises(true);
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
                this.ocultarModalManejo();
            }
            finally {
                this.ocultarModalManejo();
            }
        });
    }
    abrirModalManejo(id = null) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (typeof id !== 'undefined' && id) {
                    this.dadosManejo = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_MANEJO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_MANEJO)
                        .id(id)
                        .ver();
                    if (this.dadosManejo) {
                        if (typeof this.dadosManejo.data === 'string') {
                            this.dadosManejo.data = new Date(this.dadosManejo.data);
                        }
                    }
                }
                this.modalManejo = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalManejo() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalManejo = false;
            this.dadosManejo = new ProducaoMudaClonalMinijardimManejo_1.ProducaoMudaClonalMinijardimManejo();
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    excluirManejo(id) {
        return __awaiter(this, void 0, void 0, function* () {
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            const ctrl = this;
            this.notificacao
                .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                .then((result) => __awaiter(this, void 0, void 0, function* () {
                if (result.value) {
                    CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_MANEJO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_MANEJO)
                        .id(id)
                        .remover();
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.listaOpcoesManejo = yield ctrl.dadosListaManejo();
                    ctrl.scopeApply();
                }
            }));
        });
    }
    adicionarRegistroListaControleEcManejo() {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.dadosManejo === 'undefined' ||
                !this.dadosManejo) {
                this.dadosManejo = new ProducaoMudaClonalMinijardimManejo_1.ProducaoMudaClonalMinijardimManejo();
            }
            if (typeof this.dadosManejo.listaDetalhamentoEC === 'undefined' ||
                this.dadosManejo.listaDetalhamentoEC === null ||
                !this.dadosManejo.listaDetalhamentoEC) {
                this.dadosManejo.listaDetalhamentoEC = new Array();
            }
            this.dadosManejo.listaDetalhamentoEC.push(new DetalhamentoEC_1.DetalhamentoEC());
            this.scopeApply();
        });
    }
    removerRegistroListaControleEcManejo(key) {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.dadosManejo.listaDetalhamentoEC === 'undefined' ||
                this.dadosManejo.listaDetalhamentoEC === null) {
                this.dadosManejo.listaDetalhamentoEC = new Array();
            }
            if (typeof key !== 'undefined' &&
                key !== null &&
                this.dadosManejo.listaDetalhamentoEC.length > 0 &&
                key <= this.dadosManejo.listaDetalhamentoEC.length - 1) {
                this.dadosManejo.listaDetalhamentoEC.splice(key, 1);
                this.scopeApply();
            }
        });
    }
    moverRegistroListaControleEcManejoParaCima(key) {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.dadosManejo.listaDetalhamentoEC === 'undefined' ||
                !this.dadosManejo.listaDetalhamentoEC) {
                return;
            }
            if (key != null && key >= 0 && key < this.dadosManejo.listaDetalhamentoEC.length - 1) {
                let oldKey = key;
                let newKey = key - 1;
                this.dadosManejo.listaDetalhamentoEC.splice(newKey, 0, this.dadosManejo.listaDetalhamentoEC.splice(oldKey, 1)[0]);
                this.scopeApply();
            }
        });
    }
    moverRegistroListaControleEcManejoParaBaixo(key) {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.dadosManejo.listaDetalhamentoEC === 'undefined' ||
                !this.dadosManejo.listaDetalhamentoEC) {
                return;
            }
            if (key != null && key >= 0 && key < this.dadosManejo.listaDetalhamentoEC.length - 1) {
                let oldKey = key;
                let newKey = key + 1;
                this.dadosManejo.listaDetalhamentoEC.splice(newKey, 0, this.dadosManejo.listaDetalhamentoEC.splice(oldKey, 1)[0]);
                this.scopeApply();
            }
        });
    }
    resetarManejo() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            ctrl.dadosManejo = null;
            ctrl.dadosManejo = new ProducaoMudaClonalMinijardimManejo_1.ProducaoMudaClonalMinijardimManejo();
        });
    }
    filtrarRelatorioRecomendacao() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            if ((typeof this.filtroDossieClone.clone !== 'undefined') && this.filtroDossieClone.clone) {
                let listaProducao = this.listaProducao;
                let listaEnraizamento = this.listaEnraizamento;
                let listaDataRelatorio = [];
                let listaDataProducaoRelatorio = [];
                let listaDataEnraizamentoRelatorio = [];
                let listaLabelsRelatorio = [];
                listaProducao.sort((a, b) => (a.data > b.data) ? 1 : ((b.data > a.data) ? -1 : 0));
                listaEnraizamento.sort((a, b) => (a.data > b.data) ? 1 : ((b.data > a.data) ? -1 : 0));
                const listaProducaoRelatorio = listaProducao.filter(producao => {
                    return ((typeof producao.clone.id !== 'undefined') && producao.clone.id &&
                        (typeof ctrl.filtroDossieClone.clone.producaoClone.clone.materialGenetico !== 'undefined') && ctrl.filtroDossieClone.clone.producaoClone.clone.materialGenetico &&
                        (producao.clone.id === ctrl.filtroDossieClone.clone.producaoClone.clone.materialGenetico.id));
                });
                const listaEnraizamentoRelatorio = listaEnraizamento.filter(enraizamento => {
                    return ((typeof enraizamento.clone.clone !== 'undefined') && enraizamento.clone.clone &&
                        (typeof ctrl.filtroDossieClone.clone.producaoClone.clone.materialGenetico !== 'undefined') && ctrl.filtroDossieClone.clone.producaoClone.clone.materialGenetico &&
                        (String(enraizamento.clone.clone) === ctrl.filtroDossieClone.clone.producaoClone.clone.materialGenetico.id));
                });
                angular.forEach(listaProducaoRelatorio, (producao) => {
                    var _a, _b;
                    producao.dataStr = producao.data;
                    let itemDataRelatorio = listaDataRelatorio.find(o => o.data === producao.data);
                    if ((typeof itemDataRelatorio === 'undefined') && !itemDataRelatorio) {
                        listaDataRelatorio.push({
                            data: producao.data,
                            enraizamento: 0,
                            producao: (_a = producao.numMiniestacasCepa) !== null && _a !== void 0 ? _a : 0,
                            totalProducao: 1,
                            totalEnraizamento: 0
                        });
                    }
                    else {
                        itemDataRelatorio.enraizamento += 0;
                        itemDataRelatorio.producao += (_b = producao.numMiniestacasCepa) !== null && _b !== void 0 ? _b : 0;
                        itemDataRelatorio.totalProducao += 1;
                        itemDataRelatorio.totalEnraizamento += 0;
                    }
                });
                angular.forEach(listaEnraizamentoRelatorio, (enraizamento) => {
                    var _a;
                    enraizamento.dataStr = enraizamento.data;
                    let itemDataRelatorio = listaDataRelatorio.find(o => o.data === enraizamento.data);
                    if ((typeof itemDataRelatorio === 'undefined') || !itemDataRelatorio) {
                        listaDataRelatorio.push({
                            data: enraizamento.data,
                            enraizamento: (_a = enraizamento.porcentagemEnraizamento) !== null && _a !== void 0 ? _a : 0,
                            producao: 0,
                            totalProducao: 0,
                            totalEnraizamento: 1
                        });
                    }
                    else {
                        itemDataRelatorio.enraizamento += enraizamento.porcentagemEnraizamento;
                        itemDataRelatorio.producao += 0;
                        itemDataRelatorio.totalEnraizamento += 1;
                        itemDataRelatorio.totalProducao += 0;
                    }
                });
                angular.forEach(listaDataRelatorio, (dataRelatorio) => {
                    if ((typeof dataRelatorio.data !== 'undefined') && dataRelatorio.data) {
                        if (dataRelatorio.totalProducao > 0) {
                            listaDataProducaoRelatorio.push(Number((dataRelatorio.producao / dataRelatorio.totalProducao).toFixed(2)));
                        }
                        else {
                            listaDataProducaoRelatorio.push(Number((dataRelatorio.producao).toFixed(2)));
                        }
                        if (dataRelatorio.totalEnraizamento > 0) {
                            listaDataEnraizamentoRelatorio.push(Number((dataRelatorio.enraizamento / dataRelatorio.totalEnraizamento).toFixed(2)));
                        }
                        else {
                            listaDataEnraizamentoRelatorio.push(Number((dataRelatorio.enraizamento).toFixed(2)));
                        }
                        listaLabelsRelatorio.push(new Date(dataRelatorio.data).toLocaleDateString('pt-BR'));
                    }
                });
                yield this.dadosRelatorioRecomendacao(listaDataProducaoRelatorio, listaDataEnraizamentoRelatorio, listaLabelsRelatorio);
            }
            this.scopeApply();
        });
    }
    dadosRelatorioRecomendacao(listaDataProducaoRelatorio, listaDataEnraizamentoRelatorio, listaLabelsRelatorio) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.graficoRelatorioDossie !== 'undefined') && this.graficoRelatorioDossie) {
                this.graficoRelatorioDossie.destroy();
                this.graficoRelatorioDossie = null;
            }
            for (let cargaGrafico = 1; cargaGrafico <= 10; cargaGrafico++) {
                setTimeout((ctrl) => {
                    if (ctrl.graficoRelatorioDossie) {
                        return;
                    }
                    const graficoRelatorioDossieEl = document.getElementById('graficoRelatorioDossie');
                    if ((typeof graficoRelatorioDossieEl === 'undefined') || !graficoRelatorioDossieEl) {
                        return;
                    }
                    const graficoRelatorioDossieCtx = graficoRelatorioDossieEl.getContext('2d');
                    const graficoRelatorioDossieDataSets = [{
                            type: 'bar',
                            label: 'Porcentagem de Enraizamento',
                            data: listaDataEnraizamentoRelatorio,
                            backgroundColor: 'rgba(54, 162, 235, 0.2)',
                            borderColor: 'rgb(54, 162, 235)',
                            borderWidth: 1,
                            yAxisID: 'A',
                        }, {
                            type: 'line',
                            label: 'Nº Miniestacas por Minicepa',
                            data: listaDataProducaoRelatorio,
                            backgroundColor: 'rgb(255, 159, 64)',
                            borderColor: 'rgb(255, 159, 64)',
                            borderWidth: 1,
                            fill: false,
                            yAxisID: 'B',
                        }];
                    ctrl.graficoRelatorioDossie = new chart_js_1.Chart(graficoRelatorioDossieCtx, {
                        type: 'bar',
                        data: {
                            labels: listaLabelsRelatorio,
                            datasets: graficoRelatorioDossieDataSets
                        },
                        plugins: [globalThis.ChartDataLabels],
                        options: {
                            responsive: true,
                            legend: {
                                display: false
                            },
                            layout: {
                                padding: {
                                    right: 30
                                }
                            },
                            scales: {
                                gridLines: { offsetGridLines: true },
                                xAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    }],
                                yAxes: [{
                                        id: 'A',
                                        type: 'linear',
                                        position: 'left',
                                        ticks: {
                                            min: 0
                                        }
                                    }, {
                                        id: 'B',
                                        type: 'linear',
                                        position: 'right',
                                        ticks: {
                                            min: 0
                                        }
                                    }]
                            }
                        }
                    });
                }, cargaGrafico * 1000, this);
            }
            this.scopeApply();
        });
    }
    dadosRelatorioEspecie(listaEspecies, listaQuantidade) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.graficoRelatorioEspecie !== 'undefined') && this.graficoRelatorioEspecie) {
                this.graficoRelatorioEspecie.destroy();
                this.graficoRelatorioEspecie = null;
            }
            for (let cargaGrafico = 1; cargaGrafico <= 10; cargaGrafico++) {
                setTimeout((ctrl) => {
                    if (ctrl.graficoRelatorioEspecie) {
                        return;
                    }
                    const graficoRelatorioEspecieEl = document.getElementById('graficoRelatorioEspecie');
                    if ((typeof graficoRelatorioEspecieEl === 'undefined') || !graficoRelatorioEspecieEl) {
                        return;
                    }
                    const graficoRelatorioEspecieCtx = graficoRelatorioEspecieEl.getContext('2d');
                    const graficoRelatorioEspecieDataSets = [{
                            label: '',
                            data: listaQuantidade,
                            backgroundColor: 'rgb(98,163,78)',
                            borderWidth: 1,
                            datalabels: {
                                display: true,
                                font: { size: 11 },
                                align: 'right',
                                padding: { left: 3, right: 3 },
                                backgroundColor: 'rgba(255,255,255,.3)',
                                textShadowBlur: 2,
                                textShadowColor: '#fff'
                            }
                        }];
                    ctrl.graficoRelatorioEspecie = new chart_js_1.Chart(graficoRelatorioEspecieCtx, {
                        type: 'horizontalBar',
                        data: {
                            labels: listaEspecies,
                            datasets: graficoRelatorioEspecieDataSets
                        },
                        plugins: [globalThis.ChartDataLabels],
                        options: {
                            title: {
                                display: true,
                                text: 'Nº de clones por espécie',
                                position: 'top'
                            },
                            responsive: true,
                            legend: {
                                display: false
                            },
                            layout: {
                                padding: {
                                    right: 30
                                }
                            },
                            scales: {
                                gridLines: { offsetGridLines: true },
                                xAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    }],
                                yAxes: [{
                                        ticks: {
                                            callback: function (value) {
                                                if (value.length > 20) {
                                                    return value.substr(0, 20) + '...';
                                                }
                                                else {
                                                    return value;
                                                }
                                            },
                                        }
                                    }]
                            }
                        }
                    });
                }, cargaGrafico * 1000, this);
            }
            this.scopeApply();
        });
    }
    dadosRelatorioFinalidade(listaFinalidade, listaQuantidade) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.graficoRelatorioFinalidade !== 'undefined') && this.graficoRelatorioFinalidade) {
                this.graficoRelatorioFinalidade.destroy();
                this.graficoRelatorioFinalidade = null;
            }
            for (let cargaGrafico = 1; cargaGrafico <= 10; cargaGrafico++) {
                setTimeout((ctrl) => {
                    if (ctrl.graficoRelatorioFinalidade) {
                        return;
                    }
                    const graficoRelatorioFinalidadeEl = document.getElementById('graficoRelatorioFinalidade');
                    if ((typeof graficoRelatorioFinalidadeEl === 'undefined') || !graficoRelatorioFinalidadeEl) {
                        return;
                    }
                    const graficoRelatorioFinalidadeCtx = graficoRelatorioFinalidadeEl.getContext('2d');
                    const graficoRelatorioFinalidadeDataSets = [{
                            label: '',
                            data: listaQuantidade,
                            backgroundColor: 'rgb(98,163,78)',
                            borderWidth: 1,
                            datalabels: {
                                display: true,
                                font: { size: 11 },
                                align: 'right',
                                padding: { left: 3, right: 3 },
                                backgroundColor: 'rgba(255,255,255,.3)',
                                textShadowBlur: 2,
                                textShadowColor: '#fff'
                            }
                        }];
                    ctrl.graficoRelatorioFinalidade = new chart_js_1.Chart(graficoRelatorioFinalidadeCtx, {
                        type: 'horizontalBar',
                        data: {
                            labels: listaFinalidade,
                            datasets: graficoRelatorioFinalidadeDataSets
                        },
                        plugins: [globalThis.ChartDataLabels],
                        options: {
                            title: {
                                display: true,
                                text: 'Nº de clones por finalidade',
                                position: 'top'
                            },
                            responsive: true,
                            legend: {
                                display: false
                            },
                            layout: {
                                padding: {
                                    right: 30
                                }
                            },
                            scales: {
                                gridLines: { offsetGridLines: true },
                                xAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    }]
                            }
                        }
                    });
                }, cargaGrafico * 1000, this);
            }
            this.scopeApply();
        });
    }
    dadosRelatorioResgate(listaResgate, listaQuantidade) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.graficoRelatorioResgate !== 'undefined') && this.graficoRelatorioResgate) {
                this.graficoRelatorioResgate.destroy();
                this.graficoRelatorioResgate = null;
            }
            for (let cargaGrafico = 1; cargaGrafico <= 10; cargaGrafico++) {
                setTimeout((ctrl) => {
                    if (ctrl.graficoRelatorioResgate) {
                        return;
                    }
                    const graficoRelatorioResgateEl = document.getElementById('graficoRelatorioResgate');
                    if ((typeof graficoRelatorioResgateEl === 'undefined') || !graficoRelatorioResgateEl) {
                        return;
                    }
                    const graficoRelatorioResgateCtx = graficoRelatorioResgateEl.getContext('2d');
                    const graficoRelatorioResgateDataSets = [{
                            label: '',
                            data: listaQuantidade,
                            backgroundColor: 'rgb(98,163,78)',
                            borderWidth: 1,
                            datalabels: {
                                display: true,
                                font: { size: 11 },
                                align: 'right',
                                padding: { left: 3, right: 3 },
                                backgroundColor: 'rgba(255,255,255,.3)',
                                textShadowBlur: 2,
                                textShadowColor: '#fff'
                            }
                        }];
                    ctrl.graficoRelatorioResgate = new chart_js_1.Chart(graficoRelatorioResgateCtx, {
                        type: 'horizontalBar',
                        data: {
                            labels: listaResgate,
                            datasets: graficoRelatorioResgateDataSets
                        },
                        plugins: [globalThis.ChartDataLabels],
                        options: {
                            title: {
                                display: true,
                                text: 'Nº de clones por resgate',
                                position: 'top'
                            },
                            responsive: true,
                            legend: {
                                display: false
                            },
                            layout: {
                                padding: {
                                    right: 30
                                }
                            },
                            scales: {
                                gridLines: { offsetGridLines: true },
                                xAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    }]
                            }
                        }
                    });
                }, cargaGrafico * 1000, this);
            }
            this.scopeApply();
        });
    }
    configDadosFiltros() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            ctrl.minijardinsFiltrados = [];
            angular.forEach(ctrl.minijardins, (minijardim) => {
                if ((typeof minijardim.producaoClone !== 'undefined') && minijardim.producaoClone) {
                    if (!ctrl.minijardinsFiltrados.find((o) => o.producaoClone.id === minijardim.producaoClone.id)) {
                        ctrl.minijardinsFiltrados.push(minijardim);
                    }
                }
            });
        });
    }
    filtrarDossieClone() {
        return __awaiter(this, void 0, void 0, function* () {
            let listaDossie = [];
            this.totalNumMiniestacasCepa = 0;
            this.totalNumMudas = 0;
            this.medEnraizamento = 0;
            if ((typeof this.filtroDossieClone.clone !== 'undefined') && this.filtroDossieClone.clone) {
                listaDossie = this.minijardins.filter((o) => {
                    return (o.producaoClone.id === this.filtroDossieClone.clone.producaoClone.id);
                });
                angular.forEach(listaDossie, (itemDossie) => {
                    var _a, _b, _c;
                    this.filtroDossieClone.listaMinijardins.push(itemDossie);
                    this.totalNumMiniestacasCepa += (_a = itemDossie.numMiniestacasCepa) !== null && _a !== void 0 ? _a : 0;
                    this.totalNumMudas += (_b = itemDossie.numMudas) !== null && _b !== void 0 ? _b : 0;
                    this.medEnraizamento += (_c = itemDossie.enraizamento) !== null && _c !== void 0 ? _c : 0;
                });
                if (listaDossie.length > 0) {
                    this.medEnraizamento = this.medEnraizamento / listaDossie.length;
                }
                yield this.filtrarRelatorioRecomendacao();
                this.showDossieClone = true;
            }
        });
    }
    filtrarRelatorioClone() {
        return __awaiter(this, void 0, void 0, function* () {
            this.totalClonesMinijardim = 0;
            this.totalClonesMultiplicacao = 0;
            let listaRelatorio = [];
            let listaClonesEspecie = [];
            let listaClonesFinalidade = [];
            let listaClonesAno = [];
            let listaEspecies = [], listaFinalidades = [], listaResgates = [], listaQntEspecies = [], listaQntFinalidades = [], listaQntResgates = [];
            if ((typeof this.filtroRelatorioClone.acao !== 'undefined') && this.filtroRelatorioClone.acao) {
                listaRelatorio = this.minijardins.filter((o) => {
                    return (o.acao.id === this.filtroRelatorioClone.acao.id);
                });
            }
            else {
                listaRelatorio = this.minijardins;
            }
            angular.forEach(listaRelatorio, (itemRelatorio) => {
                let itemEspecie = listaClonesEspecie.find(o => (o.especie.id === itemRelatorio.especie.id));
                if ((typeof itemEspecie === 'undefined') || !itemEspecie) {
                    listaClonesEspecie.push({
                        especie: itemRelatorio.especie,
                        acao: itemRelatorio.acao,
                        nome: itemRelatorio.especie.descricao,
                        quantidade: 1
                    });
                }
                else {
                    itemEspecie.quantidade += 1;
                }
                let itemFinalidade = listaClonesFinalidade.find(o => (o.finalidade.id === itemRelatorio.finalidade.id));
                if ((typeof itemFinalidade === 'undefined') || !itemFinalidade) {
                    listaClonesFinalidade.push({
                        finalidade: itemRelatorio.finalidade,
                        acao: itemRelatorio.acao,
                        nome: itemRelatorio.finalidade.descricao,
                        quantidade: 1
                    });
                }
                else {
                    itemFinalidade.quantidade += 1;
                }
                let itemAno = listaClonesAno.find(o => (o.ano === itemRelatorio.anoResgate));
                if ((typeof itemAno === 'undefined') || !itemAno) {
                    listaClonesAno.push({
                        ano: itemRelatorio.anoResgate,
                        acao: itemRelatorio.acao,
                        nome: String(itemRelatorio.anoResgate),
                        quantidade: 1
                    });
                }
                else {
                    itemAno.quantidade += 1;
                }
                if (itemRelatorio.acao.codigo === 1) {
                    this.totalClonesMinijardim++;
                }
                if (itemRelatorio.acao.codigo === 2 || itemRelatorio.acao.codigo === 3) {
                    this.totalClonesMultiplicacao++;
                }
            });
            angular.forEach(listaClonesEspecie, (itemEspecie) => {
                listaEspecies.push(itemEspecie.nome);
                listaQntEspecies.push(itemEspecie.quantidade);
            });
            angular.forEach(listaClonesFinalidade, (itemFinalidade) => {
                listaFinalidades.push(itemFinalidade.nome);
                listaQntFinalidades.push(itemFinalidade.quantidade);
            });
            angular.forEach(listaClonesAno, (itemAno) => {
                listaResgates.push(itemAno.nome);
                listaQntResgates.push(itemAno.quantidade);
            });
            yield this.dadosRelatorioFinalidade(listaFinalidades, listaQntFinalidades);
            yield this.dadosRelatorioEspecie(listaEspecies, listaQntEspecies);
            yield this.dadosRelatorioResgate(listaResgates, listaQntResgates);
        });
    }
    updateFormularioClone() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.modelo.producaoClone !== 'undefined') && this.modelo.producaoClone) {
                this.modelo.anoResgate = (_a = this.modelo.producaoClone.anoResgate) !== null && _a !== void 0 ? _a : 0;
                this.modelo.enraizamento = (_b = this.modelo.producaoClone.perEnraizamento) !== null && _b !== void 0 ? _b : 0;
            }
        });
    }
    filtrarAnalises(alterouDados = false) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.analisesFiltradas === 'undefined') || !this.analisesFiltradas || ((typeof alterouDados !== 'undefined') && alterouDados)) {
                this.listaOpcoes = yield this.dadosLista();
                this.listaOpcoesCroqui = yield this.dadosListaCroqui();
                this.listaOpcoesPlantioMacroestacas = yield this.dadosListaPlantioMacroestacas();
                this.listaOpcoesPlantioMiniestacas = yield this.dadosListaPlantioMiniestacas();
                this.listaOpcoesAvaliacaoSobrevivencia = yield this.dadosListaAvaliacaoSobrevivencia();
                this.listaOpcoesEliminarMiniMacroEstacas = yield this.dadosListaEliminarMiniMacroEstacas();
                this.listaOpcoesManejo = yield this.dadosListaManejo();
                yield this.configDadosFiltros();
                yield this.filtrarRelatorioClone();
                if (angular.element('#planilha_croqui_producao_muda_eucalyptus').length > 0) {
                    if ((typeof globalThis.luckysheet !== 'undefined') && globalThis.luckysheet) {
                        globalThis.luckysheet.destroy();
                    }
                    angular.element('#planilha_croqui_producao_muda_eucalyptus').empty();
                }
                yield this.carregarPlanilhaCroqui();
                this.analisesFiltradas = true;
                this.scopeApply();
            }
        });
    }
}
exports.ProducaoMudaClonalEucalyptusMinijardimController = ProducaoMudaClonalEucalyptusMinijardimController;
ProducaoMudaClonalEucalyptusMinijardimController.$inject = ['$scope', '$injector'];
