"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProdutoReceitaSolucao = void 0;
const Base_1 = require("./Base");
class ProdutoReceitaSolucao extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.produto = null;
        this.marca = null;
        this.dosagem = null;
        this.contaDepositoSap = null;
        this.centroCustoSap = null;
    }
}
exports.ProdutoReceitaSolucao = ProdutoReceitaSolucao;
