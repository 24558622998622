"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PomarBancoConservacaoDadosListaUtil = void 0;
const angular = require("angular");
const moment = require("moment");
const UiGridConstrutor_1 = require("../../../app/construtores/UiGridConstrutor");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const Pomar_1 = require("../../../../../modelos/Pomar");
const Pais_1 = require("../../../../../modelos/Pais");
const PerfilClimaticoPomar_1 = require("../../../../../modelos/PerfilClimaticoPomar");
const FiltroPlanejamentoPomar_1 = require("../../../../../io/FiltroPlanejamentoPomar");
const FiltroControleManejoPomar_1 = require("../../../../../io/FiltroControleManejoPomar");
const OperacaoManejoPomar_1 = require("../../../../../modelos/OperacaoManejoPomar");
const FiltroControleEnxertiaPomar_1 = require("../../../../../io/FiltroControleEnxertiaPomar");
const TipoEnxertoPomar_1 = require("../../../../../modelos/TipoEnxertoPomar");
const FiltroProdutividadePomar_1 = require("../../../../../io/FiltroProdutividadePomar");
const FiltroFenologiaPomar_1 = require("../../../../../io/FiltroFenologiaPomar");
const FiltroPolinizacaoPomar_1 = require("../../../../../io/FiltroPolinizacaoPomar");
const ColetaConesSementesPolinizacaoPomar_1 = require("../../../../../modelos/ColetaConesSementesPolinizacaoPomar");
const PolinizacaoPomarSimplificado_1 = require("../../../../../modelos/PolinizacaoPomarSimplificado");
const Estrobilo_1 = require("../../../../../modelos/Estrobilo");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const DataUtil_1 = require("../../../../../util/DataUtil");
const DispositivoUtil_1 = require("../../../../../util/DispositivoUtil");
const NumeroUtil_1 = require("../../../../../util/NumeroUtil");
const TextoUtil_1 = require("../../../../../util/TextoUtil");
class PomarBancoConservacaoDadosListaUtil {
    static dadosListaPomares(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                var auxColunas = Array();
                auxColunas.push({
                    name: 'generoDescricao',
                    displayName: 'Gênero',
                    width: 100
                });
                auxColunas.push({
                    name: 'especieDescricao',
                    displayName: 'Espécie',
                    width: 140
                });
                auxColunas.push({
                    name: 'tipoDescricao',
                    displayName: 'População (Tipo Pomar)',
                    width: 220
                });
                auxColunas.push({
                    name: 'geracaoDescricao',
                    displayName: 'Geração',
                    width: 80,
                    cellTemplate: `
                    <div class="ui-grid-cell-contents">
                        {{row.entity.geracaoCodigo ? row.entity.geracaoCodigo : row.entity.geracaoDescricao}}
                    </div>
                `
                });
                auxColunas.push({
                    name: 'descricao',
                    displayName: 'Pomar - Descrição',
                    width: 180
                });
                auxColunas.push({
                    name: 'data',
                    displayName: 'Data',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 100
                });
                auxColunas.push({
                    name: 'regiaoNome',
                    displayName: 'Fazenda',
                    width: 130
                });
                auxColunas.push({
                    name: 'regiaoNum',
                    displayName: 'Região',
                    width: 80
                });
                auxColunas.push({
                    name: 'talhaoNumero',
                    displayName: 'Talhão',
                    width: 90
                });
                auxColunas.push({
                    name: 'area',
                    displayName: 'Área (Ha)',
                    width: 90
                });
                auxColunas.push({
                    name: 'idade',
                    displayName: 'Idade',
                    width: 90
                });
                auxColunas.push({
                    name: 'situacaoDescricao',
                    displayName: 'Situação',
                    width: 96
                });
                auxColunas.push({
                    name: 'tratamentos',
                    displayName: 'Tratamentos',
                    width: 106
                });
                auxColunas.push({
                    name: 'individuos',
                    displayName: 'Indivíduos',
                    width: 100
                });
                auxColunas.push({
                    name: 'municipio',
                    displayName: 'municipio',
                    width: 160
                });
                auxColunas.push({
                    name: 'provinciaUfDescricao',
                    displayName: 'ProvinciaUf (UF)',
                    width: 130
                });
                auxColunas.push({
                    name: 'criacao',
                    displayName: 'Data/Hora Cadastro',
                    cellFilter: 'date:"dd/MM/yyyy HH:mm:ss"',
                    width: 190
                });
                auxColunas.push({
                    name: 'codigo',
                    displayName: 'Código',
                    width: 95
                });
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
                ctrl.listaPomares = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POMAR)
                    .filtro({})
                    .listar();
                if ((typeof ctrl.listaPomares !== 'undefined') && ctrl.listaPomares) {
                    angular.forEach(ctrl.listaPomares, (pomar) => {
                        pomar.descricaoDetalhada = ((typeof pomar.descricao !== 'undefined') && pomar.descricao ? pomar.descricao : '');
                        if ((typeof pomar.data !== 'undefined') && pomar.data) {
                            pomar.data = (typeof pomar.data === 'string') ? new Date(pomar.data) : pomar.data;
                            if (pomar.data) {
                                let dataHoraAtual = new Date();
                                let idadeMs = dataHoraAtual.getTime() - pomar.data.getTime();
                                let idadeData = new Date(idadeMs);
                                pomar.idade = Math.abs(idadeData.getUTCFullYear() - 1970);
                                pomar.ano = pomar.data.getFullYear();
                                pomar.descricaoDetalhada += ' - ' + pomar.data.getFullYear();
                            }
                        }
                        if ((typeof pomar.paisDescricao === 'undefined') && !pomar.paisDescricao) {
                            if ((typeof ctrl.paises !== 'undefined') && ctrl.paises && (ctrl.paises.length > 0)) {
                                let paisPadrao = null;
                                const paisPadraoReferencia = TextoUtil_1.TextoUtil.converteTextoParaReferencia('Brasil');
                                angular.forEach(ctrl.paises, (pais) => {
                                    if (!paisPadrao && (typeof pais.descricao !== 'undefined') && pais.descricao && (pais.descricao.length > 0)) {
                                        if (TextoUtil_1.TextoUtil.converteTextoParaReferencia(pais.descricao) === paisPadraoReferencia) {
                                            paisPadrao = pais;
                                        }
                                    }
                                });
                                if (paisPadrao) {
                                    pomar.pais = paisPadrao;
                                    pomar.paisId = paisPadrao.id;
                                    pomar.paisCodigo = paisPadrao.codigo;
                                    pomar.paisDescricao = paisPadrao.descricao;
                                }
                            }
                            else {
                                let paisPadrao = {
                                    id: Pais_1.Pais.BRASIL,
                                    uuid: Pais_1.Pais.BRASIL,
                                    status: true,
                                    criacao: new Date(),
                                    codigo: 1,
                                    descricao: 'Brasil'
                                };
                                pomar.pais = paisPadrao;
                                pomar.paisId = paisPadrao.id;
                                pomar.paisCodigo = paisPadrao.codigo;
                                pomar.paisDescricao = paisPadrao.descricao;
                            }
                        }
                        if ((typeof pomar.instituicao === 'undefined') || !pomar.instituicao || (pomar.instituicao.length === 0)) {
                            pomar.instituicao = 'WestRock';
                        }
                        if ((typeof pomar.nomeCientifico === 'undefined') || !pomar.nomeCientifico || (pomar.nomeCientifico.length === 0)) {
                            pomar.nomeCientifico = pomar.descricao;
                        }
                        if ((typeof pomar.distanciaSede === 'undefined') || !pomar.distanciaSede) {
                            pomar.distanciaSede = (pomar.descricaoDetalhada.toLowerCase().indexOf('sede') !== -1) ? '1 km' : '35 km';
                        }
                        pomar.local = ((typeof pomar.municipio !== 'undefined') && pomar.municipio ? pomar.municipio : '') + ' - ' +
                            ((typeof pomar.provinciaUfSigla !== 'undefined') && pomar.provinciaUfSigla ? pomar.provinciaUfSigla : '');
                        if ((typeof pomar.perfilClimaticoAnual !== 'undefined') && pomar.perfilClimaticoAnual) {
                            if ((typeof pomar.perfilClimaticoAnual.atualizacao === 'undefined') || !pomar.perfilClimaticoAnual.atualizacao) {
                                pomar.perfilClimaticoAnual.atualizacao = new Date();
                            }
                            else if (typeof pomar.perfilClimaticoAnual.atualizacao === 'string') {
                                pomar.perfilClimaticoAnual.atualizacao = new Date(pomar.perfilClimaticoAnual.atualizacao);
                            }
                        }
                        else {
                            pomar.perfilClimaticoAnual = new PerfilClimaticoPomar_1.PerfilClimaticoPomar();
                            pomar.perfilClimaticoAnual.atualizacao = new Date();
                        }
                        if ((typeof pomar.producaoPortaEnxertosPlantio !== 'undefined') && pomar.producaoPortaEnxertosPlantio) {
                            pomar.producaoPortaEnxertosPlantio = (typeof pomar.producaoPortaEnxertosPlantio === 'string') ? new Date(pomar.producaoPortaEnxertosPlantio) : pomar.producaoPortaEnxertosPlantio;
                        }
                        if ((typeof pomar.implantacaoPomarEnxertia !== 'undefined') && pomar.implantacaoPomarEnxertia) {
                            pomar.implantacaoPomarEnxertia = (typeof pomar.implantacaoPomarEnxertia === 'string') ? new Date(pomar.implantacaoPomarEnxertia) : pomar.implantacaoPomarEnxertia;
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                let posicaoColuna = 1;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                const gridOption = {
                    data: ctrl.listaPomares,
                    columnDefs: gridConstrutor.$colunas,
                    controller: ctrl
                };
                return gridOption;
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            return null;
        });
    }
    static dadosListaPlanejamentoPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.pomarSelecionado || !ctrl.pomarSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if ((typeof ctrl.filtroPlanejamentoPomar === 'undefined') || !ctrl.filtroPlanejamentoPomar) {
                    ctrl.filtroPlanejamentoPomar = new FiltroPlanejamentoPomar_1.FiltroPlanejamentoPomar();
                }
                let filtro = new FiltroPlanejamentoPomar_1.FiltroPlanejamentoPomarTratado();
                filtro.listaSimplificadaOnline = !DispositivoUtil_1.DispositivoUtil.ehCelular();
                filtro.pomar = ctrl.pomarSelecionado.id;
                if ((typeof ctrl.filtroPlanejamentoPomar.dataIni !== 'undefined') && (ctrl.filtroPlanejamentoPomar.dataIni !== null)) {
                    filtro.dataIni = DataUtil_1.DataUtil.converterDataParaFormatoPHP(ctrl.filtroPlanejamentoPomar.dataIni);
                }
                if ((typeof ctrl.filtroPlanejamentoPomar.dataFim !== 'undefined') && (ctrl.filtroPlanejamentoPomar.dataFim !== null)) {
                    filtro.dataFim = DataUtil_1.DataUtil.converterDataParaFormatoPHP(ctrl.filtroPlanejamentoPomar.dataFim);
                }
                if (typeof ctrl.filtroPlanejamentoPomar.operacaoLimpeza !== 'undefined') {
                    filtro.operacaoLimpeza = ctrl.filtroPlanejamentoPomar.operacaoLimpeza ? true : false;
                }
                if (typeof ctrl.filtroPlanejamentoPomar.operacaoDesbaste !== 'undefined') {
                    filtro.operacaoDesbaste = ctrl.filtroPlanejamentoPomar.operacaoDesbaste ? true : false;
                }
                if (typeof ctrl.filtroPlanejamentoPomar.operacaoPolinizacao !== 'undefined') {
                    filtro.operacaoPolinizacao = ctrl.filtroPlanejamentoPomar.operacaoPolinizacao ? true : false;
                }
                if (typeof ctrl.filtroPlanejamentoPomar.operacaoColetaAmostras !== 'undefined') {
                    filtro.operacaoColetaAmostras = ctrl.filtroPlanejamentoPomar.operacaoColetaAmostras ? true : false;
                }
                if (typeof ctrl.filtroPlanejamentoPomar.operacaoAdubacaoFertilizacao !== 'undefined') {
                    filtro.operacaoAdubacaoFertilizacao = ctrl.filtroPlanejamentoPomar.operacaoAdubacaoFertilizacao ? true : false;
                }
                if (typeof ctrl.filtroPlanejamentoPomar.operacaoColetaSementes !== 'undefined') {
                    filtro.operacaoColetaSementes = ctrl.filtroPlanejamentoPomar.operacaoColetaSementes ? true : false;
                }
                if (typeof ctrl.filtroPlanejamentoPomar.operacaoControleDoencas !== 'undefined') {
                    filtro.operacaoControleDoencas = ctrl.filtroPlanejamentoPomar.operacaoControleDoencas ? true : false;
                }
                if (typeof ctrl.filtroPlanejamentoPomar.outrasOperacoes !== 'undefined') {
                    filtro.outrasOperacoes = ctrl.filtroPlanejamentoPomar.outrasOperacoes ? true : false;
                }
                let lista = [];
                if (lista && (lista.length > 0)) {
                    angular.forEach(lista, (planejamentoPomar) => {
                        if ((typeof planejamentoPomar.criacao !== 'undefined') && planejamentoPomar.criacao) {
                            planejamentoPomar.criacao = new Date(planejamentoPomar.criacao);
                        }
                        if ((typeof planejamentoPomar.dataHoraInclusao !== 'undefined') && planejamentoPomar.dataHoraInclusao) {
                            planejamentoPomar.dataHoraInclusao = new Date(planejamentoPomar.dataHoraInclusao);
                        }
                        if ((typeof planejamentoPomar.dataHoraAlteracao !== 'undefined') && planejamentoPomar.dataHoraAlteracao) {
                            planejamentoPomar.dataHoraAlteracao = new Date(planejamentoPomar.dataHoraAlteracao);
                        }
                        if ((typeof planejamentoPomar.mes !== 'undefined') && planejamentoPomar.mes) {
                            const objetoMes = ctrl.listaMeses.find(m => m.mes === planejamentoPomar.mes);
                            planejamentoPomar.mesDescricao = ((typeof objetoMes !== 'undefined') && objetoMes) ? objetoMes.descricao : null;
                            planejamentoPomar.mesSigla = ((typeof objetoMes !== 'undefined') && objetoMes) ? objetoMes.sigla : null;
                        }
                    });
                }
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    cellClass: 'text-center',
                    cellTemplate: `
                        <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                            <button type="button" class="btn btn-icon bx bx-dots-vertical-rounded" uib-dropdown-toggle></button>
                            <ul class="dropdown-menu dropdown-menu-left" uib-dropdown-menu role="menu">
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalPlanejamentoPomar({ id: row.entity.id || row.entity.uuid, visualizar: true })' class="text-secondary w-100"><i class="bx bx-show mr-1 ml-1"></i>Visualizar</a></li>
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalPlanejamentoPomar({ id: row.entity.id || row.entity.uuid, editar: true })' class="text-secondary w-100"><i class="bx bxs-edit-alt mr-1 ml-1"></i>Editar</a></li>
                                <li><hr></li>
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalPlanejamentoPomar({ id: row.entity.id || row.entity.uuid, excluir: true })' class="text-secondary w-100"><i class="bx bxs-trash-alt mr-1 ml-1"></i>Excluir</a></li>
                            </ul>
                        </div> `
                });
                auxColunas.push({
                    name: 'mesDescricao',
                    displayName: 'Mês',
                    width: 100
                });
                auxColunas.push({
                    name: 'ano',
                    displayName: 'Ano',
                    width: 70
                });
                auxColunas.push({
                    name: 'operacao.descricao',
                    displayName: 'Operação',
                    width: 200
                });
                auxColunas.push({
                    name: 'observacoes',
                    displayName: 'Observações',
                    width: 380
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            return null;
        });
    }
    static dadosListaManejoPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.pomarSelecionado || !ctrl.pomarSelecionado.id) {
                    return { resumido: null, detalhado: null };
                }
                const gridConstrutorResumido = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                const gridConstrutorDetalhado = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutorResumido.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutorResumido.removerAcao();
                gridConstrutorDetalhado.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutorDetalhado.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if ((typeof ctrl.filtroControleManejoPomar === 'undefined') || !ctrl.filtroControleManejoPomar) {
                    ctrl.filtroControleManejoPomar = new FiltroControleManejoPomar_1.FiltroControleManejoPomar();
                }
                let filtro = new FiltroControleManejoPomar_1.FiltroControleManejoPomarTratado();
                filtro.listaSimplificadaOnline = !DispositivoUtil_1.DispositivoUtil.ehCelular();
                filtro.pomar = ctrl.pomarSelecionado.id;
                if ((typeof ctrl.filtroControleManejoPomar.dataIni !== 'undefined') && (ctrl.filtroControleManejoPomar.dataIni !== null)) {
                    filtro.dataIni = DataUtil_1.DataUtil.converterDataParaFormatoPHP(ctrl.filtroControleManejoPomar.dataIni);
                }
                if ((typeof ctrl.filtroControleManejoPomar.dataFim !== 'undefined') && (ctrl.filtroControleManejoPomar.dataFim !== null)) {
                    filtro.dataFim = DataUtil_1.DataUtil.converterDataParaFormatoPHP(ctrl.filtroControleManejoPomar.dataFim);
                }
                if (typeof ctrl.filtroControleManejoPomar.operacaoLimpeza !== 'undefined') {
                    filtro.operacaoLimpeza = ctrl.filtroControleManejoPomar.operacaoLimpeza ? true : false;
                }
                if (typeof ctrl.filtroControleManejoPomar.operacaoDesbaste !== 'undefined') {
                    filtro.operacaoDesbaste = ctrl.filtroControleManejoPomar.operacaoDesbaste ? true : false;
                }
                if (typeof ctrl.filtroControleManejoPomar.operacaoPolinizacao !== 'undefined') {
                    filtro.operacaoPolinizacao = ctrl.filtroControleManejoPomar.operacaoPolinizacao ? true : false;
                }
                if (typeof ctrl.filtroControleManejoPomar.operacaoColetaAmostras !== 'undefined') {
                    filtro.operacaoColetaAmostras = ctrl.filtroControleManejoPomar.operacaoColetaAmostras ? true : false;
                }
                if (typeof ctrl.filtroControleManejoPomar.operacaoAdubacaoFertilizacao !== 'undefined') {
                    filtro.operacaoAdubacaoFertilizacao = ctrl.filtroControleManejoPomar.operacaoAdubacaoFertilizacao ? true : false;
                }
                if (typeof ctrl.filtroControleManejoPomar.operacaoColetaSementes !== 'undefined') {
                    filtro.operacaoColetaSementes = ctrl.filtroControleManejoPomar.operacaoColetaSementes ? true : false;
                }
                if (typeof ctrl.filtroControleManejoPomar.operacaoControleDoencas !== 'undefined') {
                    filtro.operacaoControleDoencas = ctrl.filtroControleManejoPomar.operacaoControleDoencas ? true : false;
                }
                if (typeof ctrl.filtroControleManejoPomar.operacaoPodaRamosPortaEnxerto !== 'undefined') {
                    filtro.operacaoPodaRamosPortaEnxerto = ctrl.filtroControleManejoPomar.operacaoPodaRamosPortaEnxerto ? true : false;
                }
                if (typeof ctrl.filtroControleManejoPomar.outrasOperacoes !== 'undefined') {
                    filtro.outrasOperacoes = ctrl.filtroControleManejoPomar.outrasOperacoes ? true : false;
                }
                let listaDetalhada = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.CONTROLE_MANEJO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CONTROLE_MANEJO_POMAR)
                    .filtro(filtro)
                    .listar();
                let listaResumida = [];
                if (listaDetalhada && (listaDetalhada.length > 0)) {
                    angular.forEach(listaDetalhada, (controleManejoPomar) => {
                        let mesAno = null;
                        if ((typeof controleManejoPomar.criacao !== 'undefined') && controleManejoPomar.criacao) {
                            controleManejoPomar.criacao = new Date(controleManejoPomar.criacao);
                            mesAno = DataUtil_1.DataUtil.converterDataParaFormatoMMYYYY(controleManejoPomar.criacao);
                        }
                        if ((typeof controleManejoPomar.dataHoraInclusao !== 'undefined') && controleManejoPomar.dataHoraInclusao) {
                            controleManejoPomar.dataHoraInclusao = new Date(controleManejoPomar.dataHoraInclusao);
                        }
                        if ((typeof controleManejoPomar.dataHoraAlteracao !== 'undefined') && controleManejoPomar.dataHoraAlteracao) {
                            controleManejoPomar.dataHoraAlteracao = new Date(controleManejoPomar.dataHoraAlteracao);
                        }
                        if ((typeof controleManejoPomar.operacao !== 'undefined') && controleManejoPomar.operacao &&
                            (typeof controleManejoPomar.operacao.tipoOperacao !== 'undefined') && controleManejoPomar.operacao.tipoOperacao) {
                            controleManejoPomar.operacao.ehOperacaoLimpeza = (controleManejoPomar.operacao.tipoOperacao === OperacaoManejoPomar_1.OperacaoManejoPomar.OPERACAO_LIMPEZA);
                            controleManejoPomar.operacao.ehOperacaoDesbaste = (controleManejoPomar.operacao.tipoOperacao === OperacaoManejoPomar_1.OperacaoManejoPomar.OPERACAO_DESBASTE);
                            controleManejoPomar.operacao.ehOperacaoPolinizacao = (controleManejoPomar.operacao.tipoOperacao === OperacaoManejoPomar_1.OperacaoManejoPomar.OPERACAO_POLINIZACAO);
                            controleManejoPomar.operacao.ehOperacaoColetaAmostras = (controleManejoPomar.operacao.tipoOperacao === OperacaoManejoPomar_1.OperacaoManejoPomar.OPERACAO_COLETA_AMOSTRAS);
                            controleManejoPomar.operacao.ehOperacaoColetaSementes = (controleManejoPomar.operacao.tipoOperacao === OperacaoManejoPomar_1.OperacaoManejoPomar.OPERACAO_COLETA_SEMENTES);
                            controleManejoPomar.operacao.ehOperacaoControleDoencas = (controleManejoPomar.operacao.tipoOperacao === OperacaoManejoPomar_1.OperacaoManejoPomar.OPERACAO_CONTROLE_DOENCAS);
                            controleManejoPomar.operacao.ehOperacaoAdubacaoFertilizacao = (controleManejoPomar.operacao.tipoOperacao === OperacaoManejoPomar_1.OperacaoManejoPomar.OPERACAO_ADUBACAO_FERTILIZACAO);
                            controleManejoPomar.operacao.ehOperacaoPodaRamosPortaEnxerto = (controleManejoPomar.operacao.tipoOperacao === OperacaoManejoPomar_1.OperacaoManejoPomar.OPERACAO_PODA_RAMOS_PORTA_ENXERTO);
                            controleManejoPomar.operacao.ehOutrasOperacoes = (controleManejoPomar.operacao.tipoOperacao === OperacaoManejoPomar_1.OperacaoManejoPomar.OUTRAS_OPERACOES);
                        }
                        if (mesAno) {
                            let achouRegistroResumido = false;
                            angular.forEach(listaResumida, (registroResumido) => {
                                if (!achouRegistroResumido && (mesAno === registroResumido.mesAno)) {
                                    achouRegistroResumido = true;
                                    registroResumido.operacaoLimpeza += (controleManejoPomar.operacao && controleManejoPomar.operacao.ehOperacaoLimpeza ? 1 : 0);
                                    registroResumido.operacaoDesbaste += (controleManejoPomar.operacao && controleManejoPomar.operacao.ehOperacaoDesbaste ? 1 : 0);
                                    registroResumido.operacaoPolinizacao += (controleManejoPomar.operacao && controleManejoPomar.operacao.ehOperacaoPolinizacao ? 1 : 0);
                                    registroResumido.operacaoColetaAmostras += (controleManejoPomar.operacao && controleManejoPomar.operacao.ehOperacaoColetaAmostras ? 1 : 0);
                                    registroResumido.operacaoColetaSementes += (controleManejoPomar.operacao && controleManejoPomar.operacao.ehOperacaoColetaSementes ? 1 : 0);
                                    registroResumido.operacaoControleDoencas += (controleManejoPomar.operacao && controleManejoPomar.operacao.ehOperacaoControleDoencas ? 1 : 0);
                                    registroResumido.operacaoAdubacaoFertilizacao += (controleManejoPomar.operacao && controleManejoPomar.operacao.ehOperacaoAdubacaoFertilizacao ? 1 : 0);
                                    registroResumido.operacaoPodaRamosPortaEnxerto += (controleManejoPomar.operacao && controleManejoPomar.operacao.ehOperacaoPodaRamosPortaEnxerto ? 1 : 0);
                                    registroResumido.outrasOperacoes += (controleManejoPomar.operacao && controleManejoPomar.operacao.ehOutrasOperacoes ? 1 : 0);
                                }
                            });
                            if (!achouRegistroResumido) {
                                listaResumida.push({
                                    mesAno: mesAno,
                                    operacaoLimpeza: (controleManejoPomar.operacao && controleManejoPomar.operacao.ehOperacaoLimpeza ? 1 : 0),
                                    operacaoDesbaste: (controleManejoPomar.operacao && controleManejoPomar.operacao.ehOperacaoDesbaste ? 1 : 0),
                                    operacaoPolinizacao: (controleManejoPomar.operacao && controleManejoPomar.operacao.ehOperacaoPolinizacao ? 1 : 0),
                                    operacaoColetaAmostras: (controleManejoPomar.operacao && controleManejoPomar.operacao.ehOperacaoColetaAmostras ? 1 : 0),
                                    operacaoColetaSementes: (controleManejoPomar.operacao && controleManejoPomar.operacao.ehOperacaoColetaSementes ? 1 : 0),
                                    operacaoControleDoencas: (controleManejoPomar.operacao && controleManejoPomar.operacao.ehOperacaoControleDoencas ? 1 : 0),
                                    operacaoAdubacaoFertilizacao: (controleManejoPomar.operacao && controleManejoPomar.operacao.ehOperacaoAdubacaoFertilizacao ? 1 : 0),
                                    operacaoPodaRamosPortaEnxerto: (controleManejoPomar.operacao && controleManejoPomar.operacao.ehOperacaoPodaRamosPortaEnxerto ? 1 : 0),
                                    outrasOperacoes: (controleManejoPomar.operacao && controleManejoPomar.operacao.ehOutrasOperacoes ? 1 : 0),
                                });
                            }
                        }
                        let detalhamento = '';
                        if (controleManejoPomar.operacao && controleManejoPomar.operacao.ehOperacaoLimpeza) {
                            if ((typeof controleManejoPomar.limpezas !== 'undefined') && controleManejoPomar.limpezas) {
                                let numLimpeza = 0;
                                angular.forEach(controleManejoPomar.limpezas, (limpeza) => {
                                    numLimpeza++;
                                    const tipoLimpeza = ((typeof limpeza.tipoLimpeza !== 'undefined') && limpeza.tipoLimpeza && (typeof limpeza.tipoLimpeza.descricao !== 'undefined') && limpeza.tipoLimpeza.descricao) ? limpeza.tipoLimpeza.descricao : '(Tipo não informado)';
                                    const produto = ((typeof limpeza.produto !== 'undefined') && limpeza.produto && (typeof limpeza.produto.descricao !== 'undefined') && limpeza.produto.descricao) ? limpeza.produto.descricao : null;
                                    const dosagemProduto = ((typeof limpeza.dosagemProduto !== 'undefined') && limpeza.dosagemProduto) ? '' + NumeroUtil_1.NumeroUtil.arredondarNumero(limpeza.dosagemProduto, 2) : null;
                                    detalhamento += ((detalhamento.length > 0) ? '<br>' : '') + '#' + numLimpeza + '. Limpeza: ' + tipoLimpeza +
                                        (produto ? ', Produto: ' + produto + (dosagemProduto ? ', Dosagem: ' + dosagemProduto : '') : '');
                                });
                                if (numLimpeza === 1) {
                                    detalhamento = detalhamento.replace('#1. ', '');
                                }
                            }
                        }
                        else if (controleManejoPomar.operacao && controleManejoPomar.operacao.ehOperacaoDesbaste) {
                            if ((typeof controleManejoPomar.desbastes !== 'undefined') && controleManejoPomar.desbastes) {
                                let numDesbaste = 0;
                                angular.forEach(controleManejoPomar.desbastes, (desbaste) => {
                                    numDesbaste++;
                                    const materialGenetico = ((typeof desbaste.materialGenetico !== 'undefined') && desbaste.materialGenetico && (typeof desbaste.materialGenetico.descricao !== 'undefined') && desbaste.materialGenetico.descricao) ? desbaste.materialGenetico.descricao : '(Nenhum)';
                                    const tipoDesbaste = ((typeof desbaste.tipoDesbaste !== 'undefined') && desbaste.tipoDesbaste && (typeof desbaste.tipoDesbaste.descricao !== 'undefined') && desbaste.tipoDesbaste.descricao) ? desbaste.tipoDesbaste.descricao : '(Não informado)';
                                    const numRametes = ((typeof desbaste.numRametes !== 'undefined') && desbaste.numRametes) ? '' + NumeroUtil_1.NumeroUtil.arredondarNumero(desbaste.numRametes, 2) : null;
                                    detalhamento += ((detalhamento.length > 0) ? '<br>' : '') + '#' + numDesbaste + '. Material Genético: ' + materialGenetico +
                                        ', Desbaste: ' + tipoDesbaste + (numRametes ? ', Núm. Rametes: ' + numRametes : '');
                                });
                                if (numDesbaste === 1) {
                                    detalhamento = detalhamento.replace('#1. ', '');
                                }
                            }
                        }
                        else if (controleManejoPomar.operacao && controleManejoPomar.operacao.ehOperacaoPolinizacao) {
                            if ((typeof controleManejoPomar.polinizacoes !== 'undefined') && controleManejoPomar.polinizacoes) {
                                let numPolinizacao = 0;
                                if (numPolinizacao === 1) {
                                    detalhamento = detalhamento.replace('#1. ', '');
                                }
                            }
                        }
                        else if (controleManejoPomar.operacao && controleManejoPomar.operacao.ehOperacaoColetaAmostras) {
                            if ((typeof controleManejoPomar.coletasAmostras !== 'undefined') && controleManejoPomar.coletasAmostras) {
                                let numColetaAmostra = 0;
                                angular.forEach(controleManejoPomar.coletasAmostras, (coletaAmostra) => {
                                    numColetaAmostra++;
                                    const materialGenetico = ((typeof coletaAmostra.materialGenetico !== 'undefined') && coletaAmostra.materialGenetico && (typeof coletaAmostra.materialGenetico.descricao !== 'undefined') && coletaAmostra.materialGenetico.descricao) ? coletaAmostra.materialGenetico.descricao : '(Nenhum)';
                                    const tipoColetaAmostra = ((typeof coletaAmostra.tipoColetaAmostra !== 'undefined') && coletaAmostra.tipoColetaAmostra && (typeof coletaAmostra.tipoColetaAmostra.descricao !== 'undefined') && coletaAmostra.tipoColetaAmostra.descricao) ? coletaAmostra.tipoColetaAmostra.descricao : '(Não informado)';
                                    const quantidadeAmostras = ((typeof coletaAmostra.quantidadeAmostras !== 'undefined') && coletaAmostra.quantidadeAmostras) ? '' + NumeroUtil_1.NumeroUtil.arredondarNumero(coletaAmostra.quantidadeAmostras, 2) : null;
                                    detalhamento += ((detalhamento.length > 0) ? '<br>' : '') + '#' + numColetaAmostra + '. Material Genético: ' + materialGenetico +
                                        ', Tipo Coleta: ' + tipoColetaAmostra + (quantidadeAmostras ? ', Quant. Amostras: ' + quantidadeAmostras : '');
                                });
                                if (numColetaAmostra === 1) {
                                    detalhamento = detalhamento.replace('#1. ', '');
                                }
                            }
                        }
                        else if (controleManejoPomar.operacao && controleManejoPomar.operacao.ehOperacaoAdubacaoFertilizacao) {
                            if ((typeof controleManejoPomar.adubacoesFertilizacoes !== 'undefined') && controleManejoPomar.adubacoesFertilizacoes) {
                                let numAdubacaoFertilizacao = 0;
                                angular.forEach(controleManejoPomar.adubacoesFertilizacoes, (adubacaoFertilizacao) => {
                                    numAdubacaoFertilizacao++;
                                    const tipoAplicacao = ((typeof adubacaoFertilizacao.tipoAplicacao !== 'undefined') && adubacaoFertilizacao.tipoAplicacao && (typeof adubacaoFertilizacao.tipoAplicacao.descricao !== 'undefined') && adubacaoFertilizacao.tipoAplicacao.descricao) ? adubacaoFertilizacao.tipoAplicacao.descricao : '(Tipo não informado)';
                                    const produto = ((typeof adubacaoFertilizacao.produto !== 'undefined') && adubacaoFertilizacao.produto && (typeof adubacaoFertilizacao.produto.descricao !== 'undefined') && adubacaoFertilizacao.produto.descricao) ? adubacaoFertilizacao.produto.descricao : null;
                                    const dosagemProduto = ((typeof adubacaoFertilizacao.dosagemProduto !== 'undefined') && adubacaoFertilizacao.dosagemProduto) ? '' + NumeroUtil_1.NumeroUtil.arredondarNumero(adubacaoFertilizacao.dosagemProduto, 2) : null;
                                    detalhamento += ((detalhamento.length > 0) ? '<br>' : '') + '#' + numAdubacaoFertilizacao + '. Tipo Aplicação: ' + tipoAplicacao +
                                        (produto ? ', Produto: ' + produto + (dosagemProduto ? ', Dosagem: ' + dosagemProduto : '') : '');
                                });
                                if (numAdubacaoFertilizacao === 1) {
                                    detalhamento = detalhamento.replace('#1. ', '');
                                }
                            }
                        }
                        else if (controleManejoPomar.operacao && controleManejoPomar.operacao.ehOperacaoColetaSementes) {
                            if ((typeof controleManejoPomar.coletasSementes !== 'undefined') && controleManejoPomar.coletasSementes) {
                                let numColetaSemente = 0;
                                angular.forEach(controleManejoPomar.coletasSementes, (coletaSemente) => {
                                    numColetaSemente++;
                                    const materialGenetico = ((typeof coletaSemente.materialGenetico !== 'undefined') && coletaSemente.materialGenetico && (typeof coletaSemente.materialGenetico.descricao !== 'undefined') && coletaSemente.materialGenetico.descricao) ? coletaSemente.materialGenetico.descricao : '(Nenhum)';
                                    const anoPolinizacao = ((typeof coletaSemente.anoPolinizacao !== 'undefined') && coletaSemente.anoPolinizacao) ? '' + NumeroUtil_1.NumeroUtil.arredondarNumero(coletaSemente.anoPolinizacao, 2) : null;
                                    const quantidade = ((typeof coletaSemente.quantidade !== 'undefined') && coletaSemente.quantidade) ? '' + NumeroUtil_1.NumeroUtil.arredondarNumero(coletaSemente.quantidade, 2) : null;
                                    const numArvores = ((typeof coletaSemente.numArvores !== 'undefined') && coletaSemente.numArvores) ? '' + NumeroUtil_1.NumeroUtil.arredondarNumero(coletaSemente.numArvores, 2) : null;
                                    const numCones = ((typeof coletaSemente.numCones !== 'undefined') && coletaSemente.numCones) ? '' + NumeroUtil_1.NumeroUtil.arredondarNumero(coletaSemente.numCones, 2) : null;
                                    detalhamento += ((detalhamento.length > 0) ? '<br>' : '') + '#' + numColetaSemente + '. ' +
                                        (controleManejoPomar.ehOperacional ? '[ Operacional ] ' : (controleManejoPomar.ehPesquisa ? '[ Pesquisa ] ' : '')) +
                                        'Material Genético: ' + materialGenetico +
                                        (anoPolinizacao ? ', Ano Polinização: ' + anoPolinizacao : '') +
                                        (quantidade ? ', Quantidade (g): ' + quantidade : '') +
                                        (quantidade ? ', Árvores: ' + numArvores : '') +
                                        (numCones ? ', Nº Cones: ' + numCones : '');
                                });
                                if (numColetaSemente === 1) {
                                    detalhamento = detalhamento.replace('#1. ', '');
                                }
                            }
                        }
                        else if (controleManejoPomar.operacao && controleManejoPomar.operacao.ehOperacaoControleDoencas) {
                            if ((typeof controleManejoPomar.doencas !== 'undefined') && controleManejoPomar.doencas) {
                                let numControleDoenca = 0;
                                angular.forEach(controleManejoPomar.doencas, (controleDoenca) => {
                                    numControleDoenca++;
                                    const materialGenetico = ((typeof controleDoenca.materialGenetico !== 'undefined') && controleDoenca.materialGenetico && (typeof controleDoenca.materialGenetico.descricao !== 'undefined') && controleDoenca.materialGenetico.descricao) ? controleDoenca.materialGenetico.descricao : '(Nenhum)';
                                    const tipoDoenca = ((typeof controleDoenca.tipoDoenca !== 'undefined') && controleDoenca.tipoDoenca && (typeof controleDoenca.tipoDoenca.descricao !== 'undefined') && controleDoenca.tipoDoenca.descricao) ? controleDoenca.tipoDoenca.descricao : '(Tipo não informado)';
                                    const tipoIntervencaoDoenca = ((typeof controleDoenca.tipoIntervencaoDoenca !== 'undefined') && controleDoenca.tipoIntervencaoDoenca && (typeof controleDoenca.tipoIntervencaoDoenca.descricao !== 'undefined') && controleDoenca.tipoIntervencaoDoenca.descricao) ? controleDoenca.tipoIntervencaoDoenca.descricao : null;
                                    const produto = ((typeof controleDoenca.produto !== 'undefined') && controleDoenca.produto && (typeof controleDoenca.produto.descricao !== 'undefined') && controleDoenca.produto.descricao) ? controleDoenca.produto.descricao : null;
                                    const quantidadeArvores = ((typeof controleDoenca.quantidadeArvores !== 'undefined') && controleDoenca.quantidadeArvores) ? '' + NumeroUtil_1.NumeroUtil.arredondarNumero(controleDoenca.quantidadeArvores, 2) : null;
                                    detalhamento += ((detalhamento.length > 0) ? '<br>' : '') + '#' + numControleDoenca + '. Material Genético: ' + materialGenetico +
                                        (tipoDoenca ? ', Doença: ' + tipoDoenca : '') +
                                        (tipoIntervencaoDoenca ? ', Intervenção: ' + tipoIntervencaoDoenca : '') +
                                        (produto ? ', Produto: ' + produto : '') +
                                        (quantidadeArvores ? ', Quant. Árvores: ' + quantidadeArvores : '');
                                });
                                if (numControleDoenca === 1) {
                                    detalhamento = detalhamento.replace('#1. ', '');
                                }
                            }
                        }
                        else if (controleManejoPomar.operacao && controleManejoPomar.operacao.ehOperacaoPodaRamosPortaEnxerto) {
                            detalhamento = '-';
                        }
                        else if (controleManejoPomar.operacao && controleManejoPomar.operacao.ehOutrasOperacoes) {
                            detalhamento = '-';
                        }
                        controleManejoPomar.descricaoDetalhada = detalhamento.substring(0, 1000).replace(/\<br\>/g, '<br>\r\n');
                        controleManejoPomar.descricaoResumida = detalhamento.substring(0, 250).replace('#1. ', '').replace(/\<br\>/g, ' | ');
                    });
                }
                let auxColunasResumido = new Array();
                let auxColunasDetalhado = new Array();
                auxColunasDetalhado.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    cellClass: 'text-center',
                    cellTemplate: `
                        <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                            <button type="button" class="btn btn-icon bx bx-dots-vertical-rounded" uib-dropdown-toggle></button>
                            <ul class="dropdown-menu dropdown-menu-left" uib-dropdown-menu role="menu">
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalControleManejoPomar({ id: row.entity.id || row.entity.uuid, visualizar: true })' class="text-secondary w-100"><i class="bx bx-show mr-1 ml-1"></i>Visualizar</a></li>
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalControleManejoPomar({ id: row.entity.id || row.entity.uuid, editar: true })' class="text-secondary w-100"><i class="bx bxs-edit-alt mr-1 ml-1"></i>Editar</a></li>
                                <li><hr></li>
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalControleManejoPomar({ id: row.entity.id || row.entity.uuid, excluir: true })' class="text-secondary w-100"><i class="bx bxs-trash-alt mr-1 ml-1"></i>Excluir</a></li>
                            </ul>
                        </div> `
                });
                auxColunasDetalhado.push({
                    name: 'data',
                    displayName: 'Data',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 120
                });
                auxColunasDetalhado.push({
                    name: 'operacao.descricao',
                    displayName: 'Operação',
                    width: 180
                });
                auxColunasDetalhado.push({
                    name: 'descricaoResumida',
                    displayName: 'Detalhamento',
                    width: 850,
                    cellTemplate: `<div class="grid-tooltip" data-trigger="hover" data-placement="bottom"
                    data-title="{{row.entity.descricaoDetalhada}}" data-html="true" data-offset="0,4" bs-tooltip>
                    <div class="ui-grid-cell-contents">
                        {{row.entity.descricaoResumida}}
                    </div>
                </div>`
                });
                auxColunasDetalhado.push({
                    name: 'observacoes',
                    displayName: 'Observações',
                    width: 180
                });
                auxColunasResumido.push({
                    name: 'mesAno',
                    displayName: 'Mês/Ano',
                    width: 100
                });
                auxColunasResumido.push({
                    name: 'desbastes',
                    displayName: 'Desbastes',
                    width: 100
                });
                auxColunasResumido.push({
                    name: 'tratamentos',
                    displayName: 'Tratamentos',
                    width: 120
                });
                auxColunasResumido.push({
                    name: 'mediaPorHectare',
                    displayName: 'Média por Ha',
                    width: 120
                });
                auxColunasResumido.push({
                    name: 'numArvores',
                    displayName: 'Nº de Árvores',
                    width: 120
                });
                auxColunasResumido.push({
                    name: 'adubacaoFormulacao',
                    displayName: 'Formulação',
                    width: 100
                });
                auxColunasResumido.push({
                    name: 'adubacaoQuantidade',
                    displayName: 'Quantidade',
                    width: 100
                });
                auxColunasResumido.push({
                    name: 'produtividade',
                    displayName: 'Produtividade',
                    width: 120
                });
                let posicaoColunaResumido = 0;
                angular.forEach(auxColunasResumido, (coluna) => {
                    posicaoColunaResumido++;
                    coluna.posicao = posicaoColunaResumido;
                    gridConstrutorResumido.adicionarColuna(coluna);
                });
                let posicaoColunaDetalhado = 0;
                angular.forEach(auxColunasDetalhado, (coluna) => {
                    posicaoColunaDetalhado++;
                    coluna.posicao = posicaoColunaDetalhado;
                    gridConstrutorDetalhado.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return {
                    resumido: {
                        data: listaResumida,
                        columnDefs: gridConstrutorResumido.$colunas,
                        enableSorting: true,
                        controller: ctrl
                    },
                    detalhado: {
                        data: listaDetalhada,
                        columnDefs: gridConstrutorDetalhado.$colunas,
                        enableSorting: true,
                        controller: ctrl
                    }
                };
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            return null;
        });
    }
    static dadosListaEnxertiaPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.pomarSelecionado || !ctrl.pomarSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if ((typeof ctrl.filtroControleEnxertiaPomar === 'undefined') || !ctrl.filtroControleEnxertiaPomar) {
                    ctrl.filtroControleEnxertiaPomar = new FiltroControleEnxertiaPomar_1.FiltroControleEnxertiaPomar();
                }
                let filtro = new FiltroControleEnxertiaPomar_1.FiltroControleEnxertiaPomarTratado();
                filtro.listaSimplificadaOnline = !DispositivoUtil_1.DispositivoUtil.ehCelular();
                filtro.pomar = ctrl.pomarSelecionado.id;
                if ((typeof ctrl.filtroControleEnxertiaPomar.dataIni !== 'undefined') && (ctrl.filtroControleEnxertiaPomar.dataIni !== null)) {
                    filtro.dataIni = DataUtil_1.DataUtil.converterDataParaFormatoPHP(ctrl.filtroControleEnxertiaPomar.dataIni);
                }
                if ((typeof ctrl.filtroControleEnxertiaPomar.dataFim !== 'undefined') && (ctrl.filtroControleEnxertiaPomar.dataFim !== null)) {
                    filtro.dataFim = DataUtil_1.DataUtil.converterDataParaFormatoPHP(ctrl.filtroControleEnxertiaPomar.dataFim);
                }
                let lista = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.CONTROLE_ENXERTIA_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CONTROLE_ENXERTIA_POMAR)
                    .filtro(filtro)
                    .listar();
                let listaTratada = new Array();
                if (lista && (lista.length > 0)) {
                    angular.forEach(lista, (controleEnxertiaPomar) => {
                        if ((typeof controleEnxertiaPomar.criacao !== 'undefined') && controleEnxertiaPomar.criacao) {
                            controleEnxertiaPomar.criacao = new Date(controleEnxertiaPomar.criacao);
                        }
                        if ((typeof controleEnxertiaPomar.data !== 'undefined') && controleEnxertiaPomar.data) {
                            controleEnxertiaPomar.data = new Date(controleEnxertiaPomar.data);
                        }
                        if ((typeof controleEnxertiaPomar.dataHoraInclusao !== 'undefined') && controleEnxertiaPomar.dataHoraInclusao) {
                            controleEnxertiaPomar.dataHoraInclusao = new Date(controleEnxertiaPomar.dataHoraInclusao);
                        }
                        if ((typeof controleEnxertiaPomar.dataHoraAlteracao !== 'undefined') && controleEnxertiaPomar.dataHoraAlteracao) {
                            controleEnxertiaPomar.dataHoraAlteracao = new Date(controleEnxertiaPomar.dataHoraAlteracao);
                        }
                        if ((typeof controleEnxertiaPomar.tipoEnxerto !== 'undefined') && controleEnxertiaPomar.tipoEnxerto) {
                            let listaEnxertos = [];
                            if (controleEnxertiaPomar.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.PORTA_ENXERTO) {
                                listaEnxertos = (controleEnxertiaPomar.portaEnxertos ? controleEnxertiaPomar.portaEnxertos : []);
                            }
                            else if (controleEnxertiaPomar.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_1) {
                                listaEnxertos = (controleEnxertiaPomar.enxertosAno1 ? controleEnxertiaPomar.enxertosAno1 : []);
                            }
                            else if (controleEnxertiaPomar.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_2) {
                                listaEnxertos = (controleEnxertiaPomar.enxertosAno2 ? controleEnxertiaPomar.enxertosAno2 : []);
                            }
                            else if (controleEnxertiaPomar.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_3) {
                                listaEnxertos = (controleEnxertiaPomar.enxertosAno3 ? controleEnxertiaPomar.enxertosAno3 : []);
                            }
                            angular.forEach(listaEnxertos, (enxerto) => {
                                let registroValido = false;
                                if ((typeof enxerto.materialGenetico !== 'undefined') && enxerto.materialGenetico &&
                                    (typeof enxerto.materialGenetico.id !== 'undefined') && enxerto.materialGenetico.id) {
                                    registroValido = true;
                                }
                                else if ((typeof enxerto.fila !== 'undefined') && (enxerto.fila !== null) &&
                                    (typeof enxerto.coluna !== 'undefined') && (enxerto.coluna !== null)) {
                                    registroValido = true;
                                }
                                if (registroValido) {
                                    let registroControleEnxertiaPomar = angular.copy(controleEnxertiaPomar);
                                    if ((typeof enxerto.materialGenetico !== 'undefined') && enxerto.materialGenetico) {
                                        registroControleEnxertiaPomar.materialGenetico = enxerto.materialGenetico;
                                    }
                                    if ((typeof enxerto.fila !== 'undefined') && (enxerto.fila !== null)) {
                                        registroControleEnxertiaPomar.fila = enxerto.fila;
                                    }
                                    if ((typeof enxerto.coluna !== 'undefined') && (enxerto.coluna !== null)) {
                                        registroControleEnxertiaPomar.coluna = enxerto.coluna;
                                    }
                                    if ((typeof enxerto.posicoesRealizadas !== 'undefined') && (enxerto.posicoesRealizadas !== null)) {
                                        registroControleEnxertiaPomar.posicoesRealizadas = enxerto.posicoesRealizadas;
                                    }
                                    if ((typeof enxerto.sobrevivencia !== 'undefined') && (enxerto.sobrevivencia !== null)) {
                                        registroControleEnxertiaPomar.sobrevivencia = enxerto.sobrevivencia;
                                    }
                                    if ((typeof enxerto.sobrevivencia60dias !== 'undefined') && (enxerto.sobrevivencia60dias !== null)) {
                                        registroControleEnxertiaPomar.sobrevivencia60dias = enxerto.sobrevivencia60dias;
                                    }
                                    if ((typeof enxerto.sobrevivencia90dias !== 'undefined') && (enxerto.sobrevivencia90dias !== null)) {
                                        registroControleEnxertiaPomar.sobrevivencia90dias = enxerto.sobrevivencia90dias;
                                    }
                                    if ((typeof enxerto.plantio !== 'undefined') && (enxerto.plantio !== null)) {
                                        registroControleEnxertiaPomar.plantio = enxerto.plantio;
                                    }
                                    if ((typeof enxerto.replantio !== 'undefined') && (enxerto.replantio !== null)) {
                                        registroControleEnxertiaPomar.replantio = enxerto.replantio;
                                    }
                                    if ((typeof enxerto.numClones !== 'undefined') && (enxerto.numClones !== null)) {
                                        registroControleEnxertiaPomar.numClones = enxerto.numClones;
                                    }
                                    if ((typeof enxerto.numRametes !== 'undefined') && (enxerto.numRametes !== null)) {
                                        registroControleEnxertiaPomar.numRametes = enxerto.numRametes;
                                    }
                                    if ((typeof registroControleEnxertiaPomar.enxertoAno === 'undefined') || !registroControleEnxertiaPomar.enxertoAno) {
                                        if (controleEnxertiaPomar.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.PORTA_ENXERTO) {
                                            registroControleEnxertiaPomar.enxertoAno = 'Porta Enxerto';
                                        }
                                        else if (controleEnxertiaPomar.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_1) {
                                            registroControleEnxertiaPomar.enxertoAno = 'Enxerto Ano 1';
                                        }
                                        else if (controleEnxertiaPomar.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_2) {
                                            registroControleEnxertiaPomar.enxertoAno = 'Enxerto Ano 2';
                                        }
                                        else if (controleEnxertiaPomar.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_3) {
                                            registroControleEnxertiaPomar.enxertoAno = 'Enxerto Ano 3';
                                        }
                                    }
                                    listaTratada.push(registroControleEnxertiaPomar);
                                }
                            });
                        }
                    });
                }
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    cellClass: 'text-center',
                    cellTemplate: `
                        <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                            <button type="button" class="btn btn-icon bx bx-dots-vertical-rounded" uib-dropdown-toggle></button>
                            <ul class="dropdown-menu dropdown-menu-left" uib-dropdown-menu role="menu">
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalControleEnxertiaPomar({ id: row.entity.id || row.entity.uuid, visualizar: true })' class="text-secondary w-100"><i class="bx bx-show mr-1 ml-1"></i>Visualizar</a></li>
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalControleEnxertiaPomar({ id: row.entity.id || row.entity.uuid, editar: true })' class="text-secondary w-100"><i class="bx bxs-edit-alt mr-1 ml-1"></i>Editar</a></li>
                                <li><hr></li>
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalControleEnxertiaPomar({ id: row.entity.id || row.entity.uuid, excluir: true })' class="text-secondary w-100"><i class="bx bxs-trash-alt mr-1 ml-1"></i>Excluir</a></li>
                            </ul>
                        </div> `
                });
                auxColunas.push({
                    name: 'data',
                    displayName: 'Data',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 100
                });
                auxColunas.push({
                    name: 'materialGenetico.descricao',
                    displayName: 'Material Genético',
                    width: 140
                });
                auxColunas.push({
                    name: 'fila',
                    displayName: 'Fila',
                    width: 70
                });
                auxColunas.push({
                    name: 'coluna',
                    displayName: 'Coluna',
                    width: 76
                });
                auxColunas.push({
                    name: 'plantio',
                    displayName: 'Plantio Nº',
                    width: 100
                });
                auxColunas.push({
                    name: 'sobrevivencia',
                    displayName: 'Sobrevivência',
                    width: 120
                });
                auxColunas.push({
                    name: 'replantio',
                    displayName: 'Replantio',
                    width: 100
                });
                auxColunas.push({
                    name: 'enxertoAno',
                    displayName: 'Enxerto Ano',
                    width: 110
                });
                auxColunas.push({
                    name: 'sobrevivencia60dias',
                    displayName: 'Sobrevivência 60 dias',
                    width: 160
                });
                auxColunas.push({
                    name: 'sobrevivencia90dias',
                    displayName: 'Sobrevivência 90 dias',
                    width: 160
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return {
                    data: listaTratada,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            return null;
        });
    }
    static dadosListaProdutividadePomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.pomarSelecionado || !ctrl.pomarSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if ((typeof ctrl.filtroProdutividadePomar === 'undefined') || !ctrl.filtroProdutividadePomar) {
                    ctrl.filtroProdutividadePomar = new FiltroProdutividadePomar_1.FiltroProdutividadePomar();
                }
                let filtro = new FiltroProdutividadePomar_1.FiltroProdutividadePomarTratado();
                filtro.listaSimplificadaOnline = !DispositivoUtil_1.DispositivoUtil.ehCelular();
                filtro.pomar = ctrl.pomarSelecionado.id;
                if ((typeof ctrl.filtroProdutividadePomar.dataIni !== 'undefined') && (ctrl.filtroProdutividadePomar.dataIni !== null)) {
                    filtro.dataIni = DataUtil_1.DataUtil.converterDataParaFormatoPHP(ctrl.filtroProdutividadePomar.dataIni);
                }
                if ((typeof ctrl.filtroProdutividadePomar.dataFim !== 'undefined') && (ctrl.filtroProdutividadePomar.dataFim !== null)) {
                    filtro.dataFim = DataUtil_1.DataUtil.converterDataParaFormatoPHP(ctrl.filtroProdutividadePomar.dataFim);
                }
                let lista = [];
                if (lista && (lista.length > 0)) {
                    angular.forEach(lista, (produtividadePomar) => {
                        if ((typeof produtividadePomar.criacao !== 'undefined') && produtividadePomar.criacao) {
                            produtividadePomar.criacao = new Date(produtividadePomar.criacao);
                        }
                        if ((typeof produtividadePomar.data !== 'undefined') && produtividadePomar.data) {
                            produtividadePomar.data = new Date(produtividadePomar.data);
                        }
                        if ((typeof produtividadePomar.dataHoraInclusao !== 'undefined') && produtividadePomar.dataHoraInclusao) {
                            produtividadePomar.dataHoraInclusao = new Date(produtividadePomar.dataHoraInclusao);
                        }
                        if ((typeof produtividadePomar.dataHoraAlteracao !== 'undefined') && produtividadePomar.dataHoraAlteracao) {
                            produtividadePomar.dataHoraAlteracao = new Date(produtividadePomar.dataHoraAlteracao);
                        }
                    });
                }
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    cellClass: 'text-center',
                    cellTemplate: `
                        <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                            <button type="button" class="btn btn-icon bx bx-dots-vertical-rounded" uib-dropdown-toggle></button>
                            <ul class="dropdown-menu dropdown-menu-left" uib-dropdown-menu role="menu">
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalProdutividadePomar({ id: row.entity.id || row.entity.uuid, visualizar: true })' class="text-secondary w-100"><i class="bx bx-show mr-1 ml-1"></i>Visualizar</a></li>
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalProdutividadePomar({ id: row.entity.id || row.entity.uuid, editar: true })' class="text-secondary w-100"><i class="bx bxs-edit-alt mr-1 ml-1"></i>Editar</a></li>
                                <li><hr></li>
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalProdutividadePomar({ id: row.entity.id || row.entity.uuid, excluir: true })' class="text-secondary w-100"><i class="bx bxs-trash-alt mr-1 ml-1"></i>Excluir</a></li>
                            </ul>
                        </div> `
                });
                auxColunas.push({
                    name: 'data',
                    displayName: 'Data',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 120
                });
                auxColunas.push({
                    name: 'funcionario.nome',
                    displayName: 'Funcionário',
                    width: 230
                });
                auxColunas.push({
                    name: 'quantidadeEnxertos',
                    displayName: 'Quant. Enxertos',
                    width: 140
                });
                auxColunas.push({
                    name: 'quantidadeColetas',
                    displayName: 'Quant. Coletas',
                    width: 140
                });
                auxColunas.push({
                    name: 'observacoes',
                    displayName: 'Observações',
                    width: 350
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            return null;
        });
    }
    static dadosListaFenologiaPomar(ctrl, seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.pomarSelecionado || !ctrl.pomarSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(seletorCarregamento ? seletorCarregamento : '.card');
                if ((typeof ctrl.filtroFenologiaPomar === 'undefined') || !ctrl.filtroFenologiaPomar) {
                    ctrl.filtroFenologiaPomar = new FiltroFenologiaPomar_1.FiltroFenologiaPomar();
                }
                let filtro = new FiltroFenologiaPomar_1.FiltroFenologiaPomarTratado();
                filtro.listaSimplificadaOnline = !DispositivoUtil_1.DispositivoUtil.ehCelular();
                filtro.pomar = ctrl.pomarSelecionado.id;
                if ((typeof ctrl.filtroFenologiaPomar.dataIni !== 'undefined') && (ctrl.filtroFenologiaPomar.dataIni !== null)) {
                    filtro.dataIni = DataUtil_1.DataUtil.converterDataParaFormatoPHP(ctrl.filtroFenologiaPomar.dataIni);
                }
                if ((typeof ctrl.filtroFenologiaPomar.dataFim !== 'undefined') && (ctrl.filtroFenologiaPomar.dataFim !== null)) {
                    filtro.dataFim = DataUtil_1.DataUtil.converterDataParaFormatoPHP(ctrl.filtroFenologiaPomar.dataFim);
                }
                let lista = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.FENOLOGIA_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.FENOLOGIA_POMAR)
                    .filtro(filtro)
                    .listar();
                if (lista && (lista.length > 0)) {
                    angular.forEach(lista, (fenologiaPomar) => {
                        if ((typeof fenologiaPomar.data !== 'undefined') && fenologiaPomar.data) {
                            fenologiaPomar.data = new Date(fenologiaPomar.data);
                        }
                        if ((typeof fenologiaPomar.dataHoraInclusao !== 'undefined') && fenologiaPomar.dataHoraInclusao) {
                            fenologiaPomar.dataHoraInclusao = new Date(fenologiaPomar.dataHoraInclusao);
                        }
                        if ((typeof fenologiaPomar.dataHoraAlteracao !== 'undefined') && fenologiaPomar.dataHoraAlteracao) {
                            fenologiaPomar.dataHoraAlteracao = new Date(fenologiaPomar.dataHoraAlteracao);
                        }
                        if ((typeof fenologiaPomar.pomarOrigem === 'undefined') || !fenologiaPomar.pomarOrigem) {
                            fenologiaPomar.pomarOrigem = new Pomar_1.Pomar();
                            if ((fenologiaPomar.pomarOrigemDescricao !== 'undefined') && fenologiaPomar.pomarOrigemDescricao) {
                                fenologiaPomar.pomarOrigem.codigo = ((typeof fenologiaPomar.pomarOrigemCodigo !== 'undefined') && fenologiaPomar.pomarOrigemCodigo) ? fenologiaPomar.pomarOrigemCodigo : null;
                                fenologiaPomar.pomarOrigem.descricao = fenologiaPomar.pomarOrigemDescricao;
                            }
                            else if ((typeof ctrl.pomarSelecionado !== 'undefined') && ctrl.pomarSelecionado) {
                                fenologiaPomar.pomarOrigem.codigo = ((typeof ctrl.pomarSelecionado.codigo !== 'undefined') && ctrl.pomarSelecionado.codigo) ? ctrl.pomarSelecionado.codigo : null;
                                fenologiaPomar.pomarOrigemCodigo = fenologiaPomar.pomarOrigem.codigo;
                                fenologiaPomar.pomarOrigem.descricao = ((typeof ctrl.pomarSelecionado.descricaoDetalhada !== 'undefined') && ctrl.pomarSelecionado.descricaoDetalhada) ? ctrl.pomarSelecionado.descricaoDetalhada : (((typeof ctrl.pomarSelecionado.descricao !== 'undefined') && ctrl.pomarSelecionado.descricao) ? ctrl.pomarSelecionado.descricao : null);
                                fenologiaPomar.pomarOrigemDescricao = fenologiaPomar.pomarOrigem.descricao;
                            }
                        }
                        fenologiaPomar.tipoFenologiaDescricao = fenologiaPomar.tipoFenologia ? (fenologiaPomar.tipoFenologia.substring(0, 1).toUpperCase() + fenologiaPomar.tipoFenologia.substring(1)) : null;
                        fenologiaPomar.estagioFloralDescricao = null;
                        if ((typeof fenologiaPomar.estagioFloralFeminino !== 'undefined') && fenologiaPomar.estagioFloralFeminino &&
                            (typeof fenologiaPomar.estagioFloralFeminino.descricao !== 'undefined') && fenologiaPomar.estagioFloralFeminino.descricao) {
                            fenologiaPomar.estagioFloralDescricao = fenologiaPomar.estagioFloralFeminino.descricao;
                        }
                        else if ((typeof fenologiaPomar.estagioFloralMasculino !== 'undefined') && fenologiaPomar.estagioFloralMasculino &&
                            (typeof fenologiaPomar.estagioFloralMasculino.descricao !== 'undefined') && fenologiaPomar.estagioFloralMasculino.descricao) {
                            fenologiaPomar.estagioFloralDescricao = fenologiaPomar.estagioFloralMasculino.descricao;
                        }
                        else if ((typeof fenologiaPomar.estagioFloralEucalipto !== 'undefined') && fenologiaPomar.estagioFloralEucalipto &&
                            (typeof fenologiaPomar.estagioFloralEucalipto.descricao !== 'undefined') && fenologiaPomar.estagioFloralEucalipto.descricao) {
                            fenologiaPomar.estagioFloralDescricao = fenologiaPomar.estagioFloralEucalipto.descricao;
                        }
                        fenologiaPomar.maturacaoFrutoDescricao = null;
                        if ((typeof fenologiaPomar.maturacaoFruto !== 'undefined') && fenologiaPomar.maturacaoFruto &&
                            (typeof fenologiaPomar.maturacaoFruto.descricao !== 'undefined') && fenologiaPomar.maturacaoFruto.descricao) {
                            fenologiaPomar.maturacaoFrutoDescricao = fenologiaPomar.maturacaoFruto.descricao;
                        }
                        fenologiaPomar.numArvoreDescricao = null;
                        if ((typeof fenologiaPomar.numArvore !== 'undefined') && fenologiaPomar.numArvore) {
                            fenologiaPomar.numArvoreDescricao = `${fenologiaPomar.numArvore}`;
                        }
                        if ((typeof fenologiaPomar.estagiosFlorais !== 'undefined') && fenologiaPomar.estagiosFlorais) {
                            angular.forEach(fenologiaPomar.estagiosFlorais, (estagioFloral) => {
                                if (fenologiaPomar.estagioFloralDescricao === null) {
                                    if ((typeof estagioFloral.estagioFloralFeminino !== 'undefined') && estagioFloral.estagioFloralFeminino &&
                                        (typeof estagioFloral.estagioFloralFeminino.descricao !== 'undefined') && estagioFloral.estagioFloralFeminino.descricao) {
                                        fenologiaPomar.estagioFloralDescricao = estagioFloral.estagioFloralFeminino.descricao;
                                    }
                                    else if ((typeof estagioFloral.estagioFloralMasculino !== 'undefined') && estagioFloral.estagioFloralMasculino &&
                                        (typeof estagioFloral.estagioFloralMasculino.descricao !== 'undefined') && estagioFloral.estagioFloralMasculino.descricao) {
                                        fenologiaPomar.estagioFloralDescricao = estagioFloral.estagioFloralMasculino.descricao;
                                    }
                                    else if ((typeof estagioFloral.estagioFloralEucalipto !== 'undefined') && estagioFloral.estagioFloralEucalipto &&
                                        (typeof estagioFloral.estagioFloralEucalipto.descricao !== 'undefined') && estagioFloral.estagioFloralEucalipto.descricao) {
                                        fenologiaPomar.estagioFloralDescricao = estagioFloral.estagioFloralEucalipto.descricao;
                                    }
                                }
                                if (fenologiaPomar.maturacaoFrutoDescricao === null) {
                                    if ((typeof estagioFloral.maturacaoFruto !== 'undefined') && estagioFloral.maturacaoFruto &&
                                        (typeof estagioFloral.maturacaoFruto.descricao !== 'undefined') && estagioFloral.maturacaoFruto.descricao) {
                                        fenologiaPomar.maturacaoFrutoDescricao = estagioFloral.maturacaoFruto.descricao;
                                    }
                                }
                                if (fenologiaPomar.numArvoreDescricao === null) {
                                    if ((typeof estagioFloral.numArvore !== 'undefined') && estagioFloral.numArvore) {
                                        fenologiaPomar.numArvoreDescricao = `${estagioFloral.numArvore}`;
                                    }
                                }
                            });
                        }
                    });
                }
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    cellClass: 'text-center',
                    cellTemplate: `
                        <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                            <button type="button" class="btn btn-icon bx bx-dots-vertical-rounded" uib-dropdown-toggle></button>
                            <ul class="dropdown-menu dropdown-menu-left" uib-dropdown-menu role="menu">
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalFenologiaPomar({ id: row.entity.id || row.entity.uuid, visualizar: true })' class="text-secondary w-100"><i class="bx bx-show mr-1 ml-1"></i>Visualizar</a></li>
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalFenologiaPomar({ id: row.entity.id || row.entity.uuid, editar: true })' class="text-secondary w-100"><i class="bx bxs-edit-alt mr-1 ml-1"></i>Editar</a></li>
                                <li><hr></li>
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalFenologiaPomar({ id: row.entity.id || row.entity.uuid, excluir: true })' class="text-secondary w-100"><i class="bx bxs-trash-alt mr-1 ml-1"></i>Excluir</a></li>
                            </ul>
                        </div> `
                });
                auxColunas.push({
                    name: 'tipoFenologiaDescricao',
                    displayName: 'Tipo Fenologia',
                    width: 120
                });
                auxColunas.push({
                    name: 'data',
                    displayName: 'Data',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 120
                });
                auxColunas.push({
                    name: 'estrobilo.descricao',
                    displayName: 'Estróbilo',
                    width: 120
                });
                auxColunas.push({
                    name: 'pomarOrigem.descricao',
                    displayName: 'Pomar Origem',
                    width: 160
                });
                auxColunas.push({
                    name: 'materialGenetico.descricao',
                    displayName: 'Material Genético',
                    width: 140
                });
                auxColunas.push({
                    name: 'estagioFloralDescricao',
                    displayName: 'Estágio Floral',
                    width: 120
                });
                auxColunas.push({
                    name: 'maturacaoFrutoDescricao',
                    displayName: 'Maturação Fruto',
                    width: 120
                });
                auxColunas.push({
                    name: 'numArvoreDescricao',
                    displayName: 'Nº Árvore',
                    width: 110
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            return null;
        });
    }
    static dadosListaPolinizacaoPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.pomarSelecionado || !ctrl.pomarSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if ((typeof ctrl.filtroPolinizacaoPomar === 'undefined') || !ctrl.filtroPolinizacaoPomar) {
                    ctrl.filtroPolinizacaoPomar = new FiltroPolinizacaoPomar_1.FiltroPolinizacaoPomar();
                }
                let filtro = new FiltroPolinizacaoPomar_1.FiltroPolinizacaoPomarTratado();
                filtro.listaSimplificadaOnline = !DispositivoUtil_1.DispositivoUtil.ehCelular();
                filtro.pomar = ctrl.pomarSelecionado.id;
                let lista = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POLINIZACAO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POLINIZACAO_POMAR)
                    .filtro(filtro)
                    .listar();
                let listaTratada = Array();
                if (lista && (lista.length > 0)) {
                    angular.forEach(lista, (polinizacaoPomar) => {
                        if ((typeof polinizacaoPomar.data !== 'undefined') && polinizacaoPomar.data) {
                            polinizacaoPomar.data = new Date(polinizacaoPomar.data);
                        }
                        if ((typeof polinizacaoPomar.dataHoraInclusao !== 'undefined') && polinizacaoPomar.dataHoraInclusao) {
                            polinizacaoPomar.dataHoraInclusao = new Date(polinizacaoPomar.dataHoraInclusao);
                        }
                        if ((typeof polinizacaoPomar.dataHoraAlteracao !== 'undefined') && polinizacaoPomar.dataHoraAlteracao) {
                            polinizacaoPomar.dataHoraAlteracao = new Date(polinizacaoPomar.dataHoraAlteracao);
                        }
                        polinizacaoPomar.tipoPlanejamentoDescricao = polinizacaoPomar.tipoPlanejamento ? (polinizacaoPomar.tipoPlanejamento.substring(0, 1).toUpperCase() + polinizacaoPomar.tipoPlanejamento.substring(1)) : null;
                        polinizacaoPomar.cruzamentoDescricao = (polinizacaoPomar.materialGeneticoFeminino && polinizacaoPomar.materialGeneticoFeminino.descricao ? polinizacaoPomar.materialGeneticoFeminino.descricao : '') + 'x' +
                            (polinizacaoPomar.materialGeneticoMasculino && polinizacaoPomar.materialGeneticoMasculino.descricao ? polinizacaoPomar.materialGeneticoMasculino.descricao : '');
                        if ((typeof polinizacaoPomar.coletasConesSementes !== 'undefined') && polinizacaoPomar.coletasConesSementes && (polinizacaoPomar.coletasConesSementes.length > 0)) {
                            let totalizaColetaConesSementes = new ColetaConesSementesPolinizacaoPomar_1.ColetaConesSementesPolinizacaoPomar();
                            totalizaColetaConesSementes.data = null;
                            totalizaColetaConesSementes.numConesColetados = 0;
                            angular.forEach(polinizacaoPomar.coletasConesSementes, (coletaConesSementes) => {
                                if ((typeof coletaConesSementes.data !== 'undefined') && coletaConesSementes.data) {
                                    if (typeof coletaConesSementes.data === 'string') {
                                        coletaConesSementes.data = new Date(coletaConesSementes.data);
                                    }
                                    if (!totalizaColetaConesSementes.data || (coletaConesSementes.data >= totalizaColetaConesSementes.data)) {
                                        totalizaColetaConesSementes.data = coletaConesSementes.data;
                                    }
                                }
                                if ((typeof coletaConesSementes.numConesColetados !== 'undefined') && coletaConesSementes.numConesColetados) {
                                    totalizaColetaConesSementes.numConesColetados += coletaConesSementes.numConesColetados;
                                }
                            });
                            polinizacaoPomar.coletaConesSementes = totalizaColetaConesSementes;
                            listaTratada.push(polinizacaoPomar);
                        }
                        else {
                            listaTratada.push(polinizacaoPomar);
                        }
                    });
                }
                ctrl.planejamentosCruzamentosPolinizacaoPomar = new Array();
                angular.forEach(listaTratada, (polinizacaoPomar) => {
                    let polinizacaoPomarValido = false;
                    let descricaoPolinizacaoPomar = '';
                    if ((typeof polinizacaoPomar.tipoPlanejamentoDescricao !== 'undefined') && polinizacaoPomar.tipoPlanejamentoDescricao) {
                        descricaoPolinizacaoPomar += polinizacaoPomar.tipoPlanejamentoDescricao;
                    }
                    else if ((typeof polinizacaoPomar.tipoPlanejamento !== 'undefined') && polinizacaoPomar.tipoPlanejamento) {
                        descricaoPolinizacaoPomar += polinizacaoPomar.tipoPlanejamento;
                    }
                    if ((typeof polinizacaoPomar.materialGeneticoFeminino !== 'undefined') && polinizacaoPomar.materialGeneticoFeminino &&
                        (typeof polinizacaoPomar.materialGeneticoFeminino.descricao !== 'undefined') && polinizacaoPomar.materialGeneticoFeminino.descricao &&
                        (typeof polinizacaoPomar.materialGeneticoMasculino !== 'undefined') && polinizacaoPomar.materialGeneticoMasculino &&
                        (typeof polinizacaoPomar.materialGeneticoMasculino.descricao !== 'undefined') && polinizacaoPomar.materialGeneticoMasculino.descricao) {
                        polinizacaoPomarValido = true;
                        descricaoPolinizacaoPomar += (descricaoPolinizacaoPomar && (descricaoPolinizacaoPomar.length > 0) ? ' - ' : '') +
                            polinizacaoPomar.materialGeneticoFeminino.descricao + 'x' + polinizacaoPomar.materialGeneticoMasculino.descricao;
                    }
                    else if ((typeof polinizacaoPomar.cruzamentoDescricao !== 'undefined') && polinizacaoPomar.cruzamentoDescricao) {
                        polinizacaoPomarValido = true;
                        descricaoPolinizacaoPomar += (descricaoPolinizacaoPomar && (descricaoPolinizacaoPomar.length > 0) ? ' - ' : '') +
                            polinizacaoPomar.cruzamentoDescricao;
                    }
                    else if ((typeof polinizacaoPomar.materialGeneticoFeminino !== 'undefined') && polinizacaoPomar.materialGeneticoFeminino &&
                        (typeof polinizacaoPomar.materialGeneticoFeminino.descricao !== 'undefined') && polinizacaoPomar.materialGeneticoFeminino.descricao) {
                        polinizacaoPomarValido = true;
                        descricaoPolinizacaoPomar += (descricaoPolinizacaoPomar && (descricaoPolinizacaoPomar.length > 0) ? ' - ' : '') +
                            polinizacaoPomar.materialGeneticoFeminino.descricao;
                    }
                    else if ((typeof polinizacaoPomar.materialGeneticoMasculino !== 'undefined') && polinizacaoPomar.materialGeneticoMasculino &&
                        (typeof polinizacaoPomar.materialGeneticoMasculino.descricao !== 'undefined') && polinizacaoPomar.materialGeneticoMasculino.descricao) {
                        polinizacaoPomarValido = true;
                        descricaoPolinizacaoPomar += (descricaoPolinizacaoPomar && (descricaoPolinizacaoPomar.length > 0) ? ' - ' : '') +
                            polinizacaoPomar.materialGeneticoMasculino.descricao;
                    }
                    if ((typeof polinizacaoPomar.pomarOrigemPolen !== 'undefined') && polinizacaoPomar.pomarOrigemPolen &&
                        (typeof polinizacaoPomar.pomarOrigemPolen.descricao !== 'undefined') && polinizacaoPomar.pomarOrigemPolen.descricao) {
                        descricaoPolinizacaoPomar += (descricaoPolinizacaoPomar && (descricaoPolinizacaoPomar.length > 0) ? '  ' : '') +
                            '(' + polinizacaoPomar.pomarOrigemPolen.descricao + ')';
                    }
                    if (polinizacaoPomarValido) {
                        let polinizacaoPomarSimplificado = ctrl.planejamentosCruzamentosPolinizacaoPomar.find(o => o.descricao === descricaoPolinizacaoPomar);
                        if ((typeof polinizacaoPomarSimplificado !== 'undefined') && polinizacaoPomarSimplificado) {
                            if (polinizacaoPomarSimplificado.planejamentosPolinizacaoPomar.indexOf((typeof polinizacaoPomar.id !== 'undefined') ? polinizacaoPomar.id : null) === -1) {
                                polinizacaoPomarSimplificado.planejamentosPolinizacaoPomar.push((typeof polinizacaoPomar.id !== 'undefined') ? polinizacaoPomar.id : null);
                            }
                        }
                        else {
                            polinizacaoPomarSimplificado = new PolinizacaoPomarSimplificado_1.PolinizacaoPomarSimplificado();
                            polinizacaoPomarSimplificado.id = (typeof polinizacaoPomar.id !== 'undefined') ? polinizacaoPomar.id : null;
                            polinizacaoPomarSimplificado.status = (typeof polinizacaoPomar.status !== 'undefined') ? polinizacaoPomar.status : null;
                            polinizacaoPomarSimplificado.criacao = (typeof polinizacaoPomar.criacao !== 'undefined') ? polinizacaoPomar.criacao : null;
                            polinizacaoPomarSimplificado.codigo = (typeof polinizacaoPomar.codigo !== 'undefined') ? polinizacaoPomar.codigo : null;
                            polinizacaoPomarSimplificado.data = (typeof polinizacaoPomar.data !== 'undefined') ? polinizacaoPomar.data : null;
                            polinizacaoPomarSimplificado.descricao = descricaoPolinizacaoPomar;
                            polinizacaoPomarSimplificado.pomar = (typeof polinizacaoPomar.pomar !== 'undefined') ? polinizacaoPomar.pomar : null;
                            polinizacaoPomarSimplificado.tipoPlanejamento = (typeof polinizacaoPomar.tipoPlanejamento !== 'undefined') ? polinizacaoPomar.tipoPlanejamento : null;
                            polinizacaoPomarSimplificado.materialGeneticoFeminino = (typeof polinizacaoPomar.materialGeneticoFeminino !== 'undefined') ? polinizacaoPomar.materialGeneticoFeminino : null;
                            polinizacaoPomarSimplificado.pomarOrigemPolen = (typeof polinizacaoPomar.pomarOrigemPolen !== 'undefined') ? polinizacaoPomar.pomarOrigemPolen : null;
                            polinizacaoPomarSimplificado.pomarOrigemPolenCodigo = (typeof polinizacaoPomar.pomarOrigemPolenCodigo !== 'undefined') ? polinizacaoPomar.pomarOrigemPolenCodigo : null;
                            polinizacaoPomarSimplificado.pomarOrigemPolenDescricao = (typeof polinizacaoPomar.pomarOrigemPolenDescricao !== 'undefined') ? polinizacaoPomar.pomarOrigemPolenDescricao : null;
                            polinizacaoPomarSimplificado.materialGeneticoMasculino = (typeof polinizacaoPomar.materialGeneticoMasculino !== 'undefined') ? polinizacaoPomar.materialGeneticoMasculino : null;
                            polinizacaoPomarSimplificado.especieMaterialGeneticoMasculino = (typeof polinizacaoPomar.especieMaterialGeneticoMasculino !== 'undefined') ? polinizacaoPomar.especieMaterialGeneticoMasculino : null;
                            polinizacaoPomarSimplificado.planejamentosPolinizacaoPomar = new Array();
                            polinizacaoPomarSimplificado.planejamentosPolinizacaoPomar.push((typeof polinizacaoPomar.id !== 'undefined') ? polinizacaoPomar.id : null);
                            ctrl.planejamentosCruzamentosPolinizacaoPomar.push(polinizacaoPomarSimplificado);
                        }
                    }
                });
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    cellClass: 'text-center',
                    cellTemplate: `
                        <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                            <button type="button" class="btn btn-icon bx bx-dots-vertical-rounded" uib-dropdown-toggle></button>
                            <ul class="dropdown-menu dropdown-menu-left" uib-dropdown-menu role="menu" style="min-width: 206px !important">
                                <li class="dropdown-item" role="menuitem">
                                    <a href='' ng-click='grid.appScope.getParentController().exibirModalPolinizacaoPomar("${ctrl.tipoPolinizacaoPomar.PLANEJAMENTO_CRUZAMENTOS}", { id: (row.entity.id ? row.entity.id : row.entity.uuid), editar: true })' class="text-secondary w-100">
                                        <i class="bx bxs-edit-alt mr-50 ml-1"></i> Editar Planejamento
                                    </a>
                                </li>
                                <li class="dropdown-item" role="menuitem">
                                    <a href='' ng-click='grid.appScope.getParentController().exibirModalPolinizacaoPomar("${ctrl.tipoPolinizacaoPomar.MONITORAMENTO_FLORACAO}", { id: (row.entity.id ? row.entity.id : row.entity.uuid), editar: true })' class="text-secondary w-100">
                                        <i class="bx bx-detail mr-50 ml-1"></i> Monitoramento de floração
                                    </a>
                                </li>
                                <li class="dropdown-item" role="menuitem">
                                    <a href='' ng-click='grid.appScope.getParentController().exibirModalPolinizacaoPomar("${ctrl.tipoPolinizacaoPomar.POLINIZACAO_PINUS}", { id: (row.entity.id ? row.entity.id : row.entity.uuid), editar: true })' class="text-secondary w-100">
                                        <i class="bx bx-detail mr-50 ml-1"></i> Polinização de Pinus
                                    </a>
                                </li>
                                <li class="dropdown-item" role="menuitem">
                                    <a href='' ng-click='grid.appScope.getParentController().exibirModalPolinizacaoPomar("${ctrl.tipoPolinizacaoPomar.POLINIZACAO_EUCALYPTUS}", { id: (row.entity.id ? row.entity.id : row.entity.uuid), editar: true })' class="text-secondary w-100">
                                        <i class="bx bx-detail mr-50 ml-1"></i> Polinização de Eucalyptus
                                    </a>
                                </li>
                                <li class="dropdown-item" role="menuitem">
                                    <a href='' ng-click='grid.appScope.getParentController().exibirModalPolinizacaoPomar("${ctrl.tipoPolinizacaoPomar.COLETA_CONES_SEMENTES}", { id: (row.entity.id ? row.entity.id : row.entity.uuid), editar: true })' class="text-secondary w-100">
                                        <i class="bx bx-detail mr-50 ml-1"></i> Coleta de cones/sementes
                                    </a>
                                </li>
                                <li class="dropdown-item" role="menuitem">
                                    <a href='' ng-click='grid.appScope.getParentController().exibirModalPolinizacaoPomar("${ctrl.tipoPolinizacaoPomar.BENEFICIAMENTO_PINUS}", { id: (row.entity.id ? row.entity.id : row.entity.uuid), editar: true })' class="text-secondary w-100">
                                        <i class="bx bx-detail mr-50 ml-1"></i> Beneficiamento Pinus
                                    </a>
                                </li>
                                <li class="dropdown-item" role="menuitem">
                                    <a href='' ng-click='grid.appScope.getParentController().exibirModalPolinizacaoPomar("${ctrl.tipoPolinizacaoPomar.BENEFICIAMENTO_EUCALYPTUS}", { id: (row.entity.id ? row.entity.id : row.entity.uuid), editar: true })' class="text-secondary w-100">
                                        <i class="bx bx-detail mr-50 ml-1"></i> Beneficiamento Eucalyptus
                                    </a>
                                </li>
                                <li><hr></li>
                                <li class="dropdown-item" role="menuitem">
                                    <a href='' ng-click='grid.appScope.getParentController().exibirModalPolinizacaoPomar("${ctrl.tipoPolinizacaoPomar.PLANEJAMENTO_CRUZAMENTOS}", { id: (row.entity.id ? row.entity.id : row.entity.uuid), excluir: true })' class="text-secondary w-100">
                                        <i class="bx bxs-trash-alt mr-50 ml-1"></i> Excluir
                                    </a>
                                </li>
                            </ul>
                        </div> `
                });
                auxColunas.push({
                    name: 'tipoPlanejamentoDescricao',
                    displayName: 'Planejamento',
                    width: 120
                });
                auxColunas.push({
                    name: 'materialGeneticoFeminino.descricao',
                    displayName: 'Mãe/feminino',
                    width: 140
                });
                auxColunas.push({
                    name: 'materialGeneticoMasculino.descricao',
                    displayName: 'Pai/masculino',
                    width: 140
                });
                auxColunas.push({
                    name: 'cruzamentoDescricao',
                    displayName: 'Cruzamento',
                    width: 140
                });
                auxColunas.push({
                    name: 'pomarOrigemPolen.descricao',
                    displayName: 'Pomar Origem Pólen',
                    width: 160
                });
                auxColunas.push({
                    name: 'coletaConesSementes.data',
                    displayName: 'Data coleta floração',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 150
                });
                auxColunas.push({
                    name: 'coletaConesSementes.numConesColetados',
                    displayName: 'Quant. Cones Coletados',
                    width: 180
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return {
                    data: listaTratada,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            return null;
        });
    }
    static dadosListaMonitoramentoFloracaoMasculinoPolinizacaoPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.pomarSelecionado || !ctrl.pomarSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let lista = new Array();
                if ((typeof ctrl.listaOpcoesPolinizacaoPomar !== 'undefined') && ctrl.listaOpcoesPolinizacaoPomar &&
                    (typeof ctrl.listaOpcoesPolinizacaoPomar.data !== 'undefined') && ctrl.listaOpcoesPolinizacaoPomar.data) {
                    angular.forEach(ctrl.listaOpcoesPolinizacaoPomar.data, (polinizacaoPomarBase) => {
                        if ((typeof polinizacaoPomarBase.monitoramentosFloracao !== 'undefined') && polinizacaoPomarBase.monitoramentosFloracao && (polinizacaoPomarBase.monitoramentosFloracao.length > 0)) {
                            angular.forEach(polinizacaoPomarBase.monitoramentosFloracao, (monitoramentoFloracaoPomar, keyMonitoramentoFloracaoPomar) => {
                                let ehEstrobiloFeminino = false;
                                let ehEstrobiloMasculino = false;
                                if ((typeof monitoramentoFloracaoPomar.estrobilo !== 'undefined') && monitoramentoFloracaoPomar.estrobilo &&
                                    (typeof monitoramentoFloracaoPomar.estrobilo.id !== 'undefined') && monitoramentoFloracaoPomar.estrobilo.id) {
                                    if (ctrl.estrobiloFeminino && ctrl.estrobiloFeminino.id && (monitoramentoFloracaoPomar.estrobilo.id === ctrl.estrobiloFeminino.id)) {
                                        ehEstrobiloFeminino = true;
                                    }
                                    else if (ctrl.estrobiloMasculino && ctrl.estrobiloMasculino.id && (monitoramentoFloracaoPomar.estrobilo.id === ctrl.estrobiloMasculino.id)) {
                                        ehEstrobiloMasculino = true;
                                    }
                                    else if (monitoramentoFloracaoPomar.estrobilo.id === Estrobilo_1.Estrobilo.FEMININO) {
                                        ehEstrobiloFeminino = true;
                                    }
                                    else if (monitoramentoFloracaoPomar.estrobilo.id === Estrobilo_1.Estrobilo.MASCULINO) {
                                        ehEstrobiloMasculino = true;
                                    }
                                }
                                if (!ehEstrobiloFeminino && !ehEstrobiloMasculino) {
                                    if ((typeof monitoramentoFloracaoPomar.materialGeneticoMasculino !== 'undefined') && monitoramentoFloracaoPomar.materialGeneticoMasculino &&
                                        (typeof monitoramentoFloracaoPomar.materialGeneticoMasculino.id !== 'undefined') && monitoramentoFloracaoPomar.materialGeneticoMasculino.id) {
                                        ehEstrobiloMasculino = true;
                                    }
                                }
                                if (ehEstrobiloMasculino) {
                                    let polinizacaoPomar = angular.copy(polinizacaoPomarBase);
                                    polinizacaoPomar.monitoramentoFloracao = monitoramentoFloracaoPomar;
                                    polinizacaoPomar.indiceMonitoramentoFloracao = keyMonitoramentoFloracaoPomar;
                                    if ((typeof polinizacaoPomar.monitoramentoFloracao.pomarOrigem !== 'undefined') && polinizacaoPomar.monitoramentoFloracao.pomarOrigem) {
                                        polinizacaoPomar.pomarOrigemPolen = polinizacaoPomar.monitoramentoFloracao.pomarOrigem;
                                    }
                                    if ((typeof polinizacaoPomar.monitoramentoFloracao.materialGeneticoMasculino !== 'undefined') && polinizacaoPomar.monitoramentoFloracao.materialGeneticoMasculino) {
                                        polinizacaoPomar.materialGeneticoMasculino = polinizacaoPomar.monitoramentoFloracao.materialGeneticoMasculino;
                                    }
                                    if ((typeof polinizacaoPomar.id !== 'undefined') && polinizacaoPomar.id && (keyMonitoramentoFloracaoPomar !== null)) {
                                        if (!lista.find(o => (o.id === polinizacaoPomar.id) && (o.indiceMonitoramentoFloracao === keyMonitoramentoFloracaoPomar))) {
                                            lista.push(polinizacaoPomar);
                                        }
                                    }
                                    else {
                                        lista.push(polinizacaoPomar);
                                    }
                                }
                            });
                        }
                    });
                }
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'acaoEditar',
                    displayName: 'Ação',
                    width: 70,
                    enableFiltering: false,
                    enableSorting: false,
                    cellTemplate: `
                        <div class="btn-group btn-ui-grid">
                            <button type="button" class="btn btn-icon"
                                ng-click="grid.appScope.getParentController().editarMonitoramentoFloracaoPolinizacaoPomar(row.entity.id || row.entity.uuid, row.entity.indiceMonitoramentoFloracao)">
                                <i class="bx bxs-edit-alt"></i> Editar
                            </button>
                        </div>`
                });
                auxColunas.push({
                    name: 'monitoramentoFloracao.dataColeta',
                    displayName: 'Data Coleta',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 110
                });
                auxColunas.push({
                    name: 'materialGeneticoFeminino.descricao',
                    displayName: 'Mat. Genético Feminino',
                    width: 170
                });
                auxColunas.push({
                    name: 'pomarOrigemPolen.descricao',
                    displayName: 'Pomar Origem Pólen',
                    width: 150
                });
                auxColunas.push({
                    name: 'materialGeneticoMasculino.descricao',
                    displayName: 'Mat. Genético Masculino',
                    width: 174
                });
                auxColunas.push({
                    name: 'monitoramentoFloracao.quantidadePolenColetado',
                    displayName: 'Quant. Pólen Coletado (g)',
                    width: 200
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            return null;
        });
    }
    static dadosListaMonitoramentoFloracaoFemininoPolinizacaoPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.pomarSelecionado || !ctrl.pomarSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let lista = new Array();
                if ((typeof ctrl.listaOpcoesPolinizacaoPomar !== 'undefined') && ctrl.listaOpcoesPolinizacaoPomar &&
                    (typeof ctrl.listaOpcoesPolinizacaoPomar.data !== 'undefined') && ctrl.listaOpcoesPolinizacaoPomar.data) {
                    angular.forEach(ctrl.listaOpcoesPolinizacaoPomar.data, (polinizacaoPomarBase) => {
                        if ((typeof polinizacaoPomarBase.monitoramentosFloracao !== 'undefined') && polinizacaoPomarBase.monitoramentosFloracao && (polinizacaoPomarBase.monitoramentosFloracao.length > 0)) {
                            angular.forEach(polinizacaoPomarBase.monitoramentosFloracao, (monitoramentoFloracaoPomar, keyMonitoramentoFloracaoPomar) => {
                                let ehEstrobiloFeminino = false;
                                let ehEstrobiloMasculino = false;
                                if ((typeof monitoramentoFloracaoPomar.estrobilo !== 'undefined') && monitoramentoFloracaoPomar.estrobilo &&
                                    (typeof monitoramentoFloracaoPomar.estrobilo.id !== 'undefined') && monitoramentoFloracaoPomar.estrobilo.id) {
                                    if (ctrl.estrobiloFeminino && ctrl.estrobiloFeminino.id && (monitoramentoFloracaoPomar.estrobilo.id === ctrl.estrobiloFeminino.id)) {
                                        ehEstrobiloFeminino = true;
                                    }
                                    else if (ctrl.estrobiloMasculino && ctrl.estrobiloMasculino.id && (monitoramentoFloracaoPomar.estrobilo.id === ctrl.estrobiloMasculino.id)) {
                                        ehEstrobiloMasculino = true;
                                    }
                                    else if (monitoramentoFloracaoPomar.estrobilo.id === Estrobilo_1.Estrobilo.FEMININO) {
                                        ehEstrobiloFeminino = true;
                                    }
                                    else if (monitoramentoFloracaoPomar.estrobilo.id === Estrobilo_1.Estrobilo.MASCULINO) {
                                        ehEstrobiloMasculino = true;
                                    }
                                }
                                if (!ehEstrobiloFeminino && !ehEstrobiloMasculino) {
                                    if ((typeof monitoramentoFloracaoPomar.materialGeneticoMasculino !== 'undefined') && monitoramentoFloracaoPomar.materialGeneticoMasculino &&
                                        (typeof monitoramentoFloracaoPomar.materialGeneticoMasculino.id !== 'undefined') && monitoramentoFloracaoPomar.materialGeneticoMasculino.id) {
                                        ehEstrobiloMasculino = true;
                                    }
                                }
                                if (!ehEstrobiloFeminino && !ehEstrobiloMasculino) {
                                    ehEstrobiloFeminino = true;
                                }
                                if (ehEstrobiloFeminino) {
                                    let polinizacaoPomar = angular.copy(polinizacaoPomarBase);
                                    polinizacaoPomar.monitoramentoFloracao = monitoramentoFloracaoPomar;
                                    polinizacaoPomar.indiceMonitoramentoFloracao = keyMonitoramentoFloracaoPomar;
                                    if ((typeof polinizacaoPomar.id !== 'undefined') && polinizacaoPomar.id && (keyMonitoramentoFloracaoPomar !== null)) {
                                        if (!lista.find(o => (o.id === polinizacaoPomar.id) && (o.indiceMonitoramentoFloracao === keyMonitoramentoFloracaoPomar))) {
                                            lista.push(polinizacaoPomar);
                                        }
                                    }
                                    else {
                                        lista.push(polinizacaoPomar);
                                    }
                                }
                            });
                        }
                    });
                }
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'acaoEditar',
                    displayName: 'Ação',
                    width: 70,
                    enableFiltering: false,
                    enableSorting: false,
                    cellTemplate: `
                        <div class="btn-group btn-ui-grid">
                            <button type="button" class="btn btn-icon"
                                ng-click="grid.appScope.getParentController().editarMonitoramentoFloracaoPolinizacaoPomar(row.entity.id || row.entity.uuid, row.entity.indiceMonitoramentoFloracao)">
                                <i class="bx bxs-edit-alt"></i> Editar
                            </button>
                        </div>`
                });
                auxColunas.push({
                    name: 'monitoramentoFloracao.dataIsolamento',
                    displayName: 'Data Isolamento',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 130
                });
                auxColunas.push({
                    name: 'materialGeneticoFeminino.descricao',
                    displayName: 'Mat. Genético Feminino',
                    width: 170
                });
                auxColunas.push({
                    name: 'pomarOrigemPolen.descricao',
                    displayName: 'Pomar Origem Pólen',
                    width: 150
                });
                auxColunas.push({
                    name: 'materialGeneticoMasculino.descricao',
                    displayName: 'Mat. Genético Masculino',
                    width: 174
                });
                auxColunas.push({
                    name: 'monitoramentoFloracao.fila',
                    displayName: 'Fila',
                    width: 80
                });
                auxColunas.push({
                    name: 'monitoramentoFloracao.coluna',
                    displayName: 'Coluna',
                    width: 88
                });
                auxColunas.push({
                    name: 'monitoramentoFloracao.numPacotes',
                    displayName: 'Nº Pacotes',
                    width: 110
                });
                auxColunas.push({
                    name: 'monitoramentoFloracao.numEstrobilosFemininosCones',
                    displayName: 'Nº Estróbilos Femininos/Cones',
                    width: 220
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            return null;
        });
    }
    static dadosListaEstoquePolenPolinizacaoPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.pomarSelecionado || !ctrl.pomarSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let lista = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESTOQUE_POLEN_POLINIZACAO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESTOQUE_POLEN_POLINIZACAO_POMAR)
                    .filtro({
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    pomar: ctrl.pomarSelecionado.id
                })
                    .listar();
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'materialGenetico.genero.descricao',
                    displayName: 'Gênero',
                    width: 90
                });
                auxColunas.push({
                    name: 'materialGenetico.especie.descricao',
                    displayName: 'Espécie',
                    width: 140
                });
                auxColunas.push({
                    name: 'pomarOrigemPolen.descricao',
                    displayName: 'Pomar Origem Pólen',
                    width: 150
                });
                auxColunas.push({
                    name: 'materialGenetico.geracao.descricao',
                    displayName: 'Geração',
                    width: 90
                });
                auxColunas.push({
                    name: 'materialGenetico.descricao',
                    displayName: 'Mat. Genético',
                    width: 114
                });
                auxColunas.push({
                    name: 'anoColeta',
                    displayName: 'Ano Coleta',
                    width: 98
                });
                auxColunas.push({
                    name: 'quantidadeColetada',
                    displayName: 'Quant. Coletada',
                    width: 130
                });
                auxColunas.push({
                    name: 'quantidadeAtual',
                    displayName: 'Quant. Atual',
                    width: 104
                });
                auxColunas.push({
                    name: 'acaoEditar',
                    displayName: 'Ação',
                    width: 90,
                    cellTemplate: `
                        <div class="btn-group btn-ui-grid">
                            <button type="button" class="btn btn-icon"
                                ng-click="grid.appScope.getParentController().editarEstoquePolenPolinizacaoPomar(row.entity.id || row.entity.uuid)">
                                <i class="bx bxs-edit-alt"></i> Editar
                            </button>
                        </div>`
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            return null;
        });
    }
    static dadosListaHistoricoEstoquePolenPolinizacaoPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.pomarSelecionado || !ctrl.pomarSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let lista = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.HISTORICO_ESTOQUE_POLEN_POLINIZACAO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.HISTORICO_ESTOQUE_POLEN_POLINIZACAO_POMAR)
                    .filtro({
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    pomar: ctrl.pomarSelecionado.id
                })
                    .listar();
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'criacao',
                    displayName: 'Data/Hora',
                    cellFilter: 'date:"dd/MM/yyyy HH:mm:ss"',
                    width: 170
                });
                auxColunas.push({
                    name: 'estoquePolenPolinizacaoPomar.pomarOrigemPolen.descricao',
                    displayName: 'Pomar Origem Pólen',
                    width: 150
                });
                auxColunas.push({
                    name: 'estoquePolenPolinizacaoPomar.materialGenetico.descricao',
                    displayName: 'Mat. Genético',
                    width: 120
                });
                auxColunas.push({
                    name: 'movimento',
                    displayName: 'Movimentação',
                    width: 120
                });
                auxColunas.push({
                    name: 'quantidadeAnterior',
                    displayName: 'Quant. Anterior',
                    width: 130
                });
                auxColunas.push({
                    name: 'quantidadeMovimento',
                    displayName: 'Quant. Movimento',
                    width: 140
                });
                auxColunas.push({
                    name: 'quantidadeAtual',
                    displayName: 'Quant. Atual',
                    width: 120
                });
                auxColunas.push({
                    name: 'usuario.nomeCompleto',
                    displayName: 'Usuário',
                    width: 160
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            return null;
        });
    }
    static dadosListaLancamentosPinusPolinizacaoPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.pomarSelecionado || !ctrl.pomarSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let lista = new Array();
                if ((typeof ctrl.listaOpcoesPolinizacaoPomar !== 'undefined') && ctrl.listaOpcoesPolinizacaoPomar &&
                    (typeof ctrl.listaOpcoesPolinizacaoPomar.data !== 'undefined') && ctrl.listaOpcoesPolinizacaoPomar.data) {
                    angular.forEach(ctrl.listaOpcoesPolinizacaoPomar.data, (polinizacaoPomarBase) => {
                        if ((typeof polinizacaoPomarBase.polinizacoes !== 'undefined') && polinizacaoPomarBase.polinizacoes && (polinizacaoPomarBase.polinizacoes.length > 0)) {
                            angular.forEach(polinizacaoPomarBase.polinizacoes, (lancamentoPolinizacaoPomar, keyLancamentoPolinizacaoPomar) => {
                                if ((typeof lancamentoPolinizacaoPomar.ehPinus !== 'undefined') && lancamentoPolinizacaoPomar.ehPinus) {
                                    let polinizacaoPomar = angular.copy(polinizacaoPomarBase);
                                    polinizacaoPomar.polinizacao = lancamentoPolinizacaoPomar;
                                    polinizacaoPomar.indicePolinizacao = keyLancamentoPolinizacaoPomar;
                                    if ((typeof polinizacaoPomar.id !== 'undefined') && polinizacaoPomar.id && (keyLancamentoPolinizacaoPomar !== null)) {
                                        if (!lista.find(o => (o.id === polinizacaoPomar.id) && (o.indicePolinizacao === keyLancamentoPolinizacaoPomar))) {
                                            lista.push(polinizacaoPomar);
                                        }
                                    }
                                    else {
                                        lista.push(polinizacaoPomar);
                                    }
                                }
                            });
                        }
                    });
                }
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'acaoEditar',
                    displayName: 'Ação',
                    width: 70,
                    enableFiltering: false,
                    enableSorting: false,
                    cellTemplate: `
                        <div class="btn-group btn-ui-grid">
                            <button type="button" class="btn btn-icon"
                                ng-click="grid.appScope.getParentController().editarLancamentoPinusPolinizacaoPomar(row.entity.id || row.entity.uuid, row.entity.indicePolinizacao)">
                                <i class="bx bxs-edit-alt"></i> Editar
                            </button>
                        </div>`
                });
                auxColunas.push({
                    name: 'materialGeneticoFeminino.descricao',
                    displayName: 'Mat. Genético Feminino',
                    width: 170
                });
                auxColunas.push({
                    name: 'pomarOrigemPolen.descricao',
                    displayName: 'Pomar Origem Pólen',
                    width: 150
                });
                auxColunas.push({
                    name: 'materialGeneticoMasculino.descricao',
                    displayName: 'Mat. Genético Masculino',
                    width: 174
                });
                auxColunas.push({
                    name: 'polinizacao.dataInicial',
                    displayName: 'Data Inicial',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 110
                });
                auxColunas.push({
                    name: 'polinizacao.dataFinal',
                    displayName: 'Data Final',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 110
                });
                auxColunas.push({
                    name: 'polinizacao.dataRetiradaPacote',
                    displayName: 'Data R. Pacote',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 128
                });
                auxColunas.push({
                    name: 'polinizacao.polenAno',
                    displayName: 'Pólen Ano',
                    width: 110
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            return null;
        });
    }
    static dadosListaLancamentosEucalyptusPolinizacaoPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.pomarSelecionado || !ctrl.pomarSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let lista = new Array();
                if ((typeof ctrl.listaOpcoesPolinizacaoPomar !== 'undefined') && ctrl.listaOpcoesPolinizacaoPomar &&
                    (typeof ctrl.listaOpcoesPolinizacaoPomar.data !== 'undefined') && ctrl.listaOpcoesPolinizacaoPomar.data) {
                    angular.forEach(ctrl.listaOpcoesPolinizacaoPomar.data, (polinizacaoPomarBase) => {
                        if ((typeof polinizacaoPomarBase.polinizacoes !== 'undefined') && polinizacaoPomarBase.polinizacoes && (polinizacaoPomarBase.polinizacoes.length > 0)) {
                            angular.forEach(polinizacaoPomarBase.polinizacoes, (lancamentoPolinizacaoPomar, keyLancamentoPolinizacaoPomar) => {
                                if ((typeof lancamentoPolinizacaoPomar.ehEucalyptus !== 'undefined') && lancamentoPolinizacaoPomar.ehEucalyptus) {
                                    let polinizacaoPomar = angular.copy(polinizacaoPomarBase);
                                    polinizacaoPomar.polinizacao = lancamentoPolinizacaoPomar;
                                    polinizacaoPomar.indicePolinizacao = keyLancamentoPolinizacaoPomar;
                                    if ((typeof polinizacaoPomar.id !== 'undefined') && polinizacaoPomar.id && (keyLancamentoPolinizacaoPomar !== null)) {
                                        if (!lista.find(o => (o.id === polinizacaoPomar.id) && (o.indicePolinizacao === keyLancamentoPolinizacaoPomar))) {
                                            lista.push(polinizacaoPomar);
                                        }
                                    }
                                    else {
                                        lista.push(polinizacaoPomar);
                                    }
                                }
                            });
                        }
                    });
                }
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'acaoEditar',
                    displayName: 'Ação',
                    width: 70,
                    enableFiltering: false,
                    enableSorting: false,
                    cellTemplate: `
                        <div class="btn-group btn-ui-grid">
                            <button type="button" class="btn btn-icon"
                                ng-click="grid.appScope.getParentController().editarLancamentoEucalyptusPolinizacaoPomar(row.entity.id || row.entity.uuid, row.entity.indicePolinizacao)">
                                <i class="bx bxs-edit-alt"></i> Editar
                            </button>
                        </div>`
                });
                auxColunas.push({
                    name: 'materialGeneticoFeminino.descricao',
                    displayName: 'Mat. Genético',
                    width: 114
                });
                auxColunas.push({
                    name: 'polinizacao.data',
                    displayName: 'Data Polinização',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 130
                });
                auxColunas.push({
                    name: 'polinizacao.numGalhos',
                    displayName: 'Nº de Galhos',
                    width: 110
                });
                auxColunas.push({
                    name: 'polinizacao.numBotoes',
                    displayName: 'Nº de Botões',
                    width: 110
                });
                auxColunas.push({
                    name: 'polinizacao.pomarOrigem.descricao',
                    displayName: 'Pomar Origem Pólen',
                    width: 150
                });
                auxColunas.push({
                    name: 'polinizacao.materialGeneticoGenitor.descricao',
                    displayName: 'Genitor',
                    width: 90
                });
                auxColunas.push({
                    name: 'polinizacao.hibridacao.descricao',
                    displayName: 'Hibridação',
                    width: 98
                });
                auxColunas.push({
                    name: 'polinizacao.hibrido',
                    displayName: 'Híbrido',
                    width: 110
                });
                auxColunas.push({
                    name: 'polinizacao.cruzamento',
                    displayName: 'Cruzamento',
                    width: 120
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            return null;
        });
    }
    static dadosListaColetasConesSementesPolinizacaoPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.pomarSelecionado || !ctrl.pomarSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let lista = new Array();
                if ((typeof ctrl.listaOpcoesPolinizacaoPomar !== 'undefined') && ctrl.listaOpcoesPolinizacaoPomar &&
                    (typeof ctrl.listaOpcoesPolinizacaoPomar.data !== 'undefined') && ctrl.listaOpcoesPolinizacaoPomar.data) {
                    angular.forEach(ctrl.listaOpcoesPolinizacaoPomar.data, (polinizacaoPomarBase) => {
                        let monitoramentoFloracaoBase = null;
                        let numPacotesBase = 0;
                        let numEstrobilosFemininosConesBase = 0;
                        if ((typeof polinizacaoPomarBase.monitoramentosFloracao !== 'undefined') && polinizacaoPomarBase.monitoramentosFloracao && (polinizacaoPomarBase.monitoramentosFloracao.length > 0)) {
                            angular.forEach(polinizacaoPomarBase.monitoramentosFloracao, (monitoramentoFloracao) => {
                                if ((typeof monitoramentoFloracao.estrobilo !== 'undefined') && monitoramentoFloracao.estrobilo &&
                                    (typeof monitoramentoFloracao.estrobilo.id !== 'undefined') && monitoramentoFloracao.estrobilo.id) {
                                    if (ctrl.estrobiloFeminino && ctrl.estrobiloFeminino.id && (monitoramentoFloracao.estrobilo.id === ctrl.estrobiloFeminino.id)) {
                                        monitoramentoFloracaoBase = monitoramentoFloracao;
                                        if ((typeof monitoramentoFloracao.numPacotes !== 'undefined') && monitoramentoFloracao.numPacotes) {
                                            numPacotesBase += monitoramentoFloracao.numPacotes;
                                        }
                                        if ((typeof monitoramentoFloracao.numEstrobilosFemininosCones !== 'undefined') && monitoramentoFloracao.numEstrobilosFemininosCones) {
                                            numEstrobilosFemininosConesBase += monitoramentoFloracao.numEstrobilosFemininosCones;
                                        }
                                    }
                                }
                            });
                        }
                        let lancamentoPolinizacaoBase = null;
                        let cruzamentoPolinizacaoBase = null;
                        if ((typeof polinizacaoPomarBase.polinizacoes !== 'undefined') && polinizacaoPomarBase.polinizacoes && (polinizacaoPomarBase.polinizacoes.length > 0)) {
                            angular.forEach(polinizacaoPomarBase.polinizacoes, (lancamentoPolinizacao) => {
                                if ((typeof lancamentoPolinizacao.ehPinus !== 'undefined') && lancamentoPolinizacao.ehPinus &&
                                    (((typeof lancamentoPolinizacao.dataInicial !== 'undefined') && lancamentoPolinizacao.dataInicial) ||
                                        ((typeof lancamentoPolinizacao.dataFinal !== 'undefined') && lancamentoPolinizacao.dataFinal) ||
                                        ((typeof lancamentoPolinizacao.dataRetiradaPacote !== 'undefined') && lancamentoPolinizacao.dataRetiradaPacote) ||
                                        ((typeof lancamentoPolinizacao.polenAno !== 'undefined') && lancamentoPolinizacao.polenAno))) {
                                    lancamentoPolinizacaoBase = lancamentoPolinizacao;
                                }
                                else if ((typeof lancamentoPolinizacao.ehEucalyptus !== 'undefined') && lancamentoPolinizacao.ehEucalyptus &&
                                    (((typeof lancamentoPolinizacao.data !== 'undefined') && lancamentoPolinizacao.data) ||
                                        ((typeof lancamentoPolinizacao.pomarOrigem !== 'undefined') && lancamentoPolinizacao.pomarOrigem) ||
                                        ((typeof lancamentoPolinizacao.materialGeneticoGenitor !== 'undefined') && lancamentoPolinizacao.materialGeneticoGenitor) ||
                                        ((typeof lancamentoPolinizacao.hibridacao !== 'undefined') && lancamentoPolinizacao.hibridacao))) {
                                    lancamentoPolinizacaoBase = lancamentoPolinizacao;
                                }
                                if ((typeof lancamentoPolinizacao.cruzamento !== 'undefined') && lancamentoPolinizacao.cruzamento && (lancamentoPolinizacao.cruzamento.length > 0)) {
                                    cruzamentoPolinizacaoBase = lancamentoPolinizacao.cruzamento;
                                }
                            });
                        }
                        if ((typeof polinizacaoPomarBase.coletasConesSementes !== 'undefined') && polinizacaoPomarBase.coletasConesSementes && (polinizacaoPomarBase.coletasConesSementes.length > 0)) {
                            angular.forEach(polinizacaoPomarBase.coletasConesSementes, (coletaConesSementesPolinizacaoPomar, keyColetaConesSementesPolinizacaoPomar) => {
                                let polinizacaoPomar = angular.copy(polinizacaoPomarBase);
                                polinizacaoPomar.coletaConesSementes = coletaConesSementesPolinizacaoPomar;
                                polinizacaoPomar.indiceColetaConesSementes = keyColetaConesSementesPolinizacaoPomar;
                                polinizacaoPomar.monitoramentoFloracao = monitoramentoFloracaoBase;
                                polinizacaoPomar.numPacotes = numPacotesBase;
                                polinizacaoPomar.numEstrobilosFemininosCones = numEstrobilosFemininosConesBase;
                                polinizacaoPomar.polinizacao = lancamentoPolinizacaoBase;
                                if ((typeof polinizacaoPomar.cruzamentoDescricao === 'undefined') || !polinizacaoPomar.cruzamentoDescricao || (polinizacaoPomar.cruzamentoDescricao.length === 0)) {
                                    polinizacaoPomar.cruzamentoDescricao = cruzamentoPolinizacaoBase;
                                }
                                if ((typeof polinizacaoPomar.id !== 'undefined') && polinizacaoPomar.id && (keyColetaConesSementesPolinizacaoPomar !== null)) {
                                    if (!lista.find(o => (o.id === polinizacaoPomar.id) && (o.indiceColetaConesSementes === keyColetaConesSementesPolinizacaoPomar))) {
                                        lista.push(polinizacaoPomar);
                                    }
                                }
                                else {
                                    lista.push(polinizacaoPomar);
                                }
                            });
                        }
                    });
                }
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'acaoEditar',
                    displayName: 'Ação',
                    width: 70,
                    enableFiltering: false,
                    enableSorting: false,
                    cellTemplate: `
                        <div class="btn-group btn-ui-grid">
                            <button type="button" class="btn btn-icon"
                                ng-click="grid.appScope.getParentController().editarColetaConesSementesPolinizacaoPomar(row.entity.id || row.entity.uuid, row.entity.indiceColetaConesSementes)">
                                <i class="bx bxs-edit-alt"></i> Editar
                            </button>
                        </div>`
                });
                auxColunas.push({
                    name: 'materialGeneticoFeminino.descricao',
                    displayName: 'Mat. Genético Feminino',
                    width: 170
                });
                auxColunas.push({
                    name: 'monitoramentoFloracao.fila',
                    displayName: 'Fila',
                    width: 80
                });
                auxColunas.push({
                    name: 'monitoramentoFloracao.coluna',
                    displayName: 'Coluna',
                    width: 90
                });
                auxColunas.push({
                    name: 'materialGeneticoMasculino.descricao',
                    displayName: 'Mat. Genético Masculino',
                    width: 178
                });
                auxColunas.push({
                    name: 'cruzamentoDescricao',
                    displayName: 'Cruzamento',
                    width: 110
                });
                auxColunas.push({
                    name: 'numPacotes',
                    displayName: 'Nº Pacotes',
                    width: 120
                });
                auxColunas.push({
                    name: 'numEstrobilosFemininosCones',
                    displayName: 'Nº Estróbilos femininos/cones',
                    width: 210
                });
                auxColunas.push({
                    name: 'polinizacao.dataInicial',
                    displayName: 'Data Inicial',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 130
                });
                auxColunas.push({
                    name: 'polinizacao.dataFinal',
                    displayName: 'Data Final',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 130
                });
                auxColunas.push({
                    name: 'polinizacao.dataRetiradaPacote',
                    displayName: 'Data R. Pacote',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 130
                });
                auxColunas.push({
                    name: 'coletaConesSementes.data',
                    displayName: 'Data Coleta Cone',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 140
                });
                auxColunas.push({
                    name: 'coletaConesSementes.numConesColetados',
                    displayName: 'Nº Cones Coletados',
                    width: 160
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            return null;
        });
    }
    static dadosListaBeneficiamentosPinusPolinizacaoPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.pomarSelecionado || !ctrl.pomarSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let lista = new Array();
                if ((typeof ctrl.listaOpcoesPolinizacaoPomar !== 'undefined') && ctrl.listaOpcoesPolinizacaoPomar &&
                    (typeof ctrl.listaOpcoesPolinizacaoPomar.data !== 'undefined') && ctrl.listaOpcoesPolinizacaoPomar.data) {
                    angular.forEach(ctrl.listaOpcoesPolinizacaoPomar.data, (polinizacaoPomarBase) => {
                        let monitoramentoFloracaoBase = null;
                        let numPacotesBase = 0;
                        let numEstrobilosFemininosConesBase = 0;
                        let dataIsolamentoBase = null;
                        if ((typeof polinizacaoPomarBase.monitoramentosFloracao !== 'undefined') && polinizacaoPomarBase.monitoramentosFloracao && (polinizacaoPomarBase.monitoramentosFloracao.length > 0)) {
                            angular.forEach(polinizacaoPomarBase.monitoramentosFloracao, (monitoramentoFloracao) => {
                                if ((typeof monitoramentoFloracao.estrobilo !== 'undefined') && monitoramentoFloracao.estrobilo &&
                                    (typeof monitoramentoFloracao.estrobilo.id !== 'undefined') && monitoramentoFloracao.estrobilo.id) {
                                    if (ctrl.estrobiloFeminino && ctrl.estrobiloFeminino.id && (monitoramentoFloracao.estrobilo.id === ctrl.estrobiloFeminino.id)) {
                                        monitoramentoFloracaoBase = monitoramentoFloracao;
                                        if ((typeof monitoramentoFloracao.numPacotes !== 'undefined') && monitoramentoFloracao.numPacotes) {
                                            numPacotesBase += monitoramentoFloracao.numPacotes;
                                        }
                                        if ((typeof monitoramentoFloracao.numEstrobilosFemininosCones !== 'undefined') && monitoramentoFloracao.numEstrobilosFemininosCones) {
                                            numEstrobilosFemininosConesBase += monitoramentoFloracao.numEstrobilosFemininosCones;
                                        }
                                        if ((typeof monitoramentoFloracao.dataIsolamento !== 'undefined') && monitoramentoFloracao.dataIsolamento) {
                                            dataIsolamentoBase = monitoramentoFloracao.dataIsolamento;
                                        }
                                    }
                                }
                            });
                        }
                        let lancamentoPolinizacaoBase = null;
                        let cruzamentoPolinizacaoBase = null;
                        if ((typeof polinizacaoPomarBase.polinizacoes !== 'undefined') && polinizacaoPomarBase.polinizacoes && (polinizacaoPomarBase.polinizacoes.length > 0)) {
                            angular.forEach(polinizacaoPomarBase.polinizacoes, (lancamentoPolinizacao) => {
                                if ((typeof lancamentoPolinizacao.ehPinus !== 'undefined') && lancamentoPolinizacao.ehPinus) {
                                    lancamentoPolinizacaoBase = lancamentoPolinizacao;
                                }
                                if ((typeof lancamentoPolinizacao.cruzamento !== 'undefined') && lancamentoPolinizacao.cruzamento && (lancamentoPolinizacao.cruzamento.length > 0)) {
                                    cruzamentoPolinizacaoBase = lancamentoPolinizacao.cruzamento;
                                }
                            });
                        }
                        let coletaConesSementesBase = null;
                        if ((typeof polinizacaoPomarBase.coletasConesSementes !== 'undefined') && polinizacaoPomarBase.coletasConesSementes && (polinizacaoPomarBase.coletasConesSementes.length > 0)) {
                            angular.forEach(polinizacaoPomarBase.coletasConesSementes, (coletaConesSementes) => {
                                if (((typeof coletaConesSementes.data !== 'undefined') && coletaConesSementes.data) ||
                                    ((typeof coletaConesSementes.numConesColetados !== 'undefined') && coletaConesSementes.numConesColetados)) {
                                    coletaConesSementesBase = coletaConesSementes;
                                }
                            });
                        }
                        if ((typeof polinizacaoPomarBase.beneficiamentos !== 'undefined') && polinizacaoPomarBase.beneficiamentos && (polinizacaoPomarBase.beneficiamentos.length > 0)) {
                            angular.forEach(polinizacaoPomarBase.beneficiamentos, (beneficiamentoPolinizacaoPomar, keyBeneficiamentoPolinizacaoPomar) => {
                                if ((typeof beneficiamentoPolinizacaoPomar.ehPinus !== 'undefined') && beneficiamentoPolinizacaoPomar.ehPinus) {
                                    let polinizacaoPomar = angular.copy(polinizacaoPomarBase);
                                    polinizacaoPomar.beneficiamento = beneficiamentoPolinizacaoPomar;
                                    polinizacaoPomar.indiceBeneficiamento = keyBeneficiamentoPolinizacaoPomar;
                                    polinizacaoPomar.monitoramentoFloracao = monitoramentoFloracaoBase;
                                    polinizacaoPomar.numPacotes = numPacotesBase;
                                    polinizacaoPomar.numEstrobilosFemininosCones = numEstrobilosFemininosConesBase;
                                    polinizacaoPomar.dataIsolamento = dataIsolamentoBase;
                                    polinizacaoPomar.polinizacao = lancamentoPolinizacaoBase;
                                    if ((typeof polinizacaoPomar.cruzamentoDescricao === 'undefined') || !polinizacaoPomar.cruzamentoDescricao || (polinizacaoPomar.cruzamentoDescricao.length === 0)) {
                                        polinizacaoPomar.cruzamentoDescricao = cruzamentoPolinizacaoBase;
                                    }
                                    polinizacaoPomar.coletaConesSementes = coletaConesSementesBase;
                                    if ((typeof polinizacaoPomar.id !== 'undefined') && polinizacaoPomar.id && (keyBeneficiamentoPolinizacaoPomar !== null)) {
                                        if (!lista.find(o => (o.id === polinizacaoPomar.id) && (o.indiceBeneficiamento === keyBeneficiamentoPolinizacaoPomar))) {
                                            lista.push(polinizacaoPomar);
                                        }
                                    }
                                    else {
                                        lista.push(polinizacaoPomar);
                                    }
                                }
                            });
                        }
                    });
                }
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'acaoEditar',
                    displayName: 'Ação',
                    width: 70,
                    enableFiltering: false,
                    enableSorting: false,
                    cellTemplate: `
                        <div class="btn-group btn-ui-grid">
                            <button type="button" class="btn btn-icon"
                                ng-click="grid.appScope.getParentController().editarBeneficiamentoPinusPolinizacaoPomar(row.entity.id || row.entity.uuid, row.entity.indiceBeneficiamento)">
                                <i class="bx bxs-edit-alt"></i> Editar
                            </button>
                        </div>`
                });
                auxColunas.push({
                    name: 'materialGeneticoFeminino.descricao',
                    displayName: 'Mat. Genético Feminino',
                    width: 170
                });
                auxColunas.push({
                    name: 'monitoramentoFloracao.fila',
                    displayName: 'Fila',
                    width: 80
                });
                auxColunas.push({
                    name: 'monitoramentoFloracao.coluna',
                    displayName: 'Coluna',
                    width: 90
                });
                auxColunas.push({
                    name: 'materialGeneticoMasculino.descricao',
                    displayName: 'Mat. Genético Masculino',
                    width: 174
                });
                auxColunas.push({
                    name: 'cruzamentoDescricao',
                    displayName: 'Cruzamento',
                    width: 110,
                    cellTemplate: `
                    <div class="ui-grid-cell-contents">
                        {{row.entity.materialGeneticoFeminino.descricao}} x {{row.entity.materialGeneticoMasculino.descricao}}
                    </div>
                `
                });
                auxColunas.push({
                    name: 'numPacotes',
                    displayName: 'Nº Pacotes',
                    width: 120
                });
                auxColunas.push({
                    name: 'numEstrobilosFemininosCones',
                    displayName: 'Nº Estróbilos femininos/cones',
                    width: 200
                });
                auxColunas.push({
                    name: 'dataIsolamento',
                    displayName: 'Data Isolamento',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 130
                });
                auxColunas.push({
                    name: 'polinizacao.dataInicial',
                    displayName: 'Data Inicial',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 130
                });
                auxColunas.push({
                    name: 'polinizacao.dataFinal',
                    displayName: 'Data Final',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 130
                });
                auxColunas.push({
                    name: 'polinizacao.dataRetiradaPacote',
                    displayName: 'Data R. Pacote',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 130
                });
                auxColunas.push({
                    name: 'coletaConesSementes.data',
                    displayName: 'Data Coleta Cone',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 140
                });
                auxColunas.push({
                    name: 'coletaConesSementes.numConesColetados',
                    displayName: 'Nº Cones Coletados',
                    width: 160
                });
                auxColunas.push({
                    name: 'beneficiamento.data',
                    displayName: 'Data Beneficiamento',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 152
                });
                auxColunas.push({
                    name: 'beneficiamento.numSementesTotal',
                    displayName: 'Nº Sementes Total',
                    width: 160
                });
                auxColunas.push({
                    name: 'beneficiamento.numSementesBoas',
                    displayName: 'Nº Sementes Boas',
                    width: 160
                });
                auxColunas.push({
                    name: 'beneficiamento.numSementesCone',
                    displayName: 'Nº Sementes Cone',
                    width: 160
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            return null;
        });
    }
    static dadosListaEstoqueBeneficiamentoPinusPolinizacaoPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.pomarSelecionado || !ctrl.pomarSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let lista = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESTOQUE_BENEFICIAMENTO_PINUS_POLINIZACAO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESTOQUE_BENEFICIAMENTO_PINUS_POLINIZACAO_POMAR)
                    .filtro({
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    pomar: ctrl.pomarSelecionado.id
                })
                    .listar();
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'materialGeneticoFeminino.descricao',
                    displayName: 'Mat. Genético Feminino',
                    width: 170
                });
                auxColunas.push({
                    name: 'pomarOrigemPolen.descricao',
                    displayName: 'Pomar Origem Pólen',
                    width: 150
                });
                auxColunas.push({
                    name: 'materialGeneticoMasculino.descricao',
                    displayName: 'Mat. Genético Masculino',
                    width: 174
                });
                auxColunas.push({
                    name: 'quantidadeAtual',
                    displayName: 'Quant. Atual',
                    width: 104
                });
                auxColunas.push({
                    name: 'acaoEditar',
                    displayName: 'Ação',
                    width: 90,
                    cellTemplate: `
                        <div class="btn-group btn-ui-grid">
                            <button type="button" class="btn btn-icon"
                                ng-click="grid.appScope.getParentController().editarEstoqueBeneficiamentoPinusPolinizacaoPomar(row.entity.id || row.entity.uuid)">
                                <i class="bx bxs-edit-alt"></i> Editar
                            </button>
                        </div>`
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            return null;
        });
    }
    static dadosListaHistoricoEstoqueBeneficiamentoPinusPolinizacaoPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.pomarSelecionado || !ctrl.pomarSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let lista = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.HISTORICO_ESTOQUE_BENEFICIAMENTO_PINUS_POLINIZACAO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.HISTORICO_ESTOQUE_BENEFICIAMENTO_PINUS_POLINIZACAO_POMAR)
                    .filtro({
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    pomar: ctrl.pomarSelecionado.id
                })
                    .listar();
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'criacao',
                    displayName: 'Data/Hora',
                    cellFilter: 'date:"dd/MM/yyyy HH:mm:ss"',
                    width: 170
                });
                auxColunas.push({
                    name: 'estoqueBeneficiamentoPinusPolinizacaoPomar.materialGeneticoFeminino.descricao',
                    displayName: 'Mat. Genético Feminino',
                    width: 170
                });
                auxColunas.push({
                    name: 'estoqueBeneficiamentoPinusPolinizacaoPomar.pomarOrigemPolen.descricao',
                    displayName: 'Pomar Origem Pólen',
                    width: 150
                });
                auxColunas.push({
                    name: 'estoqueBeneficiamentoPinusPolinizacaoPomar.materialGeneticoMasculino.descricao',
                    displayName: 'Mat. Genético Masculino',
                    width: 174
                });
                auxColunas.push({
                    name: 'movimento',
                    displayName: 'Movimentação',
                    width: 120
                });
                auxColunas.push({
                    name: 'quantidadeAnterior',
                    displayName: 'Quant. Anterior',
                    width: 130
                });
                auxColunas.push({
                    name: 'quantidadeMovimento',
                    displayName: 'Quant. Movimento',
                    width: 140
                });
                auxColunas.push({
                    name: 'quantidadeAtual',
                    displayName: 'Quant. Atual',
                    width: 120
                });
                auxColunas.push({
                    name: 'usuario.nomeCompleto',
                    displayName: 'Usuário',
                    width: 160
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            return null;
        });
    }
    static dadosListaBeneficiamentosEucalyptusPolinizacaoPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.pomarSelecionado || !ctrl.pomarSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let lista = new Array();
                if ((typeof ctrl.listaOpcoesPolinizacaoPomar !== 'undefined') && ctrl.listaOpcoesPolinizacaoPomar &&
                    (typeof ctrl.listaOpcoesPolinizacaoPomar.data !== 'undefined') && ctrl.listaOpcoesPolinizacaoPomar.data) {
                    angular.forEach(ctrl.listaOpcoesPolinizacaoPomar.data, (polinizacaoPomarBase) => {
                        let monitoramentoFloracaoBase = null;
                        let numPacotesBase = 0;
                        let numEstrobilosFemininosConesBase = 0;
                        let dataIsolamentoBase = null;
                        if ((typeof polinizacaoPomarBase.monitoramentosFloracao !== 'undefined') && polinizacaoPomarBase.monitoramentosFloracao && (polinizacaoPomarBase.monitoramentosFloracao.length > 0)) {
                            angular.forEach(polinizacaoPomarBase.monitoramentosFloracao, (monitoramentoFloracao) => {
                                if ((typeof monitoramentoFloracao.estrobilo !== 'undefined') && monitoramentoFloracao.estrobilo &&
                                    (typeof monitoramentoFloracao.estrobilo.id !== 'undefined') && monitoramentoFloracao.estrobilo.id) {
                                    if (ctrl.estrobiloFeminino && ctrl.estrobiloFeminino.id && (monitoramentoFloracao.estrobilo.id === ctrl.estrobiloFeminino.id)) {
                                        monitoramentoFloracaoBase = monitoramentoFloracao;
                                        if ((typeof monitoramentoFloracao.numPacotes !== 'undefined') && monitoramentoFloracao.numPacotes) {
                                            numPacotesBase += monitoramentoFloracao.numPacotes;
                                        }
                                        if ((typeof monitoramentoFloracao.numEstrobilosFemininosCones !== 'undefined') && monitoramentoFloracao.numEstrobilosFemininosCones) {
                                            numEstrobilosFemininosConesBase += monitoramentoFloracao.numEstrobilosFemininosCones;
                                        }
                                        if ((typeof monitoramentoFloracao.dataIsolamento !== 'undefined') && monitoramentoFloracao.dataIsolamento) {
                                            dataIsolamentoBase = monitoramentoFloracao.dataIsolamento;
                                        }
                                    }
                                }
                            });
                        }
                        let lancamentoPolinizacaoBase = null;
                        let cruzamentoPolinizacaoBase = null;
                        if ((typeof polinizacaoPomarBase.polinizacoes !== 'undefined') && polinizacaoPomarBase.polinizacoes && (polinizacaoPomarBase.polinizacoes.length > 0)) {
                            angular.forEach(polinizacaoPomarBase.polinizacoes, (lancamentoPolinizacao) => {
                                if ((typeof lancamentoPolinizacao.ehPinus !== 'undefined') && lancamentoPolinizacao.ehPinus) {
                                    lancamentoPolinizacaoBase = lancamentoPolinizacao;
                                }
                                if ((typeof lancamentoPolinizacao.cruzamento !== 'undefined') && lancamentoPolinizacao.cruzamento && (lancamentoPolinizacao.cruzamento.length > 0)) {
                                    cruzamentoPolinizacaoBase = lancamentoPolinizacao.cruzamento;
                                }
                            });
                        }
                        let coletaConesSementesBase = null;
                        if ((typeof polinizacaoPomarBase.coletasConesSementes !== 'undefined') && polinizacaoPomarBase.coletasConesSementes && (polinizacaoPomarBase.coletasConesSementes.length > 0)) {
                            angular.forEach(polinizacaoPomarBase.coletasConesSementes, (coletaConesSementes) => {
                                if (((typeof coletaConesSementes.data !== 'undefined') && coletaConesSementes.data) ||
                                    ((typeof coletaConesSementes.numConesColetados !== 'undefined') && coletaConesSementes.numConesColetados)) {
                                    coletaConesSementesBase = coletaConesSementes;
                                }
                            });
                        }
                        if ((typeof polinizacaoPomarBase.beneficiamentos !== 'undefined') && polinizacaoPomarBase.beneficiamentos && (polinizacaoPomarBase.beneficiamentos.length > 0)) {
                            angular.forEach(polinizacaoPomarBase.beneficiamentos, (beneficiamentoPolinizacaoPomar, keyBeneficiamentoPolinizacaoPomar) => {
                                if ((typeof beneficiamentoPolinizacaoPomar.ehEucalyptus !== 'undefined') && beneficiamentoPolinizacaoPomar.ehEucalyptus) {
                                    let polinizacaoPomar = angular.copy(polinizacaoPomarBase);
                                    polinizacaoPomar.beneficiamento = beneficiamentoPolinizacaoPomar;
                                    polinizacaoPomar.indiceBeneficiamento = keyBeneficiamentoPolinizacaoPomar;
                                    polinizacaoPomar.monitoramentoFloracao = monitoramentoFloracaoBase;
                                    polinizacaoPomar.numPacotes = numPacotesBase;
                                    polinizacaoPomar.numEstrobilosFemininosCones = numEstrobilosFemininosConesBase;
                                    polinizacaoPomar.dataIsolamento = dataIsolamentoBase;
                                    polinizacaoPomar.polinizacao = lancamentoPolinizacaoBase;
                                    if ((typeof polinizacaoPomar.cruzamentoDescricao === 'undefined') || !polinizacaoPomar.cruzamentoDescricao || (polinizacaoPomar.cruzamentoDescricao.length === 0)) {
                                        polinizacaoPomar.cruzamentoDescricao = cruzamentoPolinizacaoBase;
                                    }
                                    polinizacaoPomar.coletaConesSementes = coletaConesSementesBase;
                                    if ((typeof polinizacaoPomar.id !== 'undefined') && polinizacaoPomar.id && (keyBeneficiamentoPolinizacaoPomar !== null)) {
                                        if (!lista.find(o => (o.id === polinizacaoPomar.id) && (o.indiceBeneficiamento === keyBeneficiamentoPolinizacaoPomar))) {
                                            lista.push(polinizacaoPomar);
                                        }
                                    }
                                    else {
                                        lista.push(polinizacaoPomar);
                                    }
                                }
                            });
                        }
                    });
                }
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'acaoEditar',
                    displayName: 'Ação',
                    width: 70,
                    enableFiltering: false,
                    enableSorting: false,
                    cellTemplate: `
                        <div class="btn-group btn-ui-grid">
                            <button type="button" class="btn btn-icon"
                                ng-click="grid.appScope.getParentController().editarBeneficiamentoEucalyptusPolinizacaoPomar(row.entity.id || row.entity.uuid, row.entity.indiceBeneficiamento)">
                                <i class="bx bxs-edit-alt"></i> Editar
                            </button>
                        </div>`
                });
                auxColunas.push({
                    name: 'materialGeneticoFeminino.descricao',
                    displayName: 'Mat. Genético Feminino',
                    width: 174
                });
                auxColunas.push({
                    name: 'monitoramentoFloracao.fila',
                    displayName: 'Fila',
                    width: 80
                });
                auxColunas.push({
                    name: 'monitoramentoFloracao.coluna',
                    displayName: 'Coluna',
                    width: 90
                });
                auxColunas.push({
                    name: 'materialGeneticoMasculino.descricao',
                    displayName: 'Mat. Genético Masculino',
                    width: 174
                });
                auxColunas.push({
                    name: 'cruzamentoDescricao',
                    displayName: 'Cruzamento',
                    width: 110
                });
                auxColunas.push({
                    name: 'numPacotes',
                    displayName: 'Nº Pacotes',
                    width: 120
                });
                auxColunas.push({
                    name: 'numEstrobilosFemininosCones',
                    displayName: 'Nº Estróbilos femininos/cones',
                    width: 200
                });
                auxColunas.push({
                    name: 'dataIsolamento',
                    displayName: 'Data Isolamento',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 130
                });
                auxColunas.push({
                    name: 'polinizacao.dataInicial',
                    displayName: 'Data Inicial',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 130
                });
                auxColunas.push({
                    name: 'polinizacao.dataFinal',
                    displayName: 'Data Final',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 130
                });
                auxColunas.push({
                    name: 'polinizacao.dataRetiradaPacote',
                    displayName: 'Data R. Pacote',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 130
                });
                auxColunas.push({
                    name: 'coletaConesSementes.data',
                    displayName: 'Data Coleta Cone',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 140
                });
                auxColunas.push({
                    name: 'coletaConesSementes.numConesColetados',
                    displayName: 'Nº Cones Coletados',
                    width: 160
                });
                auxColunas.push({
                    name: 'beneficiamento.data',
                    displayName: 'Data Beneficiamento',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 152
                });
                auxColunas.push({
                    name: 'beneficiamento.quantidade',
                    displayName: 'Quantidade (g)',
                    width: 150
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            return null;
        });
    }
    static dadosListaEstoqueBeneficiamentoEucalyptusPolinizacaoPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.pomarSelecionado || !ctrl.pomarSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let lista = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESTOQUE_BENEFICIAMENTO_EUCALYPTUS_POLINIZACAO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESTOQUE_BENEFICIAMENTO_EUCALYPTUS_POLINIZACAO_POMAR)
                    .filtro({
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    pomar: ctrl.pomarSelecionado.id
                })
                    .listar();
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'materialGeneticoFeminino.descricao',
                    displayName: 'Mat. Genético Feminino',
                    width: 170
                });
                auxColunas.push({
                    name: 'pomarOrigemPolen.descricao',
                    displayName: 'Pomar Origem Pólen',
                    width: 150
                });
                auxColunas.push({
                    name: 'materialGeneticoMasculino.descricao',
                    displayName: 'Mat. Genético Masculino',
                    width: 174
                });
                auxColunas.push({
                    name: 'quantidadeAtual',
                    displayName: 'Quant. Atual',
                    width: 104
                });
                auxColunas.push({
                    name: 'acaoEditar',
                    displayName: 'Ação',
                    width: 90,
                    cellTemplate: `
                        <div class="btn-group btn-ui-grid">
                            <button type="button" class="btn btn-icon"
                                ng-click="grid.appScope.getParentController().editarEstoqueBeneficiamentoEucalyptusPolinizacaoPomar(row.entity.id || row.entity.uuid)">
                                <i class="bx bxs-edit-alt"></i> Editar
                            </button>
                        </div>`
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            return null;
        });
    }
    static dadosListaHistoricoEstoqueBeneficiamentoEucalyptusPolinizacaoPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.pomarSelecionado || !ctrl.pomarSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let lista = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.HISTORICO_ESTOQUE_BENEFICIAMENTO_EUCALYPTUS_POLINIZACAO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.HISTORICO_ESTOQUE_BENEFICIAMENTO_EUCALYPTUS_POLINIZACAO_POMAR)
                    .filtro({
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    pomar: ctrl.pomarSelecionado.id
                })
                    .listar();
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'criacao',
                    displayName: 'Data/Hora',
                    cellFilter: 'date:"dd/MM/yyyy HH:mm:ss"',
                    width: 170
                });
                auxColunas.push({
                    name: 'estoqueBeneficiamentoEucalyptusPolinizacaoPomar.materialGeneticoFeminino.descricao',
                    displayName: 'Mat. Genético Feminino',
                    width: 170
                });
                auxColunas.push({
                    name: 'estoqueBeneficiamentoEucalyptusPolinizacaoPomar.pomarOrigemPolen.descricao',
                    displayName: 'Pomar Origem Pólen',
                    width: 150
                });
                auxColunas.push({
                    name: 'estoqueBeneficiamentoEucalyptusPolinizacaoPomar.materialGeneticoMasculino.descricao',
                    displayName: 'Mat. Genético Masculino',
                    width: 174
                });
                auxColunas.push({
                    name: 'movimento',
                    displayName: 'Movimentação',
                    width: 120
                });
                auxColunas.push({
                    name: 'quantidadeAnterior',
                    displayName: 'Quant. Anterior',
                    width: 130
                });
                auxColunas.push({
                    name: 'quantidadeMovimento',
                    displayName: 'Quant. Movimento',
                    width: 140
                });
                auxColunas.push({
                    name: 'quantidadeAtual',
                    displayName: 'Quant. Atual',
                    width: 120
                });
                auxColunas.push({
                    name: 'usuario.nomeCompleto',
                    displayName: 'Usuário',
                    width: 160
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            return null;
        });
    }
    static dadosListaRelatorioPolinizacaoPinusPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.pomarSelecionado || !ctrl.pomarSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let lista = new Array();
                if ((typeof ctrl.listaOpcoesPolinizacaoPomar !== 'undefined') && ctrl.listaOpcoesPolinizacaoPomar &&
                    (typeof ctrl.listaOpcoesPolinizacaoPomar.data !== 'undefined') && ctrl.listaOpcoesPolinizacaoPomar.data) {
                    angular.forEach(ctrl.listaOpcoesPolinizacaoPomar.data, (_polinizacaoPomarBase) => {
                    });
                }
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'pomar.descricao',
                    displayName: 'Pomar',
                    width: 160
                });
                auxColunas.push({
                    name: 'materialGeneticoFeminino.descricao',
                    displayName: 'Genitora',
                    width: 100
                });
                auxColunas.push({
                    name: 'materialGeneticoMasculino.descricao',
                    displayName: 'Genitor',
                    width: 100
                });
                auxColunas.push({
                    name: 'cruzamento',
                    displayName: 'Cruzamento',
                    width: 110
                });
                auxColunas.push({
                    name: 'realizado',
                    displayName: 'Realizado',
                    width: 90
                });
                auxColunas.push({
                    name: 'numIsolamentos',
                    displayName: 'Nº de Isolamentos',
                    width: 140
                });
                auxColunas.push({
                    name: 'coletaPolen',
                    displayName: 'Coleta Pólen',
                    width: 110
                });
                auxColunas.push({
                    name: 'quantidadePolen',
                    displayName: 'Quantidade Pólen',
                    width: 140
                });
                auxColunas.push({
                    name: 'coleta',
                    displayName: 'Coleta',
                    width: 90
                });
                auxColunas.push({
                    name: 'numCones',
                    displayName: 'Nº de Cones',
                    width: 110
                });
                auxColunas.push({
                    name: 'numSementes',
                    displayName: 'Nº de Sementes',
                    width: 130
                });
                auxColunas.push({
                    name: 'numSementesPorCone',
                    displayName: 'Nº Sementes por Cone',
                    width: 172
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            return null;
        });
    }
    static dadosListaRelatorioPolinizacaoEucalyptusPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.pomarSelecionado || !ctrl.pomarSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let lista = new Array();
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'pomar.descricao',
                    displayName: 'Pomar',
                    width: 160
                });
                auxColunas.push({
                    name: 'materialGeneticoFeminino.descricao',
                    displayName: 'Genitora',
                    width: 100
                });
                auxColunas.push({
                    name: 'materialGeneticoMasculino.descricao',
                    displayName: 'Genitor',
                    width: 100
                });
                auxColunas.push({
                    name: 'cruzamento',
                    displayName: 'Cruzamento',
                    width: 110
                });
                auxColunas.push({
                    name: 'realizado',
                    displayName: 'Realizado',
                    width: 120
                });
                auxColunas.push({
                    name: 'numIsolamentos',
                    displayName: 'Nº de Isolamentos',
                    width: 150
                });
                auxColunas.push({
                    name: 'coletaPolen',
                    displayName: 'Coleta Pólen',
                    width: 110
                });
                auxColunas.push({
                    name: 'coletaSementes',
                    displayName: 'Coleta Sementes',
                    width: 130
                });
                auxColunas.push({
                    name: 'quantidadeSementes',
                    displayName: 'Quantidade Sementes',
                    width: 150
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            return null;
        });
    }
    static dadosListaTratamentosPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.pomarSelecionado || !ctrl.pomarSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let filtro = {
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    pomarId: ctrl.pomarSelecionado.id,
                    dadosDetalhadosExportacao: true,
                    limiteRegistros: 100000
                };
                let lista = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.APONTAMENTO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.APONTAMENTO)
                    .filtro(filtro)
                    .listar();
                let auxColunas = new Array();
                if ((typeof lista !== 'undefined') && (lista !== null) && (lista.length > 0)) {
                    let dataHoraAtual = new Date();
                    let referenciaPomarSelecionado = TextoUtil_1.TextoUtil.converteTextoParaReferencia(ctrl.pomarSelecionado.descricao);
                    const camposCabecalho = new Array();
                    const indicadores = new Array();
                    const camposRodape = new Array();
                    angular.forEach(lista, (apontamento) => {
                        if ((typeof apontamento.detalhamento !== 'undefined') && (apontamento.detalhamento !== null)) {
                            apontamento.detalhamento.pomarDescricao = null;
                            apontamento.detalhamento.pomarValor = null;
                            apontamento.detalhamento.materialGeneticoDescricao = null;
                            apontamento.detalhamento.polemDescricao = null;
                            if ((typeof apontamento.detalhamento.outrosPomares === 'undefined') || !apontamento.detalhamento.outrosPomares) {
                                apontamento.detalhamento.outrosPomares = {};
                            }
                            if ((typeof apontamento.detalhamento.camposCabecalho !== 'undefined') && (apontamento.detalhamento.camposCabecalho !== null)) {
                                let dataApontamento = null;
                                angular.forEach(apontamento.detalhamento.camposCabecalho, (campoCabecalho) => {
                                    if ((typeof campoCabecalho.titulo !== 'undefined') && campoCabecalho.titulo && (typeof campoCabecalho.valor !== 'undefined') && campoCabecalho.valor) {
                                        let referenciaCampoCabecalho = TextoUtil_1.TextoUtil.converteTextoParaReferencia(campoCabecalho.titulo);
                                        if ((referenciaCampoCabecalho === 'data') || (referenciaCampoCabecalho === 'datahora') || (referenciaCampoCabecalho === 'data_hora')) {
                                            if (campoCabecalho.valor.indexOf('T') !== -1) {
                                                let data = new Date(campoCabecalho.valor);
                                                dataApontamento = data;
                                                campoCabecalho.titulo = 'Data';
                                                campoCabecalho.valor = DataUtil_1.DataUtil.converterDataParaFormatoDDMMYYYY(data);
                                            }
                                        }
                                        else if (referenciaCampoCabecalho === 'idade') {
                                            if (dataApontamento) {
                                                let idadeMs = dataHoraAtual.getTime() - dataApontamento.getTime();
                                                let idadeData = new Date(idadeMs);
                                                if (idadeData) {
                                                    campoCabecalho.valor = '' + Math.abs(idadeData.getUTCFullYear() - 1970);
                                                }
                                            }
                                        }
                                        else if ((referenciaCampoCabecalho.indexOf('material') !== -1) && (referenciaCampoCabecalho.indexOf('genetico') !== -1)) {
                                            if (!apontamento.detalhamento.materialGeneticoDescricao) {
                                                apontamento.detalhamento.materialGeneticoDescricao = campoCabecalho.valor;
                                            }
                                        }
                                        else if ((referenciaCampoCabecalho.indexOf('pai') !== -1)) {
                                            if (!apontamento.detalhamento.polemDescricao) {
                                                apontamento.detalhamento.polemDescricao = campoCabecalho.valor;
                                            }
                                        }
                                    }
                                });
                                angular.forEach(Object.keys(apontamento.detalhamento.camposCabecalho), (chaveCampo) => {
                                    let achouCampoCabecalho = false;
                                    angular.forEach(camposCabecalho, (campo) => {
                                        if (!achouCampoCabecalho && (campo.key === chaveCampo)) {
                                            achouCampoCabecalho = true;
                                        }
                                    });
                                    if (!achouCampoCabecalho) {
                                        camposCabecalho.push({
                                            key: chaveCampo,
                                            titulo: apontamento.detalhamento.camposCabecalho[chaveCampo].titulo
                                        });
                                    }
                                });
                            }
                            if ((typeof apontamento.detalhamento.indicadores !== 'undefined') && (apontamento.detalhamento.indicadores !== null)) {
                                angular.forEach(Object.keys(apontamento.detalhamento.indicadores), (chaveIndicador) => {
                                    let objIndicador = null;
                                    angular.forEach(indicadores, (indicador) => {
                                        if (!objIndicador && (indicador.key === chaveIndicador)) {
                                            objIndicador = indicador;
                                        }
                                    });
                                    if (!objIndicador) {
                                        objIndicador = {
                                            key: chaveIndicador,
                                            descricao: null,
                                            campos: new Array()
                                        };
                                        indicadores.push(objIndicador);
                                    }
                                    if ((typeof apontamento.detalhamento.indicadores[chaveIndicador] !== 'undefined') && (apontamento.detalhamento.indicadores[chaveIndicador] !== null)) {
                                        if ((typeof apontamento.detalhamento.indicadores[chaveIndicador].descricao !== 'undefined') && (apontamento.detalhamento.indicadores[chaveIndicador].descricao !== null)) {
                                            objIndicador.descricao = apontamento.detalhamento.indicadores[chaveIndicador].descricao;
                                        }
                                        const referenciaIndicador = TextoUtil_1.TextoUtil.converteTextoParaReferencia(objIndicador.descricao);
                                        if ((typeof apontamento.detalhamento.indicadores[chaveIndicador].campos !== 'undefined') && (apontamento.detalhamento.indicadores[chaveIndicador].campos !== null)) {
                                            let campoAnteriorPomar = false;
                                            let campoAnteriorOutroPomar = null;
                                            angular.forEach(Object.keys(apontamento.detalhamento.indicadores[chaveIndicador].campos), (chaveCampo) => {
                                                let achouCampoIndicador = false;
                                                angular.forEach(objIndicador.campos, (campo) => {
                                                    if (!achouCampoIndicador && (campo.key === chaveCampo)) {
                                                        achouCampoIndicador = true;
                                                    }
                                                });
                                                if (!achouCampoIndicador) {
                                                    objIndicador.campos.push({
                                                        key: chaveCampo,
                                                        titulo: apontamento.detalhamento.indicadores[chaveIndicador].campos[chaveCampo].titulo
                                                    });
                                                }
                                                let referenciaCampo = TextoUtil_1.TextoUtil.converteTextoParaReferencia(apontamento.detalhamento.indicadores[chaveIndicador].campos[chaveCampo].titulo);
                                                if (!apontamento.detalhamento.pomarDescricao || !apontamento.detalhamento.pomarValor) {
                                                    if ((referenciaCampo.indexOf('pomar') !== -1) && (referenciaCampo.indexOf('origem') === -1)) {
                                                        let referenciaPomar = TextoUtil_1.TextoUtil.converteTextoParaReferencia(apontamento.detalhamento.indicadores[chaveIndicador].campos[chaveCampo].valor);
                                                        if (referenciaPomar === referenciaPomarSelecionado) {
                                                            apontamento.detalhamento.pomarDescricao = apontamento.detalhamento.indicadores[chaveIndicador].campos[chaveCampo].valor;
                                                            campoAnteriorPomar = true;
                                                        }
                                                        else if (campoAnteriorPomar) {
                                                            apontamento.detalhamento.pomarValor = apontamento.detalhamento.indicadores[chaveIndicador].campos[chaveCampo].valor;
                                                            campoAnteriorPomar = false;
                                                        }
                                                    }
                                                    else if (campoAnteriorPomar) {
                                                        apontamento.detalhamento.pomarValor = apontamento.detalhamento.indicadores[chaveIndicador].campos[chaveCampo].valor;
                                                        campoAnteriorPomar = false;
                                                    }
                                                }
                                                if ((referenciaCampo.indexOf('pomar') !== -1) && (referenciaCampo.indexOf('origem') === -1) &&
                                                    (typeof apontamento.detalhamento.indicadores[chaveIndicador].campos[chaveCampo].valor !== 'undefined') && apontamento.detalhamento.indicadores[chaveIndicador].campos[chaveCampo].valor) {
                                                    let referenciaPomar = TextoUtil_1.TextoUtil.converteTextoParaReferencia(apontamento.detalhamento.indicadores[chaveIndicador].campos[chaveCampo].valor);
                                                    if (referenciaPomar && (referenciaPomar.length > 0)) {
                                                        if (referenciaPomar !== referenciaPomarSelecionado) {
                                                            apontamento.detalhamento.outrosPomares[referenciaPomar] = {
                                                                pomar: apontamento.detalhamento.indicadores[chaveIndicador].campos[chaveCampo].valor,
                                                                valor: null
                                                            };
                                                            campoAnteriorOutroPomar = `${referenciaPomar}`;
                                                        }
                                                        else {
                                                            if ((typeof campoAnteriorOutroPomar !== 'undefined') && campoAnteriorOutroPomar && (campoAnteriorOutroPomar.length > 0)) {
                                                                if ((typeof apontamento.detalhamento.outrosPomares[campoAnteriorOutroPomar].pomar !== 'undefined') &&
                                                                    (apontamento.detalhamento.outrosPomares[campoAnteriorOutroPomar].pomar !== null)) {
                                                                    apontamento.detalhamento.outrosPomares[campoAnteriorOutroPomar].valor = apontamento.detalhamento.indicadores[chaveIndicador].campos[chaveCampo].valor;
                                                                }
                                                                campoAnteriorOutroPomar = null;
                                                            }
                                                        }
                                                    }
                                                }
                                                else if ((typeof campoAnteriorOutroPomar !== 'undefined') && campoAnteriorOutroPomar && (campoAnteriorOutroPomar.length > 0)) {
                                                    if ((typeof apontamento.detalhamento.outrosPomares[campoAnteriorOutroPomar].pomar !== 'undefined') &&
                                                        (apontamento.detalhamento.outrosPomares[campoAnteriorOutroPomar].pomar !== null)) {
                                                        apontamento.detalhamento.outrosPomares[campoAnteriorOutroPomar].valor = apontamento.detalhamento.indicadores[chaveIndicador].campos[chaveCampo].valor;
                                                    }
                                                    campoAnteriorOutroPomar = null;
                                                }
                                                if ((referenciaCampo.indexOf('material') !== -1) && (referenciaCampo.indexOf('genetico') !== -1)) {
                                                    if (!apontamento.detalhamento.materialGeneticoDescricao) {
                                                        apontamento.detalhamento.materialGeneticoDescricao = apontamento.detalhamento.indicadores[chaveIndicador].campos[chaveCampo].valor;
                                                    }
                                                }
                                                else if ((referenciaIndicador.indexOf('cruzamento') !== -1) && (referenciaCampo.indexOf('pai') !== -1)) {
                                                    if (!apontamento.detalhamento.polemDescricao) {
                                                        apontamento.detalhamento.polemDescricao = apontamento.detalhamento.indicadores[chaveIndicador].campos[chaveCampo].valor;
                                                    }
                                                }
                                            });
                                        }
                                    }
                                });
                            }
                            if ((typeof apontamento.detalhamento.camposRodape !== 'undefined') && (apontamento.detalhamento.camposRodape !== null)) {
                                angular.forEach(Object.keys(apontamento.detalhamento.camposRodape), (chaveCampo) => {
                                    let achouCampoRodape = false;
                                    angular.forEach(camposRodape, (campo) => {
                                        if (!achouCampoRodape && (campo.key === chaveCampo)) {
                                            achouCampoRodape = true;
                                        }
                                    });
                                    if (!achouCampoRodape) {
                                        camposRodape.push({
                                            key: chaveCampo,
                                            titulo: apontamento.detalhamento.camposRodape[chaveCampo].titulo
                                        });
                                    }
                                });
                            }
                        }
                    });
                    auxColunas.push({
                        name: 'detalhamento.pomarDescricao',
                        displayName: 'Pomar',
                        width: 140,
                        visible: false
                    });
                    auxColunas.push({
                        name: 'detalhamento.pomarValor',
                        displayName: 'Código',
                        width: 100
                    });
                    if (indicadores.length > 0) {
                        indicadores.sort((a, b) => a.key.localeCompare(b.key));
                        angular.forEach(indicadores, (indicador) => {
                            indicador.campos.sort((a, b) => a.key.localeCompare(b.key));
                            angular.forEach(indicador.campos, (campo) => {
                                let novaColuna = {
                                    name: 'detalhamento.indicadores.' + indicador.key + '.campos.' + campo.key + '.valor',
                                    displayName: ((indicador.descricao && campo.titulo && (indicador.descricao.trim().toLowerCase() === campo.titulo.trim().toLowerCase())) ? indicador.descricao : (indicador.descricao + ' | ' + campo.titulo))
                                };
                                let referenciaTituloCampo = TextoUtil_1.TextoUtil.converteTextoParaReferencia(novaColuna.displayName);
                                if (referenciaTituloCampo.indexOf('cruzamento') !== -1) {
                                    auxColunas.push(novaColuna);
                                }
                            });
                        });
                        angular.forEach(indicadores, (indicador) => {
                            indicador.campos.sort((a, b) => a.key.localeCompare(b.key));
                            angular.forEach(indicador.campos, (campo) => {
                                let novaColuna = {
                                    name: 'detalhamento.indicadores.' + indicador.key + '.campos.' + campo.key + '.valor',
                                    displayName: ((indicador.descricao && campo.titulo && (indicador.descricao.trim().toLowerCase() === campo.titulo.trim().toLowerCase())) ? indicador.descricao : (indicador.descricao + ' | ' + campo.titulo))
                                };
                                let referenciaTituloCampo = TextoUtil_1.TextoUtil.converteTextoParaReferencia(novaColuna.displayName);
                                if (referenciaTituloCampo.indexOf('cruzamento') === -1) {
                                    if (referenciaTituloCampo.indexOf('pomar') === 0) {
                                        novaColuna.visible = false;
                                    }
                                    auxColunas.push(novaColuna);
                                }
                            });
                        });
                    }
                    if (camposCabecalho.length > 0) {
                        camposCabecalho.sort((a, b) => a.key.localeCompare(b.key));
                        angular.forEach(camposCabecalho, (campo) => {
                            let novaColuna = {
                                name: 'detalhamento.camposCabecalho.' + campo.key + '.valor',
                                displayName: campo.titulo
                            };
                            if (campo.titulo && (TextoUtil_1.TextoUtil.converteTextoParaReferencia(campo.titulo) === 'data')) {
                                novaColuna.width = 130;
                            }
                            else if (campo.titulo && (TextoUtil_1.TextoUtil.converteTextoParaReferencia(campo.titulo) === 'ordem')) {
                                novaColuna.visible = false;
                            }
                            auxColunas.push(novaColuna);
                        });
                    }
                    if (camposRodape.length > 0) {
                        camposRodape.sort((a, b) => a.key.localeCompare(b.key));
                        angular.forEach(camposRodape, (campo) => {
                            let novaColuna = {
                                name: 'detalhamento.camposRodape.' + campo.key + '.valor',
                                displayName: campo.titulo,
                            };
                            auxColunas.push(novaColuna);
                        });
                    }
                    let possuiCampoNumRametes = false;
                    angular.forEach(auxColunas, (coluna) => {
                        if (!possuiCampoNumRametes && (typeof coluna.displayName !== 'undefined') && coluna.displayName) {
                            const tituloColuna = coluna.displayName.trim().toLowerCase();
                            if (tituloColuna.indexOf('rametes') !== -1) {
                                possuiCampoNumRametes = true;
                            }
                        }
                    });
                    if (!possuiCampoNumRametes) {
                        let novaColuna = {
                            name: 'detalhamento.numRametes',
                            displayName: 'Núm. Rametes',
                        };
                        auxColunas.push(novaColuna);
                    }
                    lista.sort((a, b) => {
                        let s1 = '0000000000' + (a && (typeof a.detalhamento !== 'undefined') && a.detalhamento &&
                            (typeof a.detalhamento.pomarValor !== 'undefined') && a.detalhamento.pomarValor
                            ? a.detalhamento.pomarValor : '');
                        if (s1.length > 10) {
                            s1 = s1.substring(s1.length - 10, s1.length);
                        }
                        let s2 = '0000000000' + (b && (typeof b.detalhamento !== 'undefined') && b.detalhamento &&
                            (typeof b.detalhamento.pomarValor !== 'undefined') && b.detalhamento.pomarValor
                            ? b.detalhamento.pomarValor : '');
                        if (s2.length > 10) {
                            s2 = s2.substring(s2.length - 10, s2.length);
                        }
                        return s1.localeCompare(s2);
                    });
                }
                let auxColunas2 = new Array();
                angular.forEach(auxColunas, (coluna) => {
                    if ((typeof coluna.displayName !== 'undefined') && coluna.displayName) {
                        const tituloColuna = coluna.displayName.trim().toLowerCase();
                        if ((tituloColuna === 'código') ||
                            (tituloColuna === 'codigo')) {
                            const colunaCodigoMaterialGenetico = {
                                name: coluna.name + '_' + moment().format('X'),
                                field: coluna.name,
                                displayName: 'Cód. Material Genético',
                                width: 170
                            };
                            auxColunas2.push(colunaCodigoMaterialGenetico);
                        }
                    }
                });
                angular.forEach(auxColunas, (coluna) => {
                    if ((typeof coluna.displayName !== 'undefined') && coluna.displayName) {
                        const tituloColuna = coluna.displayName.trim().toLowerCase();
                        if ((tituloColuna.indexOf('experimento') !== -1) ||
                            (tituloColuna === 'região') ||
                            (tituloColuna === 'ano') ||
                            (tituloColuna === 'data') ||
                            (tituloColuna === 'idade') ||
                            (tituloColuna === 'tipo de teste') ||
                            (tituloColuna === 'status')) {
                            auxColunas2.push(coluna);
                        }
                    }
                });
                angular.forEach(auxColunas, (coluna) => {
                    if ((typeof coluna.displayName !== 'undefined') && coluna.displayName) {
                        const tituloColuna = coluna.displayName.trim().toLowerCase();
                        if ((tituloColuna.indexOf('especie') === 0) ||
                            (tituloColuna.indexOf('espécie') === 0)) {
                            auxColunas2.push(coluna);
                        }
                    }
                });
                angular.forEach(auxColunas, (coluna) => {
                    if ((typeof coluna.displayName !== 'undefined') && coluna.displayName) {
                        const tituloColuna = coluna.displayName.trim().toLowerCase();
                        if ((tituloColuna.indexOf('procedencia') === 0) ||
                            (tituloColuna.indexOf('procedência') === 0)) {
                            auxColunas2.push(coluna);
                        }
                    }
                });
                angular.forEach(auxColunas, (coluna) => {
                    if (auxColunas2.indexOf(coluna) === -1) {
                        auxColunas2.push(coluna);
                    }
                });
                auxColunas = auxColunas2;
                if ((typeof lista !== 'undefined') && lista) {
                    let listaTitulosOutrosPomares = new Array();
                    angular.forEach(lista, (apontamento) => {
                        if ((typeof apontamento.detalhamento.outrosPomares !== 'undefined') && apontamento.detalhamento.outrosPomares) {
                            angular.forEach(Object.keys(apontamento.detalhamento.outrosPomares), (chaveOutroPomar) => {
                                const outroPomar = apontamento.detalhamento.outrosPomares[chaveOutroPomar];
                                if (outroPomar && (typeof outroPomar.pomar !== 'undefined') && outroPomar.pomar) {
                                    if (!listaTitulosOutrosPomares.some(o => o.chave === chaveOutroPomar)) {
                                        listaTitulosOutrosPomares.push({ chave: chaveOutroPomar, pomar: outroPomar.pomar });
                                    }
                                }
                            });
                        }
                    });
                    angular.forEach(listaTitulosOutrosPomares, (tituloOutroPomar) => {
                        const colunaOutroPomar = {
                            name: 'outroPomar_' + tituloOutroPomar.chave,
                            field: 'detalhamento.outrosPomares.' + tituloOutroPomar.chave + '.valor',
                            displayName: tituloOutroPomar.pomar,
                            width: 200
                        };
                        auxColunas.push(colunaOutroPomar);
                    });
                }
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    if ((typeof coluna.displayName !== 'undefined') && coluna.displayName) {
                        const tituloColuna = coluna.displayName.trim().toLowerCase();
                        if ((tituloColuna === 'data') ||
                            (tituloColuna === 'idade') ||
                            (tituloColuna === 'tipo de teste') ||
                            (tituloColuna === 'status')) {
                            coluna.visible = false;
                        }
                    }
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
                console.log(lista);
                return gridOption;
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            return null;
        });
    }
    static dadosListaClimaAnualPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.pomarSelecionado || !ctrl.pomarSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'mes',
                    displayName: 'Mês',
                    width: 90
                });
                auxColunas.push({
                    name: 'temperaturaMaxima',
                    displayName: 'Temp. Máx.',
                    width: 110,
                    enableCellEdit: true
                });
                auxColunas.push({
                    name: 'temperaturaMedia',
                    displayName: 'Temp. Média',
                    width: 120,
                    enableCellEdit: true
                });
                auxColunas.push({
                    name: 'temperaturaMinima',
                    displayName: 'Temp. Mín.',
                    width: 110,
                    enableCellEdit: true
                });
                auxColunas.push({
                    name: 'precipitacaoMaxima',
                    displayName: 'Precip. Máx.',
                    width: 120,
                    enableCellEdit: true
                });
                auxColunas.push({
                    name: 'precipitacaoMedia',
                    displayName: 'Precip. Média',
                    width: 130,
                    enableCellEdit: true
                });
                auxColunas.push({
                    name: 'precipitacaoMinima',
                    displayName: 'Precip. Mín.',
                    width: 120,
                    enableCellEdit: true
                });
                auxColunas.push({
                    name: 'temperaturaMinimaAbsoluta',
                    displayName: 'Temp. Mín. Absoluta',
                    width: 160,
                    enableCellEdit: true
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                let lista = [
                    {
                        mes: 'Média Anual',
                        temperaturaMaxima: null,
                        temperaturaMedia: null,
                        temperaturaMinima: null,
                        precipitacaoMaxima: null,
                        precipitacaoMedia: null,
                        precipitacaoMinima: null,
                        temperaturaMinimaAbsoluta: null
                    }, {
                        mes: 'Janeiro',
                        temperaturaMaxima: 36,
                        temperaturaMedia: 24,
                        temperaturaMinima: 12,
                        precipitacaoMaxima: 175.514,
                        precipitacaoMedia: 166.738,
                        precipitacaoMinima: 157.963,
                        temperaturaMinimaAbsoluta: 12
                    }, {
                        mes: 'Fevereiro',
                        temperaturaMaxima: 33,
                        temperaturaMedia: 22,
                        temperaturaMinima: 11,
                        precipitacaoMaxima: 154.94,
                        precipitacaoMedia: 147.193,
                        precipitacaoMinima: 139.446,
                        temperaturaMinimaAbsoluta: 11
                    }, {
                        mes: 'Março',
                        temperaturaMaxima: 34,
                        temperaturaMedia: 23.5,
                        temperaturaMinima: 13,
                        precipitacaoMaxima: 120.904,
                        precipitacaoMedia: 114.859,
                        precipitacaoMinima: 108.814,
                        temperaturaMinimaAbsoluta: 13
                    }, {
                        mes: 'Abril',
                        temperaturaMaxima: 31,
                        temperaturaMedia: 18.5,
                        temperaturaMinima: 6,
                        precipitacaoMaxima: 114.554,
                        precipitacaoMedia: 108.826,
                        precipitacaoMinima: 103.099,
                        temperaturaMinimaAbsoluta: 6
                    }, {
                        mes: 'Maio',
                        temperaturaMaxima: 30,
                        temperaturaMedia: 16.5,
                        temperaturaMinima: 3,
                        precipitacaoMaxima: 235.458,
                        precipitacaoMedia: 223.685,
                        precipitacaoMinima: 211.912,
                        temperaturaMinimaAbsoluta: 3
                    }, {
                        mes: 'Junho',
                        temperaturaMaxima: 27,
                        temperaturaMedia: 16,
                        temperaturaMinima: 5,
                        precipitacaoMaxima: 53.848,
                        precipitacaoMedia: 51.156,
                        precipitacaoMinima: 48.463,
                        temperaturaMinimaAbsoluta: 5
                    }, {
                        mes: 'Julho',
                        temperaturaMaxima: 28,
                        temperaturaMedia: 13,
                        temperaturaMinima: -2,
                        precipitacaoMaxima: 205.74,
                        precipitacaoMedia: 195.453,
                        precipitacaoMinima: 185.166,
                        temperaturaMinimaAbsoluta: -2
                    }, {
                        mes: 'Agosto',
                        temperaturaMaxima: 32,
                        temperaturaMedia: 15,
                        temperaturaMinima: -2,
                        precipitacaoMaxima: 25.146,
                        precipitacaoMedia: 23.889,
                        precipitacaoMinima: 22.631,
                        temperaturaMinimaAbsoluta: -2
                    }, {
                        mes: 'Setembro',
                        temperaturaMaxima: 34,
                        temperaturaMedia: 19.5,
                        temperaturaMinima: 5,
                        precipitacaoMaxima: 120.142,
                        precipitacaoMedia: 114.135,
                        precipitacaoMinima: 108.128,
                        temperaturaMinimaAbsoluta: 5
                    }, {
                        mes: 'Outubro',
                        temperaturaMaxima: 35,
                        temperaturaMedia: 22.5,
                        temperaturaMinima: 10,
                        precipitacaoMaxima: 126.746,
                        precipitacaoMedia: 120.409,
                        precipitacaoMinima: 114.071,
                        temperaturaMinimaAbsoluta: 10
                    }, {
                        mes: 'Novembro',
                        temperaturaMaxima: 31,
                        temperaturaMedia: 18.5,
                        temperaturaMinima: 6,
                        precipitacaoMaxima: 173.736,
                        precipitacaoMedia: 165.049,
                        precipitacaoMinima: 156.362,
                        temperaturaMinimaAbsoluta: 6
                    }, {
                        mes: 'Dezembro',
                        temperaturaMaxima: 28,
                        temperaturaMedia: 23,
                        temperaturaMinima: 18,
                        precipitacaoMaxima: 1.524,
                        precipitacaoMedia: 1.448,
                        precipitacaoMinima: 1.372,
                        temperaturaMinimaAbsoluta: 18
                    }
                ];
                if ((typeof globalThis.timeoutCalculaClimaAnualPomar !== 'undefined') && globalThis.timeoutCalculaClimaAnualPomar) {
                    clearInterval(globalThis.timeoutCalculaClimaAnualPomar);
                    globalThis.timeoutCalculaClimaAnualPomar = null;
                }
                globalThis.timeoutCalculaClimaAnualPomar = setInterval((ctrl, lista) => {
                    if ((typeof ctrl.pomarSelecionado !== 'undefined') && ctrl.pomarSelecionado &&
                        (typeof ctrl.pomarSelecionado.id !== 'undefined') && ctrl.pomarSelecionado.id &&
                        (typeof lista !== 'undefined') && lista && (lista.length > 0)) {
                        const abaDadosGeograficosEl = angular.element('#banco_melhoramento_pomar_dados_geograficos');
                        if ((typeof abaDadosGeograficosEl !== 'undefined') && abaDadosGeograficosEl && (abaDadosGeograficosEl.length > 0) &&
                            (abaDadosGeograficosEl.hasClass('show') || abaDadosGeograficosEl.hasClass('active'))) {
                            let listaTemp = new Array();
                            angular.forEach(lista, (item) => {
                                listaTemp.push(Object.assign({}, item));
                            });
                            let somaTemperaturaAnual = 0;
                            let contadorTemperaturaAnual = 0;
                            let somaPrecipitacaoAnual = 0;
                            let contadorPrecipitacaoAnual = 0;
                            let temperaturaMinima = 0;
                            let itemMediaAnual = null;
                            angular.forEach(listaTemp, (item) => {
                                if (item.mes && (item.mes.indexOf('Anual') !== -1)) {
                                    item.temperaturaMaxima = null;
                                    item.temperaturaMedia = null;
                                    item.temperaturaMinima = null;
                                    item.precipitacaoMaxima = null;
                                    item.precipitacaoMedia = null;
                                    item.precipitacaoMinima = null;
                                    item.temperaturaMinimaAbsoluta = null;
                                    itemMediaAnual = item;
                                }
                                else {
                                    if ((typeof item.temperaturaMaxima !== 'undefined') && item.temperaturaMaxima && (parseFloat(item.temperaturaMaxima) !== 0) &&
                                        (typeof item.temperaturaMinima !== 'undefined') && item.temperaturaMinima && (parseFloat(item.temperaturaMinima) !== 0)) {
                                        item.temperaturaMedia = Math.round(((parseFloat(item.temperaturaMinima) + parseFloat(item.temperaturaMaxima)) / 2) * 100) / 100;
                                    }
                                    if ((typeof item.precipitacaoMaxima !== 'undefined') && item.precipitacaoMaxima && (parseFloat(item.precipitacaoMaxima) !== 0) &&
                                        (typeof item.precipitacaoMinima !== 'undefined') && item.precipitacaoMinima && (parseFloat(item.precipitacaoMinima) !== 0)) {
                                        item.precipitacaoMedia = Math.round(((parseFloat(item.precipitacaoMinima) + parseFloat(item.precipitacaoMaxima)) / 2) * 100) / 100;
                                    }
                                    if ((typeof item.temperaturaMaxima !== 'undefined') && item.temperaturaMaxima && (parseFloat(item.temperaturaMaxima) !== 0)) {
                                        if ((temperaturaMinima === 0) || (parseFloat(item.temperaturaMaxima) < temperaturaMinima)) {
                                            temperaturaMinima = parseFloat(item.temperaturaMaxima);
                                        }
                                    }
                                    if ((typeof item.temperaturaMinima !== 'undefined') && item.temperaturaMinima && (parseFloat(item.temperaturaMinima) !== 0)) {
                                        if ((temperaturaMinima === 0) || (parseFloat(item.temperaturaMinima) < temperaturaMinima)) {
                                            temperaturaMinima = parseFloat(item.temperaturaMinima);
                                        }
                                    }
                                    if ((typeof item.temperaturaMedia !== 'undefined') && item.temperaturaMedia && (parseFloat(item.temperaturaMedia) !== 0)) {
                                        if ((temperaturaMinima === 0) || (parseFloat(item.temperaturaMedia) < temperaturaMinima)) {
                                            temperaturaMinima = parseFloat(item.temperaturaMedia);
                                        }
                                    }
                                    if ((typeof item.temperaturaMedia !== 'undefined') && parseFloat(item.temperaturaMedia)) {
                                        somaTemperaturaAnual += parseFloat(item.temperaturaMedia);
                                        contadorTemperaturaAnual++;
                                    }
                                    if ((typeof item.precipitacaoMedia !== 'undefined') && parseFloat(item.precipitacaoMedia)) {
                                        somaPrecipitacaoAnual += parseFloat(item.precipitacaoMedia);
                                        contadorPrecipitacaoAnual++;
                                    }
                                    if (itemMediaAnual && (typeof item.temperaturaMaxima !== 'undefined') && item.temperaturaMaxima && (parseFloat(item.temperaturaMaxima) !== 0)) {
                                        if (!itemMediaAnual.temperaturaMaxima || (parseFloat(item.temperaturaMaxima) && (parseFloat(item.temperaturaMaxima) > itemMediaAnual.temperaturaMaxima))) {
                                            itemMediaAnual.temperaturaMaxima = parseFloat(item.temperaturaMaxima);
                                        }
                                    }
                                    if (itemMediaAnual && (typeof item.temperaturaMedia !== 'undefined') && item.temperaturaMedia && (parseFloat(item.temperaturaMedia) !== 0)) {
                                        if (!itemMediaAnual.temperaturaMedia || (item.temperaturaMedia && (parseFloat(item.temperaturaMedia) > itemMediaAnual.temperaturaMedia))) {
                                            itemMediaAnual.temperaturaMedia = parseFloat(item.temperaturaMedia);
                                        }
                                    }
                                    if (itemMediaAnual && (typeof item.temperaturaMinima !== 'undefined') && item.temperaturaMinima && (parseFloat(item.temperaturaMinima) !== 0)) {
                                        if (!itemMediaAnual.temperaturaMinima || (item.temperaturaMinima && (parseFloat(item.temperaturaMinima) < itemMediaAnual.temperaturaMinima))) {
                                            itemMediaAnual.temperaturaMinima = parseFloat(item.temperaturaMinima);
                                        }
                                    }
                                    if (itemMediaAnual && (typeof item.precipitacaoMaxima !== 'undefined') && item.precipitacaoMaxima && (parseFloat(item.precipitacaoMaxima) !== 0)) {
                                        if (!itemMediaAnual.precipitacaoMaxima || (item.precipitacaoMaxima && (parseFloat(item.precipitacaoMaxima) > itemMediaAnual.precipitacaoMaxima))) {
                                            itemMediaAnual.precipitacaoMaxima = parseFloat(item.precipitacaoMaxima);
                                        }
                                    }
                                    if (itemMediaAnual && (typeof item.precipitacaoMedia !== 'undefined') && item.precipitacaoMedia && (parseFloat(item.precipitacaoMedia) !== 0)) {
                                        if (!itemMediaAnual.precipitacaoMedia || (item.precipitacaoMedia && (parseFloat(item.precipitacaoMedia) > itemMediaAnual.precipitacaoMedia))) {
                                            itemMediaAnual.precipitacaoMedia = parseFloat(item.precipitacaoMedia);
                                        }
                                    }
                                    if (itemMediaAnual && (typeof item.temperaturaMinimaAbsoluta !== 'undefined') && item.temperaturaMinimaAbsoluta && (parseFloat(item.temperaturaMinimaAbsoluta) !== 0)) {
                                        if (!itemMediaAnual.temperaturaMinimaAbsoluta || (item.temperaturaMinimaAbsoluta && (parseFloat(item.temperaturaMinimaAbsoluta) < itemMediaAnual.temperaturaMinimaAbsoluta))) {
                                            itemMediaAnual.temperaturaMinimaAbsoluta = parseFloat(item.temperaturaMinimaAbsoluta);
                                        }
                                    }
                                    if (itemMediaAnual && (typeof item.precipitacaoMinima !== 'undefined') && item.precipitacaoMinima && (parseFloat(item.precipitacaoMinima) !== 0)) {
                                        if (!itemMediaAnual.precipitacaoMinima || (item.precipitacaoMinima && (parseFloat(item.precipitacaoMinima) < itemMediaAnual.precipitacaoMinima))) {
                                            itemMediaAnual.precipitacaoMinima = parseFloat(item.precipitacaoMinima);
                                        }
                                    }
                                }
                            });
                            let dadosAtualizados = false;
                            if (JSON.stringify(lista) !== JSON.stringify(listaTemp)) {
                                lista.splice(0, lista.length);
                                lista.push(...listaTemp);
                                dadosAtualizados = true;
                                console.log('Interval: CalculaClimaAnualPomar > Lista da tabela modificada');
                            }
                            if ((somaTemperaturaAnual !== 0) && (contadorTemperaturaAnual !== 0)) {
                                const temperaturaTemp = Number(Math.round((somaTemperaturaAnual / contadorTemperaturaAnual) * 100) / 100).toString();
                                if (ctrl.pomarSelecionado.perfilClimaticoAnual.temperatura !== temperaturaTemp) {
                                    ctrl.pomarSelecionado.perfilClimaticoAnual.temperatura = temperaturaTemp;
                                    dadosAtualizados = true;
                                    console.log('Interval: CalculaClimaAnualPomar > Temperatura anual modificada');
                                }
                            }
                            else {
                                if (ctrl.pomarSelecionado.perfilClimaticoAnual.temperatura !== '0') {
                                    ctrl.pomarSelecionado.perfilClimaticoAnual.temperatura = '0';
                                }
                            }
                            if ((somaPrecipitacaoAnual !== 0) && (contadorPrecipitacaoAnual !== 0)) {
                                const precipitacaoTemp = Number(Math.round((somaPrecipitacaoAnual / contadorPrecipitacaoAnual) * 100) / 100).toString();
                                if (ctrl.pomarSelecionado.perfilClimaticoAnual.precipitacao !== precipitacaoTemp) {
                                    ctrl.pomarSelecionado.perfilClimaticoAnual.precipitacao = precipitacaoTemp;
                                    dadosAtualizados = true;
                                    console.log('Interval: CalculaClimaAnualPomar > Precipitação modificada');
                                }
                            }
                            else {
                                if (ctrl.pomarSelecionado.perfilClimaticoAnual.precipitacao !== '0') {
                                    ctrl.pomarSelecionado.perfilClimaticoAnual.precipitacao = '0';
                                }
                            }
                            if (temperaturaMinima > 0) {
                                const temperaturaMinimaTemp = Number(Math.round(temperaturaMinima * 100) / 100).toString();
                                if (ctrl.pomarSelecionado.perfilClimaticoAnual.temperaturaMinima !== temperaturaMinimaTemp) {
                                    ctrl.pomarSelecionado.perfilClimaticoAnual.temperaturaMinima = temperaturaMinimaTemp;
                                    dadosAtualizados = true;
                                    console.log('Interval: CalculaClimaAnualPomar > Temperatura mínima modificada');
                                }
                            }
                            else {
                                if (ctrl.pomarSelecionado.perfilClimaticoAnual.temperaturaMinima !== '0') {
                                    ctrl.pomarSelecionado.perfilClimaticoAnual.temperaturaMinima = '0';
                                }
                            }
                            if (dadosAtualizados) {
                                ctrl.atualizarGraficosClimaAnual();
                                ctrl.scopeApply();
                            }
                        }
                    }
                }, 2500, ctrl, lista);
                setTimeout((ctrl) => {
                    try {
                        ctrl.atualizarGraficosClimaAnual();
                    }
                    catch (ex) {
                        console.log(ex);
                    }
                    setTimeout((ctrl) => {
                        try {
                            ctrl.atualizarGraficosClimaAnual();
                        }
                        catch (ex) {
                            console.log(ex);
                        }
                    }, 1500, ctrl);
                }, 1500, ctrl);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    controller: ctrl
                };
                return gridOption;
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            return null;
        });
    }
    static dadosListaAnexosPomar(ctrl, seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.pomarSelecionado || !ctrl.pomarSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(seletorCarregamento ? seletorCarregamento : '.card');
                let lista = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANEXO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANEXO_POMAR)
                    .filtro({
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    pomar: ctrl.pomarSelecionado.id
                })
                    .listar();
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    cellClass: 'text-center',
                    cellTemplate: `
                        <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                            <button type="button" class="btn btn-icon bx bx-dots-vertical-rounded" uib-dropdown-toggle></button>
                            <ul class="dropdown-menu dropdown-menu-left" uib-dropdown-menu role="menu">
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalAnexoPomar({ id: row.entity.id || row.entity.uuid, visualizar: true })' class="text-secondary w-100"><i class="bx bx-show mr-1 ml-1"></i>Visualizar</a></li>
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalAnexoPomar({ id: row.entity.id || row.entity.uuid, editar: true })' class="text-secondary w-100"><i class="bx bxs-edit-alt mr-1 ml-1"></i>Editar</a></li>
                                <li><hr></li>
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalAnexoPomar({ id: row.entity.id || row.entity.uuid, excluir: true })' class="text-secondary w-100"><i class="bx bxs-trash-alt mr-1 ml-1"></i>Excluir</a></li>
                            </ul>
                        </div> `
                });
                auxColunas.push({
                    name: 'descricao',
                    displayName: 'Descrição anexo',
                    width: 300
                });
                auxColunas.push({
                    name: 'criacao',
                    displayName: 'Data/Hora anexo',
                    cellFilter: 'date:"dd/MM/yyyy HH:mm:ss"',
                    width: 160
                });
                auxColunas.push({
                    name: 'usuarioInclusao.nomeCompleto',
                    displayName: 'Anexado por',
                    width: 200
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            return null;
        });
    }
}
exports.PomarBancoConservacaoDadosListaUtil = PomarBancoConservacaoDadosListaUtil;
