"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UiGridConstrutor = void 0;
class UiGridConstrutor {
    constructor(ctrl) {
        this.colunaDefinicao = null;
        this.colunaDefinicaoTemp = null;
        this.colunas = null;
        this.colunasRemovidas = null;
        this.uiGridConstantes = null;
        this.acao = null;
        this.uiGridConstantes = ctrl.uiGridConstantes;
        this.colunas = Object.getOwnPropertyNames(ctrl.modelo);
        this.colunaDefinicao = [];
        this.colunaDefinicaoTemp = [];
        this.colunasRemovidas = [];
        this.removerColuna('_id');
        this.removerColuna('id');
        const podeAlterar = ((typeof globalThis.permissoes !== 'undefined') && globalThis.permissoes &&
            (typeof globalThis.permissoes.alterar !== 'undefined') && globalThis.permissoes.alterar ? true : false);
        let acoes = {
            name: 'acao',
            displayName: 'Ações',
            visible: true,
            width: 70,
            enableFiltering: false,
            enableSorting: false,
            cellClass: 'text-center'
        };
        acoes.cellTemplate = `
                        <small ng-if='row.entity.syncLocal' class='acao-info badge badge-pill badge-danger'>
                            <i class='bx bx-wifi-off'></i>
                        </small>
                        <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                        <button type="button" class="btn btn-icon bx bx-dots-vertical-rounded" uib-dropdown-toggle></button>                            
                        <ul class="dropdown-menu dropdown-menu-left" uib-dropdown-menu role="menu">
                            <li class="dropdown-item" role="menuitem"><a ui-sref='${ctrl.links.VISUALIZAR.rota}({ id : row.entity.id || row.entity.uuid})' class="text-secondary w-100"><i class="bx bx-show mr-1 ml-1"></i>Visualizar</a></li>
                            ` + (podeAlterar ?
            `<li class="dropdown-item" role="menuitem"><a ui-sref='${ctrl.links.EDITAR.rota}({ id : row.entity.id || row.entity.uuid})' class="text-secondary w-100"><i class="bx bxs-edit-alt mr-1 ml-1"></i>Editar</a></li>` :
            ``) + `
                        </ul>
                    </div> `;
        this.acao = acoes;
    }
    adicionarColuna(column) {
        column.cellTemplate = column.cellTemplate ? column.cellTemplate : `
            <div style="min-height: 100%;" ng-click="grid.appScope.getParentController().cellClicked(row)">
                <div class="ui-grid-cell-contents" title="TOOLTIP">
                    {{COL_FIELD CUSTOM_FILTERS}}
                </div>
            </div>`;
        this.colunaDefinicaoTemp.push(column);
        return this;
    }
    customizarAcao(acoes) {
        acoes.name = 'acao';
        acoes.displayName = 'Ações';
        acoes.visible = true;
        acoes.posicao = 0;
        acoes.width = acoes.width ? acoes.width : 90;
        acoes.enableFiltering = false;
        acoes.enableSorting = false;
        acoes.cellTemplate = acoes.cellTemplate ? acoes.cellTemplate : '';
        this.acao = acoes;
        return this;
    }
    removerAcao() {
        this.acao = null;
        return this;
    }
    removerColuna(coluna) {
        this.colunasRemovidas.push(coluna);
        return this;
    }
    removerColunas(colunas) {
        this.colunasRemovidas.push(...colunas);
        this.colunasRemovidas.push('_id');
        return this;
    }
    removerTodasColunas() {
        this.colunas = [];
        this.colunasRemovidas = [];
        this.colunaDefinicao = [];
        this.colunaDefinicaoTemp = [];
        return this;
    }
    get $colunas() {
        if (this.acao) {
            this.colunaDefinicao.push(this.acao);
        }
        this.colunas.forEach((coluna) => {
            const ehColunaRemovida = this.colunasRemovidas.indexOf(coluna) > 0;
            if (ehColunaRemovida) {
                return;
            }
            const temColuna = this.colunaDefinicaoTemp.filter((def) => coluna === def.name).length > 0;
            if (!temColuna) {
                let col = {};
                col.name = coluna;
                col.displayName = coluna;
                col.visible = true;
                if (coluna === 'status') {
                    return;
                }
                switch (coluna) {
                    case 'criacao':
                        col.displayName = 'Desde';
                        col.cellFilter = 'date:"dd/MM/yyyy HH:mm:ss"';
                        break;
                    case 'alteracao':
                        col.displayName = 'Atualizado';
                        col.cellFilter = 'date:"dd/MM/yyyy HH:mm:ss"';
                        break;
                    case 'nome':
                        col.displayName = 'Nome';
                        break;
                    case 'aniversario':
                        col.displayName = 'Aniversário';
                        break;
                    case 'status':
                        col.displayName = 'Ativo';
                        col.visible = false;
                        col.filter = {
                            type: this.uiGridConstantes.filter.SELECT,
                            selectOptions: [{ label: 'Sim', value: 'true' }, { label: 'Não', value: 'false' }]
                        };
                        col.cellFilter = 'boolean';
                        break;
                    case 'observacao':
                        col.displayName = 'Observação';
                        break;
                }
                this.colunaDefinicaoTemp.push(col);
            }
        });
        const posicoes = this.colunaDefinicaoTemp.filter((x) => typeof x.posicao !== 'undefined');
        posicoes.forEach((coluna) => {
            this.colunaDefinicao[coluna.posicao - 1] = coluna;
        });
        const ultimos = this.colunaDefinicaoTemp.filter((x) => typeof x.posicao === 'undefined');
        ultimos.forEach((coluna) => {
            this.colunaDefinicao.push(coluna);
        });
        return this.colunaDefinicao.filter(x => x.name && (x.name !== '_id'));
    }
}
exports.UiGridConstrutor = UiGridConstrutor;
