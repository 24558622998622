"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabGerminacaoPolen = void 0;
const Base_1 = require("./Base");
class LabGerminacaoPolen extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.data = null;
        this.estoque = null;
        this.pomar = null;
        this.genero = null;
        this.especie = null;
        this.materialGenetico = null;
        this.amostras = [];
        this.germinacaoFinal = null;
    }
}
exports.LabGerminacaoPolen = LabGerminacaoPolen;
LabGerminacaoPolen.ESTOQUE_OPERACIONAL = 'Operacional';
LabGerminacaoPolen.ESTOQUE_PESQUISA = 'Pesquisa';
