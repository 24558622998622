"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlanejamentoPomar = void 0;
const Base_1 = require("./Base");
class PlanejamentoPomar extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.id = null;
        this.codigo = null;
        this.mes = null;
        this.ano = null;
        this.pomar = null;
        this.pomarCodigo = null;
        this.pomarDescricao = null;
        this.regiao = null;
        this.talhao = null;
        this.operacao = null;
        this.materiaisGeneticos = [];
        this.ehTodosMateriaisGeneticos = null;
        this.anexos = [];
        this.observacoes = null;
        this.descricaoResumida = null;
        this.descricaoDetalhada = null;
        this.usuarioInclusao = null;
        this.dataHoraInclusao = null;
        this.usuarioAlteracao = null;
        this.dataHoraAlteracao = null;
        this.mesDescricao = null;
        this.mesSigla = null;
    }
}
exports.PlanejamentoPomar = PlanejamentoPomar;
