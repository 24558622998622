"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TesteCampoAnaliseSelecaoUtil = void 0;
const angular = require("angular");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const AnaliseSelecaoTesteGenetico_1 = require("../../../../../modelos/AnaliseSelecaoTesteGenetico");
const SumarioAnaliseSelecaoTesteGenetico_1 = require("../../../../../modelos/SumarioAnaliseSelecaoTesteGenetico");
const PlanilhaSelegenAnaliseSelecaoTesteGenetico_1 = require("../../../../../modelos/PlanilhaSelegenAnaliseSelecaoTesteGenetico");
const AnaliseSelegenAnaliseSelecaoTesteGenetico_1 = require("../../../../../modelos/AnaliseSelegenAnaliseSelecaoTesteGenetico");
const ResultadoSelegenAnaliseSelecaoTesteGenetico_1 = require("../../../../../modelos/ResultadoSelegenAnaliseSelecaoTesteGenetico");
const AnaliseAnoAnaliseSelecaoTesteGenetico_1 = require("../../../../../modelos/AnaliseAnoAnaliseSelecaoTesteGenetico");
const AnaliseBlupAnaliseSelecaoTesteGenetico_1 = require("../../../../../modelos/AnaliseBlupAnaliseSelecaoTesteGenetico");
const SelecaoGeneticaAnaliseSelecaoTesteGenetico_1 = require("../../../../../modelos/SelecaoGeneticaAnaliseSelecaoTesteGenetico");
const SelecaoCampoAnaliseSelecaoTesteGenetico_1 = require("../../../../../modelos/SelecaoCampoAnaliseSelecaoTesteGenetico");
const AnexoAnaliseSelecaoTesteGenetico_1 = require("../../../../../modelos/AnexoAnaliseSelecaoTesteGenetico");
const Planilha_1 = require("../../../../../modelos/Planilha");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const DispositivoUtil_1 = require("../../../../../util/DispositivoUtil");
const LuckysheetUtil_1 = require("../../../../../util/LuckysheetUtil");
const MenuUtil_1 = require("../../../../../util/MenuUtil");
const NumeroUtil_1 = require("../../../../../util/NumeroUtil");
const DataUtil_1 = require("../../../../../util/DataUtil");
const TextoUtil_1 = require("../../../../../util/TextoUtil");
const sweetalert2_1 = require("sweetalert2");
class TesteCampoAnaliseSelecaoUtil {
    static importarAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.analiseSelecaoTesteGeneticoImportacao = true;
            ctrl.analiseSelecaoTesteGeneticoImportacaoAnexos = new Array();
            ctrl.analiseSelecaoTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static finalizarImportacaoAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
                if ((typeof ctrl.analiseSelecaoTesteGeneticoImportacaoAnexos !== 'undefined') && ctrl.analiseSelecaoTesteGeneticoImportacaoAnexos &&
                    (ctrl.analiseSelecaoTesteGeneticoImportacaoAnexos.length > 0)) {
                    if ((typeof ctrl.analiseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.analiseSelecaoTesteGeneticoSelecionado) {
                        ctrl.analiseSelecaoTesteGeneticoSelecionado = new AnaliseSelecaoTesteGenetico_1.AnaliseSelecaoTesteGenetico();
                    }
                    if ((typeof ctrl.analiseSelecaoTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.analiseSelecaoTesteGeneticoSelecionado.planilhas) {
                        ctrl.analiseSelecaoTesteGeneticoSelecionado.planilhas = new Array();
                    }
                    if ((typeof ctrl.analiseSelecaoTesteGeneticoSelecionado.anexos === 'undefined') || !ctrl.analiseSelecaoTesteGeneticoSelecionado.anexos) {
                        ctrl.analiseSelecaoTesteGeneticoSelecionado.anexos = new Array();
                    }
                    let anexoSelecionado = null;
                    angular.forEach(ctrl.analiseSelecaoTesteGeneticoImportacaoAnexos, (anexo) => {
                        ctrl.analiseSelecaoTesteGeneticoSelecionado.anexos.push(anexo);
                        anexoSelecionado = anexo;
                    });
                    yield ctrl.limparPlanilhasCarregadas();
                    yield ctrl.carregarPlanilha('planilha_analise_selecao_teste_genetico', null, anexoSelecionado);
                    setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                        ctrl.salvarAnaliseSelecaoTesteGenetico(true);
                    }), 1500, ctrl);
                }
                ctrl.analiseSelecaoTesteGeneticoImportacao = false;
                ctrl.analiseSelecaoTesteGeneticoImportacaoAnexos = null;
                ctrl.analiseSelecaoTesteGeneticoHistorico = false;
                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static cancelarImportacaoAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.analiseSelecaoTesteGeneticoImportacao = false;
            ctrl.analiseSelecaoTesteGeneticoImportacaoAnexos = null;
            ctrl.analiseSelecaoTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static exportarAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                return;
            }
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
            try {
                if ((typeof globalThis.luckysheet === 'undefined') || !globalThis.luckysheet) {
                    throw new Error('Plugin "luckysheet" não carregado.');
                }
                LuckysheetUtil_1.LuckysheetUtil.exportXLSX(globalThis.luckysheet);
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    static historicoAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.analiseSelecaoTesteGeneticoImportacao = false;
            ctrl.analiseSelecaoTesteGeneticoImportacaoAnexos = null;
            ctrl.analiseSelecaoTesteGeneticoHistorico = true;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static selecionarHistoricoAnaliseSelecaoTesteGenetico(ctrl, keyPlanilha) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                return;
            }
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
            try {
                if ((typeof ctrl.analiseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.analiseSelecaoTesteGeneticoSelecionado) {
                    ctrl.analiseSelecaoTesteGeneticoSelecionado = new AnaliseSelecaoTesteGenetico_1.AnaliseSelecaoTesteGenetico();
                }
                if ((typeof ctrl.analiseSelecaoTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.analiseSelecaoTesteGeneticoSelecionado.planilhas) {
                    ctrl.analiseSelecaoTesteGeneticoSelecionado.planilhas = new Array();
                }
                if ((typeof keyPlanilha !== 'undefined') && (keyPlanilha !== null) && (keyPlanilha >= 0) &&
                    (keyPlanilha <= ctrl.analiseSelecaoTesteGeneticoSelecionado.planilhas.length - 1)) {
                    const planilhaSelecionada = ctrl.analiseSelecaoTesteGeneticoSelecionado.planilhas[keyPlanilha];
                    ctrl.analiseSelecaoTesteGeneticoSelecionado.planilhaSelecionada = planilhaSelecionada;
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_SELECAO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANALISE_SELECAO_TESTE_GENETICO)
                        .atualizar(ctrl.analiseSelecaoTesteGeneticoSelecionado);
                    yield ctrl.limparPlanilhasCarregadas();
                    setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                        ctrl.exibirModalAnaliseSelecaoTesteGenetico();
                    }), 500, ctrl);
                    ctrl.analiseSelecaoTesteGeneticoImportacao = false;
                    ctrl.analiseSelecaoTesteGeneticoImportacaoAnexos = null;
                    ctrl.analiseSelecaoTesteGeneticoHistorico = false;
                }
                else {
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.notificacao.alerta('Não foi possível carregar a planilha.', 'A planilha selecionada não é válida ou foi removida.');
                    return;
                }
                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    static cancelarHistoricoAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.analiseSelecaoTesteGeneticoImportacao = false;
            ctrl.analiseSelecaoTesteGeneticoImportacaoAnexos = null;
            ctrl.analiseSelecaoTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static salvarAnaliseSelecaoTesteGenetico(ctrl, ehImportacao = false) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof ctrl.testeGeneticoSelecionado === 'undefined') || !ctrl.testeGeneticoSelecionado) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
                if ((typeof ctrl.analiseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.analiseSelecaoTesteGeneticoSelecionado) {
                    ctrl.analiseSelecaoTesteGeneticoSelecionado = new AnaliseSelecaoTesteGenetico_1.AnaliseSelecaoTesteGenetico();
                }
                if ((typeof ctrl.analiseSelecaoTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.analiseSelecaoTesteGeneticoSelecionado.planilhas) {
                    ctrl.analiseSelecaoTesteGeneticoSelecionado.planilhas = new Array();
                }
                let planilha = new Planilha_1.Planilha();
                planilha.status = true;
                planilha.modulo = 'AnaliseSelecaoTesteGenetico';
                planilha.operacao = ehImportacao ? 'Importar' : 'Salvar';
                planilha.nome = LuckysheetUtil_1.LuckysheetUtil.nomePlanilha();
                planilha.usuarioInclusao = ctrl.usuarioSimplificado();
                planilha.dataHoraInclusao = new Date();
                planilha.gzcompress = true;
                planilha.dados = LuckysheetUtil_1.LuckysheetUtil.compactarDadosPlanilha();
                planilha = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANILHA)
                    .criar(planilha);
                ctrl.analiseSelecaoTesteGeneticoSelecionado.planilhas.push(planilha);
                ctrl.analiseSelecaoTesteGeneticoSelecionado.planilhaSelecionada = planilha;
                yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_SELECAO_TESTE_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANALISE_SELECAO_TESTE_GENETICO)
                    .atualizar(ctrl.analiseSelecaoTesteGeneticoSelecionado);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static exibirModalAnaliseSelecaoTesteGenetico(ctrl, dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.testeGeneticoSelecionado === 'undefined') || !ctrl.testeGeneticoSelecionado) {
                return;
            }
            try {
                ctrl.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                ctrl.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                ctrl.analiseSelecaoTesteGeneticoVisualizar = true;
                if ((typeof dados !== 'undefined') && dados && (typeof dados.id !== 'undefined') && dados.id) {
                    ctrl.analiseSelecaoTesteGeneticoSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_SELECAO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANALISE_SELECAO_TESTE_GENETICO)
                        .id(dados.id)
                        .ver();
                    if ((typeof ctrl.analiseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.analiseSelecaoTesteGeneticoSelecionado) {
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        if (ctrl.ehCelular) {
                            alert(`Registro "${dados.id} não encontrado!`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                text: `Registro "${dados.id}" não encontrado.`,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        return;
                    }
                    if ((typeof ctrl.analiseSelecaoTesteGeneticoSelecionado.criacao === 'string') && ctrl.analiseSelecaoTesteGeneticoSelecionado.criacao) {
                        ctrl.analiseSelecaoTesteGeneticoSelecionado.criacao = new Date(ctrl.analiseSelecaoTesteGeneticoSelecionado.criacao);
                    }
                    if ((typeof ctrl.analiseSelecaoTesteGeneticoSelecionado.dataHoraInclusao === 'string') && ctrl.analiseSelecaoTesteGeneticoSelecionado.dataHoraInclusao) {
                        ctrl.analiseSelecaoTesteGeneticoSelecionado.dataHoraInclusao = new Date(ctrl.analiseSelecaoTesteGeneticoSelecionado.dataHoraInclusao);
                    }
                    if ((typeof ctrl.analiseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao === 'string') && ctrl.analiseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao) {
                        ctrl.analiseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao = new Date(ctrl.analiseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao);
                    }
                    if ((typeof dados.visualizar !== 'undefined') && dados.visualizar) {
                        ctrl.analiseSelecaoTesteGeneticoVisualizar = true;
                    }
                    else if ((typeof dados.editar !== 'undefined') && dados.editar) {
                        ctrl.analiseSelecaoTesteGeneticoVisualizar = false;
                    }
                    else if ((typeof dados.excluir !== 'undefined') && dados.excluir) {
                        ctrl.notificacao
                            .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                            .then((result) => __awaiter(this, void 0, void 0, function* () {
                            if (result.value) {
                                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_SELECAO_TESTE_GENETICO)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANALISE_SELECAO_TESTE_GENETICO)
                                    .id(dados.id)
                                    .remover();
                                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                                ctrl.listaOpcoesAnaliseSelecaoTesteGenetico = yield ctrl.dadosListaAnaliseSelecaoTesteGenetico();
                                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
                            }
                        }));
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                }
                else {
                    ctrl.analiseSelecaoTesteGeneticoSelecionado = new AnaliseSelecaoTesteGenetico_1.AnaliseSelecaoTesteGenetico();
                    ctrl.analiseSelecaoTesteGeneticoSelecionado.status = true;
                    ctrl.analiseSelecaoTesteGeneticoSelecionado.criacao = new Date();
                    ctrl.analiseSelecaoTesteGeneticoSelecionado.criacao.setMilliseconds(0);
                    ctrl.analiseSelecaoTesteGeneticoSelecionado.testeGenetico = ctrl.testeGeneticoSimplificado();
                    ctrl.analiseSelecaoTesteGeneticoSelecionado.anoAnalise = ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                        (typeof ctrl.testeGeneticoSelecionado.anoAnalise !== 'undefined') && ctrl.testeGeneticoSelecionado.anoAnalise
                        ? ctrl.testeGeneticoSelecionado.anoAnalise : null);
                    ctrl.analiseSelecaoTesteGeneticoSelecionado.dataHoraInclusao = new Date();
                    ctrl.analiseSelecaoTesteGeneticoSelecionado.dataHoraInclusao.setMilliseconds(0);
                    ctrl.analiseSelecaoTesteGeneticoSelecionado.usuarioInclusao = ctrl.usuarioSimplificado();
                    ctrl.analiseSelecaoTesteGeneticoVisualizar = false;
                }
                ctrl.modalAnaliseSelecaoTesteGenetico = true;
                angular.element('body').css('overflow-y', 'hidden');
                MenuUtil_1.MenuUtil.ajustaZIndexMenusModalPlanilha();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.scopeApply(() => {
                    MenuUtil_1.MenuUtil.ajustaZIndexMenusModalPlanilha();
                });
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static ocultarModalAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.modalAnaliseSelecaoTesteGenetico = false;
            ctrl.limparPlanilhasCarregadas();
            angular.element('body').css('overflow-y', '');
            MenuUtil_1.MenuUtil.restauraZIndexMenus();
            globalThis.posicaoTopoAntesModal = ctrl.posicaoTopoAntesModal;
            ctrl.scopeApply(() => {
                MenuUtil_1.MenuUtil.restauraZIndexMenus();
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                        (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    static importarSumarioAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.sumarioAnaliseSelecaoTesteGeneticoImportacao = true;
            ctrl.sumarioAnaliseSelecaoTesteGeneticoImportacaoAnexos = new Array();
            ctrl.sumarioAnaliseSelecaoTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static finalizarImportacaoSumarioAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
                if ((typeof ctrl.sumarioAnaliseSelecaoTesteGeneticoImportacaoAnexos !== 'undefined') && ctrl.sumarioAnaliseSelecaoTesteGeneticoImportacaoAnexos &&
                    (ctrl.sumarioAnaliseSelecaoTesteGeneticoImportacaoAnexos.length > 0)) {
                    if ((typeof ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado) {
                        ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado = new SumarioAnaliseSelecaoTesteGenetico_1.SumarioAnaliseSelecaoTesteGenetico();
                    }
                    if ((typeof ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.planilhas) {
                        ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.planilhas = new Array();
                    }
                    if ((typeof ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.anexos === 'undefined') || !ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.anexos) {
                        ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.anexos = new Array();
                    }
                    let anexoSelecionado = null;
                    angular.forEach(ctrl.sumarioAnaliseSelecaoTesteGeneticoImportacaoAnexos, (anexo) => {
                        ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.anexos.push(anexo);
                        anexoSelecionado = anexo;
                    });
                    yield ctrl.limparPlanilhasCarregadas();
                    yield ctrl.carregarPlanilha('planilha_sumario_analise_selecao_teste_genetico', null, anexoSelecionado);
                    setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                        ctrl.salvarSumarioAnaliseSelecaoTesteGenetico(true);
                    }), 1500, ctrl);
                }
                ctrl.sumarioAnaliseSelecaoTesteGeneticoImportacao = false;
                ctrl.sumarioAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
                ctrl.sumarioAnaliseSelecaoTesteGeneticoHistorico = false;
                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static cancelarImportacaoSumarioAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.sumarioAnaliseSelecaoTesteGeneticoImportacao = false;
            ctrl.sumarioAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
            ctrl.sumarioAnaliseSelecaoTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static exportarSumarioAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                return;
            }
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
            try {
                if ((typeof globalThis.luckysheet === 'undefined') || !globalThis.luckysheet) {
                    throw new Error('Plugin "luckysheet" não carregado.');
                }
                LuckysheetUtil_1.LuckysheetUtil.exportXLSX(globalThis.luckysheet);
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    static historicoSumarioAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.sumarioAnaliseSelecaoTesteGeneticoImportacao = false;
            ctrl.sumarioAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
            ctrl.sumarioAnaliseSelecaoTesteGeneticoHistorico = true;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static selecionarHistoricoSumarioAnaliseSelecaoTesteGenetico(ctrl, keyPlanilha) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                return;
            }
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
            try {
                if ((typeof ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado) {
                    ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado = new SumarioAnaliseSelecaoTesteGenetico_1.SumarioAnaliseSelecaoTesteGenetico();
                }
                if ((typeof ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.planilhas) {
                    ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.planilhas = new Array();
                }
                if ((typeof keyPlanilha !== 'undefined') && (keyPlanilha !== null) && (keyPlanilha >= 0) &&
                    (keyPlanilha <= ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.planilhas.length - 1)) {
                    const planilhaSelecionada = ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.planilhas[keyPlanilha];
                    ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada = planilhaSelecionada;
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.SUMARIO_ANALISE_SELECAO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.SUMARIO_ANALISE_SELECAO_TESTE_GENETICO)
                        .atualizar(ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado);
                    yield ctrl.limparPlanilhasCarregadas();
                    setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                        ctrl.exibirModalSumarioAnaliseSelecaoTesteGenetico();
                    }), 500, ctrl);
                    ctrl.sumarioAnaliseSelecaoTesteGeneticoImportacao = false;
                    ctrl.sumarioAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
                    ctrl.sumarioAnaliseSelecaoTesteGeneticoHistorico = false;
                }
                else {
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.notificacao.alerta('Não foi possível carregar a planilha.', 'A planilha selecionada não é válida ou foi removida.');
                    return;
                }
                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    static cancelarHistoricoSumarioAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.sumarioAnaliseSelecaoTesteGeneticoImportacao = false;
            ctrl.sumarioAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
            ctrl.sumarioAnaliseSelecaoTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static salvarSumarioAnaliseSelecaoTesteGenetico(ctrl, ehImportacao = false) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof ctrl.testeGeneticoSelecionado === 'undefined') || !ctrl.testeGeneticoSelecionado) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
                if ((typeof ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado) {
                    ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado = new SumarioAnaliseSelecaoTesteGenetico_1.SumarioAnaliseSelecaoTesteGenetico();
                }
                if ((typeof ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.planilhas) {
                    ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.planilhas = new Array();
                }
                let planilha = new Planilha_1.Planilha();
                planilha.status = true;
                planilha.modulo = 'SumarioAnaliseSelecaoTesteGenetico';
                planilha.operacao = ehImportacao ? 'Importar' : 'Salvar';
                planilha.nome = LuckysheetUtil_1.LuckysheetUtil.nomePlanilha();
                planilha.usuarioInclusao = ctrl.usuarioSimplificado();
                planilha.dataHoraInclusao = new Date();
                planilha.gzcompress = true;
                planilha.dados = LuckysheetUtil_1.LuckysheetUtil.compactarDadosPlanilha();
                planilha = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANILHA)
                    .criar(planilha);
                ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.planilhas.push(planilha);
                ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada = planilha;
                yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.SUMARIO_ANALISE_SELECAO_TESTE_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.SUMARIO_ANALISE_SELECAO_TESTE_GENETICO)
                    .atualizar(ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static exibirModalSumarioAnaliseSelecaoTesteGenetico(ctrl, dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.testeGeneticoSelecionado === 'undefined') || !ctrl.testeGeneticoSelecionado) {
                return;
            }
            try {
                ctrl.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                ctrl.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                ctrl.sumarioAnaliseSelecaoTesteGeneticoVisualizar = true;
                ctrl.sumarioAnaliseSelecaoTesteGeneticoImportacao = false;
                ctrl.sumarioAnaliseSelecaoTesteGeneticoHistorico = false;
                if ((typeof dados === 'undefined') || !dados || (typeof dados.id === 'undefined') || !dados.id) {
                    let lista = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.SUMARIO_ANALISE_SELECAO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.SUMARIO_ANALISE_SELECAO_TESTE_GENETICO)
                        .filtro({
                        listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                        testeGenetico: ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                            (typeof ctrl.testeGeneticoSelecionado.id !== 'undefined') && ctrl.testeGeneticoSelecionado.id
                            ? ctrl.testeGeneticoSelecionado.id : null),
                        anoAnalise: ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                            (typeof ctrl.testeGeneticoSelecionado.anoAnalise !== 'undefined') && ctrl.testeGeneticoSelecionado.anoAnalise
                            ? ctrl.testeGeneticoSelecionado.anoAnalise : null)
                    })
                        .listar();
                    if ((typeof lista !== 'undefined') && lista) {
                        dados = { id: null, editar: true };
                        angular.forEach(lista, (item) => {
                            if ((typeof item.id !== 'undefined') && item.id) {
                                dados.id = item.id;
                            }
                        });
                    }
                }
                if ((typeof dados !== 'undefined') && dados && (typeof dados.id !== 'undefined') && dados.id) {
                    ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.SUMARIO_ANALISE_SELECAO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.SUMARIO_ANALISE_SELECAO_TESTE_GENETICO)
                        .id(dados.id)
                        .ver();
                    if ((typeof ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado) {
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        if (ctrl.ehCelular) {
                            alert(`Registro "${dados.id} não encontrado!`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                text: `Registro "${dados.id}" não encontrado.`,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        return;
                    }
                    if ((typeof ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.criacao === 'string') && ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.criacao) {
                        ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.criacao = new Date(ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.criacao);
                    }
                    if ((typeof ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao === 'string') && ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao) {
                        ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao = new Date(ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao);
                    }
                    if ((typeof ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao === 'string') && ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao) {
                        ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao = new Date(ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao);
                    }
                    if ((typeof dados.visualizar !== 'undefined') && dados.visualizar) {
                        ctrl.sumarioAnaliseSelecaoTesteGeneticoVisualizar = true;
                    }
                    else if ((typeof dados.editar !== 'undefined') && dados.editar) {
                        ctrl.sumarioAnaliseSelecaoTesteGeneticoVisualizar = false;
                    }
                    else if ((typeof dados.excluir !== 'undefined') && dados.excluir) {
                        ctrl.notificacao
                            .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                            .then((result) => __awaiter(this, void 0, void 0, function* () {
                            if (result.value) {
                                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.SUMARIO_ANALISE_SELECAO_TESTE_GENETICO)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.SUMARIO_ANALISE_SELECAO_TESTE_GENETICO)
                                    .id(dados.id)
                                    .remover();
                                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                                ctrl.listaOpcoesAnaliseSelecaoTesteGenetico = yield ctrl.dadosListaAnaliseSelecaoTesteGenetico();
                                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
                            }
                        }));
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                }
                else {
                    ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado = new SumarioAnaliseSelecaoTesteGenetico_1.SumarioAnaliseSelecaoTesteGenetico();
                    ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.status = true;
                    ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.criacao = new Date();
                    ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.criacao.setMilliseconds(0);
                    ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.testeGenetico = ctrl.testeGeneticoSimplificado();
                    ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.anoAnalise = ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                        (typeof ctrl.testeGeneticoSelecionado.anoAnalise !== 'undefined') && ctrl.testeGeneticoSelecionado.anoAnalise
                        ? ctrl.testeGeneticoSelecionado.anoAnalise : null);
                    ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao = new Date();
                    ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao.setMilliseconds(0);
                    ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.usuarioInclusao = ctrl.usuarioSimplificado();
                    ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.SUMARIO_ANALISE_SELECAO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.SUMARIO_ANALISE_SELECAO_TESTE_GENETICO)
                        .criar(ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado);
                    ctrl.sumarioAnaliseSelecaoTesteGeneticoVisualizar = false;
                }
                if ((typeof ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.anexos === 'undefined') || (ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.anexos === null)) {
                    ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.anexos = new Array();
                }
                ctrl.listaOpcoesSumarioAnaliseSelecaoTesteGenetico = yield ctrl.dadosListaSumarioAnaliseSelecaoTesteGenetico();
                ctrl.limparPlanilhasCarregadas();
                let planilhaId = null;
                let dadosPlanilha = [];
                let dadosAnexo = null;
                if ((typeof ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada !== 'undefined') && ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada &&
                    (typeof ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada.id !== 'undefined') && ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada.id) {
                    planilhaId = ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada.id;
                }
                else {
                    if ((typeof ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.planilhas !== 'undefined') && ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.planilhas) {
                        angular.forEach(ctrl.sumarioAnaliseSelecaoTesteGeneticoSelecionado.planilhas, (planilha) => {
                            if ((typeof planilha !== 'undefined') && planilha && (typeof planilha.id !== 'undefined') && planilha.id) {
                                planilhaId = planilha.id;
                            }
                        });
                    }
                }
                if ((typeof planilhaId !== 'undefined') && planilhaId) {
                    let planilha = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANILHA)
                        .id(planilhaId)
                        .ver();
                    if ((typeof planilha !== 'undefined') && planilha &&
                        (typeof planilha.dados !== 'undefined') && planilha.dados) {
                        dadosPlanilha = LuckysheetUtil_1.LuckysheetUtil.descompactarDadosPlanilha(planilha.dados);
                    }
                }
                ctrl.carregarPlanilha('planilha_sumario_analise_selecao_teste_genetico', dadosPlanilha, dadosAnexo);
                ctrl.modalSumarioAnaliseSelecaoTesteGenetico = true;
                angular.element('body').css('overflow-y', 'hidden');
                MenuUtil_1.MenuUtil.ajustaZIndexMenusModalPlanilha();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.scopeApply(() => {
                    MenuUtil_1.MenuUtil.ajustaZIndexMenusModalPlanilha();
                });
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static ocultarModalSumarioAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.modalSumarioAnaliseSelecaoTesteGenetico = false;
            ctrl.limparPlanilhasCarregadas();
            angular.element('body').css('overflow-y', '');
            MenuUtil_1.MenuUtil.restauraZIndexMenus();
            globalThis.posicaoTopoAntesModal = ctrl.posicaoTopoAntesModal;
            ctrl.scopeApply(() => {
                MenuUtil_1.MenuUtil.restauraZIndexMenus();
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                        (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    static importarPlanilhaSelegenAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoImportacao = true;
            ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoImportacaoAnexos = new Array();
            ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static finalizarImportacaoPlanilhaSelegenAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
                if ((typeof ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoImportacaoAnexos !== 'undefined') && ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoImportacaoAnexos &&
                    (ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoImportacaoAnexos.length > 0)) {
                    if ((typeof ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado) {
                        ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado = new PlanilhaSelegenAnaliseSelecaoTesteGenetico_1.PlanilhaSelegenAnaliseSelecaoTesteGenetico();
                    }
                    if ((typeof ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas) {
                        ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas = new Array();
                    }
                    if ((typeof ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.anexos === 'undefined') || !ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.anexos) {
                        ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.anexos = new Array();
                    }
                    let anexoSelecionado = null;
                    angular.forEach(ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoImportacaoAnexos, (anexo) => {
                        ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.anexos.push(anexo);
                        anexoSelecionado = anexo;
                    });
                    yield ctrl.limparPlanilhasCarregadas();
                    yield ctrl.carregarPlanilha('planilha_selegen_analise_selecao_teste_genetico', null, anexoSelecionado);
                    setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                        ctrl.salvarPlanilhaSelegenAnaliseSelecaoTesteGenetico(true);
                    }), 1500, ctrl);
                }
                ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoImportacao = false;
                ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
                ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoHistorico = false;
                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static cancelarImportacaoPlanilhaSelegenAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoImportacao = false;
            ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
            ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static exportarPlanilhaSelegenAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                return;
            }
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
            try {
                if ((typeof globalThis.luckysheet === 'undefined') || !globalThis.luckysheet) {
                    throw new Error('Plugin "luckysheet" não carregado.');
                }
                LuckysheetUtil_1.LuckysheetUtil.exportXLSX(globalThis.luckysheet);
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    static historicoPlanilhaSelegenAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoImportacao = false;
            ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
            ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoHistorico = true;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static selecionarHistoricoPlanilhaSelegenAnaliseSelecaoTesteGenetico(ctrl, keyPlanilha) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                return;
            }
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
            try {
                if ((typeof ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado) {
                    ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado = new PlanilhaSelegenAnaliseSelecaoTesteGenetico_1.PlanilhaSelegenAnaliseSelecaoTesteGenetico();
                }
                if ((typeof ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas) {
                    ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas = new Array();
                }
                if ((typeof keyPlanilha !== 'undefined') && (keyPlanilha !== null) && (keyPlanilha >= 0) &&
                    (keyPlanilha <= ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas.length - 1)) {
                    const planilhaSelecionada = ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas[keyPlanilha];
                    ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada = planilhaSelecionada;
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANILHA_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                        .atualizar(ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado);
                    yield ctrl.limparPlanilhasCarregadas();
                    setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                        ctrl.exibirModalPlanilhaSelegenAnaliseSelecaoTesteGenetico();
                    }), 500, ctrl);
                    ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoImportacao = false;
                    ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
                    ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoHistorico = false;
                }
                else {
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.notificacao.alerta('Não foi possível carregar a planilha.', 'A planilha selecionada não é válida ou foi removida.');
                    return;
                }
                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    static cancelarHistoricoPlanilhaSelegenAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoImportacao = false;
            ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
            ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static salvarPlanilhaSelegenAnaliseSelecaoTesteGenetico(ctrl, ehImportacao = false) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof ctrl.testeGeneticoSelecionado === 'undefined') || !ctrl.testeGeneticoSelecionado) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
                if ((typeof ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado) {
                    ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado = new PlanilhaSelegenAnaliseSelecaoTesteGenetico_1.PlanilhaSelegenAnaliseSelecaoTesteGenetico();
                }
                if ((typeof ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas) {
                    ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas = new Array();
                }
                let planilha = new Planilha_1.Planilha();
                planilha.status = true;
                planilha.modulo = 'PlanilhaSelegenAnaliseSelecaoTesteGenetico';
                planilha.operacao = ehImportacao ? 'Importar' : 'Salvar';
                planilha.nome = LuckysheetUtil_1.LuckysheetUtil.nomePlanilha();
                planilha.usuarioInclusao = ctrl.usuarioSimplificado();
                planilha.dataHoraInclusao = new Date();
                planilha.gzcompress = true;
                planilha.dados = LuckysheetUtil_1.LuckysheetUtil.compactarDadosPlanilha();
                planilha = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANILHA)
                    .criar(planilha);
                ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas.push(planilha);
                ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada = planilha;
                yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANILHA_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                    .atualizar(ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static exibirModalPlanilhaSelegenAnaliseSelecaoTesteGenetico(ctrl, dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.testeGeneticoSelecionado === 'undefined') || !ctrl.testeGeneticoSelecionado) {
                return;
            }
            try {
                ctrl.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                ctrl.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoVisualizar = true;
                ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoImportacao = false;
                ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoHistorico = false;
                if ((typeof dados === 'undefined') || !dados || (typeof dados.id === 'undefined') || !dados.id) {
                    let lista = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANILHA_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                        .filtro({
                        listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                        testeGenetico: ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                            (typeof ctrl.testeGeneticoSelecionado.id !== 'undefined') && ctrl.testeGeneticoSelecionado.id
                            ? ctrl.testeGeneticoSelecionado.id : null),
                        anoAnalise: ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                            (typeof ctrl.testeGeneticoSelecionado.anoAnalise !== 'undefined') && ctrl.testeGeneticoSelecionado.anoAnalise
                            ? ctrl.testeGeneticoSelecionado.anoAnalise : null)
                    })
                        .listar();
                    if ((typeof lista !== 'undefined') && lista) {
                        dados = { id: null, editar: true };
                        angular.forEach(lista, (item) => {
                            if ((typeof item.id !== 'undefined') && item.id) {
                                dados.id = item.id;
                            }
                        });
                    }
                }
                if ((typeof dados !== 'undefined') && dados && (typeof dados.id !== 'undefined') && dados.id) {
                    ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANILHA_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                        .id(dados.id)
                        .ver();
                    if ((typeof ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado) {
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        if (ctrl.ehCelular) {
                            alert(`Registro "${dados.id} não encontrado!`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                text: `Registro "${dados.id}" não encontrado.`,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        return;
                    }
                    if ((typeof ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.criacao === 'string') && ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.criacao) {
                        ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.criacao = new Date(ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.criacao);
                    }
                    if ((typeof ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao === 'string') && ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao) {
                        ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao = new Date(ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao);
                    }
                    if ((typeof ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao === 'string') && ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao) {
                        ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao = new Date(ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao);
                    }
                    if ((typeof dados.visualizar !== 'undefined') && dados.visualizar) {
                        ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoVisualizar = true;
                    }
                    else if ((typeof dados.editar !== 'undefined') && dados.editar) {
                        ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoVisualizar = false;
                    }
                    else if ((typeof dados.excluir !== 'undefined') && dados.excluir) {
                        ctrl.notificacao
                            .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                            .then((result) => __awaiter(this, void 0, void 0, function* () {
                            if (result.value) {
                                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANILHA_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                                    .id(dados.id)
                                    .remover();
                                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                                ctrl.listaOpcoesAnaliseSelecaoTesteGenetico = yield ctrl.dadosListaAnaliseSelecaoTesteGenetico();
                                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
                            }
                        }));
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                }
                else {
                    ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado = new PlanilhaSelegenAnaliseSelecaoTesteGenetico_1.PlanilhaSelegenAnaliseSelecaoTesteGenetico();
                    ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.status = true;
                    ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.criacao = new Date();
                    ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.criacao.setMilliseconds(0);
                    ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.testeGenetico = ctrl.testeGeneticoSimplificado();
                    ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.anoAnalise = ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                        (typeof ctrl.testeGeneticoSelecionado.anoAnalise !== 'undefined') && ctrl.testeGeneticoSelecionado.anoAnalise
                        ? ctrl.testeGeneticoSelecionado.anoAnalise : null);
                    ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao = new Date();
                    ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao.setMilliseconds(0);
                    ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.usuarioInclusao = ctrl.usuarioSimplificado();
                    ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANILHA_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                        .criar(ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado);
                    ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoVisualizar = false;
                }
                if ((typeof ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.anexos === 'undefined') || (ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.anexos === null)) {
                    ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.anexos = new Array();
                }
                ctrl.limparPlanilhasCarregadas();
                let planilhaId = null;
                let dadosPlanilha = [];
                let dadosAnexo = null;
                if ((typeof ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada !== 'undefined') && ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada &&
                    (typeof ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada.id !== 'undefined') && ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada.id) {
                    planilhaId = ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada.id;
                }
                else {
                    if ((typeof ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas !== 'undefined') && ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas) {
                        angular.forEach(ctrl.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas, (planilha) => {
                            if ((typeof planilha !== 'undefined') && planilha && (typeof planilha.id !== 'undefined') && planilha.id) {
                                planilhaId = planilha.id;
                            }
                        });
                    }
                }
                if ((typeof planilhaId !== 'undefined') && planilhaId) {
                    let planilha = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANILHA)
                        .id(planilhaId)
                        .ver();
                    if ((typeof planilha !== 'undefined') && planilha &&
                        (typeof planilha.dados !== 'undefined') && planilha.dados) {
                        dadosPlanilha = LuckysheetUtil_1.LuckysheetUtil.descompactarDadosPlanilha(planilha.dados);
                    }
                }
                ctrl.carregarPlanilha('planilha_selegen_analise_selecao_teste_genetico', dadosPlanilha, dadosAnexo);
                ctrl.modalPlanilhaSelegenAnaliseSelecaoTesteGenetico = true;
                angular.element('body').css('overflow-y', 'hidden');
                MenuUtil_1.MenuUtil.ajustaZIndexMenusModalPlanilha();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.scopeApply(() => {
                    MenuUtil_1.MenuUtil.ajustaZIndexMenusModalPlanilha();
                });
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static ocultarModalPlanilhaSelegenAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.modalPlanilhaSelegenAnaliseSelecaoTesteGenetico = false;
            ctrl.limparPlanilhasCarregadas();
            angular.element('body').css('overflow-y', '');
            MenuUtil_1.MenuUtil.restauraZIndexMenus();
            globalThis.posicaoTopoAntesModal = ctrl.posicaoTopoAntesModal;
            ctrl.scopeApply(() => {
                MenuUtil_1.MenuUtil.restauraZIndexMenus();
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                        (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    static finalizarImportacaoAnaliseSelegenAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
                if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoImportacaoAnexos !== 'undefined') && ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoImportacaoAnexos &&
                    (ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoImportacaoAnexos.length > 0)) {
                    if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado) {
                        ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado = new AnaliseSelegenAnaliseSelecaoTesteGenetico_1.AnaliseSelegenAnaliseSelecaoTesteGenetico();
                    }
                    if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas) {
                        ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas = new Array();
                    }
                    if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.anexos === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.anexos) {
                        ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.anexos = new Array();
                    }
                    let anexoSelecionado = null;
                    angular.forEach(ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoImportacaoAnexos, (anexo) => {
                        ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.anexos.push(anexo);
                        anexoSelecionado = anexo;
                    });
                    yield ctrl.limparPlanilhasCarregadas();
                    yield ctrl.carregarPlanilha('planilha_analise_selegen_analise_selecao_teste_genetico', null, anexoSelecionado);
                    setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                        ctrl.salvarAnaliseSelegenAnaliseSelecaoTesteGenetico(true);
                    }), 1500, ctrl);
                }
                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoImportacao = false;
                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoHistorico = false;
                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static cancelarImportacaoAnaliseSelegenAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoImportacao = false;
            ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
            ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static historicoAnaliseSelegenAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoImportacao = false;
            ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
            ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoHistorico = true;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static selecionarHistoricoAnaliseSelegenAnaliseSelecaoTesteGenetico(ctrl, keyPlanilha) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                return;
            }
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
            try {
                if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado) {
                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado = new AnaliseSelegenAnaliseSelecaoTesteGenetico_1.AnaliseSelegenAnaliseSelecaoTesteGenetico();
                }
                if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas) {
                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas = new Array();
                }
                if ((typeof keyPlanilha !== 'undefined') && (keyPlanilha !== null) && (keyPlanilha >= 0) &&
                    (keyPlanilha <= ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas.length - 1)) {
                    const planilhaSelecionada = ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas[keyPlanilha];
                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada = planilhaSelecionada;
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANALISE_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                        .atualizar(ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado);
                    yield ctrl.limparPlanilhasCarregadas();
                    setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                        ctrl.exibirModalAnaliseSelegenAnaliseSelecaoTesteGenetico();
                    }), 500, ctrl);
                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoImportacao = false;
                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoHistorico = false;
                }
                else {
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.notificacao.alerta('Não foi possível carregar a planilha.', 'A planilha selecionada não é válida ou foi removida.');
                    return;
                }
                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    static cancelarHistoricoAnaliseSelegenAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoImportacao = false;
            ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
            ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static salvarAnaliseSelegenAnaliseSelecaoTesteGenetico(ctrl, ehImportacao = false) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof ctrl.testeGeneticoSelecionado === 'undefined') || !ctrl.testeGeneticoSelecionado) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
                if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado) {
                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado = new AnaliseSelegenAnaliseSelecaoTesteGenetico_1.AnaliseSelegenAnaliseSelecaoTesteGenetico();
                }
                if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas) {
                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas = new Array();
                }
                let planilha = new Planilha_1.Planilha();
                planilha.status = true;
                planilha.modulo = 'AnaliseSelegenAnaliseSelecaoTesteGenetico';
                planilha.operacao = ehImportacao ? 'Importar' : 'Salvar';
                planilha.nome = LuckysheetUtil_1.LuckysheetUtil.nomePlanilha();
                planilha.usuarioInclusao = ctrl.usuarioSimplificado();
                planilha.dataHoraInclusao = new Date();
                planilha.gzcompress = true;
                planilha.dados = LuckysheetUtil_1.LuckysheetUtil.compactarDadosPlanilha();
                planilha = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANILHA)
                    .criar(planilha);
                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas.push(planilha);
                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada = planilha;
                yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANALISE_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                    .atualizar(ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static exibirModalAnaliseSelegenAnaliseSelecaoTesteGenetico(ctrl, dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.testeGeneticoSelecionado === 'undefined') || !ctrl.testeGeneticoSelecionado) {
                return;
            }
            try {
                ctrl.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                ctrl.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoVisualizar = true;
                let ehDadosTemporarios = false;
                if ((typeof ctrl.listaAnaliseSelecao !== 'undefined') && ctrl.listaAnaliseSelecao) {
                    angular.forEach(ctrl.listaAnaliseSelecao, (analiseSelecao) => {
                        if (!ehDadosTemporarios) {
                            if (((typeof analiseSelecao.tratamento !== 'undefined') && (analiseSelecao.tratamento !== null)) ||
                                ((typeof analiseSelecao.trat !== 'undefined') && (analiseSelecao.trat !== null)) ||
                                ((typeof analiseSelecao.bloco !== 'undefined') && (analiseSelecao.bloco !== null)) ||
                                ((typeof analiseSelecao.arv !== 'undefined') && (analiseSelecao.arv !== null))) {
                                ehDadosTemporarios = true;
                            }
                        }
                    });
                }
                if ((typeof dados !== 'undefined') && dados && (typeof dados.id !== 'undefined') && dados.id) {
                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANALISE_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                        .id(dados.id)
                        .ver();
                    if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado) {
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        if (ctrl.ehCelular) {
                            alert(`Registro "${dados.id} não encontrado!`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                text: `Registro "${dados.id}" não encontrado.`,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        return;
                    }
                    if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.criacao === 'string') && ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.criacao) {
                        ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.criacao = new Date(ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.criacao);
                    }
                    if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao === 'string') && ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao) {
                        ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao = new Date(ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao);
                    }
                    if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao === 'string') && ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao) {
                        ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao = new Date(ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao);
                    }
                    if ((typeof dados.visualizar !== 'undefined') && dados.visualizar) {
                        ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoVisualizar = true;
                    }
                    else if ((typeof dados.editar !== 'undefined') && dados.editar) {
                        ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoVisualizar = false;
                    }
                    else if ((typeof dados.excluir !== 'undefined') && dados.excluir) {
                        ctrl.notificacao
                            .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                            .then((result) => __awaiter(this, void 0, void 0, function* () {
                            if (result.value) {
                                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANALISE_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                                    .id(dados.id)
                                    .remover();
                                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                                ctrl.listaOpcoesAnaliseSelecaoTesteGenetico = yield ctrl.dadosListaAnaliseSelecaoTesteGenetico();
                                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
                            }
                        }));
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                }
                else {
                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado = null;
                    if (!ehDadosTemporarios) {
                        let lista = yield ctrl.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANALISE_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                            .filtro({
                            listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                            testeGenetico: ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                                (typeof ctrl.testeGeneticoSelecionado.id !== 'undefined') && ctrl.testeGeneticoSelecionado.id
                                ? ctrl.testeGeneticoSelecionado.id : null),
                            anoAnalise: ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                                (typeof ctrl.testeGeneticoSelecionado.anoAnalise !== 'undefined') && ctrl.testeGeneticoSelecionado.anoAnalise
                                ? ctrl.testeGeneticoSelecionado.anoAnalise : null)
                        })
                            .listar();
                        if ((typeof lista !== 'undefined') && lista && (lista.length > 0) &&
                            (typeof lista[0] !== 'undefined') && lista[0]) {
                            ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado = yield ctrl.sincronia
                                .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANALISE_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                                .id(lista[0].id)
                                .ver();
                        }
                    }
                    if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado) {
                        ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado = new AnaliseSelegenAnaliseSelecaoTesteGenetico_1.AnaliseSelegenAnaliseSelecaoTesteGenetico();
                        ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.status = true;
                        ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.criacao = new Date();
                        ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.criacao.setMilliseconds(0);
                        ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.testeGenetico = ctrl.testeGeneticoSimplificado();
                        ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.anoAnalise = ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                            (typeof ctrl.testeGeneticoSelecionado.anoAnalise !== 'undefined') && ctrl.testeGeneticoSelecionado.anoAnalise
                            ? ctrl.testeGeneticoSelecionado.anoAnalise : null);
                        ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao = new Date();
                        ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao.setMilliseconds(0);
                        ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.usuarioInclusao = ctrl.usuarioSimplificado();
                    }
                    if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.testeGenetico === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.testeGenetico) {
                        ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.testeGenetico = ctrl.testeGeneticoSimplificado();
                    }
                    if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao) {
                        ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao = new Date();
                        ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao.setMilliseconds(0);
                    }
                    if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.usuarioInclusao === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.usuarioInclusao) {
                        ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.usuarioInclusao = ctrl.usuarioSimplificado();
                    }
                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoVisualizar = false;
                }
                if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.testeGenetico === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.testeGenetico) {
                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.testeGenetico = ctrl.testeGeneticoSimplificado();
                }
                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.importar = false;
                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoPlanilha = 0;
                if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosPlanilha !== 'undefined') && ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosPlanilha) {
                    angular.forEach(ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosPlanilha, (item) => {
                        if ((typeof item.individuo !== 'undefined') && item.individuo && (item.individuo > ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoPlanilha)) {
                            ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoPlanilha = item.individuo;
                        }
                    });
                }
                else {
                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosPlanilha = new Array();
                }
                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo1 = 1000;
                if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo1 !== 'undefined') && ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo1) {
                    angular.forEach(ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo1, (item) => {
                        if ((typeof item.individuo !== 'undefined') && item.individuo && (item.individuo > ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo1)) {
                            ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo1 = item.individuo;
                        }
                    });
                }
                else {
                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo1 = new Array();
                }
                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo2 = 1000;
                if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo2 !== 'undefined') && ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo2) {
                    angular.forEach(ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo2, (item) => {
                        if ((typeof item.individuo !== 'undefined') && item.individuo && (item.individuo > ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo2)) {
                            ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo2 = item.individuo;
                        }
                    });
                }
                else {
                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo2 = new Array();
                }
                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo4 = 1000;
                if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo4 !== 'undefined') && ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo4) {
                    angular.forEach(ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo4, (item) => {
                        if ((typeof item.individuo !== 'undefined') && item.individuo && (item.individuo > ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo4)) {
                            ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo4 = item.individuo;
                        }
                    });
                }
                else {
                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo4 = new Array();
                }
                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo24 = 1000;
                if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo24 !== 'undefined') && ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo24) {
                    angular.forEach(ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo24, (item) => {
                        if ((typeof item.individuo !== 'undefined') && item.individuo && (item.individuo > ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo24)) {
                            ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo24 = item.individuo;
                        }
                    });
                }
                else {
                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo24 = new Array();
                }
                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo33 = 1000;
                if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo33 !== 'undefined') && ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo33) {
                    angular.forEach(ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo33, (item) => {
                        if ((typeof item.individuo !== 'undefined') && item.individuo && (item.individuo > ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo33)) {
                            ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo33 = item.individuo;
                        }
                    });
                }
                else {
                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo33 = new Array();
                }
                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo51 = 1000;
                if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo51 !== 'undefined') && ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo51) {
                    angular.forEach(ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo51, (item) => {
                        if ((typeof item.individuo !== 'undefined') && item.individuo && (item.individuo > ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo51)) {
                            ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo51 = item.individuo;
                        }
                    });
                }
                else {
                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo51 = new Array();
                }
                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo105 = 1000;
                if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo105 !== 'undefined') && ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo105) {
                    angular.forEach(ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo105, (item) => {
                        if ((typeof item.individuo !== 'undefined') && item.individuo && (item.individuo > ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo105)) {
                            ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo105 = item.individuo;
                        }
                    });
                }
                else {
                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo105 = new Array();
                }
                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo106 = 1000;
                if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo106 !== 'undefined') && ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo106) {
                    angular.forEach(ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo106, (item) => {
                        if ((typeof item.individuo !== 'undefined') && item.individuo && (item.individuo > ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo106)) {
                            ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo106 = item.individuo;
                        }
                    });
                }
                else {
                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo106 = new Array();
                }
                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo441 = 1000;
                if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo441 !== 'undefined') && ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo441) {
                    angular.forEach(ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo441, (item) => {
                        if ((typeof item.individuo !== 'undefined') && item.individuo && (item.individuo > ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo441)) {
                            ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo441 = item.individuo;
                        }
                    });
                }
                else {
                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo441 = new Array();
                }
                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo442 = 1000;
                if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo442 !== 'undefined') && ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo442) {
                    angular.forEach(ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo442, (item) => {
                        if ((typeof item.individuo !== 'undefined') && item.individuo && (item.individuo > ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo442)) {
                            ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo442 = item.individuo;
                        }
                    });
                }
                else {
                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo442 = new Array();
                }
                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo37 = 1000;
                if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo37 !== 'undefined') && ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo37) {
                    angular.forEach(ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo37, (item) => {
                        if ((typeof item.individuo !== 'undefined') && item.individuo && (item.individuo > ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo37)) {
                            ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo37 = item.individuo;
                        }
                    });
                }
                else {
                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo37 = new Array();
                }
                if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.anexos === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.anexos) {
                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.anexos = new Array();
                }
                if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado !== 'undefined') && ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado) {
                    angular.forEach([
                        'dadosPlanilha', 'dadosModelo1', 'dadosModelo2', 'dadosModelo4', 'dadosModelo24',
                        'dadosModelo33', 'dadosModelo51', 'dadosModelo105', 'dadosModelo106', 'dadosModelo441',
                        'dadosModelo442', 'dadosModelo37'
                    ], (campoDados) => {
                        if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado[campoDados] !== 'undefined') && ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado[campoDados]) {
                            const lista = Object.values(ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado[campoDados]);
                            ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado[campoDados] = [];
                            angular.forEach(lista, (item) => {
                                let materialGenetico = null;
                                if ((typeof item.clone !== 'undefined') && (item.clone !== null)) {
                                    materialGenetico = item.clone;
                                }
                                else if ((typeof item.progenie !== 'undefined') && (item.progenie !== null)) {
                                    materialGenetico = item.progenie;
                                }
                                else if ((typeof item.populacao !== 'undefined') && (item.populacao !== null)) {
                                    materialGenetico = item.populacao;
                                }
                                else if ((typeof item.familia !== 'undefined') && (item.familia !== null)) {
                                    materialGenetico = item.familia;
                                }
                                let bloco = null;
                                if ((typeof item.bloco !== 'undefined') && (item.bloco !== null)) {
                                    bloco = item.bloco;
                                }
                                else if ((typeof item.repeticao !== 'undefined') && (item.repeticao !== null)) {
                                    bloco = item.repeticao;
                                }
                                let arvore = null;
                                if ((typeof item.arvore !== 'undefined') && (item.arvore !== null)) {
                                    arvore = item.arvore;
                                }
                                let parcela = null;
                                if ((typeof item.parcela !== 'undefined') && (item.parcela !== null)) {
                                    parcela = item.parcela;
                                }
                                let paiMaterialGenetico = null;
                                if ((typeof item.pai !== 'undefined') && (item.pai !== null)) {
                                    paiMaterialGenetico = item.pai;
                                }
                                let maeMaterialGenetico = null;
                                if ((typeof item.mae !== 'undefined') && (item.mae !== null)) {
                                    maeMaterialGenetico = item.mae;
                                }
                                const chaveCloneBlocoArvore = `${materialGenetico}_${bloco}_${arvore}`;
                                const chaveCloneBlocoParcelaArvore = `${materialGenetico}_${bloco}_${parcela}_${arvore}`;
                                const chavePaiMae = `${paiMaterialGenetico}_${maeMaterialGenetico}`;
                                if (campoDados === 'dadosModelo106') {
                                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado[campoDados][chaveCloneBlocoArvore] = item;
                                }
                                else if (campoDados === 'dadosModelo442') {
                                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado[campoDados][chavePaiMae] = item;
                                }
                                else {
                                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado[campoDados][chaveCloneBlocoParcelaArvore] = item;
                                }
                            });
                        }
                    });
                }
                let dadosAtualizados = false;
                let dadosAleatoriosAnaliseSelecao = false;
                const numTotalBlocos = ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                    (typeof ctrl.testeGeneticoSelecionado.numBlocos !== 'undefined') && ctrl.testeGeneticoSelecionado.numBlocos
                    ? ctrl.testeGeneticoSelecionado.numBlocos : 1);
                const numTotalParcelas = ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                    (typeof ctrl.testeGeneticoSelecionado.numParcelas !== 'undefined') && ctrl.testeGeneticoSelecionado.numParcelas
                    ? ctrl.testeGeneticoSelecionado.numParcelas : 1);
                const numArvoresPorTratamento = ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                    (typeof ctrl.testeGeneticoSelecionado.delineamentoArvoresPorTratamento !== 'undefined') && ctrl.testeGeneticoSelecionado.delineamentoArvoresPorTratamento
                    ? ctrl.testeGeneticoSelecionado.delineamentoArvoresPorTratamento : 1);
                if (ehDadosTemporarios && (typeof ctrl.listaAnaliseSelecao !== 'undefined') && ctrl.listaAnaliseSelecao) {
                    angular.forEach(ctrl.listaAnaliseSelecao, (analiseSelecao) => {
                        if ((typeof analiseSelecao !== 'undefined') && analiseSelecao) {
                            let titulosPlanilhaDados = ['Bloco', 'Progenie', 'Parcela', 'Arvore', 'VOL', 'DAP', 'ALT', 'FOR', 'BF', 'CQ', 'FT'];
                            let registroAnaliseSelecao = {};
                            angular.forEach(titulosPlanilhaDados, (titulo) => {
                                let valor = null;
                                let refTitulo = TextoUtil_1.TextoUtil.converteTextoParaReferencia(titulo);
                                if (refTitulo && (refTitulo === TextoUtil_1.TextoUtil.converteTextoParaReferencia('Bloco'))) {
                                    if ((typeof analiseSelecao.bloco !== 'undefined') && (analiseSelecao.bloco !== null)) {
                                        valor = analiseSelecao.bloco;
                                    }
                                    registroAnaliseSelecao.bloco = ((typeof valor !== 'undefined') && (valor !== null) ? valor : registroAnaliseSelecao.bloco);
                                }
                                else if (refTitulo && (refTitulo === TextoUtil_1.TextoUtil.converteTextoParaReferencia('Progenie'))) {
                                    if ((typeof analiseSelecao.progenie !== 'undefined') && (analiseSelecao.progenie !== null)) {
                                        valor = analiseSelecao.progenie;
                                    }
                                    else if ((typeof analiseSelecao.trat !== 'undefined') && (analiseSelecao.trat !== null)) {
                                        valor = analiseSelecao.trat;
                                    }
                                    else if ((typeof analiseSelecao.tratamento !== 'undefined') && (analiseSelecao.tratamento !== null)) {
                                        valor = analiseSelecao.tratamento;
                                    }
                                    registroAnaliseSelecao.trat = ((typeof valor !== 'undefined') && (valor !== null) ? valor : registroAnaliseSelecao.trat);
                                }
                                else if (refTitulo && (refTitulo === TextoUtil_1.TextoUtil.converteTextoParaReferencia('Parcela'))) {
                                    if ((typeof analiseSelecao.parc !== 'undefined') && (analiseSelecao.parc !== null)) {
                                        valor = analiseSelecao.parc;
                                    }
                                    else if ((typeof analiseSelecao.parcela !== 'undefined') && (analiseSelecao.parcela !== null)) {
                                        valor = analiseSelecao.parcela;
                                    }
                                    registroAnaliseSelecao.parcela = ((typeof valor !== 'undefined') && (valor !== null) ? valor : registroAnaliseSelecao.parcela);
                                }
                                else if (refTitulo && (refTitulo === TextoUtil_1.TextoUtil.converteTextoParaReferencia('Arvore'))) {
                                    if ((typeof analiseSelecao.arv !== 'undefined') && (analiseSelecao.arv !== null)) {
                                        valor = analiseSelecao.arv;
                                    }
                                    else if ((typeof analiseSelecao.arvore !== 'undefined') && (analiseSelecao.arvore !== null)) {
                                        valor = analiseSelecao.arvore;
                                    }
                                    registroAnaliseSelecao.arv = ((typeof valor !== 'undefined') && (valor !== null) ? valor : registroAnaliseSelecao.arv);
                                }
                                else if (refTitulo && (refTitulo === TextoUtil_1.TextoUtil.converteTextoParaReferencia('VOL'))) {
                                    if ((typeof analiseSelecao.vol !== 'undefined') && (analiseSelecao.vol !== null)) {
                                        valor = analiseSelecao.vol;
                                    }
                                    else if ((typeof analiseSelecao.volume !== 'undefined') && (analiseSelecao.volume !== null)) {
                                        valor = analiseSelecao.volume;
                                    }
                                    registroAnaliseSelecao.vol = ((typeof valor !== 'undefined') && (valor !== null) ? NumeroUtil_1.NumeroUtil.converterValorParaNumero(valor) : registroAnaliseSelecao.vol);
                                }
                                else if (refTitulo && (refTitulo === TextoUtil_1.TextoUtil.converteTextoParaReferencia('DAP'))) {
                                    if ((typeof analiseSelecao.dap !== 'undefined') && (analiseSelecao.dap !== null)) {
                                        valor = analiseSelecao.dap;
                                    }
                                    else if ((typeof analiseSelecao.diametro !== 'undefined') && (analiseSelecao.diametro !== null)) {
                                        valor = analiseSelecao.diametro;
                                    }
                                    registroAnaliseSelecao.dap = ((typeof valor !== 'undefined') && (valor !== null) ? NumeroUtil_1.NumeroUtil.converterValorParaNumero(valor) : registroAnaliseSelecao.dap);
                                }
                                else if (refTitulo && (refTitulo === TextoUtil_1.TextoUtil.converteTextoParaReferencia('ALT'))) {
                                    if ((typeof analiseSelecao.alt !== 'undefined') && (analiseSelecao.alt !== null)) {
                                        valor = analiseSelecao.alt;
                                    }
                                    else if ((typeof analiseSelecao.altura !== 'undefined') && (analiseSelecao.altura !== null)) {
                                        valor = analiseSelecao.altura;
                                    }
                                    registroAnaliseSelecao.alt = ((typeof valor !== 'undefined') && (valor !== null) ? NumeroUtil_1.NumeroUtil.converterValorParaNumero(valor) : registroAnaliseSelecao.alt);
                                }
                                else if (refTitulo && (refTitulo === TextoUtil_1.TextoUtil.converteTextoParaReferencia('FOR'))) {
                                    if ((typeof analiseSelecao.for !== 'undefined') && (analiseSelecao.for !== null)) {
                                        valor = analiseSelecao.for;
                                    }
                                    else if ((typeof analiseSelecao.forma !== 'undefined') && (analiseSelecao.forma !== null)) {
                                        valor = analiseSelecao.forma;
                                    }
                                    registroAnaliseSelecao.for = ((typeof valor !== 'undefined') && (valor !== null) ? NumeroUtil_1.NumeroUtil.converterValorParaNumero(valor) : registroAnaliseSelecao.for);
                                }
                                else if (refTitulo && (refTitulo === TextoUtil_1.TextoUtil.converteTextoParaReferencia('BF'))) {
                                    if ((typeof analiseSelecao.bif !== 'undefined') && (analiseSelecao.bif !== null)) {
                                        valor = analiseSelecao.bif;
                                    }
                                    else if ((typeof analiseSelecao.bf !== 'undefined') && (analiseSelecao.bf !== null)) {
                                        valor = analiseSelecao.bf;
                                    }
                                    registroAnaliseSelecao.bif = ((typeof valor !== 'undefined') && (valor !== null) ? NumeroUtil_1.NumeroUtil.converterValorParaNumero(valor) : registroAnaliseSelecao.bif);
                                }
                                else if (refTitulo && (refTitulo === TextoUtil_1.TextoUtil.converteTextoParaReferencia('CQ'))) {
                                    if ((typeof analiseSelecao.cq !== 'undefined') && (analiseSelecao.cq !== null)) {
                                        valor = analiseSelecao.cq;
                                    }
                                    else if ((typeof analiseSelecao.copaQuebrada !== 'undefined') && (analiseSelecao.copaQuebrada !== null)) {
                                        valor = analiseSelecao.copaQuebrada;
                                    }
                                    registroAnaliseSelecao.cq = ((typeof valor !== 'undefined') && (valor !== null) ? NumeroUtil_1.NumeroUtil.converterValorParaNumero(valor) : registroAnaliseSelecao.cq);
                                }
                                else if (refTitulo && (refTitulo === TextoUtil_1.TextoUtil.converteTextoParaReferencia('FT'))) {
                                    if ((typeof analiseSelecao.ft !== 'undefined') && (analiseSelecao.ft !== null)) {
                                        valor = analiseSelecao.ft;
                                    }
                                    else if ((typeof analiseSelecao.foxTail !== 'undefined') && (analiseSelecao.foxTail !== null)) {
                                        valor = analiseSelecao.foxTail;
                                    }
                                    registroAnaliseSelecao.ft = ((typeof valor !== 'undefined') && (valor !== null) ? NumeroUtil_1.NumeroUtil.converterValorParaNumero(valor) : registroAnaliseSelecao.ft);
                                }
                            });
                            if ((typeof registroAnaliseSelecao.trat !== 'undefined') && registroAnaliseSelecao.trat) {
                                const chaveCloneBlocoArvore = `${registroAnaliseSelecao.trat}_${registroAnaliseSelecao.bloco}_${registroAnaliseSelecao.arv}`;
                                const chaveCloneBlocoParcelaArvore = `${registroAnaliseSelecao.trat}_${registroAnaliseSelecao.bloco}_${registroAnaliseSelecao.parcela}_${registroAnaliseSelecao.arv}`;
                                const chavePaiMae = `${registroAnaliseSelecao.trat}_${registroAnaliseSelecao.trat}`;
                                if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosPlanilha[chaveCloneBlocoParcelaArvore] === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosPlanilha[chaveCloneBlocoParcelaArvore]) {
                                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoPlanilha++;
                                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosPlanilha[chaveCloneBlocoParcelaArvore] = {
                                        individuo: ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoPlanilha,
                                        chave: chaveCloneBlocoParcelaArvore,
                                        clone: registroAnaliseSelecao.trat,
                                        bloco: registroAnaliseSelecao.bloco,
                                        parcela: registroAnaliseSelecao.parcela,
                                        arvore: registroAnaliseSelecao.arv,
                                        vol: registroAnaliseSelecao.vol
                                    };
                                    dadosAtualizados = true;
                                }
                                if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo1[chaveCloneBlocoParcelaArvore] === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo1[chaveCloneBlocoParcelaArvore]) {
                                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo1++;
                                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo1[chaveCloneBlocoParcelaArvore] = {
                                        individuo: ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo1,
                                        chave: chaveCloneBlocoParcelaArvore,
                                        progenie: registroAnaliseSelecao.trat,
                                        bloco: registroAnaliseSelecao.bloco,
                                        parcela: registroAnaliseSelecao.parcela,
                                        arvore: registroAnaliseSelecao.arv,
                                        ap: registroAnaliseSelecao.vol
                                    };
                                    dadosAtualizados = true;
                                }
                                if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo2[chaveCloneBlocoParcelaArvore] === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo2[chaveCloneBlocoParcelaArvore]) {
                                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo2++;
                                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo2[chaveCloneBlocoParcelaArvore] = {
                                        individuo: ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo2,
                                        chave: chaveCloneBlocoParcelaArvore,
                                        clone: registroAnaliseSelecao.trat,
                                        bloco: registroAnaliseSelecao.bloco,
                                        parcela: registroAnaliseSelecao.parcela,
                                        arvore: registroAnaliseSelecao.arv,
                                        vol: registroAnaliseSelecao.vol
                                    };
                                    dadosAtualizados = true;
                                }
                                if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo4[chaveCloneBlocoParcelaArvore] === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo4[chaveCloneBlocoParcelaArvore]) {
                                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo4++;
                                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo4[chaveCloneBlocoParcelaArvore] = {
                                        individuo: ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo4,
                                        chave: chaveCloneBlocoParcelaArvore,
                                        progenie: registroAnaliseSelecao.trat,
                                        repeticao: registroAnaliseSelecao.bloco,
                                        parcela: registroAnaliseSelecao.parcela,
                                        interacao: 168,
                                        arvore: registroAnaliseSelecao.arv,
                                        dap: registroAnaliseSelecao.dap
                                    };
                                    dadosAtualizados = true;
                                }
                                if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo24[chaveCloneBlocoParcelaArvore] === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo24[chaveCloneBlocoParcelaArvore]) {
                                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo24++;
                                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo24[chaveCloneBlocoParcelaArvore] = {
                                        individuo: ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo24,
                                        chave: chaveCloneBlocoParcelaArvore,
                                        populacao: registroAnaliseSelecao.trat,
                                        bloco: registroAnaliseSelecao.bloco,
                                        parcela: registroAnaliseSelecao.parcela,
                                        arvore: registroAnaliseSelecao.arv,
                                        ap: registroAnaliseSelecao.vol
                                    };
                                    dadosAtualizados = true;
                                }
                                if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo33[chaveCloneBlocoParcelaArvore] === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo33[chaveCloneBlocoParcelaArvore]) {
                                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo33++;
                                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo33[chaveCloneBlocoParcelaArvore] = {
                                        individuo: ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo33,
                                        chave: chaveCloneBlocoParcelaArvore,
                                        pai: registroAnaliseSelecao.trat,
                                        mae: registroAnaliseSelecao.trat,
                                        bloco: registroAnaliseSelecao.bloco,
                                        parcela: registroAnaliseSelecao.parcela,
                                        progenie: registroAnaliseSelecao.trat,
                                        arvore: registroAnaliseSelecao.arv,
                                        dap: registroAnaliseSelecao.dap
                                    };
                                    dadosAtualizados = true;
                                }
                                if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo51[chaveCloneBlocoParcelaArvore] === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo51[chaveCloneBlocoParcelaArvore]) {
                                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo51++;
                                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo51[chaveCloneBlocoParcelaArvore] = {
                                        local: 1,
                                        individuo: ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo51,
                                        chave: chaveCloneBlocoParcelaArvore,
                                        progenie: registroAnaliseSelecao.trat,
                                        repeticao: registroAnaliseSelecao.bloco,
                                        parcela: registroAnaliseSelecao.parcela,
                                        interacao: 11,
                                        arvore: registroAnaliseSelecao.arv,
                                        dap: registroAnaliseSelecao.dap,
                                        alt: registroAnaliseSelecao.alt
                                    };
                                    dadosAtualizados = true;
                                }
                                if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo105[chaveCloneBlocoParcelaArvore] === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo105[chaveCloneBlocoParcelaArvore]) {
                                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo105++;
                                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo105[chaveCloneBlocoParcelaArvore] = {
                                        individuo: ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo105,
                                        chave: chaveCloneBlocoParcelaArvore,
                                        progenie: registroAnaliseSelecao.trat,
                                        bloco: registroAnaliseSelecao.bloco,
                                        parcela: registroAnaliseSelecao.parcela,
                                        arvore: registroAnaliseSelecao.arv
                                    };
                                    dadosAtualizados = true;
                                }
                                if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo106[chaveCloneBlocoArvore] === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo106[chaveCloneBlocoArvore]) {
                                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo106++;
                                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo106[chaveCloneBlocoArvore] = {
                                        ordem: ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo106,
                                        chave: chaveCloneBlocoArvore,
                                        bloco: registroAnaliseSelecao.bloco,
                                        familia: registroAnaliseSelecao.trat,
                                        arvore: registroAnaliseSelecao.arv,
                                        f: registroAnaliseSelecao.ft,
                                        a: registroAnaliseSelecao.alt,
                                        ua: registroAnaliseSelecao.bif,
                                        ganho: null,
                                        novaMedia: null,
                                        ne: null,
                                        d: null,
                                        g: null
                                    };
                                    dadosAtualizados = true;
                                }
                                if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo441[chaveCloneBlocoParcelaArvore] === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo441[chaveCloneBlocoParcelaArvore]) {
                                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo441++;
                                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo441[chaveCloneBlocoParcelaArvore] = {
                                        local: 1,
                                        individuo: ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo441,
                                        chave: chaveCloneBlocoParcelaArvore,
                                        pai: registroAnaliseSelecao.trat,
                                        mae: registroAnaliseSelecao.trat,
                                        repeticao: registroAnaliseSelecao.bloco,
                                        interacao: 11,
                                        progenie: registroAnaliseSelecao.trat,
                                        parcela: registroAnaliseSelecao.parcela,
                                        arvore: registroAnaliseSelecao.arv,
                                        vol: registroAnaliseSelecao.vol
                                    };
                                    dadosAtualizados = true;
                                }
                                if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo442[chavePaiMae] === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo442[chavePaiMae]) {
                                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo442++;
                                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo442[chavePaiMae] = {
                                        chave: chavePaiMae,
                                        pedigree: ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo442,
                                        pai: registroAnaliseSelecao.trat,
                                        mae: registroAnaliseSelecao.trat
                                    };
                                    dadosAtualizados = true;
                                }
                                if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo37[chaveCloneBlocoParcelaArvore] === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo37[chaveCloneBlocoParcelaArvore]) {
                                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo37++;
                                    ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo37[chaveCloneBlocoParcelaArvore] = {
                                        local: 1,
                                        individuo: ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo37,
                                        chave: chaveCloneBlocoParcelaArvore,
                                        pai: registroAnaliseSelecao.trat,
                                        mae: registroAnaliseSelecao.trat,
                                        repeticao: registroAnaliseSelecao.bloco,
                                        interacao: 11,
                                        progenie: registroAnaliseSelecao.trat,
                                        parcela: registroAnaliseSelecao.parcela,
                                        arvore: registroAnaliseSelecao.arv,
                                        vol: registroAnaliseSelecao.vol
                                    };
                                    dadosAtualizados = true;
                                }
                            }
                        }
                    });
                }
                else {
                    if ((typeof ctrl.listaTratamentosTesteGenetico !== 'undefined') && ctrl.listaTratamentosTesteGenetico) {
                        angular.forEach(ctrl.listaTratamentosTesteGenetico, (tratamentoTesteGenetico) => {
                            if ((typeof tratamentoTesteGenetico.materialGenetico !== 'undefined') && tratamentoTesteGenetico.materialGenetico &&
                                (typeof tratamentoTesteGenetico.materialGenetico.descricao !== 'undefined') && tratamentoTesteGenetico.materialGenetico.descricao &&
                                (tratamentoTesteGenetico.materialGenetico.descricao.trim().length > 0)) {
                                for (let bloco = 1; bloco <= numTotalBlocos; bloco++) {
                                    for (let parcela = 1; parcela <= numTotalParcelas; parcela++) {
                                        for (let arvore = 1; arvore <= numArvoresPorTratamento; arvore++) {
                                            const materialGenetico = tratamentoTesteGenetico.materialGenetico.descricao.trim();
                                            let maeMaterialGenetico = `${materialGenetico}`;
                                            if ((typeof tratamentoTesteGenetico.materialGenetico.mae !== 'undefined') && tratamentoTesteGenetico.materialGenetico.mae) {
                                                if ((typeof tratamentoTesteGenetico.materialGenetico.mae.descricao !== 'undefined') && tratamentoTesteGenetico.materialGenetico.mae.descricao) {
                                                    maeMaterialGenetico = tratamentoTesteGenetico.materialGenetico.mae.descricao.trim();
                                                }
                                                else if ((typeof tratamentoTesteGenetico.materialGenetico.mae === 'string') && tratamentoTesteGenetico.materialGenetico.mae.trim().length > 0) {
                                                    maeMaterialGenetico = tratamentoTesteGenetico.materialGenetico.mae.trim();
                                                }
                                            }
                                            let paiMaterialGenetico = `${materialGenetico}`;
                                            if ((typeof tratamentoTesteGenetico.materialGenetico.pai !== 'undefined') && tratamentoTesteGenetico.materialGenetico.pai) {
                                                if ((typeof tratamentoTesteGenetico.materialGenetico.pai.descricao !== 'undefined') && tratamentoTesteGenetico.materialGenetico.pai.descricao) {
                                                    paiMaterialGenetico = tratamentoTesteGenetico.materialGenetico.pai.descricao.trim();
                                                }
                                                else if ((typeof tratamentoTesteGenetico.materialGenetico.pai === 'string') && tratamentoTesteGenetico.materialGenetico.pai.trim().length > 0) {
                                                    paiMaterialGenetico = tratamentoTesteGenetico.materialGenetico.pai.trim();
                                                }
                                            }
                                            const chaveCloneBlocoArvore = `${materialGenetico}_${bloco}_${arvore}`;
                                            const chaveCloneBlocoParcelaArvore = `${materialGenetico}_${bloco}_${parcela}_${arvore}`;
                                            const chavePaiMae = `${paiMaterialGenetico}_${maeMaterialGenetico}`;
                                            if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosPlanilha[chaveCloneBlocoParcelaArvore] === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosPlanilha[chaveCloneBlocoParcelaArvore]) {
                                                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoPlanilha++;
                                                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosPlanilha[chaveCloneBlocoParcelaArvore] = {
                                                    individuo: ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoPlanilha,
                                                    chave: chaveCloneBlocoParcelaArvore,
                                                    clone: materialGenetico,
                                                    bloco: bloco,
                                                    parcela: parcela,
                                                    arvore: arvore,
                                                    vol: dadosAleatoriosAnaliseSelecao ? NumeroUtil_1.NumeroUtil.gerarNumeroAleatorio(0.01, 0.20, 2) : null
                                                };
                                                dadosAtualizados = true;
                                            }
                                            if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo1[chaveCloneBlocoParcelaArvore] === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo1[chaveCloneBlocoParcelaArvore]) {
                                                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo1++;
                                                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo1[chaveCloneBlocoParcelaArvore] = {
                                                    individuo: ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo1,
                                                    chave: chaveCloneBlocoParcelaArvore,
                                                    progenie: materialGenetico,
                                                    bloco: bloco,
                                                    parcela: parcela,
                                                    arvore: arvore,
                                                    ap: dadosAleatoriosAnaliseSelecao ? NumeroUtil_1.NumeroUtil.gerarNumeroAleatorio(3.0, 11.9, 1) : null
                                                };
                                                dadosAtualizados = true;
                                            }
                                            if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo2[chaveCloneBlocoParcelaArvore] === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo2[chaveCloneBlocoParcelaArvore]) {
                                                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo2++;
                                                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo2[chaveCloneBlocoParcelaArvore] = {
                                                    individuo: ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo2,
                                                    chave: chaveCloneBlocoParcelaArvore,
                                                    clone: materialGenetico,
                                                    bloco: bloco,
                                                    parcela: parcela,
                                                    arvore: arvore,
                                                    vol: dadosAleatoriosAnaliseSelecao ? NumeroUtil_1.NumeroUtil.gerarNumeroAleatorio(45.00, 699.00, 2) : null
                                                };
                                                dadosAtualizados = true;
                                            }
                                            if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo4[chaveCloneBlocoParcelaArvore] === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo4[chaveCloneBlocoParcelaArvore]) {
                                                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo4++;
                                                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo4[chaveCloneBlocoParcelaArvore] = {
                                                    individuo: ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo4,
                                                    chave: chaveCloneBlocoParcelaArvore,
                                                    progenie: materialGenetico,
                                                    repeticao: bloco,
                                                    parcela: parcela,
                                                    interacao: 168,
                                                    arvore: arvore,
                                                    dap: dadosAleatoriosAnaliseSelecao ? NumeroUtil_1.NumeroUtil.gerarNumeroAleatorio(0.0, 12.0, 1) : null
                                                };
                                                dadosAtualizados = true;
                                            }
                                            if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo24[chaveCloneBlocoParcelaArvore] === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo24[chaveCloneBlocoParcelaArvore]) {
                                                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo24++;
                                                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo24[chaveCloneBlocoParcelaArvore] = {
                                                    individuo: ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo24,
                                                    chave: chaveCloneBlocoParcelaArvore,
                                                    populacao: materialGenetico,
                                                    bloco: bloco,
                                                    parcela: parcela,
                                                    arvore: arvore,
                                                    ap: dadosAleatoriosAnaliseSelecao ? NumeroUtil_1.NumeroUtil.gerarNumeroAleatorio(3.0, 15.0, 1) : null
                                                };
                                                dadosAtualizados = true;
                                            }
                                            if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo33[chaveCloneBlocoParcelaArvore] === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo33[chaveCloneBlocoParcelaArvore]) {
                                                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo33++;
                                                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo33[chaveCloneBlocoParcelaArvore] = {
                                                    individuo: ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo33,
                                                    chave: chaveCloneBlocoParcelaArvore,
                                                    pai: paiMaterialGenetico,
                                                    mae: maeMaterialGenetico,
                                                    bloco: bloco,
                                                    parcela: parcela,
                                                    progenie: materialGenetico,
                                                    arvore: arvore,
                                                    dap: dadosAleatoriosAnaliseSelecao ? NumeroUtil_1.NumeroUtil.gerarNumeroAleatorio(19.0, 39.0, 1) : null
                                                };
                                                dadosAtualizados = true;
                                            }
                                            if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo51[chaveCloneBlocoParcelaArvore] === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo51[chaveCloneBlocoParcelaArvore]) {
                                                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo51++;
                                                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo51[chaveCloneBlocoParcelaArvore] = {
                                                    local: 1,
                                                    individuo: ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo51,
                                                    chave: chaveCloneBlocoParcelaArvore,
                                                    progenie: materialGenetico,
                                                    repeticao: bloco,
                                                    parcela: parcela,
                                                    interacao: 11,
                                                    arvore: arvore,
                                                    dap: dadosAleatoriosAnaliseSelecao ? NumeroUtil_1.NumeroUtil.gerarNumeroAleatorio(19.0, 39.0, 1) : null,
                                                    alt: dadosAleatoriosAnaliseSelecao ? NumeroUtil_1.NumeroUtil.gerarNumeroAleatorio(0.0, 10.0, 1) : null
                                                };
                                                dadosAtualizados = true;
                                            }
                                            if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo105[chaveCloneBlocoParcelaArvore] === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo105[chaveCloneBlocoParcelaArvore]) {
                                                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo105++;
                                                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo105[chaveCloneBlocoParcelaArvore] = {
                                                    individuo: ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo105,
                                                    chave: chaveCloneBlocoParcelaArvore,
                                                    progenie: materialGenetico,
                                                    bloco: bloco,
                                                    parcela: parcela,
                                                    arvore: arvore
                                                };
                                                dadosAtualizados = true;
                                            }
                                            if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo106[chaveCloneBlocoArvore] === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo106[chaveCloneBlocoArvore]) {
                                                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo106++;
                                                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo106[chaveCloneBlocoArvore] = {
                                                    ordem: ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo106,
                                                    chave: chaveCloneBlocoArvore,
                                                    bloco: bloco,
                                                    familia: materialGenetico,
                                                    arvore: arvore,
                                                    f: dadosAleatoriosAnaliseSelecao ? NumeroUtil_1.NumeroUtil.gerarNumeroAleatorio(2.2500, 0.0001, 4) : null,
                                                    a: dadosAleatoriosAnaliseSelecao ? NumeroUtil_1.NumeroUtil.gerarNumeroAleatorio(2.2500, 0.0001, 4) : null,
                                                    ua: dadosAleatoriosAnaliseSelecao ? NumeroUtil_1.NumeroUtil.gerarNumeroAleatorio(6.9999, 1.0000, 4) : null,
                                                    ganho: null,
                                                    novaMedia: null,
                                                    ne: null,
                                                    d: null,
                                                    g: null
                                                };
                                                dadosAtualizados = true;
                                            }
                                            if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo441[chaveCloneBlocoParcelaArvore] === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo441[chaveCloneBlocoParcelaArvore]) {
                                                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo441++;
                                                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo441[chaveCloneBlocoParcelaArvore] = {
                                                    local: 1,
                                                    individuo: ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo441,
                                                    chave: chaveCloneBlocoParcelaArvore,
                                                    pai: paiMaterialGenetico,
                                                    mae: maeMaterialGenetico,
                                                    repeticao: bloco,
                                                    interacao: 11,
                                                    progenie: materialGenetico,
                                                    parcela: parcela,
                                                    arvore: arvore,
                                                    vol: dadosAleatoriosAnaliseSelecao ? NumeroUtil_1.NumeroUtil.gerarNumeroAleatorio(0.3000, 0.6999, 4) : null
                                                };
                                                dadosAtualizados = true;
                                            }
                                            if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo442[chavePaiMae] === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo442[chavePaiMae]) {
                                                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo442++;
                                                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo442[chavePaiMae] = {
                                                    chave: chavePaiMae,
                                                    pedigree: ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo442,
                                                    pai: paiMaterialGenetico,
                                                    mae: maeMaterialGenetico
                                                };
                                                dadosAtualizados = true;
                                            }
                                            if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo37[chaveCloneBlocoParcelaArvore] === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo37[chaveCloneBlocoParcelaArvore]) {
                                                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo37++;
                                                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo37[chaveCloneBlocoParcelaArvore] = {
                                                    local: 1,
                                                    individuo: ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.ultimoIndividuoModelo37,
                                                    chave: chaveCloneBlocoParcelaArvore,
                                                    pai: paiMaterialGenetico,
                                                    mae: maeMaterialGenetico,
                                                    repeticao: bloco,
                                                    interacao: 11,
                                                    progenie: materialGenetico,
                                                    parcela: parcela,
                                                    arvore: arvore,
                                                    vol: dadosAleatoriosAnaliseSelecao ? NumeroUtil_1.NumeroUtil.gerarNumeroAleatorio(0.0500, 0.3999, 4) : null
                                                };
                                                dadosAtualizados = true;
                                            }
                                        }
                                    }
                                }
                            }
                        });
                    }
                }
                if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado !== 'undefined') && ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado) {
                    angular.forEach([
                        'dadosPlanilha', 'dadosModelo1', 'dadosModelo2', 'dadosModelo4', 'dadosModelo24',
                        'dadosModelo33', 'dadosModelo51', 'dadosModelo105', 'dadosModelo106', 'dadosModelo441',
                        'dadosModelo442', 'dadosModelo37'
                    ], (campoDados) => {
                        if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado[campoDados] !== 'undefined') && ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado[campoDados]) {
                            ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado[campoDados] = Object.values(ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado[campoDados]);
                        }
                    });
                }
                if (dadosAtualizados && !ehDadosTemporarios) {
                    let resposta = null;
                    if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.id !== 'undefined') && ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.id) {
                        resposta = yield ctrl.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANALISE_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                            .atualizar(ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado);
                    }
                    else {
                        resposta = yield ctrl.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANALISE_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                            .criar(ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado);
                    }
                    if ((typeof resposta !== 'undefined') && resposta) {
                        if ((typeof resposta.id !== 'undefined') && resposta.id) {
                            ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.id = resposta.id;
                        }
                        if ((typeof resposta.uuid !== 'undefined') && resposta.uuid) {
                            ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.uuid = resposta.uuid;
                        }
                    }
                }
                ctrl.listaOpcoesAnaliseSelegenAnaliseSelecaoTesteGenetico = yield ctrl.dadosListaAnaliseSelegenAnaliseSelecaoTesteGenetico();
                ctrl.modalAnaliseSelegenAnaliseSelecaoTesteGenetico = true;
                angular.element('body').css('overflow-y', 'hidden');
                MenuUtil_1.MenuUtil.ajustaZIndexMenusModalPlanilha();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.scopeApply(() => {
                    MenuUtil_1.MenuUtil.ajustaZIndexMenusModalPlanilha();
                });
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static ocultarModalAnaliseSelegenAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.modalAnaliseSelegenAnaliseSelecaoTesteGenetico = false;
            ctrl.limparPlanilhasCarregadas();
            angular.element('body').css('overflow-y', '');
            MenuUtil_1.MenuUtil.restauraZIndexMenus();
            globalThis.posicaoTopoAntesModal = ctrl.posicaoTopoAntesModal;
            ctrl.scopeApply(() => {
                MenuUtil_1.MenuUtil.restauraZIndexMenus();
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                        (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    static alterarTipoAnaliseSelegenAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.listaOpcoesAnaliseSelegenAnaliseSelecaoTesteGenetico = {
                data: [],
                columnDefs: []
            };
            ctrl.scopeApply(() => __awaiter(this, void 0, void 0, function* () {
                ctrl.listaOpcoesAnaliseSelegenAnaliseSelecaoTesteGenetico = yield ctrl.dadosListaAnaliseSelegenAnaliseSelecaoTesteGenetico();
                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
            }));
        });
    }
    static importarAnaliseSelegenAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado) {
                ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado = new AnaliseSelegenAnaliseSelecaoTesteGenetico_1.AnaliseSelegenAnaliseSelecaoTesteGenetico();
            }
            ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.importar = true;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static exportarAnaliseSelegenAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if ((typeof ctrl.listaOpcoesAnaliseSelegenAnaliseSelecaoTesteGenetico !== 'undefined') && ctrl.listaOpcoesAnaliseSelegenAnaliseSelecaoTesteGenetico &&
                    (typeof ctrl.listaOpcoesAnaliseSelegenAnaliseSelecaoTesteGenetico.data !== 'undefined') && ctrl.listaOpcoesAnaliseSelegenAnaliseSelecaoTesteGenetico.data) {
                    let tipoAnaliseSelegen = (typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado !== 'undefined') && ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado &&
                        (typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.tipoAnaliseSelegen !== 'undefined') && ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.tipoAnaliseSelegen
                        ? ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.tipoAnaliseSelegen : null;
                    let txt = '';
                    const campos = [];
                    angular.forEach(ctrl.listaOpcoesAnaliseSelegenAnaliseSelecaoTesteGenetico.data, (item) => {
                        angular.forEach(Object.keys(item), (campo) => {
                            if ((campo !== 'id') && (campo !== '_id') && (campo !== 'uuid') && (campo !== 'status') && (campo !== 'criacao') &&
                                (campo !== 'chave') && (campo !== '$$hashKey')) {
                                if (!campos.includes(campo)) {
                                    campos.push(campo);
                                }
                            }
                        });
                    });
                    angular.forEach(campos, (campo) => {
                        let s = `${campo}`;
                        let len = ((campo.length >= 8) ? 12 : ((campo.length >= 6) ? 10 : 8));
                        while (s.length < len) {
                            s = s + ' ';
                        }
                        txt += s;
                    });
                    angular.forEach(ctrl.listaOpcoesAnaliseSelegenAnaliseSelecaoTesteGenetico.data, (item) => {
                        txt += '\r\n';
                        angular.forEach(campos, (campo) => {
                            let s = (typeof item[campo] !== 'undefined') && item[campo] ? `${item[campo]}` : '';
                            let len = ((campo.length >= 8) ? 12 : ((campo.length >= 6) ? 10 : 8));
                            while (s.length < len) {
                                s = s + ' ';
                            }
                            while (s.length > len) {
                                s = s.substring(0, len);
                            }
                            txt += s;
                        });
                    });
                    const dataHoraAtual = new Date();
                    var element = document.createElement('a');
                    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(txt));
                    element.setAttribute('download', 'AnaliseSelegen_' + (tipoAnaliseSelegen ? (tipoAnaliseSelegen + '_') : '') +
                        DataUtil_1.DataUtil.converterDataParaFormatoDDMMYY(dataHoraAtual).replace(/\//g, '-').replace(/\:/g, ';') + '_' +
                        DataUtil_1.DataUtil.converterDataParaFormatoHHMMSS(dataHoraAtual).replace(/\//g, '-').replace(/\:/g, ';') + '.txt');
                    element.style.display = 'none';
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                }
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static importarResultadoSelegenAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoImportacao = true;
            ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoImportacaoAnexos = new Array();
            ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static finalizarImportacaoResultadoSelegenAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
                if ((typeof ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoImportacaoAnexos !== 'undefined') && ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoImportacaoAnexos &&
                    (ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoImportacaoAnexos.length > 0)) {
                    if ((typeof ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado) {
                        ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado = new ResultadoSelegenAnaliseSelecaoTesteGenetico_1.ResultadoSelegenAnaliseSelecaoTesteGenetico();
                    }
                    if ((typeof ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas) {
                        ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas = new Array();
                    }
                    if ((typeof ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.anexos === 'undefined') || !ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.anexos) {
                        ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.anexos = new Array();
                    }
                    let anexoSelecionado = null;
                    angular.forEach(ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoImportacaoAnexos, (anexo) => {
                        ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.anexos.push(anexo);
                        anexoSelecionado = anexo;
                    });
                    yield ctrl.limparPlanilhasCarregadas();
                    yield ctrl.carregarPlanilha('planilha_resultado_selegen_analise_selecao_teste_genetico', null, anexoSelecionado);
                    setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                        ctrl.salvarResultadoSelegenAnaliseSelecaoTesteGenetico(true);
                    }), 1500, ctrl);
                }
                ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoImportacao = false;
                ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
                ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoHistorico = false;
                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static cancelarImportacaoResultadoSelegenAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoImportacao = false;
            ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
            ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static exportarResultadoSelegenAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                return;
            }
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
            try {
                if ((typeof globalThis.luckysheet === 'undefined') || !globalThis.luckysheet) {
                    throw new Error('Plugin "luckysheet" não carregado.');
                }
                LuckysheetUtil_1.LuckysheetUtil.exportXLSX(globalThis.luckysheet);
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    static historicoResultadoSelegenAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoImportacao = false;
            ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
            ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoHistorico = true;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static selecionarHistoricoResultadoSelegenAnaliseSelecaoTesteGenetico(ctrl, keyPlanilha) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                return;
            }
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
            try {
                if ((typeof ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado) {
                    ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado = new ResultadoSelegenAnaliseSelecaoTesteGenetico_1.ResultadoSelegenAnaliseSelecaoTesteGenetico();
                }
                if ((typeof ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas) {
                    ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas = new Array();
                }
                if ((typeof keyPlanilha !== 'undefined') && (keyPlanilha !== null) && (keyPlanilha >= 0) &&
                    (keyPlanilha <= ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas.length - 1)) {
                    const planilhaSelecionada = ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas[keyPlanilha];
                    ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada = planilhaSelecionada;
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.RESULTADO_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.RESULTADO_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                        .atualizar(ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado);
                    yield ctrl.limparPlanilhasCarregadas();
                    setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                        ctrl.exibirModalResultadoSelegenAnaliseSelecaoTesteGenetico();
                    }), 500, ctrl);
                    ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoImportacao = false;
                    ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
                    ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoHistorico = false;
                }
                else {
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.notificacao.alerta('Não foi possível carregar a planilha.', 'A planilha selecionada não é válida ou foi removida.');
                    return;
                }
                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    static cancelarHistoricoResultadoSelegenAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoImportacao = false;
            ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
            ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static atualizarResultadoSelegenAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield TesteCampoAnaliseSelecaoUtil.salvarResultadoSelegenAnaliseSelecaoTesteGenetico(ctrl, false, true);
        });
    }
    static salvarResultadoSelegenAnaliseSelecaoTesteGenetico(ctrl, ehImportacao = false, ehAtualizacaoDadosResultados = false) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof ctrl.testeGeneticoSelecionado === 'undefined') || !ctrl.testeGeneticoSelecionado) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
                if ((typeof ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado) {
                    ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado = new ResultadoSelegenAnaliseSelecaoTesteGenetico_1.ResultadoSelegenAnaliseSelecaoTesteGenetico();
                }
                if ((typeof ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas) {
                    ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas = new Array();
                }
                if ((typeof ehAtualizacaoDadosResultados !== 'undefined') && ehAtualizacaoDadosResultados) {
                    if ((typeof ctrl.listaAnaliseSelecao === 'undefined') || !ctrl.listaAnaliseSelecao || (ctrl.listaAnaliseSelecao.length === 0)) {
                        if (ctrl.ehCelular) {
                            alert(`Não há dados carregados na Análise Seleção para importar os dados e calcular os resultados.`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                title: 'Sem dados!',
                                text: 'Não há dados carregados na Análise Seleção para importar os dados e calcular os resultados.',
                                icon: 'info',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        return;
                    }
                    let tituloPlanilhaDados = 'Dados ' +
                        DataUtil_1.DataUtil.converterDataParaFormatoDDMMYY(DataUtil_1.DataUtil.dataHoraAtual()) + ' ' +
                        DataUtil_1.DataUtil.converterDataParaFormatoHHMMSS(DataUtil_1.DataUtil.dataHoraAtual());
                    let tituloPlanilhaResultados = 'Resultados ' +
                        DataUtil_1.DataUtil.converterDataParaFormatoDDMMYY(DataUtil_1.DataUtil.dataHoraAtual()) + ' ' +
                        DataUtil_1.DataUtil.converterDataParaFormatoHHMMSS(DataUtil_1.DataUtil.dataHoraAtual());
                    let tituloPlanilhaInformacoes = 'Informações ' +
                        DataUtil_1.DataUtil.converterDataParaFormatoDDMMYY(DataUtil_1.DataUtil.dataHoraAtual()) + ' ' +
                        DataUtil_1.DataUtil.converterDataParaFormatoHHMMSS(DataUtil_1.DataUtil.dataHoraAtual());
                    let tratamentosAgrupados = [];
                    let dadosPlanilhaDados = [];
                    for (let linhaIdx = 0; linhaIdx <= Math.max(250, ctrl.listaAnaliseSelecao.length + 1); linhaIdx++) {
                        let linha = [];
                        for (let colunaIdx = 0; colunaIdx <= 60; colunaIdx++) {
                            let valor = null;
                            linha.push(valor);
                        }
                        dadosPlanilhaDados.push(linha);
                    }
                    let titulosPlanilhaDados = ['Bloco', 'Progenie', 'Arvore', 'VOL', 'DAP', 'ALT', 'FOR', 'BF', 'CQ', 'FT'];
                    angular.forEach(titulosPlanilhaDados, (titulo, keyTitulo) => {
                        dadosPlanilhaDados[0][keyTitulo] = { v: titulo, m: titulo, bl: 1, ht: 1 };
                    });
                    angular.forEach(ctrl.listaAnaliseSelecao, (analiseSelecao, keyAnaliseSelecao) => {
                        if ((typeof analiseSelecao !== 'undefined') && analiseSelecao) {
                            let registroAnaliseSelecao = {};
                            angular.forEach(titulosPlanilhaDados, (titulo, keyTitulo) => {
                                let valor = null;
                                let refTitulo = TextoUtil_1.TextoUtil.converteTextoParaReferencia(titulo);
                                if (refTitulo && (refTitulo === TextoUtil_1.TextoUtil.converteTextoParaReferencia('Bloco'))) {
                                    if ((typeof analiseSelecao.bloco !== 'undefined') && (analiseSelecao.bloco !== null)) {
                                        valor = analiseSelecao.bloco;
                                    }
                                    registroAnaliseSelecao.bloco = ((typeof valor !== 'undefined') && (valor !== null) ? valor : registroAnaliseSelecao.bloco);
                                }
                                else if (refTitulo && (refTitulo === TextoUtil_1.TextoUtil.converteTextoParaReferencia('Progenie'))) {
                                    if ((typeof analiseSelecao.progenie !== 'undefined') && (analiseSelecao.progenie !== null)) {
                                        valor = analiseSelecao.progenie;
                                    }
                                    else if ((typeof analiseSelecao.trat !== 'undefined') && (analiseSelecao.trat !== null)) {
                                        valor = analiseSelecao.trat;
                                    }
                                    else if ((typeof analiseSelecao.tratamento !== 'undefined') && (analiseSelecao.tratamento !== null)) {
                                        valor = analiseSelecao.tratamento;
                                    }
                                    registroAnaliseSelecao.trat = ((typeof valor !== 'undefined') && (valor !== null) ? valor : registroAnaliseSelecao.trat);
                                }
                                else if (refTitulo && (refTitulo === TextoUtil_1.TextoUtil.converteTextoParaReferencia('Arvore'))) {
                                    if ((typeof analiseSelecao.arv !== 'undefined') && (analiseSelecao.arv !== null)) {
                                        valor = analiseSelecao.arv;
                                    }
                                    else if ((typeof analiseSelecao.arvore !== 'undefined') && (analiseSelecao.arvore !== null)) {
                                        valor = analiseSelecao.arvore;
                                    }
                                    registroAnaliseSelecao.arv = ((typeof valor !== 'undefined') && (valor !== null) ? valor : registroAnaliseSelecao.arv);
                                }
                                else if (refTitulo && (refTitulo === TextoUtil_1.TextoUtil.converteTextoParaReferencia('VOL'))) {
                                    if ((typeof analiseSelecao.vol !== 'undefined') && (analiseSelecao.vol !== null)) {
                                        valor = analiseSelecao.vol;
                                    }
                                    else if ((typeof analiseSelecao.volume !== 'undefined') && (analiseSelecao.volume !== null)) {
                                        valor = analiseSelecao.volume;
                                    }
                                    registroAnaliseSelecao.vol = ((typeof valor !== 'undefined') && (valor !== null) ? NumeroUtil_1.NumeroUtil.converterValorParaNumero(valor) : registroAnaliseSelecao.vol);
                                }
                                else if (refTitulo && (refTitulo === TextoUtil_1.TextoUtil.converteTextoParaReferencia('DAP'))) {
                                    if ((typeof analiseSelecao.dap !== 'undefined') && (analiseSelecao.dap !== null)) {
                                        valor = analiseSelecao.dap;
                                    }
                                    else if ((typeof analiseSelecao.diametro !== 'undefined') && (analiseSelecao.diametro !== null)) {
                                        valor = analiseSelecao.diametro;
                                    }
                                    registroAnaliseSelecao.dap = ((typeof valor !== 'undefined') && (valor !== null) ? NumeroUtil_1.NumeroUtil.converterValorParaNumero(valor) : registroAnaliseSelecao.dap);
                                }
                                else if (refTitulo && (refTitulo === TextoUtil_1.TextoUtil.converteTextoParaReferencia('ALT'))) {
                                    if ((typeof analiseSelecao.alt !== 'undefined') && (analiseSelecao.alt !== null)) {
                                        valor = analiseSelecao.alt;
                                    }
                                    else if ((typeof analiseSelecao.altura !== 'undefined') && (analiseSelecao.altura !== null)) {
                                        valor = analiseSelecao.altura;
                                    }
                                    registroAnaliseSelecao.alt = ((typeof valor !== 'undefined') && (valor !== null) ? NumeroUtil_1.NumeroUtil.converterValorParaNumero(valor) : registroAnaliseSelecao.alt);
                                }
                                else if (refTitulo && (refTitulo === TextoUtil_1.TextoUtil.converteTextoParaReferencia('FOR'))) {
                                    if ((typeof analiseSelecao.for !== 'undefined') && (analiseSelecao.for !== null)) {
                                        valor = analiseSelecao.for;
                                    }
                                    else if ((typeof analiseSelecao.forma !== 'undefined') && (analiseSelecao.forma !== null)) {
                                        valor = analiseSelecao.forma;
                                    }
                                    registroAnaliseSelecao.for = ((typeof valor !== 'undefined') && (valor !== null) ? NumeroUtil_1.NumeroUtil.converterValorParaNumero(valor) : registroAnaliseSelecao.for);
                                }
                                else if (refTitulo && (refTitulo === TextoUtil_1.TextoUtil.converteTextoParaReferencia('BF'))) {
                                    if ((typeof analiseSelecao.bif !== 'undefined') && (analiseSelecao.bif !== null)) {
                                        valor = analiseSelecao.bif;
                                    }
                                    else if ((typeof analiseSelecao.bf !== 'undefined') && (analiseSelecao.bf !== null)) {
                                        valor = analiseSelecao.bf;
                                    }
                                    registroAnaliseSelecao.bif = ((typeof valor !== 'undefined') && (valor !== null) ? NumeroUtil_1.NumeroUtil.converterValorParaNumero(valor) : registroAnaliseSelecao.bif);
                                }
                                else if (refTitulo && (refTitulo === TextoUtil_1.TextoUtil.converteTextoParaReferencia('CQ'))) {
                                    if ((typeof analiseSelecao.cq !== 'undefined') && (analiseSelecao.cq !== null)) {
                                        valor = analiseSelecao.cq;
                                    }
                                    else if ((typeof analiseSelecao.copaQuebrada !== 'undefined') && (analiseSelecao.copaQuebrada !== null)) {
                                        valor = analiseSelecao.copaQuebrada;
                                    }
                                    registroAnaliseSelecao.cq = ((typeof valor !== 'undefined') && (valor !== null) ? NumeroUtil_1.NumeroUtil.converterValorParaNumero(valor) : registroAnaliseSelecao.cq);
                                }
                                else if (refTitulo && (refTitulo === TextoUtil_1.TextoUtil.converteTextoParaReferencia('FT'))) {
                                    if ((typeof analiseSelecao.ft !== 'undefined') && (analiseSelecao.ft !== null)) {
                                        valor = analiseSelecao.ft;
                                    }
                                    else if ((typeof analiseSelecao.foxTail !== 'undefined') && (analiseSelecao.foxTail !== null)) {
                                        valor = analiseSelecao.foxTail;
                                    }
                                    registroAnaliseSelecao.ft = ((typeof valor !== 'undefined') && (valor !== null) ? NumeroUtil_1.NumeroUtil.converterValorParaNumero(valor) : registroAnaliseSelecao.ft);
                                }
                                dadosPlanilhaDados[keyAnaliseSelecao + 1][keyTitulo] = { v: valor, m: valor, bl: 0, ht: 1 };
                            });
                            if ((typeof registroAnaliseSelecao.trat !== 'undefined') && registroAnaliseSelecao.trat) {
                                let referenciaTratamento = TextoUtil_1.TextoUtil.converteTextoParaReferencia(registroAnaliseSelecao.trat);
                                let tratamentoAgrupado = tratamentosAgrupados.find(x => x.referencia === referenciaTratamento);
                                if ((typeof tratamentoAgrupado !== 'undefined') && tratamentoAgrupado) {
                                    tratamentoAgrupado.contagem++;
                                }
                                else {
                                    tratamentoAgrupado = {
                                        descricao: registroAnaliseSelecao.trat,
                                        referencia: referenciaTratamento,
                                        contagem: 1
                                    };
                                    tratamentosAgrupados.push(tratamentoAgrupado);
                                }
                                angular.forEach(['Vol', 'For', 'Bif', 'Cq', 'Ft'], (campo) => {
                                    let lc_campo = campo.toLowerCase();
                                    if ((typeof registroAnaliseSelecao[lc_campo] !== 'undefined') && (registroAnaliseSelecao[lc_campo] !== null)) {
                                        tratamentoAgrupado['contagem' + campo] = ((typeof tratamentoAgrupado['contagem' + campo] !== 'undefined') && tratamentoAgrupado['contagem' + campo] ? tratamentoAgrupado['contagem' + campo] : 0) + 1;
                                        tratamentoAgrupado['soma' + campo] = ((typeof tratamentoAgrupado['soma' + campo] !== 'undefined') && tratamentoAgrupado['soma' + campo] ? tratamentoAgrupado['soma' + campo] : 0) + registroAnaliseSelecao[lc_campo];
                                    }
                                });
                            }
                        }
                    });
                    let dadosPlanilhaResultados = [];
                    for (let linhaIdx = 0; linhaIdx <= 100; linhaIdx++) {
                        let linha = [];
                        for (let colunaIdx = 0; colunaIdx <= Math.max(60, tratamentosAgrupados.length + 1); colunaIdx++) {
                            let valor = null;
                            linha.push(valor);
                        }
                        dadosPlanilhaResultados.push(linha);
                    }
                    angular.forEach(tratamentosAgrupados, (tratamentoAgrupado, idxTratamentoAgrupado) => {
                        dadosPlanilhaResultados[0][idxTratamentoAgrupado + 1] = { v: 'Progenie', m: 'Progenie', bl: 0, ht: 1 };
                        dadosPlanilhaResultados[1][idxTratamentoAgrupado + 1] = { v: tratamentoAgrupado.descricao, m: tratamentoAgrupado.descricao, bl: 1, ht: 1 };
                        dadosPlanilhaResultados[2][idxTratamentoAgrupado + 1] = { v: tratamentoAgrupado.contagem, m: tratamentoAgrupado.contagem, bl: 0, ht: 1 };
                        angular.forEach(['Vol', 'For', 'Bif', 'Cq', 'Ft'], (campo, idxCampo) => {
                            if ((typeof tratamentoAgrupado['contagem' + campo] !== 'undefined') && (tratamentoAgrupado['contagem' + campo] !== null) && (tratamentoAgrupado['contagem' + campo] > 0)) {
                                tratamentoAgrupado['media' + campo] = NumeroUtil_1.NumeroUtil.arredondarNumero(tratamentoAgrupado['soma' + campo] / tratamentoAgrupado['contagem' + campo], 4);
                                dadosPlanilhaResultados[idxCampo + 3][idxTratamentoAgrupado + 1] = { v: tratamentoAgrupado['media' + campo], m: tratamentoAgrupado['media' + campo], bl: 0, ht: 1 };
                            }
                        });
                    });
                    dadosPlanilhaResultados[2][0] = { v: 'Número', m: 'Número', bl: 0, ht: 1 };
                    dadosPlanilhaResultados[3][0] = { v: 'Volume', m: 'Volume', bl: 0, ht: 1 };
                    dadosPlanilhaResultados[4][0] = { v: 'Forma', m: 'Forma', bl: 0, ht: 1 };
                    dadosPlanilhaResultados[5][0] = { v: 'Bifurcação', m: 'Bifurcação', bl: 0, ht: 1 };
                    dadosPlanilhaResultados[6][0] = { v: 'Copa Quebrada', m: 'Copa Quebrada', bl: 0, ht: 1 };
                    dadosPlanilhaResultados[7][0] = { v: 'Fox Tail', m: 'Fox Tail', bl: 0, ht: 1 };
                    let dadosPlanilhaInformacoes = [];
                    let largurasColunasPlanilhaInformacoes = {};
                    let bordasCelulasPlanilhaInformacoes = [];
                    let mergeCelulasPlanilhaInformacoes = {};
                    const linhaIdxTabelaInformacoes = 4;
                    for (let linhaIdx = 0; linhaIdx <= Math.max(250, tratamentosAgrupados.length + linhaIdxTabelaInformacoes + 10); linhaIdx++) {
                        let linha = [];
                        for (let colunaIdx = 0; colunaIdx <= 60; colunaIdx++) {
                            let valor = null;
                            linha.push(valor);
                        }
                        dadosPlanilhaInformacoes.push(linha);
                    }
                    dadosPlanilhaInformacoes[1][1] = { v: 'Informações Sobre o Teste Genético', m: 'Número', bl: 1, ht: 0 };
                    mergeCelulasPlanilhaInformacoes['1_1'] = { r: 1, c: 1, rs: 1, cs: 6 };
                    dadosPlanilhaInformacoes[linhaIdxTabelaInformacoes][1] = { v: 'Número', m: 'Número', bl: 1, ht: 0, fc: '#000000', bg: '#ffecd9' };
                    dadosPlanilhaInformacoes[linhaIdxTabelaInformacoes][2] = { v: 'Médias dos Tratamentos', m: 'Médias dos Tratamentos', bl: 1, ht: 0, fc: '#000000', bg: '#ffecd9' };
                    dadosPlanilhaInformacoes[linhaIdxTabelaInformacoes + 1][2] = { v: 'Volume', m: 'Volume', bl: 1, ht: 0, fc: '#000000', bg: '#ffecd9' };
                    dadosPlanilhaInformacoes[linhaIdxTabelaInformacoes + 1][3] = { v: 'Forma', m: 'Forma', bl: 1, ht: 0, fc: '#000000', bg: '#ffecd9' };
                    dadosPlanilhaInformacoes[linhaIdxTabelaInformacoes + 1][4] = { v: 'Bifurc.', m: 'Bifurc.', bl: 1, ht: 0, fc: '#000000', bg: '#ffecd9' };
                    dadosPlanilhaInformacoes[linhaIdxTabelaInformacoes + 1][5] = { v: 'Copa Queb.', m: 'Copa Queb.', bl: 1, ht: 0, fc: '#000000', bg: '#ffecd9' };
                    dadosPlanilhaInformacoes[linhaIdxTabelaInformacoes + 1][6] = { v: 'Fox Tail', m: 'Fox Tail', bl: 1, ht: 0, fc: '#000000', bg: '#ffecd9' };
                    largurasColunasPlanilhaInformacoes[0] = 20;
                    largurasColunasPlanilhaInformacoes[1] = 90;
                    mergeCelulasPlanilhaInformacoes[linhaIdxTabelaInformacoes + '_1'] = { r: linhaIdxTabelaInformacoes, c: 1, rs: 2, cs: 1 };
                    mergeCelulasPlanilhaInformacoes[linhaIdxTabelaInformacoes + '_2'] = { r: linhaIdxTabelaInformacoes, c: 2, rs: 1, cs: 5 };
                    for (let linhaIdx = linhaIdxTabelaInformacoes; linhaIdx <= linhaIdxTabelaInformacoes + 1; linhaIdx++) {
                        for (let colunaIdx = 1; colunaIdx <= 6; colunaIdx++) {
                            bordasCelulasPlanilhaInformacoes.push({ row: [linhaIdx, linhaIdx], column: [colunaIdx, colunaIdx] });
                        }
                    }
                    angular.forEach(tratamentosAgrupados, (tratamentoAgrupado, idxTratamentoAgrupado) => {
                        let linhaIdx = linhaIdxTabelaInformacoes + 2 + idxTratamentoAgrupado;
                        dadosPlanilhaInformacoes[linhaIdx][1] = { v: tratamentoAgrupado.descricao, m: tratamentoAgrupado.descricao, bl: 1, ht: 1, fc: '#000000', bg: '#ffffcc' };
                        bordasCelulasPlanilhaInformacoes.push({
                            row: [linhaIdx, linhaIdx],
                            column: [1, 1]
                        });
                        angular.forEach(['Vol', 'For', 'Bif', 'Cq', 'Ft'], (campo, idxCampo) => {
                            let valor = null;
                            if ((typeof tratamentoAgrupado['contagem' + campo] !== 'undefined') && (tratamentoAgrupado['contagem' + campo] !== null) && (tratamentoAgrupado['contagem' + campo] > 0)) {
                                tratamentoAgrupado['media' + campo] = NumeroUtil_1.NumeroUtil.arredondarNumero(tratamentoAgrupado['soma' + campo] / tratamentoAgrupado['contagem' + campo], 4);
                                valor = (campo === 'Vol') ? tratamentoAgrupado['media' + campo] : (NumeroUtil_1.NumeroUtil.arredondarNumero(tratamentoAgrupado['media' + campo] * 100, 1) + '%');
                            }
                            dadosPlanilhaInformacoes[linhaIdx][idxCampo + 2] = { v: valor, m: valor, bl: 0, ht: 1 };
                            bordasCelulasPlanilhaInformacoes.push({
                                row: [linhaIdx, linhaIdx],
                                column: [idxCampo + 2, idxCampo + 2]
                            });
                        });
                    });
                    globalThis.luckysheet.setSheetAdd({
                        sheetObject: {
                            name: tituloPlanilhaDados,
                            data: dadosPlanilhaDados
                        },
                        order: 0
                    });
                    globalThis.luckysheet.setSheetAdd({
                        sheetObject: {
                            name: tituloPlanilhaResultados,
                            data: dadosPlanilhaResultados
                        },
                        order: 0
                    });
                    globalThis.luckysheet.setSheetAdd({
                        sheetObject: {
                            name: tituloPlanilhaInformacoes,
                            data: dadosPlanilhaInformacoes,
                            config: {
                                columnlen: largurasColunasPlanilhaInformacoes,
                                merge: mergeCelulasPlanilhaInformacoes,
                                borderInfo: [{
                                        rangeType: 'range',
                                        borderType: 'border-all',
                                        color: '#000',
                                        style: '1',
                                        range: bordasCelulasPlanilhaInformacoes
                                    }]
                            },
                        },
                        order: 0
                    });
                }
                let planilha = new Planilha_1.Planilha();
                planilha.status = true;
                planilha.modulo = 'ResultadoSelegenAnaliseSelecaoTesteGenetico';
                planilha.operacao = ehImportacao ? 'Importar' : 'Salvar';
                planilha.nome = LuckysheetUtil_1.LuckysheetUtil.nomePlanilha();
                planilha.usuarioInclusao = ctrl.usuarioSimplificado();
                planilha.dataHoraInclusao = new Date();
                planilha.gzcompress = true;
                planilha.dados = LuckysheetUtil_1.LuckysheetUtil.compactarDadosPlanilha();
                planilha = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANILHA)
                    .criar(planilha);
                ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas.push(planilha);
                ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada = planilha;
                yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.RESULTADO_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.RESULTADO_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                    .atualizar(ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static exibirModalResultadoSelegenAnaliseSelecaoTesteGenetico(ctrl, dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.testeGeneticoSelecionado === 'undefined') || !ctrl.testeGeneticoSelecionado) {
                return;
            }
            try {
                ctrl.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                ctrl.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoVisualizar = true;
                ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoImportacao = false;
                ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoHistorico = false;
                if ((typeof dados === 'undefined') || !dados || (typeof dados.id === 'undefined') || !dados.id) {
                    let lista = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.RESULTADO_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.RESULTADO_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                        .filtro({
                        listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                        testeGenetico: ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                            (typeof ctrl.testeGeneticoSelecionado.id !== 'undefined') && ctrl.testeGeneticoSelecionado.id
                            ? ctrl.testeGeneticoSelecionado.id : null),
                        anoAnalise: ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                            (typeof ctrl.testeGeneticoSelecionado.anoAnalise !== 'undefined') && ctrl.testeGeneticoSelecionado.anoAnalise
                            ? ctrl.testeGeneticoSelecionado.anoAnalise : null)
                    })
                        .listar();
                    if ((typeof lista !== 'undefined') && lista) {
                        dados = { id: null, editar: true };
                        angular.forEach(lista, (item) => {
                            if ((typeof item.id !== 'undefined') && item.id) {
                                dados.id = item.id;
                            }
                        });
                    }
                }
                if ((typeof dados !== 'undefined') && dados && (typeof dados.id !== 'undefined') && dados.id) {
                    ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.RESULTADO_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.RESULTADO_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                        .id(dados.id)
                        .ver();
                    if ((typeof ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado) {
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        if (ctrl.ehCelular) {
                            alert(`Registro "${dados.id} não encontrado!`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                text: `Registro "${dados.id}" não encontrado.`,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        return;
                    }
                    if ((typeof ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.criacao === 'string') && ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.criacao) {
                        ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.criacao = new Date(ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.criacao);
                    }
                    if ((typeof ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao === 'string') && ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao) {
                        ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao = new Date(ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao);
                    }
                    if ((typeof ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao === 'string') && ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao) {
                        ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao = new Date(ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao);
                    }
                    if ((typeof dados.visualizar !== 'undefined') && dados.visualizar) {
                        ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoVisualizar = true;
                    }
                    else if ((typeof dados.editar !== 'undefined') && dados.editar) {
                        ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoVisualizar = false;
                    }
                    else if ((typeof dados.excluir !== 'undefined') && dados.excluir) {
                        ctrl.notificacao
                            .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                            .then((result) => __awaiter(this, void 0, void 0, function* () {
                            if (result.value) {
                                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.RESULTADO_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.RESULTADO_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                                    .id(dados.id)
                                    .remover();
                                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                                ctrl.listaOpcoesAnaliseSelecaoTesteGenetico = yield ctrl.dadosListaAnaliseSelecaoTesteGenetico();
                                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
                            }
                        }));
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                }
                else {
                    ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado = new ResultadoSelegenAnaliseSelecaoTesteGenetico_1.ResultadoSelegenAnaliseSelecaoTesteGenetico();
                    ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.status = true;
                    ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.criacao = new Date();
                    ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.criacao.setMilliseconds(0);
                    ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.testeGenetico = ctrl.testeGeneticoSimplificado();
                    ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.anoAnalise = ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                        (typeof ctrl.testeGeneticoSelecionado.anoAnalise !== 'undefined') && ctrl.testeGeneticoSelecionado.anoAnalise
                        ? ctrl.testeGeneticoSelecionado.anoAnalise : null);
                    ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao = new Date();
                    ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao.setMilliseconds(0);
                    ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.usuarioInclusao = ctrl.usuarioSimplificado();
                    ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.RESULTADO_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.RESULTADO_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO)
                        .criar(ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado);
                    ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoVisualizar = false;
                }
                if ((typeof ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.anexos === 'undefined') || (ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.anexos === null)) {
                    ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.anexos = new Array();
                }
                ctrl.listaOpcoesRankingResultadoSelegenAnaliseSelecaoTesteGenetico = yield ctrl.dadosListaRankingResultadoSelegenAnaliseSelecaoTesteGenetico();
                ctrl.listaOpcoesVariaveisResultadoSelegenAnaliseSelecaoTesteGenetico = yield ctrl.dadosListaVariaveisResultadoSelegenAnaliseSelecaoTesteGenetico();
                ctrl.listaOpcoesResultadoSelegenAnaliseSelecaoTesteGenetico = yield ctrl.dadosListaResultadoSelegenAnaliseSelecaoTesteGenetico();
                ctrl.limparPlanilhasCarregadas();
                let planilhaId = null;
                let dadosPlanilha = [];
                let dadosAnexo = null;
                if ((typeof ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada !== 'undefined') && ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada &&
                    (typeof ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada.id !== 'undefined') && ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada.id) {
                    planilhaId = ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada.id;
                }
                else {
                    if ((typeof ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas !== 'undefined') && ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas) {
                        angular.forEach(ctrl.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado.planilhas, (planilha) => {
                            if ((typeof planilha !== 'undefined') && planilha && (typeof planilha.id !== 'undefined') && planilha.id) {
                                planilhaId = planilha.id;
                            }
                        });
                    }
                }
                if ((typeof planilhaId !== 'undefined') && planilhaId) {
                    let planilha = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANILHA)
                        .id(planilhaId)
                        .ver();
                    if ((typeof planilha !== 'undefined') && planilha &&
                        (typeof planilha.dados !== 'undefined') && planilha.dados) {
                        dadosPlanilha = LuckysheetUtil_1.LuckysheetUtil.descompactarDadosPlanilha(planilha.dados);
                    }
                }
                ctrl.carregarPlanilha('planilha_resultado_selegen_analise_selecao_teste_genetico', dadosPlanilha, dadosAnexo);
                ctrl.modalResultadoSelegenAnaliseSelecaoTesteGenetico = true;
                angular.element('body').css('overflow-y', 'hidden');
                MenuUtil_1.MenuUtil.ajustaZIndexMenusModalPlanilha();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.scopeApply(() => {
                    MenuUtil_1.MenuUtil.ajustaZIndexMenusModalPlanilha();
                });
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static ocultarModalResultadoSelegenAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.modalResultadoSelegenAnaliseSelecaoTesteGenetico = false;
            ctrl.limparPlanilhasCarregadas();
            angular.element('body').css('overflow-y', '');
            MenuUtil_1.MenuUtil.restauraZIndexMenus();
            globalThis.posicaoTopoAntesModal = ctrl.posicaoTopoAntesModal;
            ctrl.scopeApply(() => {
                MenuUtil_1.MenuUtil.restauraZIndexMenus();
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                        (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    static importarAnaliseAnoAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.analiseAnoAnaliseSelecaoTesteGeneticoImportacao = true;
            ctrl.analiseAnoAnaliseSelecaoTesteGeneticoImportacaoAnexos = new Array();
            ctrl.analiseAnoAnaliseSelecaoTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static finalizarImportacaoAnaliseAnoAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
                if ((typeof ctrl.analiseAnoAnaliseSelecaoTesteGeneticoImportacaoAnexos !== 'undefined') && ctrl.analiseAnoAnaliseSelecaoTesteGeneticoImportacaoAnexos &&
                    (ctrl.analiseAnoAnaliseSelecaoTesteGeneticoImportacaoAnexos.length > 0)) {
                    if ((typeof ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado) {
                        ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado = new AnaliseAnoAnaliseSelecaoTesteGenetico_1.AnaliseAnoAnaliseSelecaoTesteGenetico();
                    }
                    if ((typeof ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.planilhas) {
                        ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.planilhas = new Array();
                    }
                    if ((typeof ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.anexos === 'undefined') || !ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.anexos) {
                        ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.anexos = new Array();
                    }
                    let anexoSelecionado = null;
                    angular.forEach(ctrl.analiseAnoAnaliseSelecaoTesteGeneticoImportacaoAnexos, (anexo) => {
                        ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.anexos.push(anexo);
                        anexoSelecionado = anexo;
                    });
                    yield ctrl.limparPlanilhasCarregadas();
                    yield ctrl.carregarPlanilha('planilha_analise_ano_analise_selecao_teste_genetico', null, anexoSelecionado);
                    setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                        ctrl.salvarAnaliseAnoAnaliseSelecaoTesteGenetico(true);
                    }), 1500, ctrl);
                }
                ctrl.analiseAnoAnaliseSelecaoTesteGeneticoImportacao = false;
                ctrl.analiseAnoAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
                ctrl.analiseAnoAnaliseSelecaoTesteGeneticoHistorico = false;
                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static cancelarImportacaoAnaliseAnoAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.analiseAnoAnaliseSelecaoTesteGeneticoImportacao = false;
            ctrl.analiseAnoAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
            ctrl.analiseAnoAnaliseSelecaoTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static exportarAnaliseAnoAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                return;
            }
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
            try {
                if ((typeof globalThis.luckysheet === 'undefined') || !globalThis.luckysheet) {
                    throw new Error('Plugin "luckysheet" não carregado.');
                }
                LuckysheetUtil_1.LuckysheetUtil.exportXLSX(globalThis.luckysheet);
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    static historicoAnaliseAnoAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.analiseAnoAnaliseSelecaoTesteGeneticoImportacao = false;
            ctrl.analiseAnoAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
            ctrl.analiseAnoAnaliseSelecaoTesteGeneticoHistorico = true;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static selecionarHistoricoAnaliseAnoAnaliseSelecaoTesteGenetico(ctrl, keyPlanilha) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                return;
            }
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
            try {
                if ((typeof ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado) {
                    ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado = new AnaliseAnoAnaliseSelecaoTesteGenetico_1.AnaliseAnoAnaliseSelecaoTesteGenetico();
                }
                if ((typeof ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.planilhas) {
                    ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.planilhas = new Array();
                }
                if ((typeof keyPlanilha !== 'undefined') && (keyPlanilha !== null) && (keyPlanilha >= 0) &&
                    (keyPlanilha <= ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.planilhas.length - 1)) {
                    const planilhaSelecionada = ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.planilhas[keyPlanilha];
                    ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada = planilhaSelecionada;
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_ANO_ANALISE_SELECAO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANALISE_ANO_ANALISE_SELECAO_TESTE_GENETICO)
                        .atualizar(ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado);
                    yield ctrl.limparPlanilhasCarregadas();
                    setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                        ctrl.exibirModalAnaliseAnoAnaliseSelecaoTesteGenetico();
                    }), 500, ctrl);
                    ctrl.analiseAnoAnaliseSelecaoTesteGeneticoImportacao = false;
                    ctrl.analiseAnoAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
                    ctrl.analiseAnoAnaliseSelecaoTesteGeneticoHistorico = false;
                }
                else {
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.notificacao.alerta('Não foi possível carregar a planilha.', 'A planilha selecionada não é válida ou foi removida.');
                    return;
                }
                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    static cancelarHistoricoAnaliseAnoAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.analiseAnoAnaliseSelecaoTesteGeneticoImportacao = false;
            ctrl.analiseAnoAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
            ctrl.analiseAnoAnaliseSelecaoTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static salvarAnaliseAnoAnaliseSelecaoTesteGenetico(ctrl, ehImportacao = false) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof ctrl.testeGeneticoSelecionado === 'undefined') || !ctrl.testeGeneticoSelecionado) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
                if ((typeof ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado) {
                    ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado = new AnaliseAnoAnaliseSelecaoTesteGenetico_1.AnaliseAnoAnaliseSelecaoTesteGenetico();
                }
                if ((typeof ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.planilhas) {
                    ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.planilhas = new Array();
                }
                let planilha = new Planilha_1.Planilha();
                planilha.status = true;
                planilha.modulo = 'AnaliseAnoAnaliseSelecaoTesteGenetico';
                planilha.operacao = ehImportacao ? 'Importar' : 'Salvar';
                planilha.nome = LuckysheetUtil_1.LuckysheetUtil.nomePlanilha();
                planilha.usuarioInclusao = ctrl.usuarioSimplificado();
                planilha.dataHoraInclusao = new Date();
                planilha.gzcompress = true;
                planilha.dados = LuckysheetUtil_1.LuckysheetUtil.compactarDadosPlanilha();
                planilha = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANILHA)
                    .criar(planilha);
                ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.planilhas.push(planilha);
                ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada = planilha;
                yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_ANO_ANALISE_SELECAO_TESTE_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANALISE_ANO_ANALISE_SELECAO_TESTE_GENETICO)
                    .atualizar(ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static exibirModalAnaliseAnoAnaliseSelecaoTesteGenetico(ctrl, dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.testeGeneticoSelecionado === 'undefined') || !ctrl.testeGeneticoSelecionado) {
                return;
            }
            try {
                ctrl.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                ctrl.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                ctrl.analiseAnoAnaliseSelecaoTesteGeneticoVisualizar = true;
                ctrl.analiseAnoAnaliseSelecaoTesteGeneticoImportacao = false;
                ctrl.analiseAnoAnaliseSelecaoTesteGeneticoHistorico = false;
                if ((typeof dados === 'undefined') || !dados || (typeof dados.id === 'undefined') || !dados.id) {
                    let lista = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_ANO_ANALISE_SELECAO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANALISE_ANO_ANALISE_SELECAO_TESTE_GENETICO)
                        .filtro({
                        listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                        testeGenetico: ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                            (typeof ctrl.testeGeneticoSelecionado.id !== 'undefined') && ctrl.testeGeneticoSelecionado.id
                            ? ctrl.testeGeneticoSelecionado.id : null),
                        anoAnalise: ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                            (typeof ctrl.testeGeneticoSelecionado.anoAnalise !== 'undefined') && ctrl.testeGeneticoSelecionado.anoAnalise
                            ? ctrl.testeGeneticoSelecionado.anoAnalise : null)
                    })
                        .listar();
                    if ((typeof lista !== 'undefined') && lista) {
                        dados = { id: null, editar: true };
                        angular.forEach(lista, (item) => {
                            if ((typeof item.id !== 'undefined') && item.id) {
                                dados.id = item.id;
                            }
                        });
                    }
                }
                if ((typeof dados !== 'undefined') && dados && (typeof dados.id !== 'undefined') && dados.id) {
                    ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_ANO_ANALISE_SELECAO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANALISE_ANO_ANALISE_SELECAO_TESTE_GENETICO)
                        .id(dados.id)
                        .ver();
                    if ((typeof ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado) {
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        if (ctrl.ehCelular) {
                            alert(`Registro "${dados.id} não encontrado!`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                text: `Registro "${dados.id}" não encontrado.`,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        return;
                    }
                    if ((typeof ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.criacao === 'string') && ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.criacao) {
                        ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.criacao = new Date(ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.criacao);
                    }
                    if ((typeof ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao === 'string') && ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao) {
                        ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao = new Date(ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao);
                    }
                    if ((typeof ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao === 'string') && ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao) {
                        ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao = new Date(ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao);
                    }
                    if ((typeof dados.visualizar !== 'undefined') && dados.visualizar) {
                        ctrl.analiseAnoAnaliseSelecaoTesteGeneticoVisualizar = true;
                    }
                    else if ((typeof dados.editar !== 'undefined') && dados.editar) {
                        ctrl.analiseAnoAnaliseSelecaoTesteGeneticoVisualizar = false;
                    }
                    else if ((typeof dados.excluir !== 'undefined') && dados.excluir) {
                        ctrl.notificacao
                            .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                            .then((result) => __awaiter(this, void 0, void 0, function* () {
                            if (result.value) {
                                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_ANO_ANALISE_SELECAO_TESTE_GENETICO)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANALISE_ANO_ANALISE_SELECAO_TESTE_GENETICO)
                                    .id(dados.id)
                                    .remover();
                                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                                ctrl.listaOpcoesAnaliseSelecaoTesteGenetico = yield ctrl.dadosListaAnaliseSelecaoTesteGenetico();
                                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
                            }
                        }));
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                }
                else {
                    ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado = new AnaliseAnoAnaliseSelecaoTesteGenetico_1.AnaliseAnoAnaliseSelecaoTesteGenetico();
                    ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.status = true;
                    ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.criacao = new Date();
                    ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.criacao.setMilliseconds(0);
                    ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.testeGenetico = ctrl.testeGeneticoSimplificado();
                    ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.anoAnalise = ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                        (typeof ctrl.testeGeneticoSelecionado.anoAnalise !== 'undefined') && ctrl.testeGeneticoSelecionado.anoAnalise
                        ? ctrl.testeGeneticoSelecionado.anoAnalise : null);
                    ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao = new Date();
                    ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao.setMilliseconds(0);
                    ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.usuarioInclusao = ctrl.usuarioSimplificado();
                    ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_ANO_ANALISE_SELECAO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANALISE_ANO_ANALISE_SELECAO_TESTE_GENETICO)
                        .criar(ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado);
                    ctrl.analiseAnoAnaliseSelecaoTesteGeneticoVisualizar = false;
                }
                if ((typeof ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.anexos === 'undefined') || (ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.anexos === null)) {
                    ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.anexos = new Array();
                }
                ctrl.listaOpcoesAnaliseAnoAnaliseSelecaoTesteGenetico = yield ctrl.dadosListaAnaliseAnoAnaliseSelecaoTesteGenetico();
                ctrl.limparPlanilhasCarregadas();
                let planilhaId = null;
                let dadosPlanilha = [];
                let dadosAnexo = null;
                if ((typeof ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada !== 'undefined') && ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada &&
                    (typeof ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada.id !== 'undefined') && ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada.id) {
                    planilhaId = ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada.id;
                }
                else {
                    if ((typeof ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.planilhas !== 'undefined') && ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.planilhas) {
                        angular.forEach(ctrl.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado.planilhas, (planilha) => {
                            if ((typeof planilha !== 'undefined') && planilha && (typeof planilha.id !== 'undefined') && planilha.id) {
                                planilhaId = planilha.id;
                            }
                        });
                    }
                }
                if ((typeof planilhaId !== 'undefined') && planilhaId) {
                    let planilha = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANILHA)
                        .id(planilhaId)
                        .ver();
                    if ((typeof planilha !== 'undefined') && planilha &&
                        (typeof planilha.dados !== 'undefined') && planilha.dados) {
                        dadosPlanilha = LuckysheetUtil_1.LuckysheetUtil.descompactarDadosPlanilha(planilha.dados);
                    }
                }
                ctrl.carregarPlanilha('planilha_analise_ano_analise_selecao_teste_genetico', dadosPlanilha, dadosAnexo);
                ctrl.modalAnaliseAnoAnaliseSelecaoTesteGenetico = true;
                angular.element('body').css('overflow-y', 'hidden');
                MenuUtil_1.MenuUtil.ajustaZIndexMenusModalPlanilha();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.scopeApply(() => {
                    MenuUtil_1.MenuUtil.ajustaZIndexMenusModalPlanilha();
                });
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static ocultarModalAnaliseAnoAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.modalAnaliseAnoAnaliseSelecaoTesteGenetico = false;
            ctrl.limparPlanilhasCarregadas();
            angular.element('body').css('overflow-y', '');
            MenuUtil_1.MenuUtil.restauraZIndexMenus();
            globalThis.posicaoTopoAntesModal = ctrl.posicaoTopoAntesModal;
            ctrl.scopeApply(() => {
                MenuUtil_1.MenuUtil.restauraZIndexMenus();
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                        (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    static importarAnaliseBlupAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.analiseBlupAnaliseSelecaoTesteGeneticoImportacao = true;
            ctrl.analiseBlupAnaliseSelecaoTesteGeneticoImportacaoAnexos = new Array();
            ctrl.analiseBlupAnaliseSelecaoTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static finalizarImportacaoAnaliseBlupAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
                if ((typeof ctrl.analiseBlupAnaliseSelecaoTesteGeneticoImportacaoAnexos !== 'undefined') && ctrl.analiseBlupAnaliseSelecaoTesteGeneticoImportacaoAnexos &&
                    (ctrl.analiseBlupAnaliseSelecaoTesteGeneticoImportacaoAnexos.length > 0)) {
                    if ((typeof ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado) {
                        ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado = new AnaliseBlupAnaliseSelecaoTesteGenetico_1.AnaliseBlupAnaliseSelecaoTesteGenetico();
                    }
                    if ((typeof ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.planilhas) {
                        ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.planilhas = new Array();
                    }
                    if ((typeof ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.anexos === 'undefined') || !ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.anexos) {
                        ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.anexos = new Array();
                    }
                    let anexoSelecionado = null;
                    angular.forEach(ctrl.analiseBlupAnaliseSelecaoTesteGeneticoImportacaoAnexos, (anexo) => {
                        ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.anexos.push(anexo);
                        anexoSelecionado = anexo;
                    });
                    yield ctrl.limparPlanilhasCarregadas();
                    yield ctrl.carregarPlanilha('planilha_analise_blup_analise_selecao_teste_genetico', null, anexoSelecionado);
                    setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                        ctrl.salvarAnaliseBlupAnaliseSelecaoTesteGenetico(true);
                    }), 1500, ctrl);
                }
                ctrl.analiseBlupAnaliseSelecaoTesteGeneticoImportacao = false;
                ctrl.analiseBlupAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
                ctrl.analiseBlupAnaliseSelecaoTesteGeneticoHistorico = false;
                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static cancelarImportacaoAnaliseBlupAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.analiseBlupAnaliseSelecaoTesteGeneticoImportacao = false;
            ctrl.analiseBlupAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
            ctrl.analiseBlupAnaliseSelecaoTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static exportarAnaliseBlupAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                return;
            }
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
            try {
                if ((typeof globalThis.luckysheet === 'undefined') || !globalThis.luckysheet) {
                    throw new Error('Plugin "luckysheet" não carregado.');
                }
                LuckysheetUtil_1.LuckysheetUtil.exportXLSX(globalThis.luckysheet);
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    static historicoAnaliseBlupAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.analiseBlupAnaliseSelecaoTesteGeneticoImportacao = false;
            ctrl.analiseBlupAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
            ctrl.analiseBlupAnaliseSelecaoTesteGeneticoHistorico = true;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static selecionarHistoricoAnaliseBlupAnaliseSelecaoTesteGenetico(ctrl, keyPlanilha) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                return;
            }
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
            try {
                if ((typeof ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado) {
                    ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado = new AnaliseBlupAnaliseSelecaoTesteGenetico_1.AnaliseBlupAnaliseSelecaoTesteGenetico();
                }
                if ((typeof ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.planilhas) {
                    ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.planilhas = new Array();
                }
                if ((typeof keyPlanilha !== 'undefined') && (keyPlanilha !== null) && (keyPlanilha >= 0) &&
                    (keyPlanilha <= ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.planilhas.length - 1)) {
                    const planilhaSelecionada = ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.planilhas[keyPlanilha];
                    ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada = planilhaSelecionada;
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_BLUP_ANALISE_SELECAO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANALISE_BLUP_ANALISE_SELECAO_TESTE_GENETICO)
                        .atualizar(ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado);
                    yield ctrl.limparPlanilhasCarregadas();
                    setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                        ctrl.exibirModalAnaliseBlupAnaliseSelecaoTesteGenetico();
                    }), 500, ctrl);
                    ctrl.analiseBlupAnaliseSelecaoTesteGeneticoImportacao = false;
                    ctrl.analiseBlupAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
                    ctrl.analiseBlupAnaliseSelecaoTesteGeneticoHistorico = false;
                }
                else {
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.notificacao.alerta('Não foi possível carregar a planilha.', 'A planilha selecionada não é válida ou foi removida.');
                    return;
                }
                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    static cancelarHistoricoAnaliseBlupAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.analiseBlupAnaliseSelecaoTesteGeneticoImportacao = false;
            ctrl.analiseBlupAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
            ctrl.analiseBlupAnaliseSelecaoTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static salvarAnaliseBlupAnaliseSelecaoTesteGenetico(ctrl, ehImportacao = false) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof ctrl.testeGeneticoSelecionado === 'undefined') || !ctrl.testeGeneticoSelecionado) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
                if ((typeof ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado) {
                    ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado = new AnaliseBlupAnaliseSelecaoTesteGenetico_1.AnaliseBlupAnaliseSelecaoTesteGenetico();
                }
                if ((typeof ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.planilhas) {
                    ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.planilhas = new Array();
                }
                let planilha = new Planilha_1.Planilha();
                planilha.status = true;
                planilha.modulo = 'AnaliseBlupAnaliseSelecaoTesteGenetico';
                planilha.operacao = ehImportacao ? 'Importar' : 'Salvar';
                planilha.nome = LuckysheetUtil_1.LuckysheetUtil.nomePlanilha();
                planilha.usuarioInclusao = ctrl.usuarioSimplificado();
                planilha.dataHoraInclusao = new Date();
                planilha.gzcompress = true;
                planilha.dados = LuckysheetUtil_1.LuckysheetUtil.compactarDadosPlanilha();
                planilha = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANILHA)
                    .criar(planilha);
                ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.planilhas.push(planilha);
                ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada = planilha;
                yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_BLUP_ANALISE_SELECAO_TESTE_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANALISE_BLUP_ANALISE_SELECAO_TESTE_GENETICO)
                    .atualizar(ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static exibirModalAnaliseBlupAnaliseSelecaoTesteGenetico(ctrl, dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.testeGeneticoSelecionado === 'undefined') || !ctrl.testeGeneticoSelecionado) {
                return;
            }
            try {
                ctrl.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                ctrl.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                ctrl.analiseBlupAnaliseSelecaoTesteGeneticoVisualizar = true;
                ctrl.analiseBlupAnaliseSelecaoTesteGeneticoImportacao = false;
                ctrl.analiseBlupAnaliseSelecaoTesteGeneticoHistorico = false;
                if ((typeof dados === 'undefined') || !dados || (typeof dados.id === 'undefined') || !dados.id) {
                    let lista = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_BLUP_ANALISE_SELECAO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANALISE_BLUP_ANALISE_SELECAO_TESTE_GENETICO)
                        .filtro({
                        listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                        testeGenetico: ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                            (typeof ctrl.testeGeneticoSelecionado.id !== 'undefined') && ctrl.testeGeneticoSelecionado.id
                            ? ctrl.testeGeneticoSelecionado.id : null),
                        anoAnalise: ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                            (typeof ctrl.testeGeneticoSelecionado.anoAnalise !== 'undefined') && ctrl.testeGeneticoSelecionado.anoAnalise
                            ? ctrl.testeGeneticoSelecionado.anoAnalise : null)
                    })
                        .listar();
                    if ((typeof lista !== 'undefined') && lista) {
                        dados = { id: null, editar: true };
                        angular.forEach(lista, (item) => {
                            if ((typeof item.id !== 'undefined') && item.id) {
                                dados.id = item.id;
                            }
                        });
                    }
                }
                if ((typeof dados !== 'undefined') && dados && (typeof dados.id !== 'undefined') && dados.id) {
                    ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_BLUP_ANALISE_SELECAO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANALISE_BLUP_ANALISE_SELECAO_TESTE_GENETICO)
                        .id(dados.id)
                        .ver();
                    if ((typeof ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado) {
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        if (ctrl.ehCelular) {
                            alert(`Registro "${dados.id} não encontrado!`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                text: `Registro "${dados.id}" não encontrado.`,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        return;
                    }
                    if ((typeof ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.criacao === 'string') && ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.criacao) {
                        ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.criacao = new Date(ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.criacao);
                    }
                    if ((typeof ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao === 'string') && ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao) {
                        ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao = new Date(ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao);
                    }
                    if ((typeof ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao === 'string') && ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao) {
                        ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao = new Date(ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao);
                    }
                    if ((typeof dados.visualizar !== 'undefined') && dados.visualizar) {
                        ctrl.analiseBlupAnaliseSelecaoTesteGeneticoVisualizar = true;
                    }
                    else if ((typeof dados.editar !== 'undefined') && dados.editar) {
                        ctrl.analiseBlupAnaliseSelecaoTesteGeneticoVisualizar = false;
                    }
                    else if ((typeof dados.excluir !== 'undefined') && dados.excluir) {
                        ctrl.notificacao
                            .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                            .then((result) => __awaiter(this, void 0, void 0, function* () {
                            if (result.value) {
                                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_BLUP_ANALISE_SELECAO_TESTE_GENETICO)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANALISE_BLUP_ANALISE_SELECAO_TESTE_GENETICO)
                                    .id(dados.id)
                                    .remover();
                                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                                ctrl.listaOpcoesAnaliseSelecaoTesteGenetico = yield ctrl.dadosListaAnaliseSelecaoTesteGenetico();
                                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
                            }
                        }));
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                }
                else {
                    ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado = new AnaliseBlupAnaliseSelecaoTesteGenetico_1.AnaliseBlupAnaliseSelecaoTesteGenetico();
                    ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.status = true;
                    ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.criacao = new Date();
                    ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.criacao.setMilliseconds(0);
                    ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.testeGenetico = ctrl.testeGeneticoSimplificado();
                    ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.anoAnalise = ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                        (typeof ctrl.testeGeneticoSelecionado.anoAnalise !== 'undefined') && ctrl.testeGeneticoSelecionado.anoAnalise
                        ? ctrl.testeGeneticoSelecionado.anoAnalise : null);
                    ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao = new Date();
                    ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao.setMilliseconds(0);
                    ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.usuarioInclusao = ctrl.usuarioSimplificado();
                    ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_BLUP_ANALISE_SELECAO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANALISE_BLUP_ANALISE_SELECAO_TESTE_GENETICO)
                        .criar(ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado);
                    ctrl.analiseBlupAnaliseSelecaoTesteGeneticoVisualizar = false;
                }
                if ((typeof ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.anexos === 'undefined') || (ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.anexos === null)) {
                    ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.anexos = new Array();
                }
                ctrl.listaOpcoesParametrosAnaliseBlupAnaliseSelecaoTesteGenetico = yield ctrl.dadosListaParametrosAnaliseBlupAnaliseSelecaoTesteGenetico();
                ctrl.listaOpcoesLimitesReferenciaAnaliseBlupAnaliseSelecaoTesteGenetico = yield ctrl.dadosListaLimitesReferenciaAnaliseBlupAnaliseSelecaoTesteGenetico();
                ctrl.listaOpcoesAnaliseBlupAnaliseSelecaoTesteGenetico = yield ctrl.dadosListaAnaliseBlupAnaliseSelecaoTesteGenetico();
                ctrl.limparPlanilhasCarregadas();
                let planilhaId = null;
                let dadosPlanilha = [];
                let dadosAnexo = null;
                if ((typeof ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada !== 'undefined') && ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada &&
                    (typeof ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada.id !== 'undefined') && ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada.id) {
                    planilhaId = ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada.id;
                }
                else {
                    if ((typeof ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.planilhas !== 'undefined') && ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.planilhas) {
                        angular.forEach(ctrl.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado.planilhas, (planilha) => {
                            if ((typeof planilha !== 'undefined') && planilha && (typeof planilha.id !== 'undefined') && planilha.id) {
                                planilhaId = planilha.id;
                            }
                        });
                    }
                }
                if ((typeof planilhaId !== 'undefined') && planilhaId) {
                    let planilha = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANILHA)
                        .id(planilhaId)
                        .ver();
                    if ((typeof planilha !== 'undefined') && planilha &&
                        (typeof planilha.dados !== 'undefined') && planilha.dados) {
                        dadosPlanilha = LuckysheetUtil_1.LuckysheetUtil.descompactarDadosPlanilha(planilha.dados);
                    }
                }
                ctrl.carregarPlanilha('planilha_analise_blup_analise_selecao_teste_genetico', dadosPlanilha, dadosAnexo);
                ctrl.modalAnaliseBlupAnaliseSelecaoTesteGenetico = true;
                angular.element('body').css('overflow-y', 'hidden');
                MenuUtil_1.MenuUtil.ajustaZIndexMenusModalPlanilha();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.scopeApply(() => {
                    MenuUtil_1.MenuUtil.ajustaZIndexMenusModalPlanilha();
                });
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static ocultarModalAnaliseBlupAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.modalAnaliseBlupAnaliseSelecaoTesteGenetico = false;
            ctrl.limparPlanilhasCarregadas();
            angular.element('body').css('overflow-y', '');
            MenuUtil_1.MenuUtil.restauraZIndexMenus();
            globalThis.posicaoTopoAntesModal = ctrl.posicaoTopoAntesModal;
            ctrl.scopeApply(() => {
                MenuUtil_1.MenuUtil.restauraZIndexMenus();
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                        (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    static importarSelecaoGeneticaAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoImportacao = true;
            ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoImportacaoAnexos = new Array();
            ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static finalizarImportacaoSelecaoGeneticaAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
                if ((typeof ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoImportacaoAnexos !== 'undefined') && ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoImportacaoAnexos &&
                    (ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoImportacaoAnexos.length > 0)) {
                    if ((typeof ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado) {
                        ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado = new SelecaoGeneticaAnaliseSelecaoTesteGenetico_1.SelecaoGeneticaAnaliseSelecaoTesteGenetico();
                    }
                    if ((typeof ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.planilhas) {
                        ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.planilhas = new Array();
                    }
                    if ((typeof ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.anexos === 'undefined') || !ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.anexos) {
                        ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.anexos = new Array();
                    }
                    let anexoSelecionado = null;
                    angular.forEach(ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoImportacaoAnexos, (anexo) => {
                        ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.anexos.push(anexo);
                        anexoSelecionado = anexo;
                    });
                    yield ctrl.limparPlanilhasCarregadas();
                    yield ctrl.carregarPlanilha('planilha_selecao_genetica_analise_selecao_teste_genetico', null, anexoSelecionado);
                    setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                        ctrl.salvarSelecaoGeneticaAnaliseSelecaoTesteGenetico(true);
                    }), 1500, ctrl);
                }
                ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoImportacao = false;
                ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
                ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoHistorico = false;
                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static cancelarImportacaoSelecaoGeneticaAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoImportacao = false;
            ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
            ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static exportarSelecaoGeneticaAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                return;
            }
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
            try {
                if ((typeof globalThis.luckysheet === 'undefined') || !globalThis.luckysheet) {
                    throw new Error('Plugin "luckysheet" não carregado.');
                }
                LuckysheetUtil_1.LuckysheetUtil.exportXLSX(globalThis.luckysheet);
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    static historicoSelecaoGeneticaAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoImportacao = false;
            ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
            ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoHistorico = true;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static selecionarHistoricoSelecaoGeneticaAnaliseSelecaoTesteGenetico(ctrl, keyPlanilha) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                return;
            }
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
            try {
                if ((typeof ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado) {
                    ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado = new SelecaoGeneticaAnaliseSelecaoTesteGenetico_1.SelecaoGeneticaAnaliseSelecaoTesteGenetico();
                }
                if ((typeof ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.planilhas) {
                    ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.planilhas = new Array();
                }
                if ((typeof keyPlanilha !== 'undefined') && (keyPlanilha !== null) && (keyPlanilha >= 0) &&
                    (keyPlanilha <= ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.planilhas.length - 1)) {
                    const planilhaSelecionada = ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.planilhas[keyPlanilha];
                    ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada = planilhaSelecionada;
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.SELECAO_GENETICA_ANALISE_SELECAO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.SELECAO_GENETICA_ANALISE_SELECAO_TESTE_GENETICO)
                        .atualizar(ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado);
                    yield ctrl.limparPlanilhasCarregadas();
                    setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                        ctrl.exibirModalSelecaoGeneticaAnaliseSelecaoTesteGenetico();
                    }), 500, ctrl);
                    ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoImportacao = false;
                    ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
                    ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoHistorico = false;
                }
                else {
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.notificacao.alerta('Não foi possível carregar a planilha.', 'A planilha selecionada não é válida ou foi removida.');
                    return;
                }
                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    static cancelarHistoricoSelecaoGeneticaAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoImportacao = false;
            ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
            ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static salvarSelecaoGeneticaAnaliseSelecaoTesteGenetico(ctrl, ehImportacao = false) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof ctrl.testeGeneticoSelecionado === 'undefined') || !ctrl.testeGeneticoSelecionado) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
                if ((typeof ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado) {
                    ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado = new SelecaoGeneticaAnaliseSelecaoTesteGenetico_1.SelecaoGeneticaAnaliseSelecaoTesteGenetico();
                }
                if ((typeof ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.planilhas) {
                    ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.planilhas = new Array();
                }
                let planilha = new Planilha_1.Planilha();
                planilha.status = true;
                planilha.modulo = 'SelecaoGeneticaAnaliseSelecaoTesteGenetico';
                planilha.operacao = ehImportacao ? 'Importar' : 'Salvar';
                planilha.nome = LuckysheetUtil_1.LuckysheetUtil.nomePlanilha();
                planilha.usuarioInclusao = ctrl.usuarioSimplificado();
                planilha.dataHoraInclusao = new Date();
                planilha.gzcompress = true;
                planilha.dados = LuckysheetUtil_1.LuckysheetUtil.compactarDadosPlanilha();
                planilha = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANILHA)
                    .criar(planilha);
                ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.planilhas.push(planilha);
                ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada = planilha;
                yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.SELECAO_GENETICA_ANALISE_SELECAO_TESTE_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.SELECAO_GENETICA_ANALISE_SELECAO_TESTE_GENETICO)
                    .atualizar(ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static exibirModalSelecaoGeneticaAnaliseSelecaoTesteGenetico(ctrl, dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.testeGeneticoSelecionado === 'undefined') || !ctrl.testeGeneticoSelecionado) {
                return;
            }
            try {
                ctrl.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                ctrl.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoVisualizar = true;
                ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoImportacao = false;
                ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoHistorico = false;
                if ((typeof dados === 'undefined') || !dados || (typeof dados.id === 'undefined') || !dados.id) {
                    let lista = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.SELECAO_GENETICA_ANALISE_SELECAO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.SELECAO_GENETICA_ANALISE_SELECAO_TESTE_GENETICO)
                        .filtro({
                        listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                        testeGenetico: ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                            (typeof ctrl.testeGeneticoSelecionado.id !== 'undefined') && ctrl.testeGeneticoSelecionado.id
                            ? ctrl.testeGeneticoSelecionado.id : null),
                        anoAnalise: ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                            (typeof ctrl.testeGeneticoSelecionado.anoAnalise !== 'undefined') && ctrl.testeGeneticoSelecionado.anoAnalise
                            ? ctrl.testeGeneticoSelecionado.anoAnalise : null)
                    })
                        .listar();
                    if ((typeof lista !== 'undefined') && lista) {
                        dados = { id: null, editar: true };
                        angular.forEach(lista, (item) => {
                            if ((typeof item.id !== 'undefined') && item.id) {
                                dados.id = item.id;
                            }
                        });
                    }
                }
                if ((typeof dados !== 'undefined') && dados && (typeof dados.id !== 'undefined') && dados.id) {
                    ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.SELECAO_GENETICA_ANALISE_SELECAO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.SELECAO_GENETICA_ANALISE_SELECAO_TESTE_GENETICO)
                        .id(dados.id)
                        .ver();
                    if ((typeof ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado) {
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        if (ctrl.ehCelular) {
                            alert(`Registro "${dados.id} não encontrado!`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                text: `Registro "${dados.id}" não encontrado.`,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        return;
                    }
                    if ((typeof ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.criacao === 'string') && ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.criacao) {
                        ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.criacao = new Date(ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.criacao);
                    }
                    if ((typeof ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao === 'string') && ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao) {
                        ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao = new Date(ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao);
                    }
                    if ((typeof ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao === 'string') && ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao) {
                        ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao = new Date(ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao);
                    }
                    if ((typeof dados.visualizar !== 'undefined') && dados.visualizar) {
                        ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoVisualizar = true;
                    }
                    else if ((typeof dados.editar !== 'undefined') && dados.editar) {
                        ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoVisualizar = false;
                    }
                    else if ((typeof dados.excluir !== 'undefined') && dados.excluir) {
                        ctrl.notificacao
                            .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                            .then((result) => __awaiter(this, void 0, void 0, function* () {
                            if (result.value) {
                                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.SELECAO_GENETICA_ANALISE_SELECAO_TESTE_GENETICO)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.SELECAO_GENETICA_ANALISE_SELECAO_TESTE_GENETICO)
                                    .id(dados.id)
                                    .remover();
                                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                                ctrl.listaOpcoesAnaliseSelecaoTesteGenetico = yield ctrl.dadosListaAnaliseSelecaoTesteGenetico();
                                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
                            }
                        }));
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                }
                else {
                    ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado = new SelecaoGeneticaAnaliseSelecaoTesteGenetico_1.SelecaoGeneticaAnaliseSelecaoTesteGenetico();
                    ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.status = true;
                    ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.criacao = new Date();
                    ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.criacao.setMilliseconds(0);
                    ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.testeGenetico = ctrl.testeGeneticoSimplificado();
                    ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.anoAnalise = ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                        (typeof ctrl.testeGeneticoSelecionado.anoAnalise !== 'undefined') && ctrl.testeGeneticoSelecionado.anoAnalise
                        ? ctrl.testeGeneticoSelecionado.anoAnalise : null);
                    ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao = new Date();
                    ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao.setMilliseconds(0);
                    ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.usuarioInclusao = ctrl.usuarioSimplificado();
                    ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.SELECAO_GENETICA_ANALISE_SELECAO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.SELECAO_GENETICA_ANALISE_SELECAO_TESTE_GENETICO)
                        .criar(ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado);
                    ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoVisualizar = false;
                }
                if ((typeof ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.anexos === 'undefined') || (ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.anexos === null)) {
                    ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.anexos = new Array();
                }
                ctrl.listaOpcoesSelecaoGeneticaAnaliseSelecaoTesteGenetico = yield ctrl.dadosListaSelecaoGeneticaAnaliseSelecaoTesteGenetico();
                ctrl.limparPlanilhasCarregadas();
                let planilhaId = null;
                let dadosPlanilha = [];
                let dadosAnexo = null;
                if ((typeof ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada !== 'undefined') && ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada &&
                    (typeof ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada.id !== 'undefined') && ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada.id) {
                    planilhaId = ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada.id;
                }
                else {
                    if ((typeof ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.planilhas !== 'undefined') && ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.planilhas) {
                        angular.forEach(ctrl.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado.planilhas, (planilha) => {
                            if ((typeof planilha !== 'undefined') && planilha && (typeof planilha.id !== 'undefined') && planilha.id) {
                                planilhaId = planilha.id;
                            }
                        });
                    }
                }
                if ((typeof planilhaId !== 'undefined') && planilhaId) {
                    let planilha = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANILHA)
                        .id(planilhaId)
                        .ver();
                    if ((typeof planilha !== 'undefined') && planilha &&
                        (typeof planilha.dados !== 'undefined') && planilha.dados) {
                        dadosPlanilha = LuckysheetUtil_1.LuckysheetUtil.descompactarDadosPlanilha(planilha.dados);
                    }
                }
                ctrl.carregarPlanilha('planilha_selecao_genetica_analise_selecao_teste_genetico', dadosPlanilha, dadosAnexo);
                ctrl.modalSelecaoGeneticaAnaliseSelecaoTesteGenetico = true;
                angular.element('body').css('overflow-y', 'hidden');
                MenuUtil_1.MenuUtil.ajustaZIndexMenusModalPlanilha();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.scopeApply(() => {
                    MenuUtil_1.MenuUtil.ajustaZIndexMenusModalPlanilha();
                });
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static ocultarModalSelecaoGeneticaAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.modalSelecaoGeneticaAnaliseSelecaoTesteGenetico = false;
            ctrl.limparPlanilhasCarregadas();
            angular.element('body').css('overflow-y', '');
            MenuUtil_1.MenuUtil.restauraZIndexMenus();
            globalThis.posicaoTopoAntesModal = ctrl.posicaoTopoAntesModal;
            ctrl.scopeApply(() => {
                MenuUtil_1.MenuUtil.restauraZIndexMenus();
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                        (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    static importarSelecaoCampoAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoImportacao = true;
            ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoImportacaoAnexos = new Array();
            ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static finalizarImportacaoSelecaoCampoAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
                if ((typeof ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoImportacaoAnexos !== 'undefined') && ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoImportacaoAnexos &&
                    (ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoImportacaoAnexos.length > 0)) {
                    if ((typeof ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado) {
                        ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado = new SelecaoCampoAnaliseSelecaoTesteGenetico_1.SelecaoCampoAnaliseSelecaoTesteGenetico();
                    }
                    if ((typeof ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.planilhas) {
                        ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.planilhas = new Array();
                    }
                    if ((typeof ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.anexos === 'undefined') || !ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.anexos) {
                        ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.anexos = new Array();
                    }
                    let anexoSelecionado = null;
                    angular.forEach(ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoImportacaoAnexos, (anexo) => {
                        ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.anexos.push(anexo);
                        anexoSelecionado = anexo;
                    });
                    yield ctrl.limparPlanilhasCarregadas();
                    yield ctrl.carregarPlanilha('planilha_selecao_campo_analise_selecao_teste_genetico', null, anexoSelecionado);
                    setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                        ctrl.salvarSelecaoCampoAnaliseSelecaoTesteGenetico(true);
                    }), 1500, ctrl);
                }
                ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoImportacao = false;
                ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
                ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoHistorico = false;
                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static cancelarImportacaoSelecaoCampoAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoImportacao = false;
            ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
            ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static exportarSelecaoCampoAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                return;
            }
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
            try {
                if ((typeof globalThis.luckysheet === 'undefined') || !globalThis.luckysheet) {
                    throw new Error('Plugin "luckysheet" não carregado.');
                }
                LuckysheetUtil_1.LuckysheetUtil.exportXLSX(globalThis.luckysheet);
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    static historicoSelecaoCampoAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoImportacao = false;
            ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
            ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoHistorico = true;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static selecionarHistoricoSelecaoCampoAnaliseSelecaoTesteGenetico(ctrl, keyPlanilha) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                return;
            }
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
            try {
                if ((typeof ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado) {
                    ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado = new SelecaoCampoAnaliseSelecaoTesteGenetico_1.SelecaoCampoAnaliseSelecaoTesteGenetico();
                }
                if ((typeof ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.planilhas) {
                    ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.planilhas = new Array();
                }
                if ((typeof keyPlanilha !== 'undefined') && (keyPlanilha !== null) && (keyPlanilha >= 0) &&
                    (keyPlanilha <= ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.planilhas.length - 1)) {
                    const planilhaSelecionada = ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.planilhas[keyPlanilha];
                    ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada = planilhaSelecionada;
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.SELECAO_CAMPO_ANALISE_SELECAO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.SELECAO_CAMPO_ANALISE_SELECAO_TESTE_GENETICO)
                        .atualizar(ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado);
                    yield ctrl.limparPlanilhasCarregadas();
                    setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                        ctrl.exibirModalSelecaoCampoAnaliseSelecaoTesteGenetico();
                    }), 500, ctrl);
                    ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoImportacao = false;
                    ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
                    ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoHistorico = false;
                }
                else {
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.notificacao.alerta('Não foi possível carregar a planilha.', 'A planilha selecionada não é válida ou foi removida.');
                    return;
                }
                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    static cancelarHistoricoSelecaoCampoAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoImportacao = false;
            ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
            ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static salvarSelecaoCampoAnaliseSelecaoTesteGenetico(ctrl, ehImportacao = false) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof ctrl.testeGeneticoSelecionado === 'undefined') || !ctrl.testeGeneticoSelecionado) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
                if ((typeof ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado) {
                    ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado = new SelecaoCampoAnaliseSelecaoTesteGenetico_1.SelecaoCampoAnaliseSelecaoTesteGenetico();
                }
                if ((typeof ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.planilhas) {
                    ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.planilhas = new Array();
                }
                let planilha = new Planilha_1.Planilha();
                planilha.status = true;
                planilha.modulo = 'SelecaoCampoAnaliseSelecaoTesteGenetico';
                planilha.operacao = ehImportacao ? 'Importar' : 'Salvar';
                planilha.nome = LuckysheetUtil_1.LuckysheetUtil.nomePlanilha();
                planilha.usuarioInclusao = ctrl.usuarioSimplificado();
                planilha.dataHoraInclusao = new Date();
                planilha.gzcompress = true;
                planilha.dados = LuckysheetUtil_1.LuckysheetUtil.compactarDadosPlanilha();
                planilha = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANILHA)
                    .criar(planilha);
                ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.planilhas.push(planilha);
                ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada = planilha;
                yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.SELECAO_CAMPO_ANALISE_SELECAO_TESTE_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.SELECAO_CAMPO_ANALISE_SELECAO_TESTE_GENETICO)
                    .atualizar(ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static exibirModalSelecaoCampoAnaliseSelecaoTesteGenetico(ctrl, dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.testeGeneticoSelecionado === 'undefined') || !ctrl.testeGeneticoSelecionado) {
                return;
            }
            try {
                ctrl.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                ctrl.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoVisualizar = true;
                ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoImportacao = false;
                ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoHistorico = false;
                if ((typeof dados === 'undefined') || !dados || (typeof dados.id === 'undefined') || !dados.id) {
                    let lista = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.SELECAO_CAMPO_ANALISE_SELECAO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.SELECAO_CAMPO_ANALISE_SELECAO_TESTE_GENETICO)
                        .filtro({
                        listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                        testeGenetico: ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                            (typeof ctrl.testeGeneticoSelecionado.id !== 'undefined') && ctrl.testeGeneticoSelecionado.id
                            ? ctrl.testeGeneticoSelecionado.id : null),
                        anoAnalise: ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                            (typeof ctrl.testeGeneticoSelecionado.anoAnalise !== 'undefined') && ctrl.testeGeneticoSelecionado.anoAnalise
                            ? ctrl.testeGeneticoSelecionado.anoAnalise : null)
                    })
                        .listar();
                    if ((typeof lista !== 'undefined') && lista) {
                        dados = { id: null, editar: true };
                        angular.forEach(lista, (item) => {
                            if ((typeof item.id !== 'undefined') && item.id) {
                                dados.id = item.id;
                            }
                        });
                    }
                }
                if ((typeof dados !== 'undefined') && dados && (typeof dados.id !== 'undefined') && dados.id) {
                    ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.SELECAO_CAMPO_ANALISE_SELECAO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.SELECAO_CAMPO_ANALISE_SELECAO_TESTE_GENETICO)
                        .id(dados.id)
                        .ver();
                    if ((typeof ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado) {
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        if (ctrl.ehCelular) {
                            alert(`Registro "${dados.id} não encontrado!`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                text: `Registro "${dados.id}" não encontrado.`,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        return;
                    }
                    if ((typeof ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.criacao === 'string') && ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.criacao) {
                        ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.criacao = new Date(ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.criacao);
                    }
                    if ((typeof ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao === 'string') && ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao) {
                        ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao = new Date(ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao);
                    }
                    if ((typeof ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao === 'string') && ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao) {
                        ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao = new Date(ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao);
                    }
                    if ((typeof dados.visualizar !== 'undefined') && dados.visualizar) {
                        ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoVisualizar = true;
                    }
                    else if ((typeof dados.editar !== 'undefined') && dados.editar) {
                        ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoVisualizar = false;
                    }
                    else if ((typeof dados.excluir !== 'undefined') && dados.excluir) {
                        ctrl.notificacao
                            .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                            .then((result) => __awaiter(this, void 0, void 0, function* () {
                            if (result.value) {
                                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.SELECAO_CAMPO_ANALISE_SELECAO_TESTE_GENETICO)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.SELECAO_CAMPO_ANALISE_SELECAO_TESTE_GENETICO)
                                    .id(dados.id)
                                    .remover();
                                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                                ctrl.listaOpcoesAnaliseSelecaoTesteGenetico = yield ctrl.dadosListaAnaliseSelecaoTesteGenetico();
                                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
                            }
                        }));
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                }
                else {
                    ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado = new SelecaoCampoAnaliseSelecaoTesteGenetico_1.SelecaoCampoAnaliseSelecaoTesteGenetico();
                    ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.status = true;
                    ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.criacao = new Date();
                    ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.criacao.setMilliseconds(0);
                    ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.testeGenetico = ctrl.testeGeneticoSimplificado();
                    ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.anoAnalise = ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                        (typeof ctrl.testeGeneticoSelecionado.anoAnalise !== 'undefined') && ctrl.testeGeneticoSelecionado.anoAnalise
                        ? ctrl.testeGeneticoSelecionado.anoAnalise : null);
                    ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao = new Date();
                    ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao.setMilliseconds(0);
                    ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.usuarioInclusao = ctrl.usuarioSimplificado();
                    ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.SELECAO_CAMPO_ANALISE_SELECAO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.SELECAO_CAMPO_ANALISE_SELECAO_TESTE_GENETICO)
                        .criar(ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado);
                    ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoVisualizar = false;
                }
                if ((typeof ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.anexos === 'undefined') || (ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.anexos === null)) {
                    ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.anexos = new Array();
                }
                ctrl.listaOpcoesSelecaoCampoAnaliseSelecaoTesteGenetico = yield ctrl.dadosListaSelecaoCampoAnaliseSelecaoTesteGenetico();
                ctrl.limparPlanilhasCarregadas();
                ctrl.listaOpcoesCriteriosSelecaoCampoAnaliseSelecaoTesteGenetico = yield ctrl.dadosListaCriteriosSelecaoCampoAnaliseSelecaoTesteGenetico();
                ctrl.listaOpcoesLimitesReferenciaSelecaoCampoAnaliseSelecaoTesteGenetico = yield ctrl.dadosListaLimitesReferenciaSelecaoCampoAnaliseSelecaoTesteGenetico();
                ctrl.listaOpcoesSelecaoCampoAnaliseSelecaoTesteGenetico = yield ctrl.dadosListaSelecaoCampoAnaliseSelecaoTesteGenetico();
                ctrl.listaOpcoesCroquiSelecaoCampoAnaliseSelecaoTesteGenetico = yield ctrl.dadosListaCroquiSelecaoCampoAnaliseSelecaoTesteGenetico();
                let planilhaId = null;
                let dadosPlanilha = [];
                let dadosAnexo = null;
                if ((typeof ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada !== 'undefined') && ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada &&
                    (typeof ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada.id !== 'undefined') && ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada.id) {
                    planilhaId = ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.planilhaSelecionada.id;
                }
                else {
                    if ((typeof ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.planilhas !== 'undefined') && ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.planilhas) {
                        angular.forEach(ctrl.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado.planilhas, (planilha) => {
                            if ((typeof planilha !== 'undefined') && planilha && (typeof planilha.id !== 'undefined') && planilha.id) {
                                planilhaId = planilha.id;
                            }
                        });
                    }
                }
                if ((typeof planilhaId !== 'undefined') && planilhaId) {
                    let planilha = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANILHA)
                        .id(planilhaId)
                        .ver();
                    if ((typeof planilha !== 'undefined') && planilha &&
                        (typeof planilha.dados !== 'undefined') && planilha.dados) {
                        dadosPlanilha = LuckysheetUtil_1.LuckysheetUtil.descompactarDadosPlanilha(planilha.dados);
                    }
                }
                ctrl.carregarPlanilha('planilha_selecao_campo_analise_selecao_teste_genetico', dadosPlanilha, dadosAnexo);
                ctrl.modalSelecaoCampoAnaliseSelecaoTesteGenetico = true;
                angular.element('body').css('overflow-y', 'hidden');
                MenuUtil_1.MenuUtil.ajustaZIndexMenusModalPlanilha();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.scopeApply(() => {
                    MenuUtil_1.MenuUtil.ajustaZIndexMenusModalPlanilha();
                });
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static ocultarModalSelecaoCampoAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.modalSelecaoCampoAnaliseSelecaoTesteGenetico = false;
            ctrl.limparPlanilhasCarregadas();
            angular.element('body').css('overflow-y', '');
            MenuUtil_1.MenuUtil.restauraZIndexMenus();
            globalThis.posicaoTopoAntesModal = ctrl.posicaoTopoAntesModal;
            ctrl.scopeApply(() => {
                MenuUtil_1.MenuUtil.restauraZIndexMenus();
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                        (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    static exibirModalAnexoAnaliseSelecaoTesteGenetico(ctrl, dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.testeGeneticoSelecionado === 'undefined') || !ctrl.testeGeneticoSelecionado) {
                return;
            }
            try {
                ctrl.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                ctrl.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                ctrl.anexoAnaliseSelecaoTesteGeneticoVisualizar = true;
                if ((typeof dados !== 'undefined') && dados && (typeof dados.id !== 'undefined') && dados.id) {
                    ctrl.anexoAnaliseSelecaoTesteGeneticoSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANEXO_ANALISE_SELECAO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANEXO_ANALISE_SELECAO_TESTE_GENETICO)
                        .id(dados.id)
                        .ver();
                    if ((typeof ctrl.anexoAnaliseSelecaoTesteGeneticoSelecionado === 'undefined') || !ctrl.anexoAnaliseSelecaoTesteGeneticoSelecionado) {
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        if (ctrl.ehCelular) {
                            alert(`Registro "${dados.id} não encontrado!`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                text: `Registro "${dados.id}" não encontrado.`,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        return;
                    }
                    if ((typeof ctrl.anexoAnaliseSelecaoTesteGeneticoSelecionado.criacao === 'string') && ctrl.anexoAnaliseSelecaoTesteGeneticoSelecionado.criacao) {
                        ctrl.anexoAnaliseSelecaoTesteGeneticoSelecionado.criacao = new Date(ctrl.anexoAnaliseSelecaoTesteGeneticoSelecionado.criacao);
                    }
                    if ((typeof ctrl.anexoAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao === 'string') && ctrl.anexoAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao) {
                        ctrl.anexoAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao = new Date(ctrl.anexoAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao);
                    }
                    if ((typeof ctrl.anexoAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao === 'string') && ctrl.anexoAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao) {
                        ctrl.anexoAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao = new Date(ctrl.anexoAnaliseSelecaoTesteGeneticoSelecionado.dataHoraAlteracao);
                    }
                    if ((typeof dados.visualizar !== 'undefined') && dados.visualizar) {
                        ctrl.anexoAnaliseSelecaoTesteGeneticoVisualizar = true;
                    }
                    else if ((typeof dados.editar !== 'undefined') && dados.editar) {
                        ctrl.anexoAnaliseSelecaoTesteGeneticoVisualizar = false;
                    }
                    else if ((typeof dados.excluir !== 'undefined') && dados.excluir) {
                        ctrl.notificacao
                            .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                            .then((result) => __awaiter(this, void 0, void 0, function* () {
                            if (result.value) {
                                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANEXO_ANALISE_SELECAO_TESTE_GENETICO)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANEXO_ANALISE_SELECAO_TESTE_GENETICO)
                                    .id(dados.id)
                                    .remover();
                                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                                ctrl.listaOpcoesAnaliseSelecaoTesteGenetico = yield ctrl.dadosListaAnaliseSelecaoTesteGenetico();
                                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
                            }
                        }));
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                }
                else {
                    ctrl.anexoAnaliseSelecaoTesteGeneticoSelecionado = new AnexoAnaliseSelecaoTesteGenetico_1.AnexoAnaliseSelecaoTesteGenetico();
                    ctrl.anexoAnaliseSelecaoTesteGeneticoSelecionado.status = true;
                    ctrl.anexoAnaliseSelecaoTesteGeneticoSelecionado.criacao = new Date();
                    ctrl.anexoAnaliseSelecaoTesteGeneticoSelecionado.criacao.setMilliseconds(0);
                    ctrl.anexoAnaliseSelecaoTesteGeneticoSelecionado.testeGenetico = ctrl.testeGeneticoSimplificado();
                    ctrl.anexoAnaliseSelecaoTesteGeneticoSelecionado.anoAnalise = ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                        (typeof ctrl.testeGeneticoSelecionado.anoAnalise !== 'undefined') && ctrl.testeGeneticoSelecionado.anoAnalise
                        ? ctrl.testeGeneticoSelecionado.anoAnalise : null);
                    ctrl.anexoAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao = new Date();
                    ctrl.anexoAnaliseSelecaoTesteGeneticoSelecionado.dataHoraInclusao.setMilliseconds(0);
                    ctrl.anexoAnaliseSelecaoTesteGeneticoSelecionado.usuarioInclusao = ctrl.usuarioSimplificado();
                    ctrl.anexoAnaliseSelecaoTesteGeneticoVisualizar = false;
                }
                if ((typeof ctrl.anexoAnaliseSelecaoTesteGeneticoSelecionado.anexos === 'undefined') || (ctrl.anexoAnaliseSelecaoTesteGeneticoSelecionado.anexos === null)) {
                    ctrl.anexoAnaliseSelecaoTesteGeneticoSelecionado.anexos = new Array();
                }
                ctrl.modalAnexoAnaliseSelecaoTesteGenetico = true;
                angular.element('body').css('overflow-y', 'hidden');
                MenuUtil_1.MenuUtil.ajustaZIndexMenusModalPlanilha();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.scopeApply(() => {
                    MenuUtil_1.MenuUtil.ajustaZIndexMenusModalPlanilha();
                });
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static ocultarModalAnexoAnaliseSelecaoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.modalAnexoAnaliseSelecaoTesteGenetico = false;
            ctrl.limparPlanilhasCarregadas();
            angular.element('body').css('overflow-y', '');
            MenuUtil_1.MenuUtil.restauraZIndexMenus();
            globalThis.posicaoTopoAntesModal = ctrl.posicaoTopoAntesModal;
            ctrl.scopeApply(() => {
                MenuUtil_1.MenuUtil.restauraZIndexMenus();
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                        (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
}
exports.TesteCampoAnaliseSelecaoUtil = TesteCampoAnaliseSelecaoUtil;
