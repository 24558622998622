"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OperacaoColetaSementePomar = void 0;
const Base_1 = require("./Base");
const uuid_1 = require("uuid");
class OperacaoColetaSementePomar extends Base_1.Base {
    constructor() {
        super();
        this.id = null;
        this.guid = null;
        this.materialGenetico = null;
        this.anoPolinizacao = null;
        this.quantidade = null;
        this.numArvores = null;
        this.numClones = null;
        this.numCones = null;
        this.guid = uuid_1.v4();
        this.status = true;
    }
}
exports.OperacaoColetaSementePomar = OperacaoColetaSementePomar;
