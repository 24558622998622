"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SincronizacaoController = exports.Sincronizacao = void 0;
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const AbstratoController_1 = require("../../AbstratoController");
const angular = require("angular");
const ICRUDOperacao_1 = require("../../interfaces/ICRUDOperacao");
const config_app_1 = require("../../../../../config/config.app");
const DispositivoUtil_1 = require("../../../../../util/DispositivoUtil");
class Sincronizacao {
    constructor() {
        this.id = null;
    }
}
exports.Sincronizacao = Sincronizacao;
class SincronizacaoController extends AbstratoController_1.AbstratoController {
    constructor($scope, $injector) {
        super($scope, $injector, new Sincronizacao());
        this.path = '';
        this.caminho = '';
        this.tipoOperacao = null;
        this.sincronizando = false;
        this.contadorAtualizaStatusSincronia = null;
        this.timeoutAtualizaStatusSincronia = null;
        this.tipoOperacao = ICRUDOperacao_1.CRUDOperacao;
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.path = config_app_1.config.api.caminho;
            this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO;
            if (!this.usuario) {
                this.links = {
                    VISUALIZAR: null,
                    CRIAR: null,
                    DUPLICAR: null,
                    EDITAR: null,
                    LISTAR: {
                        rota: 'login',
                        params: null
                    }
                };
            }
            this.sincronizando = false;
            this.atualizaStatusSincronia(this);
            this.$scope.$apply();
        });
    }
    salvar() {
        return __awaiter(this, void 0, void 0, function* () {
        });
    }
    visualizar(_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Sincronizacao();
        });
    }
    remover(_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return null;
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
            return null;
        });
    }
    dadosLista() {
        return __awaiter(this, void 0, void 0, function* () {
            return null;
        });
    }
    sincronizarDados() {
        return __awaiter(this, void 0, void 0, function* () {
            this.sincronizando = true;
            this.contadorAtualizaStatusSincronia = 5;
            if (DispositivoUtil_1.DispositivoUtil.ehCelular()) {
                angular.element('.app-status-sincronizacao-msg').html('Carregando...');
                angular.element('.app-status-sincronizacao-msg-adicional').empty();
                angular.element('.app-status-sincronizacao').show();
            }
            setTimeout((factory) => {
                this.sincronia.sincronizar(factory, true);
            }, 1000, this.sincronia);
            this.atualizaStatusSincronia(this);
            this.scopeApply();
        });
    }
    atualizaStatusSincronia(controller) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof controller.timeoutAtualizaStatusSincronia !== 'undefined') && (controller.timeoutAtualizaStatusSincronia !== null)) {
                clearTimeout(controller.timeoutAtualizaStatusSincronia);
                controller.timeoutAtualizaStatusSincronia = null;
            }
            if ((typeof controller !== 'undefined') && (controller !== null) && (typeof controller.sincronizando !== 'undefined')) {
                let tmpSincronizando = ((typeof globalThis.sincronizando !== 'undefined') && globalThis.sincronizando);
                if ((typeof controller.contadorAtualizaStatusSincronia !== 'undefined') &&
                    (controller.contadorAtualizaStatusSincronia !== null) &&
                    (controller.contadorAtualizaStatusSincronia > 0)) {
                    controller.contadorAtualizaStatusSincronia--;
                }
                else {
                    if (tmpSincronizando !== controller.sincronizando) {
                        controller.sincronizando = tmpSincronizando;
                        controller.scopeApply();
                    }
                }
                controller.timeoutAtualizaStatusSincronia = setTimeout(controller.atualizaStatusSincronia, 2000, controller);
            }
        });
    }
}
exports.SincronizacaoController = SincronizacaoController;
SincronizacaoController.$inject = ['$scope', '$injector'];
