"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImportaShapefileController = void 0;
const angular = require("angular");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const UiGridConstrutor_1 = require("../../../app/construtores/UiGridConstrutor");
const AbstratoController_1 = require("../../../app/AbstratoController");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const ICRUDOperacao_1 = require("../../../app/interfaces/ICRUDOperacao");
const ImportaShapefile_1 = require("../../../../../modelos/ImportaShapefile");
const Regiao_1 = require("../../../../../modelos/Regiao");
const config_app_1 = require("../../../../../config/config.app");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const PlanilhaFactory_1 = require("../../../ferramenta/fabricas/PlanilhaFactory");
const TextoUtil_1 = require("../../../../../util/TextoUtil");
const sweetalert2_1 = require("sweetalert2");
class ImportaShapefileController extends AbstratoController_1.AbstratoController {
    constructor($scope, $injector, planilha) {
        super($scope, $injector, new ImportaShapefile_1.ImportaShapefile());
        this.path = '';
        this.caminho = '';
        this.tipoOperacao = null;
        this.planilha = null;
        this.listaSgifRegioes = [];
        this.listaSgifRegioesCarregando = false;
        this.listaSgifTalhoes = [];
        this.listaSgifTalhoesFiltrados = [];
        this.listaSgifTalhoesCarregando = false;
        this.coordenadaMapa = { latitude: 0, longitude: 0 };
        this.geoJsonMapa = null;
        this.mapaLiberado = false;
        this.timeoutFiltroSgifTalhoes = null;
        this.tipoOperacao = ICRUDOperacao_1.CRUDOperacao;
        this.planilha = planilha;
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.importar = true;
            this.path = config_app_1.config.api.caminho;
            this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.IMPORTA_SHAPEFILE;
            this.carregar();
            if (!this.modelo) {
                if ((typeof this.modelo.dataPlantio !== 'undefined') && (this.modelo.dataPlantio !== null)) {
                    this.modelo.dataPlantio = new Date(this.modelo.dataPlantio);
                }
            }
            this.$scope.$apply();
        });
    }
    carregar() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.operacao !== ICRUDOperacao_1.CRUDOperacao.LISTAR) {
                this.listaSgifRegioesCarregando = true;
                this.listaSgifRegioes = new Array();
                const promiseRegioesFiltro = this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.REGIAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.REGIAO)
                    .filtro({ permiteDadosOffline: true })
                    .listar();
                promiseRegioesFiltro.then((resposta) => {
                    this.listaSgifRegioes.splice(0, this.listaSgifRegioes.length);
                    angular.forEach(resposta, (regiao) => {
                        if ((typeof regiao.nome !== 'undefined') && (regiao.nome !== null)) {
                            const s = regiao.nome.trim();
                            if ((s.length > 0) && (s !== '-') && (s !== '_')) {
                                this.listaSgifRegioes.push(regiao);
                            }
                        }
                    });
                    this.listaSgifRegioesCarregando = false;
                    this.scopeApply();
                });
                this.listaSgifTalhoesCarregando = true;
                this.listaSgifTalhoes = new Array();
                this.listaSgifTalhoesFiltrados = new Array();
                const promiseTalhoes = this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TALHAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TALHAO)
                    .filtro({ permiteDadosOffline: true })
                    .listar();
                promiseTalhoes.then((resposta) => {
                    this.listaSgifTalhoes.splice(0, this.listaSgifTalhoes.length);
                    this.listaSgifTalhoes.push(...resposta);
                    if ((typeof this.listaSgifTalhoes !== 'undefined') && (this.listaSgifTalhoes !== null)) {
                        angular.forEach(this.listaSgifTalhoes, (talhao) => {
                            if ((typeof talhao.regiao === 'undefined') || (talhao.regiao === null)) {
                                talhao.regiao = new Regiao_1.Regiao();
                                talhao.regiao.sgif_id = talhao.regiaoSgifId;
                                talhao.regiao.nome = talhao.regiaoNome;
                            }
                            else if ((typeof talhao.regiao.nome === 'undefined') || (talhao.regiao.nome === null) || (talhao.regiao.nome.trim().length <= 0)) {
                                talhao.regiao.sgif_id = talhao.regiaoSgifId;
                                talhao.regiao.nome = talhao.regiaoNome;
                            }
                        });
                    }
                    this.filtraListaSgifTalhoes();
                    this.listaSgifTalhoesCarregando = false;
                    this.scopeApply();
                });
            }
        });
    }
    salvar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if ((typeof this.modelo !== 'undefined') && this.modelo) {
                    if ((typeof this.modelo.regiao !== 'undefined') && this.modelo.regiao &&
                        (typeof this.modelo.regiao.numRegiao !== 'undefined') && this.modelo.regiao.numRegiao) {
                        this.modelo.regiaoNum = '' + this.modelo.regiao.numRegiao;
                    }
                    if ((typeof this.modelo.talhao !== 'undefined') && this.modelo.talhao &&
                        (typeof this.modelo.talhao.numero !== 'undefined') && this.modelo.talhao.numero) {
                        this.modelo.talhaoNum = this.modelo.talhao.numero;
                    }
                }
                if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.IMPORTA_SHAPEFILE)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.IMPORTA_SHAPEFILE)
                        .criar(this.modelo);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.IMPORTA_SHAPEFILE)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.IMPORTA_SHAPEFILE)
                        .atualizar(this.modelo);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir um novo Talhão ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir um novo arquivo .SHP (Shapefile) ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não'
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    visualizar(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.mapaLiberado = false;
                const sgifImportaShapefile = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.IMPORTA_SHAPEFILE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.IMPORTA_SHAPEFILE)
                    .id(id)
                    .ver();
                if ((typeof sgifImportaShapefile.criacao !== 'undefined') && (sgifImportaShapefile.criacao !== null)) {
                    sgifImportaShapefile.criacao = new Date(sgifImportaShapefile.criacao);
                }
                else {
                    sgifImportaShapefile.criacao = new Date();
                }
                if ((typeof sgifImportaShapefile.dataPlantio !== 'undefined') && (sgifImportaShapefile.dataPlantio !== null)) {
                    sgifImportaShapefile.dataPlantio = new Date(sgifImportaShapefile.dataPlantio);
                }
                if ((typeof sgifImportaShapefile.talhao !== 'undefined') && (sgifImportaShapefile.talhao !== null)) {
                    if ((typeof sgifImportaShapefile.talhao.dataPlantio !== 'undefined') && (sgifImportaShapefile.talhao.dataPlantio !== null)) {
                        sgifImportaShapefile.talhao.dataPlantio = new Date(sgifImportaShapefile.talhao.dataPlantio);
                    }
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if ((typeof sgifImportaShapefile.geoJSON !== 'undefined') && (sgifImportaShapefile.geoJSON !== null)) {
                    if (angular.isArray(sgifImportaShapefile.geoJSON) && (sgifImportaShapefile.geoJSON.length > 0)) {
                        setTimeout((controller, sgifImportaShapefile) => {
                            controller.mapaLiberado = true;
                            if (sgifImportaShapefile.geoJSON && (sgifImportaShapefile.geoJSON.length > 0)) {
                                let geoItem = sgifImportaShapefile.geoJSON[0];
                                let primeiraCoordenada = null;
                                let segundaCoordenada = null;
                                if ((typeof geoItem.geometry !== 'undefined') && geoItem.geometry &&
                                    (typeof geoItem.geometry.coordinates !== 'undefined') && geoItem.geometry.coordinates) {
                                    angular.forEach(geoItem.geometry.coordinates, (coord1) => {
                                        if (angular.isArray(coord1)) {
                                            angular.forEach(coord1, (coord2) => {
                                                if (angular.isArray(coord2)) {
                                                    angular.forEach(coord2, (coord3) => {
                                                        if (angular.isArray(coord3)) {
                                                            angular.forEach(coord3, (coord4) => {
                                                                if (angular.isArray(coord4)) {
                                                                    angular.forEach(coord4, (coord5) => {
                                                                        if ((typeof coord5 === 'number') || (typeof coord5 === 'string')) {
                                                                            if (primeiraCoordenada === null) {
                                                                                primeiraCoordenada = parseFloat('' + coord5);
                                                                            }
                                                                            else if (segundaCoordenada === null) {
                                                                                segundaCoordenada = parseFloat('' + coord5);
                                                                            }
                                                                        }
                                                                    });
                                                                }
                                                                else if ((typeof coord4 === 'number') || (typeof coord4 === 'string')) {
                                                                    if (primeiraCoordenada === null) {
                                                                        primeiraCoordenada = parseFloat('' + coord4);
                                                                    }
                                                                    else if (segundaCoordenada === null) {
                                                                        segundaCoordenada = parseFloat('' + coord4);
                                                                    }
                                                                }
                                                            });
                                                        }
                                                        else if ((typeof coord3 === 'number') || (typeof coord3 === 'string')) {
                                                            if (primeiraCoordenada === null) {
                                                                primeiraCoordenada = parseFloat('' + coord3);
                                                            }
                                                            else if (segundaCoordenada === null) {
                                                                segundaCoordenada = parseFloat('' + coord3);
                                                            }
                                                        }
                                                    });
                                                }
                                                else if ((typeof coord2 === 'number') || (typeof coord2 === 'string')) {
                                                    if (primeiraCoordenada === null) {
                                                        primeiraCoordenada = parseFloat('' + coord2);
                                                    }
                                                    else if (segundaCoordenada === null) {
                                                        segundaCoordenada = parseFloat('' + coord2);
                                                    }
                                                }
                                            });
                                        }
                                        else if ((typeof coord1 === 'number') || (typeof coord1 === 'string')) {
                                            if (primeiraCoordenada === null) {
                                                primeiraCoordenada = parseFloat('' + coord1);
                                            }
                                            else if (segundaCoordenada === null) {
                                                segundaCoordenada = parseFloat('' + coord1);
                                            }
                                        }
                                    });
                                }
                                if (primeiraCoordenada || segundaCoordenada) {
                                    controller.coordenadaMapa.longitude = primeiraCoordenada;
                                    controller.coordenadaMapa.latitude = segundaCoordenada;
                                    controller.geoJsonMapa = geoItem;
                                    controller.scopeApply();
                                }
                            }
                        }, 1000, this, sgifImportaShapefile);
                    }
                }
                return sgifImportaShapefile;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    remover(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const sgifImportaShapefile = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.IMPORTA_SHAPEFILE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.IMPORTA_SHAPEFILE)
                    .id(id)
                    .remover();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (sgifImportaShapefile) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const sgifImportaShapefile = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.IMPORTA_SHAPEFILE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.IMPORTA_SHAPEFILE)
                    .id(this.modelo.id)
                    .restaurar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (sgifImportaShapefile) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosLista(filtro = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                this.path = config_app_1.config.api.caminho;
                this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.IMPORTA_SHAPEFILE;
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                var auxColunas = Array();
                auxColunas.push({
                    name: 'regiaoNome',
                    displayName: 'Região',
                    width: 230
                });
                auxColunas.push({
                    name: 'talhaoNumero',
                    displayName: 'Nº Talhão',
                    width: 120
                });
                auxColunas.push({
                    name: 'criacao',
                    displayName: 'Data/Hora Importação',
                    cellFilter: 'date:"dd/MM/yyyy HH:mm:ss"',
                    width: 180
                });
                auxColunas.push({
                    name: 'numeroPontosCoordenadas',
                    displayName: 'Quant. Pontos/Coordenadas',
                    width: 200
                });
                auxColunas.push({
                    name: 'areaTalhao',
                    displayName: 'Área Talhão',
                    width: 140
                });
                auxColunas.push({
                    name: 'dataPlantio',
                    displayName: 'Data Plantio',
                    width: 150,
                    cellFilter: 'date:"dd/MM/yyyy"'
                });
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
                const lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.IMPORTA_SHAPEFILE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.IMPORTA_SHAPEFILE)
                    .filtro(filtro)
                    .listar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach(element => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    filtrar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    limparFiltro() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    abrirPlanilhaModal($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.planilha.abrir($event, SincroniaConstantes_1.SincroniaConstantes.IMPORTA_SHAPEFILE, false);
        });
    }
    filtraListaSgifTalhoes() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.timeoutFiltroSgifTalhoes !== 'undefined') && (this.timeoutFiltroSgifTalhoes !== null)) {
                clearTimeout(this.timeoutFiltroSgifTalhoes);
                this.timeoutFiltroSgifTalhoes = null;
            }
            this.timeoutFiltroSgifTalhoes = setTimeout((controller) => {
                if ((typeof controller.listaSgifTalhoes !== 'undefined') && (controller.listaSgifTalhoes !== null) && (controller.listaSgifTalhoes.length > 0)) {
                    if ((typeof controller.listaSgifTalhoesFiltrados !== 'undefined') && (controller.listaSgifTalhoesFiltrados !== null)) {
                        controller.listaSgifTalhoesFiltrados.splice(0, controller.listaSgifTalhoesFiltrados.length);
                    }
                    else {
                        controller.listaSgifTalhoesFiltrados = new Array();
                    }
                    if ((typeof controller.modelo.regiao !== 'undefined') && (controller.modelo.regiao !== null)) {
                        if ((typeof controller.modelo.regiao.nome !== 'undefined') && (controller.modelo.regiao.nome !== null)) {
                            const s1 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(controller.modelo.regiao.nome).trim().toLowerCase();
                            angular.forEach(controller.listaSgifTalhoes, (talhao) => {
                                const s2 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(talhao.regiaoNome).trim().toLowerCase();
                                if (s1 === s2) {
                                    controller.listaSgifTalhoesFiltrados.push(talhao);
                                }
                            });
                        }
                    }
                    console.log('Talhões carregados: ' + controller.listaSgifTalhoes.length);
                    console.log('Talhões filtrados: ' + controller.listaSgifTalhoesFiltrados.length);
                    try {
                        controller.scopeApply();
                    }
                    catch (err) {
                        console.log(err);
                    }
                }
            }, 500, this);
        });
    }
}
exports.ImportaShapefileController = ImportaShapefileController;
ImportaShapefileController.$inject = ['$scope', '$injector', PlanilhaFactory_1.PlanilhaFactory.id];
