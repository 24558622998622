"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AutenticacaoUtil = void 0;
const LoginTeste_1 = require("../angularjs/modulos/app/paginas/login/LoginTeste");
const ArmazenamentoUtil_1 = require("./ArmazenamentoUtil");
class AutenticacaoUtil {
    static autorizar(autenticacao) {
        return __awaiter(this, void 0, void 0, function* () {
            const token = autenticacao.tokenJWE;
            const armazenamento = new ArmazenamentoUtil_1.LocalStorageStrategy(AutenticacaoUtil.chave);
            yield armazenamento.salvar(token);
        });
    }
    static revogar() {
        return __awaiter(this, void 0, void 0, function* () {
            let token = null;
            const armazenamento = new ArmazenamentoUtil_1.LocalStorageStrategy(AutenticacaoUtil.chave);
            try {
                token = yield AutenticacaoUtil.usuario();
            }
            catch (e) {
                console.log(e);
            }
            yield armazenamento.salvar(null);
            if ((typeof token !== 'undefined') && (token !== null)) {
                document.location.reload();
            }
        });
    }
    static ehAutenticado() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const token = yield AutenticacaoUtil.usuario();
                if (token) {
                    if ((typeof token.validade !== 'undefined') && (token.validade !== null) &&
                        (typeof token.sistema !== 'undefined') && (token.sistema !== null) &&
                        (typeof token.versao !== 'undefined') && (token.versao !== null)) {
                        if ((token.sistema.trim().toLowerCase() === 'sgp') &&
                            (token.versao >= 2)) {
                            return true;
                        }
                    }
                    return false;
                }
                else {
                    return null;
                }
            }
            catch (ex) {
                console.log(ex);
                return false;
            }
        });
    }
    static usuario() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const armazenamento = new ArmazenamentoUtil_1.LocalStorageStrategy(AutenticacaoUtil.chave);
                const tokenString = yield armazenamento.ler();
                if (!tokenString) {
                    return null;
                }
                if (tokenString.replace(/"/g, '') === 'null') {
                    return null;
                }
                if (tokenString === 'ey0000__' + LoginTeste_1.LoginTeste.emailPadrao) {
                    const dadosLoginTeste = LoginTeste_1.LoginTeste.respostaLogin().dados;
                    dadosLoginTeste.nome = 'Teste';
                    return dadosLoginTeste;
                }
                return JSON.parse(atob(tokenString.replace(/"/g, '')));
            }
            catch (ex) {
                return null;
            }
        });
    }
    static checaLogin($q, $location) {
        return __awaiter(this, void 0, void 0, function* () {
            const deferred = $q.defer();
            const ehAutenticado = yield AutenticacaoUtil.ehAutenticado();
            if (ehAutenticado) {
                console.log('checaLogin() = Usuário válido, autenticado!');
                deferred.resolve();
            }
            else {
                console.log('checaLogin() = Usuário inválido, acesso negado!');
                $location.path('/');
            }
            return deferred.promise;
        });
    }
}
exports.AutenticacaoUtil = AutenticacaoUtil;
AutenticacaoUtil.chave = 'auth';
