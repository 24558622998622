"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabGerminacaoSementesController = void 0;
const angular = require("angular");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const UiGridConstrutor_1 = require("../../../app/construtores/UiGridConstrutor");
const AbstratoController_1 = require("../../../app/AbstratoController");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const ICRUDOperacao_1 = require("../../../app/interfaces/ICRUDOperacao");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const config_app_1 = require("../../../../../config/config.app");
const sweetalert2_1 = require("sweetalert2");
const LabGerminacaoSementes_1 = require("../../../../../modelos/LabGerminacaoSementes");
const NumeroUtil_1 = require("../../../../../util/NumeroUtil");
const AnaliseInternaGerminacao_1 = require("../../../../../modelos/AnaliseInternaGerminacao");
const LabGerminacaoSementesExterno_1 = require("../../../../../modelos/LabGerminacaoSementesExterno");
const DispositivoUtil_1 = require("../../../../../util/DispositivoUtil");
const CampoIndicadorFotos_1 = require("../../../app/componentes/campo-indicador/CampoIndicadorFotos");
const DataUtil_1 = require("../../../../../util/DataUtil");
class LabGerminacaoSementesController extends AbstratoController_1.AbstratoController {
    constructor($scope, $injector) {
        super($scope, $injector, new LabGerminacaoSementes_1.LabGerminacaoSementes());
        this.path = '';
        this.caminho = '';
        this.tipoOperacao = null;
        this.listasFotos = null;
        this.analisesFiltradas = false;
        this.listaGerminacaoSementes = [];
        this.listaGerminacaoSementesCarregada = false;
        this.generosGerminacaoSementes = [];
        this.especiesGerminacaoSementes = [];
        this.materialGeneticoGerminacaoSementes = [];
        this.pomarGerminacaoSementes = [];
        this.safraGerminacaoSementes = [];
        this.loteGerminacaoSementes = [];
        this.listaOpcoesAnalise = null;
        this.listaOpcoesLaboratorio = null;
        this.listaOpcoesAnaliseInterna = null;
        this.listaOpcoesAnaliseInternaCompleto = {
            data: [],
            columnDefs: [],
            headerTemplate: 'app/angularjs/modulos/cadastro/paginas/labGerminacaoSementes/template/cabecalhoRelatorioInternoCompleto.html'
        };
        this.listaOpcoesExterno = null;
        this.listaOpcoesExternoCompleto = {
            data: [],
            columnDefs: [],
            headerTemplate: 'app/angularjs/modulos/cadastro/paginas/labGerminacaoSementes/template/cabecalhoRelatorioExternoCompleto.html'
        };
        this.filtroRelatorio = { germinacaoSementeId: null };
        this.listaAnalise = [];
        this.listaAnaliseInterna = [];
        this.listaLaboratoriosRelatorios = [];
        this.listaLaboratoriosRelatoriosNomes = [];
        this.dadosAnaliseInterna = null;
        this.dadosLaboratorioExterno = null;
        this.modalAnaliseInterna = false;
        this.modalLaboratorioExterno = false;
        this.posicaoTopoAntesModal = null;
        this.generos = [];
        this.especies = [];
        this.pomares = [];
        this.safras = [];
        this.lotes = [];
        this.materiaisGeneticos = [];
        this.laboratorios = [];
        this.laboratoriosExternos = [];
        this.germinacaoSelecionado = null;
        this.germinacaoCarregado = false;
        this.dormenciaSem = AnaliseInternaGerminacao_1.AnaliseInternaGerminacao.DORMENCIA_SEM;
        this.dormenciaCom = AnaliseInternaGerminacao_1.AnaliseInternaGerminacao.DORMENCIA_COM;
        this.tipoAvaliacao7 = AnaliseInternaGerminacao_1.AnaliseInternaGerminacao.TIPO_AVALIACAO_7;
        this.tipoAvaliacao14 = AnaliseInternaGerminacao_1.AnaliseInternaGerminacao.TIPO_AVALIACAO_14;
        this.tipoAvaliacao21 = AnaliseInternaGerminacao_1.AnaliseInternaGerminacao.TIPO_AVALIACAO_21;
        this.tipoAvaliacao28 = AnaliseInternaGerminacao_1.AnaliseInternaGerminacao.TIPO_AVALIACAO_28;
        this.tipoOperacao = ICRUDOperacao_1.CRUDOperacao;
        this.listasFotos = new CampoIndicadorFotos_1.CampoIndicadorFotos($scope);
        this.analisesFiltradas = false;
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.analisesFiltradas = false;
            this.path = config_app_1.config.api.caminho;
            this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.LAB_GERMINACAO_SEMENTES;
            this.modalAnaliseInterna = false;
            this.modalLaboratorioExterno = false;
            this.dadosAnaliseInterna = null;
            this.dadosLaboratorioExterno = null;
            if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR ||
                this.operacao === ICRUDOperacao_1.CRUDOperacao.EDITAR ||
                this.operacao === ICRUDOperacao_1.CRUDOperacao.DUPLICAR) {
                this.generos = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.GENERO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.GENERO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.generos = resposta;
                    }
                });
                this.especies = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESPECIE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESPECIE)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.especies = resposta;
                    }
                });
                this.pomares = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POMAR)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.pomares = resposta;
                    }
                });
                this.safras = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.SAFRA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.SAFRA)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.safras = resposta;
                    }
                });
                this.lotes = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.LOTE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LOTE)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.lotes = resposta;
                    }
                });
                this.materiaisGeneticos = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MATERIAL_GENETICO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.materiaisGeneticos = resposta;
                    }
                });
            }
            if (this.operacao === ICRUDOperacao_1.CRUDOperacao.LISTAR) {
                this.listaOpcoesAnalise = {
                    data: [],
                    columnDefs: []
                };
                this.listaOpcoesLaboratorio = {
                    data: [],
                    columnDefs: []
                };
                this.listaOpcoesAnaliseInterna = {
                    data: [],
                    columnDefs: []
                };
                this.listaOpcoesAnaliseInternaCompleto = {
                    data: [],
                    columnDefs: [],
                    headerTemplate: 'app/angularjs/modulos/cadastro/paginas/labGerminacaoSementes/template/cabecalhoRelatorioInternoCompleto.html'
                };
                this.listaOpcoesExterno = {
                    data: [],
                    columnDefs: []
                };
                this.listaOpcoesExternoCompleto = {
                    data: [],
                    columnDefs: [],
                    headerTemplate: 'app/angularjs/modulos/cadastro/paginas/labGerminacaoSementes/template/cabecalhoRelatorioExternoCompleto.html'
                };
                this.generos = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.GENERO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.GENERO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.generos = resposta;
                    }
                });
                this.especies = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESPECIE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESPECIE)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.especies = resposta;
                    }
                });
                this.pomares = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POMAR)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.pomares = resposta;
                    }
                });
                this.safras = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.SAFRA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.SAFRA)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.safras = resposta;
                    }
                });
                this.lotes = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.LOTE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LOTE)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.lotes = resposta;
                    }
                });
                this.materiaisGeneticos = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MATERIAL_GENETICO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.materiaisGeneticos = resposta;
                    }
                });
                this.laboratorios = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.LABORATORIO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LABORATORIO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.laboratorios = resposta;
                    }
                });
            }
            this.$scope.$apply();
        });
    }
    salvar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.modalLaboratorioExterno = false;
                this.modalAnaliseInterna = false;
                this.dadosAnaliseInterna = null;
                this.dadosLaboratorioExterno = null;
                if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_GERMINACAO_SEMENTES)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_GERMINACAO_SEMENTES)
                        .criar(this.modelo);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_GERMINACAO_SEMENTES)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_GERMINACAO_SEMENTES)
                        .atualizar(this.modelo);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir uma nova Germinação de Sementes ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir uma nova Germinação de Sementes ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    visualizar(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const labGerminacaoSementes = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_GERMINACAO_SEMENTES)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_GERMINACAO_SEMENTES)
                    .id(id)
                    .ver();
                labGerminacaoSementes.criacao = new Date(labGerminacaoSementes.criacao);
                labGerminacaoSementes.dataGerminacao = new Date(labGerminacaoSementes.dataGerminacao);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return labGerminacaoSementes;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    remover(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const labGerminacaoSementes = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_GERMINACAO_SEMENTES)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_GERMINACAO_SEMENTES)
                    .id(id)
                    .remover();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (labGerminacaoSementes) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const labGerminacaoSementes = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_GERMINACAO_SEMENTES)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_GERMINACAO_SEMENTES)
                    .id(this.modelo.id)
                    .restaurar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (labGerminacaoSementes) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    cellClicked(row) {
        if (row.entity && typeof row.entity.id !== 'undefined' && row.entity.id) {
            const nomeTabelaGerminacao = 'tabelaGerminacao';
            let gridGerminacoesApi = null;
            let gridGerminacaoScope = angular.element('#' + nomeTabelaGerminacao).scope();
            if ((typeof gridGerminacaoScope.listaGridApi !== 'undefined') && gridGerminacaoScope.listaGridApi) {
                angular.forEach(gridGerminacaoScope.listaGridApi, (gridApi) => {
                    const grid = (typeof gridApi.grid !== 'undefined') && gridApi.grid ? gridApi.grid : null;
                    if (grid && (typeof grid.element !== 'undefined') && grid.element) {
                        const tabelasEl = angular.element(grid.element).parents('tabela');
                        if (tabelasEl && (tabelasEl.length > 0) && (tabelasEl.first().attr('id') === nomeTabelaGerminacao)) {
                            gridGerminacoesApi = gridApi;
                        }
                    }
                });
            }
            if (gridGerminacoesApi && (typeof gridGerminacoesApi.selection !== 'undefined') && gridGerminacoesApi.selection) {
                gridGerminacoesApi.selection.selectRow(row.entity);
            }
        }
    }
    carregarListaGerminacaoSementes(filtro = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
            this.generosGerminacaoSementes = [];
            this.especiesGerminacaoSementes = [];
            this.materialGeneticoGerminacaoSementes = [];
            this.pomarGerminacaoSementes = [];
            this.safraGerminacaoSementes = [];
            this.loteGerminacaoSementes = [];
            this.listaGerminacaoSementes = yield this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_GERMINACAO_SEMENTES)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_GERMINACAO_SEMENTES)
                .filtro(filtro)
                .listar();
            angular.forEach(this.listaGerminacaoSementes, (germinacaoSemente) => {
                if ((typeof germinacaoSemente.generoId !== 'undefined') && germinacaoSemente.generoId &&
                    (typeof germinacaoSemente.generoDescricao !== 'undefined') && germinacaoSemente.generoDescricao) {
                    if (!ctrl.generosGerminacaoSementes.find(o => o.id === germinacaoSemente.generoId)) {
                        ctrl.generosGerminacaoSementes.push({
                            id: germinacaoSemente.generoId,
                            uuid: germinacaoSemente.generoId,
                            codigo: null,
                            descricao: germinacaoSemente.generoDescricao
                        });
                    }
                }
                if ((typeof germinacaoSemente.especieId !== 'undefined') && germinacaoSemente.especieId &&
                    (typeof germinacaoSemente.especieDescricao !== 'undefined') && germinacaoSemente.especieDescricao) {
                    if (!ctrl.especiesGerminacaoSementes.find(o => o.id === germinacaoSemente.especieId)) {
                        ctrl.especiesGerminacaoSementes.push({
                            id: germinacaoSemente.especieId,
                            uuid: germinacaoSemente.especieId,
                            codigo: null,
                            descricao: germinacaoSemente.especieDescricao
                        });
                    }
                }
                if ((typeof germinacaoSemente.pomarId !== 'undefined') && germinacaoSemente.pomarId &&
                    (typeof germinacaoSemente.pomarDescricao !== 'undefined') && germinacaoSemente.pomarDescricao) {
                    if (!ctrl.pomarGerminacaoSementes.find(o => o.id === germinacaoSemente.pomarId)) {
                        ctrl.pomarGerminacaoSementes.push({
                            id: germinacaoSemente.pomarId,
                            uuid: germinacaoSemente.pomarId,
                            codigo: null,
                            descricao: germinacaoSemente.pomarDescricao
                        });
                    }
                }
                if ((typeof germinacaoSemente.safraId !== 'undefined') && germinacaoSemente.safraId &&
                    (typeof germinacaoSemente.safraDescricao !== 'undefined') && germinacaoSemente.safraDescricao) {
                    if (!ctrl.safraGerminacaoSementes.find(o => o.id === germinacaoSemente.safraId)) {
                        ctrl.safraGerminacaoSementes.push({
                            id: germinacaoSemente.safraId,
                            uuid: germinacaoSemente.safraId,
                            codigo: null,
                            descricao: germinacaoSemente.safraDescricao
                        });
                    }
                }
                if ((typeof germinacaoSemente.loteId !== 'undefined') && germinacaoSemente.loteId &&
                    (typeof germinacaoSemente.loteDescricao !== 'undefined') && germinacaoSemente.loteDescricao) {
                    if (!ctrl.loteGerminacaoSementes.find(o => o.id === germinacaoSemente.loteId)) {
                        ctrl.loteGerminacaoSementes.push({
                            id: germinacaoSemente.loteId,
                            uuid: germinacaoSemente.loteId,
                            codigo: null,
                            descricao: germinacaoSemente.loteDescricao
                        });
                    }
                }
                if ((typeof germinacaoSemente.materialGeneticoId !== 'undefined') && germinacaoSemente.materialGeneticoId &&
                    (typeof germinacaoSemente.materialGeneticoDescricao !== 'undefined') && germinacaoSemente.materialGeneticoDescricao) {
                    if (!ctrl.materialGeneticoGerminacaoSementes.find(o => o.id === germinacaoSemente.materialGeneticoId)) {
                        ctrl.materialGeneticoGerminacaoSementes.push({
                            id: germinacaoSemente.materialGeneticoId,
                            uuid: germinacaoSemente.materialGeneticoId,
                            codigo: null,
                            descricao: germinacaoSemente.materialGeneticoDescricao
                        });
                    }
                }
            });
            this.listaGerminacaoSementesCarregada = true;
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    dadosLista(filtro = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                this.path = config_app_1.config.api.caminho;
                this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.LAB_GERMINACAO_SEMENTES;
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                var auxColunas = Array();
                auxColunas.push({
                    name: 'codigo',
                    displayName: 'Código',
                    width: 100,
                });
                auxColunas.push({
                    name: 'tipoTratamento',
                    displayName: 'Tipo de Tratamento',
                    width: 150,
                });
                auxColunas.push({
                    name: 'generoDescricao',
                    displayName: 'Gênero',
                    width: 140,
                });
                auxColunas.push({
                    name: 'especieDescricao',
                    displayName: 'Espécie',
                    width: 120,
                });
                auxColunas.push({
                    name: 'pesoSementes',
                    displayName: 'Peso de mil sementes (KG)',
                    width: 160,
                });
                auxColunas.push({
                    name: 'numSementes',
                    displayName: 'Nº sementes por KG',
                    width: 150,
                });
                auxColunas.push({
                    name: 'dataGerminacao',
                    displayName: 'Data/Hora Cadastro',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 190,
                });
                if (!this.listaGerminacaoSementesCarregada) {
                    yield this.carregarListaGerminacaoSementes(filtro);
                }
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: this.listaGerminacaoSementes,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return { data: [], columnDefs: [] };
        });
    }
    dadosListaAnaliseInternaAvaliacao() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                if (!this.germinacaoSelecionado || !this.germinacaoSelecionado.id) {
                    return { data: [], columnDefs: [] };
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let filtro = {
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    germinacaoId: this.germinacaoSelecionado.id,
                    limiteRegistros: 100000
                };
                let lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_INTERNA_AVALIACAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANALISE_INTERNA_AVALIACAO)
                    .filtro(filtro)
                    .listar();
                let auxColunas = new Array();
                lista.sort((a, b) => (a.dataGerminacao > b.dataGerminacao) ? 1 : ((b.dataGerminacao > a.dataGerminacao) ? -1 : 0));
                if ((typeof lista !== 'undefined') && (lista !== null) && (lista.length > 0)) {
                    angular.forEach(lista, (analise) => {
                        if ((typeof analise.germinacaoSementeId !== 'undefined') && filtro.germinacaoId &&
                            (analise.germinacaoSementeId === filtro.germinacaoId) &&
                            (typeof analise.generoId !== 'undefined') && analise.generoId &&
                            (typeof analise.especieId !== 'undefined') && analise.especieId &&
                            (typeof analise.pomarId !== 'undefined') && analise.pomarId &&
                            (typeof analise.safraId !== 'undefined') && analise.safraId &&
                            (typeof analise.loteId !== 'undefined') && analise.loteId &&
                            (typeof analise.materialGeneticoId !== 'undefined') && analise.materialGeneticoId) {
                            let itemAnalise = ctrl.listaAnalise.find(o => ((o.germinacaoSementeId === analise.germinacaoSementeId)));
                            if ((typeof itemAnalise === 'undefined') || !itemAnalise) {
                                itemAnalise = {
                                    id: analise.id,
                                    germinacaoId: analise.germinacaoSementeId,
                                    generoId: analise.generoId,
                                    generoDescricao: analise.generoDescricao,
                                    especieId: analise.especieId,
                                    especieDescricao: analise.especieDescricao,
                                    materialGeneticoId: analise.materialGeneticoId,
                                    materialGeneticoDescricao: analise.materialGeneticoDescricao,
                                    pomarId: analise.pomarId,
                                    pomarDescricao: analise.pomarDescricao,
                                    safraId: analise.safraId,
                                    safraDescricao: analise.safraDescricao,
                                    loteId: analise.loteId,
                                    loteDescricao: analise.loteDescricao,
                                    germinacaoFinal: analise.germinacaoFinal,
                                    dataGerminacao: analise.dataGerminacao
                                };
                                ctrl.listaAnalise.push(itemAnalise);
                            }
                        }
                    });
                }
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    posicao: 1,
                    cellClass: 'text-center',
                    cellTemplate: `
                <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                    <button type="button" class="btn btn-icon bx bxs-edit" style="padding-right: .4rem"
                        onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                        ng-click="grid.appScope.getParentController().abrirAnaliseInternaAvaliacao(row.entity.id)">
                    </button>
                    <button type="button" class="btn btn-icon bx bx-trash" style="padding-left: .4rem"
                        onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                        ng-click="grid.appScope.getParentController().excluirAnaliseInternaAvaliacao(row.entity.id)">
                    </button>
                </div> `
                });
                auxColunas.push({
                    name: 'generoDescricao',
                    displayName: 'Gênero',
                    width: 140
                });
                auxColunas.push({
                    name: 'especieDescricao',
                    displayName: 'Espécie',
                    width: 140
                });
                auxColunas.push({
                    name: 'materialGeneticoDescricao',
                    displayName: 'Material Genético',
                    width: 140,
                });
                auxColunas.push({
                    name: 'pomarDescricao',
                    displayName: 'Pomar',
                    width: 140,
                });
                auxColunas.push({
                    name: 'safraDescricao',
                    displayName: 'Safra',
                    width: 140
                });
                auxColunas.push({
                    name: 'loteDescricao',
                    displayName: 'Lote',
                    width: 140
                });
                auxColunas.push({
                    name: 'germinacaoFinal',
                    displayName: 'Germinação (%)',
                    width: 80
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: ctrl.listaAnalise,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            return { data: [], columnDefs: [] };
        });
    }
    dadosListaRelatorioAnaliseInterna() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let filtro = {
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    limiteRegistros: 100000
                };
                let lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_INTERNA_AVALIACAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANALISE_INTERNA_AVALIACAO)
                    .filtro(filtro)
                    .listar();
                let auxColunas = new Array();
                let listaAnalise = [];
                lista.sort((a, b) => (a.dataGerminacao > b.dataGerminacao) ? 1 : ((b.dataGerminacao > a.dataGerminacao) ? -1 : 0));
                if ((typeof lista !== 'undefined') && (lista !== null) && (lista.length > 0)) {
                    angular.forEach(lista, (analise) => {
                        if ((typeof analise.germinacaoSementeId !== 'undefined') && analise.germinacaoSementeId &&
                            (typeof analise.generoId !== 'undefined') && analise.generoId &&
                            (typeof analise.especieId !== 'undefined') && analise.especieId &&
                            (typeof analise.pomarId !== 'undefined') && analise.pomarId &&
                            (typeof analise.safraId !== 'undefined') && analise.safraId &&
                            (typeof analise.loteId !== 'undefined') && analise.loteId &&
                            (typeof analise.materialGeneticoId !== 'undefined') && analise.materialGeneticoId) {
                            let itemAnalise = listaAnalise.find(o => ((o.germinacaoSementeId === analise.germinacaoSementeId)));
                            if ((typeof itemAnalise === 'undefined') || !itemAnalise) {
                                itemAnalise = {
                                    id: analise.id,
                                    germinacaoId: analise.germinacaoSementeId,
                                    generoId: analise.generoId,
                                    generoDescricao: analise.generoDescricao,
                                    especieId: analise.especieId,
                                    especieDescricao: analise.especieDescricao,
                                    materialGeneticoId: analise.materialGeneticoId,
                                    materialGeneticoDescricao: analise.materialGeneticoDescricao,
                                    pomarId: analise.pomarId,
                                    pomarDescricao: analise.pomarDescricao,
                                    safraId: analise.safraId,
                                    safraDescricao: analise.safraDescricao,
                                    loteId: analise.loteId,
                                    loteDescricao: analise.loteDescricao,
                                    germinacaoFinal: analise.germinacaoFinal,
                                    dataGerminacao: analise.dataGerminacao
                                };
                                listaAnalise.push(itemAnalise);
                            }
                        }
                    });
                }
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    posicao: 1,
                    cellClass: 'text-center',
                    cellTemplate: `
                <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                    <button type="button" class="btn btn-icon bx bxs-edit" style="padding-right: .4rem"
                        onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                        ng-click="grid.appScope.getParentController().abrirAnaliseInternaAvaliacao(row.entity.id)">
                    </button>
                    <button type="button" class="btn btn-icon bx bx-trash" style="padding-left: .4rem"
                        onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                        ng-click="grid.appScope.getParentController().excluirAnaliseInternaAvaliacao(row.entity.id)">
                    </button>
                </div> `
                });
                auxColunas.push({
                    name: 'generoDescricao',
                    displayName: 'Gênero',
                    width: 140
                });
                auxColunas.push({
                    name: 'especieDescricao',
                    displayName: 'Espécie',
                    width: 140
                });
                auxColunas.push({
                    name: 'materialGeneticoDescricao',
                    displayName: 'Material Genético',
                    width: 140,
                });
                auxColunas.push({
                    name: 'pomarDescricao',
                    displayName: 'Pomar',
                    width: 140,
                });
                auxColunas.push({
                    name: 'safraDescricao',
                    displayName: 'Safra',
                    width: 140
                });
                auxColunas.push({
                    name: 'loteDescricao',
                    displayName: 'Lote',
                    width: 140
                });
                auxColunas.push({
                    name: 'germinacaoFinal',
                    displayName: 'Germinação (%)',
                    width: 80
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: listaAnalise,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            return { data: [], columnDefs: [] };
        });
    }
    dadosListaLaboratorioExterno() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let filtro = {
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    limiteRegistros: 100000
                };
                let lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_GERMINACAO_SEMENTES_EXTERNO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_GERMINACAO_SEMENTES_EXTERNO)
                    .filtro(filtro)
                    .listar();
                let auxColunas = new Array();
                let listaLaboratorios = [];
                if ((typeof lista !== 'undefined') && (lista !== null) && (lista.length > 0)) {
                    angular.forEach(lista, (laboratorio) => {
                        if ((typeof laboratorio.generoId !== 'undefined') && laboratorio.generoId &&
                            (typeof laboratorio.especieId !== 'undefined') && laboratorio.especieId &&
                            (typeof laboratorio.pomarId !== 'undefined') && laboratorio.pomarId &&
                            (typeof laboratorio.safraId !== 'undefined') && laboratorio.safraId &&
                            (typeof laboratorio.loteId !== 'undefined') && laboratorio.loteId &&
                            (typeof laboratorio.materialGeneticoId !== 'undefined') && laboratorio.materialGeneticoId) {
                            angular.forEach(laboratorio.laboratorios, (lab) => {
                                var _a, _b;
                                let idLab = (_a = lab.laboratorio) !== null && _a !== void 0 ? _a : '';
                                let itemLabData = ctrl.laboratorios.find(u => ((typeof u.id !== 'undefined') &&
                                    idLab === u.id));
                                let nomeLab = (_b = itemLabData.descricao) !== null && _b !== void 0 ? _b : '';
                                let itemLaboratorio = listaLaboratorios.find(o => ((typeof o.germinacaoSementeId !== 'undefined' ||
                                    typeof o.germinacaoAnterior !== 'undefined')));
                                if ((typeof itemLaboratorio === 'undefined') || !itemLaboratorio) {
                                    itemLaboratorio = {
                                        id: laboratorio.id,
                                        germinacaoId: laboratorio.germinacaoSementeId,
                                        generoId: laboratorio.generoId,
                                        generoDescricao: laboratorio.generoDescricao,
                                        especieId: laboratorio.especieId,
                                        especieDescricao: laboratorio.especieDescricao,
                                        materialGeneticoId: laboratorio.materialGeneticoId,
                                        materialGeneticoDescricao: laboratorio.materialGeneticoDescricao,
                                        pomarId: laboratorio.pomarId,
                                        pomarDescricao: laboratorio.pomarDescricao,
                                        safraId: laboratorio.safraId,
                                        safraDescricao: laboratorio.safraDescricao,
                                        loteId: laboratorio.loteId,
                                        loteDescricao: laboratorio.loteDescricao,
                                        dataGerminacao: laboratorio.dataGerminacao,
                                        laboratorio: nomeLab
                                    };
                                    listaLaboratorios.push(itemLaboratorio);
                                }
                            });
                        }
                    });
                }
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    posicao: 1,
                    cellClass: 'text-center',
                    cellTemplate: `
                <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                    <button type="button" class="btn btn-icon bx bxs-edit" style="padding-right: .4rem"
                        onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                        ng-click="grid.appScope.getParentController().abrirLaboratorioExterno(row.entity.id)">
                    </button>
                    <button type="button" class="btn btn-icon bx bx-trash" style="padding-left: .4rem"
                        onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                        ng-click="grid.appScope.getParentController().excluirLaboratorioExterno(row.entity.id)">
                    </button>
                </div> `
                });
                auxColunas.push({
                    name: 'generoDescricao',
                    displayName: 'Gênero',
                    width: 140
                });
                auxColunas.push({
                    name: 'especieDescricao',
                    displayName: 'Espécie',
                    width: 140
                });
                auxColunas.push({
                    name: 'materialGeneticoDescricao',
                    displayName: 'Material Genético',
                    width: 140,
                });
                auxColunas.push({
                    name: 'pomarDescricao',
                    displayName: 'Pomar',
                    width: 140,
                });
                auxColunas.push({
                    name: 'safraDescricao',
                    displayName: 'Safra',
                    width: 140
                });
                auxColunas.push({
                    name: 'loteDescricao',
                    displayName: 'Lote',
                    width: 140
                });
                auxColunas.push({
                    name: 'dataGerminacao',
                    displayName: 'Data Envio',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 190,
                });
                auxColunas.push({
                    name: 'laboratorio',
                    displayName: 'Laboratório',
                    width: 140,
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: listaLaboratorios,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            return { data: [], columnDefs: [] };
        });
    }
    dadosListaRelatorioAnaliseInternaCompleto() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.path = config_app_1.config.api.caminho;
                this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.LAB_GERMINACAO_SEMENTES;
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                let lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_INTERNA_AVALIACAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANALISE_INTERNA_AVALIACAO)
                    .filtro(ctrl.filtroRelatorio)
                    .listar();
                var auxColunas = Array();
                this.listaAnaliseInterna = [];
                lista.sort((a, b) => (a.dataGerminacao > b.dataGerminacao) ? 1 : ((b.dataGerminacao > a.dataGerminacao) ? -1 : 0));
                if ((typeof lista !== 'undefined') && (lista !== null) && (lista.length > 0)) {
                    angular.forEach(lista, (analise) => {
                        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
                        if ((typeof analise.germinacaoSementeId !== 'undefined') && analise.germinacaoSementeId &&
                            (typeof analise.generoId !== 'undefined') && analise.generoId &&
                            (typeof analise.especieId !== 'undefined') && analise.especieId &&
                            (typeof analise.pomarId !== 'undefined') && analise.pomarId &&
                            (typeof analise.safraId !== 'undefined') && analise.safraId &&
                            (typeof analise.loteId !== 'undefined') && analise.loteId &&
                            (typeof analise.materialGeneticoId !== 'undefined') && analise.materialGeneticoId) {
                            let itemVerifica = ctrl.listaAnaliseInterna.find(u => (((typeof u.germinacaoId !== 'undefined') && (u.germinacaoId === analise.germinacaoSementeId))));
                            if ((typeof itemVerifica === 'undefined') || !itemVerifica) {
                                let itemGerminacao = ctrl.listaGerminacaoSementes.find(o => (((typeof o.id !== 'undefined') && (o.id === analise.germinacaoSementeId))));
                                if ((typeof itemGerminacao !== 'undefined') && itemGerminacao) {
                                    const dataFormatada = DataUtil_1.DataUtil.converterDataParaFormatoDDMMYY(analise.dataGerminacao);
                                    let itemAnalise = {
                                        codigoAnalise: analise.codigo,
                                        germinacaoId: analise.germinacaoSementeId,
                                        dataGerminacao: dataFormatada,
                                        dataGerminacaoCom: dataFormatada,
                                        generoId: analise.generoId,
                                        generoDescricao: analise.generoDescricao,
                                        especieId: analise.especieId,
                                        especieDescricao: analise.especieDescricao,
                                        materialGeneticoId: analise.materialGeneticoId,
                                        materialGeneticoDescricao: analise.materialGeneticoDescricao,
                                        pomarId: analise.pomarId,
                                        pomarDescricao: analise.pomarDescricao,
                                        safraId: analise.safraId,
                                        safraDescricao: analise.safraDescricao,
                                        loteId: analise.loteId,
                                        loteDescricao: analise.loteDescricao,
                                        germinacaoFinal: analise.germinacaoFinal,
                                        tipoTratamento: itemGerminacao.tipoTratamento,
                                        pesoKg: itemGerminacao.pesoSementes,
                                        numSementes: itemGerminacao.numSementes,
                                        semEstratificao: [],
                                        comEstratificao: [],
                                        totalGerminadoSem: 0,
                                        totalGerminadoCom: 0,
                                        tetrazolio: 0
                                    };
                                    let sementesGerminadas = {
                                        amostra1: (_a = analise.amostra1NumGerminada) !== null && _a !== void 0 ? _a : 0,
                                        amostra2: (_b = analise.amostra2NumGerminada) !== null && _b !== void 0 ? _b : 0,
                                        amostra3: (_c = analise.amostra3NumGerminada) !== null && _c !== void 0 ? _c : 0,
                                        amostra4: (_d = analise.amostra4NumGerminada) !== null && _d !== void 0 ? _d : 0,
                                    };
                                    let mediaTotGerminado = parseFloat(((sementesGerminadas.amostra1 + sementesGerminadas.amostra2 + sementesGerminadas.amostra3 + sementesGerminadas.amostra4) / 4).toFixed(2));
                                    let estratificacao = (_e = analise.dormencia) !== null && _e !== void 0 ? _e : 'Sem quebra';
                                    if (estratificacao === 'Sem quebra') {
                                        itemAnalise.semEstratificao.push(sementesGerminadas);
                                        if (itemAnalise.semEstratificao.length < 2) {
                                            itemAnalise.totalGerminadoSem += mediaTotGerminado;
                                        }
                                    }
                                    else {
                                        itemAnalise.comEstratificao.push(sementesGerminadas);
                                        if (itemAnalise.comEstratificao.length < 2) {
                                            itemAnalise.totalGerminadoCom += mediaTotGerminado;
                                        }
                                    }
                                    ctrl.listaAnaliseInterna.push(itemAnalise);
                                }
                            }
                            else {
                                let sementesGerminadas = {
                                    amostra1: (_f = analise.amostra1NumGerminada) !== null && _f !== void 0 ? _f : 0,
                                    amostra2: (_g = analise.amostra2NumGerminada) !== null && _g !== void 0 ? _g : 0,
                                    amostra3: (_h = analise.amostra3NumGerminada) !== null && _h !== void 0 ? _h : 0,
                                    amostra4: (_j = analise.amostra4NumGerminada) !== null && _j !== void 0 ? _j : 0,
                                };
                                let mediaTotGerminado = parseFloat(((sementesGerminadas.amostra1 + sementesGerminadas.amostra2 + sementesGerminadas.amostra3 + sementesGerminadas.amostra4) / 4).toFixed(2));
                                let estratificacao = (_k = analise.dormencia) !== null && _k !== void 0 ? _k : 'Sem quebra';
                                if (estratificacao === 'Sem quebra') {
                                    itemVerifica.semEstratificao.push(sementesGerminadas);
                                    if (itemVerifica.semEstratificao.length < 2) {
                                        itemVerifica.totalGerminadoSem += mediaTotGerminado;
                                    }
                                }
                                else {
                                    itemVerifica.comEstratificao.push(sementesGerminadas);
                                    if (itemVerifica.comEstratificao.length < 2) {
                                        itemVerifica.totalGerminadoCom += mediaTotGerminado;
                                    }
                                }
                            }
                        }
                    });
                }
                var primeiraAvaliacaoSemTemplate = '<div class="text-center" ng-if="row.entity.semEstratificao[0]">{{row.entity.semEstratificao[0].amostra1}}</br>{{row.entity.semEstratificao[0].amostra2}}</br>{{row.entity.semEstratificao[0].amostra3}}</br>{{row.entity.semEstratificao[0].amostra4}}</div>' +
                    '<div class="text-center" ng-if="!row.entity.semEstratificao[0]">0</br> 0</br> 0</br> 0</div>';
                var segundaAvaliacaoSemTemplate = '<div class="text-center" ng-if="row.entity.semEstratificao[1]">{{row.entity.semEstratificao[1].amostra1}}</br>{{row.entity.semEstratificao[1].amostra2}}</br>{{row.entity.semEstratificao[1].amostra3}}</br>{{row.entity.semEstratificao[1].amostra4}}</div>' +
                    '<div class="text-center" ng-if="!row.entity.semEstratificao[1]">0</br> 0</br> 0</br> 0</div>';
                var primeiraAvaliacaoComTemplate = '<div class="text-center" ng-if="row.entity.comEstratificao[0]">{{row.entity.comEstratificao[0].amostra1}}</br>{{row.entity.comEstratificao[0].amostra2}}</br>{{row.entity.comEstratificao[0].amostra3}}</br>{{row.entity.comEstratificao[0].amostra4}}</div>' +
                    '<div class="text-center" ng-if="!row.entity.comEstratificao[0]">0</br> 0</br> 0</br> 0</div>';
                var segundaAvaliacaoComTemplate = '<div class="text-center" ng-if="row.entity.comEstratificao[1]">{{row.entity.comEstratificao[1].amostra1}}</br>{{row.entity.comEstratificao[1].amostra2}}</br>{{row.entity.comEstratificao[1].amostra3}}</br>{{row.entity.comEstratificao[1].amostra4}}</div>' +
                    '<div class="text-center" ng-if="!row.entity.comEstratificao[1]">0</br> 0</br> 0</br> 0</div>';
                auxColunas.push({
                    name: 'generoDescricao',
                    displayName: 'Gênero',
                    width: 140
                });
                auxColunas.push({
                    name: 'especieDescricao',
                    displayName: 'Espécie',
                    width: 140
                });
                auxColunas.push({
                    name: 'materialGeneticoDescricao',
                    displayName: 'Material Genético',
                    width: 140,
                });
                auxColunas.push({
                    name: 'pomarDescricao',
                    displayName: 'Pomar',
                    width: 140,
                });
                auxColunas.push({
                    name: 'safraDescricao',
                    displayName: 'Safra',
                    width: 140
                });
                auxColunas.push({
                    name: 'loteDescricao',
                    displayName: 'Lote',
                    width: 140
                });
                auxColunas.push({
                    name: 'pesoKg',
                    displayName: 'Peso 1000 Semente',
                    width: 150
                });
                auxColunas.push({
                    name: 'numSementes',
                    displayName: 'Sementes por KG',
                    width: 140
                });
                auxColunas.push({
                    name: 'tipoTratamento',
                    displayName: 'Tipo de Tratamento',
                    width: 146
                });
                auxColunas.push({
                    name: 'dataGerminacao',
                    displayName: 'Inicio da germinação',
                    width: 154,
                });
                auxColunas.push({
                    name: 'semEstratificao[0]',
                    displayName: '1º Avaliação',
                    width: 110,
                    cellTemplate: primeiraAvaliacaoSemTemplate
                });
                auxColunas.push({
                    name: 'semEstratificao[1]',
                    displayName: '2º Avaliação',
                    width: 110,
                    cellTemplate: segundaAvaliacaoSemTemplate
                });
                auxColunas.push({
                    name: 'totalGerminadoSem',
                    displayName: 'Total Germinado',
                    width: 130
                });
                auxColunas.push({
                    name: 'dataGerminacaoCom',
                    displayName: 'Inicio da germinação',
                    width: 154,
                });
                auxColunas.push({
                    name: 'comEstratificao[0]',
                    displayName: '1º Avaliação',
                    width: 110,
                    cellTemplate: primeiraAvaliacaoComTemplate
                });
                auxColunas.push({
                    name: 'comEstratificao[1]',
                    displayName: '2º Avaliação',
                    width: 110,
                    cellTemplate: segundaAvaliacaoComTemplate
                });
                auxColunas.push({
                    name: 'totalGerminadoCom',
                    displayName: 'Total Germinado',
                    width: 140
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: ctrl.listaAnaliseInterna,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this,
                    rowHeight: 50,
                    headerTemplate: 'app/angularjs/modulos/cadastro/paginas/labGerminacaoSementes/template/cabecalhoRelatorioInternoCompleto.html'
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            return { data: [], columnDefs: [] };
        });
    }
    dadosListaRelatorioExterno() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.path = config_app_1.config.api.caminho;
                this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO;
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                let lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_GERMINACAO_SEMENTES_EXTERNO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_GERMINACAO_SEMENTES_EXTERNO)
                    .filtro(ctrl.filtroRelatorio)
                    .listar();
                var auxColunas = Array();
                this.listaLaboratoriosRelatorios = [];
                this.listaLaboratoriosRelatoriosNomes = [];
                let listaRelatorio = [];
                lista.sort((a, b) => (a.dataGerminacao > b.dataGerminacao) ? 1 : ((b.dataGerminacao > a.dataGerminacao) ? -1 : 0));
                if ((typeof lista !== 'undefined') && (lista !== null) && (lista.length > 0)) {
                    angular.forEach(lista, (laboratorio) => {
                        var _a, _b, _c, _d;
                        if ((typeof laboratorio.generoId !== 'undefined') && laboratorio.generoId &&
                            (typeof laboratorio.especieId !== 'undefined') && laboratorio.especieId &&
                            (typeof laboratorio.pomarId !== 'undefined') && laboratorio.pomarId &&
                            (typeof laboratorio.safraId !== 'undefined') && laboratorio.safraId &&
                            (typeof laboratorio.loteId !== 'undefined') && laboratorio.loteId &&
                            (typeof laboratorio.materialGeneticoId !== 'undefined') && laboratorio.materialGeneticoId) {
                            let reteste = laboratorio.reteste ? 'Sim' : 'Não';
                            let codigoAnaliseAnterior = Number((_a = laboratorio.germinacaoAnterior) !== null && _a !== void 0 ? _a : 0);
                            let itemLaboratorio = {
                                id: laboratorio.id,
                                germinacaoId: laboratorio.germinacaoSementeId,
                                generoId: laboratorio.generoId,
                                generoDescricao: laboratorio.generoDescricao,
                                especieId: laboratorio.especieId,
                                especieDescricao: laboratorio.especieDescricao,
                                materialGeneticoId: laboratorio.materialGeneticoId,
                                materialGeneticoDescricao: laboratorio.materialGeneticoDescricao,
                                pomarId: laboratorio.pomarId,
                                pomarDescricao: laboratorio.pomarDescricao,
                                safraId: laboratorio.safraId,
                                safraDescricao: laboratorio.safraDescricao,
                                loteId: laboratorio.loteId,
                                loteDescricao: laboratorio.loteDescricao,
                                dataGerminacao: laboratorio.dataGerminacao,
                                reteste: reteste,
                                representatividade: laboratorio.representatividade,
                                germinacaoAnterior: codigoAnaliseAnterior,
                                germinacaoFinal: 0,
                                pesoKgAnterior: 0,
                                numSementesAnterior: 0,
                                variacaoGerminacaoSEM: 0,
                                variacaoGerminacaoCOM: 0,
                                tetrazolio: 0,
                                laboratorios: {}
                            };
                            let itemAnaliseInterna = ctrl.listaAnaliseInterna.find(o => (((typeof o.codigoAnalise !== 'undefined') && (o.codigoAnalise === itemLaboratorio.germinacaoAnterior))));
                            if ((typeof itemAnaliseInterna !== 'undefined') && itemAnaliseInterna) {
                                itemLaboratorio.germinacaoFinal = (_b = itemAnaliseInterna.germinacaoFinal) !== null && _b !== void 0 ? _b : 0;
                                itemLaboratorio.pesoKgAnterior = (_c = itemAnaliseInterna.pesoKg) !== null && _c !== void 0 ? _c : 0;
                                itemLaboratorio.numSementesAnterior = (_d = itemAnaliseInterna.numSementes) !== null && _d !== void 0 ? _d : 0;
                            }
                            angular.forEach(laboratorio.laboratorios, (lab) => {
                                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
                                let idLab = (_a = lab.laboratorio) !== null && _a !== void 0 ? _a : '';
                                let itemLabData = ctrl.laboratorios ? ctrl.laboratorios.find(u => (typeof u.id !== 'undefined') && (idLab === u.id)) : null;
                                let nomeLab = itemLabData && itemLabData.descricao ? itemLabData.descricao : idLab;
                                if (ctrl.listaLaboratoriosRelatorios.indexOf(idLab) === -1) {
                                    ctrl.listaLaboratoriosRelatorios.push(idLab);
                                    ctrl.listaLaboratoriosRelatoriosNomes.push(nomeLab);
                                }
                                let laboratorioData = {
                                    nomeLab: nomeLab !== null && nomeLab !== void 0 ? nomeLab : '',
                                    numBAS: (_b = lab.numBAS) !== null && _b !== void 0 ? _b : '',
                                    pureza: (_c = lab.pureza) !== null && _c !== void 0 ? _c : 0,
                                    pesoMilSementes: (_d = lab.pesoMilSementes) !== null && _d !== void 0 ? _d : 0,
                                    numSementesKg: (_e = lab.numSementesKg) !== null && _e !== void 0 ? _e : 0,
                                    germinacaoCOM: (_f = lab.germinacaoCOM) !== null && _f !== void 0 ? _f : 0,
                                    germinacaoSEM: (_g = lab.germinacaoSEM) !== null && _g !== void 0 ? _g : 0,
                                };
                                if ((typeof itemLaboratorio.laboratorios[idLab] !== 'undefined') && itemLaboratorio.laboratorios[idLab]) {
                                    itemLaboratorio.laboratorios[idLab].lista.push(laboratorioData);
                                    let itemSomaLab = {
                                        nomeLab: nomeLab !== null && nomeLab !== void 0 ? nomeLab : '',
                                        numBAS: (_h = lab.numBAS) !== null && _h !== void 0 ? _h : '',
                                        pureza: 0,
                                        pesoMilSementes: 0,
                                        numSementesKg: 0,
                                        germinacaoCOM: 0,
                                        germinacaoSEM: 0,
                                    };
                                    angular.forEach(itemLaboratorio.laboratorios[idLab].lista, (labAtual) => {
                                        itemSomaLab.pureza += (labAtual.pureza ? labAtual.pureza : 0);
                                        itemSomaLab.pesoMilSementes += (labAtual.pesoMilSementes ? labAtual.pesoMilSementes : 0);
                                        itemSomaLab.numSementesKg += (labAtual.numSementesKg ? labAtual.numSementesKg : 0);
                                        itemSomaLab.germinacaoCOM += (labAtual.germinacaoCOM ? labAtual.germinacaoCOM : 0);
                                        itemSomaLab.germinacaoSEM += (labAtual.germinacaoSEM ? labAtual.germinacaoSEM : 0);
                                        itemLaboratorio.variacaoGerminacaoSEM -= (labAtual.germinacaoCOM ? labAtual.germinacaoCOM : 0);
                                        itemLaboratorio.variacaoGerminacaoCOM -= (labAtual.germinacaoSEM ? labAtual.germinacaoSEM : 0);
                                    });
                                    itemLaboratorio.laboratorios[idLab].media = {
                                        nomeLab: (_j = itemSomaLab.nomeLab) !== null && _j !== void 0 ? _j : '',
                                        numBAS: (_k = itemSomaLab.numBAS) !== null && _k !== void 0 ? _k : '',
                                        pureza: (Math.round((itemSomaLab.pureza / itemLaboratorio.laboratorios[idLab].lista.length) * 100) / 100),
                                        pesoMilSementes: (Math.round((itemSomaLab.pesoMilSementes / itemLaboratorio.laboratorios[idLab].lista.length) * 100) / 100),
                                        numSementesKg: (Math.round((itemSomaLab.numSementesKg / itemLaboratorio.laboratorios[idLab].lista.length) * 100) / 100),
                                        germinacaoCOM: (Math.round((itemSomaLab.germinacaoCOM / itemLaboratorio.laboratorios[idLab].lista.length) * 100) / 100),
                                        germinacaoSEM: (Math.round((itemSomaLab.germinacaoSEM / itemLaboratorio.laboratorios[idLab].lista.length) * 100) / 100),
                                    };
                                }
                                else {
                                    itemLaboratorio.laboratorios[idLab] = {
                                        media: laboratorioData,
                                        lista: [laboratorioData]
                                    };
                                    itemLaboratorio.variacaoGerminacaoSEM = laboratorioData.germinacaoSEM;
                                    itemLaboratorio.variacaoGerminacaoCOM = laboratorioData.germinacaoCOM;
                                }
                            });
                            listaRelatorio.push(itemLaboratorio);
                        }
                    });
                }
                auxColunas.push({
                    name: 'generoDescricao',
                    displayName: 'Gênero',
                    width: 140
                });
                auxColunas.push({
                    name: 'especieDescricao',
                    displayName: 'Espécie',
                    width: 140
                });
                auxColunas.push({
                    name: 'materialGeneticoDescricao',
                    displayName: 'Material Genético',
                    width: 140,
                });
                auxColunas.push({
                    name: 'pomarDescricao',
                    displayName: 'Pomar',
                    width: 140,
                });
                auxColunas.push({
                    name: 'safraDescricao',
                    displayName: 'Safra',
                    width: 140
                });
                auxColunas.push({
                    name: 'loteDescricao',
                    displayName: 'Lote',
                    width: 140
                });
                auxColunas.push({
                    name: 'representatividade',
                    displayName: 'Representatividade (kg)',
                    width: 170,
                });
                auxColunas.push({
                    name: 'reteste',
                    displayName: 'Reteste',
                    width: 80,
                });
                auxColunas.push({
                    name: 'germinacaoFinal',
                    displayName: '% Germinação Anterior',
                    width: 170,
                });
                angular.forEach(ctrl.listaLaboratoriosRelatorios, (idLab) => {
                    let itemLabData = ctrl.laboratorios ? ctrl.laboratorios.find(u => (typeof u.id !== 'undefined') && (idLab === u.id)) : null;
                    let nomeLab = itemLabData && itemLabData.descricao ? itemLabData.descricao : idLab;
                    auxColunas.push({
                        name: 'laboratorios.' + idLab + '.media.numBAS',
                        displayName: nomeLab + '||BAS',
                        visible: true,
                        width: 90
                    });
                    auxColunas.push({
                        name: 'laboratorios.' + idLab + '.media.pureza',
                        displayName: 'Pureza %',
                        visible: true,
                        width: 90
                    });
                    auxColunas.push({
                        name: 'laboratorios.' + idLab + '.media.pesoMilSementes',
                        displayName: 'Peso Mil',
                        visible: true,
                        width: 90
                    });
                    auxColunas.push({
                        name: 'laboratorios.' + idLab + '.media.numSementesKg',
                        displayName: 'Nº sem/kg',
                        visible: true,
                        width: 90
                    });
                    auxColunas.push({
                        name: 'laboratorios.' + idLab + '.media.germinacaoSEM',
                        displayName: '% G *',
                        visible: true,
                        width: 90
                    });
                    auxColunas.push({
                        name: 'laboratorios.' + idLab + '.media.germinacaoCOM',
                        displayName: '% G **',
                        visible: true,
                        width: 90
                    });
                });
                auxColunas.push({
                    name: 'pesoKgAnterior',
                    displayName: 'Peso Mil',
                    width: 90
                });
                auxColunas.push({
                    name: 'numSementesAnterior',
                    displayName: 'Nº sem/kg',
                    width: 100
                });
                auxColunas.push({
                    name: 'variacaoGerminacaoSEM',
                    displayName: 'Δ % G *',
                    width: 90
                });
                auxColunas.push({
                    name: 'variacaoGerminacaoCOM',
                    displayName: 'Δ % G **',
                    width: 100
                });
                auxColunas.push({
                    name: 'tetrazolio',
                    displayName: 'Tetrazólio %',
                    width: 120
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: listaRelatorio,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this,
                    headerTemplate: 'app/angularjs/modulos/cadastro/paginas/labGerminacaoSementes/template/cabecalhoRelatorioExternoCompleto.html'
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            return { data: [], columnDefs: [] };
        });
    }
    filtrar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    limparFiltro() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    salvarLaboratorioExterno() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!this.germinacaoSelecionado) {
                    return;
                }
                this.dadosLaboratorioExterno.germinacaoSemente = this.germinacaoSelecionado;
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                if (typeof this.dadosLaboratorioExterno.id === 'undefined' || !this.dadosLaboratorioExterno.id) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_GERMINACAO_SEMENTES_EXTERNO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_GERMINACAO_SEMENTES_EXTERNO)
                        .criar(this.dadosLaboratorioExterno);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_GERMINACAO_SEMENTES_EXTERNO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_GERMINACAO_SEMENTES_EXTERNO)
                        .atualizar(this.dadosLaboratorioExterno);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir um novo registro de Laboratório Externo ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir um novo registro de Laboratório Externo ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.ocultarAnaliseInternaAvaliacao();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
                this.ocultarLaboratorioExterno();
            }
            finally {
                this.ocultarLaboratorioExterno();
            }
        });
    }
    salvarAnaliseInternaAvaliacao() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!this.germinacaoSelecionado) {
                    return;
                }
                this.dadosAnaliseInterna.germinacaoSemente = this.germinacaoSelecionado;
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                if (typeof this.dadosAnaliseInterna.id === 'undefined' || !this.dadosAnaliseInterna.id) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_INTERNA_AVALIACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANALISE_INTERNA_AVALIACAO)
                        .criar(this.dadosAnaliseInterna);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_INTERNA_AVALIACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANALISE_INTERNA_AVALIACAO)
                        .atualizar(this.dadosAnaliseInterna);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir uma nova Análise de Germinação Interna ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir uma nova Análise de Germinação Interna ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.ocultarAnaliseInternaAvaliacao();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
                this.ocultarAnaliseInternaAvaliacao();
            }
            finally {
                this.ocultarAnaliseInternaAvaliacao();
            }
        });
    }
    abrirAnaliseInternaAvaliacao(id = null) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.germinacaoSelecionado === 'undefined') || !this.germinacaoSelecionado) {
                sweetalert2_1.default.fire({
                    title: 'Nenhum registro selecionado!',
                    text: 'Selecione primeiramente um registro de Germinação de Sementes na tabela para lançar Análise Interna - Avaliação.',
                    icon: 'warning',
                });
                return;
            }
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (typeof id !== 'undefined' && id) {
                    this.dadosAnaliseInterna = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_INTERNA_AVALIACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANALISE_INTERNA_AVALIACAO)
                        .id(id)
                        .ver();
                    if (this.dadosAnaliseInterna) {
                        if (typeof this.dadosAnaliseInterna.dataGerminacao === 'string') {
                            this.dadosAnaliseInterna.dataGerminacao = new Date(this.dadosAnaliseInterna.dataGerminacao);
                        }
                    }
                }
                else {
                    this.dadosAnaliseInterna = new AnaliseInternaGerminacao_1.AnaliseInternaGerminacao();
                }
                this.modalAnaliseInterna = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    excluirAnaliseInternaAvaliacao(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            this.notificacao
                .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                .then((result) => __awaiter(this, void 0, void 0, function* () {
                if (result.value) {
                    CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_INTERNA_AVALIACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANALISE_INTERNA_AVALIACAO)
                        .id(id)
                        .remover();
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.listaOpcoesAnalise = yield ctrl.dadosListaAnaliseInternaAvaliacao();
                    ctrl.scopeApply();
                }
            }));
        });
    }
    ocultarAnaliseInternaAvaliacao() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalAnaliseInterna = false;
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    abrirLaboratorioExterno(id = null) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.germinacaoSelecionado === 'undefined') || !this.germinacaoSelecionado) {
                sweetalert2_1.default.fire({
                    title: 'Nenhum registro selecionado!',
                    text: 'Selecione primeiramente um registro de Germinação de Sementes na tabela para lançar Laboratório Externo.',
                    icon: 'warning',
                });
                return;
            }
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (typeof id !== 'undefined' && id) {
                    this.dadosLaboratorioExterno = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_GERMINACAO_SEMENTES_EXTERNO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_GERMINACAO_SEMENTES_EXTERNO)
                        .id(id)
                        .ver();
                    if (this.dadosLaboratorioExterno) {
                        if (typeof this.dadosLaboratorioExterno.dataGerminacao === 'string') {
                            this.dadosLaboratorioExterno.dataGerminacao = new Date(this.dadosLaboratorioExterno.dataGerminacao);
                        }
                    }
                }
                else {
                    this.dadosLaboratorioExterno = new LabGerminacaoSementesExterno_1.LabGerminacaoSementesExterno();
                }
                this.modalLaboratorioExterno = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    excluirLaboratorioExterno(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            this.notificacao
                .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                .then((result) => __awaiter(this, void 0, void 0, function* () {
                if (result.value) {
                    CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_GERMINACAO_SEMENTES_EXTERNO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_GERMINACAO_SEMENTES_EXTERNO)
                        .id(id)
                        .remover();
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.listaOpcoesLaboratorio = yield ctrl.dadosListaLaboratorioExterno();
                    ctrl.scopeApply();
                }
            }));
        });
    }
    ocultarLaboratorioExterno() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalLaboratorioExterno = false;
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    abrirRelatorios() {
        return __awaiter(this, void 0, void 0, function* () { });
    }
    calcularNumSementes() {
        return __awaiter(this, void 0, void 0, function* () {
            let pesoSementes = this.modelo.pesoSementes !== 0 ? this.modelo.pesoSementes : 1;
            this.modelo.numSementes = parseFloat((1000 / pesoSementes).toFixed(2));
        });
    }
    limparFiltroGerminacao() {
        return __awaiter(this, void 0, void 0, function* () {
            this.germinacaoSelecionado = null;
            this.germinacaoCarregado = false;
        });
    }
    formatarNumero(valor, casasDecimais) {
        return NumeroUtil_1.NumeroUtil.formatarNumero(valor, casasDecimais);
    }
    selecionarGerminacao() {
        return __awaiter(this, void 0, void 0, function* () {
            let ctrl = this;
            if (ctrl && ctrl.constructor && ctrl.constructor.name) {
                if (ctrl.constructor.name === 'TabelaController') {
                    ctrl = ctrl.$scope.$parent.$ctrl;
                }
            }
            ctrl.filtrarGerminacaoSelecionado();
        });
    }
    filtrarGerminacaoSementes() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.filtrarGerminacaoSelecionado();
                if ((typeof this.germinacaoSelecionado !== 'undefined') && this.germinacaoSelecionado &&
                    (typeof this.germinacaoSelecionado.id !== 'undefined') && this.germinacaoSelecionado.id) {
                    this.germinacaoCarregado = true;
                    this.scopeApply();
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
        });
    }
    filtrarGerminacaoSelecionado() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                let germinacao = null;
                const nomeTabelaGerminacao = 'tabelaGerminacao';
                let gridGerminacoesApi = null;
                let gridGerminacaoScope = angular.element('#' + nomeTabelaGerminacao).scope();
                if ((typeof gridGerminacaoScope.listaGridApi !== 'undefined') && gridGerminacaoScope.listaGridApi) {
                    angular.forEach(gridGerminacaoScope.listaGridApi, (gridApi) => {
                        const grid = (typeof gridApi.grid !== 'undefined') && gridApi.grid ? gridApi.grid : null;
                        if (grid && typeof (grid.element !== 'undefined') && grid.element) {
                            const tabelasEl = angular.element(grid.element).parents('tabela');
                            if (tabelasEl && (tabelasEl.length > 0) && (tabelasEl.first().attr('id') === nomeTabelaGerminacao)) {
                                gridGerminacoesApi = gridApi;
                            }
                        }
                    });
                }
                if (gridGerminacoesApi) {
                    const germinacoesSelecionados = gridGerminacoesApi.selection.getSelectedRows();
                    if ((typeof germinacoesSelecionados !== 'undefined') && germinacoesSelecionados) {
                        angular.forEach(germinacoesSelecionados, (obj) => {
                            if (!germinacao) {
                                germinacao = obj;
                            }
                        });
                    }
                }
                if (germinacao && (typeof germinacao.id !== 'undefined') && germinacao.id) {
                    if ((typeof ctrl.germinacaoSelecionado !== 'undefined') && ctrl.germinacaoSelecionado &&
                        (typeof ctrl.germinacaoSelecionado.id !== 'undefined') && ctrl.germinacaoSelecionado.id) {
                        if (ctrl.germinacaoSelecionado.id !== germinacao.id) {
                            yield ctrl.limparFiltroGerminacao();
                            ctrl.germinacaoSelecionado = germinacao;
                            ctrl.germinacaoCarregado = false;
                        }
                    }
                    else {
                        yield ctrl.limparFiltroGerminacao();
                        ctrl.germinacaoSelecionado = germinacao;
                        ctrl.germinacaoCarregado = false;
                    }
                }
                else {
                    if (ctrl.germinacaoSelecionado || ctrl.germinacaoCarregado) {
                        yield ctrl.limparFiltroGerminacao();
                        ctrl.germinacaoCarregado = false;
                    }
                }
                ctrl.scopeApply();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
        });
    }
    calcularGerminacaoFinal() {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.germinacaoSelecionado === 'undefined') || !this.germinacaoSelecionado) {
                return;
            }
            let sementeTot1 = (_a = this.dadosAnaliseInterna.amostra1NumTot) !== null && _a !== void 0 ? _a : 0;
            let sementeGer1 = (_b = this.dadosAnaliseInterna.amostra1NumGerminada) !== null && _b !== void 0 ? _b : 0;
            let sementeTot2 = (_c = this.dadosAnaliseInterna.amostra2NumTot) !== null && _c !== void 0 ? _c : 0;
            let sementeGer2 = (_d = this.dadosAnaliseInterna.amostra2NumGerminada) !== null && _d !== void 0 ? _d : 0;
            let sementeTot3 = (_e = this.dadosAnaliseInterna.amostra3NumTot) !== null && _e !== void 0 ? _e : 0;
            let sementeGer3 = (_f = this.dadosAnaliseInterna.amostra3NumGerminada) !== null && _f !== void 0 ? _f : 0;
            let sementeTot4 = (_g = this.dadosAnaliseInterna.amostra4NumTot) !== null && _g !== void 0 ? _g : 0;
            let sementeGer4 = (_h = this.dadosAnaliseInterna.amostra4NumGerminada) !== null && _h !== void 0 ? _h : 0;
            let totSem = sementeTot1 + sementeTot2 + sementeTot3 + sementeTot4;
            let totGer = sementeGer1 + sementeGer2 + sementeGer3 + sementeGer4;
            if (totSem === 0) {
                this.dadosAnaliseInterna.germinacaoFinal = 0;
            }
            else {
                this.dadosAnaliseInterna.germinacaoFinal = parseFloat(((totGer / totSem) * 100).toFixed(2));
            }
        });
    }
    adicionarRegistroListaLaboratorios() {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.germinacaoSelecionado === 'undefined' ||
                !this.germinacaoSelecionado) {
                return;
            }
            if (typeof this.dadosLaboratorioExterno === 'undefined' ||
                !this.dadosLaboratorioExterno) {
                this.dadosLaboratorioExterno = new LabGerminacaoSementesExterno_1.LabGerminacaoSementesExterno();
            }
            if (typeof this.dadosLaboratorioExterno.laboratorios === 'undefined' ||
                !this.dadosLaboratorioExterno.laboratorios) {
                this.dadosLaboratorioExterno.laboratorios = new Array();
            }
            this.dadosLaboratorioExterno.laboratorios.push(new LabGerminacaoSementesExterno_1.LabGerminacaoSementesExterno());
            this.scopeApply();
        });
    }
    removerRegistroListaLaboratorios(key) {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.germinacaoSelecionado === 'undefined' || !this.germinacaoSelecionado) {
                return;
            }
            if (typeof this.dadosLaboratorioExterno.laboratorios === 'undefined' ||
                this.dadosLaboratorioExterno.laboratorios === null) {
                this.dadosLaboratorioExterno.laboratorios = new Array();
            }
            if (typeof key !== 'undefined' &&
                key !== null &&
                this.dadosLaboratorioExterno.laboratorios.length > 0 &&
                key <= this.dadosLaboratorioExterno.laboratorios.length - 1) {
                this.dadosLaboratorioExterno.laboratorios.splice(key, 1);
                this.scopeApply();
            }
        });
    }
    moverRegistroListaLaboratoriosParaCima(key) {
        if (typeof this.germinacaoSelecionado === 'undefined' ||
            !this.germinacaoSelecionado ||
            typeof this.dadosLaboratorioExterno.laboratorios === 'undefined' ||
            !this.dadosLaboratorioExterno.laboratorios) {
            return;
        }
        if (key != null && key >= 0 && key < this.dadosLaboratorioExterno.laboratorios.length - 1) {
            let oldKey = key;
            let newKey = key - 1;
            this.dadosLaboratorioExterno.laboratorios.splice(newKey, 0, this.dadosLaboratorioExterno.laboratorios.splice(oldKey, 1)[0]);
            this.scopeApply();
        }
    }
    moverRegistroListaLaboratoriosParaBaixo(key) {
        if (typeof this.germinacaoSelecionado === 'undefined' ||
            !this.germinacaoSelecionado ||
            typeof this.dadosLaboratorioExterno.laboratorios === 'undefined' ||
            !this.dadosLaboratorioExterno.laboratorios) {
            return;
        }
        if (key != null && key >= 0 && key < this.dadosLaboratorioExterno.laboratorios.length - 1) {
            let oldKey = key;
            let newKey = key + 1;
            this.dadosLaboratorioExterno.laboratorios.splice(newKey, 0, this.dadosLaboratorioExterno.laboratorios.splice(oldKey, 1)[0]);
            this.scopeApply();
        }
    }
    calcularPesoSementesKg(key) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.germinacaoSelecionado === 'undefined' ||
                !this.germinacaoSelecionado ||
                typeof this.dadosLaboratorioExterno.laboratorios === 'undefined' ||
                !this.dadosLaboratorioExterno.laboratorios) {
                return;
            }
            let lista = this.dadosLaboratorioExterno.laboratorios[key];
            let peso = (_a = lista.pesoMilSementes) !== null && _a !== void 0 ? _a : 0;
            if (peso !== 0) {
                let totKg = parseFloat((1000 / peso).toFixed(2));
                this.dadosLaboratorioExterno.laboratorios[key].numSementesKg = totKg;
            }
            else {
                this.dadosLaboratorioExterno.laboratorios[key].numSementesKg = 0;
            }
        });
    }
    filtrarAnalises(alterouDados = false) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.analisesFiltradas === 'undefined') || !this.analisesFiltradas || ((typeof alterouDados !== 'undefined') && alterouDados)) {
                this.listaAnalise = [];
                this.listaOpcoesAnalise = yield this.dadosListaAnaliseInternaAvaliacao();
                this.listaOpcoesLaboratorio = yield this.dadosListaLaboratorioExterno();
                this.listaOpcoesAnaliseInterna = yield this.dadosListaRelatorioAnaliseInterna();
                this.listaOpcoesAnaliseInternaCompleto = yield this.dadosListaRelatorioAnaliseInternaCompleto();
                this.listaOpcoesExterno = this.listaOpcoesLaboratorio;
                this.listaOpcoesExternoCompleto = yield this.dadosListaRelatorioExterno();
                this.analisesFiltradas = true;
                this.scopeApply();
            }
        });
    }
    verFoto(foto) {
        return __awaiter(this, void 0, void 0, function* () {
            DispositivoUtil_1.DispositivoUtil.verFoto(foto);
        });
    }
}
exports.LabGerminacaoSementesController = LabGerminacaoSementesController;
LabGerminacaoSementesController.$inject = ['$scope', '$injector'];
