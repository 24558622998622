"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProducaoMudaClonalMinijardimCroquiAnexo = void 0;
const Base_1 = require("./Base");
class ProducaoMudaClonalMinijardimCroquiAnexo extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.id = null;
        this.codigo = null;
        this.titulo = null;
        this.dados = [];
        this.anexos = [];
    }
}
exports.ProducaoMudaClonalMinijardimCroquiAnexo = ProducaoMudaClonalMinijardimCroquiAnexo;
