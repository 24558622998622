"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProducaoMudaControlePragaDoenca = void 0;
const Base_1 = require("./Base");
class ProducaoMudaControlePragaDoenca extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.tipoControle = null;
        this.data = null;
        this.casaVegetacao = null;
        this.pragaDoenca = null;
        this.nivelContaminacao = null;
        this.observacao = null;
        this.produto = null;
        this.quantidade = null;
        this.contaDepositoSap = null;
        this.centroCustoSap = null;
        this.listaCasaVegetacao = [];
    }
}
exports.ProducaoMudaControlePragaDoenca = ProducaoMudaControlePragaDoenca;
ProducaoMudaControlePragaDoenca.CONTROLE_IDENTIFICACAO = 'Identificação';
ProducaoMudaControlePragaDoenca.CONTROLE_INTERVENCAO = 'Intervenção';
