"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiltroControleManejoPomarTratado = exports.FiltroControleManejoPomar = void 0;
class FiltroControleManejoPomar {
    constructor() {
        this.visualizacaoResumida = false;
        this.visualizacaoDetalhada = true;
        this.dataIni = null;
        this.dataFim = null;
        this.operacaoLimpeza = true;
        this.operacaoDesbaste = true;
        this.operacaoPolinizacao = true;
        this.operacaoColetaAmostras = true;
        this.operacaoColetaSementes = true;
        this.operacaoControleDoencas = true;
        this.operacaoAdubacaoFertilizacao = true;
        this.operacaoPodaRamosPortaEnxerto = true;
        this.outrasOperacoes = true;
    }
}
exports.FiltroControleManejoPomar = FiltroControleManejoPomar;
class FiltroControleManejoPomarTratado {
    constructor() {
        this.pomar = null;
    }
}
exports.FiltroControleManejoPomarTratado = FiltroControleManejoPomarTratado;
