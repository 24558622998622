"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VisibilidadeColunaTabela = void 0;
const Base_1 = require("./Base");
class VisibilidadeColunaTabela extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.chaveTabela = null;
        this.nomeCampo = null;
        this.tituloCampo = null;
        this.campoVisivel = null;
        this.usuario = null;
    }
}
exports.VisibilidadeColunaTabela = VisibilidadeColunaTabela;
