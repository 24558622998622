"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProtecaoRegistroCultivarOrientacoesProcedimentos = void 0;
const Base_1 = require("./Base");
class ProtecaoRegistroCultivarOrientacoesProcedimentos extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.controleRegistro = null;
        this.descricaoAnexo = null;
        this.nomeOperador = null;
        this.anexos = [];
    }
}
exports.ProtecaoRegistroCultivarOrientacoesProcedimentos = ProtecaoRegistroCultivarOrientacoesProcedimentos;
