"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabGerminacaoSementes = void 0;
const Base_1 = require("./Base");
class LabGerminacaoSementes extends Base_1.Base {
    constructor() {
        super(...arguments);
        this._id = null;
        this.codigo = null;
        this.dataGerminacao = null;
        this.genero = null;
        this.especie = null;
        this.pomar = null;
        this.safra = null;
        this.lote = null;
        this.materialGenetico = null;
        this.tipoTratamento = null;
        this.pesoSementes = null;
        this.numSementes = null;
    }
}
exports.LabGerminacaoSementes = LabGerminacaoSementes;
