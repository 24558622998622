"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProducaoMudaControlePragaDoencaController = void 0;
const angular = require("angular");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const UiGridConstrutor_1 = require("../../../app/construtores/UiGridConstrutor");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const AbstratoController_1 = require("../../../app/AbstratoController");
const ICRUDOperacao_1 = require("../../../app/interfaces/ICRUDOperacao");
const config_app_1 = require("../../../../../config/config.app");
const sweetalert2_1 = require("sweetalert2");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const ProducaoMudaControlePragaDoenca_1 = require("../../../../../modelos/ProducaoMudaControlePragaDoenca");
const CasaVegetacao_1 = require("../../../../../modelos/CasaVegetacao");
const HistoricoEstoqueProdutoDefensivo_1 = require("../../../../../modelos/HistoricoEstoqueProdutoDefensivo");
class ProducaoMudaControlePragaDoencaController extends AbstratoController_1.AbstratoController {
    constructor($scope, $injector) {
        super($scope, $injector, new ProducaoMudaControlePragaDoenca_1.ProducaoMudaControlePragaDoenca());
        this.path = '';
        this.caminho = '';
        this.tipoOperacao = null;
        this.modalCasasIntervencao = false;
        this.posicaoTopoAntesModal = null;
        this.casasVegetacao = [];
        this.pragasDoenca = [];
        this.niveisContaminacao = [];
        this.produtosDefensivos = [];
        this.contasDepositoSap = [];
        this.centrosCustoSap = [];
        this.controleIdentificacao = ProducaoMudaControlePragaDoenca_1.ProducaoMudaControlePragaDoenca.CONTROLE_IDENTIFICACAO;
        this.controleIntervencao = ProducaoMudaControlePragaDoenca_1.ProducaoMudaControlePragaDoenca.CONTROLE_INTERVENCAO;
        this.tipoOperacao = ICRUDOperacao_1.CRUDOperacao;
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.path = config_app_1.config.api.caminho;
            this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CONTROLE_PRAGA_DOENCA;
            this.modalCasasIntervencao = false;
            if (this.operacao !== this.tipoOperacao.LISTAR) {
                this.carregarListaCasaVegetacao();
            }
            if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR ||
                this.operacao === ICRUDOperacao_1.CRUDOperacao.EDITAR ||
                this.operacao === ICRUDOperacao_1.CRUDOperacao.DUPLICAR) {
                this.casasVegetacao = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.CASA_VEGETACAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CASA_VEGETACAO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.casasVegetacao = resposta;
                    }
                });
                this.pragasDoenca = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRAGA_DOENCA_MANEJO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRAGA_DOENCA_MANEJO_POMAR)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.pragasDoenca = resposta;
                    }
                });
                this.niveisContaminacao = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.NIVEL_CONTAMINACAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.NIVEL_CONTAMINACAO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.niveisContaminacao = resposta;
                    }
                });
                this.produtosDefensivos = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_DEFENSIVO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_DEFENSIVO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.produtosDefensivos = resposta;
                    }
                });
                this.contasDepositoSap = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.CONTA_DEPOSITO_SAP)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CONTA_DEPOSITO_SAP)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.contasDepositoSap = resposta;
                    }
                });
                this.centrosCustoSap = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.CENTRO_CUSTO_SAP)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CENTRO_CUSTO_SAP)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.centrosCustoSap = resposta;
                    }
                });
            }
            this.$scope.$apply();
        });
    }
    carregarListaCasaVegetacao() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.casasVegetacao = new Array();
            const promiseCasasVegetacao = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.CASA_VEGETACAO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CASA_VEGETACAO)
                .filtro({})
                .listar();
            promiseCasasVegetacao.then((resposta) => {
                if (typeof resposta !== 'undefined' && resposta) {
                    ctrl.casasVegetacao = [...resposta];
                }
                console.log('Casas de vegetacao carregadas: ' + this.casasVegetacao.length);
                ctrl.scopeApply();
            });
        });
    }
    salvar() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.modalCasasIntervencao = false;
                if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CONTROLE_PRAGA_DOENCA)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CONTROLE_PRAGA_DOENCA)
                        .criar(this.modelo);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CONTROLE_PRAGA_DOENCA)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CONTROLE_PRAGA_DOENCA)
                        .atualizar(this.modelo);
                }
                if ((typeof this.modelo.tipoControle !== 'undefined') && this.modelo.tipoControle && this.modelo.tipoControle === this.controleIntervencao &&
                    (typeof this.modelo.produto !== 'undefined') && this.modelo.produto) {
                    let produtoDefensivo = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_DEFENSIVO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_DEFENSIVO)
                        .id(this.modelo.produto.id)
                        .ver();
                    let historicoEstoqueProdutoDefensivo = new HistoricoEstoqueProdutoDefensivo_1.HistoricoEstoqueProdutoDefensivo();
                    if ((typeof produtoDefensivo !== 'undefined') && produtoDefensivo) {
                        historicoEstoqueProdutoDefensivo.produtoDefensivo = this.modelo.produto;
                        historicoEstoqueProdutoDefensivo.quantidadeAnterior = (_a = produtoDefensivo.quantidadeAtual) !== null && _a !== void 0 ? _a : 0;
                        historicoEstoqueProdutoDefensivo.quantidadeMovimento = (_b = this.modelo.quantidade) !== null && _b !== void 0 ? _b : 0;
                        historicoEstoqueProdutoDefensivo.quantidadeAtual = historicoEstoqueProdutoDefensivo.quantidadeAnterior - historicoEstoqueProdutoDefensivo.quantidadeMovimento;
                        historicoEstoqueProdutoDefensivo.movimento = HistoricoEstoqueProdutoDefensivo_1.HistoricoEstoqueProdutoDefensivo.MOVIMENTO_SAIDA;
                        historicoEstoqueProdutoDefensivo.usuario = this.usuarioLogado;
                        historicoEstoqueProdutoDefensivo.centroCustoSap = this.modelo.centroCustoSap;
                        historicoEstoqueProdutoDefensivo.contaDepositoSap = this.modelo.contaDepositoSap;
                        produtoDefensivo.quantidadeAtual = historicoEstoqueProdutoDefensivo.quantidadeAtual;
                        yield this.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.HISTORICO_ESTOQUE_PRODUTO_DEFENSIVO)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.HISTORICO_ESTOQUE_PRODUTO_DEFENSIVO)
                            .criar(historicoEstoqueProdutoDefensivo);
                        yield this.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_DEFENSIVO)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_DEFENSIVO)
                            .atualizar(produtoDefensivo);
                    }
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir um novo Controle de Praga e Doença ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir um novo Controle de Praga e Doença ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    visualizar(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const producaoMudasClonal = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CONTROLE_PRAGA_DOENCA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CONTROLE_PRAGA_DOENCA)
                    .id(id)
                    .ver();
                producaoMudasClonal.criacao = new Date(producaoMudasClonal.criacao);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return producaoMudasClonal;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    remover(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const producaoMudasClonal = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CONTROLE_PRAGA_DOENCA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CONTROLE_PRAGA_DOENCA)
                    .id(id)
                    .remover();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (producaoMudasClonal) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const producaoMudasClonal = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CONTROLE_PRAGA_DOENCA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CONTROLE_PRAGA_DOENCA)
                    .id(this.modelo.id)
                    .restaurar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (producaoMudasClonal) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosLista(filtro = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                this.path = config_app_1.config.api.caminho;
                this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CONTROLE_PRAGA_DOENCA;
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                var auxColunas = Array();
                auxColunas.push({
                    name: 'codigo',
                    displayName: 'Código',
                    width: 90,
                });
                auxColunas.push({
                    name: 'tipoControle',
                    displayName: 'Tipo (Identificação/Intervenção)',
                    width: 190,
                });
                auxColunas.push({
                    name: 'data',
                    displayName: 'Data',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 120,
                });
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
                const lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CONTROLE_PRAGA_DOENCA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CONTROLE_PRAGA_DOENCA)
                    .filtro(filtro)
                    .listar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    filtrar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    limparFiltro() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    exibirModalCasasIntervencao() {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.modelo === 'undefined' || !this.modelo) {
                return;
            }
            if (typeof this.modelo.listaCasaVegetacao === 'undefined' ||
                this.modelo.listaCasaVegetacao === null) {
                this.modelo.listaCasaVegetacao = new Array();
            }
            try {
                try {
                    this.posicaoTopoAntesModal = angular.element(window).scrollTop();
                }
                catch (ex) {
                    this.posicaoTopoAntesModal = null;
                    console.log(ex);
                }
                this.carregarListaCasaVegetacao();
                this.modalCasasIntervencao = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalCasasIntervencao() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalCasasIntervencao = false;
            angular.element('body').css('overflow-y', '');
            if (typeof this.modelo !== 'undefined' &&
                this.modelo &&
                typeof this.modelo.listaCasaVegetacao !== 'undefined' &&
                this.modelo.listaCasaVegetacao) {
                this.modelo.listaCasaVegetacao = this.modelo.listaCasaVegetacao;
            }
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    moverCasasIntervencaoListaParaCima(key) {
        if (typeof this.modelo === 'undefined' ||
            !this.modelo ||
            typeof this.modelo.listaCasaVegetacao === 'undefined' ||
            !this.modelo.listaCasaVegetacao) {
            return;
        }
        if (key !== null && key > 0 && key <= this.modelo.listaCasaVegetacao.length - 1) {
            let oldKey = key;
            let newKey = key - 1;
            this.modelo.listaCasaVegetacao.splice(newKey, 0, this.modelo.listaCasaVegetacao.splice(oldKey, 1)[0]);
            this.scopeApply();
        }
    }
    moverCasasIntervencaoListaParaBaixo(key) {
        if (typeof this.modelo === 'undefined' ||
            !this.modelo ||
            typeof this.modelo.listaCasaVegetacao === 'undefined' ||
            !this.modelo.listaCasaVegetacao) {
            return;
        }
        if (key != null && key >= 0 && key < this.modelo.listaCasaVegetacao.length - 1) {
            let oldKey = key;
            let newKey = key + 1;
            this.modelo.listaCasaVegetacao.splice(newKey, 0, this.modelo.listaCasaVegetacao.splice(oldKey, 1)[0]);
            this.scopeApply();
        }
    }
    adicionarCasasIntervencaoLista() {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.modelo === 'undefined' || !this.modelo) {
                return;
            }
            if (typeof this.modelo.listaCasaVegetacao === 'undefined' ||
                this.modelo.listaCasaVegetacao === null) {
                this.modelo.listaCasaVegetacao = new Array();
            }
            this.modelo.listaCasaVegetacao.push(new CasaVegetacao_1.CasaVegetacao());
            this.scopeApply();
        });
    }
    removerCasasIntervencaoLista(key) {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.modelo === 'undefined' || !this.modelo) {
                return;
            }
            if (typeof this.modelo.listaCasaVegetacao === 'undefined' ||
                this.modelo.listaCasaVegetacao === null) {
                this.modelo.listaCasaVegetacao = new Array();
            }
            if (typeof key !== 'undefined' &&
                key !== null &&
                this.modelo.listaCasaVegetacao.length > 0 &&
                key <= this.modelo.listaCasaVegetacao.length - 1) {
                this.modelo.listaCasaVegetacao.splice(key, 1);
                this.scopeApply();
            }
        });
    }
}
exports.ProducaoMudaControlePragaDoencaController = ProducaoMudaControlePragaDoencaController;
ProducaoMudaControlePragaDoencaController.$inject = ['$scope', '$injector'];
