"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImportaShapefile = void 0;
const Base_1 = require("./Base");
class ImportaShapefile extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.id = null;
        this.regiaoNum = null;
        this.regiao = null;
        this.talhaoNum = null;
        this.talhao = null;
        this.numeroPontosCoordenadas = null;
        this.geoJSON = null;
        this.regiaoId = null;
        this.regiaoSgifId = null;
        this.regiaoNumero = null;
        this.regiaoNome = null;
        this.talhaoId = null;
        this.talhaoSgifId = null;
        this.talhaoNumero = null;
        this.areaTalhao = null;
        this.dataPlantio = null;
        this.tipoFloresta = null;
    }
}
exports.ImportaShapefile = ImportaShapefile;
