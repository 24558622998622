"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabUmidadeSementesController = void 0;
const angular = require("angular");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const UiGridConstrutor_1 = require("../../../app/construtores/UiGridConstrutor");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const AbstratoController_1 = require("../../../app/AbstratoController");
const AmostraUmidade_1 = require("../../../../../modelos/AmostraUmidade");
const LabUmidadeSementes_1 = require("../../../../../modelos/LabUmidadeSementes");
const ICRUDOperacao_1 = require("../../../app/interfaces/ICRUDOperacao");
const config_app_1 = require("../../../../../config/config.app");
const sweetalert2_1 = require("sweetalert2");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
class LabUmidadeSementesController extends AbstratoController_1.AbstratoController {
    constructor($scope, $injector) {
        super($scope, $injector, new LabUmidadeSementes_1.LabUmidadeSementes());
        this.path = '';
        this.caminho = '';
        this.tipoOperacao = null;
        this.modalAmostrasLista = false;
        this.posicaoTopoAntesModal = null;
        this.amostrasUmidadeSemente = [];
        this.lotes = [];
        this.pomares = [];
        this.generos = [];
        this.especies = [];
        this.materiaisGeneticos = [];
        this.tipoOperacao = ICRUDOperacao_1.CRUDOperacao;
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.path = config_app_1.config.api.caminho;
            this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.LAB_UMIDADE_SEMENTES;
            this.modalAmostrasLista = false;
            if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR ||
                this.operacao === ICRUDOperacao_1.CRUDOperacao.EDITAR ||
                this.operacao === ICRUDOperacao_1.CRUDOperacao.DUPLICAR) {
                this.lotes = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.LOTE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LOTE)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.lotes = resposta;
                    }
                });
                this.pomares = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POMAR)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.pomares = resposta;
                    }
                });
                this.generos = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.GENERO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.GENERO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.generos = resposta;
                    }
                });
                this.especies = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESPECIE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESPECIE)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.especies = resposta;
                    }
                });
                this.materiaisGeneticos = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MATERIAL_GENETICO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.materiaisGeneticos = resposta;
                    }
                });
            }
            this.$scope.$apply();
        });
    }
    salvar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.modalAmostrasLista = false;
                if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_UMIDADE_SEMENTES)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_UMIDADE_SEMENTES)
                        .criar(this.modelo);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_UMIDADE_SEMENTES)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_UMIDADE_SEMENTES)
                        .atualizar(this.modelo);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir uma nova Umidade de Sementes ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir uma nova Umidade de Sementes ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    visualizar(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const labUmidadeSemente = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_UMIDADE_SEMENTES)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_UMIDADE_SEMENTES)
                    .id(id)
                    .ver();
                labUmidadeSemente.criacao = new Date(labUmidadeSemente.criacao);
                labUmidadeSemente.data = new Date(labUmidadeSemente.data);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return labUmidadeSemente;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    remover(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const labUmidadeSemente = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_UMIDADE_SEMENTES)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_UMIDADE_SEMENTES)
                    .id(id)
                    .remover();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (labUmidadeSemente) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const labUmidadeSemente = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_UMIDADE_SEMENTES)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_UMIDADE_SEMENTES)
                    .id(this.modelo.id)
                    .restaurar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (labUmidadeSemente) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosLista(filtro = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                let listaUmidadeSementes = [];
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                this.path = config_app_1.config.api.caminho;
                this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.LAB_UMIDADE_SEMENTES;
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                var auxColunas = Array();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
                const lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.LAB_UMIDADE_SEMENTES)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LAB_UMIDADE_SEMENTES)
                    .filtro(filtro)
                    .listar();
                angular.forEach(lista, (semente) => {
                    if ((typeof semente !== 'undefined') && semente) {
                        angular.forEach(semente.amostras, (amostra) => {
                            let itemSemente = {
                                id: semente.id,
                                codigo: semente.codigo,
                                criacao: semente.criacao,
                                data: semente.data,
                                especieDescricao: semente.especie.descricao,
                                generoDescricao: semente.genero.descricao,
                                loteDescricao: semente.lote.descricao,
                                materialGeneticoDescricao: semente.materialGenetico.descricao,
                                pomarDescricao: semente.pomar.descricao,
                                umidadeFinal: semente.umidadeFinal,
                                pesoPlaca: amostra.pesoPlaca,
                                pesoVerde: amostra.pesoVerde,
                                pesoSeco: amostra.pesoSeco,
                                umidade: amostra.umidade,
                            };
                            listaUmidadeSementes.push(itemSemente);
                        });
                    }
                });
                console.log(listaUmidadeSementes);
                auxColunas.push({
                    name: 'codigo',
                    displayName: 'Código',
                    width: 90,
                });
                auxColunas.push({
                    name: 'criacao',
                    displayName: 'Data/Hora Cadastro',
                    cellFilter: 'date:"dd/MM/yyyy HH:mm:ss"',
                    width: 190
                });
                auxColunas.push({
                    name: 'loteDescricao',
                    displayName: 'Lote',
                    width: 140,
                });
                auxColunas.push({
                    name: 'generoDescricao',
                    displayName: 'Gênero',
                    width: 140,
                });
                auxColunas.push({
                    name: 'especieDescricao',
                    displayName: 'Espécie',
                    width: 140,
                });
                auxColunas.push({
                    name: 'materialGeneticoDescricao',
                    displayName: 'Material Genético',
                    width: 140,
                });
                auxColunas.push({
                    name: 'pomarDescricao',
                    displayName: 'Pomar',
                    width: 140,
                });
                auxColunas.push({
                    name: 'pesoPlaca',
                    displayName: 'Peso Placa',
                    width: 140
                });
                auxColunas.push({
                    name: 'pesoVerde',
                    displayName: 'Peso Verde',
                    width: 140
                });
                auxColunas.push({
                    name: 'pesoSeco',
                    displayName: 'Peso Seco',
                    width: 140
                });
                auxColunas.push({
                    name: 'umidade',
                    displayName: 'Umidade (%)',
                    width: 140
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: listaUmidadeSementes,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    filtrar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    limparFiltro() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    exibirModalAmostrasLista() {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.modelo === 'undefined' || !this.modelo) {
                return;
            }
            if (typeof this.modelo.amostras === 'undefined' ||
                this.modelo.amostras === null) {
                this.modelo.amostras = new Array();
            }
            try {
                try {
                    this.posicaoTopoAntesModal = angular.element(window).scrollTop();
                }
                catch (ex) {
                    this.posicaoTopoAntesModal = null;
                    console.log(ex);
                }
                this.modalAmostrasLista = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalAmostrasLista() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalAmostrasLista = false;
            angular.element('body').css('overflow-y', '');
            if (typeof this.modelo !== 'undefined' &&
                this.modelo &&
                typeof this.modelo.amostras !== 'undefined' &&
                this.modelo.amostras) {
                this.modelo.amostras = this.modelo.amostras.filter((o) => typeof o.umidade !== 'undefined' && o.umidade);
            }
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    moverAmostraParaCima(key) {
        if (typeof this.modelo === 'undefined' ||
            !this.modelo ||
            typeof this.modelo.amostras === 'undefined' ||
            !this.modelo.amostras) {
            return;
        }
        if (key != null && key >= 0 && key < this.modelo.amostras.length - 1) {
            let oldKey = key;
            let newKey = key - 1;
            this.modelo.amostras.splice(newKey, 0, this.modelo.amostras.splice(oldKey, 1)[0]);
            this.scopeApply();
        }
    }
    moverAmostraParaBaixo(key) {
        if (typeof this.modelo === 'undefined' ||
            !this.modelo ||
            typeof this.modelo.amostras === 'undefined' ||
            !this.modelo.amostras) {
            return;
        }
        if (key != null && key >= 0 && key < this.modelo.amostras.length - 1) {
            let oldKey = key;
            let newKey = key + 1;
            this.modelo.amostras.splice(newKey, 0, this.modelo.amostras.splice(oldKey, 1)[0]);
            this.scopeApply();
        }
    }
    adicionarAmostra() {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.modelo === 'undefined' || !this.modelo) {
                return;
            }
            if (typeof this.modelo.amostras === 'undefined' ||
                this.modelo.amostras === null) {
                this.modelo.amostras = new Array();
            }
            this.modelo.amostras.push(new AmostraUmidade_1.AmostraUmidade());
            this.scopeApply();
        });
    }
    removerAmostra(key) {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.modelo === 'undefined' || !this.modelo) {
                return;
            }
            if (typeof this.modelo.amostras === 'undefined' ||
                this.modelo.amostras === null) {
                this.modelo.amostras = new Array();
            }
            if (typeof key !== 'undefined' &&
                key !== null &&
                this.modelo.amostras.length > 0 &&
                key <= this.modelo.amostras.length - 1) {
                this.modelo.amostras.splice(key, 1);
                this.scopeApply();
            }
        });
    }
    calcularUmidadeAmostra(key) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.modelo === 'undefined' ||
                !this.modelo ||
                typeof this.modelo.amostras === 'undefined' ||
                !this.modelo.amostras) {
                return;
            }
            let lista = this.modelo.amostras;
            let pPlaca = (_a = lista[key].pesoPlaca) !== null && _a !== void 0 ? _a : 0;
            let pVerde = (_b = lista[key].pesoVerde) !== null && _b !== void 0 ? _b : 0;
            let pSeco = (_c = lista[key].pesoSeco) !== null && _c !== void 0 ? _c : 0;
            let calcVar1 = (_d = pVerde - pSeco) !== null && _d !== void 0 ? _d : 0;
            let calcVar2 = pVerde - pPlaca !== 0 ? pVerde - pPlaca : 1;
            lista[key].umidade = parseFloat(((calcVar1 / calcVar2) * 100).toFixed(2));
            this.calcularUmidadeFinal();
        });
    }
    calcularUmidadeFinal() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.modelo === 'undefined' ||
                !this.modelo ||
                typeof this.modelo.amostras === 'undefined' ||
                !this.modelo.amostras) {
                return;
            }
            let varSoma = 0;
            let totArr = (_a = this.modelo.amostras.length) !== null && _a !== void 0 ? _a : 1;
            this.modelo.umidadeFinal = 0;
            for (let i = 0; i < totArr; i++) {
                varSoma += (_b = this.modelo.amostras[i].umidade) !== null && _b !== void 0 ? _b : 0;
            }
            this.modelo.umidadeFinal = parseFloat((varSoma / (totArr)).toFixed(2));
        });
    }
}
exports.LabUmidadeSementesController = LabUmidadeSementesController;
LabUmidadeSementesController.$inject = ['$scope', '$injector'];
