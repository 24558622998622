"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SincroniaFactory = exports.SincroniaError = exports.SincroniaMetaDados = void 0;
const angular = require("angular");
const ArmazenamentoUtil_1 = require("../../util/ArmazenamentoUtil");
const SincroniaCustomizada_1 = require("../SincroniaCustomizada");
const SincroniaConstantes_1 = require("../../constantes/SincroniaConstantes");
const ManipulaErroFactory_1 = require("./ManipulaErroFactory");
const NotificacaoFactory_1 = require("./NotificacaoFactory");
const RestFactory_1 = require("./RestFactory");
const AutenticacaoUtil_1 = require("../../util/AutenticacaoUtil");
const DispositivoUtil_1 = require("../../util/DispositivoUtil");
const RotaAPIConstantes_1 = require("../../constantes/RotaAPIConstantes");
const DataUtil_1 = require("../../util/DataUtil");
const LoginTeste_1 = require("../modulos/app/paginas/login/LoginTeste");
const sweetalert2_1 = require("sweetalert2");
class SincroniaMetaDados {
    constructor($state, etiqueta, rota, filtro) {
        this.etiqueta = etiqueta;
        this.rota = rota;
        this.filtro = filtro;
        this.nome = null;
        this.parametros = null;
        this.data = null;
        this.data = new Date();
        this.parametros = $state.current.params;
        this.nome = $state.current.name;
    }
}
exports.SincroniaMetaDados = SincroniaMetaDados;
class SincroniaError extends Error {
}
exports.SincroniaError = SincroniaError;
class OperacaoSincronia {
    constructor(rota, rest, manipulaErro, $state, notificacao) {
        this.rota = rota;
        this.rest = rest;
        this.manipulaErro = manipulaErro;
        this.$state = $state;
        this.notificacao = notificacao;
        this.identificador = null;
        this.filtroValor = null;
        this.localSincronia = null;
        this.sufixoRotaParametroNome = null;
        this.sufixoRotaParametroValor = null;
        this.sufixoRotaParametroNome = null;
        this.sufixoRotaParametroValor = null;
    }
    sufixoRota(parametroNome, parametroValor) {
        this.sufixoRotaParametroNome = parametroNome;
        this.sufixoRotaParametroValor = parametroValor;
        return this;
    }
    id(id) {
        this.identificador = id;
        return this;
    }
    filtro(filtro) {
        this.filtroValor = filtro;
        return this;
    }
    etiqueta(etiqueta) {
        this.localSincronia = etiqueta;
        return this;
    }
    listar() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.localSincronia) {
                throw new Error('Você deve informar a etiqueta.');
            }
            const ehCelular = DispositivoUtil_1.DispositivoUtil.ehCelular();
            const armazem = this.armazem();
            let sincroniaLista = ehCelular ? yield armazem.ler() : [];
            let possuiDadosGenerico = false;
            if (Array.isArray(sincroniaLista) && (sincroniaLista.length > 0)) {
                for (let i = sincroniaLista.length - 1; i >= 0; i--) {
                    if ((typeof sincroniaLista[i].chave !== 'undefined') && (sincroniaLista[i].chave === 'generico')) {
                        possuiDadosGenerico = true;
                        let itemValido = false;
                        if ((typeof sincroniaLista[i].referencia !== 'undefined') && (sincroniaLista[i].referencia !== null) &&
                            (this.filtroValor !== 'undefined') && (this.filtroValor !== null)) {
                            if ((typeof this.filtroValor.generico !== 'undefined') && (this.filtroValor.generico !== null) &&
                                (typeof this.filtroValor.generico.referencia !== 'undefined') && (this.filtroValor.generico.referencia !== null) &&
                                (sincroniaLista[i].referencia.trim().toLowerCase() === this.filtroValor.generico.referencia.trim().toLowerCase())) {
                                itemValido = true;
                            }
                            else if ((typeof this.filtroValor.referencia !== 'undefined') && (this.filtroValor.referencia !== null) &&
                                (sincroniaLista[i].referencia.trim().toLowerCase() === this.filtroValor.referencia.trim().toLowerCase())) {
                                itemValido = true;
                            }
                        }
                        if (!itemValido) {
                            sincroniaLista.splice(i, 1);
                        }
                    }
                }
            }
            if (Array.isArray(sincroniaLista) && (sincroniaLista.length > 0)) {
                sincroniaLista.sort((a, b) => {
                    let aOrdem = 0;
                    if ((typeof a === 'object') && (typeof a.syncOrdem !== 'undefined') && (a.syncOrdem !== null)) {
                        aOrdem = a.syncOrdem;
                    }
                    let bOrdem = 0;
                    if ((typeof b === 'object') && (typeof b.syncOrdem !== 'undefined') && (b.syncOrdem !== null)) {
                        bOrdem = b.syncOrdem;
                    }
                    return (aOrdem > bOrdem) ? 1 : ((bOrdem > aOrdem) ? -1 : 0);
                });
            }
            let ehSincronizacao = false;
            let permiteDadosOffline = false;
            if ((typeof this.filtroValor !== 'undefined') && (this.filtroValor !== null)) {
                if ((typeof this.filtroValor.sincronizacao !== 'undefined') && (this.filtroValor.sincronizacao !== null)) {
                    if (this.filtroValor.sincronizacao) {
                        ehSincronizacao = true;
                        if ((typeof this.filtroValor.referencia === 'undefined') || (this.filtroValor.referencia === null)) {
                            this.filtroValor.referencia = '%%%';
                        }
                    }
                }
                if ((typeof this.filtroValor.permiteDadosOffline !== 'undefined') && (this.filtroValor.permiteDadosOffline !== null)) {
                    if (this.filtroValor.permiteDadosOffline) {
                        permiteDadosOffline = true;
                    }
                }
            }
            if (ehCelular && !ehSincronizacao && permiteDadosOffline && Array.isArray(sincroniaLista)) {
                let dataHoraArmazenada = localStorage.getItem('sincronia_' + this.localSincronia);
                if ((dataHoraArmazenada !== null) && (dataHoraArmazenada.length >= 10)) {
                    try {
                        let dataHoraSincronizacao = new Date(dataHoraArmazenada);
                        let tempoLimiteSincronizacao = 72 * 60 * 60 * 1000;
                        if (sincroniaLista.length === 0) {
                            tempoLimiteSincronizacao = 15 * 60 * 1000;
                        }
                        if (Math.abs(DataUtil_1.DataUtil.dataHoraAtual().getTime() - dataHoraSincronizacao.getTime()) < tempoLimiteSincronizacao) {
                            return sincroniaLista;
                        }
                    }
                    catch (e2) {
                        console.log('ERRO: OperacaoSincronia > listar > Não foi possível converter a data da última sincronização da entidade "' + this.localSincronia + '"');
                        console.log(dataHoraArmazenada);
                        console.log(e2);
                    }
                }
            }
            try {
                let resposta = yield this.rest.get(this.rota, this.filtroValor, false);
                if ((typeof resposta !== 'undefined') && resposta) {
                    if (resposta.dados) {
                        angular.forEach(resposta.dados, (item, key) => {
                            if (typeof item === 'object') {
                                item.syncOrdem = key + 1;
                            }
                        });
                        if ((typeof this.filtroValor !== 'undefined') && (this.filtroValor !== null)) {
                            if ((typeof this.filtroValor.listaSimplificadaOnline !== 'undefined') && (this.filtroValor.listaSimplificadaOnline !== null)) {
                                if (this.filtroValor.listaSimplificadaOnline) {
                                    let lista = resposta.dados;
                                    return Array.isArray(lista) ? lista : [];
                                }
                            }
                        }
                        let lista = resposta.dados;
                        if (!possuiDadosGenerico || ehSincronizacao) {
                            if (ehCelular && Array.isArray(lista) && (lista.length > 0)) {
                                yield armazem.resetar();
                            }
                        }
                        if (!Array.isArray(lista)) {
                            lista = [];
                        }
                        if (sincroniaLista && Array.isArray(sincroniaLista) && (sincroniaLista.length > 0)) {
                            sincroniaLista.forEach(itemSincronia => {
                                let itemListaId = -1;
                                for (let i = 0; i < lista.length; i++) {
                                    if ((typeof itemSincronia.id !== 'undefined') && (itemSincronia.id !== null) &&
                                        (typeof lista[i].id !== 'undefined') && (lista[i].id !== null) &&
                                        (String(itemSincronia.id).trim().toLowerCase() === String(lista[i].id).trim().toLowerCase())) {
                                        itemListaId = i;
                                        break;
                                    }
                                    else if ((typeof itemSincronia.uuid !== 'undefined') && (itemSincronia.uuid !== null) &&
                                        (typeof lista[i].uuid !== 'undefined') && (lista[i].uuid !== null) &&
                                        (String(itemSincronia.uuid).trim().toLowerCase() === String(lista[i].uuid).trim().toLowerCase())) {
                                        itemListaId = i;
                                        break;
                                    }
                                }
                                if (itemListaId !== -1) {
                                    if (((typeof itemSincronia.syncLocal !== 'undefined') && (itemSincronia.syncLocal)) ||
                                        (typeof itemSincronia.id === 'undefined') || (itemSincronia.id === null)) {
                                        lista[itemListaId] = itemSincronia;
                                    }
                                }
                                else {
                                    if ((typeof itemSincronia.syncLocal !== 'undefined') && itemSincronia.syncLocal) {
                                        lista = [itemSincronia].concat(lista);
                                    }
                                }
                            });
                        }
                        if (ehCelular) {
                            yield armazem.salvar(lista);
                            yield this.salvarMetadados();
                        }
                        sincroniaLista = lista;
                    }
                    else {
                        this.notificacao.servidor(resposta);
                        return null;
                    }
                }
                if (sincroniaLista && Array.isArray(sincroniaLista) && (sincroniaLista.length > 0)) {
                    for (let i = sincroniaLista.length - 1; i >= 0; i--) {
                        if ((sincroniaLista[i].status !== 'undefined') && (sincroniaLista[i].status === false)) {
                            sincroniaLista.splice(i, 1);
                        }
                    }
                }
                return Array.isArray(sincroniaLista) ? sincroniaLista : [];
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            if (sincroniaLista && Array.isArray(sincroniaLista) && (sincroniaLista.length > 0)) {
                for (let i = sincroniaLista.length - 1; i >= 0; i--) {
                    if ((sincroniaLista[i].status !== 'undefined') && (sincroniaLista[i].status === false)) {
                        sincroniaLista.splice(i, 1);
                    }
                }
            }
            return Array.isArray(sincroniaLista) ? sincroniaLista : [];
        });
    }
    remover(ignorarMensagemSucesso = false) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const armazem = this.armazem();
                if (!this.identificador) {
                    throw new Error('Você deve informar o id.');
                }
                if (!this.localSincronia) {
                    throw new Error('Você deve informar a etiqueta.');
                }
                let resposta = yield this.rest.delete(`${this.rota}/${this.identificador}`, this.filtroValor, false);
                if ((typeof resposta !== 'undefined') && resposta) {
                    if (resposta.dados) {
                        let item = yield armazem.item(this.identificador);
                        if (!item) {
                            item = yield armazem.itemById(this.identificador);
                        }
                        if (item) {
                            if (item.id) {
                                item.status = false;
                                item['syncLocal'] = true;
                                yield armazem.salvar(item);
                            }
                            else {
                                yield armazem.remover(this.identificador);
                            }
                        }
                        if ((typeof ignorarMensagemSucesso === 'undefined') || !ignorarMensagemSucesso) {
                            this.notificacao.sucesso('Removido com sucesso', 'O registro foi removido da base online.');
                        }
                        return true;
                    }
                    else {
                        this.notificacao.servidor(resposta);
                        return false;
                    }
                }
                let item = yield armazem.item(this.identificador);
                if (!item) {
                    item = yield armazem.itemById(this.identificador);
                }
                if (item.id) {
                    item.status = false;
                    item['syncLocal'] = true;
                    yield armazem.salvar(item);
                }
                else {
                    yield armazem.remover(this.identificador);
                }
                if ((typeof ignorarMensagemSucesso === 'undefined') || !ignorarMensagemSucesso) {
                    this.notificacao.sucesso('Removido com sucesso', 'O registro foi removido da base offline.');
                }
                return true;
            }
            catch (ex) {
                console.log(ex);
                this.manipulaErro.exception(ex);
            }
            this.notificacao.erro('Não foi possível Remover', 'Não foi possível conectar a base de dados.');
            return false;
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!this.identificador) {
                    throw new Error('Você deve informar o id.');
                }
                if (!this.localSincronia) {
                    throw new Error('Você deve informar a etiqueta.');
                }
                const armazem = this.armazem();
                let valor = yield armazem.item(this.identificador);
                if (!valor) {
                    valor = yield armazem.itemById(this.identificador);
                }
                valor.status = true;
                let resposta = yield this.rest.put(`${this.rota}/${this.identificador}`, valor, null, false);
                if ((typeof resposta !== 'undefined') && resposta) {
                    if (resposta.dados) {
                        this.notificacao.sucesso('Registro Restaurado', 'O registro foi restaurado na base online.');
                        return true;
                    }
                    else {
                        this.notificacao.sucesso('Não foi possível Restaurar', 'Não foi possível conectar a base de dados online.');
                        return false;
                    }
                }
                valor['syncLocal'] = true;
                yield armazem.salvar(valor);
                this.notificacao.sucesso('Restaurado com sucesso', 'O registro foi restaurado na base offline.');
                return true;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            this.notificacao.erro('Não foi possível Restaurar', 'Não foi possível conectar a base de dados OFF e ON.');
            return false;
        });
    }
    ver() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!this.identificador) {
                    let usuarioLogado = yield AutenticacaoUtil_1.AutenticacaoUtil.usuario();
                    if ((typeof usuarioLogado !== 'undefined') && usuarioLogado &&
                        (typeof usuarioLogado.email !== 'undefined') && usuarioLogado.email &&
                        (usuarioLogado.email === LoginTeste_1.LoginTeste.emailPadrao)) {
                        return null;
                    }
                    throw new Error('Você deve informar o id.');
                }
                const existeOffline = this.identificador.search('-') >= 0;
                if (existeOffline && this.localSincronia) {
                    const armazem = this.armazem(this.localSincronia);
                    if (DispositivoUtil_1.DispositivoUtil.ehCelular()) {
                        let item = yield armazem.item(this.identificador);
                        if (!item) {
                            item = yield armazem.itemById(this.identificador);
                        }
                        return item;
                    }
                    else {
                        let item = yield armazem.itemById(this.identificador);
                        if ((typeof item !== 'undefined') && item) {
                            return item;
                        }
                    }
                }
                let armazem = this.armazem();
                let ehSincronizacao = false;
                let permiteDadosOffline = false;
                if ((typeof this.filtroValor !== 'undefined') && (this.filtroValor !== null)) {
                    if ((typeof this.filtroValor.sincronizacao !== 'undefined') && (this.filtroValor.sincronizacao !== null)) {
                        if (this.filtroValor.sincronizacao) {
                            ehSincronizacao = true;
                        }
                    }
                    if ((typeof this.filtroValor.permiteDadosOffline !== 'undefined') && (this.filtroValor.permiteDadosOffline !== null)) {
                        if (this.filtroValor.permiteDadosOffline) {
                            permiteDadosOffline = true;
                        }
                    }
                }
                if (DispositivoUtil_1.DispositivoUtil.ehCelular() && !ehSincronizacao && permiteDadosOffline) {
                    let dataHoraArmazenada = localStorage.getItem('sincronia_' + this.localSincronia);
                    if ((dataHoraArmazenada !== null) && (dataHoraArmazenada.length >= 10)) {
                        try {
                            let dataHoraSincronizacao = new Date(dataHoraArmazenada);
                            let tempoLimiteSincronizacao = 72 * 60 * 60 * 1000;
                            if (Math.abs(DataUtil_1.DataUtil.dataHoraAtual().getTime() - dataHoraSincronizacao.getTime()) < tempoLimiteSincronizacao) {
                                let item = yield armazem.item(this.identificador);
                                if (!item) {
                                    item = yield armazem.itemById(this.identificador);
                                }
                                if ((typeof item === 'object') && (item !== null)) {
                                    let resposta = { dados: item };
                                    return resposta.dados;
                                }
                            }
                        }
                        catch (e2) {
                            console.log('ERRO: OperacaoSincronia > ver(id: ' + this.identificador + ') ' +
                                '> Não foi possível converter a data da última sincronização da entidade "' + this.localSincronia + '"');
                            console.log(dataHoraArmazenada);
                            console.log(e2);
                        }
                    }
                }
                let resposta = yield this.rest.get(`${this.rota}/${this.identificador}`, this.filtroValor, false);
                if ((typeof resposta !== 'undefined') && resposta) {
                    if (resposta.dados) {
                        let item = yield armazem.item(this.identificador);
                        if (!item) {
                            item = yield armazem.itemById(this.identificador);
                        }
                        if ((typeof item !== 'undefined') && (item !== null) &&
                            (typeof item.chave !== 'undefined') && (item.chave !== null) &&
                            (String(item.chave).trim().length > 0)) {
                            armazem = this.armazem(item.chave);
                            yield armazem.salvar(resposta.dados);
                            yield this.salvarMetadados();
                        }
                        return resposta.dados;
                    }
                    else {
                        this.notificacao.servidor(resposta);
                        return null;
                    }
                }
                else {
                    let item = yield armazem.item(this.identificador);
                    if (!item) {
                        item = yield armazem.itemById(this.identificador);
                    }
                    resposta = { dados: item };
                }
                return resposta.dados;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            const armazem = this.armazem();
            return armazem.ler();
        });
    }
    atualizar(valor) {
        return __awaiter(this, void 0, void 0, function* () {
            const id = 'id';
            const uuid = 'uuid';
            this.identificador = valor[id] || valor[uuid];
            return this.salvar(valor, true);
        });
    }
    criar(valor) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.salvar(valor);
        });
    }
    salvar(valor, edicao = false) {
        return __awaiter(this, void 0, void 0, function* () {
            let erroServidor = false;
            try {
                if (edicao) {
                    if (!this.identificador) {
                        throw new Error('Para atualizar você precisa informar o ID use o métido id()');
                    }
                    valor['alteracao'] = new Date();
                }
                else if ((typeof valor['uuid'] !== 'string') || (valor['uuid'] === null) || (valor['uuid'].trim().length <= 0)) {
                    valor['uuid'] = ArmazenamentoUtil_1.ArmazenamentoUtil.uuid();
                }
                else if (this.rota !== RotaAPIConstantes_1.RotaAPIConstantes.FILE) {
                    valor['uuid'] = ArmazenamentoUtil_1.ArmazenamentoUtil.uuid();
                }
                const armazem = this.armazem();
                let sufixoRota = '';
                if ((this.sufixoRotaParametroNome !== null) && (this.sufixoRotaParametroNome.trim().length > 0) &&
                    (this.sufixoRotaParametroValor !== null) && (this.sufixoRotaParametroValor.trim().length > 0)) {
                    sufixoRota = '?' + this.sufixoRotaParametroNome.trim() + '=' + this.sufixoRotaParametroValor.trim();
                }
                let resposta = edicao
                    ? yield this.rest.put(`${this.rota}/${this.identificador}${sufixoRota}`, valor, null, false)
                    : yield this.rest.post(`${this.rota}${sufixoRota}`, valor, null, false);
                if ((typeof resposta !== 'undefined') && resposta) {
                    if ((typeof resposta.dados !== 'undefined') && (resposta.dados !== null)) {
                        const id = 'id';
                        if (resposta.dados[id]) {
                            yield armazem.remover(valor['id'] || valor['uuid']);
                            return resposta.dados;
                        }
                    }
                    else if ((typeof resposta.mensagens !== 'undefined') && (resposta.mensagens !== null)) {
                        this.notificacao.servidor(resposta);
                        let msgErro = null;
                        angular.forEach(resposta.mensagens, (valor) => {
                            if ((typeof valor.texto !== 'undefined') && (valor.texto !== null) && (valor.texto.trim().length > 0)) {
                                msgErro = valor.texto;
                            }
                        });
                        if ((msgErro !== null) && (msgErro.trim().length > 0)) {
                            erroServidor = true;
                            const erro = new SincroniaError(msgErro);
                            erro.data = resposta;
                            throw erro;
                        }
                        return null;
                    }
                }
                valor['syncLocal'] = true;
                yield armazem.salvar(valor);
                return valor;
            }
            catch (ex) {
                if (erroServidor) {
                    throw ex;
                }
                this.manipulaErro.exception(ex);
            }
            const armazem = this.armazem();
            yield armazem.salvar(valor);
            return valor;
        });
    }
    lerMetadados() {
        let sync = JSON.parse(window.localStorage.getItem('sync'));
        if (!Array.isArray(sync)) {
            sync = [];
        }
        return sync;
    }
    salvarMetadados() {
        return __awaiter(this, void 0, void 0, function* () {
            const meta = new SincroniaMetaDados(this.$state, this.localSincronia, this.rota, this.filtroValor);
            const metas = this.lerMetadados();
            const i = metas.findIndex(x => x.etiqueta === meta.etiqueta);
            if (i >= 0) {
                metas[i] = meta;
            }
            else {
                metas.push(meta);
            }
            const armazem = new ArmazenamentoUtil_1.LocalStorageStrategy('sync');
            yield armazem.salvar(metas);
        });
    }
    armazem(etiqueta = null) {
        const chave = etiqueta ? etiqueta : this.localSincronia;
        return ArmazenamentoUtil_1.ArmazenamentoUtil.em(chave);
    }
}
class SincroniaFactory {
    constructor($state, rest, manipulaErro, notificacao) {
        this.$state = $state;
        this.rest = rest;
        this.manipulaErro = manipulaErro;
        this.notificacao = notificacao;
        this.sincronizando = false;
        this.erroSincronizacao = false;
        this.timeoutValidacao = null;
        this.timeout = null;
        this.sincroniaCustomizada = null;
        this.estaOnline = false;
        this.estaOffline = false;
        this.sincronizando = false;
        this.erroSincronizacao = false;
        this.estaOnline = false;
        this.estaOffline = false;
        this.sincroniaCustomizada = new SincroniaCustomizada_1.SincroniaCustomizada(this);
        globalThis.sincronizando = false;
        document.addEventListener('online', () => { this.estaOnline = true; this.onOnline(); }, false);
        document.addEventListener('offline', () => { this.estaOffline = false; this.onOffline(); }, false);
        document.addEventListener('deviceready', () => { this.onDeviceReady(); }, false);
        this.onDeviceReady();
    }
    rota(rota) {
        if (!rota) {
            throw new Error('Você deve informar a rota.');
        }
        return new OperacaoSincronia(rota, this.rest, this.manipulaErro, this.$state, this.notificacao);
    }
    onOnline() {
        if ((typeof this.timeoutValidacao !== 'undefined') && (this.timeoutValidacao !== null)) {
            clearTimeout(this.timeoutValidacao);
            this.timeoutValidacao = null;
        }
        this.timeoutValidacao = setTimeout(() => __awaiter(this, void 0, void 0, function* () {
            const ehAutenticado = yield AutenticacaoUtil_1.AutenticacaoUtil.ehAutenticado();
            if (ehAutenticado) {
                angular.element('.user-status').html('<div class="online">online<div>');
                if (DispositivoUtil_1.DispositivoUtil.ehCelular()) {
                    angular.element('.app-status-sincronizacao-msg').html('Carregando...');
                    angular.element('.app-status-sincronizacao-msg-adicional').empty();
                    angular.element('.app-status-sincronizacao').show();
                }
                this.timeout = setTimeout(this.sincronizar, 1000, this);
            }
            else {
                this.onOnline();
            }
        }), 3000);
    }
    onOffline() {
        angular.element('.user-status').html('<div class="offline">offline<div>');
        angular.element('.app-status-sincronizacao').hide();
        angular.element('.app-status-sincronizacao-msg').empty();
        angular.element('.app-status-sincronizacao-msg-adicional').empty();
        if ((typeof this.timeoutValidacao !== 'undefined') && (this.timeoutValidacao !== null)) {
            clearTimeout(this.timeoutValidacao);
            this.timeoutValidacao = null;
        }
        if ((typeof this.timeout !== 'undefined') && (this.timeout !== null)) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
    }
    onDeviceReady() {
        if (!this.estaOnline && !this.estaOffline) {
            this.onOnline();
        }
        else {
            if ((typeof globalThis.dataHoraUltimaSincronizacao === 'undefined') || (globalThis.dataHoraUltimaSincronizacao === null)) {
                globalThis.dataHoraUltimaSincronizacao = new Date();
                this.onOnline();
            }
            else {
                let dataHoraUltimaSincronizacao = new Date(globalThis.dataHoraUltimaSincronizacao);
                let dataHoraAtual = new Date();
                let tempoLimiteSincronizacao = 15 * 60 * 1000;
                if (Math.abs(dataHoraAtual.getTime() - dataHoraUltimaSincronizacao.getTime()) > tempoLimiteSincronizacao) {
                    if (this.estaOnline) {
                        this.onOnline();
                    }
                }
            }
        }
    }
    sincronizar(factory, sincroniaCompleta) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.sincronizando) {
                return;
            }
            if (!DispositivoUtil_1.DispositivoUtil.ehCelular()) {
                setTimeout(function () {
                    return __awaiter(this, void 0, void 0, function* () {
                        angular.element('.app-status-sincronizacao').fadeOut(800, function () {
                            angular.element('.app-status-sincronizacao').hide();
                            angular.element('.app-status-sincronizacao-msg').empty();
                            angular.element('.app-status-sincronizacao-msg-adicional').empty();
                        });
                    });
                }, 1000);
                return;
            }
            this.sincronizando = true;
            globalThis.sincronizando = true;
            try {
                this.erroSincronizacao = false;
                let numTotalItensASincronizar = factory.sincroniaCustomizada.itensParaEnviarPrimeiro.length +
                    factory.sincroniaCustomizada.itensParaBaixar.length +
                    factory.sincroniaCustomizada.itensParaEnviarPorUltimo.length;
                let numItensSincronizados = 0;
                angular.element('.app-status-sincronizacao-msg').html('Sincronizando...');
                angular.element('.app-status-sincronizacao-msg-adicional').empty();
                globalThis.dataHoraUltimaSincronizacao = new Date();
                if (!this.erroSincronizacao) {
                    for (let i = 0; i < factory.sincroniaCustomizada.itensParaEnviarPrimeiro.length; i++) {
                        angular.element('.app-status-sincronizacao-msg').html('Sincronizando...  ( ' +
                            numItensSincronizados.toString() + ' de ' + numTotalItensASincronizar.toString() + ' módulos restantes )');
                        angular.element('.app-status-sincronizacao-msg-adicional').empty();
                        yield factory.enviar(factory.sincroniaCustomizada.itensParaEnviarPrimeiro[i]);
                        numItensSincronizados++;
                        if (this.erroSincronizacao) {
                            break;
                        }
                    }
                }
                if (!this.erroSincronizacao) {
                    for (let i = 0; i < factory.sincroniaCustomizada.itensParaBaixar.length; i++) {
                        angular.element('.app-status-sincronizacao-msg').html('Sincronizando...  ( ' +
                            numItensSincronizados.toString() + ' de ' + numTotalItensASincronizar.toString() + ' módulos restantes )');
                        angular.element('.app-status-sincronizacao-msg-adicional').empty();
                        yield factory.baixar(factory.sincroniaCustomizada.itensParaBaixar[i], sincroniaCompleta);
                        numItensSincronizados++;
                        if (this.erroSincronizacao) {
                            break;
                        }
                    }
                }
                if (!this.erroSincronizacao) {
                    for (let i = 0; i < factory.sincroniaCustomizada.itensParaEnviarPorUltimo.length; i++) {
                        angular.element('.app-status-sincronizacao-msg').html('Sincronizando...  ( ' +
                            numItensSincronizados.toString() + ' de ' + numTotalItensASincronizar.toString() + ' módulos restantes )');
                        angular.element('.app-status-sincronizacao-msg-adicional').empty();
                        yield factory.enviar(factory.sincroniaCustomizada.itensParaEnviarPorUltimo[i]);
                        numItensSincronizados++;
                        if (this.erroSincronizacao) {
                            break;
                        }
                    }
                }
                globalThis.dataHoraUltimaSincronizacao = new Date();
                if (this.erroSincronizacao) {
                    angular.element('.app-status-sincronizacao-msg').html('Sincronização não concluída! Sem conexão.');
                }
                else {
                    angular.element('.app-status-sincronizacao-msg').html('Sincronização concluída!');
                }
                angular.element('.app-status-sincronizacao-msg-adicional').empty();
                setTimeout(function () {
                    return __awaiter(this, void 0, void 0, function* () {
                        angular.element('.app-status-sincronizacao').fadeOut(800, function () {
                            angular.element('.app-status-sincronizacao').hide();
                            angular.element('.app-status-sincronizacao-msg').empty();
                            angular.element('.app-status-sincronizacao-msg-adicional').empty();
                        });
                    });
                }, 2000);
            }
            catch (e) {
                this.erroSincronizacao = true;
                this.sincronizando = false;
                globalThis.sincronizando = false;
                console.log(e);
            }
            finally {
                this.sincronizando = false;
                globalThis.sincronizando = false;
            }
        });
    }
    enviar(itemAEnviar) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const armazem = ArmazenamentoUtil_1.ArmazenamentoUtil.em(itemAEnviar.etiqueta);
                const listaIds = yield armazem.lerUUIDs();
                const armazemMetadados = new ArmazenamentoUtil_1.LocalStorageStrategy('sync');
                let sync = yield armazemMetadados.info(itemAEnviar.etiqueta);
                if (sync) {
                    if ((typeof sync.etiqueta === 'undefined') || !sync.etiqueta) {
                        sync.etiqueta = itemAEnviar.etiqueta;
                    }
                    if ((typeof sync.rota === 'undefined') || !sync.rota) {
                        sync.rota = itemAEnviar.rota;
                    }
                }
                else {
                    sync = new SincroniaMetaDados(this.$state, itemAEnviar.etiqueta, itemAEnviar.rota, {});
                }
                if (Array.isArray(listaIds)) {
                    console.log('SincroniaFactory > enviar ({ ' +
                        'etiqueta: "' + itemAEnviar.etiqueta + '"; ' +
                        'rota: "' + itemAEnviar.rota + '" }) ' +
                        '> listaIds.length = ' + listaIds.length);
                    for (let i = 0; i < listaIds.length; i++) {
                        let base = yield armazem.item(listaIds[i]);
                        if ((typeof base === 'undefined') || (base === null)) {
                            base = yield armazem.itemById(listaIds[i]);
                        }
                        if ((typeof base !== 'undefined') && (base !== null)) {
                            if ((typeof base.syncLocal !== 'undefined') && base.syncLocal) {
                                let resposta = null;
                                if (base.id) {
                                    resposta = yield this.rest.put(`${sync.rota}/${base.id}`, base, null, false);
                                }
                                else {
                                    resposta = yield this.rest.post(sync.rota, base, null, false);
                                }
                                if ((typeof resposta !== 'undefined') && (resposta !== null) &&
                                    (typeof resposta.dados !== 'undefined') && (resposta.dados !== null) &&
                                    (typeof resposta.dados.id !== 'undefined') && (resposta.dados.id !== null)) {
                                    base.syncLocal = false;
                                    base.id = resposta.dados.id;
                                    armazem.salvar(base);
                                    if ((base.id || base.uuid) && (itemAEnviar.etiqueta === SincroniaConstantes_1.SincroniaConstantes.FILE)) {
                                        if ((typeof base.id !== 'undefined') && base.id) {
                                            armazem.remover(base.id);
                                        }
                                        if ((typeof base.uuid !== 'undefined') && base.uuid) {
                                            armazem.remover(base.uuid);
                                        }
                                    }
                                    else {
                                        if ((typeof resposta.dados.uuid !== 'undefined') && (resposta.dados.uuid !== null) && (resposta.dados.uuid.trim().length > 0)) {
                                            base.uuid = resposta.dados.uuid;
                                        }
                                        else {
                                            base.uuid = resposta.dados.id;
                                        }
                                        armazem.salvar(base);
                                    }
                                }
                            }
                            else {
                                if ((base.id || base.uuid) && (itemAEnviar.etiqueta === SincroniaConstantes_1.SincroniaConstantes.FILE)) {
                                    if ((typeof base.id !== 'undefined') && base.id) {
                                        armazem.remover(base.id);
                                    }
                                    if ((typeof base.uuid !== 'undefined') && base.uuid) {
                                        armazem.remover(base.uuid);
                                    }
                                }
                            }
                        }
                        base = undefined;
                    }
                }
            }
            catch (ex) {
                console.log('ERRO: SincroniaFactory > enviar ');
                console.log(itemAEnviar);
                console.log(ex);
                if ((typeof ex !== 'undefined') && (ex !== null)) {
                    if ((typeof ex.message !== 'undefined') && (ex.message !== null)) {
                        let msg = ex.message;
                        if (msg.indexOf('IDBDatabase') !== -1) {
                            sweetalert2_1.default.fire({
                                title: '',
                                text: 'Erro ao executar a sincronização. \n' + msg + ' \n' +
                                    'Rota: ' + itemAEnviar.rota + ' - Etiqueta: ' + itemAEnviar.etiqueta,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                    }
                }
            }
        });
    }
    baixar(itemABaixar, sincroniaCompleta) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                let executar = true;
                if (!sincroniaCompleta) {
                    let dataHoraArmazenada = localStorage.getItem('sincronia_' + itemABaixar.etiqueta);
                    if ((dataHoraArmazenada !== null) && (dataHoraArmazenada.length >= 10)) {
                        try {
                            let dataHoraSincronizacao = new Date(dataHoraArmazenada);
                            let tempoLimiteSincronizacao = 48 * 60 * 60 * 1000;
                            if (Math.abs(DataUtil_1.DataUtil.dataHoraAtual().getTime() - dataHoraSincronizacao.getTime()) < tempoLimiteSincronizacao) {
                                executar = false;
                            }
                        }
                        catch (e2) {
                            console.log('ERRO: SincroniaFactory > baixar > Não foi possível converter a data da última sincronização da entidade "' + itemABaixar.etiqueta + '"');
                            console.log(dataHoraArmazenada);
                            console.log(e2);
                        }
                    }
                }
                if (executar) {
                    let lista = yield this.rota(itemABaixar.rota)
                        .etiqueta(itemABaixar.etiqueta)
                        .filtro({ sincronizacao: true })
                        .listar();
                    if ((lista !== null) && angular.isArray(lista)) {
                        localStorage.setItem('sincronia_' + itemABaixar.etiqueta, DataUtil_1.DataUtil.dataHoraAtual().toISOString());
                    }
                }
            }
            catch (ex) {
                this.erroSincronizacao = true;
                console.log('ERRO: SincroniaFactory > baixar ');
                console.log(itemABaixar);
                console.log(ex);
                if ((typeof ex !== 'undefined') && (ex !== null)) {
                    if ((typeof ex.message !== 'undefined') && (ex.message !== null)) {
                        let msg = ex.message;
                        if (msg.indexOf('IDBDatabase') !== -1) {
                            sweetalert2_1.default.fire({
                                title: '',
                                text: 'Erro ao executar a sincronização. \n' + msg + ' \n' +
                                    'Rota: ' + itemABaixar.rota + ' - Etiqueta: ' + itemABaixar.etiqueta,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                    }
                }
            }
        });
    }
}
exports.SincroniaFactory = SincroniaFactory;
SincroniaFactory.id = SincroniaFactory.name;
SincroniaFactory.$inject = ['$state', RestFactory_1.RestFactory.id, ManipulaErroFactory_1.ManipulaErroFactory.id, NotificacaoFactory_1.NotificacaoFactory.id];
