"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProducaoMudaClonalMinijardimCroqui = void 0;
const Base_1 = require("./Base");
class ProducaoMudaClonalMinijardimCroqui extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.numCanaletao = null;
        this.numLinhas = null;
        this.minijardimEucalyptus = null;
        this.minijardimPinus = null;
    }
}
exports.ProducaoMudaClonalMinijardimCroqui = ProducaoMudaClonalMinijardimCroqui;
