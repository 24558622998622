"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RotaFactory = void 0;
const AutenticacaoUtil_1 = require("../../util/AutenticacaoUtil");
const ICRUDOperacao_1 = require("../modulos/app/interfaces/ICRUDOperacao");
class RotaFactory {
    constructor($stateProvider) {
        this.$stateProvider = $stateProvider;
        this.moduloEscolhido = null;
    }
    modulo(modulo) {
        this.moduloEscolhido = modulo;
        const auth = {
            name: modulo.id,
            abstract: true,
            resolve: {
                loginRequired: AutenticacaoUtil_1.AutenticacaoUtil.checaLogin
            }
        };
        this.$stateProvider.state(auth);
        return this;
    }
    addRota(opcoes, url = null) {
        this.add(null, opcoes, url);
        return this;
    }
    addCriar(opcoes, url = null) {
        const operacao = ICRUDOperacao_1.CRUDOperacao.CRIAR;
        this.add(operacao, opcoes, url);
        return this;
    }
    addEditar(opcoes, url = null) {
        const operacao = ICRUDOperacao_1.CRUDOperacao.EDITAR;
        this.add(operacao, opcoes, url);
        return this;
    }
    addDuplicar(opcoes, url = null) {
        const operacao = ICRUDOperacao_1.CRUDOperacao.DUPLICAR;
        this.add(operacao, opcoes, url);
        return this;
    }
    addVisualizar(opcoes, url = null) {
        const operacao = ICRUDOperacao_1.CRUDOperacao.VISUALIZAR;
        this.add(operacao, opcoes, url);
        return this;
    }
    addFormulario(opcoes, url = null) {
        const operacao = ICRUDOperacao_1.CRUDOperacao.FORMULARIO;
        this.add(operacao, opcoes, url);
        return this;
    }
    addImprimir(opcoes, url = null) {
        const operacao = ICRUDOperacao_1.CRUDOperacao.IMPRIMIR;
        this.add(operacao, opcoes, url);
        return this;
    }
    addListar(opcoes, url = null) {
        const operacao = ICRUDOperacao_1.CRUDOperacao.LISTAR;
        this.add(operacao, opcoes, url);
        return this;
    }
    add(operacao, opcoes, url) {
        if (!this.moduloEscolhido) {
            throw new Error('Erro ao não informar o MÓDULO ao adicionar a rota.');
        }
        if (!opcoes.nome) {
            throw new Error('Erro ao não informar o NOME ao adicionar a rota.');
        }
        if (!opcoes.controladora) {
            throw new Error('Erro ao não informar a controladora ao adicionar a rota.');
        }
        let nome = opcoes.nome;
        if (operacao) {
            nome = `${opcoes.nome}-${operacao}`;
        }
        nome = opcoes.autenticar ? `${this.moduloEscolhido.id}.${nome}` : nome;
        if (!url) {
            url = `/${opcoes.nome}`;
            if (operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                url = `/${opcoes.nome}/cadastrar`;
            }
            else if (operacao === ICRUDOperacao_1.CRUDOperacao.FORMULARIO) {
                url = `/${opcoes.nome}/formulario/{id}`;
            }
            if (operacao === ICRUDOperacao_1.CRUDOperacao.EDITAR) {
                url = `/${opcoes.nome}/editar/{id}`;
            }
            else if (operacao === ICRUDOperacao_1.CRUDOperacao.VISUALIZAR) {
                url = `/${opcoes.nome}/visualizar/{id}`;
            }
            else if (operacao === ICRUDOperacao_1.CRUDOperacao.IMPRIMIR) {
                url = `/${opcoes.nome}/imprimir/{id}`;
            }
            else if (operacao === ICRUDOperacao_1.CRUDOperacao.DUPLICAR) {
                url = `/${opcoes.nome}/duplicar/{id}`;
            }
        }
        if ((operacao === ICRUDOperacao_1.CRUDOperacao.LISTAR) || (operacao === ICRUDOperacao_1.CRUDOperacao.FORMULARIO)) {
            if ((typeof opcoes.template === 'undefined') || (opcoes.template === null)) {
                opcoes.template = require('../modulos/app/paginas/lista.html');
            }
            else if ((opcoes.nome !== 'sgifFuncionario') &&
                (opcoes.nome !== 'importaTalhao') &&
                (opcoes.nome !== 'talhao') &&
                (opcoes.nome !== 'lote') &&
                (opcoes.nome !== 'apontamento') &&
                (opcoes.nome !== 'sincronizacao') &&
                (opcoes.nome !== 'materialGenetico') &&
                (opcoes.nome !== 'pomar') &&
                (opcoes.nome !== 'pomarBancoConservacao') &&
                (opcoes.nome !== 'labGerminacaoSementes') &&
                (opcoes.nome !== 'labMaturacaoCones') &&
                (opcoes.nome !== 'producaoMudasSeminal') &&
                (opcoes.nome !== 'producaoMudasExperimentos') &&
                (opcoes.nome !== 'producaoMudaClonalEucalyptusResgateMacroestacas') &&
                (opcoes.nome !== 'producaoMudaClonalEucalyptusMinijardim') &&
                (opcoes.nome !== 'producaoMudaClonalEucalyptusMiniestaquia') &&
                (opcoes.nome !== 'producaoMudaClonalPinusMinijardim') &&
                (opcoes.nome !== 'producaoMudaClonalPinusMiniestaquia') &&
                (opcoes.nome !== 'protecaoRegistroCultivar') &&
                (opcoes.nome !== 'testeCampo') &&
                (opcoes.nome !== 'cloneMaterialGenetico') &&
                (opcoes.nome !== 'dashboard')) {
                opcoes.template = require('../modulos/app/paginas/lista.html');
            }
        }
        else {
            if (opcoes.template === require('../modulos/app/paginas/lista.html') || !opcoes.template) {
                let rotaFilha = opcoes.nome.split('-');
                let paginaLocalizacao = 'paginas/' + opcoes.nome + '/' + opcoes.nome + '.html';
                if (rotaFilha.length > 1) {
                    paginaLocalizacao = 'paginas/' + rotaFilha.join('/') + '.html';
                }
                const modulo = this.moduloEscolhido.id;
                opcoes.template = require(`../modulos/${modulo}/${paginaLocalizacao}`);
            }
        }
        const params = Object.assign({
            operacao: operacao,
            modulo: this.moduloEscolhido.name,
            titulo: opcoes.titulo,
            descricao: opcoes.descricao,
        }, opcoes.params);
        const rota = {
            name: nome,
            url: url,
            templateUrl: opcoes.template.default,
            controller: opcoes.controladora,
            controllerAs: '$ctrl',
            params: params
        };
        this.$stateProvider.state(rota);
        return this;
    }
}
exports.RotaFactory = RotaFactory;
RotaFactory.id = RotaFactory.name;
