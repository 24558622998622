"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PomarBancoConservacaoControleManejoUtil = void 0;
const angular = require("angular");
const FiltroControleManejoPomar_1 = require("../../../../../io/FiltroControleManejoPomar");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const OperacaoManejoPomar_1 = require("../../../../../modelos/OperacaoManejoPomar");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const ControleManejoPomar_1 = require("../../../../../modelos/ControleManejoPomar");
const OperacaoControleDoencaPomar_1 = require("../../../../../modelos/OperacaoControleDoencaPomar");
const HistoricoEstoqueProdutoDefensivo_1 = require("../../../../../modelos/HistoricoEstoqueProdutoDefensivo");
const OperacaoLimpezaPomar_1 = require("../../../../../modelos/OperacaoLimpezaPomar");
const HistoricoEstoqueProdutoFertilizante_1 = require("../../../../../modelos/HistoricoEstoqueProdutoFertilizante");
const OperacaoAdubacaoFertilizacaoPomar_1 = require("../../../../../modelos/OperacaoAdubacaoFertilizacaoPomar");
const OperacaoDesbastePomar_1 = require("../../../../../modelos/OperacaoDesbastePomar");
const OperacaoPolinizacaoPomar_1 = require("../../../../../modelos/OperacaoPolinizacaoPomar");
const OperacaoColetaAmostraPomar_1 = require("../../../../../modelos/OperacaoColetaAmostraPomar");
const OperacaoColetaSementePomar_1 = require("../../../../../modelos/OperacaoColetaSementePomar");
const OutraOperacaoPomar_1 = require("../../../../../modelos/OutraOperacaoPomar");
const LinhaColunaPomar_1 = require("../../../../../modelos/LinhaColunaPomar");
const DispositivoUtil_1 = require("../../../../../util/DispositivoUtil");
const ArmazenamentoUtil_1 = require("../../../../../util/ArmazenamentoUtil");
const sweetalert2_1 = require("sweetalert2");
class PomarBancoConservacaoControleManejoUtil {
    static visualizacaoResumidaControleManejoPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.filtroControleManejoPomar === 'undefined') || !ctrl.filtroControleManejoPomar) {
                ctrl.filtroControleManejoPomar = new FiltroControleManejoPomar_1.FiltroControleManejoPomar();
            }
            if (ctrl.filtroControleManejoPomar.visualizacaoDetalhada) {
                ctrl.filtroControleManejoPomar.visualizacaoDetalhada = false;
            }
            if (!ctrl.filtroControleManejoPomar.visualizacaoResumida) {
                ctrl.filtroControleManejoPomar.visualizacaoResumida = true;
            }
        });
    }
    static visualizacaoDetalhadaControleManejoPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.filtroControleManejoPomar === 'undefined') || !ctrl.filtroControleManejoPomar) {
                ctrl.filtroControleManejoPomar = new FiltroControleManejoPomar_1.FiltroControleManejoPomar();
            }
            if (ctrl.filtroControleManejoPomar.visualizacaoResumida) {
                ctrl.filtroControleManejoPomar.visualizacaoResumida = false;
            }
            if (!ctrl.filtroControleManejoPomar.visualizacaoDetalhada) {
                ctrl.filtroControleManejoPomar.visualizacaoDetalhada = true;
            }
        });
    }
    static filtrarControleManejoPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.pomarSelecionado || !ctrl.pomarSelecionado.id) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const listaOpcoesManejoPomar = yield ctrl.dadosListaManejoPomar();
                ctrl.listaOpcoesManejoPomarResumido = listaOpcoesManejoPomar.resumido;
                ctrl.listaOpcoesManejoPomarDetalhado = listaOpcoesManejoPomar.detalhado;
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.scopeApply();
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static salvarControleManejoPomar(ctrl) {
        var _a, _b, _c, _d, _e, _f;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.controleManejoPomarSelecionado || ctrl.controleManejoPomarVisualizar) {
                    return;
                }
                let listaCamposObrigatorios = '';
                if ((typeof ctrl.controleManejoPomarSelecionado.operacao === 'undefined') || !ctrl.controleManejoPomarSelecionado.operacao) {
                    listaCamposObrigatorios += '\r\n - <b>Operação Manejo Pomar</b>';
                }
                if (listaCamposObrigatorios && (listaCamposObrigatorios.length > 0)) {
                    let mensagem = 'Campo obrigatório não preenchido!' +
                        listaCamposObrigatorios + '\r\n \r\n' +
                        'Preencha este campo e tente novamente.';
                    if (ctrl.ehCelular) {
                        alert(mensagem.replace(/<b>/g, '').replace(/<\/b>/g, ''));
                    }
                    else {
                        yield sweetalert2_1.default.fire({
                            html: mensagem.replace(/\r\n/g, '<br>\r\n'),
                            icon: 'info',
                            showCancelButton: false,
                            showConfirmButton: true,
                            confirmButtonText: 'Ok'
                        });
                    }
                    return;
                }
                const operacaoManejoPomar = ((typeof ctrl.controleManejoPomarSelecionado.operacao !== 'undefined') && ctrl.controleManejoPomarSelecionado.operacao) ? ctrl.controleManejoPomarSelecionado.operacao : null;
                const ehOperacaoLimpeza = (operacaoManejoPomar && (typeof operacaoManejoPomar.ehOperacaoLimpeza !== 'undefined') && operacaoManejoPomar.ehOperacaoLimpeza) ? true : false;
                const ehOperacaoDesbaste = (operacaoManejoPomar && (typeof operacaoManejoPomar.ehOperacaoDesbaste !== 'undefined') && operacaoManejoPomar.ehOperacaoDesbaste) ? true : false;
                const ehOperacaoPolinizacao = (operacaoManejoPomar && (typeof operacaoManejoPomar.ehOperacaoPolinizacao !== 'undefined') && operacaoManejoPomar.ehOperacaoPolinizacao) ? true : false;
                const ehOperacaoColetaAmostras = (operacaoManejoPomar && (typeof operacaoManejoPomar.ehOperacaoColetaAmostras !== 'undefined') && operacaoManejoPomar.ehOperacaoColetaAmostras) ? true : false;
                const ehOperacaoAdubacaoFertilizacao = (operacaoManejoPomar && (typeof operacaoManejoPomar.ehOperacaoAdubacaoFertilizacao !== 'undefined') && operacaoManejoPomar.ehOperacaoAdubacaoFertilizacao) ? true : false;
                const ehOperacaoColetaSementes = (operacaoManejoPomar && (typeof operacaoManejoPomar.ehOperacaoColetaSementes !== 'undefined') && operacaoManejoPomar.ehOperacaoColetaSementes) ? true : false;
                const ehOperacaoControleDoencas = (operacaoManejoPomar && (typeof operacaoManejoPomar.ehOperacaoControleDoencas !== 'undefined') && operacaoManejoPomar.ehOperacaoControleDoencas) ? true : false;
                const ehOperacaoPodaRamosPortaEnxerto = (ehOperacaoControleDoencas && (typeof operacaoManejoPomar.ehOperacaoPodaRamosPortaEnxerto !== 'undefined') && operacaoManejoPomar.ehOperacaoPodaRamosPortaEnxerto) ? true : false;
                const ehOutrasOperacoes = (operacaoManejoPomar && (typeof operacaoManejoPomar.ehOutrasOperacoes !== 'undefined') && operacaoManejoPomar.ehOutrasOperacoes) ? true : false;
                const ehOperacaoAvaliacaoSobrevivencia = (operacaoManejoPomar && (typeof operacaoManejoPomar.ehOperacaoAvaliacaoSobrevivencia !== 'undefined') && operacaoManejoPomar.ehOperacaoAvaliacaoSobrevivencia) ? true : false;
                ctrl.modalTratamentoPomar = false;
                ctrl.modalGerarCroquiPomar = false;
                ctrl.modalHistoricoCroquiPomar = false;
                ctrl.modalPlanejamentoPomar = false;
                ctrl.modalControleEnxertiaPomar = false;
                ctrl.modalProdutividadePomar = false;
                ctrl.modalFenologiaPomar = false;
                ctrl.modalPolinizacaoPomar = false;
                ctrl.modalRelatorioPomar = false;
                ctrl.modalAnexoPomar = false;
                ctrl.controleManejoPomarSelecionado.pomar = ctrl.pomarSelecionado;
                if (!ehOperacaoLimpeza) {
                    ctrl.controleManejoPomarSelecionado.limpezas = [];
                }
                if (!ehOperacaoDesbaste) {
                    ctrl.controleManejoPomarSelecionado.desbastes = [];
                }
                if (!ehOperacaoPolinizacao) {
                    ctrl.controleManejoPomarSelecionado.polinizacoes = [];
                }
                if (!ehOperacaoColetaAmostras) {
                    ctrl.controleManejoPomarSelecionado.coletasAmostras = [];
                }
                if (!ehOperacaoColetaSementes) {
                    ctrl.controleManejoPomarSelecionado.coletasSementes = [];
                }
                if (!ehOperacaoAdubacaoFertilizacao) {
                    ctrl.controleManejoPomarSelecionado.adubacoesFertilizacoes = [];
                }
                if (!ehOperacaoControleDoencas) {
                    ctrl.controleManejoPomarSelecionado.doencas = [];
                }
                if (!ehOperacaoPodaRamosPortaEnxerto) {
                    ctrl.controleManejoPomarSelecionado.outrasOperacoes = [];
                }
                if (!ehOutrasOperacoes) {
                    ctrl.controleManejoPomarSelecionado.outrasOperacoes = [];
                }
                if (!ehOperacaoAvaliacaoSobrevivencia) {
                    ctrl.controleManejoPomarSelecionado.avaliacoesSobrevivencia = [];
                }
                if (ehOperacaoPolinizacao) {
                    if ((typeof ctrl.controleManejoPomarSelecionado.pomarCloneFeminino === 'undefined') || !ctrl.controleManejoPomarSelecionado.pomarCloneFeminino) {
                        ctrl.controleManejoPomarSelecionado.pomarCloneFeminino = ctrl.pomarSelecionado;
                    }
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                if (!ctrl.controleManejoPomarSelecionado.id) {
                    ctrl.controleManejoPomarSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.CONTROLE_MANEJO_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CONTROLE_MANEJO_POMAR)
                        .criar(ctrl.controleManejoPomarSelecionado);
                    if (ehOperacaoAvaliacaoSobrevivencia) {
                        let itemNotificacao = {
                            id: null,
                            uuid: null,
                            dataInicio: ctrl.controleManejoPomarSelecionado.data,
                            usuario: ctrl.usuarioSimplificado(),
                            lido: false,
                            uuidLancamentoOrigem: ctrl.controleManejoPomarSelecionado.uuid || ArmazenamentoUtil_1.ArmazenamentoUtil.uuid(),
                            titulo: 'Lembrete: Enxertia Ano 1 de Avaliação Sobrevivência',
                            descricao: 'Já se passou 12 meses desde a data estabelecida.',
                            listaUsuarios: []
                        };
                        angular.forEach(ctrl.usuarios, (usuario) => {
                            if ((typeof usuario !== 'undefined') && usuario) {
                                let obj = { id: null, uuid: null, status: null, email: null };
                                const temUsuario = (typeof usuario !== 'undefined') && usuario ? true : false;
                                obj.id = ((temUsuario && (typeof usuario.id !== 'undefined') && usuario.id) ? usuario.id : null);
                                obj.uuid = ((temUsuario && (typeof usuario.uuid !== 'undefined') && usuario.uuid) ? usuario.uuid : null);
                                obj.email = ((temUsuario && (typeof usuario.email !== 'undefined') && usuario.email) ? usuario.email : null);
                                obj.nomeCompleto = ((temUsuario && (typeof usuario.nomeCompleto !== 'undefined') && usuario.nomeCompleto) ? usuario.nomeCompleto :
                                    ((temUsuario && (typeof usuario.nome !== 'undefined') && usuario.nome) ? usuario.nome : null));
                                let itemUsuario = {
                                    id: null,
                                    uuid: null,
                                    usuario: obj,
                                    lido: false,
                                    status: true,
                                    visivel: true
                                };
                                let itemVerifica = itemNotificacao.listaUsuarios.find(o => ((o.usuario.id === usuario.id)));
                                if ((typeof itemVerifica === 'undefined') || !itemVerifica) {
                                    itemNotificacao.listaUsuarios.push(itemUsuario);
                                }
                            }
                        });
                        yield ctrl.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.NOTIFICACAO)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.NOTIFICACAO)
                            .criar(itemNotificacao);
                    }
                }
                else {
                    ctrl.controleManejoPomarSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.CONTROLE_MANEJO_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CONTROLE_MANEJO_POMAR)
                        .atualizar(ctrl.controleManejoPomarSelecionado);
                    if (ehOperacaoAvaliacaoSobrevivencia) {
                        let itemNotificacao = yield ctrl.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.NOTIFICACAO)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.NOTIFICACAO)
                            .id(ctrl.controleManejoPomarSelecionado.uuid || ArmazenamentoUtil_1.ArmazenamentoUtil.uuid())
                            .ver();
                        if ((typeof itemNotificacao !== 'undefined') && itemNotificacao) {
                            itemNotificacao.dataInicio = ctrl.controleManejoPomarSelecionado.data;
                            itemNotificacao.usuario = ctrl.usuarioSimplificado();
                            itemNotificacao.lido = false;
                            itemNotificacao.criacao = new Date();
                            itemNotificacao.listaUsuarios = [];
                            angular.forEach(ctrl.usuarios, (usuario) => {
                                if ((typeof usuario !== 'undefined') && usuario) {
                                    let obj = { id: null, uuid: null, status: null, email: null };
                                    const temUsuario = (typeof usuario !== 'undefined') && usuario ? true : false;
                                    obj.id = ((temUsuario && (typeof usuario.id !== 'undefined') && usuario.id) ? usuario.id : null);
                                    obj.uuid = ((temUsuario && (typeof usuario.uuid !== 'undefined') && usuario.uuid) ? usuario.uuid : null);
                                    obj.email = ((temUsuario && (typeof usuario.email !== 'undefined') && usuario.email) ? usuario.email : null);
                                    obj.nomeCompleto = ((temUsuario && (typeof usuario.nomeCompleto !== 'undefined') && usuario.nomeCompleto) ? usuario.nomeCompleto :
                                        ((temUsuario && (typeof usuario.nome !== 'undefined') && usuario.nome) ? usuario.nome : null));
                                    let itemUsuario = {
                                        id: null,
                                        uuid: null,
                                        usuario: obj,
                                        lido: false,
                                        status: true,
                                        visivel: true
                                    };
                                    let itemVerifica = itemNotificacao.listaUsuarios.find(o => ((o.usuario.id === usuario.id)));
                                    if ((typeof itemVerifica === 'undefined') || !itemVerifica) {
                                        itemNotificacao.listaUsuarios.push(itemUsuario);
                                    }
                                }
                            });
                            yield ctrl.sincronia
                                .rota(RotaAPIConstantes_1.RotaAPIConstantes.NOTIFICACAO)
                                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.NOTIFICACAO)
                                .atualizar(itemNotificacao);
                        }
                        else {
                            let itemNotificacao = {
                                id: null,
                                uuid: null,
                                dataInicio: ctrl.controleManejoPomarSelecionado.data,
                                usuario: ctrl.usuarioSimplificado(),
                                lido: false,
                                uuidLancamentoOrigem: ctrl.controleManejoPomarSelecionado.uuid || ArmazenamentoUtil_1.ArmazenamentoUtil.uuid(),
                                titulo: 'Lembrete: Enxertia Ano 1 de Avaliação Sobrevivência',
                                descricao: 'Já se passou 12 meses desde a data estabelecida.',
                                listaUsuarios: []
                            };
                            angular.forEach(ctrl.usuarios, (usuario) => {
                                if ((typeof usuario !== 'undefined') && usuario) {
                                    let obj = { id: null, uuid: null, status: null, email: null };
                                    const temUsuario = (typeof usuario !== 'undefined') && usuario ? true : false;
                                    obj.id = ((temUsuario && (typeof usuario.id !== 'undefined') && usuario.id) ? usuario.id : null);
                                    obj.uuid = ((temUsuario && (typeof usuario.uuid !== 'undefined') && usuario.uuid) ? usuario.uuid : null);
                                    obj.email = ((temUsuario && (typeof usuario.email !== 'undefined') && usuario.email) ? usuario.email : null);
                                    obj.nomeCompleto = ((temUsuario && (typeof usuario.nomeCompleto !== 'undefined') && usuario.nomeCompleto) ? usuario.nomeCompleto :
                                        ((temUsuario && (typeof usuario.nome !== 'undefined') && usuario.nome) ? usuario.nome : null));
                                    let itemUsuario = {
                                        id: null,
                                        uuid: null,
                                        usuario: obj,
                                        lido: false,
                                        status: true,
                                        visivel: true
                                    };
                                    let itemVerifica = itemNotificacao.listaUsuarios.find(o => ((o.usuario.id === usuario.id)));
                                    if ((typeof itemVerifica === 'undefined') || !itemVerifica) {
                                        itemNotificacao.listaUsuarios.push(itemUsuario);
                                    }
                                }
                            });
                            yield ctrl.sincronia
                                .rota(RotaAPIConstantes_1.RotaAPIConstantes.NOTIFICACAO)
                                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.NOTIFICACAO)
                                .criar(itemNotificacao);
                        }
                    }
                }
                if (ehOperacaoControleDoencas) {
                    if ((typeof ctrl.controleManejoPomarSelecionado.doencas !== 'undefined') && ctrl.controleManejoPomarSelecionado.doencas && ctrl.controleManejoPomarSelecionado.doencas.length > 0) {
                        let listaProdutos = [];
                        angular.forEach(ctrl.controleManejoPomarSelecionado.doencas, (produto) => {
                            var _a;
                            if ((typeof produto.produto !== 'undefined') && produto.produto &&
                                (typeof produto.centroCustoSap !== 'undefined') && produto.centroCustoSap &&
                                (typeof produto.contaDepositoSap !== 'undefined') && produto.contaDepositoSap) {
                                let itemProduto = {
                                    produto: produto.produto,
                                    contaDepositoSap: produto.contaDepositoSap,
                                    centroCustoSap: produto.centroCustoSap,
                                    dosagemTotal: (_a = produto.quantidadeArvores) !== null && _a !== void 0 ? _a : 0
                                };
                                let findProduto = listaProdutos.find((o) => (o.produto.id === itemProduto.produto.id) &&
                                    (o.centroCustoSap.id === itemProduto.centroCustoSap.id) && (o.contaDepositoSap.id === itemProduto.contaDepositoSap.id));
                                if ((typeof findProduto !== 'undefined') && findProduto) {
                                    findProduto.dosagemTotal += itemProduto.dosagemTotal;
                                }
                                else {
                                    listaProdutos.push(itemProduto);
                                }
                            }
                        });
                        for (const produtoFinal of listaProdutos) {
                            let produtoDefensivo = yield ctrl.sincronia
                                .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_DEFENSIVO)
                                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_DEFENSIVO)
                                .id(produtoFinal.produto.id)
                                .ver();
                            let historicoEstoqueProdutoDefensivo = new HistoricoEstoqueProdutoDefensivo_1.HistoricoEstoqueProdutoDefensivo();
                            if ((typeof produtoDefensivo !== 'undefined') && produtoDefensivo) {
                                historicoEstoqueProdutoDefensivo.produtoDefensivo = produtoFinal.produto;
                                historicoEstoqueProdutoDefensivo.quantidadeAnterior = (_a = produtoDefensivo.quantidadeAtual) !== null && _a !== void 0 ? _a : 0;
                                historicoEstoqueProdutoDefensivo.quantidadeMovimento = (_b = produtoFinal.dosagemTotal) !== null && _b !== void 0 ? _b : 0;
                                historicoEstoqueProdutoDefensivo.quantidadeAtual = historicoEstoqueProdutoDefensivo.quantidadeAnterior - historicoEstoqueProdutoDefensivo.quantidadeMovimento;
                                historicoEstoqueProdutoDefensivo.movimento = HistoricoEstoqueProdutoDefensivo_1.HistoricoEstoqueProdutoDefensivo.MOVIMENTO_SAIDA;
                                historicoEstoqueProdutoDefensivo.usuario = ctrl.usuarioLogado;
                                historicoEstoqueProdutoDefensivo.centroCustoSap = produtoFinal.centroCustoSap;
                                historicoEstoqueProdutoDefensivo.contaDepositoSap = produtoFinal.contaDepositoSap;
                                produtoDefensivo.quantidadeAtual = historicoEstoqueProdutoDefensivo.quantidadeAtual;
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.HISTORICO_ESTOQUE_PRODUTO_DEFENSIVO)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.HISTORICO_ESTOQUE_PRODUTO_DEFENSIVO)
                                    .criar(historicoEstoqueProdutoDefensivo);
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_DEFENSIVO)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_DEFENSIVO)
                                    .atualizar(produtoDefensivo);
                            }
                        }
                    }
                }
                if (ehOperacaoLimpeza) {
                    if ((typeof ctrl.controleManejoPomarSelecionado.limpezas !== 'undefined') && ctrl.controleManejoPomarSelecionado.limpezas && ctrl.controleManejoPomarSelecionado.limpezas.length > 0) {
                        let listaProdutos = [];
                        angular.forEach(ctrl.controleManejoPomarSelecionado.limpezas, (produto) => {
                            var _a;
                            if ((typeof produto.produto !== 'undefined') && produto.produto &&
                                (typeof produto.centroCustoSap !== 'undefined') && produto.centroCustoSap &&
                                (typeof produto.contaDepositoSap !== 'undefined') && produto.contaDepositoSap &&
                                (typeof produto.tipoLimpeza.usaProduto !== 'undefined') && produto.tipoLimpeza.usaProduto) {
                                let itemProduto = {
                                    produto: produto.produto,
                                    contaDepositoSap: produto.contaDepositoSap,
                                    centroCustoSap: produto.centroCustoSap,
                                    dosagemTotal: (_a = produto.dosagemProduto) !== null && _a !== void 0 ? _a : 0
                                };
                                let findProduto = listaProdutos.find((o) => (o.produto.id === itemProduto.produto.id) &&
                                    (o.centroCustoSap.id === itemProduto.centroCustoSap.id) && (o.contaDepositoSap.id === itemProduto.contaDepositoSap.id));
                                if ((typeof findProduto !== 'undefined') && findProduto) {
                                    findProduto.dosagemTotal += itemProduto.dosagemTotal;
                                }
                                else {
                                    listaProdutos.push(itemProduto);
                                }
                            }
                        });
                        for (const produtoFinal of listaProdutos) {
                            let produtoFertilizante = yield ctrl.sincronia
                                .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                                .id(produtoFinal.produto.id)
                                .ver();
                            let historicoEstoqueProdutoFertilizante = new HistoricoEstoqueProdutoFertilizante_1.HistoricoEstoqueProdutoFertilizante();
                            if ((typeof produtoFertilizante !== 'undefined') && produtoFertilizante) {
                                historicoEstoqueProdutoFertilizante.produtoFertilizante = produtoFinal.produto;
                                historicoEstoqueProdutoFertilizante.quantidadeAnterior = (_c = produtoFertilizante.quantidadeAtual) !== null && _c !== void 0 ? _c : 0;
                                historicoEstoqueProdutoFertilizante.quantidadeMovimento = (_d = produtoFinal.dosagemTotal) !== null && _d !== void 0 ? _d : 0;
                                historicoEstoqueProdutoFertilizante.quantidadeAtual = historicoEstoqueProdutoFertilizante.quantidadeAnterior - historicoEstoqueProdutoFertilizante.quantidadeMovimento;
                                historicoEstoqueProdutoFertilizante.movimento = HistoricoEstoqueProdutoFertilizante_1.HistoricoEstoqueProdutoFertilizante.MOVIMENTO_SAIDA;
                                historicoEstoqueProdutoFertilizante.usuario = ctrl.usuarioLogado;
                                historicoEstoqueProdutoFertilizante.centroCustoSap = produtoFinal.centroCustoSap;
                                historicoEstoqueProdutoFertilizante.contaDepositoSap = produtoFinal.contaDepositoSap;
                                produtoFertilizante.quantidadeAtual = historicoEstoqueProdutoFertilizante.quantidadeAtual;
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.HISTORICO_ESTOQUE_PRODUTO_FERTILIZANTE)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.HISTORICO_ESTOQUE_PRODUTO_FERTILIZANTE)
                                    .criar(historicoEstoqueProdutoFertilizante);
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                                    .atualizar(produtoFertilizante);
                            }
                        }
                    }
                }
                if (ehOperacaoAdubacaoFertilizacao) {
                    if ((typeof ctrl.controleManejoPomarSelecionado.adubacoesFertilizacoes !== 'undefined') && ctrl.controleManejoPomarSelecionado.adubacoesFertilizacoes && ctrl.controleManejoPomarSelecionado.adubacoesFertilizacoes.length > 0) {
                        let listaProdutos = [];
                        angular.forEach(ctrl.controleManejoPomarSelecionado.adubacoesFertilizacoes, (produto) => {
                            var _a;
                            if ((typeof produto.produto !== 'undefined') && produto.produto &&
                                (typeof produto.centroCustoSap !== 'undefined') && produto.centroCustoSap &&
                                (typeof produto.contaDepositoSap !== 'undefined') && produto.contaDepositoSap &&
                                (typeof produto.tipoAplicacao !== 'undefined') && produto.tipoAplicacao) {
                                let itemProduto = {
                                    produto: produto.produto,
                                    contaDepositoSap: produto.contaDepositoSap,
                                    centroCustoSap: produto.centroCustoSap,
                                    dosagemTotal: (_a = produto.dosagemProduto) !== null && _a !== void 0 ? _a : 0
                                };
                                let findProduto = listaProdutos.find((o) => (o.produto.id === itemProduto.produto.id) &&
                                    (o.centroCustoSap.id === itemProduto.centroCustoSap.id) && (o.contaDepositoSap.id === itemProduto.contaDepositoSap.id));
                                if ((typeof findProduto !== 'undefined') && findProduto) {
                                    findProduto.dosagemTotal += itemProduto.dosagemTotal;
                                }
                                else {
                                    listaProdutos.push(itemProduto);
                                }
                            }
                        });
                        for (const produtoFinal of listaProdutos) {
                            let produtoFertilizante = yield ctrl.sincronia
                                .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                                .id(produtoFinal.produto.id)
                                .ver();
                            let historicoEstoqueProdutoFertilizante = new HistoricoEstoqueProdutoFertilizante_1.HistoricoEstoqueProdutoFertilizante();
                            if ((typeof produtoFertilizante !== 'undefined') && produtoFertilizante) {
                                historicoEstoqueProdutoFertilizante.produtoFertilizante = produtoFinal.produto;
                                historicoEstoqueProdutoFertilizante.quantidadeAnterior = (_e = produtoFertilizante.quantidadeAtual) !== null && _e !== void 0 ? _e : 0;
                                historicoEstoqueProdutoFertilizante.quantidadeMovimento = (_f = produtoFinal.dosagemTotal) !== null && _f !== void 0 ? _f : 0;
                                historicoEstoqueProdutoFertilizante.quantidadeAtual = historicoEstoqueProdutoFertilizante.quantidadeAnterior - historicoEstoqueProdutoFertilizante.quantidadeMovimento;
                                historicoEstoqueProdutoFertilizante.movimento = HistoricoEstoqueProdutoFertilizante_1.HistoricoEstoqueProdutoFertilizante.MOVIMENTO_SAIDA;
                                historicoEstoqueProdutoFertilizante.usuario = ctrl.usuarioLogado;
                                historicoEstoqueProdutoFertilizante.centroCustoSap = produtoFinal.centroCustoSap;
                                historicoEstoqueProdutoFertilizante.contaDepositoSap = produtoFinal.contaDepositoSap;
                                produtoFertilizante.quantidadeAtual = historicoEstoqueProdutoFertilizante.quantidadeAtual;
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.HISTORICO_ESTOQUE_PRODUTO_FERTILIZANTE)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.HISTORICO_ESTOQUE_PRODUTO_FERTILIZANTE)
                                    .criar(historicoEstoqueProdutoFertilizante);
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                                    .atualizar(produtoFertilizante);
                            }
                        }
                    }
                }
                if ((typeof ctrl.controleManejoPomarSelecionado.pomar !== 'undefined') && ctrl.controleManejoPomarSelecionado.pomar &&
                    (typeof ctrl.controleManejoPomarSelecionado.pomar.descricao !== 'undefined') && ctrl.controleManejoPomarSelecionado.pomar.descricao) {
                    ctrl.controleManejoPomarSelecionado.pomarDescricao = ctrl.controleManejoPomarSelecionado.pomar.descricao;
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (ctrl.controleManejoPomarSelecionado && ctrl.controleManejoPomarSelecionado.id) {
                    ctrl.modalControleManejoPomar = false;
                    if (ctrl.ehCelular) {
                        alert(`Salvo com sucesso!`);
                    }
                    else {
                        sweetalert2_1.default.fire({
                            title: 'Salvo com sucesso!',
                            text: 'O lançamento foi salvo no banco de dados.',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'Ok'
                        });
                    }
                    const listaOpcoesManejoPomar = yield ctrl.dadosListaManejoPomar();
                    ctrl.listaOpcoesManejoPomarResumido = listaOpcoesManejoPomar.resumido;
                    ctrl.listaOpcoesManejoPomarDetalhado = listaOpcoesManejoPomar.detalhado;
                }
                ctrl.ocultarModalControleManejoPomar();
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static exibirModalControleManejoPomar(ctrl, dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.pomarSelecionado === 'undefined') || !ctrl.pomarSelecionado) {
                return;
            }
            try {
                ctrl.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                ctrl.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                ctrl.controleManejoPomarVisualizar = true;
                if ((typeof dados !== 'undefined') && dados && (typeof dados.id !== 'undefined') && dados.id) {
                    ctrl.controleManejoPomarSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.CONTROLE_MANEJO_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CONTROLE_MANEJO_POMAR)
                        .id(dados.id)
                        .ver();
                    if ((typeof ctrl.controleManejoPomarSelecionado === 'undefined') || !ctrl.controleManejoPomarSelecionado) {
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        if (ctrl.ehCelular) {
                            alert(`Registro "${dados.id} não encontrado!`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                text: `Registro "${dados.id}" não encontrado.`,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        return;
                    }
                    if ((typeof ctrl.controleManejoPomarSelecionado.criacao === 'string') && ctrl.controleManejoPomarSelecionado.criacao) {
                        ctrl.controleManejoPomarSelecionado.criacao = new Date(ctrl.controleManejoPomarSelecionado.criacao);
                    }
                    if ((typeof ctrl.controleManejoPomarSelecionado.data === 'string') && ctrl.controleManejoPomarSelecionado.data) {
                        ctrl.controleManejoPomarSelecionado.data = new Date(ctrl.controleManejoPomarSelecionado.data);
                    }
                    if ((typeof ctrl.controleManejoPomarSelecionado.dataIsolamento === 'string') && ctrl.controleManejoPomarSelecionado.dataIsolamento) {
                        ctrl.controleManejoPomarSelecionado.dataIsolamento = new Date(ctrl.controleManejoPomarSelecionado.dataIsolamento);
                    }
                    if ((typeof ctrl.controleManejoPomarSelecionado.dataColeta === 'string') && ctrl.controleManejoPomarSelecionado.dataColeta) {
                        ctrl.controleManejoPomarSelecionado.dataColeta = new Date(ctrl.controleManejoPomarSelecionado.dataColeta);
                    }
                    if ((typeof ctrl.controleManejoPomarSelecionado.dataHoraInclusao === 'string') && ctrl.controleManejoPomarSelecionado.dataHoraInclusao) {
                        ctrl.controleManejoPomarSelecionado.dataHoraInclusao = new Date(ctrl.controleManejoPomarSelecionado.dataHoraInclusao);
                    }
                    if ((typeof ctrl.controleManejoPomarSelecionado.dataHoraAlteracao === 'string') && ctrl.controleManejoPomarSelecionado.dataHoraAlteracao) {
                        ctrl.controleManejoPomarSelecionado.dataHoraAlteracao = new Date(ctrl.controleManejoPomarSelecionado.dataHoraAlteracao);
                    }
                    if ((typeof dados.visualizar !== 'undefined') && dados.visualizar) {
                        ctrl.controleManejoPomarVisualizar = true;
                    }
                    else if ((typeof dados.editar !== 'undefined') && dados.editar) {
                        ctrl.controleManejoPomarVisualizar = false;
                    }
                    else if ((typeof dados.excluir !== 'undefined') && dados.excluir) {
                        ctrl.notificacao
                            .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                            .then((result) => __awaiter(this, void 0, void 0, function* () {
                            if (result.value) {
                                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.CONTROLE_MANEJO_POMAR)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CONTROLE_MANEJO_POMAR)
                                    .id(dados.id)
                                    .remover();
                                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                                const listaOpcoesManejoPomar = yield ctrl.dadosListaManejoPomar();
                                ctrl.listaOpcoesManejoPomarResumido = listaOpcoesManejoPomar.resumido;
                                ctrl.listaOpcoesManejoPomarDetalhado = listaOpcoesManejoPomar.detalhado;
                                ctrl.scopeApply();
                            }
                        }));
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                }
                else {
                    ctrl.controleManejoPomarSelecionado = new ControleManejoPomar_1.ControleManejoPomar();
                    ctrl.controleManejoPomarSelecionado.criacao = new Date();
                    ctrl.controleManejoPomarSelecionado.criacao.setMilliseconds(0);
                    ctrl.controleManejoPomarVisualizar = false;
                }
                yield ctrl.alterarOperacaoControleManejoPomar();
                ctrl.modalControleManejoPomar = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static ocultarModalControleManejoPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.modalControleManejoPomar = false;
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = ctrl.posicaoTopoAntesModal;
            ctrl.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                        (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    static alterarOperacaoControleManejoPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.controleManejoPomarSelecionado === 'undefined') || !ctrl.controleManejoPomarSelecionado ||
                (typeof ctrl.controleManejoPomarSelecionado.operacao === 'undefined') || !ctrl.controleManejoPomarSelecionado.operacao) {
                return;
            }
            if ((typeof ctrl.controleManejoPomarSelecionado.limpezas === 'undefined') || !ctrl.controleManejoPomarSelecionado.limpezas) {
                ctrl.controleManejoPomarSelecionado.limpezas = new Array();
            }
            if ((typeof ctrl.controleManejoPomarSelecionado.desbastes === 'undefined') || !ctrl.controleManejoPomarSelecionado.desbastes) {
                ctrl.controleManejoPomarSelecionado.desbastes = new Array();
            }
            if ((typeof ctrl.controleManejoPomarSelecionado.polinizacoes === 'undefined') || !ctrl.controleManejoPomarSelecionado.polinizacoes) {
                ctrl.controleManejoPomarSelecionado.polinizacoes = new Array();
            }
            if ((typeof ctrl.controleManejoPomarSelecionado.coletasAmostras === 'undefined') || !ctrl.controleManejoPomarSelecionado.coletasAmostras) {
                ctrl.controleManejoPomarSelecionado.coletasAmostras = new Array();
            }
            if ((typeof ctrl.controleManejoPomarSelecionado.coletasSementes === 'undefined') || !ctrl.controleManejoPomarSelecionado.coletasSementes) {
                ctrl.controleManejoPomarSelecionado.coletasSementes = new Array();
            }
            if ((typeof ctrl.controleManejoPomarSelecionado.adubacoesFertilizacoes === 'undefined') || !ctrl.controleManejoPomarSelecionado.adubacoesFertilizacoes) {
                ctrl.controleManejoPomarSelecionado.adubacoesFertilizacoes = new Array();
            }
            if ((typeof ctrl.controleManejoPomarSelecionado.doencas === 'undefined') || !ctrl.controleManejoPomarSelecionado.doencas) {
                ctrl.controleManejoPomarSelecionado.doencas = new Array();
            }
            if ((typeof ctrl.controleManejoPomarSelecionado.outrasOperacoes === 'undefined') || !ctrl.controleManejoPomarSelecionado.outrasOperacoes) {
                ctrl.controleManejoPomarSelecionado.outrasOperacoes = new Array();
            }
            if ((typeof ctrl.controleManejoPomarSelecionado.avaliacoesSobrevivencia === 'undefined') || !ctrl.controleManejoPomarSelecionado.avaliacoesSobrevivencia) {
                ctrl.controleManejoPomarSelecionado.avaliacoesSobrevivencia = new Array();
            }
            if ((typeof ctrl.controleManejoPomarSelecionado.anexos === 'undefined') || !ctrl.controleManejoPomarSelecionado.anexos) {
                ctrl.controleManejoPomarSelecionado.anexos = new Array();
            }
            if ((typeof ctrl.controleManejoPomarSelecionado.operacao !== 'undefined') && ctrl.controleManejoPomarSelecionado.operacao &&
                (typeof ctrl.controleManejoPomarSelecionado.operacao.tipoOperacao !== 'undefined') && ctrl.controleManejoPomarSelecionado.operacao.tipoOperacao) {
                ctrl.controleManejoPomarSelecionado.operacao.ehOperacaoLimpeza = (ctrl.controleManejoPomarSelecionado.operacao.tipoOperacao === OperacaoManejoPomar_1.OperacaoManejoPomar.OPERACAO_LIMPEZA);
                ctrl.controleManejoPomarSelecionado.operacao.ehOperacaoDesbaste = (ctrl.controleManejoPomarSelecionado.operacao.tipoOperacao === OperacaoManejoPomar_1.OperacaoManejoPomar.OPERACAO_DESBASTE);
                ctrl.controleManejoPomarSelecionado.operacao.ehOperacaoPolinizacao = (ctrl.controleManejoPomarSelecionado.operacao.tipoOperacao === OperacaoManejoPomar_1.OperacaoManejoPomar.OPERACAO_POLINIZACAO);
                ctrl.controleManejoPomarSelecionado.operacao.ehOperacaoColetaAmostras = (ctrl.controleManejoPomarSelecionado.operacao.tipoOperacao === OperacaoManejoPomar_1.OperacaoManejoPomar.OPERACAO_COLETA_AMOSTRAS);
                ctrl.controleManejoPomarSelecionado.operacao.ehOperacaoColetaSementes = (ctrl.controleManejoPomarSelecionado.operacao.tipoOperacao === OperacaoManejoPomar_1.OperacaoManejoPomar.OPERACAO_COLETA_SEMENTES);
                ctrl.controleManejoPomarSelecionado.operacao.ehOperacaoControleDoencas = (ctrl.controleManejoPomarSelecionado.operacao.tipoOperacao === OperacaoManejoPomar_1.OperacaoManejoPomar.OPERACAO_CONTROLE_DOENCAS);
                ctrl.controleManejoPomarSelecionado.operacao.ehOperacaoAdubacaoFertilizacao = (ctrl.controleManejoPomarSelecionado.operacao.tipoOperacao === OperacaoManejoPomar_1.OperacaoManejoPomar.OPERACAO_ADUBACAO_FERTILIZACAO);
                ctrl.controleManejoPomarSelecionado.operacao.ehOperacaoPodaRamosPortaEnxerto = (ctrl.controleManejoPomarSelecionado.operacao.tipoOperacao === OperacaoManejoPomar_1.OperacaoManejoPomar.OPERACAO_PODA_RAMOS_PORTA_ENXERTO);
                ctrl.controleManejoPomarSelecionado.operacao.ehOperacaoAvaliacaoSobrevivencia = (ctrl.controleManejoPomarSelecionado.operacao.tipoOperacao === OperacaoManejoPomar_1.OperacaoManejoPomar.OPERACAO_AVALIACAO_SOBREVIVENCIA);
                ctrl.controleManejoPomarSelecionado.operacao.ehOutrasOperacoes = (ctrl.controleManejoPomarSelecionado.operacao.tipoOperacao === OperacaoManejoPomar_1.OperacaoManejoPomar.OUTRAS_OPERACOES);
            }
            const operacao = ctrl.controleManejoPomarSelecionado.operacao;
            const ehOperacaoLimpeza = (typeof operacao.ehOperacaoLimpeza !== 'undefined') && operacao.ehOperacaoLimpeza;
            const ehOperacaoDesbaste = !ehOperacaoLimpeza && (typeof operacao.ehOperacaoDesbaste !== 'undefined') && operacao.ehOperacaoDesbaste;
            const ehOperacaoPolinizacao = !ehOperacaoDesbaste && (typeof operacao.ehOperacaoPolinizacao !== 'undefined') && operacao.ehOperacaoPolinizacao;
            const ehOperacaoColetaAmostras = !ehOperacaoPolinizacao && (typeof operacao.ehOperacaoColetaAmostras !== 'undefined') && operacao.ehOperacaoColetaAmostras;
            const ehOperacaoAdubacaoFertilizacao = !ehOperacaoColetaAmostras && (typeof operacao.ehOperacaoAdubacaoFertilizacao !== 'undefined') && operacao.ehOperacaoAdubacaoFertilizacao;
            const ehOperacaoColetaSementes = !ehOperacaoAdubacaoFertilizacao && (typeof operacao.ehOperacaoColetaSementes !== 'undefined') && operacao.ehOperacaoColetaSementes;
            const ehOperacaoControleDoencas = !ehOperacaoColetaSementes && (typeof operacao.ehOperacaoControleDoencas !== 'undefined') && operacao.ehOperacaoControleDoencas;
            const ehOperacaoPodaRamosPortaEnxerto = !ehOperacaoControleDoencas && (typeof operacao.ehOperacaoPodaRamosPortaEnxerto !== 'undefined') && operacao.ehOperacaoPodaRamosPortaEnxerto;
            const ehOperacaoAvaliacaoSobrevivencia = !ehOperacaoPodaRamosPortaEnxerto && (typeof operacao.ehOperacaoAvaliacaoSobrevivencia !== 'undefined') && operacao.ehOperacaoAvaliacaoSobrevivencia;
            const ehOutrasOperacoes = !ehOperacaoAvaliacaoSobrevivencia && (typeof operacao.ehOutrasOperacoes !== 'undefined') && operacao.ehOutrasOperacoes;
            if (ehOperacaoLimpeza) {
                if (ctrl.controleManejoPomarSelecionado.limpezas.length === 0) {
                    let limpezaPomar = new OperacaoLimpezaPomar_1.OperacaoLimpezaPomar();
                    limpezaPomar.status = true;
                    limpezaPomar.criacao = new Date();
                    ctrl.controleManejoPomarSelecionado.limpezas.push(limpezaPomar);
                    ctrl.controleManejoPomarSelecionado.ehTodosMateriaisGeneticos = true;
                }
            }
            if (ehOperacaoDesbaste) {
                if (ctrl.controleManejoPomarSelecionado.desbastes.length === 0) {
                    let desbastePomar = new OperacaoDesbastePomar_1.OperacaoDesbastePomar();
                    desbastePomar.status = true;
                    desbastePomar.criacao = new Date();
                    ctrl.controleManejoPomarSelecionado.desbastes.push(desbastePomar);
                    ctrl.controleManejoPomarSelecionado.ehTodosMateriaisGeneticos = false;
                }
                angular.forEach(ctrl.controleManejoPomarSelecionado.desbastes, (desbastePomar) => {
                    if ((typeof desbastePomar.linhasColunas === 'undefined') || !desbastePomar.linhasColunas) {
                        desbastePomar.linhasColunas = new Array();
                    }
                    if (desbastePomar.linhasColunas.length === 0) {
                        desbastePomar.linhasColunas.push(new LinhaColunaPomar_1.LinhaColunaPomar());
                    }
                });
            }
            if (ehOperacaoPolinizacao) {
                if (ctrl.controleManejoPomarSelecionado.polinizacoes.length === 0) {
                    for (let i = 1; i <= 30; i++) {
                        let operacaoPolinizacaoPomar = new OperacaoPolinizacaoPomar_1.OperacaoPolinizacaoPomar();
                        operacaoPolinizacaoPomar.status = true;
                        operacaoPolinizacaoPomar.criacao = new Date();
                        ctrl.controleManejoPomarSelecionado.polinizacoes.push(operacaoPolinizacaoPomar);
                        ctrl.controleManejoPomarSelecionado.ehTodosMateriaisGeneticos = false;
                        if ((typeof ctrl.controleManejoPomarSelecionado.pomarCloneFeminino === 'undefined') || !ctrl.controleManejoPomarSelecionado.pomarCloneFeminino) {
                            ctrl.controleManejoPomarSelecionado.pomarCloneFeminino = ctrl.pomarSelecionado;
                        }
                        ctrl.controleManejoPomarSelecionado.especie = {
                            id: ctrl.pomarSelecionado.especieDescricao,
                            uuid: ctrl.pomarSelecionado.especieDescricao,
                            status: true,
                            codigo: 1,
                            descricao: ctrl.pomarSelecionado.especieDescricao,
                        };
                    }
                }
            }
            if (ehOperacaoColetaAmostras) {
                if (ctrl.controleManejoPomarSelecionado.coletasAmostras.length === 0) {
                    let operacaoColetaAmostraPomar = new OperacaoColetaAmostraPomar_1.OperacaoColetaAmostraPomar();
                    operacaoColetaAmostraPomar.status = true;
                    operacaoColetaAmostraPomar.criacao = new Date();
                    ctrl.controleManejoPomarSelecionado.coletasAmostras.push(operacaoColetaAmostraPomar);
                    ctrl.controleManejoPomarSelecionado.ehTodosMateriaisGeneticos = false;
                }
            }
            if (ehOperacaoColetaSementes) {
                if (ctrl.controleManejoPomarSelecionado.coletasSementes.length === 0) {
                    let operacaoColetaSementePomar = new OperacaoColetaSementePomar_1.OperacaoColetaSementePomar();
                    operacaoColetaSementePomar.status = true;
                    operacaoColetaSementePomar.criacao = new Date();
                    ctrl.controleManejoPomarSelecionado.coletasSementes.push(operacaoColetaSementePomar);
                    ctrl.controleManejoPomarSelecionado.ehTodosMateriaisGeneticos = false;
                }
            }
            if (ehOperacaoControleDoencas) {
                if (ctrl.controleManejoPomarSelecionado.doencas.length === 0) {
                    let operacaoControleDoencaPomar = new OperacaoControleDoencaPomar_1.OperacaoControleDoencaPomar();
                    operacaoControleDoencaPomar.status = true;
                    operacaoControleDoencaPomar.criacao = new Date();
                    ctrl.controleManejoPomarSelecionado.doencas.push(operacaoControleDoencaPomar);
                    ctrl.controleManejoPomarSelecionado.ehTodosMateriaisGeneticos = false;
                }
            }
            if (ehOperacaoAdubacaoFertilizacao) {
                if (ctrl.controleManejoPomarSelecionado.adubacoesFertilizacoes.length === 0) {
                    let operacaoAdubacaoFertilizacaoPomar = new OperacaoAdubacaoFertilizacaoPomar_1.OperacaoAdubacaoFertilizacaoPomar();
                    operacaoAdubacaoFertilizacaoPomar.status = true;
                    operacaoAdubacaoFertilizacaoPomar.criacao = new Date();
                    ctrl.controleManejoPomarSelecionado.adubacoesFertilizacoes.push(operacaoAdubacaoFertilizacaoPomar);
                    ctrl.controleManejoPomarSelecionado.ehTodosMateriaisGeneticos = true;
                }
            }
            if (ehOperacaoPodaRamosPortaEnxerto) {
                if (ctrl.controleManejoPomarSelecionado.adubacoesFertilizacoes.length === 0) {
                    let operacaoAdubacaoFertilizacaoPomar = new OperacaoAdubacaoFertilizacaoPomar_1.OperacaoAdubacaoFertilizacaoPomar();
                    operacaoAdubacaoFertilizacaoPomar.status = true;
                    operacaoAdubacaoFertilizacaoPomar.criacao = new Date();
                    ctrl.controleManejoPomarSelecionado.adubacoesFertilizacoes.push(operacaoAdubacaoFertilizacaoPomar);
                    ctrl.controleManejoPomarSelecionado.ehTodosMateriaisGeneticos = true;
                }
            }
            if (ehOutrasOperacoes) {
                if (ctrl.controleManejoPomarSelecionado.outrasOperacoes.length === 0) {
                    let outraOperacaoPomar = new OutraOperacaoPomar_1.OutraOperacaoPomar();
                    outraOperacaoPomar.status = true;
                    outraOperacaoPomar.criacao = new Date();
                    ctrl.controleManejoPomarSelecionado.outrasOperacoes.push(outraOperacaoPomar);
                    ctrl.controleManejoPomarSelecionado.ehTodosMateriaisGeneticos = true;
                }
            }
            yield ctrl.totalizarControleManejoPomar();
            ctrl.scopeApply();
        });
    }
    static alterarMateriaisGeneticosControleManejoPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.controleManejoPomarSelecionado !== 'undefined') && ctrl.controleManejoPomarSelecionado &&
                (typeof ctrl.controleManejoPomarSelecionado.materiaisGeneticos !== 'undefined') && ctrl.controleManejoPomarSelecionado.materiaisGeneticos) {
                let ehSelecionarTodos = false;
                let ehDesmarcarTodos = false;
                let alterouRegistrosOperacao = false;
                angular.forEach(ctrl.controleManejoPomarSelecionado.materiaisGeneticos, (materialGenetico) => {
                    if ((typeof materialGenetico !== 'undefined') && materialGenetico) {
                        const materialGeneticoId = ((typeof materialGenetico === 'string') ? materialGenetico :
                            ((typeof materialGenetico.id !== 'undefined') && materialGenetico.id ? materialGenetico.id :
                                ((typeof materialGenetico.value !== 'undefined') && materialGenetico.value ? materialGenetico.value : '')));
                        if (materialGeneticoId && (materialGeneticoId === 'TODOS')) {
                            ehSelecionarTodos = true;
                        }
                        else if (materialGeneticoId && (materialGeneticoId === 'NENHUM')) {
                            ehDesmarcarTodos = true;
                        }
                    }
                });
                if (ehSelecionarTodos) {
                    let lista = new Array();
                    if ((typeof ctrl.selectizeOptionsMateriaisGeneticos !== 'undefined') && ctrl.selectizeOptionsMateriaisGeneticos) {
                        angular.forEach(ctrl.selectizeOptionsMateriaisGeneticos, (materialGenetico) => {
                            if ((typeof materialGenetico !== 'undefined') && materialGenetico) {
                                const materialGeneticoId = ((typeof materialGenetico === 'string') ? materialGenetico :
                                    ((typeof materialGenetico.id !== 'undefined') && materialGenetico.id ? materialGenetico.id :
                                        ((typeof materialGenetico.value !== 'undefined') && materialGenetico.value ? materialGenetico.value : '')));
                                if (materialGeneticoId && (materialGeneticoId !== 'TODOS') && (materialGeneticoId !== 'NENHUM')) {
                                    lista.push(materialGeneticoId);
                                }
                            }
                        });
                    }
                    ctrl.controleManejoPomarSelecionado.materiaisGeneticos = lista;
                }
                else if (ehDesmarcarTodos) {
                    ctrl.controleManejoPomarSelecionado.materiaisGeneticos = [];
                }
                if ((typeof ctrl.controleManejoPomarSelecionado.operacao !== 'undefined') && ctrl.controleManejoPomarSelecionado.operacao) {
                    const operacao = ctrl.controleManejoPomarSelecionado.operacao;
                    const ehOperacaoLimpeza = (typeof operacao.ehOperacaoLimpeza !== 'undefined') && operacao.ehOperacaoLimpeza;
                    const ehOperacaoDesbaste = !ehOperacaoLimpeza && (typeof operacao.ehOperacaoDesbaste !== 'undefined') && operacao.ehOperacaoDesbaste;
                    const ehOperacaoPolinizacao = !ehOperacaoDesbaste && (typeof operacao.ehOperacaoPolinizacao !== 'undefined') && operacao.ehOperacaoPolinizacao;
                    const ehOperacaoColetaAmostras = !ehOperacaoPolinizacao && (typeof operacao.ehOperacaoColetaAmostras !== 'undefined') && operacao.ehOperacaoColetaAmostras;
                    const ehOperacaoAdubacaoFertilizacao = !ehOperacaoColetaAmostras && (typeof operacao.ehOperacaoAdubacaoFertilizacao !== 'undefined') && operacao.ehOperacaoAdubacaoFertilizacao;
                    const ehOperacaoColetaSementes = !ehOperacaoAdubacaoFertilizacao && (typeof operacao.ehOperacaoColetaSementes !== 'undefined') && operacao.ehOperacaoColetaSementes;
                    const ehOperacaoControleDoencas = !ehOperacaoColetaSementes && (typeof operacao.ehOperacaoControleDoencas !== 'undefined') && operacao.ehOperacaoControleDoencas;
                    const ehOperacaoPodaRamosPortaEnxerto = !ehOperacaoControleDoencas && (typeof operacao.ehOperacaoPodaRamosPortaEnxerto !== 'undefined') && operacao.ehOperacaoPodaRamosPortaEnxerto;
                    if (ehOperacaoDesbaste || ehOperacaoColetaAmostras || ehOperacaoColetaSementes || ehOperacaoControleDoencas || ehOperacaoPodaRamosPortaEnxerto) {
                        let materiaisGeneticos = new Array();
                        angular.forEach(ctrl.controleManejoPomarSelecionado.materiaisGeneticos, (materialGenetico) => {
                            if ((typeof materialGenetico !== 'undefined') && materialGenetico) {
                                const materialGeneticoId = ((typeof materialGenetico === 'string') ? materialGenetico :
                                    ((typeof materialGenetico.id !== 'undefined') && materialGenetico.id ? materialGenetico.id :
                                        ((typeof materialGenetico.value !== 'undefined') && materialGenetico.value ? materialGenetico.value : '')));
                                if (materialGeneticoId && (materialGeneticoId !== 'TODOS') && (materialGeneticoId !== 'NENHUM')) {
                                    let achouMaterialGenetico = false;
                                    angular.forEach(ctrl.materiaisGeneticos, (materialGenetico) => {
                                        if (!achouMaterialGenetico) {
                                            if ((materialGenetico.id ? materialGenetico.id : materialGenetico.uuid) === materialGeneticoId) {
                                                materiaisGeneticos.push(materialGenetico);
                                                achouMaterialGenetico = true;
                                            }
                                        }
                                    });
                                }
                            }
                        });
                        const listagemOperacao = ehOperacaoDesbaste ? 'desbastes' :
                            (ehOperacaoColetaAmostras ? 'coletasAmostras' :
                                (ehOperacaoColetaSementes ? 'coletasSementes' :
                                    (ehOperacaoControleDoencas ? 'doencas' : 'operacaoInvalida')));
                        if ((typeof ctrl.controleManejoPomarSelecionado[listagemOperacao] === 'undefined') || !ctrl.controleManejoPomarSelecionado[listagemOperacao]) {
                            ctrl.controleManejoPomarSelecionado[listagemOperacao] = new Array();
                        }
                        angular.forEach(materiaisGeneticos, (materialGenetico) => {
                            let achouMaterialGenetico = false;
                            angular.forEach(ctrl.controleManejoPomarSelecionado[listagemOperacao], (registro) => {
                                if (!achouMaterialGenetico && (typeof registro.materialGenetico !== 'undefined') && registro.materialGenetico) {
                                    if ((registro.materialGenetico.id ? registro.materialGenetico.id : registro.materialGenetico.uuid) === (materialGenetico.id ? materialGenetico.id : materialGenetico.uuid)) {
                                        achouMaterialGenetico = true;
                                    }
                                }
                            });
                            if (!achouMaterialGenetico) {
                                if (ctrl.controleManejoPomarSelecionado[listagemOperacao].length > 0) {
                                    let registro = ctrl.controleManejoPomarSelecionado[listagemOperacao][0];
                                    if (((typeof registro.materialGenetico === 'undefined') || !registro.materialGenetico) &&
                                        ((typeof registro.numClones === 'undefined') || !registro.numClones) &&
                                        ((typeof registro.numRametes === 'undefined') || !registro.numRametes)) {
                                        ctrl.controleManejoPomarSelecionado[listagemOperacao].splice(0, 1);
                                    }
                                }
                                let novoRegistro = {
                                    id: null,
                                    uuid: null,
                                    guid: null,
                                    status: true,
                                    criacao: new Date(),
                                    numClones: 1,
                                    materialGenetico: materialGenetico
                                };
                                ctrl.controleManejoPomarSelecionado[listagemOperacao].push(novoRegistro);
                                alterouRegistrosOperacao = true;
                            }
                        });
                        let listaIndicesRegistrosARemover = new Array();
                        angular.forEach(ctrl.controleManejoPomarSelecionado[listagemOperacao], (registro, keyRegistro) => {
                            let achouMaterialGenetico = false;
                            if ((typeof registro.materialGenetico !== 'undefined') && registro.materialGenetico) {
                                angular.forEach(materiaisGeneticos, (materialGenetico) => {
                                    if (!achouMaterialGenetico) {
                                        if ((registro.materialGenetico.id ? registro.materialGenetico.id : registro.materialGenetico.uuid) === (materialGenetico.id ? materialGenetico.id : materialGenetico.uuid)) {
                                            achouMaterialGenetico = true;
                                        }
                                    }
                                });
                            }
                            if (!achouMaterialGenetico &&
                                ((typeof registro.numClones === 'undefined') || !registro.numClones) &&
                                ((typeof registro.numRametes === 'undefined') || !registro.numRametes) &&
                                ((typeof registro.quantidadeAmostras === 'undefined') || !registro.quantidadeAmostras) &&
                                ((typeof registro.quantidade === 'undefined') || !registro.quantidade)) {
                                listaIndicesRegistrosARemover = [keyRegistro, ...listaIndicesRegistrosARemover];
                            }
                        });
                        if (listaIndicesRegistrosARemover.length > 0) {
                            angular.forEach(listaIndicesRegistrosARemover, (posicao) => {
                                ctrl.controleManejoPomarSelecionado[listagemOperacao].splice(posicao, 1);
                                alterouRegistrosOperacao = true;
                            });
                        }
                    }
                }
                if (ehSelecionarTodos || ehDesmarcarTodos) {
                    ctrl.totalizarControleManejoPomar();
                    ctrl.scopeApply(() => {
                        const elementos = angular.element('#materiaisGeneticosControleManejoPomar');
                        if (elementos && elementos.length > 0) {
                            const primeiroElemento = elementos.get(0);
                            primeiroElemento.selectize.close();
                        }
                    });
                }
                else if (alterouRegistrosOperacao) {
                    ctrl.totalizarControleManejoPomar();
                    ctrl.scopeApply();
                }
            }
        });
    }
    static alterarClonesMasculinosControleManejoPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.controleManejoPomarSelecionado !== 'undefined') && ctrl.controleManejoPomarSelecionado &&
                (typeof ctrl.controleManejoPomarSelecionado.clonesMasculinos !== 'undefined') && ctrl.controleManejoPomarSelecionado.clonesMasculinos) {
                let ehSelecionarTodos = false;
                let ehDesmarcarTodos = false;
                angular.forEach(ctrl.controleManejoPomarSelecionado.clonesMasculinos, (materialGenetico) => {
                    if ((typeof materialGenetico !== 'undefined') && materialGenetico) {
                        const materialGeneticoId = ((typeof materialGenetico === 'string') ? materialGenetico :
                            ((typeof materialGenetico.id !== 'undefined') && materialGenetico.id ? materialGenetico.id :
                                ((typeof materialGenetico.value !== 'undefined') && materialGenetico.value ? materialGenetico.value : '')));
                        if (materialGeneticoId && (materialGeneticoId === 'TODOS')) {
                            ehSelecionarTodos = true;
                        }
                        else if (materialGeneticoId && (materialGeneticoId === 'NENHUM')) {
                            ehDesmarcarTodos = true;
                        }
                    }
                });
                if (ehSelecionarTodos) {
                    let lista = new Array();
                    if ((typeof ctrl.selectizeOptionsMateriaisGeneticos !== 'undefined') && ctrl.selectizeOptionsMateriaisGeneticos) {
                        angular.forEach(ctrl.selectizeOptionsMateriaisGeneticos, (materialGenetico) => {
                            if ((typeof materialGenetico !== 'undefined') && materialGenetico) {
                                const materialGeneticoId = ((typeof materialGenetico === 'string') ? materialGenetico :
                                    ((typeof materialGenetico.id !== 'undefined') && materialGenetico.id ? materialGenetico.id :
                                        ((typeof materialGenetico.value !== 'undefined') && materialGenetico.value ? materialGenetico.value : '')));
                                if (materialGeneticoId && (materialGeneticoId !== 'TODOS') && (materialGeneticoId !== 'NENHUM')) {
                                    lista.push(materialGeneticoId);
                                }
                            }
                        });
                    }
                    ctrl.controleManejoPomarSelecionado.clonesMasculinos = lista;
                }
                else if (ehDesmarcarTodos) {
                    ctrl.controleManejoPomarSelecionado.clonesMasculinos = [];
                }
                ctrl.scopeApply();
            }
        });
    }
    static adicionarRegistroOperacaoControleManejoPomar(ctrl, listagemOperacao) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.controleManejoPomarSelecionado) {
                return;
            }
            if ((typeof ctrl.controleManejoPomarSelecionado[listagemOperacao] === 'undefined') || !ctrl.controleManejoPomarSelecionado[listagemOperacao]) {
                ctrl.controleManejoPomarSelecionado[listagemOperacao] = [];
            }
            let registro = {
                id: null,
                uuid: null,
                guid: null,
                status: true,
                criacao: new Date(),
                numClones: 1
            };
            if (listagemOperacao === 'desbastes') {
                if ((typeof registro.linhasColunas === 'undefined') || !registro.linhasColunas) {
                    registro.linhasColunas = new Array();
                }
                if (registro.linhasColunas.length === 0) {
                    registro.linhasColunas.push(new LinhaColunaPomar_1.LinhaColunaPomar());
                }
            }
            ctrl.controleManejoPomarSelecionado[listagemOperacao].push(registro);
            yield ctrl.totalizarControleManejoPomar();
            ctrl.scopeApply();
        });
    }
    static removerRegistroOperacaoControleManejoPomar(ctrl, listagemOperacao, keyOperacao) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.controleManejoPomarSelecionado || !listagemOperacao) {
                return;
            }
            if ((typeof ctrl.controleManejoPomarSelecionado[listagemOperacao] === 'undefined') || !ctrl.controleManejoPomarSelecionado[listagemOperacao]) {
                ctrl.controleManejoPomarSelecionado[listagemOperacao] = [];
            }
            if ((typeof keyOperacao !== 'undefined') && (keyOperacao !== null) &&
                (ctrl.controleManejoPomarSelecionado[listagemOperacao].length > 0) &&
                (keyOperacao <= ctrl.controleManejoPomarSelecionado[listagemOperacao].length - 1)) {
                const numRegistro = keyOperacao + 1;
                if (window.confirm('Deseja realmente remover esse registro #' + numRegistro + ' ?')) {
                    ctrl.controleManejoPomarSelecionado[listagemOperacao].splice(keyOperacao, 1);
                    yield ctrl.totalizarControleManejoPomar();
                    ctrl.scopeApply();
                }
            }
        });
    }
    static moverRegistroOperacaoControleManejoPomarParaCima(ctrl, listagemOperacao, keyOperacao) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.controleManejoPomarSelecionado) {
                return;
            }
            if ((typeof ctrl.controleManejoPomarSelecionado[listagemOperacao] === 'undefined') || !ctrl.controleManejoPomarSelecionado[listagemOperacao]) {
                ctrl.controleManejoPomarSelecionado[listagemOperacao] = [];
            }
            if ((keyOperacao !== null) && (keyOperacao > 0) && (keyOperacao <= ctrl.controleManejoPomarSelecionado[listagemOperacao].length - 1)) {
                let oldKey = keyOperacao;
                let newKey = keyOperacao - 1;
                ctrl.controleManejoPomarSelecionado[listagemOperacao].splice(newKey, 0, ctrl.controleManejoPomarSelecionado[listagemOperacao].splice(oldKey, 1)[0]);
                ctrl.scopeApply();
            }
        });
    }
    static moverRegistroOperacaoControleManejoPomarParaBaixo(ctrl, listagemOperacao, keyOperacao) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.controleManejoPomarSelecionado) {
                return;
            }
            if ((typeof ctrl.controleManejoPomarSelecionado[listagemOperacao] === 'undefined') || !ctrl.controleManejoPomarSelecionado[listagemOperacao]) {
                ctrl.controleManejoPomarSelecionado[listagemOperacao] = [];
            }
            if ((keyOperacao != null) && (keyOperacao >= 0) && (keyOperacao < ctrl.controleManejoPomarSelecionado[listagemOperacao].length - 1)) {
                let oldKey = keyOperacao;
                let newKey = keyOperacao + 1;
                ctrl.controleManejoPomarSelecionado[listagemOperacao].splice(newKey, 0, ctrl.controleManejoPomarSelecionado[listagemOperacao].splice(oldKey, 1)[0]);
                ctrl.scopeApply();
            }
        });
    }
    static adicionarLinhaColunaRegistroOperacaoControleManejoPomar(ctrl, operacaoControleManejo) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!operacaoControleManejo) {
                return;
            }
            if ((typeof operacaoControleManejo.linhasColunas === 'undefined') || !operacaoControleManejo.linhasColunas) {
                operacaoControleManejo.linhasColunas = new Array();
            }
            operacaoControleManejo.linhasColunas.push(new LinhaColunaPomar_1.LinhaColunaPomar());
            ctrl.scopeApply();
        });
    }
    static removerLinhaColunaRegistroOperacaoControleManejoPomar(ctrl, operacaoControleManejo, keyLinhaColuna) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!operacaoControleManejo) {
                return;
            }
            if ((typeof operacaoControleManejo.linhasColunas === 'undefined') || !operacaoControleManejo.linhasColunas) {
                operacaoControleManejo.linhasColunas = new Array();
            }
            if ((typeof keyLinhaColuna !== 'undefined') && (keyLinhaColuna !== null) &&
                (operacaoControleManejo.linhasColunas.length > 0) &&
                (keyLinhaColuna <= operacaoControleManejo.linhasColunas.length - 1)) {
                operacaoControleManejo.linhasColunas.splice(keyLinhaColuna, 1);
                ctrl.scopeApply();
            }
        });
    }
    static totalizarControleManejoPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.controleManejoPomarSelecionado !== 'undefined') && ctrl.controleManejoPomarSelecionado &&
                (typeof ctrl.controleManejoPomarSelecionado.operacao !== 'undefined') && ctrl.controleManejoPomarSelecionado.operacao) {
                const operacao = ctrl.controleManejoPomarSelecionado.operacao;
                const ehOperacaoLimpeza = (typeof operacao.ehOperacaoLimpeza !== 'undefined') && operacao.ehOperacaoLimpeza;
                const ehOperacaoDesbaste = !ehOperacaoLimpeza && (typeof operacao.ehOperacaoDesbaste !== 'undefined') && operacao.ehOperacaoDesbaste;
                const ehOperacaoPolinizacao = !ehOperacaoDesbaste && (typeof operacao.ehOperacaoPolinizacao !== 'undefined') && operacao.ehOperacaoPolinizacao;
                const ehOperacaoColetaAmostras = !ehOperacaoPolinizacao && (typeof operacao.ehOperacaoColetaAmostras !== 'undefined') && operacao.ehOperacaoColetaAmostras;
                const ehOperacaoAdubacaoFertilizacao = !ehOperacaoColetaAmostras && (typeof operacao.ehOperacaoAdubacaoFertilizacao !== 'undefined') && operacao.ehOperacaoAdubacaoFertilizacao;
                const ehOperacaoColetaSementes = !ehOperacaoAdubacaoFertilizacao && (typeof operacao.ehOperacaoColetaSementes !== 'undefined') && operacao.ehOperacaoColetaSementes;
                const ehOperacaoControleDoencas = !ehOperacaoColetaSementes && (typeof operacao.ehOperacaoControleDoencas !== 'undefined') && operacao.ehOperacaoControleDoencas;
                const ehOperacaoPodaRamosPortaEnxerto = !ehOperacaoControleDoencas && (typeof operacao.ehOperacaoPodaRamosPortaEnxerto !== 'undefined') && operacao.ehOperacaoPodaRamosPortaEnxerto;
                if (ehOperacaoDesbaste) {
                    let numClones = 0;
                    let numRametes = 0;
                    if ((typeof ctrl.controleManejoPomarSelecionado.desbastes !== 'undefined') && ctrl.controleManejoPomarSelecionado.desbastes) {
                        angular.forEach(ctrl.controleManejoPomarSelecionado.desbastes, (desbaste) => {
                            if ((typeof desbaste.numClones !== 'undefined') && desbaste.numClones && (desbaste.numClones > 0)) {
                                numClones += desbaste.numClones;
                            }
                            else {
                                numClones += 1;
                            }
                            if ((typeof desbaste.numRametes !== 'undefined') && desbaste.numRametes) {
                                numRametes += desbaste.numRametes;
                            }
                        });
                    }
                    ctrl.controleManejoPomarSelecionado.numClones = numClones;
                    ctrl.controleManejoPomarSelecionado.numRametes = numRametes;
                }
                if (ehOperacaoColetaAmostras) {
                    let numClones = 0;
                    let quantidadeAmostras = 0;
                    if ((typeof ctrl.controleManejoPomarSelecionado.coletasAmostras !== 'undefined') && ctrl.controleManejoPomarSelecionado.coletasAmostras) {
                        angular.forEach(ctrl.controleManejoPomarSelecionado.coletasAmostras, (coletaAmostra) => {
                            if ((typeof coletaAmostra.numClones !== 'undefined') && coletaAmostra.numClones && (coletaAmostra.numClones > 0)) {
                                numClones += coletaAmostra.numClones;
                            }
                            else {
                                numClones += 1;
                            }
                            if ((typeof coletaAmostra.quantidadeAmostras !== 'undefined') && coletaAmostra.quantidadeAmostras) {
                                quantidadeAmostras += coletaAmostra.quantidadeAmostras;
                            }
                        });
                    }
                    ctrl.controleManejoPomarSelecionado.numClones = numClones;
                    ctrl.controleManejoPomarSelecionado.quantidadeAmostras = quantidadeAmostras;
                }
                if (ehOperacaoColetaSementes) {
                    let numClones = 0;
                    let numCones = 0;
                    let quantidade = 0;
                    let quantidadeArvores = 0;
                    if ((typeof ctrl.controleManejoPomarSelecionado.coletasSementes !== 'undefined') && ctrl.controleManejoPomarSelecionado.coletasSementes) {
                        angular.forEach(ctrl.controleManejoPomarSelecionado.coletasSementes, (coletaSemente) => {
                            if ((typeof coletaSemente.numClones !== 'undefined') && coletaSemente.numClones && (coletaSemente.numClones > 0)) {
                                numClones += coletaSemente.numClones;
                            }
                            else {
                                numClones += 1;
                            }
                            if ((typeof coletaSemente.numCones !== 'undefined') && coletaSemente.numCones) {
                                numCones += coletaSemente.numCones;
                            }
                            if ((typeof coletaSemente.quantidade !== 'undefined') && coletaSemente.quantidade) {
                                quantidade += coletaSemente.quantidade;
                            }
                            if ((typeof coletaSemente.numArvores !== 'undefined') && coletaSemente.numArvores) {
                                quantidadeArvores += coletaSemente.numArvores;
                            }
                        });
                    }
                    ctrl.controleManejoPomarSelecionado.numClones = numClones;
                    ctrl.controleManejoPomarSelecionado.numCones = numCones;
                    ctrl.controleManejoPomarSelecionado.quantidade = quantidade;
                    ctrl.controleManejoPomarSelecionado.quantidadeArvores = quantidadeArvores;
                }
                if (ehOperacaoPolinizacao) {
                    let numClones = 0;
                    let numCones = 0;
                    if ((typeof ctrl.controleManejoPomarSelecionado.coletasSementes !== 'undefined') && ctrl.controleManejoPomarSelecionado.coletasSementes) {
                        angular.forEach(ctrl.controleManejoPomarSelecionado.coletasSementes, (coletaSemente) => {
                            if ((typeof coletaSemente.numClones !== 'undefined') && coletaSemente.numClones && (coletaSemente.numClones > 0)) {
                                numClones += coletaSemente.numClones;
                            }
                            else {
                                numClones += 1;
                            }
                            if ((typeof coletaSemente.numCones !== 'undefined') && coletaSemente.numCones) {
                                numCones += coletaSemente.numCones;
                            }
                        });
                    }
                    ctrl.controleManejoPomarSelecionado.numClones = numClones;
                    ctrl.controleManejoPomarSelecionado.numCones = numCones;
                }
                if (ehOperacaoControleDoencas) {
                    let numClones = 0;
                    let quantidadeArvores = 0;
                    if ((typeof ctrl.controleManejoPomarSelecionado.doencas !== 'undefined') && ctrl.controleManejoPomarSelecionado.doencas) {
                        angular.forEach(ctrl.controleManejoPomarSelecionado.doencas, (doenca) => {
                            if ((typeof doenca.numClones !== 'undefined') && doenca.numClones && (doenca.numClones > 0)) {
                                numClones += doenca.numClones;
                            }
                            else {
                                numClones += 1;
                            }
                            if ((typeof doenca.quantidadeArvores !== 'undefined') && doenca.quantidadeArvores) {
                                quantidadeArvores += doenca.quantidadeArvores;
                            }
                        });
                    }
                    ctrl.controleManejoPomarSelecionado.numClones = numClones;
                    ctrl.controleManejoPomarSelecionado.quantidadeArvores = quantidadeArvores;
                }
                if (ehOperacaoPodaRamosPortaEnxerto) {
                    ctrl.controleManejoPomarSelecionado.quantidade = 0;
                }
            }
        });
    }
    static alterarTipoDesbasteControleManejoPomar(ctrl, keyOperacaoDesbaste) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.controleManejoPomarSelecionado !== 'undefined') && ctrl.controleManejoPomarSelecionado &&
                (typeof ctrl.controleManejoPomarSelecionado.operacao !== 'undefined') && ctrl.controleManejoPomarSelecionado.operacao) {
                if ((typeof ctrl.controleManejoPomarSelecionado.desbastes === 'undefined') || !ctrl.controleManejoPomarSelecionado.desbastes) {
                    ctrl.controleManejoPomarSelecionado.desbastes = new Array();
                }
                if ((typeof globalThis.timeoutTipoDesbasteControleManejoPomar !== 'undefined') && globalThis.timeoutTipoDesbasteControleManejoPomar) {
                    clearTimeout(globalThis.timeoutTipoDesbasteControleManejoPomar);
                    globalThis.timeoutTipoDesbasteControleManejoPomar = null;
                }
                globalThis.timeoutTipoDesbasteControleManejoPomar = setTimeout((ctrl, keyOperacaoDesbaste) => {
                    if ((typeof keyOperacaoDesbaste !== 'undefined') && (keyOperacaoDesbaste !== null) &&
                        (ctrl.controleManejoPomarSelecionado.desbastes.length > 1) &&
                        (keyOperacaoDesbaste <= ctrl.controleManejoPomarSelecionado.desbastes.length - 1)) {
                        const desbaste = ctrl.controleManejoPomarSelecionado.desbastes[keyOperacaoDesbaste];
                        const tipoDesbaste = ((typeof desbaste.tipoDesbaste !== 'undefined') && desbaste.tipoDesbaste) ? desbaste.tipoDesbaste : null;
                        const tipoDesbasteDescricao = (tipoDesbaste && (typeof tipoDesbaste.descricao !== 'undefined') && tipoDesbaste.descricao) ? tipoDesbaste.descricao : '';
                        if (window.confirm('Deseja replicar o tipo de desbaste "' + tipoDesbasteDescricao + '" para as outras linhas de materiais genéticos ?')) {
                            angular.forEach(ctrl.controleManejoPomarSelecionado.desbastes, (registro) => {
                                registro.tipoDesbaste = tipoDesbaste;
                            });
                            ctrl.scopeApply();
                        }
                    }
                }, 250, ctrl, keyOperacaoDesbaste);
            }
        });
    }
    static alterarTipoColetaAmostraControleManejoPomar(ctrl, keyOperacaoColetaAmostra) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.controleManejoPomarSelecionado !== 'undefined') && ctrl.controleManejoPomarSelecionado &&
                (typeof ctrl.controleManejoPomarSelecionado.operacao !== 'undefined') && ctrl.controleManejoPomarSelecionado.operacao) {
                if ((typeof ctrl.controleManejoPomarSelecionado.coletasAmostras === 'undefined') || !ctrl.controleManejoPomarSelecionado.coletasAmostras) {
                    ctrl.controleManejoPomarSelecionado.coletasAmostras = new Array();
                }
                if ((typeof globalThis.timeoutTipoColetaAmostraControleManejoPomar !== 'undefined') && globalThis.timeoutTipoColetaAmostraControleManejoPomar) {
                    clearTimeout(globalThis.timeoutTipoColetaAmostraControleManejoPomar);
                    globalThis.timeoutTipoColetaAmostraControleManejoPomar = null;
                }
                globalThis.timeoutTipoColetaAmostraControleManejoPomar = setTimeout((ctrl, keyOperacaoColetaAmostra) => {
                    if ((typeof keyOperacaoColetaAmostra !== 'undefined') && (keyOperacaoColetaAmostra !== null) &&
                        (ctrl.controleManejoPomarSelecionado.coletasAmostras.length > 1) &&
                        (keyOperacaoColetaAmostra <= ctrl.controleManejoPomarSelecionado.coletasAmostras.length - 1)) {
                        const coletaAmostra = ctrl.controleManejoPomarSelecionado.coletasAmostras[keyOperacaoColetaAmostra];
                        const tipoColetaAmostra = ((typeof coletaAmostra.tipoColetaAmostra !== 'undefined') && coletaAmostra.tipoColetaAmostra) ? coletaAmostra.tipoColetaAmostra : null;
                        const tipoColetaAmostraDescricao = (tipoColetaAmostra && (typeof tipoColetaAmostra.descricao !== 'undefined') && tipoColetaAmostra.descricao) ? tipoColetaAmostra.descricao : '';
                        if (window.confirm('Deseja replicar o tipo de amostra "' + tipoColetaAmostraDescricao + '" para as outras linhas de materiais genéticos ?')) {
                            angular.forEach(ctrl.controleManejoPomarSelecionado.coletasAmostras, (registro) => {
                                registro.tipoColetaAmostra = tipoColetaAmostra;
                            });
                            ctrl.scopeApply();
                        }
                    }
                }, 250, ctrl, keyOperacaoColetaAmostra);
            }
        });
    }
    static alterarFinalidadeColetaAmostraControleManejoPomar(ctrl, keyOperacaoColetaAmostra) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.controleManejoPomarSelecionado !== 'undefined') && ctrl.controleManejoPomarSelecionado &&
                (typeof ctrl.controleManejoPomarSelecionado.operacao !== 'undefined') && ctrl.controleManejoPomarSelecionado.operacao) {
                if ((typeof ctrl.controleManejoPomarSelecionado.coletasAmostras === 'undefined') || !ctrl.controleManejoPomarSelecionado.coletasAmostras) {
                    ctrl.controleManejoPomarSelecionado.coletasAmostras = new Array();
                }
                if ((typeof globalThis.timeoutFinalidadeColetaAmostraControleManejoPomar !== 'undefined') && globalThis.timeoutFinalidadeColetaAmostraControleManejoPomar) {
                    clearTimeout(globalThis.timeoutFinalidadeColetaAmostraControleManejoPomar);
                    globalThis.timeoutFinalidadeColetaAmostraControleManejoPomar = null;
                }
                globalThis.timeoutFinalidadeColetaAmostraControleManejoPomar = setTimeout((ctrl, keyOperacaoColetaAmostra) => {
                    if ((typeof keyOperacaoColetaAmostra !== 'undefined') && (keyOperacaoColetaAmostra !== null) &&
                        (ctrl.controleManejoPomarSelecionado.coletasAmostras.length > 1) &&
                        (keyOperacaoColetaAmostra <= ctrl.controleManejoPomarSelecionado.coletasAmostras.length - 1)) {
                        const coletaAmostra = ctrl.controleManejoPomarSelecionado.coletasAmostras[keyOperacaoColetaAmostra];
                        const finalidadeColetaAmostra = ((typeof coletaAmostra.finalidadeColetaAmostra !== 'undefined') && coletaAmostra.finalidadeColetaAmostra) ? coletaAmostra.finalidadeColetaAmostra : null;
                        const finalidadeColetaAmostraDescricao = (finalidadeColetaAmostra && (typeof finalidadeColetaAmostra.descricao !== 'undefined') && finalidadeColetaAmostra.descricao) ? finalidadeColetaAmostra.descricao : '';
                        if (window.confirm('Deseja replicar a finalidade da amostra "' + finalidadeColetaAmostraDescricao + '" para as outras linhas de materiais genéticos ?')) {
                            angular.forEach(ctrl.controleManejoPomarSelecionado.coletasAmostras, (registro) => {
                                registro.finalidadeColetaAmostra = finalidadeColetaAmostra;
                            });
                            ctrl.scopeApply();
                        }
                    }
                }, 250, ctrl, keyOperacaoColetaAmostra);
            }
        });
    }
    static tirarFotoControleManejoPomar(ctrl, ehGaleria = false) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const foto = yield DispositivoUtil_1.DispositivoUtil.tirarFoto((typeof ehGaleria !== 'undefined') ? ehGaleria : false);
                const fotoId = ArmazenamentoUtil_1.ArmazenamentoUtil.uuid();
                ctrl.listasFotos.fotosCamera.push({
                    uuid: fotoId,
                    referencia: 'fotosControleManejoPomarSelecionado',
                    indiceIndicador: null,
                    indiceCampoIndicador: null,
                    indiceCampoCabecalho: null,
                    indiceCampoRodape: null,
                    controleManejoPomar: true,
                    anexoPomar: false,
                    foto: foto
                });
                ctrl.listasFotos.salvarFoto(fotoId);
                ctrl.scopeApply();
            }
            catch (ex) {
                alert(ex.message);
            }
        });
    }
    static removerFotoControleManejoPomar(ctrl, fotoCamera) {
        return __awaiter(this, void 0, void 0, function* () {
            if (confirm(`Deseja realmente remover essa foto?`)) {
                ctrl.listasFotos.removerFoto(fotoCamera.uuid);
                ctrl.scopeApply();
            }
        });
    }
}
exports.PomarBancoConservacaoControleManejoUtil = PomarBancoConservacaoControleManejoUtil;
