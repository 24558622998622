"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EstoqueBeneficiamentoPinusPolinizacaoPomar = void 0;
const Base_1 = require("./Base");
class EstoqueBeneficiamentoPinusPolinizacaoPomar extends Base_1.Base {
    constructor() {
        super();
        this.id = null;
        this.codigo = null;
        this.pomar = null;
        this.materialGeneticoFeminino = null;
        this.pomarOrigemPolen = null;
        this.materialGeneticoMasculino = null;
        this.quantidadeAtual = null;
        this.usuarioInclusao = null;
        this.dataHoraInclusao = null;
        this.usuarioAlteracao = null;
        this.dataHoraAlteracao = null;
        this.status = true;
    }
}
exports.EstoqueBeneficiamentoPinusPolinizacaoPomar = EstoqueBeneficiamentoPinusPolinizacaoPomar;
