"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfiguracaoGeral = void 0;
const Base_1 = require("./Base");
class ConfiguracaoGeral extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.id = null;
        this.nomeGrupoEmpresa = null;
        this.utilizaExclusaoImportacaoTalhao = null;
        this.envioEmail_SmtpHostName = null;
        this.envioEmail_SmtpHost = null;
        this.envioEmail_SmtpPort = null;
        this.envioEmail_FromMail = null;
        this.envioEmail_SendMail = null;
        this.envioEmail_Password = null;
        this.envioEmail_SSL = null;
        this.envioEmail_TLS = null;
        this.conexaoSGIF_HostName = null;
        this.conexaoSGIF_Port = null;
        this.conexaoSGIF_UserName = null;
        this.conexaoSGIF_Password = null;
        this.conexaoSGIF_BancoSGIFlorestal = null;
        this.conexaoSGIF_BancoBaseFlorestal = null;
        this.conexaoSGIF_BancoSilvicultura = null;
        this.conexaoSGIF_BancoViveiro = null;
        this.conexaoArcGIS_ClientId = null;
        this.conexaoArcGIS_ClientSecret = null;
        this.inativo = false;
        this.envioEmail_PasswordTemp = null;
        this.envioEmail_PasswordFlag = null;
        this.conexaoSGIF_PasswordTemp = null;
        this.conexaoSGIF_PasswordFlag = null;
        this.conexaoArcGIS_ClientSecretTemp = null;
        this.conexaoArcGIS_ClientSecretFlag = null;
    }
}
exports.ConfiguracaoGeral = ConfiguracaoGeral;
