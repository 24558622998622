"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProducaoMudaClonalPinusMiniestaquiaController = void 0;
const angular = require("angular");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const UiGridConstrutor_1 = require("../../../app/construtores/UiGridConstrutor");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const AbstratoController_1 = require("../../../app/AbstratoController");
const ICRUDOperacao_1 = require("../../../app/interfaces/ICRUDOperacao");
const config_app_1 = require("../../../../../config/config.app");
const sweetalert2_1 = require("sweetalert2");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const ProducaoMudaClonalPinusMiniestaquia_1 = require("../../../../../modelos/ProducaoMudaClonalPinusMiniestaquia");
const ProducaoMudaClonalMiniestaquiaAvaliacaoEnraizamento_1 = require("../../../../../modelos/ProducaoMudaClonalMiniestaquiaAvaliacaoEnraizamento");
const ProducaoMudaClonalMiniestaquiaAvaliacaoSobrevivenciaCasaSombra_1 = require("../../../../../modelos/ProducaoMudaClonalMiniestaquiaAvaliacaoSobrevivenciaCasaSombra");
const ProducaoMudaClonalMiniestaquiaAvaliacaoSobrevivenciaAclimatacao_1 = require("../../../../../modelos/ProducaoMudaClonalMiniestaquiaAvaliacaoSobrevivenciaAclimatacao");
const DispositivoUtil_1 = require("../../../../../util/DispositivoUtil");
class ProducaoMudaClonalPinusMiniestaquiaController extends AbstratoController_1.AbstratoController {
    constructor($scope, $injector) {
        super($scope, $injector, new ProducaoMudaClonalPinusMiniestaquia_1.ProducaoMudaClonalPinusMiniestaquia());
        this.path = '';
        this.caminho = '';
        this.tipoOperacao = null;
        this.analisesFiltradas = false;
        this.dadosAvaliacaoEnraizamento = null;
        this.dadosAvaliacaoSobrevivenciaCasaSombra = null;
        this.dadosAvaliacaoSobrevivenciaAclimatacao = null;
        this.posicaoTopoAntesModal = null;
        this.modalAvaliacaoEnraizamento = false;
        this.modalAvaliacaoSobrevivenciaCasaSombra = false;
        this.modalAvaliacaoSobrevivenciaAclimatacao = false;
        this.listaOpcoesAvaliacaoEnraizamento = null;
        this.listaOpcoesAvaliacaoSobrevivenciaCasaSombra = null;
        this.listaOpcoesAvaliacaoSobrevivenciaAclimatacao = null;
        this.listaOpcoesRelatoriosRecomendacao = null;
        this.materiaisGeneticos = [];
        this.producoesMiniestacas = null;
        this.tipoOperacao = ICRUDOperacao_1.CRUDOperacao;
        this.analisesFiltradas = false;
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.analisesFiltradas = false;
            this.modalAvaliacaoEnraizamento = false;
            this.dadosAvaliacaoEnraizamento = null;
            this.modalAvaliacaoSobrevivenciaCasaSombra = false;
            this.dadosAvaliacaoSobrevivenciaCasaSombra = null;
            this.modalAvaliacaoSobrevivenciaAclimatacao = false;
            this.dadosAvaliacaoSobrevivenciaAclimatacao = null;
            this.path = config_app_1.config.api.caminho;
            this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA;
            if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR ||
                this.operacao === ICRUDOperacao_1.CRUDOperacao.EDITAR ||
                this.operacao === ICRUDOperacao_1.CRUDOperacao.DUPLICAR) {
                this.materiaisGeneticos = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MATERIAL_GENETICO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.materiaisGeneticos = resposta;
                    }
                });
            }
            this.$scope.$apply();
        });
    }
    salvar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.modalAvaliacaoEnraizamento = false;
                this.dadosAvaliacaoEnraizamento = null;
                this.modalAvaliacaoSobrevivenciaCasaSombra = false;
                this.dadosAvaliacaoSobrevivenciaCasaSombra = null;
                this.modalAvaliacaoSobrevivenciaAclimatacao = false;
                this.dadosAvaliacaoSobrevivenciaAclimatacao = null;
                if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA)
                        .criar(this.modelo);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA)
                        .atualizar(this.modelo);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir uma nova Miniestaquia ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir uma nova Miniestaquia ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    visualizar(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const producaoMudaClonalPinusMiniestaquia = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA)
                    .id(id)
                    .ver();
                producaoMudaClonalPinusMiniestaquia.criacao = new Date(producaoMudaClonalPinusMiniestaquia.criacao);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return producaoMudaClonalPinusMiniestaquia;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    remover(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const producaoMudaClonalPinusMiniestaquia = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA)
                    .id(id)
                    .remover();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (producaoMudaClonalPinusMiniestaquia) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const producaoMudaClonalPinusMiniestaquia = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA)
                    .id(this.modelo.id)
                    .restaurar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (producaoMudaClonalPinusMiniestaquia) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosLista(filtro = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                this.path = config_app_1.config.api.caminho;
                this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA;
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                var auxColunas = Array();
                auxColunas.push({
                    name: 'codigo',
                    displayName: 'Código',
                    width: 90,
                });
                auxColunas.push({
                    name: 'data',
                    displayName: 'Data',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 120,
                });
                auxColunas.push({
                    name: 'clone.descricao',
                    displayName: 'Clone',
                    width: 190,
                });
                auxColunas.push({
                    name: 'codigoMiniestaquia',
                    displayName: 'Código Miniestaquia',
                    width: 100,
                });
                auxColunas.push({
                    name: 'numEstacas',
                    displayName: 'Nº de Estacas',
                    width: 140,
                });
                auxColunas.push({
                    name: 'numMiniestacasCepa',
                    displayName: 'Nº Miniestacas/Cepas',
                    width: 140,
                });
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
                const lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA)
                    .filtro(filtro)
                    .listar();
                ctrl.producoesMiniestacas = lista;
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosListaAvaliacaoEnraizamento() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let filtro = {
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    limiteRegistros: 100000
                };
                let lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_ENRAIZAMENTO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_ENRAIZAMENTO)
                    .filtro(filtro)
                    .listar();
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    posicao: 1,
                    cellClass: 'text-center',
                    cellTemplate: `
                    <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                        <button type="button" class="btn btn-icon bx bxs-edit" style="padding-right: .4rem"
                            onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                            ng-click="grid.appScope.getParentController().abrirModalAvaliacaoEnraizamento(row.entity.id)">
                        </button>
                        <button type="button" class="btn btn-icon bx bx-trash" style="padding-left: .4rem"
                            onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                            ng-click="grid.appScope.getParentController().excluirAvaliacaoEnraizamento(row.entity.id)">
                        </button>
                    </div> `
                });
                auxColunas.push({
                    name: 'data',
                    displayName: 'Data',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 120,
                });
                auxColunas.push({
                    name: 'clone.codigoMiniestaquia',
                    displayName: 'Clone',
                    width: 140,
                });
                auxColunas.push({
                    name: 'numEstacas',
                    displayName: 'Nº Estacas Enraizadas',
                    width: 120,
                });
                auxColunas.push({
                    name: 'porcentagemEnraizamento',
                    displayName: 'Enraizamento %',
                    width: 120,
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosListaAvaliacaoSobrevivenciaCasaSombra() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let filtro = {
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    limiteRegistros: 100000
                };
                let lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_CASA_SOMBRA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_CASA_SOMBRA)
                    .filtro(filtro)
                    .listar();
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    posicao: 1,
                    cellClass: 'text-center',
                    cellTemplate: `
                    <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                        <button type="button" class="btn btn-icon bx bxs-edit" style="padding-right: .4rem"
                            onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                            ng-click="grid.appScope.getParentController().abrirModalAvaliacaoSobrevivenciaCasaSombra(row.entity.id)">
                        </button>
                        <button type="button" class="btn btn-icon bx bx-trash" style="padding-left: .4rem"
                            onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                            ng-click="grid.appScope.getParentController().excluirAvaliacaoSobrevivenciaCasaSombra(row.entity.id)">
                        </button>
                    </div> `
                });
                auxColunas.push({
                    name: 'data',
                    displayName: 'Data',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 120,
                });
                auxColunas.push({
                    name: 'clone.codigoMiniestaquia',
                    displayName: 'Clone',
                    width: 140,
                });
                auxColunas.push({
                    name: 'numRestanteMudas',
                    displayName: 'Nº Restante Mudas',
                    width: 120,
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosListaAvaliacaoSobrevivenciaAclimatacao() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let filtro = {
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    limiteRegistros: 100000
                };
                let lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_ACLIMATACAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_ACLIMATACAO)
                    .filtro(filtro)
                    .listar();
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    posicao: 1,
                    cellClass: 'text-center',
                    cellTemplate: `
                    <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                        <button type="button" class="btn btn-icon bx bxs-edit" style="padding-right: .4rem"
                            onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                            ng-click="grid.appScope.getParentController().abrirModalAvaliacaoSobrevivenciaAclimatacao(row.entity.id)">
                        </button>
                        <button type="button" class="btn btn-icon bx bx-trash" style="padding-left: .4rem"
                            onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                            ng-click="grid.appScope.getParentController().excluirAvaliacaoSobrevivenciaAclimatacao(row.entity.id)">
                        </button>
                    </div> `
                });
                auxColunas.push({
                    name: 'data',
                    displayName: 'Data',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 120,
                });
                auxColunas.push({
                    name: 'clone.codigoMiniestaquia',
                    displayName: 'Clone',
                    width: 140,
                });
                auxColunas.push({
                    name: 'numRestanteMudas',
                    displayName: 'Nº Restante Mudas',
                    width: 120,
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    filtrar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    limparFiltro() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    salvarAvaliacaoEnraizamento() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                this.modalAvaliacaoSobrevivenciaCasaSombra = false;
                this.modalAvaliacaoSobrevivenciaAclimatacao = false;
                if (typeof this.dadosAvaliacaoEnraizamento.id === 'undefined' || !this.dadosAvaliacaoEnraizamento.id) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_ENRAIZAMENTO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_ENRAIZAMENTO)
                        .criar(this.dadosAvaliacaoEnraizamento);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_ENRAIZAMENTO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_ENRAIZAMENTO)
                        .atualizar(this.dadosAvaliacaoEnraizamento);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir uma nova Avaliação de Enraizamento ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir uma nova Avaliação de Enraizamento ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.ocultarModalAvaliacaoEnraizamento();
                this.filtrarAnalises(true);
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
                this.ocultarModalAvaliacaoEnraizamento();
            }
            finally {
                this.ocultarModalAvaliacaoEnraizamento();
            }
        });
    }
    abrirModalAvaliacaoEnraizamento(id = null) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (typeof id !== 'undefined' && id) {
                    this.dadosAvaliacaoEnraizamento = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_ENRAIZAMENTO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_ENRAIZAMENTO)
                        .id(id)
                        .ver();
                }
                this.modalAvaliacaoEnraizamento = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalAvaliacaoEnraizamento() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalAvaliacaoEnraizamento = false;
            this.dadosAvaliacaoEnraizamento = new ProducaoMudaClonalMiniestaquiaAvaliacaoEnraizamento_1.ProducaoMudaClonalMiniestaquiaAvaliacaoEnraizamento();
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    excluirAvaliacaoEnraizamento(id) {
        return __awaiter(this, void 0, void 0, function* () {
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            const ctrl = this;
            this.notificacao
                .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                .then((result) => __awaiter(this, void 0, void 0, function* () {
                if (result.value) {
                    CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_ENRAIZAMENTO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_ENRAIZAMENTO)
                        .id(id)
                        .remover();
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.listaOpcoesAvaliacaoEnraizamento = yield ctrl.dadosListaAvaliacaoEnraizamento();
                    ctrl.scopeApply();
                }
            }));
        });
    }
    salvarAvaliacaoSobrevivenciaCasaSombra() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                this.modalAvaliacaoEnraizamento = false;
                this.modalAvaliacaoSobrevivenciaAclimatacao = false;
                if (typeof this.dadosAvaliacaoSobrevivenciaCasaSombra.id === 'undefined' || !this.dadosAvaliacaoSobrevivenciaCasaSombra.id) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_CASA_SOMBRA)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_CASA_SOMBRA)
                        .criar(this.dadosAvaliacaoSobrevivenciaCasaSombra);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_CASA_SOMBRA)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_CASA_SOMBRA)
                        .atualizar(this.dadosAvaliacaoSobrevivenciaCasaSombra);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir uma nova Avaliação de Sobreviência de Casa Sombra ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir uma nova Avaliação de Sobreviência de Casa Sombra ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.ocultarModalAvaliacaoSobrevivenciaCasaSombra();
                this.filtrarAnalises(true);
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
                this.ocultarModalAvaliacaoSobrevivenciaCasaSombra();
            }
            finally {
                this.ocultarModalAvaliacaoSobrevivenciaCasaSombra();
            }
        });
    }
    abrirModalAvaliacaoSobrevivenciaCasaSombra(id = null) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (typeof id !== 'undefined' && id) {
                    this.dadosAvaliacaoSobrevivenciaCasaSombra = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_CASA_SOMBRA)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_CASA_SOMBRA)
                        .id(id)
                        .ver();
                }
                this.modalAvaliacaoSobrevivenciaCasaSombra = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalAvaliacaoSobrevivenciaCasaSombra() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalAvaliacaoSobrevivenciaCasaSombra = false;
            this.dadosAvaliacaoSobrevivenciaCasaSombra = new ProducaoMudaClonalMiniestaquiaAvaliacaoSobrevivenciaCasaSombra_1.ProducaoMudaClonalMiniestaquiaAvaliacaoSobrevivenciaCasaSombra();
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    excluirAvaliacaoSobrevivenciaCasaSombra(id) {
        return __awaiter(this, void 0, void 0, function* () {
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            const ctrl = this;
            this.notificacao
                .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                .then((result) => __awaiter(this, void 0, void 0, function* () {
                if (result.value) {
                    CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_CASA_SOMBRA)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_CASA_SOMBRA)
                        .id(id)
                        .remover();
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.listaOpcoesAvaliacaoSobrevivenciaCasaSombra = yield ctrl.dadosListaAvaliacaoSobrevivenciaCasaSombra();
                    ctrl.scopeApply();
                }
            }));
        });
    }
    salvarAvaliacaoSobrevivenciaAclimatacao() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                this.modalAvaliacaoEnraizamento = false;
                this.modalAvaliacaoSobrevivenciaCasaSombra = false;
                if (typeof this.dadosAvaliacaoSobrevivenciaAclimatacao.id === 'undefined' || !this.dadosAvaliacaoSobrevivenciaAclimatacao.id) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_ACLIMATACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_ACLIMATACAO)
                        .criar(this.dadosAvaliacaoSobrevivenciaAclimatacao);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_ACLIMATACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_ACLIMATACAO)
                        .atualizar(this.dadosAvaliacaoSobrevivenciaAclimatacao);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir uma nova Avaiação de Sobrevivência de Aclimatação ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir uma nova Avaiação de Sobrevivência de Aclimatação ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.ocultarModalAvaliacaoSobrevivenciaAclimatacao();
                this.filtrarAnalises(true);
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
                this.ocultarModalAvaliacaoSobrevivenciaAclimatacao();
            }
            finally {
                this.ocultarModalAvaliacaoSobrevivenciaAclimatacao();
            }
        });
    }
    abrirModalAvaliacaoSobrevivenciaAclimatacao(id = null) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (typeof id !== 'undefined' && id) {
                    this.dadosAvaliacaoSobrevivenciaAclimatacao = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_ACLIMATACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_ACLIMATACAO)
                        .id(id)
                        .ver();
                }
                this.modalAvaliacaoSobrevivenciaAclimatacao = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalAvaliacaoSobrevivenciaAclimatacao() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalAvaliacaoSobrevivenciaAclimatacao = false;
            this.dadosAvaliacaoSobrevivenciaAclimatacao = new ProducaoMudaClonalMiniestaquiaAvaliacaoSobrevivenciaAclimatacao_1.ProducaoMudaClonalMiniestaquiaAvaliacaoSobrevivenciaAclimatacao();
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    excluirAvaliacaoSobrevivenciaAclimatacao(id) {
        return __awaiter(this, void 0, void 0, function* () {
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            const ctrl = this;
            this.notificacao
                .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                .then((result) => __awaiter(this, void 0, void 0, function* () {
                if (result.value) {
                    CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_ACLIMATACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_ACLIMATACAO)
                        .id(id)
                        .remover();
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.listaOpcoesAvaliacaoSobrevivenciaAclimatacao = yield ctrl.dadosListaAvaliacaoSobrevivenciaAclimatacao();
                    ctrl.scopeApply();
                }
            }));
        });
    }
    filtrarAnalises(alterouDados = false) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.analisesFiltradas === 'undefined') || !this.analisesFiltradas || ((typeof alterouDados !== 'undefined') && alterouDados)) {
                this.listaOpcoesAvaliacaoEnraizamento = yield this.dadosListaAvaliacaoEnraizamento();
                this.listaOpcoesAvaliacaoSobrevivenciaCasaSombra = yield this.dadosListaAvaliacaoSobrevivenciaCasaSombra();
                this.listaOpcoesAvaliacaoSobrevivenciaAclimatacao = yield this.dadosListaAvaliacaoSobrevivenciaAclimatacao();
                this.listaOpcoesRelatoriosRecomendacao = null;
                this.analisesFiltradas = true;
                this.scopeApply();
            }
        });
    }
    calcularEnraizamento() {
        return __awaiter(this, void 0, void 0, function* () {
            this.dataEnraizamento();
            if ((typeof this.dadosAvaliacaoEnraizamento.clone !== 'undefined') && this.dadosAvaliacaoEnraizamento.clone &&
                (typeof this.dadosAvaliacaoEnraizamento.numEstacas !== 'undefined') && this.dadosAvaliacaoEnraizamento.numEstacas &&
                (typeof this.dadosAvaliacaoEnraizamento.clone.numEstacas !== 'undefined') && this.dadosAvaliacaoEnraizamento.clone.numEstacas > 0) {
                this.dadosAvaliacaoEnraizamento.porcentagemEnraizamento = Number(((this.dadosAvaliacaoEnraizamento.numEstacas / this.dadosAvaliacaoEnraizamento.clone.numEstacas) * 100).toFixed(2));
            }
        });
    }
    preencherCodigoProducao() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.modelo.data !== 'undefined') && this.modelo.data &&
                (typeof this.modelo.clone !== 'undefined') && this.modelo.clone) {
                this.modelo.codigoMiniestaquia = `${this.modelo.data.toLocaleDateString('pt-BR')} - ${this.modelo.clone.descricao}`;
            }
        });
    }
    dataEnraizamento() {
        if ((typeof this.dadosAvaliacaoEnraizamento.clone !== 'undefined') && this.dadosAvaliacaoEnraizamento.clone &&
            (typeof this.dadosAvaliacaoEnraizamento.clone.data !== 'undefined') && this.dadosAvaliacaoEnraizamento.clone.data) {
            if (typeof this.dadosAvaliacaoEnraizamento.clone.data === 'string') {
                this.dadosAvaliacaoEnraizamento.clone.data = new Date(this.dadosAvaliacaoEnraizamento.clone.data);
            }
            this.dadosAvaliacaoEnraizamento.data = this.dadosAvaliacaoEnraizamento.clone.data;
        }
    }
    dataAvaliacaoCasaSombra() {
        if ((typeof this.dadosAvaliacaoSobrevivenciaCasaSombra.clone !== 'undefined') && this.dadosAvaliacaoSobrevivenciaCasaSombra.clone &&
            (typeof this.dadosAvaliacaoSobrevivenciaCasaSombra.clone.data !== 'undefined') && this.dadosAvaliacaoSobrevivenciaCasaSombra.clone.data) {
            if (typeof this.dadosAvaliacaoSobrevivenciaCasaSombra.clone.data === 'string') {
                this.dadosAvaliacaoSobrevivenciaCasaSombra.clone.data = new Date(this.dadosAvaliacaoSobrevivenciaCasaSombra.clone.data);
            }
            this.dadosAvaliacaoSobrevivenciaCasaSombra.data = this.dadosAvaliacaoSobrevivenciaCasaSombra.clone.data;
        }
    }
    dataAvaliacaoAclimatacao() {
        if ((typeof this.dadosAvaliacaoSobrevivenciaAclimatacao.clone !== 'undefined') && this.dadosAvaliacaoSobrevivenciaAclimatacao.clone &&
            (typeof this.dadosAvaliacaoSobrevivenciaAclimatacao.clone.data !== 'undefined') && this.dadosAvaliacaoSobrevivenciaAclimatacao.clone.data) {
            if (typeof this.dadosAvaliacaoSobrevivenciaAclimatacao.clone.data === 'string') {
                this.dadosAvaliacaoSobrevivenciaAclimatacao.clone.data = new Date(this.dadosAvaliacaoSobrevivenciaAclimatacao.clone.data);
            }
            this.dadosAvaliacaoSobrevivenciaAclimatacao.data = this.dadosAvaliacaoSobrevivenciaAclimatacao.clone.data;
        }
    }
}
exports.ProducaoMudaClonalPinusMiniestaquiaController = ProducaoMudaClonalPinusMiniestaquiaController;
ProducaoMudaClonalPinusMiniestaquiaController.$inject = ['$scope', '$injector'];
