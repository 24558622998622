"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TipoAmostra = void 0;
const Base_1 = require("./Base");
class TipoAmostra extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.descricao = null;
    }
}
exports.TipoAmostra = TipoAmostra;
