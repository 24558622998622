"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TesteCampoCroquiUtil = void 0;
const angular = require("angular");
const CroquiTesteGenetico_1 = require("../../../../../modelos/CroquiTesteGenetico");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const Planilha_1 = require("../../../../../modelos/Planilha");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const DispositivoUtil_1 = require("../../../../../util/DispositivoUtil");
const ArmazenamentoUtil_1 = require("../../../../../util/ArmazenamentoUtil");
const LuckysheetUtil_1 = require("../../../../../util/LuckysheetUtil");
const DataUtil_1 = require("../../../../../util/DataUtil");
const DebugUtil_1 = require("../../../../../util/DebugUtil");
const NumeroUtil_1 = require("../../../../../util/NumeroUtil");
const sweetalert2_1 = require("sweetalert2");
const TextoUtil_1 = require("../../../../../util/TextoUtil");
class TesteCampoCroquiUtil {
    static carregarCroquiTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.croquiTesteGeneticoSelecionado = null;
            if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                return;
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let filtro = {
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    testeGeneticoId: ((ctrl.testeGeneticoSelecionado && ctrl.testeGeneticoSelecionado.id) ? ctrl.testeGeneticoSelecionado.id : null)
                };
                let listaCroquiTesteGenetico = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.CROQUI_TESTE_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CROQUI_TESTE_GENETICO)
                    .filtro(filtro)
                    .listar();
                if ((typeof listaCroquiTesteGenetico !== 'undefined') && listaCroquiTesteGenetico) {
                    angular.forEach(listaCroquiTesteGenetico, (item) => {
                        if ((typeof item !== 'undefined') && item) {
                            if ((typeof item.anexos !== 'undefined') && item.anexos) {
                                let anexosAgrupados = new Array();
                                angular.forEach(item.anexos, (ged) => {
                                    if (!anexosAgrupados.some((o) => o && o.id && (o.id === ged.id))) {
                                        anexosAgrupados.push(ged);
                                    }
                                });
                                item.anexos = anexosAgrupados;
                            }
                            ctrl.croquiTesteGeneticoSelecionado = item;
                        }
                    });
                }
                if (!ctrl.croquiTesteGeneticoSelecionado) {
                    ctrl.croquiTesteGeneticoSelecionado = new CroquiTesteGenetico_1.CroquiTesteGenetico();
                    ctrl.croquiTesteGeneticoSelecionado.uuid = ArmazenamentoUtil_1.ArmazenamentoUtil.uuid();
                    ctrl.croquiTesteGeneticoSelecionado.criacao = new Date();
                    ctrl.croquiTesteGeneticoSelecionado.testeGenetico = ctrl.testeGeneticoSelecionado;
                    ctrl.croquiTesteGeneticoSelecionado.estabelecimento = ((typeof ctrl.testeGeneticoSelecionado.data !== 'undefined') && ctrl.testeGeneticoSelecionado.data) ? DataUtil_1.DataUtil.converterDataParaFormatoMMYYYY(ctrl.testeGeneticoSelecionado.data) : null;
                    ctrl.croquiTesteGeneticoSelecionado.numColunas = 15;
                    ctrl.croquiTesteGeneticoSelecionado.numLinhas = 30;
                    ctrl.croquiTesteGeneticoSelecionado.tamanhoCampo = 6;
                    ctrl.croquiTesteGeneticoSelecionado.dados = [];
                    ctrl.croquiTesteGeneticoSelecionado.anexos = [];
                }
                if ((typeof ctrl.croquiTesteGeneticoSelecionado.anexos !== 'undefined') && ctrl.croquiTesteGeneticoSelecionado.anexos) {
                    let anexosAgrupados = new Array();
                    angular.forEach(ctrl.croquiTesteGeneticoSelecionado.anexos, (ged) => {
                        if (!anexosAgrupados.some((o) => o && o.id && (o.id === ged.id))) {
                            anexosAgrupados.push(ged);
                        }
                    });
                    ctrl.croquiTesteGeneticoSelecionado.anexos = anexosAgrupados;
                }
                ctrl.carregarPlanilhaCroquiTesteGenetico(false);
                LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static carregarPlanilhaCroquiTesteGenetico(ctrl, ignoraSeCarregado = true) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ignoraSeCarregado !== 'undefined') && ignoraSeCarregado &&
                (angular.element('#planilha_croqui_teste_genetico div.luckysheet').length > 0)) {
                return;
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                ctrl.limparPlanilhasCarregadas();
                let planilhaId = null;
                let dadosPlanilha = [];
                let dadosAnexo = null;
                if ((typeof ctrl.croquiTesteGeneticoSelecionado !== 'undefined') && ctrl.croquiTesteGeneticoSelecionado) {
                    if ((typeof ctrl.croquiTesteGeneticoSelecionado.planilhaSelecionada !== 'undefined') && ctrl.croquiTesteGeneticoSelecionado.planilhaSelecionada &&
                        (typeof ctrl.croquiTesteGeneticoSelecionado.planilhaSelecionada.id !== 'undefined') && ctrl.croquiTesteGeneticoSelecionado.planilhaSelecionada.id) {
                        planilhaId = ctrl.croquiTesteGeneticoSelecionado.planilhaSelecionada.id;
                    }
                    else {
                        if ((typeof ctrl.croquiTesteGeneticoSelecionado.planilhas !== 'undefined') && ctrl.croquiTesteGeneticoSelecionado.planilhas) {
                            angular.forEach(ctrl.croquiTesteGeneticoSelecionado.planilhas, (planilha) => {
                                if ((typeof planilha !== 'undefined') && planilha && (typeof planilha.id !== 'undefined') && planilha.id) {
                                    planilhaId = planilha.id;
                                }
                            });
                        }
                    }
                }
                if ((typeof planilhaId !== 'undefined') && planilhaId) {
                    let planilha = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANILHA)
                        .id(planilhaId)
                        .ver();
                    if ((typeof planilha !== 'undefined') && planilha &&
                        (typeof planilha.dados !== 'undefined') && planilha.dados) {
                        dadosPlanilha = LuckysheetUtil_1.LuckysheetUtil.descompactarDadosPlanilha(planilha.dados);
                    }
                }
                ctrl.carregarPlanilha('planilha_croqui_teste_genetico', dadosPlanilha, dadosAnexo);
                LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static exportarCroquiTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                return;
            }
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
            try {
                if ((typeof globalThis.luckysheet === 'undefined') || !globalThis.luckysheet) {
                    throw new Error('Plugin "luckysheet" não carregado.');
                }
                LuckysheetUtil_1.LuckysheetUtil.exportXLSX(globalThis.luckysheet);
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    static importarCroquiTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.exibirModalImportacaoCroquiTesteGenetico();
        });
    }
    static redimensionarPlanilhaCroquiTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha();
        });
    }
    static salvarCroquiTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.testeGeneticoSelecionado || !ctrl.croquiTesteGeneticoSelecionado) {
                    return;
                }
                const ehImportacaoCroquiTesteGenetico = ((typeof ctrl.modalImportacaoCroquiTesteGenetico !== 'undefined') && ctrl.modalImportacaoCroquiTesteGenetico) ? true : false;
                const ehGeracaoCroquiTesteGenetico = ((typeof ctrl.modalGerarCroquiTesteGenetico !== 'undefined') && ctrl.modalGerarCroquiTesteGenetico &&
                    (typeof ctrl.modalGerarCroquiTesteGeneticoDados !== 'undefined') && ctrl.modalGerarCroquiTesteGeneticoDados) ? true : false;
                const dadosGeracaoCroquiTesteGenetico = ehGeracaoCroquiTesteGenetico
                    ? angular.copy(ctrl.modalGerarCroquiTesteGeneticoDados) : null;
                if (ehGeracaoCroquiTesteGenetico && dadosGeracaoCroquiTesteGenetico) {
                    if (!dadosGeracaoCroquiTesteGenetico.quantidadeLinhas || (dadosGeracaoCroquiTesteGenetico.quantidadeLinhas <= 0)) {
                        if (ctrl.ehCelular) {
                            alert(`Preencha a quantidade de linhas! Campo necessário para gerar o croqui.`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                title: 'Preencha a quantidade de linhas!',
                                text: 'Campo \'Quantidade de Linhas\' é necessário para gerar o croqui',
                                icon: 'info',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        return;
                    }
                    if (!dadosGeracaoCroquiTesteGenetico.quantidadeBlocos || (dadosGeracaoCroquiTesteGenetico.quantidadeBlocos <= 0)) {
                        if (ctrl.ehCelular) {
                            alert(`Preencha a quantidade de blocos! Campo necessário para gerar o croqui.`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                title: 'Preencha a quantidade de blocos!',
                                text: 'Campo \'Quantidade de Blocos\' é necessário para gerar o croqui',
                                icon: 'info',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        return;
                    }
                }
                ctrl.modalTratamentoTesteGenetico = false;
                ctrl.modalGerarCroquiTesteGenetico = false;
                ctrl.modalGerarCroquiTesteGeneticoDados = null;
                ctrl.modalImportacaoCroquiTesteGenetico = false;
                ctrl.modalHistoricoCroquiTesteGenetico = false;
                ctrl.modalControleManejoTesteGenetico = false;
                ctrl.modalMedicaoDendrometricaTesteGenetico = false;
                ctrl.modalPlanejamentoMedicaoDendrometricaTesteGenetico = false;
                ctrl.modalPlanilhaMedicaoDendrometricaTesteGenetico = false;
                ctrl.modalSelegenMedicaoDendrometricaTesteGenetico = false;
                ctrl.modalQualidadeMadeiraTesteGenetico = false;
                ctrl.modalAnaliseSelecaoTesteGenetico = false;
                ctrl.modalSumarioAnaliseSelecaoTesteGenetico = false;
                ctrl.modalPlanilhaSelegenAnaliseSelecaoTesteGenetico = false;
                ctrl.modalAnaliseSelegenAnaliseSelecaoTesteGenetico = false;
                ctrl.modalResultadoSelegenAnaliseSelecaoTesteGenetico = false;
                ctrl.modalAnaliseAnoAnaliseSelecaoTesteGenetico = false;
                ctrl.modalAnaliseBlupAnaliseSelecaoTesteGenetico = false;
                ctrl.modalSelecaoGeneticaAnaliseSelecaoTesteGenetico = false;
                ctrl.modalSelecaoCampoAnaliseSelecaoTesteGenetico = false;
                ctrl.modalAnexoAnaliseSelecaoTesteGenetico = false;
                ctrl.modalRelatorioTesteGenetico = false;
                ctrl.modalAnexoTesteGenetico = false;
                ctrl.modalProtocoloTesteGenetico = false;
                ctrl.modalDossieMaterialGenetico = false;
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(ehImportacaoCroquiTesteGenetico ? '.modal-content' : '.card');
                if (ehGeracaoCroquiTesteGenetico && dadosGeracaoCroquiTesteGenetico) {
                    if ((typeof globalThis.luckysheet !== 'undefined') && globalThis.luckysheet) {
                        let tituloPlanilhaCroqui = 'Croqui ' +
                            DataUtil_1.DataUtil.converterDataParaFormatoDDMMYY(DataUtil_1.DataUtil.dataHoraAtual()) + ' ' +
                            DataUtil_1.DataUtil.converterDataParaFormatoHHMMSS(DataUtil_1.DataUtil.dataHoraAtual());
                        let numMateriaisGeneticos = 0;
                        if ((typeof ctrl.materiaisGeneticosFiltrados !== 'undefined') && ctrl.materiaisGeneticosFiltrados && (ctrl.materiaisGeneticosFiltrados.length > 0)) {
                            numMateriaisGeneticos = ctrl.materiaisGeneticosFiltrados.length;
                        }
                        else if ((typeof ctrl.materiaisGeneticos !== 'undefined') && ctrl.materiaisGeneticos && (ctrl.materiaisGeneticos.length > 0)) {
                            numMateriaisGeneticos = ctrl.materiaisGeneticos.length;
                        }
                        let largurasColunasPlanilhaCroqui = {};
                        let bordasCelulasPlanilhaCroqui = [];
                        let mergeCelulasPlanilhaCroqui = {};
                        let dadosPlanilhaCroqui = [];
                        for (let linhaIdx = 0; linhaIdx <= Math.max(250, numMateriaisGeneticos + 10); linhaIdx++) {
                            let linha = [];
                            for (let colunaIdx = 0; colunaIdx <= 60; colunaIdx++) {
                                let valor = null;
                                linha.push(valor);
                            }
                            dadosPlanilhaCroqui.push(linha);
                        }
                        largurasColunasPlanilhaCroqui[0] = 30;
                        largurasColunasPlanilhaCroqui[1] = 60;
                        const descricaoTesteGenetico = ((typeof ctrl.testeGeneticoSelecionado.descricaoDetalhada !== 'undefined') && ctrl.testeGeneticoSelecionado.descricaoDetalhada ? ctrl.testeGeneticoSelecionado.descricaoDetalhada :
                            ((typeof ctrl.testeGeneticoSelecionado.descricao !== 'undefined') && ctrl.testeGeneticoSelecionado.descricao ? ctrl.testeGeneticoSelecionado.descricao : tituloPlanilhaCroqui));
                        dadosPlanilhaCroqui[1][2] = { v: descricaoTesteGenetico, m: descricaoTesteGenetico, bl: 1, ht: 1 };
                        mergeCelulasPlanilhaCroqui['1_2'] = { r: 1, c: 2, rs: 1, cs: 8 };
                        const localTesteGenetico = 'Local: ' +
                            ((typeof ctrl.testeGeneticoSelecionado.localidade !== 'undefined') && ctrl.testeGeneticoSelecionado.localidade && (typeof ctrl.testeGeneticoSelecionado.localidade.descricao !== 'undefined') && ctrl.testeGeneticoSelecionado.localidade.descricao ? ctrl.testeGeneticoSelecionado.localidade.descricao :
                                ((typeof ctrl.testeGeneticoSelecionado.provinciaUf !== 'undefined') && ctrl.testeGeneticoSelecionado.provinciaUf && (typeof ctrl.testeGeneticoSelecionado.provinciaUf.descricao !== 'undefined') && ctrl.testeGeneticoSelecionado.provinciaUf.descricao ? ctrl.testeGeneticoSelecionado.provinciaUf.descricao :
                                    ((typeof ctrl.testeGeneticoSelecionado.pais !== 'undefined') && ctrl.testeGeneticoSelecionado.pais && (typeof ctrl.testeGeneticoSelecionado.pais.descricao !== 'undefined') && ctrl.testeGeneticoSelecionado.pais.descricao ? ctrl.testeGeneticoSelecionado.pais.descricao : '-')));
                        dadosPlanilhaCroqui[3][2] = { v: localTesteGenetico, m: localTesteGenetico, bl: 1, ht: 1 };
                        mergeCelulasPlanilhaCroqui['3_2'] = { r: 3, c: 2, rs: 1, cs: 6 };
                        const dataHoraCroqui = 'Data: ' + DataUtil_1.DataUtil.converterDataParaFormatoDDMMYY(DataUtil_1.DataUtil.dataHoraAtual());
                        dadosPlanilhaCroqui[5][2] = { v: dataHoraCroqui, m: dataHoraCroqui, bl: 1, ht: 1 };
                        mergeCelulasPlanilhaCroqui['5_2'] = { r: 5, c: 2, rs: 1, cs: 6 };
                        const linhaIdxBlocos = 8;
                        const linhaIdxDados = 9;
                        if ((typeof dadosGeracaoCroquiTesteGenetico.quantidadeBlocos !== 'undefined') && dadosGeracaoCroquiTesteGenetico.quantidadeBlocos &&
                            (dadosGeracaoCroquiTesteGenetico.quantidadeBlocos > 0)) {
                            for (let bloco = 1; bloco <= dadosGeracaoCroquiTesteGenetico.quantidadeBlocos; bloco++) {
                                let colunaIdx = bloco + 1;
                                dadosPlanilhaCroqui[linhaIdxBlocos][colunaIdx] = { v: 'BLOCK' + bloco, m: bloco, bl: 1, ht: 0, fc: '#ffffff', bg: '#666666' };
                                bordasCelulasPlanilhaCroqui.push({
                                    row: [linhaIdxBlocos, linhaIdxBlocos],
                                    column: [colunaIdx, colunaIdx]
                                });
                            }
                        }
                        let bordasCelulasPlanilhaCroquiBackup = angular.copy(bordasCelulasPlanilhaCroqui);
                        let dadosPlanilhaCroquiBackup = angular.copy(dadosPlanilhaCroqui);
                        for (let tentativaGeral = 1; tentativaGeral <= 99; tentativaGeral++) {
                            DebugUtil_1.DebugUtil.sleep(25);
                            bordasCelulasPlanilhaCroqui = angular.copy(bordasCelulasPlanilhaCroquiBackup);
                            dadosPlanilhaCroqui = angular.copy(dadosPlanilhaCroquiBackup);
                            for (let bloco = 1; bloco <= dadosGeracaoCroquiTesteGenetico.quantidadeBlocos; bloco++) {
                                let colunaIdx = bloco + 1;
                                for (let linha = 1; linha <= dadosGeracaoCroquiTesteGenetico.quantidadeLinhas; linha++) {
                                    let linhaIdx = linhaIdxDados + linha - 1;
                                    for (let tentativaBloco = 1; tentativaBloco <= (numMateriaisGeneticos * 10); tentativaBloco++) {
                                        let valor = null;
                                        if ((typeof ctrl.materiaisGeneticosFiltrados !== 'undefined') && ctrl.materiaisGeneticosFiltrados && (ctrl.materiaisGeneticosFiltrados.length > 0)) {
                                            for (let tentativaMaterialGenetico = 1; tentativaMaterialGenetico <= (numMateriaisGeneticos * 10); tentativaMaterialGenetico++) {
                                                const idxMaterialGenetico = NumeroUtil_1.NumeroUtil.gerarNumeroAleatorioInt(0, ctrl.materiaisGeneticosFiltrados.length - 1, 'GerarCroquiTesteGenetico');
                                                if ((idxMaterialGenetico >= 0) && (idxMaterialGenetico <= ctrl.materiaisGeneticosFiltrados.length - 1) &&
                                                    (typeof ctrl.materiaisGeneticosFiltrados[idxMaterialGenetico] !== 'undefined') && ctrl.materiaisGeneticosFiltrados[idxMaterialGenetico] &&
                                                    (typeof ctrl.materiaisGeneticosFiltrados[idxMaterialGenetico].descricao !== 'undefined') && ctrl.materiaisGeneticosFiltrados[idxMaterialGenetico].descricao) {
                                                    valor = ctrl.materiaisGeneticosFiltrados[idxMaterialGenetico].descricao;
                                                    break;
                                                }
                                            }
                                        }
                                        else if ((typeof ctrl.materiaisGeneticos !== 'undefined') && ctrl.materiaisGeneticos && (ctrl.materiaisGeneticos.length > 0)) {
                                            for (let tentativaMaterialGenetico = 1; tentativaMaterialGenetico <= (numMateriaisGeneticos * 10); tentativaMaterialGenetico++) {
                                                const idxMaterialGenetico = NumeroUtil_1.NumeroUtil.gerarNumeroAleatorioInt(0, ctrl.materiaisGeneticos.length - 1, 'GerarCroquiTesteGenetico');
                                                if ((idxMaterialGenetico >= 0) && (idxMaterialGenetico <= ctrl.materiaisGeneticos.length - 1) &&
                                                    (typeof ctrl.materiaisGeneticos[idxMaterialGenetico] !== 'undefined') && ctrl.materiaisGeneticos[idxMaterialGenetico] &&
                                                    (typeof ctrl.materiaisGeneticos[idxMaterialGenetico].descricao !== 'undefined') && ctrl.materiaisGeneticos[idxMaterialGenetico].descricao &&
                                                    (ctrl.materiaisGeneticos[idxMaterialGenetico].descricao.length <= 5)) {
                                                    valor = ctrl.materiaisGeneticos[idxMaterialGenetico].descricao;
                                                    break;
                                                }
                                            }
                                        }
                                        if ((typeof valor === 'undefined') || !valor) {
                                            if (numMateriaisGeneticos > 0) {
                                                continue;
                                            }
                                        }
                                        if ((typeof valor !== 'undefined') && valor) {
                                            const linhaIdxAcima = linhaIdx - 1;
                                            const linhaIdxAbaixo = linhaIdx + 1;
                                            const colunaIdxEsquerda = colunaIdx - 1;
                                            const colunaIdxDireita = colunaIdx + 1;
                                            if (linhaIdxAcima >= linhaIdxDados) {
                                                if ((typeof dadosPlanilhaCroqui[linhaIdxAcima] !== 'undefined') && dadosPlanilhaCroqui[linhaIdxAcima] &&
                                                    (typeof dadosPlanilhaCroqui[linhaIdxAcima][colunaIdxEsquerda] !== 'undefined') && dadosPlanilhaCroqui[linhaIdxAcima][colunaIdxEsquerda] &&
                                                    (typeof dadosPlanilhaCroqui[linhaIdxAcima][colunaIdxEsquerda].v !== 'undefined') && dadosPlanilhaCroqui[linhaIdxAcima][colunaIdxEsquerda].v &&
                                                    (dadosPlanilhaCroqui[linhaIdxAcima][colunaIdxEsquerda].v === valor)) {
                                                    continue;
                                                }
                                                if ((typeof dadosPlanilhaCroqui[linhaIdxAcima] !== 'undefined') && dadosPlanilhaCroqui[linhaIdxAcima] &&
                                                    (typeof dadosPlanilhaCroqui[linhaIdxAcima][colunaIdx] !== 'undefined') && dadosPlanilhaCroqui[linhaIdxAcima][colunaIdx] &&
                                                    (typeof dadosPlanilhaCroqui[linhaIdxAcima][colunaIdx].v !== 'undefined') && dadosPlanilhaCroqui[linhaIdxAcima][colunaIdx].v &&
                                                    (dadosPlanilhaCroqui[linhaIdxAcima][colunaIdx].v === valor)) {
                                                    continue;
                                                }
                                                if ((typeof dadosPlanilhaCroqui[linhaIdxAcima] !== 'undefined') && dadosPlanilhaCroqui[linhaIdxAcima] &&
                                                    (typeof dadosPlanilhaCroqui[linhaIdxAcima][colunaIdxDireita] !== 'undefined') && dadosPlanilhaCroqui[linhaIdxAcima][colunaIdxDireita] &&
                                                    (typeof dadosPlanilhaCroqui[linhaIdxAcima][colunaIdxDireita].v !== 'undefined') && dadosPlanilhaCroqui[linhaIdxAcima][colunaIdxDireita].v &&
                                                    (dadosPlanilhaCroqui[linhaIdxAcima][colunaIdxDireita].v === valor)) {
                                                    continue;
                                                }
                                            }
                                            if ((typeof dadosPlanilhaCroqui[linhaIdx] !== 'undefined') && dadosPlanilhaCroqui[linhaIdx] &&
                                                (typeof dadosPlanilhaCroqui[linhaIdx][colunaIdxEsquerda] !== 'undefined') && dadosPlanilhaCroqui[linhaIdx][colunaIdxEsquerda] &&
                                                (typeof dadosPlanilhaCroqui[linhaIdx][colunaIdxEsquerda].v !== 'undefined') && dadosPlanilhaCroqui[linhaIdx][colunaIdxEsquerda].v &&
                                                (dadosPlanilhaCroqui[linhaIdx][colunaIdxEsquerda].v === valor)) {
                                                continue;
                                            }
                                            if ((typeof dadosPlanilhaCroqui[linhaIdx] !== 'undefined') && dadosPlanilhaCroqui[linhaIdx] &&
                                                (typeof dadosPlanilhaCroqui[linhaIdx][colunaIdxDireita] !== 'undefined') && dadosPlanilhaCroqui[linhaIdx][colunaIdxDireita] &&
                                                (typeof dadosPlanilhaCroqui[linhaIdx][colunaIdxDireita].v !== 'undefined') && dadosPlanilhaCroqui[linhaIdx][colunaIdxDireita].v &&
                                                (dadosPlanilhaCroqui[linhaIdx][colunaIdxDireita].v === valor)) {
                                                continue;
                                            }
                                            if ((typeof dadosPlanilhaCroqui[linhaIdxAbaixo] !== 'undefined') && dadosPlanilhaCroqui[linhaIdxAbaixo] &&
                                                (typeof dadosPlanilhaCroqui[linhaIdxAbaixo][colunaIdxEsquerda] !== 'undefined') && dadosPlanilhaCroqui[linhaIdxAbaixo][colunaIdxEsquerda] &&
                                                (typeof dadosPlanilhaCroqui[linhaIdxAbaixo][colunaIdxEsquerda].v !== 'undefined') && dadosPlanilhaCroqui[linhaIdxAbaixo][colunaIdxEsquerda].v &&
                                                (dadosPlanilhaCroqui[linhaIdxAbaixo][colunaIdxEsquerda].v === valor)) {
                                                continue;
                                            }
                                            if ((typeof dadosPlanilhaCroqui[linhaIdxAbaixo] !== 'undefined') && dadosPlanilhaCroqui[linhaIdxAbaixo] &&
                                                (typeof dadosPlanilhaCroqui[linhaIdxAbaixo][colunaIdx] !== 'undefined') && dadosPlanilhaCroqui[linhaIdxAbaixo][colunaIdx] &&
                                                (typeof dadosPlanilhaCroqui[linhaIdxAbaixo][colunaIdx].v !== 'undefined') && dadosPlanilhaCroqui[linhaIdxAbaixo][colunaIdx].v &&
                                                (dadosPlanilhaCroqui[linhaIdxAbaixo][colunaIdx].v === valor)) {
                                                continue;
                                            }
                                            if ((typeof dadosPlanilhaCroqui[linhaIdxAbaixo] !== 'undefined') && dadosPlanilhaCroqui[linhaIdxAbaixo] &&
                                                (typeof dadosPlanilhaCroqui[linhaIdxAbaixo][colunaIdxDireita] !== 'undefined') && dadosPlanilhaCroqui[linhaIdxAbaixo][colunaIdxDireita] &&
                                                (typeof dadosPlanilhaCroqui[linhaIdxAbaixo][colunaIdxDireita].v !== 'undefined') && dadosPlanilhaCroqui[linhaIdxAbaixo][colunaIdxDireita].v &&
                                                (dadosPlanilhaCroqui[linhaIdxAbaixo][colunaIdxDireita].v === valor)) {
                                                continue;
                                            }
                                            let achouMaterialGeneticoBloco = false;
                                            for (let linhaIdx2 = Math.max(linhaIdxDados, linhaIdx + 1 - numMateriaisGeneticos); linhaIdx2 < linhaIdx; linhaIdx2++) {
                                                if ((typeof dadosPlanilhaCroqui[linhaIdx2] !== 'undefined') && dadosPlanilhaCroqui[linhaIdx2] &&
                                                    (typeof dadosPlanilhaCroqui[linhaIdx2][colunaIdx] !== 'undefined') && dadosPlanilhaCroqui[linhaIdx2][colunaIdx] &&
                                                    (typeof dadosPlanilhaCroqui[linhaIdx2][colunaIdx].v !== 'undefined') && dadosPlanilhaCroqui[linhaIdx2][colunaIdx].v &&
                                                    (dadosPlanilhaCroqui[linhaIdx2][colunaIdx].v === valor)) {
                                                    achouMaterialGeneticoBloco = true;
                                                    break;
                                                }
                                            }
                                            if (achouMaterialGeneticoBloco) {
                                                continue;
                                            }
                                        }
                                        dadosPlanilhaCroqui[linhaIdx][colunaIdx] = { v: valor, m: valor, bl: 1, ht: 0 };
                                        break;
                                    }
                                    bordasCelulasPlanilhaCroqui.push({
                                        row: [linhaIdx, linhaIdx],
                                        column: [colunaIdx, colunaIdx]
                                    });
                                }
                            }
                            let temDadosEmBranco = false;
                            for (let bloco = 1; bloco <= dadosGeracaoCroquiTesteGenetico.quantidadeBlocos; bloco++) {
                                let colunaIdx = bloco + 1;
                                for (let linha = 1; linha <= dadosGeracaoCroquiTesteGenetico.quantidadeLinhas; linha++) {
                                    let linhaIdx = linhaIdxDados + linha - 1;
                                    let celulaEmBranco = true;
                                    if ((typeof dadosPlanilhaCroqui[linhaIdx] !== 'undefined') && dadosPlanilhaCroqui[linhaIdx] &&
                                        (typeof dadosPlanilhaCroqui[linhaIdx][colunaIdx] !== 'undefined') && dadosPlanilhaCroqui[linhaIdx][colunaIdx] &&
                                        (typeof dadosPlanilhaCroqui[linhaIdx][colunaIdx].v !== 'undefined') && dadosPlanilhaCroqui[linhaIdx][colunaIdx].v) {
                                        celulaEmBranco = false;
                                    }
                                    if (celulaEmBranco) {
                                        temDadosEmBranco = true;
                                        break;
                                    }
                                }
                                if (temDadosEmBranco) {
                                    break;
                                }
                            }
                            if (!temDadosEmBranco) {
                                break;
                            }
                        }
                        bordasCelulasPlanilhaCroquiBackup = null;
                        dadosPlanilhaCroquiBackup = null;
                        for (let linha = 1; linha <= dadosGeracaoCroquiTesteGenetico.quantidadeLinhas; linha++) {
                            let linhaIdx = linhaIdxDados + linha - 1;
                            let colunaIdx = 1;
                            dadosPlanilhaCroqui[linhaIdx][colunaIdx] = { v: linha, m: linha, bl: 0, ht: 0 };
                        }
                        if ((typeof dadosGeracaoCroquiTesteGenetico.pilhas !== 'undefined') && dadosGeracaoCroquiTesteGenetico.pilhas) {
                            let linhasPilhasCroqui = Array();
                            angular.forEach(dadosGeracaoCroquiTesteGenetico.pilhas, (pilhaCroqui) => {
                                if ((typeof pilhaCroqui !== 'undefined') && pilhaCroqui) {
                                    let pilhaCroquiId = ((typeof pilhaCroqui === 'string') ? pilhaCroqui : ((typeof pilhaCroqui.id !== 'undefined') && pilhaCroqui.id ? pilhaCroqui.id : null));
                                    let posicaoPilhaCroqui = TextoUtil_1.TextoUtil.extrairListaNumeros(pilhaCroquiId);
                                    if (posicaoPilhaCroqui && (posicaoPilhaCroqui.length > 0) &&
                                        (typeof posicaoPilhaCroqui[0] !== 'undefined') && (posicaoPilhaCroqui[0] !== null)) {
                                        linhasPilhasCroqui.push(posicaoPilhaCroqui[0] + 1);
                                    }
                                }
                            });
                            if (linhasPilhasCroqui.length > 0) {
                                linhasPilhasCroqui.sort((a, b) => (a > b) ? 1 : ((a < b) ? -1 : 0));
                                let linhaIdx = linhaIdxDados;
                                let linhasRealocadas = 0;
                                const ultimaPosicaoLinhaIdx = (linhaIdxDados + dadosGeracaoCroquiTesteGenetico.quantidadeLinhas + linhasPilhasCroqui.length);
                                while (linhaIdx <= ultimaPosicaoLinhaIdx) {
                                    let linha = linhaIdx + 1 - linhaIdxDados - linhasRealocadas;
                                    if (linhasPilhasCroqui.includes(linha)) {
                                        for (let linhaIdx2 = ultimaPosicaoLinhaIdx; linhaIdx2 >= linhaIdx; linhaIdx2--) {
                                            for (let colunaIdx = 1; colunaIdx <= dadosGeracaoCroquiTesteGenetico.quantidadeBlocos + 2; colunaIdx++) {
                                                if ((typeof dadosPlanilhaCroqui[linhaIdx2] !== 'undefined') && dadosPlanilhaCroqui[linhaIdx2] &&
                                                    (typeof dadosPlanilhaCroqui[linhaIdx2][colunaIdx] !== 'undefined') && dadosPlanilhaCroqui[linhaIdx2][colunaIdx]) {
                                                    dadosPlanilhaCroqui[linhaIdx2 + 1][colunaIdx] = dadosPlanilhaCroqui[linhaIdx2][colunaIdx];
                                                    delete dadosPlanilhaCroqui[linhaIdx2][colunaIdx];
                                                    let bordaCelulaPlanilhaCroqui = bordasCelulasPlanilhaCroqui.find(x => x &&
                                                        x.row && x.row.includes(linhaIdx2) &&
                                                        x.column && x.column.includes(colunaIdx));
                                                    if ((typeof bordaCelulaPlanilhaCroqui !== 'undefined') && bordaCelulaPlanilhaCroqui) {
                                                        bordaCelulaPlanilhaCroqui.row = [linhaIdx2 + 1, linhaIdx2 + 1];
                                                    }
                                                }
                                            }
                                        }
                                        dadosPlanilhaCroqui[linhaIdx][2] = { v: 'Pilha', m: 'Pilha', bl: 1, ht: 0, fc: '#000000', bg: '#e6e6e6' };
                                        mergeCelulasPlanilhaCroqui[linhaIdx + '_2'] = { r: linhaIdx, c: 2, rs: 1, cs: dadosGeracaoCroquiTesteGenetico.quantidadeBlocos };
                                        bordasCelulasPlanilhaCroqui.push({ row: [linhaIdx, linhaIdx], column: [2, dadosGeracaoCroquiTesteGenetico.quantidadeBlocos + 1] });
                                        linhaIdx++;
                                        linhasRealocadas++;
                                    }
                                    linhaIdx++;
                                }
                            }
                        }
                        globalThis.luckysheet.setSheetAdd({
                            sheetObject: {
                                name: tituloPlanilhaCroqui,
                                data: dadosPlanilhaCroqui,
                                config: {
                                    columnlen: largurasColunasPlanilhaCroqui,
                                    merge: mergeCelulasPlanilhaCroqui,
                                    borderInfo: [{
                                            rangeType: 'range',
                                            borderType: 'border-all',
                                            color: '#000',
                                            style: '1',
                                            range: bordasCelulasPlanilhaCroqui
                                        }]
                                }
                            },
                            order: 0
                        });
                    }
                }
                if ((typeof ctrl.croquiTesteGeneticoSelecionado === 'undefined') || !ctrl.croquiTesteGeneticoSelecionado) {
                    ctrl.croquiTesteGeneticoSelecionado = new CroquiTesteGenetico_1.CroquiTesteGenetico();
                }
                if ((typeof ctrl.croquiTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.croquiTesteGeneticoSelecionado.planilhas) {
                    ctrl.croquiTesteGeneticoSelecionado.planilhas = new Array();
                }
                let planilha = new Planilha_1.Planilha();
                planilha.status = true;
                planilha.modulo = 'CroquiTesteGenetico';
                planilha.operacao = ehImportacaoCroquiTesteGenetico ? 'Importar' : 'Salvar';
                planilha.nome = LuckysheetUtil_1.LuckysheetUtil.nomePlanilha();
                planilha.usuarioInclusao = ctrl.usuarioSimplificado();
                planilha.dataHoraInclusao = new Date();
                planilha.gzcompress = true;
                planilha.dados = LuckysheetUtil_1.LuckysheetUtil.compactarDadosPlanilha();
                planilha = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANILHA)
                    .criar(planilha);
                ctrl.croquiTesteGeneticoSelecionado.planilhas.push(planilha);
                ctrl.croquiTesteGeneticoSelecionado.planilhaSelecionada = planilha;
                ctrl.croquiTesteGeneticoSelecionado.dados = '[]';
                if (!ctrl.croquiTesteGeneticoSelecionado.id) {
                    ctrl.croquiTesteGeneticoSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.CROQUI_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CROQUI_TESTE_GENETICO)
                        .criar(ctrl.croquiTesteGeneticoSelecionado);
                }
                else {
                    ctrl.croquiTesteGeneticoSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.CROQUI_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CROQUI_TESTE_GENETICO)
                        .atualizar(ctrl.croquiTesteGeneticoSelecionado);
                }
                if ((typeof ctrl.croquiTesteGeneticoSelecionado.anexos !== 'undefined') && ctrl.croquiTesteGeneticoSelecionado.anexos) {
                    let anexosAgrupados = new Array();
                    angular.forEach(ctrl.croquiTesteGeneticoSelecionado.anexos, (ged) => {
                        if (!anexosAgrupados.some((o) => o && o.id && (o.id === ged.id))) {
                            anexosAgrupados.push(ged);
                        }
                    });
                    ctrl.croquiTesteGeneticoSelecionado.anexos = anexosAgrupados;
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.ocultarModalGerarCroquiTesteGenetico();
                ctrl.ocultarModalImportacaoCroquiTesteGenetico();
                ctrl.ocultarModalHistoricoCroquiTesteGenetico();
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static exibirModalGerarCroquiTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.testeGeneticoSelecionado === 'undefined') || !ctrl.testeGeneticoSelecionado) {
                return;
            }
            try {
                ctrl.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                ctrl.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            ctrl.modalGerarCroquiTesteGenetico = true;
            ctrl.modalGerarCroquiTesteGeneticoDados = {
                quantidadeLinhas: null,
                quantidadeBlocos: null,
                espacamento: (ctrl.testeGeneticoSelecionado.implantacaoPomarEspacamento ? ctrl.testeGeneticoSelecionado.implantacaoPomarEspacamento : '10 x 10 x 10 m'),
                pilhas: []
            };
            if ((typeof ctrl.listaTratamentosTesteGenetico !== 'undefined') && ctrl.listaTratamentosTesteGenetico &&
                (ctrl.listaTratamentosTesteGenetico.length > 0)) {
                ctrl.modalGerarCroquiTesteGeneticoDados.quantidadeLinhas = ctrl.listaTratamentosTesteGenetico.length;
            }
            else if ((typeof ctrl.testeGeneticoSelecionado.delineamentoTratamentos !== 'undefined') && ctrl.testeGeneticoSelecionado.delineamentoTratamentos &&
                (ctrl.testeGeneticoSelecionado.delineamentoTratamentos > 0)) {
                ctrl.modalGerarCroquiTesteGeneticoDados.quantidadeLinhas = ctrl.testeGeneticoSelecionado.delineamentoTratamentos;
            }
            if ((typeof ctrl.testeGeneticoSelecionado.numBlocos !== 'undefined') && ctrl.testeGeneticoSelecionado.numBlocos &&
                (ctrl.testeGeneticoSelecionado.numBlocos > 0)) {
                ctrl.modalGerarCroquiTesteGeneticoDados.quantidadeBlocos = ctrl.testeGeneticoSelecionado.numBlocos;
            }
            ctrl.filtraPilhasCroqui();
            ctrl.scopeApply();
        });
    }
    static ocultarModalGerarCroquiTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.modalGerarCroquiTesteGenetico = false;
            ctrl.modalGerarCroquiTesteGeneticoDados = null;
            globalThis.posicaoTopoAntesModal = ctrl.posicaoTopoAntesModal;
            ctrl.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                        (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    static salvarImportacaoCroquiTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
                if ((typeof ctrl.modalImportacaoCroquiTesteGeneticoAnexos !== 'undefined') && ctrl.modalImportacaoCroquiTesteGeneticoAnexos &&
                    (ctrl.modalImportacaoCroquiTesteGeneticoAnexos.length > 0)) {
                    if ((typeof ctrl.croquiTesteGeneticoSelecionado === 'undefined') || !ctrl.croquiTesteGeneticoSelecionado) {
                        ctrl.croquiTesteGeneticoSelecionado = new CroquiTesteGenetico_1.CroquiTesteGenetico();
                    }
                    if ((typeof ctrl.croquiTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.croquiTesteGeneticoSelecionado.planilhas) {
                        ctrl.croquiTesteGeneticoSelecionado.planilhas = new Array();
                    }
                    if ((typeof ctrl.croquiTesteGeneticoSelecionado.anexos === 'undefined') || !ctrl.croquiTesteGeneticoSelecionado.anexos) {
                        ctrl.croquiTesteGeneticoSelecionado.anexos = new Array();
                    }
                    let anexoSelecionado = null;
                    angular.forEach(ctrl.modalImportacaoCroquiTesteGeneticoAnexos, (anexo) => {
                        ctrl.croquiTesteGeneticoSelecionado.anexos.push(anexo);
                        anexoSelecionado = anexo;
                    });
                    yield ctrl.limparPlanilhasCarregadas();
                    yield ctrl.carregarPlanilha('planilha_croqui_teste_genetico', null, anexoSelecionado);
                    setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                        ctrl.salvarCroquiTesteGenetico();
                    }), 1500, ctrl);
                }
                ctrl.modalImportacaoCroquiTesteGenetico = false;
                ctrl.modalImportacaoCroquiTesteGeneticoAnexos = null;
                ctrl.modalHistoricoCroquiTesteGenetico = false;
                yield TesteCampoCroquiUtil.ocultarModalImportacaoCroquiTesteGenetico(ctrl);
                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static exibirModalImportacaoCroquiTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.testeGeneticoSelecionado === 'undefined') || !ctrl.testeGeneticoSelecionado) {
                return;
            }
            try {
                ctrl.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                ctrl.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            ctrl.modalImportacaoCroquiTesteGenetico = true;
            ctrl.modalImportacaoCroquiTesteGeneticoAnexos = new Array();
            ctrl.scopeApply();
        });
    }
    static ocultarModalImportacaoCroquiTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.modalImportacaoCroquiTesteGenetico = false;
            globalThis.posicaoTopoAntesModal = ctrl.posicaoTopoAntesModal;
            ctrl.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                        (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    static exibirModalHistoricoCroquiTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.testeGeneticoSelecionado === 'undefined') || !ctrl.testeGeneticoSelecionado) {
                return;
            }
            try {
                ctrl.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                ctrl.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            ctrl.modalHistoricoCroquiTesteGenetico = true;
            ctrl.scopeApply();
        });
    }
    static ocultarModalHistoricoCroquiTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.modalHistoricoCroquiTesteGenetico = false;
            globalThis.posicaoTopoAntesModal = ctrl.posicaoTopoAntesModal;
            ctrl.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                        (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    static selecionarHistoricoCroquiTesteGenetico(ctrl, keyPlanilha) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                return;
            }
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
            try {
                if ((typeof ctrl.croquiTesteGeneticoSelecionado === 'undefined') || !ctrl.croquiTesteGeneticoSelecionado) {
                    ctrl.croquiTesteGeneticoSelecionado = new CroquiTesteGenetico_1.CroquiTesteGenetico();
                }
                if ((typeof ctrl.croquiTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.croquiTesteGeneticoSelecionado.planilhas) {
                    ctrl.croquiTesteGeneticoSelecionado.planilhas = new Array();
                }
                if ((typeof keyPlanilha !== 'undefined') && (keyPlanilha !== null) && (keyPlanilha >= 0) &&
                    (keyPlanilha <= ctrl.croquiTesteGeneticoSelecionado.planilhas.length - 1)) {
                    const planilhaSelecionada = ctrl.croquiTesteGeneticoSelecionado.planilhas[keyPlanilha];
                    ctrl.croquiTesteGeneticoSelecionado.planilhaSelecionada = planilhaSelecionada;
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.CROQUI_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CROQUI_TESTE_GENETICO)
                        .atualizar(ctrl.croquiTesteGeneticoSelecionado);
                    yield ctrl.limparPlanilhasCarregadas();
                    setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                        ctrl.carregarPlanilhaCroquiTesteGenetico(false);
                    }), 500, ctrl);
                    ctrl.modalImportacaoCroquiTesteGenetico = false;
                    ctrl.modalImportacaoCroquiTesteGeneticoAnexos = null;
                    ctrl.modalHistoricoCroquiTesteGenetico = false;
                }
                else {
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.notificacao.alerta('Não foi possível carregar a planilha.', 'A planilha selecionada não é válida ou foi removida.');
                    return;
                }
                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
}
exports.TesteCampoCroquiUtil = TesteCampoCroquiUtil;
