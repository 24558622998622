"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TipoNaturalPlantada = void 0;
class TipoNaturalPlantada {
    constructor() {
        this.id = null;
        this.descricao = null;
    }
    static NATURAL() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.tipoNaturalPlantada_Natural === 'undefined') || (globalThis.globalConstants.tipoNaturalPlantada_Natural === null)) {
            let tipoNaturalPlantada_Natural = new TipoNaturalPlantada();
            tipoNaturalPlantada_Natural.id = 'NATUAL';
            tipoNaturalPlantada_Natural.descricao = 'Natural';
            globalThis.globalConstants.tipoNaturalPlantada_Natural = tipoNaturalPlantada_Natural;
        }
        return globalThis.globalConstants.tipoNaturalPlantada_Natural;
    }
    static PLANTADA() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.tipoNaturalPlantada_Plantada === 'undefined') || (globalThis.globalConstants.tipoNaturalPlantada_Plantada === null)) {
            let tipoNaturalPlantada_Plantada = new TipoNaturalPlantada();
            tipoNaturalPlantada_Plantada.id = 'PLANTADA';
            tipoNaturalPlantada_Plantada.descricao = 'Plantada';
            globalThis.globalConstants.tipoNaturalPlantada_Plantada = tipoNaturalPlantada_Plantada;
        }
        return globalThis.globalConstants.tipoNaturalPlantada_Plantada;
    }
    static LISTA() {
        return [
            TipoNaturalPlantada.NATURAL(),
            TipoNaturalPlantada.PLANTADA()
        ];
    }
}
exports.TipoNaturalPlantada = TipoNaturalPlantada;
