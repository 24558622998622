"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CroquiTesteGenetico = void 0;
const Base_1 = require("./Base");
class CroquiTesteGenetico extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.id = null;
        this.codigo = null;
        this.testeGenetico = null;
        this.titulo = null;
        this.estabelecimento = null;
        this.espacamento = null;
        this.numLinhas = null;
        this.numColunas = null;
        this.tamanhoCampo = null;
        this.dados = [];
        this.planilhas = [];
        this.planilhaSelecionada = null;
        this.anexos = [];
    }
}
exports.CroquiTesteGenetico = CroquiTesteGenetico;
