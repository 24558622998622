"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Notificacao = void 0;
const Base_1 = require("./Base");
class Notificacao extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.titulo = null;
        this.descricao = null;
        this.usuario = null;
        this.listaUsuarios = [];
        this.dataInicio = null;
        this.uuidLancamentoOrigem = null;
        this.lido = false;
    }
}
exports.Notificacao = Notificacao;
