"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TipoAplicacao = void 0;
const Base_1 = require("./Base");
class TipoAplicacao extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.descricao = null;
        this.ehMecanizada = null;
        this.ehManual = null;
    }
}
exports.TipoAplicacao = TipoAplicacao;
