"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaterialGeneticoController = void 0;
const angular = require("angular");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const UiGridConstrutor_1 = require("../../../app/construtores/UiGridConstrutor");
const AbstratoController_1 = require("../../../app/AbstratoController");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const ICRUDOperacao_1 = require("../../../app/interfaces/ICRUDOperacao");
const MaterialGenetico_1 = require("../../../../../modelos/MaterialGenetico");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const config_app_1 = require("../../../../../config/config.app");
const Pais_1 = require("../../../../../modelos/Pais");
const TabelaUtil_1 = require("../../../../../util/TabelaUtil");
const TextoUtil_1 = require("../../../../../util/TextoUtil");
const UiGridUtil_1 = require("../../../../../util/UiGridUtil");
const PlanilhaFactory_1 = require("../../../ferramenta/fabricas/PlanilhaFactory");
const PerfilClimaticoPomar_1 = require("../../../../../modelos/PerfilClimaticoPomar");
const sweetalert2_1 = require("sweetalert2");
class MaterialGeneticoController extends AbstratoController_1.AbstratoController {
    constructor($scope, $injector, planilha) {
        super($scope, $injector, new MaterialGenetico_1.MaterialGenetico());
        this.path = '';
        this.caminho = '';
        this.tipoOperacao = null;
        this.planilha = null;
        this.materialGeneticoSelecionado = null;
        this.materialGeneticoCarregado = false;
        this.generos = [];
        this.especies = [];
        this.geracoes = [];
        this.fontes = [];
        this.provinciasUf = [];
        this.paises = [];
        this.pomares = [];
        this.propagulos = [];
        this.polinizacoes = [];
        this.hibridacoes = [];
        this.conjuntos = [];
        this.dialelos = [];
        this.materiaisGeneticos = [];
        this.modalTestesGeneticos = false;
        this.modalPomares = false;
        this.modalBancoMelhoramentoPomar = false;
        this.modalDossieMaterialGenetico = false;
        this.posicaoTopoAntesModal = null;
        this.listaOpcoesTestesGeneticos = null;
        this.listaOpcoesPomares = null;
        this.listaOpcoesBancoMelhoramentoPomar = null;
        this.materialGeneticoFiltrado = null;
        this.pomarFiltrado = null;
        this.tipoOperacao = ICRUDOperacao_1.CRUDOperacao;
        this.planilha = planilha;
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.path = config_app_1.config.api.caminho;
            this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO;
            this.importar = true;
            if ((this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) || (this.operacao === ICRUDOperacao_1.CRUDOperacao.EDITAR) || (this.operacao === ICRUDOperacao_1.CRUDOperacao.DUPLICAR)) {
                this.generos = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.GENERO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.GENERO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.generos = resposta;
                        ctrl.scopeApply();
                    }
                });
                this.especies = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESPECIE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESPECIE)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.especies = resposta;
                        ctrl.scopeApply();
                    }
                });
                this.geracoes = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.GERACAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.GERACAO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.geracoes = resposta;
                        ctrl.scopeApply();
                    }
                });
                this.fontes = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.FONTE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.FONTE)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.fontes = resposta;
                        ctrl.scopeApply();
                    }
                });
                this.provinciasUf = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROVINCIA_UF)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROVINCIA_UF)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.provinciasUf = resposta;
                        ctrl.scopeApply();
                    }
                });
                this.paises = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PAIS)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PAIS)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.paises = resposta;
                        ctrl.scopeApply();
                    }
                });
                this.pomares = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POMAR)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.pomares = resposta;
                        ctrl.scopeApply();
                    }
                });
                this.propagulos = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROPAGULO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROPAGULO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.propagulos = resposta;
                        ctrl.scopeApply();
                    }
                });
                this.polinizacoes = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POLINIZACAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POLINIZACAO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.polinizacoes = resposta;
                        ctrl.scopeApply();
                    }
                });
                this.hibridacoes = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.HIBRIDACAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.HIBRIDACAO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.hibridacoes = resposta;
                        ctrl.scopeApply();
                    }
                });
                this.conjuntos = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.CONJUNTO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CONJUNTO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.conjuntos = resposta;
                        ctrl.scopeApply();
                    }
                });
                this.dialelos = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.DIALELO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.DIALELO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.dialelos = resposta;
                        ctrl.scopeApply();
                    }
                });
                this.materiaisGeneticos = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MATERIAL_GENETICO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.materiaisGeneticos = resposta;
                        ctrl.scopeApply();
                    }
                });
            }
            this.$scope.$apply();
        });
    }
    salvar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MATERIAL_GENETICO)
                        .criar(this.modelo);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MATERIAL_GENETICO)
                        .atualizar(this.modelo);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir um novo Material Genético ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir um novo Material Genético ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não'
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    visualizar(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const materialGenetico = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MATERIAL_GENETICO)
                    .id(id)
                    .ver();
                materialGenetico.criacao = new Date(materialGenetico.criacao);
                if ((typeof materialGenetico.mae === 'string') && materialGenetico.mae && (materialGenetico.mae.length > 0)) {
                    try {
                        const mae = yield this.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MATERIAL_GENETICO)
                            .id(materialGenetico.mae)
                            .ver();
                        if ((typeof mae !== 'undefined') && mae) {
                            materialGenetico.mae = mae;
                        }
                    }
                    catch (ex) {
                        console.log('ERRO: Não foi possível carregar o material genético mãe, Id: ' + materialGenetico.mae + '.');
                        console.log(ex);
                    }
                }
                if ((typeof materialGenetico.pai === 'string') && materialGenetico.pai && (materialGenetico.pai.length > 0)) {
                    try {
                        const pai = yield this.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MATERIAL_GENETICO)
                            .id(materialGenetico.pai)
                            .ver();
                        if ((typeof pai !== 'undefined') && pai) {
                            materialGenetico.pai = pai;
                        }
                    }
                    catch (ex) {
                        console.log('ERRO: Não foi possível carregar o material genético pai, Id: ' + materialGenetico.mae + '.');
                        console.log(ex);
                    }
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return materialGenetico;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    remover(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const materialGenetico = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MATERIAL_GENETICO)
                    .id(id)
                    .remover();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (materialGenetico) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const materialGenetico = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MATERIAL_GENETICO)
                    .id(this.modelo.id)
                    .restaurar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (materialGenetico) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosLista(filtro = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                this.path = config_app_1.config.api.caminho;
                this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO;
                gridConstrutor.removerTodasColunas();
                var auxColunas = Array();
                auxColunas.push({
                    name: 'descricaoMaterialGenetico',
                    field: 'descricao',
                    displayName: 'Material Genético',
                    width: 150
                });
                auxColunas.push({
                    name: 'generoDescricao',
                    field: 'genero.descricao',
                    displayName: 'Gênero',
                    width: 110
                });
                auxColunas.push({
                    name: 'especieDescricao',
                    field: 'especie.descricao',
                    displayName: 'Espécie',
                    width: 120
                });
                auxColunas.push({
                    name: 'geracaoDescricao',
                    field: 'geracao.descricao',
                    displayName: 'Geração',
                    width: 110
                });
                auxColunas.push({
                    name: 'dialeloDescricao',
                    field: 'dialelo.descricao',
                    displayName: 'Dialelo',
                    width: 120
                });
                auxColunas.push({
                    name: 'tipoTratamentoDescricao',
                    field: 'tipoTratamento.descricao',
                    displayName: 'Tratamento',
                    width: 120
                });
                auxColunas.push({
                    name: 'tipoPopulacaoDescricao',
                    field: 'tipoPopulacao.descricao',
                    displayName: 'População',
                    width: 150
                });
                auxColunas.push({
                    name: 'localidade',
                    field: 'localidade',
                    displayName: 'Local',
                    width: 160
                });
                auxColunas.push({
                    name: 'anoImplantacao',
                    field: 'anoImplantacao',
                    displayName: 'Ano Implantação',
                    width: 140
                });
                auxColunas.push({
                    name: 'regiaoNumRegiao',
                    field: 'regiao.numRegiao',
                    displayName: 'Região',
                    width: 80
                });
                auxColunas.push({
                    name: 'regiaoNome',
                    field: 'regiao.nome',
                    displayName: 'Nome Região',
                    width: 160
                });
                auxColunas.push({
                    name: 'talhaoNumero',
                    field: 'talhao.numero',
                    displayName: 'Talhão',
                    width: 90
                });
                auxColunas.push({
                    name: 'umoDescricao',
                    field: 'umo.descricao',
                    displayName: 'UMO',
                    width: 60
                });
                auxColunas.push({
                    name: 'situacaoDescricao',
                    field: 'situacao.descricao',
                    displayName: 'Situação',
                    width: 110
                });
                auxColunas.push({
                    name: 'conjuntoDescricao',
                    field: 'conjunto.descricao',
                    displayName: 'Conjunto',
                    width: 120
                });
                auxColunas.push({
                    name: 'nomePopulacao',
                    field: 'nomePopulacao',
                    displayName: 'Nome População',
                    width: 250
                });
                auxColunas.push({
                    name: 'procedenciaDescricao',
                    field: 'procedencia.descricao',
                    displayName: 'Procedência',
                    width: 130
                });
                auxColunas.push({
                    name: 'fonteDescricao',
                    field: 'fonte.descricao',
                    displayName: 'Fonte (Procedência)',
                    width: 150
                });
                auxColunas.push({
                    name: 'localidadeProcedencia',
                    field: 'localidadeProcedencia',
                    displayName: 'Localidade (Procedência)',
                    width: 180
                });
                auxColunas.push({
                    name: 'tipoPomarBancoTesteProcedenciaDescricao',
                    field: 'tipoPomarBancoTesteProcedencia.descricao',
                    displayName: 'Pomar, Banco ou Teste Origem',
                    width: 240
                });
                auxColunas.push({
                    name: 'codigoOrigemProcedencia',
                    field: 'codigoOrigemProcedencia',
                    displayName: 'Código de Origem (Procedência)',
                    width: 200
                });
                auxColunas.push({
                    name: 'provinciaUfDescricao',
                    field: 'provinciaUf.descricao',
                    displayName: 'Província ou UF (Procedência)',
                    width: 160
                });
                auxColunas.push({
                    name: 'paisDescricao',
                    field: 'pais.descricao',
                    displayName: 'País (Procedência)',
                    width: 140
                });
                auxColunas.push({
                    name: 'polinizacaoDescricao',
                    field: 'polinizacao.descricao',
                    displayName: 'Polinização',
                    width: 160
                });
                auxColunas.push({
                    name: 'hibridacaoDescricao',
                    field: 'hibridacao.descricao',
                    displayName: 'Hibridação',
                    width: 140
                });
                auxColunas.push({
                    name: 'hibrido',
                    field: 'hibrido',
                    displayName: 'Híbrido',
                    width: 140
                });
                auxColunas.push({
                    name: 'polinizacaoDescricao',
                    field: 'polinizacao.descricao',
                    displayName: 'Polinização',
                    width: 140
                });
                auxColunas.push({
                    name: 'maeDescricao',
                    field: 'mae.descricao',
                    displayName: 'Mãe',
                    width: 120
                });
                auxColunas.push({
                    name: 'especieMaeDescricao',
                    field: 'especieMae.descricao',
                    displayName: 'Espécie da Mãe',
                    width: 120
                });
                auxColunas.push({
                    name: 'paiDescricao',
                    field: 'pai.descricao',
                    displayName: 'Pai',
                    width: 120
                });
                auxColunas.push({
                    name: 'especiePaiDescricao',
                    field: 'especiePai.descricao',
                    displayName: 'Espécie do Pai',
                    width: 120
                });
                auxColunas.push({
                    name: 'cruzamento',
                    field: 'cruzamento',
                    displayName: 'Cruzamento',
                    width: 120
                });
                auxColunas.push({
                    name: 'codigoCruzamento',
                    field: 'codigoCruzamento',
                    displayName: 'Código do Cruzamento/Família',
                    width: 160
                });
                auxColunas.push({
                    name: 'codigoSelecao',
                    field: 'codigoSelecao',
                    displayName: 'Código da Seleção',
                    width: 140
                });
                auxColunas.push({
                    name: 'propaguloDescricao',
                    field: 'propagulo.descricao',
                    displayName: 'Propágulo',
                    width: 140
                });
                auxColunas.push({
                    name: 'codigoClone',
                    field: 'codigoClone',
                    displayName: 'Clone',
                    width: 120
                });
                auxColunas.push({
                    name: 'minijardimDescricao',
                    field: 'minijardim.descricao',
                    displayName: 'Minijardim',
                    width: 120
                });
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
                const lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MATERIAL_GENETICO)
                    .filtro(filtro)
                    .listar();
                if ((typeof lista !== 'undefined') && lista) {
                    let refPomaresMateriaisGeneticos = [];
                    angular.forEach(lista, (materialGenetico) => {
                        if ((typeof materialGenetico.detalhamento === 'undefined') || !materialGenetico.detalhamento) {
                            materialGenetico.detalhamento = {};
                        }
                        if ((typeof materialGenetico.pomaresMateriaisGeneticos !== 'undefined') && materialGenetico.pomaresMateriaisGeneticos) {
                            angular.forEach(materialGenetico.pomaresMateriaisGeneticos, (pomarMaterialGenetico) => {
                                if ((typeof pomarMaterialGenetico.pomar !== 'undefined') && pomarMaterialGenetico.pomar &&
                                    (typeof pomarMaterialGenetico.pomar.descricao !== 'undefined') && pomarMaterialGenetico.pomar.descricao) {
                                    const refPomar = TextoUtil_1.TextoUtil.converteTextoParaReferencia(pomarMaterialGenetico.pomar.descricao);
                                    if (!refPomaresMateriaisGeneticos.some(x => x.referencia === refPomar)) {
                                        refPomaresMateriaisGeneticos.push({
                                            referencia: refPomar,
                                            descricao: pomarMaterialGenetico.pomar.descricao
                                        });
                                    }
                                    if ((typeof pomarMaterialGenetico.numMaterialGenetico !== 'undefined') && (pomarMaterialGenetico.numMaterialGenetico !== null)) {
                                        materialGenetico.detalhamento['pomar_' + refPomar] = pomarMaterialGenetico.numMaterialGenetico;
                                    }
                                }
                            });
                        }
                    });
                    angular.forEach(refPomaresMateriaisGeneticos, (itemPomaresMateriaisGeneticos) => {
                        let colunaDetalhamentoPomar = {
                            name: 'detalhamentoPomar_' + itemPomaresMateriaisGeneticos.referencia,
                            field: 'detalhamento.pomar_' + itemPomaresMateriaisGeneticos.referencia,
                            displayName: itemPomaresMateriaisGeneticos.descricao,
                            width: 140
                        };
                        colunaDetalhamentoPomar.width = UiGridUtil_1.UiGridUtil.getColumnAutoFitWidth(lista, colunaDetalhamentoPomar) + 10;
                        auxColunas.push(colunaDetalhamentoPomar);
                    });
                    let agrupamentos = TabelaUtil_1.TabelaUtil.agrupaDadosColunas(lista, auxColunas);
                    angular.forEach(auxColunas, (coluna) => {
                        const nomeColunaPadronizado = TabelaUtil_1.TabelaUtil.padronizaNomeCampoObj(coluna.field ? coluna.field : coluna.name);
                        const listaFiltroColuna = (typeof agrupamentos[nomeColunaPadronizado] !== 'undefined') ? agrupamentos[nomeColunaPadronizado] : null;
                        if (listaFiltroColuna && (listaFiltroColuna.length > 0)) {
                            coluna.filter = {
                                type: this.uiGridConstantes.filter.SELECT,
                                selectOptions: listaFiltroColuna
                            };
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach(element => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosListaTestesGeneticos() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const ctrl = this;
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                this.path = config_app_1.config.api.caminho;
                this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO;
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                var auxColunas = Array();
                auxColunas.push({
                    name: 'testesGeneticos_tipoTeste',
                    field: 'tipoTestePopulacao.descricao',
                    displayName: 'Tipo de Teste',
                    width: 140
                });
                auxColunas.push({
                    name: 'testesGeneticos_regiao',
                    field: 'regiaoNum',
                    displayName: 'Região',
                    width: 100
                });
                auxColunas.push({
                    name: 'testesGeneticos_localidade',
                    field: 'regiaoNome',
                    displayName: 'Localidade/Fazenda',
                    width: 160
                });
                auxColunas.push({
                    name: 'testesGeneticos_talhao',
                    field: 'talhaoNumero',
                    displayName: 'Talhão',
                    width: 100
                });
                auxColunas.push({
                    name: 'testesGeneticos_dataEstabelecimento',
                    field: 'dataImplantacao',
                    displayName: 'Data Estabelecimento',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 190
                });
                auxColunas.push({
                    name: 'testesGeneticos_anoEstabelecimento',
                    field: 'ano',
                    displayName: 'Ano Estabelecimento',
                    width: 160
                });
                auxColunas.push({
                    name: 'testesGeneticos_idade',
                    field: 'idade',
                    displayName: 'Idade',
                    width: 100
                });
                auxColunas.push({
                    name: 'testesGeneticos_area',
                    field: 'area',
                    displayName: 'Área (HA)',
                    width: 110
                });
                auxColunas.push({
                    name: 'testesGeneticos_situacao',
                    field: 'situacao.descricao',
                    displayName: 'Situação',
                    width: 120
                });
                auxColunas.push({
                    name: 'testesGeneticos_tratamento',
                    field: 'numTratamento',
                    displayName: 'Tratamento',
                    width: 130
                });
                auxColunas.push({
                    name: 'testesGeneticos_cruzamento',
                    field: 'cruzamento',
                    displayName: 'Cruzamento',
                    width: 130
                });
                auxColunas.push({
                    name: 'testesGeneticos_conjunto',
                    field: 'conjunto',
                    displayName: 'Conjunto',
                    width: 130
                });
                auxColunas.push({
                    name: 'testesGeneticos_descricao',
                    field: 'descricao',
                    displayName: 'Código Material',
                    width: 150
                });
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
                const lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TESTE_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TESTE_GENETICO)
                    .filtro({})
                    .listar();
                if ((typeof lista !== 'undefined') && lista) {
                    angular.forEach(lista, (testeGenetico) => {
                        if ((typeof testeGenetico.data !== 'undefined') && testeGenetico.data) {
                            testeGenetico.data = (typeof testeGenetico.data === 'string') ? new Date(testeGenetico.data) : testeGenetico.data;
                            if (testeGenetico.data) {
                                let dataHoraAtual = new Date();
                                let idadeMs = dataHoraAtual.getTime() - testeGenetico.data.getTime();
                                let idadeData = new Date(idadeMs);
                                testeGenetico.idade = Math.abs(idadeData.getUTCFullYear() - 1970);
                                testeGenetico.ano = testeGenetico.data.getFullYear();
                            }
                        }
                        if ((typeof testeGenetico.dataImplantacao !== 'undefined') && testeGenetico.dataImplantacao) {
                            testeGenetico.dataImplantacao = (typeof testeGenetico.dataImplantacao === 'string') ? new Date(testeGenetico.dataImplantacao) : testeGenetico.dataImplantacao;
                            if (testeGenetico.dataImplantacao) {
                                let dataHoraAtual = new Date();
                                let idadeMs = dataHoraAtual.getTime() - testeGenetico.dataImplantacao.getTime();
                                let idadeData = new Date(idadeMs);
                                testeGenetico.idade = Math.abs(idadeData.getUTCFullYear() - 1970);
                                testeGenetico.ano = testeGenetico.dataImplantacao.getFullYear();
                            }
                        }
                        if ((typeof testeGenetico.regiaoFazenda !== 'undefined') && testeGenetico.regiaoFazenda &&
                            (typeof testeGenetico.regiaoFazenda.id !== 'undefined') && testeGenetico.regiaoFazenda.id) {
                            testeGenetico.regiaoId = testeGenetico.regiaoFazenda.id;
                        }
                        if ((typeof testeGenetico.regiaoFazenda !== 'undefined') && testeGenetico.regiaoFazenda &&
                            (typeof testeGenetico.regiaoFazenda.numRegiao !== 'undefined') && testeGenetico.regiaoFazenda.numRegiao) {
                            testeGenetico.regiaoNum = testeGenetico.regiaoFazenda.numRegiao;
                        }
                        if ((typeof testeGenetico.regiaoFazenda !== 'undefined') && testeGenetico.regiaoFazenda &&
                            (typeof testeGenetico.regiaoFazenda.nome !== 'undefined') && testeGenetico.regiaoFazenda.nome) {
                            testeGenetico.regiaoNome = testeGenetico.regiaoFazenda.nome;
                        }
                        if ((typeof testeGenetico.talhao !== 'undefined') && testeGenetico.talhao &&
                            (typeof testeGenetico.talhao.id !== 'undefined') && testeGenetico.talhao.id) {
                            testeGenetico.talhaoId = testeGenetico.talhao.id;
                        }
                        if ((typeof testeGenetico.talhao !== 'undefined') && testeGenetico.talhao &&
                            (typeof testeGenetico.talhao.numTalhao !== 'undefined') && testeGenetico.talhao.numTalhao) {
                            testeGenetico.talhaoNumero = testeGenetico.talhao.numTalhao;
                        }
                        else if ((typeof testeGenetico.talhao !== 'undefined') && testeGenetico.talhao &&
                            (typeof testeGenetico.talhao.numero !== 'undefined') && testeGenetico.talhao.numero) {
                            testeGenetico.talhaoNumero = testeGenetico.talhao.numero;
                        }
                        testeGenetico.materialGeneticoId = ((typeof ctrl.materialGeneticoSelecionado !== 'undefined') && ctrl.materialGeneticoSelecionado &&
                            (typeof ctrl.materialGeneticoSelecionado.id !== 'undefined') && ctrl.materialGeneticoSelecionado.id
                            ? ctrl.materialGeneticoSelecionado.id : null);
                        testeGenetico.materialGeneticoDescricao = ((typeof ctrl.materialGeneticoSelecionado !== 'undefined') && ctrl.materialGeneticoSelecionado &&
                            (typeof ctrl.materialGeneticoSelecionado.descricao !== 'undefined') && ctrl.materialGeneticoSelecionado.descricao
                            ? ctrl.materialGeneticoSelecionado.descricao : null);
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach(element => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosListaPomares() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const ctrl = this;
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                this.path = config_app_1.config.api.caminho;
                this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO;
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                var auxColunas = Array();
                auxColunas.push({
                    name: 'pomares_tipoPomar',
                    field: 'tipoDescricao',
                    displayName: 'Tipo de Pomar',
                    width: 160
                });
                auxColunas.push({
                    name: 'pomares_descricao',
                    field: 'descricao',
                    displayName: 'Nome do Pomar',
                    width: 180
                });
                auxColunas.push({
                    name: 'pomares_regiao',
                    field: 'regiaoNum',
                    displayName: 'Região',
                    width: 100
                });
                auxColunas.push({
                    name: 'pomares_localidade',
                    field: 'regiaoNome',
                    displayName: 'Localidade/Fazenda',
                    width: 160
                });
                auxColunas.push({
                    name: 'pomares_talhao',
                    field: 'talhaoNumero',
                    displayName: 'Talhão',
                    width: 100
                });
                auxColunas.push({
                    name: 'pomares_dataEstabelecimento',
                    field: 'data',
                    displayName: 'Data Estabelecimento',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 190
                });
                auxColunas.push({
                    name: 'pomares_anoEstabelecimento',
                    field: 'ano',
                    displayName: 'Ano Estabelecimento',
                    width: 160
                });
                auxColunas.push({
                    name: 'pomares_idade',
                    field: 'idade',
                    displayName: 'Idade',
                    width: 100
                });
                auxColunas.push({
                    name: 'pomares_area',
                    field: 'area',
                    displayName: 'Área (HA)',
                    width: 110
                });
                auxColunas.push({
                    name: 'pomares_situacao',
                    field: 'situacao',
                    displayName: 'Situação',
                    width: 120
                });
                auxColunas.push({
                    name: 'pomares_geracao',
                    field: 'geracaoDescricao',
                    displayName: 'Geração',
                    width: 120
                });
                auxColunas.push({
                    name: 'pomares_materialGenetico',
                    field: 'materialGeneticoDescricao',
                    displayName: 'Código Material',
                    width: 150
                });
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
                let lista = [];
                const listaTratamentos = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TRATAMENTO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TRATAMENTO_POMAR)
                    .filtro({
                    materialGeneticoId: ((typeof ctrl.materialGeneticoSelecionado !== 'undefined') && ctrl.materialGeneticoSelecionado &&
                        (typeof ctrl.materialGeneticoSelecionado.id !== 'undefined') && ctrl.materialGeneticoSelecionado.id
                        ? ctrl.materialGeneticoSelecionado.id : null)
                })
                    .listar();
                const listaPomares = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POMAR)
                    .filtro({})
                    .listar();
                if ((typeof listaPomares !== 'undefined') && listaPomares) {
                    angular.forEach(listaPomares, (pomar) => {
                        pomar.descricaoDetalhada = ((typeof pomar.descricao !== 'undefined') && pomar.descricao ? pomar.descricao : '');
                        if ((typeof pomar.data !== 'undefined') && pomar.data) {
                            pomar.data = (typeof pomar.data === 'string') ? new Date(pomar.data) : pomar.data;
                            if (pomar.data) {
                                let dataHoraAtual = new Date();
                                let idadeMs = dataHoraAtual.getTime() - pomar.data.getTime();
                                let idadeData = new Date(idadeMs);
                                pomar.idade = Math.abs(idadeData.getUTCFullYear() - 1970);
                                pomar.ano = pomar.data.getFullYear();
                                pomar.descricaoDetalhada += ' - ' + pomar.data.getFullYear();
                            }
                        }
                        if ((typeof pomar.paisDescricao === 'undefined') && !pomar.paisDescricao) {
                            if ((typeof ctrl.paises !== 'undefined') && ctrl.paises && (ctrl.paises.length > 0)) {
                                let paisPadrao = null;
                                const paisPadraoReferencia = TextoUtil_1.TextoUtil.converteTextoParaReferencia('Brasil');
                                angular.forEach(ctrl.paises, (pais) => {
                                    if (!paisPadrao && (typeof pais.descricao !== 'undefined') && pais.descricao && (pais.descricao.length > 0)) {
                                        if (TextoUtil_1.TextoUtil.converteTextoParaReferencia(pais.descricao) === paisPadraoReferencia) {
                                            paisPadrao = pais;
                                        }
                                    }
                                });
                                if (paisPadrao) {
                                    pomar.pais = paisPadrao;
                                    pomar.paisId = paisPadrao.id;
                                    pomar.paisCodigo = paisPadrao.codigo;
                                    pomar.paisDescricao = paisPadrao.descricao;
                                }
                            }
                            else {
                                let paisPadrao = {
                                    id: Pais_1.Pais.BRASIL,
                                    uuid: Pais_1.Pais.BRASIL,
                                    status: true,
                                    criacao: new Date(),
                                    codigo: 1,
                                    descricao: 'Brasil'
                                };
                                pomar.pais = paisPadrao;
                                pomar.paisId = paisPadrao.id;
                                pomar.paisCodigo = paisPadrao.codigo;
                                pomar.paisDescricao = paisPadrao.descricao;
                            }
                        }
                        if ((typeof pomar.instituicao === 'undefined') || !pomar.instituicao || (pomar.instituicao.length === 0)) {
                            pomar.instituicao = 'WestRock';
                        }
                        if ((typeof pomar.nomeCientifico === 'undefined') || !pomar.nomeCientifico || (pomar.nomeCientifico.length === 0)) {
                            pomar.nomeCientifico = pomar.descricao;
                        }
                        if ((typeof pomar.distanciaSede === 'undefined') || !pomar.distanciaSede) {
                            pomar.distanciaSede = (pomar.descricaoDetalhada.toLowerCase().indexOf('sede') !== -1) ? '1 km' : '35 km';
                        }
                        pomar.local = ((typeof pomar.municipio !== 'undefined') && pomar.municipio ? pomar.municipio : '') + ' - ' +
                            ((typeof pomar.provinciaUfSigla !== 'undefined') && pomar.provinciaUfSigla ? pomar.provinciaUfSigla : '');
                        if ((typeof pomar.perfilClimaticoAnual !== 'undefined') && pomar.perfilClimaticoAnual) {
                            if ((typeof pomar.perfilClimaticoAnual.atualizacao === 'undefined') || !pomar.perfilClimaticoAnual.atualizacao) {
                                pomar.perfilClimaticoAnual.atualizacao = new Date();
                            }
                            else if (typeof pomar.perfilClimaticoAnual.atualizacao === 'string') {
                                pomar.perfilClimaticoAnual.atualizacao = new Date(pomar.perfilClimaticoAnual.atualizacao);
                            }
                        }
                        else {
                            pomar.perfilClimaticoAnual = new PerfilClimaticoPomar_1.PerfilClimaticoPomar();
                            pomar.perfilClimaticoAnual.atualizacao = new Date();
                        }
                        if ((typeof pomar.producaoPortaEnxertosPlantio !== 'undefined') && pomar.producaoPortaEnxertosPlantio) {
                            pomar.producaoPortaEnxertosPlantio = (typeof pomar.producaoPortaEnxertosPlantio === 'string') ? new Date(pomar.producaoPortaEnxertosPlantio) : pomar.producaoPortaEnxertosPlantio;
                        }
                        if ((typeof pomar.implantacaoPomarEnxertia !== 'undefined') && pomar.implantacaoPomarEnxertia) {
                            pomar.implantacaoPomarEnxertia = (typeof pomar.implantacaoPomarEnxertia === 'string') ? new Date(pomar.implantacaoPomarEnxertia) : pomar.implantacaoPomarEnxertia;
                        }
                    });
                }
                if ((typeof listaTratamentos !== 'undefined') && listaTratamentos) {
                    angular.forEach(listaTratamentos, (tratamentoPomar) => {
                        if ((typeof tratamentoPomar.pomar !== 'undefined') && tratamentoPomar.pomar && !lista.some(x => x.id === tratamentoPomar.pomar.id)) {
                            const buscaPomar = listaPomares.find(x => x.id === tratamentoPomar.pomar.id);
                            if ((typeof buscaPomar !== 'undefined') && buscaPomar) {
                                buscaPomar.materialGeneticoId = ((typeof ctrl.materialGeneticoSelecionado !== 'undefined') && ctrl.materialGeneticoSelecionado &&
                                    (typeof ctrl.materialGeneticoSelecionado.id !== 'undefined') && ctrl.materialGeneticoSelecionado.id
                                    ? ctrl.materialGeneticoSelecionado.id : null);
                                buscaPomar.materialGeneticoDescricao = ((typeof ctrl.materialGeneticoSelecionado !== 'undefined') && ctrl.materialGeneticoSelecionado &&
                                    (typeof ctrl.materialGeneticoSelecionado.descricao !== 'undefined') && ctrl.materialGeneticoSelecionado.descricao
                                    ? ctrl.materialGeneticoSelecionado.descricao : null);
                                lista.push(buscaPomar);
                            }
                            else {
                                tratamentoPomar.pomar.materialGeneticoId = ((typeof ctrl.materialGeneticoSelecionado !== 'undefined') && ctrl.materialGeneticoSelecionado &&
                                    (typeof ctrl.materialGeneticoSelecionado.id !== 'undefined') && ctrl.materialGeneticoSelecionado.id
                                    ? ctrl.materialGeneticoSelecionado.id : null);
                                tratamentoPomar.pomar.materialGeneticoDescricao = ((typeof ctrl.materialGeneticoSelecionado !== 'undefined') && ctrl.materialGeneticoSelecionado &&
                                    (typeof ctrl.materialGeneticoSelecionado.descricao !== 'undefined') && ctrl.materialGeneticoSelecionado.descricao
                                    ? ctrl.materialGeneticoSelecionado.descricao : null);
                                lista.push(tratamentoPomar.pomar);
                            }
                        }
                    });
                }
                lista = lista.filter(x => (typeof x.tipoDescricao === 'undefined') || !x.tipoDescricao ||
                    (x.tipoDescricao && x.tipoDescricao.toLowerCase().indexOf('banco') === -1));
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach(element => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosListaBancoMelhoramentoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const ctrl = this;
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                this.path = config_app_1.config.api.caminho;
                this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO;
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                var auxColunas = Array();
                auxColunas.push({
                    name: 'bancoMelhoramento_tipoPomar',
                    field: 'tipoDescricao',
                    displayName: 'Tipo de Banco',
                    width: 160
                });
                auxColunas.push({
                    name: 'bancoMelhoramento_descricao',
                    field: 'descricao',
                    displayName: 'Nome do Banco',
                    width: 180
                });
                auxColunas.push({
                    name: 'bancoMelhoramento_regiao',
                    field: 'regiaoNum',
                    displayName: 'Região',
                    width: 100
                });
                auxColunas.push({
                    name: 'bancoMelhoramento_localidade',
                    field: 'regiaoNome',
                    displayName: 'Localidade/Fazenda',
                    width: 160
                });
                auxColunas.push({
                    name: 'bancoMelhoramento_talhao',
                    field: 'talhaoNumero',
                    displayName: 'Talhão',
                    width: 100
                });
                auxColunas.push({
                    name: 'bancoMelhoramento_dataEstabelecimento',
                    field: 'data',
                    displayName: 'Data Estabelecimento',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 190
                });
                auxColunas.push({
                    name: 'bancoMelhoramento_anoEstabelecimento',
                    field: 'ano',
                    displayName: 'Ano Estabelecimento',
                    width: 160
                });
                auxColunas.push({
                    name: 'bancoMelhoramento_idade',
                    field: 'idade',
                    displayName: 'Idade',
                    width: 100
                });
                auxColunas.push({
                    name: 'bancoMelhoramento_area',
                    field: 'area',
                    displayName: 'Área (HA)',
                    width: 110
                });
                auxColunas.push({
                    name: 'bancoMelhoramento_situacao',
                    field: 'situacao',
                    displayName: 'Situação',
                    width: 120
                });
                auxColunas.push({
                    name: 'bancoMelhoramento_geracao',
                    field: 'geracaoDescricao',
                    displayName: 'Geração',
                    width: 120
                });
                auxColunas.push({
                    name: 'bancoMelhoramento_materialGenetico',
                    field: 'materialGeneticoDescricao',
                    displayName: 'Código Material',
                    width: 150
                });
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
                let lista = [];
                const listaTratamentos = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TRATAMENTO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TRATAMENTO_POMAR)
                    .filtro({
                    materialGeneticoId: ((typeof ctrl.materialGeneticoSelecionado !== 'undefined') && ctrl.materialGeneticoSelecionado &&
                        (typeof ctrl.materialGeneticoSelecionado.id !== 'undefined') && ctrl.materialGeneticoSelecionado.id
                        ? ctrl.materialGeneticoSelecionado.id : null)
                })
                    .listar();
                const listaPomares = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POMAR)
                    .filtro({})
                    .listar();
                if ((typeof listaPomares !== 'undefined') && listaPomares) {
                    angular.forEach(listaPomares, (pomar) => {
                        pomar.descricaoDetalhada = ((typeof pomar.descricao !== 'undefined') && pomar.descricao ? pomar.descricao : '');
                        if ((typeof pomar.data !== 'undefined') && pomar.data) {
                            pomar.data = (typeof pomar.data === 'string') ? new Date(pomar.data) : pomar.data;
                            if (pomar.data) {
                                let dataHoraAtual = new Date();
                                let idadeMs = dataHoraAtual.getTime() - pomar.data.getTime();
                                let idadeData = new Date(idadeMs);
                                pomar.idade = Math.abs(idadeData.getUTCFullYear() - 1970);
                                pomar.ano = pomar.data.getFullYear();
                                pomar.descricaoDetalhada += ' - ' + pomar.data.getFullYear();
                            }
                        }
                        if ((typeof pomar.paisDescricao === 'undefined') && !pomar.paisDescricao) {
                            if ((typeof ctrl.paises !== 'undefined') && ctrl.paises && (ctrl.paises.length > 0)) {
                                let paisPadrao = null;
                                const paisPadraoReferencia = TextoUtil_1.TextoUtil.converteTextoParaReferencia('Brasil');
                                angular.forEach(ctrl.paises, (pais) => {
                                    if (!paisPadrao && (typeof pais.descricao !== 'undefined') && pais.descricao && (pais.descricao.length > 0)) {
                                        if (TextoUtil_1.TextoUtil.converteTextoParaReferencia(pais.descricao) === paisPadraoReferencia) {
                                            paisPadrao = pais;
                                        }
                                    }
                                });
                                if (paisPadrao) {
                                    pomar.pais = paisPadrao;
                                    pomar.paisId = paisPadrao.id;
                                    pomar.paisCodigo = paisPadrao.codigo;
                                    pomar.paisDescricao = paisPadrao.descricao;
                                }
                            }
                            else {
                                let paisPadrao = {
                                    id: Pais_1.Pais.BRASIL,
                                    uuid: Pais_1.Pais.BRASIL,
                                    status: true,
                                    criacao: new Date(),
                                    codigo: 1,
                                    descricao: 'Brasil'
                                };
                                pomar.pais = paisPadrao;
                                pomar.paisId = paisPadrao.id;
                                pomar.paisCodigo = paisPadrao.codigo;
                                pomar.paisDescricao = paisPadrao.descricao;
                            }
                        }
                        if ((typeof pomar.instituicao === 'undefined') || !pomar.instituicao || (pomar.instituicao.length === 0)) {
                            pomar.instituicao = 'WestRock';
                        }
                        if ((typeof pomar.nomeCientifico === 'undefined') || !pomar.nomeCientifico || (pomar.nomeCientifico.length === 0)) {
                            pomar.nomeCientifico = pomar.descricao;
                        }
                        if ((typeof pomar.distanciaSede === 'undefined') || !pomar.distanciaSede) {
                            pomar.distanciaSede = (pomar.descricaoDetalhada.toLowerCase().indexOf('sede') !== -1) ? '1 km' : '35 km';
                        }
                        pomar.local = ((typeof pomar.municipio !== 'undefined') && pomar.municipio ? pomar.municipio : '') + ' - ' +
                            ((typeof pomar.provinciaUfSigla !== 'undefined') && pomar.provinciaUfSigla ? pomar.provinciaUfSigla : '');
                        if ((typeof pomar.perfilClimaticoAnual !== 'undefined') && pomar.perfilClimaticoAnual) {
                            if ((typeof pomar.perfilClimaticoAnual.atualizacao === 'undefined') || !pomar.perfilClimaticoAnual.atualizacao) {
                                pomar.perfilClimaticoAnual.atualizacao = new Date();
                            }
                            else if (typeof pomar.perfilClimaticoAnual.atualizacao === 'string') {
                                pomar.perfilClimaticoAnual.atualizacao = new Date(pomar.perfilClimaticoAnual.atualizacao);
                            }
                        }
                        else {
                            pomar.perfilClimaticoAnual = new PerfilClimaticoPomar_1.PerfilClimaticoPomar();
                            pomar.perfilClimaticoAnual.atualizacao = new Date();
                        }
                        if ((typeof pomar.producaoPortaEnxertosPlantio !== 'undefined') && pomar.producaoPortaEnxertosPlantio) {
                            pomar.producaoPortaEnxertosPlantio = (typeof pomar.producaoPortaEnxertosPlantio === 'string') ? new Date(pomar.producaoPortaEnxertosPlantio) : pomar.producaoPortaEnxertosPlantio;
                        }
                        if ((typeof pomar.implantacaoPomarEnxertia !== 'undefined') && pomar.implantacaoPomarEnxertia) {
                            pomar.implantacaoPomarEnxertia = (typeof pomar.implantacaoPomarEnxertia === 'string') ? new Date(pomar.implantacaoPomarEnxertia) : pomar.implantacaoPomarEnxertia;
                        }
                    });
                }
                if ((typeof listaTratamentos !== 'undefined') && listaTratamentos) {
                    angular.forEach(listaTratamentos, (tratamentoPomar) => {
                        if ((typeof tratamentoPomar.pomar !== 'undefined') && tratamentoPomar.pomar && !lista.some(x => x.id === tratamentoPomar.pomar.id)) {
                            const buscaPomar = listaPomares.find(x => x.id === tratamentoPomar.pomar.id);
                            if ((typeof buscaPomar !== 'undefined') && buscaPomar) {
                                buscaPomar.materialGeneticoId = ((typeof ctrl.materialGeneticoSelecionado !== 'undefined') && ctrl.materialGeneticoSelecionado &&
                                    (typeof ctrl.materialGeneticoSelecionado.id !== 'undefined') && ctrl.materialGeneticoSelecionado.id
                                    ? ctrl.materialGeneticoSelecionado.id : null);
                                buscaPomar.materialGeneticoDescricao = ((typeof ctrl.materialGeneticoSelecionado !== 'undefined') && ctrl.materialGeneticoSelecionado &&
                                    (typeof ctrl.materialGeneticoSelecionado.descricao !== 'undefined') && ctrl.materialGeneticoSelecionado.descricao
                                    ? ctrl.materialGeneticoSelecionado.descricao : null);
                                lista.push(buscaPomar);
                            }
                            else {
                                tratamentoPomar.pomar.materialGeneticoId = ((typeof ctrl.materialGeneticoSelecionado !== 'undefined') && ctrl.materialGeneticoSelecionado &&
                                    (typeof ctrl.materialGeneticoSelecionado.id !== 'undefined') && ctrl.materialGeneticoSelecionado.id
                                    ? ctrl.materialGeneticoSelecionado.id : null);
                                tratamentoPomar.pomar.materialGeneticoDescricao = ((typeof ctrl.materialGeneticoSelecionado !== 'undefined') && ctrl.materialGeneticoSelecionado &&
                                    (typeof ctrl.materialGeneticoSelecionado.descricao !== 'undefined') && ctrl.materialGeneticoSelecionado.descricao
                                    ? ctrl.materialGeneticoSelecionado.descricao : null);
                                lista.push(tratamentoPomar.pomar);
                            }
                        }
                    });
                }
                lista = lista.filter(x => (typeof x.tipoDescricao !== 'undefined') && x.tipoDescricao &&
                    (x.tipoDescricao.toLowerCase().indexOf('banco') !== -1));
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach(element => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    filtrar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    limparFiltro() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    selecionarMaterialGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            let ctrl = this;
            if (ctrl && ctrl.constructor && ctrl.constructor.name) {
                if (ctrl.constructor.name === 'TabelaController') {
                    ctrl = ctrl.$scope.$parent.$ctrl;
                }
            }
            ctrl.filtrarMaterialGeneticoSelecionado();
        });
    }
    filtrarMaterialGeneticoSelecionado() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                let materialGenetico = null;
                const nomeTabelaMateriaisGeneticos = 'tabelaMaterialGenetico';
                let gridMateriaisGeneticosApi = null;
                let gridMateriaisGeneticosScope = angular.element('#' + nomeTabelaMateriaisGeneticos).scope();
                if ((typeof gridMateriaisGeneticosScope.listaGridApi !== 'undefined') && gridMateriaisGeneticosScope.listaGridApi) {
                    angular.forEach(gridMateriaisGeneticosScope.listaGridApi, (gridApi) => {
                        const grid = ((typeof gridApi.grid !== 'undefined') && gridApi.grid) ? gridApi.grid : null;
                        if (grid && (typeof grid.element !== 'undefined') && grid.element) {
                            const tabelasEl = angular.element(grid.element).parents('tabela');
                            if (tabelasEl && (tabelasEl.length > 0) && (tabelasEl.first().attr('id') === nomeTabelaMateriaisGeneticos)) {
                                gridMateriaisGeneticosApi = gridApi;
                            }
                        }
                    });
                }
                if (gridMateriaisGeneticosApi) {
                    const materiaisGeneticosSelecionados = gridMateriaisGeneticosApi.selection.getSelectedRows();
                    if ((typeof materiaisGeneticosSelecionados !== 'undefined') && materiaisGeneticosSelecionados) {
                        angular.forEach(materiaisGeneticosSelecionados, (obj) => {
                            if (!materialGenetico) {
                                materialGenetico = obj;
                            }
                        });
                    }
                }
                if (materialGenetico && (typeof materialGenetico.id !== 'undefined') && materialGenetico.id) {
                    if ((typeof ctrl.materialGeneticoSelecionado !== 'undefined') && ctrl.materialGeneticoSelecionado &&
                        (typeof ctrl.materialGeneticoSelecionado.id !== 'undefined') && ctrl.materialGeneticoSelecionado.id) {
                        if (ctrl.materialGeneticoSelecionado.id !== materialGenetico.id) {
                            yield ctrl.limparFiltroMaterialGenetico();
                            ctrl.materialGeneticoSelecionado = materialGenetico;
                            ctrl.materialGeneticoCarregado = false;
                        }
                    }
                    else {
                        yield ctrl.limparFiltroMaterialGenetico();
                        ctrl.materialGeneticoSelecionado = materialGenetico;
                        ctrl.materialGeneticoCarregado = false;
                    }
                }
                else {
                    if (ctrl.materialGeneticoSelecionado || ctrl.materialGeneticoCarregado) {
                        yield ctrl.limparFiltroMaterialGenetico();
                        ctrl.materialGeneticoCarregado = false;
                    }
                }
                ctrl.scopeApply();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
        });
    }
    limparFiltroMaterialGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            this.materialGeneticoSelecionado = null;
            this.materialGeneticoCarregado = false;
        });
    }
    cellClicked(row) {
        if (row.entity && (typeof row.entity.id !== 'undefined') && row.entity.id) {
            const nomeTabelaMateriaisGeneticos = 'tabelaMaterialGenetico';
            let ehTabelaMateriaisGenetico = false;
            if ((typeof row.gridApi !== 'undefined') && row.gridApi &&
                (typeof row.gridApi.element !== 'undefined') && row.gridApi.element &&
                (angular.element(row.gridApi.element).length > 0)) {
                ehTabelaMateriaisGenetico = (angular.element(row.gridApi.element).parents('#' + nomeTabelaMateriaisGeneticos).length > 0) ? true : false;
            }
            else {
                ehTabelaMateriaisGenetico = true;
            }
            if (ehTabelaMateriaisGenetico) {
                let gridMateriaisGeneticoApi = null;
                let gridMateriaisGeneticoScope = angular.element('#' + nomeTabelaMateriaisGeneticos).scope();
                if ((typeof gridMateriaisGeneticoScope.listaGridApi !== 'undefined') && gridMateriaisGeneticoScope.listaGridApi) {
                    angular.forEach(gridMateriaisGeneticoScope.listaGridApi, (gridApi) => {
                        const grid = ((typeof gridApi.grid !== 'undefined') && gridApi.grid) ? gridApi.grid : null;
                        if (grid && (typeof grid.element !== 'undefined') && grid.element) {
                            const tabelasEl = angular.element(grid.element).parents('tabela');
                            if (tabelasEl && (tabelasEl.length > 0) && (tabelasEl.first().attr('id') === nomeTabelaMateriaisGeneticos)) {
                                gridMateriaisGeneticoApi = gridApi;
                            }
                        }
                    });
                }
                if (gridMateriaisGeneticoApi && (typeof gridMateriaisGeneticoApi.selection !== 'undefined') && gridMateriaisGeneticoApi.selection) {
                    gridMateriaisGeneticoApi.selection.selectRow(row.entity);
                }
            }
        }
    }
    abrirPlanilhaModal($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.planilha.abrir($event, SincroniaConstantes_1.SincroniaConstantes.MATERIAL_GENETICO, false);
        });
    }
    abrirTestesGeneticos() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.materialGeneticoSelecionado === 'undefined') || !this.materialGeneticoSelecionado) {
                this.notificacao.alerta('Selecione um Material Genético na tabela abaixo para acessar os Testes Genéticos.');
                return;
            }
            this.modalTestesGeneticos = false;
            this.modalPomares = false;
            this.modalBancoMelhoramentoPomar = false;
            this.modalDossieMaterialGenetico = false;
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.listaOpcoesTestesGeneticos = yield this.dadosListaTestesGeneticos();
                this.modalTestesGeneticos = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarTestesGeneticos() {
        this.modalTestesGeneticos = false;
        this.listaOpcoesTestesGeneticos = null;
        angular.element('body').css('overflow-y', '');
        globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
        this.scopeApply(() => {
            setTimeout(() => {
                angular.element('body').css('overflow-y', '');
                if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                    (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                    angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                }
            }, 10);
        });
    }
    abrirPomares() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.operacao !== ICRUDOperacao_1.CRUDOperacao.LISTAR) {
                this.materialGeneticoSelecionado = this.modelo;
            }
            if ((typeof this.materialGeneticoSelecionado === 'undefined') || !this.materialGeneticoSelecionado) {
                this.notificacao.alerta('Selecione um Material Genético na tabela abaixo para acessar os Pomares.');
                return;
            }
            this.modalTestesGeneticos = false;
            this.modalPomares = false;
            this.modalBancoMelhoramentoPomar = false;
            this.modalDossieMaterialGenetico = false;
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.listaOpcoesPomares = yield this.dadosListaPomares();
                this.modalPomares = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarPomares() {
        this.modalPomares = false;
        this.listaOpcoesPomares = null;
        angular.element('body').css('overflow-y', '');
        globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
        this.scopeApply(() => {
            setTimeout(() => {
                angular.element('body').css('overflow-y', '');
                if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                    (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                    angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                }
            }, 10);
        });
    }
    abrirBancoMelhoramentoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.materialGeneticoSelecionado === 'undefined') || !this.materialGeneticoSelecionado) {
                this.notificacao.alerta('Selecione um Material Genético na tabela abaixo para acessar os Bancos de Melhoramento.');
                return;
            }
            this.modalTestesGeneticos = false;
            this.modalPomares = false;
            this.modalBancoMelhoramentoPomar = false;
            this.modalDossieMaterialGenetico = false;
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.listaOpcoesBancoMelhoramentoPomar = yield this.dadosListaBancoMelhoramentoPomar();
                this.modalBancoMelhoramentoPomar = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarBancoMelhoramentoPomar() {
        this.modalBancoMelhoramentoPomar = false;
        this.listaOpcoesBancoMelhoramentoPomar = null;
        angular.element('body').css('overflow-y', '');
        globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
        this.scopeApply(() => {
            setTimeout(() => {
                angular.element('body').css('overflow-y', '');
                if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                    (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                    angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                }
            }, 10);
        });
    }
    abrirDossieMaterialGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            if ((typeof this.materialGeneticoSelecionado === 'undefined') || !this.materialGeneticoSelecionado) {
                this.notificacao.alerta('Selecione um Material Genético na tabela abaixo para acessar o Dossiê.');
                return;
            }
            this.modalTestesGeneticos = false;
            this.modalPomares = false;
            this.modalBancoMelhoramentoPomar = false;
            this.modalDossieMaterialGenetico = false;
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.materialGeneticoFiltrado = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MATERIAL_GENETICO)
                    .id(this.materialGeneticoSelecionado.id)
                    .ver();
                let lista = [];
                const listaTratamentos = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TRATAMENTO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TRATAMENTO_POMAR)
                    .filtro({
                    materialGeneticoId: ((typeof ctrl.materialGeneticoSelecionado !== 'undefined') && ctrl.materialGeneticoSelecionado &&
                        (typeof ctrl.materialGeneticoSelecionado.id !== 'undefined') && ctrl.materialGeneticoSelecionado.id
                        ? ctrl.materialGeneticoSelecionado.id : null)
                })
                    .listar();
                const listaPomares = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POMAR)
                    .filtro({})
                    .listar();
                if ((typeof listaPomares !== 'undefined') && listaPomares) {
                    angular.forEach(listaPomares, (pomar) => {
                        pomar.descricaoDetalhada = ((typeof pomar.descricao !== 'undefined') && pomar.descricao ? pomar.descricao : '');
                        if ((typeof pomar.data !== 'undefined') && pomar.data) {
                            pomar.data = (typeof pomar.data === 'string') ? new Date(pomar.data) : pomar.data;
                            if (pomar.data) {
                                let dataHoraAtual = new Date();
                                let idadeMs = dataHoraAtual.getTime() - pomar.data.getTime();
                                let idadeData = new Date(idadeMs);
                                pomar.idade = Math.abs(idadeData.getUTCFullYear() - 1970);
                                pomar.ano = pomar.data.getFullYear();
                                pomar.descricaoDetalhada += ' - ' + pomar.data.getFullYear();
                            }
                        }
                        if ((typeof pomar.paisDescricao === 'undefined') && !pomar.paisDescricao) {
                            if ((typeof ctrl.paises !== 'undefined') && ctrl.paises && (ctrl.paises.length > 0)) {
                                let paisPadrao = null;
                                const paisPadraoReferencia = TextoUtil_1.TextoUtil.converteTextoParaReferencia('Brasil');
                                angular.forEach(ctrl.paises, (pais) => {
                                    if (!paisPadrao && (typeof pais.descricao !== 'undefined') && pais.descricao && (pais.descricao.length > 0)) {
                                        if (TextoUtil_1.TextoUtil.converteTextoParaReferencia(pais.descricao) === paisPadraoReferencia) {
                                            paisPadrao = pais;
                                        }
                                    }
                                });
                                if (paisPadrao) {
                                    pomar.pais = paisPadrao;
                                    pomar.paisId = paisPadrao.id;
                                    pomar.paisCodigo = paisPadrao.codigo;
                                    pomar.paisDescricao = paisPadrao.descricao;
                                }
                            }
                            else {
                                let paisPadrao = {
                                    id: Pais_1.Pais.BRASIL,
                                    uuid: Pais_1.Pais.BRASIL,
                                    status: true,
                                    criacao: new Date(),
                                    codigo: 1,
                                    descricao: 'Brasil'
                                };
                                pomar.pais = paisPadrao;
                                pomar.paisId = paisPadrao.id;
                                pomar.paisCodigo = paisPadrao.codigo;
                                pomar.paisDescricao = paisPadrao.descricao;
                            }
                        }
                        if ((typeof pomar.instituicao === 'undefined') || !pomar.instituicao || (pomar.instituicao.length === 0)) {
                            pomar.instituicao = 'WestRock';
                        }
                        if ((typeof pomar.nomeCientifico === 'undefined') || !pomar.nomeCientifico || (pomar.nomeCientifico.length === 0)) {
                            pomar.nomeCientifico = pomar.descricao;
                        }
                        if ((typeof pomar.distanciaSede === 'undefined') || !pomar.distanciaSede) {
                            pomar.distanciaSede = (pomar.descricaoDetalhada.toLowerCase().indexOf('sede') !== -1) ? '1 km' : '35 km';
                        }
                        pomar.local = ((typeof pomar.municipio !== 'undefined') && pomar.municipio ? pomar.municipio : '') + ' - ' +
                            ((typeof pomar.provinciaUfSigla !== 'undefined') && pomar.provinciaUfSigla ? pomar.provinciaUfSigla : '');
                        if ((typeof pomar.perfilClimaticoAnual !== 'undefined') && pomar.perfilClimaticoAnual) {
                            if ((typeof pomar.perfilClimaticoAnual.atualizacao === 'undefined') || !pomar.perfilClimaticoAnual.atualizacao) {
                                pomar.perfilClimaticoAnual.atualizacao = new Date();
                            }
                            else if (typeof pomar.perfilClimaticoAnual.atualizacao === 'string') {
                                pomar.perfilClimaticoAnual.atualizacao = new Date(pomar.perfilClimaticoAnual.atualizacao);
                            }
                        }
                        else {
                            pomar.perfilClimaticoAnual = new PerfilClimaticoPomar_1.PerfilClimaticoPomar();
                            pomar.perfilClimaticoAnual.atualizacao = new Date();
                        }
                        if ((typeof pomar.producaoPortaEnxertosPlantio !== 'undefined') && pomar.producaoPortaEnxertosPlantio) {
                            pomar.producaoPortaEnxertosPlantio = (typeof pomar.producaoPortaEnxertosPlantio === 'string') ? new Date(pomar.producaoPortaEnxertosPlantio) : pomar.producaoPortaEnxertosPlantio;
                        }
                        if ((typeof pomar.implantacaoPomarEnxertia !== 'undefined') && pomar.implantacaoPomarEnxertia) {
                            pomar.implantacaoPomarEnxertia = (typeof pomar.implantacaoPomarEnxertia === 'string') ? new Date(pomar.implantacaoPomarEnxertia) : pomar.implantacaoPomarEnxertia;
                        }
                    });
                }
                if ((typeof listaTratamentos !== 'undefined') && listaTratamentos) {
                    angular.forEach(listaTratamentos, (tratamentoPomar) => {
                        if ((typeof tratamentoPomar.pomar !== 'undefined') && tratamentoPomar.pomar && !lista.some(x => x.id === tratamentoPomar.pomar.id)) {
                            const buscaPomar = listaPomares.find(x => x.id === tratamentoPomar.pomar.id);
                            if ((typeof buscaPomar !== 'undefined') && buscaPomar) {
                                buscaPomar.materialGeneticoId = ((typeof ctrl.materialGeneticoSelecionado !== 'undefined') && ctrl.materialGeneticoSelecionado &&
                                    (typeof ctrl.materialGeneticoSelecionado.id !== 'undefined') && ctrl.materialGeneticoSelecionado.id
                                    ? ctrl.materialGeneticoSelecionado.id : null);
                                buscaPomar.materialGeneticoDescricao = ((typeof ctrl.materialGeneticoSelecionado !== 'undefined') && ctrl.materialGeneticoSelecionado &&
                                    (typeof ctrl.materialGeneticoSelecionado.descricao !== 'undefined') && ctrl.materialGeneticoSelecionado.descricao
                                    ? ctrl.materialGeneticoSelecionado.descricao : null);
                                lista.push(buscaPomar);
                            }
                            else {
                                tratamentoPomar.pomar.materialGeneticoId = ((typeof ctrl.materialGeneticoSelecionado !== 'undefined') && ctrl.materialGeneticoSelecionado &&
                                    (typeof ctrl.materialGeneticoSelecionado.id !== 'undefined') && ctrl.materialGeneticoSelecionado.id
                                    ? ctrl.materialGeneticoSelecionado.id : null);
                                tratamentoPomar.pomar.materialGeneticoDescricao = ((typeof ctrl.materialGeneticoSelecionado !== 'undefined') && ctrl.materialGeneticoSelecionado &&
                                    (typeof ctrl.materialGeneticoSelecionado.descricao !== 'undefined') && ctrl.materialGeneticoSelecionado.descricao
                                    ? ctrl.materialGeneticoSelecionado.descricao : null);
                                lista.push(tratamentoPomar.pomar);
                            }
                        }
                    });
                }
                if ((typeof lista !== 'undefined') && lista && (lista.length > 0)) {
                    this.pomarFiltrado = lista[0];
                }
                this.modalDossieMaterialGenetico = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarDossieMaterialGenetico() {
        this.modalDossieMaterialGenetico = false;
        this.materialGeneticoFiltrado = null;
        this.pomarFiltrado = null;
        angular.element('body').css('overflow-y', '');
        globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
        this.scopeApply(() => {
            setTimeout(() => {
                angular.element('body').css('overflow-y', '');
                if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                    (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                    angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                }
            }, 10);
        });
    }
}
exports.MaterialGeneticoController = MaterialGeneticoController;
MaterialGeneticoController.$inject = ['$scope', '$injector', PlanilhaFactory_1.PlanilhaFactory.id];
