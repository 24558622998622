"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificacaoUtil = void 0;
const angular = require("angular");
const DispositivoUtil_1 = require("./DispositivoUtil");
const sweetalert2_1 = require("sweetalert2");
class NotificacaoUtil {
    static erro(titulo, mensagem = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.exibir(titulo, mensagem, 'error', false);
        });
    }
    static excecaoObj(ex) {
        return __awaiter(this, void 0, void 0, function* () {
            let titulo = 'Entre em contato com Suporte Técnico';
            let mensagem = 'Sem mensagem de erro aparente';
            if ((typeof ex !== 'undefined') && (ex !== null) && (typeof ex.message !== 'undefined') && (ex.message !== null)) {
                if (ex.message) {
                    titulo = (ex.message.length < 50) ? ex.message : '';
                    mensagem = (ex.message.length >= 50) ? ex.message : '';
                }
            }
            return this.exibir(titulo, mensagem, 'error', false);
        });
    }
    static excecao(titulo, mensagem = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.exibir(titulo, mensagem, 'error', false);
        });
    }
    static alerta(titulo, mensagem = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.exibir(titulo, mensagem, 'warning', false);
        });
    }
    static erroDesenvolvimento(titulo, mensagem = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.exibir(titulo, mensagem, 'error', false);
        });
    }
    static info(titulo, mensagem = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.exibir(titulo, mensagem, 'info', false);
        });
    }
    static sucesso(titulo, mensagem = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.exibir(titulo, mensagem, 'success', false);
        });
    }
    static servidor(resposta) {
        let ehValido = false;
        let erroForm = false;
        let campos = [];
        let titulo = 'Olá';
        let mensagem = null;
        let tipo = 'error';
        angular.forEach(resposta.mensagens, (valor) => {
            let display = true;
            if (valor.tipo === 0) {
                titulo = 'Operação realizada com Sucesso';
                mensagem = valor.texto;
                tipo = 'success';
                ehValido = true;
            }
            else if (valor.tipo === 2) {
                titulo = 'Opa! Tem algo errado que você pode resolver';
                mensagem = valor.texto;
                tipo = 'warning';
                ehValido = false;
            }
            else if (valor.tipo === 4) {
                erroForm = true;
                campos.push(valor.campo);
                ehValido = false;
            }
            else {
                titulo = 'Operação falhou!';
                mensagem = valor.texto;
                ehValido = false;
            }
            if (display && (typeof mensagem !== 'undefined') && (mensagem !== null)) {
                NotificacaoUtil.exibir(titulo, mensagem, tipo, false);
            }
        });
        if (erroForm) {
            titulo = 'Tem algo errado no Formulário';
            tipo = 'warning';
            mensagem = 'Campo(s) : ' + campos.join(', ');
            NotificacaoUtil.exibir(titulo, mensagem, tipo, false);
        }
        return ehValido;
    }
    static confirmacao(titulo, mensagem = null) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.exibir(titulo, mensagem, 'question', true);
            }
            catch (ex) {
                console.log(ex);
                const mensagem = ex.message ? ex.message : 'Sem mensagem de erro aparente';
                const titulo = 'Entre em contato com Suporte Técnico';
                NotificacaoUtil.excecao(titulo, mensagem);
            }
            return null;
        });
    }
    static confirmacaoSucesso(titulo, mensagem = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.exibir(titulo, mensagem, 'success', true);
        });
    }
    static exibir(titulo, mensagem, tipo, ehConfirmacao) {
        return __awaiter(this, void 0, void 0, function* () {
            const ehCelular = DispositivoUtil_1.DispositivoUtil.ehCelular();
            return new Promise((resolve, reject) => {
                try {
                    if ((typeof globalThis.notificacaoUtil === 'undefined') || (globalThis.notificacaoUtil === null)) {
                        globalThis.notificacaoUtil = {};
                    }
                    if ((typeof globalThis.notificacaoUtil.timeoutVibrate !== 'undefined') && globalThis.notificacaoUtil.timeoutVibrate) {
                        clearTimeout(globalThis.notificacaoUtil.timeoutVibrate);
                        globalThis.notificacaoUtil.timeoutVibrate = null;
                    }
                    if ((typeof globalThis.notificacaoUtil.timeoutDialog !== 'undefined') && globalThis.notificacaoUtil.timeoutDialog) {
                        clearTimeout(globalThis.notificacaoUtil.timeoutDialog);
                        globalThis.notificacaoUtil.timeoutDialog = null;
                    }
                    globalThis.notificacaoUtil.timeoutVibrate = setTimeout(() => __awaiter(this, void 0, void 0, function* () {
                        if ((typeof navigator !== 'undefined') && navigator && (typeof navigator.vibrate !== 'undefined')) {
                            navigator.vibrate(750);
                        }
                    }), 150);
                    globalThis.notificacaoUtil.timeoutDialog = setTimeout((resolve, titulo, mensagem, tipo, ehConfirmacao, ehCelular) => __awaiter(this, void 0, void 0, function* () {
                        const temTitulo = (typeof titulo !== 'undefined') && titulo && (titulo.trim().length > 0) ? true : false;
                        const tituloHtml = temTitulo ? titulo.replace(/\r\n/g, '<br>\r\n') : null;
                        const tituloSemHtml = temTitulo ? titulo.replace(/(<([^>]+)>)/ig, '') : null;
                        const temHtmlTitulo = temTitulo && tituloHtml && ((tituloHtml.indexOf('<br>') !== -1) ||
                            (tituloHtml.indexOf('<b>') !== -1) ||
                            (tituloHtml.indexOf('<strong>') !== -1) ||
                            (tituloHtml.indexOf('<div>') !== -1) ||
                            (tituloHtml.indexOf('<span>') !== -1) ||
                            (tituloHtml.indexOf('<h1>') !== -1)) ? true : false;
                        const temMensagem = (typeof mensagem !== 'undefined') && mensagem && (mensagem.trim().length > 0) ? true : false;
                        const mensagemHtml = temMensagem ? mensagem.replace(/\r\n/g, '<br>\r\n') : null;
                        const mensagemSemHtml = temMensagem ? mensagem.replace(/(<([^>]+)>)/ig, '') : null;
                        const temHtmlMensagem = temMensagem && mensagemHtml && ((mensagemHtml.indexOf('<br>') !== -1) ||
                            (mensagemHtml.indexOf('<b>') !== -1) ||
                            (mensagemHtml.indexOf('<strong>') !== -1) ||
                            (mensagemHtml.indexOf('<div>') !== -1) ||
                            (mensagemHtml.indexOf('<span>') !== -1) ||
                            (mensagemHtml.indexOf('<h1>') !== -1)) ? true : false;
                        if (!temTitulo && !temMensagem) {
                            return;
                        }
                        if (ehCelular) {
                            if ((typeof ehConfirmacao !== 'undefined') && ehConfirmacao) {
                                if (temMensagem) {
                                    if (window.confirm(mensagemSemHtml)) {
                                        resolve({ isConfirmed: true, isDenied: false, isDismissed: false, value: true });
                                    }
                                    else {
                                        resolve({ isConfirmed: false, isDenied: true, isDismissed: false, value: false, dismiss: sweetalert2_1.default.DismissReason.cancel });
                                    }
                                }
                                else if (temTitulo) {
                                    if (window.confirm(tituloSemHtml)) {
                                        resolve({ isConfirmed: true, isDenied: false, isDismissed: false, value: true });
                                    }
                                    else {
                                        resolve({ isConfirmed: false, isDenied: true, isDismissed: false, value: false, dismiss: sweetalert2_1.default.DismissReason.cancel });
                                    }
                                }
                                else if (typeof resolve === 'function') {
                                    resolve({ isConfirmed: false, isDenied: true, isDismissed: false, value: false, dismiss: sweetalert2_1.default.DismissReason.cancel });
                                }
                            }
                            else {
                                if (temMensagem) {
                                    window.alert(mensagemSemHtml);
                                }
                                else if (temTitulo) {
                                    window.alert(tituloSemHtml);
                                }
                                if (typeof resolve === 'function') {
                                    resolve();
                                }
                            }
                        }
                        else {
                            let swalOptions = {
                                icon: (typeof tipo !== 'undefined') && tipo ? tipo : 'info',
                                showCancelButton: false,
                                showConfirmButton: true,
                                confirmButtonText: 'Ok'
                            };
                            if ((typeof ehConfirmacao !== 'undefined') && ehConfirmacao) {
                                swalOptions.showConfirmButton = true;
                                swalOptions.confirmButtonColor = '#3085d6';
                                swalOptions.confirmButtonText = 'Sim';
                                swalOptions.showCancelButton = true;
                                swalOptions.cancelButtonColor = '#d33';
                                swalOptions.cancelButtonText = 'Não';
                            }
                            if (temMensagem && temHtmlMensagem) {
                                swalOptions.html = mensagemHtml;
                            }
                            else if (temTitulo && temHtmlTitulo && !temMensagem) {
                                swalOptions.html = tituloHtml;
                            }
                            else if (temTitulo && !temMensagem) {
                                swalOptions.text = titulo;
                            }
                            else if (temTitulo || temMensagem) {
                                if (temTitulo) {
                                    swalOptions.title = titulo;
                                }
                                if (temMensagem) {
                                    swalOptions.text = mensagem;
                                }
                            }
                            sweetalert2_1.default.fire(swalOptions).then((result) => {
                                if (typeof resolve !== 'undefined') {
                                    resolve(result);
                                }
                            });
                        }
                    }), 175, resolve, titulo, mensagem, tipo, ehConfirmacao, ehCelular);
                }
                catch (ex) {
                    return reject(ex.message);
                }
            });
        });
    }
}
exports.NotificacaoUtil = NotificacaoUtil;
