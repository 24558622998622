"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabMaturacaoCones = void 0;
const Base_1 = require("./Base");
class LabMaturacaoCones extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.data = null;
        this.pomar = null;
        this.especie = null;
        this.materialGenetico = null;
        this.pesoN1 = null;
        this.pesoN2 = null;
        this.pesoS1 = null;
        this.pesoS2 = null;
        this.volumeN1 = null;
        this.volumeN2 = null;
        this.volumeS1 = null;
        this.volumeS2 = null;
        this.densidadeN1 = null;
        this.densidadeN2 = null;
        this.densidadeS1 = null;
        this.densidadeS2 = null;
        this.densidadeFinal = null;
    }
}
exports.LabMaturacaoCones = LabMaturacaoCones;
