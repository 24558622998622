"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlanilhaFactory = void 0;
const CargaPlanilha_1 = require("../../../../io/CargaPlanilha");
const CarregamentoUtil_1 = require("../../../../util/CarregamentoUtil");
const DialogoFactory_1 = require("../../../fabricas/DialogoFactory");
const ManipulaErroFactory_1 = require("../../../fabricas/ManipulaErroFactory");
const NotificacaoFactory_1 = require("../../../fabricas/NotificacaoFactory");
const RestFactory_1 = require("../../../fabricas/RestFactory");
const RotaUtil_1 = require("../../../util/RotaUtil");
const ICRUDOperacao_1 = require("../../app/interfaces/ICRUDOperacao");
const PlanilhaDialogoController_1 = require("../dialogos/planilha/PlanilhaDialogoController");
const sweetalert2_1 = require("sweetalert2");
class PlanilhaFactory {
    constructor($state, rest, dialogo, manipulaErro, notificacao) {
        this.$state = $state;
        this.rest = rest;
        this.dialogo = dialogo;
        this.manipulaErro = manipulaErro;
        this.notificacao = notificacao;
        this.links = null;
        this.links = RotaUtil_1.RotaUtil.gerarLinks(this.$state);
    }
    abrir($event, tipo, mostrarPlanilhaModelo) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const planilhaUpload = new CargaPlanilha_1.CargaPlanilha();
                planilhaUpload.tipo = tipo;
                if ((planilhaUpload.tipo !== null) && (planilhaUpload.tipo === 'sgif_talhao')) {
                    planilhaUpload.tipo = 'sgif_importa_talhao';
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.content-body');
                let resposta = yield this.rest.get(`ferramenta/carga?tipo=${planilhaUpload.tipo}`);
                if (resposta.dados) {
                    const modelo = resposta.dados;
                    const planilha = {
                        geds: []
                    };
                    const modal = {
                        $event: $event,
                        controller: PlanilhaDialogoController_1.PlanilhaDialogoController,
                        model: planilha,
                        data: Object.assign({ mostrarPlanilhaModelo: mostrarPlanilhaModelo }, modelo),
                        url: require('../dialogos/planilha/planilha.dialogo.html').default,
                        operacao: ICRUDOperacao_1.CRUDOperacao.CRIAR
                    };
                    const ehUpload = yield this.dialogo.exibirModal(modal);
                    if (ehUpload) {
                        planilhaUpload.planilhas = planilha;
                        resposta = yield this.rest.post(`ferramenta/carga`, planilhaUpload);
                        if (!resposta.dados) {
                            this.notificacao.servidor(resposta);
                        }
                        else if ((typeof resposta.mensagens !== 'undefined') && (resposta.mensagens !== null) && (resposta.mensagens.length > 0) &&
                            (resposta.mensagens[0] !== null) && (resposta.mensagens[0].codigo !== null) &&
                            (resposta.mensagens[0].codigo !== 'SUC-0001') && (resposta.mensagens[0].texto !== null)) {
                            this.notificacao.servidor(resposta);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                title: 'Processado com sucesso!',
                                icon: 'success',
                                confirmButtonText: 'OK',
                            }).then(() => {
                                if ((planilhaUpload.tipo !== null) && (planilhaUpload.tipo === 'sgif_importa_talhao')) {
                                    this.$state.go('cadastro.sgifImportaTalhao-listar');
                                }
                                else {
                                    document.location.reload();
                                }
                            });
                        }
                    }
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                }
                else {
                    this.notificacao.alerta('Erro ao Abrir Tela de Importação', 'Erro ao carregar modelo de planilha.');
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
}
exports.PlanilhaFactory = PlanilhaFactory;
PlanilhaFactory.id = PlanilhaFactory.name;
PlanilhaFactory.$inject = ['$state', RestFactory_1.RestFactory.id, DialogoFactory_1.DialogoFactory.id, ManipulaErroFactory_1.ManipulaErroFactory.id, NotificacaoFactory_1.NotificacaoFactory.id];
