"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CaracteristicaDescritorMorfologicoController = void 0;
const angular = require("angular");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const UiGridConstrutor_1 = require("../../../app/construtores/UiGridConstrutor");
const AbstratoController_1 = require("../../../app/AbstratoController");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const ICRUDOperacao_1 = require("../../../app/interfaces/ICRUDOperacao");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const config_app_1 = require("../../../../../config/config.app");
const sweetalert2_1 = require("sweetalert2");
const CaracteristicaDescritorMorfologico_1 = require("../../../../../modelos/CaracteristicaDescritorMorfologico");
const IdentificacaoCaracteristica_1 = require("../../../../../modelos/IdentificacaoCaracteristica");
class CaracteristicaDescritorMorfologicoController extends AbstratoController_1.AbstratoController {
    constructor($scope, $injector) {
        super($scope, $injector, new CaracteristicaDescritorMorfologico_1.CaracteristicaDescritorMorfologico());
        this.path = '';
        this.caminho = '';
        this.tipoOperacao = null;
        this.posicaoTopoAntesModal = null;
        this.modalIdentificadores = false;
        this.faixa4Meses = CaracteristicaDescritorMorfologico_1.CaracteristicaDescritorMorfologico.FAIXA_4_MESES;
        this.faixa6Meses = CaracteristicaDescritorMorfologico_1.CaracteristicaDescritorMorfologico.FAIXA_6_MESES;
        this.faixa1Ano = CaracteristicaDescritorMorfologico_1.CaracteristicaDescritorMorfologico.FAIXA_1_ANO;
        this.faixa3Anos = CaracteristicaDescritorMorfologico_1.CaracteristicaDescritorMorfologico.FAIXA_3_ANOS;
        this.faixaAdulto = CaracteristicaDescritorMorfologico_1.CaracteristicaDescritorMorfologico.FAIXA_ADULTO;
        this.faixa5Anos = CaracteristicaDescritorMorfologico_1.CaracteristicaDescritorMorfologico.FAIXA_5_ANOS;
        this.tipoOperacao = ICRUDOperacao_1.CRUDOperacao;
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.path = config_app_1.config.api.caminho;
            this.caminho =
                this.path + RotaAPIConstantes_1.RotaAPIConstantes.CARACTERISTICA_DESCRITOR_MORFOLOGICO;
            this.modalIdentificadores = false;
            this.$scope.$apply();
        });
    }
    salvar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.modalIdentificadores = false;
                if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.CARACTERISTICA_DESCRITOR_MORFOLOGICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CARACTERISTICA_DESCRITOR_MORFOLOGICO)
                        .criar(this.modelo);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.CARACTERISTICA_DESCRITOR_MORFOLOGICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CARACTERISTICA_DESCRITOR_MORFOLOGICO)
                        .atualizar(this.modelo);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir uma nova Característica de Descritor Morfológico ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir uma nova Característica de Descritor Morfológico ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    visualizar(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const caracteristicaDescritorMorfologico = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.CARACTERISTICA_DESCRITOR_MORFOLOGICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CARACTERISTICA_DESCRITOR_MORFOLOGICO)
                    .id(id)
                    .ver();
                caracteristicaDescritorMorfologico.criacao = new Date(caracteristicaDescritorMorfologico.criacao);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return caracteristicaDescritorMorfologico;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    remover(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const caracteristicaDescritorMorfologico = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.CARACTERISTICA_DESCRITOR_MORFOLOGICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CARACTERISTICA_DESCRITOR_MORFOLOGICO)
                    .id(id)
                    .remover();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (caracteristicaDescritorMorfologico) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const caracteristicaDescritorMorfologico = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.CARACTERISTICA_DESCRITOR_MORFOLOGICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CARACTERISTICA_DESCRITOR_MORFOLOGICO)
                    .id(this.modelo.id)
                    .restaurar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (caracteristicaDescritorMorfologico) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosLista(filtro = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                this.path = config_app_1.config.api.caminho;
                this.caminho =
                    this.path + RotaAPIConstantes_1.RotaAPIConstantes.CARACTERISTICA_DESCRITOR_MORFOLOGICO;
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                var auxColunas = Array();
                auxColunas.push({
                    name: 'codigo',
                    displayName: 'Código',
                    width: 90,
                });
                auxColunas.push({
                    name: 'classificacaoVida',
                    displayName: 'Nome da Característica',
                    width: 200,
                });
                auxColunas.push({
                    name: 'nomeCaracteristica',
                    displayName: 'Nome da Característica',
                    width: 200,
                });
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
                const lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.CARACTERISTICA_DESCRITOR_MORFOLOGICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CARACTERISTICA_DESCRITOR_MORFOLOGICO)
                    .filtro(filtro)
                    .listar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    filtrar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    limparFiltro() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    exibirModalIdentificadores() {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.modelo === 'undefined' || !this.modelo) {
                return;
            }
            if (typeof this.modelo.identificadores === 'undefined' ||
                this.modelo.identificadores === null) {
                this.modelo.identificadores = new Array();
            }
            try {
                try {
                    this.posicaoTopoAntesModal = angular.element(window).scrollTop();
                }
                catch (ex) {
                    this.posicaoTopoAntesModal = null;
                    console.log(ex);
                }
                this.modalIdentificadores = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalIdentificadores() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalIdentificadores = false;
            angular.element('body').css('overflow-y', '');
            if (typeof this.modelo !== 'undefined' &&
                this.modelo &&
                typeof this.modelo.identificadores !== 'undefined' &&
                this.modelo.identificadores) {
                this.modelo.identificadores = this.modelo.identificadores;
            }
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    moverIdentificadoresParaCima(key) {
        if (typeof this.modelo === 'undefined' ||
            !this.modelo ||
            typeof this.modelo.identificadores === 'undefined' ||
            !this.modelo.identificadores) {
            return;
        }
        if (key !== null &&
            key > 0 &&
            key <= this.modelo.identificadores.length - 1) {
            let oldKey = key;
            let newKey = key - 1;
            this.modelo.identificadores.splice(newKey, 0, this.modelo.identificadores.splice(oldKey, 1)[0]);
            this.scopeApply();
        }
    }
    moverIdentificadoresParaBaixo(key) {
        if (typeof this.modelo === 'undefined' ||
            !this.modelo ||
            typeof this.modelo.identificadores === 'undefined' ||
            !this.modelo.identificadores) {
            return;
        }
        if (key != null &&
            key >= 0 &&
            key < this.modelo.identificadores.length - 1) {
            let oldKey = key;
            let newKey = key + 1;
            this.modelo.identificadores.splice(newKey, 0, this.modelo.identificadores.splice(oldKey, 1)[0]);
            this.scopeApply();
        }
    }
    adicionarIdentificadores() {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.modelo === 'undefined' || !this.modelo) {
                return;
            }
            if (typeof this.modelo.identificadores === 'undefined' ||
                this.modelo.identificadores === null) {
                this.modelo.identificadores = new Array();
            }
            this.modelo.identificadores.push(new IdentificacaoCaracteristica_1.IdentificacaoCaracteristica());
            this.scopeApply();
        });
    }
    removerIdentificadores(key) {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.modelo === 'undefined' || !this.modelo) {
                return;
            }
            if (typeof this.modelo.identificadores === 'undefined' ||
                this.modelo.identificadores === null) {
                this.modelo.identificadores = new Array();
            }
            if (typeof key !== 'undefined' &&
                key !== null &&
                this.modelo.identificadores.length > 0 &&
                key <= this.modelo.identificadores.length - 1) {
                this.modelo.identificadores.splice(key, 1);
                this.scopeApply();
            }
        });
    }
}
exports.CaracteristicaDescritorMorfologicoController = CaracteristicaDescritorMorfologicoController;
CaracteristicaDescritorMorfologicoController.$inject = ['$scope', '$injector'];
