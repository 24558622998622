"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelatorioPomaresSementesBancosConservacao = void 0;
const Base_1 = require("./Base");
class RelatorioPomaresSementesBancosConservacao extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.id = null;
        this.numTotalPomares = 0;
        this.numTotalBancos = 0;
        this.numTotalFamilias = 475;
        this.areaTotalPomares = 0;
        this.areaTotalBancos = 0;
        this.numPomaresACS = 19;
        this.numPomaresAPS = 12;
        this.numPomaresPSM = 8;
        this.numPomaresPCS = 35;
        this.numBancosBCG = 12;
        this.numBancosBMG = 4;
        this.numTotalPomaresGeracao1 = 0;
        this.numTotalBancosGeracao1 = 0;
        this.numTotalFamiliasGeracao1 = 185;
        this.numTotalPomaresGeracao15 = 0;
        this.numTotalBancosGeracao15 = 0;
        this.numTotalFamiliasGeracao15 = 98;
        this.numTotalPomaresGeracao2 = 0;
        this.numTotalBancosGeracao2 = 0;
        this.numTotalFamiliasGeracao2 = 132;
        this.numTotalPomaresGeracao25 = 0;
        this.numTotalBancosGeracao25 = 0;
        this.numTotalFamiliasGeracao25 = 37;
        this.numTotalPomaresGeracao3 = 0;
        this.numTotalBancosGeracao3 = 0;
        this.numTotalFamiliasGeracao3 = 23;
        this.numPomaresEucalyptusDunnii = 12;
        this.areaPomaresEucalyptusDunnii = 0;
        this.numBancosEucalyptusDunnii = 4;
        this.areaBancosEucalyptusDunnii = 0;
        this.numFamiliasEucalyptusDunnii = 34;
        this.numPomaresEucalyptusBenthamii = 4;
        this.areaPomaresEucalyptusBenthamii = 0;
        this.numBancosEucalyptusBenthamii = 2;
        this.areaBancosEucalyptusBenthamii = 0;
        this.numFamiliasEucalyptusBenthamii = 18;
        this.numPomaresEucalyptusOutros = 11;
        this.areaPomaresEucalyptusOutros = 0;
        this.numBancosEucalyptusOutros = 8;
        this.areaBancosEucalyptusOutros = 0;
        this.numFamiliasEucalyptusOutros = 27;
        this.numPomaresPinusTaeda = 19;
        this.areaPomaresPinusTaeda = 0;
        this.numBancosPinusTaeda = 6;
        this.areaBancosPinusTaeda = 0;
        this.numFamiliasPinusTaeda = 35;
        this.numPomaresPinusElliottii = 13;
        this.areaPomaresPinusElliottii = 0;
        this.numBancosPinusElliottii = 3;
        this.areaBancosPinusElliottii = 0;
        this.numFamiliasPinusElliottii = 28;
        this.numPomaresPinusOutros = 5;
        this.areaPomaresPinusOutros = 0;
        this.numBancosPinusOutros = 1;
        this.areaBancosPinusOutros = 0;
        this.numFamiliasPinusOutros = 8;
    }
}
exports.RelatorioPomaresSementesBancosConservacao = RelatorioPomaresSementesBancosConservacao;
