"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocalStorageStrategy = exports.IndexedDbStrategy = exports.ArmazenamentoStrategy = exports.ArmazenamentoUtil = void 0;
const angular = require("angular");
const config_app_1 = require("../config/config.app");
const DebugUtil_1 = require("./DebugUtil");
class ArmazenamentoUtil {
    static em(chave) {
        const id = chave;
        if (window.indexedDB) {
            return new IndexedDbStrategy(id);
        }
        else {
            alert('indexed db não ativo neste dispositivo');
        }
        return new LocalStorageStrategy(id);
    }
    static uuid() {
        let dt = Date.now();
        const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            const r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }
    static mongoId() {
        return Math.floor(Date.now() / 1000).toString(16) +
            ' '.repeat(16).replace(/./g, () => Math.floor(Math.random() * 16).toString(16));
    }
    static chaveTabela(chave) {
        if ((typeof chave !== 'undefined') && (chave !== null)) {
            let chaveBase = chave.trim().toLowerCase();
            let tabela = '';
            for (let i = 0, caractere; i < chaveBase.length; i++) {
                caractere = chaveBase.charAt(i);
                if ([' ', '\r\n', '\n', '\r', '-', '+', '*', '\\', '/', '|', ',', '.', ';', ':'].indexOf(caractere) !== -1) {
                    tabela += '_';
                }
                else if (['á', 'Á', 'à', 'À', 'ã', 'Ã', 'â', 'Â', 'ä', 'Ä'].indexOf(caractere) !== -1) {
                    tabela += 'a';
                }
                else if (['é', 'É', 'è', 'È', 'ê', 'Ê', 'ë', 'Ë'].indexOf(caractere) !== -1) {
                    tabela += 'e';
                }
                else if (['í', 'Í', 'ì', 'Ì', 'î', 'Î', 'ï', 'Ï'].indexOf(caractere) !== -1) {
                    tabela += 'i';
                }
                else if (['ó', 'Ó', 'ò', 'Ò', 'õ', 'Õ', 'ô', 'Ô', 'ö', 'Ö'].indexOf(caractere) !== -1) {
                    tabela += 'o';
                }
                else if (['ú', 'Ú', 'ù', 'Ù', 'û', 'Û', 'ü', 'Ü'].indexOf(caractere) !== -1) {
                    tabela += 'u';
                }
                else if (['ç', 'Ç'].indexOf(caractere) !== -1) {
                    tabela += 'c';
                }
                else if (['ñ', 'Ñ'].indexOf(caractere) !== -1) {
                    tabela += 'n';
                }
                else {
                    tabela += caractere;
                }
            }
            while (tabela.indexOf('__') !== -1) {
                tabela = tabela.replace('__', '_');
            }
            return tabela;
        }
        return '';
    }
}
exports.ArmazenamentoUtil = ArmazenamentoUtil;
ArmazenamentoUtil.VERSAO_IndexedDb_32 = 32;
ArmazenamentoUtil.LISTA_TABELAS_IndexedDb_32 = [
    'armazem', 'material_genetico', 'genero', 'especie', 'geracao', 'conjunto',
    'dialelo', 'pomar', 'tipo_pomar', 'classificacao_solo', 'unidade_manejo',
    'regiao', 'talhao', 'importa_talhao', 'importa_shapefile', 'fonte', 'tipo_manejo',
    'tipo_desbaste_manejo_pomar', 'tipo_limpeza_manejo_pomar', 'tipo_amostra_manejo_pomar',
    'finalidade_amostra_manejo_pomar', 'praga_doenca_manejo_pomar', 'funcionario', 'cliente',
    'pais', 'provincia_uf', 'localidade', 'hibridacao', 'polinizacao', 'tipo_teste_populacao',
    'situacao', 'clone_material_genetico', 'tipo_implantacao', 'procedencia', 'reproducao',
    'tipo_pomar_populacao', 'tipo_delineamento', 'disposicao_individuos',
    'classificacao_climatica', 'operacao_manejo_pomar', 'produto_fertilizante',
    'historico_estoque_produto_fertilizante', 'tipo_aplicacao', 'tipo_intervencao',
    'teste_genetico', 'ano_enxertia', 'estrobilo', 'estagio_floral_feminino',
    'estagio_floral_masculino', 'fase_avaliada', 'estagio_floral_eucalipto',
    'maturacao_fruto', 'cultivar', 'categoria_pomar', 'finalidade_semeio',
    'unidade_medida', 'tratamento_fungicida', 'status_minijardim',
    'finalidade_multiplicacao', 'acao_minijardim', 'canaletoes', 'minijardim',
    'tipo_minijardim', 'caixa_dagua', 'receita_solucao', 'nivel_contaminacao',
    'casa_vegetacao', 'cargo_funcao', 'laboratorio', 'area_solicitante', 'tipo_amostra',
    'tipo_analise', 'status_analise', 'safra', 'lote', 'propagulo',
    'coleta_macroestaca', 'classificacao_brotacao', 'produto_defensivo',
    'historico_estoque_produto_defensivo', 'estagio_observacao_polinizacao_pomar',
    'lab_solicitacao_analises', 'lab_maturacao_cones', 'lab_umidade_sementes',
    'lab_germinacao_sementes', 'lab_umidade_polen', 'lab_germinacao_polen',
    'lab_estudo_cones', 'lab_experimentos', 'lab_manuais_procedimentos',
    'analise_interna_germinacao', 'lab_germinacao_sementes_externo',
    'producao_mudas_experimentos', 'producao_mudas_recomendacoes',
    'producao_mudas_seminal', 'producao_mudas_planejamento',
    'producao_mudas_semeio_testes', 'producao_mudas_semeio_enxertia',
    'producao_mudas_meta_producao', 'producao_muda_clonal',
    'producao_muda_clonal_eucalyptus', 'producao_muda_clonal_eucalyptus_resgatemacroestacas',
    'producao_muda_clonal_eucalyptus_resgatemacroestacas_corte_raso',
    'producao_muda_clonal_eucalyptus_resgatemacroestacas_brotacoes',
    'producao_muda_clonal_eucalyptus_resgatemacroestacas_adubacao',
    'producao_muda_clonal_eucalyptus_resgatemacroestacas_controle_pragas',
    'producao_muda_clonal_eucalyptus_resgatemacroestacas_confeccao',
    'producao_muda_clonal_eucalyptus_resgatemacroestacas_avaliacao',
    'producao_muda_clonal_eucalyptus_minijardim',
    'producao_muda_clonal_eucalyptus_minijardim_croqui',
    'producao_muda_clonal_eucalyptus_minijardim_croqui_anexo',
    'producao_muda_clonal_eucalyptus_minijardim_plantio_macroestacas',
    'producao_muda_clonal_eucalyptus_minijardim_plantio_miniestacas',
    'producao_muda_clonal_eucalyptus_minijardim_avaliacao_sobrevivencia',
    'producao_muda_clonal_eucalyptus_minijardim_eliminar_minimacro',
    'producao_muda_clonal_eucalyptus_minijardim_manejo',
    'producao_muda_clonal_eucalyptus_miniestaquia',
    'producao_muda_clonal_eucalyptus_miniestaquia_avaliacao_enraizamento',
    'producao_muda_clonal_eucalyptus_miniestaquia_avaliacao_sobrevivencia_casa_sombra',
    'producao_muda_clonal_eucalyptus_miniestaquia_avaliacao_sobrevivencia_aclimatacao',
    'producao_muda_clonal_pinus', 'producao_muda_clonal_pinus_minijardim',
    'producao_muda_clonal_pinus_minijardim_croqui',
    'producao_muda_clonal_pinus_minijardim_croqui_anexo',
    'producao_muda_clonal_pinus_minijardim_plantio_miniestacas',
    'producao_muda_clonal_pinus_minijardim_avaliacao_sobrevivencia',
    'producao_muda_clonal_pinus_minijardim_eliminar_minimacro',
    'producao_muda_clonal_pinus_minijardim_manejo',
    'producao_muda_clonal_pinus_miniestaquia',
    'producao_muda_clonal_pinus_miniestaquia_avaliacao_enraizamento',
    'producao_muda_clonal_pinus_miniestaquia_avaliacao_sobrevivencia_casa_sombra',
    'producao_muda_clonal_pinus_miniestaquia_avaliacao_sobrevivencia_aclimatacao',
    'producao_muda_controle_praga_doenca', 'protecao_registro_cultivar',
    'protecao_registro_cultivar_descritor_morfologico',
    'protecao_registro_cultivar_laudo_dna',
    'protecao_registro_cultivar_orientacoes_procedimentos',
    'protecao_registro_cultivar_proteccao', 'protecao_registro_cultivar_registro',
    'caracteristica_descritor_morfologico', 'centro_custo_sap',
    'conta_deposito_sap', 'banco_melhoramento_pomar', 'tratamento_pomar',
    'croqui_pomar', 'mapa_pomar', 'planejamento_pomar', 'controle_manejo_pomar',
    'controle_enxertia_pomar', 'produtividade_pomar', 'fenologia_pomar',
    'grafico_fenologia_pomar', 'polinizacao_pomar', 'estoque_polen_polinizacao_pomar',
    'historico_estoque_polen_polinizacao_pomar',
    'estoque_beneficiamento_pinus_polinizacao_pomar',
    'historico_estoque_beneficiamento_pinus_polinizacao_pomar',
    'estoque_beneficiamento_eucalyptus_polinizacao_pomar',
    'historico_estoque_beneficiamento_eucalyptus_polinizacao_pomar',
    'anexo_pomar', 'teste_campo', 'tratamento_teste_genetico',
    'croqui_teste_genetico', 'mapa_Teste_genetico', 'controle_manejoTeste_genetico',
    'medicao_dendrometrica_teste_genetico', 'lancamento_medicao_dendrometrica_teste_genetico',
    'planejamento_medicao_dendrometrica_teste_genetico',
    'planilha_medicao_dendrometrica_teste_genetico',
    'selegen_medicao_dendrometrica_teste_genetico', 'qualidade_madeira_teste_genetico',
    'analise_selecao_teste_genetico', 'sumario_analise_selecao_teste_genetico',
    'planilha_selegen_analise_selecao_teste_genetico',
    'analise_selegen_analise_selecao_teste_genetico',
    'resultado_selegen_analise_selecao_teste_genetico',
    'analise_ano_analise_selecao_teste_genetico', 'analise_blup_analise_selecao_teste_genetico',
    'selecao_genetica_analise_selecao_teste_genetico',
    'selecao_campo_analise_selecao_teste_genetico', 'anexo_analise_selecao_teste_genetico',
    'anexo_teste_genetico', 'protocolo_teste_genetico', 'usuario', 'parametrizacao',
    'file', 'posicao_coluna_tabela', 'visibilidade_coluna_tabela'
];
class ArmazenamentoStrategy {
    constructor(chave) {
        this.chave = chave;
    }
}
exports.ArmazenamentoStrategy = ArmazenamentoStrategy;
class IndexedDbStrategy extends ArmazenamentoStrategy {
    constructor(chave) {
        super(chave);
        this.tabela = null;
        this.tabela = 'armazem';
        if (typeof chave === 'string') {
            let tmpTabela = ArmazenamentoUtil.chaveTabela(chave);
            if (tmpTabela.trim().length > 0) {
                this.tabela = tmpTabela.trim();
            }
        }
    }
    salvar(valor) {
        return __awaiter(this, void 0, void 0, function* () {
            const armazem = yield this.armazem();
            if (Array.isArray(valor)) {
                for (let i = 0; i < valor.length; i++) {
                    yield this.inserirDados(armazem, valor[i]);
                }
            }
            else {
                yield this.inserirDados(armazem, valor);
            }
            return true;
        });
    }
    resetar() {
        return __awaiter(this, void 0, void 0, function* () {
            const armazem = yield this.armazem();
            return new Promise((resolve, reject) => {
                const index = armazem.index('chave');
                const requisicao = index.openCursor(this.chave);
                requisicao.onsuccess = (e) => __awaiter(this, void 0, void 0, function* () {
                    const cursor = e.target['result'];
                    if (cursor) {
                        cursor.delete();
                        cursor.continue();
                    }
                    resolve(true);
                });
                requisicao.onerror = e => reject(e.target);
            });
        });
    }
    ler() {
        return __awaiter(this, void 0, void 0, function* () {
            const strategy = this;
            const armazem = yield strategy.armazem('readonly');
            return new Promise((resolve, reject) => {
                const requisicao = armazem.index('chave').getAll(IDBKeyRange.only(strategy.chave));
                requisicao.onsuccess = e => resolve(e.target['result']);
                requisicao.onerror = (e) => __awaiter(this, void 0, void 0, function* () { return reject(e.target); });
            });
        });
    }
    lerUUIDs() {
        return __awaiter(this, void 0, void 0, function* () {
            const strategy = this;
            const armazem = yield strategy.armazem('readonly');
            return new Promise((resolve, reject) => {
                const requisicao = armazem.index('chave').getAllKeys(IDBKeyRange.only(strategy.chave));
                requisicao.onsuccess = e => resolve(e.target['result']);
                requisicao.onerror = e => reject(e.target);
            });
        });
    }
    item(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const armazem = yield this.armazem('readonly');
            return new Promise((resolve, reject) => {
                const requisicao = armazem.index('uuid').get(IDBKeyRange.only(id));
                requisicao.onsuccess = e => resolve(e.target['result']);
                requisicao.onerror = e => reject(e.target);
            });
        });
    }
    itemById(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const armazem = yield this.armazem('readonly');
            return new Promise((resolve, reject) => {
                const requisicao = armazem.index('id').get(IDBKeyRange.only(id));
                requisicao.onsuccess = e => resolve(e.target['result']);
                requisicao.onerror = e => reject(e.target);
            });
        });
    }
    remover(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const armazem = yield this.armazem();
            return new Promise((resolve, reject) => {
                const requisicao = armazem.delete(IDBKeyRange.only(id));
                requisicao.onsuccess = e => resolve(e.target['result']);
                requisicao.onerror = e => reject(e.target);
            });
        });
    }
    info(etiqueta) {
        return __awaiter(this, void 0, void 0, function* () {
            const armazenamento = new LocalStorageStrategy(this.chave);
            return armazenamento.info(etiqueta);
        });
    }
    pegarId(valor) {
        if ((typeof valor.uuid !== 'undefined') && (valor.uuid !== null) && (String(valor.uuid).trim().length > 0)) {
            return String(valor.uuid);
        }
        else if ((typeof valor.id !== 'undefined') && (valor.id !== null) && (String(valor.id).trim().length > 0)) {
            return String(valor.id);
        }
        else {
            return ArmazenamentoUtil.uuid();
        }
    }
    armazem(modo = 'readwrite') {
        return __awaiter(this, void 0, void 0, function* () {
            const result = new Promise((resolve, reject) => {
                let nomeBanco = config_app_1.config.empresa && config_app_1.config.empresa.indexedDB ? config_app_1.config.empresa.indexedDB : null;
                if ((typeof nomeBanco === 'undefined') || !nomeBanco || (nomeBanco === 'sed5')) {
                    nomeBanco = 'sgmaterialgenetico';
                }
                let banco = window.indexedDB.open(nomeBanco, ArmazenamentoUtil.VERSAO_IndexedDb_32);
                banco.onsuccess = e => resolve(e.target['result']);
                banco.onerror = e => reject(e.target);
                banco.onupgradeneeded = e => {
                    const db = e.target['result'];
                    angular.forEach(ArmazenamentoUtil.LISTA_TABELAS_IndexedDb_32, (tabela) => {
                        if (!db.objectStoreNames.contains(tabela)) {
                            const armazem = db.createObjectStore(tabela, { keyPath: 'uuid' });
                            armazem.createIndex('chave', 'chave', { unique: false });
                            armazem.createIndex('uuid', 'uuid', { unique: false });
                            armazem.createIndex('id', 'id', { unique: false });
                            if ((tabela === 'apontamento') ||
                                (tabela === 'historico_apontamento') ||
                                (tabela === 'regiao_talhao_apontamento')) {
                                armazem.createIndex('idUnicoApontamento', 'idUnicoApontamento', { unique: false });
                            }
                        }
                    });
                };
            });
            let achouTabela = false;
            if ((typeof this.tabela !== 'undefined') && (this.tabela !== null) && (this.tabela.length > 0)) {
                for (let i = 0; i < ArmazenamentoUtil.LISTA_TABELAS_IndexedDb_32.length; i++) {
                    if (ArmazenamentoUtil.LISTA_TABELAS_IndexedDb_32[i] === this.tabela) {
                        achouTabela = true;
                        break;
                    }
                }
            }
            if (!achouTabela) {
                this.tabela = ArmazenamentoUtil.LISTA_TABELAS_IndexedDb_32[0];
            }
            const banco = yield result;
            return banco.transaction(this.tabela, modo).objectStore(this.tabela);
        });
    }
    inserirDados(armazem, valor) {
        return __awaiter(this, void 0, void 0, function* () {
            valor.uuid = this.pegarId(valor);
            valor.chave = this.chave.toString();
            return new Promise((resolve, reject) => {
                const acao = armazem.put(valor);
                acao.onerror = e => reject(e.target);
                acao.onsuccess = e => resolve(e.target);
            });
        });
    }
}
exports.IndexedDbStrategy = IndexedDbStrategy;
class LocalStorageStrategy extends ArmazenamentoStrategy {
    salvar(valor) {
        return __awaiter(this, void 0, void 0, function* () {
            if (valor === null) {
                DebugUtil_1.DebugUtil.tracar();
            }
            return new Promise((resolve, reject) => {
                try {
                    window.localStorage.setItem(this.chave.toString(), JSON.stringify(valor));
                    resolve(true);
                }
                catch (ex) {
                    console.log(ex);
                    reject(ex);
                }
            });
        });
    }
    resetar() {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                try {
                    window.localStorage.removeItem(this.chave.toString());
                    resolve(true);
                }
                catch (ex) {
                    console.log(ex);
                    reject(ex);
                }
            });
        });
    }
    ler() {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                try {
                    const lista = JSON.parse(window.localStorage.getItem(this.chave.toString()));
                    resolve(lista);
                }
                catch (ex) {
                    console.log(ex);
                    reject(ex);
                }
            });
        });
    }
    lerUUIDs() {
        return __awaiter(this, void 0, void 0, function* () {
            return [];
        });
    }
    item(id) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                try {
                    let lista = JSON.parse(window.localStorage.getItem(this.chave.toString()));
                    lista = lista.find(x => x.id === id);
                    resolve(lista);
                }
                catch (ex) {
                    reject(ex);
                }
            });
        });
    }
    itemById(id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.item(id);
        });
    }
    remover(id) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                try {
                    resolve(id);
                }
                catch (ex) {
                    reject(ex);
                }
            });
        });
    }
    info(etiqueta) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                try {
                    let lista = JSON.parse(window.localStorage.getItem(this.chave.toString()));
                    lista = lista.find(x => x.etiqueta === etiqueta);
                    resolve(lista);
                }
                catch (ex) {
                    reject(ex);
                }
            });
        });
    }
}
exports.LocalStorageStrategy = LocalStorageStrategy;
