"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TesteCampoControleManejoUtil = void 0;
const angular = require("angular");
const FiltroControleManejoTesteGenetico_1 = require("../../../../../io/FiltroControleManejoTesteGenetico");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const OperacaoManejoTesteCampo_1 = require("../../../../../modelos/OperacaoManejoTesteCampo");
const OperacaoControleDoencaPomar_1 = require("../../../../../modelos/OperacaoControleDoencaPomar");
const HistoricoEstoqueProdutoDefensivo_1 = require("../../../../../modelos/HistoricoEstoqueProdutoDefensivo");
const HistoricoEstoqueProdutoFertilizante_1 = require("../../../../../modelos/HistoricoEstoqueProdutoFertilizante");
const OperacaoAdubacaoFertilizacaoPomar_1 = require("../../../../../modelos/OperacaoAdubacaoFertilizacaoPomar");
const ControleManejoTesteGenetico_1 = require("../../../../../modelos/ControleManejoTesteGenetico");
const OperacaoLimpezaPomar_1 = require("../../../../../modelos/OperacaoLimpezaPomar");
const OperacaoDesbastePomar_1 = require("../../../../../modelos/OperacaoDesbastePomar");
const OperacaoPolinizacaoPomar_1 = require("../../../../../modelos/OperacaoPolinizacaoPomar");
const OperacaoColetaAmostraPomar_1 = require("../../../../../modelos/OperacaoColetaAmostraPomar");
const OperacaoColetaSementePomar_1 = require("../../../../../modelos/OperacaoColetaSementePomar");
const OutraOperacaoPomar_1 = require("../../../../../modelos/OutraOperacaoPomar");
const LinhaColunaPomar_1 = require("../../../../../modelos/LinhaColunaPomar");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const ArmazenamentoUtil_1 = require("../../../../../util/ArmazenamentoUtil");
const DispositivoUtil_1 = require("../../../../../util/DispositivoUtil");
const sweetalert2_1 = require("sweetalert2");
class TesteCampoControleManejoUtil {
    static visualizacaoResumidaControleManejoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.filtroControleManejoTesteGenetico === 'undefined') || !ctrl.filtroControleManejoTesteGenetico) {
                ctrl.filtroControleManejoTesteGenetico = new FiltroControleManejoTesteGenetico_1.FiltroControleManejoTesteGenetico();
            }
            if (ctrl.filtroControleManejoTesteGenetico.visualizacaoDetalhada) {
                ctrl.filtroControleManejoTesteGenetico.visualizacaoDetalhada = false;
            }
            if (!ctrl.filtroControleManejoTesteGenetico.visualizacaoResumida) {
                ctrl.filtroControleManejoTesteGenetico.visualizacaoResumida = true;
            }
        });
    }
    static visualizacaoDetalhadaControleManejoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.filtroControleManejoTesteGenetico === 'undefined') || !ctrl.filtroControleManejoTesteGenetico) {
                ctrl.filtroControleManejoTesteGenetico = new FiltroControleManejoTesteGenetico_1.FiltroControleManejoTesteGenetico();
            }
            if (ctrl.filtroControleManejoTesteGenetico.visualizacaoResumida) {
                ctrl.filtroControleManejoTesteGenetico.visualizacaoResumida = false;
            }
            if (!ctrl.filtroControleManejoTesteGenetico.visualizacaoDetalhada) {
                ctrl.filtroControleManejoTesteGenetico.visualizacaoDetalhada = true;
            }
        });
    }
    static filtrarControleManejoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const listaOpcoesManejoTesteGenetico = yield ctrl.dadosListaManejoTesteGenetico();
                ctrl.listaOpcoesManejoTesteGeneticoResumido = listaOpcoesManejoTesteGenetico.resumido;
                ctrl.listaOpcoesManejoTesteGeneticoDetalhado = listaOpcoesManejoTesteGenetico.detalhado;
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.scopeApply();
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static salvarControleManejoTesteGenetico(ctrl) {
        var _a, _b, _c, _d, _e, _f;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.controleManejoTesteGeneticoSelecionado || ctrl.controleManejoTesteGeneticoVisualizar) {
                    return;
                }
                let listaCamposObrigatorios = '';
                if ((typeof ctrl.controleManejoTesteGeneticoSelecionado.operacao === 'undefined') || !ctrl.controleManejoTesteGeneticoSelecionado.operacao) {
                    listaCamposObrigatorios += '\r\n - <b>Operação Manejo Pomar</b>';
                }
                if (listaCamposObrigatorios && (listaCamposObrigatorios.length > 0)) {
                    let mensagem = 'Campo obrigatório não preenchido!' +
                        listaCamposObrigatorios + '\r\n \r\n' +
                        'Preencha este campo e tente novamente.';
                    if (ctrl.ehCelular) {
                        alert(mensagem.replace(/<b>/g, '').replace(/<\/b>/g, ''));
                    }
                    else {
                        yield sweetalert2_1.default.fire({
                            html: mensagem.replace(/\r\n/g, '<br>\r\n'),
                            icon: 'info',
                            showCancelButton: false,
                            showConfirmButton: true,
                            confirmButtonText: 'Ok'
                        });
                    }
                    return;
                }
                const operacaoManejoTesteCampo = ((typeof ctrl.controleManejoTesteGeneticoSelecionado.operacao !== 'undefined') && ctrl.controleManejoTesteGeneticoSelecionado.operacao) ? ctrl.controleManejoTesteGeneticoSelecionado.operacao : null;
                const ehOperacaoLimpeza = (operacaoManejoTesteCampo && (typeof operacaoManejoTesteCampo.ehOperacaoLimpeza !== 'undefined') && operacaoManejoTesteCampo.ehOperacaoLimpeza) ? true : false;
                const ehOperacaoDesbaste = (operacaoManejoTesteCampo && (typeof operacaoManejoTesteCampo.ehOperacaoDesbaste !== 'undefined') && operacaoManejoTesteCampo.ehOperacaoDesbaste) ? true : false;
                const ehOperacaoPolinizacao = (operacaoManejoTesteCampo && (typeof operacaoManejoTesteCampo.ehOperacaoPolinizacao !== 'undefined') && operacaoManejoTesteCampo.ehOperacaoPolinizacao) ? true : false;
                const ehOperacaoColetaAmostras = (operacaoManejoTesteCampo && (typeof operacaoManejoTesteCampo.ehOperacaoColetaAmostras !== 'undefined') && operacaoManejoTesteCampo.ehOperacaoColetaAmostras) ? true : false;
                const ehOperacaoAdubacaoFertilizacao = (operacaoManejoTesteCampo && (typeof operacaoManejoTesteCampo.ehOperacaoAdubacaoFertilizacao !== 'undefined') && operacaoManejoTesteCampo.ehOperacaoAdubacaoFertilizacao) ? true : false;
                const ehOperacaoColetaSementes = (operacaoManejoTesteCampo && (typeof operacaoManejoTesteCampo.ehOperacaoColetaSementes !== 'undefined') && operacaoManejoTesteCampo.ehOperacaoColetaSementes) ? true : false;
                const ehOperacaoControleDoencas = (operacaoManejoTesteCampo && (typeof operacaoManejoTesteCampo.ehOperacaoControleDoencas !== 'undefined') && operacaoManejoTesteCampo.ehOperacaoControleDoencas) ? true : false;
                const ehOperacaoPodaRamosPortaEnxerto = (operacaoManejoTesteCampo && (typeof operacaoManejoTesteCampo.ehOperacaoPodaRamosPortaEnxerto !== 'undefined') && operacaoManejoTesteCampo.ehOperacaoPodaRamosPortaEnxerto) ? true : false;
                const ehOperacaoAvaliacaoSobrevivencia = (operacaoManejoTesteCampo && (typeof operacaoManejoTesteCampo.ehOperacaoAvaliacaoSobrevivencia !== 'undefined') && operacaoManejoTesteCampo.ehOperacaoAvaliacaoSobrevivencia) ? true : false;
                const ehOperacaoReplantio = (operacaoManejoTesteCampo && (typeof operacaoManejoTesteCampo.ehOperacaoReplantio !== 'undefined') && operacaoManejoTesteCampo.ehOperacaoReplantio) ? true : false;
                const ehOutrasOperacoes = (operacaoManejoTesteCampo && (typeof operacaoManejoTesteCampo.ehOutrasOperacoes !== 'undefined') && operacaoManejoTesteCampo.ehOutrasOperacoes) ? true : false;
                ctrl.modalTratamentoTesteGenetico = false;
                ctrl.modalGerarCroquiTesteGenetico = false;
                ctrl.modalGerarCroquiTesteGeneticoDados = null;
                ctrl.modalImportacaoCroquiTesteGenetico = false;
                ctrl.modalHistoricoCroquiTesteGenetico = false;
                ctrl.modalMedicaoDendrometricaTesteGenetico = false;
                ctrl.modalPlanejamentoMedicaoDendrometricaTesteGenetico = false;
                ctrl.modalPlanilhaMedicaoDendrometricaTesteGenetico = false;
                ctrl.modalSelegenMedicaoDendrometricaTesteGenetico = false;
                ctrl.modalQualidadeMadeiraTesteGenetico = false;
                ctrl.modalAnaliseSelecaoTesteGenetico = false;
                ctrl.modalSumarioAnaliseSelecaoTesteGenetico = false;
                ctrl.modalPlanilhaSelegenAnaliseSelecaoTesteGenetico = false;
                ctrl.modalAnaliseSelegenAnaliseSelecaoTesteGenetico = false;
                ctrl.modalResultadoSelegenAnaliseSelecaoTesteGenetico = false;
                ctrl.modalAnaliseAnoAnaliseSelecaoTesteGenetico = false;
                ctrl.modalAnaliseBlupAnaliseSelecaoTesteGenetico = false;
                ctrl.modalSelecaoGeneticaAnaliseSelecaoTesteGenetico = false;
                ctrl.modalSelecaoCampoAnaliseSelecaoTesteGenetico = false;
                ctrl.modalAnexoAnaliseSelecaoTesteGenetico = false;
                ctrl.modalRelatorioTesteGenetico = false;
                ctrl.modalAnexoTesteGenetico = false;
                ctrl.modalProtocoloTesteGenetico = false;
                ctrl.modalDossieMaterialGenetico = false;
                ctrl.controleManejoTesteGeneticoSelecionado.testeGenetico = ctrl.testeGeneticoSelecionado;
                if (!ehOperacaoLimpeza) {
                    ctrl.controleManejoTesteGeneticoSelecionado.limpezas = [];
                }
                if (!ehOperacaoDesbaste) {
                    ctrl.controleManejoTesteGeneticoSelecionado.desbastes = [];
                }
                if (!ehOperacaoPolinizacao) {
                    ctrl.controleManejoTesteGeneticoSelecionado.polinizacoes = [];
                }
                if (!ehOperacaoColetaAmostras) {
                    ctrl.controleManejoTesteGeneticoSelecionado.coletasAmostras = [];
                }
                if (!ehOperacaoColetaSementes) {
                    ctrl.controleManejoTesteGeneticoSelecionado.coletasSementes = [];
                }
                if (!ehOperacaoAdubacaoFertilizacao) {
                    ctrl.controleManejoTesteGeneticoSelecionado.adubacoesFertilizacoes = [];
                }
                if (!ehOperacaoControleDoencas) {
                    ctrl.controleManejoTesteGeneticoSelecionado.doencas = [];
                }
                if (!ehOperacaoAvaliacaoSobrevivencia) {
                    ctrl.controleManejoTesteGeneticoSelecionado.avaliacoesSobrevivencia = [];
                }
                if (!ehOperacaoReplantio) {
                    ctrl.controleManejoTesteGeneticoSelecionado.replantios = [];
                }
                if (!ehOperacaoPodaRamosPortaEnxerto && !ehOutrasOperacoes) {
                    ctrl.controleManejoTesteGeneticoSelecionado.outrasOperacoes = [];
                }
                if (ehOperacaoPolinizacao) {
                }
                if (ehOperacaoControleDoencas) {
                    if ((typeof ctrl.controleManejoTesteGeneticoSelecionado.doencas !== 'undefined') && ctrl.controleManejoTesteGeneticoSelecionado.doencas && (ctrl.controleManejoTesteGeneticoSelecionado.doencas.length > 0)) {
                        let listaProdutos = [];
                        angular.forEach(ctrl.controleManejoTesteGeneticoSelecionado.doencas, (produto) => {
                            var _a;
                            if ((typeof produto.produto !== 'undefined') && produto.produto &&
                                (typeof produto.centroCustoSap !== 'undefined') && produto.centroCustoSap &&
                                (typeof produto.contaDepositoSap !== 'undefined') && produto.contaDepositoSap) {
                                let itemProduto = {
                                    produto: produto.produto,
                                    contaDepositoSap: produto.contaDepositoSap,
                                    centroCustoSap: produto.centroCustoSap,
                                    dosagemTotal: (_a = produto.quantidadeArvores) !== null && _a !== void 0 ? _a : 0
                                };
                                let findProduto = listaProdutos.find((o) => (o.produto.id === itemProduto.produto.id) &&
                                    (o.centroCustoSap.id === itemProduto.centroCustoSap.id) && (o.contaDepositoSap.id === itemProduto.contaDepositoSap.id));
                                if ((typeof findProduto !== 'undefined') && findProduto) {
                                    findProduto.dosagemTotal += itemProduto.dosagemTotal;
                                }
                                else {
                                    listaProdutos.push(itemProduto);
                                }
                            }
                        });
                        for (const produtoFinal of listaProdutos) {
                            let produtoDefensivo = yield ctrl.sincronia
                                .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_DEFENSIVO)
                                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_DEFENSIVO)
                                .id(produtoFinal.produto.id)
                                .ver();
                            let historicoEstoqueProdutoDefensivo = new HistoricoEstoqueProdutoDefensivo_1.HistoricoEstoqueProdutoDefensivo();
                            if ((typeof produtoDefensivo !== 'undefined') && produtoDefensivo) {
                                historicoEstoqueProdutoDefensivo.produtoDefensivo = produtoFinal.produto;
                                historicoEstoqueProdutoDefensivo.quantidadeAnterior = (_a = produtoDefensivo.quantidadeAtual) !== null && _a !== void 0 ? _a : 0;
                                historicoEstoqueProdutoDefensivo.quantidadeMovimento = (_b = produtoFinal.dosagemTotal) !== null && _b !== void 0 ? _b : 0;
                                historicoEstoqueProdutoDefensivo.quantidadeAtual = historicoEstoqueProdutoDefensivo.quantidadeAnterior - historicoEstoqueProdutoDefensivo.quantidadeMovimento;
                                historicoEstoqueProdutoDefensivo.movimento = HistoricoEstoqueProdutoDefensivo_1.HistoricoEstoqueProdutoDefensivo.MOVIMENTO_SAIDA;
                                historicoEstoqueProdutoDefensivo.usuario = ctrl.usuarioLogado;
                                historicoEstoqueProdutoDefensivo.centroCustoSap = produtoFinal.centroCustoSap;
                                historicoEstoqueProdutoDefensivo.contaDepositoSap = produtoFinal.contaDepositoSap;
                                produtoDefensivo.quantidadeAtual = historicoEstoqueProdutoDefensivo.quantidadeAtual;
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.HISTORICO_ESTOQUE_PRODUTO_DEFENSIVO)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.HISTORICO_ESTOQUE_PRODUTO_DEFENSIVO)
                                    .criar(historicoEstoqueProdutoDefensivo);
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_DEFENSIVO)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_DEFENSIVO)
                                    .atualizar(produtoDefensivo);
                            }
                        }
                    }
                }
                if (ehOperacaoLimpeza) {
                    if ((typeof ctrl.controleManejoTesteGeneticoSelecionado.limpezas !== 'undefined') && ctrl.controleManejoTesteGeneticoSelecionado.limpezas && ctrl.controleManejoTesteGeneticoSelecionado.limpezas.length > 0) {
                        let listaProdutos = [];
                        angular.forEach(ctrl.controleManejoTesteGeneticoSelecionado.limpezas, (produto) => {
                            var _a;
                            if ((typeof produto.produto !== 'undefined') && produto.produto &&
                                (typeof produto.centroCustoSap !== 'undefined') && produto.centroCustoSap &&
                                (typeof produto.contaDepositoSap !== 'undefined') && produto.contaDepositoSap &&
                                (typeof produto.tipoLimpeza.usaProduto !== 'undefined') && produto.tipoLimpeza.usaProduto) {
                                let itemProduto = {
                                    produto: produto.produto,
                                    contaDepositoSap: produto.contaDepositoSap,
                                    centroCustoSap: produto.centroCustoSap,
                                    dosagemTotal: (_a = produto.dosagemProduto) !== null && _a !== void 0 ? _a : 0
                                };
                                let findProduto = listaProdutos.find((o) => (o.produto.id === itemProduto.produto.id) &&
                                    (o.centroCustoSap.id === itemProduto.centroCustoSap.id) && (o.contaDepositoSap.id === itemProduto.contaDepositoSap.id));
                                if ((typeof findProduto !== 'undefined') && findProduto) {
                                    findProduto.dosagemTotal += itemProduto.dosagemTotal;
                                }
                                else {
                                    listaProdutos.push(itemProduto);
                                }
                            }
                        });
                        for (const produtoFinal of listaProdutos) {
                            let produtoFertilizante = yield ctrl.sincronia
                                .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                                .id(produtoFinal.produto.id)
                                .ver();
                            let historicoEstoqueProdutoFertilizante = new HistoricoEstoqueProdutoFertilizante_1.HistoricoEstoqueProdutoFertilizante();
                            if ((typeof produtoFertilizante !== 'undefined') && produtoFertilizante) {
                                historicoEstoqueProdutoFertilizante.produtoFertilizante = produtoFinal.produto;
                                historicoEstoqueProdutoFertilizante.quantidadeAnterior = (_c = produtoFertilizante.quantidadeAtual) !== null && _c !== void 0 ? _c : 0;
                                historicoEstoqueProdutoFertilizante.quantidadeMovimento = (_d = produtoFinal.dosagemTotal) !== null && _d !== void 0 ? _d : 0;
                                historicoEstoqueProdutoFertilizante.quantidadeAtual = historicoEstoqueProdutoFertilizante.quantidadeAnterior - historicoEstoqueProdutoFertilizante.quantidadeMovimento;
                                historicoEstoqueProdutoFertilizante.movimento = HistoricoEstoqueProdutoFertilizante_1.HistoricoEstoqueProdutoFertilizante.MOVIMENTO_SAIDA;
                                historicoEstoqueProdutoFertilizante.usuario = ctrl.usuarioLogado;
                                historicoEstoqueProdutoFertilizante.centroCustoSap = produtoFinal.centroCustoSap;
                                historicoEstoqueProdutoFertilizante.contaDepositoSap = produtoFinal.contaDepositoSap;
                                produtoFertilizante.quantidadeAtual = historicoEstoqueProdutoFertilizante.quantidadeAtual;
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.HISTORICO_ESTOQUE_PRODUTO_FERTILIZANTE)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.HISTORICO_ESTOQUE_PRODUTO_FERTILIZANTE)
                                    .criar(historicoEstoqueProdutoFertilizante);
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                                    .atualizar(produtoFertilizante);
                            }
                        }
                    }
                }
                if (ehOperacaoAdubacaoFertilizacao) {
                    if ((typeof ctrl.controleManejoTesteGeneticoSelecionado.adubacoesFertilizacoes !== 'undefined') && ctrl.controleManejoTesteGeneticoSelecionado.adubacoesFertilizacoes && ctrl.controleManejoTesteGeneticoSelecionado.adubacoesFertilizacoes.length > 0) {
                        let listaProdutos = [];
                        angular.forEach(ctrl.controleManejoTesteGeneticoSelecionado.adubacoesFertilizacoes, (produto) => {
                            var _a;
                            if ((typeof produto.produto !== 'undefined') && produto.produto &&
                                (typeof produto.centroCustoSap !== 'undefined') && produto.centroCustoSap &&
                                (typeof produto.contaDepositoSap !== 'undefined') && produto.contaDepositoSap &&
                                (typeof produto.tipoAplicacao !== 'undefined') && produto.tipoAplicacao) {
                                let itemProduto = {
                                    produto: produto.produto,
                                    contaDepositoSap: produto.contaDepositoSap,
                                    centroCustoSap: produto.centroCustoSap,
                                    dosagemTotal: (_a = produto.dosagemProduto) !== null && _a !== void 0 ? _a : 0
                                };
                                let findProduto = listaProdutos.find((o) => (o.produto.id === itemProduto.produto.id) &&
                                    (o.centroCustoSap.id === itemProduto.centroCustoSap.id) && (o.contaDepositoSap.id === itemProduto.contaDepositoSap.id));
                                if ((typeof findProduto !== 'undefined') && findProduto) {
                                    findProduto.dosagemTotal += itemProduto.dosagemTotal;
                                }
                                else {
                                    listaProdutos.push(itemProduto);
                                }
                            }
                        });
                        for (const produtoFinal of listaProdutos) {
                            let produtoFertilizante = yield ctrl.sincronia
                                .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                                .id(produtoFinal.produto.id)
                                .ver();
                            let historicoEstoqueProdutoFertilizante = new HistoricoEstoqueProdutoFertilizante_1.HistoricoEstoqueProdutoFertilizante();
                            if ((typeof produtoFertilizante !== 'undefined') && produtoFertilizante) {
                                historicoEstoqueProdutoFertilizante.produtoFertilizante = produtoFinal.produto;
                                historicoEstoqueProdutoFertilizante.quantidadeAnterior = (_e = produtoFertilizante.quantidadeAtual) !== null && _e !== void 0 ? _e : 0;
                                historicoEstoqueProdutoFertilizante.quantidadeMovimento = (_f = produtoFinal.dosagemTotal) !== null && _f !== void 0 ? _f : 0;
                                historicoEstoqueProdutoFertilizante.quantidadeAtual = historicoEstoqueProdutoFertilizante.quantidadeAnterior - historicoEstoqueProdutoFertilizante.quantidadeMovimento;
                                historicoEstoqueProdutoFertilizante.movimento = HistoricoEstoqueProdutoFertilizante_1.HistoricoEstoqueProdutoFertilizante.MOVIMENTO_SAIDA;
                                historicoEstoqueProdutoFertilizante.usuario = ctrl.usuarioLogado;
                                historicoEstoqueProdutoFertilizante.centroCustoSap = produtoFinal.centroCustoSap;
                                historicoEstoqueProdutoFertilizante.contaDepositoSap = produtoFinal.contaDepositoSap;
                                produtoFertilizante.quantidadeAtual = historicoEstoqueProdutoFertilizante.quantidadeAtual;
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.HISTORICO_ESTOQUE_PRODUTO_FERTILIZANTE)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.HISTORICO_ESTOQUE_PRODUTO_FERTILIZANTE)
                                    .criar(historicoEstoqueProdutoFertilizante);
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                                    .atualizar(produtoFertilizante);
                            }
                        }
                    }
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                if (!ctrl.controleManejoTesteGeneticoSelecionado.id) {
                    ctrl.controleManejoTesteGeneticoSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.CONTROLE_MANEJO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CONTROLE_MANEJO_TESTE_GENETICO)
                        .criar(ctrl.controleManejoTesteGeneticoSelecionado);
                }
                else {
                    ctrl.controleManejoTesteGeneticoSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.CONTROLE_MANEJO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CONTROLE_MANEJO_TESTE_GENETICO)
                        .atualizar(ctrl.controleManejoTesteGeneticoSelecionado);
                }
                if ((typeof ctrl.controleManejoTesteGeneticoSelecionado.pomar !== 'undefined') && ctrl.controleManejoTesteGeneticoSelecionado.pomar &&
                    (typeof ctrl.controleManejoTesteGeneticoSelecionado.pomar.descricao !== 'undefined') && ctrl.controleManejoTesteGeneticoSelecionado.pomar.descricao) {
                    ctrl.controleManejoTesteGeneticoSelecionado.pomarDescricao = ctrl.controleManejoTesteGeneticoSelecionado.pomar.descricao;
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (ctrl.controleManejoTesteGeneticoSelecionado && ctrl.controleManejoTesteGeneticoSelecionado.id) {
                    ctrl.modalControleManejoTesteGenetico = false;
                    if (ctrl.ehCelular) {
                        alert(`Salvo com sucesso!`);
                    }
                    else {
                        sweetalert2_1.default.fire({
                            title: 'Salvo com sucesso!',
                            text: 'O lançamento foi salvo no banco de dados.',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'Ok'
                        });
                    }
                    const listaOpcoesManejoTesteGenetico = yield ctrl.dadosListaManejoTesteGenetico();
                    ctrl.listaOpcoesManejoTesteGeneticoResumido = listaOpcoesManejoTesteGenetico.resumido;
                    ctrl.listaOpcoesManejoTesteGeneticoDetalhado = listaOpcoesManejoTesteGenetico.detalhado;
                }
                ctrl.ocultarModalControleManejoTesteGenetico();
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static exibirModalControleManejoTesteGenetico(ctrl, dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.testeGeneticoSelecionado === 'undefined') || !ctrl.testeGeneticoSelecionado) {
                return;
            }
            try {
                ctrl.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                ctrl.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                ctrl.controleManejoTesteGeneticoVisualizar = true;
                if ((typeof dados !== 'undefined') && dados && (typeof dados.id !== 'undefined') && dados.id) {
                    ctrl.controleManejoTesteGeneticoSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.CONTROLE_MANEJO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CONTROLE_MANEJO_TESTE_GENETICO)
                        .id(dados.id)
                        .ver();
                    if ((typeof ctrl.controleManejoTesteGeneticoSelecionado === 'undefined') || !ctrl.controleManejoTesteGeneticoSelecionado) {
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        if (ctrl.ehCelular) {
                            alert(`Registro "${dados.id} não encontrado!`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                text: `Registro "${dados.id}" não encontrado.`,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        return;
                    }
                    if ((typeof ctrl.controleManejoTesteGeneticoSelecionado.criacao === 'string') && ctrl.controleManejoTesteGeneticoSelecionado.criacao) {
                        ctrl.controleManejoTesteGeneticoSelecionado.criacao = new Date(ctrl.controleManejoTesteGeneticoSelecionado.criacao);
                    }
                    if ((typeof ctrl.controleManejoTesteGeneticoSelecionado.data === 'string') && ctrl.controleManejoTesteGeneticoSelecionado.data) {
                        ctrl.controleManejoTesteGeneticoSelecionado.data = new Date(ctrl.controleManejoTesteGeneticoSelecionado.data);
                    }
                    if ((typeof ctrl.controleManejoTesteGeneticoSelecionado.dataIsolamento === 'string') && ctrl.controleManejoTesteGeneticoSelecionado.dataIsolamento) {
                        ctrl.controleManejoTesteGeneticoSelecionado.dataIsolamento = new Date(ctrl.controleManejoTesteGeneticoSelecionado.dataIsolamento);
                    }
                    if ((typeof ctrl.controleManejoTesteGeneticoSelecionado.dataColeta === 'string') && ctrl.controleManejoTesteGeneticoSelecionado.dataColeta) {
                        ctrl.controleManejoTesteGeneticoSelecionado.dataColeta = new Date(ctrl.controleManejoTesteGeneticoSelecionado.dataColeta);
                    }
                    if ((typeof ctrl.controleManejoTesteGeneticoSelecionado.prazo === 'string') && ctrl.controleManejoTesteGeneticoSelecionado.prazo) {
                        ctrl.controleManejoTesteGeneticoSelecionado.prazo = new Date(ctrl.controleManejoTesteGeneticoSelecionado.prazo);
                    }
                    if ((typeof ctrl.controleManejoTesteGeneticoSelecionado.dataRealizado === 'string') && ctrl.controleManejoTesteGeneticoSelecionado.dataRealizado) {
                        ctrl.controleManejoTesteGeneticoSelecionado.dataRealizado = new Date(ctrl.controleManejoTesteGeneticoSelecionado.dataRealizado);
                    }
                    if ((typeof ctrl.controleManejoTesteGeneticoSelecionado.dataHoraInclusao === 'string') && ctrl.controleManejoTesteGeneticoSelecionado.dataHoraInclusao) {
                        ctrl.controleManejoTesteGeneticoSelecionado.dataHoraInclusao = new Date(ctrl.controleManejoTesteGeneticoSelecionado.dataHoraInclusao);
                    }
                    if ((typeof ctrl.controleManejoTesteGeneticoSelecionado.dataHoraAlteracao === 'string') && ctrl.controleManejoTesteGeneticoSelecionado.dataHoraAlteracao) {
                        ctrl.controleManejoTesteGeneticoSelecionado.dataHoraAlteracao = new Date(ctrl.controleManejoTesteGeneticoSelecionado.dataHoraAlteracao);
                    }
                    if ((typeof dados.visualizar !== 'undefined') && dados.visualizar) {
                        ctrl.controleManejoTesteGeneticoVisualizar = true;
                    }
                    else if ((typeof dados.editar !== 'undefined') && dados.editar) {
                        ctrl.controleManejoTesteGeneticoVisualizar = false;
                    }
                    else if ((typeof dados.excluir !== 'undefined') && dados.excluir) {
                        ctrl.notificacao
                            .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                            .then((result) => __awaiter(this, void 0, void 0, function* () {
                            if (result.value) {
                                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.CONTROLE_MANEJO_TESTE_GENETICO)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CONTROLE_MANEJO_TESTE_GENETICO)
                                    .id(dados.id)
                                    .remover();
                                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                                const listaOpcoesManejoTesteGenetico = yield ctrl.dadosListaManejoTesteGenetico();
                                ctrl.listaOpcoesManejoTesteGeneticoResumido = listaOpcoesManejoTesteGenetico.resumido;
                                ctrl.listaOpcoesManejoTesteGeneticoDetalhado = listaOpcoesManejoTesteGenetico.detalhado;
                                ctrl.scopeApply();
                            }
                        }));
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                }
                else {
                    ctrl.controleManejoTesteGeneticoSelecionado = new ControleManejoTesteGenetico_1.ControleManejoTesteGenetico();
                    ctrl.controleManejoTesteGeneticoSelecionado.criacao = new Date();
                    ctrl.controleManejoTesteGeneticoSelecionado.criacao.setMilliseconds(0);
                    ctrl.controleManejoTesteGeneticoVisualizar = false;
                }
                yield ctrl.alterarOperacaoControleManejoTesteGenetico();
                ctrl.modalControleManejoTesteGenetico = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static ocultarModalControleManejoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.modalControleManejoTesteGenetico = false;
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = ctrl.posicaoTopoAntesModal;
            ctrl.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                        (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    static alterarOperacaoControleManejoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.controleManejoTesteGeneticoSelecionado === 'undefined') || !ctrl.controleManejoTesteGeneticoSelecionado ||
                (typeof ctrl.controleManejoTesteGeneticoSelecionado.operacao === 'undefined') || !ctrl.controleManejoTesteGeneticoSelecionado.operacao) {
                return;
            }
            if ((typeof ctrl.controleManejoTesteGeneticoSelecionado.limpezas === 'undefined') || !ctrl.controleManejoTesteGeneticoSelecionado.limpezas) {
                ctrl.controleManejoTesteGeneticoSelecionado.limpezas = new Array();
            }
            if ((typeof ctrl.controleManejoTesteGeneticoSelecionado.desbastes === 'undefined') || !ctrl.controleManejoTesteGeneticoSelecionado.desbastes) {
                ctrl.controleManejoTesteGeneticoSelecionado.desbastes = new Array();
            }
            if ((typeof ctrl.controleManejoTesteGeneticoSelecionado.polinizacoes === 'undefined') || !ctrl.controleManejoTesteGeneticoSelecionado.polinizacoes) {
                ctrl.controleManejoTesteGeneticoSelecionado.polinizacoes = new Array();
            }
            if ((typeof ctrl.controleManejoTesteGeneticoSelecionado.coletasAmostras === 'undefined') || !ctrl.controleManejoTesteGeneticoSelecionado.coletasAmostras) {
                ctrl.controleManejoTesteGeneticoSelecionado.coletasAmostras = new Array();
            }
            if ((typeof ctrl.controleManejoTesteGeneticoSelecionado.coletasSementes === 'undefined') || !ctrl.controleManejoTesteGeneticoSelecionado.coletasSementes) {
                ctrl.controleManejoTesteGeneticoSelecionado.coletasSementes = new Array();
            }
            if ((typeof ctrl.controleManejoTesteGeneticoSelecionado.adubacoesFertilizacoes === 'undefined') || !ctrl.controleManejoTesteGeneticoSelecionado.adubacoesFertilizacoes) {
                ctrl.controleManejoTesteGeneticoSelecionado.adubacoesFertilizacoes = new Array();
            }
            if ((typeof ctrl.controleManejoTesteGeneticoSelecionado.doencas === 'undefined') || !ctrl.controleManejoTesteGeneticoSelecionado.doencas) {
                ctrl.controleManejoTesteGeneticoSelecionado.doencas = new Array();
            }
            if ((typeof ctrl.controleManejoTesteGeneticoSelecionado.outrasOperacoes === 'undefined') || !ctrl.controleManejoTesteGeneticoSelecionado.outrasOperacoes) {
                ctrl.controleManejoTesteGeneticoSelecionado.outrasOperacoes = new Array();
            }
            if ((typeof ctrl.controleManejoTesteGeneticoSelecionado.anexos === 'undefined') || !ctrl.controleManejoTesteGeneticoSelecionado.anexos) {
                ctrl.controleManejoTesteGeneticoSelecionado.anexos = new Array();
            }
            if ((typeof ctrl.controleManejoTesteGeneticoSelecionado.operacao !== 'undefined') && ctrl.controleManejoTesteGeneticoSelecionado.operacao &&
                (typeof ctrl.controleManejoTesteGeneticoSelecionado.operacao.tipoOperacao !== 'undefined') && ctrl.controleManejoTesteGeneticoSelecionado.operacao.tipoOperacao) {
                ctrl.controleManejoTesteGeneticoSelecionado.operacao.ehOperacaoLimpeza = (ctrl.controleManejoTesteGeneticoSelecionado.operacao.tipoOperacao === OperacaoManejoTesteCampo_1.OperacaoManejoTesteCampo.OPERACAO_LIMPEZA);
                ctrl.controleManejoTesteGeneticoSelecionado.operacao.ehOperacaoDesbaste = (ctrl.controleManejoTesteGeneticoSelecionado.operacao.tipoOperacao === OperacaoManejoTesteCampo_1.OperacaoManejoTesteCampo.OPERACAO_DESBASTE);
                ctrl.controleManejoTesteGeneticoSelecionado.operacao.ehOperacaoPolinizacao = (ctrl.controleManejoTesteGeneticoSelecionado.operacao.tipoOperacao === OperacaoManejoTesteCampo_1.OperacaoManejoTesteCampo.OPERACAO_POLINIZACAO);
                ctrl.controleManejoTesteGeneticoSelecionado.operacao.ehOperacaoColetaAmostras = (ctrl.controleManejoTesteGeneticoSelecionado.operacao.tipoOperacao === OperacaoManejoTesteCampo_1.OperacaoManejoTesteCampo.OPERACAO_COLETA_AMOSTRAS);
                ctrl.controleManejoTesteGeneticoSelecionado.operacao.ehOperacaoColetaSementes = (ctrl.controleManejoTesteGeneticoSelecionado.operacao.tipoOperacao === OperacaoManejoTesteCampo_1.OperacaoManejoTesteCampo.OPERACAO_COLETA_SEMENTES);
                ctrl.controleManejoTesteGeneticoSelecionado.operacao.ehOperacaoControleDoencas = (ctrl.controleManejoTesteGeneticoSelecionado.operacao.tipoOperacao === OperacaoManejoTesteCampo_1.OperacaoManejoTesteCampo.OPERACAO_CONTROLE_DOENCAS);
                ctrl.controleManejoTesteGeneticoSelecionado.operacao.ehOperacaoAdubacaoFertilizacao = (ctrl.controleManejoTesteGeneticoSelecionado.operacao.tipoOperacao === OperacaoManejoTesteCampo_1.OperacaoManejoTesteCampo.OPERACAO_ADUBACAO_FERTILIZACAO);
                ctrl.controleManejoTesteGeneticoSelecionado.operacao.ehOperacaoPodaRamosPortaEnxerto = (ctrl.controleManejoTesteGeneticoSelecionado.operacao.tipoOperacao === OperacaoManejoTesteCampo_1.OperacaoManejoTesteCampo.OPERACAO_PODA_RAMOS_PORTA_ENXERTO);
                ctrl.controleManejoTesteGeneticoSelecionado.operacao.ehOperacaoAvaliacaoSobrevivencia = (ctrl.controleManejoTesteGeneticoSelecionado.operacao.tipoOperacao === OperacaoManejoTesteCampo_1.OperacaoManejoTesteCampo.OPERACAO_AVALIACAO_SOBREVIVENCIA);
                ctrl.controleManejoTesteGeneticoSelecionado.operacao.ehOperacaoReplantio = (ctrl.controleManejoTesteGeneticoSelecionado.operacao.tipoOperacao === OperacaoManejoTesteCampo_1.OperacaoManejoTesteCampo.OPERACAO_REPLANTIO);
                ctrl.controleManejoTesteGeneticoSelecionado.operacao.ehOutrasOperacoes = (ctrl.controleManejoTesteGeneticoSelecionado.operacao.tipoOperacao === OperacaoManejoTesteCampo_1.OperacaoManejoTesteCampo.OUTRAS_OPERACOES);
            }
            const operacao = ctrl.controleManejoTesteGeneticoSelecionado.operacao;
            const ehOperacaoLimpeza = (typeof operacao.ehOperacaoLimpeza !== 'undefined') && operacao.ehOperacaoLimpeza;
            const ehOperacaoDesbaste = !ehOperacaoLimpeza && (typeof operacao.ehOperacaoDesbaste !== 'undefined') && operacao.ehOperacaoDesbaste;
            const ehOperacaoPolinizacao = !ehOperacaoDesbaste && (typeof operacao.ehOperacaoPolinizacao !== 'undefined') && operacao.ehOperacaoPolinizacao;
            const ehOperacaoColetaAmostras = !ehOperacaoPolinizacao && (typeof operacao.ehOperacaoColetaAmostras !== 'undefined') && operacao.ehOperacaoColetaAmostras;
            const ehOperacaoAdubacaoFertilizacao = !ehOperacaoColetaAmostras && (typeof operacao.ehOperacaoAdubacaoFertilizacao !== 'undefined') && operacao.ehOperacaoAdubacaoFertilizacao;
            const ehOperacaoColetaSementes = !ehOperacaoAdubacaoFertilizacao && (typeof operacao.ehOperacaoColetaSementes !== 'undefined') && operacao.ehOperacaoColetaSementes;
            const ehOperacaoControleDoencas = !ehOperacaoColetaSementes && (typeof operacao.ehOperacaoControleDoencas !== 'undefined') && operacao.ehOperacaoControleDoencas;
            const ehOperacaoPodaRamosPortaEnxerto = !ehOperacaoControleDoencas && (typeof operacao.ehOperacaoPodaRamosPortaEnxerto !== 'undefined') && operacao.ehOperacaoPodaRamosPortaEnxerto;
            const ehOperacaoAvaliacaoSobrevivencia = !ehOperacaoPodaRamosPortaEnxerto && (typeof operacao.ehOperacaoAvaliacaoSobrevivencia !== 'undefined') && operacao.ehOperacaoAvaliacaoSobrevivencia;
            const ehOperacaoReplantio = !ehOperacaoAvaliacaoSobrevivencia && (typeof operacao.ehOperacaoReplantio !== 'undefined') && operacao.ehOperacaoReplantio;
            const ehOutrasOperacoes = !ehOperacaoReplantio && (typeof operacao.ehOutrasOperacoes !== 'undefined') && operacao.ehOutrasOperacoes;
            if (ehOperacaoLimpeza) {
                if (ctrl.controleManejoTesteGeneticoSelecionado.limpezas.length === 0) {
                    let limpezaPomar = new OperacaoLimpezaPomar_1.OperacaoLimpezaPomar();
                    limpezaPomar.status = true;
                    limpezaPomar.criacao = new Date();
                    ctrl.controleManejoTesteGeneticoSelecionado.limpezas.push(limpezaPomar);
                    ctrl.controleManejoTesteGeneticoSelecionado.ehTodosMateriaisGeneticos = true;
                }
            }
            if (ehOperacaoDesbaste) {
                if (ctrl.controleManejoTesteGeneticoSelecionado.desbastes.length === 0) {
                    let desbastePomar = new OperacaoDesbastePomar_1.OperacaoDesbastePomar();
                    desbastePomar.status = true;
                    desbastePomar.criacao = new Date();
                    ctrl.controleManejoTesteGeneticoSelecionado.desbastes.push(desbastePomar);
                    ctrl.controleManejoTesteGeneticoSelecionado.ehTodosMateriaisGeneticos = false;
                }
                angular.forEach(ctrl.controleManejoTesteGeneticoSelecionado.desbastes, (desbastePomar) => {
                    if ((typeof desbastePomar.linhasColunas === 'undefined') || !desbastePomar.linhasColunas) {
                        desbastePomar.linhasColunas = new Array();
                    }
                    if (desbastePomar.linhasColunas.length === 0) {
                        desbastePomar.linhasColunas.push(new LinhaColunaPomar_1.LinhaColunaPomar());
                    }
                });
            }
            if (ehOperacaoPolinizacao) {
                if (ctrl.controleManejoTesteGeneticoSelecionado.polinizacoes.length === 0) {
                    for (let i = 1; i <= 30; i++) {
                        let operacaoPolinizacaoPomar = new OperacaoPolinizacaoPomar_1.OperacaoPolinizacaoPomar();
                        operacaoPolinizacaoPomar.status = true;
                        operacaoPolinizacaoPomar.criacao = new Date();
                        ctrl.controleManejoTesteGeneticoSelecionado.polinizacoes.push(operacaoPolinizacaoPomar);
                        ctrl.controleManejoTesteGeneticoSelecionado.ehTodosMateriaisGeneticos = false;
                        ctrl.controleManejoTesteGeneticoSelecionado.especie = {
                            id: ctrl.testeGeneticoSelecionado.especieDescricao,
                            uuid: ctrl.testeGeneticoSelecionado.especieDescricao,
                            status: true,
                            codigo: 1,
                            descricao: ctrl.testeGeneticoSelecionado.especieDescricao,
                        };
                    }
                }
            }
            if (ehOperacaoColetaAmostras) {
                if (ctrl.controleManejoTesteGeneticoSelecionado.coletasAmostras.length === 0) {
                    let operacaoColetaAmostraPomar = new OperacaoColetaAmostraPomar_1.OperacaoColetaAmostraPomar();
                    operacaoColetaAmostraPomar.status = true;
                    operacaoColetaAmostraPomar.criacao = new Date();
                    ctrl.controleManejoTesteGeneticoSelecionado.coletasAmostras.push(operacaoColetaAmostraPomar);
                    ctrl.controleManejoTesteGeneticoSelecionado.ehTodosMateriaisGeneticos = false;
                }
            }
            if (ehOperacaoColetaSementes) {
                if (ctrl.controleManejoTesteGeneticoSelecionado.coletasSementes.length === 0) {
                    let operacaoColetaSementePomar = new OperacaoColetaSementePomar_1.OperacaoColetaSementePomar();
                    operacaoColetaSementePomar.status = true;
                    operacaoColetaSementePomar.criacao = new Date();
                    ctrl.controleManejoTesteGeneticoSelecionado.coletasSementes.push(operacaoColetaSementePomar);
                    ctrl.controleManejoTesteGeneticoSelecionado.ehTodosMateriaisGeneticos = false;
                }
            }
            if (ehOperacaoControleDoencas) {
                if (ctrl.controleManejoTesteGeneticoSelecionado.doencas.length === 0) {
                    let operacaoControleDoencaPomar = new OperacaoControleDoencaPomar_1.OperacaoControleDoencaPomar();
                    operacaoControleDoencaPomar.status = true;
                    operacaoControleDoencaPomar.criacao = new Date();
                    ctrl.controleManejoTesteGeneticoSelecionado.doencas.push(operacaoControleDoencaPomar);
                    ctrl.controleManejoTesteGeneticoSelecionado.ehTodosMateriaisGeneticos = false;
                }
            }
            if (ehOperacaoAdubacaoFertilizacao) {
                if (ctrl.controleManejoTesteGeneticoSelecionado.adubacoesFertilizacoes.length === 0) {
                    let operacaoAdubacaoFertilizacaoPomar = new OperacaoAdubacaoFertilizacaoPomar_1.OperacaoAdubacaoFertilizacaoPomar();
                    operacaoAdubacaoFertilizacaoPomar.status = true;
                    operacaoAdubacaoFertilizacaoPomar.criacao = new Date();
                    ctrl.controleManejoTesteGeneticoSelecionado.adubacoesFertilizacoes.push(operacaoAdubacaoFertilizacaoPomar);
                    ctrl.controleManejoTesteGeneticoSelecionado.ehTodosMateriaisGeneticos = true;
                }
            }
            if (ehOperacaoPodaRamosPortaEnxerto) {
                if (ctrl.controleManejoTesteGeneticoSelecionado.adubacoesFertilizacoes.length === 0) {
                    let operacaoAdubacaoFertilizacaoPomar = new OperacaoAdubacaoFertilizacaoPomar_1.OperacaoAdubacaoFertilizacaoPomar();
                    operacaoAdubacaoFertilizacaoPomar.status = true;
                    operacaoAdubacaoFertilizacaoPomar.criacao = new Date();
                    ctrl.controleManejoTesteGeneticoSelecionado.adubacoesFertilizacoes.push(operacaoAdubacaoFertilizacaoPomar);
                    ctrl.controleManejoTesteGeneticoSelecionado.ehTodosMateriaisGeneticos = true;
                }
            }
            if (ehOutrasOperacoes) {
                if (ctrl.controleManejoTesteGeneticoSelecionado.outrasOperacoes.length === 0) {
                    let outraOperacaoPomar = new OutraOperacaoPomar_1.OutraOperacaoPomar();
                    outraOperacaoPomar.status = true;
                    outraOperacaoPomar.criacao = new Date();
                    ctrl.controleManejoTesteGeneticoSelecionado.outrasOperacoes.push(outraOperacaoPomar);
                    ctrl.controleManejoTesteGeneticoSelecionado.ehTodosMateriaisGeneticos = true;
                }
            }
            yield ctrl.totalizarControleManejoTesteGenetico();
            ctrl.scopeApply();
        });
    }
    static alterarMateriaisGeneticosControleManejoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.controleManejoTesteGeneticoSelecionado !== 'undefined') && ctrl.controleManejoTesteGeneticoSelecionado &&
                (typeof ctrl.controleManejoTesteGeneticoSelecionado.materiaisGeneticos !== 'undefined') && ctrl.controleManejoTesteGeneticoSelecionado.materiaisGeneticos) {
                let ehSelecionarTodos = false;
                let ehDesmarcarTodos = false;
                let alterouRegistrosOperacao = false;
                angular.forEach(ctrl.controleManejoTesteGeneticoSelecionado.materiaisGeneticos, (materialGenetico) => {
                    if ((typeof materialGenetico !== 'undefined') && materialGenetico) {
                        const materialGeneticoId = ((typeof materialGenetico === 'string') ? materialGenetico :
                            ((typeof materialGenetico.id !== 'undefined') && materialGenetico.id ? materialGenetico.id :
                                ((typeof materialGenetico.value !== 'undefined') && materialGenetico.value ? materialGenetico.value : '')));
                        if (materialGeneticoId && (materialGeneticoId === 'TODOS')) {
                            ehSelecionarTodos = true;
                        }
                        else if (materialGeneticoId && (materialGeneticoId === 'NENHUM')) {
                            ehDesmarcarTodos = true;
                        }
                    }
                });
                if (ehSelecionarTodos) {
                    let lista = new Array();
                    if ((typeof ctrl.selectizeOptionsMateriaisGeneticos !== 'undefined') && ctrl.selectizeOptionsMateriaisGeneticos) {
                        angular.forEach(ctrl.selectizeOptionsMateriaisGeneticos, (materialGenetico) => {
                            if ((typeof materialGenetico !== 'undefined') && materialGenetico) {
                                const materialGeneticoId = ((typeof materialGenetico === 'string') ? materialGenetico :
                                    ((typeof materialGenetico.id !== 'undefined') && materialGenetico.id ? materialGenetico.id :
                                        ((typeof materialGenetico.value !== 'undefined') && materialGenetico.value ? materialGenetico.value : '')));
                                if (materialGeneticoId && (materialGeneticoId !== 'TODOS') && (materialGeneticoId !== 'NENHUM')) {
                                    lista.push(materialGeneticoId);
                                }
                            }
                        });
                    }
                    ctrl.controleManejoTesteGeneticoSelecionado.materiaisGeneticos = lista;
                }
                else if (ehDesmarcarTodos) {
                    ctrl.controleManejoTesteGeneticoSelecionado.materiaisGeneticos = [];
                }
                if ((typeof ctrl.controleManejoTesteGeneticoSelecionado.operacao !== 'undefined') && ctrl.controleManejoTesteGeneticoSelecionado.operacao) {
                    const operacao = ctrl.controleManejoTesteGeneticoSelecionado.operacao;
                    const ehOperacaoLimpeza = (typeof operacao.ehOperacaoLimpeza !== 'undefined') && operacao.ehOperacaoLimpeza;
                    const ehOperacaoDesbaste = !ehOperacaoLimpeza && (typeof operacao.ehOperacaoDesbaste !== 'undefined') && operacao.ehOperacaoDesbaste;
                    const ehOperacaoPolinizacao = !ehOperacaoDesbaste && (typeof operacao.ehOperacaoPolinizacao !== 'undefined') && operacao.ehOperacaoPolinizacao;
                    const ehOperacaoColetaAmostras = !ehOperacaoPolinizacao && (typeof operacao.ehOperacaoColetaAmostras !== 'undefined') && operacao.ehOperacaoColetaAmostras;
                    const ehOperacaoAdubacaoFertilizacao = !ehOperacaoColetaAmostras && (typeof operacao.ehOperacaoAdubacaoFertilizacao !== 'undefined') && operacao.ehOperacaoAdubacaoFertilizacao;
                    const ehOperacaoColetaSementes = !ehOperacaoAdubacaoFertilizacao && (typeof operacao.ehOperacaoColetaSementes !== 'undefined') && operacao.ehOperacaoColetaSementes;
                    const ehOperacaoControleDoencas = !ehOperacaoColetaSementes && (typeof operacao.ehOperacaoControleDoencas !== 'undefined') && operacao.ehOperacaoControleDoencas;
                    const ehOperacaoPodaRamosPortaEnxerto = !ehOperacaoControleDoencas && (typeof operacao.ehOperacaoPodaRamosPortaEnxerto !== 'undefined') && operacao.ehOperacaoPodaRamosPortaEnxerto;
                    const ehOperacaoAvaliacaoSobrevivencia = !ehOperacaoPodaRamosPortaEnxerto && (typeof operacao.ehOperacaoAvaliacaoSobrevivencia !== 'undefined') && operacao.ehOperacaoAvaliacaoSobrevivencia;
                    const ehOperacaoReplantio = !ehOperacaoAvaliacaoSobrevivencia && (typeof operacao.ehOperacaoReplantio !== 'undefined') && operacao.ehOperacaoReplantio;
                    if (ehOperacaoDesbaste || ehOperacaoColetaAmostras || ehOperacaoColetaSementes || ehOperacaoControleDoencas || ehOperacaoPodaRamosPortaEnxerto || ehOperacaoAvaliacaoSobrevivencia || ehOperacaoReplantio) {
                        let materiaisGeneticos = new Array();
                        angular.forEach(ctrl.controleManejoTesteGeneticoSelecionado.materiaisGeneticos, (materialGenetico) => {
                            if ((typeof materialGenetico !== 'undefined') && materialGenetico) {
                                const materialGeneticoId = ((typeof materialGenetico === 'string') ? materialGenetico :
                                    ((typeof materialGenetico.id !== 'undefined') && materialGenetico.id ? materialGenetico.id :
                                        ((typeof materialGenetico.value !== 'undefined') && materialGenetico.value ? materialGenetico.value : '')));
                                if (materialGeneticoId && (materialGeneticoId !== 'TODOS') && (materialGeneticoId !== 'NENHUM')) {
                                    let achouMaterialGenetico = false;
                                    angular.forEach(ctrl.materiaisGeneticos, (materialGenetico) => {
                                        if (!achouMaterialGenetico) {
                                            if ((materialGenetico.id ? materialGenetico.id : materialGenetico.uuid) === materialGeneticoId) {
                                                materiaisGeneticos.push(materialGenetico);
                                                achouMaterialGenetico = true;
                                            }
                                        }
                                    });
                                }
                            }
                        });
                        const listagemOperacao = ehOperacaoDesbaste ? 'desbastes' :
                            (ehOperacaoColetaAmostras ? 'coletasAmostras' :
                                (ehOperacaoColetaSementes ? 'coletasSementes' :
                                    (ehOperacaoControleDoencas ? 'doencas' : 'operacaoInvalida')));
                        if ((typeof ctrl.controleManejoTesteGeneticoSelecionado[listagemOperacao] === 'undefined') || !ctrl.controleManejoTesteGeneticoSelecionado[listagemOperacao]) {
                            ctrl.controleManejoTesteGeneticoSelecionado[listagemOperacao] = new Array();
                        }
                        angular.forEach(materiaisGeneticos, (materialGenetico) => {
                            let achouMaterialGenetico = false;
                            angular.forEach(ctrl.controleManejoTesteGeneticoSelecionado[listagemOperacao], (registro) => {
                                if (!achouMaterialGenetico && (typeof registro.materialGenetico !== 'undefined') && registro.materialGenetico) {
                                    if ((registro.materialGenetico.id ? registro.materialGenetico.id : registro.materialGenetico.uuid) === (materialGenetico.id ? materialGenetico.id : materialGenetico.uuid)) {
                                        achouMaterialGenetico = true;
                                    }
                                }
                            });
                            if (!achouMaterialGenetico) {
                                if (ctrl.controleManejoTesteGeneticoSelecionado[listagemOperacao].length > 0) {
                                    let registro = ctrl.controleManejoTesteGeneticoSelecionado[listagemOperacao][0];
                                    if (((typeof registro.materialGenetico === 'undefined') || !registro.materialGenetico) &&
                                        ((typeof registro.numClones === 'undefined') || !registro.numClones) &&
                                        ((typeof registro.numRametes === 'undefined') || !registro.numRametes)) {
                                        ctrl.controleManejoTesteGeneticoSelecionado[listagemOperacao].splice(0, 1);
                                    }
                                }
                                let novoRegistro = {
                                    id: null,
                                    uuid: null,
                                    guid: null,
                                    status: true,
                                    criacao: new Date(),
                                    numClones: 1,
                                    materialGenetico: materialGenetico
                                };
                                ctrl.controleManejoTesteGeneticoSelecionado[listagemOperacao].push(novoRegistro);
                                alterouRegistrosOperacao = true;
                            }
                        });
                        let listaIndicesRegistrosARemover = new Array();
                        angular.forEach(ctrl.controleManejoTesteGeneticoSelecionado[listagemOperacao], (registro, keyRegistro) => {
                            let achouMaterialGenetico = false;
                            if ((typeof registro.materialGenetico !== 'undefined') && registro.materialGenetico) {
                                angular.forEach(materiaisGeneticos, (materialGenetico) => {
                                    if (!achouMaterialGenetico) {
                                        if ((registro.materialGenetico.id ? registro.materialGenetico.id : registro.materialGenetico.uuid) === (materialGenetico.id ? materialGenetico.id : materialGenetico.uuid)) {
                                            achouMaterialGenetico = true;
                                        }
                                    }
                                });
                            }
                            if (!achouMaterialGenetico &&
                                ((typeof registro.numClones === 'undefined') || !registro.numClones) &&
                                ((typeof registro.numRametes === 'undefined') || !registro.numRametes) &&
                                ((typeof registro.quantidadeAmostras === 'undefined') || !registro.quantidadeAmostras) &&
                                ((typeof registro.quantidade === 'undefined') || !registro.quantidade)) {
                                listaIndicesRegistrosARemover = [keyRegistro, ...listaIndicesRegistrosARemover];
                            }
                        });
                        if (listaIndicesRegistrosARemover.length > 0) {
                            angular.forEach(listaIndicesRegistrosARemover, (posicao) => {
                                ctrl.controleManejoTesteGeneticoSelecionado[listagemOperacao].splice(posicao, 1);
                                alterouRegistrosOperacao = true;
                            });
                        }
                    }
                }
                if (ehSelecionarTodos || ehDesmarcarTodos) {
                    ctrl.totalizarControleManejoTesteGenetico();
                    ctrl.scopeApply(() => {
                        const elementos = angular.element('#materiaisGeneticosControleManejoTesteGenetico');
                        if (elementos && elementos.length > 0) {
                            const primeiroElemento = elementos.get(0);
                            primeiroElemento.selectize.close();
                        }
                    });
                }
                else if (alterouRegistrosOperacao) {
                    ctrl.totalizarControleManejoTesteGenetico();
                    ctrl.scopeApply();
                }
            }
        });
    }
    static alterarClonesMasculinosControleManejoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.controleManejoTesteGeneticoSelecionado !== 'undefined') && ctrl.controleManejoTesteGeneticoSelecionado &&
                (typeof ctrl.controleManejoTesteGeneticoSelecionado.clonesMasculinos !== 'undefined') && ctrl.controleManejoTesteGeneticoSelecionado.clonesMasculinos) {
                let ehSelecionarTodos = false;
                let ehDesmarcarTodos = false;
                angular.forEach(ctrl.controleManejoTesteGeneticoSelecionado.clonesMasculinos, (materialGenetico) => {
                    if ((typeof materialGenetico !== 'undefined') && materialGenetico) {
                        const materialGeneticoId = ((typeof materialGenetico === 'string') ? materialGenetico :
                            ((typeof materialGenetico.id !== 'undefined') && materialGenetico.id ? materialGenetico.id :
                                ((typeof materialGenetico.value !== 'undefined') && materialGenetico.value ? materialGenetico.value : '')));
                        if (materialGeneticoId && (materialGeneticoId === 'TODOS')) {
                            ehSelecionarTodos = true;
                        }
                        else if (materialGeneticoId && (materialGeneticoId === 'NENHUM')) {
                            ehDesmarcarTodos = true;
                        }
                    }
                });
                if (ehSelecionarTodos) {
                    let lista = new Array();
                    if ((typeof ctrl.selectizeOptionsMateriaisGeneticos !== 'undefined') && ctrl.selectizeOptionsMateriaisGeneticos) {
                        angular.forEach(ctrl.selectizeOptionsMateriaisGeneticos, (materialGenetico) => {
                            if ((typeof materialGenetico !== 'undefined') && materialGenetico) {
                                const materialGeneticoId = ((typeof materialGenetico === 'string') ? materialGenetico :
                                    ((typeof materialGenetico.id !== 'undefined') && materialGenetico.id ? materialGenetico.id :
                                        ((typeof materialGenetico.value !== 'undefined') && materialGenetico.value ? materialGenetico.value : '')));
                                if (materialGeneticoId && (materialGeneticoId !== 'TODOS') && (materialGeneticoId !== 'NENHUM')) {
                                    lista.push(materialGeneticoId);
                                }
                            }
                        });
                    }
                    ctrl.controleManejoTesteGeneticoSelecionado.clonesMasculinos = lista;
                }
                else if (ehDesmarcarTodos) {
                    ctrl.controleManejoTesteGeneticoSelecionado.clonesMasculinos = [];
                }
                ctrl.scopeApply();
            }
        });
    }
    static adicionarRegistroOperacaoControleManejoTesteGenetico(ctrl, listagemOperacao) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.controleManejoTesteGeneticoSelecionado) {
                return;
            }
            if ((typeof ctrl.controleManejoTesteGeneticoSelecionado[listagemOperacao] === 'undefined') || !ctrl.controleManejoTesteGeneticoSelecionado[listagemOperacao]) {
                ctrl.controleManejoTesteGeneticoSelecionado[listagemOperacao] = [];
            }
            let registro = {
                id: null,
                uuid: null,
                guid: null,
                status: true,
                criacao: new Date(),
                numClones: 1
            };
            if (listagemOperacao === 'desbastes') {
                if ((typeof registro.linhasColunas === 'undefined') || !registro.linhasColunas) {
                    registro.linhasColunas = new Array();
                }
                if (registro.linhasColunas.length === 0) {
                    registro.linhasColunas.push(new LinhaColunaPomar_1.LinhaColunaPomar());
                }
            }
            ctrl.controleManejoTesteGeneticoSelecionado[listagemOperacao].push(registro);
            yield ctrl.totalizarControleManejoTesteGenetico();
            ctrl.scopeApply();
        });
    }
    static removerRegistroOperacaoControleManejoTesteGenetico(ctrl, listagemOperacao, keyOperacao) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.controleManejoTesteGeneticoSelecionado || !listagemOperacao) {
                return;
            }
            if ((typeof ctrl.controleManejoTesteGeneticoSelecionado[listagemOperacao] === 'undefined') || !ctrl.controleManejoTesteGeneticoSelecionado[listagemOperacao]) {
                ctrl.controleManejoTesteGeneticoSelecionado[listagemOperacao] = [];
            }
            if ((typeof keyOperacao !== 'undefined') && (keyOperacao !== null) &&
                (ctrl.controleManejoTesteGeneticoSelecionado[listagemOperacao].length > 0) &&
                (keyOperacao <= ctrl.controleManejoTesteGeneticoSelecionado[listagemOperacao].length - 1)) {
                const numRegistro = keyOperacao + 1;
                if (window.confirm('Deseja realmente remover esse registro #' + numRegistro + ' ?')) {
                    ctrl.controleManejoTesteGeneticoSelecionado[listagemOperacao].splice(keyOperacao, 1);
                    yield ctrl.totalizarControleManejoTesteGenetico();
                    ctrl.scopeApply();
                }
            }
        });
    }
    static moverRegistroOperacaoControleManejoTesteGeneticoParaCima(ctrl, listagemOperacao, keyOperacao) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.controleManejoTesteGeneticoSelecionado) {
                return;
            }
            if ((typeof ctrl.controleManejoTesteGeneticoSelecionado[listagemOperacao] === 'undefined') || !ctrl.controleManejoTesteGeneticoSelecionado[listagemOperacao]) {
                ctrl.controleManejoTesteGeneticoSelecionado[listagemOperacao] = [];
            }
            if ((keyOperacao !== null) && (keyOperacao > 0) && (keyOperacao <= ctrl.controleManejoTesteGeneticoSelecionado[listagemOperacao].length - 1)) {
                let oldKey = keyOperacao;
                let newKey = keyOperacao - 1;
                ctrl.controleManejoTesteGeneticoSelecionado[listagemOperacao].splice(newKey, 0, ctrl.controleManejoTesteGeneticoSelecionado[listagemOperacao].splice(oldKey, 1)[0]);
                ctrl.scopeApply();
            }
        });
    }
    static moverRegistroOperacaoControleManejoTesteGeneticoParaBaixo(ctrl, listagemOperacao, keyOperacao) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.controleManejoTesteGeneticoSelecionado) {
                return;
            }
            if ((typeof ctrl.controleManejoTesteGeneticoSelecionado[listagemOperacao] === 'undefined') || !ctrl.controleManejoTesteGeneticoSelecionado[listagemOperacao]) {
                ctrl.controleManejoTesteGeneticoSelecionado[listagemOperacao] = [];
            }
            if ((keyOperacao != null) && (keyOperacao >= 0) && (keyOperacao < ctrl.controleManejoTesteGeneticoSelecionado[listagemOperacao].length - 1)) {
                let oldKey = keyOperacao;
                let newKey = keyOperacao + 1;
                ctrl.controleManejoTesteGeneticoSelecionado[listagemOperacao].splice(newKey, 0, ctrl.controleManejoTesteGeneticoSelecionado[listagemOperacao].splice(oldKey, 1)[0]);
                ctrl.scopeApply();
            }
        });
    }
    static adicionarLinhaColunaRegistroOperacaoControleManejoTesteGenetico(ctrl, operacaoControleManejo) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!operacaoControleManejo) {
                return;
            }
            if ((typeof operacaoControleManejo.linhasColunas === 'undefined') || !operacaoControleManejo.linhasColunas) {
                operacaoControleManejo.linhasColunas = new Array();
            }
            operacaoControleManejo.linhasColunas.push(new LinhaColunaPomar_1.LinhaColunaPomar());
            ctrl.scopeApply();
        });
    }
    static removerLinhaColunaRegistroOperacaoControleManejoTesteGenetico(ctrl, operacaoControleManejo, keyLinhaColuna) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!operacaoControleManejo) {
                return;
            }
            if ((typeof operacaoControleManejo.linhasColunas === 'undefined') || !operacaoControleManejo.linhasColunas) {
                operacaoControleManejo.linhasColunas = new Array();
            }
            if ((typeof keyLinhaColuna !== 'undefined') && (keyLinhaColuna !== null) &&
                (operacaoControleManejo.linhasColunas.length > 0) &&
                (keyLinhaColuna <= operacaoControleManejo.linhasColunas.length - 1)) {
                operacaoControleManejo.linhasColunas.splice(keyLinhaColuna, 1);
                ctrl.scopeApply();
            }
        });
    }
    static totalizarControleManejoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.controleManejoTesteGeneticoSelecionado !== 'undefined') && ctrl.controleManejoTesteGeneticoSelecionado &&
                (typeof ctrl.controleManejoTesteGeneticoSelecionado.operacao !== 'undefined') && ctrl.controleManejoTesteGeneticoSelecionado.operacao) {
                const operacao = ctrl.controleManejoTesteGeneticoSelecionado.operacao;
                const ehOperacaoLimpeza = (typeof operacao.ehOperacaoLimpeza !== 'undefined') && operacao.ehOperacaoLimpeza;
                const ehOperacaoDesbaste = !ehOperacaoLimpeza && (typeof operacao.ehOperacaoDesbaste !== 'undefined') && operacao.ehOperacaoDesbaste;
                const ehOperacaoPolinizacao = !ehOperacaoDesbaste && (typeof operacao.ehOperacaoPolinizacao !== 'undefined') && operacao.ehOperacaoPolinizacao;
                const ehOperacaoColetaAmostras = !ehOperacaoPolinizacao && (typeof operacao.ehOperacaoColetaAmostras !== 'undefined') && operacao.ehOperacaoColetaAmostras;
                const ehOperacaoAdubacaoFertilizacao = !ehOperacaoColetaAmostras && (typeof operacao.ehOperacaoAdubacaoFertilizacao !== 'undefined') && operacao.ehOperacaoAdubacaoFertilizacao;
                const ehOperacaoColetaSementes = !ehOperacaoAdubacaoFertilizacao && (typeof operacao.ehOperacaoColetaSementes !== 'undefined') && operacao.ehOperacaoColetaSementes;
                const ehOperacaoControleDoencas = !ehOperacaoColetaSementes && (typeof operacao.ehOperacaoControleDoencas !== 'undefined') && operacao.ehOperacaoControleDoencas;
                const ehOperacaoPodaRamosPortaEnxerto = !ehOperacaoControleDoencas && (typeof operacao.ehOperacaoPodaRamosPortaEnxerto !== 'undefined') && operacao.ehOperacaoPodaRamosPortaEnxerto;
                if (ehOperacaoDesbaste) {
                    let numClones = 0;
                    let numRametes = 0;
                    if ((typeof ctrl.controleManejoTesteGeneticoSelecionado.desbastes !== 'undefined') && ctrl.controleManejoTesteGeneticoSelecionado.desbastes) {
                        angular.forEach(ctrl.controleManejoTesteGeneticoSelecionado.desbastes, (desbaste) => {
                            if ((typeof desbaste.numClones !== 'undefined') && desbaste.numClones && (desbaste.numClones > 0)) {
                                numClones += desbaste.numClones;
                            }
                            else {
                                numClones += 1;
                            }
                            if ((typeof desbaste.numRametes !== 'undefined') && desbaste.numRametes) {
                                numRametes += desbaste.numRametes;
                            }
                        });
                    }
                    ctrl.controleManejoTesteGeneticoSelecionado.numClones = numClones;
                    ctrl.controleManejoTesteGeneticoSelecionado.numRametes = numRametes;
                }
                if (ehOperacaoColetaAmostras) {
                    let numClones = 0;
                    let quantidadeAmostras = 0;
                    if ((typeof ctrl.controleManejoTesteGeneticoSelecionado.coletasAmostras !== 'undefined') && ctrl.controleManejoTesteGeneticoSelecionado.coletasAmostras) {
                        angular.forEach(ctrl.controleManejoTesteGeneticoSelecionado.coletasAmostras, (coletaAmostra) => {
                            if ((typeof coletaAmostra.numClones !== 'undefined') && coletaAmostra.numClones && (coletaAmostra.numClones > 0)) {
                                numClones += coletaAmostra.numClones;
                            }
                            else {
                                numClones += 1;
                            }
                            if ((typeof coletaAmostra.quantidadeAmostras !== 'undefined') && coletaAmostra.quantidadeAmostras) {
                                quantidadeAmostras += coletaAmostra.quantidadeAmostras;
                            }
                        });
                    }
                    ctrl.controleManejoTesteGeneticoSelecionado.numClones = numClones;
                    ctrl.controleManejoTesteGeneticoSelecionado.quantidadeAmostras = quantidadeAmostras;
                }
                if (ehOperacaoColetaSementes) {
                    let numClones = 0;
                    let numCones = 0;
                    let quantidade = 0;
                    let quantidadeArvores = 0;
                    if ((typeof ctrl.controleManejoTesteGeneticoSelecionado.coletasSementes !== 'undefined') && ctrl.controleManejoTesteGeneticoSelecionado.coletasSementes) {
                        angular.forEach(ctrl.controleManejoTesteGeneticoSelecionado.coletasSementes, (coletaSemente) => {
                            if ((typeof coletaSemente.numClones !== 'undefined') && coletaSemente.numClones && (coletaSemente.numClones > 0)) {
                                numClones += coletaSemente.numClones;
                            }
                            else {
                                numClones += 1;
                            }
                            if ((typeof coletaSemente.numCones !== 'undefined') && coletaSemente.numCones) {
                                numCones += coletaSemente.numCones;
                            }
                            if ((typeof coletaSemente.quantidade !== 'undefined') && coletaSemente.quantidade) {
                                quantidade += coletaSemente.quantidade;
                            }
                            if ((typeof coletaSemente.numArvores !== 'undefined') && coletaSemente.numArvores) {
                                quantidadeArvores += coletaSemente.numArvores;
                            }
                        });
                    }
                    ctrl.controleManejoTesteGeneticoSelecionado.numClones = numClones;
                    ctrl.controleManejoTesteGeneticoSelecionado.numCones = numCones;
                    ctrl.controleManejoTesteGeneticoSelecionado.quantidade = quantidade;
                    ctrl.controleManejoTesteGeneticoSelecionado.quantidadeArvores = quantidadeArvores;
                }
                if (ehOperacaoPolinizacao) {
                    let numClones = 0;
                    let numCones = 0;
                    if ((typeof ctrl.controleManejoTesteGeneticoSelecionado.coletasSementes !== 'undefined') && ctrl.controleManejoTesteGeneticoSelecionado.coletasSementes) {
                        angular.forEach(ctrl.controleManejoTesteGeneticoSelecionado.coletasSementes, (coletaSemente) => {
                            if ((typeof coletaSemente.numClones !== 'undefined') && coletaSemente.numClones && (coletaSemente.numClones > 0)) {
                                numClones += coletaSemente.numClones;
                            }
                            else {
                                numClones += 1;
                            }
                            if ((typeof coletaSemente.numCones !== 'undefined') && coletaSemente.numCones) {
                                numCones += coletaSemente.numCones;
                            }
                        });
                    }
                    ctrl.controleManejoTesteGeneticoSelecionado.numClones = numClones;
                    ctrl.controleManejoTesteGeneticoSelecionado.numCones = numCones;
                }
                if (ehOperacaoControleDoencas) {
                    let numClones = 0;
                    let quantidadeArvores = 0;
                    if ((typeof ctrl.controleManejoTesteGeneticoSelecionado.doencas !== 'undefined') && ctrl.controleManejoTesteGeneticoSelecionado.doencas) {
                        angular.forEach(ctrl.controleManejoTesteGeneticoSelecionado.doencas, (doenca) => {
                            if ((typeof doenca.numClones !== 'undefined') && doenca.numClones && (doenca.numClones > 0)) {
                                numClones += doenca.numClones;
                            }
                            else {
                                numClones += 1;
                            }
                            if ((typeof doenca.quantidadeArvores !== 'undefined') && doenca.quantidadeArvores) {
                                quantidadeArvores += doenca.quantidadeArvores;
                            }
                        });
                    }
                    ctrl.controleManejoTesteGeneticoSelecionado.numClones = numClones;
                    ctrl.controleManejoTesteGeneticoSelecionado.quantidadeArvores = quantidadeArvores;
                }
                if (ehOperacaoPodaRamosPortaEnxerto) {
                    ctrl.controleManejoTesteGeneticoSelecionado.quantidade = 0;
                }
            }
        });
    }
    static alterarTipoDesbasteControleManejoTesteGenetico(ctrl, keyOperacaoDesbaste) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.controleManejoTesteGeneticoSelecionado !== 'undefined') && ctrl.controleManejoTesteGeneticoSelecionado &&
                (typeof ctrl.controleManejoTesteGeneticoSelecionado.operacao !== 'undefined') && ctrl.controleManejoTesteGeneticoSelecionado.operacao) {
                if ((typeof ctrl.controleManejoTesteGeneticoSelecionado.desbastes === 'undefined') || !ctrl.controleManejoTesteGeneticoSelecionado.desbastes) {
                    ctrl.controleManejoTesteGeneticoSelecionado.desbastes = new Array();
                }
                if ((typeof globalThis.timeoutTipoDesbasteControleManejoTesteGenetico !== 'undefined') && globalThis.timeoutTipoDesbasteControleManejoTesteGenetico) {
                    clearTimeout(globalThis.timeoutTipoDesbasteControleManejoTesteGenetico);
                    globalThis.timeoutTipoDesbasteControleManejoTesteGenetico = null;
                }
                globalThis.timeoutTipoDesbasteControleManejoTesteGenetico = setTimeout((ctrl, keyOperacaoDesbaste) => {
                    if ((typeof keyOperacaoDesbaste !== 'undefined') && (keyOperacaoDesbaste !== null) &&
                        (ctrl.controleManejoTesteGeneticoSelecionado.desbastes.length > 1) &&
                        (keyOperacaoDesbaste <= ctrl.controleManejoTesteGeneticoSelecionado.desbastes.length - 1)) {
                        const desbaste = ctrl.controleManejoTesteGeneticoSelecionado.desbastes[keyOperacaoDesbaste];
                        const tipoDesbaste = ((typeof desbaste.tipoDesbaste !== 'undefined') && desbaste.tipoDesbaste) ? desbaste.tipoDesbaste : null;
                        const tipoDesbasteDescricao = (tipoDesbaste && (typeof tipoDesbaste.descricao !== 'undefined') && tipoDesbaste.descricao) ? tipoDesbaste.descricao : '';
                        if (window.confirm('Deseja replicar o tipo de desbaste "' + tipoDesbasteDescricao + '" para as outras linhas de materiais genéticos ?')) {
                            angular.forEach(ctrl.controleManejoTesteGeneticoSelecionado.desbastes, (registro) => {
                                registro.tipoDesbaste = tipoDesbaste;
                            });
                            ctrl.scopeApply();
                        }
                    }
                }, 250, ctrl, keyOperacaoDesbaste);
            }
        });
    }
    static alterarTipoColetaAmostraControleManejoTesteGenetico(ctrl, keyOperacaoColetaAmostra) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.controleManejoTesteGeneticoSelecionado !== 'undefined') && ctrl.controleManejoTesteGeneticoSelecionado &&
                (typeof ctrl.controleManejoTesteGeneticoSelecionado.operacao !== 'undefined') && ctrl.controleManejoTesteGeneticoSelecionado.operacao) {
                if ((typeof ctrl.controleManejoTesteGeneticoSelecionado.coletasAmostras === 'undefined') || !ctrl.controleManejoTesteGeneticoSelecionado.coletasAmostras) {
                    ctrl.controleManejoTesteGeneticoSelecionado.coletasAmostras = new Array();
                }
                if ((typeof globalThis.timeoutTipoColetaAmostraControleManejoTesteGenetico !== 'undefined') && globalThis.timeoutTipoColetaAmostraControleManejoTesteGenetico) {
                    clearTimeout(globalThis.timeoutTipoColetaAmostraControleManejoTesteGenetico);
                    globalThis.timeoutTipoColetaAmostraControleManejoTesteGenetico = null;
                }
                globalThis.timeoutTipoColetaAmostraControleManejoTesteGenetico = setTimeout((ctrl, keyOperacaoColetaAmostra) => {
                    if ((typeof keyOperacaoColetaAmostra !== 'undefined') && (keyOperacaoColetaAmostra !== null) &&
                        (ctrl.controleManejoTesteGeneticoSelecionado.coletasAmostras.length > 1) &&
                        (keyOperacaoColetaAmostra <= ctrl.controleManejoTesteGeneticoSelecionado.coletasAmostras.length - 1)) {
                        const coletaAmostra = ctrl.controleManejoTesteGeneticoSelecionado.coletasAmostras[keyOperacaoColetaAmostra];
                        const tipoColetaAmostra = ((typeof coletaAmostra.tipoColetaAmostra !== 'undefined') && coletaAmostra.tipoColetaAmostra) ? coletaAmostra.tipoColetaAmostra : null;
                        const tipoColetaAmostraDescricao = (tipoColetaAmostra && (typeof tipoColetaAmostra.descricao !== 'undefined') && tipoColetaAmostra.descricao) ? tipoColetaAmostra.descricao : '';
                        if (window.confirm('Deseja replicar o tipo de amostra "' + tipoColetaAmostraDescricao + '" para as outras linhas de materiais genéticos ?')) {
                            angular.forEach(ctrl.controleManejoTesteGeneticoSelecionado.coletasAmostras, (registro) => {
                                registro.tipoColetaAmostra = tipoColetaAmostra;
                            });
                            ctrl.scopeApply();
                        }
                    }
                }, 250, ctrl, keyOperacaoColetaAmostra);
            }
        });
    }
    static alterarFinalidadeColetaAmostraControleManejoTesteGenetico(ctrl, keyOperacaoColetaAmostra) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.controleManejoTesteGeneticoSelecionado !== 'undefined') && ctrl.controleManejoTesteGeneticoSelecionado &&
                (typeof ctrl.controleManejoTesteGeneticoSelecionado.operacao !== 'undefined') && ctrl.controleManejoTesteGeneticoSelecionado.operacao) {
                if ((typeof ctrl.controleManejoTesteGeneticoSelecionado.coletasAmostras === 'undefined') || !ctrl.controleManejoTesteGeneticoSelecionado.coletasAmostras) {
                    ctrl.controleManejoTesteGeneticoSelecionado.coletasAmostras = new Array();
                }
                if ((typeof globalThis.timeoutFinalidadeColetaAmostraControleManejoTesteGenetico !== 'undefined') && globalThis.timeoutFinalidadeColetaAmostraControleManejoTesteGenetico) {
                    clearTimeout(globalThis.timeoutFinalidadeColetaAmostraControleManejoTesteGenetico);
                    globalThis.timeoutFinalidadeColetaAmostraControleManejoTesteGenetico = null;
                }
                globalThis.timeoutFinalidadeColetaAmostraControleManejoTesteGenetico = setTimeout((ctrl, keyOperacaoColetaAmostra) => {
                    if ((typeof keyOperacaoColetaAmostra !== 'undefined') && (keyOperacaoColetaAmostra !== null) &&
                        (ctrl.controleManejoTesteGeneticoSelecionado.coletasAmostras.length > 1) &&
                        (keyOperacaoColetaAmostra <= ctrl.controleManejoTesteGeneticoSelecionado.coletasAmostras.length - 1)) {
                        const coletaAmostra = ctrl.controleManejoTesteGeneticoSelecionado.coletasAmostras[keyOperacaoColetaAmostra];
                        const finalidadeColetaAmostra = ((typeof coletaAmostra.finalidadeColetaAmostra !== 'undefined') && coletaAmostra.finalidadeColetaAmostra) ? coletaAmostra.finalidadeColetaAmostra : null;
                        const finalidadeColetaAmostraDescricao = (finalidadeColetaAmostra && (typeof finalidadeColetaAmostra.descricao !== 'undefined') && finalidadeColetaAmostra.descricao) ? finalidadeColetaAmostra.descricao : '';
                        if (window.confirm('Deseja replicar a finalidade da amostra "' + finalidadeColetaAmostraDescricao + '" para as outras linhas de materiais genéticos ?')) {
                            angular.forEach(ctrl.controleManejoTesteGeneticoSelecionado.coletasAmostras, (registro) => {
                                registro.finalidadeColetaAmostra = finalidadeColetaAmostra;
                            });
                            ctrl.scopeApply();
                        }
                    }
                }, 250, ctrl, keyOperacaoColetaAmostra);
            }
        });
    }
    static tirarFotoControleManejoTesteGenetico(ctrl, ehGaleria = false) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const foto = yield DispositivoUtil_1.DispositivoUtil.tirarFoto((typeof ehGaleria !== 'undefined') ? ehGaleria : false);
                const fotoId = ArmazenamentoUtil_1.ArmazenamentoUtil.uuid();
                ctrl.listasFotos.fotosCamera.push({
                    uuid: fotoId,
                    referencia: 'fotosControleManejoTesteGeneticoSelecionado',
                    indiceIndicador: null,
                    indiceCampoIndicador: null,
                    indiceCampoCabecalho: null,
                    indiceCampoRodape: null,
                    controleManejoTesteGenetico: true,
                    anexoTesteGenetico: false,
                    foto: foto
                });
                ctrl.listasFotos.salvarFoto(fotoId);
                ctrl.scopeApply();
            }
            catch (ex) {
                alert(ex.message);
            }
        });
    }
    static removerFotoControleManejoTesteGenetico(ctrl, fotoCamera) {
        return __awaiter(this, void 0, void 0, function* () {
            if (confirm(`Deseja realmente remover essa foto?`)) {
                ctrl.listasFotos.removerFoto(fotoCamera.uuid);
                ctrl.scopeApply();
            }
        });
    }
}
exports.TesteCampoControleManejoUtil = TesteCampoControleManejoUtil;
