"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Regiao = void 0;
const Base_1 = require("./Base");
class Regiao extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.sgif_id = null;
        this.numRegiao = null;
        this.nome = null;
    }
}
exports.Regiao = Regiao;
