"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TipoProdutoEstoque = void 0;
class TipoProdutoEstoque {
    constructor() {
        this.id = null;
        this.descricao = null;
        this.ehViveiro = null;
    }
    static MUDAS() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.tipoProdutoEstoqueMudas === 'undefined') || (globalThis.globalConstants.tipoProdutoEstoqueMudas === null)) {
            let tipoProdutoEstoqueMudas = new TipoProdutoEstoque();
            tipoProdutoEstoqueMudas.id = 'MUDAS';
            tipoProdutoEstoqueMudas.descricao = 'Mudas';
            tipoProdutoEstoqueMudas.ehViveiro = true;
            globalThis.globalConstants.tipoProdutoEstoqueMudas = tipoProdutoEstoqueMudas;
        }
        return globalThis.globalConstants.tipoProdutoEstoqueMudas;
    }
    static BANDEJAS() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.tipoProdutoEstoqueBandejas === 'undefined') || (globalThis.globalConstants.tipoProdutoEstoqueBandejas === null)) {
            let tipoProdutoEstoqueBandejas = new TipoProdutoEstoque();
            tipoProdutoEstoqueBandejas.id = 'BANDEJAS';
            tipoProdutoEstoqueBandejas.descricao = 'Bandejas';
            tipoProdutoEstoqueBandejas.ehViveiro = true;
            globalThis.globalConstants.tipoProdutoEstoqueBandejas = tipoProdutoEstoqueBandejas;
        }
        return globalThis.globalConstants.tipoProdutoEstoqueBandejas;
    }
    static BANDEJAS_MUDAS() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.tipoProdutoEstoqueBandejasMudas === 'undefined') || (globalThis.globalConstants.tipoProdutoEstoqueBandejasMudas === null)) {
            let tipoProdutoEstoqueBandejasMudas = new TipoProdutoEstoque();
            tipoProdutoEstoqueBandejasMudas.id = 'BANDEJAS_MUDAS';
            tipoProdutoEstoqueBandejasMudas.descricao = 'Bandejas e Mudas';
            tipoProdutoEstoqueBandejasMudas.ehViveiro = true;
            globalThis.globalConstants.tipoProdutoEstoqueBandejasMudas = tipoProdutoEstoqueBandejasMudas;
        }
        return globalThis.globalConstants.tipoProdutoEstoqueBandejasMudas;
    }
    static VASILHAME_TUBETE() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.tipoProdutoEstoqueVasilhameTubete === 'undefined') || (globalThis.globalConstants.tipoProdutoEstoqueVasilhameTubete === null)) {
            let tipoProdutoEstoqueVasilhameTubete = new TipoProdutoEstoque();
            tipoProdutoEstoqueVasilhameTubete.id = 'VASILHAME_TUBETE';
            tipoProdutoEstoqueVasilhameTubete.descricao = 'Vasilhame Cliente - Tubete';
            tipoProdutoEstoqueVasilhameTubete.ehViveiro = true;
            globalThis.globalConstants.tipoProdutoEstoqueVasilhameTubete = tipoProdutoEstoqueVasilhameTubete;
        }
        return globalThis.globalConstants.tipoProdutoEstoqueVasilhameTubete;
    }
    static VASILHAME_ELLEPOT() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.tipoProdutoEstoqueVasilhameEllepot === 'undefined') || (globalThis.globalConstants.tipoProdutoEstoqueVasilhameEllepot === null)) {
            let tipoProdutoEstoqueVasilhameEllepot = new TipoProdutoEstoque();
            tipoProdutoEstoqueVasilhameEllepot.id = 'VASILHAME_ELLEPOT';
            tipoProdutoEstoqueVasilhameEllepot.descricao = 'Vasilhame Cliente - Ellepot';
            tipoProdutoEstoqueVasilhameEllepot.ehViveiro = true;
            globalThis.globalConstants.tipoProdutoEstoqueVasilhameEllepot = tipoProdutoEstoqueVasilhameEllepot;
        }
        return globalThis.globalConstants.tipoProdutoEstoqueVasilhameEllepot;
    }
    static VASILHAME_BANDEJA() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.tipoProdutoEstoqueVasilhameBandeja === 'undefined') || (globalThis.globalConstants.tipoProdutoEstoqueVasilhameBandeja === null)) {
            let tipoProdutoEstoqueVasilhameBandeja = new TipoProdutoEstoque();
            tipoProdutoEstoqueVasilhameBandeja.id = 'VASILHAME_BANDEJA';
            tipoProdutoEstoqueVasilhameBandeja.descricao = 'Vasilhame Cliente - Bandeja';
            tipoProdutoEstoqueVasilhameBandeja.ehViveiro = true;
            globalThis.globalConstants.tipoProdutoEstoqueVasilhameBandeja = tipoProdutoEstoqueVasilhameBandeja;
        }
        return globalThis.globalConstants.tipoProdutoEstoqueVasilhameBandeja;
    }
    static VASILHAME_CAIXA() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.tipoProdutoEstoqueVasilhameCaixa === 'undefined') || (globalThis.globalConstants.tipoProdutoEstoqueVasilhameCaixa === null)) {
            let tipoProdutoEstoqueVasilhameCaixa = new TipoProdutoEstoque();
            tipoProdutoEstoqueVasilhameCaixa.id = 'VASILHAME_CAIXA';
            tipoProdutoEstoqueVasilhameCaixa.descricao = 'Vasilhame Cliente - Caixa';
            tipoProdutoEstoqueVasilhameCaixa.ehViveiro = true;
            globalThis.globalConstants.tipoProdutoEstoqueVasilhameCaixa = tipoProdutoEstoqueVasilhameCaixa;
        }
        return globalThis.globalConstants.tipoProdutoEstoqueVasilhameCaixa;
    }
    static LISTA() {
        return [
            TipoProdutoEstoque.MUDAS(),
            TipoProdutoEstoque.BANDEJAS(),
            TipoProdutoEstoque.BANDEJAS_MUDAS(),
            TipoProdutoEstoque.VASILHAME_TUBETE(),
            TipoProdutoEstoque.VASILHAME_ELLEPOT(),
            TipoProdutoEstoque.VASILHAME_BANDEJA(),
            TipoProdutoEstoque.VASILHAME_CAIXA()
        ];
    }
}
exports.TipoProdutoEstoque = TipoProdutoEstoque;
