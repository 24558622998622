"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProducaoMudasPlanejamento = void 0;
const Base_1 = require("./Base");
class ProducaoMudasPlanejamento extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.testesGeneticos = null;
        this.teste = null;
        this.materialGenetico = null;
        this.listaMateriaisGeneticos = [];
        this.sementeMaterialGenetico = null;
        this.numLocais = null;
        this.numBlocos = null;
        this.numPlantasXParcela = null;
        this.replantio = null;
        this.numTotPlantas = null;
        this.teste1 = null;
        this.teste2 = null;
        this.teste3 = null;
        this.materialGeneticoDescricao = null;
        this.especieDescricao = null;
        this.pomarOrigemDescricao = null;
        this.polinizacao = null;
        this.hibridacaoDescricao = null;
        this.genitoraMatrizDescricao = null;
        this.genitorDescricao = null;
        this.estoqueSementes = null;
    }
}
exports.ProducaoMudasPlanejamento = ProducaoMudasPlanejamento;
