"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabSolicitacaoAnalises = void 0;
const Base_1 = require("./Base");
class LabSolicitacaoAnalises extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.areaSolicitante = null;
        this.data = null;
        this.pomar = null;
        this.especie = null;
        this.materialGenetico = null;
        this.geracao = null;
        this.safra = null;
        this.lote = null;
        this.tipoAmostra = null;
        this.analiseExtra = null;
        this.nomeSolicitante = null;
        this.laboratorio = null;
        this.pesoLote = null;
        this.numFrascos = null;
        this.tipoAnalise = null;
        this.tamanhoAmostraEntrega = null;
        this.reteste = null;
        this.numBASanterior = null;
        this.nomeRecebedor = null;
        this.dataRecebimento = null;
        this.statusAnaliseInterna = null;
        this.statusAnaliseExterna = null;
        this.listaPomares = [];
        this.anexos = [];
    }
}
exports.LabSolicitacaoAnalises = LabSolicitacaoAnalises;
LabSolicitacaoAnalises.AREA_SOLICITANTE_COMERCIAL = 'comercial';
LabSolicitacaoAnalises.AREA_SOLICITANTE_SILVICULTURA = 'silvicultura';
LabSolicitacaoAnalises.TIPO_ANALISE_GERMINACAO = 'germinacao';
LabSolicitacaoAnalises.TIPO_ANALISE_UMIDADE = 'umidade';
