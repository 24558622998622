"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TesteCampoQualidadeMadeiraUtil = void 0;
const angular = require("angular");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const QualidadeMadeiraTesteGenetico_1 = require("../../../../../modelos/QualidadeMadeiraTesteGenetico");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const sweetalert2_1 = require("sweetalert2");
class TesteCampoQualidadeMadeiraUtil {
    static salvarQualidadeMadeiraTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof ctrl.qualidadeMadeiraTesteGeneticoSelecionado === 'undefined') || !ctrl.qualidadeMadeiraTesteGeneticoSelecionado) {
                    return;
                }
                let listaCamposObrigatorios = '';
                if ((typeof ctrl.qualidadeMadeiraTesteGeneticoSelecionado.descricao === 'undefined') || !ctrl.qualidadeMadeiraTesteGeneticoSelecionado.descricao) {
                    listaCamposObrigatorios += '\r\n - <b>Descrição da Qualidade da Madeira - Teste Genético</b>';
                }
                if (listaCamposObrigatorios && (listaCamposObrigatorios.length > 0)) {
                    let mensagem = 'Campo obrigatório não preenchido!' +
                        listaCamposObrigatorios + '\r\n \r\n' +
                        'Preencha este campo e tente novamente.';
                    if (ctrl.ehCelular) {
                        alert(mensagem.replace(/<b>/g, '').replace(/<\/b>/g, ''));
                    }
                    else {
                        yield sweetalert2_1.default.fire({
                            html: mensagem.replace(/\r\n/g, '<br>\r\n'),
                            icon: 'info',
                            showCancelButton: false,
                            showConfirmButton: true,
                            confirmButtonText: 'Ok'
                        });
                    }
                    return;
                }
                ctrl.modalTratamentoTesteGenetico = false;
                ctrl.modalGerarCroquiTesteGenetico = false;
                ctrl.modalGerarCroquiTesteGeneticoDados = null;
                ctrl.modalImportacaoCroquiTesteGenetico = false;
                ctrl.modalHistoricoCroquiTesteGenetico = false;
                ctrl.modalControleManejoTesteGenetico = false;
                ctrl.modalMedicaoDendrometricaTesteGenetico = false;
                ctrl.modalPlanejamentoMedicaoDendrometricaTesteGenetico = false;
                ctrl.modalPlanilhaMedicaoDendrometricaTesteGenetico = false;
                ctrl.modalSelegenMedicaoDendrometricaTesteGenetico = false;
                ctrl.modalAnaliseSelecaoTesteGenetico = false;
                ctrl.modalSumarioAnaliseSelecaoTesteGenetico = false;
                ctrl.modalPlanilhaSelegenAnaliseSelecaoTesteGenetico = false;
                ctrl.modalAnaliseSelegenAnaliseSelecaoTesteGenetico = false;
                ctrl.modalResultadoSelegenAnaliseSelecaoTesteGenetico = false;
                ctrl.modalAnaliseAnoAnaliseSelecaoTesteGenetico = false;
                ctrl.modalAnaliseBlupAnaliseSelecaoTesteGenetico = false;
                ctrl.modalSelecaoGeneticaAnaliseSelecaoTesteGenetico = false;
                ctrl.modalSelecaoCampoAnaliseSelecaoTesteGenetico = false;
                ctrl.modalAnexoAnaliseSelecaoTesteGenetico = false;
                ctrl.modalRelatorioTesteGenetico = false;
                ctrl.modalAnexoTesteGenetico = false;
                ctrl.modalProtocoloTesteGenetico = false;
                ctrl.modalDossieMaterialGenetico = false;
                ctrl.qualidadeMadeiraTesteGeneticoSelecionado.testeGenetico = ctrl.testeGeneticoSelecionado;
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-dialog > .modal-content');
                if (!ctrl.qualidadeMadeiraTesteGeneticoSelecionado.id) {
                    ctrl.qualidadeMadeiraTesteGeneticoSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.QUALIDADE_MADEIRA_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.QUALIDADE_MADEIRA_TESTE_GENETICO)
                        .criar(ctrl.qualidadeMadeiraTesteGeneticoSelecionado);
                }
                else {
                    ctrl.qualidadeMadeiraTesteGeneticoSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.QUALIDADE_MADEIRA_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.QUALIDADE_MADEIRA_TESTE_GENETICO)
                        .atualizar(ctrl.qualidadeMadeiraTesteGeneticoSelecionado);
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (ctrl.qualidadeMadeiraTesteGeneticoSelecionado && ctrl.qualidadeMadeiraTesteGeneticoSelecionado.id) {
                    ctrl.modalQualidadeMadeiraTesteGenetico = false;
                    if (ctrl.ehCelular) {
                        alert(`Salvo com sucesso!`);
                    }
                    else {
                        sweetalert2_1.default.fire({
                            title: 'Salvo com sucesso!',
                            text: 'O lançamento foi salvo no banco de dados.',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'Ok'
                        });
                    }
                    ctrl.listaOpcoesQualidadeMadeiraTesteGenetico = yield ctrl.dadosListaQualidadeMadeiraTesteGenetico();
                }
                yield TesteCampoQualidadeMadeiraUtil.ocultarModalQualidadeMadeiraTesteGenetico(ctrl);
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static exibirModalQualidadeMadeiraTesteGenetico(ctrl, dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.testeGeneticoSelecionado === 'undefined') || !ctrl.testeGeneticoSelecionado) {
                return;
            }
            try {
                ctrl.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                ctrl.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                ctrl.qualidadeMadeiraTesteGeneticoVisualizar = true;
                if ((typeof dados !== 'undefined') && dados && (typeof dados.id !== 'undefined') && dados.id) {
                    ctrl.qualidadeMadeiraTesteGeneticoSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.QUALIDADE_MADEIRA_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.QUALIDADE_MADEIRA_TESTE_GENETICO)
                        .id(dados.id)
                        .ver();
                    if ((typeof ctrl.qualidadeMadeiraTesteGeneticoSelecionado === 'undefined') || !ctrl.qualidadeMadeiraTesteGeneticoSelecionado) {
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        if (ctrl.ehCelular) {
                            alert(`Registro "${dados.id} não encontrado!`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                text: `Registro "${dados.id}" não encontrado.`,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        return;
                    }
                    if ((typeof ctrl.qualidadeMadeiraTesteGeneticoSelecionado.criacao === 'string') && ctrl.qualidadeMadeiraTesteGeneticoSelecionado.criacao) {
                        ctrl.qualidadeMadeiraTesteGeneticoSelecionado.criacao = new Date(ctrl.qualidadeMadeiraTesteGeneticoSelecionado.criacao);
                    }
                    if ((typeof ctrl.qualidadeMadeiraTesteGeneticoSelecionado.dataHoraInclusao === 'string') && ctrl.qualidadeMadeiraTesteGeneticoSelecionado.dataHoraInclusao) {
                        ctrl.qualidadeMadeiraTesteGeneticoSelecionado.dataHoraInclusao = new Date(ctrl.qualidadeMadeiraTesteGeneticoSelecionado.dataHoraInclusao);
                    }
                    if ((typeof ctrl.qualidadeMadeiraTesteGeneticoSelecionado.dataHoraAlteracao === 'string') && ctrl.qualidadeMadeiraTesteGeneticoSelecionado.dataHoraAlteracao) {
                        ctrl.qualidadeMadeiraTesteGeneticoSelecionado.dataHoraAlteracao = new Date(ctrl.qualidadeMadeiraTesteGeneticoSelecionado.dataHoraAlteracao);
                    }
                    if ((typeof dados.visualizar !== 'undefined') && dados.visualizar) {
                        ctrl.qualidadeMadeiraTesteGeneticoVisualizar = true;
                    }
                    else if ((typeof dados.editar !== 'undefined') && dados.editar) {
                        ctrl.qualidadeMadeiraTesteGeneticoVisualizar = false;
                    }
                    else if ((typeof dados.excluir !== 'undefined') && dados.excluir) {
                        ctrl.notificacao
                            .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                            .then((result) => __awaiter(this, void 0, void 0, function* () {
                            if (result.value) {
                                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.QUALIDADE_MADEIRA_TESTE_GENETICO)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.QUALIDADE_MADEIRA_TESTE_GENETICO)
                                    .id(dados.id)
                                    .remover();
                                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                                ctrl.listaOpcoesQualidadeMadeiraTesteGenetico = yield ctrl.dadosListaQualidadeMadeiraTesteGenetico();
                                ctrl.scopeApply();
                            }
                        }));
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                }
                else {
                    ctrl.qualidadeMadeiraTesteGeneticoSelecionado = new QualidadeMadeiraTesteGenetico_1.QualidadeMadeiraTesteGenetico();
                    ctrl.qualidadeMadeiraTesteGeneticoSelecionado.status = true;
                    ctrl.qualidadeMadeiraTesteGeneticoSelecionado.criacao = new Date();
                    ctrl.qualidadeMadeiraTesteGeneticoSelecionado.criacao.setMilliseconds(0);
                    ctrl.qualidadeMadeiraTesteGeneticoSelecionado.dataHoraInclusao = new Date();
                    ctrl.qualidadeMadeiraTesteGeneticoSelecionado.dataHoraInclusao.setMilliseconds(0);
                    ctrl.qualidadeMadeiraTesteGeneticoSelecionado.usuarioInclusao = ctrl.usuarioSimplificado();
                    ctrl.qualidadeMadeiraTesteGeneticoVisualizar = false;
                }
                if ((typeof ctrl.qualidadeMadeiraTesteGeneticoSelecionado.anexos === 'undefined') || (ctrl.qualidadeMadeiraTesteGeneticoSelecionado.anexos === null)) {
                    ctrl.qualidadeMadeiraTesteGeneticoSelecionado.anexos = new Array();
                }
                ctrl.listasFotos.carregarDados(ctrl.sincronia, { qualidadeMadeiraTesteGenetico: ctrl.qualidadeMadeiraTesteGeneticoSelecionado });
                ctrl.modalQualidadeMadeiraTesteGenetico = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static ocultarModalQualidadeMadeiraTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.modalQualidadeMadeiraTesteGenetico = false;
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = ctrl.posicaoTopoAntesModal;
            ctrl.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                        (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
}
exports.TesteCampoQualidadeMadeiraUtil = TesteCampoQualidadeMadeiraUtil;
