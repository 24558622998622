"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AmostraUmidade = void 0;
const Base_1 = require("./Base");
class AmostraUmidade extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.pesoPlaca = null;
        this.pesoVerde = null;
        this.pesoSeco = null;
        this.umidade = null;
    }
}
exports.AmostraUmidade = AmostraUmidade;
