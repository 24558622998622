"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TipoLocalEstoque = void 0;
class TipoLocalEstoque {
    constructor() {
        this.id = null;
        this.descricao = null;
        this.ehViveiro = null;
    }
    static CASA_VEGETACAO() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.tipoLocalEstoqueCasaVegetacao === 'undefined') || (globalThis.globalConstants.tipoLocalEstoqueCasaVegetacao === null)) {
            let tipoLocalEstoqueCasaVegetacao = new TipoLocalEstoque();
            tipoLocalEstoqueCasaVegetacao.id = 'CASA_VEGETACAO';
            tipoLocalEstoqueCasaVegetacao.descricao = 'Casa Vegetação';
            tipoLocalEstoqueCasaVegetacao.ehViveiro = true;
            globalThis.globalConstants.tipoLocalEstoqueCasaVegetacao = tipoLocalEstoqueCasaVegetacao;
        }
        return globalThis.globalConstants.tipoLocalEstoqueCasaVegetacao;
    }
    static CASA_SOMBRA() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.tipoLocalEstoqueCasaSombra === 'undefined') || (globalThis.globalConstants.tipoLocalEstoqueCasaSombra === null)) {
            let tipoLocalEstoqueCasaSombra = new TipoLocalEstoque();
            tipoLocalEstoqueCasaSombra.id = 'CASA_SOMBRA';
            tipoLocalEstoqueCasaSombra.descricao = 'Casa de Sombra';
            tipoLocalEstoqueCasaSombra.ehViveiro = true;
            globalThis.globalConstants.tipoLocalEstoqueCasaSombra = tipoLocalEstoqueCasaSombra;
        }
        return globalThis.globalConstants.tipoLocalEstoqueCasaSombra;
    }
    static SOMBRITE() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.tipoLocalEstoqueSombrite === 'undefined') || (globalThis.globalConstants.tipoLocalEstoqueSombrite === null)) {
            let tipoLocalEstoqueSombrite = new TipoLocalEstoque();
            tipoLocalEstoqueSombrite.id = 'SOMBRITE';
            tipoLocalEstoqueSombrite.descricao = 'Sombrite';
            tipoLocalEstoqueSombrite.ehViveiro = true;
            globalThis.globalConstants.tipoLocalEstoqueSombrite = tipoLocalEstoqueSombrite;
        }
        return globalThis.globalConstants.tipoLocalEstoqueSombrite;
    }
    static PATIO_EXPEDICAO() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.tipoLocalEstoquePatioExpedicao === 'undefined') || (globalThis.globalConstants.tipoLocalEstoquePatioExpedicao === null)) {
            let tipoLocalEstoquePatioExpedicao = new TipoLocalEstoque();
            tipoLocalEstoquePatioExpedicao.id = 'PATIO_EXPEDICAO';
            tipoLocalEstoquePatioExpedicao.descricao = 'Pátio de Expedição';
            tipoLocalEstoquePatioExpedicao.ehViveiro = true;
            globalThis.globalConstants.tipoLocalEstoquePatioExpedicao = tipoLocalEstoquePatioExpedicao;
        }
        return globalThis.globalConstants.tipoLocalEstoquePatioExpedicao;
    }
    static EXPEDIDO() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.tipoLocalEstoqueExpedido === 'undefined') || (globalThis.globalConstants.tipoLocalEstoqueExpedido === null)) {
            let tipoLocalEstoqueExpedido = new TipoLocalEstoque();
            tipoLocalEstoqueExpedido.id = 'EXPEDIDO';
            tipoLocalEstoqueExpedido.descricao = 'Expedido';
            tipoLocalEstoqueExpedido.ehViveiro = true;
            globalThis.globalConstants.tipoLocalEstoqueExpedido = tipoLocalEstoqueExpedido;
        }
        return globalThis.globalConstants.tipoLocalEstoqueExpedido;
    }
    static LISTA() {
        return [
            TipoLocalEstoque.CASA_VEGETACAO(),
            TipoLocalEstoque.CASA_SOMBRA(),
            TipoLocalEstoque.SOMBRITE(),
            TipoLocalEstoque.PATIO_EXPEDICAO(),
            TipoLocalEstoque.EXPEDIDO()
        ];
    }
}
exports.TipoLocalEstoque = TipoLocalEstoque;
