"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TesteCampoMedicaoDendrometricaUtil = void 0;
const angular = require("angular");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const MedicaoDendrometricaTesteGenetico_1 = require("../../../../../modelos/MedicaoDendrometricaTesteGenetico");
const PlanejamentoMedicaoDendrometricaTesteGenetico_1 = require("../../../../../modelos/PlanejamentoMedicaoDendrometricaTesteGenetico");
const PlanilhaMedicaoDendrometricaTesteGenetico_1 = require("../../../../../modelos/PlanilhaMedicaoDendrometricaTesteGenetico");
const SelegenMedicaoDendrometricaTesteGenetico_1 = require("../../../../../modelos/SelegenMedicaoDendrometricaTesteGenetico");
const Planilha_1 = require("../../../../../modelos/Planilha");
const LuckysheetUtil_1 = require("../../../../../util/LuckysheetUtil");
const MenuUtil_1 = require("../../../../../util/MenuUtil");
const DispositivoUtil_1 = require("../../../../../util/DispositivoUtil");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const DataUtil_1 = require("../../../../../util/DataUtil");
const TextoUtil_1 = require("../../../../../util/TextoUtil");
const sweetalert2_1 = require("sweetalert2");
const AnaliseSelecaoTesteGenetico_1 = require("../../../../../modelos/AnaliseSelecaoTesteGenetico");
const NumeroUtil_1 = require("../../../../../util/NumeroUtil");
class TesteCampoMedicaoDendrometricaUtil {
    static adicionarAnoMedicaoDendrometrica(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                return null;
            }
            if (ctrl.ehCelular) {
                if (confirm(`Deseja incluir um novo Ano de Medição ?`)) {
                    if ((typeof ctrl.listaMedicaoDendrometrica !== 'undefined') && ctrl.listaMedicaoDendrometrica) {
                        angular.forEach(ctrl.listaMedicaoDendrometrica, (itemMedicaoDendrometrica) => {
                            if ((typeof itemMedicaoDendrometrica.anosMedicao !== 'undefined') && itemMedicaoDendrometrica.anosMedicao) {
                                itemMedicaoDendrometrica.anosMedicao.push(0);
                            }
                            else {
                                itemMedicaoDendrometrica.anosMedicao = [0];
                            }
                        });
                    }
                }
            }
            else {
                sweetalert2_1.default.fire({
                    title: 'Aguardando Confirmação!',
                    text: 'Deseja incluir um novo Ano de Medição ?',
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não'
                }).then((result) => {
                    if (result.value) {
                        if ((typeof ctrl.listaMedicaoDendrometrica !== 'undefined') && ctrl.listaMedicaoDendrometrica) {
                            angular.forEach(ctrl.listaMedicaoDendrometrica, (itemMedicaoDendrometrica) => {
                                if ((typeof itemMedicaoDendrometrica.anosMedicao !== 'undefined') && itemMedicaoDendrometrica.anosMedicao) {
                                    itemMedicaoDendrometrica.anosMedicao.push(0);
                                }
                                else {
                                    itemMedicaoDendrometrica.anosMedicao = [0];
                                }
                            });
                        }
                    }
                });
            }
            ctrl.scopeApply();
        });
    }
    static importarMedicaoDendrometricaTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.medicaoDendrometricaTesteGeneticoImportacao = true;
            ctrl.medicaoDendrometricaTesteGeneticoImportacaoAnexos = new Array();
            ctrl.medicaoDendrometricaTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static finalizarImportacaoMedicaoDendrometricaTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
                if ((typeof ctrl.medicaoDendrometricaTesteGeneticoImportacaoAnexos !== 'undefined') && ctrl.medicaoDendrometricaTesteGeneticoImportacaoAnexos &&
                    (ctrl.medicaoDendrometricaTesteGeneticoImportacaoAnexos.length > 0)) {
                    if ((typeof ctrl.medicaoDendrometricaTesteGeneticoSelecionado === 'undefined') || !ctrl.medicaoDendrometricaTesteGeneticoSelecionado) {
                        ctrl.medicaoDendrometricaTesteGeneticoSelecionado = new MedicaoDendrometricaTesteGenetico_1.MedicaoDendrometricaTesteGenetico();
                    }
                    if ((typeof ctrl.medicaoDendrometricaTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.medicaoDendrometricaTesteGeneticoSelecionado.planilhas) {
                        ctrl.medicaoDendrometricaTesteGeneticoSelecionado.planilhas = new Array();
                    }
                    if ((typeof ctrl.medicaoDendrometricaTesteGeneticoSelecionado.anexos === 'undefined') || !ctrl.medicaoDendrometricaTesteGeneticoSelecionado.anexos) {
                        ctrl.medicaoDendrometricaTesteGeneticoSelecionado.anexos = new Array();
                    }
                    let anexoSelecionado = null;
                    angular.forEach(ctrl.medicaoDendrometricaTesteGeneticoImportacaoAnexos, (anexo) => {
                        ctrl.medicaoDendrometricaTesteGeneticoSelecionado.anexos.push(anexo);
                        anexoSelecionado = anexo;
                    });
                    yield ctrl.limparPlanilhasCarregadas();
                    yield ctrl.carregarPlanilha('planilha_medicao_dendrometrica_teste_genetico', null, anexoSelecionado);
                    setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                        ctrl.salvarMedicaoDendrometricaTesteGenetico(true);
                    }), 1500, ctrl);
                }
                ctrl.medicaoDendrometricaTesteGeneticoImportacao = false;
                ctrl.medicaoDendrometricaTesteGeneticoImportacaoAnexos = null;
                ctrl.medicaoDendrometricaTesteGeneticoHistorico = false;
                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static cancelarImportacaoMedicaoDendrometricaTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.medicaoDendrometricaTesteGeneticoImportacao = false;
            ctrl.medicaoDendrometricaTesteGeneticoImportacaoAnexos = null;
            ctrl.medicaoDendrometricaTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static exportarMedicaoDendrometricaTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                return;
            }
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
            try {
                if ((typeof globalThis.luckysheet === 'undefined') || !globalThis.luckysheet) {
                    throw new Error('Plugin "luckysheet" não carregado.');
                }
                LuckysheetUtil_1.LuckysheetUtil.exportXLSX(globalThis.luckysheet);
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    static historicoMedicaoDendrometricaTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.medicaoDendrometricaTesteGeneticoImportacao = false;
            ctrl.medicaoDendrometricaTesteGeneticoImportacaoAnexos = null;
            ctrl.medicaoDendrometricaTesteGeneticoHistorico = true;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static selecionarHistoricoMedicaoDendrometricaTesteGenetico(ctrl, keyPlanilha) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                return;
            }
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
            try {
                if ((typeof ctrl.medicaoDendrometricaTesteGeneticoSelecionado === 'undefined') || !ctrl.medicaoDendrometricaTesteGeneticoSelecionado) {
                    ctrl.medicaoDendrometricaTesteGeneticoSelecionado = new MedicaoDendrometricaTesteGenetico_1.MedicaoDendrometricaTesteGenetico();
                }
                if ((typeof ctrl.medicaoDendrometricaTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.medicaoDendrometricaTesteGeneticoSelecionado.planilhas) {
                    ctrl.medicaoDendrometricaTesteGeneticoSelecionado.planilhas = new Array();
                }
                if ((typeof keyPlanilha !== 'undefined') && (keyPlanilha !== null) && (keyPlanilha >= 0) &&
                    (keyPlanilha <= ctrl.medicaoDendrometricaTesteGeneticoSelecionado.planilhas.length - 1)) {
                    const planilhaSelecionada = ctrl.medicaoDendrometricaTesteGeneticoSelecionado.planilhas[keyPlanilha];
                    ctrl.medicaoDendrometricaTesteGeneticoSelecionado.planilhaSelecionada = planilhaSelecionada;
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                        .atualizar(ctrl.medicaoDendrometricaTesteGeneticoSelecionado);
                    yield ctrl.limparPlanilhasCarregadas();
                    setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                        ctrl.exibirModalMedicaoDendrometricaTesteGenetico();
                    }), 500, ctrl);
                    ctrl.medicaoDendrometricaTesteGeneticoImportacao = false;
                    ctrl.medicaoDendrometricaTesteGeneticoImportacaoAnexos = null;
                    ctrl.medicaoDendrometricaTesteGeneticoHistorico = false;
                }
                else {
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.notificacao.alerta('Não foi possível carregar a planilha.', 'A planilha selecionada não é válida ou foi removida.');
                    return;
                }
                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    static cancelarHistoricoMedicaoDendrometricaTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.medicaoDendrometricaTesteGeneticoImportacao = false;
            ctrl.medicaoDendrometricaTesteGeneticoImportacaoAnexos = null;
            ctrl.medicaoDendrometricaTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static salvarMedicaoDendrometricaTesteGenetico(ctrl, ehImportacao = false) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof ctrl.testeGeneticoSelecionado === 'undefined') || !ctrl.testeGeneticoSelecionado) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
                if ((typeof ctrl.medicaoDendrometricaTesteGeneticoSelecionado === 'undefined') || !ctrl.medicaoDendrometricaTesteGeneticoSelecionado) {
                    ctrl.medicaoDendrometricaTesteGeneticoSelecionado = new MedicaoDendrometricaTesteGenetico_1.MedicaoDendrometricaTesteGenetico();
                }
                if ((typeof ctrl.medicaoDendrometricaTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.medicaoDendrometricaTesteGeneticoSelecionado.planilhas) {
                    ctrl.medicaoDendrometricaTesteGeneticoSelecionado.planilhas = new Array();
                }
                let planilha = new Planilha_1.Planilha();
                planilha.status = true;
                planilha.modulo = 'MedicaoDendrometricaTesteGenetico';
                planilha.operacao = ehImportacao ? 'Importar' : 'Salvar';
                planilha.nome = LuckysheetUtil_1.LuckysheetUtil.nomePlanilha();
                planilha.usuarioInclusao = ctrl.usuarioSimplificado();
                planilha.dataHoraInclusao = new Date();
                planilha.gzcompress = true;
                planilha.dados = LuckysheetUtil_1.LuckysheetUtil.compactarDadosPlanilha();
                planilha = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANILHA)
                    .criar(planilha);
                ctrl.medicaoDendrometricaTesteGeneticoSelecionado.planilhas.push(planilha);
                ctrl.medicaoDendrometricaTesteGeneticoSelecionado.planilhaSelecionada = planilha;
                yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                    .atualizar(ctrl.medicaoDendrometricaTesteGeneticoSelecionado);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static exibirModalMedicaoDendrometricaTesteGenetico(ctrl, dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.testeGeneticoSelecionado === 'undefined') || !ctrl.testeGeneticoSelecionado) {
                return;
            }
            try {
                ctrl.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                ctrl.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                ctrl.medicaoDendrometricaTesteGeneticoVisualizar = true;
                if ((typeof dados !== 'undefined') && dados && (typeof dados.id !== 'undefined') && dados.id) {
                    ctrl.medicaoDendrometricaTesteGeneticoSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                        .id(dados.id)
                        .ver();
                    if ((typeof ctrl.medicaoDendrometricaTesteGeneticoSelecionado === 'undefined') || !ctrl.medicaoDendrometricaTesteGeneticoSelecionado) {
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        if (ctrl.ehCelular) {
                            alert(`Registro "${dados.id} não encontrado!`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                text: `Registro "${dados.id}" não encontrado.`,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        return;
                    }
                    if ((typeof ctrl.medicaoDendrometricaTesteGeneticoSelecionado.criacao === 'string') && ctrl.medicaoDendrometricaTesteGeneticoSelecionado.criacao) {
                        ctrl.medicaoDendrometricaTesteGeneticoSelecionado.criacao = new Date(ctrl.medicaoDendrometricaTesteGeneticoSelecionado.criacao);
                    }
                    if ((typeof ctrl.medicaoDendrometricaTesteGeneticoSelecionado.dataHoraInclusao === 'string') && ctrl.medicaoDendrometricaTesteGeneticoSelecionado.dataHoraInclusao) {
                        ctrl.medicaoDendrometricaTesteGeneticoSelecionado.dataHoraInclusao = new Date(ctrl.medicaoDendrometricaTesteGeneticoSelecionado.dataHoraInclusao);
                    }
                    if ((typeof ctrl.medicaoDendrometricaTesteGeneticoSelecionado.dataHoraAlteracao === 'string') && ctrl.medicaoDendrometricaTesteGeneticoSelecionado.dataHoraAlteracao) {
                        ctrl.medicaoDendrometricaTesteGeneticoSelecionado.dataHoraAlteracao = new Date(ctrl.medicaoDendrometricaTesteGeneticoSelecionado.dataHoraAlteracao);
                    }
                    if ((typeof dados.visualizar !== 'undefined') && dados.visualizar) {
                        ctrl.medicaoDendrometricaTesteGeneticoVisualizar = true;
                    }
                    else if ((typeof dados.editar !== 'undefined') && dados.editar) {
                        ctrl.medicaoDendrometricaTesteGeneticoVisualizar = false;
                    }
                    else if ((typeof dados.excluir !== 'undefined') && dados.excluir) {
                        ctrl.notificacao
                            .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                            .then((result) => __awaiter(this, void 0, void 0, function* () {
                            if (result.value) {
                                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                                    .id(dados.id)
                                    .remover();
                                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                                ctrl.listaOpcoesMedicaoDendrometricaTesteGenetico = yield ctrl.dadosListaMedicaoDendrometricaTesteGenetico();
                                ctrl.scopeApply();
                            }
                        }));
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                }
                else {
                    ctrl.medicaoDendrometricaTesteGeneticoSelecionado = new MedicaoDendrometricaTesteGenetico_1.MedicaoDendrometricaTesteGenetico();
                    ctrl.medicaoDendrometricaTesteGeneticoSelecionado.status = true;
                    ctrl.medicaoDendrometricaTesteGeneticoSelecionado.criacao = new Date();
                    ctrl.medicaoDendrometricaTesteGeneticoSelecionado.criacao.setMilliseconds(0);
                    ctrl.medicaoDendrometricaTesteGeneticoSelecionado.dataHoraInclusao = new Date();
                    ctrl.medicaoDendrometricaTesteGeneticoSelecionado.dataHoraInclusao.setMilliseconds(0);
                    ctrl.medicaoDendrometricaTesteGeneticoSelecionado.usuarioInclusao = ctrl.usuarioSimplificado();
                    ctrl.medicaoDendrometricaTesteGeneticoVisualizar = false;
                }
                if ((typeof ctrl.medicaoDendrometricaTesteGeneticoSelecionado.anexos === 'undefined') || (ctrl.medicaoDendrometricaTesteGeneticoSelecionado.anexos === null)) {
                    ctrl.medicaoDendrometricaTesteGeneticoSelecionado.anexos = new Array();
                }
                ctrl.listaOpcoesLancamentoMedicaoDendrometricaTesteGenetico = yield ctrl.dadosListaLancamentoMedicaoDendrometricaTesteGenetico();
                ctrl.modalMedicaoDendrometricaTesteGenetico = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static ocultarModalMedicaoDendrometricaTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.modalMedicaoDendrometricaTesteGenetico = false;
            ctrl.limparPlanilhasCarregadas();
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = ctrl.posicaoTopoAntesModal;
            ctrl.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                        (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    static importarPlanejamentoMedicaoDendrometricaTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.planejamentoMedicaoDendrometricaTesteGeneticoImportacao = true;
            ctrl.planejamentoMedicaoDendrometricaTesteGeneticoImportacaoAnexos = new Array();
            ctrl.planejamentoMedicaoDendrometricaTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static finalizarImportacaoPlanejamentoMedicaoDendrometricaTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
                if ((typeof ctrl.planejamentoMedicaoDendrometricaTesteGeneticoImportacaoAnexos !== 'undefined') && ctrl.planejamentoMedicaoDendrometricaTesteGeneticoImportacaoAnexos &&
                    (ctrl.planejamentoMedicaoDendrometricaTesteGeneticoImportacaoAnexos.length > 0)) {
                    if ((typeof ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado === 'undefined') || !ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado) {
                        ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado = new PlanejamentoMedicaoDendrometricaTesteGenetico_1.PlanejamentoMedicaoDendrometricaTesteGenetico();
                    }
                    if ((typeof ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.planilhas) {
                        ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.planilhas = new Array();
                    }
                    if ((typeof ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.anexos === 'undefined') || !ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.anexos) {
                        ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.anexos = new Array();
                    }
                    let anexoSelecionado = null;
                    angular.forEach(ctrl.planejamentoMedicaoDendrometricaTesteGeneticoImportacaoAnexos, (anexo) => {
                        ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.anexos.push(anexo);
                        anexoSelecionado = anexo;
                    });
                    yield ctrl.limparPlanilhasCarregadas();
                    yield ctrl.carregarPlanilha('planilha_planejamento_medicao_dendrometrica_teste_genetico', null, anexoSelecionado);
                    setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                        ctrl.salvarPlanejamentoMedicaoDendrometricaTesteGenetico(true);
                    }), 1500, ctrl);
                }
                ctrl.planejamentoMedicaoDendrometricaTesteGeneticoImportacao = false;
                ctrl.planejamentoMedicaoDendrometricaTesteGeneticoImportacaoAnexos = null;
                ctrl.planejamentoMedicaoDendrometricaTesteGeneticoHistorico = false;
                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static cancelarImportacaoPlanejamentoMedicaoDendrometricaTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.planejamentoMedicaoDendrometricaTesteGeneticoImportacao = false;
            ctrl.planejamentoMedicaoDendrometricaTesteGeneticoImportacaoAnexos = null;
            ctrl.planejamentoMedicaoDendrometricaTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static exportarPlanejamentoMedicaoDendrometricaTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                return;
            }
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
            try {
                if ((typeof globalThis.luckysheet === 'undefined') || !globalThis.luckysheet) {
                    throw new Error('Plugin "luckysheet" não carregado.');
                }
                LuckysheetUtil_1.LuckysheetUtil.exportXLSX(globalThis.luckysheet);
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    static historicoPlanejamentoMedicaoDendrometricaTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.planejamentoMedicaoDendrometricaTesteGeneticoImportacao = false;
            ctrl.planejamentoMedicaoDendrometricaTesteGeneticoImportacaoAnexos = null;
            ctrl.planejamentoMedicaoDendrometricaTesteGeneticoHistorico = true;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static selecionarHistoricoPlanejamentoMedicaoDendrometricaTesteGenetico(ctrl, keyPlanilha) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                return;
            }
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
            try {
                if ((typeof ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado === 'undefined') || !ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado) {
                    ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado = new PlanejamentoMedicaoDendrometricaTesteGenetico_1.PlanejamentoMedicaoDendrometricaTesteGenetico();
                }
                if ((typeof ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.planilhas) {
                    ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.planilhas = new Array();
                }
                if ((typeof keyPlanilha !== 'undefined') && (keyPlanilha !== null) && (keyPlanilha >= 0) &&
                    (keyPlanilha <= ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.planilhas.length - 1)) {
                    const planilhaSelecionada = ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.planilhas[keyPlanilha];
                    ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.planilhaSelecionada = planilhaSelecionada;
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANEJAMENTO_MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANEJAMENTO_MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                        .atualizar(ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado);
                    yield ctrl.limparPlanilhasCarregadas();
                    setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                        ctrl.exibirModalPlanejamentoMedicaoDendrometricaTesteGenetico();
                    }), 500, ctrl);
                    ctrl.planejamentoMedicaoDendrometricaTesteGeneticoImportacao = false;
                    ctrl.planejamentoMedicaoDendrometricaTesteGeneticoImportacaoAnexos = null;
                    ctrl.planejamentoMedicaoDendrometricaTesteGeneticoHistorico = false;
                }
                else {
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.notificacao.alerta('Não foi possível carregar a planilha.', 'A planilha selecionada não é válida ou foi removida.');
                    return;
                }
                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    static cancelarHistoricoPlanejamentoMedicaoDendrometricaTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.planejamentoMedicaoDendrometricaTesteGeneticoImportacao = false;
            ctrl.planejamentoMedicaoDendrometricaTesteGeneticoImportacaoAnexos = null;
            ctrl.planejamentoMedicaoDendrometricaTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static salvarPlanejamentoMedicaoDendrometricaTesteGenetico(ctrl, ehImportacao = false) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof ctrl.testeGeneticoSelecionado === 'undefined') || !ctrl.testeGeneticoSelecionado) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
                if ((typeof ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado === 'undefined') || !ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado) {
                    ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado = new PlanejamentoMedicaoDendrometricaTesteGenetico_1.PlanejamentoMedicaoDendrometricaTesteGenetico();
                }
                if ((typeof ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.planilhas) {
                    ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.planilhas = new Array();
                }
                let planilha = new Planilha_1.Planilha();
                planilha.status = true;
                planilha.modulo = 'PlanejamentoMedicaoDendrometricaTesteGenetico';
                planilha.operacao = ehImportacao ? 'Importar' : 'Salvar';
                planilha.nome = LuckysheetUtil_1.LuckysheetUtil.nomePlanilha();
                planilha.usuarioInclusao = ctrl.usuarioSimplificado();
                planilha.dataHoraInclusao = new Date();
                planilha.gzcompress = true;
                planilha.dados = LuckysheetUtil_1.LuckysheetUtil.compactarDadosPlanilha();
                planilha = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANILHA)
                    .criar(planilha);
                ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.planilhas.push(planilha);
                ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.planilhaSelecionada = planilha;
                yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANEJAMENTO_MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANEJAMENTO_MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                    .atualizar(ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static exibirModalPlanejamentoMedicaoDendrometricaTesteGenetico(ctrl, dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.testeGeneticoSelecionado === 'undefined') || !ctrl.testeGeneticoSelecionado) {
                return;
            }
            try {
                ctrl.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                ctrl.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                ctrl.planejamentoMedicaoDendrometricaTesteGeneticoVisualizar = true;
                if ((typeof dados !== 'undefined') && dados && (typeof dados.id !== 'undefined') && dados.id) {
                    ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANEJAMENTO_MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANEJAMENTO_MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                        .id(dados.id)
                        .ver();
                    if ((typeof ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado === 'undefined') || !ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado) {
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        if (ctrl.ehCelular) {
                            alert(`Registro "${dados.id} não encontrado!`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                text: `Registro "${dados.id}" não encontrado.`,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        return;
                    }
                    if ((typeof ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.criacao === 'string') && ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.criacao) {
                        ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.criacao = new Date(ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.criacao);
                    }
                    if ((typeof ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.dataHoraInclusao === 'string') && ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.dataHoraInclusao) {
                        ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.dataHoraInclusao = new Date(ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.dataHoraInclusao);
                    }
                    if ((typeof ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.dataHoraAlteracao === 'string') && ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.dataHoraAlteracao) {
                        ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.dataHoraAlteracao = new Date(ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.dataHoraAlteracao);
                    }
                    if ((typeof dados.visualizar !== 'undefined') && dados.visualizar) {
                        ctrl.planejamentoMedicaoDendrometricaTesteGeneticoVisualizar = true;
                    }
                    else if ((typeof dados.editar !== 'undefined') && dados.editar) {
                        ctrl.planejamentoMedicaoDendrometricaTesteGeneticoVisualizar = false;
                    }
                    else if ((typeof dados.excluir !== 'undefined') && dados.excluir) {
                        ctrl.notificacao
                            .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                            .then((result) => __awaiter(this, void 0, void 0, function* () {
                            if (result.value) {
                                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANEJAMENTO_MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANEJAMENTO_MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                                    .id(dados.id)
                                    .remover();
                                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                                ctrl.scopeApply();
                            }
                        }));
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                }
                else {
                    ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado = new PlanejamentoMedicaoDendrometricaTesteGenetico_1.PlanejamentoMedicaoDendrometricaTesteGenetico();
                    ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.status = true;
                    ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.criacao = new Date();
                    ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.criacao.setMilliseconds(0);
                    ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.dataHoraInclusao = new Date();
                    ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.dataHoraInclusao.setMilliseconds(0);
                    ctrl.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado.usuarioInclusao = ctrl.usuarioSimplificado();
                    ctrl.planejamentoMedicaoDendrometricaTesteGeneticoVisualizar = false;
                }
                ctrl.listaOpcoesPlanejamentoMedicaoDendrometricaTesteGenetico = yield ctrl.dadosListaPlanejamentoMedicaoDendrometricaTesteGenetico();
                ctrl.modalPlanejamentoMedicaoDendrometricaTesteGenetico = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static ocultarModalPlanejamentoMedicaoDendrometricaTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.modalPlanejamentoMedicaoDendrometricaTesteGenetico = false;
            ctrl.limparPlanilhasCarregadas();
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = ctrl.posicaoTopoAntesModal;
            ctrl.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                        (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    static importarPlanilhaMedicaoDendrometricaTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.planilhaMedicaoDendrometricaTesteGeneticoImportacao = true;
            ctrl.planilhaMedicaoDendrometricaTesteGeneticoImportacaoAnexos = new Array();
            ctrl.planilhaMedicaoDendrometricaTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static finalizarImportacaoPlanilhaMedicaoDendrometricaTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
                if ((typeof ctrl.planilhaMedicaoDendrometricaTesteGeneticoImportacaoAnexos !== 'undefined') && ctrl.planilhaMedicaoDendrometricaTesteGeneticoImportacaoAnexos &&
                    (ctrl.planilhaMedicaoDendrometricaTesteGeneticoImportacaoAnexos.length > 0)) {
                    if ((typeof ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado === 'undefined') || !ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado) {
                        ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado = new PlanilhaMedicaoDendrometricaTesteGenetico_1.PlanilhaMedicaoDendrometricaTesteGenetico();
                    }
                    if ((typeof ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.planilhas) {
                        ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.planilhas = new Array();
                    }
                    if ((typeof ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.anexos === 'undefined') || !ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.anexos) {
                        ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.anexos = new Array();
                    }
                    let anexoSelecionado = null;
                    angular.forEach(ctrl.planilhaMedicaoDendrometricaTesteGeneticoImportacaoAnexos, (anexo) => {
                        ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.anexos.push(anexo);
                        anexoSelecionado = anexo;
                    });
                    yield ctrl.limparPlanilhasCarregadas();
                    yield ctrl.carregarPlanilha('planilha_medicao_dendrometrica_teste_genetico', null, anexoSelecionado);
                    setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                        ctrl.salvarPlanilhaMedicaoDendrometricaTesteGenetico(true);
                    }), 1500, ctrl);
                }
                ctrl.planilhaMedicaoDendrometricaTesteGeneticoImportacao = false;
                ctrl.planilhaMedicaoDendrometricaTesteGeneticoImportacaoAnexos = null;
                ctrl.planilhaMedicaoDendrometricaTesteGeneticoHistorico = false;
                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static cancelarImportacaoPlanilhaMedicaoDendrometricaTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.planilhaMedicaoDendrometricaTesteGeneticoImportacao = false;
            ctrl.planilhaMedicaoDendrometricaTesteGeneticoImportacaoAnexos = null;
            ctrl.planilhaMedicaoDendrometricaTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static exportarPlanilhaMedicaoDendrometricaTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                return;
            }
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
            try {
                if ((typeof globalThis.luckysheet === 'undefined') || !globalThis.luckysheet) {
                    throw new Error('Plugin "luckysheet" não carregado.');
                }
                LuckysheetUtil_1.LuckysheetUtil.exportXLSX(globalThis.luckysheet);
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    static historicoPlanilhaMedicaoDendrometricaTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.planilhaMedicaoDendrometricaTesteGeneticoImportacao = false;
            ctrl.planilhaMedicaoDendrometricaTesteGeneticoImportacaoAnexos = null;
            ctrl.planilhaMedicaoDendrometricaTesteGeneticoHistorico = true;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static selecionarHistoricoPlanilhaMedicaoDendrometricaTesteGenetico(ctrl, keyPlanilha) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                return;
            }
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
            try {
                if ((typeof ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado === 'undefined') || !ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado) {
                    ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado = new PlanilhaMedicaoDendrometricaTesteGenetico_1.PlanilhaMedicaoDendrometricaTesteGenetico();
                }
                if ((typeof ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.planilhas) {
                    ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.planilhas = new Array();
                }
                if ((typeof keyPlanilha !== 'undefined') && (keyPlanilha !== null) && (keyPlanilha >= 0) &&
                    (keyPlanilha <= ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.planilhas.length - 1)) {
                    const planilhaSelecionada = ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.planilhas[keyPlanilha];
                    ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.planilhaSelecionada = planilhaSelecionada;
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA_MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANILHA_MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                        .atualizar(ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado);
                    yield ctrl.limparPlanilhasCarregadas();
                    setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                        ctrl.exibirModalPlanilhaMedicaoDendrometricaTesteGenetico();
                    }), 500, ctrl);
                    ctrl.planilhaMedicaoDendrometricaTesteGeneticoImportacao = false;
                    ctrl.planilhaMedicaoDendrometricaTesteGeneticoImportacaoAnexos = null;
                    ctrl.planilhaMedicaoDendrometricaTesteGeneticoHistorico = false;
                }
                else {
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.notificacao.alerta('Não foi possível carregar a planilha.', 'A planilha selecionada não é válida ou foi removida.');
                    return;
                }
                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    static cancelarHistoricoPlanilhaMedicaoDendrometricaTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.planilhaMedicaoDendrometricaTesteGeneticoImportacao = false;
            ctrl.planilhaMedicaoDendrometricaTesteGeneticoImportacaoAnexos = null;
            ctrl.planilhaMedicaoDendrometricaTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static salvarPlanilhaMedicaoDendrometricaTesteGenetico(ctrl, ehImportacao = false) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof ctrl.testeGeneticoSelecionado === 'undefined') || !ctrl.testeGeneticoSelecionado) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
                if ((typeof ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado === 'undefined') || !ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado) {
                    ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado = new PlanilhaMedicaoDendrometricaTesteGenetico_1.PlanilhaMedicaoDendrometricaTesteGenetico();
                }
                if ((typeof ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.planilhas) {
                    ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.planilhas = new Array();
                }
                let planilha = new Planilha_1.Planilha();
                planilha.status = true;
                planilha.modulo = 'PlanilhaMedicaoDendrometricaTesteGenetico';
                planilha.operacao = ehImportacao ? 'Importar' : 'Salvar';
                planilha.nome = LuckysheetUtil_1.LuckysheetUtil.nomePlanilha();
                planilha.usuarioInclusao = ctrl.usuarioSimplificado();
                planilha.dataHoraInclusao = new Date();
                planilha.gzcompress = true;
                planilha.dados = LuckysheetUtil_1.LuckysheetUtil.compactarDadosPlanilha();
                planilha = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANILHA)
                    .criar(planilha);
                ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.planilhas.push(planilha);
                ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.planilhaSelecionada = planilha;
                yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA_MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANILHA_MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                    .atualizar(ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado);
                if ((typeof globalThis.luckysheet !== 'undefined') && globalThis.luckysheet) {
                    let listaMedicoes = new Array();
                    let idxLinhaTitulos = null;
                    let idxColunaBloco = null;
                    let idxColunaParc = null;
                    let idxColunaTrat = null;
                    let idxColunaArv = null;
                    let idxColunaDap = null;
                    let idxColunaAlt = null;
                    let idxColunaFor = null;
                    let idxColunaForma = null;
                    let idxColunaBif = null;
                    let idxColunaCq = null;
                    let idxColunaFt = null;
                    for (let linhaIdx = 0; linhaIdx <= 50; linhaIdx++) {
                        for (let colunaIdx = 0; colunaIdx <= 50; colunaIdx++) {
                            let valor = LuckysheetUtil_1.LuckysheetUtil.getCellValue(linhaIdx, colunaIdx);
                            if ((typeof valor !== 'undefined') && (valor !== null)) {
                                let referencia = TextoUtil_1.TextoUtil.converteTextoParaReferencia(valor);
                                if ((idxColunaBloco === null) && ['bloco', 'block'].includes(referencia)) {
                                    idxColunaBloco = colunaIdx;
                                }
                                else if ((idxColunaParc === null) && ['parc', 'parcela', 'plot'].includes(referencia)) {
                                    idxColunaParc = colunaIdx;
                                }
                                else if ((idxColunaTrat === null) && ['trat', 'tratamento', 'progenie', 'trt'].includes(referencia)) {
                                    idxColunaTrat = colunaIdx;
                                }
                                else if ((idxColunaArv === null) && ['arv', 'arvore', 'tree'].includes(referencia)) {
                                    idxColunaArv = colunaIdx;
                                }
                                else if ((idxColunaDap === null) && ['dap', 'diametro'].includes(referencia)) {
                                    idxColunaDap = colunaIdx;
                                }
                                else if ((idxColunaAlt === null) && ['alt', 'altura', 'h'].includes(referencia)) {
                                    idxColunaAlt = colunaIdx;
                                }
                                else if ((idxColunaFor === null) && ['for'].includes(referencia)) {
                                    idxColunaFor = colunaIdx;
                                }
                                else if ((idxColunaForma === null) && ['forma'].includes(referencia)) {
                                    idxColunaForma = colunaIdx;
                                }
                                else if ((idxColunaBif === null) && ['bif', 'bf'].includes(referencia)) {
                                    idxColunaBif = colunaIdx;
                                }
                                else if ((idxColunaCq === null) && ['cq'].includes(referencia)) {
                                    idxColunaCq = colunaIdx;
                                }
                                else if ((idxColunaFt === null) && ['ft'].includes(referencia)) {
                                    idxColunaFt = colunaIdx;
                                }
                            }
                            if ((idxColunaBloco !== null) && (idxColunaParc !== null) && (idxColunaTrat !== null) &&
                                (idxColunaArv !== null) && (idxColunaDap !== null) && (idxColunaAlt !== null) &&
                                (idxColunaFor !== null) && (idxColunaForma !== null) && (idxColunaBif !== null) &&
                                (idxColunaCq !== null) && (idxColunaFt !== null)) {
                                idxLinhaTitulos = linhaIdx;
                                break;
                            }
                        }
                        if ((idxColunaBloco !== null) && ((idxColunaParc !== null) || (idxColunaArv !== null))) {
                            idxLinhaTitulos = linhaIdx;
                            break;
                        }
                    }
                    if ((idxColunaBloco !== null) && ((idxColunaParc !== null) || (idxColunaArv !== null))) {
                        for (let linhaIdx = (idxLinhaTitulos ? idxLinhaTitulos : 0) + 1; linhaIdx <= 3000; linhaIdx++) {
                            let medicao = new MedicaoDendrometricaTesteGenetico_1.MedicaoDendrometricaTesteGenetico();
                            medicao.status = true;
                            medicao.criacao = new Date();
                            medicao.criacao.setMilliseconds(0);
                            medicao.testeGenetico = ctrl.testeGeneticoSimplificado();
                            medicao.anoAnalise = ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                                (typeof ctrl.testeGeneticoSelecionado.anoAnalise !== 'undefined') && ctrl.testeGeneticoSelecionado.anoAnalise
                                ? ctrl.testeGeneticoSelecionado.anoAnalise : null);
                            if (idxColunaBloco !== null) {
                                medicao.bloco = LuckysheetUtil_1.LuckysheetUtil.getCellValue(linhaIdx, idxColunaBloco);
                            }
                            if (idxColunaParc !== null) {
                                medicao.parc = LuckysheetUtil_1.LuckysheetUtil.getCellValue(linhaIdx, idxColunaParc);
                            }
                            else {
                                medicao.parc = 1;
                            }
                            if (idxColunaTrat !== null) {
                                medicao.trat = LuckysheetUtil_1.LuckysheetUtil.getCellValue(linhaIdx, idxColunaTrat);
                            }
                            if (idxColunaArv !== null) {
                                medicao.arv = LuckysheetUtil_1.LuckysheetUtil.getCellValue(linhaIdx, idxColunaArv);
                            }
                            if (idxColunaDap !== null) {
                                medicao.dap = LuckysheetUtil_1.LuckysheetUtil.getCellValue(linhaIdx, idxColunaDap);
                            }
                            if (idxColunaAlt !== null) {
                                medicao.alt = LuckysheetUtil_1.LuckysheetUtil.getCellValue(linhaIdx, idxColunaAlt);
                            }
                            if (idxColunaFor !== null) {
                                medicao.for = LuckysheetUtil_1.LuckysheetUtil.getCellValue(linhaIdx, idxColunaFor);
                            }
                            else if (idxColunaForma !== null) {
                                medicao.for = LuckysheetUtil_1.LuckysheetUtil.getCellValue(linhaIdx, idxColunaForma);
                            }
                            if (idxColunaForma !== null) {
                                medicao.forma = LuckysheetUtil_1.LuckysheetUtil.getCellValue(linhaIdx, idxColunaForma);
                            }
                            else if (idxColunaFor !== null) {
                                medicao.forma = LuckysheetUtil_1.LuckysheetUtil.getCellValue(linhaIdx, idxColunaFor);
                            }
                            if (idxColunaBif !== null) {
                                medicao.bif = LuckysheetUtil_1.LuckysheetUtil.getCellValue(linhaIdx, idxColunaBif);
                            }
                            if (idxColunaCq !== null) {
                                medicao.cq = LuckysheetUtil_1.LuckysheetUtil.getCellValue(linhaIdx, idxColunaCq);
                            }
                            if (idxColunaFt !== null) {
                                medicao.ft = LuckysheetUtil_1.LuckysheetUtil.getCellValue(linhaIdx, idxColunaFt);
                            }
                            if ((typeof medicao.dap !== 'undefined') && (medicao.dap !== null) && (medicao.dap !== '') &&
                                (typeof medicao.alt !== 'undefined') && (medicao.alt !== null) && (medicao.alt !== '')) {
                                medicao.volume = NumeroUtil_1.NumeroUtil.calcularVolumeArvore(medicao.dap, medicao.alt);
                            }
                            if ((typeof medicao.bloco !== 'undefined') && medicao.bloco) {
                                for (const prop in medicao) {
                                    if ((prop !== 'id') && (prop !== 'uuid') && (prop !== 'guid') && (prop !== 'descricao')) {
                                        if ((typeof medicao[prop] === 'undefined') || (medicao[prop] === null)) {
                                            delete medicao[prop];
                                        }
                                    }
                                }
                                listaMedicoes.push(medicao);
                            }
                        }
                    }
                    if ((typeof listaMedicoes !== 'undefined') && listaMedicoes && (listaMedicoes.length > 0)) {
                        let medicaoDendrometrica = new MedicaoDendrometricaTesteGenetico_1.MedicaoDendrometricaTesteGenetico();
                        medicaoDendrometrica.status = true;
                        medicaoDendrometrica.criacao = new Date();
                        medicaoDendrometrica.criacao.setMilliseconds(0);
                        medicaoDendrometrica.testeGenetico = ctrl.testeGeneticoSimplificado();
                        medicaoDendrometrica.anoAnalise = ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                            (typeof ctrl.testeGeneticoSelecionado.anoAnalise !== 'undefined') && ctrl.testeGeneticoSelecionado.anoAnalise
                            ? ctrl.testeGeneticoSelecionado.anoAnalise : null);
                        medicaoDendrometrica.medicoes = listaMedicoes;
                        yield ctrl.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                            .criar(medicaoDendrometrica);
                        let analiseSelecao = new AnaliseSelecaoTesteGenetico_1.AnaliseSelecaoTesteGenetico();
                        analiseSelecao.status = true;
                        analiseSelecao.criacao = new Date();
                        analiseSelecao.criacao.setMilliseconds(0);
                        analiseSelecao.testeGenetico = ctrl.testeGeneticoSimplificado();
                        analiseSelecao.anoAnalise = ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                            (typeof ctrl.testeGeneticoSelecionado.anoAnalise !== 'undefined') && ctrl.testeGeneticoSelecionado.anoAnalise
                            ? ctrl.testeGeneticoSelecionado.anoAnalise : null);
                        analiseSelecao.medicoes = angular.copy(listaMedicoes);
                        yield ctrl.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_SELECAO_TESTE_GENETICO)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANALISE_SELECAO_TESTE_GENETICO)
                            .criar(analiseSelecao);
                        ctrl.listaOpcoesMedicaoDendrometricaTesteGenetico = yield ctrl.dadosListaMedicaoDendrometricaTesteGenetico();
                        ctrl.listaOpcoesAnaliseSelecaoTesteGenetico = yield ctrl.dadosListaAnaliseSelecaoTesteGenetico();
                        ctrl.scopeApply();
                    }
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static exibirModalPlanilhaMedicaoDendrometricaTesteGenetico(ctrl, dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.testeGeneticoSelecionado === 'undefined') || !ctrl.testeGeneticoSelecionado) {
                return;
            }
            try {
                ctrl.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                ctrl.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                ctrl.planilhaMedicaoDendrometricaTesteGeneticoVisualizar = true;
                ctrl.planilhaMedicaoDendrometricaTesteGeneticoImportacao = false;
                ctrl.planilhaMedicaoDendrometricaTesteGeneticoHistorico = false;
                if ((typeof dados === 'undefined') || !dados || (typeof dados.id === 'undefined') || !dados.id) {
                    let lista = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA_MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANILHA_MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                        .filtro({
                        listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                        testeGenetico: ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                            (typeof ctrl.testeGeneticoSelecionado.id !== 'undefined') && ctrl.testeGeneticoSelecionado.id
                            ? ctrl.testeGeneticoSelecionado.id : null),
                        anoAnalise: ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                            (typeof ctrl.testeGeneticoSelecionado.anoAnalise !== 'undefined') && ctrl.testeGeneticoSelecionado.anoAnalise
                            ? ctrl.testeGeneticoSelecionado.anoAnalise : null)
                    })
                        .listar();
                    if ((typeof lista !== 'undefined') && lista) {
                        dados = { id: null, editar: true };
                        angular.forEach(lista, (item) => {
                            if ((typeof item.id !== 'undefined') && item.id) {
                                dados.id = item.id;
                            }
                        });
                    }
                }
                if ((typeof dados !== 'undefined') && dados && (typeof dados.id !== 'undefined') && dados.id) {
                    ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA_MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANILHA_MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                        .id(dados.id)
                        .ver();
                    if ((typeof ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado === 'undefined') || !ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado) {
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        if (ctrl.ehCelular) {
                            alert(`Registro "${dados.id} não encontrado!`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                text: `Registro "${dados.id}" não encontrado.`,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        return;
                    }
                    if ((typeof ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.criacao === 'string') && ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.criacao) {
                        ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.criacao = new Date(ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.criacao);
                    }
                    if ((typeof ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.dataHoraInclusao === 'string') && ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.dataHoraInclusao) {
                        ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.dataHoraInclusao = new Date(ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.dataHoraInclusao);
                    }
                    if ((typeof ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.dataHoraAlteracao === 'string') && ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.dataHoraAlteracao) {
                        ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.dataHoraAlteracao = new Date(ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.dataHoraAlteracao);
                    }
                    if ((typeof dados.visualizar !== 'undefined') && dados.visualizar) {
                        ctrl.planilhaMedicaoDendrometricaTesteGeneticoVisualizar = true;
                    }
                    else if ((typeof dados.editar !== 'undefined') && dados.editar) {
                        ctrl.planilhaMedicaoDendrometricaTesteGeneticoVisualizar = false;
                    }
                    else if ((typeof dados.excluir !== 'undefined') && dados.excluir) {
                        ctrl.notificacao
                            .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                            .then((result) => __awaiter(this, void 0, void 0, function* () {
                            if (result.value) {
                                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA_MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANILHA_MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                                    .id(dados.id)
                                    .remover();
                                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
                            }
                        }));
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                }
                else {
                    ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado = new PlanilhaMedicaoDendrometricaTesteGenetico_1.PlanilhaMedicaoDendrometricaTesteGenetico();
                    ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.status = true;
                    ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.criacao = new Date();
                    ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.criacao.setMilliseconds(0);
                    ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.testeGenetico = ctrl.testeGeneticoSimplificado();
                    ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.anoAnalise = ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                        (typeof ctrl.testeGeneticoSelecionado.anoAnalise !== 'undefined') && ctrl.testeGeneticoSelecionado.anoAnalise
                        ? ctrl.testeGeneticoSelecionado.anoAnalise : null);
                    ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.dataHoraInclusao = new Date();
                    ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.dataHoraInclusao.setMilliseconds(0);
                    ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.usuarioInclusao = ctrl.usuarioSimplificado();
                    ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA_MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANILHA_MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                        .criar(ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado);
                    ctrl.planilhaMedicaoDendrometricaTesteGeneticoVisualizar = false;
                }
                if ((typeof ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.anexos === 'undefined') || (ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.anexos === null)) {
                    ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.anexos = new Array();
                }
                ctrl.limparPlanilhasCarregadas();
                let planilhaId = null;
                let dadosPlanilha = [];
                let dadosAnexo = null;
                if ((typeof ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.planilhaSelecionada !== 'undefined') && ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.planilhaSelecionada &&
                    (typeof ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.planilhaSelecionada.id !== 'undefined') && ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.planilhaSelecionada.id) {
                    planilhaId = ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.planilhaSelecionada.id;
                }
                else {
                    if ((typeof ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.planilhas !== 'undefined') && ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.planilhas) {
                        angular.forEach(ctrl.planilhaMedicaoDendrometricaTesteGeneticoSelecionado.planilhas, (planilha) => {
                            if ((typeof planilha !== 'undefined') && planilha && (typeof planilha.id !== 'undefined') && planilha.id) {
                                planilhaId = planilha.id;
                            }
                        });
                    }
                }
                if ((typeof planilhaId !== 'undefined') && planilhaId) {
                    let planilha = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANILHA)
                        .id(planilhaId)
                        .ver();
                    if ((typeof planilha !== 'undefined') && planilha &&
                        (typeof planilha.dados !== 'undefined') && planilha.dados) {
                        dadosPlanilha = LuckysheetUtil_1.LuckysheetUtil.descompactarDadosPlanilha(planilha.dados);
                    }
                }
                ctrl.carregarPlanilha('planilha_medicao_dendrometrica_teste_genetico', dadosPlanilha, dadosAnexo);
                ctrl.modalPlanilhaMedicaoDendrometricaTesteGenetico = true;
                angular.element('body').css('overflow-y', 'hidden');
                MenuUtil_1.MenuUtil.ajustaZIndexMenusModalPlanilha();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.scopeApply(() => {
                    MenuUtil_1.MenuUtil.ajustaZIndexMenusModalPlanilha();
                });
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static ocultarModalPlanilhaMedicaoDendrometricaTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.modalPlanilhaMedicaoDendrometricaTesteGenetico = false;
            ctrl.limparPlanilhasCarregadas();
            angular.element('body').css('overflow-y', '');
            MenuUtil_1.MenuUtil.restauraZIndexMenus();
            globalThis.posicaoTopoAntesModal = ctrl.posicaoTopoAntesModal;
            ctrl.scopeApply(() => {
                MenuUtil_1.MenuUtil.restauraZIndexMenus();
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                        (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    static importarSelegenMedicaoDendrometricaTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.selegenMedicaoDendrometricaTesteGeneticoImportacao = true;
            ctrl.selegenMedicaoDendrometricaTesteGeneticoImportacaoAnexos = new Array();
            ctrl.selegenMedicaoDendrometricaTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static finalizarImportacaoSelegenMedicaoDendrometricaTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
                if ((typeof ctrl.selegenMedicaoDendrometricaTesteGeneticoImportacaoAnexos !== 'undefined') && ctrl.selegenMedicaoDendrometricaTesteGeneticoImportacaoAnexos &&
                    (ctrl.selegenMedicaoDendrometricaTesteGeneticoImportacaoAnexos.length > 0)) {
                    if ((typeof ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado === 'undefined') || !ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado) {
                        ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado = new SelegenMedicaoDendrometricaTesteGenetico_1.SelegenMedicaoDendrometricaTesteGenetico();
                    }
                    if ((typeof ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.planilhas) {
                        ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.planilhas = new Array();
                    }
                    if ((typeof ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.anexos === 'undefined') || !ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.anexos) {
                        ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.anexos = new Array();
                    }
                    let anexoSelecionado = null;
                    angular.forEach(ctrl.selegenMedicaoDendrometricaTesteGeneticoImportacaoAnexos, (anexo) => {
                        ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.anexos.push(anexo);
                        anexoSelecionado = anexo;
                    });
                    yield ctrl.limparPlanilhasCarregadas();
                    yield ctrl.carregarPlanilha('planilha_selegen_medicao_dendrometrica_teste_genetico', null, anexoSelecionado);
                    setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                        ctrl.salvarSelegenMedicaoDendrometricaTesteGenetico(true);
                    }), 1500, ctrl);
                }
                ctrl.selegenMedicaoDendrometricaTesteGeneticoImportacao = false;
                ctrl.selegenMedicaoDendrometricaTesteGeneticoImportacaoAnexos = null;
                ctrl.selegenMedicaoDendrometricaTesteGeneticoHistorico = false;
                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static cancelarImportacaoSelegenMedicaoDendrometricaTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.selegenMedicaoDendrometricaTesteGeneticoImportacao = false;
            ctrl.selegenMedicaoDendrometricaTesteGeneticoImportacaoAnexos = null;
            ctrl.selegenMedicaoDendrometricaTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static exportarSelegenMedicaoDendrometricaTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                return;
            }
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
            try {
                if ((typeof globalThis.luckysheet === 'undefined') || !globalThis.luckysheet) {
                    throw new Error('Plugin "luckysheet" não carregado.');
                }
                LuckysheetUtil_1.LuckysheetUtil.exportXLSX(globalThis.luckysheet);
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    static historicoSelegenMedicaoDendrometricaTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.selegenMedicaoDendrometricaTesteGeneticoImportacao = false;
            ctrl.selegenMedicaoDendrometricaTesteGeneticoImportacaoAnexos = null;
            ctrl.selegenMedicaoDendrometricaTesteGeneticoHistorico = true;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static selecionarHistoricoSelegenMedicaoDendrometricaTesteGenetico(ctrl, keyPlanilha) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                return;
            }
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
            try {
                if ((typeof ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado === 'undefined') || !ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado) {
                    ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado = new SelegenMedicaoDendrometricaTesteGenetico_1.SelegenMedicaoDendrometricaTesteGenetico();
                }
                if ((typeof ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.planilhas) {
                    ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.planilhas = new Array();
                }
                if ((typeof keyPlanilha !== 'undefined') && (keyPlanilha !== null) && (keyPlanilha >= 0) &&
                    (keyPlanilha <= ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.planilhas.length - 1)) {
                    const planilhaSelecionada = ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.planilhas[keyPlanilha];
                    ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.planilhaSelecionada = planilhaSelecionada;
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.SELEGEN_MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.SELEGEN_MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                        .atualizar(ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado);
                    yield ctrl.limparPlanilhasCarregadas();
                    setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                        ctrl.exibirModalSelegenMedicaoDendrometricaTesteGenetico();
                    }), 500, ctrl);
                    ctrl.selegenMedicaoDendrometricaTesteGeneticoImportacao = false;
                    ctrl.selegenMedicaoDendrometricaTesteGeneticoImportacaoAnexos = null;
                    ctrl.selegenMedicaoDendrometricaTesteGeneticoHistorico = false;
                }
                else {
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.notificacao.alerta('Não foi possível carregar a planilha.', 'A planilha selecionada não é válida ou foi removida.');
                    return;
                }
                ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    static cancelarHistoricoSelegenMedicaoDendrometricaTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.selegenMedicaoDendrometricaTesteGeneticoImportacao = false;
            ctrl.selegenMedicaoDendrometricaTesteGeneticoImportacaoAnexos = null;
            ctrl.selegenMedicaoDendrometricaTesteGeneticoHistorico = false;
            ctrl.scopeApply(() => { LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha(); });
        });
    }
    static salvarSelegenMedicaoDendrometricaTesteGenetico(ctrl, ehImportacao = false) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof ctrl.testeGeneticoSelecionado === 'undefined') || !ctrl.testeGeneticoSelecionado) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-content');
                if ((typeof ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado === 'undefined') || !ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado) {
                    ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado = new SelegenMedicaoDendrometricaTesteGenetico_1.SelegenMedicaoDendrometricaTesteGenetico();
                }
                if ((typeof ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.planilhas === 'undefined') || !ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.planilhas) {
                    ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.planilhas = new Array();
                }
                let planilha = new Planilha_1.Planilha();
                planilha.status = true;
                planilha.modulo = 'SelegenMedicaoDendrometricaTesteGenetico';
                planilha.operacao = ehImportacao ? 'Importar' : 'Salvar';
                planilha.nome = LuckysheetUtil_1.LuckysheetUtil.nomePlanilha();
                planilha.usuarioInclusao = ctrl.usuarioSimplificado();
                planilha.dataHoraInclusao = new Date();
                planilha.gzcompress = true;
                planilha.dados = LuckysheetUtil_1.LuckysheetUtil.compactarDadosPlanilha();
                planilha = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANILHA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANILHA)
                    .criar(planilha);
                ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.planilhas.push(planilha);
                ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.planilhaSelecionada = planilha;
                yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.SELEGEN_MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.SELEGEN_MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                    .atualizar(ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static exibirModalSelegenMedicaoDendrometricaTesteGenetico(ctrl, dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.testeGeneticoSelecionado === 'undefined') || !ctrl.testeGeneticoSelecionado) {
                return;
            }
            try {
                ctrl.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                ctrl.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                ctrl.selegenMedicaoDendrometricaTesteGeneticoVisualizar = true;
                if ((typeof dados !== 'undefined') && dados && (typeof dados.id !== 'undefined') && dados.id) {
                    ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.SELEGEN_MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.SELEGEN_MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                        .id(dados.id)
                        .ver();
                    if ((typeof ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado === 'undefined') || !ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado) {
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        if (ctrl.ehCelular) {
                            alert(`Registro "${dados.id} não encontrado!`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                text: `Registro "${dados.id}" não encontrado.`,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        return;
                    }
                    if ((typeof ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.criacao === 'string') && ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.criacao) {
                        ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.criacao = new Date(ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.criacao);
                    }
                    if ((typeof ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.dataHoraInclusao === 'string') && ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.dataHoraInclusao) {
                        ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.dataHoraInclusao = new Date(ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.dataHoraInclusao);
                    }
                    if ((typeof ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.dataHoraAlteracao === 'string') && ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.dataHoraAlteracao) {
                        ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.dataHoraAlteracao = new Date(ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.dataHoraAlteracao);
                    }
                    if ((typeof dados.visualizar !== 'undefined') && dados.visualizar) {
                        ctrl.selegenMedicaoDendrometricaTesteGeneticoVisualizar = true;
                    }
                    else if ((typeof dados.editar !== 'undefined') && dados.editar) {
                        ctrl.selegenMedicaoDendrometricaTesteGeneticoVisualizar = false;
                    }
                    else if ((typeof dados.excluir !== 'undefined') && dados.excluir) {
                        ctrl.notificacao
                            .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                            .then((result) => __awaiter(this, void 0, void 0, function* () {
                            if (result.value) {
                                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.SELEGEN_MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.SELEGEN_MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                                    .id(dados.id)
                                    .remover();
                                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                                ctrl.scopeApply();
                            }
                        }));
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                }
                else {
                    ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado = new SelegenMedicaoDendrometricaTesteGenetico_1.SelegenMedicaoDendrometricaTesteGenetico();
                    ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.status = true;
                    ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.criacao = new Date();
                    ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.criacao.setMilliseconds(0);
                    ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.dataHoraInclusao = new Date();
                    ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.dataHoraInclusao.setMilliseconds(0);
                    ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.usuarioInclusao = ctrl.usuarioSimplificado();
                    ctrl.selegenMedicaoDendrometricaTesteGeneticoVisualizar = false;
                }
                if ((typeof ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.anexos === 'undefined') || (ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.anexos === null)) {
                    ctrl.selegenMedicaoDendrometricaTesteGeneticoSelecionado.anexos = new Array();
                }
                if ((typeof ctrl.listaOpcoesMedicaoDendrometricaTesteGenetico !== 'undefined') && ctrl.listaOpcoesMedicaoDendrometricaTesteGenetico &&
                    (typeof ctrl.listaOpcoesMedicaoDendrometricaTesteGenetico.data !== 'undefined') && ctrl.listaOpcoesMedicaoDendrometricaTesteGenetico.data) {
                    let txt = '';
                    const campos = ['bloco', 'parc', 'trat', 'arv', 'dap', 'alt', 'forma', 'for', 'cq'];
                    angular.forEach(campos, (campo) => {
                        let s = `${campo}`;
                        let len = ((campo.length >= 8) ? 12 : ((campo.length >= 6) ? 10 : 8));
                        while (s.length < len) {
                            s = s + ' ';
                        }
                        txt += s;
                    });
                    angular.forEach(ctrl.listaOpcoesMedicaoDendrometricaTesteGenetico.data, (item) => {
                        txt += '\r\n';
                        angular.forEach(campos, (campo) => {
                            let s = (typeof item[campo] !== 'undefined') && item[campo] ? `${item[campo]}` : '';
                            let len = ((campo.length >= 8) ? 12 : ((campo.length >= 6) ? 10 : 8));
                            while (s.length < len) {
                                s = s + ' ';
                            }
                            while (s.length > len) {
                                s = s.substring(0, len);
                            }
                            txt += s;
                        });
                    });
                    const dataHoraAtual = new Date();
                    var element = document.createElement('a');
                    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(txt));
                    element.setAttribute('download', 'MedicaoDendrometricaTesteGenetico_' +
                        DataUtil_1.DataUtil.converterDataParaFormatoDDMMYY(dataHoraAtual).replace(/\//g, '-').replace(/\:/g, ';') + '_' +
                        DataUtil_1.DataUtil.converterDataParaFormatoHHMMSS(dataHoraAtual).replace(/\//g, '-').replace(/\:/g, ';') + '.txt');
                    element.style.display = 'none';
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static ocultarModalSelegenMedicaoDendrometricaTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.modalSelegenMedicaoDendrometricaTesteGenetico = false;
            ctrl.limparPlanilhasCarregadas();
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = ctrl.posicaoTopoAntesModal;
            ctrl.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                        (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
}
exports.TesteCampoMedicaoDendrometricaUtil = TesteCampoMedicaoDendrometricaUtil;
