"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelatorioPolinizacaoColetaSementes = void 0;
const Base_1 = require("./Base");
class RelatorioPolinizacaoColetaSementes extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.id = null;
        this.mediaConesPorCruzamento = 0;
        this.mediaSementesPorCruzamento = 0;
        this.mediaSementesPorCone = 0;
    }
}
exports.RelatorioPolinizacaoColetaSementes = RelatorioPolinizacaoColetaSementes;
