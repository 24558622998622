"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProducaoMudaClonalController = void 0;
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const UiGridConstrutor_1 = require("../../../app/construtores/UiGridConstrutor");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const AbstratoController_1 = require("../../../app/AbstratoController");
const ICRUDOperacao_1 = require("../../../app/interfaces/ICRUDOperacao");
const config_app_1 = require("../../../../../config/config.app");
const sweetalert2_1 = require("sweetalert2");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const ProducaoMudaClonal_1 = require("../../../../../modelos/ProducaoMudaClonal");
class ProducaoMudaClonalController extends AbstratoController_1.AbstratoController {
    constructor($scope, $injector) {
        super($scope, $injector, new ProducaoMudaClonal_1.ProducaoMudaClonal());
        this.path = '';
        this.caminho = '';
        this.tipoOperacao = null;
        this.materiaisGeneticos = [];
        this.especies = [];
        this.tipoOperacao = ICRUDOperacao_1.CRUDOperacao;
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.path = config_app_1.config.api.caminho;
            this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL;
            if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR ||
                this.operacao === ICRUDOperacao_1.CRUDOperacao.EDITAR ||
                this.operacao === ICRUDOperacao_1.CRUDOperacao.DUPLICAR) {
                this.materiaisGeneticos = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MATERIAL_GENETICO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.materiaisGeneticos = resposta;
                    }
                });
                this.especies = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESPECIE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESPECIE)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.especies = resposta;
                    }
                });
            }
            this.$scope.$apply();
        });
    }
    salvar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL)
                        .criar(this.modelo);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL)
                        .atualizar(this.modelo);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir um nova Produção de Muda Clonal ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir um nova Produção de Muda Clonal ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    visualizar(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const producaoMudasClonal = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL)
                    .id(id)
                    .ver();
                producaoMudasClonal.criacao = new Date(producaoMudasClonal.criacao);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return producaoMudasClonal;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    remover(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const producaoMudasClonal = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL)
                    .id(id)
                    .remover();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (producaoMudasClonal) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const producaoMudasClonal = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL)
                    .id(this.modelo.id)
                    .restaurar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (producaoMudasClonal) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosLista(filtro = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                this.path = config_app_1.config.api.caminho;
                this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL;
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                var auxColunas = Array();
                auxColunas.push({
                    name: 'codigo',
                    displayName: 'Código',
                    width: 90,
                });
                auxColunas.push({
                    name: 'testeLocal',
                    displayName: 'Teste/Local',
                    width: 190,
                });
                auxColunas.push({
                    name: 'anoTeste',
                    displayName: 'Ano teste',
                    width: 100,
                });
                auxColunas.push({
                    name: 'materialGeneticoTratamento.descricao',
                    displayName: 'Material Genético/Tratamento',
                    width: 140,
                });
                auxColunas.push({
                    name: 'especie.descricao',
                    displayName: 'Espécie',
                    width: 140,
                });
                auxColunas.push({
                    name: 'bloco',
                    displayName: 'Bloco',
                    width: 100,
                });
                auxColunas.push({
                    name: 'parcela',
                    displayName: 'Parcela',
                    width: 100,
                });
                auxColunas.push({
                    name: 'numArvore',
                    displayName: 'Nº Árvore',
                    width: 100,
                });
                auxColunas.push({
                    name: 'codSelecao',
                    displayName: 'Cód. Seleção',
                    width: 100,
                });
                auxColunas.push({
                    name: 'codClone',
                    displayName: 'Cód. Clone',
                    width: 100,
                });
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
                const lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL)
                    .filtro(filtro)
                    .listar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    filtrar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    limparFiltro() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
}
exports.ProducaoMudaClonalController = ProducaoMudaClonalController;
ProducaoMudaClonalController.$inject = ['$scope', '$injector'];
