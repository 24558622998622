"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelatorioMaterialGeneticoPomar = void 0;
const Base_1 = require("./Base");
class RelatorioMaterialGeneticoPomar extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.descricao = null;
        this.genero = null;
        this.especie = null;
        this.geracao = null;
        this.fonte = null;
        this.tipoTratamento = null;
        this.tipoPopulacao = null;
        this.anoImplantacao = null;
        this.localidade = null;
        this.provinciaUf = null;
        this.pais = null;
        this.umo = null;
        this.situacao = null;
        this.nomePopulacao = null;
        this.pomarOrigem = null;
        this.codigoSelecao = null;
        this.procedencia = null;
        this.localidadeProcedencia = null;
        this.tipoPomarBancoTesteProcedencia = null;
        this.codigoOrigemProcedencia = null;
        this.propagulo = null;
        this.polinizacao = null;
        this.hibridacao = null;
        this.hibrido = null;
        this.mae = null;
        this.especieMae = null;
        this.pai = null;
        this.especiePai = null;
        this.cruzamento = null;
        this.codigoCruzamento = null;
        this.conjunto = null;
        this.dialelo = null;
        this.codigoNovaFamilia = null;
        this.codigoClone = null;
        this.minijardim = null;
        this.pomaresMateriaisGeneticos = [];
    }
}
exports.RelatorioMaterialGeneticoPomar = RelatorioMaterialGeneticoPomar;
