"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PerfilClimaticoTesteGenetico = void 0;
const Base_1 = require("./Base");
const uuid_1 = require("uuid");
class PerfilClimaticoTesteGenetico extends Base_1.Base {
    constructor() {
        super();
        this.id = null;
        this.guid = null;
        this.atualizacao = null;
        this.temperatura = null;
        this.precipitacao = null;
        this.temperaturaMinima = null;
        this.coordenadasEstacao = null;
        this.coordenadasEstacao2 = null;
        this.altitudeEstacao = null;
        this.guid = uuid_1.v4();
        this.status = true;
    }
}
exports.PerfilClimaticoTesteGenetico = PerfilClimaticoTesteGenetico;
