"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TipoPolinizacaoPomar = void 0;
var TipoPolinizacaoPomar;
(function (TipoPolinizacaoPomar) {
    TipoPolinizacaoPomar["PLANEJAMENTO_CRUZAMENTOS"] = "planejamento_cruzamentos";
    TipoPolinizacaoPomar["MONITORAMENTO_FLORACAO"] = "monitoramento_floracao";
    TipoPolinizacaoPomar["POLINIZACAO_PINUS"] = "polinizacao_pinus";
    TipoPolinizacaoPomar["POLINIZACAO_EUCALYPTUS"] = "polinizacao_eucalyptus";
    TipoPolinizacaoPomar["COLETA_CONES_SEMENTES"] = "coleta_cones_sementes";
    TipoPolinizacaoPomar["BENEFICIAMENTO_PINUS"] = "beneficiamento_pinus";
    TipoPolinizacaoPomar["BENEFICIAMENTO_EUCALYPTUS"] = "beneficiamento_eucalyptus";
})(TipoPolinizacaoPomar = exports.TipoPolinizacaoPomar || (exports.TipoPolinizacaoPomar = {}));
