"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ControleEnxertiaPomar = void 0;
const Base_1 = require("./Base");
class ControleEnxertiaPomar extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.id = null;
        this.codigo = null;
        this.data = null;
        this.pomar = null;
        this.pomarCodigo = null;
        this.pomarDescricao = null;
        this.regiao = null;
        this.talhao = null;
        this.tipoEnxerto = null;
        this.tipoSobrevivencia = null;
        this.portaEnxertos = [];
        this.enxertosAno1 = [];
        this.enxertosAno2 = [];
        this.enxertosAno3 = [];
        this.anexos = [];
        this.observacoes = null;
        this.usuarioInclusao = null;
        this.dataHoraInclusao = null;
        this.usuarioAlteracao = null;
        this.dataHoraAlteracao = null;
    }
}
exports.ControleEnxertiaPomar = ControleEnxertiaPomar;
