"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DetalheExperimento = void 0;
const Base_1 = require("./Base");
class DetalheExperimento extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.tratamento = null;
        this.testemunha = null;
        this.variavel = null;
    }
}
exports.DetalheExperimento = DetalheExperimento;
