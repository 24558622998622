"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CadastroRota = void 0;
const RotaFactory_1 = require("../../fabricas/RotaFactory");
const CadastroModulo_1 = require("./CadastroModulo");
const MaterialGeneticoController_1 = require("./paginas/materialGenetico/MaterialGeneticoController");
const GeneroController_1 = require("./paginas/genero/GeneroController");
const EspecieController_1 = require("./paginas/especie/EspecieController");
const GeracaoController_1 = require("./paginas/geracao/GeracaoController");
const ConjuntoController_1 = require("./paginas/conjunto/ConjuntoController");
const DialeloController_1 = require("./paginas/dialelo/DialeloController");
const PomarController_1 = require("./paginas/pomar/PomarController");
const TipoPomarController_1 = require("./paginas/tipoPomar/TipoPomarController");
const ClassificacaoSoloController_1 = require("./paginas/classificacaoSolo/ClassificacaoSoloController");
const UnidadeManejoController_1 = require("./paginas/unidadeManejo/UnidadeManejoController");
const RegiaoController_1 = require("./paginas/regiao/RegiaoController");
const TalhaoController_1 = require("./paginas/talhao/TalhaoController");
const ImportaTalhaoController_1 = require("./paginas/importaTalhao/ImportaTalhaoController");
const ImportaShapefileController_1 = require("./paginas/importaShapefile/ImportaShapefileController");
const FonteController_1 = require("./paginas/fonte/FonteController");
const TipoManejoController_1 = require("./paginas/tipoManejo/TipoManejoController");
const TipoDesbasteManejoPomarController_1 = require("./paginas/tipoDesbasteManejoPomar/TipoDesbasteManejoPomarController");
const TipoLimpezaManejoPomarController_1 = require("./paginas/tipoLimpezaManejoPomar/TipoLimpezaManejoPomarController");
const TipoAmostraManejoPomarController_1 = require("./paginas/tipoAmostraManejoPomar/TipoAmostraManejoPomarController");
const FinalidadeAmostraManejoPomarController_1 = require("./paginas/finalidadeAmostraManejoPomar/FinalidadeAmostraManejoPomarController");
const PragaDoencaManejoPomarController_1 = require("./paginas/pragaDoencaManejoPomar/PragaDoencaManejoPomarController");
const FuncionarioController_1 = require("./paginas/funcionario/FuncionarioController");
const PaisController_1 = require("./paginas/pais/PaisController");
const ProvinciaUfController_1 = require("./paginas/provinciaUf/ProvinciaUfController");
const HibridacaoController_1 = require("./paginas/hibridacao/HibridacaoController");
const PolinizacaoController_1 = require("./paginas/polinizacao/PolinizacaoController");
const TipoTestePopulacaoController_1 = require("./paginas/tipoTestePopulacao/TipoTestePopulacaoController");
const SituacaoController_1 = require("./paginas/situacao/SituacaoController");
const CloneMaterialGeneticoController_1 = require("./paginas/cloneMaterialGenetico/CloneMaterialGeneticoController");
const TipoImplantacaoController_1 = require("./paginas/tipoImplantacao/TipoImplantacaoController");
const ProcedenciaController_1 = require("./paginas/procedencia/ProcedenciaController");
const ReproducaoController_1 = require("./paginas/reproducao/ReproducaoController");
const TipoPomarPopulacaoController_1 = require("./paginas/tipoPomarPopulacao/TipoPomarPopulacaoController");
const TipoDelineamentoController_1 = require("./paginas/tipoDelineamento/TipoDelineamentoController");
const DisposicaoIndividuosController_1 = require("./paginas/disposicaoIndividuos/DisposicaoIndividuosController");
const OperacaoManejoPomarController_1 = require("./paginas/operacaoManejoPomar/OperacaoManejoPomarController");
const ProdutoFertilizanteController_1 = require("./paginas/produtoFertilizante/ProdutoFertilizanteController");
const TipoAplicacaoController_1 = require("./paginas/tipoAplicacao/TipoAplicacaoController");
const TipoIntervencaoController_1 = require("./paginas/tipoIntervencao/TipoIntervencaoController");
const TesteGeneticoController_1 = require("./paginas/testeGenetico/TesteGeneticoController");
const AnoEnxertiaController_1 = require("./paginas/anoEnxertia/AnoEnxertiaController");
const EstrobiloController_1 = require("./paginas/estrobilo/EstrobiloController");
const EstagioFloralFemininoController_1 = require("./paginas/estagioFloralFeminino/EstagioFloralFemininoController");
const EstagioFloralMasculinoController_1 = require("./paginas/estagioFloralMasculino/EstagioFloralMasculinoController");
const FaseAvaliadaController_1 = require("./paginas/faseAvaliada/FaseAvaliadaController");
const EstagioFloralEucaliptoController_1 = require("./paginas/estagioFloralEucalipto/EstagioFloralEucaliptoController");
const MaturacaoFrutoController_1 = require("./paginas/maturacaoFruto/MaturacaoFrutoController");
const CultivarController_1 = require("./paginas/cultivar/CultivarController");
const CategoriaPomarController_1 = require("./paginas/categoriaPomar/CategoriaPomarController");
const FinalidadeSemeioController_1 = require("./paginas/finalidadeSemeio/FinalidadeSemeioController");
const UnidadeMedidaController_1 = require("./paginas/unidadeMedida/UnidadeMedidaController");
const TratamentoFungicidaController_1 = require("./paginas/tratamentoFungicida/TratamentoFungicidaController");
const StatusMinijardimController_1 = require("./paginas/statusMinijardim/StatusMinijardimController");
const FinalidadeMultiplicacaoController_1 = require("./paginas/finalidadeMultiplicacao/FinalidadeMultiplicacaoController");
const AcaoMinijardimController_1 = require("./paginas/acaoMinijardim/AcaoMinijardimController");
const CanaletoesController_1 = require("./paginas/canaletoes/CanaletoesController");
const MinijardimController_1 = require("./paginas/minijardim/MinijardimController");
const TipoMinijardimController_1 = require("./paginas/tipoMinijardim/TipoMinijardimController");
const CaixaDAguaController_1 = require("./paginas/caixaDAgua/CaixaDAguaController");
const ReceitaSolucaoController_1 = require("./paginas/receitaSolucao/ReceitaSolucaoController");
const NivelContaminacaoController_1 = require("./paginas/nivelContaminacao/NivelContaminacaoController");
const CasaVegetacaoController_1 = require("./paginas/casaVegetacao/CasaVegetacaoController");
const CargoFuncaoController_1 = require("./paginas/cargoFuncao/CargoFuncaoController");
const LaboratorioController_1 = require("./paginas/laboratorio/LaboratorioController");
const AreaSolicitanteController_1 = require("./paginas/areaSolicitante/AreaSolicitanteController");
const TipoAmostraController_1 = require("./paginas/tipoAmostra/TipoAmostraController");
const TipoAnaliseController_1 = require("./paginas/tipoAnalise/TipoAnaliseController");
const StatusAnaliseController_1 = require("./paginas/statusAnalise/StatusAnaliseController");
const SafraController_1 = require("./paginas/safra/SafraController");
const LoteController_1 = require("./paginas/lote/LoteController");
const PropaguloController_1 = require("./paginas/propagulo/PropaguloController");
const ColetaMacroestacaController_1 = require("./paginas/coletaMacroestaca/ColetaMacroestacaController");
const ClassificacaoBrotacaoController_1 = require("./paginas/classificacaoBrotacao/ClassificacaoBrotacaoController");
const ProdutoDefensivoController_1 = require("./paginas/produtoDefensivo/ProdutoDefensivoController");
const UsuarioController_1 = require("./paginas/usuario/UsuarioController");
const LabSolicitacaoAnalisesController_1 = require("./paginas/labSolicitacaoAnalises/LabSolicitacaoAnalisesController");
const LabMaturacaoConesController_1 = require("./paginas/labMaturacaoCones/LabMaturacaoConesController");
const LabUmidadeSementesController_1 = require("./paginas/labUmidadeSementes/LabUmidadeSementesController");
const LabGerminacaoSementesController_1 = require("./paginas/labGerminacaoSementes/LabGerminacaoSementesController");
const labUmidadePolenController_1 = require("./paginas/labUmidadePolen/labUmidadePolenController");
const LabGerminacaoPolenController_1 = require("./paginas/labGerminacaoPolen/LabGerminacaoPolenController");
const LabEstudoConesController_1 = require("./paginas/labEstudoCones/LabEstudoConesController");
const LabExperimentosController_1 = require("./paginas/labExperimentos/LabExperimentosController");
const LabManuaisProcedimentosController_1 = require("./paginas/labManuaisProcedimentos/LabManuaisProcedimentosController");
const ProducaoMudasExperimentosController_1 = require("./paginas/producaoMudasExperimentos/ProducaoMudasExperimentosController");
const ProducaoMudasSeminalController_1 = require("./paginas/producaoMudasSeminal/ProducaoMudasSeminalController");
const ProducaoMudaClonalController_1 = require("./paginas/producaoMudaClonal/ProducaoMudaClonalController");
const ProducaoMudaClonalEucalyptusController_1 = require("./paginas/producaoMudaClonalEucalyptus/ProducaoMudaClonalEucalyptusController");
const ProducaoMudaClonalEucalyptusResgateMacroestacasController_1 = require("./paginas/producaoMudaClonalEucalyptusResgateMacroestacas/ProducaoMudaClonalEucalyptusResgateMacroestacasController");
const ProducaoMudaClonalEucalyptusMinijardimController_1 = require("./paginas/producaoMudaClonalEucalyptusMinijardim/ProducaoMudaClonalEucalyptusMinijardimController");
const ProducaoMudaClonalEucalyptusMiniestaquiaController_1 = require("./paginas/producaoMudaClonalEucalyptusMiniestaquia/ProducaoMudaClonalEucalyptusMiniestaquiaController");
const ProducaoMudaClonalPinusMinijardimController_1 = require("./paginas/producaoMudaClonalPinusMinijardim/ProducaoMudaClonalPinusMinijardimController");
const ProducaoMudaClonalPinusMiniestaquiaController_1 = require("./paginas/producaoMudaClonalPinusMiniestaquia/ProducaoMudaClonalPinusMiniestaquiaController");
const ProducaoMudaControlePragaDoencaController_1 = require("./paginas/producaoMudaControlePragaDoenca/ProducaoMudaControlePragaDoencaController");
const ProtecaoRegistroCultivarController_1 = require("./paginas/protecaoRegistroCultivar/ProtecaoRegistroCultivarController");
const CaracteristicaDescritorMorfologicoController_1 = require("./paginas/caracteristicaDescritorMorfologico/CaracteristicaDescritorMorfologicoController");
const LocalidadeController_1 = require("./paginas/localidade/LocalidadeController");
const CentroCustoSapController_1 = require("./paginas/centroCustoSap/CentroCustoSapController");
const ContaDepositoSapController_1 = require("./paginas/contaDepositoSap/ContaDepositoSapController");
const ProducaoMudaClonalPinusController_1 = require("./paginas/producaoMudaClonalPinus/ProducaoMudaClonalPinusController");
class CadastroRota {
    constructor($stateProvider) {
        this.$stateProvider = $stateProvider;
        this.rota = null;
        this.rota = new RotaFactory_1.RotaFactory(this.$stateProvider);
        this.iniciar();
    }
    iniciar() {
        this.registrar();
    }
    registrar() {
        this.rota.modulo(CadastroModulo_1.CadastroModulo);
        const materialGenetico = {
            controladora: MaterialGeneticoController_1.MaterialGeneticoController,
            nome: 'materialGenetico',
            titulo: 'Materiais Genéticos',
            autenticar: true,
            template: require('../../modulos/cadastro/paginas/materialGenetico/lista.html')
        };
        this.rota.addListar(materialGenetico);
        materialGenetico.titulo = 'Visualizar Material Genético';
        materialGenetico.template = null;
        this.rota.addVisualizar(materialGenetico);
        materialGenetico.titulo = 'Adicionar novo Material Genético';
        materialGenetico.template = null;
        this.rota.addCriar(materialGenetico);
        materialGenetico.titulo = 'Atualizar Material Genético';
        materialGenetico.template = null;
        this.rota.addEditar(materialGenetico);
        const genero = {
            controladora: GeneroController_1.GeneroController,
            nome: 'genero',
            titulo: 'Gêneros',
            autenticar: true
        };
        this.rota.addListar(genero);
        genero.titulo = 'Visualizar Gênero';
        this.rota.addVisualizar(genero);
        genero.titulo = 'Adicionar novo Gênero';
        this.rota.addCriar(genero);
        genero.titulo = 'Atualizar Gênero';
        this.rota.addEditar(genero);
        const especie = {
            controladora: EspecieController_1.EspecieController,
            nome: 'especie',
            titulo: 'Espécies',
            autenticar: true
        };
        this.rota.addListar(especie);
        especie.titulo = 'Visualizar Espécie';
        this.rota.addVisualizar(especie);
        especie.titulo = 'Adicionar nova Espécie';
        this.rota.addCriar(especie);
        especie.titulo = 'Atualizar Espécie';
        this.rota.addEditar(especie);
        const geracao = {
            controladora: GeracaoController_1.GeracaoController,
            nome: 'geracao',
            titulo: 'Gerações',
            autenticar: true
        };
        this.rota.addListar(geracao);
        geracao.titulo = 'Visualizar Geração';
        this.rota.addVisualizar(geracao);
        geracao.titulo = 'Adicionar nova Geração';
        this.rota.addCriar(geracao);
        geracao.titulo = 'Atualizar Geração';
        this.rota.addEditar(geracao);
        const conjunto = {
            controladora: ConjuntoController_1.ConjuntoController,
            nome: 'conjunto',
            titulo: 'Conjuntos',
            autenticar: true
        };
        this.rota.addListar(conjunto);
        conjunto.titulo = 'Visualizar Conjunto';
        this.rota.addVisualizar(conjunto);
        conjunto.titulo = 'Adicionar novo Conjunto';
        this.rota.addCriar(conjunto);
        conjunto.titulo = 'Atualizar Conjunto';
        this.rota.addEditar(conjunto);
        const dialelo = {
            controladora: DialeloController_1.DialeloController,
            nome: 'dialelo',
            titulo: 'Dialelos',
            autenticar: true
        };
        this.rota.addListar(dialelo);
        dialelo.titulo = 'Visualizar Dialelo';
        this.rota.addVisualizar(dialelo);
        dialelo.titulo = 'Adicionar novo Dialelo';
        this.rota.addCriar(dialelo);
        dialelo.titulo = 'Atualizar Dialelo';
        this.rota.addEditar(dialelo);
        const pomar = {
            controladora: PomarController_1.PomarController,
            nome: 'pomar',
            titulo: 'Pomares e Bancos',
            autenticar: true,
            template: require('../../modulos/cadastro/paginas/pomar/lista.html')
        };
        this.rota.addListar(pomar);
        pomar.titulo = 'Visualizar Pomar';
        pomar.template = null;
        this.rota.addVisualizar(pomar);
        pomar.titulo = 'Adicionar novo Pomar';
        pomar.template = null;
        this.rota.addCriar(pomar);
        pomar.titulo = 'Atualizar Pomar';
        pomar.template = null;
        this.rota.addEditar(pomar);
        const tipoPomar = {
            controladora: TipoPomarController_1.TipoPomarController,
            nome: 'tipoPomar',
            titulo: 'Tipos de Pomar',
            autenticar: true
        };
        this.rota.addListar(tipoPomar);
        tipoPomar.titulo = 'Visualizar Tipo de Pomar';
        this.rota.addVisualizar(tipoPomar);
        tipoPomar.titulo = 'Adicionar novo Tipo de Pomar';
        this.rota.addCriar(tipoPomar);
        tipoPomar.titulo = 'Atualizar Tipo de Pomar';
        this.rota.addEditar(tipoPomar);
        const classificacaoSolo = {
            controladora: ClassificacaoSoloController_1.ClassificacaoSoloController,
            nome: 'classificacaoSolo',
            titulo: 'Classificação de Solo',
            autenticar: true
        };
        this.rota.addListar(classificacaoSolo);
        classificacaoSolo.titulo = 'Visualizar Classificação de Solo';
        this.rota.addVisualizar(classificacaoSolo);
        classificacaoSolo.titulo = 'Adicionar nova Classificação de Solo';
        this.rota.addCriar(classificacaoSolo);
        classificacaoSolo.titulo = 'Atualizar Classificação de Solo';
        this.rota.addEditar(classificacaoSolo);
        const unidadeManejo = {
            controladora: UnidadeManejoController_1.UnidadeManejoController,
            nome: 'unidadeManejo',
            titulo: 'Unidades de Manejo',
            autenticar: true
        };
        this.rota.addListar(unidadeManejo);
        unidadeManejo.titulo = 'Visualizar Unidade de Manejo';
        this.rota.addVisualizar(unidadeManejo);
        unidadeManejo.titulo = 'Adicionar nova Unidade de Manejo';
        this.rota.addCriar(unidadeManejo);
        unidadeManejo.titulo = 'Atualizar Unidade de Manejo';
        this.rota.addEditar(unidadeManejo);
        const regiao = {
            controladora: RegiaoController_1.RegiaoController,
            nome: 'regiao',
            titulo: 'Regiões',
            autenticar: true
        };
        this.rota.addListar(regiao);
        regiao.titulo = 'Visualizar Região';
        this.rota.addVisualizar(regiao);
        regiao.titulo = 'Adicionar nova Região';
        this.rota.addCriar(regiao);
        regiao.titulo = 'Atualizar Região';
        this.rota.addEditar(regiao);
        const talhao = {
            controladora: TalhaoController_1.TalhaoController,
            nome: 'talhao',
            titulo: 'Talhões',
            autenticar: true,
            template: require('../../modulos/cadastro/paginas/talhao/lista.html')
        };
        this.rota.addListar(talhao);
        talhao.titulo = 'Visualizar Talhão';
        talhao.template = null;
        this.rota.addVisualizar(talhao);
        talhao.titulo = 'Adicionar novo Talhão';
        talhao.template = null;
        this.rota.addCriar(talhao);
        talhao.titulo = 'Atualizar Talhão';
        talhao.template = null;
        this.rota.addEditar(talhao);
        const importaTalhao = {
            controladora: ImportaTalhaoController_1.ImportaTalhaoController,
            nome: 'importaTalhao',
            titulo: 'Importação de Regiões e Talhões (planilha Stand)',
            autenticar: true,
            template: require('../../modulos/cadastro/paginas/importaTalhao/lista.html')
        };
        this.rota.addListar(importaTalhao);
        const importaShapefile = {
            controladora: ImportaShapefileController_1.ImportaShapefileController,
            nome: 'importaShapefile',
            titulo: 'Importação de Shapefiles (.shp) / Geolocalização de Talhões',
            autenticar: true
        };
        this.rota.addListar(importaShapefile);
        importaShapefile.titulo = 'Visualizar Shapefile (.shp) / Geolocalização do Talhão';
        this.rota.addVisualizar(importaShapefile);
        importaShapefile.titulo = 'Adicionar novo Shapefile (.shp) / Geolocalização do Talhão';
        this.rota.addCriar(importaShapefile);
        importaShapefile.titulo = 'Atualizar Shapefile (.shp) / Geolocalização do Talhão';
        this.rota.addEditar(importaShapefile);
        const fonte = {
            controladora: FonteController_1.FonteController,
            nome: 'fonte',
            titulo: 'Fontes',
            autenticar: true
        };
        this.rota.addListar(fonte);
        fonte.titulo = 'Visualizar Fonte';
        this.rota.addVisualizar(fonte);
        fonte.titulo = 'Adicionar nova Fonte';
        this.rota.addCriar(fonte);
        fonte.titulo = 'Atualizar Fonte';
        this.rota.addEditar(fonte);
        const tipoManejo = {
            controladora: TipoManejoController_1.TipoManejoController,
            nome: 'tipoManejo',
            titulo: 'Tipos de Manejo',
            autenticar: true
        };
        this.rota.addListar(tipoManejo);
        tipoManejo.titulo = 'Visualizar Tipo de Manejo';
        this.rota.addVisualizar(tipoManejo);
        tipoManejo.titulo = 'Adicionar novo Tipo de Manejo';
        this.rota.addCriar(tipoManejo);
        tipoManejo.titulo = 'Atualizar Tipo de Manejo';
        this.rota.addEditar(tipoManejo);
        const tipoDesbasteManejoPomar = {
            controladora: TipoDesbasteManejoPomarController_1.TipoDesbasteManejoPomarController,
            nome: 'tipoDesbasteManejoPomar',
            titulo: 'Tipo de Desbaste Manejo Pomar',
            autenticar: true
        };
        this.rota.addListar(tipoDesbasteManejoPomar);
        tipoDesbasteManejoPomar.titulo = 'Visualizar Tipo de Desbaste Manejo Pomar';
        this.rota.addVisualizar(tipoDesbasteManejoPomar);
        tipoDesbasteManejoPomar.titulo = 'Adicionar novo Tipo de Desbaste Manejo Pomar';
        this.rota.addCriar(tipoDesbasteManejoPomar);
        tipoDesbasteManejoPomar.titulo = 'Atualizar Tipo de Desbaste Manejo Pomar';
        this.rota.addEditar(tipoDesbasteManejoPomar);
        const tipoLimpezaManejoPomar = {
            controladora: TipoLimpezaManejoPomarController_1.TipoLimpezaManejoPomarController,
            nome: 'tipoLimpezaManejoPomar',
            titulo: 'Tipo de Limpeza Manejo Pomar',
            autenticar: true
        };
        this.rota.addListar(tipoLimpezaManejoPomar);
        tipoLimpezaManejoPomar.titulo = 'Visualizar Tipo de Limpeza Manejo Pomar';
        this.rota.addVisualizar(tipoLimpezaManejoPomar);
        tipoLimpezaManejoPomar.titulo = 'Adicionar novo Tipo de Limpeza Manejo Pomar';
        this.rota.addCriar(tipoLimpezaManejoPomar);
        tipoLimpezaManejoPomar.titulo = 'Atualizar Tipo de Limpeza Manejo Pomar';
        this.rota.addEditar(tipoLimpezaManejoPomar);
        const tipoAmostraManejoPomar = {
            controladora: TipoAmostraManejoPomarController_1.TipoAmostraManejoPomarController,
            nome: 'tipoAmostraManejoPomar',
            titulo: 'Tipo de Amostra Manejo Pomar',
            autenticar: true
        };
        this.rota.addListar(tipoAmostraManejoPomar);
        tipoAmostraManejoPomar.titulo = 'Visualizar Tipo de Amostra Manejo Pomar';
        this.rota.addVisualizar(tipoAmostraManejoPomar);
        tipoAmostraManejoPomar.titulo = 'Adicionar novo Tipo de Amostra Manejo Pomar';
        this.rota.addCriar(tipoAmostraManejoPomar);
        tipoAmostraManejoPomar.titulo = 'Atualizar Tipo de Amostra Manejo Pomar';
        this.rota.addEditar(tipoAmostraManejoPomar);
        const finalidadeAmostraManejoPomar = {
            controladora: FinalidadeAmostraManejoPomarController_1.FinalidadeAmostraManejoPomarController,
            nome: 'finalidadeAmostraManejoPomar',
            titulo: 'Finalidade de Amostra Manejo Pomar',
            autenticar: true
        };
        this.rota.addListar(finalidadeAmostraManejoPomar);
        finalidadeAmostraManejoPomar.titulo = 'Visualizar Finalidade de Amostra Manejo Pomar';
        this.rota.addVisualizar(finalidadeAmostraManejoPomar);
        finalidadeAmostraManejoPomar.titulo = 'Adicionar nova Finalidade de Amostra Manejo Pomar';
        this.rota.addCriar(finalidadeAmostraManejoPomar);
        finalidadeAmostraManejoPomar.titulo = 'Atualizar Finalidade de Amostra Manejo Pomar';
        this.rota.addEditar(finalidadeAmostraManejoPomar);
        const pragaDoencaManejoPomar = {
            controladora: PragaDoencaManejoPomarController_1.PragaDoencaManejoPomarController,
            nome: 'pragaDoencaManejoPomar',
            titulo: 'Praga e Doença Manejo Pomar',
            autenticar: true
        };
        this.rota.addListar(pragaDoencaManejoPomar);
        pragaDoencaManejoPomar.titulo = 'Visualizar Praga e Doença Manejo Pomar';
        this.rota.addVisualizar(pragaDoencaManejoPomar);
        pragaDoencaManejoPomar.titulo = 'Adicionar nova Praga e Doença Manejo Pomar';
        this.rota.addCriar(pragaDoencaManejoPomar);
        pragaDoencaManejoPomar.titulo = 'Atualizar Praga e Doença Manejo Pomar';
        this.rota.addEditar(pragaDoencaManejoPomar);
        const funcionario = {
            controladora: FuncionarioController_1.FuncionarioController,
            nome: 'funcionario',
            titulo: 'Funcionário',
            autenticar: true
        };
        this.rota.addListar(funcionario);
        funcionario.titulo = 'Visualizar Funcionário';
        this.rota.addVisualizar(funcionario);
        funcionario.titulo = 'Adicionar novo Funcionário';
        this.rota.addCriar(funcionario);
        funcionario.titulo = 'Atualizar Funcionário';
        this.rota.addEditar(funcionario);
        const pais = {
            controladora: PaisController_1.PaisController,
            nome: 'pais',
            titulo: 'País',
            autenticar: true
        };
        this.rota.addListar(pais);
        pais.titulo = 'Visualizar País';
        this.rota.addVisualizar(pais);
        pais.titulo = 'Adicionar novo País';
        this.rota.addCriar(pais);
        pais.titulo = 'Atualizar País';
        this.rota.addEditar(pais);
        const provinciaUf = {
            controladora: ProvinciaUfController_1.ProvinciaUfController,
            nome: 'provinciaUf',
            titulo: 'Província/UF',
            autenticar: true
        };
        this.rota.addListar(provinciaUf);
        provinciaUf.titulo = 'Visualizar Província/UF';
        this.rota.addVisualizar(provinciaUf);
        provinciaUf.titulo = 'Adicionar nova Província/UF';
        this.rota.addCriar(provinciaUf);
        provinciaUf.titulo = 'Atualizar Província/UF';
        this.rota.addEditar(provinciaUf);
        const localidade = {
            controladora: LocalidadeController_1.LocalidadeController,
            nome: 'localidade',
            titulo: 'Município',
            autenticar: true
        };
        this.rota.addListar(localidade);
        localidade.titulo = 'Visualizar Município';
        this.rota.addVisualizar(localidade);
        localidade.titulo = 'Adicionar nova Município';
        this.rota.addCriar(localidade);
        localidade.titulo = 'Atualizar Município';
        this.rota.addEditar(localidade);
        const hibridacao = {
            controladora: HibridacaoController_1.HibridacaoController,
            nome: 'hibridacao',
            titulo: 'Hibridação',
            autenticar: true
        };
        this.rota.addListar(hibridacao);
        hibridacao.titulo = 'Visualizar Hibridação';
        this.rota.addVisualizar(hibridacao);
        hibridacao.titulo = 'Adicionar nova Hibridação';
        this.rota.addCriar(hibridacao);
        hibridacao.titulo = 'Atualizar Hibridação';
        this.rota.addEditar(hibridacao);
        const polinizacao = {
            controladora: PolinizacaoController_1.PolinizacaoController,
            nome: 'polinizacao',
            titulo: 'Polinização',
            autenticar: true
        };
        this.rota.addListar(polinizacao);
        polinizacao.titulo = 'Visualizar Polinização';
        this.rota.addVisualizar(polinizacao);
        polinizacao.titulo = 'Adicionar nova Polinização';
        this.rota.addCriar(polinizacao);
        polinizacao.titulo = 'Atualizar Polinização';
        this.rota.addEditar(polinizacao);
        const tipoTestePopulacao = {
            controladora: TipoTestePopulacaoController_1.TipoTestePopulacaoController,
            nome: 'tipoTestePopulacao',
            titulo: 'Tipo de Teste/População',
            autenticar: true
        };
        this.rota.addListar(tipoTestePopulacao);
        tipoTestePopulacao.titulo = 'Visualizar Tipo de Teste/População';
        this.rota.addVisualizar(tipoTestePopulacao);
        tipoTestePopulacao.titulo = 'Adicionar novo Tipo de Teste/População';
        this.rota.addCriar(tipoTestePopulacao);
        tipoTestePopulacao.titulo = 'Atualizar Tipo de Teste/População';
        this.rota.addEditar(tipoTestePopulacao);
        const situacao = {
            controladora: SituacaoController_1.SituacaoController,
            nome: 'situacao',
            titulo: 'Situação',
            autenticar: true
        };
        this.rota.addListar(situacao);
        situacao.titulo = 'Visualizar Situação';
        this.rota.addVisualizar(situacao);
        situacao.titulo = 'Adicionar nova Situação';
        this.rota.addCriar(situacao);
        situacao.titulo = 'Atualizar Situação';
        this.rota.addEditar(situacao);
        const cloneMaterialGenetico = {
            controladora: CloneMaterialGeneticoController_1.CloneMaterialGeneticoController,
            nome: 'cloneMaterialGenetico',
            titulo: 'Clone',
            autenticar: true,
            template: require('../../modulos/cadastro/paginas/cloneMaterialGenetico/lista.html')
        };
        this.rota.addListar(cloneMaterialGenetico);
        cloneMaterialGenetico.titulo = 'Visualizar Clone';
        cloneMaterialGenetico.template = null;
        this.rota.addVisualizar(cloneMaterialGenetico);
        cloneMaterialGenetico.titulo = 'Adicionar novo Clone';
        cloneMaterialGenetico.template = null;
        this.rota.addCriar(cloneMaterialGenetico);
        cloneMaterialGenetico.titulo = 'Atualizar Clone';
        cloneMaterialGenetico.template = null;
        this.rota.addEditar(cloneMaterialGenetico);
        const tipoImplantacao = {
            controladora: TipoImplantacaoController_1.TipoImplantacaoController,
            nome: 'tipoImplantacao',
            titulo: 'Tipo de Implantação',
            autenticar: true
        };
        this.rota.addListar(tipoImplantacao);
        tipoImplantacao.titulo = 'Visualizar Tipo de Implantação';
        this.rota.addVisualizar(tipoImplantacao);
        tipoImplantacao.titulo = 'Adicionar novo Tipo de Implantação';
        this.rota.addCriar(tipoImplantacao);
        tipoImplantacao.titulo = 'Atualizar Tipo de Implantação';
        this.rota.addEditar(tipoImplantacao);
        const procedencia = {
            controladora: ProcedenciaController_1.ProcedenciaController,
            nome: 'procedencia',
            titulo: 'Procedência',
            autenticar: true
        };
        this.rota.addListar(procedencia);
        procedencia.titulo = 'Visualizar Procedência';
        this.rota.addVisualizar(procedencia);
        procedencia.titulo = 'Adicionar nova Procedência';
        this.rota.addCriar(procedencia);
        procedencia.titulo = 'Atualizar Procedência';
        this.rota.addEditar(procedencia);
        const reproducao = {
            controladora: ReproducaoController_1.ReproducaoController,
            nome: 'reproducao',
            titulo: 'Reprodução',
            autenticar: true
        };
        this.rota.addListar(reproducao);
        reproducao.titulo = 'Visualizar Reprodução';
        this.rota.addVisualizar(reproducao);
        reproducao.titulo = 'Adicionar nova Reprodução';
        this.rota.addCriar(reproducao);
        reproducao.titulo = 'Atualizar Reprodução';
        this.rota.addEditar(reproducao);
        const tipoPomarPopulacao = {
            controladora: TipoPomarPopulacaoController_1.TipoPomarPopulacaoController,
            nome: 'tipoPomarPopulacao',
            titulo: 'Tipo de Pomar/População',
            autenticar: true
        };
        this.rota.addListar(tipoPomarPopulacao);
        tipoPomarPopulacao.titulo = 'Visualizar Tipo de Pomar/População';
        this.rota.addVisualizar(tipoPomarPopulacao);
        tipoPomarPopulacao.titulo = 'Adicionar novo Tipo de Pomar/População';
        this.rota.addCriar(tipoPomarPopulacao);
        tipoPomarPopulacao.titulo = 'Atualizar Tipo de Pomar/População';
        this.rota.addEditar(tipoPomarPopulacao);
        const tipoDelineamento = {
            controladora: TipoDelineamentoController_1.TipoDelineamentoController,
            nome: 'tipoDelineamento',
            titulo: 'Tipo de Delineamento',
            autenticar: true
        };
        this.rota.addListar(tipoDelineamento);
        tipoDelineamento.titulo = 'Visualizar Tipo de Delineamento';
        this.rota.addVisualizar(tipoDelineamento);
        tipoDelineamento.titulo = 'Adicionar novo Tipo de Delineamento';
        this.rota.addCriar(tipoDelineamento);
        tipoDelineamento.titulo = 'Atualizar Tipo de Delineamento';
        this.rota.addEditar(tipoDelineamento);
        const disposicaoIndividuos = {
            controladora: DisposicaoIndividuosController_1.DisposicaoIndividuosController,
            nome: 'disposicaoIndividuos',
            titulo: 'Disposição dos Indivíduos',
            autenticar: true
        };
        this.rota.addListar(disposicaoIndividuos);
        disposicaoIndividuos.titulo = 'Visualizar Disposição dos Indivíduos';
        this.rota.addVisualizar(disposicaoIndividuos);
        disposicaoIndividuos.titulo = 'Adicionar nova Disposição dos Indivíduos';
        this.rota.addCriar(disposicaoIndividuos);
        disposicaoIndividuos.titulo = 'Atualizar Disposição dos Indivíduos';
        this.rota.addEditar(disposicaoIndividuos);
        const operacaoManejoPomar = {
            controladora: OperacaoManejoPomarController_1.OperacaoManejoPomarController,
            nome: 'operacaoManejoPomar',
            titulo: 'Operação de Manejo Pomar',
            autenticar: true
        };
        this.rota.addListar(operacaoManejoPomar);
        operacaoManejoPomar.titulo = 'Visualizar Operação de Manejo Pomar';
        this.rota.addVisualizar(operacaoManejoPomar);
        operacaoManejoPomar.titulo = 'Adicionar nova Operação de Manejo Pomar';
        this.rota.addCriar(operacaoManejoPomar);
        operacaoManejoPomar.titulo = 'Atualizar Operação de Manejo Pomar';
        this.rota.addEditar(operacaoManejoPomar);
        const produtoFertilizante = {
            controladora: ProdutoFertilizanteController_1.ProdutoFertilizanteController,
            nome: 'produtoFertilizante',
            titulo: 'Produto Fertilizante',
            autenticar: true
        };
        this.rota.addListar(produtoFertilizante);
        produtoFertilizante.titulo = 'Visualizar Produto Fertilizante';
        this.rota.addVisualizar(produtoFertilizante);
        produtoFertilizante.titulo = 'Adicionar novo Produto Fertilizante';
        this.rota.addCriar(produtoFertilizante);
        produtoFertilizante.titulo = 'Atualizar Produto Fertilizante';
        this.rota.addEditar(produtoFertilizante);
        const tipoAplicacao = {
            controladora: TipoAplicacaoController_1.TipoAplicacaoController,
            nome: 'tipoAplicacao',
            titulo: 'Tipo de Aplicação',
            autenticar: true
        };
        this.rota.addListar(tipoAplicacao);
        tipoAplicacao.titulo = 'Visualizar Tipo de Aplicação';
        this.rota.addVisualizar(tipoAplicacao);
        tipoAplicacao.titulo = 'Adicionar novo Tipo de Aplicação';
        this.rota.addCriar(tipoAplicacao);
        tipoAplicacao.titulo = 'Atualizar Tipo de Aplicação';
        this.rota.addEditar(tipoAplicacao);
        const tipoIntervencao = {
            controladora: TipoIntervencaoController_1.TipoIntervencaoController,
            nome: 'tipoIntervencao',
            titulo: 'Tipo de Intervenção',
            autenticar: true
        };
        this.rota.addListar(tipoIntervencao);
        tipoIntervencao.titulo = 'Visualizar Tipo de Intervenção';
        this.rota.addVisualizar(tipoIntervencao);
        tipoIntervencao.titulo = 'Adicionar novo Tipo de Intervenção';
        this.rota.addCriar(tipoIntervencao);
        tipoIntervencao.titulo = 'Atualizar Tipo de Intervenção';
        this.rota.addEditar(tipoIntervencao);
        const testeGenetico = {
            controladora: TesteGeneticoController_1.TesteGeneticoController,
            nome: 'testeGenetico',
            titulo: 'Teste Genético',
            autenticar: true
        };
        this.rota.addListar(testeGenetico);
        testeGenetico.titulo = 'Visualizar Teste Genético';
        this.rota.addVisualizar(testeGenetico);
        testeGenetico.titulo = 'Adicionar novo Teste Genético';
        this.rota.addCriar(testeGenetico);
        testeGenetico.titulo = 'Atualizar Teste Genético';
        this.rota.addEditar(testeGenetico);
        const anoEnxertia = {
            controladora: AnoEnxertiaController_1.AnoEnxertiaController,
            nome: 'anoEnxertia',
            titulo: 'Ano Enxertia',
            autenticar: true
        };
        this.rota.addListar(anoEnxertia);
        anoEnxertia.titulo = 'Visualizar Ano Enxertia';
        this.rota.addVisualizar(anoEnxertia);
        anoEnxertia.titulo = 'Adicionar novo Ano Enxertia';
        this.rota.addCriar(anoEnxertia);
        anoEnxertia.titulo = 'Atualizar Ano Enxertia';
        this.rota.addEditar(anoEnxertia);
        const estrobilo = {
            controladora: EstrobiloController_1.EstrobiloController,
            nome: 'estrobilo',
            titulo: 'Estróbilo',
            autenticar: true
        };
        this.rota.addListar(estrobilo);
        estrobilo.titulo = 'Visualizar Estróbilo';
        this.rota.addVisualizar(estrobilo);
        estrobilo.titulo = 'Adicionar novo Estróbilo';
        this.rota.addCriar(estrobilo);
        estrobilo.titulo = 'Atualizar Estróbilo';
        this.rota.addEditar(estrobilo);
        const estagioFloralFeminino = {
            controladora: EstagioFloralFemininoController_1.EstagioFloralFemininoController,
            nome: 'estagioFloralFeminino',
            titulo: 'Estágio Floral Feminino',
            autenticar: true
        };
        this.rota.addListar(estagioFloralFeminino);
        estagioFloralFeminino.titulo = 'Visualizar Estágio Floral Feminino';
        this.rota.addVisualizar(estagioFloralFeminino);
        estagioFloralFeminino.titulo = 'Adicionar novo Estágio Floral Feminino';
        this.rota.addCriar(estagioFloralFeminino);
        estagioFloralFeminino.titulo = 'Atualizar Estágio Floral Feminino';
        this.rota.addEditar(estagioFloralFeminino);
        const estagioFloralMasculino = {
            controladora: EstagioFloralMasculinoController_1.EstagioFloralMasculinoController,
            nome: 'estagioFloralMasculino',
            titulo: 'Estagio Floral Masculino',
            autenticar: true
        };
        this.rota.addListar(estagioFloralMasculino);
        estagioFloralMasculino.titulo = 'Visualizar Estagio Floral Masculino';
        this.rota.addVisualizar(estagioFloralMasculino);
        estagioFloralMasculino.titulo = 'Adicionar novo Estagio Floral Masculino';
        this.rota.addCriar(estagioFloralMasculino);
        estagioFloralMasculino.titulo = 'Atualizar Estagio Floral Masculino';
        this.rota.addEditar(estagioFloralMasculino);
        const faseAvaliada = {
            controladora: FaseAvaliadaController_1.FaseAvaliadaController,
            nome: 'faseAvaliada',
            titulo: 'Fase Avaliada',
            autenticar: true
        };
        this.rota.addListar(faseAvaliada);
        faseAvaliada.titulo = 'Visualizar Fase Avaliada';
        this.rota.addVisualizar(faseAvaliada);
        faseAvaliada.titulo = 'Adicionar nova Fase Avaliada';
        this.rota.addCriar(faseAvaliada);
        faseAvaliada.titulo = 'Atualizar Fase Avaliada';
        this.rota.addEditar(faseAvaliada);
        const estagioFloralEucalipto = {
            controladora: EstagioFloralEucaliptoController_1.EstagioFloralEucaliptoController,
            nome: 'estagioFloralEucalipto',
            titulo: 'Estágio Floral Eucalipto',
            autenticar: true
        };
        this.rota.addListar(estagioFloralEucalipto);
        estagioFloralEucalipto.titulo = 'Visualizar Estágio Floral Eucalipto';
        this.rota.addVisualizar(estagioFloralEucalipto);
        estagioFloralEucalipto.titulo = 'Adicionar novo Estágio Floral Eucalipto';
        this.rota.addCriar(estagioFloralEucalipto);
        estagioFloralEucalipto.titulo = 'Atualizar Estágio Floral Eucalipto';
        this.rota.addEditar(estagioFloralEucalipto);
        const maturacaoFruto = {
            controladora: MaturacaoFrutoController_1.MaturacaoFrutoController,
            nome: 'maturacaoFruto',
            titulo: 'Maturação Fruto',
            autenticar: true
        };
        this.rota.addListar(maturacaoFruto);
        maturacaoFruto.titulo = 'Visualizar Maturação Fruto';
        this.rota.addVisualizar(maturacaoFruto);
        maturacaoFruto.titulo = 'Adicionar nova Maturação Fruto';
        this.rota.addCriar(maturacaoFruto);
        maturacaoFruto.titulo = 'Atualizar Maturação Fruto';
        this.rota.addEditar(maturacaoFruto);
        const cultivar = {
            controladora: CultivarController_1.CultivarController,
            nome: 'cultivar',
            titulo: 'Cultivar',
            autenticar: true
        };
        this.rota.addListar(cultivar);
        cultivar.titulo = 'Visualizar Cultivar';
        this.rota.addVisualizar(cultivar);
        cultivar.titulo = 'Adicionar novo Cultivar';
        this.rota.addCriar(cultivar);
        cultivar.titulo = 'Atualizar Cultivar';
        this.rota.addEditar(cultivar);
        const categoriaPomar = {
            controladora: CategoriaPomarController_1.CategoriaPomarController,
            nome: 'categoriaPomar',
            titulo: 'Categoria Pomar',
            autenticar: true
        };
        this.rota.addListar(categoriaPomar);
        categoriaPomar.titulo = 'Visualizar Categoria Pomar';
        this.rota.addVisualizar(categoriaPomar);
        categoriaPomar.titulo = 'Adicionar nova Categoria Pomar';
        this.rota.addCriar(categoriaPomar);
        categoriaPomar.titulo = 'Atualizar Categoria Pomar';
        this.rota.addEditar(categoriaPomar);
        const finalidadeSemeio = {
            controladora: FinalidadeSemeioController_1.FinalidadeSemeioController,
            nome: 'finalidadeSemeio',
            titulo: 'Finalidade Semeio',
            autenticar: true
        };
        this.rota.addListar(finalidadeSemeio);
        finalidadeSemeio.titulo = 'Visualizar Finalidade Semeio';
        this.rota.addVisualizar(finalidadeSemeio);
        finalidadeSemeio.titulo = 'Adicionar nova Finalidade Semeio';
        this.rota.addCriar(finalidadeSemeio);
        finalidadeSemeio.titulo = 'Atualizar Finalidade Semeio';
        this.rota.addEditar(finalidadeSemeio);
        const unidadeMedida = {
            controladora: UnidadeMedidaController_1.UnidadeMedidaController,
            nome: 'unidadeMedida',
            titulo: 'Unidade de Medida',
            autenticar: true
        };
        this.rota.addListar(unidadeMedida);
        unidadeMedida.titulo = 'Visualizar Unidade de Medida';
        this.rota.addVisualizar(unidadeMedida);
        unidadeMedida.titulo = 'Adicionar nova Unidade de Medida';
        this.rota.addCriar(unidadeMedida);
        unidadeMedida.titulo = 'Atualizar Unidade de Medida';
        this.rota.addEditar(unidadeMedida);
        const tratamentoFungicida = {
            controladora: TratamentoFungicidaController_1.TratamentoFungicidaController,
            nome: 'tratamentoFungicida',
            titulo: 'Tratamento Fungicida',
            autenticar: true
        };
        this.rota.addListar(tratamentoFungicida);
        tratamentoFungicida.titulo = 'Visualizar Tratamento Fungicida';
        this.rota.addVisualizar(tratamentoFungicida);
        tratamentoFungicida.titulo = 'Adicionar novo Tratamento Fungicida';
        this.rota.addCriar(tratamentoFungicida);
        tratamentoFungicida.titulo = 'Atualizar Tratamento Fungicida';
        this.rota.addEditar(tratamentoFungicida);
        const statusMinijardim = {
            controladora: StatusMinijardimController_1.StatusMinijardimController,
            nome: 'statusMinijardim',
            titulo: 'Status Minijardim',
            autenticar: true
        };
        this.rota.addListar(statusMinijardim);
        statusMinijardim.titulo = 'Visualizar Status Minijardim';
        this.rota.addVisualizar(statusMinijardim);
        statusMinijardim.titulo = 'Adicionar novo Status Minijardim';
        this.rota.addCriar(statusMinijardim);
        statusMinijardim.titulo = 'Atualizar Status Minijardim';
        this.rota.addEditar(statusMinijardim);
        const finalidadeMultiplicacao = {
            controladora: FinalidadeMultiplicacaoController_1.FinalidadeMultiplicacaoController,
            nome: 'finalidadeMultiplicacao',
            titulo: 'Finalidade Multiplicação',
            autenticar: true
        };
        this.rota.addListar(finalidadeMultiplicacao);
        finalidadeMultiplicacao.titulo = 'Visualizar Finalidade Multiplicação';
        this.rota.addVisualizar(finalidadeMultiplicacao);
        finalidadeMultiplicacao.titulo = 'Adicionar nova Finalidade Multiplicação';
        this.rota.addCriar(finalidadeMultiplicacao);
        finalidadeMultiplicacao.titulo = 'Atualizar Finalidade Multiplicação';
        this.rota.addEditar(finalidadeMultiplicacao);
        const acaoMinijardim = {
            controladora: AcaoMinijardimController_1.AcaoMinijardimController,
            nome: 'acaoMinijardim',
            titulo: 'Ação Minijardim',
            autenticar: true
        };
        this.rota.addListar(acaoMinijardim);
        acaoMinijardim.titulo = 'Visualizar Ação Minijardim';
        this.rota.addVisualizar(acaoMinijardim);
        acaoMinijardim.titulo = 'Adicionar nova Ação Minijardim';
        this.rota.addCriar(acaoMinijardim);
        acaoMinijardim.titulo = 'Atualizar Ação Minijardim';
        this.rota.addEditar(acaoMinijardim);
        const canaletoes = {
            controladora: CanaletoesController_1.CanaletoesController,
            nome: 'canaletoes',
            titulo: 'Canaletões',
            autenticar: true
        };
        this.rota.addListar(canaletoes);
        canaletoes.titulo = 'Visualizar Canaletões';
        this.rota.addVisualizar(canaletoes);
        canaletoes.titulo = 'Adicionar novo Canaletões';
        this.rota.addCriar(canaletoes);
        canaletoes.titulo = 'Atualizar Canaletões';
        this.rota.addEditar(canaletoes);
        const minijardim = {
            controladora: MinijardimController_1.MinijardimController,
            nome: 'minijardim',
            titulo: 'Minijardim',
            autenticar: true
        };
        this.rota.addListar(minijardim);
        minijardim.titulo = 'Visualizar Minijardim';
        this.rota.addVisualizar(minijardim);
        minijardim.titulo = 'Adicionar novo Minijardim';
        this.rota.addCriar(minijardim);
        minijardim.titulo = 'Atualizar Minijardim';
        this.rota.addEditar(minijardim);
        const tipoMinijardim = {
            controladora: TipoMinijardimController_1.TipoMinijardimController,
            nome: 'tipoMinijardim',
            titulo: 'Tipo de Minijardim',
            autenticar: true
        };
        this.rota.addListar(tipoMinijardim);
        tipoMinijardim.titulo = 'Visualizar Tipo de Minijardim';
        this.rota.addVisualizar(tipoMinijardim);
        tipoMinijardim.titulo = 'Adicionar novo Tipo de Minijardim';
        this.rota.addCriar(tipoMinijardim);
        tipoMinijardim.titulo = 'Atualizar Tipo de Minijardim';
        this.rota.addEditar(tipoMinijardim);
        const caixaDAgua = {
            controladora: CaixaDAguaController_1.CaixaDAguaController,
            nome: 'caixaDAgua',
            titulo: 'Caixa D’Agua',
            autenticar: true
        };
        this.rota.addListar(caixaDAgua);
        caixaDAgua.titulo = 'Visualizar Caixa D’Agua';
        this.rota.addVisualizar(caixaDAgua);
        caixaDAgua.titulo = 'Adicionar nova Caixa D’Agua';
        this.rota.addCriar(caixaDAgua);
        caixaDAgua.titulo = 'Atualizar Caixa D’Agua';
        this.rota.addEditar(caixaDAgua);
        const receitaSolucao = {
            controladora: ReceitaSolucaoController_1.ReceitaSolucaoController,
            nome: 'receitaSolucao',
            titulo: 'Receita Solução',
            autenticar: true
        };
        this.rota.addListar(receitaSolucao);
        receitaSolucao.titulo = 'Visualizar Receita Solução';
        this.rota.addVisualizar(receitaSolucao);
        receitaSolucao.titulo = 'Adicionar nova Receita Solução';
        this.rota.addCriar(receitaSolucao);
        receitaSolucao.titulo = 'Atualizar Receita Solução';
        this.rota.addEditar(receitaSolucao);
        const nivelContaminacao = {
            controladora: NivelContaminacaoController_1.NivelContaminacaoController,
            nome: 'nivelContaminacao',
            titulo: 'Nível de Contaminação',
            autenticar: true
        };
        this.rota.addListar(nivelContaminacao);
        nivelContaminacao.titulo = 'Visualizar Nível de Contaminação';
        this.rota.addVisualizar(nivelContaminacao);
        nivelContaminacao.titulo = 'Adicionar novo Nível de Contaminação';
        this.rota.addCriar(nivelContaminacao);
        nivelContaminacao.titulo = 'Atualizar Nível de Contaminação';
        this.rota.addEditar(nivelContaminacao);
        const casaVegetacao = {
            controladora: CasaVegetacaoController_1.CasaVegetacaoController,
            nome: 'casaVegetacao',
            titulo: 'Casa de Vegetação',
            autenticar: true
        };
        this.rota.addListar(casaVegetacao);
        casaVegetacao.titulo = 'Visualizar Casa de Vegetação';
        this.rota.addVisualizar(casaVegetacao);
        casaVegetacao.titulo = 'Adicionar nova Casa de Vegetação';
        this.rota.addCriar(casaVegetacao);
        casaVegetacao.titulo = 'Atualizar Casa de Vegetação';
        this.rota.addEditar(casaVegetacao);
        const cargoFuncao = {
            controladora: CargoFuncaoController_1.CargoFuncaoController,
            nome: 'cargoFuncao',
            titulo: 'Cargo\\Função',
            autenticar: true
        };
        this.rota.addListar(cargoFuncao);
        cargoFuncao.titulo = 'Visualizar Cargo\\Função';
        this.rota.addVisualizar(cargoFuncao);
        cargoFuncao.titulo = 'Adicionar novo Cargo\\Função';
        this.rota.addCriar(cargoFuncao);
        cargoFuncao.titulo = 'Atualizar Cargo\\Função';
        this.rota.addEditar(cargoFuncao);
        const laboratorio = {
            controladora: LaboratorioController_1.LaboratorioController,
            nome: 'laboratorio',
            titulo: 'Laboratório',
            autenticar: true
        };
        this.rota.addListar(laboratorio);
        laboratorio.titulo = 'Visualizar Laboratório';
        this.rota.addVisualizar(laboratorio);
        laboratorio.titulo = 'Adicionar novo Laboratório';
        this.rota.addCriar(laboratorio);
        laboratorio.titulo = 'Atualizar Laboratório';
        this.rota.addEditar(laboratorio);
        const labSolicitacaoAnalises = {
            controladora: LabSolicitacaoAnalisesController_1.LabSolicitacaoAnalisesController,
            nome: 'labSolicitacaoAnalises',
            titulo: 'Solicitação de Análises',
            autenticar: true
        };
        this.rota.addListar(labSolicitacaoAnalises);
        labSolicitacaoAnalises.titulo = 'Visualizar Solicitações de Análises';
        this.rota.addVisualizar(labSolicitacaoAnalises);
        labSolicitacaoAnalises.titulo = 'Adicionar nova Solicitação de Análise';
        this.rota.addCriar(labSolicitacaoAnalises);
        labSolicitacaoAnalises.titulo = 'Atualizar Solicitação de Análise';
        this.rota.addEditar(labSolicitacaoAnalises);
        const labMaturacaoCones = {
            controladora: LabMaturacaoConesController_1.LabMaturacaoConesController,
            nome: 'labMaturacaoCones',
            titulo: 'Maturação de Cones',
            autenticar: true,
            template: require('../../modulos/cadastro/paginas/labMaturacaoCones/lista.html')
        };
        this.rota.addListar(labMaturacaoCones);
        labMaturacaoCones.titulo = 'Visualizar Maturação de Cones';
        labMaturacaoCones.template = null;
        this.rota.addVisualizar(labMaturacaoCones);
        labMaturacaoCones.titulo = 'Adicionar nova Maturação de Cones';
        labMaturacaoCones.template = null;
        this.rota.addCriar(labMaturacaoCones);
        labMaturacaoCones.titulo = 'Atualizar Maturação de Cones';
        labMaturacaoCones.template = null;
        this.rota.addEditar(labMaturacaoCones);
        const labUmidadeSementes = {
            controladora: LabUmidadeSementesController_1.LabUmidadeSementesController,
            nome: 'labUmidadeSementes',
            titulo: 'Umidade de Sementes',
            autenticar: true
        };
        this.rota.addListar(labUmidadeSementes);
        labUmidadeSementes.titulo = 'Visualizar Umidade de Sementes';
        this.rota.addVisualizar(labUmidadeSementes);
        labUmidadeSementes.titulo = 'Adicionar nova Umidade de Sementes';
        this.rota.addCriar(labUmidadeSementes);
        labUmidadeSementes.titulo = 'Atualizar Umidade de Sementes';
        this.rota.addEditar(labUmidadeSementes);
        const labUmidadePolen = {
            controladora: labUmidadePolenController_1.LabUmidadePolenController,
            nome: 'labUmidadePolen',
            titulo: 'Umidade de Polen',
            autenticar: true
        };
        this.rota.addListar(labUmidadePolen);
        labUmidadePolen.titulo = 'Visualizar Umidade de Polen';
        this.rota.addVisualizar(labUmidadePolen);
        labUmidadePolen.titulo = 'Adicionar nova Umidade de Polen';
        this.rota.addCriar(labUmidadePolen);
        labUmidadePolen.titulo = 'Atualizar Umidade de Polen';
        this.rota.addEditar(labUmidadePolen);
        const labGerminacaoPolen = {
            controladora: LabGerminacaoPolenController_1.LabGerminacaoPolenController,
            nome: 'labGerminacaoPolen',
            titulo: 'Germinação de Polen',
            autenticar: true
        };
        this.rota.addListar(labGerminacaoPolen);
        labGerminacaoPolen.titulo = 'Visualizar Germinação de Polen';
        this.rota.addVisualizar(labGerminacaoPolen);
        labGerminacaoPolen.titulo = 'Adicionar nova Germinação de Polen';
        this.rota.addCriar(labGerminacaoPolen);
        labGerminacaoPolen.titulo = 'Atualizar Germinação de Polen';
        this.rota.addEditar(labGerminacaoPolen);
        const labGerminacaoSementes = {
            controladora: LabGerminacaoSementesController_1.LabGerminacaoSementesController,
            nome: 'labGerminacaoSementes',
            titulo: 'Germinação de Sementes',
            autenticar: true,
            template: require('../../modulos/cadastro/paginas/labGerminacaoSementes/lista.html')
        };
        this.rota.addListar(labGerminacaoSementes);
        labGerminacaoSementes.titulo = 'Visualizar Germinação de Sementes';
        labGerminacaoSementes.template = null;
        this.rota.addVisualizar(labGerminacaoSementes);
        labGerminacaoSementes.titulo = 'Adicionar nova Germinação de Sementes';
        labGerminacaoSementes.template = null;
        this.rota.addCriar(labGerminacaoSementes);
        labGerminacaoSementes.titulo = 'Atualizar Germinação de Sementes';
        labGerminacaoSementes.template = null;
        this.rota.addEditar(labGerminacaoSementes);
        const labEstudoCones = {
            controladora: LabEstudoConesController_1.LabEstudoConesController,
            nome: 'labEstudoCones',
            titulo: 'Estudo de Cones',
            autenticar: true
        };
        this.rota.addListar(labEstudoCones);
        labEstudoCones.titulo = 'Visualizar Estudo de Cones';
        this.rota.addVisualizar(labEstudoCones);
        labEstudoCones.titulo = 'Adicionar novo Estudo de Cones';
        this.rota.addCriar(labEstudoCones);
        labEstudoCones.titulo = 'Atualizar Estudo de Cones';
        this.rota.addEditar(labEstudoCones);
        const labExperimentos = {
            controladora: LabExperimentosController_1.LabExperimentosController,
            nome: 'labExperimentos',
            titulo: 'Experimentos',
            autenticar: true
        };
        this.rota.addListar(labExperimentos);
        labExperimentos.titulo = 'Visualizar Experimento';
        this.rota.addVisualizar(labExperimentos);
        labExperimentos.titulo = 'Adicionar novo Experimento';
        this.rota.addCriar(labExperimentos);
        labExperimentos.titulo = 'Atualizar Experimento';
        this.rota.addEditar(labExperimentos);
        const labManuaisProcedimentos = {
            controladora: LabManuaisProcedimentosController_1.LabManuaisProcedimentosController,
            nome: 'labManuaisProcedimentos',
            titulo: 'Manuais e Procedimentos',
            autenticar: true
        };
        this.rota.addListar(labManuaisProcedimentos);
        labManuaisProcedimentos.titulo = 'Visualizar Manuais e Procedimentos';
        this.rota.addVisualizar(labManuaisProcedimentos);
        labManuaisProcedimentos.titulo = 'Adicionar novo Manual e Procedimento';
        this.rota.addCriar(labManuaisProcedimentos);
        labManuaisProcedimentos.titulo = 'Atualizar Manual e Procedimento';
        this.rota.addEditar(labManuaisProcedimentos);
        const producaoMudasSeminal = {
            controladora: ProducaoMudasSeminalController_1.ProducaoMudasSeminalController,
            nome: 'producaoMudasSeminal',
            titulo: 'Produção de Mudas - Seminal',
            autenticar: true,
            template: require('../../modulos/cadastro/paginas/producaoMudasSeminal/lista.html')
        };
        this.rota.addListar(producaoMudasSeminal);
        producaoMudasSeminal.titulo = 'Visualizar Seminal';
        producaoMudasSeminal.template = null;
        this.rota.addVisualizar(producaoMudasSeminal);
        producaoMudasSeminal.titulo = 'Adicionar novo Seminal';
        producaoMudasSeminal.template = null;
        this.rota.addCriar(producaoMudasSeminal);
        producaoMudasSeminal.titulo = 'Atualizar Seminal';
        producaoMudasSeminal.template = null;
        this.rota.addEditar(producaoMudasSeminal);
        const producaoMudasClonal = {
            controladora: ProducaoMudaClonalController_1.ProducaoMudaClonalController,
            nome: 'producaoMudaClonal',
            titulo: 'Produção de Mudas - Clonal',
            autenticar: true,
        };
        this.rota.addListar(producaoMudasClonal);
        producaoMudasClonal.titulo = 'Visualizar Clonal';
        this.rota.addVisualizar(producaoMudasClonal);
        producaoMudasClonal.titulo = 'Adicionar novo Clonal';
        this.rota.addCriar(producaoMudasClonal);
        producaoMudasClonal.titulo = 'Atualizar Clonal';
        this.rota.addEditar(producaoMudasClonal);
        const producaoMudasClonalEucalyptus = {
            controladora: ProducaoMudaClonalEucalyptusController_1.ProducaoMudaClonalEucalyptusController,
            nome: 'producaoMudaClonalEucalyptus',
            titulo: 'Produção de Mudas - Clonal Eucalyptus',
            autenticar: true,
        };
        this.rota.addListar(producaoMudasClonalEucalyptus);
        producaoMudasClonalEucalyptus.titulo = 'Visualizar Clonal Eucalyptus';
        this.rota.addVisualizar(producaoMudasClonalEucalyptus);
        producaoMudasClonalEucalyptus.titulo = 'Adicionar novo Clonal Eucalyptus';
        this.rota.addCriar(producaoMudasClonalEucalyptus);
        producaoMudasClonalEucalyptus.titulo = 'Atualizar Clonal Eucalyptus';
        this.rota.addEditar(producaoMudasClonalEucalyptus);
        const producaoMudaClonalEucalyptusResgateMacroestacas = {
            controladora: ProducaoMudaClonalEucalyptusResgateMacroestacasController_1.ProducaoMudaClonalEucalyptusResgateMacroestacasController,
            nome: 'producaoMudaClonalEucalyptusResgateMacroestacas',
            titulo: 'Produção de Mudas Eucalyptus - Resgate Macroestacas',
            autenticar: true,
            template: require('../../modulos/cadastro/paginas/producaoMudaClonalEucalyptusResgateMacroestacas/lista.html')
        };
        this.rota.addListar(producaoMudaClonalEucalyptusResgateMacroestacas);
        producaoMudaClonalEucalyptusResgateMacroestacas.titulo = 'Visualizar Resgate Macroestacas';
        producaoMudaClonalEucalyptusResgateMacroestacas.template = null;
        this.rota.addVisualizar(producaoMudaClonalEucalyptusResgateMacroestacas);
        producaoMudaClonalEucalyptusResgateMacroestacas.titulo = 'Adicionar novo Resgate Macroestacas';
        producaoMudaClonalEucalyptusResgateMacroestacas.template = null;
        this.rota.addCriar(producaoMudaClonalEucalyptusResgateMacroestacas);
        producaoMudaClonalEucalyptusResgateMacroestacas.titulo = 'Atualizar Resgate Macroestacas';
        producaoMudaClonalEucalyptusResgateMacroestacas.template = null;
        this.rota.addEditar(producaoMudaClonalEucalyptusResgateMacroestacas);
        const producaoMudaClonalEucalyptusResgateMinijardim = {
            controladora: ProducaoMudaClonalEucalyptusMinijardimController_1.ProducaoMudaClonalEucalyptusMinijardimController,
            nome: 'producaoMudaClonalEucalyptusMinijardim',
            titulo: 'Produção de Mudas Eucalyptus - Minijardim',
            autenticar: true,
            template: require('../../modulos/cadastro/paginas/producaoMudaClonalEucalyptusMinijardim/lista.html')
        };
        this.rota.addListar(producaoMudaClonalEucalyptusResgateMinijardim);
        producaoMudaClonalEucalyptusResgateMinijardim.titulo = 'Visualizar Resgate Minijardim';
        producaoMudaClonalEucalyptusResgateMinijardim.template = null;
        this.rota.addVisualizar(producaoMudaClonalEucalyptusResgateMinijardim);
        producaoMudaClonalEucalyptusResgateMinijardim.titulo = 'Adicionar novo Resgate Minijardim';
        producaoMudaClonalEucalyptusResgateMinijardim.template = null;
        this.rota.addCriar(producaoMudaClonalEucalyptusResgateMinijardim);
        producaoMudaClonalEucalyptusResgateMinijardim.titulo = 'Atualizar Resgate Minijardim';
        producaoMudaClonalEucalyptusResgateMinijardim.template = null;
        this.rota.addEditar(producaoMudaClonalEucalyptusResgateMinijardim);
        const producaoMudaClonalEucalyptusMiniestaquia = {
            controladora: ProducaoMudaClonalEucalyptusMiniestaquiaController_1.ProducaoMudaClonalEucalyptusMiniestaquiaController,
            nome: 'producaoMudaClonalEucalyptusMiniestaquia',
            titulo: 'Produção de Mudas Eucalyptus - Miniestaquia',
            autenticar: true,
            template: require('../../modulos/cadastro/paginas/producaoMudaClonalEucalyptusMiniestaquia/lista.html')
        };
        this.rota.addListar(producaoMudaClonalEucalyptusMiniestaquia);
        producaoMudaClonalEucalyptusMiniestaquia.titulo = 'Visualizar Miniestaquia';
        producaoMudaClonalEucalyptusMiniestaquia.template = null;
        this.rota.addVisualizar(producaoMudaClonalEucalyptusMiniestaquia);
        producaoMudaClonalEucalyptusMiniestaquia.titulo = 'Adicionar nova Miniestaquia';
        producaoMudaClonalEucalyptusMiniestaquia.template = null;
        this.rota.addCriar(producaoMudaClonalEucalyptusMiniestaquia);
        producaoMudaClonalEucalyptusMiniestaquia.titulo = 'Atualizar Miniestaquia';
        producaoMudaClonalEucalyptusMiniestaquia.template = null;
        this.rota.addEditar(producaoMudaClonalEucalyptusMiniestaquia);
        const producaoMudaClonalPinus = {
            controladora: ProducaoMudaClonalPinusController_1.ProducaoMudaClonalPinusController,
            nome: 'producaoMudaClonalPinus',
            titulo: 'Produção de Mudas Pinus',
            autenticar: true
        };
        this.rota.addListar(producaoMudaClonalPinus);
        producaoMudaClonalPinus.titulo = 'Visualizar Resgate Minijardim';
        this.rota.addVisualizar(producaoMudaClonalPinus);
        producaoMudaClonalPinus.titulo = 'Adicionar novo Resgate Minijardim';
        this.rota.addCriar(producaoMudaClonalPinus);
        producaoMudaClonalPinus.titulo = 'Atualizar Resgate Minijardim';
        this.rota.addEditar(producaoMudaClonalPinus);
        const producaoMudaClonalPinusMinijardim = {
            controladora: ProducaoMudaClonalPinusMinijardimController_1.ProducaoMudaClonalPinusMinijardimController,
            nome: 'producaoMudaClonalPinusMinijardim',
            titulo: 'Produção de Mudas Pinus - Minijardim',
            autenticar: true,
            template: require('../../modulos/cadastro/paginas/producaoMudaClonalPinusMinijardim/lista.html')
        };
        this.rota.addListar(producaoMudaClonalPinusMinijardim);
        producaoMudaClonalPinusMinijardim.titulo = 'Visualizar Resgate Minijardim';
        producaoMudaClonalPinusMinijardim.template = null;
        this.rota.addVisualizar(producaoMudaClonalPinusMinijardim);
        producaoMudaClonalPinusMinijardim.titulo = 'Adicionar novo Resgate Minijardim';
        producaoMudaClonalPinusMinijardim.template = null;
        this.rota.addCriar(producaoMudaClonalPinusMinijardim);
        producaoMudaClonalPinusMinijardim.titulo = 'Atualizar Resgate Minijardim';
        producaoMudaClonalPinusMinijardim.template = null;
        this.rota.addEditar(producaoMudaClonalPinusMinijardim);
        const producaoMudaClonalPinusMiniestaquia = {
            controladora: ProducaoMudaClonalPinusMiniestaquiaController_1.ProducaoMudaClonalPinusMiniestaquiaController,
            nome: 'producaoMudaClonalPinusMiniestaquia',
            titulo: 'Produção de Mudas Pinus - Miniestaquia',
            autenticar: true,
            template: require('../../modulos/cadastro/paginas/producaoMudaClonalPinusMiniestaquia/lista.html')
        };
        this.rota.addListar(producaoMudaClonalPinusMiniestaquia);
        producaoMudaClonalPinusMiniestaquia.titulo = 'Visualizar Miniestaquia';
        producaoMudaClonalPinusMiniestaquia.template = null;
        this.rota.addVisualizar(producaoMudaClonalPinusMiniestaquia);
        producaoMudaClonalPinusMiniestaquia.titulo = 'Adicionar nova Miniestaquia';
        producaoMudaClonalPinusMiniestaquia.template = null;
        this.rota.addCriar(producaoMudaClonalPinusMiniestaquia);
        producaoMudaClonalPinusMiniestaquia.titulo = 'Atualizar Miniestaquia';
        producaoMudaClonalPinusMiniestaquia.template = null;
        this.rota.addEditar(producaoMudaClonalPinusMiniestaquia);
        const producaoMudaControlePragaDoenca = {
            controladora: ProducaoMudaControlePragaDoencaController_1.ProducaoMudaControlePragaDoencaController,
            nome: 'producaoMudaControlePragaDoenca',
            titulo: 'Controle de Pragas e Doenças',
            autenticar: true
        };
        this.rota.addListar(producaoMudaControlePragaDoenca);
        producaoMudaControlePragaDoenca.titulo = 'Visualizar Controle de Pragas e Doenças';
        this.rota.addVisualizar(producaoMudaControlePragaDoenca);
        producaoMudaControlePragaDoenca.titulo = 'Adicionar novo Controle de Pragas e Doenças';
        this.rota.addCriar(producaoMudaControlePragaDoenca);
        producaoMudaControlePragaDoenca.titulo = 'Atualizar Controle de Pragas e Doenças';
        this.rota.addEditar(producaoMudaControlePragaDoenca);
        const producaoMudasExperimentos = {
            controladora: ProducaoMudasExperimentosController_1.ProducaoMudasExperimentosController,
            nome: 'producaoMudasExperimentos',
            titulo: 'Produção de Mudas - Experimentos',
            autenticar: true,
            template: require('../../modulos/cadastro/paginas/producaoMudasExperimentos/lista.html')
        };
        this.rota.addListar(producaoMudasExperimentos);
        producaoMudasExperimentos.titulo = 'Visualizar Experimento';
        producaoMudasExperimentos.template = null;
        this.rota.addVisualizar(producaoMudasExperimentos);
        producaoMudasExperimentos.titulo = 'Adicionar novo Experimento';
        producaoMudasExperimentos.template = null;
        this.rota.addCriar(producaoMudasExperimentos);
        producaoMudasExperimentos.titulo = 'Atualizar Experimento';
        producaoMudasExperimentos.template = null;
        this.rota.addEditar(producaoMudasExperimentos);
        const protecaoRegistroCultivar = {
            controladora: ProtecaoRegistroCultivarController_1.ProtecaoRegistroCultivarController,
            nome: 'protecaoRegistroCultivar',
            titulo: 'Proteção e Registro de Cultivares',
            autenticar: true,
            template: require('../../modulos/cadastro/paginas/protecaoRegistroCultivar/lista.html')
        };
        this.rota.addListar(protecaoRegistroCultivar);
        protecaoRegistroCultivar.titulo = 'Visualizar Proteção de Registro e Cultivares';
        protecaoRegistroCultivar.template = null;
        this.rota.addVisualizar(protecaoRegistroCultivar);
        protecaoRegistroCultivar.titulo = 'Adicionar nova Proteção de Registro e Cultivares';
        protecaoRegistroCultivar.template = null;
        this.rota.addCriar(protecaoRegistroCultivar);
        protecaoRegistroCultivar.titulo = 'Atualizar Proteção de Registro e Cultivares';
        protecaoRegistroCultivar.template = null;
        this.rota.addEditar(protecaoRegistroCultivar);
        const areaSolicitante = {
            controladora: AreaSolicitanteController_1.AreaSolicitanteController,
            nome: 'areaSolicitante',
            titulo: 'Área Solicitante',
            autenticar: true
        };
        this.rota.addListar(areaSolicitante);
        areaSolicitante.titulo = 'Visualizar Área Solicitante';
        this.rota.addVisualizar(areaSolicitante);
        areaSolicitante.titulo = 'Adicionar novo Área Solicitante';
        this.rota.addCriar(areaSolicitante);
        areaSolicitante.titulo = 'Atualizar Área Solicitante';
        this.rota.addEditar(areaSolicitante);
        const tipoAmostra = {
            controladora: TipoAmostraController_1.TipoAmostraController,
            nome: 'tipoAmostra',
            titulo: 'Tipo de Amostra',
            autenticar: true
        };
        this.rota.addListar(tipoAmostra);
        tipoAmostra.titulo = 'Visualizar Tipo de Amostra';
        this.rota.addVisualizar(tipoAmostra);
        tipoAmostra.titulo = 'Adicionar novo Tipo de Amostra';
        this.rota.addCriar(tipoAmostra);
        tipoAmostra.titulo = 'Atualizar Tipo de Amostra';
        this.rota.addEditar(tipoAmostra);
        const tipoAnalise = {
            controladora: TipoAnaliseController_1.TipoAnaliseController,
            nome: 'tipoAnalise',
            titulo: 'Tipo de Análise',
            autenticar: true
        };
        this.rota.addListar(tipoAnalise);
        tipoAnalise.titulo = 'Visualizar Tipo de Análise';
        this.rota.addVisualizar(tipoAnalise);
        tipoAnalise.titulo = 'Adicionar novo Tipo de Análise';
        this.rota.addCriar(tipoAnalise);
        tipoAnalise.titulo = 'Atualizar Tipo de Análise';
        this.rota.addEditar(tipoAnalise);
        const statusAnalise = {
            controladora: StatusAnaliseController_1.StatusAnaliseController,
            nome: 'statusAnalise',
            titulo: 'Status Análise',
            autenticar: true
        };
        this.rota.addListar(statusAnalise);
        statusAnalise.titulo = 'Visualizar Status Análise';
        this.rota.addVisualizar(statusAnalise);
        statusAnalise.titulo = 'Adicionar novo Status Análise';
        this.rota.addCriar(statusAnalise);
        statusAnalise.titulo = 'Atualizar Status Análise';
        this.rota.addEditar(statusAnalise);
        const safra = {
            controladora: SafraController_1.SafraController,
            nome: 'safra',
            titulo: 'Safra',
            autenticar: true
        };
        this.rota.addListar(safra);
        safra.titulo = 'Visualizar Safra';
        this.rota.addVisualizar(safra);
        safra.titulo = 'Adicionar nova Safra';
        this.rota.addCriar(safra);
        safra.titulo = 'Atualizar Safra';
        this.rota.addEditar(safra);
        const lote = {
            controladora: LoteController_1.LoteController,
            nome: 'lote',
            titulo: 'Lote',
            autenticar: true
        };
        this.rota.addListar(lote);
        lote.titulo = 'Visualizar Lote';
        this.rota.addVisualizar(lote);
        lote.titulo = 'Adicionar novo Lote';
        this.rota.addCriar(lote);
        lote.titulo = 'Atualizar Lote';
        this.rota.addEditar(lote);
        const propagulo = {
            controladora: PropaguloController_1.PropaguloController,
            nome: 'propagulo',
            titulo: 'Propágulo',
            autenticar: true
        };
        this.rota.addListar(propagulo);
        propagulo.titulo = 'Visualizar Propágulo';
        this.rota.addVisualizar(propagulo);
        propagulo.titulo = 'Adicionar novo Propágulo';
        this.rota.addCriar(propagulo);
        propagulo.titulo = 'Atualizar Propágulo';
        this.rota.addEditar(propagulo);
        const caracteristicaDescritorMorfologico = {
            controladora: CaracteristicaDescritorMorfologicoController_1.CaracteristicaDescritorMorfologicoController,
            nome: 'caracteristicaDescritorMorfologico',
            titulo: 'Característica de Descritor Morfológico',
            autenticar: true
        };
        this.rota.addListar(caracteristicaDescritorMorfologico);
        caracteristicaDescritorMorfologico.titulo = 'Visualizar Característica de Descritor Morfológico';
        this.rota.addVisualizar(caracteristicaDescritorMorfologico);
        caracteristicaDescritorMorfologico.titulo = 'Adicionar nova Característica de Descritor Morfológico';
        this.rota.addCriar(caracteristicaDescritorMorfologico);
        caracteristicaDescritorMorfologico.titulo = 'Atualizar Característica de Descritor Morfológico';
        this.rota.addEditar(caracteristicaDescritorMorfologico);
        const centroCustoSap = {
            controladora: CentroCustoSapController_1.CentroCustoSapController,
            nome: 'centroCustoSap',
            titulo: 'Centro de Custo SAP',
            autenticar: true
        };
        this.rota.addListar(centroCustoSap);
        centroCustoSap.titulo = 'Visualizar Centro de Custo SAP';
        this.rota.addVisualizar(centroCustoSap);
        centroCustoSap.titulo = 'Adicionar novo Centro de Custo SAP';
        this.rota.addCriar(centroCustoSap);
        centroCustoSap.titulo = 'Atualizar Centro de Custo SAP';
        this.rota.addEditar(centroCustoSap);
        const contaDepositoSap = {
            controladora: ContaDepositoSapController_1.ContaDepositoSapController,
            nome: 'contaDepositoSap',
            titulo: 'Conta/Depósito SAP',
            autenticar: true
        };
        this.rota.addListar(contaDepositoSap);
        contaDepositoSap.titulo = 'Visualizar Conta/Depósito SAP';
        this.rota.addVisualizar(contaDepositoSap);
        contaDepositoSap.titulo = 'Adicionar nova Conta/Depósito SAP';
        this.rota.addCriar(contaDepositoSap);
        contaDepositoSap.titulo = 'Atualizar Conta/Depósito SAP';
        this.rota.addEditar(contaDepositoSap);
        const coletaMacroestaca = {
            controladora: ColetaMacroestacaController_1.ColetaMacroestacaController,
            nome: 'coletaMacroestaca',
            titulo: 'Coleta Macroestaca',
            autenticar: true
        };
        this.rota.addListar(coletaMacroestaca);
        coletaMacroestaca.titulo = 'Visualizar Coleta Macroestaca';
        this.rota.addVisualizar(coletaMacroestaca);
        coletaMacroestaca.titulo = 'Adicionar nova Coleta Macroestaca';
        this.rota.addCriar(coletaMacroestaca);
        coletaMacroestaca.titulo = 'Atualizar Coleta Macroestaca';
        this.rota.addEditar(coletaMacroestaca);
        const produtoDefensivo = {
            controladora: ProdutoDefensivoController_1.ProdutoDefensivoController,
            nome: 'produtoDefensivo',
            titulo: 'Produto Defensivo',
            autenticar: true
        };
        this.rota.addListar(produtoDefensivo);
        produtoDefensivo.titulo = 'Visualizar Produto Defensivo';
        this.rota.addVisualizar(produtoDefensivo);
        produtoDefensivo.titulo = 'Adicionar novo Produto Defensivo';
        this.rota.addCriar(produtoDefensivo);
        produtoDefensivo.titulo = 'Atualizar Produto Defensivo';
        this.rota.addEditar(produtoDefensivo);
        const classificacaoBrotacao = {
            controladora: ClassificacaoBrotacaoController_1.ClassificacaoBrotacaoController,
            nome: 'classificacaoBrotacao',
            titulo: 'Classificação de Brotação',
            autenticar: true
        };
        this.rota.addListar(classificacaoBrotacao);
        classificacaoBrotacao.titulo = 'Visualizar Classificação de Brotação';
        this.rota.addVisualizar(classificacaoBrotacao);
        classificacaoBrotacao.titulo = 'Adicionar nova Classificação de Brotação';
        this.rota.addCriar(classificacaoBrotacao);
        classificacaoBrotacao.titulo = 'Atualizar Classificação de Brotação';
        this.rota.addEditar(classificacaoBrotacao);
        const usuario = {
            controladora: UsuarioController_1.UsuarioController,
            nome: 'usuario',
            titulo: 'Usuários',
            autenticar: true
        };
        this.rota.addListar(usuario);
        usuario.titulo = 'Visualizar Usuário';
        this.rota.addVisualizar(usuario);
        usuario.titulo = 'Adicionar novo Usuário';
        this.rota.addCriar(usuario);
        usuario.titulo = 'Atualizar Usuário';
        this.rota.addEditar(usuario);
    }
}
exports.CadastroRota = CadastroRota;
CadastroRota.$inject = ['$stateProvider'];
