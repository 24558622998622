"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SincroniaConstantes = void 0;
class SincroniaConstantes {
}
exports.SincroniaConstantes = SincroniaConstantes;
SincroniaConstantes.IDENTIFICACAO = 'identificacao';
SincroniaConstantes.MATERIAL_GENETICO = 'material_genetico';
SincroniaConstantes.GENERO = 'genero';
SincroniaConstantes.ESPECIE = 'especie';
SincroniaConstantes.GERACAO = 'geracao';
SincroniaConstantes.CONJUNTO = 'conjunto';
SincroniaConstantes.DIALELO = 'dialelo';
SincroniaConstantes.POMAR = 'pomar';
SincroniaConstantes.TIPO_POMAR = 'tipo_pomar';
SincroniaConstantes.CLASSIFICACAO_SOLO = 'classificacao_solo';
SincroniaConstantes.UNIDADE_MANEJO = 'unidade_manejo';
SincroniaConstantes.REGIAO = 'regiao';
SincroniaConstantes.TALHAO = 'talhao';
SincroniaConstantes.IMPORTA_TALHAO = 'importa_talhao';
SincroniaConstantes.IMPORTA_SHAPEFILE = 'importa_shapefile';
SincroniaConstantes.FONTE = 'fonte';
SincroniaConstantes.TIPO_MANEJO = 'tipo_manejo';
SincroniaConstantes.TIPO_DESBASTE_MANEJO_POMAR = 'tipo_desbaste_manejo_pomar';
SincroniaConstantes.TIPO_LIMPEZA_MANEJO_POMAR = 'tipo_limpeza_manejo_pomar';
SincroniaConstantes.TIPO_AMOSTRA_MANEJO_POMAR = 'tipo_amostra_manejo_pomar';
SincroniaConstantes.FINALIDADE_AMOSTRA_MANEJO_POMAR = 'finalidade_amostra_manejo_pomar';
SincroniaConstantes.PRAGA_DOENCA_MANEJO_POMAR = 'praga_doenca_manejo_pomar';
SincroniaConstantes.FUNCIONARIO = 'funcionario';
SincroniaConstantes.CLIENTE = 'cliente';
SincroniaConstantes.PAIS = 'pais';
SincroniaConstantes.PROVINCIA_UF = 'provincia_uf';
SincroniaConstantes.LOCALIDADE = 'localidade';
SincroniaConstantes.HIBRIDACAO = 'hibridacao';
SincroniaConstantes.POLINIZACAO = 'polinizacao';
SincroniaConstantes.TIPO_TESTE_POPULACAO = 'tipo_teste_populacao';
SincroniaConstantes.SITUACAO = 'situacao';
SincroniaConstantes.CLONE_MATERIAL_GENETICO = 'clone_material_genetico';
SincroniaConstantes.TIPO_IMPLANTACAO = 'tipo_implantacao';
SincroniaConstantes.PROCEDENCIA = 'procedencia';
SincroniaConstantes.REPRODUCAO = 'reproducao';
SincroniaConstantes.TIPO_POMAR_POPULACAO = 'tipo_pomar_populacao';
SincroniaConstantes.TIPO_DELINEAMENTO = 'tipo_delineamento';
SincroniaConstantes.DISPOSICAO_INDIVIDUOS = 'disposicao_individuos';
SincroniaConstantes.CLASSIFICACAO_CLIMATICA = 'classificacao_climatica';
SincroniaConstantes.OPERACAO_MANEJO_POMAR = 'operacao_manejo_pomar';
SincroniaConstantes.PRODUTO_FERTILIZANTE = 'produto_fertilizante';
SincroniaConstantes.HISTORICO_ESTOQUE_PRODUTO_FERTILIZANTE = 'historico_estoque_produto_fertilizante';
SincroniaConstantes.TIPO_APLICACAO = 'tipo_aplicacao';
SincroniaConstantes.TIPO_INTERVENCAO = 'tipo_intervencao';
SincroniaConstantes.TESTE_GENETICO = 'teste_geneticos';
SincroniaConstantes.ANO_ENXERTIA = 'ano_enxertia';
SincroniaConstantes.ESTROBILO = 'estrobilo';
SincroniaConstantes.ESTAGIO_FLORAL_FEMININO = 'estagio_floral_feminino';
SincroniaConstantes.ESTAGIO_FLORAL_MASCULINO = 'estagio_floral_masculino';
SincroniaConstantes.FASE_AVALIADA = 'fase_avaliada';
SincroniaConstantes.ESTAGIO_FLORAL_EUCALIPTO = 'estagio_floral_eucalipto';
SincroniaConstantes.MATURACAO_FRUTO = 'maturacao_fruto';
SincroniaConstantes.CULTIVAR = 'cultivar';
SincroniaConstantes.CATEGORIA_POMAR = 'categoria_pomar';
SincroniaConstantes.FINALIDADE_SEMEIO = 'finalidade_semeio';
SincroniaConstantes.UNIDADE_MEDIDA = 'unidade_medida';
SincroniaConstantes.TRATAMENTO_FUNGICIDA = 'tratamento_fungicida';
SincroniaConstantes.STATUS_MINIJARDIM = 'status_minijardim';
SincroniaConstantes.FINALIDADE_MULTIPLICACAO = 'finalidade_multiplicacao';
SincroniaConstantes.ACAO_MINIJARDIM = 'acao_minijardim';
SincroniaConstantes.CANALETOES = 'canaletoes';
SincroniaConstantes.MINIJARDIM = 'minijardim';
SincroniaConstantes.TIPO_MINIJARDIM = 'tipo_minijardim';
SincroniaConstantes.CAIXA_DAGUA = 'caixa_dagua';
SincroniaConstantes.RECEITA_SOLUCAO = 'receita_solucao';
SincroniaConstantes.NIVEL_CONTAMINACAO = 'nivel_contaminacao';
SincroniaConstantes.CASA_VEGETACAO = 'casa_vegetacao';
SincroniaConstantes.CARGO_FUNCAO = 'cargo_funcao';
SincroniaConstantes.LABORATORIO = 'laboratorio';
SincroniaConstantes.AREA_SOLICITANTE = 'area_solicitante';
SincroniaConstantes.TIPO_AMOSTRA = 'tipo_amostra';
SincroniaConstantes.TIPO_ANALISE = 'tipo_analise';
SincroniaConstantes.STATUS_ANALISE = 'status_analise';
SincroniaConstantes.SAFRA = 'safra';
SincroniaConstantes.LOTE = 'lote';
SincroniaConstantes.PROPAGULO = 'propagulo';
SincroniaConstantes.NOTIFICACAO = 'notificacao';
SincroniaConstantes.COLETA_MACROESTACA = 'coleta_macroestaca';
SincroniaConstantes.CLASSIFICACAO_BROTACAO = 'classificacao_brotacao';
SincroniaConstantes.PRODUTO_DEFENSIVO = 'produto_defensivo';
SincroniaConstantes.HISTORICO_ESTOQUE_PRODUTO_DEFENSIVO = 'historico_estoque_produto_defensivo';
SincroniaConstantes.ESTAGIO_OBSERVACAO_POLINIZACAO_POMAR = 'estagio_observacao_polinizacao_pomar';
SincroniaConstantes.LAB_SOLICITACAO_ANALISES = 'lab_solicitacao_analises';
SincroniaConstantes.LAB_MATURACAO_CONES = 'lab_maturacao_cones';
SincroniaConstantes.LAB_UMIDADE_SEMENTES = 'lab_umidade_sementes';
SincroniaConstantes.LAB_GERMINACAO_SEMENTES = 'lab_germinacao_sementes';
SincroniaConstantes.LAB_UMIDADE_POLEN = 'lab_umidade_polen';
SincroniaConstantes.LAB_GERMINACAO_POLEN = 'lab_germinacao_polen';
SincroniaConstantes.LAB_ESTUDO_CONES = 'lab_estudo_cones';
SincroniaConstantes.LAB_EXPERIMENTOS = 'lab_experimentos';
SincroniaConstantes.LAB_MANUAIS_PROCEDIMENTOS = 'lab_manuais_procedimentos';
SincroniaConstantes.ANALISE_INTERNA_AVALIACAO = 'analise_interna_germinacao';
SincroniaConstantes.LAB_GERMINACAO_SEMENTES_EXTERNO = 'lab_germinacao_sementes_externo';
SincroniaConstantes.PRODUCAO_MUDAS_EXPERIMENTOS = 'producao_mudas_experimentos';
SincroniaConstantes.PRODUCAO_MUDAS_RECOMENDACOES = 'producao_mudas_recomendacoes';
SincroniaConstantes.PRODUCAO_MUDAS_SEMINAL = 'producao_mudas_seminal';
SincroniaConstantes.PRODUCAO_MUDAS_PLANEJAMENTO = 'producao_mudas_planejamento';
SincroniaConstantes.PRODUCAO_MUDAS_TESTE = 'producao_mudas_semeio_testes';
SincroniaConstantes.PRODUCAO_MUDAS_ENXERTIA = 'producao_mudas_semeio_enxertia';
SincroniaConstantes.PRODUCAO_MUDAS_META_PRODUCAO = 'producao_mudas_meta_producao';
SincroniaConstantes.PRODUCAO_MUDA_CLONAL = 'producao_muda_clonal';
SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS = 'producao_muda_clonal_eucalyptus';
SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS = 'producao_muda_clonal_eucalyptus_resgatemacroestacas';
SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CORTE_RASO = 'producao_muda_clonal_eucalyptus_resgatemacroestacas_corte_raso';
SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_BROTACAO = 'producao_muda_clonal_eucalyptus_resgatemacroestacas_brotacoes';
SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_ADUBACAO = 'producao_muda_clonal_eucalyptus_resgatemacroestacas_adubacao';
SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CONTROLE_PRAGAS = 'producao_muda_clonal_eucalyptus_resgatemacroestacas_controle_pragas';
SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CONFECCAO = 'producao_muda_clonal_eucalyptus_resgatemacroestacas_confeccao';
SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_AVALIACAO = 'producao_muda_clonal_eucalyptus_resgatemacroestacas_avaliacao';
SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM = 'producao_muda_clonal_eucalyptus_minijardim';
SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_CROQUI = 'producao_muda_clonal_eucalyptus_minijardim_croqui';
SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_CROQUI_ANEXO = 'producao_muda_clonal_eucalyptus_minijardim_croqui_anexo';
SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_PLANTIO_MACROESTACAS = 'producao_muda_clonal_eucalyptus_minijardim_plantio_macroestacas';
SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_PLANTIO_MINIESTACAS = 'producao_muda_clonal_eucalyptus_minijardim_plantio_miniestacas';
SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_AVALIACAO_SOBREVIVENCIA = 'producao_muda_clonal_eucalyptus_minijardim_avaliacao_sobrevivencia';
SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_ELIMINAR_MINIMACRO = 'producao_muda_clonal_eucalyptus_minijardim_eliminar_minimacro';
SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_MANEJO = 'producao_muda_clonal_eucalyptus_minijardim_manejo';
SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA = 'producao_muda_clonal_eucalyptus_miniestaquia';
SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_ENRAIZAMENTO = 'producao_muda_clonal_eucalyptus_miniestaquia_avaliacao_enraizamento';
SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_CASA_SOMBRA = 'producao_muda_clonal_eucalyptus_miniestaquia_avaliacao_sobrevivencia_casa_sombra';
SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_ACLIMATACAO = 'producao_muda_clonal_eucalyptus_miniestaquia_avaliacao_sobrevivencia_aclimatacao';
SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS = 'producao_muda_clonal_pinus';
SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIJARDIM = 'producao_muda_clonal_pinus_minijardim';
SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIJARDIM_CROQUI = 'producao_muda_clonal_pinus_minijardim_croqui';
SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIJARDIM_CROQUI_ANEXO = 'producao_muda_clonal_pinus_minijardim_croqui_anexo';
SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIJARDIM_PLANTIO_MINIESTACAS = 'producao_muda_clonal_pinus_minijardim_plantio_miniestacas';
SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIJARDIM_AVALIACAO_SOBREVIVENCIA = 'producao_muda_clonal_pinus_minijardim_avaliacao_sobrevivencia';
SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIJARDIM_ELIMINAR_MINIMACRO = 'producao_muda_clonal_pinus_minijardim_eliminar_minimacro';
SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIJARDIM_MANEJO = 'producao_muda_clonal_pinus_minijardim_manejo';
SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA = 'producao_muda_clonal_pinus_miniestaquia';
SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_ENRAIZAMENTO = 'producao_muda_clonal_pinus_miniestaquia_avaliacao_enraizamento';
SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_CASA_SOMBRA = 'producao_muda_clonal_pinus_miniestaquia_avaliacao_sobrevivencia_casa_sombra';
SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_ACLIMATACAO = 'producao_muda_clonal_pinus_miniestaquia_avaliacao_sobrevivencia_aclimatacao';
SincroniaConstantes.PRODUCAO_MUDA_CONTROLE_PRAGA_DOENCA = 'producao_muda_controle_praga_doenca';
SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR = 'protecao_registro_cultivar';
SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_DESCRITOR_MORFOLOGICO = 'protecao_registro_cultivar_descritor_morfologico';
SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_LAUDO_DNA = 'protecao_registro_cultivar_laudo_dna';
SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_ORIENTACOES_PROCEDIMENTOS = 'protecao_registro_cultivar_orientacoes_procedimentos';
SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_PROTECCAO = 'protecao_registro_cultivar_proteccao';
SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_REGISTRO = 'protecao_registro_cultivar_registro';
SincroniaConstantes.CARACTERISTICA_DESCRITOR_MORFOLOGICO = 'caracteristica_descritor_morfologico';
SincroniaConstantes.CENTRO_CUSTO_SAP = 'centro_custo_sap';
SincroniaConstantes.CONTA_DEPOSITO_SAP = 'conta_deposito_sap';
SincroniaConstantes.BANCO_MELHORAMENTO_POMAR = 'banco_melhoramento_pomar';
SincroniaConstantes.TRATAMENTO_POMAR = 'tratamento_pomar';
SincroniaConstantes.CROQUI_POMAR = 'croqui_pomar';
SincroniaConstantes.MAPA_POMAR = 'mapa_pomar';
SincroniaConstantes.PLANEJAMENTO_POMAR = 'planejamento_pomar';
SincroniaConstantes.CONTROLE_MANEJO_POMAR = 'controle_manejo_pomar';
SincroniaConstantes.CONTROLE_ENXERTIA_POMAR = 'controle_enxertia_pomar';
SincroniaConstantes.PRODUTIVIDADE_POMAR = 'produtividade_pomar';
SincroniaConstantes.FENOLOGIA_POMAR = 'fenologia_pomar';
SincroniaConstantes.GRAFICO_FENOLOGIA_POMAR = 'grafico_fenologia_pomar';
SincroniaConstantes.POLINIZACAO_POMAR = 'polinizacao_pomar';
SincroniaConstantes.ESTOQUE_POLEN_POLINIZACAO_POMAR = 'estoque_polen_polinizacao_pomar';
SincroniaConstantes.HISTORICO_ESTOQUE_POLEN_POLINIZACAO_POMAR = 'historico_estoque_polen_polinizacao_pomar';
SincroniaConstantes.ESTOQUE_BENEFICIAMENTO_PINUS_POLINIZACAO_POMAR = 'estoque_beneficiamento_pinus_polinizacao_pomar';
SincroniaConstantes.HISTORICO_ESTOQUE_BENEFICIAMENTO_PINUS_POLINIZACAO_POMAR = 'historico_estoque_beneficiamento_pinus_polinizacao_pomar';
SincroniaConstantes.ESTOQUE_BENEFICIAMENTO_EUCALYPTUS_POLINIZACAO_POMAR = 'estoque_beneficiamento_eucalyptus_polinizacao_pomar';
SincroniaConstantes.HISTORICO_ESTOQUE_BENEFICIAMENTO_EUCALYPTUS_POLINIZACAO_POMAR = 'historico_estoque_beneficiamento_eucalyptus_polinizacao_pomar';
SincroniaConstantes.ANEXO_POMAR = 'anexo_pomar';
SincroniaConstantes.TESTE_CAMPO = 'teste_campo';
SincroniaConstantes.TRATAMENTO_TESTE_GENETICO = 'tratamento_teste_genetico';
SincroniaConstantes.CROQUI_TESTE_GENETICO = 'croqui_teste_genetico';
SincroniaConstantes.MAPA_TESTE_GENETICO = 'mapa_teste_genetico';
SincroniaConstantes.CONTROLE_MANEJO_TESTE_GENETICO = 'controle_manejo_teste_genetico';
SincroniaConstantes.MEDICAO_DENDROMETRICA_TESTE_GENETICO = 'medicao_dendrometrica_teste_genetico';
SincroniaConstantes.LANCAMENTO_MEDICAO_DENDROMETRICA_TESTE_GENETICO = 'lancamento_medicao_dendrometrica_teste_genetico';
SincroniaConstantes.PLANEJAMENTO_MEDICAO_DENDROMETRICA_TESTE_GENETICO = 'planejamento_medicao_dendrometrica_teste_genetico';
SincroniaConstantes.PLANILHA_MEDICAO_DENDROMETRICA_TESTE_GENETICO = 'planilha_medicao_dendrometrica_teste_genetico';
SincroniaConstantes.SELEGEN_MEDICAO_DENDROMETRICA_TESTE_GENETICO = 'selegen_medicao_dendrometrica_teste_genetico';
SincroniaConstantes.QUALIDADE_MADEIRA_TESTE_GENETICO = 'qualidade_madeira_teste_genetico';
SincroniaConstantes.ANALISE_SELECAO_TESTE_GENETICO = 'analise_selecao_teste_genetico';
SincroniaConstantes.SUMARIO_ANALISE_SELECAO_TESTE_GENETICO = 'sumario_analise_selecao_teste_genetico';
SincroniaConstantes.PLANILHA_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO = 'planilha_selegen_analise_selecao_teste_genetico';
SincroniaConstantes.ANALISE_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO = 'analise_selegen_analise_selecao_teste_genetico';
SincroniaConstantes.RESULTADO_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO = 'resultado_selegen_analise_selecao_teste_genetico';
SincroniaConstantes.ANALISE_ANO_ANALISE_SELECAO_TESTE_GENETICO = 'analise_ano_analise_selecao_teste_genetico';
SincroniaConstantes.ANALISE_BLUP_ANALISE_SELECAO_TESTE_GENETICO = 'analise_blup_analise_selecao_teste_genetico';
SincroniaConstantes.SELECAO_GENETICA_ANALISE_SELECAO_TESTE_GENETICO = 'selecao_genetica_analise_selecao_teste_genetico';
SincroniaConstantes.SELECAO_CAMPO_ANALISE_SELECAO_TESTE_GENETICO = 'selecao_campo_analise_selecao_teste_genetico';
SincroniaConstantes.ANEXO_ANALISE_SELECAO_TESTE_GENETICO = 'anexo_analise_selecao_teste_genetico';
SincroniaConstantes.ANEXO_TESTE_GENETICO = 'anexo_teste_genetico';
SincroniaConstantes.PROTOCOLO_TESTE_GENETICO = 'protocolo_teste_genetico';
SincroniaConstantes.APONTAMENTO = 'apontamento';
SincroniaConstantes.APONTAMENTO_RELACIONADO = 'apontamento_relacionado';
SincroniaConstantes.PLANO_ACAO_NC = 'plano_acao_nc';
SincroniaConstantes.ANALISE_ESTATISTICA = 'analise_estatistica';
SincroniaConstantes.MOVIMENTACAO_ESTOQUE = 'movimentacao_estoque';
SincroniaConstantes.USUARIO = 'usuario';
SincroniaConstantes.PARAMETRIZACAO = 'parametrizacao';
SincroniaConstantes.FILE = 'file';
SincroniaConstantes.PLANILHA = 'planilha';
SincroniaConstantes.REV_TABELA = 'rev_tabela';
SincroniaConstantes.POSICAO_COLUNA_TABELA = 'posicao_coluna_tabela';
SincroniaConstantes.VISIBILIDADE_COLUNA_TABELA = 'visibilidade_coluna_tabela';
