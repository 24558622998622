"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SituacaoControleManejoTesteCampo = void 0;
class SituacaoControleManejoTesteCampo {
    constructor() {
        this.id = null;
        this.descricao = null;
    }
    static SOLICITADO() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.situacaoControleManejoTesteCampo_solicitado === 'undefined') ||
            (globalThis.globalConstants.situacaoControleManejoTesteCampo_solicitado === null)) {
            let situacaoControleManejoTesteCampo_solicitado = new SituacaoControleManejoTesteCampo();
            situacaoControleManejoTesteCampo_solicitado.id = 'SOLICITADO';
            situacaoControleManejoTesteCampo_solicitado.descricao = 'Solicitado';
            globalThis.globalConstants.situacaoControleManejoTesteCampo_solicitado = situacaoControleManejoTesteCampo_solicitado;
        }
        return globalThis.globalConstants.situacaoControleManejoTesteCampo_solicitado;
    }
    static ATRASADO() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.situacaoControleManejoTesteCampo_atrasado === 'undefined') ||
            (globalThis.globalConstants.situacaoControleManejoTesteCampo_atrasado === null)) {
            let situacaoControleManejoTesteCampo_atrasado = new SituacaoControleManejoTesteCampo();
            situacaoControleManejoTesteCampo_atrasado.id = 'ATRASADO';
            situacaoControleManejoTesteCampo_atrasado.descricao = 'Atrasado';
            globalThis.globalConstants.situacaoControleManejoTesteCampo_atrasado = situacaoControleManejoTesteCampo_atrasado;
        }
        return globalThis.globalConstants.situacaoControleManejoTesteCampo_atrasado;
    }
    static CONCLUIDO() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.situacaoControleManejoTesteCampo_concluido === 'undefined') ||
            (globalThis.globalConstants.situacaoControleManejoTesteCampo_concluido === null)) {
            let situacaoControleManejoTesteCampo_concluido = new SituacaoControleManejoTesteCampo();
            situacaoControleManejoTesteCampo_concluido.id = 'CONCLUIDO';
            situacaoControleManejoTesteCampo_concluido.descricao = 'Concluído';
            globalThis.globalConstants.situacaoControleManejoTesteCampo_1ano = situacaoControleManejoTesteCampo_concluido;
        }
        return globalThis.globalConstants.situacaoControleManejoTesteCampo_1ano;
    }
    static LISTA() {
        return [
            SituacaoControleManejoTesteCampo.SOLICITADO(),
            SituacaoControleManejoTesteCampo.ATRASADO(),
            SituacaoControleManejoTesteCampo.CONCLUIDO()
        ];
    }
}
exports.SituacaoControleManejoTesteCampo = SituacaoControleManejoTesteCampo;
