"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProducaoMudaClonalPinusController = void 0;
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const UiGridConstrutor_1 = require("../../../app/construtores/UiGridConstrutor");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const AbstratoController_1 = require("../../../app/AbstratoController");
const ICRUDOperacao_1 = require("../../../app/interfaces/ICRUDOperacao");
const config_app_1 = require("../../../../../config/config.app");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const ProducaoMudaClonalPinus_1 = require("../../../../../modelos/ProducaoMudaClonalPinus");
const sweetalert2_1 = require("sweetalert2");
class ProducaoMudaClonalPinusController extends AbstratoController_1.AbstratoController {
    constructor($scope, $injector) {
        super($scope, $injector, new ProducaoMudaClonalPinus_1.ProducaoMudaClonalPinus());
        this.path = '';
        this.caminho = '';
        this.tipoOperacao = null;
        this.listaOpcoes = {
            data: [],
            columnDefs: [],
            headerTemplate: 'app/angularjs/modulos/cadastro/paginas/producaoMudaClonalPinus/template/cabecalhoMudaClonalPinus.html'
        };
        this.clonesMaterialGenetico = [];
        this.generos = [];
        this.especies = [];
        this.procedencias = [];
        this.regioes = [];
        this.finalidadesMultiplicacao = [];
        this.acoesMinijardim = [];
        this.listaStatusMinijardim = [];
        this.pomares = [];
        this.tipoOperacao = ICRUDOperacao_1.CRUDOperacao;
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.path = config_app_1.config.api.caminho;
            this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS;
            if ((this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) ||
                (this.operacao === ICRUDOperacao_1.CRUDOperacao.EDITAR) ||
                (this.operacao === ICRUDOperacao_1.CRUDOperacao.DUPLICAR)) {
                this.clonesMaterialGenetico = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.CLONE_MATERIAL_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CLONE_MATERIAL_GENETICO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.clonesMaterialGenetico = resposta;
                    }
                });
                this.generos = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.GENERO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.GENERO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.generos = resposta;
                    }
                });
                this.especies = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESPECIE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESPECIE)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.especies = resposta;
                    }
                });
                this.procedencias = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROCEDENCIA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROCEDENCIA)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.procedencias = resposta;
                    }
                });
                this.regioes = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.REGIAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.REGIAO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.regioes = resposta;
                    }
                });
                this.finalidadesMultiplicacao = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.FINALIDADE_MULTIPLICACAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.FINALIDADE_MULTIPLICACAO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.finalidadesMultiplicacao = resposta;
                    }
                });
                this.acoesMinijardim = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ACAO_MINIJARDIM)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ACAO_MINIJARDIM)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.acoesMinijardim = resposta;
                    }
                });
                this.listaStatusMinijardim = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.STATUS_MINIJARDIM)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.STATUS_MINIJARDIM)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.listaStatusMinijardim = resposta;
                    }
                });
                this.pomares = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POMAR)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.pomares = resposta;
                    }
                });
            }
            if (this.operacao === ICRUDOperacao_1.CRUDOperacao.LISTAR) {
                this.listaOpcoes = {
                    data: [],
                    columnDefs: [],
                    headerTemplate: 'app/angularjs/modulos/cadastro/paginas/producaoMudasSeminal/template/cabecalhoEstoqueSementes.html'
                };
            }
            this.$scope.$apply();
        });
    }
    salvar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS)
                        .criar(this.modelo);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS)
                        .atualizar(this.modelo);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir um nova Produção de Muda Clonal de Pinus ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir um nova Produção de Muda Clonal de Pinus ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    visualizar(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const producaoMudaClonalPinus = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS)
                    .id(id)
                    .ver();
                producaoMudaClonalPinus.criacao = new Date(producaoMudaClonalPinus.criacao);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return producaoMudaClonalPinus;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    remover(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const producaoMudaClonalPinus = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS)
                    .id(id)
                    .remover();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (producaoMudaClonalPinus) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const producaoMudaClonalPinus = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS)
                    .id(this.modelo.id)
                    .restaurar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (producaoMudaClonalPinus) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosLista(filtro = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                this.path = config_app_1.config.api.caminho;
                this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS;
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                var auxColunas = Array();
                let minijardimTemplate = '<div ng-if="row.entity.minijardim" style="margin-top: 5px; text-align: center !important;">X</div>' +
                    '<div ng-if="!row.entity.minijardim" style="margin-top: 5px; text-align: center !important;">F</div>';
                auxColunas.push({
                    name: 'codigo',
                    displayName: 'Código',
                    width: 90,
                });
                auxColunas.push({
                    name: 'clone.clone',
                    displayName: 'Clone',
                    width: 140,
                });
                auxColunas.push({
                    name: 'genero.descricao',
                    displayName: 'Gênero',
                    width: 140,
                });
                auxColunas.push({
                    name: 'especie.descricao',
                    displayName: 'Espécie',
                    width: 140,
                });
                auxColunas.push({
                    name: 'anoResgate',
                    displayName: 'Ano resgate',
                    width: 90,
                });
                auxColunas.push({
                    name: 'procedencia.descricao',
                    displayName: 'Procedência',
                    width: 140,
                });
                auxColunas.push({
                    name: 'regiaoTeste.nome',
                    displayName: 'Região teste',
                    width: 100,
                });
                auxColunas.push({
                    name: 'anoTeste',
                    displayName: 'Ano teste',
                    width: 90,
                });
                auxColunas.push({
                    name: 'codTeste',
                    displayName: 'Código teste',
                    width: 120,
                });
                auxColunas.push({
                    name: 'minijardim',
                    displayName: 'Minijardim',
                    width: 90,
                    cellTemplate: minijardimTemplate,
                });
                auxColunas.push({
                    name: 'finalidade.descricao',
                    displayName: 'Finalidade',
                    width: 100,
                });
                auxColunas.push({
                    name: 'acaoMinijardim.descricao',
                    displayName: 'Ação',
                    width: 100,
                });
                auxColunas.push({
                    name: 'statusMinijardim.descricao',
                    displayName: 'Status',
                    width: 100,
                });
                auxColunas.push({
                    name: 'qntCanaletoes',
                    displayName: 'Qnt. Canaletões',
                    width: 120,
                });
                auxColunas.push({
                    name: 'numCepas',
                    displayName: 'Nº Cepas',
                    width: 100,
                });
                auxColunas.push({
                    name: 'numMiniestacaCepa',
                    displayName: 'Nº miniestaca/cepa',
                    width: 100,
                });
                auxColunas.push({
                    name: 'perEnraizamento',
                    displayName: '% Enraizamento',
                    width: 100,
                });
                auxColunas.push({
                    name: 'observacao',
                    displayName: 'Observação',
                    width: 100,
                });
                auxColunas.push({
                    name: 'regiaoBCG1.nome',
                    displayName: 'Região',
                    width: 100,
                });
                auxColunas.push({
                    name: 'anoBGC1',
                    displayName: 'Ano',
                    width: 100,
                });
                auxColunas.push({
                    name: 'regiaoBCG2.nome',
                    displayName: 'Região',
                    width: 100,
                });
                auxColunas.push({
                    name: 'anoBGC2',
                    displayName: 'Ano',
                    width: 100,
                });
                auxColunas.push({
                    name: 'regiaoTestesClonais1.nome',
                    displayName: 'Região',
                    width: 100,
                });
                auxColunas.push({
                    name: 'anoTestesClonais1',
                    displayName: 'Ano',
                    width: 100,
                });
                auxColunas.push({
                    name: 'regiaoTestesClonais2.nome',
                    displayName: 'Região',
                    width: 100,
                });
                auxColunas.push({
                    name: 'anoTestesClonais2',
                    displayName: 'Ano',
                    width: 100,
                });
                auxColunas.push({
                    name: 'regiaoTestesClonais3.nome',
                    displayName: 'Região',
                    width: 100,
                });
                auxColunas.push({
                    name: 'anoTestesClonais3',
                    displayName: 'Ano',
                    width: 100,
                });
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
                const lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_PINUS)
                    .filtro(filtro)
                    .listar();
                console.log(lista);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    headerTemplate: 'app/angularjs/modulos/cadastro/paginas/producaoMudaClonalPinus/template/cabecalhoMudaClonalPinus.html'
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    filtrar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    limparFiltro() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
}
exports.ProducaoMudaClonalPinusController = ProducaoMudaClonalPinusController;
ProducaoMudaClonalPinusController.$inject = ['$scope', '$injector'];
