"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FenologiaPomar = void 0;
const Base_1 = require("./Base");
class FenologiaPomar extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.id = null;
        this.codigo = null;
        this.data = null;
        this.pomar = null;
        this.pomarCodigo = null;
        this.pomarDescricao = null;
        this.regiao = null;
        this.talhao = null;
        this.tipoFenologia = null;
        this.estrobilo = null;
        this.pomarOrigem = null;
        this.pomarOrigemCodigo = null;
        this.pomarOrigemDescricao = null;
        this.materialGenetico = null;
        this.faseAvaliada = null;
        this.estagioFloralFeminino = null;
        this.estagioFloralMasculino = null;
        this.estagioFloralEucalipto = null;
        this.maturacaoFruto = null;
        this.numArvore = null;
        this.estagiosFlorais = [];
        this.anexos = [];
        this.observacoes = null;
        this.usuarioInclusao = null;
        this.dataHoraInclusao = null;
        this.usuarioAlteracao = null;
        this.dataHoraAlteracao = null;
    }
}
exports.FenologiaPomar = FenologiaPomar;
