"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClassificacaoSolo = void 0;
const Base_1 = require("./Base");
class ClassificacaoSolo extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.descricao = null;
        this.umo = null;
        this.sigla = null;
        this.dsg = null;
        this.profundidadeCm = null;
        this.recomendacao = null;
    }
}
exports.ClassificacaoSolo = ClassificacaoSolo;
