"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Localidade = void 0;
const Base_1 = require("./Base");
class Localidade extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.sigla = null;
        this.descricao = null;
        this.pais = null;
        this.provinciaUf = null;
    }
}
exports.Localidade = Localidade;
