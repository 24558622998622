"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProducaoMudasSemeioEnxertia = void 0;
const Base_1 = require("./Base");
class ProducaoMudasSemeioEnxertia extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.dataSemeio = null;
        this.quantidadeG = null;
        this.numMudasNecessarias = null;
        this.sobrevivencia = null;
        this.dataEnxertia = null;
        this.numEnxertosRealizados = null;
        this.sobrevivenciaEnxerto = null;
    }
}
exports.ProducaoMudasSemeioEnxertia = ProducaoMudasSemeioEnxertia;
