"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TesteCampoController = void 0;
const angular = require("angular");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const AbstratoController_1 = require("../../../app/AbstratoController");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const ICRUDOperacao_1 = require("../../../app/interfaces/ICRUDOperacao");
const TesteCampo_1 = require("../../../../../modelos/TesteCampo");
const FiltroControleManejoTesteGenetico_1 = require("../../../../../io/FiltroControleManejoTesteGenetico");
const FiltroRelatorioTesteGenetico_1 = require("../../../../../io/FiltroRelatorioTesteGenetico");
const DadosRelatorioTeste_1 = require("../../../../../io/DadosRelatorioTeste");
const config_app_1 = require("../../../../../config/config.app");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const MaterialGenetico_1 = require("../../../../../modelos/MaterialGenetico");
const Regiao_1 = require("../../../../../modelos/Regiao");
const Talhao_1 = require("../../../../../modelos/Talhao");
const AnoAnaliseTesteGenetico_1 = require("../../../../../modelos/AnoAnaliseTesteGenetico");
const Especie_1 = require("../../../../../modelos/Especie");
const Geracao_1 = require("../../../../../modelos/Geracao");
const Genero_1 = require("../../../../../modelos/Genero");
const Pais_1 = require("../../../../../modelos/Pais");
const ProvinciaUf_1 = require("../../../../../modelos/ProvinciaUf");
const ClassificacaoSolo_1 = require("../../../../../modelos/ClassificacaoSolo");
const UnidadeManejo_1 = require("../../../../../modelos/UnidadeManejo");
const TipoDelineamento_1 = require("../../../../../modelos/TipoDelineamento");
const DisposicaoIndividuos_1 = require("../../../../../modelos/DisposicaoIndividuos");
const ClassificacaoClimatica_1 = require("../../../../../modelos/ClassificacaoClimatica");
const PerfilClimaticoPomar_1 = require("../../../../../modelos/PerfilClimaticoPomar");
const MapaTesteGenetico_1 = require("../../../../../modelos/MapaTesteGenetico");
const TipoRelatorioTesteGenetico_1 = require("../../../../../modelos/TipoRelatorioTesteGenetico");
const NumeroUtil_1 = require("../../../../../util/NumeroUtil");
const DispositivoUtil_1 = require("../../../../../util/DispositivoUtil");
const DataUtil_1 = require("../../../../../util/DataUtil");
const TextoUtil_1 = require("../../../../../util/TextoUtil");
const ArmazenamentoUtil_1 = require("../../../../../util/ArmazenamentoUtil");
const CategoriaPomar_1 = require("../../../../../modelos/CategoriaPomar");
const Situacao_1 = require("../../../../../modelos/Situacao");
const TipoNaturalPlantada_1 = require("../../../../../modelos/TipoNaturalPlantada");
const TipoImplantacao_1 = require("../../../../../modelos/TipoImplantacao");
const Fonte_1 = require("../../../../../modelos/Fonte");
const OperacaoManejoTesteCampo_1 = require("../../../../../modelos/OperacaoManejoTesteCampo");
const SituacaoControleManejoTesteCampo_1 = require("../../../../../modelos/SituacaoControleManejoTesteCampo");
const ConferenciaControleManejoTesteCampo_1 = require("../../../../../modelos/ConferenciaControleManejoTesteCampo");
const ProdutoFertilizante_1 = require("../../../../../modelos/ProdutoFertilizante");
const Estrobilo_1 = require("../../../../../modelos/Estrobilo");
const PilhaCroqui_1 = require("../../../../../modelos/PilhaCroqui");
const AnexoTesteGenetico_1 = require("../../../../../modelos/AnexoTesteGenetico");
const ProtocoloTesteGenetico_1 = require("../../../../../modelos/ProtocoloTesteGenetico");
const CampoIndicadorFotos_1 = require("../../../app/componentes/campo-indicador/CampoIndicadorFotos");
const LuckysheetUtil_1 = require("../../../../../util/LuckysheetUtil");
const TipoAnaliseSelegenTesteGenetico_1 = require("../../../../../modelos/TipoAnaliseSelegenTesteGenetico");
const TipoAnexoQualidadeMadeira_1 = require("../../../../../modelos/TipoAnexoQualidadeMadeira");
const chart_js_1 = require("chart.js");
const HistoricoEstoqueProdutoFertilizante_1 = require("../../../../../modelos/HistoricoEstoqueProdutoFertilizante");
const PerfilClimaticoTesteGenetico_1 = require("../../../../../modelos/PerfilClimaticoTesteGenetico");
const TipoTesteTratamentoTestemunha_1 = require("../../../../../modelos/TipoTesteTratamentoTestemunha");
const ImportaShapefile_1 = require("../../../../../modelos/ImportaShapefile");
const TesteCampoDadosListaUtil_1 = require("./TesteCampoDadosListaUtil");
const TesteCampoTratamentoUtil_1 = require("./TesteCampoTratamentoUtil");
const TesteCampoControleManejoUtil_1 = require("./TesteCampoControleManejoUtil");
const TesteCampoRelatorioUtil_1 = require("./TesteCampoRelatorioUtil");
const TesteCampoMedicaoDendrometricaUtil_1 = require("./TesteCampoMedicaoDendrometricaUtil");
const TesteCampoQualidadeMadeiraUtil_1 = require("./TesteCampoQualidadeMadeiraUtil");
const TesteCampoAnaliseSelecaoUtil_1 = require("./TesteCampoAnaliseSelecaoUtil");
const TesteCampoCroquiUtil_1 = require("./TesteCampoCroquiUtil");
const sweetalert2_1 = require("sweetalert2");
class TesteCampoController extends AbstratoController_1.AbstratoController {
    constructor($scope, $injector) {
        super($scope, $injector, new TesteCampo_1.TesteCampo());
        this.path = '';
        this.caminho = '';
        this.tipoOperacao = null;
        this.tipoRelatorioTesteGenetico = null;
        this.tipoEstrobilo = null;
        this.listaTestesGeneticos = [];
        this.listaTratamentosTesteGenetico = [];
        this.listaOpcoesTestesGeneticos = null;
        this.listaOpcoesManejoTesteGeneticoResumido = null;
        this.listaOpcoesManejoTesteGeneticoDetalhado = null;
        this.listaOpcoesTratamentosTesteGenetico = null;
        this.listaOpcoesMateriaisGeneticosTratamentosTesteGenetico = null;
        this.listaOpcoesClimaAnualTesteGenetico = null;
        this.listaOpcoesMedicaoDendrometricaTesteGenetico = null;
        this.listaOpcoesLancamentoMedicaoDendrometricaTesteGenetico = null;
        this.listaOpcoesPlanejamentoMedicaoDendrometricaTesteGenetico = null;
        this.listaOpcoesQualidadeMadeiraTesteGenetico = null;
        this.listaOpcoesAnaliseSelecaoTesteGenetico = null;
        this.listaOpcoesSumarioAnaliseSelecaoTesteGenetico = null;
        this.listaOpcoesAnaliseSelegenAnaliseSelecaoTesteGenetico = null;
        this.listaOpcoesRankingResultadoSelegenAnaliseSelecaoTesteGenetico = null;
        this.listaOpcoesVariaveisResultadoSelegenAnaliseSelecaoTesteGenetico = null;
        this.listaOpcoesResultadoSelegenAnaliseSelecaoTesteGenetico = null;
        this.listaOpcoesAnaliseAnoAnaliseSelecaoTesteGenetico = null;
        this.listaOpcoesParametrosAnaliseBlupAnaliseSelecaoTesteGenetico = null;
        this.listaOpcoesLimitesReferenciaAnaliseBlupAnaliseSelecaoTesteGenetico = null;
        this.listaOpcoesAnaliseBlupAnaliseSelecaoTesteGenetico = null;
        this.listaOpcoesCriteriosSelecaoGeneticaAnaliseSelecaoTesteGenetico = null;
        this.listaOpcoesLimitesReferenciaSelecaoGeneticaAnaliseSelecaoTesteGenetico = null;
        this.listaOpcoesSelecaoGeneticaAnaliseSelecaoTesteGenetico = null;
        this.listaOpcoesCriteriosSelecaoCampoAnaliseSelecaoTesteGenetico = null;
        this.listaOpcoesLimitesReferenciaSelecaoCampoAnaliseSelecaoTesteGenetico = null;
        this.listaOpcoesSelecaoCampoAnaliseSelecaoTesteGenetico = null;
        this.listaOpcoesCroquiSelecaoCampoAnaliseSelecaoTesteGenetico = null;
        this.listaOpcoesAnexosTesteGenetico = null;
        this.listaOpcoesProtocolosTesteGenetico = null;
        this.testeGeneticoSelecionado = null;
        this.testeGeneticoCarregado = false;
        this.tratamentoTesteGeneticoSelecionado = null;
        this.tratamentoTesteGeneticoVisualizar = null;
        this.croquiTesteGeneticoSelecionado = null;
        this.mapaTesteGeneticoSelecionado = null;
        this.importaShapefileSelecionado = null;
        this.controleManejoTesteGeneticoSelecionado = null;
        this.controleManejoTesteGeneticoVisualizar = null;
        this.medicaoDendrometricaTesteGeneticoSelecionado = null;
        this.medicaoDendrometricaTesteGeneticoVisualizar = null;
        this.medicaoDendrometricaTesteGeneticoImportacao = null;
        this.medicaoDendrometricaTesteGeneticoImportacaoAnexos = null;
        this.medicaoDendrometricaTesteGeneticoHistorico = null;
        this.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado = null;
        this.planejamentoMedicaoDendrometricaTesteGeneticoVisualizar = null;
        this.planejamentoMedicaoDendrometricaTesteGeneticoImportacao = null;
        this.planejamentoMedicaoDendrometricaTesteGeneticoImportacaoAnexos = null;
        this.planejamentoMedicaoDendrometricaTesteGeneticoHistorico = null;
        this.planilhaMedicaoDendrometricaTesteGeneticoSelecionado = null;
        this.planilhaMedicaoDendrometricaTesteGeneticoVisualizar = null;
        this.planilhaMedicaoDendrometricaTesteGeneticoImportacao = null;
        this.planilhaMedicaoDendrometricaTesteGeneticoImportacaoAnexos = null;
        this.planilhaMedicaoDendrometricaTesteGeneticoHistorico = null;
        this.selegenMedicaoDendrometricaTesteGeneticoSelecionado = null;
        this.selegenMedicaoDendrometricaTesteGeneticoVisualizar = null;
        this.selegenMedicaoDendrometricaTesteGeneticoImportacao = null;
        this.selegenMedicaoDendrometricaTesteGeneticoImportacaoAnexos = null;
        this.selegenMedicaoDendrometricaTesteGeneticoHistorico = null;
        this.qualidadeMadeiraTesteGeneticoSelecionado = null;
        this.qualidadeMadeiraTesteGeneticoVisualizar = null;
        this.qualidadeMadeiraTesteGeneticoImportacao = null;
        this.qualidadeMadeiraTesteGeneticoImportacaoAnexos = null;
        this.qualidadeMadeiraTesteGeneticoHistorico = null;
        this.analiseSelecaoTesteGeneticoSelecionado = null;
        this.analiseSelecaoTesteGeneticoVisualizar = null;
        this.analiseSelecaoTesteGeneticoImportacao = null;
        this.analiseSelecaoTesteGeneticoImportacaoAnexos = null;
        this.analiseSelecaoTesteGeneticoHistorico = null;
        this.sumarioAnaliseSelecaoTesteGeneticoSelecionado = null;
        this.sumarioAnaliseSelecaoTesteGeneticoVisualizar = null;
        this.sumarioAnaliseSelecaoTesteGeneticoImportacao = null;
        this.sumarioAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
        this.sumarioAnaliseSelecaoTesteGeneticoHistorico = null;
        this.planilhaSelegenAnaliseSelecaoTesteGeneticoSelecionado = null;
        this.planilhaSelegenAnaliseSelecaoTesteGeneticoVisualizar = null;
        this.planilhaSelegenAnaliseSelecaoTesteGeneticoImportacao = null;
        this.planilhaSelegenAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
        this.planilhaSelegenAnaliseSelecaoTesteGeneticoHistorico = null;
        this.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado = null;
        this.analiseSelegenAnaliseSelecaoTesteGeneticoVisualizar = null;
        this.analiseSelegenAnaliseSelecaoTesteGeneticoImportacao = null;
        this.analiseSelegenAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
        this.analiseSelegenAnaliseSelecaoTesteGeneticoHistorico = null;
        this.resultadoSelegenAnaliseSelecaoTesteGeneticoSelecionado = null;
        this.resultadoSelegenAnaliseSelecaoTesteGeneticoVisualizar = null;
        this.resultadoSelegenAnaliseSelecaoTesteGeneticoImportacao = null;
        this.resultadoSelegenAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
        this.resultadoSelegenAnaliseSelecaoTesteGeneticoHistorico = null;
        this.analiseAnoAnaliseSelecaoTesteGeneticoSelecionado = null;
        this.analiseAnoAnaliseSelecaoTesteGeneticoVisualizar = null;
        this.analiseAnoAnaliseSelecaoTesteGeneticoImportacao = null;
        this.analiseAnoAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
        this.analiseAnoAnaliseSelecaoTesteGeneticoHistorico = null;
        this.analiseBlupAnaliseSelecaoTesteGeneticoSelecionado = null;
        this.analiseBlupAnaliseSelecaoTesteGeneticoVisualizar = null;
        this.analiseBlupAnaliseSelecaoTesteGeneticoImportacao = null;
        this.analiseBlupAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
        this.analiseBlupAnaliseSelecaoTesteGeneticoHistorico = null;
        this.selecaoGeneticaAnaliseSelecaoTesteGeneticoSelecionado = null;
        this.selecaoGeneticaAnaliseSelecaoTesteGeneticoVisualizar = null;
        this.selecaoGeneticaAnaliseSelecaoTesteGeneticoImportacao = null;
        this.selecaoGeneticaAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
        this.selecaoGeneticaAnaliseSelecaoTesteGeneticoHistorico = null;
        this.selecaoCampoAnaliseSelecaoTesteGeneticoSelecionado = null;
        this.selecaoCampoAnaliseSelecaoTesteGeneticoVisualizar = null;
        this.selecaoCampoAnaliseSelecaoTesteGeneticoImportacao = null;
        this.selecaoCampoAnaliseSelecaoTesteGeneticoImportacaoAnexos = null;
        this.selecaoCampoAnaliseSelecaoTesteGeneticoHistorico = null;
        this.anexoAnaliseSelecaoTesteGeneticoSelecionado = null;
        this.anexoAnaliseSelecaoTesteGeneticoVisualizar = null;
        this.relatorioTesteGeneticoSelecionado = null;
        this.relatorioTesteGeneticoVisualizar = null;
        this.anexoTesteGeneticoSelecionado = null;
        this.anexoTesteGeneticoVisualizar = null;
        this.protocoloTesteGeneticoSelecionado = null;
        this.protocoloTesteGeneticoVisualizar = null;
        this.generos = [];
        this.especies = [];
        this.geracoes = [];
        this.paises = [];
        this.provinciasUf = [];
        this.localidades = [];
        this.tiposImplantacoes = [];
        this.tiposTestesTratamentoTestemunha = [];
        this.tiposTestesPopulacoes = [];
        this.fontes = [];
        this.categoriasPomares = [];
        this.tiposPomares = [];
        this.situacoes = [];
        this.tiposNaturalPlantada = [];
        this.classificacoesSolo = [];
        this.unidadesManejo = [];
        this.produtosFertilizantes = [];
        this.tiposDelineamentos = [];
        this.disposicoesIndividuos = [];
        this.classificacoesClimaticas = [];
        this.estrobiloFeminino = null;
        this.estrobiloMasculino = null;
        this.hibridacoes = [];
        this.estagiosFloraisFemininos = [];
        this.estagiosFloraisMasculinos = [];
        this.estagiosFloraisEucalipto = [];
        this.maturacoesFrutos = [];
        this.tiposAnalisesSelegen = [];
        this.tiposAnexosQualidadeMadeira = [];
        this.regioes = [];
        this.regioesCarregando = false;
        this.talhoes = [];
        this.talhoesFiltrados = [];
        this.talhoesCarregando = false;
        this.coordenadaMapa = { latitude: 0, longitude: 0 };
        this.geoJsonMapa = null;
        this.mapaLiberado = false;
        this.grafico1ClimaAnualTesteGenetico = null;
        this.grafico2ClimaAnualTesteGenetico = null;
        this.operacoesManejoTesteGenetico = [];
        this.situacoesControleManejoTesteGenetico = [];
        this.conferenciasControleManejoTesteGenetico = [];
        this.tiposLimpezaTesteGenetico = [];
        this.produtosLimpezaTesteGenetico = [];
        this.tiposDesbasteTesteGenetico = [];
        this.tiposAmostrasManejoTesteGenetico = [];
        this.finalidadesOperacaoColetaAmostraTesteGenetico = [];
        this.tiposAdubacaoTesteGenetico = [];
        this.produtosAdubacaoTesteGenetico = [];
        this.tiposDoencasTesteGenetico = [];
        this.tiposIntervencaoDoencaTesteGenetico = [];
        this.produtosControleDoencaTesteGenetico = [];
        this.contasDepositoSap = [];
        this.centrosCustoSap = [];
        this.generosFiltro = [];
        this.pomares = [];
        this.tratamentosPomares = [];
        this.populacoes = [];
        this.conjuntos = [];
        this.statusTestes = [];
        this.especiesFiltro = [];
        this.fazendasFiltro = [];
        this.situacoesFiltro = [];
        this.tiposTestesPopulacoesFiltro = [];
        this.tiposPomaresFiltro = [];
        this.listaAnosFiltro = [];
        this.pomaresFiltro = [];
        this.anosTesteGenetico = [];
        this.materiaisGeneticos = [];
        this.materiaisGeneticosFiltrados = [];
        this.listaPolensFiltrados = [];
        this.materiaisGeneticosCarregando = false;
        this.listaFuncionarios = [];
        this.listaFuncionariosCarregando = false;
        this.listaMeses = [];
        this.listaAnos = [];
        this.listaPilhasCroqui = [];
        this.listaEspacamentos = [
            { id: '1000_1000_1000', descricao: '10 x 10 x 10 M' },
            { id: '250_200', descricao: '2,5 x 2,0 M' },
            { id: '300_200', descricao: '3,0 x 2,0 M' },
            { id: '300_250', descricao: '3,0 x 2,5 M' },
            { id: '300_350', descricao: '3,0 x 3,5 M' },
            { id: '350_180', descricao: '3,5 x 1,8 M' },
        ];
        this.listasFotos = null;
        this.listaMedicaoDendrometrica = [];
        this.listaAnaliseSelecao = [];
        this.selectizeConfigMateriaisGeneticos = {
            create: false,
            persist: true,
            maxItems: null,
            openOnFocus: true,
            closeAfterSelect: false,
            selectOnTab: true,
            plugins: ['remove_button']
        };
        this.selectizeOptionsMateriaisGeneticos = [];
        this.selectizeConfigPilhasCroqui = {
            create: false,
            persist: true,
            maxItems: null,
            openOnFocus: true,
            closeAfterSelect: false,
            selectOnTab: true,
            plugins: ['remove_button']
        };
        this.selectizeOptionsPilhasCroqui = [];
        this.filtroControleManejoTesteGenetico = null;
        this.filtroRelatorioTesteGenetico = null;
        this.dadosRelatorioTeste = null;
        this.dadosRelatorioCarregado = false;
        this.graficoTestesPie = null;
        this.graficoTestesBar = null;
        this.tratamentoSelecionado = null;
        this.tratamentoCarregado = false;
        this.pomarFiltrado = null;
        this.materialGeneticoFiltrado = null;
        this.modalTratamentoTesteGenetico = false;
        this.modalGerarCroquiTesteGenetico = false;
        this.modalGerarCroquiTesteGeneticoDados = null;
        this.modalImportacaoCroquiTesteGenetico = false;
        this.modalImportacaoCroquiTesteGeneticoAnexos = null;
        this.modalHistoricoCroquiTesteGenetico = false;
        this.modalControleManejoTesteGenetico = false;
        this.modalMedicaoDendrometricaTesteGenetico = false;
        this.modalPlanejamentoMedicaoDendrometricaTesteGenetico = false;
        this.modalPlanilhaMedicaoDendrometricaTesteGenetico = false;
        this.modalSelegenMedicaoDendrometricaTesteGenetico = false;
        this.modalQualidadeMadeiraTesteGenetico = false;
        this.modalAnaliseSelecaoTesteGenetico = false;
        this.modalSumarioAnaliseSelecaoTesteGenetico = false;
        this.modalPlanilhaSelegenAnaliseSelecaoTesteGenetico = false;
        this.modalAnaliseSelegenAnaliseSelecaoTesteGenetico = false;
        this.modalResultadoSelegenAnaliseSelecaoTesteGenetico = false;
        this.modalAnaliseAnoAnaliseSelecaoTesteGenetico = false;
        this.modalAnaliseBlupAnaliseSelecaoTesteGenetico = false;
        this.modalSelecaoGeneticaAnaliseSelecaoTesteGenetico = false;
        this.modalSelecaoCampoAnaliseSelecaoTesteGenetico = false;
        this.modalAnexoAnaliseSelecaoTesteGenetico = false;
        this.modalRelatorioTesteGenetico = false;
        this.modalAnexoTesteGenetico = false;
        this.modalProtocoloTesteGenetico = false;
        this.modalDossieMaterialGenetico = false;
        this.posicaoTopoAntesModal = null;
        this.timeoutFiltroTalhoes = null;
        this.timeoutFiltroMateriaisGeneticos = null;
        this.timeoutFiltroPilhasCroqui = null;
        this.timeoutAtualizacaoGraficosClimaAnual = null;
        this.timeoutAtualizacaoMapa = null;
        this.timeoutAtualizacaoAnoAnalise = null;
        this.timeoutAtualizacaoDelineamento = null;
        this.timeoutAtualizacaoGraficoTestesPie = [];
        this.timeoutAtualizacaoGraficoTestesBar = [];
        this.tipoOperacao = ICRUDOperacao_1.CRUDOperacao;
        this.tipoRelatorioTesteGenetico = TipoRelatorioTesteGenetico_1.TipoRelatorioTesteGenetico;
        this.tipoEstrobilo = Estrobilo_1.Estrobilo;
        this.listasFotos = new CampoIndicadorFotos_1.CampoIndicadorFotos($scope);
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.path = config_app_1.config.api.caminho;
            this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.BANCO_MELHORAMENTO_POMAR;
            this.modalTratamentoTesteGenetico = false;
            this.modalGerarCroquiTesteGenetico = false;
            this.modalGerarCroquiTesteGeneticoDados = null;
            this.modalImportacaoCroquiTesteGenetico = false;
            this.modalHistoricoCroquiTesteGenetico = false;
            this.modalControleManejoTesteGenetico = false;
            this.modalMedicaoDendrometricaTesteGenetico = false;
            this.modalPlanejamentoMedicaoDendrometricaTesteGenetico = false;
            this.modalPlanilhaMedicaoDendrometricaTesteGenetico = false;
            this.modalSelegenMedicaoDendrometricaTesteGenetico = false;
            this.modalQualidadeMadeiraTesteGenetico = false;
            this.modalAnaliseSelecaoTesteGenetico = false;
            this.modalSumarioAnaliseSelecaoTesteGenetico = false;
            this.modalPlanilhaSelegenAnaliseSelecaoTesteGenetico = false;
            this.modalAnaliseSelegenAnaliseSelecaoTesteGenetico = false;
            this.modalResultadoSelegenAnaliseSelecaoTesteGenetico = false;
            this.modalAnaliseAnoAnaliseSelecaoTesteGenetico = false;
            this.modalAnaliseBlupAnaliseSelecaoTesteGenetico = false;
            this.modalSelecaoGeneticaAnaliseSelecaoTesteGenetico = false;
            this.modalSelecaoCampoAnaliseSelecaoTesteGenetico = false;
            this.modalAnexoAnaliseSelecaoTesteGenetico = false;
            this.modalRelatorioTesteGenetico = false;
            this.modalAnexoTesteGenetico = false;
            this.modalProtocoloTesteGenetico = false;
            this.modalDossieMaterialGenetico = false;
            this.listaMedicaoDendrometrica = [];
            this.filtroControleManejoTesteGenetico = new FiltroControleManejoTesteGenetico_1.FiltroControleManejoTesteGenetico();
            this.filtroRelatorioTesteGenetico = new FiltroRelatorioTesteGenetico_1.FiltroRelatorioTesteGenetico();
            this.dadosRelatorioTeste = new DadosRelatorioTeste_1.DadosRelatorioTeste();
            if (!this.modelo.data) {
                this.modelo.data = new Date();
                this.modelo.data.setSeconds(0);
                this.modelo.data.setMilliseconds(0);
            }
            if (!this.usuario) {
                this.links = {
                    VISUALIZAR: null,
                    CRIAR: null,
                    DUPLICAR: null,
                    EDITAR: null,
                    LISTAR: {
                        rota: 'login',
                        params: null
                    }
                };
            }
            this.generosFiltro = [];
            this.pomares = [];
            this.tratamentosPomares = [];
            this.populacoes = [];
            this.conjuntos = [];
            this.statusTestes = [];
            this.especiesFiltro = [];
            this.fazendasFiltro = [];
            this.situacoesFiltro = [];
            this.tiposTestesPopulacoesFiltro = [];
            this.tiposPomaresFiltro = [];
            this.listaAnosFiltro = [];
            this.pomaresFiltro = [];
            this.listaOpcoesTestesGeneticos = yield this.dadosListaTestesGeneticos();
            this.listaOpcoesManejoTesteGeneticoResumido = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesManejoTesteGeneticoDetalhado = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesTratamentosTesteGenetico = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesMateriaisGeneticosTratamentosTesteGenetico = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesClimaAnualTesteGenetico = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesMedicaoDendrometricaTesteGenetico = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesLancamentoMedicaoDendrometricaTesteGenetico = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesPlanejamentoMedicaoDendrometricaTesteGenetico = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesQualidadeMadeiraTesteGenetico = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesAnaliseSelecaoTesteGenetico = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesSumarioAnaliseSelecaoTesteGenetico = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesAnaliseSelegenAnaliseSelecaoTesteGenetico = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesRankingResultadoSelegenAnaliseSelecaoTesteGenetico = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesVariaveisResultadoSelegenAnaliseSelecaoTesteGenetico = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesResultadoSelegenAnaliseSelecaoTesteGenetico = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesAnaliseAnoAnaliseSelecaoTesteGenetico = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesParametrosAnaliseBlupAnaliseSelecaoTesteGenetico = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesLimitesReferenciaAnaliseBlupAnaliseSelecaoTesteGenetico = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesAnaliseBlupAnaliseSelecaoTesteGenetico = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesCriteriosSelecaoGeneticaAnaliseSelecaoTesteGenetico = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesLimitesReferenciaSelecaoGeneticaAnaliseSelecaoTesteGenetico = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesSelecaoGeneticaAnaliseSelecaoTesteGenetico = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesCriteriosSelecaoCampoAnaliseSelecaoTesteGenetico = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesLimitesReferenciaSelecaoCampoAnaliseSelecaoTesteGenetico = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesSelecaoCampoAnaliseSelecaoTesteGenetico = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesCroquiSelecaoCampoAnaliseSelecaoTesteGenetico = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesAnexosTesteGenetico = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesProtocolosTesteGenetico = {
                data: [],
                columnDefs: []
            };
            this.tiposNaturalPlantada = TipoNaturalPlantada_1.TipoNaturalPlantada.LISTA();
            this.tiposAnalisesSelegen = TipoAnaliseSelegenTesteGenetico_1.TipoAnaliseSelegenTesteGenetico.LISTA();
            this.tiposAnexosQualidadeMadeira = TipoAnexoQualidadeMadeira_1.TipoAnexoQualidadeMadeira.LISTA();
            this.listaMeses = [
                { mes: 1, descricao: 'Janeiro', sigla: 'Jan' },
                { mes: 2, descricao: 'Fevereiro', sigla: 'Fev' },
                { mes: 3, descricao: 'Março', sigla: 'Mar' },
                { mes: 4, descricao: 'Abril', sigla: 'Abr' },
                { mes: 5, descricao: 'Maio', sigla: 'Mai' },
                { mes: 6, descricao: 'Junho', sigla: 'Jun' },
                { mes: 7, descricao: 'Julho', sigla: 'Jul' },
                { mes: 8, descricao: 'Agosto', sigla: 'Ago' },
                { mes: 9, descricao: 'Setembro', sigla: 'Set' },
                { mes: 10, descricao: 'Outubro', sigla: 'Out' },
                { mes: 11, descricao: 'Novembro', sigla: 'Nov' },
                { mes: 12, descricao: 'Dezembro', sigla: 'Dez' },
            ];
            this.listaAnos = new Array();
            this.carregarTodosAnos();
            this.anosTesteGenetico = AnoAnaliseTesteGenetico_1.AnoAnaliseTesteGenetico.LISTA();
            this.listaPilhasCroqui = PilhaCroqui_1.PilhaCroqui.LISTA();
            this.generos = new Array();
            const promiseGeneros = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.GENERO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.GENERO)
                .filtro({})
                .listar();
            promiseGeneros.then((resposta) => {
                ctrl.generos.splice(0, ctrl.generos.length);
                if ((typeof resposta !== 'undefined') && resposta) {
                    ctrl.generos.push(...resposta);
                }
                console.log('Gêneros carregados: ' + ctrl.generos.length);
                ctrl.scopeApply();
            });
            this.especies = new Array();
            const promiseEspecies = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESPECIE)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESPECIE)
                .filtro({})
                .listar();
            promiseEspecies.then((resposta) => {
                ctrl.especies.splice(0, ctrl.especies.length);
                if ((typeof resposta !== 'undefined') && resposta) {
                    ctrl.especies.push(...resposta);
                }
                console.log('Espécies carregadas: ' + ctrl.especies.length);
                ctrl.scopeApply();
            });
            this.geracoes = new Array();
            const promiseGeracoes = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.GERACAO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.GERACAO)
                .filtro({})
                .listar();
            promiseGeracoes.then((resposta) => {
                ctrl.geracoes.splice(0, ctrl.geracoes.length);
                if ((typeof resposta !== 'undefined') && resposta) {
                    angular.forEach(resposta, (geracao) => {
                        if ((typeof geracao.codigo !== 'undefined') && (geracao.codigo !== null)) {
                            let valor = '' + geracao.codigo;
                            geracao.codigo = valor.replace('.', ',');
                        }
                    });
                    ctrl.geracoes.push(...resposta);
                }
                console.log('Gerações carregadas: ' + ctrl.geracoes.length);
                ctrl.scopeApply();
            });
            this.paises = new Array();
            const promisePaises = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.PAIS)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PAIS)
                .filtro({})
                .listar();
            promisePaises.then((resposta) => {
                ctrl.paises.splice(0, ctrl.paises.length);
                if ((typeof resposta !== 'undefined') && resposta) {
                    ctrl.paises.push(...resposta);
                }
                console.log('Países carregados: ' + ctrl.paises.length);
                ctrl.scopeApply();
            });
            this.provinciasUf = new Array();
            const promiseProvinciasUf = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROVINCIA_UF)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROVINCIA_UF)
                .filtro({})
                .listar();
            promiseProvinciasUf.then((resposta) => {
                ctrl.provinciasUf.splice(0, ctrl.provinciasUf.length);
                if ((typeof resposta !== 'undefined') && resposta) {
                    ctrl.provinciasUf.push(...resposta);
                }
                console.log('Províncias/UF carregadas: ' + ctrl.provinciasUf.length);
                ctrl.scopeApply();
            });
            ctrl.localidades = new Array();
            const promiseLocalidades = ctrl.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.LOCALIDADE)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LOCALIDADE)
                .filtro({})
                .listar();
            promiseLocalidades.then((resposta) => {
                ctrl.localidades.splice(0, ctrl.localidades.length);
                ctrl.localidades.push(...resposta);
                console.log('Localidades carregadas: ' + ctrl.localidades.length);
                ctrl.scopeApply();
            });
            this.tiposImplantacoes = new Array();
            const promiseTiposImplantacoes = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.TIPO_IMPLANTACAO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TIPO_IMPLANTACAO)
                .filtro({})
                .listar();
            promiseTiposImplantacoes.then((resposta) => {
                ctrl.tiposImplantacoes.splice(0, ctrl.tiposImplantacoes.length);
                if ((typeof resposta !== 'undefined') && resposta) {
                    ctrl.tiposImplantacoes.push(...resposta);
                }
                console.log('Tipos de Implantações carregados: ' + ctrl.tiposImplantacoes.length);
                ctrl.scopeApply();
            });
            this.tiposTestesTratamentoTestemunha = TipoTesteTratamentoTestemunha_1.TipoTesteTratamentoTestemunha.LISTA();
            this.tiposTestesPopulacoes = new Array();
            const promiseTiposTestesPopulacoes = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.TIPO_TESTE_POPULACAO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TIPO_TESTE_POPULACAO)
                .listar();
            promiseTiposTestesPopulacoes.then((resposta) => {
                ctrl.tiposTestesPopulacoes.splice(0, ctrl.tiposTestesPopulacoes.length);
                if ((typeof resposta !== 'undefined') && resposta) {
                    ctrl.tiposTestesPopulacoes.push(...resposta);
                }
                console.log('Tipos de Testes/Populações carregados: ' + ctrl.tiposTestesPopulacoes.length);
                ctrl.scopeApply();
            });
            this.fontes = new Array();
            const promiseFontes = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.FONTE)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.FONTE)
                .filtro({})
                .listar();
            promiseFontes.then((resposta) => {
                ctrl.fontes.splice(0, ctrl.fontes.length);
                if ((typeof resposta !== 'undefined') && resposta) {
                    ctrl.fontes.push(...resposta);
                }
                console.log('Fontes carregadas: ' + ctrl.fontes.length);
                ctrl.scopeApply();
            });
            ctrl.categoriasPomares = new Array();
            const promiseCategoriasPomares = ctrl.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.CATEGORIA_POMAR)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CATEGORIA_POMAR)
                .filtro({})
                .listar();
            promiseCategoriasPomares.then((resposta) => {
                ctrl.categoriasPomares.splice(0, ctrl.categoriasPomares.length);
                ctrl.categoriasPomares.push(...resposta);
                console.log('Categorias Pomares carregadas: ' + ctrl.categoriasPomares.length);
                ctrl.scopeApply();
            });
            ctrl.tiposPomares = new Array();
            const promisePopulacoesPomares = ctrl.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.TIPO_POMAR)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TIPO_POMAR)
                .filtro({})
                .listar();
            promisePopulacoesPomares.then((resposta) => {
                ctrl.tiposPomares.splice(0, ctrl.tiposPomares.length);
                ctrl.tiposPomares.push(...resposta);
                console.log('Tipos Pomares carregadas: ' + ctrl.tiposPomares.length);
                ctrl.scopeApply();
            });
            ctrl.pomares = new Array();
            const promisePomares = ctrl.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.POMAR)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POMAR)
                .filtro({})
                .listar();
            promisePomares.then((resposta) => {
                ctrl.pomares.splice(0, ctrl.pomares.length);
                if ((typeof resposta !== 'undefined') && resposta) {
                    ctrl.pomares.push(...resposta);
                    if ((typeof ctrl.pomares !== 'undefined') && ctrl.pomares) {
                        angular.forEach(ctrl.pomares, (pomar) => {
                            pomar.descricaoDetalhada = ((typeof pomar.descricao !== 'undefined') && pomar.descricao ? pomar.descricao : '');
                            if ((typeof pomar.data !== 'undefined') && pomar.data) {
                                pomar.data = (typeof pomar.data === 'string') ? new Date(pomar.data) : pomar.data;
                                if (pomar.data) {
                                    let dataHoraAtual = new Date();
                                    let idadeMs = dataHoraAtual.getTime() - pomar.data.getTime();
                                    let idadeData = new Date(idadeMs);
                                    pomar.idade = Math.abs(idadeData.getUTCFullYear() - 1970);
                                    pomar.ano = pomar.data.getFullYear();
                                    pomar.descricaoDetalhada += ' - ' + pomar.data.getFullYear();
                                }
                            }
                            if ((typeof pomar.paisDescricao === 'undefined') && !pomar.paisDescricao) {
                                if ((typeof ctrl.paises !== 'undefined') && ctrl.paises && (ctrl.paises.length > 0)) {
                                    let paisPadrao = null;
                                    const paisPadraoReferencia = TextoUtil_1.TextoUtil.converteTextoParaReferencia('Brasil');
                                    angular.forEach(ctrl.paises, (pais) => {
                                        if (!paisPadrao && (typeof pais.descricao !== 'undefined') && pais.descricao && (pais.descricao.length > 0)) {
                                            if (TextoUtil_1.TextoUtil.converteTextoParaReferencia(pais.descricao) === paisPadraoReferencia) {
                                                paisPadrao = pais;
                                            }
                                        }
                                    });
                                    if (paisPadrao) {
                                        pomar.pais = paisPadrao;
                                        pomar.paisId = paisPadrao.id;
                                        pomar.paisCodigo = paisPadrao.codigo;
                                        pomar.paisDescricao = paisPadrao.descricao;
                                    }
                                }
                                else {
                                    let paisPadrao = {
                                        id: Pais_1.Pais.BRASIL,
                                        uuid: Pais_1.Pais.BRASIL,
                                        status: true,
                                        criacao: new Date(),
                                        codigo: 1,
                                        descricao: 'Brasil'
                                    };
                                    pomar.pais = paisPadrao;
                                    pomar.paisId = paisPadrao.id;
                                    pomar.paisCodigo = paisPadrao.codigo;
                                    pomar.paisDescricao = paisPadrao.descricao;
                                }
                            }
                            if ((typeof pomar.instituicao === 'undefined') || !pomar.instituicao || (pomar.instituicao.length === 0)) {
                                pomar.instituicao = 'WestRock';
                            }
                            if ((typeof pomar.nomeCientifico === 'undefined') || !pomar.nomeCientifico || (pomar.nomeCientifico.length === 0)) {
                                pomar.nomeCientifico = pomar.descricao;
                            }
                            if ((typeof pomar.distanciaSede === 'undefined') || !pomar.distanciaSede) {
                                pomar.distanciaSede = (pomar.descricaoDetalhada.toLowerCase().indexOf('sede') !== -1) ? '1 km' : '35 km';
                            }
                            pomar.local = ((typeof pomar.municipio !== 'undefined') && pomar.municipio ? pomar.municipio : '') + ' - ' +
                                ((typeof pomar.provinciaUfSigla !== 'undefined') && pomar.provinciaUfSigla ? pomar.provinciaUfSigla : '');
                            if ((typeof pomar.perfilClimaticoAnual !== 'undefined') && pomar.perfilClimaticoAnual) {
                                if ((typeof pomar.perfilClimaticoAnual.atualizacao === 'undefined') || !pomar.perfilClimaticoAnual.atualizacao) {
                                    pomar.perfilClimaticoAnual.atualizacao = new Date();
                                }
                                else if (typeof pomar.perfilClimaticoAnual.atualizacao === 'string') {
                                    pomar.perfilClimaticoAnual.atualizacao = new Date(pomar.perfilClimaticoAnual.atualizacao);
                                }
                            }
                            else {
                                pomar.perfilClimaticoAnual = new PerfilClimaticoPomar_1.PerfilClimaticoPomar();
                                pomar.perfilClimaticoAnual.atualizacao = new Date();
                            }
                            if ((typeof pomar.producaoPortaEnxertosPlantio !== 'undefined') && pomar.producaoPortaEnxertosPlantio) {
                                pomar.producaoPortaEnxertosPlantio = (typeof pomar.producaoPortaEnxertosPlantio === 'string') ? new Date(pomar.producaoPortaEnxertosPlantio) : pomar.producaoPortaEnxertosPlantio;
                            }
                            if ((typeof pomar.implantacaoPomarEnxertia !== 'undefined') && pomar.implantacaoPomarEnxertia) {
                                pomar.implantacaoPomarEnxertia = (typeof pomar.implantacaoPomarEnxertia === 'string') ? new Date(pomar.implantacaoPomarEnxertia) : pomar.implantacaoPomarEnxertia;
                            }
                        });
                    }
                }
                console.log('Pomares carregados: ' + ctrl.pomares.length);
                ctrl.scopeApply();
            });
            this.tratamentosPomares = new Array();
            const promiseTratamentosPomares = ctrl.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.TRATAMENTO_POMAR)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TRATAMENTO_POMAR)
                .filtro({})
                .listar();
            promiseTratamentosPomares.then((resposta) => {
                ctrl.tratamentosPomares.splice(0, ctrl.tratamentosPomares.length);
                ctrl.tratamentosPomares.push(...resposta);
                console.log('Tratamentos dos Pomares carregados: ' + ctrl.tratamentosPomares.length);
                ctrl.scopeApply();
            });
            ctrl.situacoes = new Array();
            const promiseSituacoes = ctrl.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.SITUACAO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.SITUACAO)
                .filtro({})
                .listar();
            promiseSituacoes.then((resposta) => {
                ctrl.situacoes.splice(0, ctrl.situacoes.length);
                ctrl.situacoes.push(...resposta);
                console.log('Situações Testes carregadas: ' + ctrl.situacoes.length);
                ctrl.scopeApply();
            });
            ctrl.regioes = new Array();
            ctrl.regioesCarregando = true;
            const promiseRegioesFiltro = ctrl.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.REGIAO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.REGIAO)
                .filtro({ permiteDadosOffline: true })
                .listar();
            promiseRegioesFiltro.then((resposta) => {
                ctrl.regioes.splice(0, ctrl.regioes.length);
                angular.forEach(resposta, (regiao) => {
                    if ((typeof regiao.nome !== 'undefined') && (regiao.nome !== null)) {
                        const s = regiao.nome.trim();
                        if ((s.length > 0) && (s !== '-') && (s !== '_')) {
                            ctrl.regioes.push(regiao);
                        }
                    }
                });
                ctrl.regioesCarregando = false;
                ctrl.scopeApply();
            });
            ctrl.talhoes = new Array();
            ctrl.talhoesFiltrados = new Array();
            ctrl.talhoesCarregando = true;
            const promiseTalhoes = ctrl.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.TALHAO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TALHAO)
                .filtro({ permiteDadosOffline: true })
                .listar();
            promiseTalhoes.then((resposta) => {
                ctrl.talhoes.splice(0, ctrl.talhoes.length);
                ctrl.talhoes.push(...resposta);
                if ((typeof ctrl.talhoes !== 'undefined') && (ctrl.talhoes !== null)) {
                    angular.forEach(ctrl.talhoes, (talhao) => {
                        if ((typeof talhao.regiao === 'undefined') || (talhao.regiao === null)) {
                            talhao.regiao = new Regiao_1.Regiao();
                            talhao.regiao.sgif_id = talhao.regiaoSgifId;
                            talhao.regiao.nome = talhao.regiaoNome;
                        }
                        else if ((typeof talhao.regiao.nome === 'undefined') || (talhao.regiao.nome === null) || (talhao.regiao.nome.trim().length <= 0)) {
                            talhao.regiao.sgif_id = talhao.regiaoSgifId;
                            talhao.regiao.nome = talhao.regiaoNome;
                        }
                    });
                }
                ctrl.filtraTalhoes();
                ctrl.talhoesCarregando = false;
                ctrl.scopeApply();
            });
            ctrl.classificacoesSolo = new Array();
            const promiseClassificacoeSolo = ctrl.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.CLASSIFICACAO_SOLO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CLASSIFICACAO_SOLO)
                .filtro({})
                .listar();
            promiseClassificacoeSolo.then((resposta) => {
                ctrl.classificacoesSolo.splice(0, ctrl.classificacoesSolo.length);
                ctrl.classificacoesSolo.push(...resposta);
                console.log('Classificações de Solo carregadas: ' + ctrl.classificacoesSolo.length);
                ctrl.scopeApply();
            });
            this.estrobiloMasculino = {
                id: Estrobilo_1.Estrobilo.MASCULINO,
                uuid: Estrobilo_1.Estrobilo.MASCULINO,
                status: true,
                codigo: 1,
                descricao: 'Masculino'
            };
            this.estrobiloFeminino = {
                id: Estrobilo_1.Estrobilo.FEMININO,
                uuid: Estrobilo_1.Estrobilo.FEMININO,
                status: true,
                codigo: 2,
                descricao: 'Feminino'
            };
            const promiseEstrobilos = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESTROBILO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESTROBILO)
                .filtro({})
                .listar();
            promiseEstrobilos.then((resposta) => {
                if ((typeof resposta !== 'undefined') && resposta) {
                    angular.forEach(resposta, (estrobilo) => {
                        if ((typeof estrobilo.id !== 'undefined') && estrobilo.id) {
                            if (estrobilo.id === Estrobilo_1.Estrobilo.FEMININO) {
                                ctrl.estrobiloFeminino = estrobilo;
                            }
                            else if (estrobilo.id === Estrobilo_1.Estrobilo.MASCULINO) {
                                ctrl.estrobiloMasculino = estrobilo;
                            }
                        }
                    });
                    console.log('Estróbilos carregados: ' + resposta.length);
                    ctrl.scopeApply();
                }
            });
            this.hibridacoes = new Array();
            const promiseHibridacoes = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.HIBRIDACAO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.HIBRIDACAO)
                .filtro({})
                .listar();
            promiseHibridacoes.then((resposta) => {
                ctrl.hibridacoes.splice(0, ctrl.hibridacoes.length);
                if ((typeof resposta !== 'undefined') && resposta) {
                    ctrl.hibridacoes.push(...resposta);
                }
                console.log('Hibridações carregadas: ' + ctrl.hibridacoes.length);
                ctrl.scopeApply();
            });
            this.estagiosFloraisFemininos = new Array();
            const promiseEstagiosFloraisFemininos = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESTAGIO_FLORAL_FEMININO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESTAGIO_FLORAL_FEMININO)
                .filtro({})
                .listar();
            promiseEstagiosFloraisFemininos.then((resposta) => {
                ctrl.estagiosFloraisFemininos.splice(0, ctrl.estagiosFloraisFemininos.length);
                if ((typeof resposta !== 'undefined') && resposta) {
                    ctrl.estagiosFloraisFemininos.push(...resposta);
                }
                console.log('Estágios Florais Femininos carregados: ' + ctrl.estagiosFloraisFemininos.length);
                ctrl.scopeApply();
            });
            this.estagiosFloraisMasculinos = new Array();
            const promiseEstagiosFloraisMasculinos = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESTAGIO_FLORAL_MASCULINO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESTAGIO_FLORAL_MASCULINO)
                .filtro({})
                .listar();
            promiseEstagiosFloraisMasculinos.then((resposta) => {
                ctrl.estagiosFloraisMasculinos.splice(0, ctrl.estagiosFloraisMasculinos.length);
                if ((typeof resposta !== 'undefined') && resposta) {
                    ctrl.estagiosFloraisMasculinos.push(...resposta);
                }
                console.log('Estágios Florais Masculinos carregados: ' + ctrl.estagiosFloraisMasculinos.length);
                ctrl.scopeApply();
            });
            this.estagiosFloraisEucalipto = new Array();
            const promiseEstagiosFloraisEucalipto = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESTAGIO_FLORAL_EUCALIPTO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESTAGIO_FLORAL_EUCALIPTO)
                .filtro({})
                .listar();
            promiseEstagiosFloraisEucalipto.then((resposta) => {
                ctrl.estagiosFloraisEucalipto.splice(0, ctrl.estagiosFloraisEucalipto.length);
                if ((typeof resposta !== 'undefined') && resposta) {
                    ctrl.estagiosFloraisEucalipto.push(...resposta);
                }
                console.log('Estágios Florais Eucalipto carregados: ' + ctrl.estagiosFloraisEucalipto.length);
                ctrl.scopeApply();
            });
            this.maturacoesFrutos = new Array();
            const promiseMaturacoesFrutos = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.MATURACAO_FRUTO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MATURACAO_FRUTO)
                .filtro({})
                .listar();
            promiseMaturacoesFrutos.then((resposta) => {
                ctrl.maturacoesFrutos.splice(0, ctrl.maturacoesFrutos.length);
                if ((typeof resposta !== 'undefined') && resposta) {
                    ctrl.maturacoesFrutos.push(...resposta);
                }
                console.log('Maturações Frutos carregadas: ' + ctrl.maturacoesFrutos.length);
                ctrl.scopeApply();
            });
            ctrl.unidadesManejo = new Array();
            const promiseUnidadesManejo = ctrl.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.UNIDADE_MANEJO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.UNIDADE_MANEJO)
                .filtro({})
                .listar();
            promiseUnidadesManejo.then((resposta) => {
                ctrl.unidadesManejo.splice(0, ctrl.unidadesManejo.length);
                ctrl.unidadesManejo.push(...resposta);
                console.log('Unidades Manejo carregadas: ' + ctrl.unidadesManejo.length);
                ctrl.scopeApply();
            });
            ctrl.produtosFertilizantes = new Array();
            const promiseProdutosFertilizantes = ctrl.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                .filtro({})
                .listar();
            promiseProdutosFertilizantes.then((resposta) => {
                ctrl.produtosFertilizantes.splice(0, ctrl.produtosFertilizantes.length);
                ctrl.produtosFertilizantes.push(...resposta);
                console.log('Produtos Fertilizantes carregados: ' + ctrl.produtosFertilizantes.length);
                ctrl.scopeApply();
            });
            ctrl.tiposDelineamentos = new Array();
            const promiseTiposDelineamentos = ctrl.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.TIPO_DELINEAMENTO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TIPO_DELINEAMENTO)
                .filtro({})
                .listar();
            promiseTiposDelineamentos.then((resposta) => {
                ctrl.tiposDelineamentos.splice(0, ctrl.tiposDelineamentos.length);
                ctrl.tiposDelineamentos.push(...resposta);
                console.log('Tipos Delineamentos carregados: ' + ctrl.tiposDelineamentos.length);
                ctrl.scopeApply();
            });
            ctrl.disposicoesIndividuos = new Array();
            const promiseDisposicaoIndividuos = ctrl.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.DISPOSICAO_INDIVIDUOS)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.DISPOSICAO_INDIVIDUOS)
                .filtro({})
                .listar();
            promiseDisposicaoIndividuos.then((resposta) => {
                ctrl.disposicoesIndividuos.splice(0, ctrl.disposicoesIndividuos.length);
                ctrl.disposicoesIndividuos.push(...resposta);
                console.log('Disposições Indivíduos carregadas: ' + ctrl.disposicoesIndividuos.length);
                ctrl.scopeApply();
            });
            ctrl.classificacoesClimaticas = new Array();
            const promiseClassificacoesClimaticas = ctrl.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.CLASSIFICACAO_CLIMATICA)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CLASSIFICACAO_CLIMATICA)
                .filtro({})
                .listar();
            promiseClassificacoesClimaticas.then((resposta) => {
                ctrl.classificacoesClimaticas.splice(0, ctrl.classificacoesClimaticas.length);
                ctrl.classificacoesClimaticas.push(...resposta);
                console.log('Classificações Climáticas carregadas: ' + ctrl.classificacoesClimaticas.length);
                ctrl.scopeApply();
            });
            ctrl.materiaisGeneticos = new Array();
            ctrl.materiaisGeneticosFiltrados = new Array();
            ctrl.listaPolensFiltrados = new Array();
            ctrl.materiaisGeneticosCarregando = true;
            const promiseMateriaisGeneticos = ctrl.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MATERIAL_GENETICO)
                .filtro({ permiteDadosOffline: true })
                .listar();
            promiseMateriaisGeneticos.then((resposta) => {
                ctrl.materiaisGeneticos.splice(0, ctrl.materiaisGeneticos.length);
                ctrl.materiaisGeneticos.push(...resposta);
                ctrl.filtraMateriaisGeneticos();
                ctrl.materiaisGeneticosCarregando = false;
                ctrl.scopeApply();
            });
            this.listasFotos.carregarDados(this.sincronia, {
                controleManejoTesteGenetico: null,
                anexoTesteGenetico: null,
                protocoloTesteGenetico: null
            });
            setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                ctrl.listaFuncionariosCarregando = true;
                ctrl.listaFuncionarios = new Array();
                const promiseFuncionarios = ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.FUNCIONARIO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.FUNCIONARIO)
                    .filtro({ permiteDadosOffline: true })
                    .listar();
                promiseFuncionarios.then((resposta) => {
                    ctrl.listaFuncionarios.splice(0, ctrl.listaFuncionarios.length);
                    ctrl.listaFuncionarios.push(...resposta);
                    ctrl.listaFuncionariosCarregando = false;
                    ctrl.scopeApply();
                });
                ctrl.operacoesManejoTesteGenetico = new Array();
                const promiseOperacoesManejoTesteGenetico = ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.OPERACAO_MANEJO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.OPERACAO_MANEJO_POMAR)
                    .filtro({})
                    .listar();
                promiseOperacoesManejoTesteGenetico.then((resposta) => {
                    ctrl.operacoesManejoTesteGenetico.splice(0, ctrl.operacoesManejoTesteGenetico.length);
                    if ((typeof resposta !== 'undefined') && resposta && (resposta.length > 0)) {
                        angular.forEach(resposta, (operacaoManejoTesteCampo) => {
                            if ((typeof operacaoManejoTesteCampo.tipoOperacao !== 'undefined') && operacaoManejoTesteCampo.tipoOperacao) {
                                operacaoManejoTesteCampo.ehOperacaoLimpeza = (operacaoManejoTesteCampo.tipoOperacao === OperacaoManejoTesteCampo_1.OperacaoManejoTesteCampo.OPERACAO_LIMPEZA);
                                operacaoManejoTesteCampo.ehOperacaoDesbaste = (operacaoManejoTesteCampo.tipoOperacao === OperacaoManejoTesteCampo_1.OperacaoManejoTesteCampo.OPERACAO_DESBASTE);
                                operacaoManejoTesteCampo.ehOperacaoPolinizacao = (operacaoManejoTesteCampo.tipoOperacao === OperacaoManejoTesteCampo_1.OperacaoManejoTesteCampo.OPERACAO_POLINIZACAO);
                                operacaoManejoTesteCampo.ehOperacaoColetaAmostras = (operacaoManejoTesteCampo.tipoOperacao === OperacaoManejoTesteCampo_1.OperacaoManejoTesteCampo.OPERACAO_COLETA_AMOSTRAS);
                                operacaoManejoTesteCampo.ehOperacaoColetaSementes = (operacaoManejoTesteCampo.tipoOperacao === OperacaoManejoTesteCampo_1.OperacaoManejoTesteCampo.OPERACAO_COLETA_SEMENTES);
                                operacaoManejoTesteCampo.ehOperacaoControleDoencas = (operacaoManejoTesteCampo.tipoOperacao === OperacaoManejoTesteCampo_1.OperacaoManejoTesteCampo.OPERACAO_CONTROLE_DOENCAS);
                                operacaoManejoTesteCampo.ehOperacaoAdubacaoFertilizacao = (operacaoManejoTesteCampo.tipoOperacao === OperacaoManejoTesteCampo_1.OperacaoManejoTesteCampo.OPERACAO_ADUBACAO_FERTILIZACAO);
                                operacaoManejoTesteCampo.ehOperacaoPodaRamosPortaEnxerto = (operacaoManejoTesteCampo.tipoOperacao === OperacaoManejoTesteCampo_1.OperacaoManejoTesteCampo.OPERACAO_PODA_RAMOS_PORTA_ENXERTO);
                                operacaoManejoTesteCampo.ehOperacaoAvaliacaoSobrevivencia = (operacaoManejoTesteCampo.tipoOperacao === OperacaoManejoTesteCampo_1.OperacaoManejoTesteCampo.OPERACAO_AVALIACAO_SOBREVIVENCIA);
                                operacaoManejoTesteCampo.ehOperacaoReplantio = (operacaoManejoTesteCampo.tipoOperacao === OperacaoManejoTesteCampo_1.OperacaoManejoTesteCampo.OPERACAO_REPLANTIO);
                                operacaoManejoTesteCampo.ehOutrasOperacoes = (operacaoManejoTesteCampo.tipoOperacao === OperacaoManejoTesteCampo_1.OperacaoManejoTesteCampo.OUTRAS_OPERACOES);
                            }
                        });
                    }
                    ctrl.operacoesManejoTesteGenetico.push(...resposta);
                    console.log('Operações de Manejo carregadas: ' + ctrl.operacoesManejoTesteGenetico.length);
                    ctrl.scopeApply();
                });
                ctrl.situacoesControleManejoTesteGenetico = SituacaoControleManejoTesteCampo_1.SituacaoControleManejoTesteCampo.LISTA();
                ctrl.conferenciasControleManejoTesteGenetico = ConferenciaControleManejoTesteCampo_1.ConferenciaControleManejoTesteCampo.LISTA();
                ctrl.tiposLimpezaTesteGenetico = new Array();
                const promiseTiposLimpezaTesteGenetico = ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TIPO_LIMPEZA_MANEJO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TIPO_LIMPEZA_MANEJO_POMAR)
                    .filtro({})
                    .listar();
                promiseTiposLimpezaTesteGenetico.then((resposta) => {
                    ctrl.tiposLimpezaTesteGenetico.splice(0, ctrl.tiposLimpezaTesteGenetico.length);
                    ctrl.tiposLimpezaTesteGenetico.push(...resposta);
                    console.log('Tipos de Limpeza (Manejo Pomar) carregados: ' + ctrl.tiposLimpezaTesteGenetico.length);
                    ctrl.scopeApply();
                });
                ctrl.produtosLimpezaTesteGenetico = new Array();
                const promiseProdutosLimpezaTesteGenetico = ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                    .filtro({})
                    .listar();
                promiseProdutosLimpezaTesteGenetico.then((resposta) => {
                    ctrl.produtosLimpezaTesteGenetico.splice(0, ctrl.produtosLimpezaTesteGenetico.length);
                    ctrl.produtosLimpezaTesteGenetico.push(...resposta);
                    console.log('Produtos para Limpeza (Manejo Pomar) carregados: ' + ctrl.produtosLimpezaTesteGenetico.length);
                    ctrl.scopeApply();
                });
                ctrl.tiposDesbasteTesteGenetico = new Array();
                const promiseTiposDesbasteTesteGenetico = ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TIPO_DESBASTE_MANEJO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TIPO_DESBASTE_MANEJO_POMAR)
                    .filtro({})
                    .listar();
                promiseTiposDesbasteTesteGenetico.then((resposta) => {
                    ctrl.tiposDesbasteTesteGenetico.splice(0, ctrl.tiposDesbasteTesteGenetico.length);
                    ctrl.tiposDesbasteTesteGenetico.push(...resposta);
                    console.log('Tipos de Desbaste (Manejo Pomar) carregados: ' + ctrl.tiposDesbasteTesteGenetico.length);
                    ctrl.scopeApply();
                });
                ctrl.tiposAmostrasManejoTesteGenetico = new Array();
                const promiseTiposAmostrasManejoTesteGenetico = ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TIPO_AMOSTRA_MANEJO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TIPO_AMOSTRA_MANEJO_POMAR)
                    .filtro({})
                    .listar();
                promiseTiposAmostrasManejoTesteGenetico.then((resposta) => {
                    ctrl.tiposAmostrasManejoTesteGenetico.splice(0, ctrl.tiposAmostrasManejoTesteGenetico.length);
                    ctrl.tiposAmostrasManejoTesteGenetico.push(...resposta);
                    console.log('Tipos de Coleta de Amostras (Manejo Pomar) carregadas: ' + ctrl.tiposAmostrasManejoTesteGenetico.length);
                    ctrl.scopeApply();
                });
                ctrl.finalidadesOperacaoColetaAmostraTesteGenetico = new Array();
                const promiseFinalidadesOperacaoColetaAmostraPomar = ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.FINALIDADE_AMOSTRA_MANEJO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.FINALIDADE_AMOSTRA_MANEJO_POMAR)
                    .filtro({})
                    .listar();
                promiseFinalidadesOperacaoColetaAmostraPomar.then((resposta) => {
                    ctrl.finalidadesOperacaoColetaAmostraTesteGenetico.splice(0, ctrl.finalidadesOperacaoColetaAmostraTesteGenetico.length);
                    ctrl.finalidadesOperacaoColetaAmostraTesteGenetico.push(...resposta);
                    console.log('Finalidades de Coleta de Amostras (Manejo Pomar) carregadas: ' + ctrl.finalidadesOperacaoColetaAmostraTesteGenetico.length);
                    ctrl.scopeApply();
                });
                ctrl.tiposAdubacaoTesteGenetico = new Array();
                const promiseTiposAdubacaoTesteGenetico = ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TIPO_APLICACAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TIPO_APLICACAO)
                    .filtro({})
                    .listar();
                promiseTiposAdubacaoTesteGenetico.then((resposta) => {
                    ctrl.tiposAdubacaoTesteGenetico.splice(0, ctrl.tiposAdubacaoTesteGenetico.length);
                    ctrl.tiposAdubacaoTesteGenetico.push(...resposta);
                    console.log('Tipos de Adubação/Fertilização (Manejo Pomar) carregados: ' + ctrl.tiposAdubacaoTesteGenetico.length);
                    ctrl.scopeApply();
                });
                ctrl.produtosAdubacaoTesteGenetico = new Array();
                const promiseProdutosAdubacaoTesteGenetico = ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                    .filtro({})
                    .listar();
                promiseProdutosAdubacaoTesteGenetico.then((resposta) => {
                    ctrl.produtosAdubacaoTesteGenetico.splice(0, ctrl.produtosAdubacaoTesteGenetico.length);
                    ctrl.produtosAdubacaoTesteGenetico.push(...resposta);
                    console.log('Produtos para Adubação/Fertilização (Manejo Pomar) carregados: ' + ctrl.produtosAdubacaoTesteGenetico.length);
                    ctrl.scopeApply();
                });
                ctrl.tiposDoencasTesteGenetico = new Array();
                const promiseTiposDoencasTesteGenetico = ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRAGA_DOENCA_MANEJO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRAGA_DOENCA_MANEJO_POMAR)
                    .filtro({})
                    .listar();
                promiseTiposDoencasTesteGenetico.then((resposta) => {
                    ctrl.tiposDoencasTesteGenetico.splice(0, ctrl.tiposDoencasTesteGenetico.length);
                    ctrl.tiposDoencasTesteGenetico.push(...resposta);
                    console.log('Tipos de Doenças (Manejo Pomar) carregados: ' + ctrl.tiposDoencasTesteGenetico.length);
                    ctrl.scopeApply();
                });
                ctrl.tiposIntervencaoDoencaTesteGenetico = new Array();
                const promiseTiposIntervencaoDoencaTesteGenetico = ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TIPO_INTERVENCAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TIPO_INTERVENCAO)
                    .filtro({})
                    .listar();
                promiseTiposIntervencaoDoencaTesteGenetico.then((resposta) => {
                    ctrl.tiposIntervencaoDoencaTesteGenetico.splice(0, ctrl.tiposIntervencaoDoencaTesteGenetico.length);
                    ctrl.tiposIntervencaoDoencaTesteGenetico.push(...resposta);
                    console.log('Tipos de Intervenções por Doença (Manejo Pomar) carregados: ' + ctrl.tiposIntervencaoDoencaTesteGenetico.length);
                    ctrl.scopeApply();
                });
                ctrl.produtosControleDoencaTesteGenetico = new Array();
                const promiseProdutosControleDoencaPomar = ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_DEFENSIVO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_DEFENSIVO)
                    .filtro({})
                    .listar();
                promiseProdutosControleDoencaPomar.then((resposta) => {
                    ctrl.produtosControleDoencaTesteGenetico.splice(0, ctrl.produtosControleDoencaTesteGenetico.length);
                    ctrl.produtosControleDoencaTesteGenetico.push(...resposta);
                    console.log('Produtos para Controle de Doenças (Manejo Pomar) carregados: ' + ctrl.produtosControleDoencaTesteGenetico.length);
                    ctrl.scopeApply();
                });
                ctrl.contasDepositoSap = new Array();
                const promiseContaDepositoSap = ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.CONTA_DEPOSITO_SAP)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CONTA_DEPOSITO_SAP)
                    .filtro({})
                    .listar();
                promiseContaDepositoSap.then((resposta) => {
                    ctrl.contasDepositoSap.splice(0, ctrl.contasDepositoSap.length);
                    ctrl.contasDepositoSap.push(...resposta);
                    console.log('Contas Deposito SAP carregados: ' + ctrl.contasDepositoSap.length);
                    ctrl.scopeApply();
                });
                ctrl.centrosCustoSap = new Array();
                const promiseCentroCustoSap = ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.CENTRO_CUSTO_SAP)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CENTRO_CUSTO_SAP)
                    .filtro({})
                    .listar();
                promiseCentroCustoSap.then((resposta) => {
                    ctrl.centrosCustoSap.splice(0, ctrl.centrosCustoSap.length);
                    ctrl.centrosCustoSap.push(...resposta);
                    console.log('Centros Custos SAP carregados: ' + ctrl.centrosCustoSap.length);
                    ctrl.scopeApply();
                });
            }), 1500, this);
            this.$scope.$apply();
        });
    }
    carregarTodosAnos() {
        this.listaAnos = new Array();
        const dataAtual = new Date();
        const anoAtual = dataAtual.getFullYear();
        for (let ano = 1950; ano < anoAtual + 15; ano++) {
            this.listaAnos.push(ano);
        }
    }
    salvar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (!this.modelo) {
                    return;
                }
                if ((!this.modelo.data) || (this.modelo.data === null)) {
                    this.modelo.data = new Date();
                }
                this.modalTratamentoTesteGenetico = false;
                this.modalGerarCroquiTesteGenetico = false;
                this.modalGerarCroquiTesteGeneticoDados = null;
                this.modalImportacaoCroquiTesteGenetico = false;
                this.modalHistoricoCroquiTesteGenetico = false;
                this.modalControleManejoTesteGenetico = false;
                this.modalMedicaoDendrometricaTesteGenetico = false;
                this.modalPlanejamentoMedicaoDendrometricaTesteGenetico = false;
                this.modalPlanilhaMedicaoDendrometricaTesteGenetico = false;
                this.modalSelegenMedicaoDendrometricaTesteGenetico = false;
                this.modalQualidadeMadeiraTesteGenetico = false;
                this.modalAnaliseSelecaoTesteGenetico = false;
                this.modalSumarioAnaliseSelecaoTesteGenetico = false;
                this.modalPlanilhaSelegenAnaliseSelecaoTesteGenetico = false;
                this.modalAnaliseSelegenAnaliseSelecaoTesteGenetico = false;
                this.modalResultadoSelegenAnaliseSelecaoTesteGenetico = false;
                this.modalAnaliseAnoAnaliseSelecaoTesteGenetico = false;
                this.modalAnaliseBlupAnaliseSelecaoTesteGenetico = false;
                this.modalSelecaoGeneticaAnaliseSelecaoTesteGenetico = false;
                this.modalSelecaoCampoAnaliseSelecaoTesteGenetico = false;
                this.modalAnexoAnaliseSelecaoTesteGenetico = false;
                this.modalRelatorioTesteGenetico = false;
                this.modalAnexoTesteGenetico = false;
                this.modalProtocoloTesteGenetico = false;
                this.modalDossieMaterialGenetico = false;
                angular.element('body').css('overflow-y', '');
                if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.BANCO_MELHORAMENTO_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.BANCO_MELHORAMENTO_POMAR)
                        .criar(this.modelo);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.BANCO_MELHORAMENTO_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.BANCO_MELHORAMENTO_POMAR)
                        .atualizar(this.modelo);
                }
                if (!this.usuario) {
                    this.$state.go('sair');
                }
                else {
                    this.$state.go(this.links.LISTAR.rota);
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    visualizar(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const testeCampo = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.BANCO_MELHORAMENTO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.BANCO_MELHORAMENTO_POMAR)
                    .id(id)
                    .ver();
                testeCampo.criacao = new Date(testeCampo.criacao);
                testeCampo.data = testeCampo.data ? new Date(testeCampo.data) : new Date();
                testeCampo.data.setSeconds(0);
                testeCampo.data.setMilliseconds(0);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return testeCampo;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    remover(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const testeCampo = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.BANCO_MELHORAMENTO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.BANCO_MELHORAMENTO_POMAR)
                    .id(id)
                    .remover();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (testeCampo) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const testeCampo = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.BANCO_MELHORAMENTO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.BANCO_MELHORAMENTO_POMAR)
                    .id(this.modelo.id)
                    .restaurar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (testeCampo) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosLista() {
        return __awaiter(this, void 0, void 0, function* () {
            const gridOption = {
                data: [],
                columnDefs: [],
                enableSorting: true,
                controller: this
            };
            return gridOption;
        });
    }
    testeGeneticoSimplificado() {
        return {
            id: (typeof this.testeGeneticoSelecionado.id !== 'undefined') ? this.testeGeneticoSelecionado.id : null,
            uuid: (typeof this.testeGeneticoSelecionado.uuid !== 'undefined') ? this.testeGeneticoSelecionado.uuid : null,
            status: ((typeof this.testeGeneticoSelecionado.status !== 'undefined') && (this.testeGeneticoSelecionado.status !== null)) ? this.testeGeneticoSelecionado.status : true,
            criacao: (typeof this.testeGeneticoSelecionado.criacao !== 'undefined') ? this.testeGeneticoSelecionado.criacao : null,
            data: (typeof this.testeGeneticoSelecionado.data !== 'undefined') ? this.testeGeneticoSelecionado.data : null,
        };
    }
    cellClicked(row) {
        if (row.entity && (typeof row.entity.id !== 'undefined') && row.entity.id) {
            const nomeTabelaTestesGeneticos = 'tabelaTestesGeneticos';
            let ehTabelaTestesGeneticos = false;
            if ((typeof row.gridApi !== 'undefined') && row.gridApi &&
                (typeof row.gridApi.element !== 'undefined') && row.gridApi.element &&
                (angular.element(row.gridApi.element).length > 0)) {
                ehTabelaTestesGeneticos = (angular.element(row.gridApi.element).parents('#' + nomeTabelaTestesGeneticos).length > 0) ? true : false;
            }
            else {
                ehTabelaTestesGeneticos = true;
            }
            if (ehTabelaTestesGeneticos) {
                let gridTestesGeneticosApi = null;
                let gridTestesGeneticosScope = angular.element('#' + nomeTabelaTestesGeneticos).scope();
                if ((typeof gridTestesGeneticosScope.listaGridApi !== 'undefined') && gridTestesGeneticosScope.listaGridApi) {
                    angular.forEach(gridTestesGeneticosScope.listaGridApi, (gridApi) => {
                        const grid = ((typeof gridApi.grid !== 'undefined') && gridApi.grid) ? gridApi.grid : null;
                        if (grid && (typeof grid.element !== 'undefined') && grid.element) {
                            const tabelasEl = angular.element(grid.element).parents('tabela');
                            if (tabelasEl && (tabelasEl.length > 0) && (tabelasEl.first().attr('id') === nomeTabelaTestesGeneticos)) {
                                gridTestesGeneticosApi = gridApi;
                            }
                        }
                    });
                }
                if (gridTestesGeneticosApi && (typeof gridTestesGeneticosApi.selection !== 'undefined') && gridTestesGeneticosApi.selection) {
                    gridTestesGeneticosApi.selection.selectRow(row.entity);
                }
            }
        }
    }
    dadosListaTestesGeneticos() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield TesteCampoDadosListaUtil_1.TesteCampoDadosListaUtil.dadosListaTestesGeneticos(this);
        });
    }
    dadosListaManejoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield TesteCampoDadosListaUtil_1.TesteCampoDadosListaUtil.dadosListaManejoTesteGenetico(this);
        });
    }
    dadosListaTratamentosTesteGenetico(seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield TesteCampoDadosListaUtil_1.TesteCampoDadosListaUtil.dadosListaTratamentosTesteGenetico(this, seletorCarregamento);
        });
    }
    dadosListaMateriaisGeneticosTratamentosTesteGenetico(seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield TesteCampoDadosListaUtil_1.TesteCampoDadosListaUtil.dadosListaMateriaisGeneticosTratamentosTesteGenetico(this, seletorCarregamento);
        });
    }
    dadosListaClimaAnualTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield TesteCampoDadosListaUtil_1.TesteCampoDadosListaUtil.dadosListaClimaAnualTesteGenetico(this);
        });
    }
    dadosListaMedicaoDendrometricaTesteGenetico(seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield TesteCampoDadosListaUtil_1.TesteCampoDadosListaUtil.dadosListaMedicaoDendrometricaTesteGenetico(this, seletorCarregamento);
        });
    }
    dadosListaLancamentoMedicaoDendrometricaTesteGenetico(seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield TesteCampoDadosListaUtil_1.TesteCampoDadosListaUtil.dadosListaLancamentoMedicaoDendrometricaTesteGenetico(this, seletorCarregamento);
        });
    }
    dadosListaPlanejamentoMedicaoDendrometricaTesteGenetico(seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield TesteCampoDadosListaUtil_1.TesteCampoDadosListaUtil.dadosListaPlanejamentoMedicaoDendrometricaTesteGenetico(this, seletorCarregamento);
        });
    }
    dadosListaQualidadeMadeiraTesteGenetico(seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield TesteCampoDadosListaUtil_1.TesteCampoDadosListaUtil.dadosListaQualidadeMadeiraTesteGenetico(this, seletorCarregamento);
        });
    }
    dadosListaAnaliseSelecaoTesteGenetico(seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield TesteCampoDadosListaUtil_1.TesteCampoDadosListaUtil.dadosListaAnaliseSelecaoTesteGenetico(this, seletorCarregamento);
        });
    }
    dadosListaSumarioAnaliseSelecaoTesteGenetico(seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield TesteCampoDadosListaUtil_1.TesteCampoDadosListaUtil.dadosListaSumarioAnaliseSelecaoTesteGenetico(this, seletorCarregamento);
        });
    }
    dadosListaAnaliseSelegenAnaliseSelecaoTesteGenetico(seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield TesteCampoDadosListaUtil_1.TesteCampoDadosListaUtil.dadosListaAnaliseSelegenAnaliseSelecaoTesteGenetico(this, seletorCarregamento);
        });
    }
    dadosListaRankingResultadoSelegenAnaliseSelecaoTesteGenetico(seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield TesteCampoDadosListaUtil_1.TesteCampoDadosListaUtil.dadosListaRankingResultadoSelegenAnaliseSelecaoTesteGenetico(this, seletorCarregamento);
        });
    }
    dadosListaVariaveisResultadoSelegenAnaliseSelecaoTesteGenetico(seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield TesteCampoDadosListaUtil_1.TesteCampoDadosListaUtil.dadosListaVariaveisResultadoSelegenAnaliseSelecaoTesteGenetico(this, seletorCarregamento);
        });
    }
    dadosListaResultadoSelegenAnaliseSelecaoTesteGenetico(seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield TesteCampoDadosListaUtil_1.TesteCampoDadosListaUtil.dadosListaResultadoSelegenAnaliseSelecaoTesteGenetico(this, seletorCarregamento);
        });
    }
    dadosListaAnaliseAnoAnaliseSelecaoTesteGenetico(seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield TesteCampoDadosListaUtil_1.TesteCampoDadosListaUtil.dadosListaAnaliseAnoAnaliseSelecaoTesteGenetico(this, seletorCarregamento);
        });
    }
    dadosListaParametrosAnaliseBlupAnaliseSelecaoTesteGenetico(seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield TesteCampoDadosListaUtil_1.TesteCampoDadosListaUtil.dadosListaParametrosAnaliseBlupAnaliseSelecaoTesteGenetico(this, seletorCarregamento);
        });
    }
    dadosListaLimitesReferenciaAnaliseBlupAnaliseSelecaoTesteGenetico(seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield TesteCampoDadosListaUtil_1.TesteCampoDadosListaUtil.dadosListaLimitesReferenciaAnaliseBlupAnaliseSelecaoTesteGenetico(this, seletorCarregamento);
        });
    }
    dadosListaAnaliseBlupAnaliseSelecaoTesteGenetico(seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield TesteCampoDadosListaUtil_1.TesteCampoDadosListaUtil.dadosListaAnaliseBlupAnaliseSelecaoTesteGenetico(this, seletorCarregamento);
        });
    }
    dadosListaCriteriosSelecaoGeneticaAnaliseSelecaoTesteGenetico(seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield TesteCampoDadosListaUtil_1.TesteCampoDadosListaUtil.dadosListaCriteriosSelecaoGeneticaAnaliseSelecaoTesteGenetico(this, seletorCarregamento);
        });
    }
    dadosListaLimitesReferenciaSelecaoGeneticaAnaliseSelecaoTesteGenetico(seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield TesteCampoDadosListaUtil_1.TesteCampoDadosListaUtil.dadosListaLimitesReferenciaSelecaoGeneticaAnaliseSelecaoTesteGenetico(this, seletorCarregamento);
        });
    }
    dadosListaSelecaoGeneticaAnaliseSelecaoTesteGenetico(seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield TesteCampoDadosListaUtil_1.TesteCampoDadosListaUtil.dadosListaSelecaoGeneticaAnaliseSelecaoTesteGenetico(this, seletorCarregamento);
        });
    }
    dadosListaCriteriosSelecaoCampoAnaliseSelecaoTesteGenetico(seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield TesteCampoDadosListaUtil_1.TesteCampoDadosListaUtil.dadosListaCriteriosSelecaoCampoAnaliseSelecaoTesteGenetico(this, seletorCarregamento);
        });
    }
    dadosListaLimitesReferenciaSelecaoCampoAnaliseSelecaoTesteGenetico(seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield TesteCampoDadosListaUtil_1.TesteCampoDadosListaUtil.dadosListaLimitesReferenciaSelecaoCampoAnaliseSelecaoTesteGenetico(this, seletorCarregamento);
        });
    }
    dadosListaSelecaoCampoAnaliseSelecaoTesteGenetico(seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield TesteCampoDadosListaUtil_1.TesteCampoDadosListaUtil.dadosListaSelecaoCampoAnaliseSelecaoTesteGenetico(this, seletorCarregamento);
        });
    }
    dadosListaCroquiSelecaoCampoAnaliseSelecaoTesteGenetico(seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield TesteCampoDadosListaUtil_1.TesteCampoDadosListaUtil.dadosListaCroquiSelecaoCampoAnaliseSelecaoTesteGenetico(this, seletorCarregamento);
        });
    }
    dadosListaAnexosTesteGenetico(seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield TesteCampoDadosListaUtil_1.TesteCampoDadosListaUtil.dadosListaAnexosTesteGenetico(this, seletorCarregamento);
        });
    }
    dadosListaProtocolosTesteGenetico(seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield TesteCampoDadosListaUtil_1.TesteCampoDadosListaUtil.dadosListaProtocolosTesteGenetico(this, seletorCarregamento);
        });
    }
    atualizarGraficosClimaAnual() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.timeoutAtualizacaoGraficosClimaAnual !== 'undefined') && (this.timeoutAtualizacaoGraficosClimaAnual !== null)) {
                clearTimeout(this.timeoutAtualizacaoGraficosClimaAnual);
                this.timeoutAtualizacaoGraficosClimaAnual = null;
            }
            this.timeoutAtualizacaoGraficosClimaAnual = setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                if (!ctrl.testeGeneticoSelecionado) {
                    return;
                }
                if ((typeof ctrl.grafico1ClimaAnualTesteGenetico !== 'undefined') && ctrl.grafico1ClimaAnualTesteGenetico) {
                    try {
                        ctrl.grafico1ClimaAnualTesteGenetico.destroy();
                    }
                    catch (ex) {
                        console.log('TesteCampoController.atualizarGraficosClimaAnual() -> ERRO (grafico1ClimaAnualTesteGenetico)');
                        console.log(ex);
                    }
                    ctrl.grafico1ClimaAnualTesteGenetico = null;
                }
                if ((typeof ctrl.grafico2ClimaAnualTesteGenetico !== 'undefined') && ctrl.grafico2ClimaAnualTesteGenetico) {
                    try {
                        ctrl.grafico2ClimaAnualTesteGenetico.destroy();
                    }
                    catch (ex) {
                        console.log('TesteCampoController.atualizarGraficosClimaAnual() -> ERRO (grafico2ClimaAnualTesteGenetico)');
                        console.log(ex);
                    }
                    ctrl.grafico2ClimaAnualTesteGenetico = null;
                }
                for (let cargaGrafico = 1; cargaGrafico <= 10; cargaGrafico++) {
                    setTimeout((ctrl) => {
                        if (ctrl.grafico1ClimaAnualTesteGenetico) {
                            return;
                        }
                        let listaTemperaturasMedias = [];
                        let listaTemperaturasMaximas = [];
                        let listaTemperaturasMinimas = [];
                        if ((typeof ctrl.listaOpcoesClimaAnualTesteGenetico !== 'undefined') && ctrl.listaOpcoesClimaAnualTesteGenetico &&
                            (typeof ctrl.listaOpcoesClimaAnualTesteGenetico.data !== 'undefined') && ctrl.listaOpcoesClimaAnualTesteGenetico.data) {
                            angular.forEach(ctrl.listaOpcoesClimaAnualTesteGenetico.data, (item) => {
                                if ((typeof item.mes !== 'undefined') && item.mes && (item.mes.indexOf('Média') === -1)) {
                                    listaTemperaturasMedias.push((typeof item.temperaturaMedia !== 'undefined') ? item.temperaturaMedia : null);
                                    listaTemperaturasMaximas.push((typeof item.temperaturaMaxima !== 'undefined') ? item.temperaturaMaxima : null);
                                    listaTemperaturasMinimas.push((typeof item.temperaturaMinima !== 'undefined') ? item.temperaturaMinima : null);
                                }
                            });
                        }
                        const grafico1ClimaAnualTesteGeneticoEl = document.getElementById('canvasGrafico1ClimaAnualTesteGenetico');
                        if ((typeof grafico1ClimaAnualTesteGeneticoEl === 'undefined') || !grafico1ClimaAnualTesteGeneticoEl ||
                            (grafico1ClimaAnualTesteGeneticoEl && (grafico1ClimaAnualTesteGeneticoEl.length === 0))) {
                            return;
                        }
                        const grafico1ClimaAnualTesteGeneticoCtx = grafico1ClimaAnualTesteGeneticoEl.getContext('2d');
                        const grafico1ClimaAnualTesteGeneticoDataSets = [{
                                label: 'Média',
                                data: listaTemperaturasMedias,
                                backgroundColor: 'rgba(255,255,255,0)',
                                borderColor: 'rgb(51, 153, 102)',
                                borderWidth: 2,
                                datalabels: {
                                    display: true,
                                    font: {
                                        size: 11
                                    },
                                    padding: {
                                        left: 2,
                                        right: 2
                                    },
                                    backgroundColor: 'rgba(255,255,255,.3)',
                                    textShadowBlur: 2,
                                    textShadowColor: '#fff'
                                }
                            }, {
                                label: 'Máxima',
                                data: listaTemperaturasMaximas,
                                backgroundColor: 'rgba(255,255,255,0)',
                                borderColor: 'rgb(255, 0, 0)',
                                borderWidth: 2,
                                datalabels: {
                                    display: true,
                                    font: {
                                        size: 11
                                    },
                                    padding: {
                                        left: 2,
                                        right: 2
                                    },
                                    backgroundColor: 'rgba(255,255,255,.3)',
                                    textShadowBlur: 2,
                                    textShadowColor: '#fff'
                                }
                            }, {
                                label: 'Mínima',
                                data: listaTemperaturasMinimas,
                                backgroundColor: 'rgba(255,255,255,0)',
                                borderColor: 'rgb(51, 102, 255)',
                                borderWidth: 2,
                                datalabels: {
                                    display: true,
                                    font: {
                                        size: 11
                                    },
                                    padding: {
                                        left: 2,
                                        right: 2
                                    },
                                    backgroundColor: 'rgba(255,255,255,.3)',
                                    textShadowBlur: 2,
                                    textShadowColor: '#fff'
                                }
                            }];
                        ctrl.grafico1ClimaAnualTesteGenetico = new chart_js_1.Chart(grafico1ClimaAnualTesteGeneticoCtx, {
                            type: 'line',
                            data: {
                                labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                                datasets: grafico1ClimaAnualTesteGeneticoDataSets
                            },
                            plugins: [globalThis.ChartDataLabels],
                            options: {
                                title: {
                                    display: true,
                                    position: 'top',
                                    text: 'Temperaturas Médias Mensais (ºC)'
                                },
                                responsive: true,
                                maintainAspectRatio: false,
                                scales: {
                                    gridLines: { offsetGridLines: true },
                                    xAxes: [{
                                            ticks: {
                                                beginAtZero: true
                                            }
                                        }],
                                    yAxes: [{
                                            ticks: {
                                                beginAtZero: true
                                            }
                                        }]
                                }
                            }
                        });
                    }, cargaGrafico * 1000, ctrl);
                }
                for (let cargaGrafico = 1; cargaGrafico <= 10; cargaGrafico++) {
                    setTimeout((ctrl) => {
                        if (ctrl.grafico2ClimaAnualTesteGenetico) {
                            return;
                        }
                        let listaPrecipitacoesMedias = [];
                        let listaPrecipitacoesMaximas = [];
                        let listaPrecipitacoesMinimas = [];
                        if ((typeof ctrl.listaOpcoesClimaAnualTesteGenetico !== 'undefined') && ctrl.listaOpcoesClimaAnualTesteGenetico &&
                            (typeof ctrl.listaOpcoesClimaAnualTesteGenetico.data !== 'undefined') && ctrl.listaOpcoesClimaAnualTesteGenetico.data) {
                            angular.forEach(ctrl.listaOpcoesClimaAnualTesteGenetico.data, (item) => {
                                if ((typeof item.mes !== 'undefined') && item.mes && (item.mes.indexOf('Média') === -1)) {
                                    listaPrecipitacoesMedias.push((typeof item.precipitacaoMedia !== 'undefined') ? item.precipitacaoMedia : null);
                                    listaPrecipitacoesMaximas.push((typeof item.precipitacaoMaxima !== 'undefined') ? item.precipitacaoMaxima : null);
                                    listaPrecipitacoesMinimas.push((typeof item.precipitacaoMinima !== 'undefined') ? item.precipitacaoMinima : null);
                                }
                            });
                        }
                        const grafico2ClimaAnualTesteGeneticoEl = document.getElementById('canvasGrafico2ClimaAnualTesteGenetico');
                        if ((typeof grafico2ClimaAnualTesteGeneticoEl === 'undefined') || !grafico2ClimaAnualTesteGeneticoEl ||
                            (grafico2ClimaAnualTesteGeneticoEl && (grafico2ClimaAnualTesteGeneticoEl.length === 0))) {
                            return;
                        }
                        const grafico2ClimaAnualTesteGeneticoCtx = grafico2ClimaAnualTesteGeneticoEl.getContext('2d');
                        const grafico2ClimaAnualTesteGeneticoDataSets = [{
                                label: 'Média',
                                data: listaPrecipitacoesMedias,
                                backgroundColor: 'rgba(255,255,255,0)',
                                borderColor: 'rgb(51, 153, 102)',
                                borderWidth: 2,
                                datalabels: {
                                    display: true,
                                    font: {
                                        size: 11
                                    },
                                    padding: {
                                        left: 2,
                                        right: 2
                                    },
                                    backgroundColor: 'rgba(255,255,255,.3)',
                                    textShadowBlur: 2,
                                    textShadowColor: '#fff'
                                }
                            }, {
                                label: 'Máxima',
                                data: listaPrecipitacoesMaximas,
                                backgroundColor: 'rgba(255,255,255,0)',
                                borderColor: 'rgb(255, 0, 0)',
                                borderWidth: 2,
                                datalabels: {
                                    display: true,
                                    font: {
                                        size: 11
                                    },
                                    padding: {
                                        left: 2,
                                        right: 2
                                    },
                                    backgroundColor: 'rgba(255,255,255,.3)',
                                    textShadowBlur: 2,
                                    textShadowColor: '#fff'
                                }
                            }, {
                                label: 'Mínima',
                                data: listaPrecipitacoesMinimas,
                                backgroundColor: 'rgba(255,255,255,0)',
                                borderColor: 'rgb(51, 102, 255)',
                                borderWidth: 2,
                                datalabels: {
                                    display: true,
                                    font: {
                                        size: 11
                                    },
                                    padding: {
                                        left: 2,
                                        right: 2
                                    },
                                    backgroundColor: 'rgba(255,255,255,.3)',
                                    textShadowBlur: 2,
                                    textShadowColor: '#fff'
                                }
                            }];
                        ctrl.grafico2ClimaAnualTesteGenetico = new chart_js_1.Chart(grafico2ClimaAnualTesteGeneticoCtx, {
                            type: 'line',
                            data: {
                                labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                                datasets: grafico2ClimaAnualTesteGeneticoDataSets
                            },
                            plugins: [globalThis.ChartDataLabels],
                            options: {
                                title: {
                                    display: true,
                                    position: 'top',
                                    text: 'Precipitação Média Mensal (mm)'
                                },
                                responsive: true,
                                maintainAspectRatio: false,
                                scales: {
                                    gridLines: { offsetGridLines: true },
                                    xAxes: [{
                                            ticks: {
                                                beginAtZero: true
                                            }
                                        }],
                                    yAxes: [{
                                            ticks: {
                                                beginAtZero: true
                                            }
                                        }]
                                }
                            }
                        });
                    }, cargaGrafico * 1000, ctrl);
                }
            }), 250, this);
        });
    }
    filtraTalhoes() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.timeoutFiltroTalhoes !== 'undefined') && (this.timeoutFiltroTalhoes !== null)) {
                clearTimeout(this.timeoutFiltroTalhoes);
                this.timeoutFiltroTalhoes = null;
            }
            this.timeoutFiltroTalhoes = setTimeout((ctrl) => {
                if ((typeof ctrl.talhoes !== 'undefined') && (ctrl.talhoes !== null) && (ctrl.talhoes.length > 0)) {
                    if ((typeof ctrl.talhoesFiltrados !== 'undefined') && (ctrl.talhoesFiltrados !== null)) {
                        ctrl.talhoesFiltrados.splice(0, ctrl.talhoesFiltrados.length);
                    }
                    else {
                        ctrl.talhoesFiltrados = new Array();
                    }
                    if ((typeof ctrl.controleManejoTesteGeneticoSelecionado !== 'undefined') && (ctrl.controleManejoTesteGeneticoSelecionado !== null) &&
                        (typeof ctrl.controleManejoTesteGeneticoSelecionado.regiao !== 'undefined') && (ctrl.controleManejoTesteGeneticoSelecionado.regiao !== null)) {
                        if ((typeof ctrl.controleManejoTesteGeneticoSelecionado.regiao.nome !== 'undefined') && (ctrl.controleManejoTesteGeneticoSelecionado.regiao.nome !== null)) {
                            const s1 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(ctrl.controleManejoTesteGeneticoSelecionado.regiao.nome);
                            angular.forEach(ctrl.talhoes, (talhao) => {
                                const s2 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(talhao.regiaoNome);
                                if (s1 === s2) {
                                    ctrl.talhoesFiltrados.push(talhao);
                                }
                            });
                        }
                    }
                    else if ((typeof ctrl.controleManejoTesteGeneticoSelecionado !== 'undefined') && (ctrl.controleManejoTesteGeneticoSelecionado !== null) &&
                        (typeof ctrl.controleManejoTesteGeneticoSelecionado.regiaoFazenda !== 'undefined') && (ctrl.controleManejoTesteGeneticoSelecionado.regiaoFazenda !== null)) {
                        if ((typeof ctrl.controleManejoTesteGeneticoSelecionado.regiaoFazenda.nome !== 'undefined') && (ctrl.controleManejoTesteGeneticoSelecionado.regiaoFazenda.nome !== null)) {
                            const s1 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(ctrl.controleManejoTesteGeneticoSelecionado.regiaoFazenda.nome);
                            angular.forEach(ctrl.talhoes, (talhao) => {
                                const s2 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(talhao.regiaoNome);
                                if (s1 === s2) {
                                    ctrl.talhoesFiltrados.push(talhao);
                                }
                            });
                        }
                    }
                    else if ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && (ctrl.testeGeneticoSelecionado !== null) &&
                        (typeof ctrl.testeGeneticoSelecionado.regiao !== 'undefined') && (ctrl.testeGeneticoSelecionado.regiao !== null)) {
                        if ((typeof ctrl.testeGeneticoSelecionado.regiao.nome !== 'undefined') && (ctrl.testeGeneticoSelecionado.regiao.nome !== null)) {
                            const s1 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(ctrl.testeGeneticoSelecionado.regiao.nome);
                            angular.forEach(ctrl.talhoes, (talhao) => {
                                const s2 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(talhao.regiaoNome);
                                if (s1 === s2) {
                                    ctrl.talhoesFiltrados.push(talhao);
                                }
                            });
                        }
                    }
                    else if ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && (ctrl.testeGeneticoSelecionado !== null) &&
                        (typeof ctrl.testeGeneticoSelecionado.regiaoFazenda !== 'undefined') && (ctrl.testeGeneticoSelecionado.regiaoFazenda !== null)) {
                        if ((typeof ctrl.testeGeneticoSelecionado.regiaoFazenda.nome !== 'undefined') && (ctrl.testeGeneticoSelecionado.regiaoFazenda.nome !== null)) {
                            const s1 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(ctrl.testeGeneticoSelecionado.regiaoFazenda.nome);
                            angular.forEach(ctrl.talhoes, (talhao) => {
                                const s2 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(talhao.regiaoNome);
                                if (s1 === s2) {
                                    ctrl.talhoesFiltrados.push(talhao);
                                }
                            });
                        }
                    }
                    if ((typeof ctrl.talhoesFiltrados === 'undefined') || !ctrl.talhoesFiltrados || (ctrl.talhoesFiltrados.length === 0)) {
                        ctrl.talhoesFiltrados = new Array();
                        let listaNumTalhoes = new Array();
                        angular.forEach(ctrl.talhoes, (talhao) => {
                            let numTalhao = ((typeof talhao.numero !== 'undefined') && talhao.numero) ? TextoUtil_1.TextoUtil.converteTextoParaReferencia(talhao.numero) :
                                (((typeof talhao.numTalhao !== 'undefined') && talhao.numTalhao) ? TextoUtil_1.TextoUtil.converteTextoParaReferencia(talhao.numTalhao) : '');
                            if (numTalhao && (numTalhao !== '0') && !listaNumTalhoes.includes(numTalhao)) {
                                ctrl.talhoesFiltrados.push(talhao);
                                listaNumTalhoes.push(numTalhao);
                            }
                        });
                    }
                    console.log('Talhões carregados: ' + ctrl.talhoes.length);
                    console.log('Talhões filtrados: ' + ctrl.talhoesFiltrados.length);
                    try {
                        ctrl.scopeApply();
                    }
                    catch (err) {
                        console.log(err);
                    }
                }
            }, 500, this);
        });
    }
    alterarTalhaoTesteGenetico() {
        if ((typeof this.testeGeneticoSelecionado !== 'undefined') && this.testeGeneticoSelecionado &&
            (typeof this.testeGeneticoSelecionado.talhao !== 'undefined') && this.testeGeneticoSelecionado.talhao) {
            if ((typeof this.testeGeneticoSelecionado.talhao.areaTalhao !== 'undefined') && this.testeGeneticoSelecionado.talhao.areaTalhao) {
                const areaTalhao = (typeof this.testeGeneticoSelecionado.talhao.areaTalhao !== 'number')
                    ? parseFloat(this.testeGeneticoSelecionado.talhao.areaTalhao) : this.testeGeneticoSelecionado.talhao.areaTalhao;
                if (areaTalhao > 0.01) {
                    this.testeGeneticoSelecionado.area = areaTalhao;
                    this.testeGeneticoSelecionado.delineamentoTotalArea = areaTalhao;
                }
            }
            this.atualizarMapaTesteGenetico();
            this.calcularDelineamento();
        }
    }
    alterarAnoAnaliseTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.timeoutAtualizacaoAnoAnalise !== 'undefined') && (this.timeoutAtualizacaoAnoAnalise !== null)) {
                clearTimeout(this.timeoutAtualizacaoAnoAnalise);
                this.timeoutAtualizacaoAnoAnalise = null;
            }
            const funcaoOrigemCarregamento = 'alterarAnoAnaliseTesteGenetico';
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card', funcaoOrigemCarregamento);
            this.timeoutAtualizacaoAnoAnalise = setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                try {
                    if ((typeof ctrl !== 'undefined') && ctrl &&
                        (typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado) {
                        ctrl.listaOpcoesMedicaoDendrometricaTesteGenetico = yield ctrl.dadosListaMedicaoDendrometricaTesteGenetico();
                        ctrl.listaOpcoesAnaliseSelecaoTesteGenetico = yield ctrl.dadosListaAnaliseSelecaoTesteGenetico();
                        ctrl.scopeApply();
                    }
                }
                catch (ex) {
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                    console.log('TesteCampoController.alterarAnoAnaliseTesteGenetico() -> ERRO');
                    console.log(ex);
                }
                finally {
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                }
            }), 250, this);
        });
    }
    filtraMateriaisGeneticos() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.timeoutFiltroMateriaisGeneticos !== 'undefined') && (this.timeoutFiltroMateriaisGeneticos !== null)) {
                clearTimeout(this.timeoutFiltroMateriaisGeneticos);
                this.timeoutFiltroMateriaisGeneticos = null;
            }
            this.timeoutFiltroMateriaisGeneticos = setTimeout((ctrl) => {
                ctrl.timeoutFiltroMateriaisGeneticos = null;
                if ((typeof ctrl.materiaisGeneticos !== 'undefined') && (ctrl.materiaisGeneticos !== null) && (ctrl.materiaisGeneticos.length > 0)) {
                    if ((typeof ctrl.materiaisGeneticosFiltrados !== 'undefined') && (ctrl.materiaisGeneticosFiltrados !== null)) {
                        ctrl.materiaisGeneticosFiltrados.splice(0, ctrl.materiaisGeneticosFiltrados.length);
                    }
                    else {
                        ctrl.materiaisGeneticosFiltrados = new Array();
                    }
                    if ((typeof ctrl.listaPolensFiltrados !== 'undefined') && (ctrl.listaPolensFiltrados !== null)) {
                        ctrl.listaPolensFiltrados.splice(0, ctrl.listaPolensFiltrados.length);
                    }
                    else {
                        ctrl.listaPolensFiltrados = new Array();
                    }
                    if ((typeof ctrl.selectizeOptionsMateriaisGeneticos === 'undefined') || (ctrl.selectizeOptionsMateriaisGeneticos === null)) {
                        ctrl.selectizeOptionsMateriaisGeneticos = new Array();
                    }
                    else if (ctrl.selectizeOptionsMateriaisGeneticos.length > 0) {
                        ctrl.selectizeOptionsMateriaisGeneticos.splice(0, ctrl.selectizeOptionsMateriaisGeneticos.length);
                    }
                    if ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && (ctrl.testeGeneticoSelecionado !== null) &&
                        (typeof ctrl.listaOpcoesTratamentosTesteGenetico !== 'undefined') && (ctrl.listaOpcoesTratamentosTesteGenetico !== null) &&
                        (typeof ctrl.listaOpcoesTratamentosTesteGenetico.data !== 'undefined') && (ctrl.listaOpcoesTratamentosTesteGenetico.data !== null)) {
                        let materiaisGeneticosTesteGenetico = new Array();
                        angular.forEach(ctrl.listaOpcoesTratamentosTesteGenetico.data, (tratamento) => {
                            if ((typeof tratamento.materialGenetico !== 'undefined') && tratamento.materialGenetico) {
                                if ((typeof tratamento.materialGenetico.id !== 'undefined') && tratamento.materialGenetico.id) {
                                    let buscaMaterialGenetico = ctrl.materiaisGeneticos.find(x => x.id && (x.id === tratamento.materialGenetico.id));
                                    if ((typeof buscaMaterialGenetico !== 'undefined') && buscaMaterialGenetico) {
                                        ctrl.materiaisGeneticosFiltrados.push(buscaMaterialGenetico);
                                    }
                                }
                                if ((typeof tratamento.materialGenetico.descricao !== 'undefined') && tratamento.materialGenetico.descricao) {
                                    if (materiaisGeneticosTesteGenetico.indexOf(tratamento.materialGenetico.descricao) === -1) {
                                        materiaisGeneticosTesteGenetico.push(tratamento.materialGenetico.descricao);
                                    }
                                }
                            }
                        });
                        angular.forEach(materiaisGeneticosTesteGenetico, (descricaoMaterialGenetico) => {
                            if ((typeof descricaoMaterialGenetico !== 'undefined') && (descricaoMaterialGenetico !== null) && (descricaoMaterialGenetico.length > 0)) {
                                const s1 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(descricaoMaterialGenetico);
                                let achouMaterialGenetico = false;
                                angular.forEach(ctrl.materiaisGeneticos, (materialGenetico) => {
                                    if (!achouMaterialGenetico) {
                                        const s2 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(materialGenetico.descricao);
                                        if (s1 === s2) {
                                            let buscaMaterialGeneticoFiltrado = ctrl.materiaisGeneticosFiltrados.find(x => x.descricao && (x.descricao === materialGenetico.descricao));
                                            if ((typeof buscaMaterialGeneticoFiltrado === 'undefined') || !buscaMaterialGeneticoFiltrado) {
                                                ctrl.materiaisGeneticosFiltrados.push(materialGenetico);
                                            }
                                            achouMaterialGenetico = true;
                                        }
                                    }
                                });
                                if (!achouMaterialGenetico) {
                                    let novoMaterialGenetico = new MaterialGenetico_1.MaterialGenetico();
                                    novoMaterialGenetico.status = true;
                                    novoMaterialGenetico.criacao = new Date();
                                    novoMaterialGenetico.descricao = descricaoMaterialGenetico;
                                    const promiseMaterialGenetico = ctrl.sincronia
                                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO)
                                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MATERIAL_GENETICO)
                                        .criar(novoMaterialGenetico);
                                    promiseMaterialGenetico.then((resposta) => {
                                        if ((typeof resposta.id !== 'undefined') && resposta.id) {
                                            novoMaterialGenetico.id = resposta.id;
                                        }
                                        if ((typeof resposta.uuid !== 'undefined') && resposta.uuid) {
                                            novoMaterialGenetico.uuid = resposta.uuid;
                                        }
                                        if ((typeof resposta.codigo !== 'undefined') && resposta.codigo) {
                                            novoMaterialGenetico.codigo = resposta.codigo;
                                        }
                                        ctrl.materiaisGeneticosFiltrados.push(novoMaterialGenetico);
                                        ctrl.selectizeOptionsMateriaisGeneticos.push({
                                            text: novoMaterialGenetico.descricao,
                                            value: novoMaterialGenetico.id,
                                            $order: ctrl.selectizeOptionsMateriaisGeneticos.length + 1
                                        });
                                        ctrl.scopeApply();
                                    });
                                }
                            }
                        });
                        if (ctrl.materiaisGeneticosFiltrados) {
                            let tmpMateriaisGeneticos = [
                                { text: '(Selecionar Todos)', value: 'TODOS', $order: 1 },
                                { text: '(Desmarcar Todos)', value: 'NENHUM', $order: 2 }
                            ];
                            angular.forEach(ctrl.materiaisGeneticosFiltrados, (materialGenetico) => {
                                tmpMateriaisGeneticos.push({
                                    text: materialGenetico.descricao,
                                    value: materialGenetico.id,
                                    $order: tmpMateriaisGeneticos.length + 1
                                });
                            });
                            if ((typeof ctrl.selectizeOptionsMateriaisGeneticos === 'undefined') || (ctrl.selectizeOptionsMateriaisGeneticos === null)) {
                                ctrl.selectizeOptionsMateriaisGeneticos = new Array();
                            }
                            else if (ctrl.selectizeOptionsMateriaisGeneticos.length > 0) {
                                ctrl.selectizeOptionsMateriaisGeneticos.splice(0, ctrl.selectizeOptionsMateriaisGeneticos.length);
                            }
                            ctrl.selectizeOptionsMateriaisGeneticos.push(...tmpMateriaisGeneticos);
                        }
                        let polens = new Array();
                        angular.forEach(ctrl.listaOpcoesTratamentosTesteGenetico.data, (apontamento) => {
                            if ((typeof apontamento.detalhamento !== 'undefined') && apontamento.detalhamento &&
                                (typeof apontamento.detalhamento.polemDescricao !== 'undefined') && apontamento.detalhamento.polemDescricao) {
                                if (polens.indexOf(apontamento.detalhamento.polemDescricao) === -1) {
                                    polens.push(apontamento.detalhamento.polemDescricao);
                                }
                            }
                        });
                        angular.forEach(polens, (descricaoPolem) => {
                            if ((typeof descricaoPolem !== 'undefined') && (descricaoPolem !== null) && (descricaoPolem.length > 0)) {
                                let tmpSgifPolem = new MaterialGenetico_1.MaterialGenetico();
                                tmpSgifPolem.status = true;
                                tmpSgifPolem.criacao = new Date();
                                tmpSgifPolem.descricao = descricaoPolem;
                                ctrl.listaPolensFiltrados.push(tmpSgifPolem);
                            }
                        });
                    }
                    console.log('Materiais Genéticos carregados: ' + ctrl.materiaisGeneticos.length);
                    console.log('Materiais Genéticos filtrados: ' + ctrl.materiaisGeneticosFiltrados.length);
                    if (!ctrl.materiaisGeneticosFiltrados || (ctrl.materiaisGeneticosFiltrados.length === 0)) {
                        if (ctrl.materiaisGeneticos && (ctrl.materiaisGeneticos.length > 0)) {
                            ctrl.materiaisGeneticosFiltrados.push(...ctrl.materiaisGeneticos);
                            console.log('Materiais Genéticos filtrados #2: ' + ctrl.materiaisGeneticosFiltrados.length);
                        }
                    }
                    try {
                        ctrl.scopeApply();
                    }
                    catch (err) {
                        console.log(err);
                    }
                }
            }, 500, this);
        });
    }
    filtraPilhasCroqui() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.timeoutFiltroPilhasCroqui !== 'undefined') && (this.timeoutFiltroPilhasCroqui !== null)) {
                clearTimeout(this.timeoutFiltroPilhasCroqui);
                this.timeoutFiltroPilhasCroqui = null;
            }
            this.timeoutFiltroPilhasCroqui = setTimeout((ctrl) => {
                ctrl.timeoutFiltroPilhasCroqui = null;
                if ((typeof ctrl.selectizeOptionsPilhasCroqui === 'undefined') || (ctrl.selectizeOptionsPilhasCroqui === null)) {
                    ctrl.selectizeOptionsPilhasCroqui = new Array();
                }
                else if (ctrl.selectizeOptionsPilhasCroqui.length > 0) {
                    ctrl.selectizeOptionsPilhasCroqui.splice(0, ctrl.selectizeOptionsPilhasCroqui.length);
                }
                if ((typeof ctrl.modalGerarCroquiTesteGeneticoDados !== 'undefined') && ctrl.modalGerarCroquiTesteGeneticoDados &&
                    (typeof ctrl.modalGerarCroquiTesteGeneticoDados.quantidadeLinhas !== 'undefined') && ctrl.modalGerarCroquiTesteGeneticoDados.quantidadeLinhas &&
                    (ctrl.modalGerarCroquiTesteGeneticoDados.quantidadeLinhas > 0)) {
                    ctrl.listaPilhasCroqui = PilhaCroqui_1.PilhaCroqui.LISTA(ctrl.modalGerarCroquiTesteGeneticoDados.quantidadeLinhas);
                }
                else {
                    ctrl.listaPilhasCroqui = PilhaCroqui_1.PilhaCroqui.LISTA();
                }
                if ((typeof ctrl.listaPilhasCroqui !== 'undefined') && ctrl.listaPilhasCroqui) {
                    angular.forEach(ctrl.listaPilhasCroqui, (pilhaCroqui) => {
                        ctrl.selectizeOptionsPilhasCroqui.push({
                            text: pilhaCroqui.descricao,
                            value: pilhaCroqui.id,
                            $order: ctrl.selectizeOptionsPilhasCroqui.length + 1
                        });
                    });
                }
                try {
                    ctrl.scopeApply();
                }
                catch (err) {
                    console.log(err);
                }
            }, 500, this);
        });
    }
    formatarNumero(valor, casasDecimais) {
        return NumeroUtil_1.NumeroUtil.formatarNumero(valor, casasDecimais);
    }
    selecionarTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            let ctrl = this;
            if (ctrl && ctrl.constructor && ctrl.constructor.name) {
                if (ctrl.constructor.name === 'TabelaController') {
                    ctrl = ctrl.$scope.$parent.$ctrl;
                }
            }
            ctrl.filtrarTesteGeneticoSelecionado();
            if ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado) {
                if ((typeof ctrl.testeGeneticoSelecionado.localidade !== 'undefined') && ctrl.testeGeneticoSelecionado.localidade) {
                    ctrl.testeGeneticoSelecionado.municipio = ctrl.testeGeneticoSelecionado.localidade.descricao;
                }
            }
        });
    }
    limparFiltroTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            this.testeGeneticoSelecionado = null;
            this.testeGeneticoCarregado = false;
            this.tratamentoTesteGeneticoSelecionado = null;
            this.croquiTesteGeneticoSelecionado = null;
            this.mapaTesteGeneticoSelecionado = null;
            this.importaShapefileSelecionado = null;
            this.mapaLiberado = false;
            this.controleManejoTesteGeneticoSelecionado = null;
            this.medicaoDendrometricaTesteGeneticoSelecionado = null;
            this.planejamentoMedicaoDendrometricaTesteGeneticoSelecionado = null;
            this.planilhaMedicaoDendrometricaTesteGeneticoSelecionado = null;
            this.qualidadeMadeiraTesteGeneticoSelecionado = null;
            this.anexoTesteGeneticoSelecionado = null;
            this.protocoloTesteGeneticoSelecionado = null;
            this.filtroControleManejoTesteGenetico = new FiltroControleManejoTesteGenetico_1.FiltroControleManejoTesteGenetico();
            this.listaOpcoesManejoTesteGeneticoResumido = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesManejoTesteGeneticoDetalhado = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesTratamentosTesteGenetico = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesMateriaisGeneticosTratamentosTesteGenetico = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesClimaAnualTesteGenetico = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesAnexosTesteGenetico = {
                data: [],
                columnDefs: []
            };
            yield this.limparPlanilhasCarregadas();
        });
    }
    filtrarTesteGeneticoSelecionado() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                let testeGenetico = null;
                const nomeTabelaTestesGeneticos = 'tabelaTestesGeneticos';
                let gridTestesGeneticosApi = null;
                let gridTestesGeneticosScope = angular.element('#' + nomeTabelaTestesGeneticos).scope();
                if ((typeof gridTestesGeneticosScope.listaGridApi !== 'undefined') && gridTestesGeneticosScope.listaGridApi) {
                    angular.forEach(gridTestesGeneticosScope.listaGridApi, (gridApi) => {
                        const grid = ((typeof gridApi.grid !== 'undefined') && gridApi.grid) ? gridApi.grid : null;
                        if (grid && (typeof grid.element !== 'undefined') && grid.element) {
                            const tabelasEl = angular.element(grid.element).parents('tabela');
                            if (tabelasEl && (tabelasEl.length > 0) && (tabelasEl.first().attr('id') === nomeTabelaTestesGeneticos)) {
                                gridTestesGeneticosApi = gridApi;
                            }
                        }
                    });
                }
                if (gridTestesGeneticosApi) {
                    const testesGeneticosSelecionados = gridTestesGeneticosApi.selection.getSelectedRows();
                    if ((typeof testesGeneticosSelecionados !== 'undefined') && testesGeneticosSelecionados) {
                        angular.forEach(testesGeneticosSelecionados, (obj) => {
                            if (!testeGenetico) {
                                testeGenetico = obj;
                            }
                        });
                    }
                }
                if (testeGenetico && (typeof testeGenetico.id !== 'undefined') && testeGenetico.id) {
                    if ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                        (typeof ctrl.testeGeneticoSelecionado.id !== 'undefined') && ctrl.testeGeneticoSelecionado.id) {
                        if (ctrl.testeGeneticoSelecionado.id !== testeGenetico.id) {
                            yield ctrl.limparFiltroTesteGenetico();
                            ctrl.testeGeneticoSelecionado = testeGenetico;
                            ctrl.testeGeneticoCarregado = false;
                        }
                    }
                    else {
                        yield ctrl.limparFiltroTesteGenetico();
                        ctrl.testeGeneticoSelecionado = testeGenetico;
                        ctrl.testeGeneticoCarregado = false;
                    }
                }
                else {
                    if (ctrl.testeGeneticoSelecionado || ctrl.testeGeneticoCarregado) {
                        yield ctrl.limparFiltroTesteGenetico();
                        ctrl.testeGeneticoCarregado = false;
                    }
                }
                if ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado) {
                    if ((typeof ctrl.testeGeneticoSelecionado.perfilClimaticoAnual === 'undefined') || !ctrl.testeGeneticoSelecionado.perfilClimaticoAnual) {
                        ctrl.testeGeneticoSelecionado.perfilClimaticoAnual = new PerfilClimaticoTesteGenetico_1.PerfilClimaticoTesteGenetico();
                    }
                }
                ctrl.scopeApply();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
        });
    }
    filtrarTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const ctrl = this;
                yield this.filtrarTesteGeneticoSelecionado();
                if (!this.testeGeneticoCarregado && this.testeGeneticoSelecionado && this.testeGeneticoSelecionado.id) {
                    this.limparPlanilhasCarregadas();
                    this.testeGeneticoSelecionado.anoAnalise = AnoAnaliseTesteGenetico_1.AnoAnaliseTesteGenetico.V_1_ANO().id;
                    if ((typeof this.testeGeneticoSelecionado.genero === 'undefined') || !this.testeGeneticoSelecionado.genero) {
                        let achouGenero = false;
                        if ((typeof this.testeGeneticoSelecionado.generoId !== 'undefined') && this.testeGeneticoSelecionado.generoId &&
                            (typeof this.generos !== 'undefined') && this.generos) {
                            angular.forEach(this.generos, (genero) => {
                                if (!achouGenero && genero && genero.id && (genero.id === ctrl.testeGeneticoSelecionado.generoId)) {
                                    ctrl.testeGeneticoSelecionado.genero = genero;
                                    achouGenero = true;
                                }
                            });
                        }
                        if (!achouGenero) {
                            if ((typeof this.testeGeneticoSelecionado.generoDescricao !== 'undefined') && this.testeGeneticoSelecionado.generoDescricao &&
                                (typeof this.generos !== 'undefined') && this.generos) {
                                angular.forEach(this.generos, (genero) => {
                                    if (!achouGenero && genero && genero.descricao && (genero.descricao === ctrl.testeGeneticoSelecionado.generoDescricao)) {
                                        ctrl.testeGeneticoSelecionado.genero = genero;
                                        achouGenero = true;
                                    }
                                });
                            }
                        }
                        if (!achouGenero) {
                            if ((typeof this.testeGeneticoSelecionado.generoId !== 'undefined') && this.testeGeneticoSelecionado.generoId &&
                                (typeof this.testeGeneticoSelecionado.generoDescricao !== 'undefined') && this.testeGeneticoSelecionado.generoDescricao) {
                                ctrl.testeGeneticoSelecionado.genero = new Genero_1.Genero();
                                ctrl.testeGeneticoSelecionado.genero.id = this.testeGeneticoSelecionado.generoId;
                                ctrl.testeGeneticoSelecionado.genero.codigo = (typeof this.testeGeneticoSelecionado.generoCodigo !== 'undefined') ? this.testeGeneticoSelecionado.generoCodigo : null;
                                ctrl.testeGeneticoSelecionado.genero.descricao = this.testeGeneticoSelecionado.generoDescricao;
                            }
                        }
                    }
                    if ((typeof this.testeGeneticoSelecionado.especie === 'undefined') || !this.testeGeneticoSelecionado.especie) {
                        let achouEspecie = false;
                        if ((typeof this.testeGeneticoSelecionado.especieId !== 'undefined') && this.testeGeneticoSelecionado.especieId &&
                            (typeof this.especies !== 'undefined') && this.especies) {
                            angular.forEach(this.especies, (especie) => {
                                if (!achouEspecie && especie && especie.id && (especie.id === ctrl.testeGeneticoSelecionado.especieId)) {
                                    ctrl.testeGeneticoSelecionado.especie = especie;
                                    achouEspecie = true;
                                }
                            });
                        }
                        if (!achouEspecie) {
                            if ((typeof this.testeGeneticoSelecionado.especieDescricao !== 'undefined') && this.testeGeneticoSelecionado.especieDescricao &&
                                (typeof this.especies !== 'undefined') && this.especies) {
                                angular.forEach(this.especies, (especie) => {
                                    if (!achouEspecie && especie && especie.descricao && (especie.descricao === ctrl.testeGeneticoSelecionado.especieDescricao)) {
                                        ctrl.testeGeneticoSelecionado.especie = especie;
                                        achouEspecie = true;
                                    }
                                });
                            }
                        }
                        if (!achouEspecie) {
                            if ((typeof this.testeGeneticoSelecionado.especieId !== 'undefined') && this.testeGeneticoSelecionado.especieId &&
                                (typeof this.testeGeneticoSelecionado.especieDescricao !== 'undefined') && this.testeGeneticoSelecionado.especieDescricao) {
                                ctrl.testeGeneticoSelecionado.especie = new Especie_1.Especie();
                                ctrl.testeGeneticoSelecionado.especie.id = this.testeGeneticoSelecionado.especieId;
                                ctrl.testeGeneticoSelecionado.especie.codigo = (typeof this.testeGeneticoSelecionado.especieCodigo !== 'undefined') ? this.testeGeneticoSelecionado.especieCodigo : null;
                                ctrl.testeGeneticoSelecionado.especie.descricao = this.testeGeneticoSelecionado.especieDescricao;
                            }
                        }
                    }
                    if ((typeof this.testeGeneticoSelecionado.geracao === 'undefined') || !this.testeGeneticoSelecionado.geracao) {
                        let achouGeracao = false;
                        if ((typeof this.testeGeneticoSelecionado.geracaoId !== 'undefined') && this.testeGeneticoSelecionado.geracaoId &&
                            (typeof this.geracoes !== 'undefined') && this.geracoes) {
                            angular.forEach(this.geracoes, (geracao) => {
                                if (!achouGeracao && geracao && geracao.id && (geracao.id === ctrl.testeGeneticoSelecionado.geracaoId)) {
                                    ctrl.testeGeneticoSelecionado.geracao = geracao;
                                    achouGeracao = true;
                                }
                            });
                        }
                        if (!achouGeracao) {
                            if ((typeof this.testeGeneticoSelecionado.geracaoDescricao !== 'undefined') && this.testeGeneticoSelecionado.geracaoDescricao &&
                                (typeof this.geracoes !== 'undefined') && this.geracoes) {
                                angular.forEach(this.geracoes, (geracao) => {
                                    if (!achouGeracao && geracao && geracao.descricao && (geracao.descricao === ctrl.testeGeneticoSelecionado.geracaoDescricao)) {
                                        ctrl.testeGeneticoSelecionado.geracao = geracao;
                                        achouGeracao = true;
                                    }
                                });
                            }
                        }
                        if (!achouGeracao) {
                            if ((typeof this.testeGeneticoSelecionado.geracaoId !== 'undefined') && this.testeGeneticoSelecionado.geracaoId &&
                                (typeof this.testeGeneticoSelecionado.geracaoDescricao !== 'undefined') && this.testeGeneticoSelecionado.geracaoDescricao) {
                                ctrl.testeGeneticoSelecionado.geracao = new Geracao_1.Geracao();
                                ctrl.testeGeneticoSelecionado.geracao.id = this.testeGeneticoSelecionado.geracaoId;
                                ctrl.testeGeneticoSelecionado.geracao.codigo = (typeof this.testeGeneticoSelecionado.geracaoCodigo !== 'undefined') ? this.testeGeneticoSelecionado.geracaoCodigo : null;
                                ctrl.testeGeneticoSelecionado.geracao.descricao = this.testeGeneticoSelecionado.geracaoDescricao;
                            }
                        }
                    }
                    if ((typeof this.testeGeneticoSelecionado.provinciaUf === 'undefined') || !this.testeGeneticoSelecionado.provinciaUf) {
                        let achouProvinciaUf = false;
                        if ((typeof this.testeGeneticoSelecionado.provinciaUfId !== 'undefined') && this.testeGeneticoSelecionado.provinciaUfId &&
                            (typeof this.provinciasUf !== 'undefined') && this.provinciasUf) {
                            angular.forEach(this.provinciasUf, (provinciaUf) => {
                                if (!achouProvinciaUf && provinciaUf && provinciaUf.id && (provinciaUf.id === ctrl.testeGeneticoSelecionado.provinciaUfId)) {
                                    ctrl.testeGeneticoSelecionado.provinciaUf = provinciaUf;
                                    achouProvinciaUf = true;
                                }
                            });
                        }
                        if (!achouProvinciaUf) {
                            if ((typeof this.testeGeneticoSelecionado.provinciaUfDescricao !== 'undefined') && this.testeGeneticoSelecionado.provinciaUfDescricao &&
                                (typeof this.provinciasUf !== 'undefined') && this.provinciasUf) {
                                angular.forEach(this.provinciasUf, (provinciaUf) => {
                                    if (!achouProvinciaUf && provinciaUf && provinciaUf.descricao && (provinciaUf.descricao === ctrl.testeGeneticoSelecionado.provinciaUfDescricao)) {
                                        ctrl.testeGeneticoSelecionado.provinciaUf = provinciaUf;
                                        achouProvinciaUf = true;
                                    }
                                });
                            }
                        }
                        if (!achouProvinciaUf) {
                            if ((typeof this.testeGeneticoSelecionado.provinciaUfId !== 'undefined') && this.testeGeneticoSelecionado.provinciaUfId &&
                                (typeof this.testeGeneticoSelecionado.provinciaUfDescricao !== 'undefined') && this.testeGeneticoSelecionado.provinciaUfDescricao) {
                                ctrl.testeGeneticoSelecionado.provinciaUf = new ProvinciaUf_1.ProvinciaUf();
                                ctrl.testeGeneticoSelecionado.provinciaUf.id = this.testeGeneticoSelecionado.provinciaUfId;
                                ctrl.testeGeneticoSelecionado.provinciaUf.codigo = (typeof this.testeGeneticoSelecionado.provinciaUfCodigo !== 'undefined') ? this.testeGeneticoSelecionado.provinciaUfCodigo : null;
                                ctrl.testeGeneticoSelecionado.provinciaUf.sigla = (typeof this.testeGeneticoSelecionado.provinciaUfSigla !== 'undefined') ? this.testeGeneticoSelecionado.provinciaUfSigla : null;
                                ctrl.testeGeneticoSelecionado.provinciaUf.descricao = this.testeGeneticoSelecionado.provinciaUfDescricao;
                            }
                        }
                    }
                    if ((typeof this.testeGeneticoSelecionado.pais === 'undefined') || !this.testeGeneticoSelecionado.pais) {
                        let achouPais = false;
                        if ((typeof this.testeGeneticoSelecionado.paisId !== 'undefined') && this.testeGeneticoSelecionado.paisId &&
                            (typeof this.paises !== 'undefined') && this.paises) {
                            angular.forEach(this.paises, (pais) => {
                                if (!achouPais && pais && pais.id && (pais.id === ctrl.testeGeneticoSelecionado.paisId)) {
                                    ctrl.testeGeneticoSelecionado.pais = pais;
                                    achouPais = true;
                                }
                            });
                        }
                        if (!achouPais) {
                            if ((typeof this.testeGeneticoSelecionado.paisDescricao !== 'undefined') && this.testeGeneticoSelecionado.paisDescricao &&
                                (typeof this.paises !== 'undefined') && this.paises) {
                                angular.forEach(this.paises, (pais) => {
                                    if (!achouPais && pais && pais.descricao && (pais.descricao === ctrl.testeGeneticoSelecionado.paisDescricao)) {
                                        ctrl.testeGeneticoSelecionado.pais = pais;
                                        achouPais = true;
                                    }
                                });
                            }
                        }
                        if (!achouPais) {
                            if ((typeof this.testeGeneticoSelecionado.paisId !== 'undefined') && this.testeGeneticoSelecionado.paisId &&
                                (typeof this.testeGeneticoSelecionado.paisDescricao !== 'undefined') && this.testeGeneticoSelecionado.paisDescricao) {
                                ctrl.testeGeneticoSelecionado.pais = new Pais_1.Pais();
                                ctrl.testeGeneticoSelecionado.pais.id = this.testeGeneticoSelecionado.paisId;
                                ctrl.testeGeneticoSelecionado.pais.codigo = (typeof this.testeGeneticoSelecionado.paisCodigo !== 'undefined') ? this.testeGeneticoSelecionado.paisCodigo : null;
                                ctrl.testeGeneticoSelecionado.pais.descricao = this.testeGeneticoSelecionado.paisDescricao;
                            }
                        }
                    }
                    if ((typeof this.testeGeneticoSelecionado.tipoImplantacao === 'undefined') || !this.testeGeneticoSelecionado.tipoImplantacao) {
                        let achouTipoImplantacao = false;
                        if ((typeof this.testeGeneticoSelecionado.tipoImplantacaoId !== 'undefined') && this.testeGeneticoSelecionado.tipoImplantacaoId &&
                            (typeof this.tiposImplantacoes !== 'undefined') && this.tiposImplantacoes) {
                            angular.forEach(this.tiposImplantacoes, (tipoImplantacao) => {
                                if (!achouTipoImplantacao && tipoImplantacao && tipoImplantacao.id && (tipoImplantacao.id === ctrl.testeGeneticoSelecionado.tipoImplantacaoId)) {
                                    ctrl.testeGeneticoSelecionado.tipoImplantacao = tipoImplantacao;
                                    achouTipoImplantacao = true;
                                }
                            });
                        }
                        if (!achouTipoImplantacao) {
                            if ((typeof this.testeGeneticoSelecionado.tipoImplantacaoDescricao !== 'undefined') && this.testeGeneticoSelecionado.tipoImplantacaoDescricao &&
                                (typeof this.tiposImplantacoes !== 'undefined') && this.tiposImplantacoes) {
                                angular.forEach(this.tiposImplantacoes, (tipoImplantacao) => {
                                    if (!achouTipoImplantacao && tipoImplantacao && tipoImplantacao.descricao && (tipoImplantacao.descricao === ctrl.testeGeneticoSelecionado.tipoImplantacaoDescricao)) {
                                        ctrl.testeGeneticoSelecionado.tipoImplantacao = tipoImplantacao;
                                        achouTipoImplantacao = true;
                                    }
                                });
                            }
                        }
                        if (!achouTipoImplantacao) {
                            if ((typeof this.testeGeneticoSelecionado.tipoImplantacaoId !== 'undefined') && this.testeGeneticoSelecionado.tipoImplantacaoId &&
                                (typeof this.testeGeneticoSelecionado.tipoImplantacaoDescricao !== 'undefined') && this.testeGeneticoSelecionado.tipoImplantacaoDescricao) {
                                ctrl.testeGeneticoSelecionado.tipoImplantacao = new TipoImplantacao_1.TipoImplantacao();
                                ctrl.testeGeneticoSelecionado.tipoImplantacao.id = this.testeGeneticoSelecionado.tipoImplantacaoId;
                                ctrl.testeGeneticoSelecionado.tipoImplantacao.codigo = (typeof this.testeGeneticoSelecionado.tipoImplantacaoCodigo !== 'undefined') ? this.testeGeneticoSelecionado.tipoImplantacaoCodigo : null;
                                ctrl.testeGeneticoSelecionado.tipoImplantacao.descricao = this.testeGeneticoSelecionado.tipoImplantacaoDescricao;
                            }
                        }
                    }
                    if ((typeof this.testeGeneticoSelecionado.fonte === 'undefined') || !this.testeGeneticoSelecionado.fonte) {
                        let achouFonte = false;
                        if ((typeof this.testeGeneticoSelecionado.fonteId !== 'undefined') && this.testeGeneticoSelecionado.fonteId &&
                            (typeof this.fontes !== 'undefined') && this.fontes) {
                            angular.forEach(this.fontes, (fonte) => {
                                if (!achouFonte && fonte && fonte.id && (fonte.id === ctrl.testeGeneticoSelecionado.fonteId)) {
                                    ctrl.testeGeneticoSelecionado.fonte = fonte;
                                    achouFonte = true;
                                }
                            });
                        }
                        if (!achouFonte) {
                            if ((typeof this.testeGeneticoSelecionado.fonteDescricao !== 'undefined') && this.testeGeneticoSelecionado.fonteDescricao &&
                                (typeof this.fontes !== 'undefined') && this.fontes) {
                                angular.forEach(this.fontes, (fonte) => {
                                    if (!achouFonte && fonte && fonte.descricao && (fonte.descricao === ctrl.testeGeneticoSelecionado.fonteDescricao)) {
                                        ctrl.testeGeneticoSelecionado.fonte = fonte;
                                        achouFonte = true;
                                    }
                                });
                            }
                        }
                        if (!achouFonte) {
                            if ((typeof this.testeGeneticoSelecionado.fonteId !== 'undefined') && this.testeGeneticoSelecionado.fonteId &&
                                (typeof this.testeGeneticoSelecionado.fonteDescricao !== 'undefined') && this.testeGeneticoSelecionado.fonteDescricao) {
                                ctrl.testeGeneticoSelecionado.fonte = new Fonte_1.Fonte();
                                ctrl.testeGeneticoSelecionado.fonte.id = this.testeGeneticoSelecionado.fonteId;
                                ctrl.testeGeneticoSelecionado.fonte.codigo = (typeof this.testeGeneticoSelecionado.fonteCodigo !== 'undefined') ? this.testeGeneticoSelecionado.fonteCodigo : null;
                                ctrl.testeGeneticoSelecionado.fonte.descricao = this.testeGeneticoSelecionado.fonteDescricao;
                            }
                        }
                    }
                    if ((typeof this.testeGeneticoSelecionado.categoria === 'undefined') || !this.testeGeneticoSelecionado.categoria) {
                        let achouCategoriaPomar = false;
                        if ((typeof this.testeGeneticoSelecionado.categoriaId !== 'undefined') && this.testeGeneticoSelecionado.categoriaId &&
                            (typeof this.categoriasPomares !== 'undefined') && this.categoriasPomares) {
                            angular.forEach(this.categoriasPomares, (categoria) => {
                                if (!achouCategoriaPomar && categoria && categoria.id && (categoria.id === ctrl.testeGeneticoSelecionado.categoriaId)) {
                                    ctrl.testeGeneticoSelecionado.categoria = categoria;
                                    achouCategoriaPomar = true;
                                }
                            });
                        }
                        if (!achouCategoriaPomar) {
                            if ((typeof this.testeGeneticoSelecionado.categoriaDescricao !== 'undefined') && this.testeGeneticoSelecionado.categoriaDescricao &&
                                (typeof this.categoriasPomares !== 'undefined') && this.categoriasPomares) {
                                angular.forEach(this.categoriasPomares, (categoria) => {
                                    if (!achouCategoriaPomar && categoria && categoria.descricao && (categoria.descricao === ctrl.testeGeneticoSelecionado.categoriaDescricao)) {
                                        ctrl.testeGeneticoSelecionado.categoria = categoria;
                                        achouCategoriaPomar = true;
                                    }
                                });
                            }
                        }
                        if (!achouCategoriaPomar) {
                            if ((typeof this.testeGeneticoSelecionado.categoriaId !== 'undefined') && this.testeGeneticoSelecionado.categoriaId &&
                                (typeof this.testeGeneticoSelecionado.categoriaDescricao !== 'undefined') && this.testeGeneticoSelecionado.categoriaDescricao) {
                                ctrl.testeGeneticoSelecionado.categoria = new CategoriaPomar_1.CategoriaPomar();
                                ctrl.testeGeneticoSelecionado.categoria.id = this.testeGeneticoSelecionado.categoriaId;
                                ctrl.testeGeneticoSelecionado.categoria.codigo = (typeof this.testeGeneticoSelecionado.categoriaCodigo !== 'undefined') ? this.testeGeneticoSelecionado.categoriaCodigo : null;
                                ctrl.testeGeneticoSelecionado.categoria.descricao = this.testeGeneticoSelecionado.categoriaDescricao;
                            }
                        }
                    }
                    if ((typeof this.testeGeneticoSelecionado.regiao === 'undefined') || !this.testeGeneticoSelecionado.regiao) {
                        let achouRegiao = false;
                        if ((typeof this.testeGeneticoSelecionado.regiaoId !== 'undefined') && this.testeGeneticoSelecionado.regiaoId &&
                            (typeof this.regioes !== 'undefined') && this.regioes) {
                            angular.forEach(this.regioes, (regiao) => {
                                if (!achouRegiao && regiao && regiao.id && (regiao.id === ctrl.testeGeneticoSelecionado.regiaoId)) {
                                    ctrl.testeGeneticoSelecionado.regiao = regiao;
                                    achouRegiao = true;
                                }
                            });
                        }
                        if (!achouRegiao) {
                            if ((typeof this.testeGeneticoSelecionado.regiaoNome !== 'undefined') && this.testeGeneticoSelecionado.regiaoNome &&
                                (typeof this.regioes !== 'undefined') && this.regioes) {
                                angular.forEach(this.regioes, (regiao) => {
                                    if (!achouRegiao && regiao && regiao.nome && (regiao.nome === ctrl.testeGeneticoSelecionado.regiaoNome)) {
                                        ctrl.testeGeneticoSelecionado.regiao = regiao;
                                        achouRegiao = true;
                                    }
                                });
                            }
                        }
                        if (!achouRegiao) {
                            if ((typeof this.testeGeneticoSelecionado.regiaoNum !== 'undefined') && this.testeGeneticoSelecionado.regiaoNum &&
                                (typeof this.regioes !== 'undefined') && this.regioes) {
                                angular.forEach(this.regioes, (regiao) => {
                                    if (!achouRegiao && regiao && regiao.numRegiao && (regiao.numRegiao === ctrl.testeGeneticoSelecionado.regiaoNum)) {
                                        ctrl.testeGeneticoSelecionado.regiao = regiao;
                                        achouRegiao = true;
                                    }
                                });
                            }
                        }
                        if (!achouRegiao) {
                            if ((typeof this.testeGeneticoSelecionado.regiaoId !== 'undefined') && this.testeGeneticoSelecionado.regiaoId &&
                                (typeof this.testeGeneticoSelecionado.regiaoNome !== 'undefined') && this.testeGeneticoSelecionado.regiaoNome) {
                                ctrl.testeGeneticoSelecionado.regiao = new Regiao_1.Regiao();
                                ctrl.testeGeneticoSelecionado.regiao.id = this.testeGeneticoSelecionado.regiaoId;
                                ctrl.testeGeneticoSelecionado.regiao.sgif_id = (typeof this.testeGeneticoSelecionado.regiaoSgifId !== 'undefined') ? this.testeGeneticoSelecionado.regiaoSgifId : null;
                                ctrl.testeGeneticoSelecionado.regiao.numRegiao = (typeof this.testeGeneticoSelecionado.regiaoNum !== 'undefined') ? this.testeGeneticoSelecionado.regiaoNum : null;
                                ctrl.testeGeneticoSelecionado.regiao.nome = this.testeGeneticoSelecionado.regiaoNome;
                            }
                        }
                    }
                    if ((typeof this.testeGeneticoSelecionado.talhao === 'undefined') || !this.testeGeneticoSelecionado.talhao) {
                        let achouTalhao = false;
                        if ((typeof this.testeGeneticoSelecionado.talhaoId !== 'undefined') && this.testeGeneticoSelecionado.talhaoId &&
                            (typeof this.talhoes !== 'undefined') && this.talhoes) {
                            angular.forEach(this.talhoes, (talhao) => {
                                if (!achouTalhao && talhao && talhao.id && (talhao.id === ctrl.testeGeneticoSelecionado.talhaoId)) {
                                    ctrl.testeGeneticoSelecionado.talhao = talhao;
                                    achouTalhao = true;
                                }
                            });
                        }
                        if (!achouTalhao) {
                            if ((typeof this.testeGeneticoSelecionado.talhaoNumero !== 'undefined') && this.testeGeneticoSelecionado.talhaoNumero &&
                                (typeof this.testeGeneticoSelecionado.regiaoId !== 'undefined') && this.testeGeneticoSelecionado.regiaoId &&
                                (typeof this.talhoes !== 'undefined') && this.talhoes) {
                                angular.forEach(this.talhoes, (talhao) => {
                                    if (!achouTalhao && talhao && talhao.numero && (talhao.numero === ctrl.testeGeneticoSelecionado.talhaoNumero) &&
                                        talhao.regiaoId && (talhao.regiaoId === ctrl.testeGeneticoSelecionado.regiaoId)) {
                                        ctrl.testeGeneticoSelecionado.talhao = talhao;
                                        achouTalhao = true;
                                    }
                                });
                            }
                        }
                        if (!achouTalhao) {
                            if ((typeof this.testeGeneticoSelecionado.talhaoNumero !== 'undefined') && this.testeGeneticoSelecionado.talhaoNumero &&
                                (typeof this.testeGeneticoSelecionado.regiaoNome !== 'undefined') && this.testeGeneticoSelecionado.regiaoNome &&
                                (typeof this.talhoes !== 'undefined') && this.talhoes) {
                                angular.forEach(this.talhoes, (talhao) => {
                                    if (!achouTalhao && talhao && talhao.numero && (talhao.numero === ctrl.testeGeneticoSelecionado.talhaoNumero) &&
                                        talhao.regiaoNome && (talhao.regiaoNome === ctrl.testeGeneticoSelecionado.regiaoNome)) {
                                        ctrl.testeGeneticoSelecionado.talhao = talhao;
                                        achouTalhao = true;
                                    }
                                });
                            }
                        }
                        if (!achouTalhao) {
                            if ((typeof this.testeGeneticoSelecionado.talhaoId !== 'undefined') && this.testeGeneticoSelecionado.talhaoId &&
                                (typeof this.testeGeneticoSelecionado.talhaoNumero !== 'undefined') && this.testeGeneticoSelecionado.talhaoNumero) {
                                ctrl.testeGeneticoSelecionado.talhao = new Talhao_1.Talhao();
                                ctrl.testeGeneticoSelecionado.talhao.id = this.testeGeneticoSelecionado.talhaoId;
                                ctrl.testeGeneticoSelecionado.talhao.sgif_id = (typeof this.testeGeneticoSelecionado.talhaoSgifId !== 'undefined') ? this.testeGeneticoSelecionado.talhaoSgifId : null;
                                ctrl.testeGeneticoSelecionado.talhao.numero = this.testeGeneticoSelecionado.talhaoNumero;
                                if ((typeof ctrl.testeGeneticoSelecionado.regiao !== 'undefined') && ctrl.testeGeneticoSelecionado.regiao) {
                                    ctrl.testeGeneticoSelecionado.talhao.regiao = angular.copy(ctrl.testeGeneticoSelecionado.regiao);
                                }
                            }
                        }
                    }
                    if ((typeof this.testeGeneticoSelecionado.situacao === 'undefined') || !this.testeGeneticoSelecionado.situacao) {
                        let achouSituacaoPomar = false;
                        if ((typeof this.testeGeneticoSelecionado.situacaoId !== 'undefined') && this.testeGeneticoSelecionado.situacaoId &&
                            (typeof this.situacoes !== 'undefined') && this.situacoes) {
                            angular.forEach(this.situacoes, (situacao) => {
                                if (!achouSituacaoPomar && situacao && situacao.id && (situacao.id === ctrl.testeGeneticoSelecionado.situacaoId)) {
                                    ctrl.testeGeneticoSelecionado.situacao = situacao;
                                    achouSituacaoPomar = true;
                                }
                            });
                        }
                        if (!achouSituacaoPomar) {
                            if ((typeof this.testeGeneticoSelecionado.situacaoDescricao !== 'undefined') && this.testeGeneticoSelecionado.situacaoDescricao &&
                                (typeof this.situacoes !== 'undefined') && this.situacoes) {
                                angular.forEach(this.situacoes, (situacao) => {
                                    if (!achouSituacaoPomar && situacao && situacao.descricao && (situacao.descricao === ctrl.testeGeneticoSelecionado.situacaoDescricao)) {
                                        ctrl.testeGeneticoSelecionado.situacao = situacao;
                                        achouSituacaoPomar = true;
                                    }
                                });
                            }
                        }
                        if (!achouSituacaoPomar) {
                            if ((typeof this.testeGeneticoSelecionado.situacaoId !== 'undefined') && this.testeGeneticoSelecionado.situacaoId &&
                                (typeof this.testeGeneticoSelecionado.situacaoDescricao !== 'undefined') && this.testeGeneticoSelecionado.situacaoDescricao) {
                                ctrl.testeGeneticoSelecionado.situacao = new Situacao_1.Situacao();
                                ctrl.testeGeneticoSelecionado.situacao.id = this.testeGeneticoSelecionado.situacaoId;
                                ctrl.testeGeneticoSelecionado.situacao.codigo = (typeof this.testeGeneticoSelecionado.situacaoCodigo !== 'undefined') ? this.testeGeneticoSelecionado.situacaoCodigo : null;
                                ctrl.testeGeneticoSelecionado.situacao.descricao = this.testeGeneticoSelecionado.situacaoDescricao;
                            }
                        }
                    }
                    if ((typeof this.testeGeneticoSelecionado.classificacaoSolo === 'undefined') || !this.testeGeneticoSelecionado.classificacaoSolo) {
                        let achouClassificacaoSoloPomar = false;
                        if ((typeof this.testeGeneticoSelecionado.classificacaoSoloId !== 'undefined') && this.testeGeneticoSelecionado.classificacaoSoloId &&
                            (typeof this.classificacoesSolo !== 'undefined') && this.classificacoesSolo) {
                            angular.forEach(this.classificacoesSolo, (classificacaoSolo) => {
                                if (!achouClassificacaoSoloPomar && classificacaoSolo && classificacaoSolo.id && (classificacaoSolo.id === ctrl.testeGeneticoSelecionado.classificacaoSoloId)) {
                                    ctrl.testeGeneticoSelecionado.classificacaoSolo = classificacaoSolo;
                                    achouClassificacaoSoloPomar = true;
                                }
                            });
                        }
                        if (!achouClassificacaoSoloPomar) {
                            if ((typeof this.testeGeneticoSelecionado.classificacaoSoloDescricao !== 'undefined') && this.testeGeneticoSelecionado.classificacaoSoloDescricao &&
                                (typeof this.classificacoesSolo !== 'undefined') && this.classificacoesSolo) {
                                angular.forEach(this.classificacoesSolo, (classificacaoSolo) => {
                                    if (!achouClassificacaoSoloPomar && classificacaoSolo && classificacaoSolo.descricao && (classificacaoSolo.descricao === ctrl.testeGeneticoSelecionado.classificacaoSoloDescricao)) {
                                        ctrl.testeGeneticoSelecionado.classificacaoSolo = classificacaoSolo;
                                        achouClassificacaoSoloPomar = true;
                                    }
                                });
                            }
                        }
                        if (!achouClassificacaoSoloPomar) {
                            if ((typeof this.testeGeneticoSelecionado.classificacaoSoloId !== 'undefined') && this.testeGeneticoSelecionado.classificacaoSoloId &&
                                (typeof this.testeGeneticoSelecionado.classificacaoSoloDescricao !== 'undefined') && this.testeGeneticoSelecionado.classificacaoSoloDescricao) {
                                ctrl.testeGeneticoSelecionado.classificacaoSolo = new ClassificacaoSolo_1.ClassificacaoSolo();
                                ctrl.testeGeneticoSelecionado.classificacaoSolo.id = this.testeGeneticoSelecionado.classificacaoSoloId;
                                ctrl.testeGeneticoSelecionado.classificacaoSolo.codigo = (typeof this.testeGeneticoSelecionado.classificacaoSoloCodigo !== 'undefined') ? this.testeGeneticoSelecionado.classificacaoSoloCodigo : null;
                                ctrl.testeGeneticoSelecionado.classificacaoSolo.sigla = (typeof this.testeGeneticoSelecionado.classificacaoSoloSigla !== 'undefined') ? this.testeGeneticoSelecionado.classificacaoSoloSigla : null;
                                ctrl.testeGeneticoSelecionado.classificacaoSolo.descricao = this.testeGeneticoSelecionado.classificacaoSoloDescricao;
                            }
                        }
                    }
                    if ((typeof this.testeGeneticoSelecionado.unidadeManejo === 'undefined') || !this.testeGeneticoSelecionado.unidadeManejo) {
                        let achouUnidadeManejoTesteGenetico = false;
                        if ((typeof this.testeGeneticoSelecionado.unidadeManejoId !== 'undefined') && this.testeGeneticoSelecionado.unidadeManejoId &&
                            (typeof this.unidadesManejo !== 'undefined') && this.unidadesManejo) {
                            angular.forEach(this.unidadesManejo, (unidadeManejo) => {
                                if (!achouUnidadeManejoTesteGenetico && unidadeManejo && unidadeManejo.id && (unidadeManejo.id === ctrl.testeGeneticoSelecionado.unidadeManejoId)) {
                                    ctrl.testeGeneticoSelecionado.unidadeManejo = unidadeManejo;
                                    achouUnidadeManejoTesteGenetico = true;
                                }
                            });
                        }
                        if (!achouUnidadeManejoTesteGenetico) {
                            if ((typeof this.testeGeneticoSelecionado.unidadeManejoDescricao !== 'undefined') && this.testeGeneticoSelecionado.unidadeManejoDescricao &&
                                (typeof this.unidadesManejo !== 'undefined') && this.unidadesManejo) {
                                angular.forEach(this.unidadesManejo, (unidadeManejo) => {
                                    if (!achouUnidadeManejoTesteGenetico && unidadeManejo && unidadeManejo.descricao && (unidadeManejo.descricao === ctrl.testeGeneticoSelecionado.unidadeManejoDescricao)) {
                                        ctrl.testeGeneticoSelecionado.unidadeManejo = unidadeManejo;
                                        achouUnidadeManejoTesteGenetico = true;
                                    }
                                });
                            }
                        }
                        if (!achouUnidadeManejoTesteGenetico) {
                            if ((typeof this.testeGeneticoSelecionado.unidadeManejoId !== 'undefined') && this.testeGeneticoSelecionado.unidadeManejoId &&
                                (typeof this.testeGeneticoSelecionado.unidadeManejoDescricao !== 'undefined') && this.testeGeneticoSelecionado.unidadeManejoDescricao) {
                                ctrl.testeGeneticoSelecionado.unidadeManejo = new UnidadeManejo_1.UnidadeManejo();
                                ctrl.testeGeneticoSelecionado.unidadeManejo.id = this.testeGeneticoSelecionado.unidadeManejoId;
                                ctrl.testeGeneticoSelecionado.unidadeManejo.codigo = (typeof this.testeGeneticoSelecionado.unidadeManejoCodigo !== 'undefined') ? this.testeGeneticoSelecionado.unidadeManejoCodigo : null;
                                ctrl.testeGeneticoSelecionado.unidadeManejo.descricao = this.testeGeneticoSelecionado.unidadeManejoDescricao;
                            }
                        }
                    }
                    if ((typeof this.testeGeneticoSelecionado.adubacaoBaseFertilizante === 'undefined') || !this.testeGeneticoSelecionado.adubacaoBaseFertilizante) {
                        let achouAdubacaoBaseFertilizante = false;
                        if ((typeof this.testeGeneticoSelecionado.adubacaoBaseFertilizanteId !== 'undefined') && this.testeGeneticoSelecionado.adubacaoBaseFertilizanteId &&
                            (typeof this.produtosFertilizantes !== 'undefined') && this.produtosFertilizantes) {
                            angular.forEach(this.produtosFertilizantes, (produtoFertilizante) => {
                                if (!achouAdubacaoBaseFertilizante && produtoFertilizante && produtoFertilizante.id && (produtoFertilizante.id === ctrl.testeGeneticoSelecionado.adubacaoBaseFertilizanteId)) {
                                    ctrl.testeGeneticoSelecionado.adubacaoBaseFertilizante = produtoFertilizante;
                                    achouAdubacaoBaseFertilizante = true;
                                }
                            });
                        }
                        if (!achouAdubacaoBaseFertilizante) {
                            if ((typeof this.testeGeneticoSelecionado.adubacaoBaseFertilizanteDescricao !== 'undefined') && this.testeGeneticoSelecionado.adubacaoBaseFertilizanteDescricao &&
                                (typeof this.produtosFertilizantes !== 'undefined') && this.produtosFertilizantes) {
                                angular.forEach(this.produtosFertilizantes, (produtoFertilizante) => {
                                    if (!achouAdubacaoBaseFertilizante && produtoFertilizante && produtoFertilizante.descricao && (produtoFertilizante.descricao === ctrl.testeGeneticoSelecionado.adubacaoBaseFertilizanteDescricao)) {
                                        ctrl.testeGeneticoSelecionado.adubacaoBaseFertilizante = produtoFertilizante;
                                        achouAdubacaoBaseFertilizante = true;
                                    }
                                });
                            }
                        }
                        if (!achouAdubacaoBaseFertilizante) {
                            if ((typeof this.testeGeneticoSelecionado.adubacaoBaseFertilizanteId !== 'undefined') && this.testeGeneticoSelecionado.adubacaoBaseFertilizanteId &&
                                (typeof this.testeGeneticoSelecionado.adubacaoBaseFertilizanteDescricao !== 'undefined') && this.testeGeneticoSelecionado.adubacaoBaseFertilizanteDescricao) {
                                ctrl.testeGeneticoSelecionado.adubacaoBaseFertilizante = new ProdutoFertilizante_1.ProdutoFertilizante();
                                ctrl.testeGeneticoSelecionado.adubacaoBaseFertilizante.id = this.testeGeneticoSelecionado.adubacaoBaseFertilizanteId;
                                ctrl.testeGeneticoSelecionado.adubacaoBaseFertilizante.codigo = (typeof this.testeGeneticoSelecionado.adubacaoBaseFertilizanteCodigo !== 'undefined') ? this.testeGeneticoSelecionado.adubacaoBaseFertilizanteCodigo : null;
                                ctrl.testeGeneticoSelecionado.adubacaoBaseFertilizante.descricao = this.testeGeneticoSelecionado.adubacaoBaseFertilizanteDescricao;
                            }
                        }
                    }
                    if ((typeof this.testeGeneticoSelecionado.adubacaoCoberturaFertilizante === 'undefined') || !this.testeGeneticoSelecionado.adubacaoCoberturaFertilizante) {
                        let achouAdubacaoBaseFertilizante = false;
                        if ((typeof this.testeGeneticoSelecionado.adubacaoCoberturaFertilizanteId !== 'undefined') && this.testeGeneticoSelecionado.adubacaoCoberturaFertilizanteId &&
                            (typeof this.produtosFertilizantes !== 'undefined') && this.produtosFertilizantes) {
                            angular.forEach(this.produtosFertilizantes, (produtoFertilizante) => {
                                if (!achouAdubacaoBaseFertilizante && produtoFertilizante && produtoFertilizante.id && (produtoFertilizante.id === ctrl.testeGeneticoSelecionado.adubacaoCoberturaFertilizanteId)) {
                                    ctrl.testeGeneticoSelecionado.adubacaoCoberturaFertilizante = produtoFertilizante;
                                    achouAdubacaoBaseFertilizante = true;
                                }
                            });
                        }
                        if (!achouAdubacaoBaseFertilizante) {
                            if ((typeof this.testeGeneticoSelecionado.adubacaoCoberturaFertilizanteDescricao !== 'undefined') && this.testeGeneticoSelecionado.adubacaoCoberturaFertilizanteDescricao &&
                                (typeof this.produtosFertilizantes !== 'undefined') && this.produtosFertilizantes) {
                                angular.forEach(this.produtosFertilizantes, (produtoFertilizante) => {
                                    if (!achouAdubacaoBaseFertilizante && produtoFertilizante && produtoFertilizante.descricao && (produtoFertilizante.descricao === ctrl.testeGeneticoSelecionado.adubacaoCoberturaFertilizanteDescricao)) {
                                        ctrl.testeGeneticoSelecionado.adubacaoCoberturaFertilizante = produtoFertilizante;
                                        achouAdubacaoBaseFertilizante = true;
                                    }
                                });
                            }
                        }
                        if (!achouAdubacaoBaseFertilizante) {
                            if ((typeof this.testeGeneticoSelecionado.adubacaoCoberturaFertilizanteId !== 'undefined') && this.testeGeneticoSelecionado.adubacaoCoberturaFertilizanteId &&
                                (typeof this.testeGeneticoSelecionado.adubacaoCoberturaFertilizanteDescricao !== 'undefined') && this.testeGeneticoSelecionado.adubacaoCoberturaFertilizanteDescricao) {
                                ctrl.testeGeneticoSelecionado.adubacaoCoberturaFertilizante = new ProdutoFertilizante_1.ProdutoFertilizante();
                                ctrl.testeGeneticoSelecionado.adubacaoCoberturaFertilizante.id = this.testeGeneticoSelecionado.adubacaoCoberturaFertilizanteId;
                                ctrl.testeGeneticoSelecionado.adubacaoCoberturaFertilizante.codigo = (typeof this.testeGeneticoSelecionado.adubacaoCoberturaFertilizanteCodigo !== 'undefined') ? this.testeGeneticoSelecionado.adubacaoCoberturaFertilizanteCodigo : null;
                                ctrl.testeGeneticoSelecionado.adubacaoCoberturaFertilizante.descricao = this.testeGeneticoSelecionado.adubacaoCoberturaFertilizanteDescricao;
                            }
                        }
                    }
                    if ((typeof this.testeGeneticoSelecionado.tipoDelineamento === 'undefined') || !this.testeGeneticoSelecionado.tipoDelineamento) {
                        let achouTipoDelineamentoPomar = false;
                        if ((typeof this.testeGeneticoSelecionado.tipoDelineamentoId !== 'undefined') && this.testeGeneticoSelecionado.tipoDelineamentoId &&
                            (typeof this.tiposDelineamentos !== 'undefined') && this.tiposDelineamentos) {
                            angular.forEach(this.tiposDelineamentos, (tipoDelineamento) => {
                                if (!achouTipoDelineamentoPomar && tipoDelineamento && tipoDelineamento.id && (tipoDelineamento.id === ctrl.testeGeneticoSelecionado.tipoDelineamentoId)) {
                                    ctrl.testeGeneticoSelecionado.tipoDelineamento = tipoDelineamento;
                                    achouTipoDelineamentoPomar = true;
                                }
                            });
                        }
                        if (!achouTipoDelineamentoPomar) {
                            if ((typeof this.testeGeneticoSelecionado.tipoDelineamentoDescricao !== 'undefined') && this.testeGeneticoSelecionado.tipoDelineamentoDescricao &&
                                (typeof this.tiposDelineamentos !== 'undefined') && this.tiposDelineamentos) {
                                angular.forEach(this.tiposDelineamentos, (tipoDelineamento) => {
                                    if (!achouTipoDelineamentoPomar && tipoDelineamento && tipoDelineamento.descricao && (tipoDelineamento.descricao === ctrl.testeGeneticoSelecionado.tipoDelineamentoDescricao)) {
                                        ctrl.testeGeneticoSelecionado.tipoDelineamento = tipoDelineamento;
                                        achouTipoDelineamentoPomar = true;
                                    }
                                });
                            }
                        }
                        if (!achouTipoDelineamentoPomar) {
                            if ((typeof this.testeGeneticoSelecionado.tipoDelineamentoId !== 'undefined') && this.testeGeneticoSelecionado.tipoDelineamentoId &&
                                (typeof this.testeGeneticoSelecionado.tipoDelineamentoDescricao !== 'undefined') && this.testeGeneticoSelecionado.tipoDelineamentoDescricao) {
                                ctrl.testeGeneticoSelecionado.tipoDelineamento = new TipoDelineamento_1.TipoDelineamento();
                                ctrl.testeGeneticoSelecionado.tipoDelineamento.id = this.testeGeneticoSelecionado.tipoDelineamentoId;
                                ctrl.testeGeneticoSelecionado.tipoDelineamento.codigo = (typeof this.testeGeneticoSelecionado.tipoDelineamentoCodigo !== 'undefined') ? this.testeGeneticoSelecionado.tipoDelineamentoCodigo : null;
                                ctrl.testeGeneticoSelecionado.tipoDelineamento.descricao = this.testeGeneticoSelecionado.tipoDelineamentoDescricao;
                            }
                        }
                    }
                    if ((typeof this.testeGeneticoSelecionado.disposicaoIndividuos === 'undefined') || !this.testeGeneticoSelecionado.disposicaoIndividuos) {
                        let achouDisposicaoIndividuosPomar = false;
                        if ((typeof this.testeGeneticoSelecionado.disposicaoIndividuosId !== 'undefined') && this.testeGeneticoSelecionado.disposicaoIndividuosId &&
                            (typeof this.disposicoesIndividuos !== 'undefined') && this.disposicoesIndividuos) {
                            angular.forEach(this.disposicoesIndividuos, (disposicaoIndividuos) => {
                                if (!achouDisposicaoIndividuosPomar && disposicaoIndividuos && disposicaoIndividuos.id && (disposicaoIndividuos.id === ctrl.testeGeneticoSelecionado.disposicaoIndividuosId)) {
                                    ctrl.testeGeneticoSelecionado.disposicaoIndividuos = disposicaoIndividuos;
                                    achouDisposicaoIndividuosPomar = true;
                                }
                            });
                        }
                        if (!achouDisposicaoIndividuosPomar) {
                            if ((typeof this.testeGeneticoSelecionado.disposicaoIndividuosDescricao !== 'undefined') && this.testeGeneticoSelecionado.disposicaoIndividuosDescricao &&
                                (typeof this.disposicoesIndividuos !== 'undefined') && this.disposicoesIndividuos) {
                                angular.forEach(this.disposicoesIndividuos, (disposicaoIndividuos) => {
                                    if (!achouDisposicaoIndividuosPomar && disposicaoIndividuos && disposicaoIndividuos.descricao && (disposicaoIndividuos.descricao === ctrl.testeGeneticoSelecionado.disposicaoIndividuosDescricao)) {
                                        ctrl.testeGeneticoSelecionado.disposicaoIndividuos = disposicaoIndividuos;
                                        achouDisposicaoIndividuosPomar = true;
                                    }
                                });
                            }
                        }
                        if (!achouDisposicaoIndividuosPomar) {
                            if ((typeof this.testeGeneticoSelecionado.disposicaoIndividuosId !== 'undefined') && this.testeGeneticoSelecionado.disposicaoIndividuosId &&
                                (typeof this.testeGeneticoSelecionado.disposicaoIndividuosDescricao !== 'undefined') && this.testeGeneticoSelecionado.disposicaoIndividuosDescricao) {
                                ctrl.testeGeneticoSelecionado.disposicaoIndividuos = new DisposicaoIndividuos_1.DisposicaoIndividuos();
                                ctrl.testeGeneticoSelecionado.disposicaoIndividuos.id = this.testeGeneticoSelecionado.disposicaoIndividuosId;
                                ctrl.testeGeneticoSelecionado.disposicaoIndividuos.codigo = (typeof this.testeGeneticoSelecionado.disposicaoIndividuosCodigo !== 'undefined') ? this.testeGeneticoSelecionado.disposicaoIndividuosCodigo : null;
                                ctrl.testeGeneticoSelecionado.disposicaoIndividuos.descricao = this.testeGeneticoSelecionado.disposicaoIndividuosDescricao;
                            }
                        }
                    }
                    if ((typeof this.testeGeneticoSelecionado.classificacaoClimatica === 'undefined') || !this.testeGeneticoSelecionado.classificacaoClimatica) {
                        let achouClassificacaoClimaticaPomar = false;
                        if ((typeof this.testeGeneticoSelecionado.classificacaoClimaticaId !== 'undefined') && this.testeGeneticoSelecionado.classificacaoClimaticaId &&
                            (typeof this.classificacoesClimaticas !== 'undefined') && this.classificacoesClimaticas) {
                            angular.forEach(this.classificacoesClimaticas, (classificacaoClimatica) => {
                                if (!achouClassificacaoClimaticaPomar && classificacaoClimatica && classificacaoClimatica.id && (classificacaoClimatica.id === ctrl.testeGeneticoSelecionado.classificacaoClimaticaId)) {
                                    ctrl.testeGeneticoSelecionado.classificacaoClimatica = classificacaoClimatica;
                                    achouClassificacaoClimaticaPomar = true;
                                }
                            });
                        }
                        if (!achouClassificacaoClimaticaPomar) {
                            if ((typeof this.testeGeneticoSelecionado.classificacaoClimaticaDescricao !== 'undefined') && this.testeGeneticoSelecionado.classificacaoClimaticaDescricao &&
                                (typeof this.classificacoesClimaticas !== 'undefined') && this.classificacoesClimaticas) {
                                angular.forEach(this.classificacoesClimaticas, (classificacaoClimatica) => {
                                    if (!achouClassificacaoClimaticaPomar && classificacaoClimatica && classificacaoClimatica.descricao && (classificacaoClimatica.descricao === ctrl.testeGeneticoSelecionado.classificacaoClimaticaDescricao)) {
                                        ctrl.testeGeneticoSelecionado.classificacaoClimatica = classificacaoClimatica;
                                        achouClassificacaoClimaticaPomar = true;
                                    }
                                });
                            }
                        }
                        if (!achouClassificacaoClimaticaPomar) {
                            if ((typeof this.testeGeneticoSelecionado.classificacaoClimaticaId !== 'undefined') && this.testeGeneticoSelecionado.classificacaoClimaticaId &&
                                (typeof this.testeGeneticoSelecionado.classificacaoClimaticaDescricao !== 'undefined') && this.testeGeneticoSelecionado.classificacaoClimaticaDescricao) {
                                ctrl.testeGeneticoSelecionado.classificacaoClimatica = new ClassificacaoClimatica_1.ClassificacaoClimatica();
                                ctrl.testeGeneticoSelecionado.classificacaoClimatica.id = this.testeGeneticoSelecionado.classificacaoClimaticaId;
                                ctrl.testeGeneticoSelecionado.classificacaoClimatica.codigo = (typeof this.testeGeneticoSelecionado.classificacaoClimaticaCodigo !== 'undefined') ? this.testeGeneticoSelecionado.classificacaoClimaticaCodigo : null;
                                ctrl.testeGeneticoSelecionado.classificacaoClimatica.descricao = this.testeGeneticoSelecionado.classificacaoClimaticaDescricao;
                            }
                        }
                    }
                    if ((typeof ctrl.testeGeneticoSelecionado.perfilClimaticoAnual === 'undefined') || !ctrl.testeGeneticoSelecionado.perfilClimaticoAnual) {
                        ctrl.testeGeneticoSelecionado.perfilClimaticoAnual = new PerfilClimaticoTesteGenetico_1.PerfilClimaticoTesteGenetico();
                    }
                    if ((typeof this.testeGeneticoSelecionado.area === 'undefined') || !this.testeGeneticoSelecionado.area) {
                        if ((typeof this.testeGeneticoSelecionado.talhao !== 'undefined') && this.testeGeneticoSelecionado.talhao &&
                            (typeof this.testeGeneticoSelecionado.talhao.areaTalhao !== 'undefined') && this.testeGeneticoSelecionado.talhao.areaTalhao) {
                            this.testeGeneticoSelecionado.area = this.testeGeneticoSelecionado.talhao.areaTalhao;
                            this.testeGeneticoSelecionado.delineamentoTotalArea = this.testeGeneticoSelecionado.talhao.areaTalhao;
                        }
                    }
                    if ((typeof this.testeGeneticoSelecionado.delineamentoTotalArea === 'undefined') || !this.testeGeneticoSelecionado.delineamentoTotalArea) {
                        this.testeGeneticoSelecionado.delineamentoTotalArea = this.testeGeneticoSelecionado.area;
                    }
                    this.filtroControleManejoTesteGenetico = new FiltroControleManejoTesteGenetico_1.FiltroControleManejoTesteGenetico();
                    const listaOpcoesManejoTesteGenetico = yield this.dadosListaManejoTesteGenetico();
                    this.listaOpcoesManejoTesteGeneticoResumido = listaOpcoesManejoTesteGenetico.resumido;
                    this.listaOpcoesManejoTesteGeneticoDetalhado = listaOpcoesManejoTesteGenetico.detalhado;
                    this.listaOpcoesTratamentosTesteGenetico = yield this.dadosListaTratamentosTesteGenetico();
                    this.listaOpcoesMateriaisGeneticosTratamentosTesteGenetico = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesClimaAnualTesteGenetico = yield this.dadosListaClimaAnualTesteGenetico();
                    this.listaOpcoesMedicaoDendrometricaTesteGenetico = yield this.dadosListaMedicaoDendrometricaTesteGenetico();
                    this.listaOpcoesLancamentoMedicaoDendrometricaTesteGenetico = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesPlanejamentoMedicaoDendrometricaTesteGenetico = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesQualidadeMadeiraTesteGenetico = yield this.dadosListaQualidadeMadeiraTesteGenetico();
                    this.listaOpcoesAnaliseSelecaoTesteGenetico = yield this.dadosListaAnaliseSelecaoTesteGenetico();
                    this.listaOpcoesSumarioAnaliseSelecaoTesteGenetico = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesAnaliseSelegenAnaliseSelecaoTesteGenetico = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesRankingResultadoSelegenAnaliseSelecaoTesteGenetico = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesVariaveisResultadoSelegenAnaliseSelecaoTesteGenetico = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesResultadoSelegenAnaliseSelecaoTesteGenetico = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesAnaliseAnoAnaliseSelecaoTesteGenetico = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesParametrosAnaliseBlupAnaliseSelecaoTesteGenetico = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesLimitesReferenciaAnaliseBlupAnaliseSelecaoTesteGenetico = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesAnaliseBlupAnaliseSelecaoTesteGenetico = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesCriteriosSelecaoGeneticaAnaliseSelecaoTesteGenetico = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesLimitesReferenciaSelecaoGeneticaAnaliseSelecaoTesteGenetico = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesSelecaoGeneticaAnaliseSelecaoTesteGenetico = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesCriteriosSelecaoCampoAnaliseSelecaoTesteGenetico = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesLimitesReferenciaSelecaoCampoAnaliseSelecaoTesteGenetico = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesSelecaoCampoAnaliseSelecaoTesteGenetico = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesCroquiSelecaoCampoAnaliseSelecaoTesteGenetico = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesAnexosTesteGenetico = yield this.dadosListaAnexosTesteGenetico();
                    this.listaOpcoesProtocolosTesteGenetico = yield this.dadosListaProtocolosTesteGenetico();
                    yield this.carregarCroquiTesteGenetico();
                    yield this.carregarMapaTesteGenetico();
                    yield this.filtraTalhoes();
                    yield this.filtraMateriaisGeneticos();
                    yield this.calcularDelineamento();
                    this.testeGeneticoCarregado = true;
                    this.scopeApply();
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
        });
    }
    calcularDelineamento() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.testeGeneticoSelecionado === 'undefined') || !this.testeGeneticoSelecionado) {
                return;
            }
            if ((typeof this.testeGeneticoSelecionado.talhao !== 'undefined') && this.testeGeneticoSelecionado.talhao &&
                (typeof this.testeGeneticoSelecionado.talhao.areaTalhao !== 'undefined') && this.testeGeneticoSelecionado.talhao.areaTalhao &&
                (Number(this.testeGeneticoSelecionado.talhao.areaTalhao) > 0)) {
                this.testeGeneticoSelecionado.area = Number(this.testeGeneticoSelecionado.talhao.areaTalhao);
                this.testeGeneticoSelecionado.delineamentoTotalArea = Number(this.testeGeneticoSelecionado.talhao.areaTalhao);
            }
            if ((typeof this.listaTratamentosTesteGenetico !== 'undefined') && this.listaTratamentosTesteGenetico && (this.listaTratamentosTesteGenetico.length > 0)) {
                if ((typeof this.testeGeneticoSelecionado.delineamentoTratamentos === 'undefined') || !this.testeGeneticoSelecionado.delineamentoTratamentos ||
                    (this.listaTratamentosTesteGenetico.length !== this.testeGeneticoSelecionado.delineamentoTratamentos)) {
                    this.testeGeneticoSelecionado.delineamentoTratamentos = this.listaTratamentosTesteGenetico.length;
                }
            }
            if ((typeof this.testeGeneticoSelecionado.delineamentoTratamentos !== 'undefined') && (this.testeGeneticoSelecionado.delineamentoTratamentos !== null) &&
                (typeof this.testeGeneticoSelecionado.delineamentoArvoresPorTratamento !== 'undefined') && (this.testeGeneticoSelecionado.delineamentoArvoresPorTratamento !== null)) {
                const numBlocos = ((typeof this.testeGeneticoSelecionado.numBlocos !== 'undefined') && this.testeGeneticoSelecionado.numBlocos) ? this.testeGeneticoSelecionado.numBlocos : 1;
                this.testeGeneticoSelecionado.delineamentoTotalArvores = NumeroUtil_1.NumeroUtil.arredondarNumero(Number(this.testeGeneticoSelecionado.delineamentoTratamentos) * Number(this.testeGeneticoSelecionado.delineamentoArvoresPorTratamento) * numBlocos, 2);
                this.testeGeneticoSelecionado.delineamentoTotalArvoresAtual = Number(this.testeGeneticoSelecionado.delineamentoTotalArvores);
                if ((typeof this.testeGeneticoSelecionado.area === 'undefined') || !this.testeGeneticoSelecionado.area) {
                    if ((typeof this.testeGeneticoSelecionado.talhao !== 'undefined') && this.testeGeneticoSelecionado.talhao &&
                        (typeof this.testeGeneticoSelecionado.talhao.areaTalhao !== 'undefined') && this.testeGeneticoSelecionado.talhao.areaTalhao) {
                        this.testeGeneticoSelecionado.area = Number(this.testeGeneticoSelecionado.talhao.areaTalhao);
                        this.testeGeneticoSelecionado.delineamentoTotalArea = Number(this.testeGeneticoSelecionado.talhao.areaTalhao);
                    }
                    else {
                        this.testeGeneticoSelecionado.area = 0;
                        this.testeGeneticoSelecionado.delineamentoTotalArea = 0;
                    }
                }
                else {
                    if ((typeof this.testeGeneticoSelecionado.area === 'string')) {
                        this.testeGeneticoSelecionado.area = Number(this.testeGeneticoSelecionado.area);
                    }
                    this.testeGeneticoSelecionado.delineamentoTotalArea = this.testeGeneticoSelecionado.area;
                }
                if ((typeof this.testeGeneticoSelecionado.delineamentoDensidade === 'undefined') || !this.testeGeneticoSelecionado.delineamentoDensidade) {
                    this.testeGeneticoSelecionado.delineamentoDensidade = 0;
                }
                if ((typeof this.testeGeneticoSelecionado.delineamentoTotalArea !== 'undefined') && this.testeGeneticoSelecionado.delineamentoTotalArea) {
                    this.testeGeneticoSelecionado.delineamentoDensidade = NumeroUtil_1.NumeroUtil.arredondarNumero(Number(this.testeGeneticoSelecionado.delineamentoTotalArvoresAtual) / Number(this.testeGeneticoSelecionado.delineamentoTotalArea), 2);
                }
            }
            else {
                this.testeGeneticoSelecionado.delineamentoTotalArvores = 0;
                this.testeGeneticoSelecionado.delineamentoTotalArvoresAtual = 0;
                this.testeGeneticoSelecionado.delineamentoDensidade = 0;
            }
            const delineamentoTratamentosAtual = ((typeof this.testeGeneticoSelecionado.delineamentoTratamentos !== 'undefined') && this.testeGeneticoSelecionado.delineamentoTratamentos) ? this.testeGeneticoSelecionado.delineamentoTratamentos : 0;
            const delineamentoTratamentosAnterior = ((typeof this.testeGeneticoSelecionado.delineamentoTratamentosAnterior !== 'undefined') && this.testeGeneticoSelecionado.delineamentoTratamentosAnterior) ? this.testeGeneticoSelecionado.delineamentoTratamentosAnterior : 0;
            const delineamentoArvoresPorTratamentoAtual = ((typeof this.testeGeneticoSelecionado.delineamentoArvoresPorTratamento !== 'undefined') && this.testeGeneticoSelecionado.delineamentoArvoresPorTratamento) ? this.testeGeneticoSelecionado.delineamentoArvoresPorTratamento : 0;
            const delineamentoArvoresPorTratamentoAnterior = ((typeof this.testeGeneticoSelecionado.delineamentoArvoresPorTratamentoAnterior !== 'undefined') && this.testeGeneticoSelecionado.delineamentoArvoresPorTratamentoAnterior) ? this.testeGeneticoSelecionado.delineamentoArvoresPorTratamentoAnterior : 0;
            const numBlocosAtual = ((typeof this.testeGeneticoSelecionado.numBlocos !== 'undefined') && this.testeGeneticoSelecionado.numBlocos) ? this.testeGeneticoSelecionado.numBlocos : 0;
            const numBlocosAnterior = ((typeof this.testeGeneticoSelecionado.numBlocosAnterior !== 'undefined') && this.testeGeneticoSelecionado.numBlocosAnterior) ? this.testeGeneticoSelecionado.numBlocosAnterior : 0;
            const numParcelasAtual = ((typeof this.testeGeneticoSelecionado.numParcelas !== 'undefined') && this.testeGeneticoSelecionado.numParcelas) ? this.testeGeneticoSelecionado.numParcelas : 0;
            const numParcelasAnterior = ((typeof this.testeGeneticoSelecionado.numParcelasAnterior !== 'undefined') && this.testeGeneticoSelecionado.numParcelasAnterior) ? this.testeGeneticoSelecionado.numParcelasAnterior : 0;
            if ((delineamentoTratamentosAtual !== delineamentoTratamentosAnterior) ||
                (delineamentoArvoresPorTratamentoAtual !== delineamentoArvoresPorTratamentoAnterior) ||
                (numBlocosAtual !== numBlocosAnterior) ||
                (numParcelasAtual !== numParcelasAnterior)) {
                if ((typeof this.timeoutAtualizacaoDelineamento !== 'undefined') && (this.timeoutAtualizacaoDelineamento !== null)) {
                    clearTimeout(this.timeoutAtualizacaoDelineamento);
                    this.timeoutAtualizacaoDelineamento = null;
                }
                this.timeoutAtualizacaoDelineamento = setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                    if ((typeof ctrl !== 'undefined') && ctrl) {
                        ctrl.listaOpcoesMedicaoDendrometricaTesteGenetico = yield ctrl.dadosListaMedicaoDendrometricaTesteGenetico();
                        ctrl.listaOpcoesAnaliseSelecaoTesteGenetico = yield ctrl.dadosListaAnaliseSelecaoTesteGenetico();
                    }
                }), 1000, this);
                this.testeGeneticoSelecionado.delineamentoTratamentosAnterior = delineamentoTratamentosAtual;
                this.testeGeneticoSelecionado.delineamentoArvoresPorTratamentoAnterior = delineamentoArvoresPorTratamentoAtual;
                this.testeGeneticoSelecionado.numBlocosAnterior = numBlocosAtual;
                this.testeGeneticoSelecionado.numParcelasAnterior = numParcelasAtual;
            }
            this.scopeApply();
        });
    }
    limparPlanilhasCarregadas() {
        return __awaiter(this, void 0, void 0, function* () {
            const listaPlanilhas = [
                'planilha_croqui_teste_genetico',
                'planilha_medicao_dendrometrica_teste_genetico',
                'planilha_sumario_analise_selecao_teste_genetico',
                'planilha_resultado_selegen_analise_selecao_teste_genetico',
                'planilha_analise_ano_analise_selecao_teste_genetico',
                'planilha_analise_blup_analise_selecao_teste_genetico',
                'planilha_selecao_campo_analise_selecao_teste_genetico',
                'planilha_selecao_genetica_analise_selecao_teste_genetico'
            ];
            angular.forEach(listaPlanilhas, (nomePlanilha) => {
                if (angular.element('#' + nomePlanilha).length > 0) {
                    try {
                        if ((typeof globalThis.luckysheet !== 'undefined') && globalThis.luckysheet) {
                            globalThis.luckysheet.destroy();
                        }
                        angular.element('#' + nomePlanilha).empty();
                    }
                    catch (ex) {
                        console.log(ex);
                    }
                    finally {
                        angular.element('#' + nomePlanilha).empty();
                    }
                }
            });
        });
    }
    carregarPlanilha(nomePlanilha, dadosPlanilha = null, dadosAnexo = null) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.limparPlanilhasCarregadas();
            }
            catch (ex) {
                console.log(ex);
            }
            setTimeout(() => __awaiter(this, void 0, void 0, function* () {
                LuckysheetUtil_1.LuckysheetUtil.carregarPlanilha(nomePlanilha, dadosPlanilha, dadosAnexo);
            }), 250);
        });
    }
    salvarTratamentoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoTratamentoUtil_1.TesteCampoTratamentoUtil.salvarTratamentoTesteGenetico(this);
        });
    }
    exibirModalTratamentoTesteGenetico(dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoTratamentoUtil_1.TesteCampoTratamentoUtil.exibirModalTratamentoTesteGenetico(this, dados);
        });
    }
    ocultarModalTratamentoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoTratamentoUtil_1.TesteCampoTratamentoUtil.ocultarModalTratamentoTesteGenetico(this);
        });
    }
    adicionarRegistroTratamentoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoTratamentoUtil_1.TesteCampoTratamentoUtil.adicionarRegistroTratamentoTesteGenetico(this);
        });
    }
    removerRegistroTratamentoTesteGenetico(keyTratamento) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoTratamentoUtil_1.TesteCampoTratamentoUtil.removerRegistroTratamentoTesteGenetico(this, keyTratamento);
        });
    }
    moverRegistroTratamentoTesteGeneticoParaCima(keyTratamento) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoTratamentoUtil_1.TesteCampoTratamentoUtil.moverRegistroTratamentoTesteGeneticoParaCima(this, keyTratamento);
        });
    }
    moverRegistroTratamentoTesteGeneticoParaBaixo(keyTratamento) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoTratamentoUtil_1.TesteCampoTratamentoUtil.moverRegistroTratamentoTesteGeneticoParaBaixo(this, keyTratamento);
        });
    }
    alterarTipoTratamentoTestemunhaTratamentoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoTratamentoUtil_1.TesteCampoTratamentoUtil.alterarTipoTratamentoTestemunhaTratamentoTesteGenetico(this);
        });
    }
    selecionarTratamento() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoTratamentoUtil_1.TesteCampoTratamentoUtil.selecionarTratamento(this);
        });
    }
    filtrarTratamentoSelecionado() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoTratamentoUtil_1.TesteCampoTratamentoUtil.filtrarTratamentoSelecionado(this);
        });
    }
    limparFiltroTratamento() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoTratamentoUtil_1.TesteCampoTratamentoUtil.limparFiltroTratamento(this);
        });
    }
    abrirDossieMaterialGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoTratamentoUtil_1.TesteCampoTratamentoUtil.abrirDossieMaterialGenetico(this);
        });
    }
    ocultarDossieMaterialGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoTratamentoUtil_1.TesteCampoTratamentoUtil.ocultarDossieMaterialGenetico(this);
        });
    }
    carregarMapaTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.mapaTesteGeneticoSelecionado = null;
            this.importaShapefileSelecionado = null;
            this.mapaLiberado = null;
            if (!this.testeGeneticoSelecionado || !this.testeGeneticoSelecionado.id) {
                return;
            }
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
            let filtro = {
                listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                testeGeneticoId: ((this.testeGeneticoSelecionado && this.testeGeneticoSelecionado.id) ? this.testeGeneticoSelecionado.id : null)
            };
            let listaMapaTesteGenetico = yield this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.MAPA_TESTE_GENETICO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MAPA_TESTE_GENETICO)
                .filtro(filtro)
                .listar();
            if ((typeof listaMapaTesteGenetico !== 'undefined') && listaMapaTesteGenetico) {
                angular.forEach(listaMapaTesteGenetico, (item) => {
                    if ((typeof item !== 'undefined') && item) {
                        ctrl.mapaTesteGeneticoSelecionado = item;
                    }
                });
            }
            if (!this.mapaTesteGeneticoSelecionado) {
                this.mapaTesteGeneticoSelecionado = new MapaTesteGenetico_1.MapaTesteGenetico();
                this.mapaTesteGeneticoSelecionado.uuid = ArmazenamentoUtil_1.ArmazenamentoUtil.uuid();
                this.mapaTesteGeneticoSelecionado.criacao = new Date();
                this.mapaTesteGeneticoSelecionado.testeGenetico = this.testeGeneticoSelecionado;
                this.mapaTesteGeneticoSelecionado.testeGeneticoCodigo = ((typeof this.testeGeneticoSelecionado.codigo !== 'undefined') && this.testeGeneticoSelecionado.codigo) ? this.testeGeneticoSelecionado.codigo : null;
                this.mapaTesteGeneticoSelecionado.testeGeneticoDescricao = ((typeof this.testeGeneticoSelecionado.descricao !== 'undefined') && this.testeGeneticoSelecionado.descricao) ? this.testeGeneticoSelecionado.descricao :
                    (((typeof this.testeGeneticoSelecionado.descricaoDetalhada !== 'undefined') && this.testeGeneticoSelecionado.descricaoDetalhada) ? this.testeGeneticoSelecionado.descricaoDetalhada : null);
                this.mapaTesteGeneticoSelecionado.titulo = 'Mapa 1 - ' + DataUtil_1.DataUtil.converterDataParaFormatoDDMMYYYY(new Date());
            }
            if ((typeof this.mapaTesteGeneticoSelecionado.testeGeneticoDescricao === 'undefined') || !this.mapaTesteGeneticoSelecionado.testeGeneticoDescricao) {
                this.mapaTesteGeneticoSelecionado.testeGeneticoDescricao = ((typeof this.testeGeneticoSelecionado.descricao !== 'undefined') && this.testeGeneticoSelecionado.descricao) ? this.testeGeneticoSelecionado.descricao :
                    (((typeof this.testeGeneticoSelecionado.descricaoDetalhada !== 'undefined') && this.testeGeneticoSelecionado.descricaoDetalhada) ? this.testeGeneticoSelecionado.descricaoDetalhada : null);
            }
            yield this.atualizarMapaTesteGenetico();
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    atualizarMapaTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.testeGeneticoSelecionado === 'undefined') || !this.testeGeneticoSelecionado) {
                return;
            }
            const talhaoId = (this.testeGeneticoSelecionado.talhao && this.testeGeneticoSelecionado.talhao.id ? this.testeGeneticoSelecionado.talhao.id :
                (this.testeGeneticoSelecionado.talhaoId ? this.testeGeneticoSelecionado.talhaoId : null));
            if ((typeof talhaoId === 'undefined') || !talhaoId) {
                return;
            }
            this.mapaLiberado = false;
            this.scopeApply();
            if ((typeof this.timeoutAtualizacaoMapa !== 'undefined') && (this.timeoutAtualizacaoMapa !== null)) {
                clearTimeout(this.timeoutAtualizacaoMapa);
                this.timeoutAtualizacaoMapa = null;
            }
            this.timeoutAtualizacaoMapa = setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                ctrl.importaShapefileSelecionado = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.IMPORTA_SHAPEFILE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.IMPORTA_SHAPEFILE)
                    .id(talhaoId)
                    .ver();
                if ((typeof ctrl.importaShapefileSelecionado === 'undefined') || !ctrl.importaShapefileSelecionado) {
                    ctrl.importaShapefileSelecionado = new ImportaShapefile_1.ImportaShapefile();
                }
                if ((typeof ctrl.importaShapefileSelecionado.criacao !== 'undefined') && (ctrl.importaShapefileSelecionado.criacao !== null)) {
                    ctrl.importaShapefileSelecionado.criacao = new Date(ctrl.importaShapefileSelecionado.criacao);
                }
                else {
                    ctrl.importaShapefileSelecionado.criacao = new Date();
                }
                if ((typeof ctrl.importaShapefileSelecionado.dataPlantio !== 'undefined') && (ctrl.importaShapefileSelecionado.dataPlantio !== null)) {
                    ctrl.importaShapefileSelecionado.dataPlantio = new Date(ctrl.importaShapefileSelecionado.dataPlantio);
                }
                if ((typeof ctrl.importaShapefileSelecionado.talhao !== 'undefined') && (ctrl.importaShapefileSelecionado.talhao !== null)) {
                    if ((typeof ctrl.importaShapefileSelecionado.talhao.dataPlantio !== 'undefined') && (ctrl.importaShapefileSelecionado.talhao.dataPlantio !== null)) {
                        ctrl.importaShapefileSelecionado.talhao.dataPlantio = new Date(ctrl.importaShapefileSelecionado.talhao.dataPlantio);
                    }
                }
                if ((typeof ctrl.importaShapefileSelecionado.geoJSON !== 'undefined') && (ctrl.importaShapefileSelecionado.geoJSON !== null)) {
                    if (angular.isArray(ctrl.importaShapefileSelecionado.geoJSON) && (ctrl.importaShapefileSelecionado.geoJSON.length > 0)) {
                        setTimeout((controller, importaShapefile) => {
                            controller.mapaLiberado = true;
                            if (importaShapefile.geoJSON && (importaShapefile.geoJSON.length > 0)) {
                                let geoItem = importaShapefile.geoJSON[0];
                                let primeiraCoordenada = null;
                                let segundaCoordenada = null;
                                if ((typeof geoItem.geometry !== 'undefined') && geoItem.geometry &&
                                    (typeof geoItem.geometry.coordinates !== 'undefined') && geoItem.geometry.coordinates) {
                                    angular.forEach(geoItem.geometry.coordinates, (coord1) => {
                                        if (angular.isArray(coord1)) {
                                            angular.forEach(coord1, (coord2) => {
                                                if (angular.isArray(coord2)) {
                                                    angular.forEach(coord2, (coord3) => {
                                                        if (angular.isArray(coord3)) {
                                                            angular.forEach(coord3, (coord4) => {
                                                                if (angular.isArray(coord4)) {
                                                                    angular.forEach(coord4, (coord5) => {
                                                                        if ((typeof coord5 === 'number') || (typeof coord5 === 'string')) {
                                                                            if (primeiraCoordenada === null) {
                                                                                primeiraCoordenada = parseFloat('' + coord5);
                                                                            }
                                                                            else if (segundaCoordenada === null) {
                                                                                segundaCoordenada = parseFloat('' + coord5);
                                                                            }
                                                                        }
                                                                    });
                                                                }
                                                                else if ((typeof coord4 === 'number') || (typeof coord4 === 'string')) {
                                                                    if (primeiraCoordenada === null) {
                                                                        primeiraCoordenada = parseFloat('' + coord4);
                                                                    }
                                                                    else if (segundaCoordenada === null) {
                                                                        segundaCoordenada = parseFloat('' + coord4);
                                                                    }
                                                                }
                                                            });
                                                        }
                                                        else if ((typeof coord3 === 'number') || (typeof coord3 === 'string')) {
                                                            if (primeiraCoordenada === null) {
                                                                primeiraCoordenada = parseFloat('' + coord3);
                                                            }
                                                            else if (segundaCoordenada === null) {
                                                                segundaCoordenada = parseFloat('' + coord3);
                                                            }
                                                        }
                                                    });
                                                }
                                                else if ((typeof coord2 === 'number') || (typeof coord2 === 'string')) {
                                                    if (primeiraCoordenada === null) {
                                                        primeiraCoordenada = parseFloat('' + coord2);
                                                    }
                                                    else if (segundaCoordenada === null) {
                                                        segundaCoordenada = parseFloat('' + coord2);
                                                    }
                                                }
                                            });
                                        }
                                        else if ((typeof coord1 === 'number') || (typeof coord1 === 'string')) {
                                            if (primeiraCoordenada === null) {
                                                primeiraCoordenada = parseFloat('' + coord1);
                                            }
                                            else if (segundaCoordenada === null) {
                                                segundaCoordenada = parseFloat('' + coord1);
                                            }
                                        }
                                    });
                                }
                                if (primeiraCoordenada || segundaCoordenada) {
                                    controller.coordenadaMapa.longitude = primeiraCoordenada;
                                    controller.coordenadaMapa.latitude = segundaCoordenada;
                                    if ((typeof controller.mapaTesteGeneticoSelecionado.latitude === 'undefined') || !controller.mapaTesteGeneticoSelecionado.latitude) {
                                        controller.mapaTesteGeneticoSelecionado.latitude = String(controller.coordenadaMapa.latitude);
                                    }
                                    if ((typeof controller.mapaTesteGeneticoSelecionado.longitude === 'undefined') || !controller.mapaTesteGeneticoSelecionado.longitude) {
                                        controller.mapaTesteGeneticoSelecionado.longitude = String(controller.coordenadaMapa.longitude);
                                    }
                                    controller.geoJsonMapa = geoItem;
                                    controller.scopeApply();
                                }
                            }
                        }, 1000, ctrl, ctrl.importaShapefileSelecionado);
                    }
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }), 1000, this);
        });
    }
    carregarCroquiTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoCroquiUtil_1.TesteCampoCroquiUtil.carregarCroquiTesteGenetico(this);
        });
    }
    carregarPlanilhaCroquiTesteGenetico(ignoraSeCarregado = true) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoCroquiUtil_1.TesteCampoCroquiUtil.carregarPlanilhaCroquiTesteGenetico(this, ignoraSeCarregado);
        });
    }
    exportarCroquiTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoCroquiUtil_1.TesteCampoCroquiUtil.exportarCroquiTesteGenetico(this);
        });
    }
    importarCroquiTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoCroquiUtil_1.TesteCampoCroquiUtil.importarCroquiTesteGenetico(this);
        });
    }
    redimensionarPlanilhaCroquiTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha();
        });
    }
    salvarCroquiTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoCroquiUtil_1.TesteCampoCroquiUtil.salvarCroquiTesteGenetico(this);
        });
    }
    exibirModalGerarCroquiTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoCroquiUtil_1.TesteCampoCroquiUtil.exibirModalGerarCroquiTesteGenetico(this);
        });
    }
    ocultarModalGerarCroquiTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoCroquiUtil_1.TesteCampoCroquiUtil.ocultarModalGerarCroquiTesteGenetico(this);
        });
    }
    salvarImportacaoCroquiTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoCroquiUtil_1.TesteCampoCroquiUtil.salvarImportacaoCroquiTesteGenetico(this);
        });
    }
    exibirModalImportacaoCroquiTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoCroquiUtil_1.TesteCampoCroquiUtil.exibirModalImportacaoCroquiTesteGenetico(this);
        });
    }
    ocultarModalImportacaoCroquiTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoCroquiUtil_1.TesteCampoCroquiUtil.ocultarModalImportacaoCroquiTesteGenetico(this);
        });
    }
    exibirModalHistoricoCroquiTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoCroquiUtil_1.TesteCampoCroquiUtil.exibirModalHistoricoCroquiTesteGenetico(this);
        });
    }
    ocultarModalHistoricoCroquiTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoCroquiUtil_1.TesteCampoCroquiUtil.ocultarModalHistoricoCroquiTesteGenetico(this);
        });
    }
    selecionarHistoricoCroquiTesteGenetico(keyPlanilha) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoCroquiUtil_1.TesteCampoCroquiUtil.selecionarHistoricoCroquiTesteGenetico(this, keyPlanilha);
        });
    }
    salvarInformacoesTesteGenetico() {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!this.testeGeneticoSelecionado || !this.testeGeneticoSelecionado.id) {
                    return;
                }
                this.modalTratamentoTesteGenetico = false;
                this.modalGerarCroquiTesteGenetico = false;
                this.modalGerarCroquiTesteGeneticoDados = null;
                this.modalImportacaoCroquiTesteGenetico = false;
                this.modalHistoricoCroquiTesteGenetico = false;
                this.modalControleManejoTesteGenetico = false;
                this.modalMedicaoDendrometricaTesteGenetico = false;
                this.modalPlanejamentoMedicaoDendrometricaTesteGenetico = false;
                this.modalPlanilhaMedicaoDendrometricaTesteGenetico = false;
                this.modalSelegenMedicaoDendrometricaTesteGenetico = false;
                this.modalQualidadeMadeiraTesteGenetico = false;
                this.modalAnaliseSelecaoTesteGenetico = false;
                this.modalSumarioAnaliseSelecaoTesteGenetico = false;
                this.modalPlanilhaSelegenAnaliseSelecaoTesteGenetico = false;
                this.modalAnaliseSelegenAnaliseSelecaoTesteGenetico = false;
                this.modalResultadoSelegenAnaliseSelecaoTesteGenetico = false;
                this.modalAnaliseAnoAnaliseSelecaoTesteGenetico = false;
                this.modalAnaliseBlupAnaliseSelecaoTesteGenetico = false;
                this.modalSelecaoGeneticaAnaliseSelecaoTesteGenetico = false;
                this.modalSelecaoCampoAnaliseSelecaoTesteGenetico = false;
                this.modalAnexoAnaliseSelecaoTesteGenetico = false;
                this.modalRelatorioTesteGenetico = false;
                this.modalAnexoTesteGenetico = false;
                this.modalProtocoloTesteGenetico = false;
                this.modalDossieMaterialGenetico = false;
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TESTE_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TESTE_GENETICO)
                    .atualizar(this.testeGeneticoSelecionado);
                if ((typeof this.testeGeneticoSelecionado.adubacaoBaseFertilizante !== 'undefined') && this.testeGeneticoSelecionado.adubacaoBaseFertilizante) {
                    let produtoFertilizanteBase = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                        .id(this.testeGeneticoSelecionado.adubacaoBaseFertilizante.id)
                        .ver();
                    let historicoEstoqueProdutoFertilizanteBase = new HistoricoEstoqueProdutoFertilizante_1.HistoricoEstoqueProdutoFertilizante();
                    if ((typeof produtoFertilizanteBase !== 'undefined') && produtoFertilizanteBase) {
                        historicoEstoqueProdutoFertilizanteBase.produtoFertilizante = this.testeGeneticoSelecionado.adubacaoBaseFertilizante;
                        historicoEstoqueProdutoFertilizanteBase.quantidadeAnterior = (_a = produtoFertilizanteBase.quantidadeAtual) !== null && _a !== void 0 ? _a : 0;
                        historicoEstoqueProdutoFertilizanteBase.quantidadeMovimento = (_b = this.testeGeneticoSelecionado.adubacaoBaseQuantidadeFertilizante) !== null && _b !== void 0 ? _b : 0;
                        historicoEstoqueProdutoFertilizanteBase.quantidadeAtual = historicoEstoqueProdutoFertilizanteBase.quantidadeAnterior - historicoEstoqueProdutoFertilizanteBase.quantidadeMovimento;
                        historicoEstoqueProdutoFertilizanteBase.movimento = HistoricoEstoqueProdutoFertilizante_1.HistoricoEstoqueProdutoFertilizante.MOVIMENTO_SAIDA;
                        historicoEstoqueProdutoFertilizanteBase.usuario = this.usuarioLogado;
                        historicoEstoqueProdutoFertilizanteBase.centroCustoSap = this.testeGeneticoSelecionado.adubacaoBaseCentroCustoSap;
                        historicoEstoqueProdutoFertilizanteBase.contaDepositoSap = this.testeGeneticoSelecionado.adubacaoBaseContaDepositoSap;
                        produtoFertilizanteBase.quantidadeAtual = historicoEstoqueProdutoFertilizanteBase.quantidadeAtual;
                        yield this.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.HISTORICO_ESTOQUE_PRODUTO_FERTILIZANTE)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.HISTORICO_ESTOQUE_PRODUTO_FERTILIZANTE)
                            .criar(historicoEstoqueProdutoFertilizanteBase);
                        yield this.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                            .atualizar(produtoFertilizanteBase);
                    }
                }
                if ((typeof this.testeGeneticoSelecionado.adubacaoCoberturaFertilizante !== 'undefined') && this.testeGeneticoSelecionado.adubacaoCoberturaFertilizante) {
                    let produtoFertilizanteCobertura = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                        .id(this.testeGeneticoSelecionado.adubacaoCoberturaFertilizante.id)
                        .ver();
                    let historicoEstoqueProdutoFertilizanteCobertura = new HistoricoEstoqueProdutoFertilizante_1.HistoricoEstoqueProdutoFertilizante();
                    if ((typeof produtoFertilizanteCobertura !== 'undefined') && produtoFertilizanteCobertura) {
                        historicoEstoqueProdutoFertilizanteCobertura.produtoFertilizante = this.testeGeneticoSelecionado.adubacaoCoberturaFertilizante;
                        historicoEstoqueProdutoFertilizanteCobertura.quantidadeAnterior = (_c = produtoFertilizanteCobertura.quantidadeAtual) !== null && _c !== void 0 ? _c : 0;
                        historicoEstoqueProdutoFertilizanteCobertura.quantidadeMovimento = (_d = this.testeGeneticoSelecionado.adubacaoCoberturaQuantidadeFertilizante) !== null && _d !== void 0 ? _d : 0;
                        historicoEstoqueProdutoFertilizanteCobertura.quantidadeAtual = historicoEstoqueProdutoFertilizanteCobertura.quantidadeAnterior - historicoEstoqueProdutoFertilizanteCobertura.quantidadeMovimento;
                        historicoEstoqueProdutoFertilizanteCobertura.movimento = HistoricoEstoqueProdutoFertilizante_1.HistoricoEstoqueProdutoFertilizante.MOVIMENTO_SAIDA;
                        historicoEstoqueProdutoFertilizanteCobertura.usuario = this.usuarioLogado;
                        historicoEstoqueProdutoFertilizanteCobertura.centroCustoSap = this.testeGeneticoSelecionado.adubacaoCoberturaCentroCustoSap;
                        historicoEstoqueProdutoFertilizanteCobertura.contaDepositoSap = this.testeGeneticoSelecionado.adubacaoCoberturaContaDepositoSap;
                        produtoFertilizanteCobertura.quantidadeAtual = historicoEstoqueProdutoFertilizanteCobertura.quantidadeAtual;
                        yield this.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.HISTORICO_ESTOQUE_PRODUTO_FERTILIZANTE)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.HISTORICO_ESTOQUE_PRODUTO_FERTILIZANTE)
                            .criar(historicoEstoqueProdutoFertilizanteCobertura);
                        yield this.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                            .atualizar(produtoFertilizanteCobertura);
                    }
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (this.ehCelular) {
                    alert(`Informações do Pomar foram salvas com sucesso!`);
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'As informações do pomar foram salvas com sucesso!',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Ok'
                    });
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    alterarDadosGeograficosTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!this.testeGeneticoSelecionado || !this.testeGeneticoSelecionado.id) {
                    return;
                }
                if ((typeof this.testeGeneticoSelecionado.perfilClimaticoAnual !== 'undefined') && this.testeGeneticoSelecionado.perfilClimaticoAnual) {
                    this.testeGeneticoSelecionado.perfilClimaticoAnual.atualizacao = new Date();
                }
                else {
                    this.testeGeneticoSelecionado.perfilClimaticoAnual = new PerfilClimaticoPomar_1.PerfilClimaticoPomar();
                    this.testeGeneticoSelecionado.perfilClimaticoAnual.atualizacao = new Date();
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
        });
    }
    salvarDadosGeograficosTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!this.testeGeneticoSelecionado || !this.testeGeneticoSelecionado.id) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.modalTratamentoTesteGenetico = false;
                this.modalGerarCroquiTesteGenetico = false;
                this.modalGerarCroquiTesteGeneticoDados = null;
                this.modalImportacaoCroquiTesteGenetico = false;
                this.modalHistoricoCroquiTesteGenetico = false;
                this.modalControleManejoTesteGenetico = false;
                this.modalMedicaoDendrometricaTesteGenetico = false;
                this.modalPlanejamentoMedicaoDendrometricaTesteGenetico = false;
                this.modalPlanilhaMedicaoDendrometricaTesteGenetico = false;
                this.modalSelegenMedicaoDendrometricaTesteGenetico = false;
                this.modalQualidadeMadeiraTesteGenetico = false;
                this.modalAnaliseSelecaoTesteGenetico = false;
                this.modalSumarioAnaliseSelecaoTesteGenetico = false;
                this.modalPlanilhaSelegenAnaliseSelecaoTesteGenetico = false;
                this.modalAnaliseSelegenAnaliseSelecaoTesteGenetico = false;
                this.modalResultadoSelegenAnaliseSelecaoTesteGenetico = false;
                this.modalAnaliseAnoAnaliseSelecaoTesteGenetico = false;
                this.modalAnaliseBlupAnaliseSelecaoTesteGenetico = false;
                this.modalSelecaoGeneticaAnaliseSelecaoTesteGenetico = false;
                this.modalSelecaoCampoAnaliseSelecaoTesteGenetico = false;
                this.modalAnexoAnaliseSelecaoTesteGenetico = false;
                this.modalRelatorioTesteGenetico = false;
                this.modalAnexoTesteGenetico = false;
                this.modalProtocoloTesteGenetico = false;
                this.modalDossieMaterialGenetico = false;
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TESTE_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TESTE_GENETICO)
                    .atualizar(this.testeGeneticoSelecionado);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (this.ehCelular) {
                    alert(`Informações do Pomar foram salvas com sucesso!`);
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'As informações do pomar foram salvas com sucesso!',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Ok'
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    salvarMapaTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!this.mapaTesteGeneticoSelecionado) {
                    return;
                }
                this.modalTratamentoTesteGenetico = false;
                this.modalGerarCroquiTesteGenetico = false;
                this.modalGerarCroquiTesteGeneticoDados = null;
                this.modalImportacaoCroquiTesteGenetico = false;
                this.modalHistoricoCroquiTesteGenetico = false;
                this.modalControleManejoTesteGenetico = false;
                this.modalMedicaoDendrometricaTesteGenetico = false;
                this.modalPlanejamentoMedicaoDendrometricaTesteGenetico = false;
                this.modalPlanilhaMedicaoDendrometricaTesteGenetico = false;
                this.modalSelegenMedicaoDendrometricaTesteGenetico = false;
                this.modalQualidadeMadeiraTesteGenetico = false;
                this.modalAnaliseSelecaoTesteGenetico = false;
                this.modalSumarioAnaliseSelecaoTesteGenetico = false;
                this.modalPlanilhaSelegenAnaliseSelecaoTesteGenetico = false;
                this.modalAnaliseSelegenAnaliseSelecaoTesteGenetico = false;
                this.modalResultadoSelegenAnaliseSelecaoTesteGenetico = false;
                this.modalAnaliseAnoAnaliseSelecaoTesteGenetico = false;
                this.modalAnaliseBlupAnaliseSelecaoTesteGenetico = false;
                this.modalSelecaoGeneticaAnaliseSelecaoTesteGenetico = false;
                this.modalSelecaoCampoAnaliseSelecaoTesteGenetico = false;
                this.modalAnexoAnaliseSelecaoTesteGenetico = false;
                this.modalRelatorioTesteGenetico = false;
                this.modalAnexoTesteGenetico = false;
                this.modalProtocoloTesteGenetico = false;
                this.modalDossieMaterialGenetico = false;
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (!this.mapaTesteGeneticoSelecionado) {
                    this.mapaTesteGeneticoSelecionado = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.MAPA_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MAPA_TESTE_GENETICO)
                        .criar(this.mapaTesteGeneticoSelecionado);
                }
                else {
                    this.mapaTesteGeneticoSelecionado = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.MAPA_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MAPA_TESTE_GENETICO)
                        .atualizar(this.mapaTesteGeneticoSelecionado);
                }
                if ((typeof this.mapaTesteGeneticoSelecionado.testeGenetico !== 'undefined') && this.mapaTesteGeneticoSelecionado.testeGenetico &&
                    (typeof this.mapaTesteGeneticoSelecionado.testeGenetico.descricao !== 'undefined') && this.mapaTesteGeneticoSelecionado.testeGenetico.descricao) {
                    this.mapaTesteGeneticoSelecionado.testeGeneticoDescricao = this.mapaTesteGeneticoSelecionado.testeGenetico.descricao;
                }
                else if ((typeof this.mapaTesteGeneticoSelecionado.testeGenetico !== 'undefined') && this.mapaTesteGeneticoSelecionado.testeGenetico &&
                    (typeof this.mapaTesteGeneticoSelecionado.testeGenetico.descricaoDetalhada !== 'undefined') && this.mapaTesteGeneticoSelecionado.testeGenetico.descricaoDetalhada) {
                    this.mapaTesteGeneticoSelecionado.testeGeneticoDescricao = this.mapaTesteGeneticoSelecionado.testeGenetico.descricaoDetalhada;
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    visualizacaoResumidaControleManejoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoControleManejoUtil_1.TesteCampoControleManejoUtil.visualizacaoResumidaControleManejoTesteGenetico(this);
        });
    }
    visualizacaoDetalhadaControleManejoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoControleManejoUtil_1.TesteCampoControleManejoUtil.visualizacaoDetalhadaControleManejoTesteGenetico(this);
        });
    }
    filtrarControleManejoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoControleManejoUtil_1.TesteCampoControleManejoUtil.filtrarControleManejoTesteGenetico(this);
        });
    }
    salvarControleManejoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoControleManejoUtil_1.TesteCampoControleManejoUtil.salvarControleManejoTesteGenetico(this);
        });
    }
    exibirModalControleManejoTesteGenetico(dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoControleManejoUtil_1.TesteCampoControleManejoUtil.exibirModalControleManejoTesteGenetico(this, dados);
        });
    }
    ocultarModalControleManejoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoControleManejoUtil_1.TesteCampoControleManejoUtil.ocultarModalControleManejoTesteGenetico(this);
        });
    }
    alterarOperacaoControleManejoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoControleManejoUtil_1.TesteCampoControleManejoUtil.alterarOperacaoControleManejoTesteGenetico(this);
        });
    }
    alterarMateriaisGeneticosControleManejoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoControleManejoUtil_1.TesteCampoControleManejoUtil.alterarMateriaisGeneticosControleManejoTesteGenetico(this);
        });
    }
    alterarClonesMasculinosControleManejoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoControleManejoUtil_1.TesteCampoControleManejoUtil.alterarClonesMasculinosControleManejoTesteGenetico(this);
        });
    }
    adicionarRegistroOperacaoControleManejoTesteGenetico(listagemOperacao) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoControleManejoUtil_1.TesteCampoControleManejoUtil.adicionarRegistroOperacaoControleManejoTesteGenetico(this, listagemOperacao);
        });
    }
    removerRegistroOperacaoControleManejoTesteGenetico(listagemOperacao, keyOperacao) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoControleManejoUtil_1.TesteCampoControleManejoUtil.removerRegistroOperacaoControleManejoTesteGenetico(this, listagemOperacao, keyOperacao);
        });
    }
    moverRegistroOperacaoControleManejoTesteGeneticoParaCima(listagemOperacao, keyOperacao) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoControleManejoUtil_1.TesteCampoControleManejoUtil.moverRegistroOperacaoControleManejoTesteGeneticoParaCima(this, listagemOperacao, keyOperacao);
        });
    }
    moverRegistroOperacaoControleManejoTesteGeneticoParaBaixo(listagemOperacao, keyOperacao) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoControleManejoUtil_1.TesteCampoControleManejoUtil.moverRegistroOperacaoControleManejoTesteGeneticoParaBaixo(this, listagemOperacao, keyOperacao);
        });
    }
    adicionarLinhaColunaRegistroOperacaoControleManejoTesteGenetico(operacaoControleManejo) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoControleManejoUtil_1.TesteCampoControleManejoUtil.adicionarLinhaColunaRegistroOperacaoControleManejoTesteGenetico(this, operacaoControleManejo);
        });
    }
    removerLinhaColunaRegistroOperacaoControleManejoTesteGenetico(operacaoControleManejo, keyLinhaColuna) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoControleManejoUtil_1.TesteCampoControleManejoUtil.removerLinhaColunaRegistroOperacaoControleManejoTesteGenetico(this, operacaoControleManejo, keyLinhaColuna);
        });
    }
    totalizarControleManejoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoControleManejoUtil_1.TesteCampoControleManejoUtil.totalizarControleManejoTesteGenetico(this);
        });
    }
    alterarTipoDesbasteControleManejoTesteGenetico(keyOperacaoDesbaste) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoControleManejoUtil_1.TesteCampoControleManejoUtil.alterarTipoDesbasteControleManejoTesteGenetico(this, keyOperacaoDesbaste);
        });
    }
    alterarTipoColetaAmostraControleManejoTesteGenetico(keyOperacaoColetaAmostra) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoControleManejoUtil_1.TesteCampoControleManejoUtil.alterarTipoColetaAmostraControleManejoTesteGenetico(this, keyOperacaoColetaAmostra);
        });
    }
    alterarFinalidadeColetaAmostraControleManejoTesteGenetico(keyOperacaoColetaAmostra) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoControleManejoUtil_1.TesteCampoControleManejoUtil.alterarFinalidadeColetaAmostraControleManejoTesteGenetico(this, keyOperacaoColetaAmostra);
        });
    }
    tirarFotoControleManejoTesteGenetico(ehGaleria = false) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoControleManejoUtil_1.TesteCampoControleManejoUtil.tirarFotoControleManejoTesteGenetico(this, ehGaleria);
        });
    }
    removerFotoControleManejoTesteGenetico(fotoCamera) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoControleManejoUtil_1.TesteCampoControleManejoUtil.removerFotoControleManejoTesteGenetico(this, fotoCamera);
        });
    }
    carregarDadosRelatorioTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoRelatorioUtil_1.TesteCampoRelatorioUtil.carregarDadosRelatorioTesteGenetico(this);
        });
    }
    exibirModalRelatorioTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoRelatorioUtil_1.TesteCampoRelatorioUtil.exibirModalRelatorioTesteGenetico(this);
        });
    }
    ocultarModalRelatorioTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoRelatorioUtil_1.TesteCampoRelatorioUtil.ocultarModalRelatorioTesteGenetico(this);
        });
    }
    alterarFiltroRelatorioTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoRelatorioUtil_1.TesteCampoRelatorioUtil.alterarFiltroRelatorioTesteGenetico(this);
        });
    }
    adicionarAnoMedicaoDendrometrica() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.adicionarAnoMedicaoDendrometrica(this);
        });
    }
    importarMedicaoDendrometricaTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.importarMedicaoDendrometricaTesteGenetico(this);
        });
    }
    finalizarImportacaoMedicaoDendrometricaTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.finalizarImportacaoMedicaoDendrometricaTesteGenetico(this);
        });
    }
    cancelarImportacaoMedicaoDendrometricaTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.cancelarImportacaoMedicaoDendrometricaTesteGenetico(this);
        });
    }
    exportarMedicaoDendrometricaTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.exportarMedicaoDendrometricaTesteGenetico(this);
        });
    }
    historicoMedicaoDendrometricaTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.historicoMedicaoDendrometricaTesteGenetico(this);
        });
    }
    selecionarHistoricoMedicaoDendrometricaTesteGenetico(keyPlanilha) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.selecionarHistoricoMedicaoDendrometricaTesteGenetico(this, keyPlanilha);
        });
    }
    cancelarHistoricoMedicaoDendrometricaTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.cancelarHistoricoMedicaoDendrometricaTesteGenetico(this);
        });
    }
    salvarMedicaoDendrometricaTesteGenetico(ehImportacao = false) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.salvarMedicaoDendrometricaTesteGenetico(this, ehImportacao);
        });
    }
    exibirModalMedicaoDendrometricaTesteGenetico(dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.exibirModalMedicaoDendrometricaTesteGenetico(this, dados);
        });
    }
    ocultarModalMedicaoDendrometricaTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.ocultarModalMedicaoDendrometricaTesteGenetico(this);
        });
    }
    importarPlanejamentoMedicaoDendrometricaTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.importarPlanejamentoMedicaoDendrometricaTesteGenetico(this);
        });
    }
    finalizarImportacaoPlanejamentoMedicaoDendrometricaTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.finalizarImportacaoPlanejamentoMedicaoDendrometricaTesteGenetico(this);
        });
    }
    cancelarImportacaoPlanejamentoMedicaoDendrometricaTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.cancelarImportacaoPlanejamentoMedicaoDendrometricaTesteGenetico(this);
        });
    }
    exportarPlanejamentoMedicaoDendrometricaTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.exportarPlanejamentoMedicaoDendrometricaTesteGenetico(this);
        });
    }
    historicoPlanejamentoMedicaoDendrometricaTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.historicoPlanejamentoMedicaoDendrometricaTesteGenetico(this);
        });
    }
    selecionarHistoricoPlanejamentoMedicaoDendrometricaTesteGenetico(keyPlanilha) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.selecionarHistoricoPlanejamentoMedicaoDendrometricaTesteGenetico(this, keyPlanilha);
        });
    }
    cancelarHistoricoPlanejamentoMedicaoDendrometricaTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.cancelarHistoricoPlanejamentoMedicaoDendrometricaTesteGenetico(this);
        });
    }
    salvarPlanejamentoMedicaoDendrometricaTesteGenetico(ehImportacao = false) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.salvarPlanejamentoMedicaoDendrometricaTesteGenetico(this, ehImportacao);
        });
    }
    exibirModalPlanejamentoMedicaoDendrometricaTesteGenetico(dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.exibirModalPlanejamentoMedicaoDendrometricaTesteGenetico(this, dados);
        });
    }
    ocultarModalPlanejamentoMedicaoDendrometricaTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.ocultarModalPlanejamentoMedicaoDendrometricaTesteGenetico(this);
        });
    }
    importarPlanilhaMedicaoDendrometricaTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.importarPlanilhaMedicaoDendrometricaTesteGenetico(this);
        });
    }
    finalizarImportacaoPlanilhaMedicaoDendrometricaTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.finalizarImportacaoPlanilhaMedicaoDendrometricaTesteGenetico(this);
        });
    }
    cancelarImportacaoPlanilhaMedicaoDendrometricaTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.cancelarImportacaoPlanilhaMedicaoDendrometricaTesteGenetico(this);
        });
    }
    exportarPlanilhaMedicaoDendrometricaTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.exportarPlanilhaMedicaoDendrometricaTesteGenetico(this);
        });
    }
    historicoPlanilhaMedicaoDendrometricaTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.historicoPlanilhaMedicaoDendrometricaTesteGenetico(this);
        });
    }
    selecionarHistoricoPlanilhaMedicaoDendrometricaTesteGenetico(keyPlanilha) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.selecionarHistoricoPlanilhaMedicaoDendrometricaTesteGenetico(this, keyPlanilha);
        });
    }
    cancelarHistoricoPlanilhaMedicaoDendrometricaTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.cancelarHistoricoPlanilhaMedicaoDendrometricaTesteGenetico(this);
        });
    }
    salvarPlanilhaMedicaoDendrometricaTesteGenetico(ehImportacao = false) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.salvarPlanilhaMedicaoDendrometricaTesteGenetico(this, ehImportacao);
        });
    }
    exibirModalPlanilhaMedicaoDendrometricaTesteGenetico(dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.exibirModalPlanilhaMedicaoDendrometricaTesteGenetico(this, dados);
        });
    }
    ocultarModalPlanilhaMedicaoDendrometricaTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.ocultarModalPlanilhaMedicaoDendrometricaTesteGenetico(this);
        });
    }
    importarSelegenMedicaoDendrometricaTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.importarSelegenMedicaoDendrometricaTesteGenetico(this);
        });
    }
    finalizarImportacaoSelegenMedicaoDendrometricaTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.finalizarImportacaoSelegenMedicaoDendrometricaTesteGenetico(this);
        });
    }
    cancelarImportacaoSelegenMedicaoDendrometricaTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.cancelarImportacaoSelegenMedicaoDendrometricaTesteGenetico(this);
        });
    }
    exportarSelegenMedicaoDendrometricaTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.exportarSelegenMedicaoDendrometricaTesteGenetico(this);
        });
    }
    historicoSelegenMedicaoDendrometricaTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.historicoSelegenMedicaoDendrometricaTesteGenetico(this);
        });
    }
    selecionarHistoricoSelegenMedicaoDendrometricaTesteGenetico(keyPlanilha) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.selecionarHistoricoSelegenMedicaoDendrometricaTesteGenetico(this, keyPlanilha);
        });
    }
    cancelarHistoricoSelegenMedicaoDendrometricaTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.cancelarHistoricoSelegenMedicaoDendrometricaTesteGenetico(this);
        });
    }
    salvarSelegenMedicaoDendrometricaTesteGenetico(ehImportacao = false) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.salvarSelegenMedicaoDendrometricaTesteGenetico(this, ehImportacao);
        });
    }
    exibirModalSelegenMedicaoDendrometricaTesteGenetico(dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.exibirModalSelegenMedicaoDendrometricaTesteGenetico(this, dados);
        });
    }
    ocultarModalSelegenMedicaoDendrometricaTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoMedicaoDendrometricaUtil_1.TesteCampoMedicaoDendrometricaUtil.ocultarModalSelegenMedicaoDendrometricaTesteGenetico(this);
        });
    }
    salvarQualidadeMadeiraTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            TesteCampoQualidadeMadeiraUtil_1.TesteCampoQualidadeMadeiraUtil.salvarQualidadeMadeiraTesteGenetico(this);
        });
    }
    exibirModalQualidadeMadeiraTesteGenetico(dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            TesteCampoQualidadeMadeiraUtil_1.TesteCampoQualidadeMadeiraUtil.exibirModalQualidadeMadeiraTesteGenetico(this, dados);
        });
    }
    ocultarModalQualidadeMadeiraTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            TesteCampoQualidadeMadeiraUtil_1.TesteCampoQualidadeMadeiraUtil.ocultarModalQualidadeMadeiraTesteGenetico(this);
        });
    }
    importarAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.importarAnaliseSelecaoTesteGenetico(this);
        });
    }
    finalizarImportacaoAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.finalizarImportacaoAnaliseSelecaoTesteGenetico(this);
        });
    }
    cancelarImportacaoAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.cancelarImportacaoAnaliseSelecaoTesteGenetico(this);
        });
    }
    exportarAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.exportarAnaliseSelecaoTesteGenetico(this);
        });
    }
    historicoAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.historicoAnaliseSelecaoTesteGenetico(this);
        });
    }
    selecionarHistoricoAnaliseSelecaoTesteGenetico(keyPlanilha) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.selecionarHistoricoAnaliseSelecaoTesteGenetico(this, keyPlanilha);
        });
    }
    cancelarHistoricoAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.cancelarHistoricoAnaliseSelecaoTesteGenetico(this);
        });
    }
    salvarAnaliseSelecaoTesteGenetico(ehImportacao = false) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.salvarAnaliseSelecaoTesteGenetico(this, ehImportacao);
        });
    }
    exibirModalAnaliseSelecaoTesteGenetico(dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.exibirModalAnaliseSelecaoTesteGenetico(this, dados);
        });
    }
    ocultarModalAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.ocultarModalAnaliseSelecaoTesteGenetico(this);
        });
    }
    importarSumarioAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.importarSumarioAnaliseSelecaoTesteGenetico(this);
        });
    }
    finalizarImportacaoSumarioAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.finalizarImportacaoSumarioAnaliseSelecaoTesteGenetico(this);
        });
    }
    cancelarImportacaoSumarioAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.cancelarImportacaoSumarioAnaliseSelecaoTesteGenetico(this);
        });
    }
    exportarSumarioAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.exportarSumarioAnaliseSelecaoTesteGenetico(this);
        });
    }
    historicoSumarioAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.historicoSumarioAnaliseSelecaoTesteGenetico(this);
        });
    }
    selecionarHistoricoSumarioAnaliseSelecaoTesteGenetico(keyPlanilha) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.selecionarHistoricoSumarioAnaliseSelecaoTesteGenetico(this, keyPlanilha);
        });
    }
    cancelarHistoricoSumarioAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.cancelarHistoricoSumarioAnaliseSelecaoTesteGenetico(this);
        });
    }
    salvarSumarioAnaliseSelecaoTesteGenetico(ehImportacao = false) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.salvarSumarioAnaliseSelecaoTesteGenetico(this, ehImportacao);
        });
    }
    exibirModalSumarioAnaliseSelecaoTesteGenetico(dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.exibirModalSumarioAnaliseSelecaoTesteGenetico(this, dados);
        });
    }
    ocultarModalSumarioAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.ocultarModalSumarioAnaliseSelecaoTesteGenetico(this);
        });
    }
    importarPlanilhaSelegenAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.importarPlanilhaSelegenAnaliseSelecaoTesteGenetico(this);
        });
    }
    finalizarImportacaoPlanilhaSelegenAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.finalizarImportacaoPlanilhaSelegenAnaliseSelecaoTesteGenetico(this);
        });
    }
    cancelarImportacaoPlanilhaSelegenAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.cancelarImportacaoPlanilhaSelegenAnaliseSelecaoTesteGenetico(this);
        });
    }
    exportarPlanilhaSelegenAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.exportarPlanilhaSelegenAnaliseSelecaoTesteGenetico(this);
        });
    }
    historicoPlanilhaSelegenAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.historicoPlanilhaSelegenAnaliseSelecaoTesteGenetico(this);
        });
    }
    selecionarHistoricoPlanilhaSelegenAnaliseSelecaoTesteGenetico(keyPlanilha) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.selecionarHistoricoPlanilhaSelegenAnaliseSelecaoTesteGenetico(this, keyPlanilha);
        });
    }
    cancelarHistoricoPlanilhaSelegenAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.cancelarHistoricoPlanilhaSelegenAnaliseSelecaoTesteGenetico(this);
        });
    }
    salvarPlanilhaSelegenAnaliseSelecaoTesteGenetico(ehImportacao = false) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.salvarPlanilhaSelegenAnaliseSelecaoTesteGenetico(this, ehImportacao);
        });
    }
    exibirModalPlanilhaSelegenAnaliseSelecaoTesteGenetico(dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.exibirModalPlanilhaSelegenAnaliseSelecaoTesteGenetico(this, dados);
        });
    }
    ocultarModalPlanilhaSelegenAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.ocultarModalPlanilhaSelegenAnaliseSelecaoTesteGenetico(this);
        });
    }
    importarAnaliseSelegenAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.importarAnaliseSelegenAnaliseSelecaoTesteGenetico(this);
        });
    }
    finalizarImportacaoAnaliseSelegenAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.finalizarImportacaoAnaliseSelegenAnaliseSelecaoTesteGenetico(this);
        });
    }
    cancelarImportacaoAnaliseSelegenAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.cancelarImportacaoAnaliseSelegenAnaliseSelecaoTesteGenetico(this);
        });
    }
    exportarAnaliseSelegenAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.exportarAnaliseSelegenAnaliseSelecaoTesteGenetico(this);
        });
    }
    historicoAnaliseSelegenAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.historicoAnaliseSelegenAnaliseSelecaoTesteGenetico(this);
        });
    }
    selecionarHistoricoAnaliseSelegenAnaliseSelecaoTesteGenetico(keyPlanilha) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.selecionarHistoricoAnaliseSelegenAnaliseSelecaoTesteGenetico(this, keyPlanilha);
        });
    }
    cancelarHistoricoAnaliseSelegenAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.cancelarHistoricoAnaliseSelegenAnaliseSelecaoTesteGenetico(this);
        });
    }
    salvarAnaliseSelegenAnaliseSelecaoTesteGenetico(ehImportacao = false) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.salvarAnaliseSelegenAnaliseSelecaoTesteGenetico(this, ehImportacao);
        });
    }
    exibirModalAnaliseSelegenAnaliseSelecaoTesteGenetico(dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.exibirModalAnaliseSelegenAnaliseSelecaoTesteGenetico(this, dados);
        });
    }
    ocultarModalAnaliseSelegenAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.ocultarModalAnaliseSelegenAnaliseSelecaoTesteGenetico(this);
        });
    }
    alterarTipoAnaliseSelegenAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.alterarTipoAnaliseSelegenAnaliseSelecaoTesteGenetico(this);
        });
    }
    importarResultadoSelegenAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.importarResultadoSelegenAnaliseSelecaoTesteGenetico(this);
        });
    }
    finalizarImportacaoResultadoSelegenAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.finalizarImportacaoResultadoSelegenAnaliseSelecaoTesteGenetico(this);
        });
    }
    cancelarImportacaoResultadoSelegenAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.cancelarImportacaoResultadoSelegenAnaliseSelecaoTesteGenetico(this);
        });
    }
    exportarResultadoSelegenAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.exportarResultadoSelegenAnaliseSelecaoTesteGenetico(this);
        });
    }
    historicoResultadoSelegenAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.historicoResultadoSelegenAnaliseSelecaoTesteGenetico(this);
        });
    }
    selecionarHistoricoResultadoSelegenAnaliseSelecaoTesteGenetico(keyPlanilha) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.selecionarHistoricoResultadoSelegenAnaliseSelecaoTesteGenetico(this, keyPlanilha);
        });
    }
    cancelarHistoricoResultadoSelegenAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.cancelarHistoricoResultadoSelegenAnaliseSelecaoTesteGenetico(this);
        });
    }
    atualizarResultadoSelegenAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.atualizarResultadoSelegenAnaliseSelecaoTesteGenetico(this);
        });
    }
    salvarResultadoSelegenAnaliseSelecaoTesteGenetico(ehImportacao = false) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.salvarResultadoSelegenAnaliseSelecaoTesteGenetico(this, ehImportacao);
        });
    }
    exibirModalResultadoSelegenAnaliseSelecaoTesteGenetico(dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.exibirModalResultadoSelegenAnaliseSelecaoTesteGenetico(this, dados);
        });
    }
    ocultarModalResultadoSelegenAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.ocultarModalResultadoSelegenAnaliseSelecaoTesteGenetico(this);
        });
    }
    importarAnaliseAnoAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.importarAnaliseAnoAnaliseSelecaoTesteGenetico(this);
        });
    }
    finalizarImportacaoAnaliseAnoAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.finalizarImportacaoAnaliseAnoAnaliseSelecaoTesteGenetico(this);
        });
    }
    cancelarImportacaoAnaliseAnoAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.cancelarImportacaoAnaliseAnoAnaliseSelecaoTesteGenetico(this);
        });
    }
    exportarAnaliseAnoAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.exportarAnaliseAnoAnaliseSelecaoTesteGenetico(this);
        });
    }
    historicoAnaliseAnoAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.historicoAnaliseAnoAnaliseSelecaoTesteGenetico(this);
        });
    }
    selecionarHistoricoAnaliseAnoAnaliseSelecaoTesteGenetico(keyPlanilha) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.selecionarHistoricoAnaliseAnoAnaliseSelecaoTesteGenetico(this, keyPlanilha);
        });
    }
    cancelarHistoricoAnaliseAnoAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.cancelarHistoricoAnaliseAnoAnaliseSelecaoTesteGenetico(this);
        });
    }
    salvarAnaliseAnoAnaliseSelecaoTesteGenetico(ehImportacao = false) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.salvarAnaliseAnoAnaliseSelecaoTesteGenetico(this, ehImportacao);
        });
    }
    exibirModalAnaliseAnoAnaliseSelecaoTesteGenetico(dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.exibirModalAnaliseAnoAnaliseSelecaoTesteGenetico(this, dados);
        });
    }
    ocultarModalAnaliseAnoAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.ocultarModalAnaliseAnoAnaliseSelecaoTesteGenetico(this);
        });
    }
    importarAnaliseBlupAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.importarAnaliseBlupAnaliseSelecaoTesteGenetico(this);
        });
    }
    finalizarImportacaoAnaliseBlupAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.finalizarImportacaoAnaliseBlupAnaliseSelecaoTesteGenetico(this);
        });
    }
    cancelarImportacaoAnaliseBlupAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.cancelarImportacaoAnaliseBlupAnaliseSelecaoTesteGenetico(this);
        });
    }
    exportarAnaliseBlupAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.exportarAnaliseBlupAnaliseSelecaoTesteGenetico(this);
        });
    }
    historicoAnaliseBlupAnaliseSelecaoAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.historicoAnaliseBlupAnaliseSelecaoTesteGenetico(this);
        });
    }
    selecionarHistoricoAnaliseBlupAnaliseSelecaoAnaliseSelecaoTesteGenetico(keyPlanilha) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.selecionarHistoricoAnaliseBlupAnaliseSelecaoTesteGenetico(this, keyPlanilha);
        });
    }
    cancelarHistoricoAnaliseBlupAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.cancelarHistoricoAnaliseBlupAnaliseSelecaoTesteGenetico(this);
        });
    }
    salvarAnaliseBlupAnaliseSelecaoTesteGenetico(ehImportacao = false) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.salvarAnaliseBlupAnaliseSelecaoTesteGenetico(this, ehImportacao);
        });
    }
    exibirModalAnaliseBlupAnaliseSelecaoTesteGenetico(dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.exibirModalAnaliseBlupAnaliseSelecaoTesteGenetico(this, dados);
        });
    }
    ocultarModalAnaliseBlupAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.ocultarModalAnaliseBlupAnaliseSelecaoTesteGenetico(this);
        });
    }
    importarSelecaoGeneticaAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.importarSelecaoGeneticaAnaliseSelecaoTesteGenetico(this);
        });
    }
    finalizarImportacaoSelecaoGeneticaAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.finalizarImportacaoSelecaoGeneticaAnaliseSelecaoTesteGenetico(this);
        });
    }
    cancelarImportacaoSelecaoGeneticaAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.cancelarImportacaoSelecaoGeneticaAnaliseSelecaoTesteGenetico(this);
        });
    }
    exportarSelecaoGeneticaAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.exportarSelecaoGeneticaAnaliseSelecaoTesteGenetico(this);
        });
    }
    historicoSelecaoGeneticaAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.historicoSelecaoGeneticaAnaliseSelecaoTesteGenetico(this);
        });
    }
    selecionarHistoricoSelecaoGeneticaAnaliseSelecaoTesteGenetico(keyPlanilha) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.selecionarHistoricoSelecaoGeneticaAnaliseSelecaoTesteGenetico(this, keyPlanilha);
        });
    }
    cancelarHistoricoSelecaoGeneticaAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.cancelarHistoricoSelecaoGeneticaAnaliseSelecaoTesteGenetico(this);
        });
    }
    salvarSelecaoGeneticaAnaliseSelecaoTesteGenetico(ehImportacao = false) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.salvarSelecaoGeneticaAnaliseSelecaoTesteGenetico(this, ehImportacao);
        });
    }
    exibirModalSelecaoGeneticaAnaliseSelecaoTesteGenetico(dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.exibirModalSelecaoGeneticaAnaliseSelecaoTesteGenetico(this, dados);
        });
    }
    ocultarModalSelecaoGeneticaAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.ocultarModalSelecaoGeneticaAnaliseSelecaoTesteGenetico(this);
        });
    }
    importarSelecaoCampoAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.importarSelecaoCampoAnaliseSelecaoTesteGenetico(this);
        });
    }
    finalizarImportacaoSelecaoCampoAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.finalizarImportacaoSelecaoCampoAnaliseSelecaoTesteGenetico(this);
        });
    }
    cancelarImportacaoSelecaoCampoAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.cancelarImportacaoSelecaoCampoAnaliseSelecaoTesteGenetico(this);
        });
    }
    exportarSelecaoCampoAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.exportarSelecaoCampoAnaliseSelecaoTesteGenetico(this);
        });
    }
    historicoSelecaoCampoAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.historicoSelecaoCampoAnaliseSelecaoTesteGenetico(this);
        });
    }
    selecionarHistoricoSelecaoCampoAnaliseSelecaoTesteGenetico(keyPlanilha) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.selecionarHistoricoSelecaoCampoAnaliseSelecaoTesteGenetico(this, keyPlanilha);
        });
    }
    cancelarHistoricoSelecaoCampoAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.cancelarHistoricoSelecaoCampoAnaliseSelecaoTesteGenetico(this);
        });
    }
    salvarSelecaoCampoAnaliseSelecaoTesteGenetico(ehImportacao = false) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.salvarSelecaoCampoAnaliseSelecaoTesteGenetico(this, ehImportacao);
        });
    }
    exibirModalSelecaoCampoAnaliseSelecaoTesteGenetico(dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.exibirModalSelecaoCampoAnaliseSelecaoTesteGenetico(this, dados);
        });
    }
    ocultarModalSelecaoCampoAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.ocultarModalSelecaoCampoAnaliseSelecaoTesteGenetico(this);
        });
    }
    exibirModalAnexoAnaliseSelecaoTesteGenetico(dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.exibirModalAnexoAnaliseSelecaoTesteGenetico(this, dados);
        });
    }
    ocultarModalAnexoAnaliseSelecaoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            yield TesteCampoAnaliseSelecaoUtil_1.TesteCampoAnaliseSelecaoUtil.ocultarModalAnexoAnaliseSelecaoTesteGenetico(this);
        });
    }
    salvarAnexoTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof this.anexoTesteGeneticoSelecionado === 'undefined') || !this.anexoTesteGeneticoSelecionado) {
                    return;
                }
                let listaCamposObrigatorios = '';
                if ((typeof this.anexoTesteGeneticoSelecionado.descricao === 'undefined') || !this.anexoTesteGeneticoSelecionado.descricao) {
                    listaCamposObrigatorios += '\r\n - <b>Descrição da Recomendação (Anexo) - Teste Genético</b>';
                }
                if (listaCamposObrigatorios && (listaCamposObrigatorios.length > 0)) {
                    let mensagem = 'Campo obrigatório não preenchido!' +
                        listaCamposObrigatorios + '\r\n \r\n' +
                        'Preencha este campo e tente novamente.';
                    if (this.ehCelular) {
                        alert(mensagem.replace(/<b>/g, '').replace(/<\/b>/g, ''));
                    }
                    else {
                        yield sweetalert2_1.default.fire({
                            html: mensagem.replace(/\r\n/g, '<br>\r\n'),
                            icon: 'info',
                            showCancelButton: false,
                            showConfirmButton: true,
                            confirmButtonText: 'Ok'
                        });
                    }
                    return;
                }
                this.modalTratamentoTesteGenetico = false;
                this.modalGerarCroquiTesteGenetico = false;
                this.modalGerarCroquiTesteGeneticoDados = null;
                this.modalImportacaoCroquiTesteGenetico = false;
                this.modalHistoricoCroquiTesteGenetico = false;
                this.modalControleManejoTesteGenetico = false;
                this.modalMedicaoDendrometricaTesteGenetico = false;
                this.modalPlanejamentoMedicaoDendrometricaTesteGenetico = false;
                this.modalPlanilhaMedicaoDendrometricaTesteGenetico = false;
                this.modalSelegenMedicaoDendrometricaTesteGenetico = false;
                this.modalQualidadeMadeiraTesteGenetico = false;
                this.modalAnaliseSelecaoTesteGenetico = false;
                this.modalSumarioAnaliseSelecaoTesteGenetico = false;
                this.modalPlanilhaSelegenAnaliseSelecaoTesteGenetico = false;
                this.modalAnaliseSelegenAnaliseSelecaoTesteGenetico = false;
                this.modalResultadoSelegenAnaliseSelecaoTesteGenetico = false;
                this.modalAnaliseAnoAnaliseSelecaoTesteGenetico = false;
                this.modalAnaliseBlupAnaliseSelecaoTesteGenetico = false;
                this.modalSelecaoGeneticaAnaliseSelecaoTesteGenetico = false;
                this.modalSelecaoCampoAnaliseSelecaoTesteGenetico = false;
                this.modalAnexoAnaliseSelecaoTesteGenetico = false;
                this.modalRelatorioTesteGenetico = false;
                this.modalProtocoloTesteGenetico = false;
                this.modalDossieMaterialGenetico = false;
                this.anexoTesteGeneticoSelecionado.testeGenetico = this.testeGeneticoSelecionado;
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-dialog > .modal-content');
                if (!this.anexoTesteGeneticoSelecionado.id) {
                    this.anexoTesteGeneticoSelecionado = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANEXO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANEXO_TESTE_GENETICO)
                        .criar(this.anexoTesteGeneticoSelecionado);
                }
                else {
                    this.anexoTesteGeneticoSelecionado = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANEXO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANEXO_TESTE_GENETICO)
                        .atualizar(this.anexoTesteGeneticoSelecionado);
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (this.anexoTesteGeneticoSelecionado && this.anexoTesteGeneticoSelecionado.id) {
                    this.modalAnexoTesteGenetico = false;
                    if (this.ehCelular) {
                        alert(`Salvo com sucesso!`);
                    }
                    else {
                        sweetalert2_1.default.fire({
                            title: 'Salvo com sucesso!',
                            text: 'O lançamento foi salvo no banco de dados.',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'Ok'
                        });
                    }
                    this.listaOpcoesAnexosTesteGenetico = yield this.dadosListaAnexosTesteGenetico();
                }
                this.ocultarModalAnexoTesteGenetico();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    exibirModalAnexoTesteGenetico(dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.testeGeneticoSelecionado === 'undefined') || !this.testeGeneticoSelecionado) {
                return;
            }
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.anexoTesteGeneticoVisualizar = true;
                if ((typeof dados !== 'undefined') && dados && (typeof dados.id !== 'undefined') && dados.id) {
                    this.anexoTesteGeneticoSelecionado = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANEXO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANEXO_TESTE_GENETICO)
                        .id(dados.id)
                        .ver();
                    if ((typeof this.anexoTesteGeneticoSelecionado === 'undefined') || !this.anexoTesteGeneticoSelecionado) {
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        if (this.ehCelular) {
                            alert(`Registro "${dados.id} não encontrado!`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                text: `Registro "${dados.id}" não encontrado.`,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        return;
                    }
                    if ((typeof this.anexoTesteGeneticoSelecionado.criacao === 'string') && this.anexoTesteGeneticoSelecionado.criacao) {
                        this.anexoTesteGeneticoSelecionado.criacao = new Date(this.anexoTesteGeneticoSelecionado.criacao);
                    }
                    if ((typeof this.anexoTesteGeneticoSelecionado.dataHoraInclusao === 'string') && this.anexoTesteGeneticoSelecionado.dataHoraInclusao) {
                        this.anexoTesteGeneticoSelecionado.dataHoraInclusao = new Date(this.anexoTesteGeneticoSelecionado.dataHoraInclusao);
                    }
                    if ((typeof this.anexoTesteGeneticoSelecionado.dataHoraAlteracao === 'string') && this.anexoTesteGeneticoSelecionado.dataHoraAlteracao) {
                        this.anexoTesteGeneticoSelecionado.dataHoraAlteracao = new Date(this.anexoTesteGeneticoSelecionado.dataHoraAlteracao);
                    }
                    if ((typeof dados.visualizar !== 'undefined') && dados.visualizar) {
                        this.anexoTesteGeneticoVisualizar = true;
                    }
                    else if ((typeof dados.editar !== 'undefined') && dados.editar) {
                        this.anexoTesteGeneticoVisualizar = false;
                    }
                    else if ((typeof dados.excluir !== 'undefined') && dados.excluir) {
                        const ctrl = this;
                        this.notificacao
                            .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                            .then((result) => __awaiter(this, void 0, void 0, function* () {
                            if (result.value) {
                                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANEXO_TESTE_GENETICO)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANEXO_TESTE_GENETICO)
                                    .id(dados.id)
                                    .remover();
                                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                                ctrl.listaOpcoesAnexosTesteGenetico = yield ctrl.dadosListaAnexosTesteGenetico();
                                ctrl.scopeApply();
                            }
                        }));
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                }
                else {
                    this.anexoTesteGeneticoSelecionado = new AnexoTesteGenetico_1.AnexoTesteGenetico();
                    this.anexoTesteGeneticoSelecionado.status = true;
                    this.anexoTesteGeneticoSelecionado.criacao = new Date();
                    this.anexoTesteGeneticoSelecionado.criacao.setMilliseconds(0);
                    this.anexoTesteGeneticoSelecionado.dataHoraInclusao = new Date();
                    this.anexoTesteGeneticoSelecionado.dataHoraInclusao.setMilliseconds(0);
                    this.anexoTesteGeneticoSelecionado.usuarioInclusao = this.usuarioSimplificado();
                    this.anexoTesteGeneticoVisualizar = false;
                }
                if ((typeof this.anexoTesteGeneticoSelecionado.anexos === 'undefined') || (this.anexoTesteGeneticoSelecionado.anexos === null)) {
                    this.anexoTesteGeneticoSelecionado.anexos = new Array();
                }
                this.listasFotos.carregarDados(this.sincronia, { anexoTesteGenetico: this.anexoTesteGeneticoSelecionado });
                this.modalAnexoTesteGenetico = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalAnexoTesteGenetico() {
        this.modalAnexoTesteGenetico = false;
        angular.element('body').css('overflow-y', '');
        globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
        this.scopeApply(() => {
            setTimeout(() => {
                angular.element('body').css('overflow-y', '');
                if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                    (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                    angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                }
            }, 10);
        });
    }
    salvarProtocoloTesteGenetico() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof this.protocoloTesteGeneticoSelecionado === 'undefined') || !this.protocoloTesteGeneticoSelecionado) {
                    return;
                }
                let listaCamposObrigatorios = '';
                if ((typeof this.protocoloTesteGeneticoSelecionado.descricao === 'undefined') || !this.protocoloTesteGeneticoSelecionado.descricao) {
                    listaCamposObrigatorios += '\r\n - <b>Descrição do Protocolo (Anexo) - Teste Genético</b>';
                }
                if (listaCamposObrigatorios && (listaCamposObrigatorios.length > 0)) {
                    let mensagem = 'Campo obrigatório não preenchido!' +
                        listaCamposObrigatorios + '\r\n \r\n' +
                        'Preencha este campo e tente novamente.';
                    if (this.ehCelular) {
                        alert(mensagem.replace(/<b>/g, '').replace(/<\/b>/g, ''));
                    }
                    else {
                        yield sweetalert2_1.default.fire({
                            html: mensagem.replace(/\r\n/g, '<br>\r\n'),
                            icon: 'info',
                            showCancelButton: false,
                            showConfirmButton: true,
                            confirmButtonText: 'Ok'
                        });
                    }
                    return;
                }
                this.modalTratamentoTesteGenetico = false;
                this.modalGerarCroquiTesteGenetico = false;
                this.modalGerarCroquiTesteGeneticoDados = null;
                this.modalImportacaoCroquiTesteGenetico = false;
                this.modalHistoricoCroquiTesteGenetico = false;
                this.modalControleManejoTesteGenetico = false;
                this.modalMedicaoDendrometricaTesteGenetico = false;
                this.modalPlanejamentoMedicaoDendrometricaTesteGenetico = false;
                this.modalPlanilhaMedicaoDendrometricaTesteGenetico = false;
                this.modalSelegenMedicaoDendrometricaTesteGenetico = false;
                this.modalQualidadeMadeiraTesteGenetico = false;
                this.modalAnaliseSelecaoTesteGenetico = false;
                this.modalSumarioAnaliseSelecaoTesteGenetico = false;
                this.modalPlanilhaSelegenAnaliseSelecaoTesteGenetico = false;
                this.modalAnaliseSelegenAnaliseSelecaoTesteGenetico = false;
                this.modalResultadoSelegenAnaliseSelecaoTesteGenetico = false;
                this.modalAnaliseAnoAnaliseSelecaoTesteGenetico = false;
                this.modalAnaliseBlupAnaliseSelecaoTesteGenetico = false;
                this.modalSelecaoGeneticaAnaliseSelecaoTesteGenetico = false;
                this.modalSelecaoCampoAnaliseSelecaoTesteGenetico = false;
                this.modalAnexoAnaliseSelecaoTesteGenetico = false;
                this.modalRelatorioTesteGenetico = false;
                this.modalAnexoTesteGenetico = false;
                this.modalDossieMaterialGenetico = false;
                this.protocoloTesteGeneticoSelecionado.testeGenetico = this.testeGeneticoSelecionado;
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-dialog > .modal-content');
                if (!this.protocoloTesteGeneticoSelecionado.id) {
                    this.protocoloTesteGeneticoSelecionado = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTOCOLO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTOCOLO_TESTE_GENETICO)
                        .criar(this.protocoloTesteGeneticoSelecionado);
                }
                else {
                    this.protocoloTesteGeneticoSelecionado = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTOCOLO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTOCOLO_TESTE_GENETICO)
                        .atualizar(this.protocoloTesteGeneticoSelecionado);
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (this.protocoloTesteGeneticoSelecionado && this.protocoloTesteGeneticoSelecionado.id) {
                    this.modalProtocoloTesteGenetico = false;
                    if (this.ehCelular) {
                        alert(`Salvo com sucesso!`);
                    }
                    else {
                        sweetalert2_1.default.fire({
                            title: 'Salvo com sucesso!',
                            text: 'O lançamento foi salvo no banco de dados.',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'Ok'
                        });
                    }
                    this.listaOpcoesProtocolosTesteGenetico = yield this.dadosListaProtocolosTesteGenetico();
                }
                this.ocultarModalProtocoloTesteGenetico();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    exibirModalProtocoloTesteGenetico(dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.testeGeneticoSelecionado === 'undefined') || !this.testeGeneticoSelecionado) {
                return;
            }
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.protocoloTesteGeneticoVisualizar = true;
                if ((typeof dados !== 'undefined') && dados && (typeof dados.id !== 'undefined') && dados.id) {
                    this.protocoloTesteGeneticoSelecionado = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTOCOLO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTOCOLO_TESTE_GENETICO)
                        .id(dados.id)
                        .ver();
                    if ((typeof this.protocoloTesteGeneticoSelecionado === 'undefined') || !this.protocoloTesteGeneticoSelecionado) {
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        if (this.ehCelular) {
                            alert(`Registro "${dados.id} não encontrado!`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                text: `Registro "${dados.id}" não encontrado.`,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        return;
                    }
                    if ((typeof this.protocoloTesteGeneticoSelecionado.criacao === 'string') && this.protocoloTesteGeneticoSelecionado.criacao) {
                        this.protocoloTesteGeneticoSelecionado.criacao = new Date(this.protocoloTesteGeneticoSelecionado.criacao);
                    }
                    if ((typeof this.protocoloTesteGeneticoSelecionado.dataHoraInclusao === 'string') && this.protocoloTesteGeneticoSelecionado.dataHoraInclusao) {
                        this.protocoloTesteGeneticoSelecionado.dataHoraInclusao = new Date(this.protocoloTesteGeneticoSelecionado.dataHoraInclusao);
                    }
                    if ((typeof this.protocoloTesteGeneticoSelecionado.dataHoraAlteracao === 'string') && this.protocoloTesteGeneticoSelecionado.dataHoraAlteracao) {
                        this.protocoloTesteGeneticoSelecionado.dataHoraAlteracao = new Date(this.protocoloTesteGeneticoSelecionado.dataHoraAlteracao);
                    }
                    if ((typeof dados.visualizar !== 'undefined') && dados.visualizar) {
                        this.protocoloTesteGeneticoVisualizar = true;
                    }
                    else if ((typeof dados.editar !== 'undefined') && dados.editar) {
                        this.protocoloTesteGeneticoVisualizar = false;
                    }
                    else if ((typeof dados.excluir !== 'undefined') && dados.excluir) {
                        const ctrl = this;
                        this.notificacao
                            .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                            .then((result) => __awaiter(this, void 0, void 0, function* () {
                            if (result.value) {
                                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTOCOLO_TESTE_GENETICO)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTOCOLO_TESTE_GENETICO)
                                    .id(dados.id)
                                    .remover();
                                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                                ctrl.listaOpcoesProtocolosTesteGenetico = yield ctrl.dadosListaProtocolosTesteGenetico();
                                ctrl.scopeApply();
                            }
                        }));
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                }
                else {
                    this.protocoloTesteGeneticoSelecionado = new ProtocoloTesteGenetico_1.ProtocoloTesteGenetico();
                    this.protocoloTesteGeneticoSelecionado.status = true;
                    this.protocoloTesteGeneticoSelecionado.criacao = new Date();
                    this.protocoloTesteGeneticoSelecionado.criacao.setMilliseconds(0);
                    this.protocoloTesteGeneticoSelecionado.dataHoraInclusao = new Date();
                    this.protocoloTesteGeneticoSelecionado.dataHoraInclusao.setMilliseconds(0);
                    this.protocoloTesteGeneticoSelecionado.usuarioInclusao = this.usuarioSimplificado();
                    this.protocoloTesteGeneticoVisualizar = false;
                }
                if ((typeof this.protocoloTesteGeneticoSelecionado.anexos === 'undefined') || (this.protocoloTesteGeneticoSelecionado.anexos === null)) {
                    this.protocoloTesteGeneticoSelecionado.anexos = new Array();
                }
                this.listasFotos.carregarDados(this.sincronia, { protocoloTesteGenetico: this.protocoloTesteGeneticoSelecionado });
                this.modalProtocoloTesteGenetico = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalProtocoloTesteGenetico() {
        this.modalProtocoloTesteGenetico = false;
        angular.element('body').css('overflow-y', '');
        globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
        this.scopeApply(() => {
            setTimeout(() => {
                angular.element('body').css('overflow-y', '');
                if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                    (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                    angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                }
            }, 10);
        });
    }
    tirarFotoControleEnxertiaPomar(ehGaleria = false) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const foto = yield DispositivoUtil_1.DispositivoUtil.tirarFoto((typeof ehGaleria !== 'undefined') ? ehGaleria : false);
                const fotoId = ArmazenamentoUtil_1.ArmazenamentoUtil.uuid();
                this.listasFotos.fotosCamera.push({
                    uuid: fotoId,
                    referencia: 'fotosControleEnxertiaTesteGeneticoSelecionado',
                    indiceIndicador: null,
                    indiceCampoIndicador: null,
                    indiceCampoCabecalho: null,
                    indiceCampoRodape: null,
                    controleManejoTesteGenetico: false,
                    controleEnxertiaPomar: true,
                    anexoTesteGenetico: false,
                    foto: foto
                });
                this.listasFotos.salvarFoto(fotoId);
                this.scopeApply();
            }
            catch (ex) {
                alert(ex.message);
            }
        });
    }
    removerFotoControleEnxertiaPomar(fotoCamera) {
        return __awaiter(this, void 0, void 0, function* () {
            if (confirm(`Deseja realmente remover essa foto?`)) {
                this.listasFotos.removerFoto(fotoCamera.uuid);
                this.scopeApply();
            }
        });
    }
    tirarFotoAnexoTesteGenetico(ehGaleria = false) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const foto = yield DispositivoUtil_1.DispositivoUtil.tirarFoto((typeof ehGaleria !== 'undefined') ? ehGaleria : false);
                const fotoId = ArmazenamentoUtil_1.ArmazenamentoUtil.uuid();
                this.listasFotos.fotosCamera.push({
                    uuid: fotoId,
                    referencia: 'fotosAnexoTesteGeneticoSelecionado',
                    indiceIndicador: null,
                    indiceCampoIndicador: null,
                    indiceCampoCabecalho: null,
                    indiceCampoRodape: null,
                    controleManejoTesteGenetico: false,
                    controleEnxertiaPomar: false,
                    anexoTesteGenetico: true,
                    foto: foto
                });
                this.listasFotos.salvarFoto(fotoId);
                this.scopeApply();
            }
            catch (ex) {
                alert(ex.message);
            }
        });
    }
    removerFotoAnexoTesteGenetico(fotoCamera) {
        return __awaiter(this, void 0, void 0, function* () {
            if (confirm(`Deseja realmente remover essa foto?`)) {
                this.listasFotos.removerFoto(fotoCamera.uuid);
                this.scopeApply();
            }
        });
    }
    verFoto(foto) {
        return __awaiter(this, void 0, void 0, function* () {
            DispositivoUtil_1.DispositivoUtil.verFoto(foto);
        });
    }
}
exports.TesteCampoController = TesteCampoController;
TesteCampoController.$inject = ['$scope', '$injector'];
