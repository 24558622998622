"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProducaoMudasSeminalController = void 0;
const angular = require("angular");
const AbstratoController_1 = require("../../../app/AbstratoController");
const ICRUDOperacao_1 = require("../../../app/interfaces/ICRUDOperacao");
const config_app_1 = require("../../../../../config/config.app");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const MaterialGenetico_1 = require("../../../../../modelos/MaterialGenetico");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const UiGridConstrutor_1 = require("../../../app/construtores/UiGridConstrutor");
const ProducaoMudasSeminal_1 = require("../../../../../modelos/ProducaoMudasSeminal");
const ProducaoMudasPlanejamento_1 = require("../../../../../modelos/ProducaoMudasPlanejamento");
const NumeroUtil_1 = require("../../../../../util/NumeroUtil");
const DispositivoUtil_1 = require("../../../../../util/DispositivoUtil");
const ProducaoMudasSemeioTestes_1 = require("../../../../../modelos/ProducaoMudasSemeioTestes");
const MaterialGeneticoSemeio_1 = require("../../../../../modelos/MaterialGeneticoSemeio");
const ProducaoMudasSemeioEnxertia_1 = require("../../../../../modelos/ProducaoMudasSemeioEnxertia");
const ProducaoMudasMetaProducao_1 = require("../../../../../modelos/ProducaoMudasMetaProducao");
const sweetalert2_1 = require("sweetalert2");
class ProducaoMudasSeminalController extends AbstratoController_1.AbstratoController {
    constructor($scope, $injector) {
        super($scope, $injector, new ProducaoMudasSeminal_1.ProducaoMudasSeminal());
        this.path = '';
        this.caminho = '';
        this.tipoOperacao = null;
        this.listaSeminal = [];
        this.listaSeminalCarregada = false;
        this.analisesFiltradas = false;
        this.seminalSelecionado = null;
        this.seminalCarregado = false;
        this.dadosTeste = null;
        this.dadosPlanejamento = null;
        this.dadosEnxertia = null;
        this.dadosMetaProducao = null;
        this.modalPlanejamento = false;
        this.modalSeminalTeste = false;
        this.modalRelatorioTeste = false;
        this.modalEnxertia = false;
        this.modalMetaProducao = false;
        this.posicaoTopoAntesModal = null;
        this.listaOpcoes = {
            data: [],
            columnDefs: [],
            headerTemplate: 'app/angularjs/modulos/cadastro/paginas/producaoMudasSeminal/template/cabecalhoEstoqueSementes.html'
        };
        this.listaOpcoesPlanejamento = {
            data: [],
            columnDefs: [],
            headerTemplate: 'app/angularjs/modulos/cadastro/paginas/producaoMudasSeminal/template/cabecalhoPlanejamento.html'
        };
        this.listaOpcoesTeste = null;
        this.listaOpcoesRelatorioTeste = null;
        this.listaOpcoesRelatorioSemeioTeste = null;
        this.listaOpcoesEnxertia = null;
        this.listaOpcoesMetaProducao = null;
        this.listaTestes = [];
        this.maxTestesPlanejamento = 0;
        this.pomares = [];
        this.especies = [];
        this.materiaisGeneticos = [];
        this.hibridacoes = [];
        this.finalidadesSemio = [];
        this.unidadesMedida = [];
        this.listaMaterialGeneticoGerminacao = [];
        this.listaMaterialGeneticoGerminacaoTeste = [];
        this.listaPlanejamentosCriados = [];
        this.listaPlanejamentos = [];
        this.metaProducaoSelecionado = null;
        this.metaProducaoCarregado = false;
        this.formMetaGerminacao = false;
        this.formMetaSobrevivenciaVegetacao = false;
        this.formMetaSobrevivenciaAclimatacao = false;
        this.polinizacaoPA = ProducaoMudasSeminal_1.ProducaoMudasSeminal.POLINIZACAO_PA;
        this.polinizacaoPC = ProducaoMudasSeminal_1.ProducaoMudasSeminal.POLINIZACAO_PC;
        this.tipoOperacao = ICRUDOperacao_1.CRUDOperacao;
        this.analisesFiltradas = false;
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.path = config_app_1.config.api.caminho;
            this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_SEMINAL;
            this.analisesFiltradas = false;
            this.modalPlanejamento = false;
            this.dadosPlanejamento = null;
            this.modalSeminalTeste = false;
            this.modalRelatorioTeste = false;
            this.dadosTeste = null;
            this.modalEnxertia = false;
            this.dadosEnxertia = null;
            this.modalMetaProducao = false;
            this.dadosMetaProducao = null;
            if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR ||
                this.operacao === ICRUDOperacao_1.CRUDOperacao.EDITAR ||
                this.operacao === ICRUDOperacao_1.CRUDOperacao.DUPLICAR) {
                this.pomares = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POMAR)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.pomares = resposta;
                    }
                });
                this.especies = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESPECIE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESPECIE)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.especies = resposta;
                    }
                });
                this.materiaisGeneticos = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MATERIAL_GENETICO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.materiaisGeneticos = resposta;
                    }
                });
                this.hibridacoes = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.HIBRIDACAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.HIBRIDACAO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.hibridacoes = resposta;
                    }
                });
            }
            if (this.operacao === ICRUDOperacao_1.CRUDOperacao.LISTAR) {
                if (!this.listaSeminalCarregada) {
                    yield this.carregarListaSeminal();
                }
                this.listaOpcoes = {
                    data: this.listaSeminal,
                    columnDefs: [],
                    headerTemplate: 'app/angularjs/modulos/cadastro/paginas/producaoMudasSeminal/template/cabecalhoEstoqueSementes.html'
                };
                this.listaOpcoesPlanejamento = {
                    data: [],
                    columnDefs: [],
                    headerTemplate: 'app/angularjs/modulos/cadastro/paginas/producaoMudasSeminal/template/cabecalhoPlanejamento.html'
                };
                this.maxTestesPlanejamento = 0;
                this.listaOpcoesTeste = {
                    data: [],
                    columnDefs: []
                };
                this.listaOpcoesRelatorioTeste = {
                    data: [],
                    columnDefs: []
                };
                this.listaOpcoesRelatorioSemeioTeste = {
                    data: [],
                    columnDefs: []
                };
                this.listaOpcoesEnxertia = {
                    data: [],
                    columnDefs: []
                };
                this.listaOpcoesMetaProducao = {
                    data: [],
                    columnDefs: []
                };
                this.materiaisGeneticos = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MATERIAL_GENETICO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.materiaisGeneticos = resposta;
                    }
                });
                this.especies = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESPECIE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESPECIE)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.especies = resposta;
                    }
                });
                this.finalidadesSemio = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.FINALIDADE_SEMEIO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.FINALIDADE_SEMEIO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.finalidadesSemio = resposta;
                    }
                });
                this.listaPlanejamentosCriados = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_PLANEJAMENTO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_PLANEJAMENTO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.listaPlanejamentosCriados = resposta;
                    }
                });
                this.unidadesMedida = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.UNIDADE_MEDIDA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.UNIDADE_MEDIDA)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.unidadesMedida = resposta;
                    }
                });
            }
            this.$scope.$apply();
        });
    }
    salvar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.modalPlanejamento = false;
                this.dadosPlanejamento = null;
                this.modalSeminalTeste = false;
                this.modalRelatorioTeste = false;
                this.dadosTeste = null;
                this.modalEnxertia = false;
                this.dadosEnxertia = null;
                this.modalMetaProducao = false;
                this.dadosMetaProducao = null;
                if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_SEMINAL)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_SEMINAL)
                        .criar(this.modelo);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_SEMINAL)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_SEMINAL)
                        .atualizar(this.modelo);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir uma nova Produção de Muda Seminal ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir uma nova Produção de Muda Seminal ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    visualizar(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const producaoMudasSeminal = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_SEMINAL)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_SEMINAL)
                    .id(id)
                    .ver();
                producaoMudasSeminal.criacao = new Date(producaoMudasSeminal.criacao);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return producaoMudasSeminal;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    remover(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const producaoMudasSeminal = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_SEMINAL)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_SEMINAL)
                    .id(id)
                    .remover();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (producaoMudasSeminal) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const producaoMudasSeminal = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_SEMINAL)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_SEMINAL)
                    .id(this.modelo.id)
                    .restaurar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (producaoMudasSeminal) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    carregarListaSeminal(filtro = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
            this.listaSeminal = yield this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_SEMINAL)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_SEMINAL)
                .filtro(filtro)
                .listar();
            if ((typeof this.listaSeminal !== 'undefined') && (this.listaSeminal !== null) && (this.listaSeminal.length > 0)) {
                angular.forEach(this.listaSeminal, (semente) => {
                    if ((typeof semente.materialGenetico !== 'undefined') && semente.materialGenetico) {
                        let itemLista = ctrl.listaMaterialGeneticoGerminacao.find(o => (o.id === semente.materialGeneticoId));
                        if ((typeof itemLista === 'undefined') || !itemLista) {
                            itemLista = semente.materialGenetico;
                            ctrl.listaMaterialGeneticoGerminacao.push(itemLista);
                        }
                    }
                });
            }
            this.listaSeminalCarregada = true;
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    dadosLista(filtro = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                this.path = config_app_1.config.api.caminho;
                this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_SEMINAL;
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                var auxColunas = Array();
                auxColunas.push({
                    name: 'codigo',
                    displayName: 'Código',
                    width: 90,
                });
                auxColunas.push({
                    name: 'materialGenetico.descricao',
                    displayName: 'Material Genético',
                    width: 140,
                });
                auxColunas.push({
                    name: 'especie.descricao',
                    displayName: 'Espécie',
                    width: 140,
                });
                auxColunas.push({
                    name: 'pomarOrigem.descricao',
                    displayName: 'Pomar',
                    width: 140,
                });
                auxColunas.push({
                    name: 'polinizacao',
                    displayName: 'Polinização',
                    width: 80,
                });
                auxColunas.push({
                    name: 'hibridacao.descricao',
                    displayName: 'Hibridação',
                    width: 140,
                });
                auxColunas.push({
                    name: 'genitoraMatriz.descricao',
                    displayName: 'Genitora/Matriz',
                    width: 140,
                });
                auxColunas.push({
                    name: 'genitor.descricao',
                    displayName: 'Genitor',
                    width: 140,
                });
                auxColunas.push({
                    name: 'estoqueSementes',
                    displayName: 'Estoque de Sementes',
                    width: 170,
                });
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
                if (!this.listaSeminalCarregada) {
                    yield this.carregarListaSeminal(filtro);
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: this.listaSeminal,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this,
                    headerTemplate: 'app/angularjs/modulos/cadastro/paginas/producaoMudasSeminal/template/cabecalhoEstoqueSementes.html'
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosListaPlanejamento() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                ctrl.listaPlanejamentos = [];
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let filtro = {
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    limiteRegistros: 100000
                };
                let lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_PLANEJAMENTO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_PLANEJAMENTO)
                    .filtro(filtro)
                    .listar();
                let auxColunas = new Array();
                ctrl.listaTestes = [];
                if ((typeof lista !== 'undefined') && (lista !== null) && (lista.length > 0)) {
                    angular.forEach(lista, (planejamento) => {
                        let itemPlanejamento = ctrl.listaPlanejamentos.find(o => ((typeof o.listaMateriaisGeneticos !== 'undefined')));
                        if ((typeof itemPlanejamento === 'undefined') || !itemPlanejamento) {
                            angular.forEach(planejamento.listaMateriaisGeneticos, (materialGenetico) => {
                                var _a, _b, _c, _d, _e, _f, _g;
                                let item = ctrl.listaSeminal.find(u => (u.materialGeneticoId === materialGenetico));
                                itemPlanejamento = {
                                    id: planejamento.id,
                                    materialGeneticoDescricao: (_a = item.materialGeneticoDescricao) !== null && _a !== void 0 ? _a : 'Não definido',
                                    especieDescricao: (_b = item.especieDescricao) !== null && _b !== void 0 ? _b : 'Não definido',
                                    pomarOrigemDescricao: (_c = item.pomarOrigemDescricao) !== null && _c !== void 0 ? _c : 'Não definido',
                                    polinizacao: (_d = item.polinizacao) !== null && _d !== void 0 ? _d : 'Não definido',
                                    hibridacaoDescricao: (_e = item.hibridacaoDescricao) !== null && _e !== void 0 ? _e : 'Não definido',
                                    genitoraMatrizDescricao: (_f = item.genitoraMatrizDescricao) !== null && _f !== void 0 ? _f : 'Não definido',
                                    genitorDescricao: (_g = item.genitorDescricao) !== null && _g !== void 0 ? _g : 'Não definido',
                                    estoqueSementes: item.estoqueSementes,
                                    numTotPlantas: planejamento.numTotPlantas,
                                    numLocais: planejamento.numLocais,
                                    testesLista: planejamento.testesGeneticos,
                                    testeGenetico: planejamento,
                                    teste1: planejamento.teste1 ? true : false,
                                    teste2: planejamento.teste2 ? true : false,
                                    teste3: planejamento.teste3 ? true : false
                                };
                                ctrl.listaPlanejamentos.push(itemPlanejamento);
                            });
                        }
                        let itemTeste = ctrl.listaPlanejamentosCriados.find(u => ((typeof u.teste !== 'undefined')));
                        if ((typeof itemTeste === 'undefined') || !itemTeste) {
                            ctrl.listaPlanejamentosCriados.push(planejamento);
                        }
                        if (ctrl.listaTestes.indexOf(planejamento.teste) === -1) {
                            ctrl.listaTestes.push(planejamento.teste);
                        }
                    });
                    lista.sort((a, b) => (a.numLocais > b.numLocais) ? 1 : ((b.numLocais > a.numLocais) ? -1 : 0));
                    ctrl.maxTestesPlanejamento = lista.at(-1).numLocais;
                }
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    posicao: 1,
                    cellClass: 'text-center',
                    cellTemplate: `
                <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                    <button type="button" class="btn btn-icon bx bx-show" style="padding-right: .4rem"
                        onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                        ng-click="grid.appScope.getParentController().abrirModalRelatorioTeste(row.entity.id)">
                    </button>
                    <button type="button" class="btn btn-icon bx bxs-edit" style="padding-right: .4rem"
                        onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                        ng-click="grid.appScope.getParentController().abrirModalPlanejamento(row.entity.id)">
                    </button>
                    <button type="button" class="btn btn-icon bx bx-trash" style="padding-left: .4rem"
                        onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                        ng-click="grid.appScope.getParentController().excluirPlanejamento(row.entity.id)">
                    </button>
                </div> `
                });
                auxColunas.push({
                    name: 'materialGeneticoDescricao',
                    displayName: 'Material Genético',
                    width: 140,
                });
                auxColunas.push({
                    name: 'especieDescricao',
                    displayName: 'Espécie',
                    width: 140,
                });
                auxColunas.push({
                    name: 'pomarOrigemDescricao',
                    displayName: 'Pomar Origem',
                    width: 140,
                });
                auxColunas.push({
                    name: 'polinizacao',
                    displayName: 'Polinização',
                    width: 100,
                });
                auxColunas.push({
                    name: 'hibridacaoDescricao',
                    displayName: 'Hibridação',
                    width: 140,
                });
                auxColunas.push({
                    name: 'genitoraMatrizDescricao',
                    displayName: 'Genitora/Matriz',
                    width: 140,
                });
                auxColunas.push({
                    name: 'genitorDescricao',
                    displayName: 'Genitor',
                    width: 140,
                });
                auxColunas.push({
                    name: 'estoqueSementes',
                    displayName: 'Estoque de Sementes',
                    width: 160,
                });
                auxColunas.push({
                    name: 'numTotPlantas',
                    displayName: 'Meta (Nº mudas)',
                    width: 130,
                });
                auxColunas.push({
                    name: 'numLocais',
                    displayName: 'Nº Testes possíveis',
                    width: 150,
                });
                for (let i = 0; i < ctrl.maxTestesPlanejamento; i++) {
                    auxColunas.push({
                        name: `teste${i + 1}`,
                        displayName: `Teste ${i + 1}`,
                        width: 90,
                        cellTemplate: `<div class='custom-control ml-1 mr-1'>
                  <input ng-model="row.entity.teste${i + 1}"
                      ng-change="grid.appScope.getParentController().atualizarTestePlanejamento(row.entity)" class="checkbox__input"
                      style="height: 16px !important; width: 16px !important; vertical-align: middle !important;"
                      type="checkbox" id="teste${i + 1}Planejamento">
              </div>`
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: ctrl.listaPlanejamentos,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this,
                    headerTemplate: 'app/angularjs/modulos/cadastro/paginas/producaoMudasSeminal/template/cabecalhoPlanejamento.html'
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            return null;
        });
    }
    dadosListaTeste() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let filtro = {
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    limiteRegistros: 100000
                };
                let lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_TESTE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_TESTE)
                    .filtro(filtro)
                    .listar();
                let auxColunas = new Array();
                let listaSeminalTeste = [];
                if ((typeof lista !== 'undefined') && (lista !== null) && (lista.length > 0)) {
                    angular.forEach(lista, (teste) => {
                        let itemTeste = listaSeminalTeste.find(o => ((typeof o.materiaisGeneticosSemeio !== 'undefined') ||
                            (typeof o.testeGenetico !== 'undefined')));
                        let itemPlanejamento = ctrl.listaPlanejamentos.find(o => ((typeof o.id !== 'undefined') &&
                            teste.testeGenetico.id === o.id));
                        if ((typeof itemTeste === 'undefined') || !itemTeste) {
                            angular.forEach(teste.materiaisGeneticosSemeio, (materialSemeio) => {
                                let materialAtual = this.materiaisGeneticos.find(o => ((typeof materialSemeio.producaoMudaTeste !== 'undefined') &&
                                    materialSemeio.producaoMudaTeste === o.id));
                                itemTeste = {
                                    id: teste.id,
                                    finalidadeSemeioDescricao: teste.finalidadeSemeio && teste.finalidadeSemeio.descricao ? teste.finalidadeSemeio.descricao : null,
                                    especieDescricao: teste.especie && teste.especie.descricao ? teste.especie.descricao : null,
                                    dataSemeio: teste.dataSemeio,
                                    materialGeneticoDescricao: materialAtual && materialAtual.descricao ? materialAtual.descricao : '',
                                    pomarOrigemDescricao: itemPlanejamento && itemPlanejamento.pomarOrigemDescricao ? itemPlanejamento.pomarOrigemDescricao : 'Não definido',
                                    polinizacao: itemPlanejamento && itemPlanejamento.polinizacao ? itemPlanejamento.polinizacao : 'Não definido',
                                    quantidadeUnidade: materialSemeio.quantidade ? materialSemeio.quantidade : 0
                                };
                                listaSeminalTeste.push(itemTeste);
                            });
                        }
                    });
                }
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    posicao: 1,
                    cellClass: 'text-center',
                    cellTemplate: `
                <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                    <button type="button" class="btn btn-icon bx bxs-edit" style="padding-right: .4rem"
                        onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                        ng-click="grid.appScope.getParentController().abrirModalTeste(row.entity.id)">
                    </button>
                    <button type="button" class="btn btn-icon bx bx-trash" style="padding-left: .4rem"
                        onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                        ng-click="grid.appScope.getParentController().excluirTeste(row.entity.id)">
                    </button>
                </div> `
                });
                auxColunas.push({
                    name: 'dataSemeio',
                    displayName: 'Data de Semeio',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 120,
                });
                auxColunas.push({
                    name: 'finalidadeSemeioDescricao',
                    displayName: 'Finalidade Semeio',
                    width: 140,
                });
                auxColunas.push({
                    name: 'especieDescricao',
                    displayName: 'Espécie',
                    width: 140,
                });
                auxColunas.push({
                    name: 'materialGeneticoDescricao',
                    displayName: 'Material Genético',
                    width: 140,
                });
                auxColunas.push({
                    name: 'polinizacao',
                    displayName: 'Polinização',
                    width: 80,
                });
                auxColunas.push({
                    name: 'pomarOrigemDescricao',
                    displayName: 'Origem',
                    width: 140,
                });
                auxColunas.push({
                    name: 'quantidadeUnidade',
                    displayName: 'Quant.',
                    width: 120,
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: listaSeminalTeste,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            return null;
        });
    }
    dadosListaEnxertia() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let filtro = {
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    limiteRegistros: 100000
                };
                let lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_ENXERTIA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_ENXERTIA)
                    .filtro(filtro)
                    .listar();
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    posicao: 1,
                    cellClass: 'text-center',
                    cellTemplate: `
                <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                    <button type="button" class="btn btn-icon bx bxs-edit" style="padding-right: .4rem"
                        onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                        ng-click="grid.appScope.getParentController().abrirModalEnxertia(row.entity.id)">
                    </button>
                    <button type="button" class="btn btn-icon bx bx-trash" style="padding-left: .4rem"
                        onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                        ng-click="grid.appScope.getParentController().excluirEnxertia(row.entity.id)">
                    </button>
                </div> `
                });
                auxColunas.push({
                    name: 'dataSemeio',
                    displayName: 'Data de Semeio',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 120,
                });
                auxColunas.push({
                    name: 'quantidadeG',
                    displayName: 'Quantidade (g)',
                    width: 120,
                });
                auxColunas.push({
                    name: 'numMudasNecessarias',
                    displayName: 'Nº Mudas Necessárias',
                    width: 120,
                });
                auxColunas.push({
                    name: 'sobrevivencia',
                    displayName: 'Sobrevivência',
                    width: 120,
                });
                auxColunas.push({
                    name: 'dataEnxertia',
                    displayName: 'Data Enxertia',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 120,
                });
                auxColunas.push({
                    name: 'numEnxertosRealizados',
                    displayName: 'Nº Enxertos Realizados',
                    width: 120,
                });
                auxColunas.push({
                    name: 'sobrevivenciaEnxerto',
                    displayName: 'Sobrevivência Enxerto',
                    width: 120,
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosListaMetaProducao() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let filtro = {
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    limiteRegistros: 100000
                };
                let lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_META_PRODUCAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_META_PRODUCAO)
                    .filtro(filtro)
                    .listar();
                let auxColunas = new Array();
                let listaMetaProducao = [];
                let itemLista = {};
                let listaCodigos = [];
                if ((typeof ctrl.listaPlanejamentos !== 'undefined') && (ctrl.listaPlanejamentos !== null) && (ctrl.listaPlanejamentos.length > 0)) {
                    angular.forEach(ctrl.listaPlanejamentos, (planejamento) => {
                        var _a, _b, _c, _d, _e, _f;
                        let itemMeta = listaMetaProducao.find(o => (((typeof planejamento.materialGeneticoDescricao !== 'undefined') && planejamento.materialGeneticoDescricao) &&
                            ((typeof planejamento.numTotPlantas !== 'undefined') && planejamento.numTotPlantas) &&
                            ((typeof planejamento.id !== 'undefined') && planejamento.id) &&
                            (o.materialGeneticoDescricao === planejamento.materialGeneticoDescricao) &&
                            (o.numTotPlantas === planejamento.numTotPlantas) &&
                            (o.id === planejamento.id)));
                        if ((typeof itemMeta === 'undefined') || !itemMeta) {
                            let itemApi = lista.find(u => ((typeof u.testeGenetico !== 'undefined') &&
                                (u.testeGenetico.id === planejamento.id) &&
                                (!listaCodigos.includes(u.codigo))));
                            if ((typeof itemApi === 'undefined') || !itemApi) {
                                itemLista = {
                                    id: '',
                                    planejamentoId: planejamento.id,
                                    materialGeneticoDescricao: planejamento.materialGeneticoDescricao,
                                    numTotPlantas: planejamento.numTotPlantas,
                                    dataSemeio: '',
                                    germinacao: 0,
                                    dataSobrevivenciaVegetacao: '',
                                    sobrevivenciaVegetacao: 0,
                                    dataSobrevivenciaAclimaticacao: '',
                                    sobrevivenciaAclimaticacao: 0,
                                    testeGenetico: planejamento
                                };
                            }
                            else {
                                itemLista = {
                                    id: itemApi.id,
                                    planejamentoId: planejamento.id,
                                    materialGeneticoDescricao: planejamento.materialGeneticoDescricao,
                                    numTotPlantas: planejamento.numTotPlantas,
                                    dataSemeio: (_a = itemApi.dataGerminacao) !== null && _a !== void 0 ? _a : '',
                                    germinacao: (_b = itemApi.germinacao) !== null && _b !== void 0 ? _b : 0,
                                    dataSobrevivenciaVegetacao: (_c = itemApi.dataSobrevivenciaVegetacao) !== null && _c !== void 0 ? _c : '',
                                    sobrevivenciaVegetacao: (_d = itemApi.sobrevivenciaVegetacao) !== null && _d !== void 0 ? _d : 0,
                                    dataSobrevivenciaAclimaticacao: (_e = itemApi.dataSobrevivenciaAclimaticacao) !== null && _e !== void 0 ? _e : '',
                                    sobrevivenciaAclimaticacao: (_f = itemApi.sobrevivenciaAclimaticacao) !== null && _f !== void 0 ? _f : 0,
                                    testeGenetico: planejamento
                                };
                                if (listaCodigos.indexOf(itemApi.codigo) === -1) {
                                    listaCodigos.push(itemApi.codigo);
                                }
                            }
                            listaMetaProducao.push(itemLista);
                        }
                    });
                }
                auxColunas.push({
                    name: 'materialGeneticoDescricao',
                    displayName: 'Material Genético',
                    width: 140,
                });
                auxColunas.push({
                    name: 'numTotPlantas',
                    displayName: 'Meta (Nº mudas)',
                    width: 100,
                });
                auxColunas.push({
                    name: 'dataSemeio',
                    displayName: 'Data Germinação',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 120,
                });
                auxColunas.push({
                    name: 'germinacao',
                    displayName: 'Germinação',
                    width: 100,
                });
                auxColunas.push({
                    name: 'dataSobrevivenciaVegetacao',
                    displayName: 'Data Sobrevivência',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 120,
                });
                auxColunas.push({
                    name: 'sobrevivenciaVegetacao',
                    displayName: 'Sobrevivência casa de Vegetação',
                    width: 100,
                });
                auxColunas.push({
                    name: 'dataSobrevivenciaAclimaticacao',
                    displayName: 'Data Sobrevivência',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 120,
                });
                auxColunas.push({
                    name: 'sobrevivenciaAclimaticacao',
                    displayName: 'Sobrevivência Aclimatação',
                    width: 100,
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: listaMetaProducao,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            return null;
        });
    }
    definirHibrido() {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        return __awaiter(this, void 0, void 0, function* () {
            let especieMae = '';
            let especiePai = '';
            let matMae = '';
            let matPai = '';
            if ((typeof this.modelo.genitoraMatriz === 'undefined' || !this.modelo.genitoraMatriz) ||
                (typeof this.modelo.genitoraMatriz.especie === 'undefined' || !this.modelo.genitoraMatriz.especie)) {
                especieMae = (_a = this.modelo.materialGenetico.especieMae.descricao) !== null && _a !== void 0 ? _a : '';
            }
            else {
                especieMae = (_b = this.modelo.genitoraMatriz.especie.descricao) !== null && _b !== void 0 ? _b : '';
            }
            if ((typeof this.modelo.genitor === 'undefined' || !this.modelo.genitor) ||
                (typeof this.modelo.genitor.especie === 'undefined' || !this.modelo.genitor.especie)) {
                especiePai = (_c = this.modelo.materialGenetico.especiePai.descricao) !== null && _c !== void 0 ? _c : '';
            }
            else {
                especiePai = (_d = this.modelo.genitor.especie.descricao) !== null && _d !== void 0 ? _d : '';
            }
            if (typeof this.modelo.genitoraMatriz === 'undefined' || !this.modelo.genitoraMatriz) {
                matMae = (_e = this.modelo.materialGenetico.mae.descricao) !== null && _e !== void 0 ? _e : '';
            }
            else {
                matMae = (_f = this.modelo.genitoraMatriz.descricao) !== null && _f !== void 0 ? _f : '';
            }
            if (typeof this.modelo.genitor === 'undefined' || !this.modelo.genitor) {
                matPai = (_g = this.modelo.materialGenetico.pai.descricao) !== null && _g !== void 0 ? _g : '';
            }
            else {
                matPai = (_h = this.modelo.genitor.descricao) !== null && _h !== void 0 ? _h : '';
            }
            this.modelo.hibrido = especieMae + ' x ' + especiePai;
            this.modelo.cruzamento = matMae + ' x ' + matPai;
            this.scopeApply();
        });
    }
    filtroRelatorioTeste(id = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                let listaRelatorio = [];
                let auxColunas = new Array();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if ((typeof ctrl.listaPlanejamentos !== 'undefined') && (ctrl.listaPlanejamentos !== null) && (ctrl.listaPlanejamentos.length > 0)) {
                    listaRelatorio = ctrl.listaPlanejamentos.filter(o => (o.id === id));
                }
                auxColunas.push({
                    name: 'materialGeneticoDescricao',
                    displayName: 'Material Genético',
                    width: 140,
                });
                auxColunas.push({
                    name: 'especieDescricao',
                    displayName: 'Espécie',
                    width: 140,
                });
                auxColunas.push({
                    name: 'pomarOrigemDescricao',
                    displayName: 'Pomar Origem',
                    width: 140,
                });
                auxColunas.push({
                    name: 'polinizacao',
                    displayName: 'Polinização',
                    width: 100,
                });
                auxColunas.push({
                    name: 'hibridacaoDescricao',
                    displayName: 'Hibridação',
                    width: 140,
                });
                auxColunas.push({
                    name: 'genitoraMatrizDescricao',
                    displayName: 'Genitora/Matriz',
                    width: 140,
                });
                auxColunas.push({
                    name: 'genitorDescricao',
                    displayName: 'Genitor',
                    width: 140,
                });
                auxColunas.push({
                    name: 'estoqueSementes',
                    displayName: 'Estoque de Sementes',
                    width: 160,
                });
                auxColunas.push({
                    name: 'numTotPlantas',
                    displayName: 'Meta (Nº mudas)',
                    width: 130,
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: listaRelatorio,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this,
                    headerTemplate: 'app/angularjs/modulos/cadastro/paginas/producaoMudasSeminal/template/cabecalhoPlanejamento.html'
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            return null;
        });
    }
    filtroRelatorioSemeioTeste(id = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                let listaRelatorio = [];
                let auxColunas = new Array();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if ((typeof ctrl.listaPlanejamentos !== 'undefined') && (ctrl.listaPlanejamentos !== null) && (ctrl.listaPlanejamentos.length > 0)) {
                    listaRelatorio = ctrl.listaPlanejamentos.filter(o => (o.id === id));
                }
                auxColunas.push({
                    name: 'materialGeneticoDescricao',
                    displayName: 'Material Genético',
                    width: 140,
                });
                auxColunas.push({
                    name: 'especieDescricao',
                    displayName: 'Espécie',
                    width: 140,
                });
                auxColunas.push({
                    name: 'pomarOrigemDescricao',
                    displayName: 'Pomar Origem',
                    width: 140,
                });
                auxColunas.push({
                    name: 'polinizacao',
                    displayName: 'Polinização',
                    width: 100,
                });
                auxColunas.push({
                    name: 'hibridacaoDescricao',
                    displayName: 'Hibridação',
                    width: 140,
                });
                auxColunas.push({
                    name: 'genitoraMatrizDescricao',
                    displayName: 'Genitora/Matriz',
                    width: 140,
                });
                auxColunas.push({
                    name: 'genitorDescricao',
                    displayName: 'Genitor',
                    width: 140,
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: listaRelatorio,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this,
                    headerTemplate: 'app/angularjs/modulos/cadastro/paginas/producaoMudasSeminal/template/cabecalhoPlanejamento.html'
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            return null;
        });
    }
    cellClicked(row) {
        if (row.entity && typeof row.entity.id !== 'undefined' && row.entity.id) {
            const nomeTabelaEstoqueSementes = 'tabelaEstoqueSementes';
            let gridEstoqueSementesApi = null;
            let gridEstoqueSementesScope = angular.element('#' + nomeTabelaEstoqueSementes).scope();
            if ((typeof gridEstoqueSementesScope.listaGridApi !== 'undefined') && gridEstoqueSementesScope.listaGridApi) {
                angular.forEach(gridEstoqueSementesScope.listaGridApi, (gridApi) => {
                    const grid = (typeof gridApi.grid !== 'undefined') && gridApi.grid ? gridApi.grid : null;
                    if (grid && typeof grid.element !== 'undefined' && grid.element) {
                        const tabelasEl = angular.element(grid.element).parents('tabela');
                        if (tabelasEl && (tabelasEl.length > 0) && (tabelasEl.first().attr('id') === nomeTabelaEstoqueSementes)) {
                            gridEstoqueSementesApi = gridApi;
                        }
                    }
                });
            }
            if (gridEstoqueSementesApi && (typeof gridEstoqueSementesApi.selection !== 'undefined') && gridEstoqueSementesApi.selection) {
                gridEstoqueSementesApi.selection.selectRow(row.entity);
            }
        }
    }
    filtrar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    limparFiltro() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    limparFiltroSeminal() {
        return __awaiter(this, void 0, void 0, function* () {
            this.seminalSelecionado = null;
            this.seminalCarregado = false;
        });
    }
    formatarNumero(valor, casasDecimais) {
        return NumeroUtil_1.NumeroUtil.formatarNumero(valor, casasDecimais);
    }
    selecionarSeminal() {
        return __awaiter(this, void 0, void 0, function* () {
            let ctrl = this;
            if (ctrl && ctrl.constructor && ctrl.constructor.name) {
                if (ctrl.constructor.name === 'TabelaController') {
                    ctrl = ctrl.$scope.$parent.$ctrl;
                }
            }
            ctrl.filtrarSeminalSelecionado();
        });
    }
    filtrarSeminalSementes() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.filtrarSeminalSelecionado();
                if (!this.seminalSelecionado &&
                    this.seminalSelecionado &&
                    this.seminalSelecionado.id) {
                    this.seminalCarregado = true;
                    this.scopeApply();
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
        });
    }
    filtrarSeminalSelecionado() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                let seminal = null;
                const nomeTabelaEstoqueSementes = 'tabelaEstoqueSementes';
                let gridEstoqueSementesApi = null;
                let gridEstoqueSementesScope = angular
                    .element('#' + nomeTabelaEstoqueSementes)
                    .scope();
                if (typeof gridEstoqueSementesScope.listaGridApi !== 'undefined' &&
                    gridEstoqueSementesScope.listaGridApi) {
                    angular.forEach(gridEstoqueSementesScope.listaGridApi, (gridApi) => {
                        const grid = typeof gridApi.grid !== 'undefined' && gridApi.grid
                            ? gridApi.grid
                            : null;
                        if (grid && typeof grid.element !== 'undefined' && grid.element) {
                            const tabelasEl = angular.element(grid.element).parents('tabela');
                            if (tabelasEl &&
                                tabelasEl.length > 0 &&
                                tabelasEl.first().attr('id') === nomeTabelaEstoqueSementes) {
                                gridEstoqueSementesApi = gridApi;
                            }
                        }
                    });
                }
                if (gridEstoqueSementesApi) {
                    const seminaisSelecionados = gridEstoqueSementesApi.selection.getSelectedRows();
                    if (typeof seminaisSelecionados !== 'undefined' &&
                        seminaisSelecionados) {
                        angular.forEach(seminaisSelecionados, (obj) => {
                            if (!seminal) {
                                seminal = obj;
                            }
                        });
                    }
                }
                if (seminal && typeof seminal.id !== 'undefined' && seminal.id) {
                    if (typeof ctrl.seminalSelecionado !== 'undefined' &&
                        ctrl.seminalSelecionado &&
                        typeof ctrl.seminalSelecionado.id !== 'undefined' &&
                        ctrl.seminalSelecionado.id) {
                        if (ctrl.seminalSelecionado.id !== seminal.id) {
                            yield ctrl.limparFiltroSeminal();
                            ctrl.seminalSelecionado = seminal;
                            ctrl.seminalCarregado = false;
                        }
                    }
                    else {
                        yield ctrl.limparFiltroSeminal();
                        ctrl.seminalSelecionado = seminal;
                        ctrl.seminalCarregado = false;
                    }
                }
                else {
                    if (ctrl.seminalSelecionado || ctrl.seminalCarregado) {
                        yield ctrl.limparFiltroSeminal();
                        ctrl.seminalCarregado = false;
                    }
                }
                ctrl.scopeApply();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
        });
    }
    salvarPlanejamento() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                this.modalSeminalTeste = false;
                this.modalRelatorioTeste = false;
                this.modalEnxertia = false;
                this.modalMetaProducao = false;
                if (typeof this.dadosPlanejamento.id === 'undefined' || !this.dadosPlanejamento.id) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_PLANEJAMENTO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_PLANEJAMENTO)
                        .criar(this.dadosPlanejamento);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_PLANEJAMENTO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_PLANEJAMENTO)
                        .atualizar(this.dadosPlanejamento);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir uma novo Planejamento de Testes ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir uma novo Planejamento de Testes ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.ocultarModalPlanejamento();
                this.filtrarAnalises(true);
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
                this.ocultarModalPlanejamento();
            }
            finally {
                this.ocultarModalPlanejamento();
            }
        });
    }
    atualizarTestePlanejamento(planejamento) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof planejamento === 'undefined') || !planejamento ||
                (typeof planejamento.id === 'undefined') || !planejamento.id) {
                return;
            }
            let planejamento2 = yield this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_PLANEJAMENTO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_PLANEJAMENTO)
                .id(planejamento.id)
                .ver();
            if ((typeof planejamento2 !== 'undefined') && planejamento2) {
                planejamento2.teste1 = ((typeof planejamento.teste1 !== 'undefined') && planejamento.teste1) ? true : false;
                planejamento2.teste2 = ((typeof planejamento.teste2 !== 'undefined') && planejamento.teste2) ? true : false;
                planejamento2.teste3 = ((typeof planejamento.teste3 !== 'undefined') && planejamento.teste3) ? true : false;
                yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_PLANEJAMENTO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_PLANEJAMENTO)
                    .atualizar(planejamento2);
            }
        });
    }
    abrirModalPlanejamento(id = null) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if ((typeof id !== 'undefined') && id) {
                    this.dadosPlanejamento = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_PLANEJAMENTO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_PLANEJAMENTO)
                        .id(id)
                        .ver();
                }
                else {
                    this.dadosPlanejamento = new ProducaoMudasPlanejamento_1.ProducaoMudasPlanejamento();
                }
                this.modalPlanejamento = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    excluirPlanejamento(id) {
        return __awaiter(this, void 0, void 0, function* () {
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            const ctrl = this;
            this.notificacao
                .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                .then((result) => __awaiter(this, void 0, void 0, function* () {
                if (result.value) {
                    CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_PLANEJAMENTO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_PLANEJAMENTO)
                        .id(id)
                        .remover();
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.listaOpcoesPlanejamento = yield ctrl.dadosListaPlanejamento();
                    ctrl.scopeApply();
                }
            }));
        });
    }
    ocultarModalPlanejamento() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalPlanejamento = false;
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    adicionarRegistroListaMateriaisGeneticosPlanejamento() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.dadosPlanejamento === 'undefined' ||
                !this.dadosPlanejamento) {
                this.dadosPlanejamento = new ProducaoMudasPlanejamento_1.ProducaoMudasPlanejamento();
            }
            if (typeof this.dadosPlanejamento.listaMateriaisGeneticos === 'undefined' ||
                this.dadosPlanejamento.listaMateriaisGeneticos === null ||
                !this.dadosPlanejamento.listaMateriaisGeneticos) {
                this.dadosPlanejamento.listaMateriaisGeneticos = new Array();
            }
            if (typeof this.dadosPlanejamento.numLocais === 'undefined' ||
                this.dadosPlanejamento.numLocais === null ||
                !this.dadosPlanejamento.numLocais) {
                this.dadosPlanejamento.numLocais = 0;
            }
            this.dadosPlanejamento.listaMateriaisGeneticos.push(new MaterialGenetico_1.MaterialGenetico());
            this.dadosPlanejamento.numLocais = (_a = this.dadosPlanejamento.listaMateriaisGeneticos.length) !== null && _a !== void 0 ? _a : 0;
            this.calcularTotPlantas();
            this.scopeApply();
        });
    }
    removerRegistroListaMateriaisGeneticosPlanejamento(key) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.dadosPlanejamento.listaMateriaisGeneticos === 'undefined' ||
                this.dadosPlanejamento.listaMateriaisGeneticos === null) {
                this.dadosPlanejamento.listaMateriaisGeneticos = new Array();
            }
            if (typeof this.dadosPlanejamento.numLocais === 'undefined' ||
                this.dadosPlanejamento.numLocais === null ||
                !this.dadosPlanejamento.numLocais) {
                this.dadosPlanejamento.numLocais = 0;
            }
            if (typeof key !== 'undefined' &&
                key !== null &&
                this.dadosPlanejamento.listaMateriaisGeneticos.length > 0 &&
                key <= this.dadosPlanejamento.listaMateriaisGeneticos.length - 1) {
                this.dadosPlanejamento.listaMateriaisGeneticos.splice(key, 1);
                this.dadosPlanejamento.numLocais = (_a = this.dadosPlanejamento.listaMateriaisGeneticos.length) !== null && _a !== void 0 ? _a : 0;
                this.calcularTotPlantas();
                this.scopeApply();
            }
        });
    }
    moverRegistroListaMateriaisGeneticosPlanejamentoParaCima(key) {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.dadosPlanejamento.listaMateriaisGeneticos === 'undefined' ||
                !this.dadosPlanejamento.listaMateriaisGeneticos) {
                return;
            }
            if (key != null && key >= 0 && key < this.dadosPlanejamento.listaMateriaisGeneticos.length - 1) {
                let oldKey = key;
                let newKey = key - 1;
                this.dadosPlanejamento.listaMateriaisGeneticos.splice(newKey, 0, this.dadosPlanejamento.listaMateriaisGeneticos.splice(oldKey, 1)[0]);
                this.scopeApply();
            }
        });
    }
    moverRegistroListaMateriaisGeneticosPlanejamentoParaBaixo(key) {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.dadosPlanejamento.listaMateriaisGeneticos === 'undefined' ||
                !this.dadosPlanejamento.listaMateriaisGeneticos) {
                return;
            }
            if (key != null && key >= 0 && key < this.dadosPlanejamento.listaMateriaisGeneticos.length - 1) {
                let oldKey = key;
                let newKey = key + 1;
                this.dadosPlanejamento.listaMateriaisGeneticos.splice(newKey, 0, this.dadosPlanejamento.listaMateriaisGeneticos.splice(oldKey, 1)[0]);
                this.scopeApply();
            }
        });
    }
    calcularTotPlantas() {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.dadosPlanejamento === 'undefined' ||
                !this.dadosPlanejamento) {
                return;
            }
            let blocos = (_a = this.dadosPlanejamento.numBlocos) !== null && _a !== void 0 ? _a : 0;
            let plantas = (_b = this.dadosPlanejamento.numPlantasXParcela) !== null && _b !== void 0 ? _b : 0;
            let locais = (_c = this.dadosPlanejamento.numLocais) !== null && _c !== void 0 ? _c : 0;
            let replantio = (_d = this.dadosPlanejamento.replantio) !== null && _d !== void 0 ? _d : 0;
            this.dadosPlanejamento.numTotPlantas = (blocos * plantas * locais) + replantio;
        });
    }
    salvarTeste() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                this.modalPlanejamento = false;
                this.modalRelatorioTeste = false;
                this.modalEnxertia = false;
                this.modalMetaProducao = false;
                if (typeof this.dadosTeste.id === 'undefined' || !this.dadosTeste.id) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_TESTE)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_TESTE)
                        .criar(this.dadosTeste);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_TESTE)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_TESTE)
                        .atualizar(this.dadosTeste);
                }
                if ((typeof ctrl.listaSeminal !== 'undefined') && ctrl.listaSeminal &&
                    (typeof this.dadosTeste.materiaisGeneticosSemeio !== 'undefined') && this.dadosTeste.materiaisGeneticosSemeio) {
                    let atualizouEstoqueSementes = false;
                    let materiaisGeneticosSemeio = new Array();
                    angular.forEach(this.dadosTeste.materiaisGeneticosSemeio, (materialGeneticoSemeio) => __awaiter(this, void 0, void 0, function* () {
                        materiaisGeneticosSemeio.push(materialGeneticoSemeio);
                    }));
                    for (let materialGeneticoSemeioIdx = 0; materialGeneticoSemeioIdx < materiaisGeneticosSemeio.length; materialGeneticoSemeioIdx++) {
                        const materialGeneticoSemeio = materiaisGeneticosSemeio[materialGeneticoSemeioIdx];
                        if ((typeof materialGeneticoSemeio.producaoMudaTeste !== 'undefined') && materialGeneticoSemeio.producaoMudaTeste &&
                            (typeof materialGeneticoSemeio.producaoMudaTeste.id !== 'undefined') && materialGeneticoSemeio.producaoMudaTeste.id &&
                            (typeof materialGeneticoSemeio.quantidade !== 'undefined') && materialGeneticoSemeio.quantidade) {
                            let producaoMudasSeminal = ctrl.listaSeminal.find(o => o.materialGenetico && o.materialGenetico.id && o.materialGenetico.id === materialGeneticoSemeio.producaoMudaTeste.id);
                            if ((typeof producaoMudasSeminal === 'undefined') || !producaoMudasSeminal) {
                                producaoMudasSeminal = ctrl.listaSeminal.find(o => o.materialGeneticoId && o.materialGeneticoId === materialGeneticoSemeio.producaoMudaTeste.id);
                            }
                            if ((typeof producaoMudasSeminal !== 'undefined') && producaoMudasSeminal &&
                                (typeof producaoMudasSeminal.id !== 'undefined') && producaoMudasSeminal.id) {
                                let producaoMudasSeminal2 = yield this.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_SEMINAL)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_SEMINAL)
                                    .id(producaoMudasSeminal.id)
                                    .ver();
                                if ((typeof producaoMudasSeminal2 !== 'undefined') && producaoMudasSeminal2) {
                                    if ((typeof producaoMudasSeminal2.estoqueSementes === 'undefined') || !producaoMudasSeminal2.estoqueSementes) {
                                        producaoMudasSeminal2.estoqueSementes = 0;
                                    }
                                    producaoMudasSeminal2.estoqueSementes -= materialGeneticoSemeio.quantidade;
                                    yield this.sincronia
                                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_SEMINAL)
                                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_SEMINAL)
                                        .atualizar(producaoMudasSeminal2);
                                    atualizouEstoqueSementes = true;
                                }
                            }
                        }
                    }
                    if (atualizouEstoqueSementes) {
                        this.listaSeminal = [];
                        this.listaSeminalCarregada = false;
                        if (this.listaOpcoes) {
                            const lista = yield this.dadosLista();
                            this.listaOpcoes.data = lista.data;
                        }
                        else {
                            this.listaOpcoes = yield this.dadosLista();
                        }
                        this.scopeApply();
                    }
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir uma novo Semeio de Teste e Bordadura ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir uma novo Semeio de Teste e Bordadura ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.ocultarModalTeste();
                this.filtrarAnalises(true);
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
                this.ocultarModalTeste();
            }
            finally {
                this.ocultarModalTeste();
            }
        });
    }
    abrirModalTeste(id = null) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if ((typeof id !== 'undefined') && id) {
                    this.dadosTeste = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_TESTE)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_TESTE)
                        .id(id)
                        .ver();
                    if (this.dadosTeste) {
                        if (typeof this.dadosTeste.dataSemeio === 'string') {
                            this.dadosTeste.dataSemeio = new Date(this.dadosTeste.dataSemeio);
                        }
                        this.atualizarMateriaisGeneticosTestes();
                    }
                }
                else {
                    this.dadosTeste = new ProducaoMudasSemeioTestes_1.ProducaoMudasSemeioTestes();
                    this.dadosTeste.materiaisGeneticosSemeio = new Array();
                    this.atualizarMateriaisGeneticosTestes();
                }
                this.modalSeminalTeste = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    excluirTeste(id) {
        return __awaiter(this, void 0, void 0, function* () {
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            const ctrl = this;
            this.notificacao
                .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                .then((result) => __awaiter(this, void 0, void 0, function* () {
                if (result.value) {
                    CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_TESTE)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_TESTE)
                        .id(id)
                        .remover();
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.listaOpcoesTeste = yield ctrl.dadosListaTeste();
                    ctrl.scopeApply();
                }
            }));
        });
    }
    ocultarModalTeste() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalSeminalTeste = false;
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    adicionarRegistroListaMateriaisGeneticosTeste() {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.dadosTeste === 'undefined' ||
                !this.dadosTeste) {
                this.dadosTeste = new ProducaoMudasSemeioTestes_1.ProducaoMudasSemeioTestes();
            }
            if (typeof this.dadosTeste.materiaisGeneticosSemeio === 'undefined' ||
                this.dadosTeste.materiaisGeneticosSemeio === null ||
                !this.dadosTeste.materiaisGeneticosSemeio) {
                this.dadosTeste.materiaisGeneticosSemeio = new Array();
            }
            this.dadosTeste.materiaisGeneticosSemeio.push(new MaterialGeneticoSemeio_1.MaterialGeneticoSemeio());
            this.scopeApply();
        });
    }
    removerRegistroListaMateriaisGeneticosTeste(key) {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.dadosTeste.materiaisGeneticosSemeio === 'undefined' ||
                this.dadosTeste.materiaisGeneticosSemeio === null) {
                this.dadosTeste.materiaisGeneticosSemeio = new Array();
            }
            if (typeof key !== 'undefined' &&
                key !== null &&
                this.dadosTeste.materiaisGeneticosSemeio.length > 0 &&
                key <= this.dadosTeste.materiaisGeneticosSemeio.length - 1) {
                this.dadosTeste.materiaisGeneticosSemeio.splice(key, 1);
                this.scopeApply();
            }
        });
    }
    moverRegistroListaMateriaisGeneticosTesteParaCima(key) {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.dadosTeste.materiaisGeneticosSemeio === 'undefined' ||
                !this.dadosTeste.materiaisGeneticosSemeio) {
                return;
            }
            if (key != null && key >= 0 && key < this.dadosTeste.materiaisGeneticosSemeio.length - 1) {
                let oldKey = key;
                let newKey = key - 1;
                this.dadosTeste.materiaisGeneticosSemeio.splice(newKey, 0, this.dadosTeste.materiaisGeneticosSemeio.splice(oldKey, 1)[0]);
                this.scopeApply();
            }
        });
    }
    moverRegistroListaMateriaisGeneticosTesteParaBaixo(key) {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.dadosTeste.materiaisGeneticosSemeio === 'undefined' ||
                !this.dadosTeste.materiaisGeneticosSemeio) {
                return;
            }
            if (key != null && key >= 0 && key < this.dadosTeste.materiaisGeneticosSemeio.length - 1) {
                let oldKey = key;
                let newKey = key + 1;
                this.dadosTeste.materiaisGeneticosSemeio.splice(newKey, 0, this.dadosTeste.materiaisGeneticosSemeio.splice(oldKey, 1)[0]);
                this.scopeApply();
            }
        });
    }
    atualizarMateriaisGeneticosTestes() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            if ((typeof this.dadosTeste === 'undefined') || !this.dadosTeste ||
                (typeof this.dadosTeste.testeGenetico === 'undefined') || !this.dadosTeste.testeGenetico) {
                return;
            }
            let itemTeste = ctrl.listaPlanejamentosCriados.find(u => ((typeof u.teste !== 'undefined') &&
                (u.id === ctrl.dadosTeste.testeGenetico.id) &&
                (u.teste === ctrl.dadosTeste.testeGenetico.teste)));
            if (((typeof itemTeste !== 'undefined') && itemTeste) &&
                (typeof itemTeste.listaMateriaisGeneticos !== 'undefined')) {
                angular.forEach(itemTeste.listaMateriaisGeneticos, (materialGenetico) => {
                    let itemMaterial = ctrl.materiaisGeneticos.find(u => ((typeof materialGenetico !== 'undefined') &&
                        materialGenetico === u.id));
                    if ((typeof itemMaterial !== 'undefined') && itemMaterial) {
                        ctrl.listaMaterialGeneticoGerminacaoTeste.push(itemMaterial);
                    }
                });
            }
        });
    }
    abrirModalRelatorioTeste(id = null) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.listaOpcoesRelatorioTeste = yield this.filtroRelatorioTeste(id);
                this.listaOpcoesRelatorioSemeioTeste = yield this.filtroRelatorioSemeioTeste(id);
                this.modalRelatorioTeste = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalRelatorioTeste() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalRelatorioTeste = false;
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    salvarEnxertia() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                this.modalPlanejamento = false;
                this.modalSeminalTeste = false;
                this.modalMetaProducao = false;
                if (typeof this.dadosEnxertia.id === 'undefined' || !this.dadosEnxertia.id) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_ENXERTIA)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_ENXERTIA)
                        .criar(this.dadosEnxertia);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_ENXERTIA)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_ENXERTIA)
                        .atualizar(this.dadosEnxertia);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir uma novo Semeio de Enxertia ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir uma novo Semeio de Enxertia ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.ocultarModalEnxertia();
                this.filtrarAnalises(true);
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
                this.ocultarModalEnxertia();
            }
            finally {
                this.ocultarModalEnxertia();
            }
        });
    }
    abrirModalEnxertia(id = null) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (typeof id !== 'undefined' && id) {
                    this.dadosEnxertia = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_ENXERTIA)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_ENXERTIA)
                        .id(id)
                        .ver();
                    if (this.dadosEnxertia) {
                        if (typeof this.dadosEnxertia.dataEnxertia === 'string') {
                            this.dadosEnxertia.dataEnxertia = new Date(this.dadosEnxertia.dataEnxertia);
                        }
                        if (typeof this.dadosEnxertia.dataSemeio === 'string') {
                            this.dadosEnxertia.dataSemeio = new Date(this.dadosEnxertia.dataSemeio);
                        }
                    }
                }
                this.modalEnxertia = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalEnxertia() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalEnxertia = false;
            this.dadosEnxertia = new ProducaoMudasSemeioEnxertia_1.ProducaoMudasSemeioEnxertia();
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    excluirEnxertia(id) {
        return __awaiter(this, void 0, void 0, function* () {
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            const ctrl = this;
            this.notificacao
                .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                .then((result) => __awaiter(this, void 0, void 0, function* () {
                if (result.value) {
                    CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_ENXERTIA)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_ENXERTIA)
                        .id(id)
                        .remover();
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.listaOpcoesEnxertia = yield ctrl.dadosListaEnxertia();
                    ctrl.scopeApply();
                }
            }));
        });
    }
    salvarMetaProducao() {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.metaProducaoSelecionado === 'undefined' ||
                !this.metaProducaoSelecionado) {
                return;
            }
            this.dadosMetaProducao.testeGenetico = this.metaProducaoSelecionado.testeGenetico;
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                this.modalPlanejamento = false;
                this.modalSeminalTeste = false;
                this.modalEnxertia = false;
                if (typeof this.dadosMetaProducao.id === 'undefined' || !this.dadosMetaProducao.id || this.dadosMetaProducao.id === '') {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_META_PRODUCAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_META_PRODUCAO)
                        .criar(this.dadosMetaProducao);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_META_PRODUCAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_META_PRODUCAO)
                        .atualizar(this.dadosMetaProducao);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir uma nova Meta de Produção ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir uma nova Meta de Produção ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.ocultarModalMetaProducao();
                this.filtrarAnalises(true);
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
                this.ocultarModalMetaProducao();
            }
            finally {
                this.ocultarModalMetaProducao();
            }
        });
    }
    abrirModalMetaProducao(id = null, tipo = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            if (typeof this.metaProducaoSelecionado === 'undefined' ||
                !this.metaProducaoSelecionado) {
                sweetalert2_1.default.fire({
                    title: 'Atenção!',
                    text: 'Selecione um registro da tabela abaixo e tente novamente',
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Ok'
                });
                return;
            }
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (typeof tipo !== 'undefined' && tipo) {
                    switch (tipo) {
                        case 'germinacao':
                            ctrl.formMetaGerminacao = true;
                            ctrl.formMetaSobrevivenciaVegetacao = false;
                            ctrl.formMetaSobrevivenciaAclimatacao = false;
                            break;
                        case 'sobrevivenciaVegetacao':
                            ctrl.formMetaGerminacao = false;
                            ctrl.formMetaSobrevivenciaVegetacao = true;
                            ctrl.formMetaSobrevivenciaAclimatacao = false;
                            break;
                        case 'sobrevivenciaAclimatacao':
                            ctrl.formMetaGerminacao = false;
                            ctrl.formMetaSobrevivenciaVegetacao = false;
                            ctrl.formMetaSobrevivenciaAclimatacao = true;
                            break;
                        default:
                            ctrl.formMetaGerminacao = false;
                            ctrl.formMetaSobrevivenciaVegetacao = false;
                            ctrl.formMetaSobrevivenciaAclimatacao = false;
                            break;
                    }
                }
                if (typeof id !== 'undefined' && id && id !== '') {
                    this.dadosMetaProducao = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_META_PRODUCAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_META_PRODUCAO)
                        .id(id)
                        .ver();
                    if (this.dadosMetaProducao) {
                        if (typeof this.dadosMetaProducao.dataGerminacao === 'string') {
                            this.dadosMetaProducao.dataGerminacao = new Date(this.dadosMetaProducao.dataGerminacao);
                        }
                        if (typeof this.dadosMetaProducao.dataSobrevivenciaAclimaticacao === 'string') {
                            this.dadosMetaProducao.dataSobrevivenciaAclimaticacao = new Date(this.dadosMetaProducao.dataSobrevivenciaAclimaticacao);
                        }
                        if (typeof this.dadosMetaProducao.dataSobrevivenciaVegetacao === 'string') {
                            this.dadosMetaProducao.dataSobrevivenciaVegetacao = new Date(this.dadosMetaProducao.dataSobrevivenciaVegetacao);
                        }
                    }
                }
                this.modalMetaProducao = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalMetaProducao() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalMetaProducao = false;
            this.dadosMetaProducao = new ProducaoMudasMetaProducao_1.ProducaoMudasMetaProducao();
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    excluirMetaProducao(id) {
        return __awaiter(this, void 0, void 0, function* () {
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            const ctrl = this;
            this.notificacao
                .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                .then((result) => __awaiter(this, void 0, void 0, function* () {
                if (result.value) {
                    CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDAS_META_PRODUCAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDAS_META_PRODUCAO)
                        .id(id)
                        .remover();
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.listaOpcoesMetaProducao = yield ctrl.dadosListaMetaProducao();
                    ctrl.scopeApply();
                }
            }));
        });
    }
    limparFiltroMetaProducao() {
        return __awaiter(this, void 0, void 0, function* () {
            this.metaProducaoSelecionado = null;
            this.metaProducaoCarregado = false;
        });
    }
    filtrarAnalises(alterouDados = false) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.analisesFiltradas === 'undefined') || !this.analisesFiltradas || ((typeof alterouDados !== 'undefined') && alterouDados)) {
                this.listaOpcoesPlanejamento = yield this.dadosListaPlanejamento();
                this.listaOpcoesTeste = yield this.dadosListaTeste();
                this.listaOpcoesEnxertia = yield this.dadosListaEnxertia();
                this.listaOpcoesMetaProducao = yield this.dadosListaMetaProducao();
                this.analisesFiltradas = true;
                this.scopeApply();
            }
        });
    }
    selecionarMetaProducao() {
        return __awaiter(this, void 0, void 0, function* () {
            let ctrl = this;
            if (ctrl && ctrl.constructor && ctrl.constructor.name) {
                if (ctrl.constructor.name === 'TabelaController') {
                    ctrl = ctrl.$scope.$parent.$ctrl;
                }
            }
            ctrl.filtrarMetaProducaoSelecionado();
        });
    }
    filtrarMetaProducaoSementes() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.filtrarMetaProducaoSelecionado();
                if (!this.metaProducaoSelecionado &&
                    this.metaProducaoSelecionado &&
                    this.metaProducaoSelecionado.id) {
                    this.metaProducaoCarregado = true;
                    this.scopeApply();
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
        });
    }
    filtrarMetaProducaoSelecionado() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                let metaProducao = null;
                const nomeTabelaMetaProducao = 'tabelaMetaProducao';
                let gridMetaProducaoApi = null;
                let gridMetaProducaoScope = angular
                    .element('#' + nomeTabelaMetaProducao)
                    .scope();
                if (typeof gridMetaProducaoScope.listaGridApi !== 'undefined' &&
                    gridMetaProducaoScope.listaGridApi) {
                    angular.forEach(gridMetaProducaoScope.listaGridApi, (gridApi) => {
                        const grid = typeof gridApi.grid !== 'undefined' && gridApi.grid
                            ? gridApi.grid
                            : null;
                        if (grid && typeof grid.element !== 'undefined' && grid.element) {
                            const tabelasEl = angular.element(grid.element).parents('tabela');
                            if (tabelasEl &&
                                tabelasEl.length > 0 &&
                                tabelasEl.first().attr('id') === nomeTabelaMetaProducao) {
                                gridMetaProducaoApi = gridApi;
                            }
                        }
                    });
                }
                if (gridMetaProducaoApi) {
                    const metaProducaoSelecionados = gridMetaProducaoApi.selection.getSelectedRows();
                    if (typeof metaProducaoSelecionados !== 'undefined' &&
                        metaProducaoSelecionados) {
                        angular.forEach(metaProducaoSelecionados, (obj) => {
                            if (!metaProducao) {
                                metaProducao = obj;
                            }
                        });
                    }
                }
                if (metaProducao && typeof metaProducao.planejamentoId !== 'undefined' && metaProducao.planejamentoId) {
                    if (typeof ctrl.metaProducaoSelecionado !== 'undefined' &&
                        ctrl.metaProducaoSelecionado &&
                        typeof ctrl.metaProducaoSelecionado.planejamentoId !== 'undefined' &&
                        ctrl.metaProducaoSelecionado.planejamentoId) {
                        if (ctrl.metaProducaoSelecionado.planejamentoId !== metaProducao.planejamentoId) {
                            yield ctrl.limparFiltroMetaProducao();
                            ctrl.metaProducaoSelecionado = metaProducao;
                            ctrl.metaProducaoCarregado = false;
                        }
                    }
                    else {
                        yield ctrl.limparFiltroMetaProducao();
                        ctrl.metaProducaoSelecionado = metaProducao;
                        ctrl.metaProducaoCarregado = false;
                    }
                }
                else {
                    if (ctrl.metaProducaoSelecionado || ctrl.metaProducaoCarregado) {
                        yield ctrl.limparFiltroMetaProducao();
                        ctrl.metaProducaoCarregado = false;
                    }
                }
                ctrl.scopeApply();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
        });
    }
}
exports.ProducaoMudasSeminalController = ProducaoMudasSeminalController;
ProducaoMudasSeminalController.$inject = ['$scope', '$injector'];
