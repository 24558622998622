"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BotoesOperacaoController = void 0;
const ICRUDOperacao_1 = require("../../interfaces/ICRUDOperacao");
require("@fortawesome/fontawesome-free/css/all.min.css");
const RotaUtil_1 = require("../../../../util/RotaUtil");
const NotificacaoFactory_1 = require("../../../../fabricas/NotificacaoFactory");
class BotoesOperacaoController {
    constructor($scope, $stateParams, $state, notificacao) {
        this.$scope = $scope;
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.notificacao = notificacao;
        this.operacao = null;
        this.links = null;
        this.modelo = null;
        this.podeIncluir = false;
        this.podeAlterar = false;
        this.podeExcluir = false;
        this.desabilitar = [];
        this.importar = false;
        this.tipoOperacao = null;
        this.timeoutPermissoes = null;
        this.timeoutScopeApply = null;
        this.$scope = $scope;
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.tipoOperacao = ICRUDOperacao_1.CRUDOperacao;
        this.operacao = RotaUtil_1.RotaUtil.detectarOperacao(this.$state);
        this.carregarPermissoes();
    }
    $onInit() {
        this.links = this.links ? this.links : RotaUtil_1.RotaUtil.gerarLinks(this.$state);
        this.carregarPermissoes();
    }
    $onDestroy() {
        if ((typeof this.timeoutPermissoes !== 'undefined') && (this.timeoutPermissoes !== null)) {
            clearTimeout(this.timeoutPermissoes);
            this.timeoutPermissoes = null;
        }
        if ((typeof this.timeoutScopeApply !== 'undefined') && (this.timeoutScopeApply !== null)) {
            clearTimeout(this.timeoutScopeApply);
            this.timeoutScopeApply = null;
        }
    }
    remover() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.notificacao
                .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                .then((result) => __awaiter(this, void 0, void 0, function* () {
                if (result.value) {
                    yield ctrl.$scope.$parent.$ctrl.remover(this.modelo.id || this.modelo.uuid);
                }
            }));
        });
    }
    recuperar() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.notificacao
                .confirmacao('Tem Certeza?', 'Deseja realmente recuperar esse registro?')
                .then((result) => __awaiter(this, void 0, void 0, function* () {
                if (result.value) {
                    ctrl.$scope.$parent.$ctrl.restaurar();
                }
            }));
        });
    }
    criar() {
        this.$state.go(this.links.CRIAR.rota, this.links.CRIAR.params);
    }
    listar() {
        this.$state.go(this.links.LISTAR.rota, this.links.LISTAR.params);
    }
    editar() {
        if (this.links.EDITAR.params) {
            this.links.EDITAR.params.id = this.$stateParams.id;
        }
        else {
            this.links.EDITAR.params = {
                id: this.$stateParams.id
            };
        }
        this.$state.go(this.links.EDITAR.rota, this.links.EDITAR.params);
    }
    visualizar() {
        if (this.links.VISUALIZAR.params) {
            this.links.VISUALIZAR.params.id = this.$stateParams.id;
        }
        else {
            this.links.VISUALIZAR.params = {
                id: this.$stateParams.id
            };
        }
        this.$state.go(this.links.VISUALIZAR.rota, this.links.VISUALIZAR.params);
    }
    duplicar() {
        this.$state.go(this.links.DUPLICAR.rota, this.links.DUPLICAR.params);
    }
    print() {
        window.print();
    }
    carregarPermissoes() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.timeoutPermissoes !== 'undefined') && (this.timeoutPermissoes !== null)) {
                clearTimeout(this.timeoutPermissoes);
                this.timeoutPermissoes = null;
            }
            this.timeoutPermissoes = setTimeout((ctrl) => {
                if (!ctrl) {
                    return;
                }
                ctrl.timeoutPermissoes = null;
                const podeIncluir = ((typeof globalThis.permissoes !== 'undefined') && globalThis.permissoes &&
                    (typeof globalThis.permissoes.incluir !== 'undefined') && globalThis.permissoes.incluir ? true : false);
                const podeAlterar = ((typeof globalThis.permissoes !== 'undefined') && globalThis.permissoes &&
                    (typeof globalThis.permissoes.alterar !== 'undefined') && globalThis.permissoes.alterar ? true : false);
                const podeExcluir = ((typeof globalThis.permissoes !== 'undefined') && globalThis.permissoes &&
                    (typeof globalThis.permissoes.excluir !== 'undefined') && globalThis.permissoes.excluir ? true : false);
                if ((podeIncluir !== ctrl.podeIncluir) || (podeAlterar !== ctrl.podeAlterar) || (podeExcluir !== ctrl.podeExcluir)) {
                    ctrl.podeIncluir = podeIncluir;
                    ctrl.podeAlterar = podeAlterar;
                    ctrl.podeExcluir = podeExcluir;
                    ctrl.scopeApply();
                }
                this.timeoutPermissoes = setTimeout((ctrl) => {
                    ctrl.carregarPermissoes();
                }, 1000, ctrl);
            }, 50, this);
        });
    }
    scopeApply() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.timeoutScopeApply !== 'undefined') && (this.timeoutScopeApply !== null)) {
                clearTimeout(this.timeoutScopeApply);
                this.timeoutScopeApply = null;
            }
            this.timeoutScopeApply = setTimeout((ctrl) => {
                if (!ctrl) {
                    return;
                }
                ctrl.timeoutScopeApply = null;
                try {
                    ctrl.$scope.$apply();
                }
                catch (ex) {
                    ctrl.$scope.$applyAsync();
                    console.log(ex);
                }
            }, 250, this);
        });
    }
}
exports.BotoesOperacaoController = BotoesOperacaoController;
BotoesOperacaoController.$inject = ['$scope', '$stateParams', '$state', NotificacaoFactory_1.NotificacaoFactory.id];
