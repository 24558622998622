"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TiposInputController = void 0;
const ICRUDOperacao_1 = require("../../interfaces/ICRUDOperacao");
require("@fortawesome/fontawesome-free/css/all.min.css");
const RotaUtil_1 = require("../../../../util/RotaUtil");
const NotificacaoFactory_1 = require("../../../../fabricas/NotificacaoFactory");
class TiposInputController {
    constructor($scope, $stateParams, $state, notificacao) {
        this.$scope = $scope;
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.notificacao = notificacao;
        this.operacao = null;
        this.links = null;
        this.modelo = null;
        this.desabilitar = [];
        this.importar = false;
        this.label = 'Faltou propriedade Label';
        this.placeholder = null;
        this.required = 'false';
        this.disabled = '$ctrl.operacao === $ctrl.tipoOperacao.VISUALIZAR';
        this.ngshow = 'true';
        this.change = null;
        this.show = 'nome';
        this.array = null;
        this.objeto = null;
        this.espaco_extra = null;
        this.value = null;
        this.objetovalor = null;
        this.tipoOperacao = null;
        this.$scope = $scope;
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.tipoOperacao = ICRUDOperacao_1.CRUDOperacao;
        this.operacao = RotaUtil_1.RotaUtil.detectarOperacao(this.$state);
    }
    $onInit() {
        this.links = this.links ? this.links : RotaUtil_1.RotaUtil.gerarLinks(this.$state);
        this.objeto = this.objeto ? this.objeto : 'true';
    }
    noFloat(evento) {
        if ([110, 190, 188].indexOf(evento.which) > -1) {
            evento.preventDefault();
            return false;
        }
        else {
            return true;
        }
    }
    startsWith(actual, expected) {
        var lowerStr = (actual + '').toLowerCase();
        return lowerStr.indexOf(expected.toLowerCase()) === 0;
    }
    remover() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.notificacao
                .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                .then((result) => __awaiter(this, void 0, void 0, function* () {
                if (result.value) {
                    yield ctrl.$scope.$parent.$ctrl.remover(this.modelo.id || this.modelo.uuid);
                }
            }));
        });
    }
    recuperar() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.notificacao
                .confirmacao('Tem Certeza?', 'Deseja realmente recuperar esse registro?')
                .then((result) => __awaiter(this, void 0, void 0, function* () {
                if (result.value) {
                    ctrl.$scope.$parent.$ctrl.restaurar();
                }
            }));
        });
    }
    criar() {
        this.$state.go(this.links.CRIAR.rota, this.links.CRIAR.params);
    }
    listar() {
        this.$state.go(this.links.LISTAR.rota, this.links.LISTAR.params);
    }
    editar() {
        if (this.links.EDITAR.params) {
            this.links.EDITAR.params.id = this.$stateParams.id;
        }
        else {
            this.links.EDITAR.params = {
                id: this.$stateParams.id
            };
        }
        this.$state.go(this.links.EDITAR.rota, this.links.EDITAR.params);
    }
    carregarEvento() {
    }
    visualizar() {
        if (this.links.VISUALIZAR.params) {
            this.links.VISUALIZAR.params.id = this.$stateParams.id;
        }
        else {
            this.links.VISUALIZAR.params = {
                id: this.$stateParams.id
            };
        }
        this.$state.go(this.links.VISUALIZAR.rota, this.links.VISUALIZAR.params);
    }
    duplicar() {
        this.$state.go(this.links.DUPLICAR.rota, this.links.DUPLICAR.params);
    }
    print() {
        window.print();
    }
}
exports.TiposInputController = TiposInputController;
TiposInputController.$inject = ['$scope', '$stateParams', '$state', NotificacaoFactory_1.NotificacaoFactory.id];
