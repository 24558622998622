"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HistoricoEstoqueBeneficiamentoPinusPolinizacaoPomar = void 0;
const Base_1 = require("./Base");
class HistoricoEstoqueBeneficiamentoPinusPolinizacaoPomar extends Base_1.Base {
    constructor() {
        super();
        this.id = null;
        this.codigo = null;
        this.pomar = null;
        this.estoqueBeneficiamentoPinusPolinizacaoPomar = null;
        this.quantidadeAnterior = null;
        this.quantidadeMovimento = null;
        this.quantidadeAtual = null;
        this.movimento = null;
        this.usuario = null;
        this.status = true;
    }
}
exports.HistoricoEstoqueBeneficiamentoPinusPolinizacaoPomar = HistoricoEstoqueBeneficiamentoPinusPolinizacaoPomar;
