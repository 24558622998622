"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlanilhaDialogoController = void 0;
const DialogoFactory_1 = require("../../../../fabricas/DialogoFactory");
const ManipulaErroFactory_1 = require("../../../../fabricas/ManipulaErroFactory");
const RestFactory_1 = require("../../../../fabricas/RestFactory");
class PlanilhaDialogoController {
    constructor($mdDialog, $scope, modalInfo, rest, notificacao, manipulaErro, dialogo) {
        this.operacao = null;
        this.model = null;
        this.fechar = null;
        this.modalInfo = null;
        this.rest = null;
        this.notificacao = null;
        this.manipulaErro = null;
        this.dialogo = null;
        this.$scope = null;
        this.$mdDialog = null;
        this.gedPagina = null;
        this.data = null;
        this.modalInfo = modalInfo;
        this.rest = rest;
        this.notificacao = notificacao;
        this.manipulaErro = manipulaErro;
        this.dialogo = dialogo;
        this.$scope = $scope;
        this.$mdDialog = $mdDialog;
    }
    $onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.modalInfo.data === 'undefined') || (this.modalInfo.data === null)) {
                this.modalInfo.data = { mostrarPlanilhaModelo: true };
            }
            else if ((typeof this.modalInfo.data.mostrarPlanilhaModelo === 'undefined') || (this.modalInfo.data.mostrarPlanilhaModelo === null)) {
                this.modalInfo.data.mostrarPlanilhaModelo = true;
            }
            this.model = this.modalInfo.model;
            this.data = this.modalInfo.data;
            this.fechar = this.modalInfo.fechar;
            this.operacao = this.modalInfo.operacao;
        });
    }
    salvar() {
        return __awaiter(this, void 0, void 0, function* () {
            const foiCarregado = Array.isArray(this.model.geds) && this.model.geds.length > 0;
            this.fechar(foiCarregado);
        });
    }
}
exports.PlanilhaDialogoController = PlanilhaDialogoController;
PlanilhaDialogoController.$inject = [
    '$mdDialog',
    '$scope',
    'modalInfo',
    RestFactory_1.RestFactory.id,
    ManipulaErroFactory_1.ManipulaErroFactory.id,
    ManipulaErroFactory_1.ManipulaErroFactory.id,
    DialogoFactory_1.DialogoFactory.id
];
