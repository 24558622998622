"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProdutividadeFuncionarioPomar = void 0;
const Base_1 = require("./Base");
const uuid_1 = require("uuid");
class ProdutividadeFuncionarioPomar extends Base_1.Base {
    constructor() {
        super();
        this.id = null;
        this.guid = null;
        this.funcionario = null;
        this.numClones = null;
        this.numRametes = null;
        this.quantidadeEnxertos = null;
        this.quantidadeColetas = null;
        this.guid = uuid_1.v4();
        this.status = true;
    }
}
exports.ProdutividadeFuncionarioPomar = ProdutividadeFuncionarioPomar;
