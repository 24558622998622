"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProducaoMudaClonalMinijardimEliminarMiniMacroEstacas = void 0;
const Base_1 = require("./Base");
class ProducaoMudaClonalMinijardimEliminarMiniMacroEstacas extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.data = null;
        this.numCanaletao = null;
        this.materialGenetico = null;
        this.linhaCanaletao = null;
        this.numMudas = null;
        this.observacao = null;
        this.minijardimEucalyptus = null;
        this.minijardimPinus = null;
    }
}
exports.ProducaoMudaClonalMinijardimEliminarMiniMacroEstacas = ProducaoMudaClonalMinijardimEliminarMiniMacroEstacas;
