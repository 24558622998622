"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContaDepositoSap = void 0;
const Base_1 = require("./Base");
class ContaDepositoSap extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.descricao = null;
        this.contaDepositoSap = null;
    }
}
exports.ContaDepositoSap = ContaDepositoSap;
