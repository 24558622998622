"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProducaoMudaClonalEucalyptusResgateMacroestacas = void 0;
const Base_1 = require("./Base");
class ProducaoMudaClonalEucalyptusResgateMacroestacas extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
    }
}
exports.ProducaoMudaClonalEucalyptusResgateMacroestacas = ProducaoMudaClonalEucalyptusResgateMacroestacas;
