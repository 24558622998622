"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Talhao = void 0;
const Base_1 = require("./Base");
class Talhao extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.sgif_id = null;
        this.numero = null;
        this.regiao = null;
        this.regiaoId = null;
        this.regiaoSgifId = null;
        this.regiaoNum = null;
        this.regiaoNome = null;
        this.numTalhao = null;
        this.areaTalhao = null;
        this.dataPlantio = null;
        this.tipoFloresta = null;
        this.arvores = null;
    }
}
exports.Talhao = Talhao;
