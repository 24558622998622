"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Cliente = void 0;
const Base_1 = require("./Base");
class Cliente extends Base_1.Base {
    constructor() {
        super();
        this.id = null;
        this.integradoSgif = null;
        this.sgif_id = null;
        this.nome = null;
        this.cpfCnpj = null;
        this.inscricaoEstadual = null;
        this.municipio = null;
        this.uf = null;
        this.tiposProduto = null;
        this.tipoProdutoSemente = null;
        this.tipoProdutoMuda = null;
        this.tipoProdutoToraNormal = null;
        this.tipoProdutoToraAPP = null;
        this.diametroMin = null;
        this.diametroMax = null;
        this.comprimentos = null;
        this.inativo = false;
        this.quantidadeVasilhameTubete = null;
        this.quantidadeVasilhameEllepot = null;
        this.quantidadeVasilhameBandeja = null;
        this.quantidadeVasilhameCaixa = null;
        this.status = true;
        this.integradoSgif = false;
        this.tiposProduto = new Array();
        this.comprimentos = new Array();
        this.inativo = false;
        while (this.comprimentos.length < 5) {
            this.comprimentos.push(null);
        }
    }
}
exports.Cliente = Cliente;
