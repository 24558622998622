"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BotoesFormularioComponente = void 0;
const BotoesFormularioController_1 = require("./BotoesFormularioController");
class BotoesFormularioComponente {
    constructor() {
        this.controller = BotoesFormularioController_1.BotoesFormularioController;
        this.templateUrl = require('./botoes-formulario.componente.html').default;
        this.transclude = true;
        this.bindings = {
            modelo: '=',
            formulario: '=',
            botaoExcluir: '='
        };
    }
}
exports.BotoesFormularioComponente = BotoesFormularioComponente;
BotoesFormularioComponente.id = 'botoesFormulario';
