"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GraficoFenologiaPomar = void 0;
const Base_1 = require("./Base");
class GraficoFenologiaPomar extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.id = null;
        this.codigo = null;
        this.data = null;
        this.pomar = null;
        this.pomarCodigo = null;
        this.pomarDescricao = null;
        this.regiao = null;
        this.talhao = null;
        this.ano = null;
        this.materialGeneticoFeminino = null;
        this.materialGeneticoMasculino = null;
        this.observacoes = null;
        this.usuarioInclusao = null;
        this.dataHoraInclusao = null;
        this.usuarioAlteracao = null;
        this.dataHoraAlteracao = null;
    }
}
exports.GraficoFenologiaPomar = GraficoFenologiaPomar;
