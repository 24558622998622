"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProdutoFertilizanteController = void 0;
const angular = require("angular");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const UiGridConstrutor_1 = require("../../../app/construtores/UiGridConstrutor");
const AbstratoController_1 = require("../../../app/AbstratoController");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const ICRUDOperacao_1 = require("../../../app/interfaces/ICRUDOperacao");
const ProdutoFertilizante_1 = require("../../../../../modelos/ProdutoFertilizante");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const config_app_1 = require("../../../../../config/config.app");
const HistoricoEstoqueProdutoFertilizante_1 = require("../../../../../modelos/HistoricoEstoqueProdutoFertilizante");
const sweetalert2_1 = require("sweetalert2");
const DispositivoUtil_1 = require("../../../../../util/DispositivoUtil");
class ProdutoFertilizanteController extends AbstratoController_1.AbstratoController {
    constructor($scope, $injector) {
        super($scope, $injector, new ProdutoFertilizante_1.ProdutoFertilizante());
        this.path = '';
        this.caminho = '';
        this.tipoOperacao = null;
        this.listaOpcoesHistoricoEstoque = null;
        this.unidadesMedidas = [];
        this.tipoOperacao = ICRUDOperacao_1.CRUDOperacao;
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.path = config_app_1.config.api.caminho;
            this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE;
            if ((this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) || (this.operacao === ICRUDOperacao_1.CRUDOperacao.EDITAR) || (this.operacao === ICRUDOperacao_1.CRUDOperacao.DUPLICAR)) {
                this.unidadesMedidas = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.UNIDADE_MEDIDA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.UNIDADE_MEDIDA)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.unidadesMedidas = resposta;
                    }
                });
            }
            this.$scope.$apply();
        });
    }
    salvar() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                        .criar(this.modelo);
                }
                else {
                    if ((typeof this.modelo.quantidadeAtual !== 'undefined') && this.modelo.quantidadeAtual) {
                        let produtoFertilizante = yield this.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                            .id(this.modelo.id)
                            .ver();
                        if ((typeof produtoFertilizante !== 'undefined') && produtoFertilizante &&
                            (typeof produtoFertilizante.quantidadeAtual !== 'undefined') && produtoFertilizante.quantidadeAtual !== this.modelo.quantidadeAtual) {
                            let historicoEstoqueProdutoFertilizante = new HistoricoEstoqueProdutoFertilizante_1.HistoricoEstoqueProdutoFertilizante();
                            historicoEstoqueProdutoFertilizante.produtoFertilizante = this.modelo;
                            historicoEstoqueProdutoFertilizante.usuario = this.usuarioLogado;
                            historicoEstoqueProdutoFertilizante.quantidadeAnterior = (_a = Number(produtoFertilizante.quantidadeAtual)) !== null && _a !== void 0 ? _a : 0;
                            if (produtoFertilizante.quantidadeAtual > this.modelo.quantidadeAtual) {
                                historicoEstoqueProdutoFertilizante.movimento = HistoricoEstoqueProdutoFertilizante_1.HistoricoEstoqueProdutoFertilizante.MOVIMENTO_SAIDA;
                                historicoEstoqueProdutoFertilizante.quantidadeMovimento = Number(produtoFertilizante.quantidadeAtual) - Number(this.modelo.quantidadeAtual);
                                historicoEstoqueProdutoFertilizante.quantidadeAtual = Number(historicoEstoqueProdutoFertilizante.quantidadeAnterior) - Number(historicoEstoqueProdutoFertilizante.quantidadeMovimento);
                            }
                            else {
                                historicoEstoqueProdutoFertilizante.movimento = HistoricoEstoqueProdutoFertilizante_1.HistoricoEstoqueProdutoFertilizante.MOVIMENTO_ENTRADA;
                                historicoEstoqueProdutoFertilizante.quantidadeMovimento = Number(this.modelo.quantidadeAtual) - Number(produtoFertilizante.quantidadeAtual);
                                historicoEstoqueProdutoFertilizante.quantidadeAtual = Number(historicoEstoqueProdutoFertilizante.quantidadeAnterior) + Number(historicoEstoqueProdutoFertilizante.quantidadeMovimento);
                            }
                            yield this.sincronia
                                .rota(RotaAPIConstantes_1.RotaAPIConstantes.HISTORICO_ESTOQUE_PRODUTO_FERTILIZANTE)
                                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.HISTORICO_ESTOQUE_PRODUTO_FERTILIZANTE)
                                .criar(historicoEstoqueProdutoFertilizante);
                        }
                    }
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                        .atualizar(this.modelo);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir um novo Produto Fertilizante ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir um novo Produto Fertilizante ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não'
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    visualizar(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const produtoFertilizante = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                    .id(id)
                    .ver();
                produtoFertilizante.criacao = new Date(produtoFertilizante.criacao);
                this.listaOpcoesHistoricoEstoque = yield this.dadosListaHistoricoEstoque(id);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return produtoFertilizante;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    remover(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const produtoFertilizante = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                    .id(id)
                    .remover();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (produtoFertilizante) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const produtoFertilizante = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                    .id(this.modelo.id)
                    .restaurar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (produtoFertilizante) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    editar(idProduto) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const produtoFertilizante = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                    .id(idProduto)
                    .ver();
                produtoFertilizante.criacao = new Date(produtoFertilizante.criacao);
                this.listaOpcoesHistoricoEstoque = yield this.dadosListaHistoricoEstoque(idProduto);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (this.links.EDITAR.params) {
                    this.links.EDITAR.params.id = this.$stateParams.id;
                }
                else {
                    this.links.EDITAR.params = {
                        id: this.$stateParams.id
                    };
                }
                this.$state.go(this.links.EDITAR.rota, this.links.EDITAR.params);
                return null;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosLista(filtro = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                this.path = config_app_1.config.api.caminho;
                this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE;
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                var auxColunas = Array();
                auxColunas.push({
                    name: 'codigo',
                    displayName: 'Código',
                    width: 90
                });
                auxColunas.push({
                    name: 'descricao',
                    displayName: 'Descrição - Produto Fertilizante',
                    width: 300
                });
                auxColunas.push({
                    name: 'unidadeMedida.descricao',
                    displayName: 'Unidade',
                    width: 120
                });
                auxColunas.push({
                    name: 'codigoSap',
                    displayName: 'Cód. SAP',
                    width: 120
                });
                auxColunas.push({
                    name: 'criacao',
                    displayName: 'Data/Hora Cadastro',
                    cellFilter: 'date:"dd/MM/yyyy HH:mm:ss"',
                    width: 190
                });
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
                const lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                    .filtro(filtro)
                    .listar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach(element => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosListaHistoricoEstoque(idProduto) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.HISTORICO_ESTOQUE_PRODUTO_FERTILIZANTE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.HISTORICO_ESTOQUE_PRODUTO_FERTILIZANTE)
                    .filtro({
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    produtoFertilizante: idProduto
                })
                    .listar();
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'criacao',
                    displayName: 'Data/Hora',
                    cellFilter: 'date:"dd/MM/yyyy HH:mm:ss"',
                    width: 170
                });
                auxColunas.push({
                    name: 'movimento',
                    displayName: 'Movimentação',
                    width: 120
                });
                auxColunas.push({
                    name: 'contaDepositoSap.descricao',
                    displayName: 'Deposito SAP',
                    width: 130
                });
                auxColunas.push({
                    name: 'centroCustoSap.descricao',
                    displayName: 'Custo SAP',
                    width: 130
                });
                auxColunas.push({
                    name: 'quantidadeAnterior',
                    displayName: 'Quant. Anterior',
                    width: 130
                });
                auxColunas.push({
                    name: 'quantidadeMovimento',
                    displayName: 'Quant. Movimento',
                    width: 140
                });
                auxColunas.push({
                    name: 'quantidadeAtual',
                    displayName: 'Quant. Atual',
                    width: 120
                });
                auxColunas.push({
                    name: 'usuario.nomeCompleto',
                    displayName: 'Usuário',
                    width: 200
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            return null;
        });
    }
    filtrar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    limparFiltro() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
}
exports.ProdutoFertilizanteController = ProdutoFertilizanteController;
ProdutoFertilizanteController.$inject = ['$scope', '$injector'];
