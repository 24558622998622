"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TesteCampoTratamentoUtil = void 0;
const angular = require("angular");
const TratamentoTesteGenetico_1 = require("../../../../../modelos/TratamentoTesteGenetico");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const Pais_1 = require("../../../../../modelos/Pais");
const PerfilClimaticoTesteGenetico_1 = require("../../../../../modelos/PerfilClimaticoTesteGenetico");
const TextoUtil_1 = require("../../../../../util/TextoUtil");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const sweetalert2_1 = require("sweetalert2");
class TesteCampoTratamentoUtil {
    static salvarTratamentoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.tratamentoTesteGeneticoSelecionado || ctrl.tratamentoTesteGeneticoVisualizar) {
                    return;
                }
                let listaCamposObrigatorios = '';
                if (listaCamposObrigatorios && (listaCamposObrigatorios.length > 0)) {
                    let mensagem = 'Campo obrigatório não preenchido!' +
                        listaCamposObrigatorios + '\r\n \r\n' +
                        'Preencha este campo e tente novamente.';
                    if (ctrl.ehCelular) {
                        alert(mensagem.replace(/<b>/g, '').replace(/<\/b>/g, ''));
                    }
                    else {
                        yield sweetalert2_1.default.fire({
                            html: mensagem.replace(/\r\n/g, '<br>\r\n'),
                            icon: 'info',
                            showCancelButton: false,
                            showConfirmButton: true,
                            confirmButtonText: 'Ok'
                        });
                    }
                    return;
                }
                ctrl.modalGerarCroquiTesteGenetico = false;
                ctrl.modalGerarCroquiTesteGeneticoDados = null;
                ctrl.modalImportacaoCroquiTesteGenetico = false;
                ctrl.modalHistoricoCroquiTesteGenetico = false;
                ctrl.modalControleManejoTesteGenetico = false;
                ctrl.modalMedicaoDendrometricaTesteGenetico = false;
                ctrl.modalPlanejamentoMedicaoDendrometricaTesteGenetico = false;
                ctrl.modalPlanilhaMedicaoDendrometricaTesteGenetico = false;
                ctrl.modalSelegenMedicaoDendrometricaTesteGenetico = false;
                ctrl.modalQualidadeMadeiraTesteGenetico = false;
                ctrl.modalAnaliseSelecaoTesteGenetico = false;
                ctrl.modalSumarioAnaliseSelecaoTesteGenetico = false;
                ctrl.modalPlanilhaSelegenAnaliseSelecaoTesteGenetico = false;
                ctrl.modalAnaliseSelegenAnaliseSelecaoTesteGenetico = false;
                ctrl.modalResultadoSelegenAnaliseSelecaoTesteGenetico = false;
                ctrl.modalAnaliseAnoAnaliseSelecaoTesteGenetico = false;
                ctrl.modalAnaliseBlupAnaliseSelecaoTesteGenetico = false;
                ctrl.modalSelecaoGeneticaAnaliseSelecaoTesteGenetico = false;
                ctrl.modalSelecaoCampoAnaliseSelecaoTesteGenetico = false;
                ctrl.modalAnexoAnaliseSelecaoTesteGenetico = false;
                ctrl.modalRelatorioTesteGenetico = false;
                ctrl.modalAnexoTesteGenetico = false;
                ctrl.modalProtocoloTesteGenetico = false;
                ctrl.modalDossieMaterialGenetico = false;
                ctrl.tratamentoTesteGeneticoSelecionado.testeGenetico = ctrl.testeGeneticoSelecionado;
                if ((typeof ctrl.tratamentoTesteGeneticoSelecionado.tratamentos === 'undefined') || !ctrl.tratamentoTesteGeneticoSelecionado.tratamentos) {
                    ctrl.tratamentoTesteGeneticoSelecionado.tratamentos = new Array();
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                if ((typeof ctrl.tratamentoTesteGeneticoSelecionado.id === 'undefined') || !ctrl.tratamentoTesteGeneticoSelecionado.id) {
                    if ((typeof ctrl.listaOpcoesMateriaisGeneticosTratamentosTesteGenetico !== 'undefined') && ctrl.listaOpcoesMateriaisGeneticosTratamentosTesteGenetico &&
                        (typeof ctrl.listaOpcoesMateriaisGeneticosTratamentosTesteGenetico.data !== 'undefined') && ctrl.listaOpcoesMateriaisGeneticosTratamentosTesteGenetico.data) {
                        let materiaisGeneticosSelecionados = Array();
                        angular.forEach(ctrl.listaOpcoesMateriaisGeneticosTratamentosTesteGenetico.data, (materialGenetico) => {
                            if ((typeof materialGenetico.selecionado !== 'undefined') && materialGenetico.selecionado) {
                                materiaisGeneticosSelecionados.push(materialGenetico);
                            }
                        });
                        if ((typeof ctrl.listaTratamentosTesteGenetico === 'undefined') || !ctrl.listaTratamentosTesteGenetico) {
                            ctrl.listaTratamentosTesteGenetico = new Array();
                        }
                        for (let i = 0; i < materiaisGeneticosSelecionados.length; i++) {
                            const materialGenetico = materiaisGeneticosSelecionados[i];
                            let tratamentoTesteGenetico = ctrl.listaTratamentosTesteGenetico.find(x => x.materialGenetico && x.materialGenetico.id && (x.materialGenetico.id === materialGenetico.id));
                            if ((typeof tratamentoTesteGenetico !== 'undefined') && tratamentoTesteGenetico &&
                                (typeof tratamentoTesteGenetico.id !== 'undefined') && tratamentoTesteGenetico.id) {
                                let s1 = TextoUtil_1.TextoUtil.converteTextoParaReferencia((typeof materialGenetico.tipoTeste !== 'undefined') && materialGenetico.tipoTeste ? materialGenetico.tipoTeste : '');
                                let s2 = TextoUtil_1.TextoUtil.converteTextoParaReferencia((typeof tratamentoTesteGenetico.tipoTeste !== 'undefined') && tratamentoTesteGenetico.tipoTeste ? tratamentoTesteGenetico.tipoTeste : '');
                                if (s1 && (s1.length > 0) && (s1 !== s2)) {
                                    tratamentoTesteGenetico.tipoTeste = materialGenetico.tipoTeste;
                                    tratamentoTesteGenetico = yield ctrl.sincronia
                                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.TRATAMENTO_TESTE_GENETICO)
                                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TRATAMENTO_TESTE_GENETICO)
                                        .atualizar(tratamentoTesteGenetico);
                                }
                            }
                            else {
                                let novoTratamento = new TratamentoTesteGenetico_1.TratamentoTesteGenetico();
                                novoTratamento.testeGenetico = ctrl.testeGeneticoSelecionado;
                                novoTratamento.materialGenetico = materialGenetico;
                                novoTratamento.tipoTeste = (typeof materialGenetico.tipoTeste !== 'undefined') ? materialGenetico.tipoTeste : null;
                                novoTratamento = yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TRATAMENTO_TESTE_GENETICO)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TRATAMENTO_TESTE_GENETICO)
                                    .criar(novoTratamento);
                            }
                        }
                        for (let i = 0; i < ctrl.listaTratamentosTesteGenetico.length; i++) {
                            let tratamentoTesteGenetico = ctrl.listaTratamentosTesteGenetico[i];
                            if ((typeof tratamentoTesteGenetico !== 'undefined') && tratamentoTesteGenetico &&
                                (typeof tratamentoTesteGenetico.materialGenetico !== 'undefined') && tratamentoTesteGenetico.materialGenetico &&
                                (typeof tratamentoTesteGenetico.materialGenetico.id !== 'undefined') && tratamentoTesteGenetico.materialGenetico.id) {
                                let materialGenetico = materiaisGeneticosSelecionados.find(x => x.id && (x.id === tratamentoTesteGenetico.materialGenetico.id));
                                if ((typeof materialGenetico === 'undefined') || !materialGenetico) {
                                    yield ctrl.sincronia
                                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.TRATAMENTO_TESTE_GENETICO)
                                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TRATAMENTO_TESTE_GENETICO)
                                        .id(tratamentoTesteGenetico.id)
                                        .remover(true);
                                }
                            }
                        }
                    }
                    else {
                        for (let i = 0; i < ctrl.tratamentoTesteGeneticoSelecionado.tratamentos.length; i++) {
                            const tratamento = ctrl.tratamentoTesteGeneticoSelecionado.tratamentos[i];
                            if ((typeof tratamento.materialGenetico !== 'undefined') && tratamento.materialGenetico) {
                                let novoTratamento = new TratamentoTesteGenetico_1.TratamentoTesteGenetico();
                                novoTratamento.testeGenetico = ctrl.testeGeneticoSelecionado;
                                novoTratamento.materialGenetico = tratamento.materialGenetico;
                                novoTratamento.tipoTeste = (typeof tratamento.tipoTeste !== 'undefined') ? tratamento.tipoTeste : null;
                                novoTratamento = yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TRATAMENTO_TESTE_GENETICO)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TRATAMENTO_TESTE_GENETICO)
                                    .criar(novoTratamento);
                                if ((typeof novoTratamento.id !== 'undefined') && novoTratamento.id) {
                                    ctrl.tratamentoTesteGeneticoSelecionado.id = novoTratamento.id;
                                }
                            }
                        }
                    }
                }
                else {
                    for (let i = 0; i < ctrl.tratamentoTesteGeneticoSelecionado.tratamentos.length; i++) {
                        const tratamento = ctrl.tratamentoTesteGeneticoSelecionado.tratamentos[i];
                        if ((typeof tratamento.materialGenetico !== 'undefined') && tratamento.materialGenetico) {
                            if (i > 0) {
                                let novoTratamento = new TratamentoTesteGenetico_1.TratamentoTesteGenetico();
                                novoTratamento.testeGenetico = ctrl.testeGeneticoSelecionado;
                                novoTratamento.materialGenetico = tratamento.materialGenetico;
                                novoTratamento.tipoTeste = (typeof tratamento.tipoTeste !== 'undefined') ? tratamento.tipoTeste : null;
                                novoTratamento = yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TRATAMENTO_TESTE_GENETICO)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TRATAMENTO_TESTE_GENETICO)
                                    .criar(novoTratamento);
                            }
                            else {
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TRATAMENTO_TESTE_GENETICO)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TRATAMENTO_TESTE_GENETICO)
                                    .atualizar(ctrl.tratamentoTesteGeneticoSelecionado);
                            }
                        }
                    }
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.modalTratamentoTesteGenetico = false;
                if (ctrl.ehCelular) {
                    alert(`Salvo com sucesso!`);
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'O tratamento foi salvo no banco de dados.',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Ok'
                    });
                }
                ctrl.listaOpcoesTratamentosTesteGenetico = yield ctrl.dadosListaTratamentosTesteGenetico();
                yield ctrl.filtraMateriaisGeneticos();
                yield ctrl.calcularDelineamento();
                ctrl.ocultarModalTratamentoTesteGenetico();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static exibirModalTratamentoTesteGenetico(ctrl, dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.testeGeneticoSelecionado === 'undefined') || !ctrl.testeGeneticoSelecionado) {
                return;
            }
            try {
                ctrl.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                ctrl.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                ctrl.tratamentoTesteGeneticoVisualizar = true;
                ctrl.listaOpcoesMateriaisGeneticosTratamentosTesteGenetico = {
                    data: [],
                    columnDefs: []
                };
                if ((typeof dados !== 'undefined') && dados && (typeof dados.id !== 'undefined') && dados.id) {
                    ctrl.tratamentoTesteGeneticoSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.TRATAMENTO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TRATAMENTO_TESTE_GENETICO)
                        .id(dados.id)
                        .ver();
                    if ((typeof ctrl.tratamentoTesteGeneticoSelecionado === 'undefined') || !ctrl.tratamentoTesteGeneticoSelecionado) {
                        ctrl.tratamentoTesteGeneticoSelecionado = new TratamentoTesteGenetico_1.TratamentoTesteGenetico();
                    }
                    ctrl.tratamentoTesteGeneticoSelecionado.tratamentos = [
                        ctrl.tratamentoTesteGeneticoSelecionado
                    ];
                    if ((typeof ctrl.tratamentoTesteGeneticoSelecionado === 'undefined') || !ctrl.tratamentoTesteGeneticoSelecionado) {
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        if (ctrl.ehCelular) {
                            alert(`Registro "${dados.id} não encontrado!`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                text: `Registro "${dados.id}" não encontrado.`,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        return;
                    }
                    if ((typeof ctrl.tratamentoTesteGeneticoSelecionado.criacao === 'string') && ctrl.tratamentoTesteGeneticoSelecionado.criacao) {
                        ctrl.tratamentoTesteGeneticoSelecionado.criacao = new Date(ctrl.tratamentoTesteGeneticoSelecionado.criacao);
                    }
                    if ((typeof ctrl.tratamentoTesteGeneticoSelecionado.dataHoraInclusao === 'string') && ctrl.tratamentoTesteGeneticoSelecionado.dataHoraInclusao) {
                        ctrl.tratamentoTesteGeneticoSelecionado.dataHoraInclusao = new Date(ctrl.tratamentoTesteGeneticoSelecionado.dataHoraInclusao);
                    }
                    if ((typeof ctrl.tratamentoTesteGeneticoSelecionado.dataHoraAlteracao === 'string') && ctrl.tratamentoTesteGeneticoSelecionado.dataHoraAlteracao) {
                        ctrl.tratamentoTesteGeneticoSelecionado.dataHoraAlteracao = new Date(ctrl.tratamentoTesteGeneticoSelecionado.dataHoraAlteracao);
                    }
                    if ((typeof dados.visualizar !== 'undefined') && dados.visualizar) {
                        ctrl.tratamentoTesteGeneticoVisualizar = true;
                    }
                    else if ((typeof dados.editar !== 'undefined') && dados.editar) {
                        ctrl.tratamentoTesteGeneticoVisualizar = false;
                    }
                    else if ((typeof dados.excluir !== 'undefined') && dados.excluir) {
                        ctrl.notificacao
                            .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                            .then((result) => __awaiter(this, void 0, void 0, function* () {
                            if (result.value) {
                                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TRATAMENTO_TESTE_GENETICO)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TRATAMENTO_TESTE_GENETICO)
                                    .id(dados.id)
                                    .remover();
                                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                                ctrl.scopeApply();
                            }
                        }));
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                }
                else {
                    ctrl.tratamentoTesteGeneticoSelecionado = new TratamentoTesteGenetico_1.TratamentoTesteGenetico();
                    ctrl.tratamentoTesteGeneticoSelecionado.criacao = new Date();
                    ctrl.tratamentoTesteGeneticoSelecionado.criacao.setMilliseconds(0);
                    ctrl.tratamentoTesteGeneticoSelecionado.ano = ctrl.tratamentoTesteGeneticoSelecionado.criacao.getFullYear();
                    ctrl.tratamentoTesteGeneticoSelecionado.mes = ctrl.tratamentoTesteGeneticoSelecionado.criacao.getMonth() + 1;
                    ctrl.tratamentoTesteGeneticoSelecionado.tratamentos = [{}];
                    ctrl.tratamentoTesteGeneticoVisualizar = false;
                    ctrl.listaOpcoesMateriaisGeneticosTratamentosTesteGenetico = yield ctrl.dadosListaMateriaisGeneticosTratamentosTesteGenetico('div.app-content.content');
                }
                ctrl.modalTratamentoTesteGenetico = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static ocultarModalTratamentoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.modalTratamentoTesteGenetico = false;
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = ctrl.posicaoTopoAntesModal;
            ctrl.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                        (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    static adicionarRegistroTratamentoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.tratamentoTesteGeneticoSelecionado) {
                return;
            }
            if ((typeof ctrl.tratamentoTesteGeneticoSelecionado.tratamentos === 'undefined') || !ctrl.tratamentoTesteGeneticoSelecionado.tratamentos) {
                ctrl.tratamentoTesteGeneticoSelecionado.tratamentos = [];
            }
            let registro = {
                id: null,
                uuid: null,
                guid: null,
                status: true,
                criacao: new Date()
            };
            ctrl.tratamentoTesteGeneticoSelecionado.tratamentos.push(registro);
            ctrl.scopeApply();
        });
    }
    static removerRegistroTratamentoTesteGenetico(ctrl, keyTratamento) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.tratamentoTesteGeneticoSelecionado) {
                return;
            }
            if ((typeof ctrl.tratamentoTesteGeneticoSelecionado.tratamentos === 'undefined') || !ctrl.tratamentoTesteGeneticoSelecionado.tratamentos) {
                ctrl.tratamentoTesteGeneticoSelecionado.tratamentos = [];
            }
            if ((typeof keyTratamento !== 'undefined') && (keyTratamento !== null) &&
                (ctrl.tratamentoTesteGeneticoSelecionado.tratamentos.length > 0) &&
                (keyTratamento <= ctrl.tratamentoTesteGeneticoSelecionado.tratamentos.length - 1)) {
                const numRegistro = keyTratamento + 1;
                if (window.confirm('Deseja realmente remover esse registro #' + numRegistro + ' ?')) {
                    ctrl.tratamentoTesteGeneticoSelecionado.tratamentos.splice(keyTratamento, 1);
                    ctrl.scopeApply();
                }
            }
        });
    }
    static moverRegistroTratamentoTesteGeneticoParaCima(ctrl, keyTratamento) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.tratamentoTesteGeneticoSelecionado) {
                return;
            }
            if ((typeof ctrl.tratamentoTesteGeneticoSelecionado.tratamentos === 'undefined') || !ctrl.tratamentoTesteGeneticoSelecionado.tratamentos) {
                ctrl.tratamentoTesteGeneticoSelecionado.tratamentos = [];
            }
            if ((keyTratamento !== null) && (keyTratamento > 0) && (keyTratamento <= ctrl.tratamentoTesteGeneticoSelecionado.tratamentos.length - 1)) {
                let oldKey = keyTratamento;
                let newKey = keyTratamento - 1;
                ctrl.tratamentoTesteGeneticoSelecionado.tratamentos.splice(newKey, 0, ctrl.tratamentoTesteGeneticoSelecionado.tratamentos.splice(oldKey, 1)[0]);
                ctrl.scopeApply();
            }
        });
    }
    static moverRegistroTratamentoTesteGeneticoParaBaixo(ctrl, keyTratamento) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.tratamentoTesteGeneticoSelecionado) {
                return;
            }
            if ((typeof ctrl.tratamentoTesteGeneticoSelecionado.tratamentos === 'undefined') || !ctrl.tratamentoTesteGeneticoSelecionado.tratamentos) {
                ctrl.tratamentoTesteGeneticoSelecionado.tratamentos = [];
            }
            if ((keyTratamento != null) && (keyTratamento >= 0) && (keyTratamento < ctrl.tratamentoTesteGeneticoSelecionado.tratamentos.length - 1)) {
                let oldKey = keyTratamento;
                let newKey = keyTratamento + 1;
                ctrl.tratamentoTesteGeneticoSelecionado.tratamentos.splice(newKey, 0, ctrl.tratamentoTesteGeneticoSelecionado.tratamentos.splice(oldKey, 1)[0]);
                ctrl.scopeApply();
            }
        });
    }
    static alterarTipoTratamentoTestemunhaTratamentoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.tratamentoTesteGeneticoSelecionado) {
                return;
            }
            if ((typeof ctrl.tratamentoTesteGeneticoSelecionado.tipoTeste !== 'undefined') && ctrl.tratamentoTesteGeneticoSelecionado.tipoTeste &&
                (typeof ctrl.listaOpcoesMateriaisGeneticosTratamentosTesteGenetico !== 'undefined') && ctrl.listaOpcoesMateriaisGeneticosTratamentosTesteGenetico &&
                (typeof ctrl.listaOpcoesMateriaisGeneticosTratamentosTesteGenetico.data !== 'undefined') && ctrl.listaOpcoesMateriaisGeneticosTratamentosTesteGenetico.data) {
                let alterouMateriaisGeneticosTratamentosTesteGenetico = false;
                angular.forEach(ctrl.listaOpcoesMateriaisGeneticosTratamentosTesteGenetico.data, (materialGenetico) => {
                    if ((typeof materialGenetico.selecionado !== 'undefined') && materialGenetico.selecionado) {
                        if ((typeof materialGenetico.tipoTeste === 'undefined') || !materialGenetico.tipoTeste) {
                            materialGenetico.tipoTeste = ctrl.tratamentoTesteGeneticoSelecionado.tipoTeste;
                            alterouMateriaisGeneticosTratamentosTesteGenetico = true;
                        }
                    }
                });
                if (alterouMateriaisGeneticosTratamentosTesteGenetico) {
                    ctrl.scopeApply();
                }
            }
        });
    }
    static selecionarTratamento(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            let ctrlTmp = ctrl;
            if (ctrl && ctrl.constructor && ctrl.constructor.name) {
                if (ctrl.constructor.name === 'TabelaController') {
                    let parentTmp = ctrl.$scope.$parent;
                    if (parentTmp.$ctrl) {
                        ctrlTmp = parentTmp.$ctrl;
                    }
                }
            }
            ctrlTmp.filtrarTratamentoSelecionado();
        });
    }
    static filtrarTratamentoSelecionado(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                let tratamento = null;
                const nomeTabelaTratamento = 'tabelaTratamentosTesteGenetico';
                let gridTratamentoApi = null;
                let gridTratamentoScope = angular
                    .element('#' + nomeTabelaTratamento)
                    .scope();
                if (typeof gridTratamentoScope.listaGridApi !== 'undefined' && gridTratamentoScope.listaGridApi) {
                    angular.forEach(gridTratamentoScope.listaGridApi, (gridApi) => {
                        const grid = typeof gridApi.grid !== 'undefined' && gridApi.grid ? gridApi.grid : null;
                        if (grid && typeof grid.element !== 'undefined' && grid.element) {
                            const tabelasEl = angular.element(grid.element).parents('tabela');
                            if (tabelasEl && tabelasEl.length > 0 && tabelasEl.first().attr('id') === nomeTabelaTratamento) {
                                gridTratamentoApi = gridApi;
                            }
                        }
                    });
                }
                if (gridTratamentoApi) {
                    const seminaisSelecionados = gridTratamentoApi.selection.getSelectedRows();
                    if (typeof seminaisSelecionados !== 'undefined' && seminaisSelecionados) {
                        angular.forEach(seminaisSelecionados, (obj) => {
                            if (!tratamento) {
                                tratamento = obj;
                            }
                        });
                    }
                }
                if (tratamento && typeof tratamento.id !== 'undefined' && tratamento.id) {
                    if (typeof ctrl.tratamentoSelecionado !== 'undefined' && ctrl.tratamentoSelecionado &&
                        typeof ctrl.tratamentoSelecionado.id !== 'undefined' && ctrl.tratamentoSelecionado.id) {
                        if (ctrl.tratamentoSelecionado.id !== tratamento.id) {
                            yield ctrl.limparFiltroTratamento();
                            ctrl.tratamentoSelecionado = tratamento;
                            ctrl.tratamentoCarregado = false;
                        }
                    }
                    else {
                        yield ctrl.limparFiltroTratamento();
                        ctrl.tratamentoSelecionado = tratamento;
                        ctrl.tratamentoCarregado = false;
                    }
                }
                else {
                    if (ctrl.tratamentoSelecionado || ctrl.tratamentoCarregado) {
                        yield ctrl.limparFiltroTratamento();
                        ctrl.tratamentoCarregado = false;
                    }
                }
                ctrl.scopeApply();
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
        });
    }
    static limparFiltroTratamento(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.tratamentoSelecionado = null;
            ctrl.tratamentoCarregado = false;
        });
    }
    static abrirDossieMaterialGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.tratamentoSelecionado === 'undefined') || !ctrl.tratamentoSelecionado) {
                ctrl.notificacao.alerta('Selecione um Tratamento na tabela abaixo para acessar o Dossiê.');
                return;
            }
            if ((typeof ctrl.tratamentoSelecionado.materialGenetico === 'undefined') || !ctrl.tratamentoSelecionado.materialGenetico) {
                ctrl.notificacao.alerta('O Tratamento selecionado não possuí um material genético válido.');
                return;
            }
            ctrl.modalTratamentoTesteGenetico = false;
            ctrl.modalGerarCroquiTesteGenetico = false;
            ctrl.modalGerarCroquiTesteGeneticoDados = null;
            ctrl.modalImportacaoCroquiTesteGenetico = false;
            ctrl.modalHistoricoCroquiTesteGenetico = false;
            ctrl.modalControleManejoTesteGenetico = false;
            ctrl.modalMedicaoDendrometricaTesteGenetico = false;
            ctrl.modalPlanejamentoMedicaoDendrometricaTesteGenetico = false;
            ctrl.modalPlanilhaMedicaoDendrometricaTesteGenetico = false;
            ctrl.modalSelegenMedicaoDendrometricaTesteGenetico = false;
            ctrl.modalQualidadeMadeiraTesteGenetico = false;
            ctrl.modalAnaliseSelecaoTesteGenetico = false;
            ctrl.modalSumarioAnaliseSelecaoTesteGenetico = false;
            ctrl.modalPlanilhaSelegenAnaliseSelecaoTesteGenetico = false;
            ctrl.modalAnaliseSelegenAnaliseSelecaoTesteGenetico = false;
            ctrl.modalResultadoSelegenAnaliseSelecaoTesteGenetico = false;
            ctrl.modalAnaliseAnoAnaliseSelecaoTesteGenetico = false;
            ctrl.modalAnaliseBlupAnaliseSelecaoTesteGenetico = false;
            ctrl.modalSelecaoGeneticaAnaliseSelecaoTesteGenetico = false;
            ctrl.modalSelecaoCampoAnaliseSelecaoTesteGenetico = false;
            ctrl.modalAnexoAnaliseSelecaoTesteGenetico = false;
            ctrl.modalRelatorioTesteGenetico = false;
            ctrl.modalAnexoTesteGenetico = false;
            ctrl.modalProtocoloTesteGenetico = false;
            ctrl.modalDossieMaterialGenetico = false;
            try {
                ctrl.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                ctrl.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                ctrl.materialGeneticoFiltrado = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MATERIAL_GENETICO)
                    .id(ctrl.tratamentoSelecionado.materialGenetico.id)
                    .ver();
                let lista = [];
                const listaTratamentos = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TRATAMENTO_TESTE_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TRATAMENTO_TESTE_GENETICO)
                    .filtro({
                    materialGeneticoId: ((typeof ctrl.tratamentoSelecionado.materialGenetico !== 'undefined') && ctrl.tratamentoSelecionado.materialGenetico &&
                        (typeof ctrl.tratamentoSelecionado.materialGenetico.id !== 'undefined') && ctrl.tratamentoSelecionado.materialGenetico.id
                        ? ctrl.tratamentoSelecionado.materialGenetico.id : null)
                })
                    .listar();
                const listaTestesGeneticos = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TESTE_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TESTE_GENETICO)
                    .filtro({})
                    .listar();
                if ((typeof listaTestesGeneticos !== 'undefined') && listaTestesGeneticos) {
                    angular.forEach(listaTestesGeneticos, (testeGenetico) => {
                        testeGenetico.descricaoDetalhada = ((typeof testeGenetico.descricao !== 'undefined') && testeGenetico.descricao ? testeGenetico.descricao : '');
                        if ((typeof testeGenetico.data !== 'undefined') && testeGenetico.data) {
                            testeGenetico.data = (typeof testeGenetico.data === 'string') ? new Date(testeGenetico.data) : testeGenetico.data;
                            if (testeGenetico.data) {
                                let dataHoraAtual = new Date();
                                let idadeMs = dataHoraAtual.getTime() - testeGenetico.data.getTime();
                                let idadeData = new Date(idadeMs);
                                testeGenetico.idade = Math.abs(idadeData.getUTCFullYear() - 1970);
                                testeGenetico.ano = testeGenetico.data.getFullYear();
                                testeGenetico.descricaoDetalhada += ' - ' + testeGenetico.data.getFullYear();
                            }
                        }
                        if ((typeof testeGenetico.paisDescricao === 'undefined') && !testeGenetico.paisDescricao) {
                            if ((typeof ctrl.paises !== 'undefined') && ctrl.paises && (ctrl.paises.length > 0)) {
                                let paisPadrao = null;
                                const paisPadraoReferencia = TextoUtil_1.TextoUtil.converteTextoParaReferencia('Brasil');
                                angular.forEach(ctrl.paises, (pais) => {
                                    if (!paisPadrao && (typeof pais.descricao !== 'undefined') && pais.descricao && (pais.descricao.length > 0)) {
                                        if (TextoUtil_1.TextoUtil.converteTextoParaReferencia(pais.descricao) === paisPadraoReferencia) {
                                            paisPadrao = pais;
                                        }
                                    }
                                });
                                if (paisPadrao) {
                                    testeGenetico.pais = paisPadrao;
                                    testeGenetico.paisId = paisPadrao.id;
                                    testeGenetico.paisCodigo = paisPadrao.codigo;
                                    testeGenetico.paisDescricao = paisPadrao.descricao;
                                }
                            }
                            else {
                                let paisPadrao = {
                                    id: Pais_1.Pais.BRASIL,
                                    uuid: Pais_1.Pais.BRASIL,
                                    status: true,
                                    criacao: new Date(),
                                    codigo: 1,
                                    descricao: 'Brasil'
                                };
                                testeGenetico.pais = paisPadrao;
                                testeGenetico.paisId = paisPadrao.id;
                                testeGenetico.paisCodigo = paisPadrao.codigo;
                                testeGenetico.paisDescricao = paisPadrao.descricao;
                            }
                        }
                        if ((typeof testeGenetico.instituicao === 'undefined') || !testeGenetico.instituicao || (testeGenetico.instituicao.length === 0)) {
                            testeGenetico.instituicao = 'WestRock';
                        }
                        if ((typeof testeGenetico.nomeCientifico === 'undefined') || !testeGenetico.nomeCientifico || (testeGenetico.nomeCientifico.length === 0)) {
                            testeGenetico.nomeCientifico = testeGenetico.descricao;
                        }
                        if ((typeof testeGenetico.distanciaSede === 'undefined') || !testeGenetico.distanciaSede) {
                            testeGenetico.distanciaSede = (testeGenetico.descricaoDetalhada.toLowerCase().indexOf('sede') !== -1) ? '1 km' : '35 km';
                        }
                        testeGenetico.local = ((typeof testeGenetico.municipio !== 'undefined') && testeGenetico.municipio ? testeGenetico.municipio : '') + ' - ' +
                            ((typeof testeGenetico.provinciaUfSigla !== 'undefined') && testeGenetico.provinciaUfSigla ? testeGenetico.provinciaUfSigla : '');
                        if ((typeof testeGenetico.perfilClimaticoAnual !== 'undefined') && testeGenetico.perfilClimaticoAnual) {
                            if ((typeof testeGenetico.perfilClimaticoAnual.atualizacao === 'undefined') || !testeGenetico.perfilClimaticoAnual.atualizacao) {
                                testeGenetico.perfilClimaticoAnual.atualizacao = new Date();
                            }
                            else if (typeof testeGenetico.perfilClimaticoAnual.atualizacao === 'string') {
                                testeGenetico.perfilClimaticoAnual.atualizacao = new Date(testeGenetico.perfilClimaticoAnual.atualizacao);
                            }
                        }
                        else {
                            testeGenetico.perfilClimaticoAnual = new PerfilClimaticoTesteGenetico_1.PerfilClimaticoTesteGenetico();
                            testeGenetico.perfilClimaticoAnual.atualizacao = new Date();
                        }
                        if ((typeof testeGenetico.producaoPortaEnxertosPlantio !== 'undefined') && testeGenetico.producaoPortaEnxertosPlantio) {
                            testeGenetico.producaoPortaEnxertosPlantio = (typeof testeGenetico.producaoPortaEnxertosPlantio === 'string') ? new Date(testeGenetico.producaoPortaEnxertosPlantio) : testeGenetico.producaoPortaEnxertosPlantio;
                        }
                        if ((typeof testeGenetico.implantacaoPomarEnxertia !== 'undefined') && testeGenetico.implantacaoPomarEnxertia) {
                            testeGenetico.implantacaoPomarEnxertia = (typeof testeGenetico.implantacaoPomarEnxertia === 'string') ? new Date(testeGenetico.implantacaoPomarEnxertia) : testeGenetico.implantacaoPomarEnxertia;
                        }
                    });
                }
                if ((typeof listaTratamentos !== 'undefined') && listaTratamentos) {
                    angular.forEach(listaTratamentos, (tratamentoTesteGenetico) => {
                        if ((typeof tratamentoTesteGenetico.testeGenetico !== 'undefined') && tratamentoTesteGenetico.testeGenetico && !lista.some(x => x.id === tratamentoTesteGenetico.testeGenetico.id)) {
                            const buscaTesteGenetico = listaTestesGeneticos.find(x => x.id === tratamentoTesteGenetico.testeGenetico.id);
                            if ((typeof buscaTesteGenetico !== 'undefined') && buscaTesteGenetico) {
                                buscaTesteGenetico.materialGeneticoId = ((typeof ctrl.tratamentoSelecionado.materialGenetico !== 'undefined') && ctrl.tratamentoSelecionado.materialGenetico &&
                                    (typeof ctrl.tratamentoSelecionado.materialGenetico.id !== 'undefined') && ctrl.tratamentoSelecionado.materialGenetico.id
                                    ? ctrl.tratamentoSelecionado.materialGenetico.id : null);
                                buscaTesteGenetico.materialGeneticoDescricao = ((typeof ctrl.tratamentoSelecionado.materialGenetico !== 'undefined') && ctrl.tratamentoSelecionado.materialGenetico &&
                                    (typeof ctrl.tratamentoSelecionado.materialGenetico.descricao !== 'undefined') && ctrl.tratamentoSelecionado.materialGenetico.descricao
                                    ? ctrl.tratamentoSelecionado.materialGenetico.descricao : null);
                                lista.push(buscaTesteGenetico);
                            }
                            else {
                                tratamentoTesteGenetico.testeGenetico.materialGeneticoId = ((typeof ctrl.tratamentoSelecionado.materialGenetico !== 'undefined') && ctrl.tratamentoSelecionado.materialGenetico &&
                                    (typeof ctrl.tratamentoSelecionado.materialGenetico.id !== 'undefined') && ctrl.tratamentoSelecionado.materialGenetico.id
                                    ? ctrl.tratamentoSelecionado.materialGenetico.id : null);
                                tratamentoTesteGenetico.testeGenetico.materialGeneticoDescricao = ((typeof ctrl.tratamentoSelecionado.materialGenetico !== 'undefined') && ctrl.tratamentoSelecionado.materialGenetico &&
                                    (typeof ctrl.tratamentoSelecionado.materialGenetico.descricao !== 'undefined') && ctrl.tratamentoSelecionado.materialGenetico.descricao
                                    ? ctrl.tratamentoSelecionado.materialGenetico.descricao : null);
                                lista.push(tratamentoTesteGenetico.testeGenetico);
                            }
                        }
                    });
                }
                if ((typeof lista !== 'undefined') && lista && (lista.length > 0)) {
                    ctrl.pomarFiltrado = lista[0];
                }
                ctrl.modalDossieMaterialGenetico = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static ocultarDossieMaterialGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.modalDossieMaterialGenetico = false;
            ctrl.materialGeneticoFiltrado = null;
            ctrl.pomarFiltrado = null;
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = ctrl.posicaoTopoAntesModal;
            ctrl.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                        (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
}
exports.TesteCampoTratamentoUtil = TesteCampoTratamentoUtil;
