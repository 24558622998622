"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemCaracteristicaDescritorMorfologico = void 0;
const Base_1 = require("./Base");
class ItemCaracteristicaDescritorMorfologico extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.caracteristicaDescritorMorfologico = null;
        this.respostaIdentificacao = null;
        this.anexos = [];
        this.exemplos = [];
        this.controleRegistro = null;
        this.nomeCaracteristica = null;
        this.respostaIdentificacaoDescricao = null;
        this.identificadoresCell = null;
        this.indexCaracteristica = null;
    }
}
exports.ItemCaracteristicaDescritorMorfologico = ItemCaracteristicaDescritorMorfologico;
