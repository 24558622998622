"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProdutividadePomar = void 0;
const Base_1 = require("./Base");
class ProdutividadePomar extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.id = null;
        this.codigo = null;
        this.data = null;
        this.pomar = null;
        this.pomarCodigo = null;
        this.pomarDescricao = null;
        this.regiao = null;
        this.talhao = null;
        this.funcionarios = [];
        this.anexos = [];
        this.observacoes = null;
        this.descricaoResumida = null;
        this.descricaoDetalhada = null;
        this.usuarioInclusao = null;
        this.dataHoraInclusao = null;
        this.usuarioAlteracao = null;
        this.dataHoraAlteracao = null;
    }
}
exports.ProdutividadePomar = ProdutividadePomar;
