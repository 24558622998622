"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TiposInputComponente = void 0;
const TiposInputController_1 = require("./TiposInputController");
class TiposInputComponente {
    constructor() {
        this.controller = TiposInputController_1.TiposInputController;
        this.templateUrl = require('./tipos-input.componente.html').default;
        this.transclude = true;
        this.bindings = {
            label: '@',
            placeholder: '@',
            required: '=',
            disabled: '=',
            ngshow: '=',
            change: '&',
            field: '=',
            array: '=',
            type: '@',
            show: '@',
            objeto: '@',
            espacoextra: '@',
            value: '=',
            objetovalor: '@',
            minlength: '@',
            maxlength: '@',
            step: '@'
        };
    }
}
exports.TiposInputComponente = TiposInputComponente;
TiposInputComponente.id = 'tiposInput';
