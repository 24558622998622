"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NumeroUtil = void 0;
const TextoUtil_1 = require("./TextoUtil");
class NumeroUtil {
    static converterValorParaNumero(valor) {
        if ((typeof valor !== 'undefined') && (valor !== null)) {
            if (typeof valor === 'number') {
                return isFinite(valor) && !isNaN(valor) ? valor : 0;
            }
            else if (typeof valor === 'bigint') {
                const valor2 = Number(valor);
                return isFinite(valor2) && !isNaN(valor2) ? valor2 : 0;
            }
            else if (typeof valor === 'string') {
                const valor2 = parseFloat(valor.replace(',', '.').trim());
                return isFinite(valor2) && !isNaN(valor2) ? valor2 : 0;
            }
            else {
                const valor2 = parseFloat(valor);
                return isFinite(valor2) && !isNaN(valor2) ? valor2 : 0;
            }
        }
        return null;
    }
    static arredondarNumero(valor, casasDecimais = 2) {
        let valorNumerico = ((typeof valor !== 'undefined') && valor ? NumeroUtil.converterValorParaNumero(valor) : 0);
        if (isNaN(valorNumerico) || !isFinite(valorNumerico)) {
            return 0;
        }
        if ((typeof casasDecimais !== 'undefined') && (casasDecimais !== null) && (casasDecimais > 0)) {
            valorNumerico = Math.round(valorNumerico * Math.pow(10, casasDecimais)) / Math.pow(10, casasDecimais);
        }
        else {
            valorNumerico = Math.round(valorNumerico);
        }
        return valorNumerico;
    }
    static arredondarNumeroParaBaixo(valor, casasDecimais = 2) {
        let valorNumerico = ((typeof valor !== 'undefined') && valor ? NumeroUtil.converterValorParaNumero(valor) : 0);
        if (isNaN(valorNumerico) || !isFinite(valorNumerico)) {
            return 0;
        }
        if ((typeof casasDecimais !== 'undefined') && (casasDecimais !== null) && (casasDecimais > 0)) {
            valorNumerico = Math.floor(valorNumerico * Math.pow(10, casasDecimais)) / Math.pow(10, casasDecimais);
        }
        else {
            valorNumerico = Math.floor(valorNumerico);
        }
        return valorNumerico;
    }
    static arredondarNumeroParaCima(valor, casasDecimais = 2) {
        let valorNumerico = ((typeof valor !== 'undefined') && valor ? NumeroUtil.converterValorParaNumero(valor) : 0);
        if (isNaN(valorNumerico) || !isFinite(valorNumerico)) {
            return 0;
        }
        if ((typeof casasDecimais !== 'undefined') && (casasDecimais !== null) && (casasDecimais > 0)) {
            valorNumerico = Math.ceil(valorNumerico * Math.pow(10, casasDecimais)) / Math.pow(10, casasDecimais);
        }
        else {
            valorNumerico = Math.ceil(valorNumerico);
        }
        return valorNumerico;
    }
    static gerarNumeroAleatorio(min, max, casasDecimais = 2) {
        const temValorMinimo = (typeof min !== 'undefined') && min ? true : false;
        const temValorMaximo = (typeof max !== 'undefined') && max ? true : false;
        const valorMinimoCalculado = temValorMinimo && temValorMaximo ? ((max >= min) ? min : max) : (temValorMinimo ? min : 0);
        const valorMaximoCalculado = temValorMinimo && temValorMaximo ? ((max >= min) ? max : min) : (temValorMaximo ? max : 0);
        let resultado = 0;
        if (valorMaximoCalculado > valorMinimoCalculado) {
            resultado = (Math.random() * (valorMaximoCalculado - valorMinimoCalculado)) + valorMinimoCalculado;
        }
        else if (valorMinimoCalculado > valorMaximoCalculado) {
            resultado = (Math.random() * (valorMinimoCalculado - valorMaximoCalculado)) + valorMaximoCalculado;
        }
        else {
            resultado = valorMinimoCalculado;
        }
        if ((typeof casasDecimais !== 'undefined') && (casasDecimais !== null) && (casasDecimais >= 0)) {
            return Math.round(resultado * Math.pow(10, casasDecimais)) / Math.pow(10, casasDecimais);
        }
        else {
            return Math.round(resultado);
        }
    }
    static gerarNumeroAleatorioInt(min, max, chave = null) {
        const temValorMinimo = (typeof min !== 'undefined') && min ? true : false;
        const temValorMaximo = (typeof max !== 'undefined') && max ? true : false;
        const valorMinimoCalculado = Math.round(temValorMinimo && temValorMaximo ? ((max >= min) ? min : max) : (temValorMinimo ? min : 0));
        const valorMaximoCalculado = Math.round(temValorMinimo && temValorMaximo ? ((max >= min) ? max : min) : (temValorMaximo ? max : 0));
        if (valorMinimoCalculado === valorMaximoCalculado) {
            return valorMinimoCalculado;
        }
        const prop = 'min' + valorMinimoCalculado + '_max' + valorMaximoCalculado +
            ((typeof chave !== 'undefined') && chave ? '_' + TextoUtil_1.TextoUtil.converteTextoParaReferencia(chave) : '');
        if ((typeof globalThis.numerosAleatoriosIntGerados === 'undefined') || (globalThis.numerosAleatoriosIntGerados === null)) {
            globalThis.numerosAleatoriosIntGerados = new Object();
        }
        if ((typeof globalThis.numerosAleatoriosIntGerados[prop] === 'undefined') || (globalThis.numerosAleatoriosIntGerados[prop] === null)) {
            globalThis.numerosAleatoriosIntGerados[prop] = new Array();
        }
        if (globalThis.numerosAleatoriosIntGerados[prop].length >= (valorMaximoCalculado + 1 - valorMinimoCalculado)) {
            globalThis.numerosAleatoriosIntGerados[prop].splice(0, globalThis.numerosAleatoriosIntGerados[prop].length);
        }
        let resultado = null;
        for (let tentativa = 1; tentativa <= ((valorMaximoCalculado + 1 - valorMinimoCalculado) * 100); tentativa++) {
            resultado = NumeroUtil.gerarNumeroAleatorio(min, max, 0);
            if (globalThis.numerosAleatoriosIntGerados[prop].indexOf(resultado) === -1) {
                globalThis.numerosAleatoriosIntGerados[prop].push(resultado);
                break;
            }
        }
        return resultado;
    }
    static formatarNumero(valor, casasDecimais, separadorDecimal = null, removerDecimalZero = false) {
        if ((typeof valor !== 'undefined') && (valor !== null)) {
            let valorNumerico = NumeroUtil.converterValorParaNumero(valor);
            if ((typeof valorNumerico === 'undefined') || (valorNumerico === null) || isNaN(valorNumerico)) {
                return '';
            }
            if ((typeof casasDecimais !== 'undefined') && (casasDecimais !== null) && (casasDecimais >= 0)) {
                valorNumerico = Math.round(valorNumerico * Math.pow(10, casasDecimais)) / Math.pow(10, casasDecimais);
                let texto = valorNumerico.toString();
                if ((texto.indexOf('.') === -1) && (texto.indexOf(',') === -1)) {
                    texto += ((typeof separadorDecimal !== 'undefined') && separadorDecimal && (separadorDecimal.length > 0)) ? separadorDecimal : '.';
                }
                for (let i = 1; i <= casasDecimais; i++) {
                    texto += '0';
                }
                if ((typeof separadorDecimal !== 'undefined') && separadorDecimal && (separadorDecimal.length > 0)) {
                    if (texto.indexOf('.') !== -1) {
                        texto = texto.replace(/\./g, separadorDecimal);
                    }
                    else if (texto.indexOf(',') !== -1) {
                        texto = texto.replace(/\,/g, separadorDecimal);
                    }
                }
                let posicaoPonto = (texto.indexOf('.') !== -1) ? texto.indexOf('.') : texto.indexOf(',');
                if (posicaoPonto !== -1) {
                    texto = texto.substring(0, posicaoPonto + 1 + casasDecimais);
                }
                if (removerDecimalZero) {
                    if (texto.replace(/\,/g, '.').substring(texto.length - 2) === '.0') {
                        texto = texto.substring(0, texto.length - 2);
                    }
                    else if (texto.replace(/\,/g, '.').substring(texto.length - 3) === '.00') {
                        texto = texto.substring(0, texto.length - 3);
                    }
                    else if (texto.replace(/\,/g, '.').substring(texto.length - 4) === '.000') {
                        texto = texto.substring(0, texto.length - 4);
                    }
                    else if (texto.replace(/\,/g, '.').substring(texto.length - 5) === '.0000') {
                        texto = texto.substring(0, texto.length - 5);
                    }
                    else if (texto.replace(/\,/g, '.').substring(texto.length - 6) === '.00000') {
                        texto = texto.substring(0, texto.length - 6);
                    }
                    else if (texto.replace(/\,/g, '.').substring(texto.length - 7) === '.000000') {
                        texto = texto.substring(0, texto.length - 7);
                    }
                }
                return texto;
            }
            else {
                return valorNumerico.toString();
            }
        }
        return '';
    }
    static calcularVolumeArvore(dap, alt) {
        if ((typeof dap === 'undefined') || (dap === null) || (dap === '') ||
            (typeof alt === 'undefined') || (alt === null) || (alt === '')) {
            return null;
        }
        let valorNumericoDap = NumeroUtil.converterValorParaNumero(dap);
        let valorNumericoAlt = NumeroUtil.converterValorParaNumero(alt);
        if ((typeof valorNumericoDap === 'undefined') || (valorNumericoDap === null) || isNaN(valorNumericoDap) ||
            (typeof valorNumericoAlt === 'undefined') || (valorNumericoAlt === null) || isNaN(valorNumericoAlt)) {
            return null;
        }
        return NumeroUtil.arredondarNumeroParaCima((((Math.pow(valorNumericoDap, 2) * 3.141592654) / 40000) * valorNumericoAlt * 0.45), 4);
    }
}
exports.NumeroUtil = NumeroUtil;
