"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UiGridUtil = void 0;
const angular = require("angular");
const ArmazenamentoUtil_1 = require("./ArmazenamentoUtil");
const TextoUtil_1 = require("./TextoUtil");
class UiGridUtil {
    static addLupa() {
        UiGridUtil.timeout = setTimeout(() => __awaiter(this, void 0, void 0, function* () {
            angular.element('.ui-grid-filter-container').prepend('<i class="fa fa-search buscar" style="position: absolute; top: 8px;"></i>');
            angular.element('.ui-grid-filter-container input').css('margin-left', '20px').css('width', 'calc(100% - 15px)');
            angular.element('.ui-grid-filter-container select').css('margin-left', '20px').css('width', 'calc(100% - 15px)');
            clearTimeout(UiGridUtil.timeout);
        }), 1000);
    }
    static getColumnAutoFitWidth(data, col) {
        let arr = [];
        try {
            if ((typeof data !== 'undefined') && data && (data.length > 0) &&
                (typeof col !== 'undefined') && col) {
                const prop = ((typeof col.field !== 'undefined') && col.field && (col.field.length > 0)) ? String(col.field) :
                    (((typeof col.name !== 'undefined') && col.name && (col.name.length > 0)) ? String(col.name) : null);
                if ((typeof prop !== 'undefined') && prop && (prop.length > 0)) {
                    angular.forEach(data, (item) => {
                        const val = prop.split('.').reduce((a, b) => a ? a[b] : null, item);
                        if ((typeof val !== 'undefined') && val) {
                            arr.push(`${val}`);
                        }
                    });
                }
            }
            if ((typeof col !== 'undefined') && col) {
                let titulo = null;
                if ((typeof col.displayName !== 'undefined') && col.displayName && (col.displayName.length > 0)) {
                    titulo = col.displayName;
                }
                else if ((typeof col.name !== 'undefined') && col.name && (col.name.length > 0)) {
                    titulo = col.name;
                }
                else if ((typeof col.field !== 'undefined') && col.field && (col.field.length > 0)) {
                    titulo = col.field;
                }
                if ((typeof titulo !== 'undefined') && titulo && (titulo.length > 0)) {
                    arr.push(titulo + String('-').repeat(Math.round(titulo.length / 10)));
                }
            }
            if (arr && (arr.length > 0)) {
                let minLength = 0;
                let maxLength = 0;
                angular.forEach(arr, (s) => {
                    const sLength = s ? s.length : 0;
                    if (sLength > 0) {
                        if ((minLength <= 0) || (sLength < minLength)) {
                            minLength = sLength;
                        }
                        if ((maxLength <= 0) || (sLength > maxLength)) {
                            maxLength = sLength;
                        }
                    }
                });
                const avgLength = Math.round(((minLength + maxLength) / 2) - 1);
                let elDivCalcWidth = angular.element('div#uiGridCalcWidth');
                if (!elDivCalcWidth || (elDivCalcWidth && (elDivCalcWidth.length === 0))) {
                    elDivCalcWidth = angular.element('<div id="uiGridCalcWidth"></div>');
                    elDivCalcWidth.attr('style', 'visibility: hidden !important; z-index: -9999999999 !important; ' +
                        'position: absolute !important; left: 0 !important; top: 0 !important;');
                    for (let i = 32; i < 127; i++) {
                        elDivCalcWidth.append('<span id="uiGridCalcWidthChr' + i + '" class="ui-grid-cell-contents" ' +
                            'style="margin: 0 !important; padding: 0 !important;">' + String.fromCharCode(i) + '</span>');
                    }
                    let elAppContent = angular.element('.app-content');
                    if (elAppContent && (elAppContent.length > 0)) {
                        elAppContent.first().prepend(elDivCalcWidth);
                    }
                }
                let arrCharsWidth = {};
                elDivCalcWidth.children().each((_idx, el) => {
                    const elChr = angular.element(el);
                    arrCharsWidth['chr' + elChr.attr('id').replace('uiGridCalcWidthChr', '')] = elChr.width();
                });
                let widthTotal = 0;
                angular.forEach(arr, (s) => {
                    const sLength = s ? s.length : 0;
                    if (sLength >= avgLength) {
                        let sWidth = 0;
                        for (let sKey = 0; sKey < sLength; sKey++) {
                            const charCode = s.charCodeAt(sKey);
                            const charWidth = arrCharsWidth['chr' + charCode];
                            if (typeof charWidth !== 'undefined') {
                                sWidth += charWidth ? charWidth : 5;
                            }
                            else {
                                elDivCalcWidth.append('<span id="uiGridCalcWidthChr' + charCode + '" class="ui-grid-cell-contents" ' +
                                    'style="margin: 0 !important; padding: 0 !important;">' + s.charAt(sKey) + '</span>');
                                const charWidth2 = elDivCalcWidth.find('#uiGridCalcWidthChr' + charCode).width();
                                arrCharsWidth['chr' + charCode] = charWidth2;
                                sWidth += charWidth2 ? charWidth2 : 5;
                            }
                        }
                        if ((widthTotal === 0) || (sWidth > widthTotal)) {
                            widthTotal = sWidth;
                        }
                    }
                });
                widthTotal = widthTotal + 15;
                if (widthTotal < 70) {
                    widthTotal = 70;
                }
                return widthTotal;
            }
            else {
                return 50;
            }
        }
        catch (ex) {
            const prop = ((typeof col.field !== 'undefined') && col.field && (col.field.length > 0)) ? String(col.field) :
                (((typeof col.name !== 'undefined') && col.name && (col.name.length > 0)) ? String(col.name) :
                    (((typeof col.displayName !== 'undefined') && col.displayName && (col.displayName.length > 0)) ? String(col.displayName) : ''));
            console.log('ERRO: UiGridUtil.getColumnAutoFitWidth( col: "' + prop + '" )');
            console.log(ex);
            return 50;
        }
    }
    static saveStates(gridApi) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof gridApi !== 'undefined') && gridApi &&
                    (typeof gridApi.saveState !== 'undefined') && gridApi.saveState) {
                    const grid = ((typeof gridApi.grid !== 'undefined') && gridApi.grid) ? gridApi.grid : null;
                    const gridEl = ((typeof grid.element !== 'undefined') && grid.element) ? grid.element : null;
                    const gridId = (gridEl && angular.isArray(gridEl) && (gridEl.length > 0)
                        ? angular.element(gridEl[0]).parents('tabela').first().attr('id')
                        : (gridEl ? angular.element(gridEl).parents('tabela').first().attr('id') : null));
                    const chave = 'ui-grid-' + TextoUtil_1.TextoUtil.converteTextoParaReferencia(location.href) +
                        (gridId ? TextoUtil_1.TextoUtil.converteTextoParaReferencia(gridId) : '');
                    const armazenamento = new ArmazenamentoUtil_1.LocalStorageStrategy(chave);
                    try {
                        const gridState = gridApi.saveState.save();
                        yield armazenamento.salvar(gridState);
                    }
                    catch (ex) {
                        console.log('ERRO: UiGridUtil.SaveStates() > save state');
                        console.log(ex);
                    }
                }
            }
            catch (ex) {
                console.log('ERRO: UiGridUtil.SaveStates()');
                console.log(ex);
            }
        });
    }
    static restoreStates(gridApi, $scope) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof gridApi !== 'undefined') && gridApi &&
                    (typeof gridApi.saveState !== 'undefined') && gridApi.saveState) {
                    const grid = ((typeof gridApi.grid !== 'undefined') && gridApi.grid) ? gridApi.grid : null;
                    const gridEl = ((typeof grid.element !== 'undefined') && grid.element) ? grid.element : null;
                    const gridId = (gridEl && angular.isArray(gridEl) && (gridEl.length > 0)
                        ? angular.element(gridEl[0]).parents('tabela').first().attr('id')
                        : (gridEl ? angular.element(gridEl).parents('tabela').first().attr('id') : null));
                    const chave = 'ui-grid-' + TextoUtil_1.TextoUtil.converteTextoParaReferencia(location.href) +
                        (gridId ? TextoUtil_1.TextoUtil.converteTextoParaReferencia(gridId) : '');
                    const armazenamento = new ArmazenamentoUtil_1.LocalStorageStrategy(chave);
                    try {
                        const gridState = yield armazenamento.ler();
                        if ((typeof gridState !== 'undefined') && gridState) {
                            let gridSelectedRows = null;
                            if ((typeof gridApi.selection !== 'undefined') && gridApi.selection) {
                                gridSelectedRows = gridApi.selection.getSelectedRows();
                            }
                            gridApi.saveState.restore($scope, gridState);
                            if (gridSelectedRows && (typeof gridApi.selection !== 'undefined') && gridApi.selection) {
                                angular.forEach(gridSelectedRows, (row) => {
                                    gridApi.selection.selectRow(row);
                                });
                            }
                        }
                    }
                    catch (ex) {
                        console.log('ERRO: UiGridUtil.RestoreStates() > restore state');
                        console.log(ex);
                    }
                }
            }
            catch (ex) {
                console.log('ERRO: UiGridUtil.RestoreStates()');
                console.log(ex);
            }
        });
    }
}
exports.UiGridUtil = UiGridUtil;
UiGridUtil.timeout = null;
