"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MovimentacaoEstoque = void 0;
const Base_1 = require("./Base");
class MovimentacaoEstoque extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.id = null;
        this.codigo = null;
        this.lote = null;
        this.cliente = null;
        this.ehCadastroLote = null;
        this.ehLancamentoPerdas = null;
        this.ehSegundaSelecaoMudas = null;
        this.ehVasilhameTubete = null;
        this.ehVasilhameEllepot = null;
        this.ehVasilhameBandeja = null;
        this.ehVasilhameCaixa = null;
        this.usuario = null;
        this.apontamento = null;
        this.dataHoraApontamento = null;
        this.operacao = null;
        this.tipoLocal = null;
        this.tipoLancamento = null;
        this.quantidadeAnteriorBandejas = null;
        this.quantidadeMovimentoBandejas = null;
        this.quantidadeFinalBandejas = null;
        this.quantidadeAnteriorMudas = null;
        this.quantidadeMovimentoMudas = null;
        this.quantidadeFinalMudas = null;
        this.quantidadeAnteriorVasilhames = null;
        this.quantidadeMovimentoVasilhames = null;
        this.quantidadeFinalVasilhames = null;
        this.observacoes = null;
    }
}
exports.MovimentacaoEstoque = MovimentacaoEstoque;
