"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnoAnaliseTesteGenetico = void 0;
class AnoAnaliseTesteGenetico {
    constructor() {
        this.id = null;
        this.descricao = null;
    }
    static V_15_DIAS() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.anoAnaliseTesteGenetico_15dias === 'undefined') ||
            (globalThis.globalConstants.anoAnaliseTesteGenetico_15dias === null)) {
            let anoAnaliseTesteGenetico_15dias = new AnoAnaliseTesteGenetico();
            anoAnaliseTesteGenetico_15dias.id = '15_DIAS';
            anoAnaliseTesteGenetico_15dias.descricao = '15 dias';
            globalThis.globalConstants.anoAnaliseTesteGenetico_15dias = anoAnaliseTesteGenetico_15dias;
        }
        return globalThis.globalConstants.anoAnaliseTesteGenetico_15dias;
    }
    static V_6_MESES() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.anoAnaliseTesteGenetico_6meses === 'undefined') ||
            (globalThis.globalConstants.anoAnaliseTesteGenetico_6meses === null)) {
            let anoAnaliseTesteGenetico_6meses = new AnoAnaliseTesteGenetico();
            anoAnaliseTesteGenetico_6meses.id = '6_MESES';
            anoAnaliseTesteGenetico_6meses.descricao = '6 meses';
            globalThis.globalConstants.anoAnaliseTesteGenetico_6meses = anoAnaliseTesteGenetico_6meses;
        }
        return globalThis.globalConstants.anoAnaliseTesteGenetico_6meses;
    }
    static V_1_ANO() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.anoAnaliseTesteGenetico_1ano === 'undefined') ||
            (globalThis.globalConstants.anoAnaliseTesteGenetico_1ano === null)) {
            let anoAnaliseTesteGenetico_1ano = new AnoAnaliseTesteGenetico();
            anoAnaliseTesteGenetico_1ano.id = '1_ANO';
            anoAnaliseTesteGenetico_1ano.descricao = '1º ano';
            globalThis.globalConstants.anoAnaliseTesteGenetico_1ano = anoAnaliseTesteGenetico_1ano;
        }
        return globalThis.globalConstants.anoAnaliseTesteGenetico_1ano;
    }
    static V_2_ANO() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.anoAnaliseTesteGenetico_2ano === 'undefined') ||
            (globalThis.globalConstants.anoAnaliseTesteGenetico_2ano === null)) {
            let anoAnaliseTesteGenetico_2ano = new AnoAnaliseTesteGenetico();
            anoAnaliseTesteGenetico_2ano.id = '2_ANO';
            anoAnaliseTesteGenetico_2ano.descricao = '2º ano';
            globalThis.globalConstants.anoAnaliseTesteGenetico_2ano = anoAnaliseTesteGenetico_2ano;
        }
        return globalThis.globalConstants.anoAnaliseTesteGenetico_2ano;
    }
    static V_3_ANO() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.anoAnaliseTesteGenetico_3ano === 'undefined') ||
            (globalThis.globalConstants.anoAnaliseTesteGenetico_3ano === null)) {
            let anoAnaliseTesteGenetico_3ano = new AnoAnaliseTesteGenetico();
            anoAnaliseTesteGenetico_3ano.id = '3_ANO';
            anoAnaliseTesteGenetico_3ano.descricao = '3º ano';
            globalThis.globalConstants.anoAnaliseTesteGenetico_3ano = anoAnaliseTesteGenetico_3ano;
        }
        return globalThis.globalConstants.anoAnaliseTesteGenetico_3ano;
    }
    static V_4_ANO() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.anoAnaliseTesteGenetico_4ano === 'undefined') ||
            (globalThis.globalConstants.anoAnaliseTesteGenetico_4ano === null)) {
            let anoAnaliseTesteGenetico_4ano = new AnoAnaliseTesteGenetico();
            anoAnaliseTesteGenetico_4ano.id = '4_ANO';
            anoAnaliseTesteGenetico_4ano.descricao = '4º ano';
            globalThis.globalConstants.anoAnaliseTesteGenetico_4ano = anoAnaliseTesteGenetico_4ano;
        }
        return globalThis.globalConstants.anoAnaliseTesteGenetico_4ano;
    }
    static V_5_ANO() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.anoAnaliseTesteGenetico_5ano === 'undefined') ||
            (globalThis.globalConstants.anoAnaliseTesteGenetico_5ano === null)) {
            let anoAnaliseTesteGenetico_5ano = new AnoAnaliseTesteGenetico();
            anoAnaliseTesteGenetico_5ano.id = '5_ANO';
            anoAnaliseTesteGenetico_5ano.descricao = '5º ano';
            globalThis.globalConstants.anoAnaliseTesteGenetico_5ano = anoAnaliseTesteGenetico_5ano;
        }
        return globalThis.globalConstants.anoAnaliseTesteGenetico_5ano;
    }
    static V_6_ANO() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.anoAnaliseTesteGenetico_6ano === 'undefined') ||
            (globalThis.globalConstants.anoAnaliseTesteGenetico_6ano === null)) {
            let anoAnaliseTesteGenetico_6ano = new AnoAnaliseTesteGenetico();
            anoAnaliseTesteGenetico_6ano.id = '6_ANO';
            anoAnaliseTesteGenetico_6ano.descricao = '6º ano';
            globalThis.globalConstants.anoAnaliseTesteGenetico_6ano = anoAnaliseTesteGenetico_6ano;
        }
        return globalThis.globalConstants.anoAnaliseTesteGenetico_6ano;
    }
    static V_7_ANO() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.anoAnaliseTesteGenetico_7ano === 'undefined') ||
            (globalThis.globalConstants.anoAnaliseTesteGenetico_7ano === null)) {
            let anoAnaliseTesteGenetico_7ano = new AnoAnaliseTesteGenetico();
            anoAnaliseTesteGenetico_7ano.id = '7_ANO';
            anoAnaliseTesteGenetico_7ano.descricao = '7º ano';
            globalThis.globalConstants.anoAnaliseTesteGenetico_7ano = anoAnaliseTesteGenetico_7ano;
        }
        return globalThis.globalConstants.anoAnaliseTesteGenetico_7ano;
    }
    static V_8_ANO() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.anoAnaliseTesteGenetico_8ano === 'undefined') ||
            (globalThis.globalConstants.anoAnaliseTesteGenetico_8ano === null)) {
            let anoAnaliseTesteGenetico_8ano = new AnoAnaliseTesteGenetico();
            anoAnaliseTesteGenetico_8ano.id = '8_ANO';
            anoAnaliseTesteGenetico_8ano.descricao = '8º ano';
            globalThis.globalConstants.anoAnaliseTesteGenetico_8ano = anoAnaliseTesteGenetico_8ano;
        }
        return globalThis.globalConstants.anoAnaliseTesteGenetico_8ano;
    }
    static LISTA() {
        return [
            AnoAnaliseTesteGenetico.V_15_DIAS(),
            AnoAnaliseTesteGenetico.V_6_MESES(),
            AnoAnaliseTesteGenetico.V_1_ANO(),
            AnoAnaliseTesteGenetico.V_2_ANO(),
            AnoAnaliseTesteGenetico.V_3_ANO(),
            AnoAnaliseTesteGenetico.V_4_ANO(),
            AnoAnaliseTesteGenetico.V_5_ANO(),
            AnoAnaliseTesteGenetico.V_6_ANO(),
            AnoAnaliseTesteGenetico.V_7_ANO(),
            AnoAnaliseTesteGenetico.V_8_ANO()
        ];
    }
}
exports.AnoAnaliseTesteGenetico = AnoAnaliseTesteGenetico;
