"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EstoquePolenPolinizacaoPomar = void 0;
const Base_1 = require("./Base");
class EstoquePolenPolinizacaoPomar extends Base_1.Base {
    constructor() {
        super();
        this.id = null;
        this.codigo = null;
        this.pomar = null;
        this.pomarOrigemPolen = null;
        this.materialGenetico = null;
        this.anoColeta = null;
        this.quantidadeColetada = null;
        this.quantidadeAtual = null;
        this.usuarioInclusao = null;
        this.dataHoraInclusao = null;
        this.usuarioAlteracao = null;
        this.dataHoraAlteracao = null;
        this.status = true;
    }
}
exports.EstoquePolenPolinizacaoPomar = EstoquePolenPolinizacaoPomar;
