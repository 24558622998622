"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TesteCampoRelatorioUtil = void 0;
const angular = require("angular");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const FiltroRelatorioTesteGenetico_1 = require("../../../../../io/FiltroRelatorioTesteGenetico");
const DadosRelatorioTeste_1 = require("../../../../../io/DadosRelatorioTeste");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const TesteGeneticoUtil_1 = require("../../../../../util/TesteGeneticoUtil");
const DispositivoUtil_1 = require("../../../../../util/DispositivoUtil");
const chart_js_1 = require("chart.js");
const TextoUtil_1 = require("../../../../../util/TextoUtil");
const DataUtil_1 = require("../../../../../util/DataUtil");
const NumeroUtil_1 = require("../../../../../util/NumeroUtil");
class TesteCampoRelatorioUtil {
    static carregarDadosRelatorioTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            const funcaoOrigemCarregamento = 'carregarDadosRelatorioTesteGenetico';
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card', funcaoOrigemCarregamento);
            try {
                if ((typeof ctrl.filtroRelatorioTesteGenetico === 'undefined') || !ctrl.filtroRelatorioTesteGenetico) {
                    ctrl.filtroRelatorioTesteGenetico = new FiltroRelatorioTesteGenetico_1.FiltroRelatorioTesteGenetico();
                }
                if ((typeof ctrl.dadosRelatorioTeste === 'undefined') || !ctrl.dadosRelatorioTeste ||
                    (typeof ctrl.dadosRelatorioCarregado === 'undefined') || !ctrl.dadosRelatorioCarregado) {
                    ctrl.dadosRelatorioTeste = new DadosRelatorioTeste_1.DadosRelatorioTeste();
                    if ((typeof ctrl.generosFiltro === 'undefined') || !ctrl.generosFiltro) {
                        ctrl.generosFiltro = new Array();
                    }
                    if ((typeof ctrl.especiesFiltro === 'undefined') || !ctrl.especiesFiltro) {
                        ctrl.especiesFiltro = new Array();
                    }
                    if ((typeof ctrl.fazendasFiltro === 'undefined') || !ctrl.fazendasFiltro) {
                        ctrl.fazendasFiltro = new Array();
                    }
                    if ((typeof ctrl.conjuntos === 'undefined') || !ctrl.conjuntos) {
                        ctrl.conjuntos = new Array();
                    }
                    if ((typeof ctrl.situacoesFiltro === 'undefined') || !ctrl.situacoesFiltro) {
                        ctrl.situacoesFiltro = new Array();
                    }
                    if ((typeof ctrl.tiposTestesPopulacoesFiltro === 'undefined') || !ctrl.tiposTestesPopulacoesFiltro) {
                        ctrl.tiposTestesPopulacoesFiltro = new Array();
                    }
                    if ((typeof ctrl.listaAnosFiltro === 'undefined') || !ctrl.listaAnosFiltro) {
                        ctrl.listaAnosFiltro = new Array();
                    }
                    if ((typeof ctrl.pomaresFiltro === 'undefined') || !ctrl.pomaresFiltro) {
                        ctrl.pomaresFiltro = new Array();
                    }
                    ctrl.dadosRelatorioTeste.listaTestesGeneticos = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TESTE_GENETICO)
                        .filtro({
                        listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular()
                    })
                        .listar();
                    if ((typeof ctrl.dadosRelatorioTeste.listaTestesGeneticos !== 'undefined') && ctrl.dadosRelatorioTeste.listaTestesGeneticos) {
                        angular.forEach(ctrl.dadosRelatorioTeste.listaTestesGeneticos, (testeGenetico) => {
                            TesteGeneticoUtil_1.TesteGeneticoUtil.padronizaPropriedadesTesteGenetico(testeGenetico, ctrl.paises);
                            if ((typeof testeGenetico.pomarOrigem !== 'undefined') && testeGenetico.pomarOrigem) {
                                const refPomarOrigem = TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade(testeGenetico.pomarOrigem));
                                if (refPomarOrigem && (refPomarOrigem.length > 0)) {
                                    if (!ctrl.pomares.some(x => TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade(x)) === refPomarOrigem)) {
                                        ctrl.pomares.push(testeGenetico.pomarOrigem);
                                    }
                                }
                                if ((typeof testeGenetico.pomarOrigem.tipoPomarPopulacao !== 'undefined') && testeGenetico.pomarOrigem.tipoPomarPopulacao) {
                                    const refPopulacao = TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade(testeGenetico.pomarOrigem.tipoPomarPopulacao));
                                    if (refPopulacao && (refPopulacao.length > 0)) {
                                        if (!ctrl.populacoes.some(x => TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade(x)) === refPopulacao)) {
                                            ctrl.populacoes.push(testeGenetico.tipoPomarPopulacao);
                                        }
                                    }
                                }
                            }
                            if ((typeof testeGenetico.statusTeste !== 'undefined') && testeGenetico.statusTeste) {
                                const refStatusTeste = TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade(testeGenetico.statusTeste));
                                if (refStatusTeste && (refStatusTeste.length > 0)) {
                                    if (!ctrl.statusTestes.some(x => TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade(x)) === refStatusTeste)) {
                                        ctrl.statusTestes.push(testeGenetico.statusTeste);
                                    }
                                }
                            }
                            if ((typeof testeGenetico.genero !== 'undefined') && testeGenetico.genero) {
                                const refGenero = TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade(testeGenetico.genero));
                                if (refGenero && (refGenero.length > 0)) {
                                    if (!ctrl.generosFiltro.some(x => TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade(x)) === refGenero)) {
                                        ctrl.generosFiltro.push(testeGenetico.genero);
                                    }
                                }
                            }
                            if ((typeof testeGenetico.especie !== 'undefined') && testeGenetico.especie) {
                                const refEspecie = TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade(testeGenetico.especie));
                                if (refEspecie && (refEspecie.length > 0)) {
                                    if (!ctrl.especiesFiltro.some(x => TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade(x)) === refEspecie)) {
                                        ctrl.especiesFiltro.push(testeGenetico.especie);
                                    }
                                }
                            }
                            if ((typeof testeGenetico.regiaoFazenda !== 'undefined') && testeGenetico.regiaoFazenda) {
                                const refRegiaoFazenda = TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade(testeGenetico.regiaoFazenda));
                                if (refRegiaoFazenda && (refRegiaoFazenda.length > 0)) {
                                    if (!ctrl.fazendasFiltro.some(x => TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade(x)) === refRegiaoFazenda)) {
                                        ctrl.fazendasFiltro.push(testeGenetico.regiaoFazenda);
                                    }
                                }
                            }
                            if ((typeof testeGenetico.conjunto !== 'undefined') && testeGenetico.conjunto) {
                                const refConjunto = TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade(testeGenetico.conjunto));
                                if (refConjunto && (refConjunto.length > 0)) {
                                    if (!ctrl.conjuntos.some(x => TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade(x)) === refConjunto)) {
                                        ctrl.conjuntos.push(testeGenetico.conjunto);
                                    }
                                }
                            }
                            if ((typeof testeGenetico.situacao !== 'undefined') && testeGenetico.situacao) {
                                const refSituacao = TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade(testeGenetico.situacao));
                                if (refSituacao && (refSituacao.length > 0)) {
                                    if (!ctrl.situacoesFiltro.some(x => TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade(x)) === refSituacao)) {
                                        ctrl.situacoesFiltro.push(testeGenetico.situacao);
                                    }
                                }
                            }
                            if ((typeof testeGenetico.tipoTestePopulacao !== 'undefined') && testeGenetico.tipoTestePopulacao) {
                                const refTipoTestePopulacao = TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade(testeGenetico.tipoTestePopulacao));
                                if (refTipoTestePopulacao && (refTipoTestePopulacao.length > 0)) {
                                    if (!ctrl.tiposTestesPopulacoesFiltro.some(x => TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade(x)) === refTipoTestePopulacao)) {
                                        ctrl.tiposTestesPopulacoesFiltro.push(testeGenetico.tipoTestePopulacao);
                                    }
                                }
                            }
                            if ((typeof testeGenetico.pomarOrigem !== 'undefined') && testeGenetico.pomarOrigem &&
                                (typeof testeGenetico.pomarOrigem.tipo !== 'undefined') && testeGenetico.pomarOrigem.tipo) {
                                const refTipoPomar = TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade(testeGenetico.pomarOrigem.tipo));
                                if (refTipoPomar && (refTipoPomar.length > 0)) {
                                    if (!ctrl.tiposPomaresFiltro.some(x => TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade(x)) === refTipoPomar)) {
                                        ctrl.tiposPomaresFiltro.push(testeGenetico.pomarOrigem.tipo);
                                    }
                                }
                            }
                            if ((typeof testeGenetico.data !== 'undefined') && testeGenetico.data) {
                                let dataTesteGenetico = DataUtil_1.DataUtil.converterValorParaDataHora(testeGenetico.data);
                                if ((typeof dataTesteGenetico !== 'undefined') && dataTesteGenetico) {
                                    let anoTesteGenetico = dataTesteGenetico.getFullYear();
                                    if (anoTesteGenetico && (anoTesteGenetico > 0) && !ctrl.listaAnosFiltro.includes(anoTesteGenetico)) {
                                        ctrl.listaAnosFiltro.push(anoTesteGenetico);
                                    }
                                }
                            }
                            if ((typeof testeGenetico.dataImplantacao !== 'undefined') && testeGenetico.dataImplantacao) {
                                let dataImplantacaoTesteGenetico = DataUtil_1.DataUtil.converterValorParaDataHora(testeGenetico.dataImplantacao);
                                if ((typeof dataImplantacaoTesteGenetico !== 'undefined') && dataImplantacaoTesteGenetico) {
                                    let anoImplantacaoTesteGenetico = dataImplantacaoTesteGenetico.getFullYear();
                                    if (anoImplantacaoTesteGenetico && (anoImplantacaoTesteGenetico > 0) && !ctrl.listaAnosFiltro.includes(anoImplantacaoTesteGenetico)) {
                                        ctrl.listaAnosFiltro.push(anoImplantacaoTesteGenetico);
                                    }
                                }
                            }
                            if ((typeof testeGenetico.pomarOrigem !== 'undefined') && testeGenetico.pomarOrigem) {
                                const refPomar = TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade(testeGenetico.pomarOrigem));
                                if (refPomar && (refPomar.length > 0)) {
                                    if (!ctrl.pomaresFiltro.some(x => TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade(x)) === refPomar)) {
                                        ctrl.pomaresFiltro.push(testeGenetico.pomarOrigem);
                                    }
                                }
                            }
                        });
                    }
                    if ((typeof ctrl.fazendasFiltro === 'undefined') || !ctrl.fazendasFiltro || (ctrl.fazendasFiltro.length === 0)) {
                        ctrl.fazendasFiltro = [...ctrl.regioes];
                    }
                    ctrl.fazendasFiltro.sort((a, b) => {
                        let aStr = TextoUtil_1.TextoUtil.descricaoEntidade(a);
                        let bStr = TextoUtil_1.TextoUtil.descricaoEntidade(b);
                        return ((aStr > bStr) ? 1 : ((bStr > aStr) ? -1 : 0));
                    });
                    if ((typeof ctrl.listaAnosFiltro === 'undefined') || !ctrl.listaAnosFiltro || (ctrl.listaAnosFiltro.length === 0)) {
                        ctrl.listaAnosFiltro = [...ctrl.listaAnos];
                    }
                    ctrl.listaAnosFiltro.sort();
                    if ((typeof ctrl.pomaresFiltro === 'undefined') || !ctrl.pomaresFiltro || (ctrl.pomaresFiltro.length === 0)) {
                        ctrl.pomaresFiltro = [...ctrl.pomares];
                    }
                    ctrl.pomaresFiltro.sort((a, b) => {
                        let aStr = ((typeof a.descricaoDetalhada !== 'undefined') && a.descricaoDetalhada ? a.descricaoDetalhada :
                            ((typeof a.descricao !== 'undefined') && a.descricao ? a.descricao : TextoUtil_1.TextoUtil.descricaoEntidade(a)));
                        let bStr = ((typeof b.descricaoDetalhada !== 'undefined') && b.descricaoDetalhada ? b.descricaoDetalhada :
                            ((typeof b.descricao !== 'undefined') && b.descricao ? b.descricao : TextoUtil_1.TextoUtil.descricaoEntidade(b)));
                        return ((aStr > bStr) ? 1 : ((bStr > aStr) ? -1 : 0));
                    });
                    ctrl.dadosRelatorioTeste.listaTratamentosTestesGeneticos = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.TRATAMENTO_TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TRATAMENTO_TESTE_GENETICO)
                        .filtro({
                        listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular()
                    })
                        .listar();
                    ctrl.dadosRelatorioCarregado = true;
                }
                yield ctrl.alterarFiltroRelatorioTesteGenetico();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
            }
        });
    }
    static exibirModalRelatorioTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                ctrl.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                ctrl.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                yield ctrl.alterarFiltroRelatorioTesteGenetico();
                ctrl.modalRelatorioTesteGenetico = true;
                yield ctrl.carregarDadosRelatorioTesteGenetico();
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static ocultarModalRelatorioTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.modalRelatorioTesteGenetico = false;
            ctrl.relatorioTesteGeneticoSelecionado = null;
            ctrl.relatorioTesteGeneticoVisualizar = null;
            angular.element('body').css('overflow-y', '');
            ctrl.carregarTodosAnos();
            globalThis.posicaoTopoAntesModal = ctrl.posicaoTopoAntesModal;
            ctrl.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                        (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    static alterarFiltroRelatorioTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            const funcaoOrigemCarregamento = 'alterarFiltroRelatorioTesteGenetico';
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card', funcaoOrigemCarregamento);
            try {
                ctrl.dadosRelatorioTeste.listaFiltradaTestesGeneticos = new Array();
                ctrl.dadosRelatorioTeste.listaFiltradaTratamentosTestesGeneticos = new Array();
                if ((typeof ctrl.dadosRelatorioTeste.listaTestesGeneticos === 'undefined') || !ctrl.dadosRelatorioTeste.listaTestesGeneticos) {
                    ctrl.dadosRelatorioTeste.listaTestesGeneticos = new Array();
                }
                if ((typeof ctrl.dadosRelatorioTeste.listaTratamentosTestesGeneticos === 'undefined') || !ctrl.dadosRelatorioTeste.listaTratamentosTestesGeneticos) {
                    ctrl.dadosRelatorioTeste.listaTratamentosTestesGeneticos = new Array();
                }
                angular.forEach(ctrl.dadosRelatorioTeste.listaTestesGeneticos, (testeGenetico) => {
                    let testeGeneticoValido = ((typeof testeGenetico !== 'undefined') && testeGenetico ? true : false);
                    if (testeGeneticoValido && (typeof ctrl.filtroRelatorioTesteGenetico.tipoTeste !== 'undefined') && ctrl.filtroRelatorioTesteGenetico.tipoTeste) {
                        const refFiltroTipoTeste = TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade(ctrl.filtroRelatorioTesteGenetico.tipoTeste));
                        const refTipoTeste = TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade((typeof testeGenetico.tipoTestePopulacao !== 'undefined') && testeGenetico.tipoTestePopulacao ? testeGenetico.tipoTestePopulacao : null));
                        if (refFiltroTipoTeste !== refTipoTeste) {
                            testeGeneticoValido = false;
                        }
                    }
                    if (testeGeneticoValido && (typeof ctrl.filtroRelatorioTesteGenetico.genero !== 'undefined') && ctrl.filtroRelatorioTesteGenetico.genero) {
                        const refFiltroGenero = TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade(ctrl.filtroRelatorioTesteGenetico.genero));
                        const refGenero = TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade((typeof testeGenetico.genero !== 'undefined') && testeGenetico.genero ? testeGenetico.genero : null));
                        if (refFiltroGenero !== refGenero) {
                            testeGeneticoValido = false;
                        }
                    }
                    if (testeGeneticoValido && (typeof ctrl.filtroRelatorioTesteGenetico.populacao !== 'undefined') && ctrl.filtroRelatorioTesteGenetico.populacao) {
                        const refFiltroPopulacao = TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade(ctrl.filtroRelatorioTesteGenetico.populacao));
                        const refPopulacao = TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade((typeof testeGenetico.pomarOrigem !== 'undefined') && testeGenetico.pomarOrigem &&
                            (typeof testeGenetico.pomarOrigem.tipo !== 'undefined') && testeGenetico.pomarOrigem.tipo
                            ? testeGenetico.pomarOrigem.tipo : null));
                        if (refFiltroPopulacao !== refPopulacao) {
                            testeGeneticoValido = false;
                        }
                    }
                    if (testeGeneticoValido && (typeof ctrl.filtroRelatorioTesteGenetico.ano !== 'undefined') && ctrl.filtroRelatorioTesteGenetico.ano) {
                        let contadorAnoValido = 0;
                        if ((typeof testeGenetico.data !== 'undefined') && testeGenetico.data) {
                            let dataTesteGenetico = DataUtil_1.DataUtil.converterValorParaDataHora(testeGenetico.data);
                            if ((typeof dataTesteGenetico !== 'undefined') && dataTesteGenetico &&
                                (ctrl.filtroRelatorioTesteGenetico.ano === dataTesteGenetico.getFullYear())) {
                                contadorAnoValido++;
                            }
                        }
                        if ((typeof testeGenetico.dataImplantacao !== 'undefined') && testeGenetico.dataImplantacao) {
                            let dataImplantacaoTesteGenetico = DataUtil_1.DataUtil.converterValorParaDataHora(testeGenetico.dataImplantacao);
                            if ((typeof dataImplantacaoTesteGenetico !== 'undefined') && dataImplantacaoTesteGenetico &&
                                (ctrl.filtroRelatorioTesteGenetico.ano === dataImplantacaoTesteGenetico.getFullYear())) {
                                contadorAnoValido++;
                            }
                        }
                        if (contadorAnoValido === 0) {
                            testeGeneticoValido = false;
                        }
                    }
                    if (testeGeneticoValido && (typeof ctrl.filtroRelatorioTesteGenetico.conjunto !== 'undefined') && ctrl.filtroRelatorioTesteGenetico.conjunto) {
                        const refFiltroConjunto = TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade(ctrl.filtroRelatorioTesteGenetico.conjunto));
                        const refConjunto = TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade((typeof testeGenetico.conjunto !== 'undefined') && testeGenetico.conjunto ? testeGenetico.conjunto : null));
                        if (refFiltroConjunto !== refConjunto) {
                            testeGeneticoValido = false;
                        }
                    }
                    if (testeGeneticoValido && (typeof ctrl.filtroRelatorioTesteGenetico.origem !== 'undefined') && ctrl.filtroRelatorioTesteGenetico.origem) {
                        const refFiltroOrigem = TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade(ctrl.filtroRelatorioTesteGenetico.origem));
                        const refOrigem = TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade((typeof testeGenetico.pomarOrigem !== 'undefined') && testeGenetico.pomarOrigem ? testeGenetico.pomarOrigem : null));
                        if (refFiltroOrigem !== refOrigem) {
                            testeGeneticoValido = false;
                        }
                    }
                    if (testeGeneticoValido && (typeof ctrl.filtroRelatorioTesteGenetico.status !== 'undefined') && ctrl.filtroRelatorioTesteGenetico.status) {
                        const refFiltroStatus = TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade(ctrl.filtroRelatorioTesteGenetico.status));
                        const refStatus = TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade((typeof testeGenetico.statusTeste !== 'undefined') && testeGenetico.statusTeste ? testeGenetico.statusTeste : null));
                        if (refFiltroStatus !== refStatus) {
                            testeGeneticoValido = false;
                        }
                    }
                    if (testeGeneticoValido && (typeof ctrl.filtroRelatorioTesteGenetico.especie !== 'undefined') && ctrl.filtroRelatorioTesteGenetico.especie) {
                        const refFiltroEspecie = TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade(ctrl.filtroRelatorioTesteGenetico.especie));
                        const refEspecie = TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade((typeof testeGenetico.especie !== 'undefined') && testeGenetico.especie ? testeGenetico.especie : null));
                        if (refFiltroEspecie !== refEspecie) {
                            testeGeneticoValido = false;
                        }
                    }
                    if (testeGeneticoValido && (typeof ctrl.filtroRelatorioTesteGenetico.fazenda !== 'undefined') && ctrl.filtroRelatorioTesteGenetico.fazenda) {
                        const refFiltroFazenda = TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade(ctrl.filtroRelatorioTesteGenetico.fazenda));
                        const refFazenda = TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade((typeof testeGenetico.regiaoFazenda !== 'undefined') && testeGenetico.regiaoFazenda ? testeGenetico.regiaoFazenda : null));
                        if (refFiltroFazenda !== refFazenda) {
                            testeGeneticoValido = false;
                        }
                    }
                    if (testeGeneticoValido && (typeof ctrl.filtroRelatorioTesteGenetico.pomar !== 'undefined') && ctrl.filtroRelatorioTesteGenetico.pomar) {
                        const refFiltroPomar = TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade(ctrl.filtroRelatorioTesteGenetico.pomar));
                        const refPomar = TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade((typeof testeGenetico.pomarOrigem !== 'undefined') && testeGenetico.pomarOrigem ? testeGenetico.pomarOrigem : null));
                        if (refFiltroPomar !== refPomar) {
                            testeGeneticoValido = false;
                        }
                    }
                    if (testeGeneticoValido && (typeof ctrl.filtroRelatorioTesteGenetico.situacao !== 'undefined') && ctrl.filtroRelatorioTesteGenetico.situacao) {
                        const refFiltroSituacao = TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade(ctrl.filtroRelatorioTesteGenetico.situacao));
                        const refSituacao = TextoUtil_1.TextoUtil.converteTextoParaReferencia(TextoUtil_1.TextoUtil.descricaoEntidade((typeof testeGenetico.situacao !== 'undefined') && testeGenetico.situacao ? testeGenetico.situacao : null));
                        if (refFiltroSituacao !== refSituacao) {
                            testeGeneticoValido = false;
                        }
                    }
                    if (testeGeneticoValido) {
                        testeGenetico.listaTratamentos = new Array();
                        ctrl.dadosRelatorioTeste.listaFiltradaTestesGeneticos.push(testeGenetico);
                        if ((typeof testeGenetico.id !== 'undefined') && testeGenetico.id) {
                            angular.forEach(ctrl.dadosRelatorioTeste.listaTratamentosTestesGeneticos, (tratamentoTesteGenetico) => {
                                if ((typeof tratamentoTesteGenetico.testeGenetico !== 'undefined') && tratamentoTesteGenetico.testeGenetico &&
                                    (typeof tratamentoTesteGenetico.testeGenetico.id !== 'undefined') && tratamentoTesteGenetico.testeGenetico.id &&
                                    (tratamentoTesteGenetico.testeGenetico.id === testeGenetico.id)) {
                                    testeGenetico.listaTratamentos.push(tratamentoTesteGenetico);
                                    ctrl.dadosRelatorioTeste.listaFiltradaTratamentosTestesGeneticos.push(tratamentoTesteGenetico);
                                }
                            });
                        }
                    }
                });
                ctrl.dadosRelatorioTeste.areaTotal = 0;
                ctrl.dadosRelatorioTeste.numTotalTestes = 0;
                ctrl.dadosRelatorioTeste.numTotalTratamentos = 0;
                ctrl.dadosRelatorioTeste.numTratamentosPinus = 0;
                ctrl.dadosRelatorioTeste.numTratamentosEucalyptus = 0;
                ctrl.dadosRelatorioTeste.numTratamentosOutros = 0;
                angular.forEach(ctrl.dadosRelatorioTeste.listaFiltradaTestesGeneticos, (testeGenetico) => {
                    const especieDescricao = TextoUtil_1.TextoUtil.descricaoEntidade(testeGenetico.especie);
                    const especieReferencia = TextoUtil_1.TextoUtil.converteTextoParaReferencia(especieDescricao);
                    const areaTotal = (typeof testeGenetico.area !== 'undefined') ? NumeroUtil_1.NumeroUtil.converterValorParaNumero(testeGenetico.area) : 0;
                    ctrl.dadosRelatorioTeste.areaTotal += (areaTotal ? areaTotal : 0);
                    ctrl.dadosRelatorioTeste.numTotalTestes++;
                    angular.forEach(testeGenetico.listaTratamentos, (_tratamentoTesteGenetico) => {
                        ctrl.dadosRelatorioTeste.numTotalTratamentos++;
                        if (especieReferencia && (especieReferencia.indexOf('pinus') !== -1)) {
                            ctrl.dadosRelatorioTeste.numTratamentosPinus++;
                        }
                        else if (especieReferencia && (especieReferencia.indexOf('euc') !== -1)) {
                            ctrl.dadosRelatorioTeste.numTratamentosEucalyptus++;
                        }
                        else {
                            ctrl.dadosRelatorioTeste.numTratamentosOutros++;
                        }
                    });
                });
                yield TesteCampoRelatorioUtil.carregarGraficoRelatorioPie(ctrl, ['Pinus', 'Eucalyptus', 'Outros'], ['rgb(98, 163, 78)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)'], [ctrl.dadosRelatorioTeste.numTratamentosPinus, ctrl.dadosRelatorioTeste.numTratamentosEucalyptus, ctrl.dadosRelatorioTeste.numTratamentosOutros]);
                yield TesteCampoRelatorioUtil.carregarGraficoRelatorioBar(ctrl, ['Pinus', 'Eucalyptus', 'Outros'], [ctrl.dadosRelatorioTeste.numTratamentosPinus, ctrl.dadosRelatorioTeste.numTratamentosEucalyptus, ctrl.dadosRelatorioTeste.numTratamentosOutros]);
                ctrl.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
            }
        });
    }
    static carregarGraficoRelatorioPie(ctrl, labels, bgColor, data) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.timeoutAtualizacaoGraficoTestesPie !== 'undefined') && ctrl.timeoutAtualizacaoGraficoTestesPie) {
                angular.forEach(ctrl.timeoutAtualizacaoGraficoTestesPie, timeoutGrafico => {
                    clearTimeout(timeoutGrafico);
                });
                ctrl.timeoutAtualizacaoGraficoTestesPie = [];
            }
            if ((typeof ctrl.graficoTestesPie !== 'undefined') && ctrl.graficoTestesPie) {
                ctrl.graficoTestesPie.destroy();
                ctrl.graficoTestesPie = null;
            }
            for (let cargaGrafico = 1; cargaGrafico <= 10; cargaGrafico++) {
                ctrl.timeoutAtualizacaoGraficoTestesPie.push(setTimeout((ctrl, labels, bgColor, data) => {
                    if (ctrl.graficoTestesPie) {
                        return;
                    }
                    const graficoTestesPieEl = document.getElementById('graficoTestesPie');
                    if ((typeof graficoTestesPieEl === 'undefined') || !graficoTestesPieEl) {
                        return;
                    }
                    const graficoTestesPieCtx = graficoTestesPieEl.getContext('2d');
                    const graficoTestesPieDataSets = [{
                            label: 'Nº TESTES',
                            data: data,
                            backgroundColor: bgColor,
                            hoverOffset: 4
                        }];
                    ctrl.graficoTestesPie = new chart_js_1.Chart(graficoTestesPieCtx, {
                        type: 'pie',
                        data: {
                            labels: labels,
                            datasets: graficoTestesPieDataSets
                        },
                        options: {
                            responsive: true,
                            maintainAspectRatio: false
                        }
                    });
                }, cargaGrafico * 1000, ctrl, labels, bgColor, data));
            }
        });
    }
    static carregarGraficoRelatorioBar(ctrl, labels, data) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.timeoutAtualizacaoGraficoTestesBar !== 'undefined') && ctrl.timeoutAtualizacaoGraficoTestesBar) {
                angular.forEach(ctrl.timeoutAtualizacaoGraficoTestesBar, timeoutGrafico => {
                    clearTimeout(timeoutGrafico);
                });
                ctrl.timeoutAtualizacaoGraficoTestesBar = [];
            }
            if ((typeof ctrl.graficoTestesBar !== 'undefined') && ctrl.graficoTestesBar) {
                ctrl.graficoTestesBar.destroy();
                ctrl.graficoTestesBar = null;
            }
            for (let cargaGrafico = 1; cargaGrafico <= 10; cargaGrafico++) {
                ctrl.timeoutAtualizacaoGraficoTestesPie.push(setTimeout((ctrl, labels, data) => {
                    if (ctrl.graficoTestesBar) {
                        return;
                    }
                    const graficoTestesBarEl = document.getElementById('graficoTestesBar');
                    if ((typeof graficoTestesBarEl === 'undefined') || !graficoTestesBarEl) {
                        return;
                    }
                    const graficoTestesBarCtx = graficoTestesBarEl.getContext('2d');
                    const graficoTestesBarDataSets = [{
                            label: 'Nº Testes',
                            data: data,
                            backgroundColor: 'rgb(98, 163, 78)',
                            borderColor: 'rgb(98, 163, 78)',
                            borderWidth: 1,
                        }];
                    ctrl.graficoTestesBar = new chart_js_1.Chart(graficoTestesBarCtx, {
                        type: 'bar',
                        data: {
                            labels: labels,
                            datasets: graficoTestesBarDataSets
                        },
                        plugins: [globalThis.ChartDataLabels],
                        options: {
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                                gridLines: { offsetGridLines: true },
                                xAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    }]
                            }
                        }
                    });
                }, cargaGrafico * 1000, ctrl, labels, data));
            }
        });
    }
}
exports.TesteCampoRelatorioUtil = TesteCampoRelatorioUtil;
