"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TabelaUtil = void 0;
const angular = require("angular");
class TabelaUtil {
    static padronizaNomeCampoObj(nomeCampo) {
        if ((typeof nomeCampo !== 'undefined') && (nomeCampo !== null)) {
            let campoPadronizado = String(nomeCampo).trim();
            if (campoPadronizado.indexOf('.') !== -1) {
                campoPadronizado = campoPadronizado.split('.').map((str, idx) => {
                    if (str && (str.length > 0) && (idx > 0)) {
                        return str.charAt(0).toUpperCase() + str.slice(1);
                    }
                    return str;
                }).join();
            }
            return campoPadronizado;
        }
        return null;
    }
    static agrupaDados(lista, campos) {
        let arr = {};
        const temLista = (typeof lista !== 'undefined') && (lista !== null) && (lista.length > 0);
        const temCampos = (typeof campos !== 'undefined') && (campos !== null) && (campos.length > 0);
        if (temCampos) {
            angular.forEach(campos, (campo) => {
                if ((typeof campo !== 'undefined') && (campo !== null)) {
                    const campoPadronizado = TabelaUtil.padronizaNomeCampoObj(campo);
                    if ((typeof arr[campoPadronizado] === 'undefined') || (arr[campoPadronizado] === null)) {
                        arr[campoPadronizado] = [];
                    }
                }
            });
        }
        if (temLista && temCampos) {
            angular.forEach(lista, (registro) => {
                angular.forEach(campos, (campo) => {
                    if ((typeof campo !== 'undefined') && (campo !== null)) {
                        const campoPadronizado = TabelaUtil.padronizaNomeCampoObj(campo);
                        const partesNomeCampo = String(campo).trim().split('.');
                        let valorCampo = registro;
                        angular.forEach(partesNomeCampo, (parteNomeCampo) => {
                            if ((typeof valorCampo !== 'undefined') && (valorCampo !== null) &&
                                (typeof valorCampo[parteNomeCampo] !== 'undefined') && (valorCampo[parteNomeCampo] !== null)) {
                                valorCampo = valorCampo[parteNomeCampo];
                            }
                            else {
                                valorCampo = null;
                            }
                        });
                        if (typeof valorCampo !== 'undefined') {
                            if (valorCampo !== null) {
                                if (!arr[campoPadronizado].some(o => o.value === valorCampo)) {
                                    arr[campoPadronizado].push({ value: valorCampo, label: valorCampo });
                                }
                            }
                            else {
                                if (!arr[campoPadronizado].some(o => (typeof o.value === 'undefined') || (o.value === null) || (o.value === 'null'))) {
                                    arr[campoPadronizado].push({ value: null, label: '(Em branco)' });
                                }
                            }
                        }
                    }
                });
            });
            angular.forEach(campos, (campo) => {
                if ((typeof campo !== 'undefined') && (campo !== null)) {
                    const campoPadronizado = TabelaUtil.padronizaNomeCampoObj(campo);
                    if ((typeof arr[campoPadronizado] !== 'undefined') && arr[campoPadronizado]) {
                        arr[campoPadronizado].sort((a, b) => {
                            let labelA = ((typeof a.label !== 'undefined') && (a.label !== null)) ? a.label : '';
                            let labelB = ((typeof b.label !== 'undefined') && (b.label !== null)) ? b.label : '';
                            if (labelA < labelB) {
                                return -1;
                            }
                            else if (labelA > labelB) {
                                return 1;
                            }
                            else {
                                return 0;
                            }
                        });
                    }
                }
            });
        }
        if (temCampos) {
            angular.forEach(campos, (campo) => {
                if ((typeof campo !== 'undefined') && (campo !== null)) {
                    const campoPadronizado = TabelaUtil.padronizaNomeCampoObj(campo);
                    if ((typeof arr[campoPadronizado] === 'undefined') || (arr[campoPadronizado] === null) || (arr[campoPadronizado].length === 0)) {
                        arr[campoPadronizado] = [{ value: '-', label: '-' }];
                    }
                }
            });
        }
        return arr;
    }
    static agrupaDadosColunas(lista, colunas) {
        if ((typeof lista !== 'undefined') && lista && (typeof colunas !== 'undefined') && colunas) {
            let campos = new Array();
            angular.forEach(colunas, (coluna) => {
                if ((typeof coluna.field !== 'undefined') && coluna.field) {
                    if (!campos.includes(coluna.field)) {
                        campos.push(coluna.field);
                    }
                }
                else if ((typeof coluna.name !== 'undefined') && coluna.name) {
                    if (!campos.includes(coluna.name)) {
                        campos.push(coluna.name);
                    }
                }
            });
            if (campos && (campos.length > 0)) {
                return TabelaUtil.agrupaDados(lista, campos);
            }
        }
        return {};
    }
    static exportarParaExcel(id, nomeArquivo = null) {
        if ((typeof globalThis.TableToExcel === 'undefined') || !globalThis.TableToExcel) {
            throw new Error('Plugin "linways/table-to-excel" não carregado.');
        }
        globalThis.TableToExcel.convert(document.getElementById(id), {
            name: (nomeArquivo ? nomeArquivo : 'tabela1') + '.xlsx',
            sheet: {
                name: 'Planilha 1'
            }
        });
    }
}
exports.TabelaUtil = TabelaUtil;
