"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Usuario = exports.PerfilUsuarioValor = void 0;
const Base_1 = require("./Base");
var PerfilUsuarioValor;
(function (PerfilUsuarioValor) {
    PerfilUsuarioValor[PerfilUsuarioValor["MASTER"] = 1] = "MASTER";
    PerfilUsuarioValor[PerfilUsuarioValor["ADMINISTRADOR"] = 2] = "ADMINISTRADOR";
    PerfilUsuarioValor[PerfilUsuarioValor["SUPERVISAO"] = 3] = "SUPERVISAO";
    PerfilUsuarioValor[PerfilUsuarioValor["OPERACIONAL"] = 4] = "OPERACIONAL";
})(PerfilUsuarioValor = exports.PerfilUsuarioValor || (exports.PerfilUsuarioValor = {}));
class Usuario extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.id = null;
        this.codigo = null;
        this.nome = null;
        this.nomeCompleto = null;
        this.email = null;
        this.senha = null;
        this.confirmaSenha = null;
        this.telefone = null;
        this.ativo = null;
        this.perfil = null;
        this.nivelUsuario = null;
        this.nivelUsuarioRef = null;
        this.recuperar = null;
        this.permissaoCadastroMaterialGenetico = null;
        this.permissaoCadastroGenero = null;
        this.permissaoCadastroEspecie = null;
        this.permissaoCadastroGeracao = null;
        this.permissaoCadastroConjunto = null;
        this.permissaoCadastroDialelo = null;
        this.permissaoCadastroPomar = null;
        this.permissaoCadastroTipoPomar = null;
        this.permissaoCadastroClassificacaoSolo = null;
        this.permissaoCadastroUnidadeManejo = null;
        this.permissaoCadastroRegiao = null;
        this.permissaoCadastroTalhao = null;
        this.permissaoCadastroFonte = null;
        this.permissaoCadastroTipoManejo = null;
        this.permissaoCadastroTipoDesbasteManejoPomar = null;
        this.permissaoCadastroTipoLimpezaManejoPomar = null;
        this.permissaoCadastroTipoAmostraManejoPomar = null;
        this.permissaoCadastroFinalidadeAmostraManejoPomar = null;
        this.permissaoCadastroPragaDoencaManejoPomar = null;
        this.permissaoCadastroFuncionario = null;
        this.permissaoCadastroPais = null;
        this.permissaoCadastroProvinciaUf = null;
        this.permissaoCadastroLocalidade = null;
        this.permissaoCadastroHibridacao = null;
        this.permissaoCadastroPolinizacao = null;
        this.permissaoCadastroTipoTestePopulacao = null;
        this.permissaoCadastroSituacao = null;
        this.permissaoCadastroCloneMaterialGenetico = null;
        this.permissaoCadastroTipoImplantacao = null;
        this.permissaoCadastroProcedencia = null;
        this.permissaoCadastroReproducao = null;
        this.permissaoCadastroTipoPomarPopulacao = null;
        this.permissaoCadastroTipoDelineamento = null;
        this.permissaoCadastroDisposicaoIndividuos = null;
        this.permissaoCadastroOperacaoManejoPomar = null;
        this.permissaoCadastroProdutoFertilizante = null;
        this.permissaoCadastroTipoAplicacao = null;
        this.permissaoCadastroTipoIntervencao = null;
        this.permissaoCadastroTesteGenetico = null;
        this.permissaoCadastroAnoEnxertia = null;
        this.permissaoCadastroEstrobilo = null;
        this.permissaoCadastroEstagioFloralFeminino = null;
        this.permissaoCadastroEstagioFloralMasculino = null;
        this.permissaoCadastroFaseAvaliada = null;
        this.permissaoCadastroEstagioFloralEucalipto = null;
        this.permissaoCadastroMaturacaoFruto = null;
        this.permissaoCadastroCultivar = null;
        this.permissaoCadastroCategoriaPomar = null;
        this.permissaoCadastroFinalidadeSemeio = null;
        this.permissaoCadastroUnidadeMedida = null;
        this.permissaoCadastroClassificacaoBrotacao = null;
        this.permissaoCadastroProdutoDefensivo = null;
        this.permissaoCadastroColetaMacroestaca = null;
        this.permissaoCadastroTratamentoFungicida = null;
        this.permissaoCadastroStatusMinijardim = null;
        this.permissaoCadastroFinalidadeMultiplicacao = null;
        this.permissaoCadastroAcaoMinijardim = null;
        this.permissaoCadastroCanaletoes = null;
        this.permissaoCadastroMinijardim = null;
        this.permissaoCadastroTipoMinijardim = null;
        this.permissaoCadastroCaixaDAgua = null;
        this.permissaoCadastroReceitaSolucao = null;
        this.permissaoCadastroNivelContaminacao = null;
        this.permissaoCadastroCasaVegetacao = null;
        this.permissaoCadastroCargoFuncao = null;
        this.permissaoCadastroLaboratorio = null;
        this.permissaoCadastroAreaSolicitante = null;
        this.permissaoCadastroTipoAmostra = null;
        this.permissaoCadastroTipoAnalise = null;
        this.permissaoCadastroStatusAnalise = null;
        this.permissaoCadastroSafra = null;
        this.permissaoCadastroLote = null;
        this.permissaoCadastroPropagulo = null;
        this.permissaoCadastroCaracteristicaDescritorMorfologico = null;
        this.permissaoCadastroCentroCustoSap = null;
        this.permissaoCadastroContaDepositoSap = null;
        this.permissaoCadastroUsuario = null;
        this.permissaoPomarBancoConservacao = null;
        this.permissaoProducaoMudaSeminal = null;
        this.permissaoProducaoMudaClonal = null;
        this.permissaoProducaoMudaClonalEucalyptus = null;
        this.permissaoProducaoMudaClonalEucalyptusResgateMacroestacas = null;
        this.permissaoProducaoMudaClonalEucalyptusMinijardim = null;
        this.permissaoProducaoMudaClonalEucalyptusMiniestaquia = null;
        this.permissaoProducaoMudaClonalPinusMinijardim = null;
        this.permissaoProducaoMudaClonalPinusMiniestaquia = null;
        this.permissaoProducaoMudaControlePragaDoenca = null;
        this.permissaoProducaoMudaExperimento = null;
        this.permissaoTesteCampo = null;
        this.permissaoProtecaoRegistroCultivar = null;
        this.permissaoLaboratorioSolicitacaoAnalises = null;
        this.permissaoLaboratorioMaturacaoCones = null;
        this.permissaoLaboratorioUmidadeSementes = null;
        this.permissaoLaboratorioGerminacaoSementes = null;
        this.permissaoLaboratorioUmidadePolen = null;
        this.permissaoLaboratorioGerminacaoPolen = null;
        this.permissaoLaboratorioEstudoCones = null;
        this.permissaoLaboratorioExperimentos = null;
        this.permissaoLaboratorioManuaisProcedimentos = null;
        this.permissaoConfiguracaoGeral = null;
        this.permissaoDashboard = null;
    }
}
exports.Usuario = Usuario;
