"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Planilha = void 0;
const Base_1 = require("./Base");
const uuid_1 = require("uuid");
class Planilha extends Base_1.Base {
    constructor() {
        super();
        this.guid = null;
        this.modulo = null;
        this.operacao = null;
        this.nome = null;
        this.usuarioInclusao = null;
        this.dataHoraInclusao = null;
        this.usuarioAlteracao = null;
        this.dataHoraAlteracao = null;
        this.guid = uuid_1.v4();
        this.status = true;
    }
}
exports.Planilha = Planilha;
