"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Coordenada = void 0;
class Coordenada {
    constructor() {
        this.latitude = null;
        this.longitude = null;
    }
}
exports.Coordenada = Coordenada;
