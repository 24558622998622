"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LinhaColunaPomar = void 0;
class LinhaColunaPomar {
    constructor() {
        this.linha = null;
        this.coluna = null;
    }
}
exports.LinhaColunaPomar = LinhaColunaPomar;
