"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BotoesFormularioController = void 0;
const RotaUtil_1 = require("../../../../util/RotaUtil");
const ICRUDOperacao_1 = require("../../interfaces/ICRUDOperacao");
const NotificacaoFactory_1 = require("../../../../fabricas/NotificacaoFactory");
const DispositivoUtil_1 = require("../../../../../util/DispositivoUtil");
const sweetalert2_1 = require("sweetalert2");
class BotoesFormularioController {
    constructor($scope, $state, notificacao) {
        this.$scope = $scope;
        this.$state = $state;
        this.notificacao = notificacao;
        this.formulario = null;
        this.modelo = null;
        this.botaoExcluir = false;
        this.podeExcluir = false;
        this.travarBotaoSalvar = false;
        this.tipoOperacao = null;
        this.operacao = null;
        this.timeoutPermissoes = null;
        this.timeoutScopeApply = null;
        this.$scope = $scope;
        this.$state = $state;
        this.tipoOperacao = ICRUDOperacao_1.CRUDOperacao;
        this.operacao = RotaUtil_1.RotaUtil.detectarOperacao(this.$state);
        this.carregarPermissoes();
    }
    $onInit() {
        this.carregarPermissoes();
    }
    $onDestroy() {
        if ((typeof this.timeoutPermissoes !== 'undefined') && (this.timeoutPermissoes !== null)) {
            clearTimeout(this.timeoutPermissoes);
            this.timeoutPermissoes = null;
        }
        if ((typeof this.timeoutScopeApply !== 'undefined') && (this.timeoutScopeApply !== null)) {
            clearTimeout(this.timeoutScopeApply);
            this.timeoutScopeApply = null;
        }
    }
    salvar(id, ehValido) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ehValido) {
                this.notificacao.alerta('Formulário incompleto', 'Resolva as pendências do formulário');
                return;
            }
            yield this.$scope.$parent.$ctrl.salvar(id, ehValido);
        });
    }
    cancelar() {
        this.$scope.$parent.$ctrl.resetar();
    }
    excluir() {
        let modelo = this.$scope.$parent.$ctrl.modelo;
        let idModelo = ((typeof modelo.id !== 'undefined') && modelo.id ? modelo.id : modelo.uuid);
        if (DispositivoUtil_1.DispositivoUtil.ehCelular()) {
            if (confirm(`Deseja realmente excluir este registro ?`)) {
                this.$scope.$parent.$ctrl.remover(idModelo);
            }
        }
        else {
            sweetalert2_1.default.fire({
                title: 'Confirmação',
                text: 'Deseja realmente excluir este registro ?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não'
            }).then((result) => {
                if (result.value) {
                    this.$scope.$parent.$ctrl.remover(idModelo);
                }
            });
        }
    }
    carregarPermissoes() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.timeoutPermissoes !== 'undefined') && (this.timeoutPermissoes !== null)) {
                clearTimeout(this.timeoutPermissoes);
                this.timeoutPermissoes = null;
            }
            this.timeoutPermissoes = setTimeout((ctrl) => {
                if (!ctrl) {
                    return;
                }
                ctrl.timeoutPermissoes = null;
                const podeExcluir = ((typeof globalThis.permissoes !== 'undefined') && globalThis.permissoes &&
                    (typeof globalThis.permissoes.excluir !== 'undefined') && globalThis.permissoes.excluir ? true : false);
                if (podeExcluir !== ctrl.podeExcluir) {
                    ctrl.podeExcluir = podeExcluir;
                    ctrl.scopeApply();
                }
                this.timeoutPermissoes = setTimeout((ctrl) => {
                    ctrl.carregarPermissoes();
                }, 1000, ctrl);
            }, 50, this);
        });
    }
    scopeApply() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.timeoutScopeApply !== 'undefined') && (this.timeoutScopeApply !== null)) {
                clearTimeout(this.timeoutScopeApply);
                this.timeoutScopeApply = null;
            }
            this.timeoutScopeApply = setTimeout((ctrl) => {
                if (!ctrl) {
                    return;
                }
                ctrl.timeoutScopeApply = null;
                try {
                    ctrl.$scope.$apply();
                }
                catch (ex) {
                    ctrl.$scope.$applyAsync();
                    console.log(ex);
                }
            }, 250, this);
        });
    }
}
exports.BotoesFormularioController = BotoesFormularioController;
BotoesFormularioController.$inject = ['$scope', '$state', NotificacaoFactory_1.NotificacaoFactory.id];
