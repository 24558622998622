"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProducaoMudaClonalMinijardimManejo = void 0;
const Base_1 = require("./Base");
class ProducaoMudaClonalMinijardimManejo extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.tipoControleManejo = null;
        this.minijardimEucalyptus = null;
        this.minijardimPinus = null;
        this.data = null;
        this.casaVegetacao = null;
        this.genero = null;
        this.especie = null;
        this.tipoMinijardim = null;
        this.caixaDAgua = null;
        this.receitaSolucao = null;
        this.funcionario = null;
        this.listaDetalhamentoEC = [];
        this.numCanaletao = null;
        this.observacao = null;
    }
}
exports.ProducaoMudaClonalMinijardimManejo = ProducaoMudaClonalMinijardimManejo;
ProducaoMudaClonalMinijardimManejo.CONTROLE_MANEJO_FERTILIZACAO = 'Fertilização';
ProducaoMudaClonalMinijardimManejo.CONTROLE_MANEJO_CONTROLE_EC = 'Controle de EC';
ProducaoMudaClonalMinijardimManejo.CONTROLE_MANEJO_LAVAGEM = 'Lavagem';
ProducaoMudaClonalMinijardimManejo.CONTROLE_MANEJO_PODAS = 'Podas';
