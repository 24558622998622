"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProducaoMudasMetaProducao = void 0;
const Base_1 = require("./Base");
class ProducaoMudasMetaProducao extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.testeGenetico = null;
        this.dataGerminacao = null;
        this.germinacao = null;
        this.dataSobrevivenciaVegetacao = null;
        this.sobrevivenciaVegetacao = null;
        this.dataSobrevivenciaAclimaticacao = null;
        this.sobrevivenciaAclimaticacao = null;
    }
}
exports.ProducaoMudasMetaProducao = ProducaoMudasMetaProducao;
