"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DebugUtil = void 0;
const config_app_1 = require("../config/config.app");
const DataUtil_1 = require("./DataUtil");
const NumeroUtil_1 = require("./NumeroUtil");
class DebugUtil {
    static tracar() {
        if ((config_app_1.config.ambiente === config_app_1.Ambiente.DESENVOLVIMENTO_LOCAL) || (config_app_1.config.ambiente === config_app_1.Ambiente.DESENVOLVIMENTO_WEB)) {
            console.trace();
        }
    }
    static sleep(delay = 1000) {
        let delay2 = ((typeof delay !== 'undefined') && delay ? NumeroUtil_1.NumeroUtil.converterValorParaNumero(delay) : 0);
        if (delay2 && (delay2 > 10000)) {
            delay2 = 10000;
        }
        if (delay2 && (delay2 > 0)) {
            let horarioInicial = DataUtil_1.DataUtil.dataHoraAtual().getTime();
            while (DataUtil_1.DataUtil.dataHoraAtual().getTime() < horarioInicial + delay2) {
            }
        }
    }
}
exports.DebugUtil = DebugUtil;
