"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DadosRelatorioTeste = void 0;
class DadosRelatorioTeste {
    constructor() {
        this.listaTestesGeneticos = [];
        this.listaFiltradaTestesGeneticos = [];
        this.listaTratamentosTestesGeneticos = [];
        this.listaFiltradaTratamentosTestesGeneticos = [];
        this.numTotalTestes = 0;
        this.numTotalTratamentos = 0;
        this.areaTotal = 0;
        this.testesBanco = 0;
        this.testesPomar = 0;
        this.numTratamentosPinus = 0;
        this.numTratamentosEucalyptus = 0;
        this.numTratamentosOutros = 0;
        this.dadosGraficoPie = { labels: [], bgColor: [], data: [] };
        this.dadosGraficoBar = { labels: [], data: [] };
    }
}
exports.DadosRelatorioTeste = DadosRelatorioTeste;
