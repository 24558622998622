"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProducaoMudaClonalEucalyptusMinijardim = void 0;
const Base_1 = require("./Base");
class ProducaoMudaClonalEucalyptusMinijardim extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.clone = null;
        this.producaoClone = null;
        this.especie = null;
        this.anoResgate = null;
        this.statusMinijardim = null;
        this.finalidade = null;
        this.acao = null;
        this.numCanaletao = null;
        this.numCepas = null;
        this.numMiniestacasCepa = null;
        this.enraizamento = null;
        this.numMudas = null;
    }
}
exports.ProducaoMudaClonalEucalyptusMinijardim = ProducaoMudaClonalEucalyptusMinijardim;
