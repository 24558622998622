"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JWT = void 0;
class JWT {
    constructor() {
        this.token = null;
        this.painel = null;
        this.login = null;
        this.pass = null;
    }
}
exports.JWT = JWT;
