"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OperacaoPolinizacaoPomar = void 0;
const Base_1 = require("./Base");
const uuid_1 = require("uuid");
class OperacaoPolinizacaoPomar extends Base_1.Base {
    constructor() {
        super();
        this.id = null;
        this.guid = null;
        this.numFlores = null;
        this.dataObservacao = null;
        this.estagioObservacao = null;
        this.dataInicialPolinizacao = null;
        this.dataFinalPolinizacao = null;
        this.dataRetiradaSaco = null;
        this.polem = null;
        this.numCones = null;
        this.guid = uuid_1.v4();
        this.status = true;
    }
}
exports.OperacaoPolinizacaoPomar = OperacaoPolinizacaoPomar;
