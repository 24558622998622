"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProducaoMudasSemeioTestes = void 0;
const Base_1 = require("./Base");
class ProducaoMudasSemeioTestes extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.dataSemeio = null;
        this.finalidadeSemeio = null;
        this.especie = null;
        this.testeGenetico = null;
        this.materiaisGeneticosSemeio = [];
    }
}
exports.ProducaoMudasSemeioTestes = ProducaoMudasSemeioTestes;
