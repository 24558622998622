"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TabelaComponente = void 0;
const TabelaController_1 = require("./TabelaController");
class TabelaComponente {
    constructor() {
        this.controller = TabelaController_1.TabelaController;
        this.templateUrl = require('./tabela.componente.html').default;
        this.transclude = true;
        this.bindings = {
            gridId: '@',
            gridSelection: '<',
            gridMultiSelect: '<',
            gridAutoFitColumns: '=',
            gridEdit: '=',
            gridMinHeight: '@',
            gridRowHeight: '<',
            gridRowSelectionChanged: '=',
            gridRowSelectionChangedBatch: '=',
            opcoes: '='
        };
    }
}
exports.TabelaComponente = TabelaComponente;
TabelaComponente.id = 'tabela';
