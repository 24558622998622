"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PilhaCroqui = void 0;
const NumeroUtil_1 = require("../util/NumeroUtil");
const Base_1 = require("./Base");
class PilhaCroqui extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.id = null;
        this.linha1 = null;
        this.linha2 = null;
        this.descricao = null;
    }
    static ENTRE_LINHAS(linha1, linha2) {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        const linha1Int = ((typeof linha1 !== 'undefined') && (linha1 !== null)) ? NumeroUtil_1.NumeroUtil.arredondarNumero(linha1) : 0;
        const linha2Int = ((typeof linha2 !== 'undefined') && (linha2 !== null)) ? NumeroUtil_1.NumeroUtil.arredondarNumero(linha2) : 0;
        const nomeCampo = 'pilhaCroquiEntreLinhas_' + linha1Int + '_' + linha2Int;
        if ((typeof globalThis.globalConstants[nomeCampo] === 'undefined') ||
            (globalThis.globalConstants[nomeCampo] === null)) {
            let pilhaCroqui = new PilhaCroqui();
            pilhaCroqui.id = 'ENTRE_LINHAS_' + linha1Int + '_' + linha2Int;
            pilhaCroqui.linha1 = linha1Int,
                pilhaCroqui.linha2 = linha2Int,
                pilhaCroqui.descricao = 'Entre as linhas ' + linha1Int + ' e ' + linha2Int;
            globalThis.globalConstants[nomeCampo] = pilhaCroqui;
        }
        return globalThis.globalConstants[nomeCampo];
    }
    static LISTA(numLinhas = 100) {
        let lista = new Array();
        const numLinhasInt = ((typeof numLinhas !== 'undefined') && (numLinhas !== null)) ? NumeroUtil_1.NumeroUtil.arredondarNumero(numLinhas) : 0;
        for (let linha = 0; linha < numLinhasInt; linha++) {
            lista.push(PilhaCroqui.ENTRE_LINHAS(linha, linha + 1));
        }
        return lista;
    }
}
exports.PilhaCroqui = PilhaCroqui;
