"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiltroControleManejoTesteGeneticoTratado = exports.FiltroControleManejoTesteGenetico = void 0;
class FiltroControleManejoTesteGenetico {
    constructor() {
        this.visualizacaoResumida = false;
        this.visualizacaoDetalhada = true;
        this.dataIni = null;
        this.dataFim = null;
        this.operacaoLimpeza = true;
        this.operacaoDesbaste = true;
        this.operacaoPolinizacao = true;
        this.operacaoColetaAmostras = true;
        this.operacaoColetaSementes = true;
        this.operacaoControleDoencas = true;
        this.operacaoAdubacaoFertilizacao = true;
        this.operacaoPodaRamosPortaEnxerto = true;
        this.operacaoAvaliacaoSobrevivencia = true;
        this.operacaoReplantio = true;
        this.outrasOperacoes = true;
    }
}
exports.FiltroControleManejoTesteGenetico = FiltroControleManejoTesteGenetico;
class FiltroControleManejoTesteGeneticoTratado {
    constructor() {
        this.testeGenetico = null;
    }
}
exports.FiltroControleManejoTesteGeneticoTratado = FiltroControleManejoTesteGeneticoTratado;
