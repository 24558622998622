"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnaliseSelegenAnaliseSelecaoTesteGenetico = void 0;
const Base_1 = require("./Base");
class AnaliseSelegenAnaliseSelecaoTesteGenetico extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.id = null;
        this.codigo = null;
        this.testeGenetico = null;
        this.tipoAnaliseSelegen = null;
        this.descricao = null;
        this.anoAnalise = null;
        this.dadosPlanilha = null;
        this.dadosModelo1 = null;
        this.dadosModelo2 = null;
        this.dadosModelo4 = null;
        this.dadosModelo24 = null;
        this.dadosModelo33 = null;
        this.dadosModelo51 = null;
        this.dadosModelo105 = null;
        this.dadosModelo106 = null;
        this.dadosModelo441 = null;
        this.dadosModelo442 = null;
        this.dadosModelo37 = null;
        this.planilhas = [];
        this.planilhaSelecionada = null;
        this.anexos = [];
        this.observacoes = null;
        this.usuarioInclusao = null;
        this.dataHoraInclusao = null;
        this.usuarioAlteracao = null;
        this.dataHoraAlteracao = null;
        this.importar = false;
        this.ultimoIndividuoPlanilha = null;
        this.ultimoIndividuoModelo1 = null;
        this.ultimoIndividuoModelo2 = null;
        this.ultimoIndividuoModelo4 = null;
        this.ultimoIndividuoModelo24 = null;
        this.ultimoIndividuoModelo33 = null;
        this.ultimoIndividuoModelo51 = null;
        this.ultimoIndividuoModelo105 = null;
        this.ultimoIndividuoModelo106 = null;
        this.ultimoIndividuoModelo441 = null;
        this.ultimoIndividuoModelo442 = null;
        this.ultimoIndividuoModelo37 = null;
    }
}
exports.AnaliseSelegenAnaliseSelecaoTesteGenetico = AnaliseSelegenAnaliseSelecaoTesteGenetico;
