"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnaliseInternaGerminacao = void 0;
const Base_1 = require("./Base");
class AnaliseInternaGerminacao extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.germinacaoSemente = null;
        this.dataGerminacao = null;
        this.dormencia = null;
        this.tipoAvaliacao = null;
        this.amostra1NumTot = null;
        this.amostra1NumGerminada = null;
        this.amostra2NumTot = null;
        this.amostra2NumGerminada = null;
        this.amostra3NumTot = null;
        this.amostra3NumGerminada = null;
        this.amostra4NumTot = null;
        this.amostra4NumGerminada = null;
        this.germinacaoFinal = null;
        this.germinacaoSementeId = null;
        this.generoId = null;
        this.generoDescricao = null;
        this.especieId = null;
        this.especieDescricao = null;
        this.pomarId = null;
        this.pomarDescricao = null;
        this.safraId = null;
        this.safraDescricao = null;
        this.loteId = null;
        this.loteDescricao = null;
        this.materialGeneticoId = null;
        this.materialGeneticoDescricao = null;
    }
}
exports.AnaliseInternaGerminacao = AnaliseInternaGerminacao;
AnaliseInternaGerminacao.DORMENCIA_SEM = 'Sem quebra';
AnaliseInternaGerminacao.DORMENCIA_COM = 'Com quebra';
AnaliseInternaGerminacao.TIPO_AVALIACAO_7 = '7 dias';
AnaliseInternaGerminacao.TIPO_AVALIACAO_14 = '14 dias';
AnaliseInternaGerminacao.TIPO_AVALIACAO_21 = '21 dias';
AnaliseInternaGerminacao.TIPO_AVALIACAO_28 = '28 dias';
