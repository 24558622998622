"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ControleManejoPomar = void 0;
const Base_1 = require("./Base");
class ControleManejoPomar extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.id = null;
        this.codigo = null;
        this.data = null;
        this.pomar = null;
        this.pomarCodigo = null;
        this.pomarDescricao = null;
        this.regiao = null;
        this.talhao = null;
        this.operacao = null;
        this.polem = null;
        this.materiaisGeneticos = [];
        this.ehTodosMateriaisGeneticos = null;
        this.limpezas = [];
        this.desbastes = [];
        this.polinizacoes = [];
        this.coletasAmostras = [];
        this.coletasSementes = [];
        this.adubacoesFertilizacoes = [];
        this.doencas = [];
        this.avaliacoesSobrevivencia = [];
        this.replantios = [];
        this.outrasOperacoes = [];
        this.ehOperacional = null;
        this.ehPesquisa = null;
        this.localizacaoFila = null;
        this.localizacaoColuna = null;
        this.pomarCloneFeminino = null;
        this.pomarCloneFemininoSgifId = null;
        this.pomarCloneFemininoDescricao = null;
        this.cloneFeminino = null;
        this.cloneFemininoDescricao = null;
        this.especie = null;
        this.pomaresClonesMasculinos = [];
        this.clonesMasculinos = [];
        this.numDialelos = null;
        this.dataIsolamento = null;
        this.dataColeta = null;
        this.numClones = null;
        this.numRametes = null;
        this.quantidadeArvores = null;
        this.quantidadeAmostras = null;
        this.quantidade = null;
        this.numCones = null;
        this.numFlores = null;
        this.prazo = null;
        this.situacao = null;
        this.dataRealizado = null;
        this.conferencia = null;
        this.anexos = [];
        this.observacoes = null;
        this.descricaoResumida = null;
        this.descricaoDetalhada = null;
        this.usuarioInclusao = null;
        this.dataHoraInclusao = null;
        this.usuarioAlteracao = null;
        this.dataHoraAlteracao = null;
    }
}
exports.ControleManejoPomar = ControleManejoPomar;
