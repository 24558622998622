"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TratamentoTesteGenetico = void 0;
const Base_1 = require("./Base");
class TratamentoTesteGenetico extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.id = null;
        this.codigo = null;
        this.mes = null;
        this.ano = null;
        this.testeGenetico = null;
        this.materialGenetico = null;
        this.regiao = null;
        this.talhao = null;
        this.tipoTeste = null;
        this.tratamentos = [];
        this.anexos = [];
        this.observacoes = null;
        this.descricaoResumida = null;
        this.descricaoDetalhada = null;
        this.usuarioInclusao = null;
        this.dataHoraInclusao = null;
        this.usuarioAlteracao = null;
        this.dataHoraAlteracao = null;
        this.mesDescricao = null;
        this.mesSigla = null;
        this.tipoTesteDescricao = null;
    }
}
exports.TratamentoTesteGenetico = TratamentoTesteGenetico;
