"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProducaoMudaClonalEucalyptus = void 0;
const Base_1 = require("./Base");
class ProducaoMudaClonalEucalyptus extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.clone = null;
        this.genero = null;
        this.especie = null;
        this.pomar = null;
        this.anoResgate = null;
        this.procedencia = null;
        this.regiaoTeste = null;
        this.anoTeste = null;
        this.codTeste = null;
        this.minijardim = null;
        this.finalidade = null;
        this.acaoMinijardim = null;
        this.statusMinijardim = null;
        this.numArvores = null;
        this.qntCanaletoes = null;
        this.numCepas = null;
        this.numMiniestacaCepa = null;
        this.perEnraizamento = null;
        this.observacao = null;
        this.regiaoBCG1 = null;
        this.anoBGC1 = null;
        this.regiaoBCG2 = null;
        this.anoBGC2 = null;
        this.regiaoTestesClonais1 = null;
        this.anoTestesClonais1 = null;
        this.regiaoTestesClonais2 = null;
        this.anoTestesClonais2 = null;
        this.regiaoTestesClonais3 = null;
        this.anoTestesClonais3 = null;
    }
}
exports.ProducaoMudaClonalEucalyptus = ProducaoMudaClonalEucalyptus;
