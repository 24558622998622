"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TesteGeneticoController = void 0;
const angular = require("angular");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const UiGridConstrutor_1 = require("../../../app/construtores/UiGridConstrutor");
const AbstratoController_1 = require("../../../app/AbstratoController");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const ICRUDOperacao_1 = require("../../../app/interfaces/ICRUDOperacao");
const TesteGenetico_1 = require("../../../../../modelos/TesteGenetico");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const config_app_1 = require("../../../../../config/config.app");
const TextoUtil_1 = require("../../../../../util/TextoUtil");
const RevTabelaUtil_1 = require("../../../../../util/RevTabelaUtil");
const DataUtil_1 = require("../../../../../util/DataUtil");
const NumeroUtil_1 = require("../../../../../util/NumeroUtil");
const sweetalert2_1 = require("sweetalert2");
class TesteGeneticoController extends AbstratoController_1.AbstratoController {
    constructor($scope, $injector) {
        super($scope, $injector, new TesteGenetico_1.TesteGenetico());
        this.path = '';
        this.caminho = '';
        this.tipoOperacao = null;
        this.generos = [];
        this.especies = [];
        this.geracoes = [];
        this.paises = [];
        this.fontes = [];
        this.localidades = [];
        this.provinciasUf = [];
        this.pomares = [];
        this.tiposTestesPopulacoes = [];
        this.situacoes = [];
        this.regioes = [];
        this.talhoes = [];
        this.talhoesFiltrados = [];
        this.talhoesCarregando = false;
        this.unidadesManejo = [];
        this.timeoutFiltroSgifTalhoes = null;
        this.tipoOperacao = ICRUDOperacao_1.CRUDOperacao;
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.path = config_app_1.config.api.caminho;
            this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.TESTE_GENETICO;
            if ((this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) || (this.operacao === ICRUDOperacao_1.CRUDOperacao.EDITAR) || (this.operacao === ICRUDOperacao_1.CRUDOperacao.DUPLICAR)) {
                this.generos = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.GENERO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.GENERO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.generos = resposta;
                        ctrl.scopeApply();
                    }
                });
                this.especies = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESPECIE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESPECIE)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.especies = resposta;
                        ctrl.scopeApply();
                    }
                });
                this.geracoes = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.GERACAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.GERACAO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.geracoes = resposta;
                        ctrl.scopeApply();
                    }
                });
                this.paises = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PAIS)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PAIS)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.paises = resposta;
                        ctrl.scopeApply();
                    }
                });
                this.fontes = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.FONTE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.FONTE)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.fontes = resposta;
                        ctrl.scopeApply();
                    }
                });
                this.localidades = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.LOCALIDADE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LOCALIDADE)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.localidades = resposta;
                        ctrl.scopeApply();
                    }
                });
                this.provinciasUf = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROVINCIA_UF)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROVINCIA_UF)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.provinciasUf = resposta;
                        ctrl.scopeApply();
                    }
                });
                this.pomares = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POMAR)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.pomares = resposta;
                        ctrl.scopeApply();
                    }
                });
                this.tiposTestesPopulacoes = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TIPO_TESTE_POPULACAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TIPO_TESTE_POPULACAO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.tiposTestesPopulacoes = resposta;
                        ctrl.scopeApply();
                    }
                });
                this.situacoes = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.SITUACAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.SITUACAO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.situacoes = resposta;
                        ctrl.scopeApply();
                    }
                });
                this.regioes = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.REGIAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.REGIAO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.regioes = resposta;
                        ctrl.scopeApply();
                    }
                });
                this.talhoes = new Array();
                this.talhoesFiltrados = new Array();
                this.talhoesCarregando = true;
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TALHAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TALHAO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.talhoes = resposta;
                        ctrl.filtraTalhoes();
                        ctrl.talhoesCarregando = false;
                        ctrl.scopeApply();
                    }
                });
                this.unidadesManejo = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.UNIDADE_MANEJO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.UNIDADE_MANEJO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.unidadesManejo = resposta;
                        ctrl.scopeApply();
                    }
                });
                if ((typeof this.modelo !== 'undefined') && this.modelo &&
                    (typeof this.modelo.talhao !== 'undefined') && this.modelo.talhao) {
                    yield this.alterouTalhao();
                }
                setTimeout((ctrl) => {
                    ctrl.alterouTalhao();
                }, 1500, this);
            }
            RevTabelaUtil_1.RevTabelaUtil.adicionarEventoMonitoramentoAtualizacoesTabelas(this, this.atualizarDadosTabela);
            this.$scope.$apply();
        });
    }
    atualizarDadosTabela(nomeTabela) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof nomeTabela !== 'undefined') && nomeTabela &&
                ((this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) || (this.operacao === ICRUDOperacao_1.CRUDOperacao.EDITAR) || (this.operacao === ICRUDOperacao_1.CRUDOperacao.DUPLICAR))) {
                const refNomeTabela = TextoUtil_1.TextoUtil.converteTextoParaReferencia(nomeTabela);
                const ctrl = this;
                if (refNomeTabela === TextoUtil_1.TextoUtil.converteTextoParaReferencia('Genero')) {
                    this.generos = new Array();
                    this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.GENERO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.GENERO)
                        .listar()
                        .then((resposta) => {
                        if ((typeof resposta !== 'undefined') && resposta) {
                            ctrl.generos = resposta;
                            ctrl.scopeApply();
                        }
                    });
                }
                if (refNomeTabela === TextoUtil_1.TextoUtil.converteTextoParaReferencia('Especie')) {
                    this.especies = new Array();
                    this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESPECIE)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESPECIE)
                        .listar()
                        .then((resposta) => {
                        if ((typeof resposta !== 'undefined') && resposta) {
                            ctrl.especies = resposta;
                            ctrl.scopeApply();
                        }
                    });
                }
                if (refNomeTabela === TextoUtil_1.TextoUtil.converteTextoParaReferencia('Geracao')) {
                    this.geracoes = new Array();
                    this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.GERACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.GERACAO)
                        .listar()
                        .then((resposta) => {
                        if ((typeof resposta !== 'undefined') && resposta) {
                            ctrl.geracoes = resposta;
                            ctrl.scopeApply();
                        }
                    });
                }
                if (refNomeTabela === TextoUtil_1.TextoUtil.converteTextoParaReferencia('Pais')) {
                    this.paises = new Array();
                    this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PAIS)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PAIS)
                        .listar()
                        .then((resposta) => {
                        if ((typeof resposta !== 'undefined') && resposta) {
                            ctrl.paises = resposta;
                            ctrl.scopeApply();
                        }
                    });
                }
                if (refNomeTabela === TextoUtil_1.TextoUtil.converteTextoParaReferencia('Fonte')) {
                    this.fontes = new Array();
                    this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.FONTE)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.FONTE)
                        .listar()
                        .then((resposta) => {
                        if ((typeof resposta !== 'undefined') && resposta) {
                            ctrl.fontes = resposta;
                            ctrl.scopeApply();
                        }
                    });
                }
                if (refNomeTabela === TextoUtil_1.TextoUtil.converteTextoParaReferencia('Localidade')) {
                    this.localidades = new Array();
                    this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.LOCALIDADE)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LOCALIDADE)
                        .listar()
                        .then((resposta) => {
                        if ((typeof resposta !== 'undefined') && resposta) {
                            ctrl.localidades = resposta;
                            ctrl.scopeApply();
                        }
                    });
                }
                if (refNomeTabela === TextoUtil_1.TextoUtil.converteTextoParaReferencia('ProvinciaUf')) {
                    this.provinciasUf = new Array();
                    this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROVINCIA_UF)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROVINCIA_UF)
                        .listar()
                        .then((resposta) => {
                        if ((typeof resposta !== 'undefined') && resposta) {
                            ctrl.provinciasUf = resposta;
                            ctrl.scopeApply();
                        }
                    });
                }
                if (refNomeTabela === TextoUtil_1.TextoUtil.converteTextoParaReferencia('Pomar')) {
                    this.pomares = new Array();
                    this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POMAR)
                        .listar()
                        .then((resposta) => {
                        if ((typeof resposta !== 'undefined') && resposta) {
                            ctrl.pomares = resposta;
                            ctrl.scopeApply();
                        }
                    });
                }
                if (refNomeTabela === TextoUtil_1.TextoUtil.converteTextoParaReferencia('TipoTestePopulacao')) {
                    this.tiposTestesPopulacoes = new Array();
                    this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.TIPO_TESTE_POPULACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TIPO_TESTE_POPULACAO)
                        .listar()
                        .then((resposta) => {
                        if ((typeof resposta !== 'undefined') && resposta) {
                            ctrl.tiposTestesPopulacoes = resposta;
                            ctrl.scopeApply();
                        }
                    });
                }
                if (refNomeTabela === TextoUtil_1.TextoUtil.converteTextoParaReferencia('Situacao')) {
                    this.situacoes = new Array();
                    this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.SITUACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.SITUACAO)
                        .listar()
                        .then((resposta) => {
                        if ((typeof resposta !== 'undefined') && resposta) {
                            ctrl.situacoes = resposta;
                            ctrl.scopeApply();
                        }
                    });
                }
                if (refNomeTabela === TextoUtil_1.TextoUtil.converteTextoParaReferencia('Regiao')) {
                    this.regioes = new Array();
                    this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.REGIAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.REGIAO)
                        .listar()
                        .then((resposta) => {
                        if ((typeof resposta !== 'undefined') && resposta) {
                            ctrl.regioes = resposta;
                            ctrl.scopeApply();
                        }
                    });
                }
                if (refNomeTabela === TextoUtil_1.TextoUtil.converteTextoParaReferencia('Talhao')) {
                    this.talhoes = new Array();
                    this.talhoesFiltrados = new Array();
                    this.talhoesCarregando = true;
                    this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.TALHAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TALHAO)
                        .listar()
                        .then((resposta) => {
                        if ((typeof resposta !== 'undefined') && resposta) {
                            ctrl.talhoes = resposta;
                            ctrl.filtraTalhoes();
                            ctrl.talhoesCarregando = false;
                            ctrl.scopeApply();
                        }
                    });
                }
                if (refNomeTabela === TextoUtil_1.TextoUtil.converteTextoParaReferencia('UnidadeManejo')) {
                    this.unidadesManejo = new Array();
                    this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.UNIDADE_MANEJO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.UNIDADE_MANEJO)
                        .listar()
                        .then((resposta) => {
                        if ((typeof resposta !== 'undefined') && resposta) {
                            ctrl.unidadesManejo = resposta;
                            ctrl.scopeApply();
                        }
                    });
                }
                if (refNomeTabela === TextoUtil_1.TextoUtil.converteTextoParaReferencia('Talhao')) {
                    if ((typeof this.modelo !== 'undefined') && this.modelo &&
                        (typeof this.modelo.talhao !== 'undefined') && this.modelo.talhao) {
                        yield this.alterouTalhao();
                    }
                    setTimeout((ctrl) => {
                        ctrl.alterouTalhao();
                    }, 1500, this);
                }
            }
        });
    }
    salvar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TESTE_GENETICO)
                        .criar(this.modelo);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.TESTE_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TESTE_GENETICO)
                        .atualizar(this.modelo);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir um novo Teste Genéticos ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir um novo Teste Genéticos ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não'
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    visualizar(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const testeGenetico = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TESTE_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TESTE_GENETICO)
                    .id(id)
                    .ver();
                if (testeGenetico) {
                    testeGenetico.criacao = new Date(testeGenetico.criacao);
                    if (typeof testeGenetico.data === 'string') {
                        testeGenetico.data = new Date(testeGenetico.data);
                    }
                    if (typeof testeGenetico.dataImplantacao === 'string') {
                        testeGenetico.dataImplantacao = new Date(testeGenetico.dataImplantacao);
                    }
                    this.modelo = testeGenetico;
                    this.filtraTalhoes();
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return testeGenetico;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    remover(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const testeGenetico = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TESTE_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TESTE_GENETICO)
                    .id(id)
                    .remover();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (testeGenetico) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const testeGenetico = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TESTE_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TESTE_GENETICO)
                    .id(this.modelo.id)
                    .restaurar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (testeGenetico) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosLista(filtro = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                this.path = config_app_1.config.api.caminho;
                this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.TESTE_GENETICO;
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                var auxColunas = Array();
                auxColunas.push({
                    name: 'codigo',
                    displayName: 'Código',
                    width: 120
                });
                auxColunas.push({
                    name: 'genero.descricao',
                    displayName: 'Gênero',
                    width: 160
                });
                auxColunas.push({
                    name: 'especie.descricao',
                    displayName: 'Espécie',
                    width: 200
                });
                auxColunas.push({
                    name: 'geracao.descricao',
                    displayName: 'Geração',
                    width: 200
                });
                auxColunas.push({
                    name: 'localidade.descricao',
                    displayName: 'Município',
                    width: 180
                });
                auxColunas.push({
                    name: 'situacao.descricao',
                    displayName: 'Situação',
                    width: 150
                });
                auxColunas.push({
                    name: 'criacao',
                    displayName: 'Data/Hora Cadastro',
                    cellFilter: 'date:"dd/MM/yyyy HH:mm:ss"',
                    width: 190
                });
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
                const lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TESTE_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TESTE_GENETICO)
                    .filtro(filtro)
                    .listar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach(element => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    filtrar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    limparFiltro() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    filtraTalhoes() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.timeoutFiltroSgifTalhoes !== 'undefined') && (this.timeoutFiltroSgifTalhoes !== null)) {
                clearTimeout(this.timeoutFiltroSgifTalhoes);
                this.timeoutFiltroSgifTalhoes = null;
            }
            this.timeoutFiltroSgifTalhoes = setTimeout((controller) => {
                if ((typeof controller.talhoes !== 'undefined') && (controller.talhoes !== null) && (controller.talhoes.length > 0)) {
                    if ((typeof controller.talhoesFiltrados !== 'undefined') && (controller.talhoesFiltrados !== null)) {
                        controller.talhoesFiltrados.splice(0, controller.talhoesFiltrados.length);
                    }
                    else {
                        controller.talhoesFiltrados = new Array();
                    }
                    if ((typeof controller.modelo.regiao !== 'undefined') && (controller.modelo.regiao !== null)) {
                        if ((typeof controller.modelo.regiao.nome !== 'undefined') && (controller.modelo.regiao.nome !== null)) {
                            const s1 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(controller.modelo.regiao.nome);
                            angular.forEach(controller.talhoes, (talhao) => {
                                const s2 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(talhao.regiaoNome);
                                if (s1 === s2) {
                                    controller.talhoesFiltrados.push(talhao);
                                }
                            });
                        }
                    }
                    else if ((typeof controller.modelo.regiaoFazenda !== 'undefined') && (controller.modelo.regiaoFazenda !== null)) {
                        if ((typeof controller.modelo.regiaoFazenda.nome !== 'undefined') && (controller.modelo.regiaoFazenda.nome !== null)) {
                            const s1 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(controller.modelo.regiaoFazenda.nome);
                            angular.forEach(controller.talhoes, (talhao) => {
                                const s2 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(talhao.regiaoNome);
                                if (s1 === s2) {
                                    controller.talhoesFiltrados.push(talhao);
                                }
                            });
                        }
                    }
                    if ((typeof controller.talhoesFiltrados === 'undefined') || !controller.talhoesFiltrados || controller.talhoesFiltrados.length === 0) {
                        controller.talhoesFiltrados = [];
                        angular.forEach(controller.talhoes, (talhao) => {
                            controller.talhoesFiltrados.push(talhao);
                        });
                    }
                    console.log('Talhões carregados: ' + controller.talhoes.length);
                    console.log('Talhões filtrados: ' + controller.talhoesFiltrados.length);
                    try {
                        controller.scopeApply();
                    }
                    catch (err) {
                        console.log(err);
                    }
                }
            }, 500, this);
        });
    }
    alterouTalhao() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.modelo !== 'undefined') && this.modelo &&
                (typeof this.modelo.talhao !== 'undefined') && this.modelo.talhao) {
                if ((typeof this.modelo.talhao.areaTalhao !== 'undefined') && this.modelo.talhao.areaTalhao) {
                    this.modelo.area = this.modelo.talhao.areaTalhao;
                }
                if ((typeof this.modelo.talhao.dataPlantio !== 'undefined') && this.modelo.talhao.dataPlantio) {
                    this.modelo.idade = DataUtil_1.DataUtil.diasEntreDatas(this.modelo.talhao.dataPlantio, DataUtil_1.DataUtil.dataHoraAtual());
                    this.modelo.idadeEmAnos = NumeroUtil_1.NumeroUtil.arredondarNumero(this.modelo.idade / 365, 1);
                }
                if ((typeof this.modelo.unidadeManejo === 'undefined') || !this.modelo.unidadeManejo) {
                    if ((typeof this.unidadesManejo !== 'undefined') && this.unidadesManejo && (this.unidadesManejo.length > 0)) {
                        this.modelo.unidadeManejo = this.unidadesManejo[0];
                    }
                }
                this.scopeApply();
            }
        });
    }
    calcularIdadeTeste() {
        let idade = 0;
        if ((typeof this.modelo.dataImplantacao !== 'undefined') && this.modelo.dataImplantacao) {
            const hoje = new Date();
            const anoAtual = hoje.getFullYear();
            const mesAtual = hoje.getMonth();
            const diaAtual = hoje.getDate();
            const anoNascimento = this.modelo.dataImplantacao.getFullYear();
            const mesNascimento = this.modelo.dataImplantacao.getMonth();
            const diaNascimento = this.modelo.dataImplantacao.getDate();
            idade = anoAtual - anoNascimento;
            if (mesAtual < mesNascimento || (mesAtual === mesNascimento && diaAtual < diaNascimento)) {
                idade--;
            }
        }
        this.modelo.idade = idade;
    }
}
exports.TesteGeneticoController = TesteGeneticoController;
TesteGeneticoController.$inject = ['$scope', '$injector'];
