"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Autenticacao = void 0;
class Autenticacao {
    constructor() {
        this.empresa = null;
        this.email = null;
        this.senha = null;
        this.celular = null;
        this.aparelho = null;
        this.token = null;
        this.tokenJWE = null;
        this.sistema = null;
        this.versao = null;
        this.validade = null;
        this.perfil = null;
        this.permissoes = null;
        this.logado = null;
    }
}
exports.Autenticacao = Autenticacao;
