"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TesteGeneticoUtil = void 0;
const angular = require("angular");
const Pais_1 = require("../modelos/Pais");
const TextoUtil_1 = require("./TextoUtil");
const PerfilClimaticoPomar_1 = require("../modelos/PerfilClimaticoPomar");
const DataUtil_1 = require("./DataUtil");
class TesteGeneticoUtil {
    static padronizaPropriedadesTesteGenetico(testeGenetico, paises) {
        testeGenetico.descricaoDetalhada = ((typeof testeGenetico.descricao !== 'undefined') && testeGenetico.descricao ? testeGenetico.descricao : '');
        if ((typeof testeGenetico.data !== 'undefined') && testeGenetico.data) {
            testeGenetico.data = (typeof testeGenetico.data === 'string') ? new Date(testeGenetico.data) : testeGenetico.data;
            if (testeGenetico.data) {
                let dataHoraAtual = new Date();
                let idadeMs = dataHoraAtual.getTime() - testeGenetico.data.getTime();
                let idadeData = new Date(idadeMs);
                testeGenetico.idade = Math.abs(idadeData.getUTCFullYear() - 1970);
                testeGenetico.descricaoDetalhada += ' - ' + testeGenetico.data.getFullYear();
            }
        }
        if ((typeof testeGenetico.paisDescricao === 'undefined') && !testeGenetico.paisDescricao) {
            if ((typeof paises !== 'undefined') && paises && (paises.length > 0)) {
                let paisPadrao = null;
                const paisPadraoReferencia = TextoUtil_1.TextoUtil.converteTextoParaReferencia('Brasil');
                angular.forEach(paises, (pais) => {
                    if (!paisPadrao && (typeof pais.descricao !== 'undefined') && pais.descricao && (pais.descricao.length > 0)) {
                        if (TextoUtil_1.TextoUtil.converteTextoParaReferencia(pais.descricao) === paisPadraoReferencia) {
                            paisPadrao = pais;
                        }
                    }
                });
                if (paisPadrao) {
                    testeGenetico.pais = paisPadrao;
                    testeGenetico.paisId = paisPadrao.id;
                    testeGenetico.paisCodigo = paisPadrao.codigo;
                    testeGenetico.paisDescricao = paisPadrao.descricao;
                }
            }
            else {
                let paisPadrao = {
                    id: Pais_1.Pais.BRASIL,
                    uuid: Pais_1.Pais.BRASIL,
                    status: true,
                    criacao: new Date(),
                    codigo: 1,
                    descricao: 'Brasil'
                };
                testeGenetico.pais = paisPadrao;
                testeGenetico.paisId = paisPadrao.id;
                testeGenetico.paisCodigo = paisPadrao.codigo;
                testeGenetico.paisDescricao = paisPadrao.descricao;
            }
        }
        if ((typeof testeGenetico.instituicao === 'undefined') || !testeGenetico.instituicao || (testeGenetico.instituicao.length === 0)) {
            testeGenetico.instituicao = 'WestRock';
        }
        if ((typeof testeGenetico.nomeCientifico === 'undefined') || !testeGenetico.nomeCientifico || (testeGenetico.nomeCientifico.length === 0)) {
            testeGenetico.nomeCientifico = testeGenetico.descricao;
        }
        if ((typeof testeGenetico.distanciaSede === 'undefined') || !testeGenetico.distanciaSede) {
            testeGenetico.distanciaSede = (testeGenetico.descricaoDetalhada.toLowerCase().indexOf('sede') !== -1) ? '1 km' : '35 km';
        }
        if ((typeof testeGenetico.conjunto === 'undefined') || !testeGenetico.conjunto) {
            if ((typeof testeGenetico.pomarOrigem !== 'undefined') && testeGenetico.pomarOrigem &&
                (typeof testeGenetico.pomarOrigem.conjunto !== 'undefined') && testeGenetico.pomarOrigem.conjunto) {
                testeGenetico.conjunto = testeGenetico.pomarOrigem.conjunto;
            }
        }
        if ((typeof testeGenetico.cruzamento === 'undefined') || !testeGenetico.cruzamento) {
            if ((typeof testeGenetico.pomarOrigem !== 'undefined') && testeGenetico.pomarOrigem &&
                (typeof testeGenetico.pomarOrigem.cruzamento !== 'undefined') && testeGenetico.pomarOrigem.cruzamento) {
                testeGenetico.cruzamento = testeGenetico.pomarOrigem.cruzamento;
            }
        }
        testeGenetico.local = ((typeof testeGenetico.municipio !== 'undefined') && testeGenetico.municipio ? testeGenetico.municipio : '') + ' - ' +
            ((typeof testeGenetico.provinciaUfSigla !== 'undefined') && testeGenetico.provinciaUfSigla ? testeGenetico.provinciaUfSigla : '');
        if ((typeof testeGenetico.perfilClimaticoAnual !== 'undefined') && testeGenetico.perfilClimaticoAnual) {
            if ((typeof testeGenetico.perfilClimaticoAnual.atualizacao === 'undefined') || !testeGenetico.perfilClimaticoAnual.atualizacao) {
                testeGenetico.perfilClimaticoAnual.atualizacao = new Date();
            }
            else if (typeof testeGenetico.perfilClimaticoAnual.atualizacao === 'string') {
                testeGenetico.perfilClimaticoAnual.atualizacao = new Date(testeGenetico.perfilClimaticoAnual.atualizacao);
            }
        }
        else {
            testeGenetico.perfilClimaticoAnual = new PerfilClimaticoPomar_1.PerfilClimaticoPomar();
            testeGenetico.perfilClimaticoAnual.atualizacao = new Date();
        }
        if ((typeof testeGenetico.producaoPortaEnxertosPlantio !== 'undefined') && testeGenetico.producaoPortaEnxertosPlantio) {
            testeGenetico.producaoPortaEnxertosPlantio = (typeof testeGenetico.producaoPortaEnxertosPlantio === 'string') ? new Date(testeGenetico.producaoPortaEnxertosPlantio) : testeGenetico.producaoPortaEnxertosPlantio;
        }
        if ((typeof testeGenetico.implantacaoPomarEnxertia !== 'undefined') && testeGenetico.implantacaoPomarEnxertia) {
            testeGenetico.implantacaoPomarEnxertia = (typeof testeGenetico.implantacaoPomarEnxertia === 'string') ? new Date(testeGenetico.implantacaoPomarEnxertia) : testeGenetico.implantacaoPomarEnxertia;
        }
        if ((typeof testeGenetico.data !== 'undefined') && testeGenetico.data) {
            testeGenetico.data = (typeof testeGenetico.data === 'string') ? new Date(testeGenetico.data) : testeGenetico.data;
        }
        if ((typeof testeGenetico.dataImplantacao !== 'undefined') && testeGenetico.dataImplantacao) {
            testeGenetico.dataImplantacao = (typeof testeGenetico.dataImplantacao === 'string') ? new Date(testeGenetico.dataImplantacao) : testeGenetico.dataImplantacao;
        }
        if ((typeof testeGenetico.data === 'undefined') || !testeGenetico.data) {
            if ((typeof testeGenetico.pomarOrigem !== 'undefined') && testeGenetico.pomarOrigem &&
                (typeof testeGenetico.pomarOrigem.data !== 'undefined') && testeGenetico.pomarOrigem.data) {
                testeGenetico.data = (typeof testeGenetico.pomarOrigem.data === 'string') ? new Date(testeGenetico.pomarOrigem.data) : testeGenetico.pomarOrigem.data;
            }
        }
        if ((typeof testeGenetico.dataImplantacao === 'undefined') || !testeGenetico.dataImplantacao) {
            if ((typeof testeGenetico.pomarOrigem !== 'undefined') && testeGenetico.pomarOrigem &&
                (typeof testeGenetico.pomarOrigem.data !== 'undefined') && testeGenetico.pomarOrigem.data) {
                testeGenetico.dataImplantacao = (typeof testeGenetico.pomarOrigem.data === 'string') ? new Date(testeGenetico.pomarOrigem.data) : testeGenetico.pomarOrigem.data;
            }
        }
        if ((typeof testeGenetico.regiaoFazenda === 'undefined') || !testeGenetico.regiaoFazenda) {
            if ((typeof testeGenetico.pomarOrigem !== 'undefined') && testeGenetico.pomarOrigem &&
                (typeof testeGenetico.pomarOrigem.regiao !== 'undefined') && testeGenetico.pomarOrigem.regiao) {
                testeGenetico.regiaoFazenda = testeGenetico.pomarOrigem.regiao;
            }
        }
        if ((typeof testeGenetico.talhao === 'undefined') || !testeGenetico.talhao) {
            if ((typeof testeGenetico.pomarOrigem !== 'undefined') && testeGenetico.pomarOrigem &&
                (typeof testeGenetico.pomarOrigem.talhao !== 'undefined') && testeGenetico.pomarOrigem.talhao) {
                testeGenetico.talhao = testeGenetico.pomarOrigem.talhao;
            }
        }
        testeGenetico.descricaoDetalhada = '';
        if ((typeof testeGenetico.genero !== 'undefined') && testeGenetico.genero && (typeof testeGenetico.genero.descricao !== 'undefined') && testeGenetico.genero.descricao &&
            (typeof testeGenetico.especie !== 'undefined') && testeGenetico.especie && (typeof testeGenetico.especie.descricao !== 'undefined') && testeGenetico.especie.descricao) {
            if (testeGenetico.especie.descricao.toLowerCase().indexOf(testeGenetico.genero.descricao.toLowerCase().trim()) !== -1) {
                testeGenetico.descricaoDetalhada = 'Espécie: ' + testeGenetico.especie.descricao.trim();
            }
            else {
                testeGenetico.descricaoDetalhada = 'Espécie: ' + testeGenetico.genero.descricao.trim() + ' ' + testeGenetico.especie.descricao.trim();
            }
        }
        else if ((typeof testeGenetico.genero !== 'undefined') && testeGenetico.genero && (typeof testeGenetico.genero.descricao !== 'undefined') && testeGenetico.genero.descricao) {
            testeGenetico.descricaoDetalhada = 'Gênero: ' + testeGenetico.genero.descricao.trim();
        }
        else if ((typeof testeGenetico.especie !== 'undefined') && testeGenetico.especie && (typeof testeGenetico.especie.descricao !== 'undefined') && testeGenetico.especie.descricao) {
            testeGenetico.descricaoDetalhada = 'Espécie: ' + testeGenetico.especie.descricao.trim();
        }
        if ((typeof testeGenetico.pomarOrigem !== 'undefined') && testeGenetico.pomarOrigem && (typeof testeGenetico.pomarOrigem.descricao !== 'undefined') && testeGenetico.pomarOrigem.descricao) {
            testeGenetico.descricaoDetalhada = ((testeGenetico.descricaoDetalhada && testeGenetico.descricaoDetalhada.length > 0) ? testeGenetico.descricaoDetalhada + ' - ' : '') +
                'Pomar: ' + testeGenetico.pomarOrigem.descricao;
        }
        if ((typeof testeGenetico.cruzamento !== 'undefined') && testeGenetico.cruzamento) {
            testeGenetico.descricaoDetalhada = ((testeGenetico.descricaoDetalhada && testeGenetico.descricaoDetalhada.length > 0) ? testeGenetico.descricaoDetalhada + ' - ' : '') +
                'Cruzamento: ' + testeGenetico.cruzamento;
        }
        if ((typeof testeGenetico.dataImplantacao !== 'undefined') && testeGenetico.dataImplantacao) {
            testeGenetico.descricaoDetalhada = ((testeGenetico.descricaoDetalhada && testeGenetico.descricaoDetalhada.length > 0) ? testeGenetico.descricaoDetalhada + ' - ' : '') +
                'Implantação: ' + DataUtil_1.DataUtil.converterDataParaFormatoDDMMYYYY(testeGenetico.dataImplantacao);
        }
    }
}
exports.TesteGeneticoUtil = TesteGeneticoUtil;
