"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProducaoMudaClonalEucalyptusResgateMacroestacasAvaliacao = void 0;
const Base_1 = require("./Base");
class ProducaoMudaClonalEucalyptusResgateMacroestacasAvaliacao extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.data = null;
        this.qntEnraizadas = null;
        this.clonalEucalyptus = null;
        this.cloneMuda = null;
    }
}
exports.ProducaoMudaClonalEucalyptusResgateMacroestacasAvaliacao = ProducaoMudaClonalEucalyptusResgateMacroestacasAvaliacao;
