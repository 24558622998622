"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PosicaoColunaTabela = void 0;
const Base_1 = require("./Base");
class PosicaoColunaTabela extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.chaveTabela = null;
        this.nomeCampo = null;
        this.tituloCampo = null;
        this.posicaoAnterior = null;
        this.posicaoFinal = null;
        this.usuario = null;
        this.camposTabela = [];
    }
}
exports.PosicaoColunaTabela = PosicaoColunaTabela;
