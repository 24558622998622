"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnaliseSelecaoTesteGenetico = void 0;
const Base_1 = require("./Base");
class AnaliseSelecaoTesteGenetico extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.id = null;
        this.codigo = null;
        this.testeGenetico = null;
        this.descricao = null;
        this.anoAnalise = null;
        this.bloco = null;
        this.parcela = null;
        this.parc = null;
        this.progenie = null;
        this.tratamento = null;
        this.trat = null;
        this.arvore = null;
        this.arv = null;
        this.diametro = null;
        this.dap = null;
        this.altura = null;
        this.alt = null;
        this.forma = null;
        this.for = null;
        this.dbh5 = null;
        this.h5 = null;
        this.str = null;
        this.fork = null;
        this.bif = null;
        this.bf = null;
        this.copaQuebrada = null;
        this.cq = null;
        this.foxTail = null;
        this.ft = null;
        this.volume = null;
        this.vol = null;
        this.medicoes = [];
        this.planilhas = [];
        this.planilhaSelecionada = null;
        this.anexos = [];
        this.observacoes = null;
        this.usuarioInclusao = null;
        this.dataHoraInclusao = null;
        this.usuarioAlteracao = null;
        this.dataHoraAlteracao = null;
        this.anosMedicao = [];
    }
}
exports.AnaliseSelecaoTesteGenetico = AnaliseSelecaoTesteGenetico;
