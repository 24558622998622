"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabEstudoCones = void 0;
const Base_1 = require("./Base");
class LabEstudoCones extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.data = null;
        this.genero = null;
        this.especie = null;
        this.materialGenetico = null;
        this.numCones = null;
        this.numSementesTot = null;
        this.numSementesChochas = null;
        this.numSementesViaveis = null;
        this.numSementesCone = null;
    }
}
exports.LabEstudoCones = LabEstudoCones;
