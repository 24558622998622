"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Base = void 0;
class Base {
    constructor() {
        this.id = null;
        this.uuid = null;
        this.persistGuid = null;
        this.status = true;
        this.chave = null;
        this.criacao = null;
        this.alteracao = null;
        this.syncLocal = false;
        this.syncOrdem = null;
    }
}
exports.Base = Base;
