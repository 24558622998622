"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CloneMaterialGenetico = void 0;
const Base_1 = require("./Base");
class CloneMaterialGenetico extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.clone = null;
        this.genero = null;
        this.especie = null;
        this.anoResgate = null;
        this.procedencia = null;
        this.regiaoFazendaTesteProgenie = null;
        this.anoTesteTesteProgenie = null;
        this.codigoTeste = null;
        this.codigoSelecao = null;
        this.banco = null;
        this.ano = null;
        this.regiaoTesteClonal = null;
        this.anoClonal = null;
        this.materialGenetico = null;
    }
}
exports.CloneMaterialGenetico = CloneMaterialGenetico;
