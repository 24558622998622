"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CaracteristicaDescritorMorfologico = void 0;
const Base_1 = require("./Base");
class CaracteristicaDescritorMorfologico extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.classificacaoVida = null;
        this.nomeCaracteristica = null;
        this.identificadores = [];
    }
}
exports.CaracteristicaDescritorMorfologico = CaracteristicaDescritorMorfologico;
CaracteristicaDescritorMorfologico.FAIXA_4_MESES = '3 e 4 meses de idade';
CaracteristicaDescritorMorfologico.FAIXA_6_MESES = '6 meses';
CaracteristicaDescritorMorfologico.FAIXA_1_ANO = '1 ano';
CaracteristicaDescritorMorfologico.FAIXA_3_ANOS = '2 a 3 anos';
CaracteristicaDescritorMorfologico.FAIXA_ADULTO = 'Adulto';
CaracteristicaDescritorMorfologico.FAIXA_5_ANOS = 'Intervenção';
