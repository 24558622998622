"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GedSimplificado = void 0;
const Base_1 = require("./Base");
const uuid_1 = require("uuid");
class GedSimplificado extends Base_1.Base {
    constructor(gedBase) {
        super();
        this.guid = null;
        this.guid = uuid_1.v4();
        this.criacao = new Date();
        this.status = true;
        this.id = (typeof gedBase.id !== 'undefined') ? gedBase.id : this.id;
        this.uuid = (typeof gedBase.uuid !== 'undefined') ? gedBase.uuid : this.uuid;
        this.guid = (typeof gedBase.guid !== 'undefined') ? gedBase.guid : this.guid;
        this.criacao = (typeof gedBase.criacao !== 'undefined') ? gedBase.criacao : this.criacao;
        this.status = (typeof gedBase.status !== 'undefined') ? gedBase.status : this.status;
        this.nome = (typeof gedBase.nome !== 'undefined') ? gedBase.nome : null;
        this.tipo = (typeof gedBase.tipo !== 'undefined') ? gedBase.tipo : null;
        this.tamanho = (typeof gedBase.tamanho !== 'undefined') ? gedBase.tamanho : null;
        if (typeof gedBase.chave !== 'undefined') {
            this.chave = gedBase.chave;
        }
        if (typeof gedBase.alteracao !== 'undefined') {
            this.alteracao = gedBase.alteracao;
        }
        if (typeof gedBase.syncLocal !== 'undefined') {
            this.syncLocal = gedBase.syncLocal;
        }
    }
}
exports.GedSimplificado = GedSimplificado;
