"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CampoIndicadorFotos = void 0;
const angular = require("angular");
const config_app_1 = require("../../../../../config/config.app");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const Ged_1 = require("../../../../../modelos/Ged");
const GedSimplificado_1 = require("../../../../../modelos/GedSimplificado");
const DispositivoUtil_1 = require("../../../../../util/DispositivoUtil");
class CampoIndicadorFotos {
    constructor($scope) {
        this.path = null;
        this.fotosCamera = [];
        this.ehApontamento = false;
        this.ehControleManejoPomar = false;
        this.ehAnexoPomar = false;
        this.ehControleManejoTesteGenetico = false;
        this.ehQualidadeMadeiraTesteGenetico = false;
        this.ehAnexoTesteGenetico = false;
        this.ehProtocoloTesteGenetico = false;
        this.apontamento = null;
        this.controleManejoPomar = null;
        this.anexoPomar = null;
        this.controleManejoTesteGenetico = null;
        this.qualidadeMadeiraTesteGenetico = null;
        this.anexoTesteGenetico = null;
        this.protocoloTesteGenetico = null;
        this.dadosCarregados = null;
        this.$scope = null;
        this.sincronia = null;
        this.apontamento_base = null;
        this.controleManejoPomar_base = null;
        this.anexoPomar_base = null;
        this.controleManejoTesteGenetico_base = null;
        this.qualidadeMadeiraTesteGenetico_base = null;
        this.anexoTesteGenetico_base = null;
        this.protocoloTesteGenetico_base = null;
        this.$scope = $scope;
        this.dadosCarregados = false;
        this.fotosCamera = new Array();
    }
    carregarDados(sincroniaFactory, registroBase) {
        return __awaiter(this, void 0, void 0, function* () {
            this.path = config_app_1.config.api.caminho;
            this.sincronia = sincroniaFactory;
            this.ehApontamento = ((typeof registroBase !== 'undefined') && registroBase && (typeof registroBase.apontamento !== 'undefined') && registroBase.apontamento) ? true : false;
            this.ehControleManejoPomar = !this.ehApontamento && ((typeof registroBase !== 'undefined') && registroBase && (typeof registroBase.controleManejoPomar !== 'undefined') && registroBase.controleManejoPomar) ? true : false;
            this.ehAnexoPomar = !this.ehApontamento && ((typeof registroBase !== 'undefined') && registroBase && (typeof registroBase.anexoPomar !== 'undefined') && registroBase.anexoPomar) ? true : false;
            this.ehControleManejoTesteGenetico = !this.ehApontamento && ((typeof registroBase !== 'undefined') && registroBase && (typeof registroBase.controleManejoTesteGenetico !== 'undefined') && registroBase.controleManejoTesteGenetico) ? true : false;
            this.ehQualidadeMadeiraTesteGenetico = !this.ehApontamento && ((typeof registroBase !== 'undefined') && registroBase && (typeof registroBase.qualidadeMadeiraTesteGenetico !== 'undefined') && registroBase.qualidadeMadeiraTesteGenetico) ? true : false;
            this.ehAnexoTesteGenetico = !this.ehApontamento && ((typeof registroBase !== 'undefined') && registroBase && (typeof registroBase.anexoTesteGenetico !== 'undefined') && registroBase.anexoTesteGenetico) ? true : false;
            this.ehProtocoloTesteGenetico = !this.ehApontamento && ((typeof registroBase !== 'undefined') && registroBase && (typeof registroBase.protocoloTesteGenetico !== 'undefined') && registroBase.protocoloTesteGenetico) ? true : false;
            this.apontamento = this.ehApontamento ? registroBase.apontamento : ((typeof this.apontamento !== 'undefined') ? this.apontamento : null);
            this.apontamento_base = this.ehApontamento ? registroBase.apontamento : null;
            this.controleManejoPomar = this.ehControleManejoPomar ? registroBase.controleManejoPomar : ((typeof this.controleManejoPomar !== 'undefined') ? this.controleManejoPomar : null);
            this.controleManejoPomar_base = this.ehControleManejoPomar ? registroBase.controleManejoPomar : null;
            this.anexoPomar = this.ehAnexoPomar ? registroBase.anexoPomar : ((typeof this.anexoPomar !== 'undefined') ? this.anexoPomar : null);
            this.anexoPomar_base = this.ehAnexoPomar ? registroBase.anexoPomar : null;
            this.controleManejoTesteGenetico = this.ehControleManejoTesteGenetico ? registroBase.controleManejoTesteGenetico : ((typeof this.controleManejoTesteGenetico !== 'undefined') ? this.controleManejoTesteGenetico : null);
            this.controleManejoTesteGenetico_base = this.ehControleManejoTesteGenetico ? registroBase.controleManejoTesteGenetico : null;
            this.qualidadeMadeiraTesteGenetico = this.ehQualidadeMadeiraTesteGenetico ? registroBase.qualidadeMadeiraTesteGenetico : ((typeof this.qualidadeMadeiraTesteGenetico !== 'undefined') ? this.qualidadeMadeiraTesteGenetico : null);
            this.qualidadeMadeiraTesteGenetico_base = this.ehQualidadeMadeiraTesteGenetico ? registroBase.qualidadeMadeiraTesteGenetico : null;
            this.anexoTesteGenetico = this.ehAnexoTesteGenetico ? registroBase.anexoTesteGenetico : ((typeof this.anexoTesteGenetico !== 'undefined') ? this.anexoTesteGenetico : null);
            this.anexoTesteGenetico_base = this.ehAnexoTesteGenetico ? registroBase.anexoTesteGenetico : null;
            this.protocoloTesteGenetico = this.ehProtocoloTesteGenetico ? registroBase.protocoloTesteGenetico : ((typeof this.protocoloTesteGenetico !== 'undefined') ? this.protocoloTesteGenetico : null);
            this.protocoloTesteGenetico_base = this.ehProtocoloTesteGenetico ? registroBase.protocoloTesteGenetico : null;
            this.filtrarFotos();
            this.dadosCarregados = true;
        });
    }
    filtrarFotos(registro) {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            if ((typeof this.fotosCamera !== 'undefined') && (this.fotosCamera !== null)) {
                this.fotosCamera.splice(0, this.fotosCamera.length);
            }
            else {
                this.fotosCamera = new Array();
            }
            if (this.ehApontamento) {
            }
            else if (this.ehControleManejoPomar) {
                const tmpControleManejoPomar = ((typeof registro !== 'undefined') && (registro !== null) && (typeof registro.controleManejoPomar !== 'undefined') && (registro.controleManejoPomar !== null)) ? registro.controleManejoPomar :
                    ((typeof this.controleManejoPomar !== 'undefined') && (this.controleManejoPomar !== null)) ? this.controleManejoPomar :
                        ((typeof this.controleManejoPomar_base !== 'undefined') && (this.controleManejoPomar_base !== null)) ? this.controleManejoPomar_base : null;
                if (!tmpControleManejoPomar) {
                    return;
                }
                if ((tmpControleManejoPomar.anexos !== null) && (tmpControleManejoPomar.anexos.length > 0)) {
                    angular.forEach(tmpControleManejoPomar.anexos, (item) => {
                        ctrl.fotosCamera.push({
                            uuid: (((typeof item.uuid !== 'undefined') && (item.uuid !== null) && (item.uuid.trim().length > 0)) ? item.uuid :
                                ((typeof item.id !== 'undefined') && (item.id !== null) && (item.id.trim().length > 0)) ? item.id : null),
                            controleManejoPomar: true,
                            foto: (((typeof item.foto !== 'undefined') && (item.foto !== null)) ? item.foto : null)
                        });
                    });
                }
            }
            else if (this.ehAnexoPomar) {
                const tmpAnexoPomar = ((typeof registro !== 'undefined') && (registro !== null) && (typeof registro.anexoPomar !== 'undefined') && (registro.anexoPomar !== null)) ? registro.anexoPomar :
                    ((typeof this.anexoPomar !== 'undefined') && (this.anexoPomar !== null)) ? this.anexoPomar :
                        ((typeof this.anexoPomar_base !== 'undefined') && (this.anexoPomar_base !== null)) ? this.anexoPomar_base : null;
                if (!tmpAnexoPomar) {
                    return;
                }
                if ((tmpAnexoPomar.anexos !== null) && (tmpAnexoPomar.anexos.length > 0)) {
                    angular.forEach(tmpAnexoPomar.anexos, (item) => {
                        ctrl.fotosCamera.push({
                            uuid: (((typeof item.uuid !== 'undefined') && (item.uuid !== null) && (item.uuid.trim().length > 0)) ? item.uuid :
                                ((typeof item.id !== 'undefined') && (item.id !== null) && (item.id.trim().length > 0)) ? item.id : null),
                            anexoPomar: true,
                            foto: (((typeof item.foto !== 'undefined') && (item.foto !== null)) ? item.foto : null)
                        });
                    });
                }
            }
            else if (this.ehControleManejoTesteGenetico) {
                const tmpControleManejoTesteGenetico = ((typeof registro !== 'undefined') && (registro !== null) && (typeof registro.controleManejoTesteGenetico !== 'undefined') && (registro.controleManejoTesteGenetico !== null)) ? registro.controleManejoTesteGenetico :
                    ((typeof this.controleManejoTesteGenetico !== 'undefined') && (this.controleManejoTesteGenetico !== null)) ? this.controleManejoTesteGenetico :
                        ((typeof this.controleManejoTesteGenetico_base !== 'undefined') && (this.controleManejoTesteGenetico_base !== null)) ? this.controleManejoTesteGenetico_base : null;
                if (!tmpControleManejoTesteGenetico) {
                    return;
                }
                if ((tmpControleManejoTesteGenetico.anexos !== null) && (tmpControleManejoTesteGenetico.anexos.length > 0)) {
                    angular.forEach(tmpControleManejoTesteGenetico.anexos, (item) => {
                        ctrl.fotosCamera.push({
                            uuid: (((typeof item.uuid !== 'undefined') && (item.uuid !== null) && (item.uuid.trim().length > 0)) ? item.uuid :
                                ((typeof item.id !== 'undefined') && (item.id !== null) && (item.id.trim().length > 0)) ? item.id : null),
                            controleManejoTesteGenetico: true,
                            foto: (((typeof item.foto !== 'undefined') && (item.foto !== null)) ? item.foto : null)
                        });
                    });
                }
            }
            else if (this.ehAnexoTesteGenetico) {
                const tmpAnexoTesteGenetico = ((typeof registro !== 'undefined') && (registro !== null) && (typeof registro.anexoTesteGenetico !== 'undefined') && (registro.anexoTesteGenetico !== null)) ? registro.anexoTesteGenetico :
                    ((typeof this.anexoTesteGenetico !== 'undefined') && (this.anexoTesteGenetico !== null)) ? this.anexoTesteGenetico :
                        ((typeof this.anexoTesteGenetico_base !== 'undefined') && (this.anexoTesteGenetico_base !== null)) ? this.anexoTesteGenetico_base : null;
                if (!tmpAnexoTesteGenetico) {
                    return;
                }
                if ((tmpAnexoTesteGenetico.anexos !== null) && (tmpAnexoTesteGenetico.anexos.length > 0)) {
                    angular.forEach(tmpAnexoTesteGenetico.anexos, (item) => {
                        ctrl.fotosCamera.push({
                            uuid: (((typeof item.uuid !== 'undefined') && (item.uuid !== null) && (item.uuid.trim().length > 0)) ? item.uuid :
                                ((typeof item.id !== 'undefined') && (item.id !== null) && (item.id.trim().length > 0)) ? item.id : null),
                            anexoTesteGenetico: true,
                            foto: (((typeof item.foto !== 'undefined') && (item.foto !== null)) ? item.foto : null)
                        });
                    });
                }
            }
            else if (this.ehQualidadeMadeiraTesteGenetico) {
                const tmpQualidadeMadeiraTesteGenetico = ((typeof registro !== 'undefined') && (registro !== null) && (typeof registro.qualidadeMadeiraTesteGenetico !== 'undefined') && (registro.qualidadeMadeiraTesteGenetico !== null)) ? registro.qualidadeMadeiraTesteGenetico :
                    ((typeof this.qualidadeMadeiraTesteGenetico !== 'undefined') && (this.qualidadeMadeiraTesteGenetico !== null)) ? this.qualidadeMadeiraTesteGenetico :
                        ((typeof this.qualidadeMadeiraTesteGenetico_base !== 'undefined') && (this.qualidadeMadeiraTesteGenetico_base !== null)) ? this.qualidadeMadeiraTesteGenetico_base : null;
                if (!tmpQualidadeMadeiraTesteGenetico) {
                    return;
                }
                if ((tmpQualidadeMadeiraTesteGenetico.anexos !== null) && (tmpQualidadeMadeiraTesteGenetico.anexos.length > 0)) {
                    angular.forEach(tmpQualidadeMadeiraTesteGenetico.anexos, (item) => {
                        ctrl.fotosCamera.push({
                            uuid: (((typeof item.uuid !== 'undefined') && (item.uuid !== null) && (item.uuid.trim().length > 0)) ? item.uuid :
                                ((typeof item.id !== 'undefined') && (item.id !== null) && (item.id.trim().length > 0)) ? item.id : null),
                            qualidadeMadeiraTesteGenetico: true,
                            foto: (((typeof item.foto !== 'undefined') && (item.foto !== null)) ? item.foto : null)
                        });
                    });
                }
            }
            else if (this.ehProtocoloTesteGenetico) {
                const tmpProtocoloTesteGenetico = ((typeof registro !== 'undefined') && (registro !== null) && (typeof registro.protocoloTesteGenetico !== 'undefined') && (registro.protocoloTesteGenetico !== null)) ? registro.protocoloTesteGenetico :
                    ((typeof this.protocoloTesteGenetico !== 'undefined') && (this.protocoloTesteGenetico !== null)) ? this.protocoloTesteGenetico :
                        ((typeof this.protocoloTesteGenetico_base !== 'undefined') && (this.protocoloTesteGenetico_base !== null)) ? this.protocoloTesteGenetico_base : null;
                if (!tmpProtocoloTesteGenetico) {
                    return;
                }
                if ((tmpProtocoloTesteGenetico.anexos !== null) && (tmpProtocoloTesteGenetico.anexos.length > 0)) {
                    angular.forEach(tmpProtocoloTesteGenetico.anexos, (item) => {
                        ctrl.fotosCamera.push({
                            uuid: (((typeof item.uuid !== 'undefined') && (item.uuid !== null) && (item.uuid.trim().length > 0)) ? item.uuid :
                                ((typeof item.id !== 'undefined') && (item.id !== null) && (item.id.trim().length > 0)) ? item.id : null),
                            protocoloTesteGenetico: true,
                            foto: (((typeof item.foto !== 'undefined') && (item.foto !== null)) ? item.foto : null)
                        });
                    });
                }
            }
            if (DispositivoUtil_1.DispositivoUtil.ehCelular()) {
                setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                    angular.forEach(ctrl.fotosCamera, (fotoCamera) => __awaiter(this, void 0, void 0, function* () {
                        if ((typeof fotoCamera.foto === 'undefined') || !fotoCamera.foto) {
                            fotoCamera.foto = ctrl.path + 'ferramenta/ged/load/' + fotoCamera.uuid;
                        }
                    }));
                }), 500, this);
            }
        });
    }
    salvarFoto(fotoId) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!fotoId) {
                return;
            }
            const ctrl = this;
            const sincronia = this.sincronia;
            if ((typeof this.fotosCamera !== 'undefined') && (this.fotosCamera !== null)) {
                angular.forEach(this.fotosCamera, (fotoCamera) => __awaiter(this, void 0, void 0, function* () {
                    if (fotoCamera.uuid === fotoId) {
                        let ged = new Ged_1.Ged();
                        ged.id = fotoId,
                            ged.uuid = fotoId;
                        ged.guid = fotoId;
                        ged.criacao = new Date();
                        ged.status = true;
                        ged.valor = fotoCamera.foto;
                        try {
                            let gedSalvo = yield sincronia
                                .rota(RotaAPIConstantes_1.RotaAPIConstantes.FILE)
                                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.FILE)
                                .criar(ged);
                            if ((typeof gedSalvo !== 'undefined') && gedSalvo) {
                                if ((typeof gedSalvo.id !== 'undefined') && gedSalvo.id) {
                                    ged.id = gedSalvo.id;
                                }
                            }
                        }
                        catch (ex) {
                            console.log(ex);
                        }
                        finally {
                            if (ctrl.ehApontamento) {
                                const tmpApontamento = ((typeof ctrl.apontamento !== 'undefined') && (ctrl.apontamento !== null)) ? ctrl.apontamento :
                                    ((typeof ctrl.apontamento_base !== 'undefined') && (ctrl.apontamento_base !== null)) ? ctrl.apontamento_base : null;
                                if ((tmpApontamento && (typeof tmpApontamento.formularioIndicadores !== 'undefined') && tmpApontamento.formularioIndicadores)) {
                                    if ((typeof fotoCamera.indiceCampoCabecalho !== 'undefined') && (fotoCamera.indiceCampoCabecalho !== null) &&
                                        (typeof tmpApontamento.formularioIndicadores.camposCabecalho !== 'undefined') && (tmpApontamento.formularioIndicadores.camposCabecalho !== null) &&
                                        (fotoCamera.indiceCampoCabecalho >= 0) && (fotoCamera.indiceCampoCabecalho <= tmpApontamento.formularioIndicadores.camposCabecalho.length - 1)) {
                                        let campo = tmpApontamento.formularioIndicadores.camposCabecalho[fotoCamera.indiceCampoCabecalho];
                                        if ((typeof campo.valor === 'undefined') || (campo.valor === null) || !Array.isArray(campo.valor)) {
                                            campo.valor = new Array();
                                        }
                                        campo.valor.push(new GedSimplificado_1.GedSimplificado(ged));
                                    }
                                    if ((typeof fotoCamera.indiceIndicador !== 'undefined') && (fotoCamera.indiceIndicador !== null) &&
                                        (typeof tmpApontamento.formularioIndicadores.indicadores !== 'undefined') && (tmpApontamento.formularioIndicadores.indicadores !== null) &&
                                        (fotoCamera.indiceIndicador >= 0) && (fotoCamera.indiceIndicador <= tmpApontamento.formularioIndicadores.indicadores.length - 1)) {
                                        let indicador = tmpApontamento.formularioIndicadores.indicadores[fotoCamera.indiceIndicador];
                                        if ((typeof fotoCamera.indiceCampoIndicador !== 'undefined') && (fotoCamera.indiceCampoIndicador !== null) &&
                                            (typeof indicador.camposIndicador !== 'undefined') && (indicador.camposIndicador !== null) &&
                                            (fotoCamera.indiceCampoIndicador >= 0) && (fotoCamera.indiceCampoIndicador <= indicador.camposIndicador.length - 1)) {
                                            let campo = indicador.camposIndicador[fotoCamera.indiceCampoIndicador];
                                            if ((typeof campo.valor === 'undefined') || (campo.valor === null) || !Array.isArray(campo.valor)) {
                                                campo.valor = new Array();
                                            }
                                            campo.valor.push(new GedSimplificado_1.GedSimplificado(ged));
                                        }
                                    }
                                    if ((typeof fotoCamera.indiceCampoRodape !== 'undefined') && (fotoCamera.indiceCampoRodape !== null) &&
                                        (typeof tmpApontamento.formularioIndicadores.camposRodape !== 'undefined') && (tmpApontamento.formularioIndicadores.camposRodape !== null) &&
                                        (fotoCamera.indiceCampoRodape >= 0) && (fotoCamera.indiceCampoRodape <= tmpApontamento.formularioIndicadores.camposRodape.length - 1)) {
                                        let campo = tmpApontamento.formularioIndicadores.camposRodape[fotoCamera.indiceCampoRodape];
                                        if ((typeof campo.valor === 'undefined') || (campo.valor === null) || !Array.isArray(campo.valor)) {
                                            campo.valor = new Array();
                                        }
                                        campo.valor.push(new GedSimplificado_1.GedSimplificado(ged));
                                    }
                                }
                            }
                            else if (ctrl.ehControleManejoPomar) {
                                const tmpControleManejoPomar = ((typeof ctrl.controleManejoPomar !== 'undefined') && (ctrl.controleManejoPomar !== null)) ? ctrl.controleManejoPomar :
                                    ((typeof ctrl.controleManejoPomar_base !== 'undefined') && (ctrl.controleManejoPomar_base !== null)) ? ctrl.controleManejoPomar_base : null;
                                if (tmpControleManejoPomar) {
                                    if ((typeof tmpControleManejoPomar.anexos === 'undefined') || (tmpControleManejoPomar.anexos === null) || !Array.isArray(tmpControleManejoPomar.anexos)) {
                                        tmpControleManejoPomar.anexos = new Array();
                                    }
                                    tmpControleManejoPomar.anexos.push(new GedSimplificado_1.GedSimplificado(ged));
                                }
                            }
                            else if (ctrl.ehAnexoPomar) {
                                const tmpAnexoPomar = ((typeof ctrl.anexoPomar !== 'undefined') && (ctrl.anexoPomar !== null)) ? ctrl.anexoPomar :
                                    ((typeof ctrl.anexoPomar_base !== 'undefined') && (ctrl.anexoPomar_base !== null)) ? ctrl.anexoPomar_base : null;
                                if (tmpAnexoPomar) {
                                    if ((typeof tmpAnexoPomar.anexos === 'undefined') || (tmpAnexoPomar.anexos === null) || !Array.isArray(tmpAnexoPomar.anexos)) {
                                        tmpAnexoPomar.anexos = new Array();
                                    }
                                    tmpAnexoPomar.anexos.push(new GedSimplificado_1.GedSimplificado(ged));
                                }
                            }
                            else if (ctrl.ehControleManejoTesteGenetico) {
                                const tmpControleManejoTesteGenetico = ((typeof ctrl.controleManejoTesteGenetico !== 'undefined') && (ctrl.controleManejoTesteGenetico !== null)) ? ctrl.controleManejoTesteGenetico :
                                    ((typeof ctrl.controleManejoTesteGenetico_base !== 'undefined') && (ctrl.controleManejoTesteGenetico_base !== null)) ? ctrl.controleManejoTesteGenetico_base : null;
                                if (tmpControleManejoTesteGenetico) {
                                    if ((typeof tmpControleManejoTesteGenetico.anexos === 'undefined') || (tmpControleManejoTesteGenetico.anexos === null) || !Array.isArray(tmpControleManejoTesteGenetico.anexos)) {
                                        tmpControleManejoTesteGenetico.anexos = new Array();
                                    }
                                    tmpControleManejoTesteGenetico.anexos.push(new GedSimplificado_1.GedSimplificado(ged));
                                }
                            }
                            else if (ctrl.ehQualidadeMadeiraTesteGenetico) {
                                const tmpQualidadeMadeiraTesteGenetico = ((typeof ctrl.qualidadeMadeiraTesteGenetico !== 'undefined') && (ctrl.qualidadeMadeiraTesteGenetico !== null)) ? ctrl.qualidadeMadeiraTesteGenetico :
                                    ((typeof ctrl.qualidadeMadeiraTesteGenetico_base !== 'undefined') && (ctrl.qualidadeMadeiraTesteGenetico_base !== null)) ? ctrl.qualidadeMadeiraTesteGenetico_base : null;
                                if (tmpQualidadeMadeiraTesteGenetico) {
                                    if ((typeof tmpQualidadeMadeiraTesteGenetico.anexos === 'undefined') || (tmpQualidadeMadeiraTesteGenetico.anexos === null) || !Array.isArray(tmpQualidadeMadeiraTesteGenetico.anexos)) {
                                        tmpQualidadeMadeiraTesteGenetico.anexos = new Array();
                                    }
                                    tmpQualidadeMadeiraTesteGenetico.anexos.push(new GedSimplificado_1.GedSimplificado(ged));
                                }
                            }
                            else if (ctrl.ehAnexoTesteGenetico) {
                                const tmpAnexoTesteGenetico = ((typeof ctrl.anexoTesteGenetico !== 'undefined') && (ctrl.anexoTesteGenetico !== null)) ? ctrl.anexoTesteGenetico :
                                    ((typeof ctrl.anexoTesteGenetico_base !== 'undefined') && (ctrl.anexoTesteGenetico_base !== null)) ? ctrl.anexoTesteGenetico_base : null;
                                if (tmpAnexoTesteGenetico) {
                                    if ((typeof tmpAnexoTesteGenetico.anexos === 'undefined') || (tmpAnexoTesteGenetico.anexos === null) || !Array.isArray(tmpAnexoTesteGenetico.anexos)) {
                                        tmpAnexoTesteGenetico.anexos = new Array();
                                    }
                                    tmpAnexoTesteGenetico.anexos.push(new GedSimplificado_1.GedSimplificado(ged));
                                }
                            }
                            else if (ctrl.ehProtocoloTesteGenetico) {
                                const tmpProtocoloTesteGenetico = ((typeof ctrl.protocoloTesteGenetico !== 'undefined') && (ctrl.protocoloTesteGenetico !== null)) ? ctrl.protocoloTesteGenetico :
                                    ((typeof ctrl.protocoloTesteGenetico_base !== 'undefined') && (ctrl.protocoloTesteGenetico_base !== null)) ? ctrl.protocoloTesteGenetico_base : null;
                                if (tmpProtocoloTesteGenetico) {
                                    if ((typeof tmpProtocoloTesteGenetico.anexos === 'undefined') || (tmpProtocoloTesteGenetico.anexos === null) || !Array.isArray(tmpProtocoloTesteGenetico.anexos)) {
                                        tmpProtocoloTesteGenetico.anexos = new Array();
                                    }
                                    tmpProtocoloTesteGenetico.anexos.push(new GedSimplificado_1.GedSimplificado(ged));
                                }
                            }
                        }
                    }
                }));
            }
        });
    }
    removerFoto(fotoId) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!fotoId) {
                return;
            }
            const ctrl = this;
            if ((typeof this.fotosCamera !== 'undefined') && (this.fotosCamera !== null)) {
                angular.forEach(this.fotosCamera, fotoCamera => {
                    if (fotoCamera.uuid === fotoId) {
                        if (ctrl.ehApontamento) {
                            const tmpApontamento = ((typeof ctrl.apontamento !== 'undefined') && (ctrl.apontamento !== null)) ? ctrl.apontamento :
                                ((typeof ctrl.apontamento_base !== 'undefined') && (ctrl.apontamento_base !== null)) ? ctrl.apontamento_base : null;
                            if ((tmpApontamento && (typeof tmpApontamento.formularioIndicadores !== 'undefined') && tmpApontamento.formularioIndicadores)) {
                                if ((typeof fotoCamera.indiceCampoCabecalho !== 'undefined') && (fotoCamera.indiceCampoCabecalho !== null) &&
                                    (typeof tmpApontamento.formularioIndicadores.camposCabecalho !== 'undefined') && (tmpApontamento.formularioIndicadores.camposCabecalho !== null) &&
                                    (fotoCamera.indiceCampoCabecalho >= 0) && (fotoCamera.indiceCampoCabecalho <= tmpApontamento.formularioIndicadores.camposCabecalho.length - 1)) {
                                    let campo = tmpApontamento.formularioIndicadores.camposCabecalho[fotoCamera.indiceCampoCabecalho];
                                    if ((typeof campo.valor === 'undefined') || (campo.valor === null) || !Array.isArray(campo.valor)) {
                                        campo.valor = new Array();
                                    }
                                    campo.valor = campo.valor.filter((x) => (!x.uuid || (x.uuid !== fotoId)) && (!x.id || (x.id !== fotoId)));
                                }
                                if ((typeof fotoCamera.indiceIndicador !== 'undefined') && (fotoCamera.indiceIndicador !== null) &&
                                    (typeof tmpApontamento.formularioIndicadores.indicadores !== 'undefined') && (tmpApontamento.formularioIndicadores.indicadores !== null) &&
                                    (fotoCamera.indiceIndicador >= 0) && (fotoCamera.indiceIndicador <= tmpApontamento.formularioIndicadores.indicadores.length - 1)) {
                                    let indicador = tmpApontamento.formularioIndicadores.indicadores[fotoCamera.indiceIndicador];
                                    if ((typeof fotoCamera.indiceCampoIndicador !== 'undefined') && (fotoCamera.indiceCampoIndicador !== null) &&
                                        (typeof indicador.camposIndicador !== 'undefined') && (indicador.camposIndicador !== null) &&
                                        (fotoCamera.indiceCampoIndicador >= 0) && (fotoCamera.indiceCampoIndicador <= indicador.camposIndicador.length - 1)) {
                                        let campo = indicador.camposIndicador[fotoCamera.indiceCampoIndicador];
                                        if ((typeof campo.valor === 'undefined') || (campo.valor === null) || !Array.isArray(campo.valor)) {
                                            campo.valor = new Array();
                                        }
                                        campo.valor = campo.valor.filter((x) => (!x.uuid || (x.uuid !== fotoId)) && (!x.id || (x.id !== fotoId)));
                                    }
                                }
                                if ((typeof fotoCamera.indiceCampoRodape !== 'undefined') && (fotoCamera.indiceCampoRodape !== null) &&
                                    (typeof tmpApontamento.formularioIndicadores.camposRodape !== 'undefined') && (tmpApontamento.formularioIndicadores.camposRodape !== null) &&
                                    (fotoCamera.indiceCampoRodape >= 0) && (fotoCamera.indiceCampoRodape <= tmpApontamento.formularioIndicadores.camposRodape.length - 1)) {
                                    let campo = tmpApontamento.formularioIndicadores.camposRodape[fotoCamera.indiceCampoRodape];
                                    if ((typeof campo.valor === 'undefined') || (campo.valor === null) || !Array.isArray(campo.valor)) {
                                        campo.valor = new Array();
                                    }
                                    campo.valor = campo.valor.filter((x) => (!x.uuid || (x.uuid !== fotoId)) && (!x.id || (x.id !== fotoId)));
                                }
                            }
                        }
                        else if (ctrl.ehControleManejoPomar) {
                            const tmpControleManejoPomar = ((typeof ctrl.controleManejoPomar !== 'undefined') && (ctrl.controleManejoPomar !== null)) ? ctrl.controleManejoPomar :
                                ((typeof ctrl.controleManejoPomar_base !== 'undefined') && (ctrl.controleManejoPomar_base !== null)) ? ctrl.controleManejoPomar_base : null;
                            if (tmpControleManejoPomar) {
                                if ((typeof tmpControleManejoPomar.anexos === 'undefined') || (tmpControleManejoPomar.anexos === null) || !Array.isArray(tmpControleManejoPomar.anexos)) {
                                    tmpControleManejoPomar.anexos = new Array();
                                }
                                tmpControleManejoPomar.anexos = tmpControleManejoPomar.anexos.filter((x) => (!x.uuid || (x.uuid !== fotoId)) && (!x.id || (x.id !== fotoId)));
                            }
                        }
                        else if (ctrl.ehAnexoPomar) {
                            const tmpAnexoPomar = ((typeof ctrl.anexoPomar !== 'undefined') && (ctrl.anexoPomar !== null)) ? ctrl.anexoPomar :
                                ((typeof ctrl.anexoPomar_base !== 'undefined') && (ctrl.anexoPomar_base !== null)) ? ctrl.anexoPomar_base : null;
                            if (tmpAnexoPomar) {
                                if ((typeof tmpAnexoPomar.anexos === 'undefined') || (tmpAnexoPomar.anexos === null) || !Array.isArray(tmpAnexoPomar.anexos)) {
                                    tmpAnexoPomar.anexos = new Array();
                                }
                                tmpAnexoPomar.anexos = tmpAnexoPomar.anexos.filter((x) => (!x.uuid || (x.uuid !== fotoId)) && (!x.id || (x.id !== fotoId)));
                            }
                        }
                        else if (ctrl.ehControleManejoTesteGenetico) {
                            const tmpControleManejoTesteGenetico = ((typeof ctrl.controleManejoTesteGenetico !== 'undefined') && (ctrl.controleManejoTesteGenetico !== null)) ? ctrl.controleManejoTesteGenetico :
                                ((typeof ctrl.controleManejoTesteGenetico_base !== 'undefined') && (ctrl.controleManejoTesteGenetico_base !== null)) ? ctrl.controleManejoTesteGenetico_base : null;
                            if (tmpControleManejoTesteGenetico) {
                                if ((typeof tmpControleManejoTesteGenetico.anexos === 'undefined') || (tmpControleManejoTesteGenetico.anexos === null) || !Array.isArray(tmpControleManejoTesteGenetico.anexos)) {
                                    tmpControleManejoTesteGenetico.anexos = new Array();
                                }
                                tmpControleManejoTesteGenetico.anexos = tmpControleManejoTesteGenetico.anexos.filter((x) => (!x.uuid || (x.uuid !== fotoId)) && (!x.id || (x.id !== fotoId)));
                            }
                        }
                        else if (ctrl.ehQualidadeMadeiraTesteGenetico) {
                            const tmpQualidadeMadeiraTesteGenetico = ((typeof ctrl.qualidadeMadeiraTesteGenetico !== 'undefined') && (ctrl.qualidadeMadeiraTesteGenetico !== null)) ? ctrl.qualidadeMadeiraTesteGenetico :
                                ((typeof ctrl.qualidadeMadeiraTesteGenetico_base !== 'undefined') && (ctrl.qualidadeMadeiraTesteGenetico_base !== null)) ? ctrl.qualidadeMadeiraTesteGenetico_base : null;
                            if (tmpQualidadeMadeiraTesteGenetico) {
                                if ((typeof tmpQualidadeMadeiraTesteGenetico.anexos === 'undefined') || (tmpQualidadeMadeiraTesteGenetico.anexos === null) || !Array.isArray(tmpQualidadeMadeiraTesteGenetico.anexos)) {
                                    tmpQualidadeMadeiraTesteGenetico.anexos = new Array();
                                }
                                tmpQualidadeMadeiraTesteGenetico.anexos = tmpQualidadeMadeiraTesteGenetico.anexos.filter((x) => (!x.uuid || (x.uuid !== fotoId)) && (!x.id || (x.id !== fotoId)));
                            }
                        }
                        else if (ctrl.ehAnexoTesteGenetico) {
                            const tmpAnexoTesteGenetico = ((typeof ctrl.anexoTesteGenetico !== 'undefined') && (ctrl.anexoTesteGenetico !== null)) ? ctrl.anexoTesteGenetico :
                                ((typeof ctrl.anexoTesteGenetico_base !== 'undefined') && (ctrl.anexoTesteGenetico_base !== null)) ? ctrl.anexoTesteGenetico_base : null;
                            if (tmpAnexoTesteGenetico) {
                                if ((typeof tmpAnexoTesteGenetico.anexos === 'undefined') || (tmpAnexoTesteGenetico.anexos === null) || !Array.isArray(tmpAnexoTesteGenetico.anexos)) {
                                    tmpAnexoTesteGenetico.anexos = new Array();
                                }
                                tmpAnexoTesteGenetico.anexos = tmpAnexoTesteGenetico.anexos.filter((x) => (!x.uuid || (x.uuid !== fotoId)) && (!x.id || (x.id !== fotoId)));
                            }
                        }
                        else if (ctrl.ehProtocoloTesteGenetico) {
                            const tmpProtocoloTesteGenetico = ((typeof ctrl.protocoloTesteGenetico !== 'undefined') && (ctrl.protocoloTesteGenetico !== null)) ? ctrl.protocoloTesteGenetico :
                                ((typeof ctrl.protocoloTesteGenetico_base !== 'undefined') && (ctrl.protocoloTesteGenetico_base !== null)) ? ctrl.protocoloTesteGenetico_base : null;
                            if (tmpProtocoloTesteGenetico) {
                                if ((typeof tmpProtocoloTesteGenetico.anexos === 'undefined') || (tmpProtocoloTesteGenetico.anexos === null) || !Array.isArray(tmpProtocoloTesteGenetico.anexos)) {
                                    tmpProtocoloTesteGenetico.anexos = new Array();
                                }
                                tmpProtocoloTesteGenetico.anexos = tmpProtocoloTesteGenetico.anexos.filter((x) => (!x.uuid || (x.uuid !== fotoId)) && (!x.id || (x.id !== fotoId)));
                            }
                        }
                    }
                });
                this.fotosCamera = this.fotosCamera.filter(x => x.uuid !== fotoId);
            }
        });
    }
    verFoto(foto) {
        return __awaiter(this, void 0, void 0, function* () {
            DispositivoUtil_1.DispositivoUtil.verFoto(foto);
        });
    }
}
exports.CampoIndicadorFotos = CampoIndicadorFotos;
