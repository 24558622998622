"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Inicio = void 0;
const AppModulo_1 = require("./angularjs/modulos/app/AppModulo");
class Inicio {
    constructor() {
        document.addEventListener('deviceready', this.dispositivoCarregado, false);
        document.addEventListener('pause', this.dispositivoPausado, false);
        document.addEventListener('resume', this.dispositivoEmResumo, false);
    }
    angularJS() {
        AppModulo_1.AppModulo.iniciar();
    }
    react() {
    }
    angular9() {
    }
    dispositivoCarregado() {
    }
    dispositivoPausado() {
    }
    dispositivoEmResumo() {
    }
}
exports.Inicio = Inicio;
let iniciar = new Inicio();
iniciar.angularJS();
