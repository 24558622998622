"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabUmidadePolen = void 0;
const Base_1 = require("./Base");
class LabUmidadePolen extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.data = null;
        this.estoque = null;
        this.pomar = null;
        this.genero = null;
        this.especie = null;
        this.materialGenetico = null;
        this.amostras = [];
        this.umidadeFinal = null;
    }
}
exports.LabUmidadePolen = LabUmidadePolen;
LabUmidadePolen.ESTOQUE_OPERACIONAL = 'Operacional';
LabUmidadePolen.ESTOQUE_PESQUISA = 'Pesquisa';
