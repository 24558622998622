"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LancamentoPolinizacaoPomar = void 0;
const Base_1 = require("./Base");
const uuid_1 = require("uuid");
class LancamentoPolinizacaoPomar extends Base_1.Base {
    constructor() {
        super();
        this.id = null;
        this.guid = null;
        this.ehPinus = null;
        this.ehEucalyptus = null;
        this.numGalhos = null;
        this.numBotoes = null;
        this.dataInicial = null;
        this.dataFinal = null;
        this.dataRetiradaPacote = null;
        this.polenAno = null;
        this.data = null;
        this.pomarOrigem = null;
        this.materialGeneticoGenitor = null;
        this.hibridacao = null;
        this.hibrido = null;
        this.cruzamento = null;
        this.guid = uuid_1.v4();
        this.status = true;
    }
}
exports.LancamentoPolinizacaoPomar = LancamentoPolinizacaoPomar;
