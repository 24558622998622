"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MedicaoDendrometricaTesteGenetico = void 0;
const Base_1 = require("./Base");
class MedicaoDendrometricaTesteGenetico extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.id = null;
        this.codigo = null;
        this.testeGenetico = null;
        this.regiao = null;
        this.talhao = null;
        this.descricao = null;
        this.anoAnalise = null;
        this.bloco = null;
        this.parc = null;
        this.trat = null;
        this.arv = null;
        this.dap = null;
        this.alt = null;
        this.forma = null;
        this.for = null;
        this.bif = null;
        this.cq = null;
        this.ft = null;
        this.volume = null;
        this.medicoes = [];
        this.planilhas = [];
        this.planilhaSelecionada = null;
        this.anexos = [];
        this.observacoes = null;
        this.usuarioInclusao = null;
        this.dataHoraInclusao = null;
        this.usuarioAlteracao = null;
        this.dataHoraAlteracao = null;
        this.anosMedicao = [];
    }
}
exports.MedicaoDendrometricaTesteGenetico = MedicaoDendrometricaTesteGenetico;
