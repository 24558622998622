"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificacaoFactory = void 0;
const NotificacaoUtil_1 = require("../../util/NotificacaoUtil");
class NotificacaoFactory {
    erro(titulo, mensagem = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return NotificacaoUtil_1.NotificacaoUtil.erro(titulo, mensagem);
        });
    }
    excecao(titulo, mensagem = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return NotificacaoUtil_1.NotificacaoUtil.excecao(titulo, mensagem);
        });
    }
    alerta(titulo, mensagem = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return NotificacaoUtil_1.NotificacaoUtil.alerta(titulo, mensagem);
        });
    }
    erroDesenvolvimento(titulo, mensagem = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return NotificacaoUtil_1.NotificacaoUtil.erroDesenvolvimento(titulo, mensagem);
        });
    }
    info(titulo, mensagem = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return NotificacaoUtil_1.NotificacaoUtil.info(titulo, mensagem);
        });
    }
    sucesso(titulo, mensagem = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return NotificacaoUtil_1.NotificacaoUtil.sucesso(titulo, mensagem);
        });
    }
    servidor(resposta) {
        return __awaiter(this, void 0, void 0, function* () {
            return NotificacaoUtil_1.NotificacaoUtil.servidor(resposta);
        });
    }
    confirmacao(titulo, mensagem = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return NotificacaoUtil_1.NotificacaoUtil.confirmacao(titulo, mensagem);
        });
    }
    confirmacaoSucesso(titulo, mensagem = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return NotificacaoUtil_1.NotificacaoUtil.confirmacaoSucesso(titulo, mensagem);
        });
    }
}
exports.NotificacaoFactory = NotificacaoFactory;
NotificacaoFactory.id = NotificacaoFactory.name;
