"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CargaPlanilha = void 0;
class CargaPlanilha {
    constructor() {
        this.tipo = null;
        this.planilhas = null;
    }
}
exports.CargaPlanilha = CargaPlanilha;
