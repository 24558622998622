"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnxertoPomar = void 0;
const Base_1 = require("./Base");
const uuid_1 = require("uuid");
class EnxertoPomar extends Base_1.Base {
    constructor() {
        super();
        this.id = null;
        this.guid = null;
        this.materialGenetico = null;
        this.fila = null;
        this.coluna = null;
        this.posicoesRealizadas = null;
        this.sobrevivencia = null;
        this.sobrevivencia60dias = null;
        this.sobrevivencia90dias = null;
        this.plantio = null;
        this.replantio = null;
        this.numClones = null;
        this.numRametes = null;
        this.guid = uuid_1.v4();
        this.status = true;
    }
}
exports.EnxertoPomar = EnxertoPomar;
