"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiltroRelatorioTesteGenetico = void 0;
class FiltroRelatorioTesteGenetico {
    constructor() {
        this.tipoTeste = null;
        this.situacao = null;
        this.genero = null;
        this.populacao = null;
        this.ano = null;
        this.conjunto = null;
        this.origem = null;
        this.status = null;
        this.especie = null;
        this.fazenda = null;
        this.pomar = null;
    }
}
exports.FiltroRelatorioTesteGenetico = FiltroRelatorioTesteGenetico;
