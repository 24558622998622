"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InicioController = void 0;
const config_app_1 = require("../../../../../config/config.app");
const AbstratoController_1 = require("../../AbstratoController");
class InicioController extends AbstratoController_1.AbstratoController {
    constructor($scope, $injector) {
        super($scope, $injector);
        this.log = null;
        this.empresaLogada = null;
        this.logoSrc = null;
        this.logoAlt = null;
        this.logoWidth = null;
        this.logoStyle = null;
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof config_app_1.config.empresa === 'undefined') || !config_app_1.config.empresa) {
                config_app_1.config.carregarEmpresaLogada();
            }
            this.logoSrc = 'assets/img/logo_ani_1800x628.png';
            this.logoAlt = 'Logo ANI Sistemas';
            this.logoWidth = this.ehCelular ? 250 : 365;
            this.logoStyle = 'padding-top: 100px;';
            if ((typeof config_app_1.config.empresa !== 'undefined') && config_app_1.config.empresa) {
                this.empresaLogada = config_app_1.config.empresa;
                if ((typeof config_app_1.config.empresa.logoInicio !== 'undefined') && config_app_1.config.empresa.logoInicio &&
                    (typeof config_app_1.config.empresa.logoInicio.src !== 'undefined') && config_app_1.config.empresa.logoInicio.src) {
                    this.logoSrc = config_app_1.config.empresa.logoInicio.src;
                    this.logoAlt = 'Logo ' + (config_app_1.config.empresa.nomeCompleto ? config_app_1.config.empresa.nomeCompleto : (config_app_1.config.empresa.nomeResumido ? config_app_1.config.empresa.nomeResumido : ''));
                    this.logoWidth = this.ehCelular ? (config_app_1.config.empresa.logoInicio.widthCelular ? config_app_1.config.empresa.logoInicio.widthCelular : 250) : (config_app_1.config.empresa.logoInicio.widthDesktop ? config_app_1.config.empresa.logoInicio.widthDesktop : 365);
                    this.logoStyle = config_app_1.config.empresa.logoInicio.style ? config_app_1.config.empresa.logoInicio.style : 'padding-top: 100px;';
                }
            }
            setTimeout(function (controller) {
                return __awaiter(this, void 0, void 0, function* () {
                    let listaModulosPermitidos = new Array();
                    if ((typeof controller.usuarioLogado !== 'undefined') && (controller.usuarioLogado !== null)) {
                        for (let key of Object.keys(controller.usuarioLogado)) {
                            if (key.toLowerCase().indexOf('acessa') !== -1) {
                                if (controller.usuarioLogado[key] === true) {
                                    listaModulosPermitidos.push(key);
                                }
                            }
                        }
                    }
                    if (listaModulosPermitidos.length === 1) {
                        if (listaModulosPermitidos[0].trim().toLowerCase() === 'apontamentoacessa') {
                            controller.$state.go('apontamento.apontamento-listar');
                        }
                    }
                });
            }, 250, this);
            this.$scope.$apply();
        });
    }
    salvar() {
        return __awaiter(this, void 0, void 0, function* () {
        });
    }
    visualizar() {
        return __awaiter(this, void 0, void 0, function* () {
            return null;
        });
    }
    remover() {
        return __awaiter(this, void 0, void 0, function* () {
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
        });
    }
    dadosLista() {
        return __awaiter(this, void 0, void 0, function* () {
            return null;
        });
    }
}
exports.InicioController = InicioController;
InicioController.$inject = ['$scope', '$injector'];
