"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UploadController = void 0;
const angular = require("angular");
const Dropzone = require("dropzone");
const DialogoFactory_1 = require("../../../../fabricas/DialogoFactory");
const NotificacaoFactory_1 = require("../../../../fabricas/NotificacaoFactory");
const RestFactory_1 = require("../../../../fabricas/RestFactory");
require("dropzone/dist/min/dropzone.min.css");
class UploadController {
    constructor($scope, notificacao) {
        this.$scope = null;
        this.dropzone = null;
        this.notificacao = null;
        this.id = null;
        this.lista = [];
        this.titulo = null;
        this.planilhas = null;
        this.imagens = null;
        this.mapas = null;
        this.arquivosPermitidos = null;
        this.blockWatch = false;
        this.$scope = $scope;
        this.notificacao = notificacao;
    }
    $postLink() {
        setTimeout((ctrl) => {
            ctrl.carregarTitulos();
        }, 500, this);
        setTimeout(UploadController.carregarDropzone, 1000, this);
    }
    enviarArquivo(file, done) {
        done(file);
        this.blockWatch = true;
    }
    carregarTitulos() {
        let permitePlanilhas = true;
        if ((typeof this.planilhas !== 'undefined') && (this.planilhas !== null)) {
            if (typeof this.planilhas === 'boolean') {
                permitePlanilhas = this.planilhas ? true : false;
            }
            else if (typeof this.planilhas === 'string') {
                let v = this.planilhas.trim().toLowerCase();
                permitePlanilhas = ((v === 'false') || (v === '0')) ? false : true;
            }
        }
        let permiteImagens = true;
        if ((typeof this.imagens !== 'undefined') && (this.imagens !== null)) {
            if (typeof this.imagens === 'boolean') {
                permiteImagens = this.imagens ? true : false;
            }
            else if (typeof this.imagens === 'string') {
                let v = this.imagens.trim().toLowerCase();
                permiteImagens = ((v === 'false') || (v === '0')) ? false : true;
            }
        }
        let permiteMapas = true;
        if ((typeof this.mapas !== 'undefined') && (this.mapas !== null)) {
            if (typeof this.mapas === 'boolean') {
                permiteMapas = this.mapas ? true : false;
            }
            else if (typeof this.mapas === 'string') {
                let v = this.mapas.trim().toLowerCase();
                permiteMapas = ((v === 'false') || (v === '0')) ? false : true;
            }
        }
        let textoTitulo = this.titulo ? `${this.titulo}` : '';
        let textoArquivosPermitidos = this.arquivosPermitidos ? `${this.arquivosPermitidos}` : '';
        if (permitePlanilhas && permiteImagens && permiteMapas) {
            textoTitulo = 'Anexar arquivos, mapas (ArcGIS) e fotos';
            textoArquivosPermitidos = '(são permitidos imagens, arquivos PDF, mapas ArcGIS e documentos ou planilhas do Office)';
        }
        else if (permitePlanilhas && permiteImagens) {
            textoTitulo = 'Anexar arquivos (documentos) e fotos';
            textoArquivosPermitidos = '(são permitidos imagens, arquivos PDF e documentos ou planilhas do Office)';
        }
        else if (permitePlanilhas && permiteMapas) {
            textoTitulo = 'Anexar arquivos (documentos) e mapas (ArcGIS)';
            textoArquivosPermitidos = '(são permitidos documentos (PDF) ou planilhas do Office e mapas ArcGIS)';
        }
        else if (permiteImagens && permiteMapas) {
            textoTitulo = 'Anexar mapas (ArcGIS) e fotos';
            textoArquivosPermitidos = '(são permitidos imagens, arquivos PDF e mapas ArcGIS';
        }
        else if (permitePlanilhas) {
            textoTitulo = 'Anexar arquivos (documentos)';
            textoArquivosPermitidos = '(são permitidos arquivos PDF e documentos ou planilhas do Office)';
        }
        else if (permiteImagens) {
            textoTitulo = 'Anexar fotos';
            textoArquivosPermitidos = '(são permitidos imagens (JPEG,PNG,GIF,PDF))';
        }
        else if (permiteMapas) {
            textoTitulo = 'Anexar mapas (ArcGIS)';
            textoArquivosPermitidos = '(são permitidos mapas ArcGIS e documentos de mapas)';
        }
        else {
            textoTitulo = 'Anexar arquivos, mapas (ArcGIS) e fotos';
            textoArquivosPermitidos = '(são permitidos imagens, arquivos PDF, mapas ArcGIS e documentos ou planilhas do Office)';
        }
        if ((textoTitulo !== (this.titulo ? `${this.titulo}` : '')) ||
            (textoArquivosPermitidos !== (this.arquivosPermitidos ? `${this.arquivosPermitidos}` : ''))) {
            this.titulo = textoTitulo;
            this.arquivosPermitidos = textoArquivosPermitidos;
            this.$scope.$apply();
        }
    }
    atualizarLista() {
        const ctrl = this;
        if (!ctrl.blockWatch) {
            ctrl.carregarTitulos();
            if (ctrl.lista && (ctrl.lista.length > 0) && ctrl.dropzone) {
                angular.forEach(ctrl.lista, (anexo, keyAnexo) => {
                    let url = RestFactory_1.RestFactory.rota(`ferramenta/ged/load/${anexo.id}`);
                    if ((typeof anexo.nome === 'undefined') || !anexo.nome) {
                        if ((typeof anexo.uuid !== 'undefined') && anexo.uuid) {
                            anexo.nome = '' + anexo.uuid;
                        }
                        else if ((typeof anexo.guid !== 'undefined') && anexo.guid) {
                            anexo.nome = '' + anexo.guid;
                        }
                        else if ((typeof anexo.id !== 'undefined') && anexo.id) {
                            anexo.nome = '' + anexo.id;
                        }
                        else {
                            anexo.nome = '';
                        }
                    }
                    const indiceAnexo = keyAnexo + 1;
                    if (anexo.nome.indexOf(`(${indiceAnexo})`) === -1) {
                        anexo.nome += ` (${indiceAnexo})`;
                    }
                    if ((typeof anexo.tamanho === 'undefined') || !anexo.tamanho) {
                        anexo.tamanho = 32000;
                    }
                    const file = {
                        name: anexo.nome,
                        size: anexo.tamanho,
                        index: anexo.id
                    };
                    let arquivoJaAdicionado = false;
                    if (ctrl.dropzone.element) {
                        const dropzoneElement = angular.element(ctrl.dropzone.element);
                        dropzoneElement.find('.dz-preview').each((_index, el) => {
                            if (!arquivoJaAdicionado) {
                                const elDataLink = angular.element(el).attr('data-link');
                                if ((typeof elDataLink !== 'undefined') && elDataLink) {
                                    if (elDataLink === url) {
                                        arquivoJaAdicionado = true;
                                    }
                                }
                                else {
                                    const elFileName = angular.element(el).find('.dz-details > .dz-filename').first().text();
                                    if ((typeof elFileName !== 'undefined') && elFileName) {
                                        if (elFileName === anexo.nome) {
                                            arquivoJaAdicionado = true;
                                        }
                                    }
                                }
                            }
                        });
                    }
                    if (!arquivoJaAdicionado) {
                        ctrl.dropzone.displayExistingFile(file, url, () => { }, 'anonymous', true);
                    }
                });
            }
        }
        else {
            ctrl.blockWatch = false;
        }
    }
    static carregarDropzone(ctrl) {
        try {
            let idUnico = (ctrl.id) ? '#' + ctrl.id + '-dropzone-area' : '#dropzone-area';
            const endPoint = 'ferramenta/ged/file';
            ctrl.dropzone = new Dropzone(idUnico, {
                url: RestFactory_1.RestFactory.rota(endPoint),
                addRemoveLinks: true,
                dictRemoveFile: 'Remover Arquivo'
            });
            ctrl.dropzone.options.url = RestFactory_1.RestFactory.rota(endPoint);
            ctrl.dropzone.options.addRemoveLinks = true;
            ctrl.dropzone.options.dictRemoveFile = 'Remover Arquivo';
            ctrl.dropzone.options.transformFile = ctrl.enviarArquivo.bind(ctrl);
            ctrl.dropzone.on('success', (file, response) => {
                ctrl.lista = ctrl.lista ? ctrl.lista : [];
                const ged = response.dados;
                if (ged) {
                    ctrl.lista = ctrl.lista ? ctrl.lista : [];
                    ctrl.lista.push(ged);
                    file.dataURL = RestFactory_1.RestFactory.rota(`ferramenta/ged/load/${ged.id}`);
                    ctrl.$scope.$apply();
                    ctrl.dropzone.emit('thumbnail', file, file.dataURL);
                }
                else {
                    console.log('Arquivo não foi salvo!');
                    return;
                }
            });
            ctrl.dropzone.on('thumbnail', (file) => {
                let el = angular.element(file.previewElement);
                el.attr('data-link', ((typeof file.dataURL !== 'undefined') && file.dataURL ? file.dataURL : ''));
                el.css('cursor', 'pointer');
                el.find('.dz-image').css('cursor', 'pointer');
                el.find('.dz-details').css('cursor', 'pointer');
                el.find('.dz-details .dz-size, .dz-details .dz-filename').css('cursor', 'pointer');
                let fileName = null;
                if ((typeof file.name !== 'undefined') && file.name && (file.name.length > 0)) {
                    fileName = file.name;
                }
                else {
                    if ((typeof file.dataURL !== 'undefined') && file.dataURL) {
                        fileName = file.dataURL.split(/\/|\\/).pop();
                    }
                }
                let ext = fileName ? fileName.split('.').pop() : '';
                if (ext && (ext === 'pdf')) {
                    el.find('.dz-image img').attr('src', './assets/img/pdf.png').css('max-width', '100%');
                }
                else if (ext && (ext.indexOf('doc') !== -1)) {
                    el.find('.dz-image img').attr('src', './assets/img/word.png').css('max-width', '100%');
                }
                else if (ext && (ext.indexOf('xls') !== -1)) {
                    el.find('.dz-image img').attr('src', './assets/img/excel.png').css('max-width', '100%');
                }
                else if (ext && (ext.indexOf('ppt') !== -1)) {
                    el.find('.dz-image img').attr('src', './assets/img/power_point.png').css('max-width', '100%');
                }
                file.previewElement.addEventListener('click', (e) => {
                    const link = $(e.target).parents('.dz-preview').data('link');
                    if (link.search('data:image') > -1) {
                        var image = new Image();
                        image.src = link;
                        image.style.width = '100%';
                        const w = window.open('');
                        w.document.title = fileName;
                        w.document.write(image.outerHTML);
                    }
                    else {
                        window.open(link, '_blank');
                    }
                });
            });
            ctrl.dropzone.on('removedfile', (file) => {
                if (ctrl.lista) {
                    ctrl.blockWatch = true;
                    if (typeof file.index === 'undefined') {
                        ctrl.lista = ctrl.lista.filter(x => x.nome !== file.name);
                    }
                    else {
                        ctrl.lista = ctrl.lista.filter(x => x.id !== file.index);
                    }
                    ctrl.$scope.$apply();
                }
            });
            ctrl.blockWatch = true;
            ctrl.$scope.$watch('$ctrl.lista', ctrl.atualizarLista.bind(ctrl));
            ctrl.blockWatch = false;
            ctrl.carregarTitulos();
            ctrl.atualizarLista();
        }
        catch (ex) {
            console.log(ex);
        }
    }
}
exports.UploadController = UploadController;
UploadController.$inject = ['$scope', NotificacaoFactory_1.NotificacaoFactory.id, DialogoFactory_1.DialogoFactory.id];
