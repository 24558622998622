"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CarregamentoUtil = void 0;
const TextoUtil_1 = require("./TextoUtil");
class CarregamentoUtil {
    static travarLoad() {
        if ((typeof globalThis.carregamentoUtil === 'undefined') || (globalThis.carregamentoUtil === null)) {
            globalThis.carregamentoUtil = {};
        }
        globalThis.carregamentoUtil.loadTravado = true;
    }
    static destravarLoad() {
        if ((typeof globalThis.carregamentoUtil === 'undefined') || (globalThis.carregamentoUtil === null)) {
            globalThis.carregamentoUtil = {};
        }
        globalThis.carregamentoUtil.loadTravado = false;
    }
    static exibirLoad(seletor, nomeFuncaoOrigem = null) {
        if ((typeof globalThis.carregamentoUtil === 'undefined') || (globalThis.carregamentoUtil === null)) {
            globalThis.carregamentoUtil = {};
        }
        if ((typeof globalThis.carregamentoUtil.loadTravado !== 'undefined') && (globalThis.carregamentoUtil.loadTravado === true)) {
            return;
        }
        if ((typeof nomeFuncaoOrigem !== 'undefined') && nomeFuncaoOrigem) {
            if ((typeof globalThis.carregamentoUtil.funcoesOrigem === 'undefined') || (globalThis.carregamentoUtil.funcoesOrigem === null)) {
                globalThis.carregamentoUtil.funcoesOrigem = [];
            }
            const refFuncaoOrigem = TextoUtil_1.TextoUtil.converteTextoParaReferencia(nomeFuncaoOrigem);
            if (!globalThis.carregamentoUtil.funcoesOrigem.includes(refFuncaoOrigem)) {
                globalThis.carregamentoUtil.funcoesOrigem.push(refFuncaoOrigem);
            }
        }
        $(() => {
            $('div.carregador').remove();
            const elemento = seletor && ($(seletor).length > 0) ? $(seletor).first() :
                $('.content-body').length > 0 ? $('.content-body').first() :
                    $('.card').length > 0 ? $('.card').first() :
                        $(document.body);
            const carregador = $('<div></div>');
            carregador.addClass('carregador');
            carregador.width(elemento.width());
            carregador.height(elemento.height());
            if (seletor && (seletor === document.body)) {
                carregador.addClass('fixed');
                carregador.append('<div class="load-circle-back fixed"></div>');
                carregador.append('<div class="load-circle-side fixed"></div>');
            }
            else {
                carregador.append('<div class="load-circle-back"></div>');
                carregador.append('<div class="load-circle-side"></div>');
            }
            elemento.prepend(carregador);
        });
    }
    static removerLoad(nomeFuncaoOrigem = null) {
        if ((typeof globalThis.carregamentoUtil !== 'undefined') && (globalThis.carregamentoUtil !== null)) {
            if ((typeof globalThis.carregamentoUtil.loadTravado !== 'undefined') && (globalThis.carregamentoUtil.loadTravado === true)) {
                console.log('CarregamentoUtil.removerLoad() -> loadTravado = true;');
                setTimeout(CarregamentoUtil.removerLoad, 1500);
                return;
            }
            if ((typeof globalThis.carregamentoUtil.funcoesOrigem !== 'undefined') && globalThis.carregamentoUtil.funcoesOrigem) {
                if ((typeof nomeFuncaoOrigem !== 'undefined') && nomeFuncaoOrigem) {
                    if ((nomeFuncaoOrigem === '*') || (nomeFuncaoOrigem === '*.*')) {
                        globalThis.carregamentoUtil.funcoesOrigem = [];
                    }
                    else {
                        const refFuncaoOrigem = TextoUtil_1.TextoUtil.converteTextoParaReferencia(nomeFuncaoOrigem);
                        const idxFuncaoOrigem = globalThis.carregamentoUtil.funcoesOrigem.indexOf(refFuncaoOrigem);
                        if (idxFuncaoOrigem !== -1) {
                            globalThis.carregamentoUtil.funcoesOrigem.splice(idxFuncaoOrigem, 1);
                        }
                    }
                }
                if (globalThis.carregamentoUtil.funcoesOrigem.length > 0) {
                    console.log('CarregamentoUtil.removerLoad() -> funcoesOrigem.length = ' + globalThis.carregamentoUtil.funcoesOrigem.length + ';');
                    setTimeout(CarregamentoUtil.removerLoad, 1500);
                    return;
                }
            }
        }
        $(() => {
            const elCarregador = $('.carregador');
            if (elCarregador && (elCarregador.length > 0)) {
                elCarregador.remove();
                const elCard = $('.card');
                if (elCard && (elCard.length > 0)) {
                    elCard.first().trigger('focus');
                }
            }
        });
    }
}
exports.CarregamentoUtil = CarregamentoUtil;
