"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TratamentoPomar = void 0;
const Base_1 = require("./Base");
class TratamentoPomar extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.id = null;
        this.codigo = null;
        this.mes = null;
        this.ano = null;
        this.pomar = null;
        this.pomarCodigo = null;
        this.pomarDescricao = null;
        this.materialGenetico = null;
        this.materialGeneticoCodigo = null;
        this.materialGeneticoDescricao = null;
        this.regiao = null;
        this.talhao = null;
        this.tratamentos = [];
        this.generoDescricao = null;
        this.especieDescricao = null;
        this.geracaoDescricao = null;
        this.anexos = [];
        this.observacoes = null;
        this.descricaoResumida = null;
        this.descricaoDetalhada = null;
        this.usuarioInclusao = null;
        this.dataHoraInclusao = null;
        this.usuarioAlteracao = null;
        this.dataHoraAlteracao = null;
        this.mesDescricao = null;
        this.mesSigla = null;
    }
}
exports.TratamentoPomar = TratamentoPomar;
