"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DashboardController = exports.Dashboard = void 0;
const angular = require("angular");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const UiGridConstrutor_1 = require("../../../app/construtores/UiGridConstrutor");
const AbstratoController_1 = require("../../../app/AbstratoController");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const ICRUDOperacao_1 = require("../../../app/interfaces/ICRUDOperacao");
const config_app_1 = require("../../../../../config/config.app");
class Dashboard {
    constructor() {
        this.id = null;
    }
}
exports.Dashboard = Dashboard;
class DashboardController extends AbstratoController_1.AbstratoController {
    constructor($scope, $injector) {
        super($scope, $injector, new Dashboard());
        this.path = '';
        this.caminho = '';
        this.tipoOperacao = null;
        this.dashboardTitulo = null;
        this.dashboardUrl = null;
        this.tipoOperacao = ICRUDOperacao_1.CRUDOperacao;
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.path = config_app_1.config.api.caminho;
            this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.DASHBOARD;
            this.dashboardTitulo = 'Dashboard Material Genético';
            this.dashboardUrl = config_app_1.config.empresa.dashboard;
            setTimeout((ctrl) => {
                angular.element('#secaoDashboard').empty().append(`
                <iframe id="iframeDashboard" title="${ctrl.dashboardTitulo}" width="100%" height="500" src="${ctrl.dashboardUrl}" frameborder="0" allowFullScreen="true"></iframe>
            `);
            }, 500, this);
            this.$scope.$apply();
        });
    }
    salvar() {
        return __awaiter(this, void 0, void 0, function* () {
        });
    }
    visualizar(_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Dashboard();
        });
    }
    remover(_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return null;
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
            return null;
        });
    }
    dadosLista() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                this.path = config_app_1.config.api.caminho;
                this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.DASHBOARD;
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                const gridOption = {
                    data: [],
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
}
exports.DashboardController = DashboardController;
DashboardController.$inject = ['$scope', '$injector'];
