"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OperacaoManejoPomar = void 0;
const Base_1 = require("./Base");
class OperacaoManejoPomar extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.descricao = null;
        this.tipoOperacao = null;
        this.ehOperacaoLimpeza = null;
        this.ehOperacaoDesbaste = null;
        this.ehOperacaoPolinizacao = null;
        this.ehOperacaoColetaAmostras = null;
        this.ehOperacaoAdubacaoFertilizacao = null;
        this.ehOperacaoColetaSementes = null;
        this.ehOperacaoControleDoencas = null;
        this.ehOperacaoPodaRamosPortaEnxerto = null;
        this.ehOperacaoAvaliacaoSobrevivencia = null;
        this.ehOperacaoReplantio = null;
        this.ehOutrasOperacoes = null;
    }
}
exports.OperacaoManejoPomar = OperacaoManejoPomar;
OperacaoManejoPomar.OPERACAO_LIMPEZA = 'limpeza';
OperacaoManejoPomar.OPERACAO_DESBASTE = 'desbaste';
OperacaoManejoPomar.OPERACAO_POLINIZACAO = 'polinizacao';
OperacaoManejoPomar.OPERACAO_COLETA_AMOSTRAS = 'coleta_amostras';
OperacaoManejoPomar.OPERACAO_ADUBACAO_FERTILIZACAO = 'adubacao_fertilizacao';
OperacaoManejoPomar.OPERACAO_COLETA_SEMENTES = 'coleta_sementes';
OperacaoManejoPomar.OPERACAO_CONTROLE_DOENCAS = 'controle_doencas';
OperacaoManejoPomar.OPERACAO_PODA_RAMOS_PORTA_ENXERTO = 'poda_ramos_porta_enxerto';
OperacaoManejoPomar.OPERACAO_AVALIACAO_SOBREVIVENCIA = 'avaliacao_sobrevivencia';
OperacaoManejoPomar.OPERACAO_REPLANTIO = 'replantio';
OperacaoManejoPomar.OUTRAS_OPERACOES = 'outras_operacoes';
