"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PolinizacaoPomarSimplificado = void 0;
const Base_1 = require("./Base");
class PolinizacaoPomarSimplificado extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.id = null;
        this.codigo = null;
        this.data = null;
        this.descricao = null;
        this.pomar = null;
        this.tipoPlanejamento = null;
        this.materialGeneticoFeminino = null;
        this.pomarOrigemPolen = null;
        this.pomarOrigemPolenCodigo = null;
        this.pomarOrigemPolenDescricao = null;
        this.materialGeneticoMasculino = null;
        this.especieMaterialGeneticoMasculino = null;
        this.planejamentosPolinizacaoPomar = [];
    }
}
exports.PolinizacaoPomarSimplificado = PolinizacaoPomarSimplificado;
