"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FenologiaPomarController = exports.FiltroMovimentacaoEstoqueTratado = exports.FiltroMovimentacaoEstoque = void 0;
const angular = require("angular");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const UiGridConstrutor_1 = require("../../../app/construtores/UiGridConstrutor");
const AbstratoController_1 = require("../../../app/AbstratoController");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const ICRUDOperacao_1 = require("../../../app/interfaces/ICRUDOperacao");
const ConfiguracaoGeral_1 = require("../../../../../modelos/ConfiguracaoGeral");
const MovimentacaoEstoque_1 = require("../../../../../modelos/MovimentacaoEstoque");
const TipoProdutoEstoque_1 = require("../../../../../modelos/TipoProdutoEstoque");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const DispositivoUtil_1 = require("../../../../../util/DispositivoUtil");
const DataUtil_1 = require("../../../../../util/DataUtil");
const config_app_1 = require("../../../../../config/config.app");
const sweetalert2_1 = require("sweetalert2");
class FiltroMovimentacaoEstoque {
    constructor() {
        this.produto = null;
        this.dataIni = null;
        this.dataFim = null;
        this.lote = null;
        this.cliente = null;
        this.produto = TipoProdutoEstoque_1.TipoProdutoEstoque.MUDAS();
        this.dataIni = new Date();
        this.dataIni.setDate(this.dataIni.getDate() - 7);
        this.dataFim = new Date();
    }
}
exports.FiltroMovimentacaoEstoque = FiltroMovimentacaoEstoque;
class FiltroMovimentacaoEstoqueTratado {
    constructor() {
        this.produto = null;
        this.dataIni = null;
        this.dataFim = null;
        this.lote = null;
        this.cliente = null;
    }
}
exports.FiltroMovimentacaoEstoqueTratado = FiltroMovimentacaoEstoqueTratado;
class FenologiaPomarController extends AbstratoController_1.AbstratoController {
    constructor($scope, $injector) {
        super($scope, $injector, new MovimentacaoEstoque_1.MovimentacaoEstoque());
        this.path = '';
        this.caminho = '';
        this.tipoOperacao = null;
        this.configuracaoGeral = null;
        this.filtro = null;
        this.produtos = null;
        this.mostrarFiltroLotes = null;
        this.lotes = [];
        this.mostrarFiltroClientes = null;
        this.clientes = [];
        this.tipoOperacao = ICRUDOperacao_1.CRUDOperacao;
        this.filtro = new FiltroMovimentacaoEstoque();
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.path = config_app_1.config.api.caminho;
            this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.MOVIMENTACAO_ESTOQUE;
            if (!this.filtro) {
                this.filtro = new FiltroMovimentacaoEstoque();
            }
            const listaConfiguracoes = yield this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.PARAMETRIZACAO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PARAMETRIZACAO)
                .filtro({ permiteDadosOffline: true })
                .listar();
            if ((typeof listaConfiguracoes !== 'undefined') && (listaConfiguracoes !== null) && (listaConfiguracoes.length > 0)) {
                this.configuracaoGeral = listaConfiguracoes[0];
            }
            else {
                this.configuracaoGeral = new ConfiguracaoGeral_1.ConfiguracaoGeral();
            }
            this.produtos = new Array();
            const listaTiposProdutos = TipoProdutoEstoque_1.TipoProdutoEstoque.LISTA();
            angular.forEach(listaTiposProdutos, (tipoProduto) => {
                ctrl.produtos.push(tipoProduto);
            });
            this.lotes = new Array();
            const promiseLotes = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.LOTE)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LOTE)
                .filtro({ permiteDadosOffline: true, resumido: true, listaSimplificadaOnline: true })
                .listar();
            promiseLotes.then((resposta) => {
                this.lotes.splice(0, this.lotes.length);
                this.lotes.push(...resposta);
                this.validarExibicaoCamposFiltro();
                ctrl.scopeApply();
            });
            this.clientes = new Array();
            const promiseClientes = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.CLIENTE)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CLIENTE)
                .filtro({ permiteDadosOffline: true, resumido: true, listaSimplificadaOnline: true })
                .listar();
            promiseClientes.then((resposta) => {
                this.clientes.splice(0, this.clientes.length);
                this.clientes.push(...resposta);
                this.validarExibicaoCamposFiltro();
                ctrl.scopeApply();
            });
            this.validarExibicaoCamposFiltro();
            this.$scope.$apply();
        });
    }
    salvar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.MOVIMENTACAO_ESTOQUE)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MOVIMENTACAO_ESTOQUE)
                        .criar(this.modelo);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.MOVIMENTACAO_ESTOQUE)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MOVIMENTACAO_ESTOQUE)
                        .atualizar(this.modelo);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir uma nova Movimentação de Estoque ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir uma nova Movimentação de Estoque ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não'
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    visualizar(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const movimentacaoEstoque = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.MOVIMENTACAO_ESTOQUE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MOVIMENTACAO_ESTOQUE)
                    .id(id)
                    .ver();
                movimentacaoEstoque.criacao = new Date(movimentacaoEstoque.criacao);
                if ((typeof movimentacaoEstoque.dataHoraApontamento !== 'undefined') && movimentacaoEstoque.dataHoraApontamento) {
                    movimentacaoEstoque.dataHoraApontamento = new Date(movimentacaoEstoque.dataHoraApontamento);
                }
                else if ((typeof movimentacaoEstoque.criacao !== 'undefined') && movimentacaoEstoque.criacao) {
                    movimentacaoEstoque.dataHoraApontamento = new Date(movimentacaoEstoque.criacao);
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return movimentacaoEstoque;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    remover(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const movimentacaoEstoque = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.MOVIMENTACAO_ESTOQUE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MOVIMENTACAO_ESTOQUE)
                    .id(id)
                    .remover();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (movimentacaoEstoque) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const movimentacaoEstoque = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.MOVIMENTACAO_ESTOQUE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MOVIMENTACAO_ESTOQUE)
                    .id(this.modelo.id)
                    .restaurar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (movimentacaoEstoque) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosLista() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                let filtroTratado = this.tratarFiltro();
                let filtrarTipoProdutoBandeja = false;
                let filtrarTipoProdutoMuda = false;
                let filtrarTipoProdutoVasilhameTubete = false;
                let filtrarTipoProdutoVasilhameEllepot = false;
                let filtrarTipoProdutoVasilhameBandeja = false;
                let filtrarTipoProdutoVasilhameCaixa = false;
                if ((typeof this.filtro !== 'undefined') && this.filtro &&
                    (typeof this.filtro.produto !== 'undefined') && this.filtro.produto &&
                    (typeof this.filtro.produto.id !== 'undefined') && this.filtro.produto.id) {
                    filtrarTipoProdutoBandeja = ((this.filtro.produto.id === TipoProdutoEstoque_1.TipoProdutoEstoque.BANDEJAS().id) || (this.filtro.produto.id === TipoProdutoEstoque_1.TipoProdutoEstoque.BANDEJAS_MUDAS().id));
                    filtrarTipoProdutoMuda = ((this.filtro.produto.id === TipoProdutoEstoque_1.TipoProdutoEstoque.MUDAS().id) || (this.filtro.produto.id === TipoProdutoEstoque_1.TipoProdutoEstoque.BANDEJAS_MUDAS().id));
                    filtrarTipoProdutoVasilhameTubete = (this.filtro.produto.id === TipoProdutoEstoque_1.TipoProdutoEstoque.VASILHAME_TUBETE().id);
                    filtrarTipoProdutoVasilhameEllepot = (this.filtro.produto.id === TipoProdutoEstoque_1.TipoProdutoEstoque.VASILHAME_ELLEPOT().id);
                    filtrarTipoProdutoVasilhameBandeja = (this.filtro.produto.id === TipoProdutoEstoque_1.TipoProdutoEstoque.VASILHAME_BANDEJA().id);
                    filtrarTipoProdutoVasilhameCaixa = (this.filtro.produto.id === TipoProdutoEstoque_1.TipoProdutoEstoque.VASILHAME_BANDEJA().id);
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                this.path = config_app_1.config.api.caminho;
                this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.MOVIMENTACAO_ESTOQUE;
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                var auxColunas = Array();
                auxColunas.push({
                    name: 'dataHoraApontamento',
                    displayName: 'Data/Hora',
                    cellFilter: 'date:"dd/MM/yyyy HH:mm:ss"',
                    width: 150
                });
                if (filtrarTipoProdutoMuda || filtrarTipoProdutoBandeja) {
                    auxColunas.push({
                        name: 'lote.descricao',
                        displayName: 'Lote',
                        width: 160
                    });
                }
                else if (filtrarTipoProdutoVasilhameTubete || filtrarTipoProdutoVasilhameEllepot || filtrarTipoProdutoVasilhameBandeja || filtrarTipoProdutoVasilhameCaixa) {
                    auxColunas.push({
                        name: 'cliente.nome',
                        displayName: 'Cliente',
                        width: 180
                    });
                }
                auxColunas.push({
                    name: 'usuario.nomeCompleto',
                    displayName: 'Usuário',
                    width: 180
                });
                auxColunas.push({
                    name: 'apontamento.descricao',
                    displayName: 'Apontamento',
                    width: 200
                });
                auxColunas.push({
                    name: 'operacao',
                    displayName: 'Operação',
                    width: 100
                });
                auxColunas.push({
                    name: 'tipoLancamento',
                    displayName: 'Tipo Lançamento',
                    width: 120
                });
                auxColunas.push({
                    name: 'tipoLocal',
                    displayName: 'Tipo Local',
                    width: 150
                });
                let camposQuantidades = [];
                if (filtrarTipoProdutoBandeja) {
                    camposQuantidades.push({ finalCampo: 'Bandejas', finalTitulo: 'Bandejas' });
                }
                if (filtrarTipoProdutoMuda) {
                    camposQuantidades.push({ finalCampo: 'Mudas', finalTitulo: 'Mudas' });
                }
                if (filtrarTipoProdutoVasilhameTubete) {
                    camposQuantidades.push({ finalCampo: 'Vasilhames', finalTitulo: 'Tubetes' });
                }
                if (filtrarTipoProdutoVasilhameEllepot) {
                    camposQuantidades.push({ finalCampo: 'Vasilhames', finalTitulo: 'Ellepots' });
                }
                if (filtrarTipoProdutoVasilhameBandeja) {
                    camposQuantidades.push({ finalCampo: 'Vasilhames', finalTitulo: 'Bandejas' });
                }
                if (filtrarTipoProdutoVasilhameCaixa) {
                    camposQuantidades.push({ finalCampo: 'Vasilhames', finalTitulo: 'Caixas' });
                }
                angular.forEach(camposQuantidades, (campoQuantidade) => {
                    auxColunas.push({
                        name: 'quantidadeAnterior' + campoQuantidade.finalCampo,
                        displayName: 'Quant. Anterior ' + campoQuantidade.finalTitulo,
                        width: 122
                    });
                    auxColunas.push({
                        name: 'quantidadeMovimento' + campoQuantidade.finalCampo,
                        displayName: 'Quant. Movimento ' + campoQuantidade.finalTitulo,
                        width: 140
                    });
                    auxColunas.push({
                        name: 'quantidadeFinal' + campoQuantidade.finalCampo,
                        displayName: 'Quant. Final ' + campoQuantidade.finalTitulo,
                        width: 120,
                        cellTemplate: `
                        <div class="ui-grid-cell-contents" style="font-weight: 700;">
                            {{row.entity.quantidadeFinal${campoQuantidade.finalCampo}}}
                        </div>
                    `
                    });
                });
                auxColunas.push({
                    name: 'observacoes',
                    displayName: 'Observações',
                    width: 200
                });
                auxColunas.push({
                    name: 'criacao',
                    displayName: 'Data/Hora Cadastro',
                    cellFilter: 'date:"dd/MM/yyyy HH:mm:ss"',
                    width: 190
                });
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
                let lista = new Array();
                let listaCompleta = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.MOVIMENTACAO_ESTOQUE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MOVIMENTACAO_ESTOQUE)
                    .filtro(Object.assign({ listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular() }, filtroTratado))
                    .listar();
                if ((typeof listaCompleta !== 'undefined') && listaCompleta) {
                    angular.forEach(listaCompleta, (item) => {
                        item.ehLancamentoPerdas = ((typeof item.ehLancamentoPerdas !== 'undefined') && item.ehLancamentoPerdas);
                        item.ehSegundaSelecaoMudas = ((typeof item.ehSegundaSelecaoMudas !== 'undefined') && item.ehSegundaSelecaoMudas);
                        item.ehVasilhameTubete = ((typeof item.ehVasilhameTubete !== 'undefined') && item.ehVasilhameTubete);
                        item.ehVasilhameEllepot = ((typeof item.ehVasilhameEllepot !== 'undefined') && item.ehVasilhameEllepot);
                        item.ehVasilhameBandeja = ((typeof item.ehVasilhameBandeja !== 'undefined') && item.ehVasilhameBandeja);
                        item.ehVasilhameCaixa = ((typeof item.ehVasilhameCaixa !== 'undefined') && item.ehVasilhameCaixa);
                        if ((typeof ctrl.filtro !== 'undefined') && ctrl.filtro &&
                            (typeof ctrl.filtro.produto !== 'undefined') && ctrl.filtro.produto &&
                            (typeof ctrl.filtro.produto.id !== 'undefined') && ctrl.filtro.produto.id) {
                            if ((ctrl.filtro.produto.id === TipoProdutoEstoque_1.TipoProdutoEstoque.MUDAS().id) || (ctrl.filtro.produto.id === TipoProdutoEstoque_1.TipoProdutoEstoque.BANDEJAS().id) || (ctrl.filtro.produto.id === TipoProdutoEstoque_1.TipoProdutoEstoque.BANDEJAS_MUDAS().id)) {
                                if (!item.ehVasilhameTubete && !item.ehVasilhameEllepot && !item.ehVasilhameBandeja && !item.ehVasilhameCaixa) {
                                    lista.push(item);
                                }
                            }
                            else if (ctrl.filtro.produto.id === TipoProdutoEstoque_1.TipoProdutoEstoque.VASILHAME_TUBETE().id) {
                                if (item.ehVasilhameTubete) {
                                    lista.push(item);
                                }
                            }
                            else if (ctrl.filtro.produto.id === TipoProdutoEstoque_1.TipoProdutoEstoque.VASILHAME_ELLEPOT().id) {
                                if (item.ehVasilhameEllepot) {
                                    lista.push(item);
                                }
                            }
                            else if (ctrl.filtro.produto.id === TipoProdutoEstoque_1.TipoProdutoEstoque.VASILHAME_BANDEJA().id) {
                                if (item.ehVasilhameBandeja) {
                                    lista.push(item);
                                }
                            }
                            else if (ctrl.filtro.produto.id === TipoProdutoEstoque_1.TipoProdutoEstoque.VASILHAME_BANDEJA().id) {
                                if (item.ehVasilhameCaixa) {
                                    lista.push(item);
                                }
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach(element => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    validarExibicaoCamposFiltro() {
        let filtrarTipoProdutoBandeja = false;
        let filtrarTipoProdutoMuda = false;
        let filtrarTipoProdutoVasilhameTubete = false;
        let filtrarTipoProdutoVasilhameEllepot = false;
        let filtrarTipoProdutoVasilhameBandeja = false;
        let filtrarTipoProdutoVasilhameCaixa = false;
        if ((typeof this.filtro !== 'undefined') && this.filtro &&
            (typeof this.filtro.produto !== 'undefined') && this.filtro.produto &&
            (typeof this.filtro.produto.id !== 'undefined') && this.filtro.produto.id) {
            filtrarTipoProdutoBandeja = ((this.filtro.produto.id === TipoProdutoEstoque_1.TipoProdutoEstoque.BANDEJAS().id) || (this.filtro.produto.id === TipoProdutoEstoque_1.TipoProdutoEstoque.BANDEJAS_MUDAS().id));
            filtrarTipoProdutoMuda = ((this.filtro.produto.id === TipoProdutoEstoque_1.TipoProdutoEstoque.MUDAS().id) || (this.filtro.produto.id === TipoProdutoEstoque_1.TipoProdutoEstoque.BANDEJAS_MUDAS().id));
            filtrarTipoProdutoVasilhameTubete = (this.filtro.produto.id === TipoProdutoEstoque_1.TipoProdutoEstoque.VASILHAME_TUBETE().id);
            filtrarTipoProdutoVasilhameEllepot = (this.filtro.produto.id === TipoProdutoEstoque_1.TipoProdutoEstoque.VASILHAME_ELLEPOT().id);
            filtrarTipoProdutoVasilhameBandeja = (this.filtro.produto.id === TipoProdutoEstoque_1.TipoProdutoEstoque.VASILHAME_BANDEJA().id);
            filtrarTipoProdutoVasilhameCaixa = (this.filtro.produto.id === TipoProdutoEstoque_1.TipoProdutoEstoque.VASILHAME_BANDEJA().id);
        }
        if (this.mostrarFiltroLotes !== (filtrarTipoProdutoBandeja || filtrarTipoProdutoMuda)) {
            this.mostrarFiltroLotes = (filtrarTipoProdutoBandeja || filtrarTipoProdutoMuda);
        }
        if (!this.mostrarFiltroLotes && (typeof this.filtro !== 'undefined') && this.filtro && (typeof this.filtro.lote !== 'undefined') && this.filtro.lote) {
            this.filtro.lote = null;
        }
        if (this.mostrarFiltroClientes !== (filtrarTipoProdutoVasilhameTubete || filtrarTipoProdutoVasilhameEllepot || filtrarTipoProdutoVasilhameBandeja || filtrarTipoProdutoVasilhameCaixa)) {
            this.mostrarFiltroClientes = (filtrarTipoProdutoVasilhameTubete || filtrarTipoProdutoVasilhameEllepot || filtrarTipoProdutoVasilhameBandeja || filtrarTipoProdutoVasilhameCaixa);
        }
        if (!this.mostrarFiltroClientes && (typeof this.filtro !== 'undefined') && this.filtro && (typeof this.filtro.cliente !== 'undefined') && this.filtro.cliente) {
            this.filtro.cliente = null;
        }
    }
    tratarFiltro() {
        if ((typeof this.filtro === 'undefined') || !this.filtro) {
            this.filtro = new FiltroMovimentacaoEstoque();
        }
        if ((typeof this.filtro.produto === 'undefined') || !this.filtro.produto) {
            this.filtro.produto = TipoProdutoEstoque_1.TipoProdutoEstoque.MUDAS();
        }
        let vProduto = '';
        let vLote = '';
        let vCliente = '';
        let vDataIni = '';
        let vDataFim = '';
        if ((typeof this.filtro.produto !== 'undefined') && this.filtro.produto) {
            if ((typeof this.filtro.produto.id !== 'undefined') && this.filtro.produto.id) {
                vProduto = this.filtro.produto.id;
            }
        }
        if ((typeof this.filtro.lote !== 'undefined') && this.filtro.lote) {
            if ((typeof this.filtro.lote.id !== 'undefined') && this.filtro.lote.id) {
                vLote = this.filtro.lote.id;
            }
        }
        if ((typeof this.filtro.cliente !== 'undefined') && this.filtro.cliente) {
            if ((typeof this.filtro.cliente.id !== 'undefined') && this.filtro.cliente.id) {
                vCliente = this.filtro.cliente.id;
            }
        }
        if ((typeof this.filtro.dataIni !== 'undefined') && (this.filtro.dataIni !== null)) {
            vDataIni = DataUtil_1.DataUtil.converterDataParaFormatoPHP(this.filtro.dataIni);
        }
        if ((typeof this.filtro.dataFim !== 'undefined') && (this.filtro.dataFim !== null)) {
            vDataFim = DataUtil_1.DataUtil.converterDataParaFormatoPHP(this.filtro.dataFim);
        }
        let filtroTratado = new FiltroMovimentacaoEstoqueTratado();
        filtroTratado.produto = vProduto;
        filtroTratado.lote = vLote;
        filtroTratado.cliente = vCliente;
        filtroTratado.dataIni = vDataIni;
        filtroTratado.dataFim = vDataFim;
        return filtroTratado;
    }
    filtrar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
            this.scopeApply();
        });
    }
    limparFiltro() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.filtro) {
                this.filtro = new FiltroMovimentacaoEstoque();
            }
            this.filtro.dataIni = new Date();
            this.filtro.dataIni.setDate(this.filtro.dataIni.getDate() - 30);
            this.filtro.dataFim = new Date();
            this.filtro.lote = null;
            this.filtro.cliente = null;
            this.filtrar();
        });
    }
    limparDadosCarregados() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes.data = [];
            this.listaOpcoes.columnDefs = [];
            this.validarExibicaoCamposFiltro();
            this.scopeApply();
        });
    }
}
exports.FenologiaPomarController = FenologiaPomarController;
FenologiaPomarController.$inject = ['$scope', '$injector'];
