"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TipoAnexoQualidadeMadeira = void 0;
class TipoAnexoQualidadeMadeira {
    constructor() {
        this.id = null;
        this.descricao = null;
    }
    static DADOS_RESISTOGRAFO() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.tipoAnexoQualidadeMadeira_DadosResistografo === 'undefined') ||
            (globalThis.globalConstants.tipoAnexoQualidadeMadeira_DadosResistografo === null)) {
            let tipoAnexoQualidadeMadeira_DadosResistografo = new TipoAnexoQualidadeMadeira();
            tipoAnexoQualidadeMadeira_DadosResistografo.id = 'DADOS_RESISTOGRAFO';
            tipoAnexoQualidadeMadeira_DadosResistografo.descricao = 'Dados de resistógrafo';
            globalThis.globalConstants.tipoAnexoQualidadeMadeira_DadosResistografo = tipoAnexoQualidadeMadeira_DadosResistografo;
        }
        return globalThis.globalConstants.tipoAnexoQualidadeMadeira_DadosResistografo;
    }
    static DADOS_DENSIDADE_DISCOS() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.tipoAnexoQualidadeMadeira_DadosDensidadeDiscos === 'undefined') ||
            (globalThis.globalConstants.tipoAnexoQualidadeMadeira_DadosDensidadeDiscos === null)) {
            let tipoAnexoQualidadeMadeira_DadosDensidadeDiscos = new TipoAnexoQualidadeMadeira();
            tipoAnexoQualidadeMadeira_DadosDensidadeDiscos.id = 'DADOS_DENSIDADE_DISCOS';
            tipoAnexoQualidadeMadeira_DadosDensidadeDiscos.descricao = 'Dados de densidade de discos';
            globalThis.globalConstants.tipoAnexoQualidadeMadeira_DadosDensidadeDiscos = tipoAnexoQualidadeMadeira_DadosDensidadeDiscos;
        }
        return globalThis.globalConstants.tipoAnexoQualidadeMadeira_DadosDensidadeDiscos;
    }
    static ANALISE_NIR() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.tipoAnexoQualidadeMadeira_AnaliseNIR === 'undefined') ||
            (globalThis.globalConstants.tipoAnexoQualidadeMadeira_AnaliseNIR === null)) {
            let tipoAnexoQualidadeMadeira_AnaliseNIR = new TipoAnexoQualidadeMadeira();
            tipoAnexoQualidadeMadeira_AnaliseNIR.id = 'ANALISE_NIR';
            tipoAnexoQualidadeMadeira_AnaliseNIR.descricao = 'Análise NIR';
            globalThis.globalConstants.tipoAnexoQualidadeMadeira_AnaliseNIR = tipoAnexoQualidadeMadeira_AnaliseNIR;
        }
        return globalThis.globalConstants.tipoAnexoQualidadeMadeira_AnaliseNIR;
    }
    static QUALIDADE_QUIMICA() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.tipoAnexoQualidadeMadeira_QualidadeQuimica === 'undefined') ||
            (globalThis.globalConstants.tipoAnexoQualidadeMadeira_QualidadeQuimica === null)) {
            let tipoAnexoQualidadeMadeira_QualidadeQuimica = new TipoAnexoQualidadeMadeira();
            tipoAnexoQualidadeMadeira_QualidadeQuimica.id = 'QUALIDADE_QUIMICA';
            tipoAnexoQualidadeMadeira_QualidadeQuimica.descricao = 'Qualidade química';
            globalThis.globalConstants.tipoAnexoQualidadeMadeira_QualidadeQuimica = tipoAnexoQualidadeMadeira_QualidadeQuimica;
        }
        return globalThis.globalConstants.tipoAnexoQualidadeMadeira_QualidadeQuimica;
    }
    static RENDIMENTO_FPTB() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.tipoAnexoQualidadeMadeira_RendimentoFPTB === 'undefined') ||
            (globalThis.globalConstants.tipoAnexoQualidadeMadeira_RendimentoFPTB === null)) {
            let tipoAnexoQualidadeMadeira_RendimentoFPTB = new TipoAnexoQualidadeMadeira();
            tipoAnexoQualidadeMadeira_RendimentoFPTB.id = 'RENDIMENTO_FPTB';
            tipoAnexoQualidadeMadeira_RendimentoFPTB.descricao = 'Rendimento na FPTB';
            globalThis.globalConstants.tipoAnexoQualidadeMadeira_RendimentoFPTB = tipoAnexoQualidadeMadeira_RendimentoFPTB;
        }
        return globalThis.globalConstants.tipoAnexoQualidadeMadeira_RendimentoFPTB;
    }
    static OUTROS() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.tipoAnexoQualidadeMadeira_Outros === 'undefined') ||
            (globalThis.globalConstants.tipoAnexoQualidadeMadeira_Outros === null)) {
            let tipoAnexoQualidadeMadeira_Outros = new TipoAnexoQualidadeMadeira();
            tipoAnexoQualidadeMadeira_Outros.id = 'OUTROS';
            tipoAnexoQualidadeMadeira_Outros.descricao = 'Outros (etc.)';
            globalThis.globalConstants.tipoAnexoQualidadeMadeira_Outros = tipoAnexoQualidadeMadeira_Outros;
        }
        return globalThis.globalConstants.tipoAnexoQualidadeMadeira_Outros;
    }
    static LISTA() {
        return [
            TipoAnexoQualidadeMadeira.DADOS_RESISTOGRAFO(),
            TipoAnexoQualidadeMadeira.DADOS_DENSIDADE_DISCOS(),
            TipoAnexoQualidadeMadeira.ANALISE_NIR(),
            TipoAnexoQualidadeMadeira.QUALIDADE_QUIMICA(),
            TipoAnexoQualidadeMadeira.RENDIMENTO_FPTB(),
            TipoAnexoQualidadeMadeira.OUTROS()
        ];
    }
}
exports.TipoAnexoQualidadeMadeira = TipoAnexoQualidadeMadeira;
