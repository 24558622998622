"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImportaTalhaoController = void 0;
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const UiGridConstrutor_1 = require("../../../app/construtores/UiGridConstrutor");
const AbstratoController_1 = require("../../../app/AbstratoController");
const angular = require("angular");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const ICRUDOperacao_1 = require("../../../app/interfaces/ICRUDOperacao");
const ImportaTalhao_1 = require("../../../../../modelos/ImportaTalhao");
const config_app_1 = require("../../../../../config/config.app");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const sweetalert2_1 = require("sweetalert2");
const ConfiguracaoGeral_1 = require("../../../../../modelos/ConfiguracaoGeral");
class ImportaTalhaoController extends AbstratoController_1.AbstratoController {
    constructor($scope, $injector) {
        super($scope, $injector, new ImportaTalhao_1.ImportaTalhao());
        this.path = '';
        this.caminho = '';
        this.tipoOperacao = null;
        this.configuracaoGeral = null;
        this.sgifRegioes = null;
        this.sgifTalhoes = null;
        this.listaDadosIncluidos = null;
        this.listaDadosAlterados = null;
        this.listaDadosDivididos = null;
        this.listaDadosAgrupados = null;
        this.listaDadosExcluidos = null;
        this.listaDadosSemAlteracoes = null;
        this.listaOpcoesIncluidos = null;
        this.listaOpcoesAlterados = null;
        this.listaOpcoesDivididos = null;
        this.listaOpcoesAgrupados = null;
        this.listaOpcoesExcluidos = null;
        this.listaOpcoesSemAlteracoes = null;
        this.modalAlteracaoImportaTalhao = null;
        this.modalAlteracaoImportaTalhaoId = null;
        this.modalAlteracaoImportaTalhaoRegistro = null;
        this.tipoOperacao = ICRUDOperacao_1.CRUDOperacao;
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.importar = false;
            this.path = config_app_1.config.api.caminho;
            this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.IMPORTA_TALHAO;
            this.modalAlteracaoImportaTalhao = false;
            this.modalAlteracaoImportaTalhaoId = null;
            this.modalAlteracaoImportaTalhaoRegistro = null;
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.content-body');
            const listaConfiguracoesGerais = yield this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.PARAMETRIZACAO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PARAMETRIZACAO)
                .filtro({ permiteDadosOffline: true })
                .listar();
            this.configuracaoGeral = listaConfiguracoesGerais && (listaConfiguracoesGerais.length > 0)
                ? listaConfiguracoesGerais[0] : new ConfiguracaoGeral_1.ConfiguracaoGeral();
            this.sgifRegioes = yield this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.REGIAO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.REGIAO)
                .listar();
            this.sgifTalhoes = yield this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.TALHAO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TALHAO)
                .listar();
            if (!this.usuario) {
                this.links = {
                    VISUALIZAR: null,
                    CRIAR: null,
                    DUPLICAR: null,
                    EDITAR: null,
                    LISTAR: {
                        rota: 'login',
                        params: null
                    }
                };
            }
            this.$scope.$apply();
            this.filtrar();
        });
    }
    salvar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.IMPORTA_TALHAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.IMPORTA_TALHAO)
                        .criar(this.modelo);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.IMPORTA_TALHAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.IMPORTA_TALHAO)
                        .atualizar(this.modelo);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir um novo Talhão ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir um novo Talhão ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não'
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    visualizar(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const sgifImportaTalhao = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.IMPORTA_TALHAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.IMPORTA_TALHAO)
                    .id(id)
                    .ver();
                sgifImportaTalhao.criacao = new Date(sgifImportaTalhao.criacao);
                if ((typeof sgifImportaTalhao.inativo === 'undefined') || (sgifImportaTalhao.inativo === null)) {
                    sgifImportaTalhao.inativo = false;
                }
                if ((typeof sgifImportaTalhao.dataPlantio !== 'undefined') && (sgifImportaTalhao.dataPlantio !== null)) {
                    sgifImportaTalhao.dataPlantio = new Date(sgifImportaTalhao.dataPlantio);
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return sgifImportaTalhao;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    remover(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const sgifImportaTalhao = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.IMPORTA_TALHAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.IMPORTA_TALHAO)
                    .id(id)
                    .remover();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (sgifImportaTalhao) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
            return null;
        });
    }
    dadosLista() {
        return __awaiter(this, void 0, void 0, function* () {
            return null;
        });
    }
    dadosListaCustomizada(operacaoLista) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                let auxColunas = new Array();
                let listarIncluidos = false;
                let listarAlterados = false;
                let listarDivididos = false;
                let listarAgrupados = false;
                let listarExcluidos = false;
                let listarSemAlteracoes = false;
                if ((typeof operacaoLista !== 'undefined') && (operacaoLista !== null)) {
                    if (operacaoLista.trim().toUpperCase() === 'INC') {
                        listarIncluidos = true;
                    }
                    else if (operacaoLista.trim().toUpperCase() === 'ALT') {
                        listarAlterados = true;
                    }
                    else if (operacaoLista.trim().toUpperCase() === 'DIV') {
                        listarDivididos = true;
                    }
                    else if (operacaoLista.trim().toUpperCase() === 'AGR') {
                        listarAgrupados = true;
                    }
                    else if (operacaoLista.trim().toUpperCase() === 'EXC') {
                        listarExcluidos = true;
                    }
                    else {
                        listarSemAlteracoes = true;
                    }
                }
                else {
                    listarSemAlteracoes = true;
                }
                if (!listarSemAlteracoes) {
                    auxColunas.push({
                        name: 'id',
                        displayName: 'Ações',
                        visible: true,
                        width: 70,
                        enableFiltering: false,
                        enableSorting: true,
                        posicao: 1,
                        cellClass: 'text-center',
                        cellTemplate: `
                            <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                                <button type="button" class="btn btn-icon bx bxs-edit" style="padding-right: .4rem"
                                    onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                                    ng-click="grid.appScope.getParentController().exibirModalAlteracaoImportaTalhao(row.entity.id)">
                                </button>
                                <button type="button" class="btn btn-icon bx bx-trash" style="padding-left: .4rem"
                                    onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                                    ng-click="grid.appScope.getParentController().excluirImportaTalhao(row.entity.id)">
                                </button>
                            </div> `
                    });
                }
                auxColunas.push({
                    name: 'regiaoNome',
                    displayName: 'Região',
                    width: 200
                });
                auxColunas.push({
                    name: 'numero',
                    displayName: 'Nº Talhão',
                    width: 110
                });
                auxColunas.push({
                    name: 'areaTalhao',
                    displayName: 'Área Talhão',
                    width: 120
                });
                auxColunas.push({
                    name: 'dataPlantio',
                    displayName: 'Data Plantio',
                    width: 130,
                    cellFilter: 'date:"dd/MM/yyyy"'
                });
                auxColunas.push({
                    name: 'tipoFloresta',
                    displayName: 'Tipo Floresta',
                    width: 180
                });
                auxColunas.push({
                    name: 'arvores',
                    displayName: 'Árvores',
                    width: 110
                });
                auxColunas.push({
                    name: 'talhaoAnterior1.sgif_id',
                    displayName: 'SGIF Id.',
                    width: 160
                });
                let posicaoColuna = 1;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                const gridOption = {
                    data: null,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                if (listarIncluidos) {
                    gridOption.data = this.listaDadosIncluidos;
                }
                else if (listarAlterados) {
                    gridOption.data = this.listaDadosAlterados;
                }
                else if (listarDivididos) {
                    gridOption.data = this.listaDadosDivididos;
                }
                else if (listarAgrupados) {
                    gridOption.data = this.listaDadosAgrupados;
                }
                else if (listarExcluidos) {
                    if ((typeof this.configuracaoGeral !== 'undefined') && this.configuracaoGeral &&
                        (typeof this.configuracaoGeral.utilizaExclusaoImportacaoTalhao !== 'undefined') &&
                        this.configuracaoGeral.utilizaExclusaoImportacaoTalhao) {
                        gridOption.data = this.listaDadosExcluidos;
                    }
                    else {
                        gridOption.data = [];
                    }
                }
                else if (listarSemAlteracoes) {
                    gridOption.data = this.listaDadosSemAlteracoes;
                }
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            return null;
        });
    }
    filtrar() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.path = config_app_1.config.api.caminho;
            this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.IMPORTA_TALHAO;
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.content-body');
            try {
                this.listaDadosIncluidos = new Array();
                this.listaDadosAlterados = new Array();
                this.listaDadosDivididos = new Array();
                this.listaDadosAgrupados = new Array();
                this.listaDadosExcluidos = new Array();
                this.listaDadosSemAlteracoes = new Array();
                const listaDados = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.IMPORTA_TALHAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.IMPORTA_TALHAO)
                    .filtro({ listaSimplificadaOnline: true })
                    .listar();
                if ((typeof listaDados !== 'undefined') && (listaDados !== null)) {
                    angular.forEach(listaDados, (item) => {
                        if ((typeof item.operacao !== 'undefined') && (item.operacao !== null)) {
                            if (item.operacao.trim().toUpperCase() === 'INC') {
                                ctrl.listaDadosIncluidos.push(item);
                            }
                            else if (item.operacao.trim().toUpperCase() === 'ALT') {
                                ctrl.listaDadosAlterados.push(item);
                            }
                            else if (item.operacao.trim().toUpperCase() === 'DIV') {
                                ctrl.listaDadosDivididos.push(item);
                            }
                            else if (item.operacao.trim().toUpperCase() === 'AGR') {
                                ctrl.listaDadosAgrupados.push(item);
                            }
                            else if (item.operacao.trim().toUpperCase() === 'EXC') {
                                if ((typeof ctrl.configuracaoGeral !== 'undefined') && ctrl.configuracaoGeral &&
                                    (typeof ctrl.configuracaoGeral.utilizaExclusaoImportacaoTalhao !== 'undefined') &&
                                    ctrl.configuracaoGeral.utilizaExclusaoImportacaoTalhao) {
                                    ctrl.listaDadosExcluidos.push(item);
                                }
                            }
                            else {
                                ctrl.listaDadosSemAlteracoes.push(item);
                            }
                        }
                        else {
                            ctrl.listaDadosSemAlteracoes.push(item);
                        }
                    });
                }
                this.listaOpcoesIncluidos = yield this.dadosListaCustomizada('INC');
                this.listaOpcoesAlterados = yield this.dadosListaCustomizada('ALT');
                this.listaOpcoesDivididos = yield this.dadosListaCustomizada('DIV');
                this.listaOpcoesAgrupados = yield this.dadosListaCustomizada('AGR');
                this.listaOpcoesExcluidos = yield this.dadosListaCustomizada('EXC');
                this.listaOpcoesSemAlteracoes = yield this.dadosListaCustomizada(null);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            this.scopeApply();
        });
    }
    concluirImportacao() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            if (((typeof this.listaDadosIncluidos !== 'undefined') && this.listaDadosIncluidos && (this.listaDadosIncluidos.length > 0)) ||
                ((typeof this.listaDadosAlterados !== 'undefined') && this.listaDadosAlterados && (this.listaDadosAlterados.length > 0)) ||
                ((typeof this.listaDadosDivididos !== 'undefined') && this.listaDadosDivididos && (this.listaDadosDivididos.length > 0)) ||
                ((typeof this.listaDadosAgrupados !== 'undefined') && this.listaDadosAgrupados && (this.listaDadosAgrupados.length > 0)) ||
                ((typeof this.listaDadosExcluidos !== 'undefined') && this.listaDadosExcluidos && (this.listaDadosExcluidos.length > 0))) {
                sweetalert2_1.default.fire({
                    text: 'Deseja concluir a importação dos Talhões ?',
                    icon: 'question',
                    showConfirmButton: true,
                    confirmButtonText: 'Sim',
                    showCancelButton: true,
                    cancelButtonText: 'Não'
                }).then((result) => __awaiter(this, void 0, void 0, function* () {
                    if (result.isConfirmed) {
                        ctrl.path = config_app_1.config.api.caminho;
                        ctrl.caminho = ctrl.path + RotaAPIConstantes_1.RotaAPIConstantes.IMPORTA_TALHAO;
                        CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.content-body');
                        try {
                            let listaDados = yield ctrl.sincronia
                                .rota(RotaAPIConstantes_1.RotaAPIConstantes.IMPORTA_TALHAO)
                                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.IMPORTA_TALHAO)
                                .filtro({
                                listaSimplificadaOnline: true,
                                operacao: 'concluirImportacao'
                            })
                                .listar();
                            if ((typeof listaDados !== 'undefined') && (listaDados !== null)) {
                                this.listaDadosIncluidos = new Array();
                                this.listaDadosAlterados = new Array();
                                this.listaDadosDivididos = new Array();
                                this.listaDadosAgrupados = new Array();
                                this.listaDadosExcluidos = new Array();
                                this.listaDadosSemAlteracoes = new Array();
                                angular.forEach(listaDados, (item) => {
                                    if ((typeof item.operacao !== 'undefined') && (item.operacao !== null)) {
                                        if (item.operacao.trim().toUpperCase() === 'INC') {
                                            this.listaDadosIncluidos.push(item);
                                        }
                                        else if (item.operacao.trim().toUpperCase() === 'ALT') {
                                            this.listaDadosAlterados.push(item);
                                        }
                                        else if (item.operacao.trim().toUpperCase() === 'DIV') {
                                            this.listaDadosDivididos.push(item);
                                        }
                                        else if (item.operacao.trim().toUpperCase() === 'AGR') {
                                            this.listaDadosAgrupados.push(item);
                                        }
                                        else if (item.operacao.trim().toUpperCase() === 'EXC') {
                                            if ((typeof ctrl.configuracaoGeral !== 'undefined') && ctrl.configuracaoGeral &&
                                                (typeof ctrl.configuracaoGeral.utilizaExclusaoImportacaoTalhao !== 'undefined') &&
                                                ctrl.configuracaoGeral.utilizaExclusaoImportacaoTalhao) {
                                                ctrl.listaDadosExcluidos.push(item);
                                            }
                                        }
                                        else {
                                            this.listaDadosSemAlteracoes.push(item);
                                        }
                                    }
                                    else {
                                        this.listaDadosSemAlteracoes.push(item);
                                    }
                                });
                                this.listaOpcoesIncluidos = yield this.dadosListaCustomizada('INC');
                                this.listaOpcoesAlterados = yield this.dadosListaCustomizada('ALT');
                                this.listaOpcoesDivididos = yield this.dadosListaCustomizada('DIV');
                                this.listaOpcoesAgrupados = yield this.dadosListaCustomizada('AGR');
                                this.listaOpcoesExcluidos = yield this.dadosListaCustomizada('EXC');
                                this.listaOpcoesSemAlteracoes = yield this.dadosListaCustomizada(null);
                            }
                            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        }
                        catch (ex) {
                            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                            ctrl.manipulaErro.exception(ex);
                        }
                        ctrl.scopeApply();
                        sweetalert2_1.default.fire({
                            text: 'Operação concluída com sucesso!',
                            icon: 'success',
                            confirmButtonText: 'Ok'
                        });
                    }
                }));
            }
            else {
                sweetalert2_1.default.fire({
                    text: 'Não há dados carregados para concluir a importação.',
                    icon: 'info',
                    confirmButtonText: 'Ok'
                });
            }
        });
    }
    exibirModalAlteracaoImportaTalhao(id) {
        this.modalAlteracaoImportaTalhao = false;
        if ((id !== null) && (id.trim().length > 0)) {
            this.modalAlteracaoImportaTalhaoId = id;
            this.modalAlteracaoImportaTalhaoRegistro = null;
            let listaTodosDados = new Array();
            listaTodosDados = listaTodosDados.concat(this.listaDadosIncluidos, this.listaDadosAlterados, this.listaDadosDivididos, this.listaDadosAgrupados, this.listaDadosExcluidos, this.listaDadosSemAlteracoes);
            if ((typeof listaTodosDados !== 'undefined') && (listaTodosDados !== null) &&
                (typeof listaTodosDados.length !== 'undefined') && (listaTodosDados.length !== null) &&
                (listaTodosDados.length > 0)) {
                for (let i = 0; i < listaTodosDados.length; i++) {
                    if ((typeof listaTodosDados[i].id !== 'undefined') && (listaTodosDados[i].id !== null) &&
                        (listaTodosDados[i].id.trim().toLowerCase() === id.trim().toLowerCase())) {
                        this.modalAlteracaoImportaTalhaoRegistro = listaTodosDados[i];
                        break;
                    }
                }
                listaTodosDados.splice(0, listaTodosDados.length);
            }
            if (!this.modalAlteracaoImportaTalhaoRegistro) {
                this.modalAlteracaoImportaTalhaoRegistro = new ImportaTalhao_1.ImportaTalhao();
                this.modalAlteracaoImportaTalhaoRegistro.id = 'NOVO';
                this.modalAlteracaoImportaTalhaoRegistro.criacao = new Date();
            }
            if ((typeof this.modalAlteracaoImportaTalhaoRegistro.regiaoId !== 'undefined') && (this.modalAlteracaoImportaTalhaoRegistro.regiaoId !== null)) {
                angular.forEach(this.sgifRegioes, (regiao) => {
                    if ((typeof regiao.id !== 'undefined') && (regiao.id !== null) && (regiao.id === this.modalAlteracaoImportaTalhaoRegistro.regiaoId)) {
                        this.modalAlteracaoImportaTalhaoRegistro.regiao = regiao;
                    }
                });
            }
            if ((typeof this.modalAlteracaoImportaTalhaoRegistro.dataPlantio !== 'undefined') && (this.modalAlteracaoImportaTalhaoRegistro.dataPlantio !== null)) {
                this.modalAlteracaoImportaTalhaoRegistro.dataPlantio = new Date(this.modalAlteracaoImportaTalhaoRegistro.dataPlantio);
            }
            if ((typeof this.modalAlteracaoImportaTalhaoRegistro.talhaoAnterior1 !== 'undefined') && (this.modalAlteracaoImportaTalhaoRegistro.talhaoAnterior1 !== null)) {
                if ((typeof this.modalAlteracaoImportaTalhaoRegistro.talhaoAnterior1.dataPlantio !== 'undefined') && (this.modalAlteracaoImportaTalhaoRegistro.talhaoAnterior1.dataPlantio !== null)) {
                    this.modalAlteracaoImportaTalhaoRegistro.talhaoAnterior1.dataPlantio = new Date(this.modalAlteracaoImportaTalhaoRegistro.talhaoAnterior1.dataPlantio);
                }
            }
            if ((typeof this.modalAlteracaoImportaTalhaoRegistro.talhaoAnterior2 !== 'undefined') && (this.modalAlteracaoImportaTalhaoRegistro.talhaoAnterior2 !== null)) {
                if ((typeof this.modalAlteracaoImportaTalhaoRegistro.talhaoAnterior2.dataPlantio !== 'undefined') && (this.modalAlteracaoImportaTalhaoRegistro.talhaoAnterior2.dataPlantio !== null)) {
                    this.modalAlteracaoImportaTalhaoRegistro.talhaoAnterior2.dataPlantio = new Date(this.modalAlteracaoImportaTalhaoRegistro.talhaoAnterior2.dataPlantio);
                }
            }
            if ((typeof this.modalAlteracaoImportaTalhaoRegistro.talhaoAnterior3 !== 'undefined') && (this.modalAlteracaoImportaTalhaoRegistro.talhaoAnterior3 !== null)) {
                if ((typeof this.modalAlteracaoImportaTalhaoRegistro.talhaoAnterior3.dataPlantio !== 'undefined') && (this.modalAlteracaoImportaTalhaoRegistro.talhaoAnterior3.dataPlantio !== null)) {
                    this.modalAlteracaoImportaTalhaoRegistro.talhaoAnterior3.dataPlantio = new Date(this.modalAlteracaoImportaTalhaoRegistro.talhaoAnterior3.dataPlantio);
                }
            }
            this.modalAlteracaoImportaTalhao = true;
            angular.element('#modalAlteracaoImportaTalhao').css('display', 'block');
            angular.element('body').css('overflow-y', 'hidden');
        }
        else {
            this.modalAlteracaoImportaTalhaoId = null;
            this.modalAlteracaoImportaTalhaoRegistro = null;
        }
    }
    ocultarModalAlteracaoImportaTalhao() {
        if (this.modalAlteracaoImportaTalhao && this.modalAlteracaoImportaTalhaoRegistro) {
            this.modalAlteracaoImportaTalhaoRegistro.alterado = true;
        }
        this.modalAlteracaoImportaTalhao = false;
        this.modalAlteracaoImportaTalhaoId = null;
        this.modalAlteracaoImportaTalhaoRegistro = null;
        angular.element('#modalAlteracaoImportaTalhao').css('display', 'none');
        angular.element('body').css('overflow-y', '');
    }
    excluirImportaTalhao(id) {
        if ((id !== null) && (id.trim().length > 0)) {
            let achou = false;
            if ((typeof this.listaDadosIncluidos !== 'undefined') && (this.listaDadosIncluidos !== null) &&
                (typeof this.listaDadosIncluidos.length !== 'undefined') && (this.listaDadosIncluidos.length !== null) &&
                (this.listaDadosIncluidos.length > 0)) {
                let importaTalhao = null;
                let posicaoImportaTalhao = null;
                for (let i = 0; i < this.listaDadosIncluidos.length; i++) {
                    if ((typeof this.listaDadosIncluidos[i].id !== 'undefined') && (this.listaDadosIncluidos[i].id !== null) &&
                        (this.listaDadosIncluidos[i].id.trim().toLowerCase() === id.trim().toLowerCase())) {
                        importaTalhao = this.listaDadosIncluidos[i];
                        posicaoImportaTalhao = i;
                        achou = true;
                        break;
                    }
                }
                if ((importaTalhao !== null) && (posicaoImportaTalhao !== null)) {
                    if (window.confirm('Deseja realmente remover esse registro (núm. ' + importaTalhao.numero + ' - Dados Incluídos)? ')) {
                        this.listaDadosIncluidos.splice(posicaoImportaTalhao, 1);
                    }
                }
            }
            if ((typeof this.listaDadosAlterados !== 'undefined') && (this.listaDadosAlterados !== null) &&
                (typeof this.listaDadosAlterados.length !== 'undefined') && (this.listaDadosAlterados.length !== null) &&
                (this.listaDadosAlterados.length > 0)) {
                let importaTalhao = null;
                let posicaoImportaTalhao = null;
                for (let i = 0; i < this.listaDadosAlterados.length; i++) {
                    if ((typeof this.listaDadosAlterados[i].id !== 'undefined') && (this.listaDadosAlterados[i].id !== null) &&
                        (this.listaDadosAlterados[i].id.trim().toLowerCase() === id.trim().toLowerCase())) {
                        importaTalhao = this.listaDadosAlterados[i];
                        posicaoImportaTalhao = i;
                        achou = true;
                        break;
                    }
                }
                if ((importaTalhao !== null) && (posicaoImportaTalhao !== null)) {
                    if (window.confirm('Deseja realmente remover esse registro (núm. ' + importaTalhao.numero + ' - Dados Alterados)? ')) {
                        this.listaDadosAlterados.splice(posicaoImportaTalhao, 1);
                    }
                }
            }
            if ((typeof this.listaDadosDivididos !== 'undefined') && (this.listaDadosDivididos !== null) &&
                (typeof this.listaDadosDivididos.length !== 'undefined') && (this.listaDadosDivididos.length !== null) &&
                (this.listaDadosDivididos.length > 0)) {
                let importaTalhao = null;
                let posicaoImportaTalhao = null;
                for (let i = 0; i < this.listaDadosDivididos.length; i++) {
                    if ((typeof this.listaDadosDivididos[i].id !== 'undefined') && (this.listaDadosDivididos[i].id !== null) &&
                        (this.listaDadosDivididos[i].id.trim().toLowerCase() === id.trim().toLowerCase())) {
                        importaTalhao = this.listaDadosDivididos[i];
                        posicaoImportaTalhao = i;
                        achou = true;
                        break;
                    }
                }
                if ((importaTalhao !== null) && (posicaoImportaTalhao !== null)) {
                    if (window.confirm('Deseja realmente remover esse registro (núm. ' + importaTalhao.numero + ' - Dados Divididos)? ')) {
                        this.listaDadosDivididos.splice(posicaoImportaTalhao, 1);
                    }
                }
            }
            if ((typeof this.listaDadosAgrupados !== 'undefined') && (this.listaDadosAgrupados !== null) &&
                (typeof this.listaDadosAgrupados.length !== 'undefined') && (this.listaDadosAgrupados.length !== null) &&
                (this.listaDadosAgrupados.length > 0)) {
                let importaTalhao = null;
                let posicaoImportaTalhao = null;
                for (let i = 0; i < this.listaDadosAgrupados.length; i++) {
                    if ((typeof this.listaDadosAgrupados[i].id !== 'undefined') && (this.listaDadosAgrupados[i].id !== null) &&
                        (this.listaDadosAgrupados[i].id.trim().toLowerCase() === id.trim().toLowerCase())) {
                        importaTalhao = this.listaDadosAgrupados[i];
                        posicaoImportaTalhao = i;
                        achou = true;
                        break;
                    }
                }
                if ((importaTalhao !== null) && (posicaoImportaTalhao !== null)) {
                    if (window.confirm('Deseja realmente remover esse registro (núm. ' + importaTalhao.numero + ' - Dados Agrupados)? ')) {
                        this.listaDadosAgrupados.splice(posicaoImportaTalhao, 1);
                    }
                }
            }
            if ((typeof this.listaDadosExcluidos !== 'undefined') && (this.listaDadosExcluidos !== null) &&
                (typeof this.listaDadosExcluidos.length !== 'undefined') && (this.listaDadosExcluidos.length !== null) &&
                (this.listaDadosExcluidos.length > 0)) {
                let importaTalhao = null;
                let posicaoImportaTalhao = null;
                for (let i = 0; i < this.listaDadosExcluidos.length; i++) {
                    if ((typeof this.listaDadosExcluidos[i].id !== 'undefined') && (this.listaDadosExcluidos[i].id !== null) &&
                        (this.listaDadosExcluidos[i].id.trim().toLowerCase() === id.trim().toLowerCase())) {
                        importaTalhao = this.listaDadosExcluidos[i];
                        posicaoImportaTalhao = i;
                        achou = true;
                        break;
                    }
                }
                if ((importaTalhao !== null) && (posicaoImportaTalhao !== null)) {
                    if (window.confirm('Deseja realmente remover esse registro (núm. ' + importaTalhao.numero + ' - Dados Excluídos)? ')) {
                        this.listaDadosExcluidos.splice(posicaoImportaTalhao, 1);
                    }
                }
            }
            if (!achou) {
                window.alert('O registro do talhão (id: "' + id + '") não foi encontrado nas listas.');
            }
        }
    }
}
exports.ImportaTalhaoController = ImportaTalhaoController;
ImportaTalhaoController.$inject = ['$scope', '$injector'];
