"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsuarioController = void 0;
const angular = require("angular");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const UiGridConstrutor_1 = require("../../../app/construtores/UiGridConstrutor");
const AbstratoController_1 = require("../../../app/AbstratoController");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const ICRUDOperacao_1 = require("../../../app/interfaces/ICRUDOperacao");
const Usuario_1 = require("../../../../../modelos/Usuario");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const GrupoPermissoesUsuario_1 = require("../../../../../io/GrupoPermissoesUsuario");
const config_app_1 = require("../../../../../config/config.app");
const GrupoPermissoesConstantes_1 = require("../../../../../constantes/GrupoPermissoesConstantes");
const UsuarioPermissao_1 = require("../../../../../modelos/UsuarioPermissao");
class UsuarioController extends AbstratoController_1.AbstratoController {
    constructor($scope, $injector) {
        super($scope, $injector, new Usuario_1.Usuario());
        this.MASCARA_SENHA = '********';
        this.path = '';
        this.caminho = '';
        this.gruposPermissoes = new Array();
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.importar = false;
            this.path = config_app_1.config.api.caminho;
            this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.USUARIO;
            if (!this.modelo) {
                this.modelo = new Usuario_1.Usuario();
            }
            if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                this.modelo.ativo = true;
            }
            if (this.operacao !== ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                this.modelo.senha = this.MASCARA_SENHA;
                this.modelo.confirmaSenha = this.MASCARA_SENHA;
            }
            else {
                this.modelo.senha = '';
                this.modelo.confirmaSenha = '';
            }
            yield this.carregaGrupoPermissoes();
            this.$scope.$apply();
        });
    }
    carregaGrupoPermissoes(usuario = null) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof usuario === 'undefined') || !usuario) {
                usuario = this.modelo;
            }
            this.gruposPermissoes = [...GrupoPermissoesConstantes_1.GrupoPermissoesConstantes.TODOS_GRUPOS_PERMISSOES];
            angular.forEach(this.gruposPermissoes, (grupoPermissoesUsuario) => {
                grupoPermissoesUsuario.tituloGrupoFormatado = grupoPermissoesUsuario.tituloGrupo
                    ? grupoPermissoesUsuario.tituloGrupo.replace(/<br>/g, '').trim() : '';
                grupoPermissoesUsuario.todosSubGruposPermissoes = [];
                let temSubGrupoPermissoesN2 = false;
                if ((typeof grupoPermissoesUsuario.subGruposPermissoes !== 'undefined') && grupoPermissoesUsuario.subGruposPermissoes) {
                    angular.forEach(grupoPermissoesUsuario.subGruposPermissoes, (subGrupoPermissoesUsuarioN2, keySubGrupoPermissoesUsuarioN2) => {
                        temSubGrupoPermissoesN2 = true;
                        subGrupoPermissoesUsuarioN2.tituloFormatado = subGrupoPermissoesUsuarioN2.titulo
                            ? subGrupoPermissoesUsuarioN2.titulo.replace(/<br>/g, '').trim() : '';
                        subGrupoPermissoesUsuarioN2.permissoesUsuario = new UsuarioPermissao_1.UsuarioPermissao();
                        if ((typeof subGrupoPermissoesUsuarioN2.permissoesHabilitadas !== 'undefined') && subGrupoPermissoesUsuarioN2.permissoesHabilitadas) {
                            let permissaoModulo = GrupoPermissoesUsuario_1.GrupoPermissoesUsuario.extrairPermissaoModuloRota(subGrupoPermissoesUsuarioN2.rota);
                            angular.forEach(Object.keys(subGrupoPermissoesUsuarioN2.permissoesHabilitadas), (permissao) => {
                                subGrupoPermissoesUsuarioN2.permissoesUsuario[permissao] = (usuario[permissaoModulo] && usuario[permissaoModulo][permissao]) ? usuario[permissaoModulo][permissao] : false;
                            });
                        }
                        let temSubGrupoPermissoesN3 = false;
                        if ((typeof subGrupoPermissoesUsuarioN2.subGruposPermissoes !== 'undefined') && subGrupoPermissoesUsuarioN2.subGruposPermissoes) {
                            angular.forEach(subGrupoPermissoesUsuarioN2.subGruposPermissoes, (subGrupoPermissoesUsuarioN3, keySubGrupoPermissoesUsuarioN3) => {
                                temSubGrupoPermissoesN3 = true;
                                subGrupoPermissoesUsuarioN3.tituloFormatado = subGrupoPermissoesUsuarioN3.titulo
                                    ? subGrupoPermissoesUsuarioN3.titulo.replace(/<br>/g, '').trim() : '';
                                subGrupoPermissoesUsuarioN3.permissoesUsuario = new UsuarioPermissao_1.UsuarioPermissao();
                                if ((typeof subGrupoPermissoesUsuarioN3.permissoesHabilitadas !== 'undefined') && subGrupoPermissoesUsuarioN3.permissoesHabilitadas) {
                                    let permissaoModulo = GrupoPermissoesUsuario_1.GrupoPermissoesUsuario.extrairPermissaoModuloRota(subGrupoPermissoesUsuarioN3.rota);
                                    angular.forEach(Object.keys(subGrupoPermissoesUsuarioN3.permissoesHabilitadas), (permissao) => {
                                        subGrupoPermissoesUsuarioN3.permissoesUsuario[permissao] = (usuario[permissaoModulo] && usuario[permissaoModulo][permissao]) ? usuario[permissaoModulo][permissao] : false;
                                    });
                                }
                                let temSubGrupoPermissoesN4 = false;
                                if ((typeof subGrupoPermissoesUsuarioN3.subGruposPermissoes !== 'undefined') && subGrupoPermissoesUsuarioN3.subGruposPermissoes) {
                                    angular.forEach(subGrupoPermissoesUsuarioN3.subGruposPermissoes, (subGrupoPermissoesUsuarioN4, keySubGrupoPermissoesUsuarioN4) => {
                                        temSubGrupoPermissoesN4 = true;
                                        subGrupoPermissoesUsuarioN4.tituloFormatado = subGrupoPermissoesUsuarioN4.titulo
                                            ? subGrupoPermissoesUsuarioN4.titulo.replace(/<br>/g, '').trim() : '';
                                        subGrupoPermissoesUsuarioN4.permissoesUsuario = new UsuarioPermissao_1.UsuarioPermissao();
                                        if ((typeof subGrupoPermissoesUsuarioN4.permissoesHabilitadas !== 'undefined') && subGrupoPermissoesUsuarioN4.permissoesHabilitadas) {
                                            let permissaoModulo = GrupoPermissoesUsuario_1.GrupoPermissoesUsuario.extrairPermissaoModuloRota(subGrupoPermissoesUsuarioN4.rota);
                                            angular.forEach(Object.keys(subGrupoPermissoesUsuarioN4.permissoesHabilitadas), (permissao) => {
                                                subGrupoPermissoesUsuarioN4.permissoesUsuario[permissao] = (usuario[permissaoModulo] && usuario[permissaoModulo][permissao]) ? usuario[permissaoModulo][permissao] : false;
                                            });
                                        }
                                        let temSubGrupoPermissoesN5 = false;
                                        if ((typeof subGrupoPermissoesUsuarioN4.subGruposPermissoes !== 'undefined') && subGrupoPermissoesUsuarioN4.subGruposPermissoes) {
                                            angular.forEach(subGrupoPermissoesUsuarioN4.subGruposPermissoes, (subGrupoPermissoesUsuarioN5, keySubGrupoPermissoesUsuarioN5) => {
                                                temSubGrupoPermissoesN5 = true;
                                                subGrupoPermissoesUsuarioN5.tituloFormatado = subGrupoPermissoesUsuarioN5.titulo
                                                    ? subGrupoPermissoesUsuarioN5.titulo.replace(/<br>/g, '').trim() : '';
                                                subGrupoPermissoesUsuarioN5.permissoesUsuario = new UsuarioPermissao_1.UsuarioPermissao();
                                                if ((typeof subGrupoPermissoesUsuarioN5.permissoesHabilitadas !== 'undefined') && subGrupoPermissoesUsuarioN5.permissoesHabilitadas) {
                                                    let permissaoModulo = GrupoPermissoesUsuario_1.GrupoPermissoesUsuario.extrairPermissaoModuloRota(subGrupoPermissoesUsuarioN5.rota);
                                                    angular.forEach(Object.keys(subGrupoPermissoesUsuarioN5.permissoesHabilitadas), (permissao) => {
                                                        subGrupoPermissoesUsuarioN5.permissoesUsuario[permissao] = (usuario[permissaoModulo] && usuario[permissaoModulo][permissao]) ? usuario[permissaoModulo][permissao] : false;
                                                    });
                                                }
                                                grupoPermissoesUsuario.todosSubGruposPermissoes.push({
                                                    keySubGrupoN2: keySubGrupoPermissoesUsuarioN2,
                                                    subGrupoN2: subGrupoPermissoesUsuarioN2,
                                                    keySubGrupoN3: keySubGrupoPermissoesUsuarioN3,
                                                    subGrupoN3: subGrupoPermissoesUsuarioN3,
                                                    keySubGrupoN4: keySubGrupoPermissoesUsuarioN4,
                                                    subGrupoN4: subGrupoPermissoesUsuarioN4,
                                                    keySubGrupoN5: keySubGrupoPermissoesUsuarioN5,
                                                    subGrupoN5: subGrupoPermissoesUsuarioN5,
                                                    ultimoSubGrupo: subGrupoPermissoesUsuarioN5
                                                });
                                            });
                                        }
                                        if (!temSubGrupoPermissoesN5) {
                                            grupoPermissoesUsuario.todosSubGruposPermissoes.push({
                                                keySubGrupoN2: keySubGrupoPermissoesUsuarioN2,
                                                subGrupoN2: subGrupoPermissoesUsuarioN2,
                                                keySubGrupoN3: keySubGrupoPermissoesUsuarioN3,
                                                subGrupoN3: subGrupoPermissoesUsuarioN3,
                                                keySubGrupoN4: keySubGrupoPermissoesUsuarioN4,
                                                subGrupoN4: subGrupoPermissoesUsuarioN4,
                                                keySubGrupoN5: null,
                                                subGrupoN5: null,
                                                ultimoSubGrupo: subGrupoPermissoesUsuarioN4
                                            });
                                        }
                                    });
                                }
                                if (!temSubGrupoPermissoesN4) {
                                    grupoPermissoesUsuario.todosSubGruposPermissoes.push({
                                        keySubGrupoN2: keySubGrupoPermissoesUsuarioN2,
                                        subGrupoN2: subGrupoPermissoesUsuarioN2,
                                        keySubGrupoN3: keySubGrupoPermissoesUsuarioN3,
                                        subGrupoN3: subGrupoPermissoesUsuarioN3,
                                        keySubGrupoN4: null,
                                        subGrupoN4: null,
                                        keySubGrupoN5: null,
                                        subGrupoN5: null,
                                        ultimoSubGrupo: subGrupoPermissoesUsuarioN3
                                    });
                                }
                            });
                        }
                        if (!temSubGrupoPermissoesN3) {
                            grupoPermissoesUsuario.todosSubGruposPermissoes.push({
                                keySubGrupoN2: keySubGrupoPermissoesUsuarioN2,
                                subGrupoN2: subGrupoPermissoesUsuarioN2,
                                keySubGrupoN3: null,
                                subGrupoN3: null,
                                keySubGrupoN4: null,
                                subGrupoN4: null,
                                keySubGrupoN5: null,
                                subGrupoN5: null,
                                ultimoSubGrupo: subGrupoPermissoesUsuarioN2
                            });
                        }
                    });
                }
                if (!temSubGrupoPermissoesN2) {
                    grupoPermissoesUsuario.todosSubGruposPermissoes.push({
                        keySubGrupoN2: null,
                        subGrupoN2: null,
                        keySubGrupoN3: null,
                        subGrupoN3: null,
                        keySubGrupoN4: null,
                        subGrupoN4: null,
                        keySubGrupoN5: null,
                        subGrupoN5: null,
                        ultimoSubGrupo: null
                    });
                }
            });
        });
    }
    salvar() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let resultado = null;
                if ((typeof this.modelo.senha !== 'undefined') && (this.modelo.senha !== null)) {
                    if (this.modelo.senha.trim().substring(0, 4) === this.MASCARA_SENHA.substring(0, 4)) {
                        this.modelo.senha = '';
                    }
                }
                else {
                    this.modelo.senha = '';
                }
                if ((typeof this.modelo.confirmaSenha !== 'undefined') && (this.modelo.confirmaSenha !== null)) {
                    if (this.modelo.confirmaSenha.trim().substring(0, 4) === this.MASCARA_SENHA.substring(0, 4)) {
                        this.modelo.confirmaSenha = '';
                    }
                }
                else {
                    this.modelo.confirmaSenha = '';
                }
                if ((typeof ctrl.gruposPermissoes !== 'undefined') && ctrl.gruposPermissoes) {
                    angular.forEach(ctrl.gruposPermissoes, (grupoPermissoesUsuario) => {
                        if ((typeof grupoPermissoesUsuario.subGruposPermissoes !== 'undefined') && grupoPermissoesUsuario.subGruposPermissoes) {
                            angular.forEach(grupoPermissoesUsuario.subGruposPermissoes, (subGrupoPermissoesN2) => {
                                if ((typeof subGrupoPermissoesN2.rota !== 'undefined') && subGrupoPermissoesN2.rota &&
                                    ((typeof subGrupoPermissoesN2.permissoesHabilitadas !== 'undefined') && subGrupoPermissoesN2.permissoesHabilitadas)) {
                                    if ((typeof subGrupoPermissoesN2.permissoesUsuario === 'undefined') || !subGrupoPermissoesN2.permissoesUsuario) {
                                        subGrupoPermissoesN2.permissoesUsuario = new UsuarioPermissao_1.UsuarioPermissao();
                                    }
                                    if ((typeof subGrupoPermissoesN2.permissoesHabilitadas !== 'undefined') && subGrupoPermissoesN2.permissoesHabilitadas) {
                                        let permissaoModulo = GrupoPermissoesUsuario_1.GrupoPermissoesUsuario.extrairPermissaoModuloRota(subGrupoPermissoesN2.rota);
                                        angular.forEach(Object.keys(subGrupoPermissoesN2.permissoesHabilitadas), (permissao) => {
                                            if ((typeof ctrl.modelo[permissaoModulo] === 'undefined') || !ctrl.modelo[permissaoModulo]) {
                                                ctrl.modelo[permissaoModulo] = new UsuarioPermissao_1.UsuarioPermissao();
                                            }
                                            ctrl.modelo[permissaoModulo][permissao] = subGrupoPermissoesN2.permissoesUsuario[permissao];
                                        });
                                    }
                                }
                                if ((typeof subGrupoPermissoesN2.subGruposPermissoes !== 'undefined') && subGrupoPermissoesN2.subGruposPermissoes) {
                                    angular.forEach(subGrupoPermissoesN2.subGruposPermissoes, (subGrupoPermissoesN3) => {
                                        if ((typeof subGrupoPermissoesN3.rota !== 'undefined') && subGrupoPermissoesN3.rota &&
                                            ((typeof subGrupoPermissoesN3.permissoesHabilitadas !== 'undefined') && subGrupoPermissoesN3.permissoesHabilitadas)) {
                                            if ((typeof subGrupoPermissoesN3.permissoesUsuario === 'undefined') || !subGrupoPermissoesN3.permissoesUsuario) {
                                                subGrupoPermissoesN3.permissoesUsuario = new UsuarioPermissao_1.UsuarioPermissao();
                                            }
                                            if ((typeof subGrupoPermissoesN3.permissoesHabilitadas !== 'undefined') && subGrupoPermissoesN3.permissoesHabilitadas) {
                                                let permissaoModulo = GrupoPermissoesUsuario_1.GrupoPermissoesUsuario.extrairPermissaoModuloRota(subGrupoPermissoesN3.rota);
                                                angular.forEach(Object.keys(subGrupoPermissoesN3.permissoesHabilitadas), (permissao) => {
                                                    if ((typeof ctrl.modelo[permissaoModulo] === 'undefined') || !ctrl.modelo[permissaoModulo]) {
                                                        ctrl.modelo[permissaoModulo] = new UsuarioPermissao_1.UsuarioPermissao();
                                                    }
                                                    ctrl.modelo[permissaoModulo][permissao] = subGrupoPermissoesN3.permissoesUsuario[permissao];
                                                });
                                            }
                                        }
                                        if ((typeof subGrupoPermissoesN3.subGruposPermissoes !== 'undefined') && subGrupoPermissoesN3.subGruposPermissoes) {
                                            angular.forEach(subGrupoPermissoesN3.subGruposPermissoes, (subGrupoPermissoesN4) => {
                                                if ((typeof subGrupoPermissoesN4.rota !== 'undefined') && subGrupoPermissoesN4.rota &&
                                                    ((typeof subGrupoPermissoesN4.permissoesHabilitadas !== 'undefined') && subGrupoPermissoesN4.permissoesHabilitadas)) {
                                                    if ((typeof subGrupoPermissoesN4.permissoesUsuario === 'undefined') || !subGrupoPermissoesN4.permissoesUsuario) {
                                                        subGrupoPermissoesN4.permissoesUsuario = new UsuarioPermissao_1.UsuarioPermissao();
                                                    }
                                                    if ((typeof subGrupoPermissoesN4.permissoesHabilitadas !== 'undefined') && subGrupoPermissoesN4.permissoesHabilitadas) {
                                                        let permissaoModulo = GrupoPermissoesUsuario_1.GrupoPermissoesUsuario.extrairPermissaoModuloRota(subGrupoPermissoesN4.rota);
                                                        angular.forEach(Object.keys(subGrupoPermissoesN4.permissoesHabilitadas), (permissao) => {
                                                            if ((typeof ctrl.modelo[permissaoModulo] === 'undefined') || !ctrl.modelo[permissaoModulo]) {
                                                                ctrl.modelo[permissaoModulo] = new UsuarioPermissao_1.UsuarioPermissao();
                                                            }
                                                            ctrl.modelo[permissaoModulo][permissao] = subGrupoPermissoesN4.permissoesUsuario[permissao];
                                                        });
                                                    }
                                                }
                                                if ((typeof subGrupoPermissoesN4.subGruposPermissoes !== 'undefined') && subGrupoPermissoesN4.subGruposPermissoes) {
                                                    angular.forEach(subGrupoPermissoesN4.subGruposPermissoes, (subGrupoPermissoesN5) => {
                                                        if ((typeof subGrupoPermissoesN5.rota !== 'undefined') && subGrupoPermissoesN5.rota &&
                                                            ((typeof subGrupoPermissoesN5.permissoesHabilitadas !== 'undefined') && subGrupoPermissoesN5.permissoesHabilitadas)) {
                                                            if ((typeof subGrupoPermissoesN5.permissoesUsuario === 'undefined') || !subGrupoPermissoesN5.permissoesUsuario) {
                                                                subGrupoPermissoesN5.permissoesUsuario = new UsuarioPermissao_1.UsuarioPermissao();
                                                            }
                                                            if ((typeof subGrupoPermissoesN5.permissoesHabilitadas !== 'undefined') && subGrupoPermissoesN5.permissoesHabilitadas) {
                                                                let permissaoModulo = GrupoPermissoesUsuario_1.GrupoPermissoesUsuario.extrairPermissaoModuloRota(subGrupoPermissoesN5.rota);
                                                                angular.forEach(Object.keys(subGrupoPermissoesN5.permissoesHabilitadas), (permissao) => {
                                                                    if ((typeof ctrl.modelo[permissaoModulo] === 'undefined') || !ctrl.modelo[permissaoModulo]) {
                                                                        ctrl.modelo[permissaoModulo] = new UsuarioPermissao_1.UsuarioPermissao();
                                                                    }
                                                                    ctrl.modelo[permissaoModulo][permissao] = subGrupoPermissoesN5.permissoesUsuario[permissao];
                                                                });
                                                            }
                                                        }
                                                    });
                                                }
                                            });
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
                if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    resultado = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.USUARIO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.USUARIO)
                        .criar(this.modelo);
                }
                else {
                    resultado = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.USUARIO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.USUARIO)
                        .atualizar(this.modelo);
                }
                if (resultado) {
                    this.$state.go(this.links.LISTAR.rota);
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    visualizar(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const usuario = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.USUARIO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.USUARIO)
                    .id(id)
                    .ver();
                usuario.criacao = new Date(usuario.criacao);
                if (this.operacao !== ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    usuario.senha = this.MASCARA_SENHA;
                    usuario.confirmaSenha = this.MASCARA_SENHA;
                }
                else {
                    usuario.senha = '';
                    usuario.confirmaSenha = '';
                }
                if ((typeof usuario.nivelUsuario !== 'undefined') && (usuario.nivelUsuario !== null)) {
                    usuario.nivelUsuarioRef = usuario.nivelUsuario.toString();
                }
                yield this.carregaGrupoPermissoes(usuario);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return usuario;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    remover(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const generico = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.USUARIO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.USUARIO)
                    .id(id)
                    .remover();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (generico) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const usuario = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.USUARIO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.USUARIO)
                    .id(this.modelo.id)
                    .restaurar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (usuario) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosLista(filtro = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                this.path = config_app_1.config.api.caminho;
                this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.USUARIO;
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
                const colCodigo = {
                    name: 'codigo',
                    displayName: 'Cód.',
                    width: 90,
                    posicao: 2
                };
                const colNomeCompleto = {
                    name: 'nomeCompleto',
                    displayName: 'Nome Completo',
                    width: 220,
                    posicao: 3
                };
                const colEmail = {
                    name: 'email',
                    displayName: 'E-Mail',
                    width: 250,
                    posicao: 4
                };
                const colAtivo = {
                    name: 'ativo',
                    displayName: 'Ativo',
                    width: 100,
                    posicao: 5,
                    cellClass: 'text-center',
                    cellTemplate: `
                        <div class="btn-group btn-ui-grid pt-50">
                            <span ng-if="row.entity.ativo" class="badge badge-success">Ativo</span>
                            <span ng-if="!row.entity.ativo" class="badge badge-danger">Inativo</span>
                        </div> `
                };
                gridConstrutor
                    .adicionarColuna(colCodigo)
                    .adicionarColuna(colNomeCompleto)
                    .adicionarColuna(colEmail)
                    .adicionarColuna(colAtivo);
                if (config_app_1.config.tipo !== config_app_1.TipoSistema.MATERIAL_GENETICO) {
                    const colTipoProducaoOperacao = {
                        name: 'tipoProducaoOperacao',
                        displayName: 'Produção/Operação',
                        width: 120,
                        posicao: 6,
                        cellClass: 'text-center',
                        cellTemplate: `
                            <div class="btn-group btn-ui-grid pt-50">
                                <span ng-if="row.entity.tipoProducaoOperacao" class="badge badge-primary">Sim</span>
                                <span ng-if="!row.entity.tipoProducaoOperacao" class="badge badge-warning" style="background-color: rgb(150,151,152);">Não</span>
                            </div> `
                    };
                    const colTipoAreaCliente = {
                        name: 'tipoAreaCliente',
                        displayName: 'Área Cliente',
                        width: 120,
                        posicao: 7,
                        cellClass: 'text-center',
                        cellTemplate: `
                            <div class="btn-group btn-ui-grid pt-50">
                                <span ng-if="row.entity.tipoAreaCliente" class="badge badge-primary">Sim</span>
                                <span ng-if="!row.entity.tipoAreaCliente" class="badge badge-warning" style="background-color: rgb(150,151,152);">Não</span>
                            </div> `
                    };
                    const colTipoQualidade = {
                        name: 'tipoQualidade',
                        displayName: 'Qualidade',
                        width: 100,
                        posicao: 8,
                        cellClass: 'text-center',
                        cellTemplate: `
                            <div class="btn-group btn-ui-grid pt-50">
                                <span ng-if="row.entity.tipoQualidade" class="badge badge-primary">Sim</span>
                                <span ng-if="!row.entity.tipoQualidade" class="badge badge-warning" style="background-color: rgb(150,151,152);">Não</span>
                            </div> `
                    };
                    gridConstrutor
                        .adicionarColuna(colTipoProducaoOperacao)
                        .adicionarColuna(colTipoAreaCliente)
                        .adicionarColuna(colTipoQualidade);
                }
                const lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.USUARIO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.USUARIO)
                    .filtro(filtro)
                    .listar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    filtrar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    limparFiltro() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    alterarNivelUsuario() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.modelo.nivelUsuarioRef !== 'undefined') && (this.modelo.nivelUsuarioRef !== null)) {
                this.modelo.nivelUsuario = parseInt(this.modelo.nivelUsuarioRef, 10);
            }
        });
    }
    entrarSenha() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.modelo) {
                return;
            }
            if ((typeof this.modelo.senha !== 'undefined') && (this.modelo.senha !== null)) {
                if (this.modelo.senha.trim().substring(0, 4) === this.MASCARA_SENHA.substring(0, 4)) {
                    this.modelo.senha = '';
                }
            }
            else {
                this.modelo.senha = '';
            }
        });
    }
    sairSenha() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.modelo) {
                return;
            }
            if ((typeof this.modelo.senha !== 'undefined') && (this.modelo.senha !== null)) {
                if ((this.modelo.senha.trim().length === 0) ||
                    (this.modelo.senha.trim().substring(0, 4) === this.MASCARA_SENHA.substring(0, 4))) {
                    if (this.operacao !== ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                        this.modelo.senha = this.MASCARA_SENHA;
                    }
                    else {
                        this.modelo.senha = '';
                    }
                }
            }
            else {
                if (this.operacao !== ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    this.modelo.senha = this.MASCARA_SENHA;
                }
                else {
                    this.modelo.senha = '';
                }
            }
        });
    }
    entrarConfirmaSenha() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.modelo) {
                return;
            }
            if ((typeof this.modelo.confirmaSenha !== 'undefined') && (this.modelo.confirmaSenha !== null)) {
                if (this.modelo.confirmaSenha.trim().substring(0, 4) === this.MASCARA_SENHA.substring(0, 4)) {
                    this.modelo.confirmaSenha = '';
                }
            }
            else {
                this.modelo.confirmaSenha = '';
            }
        });
    }
    sairConfirmaSenha() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.modelo) {
                return;
            }
            if ((typeof this.modelo.confirmaSenha !== 'undefined') && (this.modelo.confirmaSenha !== null)) {
                if ((this.modelo.confirmaSenha.trim().length === 0) ||
                    (this.modelo.confirmaSenha.trim().substring(0, 4) === this.MASCARA_SENHA.substring(0, 4))) {
                    if (this.operacao !== ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                        this.modelo.confirmaSenha = this.MASCARA_SENHA;
                    }
                    else {
                        this.modelo.confirmaSenha = '';
                    }
                }
            }
            else {
                if (this.operacao !== ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    this.modelo.confirmaSenha = this.MASCARA_SENHA;
                }
                else {
                    this.modelo.confirmaSenha = '';
                }
            }
        });
    }
    marcarTodos(grupoPermissoesUsuario) {
        GrupoPermissoesUsuario_1.GrupoPermissoesUsuario.marcarTodos(grupoPermissoesUsuario);
        this.scopeApply();
    }
    desmarcarTodos(grupoPermissoesUsuario) {
        GrupoPermissoesUsuario_1.GrupoPermissoesUsuario.desmarcarTodos(grupoPermissoesUsuario);
        this.scopeApply();
    }
}
exports.UsuarioController = UsuarioController;
UsuarioController.$inject = ['$scope', '$injector'];
