"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SumarioAnaliseSelecaoTesteGenetico = void 0;
const Base_1 = require("./Base");
class SumarioAnaliseSelecaoTesteGenetico extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.id = null;
        this.codigo = null;
        this.testeGenetico = null;
        this.descricao = null;
        this.anoAnalise = null;
        this.planilhas = [];
        this.planilhaSelecionada = null;
        this.anexos = [];
        this.observacoes = null;
        this.usuarioInclusao = null;
        this.dataHoraInclusao = null;
        this.usuarioAlteracao = null;
        this.dataHoraAlteracao = null;
    }
}
exports.SumarioAnaliseSelecaoTesteGenetico = SumarioAnaliseSelecaoTesteGenetico;
