"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TesteCampoDadosListaUtil = void 0;
const angular = require("angular");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const DataUtil_1 = require("../../../../../util/DataUtil");
const UiGridConstrutor_1 = require("../../../app/construtores/UiGridConstrutor");
const Pais_1 = require("../../../../../modelos/Pais");
const TextoUtil_1 = require("../../../../../util/TextoUtil");
const PerfilClimaticoPomar_1 = require("../../../../../modelos/PerfilClimaticoPomar");
const FiltroControleManejoTesteGenetico_1 = require("../../../../../io/FiltroControleManejoTesteGenetico");
const DispositivoUtil_1 = require("../../../../../util/DispositivoUtil");
const OperacaoManejoTesteCampo_1 = require("../../../../../modelos/OperacaoManejoTesteCampo");
const NumeroUtil_1 = require("../../../../../util/NumeroUtil");
const MaterialGenetico_1 = require("../../../../../modelos/MaterialGenetico");
const MedicaoDendrometricaTesteGenetico_1 = require("../../../../../modelos/MedicaoDendrometricaTesteGenetico");
const LancamentoMedicaoDendrometricaTesteGenetico_1 = require("../../../../../modelos/LancamentoMedicaoDendrometricaTesteGenetico");
const PlanejamentoMedicaoDendrometricaTesteGenetico_1 = require("../../../../../modelos/PlanejamentoMedicaoDendrometricaTesteGenetico");
const AnaliseSelecaoTesteGenetico_1 = require("../../../../../modelos/AnaliseSelecaoTesteGenetico");
const SumarioAnaliseSelecaoTesteGenetico_1 = require("../../../../../modelos/SumarioAnaliseSelecaoTesteGenetico");
const TipoAnaliseSelegenTesteGenetico_1 = require("../../../../../modelos/TipoAnaliseSelegenTesteGenetico");
const ResultadoSelegenAnaliseSelecaoTesteGenetico_1 = require("../../../../../modelos/ResultadoSelegenAnaliseSelecaoTesteGenetico");
const AnaliseAnoAnaliseSelecaoTesteGenetico_1 = require("../../../../../modelos/AnaliseAnoAnaliseSelecaoTesteGenetico");
const TipoTesteTratamentoTestemunha_1 = require("../../../../../modelos/TipoTesteTratamentoTestemunha");
const TipoAnexoQualidadeMadeira_1 = require("../../../../../modelos/TipoAnexoQualidadeMadeira");
const AnoAnaliseTesteGenetico_1 = require("../../../../../modelos/AnoAnaliseTesteGenetico");
const TesteGeneticoUtil_1 = require("../../../../../util/TesteGeneticoUtil");
class TesteCampoDadosListaUtil {
    static dadosListaTestesGeneticos(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            const funcaoOrigemCarregamento = 'dadosListaTestesGeneticos';
            try {
                ctrl.generosFiltro = [];
                ctrl.pomares = [];
                ctrl.populacoes = [];
                ctrl.conjuntos = [];
                ctrl.statusTestes = [];
                ctrl.especiesFiltro = [];
                ctrl.fazendasFiltro = [];
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                var auxColunas = Array();
                auxColunas.push({
                    name: 'genero.descricao',
                    displayName: 'Gênero',
                    width: 100
                });
                auxColunas.push({
                    name: 'especie.descricao',
                    displayName: 'Espécie',
                    width: 140
                });
                auxColunas.push({
                    name: 'fonte.descricao',
                    displayName: 'Origem',
                    width: 140
                });
                auxColunas.push({
                    name: 'tipoTestePopulacao.descricao',
                    displayName: 'População',
                    width: 220
                });
                auxColunas.push({
                    name: 'pomarOrigem.descricao',
                    displayName: 'Pomar',
                    width: 180
                });
                auxColunas.push({
                    name: 'cruzamento',
                    displayName: 'Cruzamento',
                    width: 120
                });
                auxColunas.push({
                    name: 'conjunto',
                    displayName: 'Conjunto',
                    width: 120
                });
                auxColunas.push({
                    name: 'dataImplantacao',
                    displayName: 'Data',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 100
                });
                auxColunas.push({
                    name: 'regiaoFazenda.nome',
                    displayName: 'Fazenda',
                    width: 130
                });
                auxColunas.push({
                    name: 'regiaoFazenda.numRegiao',
                    displayName: 'Região',
                    width: 80
                });
                auxColunas.push({
                    name: 'talhao.numero',
                    displayName: 'Talhão',
                    width: 90
                });
                auxColunas.push({
                    name: 'talhao.areaTalhao',
                    displayName: 'Área (Ha)',
                    width: 90
                });
                auxColunas.push({
                    name: 'idade',
                    displayName: 'Idade',
                    width: 90
                });
                auxColunas.push({
                    name: 'criacao',
                    displayName: 'Data/Hora Cadastro',
                    cellFilter: 'date:"dd/MM/yyyy HH:mm:ss"',
                    width: 190
                });
                auxColunas.push({
                    name: 'codigo',
                    displayName: 'Código',
                    width: 80
                });
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration', funcaoOrigemCarregamento);
                ctrl.listaTestesGeneticos = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TESTE_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TESTE_GENETICO)
                    .filtro({})
                    .listar();
                if ((typeof ctrl.listaTestesGeneticos !== 'undefined') && ctrl.listaTestesGeneticos) {
                    angular.forEach(ctrl.listaTestesGeneticos, (testeGenetico) => {
                        TesteGeneticoUtil_1.TesteGeneticoUtil.padronizaPropriedadesTesteGenetico(testeGenetico, ctrl.paises);
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                let posicaoColuna = 1;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                const gridOption = {
                    data: ctrl.listaTestesGeneticos,
                    columnDefs: gridConstrutor.$colunas,
                    controller: ctrl
                };
                return gridOption;
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
            }
            return null;
        });
    }
    static dadosListaManejoTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            const funcaoOrigemCarregamento = 'dadosListaManejoTesteGenetico';
            try {
                if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                    return { resumido: null, detalhado: null };
                }
                const gridConstrutorResumido = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                const gridConstrutorDetalhado = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutorResumido.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutorResumido.removerAcao();
                gridConstrutorDetalhado.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutorDetalhado.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card', funcaoOrigemCarregamento);
                if ((typeof ctrl.filtroControleManejoTesteGenetico === 'undefined') || !ctrl.filtroControleManejoTesteGenetico) {
                    ctrl.filtroControleManejoTesteGenetico = new FiltroControleManejoTesteGenetico_1.FiltroControleManejoTesteGenetico();
                }
                let filtro = new FiltroControleManejoTesteGenetico_1.FiltroControleManejoTesteGeneticoTratado();
                filtro.listaSimplificadaOnline = !DispositivoUtil_1.DispositivoUtil.ehCelular();
                filtro.testeGenetico = ctrl.testeGeneticoSelecionado.id;
                if ((typeof ctrl.filtroControleManejoTesteGenetico.dataIni !== 'undefined') && (ctrl.filtroControleManejoTesteGenetico.dataIni !== null)) {
                    filtro.dataIni = DataUtil_1.DataUtil.converterDataParaFormatoPHP(ctrl.filtroControleManejoTesteGenetico.dataIni);
                }
                if ((typeof ctrl.filtroControleManejoTesteGenetico.dataFim !== 'undefined') && (ctrl.filtroControleManejoTesteGenetico.dataFim !== null)) {
                    filtro.dataFim = DataUtil_1.DataUtil.converterDataParaFormatoPHP(ctrl.filtroControleManejoTesteGenetico.dataFim);
                }
                if (typeof ctrl.filtroControleManejoTesteGenetico.operacaoLimpeza !== 'undefined') {
                    filtro.operacaoLimpeza = ctrl.filtroControleManejoTesteGenetico.operacaoLimpeza ? true : false;
                }
                if (typeof ctrl.filtroControleManejoTesteGenetico.operacaoDesbaste !== 'undefined') {
                    filtro.operacaoDesbaste = ctrl.filtroControleManejoTesteGenetico.operacaoDesbaste ? true : false;
                }
                if (typeof ctrl.filtroControleManejoTesteGenetico.operacaoPolinizacao !== 'undefined') {
                    filtro.operacaoPolinizacao = ctrl.filtroControleManejoTesteGenetico.operacaoPolinizacao ? true : false;
                }
                if (typeof ctrl.filtroControleManejoTesteGenetico.operacaoColetaAmostras !== 'undefined') {
                    filtro.operacaoColetaAmostras = ctrl.filtroControleManejoTesteGenetico.operacaoColetaAmostras ? true : false;
                }
                if (typeof ctrl.filtroControleManejoTesteGenetico.operacaoAdubacaoFertilizacao !== 'undefined') {
                    filtro.operacaoAdubacaoFertilizacao = ctrl.filtroControleManejoTesteGenetico.operacaoAdubacaoFertilizacao ? true : false;
                }
                if (typeof ctrl.filtroControleManejoTesteGenetico.operacaoColetaSementes !== 'undefined') {
                    filtro.operacaoColetaSementes = ctrl.filtroControleManejoTesteGenetico.operacaoColetaSementes ? true : false;
                }
                if (typeof ctrl.filtroControleManejoTesteGenetico.operacaoControleDoencas !== 'undefined') {
                    filtro.operacaoControleDoencas = ctrl.filtroControleManejoTesteGenetico.operacaoControleDoencas ? true : false;
                }
                if (typeof ctrl.filtroControleManejoTesteGenetico.operacaoPodaRamosPortaEnxerto !== 'undefined') {
                    filtro.operacaoPodaRamosPortaEnxerto = ctrl.filtroControleManejoTesteGenetico.operacaoPodaRamosPortaEnxerto ? true : false;
                }
                if (typeof ctrl.filtroControleManejoTesteGenetico.operacaoAvaliacaoSobrevivencia !== 'undefined') {
                    filtro.operacaoAvaliacaoSobrevivencia = ctrl.filtroControleManejoTesteGenetico.operacaoAvaliacaoSobrevivencia ? true : false;
                }
                if (typeof ctrl.filtroControleManejoTesteGenetico.operacaoReplantio !== 'undefined') {
                    filtro.operacaoReplantio = ctrl.filtroControleManejoTesteGenetico.operacaoReplantio ? true : false;
                }
                if (typeof ctrl.filtroControleManejoTesteGenetico.outrasOperacoes !== 'undefined') {
                    filtro.outrasOperacoes = ctrl.filtroControleManejoTesteGenetico.outrasOperacoes ? true : false;
                }
                let listaDetalhada = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.CONTROLE_MANEJO_TESTE_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CONTROLE_MANEJO_TESTE_GENETICO)
                    .filtro(filtro)
                    .listar();
                let listaResumida = [];
                if (listaDetalhada && (listaDetalhada.length > 0)) {
                    angular.forEach(listaDetalhada, (controleManejoTesteGenetico) => {
                        let mesAno = null;
                        if ((typeof controleManejoTesteGenetico.criacao !== 'undefined') && controleManejoTesteGenetico.criacao) {
                            controleManejoTesteGenetico.criacao = new Date(controleManejoTesteGenetico.criacao);
                            mesAno = DataUtil_1.DataUtil.converterDataParaFormatoMMYYYY(controleManejoTesteGenetico.criacao);
                        }
                        if ((typeof controleManejoTesteGenetico.dataHoraInclusao !== 'undefined') && controleManejoTesteGenetico.dataHoraInclusao) {
                            controleManejoTesteGenetico.dataHoraInclusao = new Date(controleManejoTesteGenetico.dataHoraInclusao);
                        }
                        if ((typeof controleManejoTesteGenetico.dataHoraAlteracao !== 'undefined') && controleManejoTesteGenetico.dataHoraAlteracao) {
                            controleManejoTesteGenetico.dataHoraAlteracao = new Date(controleManejoTesteGenetico.dataHoraAlteracao);
                        }
                        if ((typeof controleManejoTesteGenetico.operacao !== 'undefined') && controleManejoTesteGenetico.operacao &&
                            (typeof controleManejoTesteGenetico.operacao.tipoOperacao !== 'undefined') && controleManejoTesteGenetico.operacao.tipoOperacao) {
                            controleManejoTesteGenetico.operacao.ehOperacaoLimpeza = (controleManejoTesteGenetico.operacao.tipoOperacao === OperacaoManejoTesteCampo_1.OperacaoManejoTesteCampo.OPERACAO_LIMPEZA);
                            controleManejoTesteGenetico.operacao.ehOperacaoDesbaste = (controleManejoTesteGenetico.operacao.tipoOperacao === OperacaoManejoTesteCampo_1.OperacaoManejoTesteCampo.OPERACAO_DESBASTE);
                            controleManejoTesteGenetico.operacao.ehOperacaoPolinizacao = (controleManejoTesteGenetico.operacao.tipoOperacao === OperacaoManejoTesteCampo_1.OperacaoManejoTesteCampo.OPERACAO_POLINIZACAO);
                            controleManejoTesteGenetico.operacao.ehOperacaoColetaAmostras = (controleManejoTesteGenetico.operacao.tipoOperacao === OperacaoManejoTesteCampo_1.OperacaoManejoTesteCampo.OPERACAO_COLETA_AMOSTRAS);
                            controleManejoTesteGenetico.operacao.ehOperacaoColetaSementes = (controleManejoTesteGenetico.operacao.tipoOperacao === OperacaoManejoTesteCampo_1.OperacaoManejoTesteCampo.OPERACAO_COLETA_SEMENTES);
                            controleManejoTesteGenetico.operacao.ehOperacaoControleDoencas = (controleManejoTesteGenetico.operacao.tipoOperacao === OperacaoManejoTesteCampo_1.OperacaoManejoTesteCampo.OPERACAO_CONTROLE_DOENCAS);
                            controleManejoTesteGenetico.operacao.ehOperacaoAdubacaoFertilizacao = (controleManejoTesteGenetico.operacao.tipoOperacao === OperacaoManejoTesteCampo_1.OperacaoManejoTesteCampo.OPERACAO_ADUBACAO_FERTILIZACAO);
                            controleManejoTesteGenetico.operacao.ehOperacaoPodaRamosPortaEnxerto = (controleManejoTesteGenetico.operacao.tipoOperacao === OperacaoManejoTesteCampo_1.OperacaoManejoTesteCampo.OPERACAO_PODA_RAMOS_PORTA_ENXERTO);
                            controleManejoTesteGenetico.operacao.ehOperacaoAvaliacaoSobrevivencia = (controleManejoTesteGenetico.operacao.tipoOperacao === OperacaoManejoTesteCampo_1.OperacaoManejoTesteCampo.OPERACAO_AVALIACAO_SOBREVIVENCIA);
                            controleManejoTesteGenetico.operacao.ehOperacaoReplantio = (controleManejoTesteGenetico.operacao.tipoOperacao === OperacaoManejoTesteCampo_1.OperacaoManejoTesteCampo.OPERACAO_REPLANTIO);
                            controleManejoTesteGenetico.operacao.ehOutrasOperacoes = (controleManejoTesteGenetico.operacao.tipoOperacao === OperacaoManejoTesteCampo_1.OperacaoManejoTesteCampo.OUTRAS_OPERACOES);
                        }
                        if (mesAno) {
                            let achouRegistroResumido = false;
                            angular.forEach(listaResumida, (registroResumido) => {
                                if (!achouRegistroResumido && (mesAno === registroResumido.mesAno)) {
                                    achouRegistroResumido = true;
                                    registroResumido.operacaoLimpeza += (controleManejoTesteGenetico.operacao && controleManejoTesteGenetico.operacao.ehOperacaoLimpeza ? 1 : 0);
                                    registroResumido.operacaoDesbaste += (controleManejoTesteGenetico.operacao && controleManejoTesteGenetico.operacao.ehOperacaoDesbaste ? 1 : 0);
                                    registroResumido.operacaoPolinizacao += (controleManejoTesteGenetico.operacao && controleManejoTesteGenetico.operacao.ehOperacaoPolinizacao ? 1 : 0);
                                    registroResumido.operacaoColetaAmostras += (controleManejoTesteGenetico.operacao && controleManejoTesteGenetico.operacao.ehOperacaoColetaAmostras ? 1 : 0);
                                    registroResumido.operacaoColetaSementes += (controleManejoTesteGenetico.operacao && controleManejoTesteGenetico.operacao.ehOperacaoColetaSementes ? 1 : 0);
                                    registroResumido.operacaoControleDoencas += (controleManejoTesteGenetico.operacao && controleManejoTesteGenetico.operacao.ehOperacaoControleDoencas ? 1 : 0);
                                    registroResumido.operacaoAdubacaoFertilizacao += (controleManejoTesteGenetico.operacao && controleManejoTesteGenetico.operacao.ehOperacaoAdubacaoFertilizacao ? 1 : 0);
                                    registroResumido.operacaoPodaRamosPortaEnxerto += (controleManejoTesteGenetico.operacao && controleManejoTesteGenetico.operacao.ehOperacaoPodaRamosPortaEnxerto ? 1 : 0);
                                    registroResumido.operacaoAvaliacaoSobrevivencia += (controleManejoTesteGenetico.operacao && controleManejoTesteGenetico.operacao.ehOperacaoAvaliacaoSobrevivencia ? 1 : 0);
                                    registroResumido.operacaoReplantio += (controleManejoTesteGenetico.operacao && controleManejoTesteGenetico.operacao.ehOperacaoReplantio ? 1 : 0);
                                    registroResumido.outrasOperacoes += (controleManejoTesteGenetico.operacao && controleManejoTesteGenetico.operacao.ehOutrasOperacoes ? 1 : 0);
                                }
                            });
                            if (!achouRegistroResumido) {
                                listaResumida.push({
                                    mesAno: mesAno,
                                    operacaoLimpeza: (controleManejoTesteGenetico.operacao && controleManejoTesteGenetico.operacao.ehOperacaoLimpeza ? 1 : 0),
                                    operacaoDesbaste: (controleManejoTesteGenetico.operacao && controleManejoTesteGenetico.operacao.ehOperacaoDesbaste ? 1 : 0),
                                    operacaoPolinizacao: (controleManejoTesteGenetico.operacao && controleManejoTesteGenetico.operacao.ehOperacaoPolinizacao ? 1 : 0),
                                    operacaoColetaAmostras: (controleManejoTesteGenetico.operacao && controleManejoTesteGenetico.operacao.ehOperacaoColetaAmostras ? 1 : 0),
                                    operacaoColetaSementes: (controleManejoTesteGenetico.operacao && controleManejoTesteGenetico.operacao.ehOperacaoColetaSementes ? 1 : 0),
                                    operacaoControleDoencas: (controleManejoTesteGenetico.operacao && controleManejoTesteGenetico.operacao.ehOperacaoControleDoencas ? 1 : 0),
                                    operacaoAdubacaoFertilizacao: (controleManejoTesteGenetico.operacao && controleManejoTesteGenetico.operacao.ehOperacaoAdubacaoFertilizacao ? 1 : 0),
                                    operacaoPodaRamosPortaEnxerto: (controleManejoTesteGenetico.operacao && controleManejoTesteGenetico.operacao.ehOperacaoPodaRamosPortaEnxerto ? 1 : 0),
                                    operacaoAvaliacaoSobrevivencia: (controleManejoTesteGenetico.operacao && controleManejoTesteGenetico.operacao.ehOperacaoAvaliacaoSobrevivencia ? 1 : 0),
                                    operacaoReplantio: (controleManejoTesteGenetico.operacao && controleManejoTesteGenetico.operacao.ehOperacaoReplantio ? 1 : 0),
                                    outrasOperacoes: (controleManejoTesteGenetico.operacao && controleManejoTesteGenetico.operacao.ehOutrasOperacoes ? 1 : 0),
                                });
                            }
                        }
                        let detalhamento = '';
                        if (controleManejoTesteGenetico.operacao && controleManejoTesteGenetico.operacao.ehOperacaoLimpeza) {
                            if ((typeof controleManejoTesteGenetico.limpezas !== 'undefined') && controleManejoTesteGenetico.limpezas) {
                                let numLimpeza = 0;
                                angular.forEach(controleManejoTesteGenetico.limpezas, (limpeza) => {
                                    numLimpeza++;
                                    const tipoLimpeza = ((typeof limpeza.tipoLimpeza !== 'undefined') && limpeza.tipoLimpeza && (typeof limpeza.tipoLimpeza.descricao !== 'undefined') && limpeza.tipoLimpeza.descricao) ? limpeza.tipoLimpeza.descricao : '(Tipo não informado)';
                                    const produto = ((typeof limpeza.produto !== 'undefined') && limpeza.produto && (typeof limpeza.produto.descricao !== 'undefined') && limpeza.produto.descricao) ? limpeza.produto.descricao : null;
                                    const dosagemProduto = ((typeof limpeza.dosagemProduto !== 'undefined') && limpeza.dosagemProduto) ? '' + NumeroUtil_1.NumeroUtil.arredondarNumero(limpeza.dosagemProduto, 2) : null;
                                    detalhamento += ((detalhamento.length > 0) ? '<br>' : '') + '#' + numLimpeza + '. Limpeza: ' + tipoLimpeza +
                                        (produto ? ', Produto: ' + produto + (dosagemProduto ? ', Dosagem: ' + dosagemProduto : '') : '');
                                });
                                if (numLimpeza === 1) {
                                    detalhamento = detalhamento.replace('#1. ', '');
                                }
                            }
                        }
                        else if (controleManejoTesteGenetico.operacao && controleManejoTesteGenetico.operacao.ehOperacaoDesbaste) {
                            if ((typeof controleManejoTesteGenetico.desbastes !== 'undefined') && controleManejoTesteGenetico.desbastes) {
                                let numDesbaste = 0;
                                angular.forEach(controleManejoTesteGenetico.desbastes, (desbaste) => {
                                    numDesbaste++;
                                    const materialGenetico = ((typeof desbaste.materialGenetico !== 'undefined') && desbaste.materialGenetico && (typeof desbaste.materialGenetico.descricao !== 'undefined') && desbaste.materialGenetico.descricao) ? desbaste.materialGenetico.descricao : '(Nenhum)';
                                    const tipoDesbaste = ((typeof desbaste.tipoDesbaste !== 'undefined') && desbaste.tipoDesbaste && (typeof desbaste.tipoDesbaste.descricao !== 'undefined') && desbaste.tipoDesbaste.descricao) ? desbaste.tipoDesbaste.descricao : '(Não informado)';
                                    const numRametes = ((typeof desbaste.numRametes !== 'undefined') && desbaste.numRametes) ? '' + NumeroUtil_1.NumeroUtil.arredondarNumero(desbaste.numRametes, 2) : null;
                                    detalhamento += ((detalhamento.length > 0) ? '<br>' : '') + '#' + numDesbaste + '. Material Genético: ' + materialGenetico +
                                        ', Desbaste: ' + tipoDesbaste + (numRametes ? ', Núm. Rametes: ' + numRametes : '');
                                });
                                if (numDesbaste === 1) {
                                    detalhamento = detalhamento.replace('#1. ', '');
                                }
                            }
                        }
                        else if (controleManejoTesteGenetico.operacao && controleManejoTesteGenetico.operacao.ehOperacaoPolinizacao) {
                            if ((typeof controleManejoTesteGenetico.polinizacoes !== 'undefined') && controleManejoTesteGenetico.polinizacoes) {
                                let numPolinizacao = 0;
                                if (numPolinizacao === 1) {
                                    detalhamento = detalhamento.replace('#1. ', '');
                                }
                            }
                        }
                        else if (controleManejoTesteGenetico.operacao && controleManejoTesteGenetico.operacao.ehOperacaoColetaAmostras) {
                            if ((typeof controleManejoTesteGenetico.coletasAmostras !== 'undefined') && controleManejoTesteGenetico.coletasAmostras) {
                                let numColetaAmostra = 0;
                                angular.forEach(controleManejoTesteGenetico.coletasAmostras, (coletaAmostra) => {
                                    numColetaAmostra++;
                                    const materialGenetico = ((typeof coletaAmostra.materialGenetico !== 'undefined') && coletaAmostra.materialGenetico && (typeof coletaAmostra.materialGenetico.descricao !== 'undefined') && coletaAmostra.materialGenetico.descricao) ? coletaAmostra.materialGenetico.descricao : '(Nenhum)';
                                    const tipoColetaAmostra = ((typeof coletaAmostra.tipoColetaAmostra !== 'undefined') && coletaAmostra.tipoColetaAmostra && (typeof coletaAmostra.tipoColetaAmostra.descricao !== 'undefined') && coletaAmostra.tipoColetaAmostra.descricao) ? coletaAmostra.tipoColetaAmostra.descricao : '(Não informado)';
                                    const quantidadeAmostras = ((typeof coletaAmostra.quantidadeAmostras !== 'undefined') && coletaAmostra.quantidadeAmostras) ? '' + NumeroUtil_1.NumeroUtil.arredondarNumero(coletaAmostra.quantidadeAmostras, 2) : null;
                                    detalhamento += ((detalhamento.length > 0) ? '<br>' : '') + '#' + numColetaAmostra + '. Material Genético: ' + materialGenetico +
                                        ', Tipo Coleta: ' + tipoColetaAmostra + (quantidadeAmostras ? ', Quant. Amostras: ' + quantidadeAmostras : '');
                                });
                                if (numColetaAmostra === 1) {
                                    detalhamento = detalhamento.replace('#1. ', '');
                                }
                            }
                        }
                        else if (controleManejoTesteGenetico.operacao && controleManejoTesteGenetico.operacao.ehOperacaoAdubacaoFertilizacao) {
                            if ((typeof controleManejoTesteGenetico.adubacoesFertilizacoes !== 'undefined') && controleManejoTesteGenetico.adubacoesFertilizacoes) {
                                let numAdubacaoFertilizacao = 0;
                                angular.forEach(controleManejoTesteGenetico.adubacoesFertilizacoes, (adubacaoFertilizacao) => {
                                    numAdubacaoFertilizacao++;
                                    const tipoAplicacao = ((typeof adubacaoFertilizacao.tipoAplicacao !== 'undefined') && adubacaoFertilizacao.tipoAplicacao && (typeof adubacaoFertilizacao.tipoAplicacao.descricao !== 'undefined') && adubacaoFertilizacao.tipoAplicacao.descricao) ? adubacaoFertilizacao.tipoAplicacao.descricao : '(Tipo não informado)';
                                    const produto = ((typeof adubacaoFertilizacao.produto !== 'undefined') && adubacaoFertilizacao.produto && (typeof adubacaoFertilizacao.produto.descricao !== 'undefined') && adubacaoFertilizacao.produto.descricao) ? adubacaoFertilizacao.produto.descricao : null;
                                    const dosagemProduto = ((typeof adubacaoFertilizacao.dosagemProduto !== 'undefined') && adubacaoFertilizacao.dosagemProduto) ? '' + NumeroUtil_1.NumeroUtil.arredondarNumero(adubacaoFertilizacao.dosagemProduto, 2) : null;
                                    detalhamento += ((detalhamento.length > 0) ? '<br>' : '') + '#' + numAdubacaoFertilizacao + '. Tipo Aplicação: ' + tipoAplicacao +
                                        (produto ? ', Produto: ' + produto + (dosagemProduto ? ', Dosagem: ' + dosagemProduto : '') : '');
                                });
                                if (numAdubacaoFertilizacao === 1) {
                                    detalhamento = detalhamento.replace('#1. ', '');
                                }
                            }
                        }
                        else if (controleManejoTesteGenetico.operacao && controleManejoTesteGenetico.operacao.ehOperacaoColetaSementes) {
                            if ((typeof controleManejoTesteGenetico.coletasSementes !== 'undefined') && controleManejoTesteGenetico.coletasSementes) {
                                let numColetaSemente = 0;
                                angular.forEach(controleManejoTesteGenetico.coletasSementes, (coletaSemente) => {
                                    numColetaSemente++;
                                    const materialGenetico = ((typeof coletaSemente.materialGenetico !== 'undefined') && coletaSemente.materialGenetico && (typeof coletaSemente.materialGenetico.descricao !== 'undefined') && coletaSemente.materialGenetico.descricao) ? coletaSemente.materialGenetico.descricao : '(Nenhum)';
                                    const anoPolinizacao = ((typeof coletaSemente.anoPolinizacao !== 'undefined') && coletaSemente.anoPolinizacao) ? '' + NumeroUtil_1.NumeroUtil.arredondarNumero(coletaSemente.anoPolinizacao, 2) : null;
                                    const quantidade = ((typeof coletaSemente.quantidade !== 'undefined') && coletaSemente.quantidade) ? '' + NumeroUtil_1.NumeroUtil.arredondarNumero(coletaSemente.quantidade, 2) : null;
                                    const numArvores = ((typeof coletaSemente.numArvores !== 'undefined') && coletaSemente.numArvores) ? '' + NumeroUtil_1.NumeroUtil.arredondarNumero(coletaSemente.numArvores, 2) : null;
                                    const numCones = ((typeof coletaSemente.numCones !== 'undefined') && coletaSemente.numCones) ? '' + NumeroUtil_1.NumeroUtil.arredondarNumero(coletaSemente.numCones, 2) : null;
                                    detalhamento += ((detalhamento.length > 0) ? '<br>' : '') + '#' + numColetaSemente + '. ' +
                                        (controleManejoTesteGenetico.ehOperacional ? '[ Operacional ] ' : (controleManejoTesteGenetico.ehPesquisa ? '[ Pesquisa ] ' : '')) +
                                        'Material Genético: ' + materialGenetico +
                                        (anoPolinizacao ? ', Ano Polinização: ' + anoPolinizacao : '') +
                                        (quantidade ? ', Quantidade (g): ' + quantidade : '') +
                                        (quantidade ? ', Árvores: ' + numArvores : '') +
                                        (numCones ? ', Nº Cones: ' + numCones : '');
                                });
                                if (numColetaSemente === 1) {
                                    detalhamento = detalhamento.replace('#1. ', '');
                                }
                            }
                        }
                        else if (controleManejoTesteGenetico.operacao && controleManejoTesteGenetico.operacao.ehOperacaoControleDoencas) {
                            if ((typeof controleManejoTesteGenetico.doencas !== 'undefined') && controleManejoTesteGenetico.doencas) {
                                let numControleDoenca = 0;
                                angular.forEach(controleManejoTesteGenetico.doencas, (controleDoenca) => {
                                    numControleDoenca++;
                                    const materialGenetico = ((typeof controleDoenca.materialGenetico !== 'undefined') && controleDoenca.materialGenetico && (typeof controleDoenca.materialGenetico.descricao !== 'undefined') && controleDoenca.materialGenetico.descricao) ? controleDoenca.materialGenetico.descricao : '(Nenhum)';
                                    const tipoDoenca = ((typeof controleDoenca.tipoDoenca !== 'undefined') && controleDoenca.tipoDoenca && (typeof controleDoenca.tipoDoenca.descricao !== 'undefined') && controleDoenca.tipoDoenca.descricao) ? controleDoenca.tipoDoenca.descricao : '(Tipo não informado)';
                                    const tipoIntervencaoDoenca = ((typeof controleDoenca.tipoIntervencaoDoenca !== 'undefined') && controleDoenca.tipoIntervencaoDoenca && (typeof controleDoenca.tipoIntervencaoDoenca.descricao !== 'undefined') && controleDoenca.tipoIntervencaoDoenca.descricao) ? controleDoenca.tipoIntervencaoDoenca.descricao : null;
                                    const produto = ((typeof controleDoenca.produto !== 'undefined') && controleDoenca.produto && (typeof controleDoenca.produto.descricao !== 'undefined') && controleDoenca.produto.descricao) ? controleDoenca.produto.descricao : null;
                                    const quantidadeArvores = ((typeof controleDoenca.quantidadeArvores !== 'undefined') && controleDoenca.quantidadeArvores) ? '' + NumeroUtil_1.NumeroUtil.arredondarNumero(controleDoenca.quantidadeArvores, 2) : null;
                                    detalhamento += ((detalhamento.length > 0) ? '<br>' : '') + '#' + numControleDoenca + '. Material Genético: ' + materialGenetico +
                                        (tipoDoenca ? ', Doença: ' + tipoDoenca : '') +
                                        (tipoIntervencaoDoenca ? ', Intervenção: ' + tipoIntervencaoDoenca : '') +
                                        (produto ? ', Produto: ' + produto : '') +
                                        (quantidadeArvores ? ', Quant. Árvores: ' + quantidadeArvores : '');
                                });
                                if (numControleDoenca === 1) {
                                    detalhamento = detalhamento.replace('#1. ', '');
                                }
                            }
                        }
                        else if (controleManejoTesteGenetico.operacao && controleManejoTesteGenetico.operacao.ehOperacaoPodaRamosPortaEnxerto) {
                            detalhamento = '-';
                        }
                        else if (controleManejoTesteGenetico.operacao && controleManejoTesteGenetico.operacao.ehOutrasOperacoes) {
                            detalhamento = '-';
                        }
                        controleManejoTesteGenetico.descricaoDetalhada = detalhamento.substring(0, 1000).replace(/\<br\>/g, '<br>\r\n');
                        controleManejoTesteGenetico.descricaoResumida = detalhamento.substring(0, 250).replace('#1. ', '').replace(/\<br\>/g, ' | ');
                    });
                }
                let auxColunasResumido = new Array();
                let auxColunasDetalhado = new Array();
                auxColunasDetalhado.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    cellClass: 'text-center',
                    cellTemplate: `
                        <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                            <button type="button" class="btn btn-icon bx bx-dots-vertical-rounded" uib-dropdown-toggle></button>
                            <ul class="dropdown-menu dropdown-menu-left" uib-dropdown-menu role="menu">
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalControleManejoTesteGenetico({ id: row.entity.id || row.entity.uuid, visualizar: true })' class="text-secondary w-100"><i class="bx bx-show mr-1 ml-1"></i>Visualizar</a></li>
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalControleManejoTesteGenetico({ id: row.entity.id || row.entity.uuid, editar: true })' class="text-secondary w-100"><i class="bx bxs-edit-alt mr-1 ml-1"></i>Editar</a></li>
                                <li><hr></li>
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalControleManejoTesteGenetico({ id: row.entity.id || row.entity.uuid, excluir: true })' class="text-secondary w-100"><i class="bx bxs-trash-alt mr-1 ml-1"></i>Excluir</a></li>
                            </ul>
                        </div> `
                });
                auxColunasDetalhado.push({
                    name: 'data',
                    displayName: 'Data',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 120
                });
                auxColunasDetalhado.push({
                    name: 'operacao.descricao',
                    displayName: 'Operação',
                    width: 180
                });
                auxColunasDetalhado.push({
                    name: 'descricaoResumida',
                    displayName: 'Detalhamento',
                    width: 400,
                    cellTemplate: `<div class="grid-tooltip" data-trigger="hover" data-placement="bottom"
                    data-title="{{row.entity.descricaoDetalhada}}" data-html="true" data-offset="0,4" bs-tooltip>
                    <div class="ui-grid-cell-contents">
                        {{row.entity.descricaoResumida}}
                    </div>
                </div>`
                });
                auxColunasDetalhado.push({
                    name: 'prazo',
                    displayName: 'Prazo',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 120
                });
                auxColunasDetalhado.push({
                    name: 'situacao',
                    displayName: 'Status',
                    width: 80,
                    cellClass: (_grid, row) => {
                        if (row.entity.situacao === 'SOLICITADO') {
                            return 'fundoAmarelo';
                        }
                        else if (row.entity.situacao === 'ATRASADO') {
                            return 'fundoVermelho';
                        }
                        else if (row.entity.situacao === 'CONCLUIDO') {
                            return 'fundoVerde';
                        }
                        return null;
                    }
                });
                auxColunasDetalhado.push({
                    name: 'dataRealizado',
                    displayName: 'Data Realizado',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 130
                });
                auxColunasDetalhado.push({
                    name: 'observacoes',
                    displayName: 'Observações',
                    width: 180
                });
                auxColunasDetalhado.push({
                    name: 'conferencia',
                    displayName: 'Conferência',
                    width: 120
                });
                auxColunasResumido.push({
                    name: 'mesAno',
                    displayName: 'Mês/Ano',
                    width: 100
                });
                auxColunasResumido.push({
                    name: 'desbastes',
                    displayName: 'Desbastes',
                    width: 100
                });
                auxColunasResumido.push({
                    name: 'tratamentos',
                    displayName: 'Tratamentos',
                    width: 120
                });
                auxColunasResumido.push({
                    name: 'mediaPorHectare',
                    displayName: 'Média por Ha',
                    width: 120
                });
                auxColunasResumido.push({
                    name: 'numArvores',
                    displayName: 'Nº de Árvores',
                    width: 120
                });
                auxColunasResumido.push({
                    name: 'adubacaoFormulacao',
                    displayName: 'Formulação',
                    width: 100
                });
                auxColunasResumido.push({
                    name: 'adubacaoQuantidade',
                    displayName: 'Quantidade',
                    width: 100
                });
                auxColunasResumido.push({
                    name: 'produtividade',
                    displayName: 'Produtividade',
                    width: 120
                });
                let posicaoColunaResumido = 0;
                angular.forEach(auxColunasResumido, (coluna) => {
                    posicaoColunaResumido++;
                    coluna.posicao = posicaoColunaResumido;
                    gridConstrutorResumido.adicionarColuna(coluna);
                });
                let posicaoColunaDetalhado = 0;
                angular.forEach(auxColunasDetalhado, (coluna) => {
                    posicaoColunaDetalhado++;
                    coluna.posicao = posicaoColunaDetalhado;
                    gridConstrutorDetalhado.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                return {
                    resumido: {
                        data: listaResumida,
                        columnDefs: gridConstrutorResumido.$colunas,
                        enableSorting: true,
                        controller: ctrl
                    },
                    detalhado: {
                        data: listaDetalhada,
                        columnDefs: gridConstrutorDetalhado.$colunas,
                        enableSorting: true,
                        controller: ctrl
                    }
                };
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
            }
            return null;
        });
    }
    static dadosListaTratamentosTesteGenetico(ctrl, seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const funcaoOrigemCarregamento = 'dadosListaTratamentosTesteGenetico';
            try {
                if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(seletorCarregamento ? seletorCarregamento : '.card', funcaoOrigemCarregamento);
                ctrl.listaTratamentosTesteGenetico = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TRATAMENTO_TESTE_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TRATAMENTO_TESTE_GENETICO)
                    .filtro({
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    testeGenetico: ctrl.testeGeneticoSelecionado.id
                })
                    .listar();
                if ((typeof ctrl.listaTratamentosTesteGenetico !== 'undefined') && ctrl.listaTratamentosTesteGenetico) {
                    angular.forEach(ctrl.listaTratamentosTesteGenetico, (tratamento) => {
                        if ((typeof tratamento.tipoTeste !== 'undefined') && tratamento.tipoTeste) {
                            tratamento.tipoTesteDescricao = (tratamento.tipoTeste.toLowerCase().indexOf('tratamento') !== -1) ? 'Tratamento' :
                                ((tratamento.tipoTeste.toLowerCase().indexOf('testemunha') !== -1) ? 'Testemunha' : tratamento.tipoTeste);
                        }
                        if ((typeof tratamento.materialGenetico === 'undefined') || !tratamento.materialGenetico) {
                            tratamento.materialGenetico = new MaterialGenetico_1.MaterialGenetico();
                        }
                        if (ctrl.testeGeneticoSelecionado && ctrl.testeGeneticoSelecionado.genero) {
                            tratamento.materialGenetico.genero = ctrl.testeGeneticoSelecionado.genero;
                        }
                        if (ctrl.testeGeneticoSelecionado && ctrl.testeGeneticoSelecionado.especie) {
                            tratamento.materialGenetico.especie = ctrl.testeGeneticoSelecionado.especie;
                        }
                        if (ctrl.testeGeneticoSelecionado && ctrl.testeGeneticoSelecionado.procedencia) {
                            tratamento.materialGenetico.procedencia = ctrl.testeGeneticoSelecionado.procedencia;
                        }
                    });
                }
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    cellClass: 'text-center',
                    cellTemplate: `
                        <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                            <button type="button" class="btn btn-icon bx bx-dots-vertical-rounded" uib-dropdown-toggle></button>
                            <ul class="dropdown-menu dropdown-menu-left" uib-dropdown-menu role="menu">
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalTratamentoTesteGenetico({ id: row.entity.id || row.entity.uuid, visualizar: true })' class="text-secondary w-100"><i class="bx bx-show mr-1 ml-1"></i>Visualizar</a></li>
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalTratamentoTesteGenetico({ id: row.entity.id || row.entity.uuid, editar: true })' class="text-secondary w-100"><i class="bx bxs-edit-alt mr-1 ml-1"></i>Editar</a></li>
                                <li><hr></li>
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalTratamentoTesteGenetico({ id: row.entity.id || row.entity.uuid, excluir: true })' class="text-secondary w-100"><i class="bx bxs-trash-alt mr-1 ml-1"></i>Excluir</a></li>
                            </ul>
                        </div> `
                });
                auxColunas.push({
                    name: 'materialGenetico.descricao',
                    displayName: 'Material Genético',
                    width: 180
                });
                auxColunas.push({
                    name: 'tipoTesteDescricao',
                    displayName: 'Tratamento/Testemunha',
                    width: 180
                });
                auxColunas.push({
                    name: 'materialGenetico.genero.descricao',
                    displayName: 'Gênero',
                    width: 120
                });
                auxColunas.push({
                    name: 'materialGenetico.especie.descricao',
                    displayName: 'Espécie',
                    width: 120
                });
                auxColunas.push({
                    name: 'materialGenetico.fonte.descricao',
                    displayName: 'Fonte',
                    width: 120
                });
                auxColunas.push({
                    name: 'testeGenetico.procedencia',
                    displayName: 'Procedência',
                    width: 120
                });
                auxColunas.push({
                    name: 'testeGenetico.reproducao.descricao',
                    displayName: 'Reprodução',
                    width: 100
                });
                auxColunas.push({
                    name: 'materialGenetico.mae.descricao',
                    displayName: 'Progenitor Feminino',
                    width: 100
                });
                auxColunas.push({
                    name: 'materialGenetico.pai.descricao',
                    displayName: 'Progenitor Masculino',
                    width: 100
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                const gridOption = {
                    data: ctrl.listaTratamentosTesteGenetico,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
                return gridOption;
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
            }
            return null;
        });
    }
    static dadosListaMateriaisGeneticosTratamentosTesteGenetico(ctrl, seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const funcaoOrigemCarregamento = 'dadosListaMateriaisGeneticosTratamentosTesteGenetico';
            try {
                if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(seletorCarregamento ? seletorCarregamento : '.card', funcaoOrigemCarregamento);
                let listaMateriaisGeneticos = [];
                if ((typeof ctrl.materiaisGeneticos !== 'undefined') && ctrl.materiaisGeneticos && (ctrl.materiaisGeneticos.length > 0)) {
                    listaMateriaisGeneticos = [...ctrl.materiaisGeneticos];
                }
                else {
                    listaMateriaisGeneticos = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MATERIAL_GENETICO)
                        .filtro({ permiteDadosOffline: true })
                        .listar();
                    ctrl.materiaisGeneticos = listaMateriaisGeneticos;
                }
                if ((typeof listaMateriaisGeneticos !== 'undefined') && listaMateriaisGeneticos) {
                    angular.forEach(listaMateriaisGeneticos, (materialGenetico) => {
                        materialGenetico.selecionado = false;
                        materialGenetico.pomares = '';
                    });
                    let listaTratamentosTesteGenetico = [];
                    if ((typeof ctrl.listaTratamentosTesteGenetico !== 'undefined') && ctrl.listaTratamentosTesteGenetico && (ctrl.listaTratamentosTesteGenetico.length > 0)) {
                        listaTratamentosTesteGenetico = [...ctrl.listaTratamentosTesteGenetico];
                    }
                    else {
                        listaTratamentosTesteGenetico = yield ctrl.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.TRATAMENTO_TESTE_GENETICO)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TRATAMENTO_TESTE_GENETICO)
                            .filtro({
                            listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                            testeGenetico: ctrl.testeGeneticoSelecionado.id
                        })
                            .listar();
                        ctrl.listaTratamentosTesteGenetico = listaTratamentosTesteGenetico;
                    }
                    if ((typeof listaTratamentosTesteGenetico !== 'undefined') && listaTratamentosTesteGenetico) {
                        angular.forEach(listaTratamentosTesteGenetico, (tratamento) => {
                            if ((typeof tratamento.materialGenetico !== 'undefined') && tratamento.materialGenetico &&
                                (typeof tratamento.materialGenetico.id !== 'undefined') && tratamento.materialGenetico.id) {
                                let materialGenetico = listaMateriaisGeneticos.find(x => x.id && (x.id === tratamento.materialGenetico.id));
                                if ((typeof materialGenetico !== 'undefined') && materialGenetico) {
                                    materialGenetico.selecionado = true;
                                    if ((typeof tratamento.tipoTeste !== 'undefined') && tratamento.tipoTeste) {
                                        if (tratamento.tipoTeste.toLowerCase().indexOf('tratamento') !== -1) {
                                            materialGenetico.tipoTeste = TipoTesteTratamentoTestemunha_1.TipoTesteTratamentoTestemunha.TRATAMENTO().id;
                                        }
                                        else if (tratamento.tipoTeste.toLowerCase().indexOf('testemunha') !== -1) {
                                            materialGenetico.tipoTeste = TipoTesteTratamentoTestemunha_1.TipoTesteTratamentoTestemunha.TESTEMUNHA().id;
                                        }
                                    }
                                }
                            }
                        });
                    }
                    let listaPomares = [];
                    if ((typeof ctrl.pomares !== 'undefined') && ctrl.pomares && (ctrl.pomares.length > 0)) {
                        listaPomares = [...ctrl.pomares];
                    }
                    else {
                        listaPomares = yield ctrl.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.POMAR)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POMAR)
                            .filtro({})
                            .listar();
                    }
                    if ((typeof listaPomares !== 'undefined') && listaPomares) {
                        angular.forEach(listaPomares, (pomar) => {
                            pomar.descricaoDetalhada = ((typeof pomar.descricao !== 'undefined') && pomar.descricao ? pomar.descricao : '');
                            if ((typeof pomar.data !== 'undefined') && pomar.data) {
                                pomar.data = (typeof pomar.data === 'string') ? new Date(pomar.data) : pomar.data;
                                if (pomar.data) {
                                    let dataHoraAtual = new Date();
                                    let idadeMs = dataHoraAtual.getTime() - pomar.data.getTime();
                                    let idadeData = new Date(idadeMs);
                                    pomar.idade = Math.abs(idadeData.getUTCFullYear() - 1970);
                                    pomar.ano = pomar.data.getFullYear();
                                    pomar.descricaoDetalhada += ' - ' + pomar.data.getFullYear();
                                }
                            }
                            if ((typeof pomar.paisDescricao === 'undefined') && !pomar.paisDescricao) {
                                if ((typeof ctrl.paises !== 'undefined') && ctrl.paises && (ctrl.paises.length > 0)) {
                                    let paisPadrao = null;
                                    const paisPadraoReferencia = TextoUtil_1.TextoUtil.converteTextoParaReferencia('Brasil');
                                    angular.forEach(ctrl.paises, (pais) => {
                                        if (!paisPadrao && (typeof pais.descricao !== 'undefined') && pais.descricao && (pais.descricao.length > 0)) {
                                            if (TextoUtil_1.TextoUtil.converteTextoParaReferencia(pais.descricao) === paisPadraoReferencia) {
                                                paisPadrao = pais;
                                            }
                                        }
                                    });
                                    if (paisPadrao) {
                                        pomar.pais = paisPadrao;
                                        pomar.paisId = paisPadrao.id;
                                        pomar.paisCodigo = paisPadrao.codigo;
                                        pomar.paisDescricao = paisPadrao.descricao;
                                    }
                                }
                                else {
                                    let paisPadrao = {
                                        id: Pais_1.Pais.BRASIL,
                                        uuid: Pais_1.Pais.BRASIL,
                                        status: true,
                                        criacao: new Date(),
                                        codigo: 1,
                                        descricao: 'Brasil'
                                    };
                                    pomar.pais = paisPadrao;
                                    pomar.paisId = paisPadrao.id;
                                    pomar.paisCodigo = paisPadrao.codigo;
                                    pomar.paisDescricao = paisPadrao.descricao;
                                }
                            }
                            if ((typeof pomar.instituicao === 'undefined') || !pomar.instituicao || (pomar.instituicao.length === 0)) {
                                pomar.instituicao = 'WestRock';
                            }
                            if ((typeof pomar.nomeCientifico === 'undefined') || !pomar.nomeCientifico || (pomar.nomeCientifico.length === 0)) {
                                pomar.nomeCientifico = pomar.descricao;
                            }
                            if ((typeof pomar.distanciaSede === 'undefined') || !pomar.distanciaSede) {
                                pomar.distanciaSede = (pomar.descricaoDetalhada.toLowerCase().indexOf('sede') !== -1) ? '1 km' : '35 km';
                            }
                            pomar.local = ((typeof pomar.municipio !== 'undefined') && pomar.municipio ? pomar.municipio : '') + ' - ' +
                                ((typeof pomar.provinciaUfSigla !== 'undefined') && pomar.provinciaUfSigla ? pomar.provinciaUfSigla : '');
                            if ((typeof pomar.perfilClimaticoAnual !== 'undefined') && pomar.perfilClimaticoAnual) {
                                if ((typeof pomar.perfilClimaticoAnual.atualizacao === 'undefined') || !pomar.perfilClimaticoAnual.atualizacao) {
                                    pomar.perfilClimaticoAnual.atualizacao = new Date();
                                }
                                else if (typeof pomar.perfilClimaticoAnual.atualizacao === 'string') {
                                    pomar.perfilClimaticoAnual.atualizacao = new Date(pomar.perfilClimaticoAnual.atualizacao);
                                }
                            }
                            else {
                                pomar.perfilClimaticoAnual = new PerfilClimaticoPomar_1.PerfilClimaticoPomar();
                                pomar.perfilClimaticoAnual.atualizacao = new Date();
                            }
                            if ((typeof pomar.producaoPortaEnxertosPlantio !== 'undefined') && pomar.producaoPortaEnxertosPlantio) {
                                pomar.producaoPortaEnxertosPlantio = (typeof pomar.producaoPortaEnxertosPlantio === 'string') ? new Date(pomar.producaoPortaEnxertosPlantio) : pomar.producaoPortaEnxertosPlantio;
                            }
                            if ((typeof pomar.implantacaoPomarEnxertia !== 'undefined') && pomar.implantacaoPomarEnxertia) {
                                pomar.implantacaoPomarEnxertia = (typeof pomar.implantacaoPomarEnxertia === 'string') ? new Date(pomar.implantacaoPomarEnxertia) : pomar.implantacaoPomarEnxertia;
                            }
                        });
                    }
                    let listaTratamentosPomares = [];
                    if ((typeof ctrl.tratamentosPomares !== 'undefined') && ctrl.tratamentosPomares && (ctrl.tratamentosPomares.length > 0)) {
                        listaTratamentosPomares = [...ctrl.tratamentosPomares];
                    }
                    else {
                        listaTratamentosPomares = yield ctrl.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.TRATAMENTO_POMAR)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TRATAMENTO_POMAR)
                            .filtro({})
                            .listar();
                    }
                    if ((typeof listaTratamentosPomares !== 'undefined') && listaTratamentosPomares) {
                        angular.forEach(listaTratamentosPomares, (tratamentoPomar) => {
                            if ((typeof tratamentoPomar.materialGenetico !== 'undefined') && tratamentoPomar.materialGenetico &&
                                (typeof tratamentoPomar.materialGenetico.id !== 'undefined') && tratamentoPomar.materialGenetico.id &&
                                (typeof tratamentoPomar.pomar !== 'undefined') && tratamentoPomar.pomar &&
                                (typeof tratamentoPomar.pomar.id !== 'undefined') && tratamentoPomar.pomar.id) {
                                let materialGenetico = listaMateriaisGeneticos.find(x => x.id && (x.id === tratamentoPomar.materialGenetico.id));
                                let pomar = listaPomares.find(x => x.id && (x.id === tratamentoPomar.pomar.id));
                                if ((typeof materialGenetico !== 'undefined') && materialGenetico) {
                                    if ((typeof materialGenetico.pomares === 'undefined') || (materialGenetico.pomares === null)) {
                                        materialGenetico.pomares = '';
                                    }
                                    if ((typeof pomar !== 'undefined') && pomar && (typeof pomar.descricaoDetalhada !== 'undefined') && pomar.descricaoDetalhada) {
                                        materialGenetico.pomares += ((materialGenetico.pomares.length > 0) ? ' | ' : '') +
                                            pomar.descricaoDetalhada;
                                    }
                                    else if ((typeof tratamentoPomar.pomar.descricaoDetalhada !== 'undefined') && tratamentoPomar.pomar.descricaoDetalhada) {
                                        materialGenetico.pomares += ((materialGenetico.pomares.length > 0) ? ' | ' : '') +
                                            tratamentoPomar.pomar.descricaoDetalhada;
                                    }
                                    else if ((typeof tratamentoPomar.pomar.descricao !== 'undefined') && tratamentoPomar.pomar.descricao) {
                                        materialGenetico.pomares += ((materialGenetico.pomares.length > 0) ? ' | ' : '') +
                                            tratamentoPomar.pomar.descricao;
                                    }
                                }
                            }
                        });
                    }
                    listaMateriaisGeneticos.sort((a, b) => (a.descricao > b.descricao) ? 1 : ((b.descricao > a.descricao) ? -1 : 0));
                }
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'selecionado',
                    displayName: 'Selecionado',
                    width: 108,
                    cellClass: 'text-center',
                    filter: {
                        type: ctrl.uiGridConstantes.filter.SELECT,
                        selectOptions: [{ label: 'Sim', value: 'true' }, { label: 'Não', value: 'false' }]
                    },
                    cellFilter: 'boolean',
                    cellTemplate: `
                        <div class="btn-group btn-ui-grid"
                            style="display: inline-block !important; position: relative !important; width: 100% !important; height: 100% !important; text-align: center !important; vertical-align: middle !important;">
                            <input ng-model="row.entity.selecionado" class="checkbox__input" type="checkbox"
                                ng-change="row.entity.tipoTeste = grid.appScope.getParentController().tratamentoTesteGeneticoSelecionado.tipoTeste;"
                                ng-attr-id="checkboxMaterialGenetico_{{row.entity.id}}">
                            <label ng-attr-for="checkboxMaterialGenetico_{{row.entity.id}}"
                                style="display: inline-block !important; position: absolute !important; left: 2px !important; top: 1px !important; right: 2px !important; bottom: 1px !important;">
                            </label>
                        </div>`
                });
                auxColunas.push({
                    name: 'descricao',
                    displayName: 'Código Material',
                    width: 130
                });
                auxColunas.push({
                    name: 'tipoTeste',
                    displayName: 'Tipo de Teste',
                    width: 130,
                    cellClass: 'text-center',
                    cellTemplate: `
                        <div class="btn-group btn-ui-grid" ng-show="row.entity.selecionado"
                            style="width: 100% !important; padding: 2px !important;">
                            <div class="form-control ui-select-container selectize-control single"
                                style="width: 100% !important;">
                                <select ng-model="row.entity.tipoTeste" theme="selectize" class="selectize-input"
                                    style="width: 100% !important;" title="Escolha uma opção">
                                    <option value="TRATAMENTO">Tratamento</option>
                                    <option value="TESTEMUNHA">Testemunha</option>
                                </select>
                            </div>
                        </div>`
                });
                auxColunas.push({
                    name: 'genero.descricao',
                    displayName: 'Gênero',
                    width: 110
                });
                auxColunas.push({
                    name: 'especie.descricao',
                    displayName: 'Espécie',
                    width: 120
                });
                auxColunas.push({
                    name: 'geracao.descricao',
                    displayName: 'Geração',
                    width: 120
                });
                auxColunas.push({
                    name: 'fonte.descricao',
                    displayName: 'Fonte',
                    width: 150
                });
                auxColunas.push({
                    name: 'localidade',
                    displayName: 'Localidade',
                    width: 160
                });
                auxColunas.push({
                    name: 'pomares',
                    displayName: 'Pomares',
                    width: 500
                });
                auxColunas.push({
                    name: 'criacao',
                    displayName: 'Data/Hora Cadastro',
                    cellFilter: 'date:"dd/MM/yyyy HH:mm:ss"',
                    width: 190
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                const gridOption = {
                    data: listaMateriaisGeneticos,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
                return gridOption;
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
            }
            return null;
        });
    }
    static dadosListaClimaAnualTesteGenetico(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            const funcaoOrigemCarregamento = 'dadosListaClimaAnualTesteGenetico';
            try {
                if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card', funcaoOrigemCarregamento);
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'mes',
                    displayName: 'Mês',
                    width: 90
                });
                auxColunas.push({
                    name: 'temperaturaMaxima',
                    displayName: 'Temp. Máx.',
                    width: 110,
                    enableCellEdit: true
                });
                auxColunas.push({
                    name: 'temperaturaMedia',
                    displayName: 'Temp. Média',
                    width: 120,
                    enableCellEdit: true
                });
                auxColunas.push({
                    name: 'temperaturaMinima',
                    displayName: 'Temp. Mín.',
                    width: 110,
                    enableCellEdit: true
                });
                auxColunas.push({
                    name: 'precipitacaoMaxima',
                    displayName: 'Precip. Máx.',
                    width: 120,
                    enableCellEdit: true
                });
                auxColunas.push({
                    name: 'precipitacaoMedia',
                    displayName: 'Precip. Média',
                    width: 130,
                    enableCellEdit: true
                });
                auxColunas.push({
                    name: 'precipitacaoMinima',
                    displayName: 'Precip. Mín.',
                    width: 120,
                    enableCellEdit: true
                });
                auxColunas.push({
                    name: 'temperaturaMinimaAbsoluta',
                    displayName: 'Temp. Mín. Absoluta',
                    width: 160,
                    enableCellEdit: true
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                let lista = [
                    {
                        mes: 'Média Anual',
                        temperaturaMaxima: null,
                        temperaturaMedia: null,
                        temperaturaMinima: null,
                        precipitacaoMaxima: null,
                        precipitacaoMedia: null,
                        precipitacaoMinima: null,
                        temperaturaMinimaAbsoluta: null
                    }, {
                        mes: 'Janeiro',
                        temperaturaMaxima: 36,
                        temperaturaMedia: 24,
                        temperaturaMinima: 12,
                        precipitacaoMaxima: 175.514,
                        precipitacaoMedia: 166.738,
                        precipitacaoMinima: 157.963,
                        temperaturaMinimaAbsoluta: 12
                    }, {
                        mes: 'Fevereiro',
                        temperaturaMaxima: 33,
                        temperaturaMedia: 22,
                        temperaturaMinima: 11,
                        precipitacaoMaxima: 154.94,
                        precipitacaoMedia: 147.193,
                        precipitacaoMinima: 139.446,
                        temperaturaMinimaAbsoluta: 11
                    }, {
                        mes: 'Março',
                        temperaturaMaxima: 34,
                        temperaturaMedia: 23.5,
                        temperaturaMinima: 13,
                        precipitacaoMaxima: 120.904,
                        precipitacaoMedia: 114.859,
                        precipitacaoMinima: 108.814,
                        temperaturaMinimaAbsoluta: 13
                    }, {
                        mes: 'Abril',
                        temperaturaMaxima: 31,
                        temperaturaMedia: 18.5,
                        temperaturaMinima: 6,
                        precipitacaoMaxima: 114.554,
                        precipitacaoMedia: 108.826,
                        precipitacaoMinima: 103.099,
                        temperaturaMinimaAbsoluta: 6
                    }, {
                        mes: 'Maio',
                        temperaturaMaxima: 30,
                        temperaturaMedia: 16.5,
                        temperaturaMinima: 3,
                        precipitacaoMaxima: 235.458,
                        precipitacaoMedia: 223.685,
                        precipitacaoMinima: 211.912,
                        temperaturaMinimaAbsoluta: 3
                    }, {
                        mes: 'Junho',
                        temperaturaMaxima: 27,
                        temperaturaMedia: 16,
                        temperaturaMinima: 5,
                        precipitacaoMaxima: 53.848,
                        precipitacaoMedia: 51.156,
                        precipitacaoMinima: 48.463,
                        temperaturaMinimaAbsoluta: 5
                    }, {
                        mes: 'Julho',
                        temperaturaMaxima: 28,
                        temperaturaMedia: 13,
                        temperaturaMinima: -2,
                        precipitacaoMaxima: 205.74,
                        precipitacaoMedia: 195.453,
                        precipitacaoMinima: 185.166,
                        temperaturaMinimaAbsoluta: -2
                    }, {
                        mes: 'Agosto',
                        temperaturaMaxima: 32,
                        temperaturaMedia: 15,
                        temperaturaMinima: -2,
                        precipitacaoMaxima: 25.146,
                        precipitacaoMedia: 23.889,
                        precipitacaoMinima: 22.631,
                        temperaturaMinimaAbsoluta: -2
                    }, {
                        mes: 'Setembro',
                        temperaturaMaxima: 34,
                        temperaturaMedia: 19.5,
                        temperaturaMinima: 5,
                        precipitacaoMaxima: 120.142,
                        precipitacaoMedia: 114.135,
                        precipitacaoMinima: 108.128,
                        temperaturaMinimaAbsoluta: 5
                    }, {
                        mes: 'Outubro',
                        temperaturaMaxima: 35,
                        temperaturaMedia: 22.5,
                        temperaturaMinima: 10,
                        precipitacaoMaxima: 126.746,
                        precipitacaoMedia: 120.409,
                        precipitacaoMinima: 114.071,
                        temperaturaMinimaAbsoluta: 10
                    }, {
                        mes: 'Novembro',
                        temperaturaMaxima: 31,
                        temperaturaMedia: 18.5,
                        temperaturaMinima: 6,
                        precipitacaoMaxima: 173.736,
                        precipitacaoMedia: 165.049,
                        precipitacaoMinima: 156.362,
                        temperaturaMinimaAbsoluta: 6
                    }, {
                        mes: 'Dezembro',
                        temperaturaMaxima: 28,
                        temperaturaMedia: 23,
                        temperaturaMinima: 18,
                        precipitacaoMaxima: 1.524,
                        precipitacaoMedia: 1.448,
                        precipitacaoMinima: 1.372,
                        temperaturaMinimaAbsoluta: 18
                    }
                ];
                if ((typeof globalThis.timeoutCalculaClimaAnualTesteGenetico !== 'undefined') && globalThis.timeoutCalculaClimaAnualTesteGenetico) {
                    clearInterval(globalThis.timeoutCalculaClimaAnualTesteGenetico);
                    globalThis.timeoutCalculaClimaAnualTesteGenetico = null;
                }
                globalThis.timeoutCalculaClimaAnualTesteGenetico = setInterval((ctrl, lista) => {
                    if ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                        (typeof ctrl.testeGeneticoSelecionado.id !== 'undefined') && ctrl.testeGeneticoSelecionado.id &&
                        (typeof lista !== 'undefined') && lista && (lista.length > 0)) {
                        const abaDadosGeograficosEl = angular.element('#teste_campo_dados_geograficos');
                        if ((typeof abaDadosGeograficosEl !== 'undefined') && abaDadosGeograficosEl && (abaDadosGeograficosEl.length > 0) &&
                            (abaDadosGeograficosEl.hasClass('show') || abaDadosGeograficosEl.hasClass('active'))) {
                            let listaTemp = new Array();
                            angular.forEach(lista, (item) => {
                                listaTemp.push(Object.assign({}, item));
                            });
                            let somaTemperaturaAnual = 0;
                            let contadorTemperaturaAnual = 0;
                            let somaPrecipitacaoAnual = 0;
                            let contadorPrecipitacaoAnual = 0;
                            let temperaturaMinima = 0;
                            let itemMediaAnual = null;
                            angular.forEach(listaTemp, (item) => {
                                if (item.mes && (item.mes.indexOf('Anual') !== -1)) {
                                    item.temperaturaMaxima = null;
                                    item.temperaturaMedia = null;
                                    item.temperaturaMinima = null;
                                    item.precipitacaoMaxima = null;
                                    item.precipitacaoMedia = null;
                                    item.precipitacaoMinima = null;
                                    item.temperaturaMinimaAbsoluta = null;
                                    itemMediaAnual = item;
                                }
                                else {
                                    if ((typeof item.temperaturaMaxima !== 'undefined') && item.temperaturaMaxima && (parseFloat(item.temperaturaMaxima) !== 0) &&
                                        (typeof item.temperaturaMinima !== 'undefined') && item.temperaturaMinima && (parseFloat(item.temperaturaMinima) !== 0)) {
                                        item.temperaturaMedia = Math.round(((parseFloat(item.temperaturaMinima) + parseFloat(item.temperaturaMaxima)) / 2) * 100) / 100;
                                    }
                                    if ((typeof item.precipitacaoMaxima !== 'undefined') && item.precipitacaoMaxima && (parseFloat(item.precipitacaoMaxima) !== 0) &&
                                        (typeof item.precipitacaoMinima !== 'undefined') && item.precipitacaoMinima && (parseFloat(item.precipitacaoMinima) !== 0)) {
                                        item.precipitacaoMedia = Math.round(((parseFloat(item.precipitacaoMinima) + parseFloat(item.precipitacaoMaxima)) / 2) * 100) / 100;
                                    }
                                    if ((typeof item.temperaturaMaxima !== 'undefined') && item.temperaturaMaxima && (parseFloat(item.temperaturaMaxima) !== 0)) {
                                        if ((temperaturaMinima === 0) || (parseFloat(item.temperaturaMaxima) < temperaturaMinima)) {
                                            temperaturaMinima = parseFloat(item.temperaturaMaxima);
                                        }
                                    }
                                    if ((typeof item.temperaturaMinima !== 'undefined') && item.temperaturaMinima && (parseFloat(item.temperaturaMinima) !== 0)) {
                                        if ((temperaturaMinima === 0) || (parseFloat(item.temperaturaMinima) < temperaturaMinima)) {
                                            temperaturaMinima = parseFloat(item.temperaturaMinima);
                                        }
                                    }
                                    if ((typeof item.temperaturaMedia !== 'undefined') && item.temperaturaMedia && (parseFloat(item.temperaturaMedia) !== 0)) {
                                        if ((temperaturaMinima === 0) || (parseFloat(item.temperaturaMedia) < temperaturaMinima)) {
                                            temperaturaMinima = parseFloat(item.temperaturaMedia);
                                        }
                                    }
                                    if ((typeof item.temperaturaMedia !== 'undefined') && parseFloat(item.temperaturaMedia)) {
                                        somaTemperaturaAnual += parseFloat(item.temperaturaMedia);
                                        contadorTemperaturaAnual++;
                                    }
                                    if ((typeof item.precipitacaoMedia !== 'undefined') && parseFloat(item.precipitacaoMedia)) {
                                        somaPrecipitacaoAnual += parseFloat(item.precipitacaoMedia);
                                        contadorPrecipitacaoAnual++;
                                    }
                                    if (itemMediaAnual && (typeof item.temperaturaMaxima !== 'undefined') && item.temperaturaMaxima && (parseFloat(item.temperaturaMaxima) !== 0)) {
                                        if (!itemMediaAnual.temperaturaMaxima || (parseFloat(item.temperaturaMaxima) && (parseFloat(item.temperaturaMaxima) > itemMediaAnual.temperaturaMaxima))) {
                                            itemMediaAnual.temperaturaMaxima = parseFloat(item.temperaturaMaxima);
                                        }
                                    }
                                    if (itemMediaAnual && (typeof item.temperaturaMedia !== 'undefined') && item.temperaturaMedia && (parseFloat(item.temperaturaMedia) !== 0)) {
                                        if (!itemMediaAnual.temperaturaMedia || (item.temperaturaMedia && (parseFloat(item.temperaturaMedia) > itemMediaAnual.temperaturaMedia))) {
                                            itemMediaAnual.temperaturaMedia = parseFloat(item.temperaturaMedia);
                                        }
                                    }
                                    if (itemMediaAnual && (typeof item.temperaturaMinima !== 'undefined') && item.temperaturaMinima && (parseFloat(item.temperaturaMinima) !== 0)) {
                                        if (!itemMediaAnual.temperaturaMinima || (item.temperaturaMinima && (parseFloat(item.temperaturaMinima) < itemMediaAnual.temperaturaMinima))) {
                                            itemMediaAnual.temperaturaMinima = parseFloat(item.temperaturaMinima);
                                        }
                                    }
                                    if (itemMediaAnual && (typeof item.precipitacaoMaxima !== 'undefined') && item.precipitacaoMaxima && (parseFloat(item.precipitacaoMaxima) !== 0)) {
                                        if (!itemMediaAnual.precipitacaoMaxima || (item.precipitacaoMaxima && (parseFloat(item.precipitacaoMaxima) > itemMediaAnual.precipitacaoMaxima))) {
                                            itemMediaAnual.precipitacaoMaxima = parseFloat(item.precipitacaoMaxima);
                                        }
                                    }
                                    if (itemMediaAnual && (typeof item.precipitacaoMedia !== 'undefined') && item.precipitacaoMedia && (parseFloat(item.precipitacaoMedia) !== 0)) {
                                        if (!itemMediaAnual.precipitacaoMedia || (item.precipitacaoMedia && (parseFloat(item.precipitacaoMedia) > itemMediaAnual.precipitacaoMedia))) {
                                            itemMediaAnual.precipitacaoMedia = parseFloat(item.precipitacaoMedia);
                                        }
                                    }
                                    if (itemMediaAnual && (typeof item.temperaturaMinimaAbsoluta !== 'undefined') && item.temperaturaMinimaAbsoluta && (parseFloat(item.temperaturaMinimaAbsoluta) !== 0)) {
                                        if (!itemMediaAnual.temperaturaMinimaAbsoluta || (item.temperaturaMinimaAbsoluta && (parseFloat(item.temperaturaMinimaAbsoluta) < itemMediaAnual.temperaturaMinimaAbsoluta))) {
                                            itemMediaAnual.temperaturaMinimaAbsoluta = parseFloat(item.temperaturaMinimaAbsoluta);
                                        }
                                    }
                                    if (itemMediaAnual && (typeof item.precipitacaoMinima !== 'undefined') && item.precipitacaoMinima && (parseFloat(item.precipitacaoMinima) !== 0)) {
                                        if (!itemMediaAnual.precipitacaoMinima || (item.precipitacaoMinima && (parseFloat(item.precipitacaoMinima) < itemMediaAnual.precipitacaoMinima))) {
                                            itemMediaAnual.precipitacaoMinima = parseFloat(item.precipitacaoMinima);
                                        }
                                    }
                                }
                            });
                            let dadosAtualizados = false;
                            if (JSON.stringify(lista) !== JSON.stringify(listaTemp)) {
                                lista.splice(0, lista.length);
                                lista.push(...listaTemp);
                                dadosAtualizados = true;
                                console.log('Interval: CalculaClimaAnualTesteGenetico > Lista da tabela modificada');
                            }
                            if ((somaTemperaturaAnual !== 0) && (contadorTemperaturaAnual !== 0)) {
                                const temperaturaTemp = Number(Math.round((somaTemperaturaAnual / contadorTemperaturaAnual) * 100) / 100).toString();
                                if (ctrl.testeGeneticoSelecionado.perfilClimaticoAnual.temperatura !== temperaturaTemp) {
                                    ctrl.testeGeneticoSelecionado.perfilClimaticoAnual.temperatura = temperaturaTemp;
                                    dadosAtualizados = true;
                                    console.log('Interval: CalculaClimaAnualTesteGenetico > Temperatura anual modificada');
                                }
                            }
                            else {
                                if (ctrl.testeGeneticoSelecionado.perfilClimaticoAnual.temperatura !== '0') {
                                    ctrl.testeGeneticoSelecionado.perfilClimaticoAnual.temperatura = '0';
                                }
                            }
                            if ((somaPrecipitacaoAnual !== 0) && (contadorPrecipitacaoAnual !== 0)) {
                                const precipitacaoTemp = Number(Math.round((somaPrecipitacaoAnual / contadorPrecipitacaoAnual) * 100) / 100).toString();
                                if (ctrl.testeGeneticoSelecionado.perfilClimaticoAnual.precipitacao !== precipitacaoTemp) {
                                    ctrl.testeGeneticoSelecionado.perfilClimaticoAnual.precipitacao = precipitacaoTemp;
                                    dadosAtualizados = true;
                                    console.log('Interval: CalculaClimaAnualTesteGenetico > Precipitação modificada');
                                }
                            }
                            else {
                                if (ctrl.testeGeneticoSelecionado.perfilClimaticoAnual.precipitacao !== '0') {
                                    ctrl.testeGeneticoSelecionado.perfilClimaticoAnual.precipitacao = '0';
                                }
                            }
                            if (temperaturaMinima !== 0) {
                                const temperaturaMinimaTemp = Number(Math.round(temperaturaMinima * 100) / 100).toString();
                                if (ctrl.testeGeneticoSelecionado.perfilClimaticoAnual.temperaturaMinima !== temperaturaMinimaTemp) {
                                    ctrl.testeGeneticoSelecionado.perfilClimaticoAnual.temperaturaMinima = temperaturaMinimaTemp;
                                    dadosAtualizados = true;
                                    console.log('Interval: CalculaClimaAnualTesteGenetico > Temperatura mínima modificada');
                                }
                            }
                            else {
                                if (ctrl.testeGeneticoSelecionado.perfilClimaticoAnual.temperaturaMinima !== '0') {
                                    ctrl.testeGeneticoSelecionado.perfilClimaticoAnual.temperaturaMinima = '0';
                                }
                            }
                            if (dadosAtualizados) {
                                ctrl.atualizarGraficosClimaAnual();
                                ctrl.scopeApply();
                            }
                        }
                    }
                }, 2500, ctrl, lista);
                setTimeout((ctrl) => {
                    try {
                        ctrl.atualizarGraficosClimaAnual();
                    }
                    catch (ex) {
                        console.log(ex);
                    }
                    setTimeout((ctrl) => {
                        try {
                            ctrl.atualizarGraficosClimaAnual();
                        }
                        catch (ex) {
                            console.log(ex);
                        }
                    }, 1500, ctrl);
                }, 1500, ctrl);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    controller: ctrl
                };
                return gridOption;
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
            }
            return null;
        });
    }
    static dadosListaMedicaoDendrometricaTesteGenetico(ctrl, seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const funcaoOrigemCarregamento = 'dadosListaMedicaoDendrometricaTesteGenetico';
            try {
                if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                    return null;
                }
                ctrl.listaMedicaoDendrometrica = [];
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(seletorCarregamento ? seletorCarregamento : '.card', funcaoOrigemCarregamento);
                const numTotalBlocos = ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                    (typeof ctrl.testeGeneticoSelecionado.numBlocos !== 'undefined') && ctrl.testeGeneticoSelecionado.numBlocos
                    ? ctrl.testeGeneticoSelecionado.numBlocos : 1);
                const numTotalParcelas = ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                    (typeof ctrl.testeGeneticoSelecionado.numParcelas !== 'undefined') && ctrl.testeGeneticoSelecionado.numParcelas
                    ? ctrl.testeGeneticoSelecionado.numParcelas : 1);
                const numArvoresPorTratamento = ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                    (typeof ctrl.testeGeneticoSelecionado.delineamentoArvoresPorTratamento !== 'undefined') && ctrl.testeGeneticoSelecionado.delineamentoArvoresPorTratamento
                    ? ctrl.testeGeneticoSelecionado.delineamentoArvoresPorTratamento : 1);
                let lista = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                    .filtro({
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    testeGenetico: ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                        (typeof ctrl.testeGeneticoSelecionado.id !== 'undefined') && ctrl.testeGeneticoSelecionado.id
                        ? ctrl.testeGeneticoSelecionado.id : null),
                    anoAnalise: ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                        (typeof ctrl.testeGeneticoSelecionado.anoAnalise !== 'undefined') && ctrl.testeGeneticoSelecionado.anoAnalise
                        ? ctrl.testeGeneticoSelecionado.anoAnalise : null)
                })
                    .listar();
                if ((typeof lista === 'undefined') || !lista || (lista.length === 0)) {
                    if ((typeof ctrl.listaTratamentosTesteGenetico !== 'undefined') && ctrl.listaTratamentosTesteGenetico) {
                        angular.forEach(ctrl.listaTratamentosTesteGenetico, (tratamentoTesteGenetico) => {
                            if ((typeof tratamentoTesteGenetico.materialGenetico !== 'undefined') && tratamentoTesteGenetico.materialGenetico &&
                                (typeof tratamentoTesteGenetico.materialGenetico.descricao !== 'undefined') && tratamentoTesteGenetico.materialGenetico.descricao &&
                                (tratamentoTesteGenetico.materialGenetico.descricao.trim().length > 0)) {
                                for (let bloco = 1; bloco <= numTotalBlocos; bloco++) {
                                    for (let parcela = 1; parcela <= numTotalParcelas; parcela++) {
                                        for (let arvore = 1; arvore <= numArvoresPorTratamento; arvore++) {
                                            const materialGenetico = tratamentoTesteGenetico.materialGenetico.descricao.trim();
                                            let medicao = new MedicaoDendrometricaTesteGenetico_1.MedicaoDendrometricaTesteGenetico();
                                            medicao.testeGenetico = ctrl.testeGeneticoSelecionado;
                                            medicao.bloco = bloco;
                                            medicao.parc = parcela;
                                            medicao.trat = materialGenetico;
                                            medicao.arv = arvore;
                                            medicao.dap = null;
                                            medicao.alt = null;
                                            medicao.forma = null;
                                            medicao.anosMedicao = [];
                                            lista.push(medicao);
                                        }
                                    }
                                }
                            }
                        });
                    }
                }
                ctrl.listaMedicaoDendrometrica = lista;
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'bloco',
                    displayName: 'BLOCO',
                    width: 100
                });
                auxColunas.push({
                    name: 'parc',
                    displayName: 'PARC',
                    width: 100
                });
                auxColunas.push({
                    name: 'trat',
                    displayName: 'TRAT',
                    width: 100
                });
                auxColunas.push({
                    name: 'arv',
                    displayName: 'ARV',
                    width: 100
                });
                auxColunas.push({
                    name: 'dap',
                    displayName: 'DAP',
                    width: 100
                });
                auxColunas.push({
                    name: 'alt',
                    displayName: 'ALT',
                    width: 100
                });
                auxColunas.push({
                    name: 'forma',
                    displayName: 'FORMA',
                    width: 100
                });
                auxColunas.push({
                    name: 'for',
                    displayName: 'FOR',
                    width: 100
                });
                auxColunas.push({
                    name: 'cq',
                    displayName: 'CQ',
                    width: 100
                });
                auxColunas.push({
                    name: 'ft',
                    displayName: 'FT',
                    width: 100
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                return {
                    data: ctrl.listaMedicaoDendrometrica,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
            }
            return null;
        });
    }
    static dadosListaLancamentoMedicaoDendrometricaTesteGenetico(ctrl, seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const funcaoOrigemCarregamento = 'dadosListaLancamentoMedicaoDendrometricaTesteGenetico';
            try {
                if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(seletorCarregamento ? seletorCarregamento : '.card', funcaoOrigemCarregamento);
                let lista = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.LANCAMENTO_MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LANCAMENTO_MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                    .filtro({
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    testeGenetico: ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                        (typeof ctrl.testeGeneticoSelecionado.id !== 'undefined') && ctrl.testeGeneticoSelecionado.id
                        ? ctrl.testeGeneticoSelecionado.id : null),
                    anoAnalise: ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                        (typeof ctrl.testeGeneticoSelecionado.anoAnalise !== 'undefined') && ctrl.testeGeneticoSelecionado.anoAnalise
                        ? ctrl.testeGeneticoSelecionado.anoAnalise : null)
                })
                    .listar();
                if ((typeof lista === 'undefined') || !lista || (lista.length === 0)) {
                    const numBlocos = ((typeof ctrl.testeGeneticoSelecionado.numBlocos !== 'undefined') && (ctrl.testeGeneticoSelecionado.numBlocos > 0)) ? ctrl.testeGeneticoSelecionado.numBlocos : 10;
                    const numParcelas = ((typeof ctrl.testeGeneticoSelecionado.numParcelas !== 'undefined') && (ctrl.testeGeneticoSelecionado.numParcelas > 0)) ? ctrl.testeGeneticoSelecionado.numParcelas : 10;
                    const numArvores = ((typeof ctrl.testeGeneticoSelecionado.delineamentoTotalArvores !== 'undefined') && (ctrl.testeGeneticoSelecionado.delineamentoTotalArvores > 0)) ? ctrl.testeGeneticoSelecionado.delineamentoTotalArvores : 10;
                    if ((typeof ctrl.listaTratamentosTesteGenetico !== 'undefined') && ctrl.listaTratamentosTesteGenetico) {
                        angular.forEach(ctrl.listaTratamentosTesteGenetico, (tratamentoTesteGenetico) => {
                            if ((typeof tratamentoTesteGenetico.materialGenetico !== 'undefined') && tratamentoTesteGenetico.materialGenetico &&
                                (typeof tratamentoTesteGenetico.materialGenetico.descricao !== 'undefined') && tratamentoTesteGenetico.materialGenetico.descricao &&
                                (tratamentoTesteGenetico.materialGenetico.descricao.trim().length > 0)) {
                                for (let bloco = 1; bloco <= numBlocos; bloco++) {
                                    for (let parcela = 1; parcela <= numParcelas; parcela++) {
                                        for (let arvore = 1; arvore <= numArvores; arvore++) {
                                            const materialGenetico = tratamentoTesteGenetico.materialGenetico.descricao.trim();
                                            let lancamento = new LancamentoMedicaoDendrometricaTesteGenetico_1.LancamentoMedicaoDendrometricaTesteGenetico();
                                            lancamento.testeGenetico = ctrl.testeGeneticoSelecionado;
                                            lancamento.bloco = bloco;
                                            lancamento.parc = parcela;
                                            lancamento.trat = materialGenetico;
                                            lancamento.arv = arvore;
                                            lancamento.dap = null;
                                            lancamento.alt = null;
                                            lancamento.forma = null;
                                            lista.push(lancamento);
                                        }
                                    }
                                }
                            }
                        });
                    }
                }
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'bloco',
                    displayName: 'BLOCO',
                    width: 100
                });
                auxColunas.push({
                    name: 'parc',
                    displayName: 'PARC',
                    width: 100
                });
                auxColunas.push({
                    name: 'trat',
                    displayName: 'TRAT',
                    width: 100
                });
                auxColunas.push({
                    name: 'arv',
                    displayName: 'ARV',
                    width: 100
                });
                auxColunas.push({
                    name: 'dap',
                    displayName: 'DAP',
                    width: 100
                });
                auxColunas.push({
                    name: 'alt',
                    displayName: 'ALT',
                    width: 100
                });
                auxColunas.push({
                    name: 'forma',
                    displayName: 'FORMA',
                    width: 100
                });
                auxColunas.push({
                    name: 'for',
                    displayName: 'FOR',
                    width: 100
                });
                auxColunas.push({
                    name: 'cq',
                    displayName: 'CQ',
                    width: 100
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
            }
            return null;
        });
    }
    static dadosListaPlanejamentoMedicaoDendrometricaTesteGenetico(ctrl, seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const funcaoOrigemCarregamento = 'dadosListaPlanejamentoMedicaoDendrometricaTesteGenetico';
            try {
                if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(seletorCarregamento ? seletorCarregamento : '.card', funcaoOrigemCarregamento);
                let lista = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANEJAMENTO_MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANEJAMENTO_MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                    .filtro({
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    testeGenetico: ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                        (typeof ctrl.testeGeneticoSelecionado.id !== 'undefined') && ctrl.testeGeneticoSelecionado.id
                        ? ctrl.testeGeneticoSelecionado.id : null),
                    anoAnalise: ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                        (typeof ctrl.testeGeneticoSelecionado.anoAnalise !== 'undefined') && ctrl.testeGeneticoSelecionado.anoAnalise
                        ? ctrl.testeGeneticoSelecionado.anoAnalise : null)
                })
                    .listar();
                if ((typeof lista === 'undefined') || !lista) {
                    lista = [];
                }
                if ((typeof ctrl.listaTratamentosTesteGenetico !== 'undefined') && ctrl.listaTratamentosTesteGenetico) {
                    angular.forEach(ctrl.listaTratamentosTesteGenetico, (tratamentoTesteGenetico) => {
                        if ((typeof tratamentoTesteGenetico.materialGenetico !== 'undefined') && tratamentoTesteGenetico.materialGenetico &&
                            (typeof tratamentoTesteGenetico.materialGenetico.descricao !== 'undefined') && tratamentoTesteGenetico.materialGenetico.descricao &&
                            (tratamentoTesteGenetico.materialGenetico.descricao.trim().length > 0)) {
                            const materialGenetico = tratamentoTesteGenetico.materialGenetico.descricao.trim();
                            let planejamento = new PlanejamentoMedicaoDendrometricaTesteGenetico_1.PlanejamentoMedicaoDendrometricaTesteGenetico();
                            planejamento.testeGenetico = ctrl.testeGeneticoSelecionado;
                            planejamento.descricao = materialGenetico;
                            planejamento.identificacaoArea = 'Melhoramento';
                            planejamento.genero = (typeof tratamentoTesteGenetico.materialGenetico !== 'undefined') && tratamentoTesteGenetico.materialGenetico &&
                                (typeof tratamentoTesteGenetico.materialGenetico.genero !== 'undefined') && tratamentoTesteGenetico.materialGenetico.genero
                                ? ((typeof tratamentoTesteGenetico.materialGenetico.genero.descricao !== 'undefined') && tratamentoTesteGenetico.materialGenetico.genero.descricao
                                    ? tratamentoTesteGenetico.materialGenetico.genero.descricao
                                    : tratamentoTesteGenetico.materialGenetico.genero)
                                : null;
                            planejamento.especie = (typeof tratamentoTesteGenetico.materialGenetico !== 'undefined') && tratamentoTesteGenetico.materialGenetico &&
                                (typeof tratamentoTesteGenetico.materialGenetico.especie !== 'undefined') && tratamentoTesteGenetico.materialGenetico.especie
                                ? ((typeof tratamentoTesteGenetico.materialGenetico.especie.descricao !== 'undefined') && tratamentoTesteGenetico.materialGenetico.especie.descricao
                                    ? tratamentoTesteGenetico.materialGenetico.especie.descricao
                                    : tratamentoTesteGenetico.materialGenetico.especie)
                                : null;
                            planejamento.origem = (typeof tratamentoTesteGenetico.materialGenetico !== 'undefined') && tratamentoTesteGenetico.materialGenetico &&
                                (typeof tratamentoTesteGenetico.materialGenetico.fonte !== 'undefined') && tratamentoTesteGenetico.materialGenetico.fonte
                                ? ((typeof tratamentoTesteGenetico.materialGenetico.fonte.descricao !== 'undefined') && tratamentoTesteGenetico.materialGenetico.fonte.descricao
                                    ? tratamentoTesteGenetico.materialGenetico.fonte.descricao
                                    : tratamentoTesteGenetico.materialGenetico.fonte)
                                : null;
                            planejamento.populacao = (typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                                (typeof ctrl.testeGeneticoSelecionado.tipoTestePopulacao !== 'undefined') && ctrl.testeGeneticoSelecionado.tipoTestePopulacao &&
                                (typeof ctrl.testeGeneticoSelecionado.tipoTestePopulacao.descricao !== 'undefined') && ctrl.testeGeneticoSelecionado.tipoTestePopulacao.descricao
                                ? ctrl.testeGeneticoSelecionado.tipoTestePopulacao.descricao : null;
                            if ((typeof planejamento.populacao === 'undefined') || !planejamento.populacao || (planejamento.populacao === '')) {
                                planejamento.populacao = (typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                                    (typeof ctrl.testeGeneticoSelecionado.pomarOrigem !== 'undefined') && ctrl.testeGeneticoSelecionado.pomarOrigem &&
                                    (typeof ctrl.testeGeneticoSelecionado.pomarOrigem.tipoPomarPopulacao !== 'undefined') && ctrl.testeGeneticoSelecionado.pomarOrigem.tipoPomarPopulacao &&
                                    (typeof ctrl.testeGeneticoSelecionado.pomarOrigem.tipoPomarPopulacao.descricao !== 'undefined') && ctrl.testeGeneticoSelecionado.pomarOrigem.tipoPomarPopulacao.descricao
                                    ? ctrl.testeGeneticoSelecionado.pomarOrigem.tipoPomarPopulacao.descricao : null;
                            }
                            planejamento.pomar = (typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                                (typeof ctrl.testeGeneticoSelecionado.pomarOrigem !== 'undefined') && ctrl.testeGeneticoSelecionado.pomarOrigem
                                ? ((typeof ctrl.testeGeneticoSelecionado.pomarOrigem.descricao !== 'undefined') && ctrl.testeGeneticoSelecionado.pomarOrigem.descricao
                                    ? ctrl.testeGeneticoSelecionado.pomarOrigem.descricao
                                    : ctrl.testeGeneticoSelecionado.pomarOrigem)
                                : null;
                            if ((typeof planejamento.pomar === 'undefined') || !planejamento.pomar || (planejamento.pomar === '')) {
                                planejamento.pomar = (typeof tratamentoTesteGenetico.materialGenetico !== 'undefined') && tratamentoTesteGenetico.materialGenetico &&
                                    (typeof tratamentoTesteGenetico.materialGenetico.pomarOrigem !== 'undefined') && tratamentoTesteGenetico.materialGenetico.pomarOrigem
                                    ? ((typeof tratamentoTesteGenetico.materialGenetico.pomarOrigem.descricao !== 'undefined') && tratamentoTesteGenetico.materialGenetico.pomarOrigem.descricao
                                        ? tratamentoTesteGenetico.materialGenetico.pomarOrigem.descricao
                                        : tratamentoTesteGenetico.materialGenetico.pomarOrigem)
                                    : null;
                            }
                            planejamento.cruzamento = (typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                                (typeof ctrl.testeGeneticoSelecionado.cruzamento !== 'undefined') && ctrl.testeGeneticoSelecionado.cruzamento
                                ? ctrl.testeGeneticoSelecionado.cruzamento : null;
                            if ((typeof planejamento.cruzamento === 'undefined') || !planejamento.cruzamento || (planejamento.cruzamento === '')) {
                                planejamento.cruzamento = (typeof tratamentoTesteGenetico.materialGenetico !== 'undefined') && tratamentoTesteGenetico.materialGenetico &&
                                    (typeof tratamentoTesteGenetico.materialGenetico.cruzamento !== 'undefined') && tratamentoTesteGenetico.materialGenetico.cruzamento
                                    ? tratamentoTesteGenetico.materialGenetico.cruzamento : null;
                            }
                            if ((typeof planejamento.cruzamento === 'undefined') || !planejamento.cruzamento || (planejamento.cruzamento === '')) {
                                planejamento.cruzamento = (typeof tratamentoTesteGenetico.materialGenetico !== 'undefined') && tratamentoTesteGenetico.materialGenetico &&
                                    (typeof tratamentoTesteGenetico.materialGenetico.descricao !== 'undefined') && tratamentoTesteGenetico.materialGenetico.descricao
                                    ? tratamentoTesteGenetico.materialGenetico.descricao : null;
                            }
                            planejamento.conjunto = (typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                                (typeof ctrl.testeGeneticoSelecionado.conjunto !== 'undefined') && ctrl.testeGeneticoSelecionado.conjunto
                                ? ctrl.testeGeneticoSelecionado.conjunto : null;
                            if ((typeof planejamento.conjunto === 'undefined') || !planejamento.conjunto || (planejamento.conjunto === '')) {
                                planejamento.conjunto = (typeof tratamentoTesteGenetico.materialGenetico !== 'undefined') && tratamentoTesteGenetico.materialGenetico &&
                                    (typeof tratamentoTesteGenetico.materialGenetico.conjunto !== 'undefined') && tratamentoTesteGenetico.materialGenetico.conjunto &&
                                    (typeof tratamentoTesteGenetico.materialGenetico.conjunto.descricao !== 'undefined') && tratamentoTesteGenetico.materialGenetico.conjunto.descricao
                                    ? tratamentoTesteGenetico.materialGenetico.conjunto.descricao : null;
                            }
                            planejamento.data = (typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                                (typeof ctrl.testeGeneticoSelecionado.dataImplantacao !== 'undefined') && ctrl.testeGeneticoSelecionado.dataImplantacao
                                ? DataUtil_1.DataUtil.converterValorParaDataHora(ctrl.testeGeneticoSelecionado.dataImplantacao) : null;
                            planejamento.ano = (typeof planejamento.data !== 'undefined') && planejamento.data &&
                                (typeof planejamento.data.getFullYear !== 'undefined') ? planejamento.data.getFullYear() : null;
                            planejamento.fazenda = (typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                                (typeof ctrl.testeGeneticoSelecionado.regiaoFazenda !== 'undefined') && ctrl.testeGeneticoSelecionado.regiaoFazenda
                                ? ((typeof ctrl.testeGeneticoSelecionado.regiaoFazenda.nome !== 'undefined') && ctrl.testeGeneticoSelecionado.regiaoFazenda.nome
                                    ? ctrl.testeGeneticoSelecionado.regiaoFazenda.nome
                                    : ctrl.testeGeneticoSelecionado.regiaoFazenda)
                                : null;
                            if ((typeof planejamento.fazenda === 'undefined') || !planejamento.fazenda || (planejamento.fazenda === '')) {
                                planejamento.fazenda = (typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                                    (typeof ctrl.testeGeneticoSelecionado.pomarOrigem !== 'undefined') && ctrl.testeGeneticoSelecionado.pomarOrigem &&
                                    (typeof ctrl.testeGeneticoSelecionado.pomarOrigem.regiao !== 'undefined') && ctrl.testeGeneticoSelecionado.pomarOrigem.regiao
                                    ? ((typeof ctrl.testeGeneticoSelecionado.pomarOrigem.regiao.nome !== 'undefined') && ctrl.testeGeneticoSelecionado.pomarOrigem.regiao.nome
                                        ? ctrl.testeGeneticoSelecionado.pomarOrigem.regiao.nome
                                        : ctrl.testeGeneticoSelecionado.pomarOrigem.regiao)
                                    : null;
                            }
                            planejamento.talhao = (typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                                (typeof ctrl.testeGeneticoSelecionado.talhao !== 'undefined') && ctrl.testeGeneticoSelecionado.talhao
                                ? ((typeof ctrl.testeGeneticoSelecionado.talhao.numero !== 'undefined') && ctrl.testeGeneticoSelecionado.talhao.numero
                                    ? ctrl.testeGeneticoSelecionado.talhao.numero
                                    : ctrl.testeGeneticoSelecionado.talhao)
                                : null;
                            if ((typeof planejamento.talhao === 'undefined') || !planejamento.talhao || (planejamento.talhao === '')) {
                                planejamento.talhao = (typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                                    (typeof ctrl.testeGeneticoSelecionado.pomarOrigem !== 'undefined') && ctrl.testeGeneticoSelecionado.pomarOrigem &&
                                    (typeof ctrl.testeGeneticoSelecionado.pomarOrigem.talhao !== 'undefined') && ctrl.testeGeneticoSelecionado.pomarOrigem.talhao
                                    ? ((typeof ctrl.testeGeneticoSelecionado.pomarOrigem.talhao.numero !== 'undefined') && ctrl.testeGeneticoSelecionado.pomarOrigem.talhao.numero
                                        ? ctrl.testeGeneticoSelecionado.pomarOrigem.talhao.numero
                                        : ctrl.testeGeneticoSelecionado.pomarOrigem.talhao)
                                    : null;
                            }
                            planejamento.areaTalhao = (typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                                (typeof ctrl.testeGeneticoSelecionado.talhao !== 'undefined') && ctrl.testeGeneticoSelecionado.talhao &&
                                (typeof ctrl.testeGeneticoSelecionado.talhao.areaTalhao !== 'undefined') && ctrl.testeGeneticoSelecionado.talhao.areaTalhao
                                ? ctrl.testeGeneticoSelecionado.talhao.areaTalhao : null;
                            if ((typeof planejamento.areaTalhao === 'undefined') || !planejamento.areaTalhao) {
                                planejamento.areaTalhao = (typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                                    (typeof ctrl.testeGeneticoSelecionado.pomarOrigem !== 'undefined') && ctrl.testeGeneticoSelecionado.pomarOrigem &&
                                    (typeof ctrl.testeGeneticoSelecionado.pomarOrigem.talhao !== 'undefined') && ctrl.testeGeneticoSelecionado.pomarOrigem.talhao &&
                                    (typeof ctrl.testeGeneticoSelecionado.pomarOrigem.talhao.areaTalhao !== 'undefined') && ctrl.testeGeneticoSelecionado.pomarOrigem.talhao.areaTalhao
                                    ? ctrl.testeGeneticoSelecionado.pomarOrigem.talhao.areaTalhao : null;
                            }
                            planejamento.idade = (typeof planejamento.ano !== 'undefined') && planejamento.ano && (planejamento.ano > 1900)
                                ? (DataUtil_1.DataUtil.dataHoraAtual().getFullYear() - planejamento.ano) : null;
                            planejamento.cortado = 'N';
                            planejamento.geada = '';
                            lista.push(planejamento);
                        }
                    });
                }
                let listaMedicoes = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MEDICAO_DENDROMETRICA_TESTE_GENETICO)
                    .filtro({
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    testeGenetico: ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                        (typeof ctrl.testeGeneticoSelecionado.id !== 'undefined') && ctrl.testeGeneticoSelecionado.id
                        ? ctrl.testeGeneticoSelecionado.id : null),
                    agruparPorAnoAnalise: true,
                    agruparPorTratamento: true
                })
                    .listar();
                if ((typeof listaMedicoes !== 'undefined') && listaMedicoes && (typeof lista !== 'undefined') && lista) {
                    angular.forEach(listaMedicoes, (medicao) => {
                        if ((typeof medicao.anoAnalise !== 'undefined') && medicao.anoAnalise &&
                            (typeof medicao.trat !== 'undefined') && medicao.trat &&
                            (typeof medicao.maxCriacao !== 'undefined') && medicao.maxCriacao) {
                            medicao.maxCriacao = DataUtil_1.DataUtil.converterValorParaDataHora(medicao.maxCriacao);
                            angular.forEach(lista, (planejamento) => {
                                planejamento['medicao_' + medicao.anoAnalise] = medicao.maxCriacao;
                            });
                        }
                    });
                }
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'descricao',
                    displayName: 'Tratamento',
                    width: 100
                });
                auxColunas.push({
                    name: 'identificacaoArea',
                    displayName: 'Identificação Área',
                    width: 140
                });
                auxColunas.push({
                    name: 'genero',
                    displayName: 'Gênero',
                    width: 130
                });
                auxColunas.push({
                    name: 'especie',
                    displayName: 'Espécie',
                    width: 130
                });
                auxColunas.push({
                    name: 'origem',
                    displayName: 'Origem',
                    width: 120
                });
                auxColunas.push({
                    name: 'populacao',
                    displayName: 'População',
                    width: 150
                });
                auxColunas.push({
                    name: 'pomar',
                    displayName: 'Pomar',
                    width: 150
                });
                auxColunas.push({
                    name: 'cruzamento',
                    displayName: 'Cruzamento',
                    width: 120
                });
                auxColunas.push({
                    name: 'conjunto',
                    displayName: 'Conjunto',
                    width: 140
                });
                auxColunas.push({
                    name: 'data',
                    displayName: 'Data',
                    width: 120,
                    cellFilter: 'date:"dd/MM/yyyy"'
                });
                auxColunas.push({
                    name: 'ano',
                    displayName: 'Ano',
                    width: 80
                });
                auxColunas.push({
                    name: 'fazenda',
                    displayName: 'Fazenda',
                    width: 150
                });
                auxColunas.push({
                    name: 'talhao',
                    displayName: 'Talhão',
                    width: 90
                });
                auxColunas.push({
                    name: 'areaTalhao',
                    displayName: 'Hectares',
                    width: 90
                });
                auxColunas.push({
                    name: 'idade',
                    displayName: 'Idade',
                    width: 90
                });
                auxColunas.push({
                    name: 'cortado',
                    displayName: 'Cortado',
                    width: 90
                });
                auxColunas.push({
                    name: 'geada',
                    displayName: 'Geada',
                    width: 90
                });
                auxColunas.push({
                    name: 'meses',
                    displayName: 'Meses',
                    width: 100
                });
                let anosTesteGenetico = AnoAnaliseTesteGenetico_1.AnoAnaliseTesteGenetico.LISTA();
                angular.forEach(anosTesteGenetico, (anoAnaliseTesteGenetico) => {
                    auxColunas.push({
                        name: 'medicao_' + anoAnaliseTesteGenetico.id,
                        displayName: anoAnaliseTesteGenetico.descricao,
                        cellFilter: 'date:"dd/MM/yyyy"',
                        width: 90
                    });
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
            }
            return null;
        });
    }
    static dadosListaQualidadeMadeiraTesteGenetico(ctrl, seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const funcaoOrigemCarregamento = 'dadosListaQualidadeMadeiraTesteGenetico';
            try {
                if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(seletorCarregamento ? seletorCarregamento : '.card', funcaoOrigemCarregamento);
                let lista = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.QUALIDADE_MADEIRA_TESTE_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.QUALIDADE_MADEIRA_TESTE_GENETICO)
                    .filtro({
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    testeGenetico: ctrl.testeGeneticoSelecionado.id
                })
                    .listar();
                if ((typeof lista !== 'undefined') && lista) {
                    let tiposAnexosQualidadeMadeira = TipoAnexoQualidadeMadeira_1.TipoAnexoQualidadeMadeira.LISTA();
                    angular.forEach(lista, (qualidadeMadeira) => {
                        if ((typeof qualidadeMadeira.tipoAnexo !== 'undefined') && qualidadeMadeira.tipoAnexo) {
                            let tipoAnexoQualidadeMadeira = tiposAnexosQualidadeMadeira ? tiposAnexosQualidadeMadeira.find(x => x.id === qualidadeMadeira.tipoAnexo) : null;
                            if ((typeof tipoAnexoQualidadeMadeira !== 'undefined') && tipoAnexoQualidadeMadeira &&
                                (typeof tipoAnexoQualidadeMadeira.descricao !== 'undefined') && tipoAnexoQualidadeMadeira.descricao) {
                                qualidadeMadeira.tipoAnexoDescricao = tipoAnexoQualidadeMadeira.descricao;
                            }
                            else {
                                qualidadeMadeira.tipoAnexoDescricao = qualidadeMadeira.tipoAnexo;
                            }
                        }
                    });
                }
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    cellClass: 'text-center',
                    cellTemplate: `
                        <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                            <button type="button" class="btn btn-icon bx bx-dots-vertical-rounded" uib-dropdown-toggle></button>
                            <ul class="dropdown-menu dropdown-menu-left" uib-dropdown-menu role="menu">
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalQualidadeMadeiraTesteGenetico({ id: row.entity.id || row.entity.uuid, visualizar: true })' class="text-secondary w-100"><i class="bx bx-show mr-1 ml-1"></i>Visualizar</a></li>
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalQualidadeMadeiraTesteGenetico({ id: row.entity.id || row.entity.uuid, editar: true })' class="text-secondary w-100"><i class="bx bxs-edit-alt mr-1 ml-1"></i>Editar</a></li>
                                <li><hr></li>
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalQualidadeMadeiraTesteGenetico({ id: row.entity.id || row.entity.uuid, excluir: true })' class="text-secondary w-100"><i class="bx bxs-trash-alt mr-1 ml-1"></i>Excluir</a></li>
                            </ul>
                        </div> `
                });
                auxColunas.push({
                    name: 'tipoAnexoDescricao',
                    displayName: 'Tipo de Anexo',
                    width: 160
                });
                auxColunas.push({
                    name: 'descricao',
                    displayName: 'Descrição do Anexo',
                    width: 300
                });
                auxColunas.push({
                    name: 'criacao',
                    displayName: 'Data/Hora anexo',
                    cellFilter: 'date:"dd/MM/yyyy HH:mm:ss"',
                    width: 160
                });
                auxColunas.push({
                    name: 'usuarioInclusao.nomeCompleto',
                    displayName: 'Anexado por',
                    width: 200
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
            }
            return null;
        });
    }
    static dadosListaAnaliseSelecaoTesteGenetico(ctrl, seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const funcaoOrigemCarregamento = 'dadosListaAnaliseSelecaoTesteGenetico';
            try {
                if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(seletorCarregamento ? seletorCarregamento : '.card', funcaoOrigemCarregamento);
                const numTotalBlocos = ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                    (typeof ctrl.testeGeneticoSelecionado.numBlocos !== 'undefined') && ctrl.testeGeneticoSelecionado.numBlocos
                    ? ctrl.testeGeneticoSelecionado.numBlocos : 1);
                const numTotalParcelas = ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                    (typeof ctrl.testeGeneticoSelecionado.numParcelas !== 'undefined') && ctrl.testeGeneticoSelecionado.numParcelas
                    ? ctrl.testeGeneticoSelecionado.numParcelas : 1);
                const numArvoresPorTratamento = ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                    (typeof ctrl.testeGeneticoSelecionado.delineamentoArvoresPorTratamento !== 'undefined') && ctrl.testeGeneticoSelecionado.delineamentoArvoresPorTratamento
                    ? ctrl.testeGeneticoSelecionado.delineamentoArvoresPorTratamento : 1);
                let lista = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANALISE_SELECAO_TESTE_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANALISE_SELECAO_TESTE_GENETICO)
                    .filtro({
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    testeGenetico: ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                        (typeof ctrl.testeGeneticoSelecionado.id !== 'undefined') && ctrl.testeGeneticoSelecionado.id
                        ? ctrl.testeGeneticoSelecionado.id : null),
                    anoAnalise: ((typeof ctrl.testeGeneticoSelecionado !== 'undefined') && ctrl.testeGeneticoSelecionado &&
                        (typeof ctrl.testeGeneticoSelecionado.anoAnalise !== 'undefined') && ctrl.testeGeneticoSelecionado.anoAnalise
                        ? ctrl.testeGeneticoSelecionado.anoAnalise : null)
                })
                    .listar();
                if ((typeof lista === 'undefined') || !lista || (lista.length === 0)) {
                    if ((typeof ctrl.listaTratamentosTesteGenetico !== 'undefined') && ctrl.listaTratamentosTesteGenetico) {
                        angular.forEach(ctrl.listaTratamentosTesteGenetico, (tratamentoTesteGenetico) => {
                            if ((typeof tratamentoTesteGenetico.materialGenetico !== 'undefined') && tratamentoTesteGenetico.materialGenetico &&
                                (typeof tratamentoTesteGenetico.materialGenetico.descricao !== 'undefined') && tratamentoTesteGenetico.materialGenetico.descricao &&
                                (tratamentoTesteGenetico.materialGenetico.descricao.trim().length > 0)) {
                                for (let bloco = 1; bloco <= numTotalBlocos; bloco++) {
                                    for (let parcela = 1; parcela <= numTotalParcelas; parcela++) {
                                        for (let arvore = 1; arvore <= numArvoresPorTratamento; arvore++) {
                                            const materialGenetico = tratamentoTesteGenetico.materialGenetico.descricao.trim();
                                            let registro = new AnaliseSelecaoTesteGenetico_1.AnaliseSelecaoTesteGenetico();
                                            registro.testeGenetico = ctrl.testeGeneticoSelecionado;
                                            registro.bloco = bloco;
                                            registro.parcela = parcela;
                                            registro.parc = parcela;
                                            registro.tratamento = materialGenetico;
                                            registro.trat = materialGenetico;
                                            registro.arvore = arvore;
                                            registro.arv = arvore;
                                            registro.dbh5 = 0;
                                            registro.h5 = 0;
                                            registro.str = 0;
                                            registro.fork = 0;
                                            registro.cq = null;
                                            lista.push(registro);
                                        }
                                    }
                                }
                            }
                        });
                    }
                }
                ctrl.listaAnaliseSelecao = lista;
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    cellClass: 'text-center',
                    cellTemplate: `
                        <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                            <button type="button" class="btn btn-icon bx bx-dots-vertical-rounded" uib-dropdown-toggle></button>
                            <ul class="dropdown-menu dropdown-menu-left" uib-dropdown-menu role="menu">
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalAnaliseSelecaoTesteGenetico({ id: row.entity.id || row.entity.uuid, visualizar: true })' class="text-secondary w-100"><i class="bx bx-show mr-1 ml-1"></i>Visualizar</a></li>
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalAnaliseSelecaoTesteGenetico({ id: row.entity.id || row.entity.uuid, editar: true })' class="text-secondary w-100"><i class="bx bxs-edit-alt mr-1 ml-1"></i>Editar</a></li>
                                <li><hr></li>
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalAnaliseSelecaoTesteGenetico({ id: row.entity.id || row.entity.uuid, excluir: true })' class="text-secondary w-100"><i class="bx bxs-trash-alt mr-1 ml-1"></i>Excluir</a></li>
                            </ul>
                        </div> `
                });
                auxColunas.push({
                    name: 'bloco',
                    displayName: 'BLOCO',
                    width: 100
                });
                auxColunas.push({
                    name: 'parc',
                    displayName: 'Parcela',
                    width: 100
                });
                auxColunas.push({
                    name: 'trat',
                    displayName: 'Tratamento',
                    width: 100
                });
                auxColunas.push({
                    name: 'arv',
                    displayName: 'Árvore',
                    width: 100
                });
                auxColunas.push({
                    name: 'dap',
                    displayName: 'DAP',
                    width: 100
                });
                auxColunas.push({
                    name: 'alt',
                    displayName: 'ALT',
                    width: 100
                });
                auxColunas.push({
                    name: 'forma',
                    displayName: 'FORMA',
                    width: 100
                });
                auxColunas.push({
                    name: 'for',
                    displayName: 'FOR',
                    width: 100
                });
                auxColunas.push({
                    name: 'cq',
                    displayName: 'CQ',
                    width: 100
                });
                auxColunas.push({
                    name: 'volume',
                    displayName: 'Volume',
                    width: 100,
                    cellFilter: 'number: 4'
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                return {
                    data: ctrl.listaAnaliseSelecao,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
            }
            return null;
        });
    }
    static dadosListaSumarioAnaliseSelecaoTesteGenetico(ctrl, seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const funcaoOrigemCarregamento = 'dadosListaSumarioAnaliseSelecaoTesteGenetico';
            try {
                if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(seletorCarregamento ? seletorCarregamento : '.card', funcaoOrigemCarregamento);
                let lista = [];
                if ((typeof lista === 'undefined') || !lista || (lista.length === 0)) {
                    if ((typeof ctrl.listaTratamentosTesteGenetico !== 'undefined') && ctrl.listaTratamentosTesteGenetico) {
                        angular.forEach(ctrl.listaTratamentosTesteGenetico, (tratamentoTesteGenetico) => {
                            if ((typeof tratamentoTesteGenetico.materialGenetico !== 'undefined') && tratamentoTesteGenetico.materialGenetico &&
                                (typeof tratamentoTesteGenetico.materialGenetico.descricao !== 'undefined') && tratamentoTesteGenetico.materialGenetico.descricao &&
                                (tratamentoTesteGenetico.materialGenetico.descricao.trim().length > 0)) {
                                const materialGenetico = tratamentoTesteGenetico.materialGenetico.descricao.trim();
                                let sumario = new SumarioAnaliseSelecaoTesteGenetico_1.SumarioAnaliseSelecaoTesteGenetico();
                                sumario.testeGenetico = ctrl.testeGeneticoSelecionado;
                                sumario.descricao = materialGenetico;
                                lista.push(sumario);
                            }
                        });
                    }
                }
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'descricao',
                    displayName: 'Clone',
                    width: 120
                });
                auxColunas.push({
                    name: 'ganho',
                    displayName: 'Ganho',
                    width: 100
                });
                auxColunas.push({
                    name: 'forma',
                    displayName: 'Forma',
                    width: 110
                });
                auxColunas.push({
                    name: 'bifurcacao',
                    displayName: 'Bifurc.',
                    width: 112
                });
                auxColunas.push({
                    name: 'copa_q',
                    displayName: 'Copa Q',
                    width: 112
                });
                auxColunas.push({
                    name: 'media_voluma',
                    displayName: 'Média Volume',
                    width: 130
                });
                auxColunas.push({
                    name: 'num_mudas',
                    displayName: 'Nº Mudas',
                    width: 110
                });
                auxColunas.push({
                    name: 'perc_sobrevivencia',
                    displayName: '% Sobrev.',
                    width: 110
                });
                auxColunas.push({
                    name: 'ima_corrigido_1',
                    displayName: 'IMA Corrigido #1',
                    width: 130
                });
                auxColunas.push({
                    name: 'ima_corrigido_2',
                    displayName: 'IMA Corrigido #2',
                    width: 130
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
            }
            return null;
        });
    }
    static dadosListaAnaliseSelegenAnaliseSelecaoTesteGenetico(ctrl, seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const funcaoOrigemCarregamento = 'dadosListaAnaliseSelegenAnaliseSelecaoTesteGenetico';
            try {
                if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(seletorCarregamento ? seletorCarregamento : '.card', funcaoOrigemCarregamento);
                let lista = [];
                if ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado !== 'undefined') && ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado) {
                    const tipoAnaliseSelegen = ((typeof ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.tipoAnaliseSelegen !== 'undefined') &&
                        ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.tipoAnaliseSelegen)
                        ? ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.tipoAnaliseSelegen : null;
                    if (tipoAnaliseSelegen === TipoAnaliseSelegenTesteGenetico_1.TipoAnaliseSelegenTesteGenetico.MODELO_1().id) {
                        lista = ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo1;
                    }
                    else if (tipoAnaliseSelegen === TipoAnaliseSelegenTesteGenetico_1.TipoAnaliseSelegenTesteGenetico.MODELO_2().id) {
                        lista = ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo2;
                    }
                    else if (tipoAnaliseSelegen === TipoAnaliseSelegenTesteGenetico_1.TipoAnaliseSelegenTesteGenetico.MODELO_4().id) {
                        lista = ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo4;
                    }
                    else if (tipoAnaliseSelegen === TipoAnaliseSelegenTesteGenetico_1.TipoAnaliseSelegenTesteGenetico.MODELO_24().id) {
                        lista = ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo24;
                    }
                    else if (tipoAnaliseSelegen === TipoAnaliseSelegenTesteGenetico_1.TipoAnaliseSelegenTesteGenetico.MODELO_33().id) {
                        lista = ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo33;
                    }
                    else if (tipoAnaliseSelegen === TipoAnaliseSelegenTesteGenetico_1.TipoAnaliseSelegenTesteGenetico.MODELO_51().id) {
                        lista = ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo51;
                    }
                    else if (tipoAnaliseSelegen === TipoAnaliseSelegenTesteGenetico_1.TipoAnaliseSelegenTesteGenetico.MODELO_105().id) {
                        lista = ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo105;
                    }
                    else if (tipoAnaliseSelegen === TipoAnaliseSelegenTesteGenetico_1.TipoAnaliseSelegenTesteGenetico.MODELO_106().id) {
                        lista = ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo106;
                    }
                    else if (tipoAnaliseSelegen === TipoAnaliseSelegenTesteGenetico_1.TipoAnaliseSelegenTesteGenetico.MODELO_44_1().id) {
                        lista = ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo441;
                    }
                    else if (tipoAnaliseSelegen === TipoAnaliseSelegenTesteGenetico_1.TipoAnaliseSelegenTesteGenetico.MODELO_44_2().id) {
                        lista = ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo442;
                    }
                    else if (tipoAnaliseSelegen === TipoAnaliseSelegenTesteGenetico_1.TipoAnaliseSelegenTesteGenetico.MODELO_37().id) {
                        lista = ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosModelo37;
                    }
                    else {
                        lista = ctrl.analiseSelegenAnaliseSelecaoTesteGeneticoSelecionado.dadosPlanilha;
                    }
                }
                let auxColunas = new Array();
                if (lista && (lista.length > 0)) {
                    for (const [key, _value] of Object.entries(lista[0])) {
                        let titulo = String(key).trim();
                        const ehCampoOculto = ['id', '_id', 'persistGuid', 'uuid', 'status', 'criacao', 'chave', 'syncLocal'].includes(titulo);
                        if (!ehCampoOculto) {
                            if (titulo.length > 3) {
                                titulo = titulo.substring(0, 1).toUpperCase() + titulo.substring(1);
                            }
                            else {
                                titulo = titulo.toUpperCase();
                            }
                            auxColunas.push({
                                name: key,
                                displayName: titulo,
                                width: 120
                            });
                        }
                    }
                }
                else {
                    auxColunas.push({
                        name: 'individuo',
                        displayName: 'Indivíduo',
                        width: 120
                    });
                    auxColunas.push({
                        name: 'clone',
                        displayName: 'Clone',
                        width: 120
                    });
                    auxColunas.push({
                        name: 'bloco',
                        displayName: 'Bloco',
                        width: 100
                    });
                    auxColunas.push({
                        name: 'parcela',
                        displayName: 'Parcela',
                        width: 100
                    });
                    auxColunas.push({
                        name: 'arvore',
                        displayName: 'Árvore',
                        width: 100
                    });
                    auxColunas.push({
                        name: 'volume',
                        displayName: 'Volume',
                        width: 110
                    });
                }
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
            }
            return null;
        });
    }
    static dadosListaRankingResultadoSelegenAnaliseSelecaoTesteGenetico(ctrl, seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const funcaoOrigemCarregamento = 'dadosListaRankingResultadoSelegenAnaliseSelecaoTesteGenetico';
            try {
                if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(seletorCarregamento ? seletorCarregamento : '.card', funcaoOrigemCarregamento);
                let lista = [];
                if ((typeof ctrl.listaTratamentosTesteGenetico !== 'undefined') && ctrl.listaTratamentosTesteGenetico) {
                    let ordem = 0;
                    angular.forEach(ctrl.listaTratamentosTesteGenetico, (tratamentoTesteGenetico) => {
                        if ((typeof tratamentoTesteGenetico.materialGenetico !== 'undefined') && tratamentoTesteGenetico.materialGenetico &&
                            (typeof tratamentoTesteGenetico.materialGenetico.descricao !== 'undefined') && tratamentoTesteGenetico.materialGenetico.descricao &&
                            (tratamentoTesteGenetico.materialGenetico.descricao.trim().length > 0)) {
                            ordem++;
                            lista.push({
                                ordem: ordem,
                                clone: tratamentoTesteGenetico.materialGenetico.descricao.trim(),
                                g: NumeroUtil_1.NumeroUtil.gerarNumeroAleatorio(-0.001, 0.02, 4),
                                u_g: NumeroUtil_1.NumeroUtil.gerarNumeroAleatorio(113.980, 114.399, 3),
                                ganho: NumeroUtil_1.NumeroUtil.gerarNumeroAleatorio(0.0010, 0.0150, 4),
                                nova: NumeroUtil_1.NumeroUtil.gerarNumeroAleatorio(113.980, 114.399, 3),
                                media: 0
                            });
                        }
                    });
                    angular.forEach(lista, (item) => {
                        item.media = NumeroUtil_1.NumeroUtil.arredondarNumero((item.u_g + item.nova) / 2, 3);
                    });
                }
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'ordem',
                    displayName: 'Ordem',
                    width: 100
                });
                auxColunas.push({
                    name: 'clone',
                    displayName: 'Clone',
                    width: 120
                });
                auxColunas.push({
                    name: 'g',
                    displayName: 'g',
                    width: 110
                });
                auxColunas.push({
                    name: 'u_g',
                    displayName: 'u * g',
                    width: 110
                });
                auxColunas.push({
                    name: 'ganho',
                    displayName: 'Ganho',
                    width: 110
                });
                auxColunas.push({
                    name: 'nova',
                    displayName: 'Nova',
                    width: 110
                });
                auxColunas.push({
                    name: 'media',
                    displayName: 'Média',
                    width: 110
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
            }
            return null;
        });
    }
    static dadosListaVariaveisResultadoSelegenAnaliseSelecaoTesteGenetico(ctrl, seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const funcaoOrigemCarregamento = 'dadosListaVariaveisResultadoSelegenAnaliseSelecaoTesteGenetico';
            try {
                if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(seletorCarregamento ? seletorCarregamento : '.card', funcaoOrigemCarregamento);
                let lista = [
                    { nome: 'Vg', valor: 0.000007 },
                    { nome: 'Vparc', valor: 0.001356 },
                    { nome: 'Ve', valor: 0.002851 },
                    { nome: 'Vf', valor: 0.004214 },
                    { nome: 'h2g', valor: 0.005541 },
                    { nome: 'h2aj', valor: 0.002568 },
                    { nome: 'c2parc', valor: 0.321734 },
                    { nome: 'h2mc', valor: 0.012992 },
                    { nome: 'Acclon', valor: 0.113983 },
                    { nome: 'CVgi%', valor: 2.946468 },
                    { nome: 'CVe%', valor: 44.48201 },
                    { nome: 'CVr', valor: 0.06624 },
                    { nome: 'PEV', valor: 0.000007 },
                    { nome: 'SEP', valor: 0.002691 },
                    { nome: 'Média geral', valor: 0.091938 }
                ];
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'nome',
                    displayName: 'Nome',
                    width: 140
                });
                auxColunas.push({
                    name: 'valor',
                    displayName: 'Valor',
                    width: 120
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
            }
            return null;
        });
    }
    static dadosListaResultadoSelegenAnaliseSelecaoTesteGenetico(ctrl, seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const funcaoOrigemCarregamento = 'dadosListaLancamentoMedicaoDendrometricaTesteGenetico';
            try {
                if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(seletorCarregamento ? seletorCarregamento : '.card', funcaoOrigemCarregamento);
                let lista = new Array();
                if ((typeof lista === 'undefined') || !lista || (lista.length === 0)) {
                    if ((typeof ctrl.listaTratamentosTesteGenetico !== 'undefined') && ctrl.listaTratamentosTesteGenetico) {
                        angular.forEach(ctrl.listaTratamentosTesteGenetico, (tratamentoTesteGenetico) => {
                            if ((typeof tratamentoTesteGenetico.materialGenetico !== 'undefined') && tratamentoTesteGenetico.materialGenetico &&
                                (typeof tratamentoTesteGenetico.materialGenetico.descricao !== 'undefined') && tratamentoTesteGenetico.materialGenetico.descricao &&
                                (tratamentoTesteGenetico.materialGenetico.descricao.trim().length > 0)) {
                                for (let bloco = 1; bloco < 10; bloco++) {
                                    for (let parcela = 1; parcela < 10; parcela++) {
                                        for (let arvore = 1; arvore < 10; arvore++) {
                                            const materialGenetico = tratamentoTesteGenetico.materialGenetico.descricao.trim();
                                            let resultado = new ResultadoSelegenAnaliseSelecaoTesteGenetico_1.ResultadoSelegenAnaliseSelecaoTesteGenetico();
                                            resultado.testeGenetico = ctrl.testeGeneticoSelecionado;
                                            resultado.bloco = bloco;
                                            resultado.parc = parcela;
                                            resultado.trat = materialGenetico;
                                            resultado.arv = arvore;
                                            resultado.dbh5 = NumeroUtil_1.NumeroUtil.gerarNumeroAleatorio(5.9, 15, 1);
                                            resultado.h5 = NumeroUtil_1.NumeroUtil.gerarNumeroAleatorio(11.5, 21.9, 1);
                                            resultado.str = 0;
                                            resultado.fork = 0;
                                            resultado.cq = 0;
                                            lista.push(resultado);
                                        }
                                    }
                                }
                            }
                        });
                    }
                }
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'bloco',
                    displayName: 'BLOCO',
                    width: 100
                });
                auxColunas.push({
                    name: 'parc',
                    displayName: 'PARC',
                    width: 100
                });
                auxColunas.push({
                    name: 'trat',
                    displayName: 'TRAT',
                    width: 100
                });
                auxColunas.push({
                    name: 'arv',
                    displayName: 'ARV',
                    width: 100
                });
                auxColunas.push({
                    name: 'dbh5',
                    displayName: 'DBH5',
                    width: 100
                });
                auxColunas.push({
                    name: 'h5',
                    displayName: 'H5',
                    width: 100
                });
                auxColunas.push({
                    name: 'str',
                    displayName: 'STR',
                    width: 100
                });
                auxColunas.push({
                    name: 'fork',
                    displayName: 'FORK',
                    width: 100
                });
                auxColunas.push({
                    name: 'cq',
                    displayName: 'CQ',
                    width: 100
                });
                if ((typeof ctrl.listaTratamentosTesteGenetico !== 'undefined') && ctrl.listaTratamentosTesteGenetico) {
                    angular.forEach(ctrl.listaTratamentosTesteGenetico, (tratamentoTesteGenetico) => {
                        if ((typeof tratamentoTesteGenetico.materialGenetico !== 'undefined') && tratamentoTesteGenetico.materialGenetico &&
                            (typeof tratamentoTesteGenetico.materialGenetico.descricao !== 'undefined') && tratamentoTesteGenetico.materialGenetico.descricao &&
                            (tratamentoTesteGenetico.materialGenetico.descricao.trim().length > 0)) {
                            auxColunas.push({
                                name: 'progenie_' + tratamentoTesteGenetico.materialGenetico.descricao.trim(),
                                displayName: 'Progênie ' + tratamentoTesteGenetico.materialGenetico.descricao.trim(),
                                width: 130
                            });
                        }
                    });
                }
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
            }
            return null;
        });
    }
    static dadosListaAnaliseAnoAnaliseSelecaoTesteGenetico(ctrl, seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const funcaoOrigemCarregamento = 'dadosListaAnaliseAnoAnaliseSelecaoTesteGenetico';
            try {
                if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(seletorCarregamento ? seletorCarregamento : '.card', funcaoOrigemCarregamento);
                let lista = [];
                if ((typeof lista === 'undefined') || !lista || (lista.length === 0)) {
                    if ((typeof ctrl.listaTratamentosTesteGenetico !== 'undefined') && ctrl.listaTratamentosTesteGenetico) {
                        angular.forEach(ctrl.listaTratamentosTesteGenetico, (tratamentoTesteGenetico) => {
                            if ((typeof tratamentoTesteGenetico.materialGenetico !== 'undefined') && tratamentoTesteGenetico.materialGenetico &&
                                (typeof tratamentoTesteGenetico.materialGenetico.descricao !== 'undefined') && tratamentoTesteGenetico.materialGenetico.descricao &&
                                (tratamentoTesteGenetico.materialGenetico.descricao.trim().length > 0)) {
                                for (let bloco = 1; bloco < 10; bloco++) {
                                    for (let parcela = 1; parcela < 10; parcela++) {
                                        for (let arvore = 1; arvore < 10; arvore++) {
                                            const materialGenetico = tratamentoTesteGenetico.materialGenetico.descricao.trim();
                                            let analiseAno = new AnaliseAnoAnaliseSelecaoTesteGenetico_1.AnaliseAnoAnaliseSelecaoTesteGenetico();
                                            analiseAno.testeGenetico = ctrl.testeGeneticoSelecionado;
                                            analiseAno.bloco = bloco;
                                            analiseAno.parc = parcela;
                                            analiseAno.trat = materialGenetico;
                                            analiseAno.arv = arvore;
                                            analiseAno.dbh5 = NumeroUtil_1.NumeroUtil.gerarNumeroAleatorio(5.9, 15, 1);
                                            analiseAno.h5 = NumeroUtil_1.NumeroUtil.gerarNumeroAleatorio(11.5, 21.9, 1);
                                            analiseAno.str = 0;
                                            analiseAno.fork = 0;
                                            analiseAno.cq = 0;
                                            lista.push(analiseAno);
                                        }
                                    }
                                }
                            }
                        });
                    }
                }
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'bloco',
                    displayName: 'BLOCO',
                    width: 100
                });
                auxColunas.push({
                    name: 'parc',
                    displayName: 'PARC',
                    width: 100
                });
                auxColunas.push({
                    name: 'trat',
                    displayName: 'TRAT',
                    width: 100
                });
                auxColunas.push({
                    name: 'arv',
                    displayName: 'ARV',
                    width: 100
                });
                auxColunas.push({
                    name: 'dbh5',
                    displayName: 'DBH5',
                    width: 100
                });
                auxColunas.push({
                    name: 'h5',
                    displayName: 'H5',
                    width: 100
                });
                auxColunas.push({
                    name: 'str',
                    displayName: 'STR',
                    width: 100
                });
                auxColunas.push({
                    name: 'fork',
                    displayName: 'FORK',
                    width: 100
                });
                auxColunas.push({
                    name: 'cq',
                    displayName: 'CQ',
                    width: 100
                });
                if ((typeof ctrl.listaTratamentosTesteGenetico !== 'undefined') && ctrl.listaTratamentosTesteGenetico) {
                    angular.forEach(ctrl.listaTratamentosTesteGenetico, (tratamentoTesteGenetico) => {
                        if ((typeof tratamentoTesteGenetico.materialGenetico !== 'undefined') && tratamentoTesteGenetico.materialGenetico &&
                            (typeof tratamentoTesteGenetico.materialGenetico.descricao !== 'undefined') && tratamentoTesteGenetico.materialGenetico.descricao &&
                            (tratamentoTesteGenetico.materialGenetico.descricao.trim().length > 0)) {
                            auxColunas.push({
                                name: 'progenie_' + tratamentoTesteGenetico.materialGenetico.descricao.trim(),
                                displayName: 'Progênie ' + tratamentoTesteGenetico.materialGenetico.descricao.trim(),
                                width: 130
                            });
                        }
                    });
                }
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
            }
            return null;
        });
    }
    static dadosListaParametrosAnaliseBlupAnaliseSelecaoTesteGenetico(ctrl, seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const funcaoOrigemCarregamento = 'dadosListaParametrosAnaliseBlupAnaliseSelecaoTesteGenetico';
            try {
                if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(seletorCarregamento ? seletorCarregamento : '.card', funcaoOrigemCarregamento);
                let contadorClones = 0;
                if ((typeof ctrl.listaTratamentosTesteGenetico !== 'undefined') && ctrl.listaTratamentosTesteGenetico) {
                    angular.forEach(ctrl.listaTratamentosTesteGenetico, (tratamentoTesteGenetico) => {
                        if ((typeof tratamentoTesteGenetico.materialGenetico !== 'undefined') && tratamentoTesteGenetico.materialGenetico &&
                            (typeof tratamentoTesteGenetico.materialGenetico.descricao !== 'undefined') && tratamentoTesteGenetico.materialGenetico.descricao &&
                            (tratamentoTesteGenetico.materialGenetico.descricao.trim().length > 0)) {
                            contadorClones++;
                        }
                    });
                }
                let lista = [
                    { nome: 'Número total de indivíduos', valor: contadorClones * 10 * 10 * 10 },
                    { nome: 'Sobrevivência', valor: 0 },
                    { nome: 'Correlação genética entre parentes', valor: (contadorClones > 0) ? 0.0025 : 0 },
                    { nome: 'Herdabilidade', valor: (contadorClones > 0) ? 0.0045 : 0 },
                    { nome: 'Número de medições', valor: contadorClones },
                    { nome: 'Repetibilidade', valor: 0 },
                    { nome: 'Correlação de ambiente comum', valor: (contadorClones > 0) ? 0.2500 : 0 },
                    { nome: 'Correlação genética de dominância', valor: (contadorClones > 0) ? 0.02322 : 0 },
                    { nome: 'Herdabilidade no sentido amplo', valor: (contadorClones > 0) ? 0.102911 : 0 },
                    { nome: 'Desvios para seleção de efeito de dominân Desvio padrão da população', valor: (contadorClones > 0) ? 2.464951 : 0 }
                ];
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'valor',
                    displayName: 'Valor',
                    width: 130
                });
                auxColunas.push({
                    name: 'nome',
                    displayName: 'Nome',
                    width: 250
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
            }
            return null;
        });
    }
    static dadosListaLimitesReferenciaAnaliseBlupAnaliseSelecaoTesteGenetico(ctrl, seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const funcaoOrigemCarregamento = 'dadosListaLimitesReferenciaAnaliseBlupAnaliseSelecaoTesteGenetico';
            try {
                if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(seletorCarregamento ? seletorCarregamento : '.card', funcaoOrigemCarregamento);
                let lista = [];
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'identificacao',
                    displayName: 'Identificação',
                    width: 160
                });
                auxColunas.push({
                    name: 'individuo',
                    displayName: 'Indivíduo',
                    width: 120
                });
                auxColunas.push({
                    name: 'familia',
                    displayName: 'Família',
                    width: 200
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
            }
            return null;
        });
    }
    static dadosListaAnaliseBlupAnaliseSelecaoTesteGenetico(ctrl, seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const funcaoOrigemCarregamento = 'dadosListaAnaliseBlupAnaliseSelecaoTesteGenetico';
            try {
                if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(seletorCarregamento ? seletorCarregamento : '.card', funcaoOrigemCarregamento);
                let lista = [];
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'familia',
                    displayName: 'Família',
                    width: 120
                });
                auxColunas.push({
                    name: 'bloco',
                    displayName: 'Bloco',
                    width: 100
                });
                auxColunas.push({
                    name: 'individuo',
                    displayName: 'Indivíduo',
                    width: 120
                });
                auxColunas.push({
                    name: 'indiceSelecao',
                    displayName: 'Índice de Seleção',
                    width: 120
                });
                auxColunas.push({
                    name: 'valoresGeneticos',
                    displayName: 'Valores Genéticos',
                    width: 140
                });
                auxColunas.push({
                    name: 'volume',
                    displayName: 'Volume',
                    width: 120
                });
                auxColunas.push({
                    name: 'forma',
                    displayName: 'Forma',
                    width: 110
                });
                auxColunas.push({
                    name: 'bifurc',
                    displayName: 'Bifurc.',
                    width: 110
                });
                auxColunas.push({
                    name: 'cQueb',
                    displayName: 'C. Queb',
                    width: 110
                });
                auxColunas.push({
                    name: 'foxTail',
                    displayName: 'FoxTail',
                    width: 110
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
            }
            return null;
        });
    }
    static dadosListaCriteriosSelecaoGeneticaAnaliseSelecaoTesteGenetico(ctrl, seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const funcaoOrigemCarregamento = 'dadosListaCriteriosSelecaoGeneticaAnaliseSelecaoTesteGenetico';
            try {
                if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(seletorCarregamento ? seletorCarregamento : '.card', funcaoOrigemCarregamento);
                let lista = [
                    { nome: 'Número total de indivíduos', valor: 2 },
                    { nome: 'Número de famílias selecionadas', valor: 2 },
                    { nome: 'Valor genético acumulado', valor: NumeroUtil_1.NumeroUtil.gerarNumeroAleatorio(0.10, 0.29, 4) },
                    { nome: 'Média da população melhorada', valor: NumeroUtil_1.NumeroUtil.gerarNumeroAleatorio(0.39, 0.59, 4) },
                    { nome: 'Tamanho efetivo populacional (Ne)', valor: 50.00 }
                ];
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'nome',
                    displayName: 'Nome',
                    width: 150
                });
                auxColunas.push({
                    name: 'valor',
                    displayName: 'Valor',
                    width: 100
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(seletorCarregamento ? seletorCarregamento : '.card', funcaoOrigemCarregamento);
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(seletorCarregamento ? seletorCarregamento : '.card', funcaoOrigemCarregamento);
            }
            return null;
        });
    }
    static dadosListaLimitesReferenciaSelecaoGeneticaAnaliseSelecaoTesteGenetico(ctrl, seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const funcaoOrigemCarregamento = 'dadosListaLimitesReferenciaSelecaoGeneticaAnaliseSelecaoTesteGenetico';
            try {
                if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(seletorCarregamento ? seletorCarregamento : '.card', funcaoOrigemCarregamento);
                let lista = [];
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'nome',
                    displayName: 'Nome',
                    width: 150
                });
                auxColunas.push({
                    name: 'valor',
                    displayName: 'Valor',
                    width: 100
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
            }
            return null;
        });
    }
    static dadosListaSelecaoGeneticaAnaliseSelecaoTesteGenetico(ctrl, seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const funcaoOrigemCarregamento = 'dadosListaSelecaoGeneticaAnaliseSelecaoTesteGenetico';
            try {
                if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(seletorCarregamento ? seletorCarregamento : '.card', funcaoOrigemCarregamento);
                let lista = [];
                if ((typeof lista === 'undefined') || !lista || (lista.length === 0)) {
                    if ((typeof ctrl.listaTratamentosTesteGenetico !== 'undefined') && ctrl.listaTratamentosTesteGenetico) {
                        angular.forEach(ctrl.listaTratamentosTesteGenetico, (tratamentoTesteGenetico) => {
                            if ((typeof tratamentoTesteGenetico.materialGenetico !== 'undefined') && tratamentoTesteGenetico.materialGenetico &&
                                (typeof tratamentoTesteGenetico.materialGenetico.descricao !== 'undefined') && tratamentoTesteGenetico.materialGenetico.descricao &&
                                (tratamentoTesteGenetico.materialGenetico.descricao.trim().length > 0)) {
                                for (let bloco = 1; bloco < 10; bloco++) {
                                    for (let parcela = 1; parcela < 10; parcela++) {
                                        for (let arvore = 1; arvore < 10; arvore++) {
                                            const materialGenetico = tratamentoTesteGenetico.materialGenetico.descricao.trim();
                                            let sumario = new SumarioAnaliseSelecaoTesteGenetico_1.SumarioAnaliseSelecaoTesteGenetico();
                                            sumario.testeGenetico = ctrl.testeGeneticoSelecionado;
                                            sumario.descricao = materialGenetico;
                                            lista.push(sumario);
                                        }
                                    }
                                }
                            }
                        });
                    }
                }
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'descricao',
                    displayName: 'Clone',
                    width: 120
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
            }
            return null;
        });
    }
    static dadosListaCriteriosSelecaoCampoAnaliseSelecaoTesteGenetico(ctrl, seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const funcaoOrigemCarregamento = 'dadosListaCriteriosSelecaoCampoAnaliseSelecaoTesteGenetico';
            try {
                if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(seletorCarregamento ? seletorCarregamento : '.card', funcaoOrigemCarregamento);
                let lista = [];
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'nome',
                    displayName: 'Nome',
                    width: 150
                });
                auxColunas.push({
                    name: 'valor',
                    displayName: 'Valor',
                    width: 100
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
            }
            return null;
        });
    }
    static dadosListaLimitesReferenciaSelecaoCampoAnaliseSelecaoTesteGenetico(ctrl, seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const funcaoOrigemCarregamento = 'dadosListaLimitesReferenciaSelecaoCampoAnaliseSelecaoTesteGenetico';
            try {
                if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(seletorCarregamento ? seletorCarregamento : '.card', funcaoOrigemCarregamento);
                let lista = [];
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'nome',
                    displayName: 'Nome',
                    width: 150
                });
                auxColunas.push({
                    name: 'valor',
                    displayName: 'Valor',
                    width: 100
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
            }
            return null;
        });
    }
    static dadosListaSelecaoCampoAnaliseSelecaoTesteGenetico(ctrl, seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const funcaoOrigemCarregamento = 'dadosListaSelecaoCampoAnaliseSelecaoTesteGenetico';
            try {
                if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(seletorCarregamento ? seletorCarregamento : '.card', funcaoOrigemCarregamento);
                let lista = [];
                if ((typeof lista === 'undefined') || !lista || (lista.length === 0)) {
                    if ((typeof ctrl.listaTratamentosTesteGenetico !== 'undefined') && ctrl.listaTratamentosTesteGenetico) {
                        angular.forEach(ctrl.listaTratamentosTesteGenetico, (tratamentoTesteGenetico) => {
                            if ((typeof tratamentoTesteGenetico.materialGenetico !== 'undefined') && tratamentoTesteGenetico.materialGenetico &&
                                (typeof tratamentoTesteGenetico.materialGenetico.descricao !== 'undefined') && tratamentoTesteGenetico.materialGenetico.descricao &&
                                (tratamentoTesteGenetico.materialGenetico.descricao.trim().length > 0)) {
                                for (let bloco = 1; bloco < 10; bloco++) {
                                    for (let parcela = 1; parcela < 10; parcela++) {
                                        for (let arvore = 1; arvore < 10; arvore++) {
                                            const materialGenetico = tratamentoTesteGenetico.materialGenetico.descricao.trim();
                                            let sumario = new SumarioAnaliseSelecaoTesteGenetico_1.SumarioAnaliseSelecaoTesteGenetico();
                                            sumario.testeGenetico = ctrl.testeGeneticoSelecionado;
                                            sumario.descricao = materialGenetico;
                                            lista.push(sumario);
                                        }
                                    }
                                }
                            }
                        });
                    }
                }
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'descricao',
                    displayName: 'Clone',
                    width: 120
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
            }
            return null;
        });
    }
    static dadosListaCroquiSelecaoCampoAnaliseSelecaoTesteGenetico(ctrl, seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const funcaoOrigemCarregamento = 'dadosListaCroquiSelecaoCampoAnaliseSelecaoTesteGenetico';
            try {
                if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(seletorCarregamento ? seletorCarregamento : '.card', funcaoOrigemCarregamento);
                let lista = [];
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'descricao',
                    displayName: 'Clone',
                    width: 120
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
            }
            return null;
        });
    }
    static dadosListaAnexosTesteGenetico(ctrl, seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const funcaoOrigemCarregamento = 'dadosListaAnexosTesteGenetico';
            try {
                if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(seletorCarregamento ? seletorCarregamento : '.card', funcaoOrigemCarregamento);
                let lista = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANEXO_TESTE_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANEXO_TESTE_GENETICO)
                    .filtro({
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    testeGenetico: ctrl.testeGeneticoSelecionado.id
                })
                    .listar();
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    cellClass: 'text-center',
                    cellTemplate: `
                        <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                            <button type="button" class="btn btn-icon bx bx-dots-vertical-rounded" uib-dropdown-toggle></button>
                            <ul class="dropdown-menu dropdown-menu-left" uib-dropdown-menu role="menu">
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalAnexoTesteGenetico({ id: row.entity.id || row.entity.uuid, visualizar: true })' class="text-secondary w-100"><i class="bx bx-show mr-1 ml-1"></i>Visualizar</a></li>
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalAnexoTesteGenetico({ id: row.entity.id || row.entity.uuid, editar: true })' class="text-secondary w-100"><i class="bx bxs-edit-alt mr-1 ml-1"></i>Editar</a></li>
                                <li><hr></li>
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalAnexoTesteGenetico({ id: row.entity.id || row.entity.uuid, excluir: true })' class="text-secondary w-100"><i class="bx bxs-trash-alt mr-1 ml-1"></i>Excluir</a></li>
                            </ul>
                        </div> `
                });
                auxColunas.push({
                    name: 'descricao',
                    displayName: 'Descrição anexo',
                    width: 300
                });
                auxColunas.push({
                    name: 'criacao',
                    displayName: 'Data/Hora anexo',
                    cellFilter: 'date:"dd/MM/yyyy HH:mm:ss"',
                    width: 160
                });
                auxColunas.push({
                    name: 'usuarioInclusao.nomeCompleto',
                    displayName: 'Anexado por',
                    width: 200
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
            }
            return null;
        });
    }
    static dadosListaProtocolosTesteGenetico(ctrl, seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const funcaoOrigemCarregamento = 'dadosListaProtocolosTesteGenetico';
            try {
                if (!ctrl.testeGeneticoSelecionado || !ctrl.testeGeneticoSelecionado.id) {
                    return null;
                }
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(ctrl);
                gridConstrutor.removerColunas(Object.keys(ctrl.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(seletorCarregamento ? seletorCarregamento : '.card', funcaoOrigemCarregamento);
                let lista = yield ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTOCOLO_TESTE_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTOCOLO_TESTE_GENETICO)
                    .filtro({
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    testeGenetico: ctrl.testeGeneticoSelecionado.id
                })
                    .listar();
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    cellClass: 'text-center',
                    cellTemplate: `
                        <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                            <button type="button" class="btn btn-icon bx bx-dots-vertical-rounded" uib-dropdown-toggle></button>
                            <ul class="dropdown-menu dropdown-menu-left" uib-dropdown-menu role="menu">
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalProtocoloTesteGenetico({ id: row.entity.id || row.entity.uuid, visualizar: true })' class="text-secondary w-100"><i class="bx bx-show mr-1 ml-1"></i>Visualizar</a></li>
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalProtocoloTesteGenetico({ id: row.entity.id || row.entity.uuid, editar: true })' class="text-secondary w-100"><i class="bx bxs-edit-alt mr-1 ml-1"></i>Editar</a></li>
                                <li><hr></li>
                                <li class="dropdown-item" role="menuitem"><a href='' ng-click='grid.appScope.getParentController().exibirModalProtocoloTesteGenetico({ id: row.entity.id || row.entity.uuid, excluir: true })' class="text-secondary w-100"><i class="bx bxs-trash-alt mr-1 ml-1"></i>Excluir</a></li>
                            </ul>
                        </div> `
                });
                auxColunas.push({
                    name: 'descricao',
                    displayName: 'Descrição anexo',
                    width: 300
                });
                auxColunas.push({
                    name: 'criacao',
                    displayName: 'Data/Hora anexo',
                    cellFilter: 'date:"dd/MM/yyyy HH:mm:ss"',
                    width: 160
                });
                auxColunas.push({
                    name: 'usuarioInclusao.nomeCompleto',
                    displayName: 'Anexado por',
                    width: 200
                });
                let posicaoColuna = 0;
                angular.forEach(auxColunas, (coluna) => {
                    posicaoColuna++;
                    coluna.posicao = posicaoColuna;
                    gridConstrutor.adicionarColuna(coluna);
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                return {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: ctrl
                };
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad(funcaoOrigemCarregamento);
            }
            return null;
        });
    }
}
exports.TesteCampoDadosListaUtil = TesteCampoDadosListaUtil;
