"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GrupoPermissoesConstantes = void 0;
class GrupoPermissoesConstantes {
}
exports.GrupoPermissoesConstantes = GrupoPermissoesConstantes;
GrupoPermissoesConstantes.GRUPO_PERMISSOES_CADASTROS = {
    tituloGrupo: 'Cadastros',
    iconeGrupo: 'bx bx-desktop',
    permissaoGrupo: 'grupoCadastros',
    subGruposPermissoes: [
        {
            titulo: 'Material Genético',
            rota: 'cadastro.materialGenetico-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Gênero',
            rota: 'cadastro.genero-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Espécie',
            rota: 'cadastro.especie-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Geração',
            rota: 'cadastro.geracao-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Conjunto',
            rota: 'cadastro.conjunto-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Dialelo',
            rota: 'cadastro.dialelo-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Pomar e Banco',
            rota: 'cadastro.pomar-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Tipo de Pomar',
            rota: 'cadastro.tipoPomar-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Classificação de Solo',
            rota: 'cadastro.classificacaoSolo-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Unidade de Manejo',
            rota: 'cadastro.unidadeManejo-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Região',
            rota: 'cadastro.regiao-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Talhão',
            rota: 'cadastro.talhao-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Mapas dos Talhões <br> / Shapefile (.shp)',
            rota: 'cadastro.importaShapefile-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Fonte',
            rota: 'cadastro.fonte-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Tipo de Manejo',
            rota: 'cadastro.tipoManejo-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Tipo de Desbaste <br> Manejo Pomar',
            rota: 'cadastro.tipoDesbasteManejoPomar-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Tipo de Limpeza <br> Manejo Pomar',
            rota: 'cadastro.tipoLimpezaManejoPomar-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Tipo de Amostra <br> Manejo Pomar',
            rota: 'cadastro.tipoAmostraManejoPomar-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Finalidade de Amostra <br> Manejo Pomar',
            rota: 'cadastro.finalidadeAmostraManejoPomar-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Praga e Doença <br> Manejo Pomar',
            rota: 'cadastro.pragaDoencaManejoPomar-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Funcionário',
            rota: 'cadastro.funcionario-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'País',
            rota: 'cadastro.pais-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Província/UF',
            rota: 'cadastro.provinciaUf-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Município',
            rota: 'cadastro.localidade-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Hibridação',
            rota: 'cadastro.hibridacao-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Polinização',
            rota: 'cadastro.polinizacao-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Tipo de Teste/População',
            rota: 'cadastro.tipoTestePopulacao-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Situação',
            rota: 'cadastro.situacao-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Clone',
            rota: 'cadastro.cloneMaterialGenetico-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Tipo de Implantação',
            rota: 'cadastro.tipoImplantacao-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Procedência',
            rota: 'cadastro.procedencia-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Reprodução',
            rota: 'cadastro.reproducao-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Tipo de Pomar/População',
            rota: 'cadastro.tipoPomarPopulacao-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Tipo de Delineamento',
            rota: 'cadastro.tipoDelineamento-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Disposição dos Indivíduos',
            rota: 'cadastro.disposicaoIndividuos-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Operação de Manejo Pomar',
            rota: 'cadastro.operacaoManejoPomar-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Produto Fertilizante',
            rota: 'cadastro.produtoFertilizante-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Tipo de Aplicação',
            rota: 'cadastro.tipoAplicacao-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Tipo de Intervenção',
            rota: 'cadastro.tipoIntervencao-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Teste Genéticos',
            rota: 'cadastro.testeGenetico-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Ano Enxertia',
            rota: 'cadastro.anoEnxertia-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Estróbilo',
            rota: 'cadastro.estrobilo-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Estágio Floral Feminino',
            rota: 'cadastro.estagioFloralFeminino-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Estagio Floral Masculino',
            rota: 'cadastro.estagioFloralMasculino-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Fase Avaliada',
            rota: 'cadastro.faseAvaliada-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Estágio Floral Eucalipto',
            rota: 'cadastro.estagioFloralEucalipto-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Maturação Fruto',
            rota: 'cadastro.maturacaoFruto-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Cultivar',
            rota: 'cadastro.cultivar-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Categoria Pomar',
            rota: 'cadastro.categoriaPomar-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Finalidade Semeio',
            rota: 'cadastro.finalidadeSemeio-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Unidade de Medida',
            rota: 'cadastro.unidadeMedida-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Classificação de Brotações',
            rota: 'cadastro.classificacaoBrotacao-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Coleta (Macroestacas)',
            rota: 'cadastro.coletaMacroestaca-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Produto Defensivo',
            rota: 'cadastro.produtoDefensivo-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Tratamento Fungicida',
            rota: 'cadastro.tratamentoFungicida-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Status Minijardim',
            rota: 'cadastro.statusMinijardim-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Finalidade Multiplicação',
            rota: 'cadastro.finalidadeMultiplicacao-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Ação Minijardim',
            rota: 'cadastro.acaoMinijardim-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Canaletões',
            rota: 'cadastro.canaletoes-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Minijardim',
            rota: 'cadastro.minijardim-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Tipo de Minijardim',
            rota: 'cadastro.tipoMinijardim-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Caixa D’Agua',
            rota: 'cadastro.caixaDAgua-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Receita Solução',
            rota: 'cadastro.receitaSolucao-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Nível de Contaminação',
            rota: 'cadastro.nivelContaminacao-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Casa de Vegetação',
            rota: 'cadastro.casaVegetacao-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Cargo\\Função',
            rota: 'cadastro.cargoFuncao-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Laboratório',
            rota: 'cadastro.laboratorio-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Área Solicitante',
            rota: 'cadastro.areaSolicitante-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Tipo de Amostra',
            rota: 'cadastro.tipoAmostra-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Tipo de Análise',
            rota: 'cadastro.tipoAnalise-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Status Análise',
            rota: 'cadastro.statusAnalise-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Safra',
            rota: 'cadastro.safra-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Lote',
            rota: 'cadastro.lote-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Propágulo',
            rota: 'cadastro.propagulo-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Características de <br> Descritores Morfológicos',
            rota: 'cadastro.caracteristicaDescritorMorfologico-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Centro de Custo SAP',
            rota: 'cadastro.centroCustoSap-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Conta/Depósito SAP',
            rota: 'cadastro.contaDepositoSap-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }
    ]
};
GrupoPermissoesConstantes.GRUPO_PERMISSOES_POMAR_BANCO_CONSERVACAO = {
    tituloGrupo: 'Pomares e bancos <br>de conservação',
    iconeGrupo: 'bx bx-bar-chart-alt',
    permissaoGrupo: 'grupoPomarBancoConservacao',
    subGruposPermissoes: [
        {
            titulo: 'Pomares e bancos de conservação',
            rota: 'lancamento.pomarBancoConservacao-listar',
            padrao: true,
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }
    ]
};
GrupoPermissoesConstantes.GRUPO_PERMISSOES_PRODUCAO_MUDA = {
    tituloGrupo: 'Produção de mudas',
    iconeGrupo: 'fa fa-leaf',
    permissaoGrupo: 'grupoProducaoMuda',
    subGruposPermissoes: [
        {
            titulo: 'Seminal',
            rota: 'cadastro.producaoMudasSeminal-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Clonal',
            permissaoGrupo: 'grupoProducaoMudaClonal',
            subGruposPermissoes: [
                {
                    titulo: 'Lançamentos',
                    rota: 'cadastro.producaoMudaClonal-listar',
                    permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
                },
                {
                    titulo: 'Eucalyptus',
                    permissaoGrupo: 'grupoProducaoMudaClonalEucalyptus',
                    subGruposPermissoes: [
                        {
                            titulo: 'Produção',
                            rota: 'cadastro.producaoMudaClonalEucalyptus-listar',
                            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
                        },
                        {
                            titulo: 'Resgate macroestacas',
                            rota: 'cadastro.producaoMudaClonalEucalyptusResgateMacroestacas-listar',
                            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
                        }, {
                            titulo: 'Minijardim',
                            rota: 'cadastro.producaoMudaClonalEucalyptusMinijardim-listar',
                            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
                        }, {
                            titulo: 'Miniestaquia',
                            rota: 'cadastro.producaoMudaClonalEucalyptusMiniestaquia-listar',
                            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
                        }
                    ]
                }, {
                    titulo: 'Pinus',
                    permissaoGrupo: 'grupoProducaoMudaClonalPinus',
                    subGruposPermissoes: [
                        {
                            titulo: 'Produção',
                            rota: 'cadastro.producaoMudaClonalPinus-listar',
                            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
                        },
                        {
                            titulo: 'Minijardim',
                            rota: 'cadastro.producaoMudaClonalPinusMinijardim-listar',
                            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
                        }, {
                            titulo: 'Miniestaquia',
                            rota: 'cadastro.producaoMudaClonalPinusMiniestaquia-listar',
                            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
                        }
                    ]
                }
            ]
        }, {
            titulo: 'Controle de pragas <br> e doenças',
            rota: 'cadastro.producaoMudaControlePragaDoenca-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Experimentos',
            rota: 'cadastro.producaoMudasExperimentos-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }
    ]
};
GrupoPermissoesConstantes.GRUPO_PERMISSOES_TESTE_CAMPO = {
    tituloGrupo: 'Testes de campo',
    iconeGrupo: 'bx bx-task',
    permissaoGrupo: 'grupoTesteCampo',
    subGruposPermissoes: [
        {
            titulo: 'Testes de campo',
            rota: 'lancamento.testeCampo-listar',
            padrao: true,
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }
    ]
};
GrupoPermissoesConstantes.GRUPO_PERMISSOES_PROTECAO_REGISTRO_CULTIVAR = {
    tituloGrupo: 'Proteção e registro <br> de cultivares',
    iconeGrupo: 'bx bxs-spreadsheet',
    permissaoGrupo: 'grupoProtecaoRegistroCultivar',
    subGruposPermissoes: [
        {
            titulo: 'Proteção e registro de cultivares',
            rota: 'cadastro.protecaoRegistroCultivar-listar',
            padrao: true,
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }
    ]
};
GrupoPermissoesConstantes.GRUPO_PERMISSOES_LABORATORIO = {
    tituloGrupo: 'Laboratório',
    iconeGrupo: 'bx bx-task',
    permissaoGrupo: 'grupoLaboratorio',
    subGruposPermissoes: [
        {
            titulo: 'Solicitação de análises',
            rota: 'cadastro.labSolicitacaoAnalises-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Maturação de cones',
            rota: 'cadastro.labMaturacaoCones-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Umidade sementes',
            rota: 'cadastro.labUmidadeSementes-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Germinação semente',
            rota: 'cadastro.labGerminacaoSementes-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Umidade Pólen',
            rota: 'cadastro.labUmidadePolen-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Germinação Pólen',
            rota: 'cadastro.labGerminacaoPolen-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Estudo de cones',
            rota: 'cadastro.labEstudoCones-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Experimentos',
            rota: 'cadastro.labExperimentos-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Manuais e procedimentos',
            rota: 'cadastro.labManuaisProcedimentos-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }
    ]
};
GrupoPermissoesConstantes.GRUPO_PERMISSOES_SINCRONIZAR_DADOS = {
    tituloGrupo: 'Sincronizar Dados',
    iconeGrupo: 'bx bxs-cloud-upload',
    permissaoGrupo: 'grupoSincronizacao',
    subGruposPermissoes: [
        {
            titulo: 'Sincronizar Dados',
            rota: 'app.sincronizacao-listar',
            padrao: true,
            permissoesHabilitadas: { acessar: true }
        }
    ]
};
GrupoPermissoesConstantes.GRUPO_PERMISSOES_CONFIGURACOES = {
    tituloGrupo: 'Configurações',
    iconeGrupo: 'bx bx-cog',
    permissaoGrupo: 'grupoConfiguracoes',
    subGruposPermissoes: [
        {
            titulo: 'Usuários',
            rota: 'cadastro.usuario-listar',
            permissoesHabilitadas: { acessar: true, incluir: true, alterar: true, excluir: true }
        }, {
            titulo: 'Parametrizações',
            rota: 'ferramenta.configuracaoGeral-listar',
            permissoesHabilitadas: { acessar: true, alterar: true }
        }
    ]
};
GrupoPermissoesConstantes.TODOS_GRUPOS_PERMISSOES = [
    GrupoPermissoesConstantes.GRUPO_PERMISSOES_CADASTROS,
    GrupoPermissoesConstantes.GRUPO_PERMISSOES_POMAR_BANCO_CONSERVACAO,
    GrupoPermissoesConstantes.GRUPO_PERMISSOES_PRODUCAO_MUDA,
    GrupoPermissoesConstantes.GRUPO_PERMISSOES_TESTE_CAMPO,
    GrupoPermissoesConstantes.GRUPO_PERMISSOES_PROTECAO_REGISTRO_CULTIVAR,
    GrupoPermissoesConstantes.GRUPO_PERMISSOES_LABORATORIO,
    GrupoPermissoesConstantes.GRUPO_PERMISSOES_SINCRONIZAR_DADOS,
    GrupoPermissoesConstantes.GRUPO_PERMISSOES_CONFIGURACOES,
];
