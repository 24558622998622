"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsuarioPermissao = void 0;
class UsuarioPermissao {
    constructor() {
        this.id = null;
        this.acessar = null;
        this.incluir = null;
        this.alterar = null;
        this.excluir = null;
    }
}
exports.UsuarioPermissao = UsuarioPermissao;
