"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfiguracaoGeralController = exports.ParametrosincronizacaoAPI = exports.ItemSincronizacaoAPI = void 0;
const angular = require("angular");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const UiGridConstrutor_1 = require("../../../app/construtores/UiGridConstrutor");
const AbstratoController_1 = require("../../../app/AbstratoController");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const ICRUDOperacao_1 = require("../../../app/interfaces/ICRUDOperacao");
const ConfiguracaoGeral_1 = require("../../../../../modelos/ConfiguracaoGeral");
const config_app_1 = require("../../../../../config/config.app");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const ManipulaErroFactory_1 = require("../../../../fabricas/ManipulaErroFactory");
const TextoUtil_1 = require("../../../../../util/TextoUtil");
class ItemSincronizacaoAPI {
    constructor(nome, titulo, filtro) {
        this.nome = nome;
        this.titulo = titulo;
        if ((typeof filtro !== 'undefined') && (filtro !== null)) {
            this.filtro = filtro;
        }
        else {
            this.filtro = null;
        }
    }
}
exports.ItemSincronizacaoAPI = ItemSincronizacaoAPI;
class ParametrosincronizacaoAPI {
    constructor(url, logStatusEl) {
        this.url = '';
        this.logStatusEl = null;
        this.ajax = null;
        this.sincronizacaoInciada = null;
        this.sincronizacaoConcluida = null;
        this.url = url;
        this.logStatusEl = logStatusEl;
        this.sincronizacaoInciada = false;
        this.sincronizacaoConcluida = false;
    }
}
exports.ParametrosincronizacaoAPI = ParametrosincronizacaoAPI;
class ConfiguracaoGeralController extends AbstratoController_1.AbstratoController {
    constructor($scope, $injector, handleError) {
        super($scope, $injector, new ConfiguracaoGeral_1.ConfiguracaoGeral());
        this.handleError = handleError;
        this.path = '';
        this.caminho = '';
        this.tipoOperacao = null;
        this.modalSincronizarDadosAPI = null;
        this.tituloSincronizacaoAPI = null;
        this.itensSincronizacaoAPI = null;
        this.tipoOperacao = ICRUDOperacao_1.CRUDOperacao;
        this.modalSincronizarDadosAPI = false;
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.path = config_app_1.config.api.caminho;
            this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.PARAMETRIZACAO;
            this.modalSincronizarDadosAPI = false;
            if (!this.modelo) {
                this.modelo = new ConfiguracaoGeral_1.ConfiguracaoGeral();
            }
            this.modelo.criacao = this.modelo.criacao ? new Date(this.modelo.criacao) : new Date();
            this.modelo.envioEmail_PasswordFlag = false;
            if (((typeof this.modelo.envioEmail_Password === 'undefined') || !this.modelo.envioEmail_Password || (this.modelo.envioEmail_Password === '')) &&
                ((typeof this.modelo.envioEmail_PasswordTemp === 'undefined') || !this.modelo.envioEmail_PasswordTemp || (this.modelo.envioEmail_PasswordTemp === ''))) {
                this.modelo.envioEmail_PasswordFlag = true;
            }
            this.modelo.conexaoSGIF_PasswordFlag = false;
            if (((typeof this.modelo.conexaoSGIF_Password === 'undefined') || !this.modelo.conexaoSGIF_Password || (this.modelo.conexaoSGIF_Password === '')) &&
                ((typeof this.modelo.conexaoSGIF_PasswordTemp === 'undefined') || !this.modelo.conexaoSGIF_PasswordTemp || (this.modelo.conexaoSGIF_PasswordTemp === ''))) {
                this.modelo.conexaoSGIF_PasswordFlag = true;
            }
            this.modelo.conexaoArcGIS_ClientSecretFlag = false;
            if (((typeof this.modelo.conexaoArcGIS_ClientSecret === 'undefined') || !this.modelo.conexaoArcGIS_ClientSecret || (this.modelo.conexaoArcGIS_ClientSecret === '')) &&
                ((typeof this.modelo.conexaoArcGIS_ClientSecretTemp === 'undefined') || !this.modelo.conexaoArcGIS_ClientSecretTemp || (this.modelo.conexaoArcGIS_ClientSecretTemp === ''))) {
                this.modelo.conexaoArcGIS_ClientSecretFlag = true;
            }
            this.$scope.$apply();
        });
    }
    salvar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if ((typeof this.modelo.envioEmail_PasswordFlag !== 'undefined') && this.modelo.envioEmail_PasswordFlag &&
                    (typeof this.modelo.envioEmail_PasswordTemp !== 'undefined') && this.modelo.envioEmail_PasswordTemp &&
                    (this.modelo.envioEmail_PasswordTemp.trim().length > 0)) {
                    this.modelo.envioEmail_Password = this.modelo.envioEmail_PasswordTemp.trim();
                }
                if ((typeof this.modelo.conexaoSGIF_PasswordFlag !== 'undefined') && this.modelo.conexaoSGIF_PasswordFlag &&
                    (typeof this.modelo.conexaoSGIF_PasswordTemp !== 'undefined') && this.modelo.conexaoSGIF_PasswordTemp &&
                    (this.modelo.conexaoSGIF_PasswordTemp.trim().length > 0)) {
                    this.modelo.conexaoSGIF_Password = this.modelo.conexaoSGIF_PasswordTemp.trim();
                }
                if ((typeof this.modelo.conexaoArcGIS_ClientSecretFlag !== 'undefined') && this.modelo.conexaoArcGIS_ClientSecretFlag &&
                    (typeof this.modelo.conexaoArcGIS_ClientSecretTemp !== 'undefined') && this.modelo.conexaoArcGIS_ClientSecretTemp &&
                    (this.modelo.conexaoArcGIS_ClientSecretTemp.trim().length > 0)) {
                    this.modelo.conexaoArcGIS_ClientSecret = this.modelo.conexaoArcGIS_ClientSecretTemp.trim();
                }
                if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PARAMETRIZACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PARAMETRIZACAO)
                        .criar(this.modelo);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PARAMETRIZACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PARAMETRIZACAO)
                        .atualizar(this.modelo);
                }
                this.notificacao.sucesso('Salvo com sucesso!');
                if (this.usuario) {
                    this.$state.go('app.inicio');
                }
                else if (!this.usuario) {
                    this.$state.go('login');
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    visualizar(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const parametrizacao = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PARAMETRIZACAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PARAMETRIZACAO)
                    .id(id)
                    .ver();
                parametrizacao.criacao = parametrizacao.criacao ? new Date(parametrizacao.criacao) : new Date();
                parametrizacao.envioEmail_PasswordFlag = false;
                if (((typeof parametrizacao.envioEmail_Password === 'undefined') || !parametrizacao.envioEmail_Password || (parametrizacao.envioEmail_Password === '')) &&
                    ((typeof parametrizacao.envioEmail_PasswordTemp === 'undefined') || !parametrizacao.envioEmail_PasswordTemp || (parametrizacao.envioEmail_PasswordTemp === ''))) {
                    parametrizacao.envioEmail_PasswordFlag = true;
                }
                parametrizacao.conexaoSGIF_PasswordFlag = false;
                if (((typeof parametrizacao.conexaoSGIF_Password === 'undefined') || !parametrizacao.conexaoSGIF_Password || (parametrizacao.conexaoSGIF_Password === '')) &&
                    ((typeof parametrizacao.conexaoSGIF_PasswordTemp === 'undefined') || !parametrizacao.conexaoSGIF_PasswordTemp || (parametrizacao.conexaoSGIF_PasswordTemp === ''))) {
                    parametrizacao.conexaoSGIF_PasswordFlag = true;
                }
                parametrizacao.conexaoArcGIS_ClientSecretFlag = false;
                if (((typeof parametrizacao.conexaoArcGIS_ClientSecret === 'undefined') || !parametrizacao.conexaoArcGIS_ClientSecret || (parametrizacao.conexaoArcGIS_ClientSecret === '')) &&
                    ((typeof parametrizacao.conexaoArcGIS_ClientSecretTemp === 'undefined') || !parametrizacao.conexaoArcGIS_ClientSecretTemp || (parametrizacao.conexaoArcGIS_ClientSecretTemp === ''))) {
                    parametrizacao.conexaoArcGIS_ClientSecretFlag = true;
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return parametrizacao;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    remover(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const parametrizacao = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PARAMETRIZACAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PARAMETRIZACAO)
                    .id(id)
                    .remover();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (parametrizacao) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const parametrizacao = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PARAMETRIZACAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PARAMETRIZACAO)
                    .id(this.modelo.id)
                    .restaurar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (parametrizacao) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosLista(filtro = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                this.path = config_app_1.config.api.caminho;
                this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.PARAMETRIZACAO;
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                var auxColunas = Array();
                auxColunas.push({
                    name: 'nomeGrupoEmpresa',
                    displayName: 'Nome Grupo Empresa',
                    width: 300
                });
                auxColunas.push({
                    name: 'criacao',
                    displayName: 'Data/Hora Cadastro',
                    cellFilter: 'date:"dd/MM/yyyy HH:mm:ss"',
                    width: 200
                });
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
                const lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PARAMETRIZACAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PARAMETRIZACAO)
                    .filtro(filtro)
                    .listar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach(element => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                };
                if ((lista !== null) && (lista.length > 0)) {
                    if (this.operacao === ICRUDOperacao_1.CRUDOperacao.LISTAR) {
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        this.$state.go('ferramenta.configuracaoGeral-editar', { id: lista[0].id });
                    }
                }
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    filtrar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    limparFiltro() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    testarEnvioEmail() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!this.modelo) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
                if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PARAMETRIZACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PARAMETRIZACAO)
                        .criar(this.modelo);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PARAMETRIZACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PARAMETRIZACAO)
                        .atualizar(this.modelo);
                }
                const respostaTesteConexao = yield this.rest.get(RotaAPIConstantes_1.RotaAPIConstantes.TESTAR_ENVIO_EMAIL, null, true, (5 * 60 * 1000));
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if ((respostaTesteConexao.mensagens) && (respostaTesteConexao.mensagens.length > 0)) {
                    respostaTesteConexao.mensagens.forEach((mensagem) => {
                        if ((typeof mensagem.texto !== 'undefined') && mensagem.texto) {
                            this.notificacao.info(mensagem.texto);
                        }
                    });
                }
            }
            catch (ex) {
                this.handleError.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    testarConexaoSGIF() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!this.modelo) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
                if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PARAMETRIZACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PARAMETRIZACAO)
                        .criar(this.modelo);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PARAMETRIZACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PARAMETRIZACAO)
                        .atualizar(this.modelo);
                }
                const respostaTesteConexao = yield this.rest.get(RotaAPIConstantes_1.RotaAPIConstantes.TESTAR_CONEXAO_SGIF, null, true, (5 * 60 * 1000));
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if ((respostaTesteConexao.mensagens) && (respostaTesteConexao.mensagens.length > 0)) {
                    respostaTesteConexao.mensagens.forEach((mensagem) => {
                        if ((typeof mensagem.texto !== 'undefined') && mensagem.texto) {
                            this.notificacao.info(mensagem.texto);
                        }
                    });
                }
            }
            catch (ex) {
                this.handleError.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    testarConexaoArcGIS() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!this.modelo) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
                if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PARAMETRIZACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PARAMETRIZACAO)
                        .criar(this.modelo);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PARAMETRIZACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PARAMETRIZACAO)
                        .atualizar(this.modelo);
                }
                const respostaTesteConexao = yield this.rest.get(RotaAPIConstantes_1.RotaAPIConstantes.TESTAR_CONEXAO_ARCGIS, null, true, (5 * 60 * 1000));
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if ((respostaTesteConexao.mensagens) && (respostaTesteConexao.mensagens.length > 0)) {
                    respostaTesteConexao.mensagens.forEach((mensagem) => {
                        if ((typeof mensagem.texto !== 'undefined') && mensagem.texto) {
                            this.notificacao.info(mensagem.texto);
                        }
                    });
                }
            }
            catch (ex) {
                this.handleError.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    exibirModalSincronizarDadosAPI(nomeTarefa) {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalSincronizarDadosAPI = true;
            angular.element('body').css('overflow-y', 'hidden');
            this.scopeApply();
            setTimeout(this.iniciarSincronizacaoDadosAPI, 500, this, nomeTarefa);
        });
    }
    ocultarModalSincronizarDadosAPI() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalSincronizarDadosAPI = false;
            this.tituloSincronizacaoAPI = null;
            angular.element('body').css('overflow-y', '');
            this.scopeApply();
        });
    }
    iniciarSincronizacaoDadosAPI($ctrl, nomeTarefa) {
        console.log('iniciarSincronizacaoDadosAPI: Begin');
        let urlBaseSincronia = null;
        let strSincronizando = 'Sincronizando';
        let strSincronizado = 'Sincronizado';
        const refNomeTarefa = TextoUtil_1.TextoUtil.converteTextoParaReferencia(((typeof nomeTarefa !== 'undefined') && nomeTarefa) ? nomeTarefa : '');
        if (refNomeTarefa === TextoUtil_1.TextoUtil.converteTextoParaReferencia('sincronizarDadosSGIF')) {
            urlBaseSincronia = config_app_1.config.api.caminho + RotaAPIConstantes_1.RotaAPIConstantes.SINCRONIZAR_DADOS_SGIF + '?q={q}&log=true';
        }
        else if (refNomeTarefa === TextoUtil_1.TextoUtil.converteTextoParaReferencia('sincronizarDadosArcGIS')) {
            urlBaseSincronia = config_app_1.config.api.caminho + RotaAPIConstantes_1.RotaAPIConstantes.SINCRONIZAR_DADOS_ARCGIS + '?q={q}&log=true';
        }
        if (!urlBaseSincronia) {
            this.ocultarModalSincronizarDadosAPI();
            alert('Não foi possível determinar o caminho da sincronização com a API. (urlBaseSincronia está vazia)');
            return;
        }
        let listaParametrossincronizacaoAPI = new Array();
        angular.forEach($ctrl.itensSincronizacaoAPI, (item, key) => {
            let url = urlBaseSincronia.replace(/{q}/gi, item.nome);
            if ((typeof item.filtro !== 'undefined') && (item.filtro !== null)) {
                url = url.replace(/{f}/gi, item.filtro);
            }
            else {
                url = url.replace(/&f={f}/gi, '');
            }
            listaParametrossincronizacaoAPI.push(new ParametrosincronizacaoAPI(url, angular.element('#logStatusSincronia_' + item.nome + '_' + key)));
        });
        const sincronizaAPI = function () {
            if (angular.element('#modalSincronizarDadosAPI').length === 0) {
                return;
            }
            for (let i = 0; i <= listaParametrossincronizacaoAPI.length - 1; i++) {
                if (angular.element('#modalSincronizarDadosAPI').length === 0) {
                    return;
                }
                if ((!listaParametrossincronizacaoAPI[i].sincronizacaoInciada) && (!listaParametrossincronizacaoAPI[i].sincronizacaoConcluida)) {
                    listaParametrossincronizacaoAPI[i].sincronizacaoInciada = true;
                    listaParametrossincronizacaoAPI[i].sincronizacaoConcluida = false;
                    listaParametrossincronizacaoAPI[i].logStatusEl.find('span.float-right').first().text(strSincronizando + '...').css('color', '#26789a');
                    if (listaParametrossincronizacaoAPI[i].logStatusEl.find('p').length === 0) {
                        listaParametrossincronizacaoAPI[i].logStatusEl.append('<p><pre></pre></p>');
                    }
                    if (listaParametrossincronizacaoAPI[i].logStatusEl.find('p').first().find('pre').length === 0) {
                        listaParametrossincronizacaoAPI[i].logStatusEl.find('p').first().append('<pre></pre>');
                    }
                    listaParametrossincronizacaoAPI[i].ajax = new XMLHttpRequest();
                    listaParametrossincronizacaoAPI[i].ajax.onreadystatechange = function () {
                        if (this.readyState >= 4) {
                            listaParametrossincronizacaoAPI[i].sincronizacaoConcluida = true;
                            if (this.responseText.indexOf('> Fim') !== -1) {
                                listaParametrossincronizacaoAPI[i].logStatusEl.find('span.float-right').first().text(strSincronizado + '!').css('color', '#2ea225');
                            }
                            else if ((this.responseText.indexOf('Concluído') !== -1) || (this.responseText.indexOf('Concluido') !== -1)) {
                                listaParametrossincronizacaoAPI[i].logStatusEl.find('span.float-right').first().text('Nenhum registro processado!').css('color', '#3e597e');
                            }
                            else {
                                listaParametrossincronizacaoAPI[i].logStatusEl.find('span.float-right').first().text('Falha! Tente novamente.').css('color', '#b92828');
                            }
                            setTimeout(() => {
                                sincronizaAPI();
                            }, 250);
                        }
                        if (this.readyState >= 3) {
                            if ((this.responseText !== null) && (this.responseText.length > 0)) {
                                let logArr = this.responseText.split('\r\n');
                                for (let i = logArr.length - 1; i >= 0; i--) {
                                    let s1 = logArr[i].toLowerCase();
                                    if ((s1.indexOf('registro processado') !== -1) || (s1.indexOf('registros processados') !== -1)) {
                                        for (let k = i - 1; k >= 0; k--) {
                                            let s2 = logArr[k].toLowerCase();
                                            if ((s2.indexOf('registro processado') !== -1) || (s2.indexOf('registros processados') !== -1)) {
                                                logArr.splice(k, 1);
                                            }
                                        }
                                        break;
                                    }
                                }
                                let logText = '';
                                logArr.forEach((log) => {
                                    if ((log !== null) && (log.trim().length > 0)) {
                                        logText += log + '\r\n';
                                    }
                                });
                                listaParametrossincronizacaoAPI[i].logStatusEl.find('p').first().find('pre').first().html(logText);
                            }
                        }
                    };
                    console.log('URL Sincronização ' + listaParametrossincronizacaoAPI[i].logStatusEl.find('span').first().text() + ' -> ' + listaParametrossincronizacaoAPI[i].url);
                    listaParametrossincronizacaoAPI[i].ajax.open('GET', listaParametrossincronizacaoAPI[i].url, true);
                    listaParametrossincronizacaoAPI[i].ajax.send();
                    break;
                }
            }
        };
        sincronizaAPI();
        console.log('iniciarSincronizacaoDadosAPI: End');
    }
    sincronizarDadosSGIF() {
        return __awaiter(this, void 0, void 0, function* () {
            this.itensSincronizacaoAPI = new Array();
            this.itensSincronizacaoAPI.push(...[
                new ItemSincronizacaoAPI('operacao', 'SGIF - Operações'),
                new ItemSincronizacaoAPI('usuario', 'SGIF - Usuários'),
                new ItemSincronizacaoAPI('empresa', 'SGIF - Empresas'),
                new ItemSincronizacaoAPI('encarregado', 'SGIF - Encarregados'),
                new ItemSincronizacaoAPI('especie', 'SGIF - Espécies'),
                new ItemSincronizacaoAPI('estagio', 'SGIF - Estágios'),
                new ItemSincronizacaoAPI('frenteServico', 'SGIF - Frentes de Serviço'),
                new ItemSincronizacaoAPI('implemento', 'SGIF - Implementos'),
                new ItemSincronizacaoAPI('patrimonio', 'SGIF - Patrimônios'),
                new ItemSincronizacaoAPI('insumo', 'SGIF - Insumos'),
                new ItemSincronizacaoAPI('materialGenetico', 'SGIF - Materiais Genéticos'),
                new ItemSincronizacaoAPI('regiao', 'SGIF - Regiões'),
                new ItemSincronizacaoAPI('talhao', 'SGIF - Talhões'),
                new ItemSincronizacaoAPI('pomar', 'SGIF - Pomares'),
                new ItemSincronizacaoAPI('matriz', 'SGIF - Matrizes'),
                new ItemSincronizacaoAPI('tipoAmostra', 'SGIF - Tipos de Amostras'),
                new ItemSincronizacaoAPI('tipoColeta', 'SGIF - Tipos de Coletas'),
                new ItemSincronizacaoAPI('tipoComposicaoTransferencia', 'SGIF - Tipos de Composições Transferência'),
                new ItemSincronizacaoAPI('tipoEntrega', 'SGIF - Tipos de Entregas'),
                new ItemSincronizacaoAPI('tipoMuda', 'SGIF - Tipos de Mudas'),
                new ItemSincronizacaoAPI('tipoRecepiente', 'SGIF - Tipos de Recipientes'),
                new ItemSincronizacaoAPI('unidadeMedida', 'SGIF - Unidades de Medida'),
                new ItemSincronizacaoAPI('cliente', 'SGIF - Clientes'),
                new ItemSincronizacaoAPI('fornecedor', 'SGIF - Fornecedores')
            ]);
            this.tituloSincronizacaoAPI = 'Status da Sincronização com o SGIFlorestal';
            this.exibirModalSincronizarDadosAPI('sincronizarDadosSGIF');
            this.scopeApply();
        });
    }
    sincronizarDadosArcGIS() {
        return __awaiter(this, void 0, void 0, function* () {
            this.itensSincronizacaoAPI = new Array();
            this.itensSincronizacaoAPI.push(...[
                new ItemSincronizacaoAPI('tokenArcGIS', 'ArcGIS - Token de Autenticação'),
                new ItemSincronizacaoAPI('fazendasArcGIS', 'ArcGIS - Consulta Fazendas'),
                new ItemSincronizacaoAPI('regioesArcGIS', 'ArcGIS - Consulta Regiões'),
                new ItemSincronizacaoAPI('talhoesArcGIS', 'ArcGIS - Consulta Talhões'),
                new ItemSincronizacaoAPI('regiao', 'Importa Regiões'),
                new ItemSincronizacaoAPI('talhao', 'Importa Talhões'),
            ]);
            this.tituloSincronizacaoAPI = 'Status da Sincronização com o ArcGIS';
            this.exibirModalSincronizarDadosAPI('sincronizarDadosArcGIS');
            this.scopeApply();
        });
    }
    conferirAnoFiscal() {
        return __awaiter(this, void 0, void 0, function* () {
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
            try {
                yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PARAMETRIZACAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PARAMETRIZACAO)
                    .filtro({ tarefa: 'conferirAnoFiscal', listaSimplificadaOnline: true })
                    .listar();
                setTimeout(() => {
                    alert('Conferência concluída.');
                }, 500);
            }
            catch (ex) {
                this.handleError.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    conferirFormularioIndicadoresApontamentos() {
        return __awaiter(this, void 0, void 0, function* () {
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
            try {
                let listaDatas = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PARAMETRIZACAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PARAMETRIZACAO)
                    .filtro({ tarefa: 'listarDatasApontamentos', listaSimplificadaOnline: true })
                    .listar();
                console.log(listaDatas);
                this.itensSincronizacaoAPI = new Array();
                this.itensSincronizacaoAPI.push(new ItemSincronizacaoAPI('formularioIndicadores', 'Formulários de Indicadores'));
                angular.forEach(listaDatas, (data) => {
                    console.log(data);
                    let titulo = 'Apontamentos de ' + data.substring(8, 10) + '/' + data.substring(5, 7) + '/' + data.substring(0, 4);
                    this.itensSincronizacaoAPI.push(new ItemSincronizacaoAPI('apontamentos', titulo, data));
                });
                this.tituloSincronizacaoAPI = 'Status da Conferência dos Formulários e Apontamentos';
                this.exibirModalSincronizarDadosAPI('conferirFormularioIndicadoresApontamentos');
                this.scopeApply();
            }
            catch (ex) {
                this.handleError.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
}
exports.ConfiguracaoGeralController = ConfiguracaoGeralController;
ConfiguracaoGeralController.$inject = ['$scope', '$injector', ManipulaErroFactory_1.ManipulaErroFactory.name];
