"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiltroPlanejamentoPomarTratado = exports.FiltroPlanejamentoPomar = void 0;
class FiltroPlanejamentoPomar {
    constructor() {
        this.dataIni = null;
        this.dataFim = null;
        this.operacaoLimpeza = true;
        this.operacaoDesbaste = true;
        this.operacaoPolinizacao = true;
        this.operacaoColetaAmostras = true;
        this.operacaoColetaSementes = true;
        this.operacaoControleDoencas = true;
        this.operacaoAdubacaoFertilizacao = true;
        this.outrasOperacoes = true;
    }
}
exports.FiltroPlanejamentoPomar = FiltroPlanejamentoPomar;
class FiltroPlanejamentoPomarTratado {
    constructor() {
        this.pomar = null;
    }
}
exports.FiltroPlanejamentoPomarTratado = FiltroPlanejamentoPomarTratado;
