"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RestFactory = void 0;
const angular = require("angular");
const pako = require("pako");
const config_app_1 = require("../../config/config.app");
const AutenticacaoUtil_1 = require("../../util/AutenticacaoUtil");
const ManipulaErroFactory_1 = require("./ManipulaErroFactory");
class RestFactory {
    constructor($http, handleError) {
        this.$http = $http;
        this.handleError = handleError;
        this.api = RestFactory.rotaAtualizada();
    }
    existeDominio(dominio) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                localStorage.setItem('dominio', dominio);
                const $config = {
                    timeout: 30000
                };
                let rota = RestFactory.rotaAtualizada();
                rota = rota.replace('v1/', 'existe.php');
                yield this.$http.get(rota, $config);
                return true;
            }
            catch (ex) {
                console.log(ex);
                return false;
            }
        });
    }
    get($url, filtro, exibirErro = true, tempoLimite = 90000) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const usuario = yield AutenticacaoUtil_1.AutenticacaoUtil.usuario();
                if (usuario && usuario.id) {
                    filtro = filtro ? Object.assign(filtro, { u: usuario.id }) : { u: usuario.id };
                }
                this.api = RestFactory.rotaAtualizada();
                this.removeEmpty(filtro);
                const $config = {
                    timeout: tempoLimite,
                    params: {
                        filtro: filtro
                    }
                };
                if (this.api) {
                    $url = this.api + $url;
                }
                const response = yield this.$http.get($url, $config);
                const result = response.data ? response.data : null;
                return result;
            }
            catch (ex) {
                this.handleError.restException(ex, exibirErro);
                return null;
            }
        });
    }
    getFile($url, filtro) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const usuario = yield AutenticacaoUtil_1.AutenticacaoUtil.usuario();
                if (usuario && usuario.id) {
                    filtro = filtro ? Object.assign(filtro, { u: usuario.id }) : { u: usuario.id };
                }
                this.api = RestFactory.rotaAtualizada();
                this.removeEmpty(filtro);
                const $config = {
                    timeout: 90000,
                    params: {
                        filtro: filtro
                    }
                };
                if (this.api) {
                    $url = this.api + $url;
                }
                const response = yield this.$http.get($url, $config);
                const result = response.data;
                return result;
            }
            catch (ex) {
                this.handleError.restException(ex);
                return null;
            }
        });
    }
    file($url, blob, nome = '') {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const formData = new FormData();
                formData.append('ged', blob, nome);
                const usuario = yield AutenticacaoUtil_1.AutenticacaoUtil.usuario();
                if (usuario && usuario.id) {
                    formData.append('u', usuario.id);
                }
                this.api = RestFactory.rotaAtualizada();
                if (this.api) {
                    $url = this.api + $url;
                }
                const result = new Promise((resolve, reject) => {
                    $.ajax({
                        url: $url,
                        type: 'POST',
                        data: formData,
                        success: (data) => resolve(data),
                        cache: false,
                        contentType: false,
                        processData: false,
                        error: (e) => reject(e)
                    });
                });
                return result;
            }
            catch (ex) {
                this.handleError.restException(ex);
                return null;
            }
        });
    }
    delete($url, filtro, exibirErro = true) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const usuario = yield AutenticacaoUtil_1.AutenticacaoUtil.usuario();
                if (usuario && usuario.id) {
                    filtro = filtro ? Object.assign(filtro, { u: usuario.id }) : { u: usuario.id };
                }
                this.api = RestFactory.rotaAtualizada();
                this.removeEmpty(filtro);
                const $config = {
                    timeout: 90000,
                    params: {
                        filtro: filtro
                    }
                };
                if (this.api) {
                    $url = this.api + $url;
                }
                const response = yield this.$http.delete($url, $config);
                return response && response.data ? response.data : null;
            }
            catch (ex) {
                this.handleError.restException(ex, exibirErro);
                if (ex && (typeof ex.status !== 'undefined') && (ex.status !== null) && (ex.status === 500)) {
                    if ((typeof ex.data !== 'undefined') && (ex.data !== null) && (typeof ex.data.dados !== 'undefined') && (typeof ex.data.mensagens !== 'undefined')) {
                        return ex.data;
                    }
                }
                return null;
            }
        });
    }
    post($url, $data, $config, exibirErro = true) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const usuario = yield AutenticacaoUtil_1.AutenticacaoUtil.usuario();
                if (usuario && usuario.id) {
                    $data = $data ? Object.assign($data, { u: usuario.id }) : { u: usuario.id };
                }
                this.api = RestFactory.rotaAtualizada();
                if (this.api) {
                    $url = this.api + $url;
                }
                if ((typeof $config === 'undefined') || !$config) {
                    $config = {};
                }
                $config.timeout = ($url.indexOf('ferramenta/carga') !== -1) ? (30 * 60 * 1000) : (90 * 1000);
                if (($url.indexOf('/apontamento') !== -1) || ($url.indexOf('/historicoApontamento') !== -1)) {
                    const compressData = pako.gzip(angular.toJson($data));
                    const response = new Promise((resolve, reject) => {
                        $.ajax({
                            url: $url,
                            type: 'POST',
                            data: compressData,
                            cache: false,
                            timeout: 90000,
                            contentType: 'text/plain;charset=UTF-8',
                            processData: false,
                            success: (data) => resolve(data),
                            error: (e) => reject(e)
                        });
                    });
                    return yield response;
                }
                else {
                    const response = yield this.$http.post($url, $data, $config);
                    return response.data;
                }
            }
            catch (ex) {
                this.handleError.restException(ex, exibirErro);
                if (ex && (typeof ex.status !== 'undefined') && (ex.status !== null) && (ex.status === 500)) {
                    if ((typeof ex.data !== 'undefined') && (ex.data !== null) && (typeof ex.data.dados !== 'undefined') && (typeof ex.data.mensagens !== 'undefined')) {
                        return ex.data;
                    }
                }
                return null;
            }
        });
    }
    put($url, $data, $config, exibirErro = true) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const usuario = yield AutenticacaoUtil_1.AutenticacaoUtil.usuario();
                if (usuario && usuario.id) {
                    $data = $data ? Object.assign($data, { u: usuario.id }) : { u: usuario.id };
                }
                this.api = RestFactory.rotaAtualizada();
                if (this.api) {
                    $url = this.api + $url;
                }
                if ((typeof $config === 'undefined') || !$config) {
                    $config = {};
                }
                $config.timeout = 90000;
                if (($url.indexOf('/apontamento') !== -1) || ($url.indexOf('/historicoApontamento') !== -1)) {
                    const compressData = pako.gzip(angular.toJson($data));
                    const response = new Promise((resolve, reject) => {
                        $.ajax({
                            url: $url,
                            type: 'PUT',
                            data: compressData,
                            cache: false,
                            timeout: 90000,
                            contentType: 'text/plain;charset=UTF-8',
                            processData: false,
                            success: (data) => resolve(data),
                            error: (e) => reject(e)
                        });
                    });
                    return yield response;
                }
                else {
                    const response = yield this.$http.put($url, $data, $config);
                    return response.data;
                }
            }
            catch (ex) {
                this.handleError.restException(ex, exibirErro);
                if (ex && (typeof ex.status !== 'undefined') && (ex.status !== null) && (ex.status === 500)) {
                    if ((typeof ex.data !== 'undefined') && (ex.data !== null) && (typeof ex.data.dados !== 'undefined') && (typeof ex.data.mensagens !== 'undefined')) {
                        return ex.data;
                    }
                }
                return null;
            }
        });
    }
    static rotaAtualizada() {
        return config_app_1.config.api.caminho;
    }
    static appConfig() {
        const caminho = RestFactory.rotaAtualizada();
        const link = caminho + 'ferramenta/ged/imagem/app';
        return {
            banner: link + '?banner=true&d=' + new Date().getTime(),
            bannerBg: link + '?bannerBg=true&d=' + new Date().getTime(),
            topo: link + '?topo=true&d=' + new Date().getTime(),
        };
    }
    static caminhoImagem(ged, imagemDefault = null) {
        const dominio = RestFactory.rotaAtualizada();
        let url = imagemDefault;
        if (ged && ged.valor) {
            if (ged.valor.search(';base64,') >= 0) {
                url = ged.valor;
            }
            else {
                url = `${dominio}ferramenta/ged/imagem/${ged.id}`;
            }
        }
        return url;
    }
    static rota(url) {
        return config_app_1.config.api.caminho + url;
    }
    removeEmpty(obj) {
        if (!obj) {
            return;
        }
        Object.keys(obj).forEach(key => {
            if ((typeof obj[key] === 'undefined') || (obj[key] === null) || (obj[key] === 'undefined') || (obj[key].length === 0)) {
                delete obj[key];
            }
            else if (obj[key] && (typeof obj[key] === 'object') && !Array.isArray(obj[key])) {
                this.removeEmpty(obj[key]);
            }
        });
    }
}
exports.RestFactory = RestFactory;
RestFactory.id = RestFactory.name;
RestFactory.$inject = ['$http', ManipulaErroFactory_1.ManipulaErroFactory.name];
