"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProducaoMudaClonalEucalyptusMiniestaquiaController = void 0;
const angular = require("angular");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const UiGridConstrutor_1 = require("../../../app/construtores/UiGridConstrutor");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const AbstratoController_1 = require("../../../app/AbstratoController");
const ICRUDOperacao_1 = require("../../../app/interfaces/ICRUDOperacao");
const config_app_1 = require("../../../../../config/config.app");
const sweetalert2_1 = require("sweetalert2");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const ProducaoMudaClonalEucalyptusMiniestaquia_1 = require("../../../../../modelos/ProducaoMudaClonalEucalyptusMiniestaquia");
const ProducaoMudaClonalMiniestaquiaAvaliacaoEnraizamento_1 = require("../../../../../modelos/ProducaoMudaClonalMiniestaquiaAvaliacaoEnraizamento");
const ProducaoMudaClonalMiniestaquiaAvaliacaoSobrevivenciaCasaSombra_1 = require("../../../../../modelos/ProducaoMudaClonalMiniestaquiaAvaliacaoSobrevivenciaCasaSombra");
const ProducaoMudaClonalMiniestaquiaAvaliacaoSobrevivenciaAclimatacao_1 = require("../../../../../modelos/ProducaoMudaClonalMiniestaquiaAvaliacaoSobrevivenciaAclimatacao");
const DispositivoUtil_1 = require("../../../../../util/DispositivoUtil");
const chart_js_1 = require("chart.js");
class ProducaoMudaClonalEucalyptusMiniestaquiaController extends AbstratoController_1.AbstratoController {
    constructor($scope, $injector) {
        super($scope, $injector, new ProducaoMudaClonalEucalyptusMiniestaquia_1.ProducaoMudaClonalEucalyptusMiniestaquia());
        this.path = '';
        this.caminho = '';
        this.tipoOperacao = null;
        this.producaoSelecionado = null;
        this.producaoCarregado = false;
        this.producaoFiltro = null;
        this.analisesFiltradas = false;
        this.dadosAvaliacaoEnraizamento = null;
        this.dadosAvaliacaoSobrevivenciaCasaSombra = null;
        this.dadosAvaliacaoSobrevivenciaAclimatacao = null;
        this.posicaoTopoAntesModal = null;
        this.modalAvaliacaoEnraizamento = false;
        this.modalAvaliacaoSobrevivenciaCasaSombra = false;
        this.modalAvaliacaoSobrevivenciaAclimatacao = false;
        this.listaOpcoesAvaliacaoEnraizamento = null;
        this.listaOpcoesAvaliacaoSobrevivenciaCasaSombra = null;
        this.listaOpcoesAvaliacaoSobrevivenciaAclimatacao = null;
        this.listaOpcoesRelatoriosRecomendacao = null;
        this.listaOpcoesDossieClone = null;
        this.listaEnraizamento = [];
        this.listaProducao = [];
        this.materiaisGeneticos = [];
        this.producoesMiniestacas = null;
        this.producoesMiniestacasClones = null;
        this.producaoClones = [];
        this.graficoRelatorioDossie = null;
        this.graficoRelatorioRecomendacao = null;
        this.filtroDossieClone = {
            clone: null,
            listaMiniestaquia: [],
            producaoClone: null
        };
        this.showDossieClone = false;
        this.totalClonesMinijardim = 0;
        this.totalClonesMultiplicacao = 0;
        this.totalNumMiniestacasCepa = 0;
        this.totalNumMudas = 0;
        this.medEnraizamento = 0;
        this.tipoOperacao = ICRUDOperacao_1.CRUDOperacao;
        this.analisesFiltradas = false;
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.analisesFiltradas = false;
            this.modalAvaliacaoEnraizamento = false;
            this.dadosAvaliacaoEnraizamento = null;
            this.modalAvaliacaoSobrevivenciaCasaSombra = false;
            this.dadosAvaliacaoSobrevivenciaCasaSombra = null;
            this.modalAvaliacaoSobrevivenciaAclimatacao = false;
            this.dadosAvaliacaoSobrevivenciaAclimatacao = null;
            this.path = config_app_1.config.api.caminho;
            this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA;
            this.listaEnraizamento = [];
            this.listaProducao = [];
            this.filtroDossieClone = { clone: null, listaMiniestaquia: [], producaoClone: null };
            this.showDossieClone = false;
            this.totalClonesMinijardim = 0;
            this.totalClonesMultiplicacao = 0;
            this.totalNumMiniestacasCepa = 0;
            this.totalNumMudas = 0;
            this.medEnraizamento = 0;
            if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR ||
                this.operacao === ICRUDOperacao_1.CRUDOperacao.EDITAR ||
                this.operacao === ICRUDOperacao_1.CRUDOperacao.DUPLICAR) {
                this.materiaisGeneticos = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MATERIAL_GENETICO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.materiaisGeneticos = resposta;
                    }
                });
            }
            if (this.operacao === ICRUDOperacao_1.CRUDOperacao.LISTAR) {
                this.producaoClones = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.producaoClones = resposta;
                    }
                });
            }
            this.$scope.$apply();
        });
    }
    salvar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.modalAvaliacaoEnraizamento = false;
                this.dadosAvaliacaoEnraizamento = null;
                this.modalAvaliacaoSobrevivenciaCasaSombra = false;
                this.dadosAvaliacaoSobrevivenciaCasaSombra = null;
                this.modalAvaliacaoSobrevivenciaAclimatacao = false;
                this.dadosAvaliacaoSobrevivenciaAclimatacao = null;
                if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA)
                        .criar(this.modelo);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA)
                        .atualizar(this.modelo);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir uma nova Miniestaquia ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir uma nova Miniestaquia ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    visualizar(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const producaoMudaClonalEucalyptusMiniestaquia = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA)
                    .id(id)
                    .ver();
                producaoMudaClonalEucalyptusMiniestaquia.criacao = new Date(producaoMudaClonalEucalyptusMiniestaquia.criacao);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return producaoMudaClonalEucalyptusMiniestaquia;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    remover(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const producaoMudaClonalEucalyptusMiniestaquia = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA)
                    .id(id)
                    .remover();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (producaoMudaClonalEucalyptusMiniestaquia) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const producaoMudaClonalEucalyptusMiniestaquia = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA)
                    .id(this.modelo.id)
                    .restaurar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (producaoMudaClonalEucalyptusMiniestaquia) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosLista(filtro = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.listaProducao = [];
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                this.path = config_app_1.config.api.caminho;
                this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA;
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                var auxColunas = Array();
                auxColunas.push({
                    name: 'codigo',
                    displayName: 'Código',
                    width: 90,
                });
                auxColunas.push({
                    name: 'data',
                    displayName: 'Data',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 120,
                });
                auxColunas.push({
                    name: 'clone.descricao',
                    displayName: 'Clone',
                    width: 190,
                });
                auxColunas.push({
                    name: 'codigoMiniestaquia',
                    displayName: 'Código Miniestaquia',
                    width: 100,
                });
                auxColunas.push({
                    name: 'numEstacas',
                    displayName: 'Nº de Estacas',
                    width: 140,
                });
                auxColunas.push({
                    name: 'numMiniestacasCepa',
                    displayName: 'Nº Miniestacas/Cepas',
                    width: 140,
                });
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
                const lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA)
                    .filtro(filtro)
                    .listar();
                ctrl.producoesMiniestacas = lista;
                this.listaProducao = lista;
                ctrl.producoesMiniestacasClones = [];
                angular.forEach(lista, (producaoItem) => {
                    if ((typeof producaoItem.clone !== 'undefined') && producaoItem.clone) {
                        if (!ctrl.producoesMiniestacasClones.find(o => o.clone.id === producaoItem.clone.id)) {
                            ctrl.producoesMiniestacasClones.push(producaoItem);
                        }
                    }
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosListaAvaliacaoEnraizamento() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaEnraizamento = [];
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let filtro = {
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    limiteRegistros: 100000
                };
                let lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_ENRAIZAMENTO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_ENRAIZAMENTO)
                    .filtro(filtro)
                    .listar();
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    posicao: 1,
                    cellClass: 'text-center',
                    cellTemplate: `
                    <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                        <button type="button" class="btn btn-icon bx bxs-edit" style="padding-right: .4rem"
                            onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                            ng-click="grid.appScope.getParentController().abrirModalAvaliacaoEnraizamento(row.entity.id)">
                        </button>
                        <button type="button" class="btn btn-icon bx bx-trash" style="padding-left: .4rem"
                            onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                            ng-click="grid.appScope.getParentController().excluirAvaliacaoEnraizamento(row.entity.id)">
                        </button>
                    </div> `
                });
                auxColunas.push({
                    name: 'data',
                    displayName: 'Data',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 120,
                });
                auxColunas.push({
                    name: 'clone.codigoMiniestaquia',
                    displayName: 'Clone',
                    width: 140,
                });
                auxColunas.push({
                    name: 'numEstacas',
                    displayName: 'Nº Estacas Enraizadas',
                    width: 120,
                });
                auxColunas.push({
                    name: 'porcentagemEnraizamento',
                    displayName: 'Enraizamento %',
                    width: 120,
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                this.listaEnraizamento = lista;
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosListaAvaliacaoSobrevivenciaCasaSombra() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let filtro = {
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    limiteRegistros: 100000
                };
                let lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_CASA_SOMBRA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_CASA_SOMBRA)
                    .filtro(filtro)
                    .listar();
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    posicao: 1,
                    cellClass: 'text-center',
                    cellTemplate: `
                    <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                        <button type="button" class="btn btn-icon bx bxs-edit" style="padding-right: .4rem"
                            onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                            ng-click="grid.appScope.getParentController().abrirModalAvaliacaoSobrevivenciaCasaSombra(row.entity.id)">
                        </button>
                        <button type="button" class="btn btn-icon bx bx-trash" style="padding-left: .4rem"
                            onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                            ng-click="grid.appScope.getParentController().excluirAvaliacaoSobrevivenciaCasaSombra(row.entity.id)">
                        </button>
                    </div> `
                });
                auxColunas.push({
                    name: 'data',
                    displayName: 'Data',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 120,
                });
                auxColunas.push({
                    name: 'clone.codigoMiniestaquia',
                    displayName: 'Clone',
                    width: 140,
                });
                auxColunas.push({
                    name: 'numRestanteMudas',
                    displayName: 'Nº Restante Mudas',
                    width: 120,
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosListaAvaliacaoSobrevivenciaAclimatacao() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let filtro = {
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    limiteRegistros: 100000
                };
                let lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_ACLIMATACAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_ACLIMATACAO)
                    .filtro(filtro)
                    .listar();
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    posicao: 1,
                    cellClass: 'text-center',
                    cellTemplate: `
                    <div class="btn-group btn-ui-grid" uib-dropdown dropdown-append-to-body>
                        <button type="button" class="btn btn-icon bx bxs-edit" style="padding-right: .4rem"
                            onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                            ng-click="grid.appScope.getParentController().abrirModalAvaliacaoSobrevivenciaAclimatacao(row.entity.id)">
                        </button>
                        <button type="button" class="btn btn-icon bx bx-trash" style="padding-left: .4rem"
                            onmouseenter="this.style.color='rgb(20,100,255)';" onmouseleave="this.style.color='#727E8C';"
                            ng-click="grid.appScope.getParentController().excluirAvaliacaoSobrevivenciaAclimatacao(row.entity.id)">
                        </button>
                    </div> `
                });
                auxColunas.push({
                    name: 'data',
                    displayName: 'Data',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 120,
                });
                auxColunas.push({
                    name: 'clone.codigoMiniestaquia',
                    displayName: 'Clone',
                    width: 140,
                });
                auxColunas.push({
                    name: 'numRestanteMudas',
                    displayName: 'Nº Restante Mudas',
                    width: 120,
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    filtrar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    limparFiltro() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    salvarAvaliacaoEnraizamento() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                this.modalAvaliacaoSobrevivenciaCasaSombra = false;
                this.modalAvaliacaoSobrevivenciaAclimatacao = false;
                if (typeof this.dadosAvaliacaoEnraizamento.id === 'undefined' || !this.dadosAvaliacaoEnraizamento.id) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_ENRAIZAMENTO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_ENRAIZAMENTO)
                        .criar(this.dadosAvaliacaoEnraizamento);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_ENRAIZAMENTO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_ENRAIZAMENTO)
                        .atualizar(this.dadosAvaliacaoEnraizamento);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir uma nova Avaliação de Enraizamento ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir uma nova Avaliação de Enraizamento ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.ocultarModalAvaliacaoEnraizamento();
                this.filtrarAnalises(true);
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
                this.ocultarModalAvaliacaoEnraizamento();
            }
            finally {
                this.ocultarModalAvaliacaoEnraizamento();
            }
        });
    }
    abrirModalAvaliacaoEnraizamento(id = null) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (typeof id !== 'undefined' && id) {
                    this.dadosAvaliacaoEnraizamento = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_ENRAIZAMENTO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_ENRAIZAMENTO)
                        .id(id)
                        .ver();
                }
                this.modalAvaliacaoEnraizamento = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalAvaliacaoEnraizamento() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalAvaliacaoEnraizamento = false;
            this.dadosAvaliacaoEnraizamento = new ProducaoMudaClonalMiniestaquiaAvaliacaoEnraizamento_1.ProducaoMudaClonalMiniestaquiaAvaliacaoEnraizamento();
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    excluirAvaliacaoEnraizamento(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            this.notificacao
                .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                .then((result) => __awaiter(this, void 0, void 0, function* () {
                if (result.value) {
                    CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_ENRAIZAMENTO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_ENRAIZAMENTO)
                        .id(id)
                        .remover();
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.listaOpcoesAvaliacaoEnraizamento = yield ctrl.dadosListaAvaliacaoEnraizamento();
                    ctrl.scopeApply();
                }
            }));
        });
    }
    salvarAvaliacaoSobrevivenciaCasaSombra() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                this.modalAvaliacaoEnraizamento = false;
                this.modalAvaliacaoSobrevivenciaAclimatacao = false;
                if (typeof this.dadosAvaliacaoSobrevivenciaCasaSombra.id === 'undefined' || !this.dadosAvaliacaoSobrevivenciaCasaSombra.id) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_CASA_SOMBRA)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_CASA_SOMBRA)
                        .criar(this.dadosAvaliacaoSobrevivenciaCasaSombra);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_CASA_SOMBRA)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_CASA_SOMBRA)
                        .atualizar(this.dadosAvaliacaoSobrevivenciaCasaSombra);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir uma nova Avaliação de Sobreviência de Casa Sombra ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir uma nova Avaliação de Sobreviência de Casa Sombra ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.ocultarModalAvaliacaoSobrevivenciaCasaSombra();
                this.filtrarAnalises(true);
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
                this.ocultarModalAvaliacaoSobrevivenciaCasaSombra();
            }
            finally {
                this.ocultarModalAvaliacaoSobrevivenciaCasaSombra();
            }
        });
    }
    abrirModalAvaliacaoSobrevivenciaCasaSombra(id = null) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (typeof id !== 'undefined' && id) {
                    this.dadosAvaliacaoSobrevivenciaCasaSombra = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_CASA_SOMBRA)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_CASA_SOMBRA)
                        .id(id)
                        .ver();
                }
                this.modalAvaliacaoSobrevivenciaCasaSombra = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalAvaliacaoSobrevivenciaCasaSombra() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalAvaliacaoSobrevivenciaCasaSombra = false;
            this.dadosAvaliacaoSobrevivenciaCasaSombra = new ProducaoMudaClonalMiniestaquiaAvaliacaoSobrevivenciaCasaSombra_1.ProducaoMudaClonalMiniestaquiaAvaliacaoSobrevivenciaCasaSombra();
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    excluirAvaliacaoSobrevivenciaCasaSombra(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            this.notificacao
                .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                .then((result) => __awaiter(this, void 0, void 0, function* () {
                if (result.value) {
                    CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_CASA_SOMBRA)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_CASA_SOMBRA)
                        .id(id)
                        .remover();
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.listaOpcoesAvaliacaoSobrevivenciaCasaSombra = yield ctrl.dadosListaAvaliacaoSobrevivenciaCasaSombra();
                    ctrl.scopeApply();
                }
            }));
        });
    }
    salvarAvaliacaoSobrevivenciaAclimatacao() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                this.modalAvaliacaoEnraizamento = false;
                this.modalAvaliacaoSobrevivenciaCasaSombra = false;
                if (typeof this.dadosAvaliacaoSobrevivenciaAclimatacao.id === 'undefined' || !this.dadosAvaliacaoSobrevivenciaAclimatacao.id) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_ACLIMATACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_ACLIMATACAO)
                        .criar(this.dadosAvaliacaoSobrevivenciaAclimatacao);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_ACLIMATACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_ACLIMATACAO)
                        .atualizar(this.dadosAvaliacaoSobrevivenciaAclimatacao);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir uma nova Avaiação de Sobrevivência de Aclimatação ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir uma nova Avaiação de Sobrevivência de Aclimatação ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.ocultarModalAvaliacaoSobrevivenciaAclimatacao();
                this.filtrarAnalises(true);
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
                this.ocultarModalAvaliacaoSobrevivenciaAclimatacao();
            }
            finally {
                this.ocultarModalAvaliacaoSobrevivenciaAclimatacao();
            }
        });
    }
    abrirModalAvaliacaoSobrevivenciaAclimatacao(id = null) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (typeof id !== 'undefined' && id) {
                    this.dadosAvaliacaoSobrevivenciaAclimatacao = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_ACLIMATACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_ACLIMATACAO)
                        .id(id)
                        .ver();
                }
                this.modalAvaliacaoSobrevivenciaAclimatacao = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalAvaliacaoSobrevivenciaAclimatacao() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalAvaliacaoSobrevivenciaAclimatacao = false;
            this.dadosAvaliacaoSobrevivenciaAclimatacao = new ProducaoMudaClonalMiniestaquiaAvaliacaoSobrevivenciaAclimatacao_1.ProducaoMudaClonalMiniestaquiaAvaliacaoSobrevivenciaAclimatacao();
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    excluirAvaliacaoSobrevivenciaAclimatacao(id) {
        return __awaiter(this, void 0, void 0, function* () {
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            const ctrl = this;
            this.notificacao
                .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                .then((result) => __awaiter(this, void 0, void 0, function* () {
                if (result.value) {
                    CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_ACLIMATACAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_ACLIMATACAO)
                        .id(id)
                        .remover();
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.listaOpcoesAvaliacaoSobrevivenciaAclimatacao = yield ctrl.dadosListaAvaliacaoSobrevivenciaAclimatacao();
                    ctrl.scopeApply();
                }
            }));
        });
    }
    filtrarCloneRelatorio() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            if ((typeof this.producaoFiltro !== 'undefined') && this.producaoFiltro) {
                let listaProducao = this.listaProducao;
                let listaEnraizamento = this.listaEnraizamento;
                let listaDataRelatorio = [];
                let listaDataProducaoRelatorio = [];
                let listaDataEnraizamentoRelatorio = [];
                let listaLabelsRelatorio = [];
                listaProducao.sort((a, b) => (a.data > b.data) ? 1 : ((b.data > a.data) ? -1 : 0));
                listaEnraizamento.sort((a, b) => (a.data > b.data) ? 1 : ((b.data > a.data) ? -1 : 0));
                const listaProducaoRelatorio = listaProducao.filter(producao => {
                    return ((typeof producao.clone.id !== 'undefined') && producao.clone.id &&
                        (typeof ctrl.producaoFiltro.clone !== 'undefined') && ctrl.producaoFiltro.clone &&
                        (producao.clone.id === ctrl.producaoFiltro.clone.id));
                });
                const listaEnraizamentoRelatorio = listaEnraizamento.filter(enraizamento => {
                    return ((typeof enraizamento.clone.clone !== 'undefined') && enraizamento.clone.clone &&
                        (typeof ctrl.producaoFiltro.clone !== 'undefined') && ctrl.producaoFiltro.clone &&
                        (String(enraizamento.clone.clone) === ctrl.producaoFiltro.clone.id));
                });
                angular.forEach(listaProducaoRelatorio, (producao) => {
                    var _a, _b;
                    producao.dataStr = producao.data;
                    let itemDataRelatorio = listaDataRelatorio.find(o => o.data === producao.data);
                    if ((typeof itemDataRelatorio === 'undefined') && !itemDataRelatorio) {
                        listaDataRelatorio.push({
                            data: producao.data,
                            enraizamento: 0,
                            producao: (_a = producao.numMiniestacasCepa) !== null && _a !== void 0 ? _a : 0,
                            totalProducao: 1,
                            totalEnraizamento: 0
                        });
                    }
                    else {
                        itemDataRelatorio.enraizamento += 0;
                        itemDataRelatorio.producao += (_b = producao.numMiniestacasCepa) !== null && _b !== void 0 ? _b : 0;
                        itemDataRelatorio.totalProducao += 1;
                        itemDataRelatorio.totalEnraizamento += 0;
                    }
                });
                angular.forEach(listaEnraizamentoRelatorio, (enraizamento) => {
                    var _a;
                    enraizamento.dataStr = enraizamento.data;
                    let itemDataRelatorio = listaDataRelatorio.find(o => o.data === enraizamento.data);
                    if ((typeof itemDataRelatorio === 'undefined') || !itemDataRelatorio) {
                        listaDataRelatorio.push({
                            data: enraizamento.data,
                            enraizamento: (_a = enraizamento.porcentagemEnraizamento) !== null && _a !== void 0 ? _a : 0,
                            producao: 0,
                            totalProducao: 0,
                            totalEnraizamento: 1
                        });
                    }
                    else {
                        itemDataRelatorio.enraizamento += enraizamento.porcentagemEnraizamento;
                        itemDataRelatorio.producao += 0;
                        itemDataRelatorio.totalEnraizamento += 1;
                        itemDataRelatorio.totalProducao += 0;
                    }
                });
                angular.forEach(listaDataRelatorio, (dataRelatorio) => {
                    if ((typeof dataRelatorio.data !== 'undefined') && dataRelatorio.data) {
                        if (dataRelatorio.totalProducao > 0) {
                            listaDataProducaoRelatorio.push(Number((dataRelatorio.producao / dataRelatorio.totalProducao).toFixed(2)));
                        }
                        else {
                            listaDataProducaoRelatorio.push(Number((dataRelatorio.producao).toFixed(2)));
                        }
                        if (dataRelatorio.totalEnraizamento > 0) {
                            listaDataEnraizamentoRelatorio.push(Number((dataRelatorio.enraizamento / dataRelatorio.totalEnraizamento).toFixed(2)));
                        }
                        else {
                            listaDataEnraizamentoRelatorio.push(Number((dataRelatorio.enraizamento).toFixed(2)));
                        }
                        listaLabelsRelatorio.push(new Date(dataRelatorio.data).toLocaleDateString('pt-BR'));
                    }
                });
                yield this.dadosRelatorioRecomendacao(listaDataProducaoRelatorio, listaDataEnraizamentoRelatorio, listaLabelsRelatorio);
            }
            this.scopeApply();
        });
    }
    dadosRelatorioRecomendacao(listaDataProducaoRelatorio, listaDataEnraizamentoRelatorio, listaLabelsRelatorio) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.graficoRelatorioRecomendacao !== 'undefined') && this.graficoRelatorioRecomendacao) {
                this.graficoRelatorioRecomendacao.destroy();
                this.graficoRelatorioRecomendacao = null;
            }
            for (let cargaGrafico = 1; cargaGrafico <= 10; cargaGrafico++) {
                setTimeout((ctrl) => {
                    if (ctrl.graficoRelatorioRecomendacao) {
                        return;
                    }
                    const graficoRelatorioRecomendacaoEl = document.getElementById('graficoRelatorioRecomendacao');
                    if ((typeof graficoRelatorioRecomendacaoEl === 'undefined') || !graficoRelatorioRecomendacaoEl) {
                        return;
                    }
                    const graficoRelatorioRecomendacaoCtx = graficoRelatorioRecomendacaoEl.getContext('2d');
                    const graficoRelatorioRecomendacaoDataSets = [{
                            type: 'bar',
                            label: 'Porcentagem de Enraizamento',
                            data: listaDataEnraizamentoRelatorio,
                            backgroundColor: 'rgba(54, 162, 235, 0.2)',
                            borderColor: 'rgb(54, 162, 235)',
                            borderWidth: 1,
                            yAxisID: 'A',
                        }, {
                            type: 'line',
                            label: 'Nº Miniestacas por Minicepa',
                            data: listaDataProducaoRelatorio,
                            backgroundColor: 'rgb(255, 159, 64)',
                            borderColor: 'rgb(255, 159, 64)',
                            borderWidth: 1,
                            fill: false,
                            yAxisID: 'B',
                        }];
                    ctrl.graficoRelatorioRecomendacao = new chart_js_1.Chart(graficoRelatorioRecomendacaoCtx, {
                        type: 'bar',
                        data: {
                            labels: listaLabelsRelatorio,
                            datasets: graficoRelatorioRecomendacaoDataSets
                        },
                        plugins: [globalThis.ChartDataLabels],
                        options: {
                            responsive: true,
                            legend: {
                                display: false
                            },
                            layout: {
                                padding: {
                                    right: 30
                                }
                            },
                            scales: {
                                gridLines: { offsetGridLines: true },
                                xAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    }],
                                yAxes: [{
                                        id: 'A',
                                        type: 'linear',
                                        position: 'left',
                                        ticks: {
                                            min: 0
                                        }
                                    }, {
                                        id: 'B',
                                        type: 'linear',
                                        position: 'right',
                                        ticks: {
                                            min: 0
                                        }
                                    }]
                            }
                        }
                    });
                }, cargaGrafico * 1000, this);
            }
            this.scopeApply();
        });
    }
    filtrarRelatorioDossie() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            if ((typeof this.filtroDossieClone.clone !== 'undefined') && this.filtroDossieClone.clone) {
                let listaProducao = this.listaProducao;
                let listaEnraizamento = this.listaEnraizamento;
                let listaProducaoClone = this.producaoClones;
                let listaDataRelatorio = [];
                let listaDataProducaoRelatorio = [];
                let listaDataEnraizamentoRelatorio = [];
                let listaLabelsRelatorio = [];
                listaProducao.sort((a, b) => (a.data > b.data) ? 1 : ((b.data > a.data) ? -1 : 0));
                listaEnraizamento.sort((a, b) => (a.data > b.data) ? 1 : ((b.data > a.data) ? -1 : 0));
                const listaProducaoRelatorio = listaProducao.filter(producao => {
                    return ((typeof producao.clone.id !== 'undefined') && producao.clone.id &&
                        (typeof ctrl.filtroDossieClone.clone.clone !== 'undefined') && ctrl.filtroDossieClone.clone.clone &&
                        (producao.clone.id === ctrl.filtroDossieClone.clone.clone.id));
                });
                const listaEnraizamentoRelatorio = listaEnraizamento.filter(enraizamento => {
                    return ((typeof enraizamento.clone.clone !== 'undefined') && enraizamento.clone.clone &&
                        (typeof ctrl.filtroDossieClone.clone.clone !== 'undefined') && ctrl.filtroDossieClone.clone.clone &&
                        (String(enraizamento.clone.clone) === ctrl.filtroDossieClone.clone.clone.id));
                });
                angular.forEach(listaProducaoRelatorio, (producao) => {
                    var _a, _b;
                    producao.dataStr = producao.data;
                    let itemDataRelatorio = listaDataRelatorio.find(o => o.data === producao.data);
                    if ((typeof itemDataRelatorio === 'undefined') && !itemDataRelatorio) {
                        listaDataRelatorio.push({
                            data: producao.data,
                            enraizamento: 0,
                            producao: (_a = producao.numMiniestacasCepa) !== null && _a !== void 0 ? _a : 0,
                            totalProducao: 1,
                            totalEnraizamento: 0
                        });
                    }
                    else {
                        itemDataRelatorio.enraizamento += 0;
                        itemDataRelatorio.producao += (_b = producao.numMiniestacasCepa) !== null && _b !== void 0 ? _b : 0;
                        itemDataRelatorio.totalProducao += 1;
                        itemDataRelatorio.totalEnraizamento += 0;
                    }
                });
                angular.forEach(listaEnraizamentoRelatorio, (enraizamento) => {
                    var _a;
                    enraizamento.dataStr = enraizamento.data;
                    let itemDataRelatorio = listaDataRelatorio.find(o => o.data === enraizamento.data);
                    if ((typeof itemDataRelatorio === 'undefined') || !itemDataRelatorio) {
                        listaDataRelatorio.push({
                            data: enraizamento.data,
                            enraizamento: (_a = enraizamento.porcentagemEnraizamento) !== null && _a !== void 0 ? _a : 0,
                            producao: 0,
                            totalProducao: 0,
                            totalEnraizamento: 1
                        });
                    }
                    else {
                        itemDataRelatorio.enraizamento += enraizamento.porcentagemEnraizamento;
                        itemDataRelatorio.producao += 0;
                        itemDataRelatorio.totalEnraizamento += 1;
                        itemDataRelatorio.totalProducao += 0;
                    }
                });
                angular.forEach(listaDataRelatorio, (dataRelatorio) => {
                    var _a, _b, _c, _d;
                    if ((typeof dataRelatorio.data !== 'undefined') && dataRelatorio.data) {
                        if (dataRelatorio.totalProducao > 0) {
                            listaDataProducaoRelatorio.push(Number((dataRelatorio.producao / dataRelatorio.totalProducao).toFixed(2)));
                            this.totalNumMudas += (_a = Number((dataRelatorio.producao / dataRelatorio.totalProducao).toFixed(2))) !== null && _a !== void 0 ? _a : 0;
                        }
                        else {
                            listaDataProducaoRelatorio.push(Number((dataRelatorio.producao).toFixed(2)));
                            this.totalNumMudas += (_b = Number((dataRelatorio.producao).toFixed(2))) !== null && _b !== void 0 ? _b : 0;
                        }
                        if (dataRelatorio.totalEnraizamento > 0) {
                            listaDataEnraizamentoRelatorio.push(Number((dataRelatorio.enraizamento / dataRelatorio.totalEnraizamento).toFixed(2)));
                            this.medEnraizamento += (_c = Number((dataRelatorio.enraizamento / dataRelatorio.totalEnraizamento).toFixed(2))) !== null && _c !== void 0 ? _c : 0;
                        }
                        else {
                            listaDataEnraizamentoRelatorio.push(Number((dataRelatorio.enraizamento).toFixed(2)));
                            this.medEnraizamento += (_d = Number((dataRelatorio.enraizamento).toFixed(2))) !== null && _d !== void 0 ? _d : 0;
                        }
                        listaLabelsRelatorio.push(new Date(dataRelatorio.data).toLocaleDateString('pt-BR'));
                    }
                });
                if (listaDataEnraizamentoRelatorio.length > 0) {
                    this.medEnraizamento = this.medEnraizamento / listaDataEnraizamentoRelatorio.length;
                }
                let dadosClone = listaProducaoClone.find(clone => (String(clone.clone.materialGenetico) === this.filtroDossieClone.clone.clone.id));
                if ((typeof dadosClone !== 'undefined') && dadosClone) {
                    this.filtroDossieClone.producaoClone = dadosClone;
                }
                console.log(this.filtroDossieClone.producaoClone);
                yield this.dadosRelatorioDossie(listaDataProducaoRelatorio, listaDataEnraizamentoRelatorio, listaLabelsRelatorio);
            }
            this.scopeApply();
        });
    }
    dadosRelatorioDossie(listaDataProducaoRelatorio, listaDataEnraizamentoRelatorio, listaLabelsRelatorio) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.graficoRelatorioDossie !== 'undefined') && this.graficoRelatorioDossie) {
                this.graficoRelatorioDossie.destroy();
                this.graficoRelatorioDossie = null;
            }
            for (let cargaGrafico = 1; cargaGrafico <= 10; cargaGrafico++) {
                setTimeout((ctrl) => {
                    if (ctrl.graficoRelatorioDossie) {
                        return;
                    }
                    const graficoRelatorioDossieEl = document.getElementById('graficoRelatorioDossie');
                    if ((typeof graficoRelatorioDossieEl === 'undefined') || !graficoRelatorioDossieEl) {
                        return;
                    }
                    const graficoRelatorioDossieCtx = graficoRelatorioDossieEl.getContext('2d');
                    const graficoRelatorioDossieDataSets = [{
                            type: 'bar',
                            label: 'Porcentagem de Enraizamento',
                            data: listaDataEnraizamentoRelatorio,
                            backgroundColor: 'rgba(54, 162, 235, 0.2)',
                            borderColor: 'rgb(54, 162, 235)',
                            borderWidth: 1,
                            yAxisID: 'A',
                        }, {
                            type: 'line',
                            label: 'Nº Miniestacas por Minicepa',
                            data: listaDataProducaoRelatorio,
                            backgroundColor: 'rgb(255, 159, 64)',
                            borderColor: 'rgb(255, 159, 64)',
                            borderWidth: 1,
                            fill: false,
                            yAxisID: 'B',
                        }];
                    ctrl.graficoRelatorioDossie = new chart_js_1.Chart(graficoRelatorioDossieCtx, {
                        type: 'bar',
                        data: {
                            labels: listaLabelsRelatorio,
                            datasets: graficoRelatorioDossieDataSets
                        },
                        plugins: [globalThis.ChartDataLabels],
                        options: {
                            responsive: true,
                            legend: {
                                display: false
                            },
                            layout: {
                                padding: {
                                    right: 30
                                }
                            },
                            scales: {
                                gridLines: { offsetGridLines: true },
                                xAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    }],
                                yAxes: [{
                                        id: 'A',
                                        type: 'linear',
                                        position: 'left',
                                        ticks: {
                                            min: 0
                                        }
                                    }, {
                                        id: 'B',
                                        type: 'linear',
                                        position: 'right',
                                        ticks: {
                                            min: 0
                                        }
                                    }]
                            }
                        }
                    });
                }, cargaGrafico * 1000, this);
            }
            this.scopeApply();
        });
    }
    cellClicked(row) {
        if (row.entity && typeof row.entity.id !== 'undefined' && row.entity.id) {
            const nomeTabelaProducao = 'tabelaProducao';
            let gridProducaoApi = null;
            let gridProducaoScope = angular.element('#' + nomeTabelaProducao).scope();
            if ((typeof gridProducaoScope.listaGridApi !== 'undefined') && gridProducaoScope.listaGridApi) {
                angular.forEach(gridProducaoScope.listaGridApi, (gridApi) => {
                    const grid = (typeof gridApi.grid !== 'undefined') && gridApi.grid ? gridApi.grid : null;
                    if (grid && (typeof grid.element !== 'undefined') && grid.element) {
                        const tabelasEl = angular.element(grid.element).parents('tabela');
                        if (tabelasEl && (tabelasEl.length > 0) && (tabelasEl.first().attr('id') === nomeTabelaProducao)) {
                            gridProducaoApi = gridApi;
                        }
                    }
                });
            }
            if (gridProducaoApi && (typeof gridProducaoApi.selection !== 'undefined') && gridProducaoApi.selection) {
                gridProducaoApi.selection.selectRow(row.entity);
            }
        }
    }
    limparFiltroProducao() {
        return __awaiter(this, void 0, void 0, function* () {
            this.producaoSelecionado = null;
            this.producaoCarregado = false;
        });
    }
    selecionarProducao() {
        return __awaiter(this, void 0, void 0, function* () {
            let ctrl = this;
            if (ctrl && ctrl.constructor && ctrl.constructor.name) {
                if (ctrl.constructor.name === 'TabelaController') {
                    ctrl = ctrl.$scope.$parent.$ctrl;
                }
            }
            ctrl.filtrarProducaoSelecionado();
        });
    }
    filtrarGerminacaoSementes() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.filtrarProducaoSelecionado();
                if ((typeof this.producaoSelecionado !== 'undefined') && this.producaoSelecionado &&
                    (typeof this.producaoSelecionado.id !== 'undefined') && this.producaoSelecionado.id) {
                    this.producaoCarregado = true;
                    this.scopeApply();
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
        });
    }
    filtrarProducaoSelecionado() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                let producao = null;
                const nomeTabelaProducao = 'tabelaProducao';
                let gridProducaoApi = null;
                let gridProducaoScope = angular.element('#' + nomeTabelaProducao).scope();
                if ((typeof gridProducaoScope.listaGridApi !== 'undefined') && gridProducaoScope.listaGridApi) {
                    angular.forEach(gridProducaoScope.listaGridApi, (gridApi) => {
                        const grid = (typeof gridApi.grid !== 'undefined') && gridApi.grid ? gridApi.grid : null;
                        if (grid && typeof (grid.element !== 'undefined') && grid.element) {
                            const tabelasEl = angular.element(grid.element).parents('tabela');
                            if (tabelasEl && (tabelasEl.length > 0) && (tabelasEl.first().attr('id') === nomeTabelaProducao)) {
                                gridProducaoApi = gridApi;
                            }
                        }
                    });
                }
                if (gridProducaoApi) {
                    const producoesSelecionados = gridProducaoApi.selection.getSelectedRows();
                    if ((typeof producoesSelecionados !== 'undefined') && producoesSelecionados) {
                        angular.forEach(producoesSelecionados, (obj) => {
                            if (!producao) {
                                producao = obj;
                            }
                        });
                    }
                }
                if (producao && (typeof producao.id !== 'undefined') && producao.id) {
                    if ((typeof ctrl.producaoSelecionado !== 'undefined') && ctrl.producaoSelecionado &&
                        (typeof ctrl.producaoSelecionado.id !== 'undefined') && ctrl.producaoSelecionado.id) {
                        if (ctrl.producaoSelecionado.id !== producao.id) {
                            yield ctrl.limparFiltroProducao();
                            ctrl.producaoSelecionado = producao;
                            ctrl.producaoCarregado = false;
                        }
                    }
                    else {
                        yield ctrl.limparFiltroProducao();
                        ctrl.producaoSelecionado = producao;
                        ctrl.producaoCarregado = false;
                    }
                }
                else {
                    if (ctrl.producaoSelecionado || ctrl.producaoCarregado) {
                        yield ctrl.limparFiltroProducao();
                        ctrl.producaoCarregado = false;
                    }
                }
                ctrl.scopeApply();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
        });
    }
    filtrarAnalises(alterouDados = false) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.analisesFiltradas === 'undefined') || !this.analisesFiltradas || ((typeof alterouDados !== 'undefined') && alterouDados)) {
                this.listaOpcoesAvaliacaoEnraizamento = yield this.dadosListaAvaliacaoEnraizamento();
                this.listaOpcoesAvaliacaoSobrevivenciaCasaSombra = yield this.dadosListaAvaliacaoSobrevivenciaCasaSombra();
                this.listaOpcoesAvaliacaoSobrevivenciaAclimatacao = yield this.dadosListaAvaliacaoSobrevivenciaAclimatacao();
                this.listaOpcoesDossieClone = null;
                this.analisesFiltradas = true;
                this.scopeApply();
            }
        });
    }
    preencherCodigoProducao() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.modelo.data !== 'undefined') && this.modelo.data &&
                (typeof this.modelo.clone !== 'undefined') && this.modelo.clone) {
                this.modelo.codigoMiniestaquia = `${this.modelo.data.toLocaleDateString('pt-BR')} - ${this.modelo.clone.descricao}`;
            }
        });
    }
    calcularEnraizamento() {
        return __awaiter(this, void 0, void 0, function* () {
            this.dataEnraizamento();
            if ((typeof this.dadosAvaliacaoEnraizamento.clone !== 'undefined') && this.dadosAvaliacaoEnraizamento.clone &&
                (typeof this.dadosAvaliacaoEnraizamento.numEstacas !== 'undefined') && this.dadosAvaliacaoEnraizamento.numEstacas &&
                (typeof this.dadosAvaliacaoEnraizamento.clone.numEstacas !== 'undefined') && this.dadosAvaliacaoEnraizamento.clone.numEstacas > 0) {
                this.dadosAvaliacaoEnraizamento.porcentagemEnraizamento = Number(((this.dadosAvaliacaoEnraizamento.numEstacas / this.dadosAvaliacaoEnraizamento.clone.numEstacas) * 100).toFixed(2));
            }
        });
    }
    filtrarDossieClone() {
        return __awaiter(this, void 0, void 0, function* () {
            let listaDossie = [];
            this.totalNumMiniestacasCepa = 0;
            this.totalNumMudas = 0;
            this.medEnraizamento = 0;
            if ((typeof this.filtroDossieClone.clone !== 'undefined') && this.filtroDossieClone.clone) {
                listaDossie = this.producoesMiniestacasClones.filter((o) => {
                    return (o.clone.id === this.filtroDossieClone.clone.clone.id);
                });
                angular.forEach(listaDossie, (itemDossie) => {
                    var _a;
                    this.filtroDossieClone.listaMiniestaquia.push(itemDossie);
                    this.totalNumMiniestacasCepa += (_a = itemDossie.numMiniestacasCepa) !== null && _a !== void 0 ? _a : 0;
                });
                if (listaDossie.length > 0) {
                    this.medEnraizamento = this.medEnraizamento / listaDossie.length;
                }
                yield this.filtrarRelatorioDossie();
                this.showDossieClone = true;
            }
        });
    }
    dataEnraizamento() {
        if ((typeof this.dadosAvaliacaoEnraizamento.clone !== 'undefined') && this.dadosAvaliacaoEnraizamento.clone &&
            (typeof this.dadosAvaliacaoEnraizamento.clone.data !== 'undefined') && this.dadosAvaliacaoEnraizamento.clone.data) {
            if (typeof this.dadosAvaliacaoEnraizamento.clone.data === 'string') {
                this.dadosAvaliacaoEnraizamento.clone.data = new Date(this.dadosAvaliacaoEnraizamento.clone.data);
            }
            this.dadosAvaliacaoEnraizamento.data = this.dadosAvaliacaoEnraizamento.clone.data;
        }
    }
    dataAvaliacaoCasaSombra() {
        if ((typeof this.dadosAvaliacaoSobrevivenciaCasaSombra.clone !== 'undefined') && this.dadosAvaliacaoSobrevivenciaCasaSombra.clone &&
            (typeof this.dadosAvaliacaoSobrevivenciaCasaSombra.clone.data !== 'undefined') && this.dadosAvaliacaoSobrevivenciaCasaSombra.clone.data) {
            if (typeof this.dadosAvaliacaoSobrevivenciaCasaSombra.clone.data === 'string') {
                this.dadosAvaliacaoSobrevivenciaCasaSombra.clone.data = new Date(this.dadosAvaliacaoSobrevivenciaCasaSombra.clone.data);
            }
            this.dadosAvaliacaoSobrevivenciaCasaSombra.data = this.dadosAvaliacaoSobrevivenciaCasaSombra.clone.data;
        }
    }
    dataAvaliacaoAclimatacao() {
        if ((typeof this.dadosAvaliacaoSobrevivenciaAclimatacao.clone !== 'undefined') && this.dadosAvaliacaoSobrevivenciaAclimatacao.clone &&
            (typeof this.dadosAvaliacaoSobrevivenciaAclimatacao.clone.data !== 'undefined') && this.dadosAvaliacaoSobrevivenciaAclimatacao.clone.data) {
            if (typeof this.dadosAvaliacaoSobrevivenciaAclimatacao.clone.data === 'string') {
                this.dadosAvaliacaoSobrevivenciaAclimatacao.clone.data = new Date(this.dadosAvaliacaoSobrevivenciaAclimatacao.clone.data);
            }
            this.dadosAvaliacaoSobrevivenciaAclimatacao.data = this.dadosAvaliacaoSobrevivenciaAclimatacao.clone.data;
        }
    }
}
exports.ProducaoMudaClonalEucalyptusMiniestaquiaController = ProducaoMudaClonalEucalyptusMiniestaquiaController;
ProducaoMudaClonalEucalyptusMiniestaquiaController.$inject = ['$scope', '$injector'];
