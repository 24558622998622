"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginTeste = void 0;
const config_app_1 = require("../../../../../config/config.app");
class LoginTeste {
    static alerta() {
        if ((typeof config_app_1.config.empresa === 'undefined') || !config_app_1.config.empresa) {
            config_app_1.config.carregarEmpresaLogada();
        }
        const nomeEmpresa = config_app_1.config.empresa && config_app_1.config.empresa.nomeCompleto ? config_app_1.config.empresa.nomeCompleto : 'ANI SISTEMAS';
        alert('Prezado usuário de teste, \r\n' +
            'Este sistema foi preparado para sincronizar os dados a partir da rede privada da © ' + nomeEmpresa + '. \r\n' +
            'Portanto é necessário estar conectado na VPN privada da empresa para baixar os dados. \r\n' +
            'O aplicativo não terá dados para trabalhar até a primeira sincronia ser realizada para baixar os dados, na rede privada.');
    }
    static respostaLogin() {
        let resposta = {
            mensagens: [
                {
                    codigo: 'SUC-0001',
                    texto: 'Login realizado com Sucesso!',
                    tipo: 0,
                    campo: null
                }
            ],
            dados: {
                id: '606f9698da652521602b7ce0',
                persistGuid: '606f9698da652521602b7ce0',
                uuid: '606f9698da652521602b7ce0',
                status: true,
                criacao: '2020-01-01T00:00:00+00:00',
                sistema: 'sgp',
                versao: 2,
                codigo: 1,
                nome: 'Teste',
                nomeCompleto: 'Teste',
                email: LoginTeste.emailPadrao,
                celular: null,
                senha: null,
                aparelho: null,
                token: [
                    [
                        321,
                        'f000000',
                        1
                    ]
                ],
                tokenJWE: 'ey0000__' + LoginTeste.emailPadrao,
                validade: '2050-12-31T23:59:59+00:00',
                perfil: null,
                ativo: true,
                tipoProducaoOperacao: true,
                tipoAreaCliente: false,
                tipoQualidade: true,
                nivelUsuario: 2,
                permissoes: {
                    materialGeneticoAcessa: true,
                    materialGeneticoInclui: true,
                    materialGeneticoAltera: true,
                    materialGeneticoExclui: true,
                    regiaoAcessa: true,
                    regiaoInclui: true,
                    regiaoAltera: true,
                    regiaoExclui: true,
                    talhaoAcessa: true,
                    talhaoInclui: true,
                    talhaoAltera: true,
                    talhaoExclui: true,
                    apontamentoAcessa: true,
                    apontamentoInclui: true,
                    apontamentoAltera: true,
                    apontamentoExclui: true,
                    usuarioAcessa: true,
                    usuarioInclui: true,
                    usuarioAltera: true,
                    usuarioExclui: true,
                    configuracaoGeralAcessa: true,
                    configuracaoGeralAltera: true
                },
                logado: true
            }
        };
        resposta.dados = Object.assign(resposta.dados, resposta.dados.permissoes);
        return resposta;
    }
}
exports.LoginTeste = LoginTeste;
LoginTeste.emailPadrao = 'teste_publico@anisistemas.com.br';
LoginTeste.senhaPadrao = 'publ1c0@2022$';
