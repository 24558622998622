"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ManipulaErroFactory = void 0;
const config_app_1 = require("../../config/config.app");
const CarregamentoUtil_1 = require("../../util/CarregamentoUtil");
const DebugUtil_1 = require("../../util/DebugUtil");
const NotificacaoFactory_1 = require("./NotificacaoFactory");
class ManipulaErroFactory {
    constructor(notificacao) {
        this.notificacao = null;
        this.notificacao = notificacao;
    }
    restException(ex, exibirErro = true) {
        let message = 'Erro ao acessar a fonte de dados.';
        if (ex.status && ex.statusText) {
            message = `[${ex.status}] -> ${ex.statusText}`;
        }
        let title = 'Entre em contato com o Suporte Técnico';
        if (ex.status === -1) {
            if (!exibirErro) {
                return;
            }
            title = 'Falha ao conectar com o Servidor!';
            message = 'Verifique sua conexão, caso repita o erro Entre em contato com o Suporte Técnico';
        }
        if (ex.data && ex.data.mensagens && ex.data.mensagens.length) {
            this.notificacao.servidor(ex.data);
        }
        else {
            if (!exibirErro) {
                return;
            }
            this.notificacao.erro(title, message);
        }
        if ((config_app_1.config.ambiente === config_app_1.Ambiente.DESENVOLVIMENTO_LOCAL) || (config_app_1.config.ambiente === config_app_1.Ambiente.DESENVOLVIMENTO_WEB)) {
            throw ex;
        }
    }
    exception(ex) {
        CarregamentoUtil_1.CarregamentoUtil.removerLoad('*');
        DebugUtil_1.DebugUtil.tracar();
        let titulo = 'Entre em contato com Suporte Técnico';
        let mensagem = 'Sem mensagem de erro aparente';
        if ((typeof ex !== 'undefined') && (ex !== null) && (typeof ex.message !== 'undefined') && (ex.message !== null)) {
            if (ex.message) {
                titulo = (ex.message.length < 50) ? ex.message : '';
                mensagem = (ex.message.length >= 50) ? ex.message : '';
            }
        }
        this.notificacao.excecao(titulo, mensagem);
    }
}
exports.ManipulaErroFactory = ManipulaErroFactory;
ManipulaErroFactory.id = ManipulaErroFactory.name;
ManipulaErroFactory.$inject = [NotificacaoFactory_1.NotificacaoFactory.id];
