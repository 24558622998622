"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DialogoFactory = void 0;
const angular = require("angular");
class DialogoFactory {
    constructor($mdDialog) {
        this.$mdDialog = null;
        this.$mdDialog = $mdDialog;
    }
    exibirModal(modalInfo) {
        return __awaiter(this, void 0, void 0, function* () {
            const fechar = (valor) => { this.$mdDialog.hide(valor); };
            modalInfo.fechar = fechar.bind(this);
            return this.$mdDialog.show({
                parent: angular.element(document.body),
                multiple: true,
                targetEvent: modalInfo.$event,
                templateUrl: modalInfo.url,
                locals: { modalInfo: modalInfo },
                controller: modalInfo.controller,
                controllerAs: '$ctrl'
            });
        });
    }
}
exports.DialogoFactory = DialogoFactory;
DialogoFactory.id = DialogoFactory.name;
DialogoFactory.$inject = ['$mdDialog'];
