"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FerramentaModulo = void 0;
const angular_1 = require("angular");
const FerramentaRota_1 = require("./FerramentaRota");
class FerramentaModulo {
    static iniciar() {
        angular_1.module(FerramentaModulo.id, []).config(FerramentaRota_1.FerramentaRota);
    }
    static carregar() {
        return angular_1.module(FerramentaModulo.id);
    }
}
exports.FerramentaModulo = FerramentaModulo;
FerramentaModulo.id = 'ferramenta';
