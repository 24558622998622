"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LuckysheetExportXLSX = void 0;
const ExcelJS = require("exceljs");
class LuckysheetExportXLSX {
    constructor() {
        this.luckysheet = null;
        this.filename = null;
        this.sheetNum = 0;
    }
    execute() {
        const workbook = new ExcelJS.Workbook();
        this.sheetNum = 0;
        if ((typeof this.luckysheet === 'undefined') || !this.luckysheet) {
            if ((typeof globalThis.luckysheet !== 'undefined') && globalThis.luckysheet) {
                this.luckysheet = globalThis.luckysheet;
            }
        }
        var sheetArr = this.luckysheet.getAllSheets();
        sheetArr.forEach((sheet) => {
            this.sheetNum++;
            if (!sheet.data || (sheet.data && (sheet.data.length === 0))) {
                return true;
            }
            const sheetName = this.parseSheetName(sheet.name);
            const sheetData = ((typeof sheet.data !== 'undefined') && sheet.data) ? sheet.data : [];
            const sheetConfig = ((typeof sheet.config !== 'undefined') && sheet.config) ? sheet.config : {};
            const sheetMergeConfig = (sheetConfig && (typeof sheetConfig.merge !== 'undefined') && sheetConfig.merge) ? sheetConfig.merge : {};
            const worksheet = workbook.addWorksheet(sheetName);
            this.setStyleAndValue(sheetData, sheetConfig, worksheet);
            this.setMerge(sheetMergeConfig, worksheet);
            this.setBorder(sheet, worksheet);
            this.setImages(sheet, worksheet, workbook);
            return true;
        });
        this.filename = this.parseWorkbookName((typeof this.filename !== 'undefined') ? this.filename : null);
        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
                type: 'application/vnd.ms-excel;charset=utf-8'
            });
            console.log('Exportação XLSX bem-sucedida!');
            this.saveFile(blob, this.filename);
        });
    }
    getBase64(file) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            console.log(e.target.result);
        };
    }
    parseWorkbookName(workbookName) {
        let s = (workbookName && workbookName.trim().length > 0) ? workbookName.trim() : null;
        if (s === null) {
            const dataHora = new Date();
            let prefixo = null, parametroRota = (location.href && (location.href.indexOf('#!/') !== -1) ? location.href : null), dia = ((dataHora.getDate() >= 10) ? '' : '0') + dataHora.getDate().toString(), mes = (((dataHora.getMonth() + 1) >= 10) ? '' : '0') + Number(dataHora.getMonth() + 1).toString(), ano = ((dataHora.getFullYear() >= 10) ? '' : '0') + dataHora.getFullYear().toString(), hora = ((dataHora.getHours() >= 10) ? '' : '0') + dataHora.getHours().toString(), minuto = ((dataHora.getMinutes() >= 10) ? '' : '0') + dataHora.getMinutes().toString(), segundo = ((dataHora.getSeconds() >= 10) ? '' : '0') + dataHora.getSeconds().toString();
            if (parametroRota && (parametroRota.indexOf('producaoMudaClonalEucal') !== -1)) {
                prefixo = 'ProdMudaClonalEucalyptus_';
            }
            else if (parametroRota && (parametroRota.indexOf('producaoMudaClonalPinus') !== -1)) {
                prefixo = 'ProdMudaClonalPinus_';
            }
            else if (parametroRota && (parametroRota.indexOf('testeGenetico') !== -1)) {
                prefixo = 'TesteGenetico_';
            }
            else if (parametroRota && (parametroRota.indexOf('testeCampo') !== -1)) {
                prefixo = 'TesteCampo_';
            }
            else {
                prefixo = 'CroquiPomar_';
            }
            s = prefixo + dia + '-' + mes + '-' + ano + '_' + hora + ';' + minuto + ';' + segundo;
        }
        s = s.replace(/\:|\\|\/|\?|\!|\*|\"|\'|\[|\]/g, '-');
        while ((s.indexOf('--') !== -1) || (s.indexOf('- -') !== -1)) {
            s = s.replace('--', '-').replace('- -', '-');
        }
        return s;
    }
    parseSheetName(sheetName) {
        let s = (sheetName && sheetName.trim().length > 0) ? sheetName.trim() : null;
        if (s === null) {
            s = 'Planilha' + this.sheetNum;
        }
        s = s.replace(/\:|\\|\/|\?|\!|\*|\"|\'|\[|\]/g, '-');
        while ((s.indexOf('--') !== -1) || (s.indexOf('- -') !== -1)) {
            s = s.replace('--', '-').replace('- -', '-');
        }
        return s;
    }
    saveFile(buf, name) {
        let blob = new Blob([buf], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
        });
        const downloadElement = document.createElement('a');
        let href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = name + '.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
    }
    setMerge(luckyMerge = {}, worksheet) {
        const mergearr = Object.values(luckyMerge);
        mergearr.forEach((elem) => {
            worksheet.mergeCells(elem.r + 1, elem.c + 1, elem.r + elem.rs, elem.c + elem.cs);
        });
    }
    setBorder(lucksheetfile, worksheet) {
        if (!lucksheetfile) {
            return;
        }
        const luckyToExcel = {
            style: {
                0: 'none',
                1: 'thin',
                2: 'hair',
                3: 'dotted',
                4: 'dashDot',
                5: 'dashDot',
                6: 'dashDotDot',
                7: 'double',
                8: 'medium',
                9: 'mediumDashed',
                10: 'mediumDashDot',
                11: 'mediumDashDotDot',
                12: 'slantDashDot',
                13: 'thick'
            }
        };
        const borderInfoCompute = this.getBorderInfo(lucksheetfile);
        for (let x in borderInfoCompute) {
            if (typeof borderInfoCompute[x] !== 'undefined') {
                let border = {};
                let info = borderInfoCompute[x];
                let row = parseInt(x.substring(0, x.indexOf('_')), 10);
                let column = parseInt(x.substring(x.indexOf('_') + 1), 10);
                if ((typeof info !== 'undefined') && (info !== null) && (typeof info.t !== 'undefined') && (info.t !== null)) {
                    const tcolor = info.t.color.indexOf('rgb') > -1 ? this.rgb2hex(info.t.color) : info.t.color;
                    border['top'] = {
                        style: luckyToExcel.style[info.t.style],
                        color: { argb: tcolor.replace('#', '') }
                    };
                }
                if ((typeof info !== 'undefined') && (info !== null) && (typeof info.r !== 'undefined') && (info.r !== null)) {
                    const rcolor = info.r.color.indexOf('rgb') > -1 ? this.rgb2hex(info.r.color) : info.r.color;
                    border['right'] = {
                        style: luckyToExcel.style[info.r.style],
                        color: { argb: rcolor.replace('#', '') }
                    };
                }
                if ((typeof info !== 'undefined') && (info !== null) && (typeof info.b !== 'undefined') && (info.b !== null)) {
                    const bcolor = info.b.color.indexOf('rgb') > -1 ? this.rgb2hex(info.b.color) : info.b.color;
                    border['bottom'] = {
                        style: luckyToExcel.style[info.b.style],
                        color: { argb: bcolor.replace('#', '') }
                    };
                }
                if ((typeof info !== 'undefined') && (info !== null) && (typeof info.l !== 'undefined') && (info.l !== null)) {
                    const lcolor = info.l.color.indexOf('rgb') > -1 ? this.rgb2hex(info.l.color) : info.l.color;
                    border['left'] = {
                        style: luckyToExcel.style[info.l.style],
                        color: { argb: lcolor.replace('#', '') }
                    };
                }
                worksheet.getCell(row + 1, column + 1).border = border;
            }
        }
    }
    getBorderInfo(luckysheetfile) {
        let borderInfoCompute = {};
        let cfg = (luckysheetfile && (typeof luckysheetfile.config !== 'undefined') && luckysheetfile.config) ? luckysheetfile.config : {};
        let data = (luckysheetfile && (typeof luckysheetfile.data !== 'undefined') && luckysheetfile.data) ? luckysheetfile.data : [];
        let borderInfo = cfg['borderInfo'];
        let dataset_row_st = 0, dataset_row_ed = data.length, dataset_col_st = 0, dataset_col_ed = data[0].length;
        if ((typeof borderInfo !== 'undefined') && (borderInfo !== null) && (borderInfo.length > 0)) {
            for (let i = 0; i < borderInfo.length; i++) {
                let rangeType = borderInfo[i].rangeType;
                if (rangeType === 'range') {
                    let borderType = borderInfo[i].borderType;
                    let borderColor = borderInfo[i].color;
                    let borderStyle = borderInfo[i].style;
                    let borderRange = borderInfo[i].range;
                    for (let j = 0; j < borderRange.length; j++) {
                        let bd_r1 = borderRange[j].row[0], bd_r2 = borderRange[j].row[1];
                        let bd_c1 = borderRange[j].column[0], bd_c2 = borderRange[j].column[1];
                        if (bd_r1 < dataset_row_st) {
                            bd_r1 = dataset_row_st;
                        }
                        if (bd_r2 > dataset_row_ed) {
                            bd_r2 = dataset_row_ed;
                        }
                        if (bd_c1 < dataset_col_st) {
                            bd_c1 = dataset_col_st;
                        }
                        if (bd_c2 > dataset_col_ed) {
                            bd_c2 = dataset_col_ed;
                        }
                        if (borderType === 'border-left') {
                            for (let bd_r = bd_r1; bd_r <= bd_r2; bd_r++) {
                                if ((typeof cfg['rowhidden'] !== 'undefined') && (cfg['rowhidden'] !== null) &&
                                    (typeof cfg['rowhidden'][bd_r] !== 'undefined') && (cfg['rowhidden'][bd_r] !== null)) {
                                    continue;
                                }
                                if ((typeof borderInfoCompute[bd_r + '_' + bd_c1] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c1] === null)) {
                                    borderInfoCompute[bd_r + '_' + bd_c1] = {};
                                }
                                borderInfoCompute[bd_r + '_' + bd_c1].l = {
                                    color: borderColor,
                                    style: borderStyle
                                };
                                let bd_c_left = bd_c1 - 1;
                                if (bd_c_left >= 0 && borderInfoCompute[bd_r + '_' + bd_c_left]) {
                                    if ((typeof data[bd_r] !== 'undefined') && (data[bd_r] !== null) &&
                                        (this.getObjType(data[bd_r][bd_c_left]) === 'object') &&
                                        (typeof data[bd_r][bd_c_left].mc !== 'undefined') && (data[bd_r][bd_c_left].mc !== null)) {
                                        let cell_left = data[bd_r][bd_c_left];
                                        let mc = cfg['merge'][cell_left.mc.r + '_' + cell_left.mc.c];
                                        if (mc.c + mc.cs - 1 === bd_c_left) {
                                            borderInfoCompute[bd_r + '_' + bd_c_left].r = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                        }
                                    }
                                    else {
                                        borderInfoCompute[bd_r + '_' + bd_c_left].r = {
                                            color: borderColor,
                                            style: borderStyle
                                        };
                                    }
                                }
                                let mc = cfg['merge'] || {};
                                for (const key in mc) {
                                    if (mc[key]) {
                                        let { c, r, cs, rs } = mc[key];
                                        if (bd_c1 <= c + cs - 1 &&
                                            bd_c1 > c &&
                                            bd_r >= r &&
                                            bd_r <= r + rs - 1) {
                                            borderInfoCompute[bd_r + '_' + bd_c1].l = null;
                                        }
                                    }
                                }
                            }
                        }
                        else if (borderType === 'border-right') {
                            for (let bd_r = bd_r1; bd_r <= bd_r2; bd_r++) {
                                if ((typeof cfg['rowhidden'] !== 'undefined') && (cfg['rowhidden'] !== null) &&
                                    (typeof cfg['rowhidden'][bd_r] !== 'undefined') && (cfg['rowhidden'][bd_r] !== null)) {
                                    continue;
                                }
                                if ((typeof borderInfoCompute[bd_r + '_' + bd_c2] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c2] === null)) {
                                    borderInfoCompute[bd_r + '_' + bd_c2] = {};
                                }
                                borderInfoCompute[bd_r + '_' + bd_c2].r = {
                                    color: borderColor,
                                    style: borderStyle
                                };
                                let bd_c_right = bd_c2 + 1;
                                if (bd_c_right < data[0].length &&
                                    borderInfoCompute[bd_r + '_' + bd_c_right]) {
                                    if ((typeof data[bd_r] !== 'undefined') && (data[bd_r] !== null) &&
                                        (this.getObjType(data[bd_r][bd_c_right]) === 'object') &&
                                        (typeof data[bd_r][bd_c_right].mc !== 'undefined') && (data[bd_r][bd_c_right].mc !== null)) {
                                        let cell_right = data[bd_r][bd_c_right];
                                        let mc = cfg['merge'][cell_right.mc.r + '_' + cell_right.mc.c];
                                        if (mc.c === bd_c_right) {
                                            borderInfoCompute[bd_r + '_' + bd_c_right].l = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                        }
                                    }
                                    else {
                                        borderInfoCompute[bd_r + '_' + bd_c_right].l = {
                                            color: borderColor,
                                            style: borderStyle
                                        };
                                    }
                                }
                                let mc = cfg['merge'] || {};
                                for (const key in mc) {
                                    if (mc[key]) {
                                        let { c, r, cs, rs } = mc[key];
                                        if (bd_c2 < c + cs - 1 &&
                                            bd_c2 >= c &&
                                            bd_r >= r &&
                                            bd_r <= r + rs - 1) {
                                            borderInfoCompute[bd_r + '_' + bd_c2].r = null;
                                        }
                                    }
                                }
                            }
                        }
                        else if (borderType === 'border-top') {
                            if ((typeof cfg['rowhidden'] !== 'undefined') && (cfg['rowhidden'] !== null) &&
                                (typeof cfg['rowhidden'][bd_r1] !== 'undefined') && (cfg['rowhidden'][bd_r1] !== null)) {
                                continue;
                            }
                            for (let bd_c = bd_c1; bd_c <= bd_c2; bd_c++) {
                                if ((typeof borderInfoCompute[bd_r1 + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r1 + '_' + bd_c] === null)) {
                                    borderInfoCompute[bd_r1 + '_' + bd_c] = {};
                                }
                                borderInfoCompute[bd_r1 + '_' + bd_c].t = {
                                    color: borderColor,
                                    style: borderStyle
                                };
                                let bd_r_top = bd_r1 - 1;
                                if (bd_r_top >= 0 && borderInfoCompute[bd_r_top + '_' + bd_c]) {
                                    if ((typeof data[bd_r_top] !== 'undefined') && (data[bd_r_top] !== null) &&
                                        (this.getObjType(data[bd_r_top][bd_c]) === 'object') &&
                                        (typeof data[bd_r_top][bd_c].mc !== 'undefined') && (data[bd_r_top][bd_c].mc !== null)) {
                                        let cell_top = data[bd_r_top][bd_c];
                                        let mc = cfg['merge'][cell_top.mc.r + '_' + cell_top.mc.c];
                                        if (mc.r + mc.rs - 1 === bd_r_top) {
                                            borderInfoCompute[bd_r_top + '_' + bd_c].b = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                        }
                                    }
                                    else {
                                        borderInfoCompute[bd_r_top + '_' + bd_c].b = {
                                            color: borderColor,
                                            style: borderStyle
                                        };
                                    }
                                }
                                let mc = cfg['merge'] || {};
                                for (const key in mc) {
                                    if (mc[key]) {
                                        let { c, r, cs, rs } = mc[key];
                                        if (bd_r1 <= r + rs - 1 &&
                                            bd_r1 > r &&
                                            bd_c >= c &&
                                            bd_c <= c + cs - 1) {
                                            borderInfoCompute[bd_r1 + '_' + bd_c].t = null;
                                        }
                                    }
                                }
                            }
                        }
                        else if (borderType === 'border-bottom') {
                            if ((typeof cfg['rowhidden'] !== 'undefined') && (cfg['rowhidden'] !== null) &&
                                (typeof cfg['rowhidden'][bd_r2] !== 'undefined') && (cfg['rowhidden'][bd_r2] !== null)) {
                                continue;
                            }
                            for (let bd_c = bd_c1; bd_c <= bd_c2; bd_c++) {
                                if ((typeof borderInfoCompute[bd_r2 + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r2 + '_' + bd_c] === null)) {
                                    borderInfoCompute[bd_r2 + '_' + bd_c] = {};
                                }
                                borderInfoCompute[bd_r2 + '_' + bd_c].b = {
                                    color: borderColor,
                                    style: borderStyle
                                };
                                let bd_r_bottom = bd_r2 + 1;
                                if (bd_r_bottom < data.length &&
                                    borderInfoCompute[bd_r_bottom + '_' + bd_c]) {
                                    if ((typeof data[bd_r_bottom] !== 'undefined') && (data[bd_r_bottom] !== null) &&
                                        (this.getObjType(data[bd_r_bottom][bd_c]) === 'object') &&
                                        (typeof data[bd_r_bottom][bd_c].mc !== 'undefined') && (data[bd_r_bottom][bd_c].mc !== null)) {
                                        let cell_bottom = data[bd_r_bottom][bd_c];
                                        let mc = cfg['merge'][cell_bottom.mc.r + '_' + cell_bottom.mc.c];
                                        if (mc.r === bd_r_bottom) {
                                            borderInfoCompute[bd_r_bottom + '_' + bd_c].t = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                        }
                                    }
                                    else {
                                        borderInfoCompute[bd_r_bottom + '_' + bd_c].t = {
                                            color: borderColor,
                                            style: borderStyle
                                        };
                                    }
                                }
                                let mc = cfg['merge'] || {};
                                for (const key in mc) {
                                    if (mc[key]) {
                                        let { c, r, cs, rs } = mc[key];
                                        if (bd_r2 < r + rs - 1 &&
                                            bd_r2 >= r &&
                                            bd_c >= c &&
                                            bd_c <= c + cs - 1) {
                                            borderInfoCompute[bd_r2 + '_' + bd_c].b = null;
                                        }
                                    }
                                }
                            }
                        }
                        else if (borderType === 'border-all') {
                            for (let bd_r = bd_r1; bd_r <= bd_r2; bd_r++) {
                                if ((typeof cfg['rowhidden'] !== 'undefined') && (cfg['rowhidden'] !== null) &&
                                    (typeof cfg['rowhidden'][bd_r] !== 'undefined') && (cfg['rowhidden'][bd_r] !== null)) {
                                    continue;
                                }
                                for (let bd_c = bd_c1; bd_c <= bd_c2; bd_c++) {
                                    if ((typeof data[bd_r] !== 'undefined') && (data[bd_r] !== null) &&
                                        (this.getObjType(data[bd_r][bd_c]) === 'object') &&
                                        (typeof data[bd_r][bd_c].mc !== 'undefined') && (data[bd_r][bd_c].mc !== null)) {
                                        let cell = data[bd_r][bd_c];
                                        let mc = cfg['merge'][cell.mc.r + '_' + cell.mc.c];
                                        if ((typeof mc === 'undefined') || (mc === null)) {
                                            continue;
                                        }
                                        if (mc.r === bd_r) {
                                            if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                                borderInfoCompute[bd_r + '_' + bd_c] = {};
                                            }
                                            borderInfoCompute[bd_r + '_' + bd_c].t = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                        }
                                        if (mc.r + mc.rs - 1 === bd_r) {
                                            if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                                borderInfoCompute[bd_r + '_' + bd_c] = {};
                                            }
                                            borderInfoCompute[bd_r + '_' + bd_c].b = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                        }
                                        if (mc.c === bd_c) {
                                            if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                                borderInfoCompute[bd_r + '_' + bd_c] = {};
                                            }
                                            borderInfoCompute[bd_r + '_' + bd_c].l = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                        }
                                        if (mc.c + mc.cs - 1 === bd_c) {
                                            if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                                borderInfoCompute[bd_r + '_' + bd_c] = {};
                                            }
                                            borderInfoCompute[bd_r + '_' + bd_c].r = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                        }
                                    }
                                    else {
                                        if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                            borderInfoCompute[bd_r + '_' + bd_c] = {};
                                        }
                                        borderInfoCompute[bd_r + '_' + bd_c].l = {
                                            color: borderColor,
                                            style: borderStyle
                                        };
                                        borderInfoCompute[bd_r + '_' + bd_c].r = {
                                            color: borderColor,
                                            style: borderStyle
                                        };
                                        borderInfoCompute[bd_r + '_' + bd_c].t = {
                                            color: borderColor,
                                            style: borderStyle
                                        };
                                        borderInfoCompute[bd_r + '_' + bd_c].b = {
                                            color: borderColor,
                                            style: borderStyle
                                        };
                                    }
                                    if (bd_r === bd_r1) {
                                        let bd_r_top = bd_r1 - 1;
                                        if (bd_r_top >= 0 &&
                                            borderInfoCompute[bd_r_top + '_' + bd_c]) {
                                            if ((typeof data[bd_r_top] !== 'undefined') && (data[bd_r_top] !== null) &&
                                                (this.getObjType(data[bd_r_top][bd_c]) === 'object') &&
                                                (typeof data[bd_r_top][bd_c].mc !== 'undefined') && (data[bd_r_top][bd_c].mc !== null)) {
                                                let cell_top = data[bd_r_top][bd_c];
                                                let mc = cfg['merge'][cell_top.mc.r + '_' + cell_top.mc.c];
                                                if (mc.r + mc.rs - 1 === bd_r_top) {
                                                    borderInfoCompute[bd_r_top + '_' + bd_c].b = {
                                                        color: borderColor,
                                                        style: borderStyle
                                                    };
                                                }
                                            }
                                            else {
                                                borderInfoCompute[bd_r_top + '_' + bd_c].b = {
                                                    color: borderColor,
                                                    style: borderStyle
                                                };
                                            }
                                        }
                                    }
                                    if (bd_r === bd_r2) {
                                        let bd_r_bottom = bd_r2 + 1;
                                        if (bd_r_bottom < data.length &&
                                            borderInfoCompute[bd_r_bottom + '_' + bd_c]) {
                                            if ((typeof data[bd_r_bottom] !== 'undefined') && (data[bd_r_bottom] !== null) &&
                                                (this.getObjType(data[bd_r_bottom][bd_c]) === 'object') &&
                                                (typeof data[bd_r_bottom][bd_c].mc !== 'undefined') && (data[bd_r_bottom][bd_c].mc !== null)) {
                                                let cell_bottom = data[bd_r_bottom][bd_c];
                                                let mc = cfg['merge'][cell_bottom.mc.r + '_' + cell_bottom.mc.c];
                                                if (mc.r === bd_r_bottom) {
                                                    borderInfoCompute[bd_r_bottom + '_' + bd_c].t = {
                                                        color: borderColor,
                                                        style: borderStyle
                                                    };
                                                }
                                            }
                                            else {
                                                borderInfoCompute[bd_r_bottom + '_' + bd_c].t = {
                                                    color: borderColor,
                                                    style: borderStyle
                                                };
                                            }
                                        }
                                    }
                                    if (bd_c === bd_c1) {
                                        let bd_c_left = bd_c1 - 1;
                                        if (bd_c_left >= 0 &&
                                            borderInfoCompute[bd_r + '_' + bd_c_left]) {
                                            if ((typeof data[bd_r] !== 'undefined') && (data[bd_r] !== null) &&
                                                (this.getObjType(data[bd_r][bd_c_left]) === 'object') &&
                                                (typeof data[bd_r][bd_c_left].mc !== 'undefined') && (data[bd_r][bd_c_left].mc !== null)) {
                                                let cell_left = data[bd_r][bd_c_left];
                                                let mc = cfg['merge'][cell_left.mc.r + '_' + cell_left.mc.c];
                                                if (mc.c + mc.cs - 1 === bd_c_left) {
                                                    borderInfoCompute[bd_r + '_' + bd_c_left].r = {
                                                        color: borderColor,
                                                        style: borderStyle
                                                    };
                                                }
                                            }
                                            else {
                                                borderInfoCompute[bd_r + '_' + bd_c_left].r = {
                                                    color: borderColor,
                                                    style: borderStyle
                                                };
                                            }
                                        }
                                    }
                                    if (bd_c === bd_c2) {
                                        let bd_c_right = bd_c2 + 1;
                                        if (bd_c_right < data[0].length &&
                                            borderInfoCompute[bd_r + '_' + bd_c_right]) {
                                            if ((typeof data[bd_r] !== 'undefined') && (data[bd_r] !== null) &&
                                                (this.getObjType(data[bd_r][bd_c_right]) === 'object') &&
                                                (typeof data[bd_r][bd_c_right].mc !== 'undefined') && (data[bd_r][bd_c_right].mc !== null)) {
                                                let cell_right = data[bd_r][bd_c_right];
                                                let mc = cfg['merge'][cell_right.mc.r + '_' + cell_right.mc.c];
                                                if (mc.c === bd_c_right) {
                                                    borderInfoCompute[bd_r + '_' + bd_c_right].l = {
                                                        color: borderColor,
                                                        style: borderStyle
                                                    };
                                                }
                                            }
                                            else {
                                                borderInfoCompute[bd_r + '_' + bd_c_right].l = {
                                                    color: borderColor,
                                                    style: borderStyle
                                                };
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        else if (borderType === 'border-outside') {
                            for (let bd_r = bd_r1; bd_r <= bd_r2; bd_r++) {
                                if ((typeof cfg['rowhidden'] !== 'undefined') && (cfg['rowhidden'] !== null) &&
                                    (typeof cfg['rowhidden'][bd_r] !== 'undefined') && (cfg['rowhidden'][bd_r] !== null)) {
                                    continue;
                                }
                                for (let bd_c = bd_c1; bd_c <= bd_c2; bd_c++) {
                                    if (!(bd_r === bd_r1 ||
                                        bd_r === bd_r2 ||
                                        bd_c === bd_c1 ||
                                        bd_c === bd_c2)) {
                                        continue;
                                    }
                                    if (bd_r === bd_r1) {
                                        if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                            borderInfoCompute[bd_r + '_' + bd_c] = {};
                                        }
                                        borderInfoCompute[bd_r + '_' + bd_c].t = {
                                            color: borderColor,
                                            style: borderStyle
                                        };
                                        let bd_r_top = bd_r1 - 1;
                                        if (bd_r_top >= 0 &&
                                            borderInfoCompute[bd_r_top + '_' + bd_c]) {
                                            if ((typeof data[bd_r_top] !== 'undefined') && (data[bd_r_top] !== null) &&
                                                (this.getObjType(data[bd_r_top][bd_c]) === 'object') &&
                                                (typeof data[bd_r_top][bd_c].mc !== 'undefined') && (data[bd_r_top][bd_c].mc !== null)) {
                                                let cell_top = data[bd_r_top][bd_c];
                                                let mc = cfg['merge'][cell_top.mc.r + '_' + cell_top.mc.c];
                                                if (mc.r + mc.rs - 1 === bd_r_top) {
                                                    borderInfoCompute[bd_r_top + '_' + bd_c].b = {
                                                        color: borderColor,
                                                        style: borderStyle
                                                    };
                                                }
                                            }
                                            else {
                                                borderInfoCompute[bd_r_top + '_' + bd_c].b = {
                                                    color: borderColor,
                                                    style: borderStyle
                                                };
                                            }
                                        }
                                    }
                                    if (bd_r === bd_r2) {
                                        if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                            borderInfoCompute[bd_r + '_' + bd_c] = {};
                                        }
                                        borderInfoCompute[bd_r + '_' + bd_c].b = {
                                            color: borderColor,
                                            style: borderStyle
                                        };
                                        let bd_r_bottom = bd_r2 + 1;
                                        if (bd_r_bottom < data.length &&
                                            borderInfoCompute[bd_r_bottom + '_' + bd_c]) {
                                            if ((typeof data[bd_r_bottom] !== 'undefined') && (data[bd_r_bottom] !== null) &&
                                                (this.getObjType(data[bd_r_bottom][bd_c]) === 'object') &&
                                                (typeof data[bd_r_bottom][bd_c].mc !== 'undefined') && (data[bd_r_bottom][bd_c].mc !== null)) {
                                                let cell_bottom = data[bd_r_bottom][bd_c];
                                                let mc = cfg['merge'][cell_bottom.mc.r + '_' + cell_bottom.mc.c];
                                                if (mc.r === bd_r_bottom) {
                                                    borderInfoCompute[bd_r_bottom + '_' + bd_c].t = {
                                                        color: borderColor,
                                                        style: borderStyle
                                                    };
                                                }
                                            }
                                            else {
                                                borderInfoCompute[bd_r_bottom + '_' + bd_c].t = {
                                                    color: borderColor,
                                                    style: borderStyle
                                                };
                                            }
                                        }
                                    }
                                    if (bd_c === bd_c1) {
                                        if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                            borderInfoCompute[bd_r + '_' + bd_c] = {};
                                        }
                                        borderInfoCompute[bd_r + '_' + bd_c].l = {
                                            color: borderColor,
                                            style: borderStyle
                                        };
                                        let bd_c_left = bd_c1 - 1;
                                        if (bd_c_left >= 0 &&
                                            borderInfoCompute[bd_r + '_' + bd_c_left]) {
                                            if ((typeof data[bd_r] !== 'undefined') && (data[bd_r] !== null) &&
                                                (this.getObjType(data[bd_r][bd_c_left]) === 'object') &&
                                                (typeof data[bd_r][bd_c_left].mc !== 'undefined') && (data[bd_r][bd_c_left].mc !== null)) {
                                                let cell_left = data[bd_r][bd_c_left];
                                                let mc = cfg['merge'][cell_left.mc.r + '_' + cell_left.mc.c];
                                                if (mc.c + mc.cs - 1 === bd_c_left) {
                                                    borderInfoCompute[bd_r + '_' + bd_c_left].r = {
                                                        color: borderColor,
                                                        style: borderStyle
                                                    };
                                                }
                                            }
                                            else {
                                                borderInfoCompute[bd_r + '_' + bd_c_left].r = {
                                                    color: borderColor,
                                                    style: borderStyle
                                                };
                                            }
                                        }
                                    }
                                    if (bd_c === bd_c2) {
                                        if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                            borderInfoCompute[bd_r + '_' + bd_c] = {};
                                        }
                                        borderInfoCompute[bd_r + '_' + bd_c].r = {
                                            color: borderColor,
                                            style: borderStyle
                                        };
                                        let bd_c_right = bd_c2 + 1;
                                        if (bd_c_right < data[0].length &&
                                            borderInfoCompute[bd_r + '_' + bd_c_right]) {
                                            if ((typeof data[bd_r] !== 'undefined') && (data[bd_r] !== null) &&
                                                (this.getObjType(data[bd_r][bd_c_right]) === 'object') &&
                                                (typeof data[bd_r][bd_c_right].mc !== 'undefined') && (data[bd_r][bd_c_right].mc !== null)) {
                                                let cell_right = data[bd_r][bd_c_right];
                                                let mc = cfg['merge'][cell_right.mc.r + '_' + cell_right.mc.c];
                                                if (mc.c === bd_c_right) {
                                                    borderInfoCompute[bd_r + '_' + bd_c_right].l = {
                                                        color: borderColor,
                                                        style: borderStyle
                                                    };
                                                }
                                            }
                                            else {
                                                borderInfoCompute[bd_r + '_' + bd_c_right].l = {
                                                    color: borderColor,
                                                    style: borderStyle
                                                };
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        else if (borderType === 'border-inside') {
                            for (let bd_r = bd_r1; bd_r <= bd_r2; bd_r++) {
                                if ((typeof cfg['rowhidden'] !== 'undefined') && (cfg['rowhidden'] !== null) &&
                                    (typeof cfg['rowhidden'][bd_r] !== 'undefined') && (cfg['rowhidden'][bd_r] !== null)) {
                                    continue;
                                }
                                for (let bd_c = bd_c1; bd_c <= bd_c2; bd_c++) {
                                    if (bd_r === bd_r1 && bd_c === bd_c1) {
                                        if ((typeof data[bd_r] !== 'undefined') && (data[bd_r] !== null) &&
                                            (this.getObjType(data[bd_r][bd_c]) === 'object') &&
                                            (typeof data[bd_r][bd_c].mc !== 'undefined') && (data[bd_r][bd_c].mc !== null)) {
                                        }
                                        else {
                                            if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                                borderInfoCompute[bd_r + '_' + bd_c] = {};
                                            }
                                            borderInfoCompute[bd_r + '_' + bd_c].r = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                            borderInfoCompute[bd_r + '_' + bd_c].b = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                        }
                                    }
                                    else if (bd_r === bd_r2 && bd_c === bd_c1) {
                                        if ((typeof data[bd_r] !== 'undefined') && (data[bd_r] !== null) &&
                                            this.getObjType(data[bd_r][bd_c]) === 'object' &&
                                            (typeof data[bd_r][bd_c].mc !== 'undefined') && (data[bd_r][bd_c].mc !== null)) {
                                        }
                                        else {
                                            if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                                borderInfoCompute[bd_r + '_' + bd_c] = {};
                                            }
                                            borderInfoCompute[bd_r + '_' + bd_c].r = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                            borderInfoCompute[bd_r + '_' + bd_c].t = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                        }
                                    }
                                    else if (bd_r === bd_r1 && bd_c === bd_c2) {
                                        if ((typeof data[bd_r] !== 'undefined') && (data[bd_r] !== null) &&
                                            (this.getObjType(data[bd_r][bd_c]) === 'object') &&
                                            (typeof data[bd_r][bd_c].mc !== 'undefined') && (data[bd_r][bd_c].mc !== null)) {
                                        }
                                        else {
                                            if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                                borderInfoCompute[bd_r + '_' + bd_c] = {};
                                            }
                                            borderInfoCompute[bd_r + '_' + bd_c].l = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                            borderInfoCompute[bd_r + '_' + bd_c].b = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                        }
                                    }
                                    else if (bd_r === bd_r2 && bd_c === bd_c2) {
                                        if ((typeof data[bd_r] !== 'undefined') && (data[bd_r] !== null) &&
                                            (this.getObjType(data[bd_r][bd_c]) === 'object') &&
                                            (typeof data[bd_r][bd_c].mc !== 'undefined') && (data[bd_r][bd_c].mc !== null)) {
                                        }
                                        else {
                                            if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                                borderInfoCompute[bd_r + '_' + bd_c] = {};
                                            }
                                            borderInfoCompute[bd_r + '_' + bd_c].l = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                            borderInfoCompute[bd_r + '_' + bd_c].t = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                        }
                                    }
                                    else if (bd_r === bd_r1) {
                                        if ((typeof data[bd_r] !== 'undefined') && (data[bd_r] !== null) &&
                                            (this.getObjType(data[bd_r][bd_c]) === 'object') &&
                                            (typeof data[bd_r][bd_c].mc !== 'undefined') && (data[bd_r][bd_c].mc !== null)) {
                                            let cell = data[bd_r][bd_c];
                                            let mc = cfg['merge'][cell.mc.r + '_' + cell.mc.c];
                                            if (mc.c === bd_c) {
                                                if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                                    borderInfoCompute[bd_r + '_' + bd_c] = {};
                                                }
                                                borderInfoCompute[bd_r + '_' + bd_c].l = {
                                                    color: borderColor,
                                                    style: borderStyle
                                                };
                                            }
                                            else if (mc.c + mc.cs - 1 === bd_c) {
                                                if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                                    borderInfoCompute[bd_r + '_' + bd_c] = {};
                                                }
                                                borderInfoCompute[bd_r + '_' + bd_c].r = {
                                                    color: borderColor,
                                                    style: borderStyle
                                                };
                                            }
                                        }
                                        else {
                                            if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                                borderInfoCompute[bd_r + '_' + bd_c] = {};
                                            }
                                            borderInfoCompute[bd_r + '_' + bd_c].l = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                            borderInfoCompute[bd_r + '_' + bd_c].r = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                            borderInfoCompute[bd_r + '_' + bd_c].b = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                        }
                                    }
                                    else if (bd_r === bd_r2) {
                                        if ((typeof data[bd_r] !== 'undefined') && (data[bd_r] !== null) &&
                                            (this.getObjType(data[bd_r][bd_c]) === 'object') &&
                                            (typeof data[bd_r][bd_c].mc !== 'undefined') && (data[bd_r][bd_c].mc !== null)) {
                                            let cell = data[bd_r][bd_c];
                                            let mc = cfg['merge'][cell.mc.r + '_' + cell.mc.c];
                                            if (mc.c === bd_c) {
                                                if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                                    borderInfoCompute[bd_r + '_' + bd_c] = {};
                                                }
                                                borderInfoCompute[bd_r + '_' + bd_c].l = {
                                                    color: borderColor,
                                                    style: borderStyle
                                                };
                                            }
                                            else if (mc.c + mc.cs - 1 === bd_c) {
                                                if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                                    borderInfoCompute[bd_r + '_' + bd_c] = {};
                                                }
                                                borderInfoCompute[bd_r + '_' + bd_c].r = {
                                                    color: borderColor,
                                                    style: borderStyle
                                                };
                                            }
                                        }
                                        else {
                                            if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                                borderInfoCompute[bd_r + '_' + bd_c] = {};
                                            }
                                            borderInfoCompute[bd_r + '_' + bd_c].l = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                            borderInfoCompute[bd_r + '_' + bd_c].r = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                            borderInfoCompute[bd_r + '_' + bd_c].t = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                        }
                                    }
                                    else if (bd_c === bd_c1) {
                                        if ((typeof data[bd_r] !== 'undefined') && (data[bd_r] !== null) &&
                                            (this.getObjType(data[bd_r][bd_c]) === 'object') &&
                                            (typeof data[bd_r][bd_c].mc !== 'undefined') && (data[bd_r][bd_c].mc !== null)) {
                                            let cell = data[bd_r][bd_c];
                                            let mc = cfg['merge'][cell.mc.r + '_' + cell.mc.c];
                                            if (mc.r === bd_r) {
                                                if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                                    borderInfoCompute[bd_r + '_' + bd_c] = {};
                                                }
                                                borderInfoCompute[bd_r + '_' + bd_c].t = {
                                                    color: borderColor,
                                                    style: borderStyle
                                                };
                                            }
                                            else if (mc.r + mc.rs - 1 === bd_r) {
                                                if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                                    borderInfoCompute[bd_r + '_' + bd_c] = {};
                                                }
                                                borderInfoCompute[bd_r + '_' + bd_c].b = {
                                                    color: borderColor,
                                                    style: borderStyle
                                                };
                                            }
                                        }
                                        else {
                                            if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                                borderInfoCompute[bd_r + '_' + bd_c] = {};
                                            }
                                            borderInfoCompute[bd_r + '_' + bd_c].r = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                            borderInfoCompute[bd_r + '_' + bd_c].t = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                            borderInfoCompute[bd_r + '_' + bd_c].b = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                        }
                                    }
                                    else if (bd_c === bd_c2) {
                                        if ((typeof data[bd_r] !== 'undefined') && (data[bd_r] !== null) &&
                                            (this.getObjType(data[bd_r][bd_c]) === 'object') &&
                                            (typeof data[bd_r][bd_c].mc !== 'undefined') && (data[bd_r][bd_c].mc !== null)) {
                                            let cell = data[bd_r][bd_c];
                                            let mc = cfg['merge'][cell.mc.r + '_' + cell.mc.c];
                                            if (mc.r === bd_r) {
                                                if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                                    borderInfoCompute[bd_r + '_' + bd_c] = {};
                                                }
                                                borderInfoCompute[bd_r + '_' + bd_c].t = {
                                                    color: borderColor,
                                                    style: borderStyle
                                                };
                                            }
                                            else if (mc.r + mc.rs - 1 === bd_r) {
                                                if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                                    borderInfoCompute[bd_r + '_' + bd_c] = {};
                                                }
                                                borderInfoCompute[bd_r + '_' + bd_c].b = {
                                                    color: borderColor,
                                                    style: borderStyle
                                                };
                                            }
                                        }
                                        else {
                                            if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                                borderInfoCompute[bd_r + '_' + bd_c] = {};
                                            }
                                            borderInfoCompute[bd_r + '_' + bd_c].l = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                            borderInfoCompute[bd_r + '_' + bd_c].t = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                            borderInfoCompute[bd_r + '_' + bd_c].b = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                        }
                                    }
                                    else {
                                        if ((typeof data[bd_r] !== 'undefined') && (data[bd_r] !== null) &&
                                            (this.getObjType(data[bd_r][bd_c]) === 'object') &&
                                            (typeof data[bd_r][bd_c].mc !== 'undefined') && (data[bd_r][bd_c].mc !== null)) {
                                            let cell = data[bd_r][bd_c];
                                            let mc = cfg['merge'][cell.mc.r + '_' + cell.mc.c];
                                            if (mc.r === bd_r) {
                                                if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                                    borderInfoCompute[bd_r + '_' + bd_c] = {};
                                                }
                                                borderInfoCompute[bd_r + '_' + bd_c].t = {
                                                    color: borderColor,
                                                    style: borderStyle
                                                };
                                            }
                                            else if (mc.r + mc.rs - 1 === bd_r) {
                                                if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                                    borderInfoCompute[bd_r + '_' + bd_c] = {};
                                                }
                                                borderInfoCompute[bd_r + '_' + bd_c].b = {
                                                    color: borderColor,
                                                    style: borderStyle
                                                };
                                            }
                                            if (mc.c === bd_c) {
                                                if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                                    borderInfoCompute[bd_r + '_' + bd_c] = {};
                                                }
                                                borderInfoCompute[bd_r + '_' + bd_c].l = {
                                                    color: borderColor,
                                                    style: borderStyle
                                                };
                                            }
                                            else if (mc.c + mc.cs - 1 === bd_c) {
                                                if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                                    borderInfoCompute[bd_r + '_' + bd_c] = {};
                                                }
                                                borderInfoCompute[bd_r + '_' + bd_c].r = {
                                                    color: borderColor,
                                                    style: borderStyle
                                                };
                                            }
                                        }
                                        else {
                                            if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                                borderInfoCompute[bd_r + '_' + bd_c] = {};
                                            }
                                            borderInfoCompute[bd_r + '_' + bd_c].l = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                            borderInfoCompute[bd_r + '_' + bd_c].r = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                            borderInfoCompute[bd_r + '_' + bd_c].t = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                            borderInfoCompute[bd_r + '_' + bd_c].b = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                        }
                                    }
                                }
                            }
                        }
                        else if (borderType === 'border-horizontal') {
                            for (let bd_r = bd_r1; bd_r <= bd_r2; bd_r++) {
                                if ((typeof cfg['rowhidden'] !== 'undefined') && (cfg['rowhidden'] !== null) &&
                                    (typeof cfg['rowhidden'][bd_r] !== 'undefined') && (cfg['rowhidden'][bd_r] !== null)) {
                                    continue;
                                }
                                for (let bd_c = bd_c1; bd_c <= bd_c2; bd_c++) {
                                    if (bd_r === bd_r1) {
                                        if ((typeof data[bd_r] !== 'undefined') && (data[bd_r] !== null) &&
                                            (this.getObjType(data[bd_r][bd_c]) === 'object') &&
                                            (typeof data[bd_r][bd_c].mc !== 'undefined') && (data[bd_r][bd_c].mc !== null)) {
                                        }
                                        else {
                                            if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                                borderInfoCompute[bd_r + '_' + bd_c] = {};
                                            }
                                            borderInfoCompute[bd_r + '_' + bd_c].b = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                        }
                                    }
                                    else if (bd_r === bd_r2) {
                                        if ((typeof data[bd_r] !== 'undefined') && (data[bd_r] !== null) &&
                                            (this.getObjType(data[bd_r][bd_c]) === 'object') &&
                                            (typeof data[bd_r][bd_c].mc !== 'undefined') && (data[bd_r][bd_c].mc !== null)) {
                                        }
                                        else {
                                            if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                                borderInfoCompute[bd_r + '_' + bd_c] = {};
                                            }
                                            borderInfoCompute[bd_r + '_' + bd_c].t = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                        }
                                    }
                                    else {
                                        if ((typeof data[bd_r] !== 'undefined') && (data[bd_r] !== null) &&
                                            (this.getObjType(data[bd_r][bd_c]) === 'object') &&
                                            (typeof data[bd_r][bd_c].mc !== 'undefined') && (data[bd_r][bd_c].mc !== null)) {
                                            let cell = data[bd_r][bd_c];
                                            let mc = cfg['merge'][cell.mc.r + '_' + cell.mc.c];
                                            if (mc.r === bd_r) {
                                                if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                                    borderInfoCompute[bd_r + '_' + bd_c] = {};
                                                }
                                                borderInfoCompute[bd_r + '_' + bd_c].t = {
                                                    color: borderColor,
                                                    style: borderStyle
                                                };
                                            }
                                            else if (mc.r + mc.rs - 1 === bd_r) {
                                                if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                                    borderInfoCompute[bd_r + '_' + bd_c] = {};
                                                }
                                                borderInfoCompute[bd_r + '_' + bd_c].b = {
                                                    color: borderColor,
                                                    style: borderStyle
                                                };
                                            }
                                        }
                                        else {
                                            if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                                borderInfoCompute[bd_r + '_' + bd_c] = {};
                                            }
                                            borderInfoCompute[bd_r + '_' + bd_c].t = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                            borderInfoCompute[bd_r + '_' + bd_c].b = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                        }
                                    }
                                }
                            }
                        }
                        else if (borderType === 'border-vertical') {
                            for (let bd_r = bd_r1; bd_r <= bd_r2; bd_r++) {
                                if ((typeof cfg['rowhidden'] !== 'undefined') && (cfg['rowhidden'] !== null) &&
                                    (typeof cfg['rowhidden'][bd_r] !== 'undefined') && (cfg['rowhidden'][bd_r] !== null)) {
                                    continue;
                                }
                                for (let bd_c = bd_c1; bd_c <= bd_c2; bd_c++) {
                                    if (bd_c === bd_c1) {
                                        if ((typeof data[bd_r] !== 'undefined') && (data[bd_r] !== null) &&
                                            (this.getObjType(data[bd_r][bd_c]) === 'object') &&
                                            (typeof data[bd_r][bd_c].mc !== 'undefined') && (data[bd_r][bd_c].mc !== null)) {
                                        }
                                        else {
                                            if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                                borderInfoCompute[bd_r + '_' + bd_c] = {};
                                            }
                                            borderInfoCompute[bd_r + '_' + bd_c].r = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                        }
                                    }
                                    else if (bd_c === bd_c2) {
                                        if ((typeof data[bd_r] !== 'undefined') && (data[bd_r] !== null) &&
                                            (this.getObjType(data[bd_r][bd_c]) === 'object') &&
                                            (typeof data[bd_r][bd_c].mc !== 'undefined') && (data[bd_r][bd_c].mc !== null)) {
                                        }
                                        else {
                                            if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                                borderInfoCompute[bd_r + '_' + bd_c] = {};
                                            }
                                            borderInfoCompute[bd_r + '_' + bd_c].l = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                        }
                                    }
                                    else {
                                        if ((typeof data[bd_r] !== 'undefined') && (data[bd_r] !== null) &&
                                            (this.getObjType(data[bd_r][bd_c]) === 'object') &&
                                            (typeof data[bd_r][bd_c].mc !== 'undefined') && (data[bd_r][bd_c].mc !== null)) {
                                            let cell = data[bd_r][bd_c];
                                            let mc = cfg['merge'][cell.mc.r + '_' + cell.mc.c] || {};
                                            if (mc.c === bd_c) {
                                                if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                                    borderInfoCompute[bd_r + '_' + bd_c] = {};
                                                }
                                                borderInfoCompute[bd_r + '_' + bd_c].l = {
                                                    color: borderColor,
                                                    style: borderStyle
                                                };
                                            }
                                            else if (mc.c + mc.cs - 1 === bd_c) {
                                                if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                                    borderInfoCompute[bd_r + '_' + bd_c] = {};
                                                }
                                                borderInfoCompute[bd_r + '_' + bd_c].r = {
                                                    color: borderColor,
                                                    style: borderStyle
                                                };
                                            }
                                        }
                                        else {
                                            if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                                                borderInfoCompute[bd_r + '_' + bd_c] = {};
                                            }
                                            borderInfoCompute[bd_r + '_' + bd_c].l = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                            borderInfoCompute[bd_r + '_' + bd_c].r = {
                                                color: borderColor,
                                                style: borderStyle
                                            };
                                        }
                                    }
                                }
                            }
                        }
                        else if (borderType === 'border-none') {
                            for (let bd_r = bd_r1; bd_r <= bd_r2; bd_r++) {
                                if ((typeof cfg['rowhidden'] !== 'undefined') && (cfg['rowhidden'] !== null) &&
                                    (typeof cfg['rowhidden'][bd_r] !== 'undefined') && (cfg['rowhidden'][bd_r] !== null)) {
                                    continue;
                                }
                                for (let bd_c = bd_c1; bd_c <= bd_c2; bd_c++) {
                                    if ((typeof borderInfoCompute[bd_r + '_' + bd_c] !== 'undefined') &&
                                        (borderInfoCompute[bd_r + '_' + bd_c] !== null)) {
                                        delete borderInfoCompute[bd_r + '_' + bd_c];
                                    }
                                    if (bd_r === bd_r1) {
                                        let bd_r_top = bd_r1 - 1;
                                        if (bd_r_top >= 0 &&
                                            borderInfoCompute[bd_r_top + '_' + bd_c]) {
                                            delete borderInfoCompute[bd_r_top + '_' + bd_c].b;
                                        }
                                    }
                                    if (bd_r === bd_r2) {
                                        let bd_r_bottom = bd_r2 + 1;
                                        if (bd_r_bottom < data.length &&
                                            borderInfoCompute[bd_r_bottom + '_' + bd_c]) {
                                            delete borderInfoCompute[bd_r_bottom + '_' + bd_c].t;
                                        }
                                    }
                                    if (bd_c === bd_c1) {
                                        let bd_c_left = bd_c1 - 1;
                                        if (bd_c_left >= 0 &&
                                            borderInfoCompute[bd_r + '_' + bd_c_left]) {
                                            delete borderInfoCompute[bd_r + '_' + bd_c_left].r;
                                        }
                                    }
                                    if (bd_c === bd_c2) {
                                        let bd_c_right = bd_c2 + 1;
                                        if (bd_c_right < data[0].length &&
                                            borderInfoCompute[bd_r + '_' + bd_c_right]) {
                                            delete borderInfoCompute[bd_r + '_' + bd_c_right].l;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                else if (rangeType === 'cell') {
                    let value = borderInfo[i].value;
                    let bd_r = value.row_index, bd_c = value.col_index;
                    if (bd_r < dataset_row_st ||
                        bd_r > dataset_row_ed ||
                        bd_c < dataset_col_st ||
                        bd_c > dataset_col_ed) {
                        continue;
                    }
                    if ((typeof cfg['rowhidden'] !== 'undefined') && (cfg['rowhidden'] !== null) &&
                        (typeof cfg['rowhidden'][bd_r] !== 'undefined') && (cfg['rowhidden'][bd_r] !== null)) {
                        continue;
                    }
                    if (((typeof value.l !== 'undefined') && (value.l !== null)) ||
                        ((typeof value.r !== 'undefined') && (value.r !== null)) ||
                        ((typeof value.t !== 'undefined') && (value.t !== null)) ||
                        ((typeof value.b !== 'undefined') && (value.b !== null))) {
                        if ((typeof borderInfoCompute[bd_r + '_' + bd_c] === 'undefined') || (borderInfoCompute[bd_r + '_' + bd_c] === null)) {
                            borderInfoCompute[bd_r + '_' + bd_c] = {};
                        }
                        if ((typeof data[bd_r] !== 'undefined') && (data[bd_r] !== null) &&
                            (this.getObjType(data[bd_r][bd_c]) === 'object') &&
                            (typeof data[bd_r][bd_c].mc !== 'undefined') && (data[bd_r][bd_c].mc !== null)) {
                            let cell = data[bd_r][bd_c];
                            let mc = cfg['merge'][cell.mc.r + '_' + cell.mc.c] || {};
                            if ((typeof value.l !== 'undefined') && (value.l !== null) && (bd_c === mc.c)) {
                                borderInfoCompute[bd_r + '_' + bd_c].l = {
                                    color: value.l.color,
                                    style: value.l.style
                                };
                                let bd_c_left = bd_c - 1;
                                if (bd_c_left >= 0 && borderInfoCompute[bd_r + '_' + bd_c_left]) {
                                    if ((typeof data[bd_r] !== 'undefined') && (data[bd_r] !== null) &&
                                        (this.getObjType(data[bd_r][bd_c_left]) === 'object') &&
                                        (typeof data[bd_r][bd_c_left].mc !== 'undefined') && (data[bd_r][bd_c_left].mc !== null)) {
                                        let cell_left = data[bd_r][bd_c_left];
                                        let mc_l = cfg['merge'][cell_left.mc.r + '_' + cell_left.mc.c];
                                        if (mc_l.c + mc_l.cs - 1 === bd_c_left) {
                                            borderInfoCompute[bd_r + '_' + bd_c_left].r = {
                                                color: value.l.color,
                                                style: value.l.style
                                            };
                                        }
                                    }
                                    else {
                                        borderInfoCompute[bd_r + '_' + bd_c_left].r = {
                                            color: value.l.color,
                                            style: value.l.style
                                        };
                                    }
                                }
                            }
                            else {
                                borderInfoCompute[bd_r + '_' + bd_c].l = null;
                            }
                            if ((typeof value.r !== 'undefined') && (value.r !== null) && (bd_c === mc.c + mc.cs - 1)) {
                                borderInfoCompute[bd_r + '_' + bd_c].r = {
                                    color: value.r.color,
                                    style: value.r.style
                                };
                                let bd_c_right = bd_c + 1;
                                if (bd_c_right < data[0].length &&
                                    borderInfoCompute[bd_r + '_' + bd_c_right]) {
                                    if ((typeof data[bd_r] !== 'undefined') && (data[bd_r] !== null) &&
                                        (this.getObjType(data[bd_r][bd_c_right]) === 'object') &&
                                        (typeof data[bd_r][bd_c_right].mc !== 'undefined') && (data[bd_r][bd_c_right].mc !== null)) {
                                        let cell_right = data[bd_r][bd_c_right];
                                        let mc_r = cfg['merge'][cell_right.mc.r + '_' + cell_right.mc.c];
                                        if (mc_r.c === bd_c_right) {
                                            borderInfoCompute[bd_r + '_' + bd_c_right].l = {
                                                color: value.r.color,
                                                style: value.r.style
                                            };
                                        }
                                    }
                                    else {
                                        borderInfoCompute[bd_r + '_' + bd_c_right].l = {
                                            color: value.r.color,
                                            style: value.r.style
                                        };
                                    }
                                }
                            }
                            else {
                                borderInfoCompute[bd_r + '_' + bd_c].r = null;
                            }
                            if ((typeof value.t !== 'undefined') && (value.t !== null) && (bd_r === mc.r)) {
                                borderInfoCompute[bd_r + '_' + bd_c].t = {
                                    color: value.t.color,
                                    style: value.t.style
                                };
                                let bd_r_top = bd_r - 1;
                                if (bd_r_top >= 0 && borderInfoCompute[bd_r_top + '_' + bd_c]) {
                                    if ((typeof data[bd_r_top] !== 'undefined') && (data[bd_r_top] !== null) &&
                                        (this.getObjType(data[bd_r_top][bd_c]) === 'object') &&
                                        (typeof data[bd_r_top][bd_c].mc !== 'undefined') && (data[bd_r_top][bd_c].mc !== null)) {
                                        let cell_top = data[bd_r_top][bd_c];
                                        let mc_t = cfg['merge'][cell_top.mc.r + '_' + cell_top.mc.c];
                                        if (mc_t.r + mc_t.rs - 1 === bd_r_top) {
                                            borderInfoCompute[bd_r_top + '_' + bd_c].b = {
                                                color: value.t.color,
                                                style: value.t.style
                                            };
                                        }
                                    }
                                    else {
                                        borderInfoCompute[bd_r_top + '_' + bd_c].b = {
                                            color: value.t.color,
                                            style: value.t.style
                                        };
                                    }
                                }
                            }
                            else {
                                borderInfoCompute[bd_r + '_' + bd_c].t = null;
                            }
                            if ((typeof value.b !== 'undefined') && (value.b !== null) && (bd_r === mc.r + mc.rs - 1)) {
                                borderInfoCompute[bd_r + '_' + bd_c].b = {
                                    color: value.b.color,
                                    style: value.b.style
                                };
                                let bd_r_bottom = bd_r + 1;
                                if (bd_r_bottom < data.length &&
                                    borderInfoCompute[bd_r_bottom + '_' + bd_c]) {
                                    if ((typeof data[bd_r_bottom] !== 'undefined') && (data[bd_r_bottom] !== null) &&
                                        (this.getObjType(data[bd_r_bottom][bd_c]) === 'object') &&
                                        (typeof data[bd_r_bottom][bd_c].mc !== 'undefined') && (data[bd_r_bottom][bd_c].mc !== null)) {
                                        let cell_bottom = data[bd_r_bottom][bd_c];
                                        let mc_b = cfg['merge'][cell_bottom.mc.r + '_' + cell_bottom.mc.c];
                                        if (mc_b.r === bd_r_bottom) {
                                            borderInfoCompute[bd_r_bottom + '_' + bd_c].t = {
                                                color: value.b.color,
                                                style: value.b.style
                                            };
                                        }
                                    }
                                    else {
                                        borderInfoCompute[bd_r_bottom + '_' + bd_c].t = {
                                            color: value.b.color,
                                            style: value.b.style
                                        };
                                    }
                                }
                            }
                            else {
                                borderInfoCompute[bd_r + '_' + bd_c].b = null;
                            }
                        }
                        else {
                            if ((typeof value.l !== 'undefined') && (value.l !== null)) {
                                borderInfoCompute[bd_r + '_' + bd_c].l = {
                                    color: value.l.color,
                                    style: value.l.style
                                };
                                let bd_c_left = bd_c - 1;
                                if (bd_c_left >= 0 && borderInfoCompute[bd_r + '_' + bd_c_left]) {
                                    if ((typeof data[bd_r] !== 'undefined') && (data[bd_r] !== null) &&
                                        (this.getObjType(data[bd_r][bd_c_left]) === 'object') &&
                                        (typeof data[bd_r][bd_c_left].mc !== 'undefined') && (data[bd_r][bd_c_left].mc !== null)) {
                                        let cell_left = data[bd_r][bd_c_left];
                                        let mc_l = cfg['merge'][cell_left.mc.r + '_' + cell_left.mc.c];
                                        if (mc_l.c + mc_l.cs - 1 === bd_c_left) {
                                            borderInfoCompute[bd_r + '_' + bd_c_left].r = {
                                                color: value.l.color,
                                                style: value.l.style
                                            };
                                        }
                                    }
                                    else {
                                        borderInfoCompute[bd_r + '_' + bd_c_left].r = {
                                            color: value.l.color,
                                            style: value.l.style
                                        };
                                    }
                                }
                            }
                            else {
                                borderInfoCompute[bd_r + '_' + bd_c].l = null;
                            }
                            if ((typeof value.r !== 'undefined') && (value.r !== null)) {
                                borderInfoCompute[bd_r + '_' + bd_c].r = {
                                    color: value.r.color,
                                    style: value.r.style
                                };
                                let bd_c_right = bd_c + 1;
                                if (bd_c_right < data[0].length &&
                                    borderInfoCompute[bd_r + '_' + bd_c_right]) {
                                    if ((typeof data[bd_r] !== 'undefined') && (data[bd_r] !== null) &&
                                        (this.getObjType(data[bd_r][bd_c_right]) === 'object') &&
                                        (typeof data[bd_r][bd_c_right].mc !== 'undefined') && (data[bd_r][bd_c_right].mc !== null)) {
                                        let cell_right = data[bd_r][bd_c_right];
                                        let mc_r = cfg['merge'][cell_right.mc.r + '_' + cell_right.mc.c];
                                        if (mc_r.c === bd_c_right) {
                                            borderInfoCompute[bd_r + '_' + bd_c_right].l = {
                                                color: value.r.color,
                                                style: value.r.style
                                            };
                                        }
                                    }
                                    else {
                                        borderInfoCompute[bd_r + '_' + bd_c_right].l = {
                                            color: value.r.color,
                                            style: value.r.style
                                        };
                                    }
                                }
                            }
                            else {
                                borderInfoCompute[bd_r + '_' + bd_c].r = null;
                            }
                            if ((typeof value.t !== 'undefined') && (value.t !== null)) {
                                borderInfoCompute[bd_r + '_' + bd_c].t = {
                                    color: value.t.color,
                                    style: value.t.style
                                };
                                let bd_r_top = bd_r - 1;
                                if (bd_r_top >= 0 && borderInfoCompute[bd_r_top + '_' + bd_c]) {
                                    if ((typeof data[bd_r_top] !== 'undefined') && (data[bd_r_top] !== null) &&
                                        (this.getObjType(data[bd_r_top][bd_c]) === 'object') &&
                                        (typeof data[bd_r_top][bd_c].mc !== 'undefined') && (data[bd_r_top][bd_c].mc !== null)) {
                                        let cell_top = data[bd_r_top][bd_c];
                                        let mc_t = cfg['merge'][cell_top.mc.r + '_' + cell_top.mc.c];
                                        if (mc_t.r + mc_t.rs - 1 === bd_r_top) {
                                            borderInfoCompute[bd_r_top + '_' + bd_c].b = {
                                                color: value.t.color,
                                                style: value.t.style
                                            };
                                        }
                                    }
                                    else {
                                        borderInfoCompute[bd_r_top + '_' + bd_c].b = {
                                            color: value.t.color,
                                            style: value.t.style
                                        };
                                    }
                                }
                            }
                            else {
                                borderInfoCompute[bd_r + '_' + bd_c].t = null;
                            }
                            if ((typeof value.b !== 'undefined') && (value.b !== null)) {
                                borderInfoCompute[bd_r + '_' + bd_c].b = {
                                    color: value.b.color,
                                    style: value.b.style
                                };
                                let bd_r_bottom = bd_r + 1;
                                if (bd_r_bottom < data.length &&
                                    borderInfoCompute[bd_r_bottom + '_' + bd_c]) {
                                    if ((typeof data[bd_r_bottom] !== 'undefined') && (data[bd_r_bottom] !== null) &&
                                        (this.getObjType(data[bd_r_bottom][bd_c]) === 'object') &&
                                        (typeof data[bd_r_bottom][bd_c].mc !== 'undefined') && (data[bd_r_bottom][bd_c].mc !== null)) {
                                        let cell_bottom = data[bd_r_bottom][bd_c];
                                        let mc_b = cfg['merge'][cell_bottom.mc.r + '_' + cell_bottom.mc.c];
                                        if (mc_b.r === bd_r_bottom) {
                                            borderInfoCompute[bd_r_bottom + '_' + bd_c].t = {
                                                color: value.b.color,
                                                style: value.b.style
                                            };
                                        }
                                    }
                                    else {
                                        borderInfoCompute[bd_r_bottom + '_' + bd_c].t = {
                                            color: value.b.color,
                                            style: value.b.style
                                        };
                                    }
                                }
                            }
                            else {
                                borderInfoCompute[bd_r + '_' + bd_c].b = null;
                            }
                        }
                    }
                    else {
                        delete borderInfoCompute[bd_r + '_' + bd_c];
                    }
                }
            }
        }
        return borderInfoCompute;
    }
    getObjType(obj) {
        if (typeof obj === 'undefined') {
            return 'undefined';
        }
        if (obj === null) {
            return 'null';
        }
        let toString = Object.prototype.toString;
        let map = {
            '[object Boolean]': 'boolean',
            '[object Number]': 'number',
            '[object String]': 'string',
            '[object Function]': 'function',
            '[object Array]': 'array',
            '[object Date]': 'date',
            '[object RegExp]': 'regExp',
            '[object Undefined]': 'undefined',
            '[object Null]': 'null',
            '[object Object]': 'object'
        };
        return map[toString.call(obj)];
    }
    setStyleAndValue(cellArr, sheetConfig, worksheet) {
        if ((typeof cellArr === 'undefined') || (cellArr === null) || !Array.isArray(cellArr)) {
            return;
        }
        cellArr.forEach((row, rowid) => {
            if ((typeof row !== 'undefined') && (row !== null)) {
                row.every((cell, columnid) => {
                    if (!cell) {
                        return true;
                    }
                    let fill = this.fillConvert(cell.bg);
                    let font = this.fontConvert(cell.ff, cell.fc, cell.bl, cell.it, cell.fs, cell.cl, cell.ul);
                    let alignment = this.alignmentConvert(cell.vt, cell.ht, cell.tb, cell.tr);
                    let value = null;
                    var v = '';
                    if (cell.ct && cell.ct.t === 'inlineStr') {
                        var s = cell.ct.s;
                        s.forEach((val, _num) => {
                            v += val.v;
                        });
                    }
                    else if ((typeof cell.ct !== 'undefined') && (cell.ct !== null) && (cell.ct.t === 'd')) {
                        v = new Date(cell.m.replace(/\-/g, '/'));
                    }
                    else if (cell.m && this.isPercentage(cell)) {
                        v = cell.m;
                    }
                    else {
                        v = cell.v;
                    }
                    if (cell.f) {
                        value = { formula: cell.f, result: v };
                    }
                    else {
                        value = v;
                    }
                    let target = worksheet.getCell(rowid + 1, columnid + 1);
                    target.fill = fill;
                    target.font = font;
                    target.alignment = alignment;
                    target.value = value;
                    return true;
                });
            }
        });
        if ((typeof sheetConfig !== 'undefined') && sheetConfig &&
            (typeof sheetConfig.columnlen !== 'undefined') && sheetConfig.columnlen) {
            const columnlenObj = Array.isArray(sheetConfig.columnlen) ? Object.assign({}, sheetConfig.columnlen) : sheetConfig.columnlen;
            for (let key in columnlenObj) {
                if (typeof columnlenObj[key] !== 'undefined') {
                    const colId = (typeof key === 'number') ? key : parseInt(key, 10);
                    const colWidth = ((typeof columnlenObj[key] === 'number') ? columnlenObj[key] : parseFloat(columnlenObj[key]));
                    if ((colWidth !== null) && !isNaN(colWidth)) {
                        const dbCol = worksheet.getColumn(colId + 1);
                        if ((typeof dbCol !== 'undefined') && dbCol) {
                            dbCol.width = (colWidth * 15 / 100);
                        }
                    }
                }
            }
        }
        if ((typeof sheetConfig !== 'undefined') && sheetConfig &&
            (typeof sheetConfig.rowlen !== 'undefined') && sheetConfig.rowlen) {
            const rowlenObj = Array.isArray(sheetConfig.rowlen) ? Object.assign({}, sheetConfig.rowlen) : sheetConfig.rowlen;
            for (let key in rowlenObj) {
                if (typeof rowlenObj[key] !== 'undefined') {
                    const rowId = (typeof key === 'number') ? key : parseInt(key, 10);
                    const rowHeight = ((typeof rowlenObj[key] === 'number') ? rowlenObj[key] : parseFloat(rowlenObj[key]));
                    if ((rowHeight !== null) && !isNaN(rowHeight)) {
                        const dbRow = worksheet.getRow(rowId + 1);
                        if ((typeof dbRow !== 'undefined') && dbRow) {
                            dbRow.height = (rowHeight * 75 / 100);
                        }
                    }
                }
            }
        }
    }
    isPercentage(value) {
        if ((typeof value !== 'undefined') && (value !== null) &&
            (typeof value.m !== 'undefined') && (value.m !== null) &&
            (typeof value.ct !== 'undefined') && (value.ct !== null)) {
            let percentageReg = /%$/;
            return percentageReg.test(value.m) && value.ct && (value.ct.t === 'n');
        }
        return false;
    }
    zero_fill_hex(num, digits) {
        var s = num.toString(16);
        while (s.length < digits) {
            s = '0' + s;
        }
        return s;
    }
    rgb2hex(rgb) {
        if (rgb.charAt(0) === '#') {
            return rgb;
        }
        var ds = rgb.split(/\D+/);
        var decimal = (Number(ds[1]) * 65536) + (Number(ds[2]) * 256) + Number(ds[3]);
        return '#' + this.zero_fill_hex(decimal, 6);
    }
    fillConvert(bg) {
        if (!bg) {
            return null;
        }
        bg = bg.indexOf('rgb') > -1 ? this.rgb2hex(bg) : bg;
        let fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: bg.replace('#', '') }
        };
        return fill;
    }
    fontConvert(ff = 0, fc = '#000000', bl = 0, it = 0, fs = 10, cl = 0, ul = 0) {
        const luckyToExcel = {
            0: 'Times New Roman',
            1: 'Arial',
            2: 'Tahoma',
            3: 'Verdana',
            4: 'Helvetica',
            num2bl: function (num) {
                return ((typeof num === 'undefined') || (num === null) || (num === 0) || (num === '0')) ? false : true;
            }
        };
        let font = {
            name: ((typeof ff !== 'undefined') && ff) ? (((typeof ff === 'number') && (typeof luckyToExcel[ff] !== 'undefined')) ? luckyToExcel[ff] : ff) : luckyToExcel[0],
            family: 1,
            size: fs,
            color: { argb: fc.replace('#', '') },
            bold: luckyToExcel.num2bl(bl),
            italic: luckyToExcel.num2bl(it),
            underline: luckyToExcel.num2bl(ul),
            strike: luckyToExcel.num2bl(cl)
        };
        return font;
    }
    alignmentConvert(vt = 'default', ht = 'default', tb = 'default', tr = 'default') {
        const luckyToExcel = {
            vertical: {
                0: 'middle',
                1: 'top',
                2: 'bottom',
                default: 'top'
            },
            horizontal: {
                0: 'center',
                1: 'left',
                2: 'right',
                default: 'left'
            },
            wrapText: {
                0: false,
                1: false,
                2: true,
                default: false
            },
            textRotation: {
                0: 0,
                1: 45,
                2: -45,
                3: 'vertical',
                4: 90,
                5: -90,
                default: 0
            }
        };
        let alignment = {
            vertical: (((typeof vt !== 'undefined') && (vt !== null) && (vt !== 'default')) ? luckyToExcel.vertical[vt] : 'middle'),
            horizontal: (((typeof ht !== 'undefined') && (ht !== null)) ? luckyToExcel.horizontal[ht] : 'left'),
            wrapText: (((typeof tb !== 'undefined') && (tb !== null)) ? luckyToExcel.wrapText[tb] : false),
            textRotation: (((typeof tr !== 'undefined') && (tr !== null)) ? luckyToExcel.textRotation[tr] : 0)
        };
        return alignment;
    }
    getImagePosition(num, arr) {
        let index = 0;
        let minIndex;
        let maxIndex;
        for (let i = 0; i < arr.length; i++) {
            if (num < arr[i]) {
                index = i;
                break;
            }
        }
        if (index === 0) {
            minIndex = 0;
            maxIndex = 1;
        }
        else if (index === arr.length - 1) {
            minIndex = arr.length - 2;
            maxIndex = arr.length - 1;
        }
        else {
            minIndex = index - 1;
            maxIndex = index;
        }
        let min = arr[minIndex];
        let max = arr[maxIndex];
        let radio = Math.abs((num - min) / (max - min)) + index;
        return radio;
    }
    setImages(table, worksheet, workbook) {
        let { images = null, visibledatacolumn = null, visibledatarow = null } = Object.assign({}, table);
        if (typeof images !== 'object') {
            return;
        }
        for (let key in images) {
            if (typeof images[key] !== 'undefined') {
                const myBase64Image = images[key].src;
                const item = images[key];
                const imageId = workbook.addImage({
                    base64: myBase64Image,
                    extension: 'png'
                });
                const col_st = this.getImagePosition(item.default.left, visibledatacolumn);
                const row_st = this.getImagePosition(item.default.top, visibledatarow);
                worksheet.addImage(imageId, {
                    tl: { col: col_st, row: row_st },
                    ext: { width: item.default.width, height: item.default.height }
                });
            }
        }
    }
}
exports.LuckysheetExportXLSX = LuckysheetExportXLSX;
