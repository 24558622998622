"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OperacaoControleDoencaPomar = void 0;
const Base_1 = require("./Base");
const uuid_1 = require("uuid");
class OperacaoControleDoencaPomar extends Base_1.Base {
    constructor() {
        super();
        this.id = null;
        this.guid = null;
        this.materialGenetico = null;
        this.tipoDoenca = null;
        this.tipoIntervencaoDoenca = null;
        this.produto = null;
        this.quantidadeArvores = null;
        this.contaDepositoSap = null;
        this.centroCustoSap = null;
        this.guid = uuid_1.v4();
        this.status = true;
    }
}
exports.OperacaoControleDoencaPomar = OperacaoControleDoencaPomar;
