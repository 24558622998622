"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProtecaoRegistroCultivarDescritorMorfologico = void 0;
const Base_1 = require("./Base");
class ProtecaoRegistroCultivarDescritorMorfologico extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.controleRegistro = null;
        this.faixaIdade = null;
        this.listaCaracteristicas = [];
    }
}
exports.ProtecaoRegistroCultivarDescritorMorfologico = ProtecaoRegistroCultivarDescritorMorfologico;
ProtecaoRegistroCultivarDescritorMorfologico.FAIXA_4_MESES = '3 e 4 meses de idade';
ProtecaoRegistroCultivarDescritorMorfologico.FAIXA_6_MESES = '6 meses';
ProtecaoRegistroCultivarDescritorMorfologico.FAIXA_1_ANO = '1 ano';
ProtecaoRegistroCultivarDescritorMorfologico.FAIXA_3_ANOS = '2 a 3 anos';
ProtecaoRegistroCultivarDescritorMorfologico.FAIXA_ADULTO = 'Adulto';
ProtecaoRegistroCultivarDescritorMorfologico.FAIXA_5_ANOS = 'Intervenção';
