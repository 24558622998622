"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConferenciaControleManejoTesteCampo = void 0;
class ConferenciaControleManejoTesteCampo {
    constructor() {
        this.id = null;
        this.descricao = null;
    }
    static PENDENTE() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.conferenciaControleManejoTesteCampo_pendente === 'undefined') ||
            (globalThis.globalConstants.conferenciaControleManejoTesteCampo_pendente === null)) {
            let conferenciaControleManejoTesteCampo_pendente = new ConferenciaControleManejoTesteCampo();
            conferenciaControleManejoTesteCampo_pendente.id = 'PENDENTE';
            conferenciaControleManejoTesteCampo_pendente.descricao = '-';
            globalThis.globalConstants.conferenciaControleManejoTesteCampo_pendente = conferenciaControleManejoTesteCampo_pendente;
        }
        return globalThis.globalConstants.conferenciaControleManejoTesteCampo_pendente;
    }
    static OK() {
        if ((typeof globalThis.globalConstants === 'undefined') || (globalThis.globalConstants === null)) {
            globalThis.globalConstants = {};
        }
        if ((typeof globalThis.globalConstants.conferenciaControleManejoTesteCampo_ok === 'undefined') ||
            (globalThis.globalConstants.conferenciaControleManejoTesteCampo_ok === null)) {
            let conferenciaControleManejoTesteCampo_ok = new ConferenciaControleManejoTesteCampo();
            conferenciaControleManejoTesteCampo_ok.id = 'OK';
            conferenciaControleManejoTesteCampo_ok.descricao = 'OK';
            globalThis.globalConstants.conferenciaControleManejoTesteCampo_ok = conferenciaControleManejoTesteCampo_ok;
        }
        return globalThis.globalConstants.conferenciaControleManejoTesteCampo_ok;
    }
    static LISTA() {
        return [
            ConferenciaControleManejoTesteCampo.PENDENTE(),
            ConferenciaControleManejoTesteCampo.OK()
        ];
    }
}
exports.ConferenciaControleManejoTesteCampo = ConferenciaControleManejoTesteCampo;
