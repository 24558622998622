"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PomarBancoConservacaoControleFenologiaUtil = void 0;
const angular = require("angular");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const FenologiaPomar_1 = require("../../../../../modelos/FenologiaPomar");
const GraficoFenologiaPomar_1 = require("../../../../../modelos/GraficoFenologiaPomar");
const EstagioFloralFenologiaPomar_1 = require("../../../../../modelos/EstagioFloralFenologiaPomar");
const DispositivoUtil_1 = require("../../../../../util/DispositivoUtil");
const DataUtil_1 = require("../../../../../util/DataUtil");
const TabelaUtil_1 = require("../../../../../util/TabelaUtil");
const sweetalert2_1 = require("sweetalert2");
class PomarBancoConservacaoControleFenologiaUtil {
    static filtrarFenologiaPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.pomarSelecionado || !ctrl.pomarSelecionado.id) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                ctrl.listaOpcoesFenologiaPomar = yield ctrl.dadosListaFenologiaPomar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.scopeApply();
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static salvarFenologiaPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.fenologiaPomarSelecionado || ctrl.fenologiaPomarVisualizar) {
                    return;
                }
                let listaCamposObrigatorios = '';
                if ((typeof ctrl.fenologiaPomarSelecionado.ehDetalhamentoGrafico !== 'undefined') &&
                    ctrl.fenologiaPomarSelecionado.ehDetalhamentoGrafico) {
                    let temAnoMateriaisGeneticos = false;
                    for (let graficoIdx = 0; graficoIdx < ctrl.fenologiaPomarSelecionado.detalhamentoGraficos.length; graficoIdx++) {
                        const grafico = ctrl.fenologiaPomarSelecionado.detalhamentoGraficos[graficoIdx];
                        if ((typeof grafico.ano !== 'undefined') && grafico.ano) {
                            if (((typeof grafico.materialGeneticoFeminino !== 'undefined') && grafico.materialGeneticoFeminino) ||
                                ((typeof grafico.materialGeneticoMasculino !== 'undefined') && grafico.materialGeneticoMasculino)) {
                                temAnoMateriaisGeneticos = true;
                                break;
                            }
                        }
                    }
                    if (!temAnoMateriaisGeneticos) {
                        listaCamposObrigatorios += '\r\n - <b>Ano</b>' +
                            '\r\n - <b>Material Genético Feminino</b>' +
                            '\r\n - <b>Material Genético Masculino</b>';
                    }
                }
                if (listaCamposObrigatorios && (listaCamposObrigatorios.length > 0)) {
                    let mensagem = 'Campo obrigatório não preenchido!' +
                        listaCamposObrigatorios + '\r\n \r\n' +
                        'Preencha este campo e tente novamente.';
                    if (ctrl.ehCelular) {
                        alert(mensagem.replace(/<b>/g, '').replace(/<\/b>/g, ''));
                    }
                    else {
                        yield sweetalert2_1.default.fire({
                            html: mensagem.replace(/\r\n/g, '<br>\r\n'),
                            icon: 'info',
                            showCancelButton: false,
                            showConfirmButton: true,
                            confirmButtonText: 'Ok'
                        });
                    }
                    return;
                }
                ctrl.modalTratamentoPomar = false;
                ctrl.modalGerarCroquiPomar = false;
                ctrl.modalHistoricoCroquiPomar = false;
                ctrl.modalPlanejamentoPomar = false;
                ctrl.modalControleManejoPomar = false;
                ctrl.modalControleEnxertiaPomar = false;
                ctrl.modalProdutividadePomar = false;
                ctrl.modalPolinizacaoPomar = false;
                ctrl.modalRelatorioPomar = false;
                ctrl.modalAnexoPomar = false;
                ctrl.fenologiaPomarSelecionado.pomar = ctrl.pomarSelecionado;
                if ((typeof ctrl.fenologiaPomarSelecionado.ehDetalhamentoGrafico !== 'undefined') &&
                    ctrl.fenologiaPomarSelecionado.ehDetalhamentoGrafico) {
                    if ((typeof ctrl.fenologiaPomarSelecionado.etapaGrafico !== 'undefined') &&
                        (ctrl.fenologiaPomarSelecionado.etapaGrafico === 2)) {
                        ctrl.modalFenologiaPomar = false;
                        ctrl.ocultarModalFenologiaPomar();
                    }
                    else {
                        CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-dialog > .modal-content');
                        let listaDetalhamentoGraficos = yield ctrl.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.GRAFICO_FENOLOGIA_POMAR)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.GRAFICO_FENOLOGIA_POMAR)
                            .filtro({
                            listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                            pomar: ctrl.pomarSelecionado.id
                        })
                            .listar();
                        if ((typeof listaDetalhamentoGraficos !== 'undefined') && listaDetalhamentoGraficos && (listaDetalhamentoGraficos.length > 0)) {
                            for (let graficoBancoDadosIdx = 0; graficoBancoDadosIdx < listaDetalhamentoGraficos.length; graficoBancoDadosIdx++) {
                                const graficoBancoDados = listaDetalhamentoGraficos[graficoBancoDadosIdx];
                                if (graficoBancoDados && (typeof graficoBancoDados.id !== 'undefined') && graficoBancoDados.id) {
                                    let graficoBancoDadosExcluido = true;
                                    for (let graficoIdx = 0; graficoIdx < ctrl.fenologiaPomarSelecionado.detalhamentoGraficos.length; graficoIdx++) {
                                        const grafico = ctrl.fenologiaPomarSelecionado.detalhamentoGraficos[graficoIdx];
                                        if (grafico && (typeof grafico.id !== 'undefined') && (grafico.id === graficoBancoDados.id)) {
                                            graficoBancoDadosExcluido = false;
                                            break;
                                        }
                                    }
                                    if (graficoBancoDadosExcluido) {
                                        yield ctrl.sincronia
                                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.GRAFICO_FENOLOGIA_POMAR)
                                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.GRAFICO_FENOLOGIA_POMAR)
                                            .filtro({})
                                            .id(graficoBancoDados.id)
                                            .remover(true);
                                    }
                                }
                            }
                        }
                        for (let graficoIdx = 0; graficoIdx < ctrl.fenologiaPomarSelecionado.detalhamentoGraficos.length; graficoIdx++) {
                            ctrl.fenologiaPomarSelecionado.detalhamentoGraficos[graficoIdx].pomar = ctrl.pomarSelecionado;
                            if (!ctrl.fenologiaPomarSelecionado.detalhamentoGraficos[graficoIdx].id) {
                                ctrl.fenologiaPomarSelecionado.detalhamentoGraficos[graficoIdx] = yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.GRAFICO_FENOLOGIA_POMAR)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.GRAFICO_FENOLOGIA_POMAR)
                                    .criar(ctrl.fenologiaPomarSelecionado.detalhamentoGraficos[graficoIdx]);
                            }
                            else {
                                ctrl.fenologiaPomarSelecionado.detalhamentoGraficos[graficoIdx] = yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.GRAFICO_FENOLOGIA_POMAR)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.GRAFICO_FENOLOGIA_POMAR)
                                    .atualizar(ctrl.fenologiaPomarSelecionado.detalhamentoGraficos[graficoIdx]);
                            }
                        }
                        if ((typeof ctrl.fenologiaPomarSelecionado.pomar !== 'undefined') && ctrl.fenologiaPomarSelecionado.pomar &&
                            (typeof ctrl.fenologiaPomarSelecionado.pomar.descricao !== 'undefined') && ctrl.fenologiaPomarSelecionado.pomar.descricao) {
                            ctrl.fenologiaPomarSelecionado.pomarDescricao = ctrl.fenologiaPomarSelecionado.pomar.descricao;
                        }
                        ctrl.listaOpcoesFenologiaPomar = yield ctrl.dadosListaFenologiaPomar('.modal-dialog > .modal-content');
                        let menorData = null;
                        let maiorData = null;
                        let menorAnoGrafico = null;
                        let maiorAnoGrafico = null;
                        if ((typeof ctrl.listaOpcoesFenologiaPomar !== 'undefined') && ctrl.listaOpcoesFenologiaPomar &&
                            (typeof ctrl.listaOpcoesFenologiaPomar.data !== 'undefined') && ctrl.listaOpcoesFenologiaPomar.data) {
                            angular.forEach(ctrl.listaOpcoesFenologiaPomar.data, (fenologia) => {
                                if ((typeof fenologia.materialGenetico !== 'undefined') && fenologia.materialGenetico &&
                                    (typeof fenologia.materialGenetico.id !== 'undefined') && fenologia.materialGenetico.id &&
                                    (typeof fenologia.data !== 'undefined') && fenologia.data) {
                                    let data = null;
                                    if (typeof fenologia.data === 'string') {
                                        data = new Date(fenologia.data);
                                    }
                                    else if (typeof fenologia.data === 'object') {
                                        data = fenologia.data;
                                    }
                                    data.setHours(0, 0, 0, 0);
                                    if (!menorData || (data < menorData)) {
                                        menorData = data;
                                    }
                                    if (!maiorData || (data > maiorData)) {
                                        maiorData = data;
                                    }
                                    for (let graficoIdx = 0; graficoIdx < ctrl.fenologiaPomarSelecionado.detalhamentoGraficos.length; graficoIdx++) {
                                        const grafico = ctrl.fenologiaPomarSelecionado.detalhamentoGraficos[graficoIdx];
                                        if ((typeof grafico.ano !== 'undefined') && grafico.ano && (grafico.ano > 0)) {
                                            if (!menorAnoGrafico || (grafico.ano < menorAnoGrafico)) {
                                                menorAnoGrafico = grafico.ano;
                                            }
                                            if (!maiorAnoGrafico || (grafico.ano > maiorAnoGrafico)) {
                                                maiorAnoGrafico = grafico.ano;
                                            }
                                        }
                                        if ((typeof grafico.materialGeneticoFeminino !== 'undefined') && grafico.materialGeneticoFeminino &&
                                            (typeof grafico.materialGeneticoFeminino.id !== 'undefined') && grafico.materialGeneticoFeminino.id &&
                                            (grafico.materialGeneticoFeminino.id === fenologia.materialGenetico.id)) {
                                            if ((typeof grafico.datasGraficoFeminino === 'undefined') || (grafico.datasGraficoFeminino === null)) {
                                                grafico.datasGraficoFeminino = new Array();
                                            }
                                            grafico.datasGraficoFeminino.push(data);
                                        }
                                        if ((typeof grafico.materialGeneticoMasculino !== 'undefined') && grafico.materialGeneticoMasculino &&
                                            (typeof grafico.materialGeneticoMasculino.id !== 'undefined') && grafico.materialGeneticoMasculino.id &&
                                            (grafico.materialGeneticoMasculino.id === fenologia.materialGenetico.id)) {
                                            if ((typeof grafico.datasGraficoMasculino === 'undefined') || (grafico.datasGraficoMasculino === null)) {
                                                grafico.datasGraficoMasculino = new Array();
                                            }
                                            grafico.datasGraficoMasculino.push(data);
                                        }
                                    }
                                }
                            });
                        }
                        if (!menorData) {
                            menorData = new Date();
                        }
                        if (menorAnoGrafico && (menorAnoGrafico > 0) && (menorData.getFullYear() < menorAnoGrafico)) {
                            menorData = new Date(`${menorAnoGrafico}-01-01T00:00:00-03:00`);
                        }
                        let menorDataComMargem = new Date();
                        menorDataComMargem.setTime(menorData.getTime());
                        menorDataComMargem.setHours(0, 0, 0, 0);
                        menorDataComMargem.setDate(menorDataComMargem.getDate() - 5);
                        if (!maiorData) {
                            maiorData = new Date();
                        }
                        if (maiorAnoGrafico && (maiorAnoGrafico > 0) && (maiorData.getFullYear() > maiorAnoGrafico)) {
                            maiorData = new Date(`${maiorAnoGrafico}-01-01T00:00:00-03:00`);
                        }
                        let maiorDataComMargem = new Date();
                        maiorDataComMargem.setTime(maiorData.getTime());
                        maiorDataComMargem.setHours(0, 0, 0, 0);
                        maiorDataComMargem.setDate(maiorDataComMargem.getDate() + 5);
                        let listaDatas = [];
                        let data = new Date();
                        data.setTime(menorDataComMargem.getTime());
                        data.setHours(0, 0, 0, 0);
                        while (data <= maiorDataComMargem) {
                            let data2 = new Date();
                            data2.setTime(data.getTime());
                            data2.setHours(0, 0, 0, 0);
                            listaDatas.push(data2);
                            data.setDate(data.getDate() + 1);
                        }
                        let listaMesesAnos = [];
                        angular.forEach(listaDatas, (data) => {
                            const mesAno = DataUtil_1.DataUtil.converterDataParaFormatoMMMYYYY(data);
                            if (!listaMesesAnos || (listaMesesAnos.length === 0) || (listaMesesAnos[listaMesesAnos.length - 1].mesAno !== mesAno)) {
                                listaMesesAnos.push({ mesAno: mesAno, dias: 1 });
                            }
                            else {
                                listaMesesAnos[listaMesesAnos.length - 1].dias += 1;
                            }
                        });
                        ctrl.fenologiaPomarSelecionado.grafico = {
                            dataInicial: menorData,
                            dataFinal: maiorData,
                            datas: listaDatas,
                            mesesAnos: listaMesesAnos,
                            cruzamentos: [],
                            html: null
                        };
                        for (let graficoIdx = 0; graficoIdx < ctrl.fenologiaPomarSelecionado.detalhamentoGraficos.length; graficoIdx++) {
                            const grafico = ctrl.fenologiaPomarSelecionado.detalhamentoGraficos[graficoIdx];
                            let cruzamento = {
                                descricao: null,
                                lancamentosFeminino: [],
                                datasFeminino: [],
                                lancamentosMasculino: [],
                                datasMasculino: [],
                                datasEstrobilos: []
                            };
                            if ((typeof grafico.materialGeneticoFeminino !== 'undefined') && grafico.materialGeneticoFeminino &&
                                (typeof grafico.materialGeneticoFeminino.descricao !== 'undefined') && grafico.materialGeneticoFeminino.descricao) {
                                cruzamento.descricao = grafico.materialGeneticoFeminino.descricao.trim();
                            }
                            if ((typeof grafico.materialGeneticoMasculino !== 'undefined') && grafico.materialGeneticoMasculino &&
                                (typeof grafico.materialGeneticoMasculino.descricao !== 'undefined') && grafico.materialGeneticoMasculino.descricao) {
                                cruzamento.descricao = ((cruzamento.descricao && (cruzamento.descricao.trim().length > 0)) ? cruzamento.descricao + 'x' : '') +
                                    grafico.materialGeneticoMasculino.descricao.trim();
                            }
                            if (!cruzamento.descricao || (cruzamento.descricao.trim().length === 0)) {
                                cruzamento.descricao = '(Vazio)';
                            }
                            if ((typeof ctrl.fenologiaPomarSelecionado.grafico.datas !== 'undefined') && ctrl.fenologiaPomarSelecionado.grafico.datas) {
                                cruzamento.datasEstrobilos = [];
                                angular.forEach(ctrl.fenologiaPomarSelecionado.grafico.datas, (data) => {
                                    cruzamento.datasEstrobilos.push({
                                        data: data,
                                        statusFeminimo: false,
                                        statusMasculino: false
                                    });
                                });
                            }
                            if ((typeof grafico.datasGraficoFeminino !== 'undefined') && grafico.datasGraficoFeminino && (grafico.datasGraficoFeminino.length > 0)) {
                                cruzamento.lancamentosFeminino.push(...grafico.datasGraficoFeminino.sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0)));
                                let ultimaData = null;
                                angular.forEach(cruzamento.lancamentosFeminino, (dataLancamentoFeminino) => {
                                    if (ultimaData) {
                                        let data = new Date();
                                        data.setTime(ultimaData.getTime());
                                        data.setDate(data.getDate() + 1);
                                        data.setHours(0, 0, 0, 0);
                                        while (data <= dataLancamentoFeminino) {
                                            let data2 = new Date();
                                            data2.setTime(data.getTime());
                                            data2.setHours(0, 0, 0, 0);
                                            cruzamento.datasFeminino.push(data2);
                                            data.setDate(data.getDate() + 1);
                                        }
                                        ultimaData = null;
                                    }
                                    else {
                                        ultimaData = new Date();
                                        ultimaData.setTime(dataLancamentoFeminino.getTime());
                                        ultimaData.setHours(0, 0, 0, 0);
                                        cruzamento.datasFeminino.push(ultimaData);
                                    }
                                });
                            }
                            if ((typeof cruzamento.datasEstrobilos !== 'undefined') && cruzamento.datasEstrobilos && (cruzamento.datasEstrobilos.length > 0)) {
                                angular.forEach(cruzamento.datasEstrobilos, (dataEstrobilos) => {
                                    if ((typeof dataEstrobilos.data !== 'undefined') && dataEstrobilos.data) {
                                        if (cruzamento.datasFeminino.find(x => x.getTime() === dataEstrobilos.data.getTime())) {
                                            dataEstrobilos.statusFeminimo = true;
                                        }
                                    }
                                });
                            }
                            if ((typeof grafico.datasGraficoMasculino !== 'undefined') && grafico.datasGraficoMasculino && (grafico.datasGraficoMasculino.length > 0)) {
                                cruzamento.lancamentosMasculino.push(...grafico.datasGraficoMasculino.sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0)));
                                let ultimaData = null;
                                angular.forEach(cruzamento.lancamentosMasculino, (dataLancamentoMasculino) => {
                                    if (ultimaData) {
                                        let data = new Date();
                                        data.setTime(ultimaData.getTime());
                                        data.setDate(data.getDate() + 1);
                                        data.setHours(0, 0, 0, 0);
                                        while (data <= dataLancamentoMasculino) {
                                            let data2 = new Date();
                                            data2.setTime(data.getTime());
                                            data2.setHours(0, 0, 0, 0);
                                            cruzamento.datasMasculino.push(data2);
                                            data.setDate(data.getDate() + 1);
                                        }
                                        ultimaData = null;
                                    }
                                    else {
                                        ultimaData = new Date();
                                        ultimaData.setTime(dataLancamentoMasculino.getTime());
                                        ultimaData.setHours(0, 0, 0, 0);
                                        cruzamento.datasMasculino.push(ultimaData);
                                    }
                                });
                            }
                            if ((typeof cruzamento.datasEstrobilos !== 'undefined') && cruzamento.datasEstrobilos && (cruzamento.datasEstrobilos.length > 0)) {
                                angular.forEach(cruzamento.datasEstrobilos, (dataEstrobilos) => {
                                    if ((typeof dataEstrobilos.data !== 'undefined') && dataEstrobilos.data) {
                                        if (cruzamento.datasMasculino.find(x => x.getTime() === dataEstrobilos.data.getTime())) {
                                            dataEstrobilos.statusMasculino = true;
                                        }
                                    }
                                });
                            }
                            ctrl.fenologiaPomarSelecionado.grafico.cruzamentos.push(cruzamento);
                        }
                        yield ctrl.montarGraficoFenologiaPomar();
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        ctrl.fenologiaPomarSelecionado.etapaGrafico = 2;
                        ctrl.scopeApply();
                    }
                }
                else {
                    CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-dialog > .modal-content');
                    if (!ctrl.fenologiaPomarSelecionado.id) {
                        ctrl.fenologiaPomarSelecionado = yield ctrl.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.FENOLOGIA_POMAR)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.FENOLOGIA_POMAR)
                            .criar(ctrl.fenologiaPomarSelecionado);
                    }
                    else {
                        ctrl.fenologiaPomarSelecionado = yield ctrl.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.FENOLOGIA_POMAR)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.FENOLOGIA_POMAR)
                            .atualizar(ctrl.fenologiaPomarSelecionado);
                    }
                    if ((typeof ctrl.fenologiaPomarSelecionado.pomar !== 'undefined') && ctrl.fenologiaPomarSelecionado.pomar &&
                        (typeof ctrl.fenologiaPomarSelecionado.pomar.descricao !== 'undefined') && ctrl.fenologiaPomarSelecionado.pomar.descricao) {
                        ctrl.fenologiaPomarSelecionado.pomarDescricao = ctrl.fenologiaPomarSelecionado.pomar.descricao;
                    }
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    if (ctrl.fenologiaPomarSelecionado && ctrl.fenologiaPomarSelecionado.id) {
                        ctrl.modalFenologiaPomar = false;
                        if (ctrl.ehCelular) {
                            alert(`Salvo com sucesso!`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                title: 'Salvo com sucesso!',
                                text: 'O lançamento foi salvo no banco de dados.',
                                icon: 'success',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        ctrl.listaOpcoesFenologiaPomar = yield ctrl.dadosListaFenologiaPomar();
                    }
                    ctrl.ocultarModalFenologiaPomar();
                }
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static exibirModalFenologiaPomar(ctrl, dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.pomarSelecionado === 'undefined') || !ctrl.pomarSelecionado) {
                return;
            }
            try {
                ctrl.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                ctrl.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                ctrl.fenologiaPomarVisualizar = true;
                if ((typeof dados !== 'undefined') && dados && (typeof dados.id !== 'undefined') && dados.id) {
                    ctrl.fenologiaPomarSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.FENOLOGIA_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.FENOLOGIA_POMAR)
                        .id(dados.id)
                        .ver();
                    if ((typeof ctrl.fenologiaPomarSelecionado === 'undefined') || !ctrl.fenologiaPomarSelecionado) {
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        if (ctrl.ehCelular) {
                            alert(`Registro "${dados.id} não encontrado!`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                text: `Registro "${dados.id}" não encontrado.`,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        return;
                    }
                    if ((typeof ctrl.fenologiaPomarSelecionado.criacao === 'string') && ctrl.fenologiaPomarSelecionado.criacao) {
                        ctrl.fenologiaPomarSelecionado.criacao = new Date(ctrl.fenologiaPomarSelecionado.criacao);
                    }
                    if ((typeof ctrl.fenologiaPomarSelecionado.data === 'string') && ctrl.fenologiaPomarSelecionado.data) {
                        ctrl.fenologiaPomarSelecionado.data = new Date(ctrl.fenologiaPomarSelecionado.data);
                    }
                    if ((typeof ctrl.fenologiaPomarSelecionado.dataHoraInclusao === 'string') && ctrl.fenologiaPomarSelecionado.dataHoraInclusao) {
                        ctrl.fenologiaPomarSelecionado.dataHoraInclusao = new Date(ctrl.fenologiaPomarSelecionado.dataHoraInclusao);
                    }
                    if ((typeof ctrl.fenologiaPomarSelecionado.dataHoraAlteracao === 'string') && ctrl.fenologiaPomarSelecionado.dataHoraAlteracao) {
                        ctrl.fenologiaPomarSelecionado.dataHoraAlteracao = new Date(ctrl.fenologiaPomarSelecionado.dataHoraAlteracao);
                    }
                    if ((typeof ctrl.fenologiaPomarSelecionado.estrobilo !== 'undefined') && ctrl.fenologiaPomarSelecionado.estrobilo &&
                        (typeof ctrl.fenologiaPomarSelecionado.estrobilo.id !== 'undefined') && ctrl.fenologiaPomarSelecionado.estrobilo.id) {
                        if (ctrl.estrobiloFeminino && ctrl.estrobiloFeminino.id && (ctrl.fenologiaPomarSelecionado.estrobilo.id === ctrl.estrobiloFeminino.id)) {
                            ctrl.fenologiaPomarSelecionado.estrobilo = ctrl.estrobiloFeminino;
                        }
                        else if (ctrl.estrobiloMasculino && ctrl.estrobiloMasculino.id && (ctrl.fenologiaPomarSelecionado.estrobilo.id === ctrl.estrobiloMasculino.id)) {
                            ctrl.fenologiaPomarSelecionado.estrobilo = ctrl.estrobiloMasculino;
                        }
                    }
                    if ((typeof dados.visualizar !== 'undefined') && dados.visualizar) {
                        ctrl.fenologiaPomarVisualizar = true;
                    }
                    else if ((typeof dados.editar !== 'undefined') && dados.editar) {
                        ctrl.fenologiaPomarVisualizar = false;
                    }
                    else if ((typeof dados.excluir !== 'undefined') && dados.excluir) {
                        ctrl.notificacao
                            .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                            .then((result) => __awaiter(this, void 0, void 0, function* () {
                            if (result.value) {
                                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.FENOLOGIA_POMAR)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.FENOLOGIA_POMAR)
                                    .id(dados.id)
                                    .remover();
                                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                                ctrl.listaOpcoesFenologiaPomar = yield ctrl.dadosListaFenologiaPomar();
                                ctrl.scopeApply();
                            }
                        }));
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                }
                else {
                    ctrl.fenologiaPomarSelecionado = new FenologiaPomar_1.FenologiaPomar();
                    ctrl.fenologiaPomarSelecionado.status = true;
                    ctrl.fenologiaPomarSelecionado.criacao = new Date();
                    ctrl.fenologiaPomarSelecionado.criacao.setMilliseconds(0);
                    if ((typeof ctrl.listaPomares !== 'undefined') && ctrl.listaPomares &&
                        (typeof ctrl.pomarSelecionado !== 'undefined') && ctrl.pomarSelecionado &&
                        (typeof ctrl.pomarSelecionado.id !== 'undefined') && ctrl.pomarSelecionado.id) {
                        ctrl.fenologiaPomarSelecionado.pomarOrigem = ctrl.listaPomares.find(o => o.id === ctrl.pomarSelecionado.id);
                    }
                    if ((typeof dados !== 'undefined') && dados &&
                        (typeof dados.ehDetalhamentoGrafico !== 'undefined') && dados.ehDetalhamentoGrafico) {
                        ctrl.fenologiaPomarSelecionado.ehDetalhamentoGrafico = true;
                        ctrl.fenologiaPomarSelecionado.detalhamentoGraficos = new Array();
                        let listaDetalhamentoGraficos = yield ctrl.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.GRAFICO_FENOLOGIA_POMAR)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.GRAFICO_FENOLOGIA_POMAR)
                            .filtro({
                            listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                            pomar: ctrl.pomarSelecionado.id
                        })
                            .listar();
                        if ((typeof listaDetalhamentoGraficos !== 'undefined') && listaDetalhamentoGraficos && (listaDetalhamentoGraficos.length > 0)) {
                            const respostaCargaGrafico = yield sweetalert2_1.default.fire({
                                text: 'Deseja carregar os materiais genéticos lançados no último gráfico gerado ?',
                                icon: 'question',
                                showCancelButton: true,
                                confirmButtonText: 'Sim',
                                cancelButtonText: 'Não'
                            });
                            if (respostaCargaGrafico && respostaCargaGrafico.isDismissed &&
                                respostaCargaGrafico.dismiss && (respostaCargaGrafico.dismiss !== sweetalert2_1.default.DismissReason.cancel)) {
                                ctrl.modalFenologiaPomar = false;
                                ctrl.fenologiaPomarSelecionado = null;
                                angular.element('body').css('overflow-y', 'hidden');
                                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                                ctrl.scopeApply();
                                return;
                            }
                            if (respostaCargaGrafico && respostaCargaGrafico.isConfirmed) {
                                angular.forEach(listaDetalhamentoGraficos, (grafico) => {
                                    ctrl.fenologiaPomarSelecionado.detalhamentoGraficos.push(grafico);
                                });
                            }
                        }
                        if (ctrl.fenologiaPomarSelecionado.detalhamentoGraficos.length === 0) {
                            let graficoFenologiaPomar = new GraficoFenologiaPomar_1.GraficoFenologiaPomar();
                            graficoFenologiaPomar.status = true;
                            graficoFenologiaPomar.criacao = new Date();
                            graficoFenologiaPomar.criacao.setMilliseconds(0);
                            ctrl.fenologiaPomarSelecionado.detalhamentoGraficos.push(graficoFenologiaPomar);
                        }
                    }
                    ctrl.fenologiaPomarVisualizar = false;
                }
                if ((typeof ctrl.fenologiaPomarSelecionado.estagiosFlorais === 'undefined') ||
                    (ctrl.fenologiaPomarSelecionado.estagiosFlorais === null) ||
                    (ctrl.fenologiaPomarSelecionado.estagiosFlorais.length === 0)) {
                    ctrl.fenologiaPomarSelecionado.estagiosFlorais = new Array();
                    let estagioFloralFenologia = new EstagioFloralFenologiaPomar_1.EstagioFloralFenologiaPomar();
                    estagioFloralFenologia.status = true;
                    estagioFloralFenologia.criacao = new Date();
                    estagioFloralFenologia.criacao.setMilliseconds(0);
                    ctrl.fenologiaPomarSelecionado.estagiosFlorais.push(estagioFloralFenologia);
                }
                if ((typeof ctrl.fenologiaPomarSelecionado.ehDetalhamentoGrafico !== 'undefined') &&
                    ctrl.fenologiaPomarSelecionado.ehDetalhamentoGrafico) {
                    if ((typeof ctrl.fenologiaPomarSelecionado.detalhamentoGraficos === 'undefined') ||
                        (ctrl.fenologiaPomarSelecionado.detalhamentoGraficos === null) ||
                        (ctrl.fenologiaPomarSelecionado.detalhamentoGraficos.length === 0)) {
                        ctrl.fenologiaPomarSelecionado.detalhamentoGraficos = new Array();
                        let graficoFenologiaPomar = new GraficoFenologiaPomar_1.GraficoFenologiaPomar();
                        graficoFenologiaPomar.status = true;
                        graficoFenologiaPomar.criacao = new Date();
                        graficoFenologiaPomar.criacao.setMilliseconds(0);
                        graficoFenologiaPomar.ano = DataUtil_1.DataUtil.dataHoraAtual().getFullYear();
                        ctrl.fenologiaPomarSelecionado.detalhamentoGraficos.push(graficoFenologiaPomar);
                    }
                }
                ctrl.modalFenologiaPomar = true;
                angular.element('body').css('overflow-y', 'hidden');
                ctrl.listaAnos = new Array();
                if ((typeof ctrl.listaOpcoesFenologiaPomar !== 'undefined') && ctrl.listaOpcoesFenologiaPomar &&
                    (typeof ctrl.listaOpcoesFenologiaPomar.data !== 'undefined') && ctrl.listaOpcoesFenologiaPomar.data) {
                    angular.forEach(ctrl.listaOpcoesFenologiaPomar.data, (fenologiaPomar) => {
                        if ((typeof fenologiaPomar.data !== 'undefined') && fenologiaPomar.data) {
                            if (typeof fenologiaPomar.data === 'string') {
                                let data = new Date(fenologiaPomar.data);
                                if (ctrl.listaAnos.indexOf(data.getFullYear()) === -1) {
                                    ctrl.listaAnos.push(data.getFullYear());
                                }
                            }
                            else {
                                if (ctrl.listaAnos.indexOf(fenologiaPomar.data.getFullYear()) === -1) {
                                    ctrl.listaAnos.push(fenologiaPomar.data.getFullYear());
                                }
                            }
                        }
                    });
                }
                if (ctrl.listaAnos && (ctrl.listaAnos.length > 0)) {
                    let dataAtual = new Date();
                    if (ctrl.listaAnos.indexOf(dataAtual.getFullYear()) === -1) {
                        ctrl.listaAnos.push(dataAtual.getFullYear());
                    }
                }
                else {
                    ctrl.carregarTodosAnos();
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static ocultarModalFenologiaPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.modalFenologiaPomar = false;
            angular.element('body').css('overflow-y', '');
            ctrl.carregarTodosAnos();
            globalThis.posicaoTopoAntesModal = ctrl.posicaoTopoAntesModal;
            ctrl.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                        (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    static adicionarRegistroFenologiaPomar(ctrl, ehDetalhamentoGrafico = false) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.fenologiaPomarSelecionado) {
                return;
            }
            if (ehDetalhamentoGrafico) {
                ctrl.fenologiaPomarSelecionado.ehDetalhamentoGrafico = true;
                if ((typeof ctrl.fenologiaPomarSelecionado.detalhamentoGraficos === 'undefined') ||
                    (ctrl.fenologiaPomarSelecionado.detalhamentoGraficos === null)) {
                    ctrl.fenologiaPomarSelecionado.detalhamentoGraficos = new Array();
                }
                let graficoFenologiaPomar = new GraficoFenologiaPomar_1.GraficoFenologiaPomar();
                graficoFenologiaPomar.status = true;
                graficoFenologiaPomar.criacao = new Date();
                graficoFenologiaPomar.criacao.setMilliseconds(0);
                graficoFenologiaPomar.ano = DataUtil_1.DataUtil.dataHoraAtual().getFullYear();
                ctrl.fenologiaPomarSelecionado.detalhamentoGraficos.push(graficoFenologiaPomar);
            }
            else {
                if ((typeof ctrl.fenologiaPomarSelecionado.estagiosFlorais === 'undefined') ||
                    (ctrl.fenologiaPomarSelecionado.estagiosFlorais === null)) {
                    ctrl.fenologiaPomarSelecionado.estagiosFlorais = new Array();
                }
                let estagioFloralFenologia = new EstagioFloralFenologiaPomar_1.EstagioFloralFenologiaPomar();
                estagioFloralFenologia.status = true;
                estagioFloralFenologia.criacao = new Date();
                estagioFloralFenologia.criacao.setMilliseconds(0);
                ctrl.fenologiaPomarSelecionado.estagiosFlorais.push(estagioFloralFenologia);
            }
            yield ctrl.totalizarFenologiaPomar();
            ctrl.scopeApply();
        });
    }
    static removerRegistroFenologiaPomar(ctrl, keyRegistro, ehDetalhamentoGrafico = false) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.fenologiaPomarSelecionado) {
                return;
            }
            if (ehDetalhamentoGrafico) {
                if ((typeof ctrl.fenologiaPomarSelecionado.detalhamentoGraficos === 'undefined') ||
                    (ctrl.fenologiaPomarSelecionado.detalhamentoGraficos === null)) {
                    ctrl.fenologiaPomarSelecionado.detalhamentoGraficos = new Array();
                }
                if ((typeof keyRegistro !== 'undefined') && (keyRegistro !== null) &&
                    (ctrl.fenologiaPomarSelecionado.detalhamentoGraficos.length > 0) &&
                    (keyRegistro <= ctrl.fenologiaPomarSelecionado.detalhamentoGraficos.length - 1)) {
                    const numRegistro = keyRegistro + 1;
                    if (window.confirm('Deseja realmente remover esse registro #' + numRegistro + ' ?')) {
                        ctrl.fenologiaPomarSelecionado.detalhamentoGraficos.splice(keyRegistro, 1);
                        yield ctrl.totalizarFenologiaPomar();
                        ctrl.scopeApply();
                    }
                }
            }
            else {
                if ((typeof ctrl.fenologiaPomarSelecionado.estagiosFlorais === 'undefined') ||
                    (ctrl.fenologiaPomarSelecionado.estagiosFlorais === null)) {
                    ctrl.fenologiaPomarSelecionado.estagiosFlorais = new Array();
                }
                if ((typeof keyRegistro !== 'undefined') && (keyRegistro !== null) &&
                    (ctrl.fenologiaPomarSelecionado.estagiosFlorais.length > 0) &&
                    (keyRegistro <= ctrl.fenologiaPomarSelecionado.estagiosFlorais.length - 1)) {
                    const numRegistro = keyRegistro + 1;
                    if (window.confirm('Deseja realmente remover esse registro #' + numRegistro + ' ?')) {
                        ctrl.fenologiaPomarSelecionado.estagiosFlorais.splice(keyRegistro, 1);
                        yield ctrl.totalizarFenologiaPomar();
                        ctrl.scopeApply();
                    }
                }
            }
        });
    }
    static moverRegistroFenologiaPomarParaCima(ctrl, keyRegistro, ehDetalhamentoGrafico = false) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.fenologiaPomarSelecionado) {
                return;
            }
            if (ehDetalhamentoGrafico) {
                if ((typeof ctrl.fenologiaPomarSelecionado.detalhamentoGraficos === 'undefined') ||
                    (ctrl.fenologiaPomarSelecionado.detalhamentoGraficos === null)) {
                    ctrl.fenologiaPomarSelecionado.detalhamentoGraficos = new Array();
                }
                if ((keyRegistro !== null) && (keyRegistro > 0) && (keyRegistro <= ctrl.fenologiaPomarSelecionado.detalhamentoGraficos.length - 1)) {
                    let oldKey = keyRegistro;
                    let newKey = keyRegistro - 1;
                    ctrl.fenologiaPomarSelecionado.detalhamentoGraficos.splice(newKey, 0, ctrl.fenologiaPomarSelecionado.detalhamentoGraficos.splice(oldKey, 1)[0]);
                    ctrl.scopeApply();
                }
            }
            else {
                if ((typeof ctrl.fenologiaPomarSelecionado.estagiosFlorais === 'undefined') ||
                    (ctrl.fenologiaPomarSelecionado.estagiosFlorais === null)) {
                    ctrl.fenologiaPomarSelecionado.estagiosFlorais = new Array();
                }
                if ((keyRegistro !== null) && (keyRegistro > 0) && (keyRegistro <= ctrl.fenologiaPomarSelecionado.estagiosFlorais.length - 1)) {
                    let oldKey = keyRegistro;
                    let newKey = keyRegistro - 1;
                    ctrl.fenologiaPomarSelecionado.estagiosFlorais.splice(newKey, 0, ctrl.fenologiaPomarSelecionado.estagiosFlorais.splice(oldKey, 1)[0]);
                    ctrl.scopeApply();
                }
            }
        });
    }
    static moverRegistroFenologiaPomarParaBaixo(ctrl, keyRegistro, ehDetalhamentoGrafico = false) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.fenologiaPomarSelecionado) {
                return;
            }
            if (ehDetalhamentoGrafico) {
                if ((typeof ctrl.fenologiaPomarSelecionado.detalhamentoGraficos === 'undefined') ||
                    (ctrl.fenologiaPomarSelecionado.detalhamentoGraficos === null)) {
                    ctrl.fenologiaPomarSelecionado.detalhamentoGraficos = new Array();
                }
                if ((keyRegistro != null) && (keyRegistro >= 0) && (keyRegistro < ctrl.fenologiaPomarSelecionado.detalhamentoGraficos.length - 1)) {
                    let oldKey = keyRegistro;
                    let newKey = keyRegistro + 1;
                    ctrl.fenologiaPomarSelecionado.detalhamentoGraficos.splice(newKey, 0, ctrl.fenologiaPomarSelecionado.detalhamentoGraficos.splice(oldKey, 1)[0]);
                    ctrl.scopeApply();
                }
            }
            else {
                if ((typeof ctrl.fenologiaPomarSelecionado.estagiosFlorais === 'undefined') ||
                    (ctrl.fenologiaPomarSelecionado.estagiosFlorais === null)) {
                    ctrl.fenologiaPomarSelecionado.estagiosFlorais = new Array();
                }
                if ((keyRegistro != null) && (keyRegistro >= 0) && (keyRegistro < ctrl.fenologiaPomarSelecionado.estagiosFlorais.length - 1)) {
                    let oldKey = keyRegistro;
                    let newKey = keyRegistro + 1;
                    ctrl.fenologiaPomarSelecionado.estagiosFlorais.splice(newKey, 0, ctrl.fenologiaPomarSelecionado.estagiosFlorais.splice(oldKey, 1)[0]);
                    ctrl.scopeApply();
                }
            }
        });
    }
    static totalizarFenologiaPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.fenologiaPomarSelecionado !== 'undefined') && ctrl.fenologiaPomarSelecionado) {
            }
        });
    }
    static montarGraficoFenologiaPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.pomarSelecionado || !ctrl.pomarSelecionado.id ||
                !ctrl.fenologiaPomarSelecionado || !ctrl.fenologiaPomarSelecionado.grafico) {
                return;
            }
            if ((typeof ctrl.fenologiaPomarSelecionado.grafico.datas === 'undefined') || !ctrl.fenologiaPomarSelecionado.grafico.datas) {
                ctrl.fenologiaPomarSelecionado.grafico.datas = [];
            }
            if ((typeof ctrl.fenologiaPomarSelecionado.grafico.mesesAnos === 'undefined') || !ctrl.fenologiaPomarSelecionado.grafico.mesesAnos) {
                ctrl.fenologiaPomarSelecionado.grafico.mesesAnos = [];
            }
            if ((typeof ctrl.fenologiaPomarSelecionado.grafico.cruzamentos === 'undefined') || !ctrl.fenologiaPomarSelecionado.grafico.cruzamentos) {
                ctrl.fenologiaPomarSelecionado.grafico.cruzamentos = [];
            }
            const colWidthDia = ',3';
            let colsWidth = '13,10' + colWidthDia.repeat(ctrl.fenologiaPomarSelecionado.grafico.datas.length);
            let thMesesAnos = '';
            angular.forEach(ctrl.fenologiaPomarSelecionado.grafico.mesesAnos, (itemMesAno) => {
                const ehUltimoMesAno = (ctrl.fenologiaPomarSelecionado.grafico.mesesAnos[ctrl.fenologiaPomarSelecionado.grafico.mesesAnos.length - 1].mesAno === itemMesAno.mesAno);
                thMesesAnos += `
                <th class="text-center" data-a-h="center" data-a-v="middle" colspan="${itemMesAno.dias}"
                    data-fill-color="FFFFFF" data-b-t-s="thin" data-b-t-c="2A2B2C" data-b-l-s="thin" data-b-l-c="2A2B2C"` +
                    (ehUltimoMesAno ? ' data-b-r-s="thin" data-b-r-c="2A2B2C" ' : '') + `
                    style="margin: 0px !important; padding: 2px 4px !important; ` +
                    `border: none !important; border-bottom: 2px solid rgb(222,224,226) !important; border-left: 2px solid rgb(222,224,226) !important;">
                    ${itemMesAno.mesAno}
                </th>`;
            });
            let thDatas = '';
            angular.forEach(ctrl.fenologiaPomarSelecionado.grafico.datas, (data) => {
                const dia = ((data.getDate() >= 10) ? '' : '0') + `${data.getDate()}`;
                const ehPrimeiroDia = (ctrl.fenologiaPomarSelecionado.grafico.datas[0] === data);
                const ehUltimoDia = (ctrl.fenologiaPomarSelecionado.grafico.datas[ctrl.fenologiaPomarSelecionado.grafico.datas.length - 1] === data);
                thDatas += `
                <th class="text-center" data-a-h="center" data-a-v="middle"
                    data-fill-color="FFFFFF" data-b-b-s="thin" data-b-b-c="2A2B2C"` +
                    ((data.getDate() === 1) || ehPrimeiroDia ? ' data-b-l-s="thin" data-b-l-c="2A2B2C" ' : '') +
                    (ehUltimoDia ? ' data-b-r-s="thin" data-b-r-c="2A2B2C" ' : '') + `
                    style="margin: 0px !important; padding: 2px 4px !important; ` +
                    `border: none !important; border-bottom: 2px solid rgb(222,224,226) !important; ` +
                    ((data.getDate() === 1) || ehPrimeiroDia ? ' border-left: 2px solid rgb(222,224,226) !important;' : '') + `">
                    ${dia}
                </th>`;
            });
            let dadosTabela = '';
            angular.forEach(ctrl.fenologiaPomarSelecionado.grafico.cruzamentos, (cruzamento) => {
                if ((typeof cruzamento.datasEstrobilos === 'undefined') || !cruzamento.datasEstrobilos) {
                    cruzamento.datasEstrobilos = [];
                }
                let tdLinha1EstrobiloDias = '';
                let tdLinha2Dias = '';
                let tdLinha3EstrobiloDias = '';
                let tdLinha4Dias = '';
                let tdLinha5EstrobiloDias = '';
                angular.forEach([null, ...cruzamento.datasEstrobilos], (dataEstrobilos) => {
                    const estiloPadraoLinha = 'margin: 0px !important; padding: 0px !important; border: none !important;';
                    const ehUltimoDia = dataEstrobilos && dataEstrobilos.data && (cruzamento.datasEstrobilos[cruzamento.datasEstrobilos.length - 1].data === dataEstrobilos.data);
                    tdLinha1EstrobiloDias += `
                    <td class="text-center" data-a-h="center" data-a-v="middle" data-fill-color="FFFFFF"` +
                        (ehUltimoDia ? ' data-b-r-s="thin" data-b-r-c="2A2B2C" ' : '') + `
                        style="${estiloPadraoLinha} height: 4px !important; min-height: 4px !important; max-height: 4px !important; ` +
                        (!dataEstrobilos || !dataEstrobilos.data ? 'position: sticky !important; left: 82px !important; background: #F8F8F8 !important;' : '') + `">
                    </td>`;
                    if (dataEstrobilos && dataEstrobilos.data) {
                        tdLinha2Dias += `
                        <td class="text-center" data-a-h="center" data-a-v="middle" style="${estiloPadraoLinha}` +
                            (dataEstrobilos.statusFeminimo ? ' background: linear-gradient(to bottom, transparent 0% 40%, #F18438 40% 100%) !important;' : '') + `"
                            data-fill-color="` + (dataEstrobilos.statusFeminimo ? 'F18438' : 'FFFFFF') + `"` +
                            (ehUltimoDia ? ' data-b-r-s="thin" data-b-r-c="2A2B2C" ' : '') + `>
                        </td>`;
                    }
                    tdLinha3EstrobiloDias += `
                    <td class="text-center" data-a-h="center" data-a-v="middle" data-fill-color="DEE0E2"` +
                        (ehUltimoDia ? ' data-b-r-s="thin" data-b-r-c="2A2B2C" ' : '') + `
                        style="${estiloPadraoLinha} background: #DEE0E2 !important; height: 1px !important; min-height: 1px !important; max-height: 1px !important; ` +
                        (!dataEstrobilos || !dataEstrobilos.data ? 'position: sticky !important; left: 82px !important; background: #F8F8F8 !important;' : '') + `">
                    </td>`;
                    if (dataEstrobilos && dataEstrobilos.data) {
                        tdLinha4Dias += `
                        <td class="text-center" data-a-h="center" data-a-v="middle" style="${estiloPadraoLinha}` +
                            (dataEstrobilos.statusMasculino ? ' background: linear-gradient(to bottom, #325599 0% 60%, transparent 60% 100%) !important;' : '') + `"
                            data-fill-color="` + (dataEstrobilos.statusMasculino ? '325599' : 'FFFFFF') + `"` +
                            (ehUltimoDia ? ' data-b-r-s="thin" data-b-r-c="2A2B2C" ' : '') + `>
                        </td>`;
                    }
                    tdLinha5EstrobiloDias += `
                    <td class="text-center" data-a-h="center" data-a-v="middle"
                        data-fill-color="FFFFFF" data-b-b-s="thin" data-b-b-c="2A2B2C"` +
                        (ehUltimoDia ? ' data-b-r-s="thin" data-b-r-c="2A2B2C" ' : '') + `
                        style="${estiloPadraoLinha} border-bottom: 2px solid #DFE3E7 !important; height: 4px !important; min-height: 4px !important; max-height: 4px !important; ` +
                        (!dataEstrobilos || !dataEstrobilos.data ? 'position: sticky !important; left: 82px !important; background: #F8F8F8 !important;' : '') + `">
                    </td>`;
                });
                dadosTabela += `
                <!-- Cruzamento: ${cruzamento.descricao} | Linha: 1. Linha em branco -->
                <tr data-height="4">
                    <td class="text-center" rowspan="5" data-a-h="center" data-a-v="middle"
                        data-fill-color="FFFFFF" data-b-b-s="thin" data-b-b-c="2A2B2C" data-b-l-s="thin" data-b-l-c="2A2B2C"
                        style="margin: 0px !important; padding: 2px 4px !important; border: none !important; border-bottom: 2px solid #DFE3E7 !important; ` +
                    `position: sticky !important; left: 0 !important; background: #F8F8F8 !important;">
                        ${cruzamento.descricao}
                    </td>
                    ${tdLinha1EstrobiloDias}
                </tr>

                <!-- Cruzamento: ${cruzamento.descricao} | Linha: 2. Estróbilo feminino -->
                <tr>
                    <td class="text-center" data-a-h="center" data-a-v="middle" data-fill-color="FFFFFF"
                        style="margin: 0px !important; padding: 1px 4px !important; border: none !important; ` +
                    `position: sticky !important; left: 82px !important; background: #F8F8F8 !important;">
                        Fem.
                    </td>
                    ${tdLinha2Dias}
                </tr>

                <!-- Cruzamento: ${cruzamento.descricao} | Linha: 3. Separador -->
                <tr data-height="1">
                    ${tdLinha3EstrobiloDias}
                </tr>

                <!-- Cruzamento: ${cruzamento.descricao} | Linha: 4. Estróbilo masculino -->
                <tr>
                    <td class="text-center" data-a-h="center" data-a-v="middle" data-fill-color="FFFFFF"
                        style="margin: 0px !important; padding: 1px 4px !important; border: none !important; ` +
                    `position: sticky !important; left: 82px !important; background: #F8F8F8 !important;">
                        Masc.
                    </td>
                    ${tdLinha4Dias}
                </tr>

                <!-- Cruzamento: ${cruzamento.descricao} | Linha: 5. Linha em branco -->
                <tr data-height="4">
                    ${tdLinha5EstrobiloDias}
                </tr>
            `;
            });
            ctrl.fenologiaPomarSelecionado.grafico.html = `
            <table class="table table-hover" id="graficoFenologiaPomar" data-cols-width="${colsWidth}">
                <thead>
                    <tr style="background: #F0F3FA !important;">
                        <th class="text-center" rowspan="2" style="margin: 0px !important; padding: .5rem !important; ` +
                `position: sticky !important; left: 0 !important; background: #F0F3FA !important; ` +
                `border: none !important; border-bottom: 2px solid rgb(222,224,226) !important;"
                            data-a-h="center" data-a-v="middle" data-fill-color="FFFFFF"
                            data-b-t-s="thin" data-b-t-c="2A2B2C" data-b-b-s="thin" data-b-b-c="2A2B2C" data-b-l-s="thin" data-b-l-c="2A2B2C">
                            Cruzamento
                        </th>
                        <th class="text-center" rowspan="2" style="margin: 0px !important; padding: .5rem 1rem .5rem .5rem !important; ` +
                `position: sticky !important; left: 82px !important; background: #F0F3FA !important; ` +
                `border: none !important; border-bottom: 2px solid rgb(222,224,226) !important; border-left: 2px solid rgb(222,224,226) !important;"
                            data-a-h="center" data-a-v="middle" data-fill-color="FFFFFF"
                            data-b-t-s="thin" data-b-t-c="2A2B2C" data-b-b-s="thin" data-b-b-c="2A2B2C" data-b-l-s="thin" data-b-l-c="2A2B2C">
                            Estróbilo
                        </th>
                        ${thMesesAnos}
                    </tr>
                    <tr style="background: #F0F3FA !important;">
                        ${thDatas}
                    </tr>
                </thead>
                <tbody>
                    ${dadosTabela}
                </tbody>
            </table>
        `;
            angular.element('#graficoFenologiaPomar-container').empty();
            for (let tentativa = 1; tentativa <= 25; tentativa++) {
                setTimeout((ctrl) => {
                    const container = angular.element('#graficoFenologiaPomar-container');
                    if (container && (container.length > 0)) {
                        if (container.find('table#graficoFenologiaPomar').length === 0) {
                            container.empty().append(ctrl.fenologiaPomarSelecionado.grafico.html);
                        }
                    }
                }, tentativa * 500, ctrl);
            }
        });
    }
    static exportarGraficoFenologiaPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.pomarSelecionado || !ctrl.pomarSelecionado.id) {
                return;
            }
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
            try {
                TabelaUtil_1.TabelaUtil.exportarParaExcel('graficoFenologiaPomar', 'Grafico Fenologia Pomar');
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
}
exports.PomarBancoConservacaoControleFenologiaUtil = PomarBancoConservacaoControleFenologiaUtil;
