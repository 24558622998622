"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PomarBancoConservacaoController = void 0;
const angular = require("angular");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const AbstratoController_1 = require("../../../app/AbstratoController");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const ICRUDOperacao_1 = require("../../../app/interfaces/ICRUDOperacao");
const PomarBancoConservacao_1 = require("../../../../../modelos/PomarBancoConservacao");
const config_app_1 = require("../../../../../config/config.app");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const MaterialGenetico_1 = require("../../../../../modelos/MaterialGenetico");
const Regiao_1 = require("../../../../../modelos/Regiao");
const Talhao_1 = require("../../../../../modelos/Talhao");
const Especie_1 = require("../../../../../modelos/Especie");
const Geracao_1 = require("../../../../../modelos/Geracao");
const Genero_1 = require("../../../../../modelos/Genero");
const Pais_1 = require("../../../../../modelos/Pais");
const ProvinciaUf_1 = require("../../../../../modelos/ProvinciaUf");
const ClassificacaoSolo_1 = require("../../../../../modelos/ClassificacaoSolo");
const UnidadeManejo_1 = require("../../../../../modelos/UnidadeManejo");
const TipoDelineamento_1 = require("../../../../../modelos/TipoDelineamento");
const DisposicaoIndividuos_1 = require("../../../../../modelos/DisposicaoIndividuos");
const ClassificacaoClimatica_1 = require("../../../../../modelos/ClassificacaoClimatica");
const PerfilClimaticoPomar_1 = require("../../../../../modelos/PerfilClimaticoPomar");
const TratamentoPomar_1 = require("../../../../../modelos/TratamentoPomar");
const MapaPomar_1 = require("../../../../../modelos/MapaPomar");
const PlanejamentoPomar_1 = require("../../../../../modelos/PlanejamentoPomar");
const TipoEnxertoPomar_1 = require("../../../../../modelos/TipoEnxertoPomar");
const TipoFenologiaPomar_1 = require("../../../../../modelos/TipoFenologiaPomar");
const TipoSobrevivenciaEnxertoPomar_1 = require("../../../../../modelos/TipoSobrevivenciaEnxertoPomar");
const PolinizacaoPomar_1 = require("../../../../../modelos/PolinizacaoPomar");
const TipoPolinizacaoPomar_1 = require("../../../../../modelos/TipoPolinizacaoPomar");
const TipoPlanejamentoPolinizacaoPomar_1 = require("../../../../../modelos/TipoPlanejamentoPolinizacaoPomar");
const TipoRelatorioPomar_1 = require("../../../../../modelos/TipoRelatorioPomar");
const FaseAvaliadaFenologiaPomar_1 = require("../../../../../modelos/FaseAvaliadaFenologiaPomar");
const NumeroUtil_1 = require("../../../../../util/NumeroUtil");
const DispositivoUtil_1 = require("../../../../../util/DispositivoUtil");
const DataUtil_1 = require("../../../../../util/DataUtil");
const TextoUtil_1 = require("../../../../../util/TextoUtil");
const ArmazenamentoUtil_1 = require("../../../../../util/ArmazenamentoUtil");
const CategoriaPomar_1 = require("../../../../../modelos/CategoriaPomar");
const TipoPomar_1 = require("../../../../../modelos/TipoPomar");
const Situacao_1 = require("../../../../../modelos/Situacao");
const TipoNaturalPlantada_1 = require("../../../../../modelos/TipoNaturalPlantada");
const TipoImplantacao_1 = require("../../../../../modelos/TipoImplantacao");
const Fonte_1 = require("../../../../../modelos/Fonte");
const OperacaoManejoPomar_1 = require("../../../../../modelos/OperacaoManejoPomar");
const ProdutoFertilizante_1 = require("../../../../../modelos/ProdutoFertilizante");
const Estrobilo_1 = require("../../../../../modelos/Estrobilo");
const MonitoramentoFloracaoPolinizacaoPomar_1 = require("../../../../../modelos/MonitoramentoFloracaoPolinizacaoPomar");
const LancamentoPolinizacaoPomar_1 = require("../../../../../modelos/LancamentoPolinizacaoPomar");
const ColetaConesSementesPolinizacaoPomar_1 = require("../../../../../modelos/ColetaConesSementesPolinizacaoPomar");
const BeneficiamentoPolinizacaoPomar_1 = require("../../../../../modelos/BeneficiamentoPolinizacaoPomar");
const FiltroPlanejamentoPomar_1 = require("../../../../../io/FiltroPlanejamentoPomar");
const FiltroControleManejoPomar_1 = require("../../../../../io/FiltroControleManejoPomar");
const FiltroControleEnxertiaPomar_1 = require("../../../../../io/FiltroControleEnxertiaPomar");
const FiltroProdutividadePomar_1 = require("../../../../../io/FiltroProdutividadePomar");
const FiltroFenologiaPomar_1 = require("../../../../../io/FiltroFenologiaPomar");
const FiltroPolinizacaoPomar_1 = require("../../../../../io/FiltroPolinizacaoPomar");
const RelatorioPomar_1 = require("../../../../../modelos/RelatorioPomar");
const AnexoPomar_1 = require("../../../../../modelos/AnexoPomar");
const CampoIndicadorFotos_1 = require("../../../app/componentes/campo-indicador/CampoIndicadorFotos");
const LuckysheetUtil_1 = require("../../../../../util/LuckysheetUtil");
const PolinizacaoPomarSimplificado_1 = require("../../../../../modelos/PolinizacaoPomarSimplificado");
const RelatorioMaterialGeneticoPomar_1 = require("../../../../../modelos/RelatorioMaterialGeneticoPomar");
const RelatorioEnxertiaPomar_1 = require("../../../../../modelos/RelatorioEnxertiaPomar");
const RelatorioPomaresSementesBancosConservacao_1 = require("../../../../../modelos/RelatorioPomaresSementesBancosConservacao");
const RelatorioPolinizacaoColetaSementes_1 = require("../../../../../modelos/RelatorioPolinizacaoColetaSementes");
const ImportaShapefile_1 = require("../../../../../modelos/ImportaShapefile");
const PomarBancoConservacaoDadosListaUtil_1 = require("./PomarBancoConservacaoDadosListaUtil");
const PomarBancoConservacaoCroquiUtil_1 = require("./PomarBancoConservacaoCroquiUtil");
const PomarBancoConservacaoControleManejoUtil_1 = require("./PomarBancoConservacaoControleManejoUtil");
const PomarBancoConservacaoControleEnxertiaUtil_1 = require("./PomarBancoConservacaoControleEnxertiaUtil");
const PomarBancoConservacaoControleProdutividadeUtil_1 = require("./PomarBancoConservacaoControleProdutividadeUtil");
const PomarBancoConservacaoControleFenologiaUtil_1 = require("./PomarBancoConservacaoControleFenologiaUtil");
const HistoricoEstoqueProdutoFertilizante_1 = require("../../../../../modelos/HistoricoEstoqueProdutoFertilizante");
const chart_js_1 = require("chart.js");
const sweetalert2_1 = require("sweetalert2");
class PomarBancoConservacaoController extends AbstratoController_1.AbstratoController {
    constructor($scope, $injector) {
        super($scope, $injector, new PomarBancoConservacao_1.PomarBancoConservacao());
        this.path = '';
        this.caminho = '';
        this.tipoOperacao = null;
        this.tipoEnxertoPomar = null;
        this.tipoFenologiaPomar = null;
        this.tipoSobrevivenciaEnxertoPomar = null;
        this.tipoPolinizacaoPomar = null;
        this.tipoPlanejamentoPolinizacaoPomar = null;
        this.tipoRelatorioPomar = null;
        this.tipoFaseAvaliadaFenologiaPomar = null;
        this.tipoEstrobilo = null;
        this.listaPomares = [];
        this.listaOpcoesPomares = null;
        this.listaOpcoesPlanejamentoPomar = null;
        this.listaOpcoesManejoPomarResumido = null;
        this.listaOpcoesManejoPomarDetalhado = null;
        this.listaOpcoesEnxertiaPomar = null;
        this.listaOpcoesProdutividadePomar = null;
        this.listaOpcoesFenologiaPomar = null;
        this.listaOpcoesPolinizacaoPomar = null;
        this.listaOpcoesMonitoramentoFloracaoMasculinoPolinizacaoPomar = null;
        this.listaOpcoesMonitoramentoFloracaoFemininoPolinizacaoPomar = null;
        this.listaOpcoesEstoquePolenPolinizacaoPomar = null;
        this.listaOpcoesHistoricoEstoquePolenPolinizacaoPomar = null;
        this.listaOpcoesLancamentosPinusPolinizacaoPomar = null;
        this.listaOpcoesLancamentosEucalyptusPolinizacaoPomar = null;
        this.listaOpcoesColetasConesSementesPolinizacaoPomar = null;
        this.listaOpcoesBeneficiamentosPinusPolinizacaoPomar = null;
        this.listaOpcoesBeneficiamentosEucalyptusPolinizacaoPomar = null;
        this.listaOpcoesEstoqueBeneficiamentoPinusPolinizacaoPomar = null;
        this.listaOpcoesHistoricoEstoqueBeneficiamentoPinusPolinizacaoPomar = null;
        this.listaOpcoesEstoqueBeneficiamentoEucalyptusPolinizacaoPomar = null;
        this.listaOpcoesHistoricoEstoqueBeneficiamentoEucalyptusPolinizacaoPomar = null;
        this.listaOpcoesRelatorioPolinizacaoPinusPomar = null;
        this.listaOpcoesRelatorioPolinizacaoEucalyptusPomar = null;
        this.listaOpcoesTratamentosPomar = null;
        this.listaOpcoesClimaAnualPomar = null;
        this.listaOpcoesAnexosPomar = null;
        this.pomarSelecionado = null;
        this.pomarCarregado = false;
        this.tratamentoPomarSelecionado = null;
        this.tratamentoPomarVisualizar = null;
        this.croquiPomarSelecionado = null;
        this.mapaPomarSelecionado = null;
        this.importaShapefileSelecionado = null;
        this.planejamentoPomarSelecionado = null;
        this.planejamentoPomarVisualizar = null;
        this.controleManejoPomarSelecionado = null;
        this.controleManejoPomarVisualizar = null;
        this.controleEnxertiaPomarSelecionado = null;
        this.controleEnxertiaPomarVisualizar = null;
        this.produtividadePomarSelecionado = null;
        this.produtividadePomarVisualizar = null;
        this.fenologiaPomarSelecionado = null;
        this.fenologiaPomarVisualizar = null;
        this.tipoPolinizacaoPomarSelecionado = null;
        this.polinizacaoPomarSelecionado = null;
        this.polinizacaoPomarSelecionadoTabela = null;
        this.polinizacaoPomarVisualizar = null;
        this.estoquePolenPolinizacaoPomarSelecionado = null;
        this.estoqueBeneficiamentoPinusPolinizacaoPomarSelecionado = null;
        this.estoqueBeneficiamentoEucalyptusPolinizacaoPomarSelecionado = null;
        this.registroPolinizacaoPomarSelecionado = null;
        this.indiceMonitoramentoFloracaoPolinizacaoPomarSelecionado = null;
        this.indiceLancamentoPolinizacaoPomarSelecionado = null;
        this.indiceColetaConesSementesPolinizacaoPomarSelecionado = null;
        this.indiceBeneficiamentoPolinizacaoPomarSelecionado = null;
        this.estrobiloMonitoramentoFloracaoPolinizacaoPomar = null;
        this.relatorioPomarSelecionado = null;
        this.relatorioPomarVisualizar = null;
        this.anexoPomarSelecionado = null;
        this.anexoPomarVisualizar = null;
        this.generos = [];
        this.especies = [];
        this.geracoes = [];
        this.paises = [];
        this.provinciasUf = [];
        this.tiposImplantacoes = [];
        this.fontes = [];
        this.categoriasPomares = [];
        this.tiposPomares = [];
        this.situacoes = [];
        this.tiposNaturalPlantada = [];
        this.classificacoesSolo = [];
        this.unidadesManejo = [];
        this.produtosFertilizantes = [];
        this.tiposDelineamentos = [];
        this.disposicoesIndividuos = [];
        this.classificacoesClimaticas = [];
        this.estrobiloFeminino = null;
        this.estrobiloMasculino = null;
        this.planejamentosCruzamentosPolinizacaoPomar = [];
        this.hibridacoes = [];
        this.estagiosFloraisFemininos = [];
        this.estagiosFloraisMasculinos = [];
        this.estagiosFloraisEucalipto = [];
        this.maturacoesFrutos = [];
        this.regioes = [];
        this.regioesCarregando = false;
        this.talhoes = [];
        this.talhoesFiltrados = [];
        this.talhoesCarregando = false;
        this.coordenadaMapa = { latitude: 0, longitude: 0 };
        this.geoJsonMapa = null;
        this.mapaLiberado = false;
        this.grafico1ClimaAnualPomar = null;
        this.grafico2ClimaAnualPomar = null;
        this.grafico1PomarPolinizacaoPinus = null;
        this.grafico2PomarPolinizacaoPinus = null;
        this.grafico1PomarPolinizacaoEucalyptus = null;
        this.grafico2PomarPolinizacaoEucalyptus = null;
        this.operacoesManejoPomar = [];
        this.tiposLimpezaPomar = [];
        this.produtosLimpezaPomar = [];
        this.tiposDesbastePomar = [];
        this.tiposAmostrasManejoPomar = [];
        this.finalidadesOperacaoColetaAmostraPomar = [];
        this.tiposAdubacaoPomar = [];
        this.produtosAdubacaoPomar = [];
        this.tiposDoencasPomar = [];
        this.tiposIntervencaoDoencaPomar = [];
        this.produtosControleDoencaPomar = [];
        this.contasDepositoSap = [];
        this.centrosCustoSap = [];
        this.usuarios = [];
        this.estagiosObservacaoPolinizacaoPomar = [];
        this.materiaisGeneticos = [];
        this.materiaisGeneticosFiltrados = [];
        this.listaPolensFiltrados = [];
        this.materiaisGeneticosCarregando = false;
        this.listaFuncionarios = [];
        this.listaFuncionariosCarregando = false;
        this.listaMeses = [];
        this.listaAnos = [];
        this.listaEspacamentos = [
            { id: '1000_1000_1000', descricao: '10 x 10 x 10 M' },
            { id: '250_200', descricao: '2,5 x 2,0 M' },
            { id: '300_200', descricao: '3,0 x 2,0 M' },
            { id: '300_250', descricao: '3,0 x 2,5 M' },
            { id: '300_350', descricao: '3,0 x 3,5 M' },
            { id: '350_180', descricao: '3,5 x 1,8 M' },
        ];
        this.listasFotos = null;
        this.selectizeConfigMateriaisGeneticos = {
            create: false,
            persist: true,
            maxItems: null,
            openOnFocus: true,
            closeAfterSelect: false,
            selectOnTab: true,
            plugins: ['remove_button']
        };
        this.selectizeOptionsMateriaisGeneticos = [];
        this.filtroPlanejamentoPomar = null;
        this.filtroControleManejoPomar = null;
        this.filtroControleEnxertiaPomar = null;
        this.filtroProdutividadePomar = null;
        this.filtroFenologiaPomar = null;
        this.filtroPolinizacaoPomar = null;
        this.modalTratamentoPomar = false;
        this.modalGerarCroquiPomar = false;
        this.modalGerarCroquiPomarDados = null;
        this.modalHistoricoCroquiPomar = false;
        this.modalPlanejamentoPomar = false;
        this.modalControleManejoPomar = false;
        this.modalControleEnxertiaPomar = false;
        this.modalProdutividadePomar = false;
        this.modalFenologiaPomar = false;
        this.modalPolinizacaoPomar = false;
        this.modalRelatorioPomar = false;
        this.modalAnexoPomar = false;
        this.posicaoTopoAntesModal = null;
        this.timeoutFiltroTalhoes = null;
        this.timeoutFiltroMateriaisGeneticos = null;
        this.timeoutAtualizacaoGraficosClimaAnual = null;
        this.timeoutAtualizacaoMapa = null;
        this.tipoOperacao = ICRUDOperacao_1.CRUDOperacao;
        this.tipoEnxertoPomar = TipoEnxertoPomar_1.TipoEnxertoPomar;
        this.tipoFenologiaPomar = TipoFenologiaPomar_1.TipoFenologiaPomar;
        this.tipoSobrevivenciaEnxertoPomar = TipoSobrevivenciaEnxertoPomar_1.TipoSobrevivenciaEnxertoPomar;
        this.tipoPolinizacaoPomar = TipoPolinizacaoPomar_1.TipoPolinizacaoPomar;
        this.tipoPlanejamentoPolinizacaoPomar = TipoPlanejamentoPolinizacaoPomar_1.TipoPlanejamentoPolinizacaoPomar;
        this.tipoFaseAvaliadaFenologiaPomar = FaseAvaliadaFenologiaPomar_1.FaseAvaliadaFenologiaPomar;
        this.tipoRelatorioPomar = TipoRelatorioPomar_1.TipoRelatorioPomar;
        this.tipoEstrobilo = Estrobilo_1.Estrobilo;
        this.listasFotos = new CampoIndicadorFotos_1.CampoIndicadorFotos($scope);
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.path = config_app_1.config.api.caminho;
            this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.BANCO_MELHORAMENTO_POMAR;
            this.modalTratamentoPomar = false;
            this.modalGerarCroquiPomar = false;
            this.modalGerarCroquiPomarDados = null;
            this.modalHistoricoCroquiPomar = false;
            this.modalPlanejamentoPomar = false;
            this.modalControleManejoPomar = false;
            this.modalControleEnxertiaPomar = false;
            this.modalProdutividadePomar = false;
            this.modalFenologiaPomar = false;
            this.modalPolinizacaoPomar = false;
            this.modalRelatorioPomar = false;
            this.modalAnexoPomar = false;
            this.filtroPlanejamentoPomar = new FiltroPlanejamentoPomar_1.FiltroPlanejamentoPomar();
            this.filtroControleManejoPomar = new FiltroControleManejoPomar_1.FiltroControleManejoPomar();
            this.filtroControleEnxertiaPomar = new FiltroControleEnxertiaPomar_1.FiltroControleEnxertiaPomar();
            this.filtroProdutividadePomar = new FiltroProdutividadePomar_1.FiltroProdutividadePomar();
            this.filtroFenologiaPomar = new FiltroFenologiaPomar_1.FiltroFenologiaPomar();
            this.filtroPolinizacaoPomar = new FiltroPolinizacaoPomar_1.FiltroPolinizacaoPomar();
            if (!this.modelo.data) {
                this.modelo.data = new Date();
                this.modelo.data.setSeconds(0);
                this.modelo.data.setMilliseconds(0);
            }
            if (!this.usuario) {
                this.links = {
                    VISUALIZAR: null,
                    CRIAR: null,
                    DUPLICAR: null,
                    EDITAR: null,
                    LISTAR: {
                        rota: 'login',
                        params: null
                    }
                };
            }
            this.listaOpcoesPomares = yield this.dadosListaPomares();
            this.listaOpcoesPlanejamentoPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesManejoPomarResumido = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesManejoPomarDetalhado = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesEnxertiaPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesProdutividadePomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesFenologiaPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesPolinizacaoPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesMonitoramentoFloracaoMasculinoPolinizacaoPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesMonitoramentoFloracaoFemininoPolinizacaoPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesEstoquePolenPolinizacaoPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesHistoricoEstoquePolenPolinizacaoPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesLancamentosPinusPolinizacaoPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesLancamentosEucalyptusPolinizacaoPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesColetasConesSementesPolinizacaoPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesBeneficiamentosPinusPolinizacaoPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesEstoqueBeneficiamentoPinusPolinizacaoPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesHistoricoEstoqueBeneficiamentoPinusPolinizacaoPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesBeneficiamentosEucalyptusPolinizacaoPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesEstoqueBeneficiamentoEucalyptusPolinizacaoPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesHistoricoEstoqueBeneficiamentoEucalyptusPolinizacaoPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesRelatorioPolinizacaoPinusPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesRelatorioPolinizacaoEucalyptusPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesTratamentosPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesClimaAnualPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesAnexosPomar = {
                data: [],
                columnDefs: []
            };
            this.tiposNaturalPlantada = TipoNaturalPlantada_1.TipoNaturalPlantada.LISTA();
            this.listaMeses = [
                { mes: 1, descricao: 'Janeiro', sigla: 'Jan' },
                { mes: 2, descricao: 'Fevereiro', sigla: 'Fev' },
                { mes: 3, descricao: 'Março', sigla: 'Mar' },
                { mes: 4, descricao: 'Abril', sigla: 'Abr' },
                { mes: 5, descricao: 'Maio', sigla: 'Mai' },
                { mes: 6, descricao: 'Junho', sigla: 'Jun' },
                { mes: 7, descricao: 'Julho', sigla: 'Jul' },
                { mes: 8, descricao: 'Agosto', sigla: 'Ago' },
                { mes: 9, descricao: 'Setembro', sigla: 'Set' },
                { mes: 10, descricao: 'Outubro', sigla: 'Out' },
                { mes: 11, descricao: 'Novembro', sigla: 'Nov' },
                { mes: 12, descricao: 'Dezembro', sigla: 'Dez' },
            ];
            this.listaAnos = new Array();
            this.carregarTodosAnos();
            this.generos = new Array();
            const promiseGeneros = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.GENERO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.GENERO)
                .filtro({})
                .listar();
            promiseGeneros.then((resposta) => {
                ctrl.generos.splice(0, ctrl.generos.length);
                if ((typeof resposta !== 'undefined') && resposta) {
                    ctrl.generos.push(...resposta);
                }
                console.log('Gêneros carregados: ' + ctrl.generos.length);
                ctrl.scopeApply();
            });
            this.especies = new Array();
            const promiseEspecies = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESPECIE)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESPECIE)
                .filtro({})
                .listar();
            promiseEspecies.then((resposta) => {
                ctrl.especies.splice(0, ctrl.especies.length);
                if ((typeof resposta !== 'undefined') && resposta) {
                    ctrl.especies.push(...resposta);
                }
                console.log('Espécies carregadas: ' + ctrl.especies.length);
                ctrl.scopeApply();
            });
            this.geracoes = new Array();
            const promiseGeracoes = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.GERACAO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.GERACAO)
                .filtro({})
                .listar();
            promiseGeracoes.then((resposta) => {
                ctrl.geracoes.splice(0, ctrl.geracoes.length);
                if ((typeof resposta !== 'undefined') && resposta) {
                    angular.forEach(resposta, (geracao) => {
                        if ((typeof geracao.codigo !== 'undefined') && (geracao.codigo !== null)) {
                            let valor = '' + geracao.codigo;
                            geracao.codigo = valor.replace('.', ',');
                        }
                    });
                    ctrl.geracoes.push(...resposta);
                }
                console.log('Gerações carregadas: ' + ctrl.geracoes.length);
                ctrl.scopeApply();
            });
            this.paises = new Array();
            const promisePaises = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.PAIS)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PAIS)
                .filtro({})
                .listar();
            promisePaises.then((resposta) => {
                ctrl.paises.splice(0, ctrl.paises.length);
                if ((typeof resposta !== 'undefined') && resposta) {
                    ctrl.paises.push(...resposta);
                }
                console.log('Países carregados: ' + ctrl.paises.length);
                ctrl.scopeApply();
            });
            this.provinciasUf = new Array();
            const promiseProvinciasUf = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROVINCIA_UF)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROVINCIA_UF)
                .filtro({})
                .listar();
            promiseProvinciasUf.then((resposta) => {
                ctrl.provinciasUf.splice(0, ctrl.provinciasUf.length);
                if ((typeof resposta !== 'undefined') && resposta) {
                    ctrl.provinciasUf.push(...resposta);
                }
                console.log('Províncias/UF carregadas: ' + ctrl.provinciasUf.length);
                ctrl.scopeApply();
            });
            this.tiposImplantacoes = new Array();
            const promiseTiposImplantacoes = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.TIPO_IMPLANTACAO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TIPO_IMPLANTACAO)
                .filtro({})
                .listar();
            promiseTiposImplantacoes.then((resposta) => {
                ctrl.tiposImplantacoes.splice(0, ctrl.tiposImplantacoes.length);
                if ((typeof resposta !== 'undefined') && resposta) {
                    ctrl.tiposImplantacoes.push(...resposta);
                }
                console.log('Tipos de Implantações carregados: ' + ctrl.tiposImplantacoes.length);
                ctrl.scopeApply();
            });
            this.fontes = new Array();
            const promiseFontes = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.FONTE)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.FONTE)
                .filtro({})
                .listar();
            promiseFontes.then((resposta) => {
                ctrl.fontes.splice(0, ctrl.fontes.length);
                if ((typeof resposta !== 'undefined') && resposta) {
                    ctrl.fontes.push(...resposta);
                }
                console.log('Fontes carregadas: ' + ctrl.fontes.length);
                ctrl.scopeApply();
            });
            ctrl.categoriasPomares = new Array();
            const promiseCategoriasPomares = ctrl.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.CATEGORIA_POMAR)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CATEGORIA_POMAR)
                .filtro({})
                .listar();
            promiseCategoriasPomares.then((resposta) => {
                ctrl.categoriasPomares.splice(0, ctrl.categoriasPomares.length);
                ctrl.categoriasPomares.push(...resposta);
                console.log('Categorias Pomares carregadas: ' + ctrl.categoriasPomares.length);
                ctrl.scopeApply();
            });
            ctrl.tiposPomares = new Array();
            const promisePopulacoesPomares = ctrl.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.TIPO_POMAR)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TIPO_POMAR)
                .filtro({})
                .listar();
            promisePopulacoesPomares.then((resposta) => {
                ctrl.tiposPomares.splice(0, ctrl.tiposPomares.length);
                ctrl.tiposPomares.push(...resposta);
                console.log('Tipos Pomares carregadas: ' + ctrl.tiposPomares.length);
                ctrl.scopeApply();
            });
            ctrl.situacoes = new Array();
            const promiseSituacoes = ctrl.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.SITUACAO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.SITUACAO)
                .filtro({})
                .listar();
            promiseSituacoes.then((resposta) => {
                ctrl.situacoes.splice(0, ctrl.situacoes.length);
                ctrl.situacoes.push(...resposta);
                console.log('Situações Pomares carregadas: ' + ctrl.situacoes.length);
                ctrl.scopeApply();
            });
            ctrl.regioes = new Array();
            ctrl.regioesCarregando = true;
            const promiseRegioesFiltro = ctrl.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.REGIAO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.REGIAO)
                .filtro({ permiteDadosOffline: true })
                .listar();
            promiseRegioesFiltro.then((resposta) => {
                ctrl.regioes.splice(0, ctrl.regioes.length);
                angular.forEach(resposta, (regiao) => {
                    if ((typeof regiao.nome !== 'undefined') && (regiao.nome !== null)) {
                        const s = regiao.nome.trim();
                        if ((s.length > 0) && (s !== '-') && (s !== '_')) {
                            ctrl.regioes.push(regiao);
                        }
                    }
                });
                ctrl.regioesCarregando = false;
                ctrl.scopeApply();
            });
            ctrl.talhoes = new Array();
            ctrl.talhoesFiltrados = new Array();
            ctrl.talhoesCarregando = true;
            const promiseTalhoes = ctrl.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.TALHAO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TALHAO)
                .filtro({ permiteDadosOffline: true })
                .listar();
            promiseTalhoes.then((resposta) => {
                ctrl.talhoes.splice(0, ctrl.talhoes.length);
                ctrl.talhoes.push(...resposta);
                if ((typeof ctrl.talhoes !== 'undefined') && (ctrl.talhoes !== null)) {
                    angular.forEach(ctrl.talhoes, (talhao) => {
                        if ((typeof talhao.regiao === 'undefined') || (talhao.regiao === null)) {
                            talhao.regiao = new Regiao_1.Regiao();
                            talhao.regiao.sgif_id = talhao.regiaoSgifId;
                            talhao.regiao.nome = talhao.regiaoNome;
                        }
                        else if ((typeof talhao.regiao.nome === 'undefined') || (talhao.regiao.nome === null) || (talhao.regiao.nome.trim().length <= 0)) {
                            talhao.regiao.sgif_id = talhao.regiaoSgifId;
                            talhao.regiao.nome = talhao.regiaoNome;
                        }
                    });
                }
                ctrl.filtraTalhoes();
                ctrl.talhoesCarregando = false;
                ctrl.scopeApply();
            });
            ctrl.classificacoesSolo = new Array();
            const promiseClassificacoeSolo = ctrl.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.CLASSIFICACAO_SOLO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CLASSIFICACAO_SOLO)
                .filtro({})
                .listar();
            promiseClassificacoeSolo.then((resposta) => {
                ctrl.classificacoesSolo.splice(0, ctrl.classificacoesSolo.length);
                ctrl.classificacoesSolo.push(...resposta);
                console.log('Classificações de Solo carregadas: ' + ctrl.classificacoesSolo.length);
                ctrl.scopeApply();
            });
            this.estrobiloMasculino = {
                id: Estrobilo_1.Estrobilo.MASCULINO,
                uuid: Estrobilo_1.Estrobilo.MASCULINO,
                status: true,
                codigo: 1,
                descricao: 'Masculino'
            };
            this.estrobiloFeminino = {
                id: Estrobilo_1.Estrobilo.FEMININO,
                uuid: Estrobilo_1.Estrobilo.FEMININO,
                status: true,
                codigo: 2,
                descricao: 'Feminino'
            };
            const promiseEstrobilos = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESTROBILO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESTROBILO)
                .filtro({})
                .listar();
            promiseEstrobilos.then((resposta) => {
                if ((typeof resposta !== 'undefined') && resposta) {
                    angular.forEach(resposta, (estrobilo) => {
                        if ((typeof estrobilo.id !== 'undefined') && estrobilo.id) {
                            if (estrobilo.id === Estrobilo_1.Estrobilo.FEMININO) {
                                ctrl.estrobiloFeminino = estrobilo;
                            }
                            else if (estrobilo.id === Estrobilo_1.Estrobilo.MASCULINO) {
                                ctrl.estrobiloMasculino = estrobilo;
                            }
                        }
                    });
                    console.log('Estróbilos carregados: ' + resposta.length);
                    ctrl.scopeApply();
                }
            });
            this.hibridacoes = new Array();
            const promiseHibridacoes = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.HIBRIDACAO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.HIBRIDACAO)
                .filtro({})
                .listar();
            promiseHibridacoes.then((resposta) => {
                ctrl.hibridacoes.splice(0, ctrl.hibridacoes.length);
                if ((typeof resposta !== 'undefined') && resposta) {
                    ctrl.hibridacoes.push(...resposta);
                }
                console.log('Hibridações carregadas: ' + ctrl.hibridacoes.length);
                ctrl.scopeApply();
            });
            this.estagiosFloraisFemininos = new Array();
            const promiseEstagiosFloraisFemininos = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESTAGIO_FLORAL_FEMININO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESTAGIO_FLORAL_FEMININO)
                .filtro({})
                .listar();
            promiseEstagiosFloraisFemininos.then((resposta) => {
                ctrl.estagiosFloraisFemininos.splice(0, ctrl.estagiosFloraisFemininos.length);
                if ((typeof resposta !== 'undefined') && resposta) {
                    ctrl.estagiosFloraisFemininos.push(...resposta);
                }
                console.log('Estágios Florais Femininos carregados: ' + ctrl.estagiosFloraisFemininos.length);
                ctrl.scopeApply();
            });
            this.estagiosFloraisMasculinos = new Array();
            const promiseEstagiosFloraisMasculinos = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESTAGIO_FLORAL_MASCULINO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESTAGIO_FLORAL_MASCULINO)
                .filtro({})
                .listar();
            promiseEstagiosFloraisMasculinos.then((resposta) => {
                ctrl.estagiosFloraisMasculinos.splice(0, ctrl.estagiosFloraisMasculinos.length);
                if ((typeof resposta !== 'undefined') && resposta) {
                    ctrl.estagiosFloraisMasculinos.push(...resposta);
                }
                console.log('Estágios Florais Masculinos carregados: ' + ctrl.estagiosFloraisMasculinos.length);
                ctrl.scopeApply();
            });
            this.estagiosFloraisEucalipto = new Array();
            const promiseEstagiosFloraisEucalipto = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESTAGIO_FLORAL_EUCALIPTO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESTAGIO_FLORAL_EUCALIPTO)
                .filtro({})
                .listar();
            promiseEstagiosFloraisEucalipto.then((resposta) => {
                ctrl.estagiosFloraisEucalipto.splice(0, ctrl.estagiosFloraisEucalipto.length);
                if ((typeof resposta !== 'undefined') && resposta) {
                    ctrl.estagiosFloraisEucalipto.push(...resposta);
                }
                console.log('Estágios Florais Eucalipto carregados: ' + ctrl.estagiosFloraisEucalipto.length);
                ctrl.scopeApply();
            });
            this.maturacoesFrutos = new Array();
            const promiseMaturacoesFrutos = this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.MATURACAO_FRUTO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MATURACAO_FRUTO)
                .filtro({})
                .listar();
            promiseMaturacoesFrutos.then((resposta) => {
                ctrl.maturacoesFrutos.splice(0, ctrl.maturacoesFrutos.length);
                if ((typeof resposta !== 'undefined') && resposta) {
                    ctrl.maturacoesFrutos.push(...resposta);
                }
                console.log('Maturações Frutos carregadas: ' + ctrl.maturacoesFrutos.length);
                ctrl.scopeApply();
            });
            ctrl.unidadesManejo = new Array();
            const promiseUnidadesManejo = ctrl.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.UNIDADE_MANEJO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.UNIDADE_MANEJO)
                .filtro({})
                .listar();
            promiseUnidadesManejo.then((resposta) => {
                ctrl.unidadesManejo.splice(0, ctrl.unidadesManejo.length);
                ctrl.unidadesManejo.push(...resposta);
                console.log('Unidades Manejo carregadas: ' + ctrl.unidadesManejo.length);
                ctrl.scopeApply();
            });
            ctrl.produtosFertilizantes = new Array();
            const promiseProdutosFertilizantes = ctrl.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                .filtro({})
                .listar();
            promiseProdutosFertilizantes.then((resposta) => {
                ctrl.produtosFertilizantes.splice(0, ctrl.produtosFertilizantes.length);
                ctrl.produtosFertilizantes.push(...resposta);
                console.log('Produtos Fertilizantes carregados: ' + ctrl.produtosFertilizantes.length);
                ctrl.scopeApply();
            });
            ctrl.tiposDelineamentos = new Array();
            const promiseTiposDelineamentos = ctrl.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.TIPO_DELINEAMENTO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TIPO_DELINEAMENTO)
                .filtro({})
                .listar();
            promiseTiposDelineamentos.then((resposta) => {
                ctrl.tiposDelineamentos.splice(0, ctrl.tiposDelineamentos.length);
                ctrl.tiposDelineamentos.push(...resposta);
                console.log('Tipos Delineamentos carregados: ' + ctrl.tiposDelineamentos.length);
                ctrl.scopeApply();
            });
            ctrl.disposicoesIndividuos = new Array();
            const promiseDisposicaoIndividuos = ctrl.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.DISPOSICAO_INDIVIDUOS)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.DISPOSICAO_INDIVIDUOS)
                .filtro({})
                .listar();
            promiseDisposicaoIndividuos.then((resposta) => {
                ctrl.disposicoesIndividuos.splice(0, ctrl.disposicoesIndividuos.length);
                ctrl.disposicoesIndividuos.push(...resposta);
                console.log('Disposições Indivíduos carregadas: ' + ctrl.disposicoesIndividuos.length);
                ctrl.scopeApply();
            });
            ctrl.classificacoesClimaticas = new Array();
            const promiseClassificacoesClimaticas = ctrl.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.CLASSIFICACAO_CLIMATICA)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CLASSIFICACAO_CLIMATICA)
                .filtro({})
                .listar();
            promiseClassificacoesClimaticas.then((resposta) => {
                ctrl.classificacoesClimaticas.splice(0, ctrl.classificacoesClimaticas.length);
                ctrl.classificacoesClimaticas.push(...resposta);
                console.log('Classificações Climáticas carregadas: ' + ctrl.classificacoesClimaticas.length);
                ctrl.scopeApply();
            });
            ctrl.materiaisGeneticos = new Array();
            ctrl.materiaisGeneticosFiltrados = new Array();
            ctrl.listaPolensFiltrados = new Array();
            ctrl.materiaisGeneticosCarregando = true;
            const promiseMateriaisGeneticos = ctrl.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MATERIAL_GENETICO)
                .filtro({ permiteDadosOffline: true })
                .listar();
            promiseMateriaisGeneticos.then((resposta) => {
                ctrl.materiaisGeneticos.splice(0, ctrl.materiaisGeneticos.length);
                ctrl.materiaisGeneticos.push(...resposta);
                ctrl.filtraMateriaisGeneticos();
                ctrl.materiaisGeneticosCarregando = false;
                ctrl.scopeApply();
            });
            this.listasFotos.carregarDados(this.sincronia, { controleManejoPomar: null, anexoPomar: null });
            setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                ctrl.listaFuncionariosCarregando = true;
                ctrl.listaFuncionarios = new Array();
                const promiseFuncionarios = ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.FUNCIONARIO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.FUNCIONARIO)
                    .filtro({ permiteDadosOffline: true })
                    .listar();
                promiseFuncionarios.then((resposta) => {
                    ctrl.listaFuncionarios.splice(0, ctrl.listaFuncionarios.length);
                    ctrl.listaFuncionarios.push(...resposta);
                    ctrl.listaFuncionariosCarregando = false;
                    ctrl.scopeApply();
                });
                ctrl.operacoesManejoPomar = new Array();
                const promiseOperacoesManejoPomar = ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.OPERACAO_MANEJO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.OPERACAO_MANEJO_POMAR)
                    .filtro({})
                    .listar();
                promiseOperacoesManejoPomar.then((resposta) => {
                    ctrl.operacoesManejoPomar.splice(0, ctrl.operacoesManejoPomar.length);
                    if ((typeof resposta !== 'undefined') && resposta && (resposta.length > 0)) {
                        angular.forEach(resposta, (operacaoManejoPomar) => {
                            if ((typeof operacaoManejoPomar.tipoOperacao !== 'undefined') && operacaoManejoPomar.tipoOperacao) {
                                operacaoManejoPomar.ehOperacaoLimpeza = (operacaoManejoPomar.tipoOperacao === OperacaoManejoPomar_1.OperacaoManejoPomar.OPERACAO_LIMPEZA);
                                operacaoManejoPomar.ehOperacaoDesbaste = (operacaoManejoPomar.tipoOperacao === OperacaoManejoPomar_1.OperacaoManejoPomar.OPERACAO_DESBASTE);
                                operacaoManejoPomar.ehOperacaoPolinizacao = (operacaoManejoPomar.tipoOperacao === OperacaoManejoPomar_1.OperacaoManejoPomar.OPERACAO_POLINIZACAO);
                                operacaoManejoPomar.ehOperacaoColetaAmostras = (operacaoManejoPomar.tipoOperacao === OperacaoManejoPomar_1.OperacaoManejoPomar.OPERACAO_COLETA_AMOSTRAS);
                                operacaoManejoPomar.ehOperacaoColetaSementes = (operacaoManejoPomar.tipoOperacao === OperacaoManejoPomar_1.OperacaoManejoPomar.OPERACAO_COLETA_SEMENTES);
                                operacaoManejoPomar.ehOperacaoControleDoencas = (operacaoManejoPomar.tipoOperacao === OperacaoManejoPomar_1.OperacaoManejoPomar.OPERACAO_CONTROLE_DOENCAS);
                                operacaoManejoPomar.ehOperacaoAdubacaoFertilizacao = (operacaoManejoPomar.tipoOperacao === OperacaoManejoPomar_1.OperacaoManejoPomar.OPERACAO_ADUBACAO_FERTILIZACAO);
                                operacaoManejoPomar.ehOperacaoPodaRamosPortaEnxerto = (operacaoManejoPomar.tipoOperacao === OperacaoManejoPomar_1.OperacaoManejoPomar.OPERACAO_PODA_RAMOS_PORTA_ENXERTO);
                                operacaoManejoPomar.ehOperacaoAvaliacaoSobrevivencia = (operacaoManejoPomar.tipoOperacao === OperacaoManejoPomar_1.OperacaoManejoPomar.OPERACAO_AVALIACAO_SOBREVIVENCIA);
                                operacaoManejoPomar.ehOutrasOperacoes = (operacaoManejoPomar.tipoOperacao === OperacaoManejoPomar_1.OperacaoManejoPomar.OUTRAS_OPERACOES);
                            }
                        });
                    }
                    ctrl.operacoesManejoPomar.push(...resposta);
                    console.log('Operações de Manejo carregadas: ' + ctrl.operacoesManejoPomar.length);
                    ctrl.scopeApply();
                });
                ctrl.tiposLimpezaPomar = new Array();
                const promiseTiposLimpezaPomar = ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TIPO_LIMPEZA_MANEJO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TIPO_LIMPEZA_MANEJO_POMAR)
                    .filtro({})
                    .listar();
                promiseTiposLimpezaPomar.then((resposta) => {
                    ctrl.tiposLimpezaPomar.splice(0, ctrl.tiposLimpezaPomar.length);
                    ctrl.tiposLimpezaPomar.push(...resposta);
                    console.log('Tipos de Limpeza (Manejo Pomar) carregados: ' + ctrl.tiposLimpezaPomar.length);
                    ctrl.scopeApply();
                });
                ctrl.produtosLimpezaPomar = new Array();
                const promiseProdutosLimpezaPomar = ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                    .filtro({})
                    .listar();
                promiseProdutosLimpezaPomar.then((resposta) => {
                    ctrl.produtosLimpezaPomar.splice(0, ctrl.produtosLimpezaPomar.length);
                    ctrl.produtosLimpezaPomar.push(...resposta);
                    console.log('Produtos para Limpeza (Manejo Pomar) carregados: ' + ctrl.produtosLimpezaPomar.length);
                    ctrl.scopeApply();
                });
                ctrl.tiposDesbastePomar = new Array();
                const promiseTiposDesbastePomar = ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TIPO_DESBASTE_MANEJO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TIPO_DESBASTE_MANEJO_POMAR)
                    .filtro({})
                    .listar();
                promiseTiposDesbastePomar.then((resposta) => {
                    ctrl.tiposDesbastePomar.splice(0, ctrl.tiposDesbastePomar.length);
                    ctrl.tiposDesbastePomar.push(...resposta);
                    console.log('Tipos de Desbaste (Manejo Pomar) carregados: ' + ctrl.tiposDesbastePomar.length);
                    ctrl.scopeApply();
                });
                ctrl.tiposAmostrasManejoPomar = new Array();
                const promiseTiposAmostrasManejoPomar = ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TIPO_AMOSTRA_MANEJO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TIPO_AMOSTRA_MANEJO_POMAR)
                    .filtro({})
                    .listar();
                promiseTiposAmostrasManejoPomar.then((resposta) => {
                    ctrl.tiposAmostrasManejoPomar.splice(0, ctrl.tiposAmostrasManejoPomar.length);
                    ctrl.tiposAmostrasManejoPomar.push(...resposta);
                    console.log('Tipos de Coleta de Amostras (Manejo Pomar) carregadas: ' + ctrl.tiposAmostrasManejoPomar.length);
                    ctrl.scopeApply();
                });
                ctrl.finalidadesOperacaoColetaAmostraPomar = new Array();
                const promiseFinalidadesOperacaoColetaAmostraPomar = ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.FINALIDADE_AMOSTRA_MANEJO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.FINALIDADE_AMOSTRA_MANEJO_POMAR)
                    .filtro({})
                    .listar();
                promiseFinalidadesOperacaoColetaAmostraPomar.then((resposta) => {
                    ctrl.finalidadesOperacaoColetaAmostraPomar.splice(0, ctrl.finalidadesOperacaoColetaAmostraPomar.length);
                    ctrl.finalidadesOperacaoColetaAmostraPomar.push(...resposta);
                    console.log('Finalidades de Coleta de Amostras (Manejo Pomar) carregadas: ' + ctrl.finalidadesOperacaoColetaAmostraPomar.length);
                    ctrl.scopeApply();
                });
                ctrl.tiposAdubacaoPomar = new Array();
                const promiseTiposAdubacaoPomar = ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TIPO_APLICACAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TIPO_APLICACAO)
                    .filtro({})
                    .listar();
                promiseTiposAdubacaoPomar.then((resposta) => {
                    ctrl.tiposAdubacaoPomar.splice(0, ctrl.tiposAdubacaoPomar.length);
                    ctrl.tiposAdubacaoPomar.push(...resposta);
                    console.log('Tipos de Adubação/Fertilização (Manejo Pomar) carregados: ' + ctrl.tiposAdubacaoPomar.length);
                    ctrl.scopeApply();
                });
                ctrl.produtosAdubacaoPomar = new Array();
                const promiseProdutosAdubacaoPomar = ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                    .filtro({})
                    .listar();
                promiseProdutosAdubacaoPomar.then((resposta) => {
                    ctrl.produtosAdubacaoPomar.splice(0, ctrl.produtosAdubacaoPomar.length);
                    ctrl.produtosAdubacaoPomar.push(...resposta);
                    console.log('Produtos para Adubação/Fertilização (Manejo Pomar) carregados: ' + ctrl.produtosAdubacaoPomar.length);
                    ctrl.scopeApply();
                });
                ctrl.tiposDoencasPomar = new Array();
                const promiseTiposDoencasPomar = ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRAGA_DOENCA_MANEJO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRAGA_DOENCA_MANEJO_POMAR)
                    .filtro({})
                    .listar();
                promiseTiposDoencasPomar.then((resposta) => {
                    ctrl.tiposDoencasPomar.splice(0, ctrl.tiposDoencasPomar.length);
                    ctrl.tiposDoencasPomar.push(...resposta);
                    console.log('Tipos de Doenças (Manejo Pomar) carregados: ' + ctrl.tiposDoencasPomar.length);
                    ctrl.scopeApply();
                });
                ctrl.tiposIntervencaoDoencaPomar = new Array();
                const promiseTiposIntervencaoDoencaPomar = ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TIPO_INTERVENCAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TIPO_INTERVENCAO)
                    .filtro({})
                    .listar();
                promiseTiposIntervencaoDoencaPomar.then((resposta) => {
                    ctrl.tiposIntervencaoDoencaPomar.splice(0, ctrl.tiposIntervencaoDoencaPomar.length);
                    ctrl.tiposIntervencaoDoencaPomar.push(...resposta);
                    console.log('Tipos de Intervenções por Doença (Manejo Pomar) carregados: ' + ctrl.tiposIntervencaoDoencaPomar.length);
                    ctrl.scopeApply();
                });
                ctrl.produtosControleDoencaPomar = new Array();
                const promiseProdutosControleDoencaPomar = ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_DEFENSIVO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_DEFENSIVO)
                    .filtro({})
                    .listar();
                promiseProdutosControleDoencaPomar.then((resposta) => {
                    ctrl.produtosControleDoencaPomar.splice(0, ctrl.produtosControleDoencaPomar.length);
                    ctrl.produtosControleDoencaPomar.push(...resposta);
                    console.log('Produtos para Controle de Doenças (Manejo Pomar) carregados: ' + ctrl.produtosControleDoencaPomar.length);
                    ctrl.scopeApply();
                });
                ctrl.contasDepositoSap = new Array();
                const promiseContaDepositoSap = ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.CONTA_DEPOSITO_SAP)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CONTA_DEPOSITO_SAP)
                    .filtro({})
                    .listar();
                promiseContaDepositoSap.then((resposta) => {
                    ctrl.contasDepositoSap.splice(0, ctrl.contasDepositoSap.length);
                    ctrl.contasDepositoSap.push(...resposta);
                    console.log('Contas Deposito SAP carregados: ' + ctrl.contasDepositoSap.length);
                    ctrl.scopeApply();
                });
                ctrl.centrosCustoSap = new Array();
                const promiseCentroCustoSap = ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.CENTRO_CUSTO_SAP)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CENTRO_CUSTO_SAP)
                    .filtro({})
                    .listar();
                promiseCentroCustoSap.then((resposta) => {
                    ctrl.centrosCustoSap.splice(0, ctrl.centrosCustoSap.length);
                    ctrl.centrosCustoSap.push(...resposta);
                    console.log('Centros Custos SAP carregados: ' + ctrl.centrosCustoSap.length);
                    ctrl.scopeApply();
                });
                ctrl.usuarios = new Array();
                const promiseUsuarios = ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.USUARIO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.USUARIO)
                    .filtro({})
                    .listar();
                promiseUsuarios.then((resposta) => {
                    ctrl.usuarios.splice(0, ctrl.usuarios.length);
                    ctrl.usuarios.push(...resposta);
                    console.log('Usuarios carregados: ' + ctrl.usuarios.length);
                    ctrl.scopeApply();
                });
                ctrl.estagiosObservacaoPolinizacaoPomar = new Array();
                const promiseEstagiosObservacaoPolinizacaoPomar = ctrl.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESTAGIO_OBSERVACAO_POLINIZACAO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESTAGIO_OBSERVACAO_POLINIZACAO_POMAR)
                    .filtro({})
                    .listar();
                promiseEstagiosObservacaoPolinizacaoPomar.then((resposta) => {
                    ctrl.estagiosObservacaoPolinizacaoPomar.splice(0, ctrl.estagiosObservacaoPolinizacaoPomar.length);
                    ctrl.estagiosObservacaoPolinizacaoPomar.push(...resposta);
                    console.log('Estágios Observação Polinização (Manejo Pomar) carregados: ' + ctrl.estagiosObservacaoPolinizacaoPomar.length);
                    ctrl.scopeApply();
                });
            }), 1500, this);
            this.$scope.$apply();
        });
    }
    carregarTodosAnos() {
        this.listaAnos = new Array();
        const dataAtual = new Date();
        const anoAtual = dataAtual.getFullYear();
        for (let ano = 1950; ano < anoAtual + 15; ano++) {
            this.listaAnos.push(ano);
        }
    }
    salvar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (!this.modelo) {
                    return;
                }
                if ((!this.modelo.data) || (this.modelo.data === null)) {
                    this.modelo.data = new Date();
                }
                this.modalTratamentoPomar = false;
                this.modalGerarCroquiPomar = false;
                this.modalGerarCroquiPomarDados = null;
                this.modalHistoricoCroquiPomar = false;
                this.modalPlanejamentoPomar = false;
                this.modalControleManejoPomar = false;
                this.modalControleEnxertiaPomar = false;
                this.modalProdutividadePomar = false;
                this.modalFenologiaPomar = false;
                this.modalPolinizacaoPomar = false;
                this.modalRelatorioPomar = false;
                this.modalAnexoPomar = false;
                angular.element('body').css('overflow-y', '');
                if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.BANCO_MELHORAMENTO_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.BANCO_MELHORAMENTO_POMAR)
                        .criar(this.modelo);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.BANCO_MELHORAMENTO_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.BANCO_MELHORAMENTO_POMAR)
                        .atualizar(this.modelo);
                }
                if (!this.usuario) {
                    this.$state.go('sair');
                }
                else {
                    this.$state.go(this.links.LISTAR.rota);
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    visualizar(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const pomarBancoConservacao = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.BANCO_MELHORAMENTO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.BANCO_MELHORAMENTO_POMAR)
                    .id(id)
                    .ver();
                pomarBancoConservacao.criacao = new Date(pomarBancoConservacao.criacao);
                pomarBancoConservacao.data = pomarBancoConservacao.data ? new Date(pomarBancoConservacao.data) : new Date();
                pomarBancoConservacao.data.setSeconds(0);
                pomarBancoConservacao.data.setMilliseconds(0);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return pomarBancoConservacao;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    remover(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const pomarBancoConservacao = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.BANCO_MELHORAMENTO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.BANCO_MELHORAMENTO_POMAR)
                    .id(id)
                    .remover();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (pomarBancoConservacao) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const pomarBancoConservacao = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.BANCO_MELHORAMENTO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.BANCO_MELHORAMENTO_POMAR)
                    .id(this.modelo.id)
                    .restaurar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (pomarBancoConservacao) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosLista() {
        return __awaiter(this, void 0, void 0, function* () {
            const gridOption = {
                data: [],
                columnDefs: [],
                enableSorting: true,
                controller: this
            };
            return gridOption;
        });
    }
    cellClicked(row) {
        if (row.entity && (typeof row.entity.id !== 'undefined') && row.entity.id) {
            const nomeTabelaPomares = 'tabelaPomarBancoConservacaoes';
            let ehTabelaPomares = false;
            if ((typeof row.gridApi !== 'undefined') && row.gridApi &&
                (typeof row.gridApi.element !== 'undefined') && row.gridApi.element &&
                (angular.element(row.gridApi.element).length > 0)) {
                ehTabelaPomares = (angular.element(row.gridApi.element).parents('#' + nomeTabelaPomares).length > 0) ? true : false;
            }
            else {
                ehTabelaPomares = true;
            }
            if (ehTabelaPomares) {
                let gridPomaresApi = null;
                let gridPomaresScope = angular.element('#' + nomeTabelaPomares).scope();
                if ((typeof gridPomaresScope.listaGridApi !== 'undefined') && gridPomaresScope.listaGridApi) {
                    angular.forEach(gridPomaresScope.listaGridApi, (gridApi) => {
                        const grid = ((typeof gridApi.grid !== 'undefined') && gridApi.grid) ? gridApi.grid : null;
                        if (grid && (typeof grid.element !== 'undefined') && grid.element) {
                            const tabelasEl = angular.element(grid.element).parents('tabela');
                            if (tabelasEl && (tabelasEl.length > 0) && (tabelasEl.first().attr('id') === nomeTabelaPomares)) {
                                gridPomaresApi = gridApi;
                            }
                        }
                    });
                }
                if (gridPomaresApi && (typeof gridPomaresApi.selection !== 'undefined') && gridPomaresApi.selection) {
                    gridPomaresApi.selection.selectRow(row.entity);
                }
            }
            const nomeTabelaPolinizacao = 'tabelaBancoMelhoramentoPolinizacaoPomar';
            let ehTabelaPolinizacao = false;
            if ((typeof row.gridApi !== 'undefined') && row.gridApi &&
                (typeof row.gridApi.element !== 'undefined') && row.gridApi.element &&
                (angular.element(row.gridApi.element).length > 0)) {
                ehTabelaPolinizacao = (angular.element(row.gridApi.element).parents('#' + nomeTabelaPolinizacao).length > 0) ? true : false;
            }
            else {
                ehTabelaPolinizacao = true;
            }
            if (ehTabelaPolinizacao) {
                let gridPolinizacaoApi = null;
                let gridPolinizacaoScope = angular.element('#' + nomeTabelaPolinizacao).scope();
                if ((typeof gridPolinizacaoScope.listaGridApi !== 'undefined') && gridPolinizacaoScope.listaGridApi) {
                    angular.forEach(gridPolinizacaoScope.listaGridApi, (gridApi) => {
                        const grid = ((typeof gridApi.grid !== 'undefined') && gridApi.grid) ? gridApi.grid : null;
                        if (grid && (typeof grid.element !== 'undefined') && grid.element) {
                            const tabelasEl = angular.element(grid.element).parents('tabela');
                            if (tabelasEl && (tabelasEl.length > 0) && (tabelasEl.first().attr('id') === nomeTabelaPolinizacao)) {
                                gridPolinizacaoApi = gridApi;
                            }
                        }
                    });
                }
                if (gridPolinizacaoApi && (typeof gridPolinizacaoApi.selection !== 'undefined') && gridPolinizacaoApi.selection) {
                    gridPolinizacaoApi.selection.selectRow(row.entity);
                }
            }
        }
    }
    dadosListaPomares() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield PomarBancoConservacaoDadosListaUtil_1.PomarBancoConservacaoDadosListaUtil.dadosListaPomares(this);
        });
    }
    dadosListaPlanejamentoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield PomarBancoConservacaoDadosListaUtil_1.PomarBancoConservacaoDadosListaUtil.dadosListaPlanejamentoPomar(this);
        });
    }
    dadosListaManejoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield PomarBancoConservacaoDadosListaUtil_1.PomarBancoConservacaoDadosListaUtil.dadosListaManejoPomar(this);
        });
    }
    dadosListaEnxertiaPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield PomarBancoConservacaoDadosListaUtil_1.PomarBancoConservacaoDadosListaUtil.dadosListaEnxertiaPomar(this);
        });
    }
    dadosListaProdutividadePomar() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield PomarBancoConservacaoDadosListaUtil_1.PomarBancoConservacaoDadosListaUtil.dadosListaProdutividadePomar(this);
        });
    }
    dadosListaFenologiaPomar(seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield PomarBancoConservacaoDadosListaUtil_1.PomarBancoConservacaoDadosListaUtil.dadosListaFenologiaPomar(this, seletorCarregamento);
        });
    }
    dadosListaPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield PomarBancoConservacaoDadosListaUtil_1.PomarBancoConservacaoDadosListaUtil.dadosListaPolinizacaoPomar(this);
        });
    }
    dadosListaMonitoramentoFloracaoMasculinoPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield PomarBancoConservacaoDadosListaUtil_1.PomarBancoConservacaoDadosListaUtil.dadosListaMonitoramentoFloracaoMasculinoPolinizacaoPomar(this);
        });
    }
    dadosListaMonitoramentoFloracaoFemininoPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield PomarBancoConservacaoDadosListaUtil_1.PomarBancoConservacaoDadosListaUtil.dadosListaMonitoramentoFloracaoFemininoPolinizacaoPomar(this);
        });
    }
    dadosListaEstoquePolenPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield PomarBancoConservacaoDadosListaUtil_1.PomarBancoConservacaoDadosListaUtil.dadosListaEstoquePolenPolinizacaoPomar(this);
        });
    }
    dadosListaHistoricoEstoquePolenPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield PomarBancoConservacaoDadosListaUtil_1.PomarBancoConservacaoDadosListaUtil.dadosListaHistoricoEstoquePolenPolinizacaoPomar(this);
        });
    }
    dadosListaLancamentosPinusPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield PomarBancoConservacaoDadosListaUtil_1.PomarBancoConservacaoDadosListaUtil.dadosListaLancamentosPinusPolinizacaoPomar(this);
        });
    }
    dadosListaLancamentosEucalyptusPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield PomarBancoConservacaoDadosListaUtil_1.PomarBancoConservacaoDadosListaUtil.dadosListaLancamentosEucalyptusPolinizacaoPomar(this);
        });
    }
    dadosListaColetasConesSementesPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield PomarBancoConservacaoDadosListaUtil_1.PomarBancoConservacaoDadosListaUtil.dadosListaColetasConesSementesPolinizacaoPomar(this);
        });
    }
    dadosListaBeneficiamentosPinusPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield PomarBancoConservacaoDadosListaUtil_1.PomarBancoConservacaoDadosListaUtil.dadosListaBeneficiamentosPinusPolinizacaoPomar(this);
        });
    }
    dadosListaEstoqueBeneficiamentoPinusPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield PomarBancoConservacaoDadosListaUtil_1.PomarBancoConservacaoDadosListaUtil.dadosListaEstoqueBeneficiamentoPinusPolinizacaoPomar(this);
        });
    }
    dadosListaHistoricoEstoqueBeneficiamentoPinusPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield PomarBancoConservacaoDadosListaUtil_1.PomarBancoConservacaoDadosListaUtil.dadosListaHistoricoEstoqueBeneficiamentoPinusPolinizacaoPomar(this);
        });
    }
    dadosListaBeneficiamentosEucalyptusPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield PomarBancoConservacaoDadosListaUtil_1.PomarBancoConservacaoDadosListaUtil.dadosListaBeneficiamentosEucalyptusPolinizacaoPomar(this);
        });
    }
    dadosListaEstoqueBeneficiamentoEucalyptusPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield PomarBancoConservacaoDadosListaUtil_1.PomarBancoConservacaoDadosListaUtil.dadosListaEstoqueBeneficiamentoEucalyptusPolinizacaoPomar(this);
        });
    }
    dadosListaHistoricoEstoqueBeneficiamentoEucalyptusPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield PomarBancoConservacaoDadosListaUtil_1.PomarBancoConservacaoDadosListaUtil.dadosListaHistoricoEstoqueBeneficiamentoEucalyptusPolinizacaoPomar(this);
        });
    }
    dadosListaRelatorioPolinizacaoPinusPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield PomarBancoConservacaoDadosListaUtil_1.PomarBancoConservacaoDadosListaUtil.dadosListaRelatorioPolinizacaoPinusPomar(this);
        });
    }
    dadosListaRelatorioPolinizacaoEucalyptusPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield PomarBancoConservacaoDadosListaUtil_1.PomarBancoConservacaoDadosListaUtil.dadosListaRelatorioPolinizacaoEucalyptusPomar(this);
        });
    }
    dadosListaTratamentosPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield PomarBancoConservacaoDadosListaUtil_1.PomarBancoConservacaoDadosListaUtil.dadosListaTratamentosPomar(this);
        });
    }
    dadosListaClimaAnualPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield PomarBancoConservacaoDadosListaUtil_1.PomarBancoConservacaoDadosListaUtil.dadosListaClimaAnualPomar(this);
        });
    }
    dadosListaAnexosPomar(seletorCarregamento = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield PomarBancoConservacaoDadosListaUtil_1.PomarBancoConservacaoDadosListaUtil.dadosListaAnexosPomar(this, seletorCarregamento);
        });
    }
    atualizarGraficosClimaAnual() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.timeoutAtualizacaoGraficosClimaAnual !== 'undefined') && (this.timeoutAtualizacaoGraficosClimaAnual !== null)) {
                clearTimeout(this.timeoutAtualizacaoGraficosClimaAnual);
                this.timeoutAtualizacaoGraficosClimaAnual = null;
            }
            this.timeoutAtualizacaoGraficosClimaAnual = setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                if (!ctrl.pomarSelecionado) {
                    return;
                }
                if ((typeof ctrl.grafico1ClimaAnualPomar !== 'undefined') && ctrl.grafico1ClimaAnualPomar) {
                    try {
                        ctrl.grafico1ClimaAnualPomar.destroy();
                    }
                    catch (ex) {
                        console.log('PomarBancoConservacaoController.atualizarGraficosClimaAnual() -> ERRO (grafico1ClimaAnualPomar)');
                        console.log(ex);
                    }
                    ctrl.grafico1ClimaAnualPomar = null;
                }
                if ((typeof ctrl.grafico2ClimaAnualPomar !== 'undefined') && ctrl.grafico2ClimaAnualPomar) {
                    try {
                        ctrl.grafico2ClimaAnualPomar.destroy();
                    }
                    catch (ex) {
                        console.log('PomarBancoConservacaoController.atualizarGraficosClimaAnual() -> ERRO (grafico2ClimaAnualPomar)');
                        console.log(ex);
                    }
                    ctrl.grafico2ClimaAnualPomar = null;
                }
                for (let cargaGrafico = 1; cargaGrafico <= 10; cargaGrafico++) {
                    setTimeout((ctrl) => {
                        if (ctrl.grafico1ClimaAnualPomar) {
                            return;
                        }
                        let listaTemperaturasMedias = [];
                        let listaTemperaturasMaximas = [];
                        let listaTemperaturasMinimas = [];
                        if ((typeof ctrl.listaOpcoesClimaAnualPomar !== 'undefined') && ctrl.listaOpcoesClimaAnualPomar &&
                            (typeof ctrl.listaOpcoesClimaAnualPomar.data !== 'undefined') && ctrl.listaOpcoesClimaAnualPomar.data) {
                            angular.forEach(ctrl.listaOpcoesClimaAnualPomar.data, (item) => {
                                if ((typeof item.mes !== 'undefined') && item.mes && (item.mes.indexOf('Média') === -1)) {
                                    listaTemperaturasMedias.push((typeof item.temperaturaMedia !== 'undefined') ? item.temperaturaMedia : null);
                                    listaTemperaturasMaximas.push((typeof item.temperaturaMaxima !== 'undefined') ? item.temperaturaMaxima : null);
                                    listaTemperaturasMinimas.push((typeof item.temperaturaMinima !== 'undefined') ? item.temperaturaMinima : null);
                                }
                            });
                        }
                        const grafico1ClimaAnualPomarEl = document.getElementById('canvasGrafico1ClimaAnualPomar');
                        if ((typeof grafico1ClimaAnualPomarEl === 'undefined') || !grafico1ClimaAnualPomarEl ||
                            (grafico1ClimaAnualPomarEl && (grafico1ClimaAnualPomarEl.length === 0))) {
                            return;
                        }
                        const grafico1ClimaAnualPomarCtx = grafico1ClimaAnualPomarEl.getContext('2d');
                        const grafico1ClimaAnualPomarDataSets = [{
                                label: 'Média',
                                data: listaTemperaturasMedias,
                                backgroundColor: 'rgba(255,255,255,0)',
                                borderColor: 'rgb(51, 153, 102)',
                                borderWidth: 2,
                                datalabels: {
                                    display: true,
                                    font: {
                                        size: 11
                                    },
                                    padding: {
                                        left: 2,
                                        right: 2
                                    },
                                    backgroundColor: 'rgba(255,255,255,.3)',
                                    textShadowBlur: 2,
                                    textShadowColor: '#fff'
                                }
                            }, {
                                label: 'Máxima',
                                data: listaTemperaturasMaximas,
                                backgroundColor: 'rgba(255,255,255,0)',
                                borderColor: 'rgb(255, 0, 0)',
                                borderWidth: 2,
                                datalabels: {
                                    display: true,
                                    font: {
                                        size: 11
                                    },
                                    padding: {
                                        left: 2,
                                        right: 2
                                    },
                                    backgroundColor: 'rgba(255,255,255,.3)',
                                    textShadowBlur: 2,
                                    textShadowColor: '#fff'
                                }
                            }, {
                                label: 'Mínima',
                                data: listaTemperaturasMinimas,
                                backgroundColor: 'rgba(255,255,255,0)',
                                borderColor: 'rgb(51, 102, 255)',
                                borderWidth: 2,
                                datalabels: {
                                    display: true,
                                    font: {
                                        size: 11
                                    },
                                    padding: {
                                        left: 2,
                                        right: 2
                                    },
                                    backgroundColor: 'rgba(255,255,255,.3)',
                                    textShadowBlur: 2,
                                    textShadowColor: '#fff'
                                }
                            }];
                        ctrl.grafico1ClimaAnualPomar = new chart_js_1.Chart(grafico1ClimaAnualPomarCtx, {
                            type: 'line',
                            data: {
                                labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                                datasets: grafico1ClimaAnualPomarDataSets
                            },
                            plugins: [globalThis.ChartDataLabels],
                            options: {
                                title: {
                                    display: true,
                                    position: 'top',
                                    text: 'Temperaturas Médias Mensais (ºC)'
                                },
                                responsive: true,
                                maintainAspectRatio: false,
                                scales: {
                                    gridLines: { offsetGridLines: true },
                                    xAxes: [{
                                            ticks: {
                                                beginAtZero: true
                                            }
                                        }],
                                    yAxes: [{
                                            ticks: {
                                                beginAtZero: true
                                            }
                                        }]
                                }
                            }
                        });
                    }, cargaGrafico * 1000, ctrl);
                }
                for (let cargaGrafico = 1; cargaGrafico <= 10; cargaGrafico++) {
                    setTimeout((ctrl) => {
                        if (ctrl.grafico2ClimaAnualPomar) {
                            return;
                        }
                        let listaPrecipitacoesMedias = [];
                        let listaPrecipitacoesMaximas = [];
                        let listaPrecipitacoesMinimas = [];
                        if ((typeof ctrl.listaOpcoesClimaAnualPomar !== 'undefined') && ctrl.listaOpcoesClimaAnualPomar &&
                            (typeof ctrl.listaOpcoesClimaAnualPomar.data !== 'undefined') && ctrl.listaOpcoesClimaAnualPomar.data) {
                            angular.forEach(ctrl.listaOpcoesClimaAnualPomar.data, (item) => {
                                if ((typeof item.mes !== 'undefined') && item.mes && (item.mes.indexOf('Média') === -1)) {
                                    listaPrecipitacoesMedias.push((typeof item.precipitacaoMedia !== 'undefined') ? item.precipitacaoMedia : null);
                                    listaPrecipitacoesMaximas.push((typeof item.precipitacaoMaxima !== 'undefined') ? item.precipitacaoMaxima : null);
                                    listaPrecipitacoesMinimas.push((typeof item.precipitacaoMinima !== 'undefined') ? item.precipitacaoMinima : null);
                                }
                            });
                        }
                        const grafico2ClimaAnualPomarEl = document.getElementById('canvasGrafico2ClimaAnualPomar');
                        if ((typeof grafico2ClimaAnualPomarEl === 'undefined') || !grafico2ClimaAnualPomarEl ||
                            (grafico2ClimaAnualPomarEl && (grafico2ClimaAnualPomarEl.length === 0))) {
                            return;
                        }
                        const grafico2ClimaAnualPomarCtx = grafico2ClimaAnualPomarEl.getContext('2d');
                        const grafico2ClimaAnualPomarDataSets = [{
                                label: 'Média',
                                data: listaPrecipitacoesMedias,
                                backgroundColor: 'rgba(255,255,255,0)',
                                borderColor: 'rgb(51, 153, 102)',
                                borderWidth: 2,
                                datalabels: {
                                    display: true,
                                    font: {
                                        size: 11
                                    },
                                    padding: {
                                        left: 2,
                                        right: 2
                                    },
                                    backgroundColor: 'rgba(255,255,255,.3)',
                                    textShadowBlur: 2,
                                    textShadowColor: '#fff'
                                }
                            }, {
                                label: 'Máxima',
                                data: listaPrecipitacoesMaximas,
                                backgroundColor: 'rgba(255,255,255,0)',
                                borderColor: 'rgb(255, 0, 0)',
                                borderWidth: 2,
                                datalabels: {
                                    display: true,
                                    font: {
                                        size: 11
                                    },
                                    padding: {
                                        left: 2,
                                        right: 2
                                    },
                                    backgroundColor: 'rgba(255,255,255,.3)',
                                    textShadowBlur: 2,
                                    textShadowColor: '#fff'
                                }
                            }, {
                                label: 'Mínima',
                                data: listaPrecipitacoesMinimas,
                                backgroundColor: 'rgba(255,255,255,0)',
                                borderColor: 'rgb(51, 102, 255)',
                                borderWidth: 2,
                                datalabels: {
                                    display: true,
                                    font: {
                                        size: 11
                                    },
                                    padding: {
                                        left: 2,
                                        right: 2
                                    },
                                    backgroundColor: 'rgba(255,255,255,.3)',
                                    textShadowBlur: 2,
                                    textShadowColor: '#fff'
                                }
                            }];
                        ctrl.grafico2ClimaAnualPomar = new chart_js_1.Chart(grafico2ClimaAnualPomarCtx, {
                            type: 'line',
                            data: {
                                labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                                datasets: grafico2ClimaAnualPomarDataSets
                            },
                            plugins: [globalThis.ChartDataLabels],
                            options: {
                                title: {
                                    display: true,
                                    position: 'top',
                                    text: 'Precipitação Média Mensal (mm)'
                                },
                                responsive: true,
                                maintainAspectRatio: false,
                                scales: {
                                    gridLines: { offsetGridLines: true },
                                    xAxes: [{
                                            ticks: {
                                                beginAtZero: true
                                            }
                                        }],
                                    yAxes: [{
                                            ticks: {
                                                beginAtZero: true
                                            }
                                        }]
                                }
                            }
                        });
                    }, cargaGrafico * 1000, ctrl);
                }
            }), 250, this);
        });
    }
    filtraTalhoes() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.timeoutFiltroTalhoes !== 'undefined') && (this.timeoutFiltroTalhoes !== null)) {
                clearTimeout(this.timeoutFiltroTalhoes);
                this.timeoutFiltroTalhoes = null;
            }
            this.timeoutFiltroTalhoes = setTimeout((ctrl) => {
                if ((typeof ctrl.talhoes !== 'undefined') && (ctrl.talhoes !== null) && (ctrl.talhoes.length > 0)) {
                    if ((typeof ctrl.talhoesFiltrados !== 'undefined') && (ctrl.talhoesFiltrados !== null)) {
                        ctrl.talhoesFiltrados.splice(0, ctrl.talhoesFiltrados.length);
                    }
                    else {
                        ctrl.talhoesFiltrados = new Array();
                    }
                    if ((typeof ctrl.planejamentoPomarSelecionado !== 'undefined') && (ctrl.planejamentoPomarSelecionado !== null) &&
                        (typeof ctrl.planejamentoPomarSelecionado.regiao !== 'undefined') && (ctrl.planejamentoPomarSelecionado.regiao !== null)) {
                        if ((typeof ctrl.planejamentoPomarSelecionado.regiao.nome !== 'undefined') && (ctrl.planejamentoPomarSelecionado.regiao.nome !== null)) {
                            const s1 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(ctrl.planejamentoPomarSelecionado.regiao.nome);
                            angular.forEach(ctrl.talhoes, (talhao) => {
                                const s2 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(talhao.regiaoNome);
                                if (s1 === s2) {
                                    ctrl.talhoesFiltrados.push(talhao);
                                }
                            });
                        }
                    }
                    else if ((typeof ctrl.controleManejoPomarSelecionado !== 'undefined') && (ctrl.controleManejoPomarSelecionado !== null) &&
                        (typeof ctrl.controleManejoPomarSelecionado.regiao !== 'undefined') && (ctrl.controleManejoPomarSelecionado.regiao !== null)) {
                        if ((typeof ctrl.controleManejoPomarSelecionado.regiao.nome !== 'undefined') && (ctrl.controleManejoPomarSelecionado.regiao.nome !== null)) {
                            const s1 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(ctrl.controleManejoPomarSelecionado.regiao.nome);
                            angular.forEach(ctrl.talhoes, (talhao) => {
                                const s2 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(talhao.regiaoNome);
                                if (s1 === s2) {
                                    ctrl.talhoesFiltrados.push(talhao);
                                }
                            });
                        }
                    }
                    else if ((typeof ctrl.controleEnxertiaPomarSelecionado !== 'undefined') && (ctrl.controleEnxertiaPomarSelecionado !== null) &&
                        (typeof ctrl.controleEnxertiaPomarSelecionado.regiao !== 'undefined') && (ctrl.controleEnxertiaPomarSelecionado.regiao !== null)) {
                        if ((typeof ctrl.controleEnxertiaPomarSelecionado.regiao.nome !== 'undefined') && (ctrl.controleEnxertiaPomarSelecionado.regiao.nome !== null)) {
                            const s1 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(ctrl.controleEnxertiaPomarSelecionado.regiao.nome);
                            angular.forEach(ctrl.talhoes, (talhao) => {
                                const s2 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(talhao.regiaoNome);
                                if (s1 === s2) {
                                    ctrl.talhoesFiltrados.push(talhao);
                                }
                            });
                        }
                    }
                    else if ((typeof ctrl.produtividadePomarSelecionado !== 'undefined') && (ctrl.produtividadePomarSelecionado !== null) &&
                        (typeof ctrl.produtividadePomarSelecionado.regiao !== 'undefined') && (ctrl.produtividadePomarSelecionado.regiao !== null)) {
                        if ((typeof ctrl.produtividadePomarSelecionado.regiao.nome !== 'undefined') && (ctrl.produtividadePomarSelecionado.regiao.nome !== null)) {
                            const s1 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(ctrl.produtividadePomarSelecionado.regiao.nome);
                            angular.forEach(ctrl.talhoes, (talhao) => {
                                const s2 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(talhao.regiaoNome);
                                if (s1 === s2) {
                                    ctrl.talhoesFiltrados.push(talhao);
                                }
                            });
                        }
                    }
                    else if ((typeof ctrl.fenologiaPomarSelecionado !== 'undefined') && (ctrl.fenologiaPomarSelecionado !== null) &&
                        (typeof ctrl.fenologiaPomarSelecionado.regiao !== 'undefined') && (ctrl.fenologiaPomarSelecionado.regiao !== null)) {
                        if ((typeof ctrl.fenologiaPomarSelecionado.regiao.nome !== 'undefined') && (ctrl.fenologiaPomarSelecionado.regiao.nome !== null)) {
                            const s1 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(ctrl.fenologiaPomarSelecionado.regiao.nome);
                            angular.forEach(ctrl.talhoes, (talhao) => {
                                const s2 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(talhao.regiaoNome);
                                if (s1 === s2) {
                                    ctrl.talhoesFiltrados.push(talhao);
                                }
                            });
                        }
                    }
                    else if ((typeof ctrl.polinizacaoPomarSelecionado !== 'undefined') && (ctrl.polinizacaoPomarSelecionado !== null) &&
                        (typeof ctrl.polinizacaoPomarSelecionado.regiao !== 'undefined') && (ctrl.polinizacaoPomarSelecionado.regiao !== null)) {
                        if ((typeof ctrl.polinizacaoPomarSelecionado.regiao.nome !== 'undefined') && (ctrl.polinizacaoPomarSelecionado.regiao.nome !== null)) {
                            const s1 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(ctrl.polinizacaoPomarSelecionado.regiao.nome);
                            angular.forEach(ctrl.talhoes, (talhao) => {
                                const s2 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(talhao.regiaoNome);
                                if (s1 === s2) {
                                    ctrl.talhoesFiltrados.push(talhao);
                                }
                            });
                        }
                    }
                    else if ((typeof ctrl.anexoPomarSelecionado !== 'undefined') && (ctrl.anexoPomarSelecionado !== null) &&
                        (typeof ctrl.anexoPomarSelecionado.regiao !== 'undefined') && (ctrl.anexoPomarSelecionado.regiao !== null)) {
                        if ((typeof ctrl.anexoPomarSelecionado.regiao.nome !== 'undefined') && (ctrl.anexoPomarSelecionado.regiao.nome !== null)) {
                            const s1 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(ctrl.anexoPomarSelecionado.regiao.nome);
                            angular.forEach(ctrl.talhoes, (talhao) => {
                                const s2 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(talhao.regiaoNome);
                                if (s1 === s2) {
                                    ctrl.talhoesFiltrados.push(talhao);
                                }
                            });
                        }
                    }
                    else if ((typeof ctrl.pomarSelecionado !== 'undefined') && (ctrl.pomarSelecionado !== null) &&
                        (typeof ctrl.pomarSelecionado.regiao !== 'undefined') && (ctrl.pomarSelecionado.regiao !== null)) {
                        if ((typeof ctrl.pomarSelecionado.regiao.nome !== 'undefined') && (ctrl.pomarSelecionado.regiao.nome !== null)) {
                            const s1 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(ctrl.pomarSelecionado.regiao.nome);
                            angular.forEach(ctrl.talhoes, (talhao) => {
                                const s2 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(talhao.regiaoNome);
                                if (s1 === s2) {
                                    ctrl.talhoesFiltrados.push(talhao);
                                }
                            });
                        }
                    }
                    console.log('Talhões carregados: ' + ctrl.talhoes.length);
                    console.log('Talhões filtrados: ' + ctrl.talhoesFiltrados.length);
                    try {
                        ctrl.scopeApply();
                    }
                    catch (err) {
                        console.log(err);
                    }
                }
            }, 500, this);
        });
    }
    alterarTalhaoPomar() {
        if ((typeof this.pomarSelecionado !== 'undefined') && this.pomarSelecionado &&
            (typeof this.pomarSelecionado.talhao !== 'undefined') && this.pomarSelecionado.talhao) {
            if ((typeof this.pomarSelecionado.talhao.areaTalhao !== 'undefined') && this.pomarSelecionado.talhao.areaTalhao) {
                const areaTalhao = (typeof this.pomarSelecionado.talhao.areaTalhao !== 'number')
                    ? parseFloat(this.pomarSelecionado.talhao.areaTalhao) : this.pomarSelecionado.talhao.areaTalhao;
                if (areaTalhao > 0.01) {
                    this.pomarSelecionado.area = areaTalhao;
                    this.pomarSelecionado.delineamentoTotalArea = areaTalhao;
                }
            }
            this.atualizarMapaPomar();
        }
    }
    filtraMateriaisGeneticos() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.timeoutFiltroMateriaisGeneticos !== 'undefined') && (this.timeoutFiltroMateriaisGeneticos !== null)) {
                clearTimeout(this.timeoutFiltroMateriaisGeneticos);
                this.timeoutFiltroMateriaisGeneticos = null;
            }
            this.timeoutFiltroMateriaisGeneticos = setTimeout((ctrl) => {
                if ((typeof ctrl.materiaisGeneticos !== 'undefined') && (ctrl.materiaisGeneticos !== null) && (ctrl.materiaisGeneticos.length > 0)) {
                    if ((typeof ctrl.materiaisGeneticosFiltrados !== 'undefined') && (ctrl.materiaisGeneticosFiltrados !== null)) {
                        ctrl.materiaisGeneticosFiltrados.splice(0, ctrl.materiaisGeneticosFiltrados.length);
                    }
                    else {
                        ctrl.materiaisGeneticosFiltrados = new Array();
                    }
                    if ((typeof ctrl.listaPolensFiltrados !== 'undefined') && (ctrl.listaPolensFiltrados !== null)) {
                        ctrl.listaPolensFiltrados.splice(0, ctrl.listaPolensFiltrados.length);
                    }
                    else {
                        ctrl.listaPolensFiltrados = new Array();
                    }
                    if ((typeof ctrl.selectizeOptionsMateriaisGeneticos === 'undefined') || (ctrl.selectizeOptionsMateriaisGeneticos === null)) {
                        ctrl.selectizeOptionsMateriaisGeneticos = new Array();
                    }
                    else if (ctrl.selectizeOptionsMateriaisGeneticos.length > 0) {
                        ctrl.selectizeOptionsMateriaisGeneticos.splice(0, ctrl.selectizeOptionsMateriaisGeneticos.length);
                    }
                    if ((typeof ctrl.pomarSelecionado !== 'undefined') && (ctrl.pomarSelecionado !== null) &&
                        (typeof ctrl.listaOpcoesTratamentosPomar !== 'undefined') && (ctrl.listaOpcoesTratamentosPomar !== null) &&
                        (typeof ctrl.listaOpcoesTratamentosPomar.data !== 'undefined') && (ctrl.listaOpcoesTratamentosPomar.data !== null)) {
                        let materiaisGeneticosPomar = new Array();
                        angular.forEach(ctrl.listaOpcoesTratamentosPomar.data, (apontamento) => {
                            if ((typeof apontamento.detalhamento !== 'undefined') && apontamento.detalhamento) {
                                if ((typeof apontamento.detalhamento.pomarValor !== 'undefined') && apontamento.detalhamento.pomarValor) {
                                    if (materiaisGeneticosPomar.indexOf(apontamento.detalhamento.pomarValor) === -1) {
                                        materiaisGeneticosPomar.push(apontamento.detalhamento.pomarValor);
                                    }
                                }
                                else if ((typeof apontamento.detalhamento.materialGeneticoDescricao !== 'undefined') && apontamento.detalhamento.materialGeneticoDescricao) {
                                    if (materiaisGeneticosPomar.indexOf(apontamento.detalhamento.materialGeneticoDescricao) === -1) {
                                        materiaisGeneticosPomar.push(apontamento.detalhamento.materialGeneticoDescricao);
                                    }
                                }
                            }
                        });
                        angular.forEach(materiaisGeneticosPomar, (descricaoMaterialGenetico) => {
                            if ((typeof descricaoMaterialGenetico !== 'undefined') && (descricaoMaterialGenetico !== null) && (descricaoMaterialGenetico.length > 0)) {
                                const s1 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(descricaoMaterialGenetico);
                                let achouMaterialGenetico = false;
                                angular.forEach(ctrl.materiaisGeneticos, (materialGenetico) => {
                                    if (!achouMaterialGenetico) {
                                        const s2 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(materialGenetico.descricao);
                                        if (s1 === s2) {
                                            ctrl.materiaisGeneticosFiltrados.push(materialGenetico);
                                            achouMaterialGenetico = true;
                                        }
                                    }
                                });
                                if (!achouMaterialGenetico) {
                                    let novoMaterialGenetico = new MaterialGenetico_1.MaterialGenetico();
                                    novoMaterialGenetico.status = true;
                                    novoMaterialGenetico.criacao = new Date();
                                    novoMaterialGenetico.descricao = descricaoMaterialGenetico;
                                    const promiseMaterialGenetico = ctrl.sincronia
                                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.MATERIAL_GENETICO)
                                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MATERIAL_GENETICO)
                                        .criar(novoMaterialGenetico);
                                    promiseMaterialGenetico.then((resposta) => {
                                        if ((typeof resposta.id !== 'undefined') && resposta.id) {
                                            novoMaterialGenetico.id = resposta.id;
                                        }
                                        if ((typeof resposta.uuid !== 'undefined') && resposta.uuid) {
                                            novoMaterialGenetico.uuid = resposta.uuid;
                                        }
                                        if ((typeof resposta.codigo !== 'undefined') && resposta.codigo) {
                                            novoMaterialGenetico.codigo = resposta.codigo;
                                        }
                                        ctrl.materiaisGeneticosFiltrados.push(novoMaterialGenetico);
                                        ctrl.selectizeOptionsMateriaisGeneticos.push({
                                            text: novoMaterialGenetico.descricao,
                                            value: novoMaterialGenetico.id,
                                            $order: ctrl.selectizeOptionsMateriaisGeneticos.length + 1
                                        });
                                        ctrl.scopeApply();
                                    });
                                }
                            }
                        });
                        if (ctrl.materiaisGeneticosFiltrados) {
                            let tmpMateriaisGeneticos = [
                                { text: '(Selecionar Todos)', value: 'TODOS', $order: 1 },
                                { text: '(Desmarcar Todos)', value: 'NENHUM', $order: 2 }
                            ];
                            angular.forEach(ctrl.materiaisGeneticosFiltrados, (materialGenetico) => {
                                tmpMateriaisGeneticos.push({
                                    text: materialGenetico.descricao,
                                    value: materialGenetico.id,
                                    $order: tmpMateriaisGeneticos.length + 1
                                });
                            });
                            if ((typeof ctrl.selectizeOptionsMateriaisGeneticos === 'undefined') || (ctrl.selectizeOptionsMateriaisGeneticos === null)) {
                                ctrl.selectizeOptionsMateriaisGeneticos = new Array();
                            }
                            else if (ctrl.selectizeOptionsMateriaisGeneticos.length > 0) {
                                ctrl.selectizeOptionsMateriaisGeneticos.splice(0, ctrl.selectizeOptionsMateriaisGeneticos.length);
                            }
                            ctrl.selectizeOptionsMateriaisGeneticos.push(...tmpMateriaisGeneticos);
                        }
                        let polens = new Array();
                        angular.forEach(ctrl.listaOpcoesTratamentosPomar.data, (apontamento) => {
                            if ((typeof apontamento.detalhamento !== 'undefined') && apontamento.detalhamento &&
                                (typeof apontamento.detalhamento.polemDescricao !== 'undefined') && apontamento.detalhamento.polemDescricao) {
                                if (polens.indexOf(apontamento.detalhamento.polemDescricao) === -1) {
                                    polens.push(apontamento.detalhamento.polemDescricao);
                                }
                            }
                        });
                        angular.forEach(polens, (descricaoPolem) => {
                            if ((typeof descricaoPolem !== 'undefined') && (descricaoPolem !== null) && (descricaoPolem.length > 0)) {
                                let tmpSgifPolem = new MaterialGenetico_1.MaterialGenetico();
                                tmpSgifPolem.status = true;
                                tmpSgifPolem.criacao = new Date();
                                tmpSgifPolem.descricao = descricaoPolem;
                                ctrl.listaPolensFiltrados.push(tmpSgifPolem);
                            }
                        });
                    }
                    console.log('Materiais Genéticos carregados: ' + ctrl.materiaisGeneticos.length);
                    console.log('Materiais Genéticos filtrados: ' + ctrl.materiaisGeneticosFiltrados.length);
                    if (!ctrl.materiaisGeneticosFiltrados || (ctrl.materiaisGeneticosFiltrados.length === 0)) {
                        if (ctrl.materiaisGeneticos && (ctrl.materiaisGeneticos.length > 0)) {
                            ctrl.materiaisGeneticosFiltrados.push(...ctrl.materiaisGeneticos);
                            console.log('Materiais Genéticos filtrados #2: ' + ctrl.materiaisGeneticosFiltrados.length);
                        }
                    }
                    try {
                        ctrl.scopeApply();
                    }
                    catch (err) {
                        console.log(err);
                    }
                }
            }, 500, this);
        });
    }
    formatarNumero(valor, casasDecimais) {
        return NumeroUtil_1.NumeroUtil.formatarNumero(valor, casasDecimais);
    }
    selecionarPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            let ctrl = this;
            if (ctrl && ctrl.constructor && ctrl.constructor.name) {
                if (ctrl.constructor.name === 'TabelaController') {
                    ctrl = ctrl.$scope.$parent.$ctrl;
                }
            }
            ctrl.filtrarPomarSelecionado();
        });
    }
    limparFiltroPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.pomarSelecionado = null;
            this.pomarCarregado = false;
            this.tratamentoPomarSelecionado = null;
            this.croquiPomarSelecionado = null;
            this.mapaPomarSelecionado = null;
            this.importaShapefileSelecionado = null;
            this.mapaLiberado = false;
            this.controleManejoPomarSelecionado = null;
            this.controleEnxertiaPomarSelecionado = null;
            this.polinizacaoPomarSelecionado = null;
            this.anexoPomarSelecionado = null;
            this.filtroPlanejamentoPomar = new FiltroPlanejamentoPomar_1.FiltroPlanejamentoPomar();
            this.filtroControleManejoPomar = new FiltroControleManejoPomar_1.FiltroControleManejoPomar();
            this.filtroControleEnxertiaPomar = new FiltroControleEnxertiaPomar_1.FiltroControleEnxertiaPomar();
            this.filtroProdutividadePomar = new FiltroProdutividadePomar_1.FiltroProdutividadePomar();
            this.listaOpcoesPlanejamentoPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesManejoPomarResumido = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesManejoPomarDetalhado = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesEnxertiaPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesProdutividadePomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesFenologiaPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesPolinizacaoPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesMonitoramentoFloracaoMasculinoPolinizacaoPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesMonitoramentoFloracaoFemininoPolinizacaoPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesEstoquePolenPolinizacaoPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesHistoricoEstoquePolenPolinizacaoPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesLancamentosPinusPolinizacaoPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesLancamentosEucalyptusPolinizacaoPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesColetasConesSementesPolinizacaoPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesBeneficiamentosPinusPolinizacaoPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesEstoqueBeneficiamentoPinusPolinizacaoPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesHistoricoEstoqueBeneficiamentoPinusPolinizacaoPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesBeneficiamentosEucalyptusPolinizacaoPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesEstoqueBeneficiamentoEucalyptusPolinizacaoPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesHistoricoEstoqueBeneficiamentoEucalyptusPolinizacaoPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesRelatorioPolinizacaoPinusPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesRelatorioPolinizacaoEucalyptusPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesTratamentosPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesClimaAnualPomar = {
                data: [],
                columnDefs: []
            };
            this.listaOpcoesAnexosPomar = {
                data: [],
                columnDefs: []
            };
            if (angular.element('#planilha_croqui_pomar').length > 0) {
                if ((typeof globalThis.luckysheet !== 'undefined') && globalThis.luckysheet) {
                    globalThis.luckysheet.destroy();
                }
                angular.element('#planilha_croqui_pomar').empty();
            }
        });
    }
    filtrarPomarSelecionado() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                let pomar = null;
                const nomeTabelaPomares = 'tabelaPomarBancoConservacaoes';
                let gridPomaresApi = null;
                let gridPomaresScope = angular.element('#' + nomeTabelaPomares).scope();
                if ((typeof gridPomaresScope.listaGridApi !== 'undefined') && gridPomaresScope.listaGridApi) {
                    angular.forEach(gridPomaresScope.listaGridApi, (gridApi) => {
                        const grid = ((typeof gridApi.grid !== 'undefined') && gridApi.grid) ? gridApi.grid : null;
                        if (grid && (typeof grid.element !== 'undefined') && grid.element) {
                            const tabelasEl = angular.element(grid.element).parents('tabela');
                            if (tabelasEl && (tabelasEl.length > 0) && (tabelasEl.first().attr('id') === nomeTabelaPomares)) {
                                gridPomaresApi = gridApi;
                            }
                        }
                    });
                }
                if (gridPomaresApi) {
                    const pomaresSelecionados = gridPomaresApi.selection.getSelectedRows();
                    if ((typeof pomaresSelecionados !== 'undefined') && pomaresSelecionados) {
                        angular.forEach(pomaresSelecionados, (obj) => {
                            if (!pomar) {
                                pomar = obj;
                            }
                        });
                    }
                }
                if (pomar && (typeof pomar.id !== 'undefined') && pomar.id) {
                    if ((typeof ctrl.pomarSelecionado !== 'undefined') && ctrl.pomarSelecionado &&
                        (typeof ctrl.pomarSelecionado.id !== 'undefined') && ctrl.pomarSelecionado.id) {
                        if (ctrl.pomarSelecionado.id !== pomar.id) {
                            yield ctrl.limparFiltroPomar();
                            ctrl.pomarSelecionado = pomar;
                            ctrl.pomarCarregado = false;
                        }
                    }
                    else {
                        yield ctrl.limparFiltroPomar();
                        ctrl.pomarSelecionado = pomar;
                        ctrl.pomarCarregado = false;
                    }
                }
                else {
                    if (ctrl.pomarSelecionado || ctrl.pomarCarregado) {
                        yield ctrl.limparFiltroPomar();
                        ctrl.pomarCarregado = false;
                    }
                }
                ctrl.scopeApply();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
        });
    }
    filtrarPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const ctrl = this;
                yield this.filtrarPomarSelecionado();
                if (!this.pomarCarregado && this.pomarSelecionado && this.pomarSelecionado.id) {
                    if (angular.element('#planilha_croqui_pomar').length > 0) {
                        if ((typeof globalThis.luckysheet !== 'undefined') && globalThis.luckysheet) {
                            globalThis.luckysheet.destroy();
                        }
                        angular.element('#planilha_croqui_pomar').empty();
                    }
                    if ((typeof this.pomarSelecionado.genero === 'undefined') || !this.pomarSelecionado.genero) {
                        let achouGenero = false;
                        if ((typeof this.pomarSelecionado.generoId !== 'undefined') && this.pomarSelecionado.generoId &&
                            (typeof this.generos !== 'undefined') && this.generos) {
                            angular.forEach(this.generos, (genero) => {
                                if (!achouGenero && genero && genero.id && (genero.id === ctrl.pomarSelecionado.generoId)) {
                                    ctrl.pomarSelecionado.genero = genero;
                                    achouGenero = true;
                                }
                            });
                        }
                        if (!achouGenero) {
                            if ((typeof this.pomarSelecionado.generoDescricao !== 'undefined') && this.pomarSelecionado.generoDescricao &&
                                (typeof this.generos !== 'undefined') && this.generos) {
                                angular.forEach(this.generos, (genero) => {
                                    if (!achouGenero && genero && genero.descricao && (genero.descricao === ctrl.pomarSelecionado.generoDescricao)) {
                                        ctrl.pomarSelecionado.genero = genero;
                                        achouGenero = true;
                                    }
                                });
                            }
                        }
                        if (!achouGenero) {
                            if ((typeof this.pomarSelecionado.generoId !== 'undefined') && this.pomarSelecionado.generoId &&
                                (typeof this.pomarSelecionado.generoDescricao !== 'undefined') && this.pomarSelecionado.generoDescricao) {
                                ctrl.pomarSelecionado.genero = new Genero_1.Genero();
                                ctrl.pomarSelecionado.genero.id = this.pomarSelecionado.generoId;
                                ctrl.pomarSelecionado.genero.codigo = (typeof this.pomarSelecionado.generoCodigo !== 'undefined') ? this.pomarSelecionado.generoCodigo : null;
                                ctrl.pomarSelecionado.genero.descricao = this.pomarSelecionado.generoDescricao;
                            }
                        }
                    }
                    if ((typeof this.pomarSelecionado.especie === 'undefined') || !this.pomarSelecionado.especie) {
                        let achouEspecie = false;
                        if ((typeof this.pomarSelecionado.especieId !== 'undefined') && this.pomarSelecionado.especieId &&
                            (typeof this.especies !== 'undefined') && this.especies) {
                            angular.forEach(this.especies, (especie) => {
                                if (!achouEspecie && especie && especie.id && (especie.id === ctrl.pomarSelecionado.especieId)) {
                                    ctrl.pomarSelecionado.especie = especie;
                                    achouEspecie = true;
                                }
                            });
                        }
                        if (!achouEspecie) {
                            if ((typeof this.pomarSelecionado.especieDescricao !== 'undefined') && this.pomarSelecionado.especieDescricao &&
                                (typeof this.especies !== 'undefined') && this.especies) {
                                angular.forEach(this.especies, (especie) => {
                                    if (!achouEspecie && especie && especie.descricao && (especie.descricao === ctrl.pomarSelecionado.especieDescricao)) {
                                        ctrl.pomarSelecionado.especie = especie;
                                        achouEspecie = true;
                                    }
                                });
                            }
                        }
                        if (!achouEspecie) {
                            if ((typeof this.pomarSelecionado.especieId !== 'undefined') && this.pomarSelecionado.especieId &&
                                (typeof this.pomarSelecionado.especieDescricao !== 'undefined') && this.pomarSelecionado.especieDescricao) {
                                ctrl.pomarSelecionado.especie = new Especie_1.Especie();
                                ctrl.pomarSelecionado.especie.id = this.pomarSelecionado.especieId;
                                ctrl.pomarSelecionado.especie.codigo = (typeof this.pomarSelecionado.especieCodigo !== 'undefined') ? this.pomarSelecionado.especieCodigo : null;
                                ctrl.pomarSelecionado.especie.descricao = this.pomarSelecionado.especieDescricao;
                            }
                        }
                    }
                    if ((typeof this.pomarSelecionado.geracao === 'undefined') || !this.pomarSelecionado.geracao) {
                        let achouGeracao = false;
                        if ((typeof this.pomarSelecionado.geracaoId !== 'undefined') && this.pomarSelecionado.geracaoId &&
                            (typeof this.geracoes !== 'undefined') && this.geracoes) {
                            angular.forEach(this.geracoes, (geracao) => {
                                if (!achouGeracao && geracao && geracao.id && (geracao.id === ctrl.pomarSelecionado.geracaoId)) {
                                    ctrl.pomarSelecionado.geracao = geracao;
                                    achouGeracao = true;
                                }
                            });
                        }
                        if (!achouGeracao) {
                            if ((typeof this.pomarSelecionado.geracaoDescricao !== 'undefined') && this.pomarSelecionado.geracaoDescricao &&
                                (typeof this.geracoes !== 'undefined') && this.geracoes) {
                                angular.forEach(this.geracoes, (geracao) => {
                                    if (!achouGeracao && geracao && geracao.descricao && (geracao.descricao === ctrl.pomarSelecionado.geracaoDescricao)) {
                                        ctrl.pomarSelecionado.geracao = geracao;
                                        achouGeracao = true;
                                    }
                                });
                            }
                        }
                        if (!achouGeracao) {
                            if ((typeof this.pomarSelecionado.geracaoId !== 'undefined') && this.pomarSelecionado.geracaoId &&
                                (typeof this.pomarSelecionado.geracaoDescricao !== 'undefined') && this.pomarSelecionado.geracaoDescricao) {
                                ctrl.pomarSelecionado.geracao = new Geracao_1.Geracao();
                                ctrl.pomarSelecionado.geracao.id = this.pomarSelecionado.geracaoId;
                                ctrl.pomarSelecionado.geracao.codigo = (typeof this.pomarSelecionado.geracaoCodigo !== 'undefined') ? this.pomarSelecionado.geracaoCodigo : null;
                                ctrl.pomarSelecionado.geracao.descricao = this.pomarSelecionado.geracaoDescricao;
                            }
                        }
                    }
                    if ((typeof this.pomarSelecionado.tipo === 'undefined') || !this.pomarSelecionado.tipo) {
                        let achouTipoPomar = false;
                        if ((typeof this.pomarSelecionado.tipoId !== 'undefined') && this.pomarSelecionado.tipoId &&
                            (typeof this.tiposPomares !== 'undefined') && this.tiposPomares) {
                            angular.forEach(this.tiposPomares, (tipoPomar) => {
                                if (!achouTipoPomar && tipoPomar && tipoPomar.id && (tipoPomar.id === ctrl.pomarSelecionado.tipoId)) {
                                    ctrl.pomarSelecionado.tipo = tipoPomar;
                                    achouTipoPomar = true;
                                }
                            });
                        }
                        if (!achouTipoPomar) {
                            if ((typeof this.pomarSelecionado.tipoDescricao !== 'undefined') && this.pomarSelecionado.tipoDescricao &&
                                (typeof this.tiposPomares !== 'undefined') && this.tiposPomares) {
                                angular.forEach(this.tiposPomares, (tipoPomar) => {
                                    if (!achouTipoPomar && tipoPomar && tipoPomar.descricao && (tipoPomar.descricao === ctrl.pomarSelecionado.tipoDescricao)) {
                                        ctrl.pomarSelecionado.tipo = tipoPomar;
                                        achouTipoPomar = true;
                                    }
                                });
                            }
                        }
                        if (!achouTipoPomar) {
                            if ((typeof this.pomarSelecionado.tipoTitulo !== 'undefined') && this.pomarSelecionado.tipoTitulo &&
                                (typeof this.tiposPomares !== 'undefined') && this.tiposPomares) {
                                angular.forEach(this.tiposPomares, (tipoPomar) => {
                                    if (!achouTipoPomar && tipoPomar && tipoPomar.titulo && (tipoPomar.titulo === ctrl.pomarSelecionado.tipoTitulo)) {
                                        ctrl.pomarSelecionado.tipo = tipoPomar;
                                        achouTipoPomar = true;
                                    }
                                });
                            }
                        }
                        if (!achouTipoPomar) {
                            if ((typeof this.pomarSelecionado.tipoSigla !== 'undefined') && this.pomarSelecionado.tipoSigla &&
                                (typeof this.tiposPomares !== 'undefined') && this.tiposPomares) {
                                angular.forEach(this.tiposPomares, (tipoPomar) => {
                                    if (!achouTipoPomar && tipoPomar && tipoPomar.sigla && (tipoPomar.sigla === ctrl.pomarSelecionado.tipoSigla)) {
                                        ctrl.pomarSelecionado.tipo = tipoPomar;
                                        achouTipoPomar = true;
                                    }
                                });
                            }
                        }
                        if (!achouTipoPomar) {
                            if ((typeof this.pomarSelecionado.tipoId !== 'undefined') && this.pomarSelecionado.tipoId &&
                                (typeof this.pomarSelecionado.tipoDescricao !== 'undefined') && this.pomarSelecionado.tipoDescricao) {
                                ctrl.pomarSelecionado.tipo = new TipoPomar_1.TipoPomar();
                                ctrl.pomarSelecionado.tipo.id = this.pomarSelecionado.tipoId;
                                ctrl.pomarSelecionado.tipo.codigo = (typeof this.pomarSelecionado.tipoCodigo !== 'undefined') ? this.pomarSelecionado.tipoCodigo : null;
                                ctrl.pomarSelecionado.tipo.sigla = (typeof this.pomarSelecionado.tipoSigla !== 'undefined') ? this.pomarSelecionado.tipoSigla : null;
                                ctrl.pomarSelecionado.tipo.titulo = (typeof this.pomarSelecionado.tipoTitulo !== 'undefined') ? this.pomarSelecionado.tipoTitulo : null;
                                ctrl.pomarSelecionado.tipo.descricao = (typeof this.pomarSelecionado.tipoTitulo !== 'undefined') ? this.pomarSelecionado.tipoDescricao : null;
                            }
                        }
                    }
                    if ((typeof this.pomarSelecionado.provinciaUf === 'undefined') || !this.pomarSelecionado.provinciaUf) {
                        let achouProvinciaUf = false;
                        if ((typeof this.pomarSelecionado.provinciaUfId !== 'undefined') && this.pomarSelecionado.provinciaUfId &&
                            (typeof this.provinciasUf !== 'undefined') && this.provinciasUf) {
                            angular.forEach(this.provinciasUf, (provinciaUf) => {
                                if (!achouProvinciaUf && provinciaUf && provinciaUf.id && (provinciaUf.id === ctrl.pomarSelecionado.provinciaUfId)) {
                                    ctrl.pomarSelecionado.provinciaUf = provinciaUf;
                                    achouProvinciaUf = true;
                                }
                            });
                        }
                        if (!achouProvinciaUf) {
                            if ((typeof this.pomarSelecionado.provinciaUfDescricao !== 'undefined') && this.pomarSelecionado.provinciaUfDescricao &&
                                (typeof this.provinciasUf !== 'undefined') && this.provinciasUf) {
                                angular.forEach(this.provinciasUf, (provinciaUf) => {
                                    if (!achouProvinciaUf && provinciaUf && provinciaUf.descricao && (provinciaUf.descricao === ctrl.pomarSelecionado.provinciaUfDescricao)) {
                                        ctrl.pomarSelecionado.provinciaUf = provinciaUf;
                                        achouProvinciaUf = true;
                                    }
                                });
                            }
                        }
                        if (!achouProvinciaUf) {
                            if ((typeof this.pomarSelecionado.provinciaUfId !== 'undefined') && this.pomarSelecionado.provinciaUfId &&
                                (typeof this.pomarSelecionado.provinciaUfDescricao !== 'undefined') && this.pomarSelecionado.provinciaUfDescricao) {
                                ctrl.pomarSelecionado.provinciaUf = new ProvinciaUf_1.ProvinciaUf();
                                ctrl.pomarSelecionado.provinciaUf.id = this.pomarSelecionado.provinciaUfId;
                                ctrl.pomarSelecionado.provinciaUf.codigo = (typeof this.pomarSelecionado.provinciaUfCodigo !== 'undefined') ? this.pomarSelecionado.provinciaUfCodigo : null;
                                ctrl.pomarSelecionado.provinciaUf.sigla = (typeof this.pomarSelecionado.provinciaUfSigla !== 'undefined') ? this.pomarSelecionado.provinciaUfSigla : null;
                                ctrl.pomarSelecionado.provinciaUf.descricao = this.pomarSelecionado.provinciaUfDescricao;
                            }
                        }
                    }
                    if ((typeof this.pomarSelecionado.pais === 'undefined') || !this.pomarSelecionado.pais) {
                        let achouPais = false;
                        if ((typeof this.pomarSelecionado.paisId !== 'undefined') && this.pomarSelecionado.paisId &&
                            (typeof this.paises !== 'undefined') && this.paises) {
                            angular.forEach(this.paises, (pais) => {
                                if (!achouPais && pais && pais.id && (pais.id === ctrl.pomarSelecionado.paisId)) {
                                    ctrl.pomarSelecionado.pais = pais;
                                    achouPais = true;
                                }
                            });
                        }
                        if (!achouPais) {
                            if ((typeof this.pomarSelecionado.paisDescricao !== 'undefined') && this.pomarSelecionado.paisDescricao &&
                                (typeof this.paises !== 'undefined') && this.paises) {
                                angular.forEach(this.paises, (pais) => {
                                    if (!achouPais && pais && pais.descricao && (pais.descricao === ctrl.pomarSelecionado.paisDescricao)) {
                                        ctrl.pomarSelecionado.pais = pais;
                                        achouPais = true;
                                    }
                                });
                            }
                        }
                        if (!achouPais) {
                            if ((typeof this.pomarSelecionado.paisId !== 'undefined') && this.pomarSelecionado.paisId &&
                                (typeof this.pomarSelecionado.paisDescricao !== 'undefined') && this.pomarSelecionado.paisDescricao) {
                                ctrl.pomarSelecionado.pais = new Pais_1.Pais();
                                ctrl.pomarSelecionado.pais.id = this.pomarSelecionado.paisId;
                                ctrl.pomarSelecionado.pais.codigo = (typeof this.pomarSelecionado.paisCodigo !== 'undefined') ? this.pomarSelecionado.paisCodigo : null;
                                ctrl.pomarSelecionado.pais.descricao = this.pomarSelecionado.paisDescricao;
                            }
                        }
                    }
                    if ((typeof this.pomarSelecionado.tipoImplantacao === 'undefined') || !this.pomarSelecionado.tipoImplantacao) {
                        let achouTipoImplantacao = false;
                        if ((typeof this.pomarSelecionado.tipoImplantacaoId !== 'undefined') && this.pomarSelecionado.tipoImplantacaoId &&
                            (typeof this.tiposImplantacoes !== 'undefined') && this.tiposImplantacoes) {
                            angular.forEach(this.tiposImplantacoes, (tipoImplantacao) => {
                                if (!achouTipoImplantacao && tipoImplantacao && tipoImplantacao.id && (tipoImplantacao.id === ctrl.pomarSelecionado.tipoImplantacaoId)) {
                                    ctrl.pomarSelecionado.tipoImplantacao = tipoImplantacao;
                                    achouTipoImplantacao = true;
                                }
                            });
                        }
                        if (!achouTipoImplantacao) {
                            if ((typeof this.pomarSelecionado.tipoImplantacaoDescricao !== 'undefined') && this.pomarSelecionado.tipoImplantacaoDescricao &&
                                (typeof this.tiposImplantacoes !== 'undefined') && this.tiposImplantacoes) {
                                angular.forEach(this.tiposImplantacoes, (tipoImplantacao) => {
                                    if (!achouTipoImplantacao && tipoImplantacao && tipoImplantacao.descricao && (tipoImplantacao.descricao === ctrl.pomarSelecionado.tipoImplantacaoDescricao)) {
                                        ctrl.pomarSelecionado.tipoImplantacao = tipoImplantacao;
                                        achouTipoImplantacao = true;
                                    }
                                });
                            }
                        }
                        if (!achouTipoImplantacao) {
                            if ((typeof this.pomarSelecionado.tipoImplantacaoId !== 'undefined') && this.pomarSelecionado.tipoImplantacaoId &&
                                (typeof this.pomarSelecionado.tipoImplantacaoDescricao !== 'undefined') && this.pomarSelecionado.tipoImplantacaoDescricao) {
                                ctrl.pomarSelecionado.tipoImplantacao = new TipoImplantacao_1.TipoImplantacao();
                                ctrl.pomarSelecionado.tipoImplantacao.id = this.pomarSelecionado.tipoImplantacaoId;
                                ctrl.pomarSelecionado.tipoImplantacao.codigo = (typeof this.pomarSelecionado.tipoImplantacaoCodigo !== 'undefined') ? this.pomarSelecionado.tipoImplantacaoCodigo : null;
                                ctrl.pomarSelecionado.tipoImplantacao.descricao = this.pomarSelecionado.tipoImplantacaoDescricao;
                            }
                        }
                    }
                    if ((typeof this.pomarSelecionado.fonte === 'undefined') || !this.pomarSelecionado.fonte) {
                        let achouFonte = false;
                        if ((typeof this.pomarSelecionado.fonteId !== 'undefined') && this.pomarSelecionado.fonteId &&
                            (typeof this.fontes !== 'undefined') && this.fontes) {
                            angular.forEach(this.fontes, (fonte) => {
                                if (!achouFonte && fonte && fonte.id && (fonte.id === ctrl.pomarSelecionado.fonteId)) {
                                    ctrl.pomarSelecionado.fonte = fonte;
                                    achouFonte = true;
                                }
                            });
                        }
                        if (!achouFonte) {
                            if ((typeof this.pomarSelecionado.fonteDescricao !== 'undefined') && this.pomarSelecionado.fonteDescricao &&
                                (typeof this.fontes !== 'undefined') && this.fontes) {
                                angular.forEach(this.fontes, (fonte) => {
                                    if (!achouFonte && fonte && fonte.descricao && (fonte.descricao === ctrl.pomarSelecionado.fonteDescricao)) {
                                        ctrl.pomarSelecionado.fonte = fonte;
                                        achouFonte = true;
                                    }
                                });
                            }
                        }
                        if (!achouFonte) {
                            if ((typeof this.pomarSelecionado.fonteId !== 'undefined') && this.pomarSelecionado.fonteId &&
                                (typeof this.pomarSelecionado.fonteDescricao !== 'undefined') && this.pomarSelecionado.fonteDescricao) {
                                ctrl.pomarSelecionado.fonte = new Fonte_1.Fonte();
                                ctrl.pomarSelecionado.fonte.id = this.pomarSelecionado.fonteId;
                                ctrl.pomarSelecionado.fonte.codigo = (typeof this.pomarSelecionado.fonteCodigo !== 'undefined') ? this.pomarSelecionado.fonteCodigo : null;
                                ctrl.pomarSelecionado.fonte.descricao = this.pomarSelecionado.fonteDescricao;
                            }
                        }
                    }
                    if ((typeof this.pomarSelecionado.tipo === 'undefined') || !this.pomarSelecionado.tipo) {
                        let achouTipoPomar = false;
                        if ((typeof this.pomarSelecionado.tipoId !== 'undefined') && this.pomarSelecionado.tipoId &&
                            (typeof this.tiposPomares !== 'undefined') && this.tiposPomares) {
                            angular.forEach(this.tiposPomares, (tipoPomar) => {
                                if (!achouTipoPomar && tipoPomar && tipoPomar.id && (tipoPomar.id === ctrl.pomarSelecionado.tipoId)) {
                                    ctrl.pomarSelecionado.tipo = tipoPomar;
                                    achouTipoPomar = true;
                                }
                            });
                        }
                        if (!achouTipoPomar) {
                            if ((typeof this.pomarSelecionado.tipoDescricao !== 'undefined') && this.pomarSelecionado.tipoDescricao &&
                                (typeof this.tiposPomares !== 'undefined') && this.tiposPomares) {
                                angular.forEach(this.tiposPomares, (tipoPomar) => {
                                    if (!achouTipoPomar && tipoPomar && tipoPomar.descricao && (tipoPomar.descricao === ctrl.pomarSelecionado.tipoDescricao)) {
                                        ctrl.pomarSelecionado.tipo = tipoPomar;
                                        achouTipoPomar = true;
                                    }
                                });
                            }
                        }
                        if (!achouTipoPomar) {
                            if ((typeof this.pomarSelecionado.tipoId !== 'undefined') && this.pomarSelecionado.tipoId &&
                                (typeof this.pomarSelecionado.tipoDescricao !== 'undefined') && this.pomarSelecionado.tipoDescricao) {
                                ctrl.pomarSelecionado.tipo = new TipoPomar_1.TipoPomar();
                                ctrl.pomarSelecionado.tipo.id = this.pomarSelecionado.tipoId;
                                ctrl.pomarSelecionado.tipo.codigo = (typeof this.pomarSelecionado.tipoCodigo !== 'undefined') ? this.pomarSelecionado.tipoCodigo : null;
                                ctrl.pomarSelecionado.tipo.descricao = this.pomarSelecionado.tipoDescricao;
                            }
                        }
                    }
                    if ((typeof this.pomarSelecionado.categoria === 'undefined') || !this.pomarSelecionado.categoria) {
                        let achouCategoriaPomar = false;
                        if ((typeof this.pomarSelecionado.categoriaId !== 'undefined') && this.pomarSelecionado.categoriaId &&
                            (typeof this.categoriasPomares !== 'undefined') && this.categoriasPomares) {
                            angular.forEach(this.categoriasPomares, (categoria) => {
                                if (!achouCategoriaPomar && categoria && categoria.id && (categoria.id === ctrl.pomarSelecionado.categoriaId)) {
                                    ctrl.pomarSelecionado.categoria = categoria;
                                    achouCategoriaPomar = true;
                                }
                            });
                        }
                        if (!achouCategoriaPomar) {
                            if ((typeof this.pomarSelecionado.categoriaDescricao !== 'undefined') && this.pomarSelecionado.categoriaDescricao &&
                                (typeof this.categoriasPomares !== 'undefined') && this.categoriasPomares) {
                                angular.forEach(this.categoriasPomares, (categoria) => {
                                    if (!achouCategoriaPomar && categoria && categoria.descricao && (categoria.descricao === ctrl.pomarSelecionado.categoriaDescricao)) {
                                        ctrl.pomarSelecionado.categoria = categoria;
                                        achouCategoriaPomar = true;
                                    }
                                });
                            }
                        }
                        if (!achouCategoriaPomar) {
                            if ((typeof this.pomarSelecionado.categoriaId !== 'undefined') && this.pomarSelecionado.categoriaId &&
                                (typeof this.pomarSelecionado.categoriaDescricao !== 'undefined') && this.pomarSelecionado.categoriaDescricao) {
                                ctrl.pomarSelecionado.categoria = new CategoriaPomar_1.CategoriaPomar();
                                ctrl.pomarSelecionado.categoria.id = this.pomarSelecionado.categoriaId;
                                ctrl.pomarSelecionado.categoria.codigo = (typeof this.pomarSelecionado.categoriaCodigo !== 'undefined') ? this.pomarSelecionado.categoriaCodigo : null;
                                ctrl.pomarSelecionado.categoria.descricao = this.pomarSelecionado.categoriaDescricao;
                            }
                        }
                    }
                    if ((typeof this.pomarSelecionado.regiao === 'undefined') || !this.pomarSelecionado.regiao) {
                        let achouRegiao = false;
                        if ((typeof this.pomarSelecionado.regiaoId !== 'undefined') && this.pomarSelecionado.regiaoId &&
                            (typeof this.regioes !== 'undefined') && this.regioes) {
                            angular.forEach(this.regioes, (regiao) => {
                                if (!achouRegiao && regiao && regiao.id && (regiao.id === ctrl.pomarSelecionado.regiaoId)) {
                                    ctrl.pomarSelecionado.regiao = regiao;
                                    achouRegiao = true;
                                }
                            });
                        }
                        if (!achouRegiao) {
                            if ((typeof this.pomarSelecionado.regiaoNome !== 'undefined') && this.pomarSelecionado.regiaoNome &&
                                (typeof this.regioes !== 'undefined') && this.regioes) {
                                angular.forEach(this.regioes, (regiao) => {
                                    if (!achouRegiao && regiao && regiao.nome && (regiao.nome === ctrl.pomarSelecionado.regiaoNome)) {
                                        ctrl.pomarSelecionado.regiao = regiao;
                                        achouRegiao = true;
                                    }
                                });
                            }
                        }
                        if (!achouRegiao) {
                            if ((typeof this.pomarSelecionado.regiaoNum !== 'undefined') && this.pomarSelecionado.regiaoNum &&
                                (typeof this.regioes !== 'undefined') && this.regioes) {
                                angular.forEach(this.regioes, (regiao) => {
                                    if (!achouRegiao && regiao && regiao.numRegiao && (regiao.numRegiao === ctrl.pomarSelecionado.regiaoNum)) {
                                        ctrl.pomarSelecionado.regiao = regiao;
                                        achouRegiao = true;
                                    }
                                });
                            }
                        }
                        if (!achouRegiao) {
                            if ((typeof this.pomarSelecionado.regiaoId !== 'undefined') && this.pomarSelecionado.regiaoId &&
                                (typeof this.pomarSelecionado.regiaoNome !== 'undefined') && this.pomarSelecionado.regiaoNome) {
                                ctrl.pomarSelecionado.regiao = new Regiao_1.Regiao();
                                ctrl.pomarSelecionado.regiao.id = this.pomarSelecionado.regiaoId;
                                ctrl.pomarSelecionado.regiao.sgif_id = (typeof this.pomarSelecionado.regiaoSgifId !== 'undefined') ? this.pomarSelecionado.regiaoSgifId : null;
                                ctrl.pomarSelecionado.regiao.numRegiao = (typeof this.pomarSelecionado.regiaoNum !== 'undefined') ? this.pomarSelecionado.regiaoNum : null;
                                ctrl.pomarSelecionado.regiao.nome = this.pomarSelecionado.regiaoNome;
                            }
                        }
                    }
                    if ((typeof this.pomarSelecionado.talhao === 'undefined') || !this.pomarSelecionado.talhao) {
                        let achouTalhao = false;
                        if ((typeof this.pomarSelecionado.talhaoId !== 'undefined') && this.pomarSelecionado.talhaoId &&
                            (typeof this.talhoes !== 'undefined') && this.talhoes) {
                            angular.forEach(this.talhoes, (talhao) => {
                                if (!achouTalhao && talhao && talhao.id && (talhao.id === ctrl.pomarSelecionado.talhaoId)) {
                                    ctrl.pomarSelecionado.talhao = talhao;
                                    achouTalhao = true;
                                }
                            });
                        }
                        if (!achouTalhao) {
                            if ((typeof this.pomarSelecionado.talhaoNumero !== 'undefined') && this.pomarSelecionado.talhaoNumero &&
                                (typeof this.pomarSelecionado.regiaoId !== 'undefined') && this.pomarSelecionado.regiaoId &&
                                (typeof this.talhoes !== 'undefined') && this.talhoes) {
                                angular.forEach(this.talhoes, (talhao) => {
                                    if (!achouTalhao && talhao && talhao.numero && (talhao.numero === ctrl.pomarSelecionado.talhaoNumero) &&
                                        talhao.regiaoId && (talhao.regiaoId === ctrl.pomarSelecionado.regiaoId)) {
                                        ctrl.pomarSelecionado.talhao = talhao;
                                        achouTalhao = true;
                                    }
                                });
                            }
                        }
                        if (!achouTalhao) {
                            if ((typeof this.pomarSelecionado.talhaoNumero !== 'undefined') && this.pomarSelecionado.talhaoNumero &&
                                (typeof this.pomarSelecionado.regiaoNome !== 'undefined') && this.pomarSelecionado.regiaoNome &&
                                (typeof this.talhoes !== 'undefined') && this.talhoes) {
                                angular.forEach(this.talhoes, (talhao) => {
                                    if (!achouTalhao && talhao && talhao.numero && (talhao.numero === ctrl.pomarSelecionado.talhaoNumero) &&
                                        talhao.regiaoNome && (talhao.regiaoNome === ctrl.pomarSelecionado.regiaoNome)) {
                                        ctrl.pomarSelecionado.talhao = talhao;
                                        achouTalhao = true;
                                    }
                                });
                            }
                        }
                        if (!achouTalhao) {
                            if ((typeof this.pomarSelecionado.talhaoId !== 'undefined') && this.pomarSelecionado.talhaoId &&
                                (typeof this.pomarSelecionado.talhaoNumero !== 'undefined') && this.pomarSelecionado.talhaoNumero) {
                                ctrl.pomarSelecionado.talhao = new Talhao_1.Talhao();
                                ctrl.pomarSelecionado.talhao.id = this.pomarSelecionado.talhaoId;
                                ctrl.pomarSelecionado.talhao.sgif_id = (typeof this.pomarSelecionado.talhaoSgifId !== 'undefined') ? this.pomarSelecionado.talhaoSgifId : null;
                                ctrl.pomarSelecionado.talhao.numero = this.pomarSelecionado.talhaoNumero;
                                if ((typeof ctrl.pomarSelecionado.regiao !== 'undefined') && ctrl.pomarSelecionado.regiao) {
                                    ctrl.pomarSelecionado.talhao.regiao = angular.copy(ctrl.pomarSelecionado.regiao);
                                }
                            }
                        }
                    }
                    if ((typeof this.pomarSelecionado.situacao === 'undefined') || !this.pomarSelecionado.situacao) {
                        let achouSituacaoPomar = false;
                        if ((typeof this.pomarSelecionado.situacaoId !== 'undefined') && this.pomarSelecionado.situacaoId &&
                            (typeof this.situacoes !== 'undefined') && this.situacoes) {
                            angular.forEach(this.situacoes, (situacao) => {
                                if (!achouSituacaoPomar && situacao && situacao.id && (situacao.id === ctrl.pomarSelecionado.situacaoId)) {
                                    ctrl.pomarSelecionado.situacao = situacao;
                                    achouSituacaoPomar = true;
                                }
                            });
                        }
                        if (!achouSituacaoPomar) {
                            if ((typeof this.pomarSelecionado.situacaoDescricao !== 'undefined') && this.pomarSelecionado.situacaoDescricao &&
                                (typeof this.situacoes !== 'undefined') && this.situacoes) {
                                angular.forEach(this.situacoes, (situacao) => {
                                    if (!achouSituacaoPomar && situacao && situacao.descricao && (situacao.descricao === ctrl.pomarSelecionado.situacaoDescricao)) {
                                        ctrl.pomarSelecionado.situacao = situacao;
                                        achouSituacaoPomar = true;
                                    }
                                });
                            }
                        }
                        if (!achouSituacaoPomar) {
                            if ((typeof this.pomarSelecionado.situacaoId !== 'undefined') && this.pomarSelecionado.situacaoId &&
                                (typeof this.pomarSelecionado.situacaoDescricao !== 'undefined') && this.pomarSelecionado.situacaoDescricao) {
                                ctrl.pomarSelecionado.situacao = new Situacao_1.Situacao();
                                ctrl.pomarSelecionado.situacao.id = this.pomarSelecionado.situacaoId;
                                ctrl.pomarSelecionado.situacao.codigo = (typeof this.pomarSelecionado.situacaoCodigo !== 'undefined') ? this.pomarSelecionado.situacaoCodigo : null;
                                ctrl.pomarSelecionado.situacao.descricao = this.pomarSelecionado.situacaoDescricao;
                            }
                        }
                    }
                    if ((typeof this.pomarSelecionado.classificacaoSolo === 'undefined') || !this.pomarSelecionado.classificacaoSolo) {
                        let achouClassificacaoSoloPomar = false;
                        if ((typeof this.pomarSelecionado.classificacaoSoloId !== 'undefined') && this.pomarSelecionado.classificacaoSoloId &&
                            (typeof this.classificacoesSolo !== 'undefined') && this.classificacoesSolo) {
                            angular.forEach(this.classificacoesSolo, (classificacaoSolo) => {
                                if (!achouClassificacaoSoloPomar && classificacaoSolo && classificacaoSolo.id && (classificacaoSolo.id === ctrl.pomarSelecionado.classificacaoSoloId)) {
                                    ctrl.pomarSelecionado.classificacaoSolo = classificacaoSolo;
                                    achouClassificacaoSoloPomar = true;
                                }
                            });
                        }
                        if (!achouClassificacaoSoloPomar) {
                            if ((typeof this.pomarSelecionado.classificacaoSoloDescricao !== 'undefined') && this.pomarSelecionado.classificacaoSoloDescricao &&
                                (typeof this.classificacoesSolo !== 'undefined') && this.classificacoesSolo) {
                                angular.forEach(this.classificacoesSolo, (classificacaoSolo) => {
                                    if (!achouClassificacaoSoloPomar && classificacaoSolo && classificacaoSolo.descricao && (classificacaoSolo.descricao === ctrl.pomarSelecionado.classificacaoSoloDescricao)) {
                                        ctrl.pomarSelecionado.classificacaoSolo = classificacaoSolo;
                                        achouClassificacaoSoloPomar = true;
                                    }
                                });
                            }
                        }
                        if (!achouClassificacaoSoloPomar) {
                            if ((typeof this.pomarSelecionado.classificacaoSoloId !== 'undefined') && this.pomarSelecionado.classificacaoSoloId &&
                                (typeof this.pomarSelecionado.classificacaoSoloDescricao !== 'undefined') && this.pomarSelecionado.classificacaoSoloDescricao) {
                                ctrl.pomarSelecionado.classificacaoSolo = new ClassificacaoSolo_1.ClassificacaoSolo();
                                ctrl.pomarSelecionado.classificacaoSolo.id = this.pomarSelecionado.classificacaoSoloId;
                                ctrl.pomarSelecionado.classificacaoSolo.codigo = (typeof this.pomarSelecionado.classificacaoSoloCodigo !== 'undefined') ? this.pomarSelecionado.classificacaoSoloCodigo : null;
                                ctrl.pomarSelecionado.classificacaoSolo.sigla = (typeof this.pomarSelecionado.classificacaoSoloSigla !== 'undefined') ? this.pomarSelecionado.classificacaoSoloSigla : null;
                                ctrl.pomarSelecionado.classificacaoSolo.descricao = this.pomarSelecionado.classificacaoSoloDescricao;
                            }
                        }
                    }
                    if ((typeof this.pomarSelecionado.unidadeManejo === 'undefined') || !this.pomarSelecionado.unidadeManejo) {
                        let achouUnidadeManejoPomar = false;
                        if ((typeof this.pomarSelecionado.unidadeManejoId !== 'undefined') && this.pomarSelecionado.unidadeManejoId &&
                            (typeof this.unidadesManejo !== 'undefined') && this.unidadesManejo) {
                            angular.forEach(this.unidadesManejo, (unidadeManejo) => {
                                if (!achouUnidadeManejoPomar && unidadeManejo && unidadeManejo.id && (unidadeManejo.id === ctrl.pomarSelecionado.unidadeManejoId)) {
                                    ctrl.pomarSelecionado.unidadeManejo = unidadeManejo;
                                    achouUnidadeManejoPomar = true;
                                }
                            });
                        }
                        if (!achouUnidadeManejoPomar) {
                            if ((typeof this.pomarSelecionado.unidadeManejoDescricao !== 'undefined') && this.pomarSelecionado.unidadeManejoDescricao &&
                                (typeof this.unidadesManejo !== 'undefined') && this.unidadesManejo) {
                                angular.forEach(this.unidadesManejo, (unidadeManejo) => {
                                    if (!achouUnidadeManejoPomar && unidadeManejo && unidadeManejo.descricao && (unidadeManejo.descricao === ctrl.pomarSelecionado.unidadeManejoDescricao)) {
                                        ctrl.pomarSelecionado.unidadeManejo = unidadeManejo;
                                        achouUnidadeManejoPomar = true;
                                    }
                                });
                            }
                        }
                        if (!achouUnidadeManejoPomar) {
                            if ((typeof this.pomarSelecionado.unidadeManejoId !== 'undefined') && this.pomarSelecionado.unidadeManejoId &&
                                (typeof this.pomarSelecionado.unidadeManejoDescricao !== 'undefined') && this.pomarSelecionado.unidadeManejoDescricao) {
                                ctrl.pomarSelecionado.unidadeManejo = new UnidadeManejo_1.UnidadeManejo();
                                ctrl.pomarSelecionado.unidadeManejo.id = this.pomarSelecionado.unidadeManejoId;
                                ctrl.pomarSelecionado.unidadeManejo.codigo = (typeof this.pomarSelecionado.unidadeManejoCodigo !== 'undefined') ? this.pomarSelecionado.unidadeManejoCodigo : null;
                                ctrl.pomarSelecionado.unidadeManejo.descricao = this.pomarSelecionado.unidadeManejoDescricao;
                            }
                        }
                    }
                    if ((typeof this.pomarSelecionado.adubacaoBaseFertilizante === 'undefined') || !this.pomarSelecionado.adubacaoBaseFertilizante) {
                        let achouAdubacaoBaseFertilizante = false;
                        if ((typeof this.pomarSelecionado.adubacaoBaseFertilizanteId !== 'undefined') && this.pomarSelecionado.adubacaoBaseFertilizanteId &&
                            (typeof this.produtosFertilizantes !== 'undefined') && this.produtosFertilizantes) {
                            angular.forEach(this.produtosFertilizantes, (produtoFertilizante) => {
                                if (!achouAdubacaoBaseFertilizante && produtoFertilizante && produtoFertilizante.id && (produtoFertilizante.id === ctrl.pomarSelecionado.adubacaoBaseFertilizanteId)) {
                                    ctrl.pomarSelecionado.adubacaoBaseFertilizante = produtoFertilizante;
                                    achouAdubacaoBaseFertilizante = true;
                                }
                            });
                        }
                        if (!achouAdubacaoBaseFertilizante) {
                            if ((typeof this.pomarSelecionado.adubacaoBaseFertilizanteDescricao !== 'undefined') && this.pomarSelecionado.adubacaoBaseFertilizanteDescricao &&
                                (typeof this.produtosFertilizantes !== 'undefined') && this.produtosFertilizantes) {
                                angular.forEach(this.produtosFertilizantes, (produtoFertilizante) => {
                                    if (!achouAdubacaoBaseFertilizante && produtoFertilizante && produtoFertilizante.descricao && (produtoFertilizante.descricao === ctrl.pomarSelecionado.adubacaoBaseFertilizanteDescricao)) {
                                        ctrl.pomarSelecionado.adubacaoBaseFertilizante = produtoFertilizante;
                                        achouAdubacaoBaseFertilizante = true;
                                    }
                                });
                            }
                        }
                        if (!achouAdubacaoBaseFertilizante) {
                            if ((typeof this.pomarSelecionado.adubacaoBaseFertilizanteId !== 'undefined') && this.pomarSelecionado.adubacaoBaseFertilizanteId &&
                                (typeof this.pomarSelecionado.adubacaoBaseFertilizanteDescricao !== 'undefined') && this.pomarSelecionado.adubacaoBaseFertilizanteDescricao) {
                                ctrl.pomarSelecionado.adubacaoBaseFertilizante = new ProdutoFertilizante_1.ProdutoFertilizante();
                                ctrl.pomarSelecionado.adubacaoBaseFertilizante.id = this.pomarSelecionado.adubacaoBaseFertilizanteId;
                                ctrl.pomarSelecionado.adubacaoBaseFertilizante.codigo = (typeof this.pomarSelecionado.adubacaoBaseFertilizanteCodigo !== 'undefined') ? this.pomarSelecionado.adubacaoBaseFertilizanteCodigo : null;
                                ctrl.pomarSelecionado.adubacaoBaseFertilizante.descricao = this.pomarSelecionado.adubacaoBaseFertilizanteDescricao;
                            }
                        }
                    }
                    if ((typeof this.pomarSelecionado.adubacaoCoberturaFertilizante === 'undefined') || !this.pomarSelecionado.adubacaoCoberturaFertilizante) {
                        let achouAdubacaoBaseFertilizante = false;
                        if ((typeof this.pomarSelecionado.adubacaoCoberturaFertilizanteId !== 'undefined') && this.pomarSelecionado.adubacaoCoberturaFertilizanteId &&
                            (typeof this.produtosFertilizantes !== 'undefined') && this.produtosFertilizantes) {
                            angular.forEach(this.produtosFertilizantes, (produtoFertilizante) => {
                                if (!achouAdubacaoBaseFertilizante && produtoFertilizante && produtoFertilizante.id && (produtoFertilizante.id === ctrl.pomarSelecionado.adubacaoCoberturaFertilizanteId)) {
                                    ctrl.pomarSelecionado.adubacaoCoberturaFertilizante = produtoFertilizante;
                                    achouAdubacaoBaseFertilizante = true;
                                }
                            });
                        }
                        if (!achouAdubacaoBaseFertilizante) {
                            if ((typeof this.pomarSelecionado.adubacaoCoberturaFertilizanteDescricao !== 'undefined') && this.pomarSelecionado.adubacaoCoberturaFertilizanteDescricao &&
                                (typeof this.produtosFertilizantes !== 'undefined') && this.produtosFertilizantes) {
                                angular.forEach(this.produtosFertilizantes, (produtoFertilizante) => {
                                    if (!achouAdubacaoBaseFertilizante && produtoFertilizante && produtoFertilizante.descricao && (produtoFertilizante.descricao === ctrl.pomarSelecionado.adubacaoCoberturaFertilizanteDescricao)) {
                                        ctrl.pomarSelecionado.adubacaoCoberturaFertilizante = produtoFertilizante;
                                        achouAdubacaoBaseFertilizante = true;
                                    }
                                });
                            }
                        }
                        if (!achouAdubacaoBaseFertilizante) {
                            if ((typeof this.pomarSelecionado.adubacaoCoberturaFertilizanteId !== 'undefined') && this.pomarSelecionado.adubacaoCoberturaFertilizanteId &&
                                (typeof this.pomarSelecionado.adubacaoCoberturaFertilizanteDescricao !== 'undefined') && this.pomarSelecionado.adubacaoCoberturaFertilizanteDescricao) {
                                ctrl.pomarSelecionado.adubacaoCoberturaFertilizante = new ProdutoFertilizante_1.ProdutoFertilizante();
                                ctrl.pomarSelecionado.adubacaoCoberturaFertilizante.id = this.pomarSelecionado.adubacaoCoberturaFertilizanteId;
                                ctrl.pomarSelecionado.adubacaoCoberturaFertilizante.codigo = (typeof this.pomarSelecionado.adubacaoCoberturaFertilizanteCodigo !== 'undefined') ? this.pomarSelecionado.adubacaoCoberturaFertilizanteCodigo : null;
                                ctrl.pomarSelecionado.adubacaoCoberturaFertilizante.descricao = this.pomarSelecionado.adubacaoCoberturaFertilizanteDescricao;
                            }
                        }
                    }
                    if ((typeof this.pomarSelecionado.tipoDelineamento === 'undefined') || !this.pomarSelecionado.tipoDelineamento) {
                        let achouTipoDelineamentoPomar = false;
                        if ((typeof this.pomarSelecionado.tipoDelineamentoId !== 'undefined') && this.pomarSelecionado.tipoDelineamentoId &&
                            (typeof this.tiposDelineamentos !== 'undefined') && this.tiposDelineamentos) {
                            angular.forEach(this.tiposDelineamentos, (tipoDelineamento) => {
                                if (!achouTipoDelineamentoPomar && tipoDelineamento && tipoDelineamento.id && (tipoDelineamento.id === ctrl.pomarSelecionado.tipoDelineamentoId)) {
                                    ctrl.pomarSelecionado.tipoDelineamento = tipoDelineamento;
                                    achouTipoDelineamentoPomar = true;
                                }
                            });
                        }
                        if (!achouTipoDelineamentoPomar) {
                            if ((typeof this.pomarSelecionado.tipoDelineamentoDescricao !== 'undefined') && this.pomarSelecionado.tipoDelineamentoDescricao &&
                                (typeof this.tiposDelineamentos !== 'undefined') && this.tiposDelineamentos) {
                                angular.forEach(this.tiposDelineamentos, (tipoDelineamento) => {
                                    if (!achouTipoDelineamentoPomar && tipoDelineamento && tipoDelineamento.descricao && (tipoDelineamento.descricao === ctrl.pomarSelecionado.tipoDelineamentoDescricao)) {
                                        ctrl.pomarSelecionado.tipoDelineamento = tipoDelineamento;
                                        achouTipoDelineamentoPomar = true;
                                    }
                                });
                            }
                        }
                        if (!achouTipoDelineamentoPomar) {
                            if ((typeof this.pomarSelecionado.tipoDelineamentoId !== 'undefined') && this.pomarSelecionado.tipoDelineamentoId &&
                                (typeof this.pomarSelecionado.tipoDelineamentoDescricao !== 'undefined') && this.pomarSelecionado.tipoDelineamentoDescricao) {
                                ctrl.pomarSelecionado.tipoDelineamento = new TipoDelineamento_1.TipoDelineamento();
                                ctrl.pomarSelecionado.tipoDelineamento.id = this.pomarSelecionado.tipoDelineamentoId;
                                ctrl.pomarSelecionado.tipoDelineamento.codigo = (typeof this.pomarSelecionado.tipoDelineamentoCodigo !== 'undefined') ? this.pomarSelecionado.tipoDelineamentoCodigo : null;
                                ctrl.pomarSelecionado.tipoDelineamento.descricao = this.pomarSelecionado.tipoDelineamentoDescricao;
                            }
                        }
                    }
                    if ((typeof this.pomarSelecionado.disposicaoIndividuos === 'undefined') || !this.pomarSelecionado.disposicaoIndividuos) {
                        let achouDisposicaoIndividuosPomar = false;
                        if ((typeof this.pomarSelecionado.disposicaoIndividuosId !== 'undefined') && this.pomarSelecionado.disposicaoIndividuosId &&
                            (typeof this.disposicoesIndividuos !== 'undefined') && this.disposicoesIndividuos) {
                            angular.forEach(this.disposicoesIndividuos, (disposicaoIndividuos) => {
                                if (!achouDisposicaoIndividuosPomar && disposicaoIndividuos && disposicaoIndividuos.id && (disposicaoIndividuos.id === ctrl.pomarSelecionado.disposicaoIndividuosId)) {
                                    ctrl.pomarSelecionado.disposicaoIndividuos = disposicaoIndividuos;
                                    achouDisposicaoIndividuosPomar = true;
                                }
                            });
                        }
                        if (!achouDisposicaoIndividuosPomar) {
                            if ((typeof this.pomarSelecionado.disposicaoIndividuosDescricao !== 'undefined') && this.pomarSelecionado.disposicaoIndividuosDescricao &&
                                (typeof this.disposicoesIndividuos !== 'undefined') && this.disposicoesIndividuos) {
                                angular.forEach(this.disposicoesIndividuos, (disposicaoIndividuos) => {
                                    if (!achouDisposicaoIndividuosPomar && disposicaoIndividuos && disposicaoIndividuos.descricao && (disposicaoIndividuos.descricao === ctrl.pomarSelecionado.disposicaoIndividuosDescricao)) {
                                        ctrl.pomarSelecionado.disposicaoIndividuos = disposicaoIndividuos;
                                        achouDisposicaoIndividuosPomar = true;
                                    }
                                });
                            }
                        }
                        if (!achouDisposicaoIndividuosPomar) {
                            if ((typeof this.pomarSelecionado.disposicaoIndividuosId !== 'undefined') && this.pomarSelecionado.disposicaoIndividuosId &&
                                (typeof this.pomarSelecionado.disposicaoIndividuosDescricao !== 'undefined') && this.pomarSelecionado.disposicaoIndividuosDescricao) {
                                ctrl.pomarSelecionado.disposicaoIndividuos = new DisposicaoIndividuos_1.DisposicaoIndividuos();
                                ctrl.pomarSelecionado.disposicaoIndividuos.id = this.pomarSelecionado.disposicaoIndividuosId;
                                ctrl.pomarSelecionado.disposicaoIndividuos.codigo = (typeof this.pomarSelecionado.disposicaoIndividuosCodigo !== 'undefined') ? this.pomarSelecionado.disposicaoIndividuosCodigo : null;
                                ctrl.pomarSelecionado.disposicaoIndividuos.descricao = this.pomarSelecionado.disposicaoIndividuosDescricao;
                            }
                        }
                    }
                    if ((typeof this.pomarSelecionado.classificacaoClimatica === 'undefined') || !this.pomarSelecionado.classificacaoClimatica) {
                        let achouClassificacaoClimaticaPomar = false;
                        if ((typeof this.pomarSelecionado.classificacaoClimaticaId !== 'undefined') && this.pomarSelecionado.classificacaoClimaticaId &&
                            (typeof this.classificacoesClimaticas !== 'undefined') && this.classificacoesClimaticas) {
                            angular.forEach(this.classificacoesClimaticas, (classificacaoClimatica) => {
                                if (!achouClassificacaoClimaticaPomar && classificacaoClimatica && classificacaoClimatica.id && (classificacaoClimatica.id === ctrl.pomarSelecionado.classificacaoClimaticaId)) {
                                    ctrl.pomarSelecionado.classificacaoClimatica = classificacaoClimatica;
                                    achouClassificacaoClimaticaPomar = true;
                                }
                            });
                        }
                        if (!achouClassificacaoClimaticaPomar) {
                            if ((typeof this.pomarSelecionado.classificacaoClimaticaDescricao !== 'undefined') && this.pomarSelecionado.classificacaoClimaticaDescricao &&
                                (typeof this.classificacoesClimaticas !== 'undefined') && this.classificacoesClimaticas) {
                                angular.forEach(this.classificacoesClimaticas, (classificacaoClimatica) => {
                                    if (!achouClassificacaoClimaticaPomar && classificacaoClimatica && classificacaoClimatica.descricao && (classificacaoClimatica.descricao === ctrl.pomarSelecionado.classificacaoClimaticaDescricao)) {
                                        ctrl.pomarSelecionado.classificacaoClimatica = classificacaoClimatica;
                                        achouClassificacaoClimaticaPomar = true;
                                    }
                                });
                            }
                        }
                        if (!achouClassificacaoClimaticaPomar) {
                            if ((typeof this.pomarSelecionado.classificacaoClimaticaId !== 'undefined') && this.pomarSelecionado.classificacaoClimaticaId &&
                                (typeof this.pomarSelecionado.classificacaoClimaticaDescricao !== 'undefined') && this.pomarSelecionado.classificacaoClimaticaDescricao) {
                                ctrl.pomarSelecionado.classificacaoClimatica = new ClassificacaoClimatica_1.ClassificacaoClimatica();
                                ctrl.pomarSelecionado.classificacaoClimatica.id = this.pomarSelecionado.classificacaoClimaticaId;
                                ctrl.pomarSelecionado.classificacaoClimatica.codigo = (typeof this.pomarSelecionado.classificacaoClimaticaCodigo !== 'undefined') ? this.pomarSelecionado.classificacaoClimaticaCodigo : null;
                                ctrl.pomarSelecionado.classificacaoClimatica.descricao = this.pomarSelecionado.classificacaoClimaticaDescricao;
                            }
                        }
                    }
                    if ((typeof this.pomarSelecionado.area === 'undefined') || !this.pomarSelecionado.area) {
                        if ((typeof this.pomarSelecionado.talhao !== 'undefined') && this.pomarSelecionado.talhao &&
                            (typeof this.pomarSelecionado.talhao.areaTalhao !== 'undefined') && this.pomarSelecionado.talhao.areaTalhao) {
                            this.pomarSelecionado.area = this.pomarSelecionado.talhao.areaTalhao;
                            this.pomarSelecionado.delineamentoTotalArea = this.pomarSelecionado.talhao.areaTalhao;
                        }
                    }
                    if ((typeof this.pomarSelecionado.delineamentoTotalArea === 'undefined') || !this.pomarSelecionado.delineamentoTotalArea) {
                        this.pomarSelecionado.delineamentoTotalArea = this.pomarSelecionado.area;
                    }
                    this.filtroPlanejamentoPomar = new FiltroPlanejamentoPomar_1.FiltroPlanejamentoPomar();
                    this.listaOpcoesPlanejamentoPomar = yield this.dadosListaPlanejamentoPomar();
                    this.filtroControleManejoPomar = new FiltroControleManejoPomar_1.FiltroControleManejoPomar();
                    const listaOpcoesManejoPomar = yield this.dadosListaManejoPomar();
                    this.listaOpcoesManejoPomarResumido = listaOpcoesManejoPomar.resumido;
                    this.listaOpcoesManejoPomarDetalhado = listaOpcoesManejoPomar.detalhado;
                    this.filtroControleEnxertiaPomar = new FiltroControleEnxertiaPomar_1.FiltroControleEnxertiaPomar();
                    this.listaOpcoesEnxertiaPomar = yield this.dadosListaEnxertiaPomar();
                    this.filtroProdutividadePomar = new FiltroProdutividadePomar_1.FiltroProdutividadePomar();
                    this.listaOpcoesProdutividadePomar = yield this.dadosListaProdutividadePomar();
                    this.filtroFenologiaPomar = new FiltroFenologiaPomar_1.FiltroFenologiaPomar();
                    this.listaOpcoesFenologiaPomar = yield this.dadosListaFenologiaPomar();
                    this.filtroPolinizacaoPomar = new FiltroPolinizacaoPomar_1.FiltroPolinizacaoPomar();
                    this.listaOpcoesPolinizacaoPomar = yield this.dadosListaPolinizacaoPomar();
                    this.listaOpcoesMonitoramentoFloracaoMasculinoPolinizacaoPomar = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesMonitoramentoFloracaoFemininoPolinizacaoPomar = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesEstoquePolenPolinizacaoPomar = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesHistoricoEstoquePolenPolinizacaoPomar = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesLancamentosPinusPolinizacaoPomar = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesLancamentosEucalyptusPolinizacaoPomar = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesColetasConesSementesPolinizacaoPomar = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesBeneficiamentosPinusPolinizacaoPomar = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesEstoqueBeneficiamentoPinusPolinizacaoPomar = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesHistoricoEstoqueBeneficiamentoPinusPolinizacaoPomar = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesBeneficiamentosEucalyptusPolinizacaoPomar = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesEstoqueBeneficiamentoEucalyptusPolinizacaoPomar = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesHistoricoEstoqueBeneficiamentoEucalyptusPolinizacaoPomar = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesRelatorioPolinizacaoPinusPomar = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesRelatorioPolinizacaoEucalyptusPomar = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesTratamentosPomar = yield this.dadosListaTratamentosPomar();
                    this.listaOpcoesClimaAnualPomar = yield this.dadosListaClimaAnualPomar();
                    this.listaOpcoesAnexosPomar = yield this.dadosListaAnexosPomar();
                    yield this.carregarCroquiPomar();
                    yield this.carregarMapaPomar();
                    yield this.filtraTalhoes();
                    yield this.filtraMateriaisGeneticos();
                    this.pomarCarregado = true;
                    this.scopeApply();
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
        });
    }
    salvarTratamentoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!this.tratamentoPomarSelecionado || this.tratamentoPomarVisualizar) {
                    return;
                }
                let listaCamposObrigatorios = '';
                if (listaCamposObrigatorios && (listaCamposObrigatorios.length > 0)) {
                    let mensagem = 'Campo obrigatório não preenchido!' +
                        listaCamposObrigatorios + '\r\n \r\n' +
                        'Preencha este campo e tente novamente.';
                    if (this.ehCelular) {
                        alert(mensagem.replace(/<b>/g, '').replace(/<\/b>/g, ''));
                    }
                    else {
                        yield sweetalert2_1.default.fire({
                            html: mensagem.replace(/\r\n/g, '<br>\r\n'),
                            icon: 'info',
                            showCancelButton: false,
                            showConfirmButton: true,
                            confirmButtonText: 'Ok'
                        });
                    }
                    return;
                }
                this.modalGerarCroquiPomar = false;
                this.modalGerarCroquiPomarDados = null;
                this.modalHistoricoCroquiPomar = false;
                this.modalPlanejamentoPomar = false;
                this.modalControleManejoPomar = false;
                this.modalControleEnxertiaPomar = false;
                this.modalProdutividadePomar = false;
                this.modalFenologiaPomar = false;
                this.modalPolinizacaoPomar = false;
                this.modalRelatorioPomar = false;
                this.modalAnexoPomar = false;
                this.tratamentoPomarSelecionado.pomar = this.pomarSelecionado;
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                setTimeout((ctrl) => {
                    if (!ctrl.tratamentoPomarSelecionado.id) {
                        ctrl.tratamentoPomarSelecionado = new TratamentoPomar_1.TratamentoPomar();
                        ctrl.tratamentoPomarSelecionado.id = ctrl.pomarSelecionado.id;
                    }
                    else {
                        ctrl.tratamentoPomarSelecionado = new TratamentoPomar_1.TratamentoPomar();
                        ctrl.tratamentoPomarSelecionado.id = ctrl.pomarSelecionado.id;
                    }
                    if ((typeof ctrl.tratamentoPomarSelecionado.pomar !== 'undefined') && ctrl.tratamentoPomarSelecionado.pomar &&
                        (typeof ctrl.tratamentoPomarSelecionado.pomar.descricao !== 'undefined') && ctrl.tratamentoPomarSelecionado.pomar.descricao) {
                        ctrl.tratamentoPomarSelecionado.pomarDescricao = ctrl.tratamentoPomarSelecionado.pomar.descricao;
                    }
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    if (ctrl.tratamentoPomarSelecionado && ctrl.tratamentoPomarSelecionado.id) {
                        ctrl.modalTratamentoPomar = false;
                        if (ctrl.ehCelular) {
                            alert(`Salvo com sucesso!`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                title: 'Salvo com sucesso!',
                                text: 'O tratamento foi salvo no banco de dados.',
                                icon: 'success',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                    }
                    ctrl.ocultarModalTratamentoPomar();
                }, 250000, this);
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
            }
        });
    }
    exibirModalTratamentoPomar(dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.pomarSelecionado === 'undefined') || !this.pomarSelecionado) {
                return;
            }
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.tratamentoPomarVisualizar = true;
                if ((typeof dados !== 'undefined') && dados && (typeof dados.id !== 'undefined') && dados.id) {
                    this.tratamentoPomarSelecionado = null;
                    if ((typeof this.tratamentoPomarSelecionado === 'undefined') || !this.tratamentoPomarSelecionado) {
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        if (this.ehCelular) {
                            alert(`Registro "${dados.id} não encontrado!`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                text: `Registro "${dados.id}" não encontrado.`,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        return;
                    }
                    if ((typeof this.tratamentoPomarSelecionado.criacao === 'string') && this.tratamentoPomarSelecionado.criacao) {
                        this.tratamentoPomarSelecionado.criacao = new Date(this.tratamentoPomarSelecionado.criacao);
                    }
                    if ((typeof this.tratamentoPomarSelecionado.dataHoraInclusao === 'string') && this.tratamentoPomarSelecionado.dataHoraInclusao) {
                        this.tratamentoPomarSelecionado.dataHoraInclusao = new Date(this.tratamentoPomarSelecionado.dataHoraInclusao);
                    }
                    if ((typeof this.tratamentoPomarSelecionado.dataHoraAlteracao === 'string') && this.tratamentoPomarSelecionado.dataHoraAlteracao) {
                        this.tratamentoPomarSelecionado.dataHoraAlteracao = new Date(this.tratamentoPomarSelecionado.dataHoraAlteracao);
                    }
                    if ((typeof dados.visualizar !== 'undefined') && dados.visualizar) {
                        this.tratamentoPomarVisualizar = true;
                    }
                    else if ((typeof dados.editar !== 'undefined') && dados.editar) {
                        this.tratamentoPomarVisualizar = false;
                    }
                    else if ((typeof dados.excluir !== 'undefined') && dados.excluir) {
                        const ctrl = this;
                        this.notificacao
                            .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                            .then((result) => __awaiter(this, void 0, void 0, function* () {
                            if (result.value) {
                                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TRATAMENTO_POMAR)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TRATAMENTO_POMAR)
                                    .id(dados.id)
                                    .remover();
                                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                                ctrl.scopeApply();
                            }
                        }));
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                }
                else {
                    this.tratamentoPomarSelecionado = new TratamentoPomar_1.TratamentoPomar();
                    this.tratamentoPomarSelecionado.criacao = new Date();
                    this.tratamentoPomarSelecionado.criacao.setMilliseconds(0);
                    this.tratamentoPomarSelecionado.ano = this.tratamentoPomarSelecionado.criacao.getFullYear();
                    this.tratamentoPomarSelecionado.mes = this.tratamentoPomarSelecionado.criacao.getMonth() + 1;
                    this.tratamentoPomarSelecionado.tratamentos = [{}];
                    this.tratamentoPomarVisualizar = false;
                }
                this.modalTratamentoPomar = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalTratamentoPomar() {
        this.modalTratamentoPomar = false;
        angular.element('body').css('overflow-y', '');
        globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
        this.scopeApply(() => {
            setTimeout(() => {
                angular.element('body').css('overflow-y', '');
                if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                    (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                    angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                }
            }, 10);
        });
    }
    adicionarRegistroTratamentoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.tratamentoPomarSelecionado) {
                return;
            }
            if ((typeof this.tratamentoPomarSelecionado.tratamentos === 'undefined') || !this.tratamentoPomarSelecionado.tratamentos) {
                this.tratamentoPomarSelecionado.tratamentos = [];
            }
            let registro = {
                id: null,
                uuid: null,
                guid: null,
                status: true,
                criacao: new Date()
            };
            this.tratamentoPomarSelecionado.tratamentos.push(registro);
            this.scopeApply();
        });
    }
    removerRegistroTratamentoPomar(keyTratamento) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.tratamentoPomarSelecionado) {
                return;
            }
            if ((typeof this.tratamentoPomarSelecionado.tratamentos === 'undefined') || !this.tratamentoPomarSelecionado.tratamentos) {
                this.tratamentoPomarSelecionado.tratamentos = [];
            }
            if ((typeof keyTratamento !== 'undefined') && (keyTratamento !== null) &&
                (this.tratamentoPomarSelecionado.tratamentos.length > 0) &&
                (keyTratamento <= this.tratamentoPomarSelecionado.tratamentos.length - 1)) {
                const numRegistro = keyTratamento + 1;
                if (window.confirm('Deseja realmente remover esse registro #' + numRegistro + ' ?')) {
                    this.tratamentoPomarSelecionado.tratamentos.splice(keyTratamento, 1);
                    this.scopeApply();
                }
            }
        });
    }
    moverRegistroTratamentoPomarParaCima(keyTratamento) {
        if (!this.tratamentoPomarSelecionado) {
            return;
        }
        if ((typeof this.tratamentoPomarSelecionado.tratamentos === 'undefined') || !this.tratamentoPomarSelecionado.tratamentos) {
            this.tratamentoPomarSelecionado.tratamentos = [];
        }
        if ((keyTratamento !== null) && (keyTratamento > 0) && (keyTratamento <= this.tratamentoPomarSelecionado.tratamentos.length - 1)) {
            let oldKey = keyTratamento;
            let newKey = keyTratamento - 1;
            this.tratamentoPomarSelecionado.tratamentos.splice(newKey, 0, this.tratamentoPomarSelecionado.tratamentos.splice(oldKey, 1)[0]);
            this.scopeApply();
        }
    }
    moverRegistroTratamentoPomarParaBaixo(keyTratamento) {
        if (!this.tratamentoPomarSelecionado) {
            return;
        }
        if ((typeof this.tratamentoPomarSelecionado.tratamentos === 'undefined') || !this.tratamentoPomarSelecionado.tratamentos) {
            this.tratamentoPomarSelecionado.tratamentos = [];
        }
        if ((keyTratamento != null) && (keyTratamento >= 0) && (keyTratamento < this.tratamentoPomarSelecionado.tratamentos.length - 1)) {
            let oldKey = keyTratamento;
            let newKey = keyTratamento + 1;
            this.tratamentoPomarSelecionado.tratamentos.splice(newKey, 0, this.tratamentoPomarSelecionado.tratamentos.splice(oldKey, 1)[0]);
            this.scopeApply();
        }
    }
    carregarCroquiPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoCroquiUtil_1.PomarBancoConservacaoCroquiUtil.carregarCroquiPomar(this);
        });
    }
    exportarCroquiPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoCroquiUtil_1.PomarBancoConservacaoCroquiUtil.exportarCroquiPomar(this);
        });
    }
    importarCroquiPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoCroquiUtil_1.PomarBancoConservacaoCroquiUtil.importarCroquiPomar(this);
        });
    }
    lancarControleEnxertiaCroqui() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoCroquiUtil_1.PomarBancoConservacaoCroquiUtil.lancarControleEnxertiaCroqui(this);
        });
    }
    lancarMonitoramentoFloracaoCroqui() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoCroquiUtil_1.PomarBancoConservacaoCroquiUtil.lancarMonitoramentoFloracaoCroqui(this);
        });
    }
    redimensionarPlanilhaCroquiPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoCroquiUtil_1.PomarBancoConservacaoCroquiUtil.redimensionarPlanilhaCroquiPomar();
        });
    }
    salvarCroquiPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoCroquiUtil_1.PomarBancoConservacaoCroquiUtil.salvarCroquiPomar(this);
        });
    }
    exibirModalGerarCroquiPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoCroquiUtil_1.PomarBancoConservacaoCroquiUtil.exibirModalGerarCroquiPomar(this);
        });
    }
    ocultarModalGerarCroquiPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoCroquiUtil_1.PomarBancoConservacaoCroquiUtil.ocultarModalGerarCroquiPomar(this);
        });
    }
    exibirModalHistoricoCroquiPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoCroquiUtil_1.PomarBancoConservacaoCroquiUtil.exibirModalHistoricoCroquiPomar(this);
        });
    }
    ocultarModalHistoricoCroquiPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoCroquiUtil_1.PomarBancoConservacaoCroquiUtil.ocultarModalHistoricoCroquiPomar(this);
        });
    }
    salvarInformacoesPomar() {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!this.pomarSelecionado || !this.pomarSelecionado.id) {
                    return;
                }
                this.modalTratamentoPomar = false;
                this.modalPlanejamentoPomar = false;
                this.modalGerarCroquiPomar = false;
                this.modalHistoricoCroquiPomar = false;
                this.modalControleManejoPomar = false;
                this.modalControleEnxertiaPomar = false;
                this.modalProdutividadePomar = false;
                this.modalFenologiaPomar = false;
                this.modalPolinizacaoPomar = false;
                this.modalRelatorioPomar = false;
                this.modalAnexoPomar = false;
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POMAR)
                    .atualizar(this.pomarSelecionado);
                if ((typeof this.pomarSelecionado.adubacaoBaseFertilizante !== 'undefined') && this.pomarSelecionado.adubacaoBaseFertilizante) {
                    let produtoFertilizanteBase = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                        .id(this.pomarSelecionado.adubacaoBaseFertilizante.id)
                        .ver();
                    let historicoEstoqueProdutoFertilizanteBase = new HistoricoEstoqueProdutoFertilizante_1.HistoricoEstoqueProdutoFertilizante();
                    if ((typeof produtoFertilizanteBase !== 'undefined') && produtoFertilizanteBase) {
                        historicoEstoqueProdutoFertilizanteBase.produtoFertilizante = this.pomarSelecionado.adubacaoBaseFertilizante;
                        historicoEstoqueProdutoFertilizanteBase.quantidadeAnterior = (_a = produtoFertilizanteBase.quantidadeAtual) !== null && _a !== void 0 ? _a : 0;
                        historicoEstoqueProdutoFertilizanteBase.quantidadeMovimento = (_b = this.pomarSelecionado.adubacaoBaseQuantidadeFertilizante) !== null && _b !== void 0 ? _b : 0;
                        historicoEstoqueProdutoFertilizanteBase.quantidadeAtual = historicoEstoqueProdutoFertilizanteBase.quantidadeAnterior - historicoEstoqueProdutoFertilizanteBase.quantidadeMovimento;
                        historicoEstoqueProdutoFertilizanteBase.movimento = HistoricoEstoqueProdutoFertilizante_1.HistoricoEstoqueProdutoFertilizante.MOVIMENTO_SAIDA;
                        historicoEstoqueProdutoFertilizanteBase.usuario = this.usuarioLogado;
                        historicoEstoqueProdutoFertilizanteBase.centroCustoSap = this.pomarSelecionado.adubacaoBaseCentroCustoSap;
                        historicoEstoqueProdutoFertilizanteBase.contaDepositoSap = this.pomarSelecionado.adubacaoBaseContaDepositoSap;
                        produtoFertilizanteBase.quantidadeAtual = historicoEstoqueProdutoFertilizanteBase.quantidadeAtual;
                        yield this.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.HISTORICO_ESTOQUE_PRODUTO_FERTILIZANTE)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.HISTORICO_ESTOQUE_PRODUTO_FERTILIZANTE)
                            .criar(historicoEstoqueProdutoFertilizanteBase);
                        yield this.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                            .atualizar(produtoFertilizanteBase);
                    }
                }
                if ((typeof this.pomarSelecionado.adubacaoCoberturaFertilizante !== 'undefined') && this.pomarSelecionado.adubacaoCoberturaFertilizante) {
                    let produtoFertilizanteCobertura = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                        .id(this.pomarSelecionado.adubacaoCoberturaFertilizante.id)
                        .ver();
                    let historicoEstoqueProdutoFertilizanteCobertura = new HistoricoEstoqueProdutoFertilizante_1.HistoricoEstoqueProdutoFertilizante();
                    if ((typeof produtoFertilizanteCobertura !== 'undefined') && produtoFertilizanteCobertura) {
                        historicoEstoqueProdutoFertilizanteCobertura.produtoFertilizante = this.pomarSelecionado.adubacaoCoberturaFertilizante;
                        historicoEstoqueProdutoFertilizanteCobertura.quantidadeAnterior = (_c = produtoFertilizanteCobertura.quantidadeAtual) !== null && _c !== void 0 ? _c : 0;
                        historicoEstoqueProdutoFertilizanteCobertura.quantidadeMovimento = (_d = this.pomarSelecionado.adubacaoCoberturaQuantidadeFertilizante) !== null && _d !== void 0 ? _d : 0;
                        historicoEstoqueProdutoFertilizanteCobertura.quantidadeAtual = historicoEstoqueProdutoFertilizanteCobertura.quantidadeAnterior - historicoEstoqueProdutoFertilizanteCobertura.quantidadeMovimento;
                        historicoEstoqueProdutoFertilizanteCobertura.movimento = HistoricoEstoqueProdutoFertilizante_1.HistoricoEstoqueProdutoFertilizante.MOVIMENTO_SAIDA;
                        historicoEstoqueProdutoFertilizanteCobertura.usuario = this.usuarioLogado;
                        historicoEstoqueProdutoFertilizanteCobertura.centroCustoSap = this.pomarSelecionado.adubacaoCoberturaCentroCustoSap;
                        historicoEstoqueProdutoFertilizanteCobertura.contaDepositoSap = this.pomarSelecionado.adubacaoCoberturaContaDepositoSap;
                        produtoFertilizanteCobertura.quantidadeAtual = historicoEstoqueProdutoFertilizanteCobertura.quantidadeAtual;
                        yield this.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.HISTORICO_ESTOQUE_PRODUTO_FERTILIZANTE)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.HISTORICO_ESTOQUE_PRODUTO_FERTILIZANTE)
                            .criar(historicoEstoqueProdutoFertilizanteCobertura);
                        yield this.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTO_FERTILIZANTE)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTO_FERTILIZANTE)
                            .atualizar(produtoFertilizanteCobertura);
                    }
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (this.ehCelular) {
                    alert(`Informações do Pomar foram salvas com sucesso!`);
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'As informações do pomar foram salvas com sucesso!',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Ok'
                    });
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    carregarMapaPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.mapaPomarSelecionado = null;
            this.importaShapefileSelecionado = null;
            this.mapaLiberado = null;
            if (!this.pomarSelecionado || !this.pomarSelecionado.id) {
                return;
            }
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
            let filtro = {
                listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                pomarId: ((this.pomarSelecionado && this.pomarSelecionado.id) ? this.pomarSelecionado.id : null)
            };
            let listaMapaPomar = yield this.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.MAPA_POMAR)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MAPA_POMAR)
                .filtro(filtro)
                .listar();
            if ((typeof listaMapaPomar !== 'undefined') && listaMapaPomar) {
                angular.forEach(listaMapaPomar, (item) => {
                    if ((typeof item !== 'undefined') && item) {
                        ctrl.mapaPomarSelecionado = item;
                    }
                });
            }
            if (!this.mapaPomarSelecionado) {
                this.mapaPomarSelecionado = new MapaPomar_1.MapaPomar();
                this.mapaPomarSelecionado.uuid = ArmazenamentoUtil_1.ArmazenamentoUtil.uuid();
                this.mapaPomarSelecionado.criacao = new Date();
                this.mapaPomarSelecionado.pomar = this.pomarSelecionado;
                this.mapaPomarSelecionado.pomarCodigo = ((typeof this.pomarSelecionado.codigo !== 'undefined') && this.pomarSelecionado.codigo) ? this.pomarSelecionado.codigo : null;
                this.mapaPomarSelecionado.pomarDescricao = ((typeof this.pomarSelecionado.descricao !== 'undefined') && this.pomarSelecionado.descricao) ? this.pomarSelecionado.descricao : null;
                this.mapaPomarSelecionado.titulo = 'Mapa 1 - ' + DataUtil_1.DataUtil.converterDataParaFormatoDDMMYYYY(new Date());
            }
            yield this.atualizarMapaPomar();
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    atualizarMapaPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.pomarSelecionado === 'undefined') || !this.pomarSelecionado) {
                return;
            }
            const talhaoId = (this.pomarSelecionado.talhao && this.pomarSelecionado.talhao.id ? this.pomarSelecionado.talhao.id :
                (this.pomarSelecionado.talhaoId ? this.pomarSelecionado.talhaoId : null));
            if ((typeof talhaoId === 'undefined') || !talhaoId) {
                return;
            }
            this.mapaLiberado = false;
            this.scopeApply();
            if ((typeof this.timeoutAtualizacaoMapa !== 'undefined') && (this.timeoutAtualizacaoMapa !== null)) {
                clearTimeout(this.timeoutAtualizacaoMapa);
                this.timeoutAtualizacaoMapa = null;
            }
            this.timeoutAtualizacaoMapa = setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                this.importaShapefileSelecionado = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.IMPORTA_SHAPEFILE)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.IMPORTA_SHAPEFILE)
                    .id((this.pomarSelecionado.talhao && this.pomarSelecionado.talhao.id ? this.pomarSelecionado.talhao.id :
                    (this.pomarSelecionado.talhaoId ? this.pomarSelecionado.talhaoId : null)))
                    .ver();
                if ((typeof this.importaShapefileSelecionado === 'undefined') || !this.importaShapefileSelecionado) {
                    this.importaShapefileSelecionado = new ImportaShapefile_1.ImportaShapefile();
                }
                if ((typeof this.importaShapefileSelecionado.criacao !== 'undefined') && (this.importaShapefileSelecionado.criacao !== null)) {
                    this.importaShapefileSelecionado.criacao = new Date(this.importaShapefileSelecionado.criacao);
                }
                else {
                    this.importaShapefileSelecionado.criacao = new Date();
                }
                if ((typeof this.importaShapefileSelecionado.dataPlantio !== 'undefined') && (this.importaShapefileSelecionado.dataPlantio !== null)) {
                    this.importaShapefileSelecionado.dataPlantio = new Date(this.importaShapefileSelecionado.dataPlantio);
                }
                if ((typeof this.importaShapefileSelecionado.talhao !== 'undefined') && (this.importaShapefileSelecionado.talhao !== null)) {
                    if ((typeof this.importaShapefileSelecionado.talhao.dataPlantio !== 'undefined') && (this.importaShapefileSelecionado.talhao.dataPlantio !== null)) {
                        this.importaShapefileSelecionado.talhao.dataPlantio = new Date(this.importaShapefileSelecionado.talhao.dataPlantio);
                    }
                }
                if ((typeof this.importaShapefileSelecionado.geoJSON !== 'undefined') && (this.importaShapefileSelecionado.geoJSON !== null)) {
                    if (angular.isArray(this.importaShapefileSelecionado.geoJSON) && (this.importaShapefileSelecionado.geoJSON.length > 0)) {
                        setTimeout((controller, importaShapefile) => {
                            controller.mapaLiberado = true;
                            if (importaShapefile.geoJSON && (importaShapefile.geoJSON.length > 0)) {
                                let geoItem = importaShapefile.geoJSON[0];
                                let primeiraCoordenada = null;
                                let segundaCoordenada = null;
                                if ((typeof geoItem.geometry !== 'undefined') && geoItem.geometry &&
                                    (typeof geoItem.geometry.coordinates !== 'undefined') && geoItem.geometry.coordinates) {
                                    angular.forEach(geoItem.geometry.coordinates, (coord1) => {
                                        if (angular.isArray(coord1)) {
                                            angular.forEach(coord1, (coord2) => {
                                                if (angular.isArray(coord2)) {
                                                    angular.forEach(coord2, (coord3) => {
                                                        if (angular.isArray(coord3)) {
                                                            angular.forEach(coord3, (coord4) => {
                                                                if (angular.isArray(coord4)) {
                                                                    angular.forEach(coord4, (coord5) => {
                                                                        if ((typeof coord5 === 'number') || (typeof coord5 === 'string')) {
                                                                            if (primeiraCoordenada === null) {
                                                                                primeiraCoordenada = parseFloat('' + coord5);
                                                                            }
                                                                            else if (segundaCoordenada === null) {
                                                                                segundaCoordenada = parseFloat('' + coord5);
                                                                            }
                                                                        }
                                                                    });
                                                                }
                                                                else if ((typeof coord4 === 'number') || (typeof coord4 === 'string')) {
                                                                    if (primeiraCoordenada === null) {
                                                                        primeiraCoordenada = parseFloat('' + coord4);
                                                                    }
                                                                    else if (segundaCoordenada === null) {
                                                                        segundaCoordenada = parseFloat('' + coord4);
                                                                    }
                                                                }
                                                            });
                                                        }
                                                        else if ((typeof coord3 === 'number') || (typeof coord3 === 'string')) {
                                                            if (primeiraCoordenada === null) {
                                                                primeiraCoordenada = parseFloat('' + coord3);
                                                            }
                                                            else if (segundaCoordenada === null) {
                                                                segundaCoordenada = parseFloat('' + coord3);
                                                            }
                                                        }
                                                    });
                                                }
                                                else if ((typeof coord2 === 'number') || (typeof coord2 === 'string')) {
                                                    if (primeiraCoordenada === null) {
                                                        primeiraCoordenada = parseFloat('' + coord2);
                                                    }
                                                    else if (segundaCoordenada === null) {
                                                        segundaCoordenada = parseFloat('' + coord2);
                                                    }
                                                }
                                            });
                                        }
                                        else if ((typeof coord1 === 'number') || (typeof coord1 === 'string')) {
                                            if (primeiraCoordenada === null) {
                                                primeiraCoordenada = parseFloat('' + coord1);
                                            }
                                            else if (segundaCoordenada === null) {
                                                segundaCoordenada = parseFloat('' + coord1);
                                            }
                                        }
                                    });
                                }
                                if (primeiraCoordenada || segundaCoordenada) {
                                    controller.coordenadaMapa.longitude = primeiraCoordenada;
                                    controller.coordenadaMapa.latitude = segundaCoordenada;
                                    if ((typeof controller.mapaPomarSelecionado.latitude === 'undefined') || !controller.mapaPomarSelecionado.latitude) {
                                        controller.mapaPomarSelecionado.latitude = String(controller.coordenadaMapa.latitude);
                                    }
                                    if ((typeof controller.mapaPomarSelecionado.longitude === 'undefined') || !controller.mapaPomarSelecionado.longitude) {
                                        controller.mapaPomarSelecionado.longitude = String(controller.coordenadaMapa.longitude);
                                    }
                                    controller.geoJsonMapa = geoItem;
                                    controller.scopeApply();
                                }
                            }
                        }, 1000, ctrl, ctrl.importaShapefileSelecionado);
                    }
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }), 1000, this);
        });
    }
    alterarDadosGeograficosPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!this.pomarSelecionado || !this.pomarSelecionado.id) {
                    return;
                }
                if ((typeof this.pomarSelecionado.perfilClimaticoAnual !== 'undefined') && this.pomarSelecionado.perfilClimaticoAnual) {
                    this.pomarSelecionado.perfilClimaticoAnual.atualizacao = new Date();
                }
                else {
                    this.pomarSelecionado.perfilClimaticoAnual = new PerfilClimaticoPomar_1.PerfilClimaticoPomar();
                    this.pomarSelecionado.perfilClimaticoAnual.atualizacao = new Date();
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
        });
    }
    salvarDadosGeograficosPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!this.pomarSelecionado || !this.pomarSelecionado.id) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.modalTratamentoPomar = false;
                this.modalPlanejamentoPomar = false;
                this.modalGerarCroquiPomar = false;
                this.modalHistoricoCroquiPomar = false;
                this.modalControleManejoPomar = false;
                this.modalControleEnxertiaPomar = false;
                this.modalProdutividadePomar = false;
                this.modalFenologiaPomar = false;
                this.modalPolinizacaoPomar = false;
                this.modalRelatorioPomar = false;
                this.modalAnexoPomar = false;
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POMAR)
                    .atualizar(this.pomarSelecionado);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (this.ehCelular) {
                    alert(`Informações do Pomar foram salvas com sucesso!`);
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'As informações do pomar foram salvas com sucesso!',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Ok'
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    salvarMapaPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!this.mapaPomarSelecionado) {
                    return;
                }
                this.modalTratamentoPomar = false;
                this.modalPlanejamentoPomar = false;
                this.modalGerarCroquiPomar = false;
                this.modalHistoricoCroquiPomar = false;
                this.modalControleManejoPomar = false;
                this.modalControleEnxertiaPomar = false;
                this.modalProdutividadePomar = false;
                this.modalFenologiaPomar = false;
                this.modalPolinizacaoPomar = false;
                this.modalRelatorioPomar = false;
                this.modalAnexoPomar = false;
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (!this.mapaPomarSelecionado) {
                    this.mapaPomarSelecionado = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.MAPA_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MAPA_POMAR)
                        .criar(this.mapaPomarSelecionado);
                }
                else {
                    this.mapaPomarSelecionado = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.MAPA_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.MAPA_POMAR)
                        .atualizar(this.mapaPomarSelecionado);
                }
                if ((typeof this.mapaPomarSelecionado.pomar !== 'undefined') && this.mapaPomarSelecionado.pomar &&
                    (typeof this.mapaPomarSelecionado.pomar.descricao !== 'undefined') && this.mapaPomarSelecionado.pomar.descricao) {
                    this.mapaPomarSelecionado.pomarDescricao = this.mapaPomarSelecionado.pomar.descricao;
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    filtrarPlanejamentoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!this.pomarSelecionado || !this.pomarSelecionado.id) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.listaOpcoesPlanejamentoPomar = yield this.dadosListaPlanejamentoPomar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    salvarPlanejamentoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!this.planejamentoPomarSelecionado || this.planejamentoPomarVisualizar) {
                    return;
                }
                let listaCamposObrigatorios = '';
                if (listaCamposObrigatorios && (listaCamposObrigatorios.length > 0)) {
                    let mensagem = 'Campo obrigatório não preenchido!' +
                        listaCamposObrigatorios + '\r\n \r\n' +
                        'Preencha este campo e tente novamente.';
                    if (this.ehCelular) {
                        alert(mensagem.replace(/<b>/g, '').replace(/<\/b>/g, ''));
                    }
                    else {
                        yield sweetalert2_1.default.fire({
                            html: mensagem.replace(/\r\n/g, '<br>\r\n'),
                            icon: 'info',
                            showCancelButton: false,
                            showConfirmButton: true,
                            confirmButtonText: 'Ok'
                        });
                    }
                    return;
                }
                this.modalTratamentoPomar = false;
                this.modalGerarCroquiPomar = false;
                this.modalHistoricoCroquiPomar = false;
                this.modalControleManejoPomar = false;
                this.modalControleEnxertiaPomar = false;
                this.modalProdutividadePomar = false;
                this.modalFenologiaPomar = false;
                this.modalPolinizacaoPomar = false;
                this.modalRelatorioPomar = false;
                this.modalAnexoPomar = false;
                this.planejamentoPomarSelecionado.pomar = this.pomarSelecionado;
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                if (!this.planejamentoPomarSelecionado.id) {
                    this.planejamentoPomarSelecionado = null;
                }
                else {
                    this.planejamentoPomarSelecionado = null;
                }
                if ((typeof this.planejamentoPomarSelecionado.pomar !== 'undefined') && this.planejamentoPomarSelecionado.pomar &&
                    (typeof this.planejamentoPomarSelecionado.pomar.descricao !== 'undefined') && this.planejamentoPomarSelecionado.pomar.descricao) {
                    this.planejamentoPomarSelecionado.pomarDescricao = this.planejamentoPomarSelecionado.pomar.descricao;
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (this.planejamentoPomarSelecionado && this.planejamentoPomarSelecionado.id) {
                    this.modalPlanejamentoPomar = false;
                    if (this.ehCelular) {
                        alert(`Salvo com sucesso!`);
                    }
                    else {
                        sweetalert2_1.default.fire({
                            title: 'Salvo com sucesso!',
                            text: 'O planejamento foi salvo no banco de dados.',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'Ok'
                        });
                    }
                    this.listaOpcoesPlanejamentoPomar = yield this.dadosListaPlanejamentoPomar();
                }
                this.ocultarModalPlanejamentoPomar();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    exibirModalPlanejamentoPomar(dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.pomarSelecionado === 'undefined') || !this.pomarSelecionado) {
                return;
            }
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.planejamentoPomarVisualizar = true;
                if ((typeof dados !== 'undefined') && dados && (typeof dados.id !== 'undefined') && dados.id) {
                    this.planejamentoPomarSelecionado = null;
                    if ((typeof this.planejamentoPomarSelecionado === 'undefined') || !this.planejamentoPomarSelecionado) {
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        if (this.ehCelular) {
                            alert(`Registro "${dados.id} não encontrado!`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                text: `Registro "${dados.id}" não encontrado.`,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        return;
                    }
                    if ((typeof this.planejamentoPomarSelecionado.criacao === 'string') && this.planejamentoPomarSelecionado.criacao) {
                        this.planejamentoPomarSelecionado.criacao = new Date(this.planejamentoPomarSelecionado.criacao);
                    }
                    if ((typeof this.planejamentoPomarSelecionado.dataHoraInclusao === 'string') && this.planejamentoPomarSelecionado.dataHoraInclusao) {
                        this.planejamentoPomarSelecionado.dataHoraInclusao = new Date(this.planejamentoPomarSelecionado.dataHoraInclusao);
                    }
                    if ((typeof this.planejamentoPomarSelecionado.dataHoraAlteracao === 'string') && this.planejamentoPomarSelecionado.dataHoraAlteracao) {
                        this.planejamentoPomarSelecionado.dataHoraAlteracao = new Date(this.planejamentoPomarSelecionado.dataHoraAlteracao);
                    }
                    if ((typeof dados.visualizar !== 'undefined') && dados.visualizar) {
                        this.planejamentoPomarVisualizar = true;
                    }
                    else if ((typeof dados.editar !== 'undefined') && dados.editar) {
                        this.planejamentoPomarVisualizar = false;
                    }
                    else if ((typeof dados.excluir !== 'undefined') && dados.excluir) {
                        const ctrl = this;
                        this.notificacao
                            .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                            .then((result) => __awaiter(this, void 0, void 0, function* () {
                            if (result.value) {
                                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PLANEJAMENTO_POMAR)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PLANEJAMENTO_POMAR)
                                    .id(dados.id)
                                    .remover();
                                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                                ctrl.listaOpcoesPlanejamentoPomar = yield ctrl.dadosListaPlanejamentoPomar();
                                ctrl.scopeApply();
                            }
                        }));
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                }
                else {
                    this.planejamentoPomarSelecionado = new PlanejamentoPomar_1.PlanejamentoPomar();
                    this.planejamentoPomarSelecionado.criacao = new Date();
                    this.planejamentoPomarSelecionado.criacao.setMilliseconds(0);
                    this.planejamentoPomarSelecionado.ano = this.planejamentoPomarSelecionado.criacao.getFullYear();
                    this.planejamentoPomarSelecionado.mes = this.planejamentoPomarSelecionado.criacao.getMonth() + 1;
                    this.planejamentoPomarVisualizar = false;
                }
                this.modalPlanejamentoPomar = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalPlanejamentoPomar() {
        this.modalPlanejamentoPomar = false;
        angular.element('body').css('overflow-y', '');
        globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
        this.scopeApply(() => {
            setTimeout(() => {
                angular.element('body').css('overflow-y', '');
                if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                    (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                    angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                }
            }, 10);
        });
    }
    alterarMateriaisGeneticosPlanejamentoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.planejamentoPomarSelecionado !== 'undefined') && this.planejamentoPomarSelecionado &&
                (typeof this.planejamentoPomarSelecionado.materiaisGeneticos !== 'undefined') && this.planejamentoPomarSelecionado.materiaisGeneticos) {
                let ehSelecionarTodos = false;
                let ehDesmarcarTodos = false;
                let alterouRegistrosOperacao = false;
                angular.forEach(this.planejamentoPomarSelecionado.materiaisGeneticos, (materialGenetico) => {
                    if ((typeof materialGenetico !== 'undefined') && materialGenetico) {
                        const materialGeneticoId = ((typeof materialGenetico === 'string') ? materialGenetico :
                            ((typeof materialGenetico.id !== 'undefined') && materialGenetico.id ? materialGenetico.id :
                                ((typeof materialGenetico.value !== 'undefined') && materialGenetico.value ? materialGenetico.value : '')));
                        if (materialGeneticoId && (materialGeneticoId === 'TODOS')) {
                            ehSelecionarTodos = true;
                        }
                        else if (materialGeneticoId && (materialGeneticoId === 'NENHUM')) {
                            ehDesmarcarTodos = true;
                        }
                    }
                });
                if (ehSelecionarTodos) {
                    let lista = new Array();
                    if ((typeof this.selectizeOptionsMateriaisGeneticos !== 'undefined') && this.selectizeOptionsMateriaisGeneticos) {
                        angular.forEach(this.selectizeOptionsMateriaisGeneticos, (materialGenetico) => {
                            if ((typeof materialGenetico !== 'undefined') && materialGenetico) {
                                const materialGeneticoId = ((typeof materialGenetico === 'string') ? materialGenetico :
                                    ((typeof materialGenetico.id !== 'undefined') && materialGenetico.id ? materialGenetico.id :
                                        ((typeof materialGenetico.value !== 'undefined') && materialGenetico.value ? materialGenetico.value : '')));
                                if (materialGeneticoId && (materialGeneticoId !== 'TODOS') && (materialGeneticoId !== 'NENHUM')) {
                                    lista.push(materialGeneticoId);
                                }
                            }
                        });
                    }
                    this.planejamentoPomarSelecionado.materiaisGeneticos = lista;
                }
                else if (ehDesmarcarTodos) {
                    this.planejamentoPomarSelecionado.materiaisGeneticos = [];
                }
                if (ehSelecionarTodos || ehDesmarcarTodos) {
                    this.totalizarPlanejamentoPomar();
                    this.scopeApply(() => {
                        const elementos = angular.element('#materiaisGeneticosPlanejamentoPomar');
                        if (elementos && elementos.length > 0) {
                            const primeiroElemento = elementos.get(0);
                            primeiroElemento.selectize.close();
                        }
                    });
                }
                else if (alterouRegistrosOperacao) {
                    this.totalizarPlanejamentoPomar();
                    this.scopeApply();
                }
            }
        });
    }
    totalizarPlanejamentoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.planejamentoPomarSelecionado !== 'undefined') && this.planejamentoPomarSelecionado) {
            }
        });
    }
    visualizacaoResumidaControleManejoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleManejoUtil_1.PomarBancoConservacaoControleManejoUtil.visualizacaoResumidaControleManejoPomar(this);
        });
    }
    visualizacaoDetalhadaControleManejoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleManejoUtil_1.PomarBancoConservacaoControleManejoUtil.visualizacaoDetalhadaControleManejoPomar(this);
        });
    }
    filtrarControleManejoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleManejoUtil_1.PomarBancoConservacaoControleManejoUtil.filtrarControleManejoPomar(this);
        });
    }
    salvarControleManejoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleManejoUtil_1.PomarBancoConservacaoControleManejoUtil.salvarControleManejoPomar(this);
        });
    }
    exibirModalControleManejoPomar(dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleManejoUtil_1.PomarBancoConservacaoControleManejoUtil.exibirModalControleManejoPomar(this, dados);
        });
    }
    ocultarModalControleManejoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleManejoUtil_1.PomarBancoConservacaoControleManejoUtil.ocultarModalControleManejoPomar(this);
        });
    }
    alterarOperacaoControleManejoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleManejoUtil_1.PomarBancoConservacaoControleManejoUtil.alterarOperacaoControleManejoPomar(this);
        });
    }
    alterarMateriaisGeneticosControleManejoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleManejoUtil_1.PomarBancoConservacaoControleManejoUtil.alterarMateriaisGeneticosControleManejoPomar(this);
        });
    }
    alterarClonesMasculinosControleManejoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleManejoUtil_1.PomarBancoConservacaoControleManejoUtil.alterarClonesMasculinosControleManejoPomar(this);
        });
    }
    adicionarRegistroOperacaoControleManejoPomar(listagemOperacao) {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleManejoUtil_1.PomarBancoConservacaoControleManejoUtil.adicionarRegistroOperacaoControleManejoPomar(this, listagemOperacao);
        });
    }
    removerRegistroOperacaoControleManejoPomar(listagemOperacao, keyOperacao) {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleManejoUtil_1.PomarBancoConservacaoControleManejoUtil.removerRegistroOperacaoControleManejoPomar(this, listagemOperacao, keyOperacao);
        });
    }
    moverRegistroOperacaoControleManejoPomarParaCima(listagemOperacao, keyOperacao) {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleManejoUtil_1.PomarBancoConservacaoControleManejoUtil.moverRegistroOperacaoControleManejoPomarParaCima(this, listagemOperacao, keyOperacao);
        });
    }
    moverRegistroOperacaoControleManejoPomarParaBaixo(listagemOperacao, keyOperacao) {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleManejoUtil_1.PomarBancoConservacaoControleManejoUtil.moverRegistroOperacaoControleManejoPomarParaBaixo(this, listagemOperacao, keyOperacao);
        });
    }
    adicionarLinhaColunaRegistroOperacaoControleManejoPomar(operacaoControleManejo) {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleManejoUtil_1.PomarBancoConservacaoControleManejoUtil.adicionarLinhaColunaRegistroOperacaoControleManejoPomar(this, operacaoControleManejo);
        });
    }
    removerLinhaColunaRegistroOperacaoControleManejoPomar(operacaoControleManejo, keyLinhaColuna) {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleManejoUtil_1.PomarBancoConservacaoControleManejoUtil.removerLinhaColunaRegistroOperacaoControleManejoPomar(this, operacaoControleManejo, keyLinhaColuna);
        });
    }
    totalizarControleManejoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleManejoUtil_1.PomarBancoConservacaoControleManejoUtil.totalizarControleManejoPomar(this);
        });
    }
    alterarTipoDesbasteControleManejoPomar(keyOperacaoDesbaste) {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleManejoUtil_1.PomarBancoConservacaoControleManejoUtil.alterarTipoDesbasteControleManejoPomar(this, keyOperacaoDesbaste);
        });
    }
    alterarTipoColetaAmostraControleManejoPomar(keyOperacaoColetaAmostra) {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleManejoUtil_1.PomarBancoConservacaoControleManejoUtil.alterarTipoColetaAmostraControleManejoPomar(this, keyOperacaoColetaAmostra);
        });
    }
    alterarFinalidadeColetaAmostraControleManejoPomar(keyOperacaoColetaAmostra) {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleManejoUtil_1.PomarBancoConservacaoControleManejoUtil.alterarFinalidadeColetaAmostraControleManejoPomar(this, keyOperacaoColetaAmostra);
        });
    }
    tirarFotoControleManejoPomar(ehGaleria = false) {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleManejoUtil_1.PomarBancoConservacaoControleManejoUtil.tirarFotoControleManejoPomar(this, ehGaleria);
        });
    }
    removerFotoControleManejoPomar(fotoCamera) {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleManejoUtil_1.PomarBancoConservacaoControleManejoUtil.removerFotoControleManejoPomar(this, fotoCamera);
        });
    }
    filtrarControleEnxertiaPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleEnxertiaUtil_1.PomarBancoConservacaoControleEnxertiaUtil.filtrarControleEnxertiaPomar(this);
        });
    }
    salvarControleEnxertiaPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleEnxertiaUtil_1.PomarBancoConservacaoControleEnxertiaUtil.salvarControleEnxertiaPomar(this);
        });
    }
    exibirModalControleEnxertiaPomar(dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleEnxertiaUtil_1.PomarBancoConservacaoControleEnxertiaUtil.exibirModalControleEnxertiaPomar(this, dados);
        });
    }
    ocultarModalControleEnxertiaPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleEnxertiaUtil_1.PomarBancoConservacaoControleEnxertiaUtil.ocultarModalControleEnxertiaPomar(this);
        });
    }
    adicionarRegistroEnxertoControleEnxertiaPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleEnxertiaUtil_1.PomarBancoConservacaoControleEnxertiaUtil.adicionarRegistroEnxertoControleEnxertiaPomar(this);
        });
    }
    removerRegistroEnxertoControleEnxertiaPomar(keyEnxerto) {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleEnxertiaUtil_1.PomarBancoConservacaoControleEnxertiaUtil.removerRegistroEnxertoControleEnxertiaPomar(this, keyEnxerto);
        });
    }
    moverRegistroEnxertoControleEnxertiaPomarParaCima(keyEnxerto) {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleEnxertiaUtil_1.PomarBancoConservacaoControleEnxertiaUtil.moverRegistroEnxertoControleEnxertiaPomarParaCima(this, keyEnxerto);
        });
    }
    moverRegistroEnxertoControleEnxertiaPomarParaBaixo(keyEnxerto) {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleEnxertiaUtil_1.PomarBancoConservacaoControleEnxertiaUtil.moverRegistroEnxertoControleEnxertiaPomarParaBaixo(this, keyEnxerto);
        });
    }
    totalizarControleEnxertiaPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleEnxertiaUtil_1.PomarBancoConservacaoControleEnxertiaUtil.totalizarControleEnxertiaPomar(this);
        });
    }
    alterarRegistroControleEnxertiaPomar(enxerto) {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleEnxertiaUtil_1.PomarBancoConservacaoControleEnxertiaUtil.alterarRegistroControleEnxertiaPomar(this, enxerto);
        });
    }
    filtrarProdutividadePomar() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleProdutividadeUtil_1.PomarBancoConservacaoControleProdutividadeUtil.filtrarProdutividadePomar(this);
        });
    }
    salvarProdutividadePomar() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleProdutividadeUtil_1.PomarBancoConservacaoControleProdutividadeUtil.salvarProdutividadePomar(this);
        });
    }
    exibirModalProdutividadePomar(dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleProdutividadeUtil_1.PomarBancoConservacaoControleProdutividadeUtil.exibirModalProdutividadePomar(this, dados);
        });
    }
    ocultarModalProdutividadePomar() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleProdutividadeUtil_1.PomarBancoConservacaoControleProdutividadeUtil.ocultarModalProdutividadePomar(this);
        });
    }
    adicionarRegistroFuncionarioProdutividadePomar() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleProdutividadeUtil_1.PomarBancoConservacaoControleProdutividadeUtil.adicionarRegistroFuncionarioProdutividadePomar(this);
        });
    }
    removerRegistroFuncionarioProdutividadePomar(key) {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleProdutividadeUtil_1.PomarBancoConservacaoControleProdutividadeUtil.removerRegistroFuncionarioProdutividadePomar(this, key);
        });
    }
    moverRegistroFuncionarioProdutividadePomarParaCima(keyFuncionario) {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleProdutividadeUtil_1.PomarBancoConservacaoControleProdutividadeUtil.moverRegistroFuncionarioProdutividadePomarParaCima(this, keyFuncionario);
        });
    }
    moverRegistroFuncionarioProdutividadePomarParaBaixo(keyFuncionario) {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleProdutividadeUtil_1.PomarBancoConservacaoControleProdutividadeUtil.moverRegistroFuncionarioProdutividadePomarParaBaixo(this, keyFuncionario);
        });
    }
    totalizarProdutividadePomar() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleProdutividadeUtil_1.PomarBancoConservacaoControleProdutividadeUtil.totalizarProdutividadePomar(this);
        });
    }
    filtrarFenologiaPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleFenologiaUtil_1.PomarBancoConservacaoControleFenologiaUtil.filtrarFenologiaPomar(this);
        });
    }
    salvarFenologiaPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleFenologiaUtil_1.PomarBancoConservacaoControleFenologiaUtil.salvarFenologiaPomar(this);
        });
    }
    exibirModalFenologiaPomar(dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleFenologiaUtil_1.PomarBancoConservacaoControleFenologiaUtil.exibirModalFenologiaPomar(this, dados);
        });
    }
    ocultarModalFenologiaPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleFenologiaUtil_1.PomarBancoConservacaoControleFenologiaUtil.ocultarModalFenologiaPomar(this);
        });
    }
    adicionarRegistroFenologiaPomar(ehDetalhamentoGrafico = false) {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleFenologiaUtil_1.PomarBancoConservacaoControleFenologiaUtil.adicionarRegistroFenologiaPomar(this, ehDetalhamentoGrafico);
        });
    }
    removerRegistroFenologiaPomar(keyRegistro, ehDetalhamentoGrafico = false) {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleFenologiaUtil_1.PomarBancoConservacaoControleFenologiaUtil.removerRegistroFenologiaPomar(this, keyRegistro, ehDetalhamentoGrafico);
        });
    }
    moverRegistroFenologiaPomarParaCima(keyRegistro, ehDetalhamentoGrafico = false) {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleFenologiaUtil_1.PomarBancoConservacaoControleFenologiaUtil.moverRegistroFenologiaPomarParaCima(this, keyRegistro, ehDetalhamentoGrafico);
        });
    }
    moverRegistroFenologiaPomarParaBaixo(keyRegistro, ehDetalhamentoGrafico = false) {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleFenologiaUtil_1.PomarBancoConservacaoControleFenologiaUtil.moverRegistroFenologiaPomarParaBaixo(this, keyRegistro, ehDetalhamentoGrafico);
        });
    }
    totalizarFenologiaPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleFenologiaUtil_1.PomarBancoConservacaoControleFenologiaUtil.totalizarFenologiaPomar(this);
        });
    }
    montarGraficoFenologiaPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleFenologiaUtil_1.PomarBancoConservacaoControleFenologiaUtil.montarGraficoFenologiaPomar(this);
        });
    }
    exportarGraficoFenologiaPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            yield PomarBancoConservacaoControleFenologiaUtil_1.PomarBancoConservacaoControleFenologiaUtil.exportarGraficoFenologiaPomar(this);
        });
    }
    selecionarPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            let ctrl = this;
            if (ctrl && ctrl.constructor && ctrl.constructor.name) {
                if (ctrl.constructor.name === 'TabelaController') {
                    ctrl = ctrl.$scope.$parent.$ctrl;
                }
            }
            ctrl.filtrarPolinizacaoPomarSelecionado();
        });
    }
    limparFiltroPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.polinizacaoPomarSelecionadoTabela = null;
        });
    }
    filtrarPolinizacaoPomarSelecionado() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                let polinizacaoPomar = null;
                const nomeTabelaPolinizacao = 'tabelaBancoMelhoramentoPolinizacaoPomar';
                let gridPolinizacaoApi = null;
                let gridPolinizacaoScope = angular.element('#' + nomeTabelaPolinizacao).scope();
                if ((typeof gridPolinizacaoScope.listaGridApi !== 'undefined') && gridPolinizacaoScope.listaGridApi) {
                    angular.forEach(gridPolinizacaoScope.listaGridApi, (gridApi) => {
                        const grid = ((typeof gridApi.grid !== 'undefined') && gridApi.grid) ? gridApi.grid : null;
                        if (grid && (typeof grid.element !== 'undefined') && grid.element) {
                            const tabelasEl = angular.element(grid.element).parents('tabela');
                            if (tabelasEl && (tabelasEl.length > 0) && (tabelasEl.first().attr('id') === nomeTabelaPolinizacao)) {
                                gridPolinizacaoApi = gridApi;
                            }
                        }
                    });
                }
                if (gridPolinizacaoApi) {
                    const polinizacoesSelecionadas = gridPolinizacaoApi.selection.getSelectedRows();
                    if ((typeof polinizacoesSelecionadas !== 'undefined') && polinizacoesSelecionadas) {
                        angular.forEach(polinizacoesSelecionadas, (obj) => {
                            if (!polinizacaoPomar) {
                                polinizacaoPomar = obj;
                            }
                        });
                    }
                }
                if (polinizacaoPomar && (typeof polinizacaoPomar.id !== 'undefined') && polinizacaoPomar.id) {
                    if ((typeof ctrl.polinizacaoPomarSelecionadoTabela !== 'undefined') && ctrl.polinizacaoPomarSelecionadoTabela &&
                        (typeof ctrl.polinizacaoPomarSelecionadoTabela.id !== 'undefined') && ctrl.polinizacaoPomarSelecionadoTabela.id) {
                        if (ctrl.polinizacaoPomarSelecionadoTabela.id !== polinizacaoPomar.id) {
                            yield ctrl.limparFiltroPolinizacaoPomar();
                            ctrl.polinizacaoPomarSelecionadoTabela = polinizacaoPomar;
                        }
                    }
                    else {
                        yield ctrl.limparFiltroPolinizacaoPomar();
                        ctrl.polinizacaoPomarSelecionadoTabela = polinizacaoPomar;
                    }
                }
                else {
                    if (ctrl.polinizacaoPomarSelecionadoTabela) {
                        yield ctrl.limparFiltroPolinizacaoPomar();
                    }
                }
                ctrl.scopeApply();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
        });
    }
    salvarPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const ctrl = this;
                if (!this.polinizacaoPomarSelecionado || this.polinizacaoPomarVisualizar) {
                    return;
                }
                let listaCamposObrigatorios = '';
                if (listaCamposObrigatorios && (listaCamposObrigatorios.length > 0)) {
                    let mensagem = 'Campo obrigatório não preenchido!' +
                        listaCamposObrigatorios + '\r\n \r\n' +
                        'Preencha este campo e tente novamente.';
                    if (this.ehCelular) {
                        alert(mensagem.replace(/<b>/g, '').replace(/<\/b>/g, ''));
                    }
                    else {
                        yield sweetalert2_1.default.fire({
                            html: mensagem.replace(/\r\n/g, '<br>\r\n'),
                            icon: 'info',
                            showCancelButton: false,
                            showConfirmButton: true,
                            confirmButtonText: 'Ok'
                        });
                    }
                    return;
                }
                this.modalTratamentoPomar = false;
                this.modalGerarCroquiPomar = false;
                this.modalHistoricoCroquiPomar = false;
                this.modalPlanejamentoPomar = false;
                this.modalControleManejoPomar = false;
                this.modalControleEnxertiaPomar = false;
                this.modalProdutividadePomar = false;
                this.modalFenologiaPomar = false;
                this.modalRelatorioPomar = false;
                this.modalAnexoPomar = false;
                this.estoquePolenPolinizacaoPomarSelecionado = null;
                this.estoqueBeneficiamentoPinusPolinizacaoPomarSelecionado = null;
                this.estoqueBeneficiamentoEucalyptusPolinizacaoPomarSelecionado = null;
                this.registroPolinizacaoPomarSelecionado = null;
                this.indiceMonitoramentoFloracaoPolinizacaoPomarSelecionado = null;
                this.indiceLancamentoPolinizacaoPomarSelecionado = null;
                this.indiceColetaConesSementesPolinizacaoPomarSelecionado = null;
                this.indiceBeneficiamentoPolinizacaoPomarSelecionado = null;
                this.polinizacaoPomarSelecionado.pomar = this.pomarSelecionado;
                if (this.tipoPolinizacaoPomarSelecionado === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.PLANEJAMENTO_CRUZAMENTOS) {
                    let contadorInclusaoPlanejamento = 0;
                    if (this.polinizacaoPomarSelecionado.tipoPlanejamento === TipoPlanejamentoPolinizacaoPomar_1.TipoPlanejamentoPolinizacaoPomar.PINUS) {
                        if ((typeof this.polinizacaoPomarSelecionado.planejamentosPinus !== 'undefined') && this.polinizacaoPomarSelecionado.planejamentosPinus) {
                            angular.forEach(this.polinizacaoPomarSelecionado.planejamentosPinus, (polinizacaoPomar) => {
                                if (((typeof polinizacaoPomar.materialGeneticoFeminino !== 'undefined') && polinizacaoPomar.materialGeneticoFeminino) ||
                                    ((typeof polinizacaoPomar.pomarOrigemPolen !== 'undefined') && polinizacaoPomar.pomarOrigemPolen) ||
                                    ((typeof polinizacaoPomar.materialGeneticoMasculino !== 'undefined') && polinizacaoPomar.materialGeneticoMasculino)) {
                                    if (contadorInclusaoPlanejamento === 0) {
                                        if ((typeof polinizacaoPomar.materialGeneticoFeminino !== 'undefined') && (polinizacaoPomar.materialGeneticoFeminino !== null)) {
                                            ctrl.polinizacaoPomarSelecionado.materialGeneticoFeminino = polinizacaoPomar.materialGeneticoFeminino;
                                        }
                                        if ((typeof polinizacaoPomar.pomarOrigemPolen !== 'undefined') && (polinizacaoPomar.pomarOrigemPolen !== null)) {
                                            ctrl.polinizacaoPomarSelecionado.pomarOrigemPolen = polinizacaoPomar.pomarOrigemPolen;
                                        }
                                        if ((typeof polinizacaoPomar.materialGeneticoMasculino !== 'undefined') && (polinizacaoPomar.materialGeneticoMasculino !== null)) {
                                            ctrl.polinizacaoPomarSelecionado.materialGeneticoMasculino = polinizacaoPomar.materialGeneticoMasculino;
                                        }
                                        if ((typeof polinizacaoPomar.especieMaterialGeneticoMasculino !== 'undefined') && (polinizacaoPomar.especieMaterialGeneticoMasculino !== null)) {
                                            ctrl.polinizacaoPomarSelecionado.especieMaterialGeneticoMasculino = polinizacaoPomar.especieMaterialGeneticoMasculino;
                                        }
                                    }
                                    else {
                                        let novaPolinizacaoPomar = new PolinizacaoPomar_1.PolinizacaoPomar();
                                        novaPolinizacaoPomar.status = true;
                                        novaPolinizacaoPomar.criacao = new Date();
                                        novaPolinizacaoPomar.criacao.setMilliseconds(0);
                                        novaPolinizacaoPomar.data = (typeof polinizacaoPomar.data !== 'undefined') ? polinizacaoPomar.data : null;
                                        novaPolinizacaoPomar.pomar = ctrl.pomarSelecionado;
                                        novaPolinizacaoPomar.pomarCodigo = (typeof polinizacaoPomar.pomarCodigo !== 'undefined') ? polinizacaoPomar.pomarCodigo : null;
                                        novaPolinizacaoPomar.pomarDescricao = (typeof polinizacaoPomar.pomarDescricao !== 'undefined') ? polinizacaoPomar.pomarDescricao : null;
                                        novaPolinizacaoPomar.tipoPlanejamento = TipoPlanejamentoPolinizacaoPomar_1.TipoPlanejamentoPolinizacaoPomar.PINUS;
                                        novaPolinizacaoPomar.materialGeneticoFeminino = (typeof polinizacaoPomar.materialGeneticoFeminino !== 'undefined') ? polinizacaoPomar.materialGeneticoFeminino : null;
                                        novaPolinizacaoPomar.pomarOrigemPolen = (typeof polinizacaoPomar.pomarOrigemPolen !== 'undefined') ? polinizacaoPomar.pomarOrigemPolen : null;
                                        novaPolinizacaoPomar.materialGeneticoMasculino = (typeof polinizacaoPomar.materialGeneticoMasculino !== 'undefined') ? polinizacaoPomar.materialGeneticoMasculino : null;
                                        novaPolinizacaoPomar.especieMaterialGeneticoMasculino = (typeof polinizacaoPomar.especieMaterialGeneticoMasculino !== 'undefined') ? polinizacaoPomar.especieMaterialGeneticoMasculino : null;
                                        novaPolinizacaoPomar.usuarioInclusao = ctrl.usuarioLogado;
                                        novaPolinizacaoPomar.dataHoraInclusao = new Date();
                                        novaPolinizacaoPomar.dataHoraInclusao.setMilliseconds(0);
                                        ctrl.sincronia
                                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.POLINIZACAO_POMAR)
                                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POLINIZACAO_POMAR)
                                            .criar(novaPolinizacaoPomar);
                                    }
                                    contadorInclusaoPlanejamento++;
                                }
                            });
                        }
                    }
                    else if (this.polinizacaoPomarSelecionado.tipoPlanejamento === TipoPlanejamentoPolinizacaoPomar_1.TipoPlanejamentoPolinizacaoPomar.EUCALYPTUS) {
                        if ((typeof this.polinizacaoPomarSelecionado.planejamentosEucalyptus !== 'undefined') && this.polinizacaoPomarSelecionado.planejamentosEucalyptus) {
                            angular.forEach(this.polinizacaoPomarSelecionado.planejamentosEucalyptus, (polinizacaoPomar) => {
                                if (((typeof polinizacaoPomar.materialGeneticoFeminino !== 'undefined') && polinizacaoPomar.materialGeneticoFeminino) ||
                                    ((typeof polinizacaoPomar.pomarOrigemPolen !== 'undefined') && polinizacaoPomar.pomarOrigemPolen) ||
                                    ((typeof polinizacaoPomar.materialGeneticoMasculino !== 'undefined') && polinizacaoPomar.materialGeneticoMasculino)) {
                                    if (contadorInclusaoPlanejamento === 0) {
                                        if ((typeof polinizacaoPomar.materialGeneticoFeminino !== 'undefined') && (polinizacaoPomar.materialGeneticoFeminino !== null)) {
                                            ctrl.polinizacaoPomarSelecionado.materialGeneticoFeminino = polinizacaoPomar.materialGeneticoFeminino;
                                        }
                                        if ((typeof polinizacaoPomar.pomarOrigemPolen !== 'undefined') && (polinizacaoPomar.pomarOrigemPolen !== null)) {
                                            ctrl.polinizacaoPomarSelecionado.pomarOrigemPolen = polinizacaoPomar.pomarOrigemPolen;
                                        }
                                        if ((typeof polinizacaoPomar.materialGeneticoMasculino !== 'undefined') && (polinizacaoPomar.materialGeneticoMasculino !== null)) {
                                            ctrl.polinizacaoPomarSelecionado.materialGeneticoMasculino = polinizacaoPomar.materialGeneticoMasculino;
                                        }
                                        if ((typeof polinizacaoPomar.especieMaterialGeneticoMasculino !== 'undefined') && (polinizacaoPomar.especieMaterialGeneticoMasculino !== null)) {
                                            ctrl.polinizacaoPomarSelecionado.especieMaterialGeneticoMasculino = polinizacaoPomar.especieMaterialGeneticoMasculino;
                                        }
                                    }
                                    else {
                                        let novaPolinizacaoPomar = new PolinizacaoPomar_1.PolinizacaoPomar();
                                        novaPolinizacaoPomar.status = true;
                                        novaPolinizacaoPomar.criacao = new Date();
                                        novaPolinizacaoPomar.criacao.setMilliseconds(0);
                                        novaPolinizacaoPomar.data = (typeof polinizacaoPomar.data !== 'undefined') ? polinizacaoPomar.data : null;
                                        novaPolinizacaoPomar.pomar = ctrl.pomarSelecionado;
                                        novaPolinizacaoPomar.pomarCodigo = (typeof polinizacaoPomar.pomarCodigo !== 'undefined') ? polinizacaoPomar.pomarCodigo : null;
                                        novaPolinizacaoPomar.pomarDescricao = (typeof polinizacaoPomar.pomarDescricao !== 'undefined') ? polinizacaoPomar.pomarDescricao : null;
                                        novaPolinizacaoPomar.tipoPlanejamento = TipoPlanejamentoPolinizacaoPomar_1.TipoPlanejamentoPolinizacaoPomar.EUCALYPTUS;
                                        novaPolinizacaoPomar.materialGeneticoFeminino = (typeof polinizacaoPomar.materialGeneticoFeminino !== 'undefined') ? polinizacaoPomar.materialGeneticoFeminino : null;
                                        novaPolinizacaoPomar.pomarOrigemPolen = (typeof polinizacaoPomar.pomarOrigemPolen !== 'undefined') ? polinizacaoPomar.pomarOrigemPolen : null;
                                        novaPolinizacaoPomar.materialGeneticoMasculino = (typeof polinizacaoPomar.materialGeneticoMasculino !== 'undefined') ? polinizacaoPomar.materialGeneticoMasculino : null;
                                        novaPolinizacaoPomar.especieMaterialGeneticoMasculino = (typeof polinizacaoPomar.especieMaterialGeneticoMasculino !== 'undefined') ? polinizacaoPomar.especieMaterialGeneticoMasculino : null;
                                        novaPolinizacaoPomar.usuarioInclusao = ctrl.usuarioLogado;
                                        novaPolinizacaoPomar.dataHoraInclusao = new Date();
                                        novaPolinizacaoPomar.dataHoraInclusao.setMilliseconds(0);
                                        ctrl.sincronia
                                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.POLINIZACAO_POMAR)
                                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POLINIZACAO_POMAR)
                                            .criar(novaPolinizacaoPomar);
                                    }
                                    contadorInclusaoPlanejamento++;
                                }
                            });
                        }
                    }
                }
                if (this.tipoPolinizacaoPomarSelecionado === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.MONITORAMENTO_FLORACAO) {
                    let monitoramentosFloracao = new Array();
                    if ((typeof this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino !== 'undefined') &&
                        this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino &&
                        (this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino.length > 0)) {
                        angular.forEach(this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino, (item) => {
                            if (((typeof item.dataColeta !== 'undefined') && item.dataColeta) ||
                                ((typeof item.dataIsolamento !== 'undefined') && item.dataIsolamento) ||
                                ((typeof item.materialGeneticoMasculino !== 'undefined') && item.materialGeneticoMasculino) ||
                                ((typeof item.fila !== 'undefined') && item.fila && (typeof item.coluna !== 'undefined') && item.coluna)) {
                                monitoramentosFloracao.push(item);
                            }
                        });
                    }
                    if ((typeof this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino !== 'undefined') &&
                        this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino &&
                        (this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino.length > 0)) {
                        angular.forEach(this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino, (item) => {
                            if (((typeof item.dataColeta !== 'undefined') && item.dataColeta) ||
                                ((typeof item.dataIsolamento !== 'undefined') && item.dataIsolamento) ||
                                ((typeof item.materialGeneticoMasculino !== 'undefined') && item.materialGeneticoMasculino) ||
                                ((typeof item.fila !== 'undefined') && item.fila && (typeof item.coluna !== 'undefined') && item.coluna)) {
                                monitoramentosFloracao.push(item);
                            }
                        });
                    }
                    if (monitoramentosFloracao && (monitoramentosFloracao.length > 0)) {
                        this.polinizacaoPomarSelecionado.monitoramentosFloracao = monitoramentosFloracao;
                    }
                }
                if ((this.tipoPolinizacaoPomarSelecionado === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.POLINIZACAO_PINUS) ||
                    (this.tipoPolinizacaoPomarSelecionado === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.POLINIZACAO_EUCALYPTUS)) {
                    let lancamentosPolinizacao = new Array();
                    if ((typeof this.polinizacaoPomarSelecionado.polinizacoesPinus !== 'undefined') &&
                        this.polinizacaoPomarSelecionado.polinizacoesPinus &&
                        (this.polinizacaoPomarSelecionado.polinizacoesPinus.length > 0)) {
                        angular.forEach(this.polinizacaoPomarSelecionado.polinizacoesPinus, (item) => {
                            if (((typeof item.dataInicial !== 'undefined') && item.dataInicial) ||
                                ((typeof item.dataFinal !== 'undefined') && item.dataFinal) ||
                                ((typeof item.dataRetiradaPacote !== 'undefined') && item.dataRetiradaPacote) ||
                                ((typeof item.data !== 'undefined') && item.data) ||
                                ((typeof item.pomarOrigem !== 'undefined') && item.pomarOrigem) ||
                                ((typeof item.materialGeneticoGenitor !== 'undefined') && item.materialGeneticoGenitor) ||
                                ((typeof item.hibridacao !== 'undefined') && item.hibridacao) ||
                                ((typeof item.cruzamento !== 'undefined') && item.cruzamento)) {
                                lancamentosPolinizacao.push(item);
                            }
                        });
                    }
                    if ((typeof this.polinizacaoPomarSelecionado.polinizacoesEucalyptus !== 'undefined') &&
                        this.polinizacaoPomarSelecionado.polinizacoesEucalyptus &&
                        (this.polinizacaoPomarSelecionado.polinizacoesEucalyptus.length > 0)) {
                        angular.forEach(this.polinizacaoPomarSelecionado.polinizacoesEucalyptus, (item) => {
                            if (((typeof item.dataInicial !== 'undefined') && item.dataInicial) ||
                                ((typeof item.dataFinal !== 'undefined') && item.dataFinal) ||
                                ((typeof item.dataRetiradaPacote !== 'undefined') && item.dataRetiradaPacote) ||
                                ((typeof item.data !== 'undefined') && item.data) ||
                                ((typeof item.pomarOrigem !== 'undefined') && item.pomarOrigem) ||
                                ((typeof item.materialGeneticoGenitor !== 'undefined') && item.materialGeneticoGenitor) ||
                                ((typeof item.hibridacao !== 'undefined') && item.hibridacao) ||
                                ((typeof item.cruzamento !== 'undefined') && item.cruzamento)) {
                                lancamentosPolinizacao.push(item);
                            }
                        });
                    }
                    if (lancamentosPolinizacao && (lancamentosPolinizacao.length > 0)) {
                        this.polinizacaoPomarSelecionado.polinizacoes = lancamentosPolinizacao;
                    }
                }
                if ((this.tipoPolinizacaoPomarSelecionado === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.BENEFICIAMENTO_PINUS) ||
                    (this.tipoPolinizacaoPomarSelecionado === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.BENEFICIAMENTO_EUCALYPTUS)) {
                    let beneficiamentos = new Array();
                    if ((typeof this.polinizacaoPomarSelecionado.beneficiamentosPinus !== 'undefined') &&
                        this.polinizacaoPomarSelecionado.beneficiamentosPinus &&
                        (this.polinizacaoPomarSelecionado.beneficiamentosPinus.length > 0)) {
                        angular.forEach(this.polinizacaoPomarSelecionado.beneficiamentosPinus, (item) => {
                            if (((typeof item.data !== 'undefined') && item.data) ||
                                ((typeof item.numSementesTotal !== 'undefined') && item.numSementesTotal) ||
                                ((typeof item.numSementesBoas !== 'undefined') && item.numSementesBoas) ||
                                ((typeof item.quantidade !== 'undefined') && item.quantidade)) {
                                beneficiamentos.push(item);
                            }
                        });
                    }
                    if ((typeof this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus !== 'undefined') &&
                        this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus &&
                        (this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus.length > 0)) {
                        angular.forEach(this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus, (item) => {
                            if (((typeof item.data !== 'undefined') && item.data) ||
                                ((typeof item.numSementesTotal !== 'undefined') && item.numSementesTotal) ||
                                ((typeof item.numSementesBoas !== 'undefined') && item.numSementesBoas) ||
                                ((typeof item.quantidade !== 'undefined') && item.quantidade)) {
                                beneficiamentos.push(item);
                            }
                        });
                    }
                    if (beneficiamentos && (beneficiamentos.length > 0)) {
                        this.polinizacaoPomarSelecionado.beneficiamentos = beneficiamentos;
                    }
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                if (!this.polinizacaoPomarSelecionado.id) {
                    this.polinizacaoPomarSelecionado = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.POLINIZACAO_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POLINIZACAO_POMAR)
                        .criar(this.polinizacaoPomarSelecionado);
                }
                else {
                    this.polinizacaoPomarSelecionado = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.POLINIZACAO_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POLINIZACAO_POMAR)
                        .atualizar(this.polinizacaoPomarSelecionado);
                }
                if ((typeof this.polinizacaoPomarSelecionado.pomar !== 'undefined') && this.polinizacaoPomarSelecionado.pomar &&
                    (typeof this.polinizacaoPomarSelecionado.pomar.descricao !== 'undefined') && this.polinizacaoPomarSelecionado.pomar.descricao) {
                    this.polinizacaoPomarSelecionado.pomarDescricao = this.polinizacaoPomarSelecionado.pomar.descricao;
                }
                if ((typeof this.polinizacaoPomarSelecionado.monitoramentosFloracao !== 'undefined') &&
                    this.polinizacaoPomarSelecionado.monitoramentosFloracao) {
                    let alterouPlanilhaCroqui = false;
                    angular.forEach(this.polinizacaoPomarSelecionado.monitoramentosFloracao, (monitoramentoFloracao) => {
                        let ehEstrobiloFeminino = false;
                        let ehEstrobiloMasculino = false;
                        if ((typeof monitoramentoFloracao.estrobilo !== 'undefined') && monitoramentoFloracao.estrobilo &&
                            (typeof monitoramentoFloracao.estrobilo.id !== 'undefined') && monitoramentoFloracao.estrobilo.id) {
                            if (this.estrobiloFeminino && this.estrobiloFeminino.id && (monitoramentoFloracao.estrobilo.id === this.estrobiloFeminino.id)) {
                                ehEstrobiloFeminino = true;
                            }
                            else if (this.estrobiloMasculino && this.estrobiloMasculino.id && (monitoramentoFloracao.estrobilo.id === this.estrobiloMasculino.id)) {
                                ehEstrobiloMasculino = true;
                            }
                            else if (monitoramentoFloracao.estrobilo.id === Estrobilo_1.Estrobilo.FEMININO) {
                                ehEstrobiloFeminino = true;
                            }
                            else if (monitoramentoFloracao.estrobilo.id === Estrobilo_1.Estrobilo.MASCULINO) {
                                ehEstrobiloMasculino = true;
                            }
                        }
                        if (!ehEstrobiloFeminino && !ehEstrobiloMasculino) {
                            if ((typeof monitoramentoFloracao.materialGeneticoMasculino !== 'undefined') && monitoramentoFloracao.materialGeneticoMasculino &&
                                (typeof monitoramentoFloracao.materialGeneticoMasculino.id !== 'undefined') && monitoramentoFloracao.materialGeneticoMasculino.id) {
                                ehEstrobiloMasculino = true;
                            }
                        }
                        if (!ehEstrobiloFeminino && !ehEstrobiloMasculino) {
                            ehEstrobiloFeminino = true;
                        }
                        if (ehEstrobiloFeminino &&
                            (typeof globalThis.luckysheet !== 'undefined') && globalThis.luckysheet &&
                            (typeof monitoramentoFloracao.fila !== 'undefined') && monitoramentoFloracao.fila &&
                            (typeof monitoramentoFloracao.coluna !== 'undefined') && monitoramentoFloracao.coluna) {
                            let planilhaCroquiAnterior = (typeof globalThis.luckysheet.getSheet !== 'undefined') ? globalThis.luckysheet.getSheet() : null;
                            let planilhaCroquiAnteriorIdx = (typeof planilhaCroquiAnterior.order !== 'undefined') ? planilhaCroquiAnterior.order : null;
                            let tituloPlanilhaMonitoramentoFloracao = 'Monitoramento de Floração';
                            let referenciaPlanilhaMonitoramentoFloracao = TextoUtil_1.TextoUtil.converteTextoParaReferencia(tituloPlanilhaMonitoramentoFloracao);
                            let planilhaMonitoramentoFloracao = null;
                            let planilhaMonitoramentoFloracaoIdx = null;
                            let ultimaPlanilhaCroqui = null;
                            let planilhaCroquiOriginalN1 = null;
                            let planilhaCroquiOriginalN2 = null;
                            angular.forEach(globalThis.luckysheet.getAllSheets(), (sheet, key) => {
                                if ((typeof sheet.name !== 'undefined') && sheet.name) {
                                    const lcSheetName = String(sheet.name).toLowerCase().trim();
                                    if (!ultimaPlanilhaCroqui) {
                                        ultimaPlanilhaCroqui = sheet;
                                    }
                                    if (!planilhaCroquiOriginalN1 && (lcSheetName.indexOf('croqui') !== -1) && (lcSheetName.indexOf('enxerto') === -1)) {
                                        planilhaCroquiOriginalN1 = sheet;
                                    }
                                    if (!planilhaCroquiOriginalN2 && (lcSheetName.indexOf('enxerto') === -1)) {
                                        planilhaCroquiOriginalN2 = sheet;
                                    }
                                    if (!planilhaMonitoramentoFloracao && (TextoUtil_1.TextoUtil.converteTextoParaReferencia(sheet.name) === referenciaPlanilhaMonitoramentoFloracao)) {
                                        planilhaMonitoramentoFloracao = sheet;
                                        planilhaMonitoramentoFloracaoIdx = key;
                                    }
                                }
                            });
                            if (planilhaCroquiOriginalN1) {
                                ultimaPlanilhaCroqui = planilhaCroquiOriginalN1;
                            }
                            else if (planilhaCroquiOriginalN2) {
                                ultimaPlanilhaCroqui = planilhaCroquiOriginalN2;
                            }
                            if (!planilhaMonitoramentoFloracao && ultimaPlanilhaCroqui) {
                                globalThis.luckysheet.setSheetAdd({
                                    sheetObject: {
                                        name: tituloPlanilhaMonitoramentoFloracao,
                                        data: (ultimaPlanilhaCroqui.data ? angular.copy(ultimaPlanilhaCroqui.data) : []),
                                        config: (ultimaPlanilhaCroqui.config ? angular.copy(ultimaPlanilhaCroqui.config) : null)
                                    },
                                    order: 0
                                });
                                planilhaMonitoramentoFloracaoIdx = 0;
                                globalThis.luckysheet.setSheetActive(planilhaMonitoramentoFloracaoIdx);
                                angular.forEach(globalThis.luckysheet.getAllSheets(), (sheet) => {
                                    if ((typeof sheet.name !== 'undefined') && sheet.name) {
                                        if (!planilhaMonitoramentoFloracao && (TextoUtil_1.TextoUtil.converteTextoParaReferencia(sheet.name) === referenciaPlanilhaMonitoramentoFloracao)) {
                                            planilhaMonitoramentoFloracao = sheet;
                                        }
                                    }
                                });
                                alterouPlanilhaCroqui = true;
                            }
                            else if ((typeof planilhaMonitoramentoFloracaoIdx !== 'undefined') && (planilhaMonitoramentoFloracaoIdx !== null)) {
                                globalThis.luckysheet.setSheetActive(planilhaMonitoramentoFloracaoIdx);
                            }
                            let planilhasCroqui = [];
                            if (planilhaMonitoramentoFloracao) {
                                planilhasCroqui.push(planilhaMonitoramentoFloracao);
                            }
                            else {
                                angular.forEach(globalThis.luckysheet.getAllSheets(), (planilha) => {
                                    if ((typeof planilha.name !== 'undefined') && planilha.name) {
                                        const lcNomePlanilha = (typeof planilha.name === 'string') ? planilha.name.trim().toLowerCase() : String(planilha.name).trim().toLowerCase();
                                        if ((lcNomePlanilha.indexOf('croqui') !== -1) || (lcNomePlanilha.indexOf('enxerto') !== -1)) {
                                            planilhasCroqui.push(planilha);
                                        }
                                    }
                                });
                                if (!planilhasCroqui || (planilhasCroqui.length === 0)) {
                                    angular.forEach(globalThis.luckysheet.getAllSheets(), (planilha) => {
                                        planilhasCroqui.push(planilha);
                                    });
                                }
                            }
                            angular.forEach(planilhasCroqui, (planilha) => {
                                globalThis.luckysheet.setSheetActive(planilha.order);
                                let posicaoMaterialGeneticoCroqui = LuckysheetUtil_1.LuckysheetUtil.buscaPosicaoMaterialGeneticoCroqui(monitoramentoFloracao.fila, monitoramentoFloracao.coluna);
                                if (posicaoMaterialGeneticoCroqui && (posicaoMaterialGeneticoCroqui.row !== null) && (posicaoMaterialGeneticoCroqui.col !== null)) {
                                    if ((typeof planilha.data !== 'undefined') && planilha.data &&
                                        (posicaoMaterialGeneticoCroqui.row < planilha.data.length) &&
                                        (typeof planilha.data[posicaoMaterialGeneticoCroqui.row] !== 'undefined') && planilha.data[posicaoMaterialGeneticoCroqui.row] &&
                                        (posicaoMaterialGeneticoCroqui.col < planilha.data[posicaoMaterialGeneticoCroqui.row].length)) {
                                        let cell = planilha.data[posicaoMaterialGeneticoCroqui.row][posicaoMaterialGeneticoCroqui.col];
                                        if ((typeof cell !== 'undefined') && cell) {
                                            cell.bg = '#ffff00';
                                        }
                                        else {
                                            cell = { ct: { fa: 'General', t: 'n' }, bg: '#ffff00' };
                                        }
                                        globalThis.luckysheet.setCellValue(posicaoMaterialGeneticoCroqui.row, posicaoMaterialGeneticoCroqui.col, cell, { order: planilha.order });
                                        alterouPlanilhaCroqui = true;
                                    }
                                }
                            });
                            if ((typeof planilhaCroquiAnteriorIdx !== 'undefined') && (planilhaCroquiAnteriorIdx !== null)) {
                                globalThis.luckysheet.setSheetActive(planilhaCroquiAnteriorIdx);
                            }
                        }
                    });
                    if (alterouPlanilhaCroqui) {
                        yield this.salvarCroquiPomar();
                    }
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (this.polinizacaoPomarSelecionado && this.polinizacaoPomarSelecionado.id) {
                    this.modalPolinizacaoPomar = false;
                    if (this.ehCelular) {
                        alert(`Salvo com sucesso!`);
                    }
                    else {
                        sweetalert2_1.default.fire({
                            title: 'Salvo com sucesso!',
                            text: 'O lançamento foi salvo no banco de dados.',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'Ok'
                        });
                    }
                    this.listaOpcoesPolinizacaoPomar = yield this.dadosListaPolinizacaoPomar();
                    this.listaOpcoesMonitoramentoFloracaoMasculinoPolinizacaoPomar = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesMonitoramentoFloracaoFemininoPolinizacaoPomar = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesEstoquePolenPolinizacaoPomar = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesHistoricoEstoquePolenPolinizacaoPomar = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesLancamentosPinusPolinizacaoPomar = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesLancamentosEucalyptusPolinizacaoPomar = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesColetasConesSementesPolinizacaoPomar = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesBeneficiamentosPinusPolinizacaoPomar = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesEstoqueBeneficiamentoPinusPolinizacaoPomar = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesHistoricoEstoqueBeneficiamentoPinusPolinizacaoPomar = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesBeneficiamentosEucalyptusPolinizacaoPomar = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesEstoqueBeneficiamentoEucalyptusPolinizacaoPomar = {
                        data: [],
                        columnDefs: []
                    };
                    this.listaOpcoesHistoricoEstoqueBeneficiamentoEucalyptusPolinizacaoPomar = {
                        data: [],
                        columnDefs: []
                    };
                }
                this.ocultarModalPolinizacaoPomar();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    exibirModalPolinizacaoPomar(tipoPolinizacaoPomar, dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.pomarSelecionado === 'undefined') || !this.pomarSelecionado) {
                return;
            }
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                const ctrl = this;
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.polinizacaoPomarVisualizar = true;
                this.tipoPolinizacaoPomarSelecionado = tipoPolinizacaoPomar;
                this.estoquePolenPolinizacaoPomarSelecionado = null;
                this.estoqueBeneficiamentoPinusPolinizacaoPomarSelecionado = null;
                this.estoqueBeneficiamentoEucalyptusPolinizacaoPomarSelecionado = null;
                this.registroPolinizacaoPomarSelecionado = null;
                this.indiceMonitoramentoFloracaoPolinizacaoPomarSelecionado = null;
                this.indiceLancamentoPolinizacaoPomarSelecionado = null;
                this.indiceColetaConesSementesPolinizacaoPomarSelecionado = null;
                this.indiceBeneficiamentoPolinizacaoPomarSelecionado = null;
                if ((typeof dados !== 'undefined') && dados && (typeof dados.id !== 'undefined') && dados.id) {
                    this.polinizacaoPomarSelecionado = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.POLINIZACAO_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POLINIZACAO_POMAR)
                        .id(dados.id)
                        .ver();
                    if ((typeof this.polinizacaoPomarSelecionado === 'undefined') || !this.polinizacaoPomarSelecionado) {
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        if (this.ehCelular) {
                            alert(`Registro "${dados.id} não encontrado!`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                text: `Registro "${dados.id}" não encontrado.`,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        return;
                    }
                    if ((typeof this.polinizacaoPomarSelecionado.criacao === 'string') && this.polinizacaoPomarSelecionado.criacao) {
                        this.polinizacaoPomarSelecionado.criacao = new Date(this.polinizacaoPomarSelecionado.criacao);
                    }
                    if ((typeof this.polinizacaoPomarSelecionado.data === 'string') && this.polinizacaoPomarSelecionado.data) {
                        this.polinizacaoPomarSelecionado.data = new Date(this.polinizacaoPomarSelecionado.data);
                    }
                    if ((typeof this.polinizacaoPomarSelecionado.dataHoraInclusao === 'string') && this.polinizacaoPomarSelecionado.dataHoraInclusao) {
                        this.polinizacaoPomarSelecionado.dataHoraInclusao = new Date(this.polinizacaoPomarSelecionado.dataHoraInclusao);
                    }
                    if ((typeof this.polinizacaoPomarSelecionado.dataHoraAlteracao === 'string') && this.polinizacaoPomarSelecionado.dataHoraAlteracao) {
                        this.polinizacaoPomarSelecionado.dataHoraAlteracao = new Date(this.polinizacaoPomarSelecionado.dataHoraAlteracao);
                    }
                    if ((typeof this.polinizacaoPomarSelecionado.planejamentosPinus === 'undefined') ||
                        (this.polinizacaoPomarSelecionado.planejamentosPinus === null)) {
                        this.polinizacaoPomarSelecionado.planejamentosPinus = new Array();
                    }
                    if ((typeof this.polinizacaoPomarSelecionado.planejamentosEucalyptus === 'undefined') ||
                        (this.polinizacaoPomarSelecionado.planejamentosEucalyptus === null)) {
                        this.polinizacaoPomarSelecionado.planejamentosEucalyptus = new Array();
                    }
                    if (this.tipoPolinizacaoPomarSelecionado === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.PLANEJAMENTO_CRUZAMENTOS) {
                        let itemPolinizacaoPomar = new PolinizacaoPomar_1.PolinizacaoPomar();
                        itemPolinizacaoPomar.id = this.polinizacaoPomarSelecionado.id;
                        itemPolinizacaoPomar.uuid = this.polinizacaoPomarSelecionado.uuid;
                        itemPolinizacaoPomar.status = true;
                        itemPolinizacaoPomar.data = (typeof this.polinizacaoPomarSelecionado.data !== 'undefined') ? this.polinizacaoPomarSelecionado.data : null;
                        itemPolinizacaoPomar.pomar = this.pomarSelecionado;
                        itemPolinizacaoPomar.pomarCodigo = (typeof this.polinizacaoPomarSelecionado.pomarCodigo !== 'undefined') ? this.polinizacaoPomarSelecionado.pomarCodigo : null;
                        itemPolinizacaoPomar.pomarDescricao = (typeof this.polinizacaoPomarSelecionado.pomarDescricao !== 'undefined') ? this.polinizacaoPomarSelecionado.pomarDescricao : null;
                        if (this.polinizacaoPomarSelecionado.tipoPlanejamento === TipoPlanejamentoPolinizacaoPomar_1.TipoPlanejamentoPolinizacaoPomar.PINUS) {
                            itemPolinizacaoPomar.tipoPlanejamento = TipoPlanejamentoPolinizacaoPomar_1.TipoPlanejamentoPolinizacaoPomar.PINUS;
                        }
                        else if (this.polinizacaoPomarSelecionado.tipoPlanejamento === TipoPlanejamentoPolinizacaoPomar_1.TipoPlanejamentoPolinizacaoPomar.EUCALYPTUS) {
                            itemPolinizacaoPomar.tipoPlanejamento = TipoPlanejamentoPolinizacaoPomar_1.TipoPlanejamentoPolinizacaoPomar.EUCALYPTUS;
                        }
                        itemPolinizacaoPomar.materialGeneticoFeminino = (typeof this.polinizacaoPomarSelecionado.materialGeneticoFeminino !== 'undefined') ? this.polinizacaoPomarSelecionado.materialGeneticoFeminino : null;
                        itemPolinizacaoPomar.pomarOrigemPolen = (typeof this.polinizacaoPomarSelecionado.pomarOrigemPolen !== 'undefined') ? this.polinizacaoPomarSelecionado.pomarOrigemPolen : null;
                        itemPolinizacaoPomar.materialGeneticoMasculino = (typeof this.polinizacaoPomarSelecionado.materialGeneticoMasculino !== 'undefined') ? this.polinizacaoPomarSelecionado.materialGeneticoMasculino : null;
                        itemPolinizacaoPomar.especieMaterialGeneticoMasculino = (typeof this.polinizacaoPomarSelecionado.especieMaterialGeneticoMasculino !== 'undefined') ? this.polinizacaoPomarSelecionado.especieMaterialGeneticoMasculino : null;
                        if (this.polinizacaoPomarSelecionado.tipoPlanejamento === TipoPlanejamentoPolinizacaoPomar_1.TipoPlanejamentoPolinizacaoPomar.PINUS) {
                            this.polinizacaoPomarSelecionado.planejamentosPinus.push(itemPolinizacaoPomar);
                        }
                        else if (this.polinizacaoPomarSelecionado.tipoPlanejamento === TipoPlanejamentoPolinizacaoPomar_1.TipoPlanejamentoPolinizacaoPomar.EUCALYPTUS) {
                            this.polinizacaoPomarSelecionado.planejamentosEucalyptus.push(itemPolinizacaoPomar);
                        }
                    }
                    if ((typeof this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino === 'undefined') ||
                        (this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino === null)) {
                        this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino = new Array();
                    }
                    if ((typeof this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino === 'undefined') ||
                        (this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino === null)) {
                        this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino = new Array();
                    }
                    if (this.tipoPolinizacaoPomarSelecionado === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.MONITORAMENTO_FLORACAO) {
                        if ((typeof this.polinizacaoPomarSelecionado.monitoramentosFloracao !== 'undefined') &&
                            this.polinizacaoPomarSelecionado.monitoramentosFloracao &&
                            (this.polinizacaoPomarSelecionado.monitoramentosFloracao.length > 0)) {
                            angular.forEach(this.polinizacaoPomarSelecionado.monitoramentosFloracao, (item) => {
                                if ((typeof item.dataColeta === 'string') && item.dataColeta) {
                                    item.dataColeta = new Date(item.dataColeta);
                                }
                                if ((typeof item.dataIsolamento === 'string') && item.dataIsolamento) {
                                    item.dataIsolamento = new Date(item.dataIsolamento);
                                }
                                if ((typeof item.estrobilo !== 'undefined') && item.estrobilo &&
                                    (typeof item.estrobilo.id !== 'undefined') && item.estrobilo.id &&
                                    (item.estrobilo.id === Estrobilo_1.Estrobilo.MASCULINO)) {
                                    ctrl.polinizacaoPomarSelecionado.estrobilo = item.estrobilo;
                                    ctrl.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino.push(item);
                                }
                                else if ((typeof item.estrobilo !== 'undefined') && item.estrobilo &&
                                    (typeof item.estrobilo.id !== 'undefined') && item.estrobilo.id &&
                                    (item.estrobilo.id === Estrobilo_1.Estrobilo.FEMININO)) {
                                    ctrl.polinizacaoPomarSelecionado.estrobilo = item.estrobilo;
                                    ctrl.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino.push(item);
                                }
                                else if ((typeof ctrl.polinizacaoPomarSelecionado.estrobilo !== 'undefined') && ctrl.polinizacaoPomarSelecionado.estrobilo &&
                                    (typeof ctrl.polinizacaoPomarSelecionado.estrobilo.id !== 'undefined') && ctrl.polinizacaoPomarSelecionado.estrobilo.id &&
                                    (ctrl.polinizacaoPomarSelecionado.estrobilo.id === Estrobilo_1.Estrobilo.MASCULINO)) {
                                    ctrl.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino.push(item);
                                }
                                else if ((typeof ctrl.polinizacaoPomarSelecionado.estrobilo !== 'undefined') && ctrl.polinizacaoPomarSelecionado.estrobilo &&
                                    (typeof ctrl.polinizacaoPomarSelecionado.estrobilo.id !== 'undefined') && ctrl.polinizacaoPomarSelecionado.estrobilo.id &&
                                    (ctrl.polinizacaoPomarSelecionado.estrobilo.id === Estrobilo_1.Estrobilo.FEMININO)) {
                                    ctrl.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino.push(item);
                                }
                            });
                        }
                    }
                    if ((typeof this.polinizacaoPomarSelecionado.polinizacoesPinus === 'undefined') ||
                        (this.polinizacaoPomarSelecionado.polinizacoesPinus === null)) {
                        this.polinizacaoPomarSelecionado.polinizacoesPinus = new Array();
                    }
                    if ((typeof this.polinizacaoPomarSelecionado.polinizacoesEucalyptus === 'undefined') ||
                        (this.polinizacaoPomarSelecionado.polinizacoesEucalyptus === null)) {
                        this.polinizacaoPomarSelecionado.polinizacoesEucalyptus = new Array();
                    }
                    if ((this.tipoPolinizacaoPomarSelecionado === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.POLINIZACAO_PINUS) ||
                        (this.tipoPolinizacaoPomarSelecionado === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.POLINIZACAO_EUCALYPTUS)) {
                        if ((typeof this.polinizacaoPomarSelecionado.polinizacoes !== 'undefined') &&
                            this.polinizacaoPomarSelecionado.polinizacoes &&
                            (this.polinizacaoPomarSelecionado.polinizacoes.length > 0)) {
                            angular.forEach(this.polinizacaoPomarSelecionado.polinizacoes, (item) => {
                                if ((typeof item.data === 'string') && item.data) {
                                    item.data = new Date(item.data);
                                }
                                if ((typeof item.dataInicial === 'string') && item.dataInicial) {
                                    item.dataInicial = new Date(item.dataInicial);
                                }
                                if ((typeof item.dataFinal === 'string') && item.dataFinal) {
                                    item.dataFinal = new Date(item.dataFinal);
                                }
                                if ((typeof item.dataRetiradaPacote === 'string') && item.dataRetiradaPacote) {
                                    item.dataRetiradaPacote = new Date(item.dataRetiradaPacote);
                                }
                                if ((typeof item.ehPinus !== 'undefined') && item.ehPinus) {
                                    ctrl.polinizacaoPomarSelecionado.polinizacoesPinus.push(item);
                                }
                                else if ((typeof item.ehEucalyptus !== 'undefined') && item.ehEucalyptus) {
                                    ctrl.polinizacaoPomarSelecionado.polinizacoesEucalyptus.push(item);
                                }
                                else if (ctrl.tipoPolinizacaoPomarSelecionado === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.POLINIZACAO_PINUS) {
                                    ctrl.polinizacaoPomarSelecionado.polinizacoesPinus.push(item);
                                }
                                else if (ctrl.tipoPolinizacaoPomarSelecionado === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.POLINIZACAO_EUCALYPTUS) {
                                    ctrl.polinizacaoPomarSelecionado.polinizacoesEucalyptus.push(item);
                                }
                            });
                        }
                    }
                    if ((typeof this.polinizacaoPomarSelecionado.beneficiamentosPinus === 'undefined') ||
                        (this.polinizacaoPomarSelecionado.beneficiamentosPinus === null)) {
                        this.polinizacaoPomarSelecionado.beneficiamentosPinus = new Array();
                    }
                    if ((typeof this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus === 'undefined') ||
                        (this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus === null)) {
                        this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus = new Array();
                    }
                    if ((this.tipoPolinizacaoPomarSelecionado === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.BENEFICIAMENTO_PINUS) ||
                        (this.tipoPolinizacaoPomarSelecionado === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.BENEFICIAMENTO_EUCALYPTUS)) {
                        if ((typeof this.polinizacaoPomarSelecionado.beneficiamentos !== 'undefined') &&
                            this.polinizacaoPomarSelecionado.beneficiamentos &&
                            (this.polinizacaoPomarSelecionado.beneficiamentos.length > 0)) {
                            angular.forEach(this.polinizacaoPomarSelecionado.beneficiamentos, (item) => {
                                if ((typeof item.data === 'string') && item.data) {
                                    item.data = new Date(item.data);
                                }
                                if ((typeof item.ehPinus !== 'undefined') && item.ehPinus) {
                                    ctrl.polinizacaoPomarSelecionado.beneficiamentosPinus.push(item);
                                }
                                else if ((typeof item.ehEucalyptus !== 'undefined') && item.ehEucalyptus) {
                                    ctrl.polinizacaoPomarSelecionado.beneficiamentosEucalyptus.push(item);
                                }
                                else if (ctrl.tipoPolinizacaoPomarSelecionado === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.BENEFICIAMENTO_PINUS) {
                                    ctrl.polinizacaoPomarSelecionado.beneficiamentosPinus.push(item);
                                }
                                else if (ctrl.tipoPolinizacaoPomarSelecionado === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.BENEFICIAMENTO_EUCALYPTUS) {
                                    ctrl.polinizacaoPomarSelecionado.beneficiamentosEucalyptus.push(item);
                                }
                            });
                        }
                    }
                    if (this.tipoPolinizacaoPomarSelecionado === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.COLETA_CONES_SEMENTES) {
                        if ((typeof this.polinizacaoPomarSelecionado.coletasConesSementes !== 'undefined') &&
                            this.polinizacaoPomarSelecionado.coletasConesSementes &&
                            (this.polinizacaoPomarSelecionado.coletasConesSementes.length > 0)) {
                            angular.forEach(this.polinizacaoPomarSelecionado.coletasConesSementes, (item) => {
                                if ((typeof item.data === 'string') && item.data) {
                                    item.data = new Date(item.data);
                                }
                            });
                        }
                    }
                    if ((typeof this.polinizacaoPomarSelecionado.estrobilo !== 'undefined') && this.polinizacaoPomarSelecionado.estrobilo &&
                        (typeof this.polinizacaoPomarSelecionado.estrobilo.id !== 'undefined') && this.polinizacaoPomarSelecionado.estrobilo.id) {
                        if (this.estrobiloFeminino && this.estrobiloFeminino.id && (this.polinizacaoPomarSelecionado.estrobilo.id === this.estrobiloFeminino.id)) {
                            this.polinizacaoPomarSelecionado.estrobilo = this.estrobiloFeminino;
                        }
                        else if (this.estrobiloMasculino && this.estrobiloMasculino.id && (this.polinizacaoPomarSelecionado.estrobilo.id === this.estrobiloMasculino.id)) {
                            this.polinizacaoPomarSelecionado.estrobilo = this.estrobiloMasculino;
                        }
                    }
                    if ((typeof dados.visualizar !== 'undefined') && dados.visualizar) {
                        this.polinizacaoPomarVisualizar = true;
                    }
                    else if ((typeof dados.editar !== 'undefined') && dados.editar) {
                        this.polinizacaoPomarVisualizar = false;
                    }
                    else if ((typeof dados.excluir !== 'undefined') && dados.excluir) {
                        const ctrl = this;
                        this.notificacao
                            .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                            .then((result) => __awaiter(this, void 0, void 0, function* () {
                            if (result.value) {
                                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POLINIZACAO_POMAR)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POLINIZACAO_POMAR)
                                    .id(dados.id)
                                    .remover();
                                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                                ctrl.listaOpcoesPolinizacaoPomar = yield ctrl.dadosListaPolinizacaoPomar();
                                ctrl.listaOpcoesMonitoramentoFloracaoMasculinoPolinizacaoPomar = {
                                    data: [],
                                    columnDefs: []
                                };
                                ctrl.listaOpcoesMonitoramentoFloracaoFemininoPolinizacaoPomar = {
                                    data: [],
                                    columnDefs: []
                                };
                                ctrl.listaOpcoesEstoquePolenPolinizacaoPomar = {
                                    data: [],
                                    columnDefs: []
                                };
                                ctrl.listaOpcoesHistoricoEstoquePolenPolinizacaoPomar = {
                                    data: [],
                                    columnDefs: []
                                };
                                ctrl.listaOpcoesLancamentosPinusPolinizacaoPomar = {
                                    data: [],
                                    columnDefs: []
                                };
                                ctrl.listaOpcoesLancamentosEucalyptusPolinizacaoPomar = {
                                    data: [],
                                    columnDefs: []
                                };
                                ctrl.listaOpcoesColetasConesSementesPolinizacaoPomar = {
                                    data: [],
                                    columnDefs: []
                                };
                                ctrl.listaOpcoesBeneficiamentosPinusPolinizacaoPomar = {
                                    data: [],
                                    columnDefs: []
                                };
                                ctrl.listaOpcoesEstoqueBeneficiamentoPinusPolinizacaoPomar = {
                                    data: [],
                                    columnDefs: []
                                };
                                ctrl.listaOpcoesHistoricoEstoqueBeneficiamentoPinusPolinizacaoPomar = {
                                    data: [],
                                    columnDefs: []
                                };
                                ctrl.listaOpcoesBeneficiamentosEucalyptusPolinizacaoPomar = {
                                    data: [],
                                    columnDefs: []
                                };
                                ctrl.listaOpcoesEstoqueBeneficiamentoEucalyptusPolinizacaoPomar = {
                                    data: [],
                                    columnDefs: []
                                };
                                ctrl.listaOpcoesHistoricoEstoqueBeneficiamentoEucalyptusPolinizacaoPomar = {
                                    data: [],
                                    columnDefs: []
                                };
                                ctrl.listaOpcoesEstoqueBeneficiamentoPinusPolinizacaoPomar = {
                                    data: [],
                                    columnDefs: []
                                };
                                ctrl.listaOpcoesHistoricoEstoqueBeneficiamentoPinusPolinizacaoPomar = {
                                    data: [],
                                    columnDefs: []
                                };
                                ctrl.scopeApply();
                            }
                        }));
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                }
                else {
                    this.polinizacaoPomarSelecionado = new PolinizacaoPomar_1.PolinizacaoPomar();
                    this.polinizacaoPomarSelecionado.status = true;
                    this.polinizacaoPomarSelecionado.criacao = new Date();
                    this.polinizacaoPomarSelecionado.criacao.setMilliseconds(0);
                    this.polinizacaoPomarVisualizar = false;
                }
                if ((typeof this.polinizacaoPomarSelecionado.planejamentosPinus === 'undefined') ||
                    (this.polinizacaoPomarSelecionado.planejamentosPinus === null) ||
                    (this.polinizacaoPomarSelecionado.planejamentosPinus.length === 0)) {
                    this.polinizacaoPomarSelecionado.planejamentosPinus = new Array();
                    let planejamentoPolinizacaoPinus = new PolinizacaoPomar_1.PolinizacaoPomar();
                    planejamentoPolinizacaoPinus.status = true;
                    planejamentoPolinizacaoPinus.criacao = new Date();
                    planejamentoPolinizacaoPinus.criacao.setMilliseconds(0);
                    planejamentoPolinizacaoPinus.tipoPlanejamento = TipoPlanejamentoPolinizacaoPomar_1.TipoPlanejamentoPolinizacaoPomar.PINUS;
                    this.polinizacaoPomarSelecionado.planejamentosPinus.push(planejamentoPolinizacaoPinus);
                }
                if ((typeof this.polinizacaoPomarSelecionado.planejamentosEucalyptus === 'undefined') ||
                    (this.polinizacaoPomarSelecionado.planejamentosEucalyptus === null) ||
                    (this.polinizacaoPomarSelecionado.planejamentosEucalyptus.length === 0)) {
                    this.polinizacaoPomarSelecionado.planejamentosEucalyptus = new Array();
                    let planejamentoPolinizacaoEucalyptus = new PolinizacaoPomar_1.PolinizacaoPomar();
                    planejamentoPolinizacaoEucalyptus.status = true;
                    planejamentoPolinizacaoEucalyptus.criacao = new Date();
                    planejamentoPolinizacaoEucalyptus.criacao.setMilliseconds(0);
                    planejamentoPolinizacaoEucalyptus.tipoPlanejamento = TipoPlanejamentoPolinizacaoPomar_1.TipoPlanejamentoPolinizacaoPomar.EUCALYPTUS;
                    this.polinizacaoPomarSelecionado.planejamentosEucalyptus.push(planejamentoPolinizacaoEucalyptus);
                }
                if ((typeof this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino === 'undefined') ||
                    (this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino === null) ||
                    (this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino.length === 0)) {
                    this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino = new Array();
                    let monitoramentoFloracaoMasculino = new MonitoramentoFloracaoPolinizacaoPomar_1.MonitoramentoFloracaoPolinizacaoPomar();
                    monitoramentoFloracaoMasculino.status = true;
                    monitoramentoFloracaoMasculino.criacao = new Date();
                    monitoramentoFloracaoMasculino.criacao.setMilliseconds(0);
                    monitoramentoFloracaoMasculino.estrobilo = {
                        id: Estrobilo_1.Estrobilo.MASCULINO,
                        uuid: Estrobilo_1.Estrobilo.MASCULINO,
                        status: true,
                        codigo: 1,
                        descricao: 'Masculino'
                    };
                    this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino.push(monitoramentoFloracaoMasculino);
                }
                if ((typeof this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino === 'undefined') ||
                    (this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino === null) ||
                    (this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino.length === 0)) {
                    this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino = new Array();
                    let monitoramentoFloracaoFeminino = new MonitoramentoFloracaoPolinizacaoPomar_1.MonitoramentoFloracaoPolinizacaoPomar();
                    monitoramentoFloracaoFeminino.status = true;
                    monitoramentoFloracaoFeminino.criacao = new Date();
                    monitoramentoFloracaoFeminino.criacao.setMilliseconds(0);
                    monitoramentoFloracaoFeminino.estrobilo = {
                        id: Estrobilo_1.Estrobilo.FEMININO,
                        uuid: Estrobilo_1.Estrobilo.FEMININO,
                        status: true,
                        codigo: 2,
                        descricao: 'Feminino'
                    };
                    this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino.push(monitoramentoFloracaoFeminino);
                }
                if ((this.tipoPolinizacaoPomarSelecionado === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.MONITORAMENTO_FLORACAO) &&
                    (!this.polinizacaoPomarSelecionado || !this.polinizacaoPomarSelecionado.id)) {
                    this.estrobiloMonitoramentoFloracaoPolinizacaoPomar = this.estrobiloMasculino;
                    this.listaOpcoesMonitoramentoFloracaoMasculinoPolinizacaoPomar = yield this.dadosListaMonitoramentoFloracaoMasculinoPolinizacaoPomar();
                    this.listaOpcoesMonitoramentoFloracaoFemininoPolinizacaoPomar = yield this.dadosListaMonitoramentoFloracaoFemininoPolinizacaoPomar();
                    this.listaOpcoesEstoquePolenPolinizacaoPomar = yield this.dadosListaEstoquePolenPolinizacaoPomar();
                    this.listaOpcoesHistoricoEstoquePolenPolinizacaoPomar = yield this.dadosListaHistoricoEstoquePolenPolinizacaoPomar();
                }
                if ((typeof dados !== 'undefined') && dados && (typeof dados.materialGeneticoCroqui !== 'undefined') && dados.materialGeneticoCroqui) {
                    if ((typeof dados.materialGeneticoCroqui.materialGenetico !== 'undefined') && (dados.materialGeneticoCroqui.materialGenetico !== null)) {
                        let referenciaMaterialGeneticoCroqui = TextoUtil_1.TextoUtil.converteTextoParaReferencia(dados.materialGeneticoCroqui.materialGenetico);
                        if (referenciaMaterialGeneticoCroqui && (referenciaMaterialGeneticoCroqui.length > 0)) {
                            let materialGeneticoCroqui = null;
                            if ((typeof this.materiaisGeneticosFiltrados !== 'undefined') && this.materiaisGeneticosFiltrados && (this.materiaisGeneticosFiltrados.length > 0)) {
                                angular.forEach(this.materiaisGeneticosFiltrados, (materialGenetico) => {
                                    if (!materialGeneticoCroqui && materialGenetico.descricao && (TextoUtil_1.TextoUtil.converteTextoParaReferencia(materialGenetico.descricao) === referenciaMaterialGeneticoCroqui)) {
                                        materialGeneticoCroqui = materialGenetico;
                                    }
                                });
                            }
                            else if ((typeof this.materiaisGeneticos !== 'undefined') && this.materiaisGeneticos && (this.materiaisGeneticos.length > 0)) {
                                angular.forEach(this.materiaisGeneticos, (materialGenetico) => {
                                    if (!materialGeneticoCroqui && materialGenetico.descricao && (TextoUtil_1.TextoUtil.converteTextoParaReferencia(materialGenetico.descricao) === referenciaMaterialGeneticoCroqui)) {
                                        materialGeneticoCroqui = materialGenetico;
                                    }
                                });
                            }
                            if ((typeof materialGeneticoCroqui !== 'undefined') && materialGeneticoCroqui &&
                                (typeof materialGeneticoCroqui.id !== 'undefined') && materialGeneticoCroqui.id) {
                                if (this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino && (this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino.length > 0)) {
                                    let monitoramentoFloracaoFeminino = this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino[this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino.length - 1];
                                    monitoramentoFloracaoFeminino.materialGeneticoMasculino = materialGeneticoCroqui;
                                }
                                if (this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino && (this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino.length > 0)) {
                                    let monitoramentoFloracaoMasculino = this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino[this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino.length - 1];
                                    monitoramentoFloracaoMasculino.materialGeneticoMasculino = materialGeneticoCroqui;
                                }
                            }
                        }
                    }
                    if ((typeof dados.materialGeneticoCroqui.fila !== 'undefined') && (dados.materialGeneticoCroqui.fila !== null)) {
                        if (this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino && (this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino.length > 0)) {
                            let monitoramentoFloracaoFeminino = this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino[this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino.length - 1];
                            if ((typeof monitoramentoFloracaoFeminino.fila === 'undefined') || !monitoramentoFloracaoFeminino.fila) {
                                monitoramentoFloracaoFeminino.fila = dados.materialGeneticoCroqui.fila;
                            }
                        }
                        if (this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino && (this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino.length > 0)) {
                            let monitoramentoFloracaoMasculino = this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino[this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino.length - 1];
                            if ((typeof monitoramentoFloracaoMasculino.fila === 'undefined') || !monitoramentoFloracaoMasculino.fila) {
                                monitoramentoFloracaoMasculino.fila = dados.materialGeneticoCroqui.fila;
                            }
                        }
                    }
                    if ((typeof dados.materialGeneticoCroqui.coluna !== 'undefined') && (dados.materialGeneticoCroqui.coluna !== null)) {
                        if (this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino && (this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino.length > 0)) {
                            let monitoramentoFloracaoFeminino = this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino[this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino.length - 1];
                            if ((typeof monitoramentoFloracaoFeminino.coluna === 'undefined') || !monitoramentoFloracaoFeminino.coluna) {
                                monitoramentoFloracaoFeminino.coluna = dados.materialGeneticoCroqui.coluna;
                            }
                        }
                        if (this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino && (this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino.length > 0)) {
                            let monitoramentoFloracaoMasculino = this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino[this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino.length - 1];
                            if ((typeof monitoramentoFloracaoMasculino.coluna === 'undefined') || !monitoramentoFloracaoMasculino.coluna) {
                                monitoramentoFloracaoMasculino.coluna = dados.materialGeneticoCroqui.coluna;
                            }
                        }
                    }
                    if ((typeof dados.materialGeneticoCroqui.materialGenetico !== 'undefined') && (dados.materialGeneticoCroqui.materialGenetico !== null)) {
                        this.estrobiloMonitoramentoFloracaoPolinizacaoPomar = this.estrobiloFeminino;
                        yield this.adicionarMonitoramentoFloracaoPolinizacaoPomar({ materialGeneticoCroqui: dados.materialGeneticoCroqui });
                    }
                    else if ((typeof dados.materialGeneticoCroqui.fila !== 'undefined') && (dados.materialGeneticoCroqui.fila !== null) &&
                        (typeof dados.materialGeneticoCroqui.coluna !== 'undefined') && (dados.materialGeneticoCroqui.coluna !== null)) {
                        this.estrobiloMonitoramentoFloracaoPolinizacaoPomar = this.estrobiloFeminino;
                        yield this.adicionarMonitoramentoFloracaoPolinizacaoPomar({ materialGeneticoCroqui: dados.materialGeneticoCroqui });
                    }
                }
                if ((typeof this.polinizacaoPomarSelecionado.polinizacoesPinus === 'undefined') ||
                    (this.polinizacaoPomarSelecionado.polinizacoesPinus === null) ||
                    (this.polinizacaoPomarSelecionado.polinizacoesPinus.length === 0)) {
                    this.polinizacaoPomarSelecionado.polinizacoesPinus = new Array();
                    let lancamentoPolinizacaoPinus = new LancamentoPolinizacaoPomar_1.LancamentoPolinizacaoPomar();
                    lancamentoPolinizacaoPinus.status = true;
                    lancamentoPolinizacaoPinus.criacao = new Date();
                    lancamentoPolinizacaoPinus.criacao.setMilliseconds(0);
                    lancamentoPolinizacaoPinus.ehPinus = true;
                    lancamentoPolinizacaoPinus.ehEucalyptus = false;
                    this.polinizacaoPomarSelecionado.polinizacoesPinus.push(lancamentoPolinizacaoPinus);
                }
                if ((typeof this.polinizacaoPomarSelecionado.polinizacoesEucalyptus === 'undefined') ||
                    (this.polinizacaoPomarSelecionado.polinizacoesEucalyptus === null) ||
                    (this.polinizacaoPomarSelecionado.polinizacoesEucalyptus.length === 0)) {
                    this.polinizacaoPomarSelecionado.polinizacoesEucalyptus = new Array();
                    let lancamentoPolinizacaoEucalyptus = new LancamentoPolinizacaoPomar_1.LancamentoPolinizacaoPomar();
                    lancamentoPolinizacaoEucalyptus.status = true;
                    lancamentoPolinizacaoEucalyptus.criacao = new Date();
                    lancamentoPolinizacaoEucalyptus.criacao.setMilliseconds(0);
                    lancamentoPolinizacaoEucalyptus.ehPinus = false;
                    lancamentoPolinizacaoEucalyptus.ehEucalyptus = true;
                    this.polinizacaoPomarSelecionado.polinizacoesEucalyptus.push(lancamentoPolinizacaoEucalyptus);
                }
                if ((this.tipoPolinizacaoPomarSelecionado === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.POLINIZACAO_PINUS) ||
                    (this.tipoPolinizacaoPomarSelecionado === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.POLINIZACAO_EUCALYPTUS)) {
                    if ((typeof this.polinizacaoPomarSelecionado.polinizacoes !== 'undefined') && this.polinizacaoPomarSelecionado.polinizacoes) {
                        angular.forEach(this.polinizacaoPomarSelecionado.polinizacoes, (lancamentoPolinizacao) => __awaiter(this, void 0, void 0, function* () {
                            yield ctrl.alterarPolinizacaoEucalyptusPomar(lancamentoPolinizacao);
                        }));
                    }
                    if ((typeof this.polinizacaoPomarSelecionado.polinizacoesPinus !== 'undefined') && this.polinizacaoPomarSelecionado.polinizacoesPinus) {
                        angular.forEach(this.polinizacaoPomarSelecionado.polinizacoesPinus, (lancamentoPolinizacaoPinus) => __awaiter(this, void 0, void 0, function* () {
                            yield ctrl.alterarPolinizacaoEucalyptusPomar(lancamentoPolinizacaoPinus);
                        }));
                    }
                    if ((typeof this.polinizacaoPomarSelecionado.polinizacoesEucalyptus !== 'undefined') && this.polinizacaoPomarSelecionado.polinizacoesEucalyptus) {
                        angular.forEach(this.polinizacaoPomarSelecionado.polinizacoesEucalyptus, (lancamentoPolinizacaoEucalyptus) => __awaiter(this, void 0, void 0, function* () {
                            yield ctrl.alterarPolinizacaoEucalyptusPomar(lancamentoPolinizacaoEucalyptus);
                        }));
                    }
                }
                if ((this.tipoPolinizacaoPomarSelecionado === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.POLINIZACAO_PINUS) &&
                    (!this.polinizacaoPomarSelecionado || !this.polinizacaoPomarSelecionado.id)) {
                    this.listaOpcoesLancamentosPinusPolinizacaoPomar = yield this.dadosListaLancamentosPinusPolinizacaoPomar();
                }
                if ((this.tipoPolinizacaoPomarSelecionado === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.POLINIZACAO_EUCALYPTUS) &&
                    (!this.polinizacaoPomarSelecionado || !this.polinizacaoPomarSelecionado.id)) {
                    this.listaOpcoesLancamentosEucalyptusPolinizacaoPomar = yield this.dadosListaLancamentosEucalyptusPolinizacaoPomar();
                }
                if ((typeof this.polinizacaoPomarSelecionado.coletasConesSementes === 'undefined') ||
                    (this.polinizacaoPomarSelecionado.coletasConesSementes === null) ||
                    (this.polinizacaoPomarSelecionado.coletasConesSementes.length === 0)) {
                    this.polinizacaoPomarSelecionado.coletasConesSementes = new Array();
                    let coletaConesSementes = new ColetaConesSementesPolinizacaoPomar_1.ColetaConesSementesPolinizacaoPomar();
                    coletaConesSementes.status = true;
                    coletaConesSementes.criacao = new Date();
                    coletaConesSementes.criacao.setMilliseconds(0);
                    this.polinizacaoPomarSelecionado.coletasConesSementes.push(coletaConesSementes);
                }
                if ((this.tipoPolinizacaoPomarSelecionado === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.COLETA_CONES_SEMENTES) &&
                    (!this.polinizacaoPomarSelecionado || !this.polinizacaoPomarSelecionado.id)) {
                    this.listaOpcoesColetasConesSementesPolinizacaoPomar = yield this.dadosListaColetasConesSementesPolinizacaoPomar();
                }
                if ((typeof this.polinizacaoPomarSelecionado.beneficiamentosPinus === 'undefined') ||
                    (this.polinizacaoPomarSelecionado.beneficiamentosPinus === null) ||
                    (this.polinizacaoPomarSelecionado.beneficiamentosPinus.length === 0)) {
                    this.polinizacaoPomarSelecionado.beneficiamentosPinus = new Array();
                    let beneficiamentoPinus = new BeneficiamentoPolinizacaoPomar_1.BeneficiamentoPolinizacaoPomar();
                    beneficiamentoPinus.status = true;
                    beneficiamentoPinus.criacao = new Date();
                    beneficiamentoPinus.criacao.setMilliseconds(0);
                    beneficiamentoPinus.ehPinus = true;
                    beneficiamentoPinus.ehEucalyptus = false;
                    this.polinizacaoPomarSelecionado.beneficiamentosPinus.push(beneficiamentoPinus);
                }
                if ((typeof this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus === 'undefined') ||
                    (this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus === null) ||
                    (this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus.length === 0)) {
                    this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus = new Array();
                    let beneficiamentoEucalyptus = new BeneficiamentoPolinizacaoPomar_1.BeneficiamentoPolinizacaoPomar();
                    beneficiamentoEucalyptus.status = true;
                    beneficiamentoEucalyptus.criacao = new Date();
                    beneficiamentoEucalyptus.criacao.setMilliseconds(0);
                    beneficiamentoEucalyptus.ehPinus = false;
                    beneficiamentoEucalyptus.ehEucalyptus = true;
                    this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus.push(beneficiamentoEucalyptus);
                }
                if ((this.tipoPolinizacaoPomarSelecionado === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.BENEFICIAMENTO_PINUS) &&
                    (!this.polinizacaoPomarSelecionado || !this.polinizacaoPomarSelecionado.id)) {
                    this.listaOpcoesBeneficiamentosPinusPolinizacaoPomar = yield this.dadosListaBeneficiamentosPinusPolinizacaoPomar();
                    this.listaOpcoesEstoqueBeneficiamentoPinusPolinizacaoPomar = yield this.dadosListaEstoqueBeneficiamentoPinusPolinizacaoPomar();
                    this.listaOpcoesHistoricoEstoqueBeneficiamentoPinusPolinizacaoPomar = yield this.dadosListaHistoricoEstoqueBeneficiamentoPinusPolinizacaoPomar();
                }
                if ((this.tipoPolinizacaoPomarSelecionado === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.BENEFICIAMENTO_EUCALYPTUS) &&
                    (!this.polinizacaoPomarSelecionado || !this.polinizacaoPomarSelecionado.id)) {
                    this.listaOpcoesBeneficiamentosEucalyptusPolinizacaoPomar = yield this.dadosListaBeneficiamentosEucalyptusPolinizacaoPomar();
                    this.listaOpcoesEstoqueBeneficiamentoEucalyptusPolinizacaoPomar = yield this.dadosListaEstoqueBeneficiamentoEucalyptusPolinizacaoPomar();
                    this.listaOpcoesHistoricoEstoqueBeneficiamentoEucalyptusPolinizacaoPomar = yield this.dadosListaHistoricoEstoqueBeneficiamentoEucalyptusPolinizacaoPomar();
                }
                this.modalPolinizacaoPomar = true;
                angular.element('body').css('overflow-y', 'hidden');
                this.listaAnos = new Array();
                if ((typeof this.listaOpcoesPolinizacaoPomar !== 'undefined') && this.listaOpcoesPolinizacaoPomar &&
                    (typeof this.listaOpcoesPolinizacaoPomar.data !== 'undefined') && this.listaOpcoesPolinizacaoPomar.data) {
                    angular.forEach(this.listaOpcoesPolinizacaoPomar.data, (polinizacaoPomar) => {
                        if ((typeof polinizacaoPomar.monitoramentosFloracao !== 'undefined') && polinizacaoPomar.monitoramentosFloracao) {
                            angular.forEach(polinizacaoPomar.monitoramentosFloracao, (item) => {
                                if ((typeof item.dataColeta !== 'undefined') && item.dataColeta) {
                                    if (typeof item.dataColeta === 'string') {
                                        let data = new Date(item.dataColeta);
                                        if (ctrl.listaAnos.indexOf(data.getFullYear()) === -1) {
                                            ctrl.listaAnos.push(data.getFullYear());
                                        }
                                    }
                                    else {
                                        if (ctrl.listaAnos.indexOf(item.dataColeta.getFullYear()) === -1) {
                                            ctrl.listaAnos.push(item.dataColeta.getFullYear());
                                        }
                                    }
                                }
                                if ((typeof item.dataIsolamento !== 'undefined') && item.dataIsolamento) {
                                    if (typeof item.dataIsolamento === 'string') {
                                        let data = new Date(item.dataIsolamento);
                                        if (ctrl.listaAnos.indexOf(data.getFullYear()) === -1) {
                                            ctrl.listaAnos.push(data.getFullYear());
                                        }
                                    }
                                    else {
                                        if (ctrl.listaAnos.indexOf(item.dataIsolamento.getFullYear()) === -1) {
                                            ctrl.listaAnos.push(item.dataIsolamento.getFullYear());
                                        }
                                    }
                                }
                            });
                        }
                        if ((typeof polinizacaoPomar.monitoramentosFloracaoFeminino !== 'undefined') && polinizacaoPomar.monitoramentosFloracaoFeminino) {
                            angular.forEach(polinizacaoPomar.monitoramentosFloracaoFeminino, (item) => {
                                if ((typeof item.dataColeta !== 'undefined') && item.dataColeta) {
                                    if (typeof item.dataColeta === 'string') {
                                        let data = new Date(item.dataColeta);
                                        if (ctrl.listaAnos.indexOf(data.getFullYear()) === -1) {
                                            ctrl.listaAnos.push(data.getFullYear());
                                        }
                                    }
                                    else {
                                        if (ctrl.listaAnos.indexOf(item.dataColeta.getFullYear()) === -1) {
                                            ctrl.listaAnos.push(item.dataColeta.getFullYear());
                                        }
                                    }
                                }
                                if ((typeof item.dataIsolamento !== 'undefined') && item.dataIsolamento) {
                                    if (typeof item.dataIsolamento === 'string') {
                                        let data = new Date(item.dataIsolamento);
                                        if (ctrl.listaAnos.indexOf(data.getFullYear()) === -1) {
                                            ctrl.listaAnos.push(data.getFullYear());
                                        }
                                    }
                                    else {
                                        if (ctrl.listaAnos.indexOf(item.dataIsolamento.getFullYear()) === -1) {
                                            ctrl.listaAnos.push(item.dataIsolamento.getFullYear());
                                        }
                                    }
                                }
                            });
                        }
                        if ((typeof polinizacaoPomar.monitoramentosFloracaoMasculino !== 'undefined') && polinizacaoPomar.monitoramentosFloracaoMasculino) {
                            angular.forEach(polinizacaoPomar.monitoramentosFloracaoMasculino, (item) => {
                                if ((typeof item.dataColeta !== 'undefined') && item.dataColeta) {
                                    if (typeof item.dataColeta === 'string') {
                                        let data = new Date(item.dataColeta);
                                        if (ctrl.listaAnos.indexOf(data.getFullYear()) === -1) {
                                            ctrl.listaAnos.push(data.getFullYear());
                                        }
                                    }
                                    else {
                                        if (ctrl.listaAnos.indexOf(item.dataColeta.getFullYear()) === -1) {
                                            ctrl.listaAnos.push(item.dataColeta.getFullYear());
                                        }
                                    }
                                }
                                if ((typeof item.dataIsolamento !== 'undefined') && item.dataIsolamento) {
                                    if (typeof item.dataIsolamento === 'string') {
                                        let data = new Date(item.dataIsolamento);
                                        if (ctrl.listaAnos.indexOf(data.getFullYear()) === -1) {
                                            ctrl.listaAnos.push(data.getFullYear());
                                        }
                                    }
                                    else {
                                        if (ctrl.listaAnos.indexOf(item.dataIsolamento.getFullYear()) === -1) {
                                            ctrl.listaAnos.push(item.dataIsolamento.getFullYear());
                                        }
                                    }
                                }
                            });
                        }
                        if ((typeof polinizacaoPomar.polinizacoes !== 'undefined') && polinizacaoPomar.polinizacoes) {
                            angular.forEach(polinizacaoPomar.polinizacoes, (item) => {
                                if ((typeof item.dataInicial !== 'undefined') && item.dataInicial) {
                                    if (typeof item.dataInicial === 'string') {
                                        let data = new Date(item.dataInicial);
                                        if (ctrl.listaAnos.indexOf(data.getFullYear()) === -1) {
                                            ctrl.listaAnos.push(data.getFullYear());
                                        }
                                    }
                                    else {
                                        if (ctrl.listaAnos.indexOf(item.dataInicial.getFullYear()) === -1) {
                                            ctrl.listaAnos.push(item.dataInicial.getFullYear());
                                        }
                                    }
                                }
                                if ((typeof item.dataFinal !== 'undefined') && item.dataFinal) {
                                    if (typeof item.dataFinal === 'string') {
                                        let data = new Date(item.dataFinal);
                                        if (ctrl.listaAnos.indexOf(data.getFullYear()) === -1) {
                                            ctrl.listaAnos.push(data.getFullYear());
                                        }
                                    }
                                    else {
                                        if (ctrl.listaAnos.indexOf(item.dataFinal.getFullYear()) === -1) {
                                            ctrl.listaAnos.push(item.dataFinal.getFullYear());
                                        }
                                    }
                                }
                                if ((typeof item.data !== 'undefined') && item.data) {
                                    if (typeof item.data === 'string') {
                                        let data = new Date(item.data);
                                        if (ctrl.listaAnos.indexOf(data.getFullYear()) === -1) {
                                            ctrl.listaAnos.push(data.getFullYear());
                                        }
                                    }
                                    else {
                                        if (ctrl.listaAnos.indexOf(item.data.getFullYear()) === -1) {
                                            ctrl.listaAnos.push(item.data.getFullYear());
                                        }
                                    }
                                }
                            });
                        }
                        if ((typeof polinizacaoPomar.coletasConesSementes !== 'undefined') && polinizacaoPomar.coletasConesSementes) {
                            angular.forEach(polinizacaoPomar.coletasConesSementes, (item) => {
                                if ((typeof item.data !== 'undefined') && item.data) {
                                    if (typeof item.data === 'string') {
                                        let data = new Date(item.data);
                                        if (ctrl.listaAnos.indexOf(data.getFullYear()) === -1) {
                                            ctrl.listaAnos.push(data.getFullYear());
                                        }
                                    }
                                    else {
                                        if (ctrl.listaAnos.indexOf(item.data.getFullYear()) === -1) {
                                            ctrl.listaAnos.push(item.data.getFullYear());
                                        }
                                    }
                                }
                            });
                        }
                    });
                }
                if (this.listaAnos && (this.listaAnos.length > 0)) {
                    let dataAtual = new Date();
                    if (this.listaAnos.indexOf(dataAtual.getFullYear()) === -1) {
                        this.listaAnos.push(dataAtual.getFullYear());
                    }
                }
                else {
                    this.carregarTodosAnos();
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalPolinizacaoPomar() {
        this.modalPolinizacaoPomar = false;
        angular.element('body').css('overflow-y', '');
        this.carregarTodosAnos();
        globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
        this.scopeApply(() => {
            setTimeout(() => {
                angular.element('body').css('overflow-y', '');
                if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                    (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                    angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                }
            }, 10);
        });
    }
    adicionarRegistroPolinizacaoPomar(dados) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.polinizacaoPomarSelecionado || !dados || !dados.tipoPolinizacao) {
                return;
            }
            if (dados.tipoPolinizacao === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.PLANEJAMENTO_CRUZAMENTOS) {
                if ((typeof dados.tipoPlanejamento === 'undefined') || !dados.tipoPlanejamento) {
                    if ((typeof dados.ehPinus !== 'undefined') && dados.ehPinus) {
                        dados.tipoPlanejamento = TipoPlanejamentoPolinizacaoPomar_1.TipoPlanejamentoPolinizacaoPomar.PINUS;
                    }
                    else if ((typeof dados.ehEucalyptus !== 'undefined') && dados.ehEucalyptus) {
                        dados.tipoPlanejamento = TipoPlanejamentoPolinizacaoPomar_1.TipoPlanejamentoPolinizacaoPomar.EUCALYPTUS;
                    }
                }
                if (dados.tipoPlanejamento === TipoPlanejamentoPolinizacaoPomar_1.TipoPlanejamentoPolinizacaoPomar.PINUS) {
                    if ((typeof this.polinizacaoPomarSelecionado.planejamentosPinus === 'undefined') ||
                        (this.polinizacaoPomarSelecionado.planejamentosPinus === null)) {
                        this.polinizacaoPomarSelecionado.planejamentosPinus = new Array();
                    }
                    let planejamentoPolinizacaoPinus = new PolinizacaoPomar_1.PolinizacaoPomar();
                    planejamentoPolinizacaoPinus.status = true;
                    planejamentoPolinizacaoPinus.criacao = new Date();
                    planejamentoPolinizacaoPinus.criacao.setMilliseconds(0);
                    planejamentoPolinizacaoPinus.tipoPlanejamento = TipoPlanejamentoPolinizacaoPomar_1.TipoPlanejamentoPolinizacaoPomar.PINUS;
                    this.polinizacaoPomarSelecionado.planejamentosPinus.push(planejamentoPolinizacaoPinus);
                }
                if (dados.tipoPlanejamento === TipoPlanejamentoPolinizacaoPomar_1.TipoPlanejamentoPolinizacaoPomar.EUCALYPTUS) {
                    if ((typeof this.polinizacaoPomarSelecionado.planejamentosEucalyptus === 'undefined') ||
                        (this.polinizacaoPomarSelecionado.planejamentosEucalyptus === null)) {
                        this.polinizacaoPomarSelecionado.planejamentosEucalyptus = new Array();
                    }
                    let planejamentoPolinizacaoEucalyptus = new PolinizacaoPomar_1.PolinizacaoPomar();
                    planejamentoPolinizacaoEucalyptus.status = true;
                    planejamentoPolinizacaoEucalyptus.criacao = new Date();
                    planejamentoPolinizacaoEucalyptus.criacao.setMilliseconds(0);
                    planejamentoPolinizacaoEucalyptus.tipoPlanejamento = TipoPlanejamentoPolinizacaoPomar_1.TipoPlanejamentoPolinizacaoPomar.EUCALYPTUS;
                    this.polinizacaoPomarSelecionado.planejamentosEucalyptus.push(planejamentoPolinizacaoEucalyptus);
                }
            }
            if (dados.tipoPolinizacao === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.MONITORAMENTO_FLORACAO) {
                if (dados.estrobilo && dados.estrobilo.id && (dados.estrobilo.id === Estrobilo_1.Estrobilo.MASCULINO)) {
                    if ((typeof this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino === 'undefined') ||
                        (this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino === null)) {
                        this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino = new Array();
                    }
                    let monitoramentoFloracaoMasculino = new MonitoramentoFloracaoPolinizacaoPomar_1.MonitoramentoFloracaoPolinizacaoPomar();
                    monitoramentoFloracaoMasculino.status = true;
                    monitoramentoFloracaoMasculino.criacao = new Date();
                    monitoramentoFloracaoMasculino.criacao.setMilliseconds(0);
                    monitoramentoFloracaoMasculino.estrobilo = {
                        id: Estrobilo_1.Estrobilo.MASCULINO,
                        uuid: Estrobilo_1.Estrobilo.MASCULINO,
                        status: true,
                        codigo: 1,
                        descricao: 'Masculino'
                    };
                    this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino.push(monitoramentoFloracaoMasculino);
                }
                if (dados.estrobilo && dados.estrobilo.id && (dados.estrobilo.id === Estrobilo_1.Estrobilo.FEMININO)) {
                    if ((typeof this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino === 'undefined') ||
                        (this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino === null)) {
                        this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino = new Array();
                    }
                    let monitoramentoFloracaoFeminino = new MonitoramentoFloracaoPolinizacaoPomar_1.MonitoramentoFloracaoPolinizacaoPomar();
                    monitoramentoFloracaoFeminino.status = true;
                    monitoramentoFloracaoFeminino.criacao = new Date();
                    monitoramentoFloracaoFeminino.criacao.setMilliseconds(0);
                    monitoramentoFloracaoFeminino.estrobilo = {
                        id: Estrobilo_1.Estrobilo.FEMININO,
                        uuid: Estrobilo_1.Estrobilo.FEMININO,
                        status: true,
                        codigo: 2,
                        descricao: 'Feminino'
                    };
                    this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino.push(monitoramentoFloracaoFeminino);
                }
            }
            if (dados.tipoPolinizacao === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.POLINIZACAO_PINUS) {
                if ((typeof this.polinizacaoPomarSelecionado.polinizacoesPinus === 'undefined') ||
                    (this.polinizacaoPomarSelecionado.polinizacoesPinus === null)) {
                    this.polinizacaoPomarSelecionado.polinizacoesPinus = new Array();
                }
                let lancamentoPolinizacaoPinus = new LancamentoPolinizacaoPomar_1.LancamentoPolinizacaoPomar();
                lancamentoPolinizacaoPinus.status = true;
                lancamentoPolinizacaoPinus.criacao = new Date();
                lancamentoPolinizacaoPinus.criacao.setMilliseconds(0);
                lancamentoPolinizacaoPinus.ehPinus = true;
                lancamentoPolinizacaoPinus.ehEucalyptus = false;
                this.polinizacaoPomarSelecionado.polinizacoesPinus.push(lancamentoPolinizacaoPinus);
                this.alterarPolinizacaoEucalyptusPomar(lancamentoPolinizacaoPinus);
            }
            if (dados.tipoPolinizacao === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.POLINIZACAO_EUCALYPTUS) {
                if ((typeof this.polinizacaoPomarSelecionado.polinizacoesEucalyptus === 'undefined') ||
                    (this.polinizacaoPomarSelecionado.polinizacoesEucalyptus === null)) {
                    this.polinizacaoPomarSelecionado.polinizacoesEucalyptus = new Array();
                }
                let lancamentoPolinizacaoEucalyptus = new LancamentoPolinizacaoPomar_1.LancamentoPolinizacaoPomar();
                lancamentoPolinizacaoEucalyptus.status = true;
                lancamentoPolinizacaoEucalyptus.criacao = new Date();
                lancamentoPolinizacaoEucalyptus.criacao.setMilliseconds(0);
                lancamentoPolinizacaoEucalyptus.ehPinus = false;
                lancamentoPolinizacaoEucalyptus.ehEucalyptus = true;
                this.polinizacaoPomarSelecionado.polinizacoesEucalyptus.push(lancamentoPolinizacaoEucalyptus);
                this.alterarPolinizacaoEucalyptusPomar(lancamentoPolinizacaoEucalyptus);
            }
            if (dados.tipoPolinizacao === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.COLETA_CONES_SEMENTES) {
                if ((typeof this.polinizacaoPomarSelecionado.coletasConesSementes === 'undefined') ||
                    (this.polinizacaoPomarSelecionado.coletasConesSementes === null)) {
                    this.polinizacaoPomarSelecionado.coletasConesSementes = new Array();
                }
                let coletaConesSementes = new ColetaConesSementesPolinizacaoPomar_1.ColetaConesSementesPolinizacaoPomar();
                coletaConesSementes.status = true;
                coletaConesSementes.criacao = new Date();
                coletaConesSementes.criacao.setMilliseconds(0);
                this.polinizacaoPomarSelecionado.coletasConesSementes.push(coletaConesSementes);
            }
            if (dados.tipoPolinizacao === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.BENEFICIAMENTO_PINUS) {
                if ((typeof this.polinizacaoPomarSelecionado.beneficiamentosPinus === 'undefined') ||
                    (this.polinizacaoPomarSelecionado.beneficiamentosPinus === null)) {
                    this.polinizacaoPomarSelecionado.beneficiamentosPinus = new Array();
                }
                let beneficiamentoPinus = new BeneficiamentoPolinizacaoPomar_1.BeneficiamentoPolinizacaoPomar();
                beneficiamentoPinus.status = true;
                beneficiamentoPinus.criacao = new Date();
                beneficiamentoPinus.criacao.setMilliseconds(0);
                beneficiamentoPinus.ehPinus = true;
                beneficiamentoPinus.ehEucalyptus = false;
                this.polinizacaoPomarSelecionado.beneficiamentosPinus.push(beneficiamentoPinus);
            }
            if (dados.tipoPolinizacao === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.BENEFICIAMENTO_EUCALYPTUS) {
                if ((typeof this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus === 'undefined') ||
                    (this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus === null)) {
                    this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus = new Array();
                }
                let beneficiamentoEucalyptus = new BeneficiamentoPolinizacaoPomar_1.BeneficiamentoPolinizacaoPomar();
                beneficiamentoEucalyptus.status = true;
                beneficiamentoEucalyptus.criacao = new Date();
                beneficiamentoEucalyptus.criacao.setMilliseconds(0);
                beneficiamentoEucalyptus.ehPinus = false;
                beneficiamentoEucalyptus.ehEucalyptus = true;
                this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus.push(beneficiamentoEucalyptus);
            }
            yield this.totalizarPolinizacaoPomar();
            this.scopeApply();
        });
    }
    removerRegistroPolinizacaoPomar(dados) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.polinizacaoPomarSelecionado || !dados || !dados.tipoPolinizacao) {
                return;
            }
            if (dados.tipoPolinizacao === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.PLANEJAMENTO_CRUZAMENTOS) {
                if (!dados.tipoPlanejamento) {
                    if ((typeof dados.ehPinus !== 'undefined') && dados.ehPinus) {
                        dados.tipoPlanejamento = TipoPlanejamentoPolinizacaoPomar_1.TipoPlanejamentoPolinizacaoPomar.PINUS;
                    }
                    else if ((typeof dados.ehEucalyptus !== 'undefined') && dados.ehEucalyptus) {
                        dados.tipoPlanejamento = TipoPlanejamentoPolinizacaoPomar_1.TipoPlanejamentoPolinizacaoPomar.EUCALYPTUS;
                    }
                }
                if (dados.tipoPlanejamento === TipoPlanejamentoPolinizacaoPomar_1.TipoPlanejamentoPolinizacaoPomar.PINUS) {
                    if ((typeof this.polinizacaoPomarSelecionado.planejamentosPinus === 'undefined') ||
                        (this.polinizacaoPomarSelecionado.planejamentosPinus === null)) {
                        this.polinizacaoPomarSelecionado.planejamentosPinus = new Array();
                    }
                    if ((typeof dados.keyRegistro !== 'undefined') && (dados.keyRegistro !== null) &&
                        (this.polinizacaoPomarSelecionado.planejamentosPinus.length > 0) &&
                        (dados.keyRegistro <= this.polinizacaoPomarSelecionado.planejamentosPinus.length - 1)) {
                        const numRegistro = dados.keyRegistro + 1;
                        if (window.confirm('Deseja realmente remover esse registro #' + numRegistro + ' ?')) {
                            this.polinizacaoPomarSelecionado.planejamentosPinus.splice(dados.keyRegistro, 1);
                            yield this.totalizarPolinizacaoPomar();
                            this.scopeApply();
                        }
                    }
                }
                if (dados.tipoPlanejamento === TipoPlanejamentoPolinizacaoPomar_1.TipoPlanejamentoPolinizacaoPomar.EUCALYPTUS) {
                    if ((typeof this.polinizacaoPomarSelecionado.planejamentosEucalyptus === 'undefined') ||
                        (this.polinizacaoPomarSelecionado.planejamentosEucalyptus === null)) {
                        this.polinizacaoPomarSelecionado.planejamentosEucalyptus = new Array();
                    }
                    if ((typeof dados.keyRegistro !== 'undefined') && (dados.keyRegistro !== null) &&
                        (this.polinizacaoPomarSelecionado.planejamentosEucalyptus.length > 0) &&
                        (dados.keyRegistro <= this.polinizacaoPomarSelecionado.planejamentosEucalyptus.length - 1)) {
                        const numRegistro = dados.keyRegistro + 1;
                        if (window.confirm('Deseja realmente remover esse registro #' + numRegistro + ' ?')) {
                            this.polinizacaoPomarSelecionado.planejamentosEucalyptus.splice(dados.keyRegistro, 1);
                            yield this.totalizarPolinizacaoPomar();
                            this.scopeApply();
                        }
                    }
                }
            }
            if (dados.tipoPolinizacao === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.MONITORAMENTO_FLORACAO) {
                if (dados.estrobilo && dados.estrobilo.id && (dados.estrobilo.id === Estrobilo_1.Estrobilo.MASCULINO)) {
                    if ((typeof this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino === 'undefined') ||
                        (this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino === null)) {
                        this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino = new Array();
                    }
                    if ((typeof dados.keyRegistro !== 'undefined') && (dados.keyRegistro !== null) &&
                        (this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino.length > 0) &&
                        (dados.keyRegistro <= this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino.length - 1)) {
                        const numRegistro = dados.keyRegistro + 1;
                        if (window.confirm('Deseja realmente remover esse registro #' + numRegistro + ' ?')) {
                            this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino.splice(dados.keyRegistro, 1);
                            yield this.totalizarPolinizacaoPomar();
                            this.scopeApply();
                        }
                    }
                }
                if (dados.estrobilo && dados.estrobilo.id && (dados.estrobilo.id === Estrobilo_1.Estrobilo.FEMININO)) {
                    if ((typeof this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino === 'undefined') ||
                        (this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino === null)) {
                        this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino = new Array();
                    }
                    if ((typeof dados.keyRegistro !== 'undefined') && (dados.keyRegistro !== null) &&
                        (this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino.length > 0) &&
                        (dados.keyRegistro <= this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino.length - 1)) {
                        const numRegistro = dados.keyRegistro + 1;
                        if (window.confirm('Deseja realmente remover esse registro #' + numRegistro + ' ?')) {
                            this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino.splice(dados.keyRegistro, 1);
                            yield this.totalizarPolinizacaoPomar();
                            this.scopeApply();
                        }
                    }
                }
            }
            if (dados.tipoPolinizacao === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.POLINIZACAO_PINUS) {
                if ((typeof this.polinizacaoPomarSelecionado.polinizacoesPinus === 'undefined') ||
                    (this.polinizacaoPomarSelecionado.polinizacoesPinus === null)) {
                    this.polinizacaoPomarSelecionado.polinizacoesPinus = new Array();
                }
                if ((typeof dados.keyRegistro !== 'undefined') && (dados.keyRegistro !== null) &&
                    (this.polinizacaoPomarSelecionado.polinizacoesPinus.length > 0) &&
                    (dados.keyRegistro <= this.polinizacaoPomarSelecionado.polinizacoesPinus.length - 1)) {
                    const numRegistro = dados.keyRegistro + 1;
                    if (window.confirm('Deseja realmente remover esse registro #' + numRegistro + ' ?')) {
                        this.polinizacaoPomarSelecionado.polinizacoesPinus.splice(dados.keyRegistro, 1);
                        yield this.totalizarPolinizacaoPomar();
                        this.scopeApply();
                    }
                }
            }
            if (dados.tipoPolinizacao === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.POLINIZACAO_EUCALYPTUS) {
                if ((typeof this.polinizacaoPomarSelecionado.polinizacoesEucalyptus === 'undefined') ||
                    (this.polinizacaoPomarSelecionado.polinizacoesEucalyptus === null)) {
                    this.polinizacaoPomarSelecionado.polinizacoesEucalyptus = new Array();
                }
                if ((typeof dados.keyRegistro !== 'undefined') && (dados.keyRegistro !== null) &&
                    (this.polinizacaoPomarSelecionado.polinizacoesEucalyptus.length > 0) &&
                    (dados.keyRegistro <= this.polinizacaoPomarSelecionado.polinizacoesEucalyptus.length - 1)) {
                    const numRegistro = dados.keyRegistro + 1;
                    if (window.confirm('Deseja realmente remover esse registro #' + numRegistro + ' ?')) {
                        this.polinizacaoPomarSelecionado.polinizacoesEucalyptus.splice(dados.keyRegistro, 1);
                        yield this.totalizarPolinizacaoPomar();
                        this.scopeApply();
                    }
                }
            }
            if (dados.tipoPolinizacao === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.COLETA_CONES_SEMENTES) {
                if ((typeof this.polinizacaoPomarSelecionado.coletasConesSementes === 'undefined') ||
                    (this.polinizacaoPomarSelecionado.coletasConesSementes === null)) {
                    this.polinizacaoPomarSelecionado.coletasConesSementes = new Array();
                }
                if ((typeof dados.keyRegistro !== 'undefined') && (dados.keyRegistro !== null) &&
                    (this.polinizacaoPomarSelecionado.coletasConesSementes.length > 0) &&
                    (dados.keyRegistro <= this.polinizacaoPomarSelecionado.coletasConesSementes.length - 1)) {
                    const numRegistro = dados.keyRegistro + 1;
                    if (window.confirm('Deseja realmente remover esse registro #' + numRegistro + ' ?')) {
                        this.polinizacaoPomarSelecionado.coletasConesSementes.splice(dados.keyRegistro, 1);
                        yield this.totalizarPolinizacaoPomar();
                        this.scopeApply();
                    }
                }
            }
            if (dados.tipoPolinizacao === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.BENEFICIAMENTO_PINUS) {
                if ((typeof this.polinizacaoPomarSelecionado.beneficiamentosPinus === 'undefined') ||
                    (this.polinizacaoPomarSelecionado.beneficiamentosPinus === null)) {
                    this.polinizacaoPomarSelecionado.beneficiamentosPinus = new Array();
                }
                if ((typeof dados.keyRegistro !== 'undefined') && (dados.keyRegistro !== null) &&
                    (this.polinizacaoPomarSelecionado.beneficiamentosPinus.length > 0) &&
                    (dados.keyRegistro <= this.polinizacaoPomarSelecionado.beneficiamentosPinus.length - 1)) {
                    const numRegistro = dados.keyRegistro + 1;
                    if (window.confirm('Deseja realmente remover esse registro #' + numRegistro + ' ?')) {
                        this.polinizacaoPomarSelecionado.beneficiamentosPinus.splice(dados.keyRegistro, 1);
                        yield this.totalizarPolinizacaoPomar();
                        this.scopeApply();
                    }
                }
            }
            if (dados.tipoPolinizacao === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.BENEFICIAMENTO_EUCALYPTUS) {
                if ((typeof this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus === 'undefined') ||
                    (this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus === null)) {
                    this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus = new Array();
                }
                if ((typeof dados.keyRegistro !== 'undefined') && (dados.keyRegistro !== null) &&
                    (this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus.length > 0) &&
                    (dados.keyRegistro <= this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus.length - 1)) {
                    const numRegistro = dados.keyRegistro + 1;
                    if (window.confirm('Deseja realmente remover esse registro #' + numRegistro + ' ?')) {
                        this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus.splice(dados.keyRegistro, 1);
                        yield this.totalizarPolinizacaoPomar();
                        this.scopeApply();
                    }
                }
            }
        });
    }
    moverRegistroPolinizacaoPomarParaCima(dados) {
        if (!this.polinizacaoPomarSelecionado || !dados || !dados.tipoPolinizacao) {
            return;
        }
        if (dados.tipoPolinizacao === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.PLANEJAMENTO_CRUZAMENTOS) {
            if (!dados.tipoPlanejamento) {
                if ((typeof dados.ehPinus !== 'undefined') && dados.ehPinus) {
                    dados.tipoPlanejamento = TipoPlanejamentoPolinizacaoPomar_1.TipoPlanejamentoPolinizacaoPomar.PINUS;
                }
                else if ((typeof dados.ehEucalyptus !== 'undefined') && dados.ehEucalyptus) {
                    dados.tipoPlanejamento = TipoPlanejamentoPolinizacaoPomar_1.TipoPlanejamentoPolinizacaoPomar.EUCALYPTUS;
                }
            }
            if (dados.tipoPlanejamento === TipoPlanejamentoPolinizacaoPomar_1.TipoPlanejamentoPolinizacaoPomar.PINUS) {
                if ((typeof this.polinizacaoPomarSelecionado.planejamentosPinus === 'undefined') ||
                    (this.polinizacaoPomarSelecionado.planejamentosPinus === null)) {
                    this.polinizacaoPomarSelecionado.planejamentosPinus = new Array();
                }
                if ((typeof dados.keyRegistro !== 'undefined') && (dados.keyRegistro !== null) && (dados.keyRegistro > 0) &&
                    (this.polinizacaoPomarSelecionado.planejamentosPinus.length > 0) &&
                    (dados.keyRegistro <= this.polinizacaoPomarSelecionado.planejamentosPinus.length - 1)) {
                    let oldKey = dados.keyRegistro;
                    let newKey = dados.keyRegistro - 1;
                    this.polinizacaoPomarSelecionado.planejamentosPinus.splice(newKey, 0, this.polinizacaoPomarSelecionado.planejamentosPinus.splice(oldKey, 1)[0]);
                    this.scopeApply();
                }
            }
            if (dados.tipoPlanejamento === TipoPlanejamentoPolinizacaoPomar_1.TipoPlanejamentoPolinizacaoPomar.EUCALYPTUS) {
                if ((typeof this.polinizacaoPomarSelecionado.planejamentosEucalyptus === 'undefined') ||
                    (this.polinizacaoPomarSelecionado.planejamentosEucalyptus === null)) {
                    this.polinizacaoPomarSelecionado.planejamentosEucalyptus = new Array();
                }
                if ((typeof dados.keyRegistro !== 'undefined') && (dados.keyRegistro !== null) && (dados.keyRegistro > 0) &&
                    (this.polinizacaoPomarSelecionado.planejamentosEucalyptus.length > 0) &&
                    (dados.keyRegistro <= this.polinizacaoPomarSelecionado.planejamentosEucalyptus.length - 1)) {
                    let oldKey = dados.keyRegistro;
                    let newKey = dados.keyRegistro - 1;
                    this.polinizacaoPomarSelecionado.planejamentosEucalyptus.splice(newKey, 0, this.polinizacaoPomarSelecionado.planejamentosEucalyptus.splice(oldKey, 1)[0]);
                    this.scopeApply();
                }
            }
        }
        if (dados.tipoPolinizacao === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.MONITORAMENTO_FLORACAO) {
            if (dados.estrobilo && dados.estrobilo.id && (dados.estrobilo.id === Estrobilo_1.Estrobilo.MASCULINO)) {
                if ((typeof this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino === 'undefined') ||
                    (this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino === null)) {
                    this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino = new Array();
                }
                if ((typeof dados.keyRegistro !== 'undefined') && (dados.keyRegistro !== null) && (dados.keyRegistro > 0) &&
                    (this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino.length > 0) &&
                    (dados.keyRegistro <= this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino.length - 1)) {
                    let oldKey = dados.keyRegistro;
                    let newKey = dados.keyRegistro - 1;
                    this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino.splice(newKey, 0, this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino.splice(oldKey, 1)[0]);
                    this.scopeApply();
                }
            }
            if (dados.estrobilo && dados.estrobilo.id && (dados.estrobilo.id === Estrobilo_1.Estrobilo.FEMININO)) {
                if ((typeof this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino === 'undefined') ||
                    (this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino === null)) {
                    this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino = new Array();
                }
                if ((typeof dados.keyRegistro !== 'undefined') && (dados.keyRegistro !== null) && (dados.keyRegistro > 0) &&
                    (this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino.length > 0) &&
                    (dados.keyRegistro <= this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino.length - 1)) {
                    let oldKey = dados.keyRegistro;
                    let newKey = dados.keyRegistro - 1;
                    this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino.splice(newKey, 0, this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino.splice(oldKey, 1)[0]);
                    this.scopeApply();
                }
            }
        }
        if (dados.tipoPolinizacao === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.POLINIZACAO_PINUS) {
            if ((typeof this.polinizacaoPomarSelecionado.polinizacoesPinus === 'undefined') ||
                (this.polinizacaoPomarSelecionado.polinizacoesPinus === null)) {
                this.polinizacaoPomarSelecionado.polinizacoesPinus = new Array();
            }
            if ((typeof dados.keyRegistro !== 'undefined') && (dados.keyRegistro !== null) && (dados.keyRegistro > 0) &&
                (this.polinizacaoPomarSelecionado.polinizacoesPinus.length > 0) &&
                (dados.keyRegistro <= this.polinizacaoPomarSelecionado.polinizacoesPinus.length - 1)) {
                let oldKey = dados.keyRegistro;
                let newKey = dados.keyRegistro - 1;
                this.polinizacaoPomarSelecionado.polinizacoesPinus.splice(newKey, 0, this.polinizacaoPomarSelecionado.polinizacoesPinus.splice(oldKey, 1)[0]);
                this.scopeApply();
            }
        }
        if (dados.tipoPolinizacao === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.POLINIZACAO_EUCALYPTUS) {
            if ((typeof this.polinizacaoPomarSelecionado.polinizacoesEucalyptus === 'undefined') ||
                (this.polinizacaoPomarSelecionado.polinizacoesEucalyptus === null)) {
                this.polinizacaoPomarSelecionado.polinizacoesEucalyptus = new Array();
            }
            if ((typeof dados.keyRegistro !== 'undefined') && (dados.keyRegistro !== null) && (dados.keyRegistro > 0) &&
                (this.polinizacaoPomarSelecionado.polinizacoesEucalyptus.length > 0) &&
                (dados.keyRegistro <= this.polinizacaoPomarSelecionado.polinizacoesEucalyptus.length - 1)) {
                let oldKey = dados.keyRegistro;
                let newKey = dados.keyRegistro - 1;
                this.polinizacaoPomarSelecionado.polinizacoesEucalyptus.splice(newKey, 0, this.polinizacaoPomarSelecionado.polinizacoesEucalyptus.splice(oldKey, 1)[0]);
                this.scopeApply();
            }
        }
        if (dados.tipoPolinizacao === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.COLETA_CONES_SEMENTES) {
            if ((typeof this.polinizacaoPomarSelecionado.coletasConesSementes === 'undefined') ||
                (this.polinizacaoPomarSelecionado.coletasConesSementes === null)) {
                this.polinizacaoPomarSelecionado.coletasConesSementes = new Array();
            }
            if ((typeof dados.keyRegistro !== 'undefined') && (dados.keyRegistro !== null) && (dados.keyRegistro > 0) &&
                (this.polinizacaoPomarSelecionado.coletasConesSementes.length > 0) &&
                (dados.keyRegistro <= this.polinizacaoPomarSelecionado.coletasConesSementes.length - 1)) {
                let oldKey = dados.keyRegistro;
                let newKey = dados.keyRegistro - 1;
                this.polinizacaoPomarSelecionado.coletasConesSementes.splice(newKey, 0, this.polinizacaoPomarSelecionado.coletasConesSementes.splice(oldKey, 1)[0]);
                this.scopeApply();
            }
        }
        if (dados.tipoPolinizacao === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.BENEFICIAMENTO_PINUS) {
            if ((typeof this.polinizacaoPomarSelecionado.beneficiamentosPinus === 'undefined') ||
                (this.polinizacaoPomarSelecionado.beneficiamentosPinus === null)) {
                this.polinizacaoPomarSelecionado.beneficiamentosPinus = new Array();
            }
            if ((typeof dados.keyRegistro !== 'undefined') && (dados.keyRegistro !== null) && (dados.keyRegistro > 0) &&
                (this.polinizacaoPomarSelecionado.beneficiamentosPinus.length > 0) &&
                (dados.keyRegistro <= this.polinizacaoPomarSelecionado.beneficiamentosPinus.length - 1)) {
                let oldKey = dados.keyRegistro;
                let newKey = dados.keyRegistro - 1;
                this.polinizacaoPomarSelecionado.beneficiamentosPinus.splice(newKey, 0, this.polinizacaoPomarSelecionado.beneficiamentosPinus.splice(oldKey, 1)[0]);
                this.scopeApply();
            }
        }
        if (dados.tipoPolinizacao === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.BENEFICIAMENTO_EUCALYPTUS) {
            if ((typeof this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus === 'undefined') ||
                (this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus === null)) {
                this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus = new Array();
            }
            if ((typeof dados.keyRegistro !== 'undefined') && (dados.keyRegistro !== null) && (dados.keyRegistro > 0) &&
                (this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus.length > 0) &&
                (dados.keyRegistro <= this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus.length - 1)) {
                let oldKey = dados.keyRegistro;
                let newKey = dados.keyRegistro - 1;
                this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus.splice(newKey, 0, this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus.splice(oldKey, 1)[0]);
                this.scopeApply();
            }
        }
    }
    moverRegistroPolinizacaoPomarParaBaixo(dados) {
        if (!this.polinizacaoPomarSelecionado || !dados || !dados.tipoPolinizacao) {
            return;
        }
        if (dados.tipoPolinizacao === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.PLANEJAMENTO_CRUZAMENTOS) {
            if (!dados.tipoPlanejamento) {
                if ((typeof dados.ehPinus !== 'undefined') && dados.ehPinus) {
                    dados.tipoPlanejamento = TipoPlanejamentoPolinizacaoPomar_1.TipoPlanejamentoPolinizacaoPomar.PINUS;
                }
                else if ((typeof dados.ehEucalyptus !== 'undefined') && dados.ehEucalyptus) {
                    dados.tipoPlanejamento = TipoPlanejamentoPolinizacaoPomar_1.TipoPlanejamentoPolinizacaoPomar.EUCALYPTUS;
                }
            }
            if (dados.tipoPlanejamento === TipoPlanejamentoPolinizacaoPomar_1.TipoPlanejamentoPolinizacaoPomar.PINUS) {
                if ((typeof this.polinizacaoPomarSelecionado.planejamentosPinus === 'undefined') ||
                    (this.polinizacaoPomarSelecionado.planejamentosPinus === null)) {
                    this.polinizacaoPomarSelecionado.planejamentosPinus = new Array();
                }
                if ((typeof dados.keyRegistro !== 'undefined') && (dados.keyRegistro !== null) && (dados.keyRegistro >= 0) &&
                    (this.polinizacaoPomarSelecionado.planejamentosPinus.length > 0) &&
                    (dados.keyRegistro < this.polinizacaoPomarSelecionado.planejamentosPinus.length - 1)) {
                    let oldKey = dados.keyRegistro;
                    let newKey = dados.keyRegistro + 1;
                    this.polinizacaoPomarSelecionado.planejamentosPinus.splice(newKey, 0, this.polinizacaoPomarSelecionado.planejamentosPinus.splice(oldKey, 1)[0]);
                    this.scopeApply();
                }
            }
            if (dados.tipoPlanejamento === TipoPlanejamentoPolinizacaoPomar_1.TipoPlanejamentoPolinizacaoPomar.EUCALYPTUS) {
                if ((typeof this.polinizacaoPomarSelecionado.planejamentosEucalyptus === 'undefined') ||
                    (this.polinizacaoPomarSelecionado.planejamentosEucalyptus === null)) {
                    this.polinizacaoPomarSelecionado.planejamentosEucalyptus = new Array();
                }
                if ((typeof dados.keyRegistro !== 'undefined') && (dados.keyRegistro !== null) && (dados.keyRegistro >= 0) &&
                    (this.polinizacaoPomarSelecionado.planejamentosEucalyptus.length > 0) &&
                    (dados.keyRegistro < this.polinizacaoPomarSelecionado.planejamentosEucalyptus.length - 1)) {
                    let oldKey = dados.keyRegistro;
                    let newKey = dados.keyRegistro + 1;
                    this.polinizacaoPomarSelecionado.planejamentosEucalyptus.splice(newKey, 0, this.polinizacaoPomarSelecionado.planejamentosEucalyptus.splice(oldKey, 1)[0]);
                    this.scopeApply();
                }
            }
        }
        if (dados.tipoPolinizacao === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.MONITORAMENTO_FLORACAO) {
            if (dados.estrobilo && dados.estrobilo.id && (dados.estrobilo.id === Estrobilo_1.Estrobilo.MASCULINO)) {
                if ((typeof this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino === 'undefined') ||
                    (this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino === null)) {
                    this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino = new Array();
                }
                if ((typeof dados.keyRegistro !== 'undefined') && (dados.keyRegistro !== null) && (dados.keyRegistro >= 0) &&
                    (this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino.length > 0) &&
                    (dados.keyRegistro < this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino.length - 1)) {
                    let oldKey = dados.keyRegistro;
                    let newKey = dados.keyRegistro + 1;
                    this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino.splice(newKey, 0, this.polinizacaoPomarSelecionado.monitoramentosFloracaoMasculino.splice(oldKey, 1)[0]);
                    this.scopeApply();
                }
            }
            if (dados.estrobilo && dados.estrobilo.id && (dados.estrobilo.id === Estrobilo_1.Estrobilo.FEMININO)) {
                if ((typeof this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino === 'undefined') ||
                    (this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino === null)) {
                    this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino = new Array();
                }
                if ((typeof dados.keyRegistro !== 'undefined') && (dados.keyRegistro !== null) && (dados.keyRegistro >= 0) &&
                    (this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino.length > 0) &&
                    (dados.keyRegistro < this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino.length - 1)) {
                    let oldKey = dados.keyRegistro;
                    let newKey = dados.keyRegistro + 1;
                    this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino.splice(newKey, 0, this.polinizacaoPomarSelecionado.monitoramentosFloracaoFeminino.splice(oldKey, 1)[0]);
                    this.scopeApply();
                }
            }
        }
        if (dados.tipoPolinizacao === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.POLINIZACAO_PINUS) {
            if ((typeof this.polinizacaoPomarSelecionado.polinizacoesPinus === 'undefined') ||
                (this.polinizacaoPomarSelecionado.polinizacoesPinus === null)) {
                this.polinizacaoPomarSelecionado.polinizacoesPinus = new Array();
            }
            if ((typeof dados.keyRegistro !== 'undefined') && (dados.keyRegistro !== null) && (dados.keyRegistro >= 0) &&
                (this.polinizacaoPomarSelecionado.polinizacoesPinus.length > 0) &&
                (dados.keyRegistro < this.polinizacaoPomarSelecionado.polinizacoesPinus.length - 1)) {
                let oldKey = dados.keyRegistro;
                let newKey = dados.keyRegistro + 1;
                this.polinizacaoPomarSelecionado.polinizacoesPinus.splice(newKey, 0, this.polinizacaoPomarSelecionado.polinizacoesPinus.splice(oldKey, 1)[0]);
                this.scopeApply();
            }
        }
        if (dados.tipoPolinizacao === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.POLINIZACAO_EUCALYPTUS) {
            if ((typeof this.polinizacaoPomarSelecionado.polinizacoesEucalyptus === 'undefined') ||
                (this.polinizacaoPomarSelecionado.polinizacoesEucalyptus === null)) {
                this.polinizacaoPomarSelecionado.polinizacoesEucalyptus = new Array();
            }
            if ((typeof dados.keyRegistro !== 'undefined') && (dados.keyRegistro !== null) && (dados.keyRegistro >= 0) &&
                (this.polinizacaoPomarSelecionado.polinizacoesEucalyptus.length > 0) &&
                (dados.keyRegistro < this.polinizacaoPomarSelecionado.polinizacoesEucalyptus.length - 1)) {
                let oldKey = dados.keyRegistro;
                let newKey = dados.keyRegistro + 1;
                this.polinizacaoPomarSelecionado.polinizacoesEucalyptus.splice(newKey, 0, this.polinizacaoPomarSelecionado.polinizacoesEucalyptus.splice(oldKey, 1)[0]);
                this.scopeApply();
            }
        }
        if (dados.tipoPolinizacao === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.COLETA_CONES_SEMENTES) {
            if ((typeof this.polinizacaoPomarSelecionado.coletasConesSementes === 'undefined') ||
                (this.polinizacaoPomarSelecionado.coletasConesSementes === null)) {
                this.polinizacaoPomarSelecionado.coletasConesSementes = new Array();
            }
            if ((typeof dados.keyRegistro !== 'undefined') && (dados.keyRegistro !== null) && (dados.keyRegistro >= 0) &&
                (this.polinizacaoPomarSelecionado.coletasConesSementes.length > 0) &&
                (dados.keyRegistro < this.polinizacaoPomarSelecionado.coletasConesSementes.length - 1)) {
                let oldKey = dados.keyRegistro;
                let newKey = dados.keyRegistro + 1;
                this.polinizacaoPomarSelecionado.coletasConesSementes.splice(newKey, 0, this.polinizacaoPomarSelecionado.coletasConesSementes.splice(oldKey, 1)[0]);
                this.scopeApply();
            }
        }
        if (dados.tipoPolinizacao === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.BENEFICIAMENTO_PINUS) {
            if ((typeof this.polinizacaoPomarSelecionado.beneficiamentosPinus === 'undefined') ||
                (this.polinizacaoPomarSelecionado.beneficiamentosPinus === null)) {
                this.polinizacaoPomarSelecionado.beneficiamentosPinus = new Array();
            }
            if ((typeof dados.keyRegistro !== 'undefined') && (dados.keyRegistro !== null) && (dados.keyRegistro >= 0) &&
                (this.polinizacaoPomarSelecionado.beneficiamentosPinus.length > 0) &&
                (dados.keyRegistro < this.polinizacaoPomarSelecionado.beneficiamentosPinus.length - 1)) {
                let oldKey = dados.keyRegistro;
                let newKey = dados.keyRegistro + 1;
                this.polinizacaoPomarSelecionado.beneficiamentosPinus.splice(newKey, 0, this.polinizacaoPomarSelecionado.beneficiamentosPinus.splice(oldKey, 1)[0]);
                this.scopeApply();
            }
        }
        if (dados.tipoPolinizacao === TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.BENEFICIAMENTO_EUCALYPTUS) {
            if ((typeof this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus === 'undefined') ||
                (this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus === null)) {
                this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus = new Array();
            }
            if ((typeof dados.keyRegistro !== 'undefined') && (dados.keyRegistro !== null) && (dados.keyRegistro >= 0) &&
                (this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus.length > 0) &&
                (dados.keyRegistro < this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus.length - 1)) {
                let oldKey = dados.keyRegistro;
                let newKey = dados.keyRegistro + 1;
                this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus.splice(newKey, 0, this.polinizacaoPomarSelecionado.beneficiamentosEucalyptus.splice(oldKey, 1)[0]);
                this.scopeApply();
            }
        }
    }
    totalizarPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.polinizacaoPomarSelecionado !== 'undefined') && this.polinizacaoPomarSelecionado) {
            }
        });
    }
    alterarMaterialGeneticoMasculinoPolinizacaoPomar(planejamento) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!planejamento) {
                return;
            }
            if ((typeof planejamento.materialGeneticoMasculino !== 'undefined') && planejamento.materialGeneticoMasculino) {
                if ((typeof planejamento.materialGeneticoMasculino.especie !== 'undefined') && planejamento.materialGeneticoMasculino.especie &&
                    (typeof planejamento.materialGeneticoMasculino.especie.id !== 'undefined') && planejamento.materialGeneticoMasculino.especie.id) {
                    planejamento.especieMaterialGeneticoMasculino = planejamento.materialGeneticoMasculino.especie;
                }
                else if ((typeof planejamento.materialGeneticoMasculino.especieMae !== 'undefined') && planejamento.materialGeneticoMasculino.especieMae &&
                    (typeof planejamento.materialGeneticoMasculino.especieMae.id !== 'undefined') && planejamento.materialGeneticoMasculino.especieMae.id) {
                    planejamento.especieMaterialGeneticoMasculino = planejamento.materialGeneticoMasculino.especieMae;
                }
                else if ((typeof planejamento.materialGeneticoMasculino.especiePai !== 'undefined') && planejamento.materialGeneticoMasculino.especiePai &&
                    (typeof planejamento.materialGeneticoMasculino.especiePai.id !== 'undefined') && planejamento.materialGeneticoMasculino.especiePai.id) {
                    planejamento.especieMaterialGeneticoMasculino = planejamento.materialGeneticoMasculino.especiePai;
                }
                else if ((typeof planejamento.tipoPlanejamento !== 'undefined') && planejamento.tipoPlanejamento &&
                    (typeof this.especies !== 'undefined') && this.especies && (this.especies.length > 0) &&
                    ((typeof planejamento.especieMaterialGeneticoMasculino === 'undefined') || !planejamento.especieMaterialGeneticoMasculino)) {
                    if (planejamento.tipoPlanejamento === TipoPlanejamentoPolinizacaoPomar_1.TipoPlanejamentoPolinizacaoPomar.PINUS) {
                        if ((typeof this.especies !== 'undefined') && this.especies) {
                            angular.forEach(this.especies, (especie) => {
                                if (!planejamento.especieMaterialGeneticoMasculino && especie && especie.descricao &&
                                    (especie.descricao.toLowerCase().trim() === 'pinus')) {
                                    planejamento.especieMaterialGeneticoMasculino = especie;
                                }
                            });
                            if (!planejamento.especieMaterialGeneticoMasculino) {
                                angular.forEach(this.especies, (especie) => {
                                    if (!planejamento.especieMaterialGeneticoMasculino && especie && especie.descricao &&
                                        (especie.descricao.toLowerCase().indexOf('pinus') !== -1)) {
                                        planejamento.especieMaterialGeneticoMasculino = especie;
                                    }
                                });
                            }
                        }
                    }
                    else if (planejamento.tipoPlanejamento === TipoPlanejamentoPolinizacaoPomar_1.TipoPlanejamentoPolinizacaoPomar.EUCALYPTUS) {
                        angular.forEach(this.especies, (especie) => {
                            if (!planejamento.especieMaterialGeneticoMasculino && especie && especie.descricao) {
                                if ((especie.descricao.toLowerCase().trim() === 'eucalipto') ||
                                    (especie.descricao.toLowerCase().trim() === 'eucalypto') ||
                                    (especie.descricao.toLowerCase().trim() === 'eucalyptus')) {
                                    planejamento.especieMaterialGeneticoMasculino = especie;
                                }
                            }
                        });
                        if (!planejamento.especieMaterialGeneticoMasculino) {
                            angular.forEach(this.especies, (especie) => {
                                if (!planejamento.especieMaterialGeneticoMasculino && especie && especie.descricao &&
                                    (especie.descricao.toLowerCase().indexOf('eucal') !== -1)) {
                                    planejamento.especieMaterialGeneticoMasculino = especie;
                                }
                            });
                        }
                    }
                }
                this.scopeApply();
            }
        });
    }
    alterarPolinizacaoEucalyptusPomar(polinizacaoEucalyptus) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof polinizacaoEucalyptus === 'undefined') || !polinizacaoEucalyptus) {
                return;
            }
            let dadosAtualizados = false;
            let materialGeneticoFeminino = null;
            if ((typeof this.polinizacaoPomarSelecionado === 'undefined') || !this.polinizacaoPomarSelecionado ||
                (typeof this.polinizacaoPomarSelecionado.id === 'undefined') || !this.polinizacaoPomarSelecionado.id ||
                (this.polinizacaoPomarSelecionado.id === 'NOVO')) {
                if ((typeof this.registroPolinizacaoPomarSelecionado !== 'undefined') && this.registroPolinizacaoPomarSelecionado &&
                    (typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento !== 'undefined') && this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento &&
                    (typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.materialGeneticoFeminino !== 'undefined') &&
                    this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.materialGeneticoFeminino) {
                    materialGeneticoFeminino = this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.materialGeneticoFeminino;
                }
                else if ((typeof this.registroPolinizacaoPomarSelecionado !== 'undefined') && this.registroPolinizacaoPomarSelecionado &&
                    (typeof this.registroPolinizacaoPomarSelecionado.materialGeneticoFeminino !== 'undefined') &&
                    this.registroPolinizacaoPomarSelecionado.materialGeneticoFeminino) {
                    materialGeneticoFeminino = this.registroPolinizacaoPomarSelecionado.materialGeneticoFeminino;
                }
            }
            if (!materialGeneticoFeminino) {
                if ((typeof this.polinizacaoPomarSelecionado.materialGeneticoFeminino !== 'undefined') && this.polinizacaoPomarSelecionado.materialGeneticoFeminino &&
                    (typeof this.polinizacaoPomarSelecionado.materialGeneticoFeminino.id !== 'undefined') && this.polinizacaoPomarSelecionado.materialGeneticoFeminino.id) {
                    materialGeneticoFeminino = this.polinizacaoPomarSelecionado.materialGeneticoFeminino;
                }
            }
            const materialGeneticoFemininoDescricao = materialGeneticoFeminino &&
                (typeof materialGeneticoFeminino.descricao !== 'undefined') && materialGeneticoFeminino.descricao
                ? materialGeneticoFeminino.descricao.trim() : '';
            const materialGeneticoFemininoGenero = materialGeneticoFeminino &&
                (typeof materialGeneticoFeminino.genero !== 'undefined') && materialGeneticoFeminino.genero &&
                (typeof materialGeneticoFeminino.genero.descricao !== 'undefined') && materialGeneticoFeminino.genero.descricao
                ? materialGeneticoFeminino.genero.descricao.trim() : '';
            const materialGeneticoFemininoEspecie = materialGeneticoFeminino &&
                (typeof materialGeneticoFeminino.especie !== 'undefined') && materialGeneticoFeminino.especie &&
                (typeof materialGeneticoFeminino.especie.descricao !== 'undefined') && materialGeneticoFeminino.especie.descricao
                ? materialGeneticoFeminino.especie.descricao.trim() : '';
            let materialGeneticoMasculino = null;
            if ((typeof polinizacaoEucalyptus.materialGeneticoGenitor !== 'undefined') && polinizacaoEucalyptus.materialGeneticoGenitor &&
                (typeof polinizacaoEucalyptus.materialGeneticoGenitor.id !== 'undefined') && polinizacaoEucalyptus.materialGeneticoGenitor.id) {
                materialGeneticoMasculino = polinizacaoEucalyptus.materialGeneticoGenitor;
            }
            if (!materialGeneticoMasculino) {
                if ((typeof this.polinizacaoPomarSelecionado === 'undefined') || !this.polinizacaoPomarSelecionado ||
                    (typeof this.polinizacaoPomarSelecionado.id === 'undefined') || !this.polinizacaoPomarSelecionado.id ||
                    (this.polinizacaoPomarSelecionado.id === 'NOVO')) {
                    if ((typeof this.registroPolinizacaoPomarSelecionado !== 'undefined') && this.registroPolinizacaoPomarSelecionado &&
                        (typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento !== 'undefined') && this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento &&
                        (typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.materialGeneticoMasculino !== 'undefined') &&
                        this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.materialGeneticoMasculino) {
                        materialGeneticoMasculino = this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.materialGeneticoMasculino;
                    }
                    else if ((typeof this.registroPolinizacaoPomarSelecionado !== 'undefined') && this.registroPolinizacaoPomarSelecionado &&
                        (typeof this.registroPolinizacaoPomarSelecionado.materialGeneticoMasculino !== 'undefined') &&
                        this.registroPolinizacaoPomarSelecionado.materialGeneticoMasculino) {
                        materialGeneticoMasculino = this.registroPolinizacaoPomarSelecionado.materialGeneticoMasculino;
                    }
                }
            }
            if (!materialGeneticoMasculino) {
                if ((typeof this.polinizacaoPomarSelecionado.materialGeneticoMasculino !== 'undefined') && this.polinizacaoPomarSelecionado.materialGeneticoMasculino &&
                    (typeof this.polinizacaoPomarSelecionado.materialGeneticoMasculino.id !== 'undefined') && this.polinizacaoPomarSelecionado.materialGeneticoMasculino.id) {
                    materialGeneticoMasculino = this.polinizacaoPomarSelecionado.materialGeneticoMasculino;
                }
            }
            const materialGeneticoMasculinoDescricao = materialGeneticoMasculino &&
                (typeof materialGeneticoMasculino.descricao !== 'undefined') && materialGeneticoMasculino.descricao
                ? materialGeneticoMasculino.descricao.trim() : '';
            const materialGeneticoMasculinoGenero = materialGeneticoMasculino &&
                (typeof materialGeneticoMasculino.genero !== 'undefined') && materialGeneticoMasculino.genero &&
                (typeof materialGeneticoMasculino.genero.descricao !== 'undefined') && materialGeneticoMasculino.genero.descricao
                ? materialGeneticoMasculino.genero.descricao.trim() : '';
            const materialGeneticoMasculinoEspecie = materialGeneticoMasculino &&
                (typeof materialGeneticoMasculino.especie !== 'undefined') && materialGeneticoMasculino.especie &&
                (typeof materialGeneticoMasculino.especie.descricao !== 'undefined') && materialGeneticoMasculino.especie.descricao
                ? materialGeneticoMasculino.especie.descricao.trim() : '';
            let hibrido = ((materialGeneticoFemininoGenero && (materialGeneticoFemininoGenero.length > 0)) ? materialGeneticoFemininoGenero
                : ((materialGeneticoFemininoEspecie && (materialGeneticoFemininoEspecie.length > 0)) ? materialGeneticoFemininoEspecie : '')) +
                ' x ' + ((materialGeneticoMasculinoGenero && (materialGeneticoMasculinoGenero.length > 0)) ? materialGeneticoMasculinoGenero
                : ((materialGeneticoMasculinoEspecie && (materialGeneticoMasculinoEspecie.length > 0)) ? materialGeneticoMasculinoEspecie : ''));
            if ((typeof polinizacaoEucalyptus.hibrido !== 'undefined') && polinizacaoEucalyptus.hibrido) {
                if (polinizacaoEucalyptus.hibrido !== hibrido) {
                    polinizacaoEucalyptus.hibrido = hibrido;
                    dadosAtualizados = true;
                }
            }
            else if (hibrido && (hibrido.length > 0)) {
                polinizacaoEucalyptus.hibrido = hibrido;
                dadosAtualizados = true;
            }
            let cruzamento = ((materialGeneticoFemininoDescricao && (materialGeneticoFemininoDescricao.length > 0)) ? materialGeneticoFemininoDescricao : '') +
                ' x ' + ((materialGeneticoMasculinoDescricao && (materialGeneticoMasculinoDescricao.length > 0)) ? materialGeneticoMasculinoDescricao : '');
            if ((typeof polinizacaoEucalyptus.cruzamento !== 'undefined') && polinizacaoEucalyptus.cruzamento) {
                if (polinizacaoEucalyptus.cruzamento !== cruzamento) {
                    polinizacaoEucalyptus.cruzamento = cruzamento;
                    dadosAtualizados = true;
                }
            }
            else if (cruzamento && (cruzamento.length > 0)) {
                polinizacaoEucalyptus.cruzamento = cruzamento;
                dadosAtualizados = true;
            }
            if (dadosAtualizados) {
                this.scopeApply();
            }
        });
    }
    alterarNumSementesPolinizacaoPomar(beneficiamento) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof beneficiamento === 'undefined') || !beneficiamento) {
                return;
            }
            if ((typeof beneficiamento.numSementesBoas !== 'undefined') && beneficiamento.numSementesBoas) {
                const sementesPorCone = Math.round((34.47 / 2.22) * 10000) / 10000;
                let numCones = null;
                if ((typeof beneficiamento.numSementesTotal !== 'undefined') && beneficiamento.numSementesTotal) {
                    numCones = Math.round((beneficiamento.numSementesTotal / sementesPorCone) * 100) / 100;
                }
                else {
                    numCones = Math.round((beneficiamento.numSementesBoas / sementesPorCone) * 100) / 100;
                }
                let sementesCone = Math.round(beneficiamento.numSementesBoas / numCones);
                if (beneficiamento.numSementesCone !== sementesCone) {
                    beneficiamento.numSementesCone = sementesCone;
                    this.scopeApply();
                }
            }
            else if ((typeof beneficiamento.numSementesCone !== 'undefined') && beneficiamento.numSementesCone) {
                beneficiamento.numSementesCone = null;
                this.scopeApply();
            }
        });
    }
    selecionarEstrobiloMasculinoMonitoramentoFloracaoPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.estrobiloMonitoramentoFloracaoPolinizacaoPomar !== 'undefined') && this.estrobiloMonitoramentoFloracaoPolinizacaoPomar) {
                if (this.estrobiloMonitoramentoFloracaoPolinizacaoPomar !== this.estrobiloMasculino) {
                    yield this.cancelarMonitoramentoFloracaoPolinizacaoPomar();
                    this.estrobiloMonitoramentoFloracaoPolinizacaoPomar = this.estrobiloMasculino;
                    this.scopeApply();
                }
            }
            else {
                yield this.cancelarMonitoramentoFloracaoPolinizacaoPomar();
                this.estrobiloMonitoramentoFloracaoPolinizacaoPomar = this.estrobiloMasculino;
                this.scopeApply();
            }
        });
    }
    selecionarEstrobiloFemininoMonitoramentoFloracaoPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.estrobiloMonitoramentoFloracaoPolinizacaoPomar !== 'undefined') && this.estrobiloMonitoramentoFloracaoPolinizacaoPomar) {
                if (this.estrobiloMonitoramentoFloracaoPolinizacaoPomar !== this.estrobiloFeminino) {
                    yield this.cancelarMonitoramentoFloracaoPolinizacaoPomar();
                    this.estrobiloMonitoramentoFloracaoPolinizacaoPomar = this.estrobiloFeminino;
                    this.scopeApply();
                }
            }
            else {
                yield this.cancelarMonitoramentoFloracaoPolinizacaoPomar();
                this.estrobiloMonitoramentoFloracaoPolinizacaoPomar = this.estrobiloFeminino;
                this.scopeApply();
            }
        });
    }
    adicionarMonitoramentoFloracaoPolinizacaoPomar(dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                if ((typeof this.pomarSelecionado === 'undefined') || !this.pomarSelecionado ||
                    (typeof this.estrobiloMonitoramentoFloracaoPolinizacaoPomar === 'undefined') || !this.estrobiloMonitoramentoFloracaoPolinizacaoPomar) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-dialog > .modal-content');
                this.registroPolinizacaoPomarSelecionado = new PolinizacaoPomar_1.PolinizacaoPomar();
                this.registroPolinizacaoPomarSelecionado.id = 'NOVO';
                this.registroPolinizacaoPomarSelecionado.status = true;
                this.registroPolinizacaoPomarSelecionado.criacao = new Date();
                this.registroPolinizacaoPomarSelecionado.criacao.setMilliseconds(0);
                this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao = new MonitoramentoFloracaoPolinizacaoPomar_1.MonitoramentoFloracaoPolinizacaoPomar();
                this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao.status = true;
                this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao.criacao = new Date();
                this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao.criacao.setMilliseconds(0);
                this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao.estrobilo = this.estrobiloMonitoramentoFloracaoPolinizacaoPomar;
                if ((typeof this.polinizacaoPomarSelecionadoTabela !== 'undefined') && this.polinizacaoPomarSelecionadoTabela &&
                    (typeof this.polinizacaoPomarSelecionadoTabela.id !== 'undefined') && this.polinizacaoPomarSelecionadoTabela.id &&
                    (typeof this.planejamentosCruzamentosPolinizacaoPomar !== 'undefined') && this.planejamentosCruzamentosPolinizacaoPomar) {
                    let planejamentoCruzamento = this.planejamentosCruzamentosPolinizacaoPomar.find(o => (o.id === ctrl.polinizacaoPomarSelecionadoTabela.id) || (o.planejamentosPolinizacaoPomar.indexOf(ctrl.polinizacaoPomarSelecionadoTabela.id) !== -1));
                    if ((typeof planejamentoCruzamento !== 'undefined') && planejamentoCruzamento) {
                        this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento = planejamentoCruzamento;
                        yield this.alterarPlanejamentoCruzamentoMonitoramentoFloracaoPolinizacaoPomar();
                    }
                }
                let ehEstrobiloFeminino = false;
                let ehEstrobiloMasculino = false;
                if ((typeof this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao.estrobilo !== 'undefined') && this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao.estrobilo &&
                    (typeof this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao.estrobilo.id !== 'undefined') && this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao.estrobilo.id) {
                    if (this.estrobiloFeminino && this.estrobiloFeminino.id && (this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao.estrobilo.id === this.estrobiloFeminino.id)) {
                        ehEstrobiloFeminino = true;
                    }
                    else if (this.estrobiloMasculino && this.estrobiloMasculino.id && (this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao.estrobilo.id === this.estrobiloMasculino.id)) {
                        ehEstrobiloMasculino = true;
                    }
                    else if (this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao.estrobilo.id === Estrobilo_1.Estrobilo.FEMININO) {
                        ehEstrobiloFeminino = true;
                    }
                    else if (this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao.estrobilo.id === Estrobilo_1.Estrobilo.MASCULINO) {
                        ehEstrobiloMasculino = true;
                    }
                }
                if (!ehEstrobiloFeminino && !ehEstrobiloMasculino) {
                    if ((typeof this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao.materialGeneticoMasculino !== 'undefined') && this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao.materialGeneticoMasculino &&
                        (typeof this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao.materialGeneticoMasculino.id !== 'undefined') && this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao.materialGeneticoMasculino.id) {
                        ehEstrobiloMasculino = true;
                    }
                }
                if (!ehEstrobiloFeminino && !ehEstrobiloMasculino) {
                    ehEstrobiloFeminino = true;
                }
                if (ehEstrobiloMasculino) {
                    this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao.dataColeta = new Date();
                }
                else if (ehEstrobiloFeminino) {
                    this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao.dataIsolamento = new Date();
                }
                if ((typeof dados !== 'undefined') && dados && (typeof dados.materialGeneticoCroqui !== 'undefined') && dados.materialGeneticoCroqui) {
                    if ((typeof dados.materialGeneticoCroqui.materialGenetico !== 'undefined') && (dados.materialGeneticoCroqui.materialGenetico !== null)) {
                        let referenciaMaterialGeneticoCroqui = TextoUtil_1.TextoUtil.converteTextoParaReferencia(dados.materialGeneticoCroqui.materialGenetico);
                        if (referenciaMaterialGeneticoCroqui && (referenciaMaterialGeneticoCroqui.length > 0)) {
                            let materialGeneticoCroqui = null;
                            if ((typeof this.materiaisGeneticosFiltrados !== 'undefined') && this.materiaisGeneticosFiltrados && (this.materiaisGeneticosFiltrados.length > 0)) {
                                angular.forEach(this.materiaisGeneticosFiltrados, (materialGenetico) => {
                                    if (!materialGeneticoCroqui && materialGenetico.descricao && (TextoUtil_1.TextoUtil.converteTextoParaReferencia(materialGenetico.descricao) === referenciaMaterialGeneticoCroqui)) {
                                        materialGeneticoCroqui = materialGenetico;
                                    }
                                });
                            }
                            else if ((typeof this.materiaisGeneticos !== 'undefined') && this.materiaisGeneticos && (this.materiaisGeneticos.length > 0)) {
                                angular.forEach(this.materiaisGeneticos, (materialGenetico) => {
                                    if (!materialGeneticoCroqui && materialGenetico.descricao && (TextoUtil_1.TextoUtil.converteTextoParaReferencia(materialGenetico.descricao) === referenciaMaterialGeneticoCroqui)) {
                                        materialGeneticoCroqui = materialGenetico;
                                    }
                                });
                            }
                            if ((typeof materialGeneticoCroqui !== 'undefined') && materialGeneticoCroqui &&
                                (typeof materialGeneticoCroqui.id !== 'undefined') && materialGeneticoCroqui.id) {
                                this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao.materialGeneticoMasculino = materialGeneticoCroqui;
                                let alterouPlanejamentoCruzamento = false;
                                if ((typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento !== 'undefined') && this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento &&
                                    (typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.materialGeneticoFeminino !== 'undefined') && this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.materialGeneticoFeminino &&
                                    (typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.materialGeneticoFeminino.descricao !== 'undefined') && this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.materialGeneticoFeminino.descricao) {
                                    if (TextoUtil_1.TextoUtil.converteTextoParaReferencia(this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.materialGeneticoFeminino.descricao) !== referenciaMaterialGeneticoCroqui) {
                                        this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento = null;
                                        alterouPlanejamentoCruzamento = true;
                                    }
                                }
                                if ((typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento === 'undefined') || !this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento) {
                                    if ((typeof this.planejamentosCruzamentosPolinizacaoPomar !== 'undefined') && this.planejamentosCruzamentosPolinizacaoPomar) {
                                        let planejamentoCruzamento = this.planejamentosCruzamentosPolinizacaoPomar.find(o => o.materialGeneticoFeminino && o.materialGeneticoFeminino.descricao &&
                                            (TextoUtil_1.TextoUtil.converteTextoParaReferencia(o.materialGeneticoFeminino.descricao) === referenciaMaterialGeneticoCroqui));
                                        if ((typeof planejamentoCruzamento !== 'undefined') && planejamentoCruzamento) {
                                            this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento = planejamentoCruzamento;
                                            alterouPlanejamentoCruzamento = true;
                                        }
                                    }
                                }
                                if ((typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento === 'undefined') || !this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento) {
                                    if ((typeof this.planejamentosCruzamentosPolinizacaoPomar !== 'undefined') && this.planejamentosCruzamentosPolinizacaoPomar) {
                                        let descricaoPolinizacaoPomar = '';
                                        if ((typeof this.pomarSelecionado !== 'undefined') && this.pomarSelecionado) {
                                            if ((typeof this.pomarSelecionado.generoDescricao !== 'undefined') && this.pomarSelecionado.generoDescricao &&
                                                (this.pomarSelecionado.generoDescricao.trim().length > 0)) {
                                                descricaoPolinizacaoPomar += this.pomarSelecionado.generoDescricao;
                                            }
                                            else if ((typeof this.pomarSelecionado.genero !== 'undefined') && this.pomarSelecionado.genero &&
                                                (typeof this.pomarSelecionado.genero.descricao !== 'undefined') && this.pomarSelecionado.genero.descricao &&
                                                (this.pomarSelecionado.genero.descricao.trim().length > 0)) {
                                                descricaoPolinizacaoPomar += this.pomarSelecionado.genero.descricao;
                                            }
                                            else if ((typeof this.pomarSelecionado.especieDescricao !== 'undefined') && this.pomarSelecionado.especieDescricao &&
                                                (this.pomarSelecionado.especieDescricao.trim().length > 0)) {
                                                descricaoPolinizacaoPomar += this.pomarSelecionado.especieDescricao;
                                            }
                                            else if ((typeof this.pomarSelecionado.especie !== 'undefined') && this.pomarSelecionado.especie &&
                                                (typeof this.pomarSelecionado.especie.descricao !== 'undefined') && this.pomarSelecionado.especie.descricao &&
                                                (this.pomarSelecionado.especie.descricao.trim().length > 0)) {
                                                descricaoPolinizacaoPomar += this.pomarSelecionado.especie.descricao;
                                            }
                                        }
                                        if ((typeof materialGeneticoCroqui !== 'undefined') && materialGeneticoCroqui &&
                                            (typeof materialGeneticoCroqui.descricao !== 'undefined') && materialGeneticoCroqui.descricao) {
                                            descricaoPolinizacaoPomar += (descricaoPolinizacaoPomar && (descricaoPolinizacaoPomar.length > 0) ? ' - ' : '') +
                                                materialGeneticoCroqui.descricao;
                                        }
                                        if ((typeof this.pomarSelecionado !== 'undefined') && this.pomarSelecionado &&
                                            (typeof this.pomarSelecionado.descricao !== 'undefined') && this.pomarSelecionado.descricao) {
                                            descricaoPolinizacaoPomar += (descricaoPolinizacaoPomar && (descricaoPolinizacaoPomar.length > 0) ? '  ' : '') +
                                                '(' + this.pomarSelecionado.descricao + ')';
                                        }
                                        let polinizacaoPomarSimplificado = new PolinizacaoPomarSimplificado_1.PolinizacaoPomarSimplificado();
                                        polinizacaoPomarSimplificado.id = 'NOVO';
                                        polinizacaoPomarSimplificado.status = true;
                                        polinizacaoPomarSimplificado.criacao = new Date();
                                        polinizacaoPomarSimplificado.data = new Date();
                                        polinizacaoPomarSimplificado.descricao = descricaoPolinizacaoPomar;
                                        polinizacaoPomarSimplificado.pomar = (typeof this.pomarSelecionado !== 'undefined') ? this.pomarSelecionado : null;
                                        polinizacaoPomarSimplificado.materialGeneticoFeminino = (typeof materialGeneticoCroqui !== 'undefined') ? materialGeneticoCroqui : null;
                                        polinizacaoPomarSimplificado.pomarOrigemPolen = (typeof this.pomarSelecionado !== 'undefined') ? this.pomarSelecionado : null;
                                        polinizacaoPomarSimplificado.pomarOrigemPolenCodigo = (typeof this.pomarSelecionado !== 'undefined') && this.pomarSelecionado && (typeof this.pomarSelecionado.codigo !== 'undefined') ? this.pomarSelecionado.codigo : null;
                                        polinizacaoPomarSimplificado.pomarOrigemPolenDescricao = (typeof this.pomarSelecionado !== 'undefined') && this.pomarSelecionado && (typeof this.pomarSelecionado.descricao !== 'undefined') ? this.pomarSelecionado.descricao : null;
                                        polinizacaoPomarSimplificado.especieMaterialGeneticoMasculino = (typeof this.pomarSelecionado !== 'undefined') && this.pomarSelecionado && (typeof this.pomarSelecionado.especie !== 'undefined') ? this.pomarSelecionado.especie : null;
                                        this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento = polinizacaoPomarSimplificado;
                                        alterouPlanejamentoCruzamento = true;
                                    }
                                }
                                if (alterouPlanejamentoCruzamento) {
                                    yield this.alterarPlanejamentoCruzamentoMonitoramentoFloracaoPolinizacaoPomar();
                                }
                            }
                        }
                    }
                    if ((typeof dados.materialGeneticoCroqui.fila !== 'undefined') && (dados.materialGeneticoCroqui.fila !== null)) {
                        if ((typeof this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao.fila === 'undefined') || !this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao.fila) {
                            this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao.fila = dados.materialGeneticoCroqui.fila;
                        }
                    }
                    if ((typeof dados.materialGeneticoCroqui.coluna !== 'undefined') && (dados.materialGeneticoCroqui.coluna !== null)) {
                        if ((typeof this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao.coluna === 'undefined') || !this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao.coluna) {
                            this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao.coluna = dados.materialGeneticoCroqui.coluna;
                        }
                    }
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    editarMonitoramentoFloracaoPolinizacaoPomar(id, indiceMonitoramentoFloracao) {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                if ((typeof this.pomarSelecionado === 'undefined') || !this.pomarSelecionado ||
                    (typeof this.estrobiloMonitoramentoFloracaoPolinizacaoPomar === 'undefined') || !this.estrobiloMonitoramentoFloracaoPolinizacaoPomar) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-dialog > .modal-content');
                this.registroPolinizacaoPomarSelecionado = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POLINIZACAO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POLINIZACAO_POMAR)
                    .id(id)
                    .ver();
                if ((typeof this.registroPolinizacaoPomarSelecionado.id === 'undefined') || !this.registroPolinizacaoPomarSelecionado.id) {
                    this.registroPolinizacaoPomarSelecionado.id = id;
                }
                if ((typeof this.registroPolinizacaoPomarSelecionado.monitoramentosFloracao === 'undefined') || !this.registroPolinizacaoPomarSelecionado.monitoramentosFloracao) {
                    this.registroPolinizacaoPomarSelecionado.monitoramentosFloracao = new Array();
                }
                if ((typeof indiceMonitoramentoFloracao !== 'undefined') && (indiceMonitoramentoFloracao !== null) &&
                    (indiceMonitoramentoFloracao !== -1) && (indiceMonitoramentoFloracao < this.registroPolinizacaoPomarSelecionado.monitoramentosFloracao.length)) {
                    this.indiceMonitoramentoFloracaoPolinizacaoPomarSelecionado = indiceMonitoramentoFloracao;
                    this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao = this.registroPolinizacaoPomarSelecionado.monitoramentosFloracao[indiceMonitoramentoFloracao];
                }
                else {
                    let monitoramentoFloracao = new MonitoramentoFloracaoPolinizacaoPomar_1.MonitoramentoFloracaoPolinizacaoPomar();
                    monitoramentoFloracao.status = true;
                    monitoramentoFloracao.criacao = new Date();
                    monitoramentoFloracao.criacao.setMilliseconds(0);
                    monitoramentoFloracao.estrobilo = this.estrobiloMonitoramentoFloracaoPolinizacaoPomar;
                    this.registroPolinizacaoPomarSelecionado.monitoramentosFloracao.push(monitoramentoFloracao);
                    this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao = monitoramentoFloracao;
                    this.indiceMonitoramentoFloracaoPolinizacaoPomarSelecionado = this.registroPolinizacaoPomarSelecionado.monitoramentosFloracao.length - 1;
                }
                if ((typeof this.planejamentosCruzamentosPolinizacaoPomar !== 'undefined') && this.planejamentosCruzamentosPolinizacaoPomar) {
                    let planejamentoCruzamento = this.planejamentosCruzamentosPolinizacaoPomar.find(o => (o.id === ctrl.registroPolinizacaoPomarSelecionado.id) || (o.planejamentosPolinizacaoPomar.indexOf(ctrl.registroPolinizacaoPomarSelecionado.id) !== -1));
                    if ((typeof planejamentoCruzamento !== 'undefined') && planejamentoCruzamento) {
                        this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento = planejamentoCruzamento;
                    }
                }
                if ((typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento !== 'undefined') && this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento &&
                    (typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id !== 'undefined') && this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id) {
                    this.registroPolinizacaoPomarSelecionado.planejamentoCruzamentoAnterior = this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id;
                }
                if ((typeof this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao !== 'undefined') && this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao) {
                    if (typeof this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao.criacao === 'string') {
                        this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao.criacao = new Date(this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao.criacao);
                    }
                    if (typeof this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao.dataColeta === 'string') {
                        this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao.dataColeta = new Date(this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao.dataColeta);
                    }
                    if (typeof this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao.dataIsolamento === 'string') {
                        this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao.dataIsolamento = new Date(this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao.dataIsolamento);
                    }
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    salvarMonitoramentoFloracaoPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof this.pomarSelecionado === 'undefined') || !this.pomarSelecionado ||
                    (typeof this.estrobiloMonitoramentoFloracaoPolinizacaoPomar === 'undefined') || !this.estrobiloMonitoramentoFloracaoPolinizacaoPomar ||
                    (typeof this.registroPolinizacaoPomarSelecionado === 'undefined') || !this.registroPolinizacaoPomarSelecionado) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-dialog > .modal-content');
                if ((typeof this.registroPolinizacaoPomarSelecionado.id !== 'undefined') && this.registroPolinizacaoPomarSelecionado.id &&
                    (this.registroPolinizacaoPomarSelecionado.id !== 'NOVO')) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.POLINIZACAO_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POLINIZACAO_POMAR)
                        .atualizar(this.registroPolinizacaoPomarSelecionado);
                }
                else {
                    if ((typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento === 'undefined') ||
                        !this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento ||
                        (typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id === 'undefined') ||
                        !this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id) {
                        if (this.ehCelular) {
                            alert(`Preencha o Planejamento/Cruzamento! Campo necessário para salvar o monitoramento de floração.`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                title: 'Preencha o Planejamento/Cruzamento!',
                                text: 'Campo \'Planejamento/Cruzamento\' é necessário para salvar o monitoramento de floração.',
                                icon: 'info',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                    if ((this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id === 'NOVO') &&
                        (typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.materialGeneticoFeminino !== 'undefined') &&
                        this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.materialGeneticoFeminino) {
                        let novaPolinizacaoPomar = angular.copy(this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento);
                        novaPolinizacaoPomar.id = null;
                        if ((typeof novaPolinizacaoPomar.tipoPlanejamento === 'undefined') || !novaPolinizacaoPomar.tipoPlanejamento) {
                            let generosEspeciesMaterialGenetico = Array();
                            if ((typeof novaPolinizacaoPomar.pomar !== 'undefined') && novaPolinizacaoPomar.pomar &&
                                (typeof novaPolinizacaoPomar.pomar.genero !== 'undefined') && novaPolinizacaoPomar.pomar.genero &&
                                (typeof novaPolinizacaoPomar.pomar.genero.descricao !== 'undefined') && novaPolinizacaoPomar.pomar.genero.descricao) {
                                generosEspeciesMaterialGenetico.push(novaPolinizacaoPomar.pomar.genero.descricao);
                            }
                            if ((typeof novaPolinizacaoPomar.pomar !== 'undefined') && novaPolinizacaoPomar.pomar &&
                                (typeof novaPolinizacaoPomar.pomar.generoDescricao !== 'undefined') && novaPolinizacaoPomar.pomar.generoDescricao) {
                                generosEspeciesMaterialGenetico.push(novaPolinizacaoPomar.pomar.generoDescricao);
                            }
                            if ((typeof novaPolinizacaoPomar.pomar !== 'undefined') && novaPolinizacaoPomar.pomar &&
                                (typeof novaPolinizacaoPomar.pomar.especie !== 'undefined') && novaPolinizacaoPomar.pomar.especie &&
                                (typeof novaPolinizacaoPomar.pomar.especie.descricao !== 'undefined') && novaPolinizacaoPomar.pomar.especie.descricao) {
                                generosEspeciesMaterialGenetico.push(novaPolinizacaoPomar.pomar.especie.descricao);
                            }
                            if ((typeof novaPolinizacaoPomar.pomar !== 'undefined') && novaPolinizacaoPomar.pomar &&
                                (typeof novaPolinizacaoPomar.pomar.especieDescricao !== 'undefined') && novaPolinizacaoPomar.pomar.especieDescricao) {
                                generosEspeciesMaterialGenetico.push(novaPolinizacaoPomar.pomar.especieDescricao);
                            }
                            if ((typeof novaPolinizacaoPomar.especieMaterialGeneticoMasculino !== 'undefined') && novaPolinizacaoPomar.especieMaterialGeneticoMasculino &&
                                (typeof novaPolinizacaoPomar.especieMaterialGeneticoMasculino.descricao !== 'undefined') && novaPolinizacaoPomar.especieMaterialGeneticoMasculino.descricao) {
                                generosEspeciesMaterialGenetico.push(novaPolinizacaoPomar.especieMaterialGeneticoMasculino.descricao);
                            }
                            let tipoPlanejamento = null;
                            angular.forEach(generosEspeciesMaterialGenetico, (generoEspecieMaterialGenetico) => {
                                if (!tipoPlanejamento && (typeof generoEspecieMaterialGenetico !== 'undefined') && generoEspecieMaterialGenetico) {
                                    if (generoEspecieMaterialGenetico.toLowerCase().indexOf('pinus') !== -1) {
                                        tipoPlanejamento = TipoPlanejamentoPolinizacaoPomar_1.TipoPlanejamentoPolinizacaoPomar.PINUS;
                                    }
                                    else if (generoEspecieMaterialGenetico.toLowerCase().indexOf('euc') !== -1) {
                                        tipoPlanejamento = TipoPlanejamentoPolinizacaoPomar_1.TipoPlanejamentoPolinizacaoPomar.EUCALYPTUS;
                                    }
                                }
                            });
                            novaPolinizacaoPomar.tipoPlanejamento = tipoPlanejamento;
                        }
                        novaPolinizacaoPomar = yield this.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.POLINIZACAO_POMAR)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POLINIZACAO_POMAR)
                            .criar(novaPolinizacaoPomar);
                        if ((typeof novaPolinizacaoPomar !== 'undefined') && novaPolinizacaoPomar &&
                            (typeof novaPolinizacaoPomar.id !== 'undefined') && novaPolinizacaoPomar.id) {
                            this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id = novaPolinizacaoPomar.id;
                            this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.planejamentosPolinizacaoPomar = [novaPolinizacaoPomar.id];
                        }
                    }
                    let polinizacaoPomar = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.POLINIZACAO_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POLINIZACAO_POMAR)
                        .id(this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id)
                        .ver();
                    if (!polinizacaoPomar) {
                        if (this.ehCelular) {
                            alert(`Registro da polinização pomar (id: ${this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id}) não encontrado.`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                title: 'Registro da polinização pomar não encontrado !',
                                text: `Registro da polinização pomar (id: ${this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id}) não encontrado.`,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                    if ((typeof polinizacaoPomar.monitoramentosFloracao === 'undefined') || !polinizacaoPomar.monitoramentosFloracao) {
                        polinizacaoPomar.monitoramentosFloracao = new Array();
                    }
                    if ((typeof this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao !== 'undefined') &&
                        this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao) {
                        polinizacaoPomar.monitoramentosFloracao.push(this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao);
                    }
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.POLINIZACAO_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POLINIZACAO_POMAR)
                        .atualizar(polinizacaoPomar);
                }
                if ((typeof this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao !== 'undefined') &&
                    this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao) {
                    const monitoramentoFloracao = this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao;
                    let alterouPlanilhaCroqui = false;
                    let ehEstrobiloFeminino = false;
                    let ehEstrobiloMasculino = false;
                    if ((typeof monitoramentoFloracao.estrobilo !== 'undefined') && monitoramentoFloracao.estrobilo &&
                        (typeof monitoramentoFloracao.estrobilo.id !== 'undefined') && monitoramentoFloracao.estrobilo.id) {
                        if (this.estrobiloFeminino && this.estrobiloFeminino.id && (monitoramentoFloracao.estrobilo.id === this.estrobiloFeminino.id)) {
                            ehEstrobiloFeminino = true;
                        }
                        else if (this.estrobiloMasculino && this.estrobiloMasculino.id && (monitoramentoFloracao.estrobilo.id === this.estrobiloMasculino.id)) {
                            ehEstrobiloMasculino = true;
                        }
                        else if (monitoramentoFloracao.estrobilo.id === Estrobilo_1.Estrobilo.FEMININO) {
                            ehEstrobiloFeminino = true;
                        }
                        else if (monitoramentoFloracao.estrobilo.id === Estrobilo_1.Estrobilo.MASCULINO) {
                            ehEstrobiloMasculino = true;
                        }
                    }
                    if (!ehEstrobiloFeminino && !ehEstrobiloMasculino) {
                        if ((typeof monitoramentoFloracao.materialGeneticoMasculino !== 'undefined') && monitoramentoFloracao.materialGeneticoMasculino &&
                            (typeof monitoramentoFloracao.materialGeneticoMasculino.id !== 'undefined') && monitoramentoFloracao.materialGeneticoMasculino.id) {
                            ehEstrobiloMasculino = true;
                        }
                    }
                    if (!ehEstrobiloFeminino && !ehEstrobiloMasculino) {
                        ehEstrobiloFeminino = true;
                    }
                    if (ehEstrobiloFeminino &&
                        (typeof globalThis.luckysheet !== 'undefined') && globalThis.luckysheet &&
                        (typeof monitoramentoFloracao.fila !== 'undefined') && monitoramentoFloracao.fila &&
                        (typeof monitoramentoFloracao.coluna !== 'undefined') && monitoramentoFloracao.coluna) {
                        let planilhaCroquiAnterior = (typeof globalThis.luckysheet.getSheet !== 'undefined') ? globalThis.luckysheet.getSheet() : null;
                        let planilhaCroquiAnteriorIdx = (typeof planilhaCroquiAnterior.order !== 'undefined') ? planilhaCroquiAnterior.order : null;
                        let tituloPlanilhaMonitoramentoFloracao = 'Monitoramento de Floração';
                        let referenciaPlanilhaMonitoramentoFloracao = TextoUtil_1.TextoUtil.converteTextoParaReferencia(tituloPlanilhaMonitoramentoFloracao);
                        let planilhaMonitoramentoFloracao = null;
                        let planilhaMonitoramentoFloracaoIdx = null;
                        let ultimaPlanilhaCroqui = null;
                        let planilhaCroquiOriginalN1 = null;
                        let planilhaCroquiOriginalN2 = null;
                        angular.forEach(globalThis.luckysheet.getAllSheets(), (sheet, key) => {
                            if ((typeof sheet.name !== 'undefined') && sheet.name) {
                                const lcSheetName = String(sheet.name).toLowerCase().trim();
                                if (!ultimaPlanilhaCroqui) {
                                    ultimaPlanilhaCroqui = sheet;
                                }
                                if (!planilhaCroquiOriginalN1 && (lcSheetName.indexOf('croqui') !== -1) && (lcSheetName.indexOf('enxerto') === -1)) {
                                    planilhaCroquiOriginalN1 = sheet;
                                }
                                if (!planilhaCroquiOriginalN2 && (lcSheetName.indexOf('enxerto') === -1)) {
                                    planilhaCroquiOriginalN2 = sheet;
                                }
                                if (!planilhaMonitoramentoFloracao && (TextoUtil_1.TextoUtil.converteTextoParaReferencia(sheet.name) === referenciaPlanilhaMonitoramentoFloracao)) {
                                    planilhaMonitoramentoFloracao = sheet;
                                    planilhaMonitoramentoFloracaoIdx = key;
                                }
                            }
                        });
                        if (planilhaCroquiOriginalN1) {
                            ultimaPlanilhaCroqui = planilhaCroquiOriginalN1;
                        }
                        else if (planilhaCroquiOriginalN2) {
                            ultimaPlanilhaCroqui = planilhaCroquiOriginalN2;
                        }
                        if (!planilhaMonitoramentoFloracao && ultimaPlanilhaCroqui) {
                            globalThis.luckysheet.setSheetAdd({
                                sheetObject: {
                                    name: tituloPlanilhaMonitoramentoFloracao,
                                    data: (ultimaPlanilhaCroqui.data ? angular.copy(ultimaPlanilhaCroqui.data) : []),
                                    config: (ultimaPlanilhaCroqui.config ? angular.copy(ultimaPlanilhaCroqui.config) : null)
                                },
                                order: 0
                            });
                            planilhaMonitoramentoFloracaoIdx = 0;
                            globalThis.luckysheet.setSheetActive(planilhaMonitoramentoFloracaoIdx);
                            angular.forEach(globalThis.luckysheet.getAllSheets(), (sheet) => {
                                if ((typeof sheet.name !== 'undefined') && sheet.name) {
                                    if (!planilhaMonitoramentoFloracao && (TextoUtil_1.TextoUtil.converteTextoParaReferencia(sheet.name) === referenciaPlanilhaMonitoramentoFloracao)) {
                                        planilhaMonitoramentoFloracao = sheet;
                                    }
                                }
                            });
                            alterouPlanilhaCroqui = true;
                        }
                        else if ((typeof planilhaMonitoramentoFloracaoIdx !== 'undefined') && (planilhaMonitoramentoFloracaoIdx !== null)) {
                            globalThis.luckysheet.setSheetActive(planilhaMonitoramentoFloracaoIdx);
                        }
                        let planilhasCroqui = [];
                        if (planilhaMonitoramentoFloracao) {
                            planilhasCroqui.push(planilhaMonitoramentoFloracao);
                        }
                        else {
                            angular.forEach(globalThis.luckysheet.getAllSheets(), (planilha) => {
                                if ((typeof planilha.name !== 'undefined') && planilha.name) {
                                    const lcNomePlanilha = (typeof planilha.name === 'string') ? planilha.name.trim().toLowerCase() : String(planilha.name).trim().toLowerCase();
                                    if ((lcNomePlanilha.indexOf('croqui') !== -1) || (lcNomePlanilha.indexOf('enxerto') !== -1)) {
                                        planilhasCroqui.push(planilha);
                                    }
                                }
                            });
                            if (!planilhasCroqui || (planilhasCroqui.length === 0)) {
                                angular.forEach(globalThis.luckysheet.getAllSheets(), (planilha) => {
                                    planilhasCroqui.push(planilha);
                                });
                            }
                        }
                        angular.forEach(planilhasCroqui, (planilha) => {
                            globalThis.luckysheet.setSheetActive(planilha.order);
                            let posicaoMaterialGeneticoCroqui = LuckysheetUtil_1.LuckysheetUtil.buscaPosicaoMaterialGeneticoCroqui(monitoramentoFloracao.fila, monitoramentoFloracao.coluna);
                            if (posicaoMaterialGeneticoCroqui && (posicaoMaterialGeneticoCroqui.row !== null) && (posicaoMaterialGeneticoCroqui.col !== null)) {
                                if ((typeof planilha.data !== 'undefined') && planilha.data &&
                                    (posicaoMaterialGeneticoCroqui.row < planilha.data.length) &&
                                    (typeof planilha.data[posicaoMaterialGeneticoCroqui.row] !== 'undefined') && planilha.data[posicaoMaterialGeneticoCroqui.row] &&
                                    (posicaoMaterialGeneticoCroqui.col < planilha.data[posicaoMaterialGeneticoCroqui.row].length)) {
                                    let cell = planilha.data[posicaoMaterialGeneticoCroqui.row][posicaoMaterialGeneticoCroqui.col];
                                    if ((typeof cell !== 'undefined') && cell) {
                                        cell.bg = '#ffff00';
                                    }
                                    else {
                                        cell = { ct: { fa: 'General', t: 'n' }, bg: '#ffff00' };
                                    }
                                    globalThis.luckysheet.setCellValue(posicaoMaterialGeneticoCroqui.row, posicaoMaterialGeneticoCroqui.col, cell, { order: planilha.order });
                                    alterouPlanilhaCroqui = true;
                                }
                            }
                        });
                        if ((typeof planilhaCroquiAnteriorIdx !== 'undefined') && (planilhaCroquiAnteriorIdx !== null)) {
                            globalThis.luckysheet.setSheetActive(planilhaCroquiAnteriorIdx);
                        }
                    }
                    if (alterouPlanilhaCroqui) {
                        yield this.salvarCroquiPomar();
                    }
                }
                this.registroPolinizacaoPomarSelecionado = null;
                this.estoquePolenPolinizacaoPomarSelecionado = null;
                this.listaOpcoesPolinizacaoPomar = yield this.dadosListaPolinizacaoPomar();
                this.listaOpcoesMonitoramentoFloracaoMasculinoPolinizacaoPomar = yield this.dadosListaMonitoramentoFloracaoMasculinoPolinizacaoPomar();
                this.listaOpcoesMonitoramentoFloracaoFemininoPolinizacaoPomar = yield this.dadosListaMonitoramentoFloracaoFemininoPolinizacaoPomar();
                this.listaOpcoesEstoquePolenPolinizacaoPomar = yield this.dadosListaEstoquePolenPolinizacaoPomar();
                this.listaOpcoesHistoricoEstoquePolenPolinizacaoPomar = yield this.dadosListaHistoricoEstoquePolenPolinizacaoPomar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    cancelarMonitoramentoFloracaoPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.registroPolinizacaoPomarSelecionado = null;
            this.indiceMonitoramentoFloracaoPolinizacaoPomarSelecionado = null;
        });
    }
    alterarPlanejamentoCruzamentoMonitoramentoFloracaoPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.registroPolinizacaoPomarSelecionado === 'undefined') || !this.registroPolinizacaoPomarSelecionado) {
                return;
            }
            const monitoramentoFloracaoPomar = ((typeof this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao !== 'undefined') &&
                this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao)
                ? this.registroPolinizacaoPomarSelecionado.monitoramentoFloracao : null;
            const planejamentoCruzamento = ((typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento !== 'undefined') &&
                this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento)
                ? this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento : null;
            if (monitoramentoFloracaoPomar && planejamentoCruzamento && planejamentoCruzamento.id) {
                if ((typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamentoAnterior !== 'undefined') &&
                    this.registroPolinizacaoPomarSelecionado.planejamentoCruzamentoAnterior &&
                    (this.registroPolinizacaoPomarSelecionado.planejamentoCruzamentoAnterior === planejamentoCruzamento.id)) {
                    return;
                }
                this.registroPolinizacaoPomarSelecionado.planejamentoCruzamentoAnterior = planejamentoCruzamento.id;
                let ehEstrobiloFeminino = false;
                let ehEstrobiloMasculino = false;
                if ((typeof monitoramentoFloracaoPomar.estrobilo !== 'undefined') && monitoramentoFloracaoPomar.estrobilo &&
                    (typeof monitoramentoFloracaoPomar.estrobilo.id !== 'undefined') && monitoramentoFloracaoPomar.estrobilo.id) {
                    if (this.estrobiloFeminino && this.estrobiloFeminino.id && (monitoramentoFloracaoPomar.estrobilo.id === this.estrobiloFeminino.id)) {
                        ehEstrobiloFeminino = true;
                    }
                    else if (this.estrobiloMasculino && this.estrobiloMasculino.id && (monitoramentoFloracaoPomar.estrobilo.id === this.estrobiloMasculino.id)) {
                        ehEstrobiloMasculino = true;
                    }
                    else if (monitoramentoFloracaoPomar.estrobilo.id === Estrobilo_1.Estrobilo.FEMININO) {
                        ehEstrobiloFeminino = true;
                    }
                    else if (monitoramentoFloracaoPomar.estrobilo.id === Estrobilo_1.Estrobilo.MASCULINO) {
                        ehEstrobiloMasculino = true;
                    }
                }
                if (!ehEstrobiloFeminino && !ehEstrobiloMasculino) {
                    if ((typeof monitoramentoFloracaoPomar.materialGeneticoMasculino !== 'undefined') && monitoramentoFloracaoPomar.materialGeneticoMasculino &&
                        (typeof monitoramentoFloracaoPomar.materialGeneticoMasculino.id !== 'undefined') && monitoramentoFloracaoPomar.materialGeneticoMasculino.id) {
                        ehEstrobiloMasculino = true;
                    }
                }
                if (!ehEstrobiloFeminino && !ehEstrobiloMasculino) {
                    ehEstrobiloFeminino = true;
                }
                if (ehEstrobiloMasculino) {
                    if ((typeof planejamentoCruzamento.pomarOrigemPolen !== 'undefined') && planejamentoCruzamento.pomarOrigemPolen) {
                        monitoramentoFloracaoPomar.pomarOrigem = planejamentoCruzamento.pomarOrigemPolen;
                    }
                    if ((typeof planejamentoCruzamento.materialGeneticoMasculino !== 'undefined') && planejamentoCruzamento.materialGeneticoMasculino) {
                        monitoramentoFloracaoPomar.materialGeneticoMasculino = planejamentoCruzamento.materialGeneticoMasculino;
                    }
                }
                this.scopeApply();
            }
        });
    }
    editarEstoquePolenPolinizacaoPomar(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof this.pomarSelecionado === 'undefined') || !this.pomarSelecionado) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-dialog > .modal-content');
                this.estoquePolenPolinizacaoPomarSelecionado = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESTOQUE_POLEN_POLINIZACAO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESTOQUE_POLEN_POLINIZACAO_POMAR)
                    .id(id)
                    .ver();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    salvarEstoquePolenPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof this.pomarSelecionado === 'undefined') || !this.pomarSelecionado ||
                    (typeof this.estoquePolenPolinizacaoPomarSelecionado === 'undefined') || !this.estoquePolenPolinizacaoPomarSelecionado) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-dialog > .modal-content');
                yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESTOQUE_POLEN_POLINIZACAO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESTOQUE_POLEN_POLINIZACAO_POMAR)
                    .atualizar(this.estoquePolenPolinizacaoPomarSelecionado);
                this.estoquePolenPolinizacaoPomarSelecionado = null;
                this.listaOpcoesMonitoramentoFloracaoMasculinoPolinizacaoPomar = yield this.dadosListaMonitoramentoFloracaoMasculinoPolinizacaoPomar();
                this.listaOpcoesMonitoramentoFloracaoFemininoPolinizacaoPomar = yield this.dadosListaMonitoramentoFloracaoFemininoPolinizacaoPomar();
                this.listaOpcoesEstoquePolenPolinizacaoPomar = yield this.dadosListaEstoquePolenPolinizacaoPomar();
                this.listaOpcoesHistoricoEstoquePolenPolinizacaoPomar = yield this.dadosListaHistoricoEstoquePolenPolinizacaoPomar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    cancelarEstoquePolenPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.estoquePolenPolinizacaoPomarSelecionado = null;
        });
    }
    adicionarLancamentoPinusPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                if ((typeof this.pomarSelecionado === 'undefined') || !this.pomarSelecionado) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-dialog > .modal-content');
                this.registroPolinizacaoPomarSelecionado = new PolinizacaoPomar_1.PolinizacaoPomar();
                this.registroPolinizacaoPomarSelecionado.id = 'NOVO';
                this.registroPolinizacaoPomarSelecionado.status = true;
                this.registroPolinizacaoPomarSelecionado.criacao = new Date();
                this.registroPolinizacaoPomarSelecionado.criacao.setMilliseconds(0);
                this.registroPolinizacaoPomarSelecionado.polinizacao = new LancamentoPolinizacaoPomar_1.LancamentoPolinizacaoPomar();
                this.registroPolinizacaoPomarSelecionado.polinizacao.status = true;
                this.registroPolinizacaoPomarSelecionado.polinizacao.criacao = new Date();
                this.registroPolinizacaoPomarSelecionado.polinizacao.criacao.setMilliseconds(0);
                this.registroPolinizacaoPomarSelecionado.polinizacao.ehPinus = true;
                this.registroPolinizacaoPomarSelecionado.polinizacao.ehEucalyptus = false;
                if ((typeof this.polinizacaoPomarSelecionadoTabela !== 'undefined') && this.polinizacaoPomarSelecionadoTabela &&
                    (typeof this.polinizacaoPomarSelecionadoTabela.id !== 'undefined') && this.polinizacaoPomarSelecionadoTabela.id &&
                    (typeof this.planejamentosCruzamentosPolinizacaoPomar !== 'undefined') && this.planejamentosCruzamentosPolinizacaoPomar) {
                    let planejamentoCruzamento = this.planejamentosCruzamentosPolinizacaoPomar.find(o => (o.id === ctrl.polinizacaoPomarSelecionadoTabela.id) || (o.planejamentosPolinizacaoPomar.indexOf(ctrl.polinizacaoPomarSelecionadoTabela.id) !== -1));
                    if ((typeof planejamentoCruzamento !== 'undefined') && planejamentoCruzamento) {
                        this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento = planejamentoCruzamento;
                        this.alterarPlanejamentoCruzamentoLancamentoPinusPolinizacaoPomar();
                    }
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    editarLancamentoPinusPolinizacaoPomar(id, indicePolinizacao) {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                if ((typeof this.pomarSelecionado === 'undefined') || !this.pomarSelecionado) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-dialog > .modal-content');
                this.registroPolinizacaoPomarSelecionado = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POLINIZACAO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POLINIZACAO_POMAR)
                    .id(id)
                    .ver();
                if ((typeof this.registroPolinizacaoPomarSelecionado.polinizacoes === 'undefined') || !this.registroPolinizacaoPomarSelecionado.polinizacoes) {
                    this.registroPolinizacaoPomarSelecionado.polinizacoes = new Array();
                }
                if ((typeof indicePolinizacao !== 'undefined') && (indicePolinizacao !== null) &&
                    (indicePolinizacao !== -1) && (indicePolinizacao < this.registroPolinizacaoPomarSelecionado.polinizacoes.length)) {
                    this.indiceLancamentoPolinizacaoPomarSelecionado = indicePolinizacao;
                    this.registroPolinizacaoPomarSelecionado.polinizacao = this.registroPolinizacaoPomarSelecionado.polinizacoes[indicePolinizacao];
                }
                else {
                    let lancamentoPolinizacaoPinus = new LancamentoPolinizacaoPomar_1.LancamentoPolinizacaoPomar();
                    lancamentoPolinizacaoPinus.status = true;
                    lancamentoPolinizacaoPinus.criacao = new Date();
                    lancamentoPolinizacaoPinus.criacao.setMilliseconds(0);
                    lancamentoPolinizacaoPinus.ehPinus = true;
                    lancamentoPolinizacaoPinus.ehEucalyptus = false;
                    this.registroPolinizacaoPomarSelecionado.polinizacoes.push(lancamentoPolinizacaoPinus);
                    this.registroPolinizacaoPomarSelecionado.polinizacao = lancamentoPolinizacaoPinus;
                    this.indiceLancamentoPolinizacaoPomarSelecionado = this.registroPolinizacaoPomarSelecionado.polinizacoes.length - 1;
                    this.alterarPolinizacaoEucalyptusPomar(lancamentoPolinizacaoPinus);
                }
                if ((typeof this.planejamentosCruzamentosPolinizacaoPomar !== 'undefined') && this.planejamentosCruzamentosPolinizacaoPomar) {
                    let planejamentoCruzamento = this.planejamentosCruzamentosPolinizacaoPomar.find(o => (o.id === ctrl.registroPolinizacaoPomarSelecionado.id) || (o.planejamentosPolinizacaoPomar.indexOf(ctrl.registroPolinizacaoPomarSelecionado.id) !== -1));
                    if ((typeof planejamentoCruzamento !== 'undefined') && planejamentoCruzamento) {
                        this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento = planejamentoCruzamento;
                    }
                }
                if ((typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento !== 'undefined') && this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento &&
                    (typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id !== 'undefined') && this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id) {
                    this.registroPolinizacaoPomarSelecionado.planejamentoCruzamentoAnterior = this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id;
                }
                if ((typeof this.registroPolinizacaoPomarSelecionado.polinizacao !== 'undefined') && this.registroPolinizacaoPomarSelecionado.polinizacao) {
                    if (typeof this.registroPolinizacaoPomarSelecionado.polinizacao.criacao === 'string') {
                        this.registroPolinizacaoPomarSelecionado.polinizacao.criacao = new Date(this.registroPolinizacaoPomarSelecionado.polinizacao.criacao);
                    }
                    if (typeof this.registroPolinizacaoPomarSelecionado.polinizacao.data === 'string') {
                        this.registroPolinizacaoPomarSelecionado.polinizacao.data = new Date(this.registroPolinizacaoPomarSelecionado.polinizacao.data);
                    }
                    if (typeof this.registroPolinizacaoPomarSelecionado.polinizacao.dataInicial === 'string') {
                        this.registroPolinizacaoPomarSelecionado.polinizacao.dataInicial = new Date(this.registroPolinizacaoPomarSelecionado.polinizacao.dataInicial);
                    }
                    if (typeof this.registroPolinizacaoPomarSelecionado.polinizacao.dataFinal === 'string') {
                        this.registroPolinizacaoPomarSelecionado.polinizacao.dataFinal = new Date(this.registroPolinizacaoPomarSelecionado.polinizacao.dataFinal);
                    }
                    if (typeof this.registroPolinizacaoPomarSelecionado.polinizacao.dataRetiradaPacote === 'string') {
                        this.registroPolinizacaoPomarSelecionado.polinizacao.dataRetiradaPacote = new Date(this.registroPolinizacaoPomarSelecionado.polinizacao.dataRetiradaPacote);
                    }
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    salvarLancamentoPinusPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof this.pomarSelecionado === 'undefined') || !this.pomarSelecionado ||
                    (typeof this.registroPolinizacaoPomarSelecionado === 'undefined') || !this.registroPolinizacaoPomarSelecionado) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-dialog > .modal-content');
                if ((typeof this.registroPolinizacaoPomarSelecionado.id !== 'undefined') && this.registroPolinizacaoPomarSelecionado.id &&
                    (this.registroPolinizacaoPomarSelecionado.id !== 'NOVO')) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.POLINIZACAO_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POLINIZACAO_POMAR)
                        .atualizar(this.registroPolinizacaoPomarSelecionado);
                }
                else {
                    if ((typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento === 'undefined') ||
                        !this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento ||
                        (typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id === 'undefined') ||
                        !this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id) {
                        if (this.ehCelular) {
                            alert(`Preencha o Planejamento/Cruzamento! Campo necessário para salvar o lançamento de polinização Pinus.`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                title: 'Preencha o Planejamento/Cruzamento!',
                                text: 'Campo \'Planejamento/Cruzamento\' é necessário para salvar o lançamento de polinização Pinus.',
                                icon: 'info',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                    let polinizacaoPomar = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.POLINIZACAO_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POLINIZACAO_POMAR)
                        .id(this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id)
                        .ver();
                    if (!polinizacaoPomar) {
                        if (this.ehCelular) {
                            alert(`Registro da polinização pomar (id: ${this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id}) não encontrado.`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                title: 'Registro da polinização pomar não encontrado !',
                                text: `Registro da polinização pomar (id: ${this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id}) não encontrado.`,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                    if ((typeof polinizacaoPomar.polinizacoes === 'undefined') || !polinizacaoPomar.polinizacoes) {
                        polinizacaoPomar.polinizacoes = new Array();
                    }
                    if ((typeof this.registroPolinizacaoPomarSelecionado.polinizacao !== 'undefined') &&
                        this.registroPolinizacaoPomarSelecionado.polinizacao) {
                        polinizacaoPomar.polinizacoes.push(this.registroPolinizacaoPomarSelecionado.polinizacao);
                    }
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.POLINIZACAO_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POLINIZACAO_POMAR)
                        .atualizar(polinizacaoPomar);
                }
                this.registroPolinizacaoPomarSelecionado = null;
                this.indiceLancamentoPolinizacaoPomarSelecionado = null;
                this.listaOpcoesPolinizacaoPomar = yield this.dadosListaPolinizacaoPomar();
                this.listaOpcoesLancamentosPinusPolinizacaoPomar = yield this.dadosListaLancamentosPinusPolinizacaoPomar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    cancelarLancamentoPinusPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.registroPolinizacaoPomarSelecionado = null;
            this.indiceLancamentoPolinizacaoPomarSelecionado = null;
        });
    }
    alterarPlanejamentoCruzamentoLancamentoPinusPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.registroPolinizacaoPomarSelecionado === 'undefined') || !this.registroPolinizacaoPomarSelecionado) {
                return;
            }
            const polinizacaoPomar = ((typeof this.registroPolinizacaoPomarSelecionado.polinizacao !== 'undefined') &&
                this.registroPolinizacaoPomarSelecionado.polinizacao)
                ? this.registroPolinizacaoPomarSelecionado.polinizacao : null;
            const planejamentoCruzamento = ((typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento !== 'undefined') &&
                this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento)
                ? this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento : null;
            if (polinizacaoPomar && planejamentoCruzamento && planejamentoCruzamento.id) {
                if ((typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamentoAnterior !== 'undefined') &&
                    this.registroPolinizacaoPomarSelecionado.planejamentoCruzamentoAnterior &&
                    (this.registroPolinizacaoPomarSelecionado.planejamentoCruzamentoAnterior === planejamentoCruzamento.id)) {
                    return;
                }
                this.registroPolinizacaoPomarSelecionado.planejamentoCruzamentoAnterior = planejamentoCruzamento.id;
            }
        });
    }
    adicionarLancamentoEucalyptusPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                if ((typeof this.pomarSelecionado === 'undefined') || !this.pomarSelecionado) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-dialog > .modal-content');
                this.registroPolinizacaoPomarSelecionado = new PolinizacaoPomar_1.PolinizacaoPomar();
                this.registroPolinizacaoPomarSelecionado.id = 'NOVO';
                this.registroPolinizacaoPomarSelecionado.status = true;
                this.registroPolinizacaoPomarSelecionado.criacao = new Date();
                this.registroPolinizacaoPomarSelecionado.criacao.setMilliseconds(0);
                this.registroPolinizacaoPomarSelecionado.polinizacao = new LancamentoPolinizacaoPomar_1.LancamentoPolinizacaoPomar();
                this.registroPolinizacaoPomarSelecionado.polinizacao.status = true;
                this.registroPolinizacaoPomarSelecionado.polinizacao.criacao = new Date();
                this.registroPolinizacaoPomarSelecionado.polinizacao.criacao.setMilliseconds(0);
                this.registroPolinizacaoPomarSelecionado.polinizacao.ehPinus = false;
                this.registroPolinizacaoPomarSelecionado.polinizacao.ehEucalyptus = true;
                this.registroPolinizacaoPomarSelecionado.polinizacao.data = new Date();
                this.registroPolinizacaoPomarSelecionado.polinizacao.data.setMilliseconds(0);
                if ((typeof this.polinizacaoPomarSelecionadoTabela !== 'undefined') && this.polinizacaoPomarSelecionadoTabela &&
                    (typeof this.polinizacaoPomarSelecionadoTabela.id !== 'undefined') && this.polinizacaoPomarSelecionadoTabela.id &&
                    (typeof this.planejamentosCruzamentosPolinizacaoPomar !== 'undefined') && this.planejamentosCruzamentosPolinizacaoPomar) {
                    let planejamentoCruzamento = this.planejamentosCruzamentosPolinizacaoPomar.find(o => (o.id === ctrl.polinizacaoPomarSelecionadoTabela.id) || (o.planejamentosPolinizacaoPomar.indexOf(ctrl.polinizacaoPomarSelecionadoTabela.id) !== -1));
                    if ((typeof planejamentoCruzamento !== 'undefined') && planejamentoCruzamento) {
                        this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento = planejamentoCruzamento;
                        this.alterarPlanejamentoCruzamentoLancamentoEucalyptusPolinizacaoPomar();
                    }
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    editarLancamentoEucalyptusPolinizacaoPomar(id, indicePolinizacao) {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                if ((typeof this.pomarSelecionado === 'undefined') || !this.pomarSelecionado) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-dialog > .modal-content');
                this.registroPolinizacaoPomarSelecionado = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POLINIZACAO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POLINIZACAO_POMAR)
                    .id(id)
                    .ver();
                if ((typeof this.registroPolinizacaoPomarSelecionado.polinizacoes === 'undefined') || !this.registroPolinizacaoPomarSelecionado.polinizacoes) {
                    this.registroPolinizacaoPomarSelecionado.polinizacoes = new Array();
                }
                if ((typeof indicePolinizacao !== 'undefined') && (indicePolinizacao !== null) &&
                    (indicePolinizacao !== -1) && (indicePolinizacao < this.registroPolinizacaoPomarSelecionado.polinizacoes.length)) {
                    this.indiceLancamentoPolinizacaoPomarSelecionado = indicePolinizacao;
                    this.registroPolinizacaoPomarSelecionado.polinizacao = this.registroPolinizacaoPomarSelecionado.polinizacoes[indicePolinizacao];
                }
                else {
                    let lancamentoPolinizacaoEucalyptus = new LancamentoPolinizacaoPomar_1.LancamentoPolinizacaoPomar();
                    lancamentoPolinizacaoEucalyptus.status = true;
                    lancamentoPolinizacaoEucalyptus.criacao = new Date();
                    lancamentoPolinizacaoEucalyptus.criacao.setMilliseconds(0);
                    lancamentoPolinizacaoEucalyptus.ehPinus = false;
                    lancamentoPolinizacaoEucalyptus.ehEucalyptus = true;
                    this.registroPolinizacaoPomarSelecionado.polinizacoes.push(lancamentoPolinizacaoEucalyptus);
                    this.registroPolinizacaoPomarSelecionado.polinizacao = lancamentoPolinizacaoEucalyptus;
                    this.indiceLancamentoPolinizacaoPomarSelecionado = this.registroPolinizacaoPomarSelecionado.polinizacoes.length - 1;
                    this.alterarPolinizacaoEucalyptusPomar(lancamentoPolinizacaoEucalyptus);
                }
                if ((typeof this.planejamentosCruzamentosPolinizacaoPomar !== 'undefined') && this.planejamentosCruzamentosPolinizacaoPomar) {
                    let planejamentoCruzamento = this.planejamentosCruzamentosPolinizacaoPomar.find(o => (o.id === ctrl.registroPolinizacaoPomarSelecionado.id) || (o.planejamentosPolinizacaoPomar.indexOf(ctrl.registroPolinizacaoPomarSelecionado.id) !== -1));
                    if ((typeof planejamentoCruzamento !== 'undefined') && planejamentoCruzamento) {
                        this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento = planejamentoCruzamento;
                    }
                }
                if ((typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento !== 'undefined') && this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento &&
                    (typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id !== 'undefined') && this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id) {
                    this.registroPolinizacaoPomarSelecionado.planejamentoCruzamentoAnterior = this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id;
                }
                if ((typeof this.registroPolinizacaoPomarSelecionado.polinizacao !== 'undefined') && this.registroPolinizacaoPomarSelecionado.polinizacao) {
                    if (typeof this.registroPolinizacaoPomarSelecionado.polinizacao.criacao === 'string') {
                        this.registroPolinizacaoPomarSelecionado.polinizacao.criacao = new Date(this.registroPolinizacaoPomarSelecionado.polinizacao.criacao);
                    }
                    if (typeof this.registroPolinizacaoPomarSelecionado.polinizacao.data === 'string') {
                        this.registroPolinizacaoPomarSelecionado.polinizacao.data = new Date(this.registroPolinizacaoPomarSelecionado.polinizacao.data);
                    }
                    if (typeof this.registroPolinizacaoPomarSelecionado.polinizacao.dataInicial === 'string') {
                        this.registroPolinizacaoPomarSelecionado.polinizacao.dataInicial = new Date(this.registroPolinizacaoPomarSelecionado.polinizacao.dataInicial);
                    }
                    if (typeof this.registroPolinizacaoPomarSelecionado.polinizacao.dataFinal === 'string') {
                        this.registroPolinizacaoPomarSelecionado.polinizacao.dataFinal = new Date(this.registroPolinizacaoPomarSelecionado.polinizacao.dataFinal);
                    }
                    if (typeof this.registroPolinizacaoPomarSelecionado.polinizacao.dataRetiradaPacote === 'string') {
                        this.registroPolinizacaoPomarSelecionado.polinizacao.dataRetiradaPacote = new Date(this.registroPolinizacaoPomarSelecionado.polinizacao.dataRetiradaPacote);
                    }
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    salvarLancamentoEucalyptusPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof this.pomarSelecionado === 'undefined') || !this.pomarSelecionado ||
                    (typeof this.registroPolinizacaoPomarSelecionado === 'undefined') || !this.registroPolinizacaoPomarSelecionado) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-dialog > .modal-content');
                if ((typeof this.registroPolinizacaoPomarSelecionado.id !== 'undefined') && this.registroPolinizacaoPomarSelecionado.id &&
                    (this.registroPolinizacaoPomarSelecionado.id !== 'NOVO')) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.POLINIZACAO_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POLINIZACAO_POMAR)
                        .atualizar(this.registroPolinizacaoPomarSelecionado);
                }
                else {
                    if ((typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento === 'undefined') ||
                        !this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento ||
                        (typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id === 'undefined') ||
                        !this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id) {
                        if (this.ehCelular) {
                            alert(`Preencha o Planejamento/Cruzamento! Campo necessário para salvar o lançamento de polinização Eucalyptus.`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                title: 'Preencha o Planejamento/Cruzamento!',
                                text: 'Campo \'Planejamento/Cruzamento\' é necessário para salvar o lançamento de polinização Eucalyptus.',
                                icon: 'info',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                    let polinizacaoPomar = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.POLINIZACAO_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POLINIZACAO_POMAR)
                        .id(this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id)
                        .ver();
                    if (!polinizacaoPomar) {
                        if (this.ehCelular) {
                            alert(`Registro da polinização pomar (id: ${this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id}) não encontrado.`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                title: 'Registro da polinização pomar não encontrado !',
                                text: `Registro da polinização pomar (id: ${this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id}) não encontrado.`,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                    if ((typeof polinizacaoPomar.polinizacoes === 'undefined') || !polinizacaoPomar.polinizacoes) {
                        polinizacaoPomar.polinizacoes = new Array();
                    }
                    if ((typeof this.registroPolinizacaoPomarSelecionado.polinizacao !== 'undefined') &&
                        this.registroPolinizacaoPomarSelecionado.polinizacao) {
                        polinizacaoPomar.polinizacoes.push(this.registroPolinizacaoPomarSelecionado.polinizacao);
                    }
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.POLINIZACAO_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POLINIZACAO_POMAR)
                        .atualizar(polinizacaoPomar);
                }
                this.registroPolinizacaoPomarSelecionado = null;
                this.indiceLancamentoPolinizacaoPomarSelecionado = null;
                this.listaOpcoesPolinizacaoPomar = yield this.dadosListaPolinizacaoPomar();
                this.listaOpcoesLancamentosEucalyptusPolinizacaoPomar = yield this.dadosListaLancamentosEucalyptusPolinizacaoPomar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    cancelarLancamentoEucalyptusPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.registroPolinizacaoPomarSelecionado = null;
            this.indiceLancamentoPolinizacaoPomarSelecionado = null;
        });
    }
    alterarPlanejamentoCruzamentoLancamentoEucalyptusPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.registroPolinizacaoPomarSelecionado === 'undefined') || !this.registroPolinizacaoPomarSelecionado) {
                return;
            }
            const polinizacaoPomar = ((typeof this.registroPolinizacaoPomarSelecionado.polinizacao !== 'undefined') &&
                this.registroPolinizacaoPomarSelecionado.polinizacao)
                ? this.registroPolinizacaoPomarSelecionado.polinizacao : null;
            const planejamentoCruzamento = ((typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento !== 'undefined') &&
                this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento)
                ? this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento : null;
            if (polinizacaoPomar && planejamentoCruzamento && planejamentoCruzamento.id) {
                if ((typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamentoAnterior !== 'undefined') &&
                    this.registroPolinizacaoPomarSelecionado.planejamentoCruzamentoAnterior &&
                    (this.registroPolinizacaoPomarSelecionado.planejamentoCruzamentoAnterior === planejamentoCruzamento.id)) {
                    return;
                }
                this.registroPolinizacaoPomarSelecionado.planejamentoCruzamentoAnterior = planejamentoCruzamento.id;
                let dadosAtualizados = false;
                if ((typeof planejamentoCruzamento.pomarOrigemPolen !== 'undefined') && planejamentoCruzamento.pomarOrigemPolen) {
                    if ((typeof polinizacaoPomar.pomarOrigem === 'undefined') || !polinizacaoPomar.pomarOrigem ||
                        (polinizacaoPomar.pomarOrigem.id !== planejamentoCruzamento.pomarOrigemPolen.id)) {
                        polinizacaoPomar.pomarOrigem = planejamentoCruzamento.pomarOrigemPolen;
                        dadosAtualizados = true;
                    }
                }
                if ((typeof planejamentoCruzamento.materialGeneticoMasculino !== 'undefined') && planejamentoCruzamento.materialGeneticoMasculino) {
                    if ((typeof polinizacaoPomar.materialGeneticoGenitor === 'undefined') || !polinizacaoPomar.materialGeneticoGenitor ||
                        (polinizacaoPomar.materialGeneticoGenitor.id !== planejamentoCruzamento.materialGeneticoMasculino.id)) {
                        polinizacaoPomar.materialGeneticoGenitor = planejamentoCruzamento.materialGeneticoMasculino;
                        dadosAtualizados = true;
                    }
                }
                if (dadosAtualizados) {
                    this.alterarPolinizacaoEucalyptusPomar(polinizacaoPomar);
                    this.scopeApply();
                }
            }
        });
    }
    adicionarColetaConesSementesPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                if ((typeof this.pomarSelecionado === 'undefined') || !this.pomarSelecionado) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-dialog > .modal-content');
                this.registroPolinizacaoPomarSelecionado = new PolinizacaoPomar_1.PolinizacaoPomar();
                this.registroPolinizacaoPomarSelecionado.id = 'NOVO';
                this.registroPolinizacaoPomarSelecionado.status = true;
                this.registroPolinizacaoPomarSelecionado.criacao = new Date();
                this.registroPolinizacaoPomarSelecionado.criacao.setMilliseconds(0);
                this.registroPolinizacaoPomarSelecionado.coletaConesSementes = new ColetaConesSementesPolinizacaoPomar_1.ColetaConesSementesPolinizacaoPomar();
                this.registroPolinizacaoPomarSelecionado.coletaConesSementes.status = true;
                this.registroPolinizacaoPomarSelecionado.coletaConesSementes.criacao = new Date();
                this.registroPolinizacaoPomarSelecionado.coletaConesSementes.criacao.setMilliseconds(0);
                this.registroPolinizacaoPomarSelecionado.coletaConesSementes.data = new Date();
                this.registroPolinizacaoPomarSelecionado.coletaConesSementes.data.setMilliseconds(0);
                if ((typeof this.polinizacaoPomarSelecionadoTabela !== 'undefined') && this.polinizacaoPomarSelecionadoTabela &&
                    (typeof this.polinizacaoPomarSelecionadoTabela.id !== 'undefined') && this.polinizacaoPomarSelecionadoTabela.id &&
                    (typeof this.planejamentosCruzamentosPolinizacaoPomar !== 'undefined') && this.planejamentosCruzamentosPolinizacaoPomar) {
                    let planejamentoCruzamento = this.planejamentosCruzamentosPolinizacaoPomar.find(o => (o.id === ctrl.polinizacaoPomarSelecionadoTabela.id) || (o.planejamentosPolinizacaoPomar.indexOf(ctrl.polinizacaoPomarSelecionadoTabela.id) !== -1));
                    if ((typeof planejamentoCruzamento !== 'undefined') && planejamentoCruzamento) {
                        this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento = planejamentoCruzamento;
                        this.alterarPlanejamentoCruzamentoColetaConesSementesPolinizacaoPomar();
                    }
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    editarColetaConesSementesPolinizacaoPomar(id, indiceColetaConesSementes) {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                if ((typeof this.pomarSelecionado === 'undefined') || !this.pomarSelecionado) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-dialog > .modal-content');
                this.registroPolinizacaoPomarSelecionado = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POLINIZACAO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POLINIZACAO_POMAR)
                    .id(id)
                    .ver();
                if ((typeof this.registroPolinizacaoPomarSelecionado.coletasConesSementes === 'undefined') || !this.registroPolinizacaoPomarSelecionado.coletasConesSementes) {
                    this.registroPolinizacaoPomarSelecionado.coletasConesSementes = new Array();
                }
                if ((typeof indiceColetaConesSementes !== 'undefined') && (indiceColetaConesSementes !== null) &&
                    (indiceColetaConesSementes !== -1) && (indiceColetaConesSementes < this.registroPolinizacaoPomarSelecionado.coletasConesSementes.length)) {
                    this.indiceColetaConesSementesPolinizacaoPomarSelecionado = indiceColetaConesSementes;
                    this.registroPolinizacaoPomarSelecionado.coletaConesSementes = this.registroPolinizacaoPomarSelecionado.coletasConesSementes[indiceColetaConesSementes];
                }
                else {
                    let coletaConesSementes = new ColetaConesSementesPolinizacaoPomar_1.ColetaConesSementesPolinizacaoPomar();
                    coletaConesSementes.status = true;
                    coletaConesSementes.criacao = new Date();
                    coletaConesSementes.criacao.setMilliseconds(0);
                    this.registroPolinizacaoPomarSelecionado.coletasConesSementes.push(coletaConesSementes);
                    this.registroPolinizacaoPomarSelecionado.coletaConesSementes = coletaConesSementes;
                    this.indiceColetaConesSementesPolinizacaoPomarSelecionado = this.registroPolinizacaoPomarSelecionado.coletasConesSementes.length - 1;
                }
                if ((typeof this.planejamentosCruzamentosPolinizacaoPomar !== 'undefined') && this.planejamentosCruzamentosPolinizacaoPomar) {
                    let planejamentoCruzamento = this.planejamentosCruzamentosPolinizacaoPomar.find(o => (o.id === ctrl.registroPolinizacaoPomarSelecionado.id) || (o.planejamentosPolinizacaoPomar.indexOf(ctrl.registroPolinizacaoPomarSelecionado.id) !== -1));
                    if ((typeof planejamentoCruzamento !== 'undefined') && planejamentoCruzamento) {
                        this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento = planejamentoCruzamento;
                    }
                }
                if ((typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento !== 'undefined') && this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento &&
                    (typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id !== 'undefined') && this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id) {
                    this.registroPolinizacaoPomarSelecionado.planejamentoCruzamentoAnterior = this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id;
                }
                if ((typeof this.registroPolinizacaoPomarSelecionado.coletaConesSementes !== 'undefined') && this.registroPolinizacaoPomarSelecionado.coletaConesSementes) {
                    if (typeof this.registroPolinizacaoPomarSelecionado.coletaConesSementes.data === 'string') {
                        this.registroPolinizacaoPomarSelecionado.coletaConesSementes.data = new Date(this.registroPolinizacaoPomarSelecionado.coletaConesSementes.data);
                    }
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    salvarColetaConesSementesPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof this.pomarSelecionado === 'undefined') || !this.pomarSelecionado ||
                    (typeof this.registroPolinizacaoPomarSelecionado === 'undefined') || !this.registroPolinizacaoPomarSelecionado) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-dialog > .modal-content');
                if ((typeof this.registroPolinizacaoPomarSelecionado.id !== 'undefined') && this.registroPolinizacaoPomarSelecionado.id &&
                    (this.registroPolinizacaoPomarSelecionado.id !== 'NOVO')) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.POLINIZACAO_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POLINIZACAO_POMAR)
                        .atualizar(this.registroPolinizacaoPomarSelecionado);
                }
                else {
                    if ((typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento === 'undefined') ||
                        !this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento ||
                        (typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id === 'undefined') ||
                        !this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id) {
                        if (this.ehCelular) {
                            alert(`Preencha o Planejamento/Cruzamento! Campo necessário para salvar a coleta de cones/sementes.`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                title: 'Preencha o Planejamento/Cruzamento!',
                                text: 'Campo \'Planejamento/Cruzamento\' é necessário para salvar a coleta de cones/sementes.',
                                icon: 'info',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                    let polinizacaoPomar = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.POLINIZACAO_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POLINIZACAO_POMAR)
                        .id(this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id)
                        .ver();
                    if (!polinizacaoPomar) {
                        if (this.ehCelular) {
                            alert(`Registro da polinização pomar (id: ${this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id}) não encontrado.`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                title: 'Registro da polinização pomar não encontrado !',
                                text: `Registro da polinização pomar (id: ${this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id}) não encontrado.`,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                    if ((typeof polinizacaoPomar.coletasConesSementes === 'undefined') || !polinizacaoPomar.coletasConesSementes) {
                        polinizacaoPomar.coletasConesSementes = new Array();
                    }
                    if ((typeof this.registroPolinizacaoPomarSelecionado.coletaConesSementes !== 'undefined') &&
                        this.registroPolinizacaoPomarSelecionado.coletaConesSementes) {
                        polinizacaoPomar.coletasConesSementes.push(this.registroPolinizacaoPomarSelecionado.coletaConesSementes);
                    }
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.POLINIZACAO_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POLINIZACAO_POMAR)
                        .atualizar(polinizacaoPomar);
                }
                this.registroPolinizacaoPomarSelecionado = null;
                this.indiceColetaConesSementesPolinizacaoPomarSelecionado = null;
                this.listaOpcoesPolinizacaoPomar = yield this.dadosListaPolinizacaoPomar();
                this.listaOpcoesColetasConesSementesPolinizacaoPomar = yield this.dadosListaColetasConesSementesPolinizacaoPomar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    cancelarColetaConesSementesPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.registroPolinizacaoPomarSelecionado = null;
            this.indiceColetaConesSementesPolinizacaoPomarSelecionado = null;
        });
    }
    alterarPlanejamentoCruzamentoColetaConesSementesPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.registroPolinizacaoPomarSelecionado === 'undefined') || !this.registroPolinizacaoPomarSelecionado) {
                return;
            }
            const coletaConesSementes = ((typeof this.registroPolinizacaoPomarSelecionado.coletaConesSementes !== 'undefined') &&
                this.registroPolinizacaoPomarSelecionado.coletaConesSementes)
                ? this.registroPolinizacaoPomarSelecionado.coletaConesSementes : null;
            const planejamentoCruzamento = ((typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento !== 'undefined') &&
                this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento)
                ? this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento : null;
            if (coletaConesSementes && planejamentoCruzamento && planejamentoCruzamento.id) {
                if ((typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamentoAnterior !== 'undefined') &&
                    this.registroPolinizacaoPomarSelecionado.planejamentoCruzamentoAnterior &&
                    (this.registroPolinizacaoPomarSelecionado.planejamentoCruzamentoAnterior === planejamentoCruzamento.id)) {
                    return;
                }
                this.registroPolinizacaoPomarSelecionado.planejamentoCruzamentoAnterior = planejamentoCruzamento.id;
            }
        });
    }
    adicionarBeneficiamentoPinusPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                if ((typeof this.pomarSelecionado === 'undefined') || !this.pomarSelecionado) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-dialog > .modal-content');
                this.registroPolinizacaoPomarSelecionado = new PolinizacaoPomar_1.PolinizacaoPomar();
                this.registroPolinizacaoPomarSelecionado.id = 'NOVO';
                this.registroPolinizacaoPomarSelecionado.status = true;
                this.registroPolinizacaoPomarSelecionado.criacao = new Date();
                this.registroPolinizacaoPomarSelecionado.criacao.setMilliseconds(0);
                this.registroPolinizacaoPomarSelecionado.beneficiamento = new BeneficiamentoPolinizacaoPomar_1.BeneficiamentoPolinizacaoPomar();
                this.registroPolinizacaoPomarSelecionado.beneficiamento.status = true;
                this.registroPolinizacaoPomarSelecionado.beneficiamento.criacao = new Date();
                this.registroPolinizacaoPomarSelecionado.beneficiamento.criacao.setMilliseconds(0);
                this.registroPolinizacaoPomarSelecionado.beneficiamento.ehPinus = true;
                this.registroPolinizacaoPomarSelecionado.beneficiamento.ehEucalyptus = false;
                this.registroPolinizacaoPomarSelecionado.beneficiamento.data = new Date();
                this.registroPolinizacaoPomarSelecionado.beneficiamento.data.setMilliseconds(0);
                if ((typeof this.polinizacaoPomarSelecionadoTabela !== 'undefined') && this.polinizacaoPomarSelecionadoTabela &&
                    (typeof this.polinizacaoPomarSelecionadoTabela.id !== 'undefined') && this.polinizacaoPomarSelecionadoTabela.id &&
                    (typeof this.planejamentosCruzamentosPolinizacaoPomar !== 'undefined') && this.planejamentosCruzamentosPolinizacaoPomar) {
                    let planejamentoCruzamento = this.planejamentosCruzamentosPolinizacaoPomar.find(o => (o.id === ctrl.polinizacaoPomarSelecionadoTabela.id) || (o.planejamentosPolinizacaoPomar.indexOf(ctrl.polinizacaoPomarSelecionadoTabela.id) !== -1));
                    if ((typeof planejamentoCruzamento !== 'undefined') && planejamentoCruzamento) {
                        this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento = planejamentoCruzamento;
                        this.alterarPlanejamentoCruzamentoBeneficiamentoPinusPolinizacaoPomar();
                    }
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    editarBeneficiamentoPinusPolinizacaoPomar(id, indiceBeneficiamentoPinus) {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                if ((typeof this.pomarSelecionado === 'undefined') || !this.pomarSelecionado) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-dialog > .modal-content');
                this.registroPolinizacaoPomarSelecionado = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POLINIZACAO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POLINIZACAO_POMAR)
                    .id(id)
                    .ver();
                if ((typeof this.registroPolinizacaoPomarSelecionado.beneficiamentos === 'undefined') || !this.registroPolinizacaoPomarSelecionado.beneficiamentos) {
                    this.registroPolinizacaoPomarSelecionado.beneficiamentos = new Array();
                }
                if ((typeof indiceBeneficiamentoPinus !== 'undefined') && (indiceBeneficiamentoPinus !== null) &&
                    (indiceBeneficiamentoPinus !== -1) && (indiceBeneficiamentoPinus < this.registroPolinizacaoPomarSelecionado.beneficiamentos.length)) {
                    this.indiceBeneficiamentoPolinizacaoPomarSelecionado = indiceBeneficiamentoPinus;
                    this.registroPolinizacaoPomarSelecionado.beneficiamento = this.registroPolinizacaoPomarSelecionado.beneficiamentos[indiceBeneficiamentoPinus];
                }
                else {
                    let beneficiamentoPinus = new BeneficiamentoPolinizacaoPomar_1.BeneficiamentoPolinizacaoPomar();
                    beneficiamentoPinus.status = true;
                    beneficiamentoPinus.criacao = new Date();
                    beneficiamentoPinus.criacao.setMilliseconds(0);
                    beneficiamentoPinus.ehPinus = true;
                    beneficiamentoPinus.ehEucalyptus = false;
                    this.registroPolinizacaoPomarSelecionado.beneficiamentos.push(beneficiamentoPinus);
                    this.registroPolinizacaoPomarSelecionado.beneficiamento = beneficiamentoPinus;
                    this.indiceBeneficiamentoPolinizacaoPomarSelecionado = this.registroPolinizacaoPomarSelecionado.beneficiamentos.length - 1;
                }
                if ((typeof this.registroPolinizacaoPomarSelecionado.beneficiamento !== 'undefined') && this.registroPolinizacaoPomarSelecionado.beneficiamento &&
                    (typeof this.registroPolinizacaoPomarSelecionado.beneficiamento.data !== 'undefined') && this.registroPolinizacaoPomarSelecionado.beneficiamento.data) {
                    if (typeof this.registroPolinizacaoPomarSelecionado.beneficiamento.data === 'string') {
                        this.registroPolinizacaoPomarSelecionado.beneficiamento.data = new Date(this.registroPolinizacaoPomarSelecionado.beneficiamento.data);
                    }
                }
                if ((typeof this.planejamentosCruzamentosPolinizacaoPomar !== 'undefined') && this.planejamentosCruzamentosPolinizacaoPomar) {
                    let planejamentoCruzamento = this.planejamentosCruzamentosPolinizacaoPomar.find(o => (o.id === ctrl.registroPolinizacaoPomarSelecionado.id) || (o.planejamentosPolinizacaoPomar.indexOf(ctrl.registroPolinizacaoPomarSelecionado.id) !== -1));
                    if ((typeof planejamentoCruzamento !== 'undefined') && planejamentoCruzamento) {
                        this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento = planejamentoCruzamento;
                    }
                }
                if ((typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento !== 'undefined') && this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento &&
                    (typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id !== 'undefined') && this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id) {
                    this.registroPolinizacaoPomarSelecionado.planejamentoCruzamentoAnterior = this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id;
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    salvarBeneficiamentoPinusPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof this.pomarSelecionado === 'undefined') || !this.pomarSelecionado ||
                    (typeof this.registroPolinizacaoPomarSelecionado === 'undefined') || !this.registroPolinizacaoPomarSelecionado) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-dialog > .modal-content');
                if ((typeof this.registroPolinizacaoPomarSelecionado.id !== 'undefined') && this.registroPolinizacaoPomarSelecionado.id &&
                    (this.registroPolinizacaoPomarSelecionado.id !== 'NOVO')) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.POLINIZACAO_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POLINIZACAO_POMAR)
                        .atualizar(this.registroPolinizacaoPomarSelecionado);
                }
                else {
                    if ((typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento === 'undefined') ||
                        !this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento ||
                        (typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id === 'undefined') ||
                        !this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id) {
                        if (this.ehCelular) {
                            alert(`Preencha o Planejamento/Cruzamento! Campo necessário para salvar o beneficiamento Pinus.`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                title: 'Preencha o Planejamento/Cruzamento!',
                                text: 'Campo \'Planejamento/Cruzamento\' é necessário para salvar o beneficiamento Pinus.',
                                icon: 'info',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                    let polinizacaoPomar = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.POLINIZACAO_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POLINIZACAO_POMAR)
                        .id(this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id)
                        .ver();
                    if (!polinizacaoPomar) {
                        if (this.ehCelular) {
                            alert(`Registro da polinização pomar (id: ${this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id}) não encontrado.`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                title: 'Registro da polinização pomar não encontrado !',
                                text: `Registro da polinização pomar (id: ${this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id}) não encontrado.`,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                    if ((typeof polinizacaoPomar.beneficiamentos === 'undefined') || !polinizacaoPomar.beneficiamentos) {
                        polinizacaoPomar.beneficiamentos = new Array();
                    }
                    if ((typeof this.registroPolinizacaoPomarSelecionado.beneficiamento !== 'undefined') &&
                        this.registroPolinizacaoPomarSelecionado.beneficiamento) {
                        polinizacaoPomar.beneficiamentos.push(this.registroPolinizacaoPomarSelecionado.beneficiamento);
                    }
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.POLINIZACAO_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POLINIZACAO_POMAR)
                        .atualizar(polinizacaoPomar);
                }
                this.registroPolinizacaoPomarSelecionado = null;
                this.estoqueBeneficiamentoPinusPolinizacaoPomarSelecionado = null;
                this.estoqueBeneficiamentoEucalyptusPolinizacaoPomarSelecionado = null;
                this.indiceBeneficiamentoPolinizacaoPomarSelecionado = null;
                this.listaOpcoesPolinizacaoPomar = yield this.dadosListaPolinizacaoPomar();
                this.listaOpcoesBeneficiamentosPinusPolinizacaoPomar = yield this.dadosListaBeneficiamentosPinusPolinizacaoPomar();
                this.listaOpcoesEstoqueBeneficiamentoPinusPolinizacaoPomar = yield this.dadosListaEstoqueBeneficiamentoPinusPolinizacaoPomar();
                this.listaOpcoesHistoricoEstoqueBeneficiamentoPinusPolinizacaoPomar = yield this.dadosListaHistoricoEstoqueBeneficiamentoPinusPolinizacaoPomar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    cancelarBeneficiamentoPinusPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.registroPolinizacaoPomarSelecionado = null;
            this.indiceBeneficiamentoPolinizacaoPomarSelecionado = null;
        });
    }
    alterarPlanejamentoCruzamentoBeneficiamentoPinusPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.registroPolinizacaoPomarSelecionado === 'undefined') || !this.registroPolinizacaoPomarSelecionado) {
                return;
            }
            const beneficiamento = ((typeof this.registroPolinizacaoPomarSelecionado.beneficiamento !== 'undefined') &&
                this.registroPolinizacaoPomarSelecionado.beneficiamento)
                ? this.registroPolinizacaoPomarSelecionado.beneficiamento : null;
            const planejamentoCruzamento = ((typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento !== 'undefined') &&
                this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento)
                ? this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento : null;
            if (beneficiamento && planejamentoCruzamento && planejamentoCruzamento.id) {
                if ((typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamentoAnterior !== 'undefined') &&
                    this.registroPolinizacaoPomarSelecionado.planejamentoCruzamentoAnterior &&
                    (this.registroPolinizacaoPomarSelecionado.planejamentoCruzamentoAnterior === planejamentoCruzamento.id)) {
                    return;
                }
                this.registroPolinizacaoPomarSelecionado.planejamentoCruzamentoAnterior = planejamentoCruzamento.id;
            }
        });
    }
    editarEstoqueBeneficiamentoPinusPolinizacaoPomar(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof this.pomarSelecionado === 'undefined') || !this.pomarSelecionado) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-dialog > .modal-content');
                this.estoqueBeneficiamentoPinusPolinizacaoPomarSelecionado = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESTOQUE_BENEFICIAMENTO_PINUS_POLINIZACAO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESTOQUE_BENEFICIAMENTO_PINUS_POLINIZACAO_POMAR)
                    .id(id)
                    .ver();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    salvarEstoqueBeneficiamentoPinusPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof this.pomarSelecionado === 'undefined') || !this.pomarSelecionado ||
                    (typeof this.estoqueBeneficiamentoPinusPolinizacaoPomarSelecionado === 'undefined') || !this.estoqueBeneficiamentoPinusPolinizacaoPomarSelecionado) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-dialog > .modal-content');
                yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESTOQUE_BENEFICIAMENTO_PINUS_POLINIZACAO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESTOQUE_BENEFICIAMENTO_PINUS_POLINIZACAO_POMAR)
                    .atualizar(this.estoqueBeneficiamentoPinusPolinizacaoPomarSelecionado);
                this.estoqueBeneficiamentoPinusPolinizacaoPomarSelecionado = null;
                this.listaOpcoesBeneficiamentosPinusPolinizacaoPomar = yield this.dadosListaBeneficiamentosPinusPolinizacaoPomar();
                this.listaOpcoesEstoqueBeneficiamentoPinusPolinizacaoPomar = yield this.dadosListaEstoqueBeneficiamentoPinusPolinizacaoPomar();
                this.listaOpcoesHistoricoEstoqueBeneficiamentoPinusPolinizacaoPomar = yield this.dadosListaHistoricoEstoqueBeneficiamentoPinusPolinizacaoPomar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    cancelarEstoqueBeneficiamentoPinusPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.estoqueBeneficiamentoPinusPolinizacaoPomarSelecionado = null;
        });
    }
    adicionarBeneficiamentoEucalyptusPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                if ((typeof this.pomarSelecionado === 'undefined') || !this.pomarSelecionado) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-dialog > .modal-content');
                this.registroPolinizacaoPomarSelecionado = new PolinizacaoPomar_1.PolinizacaoPomar();
                this.registroPolinizacaoPomarSelecionado.id = 'NOVO';
                this.registroPolinizacaoPomarSelecionado.status = true;
                this.registroPolinizacaoPomarSelecionado.criacao = new Date();
                this.registroPolinizacaoPomarSelecionado.criacao.setMilliseconds(0);
                this.registroPolinizacaoPomarSelecionado.beneficiamento = new BeneficiamentoPolinizacaoPomar_1.BeneficiamentoPolinizacaoPomar();
                this.registroPolinizacaoPomarSelecionado.beneficiamento.status = true;
                this.registroPolinizacaoPomarSelecionado.beneficiamento.criacao = new Date();
                this.registroPolinizacaoPomarSelecionado.beneficiamento.criacao.setMilliseconds(0);
                this.registroPolinizacaoPomarSelecionado.beneficiamento.ehPinus = false;
                this.registroPolinizacaoPomarSelecionado.beneficiamento.ehEucalyptus = true;
                this.registroPolinizacaoPomarSelecionado.beneficiamento.data = new Date();
                this.registroPolinizacaoPomarSelecionado.beneficiamento.data.setMilliseconds(0);
                if ((typeof this.polinizacaoPomarSelecionadoTabela !== 'undefined') && this.polinizacaoPomarSelecionadoTabela &&
                    (typeof this.polinizacaoPomarSelecionadoTabela.id !== 'undefined') && this.polinizacaoPomarSelecionadoTabela.id &&
                    (typeof this.planejamentosCruzamentosPolinizacaoPomar !== 'undefined') && this.planejamentosCruzamentosPolinizacaoPomar) {
                    let planejamentoCruzamento = this.planejamentosCruzamentosPolinizacaoPomar.find(o => (o.id === ctrl.polinizacaoPomarSelecionadoTabela.id) || (o.planejamentosPolinizacaoPomar.indexOf(ctrl.polinizacaoPomarSelecionadoTabela.id) !== -1));
                    if ((typeof planejamentoCruzamento !== 'undefined') && planejamentoCruzamento) {
                        this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento = planejamentoCruzamento;
                        this.alterarPlanejamentoCruzamentoBeneficiamentoEucalyptusPolinizacaoPomar();
                    }
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    editarBeneficiamentoEucalyptusPolinizacaoPomar(id, indiceBeneficiamentoEucalyptus) {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                if ((typeof this.pomarSelecionado === 'undefined') || !this.pomarSelecionado) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-dialog > .modal-content');
                this.registroPolinizacaoPomarSelecionado = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.POLINIZACAO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POLINIZACAO_POMAR)
                    .id(id)
                    .ver();
                if ((typeof this.registroPolinizacaoPomarSelecionado.beneficiamentos === 'undefined') || !this.registroPolinizacaoPomarSelecionado.beneficiamentos) {
                    this.registroPolinizacaoPomarSelecionado.beneficiamentos = new Array();
                }
                if ((typeof indiceBeneficiamentoEucalyptus !== 'undefined') && (indiceBeneficiamentoEucalyptus !== null) &&
                    (indiceBeneficiamentoEucalyptus !== -1) && (indiceBeneficiamentoEucalyptus < this.registroPolinizacaoPomarSelecionado.beneficiamentos.length)) {
                    this.indiceBeneficiamentoPolinizacaoPomarSelecionado = indiceBeneficiamentoEucalyptus;
                    this.registroPolinizacaoPomarSelecionado.beneficiamento = this.registroPolinizacaoPomarSelecionado.beneficiamentos[indiceBeneficiamentoEucalyptus];
                }
                else {
                    let beneficiamentoEucalyptus = new BeneficiamentoPolinizacaoPomar_1.BeneficiamentoPolinizacaoPomar();
                    beneficiamentoEucalyptus.status = true;
                    beneficiamentoEucalyptus.criacao = new Date();
                    beneficiamentoEucalyptus.criacao.setMilliseconds(0);
                    beneficiamentoEucalyptus.ehPinus = false;
                    beneficiamentoEucalyptus.ehEucalyptus = true;
                    this.registroPolinizacaoPomarSelecionado.beneficiamentos.push(beneficiamentoEucalyptus);
                    this.registroPolinizacaoPomarSelecionado.beneficiamento = beneficiamentoEucalyptus;
                    this.indiceBeneficiamentoPolinizacaoPomarSelecionado = this.registroPolinizacaoPomarSelecionado.beneficiamentos.length - 1;
                }
                if ((typeof this.registroPolinizacaoPomarSelecionado.beneficiamento !== 'undefined') && this.registroPolinizacaoPomarSelecionado.beneficiamento &&
                    (typeof this.registroPolinizacaoPomarSelecionado.beneficiamento.data !== 'undefined') && this.registroPolinizacaoPomarSelecionado.beneficiamento.data) {
                    if (typeof this.registroPolinizacaoPomarSelecionado.beneficiamento.data === 'string') {
                        this.registroPolinizacaoPomarSelecionado.beneficiamento.data = new Date(this.registroPolinizacaoPomarSelecionado.beneficiamento.data);
                    }
                }
                if ((typeof this.planejamentosCruzamentosPolinizacaoPomar !== 'undefined') && this.planejamentosCruzamentosPolinizacaoPomar) {
                    let planejamentoCruzamento = this.planejamentosCruzamentosPolinizacaoPomar.find(o => (o.id === ctrl.registroPolinizacaoPomarSelecionado.id) || (o.planejamentosPolinizacaoPomar.indexOf(ctrl.registroPolinizacaoPomarSelecionado.id) !== -1));
                    if ((typeof planejamentoCruzamento !== 'undefined') && planejamentoCruzamento) {
                        this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento = planejamentoCruzamento;
                    }
                }
                if ((typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento !== 'undefined') && this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento &&
                    (typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id !== 'undefined') && this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id) {
                    this.registroPolinizacaoPomarSelecionado.planejamentoCruzamentoAnterior = this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id;
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    salvarBeneficiamentoEucalyptusPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof this.pomarSelecionado === 'undefined') || !this.pomarSelecionado ||
                    (typeof this.registroPolinizacaoPomarSelecionado === 'undefined') || !this.registroPolinizacaoPomarSelecionado) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-dialog > .modal-content');
                if ((typeof this.registroPolinizacaoPomarSelecionado.id !== 'undefined') && this.registroPolinizacaoPomarSelecionado.id &&
                    (this.registroPolinizacaoPomarSelecionado.id !== 'NOVO')) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.POLINIZACAO_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POLINIZACAO_POMAR)
                        .atualizar(this.registroPolinizacaoPomarSelecionado);
                }
                else {
                    if ((typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento === 'undefined') ||
                        !this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento ||
                        (typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id === 'undefined') ||
                        !this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id) {
                        if (this.ehCelular) {
                            alert(`Preencha o Planejamento/Cruzamento! Campo necessário para salvar o beneficiamento Eucalyptus.`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                title: 'Preencha o Planejamento/Cruzamento!',
                                text: 'Campo \'Planejamento/Cruzamento\' é necessário para salvar o beneficiamento Eucalyptus.',
                                icon: 'info',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                    let polinizacaoPomar = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.POLINIZACAO_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POLINIZACAO_POMAR)
                        .id(this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id)
                        .ver();
                    if (!polinizacaoPomar) {
                        if (this.ehCelular) {
                            alert(`Registro da polinização pomar (id: ${this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id}) não encontrado.`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                title: 'Registro da polinização pomar não encontrado !',
                                text: `Registro da polinização pomar (id: ${this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento.id}) não encontrado.`,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                    if ((typeof polinizacaoPomar.beneficiamentos === 'undefined') || !polinizacaoPomar.beneficiamentos) {
                        polinizacaoPomar.beneficiamentos = new Array();
                    }
                    if ((typeof this.registroPolinizacaoPomarSelecionado.beneficiamento !== 'undefined') &&
                        this.registroPolinizacaoPomarSelecionado.beneficiamento) {
                        polinizacaoPomar.beneficiamentos.push(this.registroPolinizacaoPomarSelecionado.beneficiamento);
                    }
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.POLINIZACAO_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.POLINIZACAO_POMAR)
                        .atualizar(polinizacaoPomar);
                }
                this.registroPolinizacaoPomarSelecionado = null;
                this.estoqueBeneficiamentoPinusPolinizacaoPomarSelecionado = null;
                this.estoqueBeneficiamentoEucalyptusPolinizacaoPomarSelecionado = null;
                this.indiceBeneficiamentoPolinizacaoPomarSelecionado = null;
                this.listaOpcoesPolinizacaoPomar = yield this.dadosListaPolinizacaoPomar();
                this.listaOpcoesBeneficiamentosEucalyptusPolinizacaoPomar = yield this.dadosListaBeneficiamentosEucalyptusPolinizacaoPomar();
                this.listaOpcoesEstoqueBeneficiamentoEucalyptusPolinizacaoPomar = yield this.dadosListaEstoqueBeneficiamentoEucalyptusPolinizacaoPomar();
                this.listaOpcoesHistoricoEstoqueBeneficiamentoEucalyptusPolinizacaoPomar = yield this.dadosListaHistoricoEstoqueBeneficiamentoEucalyptusPolinizacaoPomar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    cancelarBeneficiamentoEucalyptusPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.registroPolinizacaoPomarSelecionado = null;
            this.indiceBeneficiamentoPolinizacaoPomarSelecionado = null;
        });
    }
    alterarPlanejamentoCruzamentoBeneficiamentoEucalyptusPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.registroPolinizacaoPomarSelecionado === 'undefined') || !this.registroPolinizacaoPomarSelecionado) {
                return;
            }
            const beneficiamento = ((typeof this.registroPolinizacaoPomarSelecionado.beneficiamento !== 'undefined') &&
                this.registroPolinizacaoPomarSelecionado.beneficiamento)
                ? this.registroPolinizacaoPomarSelecionado.beneficiamento : null;
            const planejamentoCruzamento = ((typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento !== 'undefined') &&
                this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento)
                ? this.registroPolinizacaoPomarSelecionado.planejamentoCruzamento : null;
            if (beneficiamento && planejamentoCruzamento && planejamentoCruzamento.id) {
                if ((typeof this.registroPolinizacaoPomarSelecionado.planejamentoCruzamentoAnterior !== 'undefined') &&
                    this.registroPolinizacaoPomarSelecionado.planejamentoCruzamentoAnterior &&
                    (this.registroPolinizacaoPomarSelecionado.planejamentoCruzamentoAnterior === planejamentoCruzamento.id)) {
                    return;
                }
                this.registroPolinizacaoPomarSelecionado.planejamentoCruzamentoAnterior = planejamentoCruzamento.id;
            }
        });
    }
    editarEstoqueBeneficiamentoEucalyptusPolinizacaoPomar(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof this.pomarSelecionado === 'undefined') || !this.pomarSelecionado) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-dialog > .modal-content');
                this.estoqueBeneficiamentoEucalyptusPolinizacaoPomarSelecionado = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESTOQUE_BENEFICIAMENTO_EUCALYPTUS_POLINIZACAO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESTOQUE_BENEFICIAMENTO_EUCALYPTUS_POLINIZACAO_POMAR)
                    .id(id)
                    .ver();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    salvarEstoqueBeneficiamentoEucalyptusPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof this.pomarSelecionado === 'undefined') || !this.pomarSelecionado ||
                    (typeof this.estoqueBeneficiamentoEucalyptusPolinizacaoPomarSelecionado === 'undefined') || !this.estoqueBeneficiamentoEucalyptusPolinizacaoPomarSelecionado) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-dialog > .modal-content');
                yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ESTOQUE_BENEFICIAMENTO_EUCALYPTUS_POLINIZACAO_POMAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ESTOQUE_BENEFICIAMENTO_EUCALYPTUS_POLINIZACAO_POMAR)
                    .atualizar(this.estoqueBeneficiamentoEucalyptusPolinizacaoPomarSelecionado);
                this.estoqueBeneficiamentoEucalyptusPolinizacaoPomarSelecionado = null;
                this.listaOpcoesBeneficiamentosEucalyptusPolinizacaoPomar = yield this.dadosListaBeneficiamentosEucalyptusPolinizacaoPomar();
                this.listaOpcoesEstoqueBeneficiamentoEucalyptusPolinizacaoPomar = yield this.dadosListaEstoqueBeneficiamentoEucalyptusPolinizacaoPomar();
                this.listaOpcoesHistoricoEstoqueBeneficiamentoEucalyptusPolinizacaoPomar = yield this.dadosListaHistoricoEstoqueBeneficiamentoEucalyptusPolinizacaoPomar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    cancelarEstoqueBeneficiamentoEucalyptusPolinizacaoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.estoqueBeneficiamentoEucalyptusPolinizacaoPomarSelecionado = null;
        });
    }
    exibirModalRelatorioPomar(tipoRelatorio) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.pomarSelecionado === 'undefined') || !this.pomarSelecionado) {
                return;
            }
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                const ctrl = this;
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.relatorioPomarSelecionado = new RelatorioPomar_1.RelatorioPomar();
                this.relatorioPomarSelecionado.pomar = this.pomarSelecionado;
                this.relatorioPomarSelecionado.tipoRelatorio = tipoRelatorio;
                this.relatorioPomarSelecionado.dataRelatorio = new Date();
                if (tipoRelatorio === TipoRelatorioPomar_1.TipoRelatorioPomar.DOSSIE_MATERIAL_GENETICO) {
                    this.relatorioPomarSelecionado.materiaisGeneticos = new Array();
                    let materialGeneticoTodos = new RelatorioMaterialGeneticoPomar_1.RelatorioMaterialGeneticoPomar();
                    materialGeneticoTodos.id = 'TODOS';
                    materialGeneticoTodos.descricao = '(Todos)';
                    ctrl.relatorioPomarSelecionado.materiaisGeneticos.push(materialGeneticoTodos);
                    if ((typeof this.materiaisGeneticosFiltrados !== 'undefined') && this.materiaisGeneticosFiltrados) {
                        angular.forEach(this.materiaisGeneticosFiltrados, (materialGenetico) => {
                            let materialGeneticoRelatorio = angular.copy(materialGenetico);
                            ctrl.relatorioPomarSelecionado.materiaisGeneticos.push(materialGeneticoRelatorio);
                        });
                    }
                    else if ((typeof this.materiaisGeneticos !== 'undefined') && this.materiaisGeneticos) {
                        angular.forEach(this.materiaisGeneticos, (materialGenetico) => {
                            if (ctrl.relatorioPomarSelecionado.materiaisGeneticos.length <= 250) {
                                let materialGeneticoRelatorio = angular.copy(materialGenetico);
                                ctrl.relatorioPomarSelecionado.materiaisGeneticos.push(materialGeneticoRelatorio);
                            }
                        });
                    }
                    angular.forEach(this.relatorioPomarSelecionado.materiaisGeneticos, (materialGeneticoRelatorio) => {
                        if ((typeof materialGeneticoRelatorio.genero === 'undefined') || !materialGeneticoRelatorio.genero) {
                            materialGeneticoRelatorio.genero = ctrl.pomarSelecionado.genero;
                        }
                        if ((typeof materialGeneticoRelatorio.especie === 'undefined') || !materialGeneticoRelatorio.especie) {
                            materialGeneticoRelatorio.especie = ctrl.pomarSelecionado.especie;
                        }
                        if ((typeof materialGeneticoRelatorio.geracao === 'undefined') || !materialGeneticoRelatorio.geracao) {
                            materialGeneticoRelatorio.geracao = ctrl.pomarSelecionado.geracao;
                        }
                    });
                    ctrl.relatorioPomarSelecionado.filtroMaterialGenetico = materialGeneticoTodos;
                }
                if ((tipoRelatorio === TipoRelatorioPomar_1.TipoRelatorioPomar.POLINIZACAO_PINUS) ||
                    (tipoRelatorio === TipoRelatorioPomar_1.TipoRelatorioPomar.POLINIZACAO_EUCALYPTUS)) {
                    this.listaAnos = new Array();
                    if ((typeof this.listaOpcoesPolinizacaoPomar !== 'undefined') && this.listaOpcoesPolinizacaoPomar &&
                        (typeof this.listaOpcoesPolinizacaoPomar.data !== 'undefined') && this.listaOpcoesPolinizacaoPomar.data) {
                        angular.forEach(this.listaOpcoesPolinizacaoPomar.data, (polinizacaoPomar) => {
                            if ((typeof polinizacaoPomar.monitoramentosFloracao !== 'undefined') && polinizacaoPomar.monitoramentosFloracao) {
                                angular.forEach(polinizacaoPomar.monitoramentosFloracao, (item) => {
                                    if ((typeof item.dataColeta !== 'undefined') && item.dataColeta) {
                                        if (typeof item.dataColeta === 'string') {
                                            let data = new Date(item.dataColeta);
                                            if (ctrl.listaAnos.indexOf(data.getFullYear()) === -1) {
                                                ctrl.listaAnos.push(data.getFullYear());
                                            }
                                        }
                                        else {
                                            if (ctrl.listaAnos.indexOf(item.dataColeta.getFullYear()) === -1) {
                                                ctrl.listaAnos.push(item.dataColeta.getFullYear());
                                            }
                                        }
                                    }
                                    if ((typeof item.dataIsolamento !== 'undefined') && item.dataIsolamento) {
                                        if (typeof item.dataIsolamento === 'string') {
                                            let data = new Date(item.dataIsolamento);
                                            if (ctrl.listaAnos.indexOf(data.getFullYear()) === -1) {
                                                ctrl.listaAnos.push(data.getFullYear());
                                            }
                                        }
                                        else {
                                            if (ctrl.listaAnos.indexOf(item.dataIsolamento.getFullYear()) === -1) {
                                                ctrl.listaAnos.push(item.dataIsolamento.getFullYear());
                                            }
                                        }
                                    }
                                });
                            }
                            if ((typeof polinizacaoPomar.monitoramentosFloracaoFeminino !== 'undefined') && polinizacaoPomar.monitoramentosFloracaoFeminino) {
                                angular.forEach(polinizacaoPomar.monitoramentosFloracaoFeminino, (item) => {
                                    if ((typeof item.dataColeta !== 'undefined') && item.dataColeta) {
                                        if (typeof item.dataColeta === 'string') {
                                            let data = new Date(item.dataColeta);
                                            if (ctrl.listaAnos.indexOf(data.getFullYear()) === -1) {
                                                ctrl.listaAnos.push(data.getFullYear());
                                            }
                                        }
                                        else {
                                            if (ctrl.listaAnos.indexOf(item.dataColeta.getFullYear()) === -1) {
                                                ctrl.listaAnos.push(item.dataColeta.getFullYear());
                                            }
                                        }
                                    }
                                    if ((typeof item.dataIsolamento !== 'undefined') && item.dataIsolamento) {
                                        if (typeof item.dataIsolamento === 'string') {
                                            let data = new Date(item.dataIsolamento);
                                            if (ctrl.listaAnos.indexOf(data.getFullYear()) === -1) {
                                                ctrl.listaAnos.push(data.getFullYear());
                                            }
                                        }
                                        else {
                                            if (ctrl.listaAnos.indexOf(item.dataIsolamento.getFullYear()) === -1) {
                                                ctrl.listaAnos.push(item.dataIsolamento.getFullYear());
                                            }
                                        }
                                    }
                                });
                            }
                            if ((typeof polinizacaoPomar.monitoramentosFloracaoMasculino !== 'undefined') && polinizacaoPomar.monitoramentosFloracaoMasculino) {
                                angular.forEach(polinizacaoPomar.monitoramentosFloracaoMasculino, (item) => {
                                    if ((typeof item.dataColeta !== 'undefined') && item.dataColeta) {
                                        if (typeof item.dataColeta === 'string') {
                                            let data = new Date(item.dataColeta);
                                            if (ctrl.listaAnos.indexOf(data.getFullYear()) === -1) {
                                                ctrl.listaAnos.push(data.getFullYear());
                                            }
                                        }
                                        else {
                                            if (ctrl.listaAnos.indexOf(item.dataColeta.getFullYear()) === -1) {
                                                ctrl.listaAnos.push(item.dataColeta.getFullYear());
                                            }
                                        }
                                    }
                                    if ((typeof item.dataIsolamento !== 'undefined') && item.dataIsolamento) {
                                        if (typeof item.dataIsolamento === 'string') {
                                            let data = new Date(item.dataIsolamento);
                                            if (ctrl.listaAnos.indexOf(data.getFullYear()) === -1) {
                                                ctrl.listaAnos.push(data.getFullYear());
                                            }
                                        }
                                        else {
                                            if (ctrl.listaAnos.indexOf(item.dataIsolamento.getFullYear()) === -1) {
                                                ctrl.listaAnos.push(item.dataIsolamento.getFullYear());
                                            }
                                        }
                                    }
                                });
                            }
                            if ((typeof polinizacaoPomar.polinizacoes !== 'undefined') && polinizacaoPomar.polinizacoes) {
                                angular.forEach(polinizacaoPomar.polinizacoes, (item) => {
                                    if ((typeof item.dataInicial !== 'undefined') && item.dataInicial) {
                                        if (typeof item.dataInicial === 'string') {
                                            let data = new Date(item.dataInicial);
                                            if (ctrl.listaAnos.indexOf(data.getFullYear()) === -1) {
                                                ctrl.listaAnos.push(data.getFullYear());
                                            }
                                        }
                                        else {
                                            if (ctrl.listaAnos.indexOf(item.dataInicial.getFullYear()) === -1) {
                                                ctrl.listaAnos.push(item.dataInicial.getFullYear());
                                            }
                                        }
                                    }
                                    if ((typeof item.dataFinal !== 'undefined') && item.dataFinal) {
                                        if (typeof item.dataFinal === 'string') {
                                            let data = new Date(item.dataFinal);
                                            if (ctrl.listaAnos.indexOf(data.getFullYear()) === -1) {
                                                ctrl.listaAnos.push(data.getFullYear());
                                            }
                                        }
                                        else {
                                            if (ctrl.listaAnos.indexOf(item.dataFinal.getFullYear()) === -1) {
                                                ctrl.listaAnos.push(item.dataFinal.getFullYear());
                                            }
                                        }
                                    }
                                    if ((typeof item.data !== 'undefined') && item.data) {
                                        if (typeof item.data === 'string') {
                                            let data = new Date(item.data);
                                            if (ctrl.listaAnos.indexOf(data.getFullYear()) === -1) {
                                                ctrl.listaAnos.push(data.getFullYear());
                                            }
                                        }
                                        else {
                                            if (ctrl.listaAnos.indexOf(item.data.getFullYear()) === -1) {
                                                ctrl.listaAnos.push(item.data.getFullYear());
                                            }
                                        }
                                    }
                                });
                            }
                            if ((typeof polinizacaoPomar.coletasConesSementes !== 'undefined') && polinizacaoPomar.coletasConesSementes) {
                                angular.forEach(polinizacaoPomar.coletasConesSementes, (item) => {
                                    if ((typeof item.data !== 'undefined') && item.data) {
                                        if (typeof item.data === 'string') {
                                            let data = new Date(item.data);
                                            if (ctrl.listaAnos.indexOf(data.getFullYear()) === -1) {
                                                ctrl.listaAnos.push(data.getFullYear());
                                            }
                                        }
                                        else {
                                            if (ctrl.listaAnos.indexOf(item.data.getFullYear()) === -1) {
                                                ctrl.listaAnos.push(item.data.getFullYear());
                                            }
                                        }
                                    }
                                });
                            }
                        });
                    }
                    if (this.listaAnos && (this.listaAnos.length > 0)) {
                        let dataAtual = new Date();
                        if (this.listaAnos.indexOf(dataAtual.getFullYear()) === -1) {
                            this.listaAnos.push(dataAtual.getFullYear());
                        }
                    }
                    else {
                        this.carregarTodosAnos();
                    }
                }
                yield this.alterarFiltroRelatorioPomar();
                this.modalRelatorioPomar = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalRelatorioPomar() {
        this.modalRelatorioPomar = false;
        this.relatorioPomarSelecionado = null;
        this.relatorioPomarVisualizar = null;
        angular.element('body').css('overflow-y', '');
        this.carregarTodosAnos();
        globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
        this.scopeApply(() => {
            setTimeout(() => {
                angular.element('body').css('overflow-y', '');
                if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                    (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                    angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                }
            }, 10);
        });
    }
    alterarFiltroRelatorioPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            if ((typeof this.relatorioPomarSelecionado === 'undefined') || !this.relatorioPomarSelecionado) {
                return;
            }
            if ((this.relatorioPomarSelecionado.tipoRelatorio === TipoRelatorioPomar_1.TipoRelatorioPomar.ENXERTIA_PINUS) ||
                (this.relatorioPomarSelecionado.tipoRelatorio === TipoRelatorioPomar_1.TipoRelatorioPomar.ENXERTIA_EUCALYPTUS)) {
                this.relatorioPomarSelecionado.portaEnxerto = new RelatorioEnxertiaPomar_1.RelatorioEnxertiaPomar();
                this.relatorioPomarSelecionado.enxertoAno1 = new RelatorioEnxertiaPomar_1.RelatorioEnxertiaPomar();
                this.relatorioPomarSelecionado.enxertoAno2 = new RelatorioEnxertiaPomar_1.RelatorioEnxertiaPomar();
                this.relatorioPomarSelecionado.enxertoAno3 = new RelatorioEnxertiaPomar_1.RelatorioEnxertiaPomar();
                if ((typeof this.listaOpcoesEnxertiaPomar !== 'undefined') && this.listaOpcoesEnxertiaPomar &&
                    (typeof this.listaOpcoesEnxertiaPomar.data !== 'undefined') && this.listaOpcoesEnxertiaPomar.data) {
                    angular.forEach(this.listaOpcoesEnxertiaPomar.data, (controleEnxertiaPomar) => {
                        if ((typeof controleEnxertiaPomar.posicoesRealizadas === 'undefined') || !controleEnxertiaPomar.posicoesRealizadas) {
                            controleEnxertiaPomar.posicoesRealizadas = 0;
                        }
                        if ((typeof controleEnxertiaPomar.sobrevivencia90dias === 'undefined') || !controleEnxertiaPomar.sobrevivencia90dias) {
                            controleEnxertiaPomar.sobrevivencia90dias = 0;
                        }
                        if ((typeof controleEnxertiaPomar.sobrevivencia60dias === 'undefined') || !controleEnxertiaPomar.sobrevivencia60dias) {
                            controleEnxertiaPomar.sobrevivencia60dias = 0;
                        }
                        if ((typeof controleEnxertiaPomar.sobrevivencia === 'undefined') || !controleEnxertiaPomar.sobrevivencia) {
                            controleEnxertiaPomar.sobrevivencia = 0;
                        }
                        if ((typeof controleEnxertiaPomar.plantio === 'undefined') || !controleEnxertiaPomar.plantio) {
                            controleEnxertiaPomar.plantio = 0;
                        }
                        if ((typeof controleEnxertiaPomar.replantio === 'undefined') || !controleEnxertiaPomar.replantio) {
                            controleEnxertiaPomar.replantio = 0;
                        }
                        if ((typeof controleEnxertiaPomar.tipoEnxerto !== 'undefined') && controleEnxertiaPomar.tipoEnxerto) {
                            let enxertoRelatorio = null;
                            if (controleEnxertiaPomar.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.PORTA_ENXERTO) {
                                enxertoRelatorio = ctrl.relatorioPomarSelecionado.portaEnxerto;
                            }
                            else if (controleEnxertiaPomar.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_1) {
                                enxertoRelatorio = ctrl.relatorioPomarSelecionado.enxertoAno1;
                            }
                            else if (controleEnxertiaPomar.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_2) {
                                enxertoRelatorio = ctrl.relatorioPomarSelecionado.enxertoAno2;
                            }
                            else if (controleEnxertiaPomar.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_3) {
                                enxertoRelatorio = ctrl.relatorioPomarSelecionado.enxertoAno3;
                            }
                            if (enxertoRelatorio) {
                                enxertoRelatorio.numTotalPosicoes = ((typeof enxertoRelatorio.numTotalPosicoes !== 'undefined') && enxertoRelatorio.numTotalPosicoes ? enxertoRelatorio.numTotalPosicoes : 0) + 1;
                                enxertoRelatorio.numTotalMudas = ((typeof enxertoRelatorio.numTotalMudas !== 'undefined') && enxertoRelatorio.numTotalMudas ? enxertoRelatorio.numTotalMudas : 0) +
                                    ((controleEnxertiaPomar.posicoesRealizadas > 0) ? controleEnxertiaPomar.posicoesRealizadas :
                                        ((controleEnxertiaPomar.sobrevivencia90dias > 0) ? controleEnxertiaPomar.sobrevivencia90dias :
                                            ((controleEnxertiaPomar.sobrevivencia60dias > 0) ? controleEnxertiaPomar.sobrevivencia60dias :
                                                ((controleEnxertiaPomar.sobrevivencia > 0) ? controleEnxertiaPomar.sobrevivencia :
                                                    ((controleEnxertiaPomar.plantio > 0) ? controleEnxertiaPomar.plantio :
                                                        ((controleEnxertiaPomar.replantio > 0) ? controleEnxertiaPomar.replantio : 1))))));
                                if (controleEnxertiaPomar.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.PORTA_ENXERTO) {
                                    enxertoRelatorio.numTotalReplantio = ((typeof enxertoRelatorio.numTotalReplantio !== 'undefined') && enxertoRelatorio.numTotalReplantio ? enxertoRelatorio.numTotalReplantio : 0) +
                                        ((controleEnxertiaPomar.replantio > 0) ? controleEnxertiaPomar.replantio : 0);
                                }
                                else {
                                    enxertoRelatorio.numTotalReplantio = ((typeof enxertoRelatorio.numTotalReplantio !== 'undefined') && enxertoRelatorio.numTotalReplantio ? enxertoRelatorio.numTotalReplantio : 0) +
                                        ((controleEnxertiaPomar.plantio > 0) ? controleEnxertiaPomar.plantio : 3) -
                                        ((controleEnxertiaPomar.sobrevivencia90dias > 0) ? controleEnxertiaPomar.sobrevivencia90dias :
                                            ((controleEnxertiaPomar.sobrevivencia60dias > 0) ? controleEnxertiaPomar.sobrevivencia60dias :
                                                ((controleEnxertiaPomar.sobrevivencia > 0) ? controleEnxertiaPomar.sobrevivencia : 0)));
                                }
                                if ((typeof enxertoRelatorio.numTotalMudas === 'undefined') || !enxertoRelatorio.numTotalMudas) {
                                    enxertoRelatorio.numTotalMudas = 0;
                                }
                                if ((typeof enxertoRelatorio.numTotalReplantio === 'undefined') || !enxertoRelatorio.numTotalReplantio) {
                                    enxertoRelatorio.numTotalReplantio = 0;
                                }
                                enxertoRelatorio.numTotalPegamento = enxertoRelatorio.numTotalMudas - enxertoRelatorio.numTotalReplantio;
                                if (enxertoRelatorio.numTotalPegamento < 0) {
                                    enxertoRelatorio.numTotalPegamento = 0;
                                }
                                if (enxertoRelatorio.numTotalMudas > 0) {
                                    enxertoRelatorio.porcentagemPegamento = Math.round(enxertoRelatorio.numTotalPegamento * 10000 / enxertoRelatorio.numTotalMudas) / 100;
                                }
                                enxertoRelatorio.numReenxertiaProximoAno = (((typeof enxertoRelatorio.numReenxertiaProximoAno !== 'undefined') && enxertoRelatorio.numReenxertiaProximoAno) ? enxertoRelatorio.numReenxertiaProximoAno : 0) +
                                    ((controleEnxertiaPomar.sobrevivencia90dias > 0) ? controleEnxertiaPomar.sobrevivencia90dias :
                                        ((controleEnxertiaPomar.sobrevivencia60dias > 0) ? controleEnxertiaPomar.sobrevivencia60dias :
                                            ((controleEnxertiaPomar.sobrevivencia > 0) ? controleEnxertiaPomar.sobrevivencia :
                                                ((controleEnxertiaPomar.plantio > 0) ? controleEnxertiaPomar.plantio : 0))));
                            }
                        }
                    });
                }
            }
            if ((this.relatorioPomarSelecionado.tipoRelatorio === TipoRelatorioPomar_1.TipoRelatorioPomar.POMARES_SEMENTES) ||
                (this.relatorioPomarSelecionado.tipoRelatorio === TipoRelatorioPomar_1.TipoRelatorioPomar.BANCOS_CONSERVACAO)) {
                this.relatorioPomarSelecionado.pomaresSementes = new RelatorioPomaresSementesBancosConservacao_1.RelatorioPomaresSementesBancosConservacao();
                this.relatorioPomarSelecionado.bancosConservacao = new RelatorioPomaresSementesBancosConservacao_1.RelatorioPomaresSementesBancosConservacao();
                if ((typeof this.listaPomares !== 'undefined') && this.listaPomares) {
                    angular.forEach(this.listaPomares, (pomar) => {
                        let ehBancoConservacao = false;
                        let valido = false;
                        if (pomar) {
                            valido = true;
                            if ((typeof pomar.tipoSigla !== 'undefined') && pomar.tipoSigla) {
                                if (pomar.tipoSigla.trim().substring(0, 1).toUpperCase() === 'B') {
                                    ehBancoConservacao = true;
                                }
                            }
                            else if ((typeof pomar.tipoDescricao !== 'undefined') && pomar.tipoDescricao) {
                                if (pomar.tipoDescricao.trim().substring(0, 1).toUpperCase() === 'B') {
                                    ehBancoConservacao = true;
                                }
                            }
                            if ((typeof ctrl.relatorioPomarSelecionado.tipoRelatorio !== 'undefined') &&
                                (ctrl.relatorioPomarSelecionado.tipoRelatorio === TipoRelatorioPomar_1.TipoRelatorioPomar.BANCOS_CONSERVACAO)) {
                                valido = valido && ehBancoConservacao ? true : false;
                            }
                            else {
                                valido = valido && !ehBancoConservacao ? true : false;
                            }
                        }
                        if (valido) {
                            ctrl.relatorioPomarSelecionado.pomaresSementes.numTotalPomares++;
                            ctrl.relatorioPomarSelecionado.bancosConservacao.numTotalBancos++;
                            if (pomar.area && (pomar.area > 0)) {
                                ctrl.relatorioPomarSelecionado.pomaresSementes.areaTotalPomares += pomar.area;
                                ctrl.relatorioPomarSelecionado.bancosConservacao.areaTotalBancos += pomar.area;
                            }
                            else if (pomar.talhao && pomar.talhao.areaTalhao && (pomar.talhao.areaTalhao > 0)) {
                                ctrl.relatorioPomarSelecionado.pomaresSementes.areaTotalPomares += pomar.talhao.areaTalhao;
                                ctrl.relatorioPomarSelecionado.bancosConservacao.areaTotalBancos += pomar.talhao.areaTalhao;
                            }
                            ctrl.relatorioPomarSelecionado.pomaresSementes.areaTotalPomares = NumeroUtil_1.NumeroUtil.arredondarNumero(ctrl.relatorioPomarSelecionado.pomaresSementes.areaTotalPomares, 2);
                            ctrl.relatorioPomarSelecionado.bancosConservacao.areaTotalBancos = NumeroUtil_1.NumeroUtil.arredondarNumero(ctrl.relatorioPomarSelecionado.bancosConservacao.areaTotalBancos, 2);
                            if ((typeof pomar.geracaoDescricao !== 'undefined') && pomar.geracaoDescricao) {
                                if (pomar.geracaoDescricao.indexOf('1,5') !== -1) {
                                    ctrl.relatorioPomarSelecionado.pomaresSementes.numTotalPomaresGeracao15++;
                                    ctrl.relatorioPomarSelecionado.bancosConservacao.numTotalBancosGeracao15++;
                                }
                                else if (pomar.geracaoDescricao.indexOf('2,5') !== -1) {
                                    ctrl.relatorioPomarSelecionado.pomaresSementes.numTotalPomaresGeracao25++;
                                    ctrl.relatorioPomarSelecionado.bancosConservacao.numTotalBancosGeracao25++;
                                }
                                else if (pomar.geracaoDescricao.indexOf('1') !== -1) {
                                    ctrl.relatorioPomarSelecionado.pomaresSementes.numTotalPomaresGeracao1++;
                                    ctrl.relatorioPomarSelecionado.bancosConservacao.numTotalBancosGeracao1++;
                                }
                                else if (pomar.geracaoDescricao.indexOf('2') !== -1) {
                                    ctrl.relatorioPomarSelecionado.pomaresSementes.numTotalPomaresGeracao2++;
                                    ctrl.relatorioPomarSelecionado.bancosConservacao.numTotalBancosGeracao2++;
                                }
                                else if (pomar.geracaoDescricao.indexOf('3') !== -1) {
                                    ctrl.relatorioPomarSelecionado.pomaresSementes.numTotalPomaresGeracao3++;
                                    ctrl.relatorioPomarSelecionado.bancosConservacao.numTotalBancosGeracao3++;
                                }
                            }
                            if (ehBancoConservacao) {
                                ctrl.relatorioPomarSelecionado.bancosConservacao.numTotalFamilias = 148;
                                ctrl.relatorioPomarSelecionado.bancosConservacao.numTotalFamiliasGeracao1 = 27;
                                ctrl.relatorioPomarSelecionado.bancosConservacao.numTotalFamiliasGeracao15 = 5;
                                ctrl.relatorioPomarSelecionado.bancosConservacao.numTotalFamiliasGeracao2 = 16;
                                ctrl.relatorioPomarSelecionado.bancosConservacao.numTotalFamiliasGeracao25 = 0;
                                ctrl.relatorioPomarSelecionado.bancosConservacao.numTotalFamiliasGeracao3 = 0;
                                if (ctrl.relatorioPomarSelecionado.bancosConservacao.numTotalBancos > 0) {
                                    angular.forEach(['EucalyptusDunnii', 'EucalyptusBenthamii', 'EucalyptusOutros',
                                        'PinusTaeda', 'PinusElliottii', 'PinusOutros'
                                    ], (especie) => {
                                        ctrl.relatorioPomarSelecionado.bancosConservacao['areaBancos' + especie] = NumeroUtil_1.NumeroUtil.arredondarNumero(ctrl.relatorioPomarSelecionado.bancosConservacao.areaTotalBancos
                                            * ctrl.relatorioPomarSelecionado.bancosConservacao['numBancos' + especie]
                                            / ctrl.relatorioPomarSelecionado.bancosConservacao.numTotalBancos, 2);
                                    });
                                }
                            }
                            else {
                                if (ctrl.relatorioPomarSelecionado.pomaresSementes.numTotalPomares > 0) {
                                    angular.forEach(['EucalyptusDunnii', 'EucalyptusBenthamii', 'EucalyptusOutros',
                                        'PinusTaeda', 'PinusElliottii', 'PinusOutros'
                                    ], (especie) => {
                                        ctrl.relatorioPomarSelecionado.pomaresSementes['areaPomares' + especie] = NumeroUtil_1.NumeroUtil.arredondarNumero(ctrl.relatorioPomarSelecionado.pomaresSementes.areaTotalPomares
                                            * ctrl.relatorioPomarSelecionado.pomaresSementes['numPomares' + especie]
                                            / ctrl.relatorioPomarSelecionado.pomaresSementes.numTotalPomares, 2);
                                    });
                                }
                            }
                        }
                    });
                }
            }
            if (this.relatorioPomarSelecionado.tipoRelatorio === TipoRelatorioPomar_1.TipoRelatorioPomar.POLINIZACAO_PINUS) {
                this.relatorioPomarSelecionado.polinizacaoColetaSementes = new RelatorioPolinizacaoColetaSementes_1.RelatorioPolinizacaoColetaSementes();
                if ((typeof ctrl.relatorioPomarSelecionado.filtroAno !== 'undefined') && ctrl.relatorioPomarSelecionado.filtroAno) {
                }
                this.listaOpcoesRelatorioPolinizacaoPinusPomar = yield this.dadosListaRelatorioPolinizacaoPinusPomar();
                if ((typeof this.grafico1PomarPolinizacaoPinus !== 'undefined') && this.grafico1PomarPolinizacaoPinus) {
                    this.grafico1PomarPolinizacaoPinus.destroy();
                    this.grafico1PomarPolinizacaoPinus = null;
                }
                for (let cargaGrafico = 1; cargaGrafico <= 10; cargaGrafico++) {
                    setTimeout((ctrl) => {
                        if (ctrl.grafico1PomarPolinizacaoPinus) {
                            return;
                        }
                        const grafico1PomarPolinizacaoPinusEl = document.getElementById('grafico1PomarPolinizacaoPinus');
                        if ((typeof grafico1PomarPolinizacaoPinusEl === 'undefined') || !grafico1PomarPolinizacaoPinusEl) {
                            return;
                        }
                        let numCruzamentosRealizados = 0;
                        let numCruzamentosColetados = 0;
                        const grafico1PomarPolinizacaoPinusCtx = grafico1PomarPolinizacaoPinusEl.getContext('2d');
                        const grafico1PomarPolinizacaoPinusDataSets = [{
                                label: '',
                                data: [numCruzamentosRealizados, numCruzamentosColetados],
                                backgroundColor: 'rgb(230,121,47)',
                                borderWidth: 1,
                                datalabels: {
                                    display: true,
                                    font: { size: 11 },
                                    align: 'top',
                                    padding: { left: 3, right: 3 },
                                    backgroundColor: 'rgba(255,255,255,.3)',
                                    textShadowBlur: 2,
                                    textShadowColor: '#fff'
                                }
                            }];
                        ctrl.grafico1PomarPolinizacaoPinus = new chart_js_1.Chart(grafico1PomarPolinizacaoPinusCtx, {
                            type: 'bar',
                            data: {
                                labels: [
                                    ['Cruzamentos', 'realizados'],
                                    ['Cruzamentos', 'coletados']
                                ],
                                datasets: grafico1PomarPolinizacaoPinusDataSets
                            },
                            plugins: [globalThis.ChartDataLabels],
                            options: {
                                responsive: true,
                                legend: {
                                    display: false
                                },
                                layout: {
                                    padding: {
                                        top: 30
                                    }
                                },
                                scales: {
                                    gridLines: { offsetGridLines: true },
                                    yAxes: [{
                                            ticks: {
                                                beginAtZero: true
                                            }
                                        }]
                                }
                            }
                        });
                    }, cargaGrafico * 1000, this);
                }
                if ((typeof this.grafico2PomarPolinizacaoPinus !== 'undefined') && this.grafico2PomarPolinizacaoPinus) {
                    this.grafico2PomarPolinizacaoPinus.destroy();
                    this.grafico2PomarPolinizacaoPinus = null;
                }
                for (let cargaGrafico = 1; cargaGrafico <= 10; cargaGrafico++) {
                    setTimeout((ctrl) => {
                        if (ctrl.grafico2PomarPolinizacaoPinus) {
                            return;
                        }
                        const grafico2PomarPolinizacaoPinusEl = document.getElementById('grafico2PomarPolinizacaoPinus');
                        if ((typeof grafico2PomarPolinizacaoPinusEl === 'undefined') || !grafico2PomarPolinizacaoPinusEl) {
                            return;
                        }
                        let numCruzamentosPlanejados = 0;
                        let numPolenColetados = 0;
                        let numCruzamentosRealizados = 0;
                        let numCruzamentosFaltantes = 0;
                        let numIsolamentosRealizados = 0;
                        let numCruzamentosColetados = 0;
                        const grafico2PomarPolinizacaoPinusCtx = grafico2PomarPolinizacaoPinusEl.getContext('2d');
                        const grafico2PomarPolinizacaoPinusDataSets = [{
                                label: '',
                                data: [
                                    numCruzamentosPlanejados,
                                    numPolenColetados,
                                    numCruzamentosRealizados,
                                    numCruzamentosFaltantes,
                                    numIsolamentosRealizados,
                                    numCruzamentosColetados
                                ],
                                backgroundColor: [
                                    'rgb(98,163,78)',
                                    'rgb(98,163,78)',
                                    'rgb(98,163,78)',
                                    'rgb(230,121,47)',
                                    'rgb(98,163,78)',
                                    'rgb(135,71,28)'
                                ],
                                borderWidth: 1,
                                datalabels: {
                                    display: true,
                                    font: { size: 11 },
                                    align: 'right',
                                    padding: { left: 3, right: 3 },
                                    backgroundColor: 'rgba(255,255,255,.3)',
                                    textShadowBlur: 2,
                                    textShadowColor: '#fff'
                                }
                            }];
                        ctrl.grafico2PomarPolinizacaoPinus = new chart_js_1.Chart(grafico2PomarPolinizacaoPinusCtx, {
                            type: 'horizontalBar',
                            data: {
                                labels: [
                                    'Nº cruzamentos planejados',
                                    'Nº pólen já coletados',
                                    'Nº cruzamentos realizados',
                                    'Nº cruzamentos faltantes',
                                    'Nº isolamentos realizados',
                                    'Cruzamentos coletados'
                                ],
                                datasets: grafico2PomarPolinizacaoPinusDataSets
                            },
                            plugins: [globalThis.ChartDataLabels],
                            options: {
                                responsive: true,
                                legend: {
                                    display: false
                                },
                                layout: {
                                    padding: {
                                        right: 30
                                    }
                                },
                                scales: {
                                    gridLines: { offsetGridLines: true },
                                    xAxes: [{
                                            ticks: {
                                                beginAtZero: true
                                            }
                                        }]
                                }
                            }
                        });
                    }, cargaGrafico * 1000, this);
                }
            }
            if (this.relatorioPomarSelecionado.tipoRelatorio === TipoRelatorioPomar_1.TipoRelatorioPomar.POLINIZACAO_EUCALYPTUS) {
                this.relatorioPomarSelecionado.polinizacaoColetaSementes = new RelatorioPolinizacaoColetaSementes_1.RelatorioPolinizacaoColetaSementes();
                if ((typeof ctrl.relatorioPomarSelecionado.filtroAno !== 'undefined') && ctrl.relatorioPomarSelecionado.filtroAno) {
                }
                this.listaOpcoesRelatorioPolinizacaoEucalyptusPomar = yield this.dadosListaRelatorioPolinizacaoEucalyptusPomar();
                if ((typeof this.grafico1PomarPolinizacaoEucalyptus !== 'undefined') && this.grafico1PomarPolinizacaoEucalyptus) {
                    this.grafico1PomarPolinizacaoEucalyptus.destroy();
                    this.grafico1PomarPolinizacaoEucalyptus = null;
                }
                for (let cargaGrafico = 1; cargaGrafico <= 10; cargaGrafico++) {
                    setTimeout((ctrl) => {
                        if (ctrl.grafico1PomarPolinizacaoEucalyptus) {
                            return;
                        }
                        const grafico1PomarPolinizacaoEucalyptusEl = document.getElementById('grafico1PomarPolinizacaoEucalyptus');
                        if ((typeof grafico1PomarPolinizacaoEucalyptusEl === 'undefined') || !grafico1PomarPolinizacaoEucalyptusEl) {
                            return;
                        }
                        let numCruzamentosRealizados = 0;
                        let numCruzamentosColetados = 0;
                        const grafico1PomarPolinizacaoEucalyptusCtx = grafico1PomarPolinizacaoEucalyptusEl.getContext('2d');
                        const grafico1PomarPolinizacaoEucalyptusDataSets = [{
                                label: '',
                                data: [numCruzamentosRealizados, numCruzamentosColetados],
                                backgroundColor: 'rgb(230,121,47)',
                                borderWidth: 1,
                                datalabels: {
                                    display: true,
                                    font: { size: 11 },
                                    align: 'top',
                                    padding: { left: 3, right: 3 },
                                    backgroundColor: 'rgba(255,255,255,.3)',
                                    textShadowBlur: 2,
                                    textShadowColor: '#fff'
                                }
                            }];
                        ctrl.grafico1PomarPolinizacaoEucalyptus = new chart_js_1.Chart(grafico1PomarPolinizacaoEucalyptusCtx, {
                            type: 'bar',
                            data: {
                                labels: [
                                    ['Cruzamentos', 'realizados'],
                                    ['Cruzamentos', 'coletados']
                                ],
                                datasets: grafico1PomarPolinizacaoEucalyptusDataSets
                            },
                            plugins: [globalThis.ChartDataLabels],
                            options: {
                                responsive: true,
                                legend: {
                                    display: false
                                },
                                layout: {
                                    padding: {
                                        top: 30
                                    }
                                },
                                scales: {
                                    gridLines: { offsetGridLines: true },
                                    yAxes: [{
                                            ticks: {
                                                beginAtZero: true
                                            }
                                        }]
                                }
                            }
                        });
                    }, cargaGrafico * 1000, this);
                }
                if ((typeof this.grafico2PomarPolinizacaoEucalyptus !== 'undefined') && this.grafico2PomarPolinizacaoEucalyptus) {
                    this.grafico2PomarPolinizacaoEucalyptus.destroy();
                    this.grafico2PomarPolinizacaoEucalyptus = null;
                }
                for (let cargaGrafico = 1; cargaGrafico <= 10; cargaGrafico++) {
                    setTimeout((ctrl) => {
                        if (ctrl.grafico2PomarPolinizacaoEucalyptus) {
                            return;
                        }
                        const grafico2PomarPolinizacaoEucalyptusEl = document.getElementById('grafico2PomarPolinizacaoEucalyptus');
                        if ((typeof grafico2PomarPolinizacaoEucalyptusEl === 'undefined') || !grafico2PomarPolinizacaoEucalyptusEl) {
                            return;
                        }
                        let numCruzamentosPlanejados = 0;
                        let numPolenColetados = 0;
                        let numCruzamentosRealizados = 0;
                        let numCruzamentosFaltantes = 0;
                        let numCruzamentosColetados = 0;
                        const grafico2PomarPolinizacaoEucalyptusCtx = grafico2PomarPolinizacaoEucalyptusEl.getContext('2d');
                        const grafico2PomarPolinizacaoEucalyptusDataSets = [{
                                label: '',
                                data: [
                                    numCruzamentosPlanejados,
                                    numPolenColetados,
                                    numCruzamentosRealizados,
                                    numCruzamentosFaltantes,
                                    numCruzamentosColetados
                                ],
                                backgroundColor: [
                                    'rgb(98,163,78)',
                                    'rgb(98,163,78)',
                                    'rgb(98,163,78)',
                                    'rgb(230,121,47)',
                                    'rgb(135,71,28)'
                                ],
                                borderWidth: 1,
                                datalabels: {
                                    display: true,
                                    font: { size: 11 },
                                    align: 'right',
                                    padding: { left: 3, right: 3 },
                                    backgroundColor: 'rgba(255,255,255,.3)',
                                    textShadowBlur: 2,
                                    textShadowColor: '#fff'
                                }
                            }];
                        ctrl.grafico2PomarPolinizacaoEucalyptus = new chart_js_1.Chart(grafico2PomarPolinizacaoEucalyptusCtx, {
                            type: 'horizontalBar',
                            data: {
                                labels: [
                                    'Nº cruzamentos planejados',
                                    'Nº pólen já coletados',
                                    'Nº cruzamentos realizados',
                                    'Nº cruzamentos faltantes',
                                    'Cruzamentos coletados'
                                ],
                                datasets: grafico2PomarPolinizacaoEucalyptusDataSets
                            },
                            plugins: [globalThis.ChartDataLabels],
                            options: {
                                responsive: true,
                                legend: {
                                    display: false
                                },
                                layout: {
                                    padding: {
                                        right: 30
                                    }
                                },
                                scales: {
                                    gridLines: { offsetGridLines: true },
                                    xAxes: [{
                                            ticks: {
                                                beginAtZero: true
                                            }
                                        }]
                                }
                            }
                        });
                    }, cargaGrafico * 1000, this);
                }
            }
            this.scopeApply();
        });
    }
    salvarAnexoPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if ((typeof this.anexoPomarSelecionado === 'undefined') || !this.anexoPomarSelecionado) {
                    return;
                }
                let listaCamposObrigatorios = '';
                if ((typeof this.anexoPomarSelecionado.descricao === 'undefined') || !this.anexoPomarSelecionado.descricao) {
                    listaCamposObrigatorios += '\r\n - <b>Anexo Pomar</b>';
                }
                if (listaCamposObrigatorios && (listaCamposObrigatorios.length > 0)) {
                    let mensagem = 'Campo obrigatório não preenchido!' +
                        listaCamposObrigatorios + '\r\n \r\n' +
                        'Preencha este campo e tente novamente.';
                    if (this.ehCelular) {
                        alert(mensagem.replace(/<b>/g, '').replace(/<\/b>/g, ''));
                    }
                    else {
                        yield sweetalert2_1.default.fire({
                            html: mensagem.replace(/\r\n/g, '<br>\r\n'),
                            icon: 'info',
                            showCancelButton: false,
                            showConfirmButton: true,
                            confirmButtonText: 'Ok'
                        });
                    }
                    return;
                }
                this.modalTratamentoPomar = false;
                this.modalGerarCroquiPomar = false;
                this.modalHistoricoCroquiPomar = false;
                this.modalPlanejamentoPomar = false;
                this.modalControleManejoPomar = false;
                this.modalControleEnxertiaPomar = false;
                this.modalProdutividadePomar = false;
                this.modalFenologiaPomar = false;
                this.modalPolinizacaoPomar = false;
                this.modalRelatorioPomar = false;
                this.anexoPomarSelecionado.pomar = this.pomarSelecionado;
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.modal-dialog > .modal-content');
                if (!this.anexoPomarSelecionado.id) {
                    this.anexoPomarSelecionado = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANEXO_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANEXO_POMAR)
                        .criar(this.anexoPomarSelecionado);
                }
                else {
                    this.anexoPomarSelecionado = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANEXO_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANEXO_POMAR)
                        .atualizar(this.anexoPomarSelecionado);
                }
                if ((typeof this.anexoPomarSelecionado.pomar !== 'undefined') && this.anexoPomarSelecionado.pomar &&
                    (typeof this.anexoPomarSelecionado.pomar.descricao !== 'undefined') && this.anexoPomarSelecionado.pomar.descricao) {
                    this.anexoPomarSelecionado.pomarDescricao = this.anexoPomarSelecionado.pomar.descricao;
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (this.anexoPomarSelecionado && this.anexoPomarSelecionado.id) {
                    this.modalAnexoPomar = false;
                    if (this.ehCelular) {
                        alert(`Salvo com sucesso!`);
                    }
                    else {
                        sweetalert2_1.default.fire({
                            title: 'Salvo com sucesso!',
                            text: 'O lançamento foi salvo no banco de dados.',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'Ok'
                        });
                    }
                    this.listaOpcoesAnexosPomar = yield this.dadosListaAnexosPomar();
                }
                this.ocultarModalAnexoPomar();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    exibirModalAnexoPomar(dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.pomarSelecionado === 'undefined') || !this.pomarSelecionado) {
                return;
            }
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.anexoPomarVisualizar = true;
                if ((typeof dados !== 'undefined') && dados && (typeof dados.id !== 'undefined') && dados.id) {
                    this.anexoPomarSelecionado = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANEXO_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANEXO_POMAR)
                        .id(dados.id)
                        .ver();
                    if ((typeof this.anexoPomarSelecionado === 'undefined') || !this.anexoPomarSelecionado) {
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        if (this.ehCelular) {
                            alert(`Registro "${dados.id} não encontrado!`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                text: `Registro "${dados.id}" não encontrado.`,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        return;
                    }
                    if ((typeof this.anexoPomarSelecionado.criacao === 'string') && this.anexoPomarSelecionado.criacao) {
                        this.anexoPomarSelecionado.criacao = new Date(this.anexoPomarSelecionado.criacao);
                    }
                    if ((typeof this.anexoPomarSelecionado.dataHoraInclusao === 'string') && this.anexoPomarSelecionado.dataHoraInclusao) {
                        this.anexoPomarSelecionado.dataHoraInclusao = new Date(this.anexoPomarSelecionado.dataHoraInclusao);
                    }
                    if ((typeof this.anexoPomarSelecionado.dataHoraAlteracao === 'string') && this.anexoPomarSelecionado.dataHoraAlteracao) {
                        this.anexoPomarSelecionado.dataHoraAlteracao = new Date(this.anexoPomarSelecionado.dataHoraAlteracao);
                    }
                    if ((typeof dados.visualizar !== 'undefined') && dados.visualizar) {
                        this.anexoPomarVisualizar = true;
                    }
                    else if ((typeof dados.editar !== 'undefined') && dados.editar) {
                        this.anexoPomarVisualizar = false;
                    }
                    else if ((typeof dados.excluir !== 'undefined') && dados.excluir) {
                        const ctrl = this;
                        this.notificacao
                            .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                            .then((result) => __awaiter(this, void 0, void 0, function* () {
                            if (result.value) {
                                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.ANEXO_POMAR)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.ANEXO_POMAR)
                                    .id(dados.id)
                                    .remover();
                                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                                ctrl.listaOpcoesAnexosPomar = yield ctrl.dadosListaAnexosPomar();
                                ctrl.scopeApply();
                            }
                        }));
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                }
                else {
                    this.anexoPomarSelecionado = new AnexoPomar_1.AnexoPomar();
                    this.anexoPomarSelecionado.status = true;
                    this.anexoPomarSelecionado.criacao = new Date();
                    this.anexoPomarSelecionado.criacao.setMilliseconds(0);
                    this.anexoPomarSelecionado.dataHoraInclusao = new Date();
                    this.anexoPomarSelecionado.dataHoraInclusao.setMilliseconds(0);
                    this.anexoPomarSelecionado.usuarioInclusao = this.usuarioSimplificado();
                    this.anexoPomarVisualizar = false;
                }
                if ((typeof this.anexoPomarSelecionado.anexos === 'undefined') || (this.anexoPomarSelecionado.anexos === null)) {
                    this.anexoPomarSelecionado.anexos = new Array();
                }
                this.listasFotos.carregarDados(this.sincronia, { anexoPomar: this.anexoPomarSelecionado });
                this.modalAnexoPomar = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalAnexoPomar() {
        this.modalAnexoPomar = false;
        angular.element('body').css('overflow-y', '');
        globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
        this.scopeApply(() => {
            setTimeout(() => {
                angular.element('body').css('overflow-y', '');
                if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                    (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                    angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                }
            }, 10);
        });
    }
    tirarFotoControleEnxertiaPomar(ehGaleria = false) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const foto = yield DispositivoUtil_1.DispositivoUtil.tirarFoto((typeof ehGaleria !== 'undefined') ? ehGaleria : false);
                const fotoId = ArmazenamentoUtil_1.ArmazenamentoUtil.uuid();
                this.listasFotos.fotosCamera.push({
                    uuid: fotoId,
                    referencia: 'fotosControleEnxertiaPomarSelecionado',
                    indiceIndicador: null,
                    indiceCampoIndicador: null,
                    indiceCampoCabecalho: null,
                    indiceCampoRodape: null,
                    controleManejoPomar: false,
                    controleEnxertiaPomar: true,
                    anexoPomar: false,
                    foto: foto
                });
                this.listasFotos.salvarFoto(fotoId);
                this.scopeApply();
            }
            catch (ex) {
                alert(ex.message);
            }
        });
    }
    removerFotoControleEnxertiaPomar(fotoCamera) {
        return __awaiter(this, void 0, void 0, function* () {
            if (confirm(`Deseja realmente remover essa foto?`)) {
                this.listasFotos.removerFoto(fotoCamera.uuid);
                this.scopeApply();
            }
        });
    }
    tirarFotoAnexoPomar(ehGaleria = false) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const foto = yield DispositivoUtil_1.DispositivoUtil.tirarFoto((typeof ehGaleria !== 'undefined') ? ehGaleria : false);
                const fotoId = ArmazenamentoUtil_1.ArmazenamentoUtil.uuid();
                this.listasFotos.fotosCamera.push({
                    uuid: fotoId,
                    referencia: 'fotosAnexoPomarSelecionado',
                    indiceIndicador: null,
                    indiceCampoIndicador: null,
                    indiceCampoCabecalho: null,
                    indiceCampoRodape: null,
                    controleManejoPomar: false,
                    controleEnxertiaPomar: false,
                    anexoPomar: true,
                    foto: foto
                });
                this.listasFotos.salvarFoto(fotoId);
                this.scopeApply();
            }
            catch (ex) {
                alert(ex.message);
            }
        });
    }
    removerFotoAnexoPomar(fotoCamera) {
        return __awaiter(this, void 0, void 0, function* () {
            if (confirm(`Deseja realmente remover essa foto?`)) {
                this.listasFotos.removerFoto(fotoCamera.uuid);
                this.scopeApply();
            }
        });
    }
    verFoto(foto) {
        return __awaiter(this, void 0, void 0, function* () {
            DispositivoUtil_1.DispositivoUtil.verFoto(foto);
        });
    }
}
exports.PomarBancoConservacaoController = PomarBancoConservacaoController;
PomarBancoConservacaoController.$inject = ['$scope', '$injector'];
