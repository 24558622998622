"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PomarBancoConservacaoControleEnxertiaUtil = void 0;
const angular = require("angular");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const ControleEnxertiaPomar_1 = require("../../../../../modelos/ControleEnxertiaPomar");
const EnxertoPomar_1 = require("../../../../../modelos/EnxertoPomar");
const TipoEnxertoPomar_1 = require("../../../../../modelos/TipoEnxertoPomar");
const TipoSobrevivenciaEnxertoPomar_1 = require("../../../../../modelos/TipoSobrevivenciaEnxertoPomar");
const TextoUtil_1 = require("../../../../../util/TextoUtil");
const LuckysheetUtil_1 = require("../../../../../util/LuckysheetUtil");
const DataUtil_1 = require("../../../../../util/DataUtil");
const sweetalert2_1 = require("sweetalert2");
class PomarBancoConservacaoControleEnxertiaUtil {
    static filtrarControleEnxertiaPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.pomarSelecionado || !ctrl.pomarSelecionado.id) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                ctrl.listaOpcoesEnxertiaPomar = yield ctrl.dadosListaEnxertiaPomar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.scopeApply();
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static salvarControleEnxertiaPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.controleEnxertiaPomarSelecionado || ctrl.controleEnxertiaPomarVisualizar) {
                    return;
                }
                let listaCamposObrigatorios = '';
                if (listaCamposObrigatorios && (listaCamposObrigatorios.length > 0)) {
                    let mensagem = 'Campo obrigatório não preenchido!' +
                        listaCamposObrigatorios + '\r\n \r\n' +
                        'Preencha este campo e tente novamente.';
                    if (ctrl.ehCelular) {
                        alert(mensagem.replace(/<b>/g, '').replace(/<\/b>/g, ''));
                    }
                    else {
                        yield sweetalert2_1.default.fire({
                            html: mensagem.replace(/\r\n/g, '<br>\r\n'),
                            icon: 'info',
                            showCancelButton: false,
                            showConfirmButton: true,
                            confirmButtonText: 'Ok'
                        });
                    }
                    return;
                }
                ctrl.modalTratamentoPomar = false;
                ctrl.modalGerarCroquiPomar = false;
                ctrl.modalHistoricoCroquiPomar = false;
                ctrl.modalPlanejamentoPomar = false;
                ctrl.modalControleManejoPomar = false;
                ctrl.modalProdutividadePomar = false;
                ctrl.modalFenologiaPomar = false;
                ctrl.modalPolinizacaoPomar = false;
                ctrl.modalRelatorioPomar = false;
                ctrl.modalAnexoPomar = false;
                ctrl.controleEnxertiaPomarSelecionado.pomar = ctrl.pomarSelecionado;
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                if (!ctrl.controleEnxertiaPomarSelecionado.id) {
                    ctrl.controleEnxertiaPomarSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.CONTROLE_ENXERTIA_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CONTROLE_ENXERTIA_POMAR)
                        .criar(ctrl.controleEnxertiaPomarSelecionado);
                }
                else {
                    ctrl.controleEnxertiaPomarSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.CONTROLE_ENXERTIA_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CONTROLE_ENXERTIA_POMAR)
                        .atualizar(ctrl.controleEnxertiaPomarSelecionado);
                }
                if ((typeof ctrl.controleEnxertiaPomarSelecionado.pomar !== 'undefined') && ctrl.controleEnxertiaPomarSelecionado.pomar &&
                    (typeof ctrl.controleEnxertiaPomarSelecionado.pomar.descricao !== 'undefined') && ctrl.controleEnxertiaPomarSelecionado.pomar.descricao) {
                    ctrl.controleEnxertiaPomarSelecionado.pomarDescricao = ctrl.controleEnxertiaPomarSelecionado.pomar.descricao;
                }
                let alterouControleEnxertiaPomar = false;
                let alterouPlanilhaCroqui = false;
                if ((typeof globalThis.luckysheet !== 'undefined') && globalThis.luckysheet) {
                    if ((typeof ctrl.controleEnxertiaPomarSelecionado.portaEnxertos !== 'undefined') &&
                        ctrl.controleEnxertiaPomarSelecionado.portaEnxertos &&
                        (ctrl.controleEnxertiaPomarSelecionado.portaEnxertos.length > 0) &&
                        (ctrl.controleEnxertiaPomarSelecionado.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.PORTA_ENXERTO)) {
                        let tituloPlanilhaPortaEnxerto = 'Porta Enxerto';
                        let referenciaPlanilhaPortaEnxerto = TextoUtil_1.TextoUtil.converteTextoParaReferencia(tituloPlanilhaPortaEnxerto);
                        let planilhaPortaEnxerto = null;
                        let planilhaPortaEnxertoIdx = null;
                        let ultimaPlanilhaCroqui = null;
                        let planilhaCroquiOriginalN1 = null;
                        let planilhaCroquiOriginalN2 = null;
                        angular.forEach(globalThis.luckysheet.getAllSheets(), (sheet, key) => {
                            if ((typeof sheet.name !== 'undefined') && sheet.name) {
                                const lcSheetName = String(sheet.name).toLowerCase().trim();
                                if (!ultimaPlanilhaCroqui) {
                                    ultimaPlanilhaCroqui = sheet;
                                }
                                if (!planilhaCroquiOriginalN1 && (lcSheetName.indexOf('croqui') !== -1) && (lcSheetName.indexOf('enxerto') === -1)) {
                                    planilhaCroquiOriginalN1 = sheet;
                                }
                                if (!planilhaCroquiOriginalN2 && (lcSheetName.indexOf('enxerto') === -1)) {
                                    planilhaCroquiOriginalN2 = sheet;
                                }
                                if (!planilhaPortaEnxerto && (TextoUtil_1.TextoUtil.converteTextoParaReferencia(sheet.name) === referenciaPlanilhaPortaEnxerto)) {
                                    planilhaPortaEnxerto = sheet;
                                    planilhaPortaEnxertoIdx = key;
                                }
                            }
                        });
                        if (planilhaCroquiOriginalN1) {
                            ultimaPlanilhaCroqui = planilhaCroquiOriginalN1;
                        }
                        else if (planilhaCroquiOriginalN2) {
                            ultimaPlanilhaCroqui = planilhaCroquiOriginalN2;
                        }
                        if (!planilhaPortaEnxerto && ultimaPlanilhaCroqui) {
                            globalThis.luckysheet.setSheetAdd({
                                sheetObject: {
                                    name: tituloPlanilhaPortaEnxerto,
                                    data: (ultimaPlanilhaCroqui.data ? angular.copy(ultimaPlanilhaCroqui.data) : []),
                                    config: (ultimaPlanilhaCroqui.config ? angular.copy(ultimaPlanilhaCroqui.config) : null)
                                },
                                order: 0
                            });
                            planilhaPortaEnxertoIdx = 0;
                            globalThis.luckysheet.setSheetActive(planilhaPortaEnxertoIdx);
                            angular.forEach(globalThis.luckysheet.getAllSheets(), (sheet) => {
                                if ((typeof sheet.name !== 'undefined') && sheet.name) {
                                    if (!planilhaPortaEnxerto && (TextoUtil_1.TextoUtil.converteTextoParaReferencia(sheet.name) === referenciaPlanilhaPortaEnxerto)) {
                                        planilhaPortaEnxerto = sheet;
                                    }
                                }
                            });
                            alterouPlanilhaCroqui = true;
                        }
                        else if ((typeof planilhaPortaEnxertoIdx !== 'undefined') && (planilhaPortaEnxertoIdx !== null)) {
                            globalThis.luckysheet.setSheetActive(planilhaPortaEnxertoIdx);
                        }
                        if (planilhaPortaEnxerto) {
                            angular.forEach(ctrl.controleEnxertiaPomarSelecionado.portaEnxertos, (portaEnxerto) => {
                                let posicaoMaterialGeneticoCroqui = LuckysheetUtil_1.LuckysheetUtil.buscaPosicaoMaterialGeneticoCroqui(portaEnxerto.fila, portaEnxerto.coluna);
                                if (posicaoMaterialGeneticoCroqui && (typeof posicaoMaterialGeneticoCroqui.materialGenetico !== 'undefined') && (posicaoMaterialGeneticoCroqui.materialGenetico !== null)) {
                                    let referenciaMaterialGeneticoCroqui = TextoUtil_1.TextoUtil.converteTextoParaReferencia(posicaoMaterialGeneticoCroqui.materialGenetico);
                                    if (referenciaMaterialGeneticoCroqui && (referenciaMaterialGeneticoCroqui.length > 0)) {
                                        let materialGeneticoCroqui = null;
                                        if ((typeof ctrl.materiaisGeneticosFiltrados !== 'undefined') && ctrl.materiaisGeneticosFiltrados && (ctrl.materiaisGeneticosFiltrados.length > 0)) {
                                            angular.forEach(ctrl.materiaisGeneticosFiltrados, (materialGenetico) => {
                                                if (!materialGeneticoCroqui && materialGenetico.descricao && (TextoUtil_1.TextoUtil.converteTextoParaReferencia(materialGenetico.descricao) === referenciaMaterialGeneticoCroqui)) {
                                                    materialGeneticoCroqui = materialGenetico;
                                                }
                                            });
                                        }
                                        else if ((typeof ctrl.materiaisGeneticos !== 'undefined') && ctrl.materiaisGeneticos && (ctrl.materiaisGeneticos.length > 0)) {
                                            angular.forEach(ctrl.materiaisGeneticos, (materialGenetico) => {
                                                if (!materialGeneticoCroqui && materialGenetico.descricao && (TextoUtil_1.TextoUtil.converteTextoParaReferencia(materialGenetico.descricao) === referenciaMaterialGeneticoCroqui)) {
                                                    materialGeneticoCroqui = materialGenetico;
                                                }
                                            });
                                        }
                                        if ((typeof materialGeneticoCroqui !== 'undefined') && materialGeneticoCroqui &&
                                            (typeof materialGeneticoCroqui.id !== 'undefined') && materialGeneticoCroqui.id) {
                                            portaEnxerto.materialGenetico = materialGeneticoCroqui;
                                            alterouControleEnxertiaPomar = true;
                                        }
                                    }
                                }
                                if (posicaoMaterialGeneticoCroqui && (posicaoMaterialGeneticoCroqui.row !== null) && (posicaoMaterialGeneticoCroqui.col !== null)) {
                                    if ((typeof portaEnxerto.sobrevivencia === 'undefined') || (portaEnxerto.sobrevivencia === null)) {
                                        portaEnxerto.sobrevivencia = 0;
                                    }
                                    let comentario = 'Porta enxerto em ' + DataUtil_1.DataUtil.converterDataParaFormatoDDMMYY(ctrl.controleEnxertiaPomarSelecionado.data) +
                                        ' - Sobrevivência: ' + portaEnxerto.sobrevivencia;
                                    globalThis.luckysheet.setCellValue(posicaoMaterialGeneticoCroqui.row, posicaoMaterialGeneticoCroqui.col + 1, portaEnxerto.sobrevivencia, {
                                        order: planilhaPortaEnxertoIdx,
                                        success: (data) => {
                                            if ((typeof data !== 'undefined') && data && (data.length > 0) && (posicaoMaterialGeneticoCroqui.row < data.length) &&
                                                (typeof data[posicaoMaterialGeneticoCroqui.row] !== 'undefined') && data[posicaoMaterialGeneticoCroqui.row] &&
                                                ((posicaoMaterialGeneticoCroqui.col + 1) < data[posicaoMaterialGeneticoCroqui.row].length)) {
                                                const cell = data[posicaoMaterialGeneticoCroqui.row][posicaoMaterialGeneticoCroqui.col + 1];
                                                if ((typeof cell !== 'undefined') && cell) {
                                                    if ((typeof cell.ps !== 'undefined') && cell.ps && (typeof cell.ps.value !== 'undefined') && cell.ps.value && (String(cell.ps.value).length > 0)) {
                                                        cell.ps.value = String(cell.ps.value).replace('\r\n' + comentario, '').replace(comentario, '') + '\r\n' + comentario;
                                                    }
                                                    else {
                                                        cell.ps = { left: null, top: null, width: 400, height: null, value: comentario, isshow: false };
                                                    }
                                                    globalThis.luckysheet.setCellValue(posicaoMaterialGeneticoCroqui.row, posicaoMaterialGeneticoCroqui.col + 1, cell, {
                                                        order: planilhaPortaEnxertoIdx,
                                                        success: () => {
                                                            if ((typeof globalThis.timeoutSalvarCroquiPomar !== 'undefined') && globalThis.timeoutSalvarCroquiPomar) {
                                                                clearTimeout(globalThis.timeoutSalvarCroquiPomar);
                                                                globalThis.timeoutSalvarCroquiPomar = null;
                                                            }
                                                            globalThis.timeoutSalvarCroquiPomar = setTimeout((ctrl) => {
                                                                ctrl.salvarCroquiPomar();
                                                            }, 1500, ctrl);
                                                        }
                                                    });
                                                }
                                            }
                                        }
                                    });
                                }
                            });
                        }
                    }
                    if ((typeof ctrl.controleEnxertiaPomarSelecionado.enxertosAno1 !== 'undefined') &&
                        ctrl.controleEnxertiaPomarSelecionado.enxertosAno1 &&
                        (ctrl.controleEnxertiaPomarSelecionado.enxertosAno1.length > 0) &&
                        (ctrl.controleEnxertiaPomarSelecionado.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_1)) {
                        let tituloPlanilhaEnxertoAno1 = 'Enxerto Ano 1';
                        let referenciaPlanilhaEnxertoAno1 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(tituloPlanilhaEnxertoAno1);
                        let planilhaEnxertoAno1 = null;
                        let planilhaEnxertoAno1Idx = null;
                        let ultimaPlanilhaCroqui = null;
                        let planilhaCroquiOriginalN1 = null;
                        let planilhaCroquiOriginalN2 = null;
                        angular.forEach(globalThis.luckysheet.getAllSheets(), (sheet, key) => {
                            if ((typeof sheet.name !== 'undefined') && sheet.name) {
                                const lcSheetName = String(sheet.name).toLowerCase().trim();
                                if (!ultimaPlanilhaCroqui) {
                                    ultimaPlanilhaCroqui = sheet;
                                }
                                if (!planilhaCroquiOriginalN1 && (lcSheetName.indexOf('croqui') !== -1) && (lcSheetName.indexOf('enxerto') === -1)) {
                                    planilhaCroquiOriginalN1 = sheet;
                                }
                                if (!planilhaCroquiOriginalN2 && (lcSheetName.indexOf('enxerto') === -1)) {
                                    planilhaCroquiOriginalN2 = sheet;
                                }
                                if (!planilhaEnxertoAno1 && (TextoUtil_1.TextoUtil.converteTextoParaReferencia(sheet.name) === referenciaPlanilhaEnxertoAno1)) {
                                    planilhaEnxertoAno1 = sheet;
                                    planilhaEnxertoAno1Idx = key;
                                }
                            }
                        });
                        if (planilhaCroquiOriginalN1) {
                            ultimaPlanilhaCroqui = planilhaCroquiOriginalN1;
                        }
                        else if (planilhaCroquiOriginalN2) {
                            ultimaPlanilhaCroqui = planilhaCroquiOriginalN2;
                        }
                        if (!planilhaEnxertoAno1 && ultimaPlanilhaCroqui) {
                            globalThis.luckysheet.setSheetAdd({
                                sheetObject: {
                                    name: tituloPlanilhaEnxertoAno1,
                                    data: (ultimaPlanilhaCroqui.data ? angular.copy(ultimaPlanilhaCroqui.data) : []),
                                    config: (ultimaPlanilhaCroqui.config ? angular.copy(ultimaPlanilhaCroqui.config) : null)
                                },
                                order: 0
                            });
                            planilhaEnxertoAno1Idx = 0;
                            globalThis.luckysheet.setSheetActive(planilhaEnxertoAno1Idx);
                            angular.forEach(globalThis.luckysheet.getAllSheets(), (sheet) => {
                                if ((typeof sheet.name !== 'undefined') && sheet.name) {
                                    if (!planilhaEnxertoAno1 && (TextoUtil_1.TextoUtil.converteTextoParaReferencia(sheet.name) === referenciaPlanilhaEnxertoAno1)) {
                                        planilhaEnxertoAno1 = sheet;
                                    }
                                }
                            });
                            alterouPlanilhaCroqui = true;
                        }
                        else if ((typeof planilhaEnxertoAno1Idx !== 'undefined') && (planilhaEnxertoAno1Idx !== null)) {
                            globalThis.luckysheet.setSheetActive(planilhaEnxertoAno1Idx);
                        }
                        if (planilhaEnxertoAno1) {
                            angular.forEach(ctrl.controleEnxertiaPomarSelecionado.enxertosAno1, (enxertoAno1) => {
                                let posicaoMaterialGeneticoCroqui = LuckysheetUtil_1.LuckysheetUtil.buscaPosicaoMaterialGeneticoCroqui(enxertoAno1.fila, enxertoAno1.coluna);
                                if (posicaoMaterialGeneticoCroqui && (typeof posicaoMaterialGeneticoCroqui.materialGenetico !== 'undefined') && (posicaoMaterialGeneticoCroqui.materialGenetico !== null)) {
                                    let referenciaMaterialGeneticoCroqui = TextoUtil_1.TextoUtil.converteTextoParaReferencia(posicaoMaterialGeneticoCroqui.materialGenetico);
                                    if (referenciaMaterialGeneticoCroqui && (referenciaMaterialGeneticoCroqui.length > 0)) {
                                        let materialGeneticoCroqui = null;
                                        if ((typeof ctrl.materiaisGeneticosFiltrados !== 'undefined') && ctrl.materiaisGeneticosFiltrados && (ctrl.materiaisGeneticosFiltrados.length > 0)) {
                                            angular.forEach(ctrl.materiaisGeneticosFiltrados, (materialGenetico) => {
                                                if (!materialGeneticoCroqui && materialGenetico.descricao && (TextoUtil_1.TextoUtil.converteTextoParaReferencia(materialGenetico.descricao) === referenciaMaterialGeneticoCroqui)) {
                                                    materialGeneticoCroqui = materialGenetico;
                                                }
                                            });
                                        }
                                        else if ((typeof ctrl.materiaisGeneticos !== 'undefined') && ctrl.materiaisGeneticos && (ctrl.materiaisGeneticos.length > 0)) {
                                            angular.forEach(ctrl.materiaisGeneticos, (materialGenetico) => {
                                                if (!materialGeneticoCroqui && materialGenetico.descricao && (TextoUtil_1.TextoUtil.converteTextoParaReferencia(materialGenetico.descricao) === referenciaMaterialGeneticoCroqui)) {
                                                    materialGeneticoCroqui = materialGenetico;
                                                }
                                            });
                                        }
                                        if ((typeof materialGeneticoCroqui !== 'undefined') && materialGeneticoCroqui &&
                                            (typeof materialGeneticoCroqui.id !== 'undefined') && materialGeneticoCroqui.id) {
                                            enxertoAno1.materialGenetico = materialGeneticoCroqui;
                                            alterouControleEnxertiaPomar = true;
                                        }
                                    }
                                }
                                if (posicaoMaterialGeneticoCroqui && (posicaoMaterialGeneticoCroqui.row !== null) && (posicaoMaterialGeneticoCroqui.col !== null)) {
                                    if ((typeof enxertoAno1.sobrevivencia === 'undefined') || (enxertoAno1.sobrevivencia === null)) {
                                        enxertoAno1.sobrevivencia = 0;
                                    }
                                    if ((typeof enxertoAno1.sobrevivencia60dias === 'undefined') || (enxertoAno1.sobrevivencia60dias === null)) {
                                        enxertoAno1.sobrevivencia60dias = 0;
                                    }
                                    if ((typeof enxertoAno1.sobrevivencia90dias === 'undefined') || (enxertoAno1.sobrevivencia90dias === null)) {
                                        enxertoAno1.sobrevivencia90dias = 0;
                                    }
                                    let comentario = 'Enxertia ano 1 em ' + DataUtil_1.DataUtil.converterDataParaFormatoDDMMYY(ctrl.controleEnxertiaPomarSelecionado.data) +
                                        ((ctrl.controleEnxertiaPomarSelecionado.tipoSobrevivencia === TipoSobrevivenciaEnxertoPomar_1.TipoSobrevivenciaEnxertoPomar.SOBREVIVENCIA_90_DIAS) ? ' - Sobrevivência 90 dias: ' + enxertoAno1.sobrevivencia90dias :
                                            ((ctrl.controleEnxertiaPomarSelecionado.tipoSobrevivencia === TipoSobrevivenciaEnxertoPomar_1.TipoSobrevivenciaEnxertoPomar.SOBREVIVENCIA_60_DIAS) ? ' - Sobrevivência 60 dias: ' + enxertoAno1.sobrevivencia60dias :
                                                ' - Sobrevivência: ' + enxertoAno1.sobrevivencia));
                                    globalThis.luckysheet.setCellValue(posicaoMaterialGeneticoCroqui.row, posicaoMaterialGeneticoCroqui.col + 1, ((ctrl.controleEnxertiaPomarSelecionado.tipoSobrevivencia === TipoSobrevivenciaEnxertoPomar_1.TipoSobrevivenciaEnxertoPomar.SOBREVIVENCIA_90_DIAS) ? enxertoAno1.sobrevivencia90dias :
                                        ((ctrl.controleEnxertiaPomarSelecionado.tipoSobrevivencia === TipoSobrevivenciaEnxertoPomar_1.TipoSobrevivenciaEnxertoPomar.SOBREVIVENCIA_60_DIAS) ? enxertoAno1.sobrevivencia60dias :
                                            enxertoAno1.sobrevivencia)), {
                                        order: planilhaEnxertoAno1Idx,
                                        success: (data) => {
                                            if ((typeof data !== 'undefined') && data && (data.length > 0) && (posicaoMaterialGeneticoCroqui.row < data.length) &&
                                                (typeof data[posicaoMaterialGeneticoCroqui.row] !== 'undefined') && data[posicaoMaterialGeneticoCroqui.row] &&
                                                ((posicaoMaterialGeneticoCroqui.col + 1) < data[posicaoMaterialGeneticoCroqui.row].length)) {
                                                const cell = data[posicaoMaterialGeneticoCroqui.row][posicaoMaterialGeneticoCroqui.col + 1];
                                                if ((typeof cell !== 'undefined') && cell) {
                                                    if ((typeof cell.ps !== 'undefined') && cell.ps && (typeof cell.ps.value !== 'undefined') && cell.ps.value && (String(cell.ps.value).length > 0)) {
                                                        cell.ps.value = String(cell.ps.value).replace('\r\n' + comentario, '').replace(comentario, '') + '\r\n' + comentario;
                                                    }
                                                    else {
                                                        cell.ps = { left: null, top: null, width: 400, height: null, value: comentario, isshow: false };
                                                    }
                                                    globalThis.luckysheet.setCellValue(posicaoMaterialGeneticoCroqui.row, posicaoMaterialGeneticoCroqui.col + 1, cell, {
                                                        order: planilhaEnxertoAno1Idx,
                                                        success: () => {
                                                            if ((typeof globalThis.timeoutSalvarCroquiPomar !== 'undefined') && globalThis.timeoutSalvarCroquiPomar) {
                                                                clearTimeout(globalThis.timeoutSalvarCroquiPomar);
                                                                globalThis.timeoutSalvarCroquiPomar = null;
                                                            }
                                                            globalThis.timeoutSalvarCroquiPomar = setTimeout((ctrl) => {
                                                                ctrl.salvarCroquiPomar();
                                                            }, 1500, ctrl);
                                                        }
                                                    });
                                                }
                                            }
                                        }
                                    });
                                }
                            });
                        }
                    }
                    if ((typeof ctrl.controleEnxertiaPomarSelecionado.enxertosAno2 !== 'undefined') &&
                        ctrl.controleEnxertiaPomarSelecionado.enxertosAno2 &&
                        (ctrl.controleEnxertiaPomarSelecionado.enxertosAno2.length > 0) &&
                        (ctrl.controleEnxertiaPomarSelecionado.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_2)) {
                        let tituloPlanilhaEnxertoAno2 = 'Enxerto Ano 2';
                        let referenciaPlanilhaEnxertoAno2 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(tituloPlanilhaEnxertoAno2);
                        let planilhaEnxertoAno2 = null;
                        let planilhaEnxertoAno2Idx = null;
                        let ultimaPlanilhaCroqui = null;
                        let planilhaCroquiOriginalN1 = null;
                        let planilhaCroquiOriginalN2 = null;
                        angular.forEach(globalThis.luckysheet.getAllSheets(), (sheet, key) => {
                            if ((typeof sheet.name !== 'undefined') && sheet.name) {
                                const lcSheetName = String(sheet.name).toLowerCase().trim();
                                if (!ultimaPlanilhaCroqui) {
                                    ultimaPlanilhaCroqui = sheet;
                                }
                                if (!planilhaCroquiOriginalN1 && (lcSheetName.indexOf('croqui') !== -1) && (lcSheetName.indexOf('enxerto') === -1)) {
                                    planilhaCroquiOriginalN1 = sheet;
                                }
                                if (!planilhaCroquiOriginalN2 && (lcSheetName.indexOf('enxerto') === -1)) {
                                    planilhaCroquiOriginalN2 = sheet;
                                }
                                if (!planilhaEnxertoAno2 && (TextoUtil_1.TextoUtil.converteTextoParaReferencia(sheet.name) === referenciaPlanilhaEnxertoAno2)) {
                                    planilhaEnxertoAno2 = sheet;
                                    planilhaEnxertoAno2Idx = key;
                                }
                            }
                        });
                        if (planilhaCroquiOriginalN1) {
                            ultimaPlanilhaCroqui = planilhaCroquiOriginalN1;
                        }
                        else if (planilhaCroquiOriginalN2) {
                            ultimaPlanilhaCroqui = planilhaCroquiOriginalN2;
                        }
                        if (!planilhaEnxertoAno2 && ultimaPlanilhaCroqui) {
                            globalThis.luckysheet.setSheetAdd({
                                sheetObject: {
                                    name: tituloPlanilhaEnxertoAno2,
                                    data: (ultimaPlanilhaCroqui.data ? angular.copy(ultimaPlanilhaCroqui.data) : []),
                                    config: (ultimaPlanilhaCroqui.config ? angular.copy(ultimaPlanilhaCroqui.config) : null)
                                },
                                order: 0
                            });
                            planilhaEnxertoAno2Idx = 0;
                            globalThis.luckysheet.setSheetActive(planilhaEnxertoAno2Idx);
                            angular.forEach(globalThis.luckysheet.getAllSheets(), (sheet) => {
                                if ((typeof sheet.name !== 'undefined') && sheet.name) {
                                    if (!planilhaEnxertoAno2 && (TextoUtil_1.TextoUtil.converteTextoParaReferencia(sheet.name) === referenciaPlanilhaEnxertoAno2)) {
                                        planilhaEnxertoAno2 = sheet;
                                    }
                                }
                            });
                            alterouPlanilhaCroqui = true;
                        }
                        else if ((typeof planilhaEnxertoAno2Idx !== 'undefined') && (planilhaEnxertoAno2Idx !== null)) {
                            globalThis.luckysheet.setSheetActive(planilhaEnxertoAno2Idx);
                        }
                        if (planilhaEnxertoAno2) {
                            angular.forEach(ctrl.controleEnxertiaPomarSelecionado.enxertosAno2, (enxertoAno2) => {
                                let posicaoMaterialGeneticoCroqui = LuckysheetUtil_1.LuckysheetUtil.buscaPosicaoMaterialGeneticoCroqui(enxertoAno2.fila, enxertoAno2.coluna);
                                if (posicaoMaterialGeneticoCroqui && (typeof posicaoMaterialGeneticoCroqui.materialGenetico !== 'undefined') && (posicaoMaterialGeneticoCroqui.materialGenetico !== null)) {
                                    let referenciaMaterialGeneticoCroqui = TextoUtil_1.TextoUtil.converteTextoParaReferencia(posicaoMaterialGeneticoCroqui.materialGenetico);
                                    if (referenciaMaterialGeneticoCroqui && (referenciaMaterialGeneticoCroqui.length > 0)) {
                                        let materialGeneticoCroqui = null;
                                        if ((typeof ctrl.materiaisGeneticosFiltrados !== 'undefined') && ctrl.materiaisGeneticosFiltrados && (ctrl.materiaisGeneticosFiltrados.length > 0)) {
                                            angular.forEach(ctrl.materiaisGeneticosFiltrados, (materialGenetico) => {
                                                if (!materialGeneticoCroqui && materialGenetico.descricao && (TextoUtil_1.TextoUtil.converteTextoParaReferencia(materialGenetico.descricao) === referenciaMaterialGeneticoCroqui)) {
                                                    materialGeneticoCroqui = materialGenetico;
                                                }
                                            });
                                        }
                                        else if ((typeof ctrl.materiaisGeneticos !== 'undefined') && ctrl.materiaisGeneticos && (ctrl.materiaisGeneticos.length > 0)) {
                                            angular.forEach(ctrl.materiaisGeneticos, (materialGenetico) => {
                                                if (!materialGeneticoCroqui && materialGenetico.descricao && (TextoUtil_1.TextoUtil.converteTextoParaReferencia(materialGenetico.descricao) === referenciaMaterialGeneticoCroqui)) {
                                                    materialGeneticoCroqui = materialGenetico;
                                                }
                                            });
                                        }
                                        if ((typeof materialGeneticoCroqui !== 'undefined') && materialGeneticoCroqui &&
                                            (typeof materialGeneticoCroqui.id !== 'undefined') && materialGeneticoCroqui.id) {
                                            enxertoAno2.materialGenetico = materialGeneticoCroqui;
                                            alterouControleEnxertiaPomar = true;
                                        }
                                    }
                                }
                                if (posicaoMaterialGeneticoCroqui && (posicaoMaterialGeneticoCroqui.row !== null) && (posicaoMaterialGeneticoCroqui.col !== null)) {
                                    if ((typeof enxertoAno2.sobrevivencia === 'undefined') || (enxertoAno2.sobrevivencia === null)) {
                                        enxertoAno2.sobrevivencia = 0;
                                    }
                                    if ((typeof enxertoAno2.sobrevivencia60dias === 'undefined') || (enxertoAno2.sobrevivencia60dias === null)) {
                                        enxertoAno2.sobrevivencia60dias = 0;
                                    }
                                    if ((typeof enxertoAno2.sobrevivencia90dias === 'undefined') || (enxertoAno2.sobrevivencia90dias === null)) {
                                        enxertoAno2.sobrevivencia90dias = 0;
                                    }
                                    let comentario = 'Enxertia ano 2 em ' + DataUtil_1.DataUtil.converterDataParaFormatoDDMMYY(ctrl.controleEnxertiaPomarSelecionado.data) +
                                        ((ctrl.controleEnxertiaPomarSelecionado.tipoSobrevivencia === TipoSobrevivenciaEnxertoPomar_1.TipoSobrevivenciaEnxertoPomar.SOBREVIVENCIA_90_DIAS) ? ' - Sobrevivência 90 dias: ' + enxertoAno2.sobrevivencia90dias :
                                            ((ctrl.controleEnxertiaPomarSelecionado.tipoSobrevivencia === TipoSobrevivenciaEnxertoPomar_1.TipoSobrevivenciaEnxertoPomar.SOBREVIVENCIA_60_DIAS) ? ' - Sobrevivência 60 dias: ' + enxertoAno2.sobrevivencia60dias :
                                                ' - Sobrevivência: ' + enxertoAno2.sobrevivencia));
                                    globalThis.luckysheet.setCellValue(posicaoMaterialGeneticoCroqui.row, posicaoMaterialGeneticoCroqui.col + 1, ((ctrl.controleEnxertiaPomarSelecionado.tipoSobrevivencia === TipoSobrevivenciaEnxertoPomar_1.TipoSobrevivenciaEnxertoPomar.SOBREVIVENCIA_90_DIAS)
                                        ? ((typeof enxertoAno2.sobrevivencia90dias !== 'undefined') && (enxertoAno2.sobrevivencia90dias !== null) ? enxertoAno2.sobrevivencia90dias : '0') :
                                        ((ctrl.controleEnxertiaPomarSelecionado.tipoSobrevivencia === TipoSobrevivenciaEnxertoPomar_1.TipoSobrevivenciaEnxertoPomar.SOBREVIVENCIA_60_DIAS)
                                            ? ((typeof enxertoAno2.sobrevivencia60dias !== 'undefined') && (enxertoAno2.sobrevivencia60dias !== null) ? enxertoAno2.sobrevivencia60dias : '0') :
                                            ((typeof enxertoAno2.sobrevivencia !== 'undefined') && (enxertoAno2.sobrevivencia !== null) ? enxertoAno2.sobrevivencia : '0'))), {
                                        order: planilhaEnxertoAno2Idx,
                                        success: (data) => {
                                            if ((typeof data !== 'undefined') && data && (data.length > 0) && (posicaoMaterialGeneticoCroqui.row < data.length) &&
                                                (typeof data[posicaoMaterialGeneticoCroqui.row] !== 'undefined') && data[posicaoMaterialGeneticoCroqui.row] &&
                                                ((posicaoMaterialGeneticoCroqui.col + 1) < data[posicaoMaterialGeneticoCroqui.row].length)) {
                                                const cell = data[posicaoMaterialGeneticoCroqui.row][posicaoMaterialGeneticoCroqui.col + 1];
                                                if ((typeof cell !== 'undefined') && cell) {
                                                    if ((typeof cell.ps !== 'undefined') && cell.ps && (typeof cell.ps.value !== 'undefined') && cell.ps.value && (String(cell.ps.value).length > 0)) {
                                                        cell.ps.value = String(cell.ps.value).replace('\r\n' + comentario, '').replace(comentario, '') + '\r\n' + comentario;
                                                    }
                                                    else {
                                                        cell.ps = { left: null, top: null, width: 400, height: null, value: comentario, isshow: false };
                                                    }
                                                    globalThis.luckysheet.setCellValue(posicaoMaterialGeneticoCroqui.row, posicaoMaterialGeneticoCroqui.col + 1, cell, {
                                                        order: planilhaEnxertoAno2Idx,
                                                        success: () => {
                                                            if ((typeof globalThis.timeoutSalvarCroquiPomar !== 'undefined') && globalThis.timeoutSalvarCroquiPomar) {
                                                                clearTimeout(globalThis.timeoutSalvarCroquiPomar);
                                                                globalThis.timeoutSalvarCroquiPomar = null;
                                                            }
                                                            globalThis.timeoutSalvarCroquiPomar = setTimeout((ctrl) => {
                                                                ctrl.salvarCroquiPomar();
                                                            }, 1500, ctrl);
                                                        }
                                                    });
                                                }
                                            }
                                        }
                                    });
                                }
                            });
                        }
                    }
                    if ((typeof ctrl.controleEnxertiaPomarSelecionado.enxertosAno3 !== 'undefined') &&
                        ctrl.controleEnxertiaPomarSelecionado.enxertosAno3 &&
                        (ctrl.controleEnxertiaPomarSelecionado.enxertosAno3.length > 0) &&
                        (ctrl.controleEnxertiaPomarSelecionado.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_3)) {
                        let tituloPlanilhaEnxertoAno3 = 'Enxerto Ano 3';
                        let referenciaPlanilhaEnxertoAno3 = TextoUtil_1.TextoUtil.converteTextoParaReferencia(tituloPlanilhaEnxertoAno3);
                        let planilhaEnxertoAno3 = null;
                        let planilhaEnxertoAno3Idx = null;
                        let ultimaPlanilhaCroqui = null;
                        let planilhaCroquiOriginalN1 = null;
                        let planilhaCroquiOriginalN2 = null;
                        angular.forEach(globalThis.luckysheet.getAllSheets(), (sheet, key) => {
                            if ((typeof sheet.name !== 'undefined') && sheet.name) {
                                const lcSheetName = String(sheet.name).toLowerCase().trim();
                                if (!ultimaPlanilhaCroqui) {
                                    ultimaPlanilhaCroqui = sheet;
                                }
                                if (!planilhaCroquiOriginalN1 && (lcSheetName.indexOf('croqui') !== -1) && (lcSheetName.indexOf('enxerto') === -1)) {
                                    planilhaCroquiOriginalN1 = sheet;
                                }
                                if (!planilhaCroquiOriginalN2 && (lcSheetName.indexOf('enxerto') === -1)) {
                                    planilhaCroquiOriginalN2 = sheet;
                                }
                                if (!planilhaEnxertoAno3 && (TextoUtil_1.TextoUtil.converteTextoParaReferencia(sheet.name) === referenciaPlanilhaEnxertoAno3)) {
                                    planilhaEnxertoAno3 = sheet;
                                    planilhaEnxertoAno3Idx = key;
                                }
                            }
                        });
                        if (planilhaCroquiOriginalN1) {
                            ultimaPlanilhaCroqui = planilhaCroquiOriginalN1;
                        }
                        else if (planilhaCroquiOriginalN2) {
                            ultimaPlanilhaCroqui = planilhaCroquiOriginalN2;
                        }
                        if (!planilhaEnxertoAno3 && ultimaPlanilhaCroqui) {
                            globalThis.luckysheet.setSheetAdd({
                                sheetObject: {
                                    name: tituloPlanilhaEnxertoAno3,
                                    data: (ultimaPlanilhaCroqui.data ? angular.copy(ultimaPlanilhaCroqui.data) : []),
                                    config: (ultimaPlanilhaCroqui.config ? angular.copy(ultimaPlanilhaCroqui.config) : null)
                                },
                                order: 0
                            });
                            planilhaEnxertoAno3Idx = 0;
                            globalThis.luckysheet.setSheetActive(planilhaEnxertoAno3Idx);
                            angular.forEach(globalThis.luckysheet.getAllSheets(), (sheet) => {
                                if ((typeof sheet.name !== 'undefined') && sheet.name) {
                                    if (!planilhaEnxertoAno3 && (TextoUtil_1.TextoUtil.converteTextoParaReferencia(sheet.name) === referenciaPlanilhaEnxertoAno3)) {
                                        planilhaEnxertoAno3 = sheet;
                                    }
                                }
                            });
                            alterouPlanilhaCroqui = true;
                        }
                        else if ((typeof planilhaEnxertoAno3Idx !== 'undefined') && (planilhaEnxertoAno3Idx !== null)) {
                            globalThis.luckysheet.setSheetActive(planilhaEnxertoAno3Idx);
                        }
                        if (planilhaEnxertoAno3) {
                            angular.forEach(ctrl.controleEnxertiaPomarSelecionado.enxertosAno3, (enxertoAno3) => {
                                let posicaoMaterialGeneticoCroqui = LuckysheetUtil_1.LuckysheetUtil.buscaPosicaoMaterialGeneticoCroqui(enxertoAno3.fila, enxertoAno3.coluna);
                                if (posicaoMaterialGeneticoCroqui && (typeof posicaoMaterialGeneticoCroqui.materialGenetico !== 'undefined') && (posicaoMaterialGeneticoCroqui.materialGenetico !== null)) {
                                    let referenciaMaterialGeneticoCroqui = TextoUtil_1.TextoUtil.converteTextoParaReferencia(posicaoMaterialGeneticoCroqui.materialGenetico);
                                    if (referenciaMaterialGeneticoCroqui && (referenciaMaterialGeneticoCroqui.length > 0)) {
                                        let materialGeneticoCroqui = null;
                                        if ((typeof ctrl.materiaisGeneticosFiltrados !== 'undefined') && ctrl.materiaisGeneticosFiltrados && (ctrl.materiaisGeneticosFiltrados.length > 0)) {
                                            angular.forEach(ctrl.materiaisGeneticosFiltrados, (materialGenetico) => {
                                                if (!materialGeneticoCroqui && materialGenetico.descricao && (TextoUtil_1.TextoUtil.converteTextoParaReferencia(materialGenetico.descricao) === referenciaMaterialGeneticoCroqui)) {
                                                    materialGeneticoCroqui = materialGenetico;
                                                }
                                            });
                                        }
                                        else if ((typeof ctrl.materiaisGeneticos !== 'undefined') && ctrl.materiaisGeneticos && (ctrl.materiaisGeneticos.length > 0)) {
                                            angular.forEach(ctrl.materiaisGeneticos, (materialGenetico) => {
                                                if (!materialGeneticoCroqui && materialGenetico.descricao && (TextoUtil_1.TextoUtil.converteTextoParaReferencia(materialGenetico.descricao) === referenciaMaterialGeneticoCroqui)) {
                                                    materialGeneticoCroqui = materialGenetico;
                                                }
                                            });
                                        }
                                        if ((typeof materialGeneticoCroqui !== 'undefined') && materialGeneticoCroqui &&
                                            (typeof materialGeneticoCroqui.id !== 'undefined') && materialGeneticoCroqui.id) {
                                            enxertoAno3.materialGenetico = materialGeneticoCroqui;
                                            alterouControleEnxertiaPomar = true;
                                        }
                                    }
                                }
                                if (posicaoMaterialGeneticoCroqui && (posicaoMaterialGeneticoCroqui.row !== null) && (posicaoMaterialGeneticoCroqui.col !== null)) {
                                    if ((typeof enxertoAno3.sobrevivencia === 'undefined') || (enxertoAno3.sobrevivencia === null)) {
                                        enxertoAno3.sobrevivencia = 0;
                                    }
                                    if ((typeof enxertoAno3.sobrevivencia60dias === 'undefined') || (enxertoAno3.sobrevivencia60dias === null)) {
                                        enxertoAno3.sobrevivencia60dias = 0;
                                    }
                                    if ((typeof enxertoAno3.sobrevivencia90dias === 'undefined') || (enxertoAno3.sobrevivencia90dias === null)) {
                                        enxertoAno3.sobrevivencia90dias = 0;
                                    }
                                    let comentario = 'Enxertia ano 3 em ' + DataUtil_1.DataUtil.converterDataParaFormatoDDMMYY(ctrl.controleEnxertiaPomarSelecionado.data) +
                                        ((ctrl.controleEnxertiaPomarSelecionado.tipoSobrevivencia === TipoSobrevivenciaEnxertoPomar_1.TipoSobrevivenciaEnxertoPomar.SOBREVIVENCIA_90_DIAS) ? ' - Sobrevivência 90 dias: ' + enxertoAno3.sobrevivencia90dias :
                                            ((ctrl.controleEnxertiaPomarSelecionado.tipoSobrevivencia === TipoSobrevivenciaEnxertoPomar_1.TipoSobrevivenciaEnxertoPomar.SOBREVIVENCIA_60_DIAS) ? ' - Sobrevivência 60 dias: ' + enxertoAno3.sobrevivencia60dias :
                                                ' - Sobrevivência: ' + enxertoAno3.sobrevivencia));
                                    globalThis.luckysheet.setCellValue(posicaoMaterialGeneticoCroqui.row, posicaoMaterialGeneticoCroqui.col + 1, ((ctrl.controleEnxertiaPomarSelecionado.tipoSobrevivencia === TipoSobrevivenciaEnxertoPomar_1.TipoSobrevivenciaEnxertoPomar.SOBREVIVENCIA_90_DIAS) ? enxertoAno3.sobrevivencia90dias :
                                        ((ctrl.controleEnxertiaPomarSelecionado.tipoSobrevivencia === TipoSobrevivenciaEnxertoPomar_1.TipoSobrevivenciaEnxertoPomar.SOBREVIVENCIA_60_DIAS) ? enxertoAno3.sobrevivencia60dias :
                                            enxertoAno3.sobrevivencia)), {
                                        order: planilhaEnxertoAno3Idx,
                                        success: (data) => {
                                            if ((typeof data !== 'undefined') && data && (data.length > 0) && (posicaoMaterialGeneticoCroqui.row < data.length) &&
                                                (typeof data[posicaoMaterialGeneticoCroqui.row] !== 'undefined') && data[posicaoMaterialGeneticoCroqui.row] &&
                                                ((posicaoMaterialGeneticoCroqui.col + 1) < data[posicaoMaterialGeneticoCroqui.row].length)) {
                                                const cell = data[posicaoMaterialGeneticoCroqui.row][posicaoMaterialGeneticoCroqui.col + 1];
                                                if ((typeof cell !== 'undefined') && cell) {
                                                    if ((typeof cell.ps !== 'undefined') && cell.ps && (typeof cell.ps.value !== 'undefined') && cell.ps.value && (String(cell.ps.value).length > 0)) {
                                                        cell.ps.value = String(cell.ps.value).replace('\r\n' + comentario, '').replace(comentario, '') + '\r\n' + comentario;
                                                    }
                                                    else {
                                                        cell.ps = { left: null, top: null, width: 400, height: null, value: comentario, isshow: false };
                                                    }
                                                    globalThis.luckysheet.setCellValue(posicaoMaterialGeneticoCroqui.row, posicaoMaterialGeneticoCroqui.col + 1, cell, {
                                                        order: planilhaEnxertoAno3Idx,
                                                        success: () => {
                                                            if ((typeof globalThis.timeoutSalvarCroquiPomar !== 'undefined') && globalThis.timeoutSalvarCroquiPomar) {
                                                                clearTimeout(globalThis.timeoutSalvarCroquiPomar);
                                                                globalThis.timeoutSalvarCroquiPomar = null;
                                                            }
                                                            globalThis.timeoutSalvarCroquiPomar = setTimeout((ctrl) => {
                                                                ctrl.salvarCroquiPomar();
                                                            }, 1500, ctrl);
                                                        }
                                                    });
                                                }
                                            }
                                        }
                                    });
                                }
                            });
                        }
                    }
                    if (alterouPlanilhaCroqui) {
                        yield ctrl.salvarCroquiPomar();
                    }
                    if (alterouControleEnxertiaPomar &&
                        (typeof ctrl.controleEnxertiaPomarSelecionado !== 'undefined') && ctrl.controleEnxertiaPomarSelecionado &&
                        (typeof ctrl.controleEnxertiaPomarSelecionado.id !== 'undefined') && ctrl.controleEnxertiaPomarSelecionado.id) {
                        ctrl.controleEnxertiaPomarSelecionado = yield ctrl.sincronia
                            .rota(RotaAPIConstantes_1.RotaAPIConstantes.CONTROLE_ENXERTIA_POMAR)
                            .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CONTROLE_ENXERTIA_POMAR)
                            .atualizar(ctrl.controleEnxertiaPomarSelecionado);
                    }
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (ctrl.controleEnxertiaPomarSelecionado && ctrl.controleEnxertiaPomarSelecionado.id) {
                    ctrl.modalControleEnxertiaPomar = false;
                    if (ctrl.ehCelular) {
                        alert(alterouPlanilhaCroqui ? 'Registro salvo com sucesso e Croqui atualizado!' : 'Salvo com sucesso!');
                    }
                    else {
                        sweetalert2_1.default.fire({
                            title: (alterouPlanilhaCroqui ? 'Salvo com sucesso e Croqui atualizado!' : 'Salvo com sucesso!'),
                            text: (alterouPlanilhaCroqui ? 'O lançamento foi salvo no banco de dados e a planilha do Croqui foi atualizada.' : 'O lançamento foi salvo no banco de dados.'),
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'Ok'
                        });
                    }
                    ctrl.listaOpcoesEnxertiaPomar = yield ctrl.dadosListaEnxertiaPomar();
                }
                ctrl.ocultarModalControleEnxertiaPomar();
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static exibirModalControleEnxertiaPomar(ctrl, dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.pomarSelecionado === 'undefined') || !ctrl.pomarSelecionado) {
                return;
            }
            try {
                ctrl.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                ctrl.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                ctrl.controleEnxertiaPomarVisualizar = true;
                if ((typeof dados !== 'undefined') && dados && (typeof dados.id !== 'undefined') && dados.id) {
                    ctrl.controleEnxertiaPomarSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.CONTROLE_ENXERTIA_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CONTROLE_ENXERTIA_POMAR)
                        .id(dados.id)
                        .ver();
                    if ((typeof ctrl.controleEnxertiaPomarSelecionado === 'undefined') || !ctrl.controleEnxertiaPomarSelecionado) {
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        if (ctrl.ehCelular) {
                            alert(`Registro "${dados.id} não encontrado!`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                text: `Registro "${dados.id}" não encontrado.`,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        return;
                    }
                    if ((typeof ctrl.controleEnxertiaPomarSelecionado.criacao === 'string') && ctrl.controleEnxertiaPomarSelecionado.criacao) {
                        ctrl.controleEnxertiaPomarSelecionado.criacao = new Date(ctrl.controleEnxertiaPomarSelecionado.criacao);
                    }
                    if ((typeof ctrl.controleEnxertiaPomarSelecionado.data === 'string') && ctrl.controleEnxertiaPomarSelecionado.data) {
                        ctrl.controleEnxertiaPomarSelecionado.data = new Date(ctrl.controleEnxertiaPomarSelecionado.data);
                    }
                    if ((typeof ctrl.controleEnxertiaPomarSelecionado.dataHoraInclusao === 'string') && ctrl.controleEnxertiaPomarSelecionado.dataHoraInclusao) {
                        ctrl.controleEnxertiaPomarSelecionado.dataHoraInclusao = new Date(ctrl.controleEnxertiaPomarSelecionado.dataHoraInclusao);
                    }
                    if ((typeof ctrl.controleEnxertiaPomarSelecionado.dataHoraAlteracao === 'string') && ctrl.controleEnxertiaPomarSelecionado.dataHoraAlteracao) {
                        ctrl.controleEnxertiaPomarSelecionado.dataHoraAlteracao = new Date(ctrl.controleEnxertiaPomarSelecionado.dataHoraAlteracao);
                    }
                    if ((typeof dados.visualizar !== 'undefined') && dados.visualizar) {
                        ctrl.controleEnxertiaPomarVisualizar = true;
                    }
                    else if ((typeof dados.editar !== 'undefined') && dados.editar) {
                        ctrl.controleEnxertiaPomarVisualizar = false;
                    }
                    else if ((typeof dados.excluir !== 'undefined') && dados.excluir) {
                        ctrl.notificacao
                            .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                            .then((result) => __awaiter(this, void 0, void 0, function* () {
                            if (result.value) {
                                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.CONTROLE_ENXERTIA_POMAR)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CONTROLE_ENXERTIA_POMAR)
                                    .id(dados.id)
                                    .remover();
                                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                                ctrl.listaOpcoesEnxertiaPomar = yield ctrl.dadosListaEnxertiaPomar();
                                ctrl.scopeApply();
                            }
                        }));
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                }
                else {
                    ctrl.controleEnxertiaPomarSelecionado = new ControleEnxertiaPomar_1.ControleEnxertiaPomar();
                    ctrl.controleEnxertiaPomarSelecionado.criacao = new Date();
                    ctrl.controleEnxertiaPomarSelecionado.criacao.setMilliseconds(0);
                    ctrl.controleEnxertiaPomarSelecionado.data = new Date();
                    ctrl.controleEnxertiaPomarSelecionado.data.setMilliseconds(0);
                    ctrl.controleEnxertiaPomarSelecionado.portaEnxertos = new Array();
                    ctrl.controleEnxertiaPomarSelecionado.enxertosAno1 = new Array();
                    ctrl.controleEnxertiaPomarSelecionado.enxertosAno2 = new Array();
                    ctrl.controleEnxertiaPomarSelecionado.enxertosAno3 = new Array();
                    let enxerto = new EnxertoPomar_1.EnxertoPomar();
                    enxerto.status = true;
                    enxerto.criacao = new Date();
                    enxerto.plantio = 3;
                    enxerto.replantio = 0;
                    if ((typeof dados !== 'undefined') && dados && (typeof dados.materialGeneticoCroqui !== 'undefined') && dados.materialGeneticoCroqui &&
                        (typeof dados.materialGeneticoCroqui.materialGenetico !== 'undefined') && (dados.materialGeneticoCroqui.materialGenetico !== null)) {
                        let referenciaMaterialGeneticoCroqui = TextoUtil_1.TextoUtil.converteTextoParaReferencia(dados.materialGeneticoCroqui.materialGenetico);
                        if (referenciaMaterialGeneticoCroqui && (referenciaMaterialGeneticoCroqui.length > 0)) {
                            let materialGeneticoCroqui = null;
                            if ((typeof ctrl.materiaisGeneticosFiltrados !== 'undefined') && ctrl.materiaisGeneticosFiltrados && (ctrl.materiaisGeneticosFiltrados.length > 0)) {
                                angular.forEach(ctrl.materiaisGeneticosFiltrados, (materialGenetico) => {
                                    if (!materialGeneticoCroqui && materialGenetico.descricao && (TextoUtil_1.TextoUtil.converteTextoParaReferencia(materialGenetico.descricao) === referenciaMaterialGeneticoCroqui)) {
                                        materialGeneticoCroqui = materialGenetico;
                                    }
                                });
                            }
                            else if ((typeof ctrl.materiaisGeneticos !== 'undefined') && ctrl.materiaisGeneticos && (ctrl.materiaisGeneticos.length > 0)) {
                                angular.forEach(ctrl.materiaisGeneticos, (materialGenetico) => {
                                    if (!materialGeneticoCroqui && materialGenetico.descricao && (TextoUtil_1.TextoUtil.converteTextoParaReferencia(materialGenetico.descricao) === referenciaMaterialGeneticoCroqui)) {
                                        materialGeneticoCroqui = materialGenetico;
                                    }
                                });
                            }
                            if ((typeof materialGeneticoCroqui !== 'undefined') && materialGeneticoCroqui &&
                                (typeof materialGeneticoCroqui.id !== 'undefined') && materialGeneticoCroqui.id) {
                                enxerto.materialGenetico = materialGeneticoCroqui;
                            }
                        }
                    }
                    if ((typeof dados !== 'undefined') && dados && (typeof dados.materialGeneticoCroqui !== 'undefined') && dados.materialGeneticoCroqui &&
                        (typeof dados.materialGeneticoCroqui.fila !== 'undefined') && (dados.materialGeneticoCroqui.fila !== null)) {
                        enxerto.fila = dados.materialGeneticoCroqui.fila;
                    }
                    if ((typeof dados !== 'undefined') && dados && (typeof dados.materialGeneticoCroqui !== 'undefined') && dados.materialGeneticoCroqui &&
                        (typeof dados.materialGeneticoCroqui.coluna !== 'undefined') && (dados.materialGeneticoCroqui.coluna !== null)) {
                        enxerto.coluna = dados.materialGeneticoCroqui.coluna;
                    }
                    ctrl.controleEnxertiaPomarSelecionado.tipoEnxerto = TipoEnxertoPomar_1.TipoEnxertoPomar.PORTA_ENXERTO;
                    ctrl.controleEnxertiaPomarSelecionado.portaEnxertos.push(angular.copy(enxerto));
                    ctrl.alterarRegistroControleEnxertiaPomar(ctrl.controleEnxertiaPomarSelecionado.portaEnxertos[0]);
                    ctrl.controleEnxertiaPomarSelecionado.tipoEnxerto = TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_1;
                    ctrl.controleEnxertiaPomarSelecionado.enxertosAno1.push(angular.copy(enxerto));
                    ctrl.alterarRegistroControleEnxertiaPomar(ctrl.controleEnxertiaPomarSelecionado.enxertosAno1[0]);
                    ctrl.controleEnxertiaPomarSelecionado.tipoEnxerto = TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_2;
                    ctrl.controleEnxertiaPomarSelecionado.enxertosAno2.push(angular.copy(enxerto));
                    ctrl.alterarRegistroControleEnxertiaPomar(ctrl.controleEnxertiaPomarSelecionado.enxertosAno2[0]);
                    ctrl.controleEnxertiaPomarSelecionado.tipoEnxerto = TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_3;
                    ctrl.controleEnxertiaPomarSelecionado.enxertosAno3.push(angular.copy(enxerto));
                    ctrl.alterarRegistroControleEnxertiaPomar(ctrl.controleEnxertiaPomarSelecionado.enxertosAno3[0]);
                    ctrl.controleEnxertiaPomarSelecionado.tipoEnxerto = null;
                    if ((typeof dados !== 'undefined') && dados && (typeof dados.tipoEnxertoCroqui !== 'undefined') && dados.tipoEnxertoCroqui) {
                        ctrl.controleEnxertiaPomarSelecionado.tipoEnxerto = dados.tipoEnxertoCroqui;
                    }
                    ctrl.controleEnxertiaPomarVisualizar = false;
                }
                ctrl.modalControleEnxertiaPomar = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static ocultarModalControleEnxertiaPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.modalControleEnxertiaPomar = false;
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = ctrl.posicaoTopoAntesModal;
            ctrl.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                        (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    static adicionarRegistroEnxertoControleEnxertiaPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.controleEnxertiaPomarSelecionado || !ctrl.controleEnxertiaPomarSelecionado.tipoEnxerto) {
                return;
            }
            if ((typeof ctrl.controleEnxertiaPomarSelecionado.portaEnxertos === 'undefined') || !ctrl.controleEnxertiaPomarSelecionado.portaEnxertos) {
                ctrl.controleEnxertiaPomarSelecionado.portaEnxertos = new Array();
            }
            if ((typeof ctrl.controleEnxertiaPomarSelecionado.enxertosAno1 === 'undefined') || !ctrl.controleEnxertiaPomarSelecionado.enxertosAno1) {
                ctrl.controleEnxertiaPomarSelecionado.enxertosAno1 = new Array();
            }
            if ((typeof ctrl.controleEnxertiaPomarSelecionado.enxertosAno2 === 'undefined') || !ctrl.controleEnxertiaPomarSelecionado.enxertosAno2) {
                ctrl.controleEnxertiaPomarSelecionado.enxertosAno2 = new Array();
            }
            if ((typeof ctrl.controleEnxertiaPomarSelecionado.enxertosAno3 === 'undefined') || !ctrl.controleEnxertiaPomarSelecionado.enxertosAno3) {
                ctrl.controleEnxertiaPomarSelecionado.enxertosAno3 = new Array();
            }
            let enxerto = new EnxertoPomar_1.EnxertoPomar();
            enxerto.status = true;
            enxerto.criacao = new Date();
            enxerto.plantio = 1;
            enxerto.replantio = 1;
            if (ctrl.controleEnxertiaPomarSelecionado.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.PORTA_ENXERTO) {
                ctrl.controleEnxertiaPomarSelecionado.portaEnxertos.push(enxerto);
            }
            else if (ctrl.controleEnxertiaPomarSelecionado.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_1) {
                ctrl.controleEnxertiaPomarSelecionado.enxertosAno1.push(enxerto);
            }
            else if (ctrl.controleEnxertiaPomarSelecionado.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_2) {
                ctrl.controleEnxertiaPomarSelecionado.enxertosAno2.push(enxerto);
            }
            else if (ctrl.controleEnxertiaPomarSelecionado.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_3) {
                ctrl.controleEnxertiaPomarSelecionado.enxertosAno3.push(enxerto);
            }
            yield ctrl.totalizarControleEnxertiaPomar();
            ctrl.scopeApply();
        });
    }
    static removerRegistroEnxertoControleEnxertiaPomar(ctrl, keyEnxerto) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.controleEnxertiaPomarSelecionado || !ctrl.controleEnxertiaPomarSelecionado.tipoEnxerto) {
                return;
            }
            if ((typeof ctrl.controleEnxertiaPomarSelecionado.portaEnxertos === 'undefined') || !ctrl.controleEnxertiaPomarSelecionado.portaEnxertos) {
                ctrl.controleEnxertiaPomarSelecionado.portaEnxertos = new Array();
            }
            if ((typeof ctrl.controleEnxertiaPomarSelecionado.enxertosAno1 === 'undefined') || !ctrl.controleEnxertiaPomarSelecionado.enxertosAno1) {
                ctrl.controleEnxertiaPomarSelecionado.enxertosAno1 = new Array();
            }
            if ((typeof ctrl.controleEnxertiaPomarSelecionado.enxertosAno2 === 'undefined') || !ctrl.controleEnxertiaPomarSelecionado.enxertosAno2) {
                ctrl.controleEnxertiaPomarSelecionado.enxertosAno2 = new Array();
            }
            if ((typeof ctrl.controleEnxertiaPomarSelecionado.enxertosAno3 === 'undefined') || !ctrl.controleEnxertiaPomarSelecionado.enxertosAno3) {
                ctrl.controleEnxertiaPomarSelecionado.enxertosAno3 = new Array();
            }
            if (ctrl.controleEnxertiaPomarSelecionado.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.PORTA_ENXERTO) {
                if ((typeof keyEnxerto !== 'undefined') && (keyEnxerto !== null) &&
                    (ctrl.controleEnxertiaPomarSelecionado.portaEnxertos.length > 0) &&
                    (keyEnxerto <= ctrl.controleEnxertiaPomarSelecionado.portaEnxertos.length - 1)) {
                    const numRegistro = keyEnxerto + 1;
                    if (window.confirm('Deseja realmente remover esse registro #' + numRegistro + ' ?')) {
                        ctrl.controleEnxertiaPomarSelecionado.portaEnxertos.splice(keyEnxerto, 1);
                        yield ctrl.totalizarControleEnxertiaPomar();
                        ctrl.scopeApply();
                    }
                }
            }
            else if (ctrl.controleEnxertiaPomarSelecionado.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_1) {
                if ((typeof keyEnxerto !== 'undefined') && (keyEnxerto !== null) &&
                    (ctrl.controleEnxertiaPomarSelecionado.enxertosAno1.length > 0) &&
                    (keyEnxerto <= ctrl.controleEnxertiaPomarSelecionado.enxertosAno1.length - 1)) {
                    const numRegistro = keyEnxerto + 1;
                    if (window.confirm('Deseja realmente remover esse registro #' + numRegistro + ' ?')) {
                        ctrl.controleEnxertiaPomarSelecionado.enxertosAno1.splice(keyEnxerto, 1);
                        yield ctrl.totalizarControleEnxertiaPomar();
                        ctrl.scopeApply();
                    }
                }
            }
            else if (ctrl.controleEnxertiaPomarSelecionado.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_2) {
                if ((typeof keyEnxerto !== 'undefined') && (keyEnxerto !== null) &&
                    (ctrl.controleEnxertiaPomarSelecionado.enxertosAno2.length > 0) &&
                    (keyEnxerto <= ctrl.controleEnxertiaPomarSelecionado.enxertosAno2.length - 1)) {
                    const numRegistro = keyEnxerto + 1;
                    if (window.confirm('Deseja realmente remover esse registro #' + numRegistro + ' ?')) {
                        ctrl.controleEnxertiaPomarSelecionado.enxertosAno2.splice(keyEnxerto, 1);
                        yield ctrl.totalizarControleEnxertiaPomar();
                        ctrl.scopeApply();
                    }
                }
            }
            else if (ctrl.controleEnxertiaPomarSelecionado.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_3) {
                if ((typeof keyEnxerto !== 'undefined') && (keyEnxerto !== null) &&
                    (ctrl.controleEnxertiaPomarSelecionado.enxertosAno3.length > 0) &&
                    (keyEnxerto <= ctrl.controleEnxertiaPomarSelecionado.enxertosAno3.length - 1)) {
                    const numRegistro = keyEnxerto + 1;
                    if (window.confirm('Deseja realmente remover esse registro #' + numRegistro + ' ?')) {
                        ctrl.controleEnxertiaPomarSelecionado.enxertosAno3.splice(keyEnxerto, 1);
                        yield ctrl.totalizarControleEnxertiaPomar();
                        ctrl.scopeApply();
                    }
                }
            }
        });
    }
    static moverRegistroEnxertoControleEnxertiaPomarParaCima(ctrl, keyEnxerto) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.controleEnxertiaPomarSelecionado || !ctrl.controleEnxertiaPomarSelecionado.tipoEnxerto) {
                return;
            }
            if ((typeof ctrl.controleEnxertiaPomarSelecionado.portaEnxertos === 'undefined') || !ctrl.controleEnxertiaPomarSelecionado.portaEnxertos) {
                ctrl.controleEnxertiaPomarSelecionado.portaEnxertos = new Array();
            }
            if ((typeof ctrl.controleEnxertiaPomarSelecionado.enxertosAno1 === 'undefined') || !ctrl.controleEnxertiaPomarSelecionado.enxertosAno1) {
                ctrl.controleEnxertiaPomarSelecionado.enxertosAno1 = new Array();
            }
            if ((typeof ctrl.controleEnxertiaPomarSelecionado.enxertosAno2 === 'undefined') || !ctrl.controleEnxertiaPomarSelecionado.enxertosAno2) {
                ctrl.controleEnxertiaPomarSelecionado.enxertosAno2 = new Array();
            }
            if ((typeof ctrl.controleEnxertiaPomarSelecionado.enxertosAno3 === 'undefined') || !ctrl.controleEnxertiaPomarSelecionado.enxertosAno3) {
                ctrl.controleEnxertiaPomarSelecionado.enxertosAno3 = new Array();
            }
            if (ctrl.controleEnxertiaPomarSelecionado.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.PORTA_ENXERTO) {
                if ((keyEnxerto !== null) && (keyEnxerto > 0) && (keyEnxerto <= ctrl.controleEnxertiaPomarSelecionado.portaEnxertos.length - 1)) {
                    let oldKey = keyEnxerto;
                    let newKey = keyEnxerto - 1;
                    ctrl.controleEnxertiaPomarSelecionado.portaEnxertos.splice(newKey, 0, ctrl.controleEnxertiaPomarSelecionado.portaEnxertos.splice(oldKey, 1)[0]);
                    ctrl.scopeApply();
                }
            }
            else if (ctrl.controleEnxertiaPomarSelecionado.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_1) {
                if ((keyEnxerto !== null) && (keyEnxerto > 0) && (keyEnxerto <= ctrl.controleEnxertiaPomarSelecionado.enxertosAno1.length - 1)) {
                    let oldKey = keyEnxerto;
                    let newKey = keyEnxerto - 1;
                    ctrl.controleEnxertiaPomarSelecionado.enxertosAno1.splice(newKey, 0, ctrl.controleEnxertiaPomarSelecionado.enxertosAno1.splice(oldKey, 1)[0]);
                    ctrl.scopeApply();
                }
            }
            else if (ctrl.controleEnxertiaPomarSelecionado.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_2) {
                if ((keyEnxerto !== null) && (keyEnxerto > 0) && (keyEnxerto <= ctrl.controleEnxertiaPomarSelecionado.enxertosAno2.length - 1)) {
                    let oldKey = keyEnxerto;
                    let newKey = keyEnxerto - 1;
                    ctrl.controleEnxertiaPomarSelecionado.enxertosAno2.splice(newKey, 0, ctrl.controleEnxertiaPomarSelecionado.enxertosAno2.splice(oldKey, 1)[0]);
                    ctrl.scopeApply();
                }
            }
            else if (ctrl.controleEnxertiaPomarSelecionado.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_3) {
                if ((keyEnxerto !== null) && (keyEnxerto > 0) && (keyEnxerto <= ctrl.controleEnxertiaPomarSelecionado.enxertosAno3.length - 1)) {
                    let oldKey = keyEnxerto;
                    let newKey = keyEnxerto - 1;
                    ctrl.controleEnxertiaPomarSelecionado.enxertosAno3.splice(newKey, 0, ctrl.controleEnxertiaPomarSelecionado.enxertosAno3.splice(oldKey, 1)[0]);
                    ctrl.scopeApply();
                }
            }
        });
    }
    static moverRegistroEnxertoControleEnxertiaPomarParaBaixo(ctrl, keyEnxerto) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.controleEnxertiaPomarSelecionado || !ctrl.controleEnxertiaPomarSelecionado.tipoEnxerto) {
                return;
            }
            if ((typeof ctrl.controleEnxertiaPomarSelecionado.portaEnxertos === 'undefined') || !ctrl.controleEnxertiaPomarSelecionado.portaEnxertos) {
                ctrl.controleEnxertiaPomarSelecionado.portaEnxertos = new Array();
            }
            if ((typeof ctrl.controleEnxertiaPomarSelecionado.enxertosAno1 === 'undefined') || !ctrl.controleEnxertiaPomarSelecionado.enxertosAno1) {
                ctrl.controleEnxertiaPomarSelecionado.enxertosAno1 = new Array();
            }
            if ((typeof ctrl.controleEnxertiaPomarSelecionado.enxertosAno2 === 'undefined') || !ctrl.controleEnxertiaPomarSelecionado.enxertosAno2) {
                ctrl.controleEnxertiaPomarSelecionado.enxertosAno2 = new Array();
            }
            if ((typeof ctrl.controleEnxertiaPomarSelecionado.enxertosAno3 === 'undefined') || !ctrl.controleEnxertiaPomarSelecionado.enxertosAno3) {
                ctrl.controleEnxertiaPomarSelecionado.enxertosAno3 = new Array();
            }
            if (ctrl.controleEnxertiaPomarSelecionado.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.PORTA_ENXERTO) {
                if ((keyEnxerto != null) && (keyEnxerto >= 0) && (keyEnxerto < ctrl.controleEnxertiaPomarSelecionado.portaEnxertos.length - 1)) {
                    let oldKey = keyEnxerto;
                    let newKey = keyEnxerto + 1;
                    ctrl.controleEnxertiaPomarSelecionado.portaEnxertos.splice(newKey, 0, ctrl.controleEnxertiaPomarSelecionado.portaEnxertos.splice(oldKey, 1)[0]);
                    ctrl.scopeApply();
                }
            }
            else if (ctrl.controleEnxertiaPomarSelecionado.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_1) {
                if ((keyEnxerto != null) && (keyEnxerto >= 0) && (keyEnxerto < ctrl.controleEnxertiaPomarSelecionado.enxertosAno1.length - 1)) {
                    let oldKey = keyEnxerto;
                    let newKey = keyEnxerto + 1;
                    ctrl.controleEnxertiaPomarSelecionado.enxertosAno1.splice(newKey, 0, ctrl.controleEnxertiaPomarSelecionado.enxertosAno1.splice(oldKey, 1)[0]);
                    ctrl.scopeApply();
                }
            }
            else if (ctrl.controleEnxertiaPomarSelecionado.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_2) {
                if ((keyEnxerto != null) && (keyEnxerto >= 0) && (keyEnxerto < ctrl.controleEnxertiaPomarSelecionado.enxertosAno2.length - 1)) {
                    let oldKey = keyEnxerto;
                    let newKey = keyEnxerto + 1;
                    ctrl.controleEnxertiaPomarSelecionado.enxertosAno2.splice(newKey, 0, ctrl.controleEnxertiaPomarSelecionado.enxertosAno2.splice(oldKey, 1)[0]);
                    ctrl.scopeApply();
                }
            }
            else if (ctrl.controleEnxertiaPomarSelecionado.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_3) {
                if ((keyEnxerto != null) && (keyEnxerto >= 0) && (keyEnxerto < ctrl.controleEnxertiaPomarSelecionado.enxertosAno3.length - 1)) {
                    let oldKey = keyEnxerto;
                    let newKey = keyEnxerto + 1;
                    ctrl.controleEnxertiaPomarSelecionado.enxertosAno3.splice(newKey, 0, ctrl.controleEnxertiaPomarSelecionado.enxertosAno3.splice(oldKey, 1)[0]);
                    ctrl.scopeApply();
                }
            }
        });
    }
    static totalizarControleEnxertiaPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.controleEnxertiaPomarSelecionado !== 'undefined') && ctrl.controleEnxertiaPomarSelecionado) {
            }
        });
    }
    static alterarRegistroControleEnxertiaPomar(ctrl, enxerto) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.controleEnxertiaPomarSelecionado) {
                return;
            }
            if (ctrl.controleEnxertiaPomarSelecionado.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.PORTA_ENXERTO) {
                const numPlantio = (typeof enxerto.plantio !== 'undefined') && enxerto.plantio ? enxerto.plantio : 0;
                const numSobrevivencia = (typeof enxerto.sobrevivencia !== 'undefined') && enxerto.sobrevivencia ? enxerto.sobrevivencia : 0;
                enxerto.replantio = ((numPlantio > numSobrevivencia) ? (numPlantio - numSobrevivencia) : 0);
            }
            else if ((ctrl.controleEnxertiaPomarSelecionado.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_1) ||
                (ctrl.controleEnxertiaPomarSelecionado.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_2) ||
                (ctrl.controleEnxertiaPomarSelecionado.tipoEnxerto === TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_3)) {
                const controleEnxertiaPomarSelecionadoId = (typeof ctrl.controleEnxertiaPomarSelecionado.id !== 'undefined') ? ctrl.controleEnxertiaPomarSelecionado.id : null;
                let achouFilaColuna = false;
                if ((typeof enxerto.fila !== 'undefined') && (enxerto.fila !== null) &&
                    (typeof enxerto.coluna !== 'undefined') && (enxerto.coluna !== null) &&
                    (typeof ctrl.listaOpcoesEnxertiaPomar !== 'undefined') && ctrl.listaOpcoesEnxertiaPomar &&
                    (typeof ctrl.listaOpcoesEnxertiaPomar.data !== 'undefined') && ctrl.listaOpcoesEnxertiaPomar.data) {
                    let posicoesRealizadasAnterior = 0;
                    angular.forEach(ctrl.listaOpcoesEnxertiaPomar.data, (registro) => {
                        if ((registro.id !== controleEnxertiaPomarSelecionadoId) &&
                            (typeof registro.fila !== 'undefined') && registro.fila && (registro.fila === enxerto.fila) &&
                            (typeof registro.coluna !== 'undefined') && registro.coluna && (registro.coluna === enxerto.coluna)) {
                            achouFilaColuna = true;
                            if ((typeof registro.sobrevivencia90dias !== 'undefined') && registro.sobrevivencia90dias) {
                                posicoesRealizadasAnterior = registro.sobrevivencia90dias;
                            }
                            else if ((typeof registro.sobrevivencia60dias !== 'undefined') && registro.sobrevivencia60dias) {
                                posicoesRealizadasAnterior = registro.sobrevivencia60dias;
                            }
                            else if ((typeof registro.sobrevivencia !== 'undefined') && registro.sobrevivencia) {
                                posicoesRealizadasAnterior = registro.sobrevivencia;
                            }
                        }
                    });
                    if (posicoesRealizadasAnterior && (posicoesRealizadasAnterior > 0)) {
                        enxerto.posicoesRealizadas = posicoesRealizadasAnterior;
                    }
                }
                if (!achouFilaColuna &&
                    (typeof enxerto.materialGenetico !== 'undefined') && (enxerto.materialGenetico !== null) &&
                    (typeof enxerto.materialGenetico.id !== 'undefined') && (enxerto.materialGenetico.id !== null) &&
                    (typeof ctrl.listaOpcoesEnxertiaPomar !== 'undefined') && ctrl.listaOpcoesEnxertiaPomar &&
                    (typeof ctrl.listaOpcoesEnxertiaPomar.data !== 'undefined') && ctrl.listaOpcoesEnxertiaPomar.data) {
                    let posicoesRealizadasAnterior = 0;
                    angular.forEach(ctrl.listaOpcoesEnxertiaPomar.data, (registro) => {
                        if ((registro.id !== controleEnxertiaPomarSelecionadoId) &&
                            (typeof registro.materialGenetico !== 'undefined') && registro.materialGenetico &&
                            (typeof registro.materialGenetico.id !== 'undefined') && registro.materialGenetico.id &&
                            (registro.materialGenetico.id === enxerto.materialGenetico.id)) {
                            if ((typeof registro.sobrevivencia90dias !== 'undefined') && registro.sobrevivencia90dias) {
                                posicoesRealizadasAnterior = registro.sobrevivencia90dias;
                            }
                            else if ((typeof registro.sobrevivencia60dias !== 'undefined') && registro.sobrevivencia60dias) {
                                posicoesRealizadasAnterior = registro.sobrevivencia60dias;
                            }
                            else if ((typeof registro.sobrevivencia !== 'undefined') && registro.sobrevivencia) {
                                posicoesRealizadasAnterior = registro.sobrevivencia;
                            }
                        }
                    });
                    if (posicoesRealizadasAnterior && (posicoesRealizadasAnterior > 0)) {
                        enxerto.posicoesRealizadas = posicoesRealizadasAnterior;
                    }
                }
            }
        });
    }
}
exports.PomarBancoConservacaoControleEnxertiaUtil = PomarBancoConservacaoControleEnxertiaUtil;
