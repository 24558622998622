"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FerramentaRota = void 0;
const RotaFactory_1 = require("../../fabricas/RotaFactory");
const FerramentaModulo_1 = require("./FerramentaModulo");
const ConfiguracaoGeralController_1 = require("./paginas/configuracaoGeral/ConfiguracaoGeralController");
const DashboardController_1 = require("./paginas/dashboard/DashboardController");
class FerramentaRota {
    constructor($stateProvider) {
        this.$stateProvider = $stateProvider;
        this.rota = null;
        this.rota = new RotaFactory_1.RotaFactory(this.$stateProvider);
        this.iniciar();
    }
    iniciar() {
        this.registrar();
    }
    registrar() {
        this.rota.modulo(FerramentaModulo_1.FerramentaModulo);
        const configuracaoGeral = {
            controladora: ConfiguracaoGeralController_1.ConfiguracaoGeralController,
            nome: 'configuracaoGeral',
            titulo: 'Parametrizações',
            autenticar: true
        };
        this.rota.addListar(configuracaoGeral);
        configuracaoGeral.titulo = 'Visualizar Parametrização';
        this.rota.addVisualizar(configuracaoGeral);
        configuracaoGeral.titulo = 'Adicionar nova Parametrização';
        this.rota.addCriar(configuracaoGeral);
        configuracaoGeral.titulo = 'Editar Parametrização';
        this.rota.addEditar(configuracaoGeral);
        const dashboard = {
            controladora: DashboardController_1.DashboardController,
            nome: 'dashboard',
            titulo: 'Dashboard',
            autenticar: true,
            template: require('../../modulos/ferramenta/paginas/dashboard/lista.html')
        };
        this.rota.addListar(dashboard);
    }
}
exports.FerramentaRota = FerramentaRota;
FerramentaRota.$inject = ['$stateProvider'];
