"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PomarBancoConservacaoControleProdutividadeUtil = void 0;
const angular = require("angular");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const ProdutividadePomar_1 = require("../../../../../modelos/ProdutividadePomar");
const ProdutividadeFuncionarioPomar_1 = require("../../../../../modelos/ProdutividadeFuncionarioPomar");
const sweetalert2_1 = require("sweetalert2");
class PomarBancoConservacaoControleProdutividadeUtil {
    static filtrarProdutividadePomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.pomarSelecionado || !ctrl.pomarSelecionado.id) {
                    return;
                }
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                ctrl.listaOpcoesProdutividadePomar = yield ctrl.dadosListaProdutividadePomar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.scopeApply();
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static salvarProdutividadePomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.produtividadePomarSelecionado || ctrl.produtividadePomarVisualizar) {
                    return;
                }
                let listaCamposObrigatorios = '';
                if (listaCamposObrigatorios && (listaCamposObrigatorios.length > 0)) {
                    let mensagem = 'Campo obrigatório não preenchido!' +
                        listaCamposObrigatorios + '\r\n \r\n' +
                        'Preencha este campo e tente novamente.';
                    if (ctrl.ehCelular) {
                        alert(mensagem.replace(/<b>/g, '').replace(/<\/b>/g, ''));
                    }
                    else {
                        yield sweetalert2_1.default.fire({
                            html: mensagem.replace(/\r\n/g, '<br>\r\n'),
                            icon: 'info',
                            showCancelButton: false,
                            showConfirmButton: true,
                            confirmButtonText: 'Ok'
                        });
                    }
                    return;
                }
                ctrl.modalTratamentoPomar = false;
                ctrl.modalGerarCroquiPomar = false;
                ctrl.modalHistoricoCroquiPomar = false;
                ctrl.modalPlanejamentoPomar = false;
                ctrl.modalControleManejoPomar = false;
                ctrl.modalControleEnxertiaPomar = false;
                ctrl.modalFenologiaPomar = false;
                ctrl.modalPolinizacaoPomar = false;
                ctrl.modalRelatorioPomar = false;
                ctrl.modalAnexoPomar = false;
                ctrl.produtividadePomarSelecionado.pomar = ctrl.pomarSelecionado;
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                if (!ctrl.produtividadePomarSelecionado.id) {
                    ctrl.produtividadePomarSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTIVIDADE_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTIVIDADE_POMAR)
                        .criar(ctrl.produtividadePomarSelecionado);
                }
                else {
                    ctrl.produtividadePomarSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTIVIDADE_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTIVIDADE_POMAR)
                        .atualizar(ctrl.produtividadePomarSelecionado);
                }
                if ((typeof ctrl.produtividadePomarSelecionado.pomar !== 'undefined') && ctrl.produtividadePomarSelecionado.pomar &&
                    (typeof ctrl.produtividadePomarSelecionado.pomar.descricao !== 'undefined') && ctrl.produtividadePomarSelecionado.pomar.descricao) {
                    ctrl.produtividadePomarSelecionado.pomarDescricao = ctrl.produtividadePomarSelecionado.pomar.descricao;
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (ctrl.produtividadePomarSelecionado && ctrl.produtividadePomarSelecionado.id) {
                    ctrl.modalProdutividadePomar = false;
                    if (ctrl.ehCelular) {
                        alert(`Salvo com sucesso!`);
                    }
                    else {
                        sweetalert2_1.default.fire({
                            title: 'Salvo com sucesso!',
                            text: 'O lançamento foi salvo no banco de dados.',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'Ok'
                        });
                    }
                    ctrl.listaOpcoesProdutividadePomar = yield ctrl.dadosListaProdutividadePomar();
                }
                ctrl.ocultarModalProdutividadePomar();
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static exibirModalProdutividadePomar(ctrl, dados = null) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.pomarSelecionado === 'undefined') || !ctrl.pomarSelecionado) {
                return;
            }
            try {
                ctrl.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                ctrl.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                ctrl.produtividadePomarVisualizar = true;
                if ((typeof dados !== 'undefined') && dados && (typeof dados.id !== 'undefined') && dados.id) {
                    ctrl.produtividadePomarSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTIVIDADE_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTIVIDADE_POMAR)
                        .id(dados.id)
                        .ver();
                    if ((typeof ctrl.produtividadePomarSelecionado === 'undefined') || !ctrl.produtividadePomarSelecionado) {
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        if (ctrl.ehCelular) {
                            alert(`Registro "${dados.id} não encontrado!`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                text: `Registro "${dados.id}" não encontrado.`,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        return;
                    }
                    if ((typeof ctrl.produtividadePomarSelecionado.criacao === 'string') && ctrl.produtividadePomarSelecionado.criacao) {
                        ctrl.produtividadePomarSelecionado.criacao = new Date(ctrl.produtividadePomarSelecionado.criacao);
                    }
                    if ((typeof ctrl.produtividadePomarSelecionado.data === 'string') && ctrl.produtividadePomarSelecionado.data) {
                        ctrl.produtividadePomarSelecionado.data = new Date(ctrl.produtividadePomarSelecionado.data);
                    }
                    if ((typeof ctrl.produtividadePomarSelecionado.dataHoraInclusao === 'string') && ctrl.produtividadePomarSelecionado.dataHoraInclusao) {
                        ctrl.produtividadePomarSelecionado.dataHoraInclusao = new Date(ctrl.produtividadePomarSelecionado.dataHoraInclusao);
                    }
                    if ((typeof ctrl.produtividadePomarSelecionado.dataHoraAlteracao === 'string') && ctrl.produtividadePomarSelecionado.dataHoraAlteracao) {
                        ctrl.produtividadePomarSelecionado.dataHoraAlteracao = new Date(ctrl.produtividadePomarSelecionado.dataHoraAlteracao);
                    }
                    if ((typeof dados.visualizar !== 'undefined') && dados.visualizar) {
                        ctrl.produtividadePomarVisualizar = true;
                    }
                    else if ((typeof dados.editar !== 'undefined') && dados.editar) {
                        ctrl.produtividadePomarVisualizar = false;
                    }
                    else if ((typeof dados.excluir !== 'undefined') && dados.excluir) {
                        ctrl.notificacao
                            .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                            .then((result) => __awaiter(this, void 0, void 0, function* () {
                            if (result.value) {
                                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                                yield ctrl.sincronia
                                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PRODUTIVIDADE_POMAR)
                                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PRODUTIVIDADE_POMAR)
                                    .id(dados.id)
                                    .remover();
                                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                                ctrl.listaOpcoesProdutividadePomar = yield ctrl.dadosListaProdutividadePomar();
                                ctrl.scopeApply();
                            }
                        }));
                        CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                        return;
                    }
                }
                else {
                    ctrl.produtividadePomarSelecionado = new ProdutividadePomar_1.ProdutividadePomar();
                    ctrl.produtividadePomarSelecionado.criacao = new Date();
                    ctrl.produtividadePomarSelecionado.criacao.setMilliseconds(0);
                    ctrl.produtividadePomarSelecionado.funcionarios = new Array();
                    let funcionario = new ProdutividadeFuncionarioPomar_1.ProdutividadeFuncionarioPomar();
                    funcionario.status = true;
                    funcionario.criacao = new Date();
                    ctrl.produtividadePomarSelecionado.funcionarios.push(funcionario);
                    ctrl.produtividadePomarVisualizar = false;
                }
                ctrl.modalProdutividadePomar = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static ocultarModalProdutividadePomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.modalProdutividadePomar = false;
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = ctrl.posicaoTopoAntesModal;
            ctrl.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                        (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    static adicionarRegistroFuncionarioProdutividadePomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.produtividadePomarSelecionado) {
                return;
            }
            if ((typeof ctrl.produtividadePomarSelecionado.funcionarios === 'undefined') || !ctrl.produtividadePomarSelecionado.funcionarios) {
                ctrl.produtividadePomarSelecionado.funcionarios = [];
            }
            let funcionario = new ProdutividadeFuncionarioPomar_1.ProdutividadeFuncionarioPomar();
            funcionario.status = true;
            funcionario.criacao = new Date();
            ctrl.produtividadePomarSelecionado.funcionarios.push(funcionario);
            yield ctrl.totalizarProdutividadePomar();
            ctrl.scopeApply();
        });
    }
    static removerRegistroFuncionarioProdutividadePomar(ctrl, key) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.produtividadePomarSelecionado) {
                return;
            }
            if ((typeof ctrl.produtividadePomarSelecionado.funcionarios === 'undefined') || !ctrl.produtividadePomarSelecionado.funcionarios) {
                ctrl.produtividadePomarSelecionado.funcionarios = [];
            }
            if ((typeof key !== 'undefined') && (key !== null) &&
                (ctrl.produtividadePomarSelecionado.funcionarios.length > 0) &&
                (key <= ctrl.produtividadePomarSelecionado.funcionarios.length - 1)) {
                const numRegistro = key + 1;
                if (window.confirm('Deseja realmente remover esse registro #' + numRegistro + ' ?')) {
                    ctrl.produtividadePomarSelecionado.funcionarios.splice(key, 1);
                    yield ctrl.totalizarProdutividadePomar();
                    ctrl.scopeApply();
                }
            }
        });
    }
    static moverRegistroFuncionarioProdutividadePomarParaCima(ctrl, keyFuncionario) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.produtividadePomarSelecionado) {
                return;
            }
            if ((typeof ctrl.produtividadePomarSelecionado.funcionarios === 'undefined') || !ctrl.produtividadePomarSelecionado.funcionarios) {
                ctrl.produtividadePomarSelecionado.funcionarios = [];
            }
            if ((keyFuncionario !== null) && (keyFuncionario > 0) && (keyFuncionario <= ctrl.produtividadePomarSelecionado.funcionarios.length - 1)) {
                let oldKey = keyFuncionario;
                let newKey = keyFuncionario - 1;
                ctrl.produtividadePomarSelecionado.funcionarios.splice(newKey, 0, ctrl.produtividadePomarSelecionado.funcionarios.splice(oldKey, 1)[0]);
                ctrl.scopeApply();
            }
        });
    }
    static moverRegistroFuncionarioProdutividadePomarParaBaixo(ctrl, keyFuncionario) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.produtividadePomarSelecionado) {
                return;
            }
            if ((typeof ctrl.produtividadePomarSelecionado.funcionarios === 'undefined') || !ctrl.produtividadePomarSelecionado.funcionarios) {
                ctrl.produtividadePomarSelecionado.funcionarios = [];
            }
            if ((keyFuncionario != null) && (keyFuncionario >= 0) && (keyFuncionario < ctrl.produtividadePomarSelecionado.funcionarios.length - 1)) {
                let oldKey = keyFuncionario;
                let newKey = keyFuncionario + 1;
                ctrl.produtividadePomarSelecionado.funcionarios.splice(newKey, 0, ctrl.produtividadePomarSelecionado.funcionarios.splice(oldKey, 1)[0]);
                ctrl.scopeApply();
            }
        });
    }
    static totalizarProdutividadePomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.produtividadePomarSelecionado !== 'undefined') && ctrl.produtividadePomarSelecionado) {
            }
        });
    }
}
exports.PomarBancoConservacaoControleProdutividadeUtil = PomarBancoConservacaoControleProdutividadeUtil;
