"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TipoRelatorioPomar = void 0;
var TipoRelatorioPomar;
(function (TipoRelatorioPomar) {
    TipoRelatorioPomar["ENXERTIA_PINUS"] = "enxertia_pinus";
    TipoRelatorioPomar["ENXERTIA_EUCALYPTUS"] = "enxertia_eucalyptus";
    TipoRelatorioPomar["DOSSIE_MATERIAL_GENETICO"] = "dossie_material_genetico";
    TipoRelatorioPomar["POMARES_SEMENTES"] = "pomares_sementes";
    TipoRelatorioPomar["BANCOS_CONSERVACAO"] = "bancos_conservacao";
    TipoRelatorioPomar["POLINIZACAO_PINUS"] = "polinizacao_pinus";
    TipoRelatorioPomar["POLINIZACAO_EUCALYPTUS"] = "polinizacao_eucalyptus";
})(TipoRelatorioPomar = exports.TipoRelatorioPomar || (exports.TipoRelatorioPomar = {}));
