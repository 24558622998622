"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.config = exports.Config = exports.empresas = exports.Empresa = exports.TipoSistema = exports.Ambiente = void 0;
var Ambiente;
(function (Ambiente) {
    Ambiente["PRODUCAO"] = "production";
    Ambiente["QUALIDADE"] = "quality";
    Ambiente["DESENVOLVIMENTO_LOCAL"] = "development-local";
    Ambiente["DESENVOLVIMENTO_WEB"] = "development-web";
})(Ambiente = exports.Ambiente || (exports.Ambiente = {}));
var TipoSistema;
(function (TipoSistema) {
    TipoSistema["MATERIAL_GENETICO"] = "SGMaterialGenetico";
})(TipoSistema = exports.TipoSistema || (exports.TipoSistema = {}));
class Empresa {
}
exports.Empresa = Empresa;
exports.empresas = [
    {
        id: `${TipoSistema.MATERIAL_GENETICO}-WESTROCK`,
        tipo: TipoSistema.MATERIAL_GENETICO,
        nomeCompleto: 'WESTROCK BRAZIL',
        nomeResumido: 'WestRock Brazil',
        palavrasChaves: ['WRK', 'WESTROCK'],
        dominio: 'westrock',
        api: {
            caminhoLocal: 'http://localhost:8099/v1/',
            caminhoDevANI: 'https://apimaterialgenetico.centralsgl.com.br/v1/',
            caminhoQa: 'https://apimaterialgenetico.centralsgl.com.br/v1/',
            caminhoProd: 'https://apimaterialgenetico.centralsgl.com.br/v1/'
        },
        indexedDB: 'sgmaterialgenetico-ani',
        dashboard: 'https://app.powerbi.com/links/XXXXXXXXXX?ctid=XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX&pbi_source=linkShare',
        logoMenu: {
            src: 'assets/img/logo-westrock-cor-400x90.png'
        },
        logoInicio: {
            src: 'assets/img/logo-westrock-colorida.png',
            widthDesktop: 365,
            widthCelular: 250,
            style: 'padding-top: 100px;'
        }
    },
];
class Config {
    constructor(opcoes) {
        this.ambiente = null;
        this.tipo = null;
        this.versao = null;
        this.empresa = null;
        this.api = { caminho: null };
        this.chavePublica = null;
        this.empresasFiltradas = [];
        const self = this;
        this.ambiente = opcoes.ambiente;
        this.tipo = opcoes.tipo;
        this.versao = opcoes.versao;
        this.chavePublica = opcoes.chavePublica;
        this.empresasFiltradas = exports.empresas.filter(e => e.tipo === self.tipo);
        this.carregarEmpresaLogada();
    }
    carregarEmpresaLogada() {
        const self = this;
        self.empresasFiltradas = exports.empresas.filter(e => e.tipo === self.tipo);
        if ((typeof self.empresasFiltradas !== 'undefined') && self.empresasFiltradas && (self.empresasFiltradas.length > 0)) {
            if (self.empresasFiltradas.length === 1) {
                self.empresa = self.empresasFiltradas[0];
            }
            else {
                self.empresa = null;
                if ((typeof location.href !== 'undefined') && location.href) {
                    let url = `${location.href}`;
                    let empresasTravadas = exports.empresas.filter(e => e.dominio && (url.toLowerCase().indexOf(e.dominio.toLowerCase()) !== -1));
                    if ((typeof empresasTravadas !== 'undefined') && empresasTravadas && (empresasTravadas.length === 1)) {
                        window.localStorage.setItem('idEmpresaLogada', empresasTravadas[0].id);
                        window.localStorage.setItem('travaEmpresaLogada', 'S');
                    }
                    else {
                        window.localStorage.setItem('travaEmpresaLogada', 'N');
                    }
                }
                else {
                    window.localStorage.setItem('travaEmpresaLogada', 'N');
                }
                const idEmpresaLogada = window.localStorage.getItem('idEmpresaLogada');
                if ((typeof idEmpresaLogada !== 'undefined') && idEmpresaLogada) {
                    let empresaLogada = self.empresasFiltradas.find(e => e.id === idEmpresaLogada);
                    if ((typeof empresaLogada !== 'undefined') && empresaLogada) {
                        self.empresa = empresaLogada;
                    }
                }
            }
            self.api.caminho = self.ambiente && self.empresa && self.empresa.api ?
                (self.ambiente === Ambiente.DESENVOLVIMENTO_LOCAL ? self.empresa.api.caminhoLocal :
                    (self.ambiente === Ambiente.DESENVOLVIMENTO_WEB ? self.empresa.api.caminhoDevANI :
                        (self.ambiente === Ambiente.QUALIDADE ? self.empresa.api.caminhoQa :
                            (self.ambiente === Ambiente.PRODUCAO ? self.empresa.api.caminhoProd :
                                null)))) : null;
        }
    }
}
exports.Config = Config;
exports.config = new Config({
    ambiente: Ambiente.PRODUCAO,
    tipo: TipoSistema.MATERIAL_GENETICO,
    versao: '1.0.198',
    chavePublica: `-----BEGIN PUBLIC KEY-----
    MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA31jMX9u+11mtA7IsmSHh
    kHw0xiqPBuVn6G2J7zLTSOOAT1hSC3wBu/3uH+GnZBYiqFl0ssGauf1fslZpSOPe
    fqRJSoIW5YF5bVVbdmym0t1b3I0toTd0/gcgCjkTbhkMV2pam1iuN8C2CBOvpLKJ
    psA8UQran7ACdKTqHVqVqvQ7voUWFDRZdfkBFKUSEtZWUALshSDQLFORLENmpHlk
    Zlbl7s/dXFVtZvNfESEYDcFN+eHM67IgBBEegAql4fDw5kekBt+mdUTz9EdfPMJJ
    +nS97n5XMI1yLwpLAiS1cTam3MfYgbJr0lRsGRM8pLsotGsgukdKyl5L2Fm87Smr
    fwIDAQAB
    -----END PUBLIC KEY-----`
});
