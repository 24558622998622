"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PolinizacaoPomar = void 0;
const Base_1 = require("./Base");
class PolinizacaoPomar extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.id = null;
        this.codigo = null;
        this.data = null;
        this.pomar = null;
        this.pomarCodigo = null;
        this.pomarDescricao = null;
        this.regiao = null;
        this.talhao = null;
        this.tipoPlanejamento = null;
        this.materialGeneticoFeminino = null;
        this.pomarOrigemPolen = null;
        this.pomarOrigemPolenCodigo = null;
        this.pomarOrigemPolenDescricao = null;
        this.materialGeneticoMasculino = null;
        this.especieMaterialGeneticoMasculino = null;
        this.monitoramentosFloracao = [];
        this.polinizacoes = [];
        this.coletasConesSementes = [];
        this.beneficiamentos = [];
        this.anexos = [];
        this.observacoes = null;
        this.usuarioInclusao = null;
        this.dataHoraInclusao = null;
        this.usuarioAlteracao = null;
        this.dataHoraAlteracao = null;
    }
}
exports.PolinizacaoPomar = PolinizacaoPomar;
