"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaterialPlantado = void 0;
const Base_1 = require("./Base");
class MaterialPlantado extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.materialGenetico = null;
        this.numMudas = null;
        this.linha = null;
    }
}
exports.MaterialPlantado = MaterialPlantado;
