"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OperacaoManejoTesteCampo = void 0;
const Base_1 = require("./Base");
class OperacaoManejoTesteCampo extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.descricao = null;
        this.tipoOperacao = null;
        this.ehOperacaoLimpeza = null;
        this.ehOperacaoDesbaste = null;
        this.ehOperacaoPolinizacao = null;
        this.ehOperacaoColetaAmostras = null;
        this.ehOperacaoAdubacaoFertilizacao = null;
        this.ehOperacaoColetaSementes = null;
        this.ehOperacaoControleDoencas = null;
        this.ehOperacaoPodaRamosPortaEnxerto = null;
        this.ehOperacaoAvaliacaoSobrevivencia = null;
        this.ehOperacaoReplantio = null;
        this.ehOutrasOperacoes = null;
    }
}
exports.OperacaoManejoTesteCampo = OperacaoManejoTesteCampo;
OperacaoManejoTesteCampo.OPERACAO_LIMPEZA = 'limpeza';
OperacaoManejoTesteCampo.OPERACAO_DESBASTE = 'desbaste';
OperacaoManejoTesteCampo.OPERACAO_POLINIZACAO = 'polinizacao';
OperacaoManejoTesteCampo.OPERACAO_COLETA_AMOSTRAS = 'coleta_amostras';
OperacaoManejoTesteCampo.OPERACAO_ADUBACAO_FERTILIZACAO = 'adubacao_fertilizacao';
OperacaoManejoTesteCampo.OPERACAO_COLETA_SEMENTES = 'coleta_sementes';
OperacaoManejoTesteCampo.OPERACAO_CONTROLE_DOENCAS = 'controle_doencas';
OperacaoManejoTesteCampo.OPERACAO_PODA_RAMOS_PORTA_ENXERTO = 'poda_ramos_porta_enxerto';
OperacaoManejoTesteCampo.OPERACAO_AVALIACAO_SOBREVIVENCIA = 'avaliacao_sobrevivencia';
OperacaoManejoTesteCampo.OPERACAO_REPLANTIO = 'replantio';
OperacaoManejoTesteCampo.OUTRAS_OPERACOES = 'outras_operacoes';
