"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProducaoMudaClonalMiniestaquiaAvaliacaoEnraizamento = void 0;
const Base_1 = require("./Base");
class ProducaoMudaClonalMiniestaquiaAvaliacaoEnraizamento extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.data = null;
        this.clone = null;
        this.numEstacas = null;
        this.porcentagemEnraizamento = null;
    }
}
exports.ProducaoMudaClonalMiniestaquiaAvaliacaoEnraizamento = ProducaoMudaClonalMiniestaquiaAvaliacaoEnraizamento;
