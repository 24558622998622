"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PomarBancoConservacaoCroquiUtil = void 0;
const angular = require("angular");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const CroquiPomar_1 = require("../../../../../modelos/CroquiPomar");
const TipoEnxertoPomar_1 = require("../../../../../modelos/TipoEnxertoPomar");
const TipoPolinizacaoPomar_1 = require("../../../../../modelos/TipoPolinizacaoPomar");
const ArmazenamentoUtil_1 = require("../../../../../util/ArmazenamentoUtil");
const LuckysheetUtil_1 = require("../../../../../util/LuckysheetUtil");
const DataUtil_1 = require("../../../../../util/DataUtil");
const DispositivoUtil_1 = require("../../../../../util/DispositivoUtil");
const sweetalert2_1 = require("sweetalert2");
class PomarBancoConservacaoCroquiUtil {
    static carregarCroquiPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.croquiPomarSelecionado = null;
            if (!ctrl.pomarSelecionado || !ctrl.pomarSelecionado.id) {
                return;
            }
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
            let filtro = {
                listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                pomarId: ((ctrl.pomarSelecionado && ctrl.pomarSelecionado.id) ? ctrl.pomarSelecionado.id : null)
            };
            let listaCroquiPomar = yield ctrl.sincronia
                .rota(RotaAPIConstantes_1.RotaAPIConstantes.CROQUI_POMAR)
                .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CROQUI_POMAR)
                .filtro(filtro)
                .listar();
            if ((typeof listaCroquiPomar !== 'undefined') && listaCroquiPomar) {
                angular.forEach(listaCroquiPomar, (item) => {
                    if ((typeof item !== 'undefined') && item) {
                        if ((typeof item.anexos !== 'undefined') && item.anexos) {
                            let anexosAgrupados = new Array();
                            angular.forEach(item.anexos, (ged) => {
                                if (!anexosAgrupados.some((o) => o && o.id && (o.id === ged.id))) {
                                    anexosAgrupados.push(ged);
                                }
                            });
                            item.anexos = anexosAgrupados;
                        }
                        ctrl.croquiPomarSelecionado = item;
                    }
                });
            }
            if (!ctrl.croquiPomarSelecionado) {
                ctrl.croquiPomarSelecionado = new CroquiPomar_1.CroquiPomar();
                ctrl.croquiPomarSelecionado.uuid = ArmazenamentoUtil_1.ArmazenamentoUtil.uuid();
                ctrl.croquiPomarSelecionado.criacao = new Date();
                ctrl.croquiPomarSelecionado.pomar = ctrl.pomarSelecionado;
                ctrl.croquiPomarSelecionado.pomarCodigo = ((typeof ctrl.pomarSelecionado.codigo !== 'undefined') && ctrl.pomarSelecionado.codigo) ? ctrl.pomarSelecionado.codigo : null;
                ctrl.croquiPomarSelecionado.pomarDescricao = ((typeof ctrl.pomarSelecionado.descricao !== 'undefined') && ctrl.pomarSelecionado.descricao) ? ctrl.pomarSelecionado.descricao : null;
                ctrl.croquiPomarSelecionado.estabelecimento = ((typeof ctrl.pomarSelecionado.data !== 'undefined') && ctrl.pomarSelecionado.data) ? DataUtil_1.DataUtil.converterDataParaFormatoMMYYYY(ctrl.pomarSelecionado.data) : null;
                ctrl.croquiPomarSelecionado.numColunas = 15;
                ctrl.croquiPomarSelecionado.numLinhas = 30;
                ctrl.croquiPomarSelecionado.tamanhoCampo = 6;
                ctrl.croquiPomarSelecionado.dados = [];
                ctrl.croquiPomarSelecionado.anexos = [];
            }
            if ((typeof ctrl.croquiPomarSelecionado.anexos !== 'undefined') && ctrl.croquiPomarSelecionado.anexos) {
                let anexosAgrupados = new Array();
                angular.forEach(ctrl.croquiPomarSelecionado.anexos, (ged) => {
                    if (!anexosAgrupados.some((o) => o && o.id && (o.id === ged.id))) {
                        anexosAgrupados.push(ged);
                    }
                });
                ctrl.croquiPomarSelecionado.anexos = anexosAgrupados;
            }
            if (angular.element('#planilha_croqui_pomar').length > 0) {
                if ((typeof globalThis.luckysheet !== 'undefined') && globalThis.luckysheet) {
                    globalThis.luckysheet.destroy();
                }
                angular.element('#planilha_croqui_pomar').empty();
            }
            setTimeout((ctrl) => __awaiter(this, void 0, void 0, function* () {
                let dadosPlanilhaCroquiSelecionado = null;
                let dadosAnexoCroquiSelecionado = null;
                if ((typeof ctrl.croquiPomarSelecionado !== 'undefined') && ctrl.croquiPomarSelecionado) {
                    if ((typeof ctrl.croquiPomarSelecionado.dados !== 'undefined') && ctrl.croquiPomarSelecionado.dados) {
                        if (typeof ctrl.croquiPomarSelecionado.dados === 'string') {
                            dadosPlanilhaCroquiSelecionado = JSON.parse(ctrl.croquiPomarSelecionado.dados);
                        }
                        else {
                            dadosPlanilhaCroquiSelecionado = ctrl.croquiPomarSelecionado.dados;
                        }
                    }
                    if ((typeof ctrl.croquiPomarSelecionado.anexos !== 'undefined') && ctrl.croquiPomarSelecionado.anexos && (ctrl.croquiPomarSelecionado.anexos.length > 0)) {
                        angular.forEach(ctrl.croquiPomarSelecionado.anexos, (dadosAnexo) => {
                            dadosAnexoCroquiSelecionado = dadosAnexo;
                        });
                    }
                }
                LuckysheetUtil_1.LuckysheetUtil.carregarPlanilha('planilha_croqui_pomar', dadosPlanilhaCroquiSelecionado, dadosAnexoCroquiSelecionado);
            }), 250, ctrl);
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    static exportarCroquiPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.pomarSelecionado || !ctrl.pomarSelecionado.id) {
                return;
            }
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
            try {
                if ((typeof globalThis.luckysheet === 'undefined') || !globalThis.luckysheet) {
                    throw new Error('Plugin "luckysheet" não carregado.');
                }
                LuckysheetUtil_1.LuckysheetUtil.exportXLSX(globalThis.luckysheet);
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    static importarCroquiPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.exibirModalHistoricoCroquiPomar();
        });
    }
    static lancarControleEnxertiaCroqui(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.pomarSelecionado || !ctrl.pomarSelecionado.id) {
                return;
            }
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
            try {
                let posicaoMaterialGeneticoCroqui = LuckysheetUtil_1.LuckysheetUtil.materialGeneticoSelecionadoCroqui();
                if (!posicaoMaterialGeneticoCroqui ||
                    (typeof posicaoMaterialGeneticoCroqui.materialGenetico === 'undefined') ||
                    (posicaoMaterialGeneticoCroqui.materialGenetico === null)) {
                    const textoMensagem = 'Material Genético não encontrado ou não preenchido na planilha (fila/coluna selecionados). ' +
                        'Linha: ' + ((posicaoMaterialGeneticoCroqui && (typeof posicaoMaterialGeneticoCroqui.row !== 'undefined') && (posicaoMaterialGeneticoCroqui.row !== null)) ? posicaoMaterialGeneticoCroqui.row : 'null') + ' ' +
                        'Coluna: ' + ((posicaoMaterialGeneticoCroqui && (typeof posicaoMaterialGeneticoCroqui.col !== 'undefined') && (posicaoMaterialGeneticoCroqui.col !== null)) ? posicaoMaterialGeneticoCroqui.col : 'null') + '.';
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    if (ctrl.ehCelular) {
                        alert(textoMensagem);
                    }
                    else {
                        sweetalert2_1.default.fire({
                            title: 'Material Genético não encontrado!',
                            text: textoMensagem,
                            icon: 'warning',
                            showCancelButton: false,
                            confirmButtonText: 'Ok'
                        });
                    }
                    return;
                }
                let tipoEnxertoCroqui = null;
                if ((typeof globalThis.luckysheet !== 'undefined') && globalThis.luckysheet && (typeof globalThis.luckysheet.getSheet !== 'undefined')) {
                    let planilhaCroqui = globalThis.luckysheet.getSheet();
                    if ((typeof planilhaCroqui !== 'undefined') && planilhaCroqui && (typeof planilhaCroqui.name !== 'undefined') && planilhaCroqui.name) {
                        const lcNomePlanilhaCroqui = planilhaCroqui.name.trim().toLowerCase();
                        if (lcNomePlanilhaCroqui.indexOf('porta') !== -1) {
                            tipoEnxertoCroqui = TipoEnxertoPomar_1.TipoEnxertoPomar.PORTA_ENXERTO;
                        }
                        else if (lcNomePlanilhaCroqui.indexOf('ano 1') !== -1) {
                            tipoEnxertoCroqui = TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_1;
                        }
                        else if (lcNomePlanilhaCroqui.indexOf('ano 2') !== -1) {
                            tipoEnxertoCroqui = TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_2;
                        }
                        else if (lcNomePlanilhaCroqui.indexOf('ano 3') !== -1) {
                            tipoEnxertoCroqui = TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_3;
                        }
                        else if (lcNomePlanilhaCroqui.indexOf('ano') !== -1) {
                            if (lcNomePlanilhaCroqui.indexOf('1') !== -1) {
                                tipoEnxertoCroqui = TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_1;
                            }
                            else if (lcNomePlanilhaCroqui.indexOf('2') !== -1) {
                                tipoEnxertoCroqui = TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_2;
                            }
                            else if (lcNomePlanilhaCroqui.indexOf('3') !== -1) {
                                tipoEnxertoCroqui = TipoEnxertoPomar_1.TipoEnxertoPomar.ENXERTO_ANO_3;
                            }
                        }
                    }
                }
                ctrl.exibirModalControleEnxertiaPomar({
                    materialGeneticoCroqui: posicaoMaterialGeneticoCroqui,
                    tipoEnxertoCroqui: tipoEnxertoCroqui
                });
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    static lancarMonitoramentoFloracaoCroqui(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!ctrl.pomarSelecionado || !ctrl.pomarSelecionado.id) {
                return;
            }
            CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
            try {
                let posicaoMaterialGeneticoCroqui = LuckysheetUtil_1.LuckysheetUtil.materialGeneticoSelecionadoCroqui();
                if (!posicaoMaterialGeneticoCroqui ||
                    (typeof posicaoMaterialGeneticoCroqui.materialGenetico === 'undefined') ||
                    (posicaoMaterialGeneticoCroqui.materialGenetico === null)) {
                    const textoMensagem = 'Material Genético não encontrado ou não preenchido na planilha (fila/coluna selecionados). ' +
                        'Linha: ' + ((posicaoMaterialGeneticoCroqui && (typeof posicaoMaterialGeneticoCroqui.row !== 'undefined') && (posicaoMaterialGeneticoCroqui.row !== null)) ? posicaoMaterialGeneticoCroqui.row : 'null') + ' ' +
                        'Coluna: ' + ((posicaoMaterialGeneticoCroqui && (typeof posicaoMaterialGeneticoCroqui.col !== 'undefined') && (posicaoMaterialGeneticoCroqui.col !== null)) ? posicaoMaterialGeneticoCroqui.col : 'null') + '.';
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    if (ctrl.ehCelular) {
                        alert(textoMensagem);
                    }
                    else {
                        sweetalert2_1.default.fire({
                            title: 'Material Genético não encontrado!',
                            text: textoMensagem,
                            icon: 'warning',
                            showCancelButton: false,
                            confirmButtonText: 'Ok'
                        });
                    }
                    return;
                }
                ctrl.exibirModalPolinizacaoPomar(TipoPolinizacaoPomar_1.TipoPolinizacaoPomar.MONITORAMENTO_FLORACAO, {
                    materialGeneticoCroqui: posicaoMaterialGeneticoCroqui
                });
            }
            catch (ex) {
                console.log(ex);
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    static redimensionarPlanilhaCroquiPomar() {
        return __awaiter(this, void 0, void 0, function* () {
            yield LuckysheetUtil_1.LuckysheetUtil.redimensionarPlanilha();
        });
    }
    static salvarCroquiPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!ctrl.croquiPomarSelecionado) {
                    return;
                }
                const ehGeracaoCroquiPomar = ((typeof ctrl.modalGerarCroquiPomar !== 'undefined') && ctrl.modalGerarCroquiPomar &&
                    (typeof ctrl.modalGerarCroquiPomarDados !== 'undefined') && ctrl.modalGerarCroquiPomarDados) ? true : false;
                const dadosGeracaoCroquiPomar = ehGeracaoCroquiPomar
                    ? angular.copy(ctrl.modalGerarCroquiPomarDados) : null;
                if (ehGeracaoCroquiPomar && dadosGeracaoCroquiPomar) {
                    if (!dadosGeracaoCroquiPomar.quantidadeFilas || (dadosGeracaoCroquiPomar.quantidadeFilas <= 0)) {
                        if (ctrl.ehCelular) {
                            alert(`Preencha a quantidade de filas! Campo necessário para gerar o croqui.`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                title: 'Preencha a quantidade de filas!',
                                text: 'Campo \'Quantidade de Filas\' é necessário para gerar o croqui',
                                icon: 'info',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        return;
                    }
                    if (!dadosGeracaoCroquiPomar.quantidadeColunas || (dadosGeracaoCroquiPomar.quantidadeColunas <= 0)) {
                        if (ctrl.ehCelular) {
                            alert(`Preencha a quantidade de colunas! Campo necessário para gerar o croqui.`);
                        }
                        else {
                            sweetalert2_1.default.fire({
                                title: 'Preencha a quantidade de colunas!',
                                text: 'Campo \'Quantidade de Colunas\' é necessário para gerar o croqui',
                                icon: 'info',
                                showCancelButton: false,
                                confirmButtonText: 'Ok'
                            });
                        }
                        return;
                    }
                }
                ctrl.modalTratamentoPomar = false;
                ctrl.modalGerarCroquiPomar = false;
                ctrl.modalGerarCroquiPomarDados = null;
                ctrl.modalHistoricoCroquiPomar = false;
                ctrl.modalPlanejamentoPomar = false;
                ctrl.modalControleManejoPomar = false;
                ctrl.modalProdutividadePomar = false;
                ctrl.modalFenologiaPomar = false;
                ctrl.modalRelatorioPomar = false;
                ctrl.modalAnexoPomar = false;
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (ehGeracaoCroquiPomar && dadosGeracaoCroquiPomar) {
                    if ((typeof globalThis.luckysheet !== 'undefined') && globalThis.luckysheet) {
                        let tituloPlanilhaCroqui = 'Croqui ' +
                            DataUtil_1.DataUtil.converterDataParaFormatoDDMMYY(DataUtil_1.DataUtil.dataHoraAtual()) + ' ' +
                            DataUtil_1.DataUtil.converterDataParaFormatoHHMMSS(DataUtil_1.DataUtil.dataHoraAtual());
                        let largurasColunasPlanilhaCroqui = {};
                        let bordasCelulasPlanilhaCroqui = [];
                        let dadosPlanilhaCroqui = [];
                        for (let linhaIdx = 0; linhaIdx <= 250; linhaIdx++) {
                            let linha = [];
                            for (let colunaIdx = 0; colunaIdx <= 60; colunaIdx++) {
                                let valor = null;
                                linha.push(valor);
                            }
                            dadosPlanilhaCroqui.push(linha);
                        }
                        for (let fila = 1; fila <= dadosGeracaoCroquiPomar.quantidadeFilas; fila++) {
                            let linhaIdx = fila + 1;
                            let colunaIdx = 1;
                            dadosPlanilhaCroqui[linhaIdx][colunaIdx] = dadosGeracaoCroquiPomar.quantidadeFilas + 1 - fila;
                        }
                        largurasColunasPlanilhaCroqui[0] = 50;
                        largurasColunasPlanilhaCroqui[1] = 30;
                        for (let coluna = 1; coluna <= dadosGeracaoCroquiPomar.quantidadeColunas; coluna++) {
                            let linhaIdx = 1;
                            let colunaIdx = coluna * 2;
                            dadosPlanilhaCroqui[linhaIdx][colunaIdx] = coluna;
                            largurasColunasPlanilhaCroqui[`${colunaIdx}`] = 70;
                            largurasColunasPlanilhaCroqui[`${colunaIdx + 1}`] = 15;
                        }
                        let ultimoMaterialGenetico = null;
                        for (let fila = 1; fila <= dadosGeracaoCroquiPomar.quantidadeFilas; fila++) {
                            let linhaIdx = fila + 1;
                            for (let coluna = 1; coluna <= dadosGeracaoCroquiPomar.quantidadeColunas; coluna++) {
                                let colunaIdx = coluna * 2;
                                let valor = null;
                                if ((typeof ctrl.materiaisGeneticosFiltrados !== 'undefined') && ctrl.materiaisGeneticosFiltrados && (ctrl.materiaisGeneticosFiltrados.length > 0)) {
                                    for (let tentativaMaterialGenetico = 1; tentativaMaterialGenetico <= 100; tentativaMaterialGenetico++) {
                                        let idxMaterialGenetico = Math.round(ctrl.materiaisGeneticosFiltrados.length * Math.random());
                                        if ((idxMaterialGenetico >= 0) && (idxMaterialGenetico <= ctrl.materiaisGeneticosFiltrados.length - 1) &&
                                            (typeof ctrl.materiaisGeneticosFiltrados[idxMaterialGenetico] !== 'undefined') && ctrl.materiaisGeneticosFiltrados[idxMaterialGenetico] &&
                                            (typeof ctrl.materiaisGeneticosFiltrados[idxMaterialGenetico].descricao !== 'undefined') && ctrl.materiaisGeneticosFiltrados[idxMaterialGenetico].descricao &&
                                            (ctrl.materiaisGeneticosFiltrados[idxMaterialGenetico].descricao.length <= 5) &&
                                            (!ultimoMaterialGenetico || (ultimoMaterialGenetico !== ctrl.materiaisGeneticosFiltrados[idxMaterialGenetico]))) {
                                            valor = ctrl.materiaisGeneticosFiltrados[idxMaterialGenetico].descricao;
                                            ultimoMaterialGenetico = ctrl.materiaisGeneticosFiltrados[idxMaterialGenetico];
                                        }
                                    }
                                }
                                else if ((typeof ctrl.materiaisGeneticos !== 'undefined') && ctrl.materiaisGeneticos && (ctrl.materiaisGeneticos.length > 0)) {
                                    for (let tentativaMaterialGenetico = 1; tentativaMaterialGenetico <= 100; tentativaMaterialGenetico++) {
                                        let idxMaterialGenetico = Math.round(ctrl.materiaisGeneticos.length * Math.random());
                                        if ((idxMaterialGenetico >= 0) && (idxMaterialGenetico <= ctrl.materiaisGeneticos.length - 1) &&
                                            (typeof ctrl.materiaisGeneticos[idxMaterialGenetico] !== 'undefined') && ctrl.materiaisGeneticos[idxMaterialGenetico] &&
                                            (typeof ctrl.materiaisGeneticos[idxMaterialGenetico].descricao !== 'undefined') && ctrl.materiaisGeneticos[idxMaterialGenetico].descricao &&
                                            (ctrl.materiaisGeneticos[idxMaterialGenetico].descricao.length <= 5) &&
                                            (!ultimoMaterialGenetico || (ultimoMaterialGenetico !== ctrl.materiaisGeneticos[idxMaterialGenetico]))) {
                                            valor = ctrl.materiaisGeneticos[idxMaterialGenetico].descricao;
                                            ultimoMaterialGenetico = ctrl.materiaisGeneticos[idxMaterialGenetico];
                                        }
                                    }
                                }
                                dadosPlanilhaCroqui[linhaIdx][colunaIdx] = { v: valor, m: valor, bl: 1, ht: 0 };
                                bordasCelulasPlanilhaCroqui.push({
                                    row: [linhaIdx, linhaIdx],
                                    column: [colunaIdx, colunaIdx]
                                });
                            }
                        }
                        globalThis.luckysheet.setSheetAdd({
                            sheetObject: {
                                name: tituloPlanilhaCroqui,
                                data: dadosPlanilhaCroqui,
                                config: {
                                    columnlen: largurasColunasPlanilhaCroqui,
                                    borderInfo: [{
                                            rangeType: 'range',
                                            borderType: 'border-all',
                                            color: '#000',
                                            style: '1',
                                            range: bordasCelulasPlanilhaCroqui
                                        }]
                                }
                            },
                            order: 0
                        });
                    }
                }
                if ((typeof globalThis.luckysheet !== 'undefined') && globalThis.luckysheet) {
                    ctrl.croquiPomarSelecionado.dados = JSON.stringify(globalThis.luckysheet.getluckysheetfile());
                }
                if (!ctrl.croquiPomarSelecionado.id) {
                    ctrl.croquiPomarSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.CROQUI_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CROQUI_POMAR)
                        .criar(ctrl.croquiPomarSelecionado);
                }
                else {
                    ctrl.croquiPomarSelecionado = yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.CROQUI_POMAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CROQUI_POMAR)
                        .atualizar(ctrl.croquiPomarSelecionado);
                }
                if ((typeof ctrl.croquiPomarSelecionado.pomar !== 'undefined') && ctrl.croquiPomarSelecionado.pomar &&
                    (typeof ctrl.croquiPomarSelecionado.pomar.descricao !== 'undefined') && ctrl.croquiPomarSelecionado.pomar.descricao) {
                    ctrl.croquiPomarSelecionado.pomarDescricao = ctrl.croquiPomarSelecionado.pomar.descricao;
                }
                if ((typeof ctrl.croquiPomarSelecionado.anexos !== 'undefined') && ctrl.croquiPomarSelecionado.anexos) {
                    let anexosAgrupados = new Array();
                    angular.forEach(ctrl.croquiPomarSelecionado.anexos, (ged) => {
                        if (!anexosAgrupados.some((o) => o && o.id && (o.id === ged.id))) {
                            anexosAgrupados.push(ged);
                        }
                    });
                    ctrl.croquiPomarSelecionado.anexos = anexosAgrupados;
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                ctrl.ocultarModalGerarCroquiPomar();
                ctrl.ocultarModalHistoricoCroquiPomar();
            }
            catch (ex) {
                ctrl.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    static exibirModalGerarCroquiPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.pomarSelecionado === 'undefined') || !ctrl.pomarSelecionado) {
                return;
            }
            try {
                ctrl.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                ctrl.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            ctrl.modalGerarCroquiPomar = true;
            ctrl.modalGerarCroquiPomarDados = {
                quantidadeFilas: null,
                quantidadeColunas: null,
                espacamento: (ctrl.pomarSelecionado.implantacaoPomarEspacamento ? ctrl.pomarSelecionado.implantacaoPomarEspacamento : '10 x 10 x 10 m')
            };
            ctrl.scopeApply();
        });
    }
    static ocultarModalGerarCroquiPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.modalGerarCroquiPomar = false;
            ctrl.modalGerarCroquiPomarDados = null;
            globalThis.posicaoTopoAntesModal = ctrl.posicaoTopoAntesModal;
            ctrl.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                        (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    static exibirModalHistoricoCroquiPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof ctrl.pomarSelecionado === 'undefined') || !ctrl.pomarSelecionado) {
                return;
            }
            try {
                ctrl.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                ctrl.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            ctrl.modalHistoricoCroquiPomar = true;
            ctrl.scopeApply();
        });
    }
    static ocultarModalHistoricoCroquiPomar(ctrl) {
        return __awaiter(this, void 0, void 0, function* () {
            ctrl.modalHistoricoCroquiPomar = false;
            globalThis.posicaoTopoAntesModal = ctrl.posicaoTopoAntesModal;
            ctrl.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if ((typeof globalThis.posicaoTopoAntesModal !== 'undefined') &&
                        (globalThis.posicaoTopoAntesModal !== null) && (globalThis.posicaoTopoAntesModal >= 0)) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
}
exports.PomarBancoConservacaoCroquiUtil = PomarBancoConservacaoCroquiUtil;
