"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RotaAPIConstantes = void 0;
class RotaAPIConstantes {
}
exports.RotaAPIConstantes = RotaAPIConstantes;
RotaAPIConstantes.MATERIAL_GENETICO = 'cadastro/materialGenetico';
RotaAPIConstantes.GENERO = 'cadastro/genero';
RotaAPIConstantes.ESPECIE = 'cadastro/especie';
RotaAPIConstantes.GERACAO = 'cadastro/geracao';
RotaAPIConstantes.CONJUNTO = 'cadastro/conjunto';
RotaAPIConstantes.DIALELO = 'cadastro/dialelo';
RotaAPIConstantes.POMAR = 'cadastro/pomar';
RotaAPIConstantes.TIPO_POMAR = 'cadastro/tipoPomar';
RotaAPIConstantes.CLASSIFICACAO_SOLO = 'cadastro/classificacaoSolo';
RotaAPIConstantes.UNIDADE_MANEJO = 'cadastro/unidadeManejo';
RotaAPIConstantes.REGIAO = 'cadastro/regiao';
RotaAPIConstantes.TALHAO = 'cadastro/talhao';
RotaAPIConstantes.IMPORTA_TALHAO = 'cadastro/importaTalhao';
RotaAPIConstantes.IMPORTA_SHAPEFILE = 'cadastro/importaShapefile';
RotaAPIConstantes.FONTE = 'cadastro/fonte';
RotaAPIConstantes.TIPO_MANEJO = 'cadastro/tipoManejo';
RotaAPIConstantes.TIPO_DESBASTE_MANEJO_POMAR = 'cadastro/tipoDesbasteManejoPomar';
RotaAPIConstantes.TIPO_LIMPEZA_MANEJO_POMAR = 'cadastro/tipoLimpezaManejoPomar';
RotaAPIConstantes.TIPO_AMOSTRA_MANEJO_POMAR = 'cadastro/tipoAmostraManejoPomar';
RotaAPIConstantes.FINALIDADE_AMOSTRA_MANEJO_POMAR = 'cadastro/finalidadeAmostraManejoPomar';
RotaAPIConstantes.PRAGA_DOENCA_MANEJO_POMAR = 'cadastro/pragaDoencaManejoPomar';
RotaAPIConstantes.FUNCIONARIO = 'cadastro/funcionario';
RotaAPIConstantes.CLIENTE = 'cadastro/cliente';
RotaAPIConstantes.PAIS = 'cadastro/pais';
RotaAPIConstantes.PROVINCIA_UF = 'cadastro/provinciaUf';
RotaAPIConstantes.LOCALIDADE = 'cadastro/localidade';
RotaAPIConstantes.HIBRIDACAO = 'cadastro/hibridacao';
RotaAPIConstantes.POLINIZACAO = 'cadastro/polinizacao';
RotaAPIConstantes.TIPO_TESTE_POPULACAO = 'cadastro/tipoTestePopulacao';
RotaAPIConstantes.SITUACAO = 'cadastro/situacao';
RotaAPIConstantes.CLONE_MATERIAL_GENETICO = 'cadastro/cloneMaterialGenetico';
RotaAPIConstantes.TIPO_IMPLANTACAO = 'cadastro/tipoImplantacao';
RotaAPIConstantes.PROCEDENCIA = 'cadastro/procedencia';
RotaAPIConstantes.REPRODUCAO = 'cadastro/reproducao';
RotaAPIConstantes.TIPO_POMAR_POPULACAO = 'cadastro/tipoPomarPopulacao';
RotaAPIConstantes.TIPO_DELINEAMENTO = 'cadastro/tipoDelineamento';
RotaAPIConstantes.DISPOSICAO_INDIVIDUOS = 'cadastro/disposicaoIndividuos';
RotaAPIConstantes.CLASSIFICACAO_CLIMATICA = 'cadastro/classificacaoClimatica';
RotaAPIConstantes.OPERACAO_MANEJO_POMAR = 'cadastro/operacaoManejoPomar';
RotaAPIConstantes.PRODUTO_FERTILIZANTE = 'cadastro/produtoFertilizante';
RotaAPIConstantes.HISTORICO_ESTOQUE_PRODUTO_FERTILIZANTE = 'cadastro/historicoEstoqueProdutoFertilizante';
RotaAPIConstantes.TIPO_APLICACAO = 'cadastro/tipoAplicacao';
RotaAPIConstantes.TIPO_INTERVENCAO = 'cadastro/tipoIntervencao';
RotaAPIConstantes.TESTE_GENETICO = 'cadastro/testeGenetico';
RotaAPIConstantes.ANO_ENXERTIA = 'cadastro/anoEnxertia';
RotaAPIConstantes.ESTROBILO = 'cadastro/estrobilo';
RotaAPIConstantes.ESTAGIO_FLORAL_FEMININO = 'cadastro/estagioFloralFeminino';
RotaAPIConstantes.ESTAGIO_FLORAL_MASCULINO = 'cadastro/estagioFloralMasculino';
RotaAPIConstantes.FASE_AVALIADA = 'cadastro/faseAvaliada';
RotaAPIConstantes.ESTAGIO_FLORAL_EUCALIPTO = 'cadastro/estagioFloralEucalipto';
RotaAPIConstantes.MATURACAO_FRUTO = 'cadastro/maturacaoFruto';
RotaAPIConstantes.CULTIVAR = 'cadastro/cultivar';
RotaAPIConstantes.CATEGORIA_POMAR = 'cadastro/categoriaPomar';
RotaAPIConstantes.FINALIDADE_SEMEIO = 'cadastro/finalidadeSemeio';
RotaAPIConstantes.UNIDADE_MEDIDA = 'cadastro/unidadeMedida';
RotaAPIConstantes.TRATAMENTO_FUNGICIDA = 'cadastro/tratamentoFungicida';
RotaAPIConstantes.STATUS_MINIJARDIM = 'cadastro/statusMinijardim';
RotaAPIConstantes.FINALIDADE_MULTIPLICACAO = 'cadastro/finalidadeMultiplicacao';
RotaAPIConstantes.ACAO_MINIJARDIM = 'cadastro/acaoMinijardim';
RotaAPIConstantes.CANALETOES = 'cadastro/canaletoes';
RotaAPIConstantes.MINIJARDIM = 'cadastro/minijardim';
RotaAPIConstantes.TIPO_MINIJARDIM = 'cadastro/tipoMinijardim';
RotaAPIConstantes.CAIXA_DAGUA = 'cadastro/caixaDAgua';
RotaAPIConstantes.RECEITA_SOLUCAO = 'cadastro/receitaSolucao';
RotaAPIConstantes.NIVEL_CONTAMINACAO = 'cadastro/nivelContaminacao';
RotaAPIConstantes.CASA_VEGETACAO = 'cadastro/casaVegetacao';
RotaAPIConstantes.CARGO_FUNCAO = 'cadastro/cargoFuncao';
RotaAPIConstantes.LABORATORIO = 'cadastro/laboratorio';
RotaAPIConstantes.AREA_SOLICITANTE = 'cadastro/areaSolicitante';
RotaAPIConstantes.TIPO_AMOSTRA = 'cadastro/tipoAmostra';
RotaAPIConstantes.TIPO_ANALISE = 'cadastro/tipoAnalise';
RotaAPIConstantes.STATUS_ANALISE = 'cadastro/statusAnalise';
RotaAPIConstantes.SAFRA = 'cadastro/safra';
RotaAPIConstantes.LOTE = 'cadastro/lote';
RotaAPIConstantes.PROPAGULO = 'cadastro/propagulo';
RotaAPIConstantes.NOTIFICACAO = 'cadastro/notificacao';
RotaAPIConstantes.COLETA_MACROESTACA = 'cadastro/coletaMacroestaca';
RotaAPIConstantes.CLASSIFICACAO_BROTACAO = 'cadastro/classificacaoBrotacao';
RotaAPIConstantes.PRODUTO_DEFENSIVO = 'cadastro/produtoDefensivo';
RotaAPIConstantes.HISTORICO_ESTOQUE_PRODUTO_DEFENSIVO = 'cadastro/historicoEstoqueProdutoDefensivo';
RotaAPIConstantes.ESTAGIO_OBSERVACAO_POLINIZACAO_POMAR = 'cadastro/estagioObservacaoPolinizacaoPomar';
RotaAPIConstantes.LAB_SOLICITACAO_ANALISES = 'cadastro/labSolicitacaoAnalises';
RotaAPIConstantes.LAB_MATURACAO_CONES = 'cadastro/labMaturacaoCones';
RotaAPIConstantes.LAB_UMIDADE_SEMENTES = 'cadastro/labUmidadeSementes';
RotaAPIConstantes.LAB_GERMINACAO_SEMENTES = 'cadastro/labGerminacaoSementes';
RotaAPIConstantes.LAB_UMIDADE_POLEN = 'cadastro/labUmidadePolen';
RotaAPIConstantes.LAB_GERMINACAO_POLEN = 'cadastro/labGerminacaoPolen';
RotaAPIConstantes.LAB_ESTUDO_CONES = 'cadastro/labEstudoCones';
RotaAPIConstantes.LAB_EXPERIMENTOS = 'cadastro/labExperimentos';
RotaAPIConstantes.LAB_MANUAIS_PROCEDIMENTOS = 'cadastro/labManuaisProcedimentos';
RotaAPIConstantes.ANALISE_INTERNA_AVALIACAO = 'cadastro/analiseInternaGerminacao';
RotaAPIConstantes.LAB_GERMINACAO_SEMENTES_EXTERNO = 'cadastro/labGerminacaoSementesExterno';
RotaAPIConstantes.PRODUCAO_MUDAS_EXPERIMENTOS = 'cadastro/producaoMudasExperimentos';
RotaAPIConstantes.PRODUCAO_MUDAS_RECOMENDACOES = 'cadastro/producaoMudasRecomendacoes';
RotaAPIConstantes.PRODUCAO_MUDAS_SEMINAL = 'cadastro/producaoMudasSeminal';
RotaAPIConstantes.PRODUCAO_MUDAS_PLANEJAMENTO = 'cadastro/producaoMudasPlanejamento';
RotaAPIConstantes.PRODUCAO_MUDAS_TESTE = 'cadastro/producaoMudasSemeioTestes';
RotaAPIConstantes.PRODUCAO_MUDAS_ENXERTIA = 'cadastro/producaoMudasSemeioEnxertia';
RotaAPIConstantes.PRODUCAO_MUDAS_META_PRODUCAO = 'cadastro/producaoMudasMetaProducao';
RotaAPIConstantes.PRODUCAO_MUDA_CLONAL = 'cadastro/producaoMudaClonal';
RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS = 'cadastro/producaoMudaClonalEucalyptus';
RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS = 'cadastro/producaoMudaClonalEucalyptusResgateMacroestacas';
RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CORTE_RASO = 'cadastro/producaoMudaClonalEucalyptusResgateMacroestacasCorteRaso';
RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_BROTACAO = 'cadastro/producaoMudaClonalEucalyptusResgateMacroestacasBrotacoes';
RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_ADUBACAO = 'cadastro/producaoMudaClonalEucalyptusResgateMacroestacasAdubacao';
RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CONTROLE_PRAGAS = 'cadastro/producaoMudaClonalEucalyptusResgateMacroestacasControlePragas';
RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_CONFECCAO = 'cadastro/producaoMudaClonalEucalyptusResgateMacroestacasConfeccao';
RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_RESGATEMACROESTACAS_AVALIACAO = 'cadastro/producaoMudaClonalEucalyptusResgateMacroestacasAvaliacao';
RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM = 'cadastro/producaoMudaClonalEucalyptusMinijardim';
RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_CROQUI = 'cadastro/producaoMudaClonalEucalyptusMinijardimCroqui';
RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_CROQUI_ANEXO = 'cadastro/producaoMudaClonalEucalyptusMinijardimCroquiAnexo';
RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_PLANTIO_MACROESTACAS = 'cadastro/producaoMudaClonalEucalyptusMinijardimPlantioMacroestacas';
RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_PLANTIO_MINIESTACAS = 'cadastro/producaoMudaClonalEucalyptusMinijardimPlantioMiniestacas';
RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_AVALIACAO_SOBREVIVENCIA = 'cadastro/producaoMudaClonalEucalyptusMinijardimAvaliacaoSobrevivencia';
RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_ELIMINAR_MINIMACRO = 'cadastro/producaoMudaClonalEucalyptusMinijardimEliminarMiniMacroEstacas';
RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIJARDIM_MANEJO = 'cadastro/producaoMudaClonalEucalyptusMinijardimManejo';
RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA = 'cadastro/producaoMudaClonalEucalyptusMiniestaquia';
RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_ENRAIZAMENTO = 'cadastro/producaoMudaClonalEucalyptusMiniestaquiaAvaliacaoEnraizamento';
RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_CASA_SOMBRA = 'cadastro/producaoMudaClonalEucalyptusMiniestaquiaAvaliacaoSobrevivenciaCasaSombra';
RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_EUCALYPTUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_ACLIMATACAO = 'cadastro/producaoMudaClonalEucalyptusMiniestaquiaAvaliacaoSobrevivenciaAclimatacao';
RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS = 'cadastro/producaoMudaClonalPinus';
RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIJARDIM = 'cadastro/producaoMudaClonalPinusMinijardim';
RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIJARDIM_CROQUI = 'cadastro/producaoMudaClonalPinusMinijardimCroqui';
RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIJARDIM_CROQUI_ANEXO = 'cadastro/producaoMudaClonalPinusMinijardimCroquiAnexo';
RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIJARDIM_PLANTIO_MINIESTACAS = 'cadastro/producaoMudaClonalPinusMinijardimPlantioMiniestacas';
RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIJARDIM_AVALIACAO_SOBREVIVENCIA = 'cadastro/producaoMudaClonalPinusMinijardimAvaliacaoSobrevivencia';
RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIJARDIM_ELIMINAR_MINIMACRO = 'cadastro/producaoMudaClonalPinusMinijardimEliminarMiniMacroEstacas';
RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIJARDIM_MANEJO = 'cadastro/producaoMudaClonalPinusMinijardimManejo';
RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA = 'cadastro/producaoMudaClonalPinusMiniestaquia';
RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_ENRAIZAMENTO = 'cadastro/producaoMudaClonalPinusMiniestaquiaAvaliacaoEnraizamento';
RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_CASA_SOMBRA = 'cadastro/producaoMudaClonalPinusMiniestaquiaAvaliacaoSobrevivenciaCasaSombra';
RotaAPIConstantes.PRODUCAO_MUDA_CLONAL_PINUS_MINIESTAQUIA_AVALIACAO_SOBREVIVENCIA_ACLIMATACAO = 'cadastro/producaoMudaClonalPinusMiniestaquiaAvaliacaoSobrevivenciaAclimatacao';
RotaAPIConstantes.PRODUCAO_MUDA_CONTROLE_PRAGA_DOENCA = 'cadastro/producaoMudaControlePragaDoenca';
RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR = 'cadastro/protecaoRegistroCultivar';
RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_DESCRITOR_MORFOLOGICO = 'cadastro/protecaoRegistroCultivarDescritorMorfologico';
RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_LAUDO_DNA = 'cadastro/protecaoRegistroCultivarLaudoDna';
RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_ORIENTACOES_PROCEDIMENTOS = 'cadastro/protecaoRegistroCultivarOrientacoesProcedimentos';
RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_PROTECCAO = 'cadastro/protecaoRegistroCultivarProteccao';
RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_REGISTRO = 'cadastro/protecaoRegistroCultivarRegistro';
RotaAPIConstantes.CARACTERISTICA_DESCRITOR_MORFOLOGICO = 'cadastro/caracteristicaDescritorMorfologico';
RotaAPIConstantes.CENTRO_CUSTO_SAP = 'cadastro/centroCustoSap';
RotaAPIConstantes.CONTA_DEPOSITO_SAP = 'cadastro/contaDepositoSap';
RotaAPIConstantes.BANCO_MELHORAMENTO_POMAR = 'operacao/bancoMelhoramentoPomar';
RotaAPIConstantes.TRATAMENTO_POMAR = 'operacao/tratamentoPomar';
RotaAPIConstantes.CROQUI_POMAR = 'operacao/croquiPomar';
RotaAPIConstantes.MAPA_POMAR = 'operacao/mapaPomar';
RotaAPIConstantes.PLANEJAMENTO_POMAR = 'operacao/planejamentoPomar';
RotaAPIConstantes.CONTROLE_MANEJO_POMAR = 'operacao/controleManejoPomar';
RotaAPIConstantes.CONTROLE_ENXERTIA_POMAR = 'operacao/controleEnxertiaPomar';
RotaAPIConstantes.PRODUTIVIDADE_POMAR = 'operacao/produtividadePomar';
RotaAPIConstantes.FENOLOGIA_POMAR = 'operacao/fenologiaPomar';
RotaAPIConstantes.GRAFICO_FENOLOGIA_POMAR = 'operacao/graficoFenologiaPomar';
RotaAPIConstantes.POLINIZACAO_POMAR = 'operacao/polinizacaoPomar';
RotaAPIConstantes.ESTOQUE_POLEN_POLINIZACAO_POMAR = 'operacao/estoquePolenPolinizacaoPomar';
RotaAPIConstantes.HISTORICO_ESTOQUE_POLEN_POLINIZACAO_POMAR = 'operacao/historicoEstoquePolenPolinizacaoPomar';
RotaAPIConstantes.ESTOQUE_BENEFICIAMENTO_PINUS_POLINIZACAO_POMAR = 'operacao/estoqueBeneficiamentoPinusPolinizacaoPomar';
RotaAPIConstantes.HISTORICO_ESTOQUE_BENEFICIAMENTO_PINUS_POLINIZACAO_POMAR = 'operacao/historicoEstoqueBeneficiamentoPinusPolinizacaoPomar';
RotaAPIConstantes.ESTOQUE_BENEFICIAMENTO_EUCALYPTUS_POLINIZACAO_POMAR = 'operacao/estoqueBeneficiamentoEucalyptusPolinizacaoPomar';
RotaAPIConstantes.HISTORICO_ESTOQUE_BENEFICIAMENTO_EUCALYPTUS_POLINIZACAO_POMAR = 'operacao/historicoEstoqueBeneficiamentoEucalyptusPolinizacaoPomar';
RotaAPIConstantes.ANEXO_POMAR = 'operacao/anexoPomar';
RotaAPIConstantes.TESTE_CAMPO = 'operacao/testeCampo';
RotaAPIConstantes.TRATAMENTO_TESTE_GENETICO = 'operacao/tratamentoTesteGenetico';
RotaAPIConstantes.CROQUI_TESTE_GENETICO = 'operacao/croquiTesteGenetico';
RotaAPIConstantes.MAPA_TESTE_GENETICO = 'operacao/mapaTesteGenetico';
RotaAPIConstantes.CONTROLE_MANEJO_TESTE_GENETICO = 'operacao/controleManejoTesteGenetico';
RotaAPIConstantes.MEDICAO_DENDROMETRICA_TESTE_GENETICO = 'operacao/medicaoDendrometricaTesteGenetico';
RotaAPIConstantes.LANCAMENTO_MEDICAO_DENDROMETRICA_TESTE_GENETICO = 'operacao/lancamentoMedicaoDendrometricaTesteGenetico';
RotaAPIConstantes.PLANEJAMENTO_MEDICAO_DENDROMETRICA_TESTE_GENETICO = 'operacao/planejamentoMedicaoDendrometricaTesteGenetico';
RotaAPIConstantes.PLANILHA_MEDICAO_DENDROMETRICA_TESTE_GENETICO = 'operacao/planilhaMedicaoDendrometricaTesteGenetico';
RotaAPIConstantes.SELEGEN_MEDICAO_DENDROMETRICA_TESTE_GENETICO = 'operacao/selegenMedicaoDendrometricaTesteGenetico';
RotaAPIConstantes.QUALIDADE_MADEIRA_TESTE_GENETICO = 'operacao/qualidadeMadeiraTesteGenetico';
RotaAPIConstantes.ANALISE_SELECAO_TESTE_GENETICO = 'operacao/analiseSelecaoTesteGenetico';
RotaAPIConstantes.SUMARIO_ANALISE_SELECAO_TESTE_GENETICO = 'operacao/sumarioAnaliseSelecaoTesteGenetico';
RotaAPIConstantes.PLANILHA_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO = 'operacao/planilhaSelegenAnaliseSelecaoTesteGenetico';
RotaAPIConstantes.ANALISE_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO = 'operacao/analiseSelegenAnaliseSelecaoTesteGenetico';
RotaAPIConstantes.RESULTADO_SELEGEN_ANALISE_SELECAO_TESTE_GENETICO = 'operacao/resultadoSelegenAnaliseSelecaoTesteGenetico';
RotaAPIConstantes.ANALISE_ANO_ANALISE_SELECAO_TESTE_GENETICO = 'operacao/analiseAnoAnaliseSelecaoTesteGenetico';
RotaAPIConstantes.ANALISE_BLUP_ANALISE_SELECAO_TESTE_GENETICO = 'operacao/analiseBlupAnaliseSelecaoTesteGenetico';
RotaAPIConstantes.SELECAO_GENETICA_ANALISE_SELECAO_TESTE_GENETICO = 'operacao/selecaoGeneticaAnaliseSelecaoTesteGenetico';
RotaAPIConstantes.SELECAO_CAMPO_ANALISE_SELECAO_TESTE_GENETICO = 'operacao/selecaoCampoAnaliseSelecaoTesteGenetico';
RotaAPIConstantes.ANEXO_ANALISE_SELECAO_TESTE_GENETICO = 'operacao/anexoAnaliseSelecaoTesteGenetico';
RotaAPIConstantes.ANEXO_TESTE_GENETICO = 'operacao/anexoTesteGenetico';
RotaAPIConstantes.PROTOCOLO_TESTE_GENETICO = 'operacao/protocoloTesteGenetico';
RotaAPIConstantes.APONTAMENTO = 'cadastro/apontamento';
RotaAPIConstantes.MOVIMENTACAO_ESTOQUE = 'cadastro/movimentacaoEstoque';
RotaAPIConstantes.LOCALIZACAO = 'ferramenta/localizacao';
RotaAPIConstantes.CARGA = 'ferramenta/carga';
RotaAPIConstantes.FILE = 'ferramenta/ged/file';
RotaAPIConstantes.LOAD = 'ferramenta/ged/load';
RotaAPIConstantes.GENERICO = 'ferramenta/generico';
RotaAPIConstantes.PLANILHA = 'ferramenta/planilha';
RotaAPIConstantes.REV_TABELA = 'ferramenta/revTabela';
RotaAPIConstantes.POSICAO_COLUNA_TABELA = 'ferramenta/posicaoColunaTabela';
RotaAPIConstantes.VISIBILIDADE_COLUNA_TABELA = 'ferramenta/visibilidadeColunaTabela';
RotaAPIConstantes.USUARIO = 'cadastro/usuario';
RotaAPIConstantes.DASHBOARD = 'cadastro/dashboard';
RotaAPIConstantes.PARAMETRIZACAO = 'configuracao/configuracao';
RotaAPIConstantes.TESTAR_ENVIO_EMAIL = 'configuracao/testarEnvioEmail';
RotaAPIConstantes.TESTAR_CONEXAO_SGIF = 'configuracao/testarConexaoSGIF';
RotaAPIConstantes.SINCRONIZAR_DADOS_SGIF = 'configuracao/sincronizarDadosSGIF';
RotaAPIConstantes.TESTAR_CONEXAO_ARCGIS = 'configuracao/testarConexaoArcGIS';
RotaAPIConstantes.SINCRONIZAR_DADOS_ARCGIS = 'configuracao/sincronizarDadosArcGIS';
