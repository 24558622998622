"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Generico = exports.GenericoCabecalhoIO = void 0;
const Base_1 = require("./Base");
class GenericoCabecalhoIO extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.nome = null;
        this.valor = null;
        this.preco = null;
        this.referencia = null;
        this.descricao = null;
        this.refCampo = null;
    }
}
exports.GenericoCabecalhoIO = GenericoCabecalhoIO;
class Generico extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.nome = null;
        this.valor = null;
        this.preco = null;
        this.referencia = null;
        this.descricao = null;
        this.refCampo = null;
    }
}
exports.Generico = Generico;
