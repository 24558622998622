"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Estrobilo = void 0;
const Base_1 = require("./Base");
class Estrobilo extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.descricao = null;
    }
}
exports.Estrobilo = Estrobilo;
Estrobilo.MASCULINO = '6511c3c6013373025000e06b';
Estrobilo.FEMININO = '6511c3cc013373025000e06c';
