"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProducaoMudaClonalEucalyptusResgateMacroestacasConfeccao = void 0;
const Base_1 = require("./Base");
class ProducaoMudaClonalEucalyptusResgateMacroestacasConfeccao extends Base_1.Base {
    constructor() {
        super(...arguments);
        this.codigo = null;
        this.data = null;
        this.coleta = null;
        this.numEstacas = null;
        this.tratamento = null;
        this.quantidade = null;
        this.contaDepositoSap = null;
        this.centroCustoSap = null;
        this.clonalEucalyptus = null;
        this.cloneMuda = null;
    }
}
exports.ProducaoMudaClonalEucalyptusResgateMacroestacasConfeccao = ProducaoMudaClonalEucalyptusResgateMacroestacasConfeccao;
