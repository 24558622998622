"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProtecaoRegistroCultivarController = void 0;
const angular = require("angular");
const RotaAPIConstantes_1 = require("../../../../../constantes/RotaAPIConstantes");
const UiGridConstrutor_1 = require("../../../app/construtores/UiGridConstrutor");
const SincroniaConstantes_1 = require("../../../../../constantes/SincroniaConstantes");
const AbstratoController_1 = require("../../../app/AbstratoController");
const ICRUDOperacao_1 = require("../../../app/interfaces/ICRUDOperacao");
const config_app_1 = require("../../../../../config/config.app");
const CarregamentoUtil_1 = require("../../../../../util/CarregamentoUtil");
const ProtecaoRegistroCultivar_1 = require("../../../../../modelos/ProtecaoRegistroCultivar");
const ProtecaoRegistroCultivarDescritorMorfologico_1 = require("../../../../../modelos/ProtecaoRegistroCultivarDescritorMorfologico");
const ProtecaoRegistroCultivarLaudoDna_1 = require("../../../../../modelos/ProtecaoRegistroCultivarLaudoDna");
const ProtecaoRegistroCultivarOrientacoesProcedimentos_1 = require("../../../../../modelos/ProtecaoRegistroCultivarOrientacoesProcedimentos");
const ProtecaoRegistroCultivarProteccao_1 = require("../../../../../modelos/ProtecaoRegistroCultivarProteccao");
const ProtecaoRegistroCultivarRegistro_1 = require("../../../../../modelos/ProtecaoRegistroCultivarRegistro");
const DispositivoUtil_1 = require("../../../../../util/DispositivoUtil");
const CampoIndicadorFotos_1 = require("../../../app/componentes/campo-indicador/CampoIndicadorFotos");
const TipoRegistroProtecaoCultivar_1 = require("../../../../../modelos/TipoRegistroProtecaoCultivar");
const TipoProtecaoCultivar_1 = require("../../../../../modelos/TipoProtecaoCultivar");
const ItemCaracteristicaDescritorMorfologico_1 = require("../../../../../modelos/ItemCaracteristicaDescritorMorfologico");
const sweetalert2_1 = require("sweetalert2");
class ProtecaoRegistroCultivarController extends AbstratoController_1.AbstratoController {
    constructor($scope, $injector) {
        super($scope, $injector, new ProtecaoRegistroCultivar_1.ProtecaoRegistroCultivar());
        this.path = '';
        this.caminho = '';
        this.tipoOperacao = null;
        this.registroSelecionado = null;
        this.registroCarregado = false;
        this.listasFotos = null;
        this.tipoRegistroProtecaoCultivar = null;
        this.tipoProtecaoCultivar = null;
        this.listaControleAnexar = [];
        this.listaControleExemploCaracteristica = [];
        this.analisesFiltradas = false;
        this.dadosDescritorMorfologico = null;
        this.dadosLaudoDna = null;
        this.dadosOrientacoesProcedimentos = null;
        this.dadosProteccao = null;
        this.dadosRegistro = null;
        this.posicaoTopoAntesModal = null;
        this.modalDescritorMorfologico = false;
        this.modalLaudoDna = false;
        this.modalOrientacoesProcedimentos = false;
        this.modalProteccao = false;
        this.modalRegistro = false;
        this.modalDossieCultivar = false;
        this.listaOpcoes = {
            data: [],
            columnDefs: [],
            headerTemplate: 'app/angularjs/modulos/cadastro/paginas/protecaoRegistroCultivar/template/cabecalhoControleGeral.html',
        };
        this.listaOpcoesDescritorMorfologico = null;
        this.listaOpcoesDossieCultivar = null;
        this.listaOpcoesLaudoDna = null;
        this.listaOpcoesOrientacoesProcedimentos = null;
        this.listaOpcoesProteccao = null;
        this.listaOpcoesRegistro = null;
        this.filtroRelatorio = { registro: null };
        this.listaDescritoresMorfologicos = [];
        this.listaDossieCultivar = [];
        this.itemDossieCultivar = null;
        this.clonesMaterialGenetico = [];
        this.testesGenetico = [];
        this.caracteristicasDescritorMorfologico = [];
        this.laboratorios = [];
        this.listaCaracteristicas = [];
        this.registrosCultivar = [];
        this.listaDescritorMorfologico = [];
        this.listaLaudoDna = [];
        this.protetores = [];
        this.faixa4Meses = ProtecaoRegistroCultivarDescritorMorfologico_1.ProtecaoRegistroCultivarDescritorMorfologico.FAIXA_4_MESES;
        this.faixa6Meses = ProtecaoRegistroCultivarDescritorMorfologico_1.ProtecaoRegistroCultivarDescritorMorfologico.FAIXA_6_MESES;
        this.faixa1Ano = ProtecaoRegistroCultivarDescritorMorfologico_1.ProtecaoRegistroCultivarDescritorMorfologico.FAIXA_1_ANO;
        this.faixa3Anos = ProtecaoRegistroCultivarDescritorMorfologico_1.ProtecaoRegistroCultivarDescritorMorfologico.FAIXA_3_ANOS;
        this.faixaAdulto = ProtecaoRegistroCultivarDescritorMorfologico_1.ProtecaoRegistroCultivarDescritorMorfologico.FAIXA_ADULTO;
        this.faixa5Anos = ProtecaoRegistroCultivarDescritorMorfologico_1.ProtecaoRegistroCultivarDescritorMorfologico.FAIXA_5_ANOS;
        this.tipoOperacao = ICRUDOperacao_1.CRUDOperacao;
        this.listasFotos = new CampoIndicadorFotos_1.CampoIndicadorFotos($scope);
        this.tipoRegistroProtecaoCultivar = TipoRegistroProtecaoCultivar_1.TipoRegistroProtecaoCultivar;
        this.tipoProtecaoCultivar = TipoProtecaoCultivar_1.TipoProtecaoCultivar;
        this.analisesFiltradas = false;
    }
    onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.analisesFiltradas = false;
            this.modalDescritorMorfologico = false;
            this.dadosDescritorMorfologico = null;
            this.modalLaudoDna = false;
            this.dadosLaudoDna = null;
            this.modalOrientacoesProcedimentos = false;
            this.dadosOrientacoesProcedimentos = null;
            this.modalProteccao = false;
            this.dadosProteccao = null;
            this.modalRegistro = false;
            this.dadosRegistro = null;
            this.modalDossieCultivar = false;
            this.path = config_app_1.config.api.caminho;
            this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR;
            if ((this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) ||
                (this.operacao === ICRUDOperacao_1.CRUDOperacao.EDITAR) ||
                (this.operacao === ICRUDOperacao_1.CRUDOperacao.DUPLICAR)) {
                this.clonesMaterialGenetico = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.CLONE_MATERIAL_GENETICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CLONE_MATERIAL_GENETICO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.clonesMaterialGenetico = resposta;
                    }
                });
                this.testesGenetico = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.TIPO_TESTE_POPULACAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.TIPO_TESTE_POPULACAO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.testesGenetico = resposta;
                    }
                });
            }
            if (this.operacao === ICRUDOperacao_1.CRUDOperacao.LISTAR) {
                this.listaOpcoes = {
                    data: [],
                    columnDefs: [],
                    headerTemplate: 'app/angularjs/modulos/cadastro/paginas/protecaoRegistroCultivar/template/cabecalhoControleGeral.html',
                };
                this.listaOpcoesDescritorMorfologico = {
                    data: [],
                    columnDefs: [],
                };
                this.listaOpcoesDossieCultivar = {
                    data: [],
                    columnDefs: [],
                };
                this.listaOpcoesLaudoDna = {
                    data: [],
                    columnDefs: [],
                };
                this.listaOpcoesOrientacoesProcedimentos = {
                    data: [],
                    columnDefs: [],
                };
                this.listaOpcoesProteccao = {
                    data: [],
                    columnDefs: [],
                };
                this.listaOpcoesRegistro = {
                    data: [],
                    columnDefs: [],
                };
                this.laboratorios = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.LABORATORIO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.LABORATORIO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.laboratorios = resposta;
                    }
                });
                this.caracteristicasDescritorMorfologico = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.CARACTERISTICA_DESCRITOR_MORFOLOGICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.CARACTERISTICA_DESCRITOR_MORFOLOGICO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.caracteristicasDescritorMorfologico = resposta;
                        ctrl.listaControleAnexar.push(false);
                        ctrl.listaControleExemploCaracteristica.push(false);
                    }
                });
                this.protetores = new Array();
                this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_PROTECCAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_PROTECCAO)
                    .listar()
                    .then((resposta) => {
                    if ((typeof resposta !== 'undefined') && resposta) {
                        ctrl.protetores = resposta;
                    }
                });
            }
            this.$scope.$apply();
        });
    }
    salvar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                this.modalDescritorMorfologico = false;
                this.dadosDescritorMorfologico = null;
                this.modalLaudoDna = false;
                this.dadosLaudoDna = null;
                this.modalOrientacoesProcedimentos = false;
                this.dadosOrientacoesProcedimentos = null;
                this.modalProteccao = false;
                this.dadosProteccao = null;
                this.modalRegistro = false;
                this.dadosRegistro = null;
                this.modalDossieCultivar = false;
                if (this.operacao === ICRUDOperacao_1.CRUDOperacao.CRIAR) {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR)
                        .criar(this.modelo);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR)
                        .atualizar(this.modelo);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir uma nova Proteção e Registro de Cultivares ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir uma nova Proteção e Registro de Cultivares ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    visualizar(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const protecaoRegistroCultivar = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR)
                    .id(id)
                    .ver();
                protecaoRegistroCultivar.criacao = new Date(protecaoRegistroCultivar.criacao);
                if (typeof protecaoRegistroCultivar.dataRegistro !== 'undefined' && protecaoRegistroCultivar.dataRegistro) {
                    protecaoRegistroCultivar.dataRegistro = new Date(protecaoRegistroCultivar.dataRegistro);
                }
                if (typeof protecaoRegistroCultivar.dataProtecao !== 'undefined' && protecaoRegistroCultivar.dataProtecao) {
                    protecaoRegistroCultivar.dataProtecao = new Date(protecaoRegistroCultivar.dataProtecao);
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                return protecaoRegistroCultivar;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    remover(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const protecaoRegistroCultivar = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR)
                    .id(id)
                    .remover();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (protecaoRegistroCultivar) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    restaurar() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                const protecaoRegistroCultivar = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR)
                    .id(this.modelo.id)
                    .restaurar();
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                if (protecaoRegistroCultivar) {
                    this.$state.go(this.links.LISTAR.rota);
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    cellClicked(row) {
        if (row.entity && (typeof row.entity.id !== 'undefined') && row.entity.id) {
            const nomeTabelaProtecaoRegistroCultivares = 'tabelaControleGeral';
            let ehTabelaProtecaoRegistroCultivares = false;
            if ((typeof row.gridApi !== 'undefined') && row.gridApi &&
                (typeof row.gridApi.element !== 'undefined') && row.gridApi.element &&
                (angular.element(row.gridApi.element).length > 0)) {
                ehTabelaProtecaoRegistroCultivares = (angular.element(row.gridApi.element).parents('#' + nomeTabelaProtecaoRegistroCultivares).length > 0) ? true : false;
            }
            else {
                ehTabelaProtecaoRegistroCultivares = true;
            }
            if (ehTabelaProtecaoRegistroCultivares) {
                let gridProtecaoRegistroCultivaresApi = null;
                let gridProtecaoRegistroCultivaresScope = angular.element('#' + nomeTabelaProtecaoRegistroCultivares).scope();
                if ((typeof gridProtecaoRegistroCultivaresScope.listaGridApi !== 'undefined') && gridProtecaoRegistroCultivaresScope.listaGridApi) {
                    angular.forEach(gridProtecaoRegistroCultivaresScope.listaGridApi, (gridApi) => {
                        const grid = ((typeof gridApi.grid !== 'undefined') && gridApi.grid) ? gridApi.grid : null;
                        if (grid && (typeof grid.element !== 'undefined') && grid.element) {
                            const tabelasEl = angular.element(grid.element).parents('tabela');
                            if (tabelasEl && (tabelasEl.length > 0) && (tabelasEl.first().attr('id') === nomeTabelaProtecaoRegistroCultivares)) {
                                gridProtecaoRegistroCultivaresApi = gridApi;
                            }
                        }
                    });
                }
                if (gridProtecaoRegistroCultivaresApi && (typeof gridProtecaoRegistroCultivaresApi.selection !== 'undefined') && gridProtecaoRegistroCultivaresApi.selection) {
                    gridProtecaoRegistroCultivaresApi.selection.selectRow(row.entity);
                }
            }
        }
    }
    dadosLista(filtro = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                let listaControleGeral = [];
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                this.path = config_app_1.config.api.caminho;
                this.caminho = this.path + RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR;
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                var auxColunas = Array();
                this.registrosCultivar = [];
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.table.zero-configuration');
                const lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR)
                    .filtro(filtro)
                    .listar();
                this.registrosCultivar = lista;
                let listaDescritorMorfologico = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_DESCRITOR_MORFOLOGICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_DESCRITOR_MORFOLOGICO)
                    .filtro(filtro)
                    .listar();
                let listaLaudoDna = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_LAUDO_DNA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_LAUDO_DNA)
                    .filtro(filtro)
                    .listar();
                let listaProtecoesRegistradas = this.protetores;
                angular.forEach(lista, (protecaoRegistro) => {
                    if ((typeof protecaoRegistro !== 'undefined') && protecaoRegistro) {
                        let itemDescritorMorfologico = listaDescritorMorfologico.find(o => ((o.controleRegistro.id === protecaoRegistro.id)));
                        if ((typeof itemDescritorMorfologico !== 'undefined') && (itemDescritorMorfologico)) {
                            switch (itemDescritorMorfologico.faixaIdade) {
                                case ctrl.faixa4Meses:
                                    protecaoRegistro.a4Meses = true;
                                    protecaoRegistro.a6Meses = false;
                                    protecaoRegistro.a1Ano = false;
                                    protecaoRegistro.a3Anos = false;
                                    protecaoRegistro.adultoFloracao = false;
                                    protecaoRegistro.a5Anos = false;
                                    break;
                                case ctrl.faixa6Meses:
                                    protecaoRegistro.a4Meses = true;
                                    protecaoRegistro.a6Meses = true;
                                    protecaoRegistro.a1Ano = false;
                                    protecaoRegistro.a3Anos = false;
                                    protecaoRegistro.adultoFloracao = false;
                                    protecaoRegistro.a5Anos = false;
                                    break;
                                case ctrl.faixa1Ano:
                                    protecaoRegistro.a4Meses = true;
                                    protecaoRegistro.a6Meses = true;
                                    protecaoRegistro.a1Ano = true;
                                    protecaoRegistro.a3Anos = false;
                                    protecaoRegistro.adultoFloracao = false;
                                    protecaoRegistro.a5Anos = false;
                                    break;
                                case ctrl.faixa3Anos:
                                    protecaoRegistro.a4Meses = true;
                                    protecaoRegistro.a6Meses = true;
                                    protecaoRegistro.a1Ano = true;
                                    protecaoRegistro.a3Anos = true;
                                    protecaoRegistro.adultoFloracao = false;
                                    protecaoRegistro.a5Anos = false;
                                    break;
                                case ctrl.faixaAdulto:
                                    protecaoRegistro.a4Meses = true;
                                    protecaoRegistro.a6Meses = true;
                                    protecaoRegistro.a1Ano = true;
                                    protecaoRegistro.a3Anos = true;
                                    protecaoRegistro.adultoFloracao = false;
                                    protecaoRegistro.a5Anos = false;
                                    break;
                                case ctrl.faixa5Anos:
                                    protecaoRegistro.a4Meses = true;
                                    protecaoRegistro.a6Meses = true;
                                    protecaoRegistro.a1Ano = true;
                                    protecaoRegistro.a3Anos = true;
                                    protecaoRegistro.adultoFloracao = true;
                                    protecaoRegistro.a5Anos = true;
                                    break;
                                default:
                                    protecaoRegistro.a4Meses = false;
                                    protecaoRegistro.a6Meses = false;
                                    protecaoRegistro.a1Ano = false;
                                    protecaoRegistro.a3Anos = false;
                                    protecaoRegistro.adultoFloracao = false;
                                    protecaoRegistro.a5Anos = false;
                                    break;
                            }
                        }
                        else {
                            protecaoRegistro.a4Meses = false;
                            protecaoRegistro.a6Meses = false;
                            protecaoRegistro.a1Ano = false;
                            protecaoRegistro.a3Anos = false;
                            protecaoRegistro.adultoFloracao = false;
                            protecaoRegistro.a5Anos = false;
                        }
                        let listaProtetores = listaProtecoesRegistradas.filter((protetor) => {
                            return (protetor.controleRegistro.id === protecaoRegistro.id);
                        });
                        if (typeof protecaoRegistro.dataProtecao !== 'undefined' && protecaoRegistro.dataProtecao) {
                            protecaoRegistro.listaAnuidadesProgramadas = [];
                            protecaoRegistro.listaAnuidadesProgramadasFormatadas = [];
                            protecaoRegistro.listaAnuidadesProtetores = [];
                            for (let i = 1; i < 19; i++) {
                                let dataProtecaoProgramada = this.adicionarAnos(protecaoRegistro.dataProtecao, i);
                                protecaoRegistro.listaAnuidadesProgramadas.push(dataProtecaoProgramada);
                                protecaoRegistro.listaAnuidadesProgramadasFormatadas.push(this.formatarData(dataProtecaoProgramada));
                            }
                        }
                        if ((typeof listaProtetores !== 'undefined') && listaProtetores) {
                            if (typeof protecaoRegistro.dataProtecao !== 'undefined' && protecaoRegistro.dataProtecao) {
                                angular.forEach(protecaoRegistro.listaAnuidadesProgramadas, (dataProgramada) => {
                                    let anoDataProgramada = this.obterAno(dataProgramada);
                                    let itemProtetor = listaProtetores.find(protetor => (((typeof protetor.dataProtecaoReferente !== 'undefined') && protetor.dataProtecaoReferente) &&
                                        this.obterAno(protetor.dataProtecaoReferente) === anoDataProgramada));
                                    if ((typeof itemProtetor !== 'undefined') && itemProtetor) {
                                        protecaoRegistro.listaAnuidadesProtetores.push(true);
                                    }
                                    else {
                                        protecaoRegistro.listaAnuidadesProtetores.push(false);
                                    }
                                });
                            }
                            else {
                                for (let i = 0; i < 18; i++) {
                                    protecaoRegistro.listaAnuidadesProgramadas.push(null);
                                    protecaoRegistro.listaAnuidadesProtetores.push(false);
                                }
                            }
                        }
                        else {
                            for (let i = 0; i < 18; i++) {
                                protecaoRegistro.listaAnuidadesProgramadas.push(null);
                                protecaoRegistro.listaAnuidadesProtetores.push(false);
                            }
                        }
                        let itemLaudoDna = listaLaudoDna.find(o => ((o.controleRegistro.id === protecaoRegistro.id)));
                        if ((typeof itemLaudoDna !== 'undefined') && (itemLaudoDna)) {
                            protecaoRegistro.dna = true;
                        }
                        else {
                            protecaoRegistro.dna = false;
                        }
                        listaControleGeral.push(protecaoRegistro);
                    }
                });
                let registroTemplate = '<div ng-if="row.entity.registro" style="margin-top: 5px;">X</div>' +
                    '<div ng-if="!row.entity.registro" style="margin-top: 5px;">F</div>';
                let numRegistroTemplate = '<div ng-if="row.entity.numRegistro" style="margin-top: 5px;">{{row.entity.numRegistro}}</div>' +
                    '<div ng-if="!row.entity.numRegistro" style="margin-top: 5px;">---</div>';
                let protecaoTemplate = '<div ng-if="row.entity.protecao" style="margin-top: 5px;">X</div>' +
                    '<div ng-if="!row.entity.protecao" style="margin-top: 5px;">F</div>';
                let numProtecaoTemplate = '<div ng-if="row.entity.numProtecao" style="margin-top: 5px;">{{row.entity.numProtecao}}</div>' +
                    '<div ng-if="!row.entity.numProtecao" style="margin-top: 5px;">---</div>';
                let a4MesesTemplate = '<div ng-if="row.entity.a4Meses" style="margin-top: 5px;">X</div>' +
                    '<div ng-if="!row.entity.a4Meses" style="margin-top: 5px;">F</div>';
                let a6MesesTemplate = '<div ng-if="row.entity.a6Meses" style="margin-top: 5px;">X</div>' +
                    '<div ng-if="!row.entity.a6Meses" style="margin-top: 5px;">F</div>';
                let a1AnoTemplate = '<div ng-if="row.entity.a1Ano" style="margin-top: 5px;">X</div>' +
                    '<div ng-if="!row.entity.a1Ano" style="margin-top: 5px;">F</div>';
                let a3AnosTemplate = '<div ng-if="row.entity.a3Anos" style="margin-top: 5px;">X</div>' +
                    '<div ng-if="!row.entity.a3Anos" style="margin-top: 5px;">F</div>';
                let a5AnosTemplate = '<div ng-if="row.entity.a5Anos" style="margin-top: 5px;">X</div>' +
                    '<div ng-if="!row.entity.a5Anos" style="margin-top: 5px;">F</div>';
                let adultoFloracaoTemplate = '<div ng-if="row.entity.adultoFloracao" style="margin-top: 5px;">X</div>' +
                    '<div ng-if="!row.entity.adultoFloracao" style="margin-top: 5px;">F</div>';
                let dnaTemplate = '<div ng-if="row.entity.dna" style="margin-top: 5px;">X</div>' +
                    '<div ng-if="!row.entity.dna" style="margin-top: 5px;">F</div>';
                auxColunas.push({
                    name: 'codigo',
                    displayName: 'Código',
                    width: 90,
                });
                auxColunas.push({
                    name: 'clone.clone',
                    displayName: 'Clone',
                    width: 190,
                });
                auxColunas.push({
                    name: 'nomeCultivar',
                    displayName: 'Cultivar',
                    width: 100,
                });
                auxColunas.push({
                    name: 'registro',
                    displayName: 'Registro',
                    width: 90,
                    cellClass: function (grid, row, col) {
                        if (grid.getCellValue(row, col) === true) {
                            return 'fundoVerde';
                        }
                        else {
                            return 'fundoVermelho';
                        }
                    },
                    cellTemplate: registroTemplate,
                });
                auxColunas.push({
                    name: 'dataRegistro',
                    displayName: 'Data',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 120
                });
                auxColunas.push({
                    name: 'numRegistro',
                    displayName: 'Nº Registro',
                    width: 140,
                    cellClass: 'text-center',
                    cellTemplate: numRegistroTemplate,
                });
                auxColunas.push({
                    name: 'protecao',
                    displayName: 'Proteção',
                    width: 90,
                    cellClass: function (grid, row, col) {
                        if (grid.getCellValue(row, col) === true) {
                            return 'fundoVerde';
                        }
                        else {
                            return 'fundoVermelho';
                        }
                    },
                    cellTemplate: protecaoTemplate,
                });
                auxColunas.push({
                    name: 'dataProtecao',
                    displayName: 'Data',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 100
                });
                auxColunas.push({
                    name: 'numProtecao',
                    displayName: 'Nº Proteção',
                    width: 100,
                    cellClass: 'text-center',
                    cellTemplate: numProtecaoTemplate,
                });
                auxColunas.push({
                    name: 'testeDheVcu.descricao',
                    displayName: 'Teste DHE/VCU',
                    width: 120,
                });
                auxColunas.push({
                    name: 'a4Meses',
                    displayName: '3 e 4 meses',
                    width: 100,
                    cellClass: function (grid, row, col) {
                        if (grid.getCellValue(row, col) === true) {
                            return 'fundoVerde';
                        }
                        else {
                            return 'fundoVermelho';
                        }
                    },
                    cellTemplate: a4MesesTemplate,
                });
                auxColunas.push({
                    name: 'a6Meses',
                    displayName: '6 meses',
                    width: 100,
                    cellClass: function (grid, row, col) {
                        if (grid.getCellValue(row, col) === true) {
                            return 'fundoVerde';
                        }
                        else {
                            return 'fundoVermelho';
                        }
                    },
                    cellTemplate: a6MesesTemplate,
                });
                auxColunas.push({
                    name: 'a1Ano',
                    displayName: '1 ano',
                    width: 100,
                    cellClass: function (grid, row, col) {
                        if (grid.getCellValue(row, col) === true) {
                            return 'fundoVerde';
                        }
                        else {
                            return 'fundoVermelho';
                        }
                    },
                    cellTemplate: a1AnoTemplate,
                });
                auxColunas.push({
                    name: 'a3Anos',
                    displayName: '3 anos',
                    width: 100,
                    cellClass: function (grid, row, col) {
                        if (grid.getCellValue(row, col) === true) {
                            return 'fundoVerde';
                        }
                        else {
                            return 'fundoVermelho';
                        }
                    },
                    cellTemplate: a3AnosTemplate,
                });
                auxColunas.push({
                    name: 'a5Anos',
                    displayName: '5 anos',
                    width: 100,
                    cellClass: function (grid, row, col) {
                        if (grid.getCellValue(row, col) === true) {
                            return 'fundoVerde';
                        }
                        else {
                            return 'fundoVermelho';
                        }
                    },
                    cellTemplate: a5AnosTemplate,
                });
                auxColunas.push({
                    name: 'densidade',
                    displayName: 'Densidade',
                    width: 100,
                });
                auxColunas.push({
                    name: 'adultoFloracao',
                    displayName: 'Adulto/Floração',
                    width: 100,
                    cellClass: function (grid, row, col) {
                        if (grid.getCellValue(row, col) === true) {
                            return 'fundoVerde';
                        }
                        else {
                            return 'fundoVermelho';
                        }
                    },
                    cellTemplate: adultoFloracaoTemplate,
                });
                auxColunas.push({
                    name: 'dna',
                    displayName: 'DNA',
                    width: 100,
                    cellClass: function (grid, row, col) {
                        if (grid.getCellValue(row, col) === true) {
                            return 'fundoVerde';
                        }
                        else {
                            return 'fundoVermelho';
                        }
                    },
                    cellTemplate: dnaTemplate,
                });
                auxColunas.push({
                    name: 'inscricaoA',
                    displayName: 'Inscrição A',
                    width: 100,
                });
                auxColunas.push({
                    name: 'inscricaoB',
                    displayName: 'Inscrição B',
                    width: 100,
                });
                auxColunas.push({
                    name: 'certificadoA',
                    displayName: 'Certificado A',
                    width: 100,
                });
                auxColunas.push({
                    name: 'certificadoB',
                    displayName: 'Certificado B',
                    width: 100,
                });
                auxColunas.push({
                    name: 'anuidades',
                    displayName: 'Anuidades',
                    width: 0,
                });
                for (let i = 0; i < 18; i++) {
                    auxColunas.push({
                        name: `listaAnuidadesProtetores[${i}]`,
                        displayName: `Ano ${i + 1}`,
                        width: 90,
                        cellClass: function (grid, row, col) {
                            if (grid.getCellValue(row, col) === true) {
                                return 'fundoVerde';
                            }
                            else {
                                return 'fundoVermelho';
                            }
                        },
                        cellTemplate: `<div ng-if="row.entity.listaAnuidadesProtetores[${i}]" style="margin-top: 5px;">{{row.entity.listaAnuidadesProgramadasFormatadas[${i}]}}</div>
            <div ng-if="!row.entity.listaAnuidadesProtetores[${i}]" style="margin-top: 5px;">{{row.entity.listaAnuidadesProgramadasFormatadas[${i}]}}</div>`
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: listaControleGeral,
                    columnDefs: gridConstrutor.$colunas,
                    controller: this,
                    enableSorting: true,
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosListaDescritorMorfologico() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let filtro = {
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    limiteRegistros: 100000,
                };
                let lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_DESCRITOR_MORFOLOGICO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_DESCRITOR_MORFOLOGICO)
                    .filtro(filtro)
                    .listar();
                ctrl.listaDescritoresMorfologicos = lista;
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    posicao: 1,
                    cellClass: 'text-center',
                    cellTemplate: `
                <div class='btn-group btn-ui-grid' uib-dropdown dropdown-append-to-body>
                    <button type='button' class='btn btn-icon bx bxs-edit' style='padding-right: .4rem'
                        onmouseenter='this.style.color='rgb(20,100,255)';' onmouseleave='this.style.color='#727E8C';'
                        ng-click='grid.appScope.getParentController().abrirModalDescritorMorfologico(row.entity.id)'>
                    </button>
                    <button type='button' class='btn btn-icon bx bx-trash' style='padding-left: .4rem'
                        onmouseenter='this.style.color='rgb(20,100,255)';' onmouseleave='this.style.color='#727E8C';'
                        ng-click='grid.appScope.getParentController().excluirDescritorMorfologico(row.entity.id)'>
                    </button>
                </div> `,
                });
                auxColunas.push({
                    name: 'controleRegistro.nomeCultivar',
                    displayName: 'Cultivar',
                    width: 190,
                });
                auxColunas.push({
                    name: 'faixaIdade',
                    displayName: 'Faixa Idade Atual',
                    width: 100,
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: ctrl.listaDescritoresMorfologicos,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this,
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosListaLaudoDna() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let filtro = {
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    limiteRegistros: 100000,
                };
                let lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_LAUDO_DNA)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_LAUDO_DNA)
                    .filtro(filtro)
                    .listar();
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    posicao: 1,
                    cellClass: 'text-center',
                    cellTemplate: `
                <div class='btn-group btn-ui-grid' uib-dropdown dropdown-append-to-body>
                    <button type='button' class='btn btn-icon bx bxs-edit' style='padding-right: .4rem'
                        onmouseenter='this.style.color='rgb(20,100,255)';' onmouseleave='this.style.color='#727E8C';'
                        ng-click='grid.appScope.getParentController().abrirModalLaudoDna(row.entity.id)'>
                    </button>
                    <button type='button' class='btn btn-icon bx bx-trash' style='padding-left: .4rem'
                        onmouseenter='this.style.color='rgb(20,100,255)';' onmouseleave='this.style.color='#727E8C';'
                        ng-click='grid.appScope.getParentController().excluirLaudoDna(row.entity.id)'>
                    </button>
                </div> `,
                });
                auxColunas.push({
                    name: 'dataColeta',
                    displayName: 'Data Coleta',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 120,
                });
                auxColunas.push({
                    name: 'dataEnvio',
                    displayName: 'Data Envio',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 120,
                });
                auxColunas.push({
                    name: 'dataEmissao',
                    displayName: 'Data Emissão',
                    cellFilter: 'date:"dd/MM/yyyy"',
                    width: 120,
                });
                auxColunas.push({
                    name: 'observacao',
                    displayName: 'Observação',
                    width: 200,
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this,
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosListaOrientacoesProcedimentos() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let filtro = {
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    limiteRegistros: 100000,
                };
                let lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_ORIENTACOES_PROCEDIMENTOS)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_ORIENTACOES_PROCEDIMENTOS)
                    .filtro(filtro)
                    .listar();
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    posicao: 1,
                    cellClass: 'text-center',
                    cellTemplate: `
                <div class='btn-group btn-ui-grid' uib-dropdown dropdown-append-to-body>
                    <button type='button' class='btn btn-icon bx bxs-edit' style='padding-right: .4rem'
                        onmouseenter='this.style.color='rgb(20,100,255)';' onmouseleave='this.style.color='#727E8C';'
                        ng-click='grid.appScope.getParentController().abrirModalOrientacoesProcedimentos(row.entity.id)'>
                    </button>
                    <button type='button' class='btn btn-icon bx bx-trash' style='padding-left: .4rem'
                        onmouseenter='this.style.color='rgb(20,100,255)';' onmouseleave='this.style.color='#727E8C';'
                        ng-click='grid.appScope.getParentController().excluirOrientacoesProcedimentos(row.entity.id)'>
                    </button>
                </div> `,
                });
                auxColunas.push({
                    name: 'descricaoAnexo',
                    displayName: 'Descrição Anexo',
                    width: 190,
                });
                auxColunas.push({
                    name: 'criacao',
                    displayName: 'Data/Hora Anexo',
                    cellFilter: 'date:"dd/MM/yyyy HH:mm:ss"',
                    width: 120,
                });
                auxColunas.push({
                    name: 'nomeOperador',
                    displayName: 'Anexado por',
                    width: 120,
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this,
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosListaProteccao() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let filtro = {
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    limiteRegistros: 100000,
                };
                let lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_PROTECCAO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_PROTECCAO)
                    .filtro(filtro)
                    .listar();
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    posicao: 1,
                    cellClass: 'text-center',
                    cellTemplate: `
                <div class='btn-group btn-ui-grid' uib-dropdown dropdown-append-to-body>
                    <button type='button' class='btn btn-icon bx bxs-edit' style='padding-right: .4rem'
                        onmouseenter='this.style.color='rgb(20,100,255)';' onmouseleave='this.style.color='#727E8C';'
                        ng-click='grid.appScope.getParentController().abrirModalProteccao(row.entity.id)'>
                    </button>
                    <button type='button' class='btn btn-icon bx bx-trash' style='padding-left: .4rem'
                        onmouseenter='this.style.color='rgb(20,100,255)';' onmouseleave='this.style.color='#727E8C';'
                        ng-click='grid.appScope.getParentController().excluirProteccao(row.entity.id)'>
                    </button>
                </div> `,
                });
                auxColunas.push({
                    name: 'descricaoAnexo',
                    displayName: 'Descrição Anexo',
                    width: 190,
                });
                auxColunas.push({
                    name: 'criacao',
                    displayName: 'Data/Hora Anexo',
                    cellFilter: 'date:"dd/MM/yyyy HH:mm:ss"',
                    width: 120,
                });
                auxColunas.push({
                    name: 'nomeOperador',
                    displayName: 'Anexado por',
                    width: 120,
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this,
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosListaRegistro() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let filtro = {
                    listaSimplificadaOnline: !DispositivoUtil_1.DispositivoUtil.ehCelular(),
                    limiteRegistros: 100000,
                };
                let lista = yield this.sincronia
                    .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_REGISTRO)
                    .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_REGISTRO)
                    .filtro(filtro)
                    .listar();
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'id',
                    displayName: 'Ações',
                    visible: true,
                    width: 70,
                    enableFiltering: false,
                    enableSorting: true,
                    posicao: 1,
                    cellClass: 'text-center',
                    cellTemplate: `
                <div class='btn-group btn-ui-grid' uib-dropdown dropdown-append-to-body>
                    <button type='button' class='btn btn-icon bx bxs-edit' style='padding-right: .4rem'
                        onmouseenter='this.style.color='rgb(20,100,255)';' onmouseleave='this.style.color='#727E8C';'
                        ng-click='grid.appScope.getParentController().abrirModalRegistro(row.entity.id, row.entity.tipoRegistro)'>
                    </button>
                    <button type='button' class='btn btn-icon bx bx-trash' style='padding-left: .4rem'
                        onmouseenter='this.style.color='rgb(20,100,255)';' onmouseleave='this.style.color='#727E8C';'
                        ng-click='grid.appScope.getParentController().excluirRegistro(row.entity.id)'>
                    </button>
                </div> `,
                });
                auxColunas.push({
                    name: 'descricaoAnexo',
                    displayName: 'Descrição Anexo',
                    width: 190,
                });
                auxColunas.push({
                    name: 'criacao',
                    displayName: 'Data/Hora Anexo',
                    cellFilter: 'date:"dd/MM/yyyy HH:mm:ss"',
                    width: 120,
                });
                auxColunas.push({
                    name: 'nomeOperador',
                    displayName: 'Anexado por',
                    width: 120,
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: lista,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this,
                };
                return gridOption;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            return null;
        });
    }
    dadosDossieCultivar() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            this.listaOpcoesDossieCultivar = null;
            ctrl.listaDossieCultivar = [];
            try {
                const gridConstrutor = new UiGridConstrutor_1.UiGridConstrutor(this);
                gridConstrutor.removerColunas(Object.keys(this.modelo));
                gridConstrutor.removerAcao();
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                let lista = ctrl.listaDescritoresMorfologicos.filter(descritor => {
                    return ((typeof descritor.controleRegistro !== 'undefined') && descritor.controleRegistro &&
                        (typeof ctrl.filtroRelatorio.registro !== 'undefined') && ctrl.filtroRelatorio.registro &&
                        (descritor.controleRegistro.id === ctrl.filtroRelatorio.registro.id));
                });
                angular.forEach(lista, (descritor) => {
                    let indexCaracteristica = 0;
                    angular.forEach(descritor.listaCaracteristicas, (caracteristica) => {
                        if ((typeof caracteristica.respostaIdentificacao !== 'undefined') && caracteristica.respostaIdentificacao) {
                            let identificadoresCell = ``;
                            if ((typeof caracteristica.controleRegistro === 'undefined') || !caracteristica.controleRegistro) {
                                caracteristica.controleRegistro = ctrl.filtroRelatorio.registro;
                            }
                            let itemCaracteristica = ctrl.caracteristicasDescritorMorfologico.find(o => ((o.id === caracteristica.caracteristicaDescritorMorfologico.toString())));
                            if ((typeof itemCaracteristica !== 'undefined') && (itemCaracteristica)) {
                                caracteristica.nomeCaracteristica = itemCaracteristica.nomeCaracteristica;
                                caracteristica.caracteristicaDescritorMorfologico = itemCaracteristica;
                            }
                            angular.forEach(caracteristica.caracteristicaDescritorMorfologico.identificadores, (identificadores) => {
                                identificadoresCell = identificadoresCell + identificadores.descricao + '\r \n <br/>';
                            });
                            caracteristica.identificadoresCell = identificadoresCell;
                            caracteristica.indexCaracteristica = indexCaracteristica;
                            ctrl.listaDossieCultivar.push(caracteristica);
                        }
                        indexCaracteristica++;
                    });
                });
                let auxColunas = new Array();
                auxColunas.push({
                    name: 'nomeCaracteristica',
                    displayName: 'Característica',
                    width: 190,
                });
                auxColunas.push({
                    name: 'identificadoresCell',
                    displayName: 'Identificação da característica',
                    width: 190,
                    cellTemplate: `
          <div style='padding: 5px'><div ng-repeat="(keyIdentificacao,identificacao) in row.entity.caracteristicaDescritorMorfologico.identificadores track by $index">{{keyIdentificacao + 1}} - {{identificacao.descricao}}</br></div></div>
        `
                });
                auxColunas.push({
                    name: 'respostaIdentificacao.descricao',
                    displayName: 'Resposta do Operador',
                    width: 190,
                });
                auxColunas.push({
                    name: 'id',
                    displayName: 'Fotos Anexadas',
                    visible: true,
                    width: 70,
                    cellClass: 'text-center',
                    cellTemplate: `
                <div class='btn-group btn-ui-grid' uib-dropdown dropdown-append-to-body>
                    <button type='button' class='btn btn-icon bx bx-show' style='padding-right: .4rem'
                        onmouseenter='this.style.color='rgb(20,100,255)';' onmouseleave='this.style.color='#727E8C';'
                        ng-click='grid.appScope.getParentController().abrirModalDossieCultivar(row.entity.indexCaracteristica)'>
                    </button>
                </div> `,
                });
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                auxColunas.forEach((element) => {
                    gridConstrutor.adicionarColuna(element);
                });
                const gridOption = {
                    data: ctrl.listaDossieCultivar,
                    columnDefs: gridConstrutor.$colunas,
                    enableSorting: true,
                    controller: this,
                };
                this.listaOpcoesDossieCultivar = gridOption;
                return null;
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
            this.listaOpcoesDossieCultivar = null;
            return null;
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
        });
    }
    filtrar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    limparFiltro() {
        return __awaiter(this, void 0, void 0, function* () {
            this.listaOpcoes = yield this.dadosLista();
        });
    }
    filtrarAnalises(alterouDados = false) {
        return __awaiter(this, void 0, void 0, function* () {
            if ((typeof this.analisesFiltradas === 'undefined') || !this.analisesFiltradas || ((typeof alterouDados !== 'undefined') && alterouDados)) {
                this.listaOpcoes = yield this.dadosLista();
                this.listaOpcoesDescritorMorfologico = yield this.dadosListaDescritorMorfologico();
                this.listaOpcoesLaudoDna = yield this.dadosListaLaudoDna();
                this.listaOpcoesOrientacoesProcedimentos = yield this.dadosListaOrientacoesProcedimentos();
                this.listaOpcoesProteccao = yield this.dadosListaProteccao();
                this.listaOpcoesRegistro = yield this.dadosListaRegistro();
                this.analisesFiltradas = true;
                this.scopeApply();
            }
        });
    }
    limparFiltroRegistro() {
        return __awaiter(this, void 0, void 0, function* () {
            this.registroSelecionado = null;
            this.registroCarregado = false;
            this.modalDescritorMorfologico = false;
            this.dadosDescritorMorfologico = null;
            this.modalLaudoDna = false;
            this.dadosLaudoDna = null;
            this.modalOrientacoesProcedimentos = false;
            this.dadosOrientacoesProcedimentos = null;
            this.modalProteccao = false;
            this.dadosProteccao = null;
            this.modalRegistro = false;
            this.dadosRegistro = null;
            this.modalDossieCultivar = false;
        });
    }
    selecionarRegistro() {
        return __awaiter(this, void 0, void 0, function* () {
            let ctrl = this;
            if (ctrl && ctrl.constructor && ctrl.constructor.name) {
                if (ctrl.constructor.name === 'TabelaController') {
                    ctrl = ctrl.$scope.$parent.$ctrl;
                }
            }
            ctrl.filtrarRegistroSelecionado();
        });
    }
    filtrarRegistroSementes() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.filtrarRegistroSelecionado();
                if (typeof this.registroSelecionado !== 'undefined' &&
                    this.registroSelecionado &&
                    typeof this.registroSelecionado.id !== 'undefined' &&
                    this.registroSelecionado.id) {
                    this.registroCarregado = true;
                    this.scopeApply();
                }
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
        });
    }
    filtrarRegistroSelecionado() {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                let registro = null;
                const nomeTabelaControleGeral = 'tabelaControleGeral';
                let gridRegistrosApi = null;
                let gridRegistroScope = angular
                    .element('#' + nomeTabelaControleGeral)
                    .scope();
                if (typeof gridRegistroScope.listaGridApi !== 'undefined' &&
                    gridRegistroScope.listaGridApi) {
                    angular.forEach(gridRegistroScope.listaGridApi, (gridApi) => {
                        const grid = typeof gridApi.grid !== 'undefined' && gridApi.grid
                            ? gridApi.grid
                            : null;
                        if (grid && typeof (grid.element !== 'undefined') && grid.element) {
                            const tabelasEl = angular.element(grid.element).parents('tabela');
                            if (tabelasEl &&
                                tabelasEl.length > 0 &&
                                tabelasEl.first().attr('id') === nomeTabelaControleGeral) {
                                gridRegistrosApi = gridApi;
                            }
                        }
                    });
                }
                if (gridRegistrosApi) {
                    const registrosSelecionados = gridRegistrosApi.selection.getSelectedRows();
                    if (typeof registrosSelecionados !== 'undefined' &&
                        registrosSelecionados) {
                        angular.forEach(registrosSelecionados, (obj) => {
                            if (!registro) {
                                registro = obj;
                            }
                        });
                    }
                }
                if (registro && typeof registro.id !== 'undefined' && registro.id) {
                    if (typeof ctrl.registroSelecionado !== 'undefined' &&
                        ctrl.registroSelecionado &&
                        typeof ctrl.registroSelecionado.id !== 'undefined' &&
                        ctrl.registroSelecionado.id) {
                        if (ctrl.registroSelecionado.id !== registro.id) {
                            yield ctrl.limparFiltroRegistro();
                            ctrl.registroSelecionado = registro;
                            ctrl.registroCarregado = false;
                        }
                    }
                    else {
                        yield ctrl.limparFiltroRegistro();
                        ctrl.registroSelecionado = registro;
                        ctrl.registroCarregado = false;
                    }
                }
                else {
                    if (ctrl.registroSelecionado || ctrl.registroCarregado) {
                        yield ctrl.limparFiltroRegistro();
                        ctrl.registroCarregado = false;
                    }
                }
                ctrl.scopeApply();
            }
            catch (ex) {
                this.manipulaErro.exception(ex);
            }
        });
    }
    showCampoAnexar(key) {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            if (ctrl.listaControleAnexar[key]) {
                ctrl.listaControleAnexar[key] = false;
            }
            else {
                ctrl.listaControleAnexar[key] = true;
            }
        });
    }
    showCampoExemploCaracteristica(key) {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            if (ctrl.listaControleExemploCaracteristica[key]) {
                ctrl.listaControleExemploCaracteristica[key] = false;
            }
            else {
                ctrl.listaControleExemploCaracteristica[key] = true;
            }
        });
    }
    verificaMesmoAno(data1, data2) {
        if ((typeof data1 !== 'undefined' && data1) && (typeof data2 !== 'undefined' && data2)) {
            if (typeof data1 === 'string') {
                data1 = new Date(data1);
            }
            if (typeof data2 === 'string') {
                data2 = new Date(data2);
            }
            return data1.getFullYear() === data2.getFullYear();
        }
        else {
            return false;
        }
    }
    adicionarAnos(data, anos = 1) {
        let date = null;
        if ((typeof data !== 'undefined' && data)) {
            if (typeof data === 'string') {
                date = new Date(data);
            }
            else {
                date = data;
            }
        }
        return new Date(date.setFullYear(date.getFullYear() + anos));
    }
    obterAno(data) {
        if ((typeof data !== 'undefined' && data)) {
            if (typeof data === 'string') {
                data = new Date(data);
            }
            return data.getFullYear();
        }
        else {
            return null;
        }
    }
    formatarData(data) {
        let date = null;
        if (typeof data === 'string') {
            date = new Date(data);
        }
        else {
            date = data;
        }
        let dia = date.getDate(), mes = date.getMonth() + 1, ano = date.getFullYear();
        if (dia < 10) {
            dia = '0' + dia;
        }
        if (mes < 10) {
            mes = '0' + mes;
        }
        return dia + '/' + mes + '/' + ano;
    }
    salvarDescritorMorfologico() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                this.modalLaudoDna = false;
                this.modalOrientacoesProcedimentos = false;
                this.modalProteccao = false;
                this.modalRegistro = false;
                this.modalDossieCultivar = false;
                if (typeof this.dadosDescritorMorfologico === 'undefined' ||
                    !this.dadosDescritorMorfologico) {
                    this.dadosDescritorMorfologico = new ProtecaoRegistroCultivarDescritorMorfologico_1.ProtecaoRegistroCultivarDescritorMorfologico();
                }
                if (typeof this.dadosDescritorMorfologico.id === 'undefined' ||
                    !this.dadosDescritorMorfologico.id) {
                    this.dadosDescritorMorfologico.controleRegistro = this.registroSelecionado;
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_DESCRITOR_MORFOLOGICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_DESCRITOR_MORFOLOGICO)
                        .criar(this.dadosDescritorMorfologico);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_DESCRITOR_MORFOLOGICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_DESCRITOR_MORFOLOGICO)
                        .atualizar(this.dadosDescritorMorfologico);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir um novo Descritor Morfológico ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir um novo Descritor Morfológico ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.ocultarModalDescritorMorfologico();
                this.filtrarAnalises(true);
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
                this.ocultarModalDescritorMorfologico();
            }
            finally {
                this.ocultarModalDescritorMorfologico();
            }
        });
    }
    abrirModalDescritorMorfologico(id = null) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (typeof id !== 'undefined' && id) {
                    this.dadosDescritorMorfologico = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_DESCRITOR_MORFOLOGICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_DESCRITOR_MORFOLOGICO)
                        .id(id)
                        .ver();
                }
                if ((typeof this.dadosDescritorMorfologico === 'undefined') || !this.dadosDescritorMorfologico) {
                    this.dadosDescritorMorfologico = new ProtecaoRegistroCultivarDescritorMorfologico_1.ProtecaoRegistroCultivarDescritorMorfologico();
                    this.dadosDescritorMorfologico.controleRegistro = this.registroSelecionado;
                }
                if ((typeof this.dadosDescritorMorfologico.listaCaracteristicas === 'undefined') || !this.dadosDescritorMorfologico.listaCaracteristicas) {
                    this.dadosDescritorMorfologico.listaCaracteristicas = [];
                }
                let indexCaracteristica = 0;
                angular.forEach(this.caracteristicasDescritorMorfologico, caracteristica => {
                    if (!this.dadosDescritorMorfologico.listaCaracteristicas.find(o => o.caracteristicaDescritorMorfologico && o.caracteristicaDescritorMorfologico.id === caracteristica.id)) {
                        let novoItemCaracteristica = new ItemCaracteristicaDescritorMorfologico_1.ItemCaracteristicaDescritorMorfologico();
                        novoItemCaracteristica.caracteristicaDescritorMorfologico = caracteristica;
                        novoItemCaracteristica.indexCaracteristica = indexCaracteristica;
                        this.dadosDescritorMorfologico.listaCaracteristicas.push(novoItemCaracteristica);
                        indexCaracteristica++;
                    }
                });
                this.listaControleAnexar = [];
                this.listaControleExemploCaracteristica = [];
                angular.forEach(this.dadosDescritorMorfologico.listaCaracteristicas, caracteristica => {
                    if ((typeof caracteristica.anexos === 'undefined') || !caracteristica.anexos) {
                        caracteristica.anexos = new Array();
                    }
                    this.listaControleAnexar.push(caracteristica.anexos.length > 0);
                    if ((typeof caracteristica.exemplos === 'undefined') || !caracteristica.exemplos) {
                        caracteristica.exemplos = new Array();
                    }
                    this.listaControleExemploCaracteristica.push(caracteristica.exemplos.length > 0);
                });
                this.modalDescritorMorfologico = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalDescritorMorfologico() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalDescritorMorfologico = false;
            this.dadosDescritorMorfologico = new ProtecaoRegistroCultivarDescritorMorfologico_1.ProtecaoRegistroCultivarDescritorMorfologico();
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    excluirDescritorMorfologico(id) {
        return __awaiter(this, void 0, void 0, function* () {
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            const ctrl = this;
            this.notificacao
                .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                .then((result) => __awaiter(this, void 0, void 0, function* () {
                if (result.value) {
                    CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_DESCRITOR_MORFOLOGICO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_DESCRITOR_MORFOLOGICO)
                        .id(id)
                        .remover();
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.listaOpcoesDescritorMorfologico =
                        yield ctrl.dadosListaDescritorMorfologico();
                    ctrl.scopeApply();
                }
            }));
        });
    }
    salvarLaudoDna() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                this.modalDescritorMorfologico = false;
                this.modalOrientacoesProcedimentos = false;
                this.modalProteccao = false;
                this.modalRegistro = false;
                if (typeof this.dadosLaudoDna === 'undefined' ||
                    !this.dadosLaudoDna) {
                    this.dadosLaudoDna = new ProtecaoRegistroCultivarLaudoDna_1.ProtecaoRegistroCultivarLaudoDna();
                }
                if (typeof this.dadosLaudoDna.id === 'undefined' ||
                    !this.dadosLaudoDna.id) {
                    this.dadosLaudoDna.controleRegistro = this.registroSelecionado;
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_LAUDO_DNA)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_LAUDO_DNA)
                        .criar(this.dadosLaudoDna);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_LAUDO_DNA)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_LAUDO_DNA)
                        .atualizar(this.dadosLaudoDna);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir um novo Laudo de DNA ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir um novo Laudo de DNA ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.ocultarModalLaudoDna();
                this.filtrarAnalises(true);
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
                this.ocultarModalLaudoDna();
            }
            finally {
                this.ocultarModalLaudoDna();
            }
        });
    }
    abrirModalLaudoDna(id = null) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (typeof id !== 'undefined' && id) {
                    this.dadosLaudoDna = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_LAUDO_DNA)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_LAUDO_DNA)
                        .id(id)
                        .ver();
                    if (this.dadosLaudoDna) {
                        if (typeof this.dadosLaudoDna.dataColeta === 'string') {
                            this.dadosLaudoDna.dataColeta = new Date(this.dadosLaudoDna.dataColeta);
                        }
                        if (typeof this.dadosLaudoDna.dataEmissao === 'string') {
                            this.dadosLaudoDna.dataEmissao = new Date(this.dadosLaudoDna.dataEmissao);
                        }
                        if (typeof this.dadosLaudoDna.dataEnvio === 'string') {
                            this.dadosLaudoDna.dataEnvio = new Date(this.dadosLaudoDna.dataEnvio);
                        }
                    }
                }
                this.modalLaudoDna = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalLaudoDna() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalLaudoDna = false;
            this.dadosLaudoDna = new ProtecaoRegistroCultivarLaudoDna_1.ProtecaoRegistroCultivarLaudoDna();
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    excluirLaudoDna(id) {
        return __awaiter(this, void 0, void 0, function* () {
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            const ctrl = this;
            this.notificacao
                .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                .then((result) => __awaiter(this, void 0, void 0, function* () {
                if (result.value) {
                    CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_LAUDO_DNA)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_LAUDO_DNA)
                        .id(id)
                        .remover();
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.listaOpcoesLaudoDna = yield ctrl.dadosListaLaudoDna();
                    ctrl.scopeApply();
                }
            }));
        });
    }
    salvarOrientacoesProcedimentos() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                this.modalDescritorMorfologico = false;
                this.modalLaudoDna = false;
                this.modalProteccao = false;
                this.modalRegistro = false;
                this.modalDossieCultivar = false;
                if (typeof this.dadosOrientacoesProcedimentos === 'undefined' ||
                    !this.dadosOrientacoesProcedimentos) {
                    this.dadosOrientacoesProcedimentos = new ProtecaoRegistroCultivarOrientacoesProcedimentos_1.ProtecaoRegistroCultivarOrientacoesProcedimentos();
                }
                if (typeof this.dadosOrientacoesProcedimentos.id === 'undefined' ||
                    !this.dadosOrientacoesProcedimentos.id) {
                    this.dadosOrientacoesProcedimentos.controleRegistro = this.registroSelecionado;
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_ORIENTACOES_PROCEDIMENTOS)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_ORIENTACOES_PROCEDIMENTOS)
                        .criar(this.dadosOrientacoesProcedimentos);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_ORIENTACOES_PROCEDIMENTOS)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_ORIENTACOES_PROCEDIMENTOS)
                        .atualizar(this.dadosOrientacoesProcedimentos);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir uma nova Orientação e Procedimento ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir um nova nova Orientação e Procedimento ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.ocultarModalOrientacoesProcedimentos();
                this.filtrarAnalises(true);
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
                this.ocultarModalOrientacoesProcedimentos();
            }
            finally {
                this.ocultarModalOrientacoesProcedimentos();
            }
        });
    }
    abrirModalOrientacoesProcedimentos(id = null) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (typeof id !== 'undefined' && id) {
                    this.dadosOrientacoesProcedimentos = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_ORIENTACOES_PROCEDIMENTOS)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_ORIENTACOES_PROCEDIMENTOS)
                        .id(id)
                        .ver();
                }
                this.modalOrientacoesProcedimentos = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalOrientacoesProcedimentos() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalOrientacoesProcedimentos = false;
            this.dadosOrientacoesProcedimentos = new ProtecaoRegistroCultivarOrientacoesProcedimentos_1.ProtecaoRegistroCultivarOrientacoesProcedimentos();
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    excluirOrientacoesProcedimentos(id) {
        return __awaiter(this, void 0, void 0, function* () {
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            const ctrl = this;
            this.notificacao
                .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                .then((result) => __awaiter(this, void 0, void 0, function* () {
                if (result.value) {
                    CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_ORIENTACOES_PROCEDIMENTOS)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_ORIENTACOES_PROCEDIMENTOS)
                        .id(id)
                        .remover();
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.listaOpcoesOrientacoesProcedimentos =
                        yield ctrl.dadosListaOrientacoesProcedimentos();
                    ctrl.scopeApply();
                }
            }));
        });
    }
    salvarProteccao() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                this.modalDescritorMorfologico = false;
                this.modalLaudoDna = false;
                this.modalOrientacoesProcedimentos = false;
                this.modalRegistro = false;
                this.modalDossieCultivar = false;
                if (typeof this.dadosProteccao === 'undefined' ||
                    !this.dadosProteccao) {
                    this.dadosProteccao = new ProtecaoRegistroCultivarProteccao_1.ProtecaoRegistroCultivarProteccao();
                }
                if (typeof this.dadosProteccao.id === 'undefined' ||
                    !this.dadosProteccao.id) {
                    this.dadosProteccao.controleRegistro = this.registroSelecionado;
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_PROTECCAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_PROTECCAO)
                        .criar(this.dadosProteccao);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_PROTECCAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_PROTECCAO)
                        .atualizar(this.dadosProteccao);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir uma nova Proteção ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir uma nova Proteção ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.ocultarModalProteccao();
                this.filtrarAnalises(true);
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
                this.ocultarModalProteccao();
            }
            finally {
                this.ocultarModalProteccao();
            }
        });
    }
    abrirModalProteccao(id = null, tipoProtecaoCultivar = null) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (typeof id !== 'undefined' && id) {
                    this.dadosProteccao = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_PROTECCAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_PROTECCAO)
                        .id(id)
                        .ver();
                    if (this.dadosProteccao) {
                        if (typeof this.dadosProteccao.dataProtecaoReferente === 'string') {
                            this.dadosProteccao.dataProtecaoReferente = new Date(this.dadosProteccao.dataProtecaoReferente);
                        }
                    }
                }
                if (typeof this.dadosProteccao === 'undefined' || !this.dadosProteccao) {
                    this.dadosProteccao = new ProtecaoRegistroCultivarProteccao_1.ProtecaoRegistroCultivarProteccao();
                }
                if (typeof tipoProtecaoCultivar !== 'undefined' && tipoProtecaoCultivar) {
                    this.dadosProteccao.tipoRegistro = tipoProtecaoCultivar;
                }
                this.modalProteccao = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalProteccao() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalProteccao = false;
            this.dadosProteccao = new ProtecaoRegistroCultivarProteccao_1.ProtecaoRegistroCultivarProteccao();
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    excluirProteccao(id) {
        return __awaiter(this, void 0, void 0, function* () {
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            const ctrl = this;
            this.notificacao
                .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                .then((result) => __awaiter(this, void 0, void 0, function* () {
                if (result.value) {
                    CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_PROTECCAO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_PROTECCAO)
                        .id(id)
                        .remover();
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.listaOpcoesProteccao = yield ctrl.dadosListaProteccao();
                    ctrl.scopeApply();
                }
            }));
        });
    }
    salvarRegistro() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad(document.body);
                this.modalDescritorMorfologico = false;
                this.modalLaudoDna = false;
                this.modalOrientacoesProcedimentos = false;
                this.modalProteccao = false;
                this.modalDossieCultivar = false;
                if (typeof this.dadosRegistro === 'undefined' ||
                    !this.dadosRegistro) {
                    this.dadosRegistro = new ProtecaoRegistroCultivarRegistro_1.ProtecaoRegistroCultivarRegistro();
                }
                if (typeof this.dadosRegistro.id === 'undefined' ||
                    !this.dadosRegistro.id) {
                    this.dadosRegistro.controleRegistro = this.registroSelecionado;
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_REGISTRO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_REGISTRO)
                        .criar(this.dadosRegistro);
                }
                else {
                    yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_REGISTRO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_REGISTRO)
                        .atualizar(this.dadosRegistro);
                }
                if (this.ehCelular) {
                    if (confirm(`Salvo com sucesso! Deseja incluir um novo Registro ?`)) {
                        this.modelo = null;
                        this.$state.reload();
                    }
                    else {
                        if (!this.usuario) {
                            this.$state.go('sair');
                        }
                        else {
                            this.$state.go(this.links.LISTAR.rota);
                        }
                    }
                }
                else {
                    sweetalert2_1.default.fire({
                        title: 'Salvo com sucesso!',
                        text: 'Deseja incluir um novo Registro ?',
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                    }).then((result) => {
                        if (result.value) {
                            this.$state.reload();
                        }
                        else if (result.dismiss === sweetalert2_1.default.DismissReason.cancel) {
                            if (!this.usuario) {
                                this.$state.go('login');
                            }
                            else {
                                this.$state.go(this.links.LISTAR.rota);
                            }
                        }
                    });
                }
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.ocultarModalRegistro();
                this.filtrarAnalises(true);
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
                this.ocultarModalRegistro();
            }
            finally {
                this.ocultarModalRegistro();
            }
        });
    }
    abrirModalRegistro(id = null, tipoRegistroProtecaoCultivar = null) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if (typeof id !== 'undefined' && id) {
                    this.dadosRegistro = yield this.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_REGISTRO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_REGISTRO)
                        .id(id)
                        .ver();
                }
                if (typeof this.dadosRegistro === 'undefined' || !this.dadosRegistro) {
                    this.dadosRegistro = new ProtecaoRegistroCultivarRegistro_1.ProtecaoRegistroCultivarRegistro();
                }
                if (typeof tipoRegistroProtecaoCultivar !== 'undefined' && tipoRegistroProtecaoCultivar) {
                    this.dadosRegistro.tipoRegistro = tipoRegistroProtecaoCultivar;
                }
                this.modalRegistro = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalRegistro() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalRegistro = false;
            this.dadosRegistro = new ProtecaoRegistroCultivarRegistro_1.ProtecaoRegistroCultivarRegistro();
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
    excluirRegistro(id) {
        return __awaiter(this, void 0, void 0, function* () {
            CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            const ctrl = this;
            this.notificacao
                .confirmacao('Tem Certeza?', 'Deseja realmente remover esse registro?')
                .then((result) => __awaiter(this, void 0, void 0, function* () {
                if (result.value) {
                    CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                    yield ctrl.sincronia
                        .rota(RotaAPIConstantes_1.RotaAPIConstantes.PROTECAO_REGISTRO_CULTIVAR_REGISTRO)
                        .etiqueta(SincroniaConstantes_1.SincroniaConstantes.PROTECAO_REGISTRO_CULTIVAR_REGISTRO)
                        .id(id)
                        .remover();
                    CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                    ctrl.listaOpcoesRegistro = yield ctrl.dadosListaRegistro();
                    ctrl.scopeApply();
                }
            }));
        });
    }
    verFoto(foto) {
        return __awaiter(this, void 0, void 0, function* () {
            DispositivoUtil_1.DispositivoUtil.verFoto(foto);
        });
    }
    abrirModalDossieCultivar(indexCaracteristica = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const ctrl = this;
            try {
                this.posicaoTopoAntesModal = angular.element(window).scrollTop();
            }
            catch (ex) {
                this.posicaoTopoAntesModal = null;
                console.log(ex);
            }
            try {
                CarregamentoUtil_1.CarregamentoUtil.exibirLoad('.card');
                if ((typeof indexCaracteristica !== 'undefined' && indexCaracteristica)) {
                    if ((typeof this.itemDossieCultivar === 'undefined') || !this.itemDossieCultivar) {
                        ctrl.itemDossieCultivar = new ItemCaracteristicaDescritorMorfologico_1.ItemCaracteristicaDescritorMorfologico();
                    }
                    let caracteristica = ctrl.listaDossieCultivar.find(u => ((u.indexCaracteristica === indexCaracteristica)));
                    if ((typeof caracteristica !== 'undefined') && caracteristica) {
                        ctrl.itemDossieCultivar = caracteristica;
                    }
                }
                this.modalDossieCultivar = true;
                angular.element('body').css('overflow-y', 'hidden');
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.scopeApply();
            }
            catch (ex) {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
                this.manipulaErro.exception(ex);
            }
            finally {
                CarregamentoUtil_1.CarregamentoUtil.removerLoad();
            }
        });
    }
    ocultarModalDossieCultivar() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modalDossieCultivar = false;
            angular.element('body').css('overflow-y', '');
            globalThis.posicaoTopoAntesModal = this.posicaoTopoAntesModal;
            this.scopeApply(() => {
                setTimeout(() => {
                    angular.element('body').css('overflow-y', '');
                    if (typeof globalThis.posicaoTopoAntesModal !== 'undefined' &&
                        globalThis.posicaoTopoAntesModal !== null &&
                        globalThis.posicaoTopoAntesModal >= 0) {
                        angular.element(window).scrollTop(globalThis.posicaoTopoAntesModal);
                    }
                }, 10);
            });
        });
    }
}
exports.ProtecaoRegistroCultivarController = ProtecaoRegistroCultivarController;
ProtecaoRegistroCultivarController.$inject = ['$scope', '$injector'];
